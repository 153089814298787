import React from "react";
import { Button, Popover, Table } from "antd";
import { convertArrStrToArrObj } from "../../../common/function_common/functionDisplay";
import _ from "lodash";
import TextInputArea from "./TextInputArea";

export default class TextAreaSelectSimple extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    // bind place
  }

  /**
   * trigger dirrectly from input
   *
   */
  select = (record) => {
    const { value } = this.props;
    let tpm = "";

    if (record && record.naiyou) {
      if (value) {
        tpm = value + "、" + record.naiyou;
      } else {
        tpm = record.naiyou;
      }
    }

    this.triggerChange(tpm);
  };

  /**
   * triiger from table select row (yougo_master)
   *
   */
  handleSelectChange = (value) => {
    this.triggerChange(value);
  };

  /**
   * Should provide an event to pass value to Form.
   *
   */
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  handleOk = () => {
    // reset
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.triggerChange("");

    // reset
    this.setState({
      visible: false,
    });
  };

  handleOpenTable = () => {
    // open modal
    this.setState({
      visible: true,
    });
  };

  onClickCellEvent = (record) => {
    this.triggerChange(record.naiyou);

    this.setState({
      rowActive: record.id,
    });
  };

  render() {
    const { value, dataSelect } = this.props;

    const columns = [
      {
        title: "naiyou",
        dataIndex: "naiyou",
        width: 450,
        key: "naiyou",
        render: (textEx, record, index) => record.naiyou,
        onCell: (record) => {
          return {
            onClick: (event) => {
              this.onClickCellEvent(record);
            }, // click row
          };
        },
      },
    ];

    // convert Array(String) to Array(Object)
    let dataTable1 = convertArrStrToArrObj(dataSelect);
    const content = (
      <Table
        bordered
        columns={columns}
        dataSource={dataTable1}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 240 }}
        showHeader={false}
        size={"small"}
        rowKey={(record, index) => index}
      />
    );

    return (
      <div className="text-area-select-ri-page">
        <TextInputArea
          rows={1}
          className="text-area-select-text"
          value={value}
          onChange={this.handleSelectChange}
        />
        <span className="text-area-select-btn">
          <Popover
            overlayStyle={{ width: "500px" }}
            placement={"bottomRight"}
            content={content}
            trigger="click"
            closable={true}
            maskClosable={false}
          >
            <Button type="primary" style={{ marginLeft: 8 }}>
              選択
            </Button>
          </Popover>
        </span>
      </div>
    );
  }
}
