import { ApiPaths } from "../../constants/api_paths";
import {
  YobousienPlanActionTypes,
  ErrorActionTypes,
} from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils } from "../../utils/index";

function getQuoteList(riyousyaId, yobousienPlanId) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_QUOTE_REQUEST,
      });
      const query = {
        riyousyaId,
        yobousienPlanId,
        sid,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.YobousienPlan.GetQuote,
        query,
      });
      dispatch({
        type: YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_QUOTE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_QUOTE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getAll(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_REQUEST,
      });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.YobousienPlan.GetList,
        query,
      });
      dispatch({
        type: YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_QUOTE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_REQUEST });
      const query = {
        id: Number(id),
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.YobousienPlan.GetById,
        query,
      });
      dispatch({
        type: YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_SUCCESS,
        data,
      });
      // return callback(data)
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_FAILURE,
        error,
      });
    }
  };
}

function create(value, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: YobousienPlanActionTypes.CREATE_YOBOUSIEN_PLAN_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "post",
        path: ApiPaths.YobousienPlan.Create,
        body: {
          sid: sid ? sid : null,
          ...value,
        },
      });
      dispatch({
        type: YobousienPlanActionTypes.CREATE_YOBOUSIEN_PLAN_SUCCESS,
        data,
      });
      return callback(data);
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: YobousienPlanActionTypes.CREATE_YOBOUSIEN_PLAN_FAILURE,
        error,
      });
    }
  };
}

function update(value, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: YobousienPlanActionTypes.UPDATE_YOBOUSIEN_PLAN_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "put",
        path: ApiPaths.YobousienPlan.Update,
        body: {
          sid: sid ? sid : null,
          ...value,
        },
      });
      dispatch({
        type: YobousienPlanActionTypes.UPDATE_YOBOUSIEN_PLAN_SUCCESS,
        data,
      });
      return callback(data);
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: YobousienPlanActionTypes.UPDATE_YOBOUSIEN_PLAN_FAILURE,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: YobousienPlanActionTypes.REMOVE_YOBOUSIEN_PLAN_REQUEST,
      });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.YobousienPlan.Delete,
        query,
      });
      dispatch({
        type: YobousienPlanActionTypes.REMOVE_YOBOUSIEN_PLAN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: YobousienPlanActionTypes.REMOVE_YOBOUSIEN_PLAN_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getByDate(riyousyaId, nowId) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_BY_DATA_REQUEST,
      });
      const query = {
        riyousyaId,
        sid,
        id: nowId,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.YobousienPlan.GetQuote,
        query,
      });
      dispatch({
        type: YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_BY_DATA_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_BY_DATA_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function resetData() {
  return (dispatch) => {
    dispatch({
      type: YobousienPlanActionTypes.RESET_CURRENT_YOBOUSIEN_PLAN,
    });
  };
}

function getCheckList(riyousyaId, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      // dispatch({ type: YobousienPlanActionTypes.GET_KIHON_CHECK_YOBOU_REQUEST });
      const query = { riyousyaId, sid };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.YobousienPlan.GetKihonCheck,
        query,
      });
      // dispatch({
      //   type: YobousienPlanActionTypes.GET_KIHON_CHECK_YOBOU_SUCCESS,
      //   data,
      // });
      return callback(data);
    } catch (error) {
      dispatch({
        type: YobousienPlanActionTypes.GET_KIHON_CHECK_YOBOU_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
//前回のデータ読み込み
function getFirstPlanDate(riyousyaId, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: YobousienPlanActionTypes.GET_FIRST_DATE_REQUEST });
      const query = { riyousyaId, sid };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.YobousienPlan.GetFirstPlanDate,
        query,
      });
      dispatch({
        type: YobousienPlanActionTypes.GET_FIRST_DATE_SUCCESS,
        data,
      });
      return callback(data);
    } catch (error) {
      dispatch({
        type: YobousienPlanActionTypes.GET_FIRST_DATE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function newPage() {
  return {
    type: YobousienPlanActionTypes.NEW_PAGE,
  };
}

export const YobousienPlanActions = {
  getQuoteList,
  getAll,
  getById,
  create,
  update,
  deleteId,
  getByDate,
  resetData,
  getCheckList,
  getFirstPlanDate,
  newPage,
};
