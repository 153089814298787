import axios from "axios";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const dutyReportApi = {
  async loadDutyReport(parameterObj) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_DUTY_REPORT, {
        method: "POST",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          date: parameterObj.date,
          roomBunrui: parameterObj.roomBunrui,
        },
      });
      return res.data;
    }
    catch {
      return [];
    }
  },
  async createDutyReport(parameterObj) {
    const key = getMenu();
    const res = await axios(apiUrls.INIT + apiUrls.CREATE_DUTY_REPORT, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        date: parameterObj.date,
        roomBunrui: parameterObj.roomBunrui,
        tantoId: parameterObj.tantoId,
        weather: parameterObj.weather,
        event: parameterObj.event,
        conference: parameterObj.conference,
        jikoHoukoku: parameterObj.jikoHoukoku,
        kanriComment: parameterObj.kanriComment,
        renrakuTokki: parameterObj.renrakuTokki,
        shokuinNum1: parameterObj.shokuinNum1,
        shokuinNum2: parameterObj.shokuinNum2,
        shokuinNum3: parameterObj.shokuinNum3,
        shokuinNum4: parameterObj.shokuinNum4,
        shokuinNum5: parameterObj.shokuinNum5,
        shokuinNum6: parameterObj.shokuinNum6,
        shokuin1: parameterObj.shokuin1,
        shokuin2: parameterObj.shokuin2,
        shokuin3: parameterObj.shokuin3,
        shokuin4: parameterObj.shokuin4,
        shokuin5: parameterObj.shokuin5,
        shokuin6: parameterObj.shokuin6,
        workingStatus: parameterObj.workingStatus,
      },
    });
    return res.data;
  },
  async updateDutyReport(parameterObj) {
    const key = getMenu();
    const res = await axios(apiUrls.INIT + apiUrls.EDIT_DUTY_REPORT, {
      method: "PUT",
      data: {
        dutyReportId: parameterObj.dutyReportId,
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        date: parameterObj.date,
        roomBunrui: parameterObj.roomBunrui,
        tantoId: parameterObj.tantoId,
        weather: parameterObj.weather,
        event: parameterObj.event,
        conference: parameterObj.conference,
        jikoHoukoku: parameterObj.jikoHoukoku,
        kanriComment: parameterObj.kanriComment,
        renrakuTokki: parameterObj.renrakuTokki,
        shokuinNum1: parameterObj.shokuinNum1,
        shokuinNum2: parameterObj.shokuinNum2,
        shokuinNum3: parameterObj.shokuinNum3,
        shokuinNum4: parameterObj.shokuinNum4,
        shokuinNum5: parameterObj.shokuinNum5,
        shokuinNum6: parameterObj.shokuinNum6,
        shokuin1: parameterObj.shokuin1,
        shokuin2: parameterObj.shokuin2,
        shokuin3: parameterObj.shokuin3,
        shokuin4: parameterObj.shokuin4,
        shokuin5: parameterObj.shokuin5,
        shokuin6: parameterObj.shokuin6,
        workingStatus: parameterObj.workingStatus,
      },
    });
    return res.data;
  },
  async getStatisticalOutIn(paramaterObj) {
    const key = getMenu();
    const systemControlName = JSON.parse(localStorage.getItem(key)).sn;
    const systemControlId = JSON.parse(localStorage.getItem(key)).si;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.STATISTICAL_OUT_IN +
        `?today=${paramaterObj.today}&yesterday=${paramaterObj.yesterday}&systemControlName=${systemControlName}&systemControlId=${systemControlId}&roomBunrui=${paramaterObj.roomBunrui}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch {
      return [];
    }
  },
  async getStatisticalOutInByKind(parameterObj) {
    const key = getMenu();
    const systemControlName = JSON.parse(localStorage.getItem(key)).sn;
    const systemControlId = JSON.parse(localStorage.getItem(key)).si;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.STATISTICAL_OUT_IN_BYKIND +
        `?date=${parameterObj.date}&systemControlName=${systemControlName}&systemControlId=${systemControlId}&roomBunrui=${parameterObj.roomBunrui}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      return null;
    }
  },
  async getListPeopleOutInByKind(parameterObj) {
    const key = getMenu();
    const systemControlName = JSON.parse(localStorage.getItem(key)).sn;
    const systemControlId = JSON.parse(localStorage.getItem(key)).si;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.PEOPLE_OUT_IN_BYKIND +
        `?date=${parameterObj.date}&systemControlName=${systemControlName}&systemControlId=${systemControlId}&roomBunrui=${parameterObj.roomBunrui}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      return [];
    }
  },
};
export { dutyReportApi };
