import { connect } from "react-redux";

import {
  changeValuePlan1,
  createKyotakuPlan1,
  getKyotakuPlan1,
  loadKyotakuPlan1,
  updateKyotakuPlan1,
} from "../../../actions/kyotakuPlan1Actions/actionCreators";
import { loadRiyouKaigohokenList } from "../../../actions/riyouKaigohoken/actionCreators";
import { getRiyousya } from "../../../actions/riyousyaActions/actionCreators";

import React, { Component } from "react";
import { CommonPageContainer } from "../../../common/component/IdleTimerPage";
import { getUrlVars } from "../../../common/function_common/functionCommon";
import "./InputForm.css";
import KyotakuPlan1InputFormWrapper from "./KyotakuPlan1InputForm";

class KyotakuPlan1 extends Component {
  constructor(props) {
    super(props);
    const { riyousyaId, kyotakuPlan1Id } = this.props.match.params;

    this.state = {
      riyousyaId,
      kyotakuPlan1Id,
      visibleConfirm: false,
    };
  }

  async componentDidMount() {
    this.props.getRiyousyaInfo(this.state.riyousyaId);
    const copyFrom = getUrlVars(window.location.href)["copy-from"];
    if (copyFrom) {
      await this.props.getKyotakuPlan1(copyFrom);
    }
    else {
      await this.props.getKyotakuPlan1(this.state.kyotakuPlan1Id);
    }
    this.props.getListRiyouKaigohoken(this.state.riyousyaId);
    window.scrollTo(0, 0);
  }

  regData(err, values) {
    const { kyotakuPlan1Id } = this.props.match.params;
    if (err) {
      return;
    }
    if (kyotakuPlan1Id === "new")
      this.props.createKyotakuPlan1(values, this.state.riyousyaId);
    else this.props.updateKyotakuPlan1(values, kyotakuPlan1Id);
  }
  regDataAndCheck(err, values) {
    const { kyotakuPlan1Id } = this.props.match.params;
    if (err) {
      return;
    }
    if (kyotakuPlan1Id === "new")
      this.props.createKyotakuPlan1(values, this.state.riyousyaId);
    else this.props.updateKyotakuPlan1(values, kyotakuPlan1Id);
  }
  changeValuePlan1(type) {
    this.props.changeValuePlan1(type);
  }

  render() {
    const copyFrom = getUrlVars(window.location.href)["copy-from"];
    const { kyotakuPlan1Id } = this.props.match.params;
    return (
      <CommonPageContainer
        id={"input-kyotaku-plan1-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <KyotakuPlan1InputFormWrapper
          valuesChange1={this.props.valuesChange1}
          changeValuePlan1={(type) => this.changeValuePlan1(type)}
          data={this.props.kyotakuPlan1}
          riyousyaId={this.state.riyousyaId}
          riyousya={this.props.riyousya}
          kyotakuPlan1Id={kyotakuPlan1Id}
          copyFrom={copyFrom}
          match={this.props.match}
          riyouKaigohokens={this.props.riyouKaigohokens}
          history={this.props.history}
          regData={(err, values) => this.regData(err, values)}
          regDataAndCheck={(err, values) => this.regDataAndCheck(err, values)}
          loading={this.props.loading}
        />
      </CommonPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.documentList.loading,
    riyousya: state.riyousya.riyousya,
    kyotakuPlan1: state.kyotakuPlan1.kyotakuPlan1,
    riyouKaigohokens: state.kyotakuPlan1.riyouKaigohokens,
    valuesChange1: state.kyotakuPlan1.valuesChange1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadKyotakuPlan1: (data, callback) =>
      dispatch(loadKyotakuPlan1(data, callback)),
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
    getKyotakuPlan1: (id) => dispatch(getKyotakuPlan1(id)),
    createKyotakuPlan1: (data, riyousyaId, callback) =>
      dispatch(createKyotakuPlan1(data, riyousyaId, callback)),
    updateKyotakuPlan1: (data, id, callback) =>
      dispatch(updateKyotakuPlan1(data, id, callback)),
    getListRiyouKaigohoken: (riyousyaId) =>
      dispatch(loadRiyouKaigohokenList(riyousyaId)),
    changeValuePlan1: (type) => dispatch(changeValuePlan1(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KyotakuPlan1);
