import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";

const SisetuKaigoNaiyouMasterApi = {
  async getKaigoNaiyouList(bunrui, keyword, page, kyotuf, bunrui2) {
    const res = await axios
      .get(apiUrls.GET_SISETU_KAIGO_NAIYOU_MASTER, {
        params: {
          bunrui,
          keyword,
          page,
          kyotuf,
          bunrui2
        },
      });
    return res.data;
  },

  async getKaigoNaiyouListAll(bunrui, keyword, bunrui2) {
    const res = await axios
      .get(apiUrls.GET_SISETU_KAIGO_NAIYOU_LIST_MASTER, {
        params: {
          bunrui,
          keyword,
          bunrui2
        },
      });
    return res.data;
  },

  async saveKaigoNaiyouList(data) {
    const res = await axios
      .post(apiUrls.SAVE_SISETU_KAIGO_NAIYOU_MASTER, {
        data,
      });
    return res.data;
  },

  async loadDataInit() {
    const res = await axios(apiUrls.INIT + apiUrls.GET_SISETU_KAIGO_NAIYOU_INIT, {
      method: "GET",
    });
    return res.data;
  },

  async create(data) {
    console.log(data);
    const res = await axios.post(apiUrls.CREATE_SISETU_KAIGO_NAIYOU, data);
    return res.data;
  },

  async update(data) {
    const res = await axios.put(apiUrls.UPDATE_SISETU_KAIGO_NAIYOU, data);
    return res.data;
  },
};

export { SisetuKaigoNaiyouMasterApi };
