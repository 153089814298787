import {RESET_STATUS, GET_ID_FACE_SHEET, UPDATE_ID_FACE_SHEET_SUCCESS, UPDATE_ID_FACE_SHEET_FAIL } from "../../actions/faceSheetAction/actionName";
import { notification } from "antd/lib/index";

export default function FaceSheetReducers(state = {
}, action) {
  switch (action.type) {
    case RESET_STATUS: 
      return {
        ...state,
        notifyContentEdit: {}
      }
    case GET_ID_FACE_SHEET:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case UPDATE_ID_FACE_SHEET_SUCCESS:
      notification["success"]({
        message: "保存しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        data: action.data,
        loading: false,
        notifyContentEdit: { type: "success" }
      };
    case UPDATE_ID_FACE_SHEET_FAIL:
      notification["error"]({
        message: action.error.message,
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        state,
        data: action.data,
        loading: false,
        notifyContentEdit: { type: "error" }
      };

    default:
      return state;
  }
}