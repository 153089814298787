import { SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Affix, Button, Checkbox, Modal, Row, Table } from "antd";
import axios from "axios";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { toKatakana } from "wanakana";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import { apiUrls } from "../../common/configs/common-url";
import {
  formatStorage,
  getMenu,
  getPath,
  getSid,
  isLife,
  isManagement,
} from "../../common/function_common/functionCommon";
import {
  displayJpDateKanji,
  displaySeireki,
  displaySystemName,
} from "../../common/function_common/functionDisplay";
import FormAdd from "./UI/FormAdd";

class RiyousyaAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelVisible: false,
      riyouListVisible: false,
      isLoadingUser: false,
      isConfirmAddNew: false,
      riyousyaList: null,
      rowActive: null,
      data: {},
      systemIds: null,
      systemChack: false,
      reference: null,
      path: getPath(),
      err: [false, false, false],
    };
  }

  componentDidMount() {
    let reference = null;

    if (isManagement() || isLife()) {
      const menuKey = getMenu();
      const menu = formatStorage(menuKey);

      if (menu.sn == "nyusho" || menu.sn == "rouken" || menu.sn == "ryoyo") {
        reference = { table: "riyousya_sisetu", field: "code" };
      }
      else if (menu.sn == "short" || menu.sn == "shortrouken") {
        reference = { table: "riyousya_short", field: "code" };
      }
      else if (menu.sn == "tokutei") {
        reference = { table: "riyousya_tokutei", field: "code" };
      }
      else if (menu.sn == "group") {
        reference = { table: "riyousya_group", field: "code" };
      }
      else if (menu.sn == "kyotaku") {
        reference = { table: "riyousya_kyotaku", field: "code" };
      }
      else if (menu.sn == "yobou") {
        reference = { table: "riyousya_yobou", field: "code" };
      }
      else if (menu.sn == "houkatu") {
        reference = { table: "riyousya_houkatu", field: "code" };
      }

      this.setState({
        reference,
      });
    }
    else {
      this.props.getSystemControlListSisetu((data) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            if (
              data[i].systemName == "nyusho" ||
              data[i].systemName == "rouken" ||
              data[i].systemName == "ryoyo"
            ) {
              reference = { table: "riyousya_sisetu", field: "code" };
            }
            else if (
              data[i].systemName == "short" ||
              data[i].systemName == "shortrouken"
            ) {
              reference = { table: "riyousya_short", field: "code" };
            }
            else if (data[i].systemName == "tokutei") {
              reference = { table: "riyousya_tokutei", field: "code" };
            }
            else if (data[i].systemName == "group") {
              reference = { table: "riyousya_group", field: "code" };
            }

            if (reference) {
              break;
            }
          }

          this.setState({
            reference,
          });
        }
      });
    }
  }

  showGoBackConfirm() {
    this.setState({
      cancelVisible: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      cancelVisible: false,
    });
  }

  handleConfirmGoBack() {
    const { data, path } = this.state;

    this.setState({
      cancelVisible: false,
    });

    this.cancelCreate(data.code);
    this.props.history.push(path + "/riyousya");
  }

  handleGoBack = (e) => {
    const { data, isOnChangeForm, path } = this.state;

    e.preventDefault();

    if (isOnChangeForm) {
      this.showGoBackConfirm();
    }
    else {
      this.cancelCreate(data.code);
      this.props.history.push(path + "/riyousya");
    }
  };

  cancelCreate = (code) => {
    const { reference } = this.state;
    if (reference) {
      this.props.cancelCreateMaster(reference.table, reference.field, code);
    }
  };

  handleModify = (e) => {
    const { data, systemChack, path, err } = this.state;

    e.preventDefault();

    const birthday = displaySeireki(data.gengo, data.year, data.month, data.day);
    if (data.name && data.furigana && birthday) {
      const tpmParams = {
        id: data.id ? data.id : null,
        name: data.name ? data.name : "",
        furigana: data.furigana ? data.furigana : "",
        birthday: birthday ? birthday : "0001-01-01",
        gender: data.gender ? data.gender : 1,
        sid: getSid(),
        code: data.code,
      };

      if (systemChack) {
        this.cancelCreate(tpmParams.code);
      }

      this.props.addRiyousya(tpmParams, async (data) => {
        if (data) {
          await this.props.riyousyaListIsKeep(
            this.props.searchName,
            this.props.isKeep,
            data
          );
          this.props.history.push(`${path}/riyousya`);
        }
      });
    }
    else {
      const newErr = err;
      newErr[0] = !data.name;
      newErr[1] = !data.furigana;
      newErr[2] = !birthday;

      this.setState({
        err: newErr,
      });
    }
  };

  onChange = (e, field) => {
    const { data, err } = this.state;

    const newData = data;
    if (
      field == "gengo" ||
      field == "year" ||
      field == "month" ||
      field == "day" ||
      field == "code"
    ) {
      newData[field] = e;
    }
    else {
      newData[field] = e.target.value;
    }

    const newErr = err;
    if (err[0] && newData.name) {
      newErr[0] = false;
    }

    if (err[1] && newData.furigana) {
      newErr[1] = false;
    }

    if (
      err[2] &&
      newData.gengo &&
      newData.year &&
      newData.month &&
      newData.day
    ) {
      newErr[2] = false;
    }

    this.setState({
      data: newData,
      err: newErr,
    });
  };

  onBlur = (type) => {
    const { data } = this.state;

    if (type == 0) {
      this.checkUser(data.name, "", "");
    }

    if (type == 1) {
      const value = toKatakana(data.furigana);

      this.checkUser("", value, "");

      this.setState({
        data: { ...data, furigana: value },
      });
    }

    if (type == 2) {
      const birtday = displaySeireki(data.gengo, data.year, data.month, data.day);
      this.checkUser("", "", birtday);
    }
  };

  checkUser = (name, furigana, birthday) => {
    this.setState({ riyousyaList: [] });

    axios(
      apiUrls.INIT +
      apiUrls.RIYOUSYA_SEARCH_ADD +
      `?name=${name ? name : ""}&furigana=${furigana ? furigana : ""
      }&birthday=${birthday ? birthday : ""}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({
            riyousyaList: res.data,
            riyouListVisible: true,
          });
        }
      })
      .catch(() => {
        setTimeout(() => {
          this.setState({
            riyousyaList: [],
          });
        }, 1000);
      });
  };

  handleSearchRiyou = (type) => {
    const { data } = this.state;

    if (type == 0) {
      this.fetchUser(data.name, "", "");
    }

    if (type == 1) {
      this.fetchUser("", data.furigana, "");
    }

    if (type == 2) {
      const birtday = displaySeireki(data.gengo, data.year, data.month, data.day);
      this.fetchUser("", "", birtday);
    }

    // display list riyousya model
    this.setState({
      riyouListVisible: true,
    });
  };

  fetchUser = (name, furigana, birthday) => {
    this.setState({ riyousyaList: [], isLoadingUser: true });

    axios(
      apiUrls.INIT +
      apiUrls.RIYOUSYA_SEARCH_ADD +
      `?name=${name ? name : ""}&furigana=${furigana ? furigana : ""
      }&birthday=${birthday ? birthday : ""}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        this.setState({
          riyousyaList: res.data,
          isLoadingUser: false,
        });
      })
      .catch(() => {
        setTimeout(() => {
          this.setState({
            isLoadingUser: false,
          });
        }, 1000);
      });
  };

  errCheck = (type) => {
    const { err } = this.state;

    const newErr = err;
    newErr[type] = true;
    this.setState({
      err: newErr,
    });
  };

  selectConfirm = () => {
    this.setState({
      isConfirmAddNew: !this.state.isConfirmAddNew,
    });
  };

  handleSelectUser = (record) => {
    const { rowActive } = this.state;

    if (rowActive && rowActive.id == record.id) {
      this.setState({
        rowActive: null,
      });
    }
    else {
      this.setState({
        rowActive: record,
      });
    }
  };

  handleCloseRiyou = () => {
    // visible riyousya list
    this.setState({
      riyouListVisible: false,
      isConfirmAddNew: false,
      rowActive: null,
    });
  };

  handleOkRiyou = () => {
    const { data, rowActive, isConfirmAddNew, reference } = this.state;

    if (rowActive) {
      let wareki = null;
      if (rowActive.birthday && rowActive.birthday !== "0001-01-01") {
        const dt = new Date(rowActive.birthday);
        wareki = dt
          .toLocaleDateString("ja-JP-u-ca-japanese", { era: "long" })
          .split("/");
      }

      const newData = {
        ...data,
        id: isConfirmAddNew ? null : rowActive.id,
        name: rowActive.name,
        furigana: rowActive.furigana,
        gengo: wareki ? wareki[0].substring(0, 2) : "",
        year: wareki ? wareki[0].substring(2) : "",
        month: wareki ? wareki[1] : "",
        day: wareki ? wareki[2] : "",
        gender: rowActive.gender,
      };

      let systemIds = "";
      let systemChack = false;
      const sid = getSid();
      if (!isConfirmAddNew && reference) {
        if (reference.table == "riyousya_sisetu") {
          if (rowActive.riyousyaSisetu && rowActive.riyousyaSisetu.length > 0) {
            rowActive.riyousyaSisetu.forEach((e) => {
              systemIds = systemIds ? systemIds + "," + e.id : e.id;
              if (e.serviceKikanMasterId == sid) {
                systemChack = true;
              }
            });
          }
        }
        else if (reference.table == "riyousya_short") {
          if (rowActive.riyousyaShort && rowActive.riyousyaShort.length > 0) {
            rowActive.riyousyaShort.forEach((e) => {
              systemIds = systemIds ? systemIds + "," + e.id : e.id;
              if (e.serviceKikanMasterId == sid) {
                systemChack = true;
              }
            });
          }
        }
        else if (reference.table == "riyousya_tokutei") {
          if (
            rowActive.riyousyaTokutei &&
            rowActive.riyousyaTokutei.length > 0
          ) {
            rowActive.riyousyaTokutei.forEach((e) => {
              systemIds = systemIds ? systemIds + "," + e.id : e.id;
              if (e.serviceKikanMasterId == sid) {
                systemChack = true;
              }
            });
          }
        }
        else if (reference.table == "riyousya_group") {
          if (rowActive.riyousyaGroup && rowActive.riyousyaGroup.length > 0) {
            rowActive.riyousyaGroup.forEach((e) => {
              systemIds = systemIds ? systemIds + "," + e.id : e.id;
              if (e.serviceKikanMasterId == sid) {
                systemChack = true;
              }
            });
          }
        }
        else if (reference.table == "riyousya_kyotaku") {
          if (
            rowActive.riyousyaKyotaku &&
            rowActive.riyousyaKyotaku.length > 0
          ) {
            rowActive.riyousyaKyotaku.forEach((e) => {
              systemIds = systemIds ? systemIds + "," + e.id : e.id;
              if (e.serviceKikanMasterId == sid) {
                systemChack = true;
              }
            });
          }
        }
        else if (reference.table == "riyousya_yobou") {
          if (rowActive.riyousyaYobou && rowActive.riyousyaYobou.length > 0) {
            rowActive.riyousyaYobou.forEach((e) => {
              systemIds = systemIds ? systemIds + "," + e.id : e.id;
              if (e.serviceKikanMasterId == sid) {
                systemChack = true;
              }
            });
          }
        }
      }

      this.setState({
        data: newData,
        systemIds,
        systemChack,
        err: [false, false, false],
      });
    }
    else {
      this.setState({
        data: { ...data, id: null },
        systemIds: "",
        systemChack: false,
      });
    }

    this.handleCloseRiyou();
  };

  /**
   *
   */
  render() {
    const { sessionTimeout } = this.props;
    const {
      data,
      systemIds,
      cancelVisible,
      riyouListVisible,
      isConfirmAddNew,
      isLoadingUser,
      rowActive,
      riyousyaList,
      reference,
      err,
    } = this.state;

    const columns = [
      {
        dataIndex: "利用者名前",
        key: "name",
        width: "30%",
        render: (text, record) => <div>{record.name}</div>,
      },
      {
        dataIndex: "birthday",
        key: "birtDay",
        width: "30%",
        render: (text, record) => (
          <div>
            {record.birthday && record.birthday !== "0001-01-01" ? (
              <span>{`${displayJpDateKanji(record.birthday)}`}</span>
            ) : (
              record.birthday
            )}
          </div>
        ),
      },
      {
        dataIndex: "システム",
        key: "system",
        width: "40%",
        render: (text, record) => <div>{displaySystemName(record)}</div>,
      },
    ];

    return (
      (<CommonPageContainer
        id="riyousya-update-page"
        sessionTimeout={sessionTimeout}
      >
        <Affix offsetTop={0}>
          <Row className={"submenu"}>
            <MediaQuery query="(min-device-width: 0px)">
              {(matches) => {
                if (matches) {
                  return (
                    <SubHeaderV2
                      TopLeft={
                        <SubHeaderTitleV2
                          title={"利用者新規"}
                          iconType="user"
                        />
                      }
                      TopRight={
                        <div className="submenu-t-r">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={this.handleGoBack}
                            className="sub-top-right-item"
                          >
                            <span>
                              <UndoOutlined />
                            </span>
                            <span> 戻る</span>
                          </div>

                          <div
                            style={{ cursor: "pointer" }}
                            className="sub-top-right-item"
                            onClick={this.handleModify}
                          >
                            <span>
                              <SaveOutlined style={{ color: "red" }} />
                            </span>
                            <span> 登録</span>
                          </div>
                        </div>
                      }
                    />
                  );
                }
                /**
                   * @TODO design UI for mobile
                   *
                   * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
                   */
                return null;

              }}
            </MediaQuery>
          </Row>
        </Affix>
        <div className="riyousya-update-content" style={{ marginTop: 24 }}>
          <FormAdd
            data={data}
            systemIds={systemIds}
            reference={reference}
            err={err}
            onChange={this.onChange}
            onBlur={this.onBlur}
            handleSearchRiyou={this.handleSearchRiyou}
            errCheck={this.errCheck}
          ></FormAdd>
        </div>
        <Modal
          open={cancelVisible}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          footer={[
            <Button
              key="okdelete"
              type={"primary"}
              onClick={() => this.handleConfirmGoBack()}>
              OK
            </Button>,
            <Button
              key="canceldelete"
              onClick={() => this.closeGoBackConfirm()}
            >
              キャンセル
            </Button>,
          ]}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
        {/* Search Riyousya By Furigana Keybroad */}
        <Modal
          maskClosable={false}
          open={riyouListVisible}
          title={
            <h4 style={{ padding: "0 24px" }}>
              同じ名前の利用者が既に登録されていますので、この利用者情報を呼び出します
            </h4>
          }
          onOk={this.handleSelectRiyou}
          onCancel={this.handleCloseRiyou}
          footer={[
            <Checkbox
              key="confirm"
              checked={isConfirmAddNew}
              style={{ marginRight: 48 }}
              onClick={this.selectConfirm}
            >
              この利用者を別人として登録する場合はチェックして下さい
            </Checkbox>,
            <Button
              key={"close"}
              type="primary"
              onClick={this.handleOkRiyou}
              disabled={
                !(isConfirmAddNew || (rowActive && rowActive.id))
              }
            >
              はい
            </Button>,
          ]}
          width={560}
        >
          <Table
            size={"small"}
            defaultExpandAllRows
            showHeader={false}
            header={null}
            footer={null}
            columns={columns}
            dataSource={riyousyaList}
            loading={isLoadingUser}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.handleSelectUser(record, rowIndex);
                }, // click row
              };
            }}
            rowKey={(record) => record.id}
            rowClassName={(record) =>
              rowActive && record.id === rowActive.id
                ? "pljp-table-row-hover"
                : ""
            }
          />
        </Modal>
      </CommonPageContainer>)
    );
  }
}

export default RiyousyaAdd;
