import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export default class Home extends Component {
  render() {
    const token = localStorage.getItem("jwtToken");
    if (token === null) {
      return <Redirect to={{ pathname: "/login" }} />;
    } else {
      return (
        //pathname: "/login", state: { from: props.location }
        <Redirect to={{ pathname: "/dengon" }} />
      );
    }
  }
}
