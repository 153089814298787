import React, { Component } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import Geocode from "react-geocode";
import { resizeFilePopup } from "../../../common/function_common/functionDisplay";
import { getValueLocalstorage } from "../../../common/function_common/functionCommon";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyCGV33RAK-Ej0tGjTSJcMKROcRzWtgSsF0");

// set response language. Defaults to english.
Geocode.setLanguage("jp");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("jp");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

class GoogleMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: "",
      lng: "",
    };
  }

  componentDidMount() {
    const { address } = this.props;

    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;

        this.setState({
          lat: lat,
          lng: lng,
        });

        resizeFilePopup();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  componentDidUpdate(preProps) {
    const { address } = this.props;

    if (address !== preProps.address) {
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;

          this.setState({
            lat: lat,
            lng: lng,
          });

          resizeFilePopup();
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  render() {
    const { lat, lng } = this.state;
    const { address } = this.props;
    return (
      <div id="object_popup" style={{ height: "100%" }}>
        {address ? (
          <Map
            google={this.props.google}
            zoom={16}
            center={{ lat: lat, lng: lng }}
            initialCenter={{ lat: lat, lng: lng }}
            style={{width: '99%', height: '90%'}}
          >
            <Marker title={address} position={{ lat: lat, lng: lng }} />
          </Map>
        ) : null}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: getValueLocalstorage("gmk"),
})(GoogleMap);
