import { Row, notification } from "antd";
import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";

import { getPath } from "../../common/function_common/functionCommon";
import ActionsRow from "./UI/ActionsRow";
import WrappedKYMForm from "./UI/KYMForm";

class KanriKaigoYougoMasterSave extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      kymId: parseInt(match.params.kymId),
      currentPage: props.currentKYM,
      pageSize: props.pageSizeKYM, // @TODO setting in kaigoyougomaster,
      ids: props.kymSelectedRows,
      path: getPath(),
    };

    this.registerKYM = this.registerKYM.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    // load data
    const { ids, kymId } = this.state;

    this.props.getKYMSingleRecord(kymId, ids, null);
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent, kanriKYM } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }

    if (kanriKYM != oldProps.kanriKYM) {
      if (kanriKYM && kanriKYM.id) {
        this.setState({
          kymId: kanriKYM.id,
        });

        this.props.history.replace(
          this.state.path + "/kanri/kaigoyougomaster/" + kanriKYM.id
        );
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  async registerKYM(values) {
    if (values.id) {
      await this.props.updateKYM(values);
    }
    else {
      await this.props.createKYM(values);
    }
    this.props.history.goBack();
  }

  async delete(id) {
    await this.props.deleteKYM(id.toString());

    this.props.history.goBack();
  }

  preToEnd = () => {
    const { ids, kymId } = this.state;
    this.props.getKYMSingleRecord(kymId, ids, "fastBack");
  };

  pre = () => {
    const { ids, kymId } = this.state;
    this.props.getKYMSingleRecord(kymId, ids, "stepBack");
  };

  next = () => {
    const { ids, kymId } = this.state;
    this.props.getKYMSingleRecord(kymId, ids, "stepForwad");
  };

  nextToEnd = () => {
    // const { kanriKYMList } = this.props;
    // const { kymId } = this.state;

    // if (kanriKYMList && kanriKYMList.length > 0) {
    //     let index = kanriKYMList.findIndex((item) => item.id === kymId);

    //     if (index !== kanriKYMList.length) {

    //         this.setState({
    //             kymId: kanriKYMList[kanriKYMList.length - 1].id
    //         })

    //         this.props.history.replace("/kanri/kaigoyougomaster/" + kanriKYMList[kanriKYMList.length - 1].id);
    //     }
    // }
    const { ids, kymId } = this.state;
    this.props.getKYMSingleRecord(kymId, ids, "fastForwad");
  };

  recordMark = (type, id) => {
    this.props.recordMark("kym", type, id);
  };

  render() {
    const { kanriKYM, kymSelectedRows } = this.props;
    const { kymId } = this.state;

    return (
      <KanriLayoutContainer router="kaigoyougomaster" breadcrumbLink={"/kanri/kaigoyougomaster"} breadcrumbText={"介護用語マスタ"}>
        <ActionsRow
          key={kymSelectedRows}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          selectedList={kymSelectedRows}
          id={kymId}
        />

        <Row>
          <WrappedKYMForm
            data={kanriKYM}
            history={this.props.history}
            regData={(values) => this.registerKYM(values)}
            delete={this.delete}
          />
        </Row>
      </KanriLayoutContainer>
    );
  }
}

export default KanriKaigoYougoMasterSave;
