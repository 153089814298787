/* eslint-disable */
import axios from "axios/index";
import 'whatwg-fetch';
import { apiUrls } from "../common/configs/common-url";
import { checkLogin, formatStorage, getMenu, getToken, isLife, isManagement } from "../common/function_common/functionCommon";

let ReportCaseApi = {
    async loadReportCaseStartup(tantoId) {
        const key = getMenu();
        try {
            const res = await axios(apiUrls.INIT + apiUrls.GET_REPORT_CASE_START, {
                method: 'POST',
                data: {
                    systemControlName: isManagement() || isLife() ? formatStorage(key).scn : formatStorage(key).sn,
                    systemControlId: isManagement() || isLife() ? formatStorage(key).si : formatStorage(key).si,
                    tantoId: tantoId,
                    tanto_id: tantoId
                },
                headers: {
                    'x-token': getToken()
                },
                withCredentials: true
            });
            return res.data;
        } catch (err) {
            if (!err.response) {
                // network error
                window.location.reload();
            } else if (err.response.data.error && err.response.data.error.code == "REPORTCASE_NOT_FOUND") {
                return [];
            } else {
                clearLocalStorage(err);
            }
        }
    },
    async loadReportCase(data) {
        const key = getMenu();
        try {
            const res = await axios(apiUrls.INIT + apiUrls.GET_REPORT_CASE, {
                method: 'POST',
                data: {
                    systemControlName: isManagement() || isLife() ? formatStorage(key).scn : formatStorage(key).sn,
                    systemControlId: isManagement() || isLife() ? formatStorage(key).si : formatStorage(key).si,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    riyousyaId: data.riyousyaId,
                    kiroku_kubun: data.kiroku_kubun,
                    sonota_kubun: data.sonota_kubun,
                    naiyou: data.naiyou,
                    tyuif: data.tyuif,
                    nishif: data.nishif,
                    riyouf: data.riyouf,
                    tanto_id: data.tanto_id,
                    bunrui: data.bunrui,
                    room_bunrui: data.room_bunrui,
                    sid: data.sid == null ? null : data.sid.toString(),
                    tanto_sikaku: data.tanto_sikaku,
                    searchName: data.searchName,
                    caremana_only: data.caremana_only == "ケアマネ" ? "ケアマネ" : data.caremana_only == "記録" ? "記録" : null,
                    impf: data.impf,
                },
                headers: {
                    'x-token': getToken()
                },
                withCredentials: true
            });
            return res.data;
        } catch (err) {
            if (!err.response) {
                // network error
                window.location.reload();
            } else {
                clearLocalStorage(err);
            }
        }
    },
    async createReportCase(data) {
        const key = getMenu();
        try {
            const res = await axios(apiUrls.INIT + apiUrls.CREATE_REPORT_CASE, {
                method: 'POST',
                data: {
                    systemControlName: isManagement() || isLife() ? formatStorage(key).scn : formatStorage(key).sn,
                    systemControlId: isManagement() || isLife() ? formatStorage(key).si : formatStorage(key).si,
                    sid: isManagement() || isLife() ? JSON.parse(localStorage.getItem(key)).si : null,
                    riyousyaId: data.riyousyaId.toString(),
                    date: data.date,
                    time: data.time,
                    kiroku_kubun: data.kiroku_kubun,
                    sonota_kubun: data.sonota_kubun,
                    tyuif: data.tyuif.toString(),
                    nishif: data.nishif.toString(),
                    tanto_id: data.tanto_id != null ? data.tanto_id.toString() : data.tanto_id,
                    naiyou: data.naiyou,
                    naiyou2: data.naiyou2
                },
                headers: {
                    'x-token': getToken()
                },
                withCredentials: true
            });
            return res.data;
        } catch (err) {
            if (!err.response) {
                // network error
                window.location.reload();
            } else {
                clearLocalStorage(err);
            }
        }
    },
    async updateReportCase(data) {
        const key = getMenu();
        try {
            const res = await axios(apiUrls.INIT + apiUrls.UPDATE_REPORT_CASE, {
                method: 'PUT',
                data: {
                    systemControlName: isManagement() || isLife() ? formatStorage(key).scn : formatStorage(key).sn,
                    systemControlId: isManagement() || isLife() ? formatStorage(key).si : formatStorage(key).si,
                    sid: isManagement() || isLife() ? JSON.parse(localStorage.getItem(key)).si : null,
                    reportCaseId: data.reportCaseId.toString(),
                    riyousyaId: data.riyousyaId.toString(),
                    date: data.date,
                    time: data.time,
                    kiroku_kubun: data.kiroku_kubun,
                    sonota_kubun: data.sonota_kubun,
                    tyuif: data.tyuif.toString(),
                    nishif: data.nishif.toString(),
                    tanto_id: data.tanto_id != null ? data.tanto_id.toString() : data.tanto_id,
                    naiyou: data.naiyou,
                    naiyou2: data.naiyou2
                },
                headers: {
                    'x-token': getToken()
                },
                withCredentials: true
            });
            return res.data;
        } catch (err) {
            if (!err.response) {
                // network error
                window.location.reload();
            } else {
                clearLocalStorage(err);
            }
        }
    },
    async deleteReportCase(reportCaseId) {
        const key = getMenu();
        try {
            const res = await axios(apiUrls.INIT + apiUrls.DELETE_REPORT_CASE, {
                method: 'DELETE',
                data: {
                    systemControlName: isManagement() || isLife() ? formatStorage(key).scn : formatStorage(key).sn,
                    systemControlId: isManagement() || isLife() ? formatStorage(key).si : formatStorage(key).si,
                    reportCaseId: reportCaseId
                },
                headers: {
                    'x-token': getToken()
                },
                withCredentials: true
            });
            return res.data;
        } catch (err) {
            if (!err.response) {
                // network error
                window.location.reload();
            } else {
                clearLocalStorage(err);
            }
        }
    },
    async updateKakuninComment(data) {
        try {
            const res = await axios(apiUrls.INIT + apiUrls.UPDATE_KAKUNIN_COMMENT, {
                method: 'PUT',
                data: {
                    reportCaseId: data.reportCaseId.toString(),
                    impf: data.impf,
                    comment: data.comment,
                },
                headers: {
                    'x-token': getToken()
                },
                withCredentials: true
            });
            return res.data;
        } catch (err) {
            if (!err.response) {
                // network error
                window.location.reload();
            } else {
                clearLocalStorage(err);
            }
        }
    },
    async getReportCaseById(reportCaseId) {
        try {
            const res = await axios(apiUrls.INIT + apiUrls.GET_REPORT_CASE_BY_ID + '?reportCaseId=' + reportCaseId, {
                method: 'GET'
            });
            return res.data;
        } catch (err) {
            if (!err.response) {
                // network error
                window.location.reload();
            } else {
                clearLocalStorage(err);
            }
        }
    }
};

let RiyousyaApi = {
    async getRiyousya(riyousyaId) {
        try {
            const res = await axios(apiUrls.INIT + apiUrls.RIYOUSYA_GET + '?riyousyaId=' + riyousyaId, {
                method: 'GET'
            });
            return res.data;
        } catch (err) {
            if (!err.response) {
                // network error
                window.location.reload();
            } else {
                clearLocalStorage(err);
            }
        }
    }
}

function clearLocalStorage(err) {
    // Check Token Exist
    if (err.response.status === 403) {
        checkLogin();
    }
    return err;
}

export { ReportCaseApi, RiyousyaApi };

