import {
  CREATE_KYOMI_SUCESS,
  CREATE_LIFE_SUCESS,
  CREATE_PROCESS_SUCESS,
  CREATE_TANTOKAIGI_SUCESS,
  GET_SETTING_MASTER_PROCESS_SUCESS,
  LOAD_KAIGI_TEMPLATE_SUCCESS,
  PROCESS_LOAD_KAIGI_REQUEST,
  PROCESS_LOAD_KAIGI_SUCESS,
  RESET_CURRENT_KYOMI,
  RESET_CURRENT_PROCESS,
  RESET_CURRENT_TANTOKAIGI,
  RIHABILI_ERROR,
  RIHABILI_KEEP_VALUE_SEARCH,
  RIHABILI_LOADALL_SUCESS,
  RIHABILI_LOAD_KYOMI_BY_ID_SUCESS,
  RIHABILI_LOAD_KYOMI_SUCESS,
  RIHABILI_LOAD_LIFE_RIYOU_SUCESS,
  RIHABILI_LOAD_LIFE_SUCESS,
  RIHABILI_LOAD_PROCESS_BY_ID_SUCESS,
  RIHABILI_LOAD_PROCESS_SUCESS,
  RIHABILI_LOAD_TANTOKAIGI_BY_ID_SUCESS,
  RIHABILI_LOAD_TANTOKAIGI_SUCESS,
  RIHABILI_REQUEST,
  SAVE_KAIGI_TEMPLATE_SUCCESS,
  UPDATE_TANTOKAIGI_SUCESS
} from "../../actions/rihabiliActions/actionNames";

import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

const INITIAL_STATE = {
  metaDocs: {
    page: 1,
    pageSize: 999,
    total: 0,
  },
  id: null,
  loading: false,
  keepValue: null,
};

export default function rihabiliReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RIHABILI_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RIHABILI_ERROR:
      openNotificationWithIcon(
        "error",
        action.error ? action.error.message : action.error
      );
      return {
        ...state,
        loading: false,
      };

    case RIHABILI_LOADALL_SUCESS: {
      return {
        ...state,
        dataDocs: action.data,
        metaDocs: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };
    }

    case RIHABILI_LOAD_LIFE_SUCESS: {
      return {
        ...state,
        dataDocs: action.data,
        metaDocs: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };
    }

    case RIHABILI_LOAD_LIFE_RIYOU_SUCESS: {
      return {
        ...state,
        dataRiyou: action.data,
        loading: false,
        needReload: null,
      };
    }

    //興味関心チェック
    case RIHABILI_LOAD_KYOMI_SUCESS: {
      return {
        ...state,
        dataRiyou: action.data,
        kyomiDatas: action.data,
        loading: false,
        needReload: null,
      };
    }
    case RIHABILI_LOAD_KYOMI_BY_ID_SUCESS: {
      return {
        ...state,
        kyomiData: action.data,
        loading: false,
      };
    }
    case RESET_CURRENT_KYOMI: {
      return {
        ...state,
        kyomiData: {},
        loading: false,
      };
    }
    case CREATE_KYOMI_SUCESS: {
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        kyomiData: action.data,
        createKyomi: action.data,
        loading: false,
      };
    }

    case CREATE_LIFE_SUCESS: {
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        loading: false,
      };
    }

    //リハビリ会議
    case RIHABILI_LOAD_TANTOKAIGI_SUCESS: {
      return {
        ...state,
        dataRiyou: action.data,
        tantoKaigis: action.data,
        loading: false,
        needReload: null,
      };
    }
    case RIHABILI_LOAD_TANTOKAIGI_BY_ID_SUCESS: {
      return {
        ...state,
        tantoKaigi: action.data,
        loading: false,
      };
    }
    case RESET_CURRENT_TANTOKAIGI: {
      return {
        ...state,
        tantoKaigi: {},
        loading: false,
      };
    }
    case CREATE_TANTOKAIGI_SUCESS: {
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        tantoKaigi: action.data,
        createTantoKaigi: action.data,
        loading: false,
      };
    }
    case UPDATE_TANTOKAIGI_SUCESS: {
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        tantoKaigi: action.data,
        updateTantoKaigi: action.data,
        loading: false,
      };
    }
    case SAVE_KAIGI_TEMPLATE_SUCCESS: {
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        loading: false,
      };
    }
    case LOAD_KAIGI_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    //リハプロセス
    case RIHABILI_LOAD_PROCESS_SUCESS: {
      return {
        ...state,
        dataRiyou: action.data,
        processDatas: action.data,
        loading: false,
        needReload: null,
      };
    }
    case RIHABILI_LOAD_PROCESS_BY_ID_SUCESS: {
      return {
        ...state,
        processData: action.data,
        loading: false,
      };
    }
    case RESET_CURRENT_PROCESS: {
      return {
        ...state,
        processData: {},
        loading: false,
      };
    }
    case CREATE_PROCESS_SUCESS: {
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        processData: action.data,
        createProcess: action.data,
        loading: false,
      };
    }
    case GET_SETTING_MASTER_PROCESS_SUCESS: {
      return {
        ...state,
        processNaiyou: action.data,
        loading: false,
      };
    }
    case PROCESS_LOAD_KAIGI_REQUEST: {
      return {
        ...state,
        processKaigiLoading: true,
      };
    }
    case PROCESS_LOAD_KAIGI_SUCESS: {
      return {
        ...state,
        processKaigiLoading: false,
        processKaigiData: action.data,
      };
    }

    case RIHABILI_KEEP_VALUE_SEARCH:
      return {
        ...state,
        keepValue: action.keepValue,
      };

    default:
      return {
        ...state,
      };
  }
}
