import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import {
  LOAD_REPORT_ADL_SUCCESS,
  LOAD_REPORT_ADL_ERROR,
  DELETE_REPORT_ADL_SUCCESS,
  DELETE_REPORT_ADL_ERROR,
  LOAD_REPORT_ADL_BY_ID_SUCCESS,
  LOAD_REPORT_ADL_BY_ID_ERROR,
  CREATE_REPORT_ADL_SUCCESS,
  CREATE_REPORT_ADL_ERROR,
  UPDATE_REPORT_ADL_SUCCESS,
  UPDATE_REPORT_ADL_ERROR,
  RESET_CURRENT_REPORT_ADL,
  LOAD_REPORT_ADL_SPIN,
  KEEP_VALUE_SEARCH,
  GET_LIST_IDS_REPORT_ADL,
  GET_SETTING_MASTER_SUCCESS,
  GET_ADL_MASTER_SUCCESS,
} from "../../actions/reportAdlActions/actionName";

//デフォルトのstate
const initialState = {
  adlsByDate: [],
  metaReportAdlByDate: {
    page: 1,
    pageSize: 20,
    total: 0,
  },
  keepValue: {},
  loading: false,
  adl: {},
  adls: [],
  createdAdl: {},
  updatedAdl: {},
};

export default function reportAdl(state = initialState, action) {
  switch (action.type) {
    //spin true
    case LOAD_REPORT_ADL_SPIN:
      return {
        ...state,
        loading: true,
      };
    //settingMaster読み込み成功
    case GET_SETTING_MASTER_SUCCESS:
      return {
        ...state,
        listName: action.listName,
        loading: false,
      };
    //adlMaster読み込み成功
    case GET_ADL_MASTER_SUCCESS:
      return {
        ...state,
        adlMaster: action.adlMaster,
        loading: false,
      };
    //List<reportAdl>読み込み成功
    case LOAD_REPORT_ADL_SUCCESS:
      return {
        ...state,
        adls: action.adls,
        metaTantoKaigiByDate: {
          //   page: action.meta.page,
          //   pageSize: action.meta.pageSize,
          //   total: action.meta.total,
        },
        loading: false,
      };
    //reportAdl(単)読み込み失敗
    case LOAD_REPORT_ADL_ERROR:
      return {
        ...state,
        loading: false,
      };
    //reportAdl(単)読み込み成功
    case LOAD_REPORT_ADL_BY_ID_SUCCESS:
      return {
        ...state,
        adl: action.adl,
        loading: false,
      };

    case CREATE_REPORT_ADL_SUCCESS:
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        loading: false,
        createAdl: action.createAdl,
        adl: action.createAdl,
      };
    case UPDATE_REPORT_ADL_SUCCESS:
      openNotificationWithIcon("success", "保存しました", action.callback);
      return {
        ...state,
        loading: false,
        updateAdl: action.updateAdl,
        adl: action.updateAdl,
      };
    case DELETE_REPORT_ADL_SUCCESS:
      openNotificationWithIcon("success", "削除しました", null);
      return {
        ...state,
        deleteAdl: action.deleteAdl,
        loading: false,
      };
    case LOAD_REPORT_ADL_BY_ID_ERROR:
    case CREATE_REPORT_ADL_ERROR:
    case UPDATE_REPORT_ADL_ERROR:
    case DELETE_REPORT_ADL_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case RESET_CURRENT_REPORT_ADL:
      return {
        ...state,
        adl: null,
        // createAdl: null,
        // updateAdl: {}
      };
    case KEEP_VALUE_SEARCH:
      return {
        ...state,
        keepValue: action.keepValue,
      };
    case GET_LIST_IDS_REPORT_ADL: {
      return {
        ...state,
        loading: false,
        adlIds: action.ids,
      };
    }
    default:
      return state;
  }
}
