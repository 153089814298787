import { Col, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import {
  changeExpandedRowKey,
  getDetailKyotakuPlan2,
  getRowInfolKyotakuPlan2,
  setRowKyotakuPlan2,
} from "../../../actions/kyotakuPlan2Actions/actionCreators";
import RowContent from "./RowContent";
import RowInfo from "./RowInfo";

// import Rowcontent from "./RowContent";
// import { deleteRiyousyaById } from "../../../actions/documentListActions/actionCreators";
// import { connect } from "react-redux";

class editTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: [],
      deleteVisible: false,
      checked: false,
      expandedRowKeys: [],
      edit: false,
      recordContent: null,
    };
  }
  handleExpand(expanded, record) {
    if (record) {
      if (this.props.rowInformation && this.props.expandedRowKeys) {
        this.props.setRowKyotakuPlan2(
          this.props.expandedRowKeys,
          this.props.rowInformation
        );
      }

      this.props.getDetailKyotakuPlan2(record.details);
      this.props.getRowInfolKyotakuPlan2(record);
      expanded
        ? this.props.changeExpandedRowKey(record.sortNum)
        : this.props.changeExpandedRowKey(null);
    }
    else {
      this.setState({
        expandedRowKeys: null,
        recordContent: null,
      });
    }
  }

  render() {
    const {
      kyotakuPlan2Rows,
      rowInformation,
      expandedRowKeys,
      editDetail,
      settingData,
    } = this.props;
    const columns = [
      {
        title: "Content",
        dataIndex: "content",
      },
    ];
    const tableData = [];
    if (kyotakuPlan2Rows) {
      kyotakuPlan2Rows.map((info, index) => {
        tableData.push({
          content: (
            <RowContent
              info={info}
              indexRow={index}
              handleExpand={(expanded, record) =>
                this.handleExpand(expanded, record)
              }
            />
          ),
        });
      });
    }
    return (
      <div id={"table-kyotaku-plan2"}>
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) =>
            matches ? (
              <Row className="show-header-table">
                <Col sx={1} ms={1} md={1} style={{ textAlign: "center", backgroundColor: "#fff" }}>
                  <span className="text-bold pr-1">No</span>
                </Col>
                <Col
                  sx={23}
                  ms={23}
                  md={11}
                  className="row-plan-2"
                  style={{ textAlign: "center", backgroundColor: "#fff" }}
                >
                  <span className="text-bold pr-1">
                    生活全般の解決すべき課題
                  </span>
                </Col>
                <Col
                  sx={23}
                  ms={23}
                  md={6}
                  className="row-plan-2"
                  style={{ textAlign: "center", backgroundColor: "#fff" }}
                >
                  <span className="text-bold pr-1">長期目標</span>
                </Col>
                <Col
                  sx={20}
                  ms={23}
                  md={6}
                  className="row-plan-2"
                  style={{ textAlign: "center", backgroundColor: "#fff" }}
                >
                  <span className="text-bold pr-1">短期目標</span>
                </Col>
              </Row>
            ) : (
              ""
            )
          }
        </MediaQuery>
        <Table
          rowKey={"id"}
          showHeader={false}
          columns={columns}
          dataSource={tableData}
          size="small"
          style={editDetail ? { display: "none" } : {}}
          bordered
        />
        <MediaQuery minDeviceWidth={editDetail ? 0 : 768}>
          {expandedRowKeys ? (
            <RowInfo record={rowInformation || {}} settingData={settingData} />
          ) : (
            <div />
          )}
        </MediaQuery>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kyotakuPlan2Rows: state.kyotakuPlan2.kyotakuPlan2Rows,
    rowInformation: state.kyotakuPlan2.rowInformation,
    expandedRowKeys: state.kyotakuPlan2.expandedRowKeys,
    editDetail: state.kyotakuPlan2.editDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailKyotakuPlan2: (data) => dispatch(getDetailKyotakuPlan2(data)),
    getRowInfolKyotakuPlan2: (data) => dispatch(getRowInfolKyotakuPlan2(data)),
    changeExpandedRowKey: (data) => dispatch(changeExpandedRowKey(data)),
    setRowKyotakuPlan2: (expandedRowKeys, data) =>
      dispatch(setRowKyotakuPlan2(expandedRowKeys, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(editTable);
