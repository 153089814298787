/* eslint-disable object-curly-spacing */
/* eslint-disable no-nested-ternary */
import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu, isEmpty } from "../common/function_common/functionCommon";

// function decodeToken() {
//   return axios(apiUrls.INIT + apiUrls.DECODE_TOKEN, {
//     method: 'GET',
//   }).then((res) => {
//     return res.data;
//   }).catch((err) => {
//     if (!err.response) {
//       // network error
//       window.location.reload();
//     } else {
//       return err.response;
//     }
//   });
// }

/**
 *
 * @param {*} parameterObj
 * parameterObj.tabName: active tabName
 * parameterObj.isPropData: if propData loaded, return false and reuse
 * parameterObj.isLoadBasic: check if have to reload riyousya info again
 *
 *
 */
const LoadDataApi = {
  loadDataApi(params) {
    const lisApis = [];
    if (params) {
      lisApis.push(getVitalRecord(params));

      lisApis.push(getMealRecord(params));

      lisApis.push(getSuibunRecord(params));

      lisApis.push(getHaisetuRecord(params));

      lisApis.push(getNyuyokuRecord(params));

      lisApis.push(getFukuyakuRecord(params));

      lisApis.push(getKaigonaiyoRecord(params));

      lisApis.push(getTokkiRecord(params));

      lisApis.push(getGaizouRecord(params));
    }

    lisApis.push(getDailyPlan(params));

    lisApis.push(getMealDailyPlan(params));

    return Promise.all(lisApis)
      .then(
        axios.spread(function (
          vital,
          meal,
          suibun,
          haisetu,
          nyuyoku,
          fukuyaku,
          kaigo,
          tokki,
          gazou,
          youtei,
          mealYotei
        ) {
          // console.log(vital)
          // console.log(meal)
          // console.log(suibun)
          // console.log(haisetu)
          // console.log(nyuyoku)
          // console.log(fukuyaku)
          // console.log(kaigo)
          // console.log(tokki)
          // console.log(gazou)

          return {
            vital,
            meal,
            suibun,
            haisetu,
            nyuyoku,
            fukuyaku,
            kaigo,
            tokki,
            gazou,
            youtei,
            mealYotei,
          };
        })
      )
      .catch((err) => {
        clearLocalStorage(err);
      });
  },
};

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getDailyPlan(params) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.NYUTAISHO_DAILYPLAN, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        mainSID: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: params[9],
        startDate: params[10],
        endDate: params[10],
        startTime: params[11],
        endTime: params[11],
        tabName: "提供記録",
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

async function getMealDailyPlan(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.NYUTAISHO_DAILYPLAN, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        mainSID: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds: params[9],
        startDate: params[10],
        endDate: params[10],
        startTime: params[11],
        endTime: params[11],
        tabName: "食事摂取",
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 * get record data
 *
 * @param {obj} parameterObj
 */
async function getVitalRecord(param) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: param[9],
        startDate: param[10],
        endDate: param[10],
        startTime: param[11],
        endTime: param[11],
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getMealRecord(param) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: param[9],
        startDate: param[10],
        endDate: param[10],
        startTime: param[11],
        endTime: param[11],
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getSuibunRecord(param) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: param[9],
        startDate: param[10],
        endDate: param[10],
        startTime: param[11],
        endTime: param[11],
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getHaisetuRecord(param) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: param[9],
        startDate: param[10],
        endDate: param[10],
        startTime: param[11],
        endTime: param[11],
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getNyuyokuRecord(param) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: param[9],
        startDate: param[10],
        endDate: param[10],
        startTime: param[11],
        endTime: param[11],
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getFukuyakuRecord(param) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: param[9],
        startDate: param[10],
        endDate: param[10],
        startTime: param[11],
        endTime: param[11],
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
// function getKaigonaiyoRecordNew(riyousyaIds, date, time) {
//   return axios(apiUrls.INIT + apiUrls.REPORT_KAIGONAIYOU, {
//     method: 'POST',
//     data: {
//       systemControlName: JSON.parse(localStorage.getItem('menu')).sn,
//       systemControlId: JSON.parse(localStorage.getItem(key)).si,
//       riyousyaIds: riyousyaIds.toString(),
//       startDate: date,
//       endDate: date,
//       startTime: time,
//       endTime: time
//     }
//   }).then((res) => {
//     return res.data;
//   }).catch((err) => {
//     clearLocalStorage(err)
//   });
// }

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getKaigonaiyoRecord(param) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGONAIYOU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: param[9],
        startDate: param[10],
        endDate: param[10],
        startTime: param[11],
        endTime: param[11],
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getTokkiRecord(param) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: param[9],
        startDate: param[10],
        endDate: param[10],
        startTime: param[11],
        endTime: param[11],
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getGaizouRecord(param) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: param[9],
        startDate: param[10],
        endDate: param[10],
        startTime: param[11],
        endTime: param[11],
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 */
function clearLocalStorage(err) {
  /**
   * @TODO handle error
   */
  if (!err.response) {
    // network error
  }
  else {
    // Check Token Exist
    if (err.response.status === 403) {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("doubleLogin");
      localStorage.removeItem("getAvatarResponse");
      localStorage.removeItem("getAvatarResponseData");
      window.location.replace("/login");
    }

    return err;
  }
}

/**
 * Save Plan To Record
 *
 */
const SaveYouteiToRecord = {
  saveYouteiToRecord(item) {
    return Promise.all([
      // kaigo
      !isEmpty(item.kaigo) ? kaigoInsertUpdate(item.kaigo) : {},
    ])
      .then(
        axios.spread(function (kaigo) {
          return kaigo;
        })
      )
      .catch((err) => {
        clearLocalStorage(err);
      });
  },
};

/**
 *
 * @param {*} parameterObj
 * parameterObj.tabName: active tabName
 * parameterObj.isPropData: if propData loaded, return false and reuse
 * parameterObj.isLoadBasic: check if have to reload riyousya info again
 *
 *
 */
const SaveDataApi = {
  saveDataApi(parameterObj) {
    return Promise.all([
      // vital
      !isEmpty(parameterObj.vital) && parameterObj.vital.vitalId
        ? vitalUpdate(parameterObj.vital)
        : !isEmpty(parameterObj.vital)
          ? vitalInsert(parameterObj.vital)
          : {},

      // meal
      !isEmpty(parameterObj.meal) && parameterObj.meal.mealId
        ? mealUpdate(parameterObj.meal)
        : !isEmpty(parameterObj.meal)
          ? mealInsert(parameterObj.meal)
          : {},

      // suibun
      !isEmpty(parameterObj.suibun) ? suibunInsertUpdate(parameterObj.suibun) : {},
      // !isEmpty(parameterObj.suibun) && parameterObj.suibun.suibunId
      //   ? suibunUpdate(parameterObj.suibun)
      //   : !isEmpty(parameterObj.suibun)
      //     ? suibunInsert(parameterObj.suibun)
      //     : {},

      // hainyou
      !isEmpty(parameterObj.hainyou) && parameterObj.hainyou.haisetuId
        ? haisetuUpdate(parameterObj.hainyou)
        : !isEmpty(parameterObj.hainyou)
          ? haisetuInsert(parameterObj.hainyou)
          : {},

      // haiben
      !isEmpty(parameterObj.haiben) && parameterObj.haiben.haisetuId
        ? haisetuUpdate(parameterObj.haiben)
        : !isEmpty(parameterObj.haiben)
          ? haisetuInsert(parameterObj.haiben)
          : {},

      // nyuyoku
      !isEmpty(parameterObj.nyuyoku) && parameterObj.nyuyoku.nyuyokuId
        ? nyuyokuUpdate(parameterObj.nyuyoku)
        : !isEmpty(parameterObj.nyuyoku)
          ? nyuyokuInsert(parameterObj.nyuyoku)
          : {},

      // fukuyaku
      !isEmpty(parameterObj.fukuyaku) ? fukuyakuInsertUpdate(parameterObj.fukuyaku) : {},
      // !isEmpty(parameterObj.fukuyaku) && parameterObj.fukuyaku.fukuyakuId
      //   ? fukuyakuUpdate(parameterObj.fukuyaku)
      //   : !isEmpty(parameterObj.fukuyaku)
      //     ? fukuyakuInsert(parameterObj.fukuyaku)
      //     : {},

      // kaigo
      !isEmpty(parameterObj.kaigo) ? kaigoInsertUpdate(parameterObj.kaigo) : {},

      // tokki
      !isEmpty(parameterObj.tokki) && parameterObj.tokki.tokkiId
        ? tokkiUpdate(parameterObj.tokki)
        : !isEmpty(parameterObj.tokki)
          ? tokkiInsert(parameterObj.tokki)
          : {},

      // gazou
      !isEmpty(parameterObj.gazou) && parameterObj.gazou.fileManagementId
        ? gazouUpdate(parameterObj.gazou)
        : !isEmpty(parameterObj.gazou)
          ? gazouInsert(parameterObj.gazou)
          : {},
    ])
      .then(
        axios.spread(function (
          vital,
          meal,
          suibun,
          hainyou,
          haiben,
          nyuyoku,
          fukuyaku,
          kaigo,
          tokki,
          gazou
        ) {
          if (parameterObj.paramReload) {
            return LoadDataApi.loadDataApi(parameterObj.paramReload);
          }
          return {
            vital,
            meal,
            suibun,
            hainyou,
            haiben,
            nyuyoku,
            fukuyaku,
            kaigo,
            tokki,
            gazou,
          };

        })
      )
      .catch((err) => {
        clearLocalStorage(err);
      });
  },
};

/**
 * Vitalの更新
 */
async function vitalUpdate(params) {
  params.vitalIds = params.vitalId;

  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_UPDATE, {
      method: "PUT",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function vitalInsert(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_INSERT, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

/**
 * Mealの更新
 */
async function mealUpdate(params) {
  params.mealIds = params.mealId;
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_UPDATE, {
      method: "PUT",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function mealInsert(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_INSERT, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

/**
 * Suibunの更新
 */
// async function suibunUpdate(params) {
//   params.suibunIds = params.suibunId;

//   try {
//     const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_UPDATE, {
//       method: "PUT",
//       data: params,
//     });
//     return res.data;
//   }
//   catch (err) {
//     if (!err.response) {
//       // network error
//       window.location.reload();
//     }
//     else {
//       return err.response;
//     }
//   }
// }

// async function suibunInsert(params) {
//   try {
//     const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_INSERT, {
//       method: "POST",
//       data: params,
//     });
//     return res.data;
//   }
//   catch (err) {
//     if (!err.response) {
//       // network error
//       window.location.reload();
//     }
//     else {
//       return err.response;
//     }
//   }
// }

async function suibunInsertUpdate(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_INSERT_UPDATE, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

/**
 * haisetuの更新
 */
async function haisetuUpdate(params) {
  params.haisetuIds = params.haisetuId;

  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_UPDATE, {
      method: "PUT",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function haisetuInsert(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_INSERT, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

/**
 * Nyuyokuの更新
 */
async function nyuyokuUpdate(params) {
  params.nyuyokuIds = params.nyuyokuId;
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_UPDATE, {
      method: "PUT",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function nyuyokuInsert(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_INSERT, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

/**
 * Fukuyakuの更新
 */
// async function fukuyakuUpdate(params) {
//   params.fukuyakuIds = params.fukuyakuId;
//   try {
//     const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_UPDATE, {
//       method: "PUT",
//       data: params,
//     });
//     return res.data;
//   }
//   catch (err) {
//     if (!err.response) {
//       // network error
//       window.location.reload();
//     }
//     else {
//       return err.response;
//     }
//   }
// }

// async function fukuyakuInsert(params) {
//   try {
//     const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_INSERT, {
//       method: "POST",
//       data: params,
//     });
//     return res.data;
//   }
//   catch (err) {
//     if (!err.response) {
//       // network error
//       window.location.reload();
//     }
//     else {
//       return err.response;
//     }
//   }
// }

async function fukuyakuInsertUpdate(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_INSERT_UPDATE, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

/**
 *
 * @TODO update by kaigoInsertUpdate function. update multiple
 */
/**
 * Kaigonaiyoの更新
 */
// function kaigoUpdate(params) {
//   params.kaigoIds = params.kaigoId;
//   return axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_UPDATE, {
//     method: 'PUT',
//     data: params
//   }).then((res) => {

//     return res.data
//   }).catch((err) => {
//     if (!err.response) {
//       // network error
//       window.location.reload();
//     } else {
//       return err.response;
//     }
//   });
// }

/**
 *
 * @TODO update by kaigoInsertUpdate function. update multiple
 */
// function kaigoInsert(params, type) {
//   return axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_INSERT, {
//     method: 'POST',
//     data: params
//   }).then((res) => {
//     return res.data;
//   }).catch((err) => {
//     if (!err.response) {
//       // network error
//       window.location.reload();
//     } else {
//       return err.response;
//     }
//   });
// }

async function kaigoInsertUpdate(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_INSERT_UPDATE, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

/**
 * Tokkiの更新
 */
async function tokkiUpdate(params) {
  params.tokkiIds = params.tokkiId;

  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_UPDATE, {
      method: "PUT",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function tokkiInsert(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_INSERT, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

/**
 * Gazouの更新
 */
async function gazouUpdate(params) {
  params.fileManagementIds = params.fileManagementId;
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_UPDATE, {
      method: "PUT",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function gazouInsert(params) {
  const formData = new FormData();
  formData.append("systemControlName", params.systemControlName);
  formData.append("systemControlId", params.systemControlId);
  formData.append("riyousyaId", params.riyousyaId);
  formData.append("tantoId", params.tantoId);
  formData.append("date", params.date);
  formData.append("time", params.time);
  // formData.append('type', '.jpg')
  // formData.append('fileName', 'fileName')
  formData.append("thumbnail", params.thumbnail ? params.thumbnail : null);
  formData.append("priorityf", params.priorityf ? params.priorityf : "0");
  formData.append("kubun", params.kubun ? params.kubun : "");
  formData.append("comment", params.comment ? params.comment : "");
  formData.append(
    "reportCaseTyuif",
    params.reportCaseTyuif ? params.reportCaseTyuif : "0"
  );
  formData.append("file", params.file);

  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_INSERT, {
      method: "POST",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

const DelRecordApi = {
  delRecordApi(type, recordId) {
    switch (type) {
      case 1:
        return vitalDelete(recordId);

      case 2:
        return mealDelete(recordId);

      case 3:
        return suibunDelete(recordId);

      case 41:
        return haisetuDelete(recordId);

      case 42:
        return haisetuDelete(recordId);

      case 5:
        return nyuyokuDelete(recordId);

      case 6:
        return fukuyakuDelete(recordId);

      case 7:
        return kaigoDelete(recordId);

      case 8:
        return tokkiDelete(recordId);

      case 9:
        return gazouDelete(recordId);

      default:
        return null;
    }
  },
};

async function vitalDelete(vitalId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_DELETE, {
      method: "DELETE",
      data: {
        vitalId: "" + vitalId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}
// end vital API

async function mealDelete(mealId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_DELETE, {
      method: "DELETE",
      data: {
        mealId: "" + mealId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function suibunDelete(suibunId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_DELETE, {
      method: "DELETE",
      data: {
        suibunId: "" + suibunId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function haisetuDelete(haisetuId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_DELETE, {
      method: "DELETE",
      data: {
        haisetuId: "" + haisetuId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function nyuyokuDelete(nyuyokuId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_DELETE, {
      method: "DELETE",
      data: {
        nyuyokuId: "" + nyuyokuId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function fukuyakuDelete(fukuyakuId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_DELETE, {
      method: "DELETE",
      data: {
        fukuyakuId: "" + fukuyakuId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function kaigoDelete(kaigoId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_DELETE, {
      method: "DELETE",
      data: {
        kaigoId: "" + kaigoId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function tokkiDelete(tokkiId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_DELETE, {
      method: "DELETE",
      data: {
        tokkiId: "" + tokkiId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function gazouDelete(fileManagementId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_DELETE, {
      method: "DELETE",
      data: {
        fileManagementId: "" + fileManagementId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

export { DelRecordApi, LoadDataApi, SaveDataApi, SaveYouteiToRecord };

