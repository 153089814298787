import { Checkbox, Col, DatePicker, Row } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import SortControl from "../../../common/component/control/SortControl";
import { ConstSet } from "../../../common/configs/constset";
import { rangePicker } from "../../../common/function_common/functionCommon";
import datePickerJp from "../../../data/datePickerJp";
import { Filter } from "./Filter";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

export class Toolbar1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: this.props.filterConditions,
    };

    // bind place
    this.canMoveDatesToRight = true;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      JSON.stringify(newProps.filterConditions) !=
      JSON.stringify(this.props.filterConditions)
    ) {
      this.setState({
        filterData: newProps.filterConditions,
      });
    }
  }

  handleSortOk(sortData) {
    const filterData = { ...this.state.filterData, sort: sortData };
    this.props.handleSortData(filterData);
    this.setState({
      filterData,
    });
  }

  handleFilterData(filterData) {
    this.setState({
      filterData,
    });
  }

  handleFilterOk() {
    this.props.handleFilterData(this.state.filterData);
  }

  handleFilterCancel() {
    this.setState({
      filterData: this.props.filterConditions,
    });
  }

  onChangeRanger = (dates, dateString) => {
    if (!dates || !Array.isArray(dates) || dates.length === 0) return;

    const startDate = dayjs(dates[0], ConstSet.DATE_FORMAT).format(
      ConstSet.DATE_FORMAT
    );
    const endDate = dayjs(dates[1], ConstSet.DATE_FORMAT).format(
      ConstSet.DATE_FORMAT
    );

    rangePicker.compareRangeMonths(
      startDate,
      endDate,
      this.handleMoveDatesToRight
    );

    this.props.handleRangePickerChange(dates, dateString);
  };

  // workaround (not cheat code), any better ideas? replaces the block below
  handleOpenRange = (isOpen) => {
    rangePicker.showDatesInRightSide(
      isOpen,
      this.canMoveDatesToRight,
      this.handleMoveDatesToRight
    );
  };

  handleMoveDatesToRight = (value) => {
    this.canMoveDatesToRight = value;
  };

  render() {
    const { riyousyaBunrui, startDate, endDate } = this.props;
    return (
      (<div className="w-100">
        <MediaQuery query="(min-device-width: 1281px)">
          {(matches) =>
            matches ? (
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 6 }}
                  style={{ paddingBottom: "5px" }}
                >
                  <RangePicker
                    // style={{ padding: "0 10px" }}
                    locale={datePickerJp}
                    allowClear={false}
                    inputReadOnly
                    readOnly
                    defaultValue={[dayjs(startDate), dayjs(endDate)]}
                    format={dateFormat}
                    onChange={this.onChangeRanger}
                    className="ru-datepicker"
                    popupClassName="kiroku-filter__datepicker-range-dropdown"
                    onOpenChange={this.handleOpenRange}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 11 }}
                  style={{ paddingBottom: "5px" }}
                >
                  <Filter
                    filterData={this.state.filterData}
                    tantoMasters={this.props.tantoMasters}
                    systemNames={this.props.systemNames}
                    nyutaishoFilterData={this.props.filterMasterData}
                    handleUpdateState={(filterData) =>
                      this.handleFilterData(filterData)
                    }
                    handleFilterOk={() => this.handleFilterOk()}
                    handleFilterCancel={() => this.handleFilterCancel()}
                    riyousyaBunrui={riyousyaBunrui}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 7 }}
                  style={{ paddingBottom: "5px" }}
                >
                  <SortControl
                    style={{ display: "inline" }}
                    sortOptions={[
                      { value: "1", display: "フリガナ" },
                      { value: "2", display: "記録日" },
                      { value: "3", display: "時間" },
                      { value: "4", display: "記録区分" },
                      { value: "10", display: "その他区分" },
                      { value: "5", display: "申し送り" },
                      { value: "6", display: "日誌" },
                      { value: "7", display: "担当者（フリガナ）" },
                      { value: "8", display: "部屋" },
                      // { value: "9", display: "部屋分類" }
                    ]}
                    sortData={this.state.filterData.sort}
                    handleSortData={(sortData) => this.handleSortOk(sortData)}
                  />
                  <Checkbox
                    style={{ paddingLeft: "10px" }}
                    checked={this.props.showMessageOnly}
                    onChange={() => this.props.handleChangeMessageCheckbox()}
                    value={this.props.showMessageOnly}
                  >
                    {"申送りのみ"}
                  </Checkbox>
                  <Checkbox
                    checked={this.props.showDailyJournalOnly}
                    onChange={() =>
                      this.props.handleChangeDailyJournalCheckbox()
                    }
                    value={this.props.showDailyJournalOnly}
                  >
                    {"日誌のみ"}
                  </Checkbox>
                  {this.props.use ? (
                    <Checkbox
                      checked={this.props.showUseOnly}
                      onChange={() => this.props.handleChangeUseCheckbox()}
                      value={this.props.showUseOnly}
                    >
                      {"利用中のみ"}
                    </Checkbox>
                  ) : null}
                </Col>
              </Row>
            ) : (
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 6 }}
                  style={{ paddingBottom: "5px" }}
                >
                  <RangePicker
                    style={{ padding: "0 10px" }}
                    allowClear={false}
                    inputReadOnly
                    readOnly
                    defaultValue={[
                      dayjs(startDate),
                      dayjs(endDate),
                    ]}
                    format={dateFormat}
                    onChange={this.onChangeRanger}
                    className="ru-datepicker"
                    popupClassName="kiroku-filter__datepicker-range-dropdown"
                    onOpenChange={this.handleOpenRange}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 11 }}
                  style={{ paddingBottom: "5px" }}
                >
                  <Filter
                    filterData={this.state.filterData}
                    tantoMasters={this.props.tantoMasters}
                    systemNames={this.props.systemNames}
                    nyutaishoFilterData={this.props.filterMasterData}
                    handleUpdateState={(filterData) =>
                      this.handleFilterData(filterData)
                    }
                    handleFilterOk={() => this.handleFilterOk()}
                    handleFilterCancel={() => this.handleFilterCancel()}
                    riyousyaBunrui={riyousyaBunrui}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 7 }}
                  style={{ paddingBottom: "5px" }}
                >
                  <SortControl
                    style={{ display: "inline" }}
                    sortOptions={[
                      { value: "1", display: "フリガナ" },
                      { value: "2", display: "記録日" },
                      { value: "3", display: "時間" },
                      { value: "4", display: "記録区分" },
                      { value: "10", display: "その他区分" },
                      { value: "5", display: "申し送り" },
                      { value: "6", display: "日誌" },
                      { value: "7", display: "担当者（フリガナ）" },
                      { value: "8", display: "部屋" },
                      // { value: "9", display: "部屋分類" }
                    ]}
                    sortData={this.state.filterData.sort}
                    handleSortData={(sortData) => this.handleSortOk(sortData)}
                  />
                  {/* <Checkbox
                  style={{ paddingLeft: "10px" }}
                  checked={this.props.showMessageOnly}
                  onChange={() => this.props.handleChangeMessageCheckbox()}
                  value={this.props.showMessageOnly}
                >{"申送りのみ"}</Checkbox>
                <Checkbox
                  checked={this.props.showDailyJournalOnly}
                  onChange={() => this.props.handleChangeDailyJournalCheckbox()}
                  value={this.props.showDailyJournalOnly}
                >{"日誌のみ"}</Checkbox>
                {
                  this.props.use
                    ?
                    <Checkbox
                      checked={this.props.showUseOnly}
                      onChange={() => this.props.handleChangeUseCheckbox()}
                      value={this.props.showUseOnly}
                    >{"利用中のみ"}</Checkbox>
                    :
                    null
                } */}
                </Col>
              </Row>
            )
          }
        </MediaQuery>
      </div>)
    );
  }
}
