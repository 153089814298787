import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";

export const NeedsItemApi = {
  async list(sid, text) {
    const res = await axios.get(apiUrls.GET_NEEDS_ITEM_LIST, {
      params: {
        sid: sid,
        code: text
      }
    });
    return res.data;
  },

  async loadMaxSort() {
    const res = await axios.get(apiUrls.GET_DOCTOR_MASTER_MAX_SORT);
    return res.data;
  },

  async loadDoctorMasterInit() {
    const res = await axios.get(apiUrls.GET_DOCTOR_MASTER_INIT);
    return res.data;
  },

  async createDoctorMaster(data) {
    const res = await axios.post(apiUrls.CREATE_DOCTOR_MASTER, data);
    return res.data;
  },

  async updateDoctorMaster(data) {
    const res = await axios.put(apiUrls.UPDATE_DOCTOR_MASTER, data);
    return res.data;
  },

  async updateList(data) {
    const res = await axios.put(apiUrls.UPDATE_LIST_DOCTOR_MASTER, data);
    return res.data;
  }
};