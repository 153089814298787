export const CommonActionTypes = {
  LIFE_KAIGODO_MASTER_LIST: "LIFE_KAIGODO_MASTER_LIST",
  GET_MEDISYOUBYOUMEI_MASTER: "GET_MEDISYOUBYOUMEI_MASTER",
  GET_IYAKUHIN_MASTER: "GET_IYAKUHIN_MASTER",
  GET_RIYOUBYOUREKI_MASTER: "GET_RIYOUBYOUREKI_MASTER",

  GET_MEDISYOUBYOUMEI: "GET_MEDISYOUBYOUMEI",
  GET_IYAKUHIN: "GET_IYAKUHIN",

  GET_DOCTORMASTER_LIST: "GET_DOCTORMASTER_LIST",
  GET_DOCTORMASTER_MAX_SORT: "GET_DOCTORMASTER_MAX_SORT",
  GET_DOCTORMASTER_INIT: "GET_DOCTORMASTER_INIT",
  CREATE_DOCTORMASTER_SUCCESS: "CREATE_DOCTORMASTER_SUCCESS",
  CREATE_DOCTORMASTER_ERROR: "CREATE_DOCTORMASTER_ERROR",
  UPDATE_DOCTORMASTER_SUCCESS: "UPDATE_DOCTORMASTER_SUCCESS",
  UPDATE_DOCTORMASTER_ERROR: "UPDATE_DOCTORMASTER_ERROR",

  GET_IRYOKIKAN_MASTER_INIT: "GET_IRYOKIKAN_MASTER_INIT",
  CREATE_IRYOKIKAN_MASTER_SUCCESS: "CREATE_IRYOKIKAN_MASTER_SUCCESS",
  CREATE_IRYOKIKAN_MASTER_ERROR: "CREATE_IRYOKIKAN_MASTER_ERROR",
  UPDATE_IRYOKIKAN_MASTER_SUCCESS: "UPDATE_IRYOKIKAN_MASTER_SUCCESS",
  UPDATE_IRYOKIKAN_MASTER_ERROR: "UPDATE_IRYOKIKAN_MASTER_ERROR",

  LOAD_COMMON_SPIN_REQUEST: "LOAD_COMMON_SPIN_REQUEST",
  GET_BYOMEIMASTER: "GET_BYOMEIMASTER",
};
