import React from "react";
import { Row, Col, Button } from "antd";
import { getValueLocalstorage } from "../../../common/function_common/functionCommon";
import { Link } from "react-router-dom";

let tidLocal = getValueLocalstorage("tid");

const ListItemContent = (props) => (
  <Row style={{ fontSize: 16, color: "#666666" }}>
    <Col span={24}>
      <Row style={{ display: "flex" }}>
        <Col style={{ display: "flex" }}>
          <p style={{ paddingRight: 16 }}>
            <strong style={{ fontWeight: "bolder", fontFamily: "arial" }}>
              ID:{" "}
            </strong>
            {props.data.id}
          </p>
          {/* <p style={{ paddingRight: 16 }}>ユーザID: {props.data.uid}</p> */}
          <p style={{ paddingRight: 16 }}>
            <strong style={{ fontWeight: "bolder", fontFamily: "arial" }}>
              TID:{" "}
            </strong>
            {props.data.tid}
          </p>
          <p>名前: {props.data.usersName}</p>
        </Col>
        <Col style={{ display: "flex", marginLeft: "auto" }}>
          <p>追加権限: {props.data.addAuth}</p>
          <p>制限: {props.data.riyousyaAccessCtrl}</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <p style={{ wordBreak: "break-all" }}>権限: {props.data.usageAuth}</p>
        </Col>
      </Row>

      <Row style={{ textAlign: "right", float:'right' }}>
        <Button
          type="primary"
          disabled={
            props.userName.sysMnAuth === 1 &&
            props.data.tid !== parseInt(tidLocal)
              ? false
              : true
          }
          style={{ marginRight: 16 }}
          onClick={props.deleteUser.bind(this, props.data.id)}
        >
          削除
        </Button>
        <Link to={`${props.path}/user-management/${props.data.id}`}>
          <Button
            type="primary"
            disabled={
              props.data.tid === parseInt(tidLocal) ||
              props.userName.sysMnAuth === 1
                ? false
                : true
            }
          >
            編集
          </Button>
        </Link>
      </Row>
    </Col>
  </Row>
);

export default ListItemContent;
