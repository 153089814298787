import queryString from "query-string";
import { StringUtils } from "./string";

/**
 * Convert object to query string
 * @param {object} parsedQuery
 * @returns {string}
 *
 * @example
 * parsedQuery: object = { foo: 4, bar: 3 }
 * UrlUtils.toQueryString(parsedQuery)
 *
 * return '?foo=4&bar=3'
 *
*/
function toQueryString(parsedQuery) {
  if (!parsedQuery || Object.keys(parsedQuery).length === 0) return "";

  const options = {
    skipNull: true,
    skipEmptyString: true
  };
  return "?" + queryString.stringify(parsedQuery, options);
}

/**
 * Get full api url
 * @param {object} args
 * @param {string} args.path
 * @param {object} args.params
 * @param {object} args.query
 * @returns {string}
 *
 * @example
 * path: string = '/posts/:id'
 * params: object = { id: 13 }
 * query: object = { foo: 4, bar: 3 }
 * getUrl({ path, params, query })
 *
 * return '/posts/13?foo=4&bar=3'
 *
*/
function getUrl({ path, params, query }) {
  const url = StringUtils.replace(path, params);
  const queryString = toQueryString(query);

  return url + queryString;
}

/**
 * Parse window.location.search to object
 * @param {string} search
 * @returns {object}
 *
 * @example
 * search: string = '?foo=4&bar=3'
 * UrlUtils.parse(search)
 *
 * return { foo: 4, bar: 3 }
 *
*/
function parse(search) {
  return queryString.parse(search);
}

/**
 * Replace current path with another path without reload page
 * @param {any} history
 * @param {object} query
 * @param {string} pathname
 *
 * @example
 * current path: 'example.com/hello'
 *
 * history: History (react-router-dom history)
 * query: object = { foo: 4, bar: 3 }
 * pathname: string = '/hello'
 * updateQueryString(history, query, pathname)
 *
 * result: the path become: 'example.com/hello?foo=4&bar=3'
 *
*/
function updateQueryString(history, query, pathname) {
  const search = toQueryString(query);
  history.replace({ pathname, search });
}

export const UrlUtils = {
  toQueryString,
  getUrl,
  parse,
  updateQueryString
};
