import { Form } from "@ant-design/compatible";
import { Button, Col, DatePicker, Divider, Modal, Row } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Component } from "react";
import { ConstSet } from "../../../common/configs/constset";
import ServiceKikanSelect from "./ServiceKikanSelect";
import TextAreaSelect from "./TextAreaSelect";
import TextInput from "./TextInput";
import TextInputArea from "./TextInputArea";

class FormServiceNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
    };
  }

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouService",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  searchServiceKikanMaster = (bunrui, name) => {
    this.props.searchServiceKikanMaster(bunrui, name);
  };

  render() {
    const { visible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const {
      index,
      kikanNameList,
      hindoList,
      stateList,

      serviceSyuruiMaster,
      serviceKikanMaster,
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {index && index > 0 ? (
          <Row style={{ padding: "8px 0px" }}>
            <Divider />
            <Divider />
            <Divider />
          </Row>
        ) : null}
        <Row className="title-inline">
          <h3
            style={{ background: "#d9d9d9", padding: "4px 6px" }}
          >{`サービス ${index + 1}`}</h3>
          <Button
            style={{ background: "#777", color: "#fff" }}
            onClick={this.showDeleteConfirm}
          >
            削除
          </Button>
        </Row>
        <Divider />
        <Divider />
        <Form.Item label={"機関名"} colon={false}>
          {getFieldDecorator("kikanName", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <ServiceKikanSelect
              dataSelect={kikanNameList}
              dataMaster={serviceKikanMaster}
              dataExt={serviceSyuruiMaster}
              search={this.searchServiceKikanMaster}
              rowSet={1}
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"担当者"} colon={false}>
          {getFieldDecorator("tantoName", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextInput />)}
        </Form.Item>
        <Divider />
        <Row>
          <Col xs={24} sm={12}>
            <Form.Item label={"開始日"}>
              {getFieldDecorator("kaishiDate", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<DatePicker style={{ width: "150px" }} />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label={"利用頻度"}>
              {getFieldDecorator("hindo", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <TextAreaSelect
                  isMaster={false}
                  isSelect={true}
                  isOveride={true}
                  rowSet={1}
                  dataSelect={hindoList}
                  yougoMasterUpdate={(naiyou) =>
                    this.yougoMasterUpdate("機関利用頻度", naiyou)
                  }
                  dataMasterType={"serviceKikan"}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Form.Item label={"状況"} colon={false}>
          {getFieldDecorator("state", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              isOveride={true}
              rowSet={1}
              dataSelect={stateList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("機関利用状況", naiyou)
              }
              dataMasterType={"serviceKikan"}
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"連絡先"} colon={false}>
          {getFieldDecorator("contact", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextInputArea />)}
        </Form.Item>
        <Divider />
        <Form.Item label={"備考"} colon={false}>
          {getFieldDecorator("bikou", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextInputArea />)}
        </Form.Item>
        <Divider />
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
      </Form>)
    );
  }
}

export const FormService = Form.create({
  name: "input-riyou-service",
  onValuesChange(props, changedValues, allValues) {
    // @TODO convert checkbox group to value
    const tmp = _.cloneDeep(allValues);
    if (tmp.kaishiDate) {
      tmp.kaishiDate = allValues.kaishiDate.format(ConstSet.DATE_FORMAT);
    }

    props.saveOnchangeToState(tmp, "fService", props.index);
  },
  mapPropsToFields(props) {
    let dateTime = new Date();
    if (props.data && props.data.kaishiDate) {
      dateTime = new Date(props.data.kaishiDate);
    }

    return {
      kikanName: Form.createFormField({
        value: props.data ? props.data.kikanName : null,
      }),
      tantoName: Form.createFormField({
        value: props.data ? props.data.tantoName : null,
      }),
      kaishiDate: Form.createFormField({
        value: dayjs(dateTime),
      }),
      hindo: Form.createFormField({
        value: props.data ? props.data.hindo : null,
      }),
      state: Form.createFormField({
        value: props.data ? props.data.state : null,
      }),
      contact: Form.createFormField({
        value: props.data ? props.data.contact : null,
      }),
      bikou: Form.createFormField({
        value: props.data ? props.data.bikou : null,
      }),
    };
  },
})(FormServiceNormal);
