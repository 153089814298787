import {
  FileDoneOutlined,
  HomeOutlined,
  LinkOutlined,
  LogoutOutlined,
  MessageOutlined,
  OrderedListOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TableOutlined,
  TeamOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

import {
  Badge,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Input,
  Layout,
  List,
  Menu,
  Modal,
  Row,
} from "antd";
import React from "react";
import IdleTimer from "react-idle-timer";

// import KanriHeader from './KanriHeader';
import { connect } from "react-redux";

import {
  logoutCreators,
  removeSocket,
} from "../../../actions/commonActions/actionCreators";

import { white } from "material-ui/styles/colors";
import { Link } from "react-router-dom";
import getAvatar from "../../../actions/getAvatarActions/actionCreators";
import { loadReportCasesStartup } from "../../../actions/progressActions/actionCreators";
import {
  getPath,
  getValueLocalstorage,
  localStorageClearCus,
} from "../../../common/function_common/functionCommon";


const { Header, Content, Sider } = Layout;
const { Search } = Input;

const bell = [
  <svg
    width="32"
    height="32"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
      fill="#00838f"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#00838f"
    ></path>
  </svg>,
];

const clockicon = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
      fill="#111827"
    ></path>
  </svg>,
];

const wifi = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 107 107"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="logo-spotify" fill="#2EBD59" fillRule="nonzero">
        <path
          d="M53.5,0 C23.9517912,0 0,23.9517912 0,53.5 C0,83.0482088 23.9517912,107 53.5,107 C83.0482088,107 107,83.0482088 107,53.5 C107,23.9554418 83.0482088,0.00365063118 53.5,0 Z M78.0358922,77.1597407 C77.0757762,78.7368134 75.0204708,79.2296486 73.4506994,78.2695326 C60.8888775,70.5922552 45.0743432,68.8582054 26.4524736,73.1111907 C24.656363,73.523712 22.8675537,72.3993176 22.458683,70.6032071 C22.0461617,68.8070966 23.1669055,67.0182873 24.9666667,66.6094166 C45.3444899,61.9548618 62.8273627,63.9590583 76.9297509,72.5745479 C78.4995223,73.5419652 78.9996588,75.5899693 78.0358922,77.1597407 L78.0358922,77.1597407 Z M84.5814739,62.5973729 C83.373115,64.5614125 80.8030706,65.1747185 78.8426817,63.9700102 C64.4664961,55.1318321 42.5408052,52.5727397 25.5325145,57.7347322 C23.3275333,58.4027977 20.9984306,57.1579324 20.3267144,54.9566018 C19.6622996,52.7516206 20.9071648,50.4261685 23.1084954,49.7544524 C42.5371546,43.858683 66.6933811,46.7134766 83.2051859,56.8622313 C85.1692255,58.0705902 85.7898328,60.636984 84.5814739,62.5973729 Z M85.1436711,47.4253497 C67.8980894,37.1853292 39.4523712,36.2434664 22.9880246,41.2375299 C20.3449676,42.0406687 17.5485841,40.5475606 16.7490959,37.9045036 C15.9496076,35.2614466 17.4390652,32.4650631 20.0857728,31.6619243 C38.9850904,25.9267827 70.3987718,27.0329239 90.2509041,38.8171614 C92.627465,40.2299556 93.4087001,43.3001365 91.9995565,45.6730467 C90.5940635,48.0532583 87.5165814,48.838144 85.1436711,47.4253497 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>,
];

const credit = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fill="#1890FF"
      d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
    ></path>
    <path
      fill="#1890FF"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
    ></path>
  </svg>,
];

const data = [
  {
    title: "New message from Sophie",
    description: <>{clockicon} 2 days ago</>,

    avatar: null,
  },
  {
    title: "New album by Travis Scott",
    description: <>{clockicon} 2 days ago</>,

    avatar: null,
  },
  {
    title: "Payment completed",
    description: <>{clockicon} 2 days ago</>,
    avatar: null,
  },
];


const menu = (
  <List
    min-width="100%"
    className="header-notifications-dropdown "
    itemLayout="horizontal"
    dataSource={data}
  // renderItem={(item) => (
  //   <List.Item>
  //     <List.Item.Meta
  //       avatar={<Avatar shape="square" src={item.avatar} />}
  //       title={item.title}
  //       description={item.description}
  //     />
  //   </List.Item>
  // )}
  />
);

const mapStateToProps = (state) => {
  return {
    preventLogin: state.messageList.preventLogin,
    socket: state.messageList.socket,
    getAvatarResponse: state.getAvatar.data,
    moshiokuri: state.progress.moshiokuri,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // initializeSocket: () => dispatch(initializeSocket()),
    logout: (isLoginPage) => dispatch(logoutCreators(isLoginPage)),
    closeSocket: () => dispatch(removeSocket()),
    getAvatar: () => dispatch(getAvatar()),
    getReportCasesStartup: () => dispatch(loadReportCasesStartup()),
  };
};

export class KanriLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isTimeout: false,
    };

    this.idleTimer = null;
    this.onIdle = KanriLayout._onIdle.bind(this);

    this.logout = this.logout.bind(this);
  }

  /**
   * User Active
   * @param {*} e
   */
  static _onActive(e) {
    // User interaction
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  /**
   * Idle Time
   * @param {*} e
   */
  static _onIdle(e) {
    // localStorage.removeItem('jwtToken');
    localStorage.removeItem("doubleLogin");
    localStorage.removeItem("getAvatarResponse");
    localStorage.removeItem("getAvatarResponseData");
    if (!this.state.isTimeout) {
      this.setState({
        visible: true,
        isTimeout: true,
      });
      this.props.closeSocket();
      this.props.logout();
    }
  }

  async componentDidMount() {
    if (this.props.socket) {
      this.props.removeSocket;
    }
    await this.props.getReportCasesStartup();

    if (!this.props.getAvatarResponse) {
      this.props.getAvatar();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.preventLogin && !this.state.isTimeout) {
      this.setState({
        visible: true,
        isTimeout: true,
      });
      this.props.closeSocket();
      this.props.logout();
    }
  }

  // Close Popup double login
  handleOk() {
    this.setState({
      visible: false,
    });
  }

  async logout() {
    await this.props.logout(true);
  }

  render() {
    const { getAvatarResponse, router } = this.props;

    const path = getPath();

    /** Get Avatar */
    let getAvatar = false;
    let getAvatarResponseData = "";
    if (getAvatarResponse) {
      if (getAvatarResponse.data) {
        getAvatar = true;
        getAvatarResponseData = getAvatarResponse.data;
      }
      else if (getAvatarResponse.response) {
        if (getAvatarResponse.response.data) {
          if (getAvatarResponse.response.data.error) {
            if (
              getAvatarResponse.response.data.error.code === "INVALID_TOKEN"
            ) {
              localStorageClearCus();

              window.location.href = path + "/login";
            }
          }
        }
      }
    }

    // client security
    const token = localStorage.getItem("jwtToken");
    if (token) {
      const jwtDecode = require("jwt-decode");
      const userName = jwtDecode(token).userInfo.name;

      if (userName !== "kanri" && !getValueLocalstorage("mode")) {
        window.location.href = path + "/dengon";
      }
    }

    return (
      (<IdleTimer
        ref={(ref) => {
          this.idleTimer = ref;
        }}
        element={document}
        onIdle={this.onIdle}
        timeout={this.props.sessionTimeout}
      >
        <Layout id="kanri-page">
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              // console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              // console.log(collapsed, type);
            }}
            style={{ background: white }}
          >
            <div className="logo">
              <Link to={path + "/kanri/dashboard"}>
                <h2><strong>管理画面</strong></h2>
              </Link>
            </div>
            <Menu mode="inline" selectedKeys={[router]}>
              <Menu.Item key={"dashboard"}>
                <Link to={path + "/kanri/dashboard"}>
                  <HomeOutlined />
                  <span className="nav-text">管理画面</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"table"}>
                <Link to={path + "/kanri/table"}>
                  <TableOutlined />
                  <span className="nav-text">パレット管理</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"user"}>
                <Link to={path + "/kanri/user"}>
                  <UserOutlined />
                  <span className="nav-text">ユーザ管理</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="userriyousyalink">
                <Link to={path + "/kanri/userriyousyalink"}>
                  <LinkOutlined />
                  <span className="nav-text">ユーザリンク</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="tantomaster">
                <Link to={path + "/kanri/tantomaster"}>
                  <TeamOutlined />
                  <span className="nav-text">担当者マスタ</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"settingmaster"}>
                <Link to={path + "/kanri/settingmaster"}>
                  <SettingOutlined />
                  <span className="nav-text">セッティングマスタ</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"yougomaster"}>
                <Link to={path + "/kanri/yougomaster"}>
                  <ToolOutlined />
                  <span className="nav-text">用語マスタ</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"lifeyougomaster"}>
                <Link to={path + "/kanri/lifeyougomaster"}>
                  <ToolOutlined />
                  <span className="nav-text">LIFE用語マスタ</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"kaigoyougomaster"}>
                <Link to={path + "/kanri/kaigoyougomaster"}>
                  <ToolOutlined />
                  <span className="nav-text">介護用語マスタ</span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="6">
                                <Icon type="idcard" />
                                <span className="nav-text">看護内容</span>
                            </Menu.Item>
                            <Menu.Item key="7">
                                <Icon type="profile" />
                                <span className="nav-text">看護記録</span>
                            </Menu.Item> */}
              <Menu.Item key={"dengon"}>
                <Link to={path + "/kanri/dengondata"}>
                  <MessageOutlined />
                  <span className="nav-text">伝言データ</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"moshiokuri"}>
                <Link to={path + "/kanri/moshiokurilink"}>
                  <ScheduleOutlined />
                  <span className="nav-text">申し送りリンク</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"file"}>
                <Link to={path + "/kanri/file"}>
                  <FileDoneOutlined />
                  <span className="nav-text">ファイル管理</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"sendgroup"}>
                <Link to={path + "/kanri/sendgroup"}>
                  <UsergroupAddOutlined />
                  <span className="nav-text"> グループ設定</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={"open"}>
                <Link to={path + "/kanri/openscreenlog"}>
                  <OrderedListOutlined />
                  <span className="nav-text">画面遷移ログ</span>
                </Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout>
            <Header className="kanri-header">
              <Row>
                <Col span={8} className="kanri-header-br">
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item href="/kanri/dashboard">ダッシュボード</Breadcrumb.Item>
                    <Breadcrumb.Item href={this.props.breadcrumbLink}>{this.props.breadcrumbText}</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
                <Col span={16} style={{ textAlign: "right" }} className="kanri-header-content">
                  <Search
                    placeholder="文字コード判別"
                    onSearch={value => console.log(value)}
                    style={{ width: 250, borderRadius: "4px" }}
                  />
                  <Badge size="big" count={4} className="kanri-header-logout-btn">
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <a
                        href="#"
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        {bell}
                      </a>
                    </Dropdown>
                  </Badge>
                  <Button
                    className="kanri-header-logout-btn"
                    icon={<SettingOutlined />}
                  // onClick={this.logout}
                  />
                  <Button
                    className="kanri-header-logout-btn"
                    icon={<LogoutOutlined />}
                    onClick={this.logout}
                  >
                  </Button>

                </Col>
              </Row>
            </Header>
            <Content style={{ margin: "16px 8px 0px 32px" }}>
              <div style={{ padding: 16, minHeight: 360 }}>
                {this.props.children}
              </div>
            </Content>
            {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
          </Layout>
        </Layout>
        <Modal
          open={this.state.visible}
          title="エラー情報"
          onOk={() => this.handleOk()}
          footer={[
            <Button
              style={{ backgroundColor: "#00838F", borderColor: "#00838F" }}
              key="submit"
              type="primary"
              onClick={() => this.handleOk()}
            >
              OK
            </Button>,
          ]}
        >
          <p>以下の理由によりセッションが強制終了されました。</p>
          <p>・同じIDで別の端末からログインがあった </p>
          <p>・セッションの有効期限切れ</p>
        </Modal>
      </IdleTimer>)
    );
  }
}

export const KanriLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriLayout);
