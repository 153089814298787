import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const MonitoringApi = {
  async loadMonitoring(params) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.GET_MONITORING_BY_RIYOUSYA, {
        params: {
          sid: menu.si,
          systemName: menu.sn,
          riyousyaId: params.riyousyaId,
          page: params.page,
          sort: params.sort,
          monitoringDateTo: params.monitoringDateTo,
        },
      });
    return res.data;
  },
  async getMonitoringById(monitoringId) {
    const res = await axios
      .get(apiUrls.GET_MONITORING_BY_ID, {
        params: {
          id: monitoringId,
        },
      });
    return res.data;
  },
  async updateMonitoring(data, monitoringId) {
    const res = await axios
      .put(apiUrls.UPDATE_MONITORING + "?id=" + monitoringId, {
        monitoring_date: data.monitoring_date,
        tanto_id: data.tanto_id,
        comment: data.comment,
        monitoring_mokuhyous: data.monitoring_mokuhyous,
        monitoring_hyokas: data.monitoring_hyokas,
      });
    return res.data;
  },
  async deleteMonitoring(monitoringId) {
    const res = await axios
      .delete(apiUrls.DELETE_MONITORING, {
        params: {
          id: monitoringId,
        },
      });
    return res.data;
  },
  async createMonitoring(data, riyousyaId) {
    const key = getMenu();
    const res = await axios
      .post(apiUrls.CREATE_MONITORING, {
        riyousya_id: riyousyaId,
        service_kikan_master_id: JSON.parse(localStorage.getItem(key)).si,
        ...data,
      });
    return res.data;
  },
};
export { MonitoringApi };
