/* eslint-disable */
import React, { Component } from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Col } from 'antd'
import SearchRiyousyaModal from '../../../common/component/control/SearchRiyousyaModal'
import SearchRiyousyaControl from '../../../common/component/control/SearchRiyousyaControl'
import _ from 'lodash'

export class Toolbar1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      filterData: this.props.filterConditions,
      isVisibleSearchFurigana: false,
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (JSON.stringify(newProps.filterConditions) !== JSON.stringify(this.props.filterConditions)) {
      this.setState({
        filterData: newProps.filterConditions,
      })
    }
  }

  searchByName(furigana) {
    let filterData = _.cloneDeep(this.state.filterData)
    filterData.searchName = furigana
    this.setState({
      filterData: filterData,
    })
    this.props.handleFilterData(filterData)
  }

  createReportAdl() {
    if (this.props && this.props.riyousyaId) {
      this.props.handleInputAdl(this.props.riyousyaId)
    } else {
      this.setState({
        isVisibleSearchFurigana: true,
      })
    }
  }

  onOkSearch(riyousya) {
    this.setState({
      isVisibleSearchFurigana: false,
    })
    if (riyousya)
      this.props.handleGoToInputReportCase(riyousya.id.toString())
  }

  onCancelSearch() {
    this.setState({
      isVisibleSearchFurigana: false,
    })
  }

  render() {
    return (
      <div style={{ padding: '0 16px' }} className="w-100">
        <Col xs={{ span: 24 }} sm={{ span: 19 }} style={{ paddingBottom: '3px', marginTop: 3 }}>
          <Button
            style={{ float: 'right' }}
            type={'primary'}
            icon={<LegacyIcon type={'plus'} />}
            onClick={() => this.createReportAdl()}
          >{'追加'}</Button>
        </Col>
        {
          this.props.riyousyaId == null
            ?
            <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ paddingBottom: '5px' }}>
              <SearchRiyousyaControl
                style={{ float: 'right', width: 'fit-content' }}
                searchName={this.state.filterData.searchName}
                handleSearch={furigana => this.searchByName(furigana)}
              />
              <SearchRiyousyaModal
                visible={this.state.isVisibleSearchFurigana}
                onOk={riyousya => this.onOkSearch(riyousya)}
                onCancel={() => this.onCancelSearch()}
              />
            </Col>
            :
            null
        }
      </div>
    );

  }
}