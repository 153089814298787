import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const EventPlanRiyousyaApi = {
  async eventPlanRiyousyaApi(date, riyousyaId) {
    const key = getMenu();
    const sid = JSON.parse(localStorage.getItem(key)).si;
    const systemControlName = JSON.parse(localStorage.getItem(key)).sn;
    const systemControlId = JSON.parse(localStorage.getItem(key)).si;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.EVENT_PLAN_RIYOUSYA +
        `?sid=${sid}&date=${date}&riyousyaId=${riyousyaId}&systemControlName=${systemControlName}&systemControlId=${systemControlId}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err;
      }
    }
  },
};

export { EventPlanRiyousyaApi };
