import React from "react";

class LineBreakText extends React.Component {
  render() {
    const { text, style } = this.props;

    return (
      <span
        style={{
          ...style,
        }}
      >
        {text
          ? text.split(/\r\n|\n|\r/).map((str, index) => (
            <React.Fragment key={index}>
              {str}
              <br />
            </React.Fragment>
          ))
          : null}
      </span>
    );
  }
}

export default LineBreakText;
