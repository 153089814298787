import { connect } from "react-redux";

import Report from "./Report";
import {
  loadDataCreator,
  filterCreator,
  searchCreator,
  searchByNameCreator,
  sortCreator,
  refreshCreator,
  searchByGenderCreator,
  baitaruCopyCreator,
  baitaruDelCreator,
  mealCopyCreator,
  mealDelCreator,
  suibunCopyCreator,
  suibunDelCreator,
  haisetuCopyCreator,
  haisetuDelCreator,
  nyuyokuCopyCreator,
  nyuyokuDelCreator,
  fukuyakuCopyCreator,
  fukuyakuDelCreator,
  kaigonaiyouCopyCreator,
  kaigonaiyouDelCreator,
  tokkiCopyCreator,
  tokkiDelCreator,
  gazoCopyCreator,
  gazoDelCreator,
  updateKirokuCreator,
  insertKirokuCreator,
  deleteKirokuCreator,
  loadZenkaiCreator,
  insertKirokuMulCreator,
  updateKirokuMulCreator,
  getHaibenDay,
} from "../../actions/reportActions/actionCreators";

import { tantoMasterListCreator } from "../../actions/tantoMasterListActions/actionCreators";

import {
  yougoMasterNaiyouCreator,
  yougoMasterEditStateCreator,
  yougoMasterEditCancelCreator,
  yougoMasterEditSaveCreator,
  loadEctSelectDataCreator,
  yougoMasterEctStateCreator,
  yougoMasterEctCancelCreator,
  yougoMasterEctSaveCreator,
  yougoMasterSyuruiCreator,
  yougoMasterJoutaiCreator,
} from "../../actions/yougoMasterNaiyouActions/actionCreators";

import { drugMasterListCreator } from "../../actions/drugMasterActions/actionCreators";

import {
  download,
  loadFilePreview,
} from "../../actions/fileManagementActions/actionCreators";

import {
  syokichiItemCreator,
  syokichiUpdateCreator,
} from "../../actions/syokichiActions/actionCreators";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    // report reducers
    riyousyaTab: state.report.nyutaisho,
    baitaruTab: state.report.baitaruTab,
    shokujiTab: state.report.shokujiTab,
    suibunTab: state.report.suibun,
    haisetuTab: state.report.haisetu,
    nyutaishoTab: state.report.nyutaishoTab,
    fukuyakuTab: state.report.fukuyaku,
    kaigonaiyouTab: state.report.kaigonaiyou,
    tokkiTab: state.report.tokki,
    gazouTab: state.report.gazou,
    zenkai: state.report.zenkai,
    reportFilterData: state.report.nyutaishoFilter,
    loading: state.report.loading,
    loadingButtonUpdate: state.report.loadingButtonUpdate,
    notifyDisplay: state.report.notifyDisplay,
    notifyContent: state.report.notifyContent,
    haiben: state.report.haiben,

    // tantomaster reducers
    tantoMaster: state.tantoMasterList.tantoMaster,

    // yougomaster reducers
    yougoMasterNa: state.yougoMasterNaiyou.yougoMasterNaiyou,
    yougoMasterSy: state.yougoMasterNaiyou.yougoMasterSyurui,
    yougoMasterJt: state.yougoMasterNaiyou.yougoMasterHJ, // haisetu joutai
    yougoMasterEctSelect1: state.yougoMasterNaiyou.ectDataSelect1,
    yougoMasterEctSelect2: state.yougoMasterNaiyou.ectDataSelect2,
    yougoMasterEctSelect3: state.yougoMasterNaiyou.ectDataSelect3,

    // drug reducers
    drugList: state.drugMaster.list,
    drugLoading: state.drugMaster.loading,

    // syokichi reducers
    syokichi: state.syokichi.item,

    loadingFileManagement: state.fileManagement.loading,
    created: state.fileManagement.created,
    deleteFile: state.fileManagement.delete,
    update: state.fileManagement.update,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    /**
     * report actions
     *
     */
    nyutaishoFilter: (tabName) => dispatch(filterCreator(tabName)),

    getHaibenDay: () => dispatch(getHaibenDay()),

    nyutaishoSearch: (searchData, params) =>
      dispatch(searchCreator(searchData, params)),
    searchByName: (name, params) => dispatch(searchByNameCreator(name, params)),
    searchByGender: (gender, params) =>
      dispatch(searchByGenderCreator(gender, params)),
    sort: (sortData, params) => dispatch(sortCreator(sortData, params)),
    refresh: (params) => dispatch(refreshCreator(params)),

    loadData: (parameterObj) => dispatch(loadDataCreator(parameterObj)),

    copyBaitaru: (baitaruRecord) => dispatch(baitaruCopyCreator(baitaruRecord)),
    delBaitaru: (baitaruRecord) => dispatch(baitaruDelCreator(baitaruRecord)),
    copyMeal: (mealRecord) => dispatch(mealCopyCreator(mealRecord)),
    delMeal: (mealRecord) => dispatch(mealDelCreator(mealRecord)),
    copySuibun: (suibunRecord) => dispatch(suibunCopyCreator(suibunRecord)),
    delSuibun: (suibunRecord) => dispatch(suibunDelCreator(suibunRecord)),
    copyHaisetu: (haisetuRecord) => dispatch(haisetuCopyCreator(haisetuRecord)),
    delHaisetu: (haisetuRecord) => dispatch(haisetuDelCreator(haisetuRecord)),
    copyNyuyoku: (nyuyokuRecord) => dispatch(nyuyokuCopyCreator(nyuyokuRecord)),
    delNyuyoku: (nyuyokuRecord) => dispatch(nyuyokuDelCreator(nyuyokuRecord)),
    copyFukuyaku: (fukuyakuRecord) =>
      dispatch(fukuyakuCopyCreator(fukuyakuRecord)),
    delFukuyaku: (fukuyakuRecord) =>
      dispatch(fukuyakuDelCreator(fukuyakuRecord)),
    copyKaigonaiyou: (kaigonaiyouRecord) =>
      dispatch(kaigonaiyouCopyCreator(kaigonaiyouRecord)),
    delKaigonaiyou: (kaigonaiyouRecord) =>
      dispatch(kaigonaiyouDelCreator(kaigonaiyouRecord)),
    copyTokki: (tokkiRecord) => dispatch(tokkiCopyCreator(tokkiRecord)),
    delTokki: (tokkiRecord) => dispatch(tokkiDelCreator(tokkiRecord)),
    copyGazo: (gazoRecord) => dispatch(gazoCopyCreator(gazoRecord)),
    delGazo: (gazoRecord) => dispatch(gazoDelCreator(gazoRecord)),

    updateRecord: (params, callback) =>
      dispatch(updateKirokuCreator(params, callback)),
    insertRecord: (params, callback) =>
      dispatch(insertKirokuCreator(params, callback)),
    deleteRecord: (vitalId) => dispatch(deleteKirokuCreator(vitalId)), // delete from server

    updateRecordMul: (params) => dispatch(updateKirokuMulCreator(params)),
    insertRecordMul: (params) => dispatch(insertKirokuMulCreator(params)),

    loadZenkai: (date, riyousyaId, time) =>
      dispatch(loadZenkaiCreator(date, riyousyaId, time)),

    /**
     * tanto-master actions
     *
     */
    tantoMasterList: () => dispatch(tantoMasterListCreator()),

    /**
     * yougo-master actions
     *
     */
    yougoMasterNaiyou: (listName) =>
      dispatch(yougoMasterNaiyouCreator(listName)),
    yougoMasterEditState: (index) =>
      dispatch(yougoMasterEditStateCreator(index)),
    yougoMasterEditCancel: (index) =>
      dispatch(yougoMasterEditCancelCreator(index)),
    yougoMasterEditSave: (naiyou) =>
      dispatch(yougoMasterEditSaveCreator(naiyou)),

    loadEctSelectData: (mainSid) => dispatch(loadEctSelectDataCreator(mainSid)),
    yougoMasterEctState: (index) => dispatch(yougoMasterEctStateCreator(index)),
    yougoMasterEctCancel: (index) =>
      dispatch(yougoMasterEctCancelCreator(index)),
    yougoMasterEctSave: (type, naiyou) =>
      dispatch(yougoMasterEctSaveCreator(type, naiyou)),

    yougoMasterSyurui: (syurui) => dispatch(yougoMasterSyuruiCreator(syurui)),
    yougoMasterJoutai: (joutai) => dispatch(yougoMasterJoutaiCreator(joutai)),

    /**
     * drug actions
     *
     */
    loadDrugMaster: (bunrui, name) =>
      dispatch(drugMasterListCreator(bunrui, name)),

    /**
     * syokichi actions
     *
     */
    syokichiItem: (riyousyaId, kubun) =>
      dispatch(syokichiItemCreator(riyousyaId, kubun)),
    syokichiUpdate: (riyousyaId, kubun, syokichi) =>
      dispatch(syokichiUpdateCreator(riyousyaId, kubun, syokichi)),

    /**
     * file update
     */
    download: (params, callback) => dispatch(download(params, callback)),
    loadFilePreview: (params, callback) =>
      dispatch(loadFilePreview(params, callback)),
  };
};

export const ReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Report);
