import _, { sum } from "lodash";
import {
  calListDataShow,
  checkAndRefillBaseOnType21,
  createListExpandCode,
  fillListData,
  filterMonthlyExpandCode,
  generateUuid,
  handleCalListAndSum,
  handleGroupParentIds,
  handleMapSumListOfChildGrayByServiceCode,
  isCheckGrayChildRecord,
  handleSumListPlanParent,
  handleSumListFPlanParent,
  handleSumListActualParent,
  handleSumListFActualParent,
  offerTimeFilter,
  sumPlan,
  sumTowArr,
  handleSumListFPlanParentQuote,
  checkAndRefillBaseOnKaigo,
  RENTAL_TYPE
} from "./function";

const handleCalculationAddNewRecord =  ({
  props,
  isPlan,
  listDataRecord,
  setListRowChecked,
  setListDataChild,
  setIsDisableButtonCalender,
  nengetu,
  changeSort,
  changeFilter,
  over30Day,
  changeKaigo,
  isTekiyou,
  listDataFilterCal
}) => {
  const KAIGO_CODE_SHORT_TYPE = ["21", "22", "23", "38", "27", "28", "68", "79", "2A"];

  setListRowChecked([]);
  setListDataChild([]);
  setIsDisableButtonCalender(true);
  const result = {
    listDataService: [],
    expandCodeNotParent: [],
  };
  const isCheckAddRecord = over30Day ? false : (props.riyouhyou?.betuhyouDetailList && props.riyouhyou?.betuhyouDetailList.length && props.isCheckAddRecord !== "GET_SERVICE_CODE_SUCCESS")

  let parentId = generateUuid();

  //create main code
  listDataRecord.forEach((e) => {
    const { newExpandCode, newExpandCodeChild, newAdditionList, listDataChildrenFilter, additionByList } = filterMonthlyExpandCode({
      formValues: e.formValues,
      expandCode: e.expandCode,
      props,
    });
    let mergedArray = [...newExpandCode, ...newExpandCodeChild];

    // Lọc các phần tử có serviceCode không trùng nhau
    let uniqueArray = mergedArray.filter((item, index, self) => index === self.findIndex((t) => t.serviceCode === item.serviceCode));
    const resultArrSoft = _.sortBy(uniqueArray, "serviceCode");
    e.formValues.additionList = newAdditionList;
    e.formValues.additionByList = additionByList;

    const fillData = fillListData({
      listDate: e.listDate,
      countDate: e.countDate,
      mainCode: e.mainCode,
      formValues: e.formValues,
      nengetu: nengetu,
      listValueDay: undefined,
      isGetDataWeekly: e.isGetDataWeekly,
    });

    const serviceCodeMain = handleCalculateServiceCodeMain({
      e,
      isPlan,
      fillData,
      props,
      isCheckAddRecord,
      listDataChildrenFilter,
      parentId,
      countDate: e.countDate,
      isTekiyou
    });

    //create expand code
    const { expandCodeNotParentAdd, expandCodeOtherParent } = createListExpandCode({
      expandCode: resultArrSoft,
      formValues: e.formValues,
      mainCode: e.mainCode,
      fillData: fillData,
      selectedRow: undefined,
      parentId: parentId,
      listDataChildrenFilter: listDataChildrenFilter,
      isCheckAddRecord: isCheckAddRecord,
      isGetDataWeekly: e.isGetDataWeekly,
      isPlan,
      props,
      expandCodeOfItem: e.expandCode,
      serviceCodeMain,
      isTekiyou
    });

    props.riyouhyouForm.listDataService.forEach((e) => {
      expandCodeOtherParent.forEach((i) => {
        if (!RENTAL_TYPE.includes(i.dataRow.shuiruiCode) && !RENTAL_TYPE.includes(e.dataRow.shuiruiCode)){
          handleReFill({ e, i, type: "月1コード"})
          handleReFill({ e, i, type: "口腔栄養スクリーニング" })
        }
      });
    });

    const sortData = handleGroupParentIds([
      ...handleFilterDuplicateRecord({
        serviceCodeMain,
        props,
        expandCodeOtherParent,
        nengetu,
        isPlan,
        isCheckAddRecord,
        fillData,
        formValues: e.formValues,
        isCheckAddRecord,
        isGetDataWeekly: e.isGetDataWeekly,
        listDataFilterCal
      }),
    ]);
    
    const listDataService =
      e.isGetDataWeekly || !KAIGO_CODE_SHORT_TYPE.includes(e.mainCode.shuruiCode)
        ? sortData
        : checkAndRefillBaseOnType21({
            sortData: sortData,
            serviceCodeMain: serviceCodeMain,
            isPlan: isPlan,
            isKeepFirst: e.isKeepFirst,
            isKeepLast: e.isKeepLast,
            isReCreated: over30Day,
            listDataExisted: props.riyouhyouForm.listDataService
          });
    const newListDataService = !changeKaigo ? listDataService : checkAndRefillBaseOnKaigo({
      sortData: sortData,
      serviceCodeMain: serviceCodeMain,
      isPlan: isPlan,
      isKeepFirst: e.isKeepFirst,
      isKeepLast: e.isKeepLast,
      changeSort,
      changeFilter
    });
    let expandCodeNotParent = [...expandCodeNotParentAdd, ...props.riyouhyouForm.listDataNotParent];
    expandCodeNotParent = _.sortBy(expandCodeNotParent, ["dataRow.komokuCode"]);
    result.listDataService.push(...newListDataService);
    result.expandCodeNotParent.push(...expandCodeNotParent);
    parentId++;
  });

  const listDataServiceMapSumListByServiceCode = handleMapSumListOfChildGrayByServiceCode(result.listDataService);

  const listDataShow = calListDataShow({ dataService: listDataServiceMapSumListByServiceCode, changeSort, changeFilter });
  
  //#81137  
  handleTypeSpecialType13({ listDataShow, isPlan });

  const newListDataService = (listDataShow || []).flatMap((e) => e);
  !changeKaigo && props.setRiyouhyouForm({ listDataService: newListDataService, listDataNotParent: result.expandCodeNotParent, listDataShow });
  return { listDataService: result.listDataService, listDataNotParent: result.expandCodeNotParent, listDataShow };
};

function compareArraysChildrent(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (!array2.includes(array1[i])) {
      return false;
    }
  }

  return true;
}

function handleTypeSpecialType13({ listDataShow, isPlan }) {
  if (listDataShow && listDataShow.length) {
    listDataShow.forEach((dataItem, index) => {
      dataItem.forEach((itemSecond) => {
        if (!itemSecond.existedEl) {
          if (!RENTAL_TYPE.includes(itemSecond.dataRow.shuiruiCode)) {
            let isCodeTrue = false;
            if (itemSecond.serviceName.includes("サービス")) {
              if (itemSecond.serviceCode.includes("78") && itemSecond.mainCode.sisetukubun.includes("2")) {
                checkValueEachMode(itemSecond, isPlan);
              } else {
                if (itemSecond.serviceCode.includes("78") && itemSecond.mainCode.sisetukubun.includes("1")) {
                  if (isPlan) {
                    itemSecond.sumListPlan = sum(itemSecond.listPlan);
                    itemSecond.sumListFPlan = sum(itemSecond.listPlan);
                  } else {
                    itemSecond.sumListActual = sum(itemSecond.listActual);
                    itemSecond.sumListFActual = sum(itemSecond.listActual);
                  }
                }else{
                  dataItem.forEach((item) => {
                    if (item.isParent) {
                      item.serviceFillDataList.forEach((valueParent, i) => {
                        if (
                          ((valueParent.code.includes("C003=03") && valueParent.code.includes("C006=3")) ||
                          (valueParent.code.includes("C003=03") && valueParent.code.includes("C006=2")))
                          && !itemSecond.mainCode.additionList.includes("日割り")
                          ) {
                            checkValueEachMode(itemSecond, isPlan, false);
                          }
                        });
                      }
                    });
                  }
                }
            } else if (itemSecond?.serviceFillDataList?.length > 0) {
              itemSecond.serviceFillDataList.forEach((item, i) => {
                if (((item.code.includes("C003=03") && item.code.includes("C006=3")) || item.code.includes("C006=2")) && !itemSecond.mainCode.additionList.includes("日割り")) {
                  isCodeTrue = true;
                } else if (itemSecond.serviceCode.includes("71") && item.code.includes("C003=03") && itemSecond.isParent) {
                  isCodeTrue = true;
                }
              });
            }

            if (isCodeTrue) {
              checkValueEachMode(itemSecond, isPlan, true);
            }
          }
        }
      });
    });
  }
}

function checkValueEachMode(itemSecond, isPlan, isCodeTrue) {
  if(!isCodeTrue) {
    if(isPlan) {
      if(!itemSecond.listPlan.includes(1)) {
        itemSecond.sumListPlan = null;
      }else {
        itemSecond.sumListPlan =  sum(itemSecond.listPlan);
        itemSecond.sumListFPlan =  1;
      }
    }else {
      if(!itemSecond.listActual.includes(1)) {
        itemSecond.sumListActual = null;
      }else {
        itemSecond.sumListActual =  sum(itemSecond.listActual);
        itemSecond.sumListFActual = 1;
      }
    }
  }else {
    if (isPlan) {
      if (!itemSecond.listPlan.includes(1)) {
        itemSecond.sumListPlan = null
      } else {
        itemSecond.sumListPlan = sum(itemSecond.listPlan)
        itemSecond.sumListFPlan = 1
      }
    } else {
      if (!itemSecond.listActual.includes(1)) {
        itemSecond.sumListActual = null
      } else {
        itemSecond.sumListActual = sum(itemSecond.listActual)
        itemSecond.sumListFActual = 1;
      }
    }
  }
}

const handleFilterDuplicateRecord = ({
  serviceCodeMain,
  props,
  expandCodeOtherParent,
  nengetu,
  isPlan,
  formValues,
  isCheckAddRecord,
  isGetDataWeekly,
  listDataFilterCal,
  isClickWeekly,
}) => {
  const listDataServiceStore =
    listDataFilterCal && listDataFilterCal.length > 0 && !isWeekly ? listDataFilterCal : props.riyouhyouForm.listDataService;
  const isCheckRecordAlreadyExists = listDataServiceStore.find(
    (item) =>
      item.offerTime === serviceCodeMain.offerTime &&
      item.idService === serviceCodeMain.idService &&
      item.officeName === serviceCodeMain.officeName &&
      item.serviceName === serviceCodeMain.serviceName &&
      item.isParent &&
      serviceCodeMain.isParent && 
      compareArraysChildrent(item.dataRow?.additionList, serviceCodeMain.dataRow?.additionList),
  );

  if (!isCheckRecordAlreadyExists) {
    return [...(listDataServiceStore || []), serviceCodeMain, ...expandCodeOtherParent];
  }

  let listDataServiceMap = listDataServiceStore.map((item) => {
    const recordParent = listDataServiceStore.find((e) => e.parentId === item.parentId && e.isParent);
    if (isClickWeekly) {
      if (item.sumListPlan) {
        if (item.isParent && serviceCodeMain.isParent) {
          const listPlan = recordParent.listPlan;
          const sumListPlan = recordParent.sumListPlan;
          const sumListFPlan = recordParent.sumListFPlan;

          const listActual = serviceCodeMain.listActual;
          const sumListActual = serviceCodeMain.sumListActual;
          const sumListFActual = serviceCodeMain.sumListFActual;

          return {
            ...item,

            listPlan,
            sumListPlan,
            sumListFPlan,

            listActual,
            sumListActual,
            sumListFActual,
          };
        } else if (!item.isParent && !isCheckGrayChildRecord(item)) {
          const listPlan = item.listPlan;
          const sumListPlan = item.sumListPlan;
          const sumListFPlan = item.sumListFPlan;

          const listActual = serviceCodeMain.listActual;
          const sumListActual = serviceCodeMain.sumListActual;
          const sumListFActual = serviceCodeMain.sumListFActual;

          return {
            ...item,

            listPlan,
            sumListPlan,
            sumListFPlan,

            listActual,
            sumListActual,
            sumListFActual,
          };
        } 
      } else {
        if (!item.isParent && isCheckGrayChildRecord(item) && isCheckGrayChildRecord(serviceCodeMain)) {
          const sumListFActual = serviceCodeMain.sumListFActual >= 1 ? 1 : null;
          return {
            ...item,
            sumListFActual: sumListFActual,
          };
        }
        else {
          let isQuoteActual = false;
          const listActual = serviceCodeMain.listActual;
          const sumListActual = serviceCodeMain.sumListActual;
          const sumListFActual = serviceCodeMain.sumListFActual;
          if(  item.serviceName === serviceCodeMain.serviceName &&
            item.officeName === serviceCodeMain.officeName&&
            item.offerTime === serviceCodeMain.offerTime ){
              isQuoteActual = true
            }
          return {
            ...item,
            listActual,
            sumListActual,
            sumListFActual,
            isQuoteActual
          };
        }
      }
    } else {
      if (!item.isParent && isCheckGrayChildRecord(item)) {
        const sumListFPlan = sum(sumTowArr(recordParent.listPlan, serviceCodeMain.listPlan)) >= 1 ? 1 : null;
        const sumListFActual = sum(sumTowArr(recordParent.listActual, serviceCodeMain.listActual)) >= 1 ? 1 : null;
        return {
          ...item,
          sumListFPlan: isPlan ? sumListFPlan : item.sumListFPlan,
          sumListFActual: isPlan ? item.sumListFActual : sumListFActual,
        };
      }

      if (!compareArraysChildrent(item.dataRow?.additionList, serviceCodeMain.dataRow?.additionList)) {
        return { ...item };
      }
      const {
        listPlan,
        listActual,
        sumListPlan,
        sumListFPlan,
        sumListActual,
        sumListFActual,
        listPlanCodeMain,
        listActualCodeMain,
        sumListPlanCodeMain,
        sumListFPlanCodeMain,
        sumListActualCodeMain,
        sumListFActualCodeMain,
      } = handleCalListAndSum({
        item,
        isPlan,
        nengetu,
        formValues,
        arr1: isPlan ? item.listPlan : item.listActual,
        arr2: isPlan ? serviceCodeMain.listPlan : serviceCodeMain.listActual,
        isCheckAddRecord,
        isGetDataWeekly,
        serviceCodeMain,
      });
      if (item.isParent) {
        return {
          ...item,
          listPlan: isPlan ? listPlanCodeMain : item.listPlan,
          listActual: isPlan ? item.listActual : listActualCodeMain,
          sumListPlan: isPlan ? sumListPlanCodeMain : item.sumListPlan,
          sumListFPlan: isPlan ? sumListFPlanCodeMain : item.sumListFPlan,
          sumListActual: isPlan ? item.sumListActual : sumListActualCodeMain,
          sumListFActual: isPlan ? item.sumListFActual : sumListFActualCodeMain,
        };
      }
      return {
        ...item,
        listPlan: isPlan ? listPlan : item.listPlan,
        listActual: isPlan ? item.listActual : listActual,
        sumListPlan: isPlan ? sumListPlan : item.sumListPlan,
        sumListFPlan: isPlan ? sumListFPlan : item.sumListFPlan,
        sumListActual: isPlan ? item.sumListActual : sumListActual,
        sumListFActual: isPlan ? item.sumListFActual : sumListFActual,
      };
    }
  });
 
  return [...(listDataServiceMap || [])];
};

const handleFilterDuplicateRecordEdit = ({
  listDataServiceOtherItemSelect,
  selectedRow,
  listDataOtherParentIdSelected,
  isPlan,
  nengetu,
  formValues,
  isCheckAddRecord,
  listDataService,
}) => {
  const recordAlreadyExists = listDataOtherParentIdSelected.find(
    (item) =>
      item.offerTime === selectedRow.offerTime &&
      item.officeName === selectedRow.officeName &&
      item.serviceName === selectedRow.serviceName &&
      item.isParent &&
      selectedRow.isParent &&
      compareArraysChildrent(item.dataRow?.additionList, selectedRow.dataRow?.additionList),
  );
  if (!recordAlreadyExists) {
    return [
      selectedRow,
      ...listDataServiceOtherItemSelect.map((item) => {
        if (!item.isParent && !isCheckGrayChildRecord(item)) {
          const itemExistInListDataService = listDataService.find(
            (e) =>
              e.serviceCode === item.serviceCode &&
              e.officeName === item.officeName &&
              e.parentId === item.parentId &&
              !e.isParent &&
              !isCheckGrayChildRecord(e),
          );
          if (itemExistInListDataService) {
            return {
              ...item,
              listPlan: itemExistInListDataService.listPlan,
              listActual: itemExistInListDataService.listActual,
              sumListPlan: itemExistInListDataService.sumListPlan,
              sumListFPlan: itemExistInListDataService.sumListFPlan,
              sumListActual: itemExistInListDataService.sumListActual,
              sumListFActual: itemExistInListDataService.sumListFActual,
            };
          }
          return item;
        }
        return item;
      }),
    ];
  }

  const listDataMapPlanAndActual = listDataOtherParentIdSelected.map((item) => {
    if (
      (item.isParent && item.parentId === recordAlreadyExists?.parentId) ||
      (!item.isParent && item.parentId === recordAlreadyExists?.parentId && !isCheckGrayChildRecord(item))
    ) {
      const {
        listPlan,
        listActual,
        sumListPlan,
        sumListFPlan,
        sumListActual,
        sumListFActual,
        listPlanCodeMain,
        listActualCodeMain,
        sumListPlanCodeMain,
        sumListFPlanCodeMain,
        sumListActualCodeMain,
        sumListFActualCodeMain,
      } = handleCalListAndSum({
        item,
        isPlan,
        nengetu,
        formValues,
        arr1: isPlan ? item.listPlan : item.listActual,
        arr2: isPlan ? selectedRow.listPlan : selectedRow.listActual,
        isCheckAddRecord,
        serviceCodeMain: selectedRow,
      });
      if (item.isParent) {
        return {
          ...item,
          listPlan: isPlan ? listPlanCodeMain : item.listPlan,
          listActual: isPlan ? item.listActual : listActualCodeMain,
          sumListPlan: isPlan ? sumListPlanCodeMain : item.sumListPlan,
          sumListFPlan: isPlan ? sumListFPlanCodeMain : item.sumListFPlan,
          sumListActual: isPlan ? item.sumListActual : sumListActualCodeMain,
          sumListFActual: isPlan ? item.sumListFActual : sumListFActualCodeMain,
        };
      }
      return {
        ...item,
        listPlan: isPlan ? listPlan : item.listPlan,
        listActual: isPlan ? item.listActual : listActual,
        sumListPlan: isPlan ? sumListPlan : item.sumListPlan,
        sumListFPlan: isPlan ? sumListFPlan : item.sumListFPlan,
        sumListActual: isPlan ? item.sumListActual : sumListActual,
        sumListFActual: isPlan ? item.sumListFActual : sumListFActual,
      };
    } else {
      return {
        ...item,
      };
    }
  });

  return listDataMapPlanAndActual;
};

const handleSumListPlanParentQuote = ({ e , fillData }) =>{
  if(e.mainCode.name.includes( "定期巡回訪看") || e.formValues.shuiruiCode  === "78"){
    if(sum(e.mainCode.listPlan) > 0){
      return sum(e.mainCode.listPlan)
    }
    else return e.mainCode.count;
  }else{
    if(e.mainCode.shuruiCode === "71" || e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "76") {
      if(e.mainCode.shuruiCode === "71"){
      if(sum(e.mainCode.listPlan) > 0){
        return sum(e.mainCode.listPlan)
      }else return 1;
      }
      if(sum(e.mainCode.listPlan) > 0){
        return sum(e.mainCode.listPlan)
      }else{
        return null;
      }
    }
    else{
      if(e.mainCode.listPlan) return sum(e.mainCode.listPlan)
      else return null;
    }
  }
}

const handleReFill = ({ e, i , type }) => {
  if (
    e.serviceCode === i.serviceCode &&
    e.serviceFillDataList[0]?.additionName === type &&
    i.serviceFillDataList[0]?.additionName === type &&
    e.officeName === i.officeName &&
    e.sumListFPlan === 1
  ) {
    i.sumListFPlan = 0;
  } else if (
    e.serviceCode === i.serviceCode &&
    e.serviceFillDataList[0]?.additionName === type &&
    i.serviceFillDataList[0]?.additionName === type &&
    e.officeName === i.officeName &&
    e.sumListFActual === 1
  ) {
    i.sumListFActual = 0;
  }
}


const handleCalculateServiceCodeMainQuote = ({ e, isPlan, fillData, props, isCheckAddRecord, listDataChildrenFilter, parentId, isFromApi = false }) => {
  const serviceCodeMain = {
    serviceName: e.mainCode ? e.mainCode.name : "",
    jigyousyoId: e.formValues.jigyousyoId,
    officeName: e.formValues.ryakuServiceKikan,
    offerTime: props.listColorMaster && props.listColorMaster.length > 0 && offerTimeFilter(e.formValues, props.listColorMaster),
    listPlan: e.mainCode.listPlan,
    listActual: isFromApi ? e.mainCode.listActual : isPlan ? fillData.listNotSet : isCheckAddRecord ? e.mainCode.listActual : fillData.listValueMain,
    //利用-plan
    sumListPlan: handleSumListPlanParentQuote({e, fillData}),
    //算定-plan
    sumListFPlan: handleSumListFPlanParentQuote({ e, isPlan, fillData, isCheckAddRecord }),
    //利用-actual
    sumListActual:handleSumListActualParent({ e, isPlan, fillData, isCheckAddRecord }),
    //算定-actual
    sumListFActual: handleSumListFActualParent({ e, isPlan, fillData, isCheckAddRecord }),
    serviceFillDataList: e.mainCode.serviceFillDataList,
    parentId: e.mainCode.parentId ? e.mainCode.parentId : e.formValues.nengetu + "" + parentId,
    idService: e.mainCode.idService,
    id: e.mainCode.id + "" + parentId,
    isParent: true,
    serviceCode: e.mainCode ? e.mainCode.serviceCode : "",
    isShow: true,
    dataRow: e.formValues,
    mainCode: e.mainCode,
    santeiTani: e.mainCode ? e.mainCode.santeiTani : "",
    sisetukubun: e.mainCode ? e.mainCode.sisetukubun : "",
    hiwariSikibetu: e.mainCode ? e.mainCode.hiwariSikibetu : "",
    additionListMain: e.additionListMain === null ? null : e.additionListMain.map((e) => e["addition"]),
    is30DayOver: e.formValues.is30DayOver,
    discount: props.officeInfo?.discount ?? e.mainCode.discount,
    price: props.officeInfo?.price ?? e.mainCode.price,
    doitutatemonoSantei: e.mainCode.doitutatemonoSantei,
    goseiSikbetuKubun: e.mainCode.goseiSikbetuKubun,
    tuho24: e.mainCode.tuho24,
    rihaTeikyo: e.mainCode.rihaTeikyo,
    kyoseigataSantei: e.mainCode.kyoseigataSantei,
    tokuteiShogukaizen: e.mainCode.tokuteiShogukaizen,
    rihamane: e.mainCode.rihamane,
    shogukaizen: e.mainCode.shogukaizen,
    kasanKanouCodeMap: e.mainCode.kasanKanouCodeMap,
    sikyugendogakuKubun: e.mainCode.sikyugendogakuKubun,
    serviceTeikyoKyoka: e.mainCode.serviceTeikyoKyoka,
    taniSikibetu: e.mainCode.taniSikibetu,
    seikatusoudaninHaiti: e.mainCode.seikatusoudaninHaiti,
    nintishoSenmoncare: e.mainCode.nintishoSenmoncare,
    serviceRiyoJoken: e.mainCode.serviceRiyoJoken,
    higaito: e.mainCode.higaito,
    yousien: e.mainCode.yousien,
    yousien1: e.mainCode.yousien1,
    youkaigo1: e.mainCode.youkaigo1,
    youkaigo2: e.mainCode.youkaigo2,
    youkaigo3: e.mainCode.youkaigo3,
    youkaigo4: e.mainCode.youkaigo4,
    youkaigo5: e.mainCode.youkaigo5,
    jikanEntyou: e.mainCode.jikanEntyou,
    gaibuserviceShuruicode: e.mainCode.gaibuserviceShuruicode,
    serviceKikanMasterId: e.formValues.sid ?? e.mainCode.serviceKikanMasterId,
    kyufukanriTani: e.mainCode.kyufukanriTani,
    conditionType76:
      e.mainCode.shuruiCode === "76" ? "shuruicode:" + e.mainCode.shuruiCode + ",serviceRiyoJoken:" + e.mainCode.serviceRiyoJoken : null,
    listDataChildrenFilter,
    tani: e.mainCode.tani,
  };
  return serviceCodeMain;
};

const handleCalculateServiceCodeMain = ({ e, isPlan, fillData, props, isCheckAddRecord, listDataChildrenFilter, parentId, isFromApi = false, isTekiyou }) => {
  const serviceCodeMain = {
    serviceName: e.mainCode ? e.mainCode.name : "",
    jigyousyoId: e.formValues.jigyousyoId,
    officeName: e.formValues.ryakuServiceKikan,
    offerTime: props.listColorMaster && props.listColorMaster?.length > 0 && offerTimeFilter(e.formValues, props.listColorMaster),
    listPlan: (isFromApi || isTekiyou)
      ? e.mainCode.listPlan
      : isPlan
      ? isCheckAddRecord && e.mainCode.listPlan
        ? e.mainCode.listPlan
        : fillData.listValueMain
      : fillData.listNotSet,
    listActual: (isFromApi || isTekiyou) ? e.mainCode.listActual : isPlan ? fillData.listNotSet : isCheckAddRecord ? e.mainCode.listActual : fillData.listValueMain,
    //利用-plan
    sumListPlan: isFromApi
      ? (e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "71" || e.mainCode.shuruiCode === "76") && e.mainCode.count > 0
        ? e.mainCode.count
        : (e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "71" || e.mainCode.shuruiCode === "76") && e.mainCode.count === 0
        ? null
        : e.mainCode.count
      : e.mainCode.count
      ? e.mainCode.count
      : handleSumListPlanParent({ e, isPlan, fillData, isCheckAddRecord }),
    //算定-plan
    sumListFPlan: isFromApi ? e.mainCode.santeiCount : handleSumListFPlanParent({ e, isPlan, fillData, isCheckAddRecord }),
    //利用-actual
    sumListActual: isFromApi
      ? (e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "71" || e.mainCode.shuruiCode === "76") && e.mainCode.countActual > 0
        ? e.mainCode.countActual
        : (e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "71" || e.mainCode.shuruiCode === "76") && e.mainCode.countActual === 0
        ? null
        : e.mainCode.countActual
      : e.mainCode.countActual
      ? e.mainCode.countActual
      : handleSumListActualParent({ e, isPlan, fillData, isCheckAddRecord }),
    //算定-actual
    sumListFActual: isFromApi
      ? (e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "71" || e.mainCode.shuruiCode === "76") && e.mainCode.count > 0
        ? e.mainCode.santeiCountActual
        : (e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "71" || e.mainCode.shuruiCode === "76") && e.mainCode.santeiCountActual === 0
        ? null
        : e.mainCode.santeiCountActual
      : e.mainCode.santeiCountActual
      ? e.mainCode.santeiCountActual
      : handleSumListFActualParent({ e, isPlan, fillData, isCheckAddRecord }),
    serviceFillDataList: e.mainCode.serviceFillDataList,
    parentId: e.mainCode.parentId ? e.mainCode.parentId : "" + parentId,
    idService: props.isCheckAddRecord === "GET_SERVICE_CODE_SUCCESS" ? e.mainCode.idService ? e.mainCode.idService : e.mainCode.id : e.mainCode.idService,
    id: e.mainCode.id + "" + parentId,
    isParent: true,
    serviceCode: e.mainCode ? e.mainCode.serviceCode : "",
    isShow: true,
    dataRow: e.formValues,
    mainCode: e.mainCode,
    santeiTani: e.mainCode ? e.mainCode.santeiTani : "",
    sisetukubun: e.mainCode ? e.mainCode.sisetukubun : "",
    hiwariSikibetu: e.mainCode ? e.mainCode.hiwariSikibetu : "",
    additionListMain: e.additionListMain === null ? null : e.additionListMain.map((e) => e["addition"]),
    is30DayOver: e.formValues.is30DayOver,
    discount: e.mainCode.discount,
    price: e.mainCode.price,
    doitutatemonoSantei: e.mainCode.doitutatemonoSantei,
    goseiSikbetuKubun: e.mainCode.goseiSikbetuKubun,
    tuho24: e.mainCode.tuho24,
    rihaTeikyo: e.mainCode.rihaTeikyo,
    kyoseigataSantei: e.mainCode.kyoseigataSantei,
    tokuteiShogukaizen: e.mainCode.tokuteiShogukaizen,
    rihamane: e.mainCode.rihamane,
    shogukaizen: e.mainCode.shogukaizen,
    kasanKanouCodeMap: e.mainCode.kasanKanouCodeMap,
    sikyugendogakuKubun: e.mainCode.sikyugendogakuKubun,
    serviceTeikyoKyoka: e.mainCode.serviceTeikyoKyoka,
    taniSikibetu: e.mainCode.taniSikibetu,
    seikatusoudaninHaiti: e.mainCode.seikatusoudaninHaiti,
    nintishoSenmoncare: e.mainCode.nintishoSenmoncare,
    serviceRiyoJoken: e.mainCode.serviceRiyoJoken,
    jikanEntyou: e.mainCode.jikanEntyou,
    gaibuserviceShuruicode: e.mainCode.gaibuserviceShuruicode,
    higaito: e.mainCode.higaito,
    yousien: e.mainCode.yousien,
    yousien1: e.mainCode.yousien1,
    youkaigo1: e.mainCode.youkaigo1,
    youkaigo2: e.mainCode.youkaigo2,
    youkaigo3: e.mainCode.youkaigo3,
    youkaigo4: e.mainCode.youkaigo4,
    youkaigo5: e.mainCode.youkaigo5,
    serviceKikanMasterId: e.formValues.sid ?? e.mainCode.serviceKikanMasterId,
    kyufukanriTani: e.mainCode.kyufukanriTani,
    conditionType76:
      e.mainCode.shuruiCode === "76" ? "shuruicode:" + e.mainCode.shuruiCode + ",serviceRiyoJoken:" + e.mainCode.serviceRiyoJoken : null,
    listDataChildrenFilter,
    tani: e.mainCode.tani ?? e.formValues.tani,
    planf: e.mainCode.planf,
    isQuoteActual: e.formValues?.isQuoteActual,
    existedEl: false
  };
  return serviceCodeMain;
};

export { 
  handleCalculationAddNewRecord, 
  handleFilterDuplicateRecordEdit, 
  handleCalculateServiceCodeMain, 
  handleCalculateServiceCodeMainQuote, 
  handleFilterDuplicateRecord, 
  checkValueEachMode, 
  handleTypeSpecialType13 
};
