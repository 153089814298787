import React from "react";
import { Modal, Button } from "antd";

export function BatchCreation(props) {
  return (
    (<Modal
      open={props.visible}
      title={"最新の計画書からモニタリングを一括作成します"}
      onCancel={() => props.handleCancel()}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={() => props.handleCancel()}
        >
          キャンセル
        </Button>,
        <Button key="back" type="primary" onClick={() => props.handleOk()}>
          Ok
        </Button>,
      ]}
      width={500}
    ></Modal>)
  );
}
