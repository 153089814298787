import React from "react";
import { Input } from "antd";
import { connect } from "react-redux";
import { getMasterNewCode } from "../../../../actions/referenceNumberActions/actionCreators";

class MasterCodeInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      check: false,
    };
  }

  componentDidMount() {
    const { reference } = this.props;

    if (this.props.checkType) {
      this.props.getMasterNewCode(reference.table, reference.field, (data) => {
        this.setState({
          text: data,
        });
        this.props.onChange(data, true);
      });
    } else {
      this.setState({
        text: this.props.value,
        check: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.check && prevProps.value != this.props.value) {
      this.setState({
        text: this.props.value,
      });
    }
  }

  onChangeValue(e) {
    this.setState({
      text: e.target.value,
      check: true,
    });
    this.props.onChange(e.target.value, false);
  }

  render() {
    const { text } = this.state;
    const { maxLength, style } = this.props;

    return (
      <div style={style}>
        <Input
          value={text}
          maxLength={maxLength}
          onChange={(e) => this.onChangeValue(e)}
        />
      </div>
    );
  }
}

const mapStateToProps = () => (state) => ({});

const mapDispatchToProps = () => (dispatch) => ({
  getMasterNewCode: (table, field, callback) =>
    dispatch(getMasterNewCode(table, field, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MasterCodeInput);
