import { Spin } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getRiyousya } from "../../../actions/riyousyaActions/actionCreators";
import { getSisetuDailyPlan } from "../../../actions/sisetuDailyPlanActions/actionCreators";
import { CommonPageContainer } from "../../../common/component/IdleTimerPage";
import "../DocumentList.css";
import KyotakuDailyPlanInputFormWrapper from "./InputDailyPlan";

class SisetuDailyPlanPlan extends Component {
  constructor(props) {
    super(props);
    const { sisetuDailyPlanId, riyousyaId } = this.props.match.params;
    this.state = {
      riyousyaId,
      sisetuDailyPlanId,
    };
  }
  componentDidMount() {
    this.props.getRiyousyaInfo(this.state.riyousyaId);
    this.props.getSisetuDailyPlan(this.state.sisetuDailyPlanId);
    window.scroll(0, 0);
  }
  render() {
    const { sisetuDailyPlanId } = this.props.match.params;
    return (
      <CommonPageContainer
        id={"input-kyotaku-dailyplan-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <Spin spinning={false}>
          <KyotakuDailyPlanInputFormWrapper
            riyousyaId={this.state.riyousyaId}
            sisetuDailyPlan={this.props.sisetuDailyPlan}
            riyousya={this.props.riyousya}
            history={this.props.history}
            sisetuDailyPlanId={sisetuDailyPlanId}
            match={this.props.match}
          />
        </Spin>
      </CommonPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    riyousya: state.riyousya.riyousya,
    sisetuDailyPlan: state.sisetuDailyPlan.sisetuDailyPlan,
    notifyDisplay: state.sisetuDailyPlan.notifyDisplay,
    notifyContent: state.sisetuDailyPlan.notifyContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
    getSisetuDailyPlan: (id) => dispatch(getSisetuDailyPlan(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SisetuDailyPlanPlan);
