function reverseString(str) {
  return str.split("").reverse().join("");
}

// 出席者は、「;」を改行に変換し、最初の「,」を「（」に変換して「;」の前に「）」を入れる
// Attendees convert ";" to line breaks, convert the first "," to "(" and put ")" before ";"
function parseAttendance(data) {
  if (!data) return data;
  const attendances = data.split(";");

  for (let i = 0; i < attendances.length; i++) {
    let attendance = attendances[i];
    attendance = attendance.trim();

    if (attendance) {
      const match = attendance.match(/,/g);

      if (match && match.length > 1) {
        let newAttendance = attendance.replace(/,\s*/, " (");
        newAttendance += ")";
        attendances[i] = newAttendance;
      }
    }
  }

  const parsedData = attendances.join("\n");
  return parsedData;
}

/*
  input:
  attendanceArray = [{
    affiliation: "a1",
    type: "t1",
    name: "n1"
  }, {
    affiliation: "a2",
    type: "t2",
    name: "n2"
  }]

  output:
  attendanceString = "a1, t1, n1; a2, t2, n2"
*/
function arrayToAttendanceString(attendanceArray) {
  let attendanceString = "";

  for (const attendance of attendanceArray) {
    if (attendance.affiliation || attendance.type || attendance.name) {
      if (attendanceString) {
        attendanceString += ";";
      }

      attendanceString += `${attendance.affiliation},${attendance.type},${attendance.name}`;
    }
  }
  return attendanceString;
}

/*
  input:
  attendanceString = "a1, t1, n1; a2, t2, n2"

  output:
  attendanceArray = [{
    affiliation: "a1",
    type: "t1",
    name: "n1"
  }, {
    affiliation: "a2",
    type: "t2",
    name: "n2"
  }]
*/
function stringToAttendanceArray(attendanceString) {
  if (!attendanceString) return [{ affiliation: "", type: "", name: "" }];

  // remove last ;
  attendanceString = attendanceString.replace(/;$/, "");

  const attendanceArray = [];
  const attendanceSplit = attendanceString.split(";");

  for (const attendance of attendanceSplit) {
    const [affiliation, type, name] = attendance.split(",");

    attendanceArray.push({
      affiliation: affiliation && affiliation.trim(),
      type: type && type.trim(),
      name: name && name.trim(),
    });
  }

  return attendanceArray;
}

function lineBreakAttendance(text) {
  // remove last ;
  let newText = text.replace(/;$/, "");

  // replace all ; with \n
  newText = newText.replace(/;/g, "\n");

  return newText;
}

export {
  parseAttendance,
  arrayToAttendanceString,
  stringToAttendanceArray,
  lineBreakAttendance,
};
