/* eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

const fabric = require("fabric").fabric;

class RectangleText extends FabricCanvasTool {
  constructor(canvas, text) {
    super(canvas);
    this._text = text;
  }

  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => o.selectable = o.evented = false);
    this._width = 1;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.call_back = props.update_select;
  }

  doMouseDown(o) {
    const canvas = this._canvas;
    this.isDown = true;
    this.ismove = false;
    const pointer = canvas.getPointer(o.e);
    this.startX = pointer.x;
    this.startY = pointer.y;
    this.rect = new fabric.Rect({
      left: this.startX,
      top: this.startY,
      originX: "left",
      originY: "top",
      width: 0,
      height: 0,
      stroke: this._color,
      strokeWidth: 1,
      fill: this._isfill ? "rgba(0,0,0,1)" : this._fill,
      transparentCorners: false,
      selectable: false,
      evented: false,
      angle: 0
    });
    let fontsize = 20;
    if (this._text.length <= 2) {
      fontsize = 40;
    }
    else if (this._text.length === 3) {
      fontsize = 30;
    }
    else {
      fontsize = 20;
    }
    this.txt = new fabric.Text(this._text, {
      left: -100,
      top: -100,
      originX: "center",
      originY: "center",
      fontSize: fontsize,
    });
    // this.groupc = new fabric.Group([this.rect, this.txt], {
    //   left: this.startX,
    //   top: this.startY,
    //   angle: 0,
    //   selectable: true,
    // });
    // canvas.add(this.groupc);
    canvas.add(this.rect);
    canvas.add(this.txt);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    this.ismove = true;
    const canvas = this._canvas;
    const pointer = canvas.getPointer(o.e);
    if (this.startX > pointer.x) {
      this.rect.set({ left: Math.abs(pointer.x) });
    }
    if (this.startY > pointer.y) {
      this.rect.set({ top: Math.abs(pointer.y) });
    }
    this.rect.set({ width: Math.abs(this.startX - pointer.x) });
    this.rect.set({ height: Math.abs(this.startY - pointer.y) });
    // this.rect.setCoords();
    this.txt.setOptions({
      left: this.startX + Math.abs(this.startX - pointer.x) / 2,
      top: this.startY + Math.abs(this.startY - pointer.y) / 2,
      originX: "center",
      originY: "center",
    });
     this.txt.setCoords();
   //  canvas.renderAll();

    // this.groupc = new fabric.Group([this.rect, this.txt], {
    //     left: this.startX,
    //     top: this.startY,
    //     angle: 0,
    //     selectable: true,
    // });
    // canvas.add(this.groupc);

    // this.groupc.set(
    //     {
    //         scaleX: pointer.x / this.startX,
    //         scaleY: pointer.x / this.startX,
    //     }
    // );

    // this.groupc.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    const canvas = this._canvas;
    if (!this.ismove) {
      canvas.remove(this.rect);
      canvas.remove(this.txt);
      return;
    }
    this.isDown = false;

    this.groupc = new fabric.Group([this.rect, this.txt], {
      left: this.startX,
      top: this.startY,
      angle: 0,
      selectable: true,
    });
    canvas.add(this.groupc);
    canvas.remove(this.rect);
    canvas.remove(this.txt);

    this.call_back();
  }
}

export default RectangleText;