import {
  KOHIFUTAN_MASTER_SPIN_REQUEST,
  KOHIFUTAN_MASTER_LOAD_REQUEST,
  KOHIFUTAN_MASTER_CREATE_REQUEST,
  KOHIFUTAN_MASTER_UPDATE_REQUEST,
  KOHIFUTAN_MASTER_INIT_REQUEST,
  KOHIFUTAN_MASTER_UPDATELIST_REQUEST,
  LOAD_KOHIFUTAN_MASTER_INIT_SUCCESS,
  LOAD_KOHIFUTAN_MASTER_INIT_ERROR,
  CREATE_KOHIFUTAN_MASTER_SUCCESS,
  CREATE_KOHIFUTAN_MASTER_ERROR,
  UPDATE_KOHIFUTAN_MASTER_SUCCESS,
  UPDATE_KOHIFUTAN_MASTER_ERROR,
  UPDATE_LIST_KOHIFUTAN_MASTER_SUCCESS,
  LOAD_MAX_SORT_SUCCESS,
  LOAD_MAX_SORT_ERROR,
  LOAD_KOHIFUTAN_MASTER_SUCCESS,
  LOAD_KOHIFUTAN_MASTER_ERROR,
} from "./actionName";

import { kohifutanMasterApi } from "../../api/kohifutanMaster";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

//追加時,初期化
export function loadKohifutanMasterInit(callback) {
  return (dispatch) => {
    dispatch({
      type: KOHIFUTAN_MASTER_INIT_REQUEST,
    });
    kohifutanMasterApi.init().then(
      (data) => {
        dispatch({
          type: LOAD_KOHIFUTAN_MASTER_INIT_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KOHIFUTAN_MASTER_INIT_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//新規追加
export function createKohifutanMaster(body, callback) {
  return (dispatch) => {
    dispatch({
      type: KOHIFUTAN_MASTER_CREATE_REQUEST,
    });
    kohifutanMasterApi.create(body).then(
      (data) => {
        dispatch({
          type: CREATE_KOHIFUTAN_MASTER_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_KOHIFUTAN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//修正
export function updateKohifutanMaster(body, callback) {
  return (dispatch) => {
    dispatch({
      type: KOHIFUTAN_MASTER_UPDATE_REQUEST,
    });
    kohifutanMasterApi.update(body).then(
      (data) => {
        dispatch({
          type: UPDATE_KOHIFUTAN_MASTER_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KOHIFUTAN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function loadMaxSort(callback) {
  return (dispatch) => {
    // dispatch({
    //   type: KOHIFUTAN_MASTER_SPIN_REQUEST,
    // })
    kohifutanMasterApi.loadMaxSort().then(
      (data) => {
        dispatch({
          type: LOAD_MAX_SORT_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_MAX_SORT_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//並び替え
export function updateKohifutanMasterList(body, callback) {
  return (dispatch) => {
    dispatch({
      type: KOHIFUTAN_MASTER_UPDATELIST_REQUEST,
    });
    kohifutanMasterApi.updateList(body).then(
      (data) => {
        dispatch({
          type: UPDATE_LIST_KOHIFUTAN_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KOHIFUTAN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

// /**
//  * 介護保険マスタ検索
//  * 利用者アクションで代用がある
//  * @param {*} name 担当者名
//  * @param {*} id serviceKikanのid
//  * @returns
//  */
// export function loadKohifutanMaster(name, id) {
//   return (dispatch) => {
//     dispatch({
//       type: KOHIFUTAN_MASTER_LOAD_REQUEST,
//     })
//     kohifutanMasterApi.list(name, id).then(
//       (data) => {
//         dispatch({
//           type: LOAD_KOHIFUTAN_MASTER_SUCCESS,
//           data: data,
//         })
//       },
//       (err) => {
//         checkError(err);
//         dispatch({
//           type: LOAD_KOHIFUTAN_MASTER_ERROR,
//           error:
//             err.response.status === 400
//               ? err.response.data.error
//               : { message: err.response.statusText },
//         });
//       }
//     );
//   };
// }

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
