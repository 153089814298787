export const UseSlipActionType = {
  GET_RIYOUHYOU_BY_ID: "GET_RIYOUHYOU_BY_ID",
  GET_RIYOUHYOU_BY_ID_SUCCESS: "GET_RIYOUHYOU_BY_ID_SUCCESS",
  GET_RIYOUHYOU_BY_ID_FAILURE: "GET_RIYOUHYOU_BY_ID_FAILURE",

  GET_LIST_SERVICE_TYPE: "GET_LIST_SERVICE_TYPE",
  GET_LIST_SERVICE_TYPE_SUCCESS: "GET_LIST_SERVICE_TYPE_SUCCESS",
  GET_LIST_SERVICE_TYPE_FAILURE: "GET_LIST_SERVICE_TYPE_FAILURE",

  GET_ALL_USESLIP_REQUEST: "GET_ALL_USESLIP_REQUEST",
  GET_ALL_USESLIP_SUCCESS: "GET_ALL_USESLIP_SUCCESS",
  GET_ALL_USESLIP_FAILURE: "GET_ALL_USESLIP_FAILURE",

  GET_SERVICE_CONTENT_BY_ID: "GET_SERVICE_CONTENT_BY_ID",
  GET_SERVICE_CONTENT_BY_ID_SUCCESS: "GET_SERVICE_CONTENT_BY_ID_SUCCESS",
  GET_SERVICE_CONTENT_BY_ID_FAILURE: "GET_SERVICE_CONTENT_BY_ID_FAILURE",

  GET_SERVICE_ADDITION_BY_TYPE: "GET_SERVICE_ADDITION_BY_TYPE",
  GET_SERVICE_ADDITION_BY_TYPE_SUCCESS: "GET_SERVICE_ADDITION_BY_TYPE_SUCCESS",
  GET_SERVICE_ADDITION_BY_TYPE_FAILURE: "GET_SERVICE_ADDITION_BY_TYPE_FAILURE",

  GET_SYURUI_CD: "GET_SYURUI_CD",
  GET_SYURUI_CD_SUCCESS: "GET_SYURUI_CD_SUCCESS",
  GET_SYURUI_CD_FAILURE: "GET_SYURUI_CD_FAILURE",

  GET_SERVICE_CODE: "GET_SERVICE_CODE",
  GET_SERVICE_CODE_SUCCESS: "GET_SERVICE_CODE_SUCCESS",
  GET_SERVICE_CODE_FAILURE: "GET_SERVICE_CODE_FAILURE",
  GET_EXPAND_CODE: "GET_EXPAND_CODE",
  GET_EXPAND_CODE_SUCCESS: "GET_EXPAND_CODE_SUCCESS",
  GET_EXPAND_CODE_FAILURE: "GET_EXPAND_CODE_FAILURE",

  GET_ALL_USESLIP_QUOTE_REQUEST: "GET_ALL_USESLIP_QUOTE_REQUEST",
  GET_ALL_USESLIP_QUOTE_SUCCESS: "GET_ALL_USESLIP_QUOTE_SUCCESS",
  GET_ALL_USESLIP_QUOTE_FAILURE: "GET_ALL_USESLIP_QUOTE_FAILURE",

  REMOVE_USESLIP_REQUEST: "REMOVE_USESLIP_REQUEST",
  REMOVE_USESLIP_SUCCESS: "REMOVE_USESLIP_SUCCESS",
  REMOVE_USESLIP_FAILURE: "REMOVE_USESLIP_FAILURE",

  GET_SERVICE_ADDITION_REQUEST: "GET_SERVICE_ADDITION_REQUEST",
  GET_SERVICE_ADDITION_SUCCESS: "GET_SERVICE_ADDITION_SUCCESS",
  GET_SERVICE_ADDITION_FAILURE: "GET_SERVICE_ADDITION_FAILURE",

  GET_SERVICE_ADDITION_BY_OFFICE_REQUEST:
    "GET_SERVICE_ADDITION_BY_OFFICE_REQUEST",
  GET_SERVICE_ADDITION_BY_OFFICE_SUCCESS:
    "GET_SERVICE_ADDITION_BY_OFFICE_SUCCESS",
  GET_SERVICE_ADDITION_BY_OFFICE_FAILURE:
    "GET_SERVICE_ADDITION_BY_OFFICE_FAILURE",

  GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_REQUEST:
    "GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_REQUEST",
  GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_SUCCESS:
    "GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_SUCCESS",
  GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_FAILURE:
    "GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_FAILURE",

  GET_SERVICE_COLOR_MASTER_USESLIP_REQUEST:
    "GET_SERVICE_COLOR_MASTER_USESLIP_REQUEST",
  GET_SERVICE_COLOR_MASTER_USESLIP_SUCCESS:
    "GET_SERVICE_COLOR_MASTER_USESLIP_SUCCESS",
  GET_SERVICE_COLOR_MASTER_USESLIP_FAILURE:
    "GET_SERVICE_COLOR_MASTER_USESLIP_FAILURE",
  GET_NAIYOU_REQUEST: "GET_NAIYOU_REQUEST",
  GET_NAIYOU_SUCCESS: "GET_NAIYOU_SUCCESS",
  GET_NAIYOU_FAILURE: "GET_NAIYOU_FAILURE",
  GET_WEEKLY_SCHEDULE_REQUEST: "GET_WEEKLY_SCHEDULE_REQUEST",
  GET_WEEKLY_SCHEDULE_SUCCESS: "GET_WEEKLY_SCHEDULE_SUCCESS",
  GET_WEEKLY_SCHEDULE_FAILURE: "GET_WEEKLY_SCHEDULE_FAILURE",
  GET_DUPLICATE_CHECK_NOT_POSSIBLE_REQUEST:
    "GET_DUPLICATE_CHECK_NOT_POSSIBLE_REQUEST",
  GET_DUPLICATE_CHECK_NOT_POSSIBLE_SUCCESS:
    "GET_DUPLICATE_CHECK_NOT_POSSIBLE_SUCCESS",
  GET_DUPLICATE_CHECK_NOT_POSSIBLE_FAILURE:
    "GET_DUPLICATE_CHECK_NOT_POSSIBLE_FAILURE",

  GET_INFORMATION_KAIGOHOKEN_REQUEST: "GET_INFORMATION_KAIGOHOKEN_REQUEST",
  GET_INFORMATION_KAIGOHOKEN_SUCCESS: "GET_INFORMATION_KAIGOHOKEN_SUCCESS",
  GET_INFORMATION_KAIGOHOKEN_FAILURE: "GET_INFORMATION_KAIGOHOKEN_FAILURE",

  UPDATE_RIYOUHYOU_REQUEST: "UPDATE_RIYOUHYOU_REQUEST",
  UPDATE_RIYOUHYOU_SUCCESS: "UPDATE_RIYOUHYOU_SUCCESS",
  SET_RIYOUHYOU_UNSUCESS: "SET_RIYOUHYOU_UNSUCESS",
  UPDATE_RIYOUHYOU_FAILURE: "UPDATE_RIYOUHYOU_FAILURE",

  REMOVE_ADDITION_OFFICE: "REMOVE_ADDITION_OFFICE",

  REMOVE_ADDITION_DUPLICATE: "REMOVE_ADDITION_DUPLICATE",

  GET_HOLIDAY_MASTER_REQUEST: "GET_HOLIDAY_MASTER_REQUEST",
  GET_HOLIDAY_MASTER_SUCCESS: "GET_HOLIDAY_MASTER_SUCCESS",
  GET_HOLIDAY_MASTER_FAILURE: "GET_HOLIDAY_MASTER_FAILURE",

  REMOVE_RIYOUHYOU: "REMOVE_RIYOUHYOU",

  GET_RIYOYHYOU_IDS_REQUEST: "GET_RIYOYHYOU_IDS_REQUEST",
  GET_RIYOYHYOU_IDS_SUCCESS: "GET_RIYOYHYOU_IDS_SUCCESS",
  GET_RIYOYHYOU_IDS_FAILURE: "GET_RIYOYHYOU_IDS_FAILURE",

  REMOVE_NAIYOU: "REMOVE_NAIYOU",

  GET_SERVICE_ADDITION_REQUEST_OLD: "GET_SERVICE_ADDITION_REQUEST_OLD",
  GET_SERVICE_ADDITION_SUCCESS_OLD: "GET_SERVICE_ADDITION_SUCCESS_OLD",
  GET_SERVICE_ADDITION_FAILURE_OLD: "GET_SERVICE_ADDITION_FAILURE_OLD",

  REMOVE_EXPAND_CODE: "REMOVE_EXPAND_CODE",

  GET_ATTACHED_REQUEST: "GET_ATTACHED_REQUEST",
  GET_ATTACHED_SUCCESS: "GET_ATTACHED_SUCCESS",
  GET_ATTACHED_FAILURE: "GET_ATTACHED_FAILURE",

  SET_RIYOUHYOUFORM: "SET_RIYOUHYOUFORM",
  SET_RIYOUHYOUFORM_SUCESS: "SET_RIYOUHYOUFORM_SUCESS",
  REMOVE_RIYOUHYOUFORM: "REMOVE_RIYOUHYOUFORM",
  GET_URL: "GET_URL",
  IS_LOADING: "IS_LOADING",

  GET_RENZOKU_LAST_MONTH_REQUEST: "GET_RENZOKU_LAST_MONTH_REQUEST",
  GET_RENZOKU_LAST_MONTH_SUCESS: "GET_RENZOKU_LAST_MONTH_SUCESS",
  GET_RENZOKU_LAST_MONTH_FAIL: "GET_RENZOKU_LAST_MONTH_FAIL",
  STOP_LOADING: "STOP_LOADING",

  GET_OVER_PROCESS: "GET_OVER_PROCESS",
  GET_OVER_PROCESS_ACTUAL: "GET_OVER_PROCESS_ACTUAL",

  GET_KYUFUDAYS_REQUEST: "GET_KYUFUDAYS_REQUEST",
  GET_KYUFUDAYS_SUCCESS: "GET_KYUFUDAYS_SUCCESS",
  GET_KYUFUDAYS_FAILURE: "GET_KYUFUDAYS_FAILURE",

  GET_SERVICE_COLOR_MASTER_SONOTA_REQUEST: "GET_SERVICE_COLOR_MASTER_SONOTA_REQUEST",
  GET_SERVICE_COLOR_MASTER_SONOTA_SUCCESS: "GET_SERVICE_COLOR_MASTER_SONOTA_SUCCESS",
  GET_SERVICE_COLOR_MASTER_SONOTA_FAILURE: "GET_SERVICE_COLOR_MASTER_SONOTA_FAILURE",

  SET_LIST_DATA_SERVICE_SONOTA: "SET_LIST_DATA_SERVICE_SONOTA",
  SET_LIST_ATTACHED: "SET_LIST_ATTACHED",
  SET_LIST_HISTORY: "SET_LIST_HISTORY",
  SET_LIST_MANAGEMENT: "SET_LIST_MANAGEMENT",
  SET_OLD_PARAMS: "SET_OLD_PARAMS",

  GET_LIST_RIYOUHYOU_QUOTES_REQUEST: "GET_LIST_RIYOUHYOU_QUOTES_REQUEST",
  GET_LIST_RIYOUHYOU_QUOTES_SUCCESS: "GET_LIST_RIYOUHYOU_QUOTES_SUCCESS",
  GET_LIST_RIYOUHYOU_QUOTES_FAILURE: "GET_LIST_RIYOUHYOU_QUOTES_FAILURE",

  GET_RIYOUHYOU_QUOTE_BY_ID_REQUEST: "GET_RIYOUHYOU_QUOTE_BY_ID_REQUEST",
  GET_RIYOUHYOU_QUOTE_BY_ID_SUCCESS: "GET_RIYOUHYOU_QUOTE_BY_ID_SUCCESS",
  GET_RIYOUHYOU_QUOTE_BY_ID_FAILURE: "GET_RIYOUHYOU_QUOTE_BY_ID_FAILURE",

  GET_RIYOUHYOU_LAST_QUOTE_BY_ID_REQUEST: "GET_RIYOUHYOU_LAST_QUOTE_BY_ID_REQUEST",
  GET_RIYOUHYOU_LAST_QUOTE_BY_ID_SUCCESS: "GET_RIYOUHYOU_LAST_QUOTE_BY_ID_SUCCESS",
  GET_RIYOUHYOU_LAST_QUOTE_BY_ID_FAILURE: "GET_RIYOUHYOU_LAST_QUOTE_BY_ID_FAILURE",

  CLEAR_RIYOUHYOU_QUOTE: "CLEAR_RIYOUHYOU_QUOTE",
  CLEAR_RIYOUHYOU: "CLEAR_RIYOUHYOU",

  GET_RIYOUHYOU_OF_RENZOKU_DAY_REQUEST: "GET_RIYOUHYOU_OF_RENZOKU_DAY_REQUEST",
  GET_RIYOUHYOU_OF_RENZOKU_DAY_SUCCESS: "GET_RIYOUHYOU_OF_RENZOKU_DAY_SUCCESS",
  GET_RIYOUHYOU_OF_RENZOKU_DAY_FAILURE: "GET_RIYOUHYOU_OF_RENZOKU_DAY_FAILURE",

  GET_URL_FROM_USE_SLIP: "GET_URL_FROM_USE_SLIP",
  REMOVE_URL_FROM_USE_SLIP: "REMOVE_URL_FROM_USE_SLIP",
  GET_URL_FROM_MANAGEMENT: "GET_URL_FROM_MANAGEMENT",

  GET_ALL_SYURUI_MASTER_REQUEST: "GET_ALL_SYURUI_MASTER_REQUEST",
  GET_ALL_SYURUI_MASTER_SUCCESS: "GET_ALL_SYURUI_MASTER_SUCCESS",
  GET_ALL_SYURUI_MASTER_FAILURE: "GET_ALL_SYURUI_MASTER_FAILURE",


  GET_RIYOUHYOU_VIEW_BY_ID_REQUEST: "GET_RIYOUHYOU_VIEW_BY_ID_REQUEST",
  GET_RIYOUHYOU_VIEW_BY_ID_SUCCESS: "GET_RIYOUHYOU_VIEW_BY_ID_SUCCESS",
  GET_RIYOUHYOU_VIEW_BY_ID_FAILURE: "GET_RIYOUHYOU_VIEW_BY_ID_FAILURE",

  REMOVE_RIYOUHYOU_VIEW: "REMOVE_RIYOUHYOU_VIEW",
  STOP_LOADING_VIEW: "STOP_LOADING_VIEW",

  DUPLICATE_MONTH_LIST_MANAGEMENT: "DUPLICATE_MONTH_LIST_MANAGEMENT",
  SET_LIST_OFFICE_ACTUAL: "SET_LIST_OFFICE_ACTUAL",
  SET_LIST_OFFICE_ACTUAL_CHECKED: "SET_LIST_OFFICE_ACTUAL_CHECKED",
  UPDATE_QUOTE_ACTUAL_BY_TYPE: "UPDATE_QUOTE_ACTUAL_BY_TYPE",
  SET_LIST_WEEKLY_QUOTE_ACTUAL: "SET_LIST_WEEKLY_QUOTE_ACTUAL",

  GET_WEEKLY_QUOTE_REQUEST: "GET_WEEKLY_QUOTE_REQUEST",
  GET_WEEKLY_QUOTE_SUCCESS: "GET_WEEKLY_QUOTE_SUCCESS",
  GET_WEEKLY_QUOTE_FAILURE: "GET_WEEKLY_QUOTE_FAILURE",

  COPY_DATA_QUOTE_ACTUAL: "COPY_DATA_QUOTE_ACTUAL",

  SAVE_URL_USESLIP: "SAVE_URL_USESLIP",

  SAVE_SELECT_KEY: "SAVE_SELECT_KEY"
};
