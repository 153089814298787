import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Table } from "antd";
import { connect } from "react-redux";
import { loadTantoKaigi } from "../../../../actions/tantoKaigiActions/actionCreators";

class TantoKaigiByDateModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sort: {
        gijiroku_date: "desc",
      },
      selectedRecord: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.props.loadTantoKaigi(
        {
          riyousyaId: this.props.riyousyaId,
          page: this.state.page,
          sort: this.state.sort,
          excludeId: this.props.tantoKaigiId,
        },
        (callback) => {}
      );
    }
  }

  handleOk = () => {
    this.props.onHide();
    const { selectedRecord } = this.state;

    if (selectedRecord && selectedRecord.id) {
      this.props.onSelect(selectedRecord);
    }
  };

  handleCancel = () => {
    this.props.onHide();
  };

  handleSelectRow(record) {
    this.setState({ selectedRecord: record });
  }

  handlePageChange(page) {
    this.setState({ page });
    this.props.loadTantoKaigi(
      {
        riyousyaId: this.props.riyousyaId,
        page,
        sort: this.state.sort,
        excludeId: this.props.tantoKaigiId,
      },
      (callback) => {}
    );
  }

  render() {
    const { loading, tantoKaigis, metaTantoKaigi } = this.props;

    const columns = [
      {
        title: "",
        dataIndex: "gijirokuDate",
        key: "gijirokuDate",
      },
    ];
    const { selectedRecord } = this.state;
    const pagingOptions = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: (metaTantoKaigi && metaTantoKaigi.total) || 0,
      current: (metaTantoKaigi && metaTantoKaigi.page) || 1,
      pageSize: (metaTantoKaigi && metaTantoKaigi.pageSize) || 10,
    };

    return (
      (<Modal
        open={this.props.visible}
        title="前回引用"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="tanto-kaigi-by-date-modal"
      >
        <Table
          showHeader={false}
          rowKey="id"
          loading={loading}
          style={{ cursor: "pointer" }}
          size={"small"}
          bordered={true}
          columns={columns}
          dataSource={tantoKaigis || []}
          pagination={pagingOptions}
          onRow={(record) => ({
            onClick: () => this.handleSelectRow(record),
          })}
          rowClassName={(record) =>
            selectedRecord != null && record.id === selectedRecord.id
              ? "pljp-table-row-hover"
              : "pointer-row"
          }
        />
      </Modal>)
    );
  }
}

TantoKaigiByDateModal.propTypes = {
  visible: PropTypes.bool,
  riyousyaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  onHide: PropTypes.func,
};

const mapStateToProps = () => (state) => ({
  tantoKaigis: state.documentList.tantoKaigis,
  metaTantoKaigi: state.documentList.metaTantoKaigi,
  loading: state.documentList.loading,
});

const mapDispatchToProps = () => (dispatch) => ({
  loadTantoKaigi: (args, callback) => dispatch(loadTantoKaigi(args, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TantoKaigiByDateModal);
