import {
  USER_LIST,
  FILTER_GROUP,
  SEARCH_NAME,
  USER_FILTER_GROUP,
  USER_LOGIN_DETAIL,
  UPDATE_USERS_LIST,
} from "../../actions/userListActions/actionName.js";
import { filterUserByGroupLocal } from "../../common/function_common/functionCommon.js";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  users: [],
  groupName: "全表示",
  name: "",
  userDetail: {},
};

export default function userListReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_USERS_LIST:
      return {
        ...state,
        users: state.users.filter((item) => action.id !== item.id),
      };
    case USER_LIST:
      return {
        ...state,
        users: action.users,
        urserOri: action.users,
      };

    case FILTER_GROUP:
      return {
        ...state,
        groupName: action.groupName,
      };

    case SEARCH_NAME:
      return {
        ...state,
        name: action.name,
      };

    case USER_FILTER_GROUP:
      return {
        ...state,
        users: filterUserByGroupLocal(state.urserOri, action.value),
      };

    case USER_LOGIN_DETAIL:
      return {
        ...state,
        userDetail: action.payload ? action.payload : {},
      };

    default:
      return state;
  }
}
