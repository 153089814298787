export const YOUGO_MASTER_NAIYOU_ALL = "YOUGO_MASTER_NAIYOU_ALL";
export const YOUGO_MASTER_NAIYOU = "YOUGO_MASTER_NAIYOU";
export const YOUGO_MASTER_NAIYOU2 = "YOUGO_MASTER_NAIYOU2";
export const YOUGO_MASTER_NAIYOU_NEW = "YOUGO_MASTER_NAIYOU_NEW";
export const YOUGO_MASTER_NAIYOU_BUNRUI = "YOUGO_MASTER_NAIYOU_NUNRUI";
export const YOUGO_MASTER_TANTO = "YOUGO_MASTER_TANTO";
export const YOUGO_MASTER_SYURUI = "YOUGO_MASTER_SYURUI";
export const YOUGO_MASTER_JOUTAI = "YOUGO_MASTER_JOUTAI";
export const YOUGO_MASTER_KUSURI = "YOUGO_MASTER_KUSURI";
export const YOUGO_MASTER_EDIT_STATE = "YOUGO_MASTER_EDIT_STATE";
export const YOUGO_MASTER_EDIT = "YOUGO_MASTER_EDIT";
export const YOUGO_MASTER_EDIT_CANCEL = "YOUGO_MASTER_EDIT_CANCEL";
export const YOUGO_MASTER_ECT_SELECT = "YOUGO_MASTER_ECT_SELECT";
export const YOUGO_MASTER_ECT_STATE = "YOUGO_MASTER_ECT_STATE";
export const YOUGO_MASTER_ECT_SAVE = "YOUGO_MASTER_ECT_SAVE";
export const YOUGO_MASTER_ECT_CANCEL = "YOUGO_MASTER_ECT_CANCEL";
export const YOUGO_MASTER_SHOKUJISEN_PAGE_LIST = "YOUGO_MASTER_SHOKUJISEN_PAGE_LIST";
export const YOUGO_MASTER_RIYOU_EDIT_PAGE_LIST = "YOUGO_MASTER_RIYOU_EDIT_PAGE_LIST";
export const YOUGO_MASTER_UPDATE_NEW = "YOUGO_MASTER_UPDATE_NEW";
export const YOUGO_MASTER_UPDATE_ROOM = "YOUGO_MASTER_UPDATE_ROOM";
export const YOUGO_MASTER_WEATHER_NAIYOU = "YOUGO_MASTER_WEATHER_NAIYOU";
export const YOUGO_MASTER_ROOM_NAIYOU = "YOUGO_MASTER_ROOM_NAIYOU";
export const YOUGO_MASTER_TANTOBUNRUI_NAIYOU =
  "YOUGO_MASTER_TANTOBUNRUI_NAIYOU";
export const YOUGO_MASTER_STAFFKIND_NAIYOU = "YOUGO_MASTER_STAFFKIND_NAIYOU";
export const YOUGO_MASTER_DEPART_NAIYOU = "YOUGO_MASTER_DEPART_NAIYOU";
export const YOUGO_MASTER_LOAD = "YOUGO_MASTER_LOAD";

