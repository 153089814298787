import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import {
  DOCTOR_MASTER_SPIN_REQUEST,
  LOAD_DOCTOR_MASTER_SUCCESS,
  LOAD_DOCTOR_MASTER_ERROR,
  LOAD_MAX_SORT_SUCCESS,
  LOAD_MAX_SORT_ERROR,
  LOAD_DOCTOR_MASTER_INIT_SUCCESS,
  LOAD_DOCTOR_MASTER_INIT_ERROR,
  CREATE_DOCTOR_MASTER_SUCCESS,
  CREATE_DOCTOR_MASTER_ERROR,
  UPDATE_DOCTOR_MASTER_SUCCESS,
  UPDATE_DOCTOR_MASTER_ERROR,
  UPDATE_LIST_DOCTOR_MASTER_SUCCESS,
} from "../../actions/doctorMasterActions/actionName";

export default function doctorMaster(state = {}, action) {
  switch (action.type) {
    case DOCTOR_MASTER_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_DOCTOR_MASTER_SUCCESS:
      return {
        ...state,
        doctorMasterList: action.data,
        loading: false,
      };

    case LOAD_DOCTOR_MASTER_ERROR:
      return {
        ...state,
        doctorMasterList: null,
        loading: false,
      };

    case LOAD_MAX_SORT_SUCCESS:
      return {
        ...state,
        maxSort: action.data,
        loading: false,
      };

    case LOAD_MAX_SORT_ERROR:
      return {
        ...state,
        maxSort: null,
        loading: false,
      };

    case LOAD_DOCTOR_MASTER_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorMaster: action.data,
      };

    case LOAD_DOCTOR_MASTER_INIT_ERROR:
      return {
        ...state,
        loading: false,
        doctorMaster: null,
      };

    case CREATE_DOCTOR_MASTER_SUCCESS:
    case UPDATE_DOCTOR_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        doctorMaster: action.data,
      };

    case UPDATE_LIST_DOCTOR_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        doctorMasterList: action.payload,
        loading: false,
      };

    case CREATE_DOCTOR_MASTER_ERROR:
    case UPDATE_DOCTOR_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        doctorMaster: null,
        loading: false,
      };

    default:
      return state;
  }
}
