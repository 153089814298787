import React, { useState, useEffect } from "react";
import { Modal, Button, Select } from "antd";
const { Option } = Select;
function ServiceNaiyouRentalModal(props) {
  const [serviceNaiyou, setServiceNaiyou] = useState();
  const [serviceCd, setServiceCd] = useState();
  useEffect(() => {
    if (props.recordSelected) {
      setServiceCd(props.recordSelected.serviceCd);
      setServiceNaiyou(props.recordSelected.serviceName);
    }
  }, [props.recordSelected]);

  function handleChangeServiceNaiyou(value, option) {
    setServiceCd(value);
    setServiceNaiyou(option.props.children);
  }
  function handleCancel() {
    props.onCancel();
  }

  function handleOk() {
    props.onOk(props.recordSelected.key, { serviceCd, serviceNaiyou });
  }

  return (
    (<Modal
      open={props.visible}
      title={props.title}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={handleCancel}
        >
          キャンセル
        </Button>,
        <Button key="back" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
      width={350}
    >
      <div>
        {props.titleName}
        <Select
          value={serviceCd}
          style={{ width: 150, marginLeft: 10 }}
          onChange={handleChangeServiceNaiyou}
        >
          <Option key={null} value={null}>
            &nbsp;
          </Option>
          {props.serviceNaiyou.map((item) => (
            <Option key={item.id} value={item.kaigoServiceSyuruiCd + item.item}>
              {item.serviceNaiyou}
            </Option>
          ))}
        </Select>
      </div>
    </Modal>)
  );
}

export { ServiceNaiyouRentalModal };
