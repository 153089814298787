import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import { WeeklyPlanActions } from "../../redux/actions";
import YougoModal from "./YougoModal";
import _ from "lodash";

const { Option } = Select;

function RentalSyohinMasterModal(props) {
  const [product, setProduct] = useState({});
  const [visibleBunrui, setVisibleBunrui] = useState(false);
  const [errorProductNameVisible, setErrorProductNameVisible] = useState();
  const [errorServiceNameVisible, setErrorServiceNameVisible] = useState();

  useEffect(() => {
    if (props.visible) {
      if (props.id) {
        props.getRentalSyohinMaster(props.id);
      } else {
        // setProduct(prevValue => ({...prevValue, serviceName: props.serviceName, serviceCd: props.serviceCd}));
        props.getRentalSyohinMasterInit();
      }
    }
  }, [props.visible]);

  useEffect(() => {
    if (props.id) {
      setProduct(props.rentalSyohinMaster || {});
    } else {
      setProduct(
        props.rentalSyohinMaster
          ? {
              ...props.rentalSyohinMaster,
              serviceName: props.serviceName,
              serviceCd: props.serviceCd,
            }
          : { serviceName: props.serviceName, serviceCd: props.serviceCd }
      );
    }
  }, [props.rentalSyohinMaster]);

  function checkRequired() {
    let isError = false;
    if (_.isEmpty(product.name)) {
      setErrorProductNameVisible(true);
      isError = true;
    } else {
      setErrorProductNameVisible(false);
    }

    if (_.isEmpty(product.serviceName) || product.serviceName == " ") {
      setErrorServiceNameVisible(true);
      isError = true;
    } else {
      setErrorServiceNameVisible(false);
    }
    return isError;
  }

  const handleSave = () => {
    if (checkRequired()) return;

    if (props.id) {
      props.updateRentalSyohinMaster(product);
    } else {
      props.createRentalSyohinMaster(product);
    }
    props.onOk();
    setErrorProductNameVisible(false);
    setErrorServiceNameVisible(false);
  };

  const handleYougoOk = (values) => {
    setVisibleBunrui(false);
    setProduct((prevValue) => ({ ...prevValue, bunrui: values }));
  };

  const onChangeValue = (e, field) => {
    // use stopPropagation instead of preventDefault to avoid checkbox click twice
    e.stopPropagation();
    let newValue = product[field];
    switch (field) {
      case "serviceTani":
        if (/^\d*$/.test(e.target.value)) {
          newValue = e.target.value;
        }
        break;
      case "stop":
        newValue = e.target.checked ? 1 : 0;
        break;
      default:
        newValue = e.target.value;
    }
    if (newValue !== product[field]) {
      setProduct((prevValue) => ({ ...prevValue, [field]: newValue }));
    }
  };

  const onChangeSelectService = (value, option) => {
    setProduct((prevValue) => ({
      ...prevValue,
      serviceCd: value,
      serviceName: option.props.children,
    }));
  };

  function handleCancel() {
    props.onCancel();
    setErrorProductNameVisible(false);
    setErrorServiceNameVisible(false);
  }
  return (
    (<Fragment>
      <Modal
        destroyOnClose={true}
        open={props.visible}
        onCancel={handleCancel}
        footer={[
          <Row key={1} type="flex" justify="end">
            <div style={{ paddingRight: 8 }}>
              <Button onClick={handleCancel}>{"戻る"}</Button>
            </div>
            <div>
              <Button type={"primary"} onClick={handleSave}>
                {"保存"}
              </Button>
            </div>
          </Row>,
        ]}
      >
        <Row
          type={"flex"}
          justify={"start"}
          align={"middle"}
          gutter={16}
          style={{ paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Col span={6}>
            <label>ID</label>
          </Col>
          <Col span={4}>
            <Input
              value={product.id}
              onChange={(e) => onChangeValue(e, "id")}
              readOnly
            />
          </Col>
        </Row>
        <Row
          type={"flex"}
          justify={"start"}
          align={"middle"}
          gutter={16}
          style={{ paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Col span={6}>
            <label>フリガナ</label>
          </Col>
          <Col span={18}>
            <Input
              value={product.furigana}
              onChange={(e) => onChangeValue(e, "furigana")}
            />
          </Col>
        </Row>
        <Row
          type={"flex"}
          justify={"start"}
          align={"middle"}
          gutter={16}
          style={{ paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Col span={6}>
            <label>商品名</label>
          </Col>
          <Col span={18}>
            <Input
              value={product.name}
              onChange={(e) => onChangeValue(e, "name")}
              style={errorProductNameVisible ? { border: "1px solid red" } : {}}
            />
          </Col>
        </Row>
        <Row
          type={"flex"}
          justify={"start"}
          align={"middle"}
          gutter={16}
          style={{ paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Col span={6}>
            <label>TAISコード</label>
          </Col>
          <Col span={18}>
            <Input
              value={product.taisCd}
              onChange={(e) => onChangeValue(e, "taisCd")}
            />
          </Col>
        </Row>
        <Row
          type={"flex"}
          justify={"start"}
          align={"middle"}
          gutter={16}
          style={{ paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Col span={6}>
            <label>分類</label>
          </Col>
          <Col
            span={18}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Input
              value={product.bunrui}
              onChange={(e) => onChangeValue(e, "bunrui")}
              style={{ width: "calc(100% - 55px)" }}
            />
            <Button type="primary" onClick={() => setVisibleBunrui(true)}>
              M
            </Button>
          </Col>
        </Row>
        <Row
          type={"flex"}
          justify={"start"}
          align={"middle"}
          gutter={16}
          style={{ paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Col span={6}>
            <label>サービス名</label>
          </Col>
          <Col span={12}>
            <Select
              value={product.serviceName}
              onChange={onChangeSelectService}
              className={errorServiceNameVisible ? "required-select" : ""}
              style={{ width: "100%" }}
            >
              <Option key={null} value={null}>
                &nbsp;
              </Option>
              {props.serviceContentRental
                ? props.serviceContentRental.map((item) => (
                    <Option
                      key={item.id}
                      value={item.kaigoServiceSyuruiCd + item.item}
                    >
                      {item.serviceNaiyou}
                    </Option>
                  ))
                : null}
            </Select>
          </Col>
          <Col span={6}>
            <Input
              value={product.serviceCd}
              onChange={(e) => onChangeValue(e, "serviceCd")}
              style={errorServiceNameVisible ? { border: "1px solid red" } : {}}
              readOnly
            />
          </Col>
        </Row>
        <Row
          type={"flex"}
          justify={"start"}
          align={"middle"}
          gutter={16}
          style={{ paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Col span={6}>
            <label>サービス単位</label>
          </Col>
          <Col span={4}>
            <Input
              value={product.serviceTani}
              onChange={(e) => onChangeValue(e, "serviceTani")}
            />
          </Col>
        </Row>
        <Row
          type={"flex"}
          justify={"start"}
          align={"middle"}
          gutter={16}
          style={{ paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Col span={6}>
            <label>利用中止</label>
          </Col>
          <Col span={8}>
            <Checkbox
              type={"checkbox"}
              checked={product.stop}
              onChange={(e) => onChangeValue(e, "stop")}
            >
              利用中止
            </Checkbox>
          </Col>
        </Row>
        <Row
          type={"flex"}
          justify={"start"}
          align={"middle"}
          gutter={16}
          style={{ paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Col span={6}>
            <label>順番</label>
          </Col>
          <Col span={4}>
            <Input
              value={product.sortNum}
              onChange={(e) => onChangeValue(e, "sortNum")}
            />
          </Col>
        </Row>
      </Modal>
      <YougoModal
        visible={visibleBunrui}
        onCancel={() => setVisibleBunrui(false)}
        onSubmit={handleYougoOk}
        listName={"レンタル分類"}
      />
    </Fragment>)
  );
}

const mapStateToProps = (state) => {
  return {
    rentalSyohinMaster: state.weeklyPlan.rentalSyohinMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRentalSyohinMaster: (id) =>
      dispatch(WeeklyPlanActions.getRentalSyohinMaster(id)),
    createRentalSyohinMaster: (data) =>
      dispatch(WeeklyPlanActions.createRentalSyohinMaster(data)),
    updateRentalSyohinMaster: (data) =>
      dispatch(WeeklyPlanActions.updateRentalSyohinMaster(data)),
    getRentalSyohinMasterInit: () =>
      dispatch(WeeklyPlanActions.getRentalSyohinMasterInit()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentalSyohinMasterModal);
