import { Icon as LegacyIcon } from "@ant-design/compatible";
import { UndoOutlined } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import React, { Component } from "react";
import { guid } from "../../timePicker/formatTimePicker";
import FuriganaKeyBoard from "../FuriganaKeyBoard";

const FA = require("react-fontawesome");

export default class SearchRiyousyaControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      furiganaText: this.props.searchName,
    };
  }

  showModalFuriKey() {
    this.setState({
      visible: true,
    });
  }

  handleSearchRiyouOk() {
    this.setState({
      visible: false,
    });
    this.props.handleSearch(this.state.furiganaText);
  }

  handleSearchRiyouCancel() {
    this.setState({
      visible: false,
      furiganaText: this.props.searchName,
    });
  }

  getHandleFurigana(furi) {
    this.setState({
      furiganaText: furi,
    });
  }

  onChangeFurigana(e) {
    this.setState({
      furiganaText: e.target.value,
    });
  }

  searchByName() {
    this.props.handleSearch(this.state.furiganaText);
  }

  clearInput() {
    this.setState({
      furiganaText: "",
    });
    this.props.handleSearch("");
  }

  render() {
    return (
      (<div style={this.props.style} className={"search-riyousya"}>
        <Button onClick={() => this.showModalFuriKey()}>
          <FA name="keyboard" key={guid()} />
        </Button>
        <Input
          value={this.state.furiganaText}
          onChange={(e) => this.onChangeFurigana(e)}
          style={{ width: "150px", borderRadius: 4 }}
        />
        <Button onClick={() => this.clearInput()}>
          <UndoOutlined />
        </Button>
        <Button
          type={"primary"}
          icon={<LegacyIcon type={"search"} />}
          onClick={() => this.searchByName()}
        >
          {"検索"}
        </Button>
        <Modal
          title="ふりがな検索"
          open={this.state.visible}
          onOk={() => this.handleSearchRiyouOk()}
          onCancel={() => this.handleSearchRiyouCancel()}
          className={"hiragana-model"}
          width={560}
        >
          <FuriganaKeyBoard
            furigana={(furi) => this.getHandleFurigana(furi)}
            furiganaState={this.state.furiganaText}
          />
        </Modal>
      </div>)
    );
  }
}
