import React from "react";
import { Link } from "react-router-dom";
import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

export default class PlanNavigator extends React.Component {
  render() {
    return (
      <ul style={{ display: "inline" }}>
        <li>
          <Link
            to={"#"}
            disabled={this.props.isLast}
            onClick={(e) => {
              e.preventDefault();
              if (!this.props.isLast) this.props.goLast();
            }}
          >
            <span>
              <DoubleRightOutlined />
            </span>
            <span>最後</span>
          </Link>
        </li>
        <li>
          <Link
            to={"#"}
            disabled={this.props.isLast}
            onClick={(e) => {
              e.preventDefault();
              if (!this.props.isLast) this.props.goNext();
            }}
          >
            <span>
              <RightOutlined />
            </span>
            <span>次へ</span>
          </Link>
        </li>
        <li>
          <Link
            to={"#"}
            disabled={this.props.isFirst}
            onClick={(e) => {
              e.preventDefault();
              if (!this.props.isFirst) this.props.goPrev();
            }}
          >
            <span>
              <LeftOutlined />
            </span>
            <span>前へ</span>
          </Link>
        </li>
        <li>
          <Link
            to={"#"}
            disabled={this.props.isFirst}
            onClick={(e) => {
              e.preventDefault();
              if (!this.props.isFirst) this.props.goFirst();
            }}
          >
            <span>
              <DoubleLeftOutlined />
            </span>
            <span>最初</span>
          </Link>
        </li>
      </ul>
    );
  }
}
