import { ApiPaths } from "../../constants/api_paths";
import { RequestUtils, StorageUtils } from "../../utils/index";
import { ServiceKikanMasterActionTypes } from "../action_types/serviceKikanMaster";
import { ErrorActionTypes, WeeklyPlanActionTypes } from "../action_types";

function getById(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_ID_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetById,
        params: { id },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_ID_FAILURE,
        error,
      });
    }
  };
}

function getInitialServiceKikan() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_INITIAL_SERVICE_KIKAN_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetInitialServiceKikan,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_INITIAL_SERVICE_KIKAN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_INITIAL_SERVICE_KIKAN_FAILURE,
        error,
      });
    }
  };
}

function createServiceKikanMaster(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.CREATE_SERVICE_KIKAN_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ServiceKikanMaster.Create,
        body,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.CREATE_SERVICE_KIKAN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.CREATE_SERVICE_KIKAN_FAILURE,
        error,
      });
    }
  };
}

function updateServiceKikanMaster(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.UPDATE_SERVICE_KIKAN_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.ServiceKikanMaster.Update,
        body,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.UPDATE_SERVICE_KIKAN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.UPDATE_SERVICE_KIKAN_FAILURE,
        error,
      });
    }
  };
}

function getServiceSyuruiMasters() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetServiceSyuruiMasters,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_FAILURE,
        error,
      });
    }
  };
}

function getSyuruiCdByServiceType(serviceType) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetSyuruiCdByServiceType,
        query: { serviceType },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_FAILURE,
        error,
      });
    }
  };
}

function getServiceAdditions(syuruiCd) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetServiceAdditions,
        query: { syuruiCd },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_FAILURE,
        error,
      });
    }
  };
}

function searchHojinInfoMasters(searchText) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.SearchHojinInfoMasters,
        query: { searchText },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_FAILURE,
        error,
      });
    }
  };
}

function checkLinkedWeeklySchedule(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.CHECK_LINKED_WEEKLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.CheckLinkedWeeklySchedule,
        query: { id },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.CHECK_LINKED_WEEKLY_SUCCESS,
        data: { sid: id, isLinked: data },
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.CHECK_LINKED_WEEKLY_FAILURE,
        error,
      });
    }
  };
}

function createHojinInfoMaster(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.CREATE_HOJIN_INFO_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ServiceKikanMaster.CreateHojinInfoMaster,
        body,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.CREATE_HOJIN_INFO_MASTER_SUCCESS,
        data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.CREATE_HOJIN_INFO_MASTER_FAILURE,
        error,
      });
      return null;
    }
  };
}

function getHojinInfoMasterById(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_HOJIN_INFO_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetHojinInfoMasterById,
        params: { id },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_HOJIN_INFO_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_HOJIN_INFO_MASTER_FAILURE,
        error,
      });
    }
  };
}

function duplicateServiceKikanMaster(sid, syuruiId) {
  return async (dispatch) => {
    try {
      return await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ServiceKikanMaster.Duplicate,
        query: { sid, syuruiId },
      });
    } catch (error) {
      return null;
    }
  };
}

function getServiceKikanMonthlyById(id) {}

function createServiceKikanMonthly(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.CREATE_SERVICE_MONTHLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ServiceKikanMaster.CreateServiceKikanMonthly,
        body,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.CREATE_SERVICE_MONTHLY_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.CREATE_SERVICE_MONTHLY_FAILURE,
        error,
      });
    }
  };
}

function updateServiceKikanMonthly(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.UPDATE_SERVICE_MONTHLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.ServiceKikanMaster.UpdateServiceKikanMonthly,
        body,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.UPDATE_SERVICE_MONTHLY_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.UPDATE_SERVICE_MONTHLY_FAILURE,
        error,
      });
    }
  };
}

function deleteServiceKikanMonthly(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.DELETE_SERVICE_MONTHLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "DELETE",
        path: ApiPaths.ServiceKikanMaster.DeleteServiceKikanMonthly,
        params: { id },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.DELETE_SERVICE_MONTHLY_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.DELETE_SERVICE_MONTHLY_FAILURE,
        error,
      });
    }
  };
}

function getListServiceKikanMonthly(sid) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_LIST_SERVICE_MONTHLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetListServiceKikanMonthly,
        query: { sid },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_LIST_SERVICE_MONTHLY_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_LIST_SERVICE_MONTHLY_FAILURE,
        error,
      });
    }
  };
}

function getTaiseiMaster(query) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_TAISEI_MASTERS_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetTaiseiMaster,
        query,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_TAISEI_MASTERS_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_TAISEI_MASTERS_FAILURE,
        error,
      });
    }
  };
}

function getTikibetuTikikubun(query) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetTikibetuTikikubun,
        query,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_FAILURE,
        error,
      });
    }
  };
}

function getTikiKubunMaster(query) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_TIKI_KUBUN_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetTikiKubunMaster,
        query,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_TIKI_KUBUN_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_TIKI_KUBUN_MASTER_FAILURE,
        error,
      });
    }
  };
}

function getListByServiceType(serviceType, name, kaigoCode) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sn = menu.sn;
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SERVICE_TYPE_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetListByServiceType,
        query: { serviceType, name, kaigoCode, sn },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SERVICE_TYPE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SERVICE_TYPE_FAILURE,
        error,
      });
    }
  };
}

function getListBySyuruiCd(syuruiCd, name, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SYURUI_CD_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetListBySyuruiCd,
        query: { syuruiCd, name },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SYURUI_CD_SUCCESS,
        data,
      });
      return callback(data);
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SYURUI_CD_FAILURE,
        error,
      });
    }
  };
}

function save(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({ type: ServiceKikanMasterActionTypes.SAVE_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ServiceKikanMaster.Save,
        body,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.SAVE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.SAVE_FAILURE,
        error,
      });
    }
  };
}

function getListByServiceTypePlan2(serviceType, name) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SERVICE_TYPE_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetListByServiceTypePlan2,
        query: { serviceType, name },
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SERVICE_TYPE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SERVICE_TYPE_FAILURE,
        error,
      });
    }
  };
}

function turnOffReload() {
  return (dispatch) =>
    dispatch({
      type: ServiceKikanMasterActionTypes.TURN_OFF_RELOAD_SERVICE_KIKAN,
    });
}

function getMaxSortNum() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_MAX_SORT_NUM_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetMaxSortNum,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_MAX_SORT_NUM_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.GET_MAX_SORT_NUM_FAILURE,
        error,
      });
    }
  };
}

function getHojinInfoMasterByName(jigyousyoName, callback) {
  return () => {
    try {
      RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ServiceKikanMaster.GetHojinInfoMasterByName,
        query: { jigyousyoName },
      }).then((data) => {
        return callback(data);
      });
    } catch (error) {
      return null;
    }
  };
}

function updateServiceKikanList(body, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ServiceKikanMasterActionTypes.UPDATE_SERVICE_KIKAN_LIST_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.ServiceKikanMaster.UpdateList,
        body,
      });
      dispatch({
        type: ServiceKikanMasterActionTypes.UPDATE_SERVICE_KIKAN_LIST_SUCCESS,
        data,
      });
      return callback(data);
    } catch (error) {
      dispatch({
        type: ServiceKikanMasterActionTypes.UPDATE_SERVICE_KIKAN_LIST_FAILURE,
        error,
      });
    }
  };
}

export const ServiceKikanMasterActions = {
  getById,
  getInitialServiceKikan,
  createServiceKikanMaster,
  updateServiceKikanMaster,
  getServiceSyuruiMasters,
  getServiceAdditions,
  searchHojinInfoMasters,
  checkLinkedWeeklySchedule,
  createHojinInfoMaster,
  getHojinInfoMasterById,
  duplicateServiceKikanMaster,
  getServiceKikanMonthlyById,
  createServiceKikanMonthly,
  updateServiceKikanMonthly,
  deleteServiceKikanMonthly,
  getListServiceKikanMonthly,
  getTaiseiMaster,
  getTikibetuTikikubun,
  getTikiKubunMaster,
  getListByServiceType,
  getListBySyuruiCd,
  save,
  getListByServiceTypePlan2,
  getSyuruiCdByServiceType,
  turnOffReload,
  getMaxSortNum,
  getHojinInfoMasterByName,
  updateServiceKikanList,
};
