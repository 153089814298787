import _ from "lodash";
import "moment/locale/ja";
import React, { Component } from "react";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import { ConstSet } from "../../common/configs/constset";
import { InputFormWrapper } from "./UI/InputForm";

class InputReportCase extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props.match;
    this.state = {
      riyousyaId: params.riyousyaId,
      reportCaseId: params.reportCaseId,
    };
  }

  componentDidMount() {
    this.props.getFilterMasterData();
    if (!this.props.tantoMasters) this.props.getTantoMasterList();
    this.props.getRiyousyaInfo(this.state.riyousyaId);
    this.props.getReportCaseById(this.props.match.params.reportCaseId);
  }

  handleCreate(err, values) {
    if (err) {
      return;
    }
    this.registerReportCase(values);
  }

  async registerReportCase(values) {
    if (this.props.reportCase) {
      const data = _.cloneDeep(this.props.reportCase);
      data.date = values.date.format(ConstSet.DATE_FORMAT);
      (data.time = values.time ? values.time.format("HH:mm:ss") : "00:00:00"), (data.kiroku_kubun = values.kiroku_kubun);
      data.sonota_kubun = values.sonota_kubun;
      data.tyuif = values.tyuif.length;
      data.nishif = values.nishif.length;
      data.tanto_id = values.tanto_id;
      data.naiyou = values.naiyou;
      data.naiyou2 = values.naiyou2;
      await this.props.updateReportCase(data);
    }
    else {
      const data = {
        riyousyaId: this.state.riyousyaId,
        date: values.date.format(ConstSet.DATE_FORMAT),
        time: values.time ? values.time.format("HH:mm:ss") : "00:00:00",
        kiroku_kubun: values.kiroku_kubun,
        sonota_kubun: values.sonota_kubun,
        tyuif: values.tyuif.length,
        nishif: values.nishif.length,
        tanto_id: values.tanto_id,
        naiyou: values.naiyou,
        naiyou2: values.naiyou2,
      };
      await this.props.createReportCase(data);
    }
    this.props.history.goBack();
  }

  render() {
    return (
      <CommonPageContainer
        id={"report-case-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <InputFormWrapper
          data={this.props.reportCase}
          kirokuKubuns={
            this.props.filterMasterData &&
              this.props.filterMasterData.kirokuKubun
              ? this.props.filterMasterData.kirokuKubun.split(";")
              : []
          }
          sonotaKubuns={
            this.props.filterMasterData &&
              this.props.filterMasterData.sonotaKubun
              ? this.props.filterMasterData.sonotaKubun.split(";")
              : []
          }
          riyousya={this.props.riyousya}
          history={this.props.history}
          regData={(err, values) => this.handleCreate(err, values)}
          loading={false}
        />
      </CommonPageContainer>
    );
  }
}

export default InputReportCase;
