/**
 *
 * @param {*} myData
 */
function moji_daku(myData) {
  let text = myData;

  switch (text) {
    // かきくけこ
    case "か":
      text = "が";
      break;
    case "き":
      text = "ぎ";
      break;
    case "く":
      text = "ぐ";
      break;
    case "け":
      text = "げ";
      break;
    case "こ":
      text = "ご";
      break;

    // さしすせそ
    case "さ":
      text = "ざ";
      break;
    case "し":
      text = "じ";
      break;
    case "す":
      text = "ず";
      break;
    case "せ":
      text = "ぜ";
      break;
    case "そ":
      text = "ぞ";
      break;

    // たちつてと
    case "た":
      text = "だ";
      break;
    case "ち":
      text = "ぢ";
      break;
    case "つ":
      text = "づ";
      break;
    case "て":
      text = "で";
      break;
    case "と":
      text = "ど";
      break;

    // はひふへほ
    case "は":
      text = "ば";
      break;
    case "ひ":
      text = "び";
      break;
    case "ふ":
      text = "ぶ";
      break;
    case "へ":
      text = "べ";
      break;
    case "ほ":
      text = "ぼ";
      break;

    // ばびぶべぼ
    case "ば":
      text = "は";
      break;
    case "び":
      text = "ひ";
      break;
    case "ぶ":
      text = "ふ";
      break;
    case "べ":
      text = "へ";
      break;
    case "ぼ":
      text = "ほ";
      break;

    // ぱぴぷぺぽ
    case "ぱ":
      text = "ば";
      break;
    case "ぴ":
      text = "び";
      break;
    case "ぷ":
      text = "ぶ";
      break;
    case "ぺ":
      text = "べ";
      break;
    case "ぽ":
      text = "ぼ";
      break;
    default:
      break;
  }
  return text;
}

/**
 *
 * @param {*} myData
 */
function moji_handaku(myData) {
  let text = myData;

  switch (text) {
    // はひふへほ
    case "は":
      text = "ぱ";
      break;
    case "ひ":
      text = "ぴ";
      break;
    case "ふ":
      text = "ぷ";
      break;
    case "へ":
      text = "ぺ";
      break;
    case "ほ":
      text = "ぽ";
      break;

    // ぱぴぷぺぽ
    case "ぱ":
      text = "は";
      break;
    case "ぴ":
      text = "ひ";
      break;
    case "ぷ":
      text = "ふ";
      break;
    case "ぺ":
      text = "へ";
      break;
    case "ぽ":
      text = "ほ";
      break;

    // ばびぶべぼ
    case "ば":
      text = "ぱ";
      break;
    case "び":
      text = "ぴ";
      break;
    case "ぶ":
      text = "ぷ";
      break;
    case "べ":
      text = "ぺ";
      break;
    case "ぼ":
      text = "ぽ";
      break;
    default:
      break;
  }
  return text;
}

/**
 *
 * @param {*} myData
 */
function moji_small(myData) {
  let text = myData;
  switch (text) {
    case "あ":
      text = "ぁ";
      break;
    case "い":
      text = "ぃ";
      break;
    case "う":
      text = "ぅ";
      break;
    case "え":
      text = "ぇ";
      break;
    case "お":
      text = "ぉ";
      break;
    case "つ":
      text = "っ";
      break;
    case "や":
      text = "ゃ";
      break;
    case "ゆ":
      text = "ゅ";
      break;
    case "よ":
      text = "ょ";
      break;

    //
    case "ぁ":
      text = "あ";
      break;
    case "ぃ":
      text = "い";
      break;
    case "ぅ":
      text = "う";
      break;
    case "ぇ":
      text = "え";
      break;
    case "ぉ":
      text = "お";
      break;
    case "っ":
      text = "つ";
      break;
    case "ゃ":
      text = "や";
      break;
    case "ゅ":
      text = "ゆ";
      break;
    case "ょ":
      text = "よ";
      break;
    default:
      break;
  }

  return text;
}

export const Furigana = {
  moji_daku,
  moji_handaku,
  moji_small,
};
