import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import {
  // UPDATE_LIST_DOCTOR_MASTER_SUCCESS,

  KAIGOHOKEN_MASTER_SPIN_REQUEST,
  KAIGOHOKEN_MASTER_LOAD_REQUEST,
  KAIGOHOKEN_MASTER_CREATE_REQUEST,
  KAIGOHOKEN_MASTER_UPDATE_REQUEST,
  KAIGOHOKEN_MASTER_INIT_REQUEST,
  KAIGOHOKEN_MASTER_UPDATELIST_REQUEST,
  LOAD_KAIGOHOKEN_MASTER_INIT_SUCCESS,
  LOAD_KAIGOHOKEN_MASTER_INIT_ERROR,
  CREATE_KAIGOHOKEN_MASTER_SUCCESS,
  CREATE_KAIGOHOKEN_MASTER_ERROR,
  UPDATE_KAIGOHOKEN_MASTER_SUCCESS,
  UPDATE_KAIGOHOKEN_MASTER_ERROR,
  UPDATE_LIST_KAIGOHOKEN_MASTER_SUCCESS,
  LOAD_KAIGOHOKEN_MASTER_SUCCESS,
  LOAD_KAIGOHOKEN_MASTER_ERROR,
} from "../../actions/kaigoHokenMasterActions/actionName";

export default function kaigoHokenMaster(state = {}, action) {
  switch (action.type) {
    case KAIGOHOKEN_MASTER_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KAIGOHOKEN_MASTER_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KAIGOHOKEN_MASTER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KAIGOHOKEN_MASTER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KAIGOHOKEN_MASTER_INIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KAIGOHOKEN_MASTER_UPDATELIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //初期値
    case LOAD_KAIGOHOKEN_MASTER_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        initKaigoHoken: action.data,
      };
    case LOAD_KAIGOHOKEN_MASTER_INIT_ERROR:
      return {
        ...state,
        loading: false,
        initKaigoHoken: null,
      };

    //追加
    case CREATE_KAIGOHOKEN_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        createKaigoHoken: action.data,
      };
    case CREATE_KAIGOHOKEN_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        createKaigoHoken: null,
        loading: false,
      };

    //修正
    case UPDATE_KAIGOHOKEN_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateKaigoHoken: action.payload,
        loading: false,
      };
    case UPDATE_KAIGOHOKEN_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        updateKaigoHoken: null,
        loading: false,
      };

    //並び替え
    case UPDATE_LIST_KAIGOHOKEN_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateListKaigoHoken: action.payload,
        loading: false,
      };

    //マスター読み込みはriyousyaで行われている
    //使われてないリデューサー
    case LOAD_KAIGOHOKEN_MASTER_SUCCESS:
      return {
        ...state,
        doctorMasterList: action.data,
        loading: false,
      };

    case LOAD_KAIGOHOKEN_MASTER_ERROR:
      return {
        ...state,
        doctorMasterList: null,
        loading: false,
      };

    default:
      return state;
  }
}
