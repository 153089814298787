import { KaigoYougoMasterApi } from "../../api/kaigoYougoMaster";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
import {
  LOAD_KAIGO_YOUGO_ALL, LOAD_KAIGO_YOUGO_ALL_SUCCESS, LOAD_KAIGO_YOUGO_ERROR, LOAD_KAIGO_YOUGO_ID_ERROR, LOAD_KAIGO_YOUGO_ID_REQUEST,
  LOAD_KAIGO_YOUGO_ID_SUCCESS,
  LOAD_KAIGO_YOUGO_MAX_SORT, LOAD_KAIGO_YOUGO_REQUEST, LOAD_KAIGO_YOUGO_SUCCESS, UPDATE_KAIGO_YOUGO_ERROR, UPDATE_KAIGO_YOUGO_LIST_REQUEST, UPDATE_KAIGO_YOUGO_LIST_SUCCESS, UPDATE_KAIGO_YOUGO_REQUEST,
  UPDATE_KAIGO_YOUGO_SUCCESS
} from "./actionName";

export function getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page) {
  return (dispatch) => {
    dispatch({
      type: LOAD_KAIGO_YOUGO_REQUEST,
    });
    KaigoYougoMasterApi.getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page).then(
      (data) => {
        dispatch({
          type: LOAD_KAIGO_YOUGO_SUCCESS,
          payload: data
        })
        console.log(data)
      },
      err => {
        checkError(err);
        dispatch({
          type: LOAD_KAIGO_YOUGO_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getKaigoYougoListAll(
  attribute,
  bunrui1,
  bunrui2,
  keyword,
  callback
) {
  //検索タブ
  return (dispatch) => {
    dispatch({
      type: LOAD_KAIGO_YOUGO_REQUEST,
    });
    KaigoYougoMasterApi.getKaigoYougoListAll(
      attribute,
      bunrui1,
      bunrui2,
      keyword
    ).then(
      (data) => {
        dispatch({
          type: LOAD_KAIGO_YOUGO_ALL_SUCCESS,
          payload: data
        })
        callback(data);
      },
      err => {
        checkError(err);
        dispatch({
          type: LOAD_KAIGO_YOUGO_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getKaigoYougoAll(callback) {
  //検索タブ
  return (dispatch) => {
    dispatch({
      type: LOAD_KAIGO_YOUGO_REQUEST,
    });
    KaigoYougoMasterApi.getKaigoYougoAll().then(
      (data) => {
        dispatch({
          type: LOAD_KAIGO_YOUGO_ALL,
          payload: data
        })
        console.log(data)
        callback(data);
      },
      err => {
        checkError(err);
        dispatch({
          type: LOAD_KAIGO_YOUGO_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getKaigoYougoId(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_KAIGO_YOUGO_ID_REQUEST,
    });
    KaigoYougoMasterApi.getKaigoYougoId(id).then(
      (data) =>
        dispatch({
          type: LOAD_KAIGO_YOUGO_ID_SUCCESS,
          payload: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KAIGO_YOUGO_ID_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function updateKaigoYougo(
  id,
  attribute,
  bunrui1,
  bunrui2,
  keyword,
  naiyou
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_KAIGO_YOUGO_REQUEST,
    });
    KaigoYougoMasterApi.updateKaigoYougo(
      id,
      attribute,
      bunrui1,
      bunrui2,
      keyword,
      naiyou
    ).then(
      (data) =>
        dispatch({
          type: UPDATE_KAIGO_YOUGO_SUCCESS,
          payload: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KAIGO_YOUGO_ERROR,
          error: err.response.status === 400 ? err.response.data.error : { message: err.response.statusText }
        })
      }
    );
  };
}

export function updateKaigoYougoList(data, callback) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_KAIGO_YOUGO_LIST_REQUEST,
    });
    KaigoYougoMasterApi.updateKaigoYougoList(data).then(
      (data) => {
        dispatch({
          type: UPDATE_KAIGO_YOUGO_LIST_SUCCESS,
          payload: data
        })
        callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KAIGO_YOUGO_ERROR,
          error: err.response.status === 400 ? err.response.data.error : { message: err.response.statusText }
        })
      }
    );
  };
}

export function getMaxSortNum(callback) {
  return (dispatch) => {
    KaigoYougoMasterApi.getMaxSortNum().then(
      (data) => {
        dispatch({
          type: LOAD_KAIGO_YOUGO_MAX_SORT,
          payload: data
        }),
          callback(data)
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KAIGO_YOUGO_ERROR,
          error: err.response.status === 400 ? err.response.data.error : { message: err.response.statusText }
        })
      }
    );
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
