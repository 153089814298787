/**
 * Replace params with object value
 * @param {string} str
 * @param {object} obj
 * return {string}
 *
 * @example
 * str: string = '/posts/:id/:hello'
 * obj: object = { id: 100, hello: 'world' }
 * replace(str, obj)
 *
 * return '/posts/100/world'
 *
 */
function replace(str, obj) {
  const pattern = /:[a-zA-Z]+/g;
  const matches = str.match(pattern);

  if (matches) {
    for (const match of matches) {
      const objKey = match.replace(":", "");
      str = str.replace(match, obj[objKey]);
    }
  }
  return str;
}

export const StringUtils = {
  replace,
};
