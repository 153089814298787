/* eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

const fabric = require("fabric").fabric;

class TwoCircle extends FabricCanvasTool {

  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => o.selectable = o.evented = false);
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.call_back = props.update_select;
  }

  doMouseDown(o) {
    const canvas = this._canvas;
    this.isDown = true;
    const pointer = canvas.getPointer(o.e);
    [this.startX, this.startY] = [pointer.x, pointer.y];
    const circle = new fabric.Circle({
      left: this.startX, top: this.startY,
      originX: "center", originY: "center",
      strokeWidth: this._width,
      stroke: this._color,
      fill: this._fill,
      selectable: false,
      evented: false,
      radius: 50
    });
    const circle2 = new fabric.Circle({
      left: this.startX, top: this.startY,
      originX: "center", originY: "center",
      strokeWidth: this._width,
      stroke: this._color,
      fill: this._fill,
      selectable: false,
      evented: false,
      radius: 30
    });
    this.groupc  = new fabric.Group([circle, circle2], {
      left: this.startX,
      top: this.startY,
      angle: 0,
      selectable: true,
    });
    canvas.add(this.groupc);
    // canvas.add(this.circle);
    // canvas.add(this.circle2);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    const canvas = this._canvas;
    const pointer = canvas.getPointer(o.e);
    this.groupc.set(
      {
        scaleX: pointer.x / this.startX,
        scaleY: pointer.x / this.startX,
      }
    );
    this.groupc.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.call_back();
  }
}

export default TwoCircle;