export default {
  error: {
    code: null,
    message: "",
  },
  config: {
    menu: {
      // TODO set default menu here
    },
    values: {},
  },
  auth: {
    isUserLoggedIn: false,
    loading: false,
    dataMode: "",
    gmk: "",
    menu: "",
    mfs: "",
    sessionTimeout: "",
  },
  document: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
  },
  plan1: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  plan2: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  weeklyPlan: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    loadingColor: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
    listQuote: [],
    listColorMaster: [],
    listServiceContent: [],
    listServiceAddition: [],
    listQuoteTab: [],
    listKeepValueSchedule: [],
    listQuoteRental: [],
    listServiceContentRental: [],
    listRentalSyohin: [],
  },
  monitoringMNGT: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  meeting: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  progressMNGT: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  inquiryRequest: {
    loadingData: false,
    yougoNaiyou: [],
    tantoMaster: [],
    serviceKikan: [],
    serviceSyuruiMaster: [],
    dataInquiryRequest: {},
    syoukaiIraiDetailList: [],
    SyoukiaDate: [],
    idsInquiRequest: [],
    reload: false,
    dataList: [],
    metaDataList: {},
  },
  monitoringManagement: {
    createBatch: {},
    list: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 100,
      total: 0,
    },
    keepValue: {},
    isReload: false,
  },
  useslip: {
    riyouhyouView: {},
    additionDuplicate: [],
    listAttached: [],
    isGetOverProcess: false,
    riyouhyouForm: {
      id: null,
      riyousyaId: null,
      riyouhyouDate: null,
      sid: null,
      nengetu: null,
      hokensyaNo: null,
      hihokenNo: null,
      kaigodoName: null,
      yukoDateFrom: null,
      tekiyouDate: null,
      yukoDateTo: null,
      tantoId: null,
      adoptf: false,
      kaigoHokenId: null,
      comment: null,
      douiDate: null,
      listDataService: [],
      listDataNotParent: [],
      listDataShow: [],
      formValuesDouble: null,
      kubunsikyu: null,
      tankiDays: null,
      renzokuDays: null
    },
    listDataServiceSonota: [],
    listHistory: [],
    isStartManagement: false,
    listManagement: [],
    urlUseSlip: null,
    idCreated: null,
    isDuplicateMonthListManagement: false,
    listOfficeActual: [],
    listOfficeActualChecked: [],
    listWeeklyQuote: [],
    listDataAttached: {
      dataAttachedPlan: [],
      smallTotalPlan: {},
      dataAttachedActual: [],
      smallTotalActual: {}
    }
  },
  facesheet: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  kihonCheck: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  yobousienPlan: {
    data: null,
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    loadingNew: false,
    kihonLoading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
    yobousienPlanRows: [],
    yobousienPlanRowsFormated: [],
    yobousienPlanDetails: [],
    rowInformation: null,
    expandedRowKeys: null,
    editMode: true,
    editDetail: false,
    kaigohoken: null,
    status: null,
  },
  hyoukahyou: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    loadingYobousien: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
};
