import { checkTemplate } from "@pdfme/generator";
import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";
import _ from "lodash";

export const life_KoKuTab_inputPDF = (data, jobList, tantoMaster) => {

  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];

  const generateOption = (options, field) => {
    if (options && options[field]) {
      return options[field]
        .split(";")
        .filter((item) => !!item)
        .map((item) => {
          // let temp = (item && item.split(":")[0]) ?? item;
          let temp = item.includes(":") ? item.split(":")[0] : item;
          return { label: item, value: temp };
        });
    }
    return [];
  };

  //{ label: item, value: temp }
  //データ
  const generateCheckBoxString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    return result;
  };
  //口腔（食事形態）
  const generateCheckBoxString3 = (
    options,
    optionSelected,
    other,
    otherSelected
  ) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    if (otherSelected) {
      other.forEach((item2) => {
        if (item2.value === otherSelected) {
          result += "（" + item2.label + "）";
        }
      });
    }
    return result;
  };
  //口腔(誤嚥性肺炎の発症・罹患)
  const generateCheckBoxString4 = (
    options,
    optionSelected,
    date1,
    date2,
    date3,
    date4,
    date5
  ) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    if (optionSelected === "1") {
      result += "（発症日：";
      if (date1 && date1 !== "0001-01-01") {
        result += `${date1.replace(/-/g, "/")}`;
      }
      if (date2 && date2 !== "0001-01-01") {
        result += `, ${date2.replace(/-/g, "/")}`;
      }
      if (date3 && date3 !== "0001-01-01") {
        result += `, ${date3.replace(/-/g, "/")}`;
      }
      if (date4 && date4 !== "0001-01-01") {
        result += `, ${date4.replace(/-/g, "/")}`;
      }
      if (date5 && date5 !== "0001-01-01") {
        result += `, ${date5.replace(/-/g, "/")}`;
      }
      result += ")";
    }
    return result;
  };

  const generateCheckBoxArray2 = (options, optionSelected) => {
    if (optionSelected === "1") return " ■" + options;
    else return " □" + options;
  };

  const formatJob = (id) => {
    let result = "";

    if (
      _.find(tantoMaster, { id: id }) &&
      jobList.naiyou.split(";").length > 0
    ) {
      _.find(jobList.naiyou.split(";"), function (item) {
        if (_.find(tantoMaster, { id: id }).job == item.split(":")[0]) {
          result = item.split(":")[1];
        }
      });
    }
    return result;
  };

  const formatJob2 = (num) => {
    let result = "";

    if (num && jobList.naiyou.split(";").length > 0) {
      jobList.naiyou.split(";").map((item) => {
        if (item.split(":")[0] == num) {
          result = item.split(":")[1];
        }
      });
    }
    return result;
  };

  //（optionSelected（comment））
  const generateKokuTarget = (options, optionSelected, comment) => {
    let result = "";
    options.map((item) => {
      if (item.value === optionSelected) {
        result = " （" + item.label.split(":")[1];
        if (comment) {
          result += "（" + comment + "）";
        }
        result += "）";
      }
    });
    return result;
  };

  const generateKoku = (options, optionSelected, item) => {
    let result = "";
    options.map((i) => {
      if (i.value === optionSelected) {
        result = item;
        result += " （" + i.label.split(":")[1];
        result += "）";
      }
    });
    return result;
  };

  const dateCheckFormat = (date) => {
    if (date && date !== "0001-01-01") {
      return date.replace(/-/g, "/");
    }
    return "";
  };

    const obj = {
      normal: "0:無し;1:有り",
      familyDentist: "0:無し;1:有り",
      dentureUsing: "0:無し;1:有り",
      foodFormIngestion: "0:無し;1:有り",
      foodFormEnteralNutrition: "0:無し;1:有り",
      aspirationPneumonitis: "0:無し;1:有り",
      screening: "0:無し;1:有り;2:分からない",
      remarks1:
        "歯（う蝕、修復物脱離等）、義歯（義歯不適合等）、歯周病、口腔粘膜疾患（潰瘍等）　の疾患の可能性",
      remarks2: "音声・言語機能に関する疾患の可能性",
      target: "0:目標設定無し;1:維持;2:改善",
      preventionOfAspirationPneumonia: "誤嚥性肺炎の予防",
      contentsGuidanceOralCleaning: "摂食・嚥下等の口腔機能に関する指導",
      contentsGuidanceEatingSwallowing: "口腔の清掃、口腔清潔に関する指導",
      contentsGuidanceLanguageVoiceFunction: "音声・言語機能に関する指導",
    };
    const { kokueiseikokukino, lifeYougoList } = data;

    if (typeof kokueiseikokukino === 'object' && Array.isArray(kokueiseikokukino)) {
      let sampledata = [];
      kokueiseikokukino?.map((kokueiseikokukino) => {
        sampledata.push({
          氏名:
            `${kokueiseikokukino?.riyousya?.name ?? ""} (${kokueiseikokukino?.riyousya?.furigana ?? ""})` ??
            "",
          性別: `${generateCheckBoxString(
            GENDER_OPTIONS,
            kokueiseikokukino?.riyousya?.gender ?? ""
          )}`,
          生年月日: formatBirthday(kokueiseikokukino?.riyousya?.birthday) ?? "",
          かかりつけ歯科医: `${generateCheckBoxString(
            generateOption(obj, "familyDentist"),
            kokueiseikokukino.familyDentist
          )}`,
          入れ歯の使用: `${generateCheckBoxString(
            generateOption(obj, "dentureUsing"),
            kokueiseikokukino.dentureUsing
          )}`,
          食形態等: `経口摂取 ${generateCheckBoxString3(
            generateOption(obj, "foodFormIngestion"),
            kokueiseikokukino.foodFormIngestion,
            generateOption(lifeYougoList, "bunrui1"),
            kokueiseikokukino.mealForm
          )} 
  経腸栄養 ${generateCheckBoxString(
            generateOption(obj, "foodFormEnteralNutrition"),
            kokueiseikokukino.foodFormEnteralNutrition
          )}
  静脈栄養 ${generateCheckBoxString(
            generateOption(obj, "foodFormEnteralNutrition"),
            kokueiseikokukino.foodFormTubeFeeding
          )}`,
          "誤嚥性肺炎の発症・罹患": `${generateCheckBoxString4(
            generateOption(obj, "aspirationPneumonitis"),
            kokueiseikokukino.aspirationPneumonitis,
            kokueiseikokukino.dateOfOnset01,
            kokueiseikokukino.dateOfOnset02,
            kokueiseikokukino.dateOfOnset03,
            kokueiseikokukino.dateOfOnset04,
            kokueiseikokukino.dateOfOnset05
          )}`,
          実施日1:
            formatSeireki(kokueiseikokukino?.kokuScreening[0]?.date) ?? "",
          実施日2:
            formatSeireki(kokueiseikokukino?.kokuScreening[1]?.date) ?? "",
          実施日3:
            formatSeireki(kokueiseikokukino?.kokuScreening[2]?.date) ?? "",
          記入者1: kokueiseikokukino?.kokuScreening[0]?.tantoMaster?.name ?? "",
          記入者2: kokueiseikokukino?.kokuScreening[1]?.tantoMaster?.name ?? "",
          記入者3: kokueiseikokukino?.kokuScreening[2]?.tantoMaster?.name ?? "",
          "職種1-1": `${
            formatJob(kokueiseikokukino?.kokuScreening[0]?.tantoMaster?.id) ?? ""
          }`,
          "職種1-2": `${
            formatJob(kokueiseikokukino?.kokuScreening[1]?.tantoMaster?.id) ?? ""
          }`,
          "職種1-3": `${
            formatJob(kokueiseikokukino?.kokuScreening[2]?.tantoMaster?.id) ?? ""
          }`,
          口臭1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.problemBadBreath
          )}`,
          口臭2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.problemBadBreath
          )}`,
          口臭3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.problemBadBreath
          )}`,
          歯の汚れ1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.teethStaining
          )}`,
          歯の汚れ2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.teethStaining
          )}`,
          歯の汚れ3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.teethStaining
          )}`,
          義歯の汚れ1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.dentureStaining
          )}`,
          義歯の汚れ2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.dentureStaining
          )}`,
          義歯の汚れ3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.dentureStaining
          )}`,
          舌苔1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.coatingTongue
          )}`,
          舌苔2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.coatingTongue
          )}`,
          舌苔3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.coatingTongue
          )}`,
          食べこぼし1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.problemSpillFood
          )}`,
          食べこぼし2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.problemSpillFood
          )}`,
          食べこぼし3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.problemSpillFood
          )}`,
          舌の動きが悪い1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.moveTongue
          )}`,
          舌の動きが悪い2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.moveTongue
          )}`,
          舌の動きが悪い3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.moveTongue
          )}`,
          むせ1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.choke
          )}`,
          むせ2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.choke
          )}`,
          むせ3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.choke
          )}`,
          痰がらみ1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.phlegm
          )}`,
          痰がらみ2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.phlegm
          )}`,
          痰がらみ3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.phlegm
          )}`,
          口腔乾燥1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.drynessOfMouth
          )}`,
          口腔乾燥2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.drynessOfMouth
          )}`,
          口腔乾燥3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.drynessOfMouth
          )}`,
          特記事項1: `${generateCheckBoxArray2(
            obj.remarks1,
            kokueiseikokukino?.kokuScreening[0]?.caries
          )} 
  ${generateCheckBoxArray2(
    obj.remarks2,
    kokueiseikokukino?.kokuScreening[0]?.diseasesRelatedVoiceLanguageFunction
  )}
  ${kokueiseikokukino?.kokuScreening[0]?.dentureOtherContents ?? ""}`,
          特記事項2: `${generateCheckBoxArray2(
            obj.remarks1,
            kokueiseikokukino?.kokuScreening[1]?.caries
          )} 
  ${generateCheckBoxArray2(
    obj.remarks2,
    kokueiseikokukino?.kokuScreening[1]?.diseasesRelatedVoiceLanguageFunction
  )}
  ${kokueiseikokukino?.kokuScreening[1]?.dentureOtherContents ?? ""}`,
          特記事項3: `${generateCheckBoxArray2(
            obj.remarks1,
            kokueiseikokukino?.kokuScreening[2]?.caries
          )} 
  ${generateCheckBoxArray2(
    obj.remarks2,
    kokueiseikokukino?.kokuScreening[2]?.diseasesRelatedVoiceLanguageFunction
  )}
  ${kokueiseikokukino?.kokuScreening[2]?.dentureOtherContents ?? ""}`,
          作成日: formatSeireki(kokueiseikokukino.assessDate) ?? "",
          計画立案者: `${formatJob2(kokueiseikokukino.planPersonnel)}`,
          "サービス提供者（計画）": `${formatJob2(
            kokueiseikokukino.providerPersonnel
          )}`,
          目標: `口腔衛生 ${generateKokuTarget(
            generateOption(obj, "target"),
            kokueiseikokukino.oralHygiene,
            kokueiseikokukino.oralCleaningNotice
          )}
  摂食・嚥下機能 ${generateKokuTarget(
            generateOption(obj, "target"),
            kokueiseikokukino.oralCavityFunctionFlag,
            kokueiseikokukino.oralCavityFunctionNotice
          )}
  食形態 ${generateKokuTarget(
            generateOption(obj, "target"),
            kokueiseikokukino.foodFormFlag,
            kokueiseikokukino.foodFormNotice
          )}
  音声・言語機能 ${generateKokuTarget(
            generateOption(obj, "target"),
            kokueiseikokukino.languageVoiceFunctionFlag,
            kokueiseikokukino.languageVoiceFunctionNotice
          )}
  誤嚥性肺炎の予防 ${generateKokuTarget(
            generateOption(obj, "normal"),
            kokueiseikokukino.preventionOfAspirationPneumonia
          )}
  ${kokueiseikokukino.goalOtherContents ?? ""}`,
          実施内容: `摂食・嚥下等の口腔機能に関する指導 ${generateKokuTarget(
            generateOption(obj, "normal"),
            kokueiseikokukino.contentsGuidanceEatingSwallowing
          )}
  口腔の清掃、口腔清潔に関する指導 ${generateKokuTarget(
            generateOption(obj, "normal"),
            kokueiseikokukino.contentsGuidanceOralCleaning
          )}
  音声・言語機能に関する指導 ${generateKokuTarget(
            generateOption(obj, "normal"),
            kokueiseikokukino.contentsGuidanceLanguageVoiceFunction
          )}
  ${kokueiseikokukino.isContentsOtherNotices ?? ""}`,
          実施年月日1:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[0]?.date
            ) ?? "",
          実施年月日2:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[1]?.date
            ) ?? "",
          実施年月日3:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[2]?.date
            ) ?? "",
          実施年月日4:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[3]?.date
            ) ?? "",
          実施年月日5:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[4]?.date
            ) ?? "",
          実施年月日6:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[5]?.date
            ) ?? "",
          サービス提供者1:
            kokueiseikokukino?.kokuImplementationRecord[0]?.tantoMaster?.name ??
            "",
          サービス提供者2:
            kokueiseikokukino?.kokuImplementationRecord[1]?.tantoMaster?.name ??
            "",
          サービス提供者3:
            kokueiseikokukino?.kokuImplementationRecord[2]?.tantoMaster?.name ??
            "",
          サービス提供者4:
            kokueiseikokukino?.kokuImplementationRecord[3]?.tantoMaster?.name ??
            "",
          サービス提供者5:
            kokueiseikokukino?.kokuImplementationRecord[4]?.tantoMaster?.name ??
            "",
          サービス提供者6:
            kokueiseikokukino?.kokuImplementationRecord[5]?.tantoMaster?.name ??
            "",
          "職種2-1": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[0]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-2": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[1]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-3": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[2]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-4": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[3]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-5": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[4]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-6": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[5]?.tantoMaster?.id
            ) ?? ""
          }`,
          "口腔清掃、口腔清掃に関する指導1": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[0]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導2": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[1]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導3": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[2]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導4": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[3]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導5": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[4]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導6": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[5]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "摂食・嚥下等の口腔機能に関する指導1": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[0]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導2": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[1]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導3": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[2]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導4": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[3]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導5": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[4]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導6": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[5]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "音声・言語機能に関する指導1": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[0]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導2": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[1]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導3": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[2]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導4": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[3]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導5": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[4]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導6": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[5]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "その他　具体的な実施内容1":
            kokueiseikokukino?.kokuImplementationRecord[0]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容2":
            kokueiseikokukino?.kokuImplementationRecord[1]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容3":
            kokueiseikokukino?.kokuImplementationRecord[2]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容4":
            kokueiseikokukino?.kokuImplementationRecord[3]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容5":
            kokueiseikokukino?.kokuImplementationRecord[4]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容6":
            kokueiseikokukino?.kokuImplementationRecord[5]
              ?.oralCareOtherContents ?? "",
          その他特記事項: kokueiseikokukino.specialNotes ?? "",
        })
      })

      return sampledata;
    } else if (typeof kokueiseikokukino === 'object') {
      let sampledata = [
        {
          氏名:
            `${kokueiseikokukino?.riyousya?.name ?? ""} (${kokueiseikokukino?.riyousya?.furigana ?? ""})` ??
            "",
          性別: `${generateCheckBoxString(
            GENDER_OPTIONS,
            kokueiseikokukino?.riyousya?.gender ?? ""
          )}`,
          生年月日: formatBirthday(kokueiseikokukino?.riyousya?.birthday) ?? "",
          かかりつけ歯科医: `${generateCheckBoxString(
            generateOption(obj, "familyDentist"),
            kokueiseikokukino.familyDentist
          )}`,
          入れ歯の使用: `${generateCheckBoxString(
            generateOption(obj, "dentureUsing"),
            kokueiseikokukino.dentureUsing
          )}`,
          食形態等: `経口摂取 ${generateCheckBoxString3(
            generateOption(obj, "foodFormIngestion"),
            kokueiseikokukino.foodFormIngestion,
            generateOption(lifeYougoList, "bunrui1"),
            kokueiseikokukino.mealForm
          )} 
  経腸栄養 ${generateCheckBoxString(
            generateOption(obj, "foodFormEnteralNutrition"),
            kokueiseikokukino.foodFormEnteralNutrition
          )}
  静脈栄養 ${generateCheckBoxString(
            generateOption(obj, "foodFormEnteralNutrition"),
            kokueiseikokukino.foodFormTubeFeeding
          )}`,
          "誤嚥性肺炎の発症・罹患": `${generateCheckBoxString4(
            generateOption(obj, "aspirationPneumonitis"),
            kokueiseikokukino.aspirationPneumonitis,
            kokueiseikokukino.dateOfOnset01,
            kokueiseikokukino.dateOfOnset02,
            kokueiseikokukino.dateOfOnset03,
            kokueiseikokukino.dateOfOnset04,
            kokueiseikokukino.dateOfOnset05
          )}`,
          実施日1:
            formatSeireki(kokueiseikokukino?.kokuScreening[0]?.date) ?? "",
          実施日2:
            formatSeireki(kokueiseikokukino?.kokuScreening[1]?.date) ?? "",
          実施日3:
            formatSeireki(kokueiseikokukino?.kokuScreening[2]?.date) ?? "",
          記入者1: kokueiseikokukino?.kokuScreening[0]?.tantoMaster?.name ?? "",
          記入者2: kokueiseikokukino?.kokuScreening[1]?.tantoMaster?.name ?? "",
          記入者3: kokueiseikokukino?.kokuScreening[2]?.tantoMaster?.name ?? "",
          "職種1-1": `${
            formatJob(kokueiseikokukino?.kokuScreening[0]?.tantoMaster?.id) ?? ""
          }`,
          "職種1-2": `${
            formatJob(kokueiseikokukino?.kokuScreening[1]?.tantoMaster?.id) ?? ""
          }`,
          "職種1-3": `${
            formatJob(kokueiseikokukino?.kokuScreening[2]?.tantoMaster?.id) ?? ""
          }`,
          口臭1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.problemBadBreath
          )}`,
          口臭2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.problemBadBreath
          )}`,
          口臭3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.problemBadBreath
          )}`,
          歯の汚れ1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.teethStaining
          )}`,
          歯の汚れ2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.teethStaining
          )}`,
          歯の汚れ3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.teethStaining
          )}`,
          義歯の汚れ1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.dentureStaining
          )}`,
          義歯の汚れ2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.dentureStaining
          )}`,
          義歯の汚れ3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.dentureStaining
          )}`,
          舌苔1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.coatingTongue
          )}`,
          舌苔2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.coatingTongue
          )}`,
          舌苔3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.coatingTongue
          )}`,
          食べこぼし1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.problemSpillFood
          )}`,
          食べこぼし2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.problemSpillFood
          )}`,
          食べこぼし3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.problemSpillFood
          )}`,
          舌の動きが悪い1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.moveTongue
          )}`,
          舌の動きが悪い2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.moveTongue
          )}`,
          舌の動きが悪い3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.moveTongue
          )}`,
          むせ1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.choke
          )}`,
          むせ2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.choke
          )}`,
          むせ3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.choke
          )}`,
          痰がらみ1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.phlegm
          )}`,
          痰がらみ2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.phlegm
          )}`,
          痰がらみ3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.phlegm
          )}`,
          口腔乾燥1: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[0]?.drynessOfMouth
          )}`,
          口腔乾燥2: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[1]?.drynessOfMouth
          )}`,
          口腔乾燥3: `${generateCheckBoxString(
            generateOption(obj, "screening"),
            kokueiseikokukino?.kokuScreening[2]?.drynessOfMouth
          )}`,
          特記事項1: `${generateCheckBoxArray2(
            obj.remarks1,
            kokueiseikokukino?.kokuScreening[0]?.caries
          )} 
  ${generateCheckBoxArray2(
    obj.remarks2,
    kokueiseikokukino?.kokuScreening[0]?.diseasesRelatedVoiceLanguageFunction
  )}
  ${kokueiseikokukino?.kokuScreening[0]?.dentureOtherContents ?? ""}`,
          特記事項2: `${generateCheckBoxArray2(
            obj.remarks1,
            kokueiseikokukino?.kokuScreening[1]?.caries
          )} 
  ${generateCheckBoxArray2(
    obj.remarks2,
    kokueiseikokukino?.kokuScreening[1]?.diseasesRelatedVoiceLanguageFunction
  )}
  ${kokueiseikokukino?.kokuScreening[1]?.dentureOtherContents ?? ""}`,
          特記事項3: `${generateCheckBoxArray2(
            obj.remarks1,
            kokueiseikokukino?.kokuScreening[2]?.caries
          )} 
  ${generateCheckBoxArray2(
    obj.remarks2,
    kokueiseikokukino?.kokuScreening[2]?.diseasesRelatedVoiceLanguageFunction
  )}
  ${kokueiseikokukino?.kokuScreening[2]?.dentureOtherContents ?? ""}`,
          作成日: formatSeireki(kokueiseikokukino.assessDate) ?? "",
          計画立案者: `${formatJob2(kokueiseikokukino.planPersonnel)}`,
          "サービス提供者（計画）": `${formatJob2(
            kokueiseikokukino.providerPersonnel
          )}`,
          目標: `口腔衛生 ${generateKokuTarget(
            generateOption(obj, "target"),
            kokueiseikokukino.oralHygiene,
            kokueiseikokukino.oralCleaningNotice
          )}
  摂食・嚥下機能 ${generateKokuTarget(
            generateOption(obj, "target"),
            kokueiseikokukino.oralCavityFunctionFlag,
            kokueiseikokukino.oralCavityFunctionNotice
          )}
  食形態 ${generateKokuTarget(
            generateOption(obj, "target"),
            kokueiseikokukino.foodFormFlag,
            kokueiseikokukino.foodFormNotice
          )}
  音声・言語機能 ${generateKokuTarget(
            generateOption(obj, "target"),
            kokueiseikokukino.languageVoiceFunctionFlag,
            kokueiseikokukino.languageVoiceFunctionNotice
          )}
  誤嚥性肺炎の予防 ${generateKokuTarget(
            generateOption(obj, "normal"),
            kokueiseikokukino.preventionOfAspirationPneumonia
          )}
  ${kokueiseikokukino.goalOtherContents ?? ""}`,
          実施内容: `摂食・嚥下等の口腔機能に関する指導 ${generateKokuTarget(
            generateOption(obj, "normal"),
            kokueiseikokukino.contentsGuidanceEatingSwallowing
          )}
  口腔の清掃、口腔清潔に関する指導 ${generateKokuTarget(
            generateOption(obj, "normal"),
            kokueiseikokukino.contentsGuidanceOralCleaning
          )}
  音声・言語機能に関する指導 ${generateKokuTarget(
            generateOption(obj, "normal"),
            kokueiseikokukino.contentsGuidanceLanguageVoiceFunction
          )}
  ${kokueiseikokukino.isContentsOtherNotices ?? ""}`,
          実施年月日1:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[0]?.date
            ) ?? "",
          実施年月日2:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[1]?.date
            ) ?? "",
          実施年月日3:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[2]?.date
            ) ?? "",
          実施年月日4:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[3]?.date
            ) ?? "",
          実施年月日5:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[4]?.date
            ) ?? "",
          実施年月日6:
            formatSeireki(
              kokueiseikokukino?.kokuImplementationRecord[5]?.date
            ) ?? "",
          サービス提供者1:
            kokueiseikokukino?.kokuImplementationRecord[0]?.tantoMaster?.name ??
            "",
          サービス提供者2:
            kokueiseikokukino?.kokuImplementationRecord[1]?.tantoMaster?.name ??
            "",
          サービス提供者3:
            kokueiseikokukino?.kokuImplementationRecord[2]?.tantoMaster?.name ??
            "",
          サービス提供者4:
            kokueiseikokukino?.kokuImplementationRecord[3]?.tantoMaster?.name ??
            "",
          サービス提供者5:
            kokueiseikokukino?.kokuImplementationRecord[4]?.tantoMaster?.name ??
            "",
          サービス提供者6:
            kokueiseikokukino?.kokuImplementationRecord[5]?.tantoMaster?.name ??
            "",
          "職種2-1": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[0]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-2": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[1]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-3": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[2]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-4": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[3]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-5": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[4]?.tantoMaster?.id
            ) ?? ""
          }`,
          "職種2-6": `${
            formatJob(
              kokueiseikokukino?.kokuImplementationRecord[5]?.tantoMaster?.id
            ) ?? ""
          }`,
          "口腔清掃、口腔清掃に関する指導1": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[0]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導2": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[1]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導3": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[2]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導4": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[3]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導5": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[4]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "口腔清掃、口腔清掃に関する指導6": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[5]
              ?.contentsGuidanceOralCleaning,
            obj.contentsGuidanceOralCleaning
          )}`,
          "摂食・嚥下等の口腔機能に関する指導1": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[0]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導2": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[1]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導3": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[2]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導4": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[3]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導5": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[4]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "摂食・嚥下等の口腔機能に関する指導6": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[5]
              ?.contentsGuidanceEatingSwallowing,
            obj.contentsGuidanceEatingSwallowing
          )}`,
          "音声・言語機能に関する指導1": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[0]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導2": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[1]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導3": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[2]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導4": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[3]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導5": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[4]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "音声・言語機能に関する指導6": `${generateKoku(
            generateOption(obj, "normal"),
            kokueiseikokukino?.kokuImplementationRecord[5]
              ?.contentsGuidanceLanguageVoiceFunction,
            obj.contentsGuidanceLanguageVoiceFunction
          )}`,
          "その他　具体的な実施内容1":
            kokueiseikokukino?.kokuImplementationRecord[0]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容2":
            kokueiseikokukino?.kokuImplementationRecord[1]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容3":
            kokueiseikokukino?.kokuImplementationRecord[2]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容4":
            kokueiseikokukino?.kokuImplementationRecord[3]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容5":
            kokueiseikokukino?.kokuImplementationRecord[4]
              ?.oralCareOtherContents ?? "",
          "その他　具体的な実施内容6":
            kokueiseikokukino?.kokuImplementationRecord[5]
              ?.oralCareOtherContents ?? "",
          その他特記事項: kokueiseikokukino.specialNotes ?? "",
        },
      ];
      return sampledata;
    } else {
      return [{}];
    }
};
