import {
  CREATE_TANTO_KAIGI_ERROR, CREATE_TANTO_KAIGI_SUCCESS, LOAD_TANTO_KAIGI_BY_DATE_ERROR, LOAD_TANTO_KAIGI_BY_DATE_SUCCESS, LOAD_TANTO_KAIGI_BY_ID_ERROR, LOAD_TANTO_KAIGI_BY_ID_SUCCESS, LOAD_TANTO_KAIGI_SPIN, LOAD_TANTO_KAIGI_TEMPALTE_ERROR, LOAD_TANTO_KAIGI_TEMPLATE_SUCCESS, RESET_CURRENT_TANTO_KAIGI, SAVE_TANTO_KAIGI_TEMPLATE_ERROR, SAVE_TANTO_KAIGI_TEMPLATE_SUCCESS, UPDATE_TANTO_KAIGI_ERROR, UPDATE_TANTO_KAIGI_SUCCESS
} from "../../actions/tantoKaigiActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

const initialState = {
  tantoKaigisByDate: [],
  metaTantoKaigiByDate: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
  loading: false,
  tantoKaigi: {},
  createdTantoKaigi: {},
  updatedTantoKaigi: {},
};

export default function tantoKaigi(state = initialState, action) {
  switch (action.type) {
    case LOAD_TANTO_KAIGI_SPIN:
      return {
        ...state,
        loading: true,
      };
    case LOAD_TANTO_KAIGI_BY_DATE_SUCCESS:
      return {
        ...state,
        tantoKaigisByDate: action.tantoKaigis,
        metaTantoKaigiByDate: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
      };
    case LOAD_TANTO_KAIGI_BY_DATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case LOAD_TANTO_KAIGI_BY_ID_SUCCESS:
      return {
        ...state,
        tantoKaigi: action.tantoKaigi,
        loading: false,
      };

    case CREATE_TANTO_KAIGI_SUCCESS:
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        loading: false,
        createdTantoKaigi: action.createdTantoKaigi,
        tantoKaigi: action.createdTantoKaigi,
      };
    case UPDATE_TANTO_KAIGI_SUCCESS:
      openNotificationWithIcon("success", "保存しました", action.callback);
      return {
        ...state,
        loading: false,
        updatedTantoKaigi: action.updatedTantoKaigi,
        tantoKaigi: action.updatedTantoKaigi,
      };

    case SAVE_TANTO_KAIGI_TEMPLATE_SUCCESS:
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        loading: false,
      };
    case LOAD_TANTO_KAIGI_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case LOAD_TANTO_KAIGI_TEMPALTE_ERROR:
    case SAVE_TANTO_KAIGI_TEMPLATE_ERROR:
    case LOAD_TANTO_KAIGI_BY_ID_ERROR:
    case CREATE_TANTO_KAIGI_ERROR:
    case UPDATE_TANTO_KAIGI_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case RESET_CURRENT_TANTO_KAIGI:
      return {
        ...state,
        tantoKaigi: {},
        createdTantoKaigi: {},
        updatedTantoKaigi: {},
      };
    default:
      return state;
  }
}
