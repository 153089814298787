import { Button, Col, Input, Row } from "antd";
import React from "react";

import {
  moji_daku,
  moji_handaku,
  moji_small,
} from "../function_common/furigana";

export default class FuriganaKeyboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // furigana: ''
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleMojiDaku = this.handleMojiDaku.bind(this);
    this.handleMojiHandaku = this.handleMojiHandaku.bind(this);
    this.handleMojiSmall = this.handleMojiSmall.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onChangeFurigana = this.onChangeFurigana.bind(this);
  }

  handleDelete() {
    this.props.furigana(this.props.furiganaState.slice(0, -1));
    // this.setState({
    //     furigana: this.props.furiganaState.slice(0, -1)
    // })
  }

  handleInput(furigana) {
    const { furiganaState } = this.props;
    if (furiganaState && furiganaState.length <= 10) {
      this.props.furigana(this.props.furiganaState + furigana);
      // this.setState({
      //     furigana: this.props.furiganaState + furigana
      // })
    }
    else {
      this.props.furigana(furigana);
    }
  }

  handleMojiDaku() {
    const tmp = this.props.furiganaState;

    if (tmp.slice(-1)) {
      this.props.furigana(
        this.props.furiganaState.slice(0, -1) + moji_daku(tmp.slice(-1))
      );
      // this.setState({
      //     furigana: this.props.furiganaState.slice(0, -1) + moji_daku(tmp.slice(-1))
      // })
    }
  }

  handleMojiHandaku() {
    const tmp = this.props.furiganaState;
    this.props.furigana(
      this.props.furiganaState.slice(0, -1) + moji_handaku(tmp.slice(-1))
    );
    if (tmp.slice(-1)) {
      // this.setState({
      //     furigana: this.props.furiganaState.slice(0, -1) + moji_handaku(tmp.slice(-1))
      // })
    }
  }

  handleMojiSmall() {
    const tmp = this.props.furiganaState;
    this.props.furigana(
      this.props.furiganaState.slice(0, -1) + moji_small(tmp.slice(-1))
    );
    // if (tmp.slice(-1)) {
    //     this.setState({
    //         furigana: this.props.furiganaState.slice(0, -1) + moji_small(tmp.slice(-1))
    //     })
    // }
  }

  onChangeFurigana(e) {
    this.props.furigana(e.target.value);
  }

  render() {
    return (
      <div>
        <Row style={{ padding: "0px 5px" }}>
          <Col span={12}>
            <Input
              value={this.props.furiganaState}
              onChange={this.onChangeFurigana}
            />
          </Col>
          <Col span={6} offset={6}>
            <Button style={{ float: "right" }} onClick={this.handleDelete}>
              消去
            </Button>
          </Col>
        </Row>
        <Row style={{ padding: "16px 5px 0px 5px" }}>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("あ")}
          >
            あ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("か")}
          >
            か
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("さ")}
          >
            さ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("た")}
          >
            た
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("な")}
          >
            な
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("は")}
          >
            は
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ま")}
          >
            ま
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("や")}
          >
            や
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ら")}
          >
            ら
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("わ")}
          >
            わ
          </Button>

          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("い")}
          >
            い
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("き")}
          >
            き
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("し")}
          >
            し
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ち")}
          >
            ち
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("に")}
          >
            に
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ひ")}
          >
            ひ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("み")}
          >
            み
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ゆ")}
          >
            ゆ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("り")}
          >
            り
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("を")}
          >
            を
          </Button>

          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("う")}
          >
            う
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("く")}
          >
            く
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("す")}
          >
            す
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("つ")}
          >
            つ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ぬ")}
          >
            ぬ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ふ")}
          >
            ふ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("む")}
          >
            む
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("よ")}
          >
            よ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("る")}
          >
            る
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ん")}
          >
            ん
          </Button>

          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("え")}
          >
            え
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("け")}
          >
            け
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("せ")}
          >
            せ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("て")}
          >
            て
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ね")}
          >
            ね
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("へ")}
          >
            へ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("め")}
          >
            め
          </Button>
          <Button className={"hm-item-hira-kb"} onClick={this.handleMojiDaku}>
            ゛
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("れ")}
          >
            れ
          </Button>
          <Button className={"hm-item-hira-kb"} onClick={this.handleMojiSmall}>
            小
          </Button>

          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("お")}
          >
            お
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("こ")}
          >
            こ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("そ")}
          >
            そ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("と")}
          >
            と
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("の")}
          >
            の
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ほ")}
          >
            ほ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("も")}
          >
            も
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={this.handleMojiHandaku}
          >
            ゜
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput("ろ")}
          >
            ろ
          </Button>
          <Button
            className={"hm-item-hira-kb"}
            onClick={() => this.handleInput(" ")}
            style={{ padding: "4px 7px" }}
          >
            空白
          </Button>
        </Row>
      </div>
    );
  }
}
