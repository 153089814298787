import { Form } from '@ant-design/compatible';
import { SearchOutlined } from '@ant-design/icons';

// import '@ant-design/compatible/assets/index.css';


import { Button, Checkbox, Col, Input, Modal, Radio, Row, Select, notification } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { TableListKanriActions } from "../../actions/kanriActions/actions";
import { guid } from "../../common/timePicker/formatTimePicker";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import TableResizeAble from "./UI/TableResizable";

const CheckboxGroup = Checkbox.Group;
const FormItem = Form.Item;
const Option = Select.Option;

class KanriTableRecord extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);

    this.state = {
      xmldoc: query.get("xmldoc"),
      name: query.get("name"),
      table: query.get("table"),
      fields: query.get("fields"),
      widthx: query.get("fields") ? query.get("fields").split(",").length * 150 + 80 : 1400,
      visibleMF: false,
      fieldsDis: query.get("fields") ? query.get("fields").split(",") : [],
      checkedList: query.get("fields") ? query.get("fields").split(",") : [],
      indeterminate: true,
      checkAll: false,
      isVisibleSortM: false,
      sortData: [],
      isVisibleFilterM: false,
      filters: { key1: null, ope1: null, value1: null, mer: null, key2: null, ope2: null, value2: null },
      isVisibleModify: false
    };
  }

  componentDidMount() {
    const { xmldoc, name, filters, sortData } = this.state;
    const { pagination } = this.props;
    this.props.getTableDetail(xmldoc, name);
    this.props.getTableRecord(xmldoc, name, filters, sortData, pagination);
  }

  handleChange = (pagination, filters, sorter) => {
    // console.log("Various parameters", pagination);
    // console.log("Various parameters", filters);
    // console.log("Various parameters", sorter);

    const { xmldoc, name } = this.state;
    this.props.getTableRecord(xmldoc, name, filters, sorter, pagination);

    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  displaySortM = () => {
    this.setState({
      isVisibleSortM: true,
    });
  };

  cancelSortM = () => {
    this.setState({
      isVisibleSortM: false,
      sortData: []
    });
  };

  okSortM = () => {
    const { xmldoc, name, filters, sortData } = this.state;
    const { pagination } = this.props;

    this.props.getTableRecord(xmldoc, name, filters, sortData, pagination);

    this.setState({
      isVisibleSortM: false
    });
  }

  displayFilterM = () => {
    this.setState({
      isVisibleFilterM: true,
    });
  };

  cancelFilterM = () => {
    this.setState({
      isVisibleFilterM: false,
      sortData: []
    });
  };

  okFilterM = () => {
    const { xmldoc, name, filters, sortData } = this.state;
    const { pagination } = this.props;

    this.props.getTableRecord(xmldoc, name, filters, sortData, pagination);

    this.setState({
      isVisibleFilterM: false
    });
  }

  displayModifyM = () => {
    this.setState({
      isVisibleModify: true,
    });
  };

  cancelModifyM = () => {
    this.setState({
      isVisibleModify: false,
    });
  };

  okModifyM = () => {
    // const { xmldoc, name, filters, sortData } = this.state;
    // const { pagination } = this.props;

    // this.props.getTableRecord(xmldoc, name, filters, sortData, pagination);

    this.setState({
      isVisibleModify: false
    });
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`検索 ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          検索
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          クリア
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleTableChild = (record) => {
    console.log(record);
  }

  handleGoback = () => {
    this.props.history.goBack();
  }

  handleColDisSelect = () => {
    this.setState({
      visibleMF: true
    });
  }

  handleOKMF = () => {
    // handle display colume

    this.setState({
      visibleMF: false,
    });
  };

  handleCancelMF = () => {
    this.setState({
      visibleMF: false,
      checkedList: []
    });
  };

  onCheckAllChange = e => {
    const { checkedList, fieldsDis } = this.state;

    this.setState({
      checkedList: e.target.checked ? checkedList : fieldsDis,
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onChangeCbFieldSelect = checkedList => {
    const { fieldsDis } = this.state;

    this.setState({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < fieldsDis.length,
      checkAll: checkedList.length === fieldsDis.length,
    });
  };

  handleChangeOption = (value, i) => {
    const newValue = this.state.sortData;
    newValue["key" + i] = value;
    newValue["value" + i] = "asc";
    this.setState({ sortData: newValue });
  }

  handleChangeOrder(value, i) {
    const newValue = this.state.sortData;
    newValue["value" + i] = value;
    this.setState({ sortData: newValue });
  }

  onChangeFilterOptionL = (value, i) => {
    // console.log(value, i);
    const { filters } = this.state;
    filters[`key${i}`] = value;

    this.setState({
      filters
    });
  }

  onChangeFilterOptionR = (value, i) => {
    // console.log(value, i);
    const { filters } = this.state;
    filters[`ope${i}`] = value;

    this.setState({
      filters
    });

  }

  onChangeFilterOptionV = (e, i) => {
    // console.log(value, i);
    const { value } = e.target;
    const { filters } = this.state;
    filters[`value${i}`] = value;
    this.setState({
      filters
    });

  }

  onChangeFilterOptionM = (value) => {
    // console.log(value, i);
    const { filters } = this.state;
    filters.mer = value;

    this.setState({
      filters
    });
  }

  onChangeFilterSelect = (value) => {
    console.log(value);
  }

  render() {
    let { sortedInfo } = this.state;
    const { fields, widthx, fieldsDis, checkedList, visibleMF, isVisibleSortM, isVisibleFilterM, table, sortData, filters, name, isVisibleModify } = this.state;
    const { tableRecord, tableDetail, pagination, loading } = this.props;
    sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};

    const columns = [];
    const filedsArr = fields ? fields.split(",") : [];
    if (filedsArr != null && filedsArr.length > 0) {
      for (let i = 0; i < filedsArr.length; i++) {
        if (checkedList.length === 0 || (checkedList.length > 0 && checkedList.includes(filedsArr[i]))) {
          const tmpComlums = {
            title: filedsArr[i],
            dataIndex: filedsArr[i],
            key: filedsArr[i],
            sorter: (a, b) => a[`${filedsArr[i]}`].length - b[`${filedsArr[i]}`].length,
            sortOrder: sortedInfo.columnKey === filedsArr[i] && sortedInfo.order,
            ellipsis: true,
            // ...this.getColumnSearchProps(filedsArr[i]),
            width: 150,
          };

          columns.push(tmpComlums);
        }
      }

      columns.push({
        title: "Action",
        key: "action",
        render: () => (<Button type="primary">編集</Button>),
      });
    }

    // 並び替え内容
    const children = [];
    const count = 3;
    for (let i = 1; i <= count; i++) {
      const selected = [];
      for (let j = 1; j <= count; j++) {
        if (j !== i && sortData["key" + j] !== "0") {
          selected.push(sortData["key" + j]);
        }
      }
      children.push(
        <Row key={"condition-no-" + i}>
          <h4>
            {
              // eslint-disable-next-line no-nested-ternary
              i === 1
                ?
                "最優先されるキー"
                :
                (
                  i === 2
                    ?
                    "2番目に先されるキー"
                    :
                    "3番目に先されるキー"
                )
            }
          </h4>
          <Col xs={18}>
            <FormItem>
              <Select
                style={{ width: "100%" }}
                value={sortData["key" + i]}
                onChange={(value) => this.handleChangeOption(value, i)}
              >
                <Option key={guid()} value="">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Option>
                {tableDetail.map((item) => (
                  <Option
                    key={guid()}
                    value={item.name}
                    disabled={selected.indexOf(item.name) >= 0}
                  >
                    {`[${name}] ${item.jname}`}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col xs={6}>
            <FormItem>
              <Select
                style={{ width: "100%" }}
                value={sortData["value" + i]}
                onChange={(value) => this.handleChangeOrder(value, i)}
              >
                <Option value="asc">昇順</Option>
                <Option value="desc">降順</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      );
    }

    // 検索内容
    const childrenS = [];
    childrenS.push(
      <Row>
        <Button>条件登録</Button>
        <Button>条件登録</Button>
        <Button>登録削除</Button>
        <Input />
      </Row>
    );

    const countF = 2;
    for (let i = 1; i <= countF; i++) {
      const selected = [];
      for (let j = 1; j <= countF; j++) {
        if (j !== i && filters["key" + j] !== "0") {
          selected.push(filters["key" + j]);
        }
      }

      childrenS.push(
        <div>
          <Row key={"condition-no-" + i}>
            <Col xs={6}>
              <Button>検索項目</Button>
            </Col>
            <Col xs={18}>
              <Select
                style={{ width: "100%" }}
                value={filters[`key${i}`]}
                onChange={(value) => this.onChangeFilterOptionL(value, i)}
              >
                <Option key={guid()} value="0">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Option>
                {tableDetail.map((item) => (
                  <Option
                    key={guid()}
                    value={item.name}
                    disabled={selected.indexOf(item.name) >= 0}
                  >
                    {`[${name}] ${item.jname}`}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
            </Col>
            <Col xs={2}>
              <Button>值</Button>
            </Col>
            <Col xs={10}>
              <Input
                // value={filters[`value${i}`]}
                onBlur={(value) => this.onChangeFilterOptionV(value, i)}
              />
            </Col>
            <Col xs={6}>
              <Select
                style={{ width: "100%" }}
                onChange={(value) => this.onChangeFilterOptionR(value, i)}
                value={filters[`ope${i}`]}
              >
                {/*
                の項目 =
                でない項目 !=
                から始まる
                で終わる
                より大きい >
                以上 >=
                より小さい <
                以下 <=
                を含む
                を含まない
                */}
                <Option key={guid()} value="0">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Option>
                <Option key={guid()} value="=">の項目</Option>
                <Option key={guid()} value="!=">でない項目</Option>
                <Option key={guid()} value="LIKE %val">から始まる</Option>
                <Option key={guid()} value="LIKE val%">で終わる</Option>
                <Option key={guid()} value=">">より大きい</Option>
                <Option key={guid()} value=">=">以上</Option>
                <Option key={guid()} value="<">より小さい</Option>
                <Option key={guid()} value="<=">以下</Option>
                <Option key={guid()} value="LIKE %val%">を含む</Option>
                <Option key={guid()} value="NOT LIKE %val%">を含まない</Option>
              </Select>
            </Col>
          </Row>
          {
            i === 1
              ?
              <Row>
                <Col xs={6}>
                  <Button>接続肢</Button>
                </Col>
                <Col xs={10}>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(value) => this.onChangeFilterOptionM(value, i)}
                    value={filters.mer}
                  >
                    <Option key={guid()} value="0">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </Option>
                    <Option key={guid()} value="ONLY">第一条件のみ</Option>
                    <Option key={guid()} value="OR">または</Option>
                    <Option key={guid()} value="AND">かつ</Option>
                  </Select>
                </Col>
                <Col xs={8}>
                </Col>
              </Row>
              :
              null
          }
        </div>
      );
    }
    childrenS.push(<div>
      <Radio.Group onChange={this.onChangeFilterSelect} value={this.state.value}>
        <Radio value={1}>置き換え</Radio>
        <Radio value={2}>絞り込み</Radio>
        <Radio value={3}>追加</Radio>
      </Radio.Group>
    </div>);

    return (
      (<KanriLayoutContainer router="table" breadcrumbLink={"/kanri/table"} breadcrumbText={"パレット管理"}>
        <div>
          <div className="table-operations">
            <Button onClick={this.displaySortM}>並び替え</Button>
            <Button onClick={this.displayFilterM}>検索</Button>
            <Button onClick={this.clearAll}>全クリア</Button>
            <Button type="primary" onClick={this.displayModifyM}>新規</Button>
            <Button type="primary" danger onClick={this.handleColDisSelect}>表示設定</Button>
            <Button type="primary" style={{ float: "right" }} onClick={this.handleGoback}>戻る</Button>
          </div>
          <div id="table-resizeable-2">
            <TableResizeAble
              columns={columns}
              data={tableRecord}
              onChange={this.handleChange}
              widthx={widthx}
              pagination={pagination}
              loading={loading}
            />
          </div>

          <Modal
            title="フィールド表示選択"
            open={visibleMF}
            onOk={this.handleOKMF}
            onCancel={this.handleCancelMF}
            okText="OK"
            cancelText="キャンセル"
            className="m-kanri-record"
          >
            <div className="kanri-table-select-dis">
              <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                <Checkbox
                  indeterminate={this.state.indeterminate}
                  onChange={this.onCheckAllChange}
                  checked={this.state.checkAll}
                >
                  全選択
                </Checkbox>
              </div>
              <br />
              <CheckboxGroup
                options={fieldsDis}
                value={checkedList}
                onChange={this.onChangeCbFieldSelect}
              />
            </div>
          </Modal>

          <Modal
            title={table}
            open={isVisibleSortM}
            onOk={this.okSortM}
            onCancel={this.cancelSortM}
            okText="OK"
            cancelText="キャンセル"
            className="m-kanri-record"
          >
            <div className={"kn-search-con"}>{children}</div>
          </Modal>

          <Modal
            title={`検索 - ${table}`}
            open={isVisibleFilterM}
            onOk={this.okFilterM}
            onCancel={this.cancelFilterM}
            okText="OK"
            cancelText="キャンセル"
            className="m-kanri-record"
          >
            <div className={"kn-search-con"}>{childrenS}</div>
          </Modal>

          <Modal
            title={"ADD AND EDIT RECORD"}
            open={isVisibleModify}
            onOk={this.okModifyM}
            onCancel={this.cancelModifyM}
            okText="OK"
            cancelText="キャンセル"
            className="m-kanri-record"
            width={980}
          >
            Hello World
          </Modal>
        </div>
      </KanriLayoutContainer>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tableDetail: state.kanriTable.tableDetail,
    tableRecord: state.kanriTable.tableRecord,
    pagination: state.kanriTable.pagination,
    loading: state.kanriTable.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTableDetail: (xmldoc, name) => dispatch(TableListKanriActions.getTableDetail(xmldoc, name)),
    getTableRecord: (xmldoc, name, filters, sorter, pagination) => dispatch(TableListKanriActions.getTableRecord(xmldoc, name, filters, sorter, pagination)),
    // keepValueSearch: (data) => dispatch(LifeActions.keepValueSearch(data)),
  };
};

const propTypes = {
  //
};

const defaultProps = {
  minRows: 0,
  maxRows: Infinity,
};

KanriTableRecord.propTypes = propTypes;
KanriTableRecord.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(KanriTableRecord);
