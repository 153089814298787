export const ROOM_MASTER_INIT_SUCCESS = "ROOM_MASTER_INIT_SUCCESS";

export const ALL_ROOM_REQUEST = "ALL_ROOM_REQUEST";

export const LOAD_ROOM_REQUEST = "LOAD_ROOM_REQUEST";
export const LOAD_ROOM_SUCCESS = "LOAD_ROOM_SUCCESS";
export const LOAD_ROOM_ERROR = "LOAD_ROOM_ERROR";

export const SAVE_ROOM_REQUEST = "SAVE_ROOM_REQUEST";
export const SAVE_ROOM_LIST_SUCCESS = "SAVE_ROOM_LIST_SUCCESS";
export const SAVE_ROOM_ERROR = "SAVE_ROOM_ERROR";

export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";
export const CREATE_ROOM_ERROR = "CREATE_ROOM_ERROR";

export const UPDATE_ROOM_SUCCESS = "UPDATE_ROOM_SUCCESS";
export const UPDATE_ROOM_ERROR = "UPDATE_ROOM_ERROR";
