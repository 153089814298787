import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import {
  formatStorage,
  getMenu,
  localStorageClearCus
} from "../common/function_common/functionCommon";

export const ItakuTantoMasterApi = {
  async list(name, id) {
    try {
      const res = await axios(
        apiUrls.INIT + apiUrls.ITAKUTANTO_MASTER_LIST + `?name=${name}&id=${id}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },

  async create(data) {
    const res = await axios.post(apiUrls.CREATE_ITAKUTANTO_MASTER, data);
    return res.data;
  },

  async update(data) {
    const res = await axios.put(apiUrls.UPDATE_ITAKUTANTO_MASTER, data);
    return res.data;
  },

  async loadInit() {
    const res = await axios
      .get(apiUrls.GET_ITAKUTANTO_MASTER_INIT);
    return res.data;
  },

  // 並び替え時に使用（未）
  // updateList(data) {
  //   return axios.put(apiUrls.UPDATE_LIST_DOCTOR_MASTER, data).then((res) => res.data);
  // },

  // loadMaxSort() {
  //   return axios.get(apiUrls.GET_DOCTOR_MASTER_MAX_SORT).then((res) => res.data);
  // },

  async save(params) {
    const mainSID = formatStorage(getMenu()).si;
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_KAIGOHOKEN_UPDATE +
        `?mainSID=${mainSID}&systemName=${systemName}`,
        {
          method: "POST",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};
