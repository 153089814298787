import {
  LOAD_IO_STATUS_SUCCESS,
  EDIT_IO_STATUS_SUCCESS,
  FILTER_SUCCESS,
  SORT_SUCCESS,
  CHANGE_MEAL_STATUS,
  INSERT_IO_STATUS_SUCCESS,
  DELETE_IO_STATUS_SUCCESS,
  EDIT_KAIHAKU_KUBUN_SUCCESS,
  LOAD_IO_STATUS_REQUEST,
  LOAD_PAGING_IO_STATUS_SUCCESS,
  IN_OUT_QUOTE,
  INSERT_IO_STATUS_REQUEST,
  INSERT_IO_STATUS_ERROR,
  EDIT_IO_STATUS_ERROR,
  EDIT_IO_STATUS_REQUEST,
} from "./actionName";
import { InOutStatusApi } from "../../api/InOutStatus";
import { RESET_STATUS } from "./actionName";
import {
  CREATE_DUTY_REPORT_ERROR,
  LOAD_DUTY_REPORT_SUCCESS,
} from "../dutyReportActions/actionName";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
export function resetStatus() {
  return (dispatch) =>
    dispatch({
      type: RESET_STATUS,
    });
}
export function getDataInOut(value, filterObj, sort, searchName, page) {
  return (dispatch) => {
    dispatch({
      type: LOAD_IO_STATUS_REQUEST,
    });
    InOutStatusApi.loadInOutStatusApi(value, filterObj, sort, searchName, page)
      .then((data) =>
        dispatch({
          type: LOAD_IO_STATUS_SUCCESS,
          InOutStatus: data,
        })
      )
      .catch((err) => {
        dispatch({
          type: LOAD_IO_STATUS_SUCCESS,
          InOutStatus: [],
        });
      });
  };
}

export function getDataInOutPaging(value, filterObj, sort, searchName, page) {
  return (dispatch) => {
    dispatch({
      type: LOAD_PAGING_IO_STATUS_SUCCESS,
    });
    InOutStatusApi.loadInOutPagingStatusApi(
      value,
      filterObj,
      sort,
      searchName,
      page
    ).then((data) =>
      dispatch({
        type: LOAD_PAGING_IO_STATUS_SUCCESS,
        PagingInOutStatus: data,
      })
    );
  };
}

export function editDataInOut(value) {
  return (dispatch) => {
    dispatch({
      type: EDIT_IO_STATUS_REQUEST,
    });
    InOutStatusApi.editInOutStatusApi(value).then(
      (data) =>
        dispatch({
          type: EDIT_IO_STATUS_SUCCESS,
          InOutEditStatus: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: EDIT_IO_STATUS_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function editDataKaihakuKubun(value) {
  return (dispatch) => {
    dispatch({
      type: EDIT_KAIHAKU_KUBUN_SUCCESS,
    });
    InOutStatusApi.editKaihakuKubunApi(value).then(
      (data) =>
        dispatch({
          type: EDIT_KAIHAKU_KUBUN_SUCCESS,
          KaihakuKubunEditStatus: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: EDIT_KAIHAKU_KUBUN_SUCCESS,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function insertDataInOut(value) {
  return (dispatch) => {
    dispatch({
      type: INSERT_IO_STATUS_REQUEST,
    });
    InOutStatusApi.insertInOutStatusApi(value).then(
      (data) =>
        dispatch({
          type: INSERT_IO_STATUS_SUCCESS,
          InOutInsertStatus: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: INSERT_IO_STATUS_ERROR,
          error:
            err.response && err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function inOutQuote(date) {
  return (dispatch) => {
    InOutStatusApi.inOutQuote(date).then(
      (data) =>
        dispatch({
          type: IN_OUT_QUOTE,
          InOutQuote: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: IN_OUT_QUOTE,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function deleteDataInOut(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_IO_STATUS_SUCCESS,
    });
    InOutStatusApi.deleteInOutStatusApi(id).then(
      (data) =>
        dispatch({
          type: DELETE_IO_STATUS_SUCCESS,
          InOutDeleteStatus: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: DELETE_IO_STATUS_SUCCESS,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

/* export function loadFilterMasterSetting() {
  return (dispatch) => {
    DataFilterApi.dataFilterApi("").then(
      (data) => dispatch({
        type: LOAD_FILTER_MASTER_SUCCESS,
        filterMaster: data
      })
    )
  }
}*/

export function filterRiyousyaData(searchData) {
  return (dispatch) =>
    dispatch({
      type: FILTER_SUCCESS,
      searchData,
    });
}

export function changeMealStatus(isMealStatus) {
  return (dispatch) =>
    dispatch({
      type: CHANGE_MEAL_STATUS,
      isMealStatus,
    });
}

export function sortReportCaseData(sortData) {
  return (dispatch) =>
    dispatch({
      type: SORT_SUCCESS,
      sortData,
    });
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
