export const SOCKET_CONNECTION_INIT = "SOCKET_CONNECTION_INIT";
export const SOCKET_CONNECTION_SUCESS = "UP_SOCKET_CONNECTION_SUCCESS";
export const SOCKET_CONNECTION_ERROR = "SOCKET_CONNECTION_ERROR";
export const SOCKET_CONNECTION_CLOSED = "SOCKET_CONNECTION_CLOSED";
export const SOCKET_MESSAGE = "SOCKET_MESSAGE";
export const SOCKET_DISCONNECT = "UP_SOCKET_DISCONNECT";

export const SOCKET_SEND = "SOCKET_SEND";

// Logout Action
export const LOGOUT = "LOGOUT";

// Error action for all request
export const ERROR_REQUEST = "ERROR_REQUEST";
export const API_CALL_REQUEST = "API_CALL_REQUEST";

// report page
export const MULTIPLE_UPDATE_ERROR = "MULTIPLE_UPDATE_ERROR";

// user report page
export const USER_MULTIPLE_UPDATE_ERROR = "USER_MULTIPLE_UPDATE_ERROR";
