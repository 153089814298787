import React, { Component } from "react";

import { LeftCircleOutlined, PlusOutlined, RightCircleOutlined } from "@ant-design/icons";

import { Button, Checkbox, Col, Input, Modal, Row, Select } from "antd";
import MediaQuery from "react-responsive";

import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";

import _ from "lodash";
import { isKanji, toKatakana } from "wanakana";

import "./SendGroup.css";

const { Option } = Select;

class SendGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: null,
      checkListGroups: [],
      checkListUsers: [],
    };

    this.addMemberToGroup = this.addMemberToGroup.bind(this);
    this.delMemberFromGroup = this.delMemberFromGroup.bind(this);
    this.handleUpdateSendGroups = this.handleUpdateSendGroups.bind(this);
  }

  componentDidMount() {
    // load list group
    this.props.sendGroup();
    this.props.getAllSendGroup();

    // load tanto master list
    this.props.userList(true);

    // load send-group
  }

  componentDidUpdate(preProps) {
    const { groups } = this.props;

    if (groups !== preProps.groups) {
      const { selectedGroup } = this.state;

      if (!selectedGroup) {
        this.setState({
          selectedGroup: groups[0],
        });
      }
    }
  }

  handleChangeGroup = (value) => {
    this.setState({
      selectedGroup: value,
      checkListGroups: [],
    });
  };

  async addMemberToGroup() {
    const { checkListUsers, selectedGroup } = this.state;

    if (checkListUsers.length > 0 && selectedGroup !== null) {
      await this.props.addMemberToGroup(checkListUsers, selectedGroup);

      this.setState({
        checkListUsers: [],
      });
    }
  }

  async delMemberFromGroup() {
    const { checkListGroups, selectedGroup } = this.state;

    if (checkListGroups.length > 0) {
      await this.props.delMemberFromGroup(checkListGroups, selectedGroup);

      this.setState({
        checkListGroups: [],
      });
    }
  }

  searchMember = () => {
    const text = document.getElementById("txtSearch").value;
    this.setState({
      searchText: text,
      checkListUsers: [],
    });
  };

  saveOnsearchToState = (e) => {
    this.setState({
      textSearch: e.target.value,
    });
  };

  onChangeSelectUser = (e) => {
    this.setState({
      checkListUsers: e,
    });
  };

  onChangeSelectSendGroupRecords = (e) => {
    this.setState({
      checkListGroups: e,
    });
  };

  showModalAddGroup = () => {
    this.setState({
      visibleAddGroup: true,
    });
  };

  handleOkAddGroup = () => {
    const text = document.getElementById("kpsg-add-send-group-input").value;
    const { groups } = this.props;

    if (!groups.includes(text)) {
      this.setState({
        selectedGroup: text,
      });

      // update state
      this.props.addNewSendGroup(text);
    }

    this.setState({
      visibleAddGroup: false,
    });
  };

  handleCancelAddGroup = () => {
    this.setState({
      visibleAddGroup: false,
    });
  };

  async handleUpdateSendGroups() {
    const { groupRecords, delIdsSendGroup } = this.props;

    const tmpAddData = [];
    if (groupRecords !== null && groupRecords.length > 0) {
      for (let i = 0; i < groupRecords.length; i++) {
        if (groupRecords[i] && groupRecords[i].id === 0) {
          tmpAddData.push(groupRecords[i]);
        }
      }
    }

    await this.props.updateSendGroupToServer(tmpAddData, delIdsSendGroup);
  }

  /**
   *
   */
  handleGoBack = (e) => {
    const { isUpdateGroup } = this.props;

    if (isUpdateGroup) {
      this.setState({
        visibleGoBack: true,
      });
    }
    else {
      e.preventDefault();
      this.props.history.goBack();
    }
  };

  handleOkGoBack = (e) => {
    this.setState({
      visibleGoBack: false,
    });

    e.preventDefault();
    this.props.history.goBack();
  };

  handleCancelGoBack = () => {
    this.setState({
      visibleGoBack: false,
    });
  };

  /**
   *
   */
  render() {
    const {
      sessionTimeout,
      groups,
      groupRecords,
      users,
      isUpdateGroup,
      isApiLoadingSG,
    } = this.props;
    const { selectedGroup, searchText, checkListGroups, checkListUsers } =
      this.state;

    let dataUsers = [];
    if (searchText && searchText !== null) {
      dataUsers = _.filter(users, (obj) => {
        if (searchText === "") {
          // case get all
          return _.includes(obj.name, "");
        } // case search by name
        if (isKanji(searchText)) {
          // kanji input
          return _.includes(obj.name, searchText);
        } // hiragana or katakana input
        return _.startsWith(obj.furigana, toKatakana(searchText));
      });
    }
    else {
      dataUsers = users;
    }
    const optionsUserRecords = [];
    if (dataUsers && dataUsers.length > 0) {
      for (let i = 0; i < dataUsers.length; i++) {
        const obj = {
          value:
            dataUsers[i].id + "_" + dataUsers[i].tid + "_" + dataUsers[i].name,
          label: dataUsers[i].name,
        };

        optionsUserRecords.push(obj);
      }
    }

    const optionsGroupRecords = [];
    if (
      selectedGroup &&
      selectedGroup !== null &&
      groupRecords &&
      groupRecords.length > 0
    ) {
      for (let i = 0; i < groupRecords.length; i++) {
        if (groupRecords[i].groupName === selectedGroup) {
          const obj = {
            value:
              (groupRecords[i].id ? groupRecords[i].id : 0) +
              "_" +
              groupRecords[i].tantoId,
            label: groupRecords[i].tantoMasterOri
              ? groupRecords[i].tantoMasterOri.name
              : "",
          };

          optionsGroupRecords.push(obj);
        }
      }
    }

    return (
      (<CommonPageContainer id="send-group-page" sessionTimeout={sessionTimeout}>
        <Row className={"submenu"}>
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={"ユーザ一覧(グループ設定)"}
                        iconType="setting"
                      />
                    }
                    TopRight={null}
                  />
                );
              }
              /**
                 * @TODO design UI for mobile
                 *
                 * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
                 */
              return null;

            }}
          </MediaQuery>
        </Row>
        <div className="send-group-content">
          <Row style={{ width: '100%'}}>
            <span style={{float: 'right', marginLeft: 'auto'}}>
              <Button
                type="primary"
                danger
                disabled={!isUpdateGroup}
                onClick={this.handleUpdateSendGroups}
                loading={isApiLoadingSG}
              >
                保存
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 16 }}
                onClick={this.handleGoBack}
              >
                閉じる
              </Button>
            </span>
          </Row>
          <Row style={{marginTop: '10px'}}>
            <Col md={12}>
              <p>グループ名</p>
              <Select
                value={selectedGroup ? selectedGroup : null}
                style={{ width: 200 }}
                onChange={this.handleChangeGroup}
              >
                {groups && groups.length > 0
                  ? groups.map((item, index) => {
                    return (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    );
                  })
                  : null}
              </Select>
              <Button
                type="primary"
                style={{ marginLeft: 8, padding: "4px 24px" }}
                onClick={this.showModalAddGroup}
              >
                <PlusOutlined />
              </Button>
            </Col>

            <Col md={12}>
              <p>メンバー</p>
              <Input
                style={{ width: 200, borderRadius: 4 }}
                placeholder="お名前"
                id={"txtSearch"}
              />
              <Button
                type="primary"
                style={{ marginLeft: 8, padding: "4px 24px" }}
                onClick={this.searchMember}
              >
                検索
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: 24, display: "flex" }}>
            <Col md={12} style={{ border: "1px solid #ccc", borderRadius: 4 }}>
              <Row style={{ display: "flex" }}>
                <Col xs={16}>
                  <Checkbox.Group
                    options={optionsUserRecords}
                    value={checkListUsers}
                    onChange={this.onChangeSelectUser}
                    className="krsg-select-area"
                  />
                </Col>
                <Col
                  xs={8}
                  className="kanri-page-send-group"
                  onClick={this.addMemberToGroup}
                >
                  <span>
                    <RightCircleOutlined />
                  </span>
                  <span>
                    <RightCircleOutlined />
                  </span>
                  <span>追加</span>
                  <span>
                    <RightCircleOutlined />
                  </span>
                  <span>
                    <RightCircleOutlined />
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={12} style={{ border: "1px solid #ccc", borderRadius: 4 }}>
              <Row style={{ display: "flex", height: "100%" }}>
                <Col
                  xs={8}
                  className="kanri-page-send-group"
                  onClick={this.delMemberFromGroup}
                >
                  <span>
                    <LeftCircleOutlined />
                  </span>
                  <span>
                    <LeftCircleOutlined />
                  </span>
                  <span>削除</span>
                  <span>
                    <LeftCircleOutlined />
                  </span>
                  <span>
                    <LeftCircleOutlined />
                  </span>
                </Col>
                <Col xs={16}>
                  <Checkbox.Group
                    options={optionsGroupRecords}
                    value={checkListGroups}
                    onChange={this.onChangeSelectSendGroupRecords}
                    className="krsg-select-area"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Modal
          open={this.state.visibleAddGroup}
          title="グループ名入力"
          onOk={this.handleOkAddGroup}
          onCancel={this.handleCancelAddGroup}
          footer={[
            <Button key="back" onClick={this.handleCancelAddGroup}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOkAddGroup}>
              OK
            </Button>,
          ]}
        >
          <p>追加するグループ名を指定してください</p>
          <Input id="kpsg-add-send-group-input" />
        </Modal>
        <Modal
          open={this.state.visibleGoBack}
          title="キャンセル確認"
          onOk={this.handleOkGoBack}
          onCancel={this.handleCancelGoBack}
          footer={[
            <Button key="back" onClick={this.handleCancelGoBack}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOkGoBack}>
              OK
            </Button>,
          ]}
        >
          <p>キャンセルしてもよろしいですか？</p>
        </Modal>
      </CommonPageContainer>)
    );
  }
}

export default SendGroup;
