import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../../../common/configs/common-url";
import { ConstSet } from "../../../common/configs/constset";
import { getMenu } from "../../../common/function_common/functionCommon";
import { StorageUtils } from "../../utils";
import { ConfigName } from "../../../config-name";

let YougoMasterNaiyouAllApi = {
  yougoMasterNaiyouAll() {
    return axios(apiUrls.INIT + apiUrls.GET_YOUGO_MASTER_NAIYOU_ALL, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/management/login");
          }

          return err;
        }
      });
  },
};

let YougoMasterNaiyouApi = {
  yougoMasterNaiyou(listName) {
    return axios
      .get(apiUrls.INIT + apiUrls.GET_YOUGO_MASTER_NAIYOU, {
        params: {
          listName,
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  createYougoMaster(listName, naiyou) {
    return axios
      .post(apiUrls.INIT + apiUrls.POST_YOUGO_MASTER, {
        listName: listName,
        naiyou: naiyou,
      })
      .then((res) => {
        return res.data;
      });
  },
  updateYougoMasterApi(listName, naiyou) {
    return axios
      .put(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
        listName: listName,
        naiyou: naiyou,
      })
      .then((res) => {
        return res.data;
      });
  },
};

//
let YougoMasterUpdateApi = {
  update(key, naiyou) {
    return axios(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
      method: "PUT",
      data: {
        listName: key,
        naiyou: naiyou,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/management/login");
          }

          return err;
        }
      });
  },
};

let YougoMasterEditApi = {
  yougoMasterEditApi(naiyou, type) {
    let listName = "";
    if (type && type > 0) {
      switch (type) {
        case 1:
          listName = "バイタルコメント";
          break;

        case 2:
          listName = "食事コメント";
          break;

        case 3:
          listName = "水分コメント";
          break;

        case 4:
          listName = "排泄コメント";
          break;

        case 5:
          listName = "入浴コメント";
          break;

        case 6:
          listName = "服薬コメント";
          break;

        case 7:
          listName = "介護記録コメント";
          break;

        case 8:
          listName = "その他コメント";
          break;

        case 9:
          listName = "画像コメント";
          break;

        default:
          break;
      }
    } else {
      let tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          listName = "バイタルコメント";
          break;

        case ConstSet.KIROKU_MEAL:
          listName = "食事コメント";
          break;

        case ConstSet.KIROKU_SUIBUN:
          listName = "水分コメント";
          break;

        case ConstSet.KIROKU_HAISETU:
          listName = "排泄コメント";
          break;

        case ConstSet.KIROKU_NYUYOKU:
          listName = "入浴コメント";
          break;

        case ConstSet.KIROKU_FUKUYAKU:
          listName = "服薬コメント";
          break;

        case ConstSet.KIROKU_KAIGO:
          listName = "介護記録コメント";
          break;

        case ConstSet.KIROKU_TOKKI:
          listName = "その他コメント";
          break;

        case ConstSet.KIROKU_FILE:
          listName = "画像コメント";
          break;

        default:
          break;
      }
    }

    return axios(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
      method: "PUT",
      data: {
        listName: listName,
        naiyou: naiyou,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/management/login");
          }

          return err;
        }
      });
  },
};

let YougoMasterEctApi = {
  yougoMasterEctApi(sid, pageName) {
    return axios(
      apiUrls.INIT +
        apiUrls.GET_YOUGO_MASTER_ECT +
        `?sid=${sid}&pageName=${pageName ? pageName : "default"}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/management/login");
          }

          return err;
        }
      });
  },
};

let YougoMasterEditEctApi = {
  yougoMasterEditEctApi(type, naiyou) {
    const key = getMenu();
    let listName =
      "バイタル他" +
      type +
      "_" +
      StorageUtils.getValue(ConfigName.SWLIFE_MENU).si;

    return axios(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
      method: "PUT",
      data: {
        listName: listName,
        naiyou: naiyou,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/management/login");
          }

          return err;
        }
      });
  },
};

export {
  YougoMasterNaiyouAllApi,
  YougoMasterNaiyouApi,
  YougoMasterEditApi,
  YougoMasterEctApi,
  YougoMasterEditEctApi,
  YougoMasterUpdateApi,
};
