import { notification } from "antd";

export function openNotificationWithIcon(type, notifyContent) {
  notification[type]({
    message: notifyContent,
    // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    placement: "topRight",
    duration: type === "success" ? 1 : 3,
    prefixCls: "pljp-notification",
  });
}
