export const seikatuCheckActionTypes = {
  SAVE_SEIKATU_CHECK_REQUEST: "SAVE_SEIKATU_CHECK_REQUEST",
  SAVE_SEIKATU_CHECK_SUCCESS: "SAVE_SEIKATU_CHECK_SUCCESS",
  SAVE_SEIKATU_CHECK_ERROR: "SAVE_SEIKATU_CHECK_ERROR",

  LOAD_SEIKATUCHECK_BY_ID_REQUEST: "LOAD_SEIKATUCHECK_BY_ID_REQUEST",
  LOAD_SEIKATUCHECK_BY_ID_SUCCESS: "LOAD_SEIKATUCHECK_BY_ID_SUCCESS",
  LOAD_SEIKATUCHECK_BY_ID_ERROR: "LOAD_SEIKATUCHECK_BY_ID_ERROR",

  LOAD_SEIKATUCHECK_BY_KKID_REQUEST: "LOAD_SEIKATUCHECK_BY_KKID_REQUEST",
  LOAD_SEIKATUCHECK_BY_KKID_SUCCESS: "LOAD_SEIKATUCHECK_BY_KKID_SUCCESS",
  LOAD_SEIKATUCHECK_BY_KKID_ERROR: "LOAD_SEIKATUCHECK_BY_KKID_ERROR",

  LOAD_SEIKATU_CHECK_TO_QUOTE: "LOAD_SEIKATU_CHECK_TO_QUOTE",
  LOAD_SEIKATU_CHECK_TO_QUOTE_SUCCESS: "LOAD_SEIKATU_CHECK_TO_QUOTE_SUCCESS",
  LOAD_SEIKATU_CHECK_TO_QUOTE_ERROR: "LOAD_SEIKATU_CHECK_TO_QUOTE_ERROR",

  GET_IDS_REQUEST: "GET_IDS_REQUEST",
  GET_IDS_SUCCESS: "GET_IDS_SUCCESS",
  GET_IDS_ERROR: "GET_IDS_ERROR",

  GET_ADL_INYO: "GET_ADL_INYO",
  GET_ADL_INYO_SUCCESS: "GET_ADL_INYO_SUCCESS",
  GET_ADL_INYO_ERROR: "GET_ADL_INYO_ERROR",

  SET_NEW_DATA: "SET_NEW_DATA",
};
