import { checkTemplate } from "@pdfme/generator";
import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";
import _ from "lodash";

export const life_Haisetsu_InputFormV3PDF = (data) => {
  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];
  const generateOption = (options, field) => {
    if (options && options[field]) {
      return options[field]
        .split(";")
        .filter((item) => !!item)
        .map((item) => {
          // let temp = (item && item.split(":")[0]) ?? item;
          let temp = item.includes(":") ? item.split(":")[0] : item;
          return { label: item, value: temp };
        });
    }
    return [];
  };

  const generateGenderString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += item.label + " ";
      }
    });
    return result;
  };

  //{ label: item, value: temp }
  //データ
  const generateCheckBoxString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    return result;
  };

  const generateCheckBoxString2 = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label.split(":")[1] + " ";
      } else {
        result += "□" + item.label.split(":")[1] + " ";
      }
    });
    return result;
  };

  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 78;
    const MAX_LINE = 10;
    let stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    if (stringArr.length > 1) {
      stringArr.forEach((item) => {
        countOld = count;
        count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
        if (count < MAX_LINE) {
          result = result + item + "\n";
        } else {
          result = result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
          return;
        }
      });
    } else {
      stringArr.forEach((item) => {
        result = result + item.substring(0, (MAX_LINE - count) * LENGTH_LINE - 3);
        return;
      });
    }
    return result;
  };

  const dateCheckFormat = (date) => {
    if (date && date !== "0001-01-01") {
      return date.replace(/-/g, "/");
    }
    return "";
  };

  const convertKaigodo = (data, kaigodo) => {
    return kaigodo?.find((k) => k.kaigodoNo == data)?.kaigodoName ?? "";
  };

  const convertText = (data, options) => {
    return options?.find((v) => v.value == data)?.label ?? "";
  };

  const obj = {
    normal: "1:あり;0:なし",
  };

  const { haisetsu, lifeYougoList, riyouKaigohoken, kaigodo } = data;

  if (typeof haisetsu === 'object' && Array.isArray(haisetsu)) {
    let sampledata = [];
    haisetsu?.map((haisetsu) => {
      sampledata.push({
        記入者名: haisetsu?.tantoMaster?.name ?? "",
        医師名: haisetsu?.doctorMaster?.name?? "",
        看護師名: haisetsu?.nurseTantoMaster?.name ?? "",
        氏名: haisetsu?.riyousya?.name ?? "",
        生年月日: formatBirthday(haisetsu?.riyousya?.birthday) ?? "",
        性別: `${generateGenderString(GENDER_OPTIONS, haisetsu?.riyousya?.gender ?? "")}`,
        保険者番号: haisetsu?.riyouKaigoHoken?.hokensyaNo ?? "",
        被保険者番号: haisetsu?.riyouKaigoHoken?.hihokenNo ?? "",
        要介護度: convertKaigodo(haisetsu?.careLevel, kaigodo),
        自立度: `${generateCheckBoxString(
          generateOption(lifeYougoList, "ziritsudo"),
          haisetsu.dailyDegree
        )}`,
        認知度: `${generateCheckBoxString(
          generateOption(lifeYougoList, "nintido"),
          haisetsu.dementiaDegree
        )}`,
        評価日: formatSeireki(haisetsu.hyoukaDate) ?? "",
        評価時点: convertText(
          haisetsu.status,
          generateOption(lifeYougoList, "hyoukaziten")
        ),
        トイレ動作: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui1"),
          haisetsu.dischargeStatus
        )}`,
        排便コントロール: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui2"),
          haisetsu.defecationEvaluationStatus
        )}`,
        排尿コントロール: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui3"),
          haisetsu.urinationEvaluationStatus
        )}`,
        排便排尿可能: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui4"),
          haisetsu.excretionStatus
        )}`,
        おむつ: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui5"),
          haisetsu.diaperUserEvaluationStatus
        )}`,
        ポータブルトイレ: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui6"),
          haisetsu.portableToiletEvaluationStatus
        )}`,
        尿道カテーテル: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui7"),
          haisetsu.urinaryCatheterStatus
        )}`,
        人工肛門: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui8"),
          haisetsu.artificialAnusStatus
        )}`,
        トイレへの誘導促し: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui9"),
          haisetsu.excretionInductionStatus
        )}`,
        支援の必要性: `${generateCheckBoxString2(
          generateOption(obj, "normal"),
          haisetsu.excretionStatusNecessity
        )}`,
        介護要因: `${formatString10Line(haisetsu.excretionCauseSupport) ?? ""}`,
        計画作成日: `${formatSeireki(haisetsu.makeDate) ?? ""}`,
        支援計画: `${formatString10Line(haisetsu.supportProgram) ?? ""}`,
      })
    })
    return sampledata;
  } else if (typeof haisetsu === 'object') {
    let sampledata = [
      {
        記入者名: haisetsu?.tantoMaster?.name ?? "",
        医師名: haisetsu?.doctorMaster?.name ?? "",
        看護師名: haisetsu?.nurseTantoMaster?.name ?? "",
        氏名: haisetsu?.riyousya?.name ?? "",
        生年月日: formatBirthday(haisetsu?.riyousya?.birthday) ?? "",
        性別: `${generateGenderString(GENDER_OPTIONS, haisetsu?.riyousya?.gender ?? "")}`,
        保険者番号: riyouKaigohoken?.hokensyaNo ?? "",
        被保険者番号: riyouKaigohoken?.hihokenNo ?? "",
        要介護度: convertKaigodo(haisetsu?.careLevel, kaigodo),
        自立度: `${generateCheckBoxString(
          generateOption(lifeYougoList, "ziritsudo"),
          haisetsu.dailyDegree
        )}`,
        認知度: `${generateCheckBoxString(
          generateOption(lifeYougoList, "nintido"),
          haisetsu.dementiaDegree
        )}`,
        評価日: formatSeireki(haisetsu.hyoukaDate) ?? "",
        評価時点: convertText(
          haisetsu.status,
          generateOption(lifeYougoList, "hyoukaziten")
        ),
        トイレ動作: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui1"),
          haisetsu.dischargeStatus
        )}`,
        排便コントロール: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui2"),
          haisetsu.defecationEvaluationStatus
        )}`,
        排尿コントロール: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui3"),
          haisetsu.urinationEvaluationStatus
        )}`,
        排便排尿可能: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui4"),
          haisetsu.excretionStatus
        )}`,
        おむつ: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui5"),
          haisetsu.diaperUserEvaluationStatus
        )}`,
        ポータブルトイレ: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui6"),
          haisetsu.portableToiletEvaluationStatus
        )}`,
        尿道カテーテル: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui7"),
          haisetsu.urinaryCatheterStatus
        )}`,
        人工肛門: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui8"),
          haisetsu.artificialAnusStatus
        )}`,
        トイレへの誘導促し: `${generateCheckBoxString2(
          generateOption(lifeYougoList, "bunrui9"),
          haisetsu.excretionInductionStatus
        )}`,
        支援の必要性: `${generateCheckBoxString2(
          generateOption(obj, "normal"),
          haisetsu.excretionStatusNecessity
        )}`,
        介護要因: `${formatString10Line(haisetsu.excretionCauseSupport) ?? ""}`,
        計画作成日: `${formatSeireki(haisetsu.makeDate) ?? ""}`,
        支援計画: `${formatString10Line(haisetsu.supportProgram) ?? ""}`,
      },
    ];
    return sampledata;
  } else {
    return [{}];
  }
};
