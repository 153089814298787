import React from "react";
import { Radio } from "antd";

const RadioGroup = Radio.Group;

export default class HaisetuKubunKB extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    this.props.handleHaisetuKubun(e.target.value);
  };

  render() {
    const { value, styleCus } = this.props;
    return (
      <div
        style={{ textAlign: styleCus ? styleCus : "center" }}
        className="modal-check"
      >
        <RadioGroup
          onChange={this.onChange}
          value={value}
          className="kdf-f-extent"
        >
          <Radio value={"排尿"}>排尿</Radio>
          <Radio value={"排便"}>排便</Radio>
        </RadioGroup>
      </div>
    );
  }
}
