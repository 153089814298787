import {
  LOAD_IO_STATUS_SUCCESS,
  FILTER_SUCCESS,
  SORT_SUCCESS,
  CHANGE_MEAL_STATUS,
  EDIT_IO_STATUS_SUCCESS,
  INSERT_IO_STATUS_SUCCESS,
  DELETE_IO_STATUS_SUCCESS,
  LOAD_IO_STATUS_REQUEST,
  EDIT_KAIHAKU_KUBUN_SUCCESS,
  IN_OUT_QUOTE,
  INSERT_IO_STATUS_REQUEST,
  INSERT_IO_STATUS_ERROR,
  EDIT_IO_STATUS_ERROR,
  EDIT_IO_STATUS_REQUEST,
} from "../../actions/ioStatusActions/actionName";
import _ from "lodash";
import { RESET_STATUS } from "../../actions/dutyReportActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

export default function ioStatusReducers(
  state = { loading: false, inserting: false },
  action
) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        notifyDisplay: 0,
        notifyContentInsert: {},
        notifyContentEdit: {},
      };
    case IN_OUT_QUOTE:
      if (_.isEmpty(action.InOutQuote)) {
        openNotificationWithIcon("error", "本日入所の利用者がありません。");
      } else {
        openNotificationWithIcon("success", "本日入所の利用者を追加しました。");
      }
      return {
        ...state,
        dataQuote: action.InOutQuote ? action.InOutQuote : [],
        notifyContentQuote: { type: "success" },
      };
    case LOAD_IO_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        notifyDisplay: 0,
        notifyContent: {},
      };
    case LOAD_IO_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.InOutStatus ? action.InOutStatus.data : action.InOutStatus,
        meta: action.InOutStatus ? action.InOutStatus.meta : action.InOutStatus,
        notifyContent: {},
        notifyContentQuote: {},
      };
    case EDIT_IO_STATUS_REQUEST: {
      return {
        ...state,
        notifyContent: {},
        notifyDisplay: 0,
        editing: true,
      };
    }
    case EDIT_IO_STATUS_SUCCESS: {
      return {
        ...state,
        InOutEditStatus: action.InOutEditStatus,
        notifyContentEdit: { type: "success", message: "保存しました" },
        editing: false,
      };
    }
    case EDIT_IO_STATUS_ERROR: {
      let message;
      if (action.error.code === "RIYOUSYA_ALREADY_EXIST") {
        message = { type: "error", message: "期間が重複できません" };
      } else {
        message = { type: "error", message: action.error.message };
      }
      return {
        ...state,
        InOutEditStatus: action.InOutEditStatus,
        notifyContentEdit: message,
        editing: false,
      };
    }
    case INSERT_IO_STATUS_REQUEST: {
      return {
        ...state,
        notifyContent: {},
        notifyDisplay: 0,
        inserting: true,
      };
    }
    case INSERT_IO_STATUS_SUCCESS: {
      return {
        ...state,
        notifyContentInsert: { type: "success", message: "保存しました" },
        notifyDisplay: 1,
        inserting: false,
      };
    }
    case INSERT_IO_STATUS_ERROR: {
      let messageInsert;
      if (action.error.code === "RIYOUSYA_ALREADY_EXIST") {
        messageInsert = { type: "error", message: "期間が重複できません" };
      } else {
        messageInsert = { type: "error", message: action.error.message };
      }
      return {
        ...state,
        notifyContentInsert: messageInsert,
        notifyDisplay: 2,
        inserting: false,
      };
    }
    case DELETE_IO_STATUS_SUCCESS: {
      let checkMessage;
      if (action.InOutDeleteStatus && action.InOutDeleteStatus.id) {
        checkMessage = { type: "success", message: "削除しました。" };
      } else if (
        action.InOutDeleteStatus &&
        !action.InOutDeleteStatus.id &&
        action.InOutDeleteStatus !== undefined
      ) {
        checkMessage = { type: "error", message: "削除できませんでした" };
      }
      return {
        ...state,
        notifyContentEdit: checkMessage,
      };
    }
    case EDIT_KAIHAKU_KUBUN_SUCCESS: {
      let checkMessage;
      if (action.KaihakuKubunEditStatus && action.KaihakuKubunEditStatus.id) {
        checkMessage = { type: "success", message: "保存しました" };
      } else if (
        action.KaihakuKubunEditStatus &&
        !action.KaihakuKubunEditStatus.id &&
        action.KaihakuKubunEditStatus !== undefined
      ) {
        checkMessage = { type: "error", message: "値を変更できません" };
      }
      return {
        ...state,
        notifyContentEdit: checkMessage,
      };
    }
    case FILTER_SUCCESS: // Search Function(local)
      return {
        ...state,
        filteredIndividualIOStatus: searchAll(
          state.individualPlans,
          state.allNyutaisho,
          state.filterMaster,
          action.searchData
        ),
        filterConditions: action.searchData,
        notifyContent: {},
      };
    case SORT_SUCCESS:
      return {
        ...state,
        reportCases: sortAlgorithm(state.reportCases, action.sortData.sort),
        filterConditions: action.sortData,
        notifyContent: {},
      };
    case CHANGE_MEAL_STATUS:
      return {
        ...state,
        isMealStatus: action.isMealStatus,
        notifyContent: {},
      };
    default:
      return state;
  }
}

function searchAll(individualPlans, arraySearch, roomList, dataFilter) {
  if (!_.isEmpty(dataFilter)) {
    if (
      !dataFilter.search.checkbox1 &&
      !dataFilter.search.checkbox2 &&
      !dataFilter.search.checkbox3
    ) {
      return individualPlans;
    }
    if (arraySearch) {
      // SEARCH BY BUNRUI
      if (dataFilter.search.field10 && dataFilter.search.checkbox1) {
        const valueKey = dataFilter.search.field10.split("=")[0];

        if (valueKey === "分類名1" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui1 === dataFilter.search.field11
          );
        }

        if (valueKey === "分類名2" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui2 === dataFilter.search.field11
          );
        }

        if (valueKey === "分類名3" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui3 === dataFilter.search.field11
          );
        }

        if (valueKey === "分類名4" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui4 === dataFilter.search.field11
          );
        }

        if (valueKey === "分類名5" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui5 === dataFilter.search.field11
          );
        }
      }

      // SEARCH BY nyutaisho type
      if (dataFilter.search.field2 && dataFilter.search.checkbox2) {
        arraySearch = arraySearch.filter(
          (item) => item.kubun === dataFilter.search.field2
        );
      }

      // SEARCH BY ROOM
      if (
        dataFilter.search.field3 &&
        dataFilter.search.checkbox3 &&
        roomList &&
        roomList.roomMasters
      ) {
        const filteredRooms = roomList.roomMasters.filter(
          (item) => item.bunrui === dataFilter.search.field3
        );
        const rooms = filteredRooms.map((i) => i.room);

        // Algorithm filter room in array
        arraySearch = arraySearch.filter(
          (item) => rooms.indexOf(item.room) > -1
        );
      }
    }
    const filteredRiyousyas = arraySearch.map((hist) => hist.riyousya.id);
    return _.filter(
      individualPlans,
      (i) => filteredRiyousyas.indexOf(i.riyousyaId) > -1
    );
  }
  return individualPlans;
}

function sortKeyCheck(key) {
  switch (key) {
    case "1":
      return "riyousyaFurigana";
    case "2":
      return "date";
    case "3":
      return "time";
    case "4":
      return "kiroku_kubun";
    case "5":
      return "tyuif";
    case "6":
      return "nishif";
    case "7":
      return "tantoFurigana";
    case "8":
      return "room";
    // case "9":
    //     return "roomBunrui";
    default:
      return null;
  }
}

function sortAlgorithm(arraySort, sortData) {
  if (arraySort && sortData) {
    const fieldArray = [],
      orderArray = [];
    if (sortData.key1 && sortData.key1 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key1));
      if (sortData.value1 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (sortData.key2 && sortData.key2 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key2));
      if (sortData.value2 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (sortData.key3 && sortData.key3 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key3));
      if (sortData.value3 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (sortData.key4 && sortData.key4 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key4));
      if (sortData.value4 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (sortData.key5 && sortData.key5 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key5));
      if (sortData.value5 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (fieldArray.length > 0) {
      arraySort = _.orderBy(arraySort.slice(), fieldArray, orderArray);
    }
  }
  return arraySort;
}
