import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import {
  formatStorage,
  getMenu,
} from "../common/function_common/functionCommon";

const TantoMasterListApi = {
  async tantoMasterList() {
    const key = getMenu();
    const systemControlName = JSON.parse(localStorage.getItem(key)).sn;
    const systemControlId = JSON.parse(localStorage.getItem(key)).si;
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_TANTO_MASTER_LIST2, {
        method: "GET",
        params: {
          systemControlName,
          systemControlId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  },
  async tantoMasterListByKikan(bunrui, searchName, page) {
    const key = getMenu();
    const systemControlName = JSON.parse(localStorage.getItem(key)).sn;
    const systemControlId = JSON.parse(localStorage.getItem(key)).si;
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_TANTO_MASTER_LIST_KIKAN, {
        method: "GET",
        params: {
          systemControlName,
          systemControlId,
          bunrui,
          searchName,
          page: page ? page.toString() : "1",
        },
      });
      return res.data;
    }
    catch (err) {
      return [];
    }
  },
  async tantoMasterList2() {
    const key = getMenu();
    const systemControlName = formatStorage(key).sn;
    const systemControlId = formatStorage(key).si;
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_TANTO_MASTER_LIST2, {
        method: "GET",
        params: {
          systemControlName,
          systemControlId
        }
      });
      return res.data;
    }
    catch (err) {
      return [];
    }
  },

  async tantoMasterById(id) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_TANTO_MASTER_BY_ID, {
        method: "GET",
        params: {
          id
        }
      });
      return res.data;
    }
    catch (err) {
      return null;
    }
  }
};
export default TantoMasterListApi;
