/* eslint-disable react/prop-types */
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  Button,
  Checkbox,
  Col,
  Modal,
  Pagination,
  Row,
  Spin,
  Table,
} from "antd";
import _ from "lodash";
import React from "react";
import MediaQuery from "react-responsive";
import { ConstSet } from "../../../common/configs/constset";
import { convert_wareki_4 } from "../../../common/function_common/functionCommon";
import { HokenTableForm1 } from "./HokenTableForm1";

export default class HokenTable1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandRowIndex: 0,
      deleteVisible: false,
      delRecordId: 0,
      newId: -1,
      rowIndex: null,
      checked: false,
      inputVisible: false,
      recordInput: [],
      jigyousyoName: "",
      hokenName: "",
      addCopyData: null,
      isFormUpdate: false,
      syuruiCds: "43, 46, 73, 77",
      updateVisible: false,
    };

    // bind place
  }

  /**
   * this.props 最新のprops
   * @param {*} preProps 変わる前のprops
   */
  componentDidUpdate(preProps) {
    // データの更新があれば最上部のデータをコピーする（追加用）
    if (this.props.data && preProps.data != this.props.data) {
      this.setState({
        addCopyData: this.props.data[0],
      });
    }
  }

  expandRow = (recordId) => {
    const { expandRowIndex } = this.state;

    if (expandRowIndex === recordId) {
      this.setState({
        expandRowIndex: 0,
      });
    }
    else {
      this.setState({
        expandRowIndex: recordId,
      });
    }
  };

  showDeleteConfirm = (recordId, index) => {
    this.setState({
      deleteVisible: true,
      delRecordId: recordId,
      rowIndex: index,
      checked: false,
    });
  };

  /**
   * 修正ボタン
   * 行のデータ(record)でフォームモーダル呼び出し
   * @param {*} record
   */
  handleEdit = (record) => {
    if (record.kyotakuNo) {
      this.props.getJigyousyoName(
        record.kyotakuNo,
        this.state.syuruiCds,
        (data) => {
          if (data) {
            this.setState({
              jigyousyoName: data,
            });
          }
        }
      );
    }
    this.setState({
      inputVisible: true,
      recordInput: record,
    });
  };

  // //不要 stateの初期化
  nameStateOff = () => {
    this.setState({
      jigyousyoName: "",
    });
  };

  closeDeleteConfirm = () => {
    this.setState({
      deleteVisible: false,
      delRecordId: 0,
      rowIndex: null,
      checked: false,
    });
  };

  handleConfirmDelete = () => {
    const { delRecordId, rowIndex } = this.state;
    const id = _.clone(delRecordId);

    this.props.handleDelHokenTable(id, rowIndex);

    this.setState({
      deleteVisible: false,
      delRecordId: 0,
      rowIndex: null,
      checked: false,
      expandRowIndex: 0,
    });
  };

  handleChangeCheckbox = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked,
    });
  };

  // 新規追加
  handleAddNew = () => {
    const { addCopyData } = this.state;
    if (addCopyData && addCopyData?.kyotakuNo) {
      this.props.getJigyousyoName(
        addCopyData.kyotakuNo,
        this.state.syuruiCds,
        (data) => {
          this.setState({
            jigyousyoName: data,
          });
        }
      );
    }

    if (addCopyData) {
      this.setState({
        recordInput: {
          // 一番上のデータのコピー
          id: -2,
          hokensyaNo: addCopyData.hokensyaNo,
          kaigohokenMaster: addCopyData.kaigohokenMaster,
          hihokenNo: addCopyData.hihokenNo,
          kyufu: addCopyData.kyufu,
          todokedeDate: addCopyData.todokedeDate,
          kaigodo: addCopyData.kaigodo,
          kubunsikyu: addCopyData.kubunsikyu,
          kyotakuNo: addCopyData.kyotakuNo,
          serviceKikanMaster: addCopyData.serviceKikanMaster,
          kyotakuSakuseiKubun: addCopyData.kyotakuSakuseiKubun,
          // jigyousyoName: jigyousyoName,
        },
      });
    }
    else {
      this.setState({
        jigyousyoName: "",
        recordInput: [],
      });
    }

    setTimeout(() => {
      this.setState({
        inputVisible: true,
      });
    }, 700);
  };

  closeInput = () => {
    this.setState({
      inputVisible: false,
      recordInput: [],
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  updateCancel = () => {
    this.setState({
      updateVisible: false,
    });
  };
  updateOk = () => {
    this.handleInputModalOk();
    setTimeout(() => {
      this.setState({
        updateVisible: false,
      });
    }, 100);
  };

  // フォームモーダルOK
  handleInput = () => {
    // e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (
        !values.hihokenNo ||
        !values.hokensyaNo ||
        !values.kaigodo ||
        !values.kyotakuNo ||
        values.yukoDateFrom.format(ConstSet.DATE_FORMAT) === "0001-01-01" ||
        values.tekiyouDate.format(ConstSet.DATE_FORMAT) === "0001-01-01"
      ) {
        this.setState({
          updateVisible: true,
        });
      }
      else {
        this.update(values);
      }

      // this.update(values);
    });
  };
  // 必須無視の保存
  handleInputModalOk = () => {
    // e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.update(values);
    });
  };

  update = (values) => {
    if (!values.id) {
      values.id = this.state.newId;
    }

    if (values.kofuDate) {
      values.kofuDate = values.kofuDate.format(ConstSet.DATE_FORMAT);
    }

    if (values.kubunYukoDateFrom) {
      values.kubunYukoDateFrom = values.kubunYukoDateFrom.format(
        ConstSet.DATE_FORMAT
      );
    }

    if (values.kubunYukoDateTo) {
      values.kubunYukoDateTo = values.kubunYukoDateTo.format(
        ConstSet.DATE_FORMAT
      );
    }

    if (values.ninteiDate) {
      values.ninteiDate = values.ninteiDate.format(ConstSet.DATE_FORMAT);
    }

    if (values.tekiyouDate) {
      values.tekiyouDate = values.tekiyouDate.format(ConstSet.DATE_FORMAT);
    }

    if (values.todokedeDate) {
      values.todokedeDate = values.todokedeDate.format(ConstSet.DATE_FORMAT);
    }

    if (values.yukoDateFrom) {
      values.yukoDateFrom = values.yukoDateFrom.format(ConstSet.DATE_FORMAT);
    }

    if (values.yukoDateTo) {
      values.yukoDateTo = values.yukoDateTo.format(ConstSet.DATE_FORMAT);
    }

    //
    values.id = values.id.toString();
    values.kubunsikyu = values.kubunsikyu ? values.kubunsikyu.toString() : "0";
    values.kyotakuSakuseiKubun = values.kyotakuSakuseiKubun
      ? values.kyotakuSakuseiKubun.toString()
      : "0";
    values.kyufu = values.kyufu ? values.kyufu.toString() : "0";
    values.riyousyaId = values.riyousyaId.toString();

    values.hihokenNo = values.hihokenNo ? values.hihokenNo.toString() : "";
    values.hokensyaNo = values.hokensyaNo ? values.hokensyaNo.toString() : "";
    values.kyotakuNo = values.kyotakuNo ? values.kyotakuNo.toString() : "";
    values.sinseif = values.sinseif ? "1" : "0";
    values.serviceKikanMasterId = values.serviceKikanMasterId
      ? String(values.serviceKikanMasterId)
      : null;

    this.props.updateHokenTable1(values);

    this.setState({
      inputVisible: false,
      recordInput: [],
      expandRowIndex: 0,
      newId: this.state.newId,
    });
  };

  onChangePag = (page) => {
    this.props.onChangeKaigoPag(page);
  };

  /**
   * Update file to State, Prepare data to save when click on 更新 button
   * @param {*} value 値
   * @param {*} type どの箇所か
   */
  getHandleFormInput = (value, type) => {
    const prepareData = this.state.recordInput;
    const objData = {};

    // handle input number
    if (
      type === "hokensyaNo" ||
      type === "hihokenNo" ||
      type === "kyufu" ||
      type === "kubunsikyu"
    ) {
      value = value ? value : "0";
    }

    objData[type] = Number(value);
    this.setState({
      recordInput: Object.assign({}, prepareData, objData),
      isFormUpdate: true,
    });
  };

  serviceKikanSearch = (bunrui, name) => {
    this.props.getServiceKikanMasterList(bunrui, name);
  };

  render() {
    const {
      data,
      riyousyaId,
      serviceKikanMaster,
      serviceSyurui,
      kaigodoMaster,
      page,
      pageTotal,
      duplicationRiyousya,
      loading,
    } = this.props;
    const { expandRowIndex, recordInput } = this.state;

    const columns = [
      {
        dataIndex: "1",
        key: "1",
        render: (text, record, index) => {
          let jigyouName = "";

          if (record?.serviceKikanMaster?.id) {
            if (
              record.serviceKikanMaster.syuruiCd == "43" ||
              record.serviceKikanMaster.syuruiCd == "46" ||
              record.serviceKikanMaster.syuruiCd == "73" ||
              record.serviceKikanMaster.syuruiCd == "77"
            ) {
              jigyouName = record.serviceKikanMaster.jigyousyoName;
            }
          }
          return (
            <div>
              <div
                onClick={() => this.expandRow(index + 1)}
                className="riyou-hoken-table-row"
              >
                <Row>
                  <Col md={5}>
                    <span>保険者番号: </span>
                    <span>{record.hokensyaNo}</span>
                  </Col>
                  <Col md={6}>
                    <span>
                      {record && record.kaigodoName ? record.kaigodoName : ""}{" "}
                      &nbsp;&nbsp;限度額: {record.kubunsikyu}
                    </span>
                  </Col>
                  <Col md={11}>
                    <span>
                      有効期限: {convert_wareki_4(record.yukoDateFrom)}
                      &nbsp;〜&nbsp;{convert_wareki_4(record.yukoDateTo)}
                    </span>
                  </Col>
                  <Col md={2}>
                    <span>
                      {record && record.sinseif && record.sinseif === 1
                        ? "申請中"
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <span>被保険者番号: </span>
                    <span>
                      {record?.hihokenNo && record?.hihokenNo != "0"
                        ? record.hihokenNo
                        : ""}
                    </span>
                  </Col>
                  <Col md={6}>
                    <span>給付率 {record.kyufu}%</span>
                  </Col>
                  <Col md={7}>
                    <span>
                      適用開始日:{" "}
                      {record?.tekiyouDate
                        ? convert_wareki_4(record.tekiyouDate)
                        : ""}
                    </span>
                  </Col>
                  <Col md={6}>
                    <span>届出日:</span>{" "}
                    <span>
                      {record?.todokedeDate
                        ? convert_wareki_4(record.todokedeDate)
                        : ""}
                    </span>
                  </Col>
                </Row>
              </div>
              {expandRowIndex && expandRowIndex === index + 1 ? (
                <div>
                  <Row>
                    <Col md={24}>
                      <span style={{ marginLeft: 5 }}>包括・居宅名: </span>
                      <span>{jigyouName}</span>
                    </Col>
                  </Row>
                  <div style={{ float: "right", padding: "0 5px 5px 0" }}>
                    <Button
                      icon={<LegacyIcon type={"delete"} />}
                      style={{ color: "white", backgroundColor: "gray" }}
                      onClick={() => this.showDeleteConfirm(record.id, index)}
                    >
                      {"削除"}
                    </Button>
                    <Button
                      icon={<LegacyIcon type={"edit"} />}
                      style={{ color: "white", backgroundColor: "#00838F" }}
                      onClick={() => this.handleEdit(record)}
                    >
                      {"修正"}
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          );
        },
      },
    ];

    return (
      (<div>
        <Spin spinning={loading ? loading : false}>
          <Row style={{ justifyContent: "flex-end" }}>
            <Button
              icon={<LegacyIcon type={"plus"} />}
              style={{
                color: "white",
                backgroundColor: "#dc0606",
                marginBottom: 8,
              }}
              onClick={() => this.handleAddNew()}
            >
              {"追加"}
            </Button>
          </Row>

          <Table
            dataSource={data}
            columns={columns}
            showHeader={false}
            className="riyou-hoken-table"
            rowKey={(record, index) => index}
            pagination={false}
          />
          <Pagination
            onChange={this.onChangePag}
            total={pageTotal * 4}
            pageSize={4}
            current={page}
            style={{ textAlign: "right", marginTop: 16 }}
          />
        </Spin>
        {/* modal del */}
        <Modal
          open={this.state.deleteVisible}
          title="削除確認（同意するにチェックしてOKボタンを押してください）"
          onCancel={() => this.closeDeleteConfirm()}
          footer={[
            <Button
              disabled={!this.state.checked}
              key="delete"
              type={"primary"}
              onClick={() => this.handleConfirmDelete()}
            >
              OK
            </Button>,
            <Button
              key="canceldelete"
              onClick={() => this.closeDeleteConfirm()}
            >
              Cancel
            </Button>,
          ]}
        >
          <div style={{ backgroundColor: "aliceblue" }}>
            <Checkbox
              checked={this.state.checked}
              onChange={() => this.handleChangeCheckbox()}
              value={this.state.checked}
            >
              {"削除に同意する"}
            </Checkbox>
          </div>
        </Modal>
        <MediaQuery query="(min-device-width: 880px)">
          {(matches) =>
            matches ? (
              <div>
                <Modal
                  // style={{ backgroundColor: "#FFFDE7" }}
                  className="hoken1-input-form"
                  open={this.state.inputVisible}
                  // visible={true}
                  title={false}
                  closable={false}
                  maskClosable={false}
                  onCancel={() => this.closeInput()}
                  footer={null}
                  // footer={[
                  //         <Button key="input" type={"primary"}
                  //             onClick={() => this.handleInput()}>
                  //             OK
                  //         </Button>,
                  //     <Button key="cancelInput" onClick={() => this.closeInput()}>
                  //         キャンセル
                  //     </Button>
                  // ]}

                  width={1000}
                >
                  <HokenTableForm1
                    data={recordInput} // データ
                    wrappedComponentRef={(formRef) => this.saveFormRef(formRef)}
                    riyousyaId={riyousyaId}
                    syuruiCds={this.state.syuruiCds}
                    updateVisible={this.state.updateVisible}
                    serviceKikanMaster={serviceKikanMaster}
                    serviceSyurui={serviceSyurui}
                    kaigodoMaster={kaigodoMaster}
                    duplicationRiyousya={duplicationRiyousya}
                    // yougoMasterUpdate={this.yougoMasterUpdate}
                    jigyousyoName={this.state.jigyousyoName}
                    hokenName={this.state.hokenName}
                    nameStateOff={this.nameStateOff}
                    handleFormInput={this.getHandleFormInput}
                    getServiceKikanMasterList={this.serviceKikanSearch}
                    checkHokenNo={this.props.checkHokenNo}
                    getServiceKikanMaster={this.props.getJigyousyoName}
                    getKaigoHokenMaster={this.props.getHokenName}
                    update={this.update}
                    updateOk={this.handleInputModalOk}
                    updateCancel={this.updateCancel}
                    handleInput={this.handleInput}
                    closeInput={this.closeInput}
                  />
                </Modal>
              </div>
            ) : (
              <div>
                <Modal
                  style={{ marginTop: -45 }}
                  className="hoken1-input-form"
                  open={this.state.inputVisible}
                  // visible={true}
                  title={false}
                  closable={false}
                  maskClosable={false}
                  onCancel={() => this.closeInput()}
                  footer={null}
                  // footer={[
                  //     <Button key="input" type={"primary"}
                  //         onClick={() => this.handleInput()}>
                  //         OK
                  //     </Button>
                  //     ,
                  //     <Button key="cancelInput" onClick={() => this.closeInput()}>
                  //         キャンセル
                  //     </Button>
                  // ]}

                  width={1000}
                >
                  <HokenTableForm1
                    data={recordInput} // データ
                    wrappedComponentRef={(formRef) => this.saveFormRef(formRef)}
                    riyousyaId={riyousyaId}
                    syuruiCds={this.state.syuruiCds}
                    // updateVisible={this.state.updateVisible}
                    serviceKikanMaster={serviceKikanMaster}
                    serviceSyurui={serviceSyurui}
                    kaigodoMaster={kaigodoMaster}
                    duplicationRiyousya={duplicationRiyousya}
                    // yougoMasterUpdate={this.yougoMasterUpdate}
                    jigyousyoName={this.state.jigyousyoName}
                    hokenName={this.state.hokenName}
                    nameStateOff={this.nameStateOff}
                    handleFormInput={this.getHandleFormInput}
                    getServiceKikanMasterList={this.serviceKikanSearch}
                    checkHokenNo={this.props.checkHokenNo}
                    getServiceKikanMaster={this.props.getJigyousyoName}
                    getKaigoHokenMaster={this.props.getHokenName}
                    update={this.update}
                    updateOk={this.handleInputModalOk}
                    updateCancel={this.updateCancel}
                    handleInput={this.handleInput}
                    closeInput={this.closeInput}
                  />
                </Modal>
              </div>
            )
          }
        </MediaQuery>
        {/* 必須項目未入力警告(予備) */}
        {/* <Modal
                    // visible={this.state.updateVisible}
                    title={false}
                    closable={false}
                    onOk={() => this.updateOk()}
                    onCancel={() => this.updateCancel()}
                    // footer={null}
                >
                    <div style={{}}>
                        <div style={{}}>
                            <p>必須項目が未入力ですがそのまま登録しますか？</p>
                        </div>
                    </div>
                </Modal> */}
      </div>)
    );
  }
}
