import {RESET_STATUS, GET_ID_HEALTH_STATUS, UPDATE_ID_HEALTH_STATUS_SUCCESS, UPDATE_ID_HEALTH_STATUS_FAIL } from '../../actions/healthStatusActions/actionName';
import { notification } from "antd/lib/index";
export default function HealthStatusReducers(state = {
    loading: true
}, action) {
    switch (action.type) {
        case RESET_STATUS:
            return {
                ...state,
                notifyContentEdit: {}
            }
        case GET_ID_HEALTH_STATUS:
            return {
                ...state,
                data: action.data,
                loading: false
            };
        case UPDATE_ID_HEALTH_STATUS_SUCCESS:
            notification["success"]({
                message: '保存しました',
                placement: "topRight",
                duration: 3,
                style: { whiteSpace: "pre-wrap" },
            });
            return {
                ...state,
                data: action.data,
                loading: false,
                notifyContentEdit: { type: "success" }
            };
        case UPDATE_ID_HEALTH_STATUS_FAIL:
            notification["error"]({
                message: action.error.message,
                placement: "topRight",
                duration: 3,
                style: { whiteSpace: "pre-wrap" },
            });
            return {
                state,
                data: action.data,
                loading: false,
                notifyContentEdit: { type: "error" }
            };
        default:
            return state;
    }
}