import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Col, Row, Table } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { tantoMasterList2 } from "../../../actions/tantoMasterListActions/actionCreators";
import LineBreakText from "../../../common/component/LineBreakText";
import { ConstSet } from "../../../common/configs/constset";
import { sortByDate } from "../../../common/function_common/functionCommon";
dayjs.extend(dayjsPluginUTC);

class ViewMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMode: 2, // 1: monitoring screen, 2: evaluation screen
      currentMokuhyouIndex: 0,
    };
  }

  componentDidMount() {
    this.props.getTantoMasterList2();
  }

  switchMode = () => {
    this.setState((prevState) => ({
      currentMode: prevState.currentMode === 1 ? 2 : 1,
    }));
  };

  setCurrentMokuhyouIndex = (index) => {
    if (this.state.currentMokuhyouIndex === index) {
      return this.setState({ currentMokuhyouIndex: null });
    }
    this.setState({ currentMokuhyouIndex: index });
  };

  renderDesktopMonitoring(data) {
    const rows =
      Array.isArray(data.monitoringMokuhyous) &&
      data.monitoringMokuhyous.map((item, parentIndex) => (
        <tbody key={parentIndex} className="pljp-table-tbody">
          {Array.isArray(item.results) &&
            item.results.map((result, index) => {
              if (index === 0) {
                return (
                  <tr key={index}>
                    <td
                      className="table-cell-wordbreak"
                      rowSpan={item.results.length}
                    >
                      <LineBreakText text={item.shortMokuhyou} />
                    </td>
                    <td
                      className="table-cell-wordbreak"
                      rowSpan={item.results.length}
                    >
                      <LineBreakText text={item.attainment} />
                    </td>
                    <td className="table-cell-wordbreak">
                      {" "}
                      <LineBreakText text={result.naiyou} />
                    </td>
                    <td className="table-cell-wordbreak">
                      <LineBreakText text={result.service} />
                    </td>
                    <td className="table-cell-wordbreak">
                      {" "}
                      <LineBreakText text={result.condition} />
                    </td>
                    <td className="table-cell-wordbreak">
                      {" "}
                      <LineBreakText text={result.satisPerson} />
                    </td>
                    <td className="table-cell-wordbreak">
                      {" "}
                      <LineBreakText text={result.satisFamily} />
                    </td>
                    <td className="table-cell-wordbreak">
                      {" "}
                      <LineBreakText text={result.cope} />
                    </td>
                    <td
                      className="table-cell-wordbreak"
                      rowSpan={item.results.length}
                    >
                      <LineBreakText text={item.evaluate} />
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={index}>
                  <td className="table-cell-wordbreak">{result.naiyou}</td>
                  <td className="table-cell-wordbreak">{result.service}</td>
                  <td className="table-cell-wordbreak">{result.condition}</td>
                  <td className="table-cell-wordbreak">{result.satisPerson}</td>
                  <td className="table-cell-wordbreak">{result.satisFamily}</td>
                  <td className="table-cell-wordbreak">{result.cope}</td>
                </tr>
              );
            })}
        </tbody>
      ));

    return (
      <div type="flex" className="view-monitoring-table">
        <div className="pljp-table pljp-table-bordered pljp-table-small pljp-table-scroll-position-left">
          <div>
            <div className="pljp-table-content">
              <div className="pljp-table-body">
                <table style={{ tableLayout: "auto" }}>
                  <colgroup>
                    <col style={{ width: "13%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "14%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "13%" }} />
                  </colgroup>
                  <thead className="pljp-table-thead">
                    <tr>
                      <th>
                        <span className="pljp-table-header-column">
                          <div>
                            <span className="pljp-table-column-title">
                              短期目標
                            </span>
                          </div>
                        </span>
                      </th>
                      <th>
                        <span className="pljp-table-header-column">
                          <div>
                            <span className="pljp-table-column-title">
                              目標の達成状況
                            </span>
                          </div>
                        </span>
                      </th>
                      <th>
                        <span className="pljp-table-header-column">
                          <div>
                            <span className="pljp-table-column-title">
                              サービス内容
                            </span>
                          </div>
                        </span>
                      </th>
                      <th>
                        <span className="pljp-table-header-column">
                          <div>
                            <span className="pljp-table-column-title">
                              担当者
                            </span>
                          </div>
                        </span>
                      </th>
                      <th>
                        <span className="pljp-table-header-column">
                          <div>
                            <span className="pljp-table-column-title">
                              サービスの実施状況
                            </span>
                          </div>
                        </span>
                      </th>
                      <th>
                        <span className="pljp-table-header-column">
                          <div>
                            <span className="pljp-table-column-title">
                              本人満足度
                            </span>
                          </div>
                        </span>
                      </th>
                      <th>
                        <span className="pljp-table-header-column">
                          <div>
                            <span className="pljp-table-column-title">
                              家族満足度
                            </span>
                          </div>
                        </span>
                      </th>
                      <th>
                        <span className="pljp-table-header-column">
                          <div>
                            <span className="pljp-table-column-title">
                              今後の対応
                            </span>
                          </div>
                        </span>
                      </th>
                      <th>
                        <span className="pljp-table-header-column">
                          <div>
                            <span className="pljp-table-column-title">
                              評価、対応、課題等
                            </span>
                          </div>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  {rows}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDesktopEvaluate(data) {
    const sortedMonitoringHyokas =
      data.monitoringHyokas && Array.isArray(data.monitoringHyokas)
        ? sortByDate({ list: data.monitoringHyokas, key: "hyokaDate" })
        : null;

    return (
      <div type="flex" className="view-monitoring-table">
        <div className="pljp-table-wrapper view-plan-table3">
          <div className="pljp-spin-nested-loading">
            <div className="pljp-spin-container">
              <div className="pljp-table pljp-table-small pljp-table-scroll-position-left">
                <div className="pljp-table-container">
                  <div className="pljp-table-content">
                    <table style={{ tableLayout: "auto" }}>
                      <colgroup>
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "70%" }} />
                      </colgroup>
                      <thead className="pljp-table-thead">
                        <tr>
                          <th className="pljp-table-cell">評価日</th>
                          <th className="pljp-table-cell">評価担当者</th>
                          <th className="pljp-table-cell">評価内容</th>
                        </tr>
                      </thead>
                      <tbody className="pljp-table-tbody">
                        {Array.isArray(sortedMonitoringHyokas) &&
                          sortedMonitoringHyokas.map((item, index) => (
                            <tr key={index}>
                              <td>{item.hyokaDate}</td>
                              <td className="table-cell-wordbreak">
                                {this.getEvaluateTantoName(
                                  this.props.tantoMasterList,
                                  item.hyokaTantoId
                                )}
                              </td>
                              <td className="table-cell-wordbreak">
                                <LineBreakText text={item.hyoka} />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDesktopContent(data) {
    if (!data) return null;

    const { currentMode } = this.state;
    const buttonName = currentMode === 1 ? "個別評価へ" : "モニタリングへ";
    const columnTable3 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "20%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "20%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title3",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content3",
        width: "15%",
      },
    ];
    const columnTable4 = [
      {
        className: "title-view1",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "content",
        width: "85%",
        className: "content-tb1",
        render: (text) => <LineBreakText text={text} />,
      },
    ];
    const dataTable3 = [
      {
        title: "日付",
        content: data.monitoringDate
          ? dayjs
            .utc(data.monitoringDate)
            .format("YYYY-MM-DD")
          : "",
        title2: "ケアプラン作成日",
        content2: data.carePlanDate
          ? dayjs
            .utc(data.carePlanDate)
            .format("YYYY-MM-DD")
          : "",
        title3: "計画作成者",
        content3: data.tantoMasterName || "",
      },
    ];
    const dataTable4 = [
      {
        title: "総評",
        content: data.comment || "",
      },
    ];

    return (
      <div className="view-monitoring-container">
        {/* View header */}
        <Row className="view-monitoring-header">
          <Col span={24} className="view-monitoring-header__right">
            <Button onClick={this.switchMode}>{buttonName}</Button>
          </Col>
        </Row>

        {/* Dates */}
        <Table
          bordered={false}
          className={"view-plan-table3"}
          pagination={false}
          showHeader={false}
          columns={columnTable3}
          dataSource={dataTable3}
        />

        {currentMode === 1
          ? this.renderDesktopMonitoring(data)
          : this.renderDesktopEvaluate(data)}

        {/* View footer */}
        <Table
          bordered={false}
          style={{ margin: "20px 0px" }}
          className={"view-plan-table4"}
          pagination={false}
          showHeader={false}
          columns={columnTable4}
          dataSource={dataTable4}
        />
      </div>
    );
  }

  renderMobileContent(data) {
    // keep container min height
    if (!data) return <div className="view-monitoring-container-m"></div>;

    const { currentMode } = this.state;
    const buttonName = currentMode === 1 ? "個別評価へ" : "モニタリングへ";
    const sortedMonitoringHyokas =
      data.monitoringHyokas && Array.isArray(data.monitoringHyokas)
        ? sortByDate({ list: data.monitoringHyokas, key: "hyokaDate" })
        : null;

    return (
      <div className="view-monitoring-container-m">
        {/* Header */}
        <div className="view-monitoring-header-m">
          <Row>
            <Col span={10}>日付</Col>
            <Col span={14}>
              <span>
                {data.monitoringDate
                  ? dayjs
                    .utc(data.monitoringDate, ConstSet.DATE_FORMAT)
                    .format("YYYY-MM-DD")
                  : ""}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>ケアプラン作成日</Col>
            <Col span={14}>
              <span>
                {data.carePlanDate
                  ? dayjs
                    .utc(data.carePlanDate, ConstSet.DATE_FORMAT)
                    .format("YYYY-MM-DD")
                  : ""}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>計画作成者</Col>
            <Col span={14}>
              <span>{data.tantoMasterName || ""}</span>
            </Col>
          </Row>
        </div>

        {/* Table */}
        <div className="view-monitoring-table-m">
          <Row className="view-monitoring-switch">
            <Button type="primary" onClick={this.switchMode}>
              {buttonName}
            </Button>
          </Row>
          <div>
            {this.state.currentMode === 1
              ? Array.isArray(data.monitoringMokuhyous) &&
              data.monitoringMokuhyous.map((item, parentIndex) => (
                <Row
                  key={parentIndex}
                  className={`
                      view-monitoring-mokuhyou
                      ${this.state.currentMokuhyouIndex === parentIndex ? "view-monitoring-mokuhyou--show" : ""}`}
                >
                  <div
                    className="view-monitoring-mokuhyou__header"
                    role="button"
                    onClick={() => this.setCurrentMokuhyouIndex(parentIndex)}
                  >
                    <span>短期目標 {parentIndex + 1}</span>
                    <Button
                      type="link"
                      icon={<LegacyIcon
                        type={
                          this.state.currentMokuhyouIndex === parentIndex
                            ? "caret-up"
                            : "caret-down"
                        } />}
                    ></Button>
                  </div>
                  <div className="view-monitoring-mokuhyou__content">
                    <Row>
                      <span className="view-monitoring-mokuhyou__title">
                        短期目標
                      </span>
                      <LineBreakText text={item.shortMokuhyou} />
                    </Row>
                    <Row>
                      <span className="view-monitoring-mokuhyou__title">
                        目標の達成状況
                      </span>
                      <span>{item.attainment}</span>
                    </Row>
                    <Row>
                      <div className="view-monitoring-result">
                        {Array.isArray(item.results) &&
                          item.results.map((result, index) => (
                            <div
                              key={index}
                              className="view-monitoring-result-item"
                            >
                              <div className="view-monitoring-result-item-left">
                                {index + 1}
                              </div>
                              <div className="view-monitoring-result-item-right">
                                <Row>
                                  <span className="view-monitoring-mokuhyou__title">
                                    サービス内容
                                  </span>
                                  <LineBreakText text={result.naiyou} />
                                </Row>
                                <Row>
                                  <span className="view-monitoring-mokuhyou__title">
                                    担当者
                                  </span>
                                  <LineBreakText text={result.service} />
                                </Row>
                                <Row>
                                  <span className="view-monitoring-mokuhyou__title">
                                    サービスの実施状況
                                  </span>
                                  <LineBreakText text={result.condition} />
                                </Row>
                                <Row>
                                  <span className="view-monitoring-mokuhyou__title">
                                    本人満足度
                                  </span>
                                  <LineBreakText text={result.satisPerson} />
                                </Row>
                                <Row>
                                  <span className="view-monitoring-mokuhyou__title">
                                    家族満足度
                                  </span>
                                  <LineBreakText text={result.satisFamily} />
                                </Row>
                                <Row>
                                  <span className="view-monitoring-mokuhyou__title">
                                    今後の対応
                                  </span>
                                  <LineBreakText text={result.cope} />
                                </Row>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Row>
                    <Row>
                      <span className="view-monitoring-mokuhyou__title">
                        評価、対応、課題等
                      </span>
                      <LineBreakText text={item.evaluate} />
                    </Row>
                  </div>
                </Row>
              ))
              : Array.isArray(sortedMonitoringHyokas) &&
              sortedMonitoringHyokas.map((item, index) => (
                <div key={index} className="view-monitoring-hyoka">
                  <div className="view-monitoring-hyoka__header">
                    <span>評価内容 {index + 1}</span>
                  </div>
                  <Row>
                    <span className="view-monitoring-mokuhyou__title">
                      評価日
                    </span>
                    <span>{item.hyokaDate}</span>
                  </Row>
                  <Row>
                    <span className="view-monitoring-mokuhyou__title">
                      評価担当者
                    </span>
                    <span>
                      {this.getEvaluateTantoName(
                        this.props.tantoMasterList,
                        item.hyokaTantoId
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="view-monitoring-mokuhyou__title">
                      評価内容
                    </span>
                    <LineBreakText text={item.hyoka} />
                  </Row>
                </div>
              ))}
          </div>
        </div>

        {/* Comment */}
        <Row className="view-monitoring-comment">
          <Col span={24}>
            <p className="text-bold">総評</p>
            {/* <p>{data.comment}</p> */}
            <LineBreakText text={data.comment} />
          </Col>
        </Row>
      </div>
    );
  }

  getEvaluateTantoName(tantoMasterList, tantoId) {
    if (!tantoMasterList || !tantoMasterList.tantoMasters2) return "";

    for (const item of tantoMasterList.tantoMasters2) {
      if (item.id === tantoId) {
        return item.name;
      }
    }
    return "";
  }

  render() {
    const { data } = this.props;

    return (
      <MediaQuery query="(min-device-width: 768px)">
        {(matches) =>
          matches
            ? this.renderDesktopContent(data)
            : this.renderMobileContent(data)
        }
      </MediaQuery>
    );
  }
}

ViewMonitoring.propTypes = {
  data: PropTypes.object,
};

const mapStateToProps = () => (state) => ({
  tantoMasterList: state.tantoMasterList,
});

const mapDispatchToProps = () => (dispatch) => ({
  getTantoMasterList2: () => dispatch(tantoMasterList2()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMonitoring);
