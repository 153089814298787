import React, { useEffect, useState } from "react";
import { Table } from "antd";
import HeaderMissInput from "./HeaderMessInput";
import { StorageUtils, history } from "../../../../utils";
import { STATE_USAGE_TICKET_SAVE_TO_LOCAL } from "../../constants";

function CheckMissInput() {
  const [dataSource, setDataSource] = useState([]); 
    useEffect(() => {
      const usageTicketManagementState = StorageUtils.getValue(STATE_USAGE_TICKET_SAVE_TO_LOCAL) || {};
      if(usageTicketManagementState) {
        const {listBetuhyou} = usageTicketManagementState;
        const filteredList = listBetuhyou.filter(item => {
          return item.checkMess.some(mess => mess.trim() !== '');
        });
        filteredList.sort((a, b) => a.id - b.id);
        filteredList.forEach(item => {
          let date = item.nengetu;
          date = date.substring(0, 4) + '-' + date.substring(4, 6);
          item.checkMess = item.checkMess.join(', ');
          item.nengetu = date
        });
        setDataSource(filteredList)
      }
    },[])

  return <div className="container-check-mess">
    <HeaderMissInput />
    <div className="section-check-mess">
      <h1 className="check-mess-title">利用票エラーチェック</h1>
      <div className="attached-table table-check-mess">
        <table className="custom-table custom-table-check-mess">
          <tr>
            <th>利用票ID</th>
            <th>利用者名</th>
            <th>作成年月</th>
            <th>エラー内容</th>
          </tr>
          {dataSource?.map((item, index) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.riyousya.name}</td>
              <td>{item.nengetu}</td>
              <td>{item.checkMess}</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  </div>;
}

export default CheckMissInput;
