import { Form } from "@ant-design/compatible";
import { Button, Input, Modal } from "antd";
import React, { Component } from "react";
import PaintApp from "../../../common/paint/PaintApp";
import { PaintView } from "../../../common/paint/PaintView";


class FormChartNomal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      visible: false,
      visible2: false,
      changePaint: "",
      printOK: false,
      paintvalue: props.data ? props.data.swpict : null,

    };
    this.openPaint = this.openPaint.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      if (this.painview) {
        this.painview.zoomPainview(0.375);
      }
    }, 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      if (
        nextProps.data.swpict &&
        this.props.data.swpict &&
        nextProps.data.swpict !== this.props.data.swpict
      ) {
        // setTimeout(() => {
        //     if (this.painview) {
        //         this.painview.zoomPainview(0.375);
        //     }
        // }, 1000);
      }
      else {
        // setTimeout(() => {
        //   if (this.painview) {
        //     this.painview.zoomPainview(1);
        //   }
        // }, 1000);
      }
    }
  }
  openPaint() {
    if (
      this.state.paintvalue === undefined ||
      this.state.paintvalue === null
    ) {
      this.setState({
        visible: true,
        paintvalue: this.props.data ? this.props.data.swpict : null,
        printOK: false,
      });
    }
    else {
      this.setState({
        visible: true,
        printOK: false,
      });
    }
  }
  hideDialog() {
    this.setState({
      visible: false,
      visible2: false,
    });
  }

  handleOk = async () => {
    const { form } = this.props;
    const newvalues = await this.child.GetValuesJson();
    if (newvalues === this.state.paintvalue) {
      this.setState({
        visible: false,
      });
      return;
    }
    form.setFields({
      swpict: {
        value: newvalues,
      },
    });

    const newChart = form.getFieldsValue();
    this.props.saveOnchangeToState(newChart, "fChart", "");

    this.setState({
      visible: false,
      paintvalue: newvalues,
    });
    setTimeout(() => {
      this.painview.zoomPainview(0.375);
    }, 1000);
  };
  handleCancel = () => {
    const newvalues = this.child.GetValuesJson();
    if (newvalues === this.state.paintvalue) {
      this.setState({
        visible: false,
      });
      return;
    }
    this.setState({
      visible2: true,
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      (
        <>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark={true}
            labelAlign="left"
          >
            <Button
              style={{ margin: 10, float: "right" }}
              onClick={this.openPaint}
              type="primary"
            >
              書く
            </Button>
            {getFieldDecorator("swpict")(
              <PaintView onRef={(ref) => (this.painview = ref)} />
            )}
            <Modal
              title="家族構成図"
              open={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              style={{
                maxWidth: 980,
                top: "4rem",
              }}
              width={980}
              closable={false}
              maskClosable={false}
              destroyOnClose={true}
              bodyStyle={{ padding: 0 }}
            >
              <div>
                <PaintApp
                  screen="family"
                  value={this.state.paintvalue}
                  onRef={(ref) => (this.child = ref)}
                ></PaintApp>
                <Modal
                  open={this.state.visible2}
                  title="キャンセル確認"
                  content="キャンセルしてもよろしいですか？"
                  onOk={() => this.hideDialog()}
                  onCancel={() => this.setState({ visible2: false })}
                >
                  <div>キャンセルしてもよろしいですか？</div>
                </Modal>
              </div>
            </Modal>
            <div style={{ display: "none" }}>
              {getFieldDecorator("id")(<Input />)}
              {getFieldDecorator("facesheetId")(<Input />)}
              {getFieldDecorator("riyousyaId")(<Input />)}
            </div>
          </Form>

        </>
      )
    );
  }
}

export const FormChart = Form.create({
  name: "input-riyou-chart",
  onValuesChange(props, changedValues, allValues) {
    props.saveOnchangeToState(allValues, "fChart", props.index);
  },
  mapPropsToFields(props) {
    return {
      id: Form.createFormField({
        value: props.data ? props.data.id : null,
      }),
      facesheetId: Form.createFormField({
        value: props.data ? props.data.facesheetId : null,
      }),
      riyousyaId: Form.createFormField({
        value: props.data ? props.data.riyousyaId : null,
      }),
      swpict: Form.createFormField({
        value: props.data ? props.data.swpict : null,
      }),
    };
  },
})(FormChartNomal);
