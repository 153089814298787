import {
  MonitoringActionTypes,
  MonitoringMNGTActionType,
} from "../action_types";
import { CommonUtils } from "../../utils/index";

function monitoringMNGT(state = {}, action) {
  switch (action.type) {
    // get all monitoring by user id
    case MonitoringActionTypes.GET_ALL_MONITORING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case MonitoringActionTypes.GET_ALL_MONITORING_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case MonitoringActionTypes.GET_ALL_MONITORING_FAILURE: {
      return {
        ...state,
        data: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }

    case MonitoringActionTypes.GET_MONITORING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getOne",
      };
    }
    case MonitoringActionTypes.GET_MONITORING_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case MonitoringActionTypes.GET_MONITORING_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // create monitoring
    case MonitoringActionTypes.CREATE_MONITORING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case MonitoringActionTypes.CREATE_MONITORING_SUCCESS:
    case MonitoringActionTypes.CREATE_MONITORING_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // update monitoring
    case MonitoringActionTypes.UPDATE_MONITORING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case MonitoringActionTypes.UPDATE_MONITORING_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case MonitoringActionTypes.UPDATE_MONITORING_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // remove monitoring
    case MonitoringActionTypes.REMOVE_MONITORING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case MonitoringActionTypes.REMOVE_MONITORING_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case MonitoringActionTypes.REMOVE_MONITORING_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    case MonitoringActionTypes.GET_ALL_MONITORING_QUOTE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case MonitoringActionTypes.GET_ALL_MONITORING_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuote: action.data,
      };
    case MonitoringActionTypes.GET_ALL_MONITORING_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listQuote: [],
      };
    case MonitoringMNGTActionType.GET_ALL_REQUEST: {
      return {
        ...state,
        reload: false,
      };
    }
    default: {
      return { ...state };
    }
  }
}

export default monitoringMNGT;
