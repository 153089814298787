import { Modal } from "antd";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import {
  changeValuePlan2,
  updateSingleKikanKyotakuPlan2,
} from "../../../../actions/kyotakuPlan2Actions/actionCreators";
import {
  checkCalendarIntoGeneral,
  checkCalendarIntoJap,
} from "../common/convertDate";
import { openNotificationWithIcon } from "../function/alert";
import jaconv from "../lib/jaconv.min";
import { formatDuration, isValidDate } from "./common";
import TimeComponent from "./timeDetails";

const listTime = [
  "1週間",
  "2週間",
  "1ヵ月",
  "3ヵ月",
  "6ヵ月",
  "8ヵ月",
  "9ヵ月",
  "10ヵ月",
  "11ヵ月",
  "12ヵ月",
  "1年",
  "1年半",
  "2年",
];
class SingleTime extends React.Component {
  constructor(props) {
    super(props);
    const values =
      (props.period ? props.period + "\n" : "") +
      (isValidDate(props.startDate) ? props.startDate + " ～\n" : "") +
      (isValidDate(props.endDate) ? props.endDate : "");
    this.state = {
      period: null,
      startDate: null,
      endDate: null,
      timeKikan: "",
      values,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { settingData } = this.props;
    if (nextProps.timeModal !== this.props.timeModal) {
      const valuesDefault =
        (nextProps.period ? nextProps.period + "\n" : "") +
        (isValidDate(nextProps.startDate)
          ? checkCalendarIntoJap(
            dayjs(nextProps.startDate).format("YYYY-MM-DD"),
            settingData
          ) + " ～\n"
          : "") +
        (isValidDate(nextProps.endDate)
          ? checkCalendarIntoJap(
            dayjs(nextProps.endDate).format("YYYY-MM-DD"),
            settingData
          )
          : "");
      this.setState({
        startDate: nextProps.startDate,
        startDateIp: dayjs(nextProps.startDate).format("YYYY-MM-DD"),
        endDate: nextProps.endDate,
        period: nextProps.period,
        values:
          nextProps.kikanType === "kikan"
            ? nextProps.listContent[nextProps.indexDetail][nextProps.kikanType] || valuesDefault
            : nextProps.recordInfo[nextProps.kikanType] || valuesDefault,
      });
    }
  }

  isValidDate = (date) => {
    const timestamp = Date.parse(date);
    return !isNaN(timestamp);
  };

  onChangeSetPeriod = (value) => {
    const startDateType = this.state.startDate;
    const endDateType = this.state.endDate;
    const startDateMoment = this.state.startDate;
    let endDateClone = null;
    const { settingData } = this.props;
    if (startDateType && formatDuration(value)) {
      const num = formatDuration(value).number;
      const format = formatDuration(value).format;
      if (format && format === "days") {
        endDateClone = dayjs(startDateMoment)
          .subtract(-num, format)
          .format("YYYY-MM-DD");
      }
      else {
        endDateClone = dayjs(startDateMoment)
          .subtract(-num, format)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }
      this.setState({
        period: value,
        endDate: endDateClone,
      });
    }
    else {
      endDateClone = endDateType;
    }
    this.setState({
      period: value,
      values:
        (value ? value + "\n" : "") +
        (isValidDate(startDateType)
          ? checkCalendarIntoJap(startDateType, settingData) + " ～\n"
          : "") +
        (isValidDate(endDateClone)
          ? checkCalendarIntoJap(endDateClone, settingData)
          : ""),
    });
  };

  onChangeDateStart = (date) => {
    const periodType = this.state.period;
    const endDateType = this.state.endDate;
    let endDateClone = null;
    const { settingData } = this.props;
    if (formatDuration(periodType)) {
      const num = formatDuration(periodType).number;
      const format = formatDuration(periodType).format;
      if (format && format === "days") {
        endDateClone = dayjs(date).subtract(-num, format).format("YYYY-MM-DD");
      }
      else {
        endDateClone = dayjs(date)
          .subtract(-num, format)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }

      this.setState({
        endDate: endDateClone,
      });
    }
    else {
      endDateClone = endDateType;
    }
    const values =
      (periodType ? periodType + "\n" : "") +
      (isValidDate(date)
        ? checkCalendarIntoJap(dayjs(date).format("YYYY-MM-DD"), settingData) +
        " ～\n"
        : "") +
      (isValidDate(endDateClone)
        ? checkCalendarIntoJap(endDateClone, settingData)
        : "");
    this.setState({
      startDate: dayjs(date).format("YYYY-MM-DD"),
      startDateIp: date,
      values,
    });
  };

  onChangeDateEnd = (date) => {
    const periodType = this.state.period;
    const startDateType = this.state.startDate;
    const { settingData } = this.props;
    const values =
      (periodType ? periodType + "\n" : "") +
      (isValidDate(startDateType)
        ? checkCalendarIntoJap(startDateType, settingData) + " ～\n"
        : "") +
      (isValidDate(date)
        ? checkCalendarIntoJap(dayjs(date).format("YYYY-MM-DD"), settingData)
        : "");
    this.setState({
      endDate: date.format("YYYY-MM-DD"),
      values,
    });
  };

  handleSetTimeOk = () => {
    if (!this.props.valuesChange) {
      this.props.changeValuePlan2(true);
    }
    // const { period } = this.state;
    // const startDate = this.state.startDate;
    // const endDate = this.state.endDate;
    const { kikanType, indexDetail } = this.props;
    // const values =
    //   (period ? period + "\n" : "") +
    //   (isValidDate(startDate) ? startDate + " ～\n" : "") +
    //   (isValidDate(endDate) ? endDate : "");

    this.props.handleSetTimeOk();
    this.props.updateSingleKikanKyotakuPlan2(
      this.state.values || null,
      kikanType,
      indexDetail
    );
    this.setState({
      startDate: null,
      endDate: null,
      period: null,
    });
  };

  handleSetTimeCancel = () => {
    this.props.handleSetTimeCancel();
  };

  clearStartDate = () => {
    const periodType = this.state.period;
    const endDateType = this.state.endDate;
    const values =
      (periodType ? periodType + "\n" : "") +
      "" +
      (isValidDate(endDateType) ? endDateType : "");
    this.setState({
      startDate: null,
      values,
    });
  };

  clearEndDate = () => {
    const periodType = this.state.period;
    const startDateType = this.state.startDate;
    const values =
      (periodType ? periodType + "\n" : "") +
      (isValidDate(startDateType) ? startDateType + " ～\n" : "") +
      "";
    this.setState({
      endDate: null,
      values,
    });
  };

  onChangeTextArea(value, type) {
    const { settingData } = this.props;
    const valueJac = jaconv["normalize"](value).split("ー").join("-");
    if (type === "longKikan" || type === "shortKikan") {
      if (value.length > 40) {
        if (type === "longKikan") {
          openNotificationWithIcon("error", "長期期間が40文字を超えています!");
          return;
        }
        if (type === "shortKikan") {
          openNotificationWithIcon("error", "短期期間が40文字を超えています!");
          return;
        }
      }
    }

    if (!value || (value !== null && value.trim().length == 0)) {
      this.setState({
        startDate: null,
        endDate: null,
        period: null,
      });
    }
    else {
      const valueClone1 = valueJac
        .split("、")[0]
        .replace(/\n/g, " ")
        .replace(/~/g, "～")
        .split(" ")
        .filter((val) => val);
      const valueClone = valueClone1.filter((val) => val !== "～");
      let i = -1;

      if (listTime.indexOf(`${valueClone[0]}`) !== -1) {
        i = i + 1;
      }

      this.setState({
        period: valueClone[i],
      });
      if (valueClone[i + 1]) {
        this.setState({
          startDate: checkCalendarIntoGeneral(valueClone[i + 1], settingData),
        });
      }
      else {
        this.setState({
          startDate: null,
        });
      }
      if (valueClone[i + 2]) {
        this.setState({
          endDate: checkCalendarIntoGeneral(valueClone[i + 2], settingData),
        });
      }
      else {
        this.setState({
          endDate: null,
        });
      }
    }
    this.setState({
      values: value,
    });
  }

  render() {
    const { modalSetTimeVisible, kikanType } = this.props;
    const { period, startDate, endDate, values } = this.state;
    return (
      (<div>
        <Modal
          destroyOnClose={true}
          open={modalSetTimeVisible}
          onOk={this.handleSetTimeOk}
          onCancel={this.handleSetTimeCancel}
          title="期間入力ダイアログ"
        >
          <div>
            <TimeComponent
              valueString={values}
              timeKikan={"期間 出来上がり"}
              onChangeSetPeriod={(value, type) =>
                this.onChangeSetPeriod(value, type)
              }
              kikanType={kikanType}
              typeTime={kikanType}
              period={period}
              onChangeDateStart={(date, dateString, value) =>
                this.onChangeDateStart(date, dateString, value)
              }
              onChangeDateEnd={(date, dateString, value) =>
                this.onChangeDateEnd(date, dateString, value)
              }
              startDate={startDate}
              endDate={endDate}
              singleModalTitle={true}
              clearStartDate={() => this.clearStartDate()}
              clearEndDate={() => this.clearEndDate()}
              onChangeTextArea={(value, type) =>
                this.onChangeTextArea(value, type)
              }
            />
          </div>
        </Modal>
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kyotakuPlan2: state.kyotakuPlan2.kyotakuPlan2,
    kyotakuPlan2Rows: state.kyotakuPlan2.kyotakuPlan2Rows,
    notifyDisplay: state.kyotakuPlan2.notifyDisplay,
    notifyContent: state.kyotakuPlan2.notifyContent,
    valuesChange: state.kyotakuPlan2.valuesChange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSingleKikanKyotakuPlan2: (values, kikanType, indexDetail) =>
      dispatch(updateSingleKikanKyotakuPlan2(values, kikanType, indexDetail)),
    changeValuePlan2: (type) => dispatch(changeValuePlan2(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleTime);
