import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Input, Modal, Tooltip } from "antd";
import _ from "lodash";
import "moment/locale/ja";
import React from "react";
import MediaQuery from "react-responsive";
import SearchConditions from "../../../common/component/SearchConditions";
import filterForm from "../../../data/jsonLocal";
class InputDisplay extends React.Component {
  render() {
    return (
      <Tooltip placement="topLeft" title={this.props.dataFilter}>
        <Input
          style={{ width: this.props.width }}
          readOnly
          value={this.props.dataFilter}
        />
      </Tooltip>
    );
  }
}
export class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleModalSearch: false,
      filterData: filterForm,
    };
    // bind place
    this.getHandleState = this.getHandleState.bind(this);
    this.handleModalSearchOk = this.handleModalSearchOk.bind(this);
    this.handleModalSearchCancel = this.handleModalSearchCancel.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      !_.isEmpty(this.props.filterDataBackup) &&
      JSON.stringify(newProps.filterDataBackup) !==
      JSON.stringify(this.state.filterData)
    ) {
      this.setState({ filterData: newProps.filterDataBackup });
    }
  }

  showModalSearchConditions = () => {
    this.setState({
      visibleModalSearch: true,
    });
  };

  handleModalSearchOk = () => {
    this.props.handleFilterOk(this.state.filterData);
    this.setState({
      visibleModalSearch: false,
    });
  };

  handleModalSearchCancel = () => {
    this.setState({
      visibleModalSearch: false,
      filterData: _.isEmpty(this.props.filterDataBackup)
        ? filterForm
        : this.props.filterDataBackup,
    });
  };

  /**
   * Update state between component
   */
  getHandleState = (filterData) => {
    if (!filterData.search.checkbox1) {
      filterData.search.field10 = null;
      filterData.search.field11 = null;
    }
    if (!filterData.search.checkbox2) {
      filterData.search.field2 = null;
    }
    if (!filterData.search.checkbox3) {
      filterData.search.field3 = null;
    }
    this.setState({
      filterData,
    });
  };

  render() {
    const filterData = this.state.filterData;
    const { nyutaishoFilterData } = this.props;
    let settingNaiyo1 = null;
    let yougoMaster11 = null;
    let yougoMaster12 = null;
    let yougoMaster13 = null;
    let yougoMaster14 = null;
    let yougoMaster15 = null;
    let yougoMaster21 = null;
    if (nyutaishoFilterData) {
      settingNaiyo1 = nyutaishoFilterData.settingNaiyo1
        ? nyutaishoFilterData.settingNaiyo1.split(",")
        : "";
      yougoMaster11 = nyutaishoFilterData.yougoMaster11
        ? nyutaishoFilterData.yougoMaster11.split(";")
        : "";
      yougoMaster12 = nyutaishoFilterData.yougoMaster12
        ? nyutaishoFilterData.yougoMaster12.split(";")
        : "";
      yougoMaster13 = nyutaishoFilterData.yougoMaster13
        ? nyutaishoFilterData.yougoMaster13.split(";")
        : "";
      yougoMaster14 = nyutaishoFilterData.yougoMaster14
        ? nyutaishoFilterData.yougoMaster14.split(";")
        : "";
      yougoMaster15 = nyutaishoFilterData.yougoMaster15
        ? nyutaishoFilterData.yougoMaster15.split(";")
        : "";
      yougoMaster21 = nyutaishoFilterData.yougoMaster21
        ? nyutaishoFilterData.yougoMaster21.split(";")
        : "";
    }
    const dataFilter =
      (filterData.search.field11 && filterData.search.field10
        ? filterData.search.field10.split("=")[1] +
        "=" +
        filterData.search.field11 +
        " / "
        : "") +
      (filterData.search.field2
        ? "絞込区分: " + filterData.search.field2 + " / "
        : "") +
      (filterData.search.field3 ? "部屋分類:" + filterData.search.field3 : "");

    return (
      (<div
        style={{ display: "inline-block", paddingLeft: "10px" }}
        className={"filter"}
      >
        <div style={{ margin: "10px 0px", display: "flex" }}>
          <span className={"filterInOut"}>{"絞り込み条件"}</span>

          <MediaQuery query="(min-width: 0px)">
            <MediaQuery query="(max-width: 1379px)">
              <InputDisplay dataFilter={dataFilter} width={"300px"} />
            </MediaQuery>
          </MediaQuery>
          <MediaQuery query="(min-width: 1380px)">
            <MediaQuery query="(max-width: 1599px)">
              <InputDisplay dataFilter={dataFilter} width={"400px"} />
            </MediaQuery>
          </MediaQuery>
          <MediaQuery query="(min-width: 1600px)">
            <MediaQuery query="(max-width: 1899px)">
              <InputDisplay dataFilter={dataFilter} width={"600px"} />
            </MediaQuery>
          </MediaQuery>
          <MediaQuery query="(min-width: 1900px)">
            <InputDisplay dataFilter={dataFilter} width={"865px"} />
          </MediaQuery>

          <Button
            type="primary"
            style={{ padding: "0px 5px", marginLeft: "2px" }}
            onClick={this.showModalSearchConditions}
            icon={<LegacyIcon type={"setting"} />}
          >
            {"条件"}
          </Button>
        </div>
        <Modal
          title="絞り込み検索"
          open={this.state.visibleModalSearch}
          okText={"検索"}
          cancelText={"閉じる"}
          onOk={this.handleModalSearchOk}
          onCancel={this.handleModalSearchCancel}
          className={"filter-model"}
          destroyOnClose={true}
        >
          <SearchConditions
            settingNaiyo1={settingNaiyo1}
            yougoMaster11={yougoMaster11}
            yougoMaster12={yougoMaster12}
            yougoMaster13={yougoMaster13}
            yougoMaster14={yougoMaster14}
            yougoMaster15={yougoMaster15}
            yougoMaster21={yougoMaster21}
            handleUpdateState={(data) => this.getHandleState(data)}
            filterData={filterData}
          />
        </Modal>
      </div>)
    );
  }
}
