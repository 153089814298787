import React, { Component } from "react";
import { Select } from "antd";

const { Option } = Select;

export class ModeCombo extends Component {
  render() {
    return (
      <div style={this.props.style}>
        <Select
          style={{ width: "133px" }}
          size={"small"}
          value={this.props.mode}
          onChange={(value) => this.props.handleChangeMode(value)}
        >
          <Option value="event">{"施設行事予定"}</Option>
          <Option value="individual">{"利用者個別予定"}</Option>
        </Select>
      </div>
    );
  }
}
