import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { ErrorActionTypes, RihabiriActionTypes } from "../action_types";

const INITIAL_STATE = {
  rehabiliAssessmentData: {},
  loading: false,
  rehabiliPlanSub: null,
};

function rihabiri(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RihabiriActionTypes.REHABILI_LOADING_MODE: {
      return {
        ...state,
        loading: true,
        rehabiliPlanSub: null,
      }
    }

    case RihabiriActionTypes.LOAD_REHABILI_TO_QUOTE_SUCCESS: {
      return {
        ...state,
        rehabiliDetails: action.data,
      }
    }

    case RihabiriActionTypes.GET_SIENNAIYO_MASTER_SUCESS_FORDIS: {
      return {
        ...state,
        sienaiyouForDis: action.data,
      };
    }
    case RihabiriActionTypes.LOAD_NEEDS_ITEM_SUCCESS_FORDIS: {
      return {
        ...state,
        needItemForDis: action.data,
      };
    }
    case RihabiriActionTypes.GET_ICF_MASTER_SUCESS_FORDIS: {
      return {
        ...state,
        icfMasterForDis: action.data,
      };
    }
    case RihabiriActionTypes.GET_RIHABIRI_SAISHIN_RECORD: {
      return {
        ...state,
        saishin: action.data,
        loading: false
      };
    }
    case RihabiriActionTypes.GET_COMMON_MEDISYOUBYOUMEI_MASTER1: {
      return {
        ...state,
        mediSyoubyoumeiMaster: action.data,
      };
    }
    case RihabiriActionTypes.GET_COMMON_RIYOUBYOUREKI_MASTER1: {
      return {
        ...state,
        riyouByoureki: action.data,
      };
    }

    case RihabiriActionTypes.REHA_ASSESS_ADL_ITEM_ANSWER_NAIYOU: {
      return {
        ...state,
        rehaAssessAdlItemAnswerNaiyou: action.data,
      };
    }

    case RihabiriActionTypes.REHA_ASSESS_ADL_ITEM_ADL: {
      return {
        ...state,
        rehaAssessAdlItemAdl: action.data,
      };
    }

    case RihabiriActionTypes.REHA_ASSESS_ADL_ITEM_KANKYOU: {
      return {
        ...state,
        rehaAssessAdlItemKanKyou: action.data,
      };
    }

    case RihabiriActionTypes.REHA_ASSESS_IADL_ITEM: {
      return {
        ...state,
        rehaAssessIADL: action.data,
      };
    }

    case RihabiriActionTypes.REHA_ASSESS_MMSE_ITEM: {
      return {
        ...state,
        rehaAssessMMSE: action.data,
      };
    }

    case RihabiriActionTypes.LOAD_RIHABIKIASSESSMENTBYID: {
      return {
        ...state,
        rehabiliAssessmentData: action.data,
        rehabiliPlanSub: 
          action.data && 
          action.data.rehabiliPlan && 
          action.data.rehabiliPlan.length > 0 &&
          action.data.rehabiliPlan[0].rehabiliPlanSub
            ? action.data.rehabiliPlan[0].rehabiliPlanSub
            : [],
        loading: false
      };
    }

    case RihabiriActionTypes.GET_RIHABILI_LIFE_YOUGO_MASTER_SUCCESS: {
      return {
        ...state,
        lifeYougoMaster: action.data,
      }
    }

    case RihabiriActionTypes.GET_FIRST_LOAD_DATA: {
      return {
        ...state,
        firstData: action.data,
      }
    }
    case RihabiriActionTypes.GET_FIRST_LOAD_DATA_ERROR: {
      return {
        ...state,
      }
    }

    case RihabiriActionTypes.SET_RIHABIRIPLANSUB: {
      console.log(action.data);
      if (action.data && action.data.length > 0) {
        const data = action.data;
        for (let i = 0; i < data.length; i++) {
          data[i].id = null;
        } 
      }

      return {
        ...state,
        rehabiliPlanSub: action.data,
      };
    }

    // case GET_COMMON_RIYOUBYOUREKI_MASTER: {
    //   return {
    //     ...state,
    //     riyouByoureki: action.data
    //   }
    // }

    // case CommonActionTypes.GET_MEDISYOUBYOUMEI: {
    //   return {
    //     ...state,
    //     syoubyoumei: action.data
    //   }
    // }

    // case CommonActionTypes.GET_IYAKUHIN: {
    //   return {
    //     ...state,
    //     iyakuhin: action.data
    //   }
    // }

    // case CommonActionTypes.GET_DOCTOR_MASTER_LIST: {
    //   return {
    //     ...state,
    //     doctorMasterList: action.data
    //   }
    // }

    // case CommonActionTypes.GET_DOCTORMASTER_MAX_SORT: {
    //   return {
    //     ...state,
    //     doctorMaxSort: action.data
    //   }
    // }

    // case CommonActionTypes.GET_DOCTORMASTER_INIT: {
    //   return {
    //     ...state,
    //     doctorMaster: action.data
    //   }
    // }

    // case CommonActionTypes.CREATE_DOCTORMASTER_SUCCESS: {
    //   openNotificationWithIcon("success", "保存しました");
    //   return {
    //     ...state,
    //     doctorMaster: action.data
    //   }
    // }

    // case CommonActionTypes.CREATE_DOCTORMASTER_ERROR: {
    //   openNotificationWithIcon("error", "エラー");
    //   return {
    //     ...state,
    //     doctorMaster: null
    //   }
    // }

    // case CommonActionTypes.UPDATE_DOCTORMASTER_SUCCESS: {
    //   openNotificationWithIcon("success", "保存しました");
    //   return {
    //     ...state,
    //     doctorMaster: action.data
    //   }
    // }

    // case CommonActionTypes.UPDATE_DOCTORMASTER_ERROR: {
    //   openNotificationWithIcon("error", "エラー");
    //   return {
    //     ...state,
    //     doctorMaster: null
    //   }
    // }

    // case CommonActionTypes.GET_IRYOKIKAN_MASTER_INIT: {
    //   return {
    //     ...state,
    //     iryoKikanMaster: action.data
    //   }
    // }

    // case CommonActionTypes.CREATE_IRYOKIKAN_MASTER_SUCCESS: {
    //   openNotificationWithIcon("success", "保存しました");
    //   return {
    //     ...state,
    //     iryoKikanMaster: action.data
    //   }
    // }

    // case CommonActionTypes.CREATE_IRYOKIKAN_MASTER_ERROR: {
    //   openNotificationWithIcon("error", "エラー");
    //   return {
    //     ...state,
    //     iryoKikanMaster: null
    //   }
    // }

    // case CommonActionTypes.UPDATE_IRYOKIKAN_MASTER_SUCCESS: {
    //   openNotificationWithIcon("success", "保存しました");
    //   return {
    //     ...state,
    //     iryoKikanMaster: action.data
    //   }
    // }

    case ErrorActionTypes.SET_ERROR: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

export default rihabiri;
