import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import {
  getMenu,
  localStorageClearCus,
} from "../common/function_common/functionCommon";

const IndividualPlanApi = {
  async loadIndividualPlan(riyousyaIds, startDate, endDate) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_RIYOUSYA_PLAN, {
        method: "POST",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          riyousyaIds,
          startDate,
          endDate,
        },
        headers: {
          "x-token": localStorage.getItem("jwtToken"),
        },
        withCredentials: true,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        clearLocalStorage(err);
      }
    }
  },
  async createIndividualPlan(riyousyaId, date, yotei, sonota) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.CREATE_RIYOUSYA_PLAN, {
        method: "POST",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          riyousyaId,
          date,
          yotei,
          sonota,
        },
        headers: {
          "x-token": localStorage.getItem("jwtToken"),
        },
        withCredentials: true,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        clearLocalStorage(err);
      }
    }
  },
  async updateIndividualPlan(riyousyaPlanId, riyousyaId, date, yotei, sonota) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.UPDATE_RIYOUSYA_PLAN, {
        method: "PUT",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          riyousyaPlanId,
          riyousyaId,
          date,
          yotei,
          sonota,
        },
        headers: {
          "x-token": localStorage.getItem("jwtToken"),
        },
        withCredentials: true,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        clearLocalStorage(err);
      }
    }
  },
  async deleteIndividualPlan(riyousyaPlanId) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.DELETE_RIYOUSYA_PLAN, {
        method: "DELETE",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          riyousyaPlanId,
        },
        headers: {
          "x-token": localStorage.getItem("jwtToken"),
        },
        withCredentials: true,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        clearLocalStorage(err);
      }
    }
  },
};

const EventPlanApi = {
  async loadEventPlan(startDate, endDate) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_EVENT_PLAN, {
        method: "POST",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          startDate,
          endDate,
        },
        headers: {
          "x-token": localStorage.getItem("jwtToken"),
        },
        withCredentials: true,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        clearLocalStorage(err);
      }
    }
  },

  async createEventPlan(date, event) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.CREATE_EVENT_PLAN, {
        method: "POST",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          date,
          event,
        },
        headers: {
          "x-token": localStorage.getItem("jwtToken"),
        },
        withCredentials: true,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        clearLocalStorage(err);
      }
    }
  },

  async updateEventPlan(eventPlanId, date, event) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.UPDATE_EVENT_PLAN, {
        method: "PUT",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          eventPlanId,
          date,
          event,
        },
        headers: {
          "x-token": localStorage.getItem("jwtToken"),
        },
        withCredentials: true,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        clearLocalStorage(err);
      }
    }
  },

  async deleteEventPlan(eventPlanId) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.DELETE_EVENT_PLAN, {
        method: "DELETE",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          eventPlanId,
        },
        headers: {
          "x-token": localStorage.getItem("jwtToken"),
        },
        withCredentials: true,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        clearLocalStorage(err);
      }
    }
  },
};

const LoadRiyousyaDataApi = {
  async loadNyutaishoRiyousya(startDate, endDate) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BETWEEN_DATES +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate,
        {
          method: "GET",
          headers: {
            "x-token": localStorage.getItem("jwtToken"),
          },
          withCredentials: true,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        clearLocalStorage(err);
      }
    }
  },
};

function clearLocalStorage(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
  return err;
}

export { EventPlanApi, IndividualPlanApi, LoadRiyousyaDataApi };

