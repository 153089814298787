import { SEND_TO_IDS } from "../../actions/dengonSentToActions/actionName";

/*
 * @param: username: string
 * @param: password: string
 */
function sendToCreators(ids) {
  return {
    type: SEND_TO_IDS,
    sendTo: ids,
  };
}

export { sendToCreators };
