/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Modal, Row } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Component } from "react";
import IryoKikanMasterModal from "../../../common/component/control/IryoKikanMasterModal";
import { ConstSet } from "../../../common/configs/constset";
import TextAreaSelect from "./TextAreaSelect";
import TextInputArea from "./TextInputArea";

class FormNyuinNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
      isTaishopDateOpen: false,
      visibleIryoKikan: false,
      isNyuinDateOpen: false,
    };
  }

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouNyuin",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleSort = (type, sortType, index) => {
    this.props.handleSort(type, sortType, index);
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  searchIryouKikanMaster = (bunrui, name) => {
    this.props.searchIryouKikanMaster(bunrui, name);
  };

  handleEndOpenNyuinDate = (open) => {
    this.setState({ isNyuinDateOpen: open });
  };

  handleClearNyuinDate = () => {
    this.props.form.setFieldsValue({
      nyuinDate: null,
    });
    this.setState({ isNyuinDateOpen: false });
    const tmp = _.cloneDeep(this.props.form.getFieldsValue());
    tmp.isClearNyuinDate = true;
    if (tmp.nyuinDate) {
      tmp.nyuinDate = tmp.nyuinDate.format(ConstSet.DATE_FORMAT);
    }
    if (tmp.tainDate) {
      tmp.tainDate = tmp.tainDate.format(ConstSet.DATE_FORMAT);
    }
    this.props.saveOnchangeToState(tmp, "fNyuin", this.props.index);
  };

  handleEndOpenChange = (open) => {
    this.setState({ isTaishopDateOpen: open });
  };

  handleClearTaishoDate = () => {
    this.props.form.setFieldsValue({
      tainDate: null,
    });
    this.setState({ isTaishopDateOpen: false });
    const tmp = _.cloneDeep(this.props.form.getFieldsValue());
    if (tmp.nyuinDate) {
      tmp.nyuinDate = tmp.nyuinDate.format(ConstSet.DATE_FORMAT);
    }
    if (tmp.tainDate) {
      tmp.tainDate = tmp.tainDate.format(ConstSet.DATE_FORMAT);
    }
    this.props.saveOnchangeToState(tmp, "fNyuin", this.props.index);
  };

  handleOpenTable = () => {
    this.setState({
      visibleIryoKikan: true,
    });
  };

  handleCloseTable = () => {
    this.setState({
      visibleIryoKikan: false,
    });
  };

  onSubmiIryoKikan = (record) => {
    if (record) {
      this.props.form.setFieldsValue({
        hospital: record.hospital,
      });
    }
    this.handleCloseTable();
  };

  render() {
    const { visible, isTaishopDateOpen, visibleIryoKikan, isNyuinDateOpen } =
      this.state;
    const { getFieldDecorator } = this.props.form;
    const { index, nyuinRiyuList, tainRiyuList } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {index && index > 0 ? (
          <Row style={{ padding: "8px 0px" }}>
            <Divider />
            <Divider />
            <Divider />
          </Row>
        ) : null}
        <Row className="title-inline">
          <Col span={12}>
            <h3
              style={{ background: "#d9d9d9", padding: "4px 6px" }}
            >{`入退院 ${index + 1}`}</h3>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.showDeleteConfirm}
            >
              削除
            </Button>
          </Col>
          <Col span={12} className="title-inline-right">
            <h3>順序</h3>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.handleSort.bind(this, "riyouNyuin", "up", index)}
            >
              上へ <CaretUpOutlined />
            </Button>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.handleSort.bind(this, "riyouNyuin", "down", index)}
            >
              下へ <CaretDownOutlined />
            </Button>
          </Col>
        </Row>
        <Divider />
        <Divider />
        <Form.Item label={"施設名"} colon={false}>
          <div className="text-area-select-ri-page">
            {getFieldDecorator("hospital", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(
              <TextInputArea
                element={"hospital"}
                autoSize={{ minRows: 1, maxRows: 99 }}
              />
            )}
            <span className="text-area-select-btn" style={{ marginLeft: 5 }}>
              <Button type="primary" onClick={this.handleOpenTable}>
                マスタ
              </Button>
            </span>
          </div>
        </Form.Item>
        <Divider />
        <Row>
          <Col xs={24} sm={12}>
            <Form.Item label={"入院日"}>
              {getFieldDecorator("nyuinDate", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <DatePicker
                  style={{ width: "150px" }}
                  showToday={false}
                  renderExtraFooter={() => (
                    <a onClick={this.handleClearNyuinDate}>クリア</a>
                  )}
                  onOpenChange={this.handleEndOpenNyuinDate}
                  open={isNyuinDateOpen}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label={"入院理由"}>
              {getFieldDecorator("nyuinRiyu", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <TextAreaSelect
                  isMaster={false}
                  isSelect={true}
                  isOveride={true}
                  rowSet={1}
                  dataSelect={nyuinRiyuList}
                  yougoMasterUpdate={(naiyou) =>
                    this.yougoMasterUpdate("入院理由", naiyou)
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xs={24} sm={12}>
            <Form.Item label={"退院日"}>
              {getFieldDecorator("tainDate", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <DatePicker
                  style={{ width: "150px" }}
                  showToday={false}
                  renderExtraFooter={() => (
                    <a onClick={this.handleClearTaishoDate}>クリア</a>
                  )}
                  onOpenChange={this.handleEndOpenChange}
                  open={isTaishopDateOpen}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label={"退院理由"}>
              {getFieldDecorator("tainRiyu", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <TextAreaSelect
                  isMaster={false}
                  isSelect={true}
                  isOveride={true}
                  rowSet={1}
                  dataSelect={tainRiyuList}
                  yougoMasterUpdate={(naiyou) =>
                    this.yougoMasterUpdate("退院理由", naiyou)
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Form.Item label={"備考"} colon={false}>
          {getFieldDecorator("bikou", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextAreaSelect isMaster={false} isSelect={false} rowSet={2} />)}
        </Form.Item>
        <Divider />
        <IryoKikanMasterModal
          visible={visibleIryoKikan}
          onSubmit={(record) => this.onSubmiIryoKikan(record)}
          onCancel={this.handleCloseTable}
        />
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
      </Form>)
    );
  }
}

export const FormNyuin = Form.create({
  name: "input-riyou-nyuin",
  onValuesChange(props, changedValues, allValues) {
    // Format Date time
    const tmp = _.cloneDeep(allValues);
    if (tmp.nyuinDate) {
      tmp.nyuinDate = tmp.nyuinDate.format(ConstSet.DATE_FORMAT);
    }

    if (tmp.tainDate) {
      tmp.tainDate = tmp.tainDate.format(ConstSet.DATE_FORMAT);
    }

    props.saveOnchangeToState(tmp, "fNyuin", props.index);
  },
  mapPropsToFields(props) {
    let dateTimeNyu = new Date();
    if (props.data && props.data.nyuinDate) {
      dateTimeNyu = new Date(props.data.nyuinDate);
    }

    let dateTimeTai = new Date();
    if (
      props.data &&
      props.data.tainDate &&
      props.data.tainDate !== "0001-01-01"
    ) {
      dateTimeTai = new Date(props.data.tainDate);
    }

    return {
      hospital: Form.createFormField({
        value: props.data ? props.data.hospital : null,
      }),
      nyuinDate: Form.createFormField({
        value:
          props.data &&
            props.data.nyuinDate &&
            props.data.nyuinDate !== "0001-01-01"
            ? dayjs(dateTimeNyu)
            : props.data && props.data.isClearNyuinDate
              ? null
              : dayjs(new Date()),
      }),
      nyuinRiyu: Form.createFormField({
        value: props.data ? props.data.nyuinRiyu : null,
      }),
      tainDate: Form.createFormField({
        value:
          props.data &&
            props.data.tainDate &&
            props.data.tainDate !== "0001-01-01"
            ? dayjs(dateTimeTai)
            : null,
      }),
      tainRiyu: Form.createFormField({
        value: props.data ? props.data.tainRiyu : null,
      }),
      bikou: Form.createFormField({
        value: props.data ? props.data.bikou : null,
      }),
    };
  },
})(FormNyuinNormal);
