import React from "react";
// import PaintView from "../../../common/paint/PaintView";
import { PaintView } from "../../../common/paint/PaintView";

export default class ChartInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount() {
  //   this.props.onRef(this);
  //   setTimeout(() => {
  //     if (this.painview) {
  //       this.painview.zoomPainview(0.375);
  //     }
  //   }, 1500);
  // }
  
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidUpdate(prevProps) {
    const { changeFacesheetState, saveFacesheetState, openFacesheetState } = this.props;

    if (openFacesheetState) {
      clearTimeout(this.openZoomTimer);

      this.openZoomTimer = setTimeout(() => {
        if (this.painview) {
          this.painview.zoomPainview(0.375);
          this.props.changeFacesheet(false, "open");
        }
      }, 1500);
    } else if (changeFacesheetState) {
      clearTimeout(this.changeZoomTimer);

      if (prevProps.riyouChart !== this.props.riyouChart) {
        this.changeZoomTimer = setTimeout(() => {
          if (this.painview) {
            this.painview.zoomPainview(0.375);
            this.props.changeFacesheet(false, "change");
          }
        }, 1500);
      }
    } else if (saveFacesheetState) {
      clearTimeout(this.saveZoomTimer);

      if (prevProps.riyouChart !== this.props.riyouChart && this.painview) {
        this.saveZoomTimer = setTimeout(() => {
          if (this.painview) {
            this.painview.zoomPainview(0.375);
            this.props.changeFacesheet(false, "save");
          }
        }, 1500);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.riyouChart !== this.props.riyouChart) {
      if (
        nextProps.riyouChart.swpict &&
        this.props.riyouChart.swpict &&
        nextProps.riyouChart.swpict !== this.props.riyouChart.swpict
      ) {
        // setTimeout(() => {
        //     if (this.painview) {
        //         this.painview.zoomPainview(0.375);
        //         this.setState({ printOK: true });
        //     }
        // }, 1000);
      } else {
        setTimeout(() => {
          if (this.painview) {
            this.painview.zoomPainview(1);
          }
        }, 1500);
      }
    }
  }

  render() {
    const { riyouChart } = this.props;
    return (
      <div>
        {riyouChart?.swpict ? (
          <PaintView
            onRef={(ref) => (this.painview = ref)}
            value={riyouChart?.swpict ? riyouChart.swpict : null}
            style={
              riyouChart?.swpict
                ? { pointerEvents: "none" }
                : { display: "none", pointerEvents: "none" }
            }
          />
        ) : null}
      </div>
    );
  }
}
