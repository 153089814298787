import { UserOutlined } from "@ant-design/icons";
import { Button, Select, Spin } from "antd";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { apiUrls } from "../configs/common-url";
import {
  formatStorage,
  getMenu,
  getPath,
  isLife,
  isManagement,
} from "../function_common/functionCommon";
import SearchRiyousyaModal from "./control/SearchRiyousyaModal";

const { Option } = Select;

export default class SubHeaderRiyousya extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: {
        key: "",
        label: "",
      },
      fetching: false,
      isVisibleSearchFurigana: false,
    };
  }

  // change value one time when props change at start
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.riyousayInfo) {
      this.setState({
        value: {
          key: newProps.riyousayInfo.riyousya.id,
          label: newProps.riyousayInfo.riyousya.name,
        },
      });
    }
  }

  /**
   *
   */
  fetchUser = (value) => {
    this.setState({ data: [], fetching: true });
    const check = isManagement() || isLife();
    if (check) {
      const systemName = formatStorage(getMenu()).scn;
      const mainSid = formatStorage(getMenu()).si;
      axios(
        apiUrls.INIT +
        apiUrls.RIYOUSYA_SEARCH2 +
        "?textSearch=" +
        value +
        "&systemName=" +
        systemName,
        "&mainSid=" +
        mainSid,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // console.log(res.data);
          this.setState({
            data: res.data,
            fetching: false,
          });
        })
        .catch(() => {
          setTimeout(() => {
            this.setState({
              fetching: false,
            });
          }, 1000);
        });
    }
    else {
      axios(apiUrls.INIT + apiUrls.RIYOUSYA_SEARCH + "?textSearch=" + value, {
        method: "GET",
      })
        .then((res) => {
          // console.log(res.data);
          this.setState({
            data: res.data,
            fetching: false,
          });
        })
        .catch(() => {
          setTimeout(() => {
            this.setState({
              fetching: false,
            });
          }, 1000);
        });
    }
  };

  handleChange = (value) => {
    // console.log(value);
    this.setState({
      value,
      data: [],
      fetching: false,
    });
    this.props.reportRiyousya(value.key.toString());
  };

  isSearchFurigana = () => {
    this.setState({
      isVisibleSearchFurigana: true,
    });
  };

  onOkSearch(riyousya) {
    this.setState({
      isVisibleSearchFurigana: false,
    });
    if (riyousya) this.props.reportRiyousya(riyousya.id.toString());
  }

  onCancelSearch() {
    this.setState({
      isVisibleSearchFurigana: false,
    });
  }

  render() {
    const { fetching, data, value } = this.state;
    const path = getPath();
    return (
      <div style={this.props.style}>
        <Button type="primary" className="kmu-menu-detail">
          <Link to={path + "/riyousya-detail/" + value.key}>利用者詳細</Link>
        </Button>
        <Select
          showSearch
          labelInValue
          value={value}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={this.fetchUser}
          onChange={this.handleChange}
          style={{ width: "180px", top: "1px" }}
          className="kmu-menu-detail"
        >
          {data.map((d) => (
            <Option key={d.id} value={d.id}>
              {d.name}
            </Option>
          ))}
        </Select>
        <Button
          type="primary"
          className="kmu-menu-detail"
          icon={<UserOutlined />}
          onClick={this.isSearchFurigana}
        >
          {"検索"}
        </Button>
        <SearchRiyousyaModal
          visible={this.state.isVisibleSearchFurigana}
          onOk={(riyousya) => this.onOkSearch(riyousya)}
          onCancel={() => this.onCancelSearch()}
        />
      </div>
    );
  }
}
