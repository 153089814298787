/*eslint no-unused-vars: 0*/

import FabricCanvasTool from './fabrictool'

const fabric = require('fabric').fabric;

class Kaidan extends FabricCanvasTool {


    configureCanvas(props) {
        let canvas = this._canvas;
        canvas.isDrawingMode = canvas.selection = false;
        canvas.forEachObject((o) => o.selectable = o.evented = false);
        this._width = 1;
        this._color = props.lineColor;
        this._fill = props.fillColor;
        this.call_back = props.update_select;
    }

    doMouseDown(o) {
        let canvas = this._canvas;
        this.isDown = true;
        let pointer = canvas.getPointer(o.e);
        this.startX = pointer.x;
        this.startY = pointer.y;
        var rect = new fabric.Rect({
            left: this.startX,
            top: this.startY,
            originX: 'left',
            originY: 'top',
            width: 100,
            height: 60,
            stroke: this._color,
            strokeWidth: 1,
            fill: this._isfill ? 'rgba(0,0,0,1)' : this._fill,
            transparentCorners: false,
            selectable: false,
            evented: false,
            angle: 0
        });
        var points = [this.startX, this.startY + 15, this.startX + 100, this.startY + 15];
        var line = new fabric.Line(points, {
            strokeWidth: this._width,
            fill: this._color,
            stroke: this._color,
            originX: 'left',
            originY: 'top',
            selectable: false,
            evented: false
        });
        var points2 = [this.startX, this.startY + 30, this.startX + 100, this.startY + 30];
        var line2 = new fabric.Line(points2, {
            strokeWidth: this._width,
            fill: this._color,
            stroke: this._color,
            originX: 'left',
            originY: 'top',
            selectable: false,
            evented: false
        });
        var points3 = [this.startX, this.startY + 45, this.startX + 100, this.startY + 45];
        var line3 = new fabric.Line(points3, {
            strokeWidth: this._width,
            fill: this._color,
            stroke: this._color,
            originX: 'left',
            originY: 'top',
            selectable: false,
            evented: false
        });
        var txt = new fabric.Text(`階段`, {
            left: this.startX + 50,
            top: this.startY + 30,
            originX: 'center',
            originY: 'center',
            fontSize: 40,
        });
        this.groupc = new fabric.Group([rect, line, line2, line3,txt], {
            left: this.startX,
            top: this.startY,
            angle: 0,
            selectable: true,
        });
        canvas.add(this.groupc);
    }

    doMouseMove(o) {
        if (!this.isDown) return;
        let canvas = this._canvas;
        let pointer = canvas.getPointer(o.e);
        this.groupc.set(
            {
                scaleX: pointer.x / this.startX,
                scaleY: pointer.x / this.startX,
            }
        );

        this.groupc.setCoords();
        canvas.renderAll();
    }

    doMouseUp(o) {
        this.isDown = false;
        this.call_back();
    }
}

export default Kaidan;