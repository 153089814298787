import { connect } from "react-redux";
import Logout from "./Logout";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const LogoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
