import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BarsOutlined, EnterOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button } from "antd";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";

// constants
import { General, Label } from "../../constants";

// dialogs
import { ConfirmSignoutDialog } from "../../dialogs";

// redux actions
import { AuthActions } from "../../redux/actions";
import { AuthActionTypes } from "../../redux/action_types";

import { StorageUtils } from "../../utils";
import { getValueLocalstorage } from "../../common/function_common/functionCommon";
import { ConfigName } from "../../../config-name";

function Header({ avatar, onSignout, config, onHandleMenu, history }) {
  const [visibleDialog, updateVisibledialog] = useState(false);
  const menuKiroku = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
  const formatMenu = menuKiroku && menuKiroku.menu.split(";");
  const format = formatMenu && formatMenu.map((item) => item.split(","));
  const current = StorageUtils.getValue(ConfigName.SWLIFE_CURRENT_MENU);

  const tokenslife =
    StorageUtils.getValue(ConfigName.SWLIFE_TOKEN) &&
    StorageUtils.getValue(ConfigName.SWLIFE_TOKEN).token;
  let userName = "";
  if (tokenslife && getValueLocalstorage("tid")) {
    const decoded = jwtDecode(tokenslife);
    userName = decoded && decoded.userInfo && decoded.userInfo.name;
    const loginMode = getValueLocalstorage("mode");
    if (loginMode) {
      userName = loginMode + "_" + userName;
    }
  }

  useEffect(() => {
    if (format && current) {
      if (current != `${menuKiroku.scn}_${menuKiroku.si}`) {
        let menu = format.find(
          (item) =>
            item[3] == current.split("_")[0] && item[0] == current.split("_")[1]
        );

        if (menu) {
          onHandleMenu(menu);
        }
      }
    } else {
      StorageUtils.setValue(
        ConfigName.SWLIFE_CURRENT_MENU,
        `${format[0][3]}_${format[0][0]}`
      );
    }
  }, []);

  const handleLink = (item) => {
    StorageUtils.setValue(
      ConfigName.SWLIFE_CURRENT_MENU,
      `${item[3]}_${item[0]}`
    );
    history.push("/life/list");
    // window.location.reload();
  };

  const handleUserLink = () => {
    history.push("/life/user-management");
  };

  const menuHeader =
    format &&
    format.map((item, index) => {
      return (
        <Menu.Item key={`${item[3]}_${item[0]}`}>
          <div
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              handleLink(item);
              onHandleMenu(item);
            }}
            className={`${
              (config && config.values && config.values[2] == item[2]) ||
              (menuKiroku && menuKiroku.sy == item[2])
                ? "active-menu"
                : ""
            }`}
          >
            {item[2]}
          </div>
        </Menu.Item>
      );
    });
  const menu = (
    <Menu selectedKeys={[current]}>
      {menuHeader}
      <Menu.Divider />
      <Menu.Item key="callback">
        <div
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            handleUserLink();
          }}
        >
          {Label.Header.DropdownItem.Profile}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => updateVisibledialog(true)}
        >
          {Label.Header.DropdownItem.Signout}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className="life-header">
      {/* header left */}
      <div className="life-header__left">
        <div className="life-header__logo">
          <img src={General.Logo} className="life-header__logo" />
        </div>
        <h2 className="life-header__logo-title">
          {menuKiroku
            ? Label.General.LogoTitle + "　" + menuKiroku.sy
            : Label.General.LogoTitle}
        </h2>
      </div>

      {/* header right */}
      <div className="life-header__right">
        {getValueLocalstorage("mode") ? (
          <div className={"h-r-item"}>
            <Link to="/life/kanri/user">
              <Button type="dashed" style={{ marginTop: 8, marginRight: 8 }}>
                管理画面
                <EnterOutlined />
              </Button>
            </Link>
          </div>
        ) : null}

        <h2 className="life-header__nickname">{userName}</h2>

        <div className="life-header__avatar">
          <img alt="avatar" src={avatar} />
        </div>

        <div className="life-header__menu">
          <Dropdown overlay={menu} trigger={["click"]}>
            <BarsOutlined style={{ fontSize: "36px", padding: "2px", color: "#fff" }} />
          </Dropdown>
        </div>
      </div>

      {/* confirm signout dialog */}
      <ConfirmSignoutDialog
        visible={visibleDialog}
        onOk={onSignout}
        onCancel={() => updateVisibledialog(false)}
      />
    </header>
  );
}
const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSignout: () => dispatch(AuthActions.signout()),
  onHandleMenu: (values) =>
    dispatch({
      type: AuthActionTypes.LIFE_HANDLE_MENU,
      values,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
