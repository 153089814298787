import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Modal, Table, Input, Button, Row, Col, Spin, Divider } from "antd";
import DoctorMasterEditModal from "./DoctorMasterEditModal";
import { connect } from "react-redux";
import {
  loadDoctorMaster,
  updateDocotrMasterList,
} from "../../../actions/doctorMasterActions/actionCreators";

class DoctorMasterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecord: null,
      search: null,
      visibleEdit: false,
      editType: null,
      sort: false,
      sortTable: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.props.loadDoctorMaster();
    }
  }

  handleOk = () => {
    const { selectedRecord } = this.state;
    if (selectedRecord && selectedRecord.id) {
      this.props.onSelect(selectedRecord);
    }
    this.setState({
      selectedRecord: {},
      search: null,
      sort: false,
      sortTable: [],
    });
    this.props.onHide();
  };

  handleCancel = () => {
    this.props.onHide();
    this.setState({
      selectedRecord: {},
      search: null,
      sort: false,
      sortTable: [],
    });
  };

  handleSelectRow(record) {
    this.setState({ selectedRecord: record });
  }

  handleSearchInput(e) {
    this.setState({
      search: e.target.value,
    });
  }

  search() {
    this.props.loadDoctorMaster(this.state.search);
  }

  isDisableButton(selected) {
    return selected && this.props.doctorMasterList
      ? !this.props.doctorMasterList.some((item) => item.id == selected.id)
      : true;
  }

  handleAdd = () => {
    this.setState({
      visibleEdit: true,
      editType: 1, // add
    });
  };

  handleEdit = () => {
    this.setState({
      visibleEdit: true,
      editType: 2, // edit
    });
  };

  handleCopy = () => {
    this.setState({
      visibleEdit: true,
      editType: 3, // copy
    });
  };

  handleClose = () => {
    this.props.loadDoctorMaster(this.state.search);

    this.setState({
      visibleEdit: false,
      editType: null,
    });
  };

  sortOn = () => {
    const { doctorMasterList } = this.props;
    let tmp = JSON.parse(JSON.stringify(doctorMasterList));
    this.setState({
      sort: true,
      sortTable: tmp,
      selectedRecord: null,
    });
  };
  sortOff = () => {
    this.search();

    this.setState({
      sort: false,
      sortTable: [],
      selectedRecord: null,
    });
  };
  sortSave = () => {
    const { sortTable } = this.state;

    this.props.updateDocotrMasterList(sortTable);

    this.setState({
      sort: false,
      sortTable: [],
    });
  };

  sortTop = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const minNum = sortTable[0].sortNum;

    if (elementIndex != 0) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = minNum - 1;
      tableTmp.splice(0, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  sortUp = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);

    if (elementIndex != 0) {
      const changeElement = tableTmp[elementIndex - 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex - 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum--;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum++;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  sortDown = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      const changeElement = tableTmp[elementIndex + 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex + 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum++;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum--;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  sortBottom = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    let maxNum = 0;
    for (let i of tableTmp) {
      maxNum < i.sortNum ? (maxNum = i.sortNum) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = maxNum + 1;
      tableTmp.splice(bottomIndex, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  render() {
    const columns = [
      {
        title: "医師名",
        dataIndex: "name",
        key: "name",
        width: "40%",
      },
      {
        title: "病院名",
        dataIndex: "hospital",
        key: "hospital",
        width: "60%",
      },
    ];

    const columns2 = [
      {
        title: "医師名",
        dataIndex: "name",
        key: "name",
        width: "35%",
      },
      {
        title: "病院名",
        dataIndex: "hospital",
        key: "hospital",
        width: "35%",
      },
      // { //テスト
      //   title: "番号",
      //   dataIndex: "sortNum",
      //   // key: "hospital",
      //   width: "35%"
      // },
      {
        title: "行動",
        dataIndex: "operation",
        width: "30%",
        key: "operation",
        render: (record, index) => {
          return (
            <div>
              <span>
                <a onClick={() => this.sortTop(index)} style={{}}>
                  <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.sortUp(index)} style={{}}>
                  <CaretUpOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.sortDown(index)} style={{}}>
                  <CaretDownOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.sortBottom(index)} style={{}}>
                  <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
              </span>
            </div>
          );
        },
      },
    ];

    const { selectedRecord, visibleEdit, editType, sort, sortTable } =
      this.state;

    const title = (
      <div>
        <Row style={{ marginBottom: "-10px" }}>
          <Col span={6}>医師選択リスト</Col>
          <Col span={18} style={{ display: "flex" }}>
            <Input
              value={this.state.search}
              className={
                sort == false
                  ? "search-service-kikan"
                  : "search-service-kikan-none"
              }
              onChange={(e) => this.handleSearchInput(e)}
            />
            <Button
              onClick={() => this.search()}
              className={
                sort == false
                  ? "search-service-kikan"
                  : "search-service-kikan-none"
              }
              icon={<SearchOutlined />}
            >
              検索
            </Button>
          </Col>
        </Row>
      </div>
    );

    return (
      (<div>
        <Modal
          open={this.props.visible}
          title={title}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="tanto-doctor-master-modal modal-list"
          closable={false}
          footer={[
            <Button key="back" onClick={() => this.handleCancel()}>
              キャンセル
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => this.handleOk()}
              disabled={sort == true ? true : selectedRecord ? false : true}
            >
              OK
            </Button>,
          ]}
        >
          <Button
            key="add"
            type={"primary"}
            onClick={this.handleAdd}
            style={{ marginRight: 8, marginTop: -12 }}
            disabled={sort == true ? true : false}
          >
            追加
          </Button>
          <Button
            key="edit"
            type={"primary"}
            onClick={this.handleEdit}
            disabled={
              sort == true ? true : this.isDisableButton(selectedRecord)
            }
            style={{ marginRight: 8 }}
          >
            修正
          </Button>
          <Button
            key="delete"
            type={"primary"}
            onClick={this.handleCopy}
            disabled={
              sort == true ? true : this.isDisableButton(selectedRecord)
            }
            style={{ marginRight: 8 }}
          >
            複製
          </Button>
          {sort == false ? (
            <a
              key="sort"
              // type={"primary"}
              onClick={this.sortOn}
              style={{
                marginRight: 8,
                marginTop: 7,
                marginLeft: 10,
                color: "black",
              }}
            >
              並び替え
            </a>
          ) : (
            <span>
              <Button
                key="sortSave"
                type={"primary"}
                onClick={this.sortSave}
                style={{ marginRight: 8 }}
              >
                並び替え保存
              </Button>
              <a
                onClick={this.sortOff}
                style={{ paddingTop: "14px", color: "black" }}
              >
                戻る
              </a>
            </span>
          )}
          <Spin spinning={this.props.loading}>
            {sort === false ? (
              <Table
                rowKey="id"
                style={{ cursor: "pointer", marginTop: 5, marginBottom: -30 }}
                size={"small"}
                bordered={true}
                columns={columns}
                dataSource={this.props.doctorMasterList}
                pagination={{ pageSize: 20 }}
                scroll={{ y: 300 }}
                onRow={(record) => ({
                  onClick: () => this.handleSelectRow(record),
                })}
                rowClassName={(record) =>
                  selectedRecord != null && record.id === selectedRecord.id
                    ? "pljp-table-row-hover"
                    : "pointer-row"
                }
              />
            ) : sort == true ? (
              <Table
                rowKey="id"
                style={{ cursor: "pointer", marginTop: 5, marginBottom: -30 }}
                size={"small"}
                bordered={true}
                columns={columns2}
                dataSource={sortTable}
                pagination={{ pageSize: 20 }}
                scroll={{ y: 300 }}
                // onRow={record => ({
                //   onClick: () => this.handleSelectRow(record)
                // })}
                rowClassName={(record) =>
                  selectedRecord != null && record.id === selectedRecord.id
                    ? "pljp-table-row-hover"
                    : "pointer-row"
                }
              />
            ) : null}
          </Spin>
        </Modal>
        <DoctorMasterEditModal
          visible={visibleEdit}
          data={selectedRecord}
          editType={editType}
          handleSelectRow={(record) => this.handleSelectRow(record)}
          handleClose={this.handleClose}
        />
      </div>)
    );
  }
}

DoctorMasterModal.propTypes = {
  visible: PropTypes.bool,
  onSelect: PropTypes.func,
  onHide: PropTypes.func,
};

const mapStateToProps = () => (state) => ({
  loading: state.doctorMaster.loading,
  doctorMasterList: state.doctorMaster.doctorMasterList,
});

const mapDispatchToProps = () => (dispatch) => ({
  loadDoctorMaster: (searchText) => dispatch(loadDoctorMaster(searchText)),
  updateDocotrMasterList: (data) => dispatch(updateDocotrMasterList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorMasterModal);
