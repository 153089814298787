import { applyMiddleware, compose, legacy_createStore as createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { StorageUtils } from "../../utils";
import rootReducer from "../reducers";
import initialStates from "./initial_states";
import middlewares from "./middlewares";
const presistConfig = {
  key: "root",
  storage,
  whitelist: ["useslip"],
  stateReconciler: autoMergeLevel2,
  serialize: (obj) => {
    return JSON.stringify(obj, (key, value) => (typeof value === "function" ? "__FUNCTION__" : value));
  },
  deserialize: (str) => {
    return JSON.parse(str, (key, value) => (value === "__FUNCTION__" ? () => { } : value));
  },
};
const getStorageStates = () => {
  let states = {};
  const auth = StorageUtils.getValue("auth");

  if (auth) {
    states = { ...states, auth };
  }
  return states;
};

const composedEnhancer = compose(applyMiddleware(...middlewares));
const storageStates = getStorageStates();
const preloadedStates = { ...initialStates, ...storageStates };
const persistedReducer = persistReducer(presistConfig, rootReducer);
const store = createStore(persistedReducer, preloadedStates, composedEnhancer);
const persistor = persistStore(store);

export default store;
export { persistor };
