import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { YougoMasterActionTypes } from "../action_types";

function yougoMaster(state = {}, action) {
  switch (action.type) {
    case YougoMasterActionTypes.GET_YOUGO_MASTER_BUNRUI1: {
      return {
        ...state,
        bunrui1: action.naiyou,
      };
    }
    case YougoMasterActionTypes.GET_YOUGO_MASTER_BUNRUI2: {
      return {
        ...state,
        bunrui2: action.naiyou,
      };
    }
    case YougoMasterActionTypes.GET_YOUGO_MASTER_BUNRUI3: {
      return {
        ...state,
        bunrui3: action.naiyou,
      };
    }

    case YougoMasterActionTypes.GET_YOUGO_MASTER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case YougoMasterActionTypes.GET_YOUGO_MASTER_SUCCESS: {
      const { naiyou } = action;
      const yougoNaiyou = naiyou
        ? naiyou.split(";").map((v, i) => {
            return {
              key: i,
              naiyou: v,
              editable: false,
            };
          })
        : [];
      return {
        ...state,
        yougoNaiyou,
        loading: false,
      };
    }

    case YougoMasterActionTypes.GET_YOUGO_MASTER_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoNaiyou: [],
      };

    case YougoMasterActionTypes.SAVE_YOUGO_MASTER_SUCCESS:
      return {
        ...state,
        yougoNaiyou: action.naiyou
          ? action.naiyou.split(";").map((v, i) => {
              return {
                key: i,
                naiyou: v,
                editable: false,
              };
            })
          : [],
      };

    case YougoMasterActionTypes.SAVE_YOUGO_MASTER_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoNaiyou: [],
      };

    case YougoMasterActionTypes.YOUGO_EDIT_STATE:
      return {
        ...state,
        yougoNaiyou: state.yougoNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = true;
          } else {
            item.editable = false;
          }
          return item;
        }),
      };

    case YougoMasterActionTypes.YOUGO_CANCEL_STATE:
      return {
        ...state,
        yougoNaiyou: state.yougoNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = false;
          }
          return item;
        }),
      };

    default: {
      return {
        ...state,
      };
    }
  }
}

export default yougoMaster;
