import React, { Component } from "react";
import { Radio } from "antd";

const { Group, Button } = Radio;

export class ModeSwitch extends Component {
  render() {
    return (
      <div style={this.props.style}>
        <Group
          onChange={(e) => this.props.handleChangeMode(e.target.value)}
          value={this.props.mode}
        >
          <Button value="1">{">モニタリング"}</Button>
          <Button value="2">{">個別評価"}</Button>
        </Group>
      </div>
    );
  }
}
