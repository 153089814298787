import { connect } from "react-redux";

import ProgressRecord from "./ProgressRecord";
import ProgressRecordRiyousya from "./ProgressRecordRiyousya";
import InputReportCase from "./InputReportCase";

import {
  loadReportCases,
  loadReportCasesStartup,
  createReportCase,
  updateReportCase,
  deleteReportCase,
  updateKakuninComment,
  loadFilterMasterSetting,
  sortReportCaseData,
  saveOpenScreenLog,
  getReportCaseById,
} from "../../actions/progressActions/actionCreators";
import { getRiyousya } from "../../actions/riyousyaActions/actionCreators";
import { tantoMasterList2 } from "../../actions/tantoMasterListActions/actionCreators";
import { systemNameListCreator } from "../../actions/systemControlActions/actionCreators";
import { getValueLocalstorage } from "../../common/function_common/functionCommon";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    tantoMasters: state.tantoMasterList.tantoMasters2,
    systemNames: state.system.systemNames,
    filterMasterData: state.progress.filterMaster,
    filterConditions: state.progress.filterConditions,
    reportCases: state.progress.reportCases,
    moshiokuri: state.progress.moshiokuri,
    isRed: state.progress.isRed,
    riyousya: state.riyousya.riyousya,
    reportCase: state.progress.reportCase,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getTantoMasterList: () => dispatch(tantoMasterList2()),
    getSystemNameList: () => dispatch(systemNameListCreator()),
    getFilterMasterData: () => dispatch(loadFilterMasterSetting()),
    sortReportCases: (searchData) => dispatch(sortReportCaseData(searchData)),
    getReportCasesStartup: () => dispatch(loadReportCasesStartup()),
    getReportCases: (params, searchData) =>
      dispatch(loadReportCases(params, searchData)),
    createReportCase: (data) => dispatch(createReportCase(data)),
    updateReportCase: (data) => dispatch(updateReportCase(data)),
    deleteReportCase: (reportCaseId) =>
      dispatch(deleteReportCase(reportCaseId)),
    updateKakuninComment: (data) => dispatch(updateKakuninComment(data)),
    saveOpenScreenLog: () =>
      dispatch(saveOpenScreenLog(getValueLocalstorage("tid"))),
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
    getReportCaseById: (reportCaseId) =>
      dispatch(getReportCaseById(reportCaseId)),
  };
};

export const ProgressRecordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressRecord);
export const ProgressRecordRiyousyaContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressRecordRiyousya);
export const InputReportCaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputReportCase);
