import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";

// import jsonLocal from '../data/jsonLocal'

let SearchApi = {
  search(text) {
    return axios(apiUrls.INIT + apiUrls.ZIP_MASTER_SEARCH + `?text=${text}`, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
};
export { SearchApi };
