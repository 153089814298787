import { Row, notification } from "antd";
import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";

import { getPath } from "../../common/function_common/functionCommon";
import ActionsRow from "./UI/ActionsRow";
import WrappedUserForm from "./UI/UserForm";

class KanriUserSave extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      userId: parseInt(match.params.userId),
      path: getPath(),
    };

    this.registerUser = this.registerUser.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    // load data
    this.props.getUserList();
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  async registerUser(values) {
    if (values.id) {
      await this.props.updateUser(values);
    }
    else {
      await this.props.createUser(values);
    }
    this.props.history.goBack();
  }

  async delete(id) {
    await this.props.deleteUser(id.toString());

    this.props.history.goBack();
  }

  preToEnd = () => {
    const { kanriUserList } = this.props;
    const { userId, path } = this.state;

    if (kanriUserList && kanriUserList.length > 0) {
      const index = kanriUserList.findIndex((item) => item.id === userId);

      if (index >= 1) {
        this.setState({
          userId: kanriUserList[0].id,
        });

        this.props.history.replace(path + "/kanri/user/" + kanriUserList[0].id);
      }
    }
  };

  pre = () => {
    const { kanriUserList } = this.props;
    const { userId, path } = this.state;

    if (kanriUserList && kanriUserList.length > 0) {
      const index = kanriUserList.findIndex((item) => item.id === userId);

      if (index >= 1) {
        this.setState({
          userId: kanriUserList[index - 1].id,
        });

        this.props.history.replace(
          path + "/kanri/user/" + kanriUserList[index - 1].id
        );
      }
    }
  };

  next = () => {
    const { kanriUserList } = this.props;
    const { userId, path } = this.state;

    if (kanriUserList && kanriUserList.length > 0) {
      const index = kanriUserList.findIndex((item) => item.id === userId);

      if (index >= 0 && index < kanriUserList.length - 1) {
        this.setState({
          userId: kanriUserList[index + 1].id,
        });

        this.props.history.replace(
          path + "/kanri/user/" + kanriUserList[index + 1].id
        );
      }
    }
  };

  nextToEnd = () => {
    const { kanriUserList } = this.props;
    const { userId, path } = this.state;

    if (kanriUserList && kanriUserList.length > 0) {
      const index = kanriUserList.findIndex((item) => item.id === userId);

      if (index !== kanriUserList.length) {
        this.setState({
          userId: kanriUserList[kanriUserList.length - 1].id,
        });

        this.props.history.replace(
          path + "/kanri/user/" + kanriUserList[kanriUserList.length - 1].id
        );
      }
    }
  };

  recordMark = (type, id) => {
    this.props.recordMark("user", type, id);
  };

  render() {
    const { kanriUserList, userSelectedRows } = this.props;
    const { userId } = this.state;

    return (
      <KanriLayoutContainer router="user" breadcrumbLink={"/kanri/user"} breadcrumbText={"ユーザ管理"}>
        <ActionsRow
          key={userSelectedRows}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          selectedList={userSelectedRows}
          id={userId}
        />

        <Row>
          <WrappedUserForm
            data={kanriUserList.find((item) => item.id === userId)}
            history={this.props.history}
            regData={(values) => this.registerUser(values)}
            delete={this.delete}
          />
        </Row>
      </KanriLayoutContainer>
    );
  }
}

export default KanriUserSave;
