import { ItakuTantoMasterActionTypes } from "../../management/redux/action_types";
import {
  ITAKUTANTO_MASTER_SPIN_REQUEST,
  ITAKUTANTO_MASTER_LOAD_REQUEST,
  ITAKUTANTO_MASTER_CREATE_REQUEST,
  ITAKUTANTO_MASTER_UPDATE_REQUEST,
  ITAKUTANTO_MASTER_INIT_REQUEST,
  LOAD_ITAKUTANTO_MASTER_SUCCESS,
  LOAD_ITAKUTANTO_MASTER_ERROR,
  LOAD_MAX_SORT_SUCCESS,
  LOAD_MAX_SORT_ERROR,
  LOAD_ITAKUTANTO_MASTER_INIT_SUCCESS,
  LOAD_ITAKUTANTO_MASTER_INIT_ERROR,
  CREATE_ITAKUTANTO_MASTER_SUCCESS,
  CREATE_ITAKUTANTO_MASTER_ERROR,
  UPDATE_ITAKUTANTO_MASTER_SUCCESS,
  UPDATE_ITAKUTANTO_MASTER_ERROR,
} from "../../actions/itakuTantoMasterActions/actionName";

import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

function itakuTantoMaster(
  state = { loading: false, itakuTantoList: [] },
  action
) {
  switch (action.type) {
    case ITAKUTANTO_MASTER_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ITAKUTANTO_MASTER_LOAD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_ITAKUTANTO_MASTER_SUCCESS: {
      return {
        ...state,
        itakuTantoMaster: action.data,
        loading: false,
      };
    }
    case LOAD_ITAKUTANTO_MASTER_ERROR: {
      return {
        ...state,
        itakuTantoMaster: null,
        loading: false,
      };
    }
    case ITAKUTANTO_MASTER_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_ITAKUTANTO_MASTER_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        createItakuTanto: action.data,
        loading: false,
      };
    }
    case CREATE_ITAKUTANTO_MASTER_ERROR: {
      openNotificationWithIcon("errpr", "エラー");
      return {
        ...state,
        createItakuTanto: null,
        loading: false,
      };
    }
    case ITAKUTANTO_MASTER_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_ITAKUTANTO_MASTER_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateItakuTanto: action.data,
        loading: false,
      };
    }
    case UPDATE_ITAKUTANTO_MASTER_ERROR: {
      openNotificationWithIcon("errpr", "エラー");
      return {
        ...state,
        updateItakuTanto: null,
        loading: false,
      };
    }
    case ITAKUTANTO_MASTER_INIT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_ITAKUTANTO_MASTER_INIT_SUCCESS: {
      return {
        ...state,
        initItakuTanto: action.data,
        loading: false,
      };
    }
    case LOAD_ITAKUTANTO_MASTER_INIT_ERROR: {
      return {
        ...state,
        initItakuTanto: null,
        loading: false,
      };
    }

    case ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        itakuTantoMaster: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.GET_INITIAL_ITAKU_TANTO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_INITIAL_ITAKU_TANTO_SUCCESS: {
      return {
        ...state,
        loading: false,
        itakuTantoMaster: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_INITIAL_ITAKU_TANTO_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceSyuruiMasters: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceAdditions: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_FAILURE: {
      return {
        ...state,
        loading: false,
        serviceAdditions: [],
      };
    }
    case ItakuTantoMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        hojinInfoMasters: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        hojinInfoMasters: [],
      };
    }
    case ItakuTantoMasterActionTypes.CREATE_ITAKU_TANTO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.CREATE_ITAKU_TANTO_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        // createItakuTanto: action.data,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.CREATE_ITAKU_TANTO_FAILURE: {
      return {
        ...state,
        // createItakuTanto: null,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.UPDATE_ITAKU_TANTO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.UPDATE_ITAKU_TANTO_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        // updateItakuTanto: action.data,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.UPDATE_ITAKU_TANTO_FAILURE: {
      return {
        ...state,
        // updateItakuTanto: null,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.CHECK_LINKED_WEEKLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.CHECK_LINKED_WEEKLY_SUCCESS: {
      return {
        ...state,
        loading: false,
        linkedWeeklySchedule: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.CHECK_LINKED_WEEKLY_FAILURE: {
      return {
        ...state,
        loading: false,
        linkedWeeklySchedule: null,
      };
    }
    case ItakuTantoMasterActionTypes.GET_HOJIN_INFO_MASTER_REQUEST: {
      return {
        ...state,
      };
    }
    case ItakuTantoMasterActionTypes.GET_HOJIN_INFO_MASTER_SUCCESS: {
      return {
        ...state,
        hojinInfo: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_HOJIN_INFO_MASTER_FAILURE: {
      return {
        ...state,
        hojinInfo: null,
      };
    }
    case ItakuTantoMasterActionTypes.CREATE_HOJIN_INFO_MASTER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.CREATE_HOJIN_INFO_MASTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        hojinInfo: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.CREATE_HOJIN_INFO_MASTER_FAILURE: {
      return {
        ...state,
        loading: false,
        hojinInfo: null,
      };
    }
    case ItakuTantoMasterActionTypes.GET_LIST_SERVICE_MONTHLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_LIST_SERVICE_MONTHLY_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceMonthlyList: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_LIST_SERVICE_MONTHLY_FAILURE: {
      return {
        ...state,
        loading: false,
        serviceMonthlyList: [],
      };
    }
    case ItakuTantoMasterActionTypes.GET_TAISEI_MASTERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_TAISEI_MASTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        taiseiMasters: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_TAISEI_MASTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        taiseiMasters: null,
      };
    }
    case ItakuTantoMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_SUCCESS: {
      return {
        ...state,
        loading: false,
        tikibetuTikikubun: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_FAILURE: {
      return {
        ...state,
        loading: false,
        tikibetuTikikubun: null,
      };
    }
    case ItakuTantoMasterActionTypes.GET_TIKI_KUBUN_MASTER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_TIKI_KUBUN_MASTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        tikiKubunMaster: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_TIKI_KUBUN_MASTER_FAILURE: {
      return {
        ...state,
        loading: false,
        tikiKubunMaster: null,
      };
    }
    case ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SERVICE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SERVICE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        itakuTantoList: action.data,
      };
    case ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SERVICE_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        itakuTantoList: [],
      };
    case ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SYURUI_CD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ItakuTantoMasterActionTypes.CREATE_SERVICE_MONTHLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SYURUI_CD_SUCCESS:
      return {
        ...state,
        loading: false,
        itakuTantoList: action.data,
      };
    case ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SYURUI_CD_FAILURE:
      return {
        ...state,
        loading: false,
        itakuTantoList: [],
      };

    case ItakuTantoMasterActionTypes.CREATE_SERVICE_MONTHLY_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.CREATE_SERVICE_MONTHLY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.UPDATE_SERVICE_MONTHLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.UPDATE_SERVICE_MONTHLY_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.UPDATE_SERVICE_MONTHLY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.DELETE_SERVICE_MONTHLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.DELETE_SERVICE_MONTHLY_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.DELETE_SERVICE_MONTHLY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ItakuTantoMasterActionTypes.SAVE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.SAVE_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        isReload: true,
      };
    }
    case ItakuTantoMasterActionTypes.SAVE_FAILURE: {
      openNotificationWithIcon("error", action.error.message);
      console.log("error", action.error);
      return {
        ...state,
        loading: false,
        isReload: false,
      };
    }
    case ItakuTantoMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ItakuTantoMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_SUCCESS: {
      return {
        ...state,
        syuruiCds: action.data.map((e) => e.serviceSyuruiCd),
      };
    }
    case ItakuTantoMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_FAILURE: {
      return {
        ...state,
        syuruiCds: [],
      };
    }
    case ItakuTantoMasterActionTypes.GET_MAX_SORT_NUM_REQUEST: {
      return {
        ...state,
        loading: true,
        sortNumCheck: false,
      };
    }
    case ItakuTantoMasterActionTypes.GET_MAX_SORT_NUM_SUCCESS: {
      return {
        ...state,
        loading: false,
        sortNumCheck: true,
        itakuTantoSortNum: action.data,
      };
    }
    case ItakuTantoMasterActionTypes.GET_MAX_SORT_NUM_FAILURE: {
      return {
        ...state,
        loading: false,
        sortNumCheck: true,
        itakuTantoSortNum: null,
      };
    }
    case ItakuTantoMasterActionTypes.TURN_OFF_RELOAD_SERVICE_KIKAN:
      return {
        ...state,
        isReload: undefined,
      };
    default: {
      return { ...state };
    }
  }
}

export default itakuTantoMaster;
