import React, { Component } from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Divider, Select, Row } from "antd";
import _ from "lodash";

class FormAdlNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { kihonAdl, data, index } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {index && index > 0 ? (
          <Row style={{ padding: "8px 0px" }}>
            <Divider />
          </Row>
        ) : null}
        <Form.Item hidden={true}>
          {getFieldDecorator("id", {
            rules: [{ required: false }],
          })}
        </Form.Item>
        <Form.Item hidden={true}>
          {getFieldDecorator("kihonAdlMasterId", {
            rules: [{ required: false }],
          })}
        </Form.Item>
        <Form.Item label={kihonAdl ? kihonAdl.title : "ADL1"} colon={false}>
          {getFieldDecorator("answer", {
            rules: [{ required: false }],
          })(
            <Select style={{ width: 200 }}>
              <Select.Option key={-1} value={""}>
                &nbsp;
              </Select.Option>
              {kihonAdl
                ? kihonAdl.answerItem
                    .toString()
                    .split(";")
                    .map((item, intex) => {
                      return (
                        <Select.Option key={intex} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })
                : null}
            </Select>
          )}
        </Form.Item>
      </Form>
    );
  }
}

export const FormAdl = Form.create({
  name: "input-riyou-adl",
  onValuesChange(props, changedValues, allValues) {
    props.saveOnchangeToState(allValues, "fAdl", props.index);
  },
  mapPropsToFields(props) {
    let id = "";
    let answer = "";

    if (props.faceAdl) {
      const faceAdl = props.faceAdl
        .filter(function (item) {
          return (
            item.kihonAdlMaster && item.kihonAdlMaster.id == props.kihonAdl.id
          );
        })
        .shift();
      if (faceAdl) {
        id = faceAdl.id;
        answer = faceAdl.answer;
      }
    }

    if (props.data && (props.data.answer || props.data.answer == "")) {
      answer = props.data.answer;
    }

    return {
      id: Form.createFormField({
        value: id ? id : 0,
      }),
      kihonAdlMasterId: Form.createFormField({
        value: props.kihonAdl && props.kihonAdl.id ? props.kihonAdl.id : 0,
      }),
      answer: Form.createFormField({
        value: answer,
      }),
    };
  },
})(FormAdlNormal);
