import { YougoMasterActionTypes, ErrorActionTypes } from "../action_types";

// constants
import { ApiPaths } from "../../constants";

// utils
import { RequestUtils, StorageUtils } from "../../utils";
import { ConfigName } from "../../../config-name";
import { YougoMasterNaiyouApi } from "../../reuse/api/yougoMasterNaiyou";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
import { apiUrls } from "../../../common/configs/common-url";

export function getYougoMaster(listName) {
  return (dispatch) => {
    if (listName) {
      // by listName
      YougoMasterNaiyouApi.yougoMasterNaiyou(listName ? listName : "").then(
        (data) =>
          dispatch({
            type: YougoMasterActionTypes.GET_YOUGO_MASTER_SUCCESS,
            naiyou: data ? data.naiyou : null,
          }),
        (err) => {
          checkError(err);
          dispatch({
            type: YougoMasterActionTypes.LOAD_YOUGO_ATTRIBUTE_ERROR,
            error:
              err.response.status === 400
                ? err.response.data.error
                : { message: err.response.statusText },
          });
        }
      );
    } else {
      YougoMasterNaiyouApi.yougoMasterNaiyou("自立度").then(
        (data) =>
          dispatch({
            type: YougoMasterActionTypes.GET_YOUGO_MASTER_BUNRUI1,
            naiyou: data ? data.naiyou : null,
          }),
        (err) => {
          checkError(err);
          dispatch({
            type: YougoMasterActionTypes.LOAD_YOUGO_ATTRIBUTE_ERROR,
            error:
              err.response.status === 400
                ? err.response.data.error
                : { message: err.response.statusText },
          });
        }
      );

      YougoMasterNaiyouApi.yougoMasterNaiyou("認知度").then(
        (data) =>
          dispatch({
            type: YougoMasterActionTypes.GET_YOUGO_MASTER_BUNRUI2,
            naiyou: data ? data.naiyou : null,
          }),
        (err) => {
          checkError(err);
          dispatch({
            type: YougoMasterActionTypes.LOAD_YOUGO_ATTRIBUTE_ERROR,
            error:
              err.response.status === 400
                ? err.response.data.error
                : { message: err.response.statusText },
          });
        }
      );

      YougoMasterNaiyouApi.yougoMasterNaiyou("本人の希望").then(
        (data) =>
          dispatch({
            type: YougoMasterActionTypes.GET_YOUGO_MASTER_BUNRUI3,
            naiyou: data ? data.naiyou : null,
          }),
        (err) => {
          checkError(err);
          dispatch({
            type: YougoMasterActionTypes.LOAD_YOUGO_ATTRIBUTE_ERROR,
            error:
              err.response.status === 400
                ? err.response.data.error
                : { message: err.response.statusText },
          });
        }
      );
    }
  };
}

export function saveYougoMaster(listName, naiyou) {
  return (dispatch) => {
    YougoMasterNaiyouApi.updateYougoMasterApi(listName, naiyou).then(
      (data) =>
        dispatch({
          type: YougoMasterActionTypes.SAVE_YOUGO_MASTER_SUCCESS,
          naiyou: data.naiyou,
        }),
      (err) => {
        if (
          err.response.status === 400 &&
          err.response.data.error.code === "YOUGOMASTER_NOT_FOUND"
        ) {
          YougoMasterNaiyouApi.createYougoMaster(listName, naiyou).then(
            (data) =>
              dispatch({
                type: YougoMasterActionTypes.SAVE_YOUGO_MASTER_SUCCESS,
                naiyou: data.naiyou,
              }),
            (err2) => {
              checkError(err2);
              dispatch({
                type: YougoMasterActionTypes.SAVE_YOUGO_MASTER_ERROR,
                error:
                  err2.response.status === 400
                    ? err2.response.data.error
                    : { message: err2.response.statusText },
              });
            }
          );
        } else {
          checkError(err);
          dispatch({
            type: YougoMasterActionTypes.SAVE_YOUGO_MASTER_ERROR,
            error:
              err.response.status === 400
                ? err.response.data.error
                : { message: err.response.statusText },
          });
        }
      }
    );
  };
}

export function getYougoMasterNew(listName, callback) {
  return async (dispatch) => {
    const data = await RequestUtils.callApi({
      method: "GET",
      path: `${
        apiUrls.INIT + apiUrls.GET_YOUGO_MASTER_NAIYOU
      }?listName=${listName}`,
    });

    return callback(data);
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/life/login");
  }
}
