import { Form } from "@ant-design/compatible";
import { Button, Col, DatePicker, Input, Modal, Row } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { addMonitoringHyoka, changeExpandedRowKey, getResultMonitoring, getRowInfolMonitoring, removeMonitoringHyoka, updateMonitoringHyoka, updateOptionOfMonitoring } from "../../../../actions/monitoringActions/actionCreators";
import TantoInput from "../../../../common/component/control/TantoInput";
import { ConstSet } from "../../../../common/configs/constset";
import CareTermDictModal from "../../UI/CareTermDictModal";
dayjs.extend(dayjsPluginUTC);

const optionsOfAttainment = [
  "1.目標達成",
  "2.一部達成",
  "3.達成していない",
  "4.その他",
];
const optionsOfCondition = [
  "1.実施されている",
  "2.実施されてないことがある",
  "3.実施されていない",
  "4.その他",
];
const optionsOfSatisPerson = [
  "1.満足している",
  "2.ある程度満足している",
  "3.満足していない",
  "4.その他",
];
const optionsOfSatisFamily = [
  "1.満足している",
  "2.ある程度満足している",
  "3.満足していない",
  "4.その他",
];
const optionsOfCope = [
  "1.プラン継続",
  "2.プラン一部変更",
  "3.プラン変更",
  "4.プラン中止",
  "5.その他",
];
class editTable2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monitoringHyokas: props.monitoringHyokas,
      tab: [],
      deleteVisible: false,
      checked: false,
      expandedRowKeys: [],
      edit: false,
      recordContent: null,
      careTermVisible: false,
      confirmModalVisible: false,
      selectedRowIndex: null,
    };
  }
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.monitoringHyokas !== this.props.monitoringHyokas) {
      this.setState({
        monitoringHyokas: nextProps.monitoringHyokas,
      });
    }
  }
  handleExpand(expanded, record) {
    if (record) {
      this.props.getResultMonitoring(record.results);
      this.props.getRowInfolMonitoring(record);
      expanded
        ? this.props.changeExpandedRowKey(record.sortNum)
        : this.props.changeExpandedRowKey(null);
    }
    else {
      this.setState({
        expandedRowKeys: null,
        recordContent: null,
      });
    }
  }
  handleClickOption(number) {
    // const { monitoringRows, expandedRowKeys, rowInformation } = this.props;
    const attainmentNew = optionsOfAttainment[number - 1];
    const conditionNew = optionsOfCondition[number - 1];
    const satisPersonNew = optionsOfSatisPerson[number - 1];
    const satisFamilyNew = optionsOfSatisFamily[number - 1];
    const copeNew = optionsOfCope[number - 1];
    this.props.updateOptionOfMonitoring(
      attainmentNew,
      conditionNew,
      satisPersonNew,
      satisFamilyNew,
      copeNew
    );
  }
  onChangeHyokaDate(value, index) {
    const monitoringHyokasNew = [...this.state.monitoringHyokas];
    monitoringHyokasNew[index]["hyokaDate"] = value;
    this.setState({
      monitoringHyokas: monitoringHyokasNew,
    });
    this.props.updateMonitoringHyoka(monitoringHyokasNew);
    this.triggerDataChange();
  }
  onChangHyokaTanto(value, index) {
    const monitoringHyokasNew = [...this.state.monitoringHyokas];
    monitoringHyokasNew[index]["hyokaTantoId"] = value;
    this.setState({
      monitoringHyokas: monitoringHyokasNew,
    });
    this.props.updateMonitoringHyoka(monitoringHyokasNew);
    this.triggerDataChange();
  }
  onChangHyoka(e, index) {
    const monitoringHyokasNew = [...this.state.monitoringHyokas];
    monitoringHyokasNew[index]["hyoka"] = e.target.value;
    this.setState({
      monitoringHyokas: monitoringHyokasNew,
    });
    this.props.updateMonitoringHyoka(monitoringHyokasNew);
    this.triggerDataChange();
  }
  addNewMonitoringHyoka() {
    this.props.addMonitoringHyoka();
    this.triggerDataChange();
  }

  removeMonitoringHyoka(index) {
    this.setState({
      confirmModalVisible: true,
      selectedRowIndex: index,
    });
  }

  showModalCareTerm(field, linkingCareTerm, hyokaIndex) {
    this.setState({
      careTermVisible: true,
      field,
      linkingCareTerm,
      hyokaIndex,
    });
  }
  handleSelectCareTerm(careTerm) {
    if (careTerm) {
      const { monitoringHyokas, hyokaIndex } = this.state;

      if (Array.isArray(monitoringHyokas)) {
        const selectedHyoka = monitoringHyokas[hyokaIndex];
        const hyoka = selectedHyoka.hyoka;
        const result = _.isEmpty(hyoka) ? careTerm : hyoka + "、" + careTerm;

        monitoringHyokas[hyokaIndex].hyoka = result;
        this.setState({ monitoringHyokas });
      }
    }
    this.setState({
      careTermVisible: false,
      hyokaIndex: null,
    });
    this.triggerDataChange();
  }
  hideModalCareTerm() {
    this.setState({
      careTermVisible: false,
      hyokaIndex: null,
    });
  }

  triggerDataChange() {
    if (this.props.onChangeData) {
      this.props.onChangeData();
    }
  }

  handleOkDelete = () => {
    this.triggerDataChange();
    if (this.state.selectedRowIndex !== null) {
      this.props.removeMonitoringHyoka(this.state.selectedRowIndex);
    }
    this.handleCloseDialog();
  };

  handleCloseDialog = () => {
    this.setState({
      confirmModalVisible: false,
      selectedRowIndex: null,
    });
  };

  render() {
    // const { monitoringRows, expandedRowKeys, rowInformation } = this.props;
    const { monitoringHyokas, confirmModalVisible } = this.state;
    let tableData = [];
    if (monitoringHyokas) {
      tableData = monitoringHyokas.map((info, index) => {
        // content: info.id
        return (
          <div key={index} className="monitoring-hyoka-info">
            <Row>
              <Col
                sx={24}
                ms={24}
                md={24}
                className="header-table-tab42 text-bold monitoring-hyoka-info__title"
              >
                {`No${index + 1}`}
              </Col>
            </Row>
            <Row
              style={{ marginBottom: 10 }}
              className="monitoring-hyoka-info__content"
            >
              <Col xs={24} md={7} className="monitoring-hyoka-info__date">
                <Form.Item
                  style={{ display: "flex" }}
                  label={"評価日"}
                  colon={false}
                >
                  <DatePicker
                    onChange={(value) => this.onChangeHyokaDate(value, index)}
                    value={dayjs.utc(info.hyokaDate, ConstSet.DATE_FORMAT)}
                    style={{ width: "120px" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} className="monitoring-hyoka-info__evaluator">
                <Form.Item
                  style={{ display: "flex" }}
                  label={"評価担当者"}
                  colon={false}
                >
                  <TantoInput
                    onChange={(value) => this.onChangHyokaTanto(value, index)}
                    value={info.hyokaTantoId}
                    containerId={"input-monitoring-page"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="monitoring-hyoka-info__content">
              <Col sx={24} ms={24} span={24}>
                <div style={{ marginBottom: 16 }}>
                  <label>評価内容</label>
                  <Button
                    size={"small"}
                    type={"primary"}
                    className={"edit-button"}
                    onClick={() =>
                      this.showModalCareTerm("hyoka", "評価", index)
                    }
                  >
                    {"用語"}
                  </Button>
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="edit-button"
                    style={{ marginRight: 5 }}
                    onClick={() => this.removeMonitoringHyoka(index)}
                  >
                    削除
                  </Button>
                </div>
                <Input.TextArea
                  onChange={(e) => this.onChangHyoka(e, index)}
                  style={{ width: "100%" }}
                  value={info.hyoka}
                  autoSize={{ minRows: 3 }}
                ></Input.TextArea>
              </Col>
            </Row>
          </div>
        );
      });
    }
    return (
      (<div
        id={"table-monitoring"}
        className={this.props.mode === "mobile" ? "table-monitoring-m" : ""}
      >
        <div className="monitoring-hyoka-title" style={{ marginTop: 16 }}>
          <label>個別評価</label>
          <Button
            type="primary" danger
            className={"edit-button"}
            onClick={() => this.addNewMonitoringHyoka()}
          >
            {"評価追加"}
          </Button>
        </div>
        {tableData}
        <CareTermDictModal
          visible={this.state.careTermVisible}
          linkingCareTerm={this.state.linkingCareTerm}
          handleSelectCareTerm={(data) => this.handleSelectCareTerm(data)}
          hideModalCareTerm={() => this.hideModalCareTerm()}
        />
        <Modal
          open={confirmModalVisible}
          onOk={this.handleOkDelete}
          onCancel={this.handleCloseDialog}
        >
          <p>この行を削除しますか？</p>
        </Modal>
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    monitoringRows: state.monitoring.monitoringRows,
    monitoringHyokas: state.monitoring.monitoringHyokas,
    rowInformation: state.monitoring.rowInformation,
    expandedRowKeys: state.monitoring.expandedRowKeys,
    // editDetail: state.kyotakuPlan2.editDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getResultMonitoring: (data) => dispatch(getResultMonitoring(data)),
    getRowInfolMonitoring: (data) => dispatch(getRowInfolMonitoring(data)),
    changeExpandedRowKey: (data) => dispatch(changeExpandedRowKey(data)),
    updateOptionOfMonitoring: (
      attainment,
      condition,
      satisPerson,
      satisFamily,
      cope,
      values
    ) =>
      dispatch(
        updateOptionOfMonitoring(
          attainment,
          condition,
          satisPerson,
          satisFamily,
          cope,
          values
        )
      ),
    updateMonitoringHyoka: (data) => dispatch(updateMonitoringHyoka(data)),
    addMonitoringHyoka: () => dispatch(addMonitoringHyoka()),
    removeMonitoringHyoka: (index) => dispatch(removeMonitoringHyoka(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(editTable2);
