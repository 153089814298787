/* eslint-disable no-nested-ternary */
import React from "react";

import { Button, Col, Input, Row } from "antd";
import { PopoverKaigoNaiyou } from "../../components/documentList/DailyPlan/PopoverKaigoNaiyou";

export default class SelectKaigoNaiyou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visiblePopover: false,
      kaigoNaiyou: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.handleChangeSelect(value);
  }

  disPopoverKaigo = () => {
    this.setState({
      visiblePopover: true
    });
  }

  hanleVisible = (visible) => {
    // const { data } = this.props;
    // console.log(data);
    this.setState({
      visiblePopover: visible,
    });
  };
  handleClose = () => {
    // this.search();

    this.setState({
      visiblePopover: false,
    });
  }

  selectRecordKaigo = (rc) => {

    this.props.handleChangeSelect(rc.kaigoNaiyouCode);

    this.setState({
      kaigoNaiyou: rc.kaigoNaiyou,
      visiblePopover: false,
    });
  }

  render() {
    const { kaigoNaiyou } = this.state;
    const { value } = this.props;
    return (
      <Row style={{ marginBottom: 8 }}>
        <Col className="kdf-label" span={4}></Col>
        <Col className="kdf-label" span={16}>
          <Input value={kaigoNaiyou ? kaigoNaiyou : (value ? value : null)} style={{ borderRadius: 4 }} />
        </Col>
        <Col className="kdf-label" span={4}>
          <Button type="primary" style={{ margin: "0 10px" }} onClick={this.disPopoverKaigo}>選択</Button>
          <PopoverKaigoNaiyou
            visiblePopover={this.state.visiblePopover}
            handleVisible={(visible) => this.hanleVisible(visible)}
            selectRecordKaigo={(rc) => this.selectRecordKaigo(rc)}
            handleClose={() => this.handleClose()}
          />
        </Col>
      </Row>
    );
  }
}
