import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const SisetuDailyPlanApi = {
  async loadSisetuDailyPlan(params) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.GET_SISETU_DAILY_PLAN_LIST, {
        params: {
          riyousyaId: params.riyousyaId,
          sid: menu.si,
          systemName: menu.sn,
          inUsed: params.inUsed,
          searchName: params.searchName,
          sort: params.sort,
          page: params.page,
        },
      });
    return res.data;
  },
  async getSisetuDailyPlanById(sisetuDailyPlanId) {
    const res = await axios
      .get(apiUrls.GET_SISETU_DAILY_PLAN_BY_ID, {
        params: {
          id: sisetuDailyPlanId,
        },
      });
    return res.data;
  },
  async saveSisetuDailyPlan(data, riyousyaId, planId) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .post(apiUrls.SAVE_SISETU_DAILY_PLAN, {
        ...data,
        riyousya_id: riyousyaId ? parseInt(riyousyaId, 10) : null,
        id: planId ? parseInt(planId, 10) : null,
        service_kikan_master_id: menu && menu.si ? menu.si : null, // server requires String value ???
      });
    return res.data;
  },
  async deleteSisetuDailyPlan(sisetuDailyPlanId) {
    const res = await axios
      .delete(apiUrls.DELETE_SISETU_DAILY_PLAN, {
        params: {
          id: sisetuDailyPlanId,
        },
      });
    return res.data;
  },
  async saveKaigoServiceTemplate(args) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .post(apiUrls.SAVE_SISETU_DAILY_KAIGO_SERVICE_TEMPLATE, {
        temp_name: args.templateName,
        service_kikan_master_id: menu && menu.si ? menu.si : null,
        daily_plan_details: args["daily_plan_details"],
      });
    return res.data;
  },
  async getKaigoServiceTemplateName() {
    const res = await axios
      .get(apiUrls.GET_SISETU_DAILY_KAIGO_SERVICE_TEMPLATE_NAME, {});
    return res.data;
  },
  async getKaigoServiceTemplate(templateId) {
    const res = await axios
      .get(apiUrls.GET_SISETU_DAILY_KAIGO_SERVICE_TEMPLATE, {
        params: {
          id: templateId ? parseInt(templateId, 10) : null,
        },
      });
    return res.data;
  },
  async saveActTemplate(args) {
    const res = await axios
      .post(apiUrls.SAVE_SISETU_DAILY_ACT_TEMPLATE, {
        daily_plan_details: args["daily_plan_details"],
      });
    return res.data;
  },
  async getActTemplate() {
    const res = await axios
      .get(apiUrls.GET_SISETU_DAILY_ACT_TEMPLATE);
    return res.data;
  },
};

export { SisetuDailyPlanApi };
