import React from "react";
import { EnterOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Col, Row, InputNumber, Checkbox, Radio, Button, Popover, Divider } from "antd";

// import { convertMessageByCode } from '../../../common/function_common/functionCommon'
import {
  handleInputNumber,
  getValueIskeep,
} from "../../../common/function_common/functionCommon";

import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";

import SelectIntakeKB from "../../../common/model-kiroku/SelectIntakeKB";

const RadioGroup = Radio.Group;
class NormalFormMeal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif ? true : false,
      shokuNasi: this.props.data.shokuNasi ? true : false,
      type: null,
      extendValue: false,
      visibleNoKeyboard: false,
    };

    this.onChangeKubun = this.onChangeKubun.bind(this);
    this.onChangeStapleIntake = this.onChangeStapleIntake.bind(this);
    this.onChangeSideIntake = this.onChangeSideIntake.bind(this);
    this.onChangeShokuNasi = this.onChangeShokuNasi.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
    this.handleType = this.handleType.bind(this);
  }

  componentDidUpdate(newProps) {
    // const oldProps = this.props

    //@TODO CHECK
    this.props.form.resetFields();
    // if (oldProps.data !== newProps.data) {
    //     this.props.form.resetFields();
    // }
  }

  onChangeKubun(e) {
    this.props.handleFormInput(e.target.value, "meal", "kubunMeal");
  }

  onChangeStapleIntake(value) {
    this.props.handleFormInput(value, "meal", "stapleIntake");
  }

  onChangeSideIntake(value) {
    this.props.handleFormInput(value, "meal", "sideIntake");
  }

  onChangeShokuNasi(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "meal", "shokuNasi");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "meal", "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "meal", "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "meal", "tantoId");
  }

  handleInput(value) {
    let checkValue = handleInputNumber(value, this.state.value, true);

    this.props.handleFormInput(checkValue, "meal", this.state.type);

    this.setState({
      value: checkValue,
    });
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index, 2);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index, 2);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou, 2);
  }

  handleType(type) {
    this.setState({
      type: type,
      value: null,
      visibleNoKeyboard: true,
    });
  }

  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  onVisibleChangePopover = () => {
    const { visibleNoKeyboard } = this.state;

    if (visibleNoKeyboard) {
      this.setState({
        visibleNoKeyboard: false,
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { tantoMaster, data, yougoMasterNa, disabled } = this.props;

    const { visibleNoKeyboard, type } = this.state;

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            <Button onClick={() => this.handleInput(".")}>.</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
          <Row>
            <Button
              onClick={this.handleEnter}
              style={{ padding: "0px", height: 150 }}
            >
              <EnterOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 21 },
      },
    };

    const formItemLayoutSmall = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <Form
        layout="vertical"
        {...formItemLayout}
        // onSubmit={this.handleSubmit}
      >
        <Divider orientation="left">
          {
            <RadioGroup
              disabled={disabled}
              value={data.kubunMeal}
              onChange={this.onChangeKubun}
            >
              <Radio value={"朝"}>朝</Radio>
              <Radio value={"昼"}>昼</Radio>
              <Radio value={"夕"}>夕</Radio>
              <Radio value={"間食"}>間食</Radio>
              <Radio value={"経管栄養"}>経管栄養</Radio>
            </RadioGroup>
          }
        </Divider>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>担当者 :</div>
          </Col>

          <Col sm={18} md={21}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>摂取量 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <Row gutter={16}>
                {(data.kubunMeal && data.kubunMeal === "経管栄養") ||
                getValueIskeep("mealType") === "経管栄養" ? (
                  <Col span={10}>
                    <Form.Item {...formItemLayoutSmall}>
                      <span className="rwf-title">主</span>
                      {getFieldDecorator("stapleIntake", {
                        rules: [{ required: false }],
                        initialValue: data.stapleIntake,
                      })(
                        <InputNumber
                          disabled={disabled}
                          min={0}
                          max={100000}
                          onChange={this.onChangeStapleIntake}
                        />
                      )}
                      {disabled ? null : (
                        <Popover
                          visible={
                            visibleNoKeyboard && type === "stapleIntake"
                              ? true
                              : false
                          }
                          placement="bottomRight"
                          content={numberKB}
                          trigger="click"
                          type="temperature"
                          onVisibleChange={this.onVisibleChangePopover}
                        >
                          <Button
                            onClick={() => this.handleType("stapleIntake")}
                            type="primary"
                          >
                            入力
                          </Button>
                        </Popover>
                      )}
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={10}>
                    <Form.Item {...formItemLayoutSmall}>
                      <span className="rwf-title rwf-fix-small-title">主</span>
                      <SelectIntakeKB
                        handleInputNum={this.onChangeStapleIntake}
                        value={data.stapleIntake}
                        inputWidth={"100%"}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                )}
                {(data.kubunMeal && data.kubunMeal === "経管栄養") ||
                getValueIskeep("mealType") === "経管栄養" ? (
                  <Col span={10}>
                    <Form.Item {...formItemLayoutSmall}>
                      <span className="rwf-title">副</span>
                      {getFieldDecorator("sideIntake", {
                        rules: [{ required: false }],
                        initialValue: data.sideIntake,
                      })(
                        <InputNumber
                          disabled={disabled}
                          min={0}
                          max={100000}
                          onChange={this.onChangeSideIntake}
                        />
                      )}
                      {disabled ? null : (
                        <Popover
                          visible={
                            visibleNoKeyboard && type === "sideIntake"
                              ? true
                              : false
                          }
                          placement="bottomRight"
                          content={numberKB}
                          trigger="click"
                          type="temperature"
                          onVisibleChange={this.onVisibleChangePopover}
                        >
                          <Button
                            onClick={() => this.handleType("sideIntake")}
                            type="primary"
                          >
                            入力
                          </Button>
                        </Popover>
                      )}
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={10}>
                    <Form.Item {...formItemLayoutSmall}>
                      <span className="rwf-title rwf-fix-small-title">副</span>
                      <SelectIntakeKB
                        handleInputNum={this.onChangeSideIntake}
                        value={data.sideIntake}
                        inputWidth={"100%"}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={4}>
                  {getFieldDecorator("shokuNasi", {
                    rules: [{ required: false }],
                    initialValue: data.shokuNasi ? data.shokuNasi : "",
                  })(
                    <Checkbox
                      disabled={disabled}
                      checked={data.shokuNasi ? true : false}
                      onChange={this.onChangeShokuNasi}
                    >
                      食無し
                    </Checkbox>
                  )}
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>コメント :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={2}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const FormMeal = Form.create()(NormalFormMeal);
export default FormMeal;
