/* eslint-disable no-nested-ternary */
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Button, Checkbox, Col, Input, Row } from "antd";
import React from "react";

// import { convertMessageByCode } from '../../../common/function_common/functionCommon'

import { PopoverKaigoNaiyou } from "../../components/documentList/DailyPlan/PopoverKaigoNaiyou";
import TimeKBCus from "../component/TimeKBCus";
import CommentKB from "../model-kiroku/CommentKB";
import TantoMasterKB from "../model-kiroku/TantoMasterKB";

class NormalFukuyakuForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: !!this.props.data.reportCaseTyuif,
      type: null,
      extendValue: false,
      visibleNoKeyboard: false,
      visiblePopover: false,
      kaigoNaiyou: null,
    };

    this.onChangeTime = this.onChangeTime.bind(this);
    this.onClickRctf = this.onClickRctf.bind(this);

    this.onChangeKaigoNaiyou = this.onChangeKaigoNaiyou.bind(this);

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.data !== newProps.data) {
      this.setState({
        reportCaseTyuif: !!newProps.data.reportCaseTyuif,
      });

      this.props.form.resetFields();
    }
  }

  onChangeTime(time, timeString) {
    this.props.handleFormInput(timeString, "time");
  }

  onClickRctf(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "reportCaseTyuif");
    this.setState({
      reportCaseTyuif: e.target.checked,
    });
  }

  onChangeKaigoNaiyou(value) {
    this.props.handleFormInput(value, "kaigo_naiyou_code");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "tantoId");
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulModeNew = (timeString) => {
    this.props.handleFormInput(timeString, "time");
  };

  /**
   * getCurrentTime
   *
   */
  handleSetCurrentTime = () => {
    const currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.props.handleFormInput(currentTime, "time");
  };

  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };


  disPopoverKaigo = () => {
    this.setState({
      visiblePopover: true
    });
  }

  hanleVisible = (visible) => {
    this.setState({
      visiblePopover: visible,
    });
  };
  handleClose = () => {
    // this.search();

    this.setState({
      visiblePopover: false,
    });
  }

  selectRecordKaigo = (rc) => {
    // const { rowIndex, dataDaily } = this.state;
    // const dataClone = [...dataDaily];

    // dataClone[rowIndex].name = rc.kaigoNaiyou;
    // dataClone[rowIndex].code = rc.kaigoNaiyouCode;

    this.props.handleFormInput(rc.kaigoNaiyouCode, "kaigo_naiyou_code");

    this.setState({
      // dataDaily: dataClone,
      kaigoNaiyou: rc.kaigoNaiyou,
      visiblePopover: false,
    });
  }

  render() {
    const { kaigoNaiyou } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { tantoMaster, data, yougoMasterNa } = this.props;

    // console.log(data);

    return (
      <Form layout="vertical" hideRequiredMark>
        <Row>
          <Col className="kdf-label" span={4}>
            <span>時間</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item className="form-item-300">
              <TimeKBCus
                timeString={data && data.time ? data.time : null}
                handleInput={this.onChangeTimeMulModeNew}
              />
              <Button
                className="kdf-number-k"
                type="primary"
                onClick={this.handleSetCurrentTime}
              >
                現時刻
              </Button>
            </Form.Item>
            <Form.Item className="form-item-100">
              {getFieldDecorator("reportCaseTyuif", {
                rules: [{ required: false }],
              })(
                <Checkbox
                  className="kdf-f-extent"
                  checked={!!(data && data.reportCaseTyuif)}
                  onChange={this.onClickRctf}
                >
                  申送
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginBottom: 8 }}>
          <Col className="kdf-label" span={4}>
            <span>提供内容</span>
          </Col>
          <Col className="kdf-label" span={8}>
            <Input value={kaigoNaiyou ? kaigoNaiyou : (data.kaigoNaiyou ? data.kaigoNaiyou : null)} style={{ borderRadius: 4 }} />
          </Col>
          <Col className="kdf-label" span={4}>
            <Button type="primary" style={{ margin: "0 10px" }} onClick={this.disPopoverKaigo}>選択</Button>
            <PopoverKaigoNaiyou
              visiblePopover={this.state.visiblePopover}
              handleVisible={(visible) => this.hanleVisible(visible)}
              selectRecordKaigo={(rc) => this.selectRecordKaigo(rc)}
              handleClose={() => this.handleClose()}
            />
          </Col>
          {/* <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <SelectMul
                value={data.kaigo_naiyou_code ? data.kaigo_naiyou_code : null}
                handleChangeSelect={this.onChangeKaigoNaiyou}
                data={teikyouData}
                styleCus="left"
              />
            </Form.Item>
          </Col> */}
        </Row>
        <Row>
          <Col className="kdf-label" span={4}>
            <span>コメント</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={4}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="kdf-label" span={4}>
            <span>担当者</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const FukuyakuForm = Form.create()(NormalFukuyakuForm);
export default FukuyakuForm;
