/* eslint-disable indent */
import React, { Component, Fragment } from "react";
import { DownloadOutlined, LinkOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button } from "antd";
import FileViewer from "react-file-viewer";
// import '../Kiroku.css'

function DefaultFileView(props) {
  return (
    <div className="kiroku-drawer__content">
      <img className="kiroku-drawer__thumb-icon" alt="" src={props.thumbnail} />
    </div>
  );
}

class PopupUploadFile extends Component {
  zoom(type) {
    if (["png", "jpg", "jpeg", "bmp", "gif"].includes(type)) {
      const content = document.querySelector(".kiroku-drawer__content");

      if (content) {
        content.classList.toggle("kiroku-drawer__content--zoom-image");
      }
    }
  }

  renderContent(record, filePath) {
    return (
      <div
        className="kiroku-drawer__content"
        onClick={() =>
          filePath ? this.zoom(filePath.type.split("/")[1]) : null
        }
      >
        {filePath ? (
          <FileViewer
            fileType={filePath.type.split("/")[1] || ""}
            filePath={URL.createObjectURL(filePath)}
            errorComponent={DefaultFileView}
            unsupportedComponent={DefaultFileView}
            thumbnail={record ? record.thumbnail : null}
            key="fileviewer"
          />
        ) : (
          <DefaultFileView thumbnail={record ? record.thumbnail : null} />
        )}
      </div>
    );
  }

  render() {
    const { record, filePreview, download, onCancel } = this.props;
    return (
      <Fragment>
        {/* Title */}
        <div className="kiroku-drawer__title">
          <LinkOutlined style={{ float: "left", marginRight: 8 }} />
          <span>
            {record && record.fileName
              ? `${record.fileName}`
              : record && record.file_name
              ? record.file_name
              : ""}
          </span>
        </div>

        {/* Content */}
        {this.renderContent(record, filePreview)}
        {/* Footer */}
        <div className="kiroku-drawer__footer">
          {record && record.isDownloadable ? (
            <Button
              type="primary"
              onClick={() => download(record)}
              style={{ color: "white" }}
            >
              <DownloadOutlined style={{ fontSize: "16px" }} />
              取得
            </Button>
          ) : null}

          <Button onClick={onCancel}>
            <RollbackOutlined style={{ fontSize: "16px" }} />
            戻る
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default PopupUploadFile;
