/* eslint-disable react/prop-types */
import { Form } from "@ant-design/compatible";
import { EnterOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, InputNumber, Modal, Popover, Row } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import {
  convert_wareki_3,
  handleInputNumber,
} from "../../../common/function_common/functionCommon";
import JPDateSelectPicker from "./JPDateSelectPicker"; // 日付
import SelectOptionsFormNew from "./SelectOptionsFormNew"; // 要介護
import SelectOptionsFormNew1 from "./SelectOptionsFormNew1"; // 作成区分
import TextAreaSelectNew from "./TextAreaSelectNew"; // 保険者番号右枠
import TextAreaSelectNewSKM from "./TextAreaSelectNewSKM"; // 包括・居宅右枠
import TextInputArea from "./TextInputArea"; // 備考
dayjs.extend(dayjsPluginUTC);

class HokenTableForm1Normal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visibleCaution: false,
      type: null,
      index: null,
      kubunsikyu: true, // 区分支給限度額入力
      visibleNoKeyboard: false, // テンキーipadのみ
      keyType: "", // テンキータイプ
      value: "", // テンキーの値
      serviceType: "43",
      // updateVisible: false,
    };
  }

  componentDidMount() {
    // this.props.nameStateOff()
  }

  componentDidUpdate() { }

  cautionModalOn = () => {
    this.setState({
      visibleCaution: true,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  // 保険右枠onChange 不要
  updateChangeHoken = (value) => {
    const { form } = this.props;

    form.setFieldsValue({
      hokenName: value.split("-")[0],
      hokensyaNo: value.split("-")[1] ? value.split("-")[1] : "",
      kyufu: value.split("-")[2] ? value.split("-")[2] : "",
    });
  };

  // 包活・居宅マスタOK時
  updateChangeKyotaku = (value) => {
    // value = "事業所名-事業所番号-id"
    const { form } = this.props;

    form.setFieldsValue({
      stapleFood: value.split("-")[0],
      kyotakuNo: value.split("-")[1] ? value.split("-")[1] : "",
      serviceKikanMasterId: value.split("-")[2] ? value.split("-")[2] : null,
    });
  };
  // 要介護度変更時
  changeKaigodo = (value) => {
    if (
      value == "21" ||
      value == "22" ||
      value == "23" ||
      value == "24" ||
      value == "25" ||
      value == "01" ||
      value == ""
    ) {
      this.setState({
        serviceType: "43",
      });
    }
    else if (
      value == "06" ||
      value == "11" ||
      value == "12" ||
      value == "13"
    ) {
      this.setState({
        serviceType: "46",
      });
    }
  };

  // テンキー入力(区分支給)
  kubunsikyuInput = (mobile, type) => {
    if (mobile) {
      // モバイルはtrue テンキー呼び出し
      this.setState({
        value: null, // 値
        visibleNoKeyboard: true,
        keyType: type,
      });
      if (type === "kubunsikyu") {
        this.setState({
          kubunsikyu: false, // 隠す
        });
      }
    }
    else {
      this.setState({
        kubunsikyu: false,
        value: null,
      });
    }
    // document.getElementById("myTextField").focus();
    // this.id['myTextField'].focus();
  };
  // inputクリック時(テンキー呼び出し)
  keyInput = (type) => {
    this.setState({
      value: null, // 値
      visibleNoKeyboard: true,
      keyType: type,
    });
  };
  // テンキー入力
  handleInput(value) {
    const { keyType } = this.state;
    const { form } = this.props;

    value = value ? value : "0";
    const checkValue = handleInputNumber(value, this.state.value, true);
    // (旧)ここで親に値を渡して、Object.assignでDataを複製して渡している typeごとに追加されていく
    // this.props.handleFormInput(checkValue, this.state.keyType)
    // 直接fieldを変更
    form.setFieldsValue({
      [keyType]: checkValue,
    });

    this.setState({
      value: checkValue, // ステートに保存して数字を重ねる
    });
  }
  // テンキーvisible
  onVisibleChangePopover = () => {
    const { visibleNoKeyboard } = this.state;

    if (visibleNoKeyboard) {
      this.setState({
        visibleNoKeyboard: false,
      });
    }
  };
  // テンキーエンター
  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  /**
   * もらったvalueとgetFieldValueで二つの値をゲットして重複検索をする
   * hihokenNoが空ならapiはしない
   * @param {*} value
   * @param {*} type
   */
  checkNo = (value, type) => {
    const { getFieldValue } = this.props.form;

    if (type == "hoken" && getFieldValue("hihokenNo")) {
      this.props.checkHokenNo(
        value,
        getFieldValue("hihokenNo"),
        this.props.riyousyaId,
        (data) => {
          if (data.id) {
            this.setState({
              visibleCaution: true,
            });
          }
        }
      );
    }
    else if (type == "hihoken" && value) {
      this.props.checkHokenNo(
        getFieldValue("hokensyaNo"),
        value,
        this.props.riyousyaId,
        (data) => {
          if (data.id) {
            this.setState({
              visibleCaution: true,
            });
          }
        }
      );
    }
  };

  // 重複警告モーダルOK
  cautionOk = () => {
    const { setFieldsValue } = this.props.form;
    setFieldsValue({ hihokenNo: null });

    this.setState({
      visibleCaution: false,
    });
  };

  // OKクリック時
  formHandleInput = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // 無記入チェックで警告モーダル コメントアウト
      // if(!values.hihokenNo || !values.hokensyaNo || !values.kaigodo || !values.kyotakuNo
      //     || values.yukoDateFrom.format(ConstSet.DATE_FORMAT) === "0001-01-01"
      //     || values.tekiyouDate.format(ConstSet.DATE_FORMAT) === "0001-01-01"
      // ) {
      //     this.setState({
      //         updateVisible: true
      //     })
      // } else {
      this.props.update(values);
      // }
    });
  };
  // ok
  formCloseInput = () => {
    this.setState({
      updateVisible: false,
      kubunsikyu: true,
    });
    this.props.closeInput();
  };
  // modal閉じるok
  formUpdateCancel = () => {
    // this.props.updateCancel()
    this.setState({
      updateVisible: false,
    });
  };
  // 強行保存ok
  formUpdateOk = () => {
    this.props.updateOk();
    setTimeout(() => {
      this.setState({
        updateVisible: false,
      });
    }, 100);
  };

  serviceKikanSearch = (bunrui, name) => {
    this.props.getServiceKikanMasterList(bunrui, name);
  };

  // 日付をコンバート（区分支給有効期間）
  convertData = (data) => {
    let result = "";
    const dataTmp = convert_wareki_3(data);
    if (dataTmp && dataTmp !== "0001-01-01") {
      result = `${dataTmp.jt}${dataTmp.jn}年 ${dataTmp.m}月 ${dataTmp.d}日`;
    }
    return result;
  };

  render() {
    const { visibleCaution, keyType, serviceType } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { data, serviceSyurui, serviceKikanMaster, duplicationRiyousya } =
      this.props;

    const formItemLayoutCus = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            {/* <Button onClick={() => this.handleInput('.')}>.</Button> */}
            <Button disabled>-</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
          <Row>
            <Button
              onClick={this.handleEnter}
              style={{ padding: "0px", height: 150 }}
            >
              <EnterOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    );

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        // {...formItemLayout}
        labelAlign="left"
      >
        <Row className="title-inline">
          {/* hidden field place */}
          <Col span={24}>
            <Form.Item
              label={"無し"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("id", {
                rules: [
                  {
                    required: false,
                    message: "必須項目をセットして下さい",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"利用者"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("riyousyaId", {
                rules: [
                  {
                    required: false,
                    message: "必須項目をセットして下さい",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"mainSID"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("serviceKikanMasterId", {
                rules: [
                  {
                    required: false,
                    message: "必須項目をセットして下さい",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item style={{ display: "none" }}>
              {getFieldDecorator("kubunYukoDateFrom", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item style={{ display: "none" }}>
              {getFieldDecorator("kubunYukoDateTo", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title-red">保険者番号</span>
            <span style={{ fontSize: 12, color: "red" }}>（6桁）</span>
          </Col>
          <Col span={3}>
            {getFieldDecorator("hokensyaNo", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(
              <Input
                style={{ width: 110 }}
                onChange={(value) => this.checkNo(value.target.value, "hoken")}
              />
            )}
          </Col>
          <Col span={10} style={{ marginLeft: -5 }}>
            {getFieldDecorator("hokenName", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(
              <TextAreaSelectNew
                onChangeCus={this.updateChangeHoken}
                disabled={true}
                style={{ marginRight: 20 }}
              />
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 8 }}>
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title-red">被保険者番号</span>
            <span style={{ fontSize: 12, color: "red" }}>（10桁）</span>
          </Col>
          <MediaQuery query="(min-device-width: 850px)">
            {(matches) =>
              matches ? (
                <Col span={6}>
                  {getFieldDecorator("hihokenNo", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(
                    <Input
                      style={{ width: 130 }}
                      onChange={(value) =>
                        this.checkNo(value.target.value, "hihoken")
                      }
                    />
                  )}
                </Col>
              ) : (
                <Col span={6}>
                  {getFieldDecorator("hihokenNo", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(
                    <Input
                      style={{ width: 130 }}
                      onChange={(value) =>
                        this.checkNo(value.target.value, "hihoken")
                      }
                    />
                  )}
                  <Popover
                    open={
                      !!(this.state.visibleNoKeyboard && keyType == "hihokenNo")
                    }
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    onOpenChange={this.onVisibleChangePopover}
                  >
                    <Button
                      className="kdf-number-k"
                      type="primary"
                      onClick={() => this.kubunsikyuInput(true, "hihokenNo")}
                    >
                      入力
                    </Button>
                  </Popover>
                </Col>
              )
            }
          </MediaQuery>

          <Col span={2} style={{ marginTop: 5 }}>
            <span className="title">給付率</span>
          </Col>
          <MediaQuery query="(min-device-width: 850px)">
            {(matches) =>
              matches ? (
                <Col span={3}>
                  {getFieldDecorator("kyufu", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(
                    <InputNumber
                      min={0}
                      max={100}
                    // formatter={value => `${value}%`}
                    // parser={value => value.replace('%', '')}
                    />
                  )}
                  %
                </Col>
              ) : (
                <Col span={3}>
                  {getFieldDecorator("kyufu", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(
                    <InputNumber
                      min={0}
                      max={100}
                      // formatter={value => `${value}%`}
                      // parser={value => value.replace('%', '')}
                      onClick={() => this.keyInput("kyufu")}
                      readOnly
                    />
                  )}
                  <Popover
                    open={
                      !!(this.state.visibleNoKeyboard && keyType == "kyufu")
                    }
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    onOpenChange={this.onVisibleChangePopover}
                  >
                    <span>%</span>
                  </Popover>
                </Col>
              )
            }
          </MediaQuery>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title-red">要介護度</span>
          </Col>
          <Col span={5}>
            {getFieldDecorator("kaigodo", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(
              <SelectOptionsFormNew
                onChangeCus={this.changeKaigodo}
                style={{ width: 150 }}
              />
            )}
          </Col>
          <Col span={3} style={{ marginTop: 5 }}>
            <span className="title">区分支給限度額</span>
          </Col>
          <MediaQuery query="(min-device-width: 850px)">
            {(matches) =>
              matches ? (
                <Col span={8}>
                  {getFieldDecorator("kubunsikyu", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(
                    <InputNumber
                      style={{
                        width: 130,
                        background: "white",
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                      disabled={this.state.kubunsikyu}
                      id="myTextField"
                    />
                  )}
                  <Button
                    className="kdf-number-k"
                    type="primary"
                    onClick={() => this.kubunsikyuInput(false, "kubunsikyu")}
                  >
                    入力
                  </Button>
                </Col>
              ) : (
                <Col span={8}>
                  {getFieldDecorator("kubunsikyu", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(<Input style={{ width: 130 }} readOnly />)}
                  <Popover
                    open={
                      !!(
                        this.state.visibleNoKeyboard && keyType == "kubunsikyu"
                      )
                    }
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    onOpenChange={this.onVisibleChangePopover}
                  >
                    <Button
                      className="kdf-number-k"
                      type="primary"
                      onClick={() => this.kubunsikyuInput(true, "kubunsikyu")}
                    >
                      入力
                    </Button>
                  </Popover>
                </Col>
              )
            }
          </MediaQuery>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ color: "red", marginTop: 28 }}>
            <span className="title-red">認定有効期間</span>
          </Col>
          <Col span={15}>
            <div style={{ marginBottom: 8 }}>
              {getFieldDecorator("yukoDateFrom", {
                rules: [
                  {
                    required: false,
                    message: "必須項目をセットして下さい",
                  },
                ],
              })(
                <JPDateSelectPicker
                  keyId={data ? data.id : -1}
                  style={{ marginLeft: 0 }}
                />
              )}
            </div>
            <div>
              <span style={{ display: "flex" }}>
                {getFieldDecorator("yukoDateTo", {
                  rules: [
                    {
                      required: false,
                      message: "必須項目をセットして下さい",
                    },
                  ],
                })(
                  <JPDateSelectPicker
                    keyId={data ? data.id : -1}
                    style={{ marginLeft: 0 }}
                  />
                )}
                {getFieldDecorator("sinseif", {
                  rules: [{ required: false }],
                  valuePropName: "checked",
                })(
                  <Checkbox style={{ marginTop: 5, marginLeft: 15, alignItems: "center" }}>
                    <span className="title">申請中</span>
                  </Checkbox>
                )}
              </span>
            </div>
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title-red">適用開始日</span>
          </Col>
          <Col span={15}>
            {getFieldDecorator("tekiyouDate", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(<JPDateSelectPicker keyId={data ? data.id : -1} />)}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} className="title">
            区分支給有効期間
          </Col>
          <Col span={18}>
            <span style={{ marginRight: 10 }}>
              {this.convertData(
                getFieldValue("kubunYukoDateFrom").format("YYYY-MM-DD")
              )}
            </span>
            <span style={{ marginRight: 10 }}>～</span>
            {this.convertData(
              getFieldValue("kubunYukoDateTo").format("YYYY-MM-DD")
            )}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">交付日</span>
          </Col>
          <Col span={18}>
            {/* <Form.Item label={"交付日"} colon={false}> */}
            {getFieldDecorator("kofuDate", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(
              <JPDateSelectPicker
                keyId={data ? data.id : -1}
                style={{ marginLeft: 0 }}
              />
            )}
            {/* </Form.Item> */}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row>
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">認定日</span>
          </Col>
          <Col span={15}>
            {getFieldDecorator("ninteiDate", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(<JPDateSelectPicker keyId={data ? data.id : -1} />)}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row>
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">届出日</span>
            <span style={{ color: "red", fontSize: 12 }}>（居宅のみ必須）</span>
          </Col>
          <Col span={9}>
            {getFieldDecorator("todokedeDate", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(<JPDateSelectPicker keyId={data && data?.id ? data.id : -1} />)}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title-red">包括・居宅</span>
          </Col>
          <Col span={3}>
            {getFieldDecorator("kyotakuNo", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(<Input style={{ width: 110 }} />)}
          </Col>
          <Col span={11} style={{ marginLeft: -5, marginRight: 10 }}>
            {getFieldDecorator("stapleFood", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(
              <TextAreaSelectNewSKM
                onChangeCus={this.updateChangeKyotaku} // ok押した際にvalueを引数に関数を実行
                type={"form"}
                serviceSyurui={serviceSyurui}
                serviceKikanMaster={serviceKikanMaster}
                getServiceKikanMasterList={this.serviceKikanSearch}
                serviceType={serviceType}
              />
            )}
          </Col>
          <Col span={2} style={{ marginTop: 5 }}>
            <span className="title">作成区分</span>
          </Col>
          <Col span={3}>
            {getFieldDecorator("kyotakuSakuseiKubun", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(
              <SelectOptionsFormNew1
                data={["居宅", "自己", "包括"]}
                style={{ width: 130 }}
              />
            )}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">意見</span>
          </Col>
          <Col span={20}>
            {getFieldDecorator("iken", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(<TextInputArea rows={1} style={{ marginLeft: 0 }} />)}
          </Col>
        </Row>
        <Row style={{ justifyContent: "flex-end", marginTop: 20 }}>
          <Popover
            open={this.state.updateVisible}
            title={false}
            content={
              <div style={{ overflow: "hidden" }}>
                <p>必須項目が未入力ですがそのまま登録しますか？</p>
                <div style={{ float: "right" }}>
                  <Button
                    key="update"
                    type={"primary"}
                    onClick={() => this.formUpdateOk()}
                    style={{ marginRight: 5, marginTop: 20 }}
                  >
                    OK
                  </Button>
                  <Button
                    key="cancelUpdate"
                    onClick={() => this.formUpdateCancel()}
                  >
                    キャンセル
                  </Button>
                </div>
              </div>
            }
          >
            <Button
              key="input"
              type={"primary"}
              onClick={() => this.formHandleInput()}
            >
              OK
            </Button>
          </Popover>
          <Button
            key="cancelInput"
            style={{ marginLeft: 8 }}
            onClick={() => this.formCloseInput()}
          >
            キャンセル
          </Button>
        </Row>
        {/* 必須項目未入力警告 */}
        {/* <Modal
                        open={this.state.updateVisible}
                        title={false}
                        closable={false}
                        onOk={() => this.updateOk()}
                        onCancel={() => this.updateCancel()}
                        // footer={null}
                    >
                        <div style={{}}>
                            <div style={{}}>
                                <p>必須項目が未入力ですがそのまま登録しますか？</p>
                            </div>
                        </div>
                    </Modal> */}
        <Modal
          open={visibleCaution}
          title={false}
          closable={false}
          footer={null}
          style={{ paddingBottom: 50 }}
        >
          <div style={{ display: "flex" }}>
            <WarningOutlined style={{ fontSize: "36px" }} />
            <div style={{ marginLeft: "8px" }}>
              <p>同一被保険者番号が存在します。</p>
              <p>被保険者番号、利用者基本情報から入れなおして下さい。</p>
            </div>
          </div>
          <Row style={{ marginTop: "20px" }}>
            <p>【名前】 {duplicationRiyousya?.name}</p>
            <p>【フリガナ】 {duplicationRiyousya?.furigana}</p>
          </Row>
          <div style={{ textAlign: "right" }}>
            <Button onClick={() => this.cautionOk()}>OK</Button>
          </div>
        </Modal>
      </Form>)
    );
  }
}

export const HokenTableForm1 = Form.create({
  name: "input-riyou-syokujisen",
  // onFieldsChange(props, changedFields) {
  //     // props.onChange(changedFields);
  // },
  onValuesChange(props, changedFields) {
    const { form } = props;

    // console.log(changedFields)

    if (changedFields.hihokenNo) {
      // 被保険者番号のH
      if (changedFields.hihokenNo.slice(0, 1) == "H") {
        form.setFieldsValue({
          kyufu: "0",
        });
      }
    }

    if (changedFields.kyotakuNo) {
      // 包括手入力で自動事業所検索
      props.getServiceKikanMaster(
        changedFields.kyotakuNo,
        props.syuruiCds,
        (data) => {
          form.setFieldsValue({
            serviceKikanMasterId: data?.id ? data.id : null,
            stapleFood: data?.jigyousyoName ? data.jigyousyoName : null,
          });
        }
      );
    }

    if (changedFields.hokensyaNo || changedFields.hokensyaNo == "") {
      // 保険者番号手入力で自動検索
      props.getKaigoHokenMaster(changedFields.hokensyaNo, (data) => {
        if (data) {
          form.setFieldsValue({
            hokenName: data?.hokenName ? data.hokenName : null,
            kyufu: data?.kyufu ? data?.kyufu : null,
          });
        }
        else {
          form.setFieldsValue({
            hokenName: null,
          });
        }
      });
    }

    if (changedFields.kaigodo) {
      // 限度額変更
      props.kaigodoMaster.map((item) => {
        if (item.kaigodoNo == changedFields.kaigodo) {
          form.setFieldsValue({ kubunsikyu: item.gendogaku });
        }
      });
      // 作成区分変更
      if (
        changedFields.kaigodo == "21" ||
        changedFields.kaigodo == "22" ||
        changedFields.kaigodo == "23" ||
        changedFields.kaigodo == "24" ||
        changedFields.kaigodo == "25"
      ) {
        form.setFieldsValue({ kyotakuSakuseiKubun: 1 });
      }
      else if (
        changedFields.kaigodo == "06" ||
        changedFields.kaigodo == "12" ||
        changedFields.kaigodo == "13"
      ) {
        form.setFieldsValue({ kyotakuSakuseiKubun: 3 });
      }
    }
    if (changedFields.kaigodo == "") {
      // 空の要介護
      form.setFieldsValue({ kubunsikyu: null });
    }

    if (
      changedFields.yukoDateFrom &&
      changedFields.yukoDateFrom.format("YYYY-MM-DD") !== "0001-01-01"
    ) {
      // 認定有効期限変更
      form.setFieldsValue({
        tekiyouDate: changedFields.yukoDateFrom,
        kubunYukoDateFrom: changedFields.yukoDateFrom,
      });
    }
    if (
      changedFields.yukoDateTo &&
      changedFields.yukoDateTo.format("YYYY-MM-DD") !== "0001-01-01"
    ) {
      form.setFieldsValue({
        kubunYukoDateTo: changedFields.yukoDateTo,
      });
    }
  },
  mapPropsToFields(props) {
    // let date = new Date();
    let jigyouName = "";

    // if (props.data && props.data.date) {
    //   let propsDate = new Date(props.data.date);
    //   date = propsDate;
    // }


    if (props?.data?.serviceKikanMaster?.id) {
      if (
        props.data.serviceKikanMaster.syuruiCd == "43" ||
        props.data.serviceKikanMaster.syuruiCd == "46" ||
        props.data.serviceKikanMaster.syuruiCd == "73" ||
        props.data.serviceKikanMaster.syuruiCd == "77"
      ) {
        jigyouName = props.data.serviceKikanMaster.jigyousyoName;
      }
    }

    // let serviceKikanMasterId = StorageUtils.getValue(getMenu()).si;

    return {
      id: Form.createFormField({
        value: props.data && props.data.id ? props.data.id : null,
      }),
      riyousyaId: Form.createFormField({
        value:
          props.data && props.data.riyousyaId
            ? props.data.riyousyaId
            : props.riyousyaId,
      }),
      serviceKikanMasterId: Form.createFormField({
        value:
          props.data && props.data.serviceKikanMaster
            ? props.data.serviceKikanMaster.id
            : "",
      }),
      hokenName: Form.createFormField({
        value: props?.data?.kaigohokenMaster
          ? props.data.kaigohokenMaster.hokenName
          : null,
      }),
      hokensyaNo: Form.createFormField({
        value:
          props.data && props.data.hokensyaNo !== "0"
            ? props.data.hokensyaNo
            : null,
      }),
      hihokenNo: Form.createFormField({
        value:
          props?.data && props.data.hihokenNo !== "0"
            ? props.data.hihokenNo
            : null,
      }),
      kyufu: Form.createFormField({
        value: props.data ? props.data.kyufu : null,
      }),
      kofuDate: Form.createFormField({
        value:
          props.data && props.data.kofuDate
            ? dayjs(props.data.kofuDate)
            : // : dayjs(new Date())
            dayjs("0001-01-01"),
      }),

      ninteiDate: Form.createFormField({
        value:
          props.data && props.data.ninteiDate
            ? dayjs(props.data.ninteiDate)
            : // : dayjs(new Date())
            dayjs("0001-01-01"),
      }),

      todokedeDate: Form.createFormField({
        // value: props.data && props.data.todokedeDate ? dayjs(props.data.todokedeDate) : dayjs(new Date())
        value:
          props.data && props.data.todokedeDate
            ? dayjs(props.data.todokedeDate)
            : // : dayjs(new Date())
            dayjs("0001-01-01"),
      }),

      sinseif: Form.createFormField({
        value: props.data ? props.data.sinseif : null,
      }),

      yukoDateFrom: Form.createFormField({
        value:
          props.data && props.data.yukoDateFrom
            ? dayjs(props.data.yukoDateFrom)
            : // : dayjs(new Date())
            dayjs("0001-01-01"),
      }),

      yukoDateTo: Form.createFormField({
        value:
          props.data && props.data.yukoDateTo
            ? dayjs(props.data.yukoDateTo)
            : // : dayjs(new Date())
            dayjs("0001-01-01"),
      }),

      tekiyouDate: Form.createFormField({
        value:
          props.data && props.data.tekiyouDate
            ? dayjs(props.data.tekiyouDate)
            : // : dayjs(new Date())
            dayjs("0001-01-01"),
      }),

      kubunYukoDateFrom: Form.createFormField({
        value:
          props.data && props.data.kubunYukoDateFrom
            ? dayjs(props.data.kubunYukoDateFrom)
            : // : dayjs(new Date())
            dayjs("0001-01-01"),
      }),

      kubunYukoDateTo: Form.createFormField({
        value:
          props.data && props.data.kubunYukoDateTo
            ? dayjs(props.data.kubunYukoDateTo)
            : // : dayjs(new Date())
            dayjs("0001-01-01"),
      }),

      kaigodo: Form.createFormField({
        value: props.data && props.data.kaigodo ? props.data.kaigodo : "",
      }),

      kubunsikyu: Form.createFormField({
        value: props.data ? props.data.kubunsikyu : null,
      }),

      kyotakuNo: Form.createFormField({
        value: props.data ? props.data.kyotakuNo : null,
      }),
      stapleFood: Form.createFormField({
        value: jigyouName,
        // value: props?.data?.serviceKikanMaster
        //         ? props.data.serviceKikanMaster.jigyousyoName
        //         : null
      }),

      kyotakuSakuseiKubun: Form.createFormField({
        value: props.data ? props.data.kyotakuSakuseiKubun : null,
      }),

      iken: Form.createFormField({
        value: props.data && props.data.iken ? props.data.iken : "",
      }),
    };
  },
})(HokenTableForm1Normal);
