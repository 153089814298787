/* eslint-disable react/prop-types */
import { connect } from "react-redux";

import index from "./index";
import input from "./input";
import detail from "./detail";

import { getRiyousya } from "../../actions/riyousyaActions/actionCreators";
import {
  loadAdl,
  loadAdlById,
  deleteAdl,
  keepValueSearch,
  resetCurrentAdl,
  getSettingMasterAdl,
  getAdlMaster,
  updateAdl,
} from "../../actions/reportAdlActions/actionCreators";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    riyousya: state.riyousya.riyousya,
    total: state.reportAdl.metaReportAdlByDate.total,
    page: state.reportAdl.metaReportAdlByDate.page,
    pageSize: state.reportAdl.metaReportAdlByDate.pageSize,
    loading: state.reportAdl.loading,
    keepValue: state.reportAdl.keepValue,
    adls: state.reportAdl.adls,
    adl: state.reportAdl.adl,
    updateAdl: state.reportAdl.updateAdl,
    deleteAdl: state.reportAdl.deleteAdl,
    filterConditions: state.reportAdl.filterConditions || null,
    listName: state.reportAdl.listName,
    adlMaster: state.reportAdl.adlMaster,
    tantoFormat: state.tantoMasterList.tantoMasters2,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    //urlのriyousyaIdで利用者情報検索
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),

    //riyousyaIdで複数のreportAdlを検索
    loadAdl: (data, callback) => dispatch(loadAdl(data, callback)),

    //adlIdで単一reportAdlレコード取得
    loadAdlById: (adlId) => dispatch(loadAdlById(adlId)),

    //レコードidで削除
    deleteAdl: (id, callback) => dispatch(deleteAdl(id, callback)),

    //レコード選択時にレコードdataを保持する
    keepValueSearch: (data) => dispatch(keepValueSearch(data)),

    //input追加時reportAdlのidが無ければデフォルトの値をセット
    resetCurrentAdl: () => dispatch(resetCurrentAdl()),

    //settingMaster（ADL分類）読み込み
    getSettingMasterAdl: (callback) => dispatch(getSettingMasterAdl(callback)),

    //adlMaster 読み込み（bunrui検索）List返し
    getAdlMaster: (data, callback) => dispatch(getAdlMaster(data, callback)),

    //修正＆登録
    updateAdl: (params, callback) => dispatch(updateAdl(params, callback)),
  };
};

export const AdlIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(index); //toppage
export const AdlInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(input); //form
export const AdlDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(detail); //閲覧
