import {
  ITAKUTANTO_MASTER_SPIN_REQUEST,
  ITAKUTANTO_MASTER_LOAD_REQUEST,
  ITAKUTANTO_MASTER_CREATE_REQUEST,
  ITAKUTANTO_MASTER_UPDATE_REQUEST,
  ITAKUTANTO_MASTER_INIT_REQUEST,
  LOAD_ITAKUTANTO_MASTER_SUCCESS,
  LOAD_ITAKUTANTO_MASTER_ERROR,
  CREATE_ITAKUTANTO_MASTER_SUCCESS,
  CREATE_ITAKUTANTO_MASTER_ERROR,
  UPDATE_ITAKUTANTO_MASTER_SUCCESS,
  UPDATE_ITAKUTANTO_MASTER_ERROR,
  LOAD_MAX_SORT_SUCCESS,
  LOAD_MAX_SORT_ERROR,
  LOAD_ITAKUTANTO_MASTER_INIT_SUCCESS,
  LOAD_ITAKUTANTO_MASTER_INIT_ERROR,
} from "./actionName";

import { ItakuTantoMasterApi } from "../../api/itakuTantoMaster";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

/**
 * 委託担当マスター検索
 * @param {*} name 担当者名
 * @param {*} id serviceKikanのid
 * @returns
 */
export function loadItakuTantoMaster(name, id) {
  return (dispatch) => {
    dispatch({
      type: ITAKUTANTO_MASTER_LOAD_REQUEST,
    });
    ItakuTantoMasterApi.list(name, id).then(
      (data) => {
        dispatch({
          type: LOAD_ITAKUTANTO_MASTER_SUCCESS,
          data: data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_ITAKUTANTO_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//追加時,初期化
export function loadItakuTantoMasterInit(callback) {
  return (dispatch) => {
    dispatch({
      type: ITAKUTANTO_MASTER_INIT_REQUEST,
    });
    ItakuTantoMasterApi.loadInit().then(
      (data) => {
        dispatch({
          type: LOAD_ITAKUTANTO_MASTER_INIT_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_ITAKUTANTO_MASTER_INIT_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//新規追加
export function createItakuTantoMaster(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ITAKUTANTO_MASTER_CREATE_REQUEST,
    });
    ItakuTantoMasterApi.create(body).then(
      (data) => {
        dispatch({
          type: CREATE_ITAKUTANTO_MASTER_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_ITAKUTANTO_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//修正
export function updateItakuTantoMaster(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ITAKUTANTO_MASTER_UPDATE_REQUEST,
    });
    ItakuTantoMasterApi.update(body).then(
      (data) => {
        dispatch({
          type: UPDATE_ITAKUTANTO_MASTER_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_ITAKUTANTO_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//並び替え
// export function updateDocotrMasterList(body) {
//   return (dispatch) => {
//     // dispatch({
//     //   type: DOCTOR_MASTER_SPIN_REQUEST,
//     // })
//     DoctorMasterApi.updateList(body).then(
//       (data) => {
//         dispatch({
//           type: UPDATE_LIST_DOCTOR_MASTER_SUCCESS,
//           payload: data,
//         })
//       },
//       err => {
//         checkError(err);
//         dispatch({
//           type: UPDATE_DOCTOR_MASTER_ERROR,
//           error: err.response.status === 400 ? err.response.data.error : { message: err.response.statusText}
//         })
//       }
//     )
//   }
// }

// export function loadMaxSort(callback) {
//   return (dispatch) => {
//     // dispatch({
//     //   type: DOCTOR_MASTER_SPIN_REQUEST,
//     // })
//     DoctorMasterApi.loadMaxSort().then(
//       (data) => {
//         dispatch({
//           type: LOAD_MAX_SORT_SUCCESS,
//           data: data,
//         });
//         return callback(data);
//       },
//       (err) => {
//         checkError(err);
//         dispatch({
//           type: LOAD_MAX_SORT_ERROR,
//           error:
//             err.response.status === 400
//               ? err.response.data.error
//               : { message: err.response.statusText },
//         });
//       }
//     );
//   };
// }

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
