import {
  RIYOUSYA_MONTHLY_LIST,
  RIYOUSYA_MONTHLY_EDIT,
  RIYOUSYA_MONTHLY_ADD,
  RIYOUSYA_MONTHLY_DEL,
  RIYOUSYA_MONTHLY_LOAD,
  RIYOUSYA_MONTHLY_ERROR,
  RIYOU_MONTHLY_KEEP_VALUE_SEARCH

} from '../../actions/RiyousyaMonthlyActions/actionName';
import { openNotificationWithIcon } from '../../common/function_common/functionCommon';
import { CommonUtils } from '../../utils';

/*
*
* @param state: array // substate of store for home page
* @param action: action dispatched from Home component
*
*/
const INITIAL_STATE = {
  list: [],
  loading: false,
  error: false,
  limit: 50,
  page: 1,
  total: 0,
}

export default function riyousyaMonthlyReducers(state = INITIAL_STATE, action) {

  switch (action.type) {
    case RIYOUSYA_MONTHLY_LOAD:
      return {
        ...state,

        loading: true,
        error: false,
      }

    case RIYOUSYA_MONTHLY_ERROR:
      return {
        ...state,

        loading: false,
        error: true
      }

    case RIYOUSYA_MONTHLY_LIST:
      return {
        ...state,
        list: action.payload.data,
        metaData: CommonUtils.pagination(action.payload),

        loading: false,
        error: false
      };

    case RIYOUSYA_MONTHLY_EDIT:
      if (!action.noDisNoti) {
        openNotificationWithIcon("success", "保存されました。")
      }

      let tmp = state.list.map(item => {
        if(item.index === action.payload.index){
          return action.payload
        }else{
          return item
        }
      });
      
      return {
        ...state,
        list: tmp,

        loading: false,
        error: false
      };

    case RIYOUSYA_MONTHLY_ADD:
      return {
        ...state,
        editResult: action.payload,

        loading: false,
        error: false
      };

    case RIYOUSYA_MONTHLY_DEL:
      return {
        ...state,
        delResult: action.payload,

        loading: false,
        error: false
      };

    case RIYOU_MONTHLY_KEEP_VALUE_SEARCH:
      return {
        ...state,
        keepValue: action.keepValue,
      }

    default:
      return state;
  }
}
