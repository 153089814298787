import React from "react";
import { Row, Checkbox } from "antd";

export default class ShokunasiKB extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.handleOnchange(e.target.checked);
  }

  render() {
    let check = false;
    if (this.props.defaltValue === "1" || this.props.defaltValue === 1) {
      check = true;
    }

    return (
      <Row className="meal-shokuna">
        <Checkbox onChange={this.onChange} checked={check}>
          食無し
        </Checkbox>
      </Row>
    );
  }
}
