/* eslint-disable react/prop-types */
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Checkbox, Col, Modal, Pagination, Row, Table } from "antd";
import _ from "lodash";
import React from "react";
import { ConstSet } from "../../../common/configs/constset";
import { convert_wareki_4 } from "../../../common/function_common/functionCommon";
import { HokenTableForm4 } from "./HokenTableForm4";

export default class HokenTable4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandRowIndex: 0,
      deleteVisible: false,
      delRecordId: 0,
      newId: -1,
      rowIndex: null,
      checked: false,
      inputVisible: false,
      recordInput: [],
      updateVisible: false,
    };
  }

  expandRow = (recordId) => {
    const { expandRowIndex } = this.state;

    if (expandRowIndex === recordId) {
      this.setState({
        expandRowIndex: 0,
      });
    }
    else {
      this.setState({
        expandRowIndex: recordId,
      });
    }
  };

  showDeleteConfirm = (recordId, index) => {
    this.setState({
      deleteVisible: true,
      delRecordId: recordId,
      rowIndex: index,
      checked: false,
    });
  };

  // 修正
  handleEdit = (record) => {
    this.setState({
      inputVisible: true,
      recordInput: record,
    });
  };

  closeDeleteConfirm = () => {
    this.setState({
      deleteVisible: false,
      delRecordId: 0,
      rowIndex: null,
      checked: false,
    });
  };

  handleConfirmDelete = () => {
    const { delRecordId, rowIndex } = this.state;
    const id = _.clone(delRecordId);

    this.props.handleDelHokenTable(id, rowIndex);

    this.setState({
      deleteVisible: false,
      delRecordId: 0,
      rowIndex: null,
      checked: false,
      expandRowIndex: 0,
    });
  };

  handleChangeCheckbox = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked,
    });
  };

  // 新規追加
  handleAddNew = () => {
    this.setState({
      inputVisible: true,
      recordInput: [],
    });
  };

  closeInput = () => {
    this.setState({
      inputVisible: false,
      recordInput: [],
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  // 必須確認OK
  updateOk = () => {
    this.handleInputModalOk();
    setTimeout(() => {
      this.setState({
        updateVisible: false,
      });
    }, 100);
  };

  // 必須無視の保存
  handleInputModalOk = () => {
    // e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.update(values);
    });
  };

  update = (values) => {
    if (!values.id) {
      values.id = this.state.newId;
    }

    if (values.yukoDateFrom) {
      values.yukoDateFrom = values.yukoDateFrom.format(ConstSet.DATE_FORMAT);
    }
    if (values.yukoDateTo) {
      values.yukoDateTo = values.yukoDateTo.format(ConstSet.DATE_FORMAT);
    }
    values.id = values.id.toString();
    values.riyousyaId = values.riyousyaId.toString();
    values.hokenKubun = values.hokenKubun ? values.hokenKubun.toString() : "";
    values.keisanF = values.keisanF ? values.keisanF.toString() : "0";
    values.jogenFutangaku = values.jogenFutangaku
      ? values.jogenFutangaku.toString()
      : "0";

    this.props.updateHokenTable(values);

    this.setState({
      inputVisible: false,
      recordInput: [],
      expandRowIndex: 0,
      newId: this.state.newId,
    });
  };

  onChangePag = (page) => {
    this.props.onChangePage(page);
  };

  render() {
    const { data, riyousyaId, page, pageTotal } = this.props;
    const { expandRowIndex, recordInput } = this.state;

    const columns = [
      {
        dataIndex: "1",
        key: "1",
        render: (text, record, index) => {
          return (
            <div>
              <div
                onClick={() => this.expandRow(index + 1)}
                className="riyou-hoken-table-row"
              >
                <Row>
                  <Col md={5}>
                    <span>上限負担額: </span>
                    <span>{record.jogenFutangaku}</span>
                  </Col>
                  <Col md={7}>
                    <span>
                      有効期限: {convert_wareki_4(record.yukoDateFrom)}
                      &nbsp;〜&nbsp;{convert_wareki_4(record.yukoDateTo)}
                    </span>
                  </Col>
                  <Col md={6}>
                    <span>適応区分:</span>
                    <span>{record.hokenKubun}</span>
                  </Col>
                </Row>
              </div>
              {expandRowIndex && expandRowIndex === index + 1 ? (
                <div style={{ float: "right", padding: "0 5px 5px 0" }}>
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    style={{ color: "white", backgroundColor: "gray" }}
                    onClick={() => this.showDeleteConfirm(record.id, index)}
                  >
                    {"削除"}
                  </Button>
                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    style={{ color: "white", backgroundColor: "#00838F" }}
                    onClick={() => this.handleEdit(record)}
                  >
                    {"修正"}
                  </Button>
                </div>
              ) : null}
            </div>
          );
        },
      },
    ];

    return (
      (<div>
        <Row style={{ justifyContent: "flex-end" }}>
          <Button
            icon={<LegacyIcon type={"plus"} />}
            style={{
              color: "white",
              backgroundColor: "#dc0606",
              marginBottom: 8,
            }}
            onClick={() => this.handleAddNew()}
          >
            {"追加"}
          </Button>
        </Row>
        <Table
          dataSource={data}
          columns={columns}
          showHeader={false}
          className="riyou-hoken-table"
          rowKey={(record, index) => index}
          pagination={false}
        />
        <Pagination
          onChange={this.onChangePag}
          total={pageTotal * 10}
          pageSize={10}
          current={page}
          style={{ textAlign: "right", marginTop: 16 }}
        />
        {/* modal del */}
        <Modal
          open={this.state.deleteVisible}
          title="削除確認（同意するにチェックしてOKボタンを押してください）"
          onCancel={() => this.closeDeleteConfirm()}
          footer={[
            <Button
              disabled={!this.state.checked}
              key="delete"
              type={"primary"}
              onClick={() => this.handleConfirmDelete()}
            >
              OK
            </Button>,
            <Button
              key="canceldelete"
              onClick={() => this.closeDeleteConfirm()}
            >
              Cancel
            </Button>,
          ]}
        >
          <div style={{ backgroundColor: "aliceblue" }}>
            <Checkbox
              checked={this.state.checked}
              onChange={() => this.handleChangeCheckbox()}
              value={this.state.checked}
            >
              {"削除に同意する"}
            </Checkbox>
          </div>
        </Modal>
        {/* modal input */}
        <Modal
          className="hoken1-input-form"
          open={this.state.inputVisible}
          title={false}
          closable={false}
          maskClosable={false}
          onCancel={() => this.closeInput()}
          footer={null}
          width={960}
        >
          <HokenTableForm4
            data={recordInput}
            wrappedComponentRef={(formRef) => this.saveFormRef(formRef)}
            riyousyaId={riyousyaId}
            update={this.update}
            closeInput={this.closeInput}
            updateOk={this.handleInputModalOk}
          />
        </Modal>
      </div>)
    );
  }
}
