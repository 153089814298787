import { SEND_TO_IDS } from "../../actions/dengonSentToActions/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
var initialState = {
  sendTo: [],
};

export default function dengonSendToReducers(state = initialState, action) {
  switch (action.type) {
    case SEND_TO_IDS:
      return {
        ...state,
        sendTo: action.sendTo,
      };
    default:
      return state;
  }
}
