import { ApiPaths } from "../../constants/api_paths";
import { MeetingActionTypes, ErrorActionTypes } from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils } from "../../utils/index";

function getAll(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: MeetingActionTypes.GET_ALL_MEETING_REQUEST });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.TantoKaigi.GetList,
        query,
      });
      dispatch({ type: MeetingActionTypes.GET_ALL_MEETING_SUCCESS, data });
    } catch (error) {
      dispatch({ type: MeetingActionTypes.GET_ALL_MEETING_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: MeetingActionTypes.REMOVE_MEETING_REQUEST });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.TantoKaigi.Delete,
        query,
      });
      dispatch({ type: MeetingActionTypes.REMOVE_MEETING_SUCCESS, data });
    } catch (error) {
      dispatch({ type: MeetingActionTypes.REMOVE_MEETING_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

export const MeetingActions = {
  getAll,
  deleteId,
};
