import { notification } from "antd";
import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";

import { getPath } from "../../common/function_common/functionCommon";
import ActionsRow from "./UI/ActionsRow";
import WrappedSMForm from "./UI/SMForm";

class KanriSettingMasterSave extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      smId: parseInt(match.params.smId),
      path: getPath(),
    };

    this.registerSM = this.registerSM.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    // load data
    this.props.getSMList();
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  async registerSM(values) {
    if (values.id) {
      await this.props.updateSM(values);
    }
    else {
      await this.props.createSM(values);
    }
    this.props.history.goBack();
  }

  async delete(id) {
    await this.props.deleteSM(id.toString());

    this.props.history.goBack();
  }

  preToEnd = () => {
    const { kanriSMList } = this.props;
    const { smId, path } = this.state;

    if (kanriSMList && kanriSMList.length > 0) {
      const index = kanriSMList.findIndex((item) => item.id === smId);

      if (index >= 1) {
        this.setState({
          smId: kanriSMList[0].id,
        });

        this.props.history.replace(
          path + "/kanri/settingmaster/" + kanriSMList[0].id
        );
      }
    }
  };

  pre = () => {
    const { kanriSMList } = this.props;
    const { smId, path } = this.state;

    if (kanriSMList && kanriSMList.length > 0) {
      const index = kanriSMList.findIndex((item) => item.id === smId);

      if (index >= 1) {
        this.setState({
          smId: kanriSMList[index - 1].id,
        });

        this.props.history.replace(
          path + "/kanri/settingmaster/" + kanriSMList[index - 1].id
        );
      }
    }
  };

  next = () => {
    const { kanriSMList } = this.props;
    const { smId, path } = this.state;

    if (kanriSMList && kanriSMList.length > 0) {
      const index = kanriSMList.findIndex((item) => item.id === smId);

      if (index >= 0 && index < kanriSMList.length - 1) {
        this.setState({
          smId: kanriSMList[index + 1].id,
        });

        this.props.history.replace(
          path + "/kanri/settingmaster/" + kanriSMList[index + 1].id
        );
      }
    }
  };

  nextToEnd = () => {
    const { kanriSMList } = this.props;
    const { smId, path } = this.state;

    if (kanriSMList && kanriSMList.length > 0) {
      const index = kanriSMList.findIndex((item) => item.id === smId);

      if (index !== kanriSMList.length) {
        this.setState({
          smId: kanriSMList[kanriSMList.length - 1].id,
        });

        this.props.history.replace(
          path +
          "/kanri/settingmaster/" +
          kanriSMList[kanriSMList.length - 1].id
        );
      }
    }
  };

  recordMark = (type, id) => {
    this.props.recordMark("sm", type, id);
  };

  render() {
    const { kanriSMList, smSelectedRows } = this.props;
    const { smId } = this.state;

    return (
      <KanriLayoutContainer router="settingmaster" breadcrumbLink={"/kanri/settingmaster"} breadcrumbText={"セッティングマスタ"}>
        <ActionsRow
          key={smSelectedRows}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          selectedList={smSelectedRows}
          id={smId}
        />

        <WrappedSMForm
          data={kanriSMList.find((item) => item.id === smId)}
          history={this.props.history}
          regData={(values) => this.registerSM(values)}
          delete={this.delete}
        />
      </KanriLayoutContainer>
    );
  }
}

export default KanriSettingMasterSave;
