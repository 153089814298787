import React from "react";
import { Router, Switch } from "react-router-dom";

// layouts
import { ErrorLayout } from "./error";
import { PrivateLayout } from "./private";
import { PublicLayout } from "./public";
import { history } from "../utils/index";
// routes
import Routes from "../routes";

import { CommonUtils } from "../utils";

function Layout({ isUserLoggedIn, sessionTimeout, jwtTokenType }) {
  function renderRoutes(route, index) {
    if (route.type === "error") {
      return (
        <ErrorLayout
          key={index}
          path={route.path}
          component={route.component}
        />
      );
    }

    const AppLayout = route.type === "public" ? PublicLayout : PrivateLayout;
    return (
      <AppLayout
        exact
        key={index}
        isUserLoggedIn={isUserLoggedIn}
        path={route.path}
        component={route.component}
        sessionTimeout={sessionTimeout}
        jwtTokenType={jwtTokenType}
        isKanriPage={CommonUtils.isManagementAdmin(route.path)}
      />
    );
  }

  return (
    <Router history={history}>
      <Switch>{Routes.map(renderRoutes)}</Switch>
    </Router>
  );
}

export default Layout;
