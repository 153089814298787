import dayjs from "dayjs";
import { ConstSet } from "../../../common/configs/constset";
import { ConfigName } from "../../../config-name";
import { ApiPaths } from "../../constants";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils/index";
import { jokusoPlanActionTypes } from "../action_types/index";

function getAll(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: jokusoPlanActionTypes.GET_ALL_JOKUSO_PLAN_REQUEST });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.JokusoPlan.GetList,
        query,
      });
      dispatch({
        type: jokusoPlanActionTypes.GET_ALL_JOKUSO_PLAN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.GET_ALL_JOKUSO_PLAN_FAILURE,
        error,
      });
    }
  };
}

function deleteId(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: jokusoPlanActionTypes.REMOVE_JOKUSO_PLAN_REQUEST });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.JokusoPlan.DeleteJokusoPlan,
        query,
      });
      dispatch({
        type: jokusoPlanActionTypes.REMOVE_JOKUSO_PLAN_SUCCESS,
        data,
      });

      callback(data);
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.REMOVE_JOKUSO_PLAN_FAILURE,
        error,
      });
    }
  };
}

function saveJokusoPlan(body, callback) {
  if (body.id) {
    return async (dispatch) => {
      try {
        dispatch({
          type: jokusoPlanActionTypes.SAVE_UPDATE_JOKUSO_PLAN_REQUEST,
        });
        const data = await RequestUtils.callApi({
          method: "PUT",
          path: ApiPaths.JokusoPlan.SaveUpdateJokusoPlan,
          body,
        });
        dispatch({
          type: jokusoPlanActionTypes.SAVE_UPDATE_JOKUSO_PLAN_SUCCESS,
          jokusoPlan: data,
        });
      } catch (error) {
        dispatch({
          type: jokusoPlanActionTypes.SAVE_UPDATE_JOKUSO_PLAN_ERROR,
          error,
        });
      }
    };
  }
  return async (dispatch) => {
    const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
    try {
      dispatch({
        type: jokusoPlanActionTypes.SAVE_CREATE_JOKUSO_PLAN_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.JokusoPlan.SaveCreateJokusoPlan,
        body: { ...body, serviceKikanMasterId: Number(menu.si) },
      });
      dispatch({
        type: jokusoPlanActionTypes.SAVE_CREATE_JOKUSO_PLAN_SUCCESS,
        jokusoPlan: data,
      });
      callback({ ...data });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.SAVE_CREATE_JOKUSO_PLAN_ERROR,
        error,
      });
    }
  };
}

function getYougoMaster(listName) {
  return async (dispatch) => {
    try {
      dispatch({ type: jokusoPlanActionTypes.GET_YOUGO_MASTER });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.ApiGeneral.GetYougoMaster}?listName=${listName}`,
      });
      dispatch({
        type: jokusoPlanActionTypes.GET_YOUGO_MASTER_SUCCESS,
        yougoNaiyouMaster: data,
      });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.GET_YOUGO_MASTER_ERROR,
        error,
      });
    }
  };
}

function saveYougoMaster(listName, naiyou) {
  return async (dispatch) => {
    try {
      const body = { listName, naiyou };
      dispatch({ type: jokusoPlanActionTypes.SAVE_YOUGO_MASTER });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.ApiGeneral.UpdateYougomaster,
        body,
      });
      dispatch({
        type: jokusoPlanActionTypes.SAVE_YOUGO_MASTER_SUCCESS,
        ...data,
      });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.SAVE_YOUGO_MASTER_ERROR,
        error,
      });
    }
  };
}

function getJokusoPlanById(id, callback) {
  return async (dispatch) => {
    try {
      if (id === "new" || id === 0) {
        return dispatch({
          type: jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_ID_REQUEST,
          jokusoPlan: null,
        });
      }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.JokusoPlan.GetJokusoPlan}?id=${id}`,
      });
      dispatch({
        type: jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_ID_SUCCESS,
        jokusoPlan: data,
      });
      if (callback) {
        callback(data);
      }
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_ID_ERROR,
        error,
      });
    }
  };
}

function getJokusoPlanByIds(ids) {
  return async (dispatch) => {
    try {
      dispatch({
        type: jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_IDS_REQUEST,
        jokusoPlanList: [],
      });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.JokusoPlan.GetJokusoPlanList,
        query: { ids },
      });
      dispatch({
        type: jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_IDS_SUCCESS,
        jokusoPlanList: data,
      });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_IDS_ERROR,
        error,
      });
    }
  };
}

function clearJokusoPlanByIds() {
  return (dispatch) =>
    dispatch({ type: jokusoPlanActionTypes.CLEAR_JOKUSOPLAN_BY_IDS });
}

function cloneJokusoPlanById(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: jokusoPlanActionTypes.CLONE_JOKUSOPLAN_BY_ID_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.JokusoPlan.GetJokusoPlan}?id=${id}`,
      });
      dispatch({
        type: jokusoPlanActionTypes.CLONE_JOKUSOPLAN_BY_ID_SUCCESS,
        jokusoPlan: data,
      });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.CLONE_JOKUSOPLAN_BY_ID_ERROR,
        error,
      });
    }
  };
}

function getListJokusoIds(params) {
  const SWLIFEListRiyousya = StorageUtils.getValue(
    ConfigName.SWLIFE_LISTRIYOUSYA
  );
  const jokusoPlanSort =
    SWLIFEListRiyousya.sort && SWLIFEListRiyousya.sort.jokuso
      ? SWLIFEListRiyousya.sort.jokuso
      : {};
  const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
  return async (dispatch) => {
    const body = {
      sid: menu.si,
      searchName: params.searchName,
      inUsed: SWLIFEListRiyousya.isUsed ? 1 : 0,
      fromDate:
        !params.riyousyaId > 0
          ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .startOf("month")
              .format(ConstSet.DATE_FORMAT)
          : null,
      toDate:
        !params.riyousyaId > 0
          ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .endOf("month")
              .format(ConstSet.DATE_FORMAT)
          : null,
      sort: JSON.stringify(CommonUtils.convertSortDataToParams(jokusoPlanSort)),
      riyousyaId: params.riyousyaId,
    };
    try {
      dispatch({ type: jokusoPlanActionTypes.GET_LIST_IDS_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.JokusoPlan.GetListIdsJokusoPlan,
        query: body,
      });
      dispatch({
        type: jokusoPlanActionTypes.GET_LIST_IDS_SUCCESS,
        idsJokusoPlan: data,
      });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.GET_LIST_IDS_ERROR,
        error,
      });
    }
  };
}

function getLifeYougoMaster(version) {
  return async (dispatch) => {
    try {
      dispatch({ type: jokusoPlanActionTypes.GET_LIFE_YOUGO_MASTER_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.JokusoPlan.GetJokusoLifeYougo}`,
        query: { version: version },
      });
      dispatch({
        type: jokusoPlanActionTypes.GET_LIFE_YOUGO_MASTER_SUCCESS,
        lifeYougoMasters: data,
      });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.GET_LIFE_YOUGO_MASTER_ERROR,
        error,
      });
    }
  };
}

function getFirstLoadData(riyousyaId, fdayMonth, ldayMonth) {
  return async (dispatch) => {
    try {
      const query = {
        riyousyaId,
        fdayMonth,
        ldayMonth,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.JokusoPlan.getFirstLoadData,
        query,
      });

      dispatch({ type: jokusoPlanActionTypes.GET_FIRST_LOAD_DATA, data });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.GET_FIRST_LOAD_DATA_ERROR,
        error,
      });
    }
  };
}

function getRiyouKaigohoken(riyousyaId, fromDate, toDate) {
  return async (dispatch) => {
    try {
      dispatch({ type: jokusoPlanActionTypes.GET_RIYOU_KAIGOHOKEN_REQUEST });
      const query = {
        riyousyaId,
        fromDate,
        toDate,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.JokusoPlan.getRiyouKaigohoken,
        query,
      });

      dispatch({
        type: jokusoPlanActionTypes.GET_RIYOU_KAIGOHOKEN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.GET_RIYOU_KAIGOHOKEN_ERROR,
        error,
      });
    }
  };
}

function getListJokusoPlanToQuote(riyousyaId, jokusoId) {
  const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
  const scn = menu.scn || null;
  const si = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: jokusoPlanActionTypes.LOAD_JOKUSO_TO_QUOTE_REQUEST,
      });
      const query = {
        riyousyaId,
        jokusoId,
        systemControlName: scn,
        systemControlId: si,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.JokusoPlan.GetListJokusoPlanToQuote,
        query,
      });
      dispatch({
        type: jokusoPlanActionTypes.LOAD_JOKUSO_TO_QUOTE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: jokusoPlanActionTypes.LOAD_JOKUSO_TO_QUOTE_FAILURE,
        error,
      });
    }
  };
}

export const JokusoPlan = {
  getAll,
  deleteId,
  saveJokusoPlan,
  getLifeYougoMaster,
  getYougoMaster,
  saveYougoMaster,
  getListJokusoIds,
  getJokusoPlanById,
  getJokusoPlanByIds,
  clearJokusoPlanByIds,
  cloneJokusoPlanById,
  getFirstLoadData,
  getListJokusoPlanToQuote,
  getRiyouKaigohoken,
};
