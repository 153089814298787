import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const KyotakuPlan2Api = {
  async loadKyotakuPlan2(params) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.GET_KYOTAKU_PLAN2_LIST, {
        params: {
          riyousyaId: params.riyousyaId,
          sid: menu.si,
          systemName: menu.sn,
          inUsed: params.inUsed,
          searchName: params.searchName,
          sort: params.sort,
          page: params.page,
        },
      });
    return res.data;
  },
  async createKyotakuPlan2(data, riyousyaId) {
    const key = getMenu();
    const res = await axios
      .post(apiUrls.CREATE_KYOTAKU_PLAN2, {
        riyousya_id: riyousyaId,
        service_kikan_master_id: JSON.parse(localStorage.getItem(key)).si,
        ...data,
      });
    return res.data;
  },
  async updateKyotakuPlan2(data, kyotakuPlan2Id) {
    const res = await axios
      .put(apiUrls.UPDATE_KYOTAKU_PLAN2 + "?id=" + kyotakuPlan2Id, {
        ...data,
      });
    return res.data;
  },
  async deleteKyotakuPlan2(kyotakuPlan2Id) {
    const res = await axios
      .delete(apiUrls.DELETE_KYOTAKU_PLAN2, {
        params: {
          id: kyotakuPlan2Id,
        },
      });
    return res.data;
  },
  async getKyotakuPlan2ById(kyotakuPlan2Id) {
    const res = await axios
      .get(apiUrls.GET_KYOTAKU_PLAN2_BY_ID, {
        params: {
          id: kyotakuPlan2Id,
        },
      });
    return res.data;
  },
  async getKyotakuPlan2ToQuote(riyousyaId, kyotakuPlan2Id) {
    const key = getMenu();
    const res = await axios
      .get(apiUrls.GET_KYOTAKU_PLAN2_TO_QUOTE, {
        params: {
          riyousyaId,
          kyotakuPlan2Id,
          systemName: JSON.parse(localStorage.getItem(key)).sn,
          mainSid: JSON.parse(localStorage.getItem(key)).si,
        },
      });
    return res.data;
  },
  async settingMasterNaiyou(value) {
    const res = await axios
      .get(apiUrls.GET_SETTING_MASTER, {
        params: {
          settingName: value,
        },
      });
    return res.data;
  },
};

export { KyotakuPlan2Api };
