export const Validation = {
  Username: [
    {
      required: true,
      message: "ログインIDを入力してください",
    },
  ],
  Password: [
    {
      required: true,
      message: "パスワードを入力してください",
    },
  ],
};
