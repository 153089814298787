import { Row, notification } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import MenuRecord from "../../common/component/UI/MenuRecordV2";
import { ConstSet } from "../../common/configs/constset";
import "./Plan.css";
import { EventPlan } from "./UI/EventPlan";
import { EventPlanMobile } from "./UI/EventPlanMobile";
import { Filter } from "./UI/Filter";
import { IndividualPlan } from "./UI/IndividualPlan";
import { IndividualPlanMobile } from "./UI/IndividualPlanMobile";
import { ModeCombo } from "./UI/ModeCombo";
import Toolbar from "./UI/Toolbar";

class Plan extends Component {
  constructor(props) {
    super(props);
    const today = new Date();

    this.state = {
      screenMode: "event",
      selectedDate: dayjs(today),
      selectedValues: [],
    };

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  async loadData(mode, dateMoment) {
    const date = dateMoment.toDate();
    const startDate = dayjs(
      new Date(date.getFullYear(), date.getMonth(), -15)
    ).format(ConstSet.DATE_FORMAT);
    const endDate = dayjs(
      new Date(date.getFullYear(), date.getMonth() + 1, 15)
    ).format(ConstSet.DATE_FORMAT);
    if (mode === "event") {
      this.props.getEventPlans(startDate, endDate);
    }
    else {
      if (this.props.filterMasterData == null) {
        this.props.getFilterMasterData();
      }
      await this.props.getNyutaishoData(startDate, endDate);
      this.props.getIndividualPlans("", startDate, endDate);
    }
  }

  /**
   * Load for first Time
   */
  componentDidMount() {
    // Setting page befor redering DOM
    this.props.getyougoMasterNaiyou();
    this.loadData(this.state.screenMode, this.state.selectedDate);
  }

  /**
   * Load when action ある
   */
  componentDidUpdate(prevProps) {
    // Check When Action Complie
    const { notifyDisplay, notifyContent } = this.props;

    if (prevProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        this.openNotificationWithIcon("success", notifyContent);
      }
      if (notifyDisplay === 2) {
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  changeMode(mode) {
    this.setState({ screenMode: mode });
    this.loadData(mode, this.state.selectedDate);
  }

  selectMonth(month) {
    const oldMonth = this.state.selectedDate.toDate().getMonth();
    if (month.toDate().getMonth() !== oldMonth)
      this.loadData(this.state.screenMode, month);
    this.setState({
      selectedDate: month,
    });
  }

  handleSelectValues(values) {
    this.setState({
      selectedValues: values,
    });
  }

  handleInputEventPlan(data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].eventPlanId) {
        if (i === data.length - 1) {
          this.props.updateEventPlan(
            data[i].eventPlanId.toString(),
            data[i].date,
            data[i].event,
            () => {
              this.loadData(this.state.screenMode, this.state.selectedDate);
            }
          );
        }
        else {
          this.props.updateEventPlan(
            data[i].eventPlanId.toString(),
            data[i].date,
            data[i].event,
            () => {
              /* do nothing*/
            }
          );
        }
      }
      else if (i === data.length - 1) {
        this.props.createEventPlan(data[i].date, data[i].event, () => {
          this.loadData(this.state.screenMode, this.state.selectedDate);
        });
      }
      else {
        this.props.createEventPlan(data[i].date, data[i].event, () => {
          /* do nothing*/
        });
      }
    }
  }

  handleDeleteEventPlan(eventPlanIds) {
    if (eventPlanIds || eventPlanIds.length > 0) {
      for (let i = 0; i < eventPlanIds.length; i++) {
        if (i === eventPlanIds.length - 1) {
          if (eventPlanIds[i]) {
            this.props.deleteEventPlan(eventPlanIds[i].toString(), () => {
              this.loadData(this.state.screenMode, this.state.selectedDate);
            });
          }
          else {
            this.loadData(this.state.screenMode, this.state.selectedDate);
          }
        }
        else if (eventPlanIds[i]) {
          this.props.deleteEventPlan(eventPlanIds[i].toString(), () => {
            /* do nothing*/
          });
        }
      }
    }
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }

  render() {
    return (
      <CommonPageContainer
        id="plan-page"
        sessionTimeout={this.props.sessionTimeout}
      >
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <div className={"container"}>
                  <Row className={"submenu"}>
                    <SubHeaderV2
                      TopLeft={
                        <SubHeaderTitleV2
                          title={
                            this.state.screenMode === "event"
                              ? "施設行事予定"
                              : "利用者個別予定"
                          }
                          iconType="schedule"
                        />
                      }
                      TopRight2={<MenuRecord isPlan={true} />}
                      IncBottom={
                        <div>
                          <Toolbar
                            mode={this.state.screenMode}
                            handleChangeMode={(mode) => this.changeMode(mode)}
                            selectedDate={this.state.selectedDate}
                            selectedValues={this.state.selectedValues}
                            data={this.props.eventPlans}
                            handleSelectMonth={(month) =>
                              this.selectMonth(month)
                            }
                            handleInputEventPlan={(data) =>
                              this.handleInputEventPlan(data)
                            }
                            handleDeleteEventPlan={(eventPlanId) =>
                              this.handleDeleteEventPlan(eventPlanId)
                            }
                            yougoMasterEditState={this.getYougoMasterEditState}
                            yougoMasterEditCancel={
                              this.getYougoMasterEditCancel
                            }
                            yougoMasterEditSave={this.getYougoMasterEditSave}
                            yougoMasterNaiyou={this.props.yougoMasterNaiyou}
                          />
                          {this.state.screenMode === "event" ? null : (
                            <Filter
                              innerStyle={{ maxWidth: "600px" }}
                              filterDataBackup={this.props.filterConditions}
                              nyutaishoFilterData={this.props.filterMasterData}
                              filterNyutaisho={(filter) =>
                                this.props.filterNyutaishoData(filter)
                              }
                            />
                          )}
                        </div>
                      }
                    />
                  </Row>
                  <Row>
                    {this.state.screenMode === "event" ? (
                      <EventPlan
                        selectedDate={this.state.selectedDate}
                        handleSelectMonth={(month) => this.selectMonth(month)}
                        data={this.props.eventPlans}
                        selectedValues={this.state.selectedValues}
                        handleSelectValues={(values) =>
                          this.handleSelectValues(values)
                        }
                      />
                    ) : (
                      <IndividualPlan
                        selectedDate={this.state.selectedDate}
                        handleSelectMonth={(month) => this.selectMonth(month)}
                        data={this.props.filteredIndividualPlans}
                      />
                    )}
                  </Row>
                </div>
              );
            }

            return (
              <div className={"container"}>
                <Row className={"submenu"}>
                  <SubHeaderV2
                    TopLeft={
                      <div id={"submenu-mobile"}>
                        <SubHeaderTitleV2
                          title={
                            this.state.screenMode === "event"
                              ? "施設行事予定"
                              : "利用者個別予定"
                          }
                          iconType="schedule"
                        />
                        <ModeCombo
                          style={{ float: "right" }}
                          mode={this.state.screenMode}
                          handleChangeMode={(mode) => this.changeMode(mode)}
                        />
                      </div>
                    }
                    TopRight2={<MenuRecord isPlan={true} />}
                    IncBottom={
                      this.state.screenMode === "event" ? null : (
                        <Filter
                          innerStyle={{ display: "flex" }}
                          filterDataBackup={this.props.filterConditions}
                          nyutaishoFilterData={this.props.filterMasterData}
                          filterNyutaisho={(filter) =>
                            this.props.filterNyutaishoData(filter)
                          }
                        />
                      )
                    }
                  />
                </Row>
                <Row>
                  {this.state.screenMode === "event" ? (
                    <EventPlanMobile
                      mode={"event"}
                      selectedDate={this.state.selectedDate}
                      handleSelectMonth={(month) => this.selectMonth(month)}
                      data={this.props.eventPlans}
                      handleInputEventPlan={(data) =>
                        this.handleInputEventPlan(data)
                      }
                      handleDeleteEventPlan={(eventPlanId) =>
                        this.handleDeleteEventPlan(eventPlanId)
                      }
                      yougoMasterEditState={this.getYougoMasterEditState}
                      yougoMasterEditCancel={this.getYougoMasterEditCancel}
                      yougoMasterEditSave={this.getYougoMasterEditSave}
                      yougoMasterNaiyou={this.props.yougoMasterNaiyou}
                    />
                  ) : (
                    <IndividualPlanMobile
                      mode={"individual"}
                      selectedDate={this.state.selectedDate}
                      handleSelectMonth={(month) => this.selectMonth(month)}
                      data={this.props.filteredIndividualPlans}
                    />
                  )}
                </Row>
              </div>
            );
          }}
        </MediaQuery>
      </CommonPageContainer>
    );
  }
}

export default Plan;
