import { Button, Col, DatePicker, Modal, Row, Select, Table } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { loadKeikaKirokubyDate } from "../../../../actions/keikaKirokuActions/actionCreators";
import LineBreakText from "../../../../common/component/LineBreakText";
import { apiUrls } from "../../../../common/configs/common-url";
import { ConstSet } from "../../../../common/configs/constset";

const { Option } = Select;
function mapStateToProps(state) {
  return {
    keikaKirokusByDate: state.keikaKiroku.keikaKirokusByDate,
    metaKeikaKirokuByDate: state.keikaKiroku.metaKeikaKirokuByDate,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadKeikaKirokubyDate: (data) => dispatch(loadKeikaKirokubyDate(data)),
  };
};

class ProgressRecord extends Component {
  constructor(props, context) {
    super(props, context);
    const startDate =
      props.monitoringDate ||
      dayjs(new Date(), ConstSet.DATE_FORMAT).format(ConstSet.DATE_FORMAT);
    const endDate =
      props.monitoringDate ||
      dayjs(new Date(), ConstSet.DATE_FORMAT).format(ConstSet.DATE_FORMAT);
    this.state = {
      startDate: dayjs(startDate, ConstSet.DATE_FORMAT).subtract(6, "months"),
      endDate: dayjs(endDate, ConstSet.DATE_FORMAT),
      listKubun: [],
      kirokuKubun: null,
      page: 1,
      selected: "",
    };
    this.selectedCellRef = null;
  }

  componentDidMount() {
    axios
      .get(apiUrls.GET_SETTING_MASTER, {
        params: {
          settingName: "Vi経過記録区分",
        },
      })
      .then((res) => {
        const listKubun = this.parseData(res.data);
        if (listKubun && listKubun.length > 0) {
          this.setState({ listKubun });
        }
      })
      .catch(() => { });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.monitoringDate &&
      this.props.monitoringDate !== prevProps.monitoringDate
    ) {
      this.setState({
        startDate: dayjs(
          this.props.monitoringDate,
          ConstSet.DATE_FORMAT
        ).subtract(6, "months"),
        endDate: dayjs(this.props.monitoringDate, ConstSet.DATE_FORMAT),
      });
    }
  }

  loadData() {
    this.props.loadKeikaKirokubyDate({
      riyousyaId: this.props.riyousyaId,
      page: this.state.page,
      startDate: this.state.startDate.format("YYYY-MM-DD"),
      endDate: this.state.endDate.format("YYYY-MM-DD"),
      kirokuKubun: this.state.kirokuKubun,
    });
  }
  onHide() {
    this.setState({
      visible: false,
    });
  }
  handleRangePickerChange(date) {
    this.setState({
      startDate: date[0],
      endDate: date[1],
      page: 1,
    });
    this.props.loadKeikaKirokubyDate({
      riyousyaId: this.props.riyousyaId,
      page: 1,
      startDate: date[0].format("YYYY-MM-DD"),
      endDate: date[1].format("YYYY-MM-DD"),
      kirokuKubun: this.state.kirokuKubun,
    });
  }
  handleChangeKirokuKubun(value) {
    this.setState({
      kirokuKubun: value,
      page: 1,
    });
    this.props.loadKeikaKirokubyDate({
      riyousyaId: this.props.riyousyaId,
      page: 1,
      startDate: this.state.startDate.format("YYYY-MM-DD"),
      endDate: this.state.endDate.format("YYYY-MM-DD"),
      kirokuKubun: value,
    });
  }
  async showModalProgressRecord() {
    if (this.props.onShow) {
      this.props.onShow();
    }
    await this.loadData();
    this.setState({
      visible: true,
    });
  }
  onOkDialog() {
    const { selected } = this.state;

    if (selected) {
      this.props.onOkQuoteComment(selected);
      this.setState({ selected: "" });

      if (this.selectedCellRef) {
        this.selectedCellRef.classList.remove(
          "progress-record-modal__td--selected"
        );
        this.selectedCellRef = null;
      }
    }
    this.setState({
      visible: false,
      selected: "",
    });
  }

  handleStartDateChange(startDate) {
    this.setState({ startDate, page: 1 });
    if (this.state.endDate) {
      this.props.loadKeikaKirokubyDate({
        riyousyaId: this.props.riyousyaId,
        page: 1,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: this.state.endDate.format("YYYY-MM-DD"),
        kirokuKubun: this.state.kirokuKubun,
      });
    }
  }

  handleEndDateChange(endDate) {
    this.setState({ endDate, page: 1 });
    if (this.state.startDate) {
      this.props.loadKeikaKirokubyDate({
        riyousyaId: this.props.riyousyaId,
        page: 1,
        startDate: this.state.startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        kirokuKubun: this.state.kirokuKubun,
      });
    }
  }

  handlePageChange(page) {
    this.setState({ page });
    this.props.loadKeikaKirokubyDate({
      riyousyaId: this.props.riyousyaId,
      page,
      startDate: this.state.startDate.format("YYYY-MM-DD"),
      endDate: this.state.endDate.format("YYYY-MM-DD"),
      kirokuKubun: this.state.kirokuKubun,
    });
  }

  canonicalizeNewlines(str) {
    return str.replace(/(\r\n|\r|\n)/g, "\n");
  }

  parseData(data) {
    if (!data || !data.naiyou) return;

    const { naiyou } = data;
    const parsedData = this.canonicalizeNewlines(naiyou);
    return parsedData.split("\n");
  }

  handleSelectCell(value, e) {
    if (this.selectedCellRef) {
      this.selectedCellRef.classList.remove(
        "progress-record-modal__td--selected"
      );
    }
    this.selectedCellRef = e.target;
    this.selectedCellRef.classList.add("progress-record-modal__td--selected");

    this.setState({
      selected: value,
    });
  }

  render() {
    const { keikaKirokusByDate, metaKeikaKirokuByDate } = this.props;
    const { listKubun } = this.state;
    // const this1 = this;
    const columns = [
      {
        title: "記録日",
        dataIndex: "kirokuDate",
        key: "kirokuDate",
        width: 250,
        render: (text) => (
          <div
            className="progress-record-modal__td"
            onClick={(e) => this.handleSelectCell(text, e)}
          >
            {<LineBreakText text={text} />}
          </div>
        ),
      },
      {
        title: "内容",
        dataIndex: "naiyou",
        key: "naiyou",
        width: 250,
        render: (text) => (
          <div
            className="progress-record-modal__td"
            onClick={(e) => this.handleSelectCell(text, e)}
          >
            {<LineBreakText text={text} />}
          </div>
        ),
      },
      {
        title: "判断",
        dataIndex: "sakuseisyaHandan",
        key: "sakuseisyaHandan",
        width: 250,
        render: (text) => (
          <div
            className="progress-record-modal__td"
            onClick={(e) => this.handleSelectCell(text, e)}
          >
            {<LineBreakText text={text} />}
          </div>
        ),
      },
      {
        title: "考え方",
        dataIndex: "riyousyaKangae",
        key: "riyousyaKangae",
        width: 250,
        render: (text) => (
          <div
            className="progress-record-modal__td"
            onClick={(e) => this.handleSelectCell(text, e)}
          >
            {<LineBreakText text={text} />}
          </div>
        ),
      },
    ];
    let dataSource = [];
    dataSource =
      keikaKirokusByDate && Array.isArray(keikaKirokusByDate)
        ? keikaKirokusByDate.map((info) => {
          return {
            kirokuDate: info.kirokuDate,
            naiyou: info.naiyou,
            sakuseisyaHandan: info.sakuseisyaHandan,
            riyousyaKangae: info.riyousyaKangae,
          };
        })
        : [];
    const pagingOptions = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: metaKeikaKirokuByDate && metaKeikaKirokuByDate.total,
      current: metaKeikaKirokuByDate && metaKeikaKirokuByDate.page,
      pageSize: metaKeikaKirokuByDate && metaKeikaKirokuByDate.pageSize,
    };

    return (
      (<div>
        <Button
          size={this.props.buttonSize}
          onClick={() => this.showModalProgressRecord()}
          type={this.props.buttonType}
          style={{ marginLeft: 1, marginBottom: 4, width: "100%" }}
        >
          {this.props.buttonName}
        </Button>
        <Modal
          open={this.state.visible}
          title="経過記録"
          width={1000}
          style={{ maxWidth: "calc(100% - 30px)" }}
          onOk={() => this.onOkDialog()}
          onCancel={() => this.onHide()}
          className="progress-record-modal"
        >
          <div>
            <MediaQuery query="(min-device-width: 768px)">
              {(matches) =>
                matches ? (
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 11 }}
                      style={{ paddingBottom: "5px" }}
                    >
                      <span className="text-bold">記録日</span>
                      <DatePicker.RangePicker
                        style={{ padding: "0 10px" }}
                        value={[this.state.startDate, this.state.endDate]}
                        onChange={(date, dateString) =>
                          this.handleRangePickerChange(date, dateString)
                        }
                        allowClear={false}
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 8 }}
                      style={{ paddingBottom: "5px" }}
                    >
                      <span className="text-bold" style={{ marginRight: 10 }}>
                        分類指定
                      </span>
                      <Select
                        value={this.state.kirokuKubun}
                        onChange={(value) =>
                          this.handleChangeKirokuKubun(value)
                        }
                        style={{ width: 170 }}
                      >
                        <Option
                          style={{ height: 30 }}
                          value={null}
                        >{" "}</Option>
                        {listKubun && Array.isArray(listKubun)
                          ? listKubun.map((value, index) => {
                            return (
                              <Option key={index} value={value}>
                                {value}
                              </Option>
                            );
                          })
                          : null}
                      </Select>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={24}>
                      <p className="text-bold" style={{ marginBottom: 0 }}>
                        記録日
                      </p>
                      <DatePicker
                        value={this.state.startDate}
                        onChange={(date) => this.handleStartDateChange(date)}
                      />
                      <DatePicker
                        value={this.state.endDate}
                        onChange={(date) => this.handleEndDateChange(date)}
                      />
                    </Col>
                    <Col span={24} style={{ marginTop: 10, marginBottom: 5 }}>
                      <p className="text-bold" style={{ marginBottom: 0 }}>
                        分類指定
                      </p>
                      <Select
                        value={this.state.kirokuKubun}
                        onChange={(value) =>
                          this.handleChangeKirokuKubun(value)
                        }
                        style={{ width: 170 }}
                      >
                        <Option
                          style={{ height: 30 }}
                          value={null}
                        >{" "}</Option>
                        {listKubun && Array.isArray(listKubun)
                          ? listKubun.map((value, index) => {
                            return (
                              <Option key={index} value={value}>
                                {value}
                              </Option>
                            );
                          })
                          : null}
                      </Select>
                    </Col>
                  </Row>
                )
              }
            </MediaQuery>
            <Table
              style={{ cursor: "pointer" }}
              size={"small"}
              bordered={true}
              columns={columns}
              dataSource={dataSource}
              pagination={pagingOptions}
            ></Table>
          </div>
        </Modal>
      </div>)
    );
  }
}

ProgressRecord.defaultProps = {
  buttonName: "経過記録",
  buttonSize: "small",
  buttonType: "primary",
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressRecord);
