import { CommonActionTypes, ErrorActionTypes } from "../action_types";

// constants
import { ApiPaths } from "../../constants";

// utils
import { RequestUtils } from "../../utils";

function getkaigodo() {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetKaidodoList,
      });
      dispatch({
        type: CommonActionTypes.LIFE_KAIGODO_MASTER_LIST,
        data,
      });
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getMediSyoubyoumei(text) {
  return async (dispatch) => {
    try {
      const query = { text: text }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetMediSyoubyoumei,
        query,
      });
      dispatch({
        type: CommonActionTypes.GET_MEDISYOUBYOUMEI_MASTER,
        data,
      });
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getMediSyoubyoumeiAll(text) {
  return async (dispatch) => {
    try {
      const query = { text: text }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetMediSyoubyoumeiAll,
        query
      });
      dispatch({
        type: CommonActionTypes.GET_MEDISYOUBYOUMEI_MASTER,
        data,
      });
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getIyakuhinMaster(text) {
  return async (dispatch) => {
    try {
      const query = { text: text }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetIyakuhinMaster,
        query,
      });
      dispatch({
        type: CommonActionTypes.GET_IYAKUHIN_MASTER,
        data,
      });
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getRiyouByoureki(riyousyaId) {
  return async (dispatch) => {
    try {
      const query = { riyousyaId: riyousyaId }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetRiyouByoureki,
        query,
      });
      dispatch({
        type: CommonActionTypes.GET_RIYOUBYOUREKI_MASTER,
        data,
      });
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getMediSyoubyoumeiByCode(code, callback) {
  return async (dispatch) => {
    try {
      const query = { code: code }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetMediSyoubyoumeiByCode,
        query,
      });
      dispatch({
        type: CommonActionTypes.GET_MEDISYOUBYOUMEI,
        data,
      });
      callback(data);
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getMediSyoubyoumeiByCodeDetail(code, callback) {
  return async () => {
      const query = { code: code }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetMediSyoubyoumeiByCode,
        query
      });
      callback(data)
  };
}

function getIyakuhinMasterByCode(code, callback) {
  return async (dispatch) => {
    try {
      const query = { code: code }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetIyakuhinMasterByCode,
        query,
      });
      dispatch({
        type: CommonActionTypes.GET_IYAKUHIN,
        data,
      });
      callback(data);
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getDoctorMaster(text) {
  return async (dispatch) => {
    try {
      const query = { text: text };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetDoctorMaster,
        query,
      });
      dispatch({
        type: CommonActionTypes.GET_DOCTORMASTER_LIST,
        data,
      });
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getDoctorMasterMaxSort(callback) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.DoctorMaster.GetDoctorMasterMaxSort,
      });
      dispatch({
        type: CommonActionTypes.GET_DOCTORMASTER_MAX_SORT,
        data,
      });
      callback(data);
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getDoctorMasterInit(callback) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.DoctorMaster.GetDoctorMasterInit,
      });
      dispatch({
        type: CommonActionTypes.GET_DOCTORMASTER_INIT,
        data,
      });
      callback(data);
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function createDoctorMaster(body, callback) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.DoctorMaster.CreateDoctorMaster,
        body,
      });
      dispatch({
        type: CommonActionTypes.CREATE_DOCTORMASTER_SUCCESS,
        data,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: CommonActionTypes.CREATE_DOCTORMASTER_ERROR,
        error,
      });
    }
  };
}

function updateDoctorMaster(body, callback) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.DoctorMaster.UpdateDoctorMaster,
        body,
      });
      dispatch({
        type: CommonActionTypes.UPDATE_DOCTORMASTER_SUCCESS,
        data,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: CommonActionTypes.UPDATE_DOCTORMASTER_ERROR,
        error,
      });
    }
  };
}

function getIryouKikanMasterInit(callback) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.IryouKikanMaster.GetIryouKikanMasterInit,
      });
      dispatch({
        type: CommonActionTypes.GET_IRYOKIKAN_MASTER_INIT,
        data,
      });
      callback(data);
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function createIryouKikanMaster(body, callback) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.IryouKikanMaster.CreateIryouKikanMaster,
        body,
      });
      dispatch({
        type: CommonActionTypes.CREATE_IRYOKIKAN_MASTER_SUCCESS,
        data,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: CommonActionTypes.CREATE_IRYOKIKAN_MASTER_ERROR,
        error,
      });
    }
  };
}

function updateIryouKikanMaster(body, callback) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.IryouKikanMaster.UpdateIryouKikanMaster,
        body,
      });
      dispatch({
        type: CommonActionTypes.UPDATE_IRYOKIKAN_MASTER_SUCCESS,
        data,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: CommonActionTypes.UPDATE_IRYOKIKAN_MASTER_ERROR,
        error,
      });
    }
  };
}

function getByoumeiMaster(bunrui, name, callback) {
  return async (dispatch) => {
    try {
      const body = { bunrui: bunrui, name: name }
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.LifeList.getByomeiMaster,
        body,
      });
      dispatch({
        type: CommonActionTypes.GET_BYOMEIMASTER,
        data,
      });

      callback(data);
    } catch (error) {
      // dispatch({
      //   type: ErrorActionTypes.SET_ERROR,
      // });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

export const CommonActions = {
  getkaigodo,
  getMediSyoubyoumei,
  getMediSyoubyoumeiAll,
  getRiyouByoureki,
  getMediSyoubyoumeiByCode,
  getMediSyoubyoumeiByCodeDetail,
  getIyakuhinMaster,
  getIyakuhinMasterByCode,
  getDoctorMaster,
  getDoctorMasterMaxSort,
  getDoctorMasterInit,
  createDoctorMaster,
  updateDoctorMaster,
  getIryouKikanMasterInit,
  createIryouKikanMaster,
  updateIryouKikanMaster,
  getByoumeiMaster,
};
