import { riyousyaHokenActionTypes } from "../../actions/riyousyaHokenActions/actionName.js";

import {
  openNotificationWithIcon
} from "../../common/function_common/functionCommon.js";

// import { getValueLocalstorage } from '../../common/function_common/functionCommon.js';

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  loading: false,
  page: 1,
  page_total: 1,
  loadingUpload: false,
  isKeep: false,
  searchName: "",
  iryouHokenLoading: false
};

export default function riyousyaHokenReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    // case riyousyaHokenActionTypes.RIYOUSYA_KAIGOHOKEN_DELETE:
    //   openNotificationWithIcon("success", "削除しました");
    //   return {
    //     ...state,
    //     hokenAbove: state.hokenAbove.filter((item) => {
    //       return item.id !== action.id;
    //     }),
    //   };

    // case riyousyaHokenActionTypes.RIYOUSYA_KOHIJOHO_DELETE:
    //   openNotificationWithIcon("success", "削除しました");
    //   return {
    //     ...state,
    //     hokenBelow: state.hokenBelow.filter((item) => {
    //       return item.id !== action.id;
    //     }),
    //   };

    // case riyousyaHokenActionTypes.RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST:
    //   return {
    //     ...state,
    //     hokenLoading: true,
    //   };

    // case riyousyaHokenActionTypes.RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS:
    //   openNotificationWithIcon("success", "保存しました");
    //   let updateHoken1 = state.hokenAbove;
    //   const index1 = state.hokenAbove.findIndex(
    //     (item) => item.id === action.data.id
    //   );

    //   // case update
    //   if (index1 != null && index1 >= 0) {
    //     updateHoken1 = state.hokenAbove.map((item) => {
    //       return item.id === action.data.id
    //         ? Object.assign(item, action.data)
    //         : item;
    //     });
    //   } else {
    //     if (updateHoken1.every((item) => item.id != action.data.id)) {
    //       updateHoken1.unshift(action.data);
    //     }
    //   }

    //   return {
    //     ...state,
    //     hokenAbove: updateHoken1.map((a) => Object.assign({ ...a })),
    //     hokenLoading: false,
    //   };

    // case riyousyaHokenActionTypes.RIYOUSYA_KOHI_UPDATE_REQUEST:
    //   return {
    //     ...state,
    //     kohiLoading: true,
    //   };

    // case riyousyaHokenActionTypes.RIYOUSYA_KOHI_UPDATE_SUCCESS:
    //   openNotificationWithIcon("success", "保存しました");
    //   let updateHoken2 = state.hokenBelow;
    //   const index2 = state.hokenBelow.findIndex(
    //     (item) => item.id === action.data.id
    //   );

    //   // case update
    //   if (index2 != null && index2 >= 0) {
    //     updateHoken2 = state.hokenBelow.map((item) => {
    //       return item.id === action.data.id
    //         ? Object.assign(item, action.data)
    //         : item;
    //     });
    //   } else {
    //     if (updateHoken2.every((item) => item.id != action.data.id)) {
    //       updateHoken2.unshift(action.data);
    //     }
    //   }

    //   return {
    //     ...state,
    //     kohiLoading: false,
    //     hokenBelow: updateHoken2.map((a) => Object.assign({ ...a })),
    //   };

    // case riyousyaHokenActionTypes.KAIGOHOKEN_MASTER_SPIN_REQUEST:
    //   return {
    //     ...state,
    //     loadingKaigo: true,
    //   };
    // case riyousyaHokenActionTypes.LOAD_KAIGOHOKEN_MASTER_SUCCESS:
    //   return {
    //     ...state,
    //     kaigoHokenMasterList: action.data,
    //     loadingKaigo: false,
    //   };

    // case riyousyaHokenActionTypes.LOAD_KAIGOHOKEN_MASTER_ERROR:

    // case riyousyaHokenActionTypes.RIYOUSYA_HOKEN_ABOVE_REQUEST:
    //   return {
    //     ...state,
    //     // hokenLoading: true
    //   };

    // case riyousyaHokenActionTypes.RIYOUSYA_HOKEN_ABOVE_SUCCESS:
    //   return {
    //     ...state,
    //     // hokenLoading: false,
    //     hokenAbove:
    //       action.payload && action.payload.data
    //         ? action.payload.data
    //         : state.hokenAbove,
    //     pageHokenAbove:
    //       action.payload && action.payload.meta && action.payload.meta.page
    //         ? action.payload.meta.page
    //         : 1,
    //     pageTotalHokenAbove:
    //       action.payload &&
    //       action.payload.meta &&
    //       action.payload.meta.page_total
    //         ? action.payload.meta.page_total
    //         : 1,
    //   };

    // case riyousyaHokenActionTypes.RIYOUSYA_HOKEN_BELOW_REQUEST:
    //   return {
    //     ...state,
    //     // kohiLoading: true,
    //   };

    // case riyousyaHokenActionTypes.RIYOUSYA_HOKEN_BELOW_SUCCESS:
    //   return {
    //     ...state,
    //     // kohiLoading: false,
    //     hokenBelow:
    //       action.data && action.data.data
    //         ? action.data.data
    //         : state.mealBelow,
    //     pageHokenBelow:
    //       action.data && action.data.meta && action.data.meta.page
    //         ? action.data.meta.page
    //         : 1,
    //     pageTotalHokenBelow:
    //       action.data &&
    //       action.data.meta &&
    //       action.data.meta.page_total
    //         ? action.data.meta.page_total
    //         : 1,
    //   };
    //医療保険
    case riyousyaHokenActionTypes.GET_LIST_IRYOU_HOKEN_REQUEST:
      return {
        ...state,
        // kohiLoading: true,
      };
    case riyousyaHokenActionTypes.GET_LIST_IRYOU_HOKEN_SUCCESS:
      return {
        ...state,
        // kohiLoading: false,
        iryouhoken:
          action?.payload?.data
            ? action.payload.data
            : state.mealBelow,
        pageIryouhoken:
          action?.payload?.meta?.page
            ? action.payload.meta.page
            : 1,
        pageTotalIryouhoken:
          action?.payload?.meta?.page_total
            ? action.payload.meta.page_total
            : 1,
      };
    case riyousyaHokenActionTypes.GET_LIST_IRYOU_HOKEN_FAILURE:
      return {
        ...state,
        // kohiLoading: false,
      };

    case riyousyaHokenActionTypes.UPDATE_IRYOU_HOKEN_REQUEST:
      return {
        ...state,
        iryouHokenLoading: true,
      };
    case riyousyaHokenActionTypes.UPDATE_IRYOU_HOKEN_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      let updateIryouHoken = state.iryouhoken;
      const index1 = state.iryouhoken.findIndex(
        (item) => item.id === action.payload.id
      );

      // case update
      if (index1 != null && index1 >= 0) {
        updateIryouHoken = state.iryouhoken.map((item) => {
          return item.id === action.payload.id
            ? Object.assign(item, action.payload)
            : item;
        });
      } else {
        if (updateIryouHoken.every((item) => item.id != action.payload.id)) {
          updateIryouHoken.unshift(action.payload);
        }
      }

      return {
        ...state,
        iryouHokenLoading: false,
        iryouhoken: updateIryouHoken.map((a) => Object.assign({ ...a })),
      };
    case riyousyaHokenActionTypes.UPDATE_IRYOU_HOKEN_FAILURE:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        iryouHokenLoading: false,
        // updateIryouhoken: null,
      };

    case riyousyaHokenActionTypes.DELETE_IRYOU_HOKEN_SUCCESS:
      return {
        ...state,
        // iryouHokenLoading: true;
      };
    case riyousyaHokenActionTypes.DELETE_IRYOU_HOKEN_SUCCESS:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        // iryouHokenLoading: false;
        // iryouhoken: state.iryouhoken.filter((item) => {
        //   return item.id !== action.id;
        // }),
      };
    case riyousyaHokenActionTypes.DELETE_IRYOU_HOKEN_FAILURE:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        // iryouHokenLoading: false;
      };
    //高額医療費上限
    case riyousyaHokenActionTypes.GET_LIST_KOUGAKU_IRYOUHI_REQUEST:
      return {
        ...state,
        // kougakuIryouhoLoading: true,
      };
    case riyousyaHokenActionTypes.GET_LIST_KOUGAKU_IRYOUHI_SUCCESS:
      return {
        ...state,
        // kougakuIryouhoLoading: false,
        kougakuIryouhi:
          action?.payload?.data
            ? action.payload.data
            : state.kougakuIryouhi,
        pageKougakuIryouhi:
          action?.payload?.meta?.page
            ? action.payload.meta.page
            : 1,
        pageTotalKougakuIryouhi:
          action?.payload?.meta?.page_total
            ? action.payload.meta.page_total
            : 1,
      };
    case riyousyaHokenActionTypes.GET_LIST_KOUGAKU_IRYOUHI_FAILURE:
      return {
        ...state,
        // kougakuIryouhoLoading: false,
      };

    case riyousyaHokenActionTypes.UPDATE_KOUGAKU_IRYOUHI_REQUEST:
      return {
        ...state,
        kougakuIryouhiLoading: true,
      };
    case riyousyaHokenActionTypes.UPDATE_KOUGAKU_IRYOUHI_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      let updateKougakuIryohi = state.kougakuIryouhi;
      const index2 = state.kougakuIryouhi.findIndex(
        (item) => item.id === action.payload.id
      );

      // case update
      if (index2 != null && index2 >= 0) {
        updateKougakuIryohi = state.kougakuIryouhi.map((item) => {
          return item.id === action.payload.id
            ? Object.assign(item, action.payload)
            : item;
        });
      } else {
        if (updateKougakuIryohi.every((item) => item.id != action.payload.id)) {
          updateKougakuIryohi.unshift(action.payload);
        }
      }

      return {
        ...state,
        kougakuIryouhiLoading: false,
        kougakuIryouhi: updateKougakuIryohi.map((a) => Object.assign({ ...a })),
      };
    case riyousyaHokenActionTypes.UPDATE_KOUGAKU_IRYOUHI_FAILURE:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        kougakuIryouhiLoading: false,
      };

    case riyousyaHokenActionTypes.DELETE_KOUGAKU_IRYOUHI_REQUEST:
      return {
        ...state,
        kougakuIryouhiLoading: true,
      };
    case riyousyaHokenActionTypes.DELETE_KOUGAKU_IRYOUHI_SUCCESS:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        kougakuIryouhiLoading: false,
      };
    case riyousyaHokenActionTypes.DELETE_KOUGAKU_IRYOUHI_FAILURE:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        kougakuIryouhiLoading: false,
      };
    default:
      return state;
  }
}
