import React, { Fragment } from "react";
import {
  Modal,
  Input,
  Row,
  Col,
  Button,
  Checkbox,
  Spin,
  InputNumber,
} from "antd";
import { toKatakana } from "wanakana";
import { connect } from "react-redux";
import {
  loadKaigoHokenMasterInit,
  createKaigoHokenMaster,
  updateKaigoHokenMaster,
} from "../../../actions/kaigoHokenMasterActions/actionCreators";

import _ from "lodash";

const ADD = 1;
const EDIT = 2;
const COPY = 3;

// const reference = { table: "doctor_master", field: "code" }

class KaigoHokenMasterEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      initCode: null,
      errorNameVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      if (this.props.data && !(this.props.editType === ADD)) {
        if (this.props.editType === EDIT) {
          this.setState({
            data: this.props.data,
          });
        }
      } else {
        this.props.loadKaigoHokenMasterInit((data) => {
          const initDate = data ? { ...data, id: null } : {};
          this.setState({
            data: initDate,
          });
        });
      }
    }
  }

  //フォーム入力時
  onChangeValue(e, field) {
    const { data } = this.state;

    e.stopPropagation();
    let newValue = data[field];
    newValue = e.target.value;

    if (newValue !== data[field]) {
      this.setState({
        data: { ...data, [field]: newValue },
      });
    }
  }

  //保存
  async handleSave() {
    const { data } = this.state;

    if (this.checkRequired()) return;

    if (data.id && data.id > 0) {
      await this.props.updateKaigoHokenMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    } else {
      await this.props.createKaigoHokenMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    }

    this.handleClose();
  }
  checkRequired() {
    const { data } = this.state;

    let isError = false;
    if (_.isEmpty(data.hokenName)) {
      isError = true;
      this.setState({
        errorNameVisible: true,
      });
    } else {
      this.setState({
        errorNameVisible: false,
      });
    }

    return isError;
  }

  //閉じる
  handleClose() {
    this.setState({
      errorNameVisible: false,
    });
    this.props.handleClose();
  }

  render() {
    const { data, errorNameVisible, initCode } = this.state;

    return (
      (<Fragment>
        <Modal
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          open={this.props.visible}
          width={800}
          footer={[
            <Row key={1} type="flex" justify="end">
              <div style={{ paddingRight: 8 }}>
                <Button type={"primary"} onClick={() => this.handleClose()}>
                  {"戻る"}
                </Button>
              </div>
              <div>
                <Button type={"primary"} onClick={() => this.handleSave()}>
                  {"保存"}
                </Button>
              </div>
            </Row>,
          ]}
        >
          <Row>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>ID</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.id}
                onChange={(e) => this.onChangeValue(e, "id")}
                readOnly
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>保険者番号</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.hokenNo}
                // maxLength={10}
                // checkType={this.props.editType === ADD}
                // reference={reference}
                onChange={(e) => this.onChangeValue(e, "hokenNo")}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>保険者名</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.hokenName}
                onChange={(e) => this.onChangeValue(e, "hokenName")}
                style={errorNameVisible ? { border: "1px solid red" } : {}}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>給付率</p>
            </Col>
            <Col span={4} style={{ display: "flex" }}>
              <Input
                value={data.kyufu}
                onChange={(e) => this.onChangeValue(e, "kyufu")}
                // onBlur={(e) => this.handleFurigana(e, "furigana")}
              />
              <span style={{ fontSize: 14, marginTop: 5 }}>%</span>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>順番</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.sortNum}
                onChange={(e) => this.onChangeValue(e, "sortNum")}
              />
            </Col>
          </Row>
        </Modal>
      </Fragment>)
    );
  }
}

const mapStateToProps = () => (state) => ({
  loading: state.referenceNumber.loading || state.doctorMaster.loading,
});

const mapDispatchToProps = () => (dispatch) => ({
  loadKaigoHokenMasterInit: (callback) =>
    dispatch(loadKaigoHokenMasterInit(callback)),
  createKaigoHokenMaster: (body, callback) =>
    dispatch(createKaigoHokenMaster(body, callback)),
  updateKaigoHokenMaster: (body, callback) =>
    dispatch(updateKaigoHokenMaster(body, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KaigoHokenMasterEditModal);
