import { notification } from "antd/lib/index";
import {
  KEEP_VALUE,
  LOAD_SOCIAL_LIFE_ERROR,
  LOAD_SOCIAL_LIFE_REQUEST,
  LOAD_SOCIAL_LIFE_SUCCESS,
  RESET_STATUS,
  UPDATE_SOCIAL_LIFE_ERROR,
  UPDATE_SOCIAL_LIFE_REQUEST,
  UPDATE_SOCIAL_LIFE_SUCCESS
} from "../../actions/socialLifeActions/actionName";

const INITIAL_STATE = {
  messages: [],
  notifyDisplay: 0

};
export default function socialLifeReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        notifyContentInsert: {},
        notifyContentEdit: {}
      };
    case KEEP_VALUE:
      return {
        ...state,
        socialLife: action.data
      };
    case LOAD_SOCIAL_LIFE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_SOCIAL_LIFE_SUCCESS:
      return {
        ...state,
        loading: false,
        socialLife: action.data
      };
    case LOAD_SOCIAL_LIFE_ERROR:
      return {
        ...state,
        loading: false,
        socialLife: action.data
      };
    case UPDATE_SOCIAL_LIFE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SOCIAL_LIFE_SUCCESS:
      notification["success"]({
        message: "保存しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        socialLife: action.data,
        loading: false,
        notifyContentEdit: { type: "success" }
      };
    case UPDATE_SOCIAL_LIFE_ERROR:
      notification["error"]({
        message: action.error.message,
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        loading: false,
        notifyContentEdit: { type: "error" }
      };
    default:
      return state;

  }
}