import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { iryoKougakuFutanActionTypes } from "../../actions/iryoKougakuFutanMasterActions/actionName";

export default function iryoKougakuFutanMaster(state = {}, action) {
  switch (action.type) {
    case iryoKougakuFutanActionTypes.IRYO_KOUGAKU_FUTAN_MASTER_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //getList
    case iryoKougakuFutanActionTypes.IRYO_KOUGAKU_FUTAN_MASTER_GET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case iryoKougakuFutanActionTypes.IRYO_KOUGAKU_FUTAN_MASTER_GET_LIST_SUCCESS:
      return {
        ...state,
        datas: action.data,
        loading: false,
      };
    case iryoKougakuFutanActionTypes.IRYO_KOUGAKU_FUTAN_MASTER_GET_LIST_ERROR:
      return {
        ...state,
        datas: null,
        loading: false,
      };

    // //init
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_INIT_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_INIT_SUCCESS:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_INIT_ERROR:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // // create
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_CREATE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_CREATE_SUCCESS:
    //   openNotificationWithIcon("success", "保存しました");
    //   return {
    //     ...state,
    //     loading: false,
    //     create: action.data,
    //   };
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_CREATE_ERROR:
    //   openNotificationWithIcon("error", "エラー");
    //   return {
    //     ...state,
    //     create: null,
    //     loading: false,
    //   };

    // // update
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATE_SUCCESS:
    //   openNotificationWithIcon("success", "保存しました");
    //   return {
    //     ...state,
    //     loading: true,
    //     update: action.data,
    //   };
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATE_ERROR:
    //   openNotificationWithIcon("error", "エラー");
    //   return {
    //     ...state,
    //     loading: false,
    //     update: null,
    //   };

    // // updateList
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_SUCCESS:
    //   openNotificationWithIcon("success", "保存しました");
    //   return {
    //     ...state,
    //     updateList: action.data,
    //     loading: false,
    //   };
    // case iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_ERROR:
    //   openNotificationWithIcon("error", "エラー");
    //   return {
    //     ...state,
    //     updateList: null,
    //     loading: false,
    //   };

    // // maxSort
    // case iryoKougakuFutanActionTypes.LOAD_MAX_SORT_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case iryoKougakuFutanActionTypes.LOAD_MAX_SORT_SUCCESS:
    //   return {
    //     ...state,
    //     maxSort: action.data,
    //     loading: false,
    //   };
    // case iryoKougakuFutanActionTypes.LOAD_MAX_SORT_ERROR:
    //   return {
    //     ...state,
    //     maxSort: null,
    //     loading: false,
    //   };
    default:
      return state;
  }
}