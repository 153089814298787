/* eslint-disable no-nested-ternary */
import _ from "lodash";
import React, { Component } from "react";
import DatePickerControl from "../../../common/component/control/DatePickerControl";
import { ConstSet } from "../../../common/configs/constset";
import { CommandButtons } from "./CommandButtons";
import { ModeSwitch } from "./ModeSwitch";

export default class Toolbar extends Component {
  render() {
    const plans = this.props.data,
      selectedPlans = [];
    const formatedDates = this.props.selectedValues.map((i) =>
      i.format(ConstSet.DATE_FORMAT)
    );
    formatedDates.forEach((d) => {
      let plan = _.find(plans, (i) => i.date === d);
      if (plan == null) {
        plan =
          this.props.mode === "event"
            ? {
              date: d,
              eventPlanId: null,
              event: null,
            }
            : {
              date: d,
              riyousyaId: this.props.riyousyaId,
              riyousyaPlanId: null,
              yotei: null,
              sonota: null,
            };
      }
      selectedPlans.push(plan);
    });
    return (
      <div style={this.props.style}>
        <DatePickerControl
          style={{ display: "inline" }}
          value={this.props.selectedDate}
          mode={"month"}
          onChange={(value) => this.props.handleSelectMonth(value)}
        />
        {this.props.riyousyaId ? null : (
          <ModeSwitch
            style={{ display: "inline", paddingLeft: "20px" }}
            mode={this.props.mode}
            handleChangeMode={(mode) => this.props.handleChangeMode(mode)}
          />
        )}
        {this.props.mode === "event" ? (
          <CommandButtons
            selectedPlans={selectedPlans}
            handleInputPlan={(selectedPlans) =>
              this.props.handleInputEventPlan(selectedPlans)
            }
            handleDeletePlan={(eventPlanIds) =>
              this.props.handleDeleteEventPlan(eventPlanIds)
            }
            yougoMasterEditState={(index) =>
              this.props.yougoMasterEditState(index)
            }
            yougoMasterEditCancel={(index) =>
              this.props.yougoMasterEditCancel(index)
            }
            yougoMasterEditSave={(naiyou) =>
              this.props.yougoMasterEditSave(naiyou)
            }
            yougoMasterNaiyou={this.props.yougoMasterNaiyou}
          />
        ) : this.props.riyousyaId ? (
          <CommandButtons
            riyousyaId={this.props.riyousyaId}
            selectedPlans={selectedPlans}
            handleInputPlan={(data) => this.props.handleInputUserPlan(data)}
            handleDeletePlan={(riyousyaPlanIds) =>
              this.props.handleDeleteUserPlan(riyousyaPlanIds)
            }
            yougoMasterEditState={(index) =>
              this.props.yougoMasterEditState(index)
            }
            yougoMasterEditCancel={(index) =>
              this.props.yougoMasterEditCancel(index)
            }
            yougoMasterEditSave={(naiyou) =>
              this.props.yougoMasterEditSave(naiyou)
            }
            yougoMasterNaiyou={this.props.yougoMasterNaiyou}
          />
        ) : null}
      </div>
    );
  }
}
