import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Checkbox, Radio } from "antd";
function ColorUseSlipModal(props) {
  const [recordSelected, setRecordSelected] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.data) {
      // add type 17
      let arrInit = props.data;

      arrInit.push({
        kaigoServiceSyuruiCd: "17",
        ryaku: "レンタル",
        serviceType: "レンタル",
      });
      // cal row 4 item
      let arr = [];
      let dataArr = [];
      for (let i = 0; i < arrInit.length; i++) {
        arr.push(arrInit[i]);
        if (
          (i != 0 && i % 4 == 3) ||
          (i == arrInit.length - 1 && (arrInit.length - 1) % 4 != 3)
        ) {
          dataArr.push(arr);
          arr = [];
        }
      }
      setData(dataArr);
    }
  }, [props.data]);
  
  function handleGenerateColorItem(item) {
    const filterArray = item.reduce((accumulator, currentItem) => {
      const existingItem = accumulator.find(item => item.ryaku === currentItem.ryaku);
      if (!existingItem) {
        accumulator.push(currentItem);
      }
      return accumulator;
    }, []);

    return filterArray.map((record, index) => (
      <td key={index} style={{ width: "250px" }}>
        <Radio
          value={record}
          onChange={handleSelectedRow}
          style={{
            padding: "3px 0px",
            margin: "1px",
            width: "100%",
            backgroundColor: `${record.swColorBak}`,
          }}
        >
          {record.ryaku}
        </Radio>
      </td>
    ))
  }

  function handleSelectedRow(e) {
    setRecordSelected(e.target.value);
  }

  function handleCancel() {
    props.onHideColorModal();
    setRecordSelected(null);
  }
  function handleOk() {
    props.onSelectedColor(recordSelected);
    setRecordSelected(null);
  }
  return (
    (<Modal
      open={props.visible}
      onCancel={() => handleCancel()}
      closable={false}
      footer={[
        <Button
          key="ok"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={() => handleCancel()}
        >
          キャンセル
        </Button>,
        <Button
          key="back"
          type="primary"
          onClick={() => handleOk()}
          disabled={(props.data && props.data.length === 0) || !recordSelected}
        >
          OK
        </Button>,
      ]}
      width={500}
      className={props.className ? props.className : "color-master-modal"}
    >
      <div>
        <table>
          <Radio.Group onChange={handleSelectedRow} value={recordSelected}>
            {data &&
              data.map((item, indexRow) => (
                <tr key={indexRow}>
                  {handleGenerateColorItem(item)}
                </tr>
              ))}
          </Radio.Group>
        </table>
      </div>
    </Modal>)
  );
}

export { ColorUseSlipModal };
