/* eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

const fabric = require("fabric").fabric;

class TwoRectangle extends FabricCanvasTool {

  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => o.selectable = o.evented = false);
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.call_back = props.update_select;
  }


  doMouseDown(o) {
    const canvas = this._canvas;
    this.isDown = true;
    const pointer = canvas.getPointer(o.e);
    [this.startX, this.startY] = [pointer.x, pointer.y];
    const rectangle = new fabric.Rect({
      left: this.startX,
      top: this.startY,
      originX: "center",
      originY: "center",
      width: 100,
      height: 100,
      stroke: this._color,
      strokeWidth: this._width,
      fill: this._fill,
      // fill: 'rgba(255,0,0,0.5)',
      transparentCorners: false,
      selectable: false,
      evented: false,
      angle: 0
    });
    const rectangle2 = new fabric.Rect({
      left: this.startX,
      top: this.startY,
      originX: "center",
      originY: "center",
      width: 80,
      height: 80,
      stroke: this._color,
      strokeWidth: this._width,
      fill: this._fill,
      // fill: 'rgba(255,0,0,0.5)',
      transparentCorners: false,
      selectable: false,
      evented: false,
      angle: 0
    });
    this.groupc = new fabric.Group([rectangle, rectangle2], {
      left: this.startX,
      top: this.startY,
      angle: 0,
      selectable: true,
    });
    canvas.add(this.groupc);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    const canvas = this._canvas;
    const pointer = canvas.getPointer(o.e);


    this.groupc.set(
      {
        scaleX: pointer.x / this.startX,
        scaleY: pointer.x / this.startX,
      }
    );

    this.groupc.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.call_back();
  }
}

export default TwoRectangle;