import { RESET_STATUS, DOCTOR_OPINION_GET, DOCTOR_OPINION_UPDATE, DOCTOR_OPINION_UPDATE_ERROR } from "../../actions/doctoropinionActions/actionName";
import { notification } from "antd/lib/index";

export default function DoctorOpinionReducers(state = {
  loading: true
}, action) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        notifyContentEdit: {}
      }
    case DOCTOR_OPINION_GET:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case DOCTOR_OPINION_UPDATE:
      notification["success"]({
        message: "保存しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        data: action.data,
        loading: false,
        notifyContentEdit: { type: "success" }
      };
    case DOCTOR_OPINION_UPDATE_ERROR:
      notification["error"]({
        message: "エラーが発生しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        state,
        data: state.data,
        loading: false,
        notifyContentEdit: { type: "error" }
      };
    default:
      return state;
  }
}