import React from "react";
import { Row, Col, Divider } from "antd";
import LineBreakText from "../../../common/component/LineBreakText";

const NyuutaiinInfo = (props) => {
  let items =
    props.riyousya &&
    props.riyousya.riyouNyuin &&
    props.riyousya.riyouNyuin.length > 0
      ? props.riyousya.riyouNyuin
      : null;

  return (
    <div>
      {items
        ? items.map((item, index) => {
            return (
              <Row key={index}>
                {index > 0 ? <Divider /> : null}
                <Col sm={12}>
                  <Row>
                    <Col xs={12} className="rdp-basic-tab-title">
                      施設名
                    </Col>
                    <Col xs={12}>{item.hospital}</Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="rdp-basic-tab-title">
                      入院日
                    </Col>
                    <Col xs={12}>{item.nyuinDate}</Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="rdp-basic-tab-title">
                      退院日
                    </Col>
                    <Col xs={12}>{item.tainDate}</Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="rdp-basic-tab-title">
                      備考
                    </Col>
                    <Col xs={12}>
                      <LineBreakText text={item.bikou ? item.bikou : "　"} />
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col xs={12} className="rdp-basic-tab-title">
                      入院理由
                    </Col>
                    <Col xs={12}>{item.nyuinRiyu}</Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="rdp-basic-tab-title">
                      退院理由
                    </Col>
                    <Col xs={12}>{item.tainRiyu}</Col>
                  </Row>
                </Col>
              </Row>
            );
          })
        : null}
    </div>
  );
};

export default NyuutaiinInfo;
