import React from "react";
import { Row, Col, Divider } from "antd";
import LineBreakText from "../../../common/component/LineBreakText";
import {
  getMenu,
  formatStorage,
} from "../../../common/function_common/functionCommon";

const SonotaInfo = (props) => {
  var jigyosyofacesheet = {};
  if (props.facesheet && props.facesheet.jigyosyoFacesheet) {
    let mainSID = formatStorage(getMenu()).si;
    let jf = props.facesheet.jigyosyoFacesheet.find(
      (item) => item.serviceKikanMasterId.toString() === mainSID.toString()
    );
    if (jf !== undefined) {
      jigyosyofacesheet = jf;
    }
  }

  return (
    <div>
      {props.facesheet && props.setFSSonotaTitle
        ? props.setFSSonotaTitle
            .toString()
            .split(";")
            .map((item, index) => {
              if (item !== "none") {
                return (
                  <Row key={index}>
                    <Col span={8}>
                      <h4>
                        {props.fsSonotaTitleDis
                          ? props.fsSonotaTitleDis.toString().split(";")[index]
                          : null}
                      </h4>
                    </Col>
                    <Col span={16}>
                      <LineBreakText
                        text={
                          props.facesheet &&
                          props.facesheet[`sonota${index + 1}`]
                            ? props.facesheet[`sonota${index + 1}`]
                            : "　"
                        }
                      />
                    </Col>
                    <Divider />
                  </Row>
                );
              }
            })
        : null}

      {props.facesheet && props.system_setFSSonotaTitle
        ? props.system_setFSSonotaTitle
            .toString()
            .split(";")
            .map((item, index) => {
              if (item !== "none") {
                return (
                  <Row key={index}>
                    <Col span={8}>
                      <h4>
                        {props.system_fsSonotaTitleDis
                          ? props.system_fsSonotaTitleDis.toString().split(";")[
                              index
                            ]
                          : null}
                      </h4>
                    </Col>
                    <Col span={16}>
                      <LineBreakText
                        text={
                          jigyosyofacesheet
                            ? jigyosyofacesheet[`jigyosyoSonota${index + 1}`]
                            : "　"
                        }
                      />
                    </Col>
                    <Divider />
                  </Row>
                );
              }
            })
        : null}
    </div>
  );
};

export default SonotaInfo;
