import React, { Fragment } from "react";
import { Modal, Input, Row, Col, Button, Checkbox, Spin } from "antd";
import { toKatakana } from "wanakana";
import { connect } from "react-redux";
import {
  loadMaxSort,
  loadDoctorMasterInit,
  createDoctorMaster,
  updateDoctorMaster,
} from "../../../actions/doctorMasterActions/actionCreators";
import { getKyotakuPlan2YougoMaster } from "../../../actions/kyotakuPlan2Actions/actionCreators";
import { zipMasterSearchCreator } from "../../../actions/zipMasterActions/actionCreators";
import MasterCodeInput from "./MasterCodeInput";
import MasterCodeCancelButton from "./MasterCodeCancelButton";
import IryoKikanMasterModal from "./IryoKikanMasterModal";
import SearchYubinModal from "./SearchYubinModal";
import YougoModal from "./YougoModal";
import _ from "lodash";

const ADD = 1;
const EDIT = 2;
const COPY = 3;

const reference = { table: "doctor_master", field: "code" };

class DoctorMasterEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      initCode: null,
      visibleIryoKikan: false,
      visibleZip: false,
      checkZip: false,
      visibleYougo: false,
      listName: "",
      titleYougo: "",
      errorNameVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      if (this.props.data && !(this.props.editType === ADD)) {
        if (this.props.editType === EDIT) {
          this.setState({
            data: this.props.data,
          });
        } else if (this.props.editType === COPY) {
          this.props.loadMaxSort((data) => {
            let copyData = {
              ...this.props.data,
              id: null,
              code: data.code || "",
              sortNum: data.sortNum || "",
            };
            this.setState({
              data: copyData,
            });
          });
        }
      } else {
        this.props.loadDoctorMasterInit((data) => {
          const initDate = data
            ? { ...data, id: null, code: this.state.data.code }
            : {};
          this.setState({
            data: initDate,
          });
        });
      }
    }

    if (this.props.zipSearch !== prevProps.zipSearch && this.state.checkZip) {
      if (this.props.zipSearch.length === 1) {
        let record = this.props.zipSearch[0];
        this.setState({
          data: {
            ...this.state.data,
            zip: record.zip || "",
            address: record.address || "",
            checkZip: false,
          },
        });
      } else {
        this.setState({
          visibleZip: true,
          checkZip: false,
        });
      }
    }
  }

  onChangeValue(e, field) {
    const { data } = this.state;

    e.stopPropagation();
    let newValue = data[field];
    switch (field) {
      case "stop":
        newValue = e.target.checked ? 1 : 0;
        break;
      case "zaitakuSien":
        newValue = e.target.checked ? 1 : 0;
        break;
      default:
        newValue = e.target.value;
    }

    if (newValue !== data[field]) {
      this.setState({
        data: { ...data, [field]: newValue },
      });
    }
  }

  onChangeCode(value, first) {
    const { data } = this.state;

    if (first) {
      this.setState({
        data: { ...data, code: value },
        initCode: value,
      });
    } else {
      this.setState({
        data: { ...data, code: value },
      });
    }
  }

  async handleSave() {
    const { data } = this.state;

    if (this.checkRequired()) return;

    if (data.id && data.id > 0) {
      await this.props.updateDoctorMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    } else {
      await this.props.createDoctorMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    }

    this.handleClose();
  }

  checkRequired() {
    const { data } = this.state;

    let isError = false;
    if (_.isEmpty(data.name)) {
      isError = true;
      this.setState({
        errorNameVisible: true,
      });
    } else {
      this.setState({
        errorNameVisible: false,
      });
    }

    return isError;
  }

  handleFurigana(e, field) {
    const { data } = this.state;

    e.preventDefault();
    const value = toKatakana(e.target.value);
    this.setState({
      data: { ...data, [field]: value },
    });
  }

  openIryoKikan() {
    this.setState({
      visibleIryoKikan: true,
    });
  }

  closeIryoKikan() {
    this.setState({
      visibleIryoKikan: false,
    });
  }

  onSubmiIryoKikan(record) {
    const { data } = this.state;

    if (record) {
      this.setState({
        data: {
          ...data,
          hospital: record.hospital || "",
          hospitalFurigana: record.furigana || "",
          zip: record.zip || "",
          address: record.address || "",
          tel: record.tel || "",
          fax: record.fax || "",
        },
      });
    }
    this.closeIryoKikan();
  }

  openSearchYubin() {
    const { data } = this.state;

    this.props.getZipMasterSearch(data.zip);
    this.setState({
      checkZip: true,
    });
  }

  closeSearchYubinBango() {
    this.setState({
      visibleZip: false,
    });
  }

  onSubmitZip(record) {
    const { data } = this.state;

    if (record) {
      this.setState({
        data: {
          ...data,
          zip: record.zip || "",
          address: record.address || "",
        },
      });
    }
    this.closeSearchYubinBango();
  }

  openYougoMaster(listName, titleYougo) {
    this.props.getYougoMaster(listName);
    this.setState({
      visibleYougo: true,
      listName: listName,
      titleYougo: titleYougo,
    });
  }

  closeYougo() {
    this.setState({
      visibleYougo: false,
      listName: "",
      titleYougo: "",
    });
  }

  onSubmitYougo(values) {
    const { data } = this.state;

    const newValue = !data.bunrui
      ? `${values}`
      : data.bunrui + "、" + `${values}`;
    this.setState({
      data: { ...data, bunrui: newValue },
    });

    this.closeYougo();
  }

  handleClose() {
    this.setState({
      errorNameVisible: false,
    });
    this.props.handleClose();
  }

  render() {
    const { data, errorNameVisible, initCode } = this.state;

    return (
      (<Fragment>
        <Modal
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          open={this.props.visible}
          width={800}
          footer={[
            <Row key={1} type="flex" justify="end">
              <div style={{ paddingRight: 8 }}>
                <MasterCodeCancelButton
                  text={"戻る"}
                  code={initCode}
                  reference={reference}
                  checkType={this.props.editType === ADD}
                  onClose={() => this.handleClose()}
                />
              </div>
              <div>
                <Button type={"primary"} onClick={() => this.handleSave()}>
                  {"保存"}
                </Button>
              </div>
            </Row>,
          ]}
        >
          <Row>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>ID</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.id}
                onChange={(e) => this.onChangeValue(e, "id")}
                readOnly
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>整理番号</p>
            </Col>
            <Col span={4}>
              <MasterCodeInput
                value={data.code}
                maxLength={10}
                checkType={this.props.editType === ADD}
                reference={reference}
                onChange={(value, first) => this.onChangeCode(value, first)}
              />
            </Col>
            <Col span={14}>
              <Checkbox
                type={"checkbox"}
                checked={data.stop}
                style={{ marginTop: 5, marginLeft: 50 }}
                onChange={(e) => this.onChangeValue(e, "stop")}
              >
                利用中止
              </Checkbox>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>医師名</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.name}
                onChange={(e) => this.onChangeValue(e, "name")}
                style={errorNameVisible ? { border: "1px solid red" } : {}}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>フリガナ</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.furigana}
                onChange={(e) => this.onChangeValue(e, "furigana")}
                onBlur={(e) => this.handleFurigana(e, "furigana")}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>病医院名</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.hospital}
                onChange={(e) => this.onChangeValue(e, "hospital")}
              />
            </Col>
            <Col span={2} style={{ marginLeft: 5 }}>
              <Button type={"primary"} onClick={() => this.openIryoKikan()}>
                {"M"}
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>病医院名フリガナ</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.hospitalFurigana}
                onChange={(e) => this.onChangeValue(e, "hospitalFurigana")}
                onBlur={(e) => this.handleFurigana(e, "hospitalFurigana")}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>郵便番号</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.zip}
                onChange={(e) => this.onChangeValue(e, "zip")}
                maxLength={8}
              />
            </Col>
            <Col span={4} style={{ marginLeft: 5 }}>
              <Button type={"primary"} onClick={() => this.openSearchYubin()}>
                〒
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>住所</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.address}
                onChange={(e) => this.onChangeValue(e, "address")}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>電話</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.tel}
                onChange={(e) => this.onChangeValue(e, "tel")}
                maxLength={12}
              />
            </Col>
            <Col span={2} style={{ marginLeft: 30 }}>
              <p style={{ marginTop: 5 }}>FAX</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.fax}
                onChange={(e) => this.onChangeValue(e, "fax")}
                maxLength={12}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>緊急連絡先</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.urgent}
                onChange={(e) => this.onChangeValue(e, "urgent")}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>メールアドレス</p>
            </Col>
            <Col span={18}>
              <Input
                value={data.mailAddress}
                onChange={(e) => this.onChangeValue(e, "mailAddress")}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>順番</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.sortNum}
                onChange={(e) => this.onChangeValue(e, "sortNum")}
              />
            </Col>
            <Col span={14}>
              <Checkbox
                type={"checkbox"}
                checked={data.zaitakuSien}
                style={{ marginTop: 5, marginLeft: 50 }}
                onChange={(e) => this.onChangeValue(e, "zaitakuSien")}
              >
                在宅支援
              </Checkbox>
            </Col>
          </Row>
          <Row style={{ marginTop: 10, marginBottom: 20 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>分類</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.bunrui}
                onChange={(e) => this.onChangeValue(e, "bunrui")}
              />
            </Col>
            <Col span={2} style={{ marginLeft: 5 }}>
              <Button
                type={"primary"}
                onClick={() => this.openYougoMaster("医師分類", "医師分類")}
              >
                {"M"}
              </Button>
            </Col>
          </Row>
        </Modal>
        <YougoModal
          visible={this.state.visibleYougo}
          listName={this.state.listName}
          onCancel={() => this.closeYougo()}
          onSubmit={(values) => this.onSubmitYougo(values)}
        />
        <IryoKikanMasterModal
          visible={this.state.visibleIryoKikan}
          onSubmit={(record) => this.onSubmiIryoKikan(record)}
          onCancel={() => this.closeIryoKikan()}
        />
        <SearchYubinModal
          visible={this.state.visibleZip}
          loading={this.props.zipLoading}
          data={this.props.zipSearch}
          zip={data.zip}
          searchYubinBango={(zip) => this.props.getZipMasterSearch(zip)}
          onSubmit={(record) => this.onSubmitZip(record)}
          onCancel={() => this.closeSearchYubinBango()}
        />
      </Fragment>)
    );
  }
}

const mapStateToProps = () => (state) => ({
  loading: state.referenceNumber.loading || state.doctorMaster.loading,
  zipLoading: state.zipMaster.loading,
  zipSearch: state.zipMaster.zip,
});

const mapDispatchToProps = () => (dispatch) => ({
  loadMaxSort: (callback) => dispatch(loadMaxSort(callback)),
  loadDoctorMasterInit: (callback) => dispatch(loadDoctorMasterInit(callback)),
  createDoctorMaster: (body, callback) =>
    dispatch(createDoctorMaster(body, callback)),
  updateDoctorMaster: (body, callback) =>
    dispatch(updateDoctorMaster(body, callback)),
  getYougoMaster: (listName) => dispatch(getKyotakuPlan2YougoMaster(listName)),
  getZipMasterSearch: (zip) => dispatch(zipMasterSearchCreator(zip)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorMasterEditModal);
