import { Icon as LegacyIcon } from "@ant-design/compatible";
import { CaretUpOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Affix, Button, Col, Collapse, Modal, Row, Upload } from "antd";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";

import _ from "lodash";
import { guid } from "../../common/timePicker/formatTimePicker";
import { FormBasic } from "./UI/FormBasic";

import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import { formatStorage, getBase64, getMenu, getValueIskeep, riyouMergeFormToState, setValueIsKeep } from "../../common/function_common/functionCommon";
import { displaySeireki } from "../../common/function_common/functionDisplay";

import "./Riyousya.css";
import { FormAdl } from "./UI/FormAdl";
import { FormByouki } from "./UI/FormByouki";
import { FormChart } from "./UI/FormChart";
import { FormDoctor } from "./UI/FormDoctor";
import { FormFacesheet } from "./UI/FormFacesheet";
import { FormKankei } from "./UI/FormKankei";
import { FormKiki } from "./UI/FormKiki";
import { FormKusuri } from "./UI/FormKusuri";
import { FormNyuin } from "./UI/FormNyuin";
import { FormOther } from "./UI/FormOther";
import { FormOther2 } from "./UI/FormOther2";
import { FormService } from "./UI/FormService";
import { FormStatus } from "./UI/FormStatus";
// import FormChart from "./UI/FormChart";
import { FormCaremanager } from "./UI/FormCaremanager";

import noImage from "../../common/images/no-image.png";

import { isManagement } from "../../common/function_common/functionCommon";


const FA = require("react-fontawesome");

const { Panel } = Collapse;

const customPanelStyle = {
  background: "#ddd",
  borderRadius: 4,
  marginBottom: 2,
  border: 0,
  overflow: "hidden",
};

class RiyousyaUpdate extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;

    const query = new URLSearchParams(this.props.location.search);

    /**
     * Open tab when start is taken form localStorate
     *
     */
    const riyousyaTabInfo = getValueIskeep("riyousya_tab_info");
    const isOpen1 = riyousyaTabInfo ? riyousyaTabInfo.tab1 : "";
    const isOpen2 = riyousyaTabInfo ? riyousyaTabInfo.tab2 : "";
    const isOpen3 = riyousyaTabInfo ? riyousyaTabInfo.tab3 : "";
    const isOpen4 = riyousyaTabInfo ? riyousyaTabInfo.tab4 : "";
    const isOpen5 = riyousyaTabInfo ? riyousyaTabInfo.tab5 : "";
    const isOpen6 = riyousyaTabInfo ? riyousyaTabInfo.tab6 : "";
    const isOpen7 = riyousyaTabInfo ? riyousyaTabInfo.tab7 : "";
    const isOpen8 =
      riyousyaTabInfo && riyousyaTabInfo.tab8
        ? riyousyaTabInfo.tab8
        : query.get("faceId") > 0 && query.get("type") === "copy"
          ? "8"
          : "";
    const isOpen9 =
      riyousyaTabInfo && riyousyaTabInfo.tab9
        ? riyousyaTabInfo.tab9
        : query.get("faceId") > 0 && query.get("type") === "copy"
          ? "9"
          : "";
    const isOpen10 =
      riyousyaTabInfo && riyousyaTabInfo.tab10
        ? riyousyaTabInfo.tab10
        : query.get("faceId") > 0 && query.get("type") === "copy"
          ? "10"
          : "";
    const isOpen11 =
      riyousyaTabInfo && riyousyaTabInfo.tab11
        ? riyousyaTabInfo.tab11
        : query.get("faceId") > 0 && query.get("type") === "copy"
          ? "11"
          : "";
    const isOpen12 = riyousyaTabInfo ? riyousyaTabInfo.tab12 : "";
    const isOpen13 = riyousyaTabInfo ? riyousyaTabInfo.tab13 : "";

    this.state = {
      visible: false,
      isOpen: false,
      isOpen1,
      isOpen2,
      isOpen3,
      isOpen4,
      isOpen5,
      isOpen6,
      isOpen7,
      isOpen8,
      isOpen9,
      isOpen10,
      isOpen11,
      isOpen12,
      isOpen13,

      riyousyaId: match.params.riyousyaId,
      facesheetId: query.get("faceId"),
      facesheetType: query.get("type"),

      recordUpdate: null,
      imageUrl: "",
      isChangeAvatar: false,
      isConfirmDelAvatar: false,
      isOnChangeForm: false,
    };
  }

  /**
   *
   */
  componentDidMount() {
    const { riyousyaId } = this.state;
    this.props.getRiyousya(riyousyaId);

    // get setting_master
    this.props.getSettingALL("", "");

    // get kihon_adl_master
    this.props.getKihonAdlAll();

    // load yougo master setting
    this.props.ymRiyouUpdatePage();

    // load byoumei master
    this.props.byoumeiMasterList();

    // load drug master
    this.props.drugMasterList();

    // load machine master
    this.props.machineMasterList();

    // load service ryurui master
    this.props.serviceRyuruiMasterList();

    // load service kikam master
    this.props.serviceKikanMasterList();

    // load service kikam master
    this.props.iryouKikanMasterList();
  }

  /**
   *
   */
  componentDidUpdate(preProps) {
    const { riyousyaDetail } = this.props;

    if (riyousyaDetail !== preProps.riyousyaDetail) {
      this.setState({
        recordUpdate: riyousyaDetail,
      });
    }
  }

  onChangePanel = (key) => {
    switch (key) {
      case "1":
        const isOpen1 = this.state.isOpen1;
        if (isOpen1) {
          this.setState({
            isOpen1: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab1");
        }
        else {
          this.setState({
            isOpen1: "1",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "1", "tab1");
        }
        return;

      case "2":
        const isOpen2 = this.state.isOpen2;
        if (isOpen2) {
          this.setState({
            isOpen2: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab2");
        }
        else {
          this.setState({
            isOpen2: "2",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "2", "tab2");
        }
        return;

      case "3":
        const isOpen3 = this.state.isOpen3;
        if (isOpen3) {
          this.setState({
            isOpen3: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab3");
        }
        else {
          this.setState({
            isOpen3: "3",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "3", "tab3");
        }
        return;

      case "4":
        const isOpen4 = this.state.isOpen4;
        if (isOpen4) {
          this.setState({
            isOpen4: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab4");
        }
        else {
          this.setState({
            isOpen4: "4",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "4", "tab4");
        }
        return;

      case "5":
        const isOpen5 = this.state.isOpen5;
        if (isOpen5) {
          this.setState({
            isOpen5: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab5");
        }
        else {
          this.setState({
            isOpen5: "5",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "5", "tab5");
        }
        return;

      case "6":
        const isOpen6 = this.state.isOpen6;
        if (isOpen6) {
          this.setState({
            isOpen6: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab6");
        }
        else {
          this.setState({
            isOpen6: "6",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "6", "tab6");
        }
        return;

      case "7":
        const isOpen7 = this.state.isOpen7;
        if (isOpen7) {
          this.setState({
            isOpen7: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab7");
        }
        else {
          this.setState({
            isOpen7: "7",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "7", "tab7");
        }
        return;

      case "8":
        const isOpen8 = this.state.isOpen8;
        if (isOpen8) {
          this.setState({
            isOpen8: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab8");
        }
        else {
          this.setState({
            isOpen8: "8",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "8", "tab8");
        }
        return;

      case "9":
        const isOpen9 = this.state.isOpen9;
        if (isOpen9) {
          this.setState({
            isOpen9: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab9");
        }
        else {
          this.setState({
            isOpen9: "9",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "9", "tab9");
        }
        return;

      case "10":
        const isOpen10 = this.state.isOpen10;
        if (isOpen10) {
          this.setState({
            isOpen10: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab10");
        }
        else {
          this.setState({
            isOpen10: "10",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "10", "tab10");
        }
        return;

      case "11":
        const isOpen11 = this.state.isOpen11;
        if (isOpen11) {
          this.setState({
            isOpen11: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab11");
        }
        else {
          this.setState({
            isOpen11: "11",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "11", "tab11");
        }
        return;

      case "12":
        const isOpen12 = this.state.isOpen12;
        if (isOpen12) {
          this.setState({
            isOpen12: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab12");
        }
        else {
          this.setState({
            isOpen12: "12",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "12", "tab12");
        }
        return;

      case "13":
        const isOpen13 = this.state.isOpen13;
        if (isOpen13) {
          this.setState({
            isOpen13: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab13");
        }
        else {
          this.setState({
            isOpen13: "13",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "13", "tab13");
        }
        return;

      default:
        return;
    }
  };

  handleOpenColl = () => {
    const { isOpen } = this.state;

    if (!isOpen) {
      this.setState({
        isOpen: !isOpen,
        isOpen1: "1",
        isOpen2: "2",
        isOpen3: "3",
        isOpen4: "4",
        isOpen5: "5",
        isOpen6: "6",
        isOpen7: "7",
        isOpen8: "8",
        isOpen9: "9",
        isOpen10: "10",
        isOpen11: "11",
        isOpen12: "12",
        isOpen13: "13",
      });
      // setValueToLocalStorae
      const riyousyaTabInfo = {
        tab1: "1",
        tab2: "2",
        tab3: "3",
        tab4: "4",
        tab5: "5",
        tab6: "6",
        tab7: "7",
        tab8: "8",
        tab9: "9",
        tab10: "10",
        tab11: "11",
        tab12: "12",
        tab13: "13",
      };
      setValueIsKeep("riyousya_tab_info", riyousyaTabInfo);
    }
    else {
      this.setState({
        isOpen: !isOpen,
        isOpen1: "",
        isOpen2: "",
        isOpen3: "",
        isOpen4: "",
        isOpen5: "",
        isOpen6: "",
        isOpen7: "",
        isOpen8: "",
        isOpen9: "",
        isOpen10: "",
        isOpen11: "",
        isOpen12: "",
        isOpne13: "",
      });

      const riyousyaTabInfo = {
        tab1: "",
        tab2: "",
        tab3: "",
        tab4: "",
        tab5: "",
        tab6: "",
        tab7: "",
        tab8: "",
        tab9: "",
        tab10: "",
        tab11: "",
        tab12: "",
        tab13: "",
      };
      setValueIsKeep("riyousya_tab_info", riyousyaTabInfo);
    }
  };

  /**
   * Handle input and save process
   *
   */
  saveFormRefBasic = (formRef) => {
    this.formRefBasic = formRef;
  };

  saveFormRefKankei = (formRef, index) => {
    if (!this[`formRefKankei_${index}`]) {
      this[`formRefKankei_${index}`] = formRef;
    }
  };

  saveFormRefByoki = (formRef, index) => {
    if (!this[`formRefByoki_${index}`]) {
      this[`formRefByoki_${index}`] = formRef;
    }
  };

  saveFormRefDoctor = (formRef) => {
    this.formRefDoctor = formRef;
  };

  saveFormRefKusuri = (formRef, index) => {
    if (!this[`formRefKusuri_${index}`]) {
      this[`formRefKusuri_${index}`] = formRef;
    }
  };

  saveFormRefCaremanager = formRef => {
    this.formRefCaremanager = formRef;
  }

  saveFormRefKiki = (formRef, index) => {
    if (!this[`formRefKiki_${index}`]) {
      this[`formRefKiki_${index}`] = formRef;
    }
  };

  saveFormRefService = (formRef, index) => {
    if (!this[`formRefService_${index}`]) {
      this[`formRefService_${index}`] = formRef;
    }
  };

  saveFormRefNyuin = (formRef, index) => {
    if (!this[`formRefNyuin_${index}`]) {
      this[`formRefNyuin_${index}`] = formRef;
    }
  };

  saveFormRefFacesheet = (formRef) => {
    this.formRefFacesheet = formRef;
  };

  saveFormRefChart = (formRef) => {
    this.formRefChart = formRef;
  };

  saveFormRefAdl = (formRef, index) => {
    if (!this[`formRefAdl${index}`]) {
      this[`formRefAdl${index}`] = formRef;
    }
  };

  saveFormRefJokyo = (formRef) => {
    this.formRefJokyo = formRef;
  };

  saveFormRefHoka = (formRef) => {
    this.formRefHoka = formRef;
  };

  saveFormRefHoka2 = (formRef) => {
    this.formRefHoka2 = formRef;
  };

  handleModify = (e) => {
    const { riyousyaDetail, kihonAdl } = this.props;
    const { imageUrl, isChangeAvatar } = this.state;
    e.preventDefault();

    const preDataUpdate = {};

    // check basic form
    if (!preDataUpdate.formRefBasic) {
      preDataUpdate.formRefBasic = {};
    }
    if (this.formRefBasic) {
      const formBasic = this.formRefBasic.props.form;

      formBasic.validateFields((err, values) => {
        if (err) {
          return;
        }

        values.name = values.nameSyusei;
        preDataUpdate.formRefBasic = values;
      });
    }

    // check kankei form
    if (!preDataUpdate.formRefKankei) {
      preDataUpdate.formRefKankei = [];
    }
    if (riyousyaDetail.riyouConcern && riyousyaDetail.riyouConcern.length > 0) {
      for (let i = 0; i < riyousyaDetail.riyouConcern.length; i++) {
        if (this[`formRefKankei_${i}`]) {
          // const formKankei = this.formRefKankei.props.form;

          this[`formRefKankei_${i}`].props.form.validateFields(
            (err, values) => {
              // 関係者の生年月日の最終登録
              if (values.gengo && values.year && values.month && values.day) {
                const tmpYear = displaySeireki(
                  values.gengo,
                  values.year,
                  values.month,
                  values.day
                );
                values.birthday = tmpYear;
              }
              if (err) {
                return;
              }

              //
              preDataUpdate.formRefKankei.push(values);
            }
          );
        }
      }
    }

    // check byoki form
    if (!preDataUpdate.formRefByoki) {
      preDataUpdate.formRefByoki = [];
    }
    if (
      riyousyaDetail.riyouByoureki &&
      riyousyaDetail.riyouByoureki.length > 0
    ) {
      for (let i = 0; i < riyousyaDetail.riyouByoureki.length; i++) {
        if (this[`formRefByoki_${i}`]) {
          // const formKankei = this.formRefKankei.props.form;

          this[`formRefByoki_${i}`].props.form.validateFields((err, values) => {
            if (err) {
              return;
            }

            //
            preDataUpdate.formRefByoki.push(values);
          });
        }
      }
    }

    // check kusuri form
    if (!preDataUpdate.formRefKusuri) {
      preDataUpdate.formRefKusuri = [];
    }
    if (riyousyaDetail.riyouKusuri && riyousyaDetail.riyouKusuri.length > 0) {
      for (let i = 0; i < riyousyaDetail.riyouKusuri.length; i++) {
        if (this[`formRefKusuri_${i}`]) {
          // const formKankei = this.formRefKankei.props.form;

          this[`formRefKusuri_${i}`].props.form.validateFields(
            (err, values) => {
              if (err) {
                return;
              }

              //
              preDataUpdate.formRefKusuri.push(values);
            }
          );
        }
      }
    }

    // check kiki form
    if (!preDataUpdate.formRefKiki) {
      preDataUpdate.formRefKiki = [];
    }
    if (riyousyaDetail.riyouKiki && riyousyaDetail.riyouKiki.length > 0) {
      for (let i = 0; i < riyousyaDetail.riyouKiki.length; i++) {
        if (this[`formRefKiki_${i}`]) {
          // const formKankei = this.formRefKankei.props.form;

          this[`formRefKiki_${i}`].props.form.validateFields((err, values) => {
            if (err) {
              return;
            }

            //
            preDataUpdate.formRefKiki.push(values);
          });
        }
      }
    }

    // check service form
    if (!preDataUpdate.formRefService) {
      preDataUpdate.formRefService = [];
    }
    if (riyousyaDetail.riyouService && riyousyaDetail.riyouService.length > 0) {
      for (let i = 0; i < riyousyaDetail.riyouService.length; i++) {
        if (this[`formRefService_${i}`]) {
          // const formKankei = this.formRefKankei.props.form;

          this[`formRefService_${i}`].props.form.validateFields(
            (err, values) => {
              if (err) {
                return;
              }

              //
              preDataUpdate.formRefService.push(values);
            }
          );
        }
      }
    }

    // check nyuin form
    if (!preDataUpdate.formRefNyuin) {
      preDataUpdate.formRefNyuin = [];
    }
    if (riyousyaDetail.riyouNyuin && riyousyaDetail.riyouNyuin.length > 0) {
      for (let i = 0; i < riyousyaDetail.riyouNyuin.length; i++) {
        if (this[`formRefNyuin_${i}`]) {
          // const formKankei = this.formRefKankei.props.form;

          this[`formRefNyuin_${i}`].props.form.validateFields((err, values) => {
            if (err) {
              return;
            }

            //
            preDataUpdate.formRefNyuin.push(values);
          });
        }
      }
    }

    // check facesheet form
    if (!preDataUpdate.formRefFacesheet) {
      preDataUpdate.formRefFacesheet = {};
    }
    if (this.formRefFacesheet) {
      const formFacesheet = this.formRefFacesheet.props.form;

      formFacesheet.validateFields((err, values) => {
        if (err) {
          return;
        }

        preDataUpdate.formRefFacesheet = values;
        // this.registerRiyousya(values)
      });
    }

    // check 家族構成図 form
    if (!preDataUpdate.formRefChart) {
      preDataUpdate.formRefChart = {};
    }
    if (this.formRefChart) {
      const formRiyouChart = this.formRefChart.props.form;

      formRiyouChart.validateFields((err, values) => {
        if (err) {
          return;
        }

        preDataUpdate.formRefChart = values;
        // this.registerRiyousya(values)
      });
    }


    // check adl form
    if (!preDataUpdate.formRefAdl) {
      preDataUpdate.formRefAdl = [];
    }
    if (kihonAdl && kihonAdl.length > 0) {
      for (let i = 0; i < kihonAdl.length; i++) {
        if (this[`formRefAdl${i}`]) {
          this[`formRefAdl${i}`].props.form.validateFields((err, values) => {
            if (err) {
              return;
            }

            //
            preDataUpdate.formRefAdl.push(values);
          });
        }
      }
    }

    // check jokyo form
    if (!preDataUpdate.formRefJokyo) {
      preDataUpdate.formRefJokyo = {};
    }
    if (this.formRefJokyo) {
      const formJokyo = this.formRefJokyo.props.form;

      formJokyo.validateFields((err, values) => {
        if (err) {
          return;
        }

        preDataUpdate.formRefJokyo = values;
        // this.registerRiyousya(values)
      });
    }

    // check hoka form
    if (!preDataUpdate.formRefHoka) {
      preDataUpdate.formRefHoka = {};
    }
    if (this.formRefHoka) {
      const formHoka = this.formRefHoka.props.form;

      formHoka.validateFields((err, values) => {
        if (err) {
          return;
        }

        preDataUpdate.formRefHoka = values;
        // this.registerRiyousya(values)
      });
    }

    // check hoka2 form
    if (!preDataUpdate.formRefHoka2) {
      preDataUpdate.formRefHoka2 = {};
    }
    if (this.formRefHoka2) {
      const formHoka2 = this.formRefHoka2.props.form;

      formHoka2.validateFields((err, values) => {
        if (err) {
          return;
        }

        preDataUpdate.formRefHoka2 = values;
        // this.registerRiyousya(values)
      });
    }

    if (imageUrl || isChangeAvatar) {
      preDataUpdate.formRefBasic.riyousyaImage = imageUrl;
    }

    if (!_.isEmpty(preDataUpdate)) {
      this.registerRiyousya(preDataUpdate); // values
    }
  };

  registerRiyousya = (values) => {
    const { riyousyaDetail } = this.props;
    const { facesheetId, facesheetType } = this.state;

    if (this.props.riyousyaDetail) {
      this.props.updateRiyousya(
        riyouMergeFormToState(
          riyousyaDetail,
          values,
          facesheetId,
          facesheetType
        )
      );

      this.setState({
        imageUrl: null,
      });

      this.props.history.goBack();
    }
  };
  // end save process

  showGoBackConfirm() {
    this.setState({
      visible: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visible: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visible: false,
    });
    this.props.history.goBack();
  }

  handleGoBack = (e) => {
    // const { riyousyaDetail } = this.props
    const { isOnChangeForm } = this.state;

    e.preventDefault();
    // let isCheckGoback = false;

    // // check basic form
    // if (this.formRefBasic && this.formRefBasic.props.form.isFieldsTouched()) {
    //     isCheckGoback = true
    // }

    // // check kankei form
    // if (riyousyaDetail.riyouConcern && riyousyaDetail.riyouConcern.length > 0) {
    //     for (let i = 0; i < riyousyaDetail.riyouConcern.length; i++) {
    //         if (this[`formRefKankei_${i}`] && this[`formRefKankei_${i}`].props.form.isFieldsTouched()) {
    //             isCheckGoback = true
    //         }
    //     }
    // }

    // // check byoki form
    // if (riyousyaDetail.riyouByoureki && riyousyaDetail.riyouByoureki.length > 0) {
    //     for (let i = 0; i < riyousyaDetail.riyouByoureki.length; i++) {
    //         if (this[`formRefByoki_${i}`] && this[`formRefByoki_${i}`].props.form.isFieldsTouched()) {
    //             isCheckGoback = true
    //         }
    //     }
    // }

    // // check kusuri form
    // if (riyousyaDetail.riyouKusuri && riyousyaDetail.riyouKusuri.length > 0) {
    //     for (let i = 0; i < riyousyaDetail.riyouKusuri.length; i++) {
    //         if (this[`formRefKusuri_${i}`] && this[`formRefKusuri_${i}`].props.form.isFieldsTouched()) {
    //             isCheckGoback = true
    //         }
    //     }
    // }

    // // check kiki form
    // if (riyousyaDetail.riyouKiki && riyousyaDetail.riyouKiki.length > 0) {
    //     for (let i = 0; i < riyousyaDetail.riyouKiki.length; i++) {
    //         if (this[`formRefKiki_${i}`] && this[`formRefKiki_${i}`].props.form.isFieldsTouched()) {
    //             isCheckGoback = true
    //         }
    //     }
    // }

    // // check service form
    // if (riyousyaDetail.riyouService && riyousyaDetail.riyouService.length > 0) {
    //     for (let i = 0; i < riyousyaDetail.riyouService.length; i++) {
    //         if (this[`formRefService_${i}`] && this[`formRefService_${i}`].props.form.isFieldsTouched()) {
    //             isCheckGoback = true
    //         }
    //     }
    // }

    // // check nyuin form
    // if (riyousyaDetail.riyouNyuin && riyousyaDetail.riyouNyuin.length > 0) {
    //     for (let i = 0; i < riyousyaDetail.riyouNyuin.length; i++) {
    //         if (this[`formRefNyuin_${i}`] && this[`formRefNyuin_${i}`].props.form.isFieldsTouched()) {
    //             isCheckGoback = true
    //         }
    //     }
    // }

    // // check facesheet form
    // if (this.formRefFacesheet && this.formRefFacesheet.props.form.isFieldsTouched()) {
    //     isCheckGoback = true
    // }

    // // check adl form
    // if (this.formRefAdl && this.formRefAdl.props.form.isFieldsTouched()) {
    //     isCheckGoback = true
    // }

    // // check jokyo form
    // if (this.formRefJokyo && this.formRefJokyo.props.form.isFieldsTouched()) {
    //     isCheckGoback = true
    // }

    // // check hoka form
    // if (this.formRefHoka && this.formRefHoka.props.form.isFieldsTouched()) {
    //     isCheckGoback = true
    // }

    if (isOnChangeForm) {
      this.showGoBackConfirm();
    }
    else {
      this.props.history.goBack();
    }
  };

  searchYuuBinBango = (text) => {
    this.props.searchYuuBinBango(text);
  };

  handleAdd = (type) => {
    this.setState({
      isOnChangeForm: true,
    });

    this.props.handleAdd(type);
  };

  handleDelete = (type, index) => {
    this.setState({
      isOnChangeForm: true,
    });

    this.props.handleDelete(type, index);
  };

  handleSort = (type, sortType, index) => {
    const { riyousyaDetail } = this.props;

    // update confrim when click on back button
    this.setState({
      isOnChangeForm: true,
    });

    // uodate form
    let tmp1 = null;
    let tmp2 = null;
    switch (type) {
      case "riyouConcern":
        if (sortType === "up") {
          if (index !== 0) {
            if (
              this.state[`fKankei${index}`] &&
              this.state[`fKankei${index - 1}`]
            ) {
              tmp1 = this.state[`fKankei${index}`];
              tmp2 = this.state[`fKankei${index - 1}`];

              this.setState({
                [`fKankei${index}`]: tmp2,
                [`fKankei${index - 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fKankei${index}`] &&
              !this.state[`fKankei${index - 1}`]
            ) {
              this.setState({
                [`fKankei${index}`]: null,
                [`fKankei${index - 1}`]: this.state[`fKankei${index}`],
              });
            }
            else if (
              !this.state[`fKankei${index}`] &&
              this.state[`fKankei${index - 1}`]
            ) {
              this.setState({
                [`fKankei${index}`]: this.state[`fKankei${index - 1}`],
                [`fKankei${index - 1}`]: null,
              });
            }
          }
        }

        if (sortType === "down") {
          if (index !== riyousyaDetail.riyouConcern.length - 1) {
            if (
              this.state[`fKankei${index}`] &&
              this.state[`fKankei${index + 1}`]
            ) {
              tmp1 = this.state[`fKankei${index}`];
              tmp2 = this.state[`fKankei${index + 1}`];

              this.setState({
                [`fKankei${index}`]: tmp2,
                [`fKankei${index + 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fKankei${index}`] &&
              !this.state[`fKankei${index + 1}`]
            ) {
              this.setState({
                [`fKankei${index}`]: null,
                [`fKankei${index + 1}`]: this.state[`fKankei${index}`],
              });
            }
            else if (
              !this.state[`fKankei${index}`] &&
              this.state[`fKankei${index + 1}`]
            ) {
              this.setState({
                [`fKankei${index}`]: this.state[`fKankei${index + 1}`],
                [`fKankei${index + 1}`]: null,
              });
            }
          }
        }

        break;

      case "riyouByoureki":
        if (sortType === "up") {
          if (index !== 0) {
            if (
              this.state[`fByouki${index}`] &&
              this.state[`fByouki${index - 1}`]
            ) {
              tmp1 = this.state[`fByouki${index}`];
              tmp2 = this.state[`fByouki${index - 1}`];

              this.setState({
                [`fByouki${index}`]: tmp2,
                [`fByouki${index - 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fByouki${index}`] &&
              !this.state[`fByouki${index - 1}`]
            ) {
              this.setState({
                [`fByouki${index}`]: null,
                [`fByouki${index - 1}`]: this.state[`fByouki${index}`],
              });
            }
            else if (
              !this.state[`fByouki${index}`] &&
              this.state[`fByouki${index - 1}`]
            ) {
              this.setState({
                [`fByouki${index}`]: this.state[`fByouki${index - 1}`],
                [`fByouki${index - 1}`]: null,
              });
            }
          }
        }

        if (sortType === "down") {
          if (index !== riyousyaDetail.riyouConcern.length - 1) {
            if (
              this.state[`fByouki${index}`] &&
              this.state[`fByouki${index + 1}`]
            ) {
              tmp1 = this.state[`fByouki${index}`];
              tmp2 = this.state[`fByouki${index + 1}`];

              this.setState({
                [`fByouki${index}`]: tmp2,
                [`fByouki${index + 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fByouki${index}`] &&
              !this.state[`fByouki${index + 1}`]
            ) {
              this.setState({
                [`fByouki${index}`]: null,
                [`fByouki${index + 1}`]: this.state[`fByouki${index}`],
              });
            }
            else if (
              !this.state[`fByouki${index}`] &&
              this.state[`fByouki${index + 1}`]
            ) {
              this.setState({
                [`fByouki${index}`]: this.state[`fByouki${index + 1}`],
                [`fByouki${index + 1}`]: null,
              });
            }
          }
        }

        break;

      case "riyouKusuri":
        if (sortType === "up") {
          if (index !== 0) {
            if (
              this.state[`fKusuri${index}`] &&
              this.state[`fKusuri${index - 1}`]
            ) {
              tmp1 = this.state[`fKusuri${index}`];
              tmp2 = this.state[`fKusuri${index - 1}`];

              this.setState({
                [`fKusuri${index}`]: tmp2,
                [`fKusuri${index - 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fKusuri${index}`] &&
              !this.state[`fKusuri${index - 1}`]
            ) {
              this.setState({
                [`fKusuri${index}`]: null,
                [`fKusuri${index - 1}`]: this.state[`fKusuri${index}`],
              });
            }
            else if (
              !this.state[`fKusuri${index}`] &&
              this.state[`fKusuri${index - 1}`]
            ) {
              this.setState({
                [`fKusuri${index}`]: this.state[`fKusuri${index - 1}`],
                [`fKusuri${index - 1}`]: null,
              });
            }
          }
        }

        if (sortType === "down") {
          if (index !== riyousyaDetail.riyouConcern.length - 1) {
            if (
              this.state[`fKusuri${index}`] &&
              this.state[`fKusuri${index + 1}`]
            ) {
              tmp1 = this.state[`fKusuri${index}`];
              tmp2 = this.state[`fKusuri${index + 1}`];

              this.setState({
                [`fKusuri${index}`]: tmp2,
                [`fKusuri${index + 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fKusuri${index}`] &&
              !this.state[`fKusuri${index + 1}`]
            ) {
              this.setState({
                [`fKusuri${index}`]: null,
                [`fKusuri${index + 1}`]: this.state[`fKusuri${index}`],
              });
            }
            else if (
              !this.state[`fKusuri${index}`] &&
              this.state[`fKusuri${index + 1}`]
            ) {
              this.setState({
                [`fKusuri${index}`]: this.state[`fKusuri${index + 1}`],
                [`fKusuri${index + 1}`]: null,
              });
            }
          }
        }

        break;

      case "riyouKiki":
        if (sortType === "up") {
          if (index !== 0) {
            if (
              this.state[`fKiki${index}`] &&
              this.state[`fKiki${index - 1}`]
            ) {
              tmp1 = this.state[`fKiki${index}`];
              tmp2 = this.state[`fKiki${index - 1}`];

              this.setState({
                [`fKiki${index}`]: tmp2,
                [`fKiki${index - 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fKiki${index}`] &&
              !this.state[`fKiki${index - 1}`]
            ) {
              this.setState({
                [`fKiki${index}`]: null,
                [`fKiki${index - 1}`]: this.state[`fKiki${index}`],
              });
            }
            else if (
              !this.state[`fKiki${index}`] &&
              this.state[`fKiki${index - 1}`]
            ) {
              this.setState({
                [`fKiki${index}`]: this.state[`fKiki${index - 1}`],
                [`fKiki${index - 1}`]: null,
              });
            }
          }
        }

        if (sortType === "down") {
          if (index !== riyousyaDetail.riyouConcern.length - 1) {
            if (
              this.state[`fKiki${index}`] &&
              this.state[`fKiki${index + 1}`]
            ) {
              tmp1 = this.state[`fKiki${index}`];
              tmp2 = this.state[`fKiki${index + 1}`];

              this.setState({
                [`fKiki${index}`]: tmp2,
                [`fKiki${index + 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fKiki${index}`] &&
              !this.state[`fKiki${index + 1}`]
            ) {
              this.setState({
                [`fKiki${index}`]: null,
                [`fKiki${index + 1}`]: this.state[`fKiki${index}`],
              });
            }
            else if (
              !this.state[`fKiki${index}`] &&
              this.state[`fKiki${index + 1}`]
            ) {
              this.setState({
                [`fKiki${index}`]: this.state[`fKiki${index + 1}`],
                [`fKiki${index + 1}`]: null,
              });
            }
          }
        }

        break;

      case "riyouNyuin":
        if (sortType === "up") {
          if (index !== 0) {
            if (
              this.state[`fNyuin${index}`] &&
              this.state[`fNyuin${index - 1}`]
            ) {
              tmp1 = this.state[`fNyuin${index}`];
              tmp2 = this.state[`fNyuin${index - 1}`];

              this.setState({
                [`fNyuin${index}`]: tmp2,
                [`fNyuin${index - 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fNyuin${index}`] &&
              !this.state[`fNyuin${index - 1}`]
            ) {
              this.setState({
                [`fNyuin${index}`]: null,
                [`fNyuin${index - 1}`]: this.state[`fNyuin${index}`],
              });
            }
            else if (
              !this.state[`fNyuin${index}`] &&
              this.state[`fNyuin${index - 1}`]
            ) {
              this.setState({
                [`fNyuin${index}`]: this.state[`fNyuin${index - 1}`],
                [`fNyuin${index - 1}`]: null,
              });
            }
          }
        }

        if (sortType === "down") {
          if (index !== riyousyaDetail.riyouConcern.length - 1) {
            if (
              this.state[`fNyuin${index}`] &&
              this.state[`fNyuin${index + 1}`]
            ) {
              tmp1 = this.state[`fNyuin${index}`];
              tmp2 = this.state[`fNyuin${index + 1}`];

              this.setState({
                [`fNyuin${index}`]: tmp2,
                [`fNyuin${index + 1}`]: tmp1,
              });
            }
            else if (
              this.state[`fNyuin${index}`] &&
              !this.state[`fNyuin${index + 1}`]
            ) {
              this.setState({
                [`fNyuin${index}`]: null,
                [`fNyuin${index + 1}`]: this.state[`fNyuin${index}`],
              });
            }
            else if (
              !this.state[`fNyuin${index}`] &&
              this.state[`fNyuin${index + 1}`]
            ) {
              this.setState({
                [`fNyuin${index}`]: this.state[`fNyuin${index + 1}`],
                [`fNyuin${index + 1}`]: null,
              });
            }
          }
        }

        break;

      default:
        break;
    }
    this.props.handleSort(type, sortType, index);
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      alert("JPG/PNGファイルのみアップロードできます！");
    }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //     alert('画像は2MB未満でなければなりません！');
    // }

    return isJpgOrPng;
  };

  handleChange = (info) => {
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";
    if (isJpgOrPng) {
      // Get this url
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
          isOnChangeForm: true,
          isChangeAvatar: true,
        })
      );
    }
  };

  handleRemoveAvatar = () => {
    this.setState({
      isConfirmDelAvatar: true,
    });
  };

  handleConfirmDelAvatar = () => {
    this.setState({
      isConfirmDelAvatar: false,
      imageUrl: "",
      isOnChangeForm: true,
      isChangeAvatar: true,
    });
  };

  closeConfirmDelAvatar = () => {
    this.setState({
      isConfirmDelAvatar: false,
    });
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  searchByomeiMaster = (bunrui, name) => {
    this.props.byoumeiMasterList(bunrui, name);
  };

  searchDrugMaster = (bunrui, name) => {
    this.props.drugMasterList(bunrui, name);
  };

  searchMachineMaster = (bunrui, name) => {
    this.props.machineMasterList(bunrui, name);
  };

  searchServiceKikanMaster = (bunrui, name) => {
    this.props.serviceKikanMasterList(bunrui, name);
  };

  searchIryouKikanMaster = (bunrui, name) => {
    this.props.iryouKikanMasterList(bunrui, name);
  };

  /**
   *
   */
  saveOnchangeToState = (values, type, index) => {
    const tmp = type + index;

    this.setState({
      [tmp]: values,
      isOnChangeForm: true,
    });
  };

  onChangeFuriganaGenderBirthday = (field, value) => {
    if (field == "birthday") {
      this.props.riyousyaDetail.birthday = value;
    }
    else if (field == "gender") {
      this.props.riyousyaDetail.gender = value;
    }
    else if (field == "furigana") {
      this.props.riyousyaDetail.furigana = value;
    }
  }

  /**
   *
   */
  render() {
    const {
      sessionTimeout,
      riyousyaDetail,
      zipLoading,
      zipSearch,
      loadingSetting,
      tantoMastersList,

      // 設定ところ
      bunrui_is_dis,
      bunrui_title,
      systemBunrui_is_dis,
      systemBunrui_title,
      tekiyou_is_dis,
      tekiyou_title,
      fax_title,
      kihonAdl,

      // yougo master
      riyousyaBunrui1,
      riyousyaBunrui2,
      riyousyaBunrui3,
      riyousyaBunrui4,
      riyousyaBunrui5,
      systemBunrui1,
      systemBunrui2,
      systemBunrui3,
      systemBunrui4,
      systemBunrui5,
      nyukinBunrui,
      nyukinTekiyo,

      // kanke tab2
      kankeList,

      // service tab6
      kikanNameList,
      hindoList,
      stateList,

      // nyuin tab7
      nyuinRiyuList,
      tainRiyuList,

      //
      attnList,
      genkyouList,
      keizaiJoukyouList,
      kazokuJoukyouList,
      shintaiJoukyouList,
      ryoyoJokyoList,
      kaigoJokyoList,
      jukyoKankyouList,
      seikatuRekiList,
      kiorekiList,
      shokuRekiList,
      syumiEtcList,
      taijinKankeiList,
      honninSyusoList,
      kazokuSyusoList,
      honninKazokuIkoList,
      kankeishaYoboList,
      riyouServiceKoutekiList,
      riyouServiceHikoutekiList,

      //
      sonotaList1,
      sonotaList2,
      sonotaList3,
      sonotaList4,
      sonotaList5,
      sonotaList6,
      sonotaList7,
      sonotaList8,
      sonotaList9,
      sonotaList10,

      systemSonotaList1,
      systemSonotaList2,
      systemSonotaList3,
      systemSonotaList4,
      systemSonotaList5,
      systemSonotaList6,
      systemSonotaList7,
      systemSonotaList8,
      systemSonotaList9,
      systemSonotaList10,

      joukyouList1,
      joukyouList2,
      joukyouList3,
      joukyouList4,
      joukyouList5,
      joukyouList6,
      joukyouList7,
      joukyouList8,
      joukyouList9,
      joukyouList10,

      //
      byomeiMaster,
      drugMaster,
      machineMaster,
      serviceSyuruiMaster,
      serviceKikanMaster,

      ymByoumeiBunrui,
      ymKusurimeiBunrui,

      iryouKikanMaster,

      //
      setFSOtherTitle,

      //
      setFSSonotaTitle,
      fsSonotaTitleDis,

      //
      system_setFSSonotaTitle,
      system_fsSonotaTitleDis,
    } = this.props;

    const {
      isOpen,
      isOpen1,
      isOpen2,
      isOpen3,
      isOpen4,
      isOpen5,
      isOpen6,
      isOpen7,
      isOpen8,
      isOpen9,
      isOpen10,
      isOpen11,
      isOpen13,
      imageUrl,
      facesheetId,
      facesheetType,
      isChangeAvatar,
    } = this.state;

    const uploadButton = (
      <div>
        <LegacyIcon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    let facesheetItemSelected = {};
    let jigyosyofacesheetItemSelected = {};
    if (
      riyousyaDetail &&
      riyousyaDetail.facesheet &&
      riyousyaDetail.facesheet.length > 0
    ) {
      facesheetItemSelected = riyousyaDetail.facesheet.find(
        (item) => item.id.toString() === facesheetId.toString()
      );

      if (!facesheetItemSelected) {
        facesheetItemSelected = riyousyaDetail.facesheet[0];
      }
      else if (facesheetItemSelected.faceAdl && facesheetType === "copy") {
        for (let i = 0; facesheetItemSelected.faceAdl.length > i; i++) {
          facesheetItemSelected.faceAdl[i].id = 0;
        }
      }

      if (facesheetItemSelected.jigyosyoFacesheet) {
        const mainSID = formatStorage(getMenu()).si;
        const jf = facesheetItemSelected.jigyosyoFacesheet.find(
          (item) => item.serviceKikanMasterId.toString() === mainSID.toString()
        );
        if (jf !== undefined) {
          jigyosyofacesheetItemSelected = jf;
        }
      }
    }

    let stethoscopeTitle = "主治医・病名";
    if (isManagement())
      stethoscopeTitle = "主治医・病名・ケアマネ";

    return (
      (<CommonPageContainer
        id="riyousya-update-page"
        sessionTimeout={sessionTimeout}
      >
        <Affix offsetTop={0}>
          <Row className={"submenu"}>
            <MediaQuery query="(min-device-width: 0px)">
              {(matches) => {
                if (matches) {
                  return (
                    <SubHeaderV2
                      TopLeft={
                        <SubHeaderTitleV2
                          title={`${riyousyaDetail ? riyousyaDetail.name : ""
                            }さん利用者情報`}
                          iconType="user"
                        />
                      }
                      TopRight={
                        <div className="submenu-t-r">
                          <Link
                            to={"/"}
                            onClick={this.handleGoBack}
                            className="sub-top-right-item"
                          >
                            <span>
                              <UndoOutlined />
                            </span>
                            <span> 戻る</span>
                          </Link>

                          <Link
                            to={"/"}
                            className="sub-top-right-item"
                            onClick={this.handleModify}
                          >
                            <span>
                              <SaveOutlined style={{ color: "red" }} />
                            </span>
                            <span> 登録</span>
                          </Link>
                        </div>
                      }
                    />
                  );
                }
                /**
                   * @TODO design UI for mobile
                   *
                   * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
                   */
                return null;

              }}
            </MediaQuery>
          </Row>
        </Affix>
        <Row className="riyousya-update-content">
          <Col sm={24} md={6} className="rdc-avatar">
            <div className="rdc-avatar-img">
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                // action="/"
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                {imageUrl || isChangeAvatar ? (
                  <img
                    src={imageUrl ? imageUrl : noImage}
                    style={{ width: "100%" }}
                  />
                ) : riyousyaDetail && riyousyaDetail.riyousyaImage ? (
                  <img
                    src={
                      riyousyaDetail.riyousyaImage
                        ? riyousyaDetail.riyousyaImage
                        : noImage
                    }
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              <Button type="primary" onClick={this.handleRemoveAvatar}>
                画像削除
              </Button>
            </div>
          </Col>
          <Col sm={24} md={18} className="rdc-list">
            <div className="expand-btn">
              <Button
                type="primary"
                icon={<LegacyIcon type={isOpen ? "minus" : "plus"} />}
                onClick={this.handleOpenColl}
              >
                格納
              </Button>
            </div>
            {riyousyaDetail ? (
              <div>
                <Collapse
                  forceRender={true}
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen1]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "1")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="file-text-o" key={guid()} /> 基本情報
                      </span>
                    }
                    key="1"
                    style={customPanelStyle}
                  >
                    <FormBasic
                      wrappedComponentRef={this.saveFormRefBasic}
                      data={Object.assign(riyousyaDetail, this.state["fBasic"])}
                      zipSearch={zipSearch}
                      zipLoading={zipLoading}
                      searchYuuBinBango={this.searchYuuBinBango}
                      // setting data
                      bunrui_is_dis={bunrui_is_dis}
                      bunrui_title={bunrui_title}
                      systemBunrui_is_dis={systemBunrui_is_dis}
                      systemBunrui_title={systemBunrui_title}
                      tekiyou_is_dis={tekiyou_is_dis}
                      tekiyou_title={tekiyou_title}
                      fax_title={fax_title}
                      riyousyaBunrui1={riyousyaBunrui1}
                      riyousyaBunrui2={riyousyaBunrui2}
                      riyousyaBunrui3={riyousyaBunrui3}
                      riyousyaBunrui4={riyousyaBunrui4}
                      riyousyaBunrui5={riyousyaBunrui5}
                      systemBunrui1={systemBunrui1}
                      systemBunrui2={systemBunrui2}
                      systemBunrui3={systemBunrui3}
                      systemBunrui4={systemBunrui4}
                      systemBunrui5={systemBunrui5}
                      nyukinBunrui={nyukinBunrui}
                      nyukinTekiyo={nyukinTekiyo}
                      yougoMasterUpdate={this.yougoMasterUpdate}
                      saveOnchangeToState={this.saveOnchangeToState}

                      onChange={this.onChangeFuriganaGenderBirthday}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen2]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "2")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="medkit" key={guid()} /> 家族・関係者
                      </span>
                    }
                    key="2"
                    style={customPanelStyle}
                  >
                    {riyousyaDetail &&
                      riyousyaDetail.riyouConcern &&
                      riyousyaDetail.riyouConcern.length > 0
                      ? riyousyaDetail.riyouConcern.map((item, index) => {
                        if (!item.inputType || item.inputType !== "del")
                          return (
                            <FormKankei
                              wrappedComponentRef={(formRef) =>
                                this.saveFormRefKankei(formRef, index)
                              }
                              // data={item}
                              data={Object.assign(
                                item,
                                this.state[`fKankei${index}`]
                              )}
                              key={index}
                              index={index}
                              zipSearch={zipSearch}
                              zipLoading={zipLoading}
                              handleDelete={this.handleDelete}
                              handleSort={this.handleSort}
                              searchYuuBinBango={this.searchYuuBinBango}
                              yougoMasterUpdate={this.yougoMasterUpdate}
                              kankeList={kankeList}
                              saveOnchangeToState={this.saveOnchangeToState}
                            />
                          );
                        return null;
                      })
                      : null}
                    <Row style={{ textAlign: "right", paddingTop: 16 }}>
                      <Button
                        type="primary"
                        danger
                        onClick={this.handleAdd.bind(this, "riyouConcern")}
                        style={{float:'right', marginLeft: 'auto'}}
                      >
                        ＋関係者追加
                      </Button>
                    </Row>
                  </Panel>
                </Collapse>
                {/* 家族構成図 */}
                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen13]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "13")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="plug" key={guid()} /> 家族構成図
                      </span>
                    }
                    key="13"
                    style={customPanelStyle}
                  >
                    <FormChart
                      wrappedComponentRef={(formRef) =>
                        this.saveFormRefChart(formRef)
                      }
                      data={Object.assign(
                        facesheetItemSelected?.riyouChart?.length > 0 ? facesheetItemSelected.riyouChart[0] : {},
                        this.state["fChart"]
                      )}
                      saveOnchangeToState={this.saveOnchangeToState}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen3]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "3")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="stethoscope" key={guid()} /> {stethoscopeTitle}
                      </span>
                    }
                    key="3"
                    style={customPanelStyle}
                  >
                    <FormDoctor
                      wrappedComponentRef={(formRef) => this.saveFormRefDoctor(formRef)}
                      data={Object.assign(riyousyaDetail, this.state["fDoctor"])}
                      saveOnchangeToState={this.saveOnchangeToState}
                    />
                    {
                      isManagement() ?
                        <FormCaremanager
                          wrappedComponentRef={(formRef) => this.saveFormRefCaremanager(formRef)}
                          data={Object.assign(riyousyaDetail, this.state["fCaremanager"])}
                          saveOnchangeToState={this.saveOnchangeToState}
                          tantoMastersList={tantoMastersList}
                        />
                        :
                        null
                    }
                    {riyousyaDetail &&
                      riyousyaDetail.riyouByoureki &&
                      riyousyaDetail.riyouByoureki.length > 0
                      ? riyousyaDetail.riyouByoureki.map((item, index) => {
                        return (
                          <FormByouki
                            wrappedComponentRef={(formRef) =>
                              this.saveFormRefByoki(formRef, index)
                            }
                            // data={item}
                            data={Object.assign(
                              item,
                              this.state[`fByouki${index}`]
                            )}
                            key={index}
                            index={index}
                            handleDelete={this.handleDelete}
                            handleSort={this.handleSort}
                            byomeiMaster={byomeiMaster}
                            ymByoumeiBunrui={ymByoumeiBunrui}
                            searchByomeiMaster={this.searchByomeiMaster}
                            saveOnchangeToState={this.saveOnchangeToState}
                            getMediSyoubyoumeiByCode={
                              this.props.getMediSyoubyoumeiByCode
                            }
                            // yougoMasterUpdate={this.yougoMasterUpdate}
                            yougoMasterUpdate={this.props.yougoMasterUpdate}
                          />
                        );
                      })
                      : null}
                    <Row style={{ textAlign: "right", paddingTop: 16 }}>
                      <Button
                        type="primary"
                        danger
                        onClick={this.handleAdd.bind(this, "riyouByoureki")}
                        style={{float:'right', marginLeft: 'auto'}}
                      >
                        ＋病名追加
                      </Button>
                    </Row>
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen4]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "4")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="flask" key={guid()} /> 薬情報
                      </span>
                    }
                    key="4"
                    style={customPanelStyle}
                  >
                    {riyousyaDetail &&
                      riyousyaDetail.riyouKusuri &&
                      riyousyaDetail.riyouKusuri.length > 0
                      ? riyousyaDetail.riyouKusuri.map((item, index) => {
                        return (
                          <FormKusuri
                            wrappedComponentRef={(formRef) =>
                              this.saveFormRefKusuri(formRef, index)
                            }
                            // data={item}
                            data={Object.assign(
                              item,
                              this.state[`fKusuri${index}`]
                            )}
                            key={index}
                            index={index}
                            handleDelete={this.handleDelete}
                            handleSort={this.handleSort}
                            drugMaster={drugMaster}
                            ymKusurimeiBunrui={ymKusurimeiBunrui}
                            searchDrugMaster={this.searchDrugMaster}
                            saveOnchangeToState={this.saveOnchangeToState}
                            yougoMasterUpdate={this.props.yougoMasterUpdate}
                          />
                        );
                      })
                      : null}
                    <Row style={{ textAlign: "right", paddingTop: 16 }}>
                      <Button
                        type="primary"
                        danger
                        onClick={this.handleAdd.bind(this, "riyouKusuri")}
                        style={{float:'right', marginLeft: 'auto'}}
                      >
                        ＋薬追加
                      </Button>
                    </Row>
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen5]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "5")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="plug" key={guid()} /> 機器情報
                      </span>
                    }
                    key="5"
                    style={customPanelStyle}
                  >
                    {riyousyaDetail &&
                      riyousyaDetail.riyouKiki &&
                      riyousyaDetail.riyouKiki.length > 0
                      ? riyousyaDetail.riyouKiki.map((item, index) => {
                        return (
                          <FormKiki
                            wrappedComponentRef={(formRef) =>
                              this.saveFormRefKiki(formRef, index)
                            }
                            // data={item}
                            data={Object.assign(
                              item,
                              this.state[`fKiki${index}`]
                            )}
                            key={index}
                            index={index}
                            handleDelete={this.handleDelete}
                            handleSort={this.handleSort}
                            machineMaster={machineMaster}
                            searchMachineMaster={this.searchMachineMaster}
                            saveOnchangeToState={this.saveOnchangeToState}
                          />
                        );
                      })
                      : null}
                    <Row style={{ textAlign: "right", paddingTop: 16 }}>
                      <Button
                        type="primary"
                        danger
                        onClick={this.handleAdd.bind(this, "riyouKiki")}
                        style={{float:'right', marginLeft: 'auto'}}
                      >
                        ＋機器追加
                      </Button>
                    </Row>
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen6]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "6")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="sign-language" key={guid()} /> サービス情報
                      </span>
                    }
                    key="6"
                    style={customPanelStyle}
                  >
                    {riyousyaDetail &&
                      riyousyaDetail.riyouService &&
                      riyousyaDetail.riyouService.length > 0
                      ? riyousyaDetail.riyouService.map((item, index) => {
                        return (
                          <FormService
                            wrappedComponentRef={(formRef) =>
                              this.saveFormRefService(formRef, index)
                            }
                            // data={item}
                            data={Object.assign(
                              item,
                              this.state[`fService${index}`]
                            )}
                            key={index}
                            index={index}
                            handleDelete={this.handleDelete}
                            yougoMasterUpdate={this.yougoMasterUpdate}
                            kikanNameList={kikanNameList}
                            hindoList={hindoList}
                            stateList={stateList}
                            serviceSyuruiMaster={serviceSyuruiMaster}
                            serviceKikanMaster={serviceKikanMaster}
                            searchServiceKikanMaster={
                              this.searchServiceKikanMaster
                            }
                            saveOnchangeToState={this.saveOnchangeToState}
                          />
                        );
                      })
                      : null}
                    <Row style={{ textAlign: "right", paddingTop: 16 }}>
                      <Button
                        type="primary"
                        danger
                        onClick={this.handleAdd.bind(this, "riyouService")}
                        style={{float:'right', marginLeft: 'auto'}}
                      >
                        ＋サービス追加
                      </Button>
                    </Row>
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen7]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "7")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="ambulance" key={guid()} /> 入退院情報
                      </span>
                    }
                    key="7"
                    style={customPanelStyle}
                  >
                    <Row style={{ textAlign: "right", paddingBottom: 16 }}>
                      <Button
                        type="primary"
                        danger
                        onClick={this.handleAdd.bind(this, "riyouNyuin")}
                        style={{float:'right', marginLeft: 'auto'}}
                      >
                        ＋入退院追加
                      </Button>
                    </Row>

                    {riyousyaDetail &&
                      riyousyaDetail.riyouNyuin &&
                      riyousyaDetail.riyouNyuin.length > 0
                      ? riyousyaDetail.riyouNyuin.map((item, index) => {
                        return (
                          <FormNyuin
                            wrappedComponentRef={(formRef) =>
                              this.saveFormRefNyuin(formRef, index)
                            }
                            // data={item}
                            data={Object.assign(
                              item,
                              this.state[`fNyuin${index}`]
                            )}
                            key={index}
                            index={index}
                            handleDelete={this.handleDelete}
                            handleSort={this.handleSort}
                            yougoMasterUpdate={this.yougoMasterUpdate}
                            nyuinRiyuList={nyuinRiyuList}
                            tainRiyuList={tainRiyuList}
                            iryouKikanMaster={iryouKikanMaster}
                            searchIryouKikanMaster={
                              this.searchIryouKikanMaster
                            }
                            saveOnchangeToState={this.saveOnchangeToState}
                          />
                        );
                      })
                      : null}
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen8]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "8")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="meh-o" key={guid()} /> フェイスシート
                      </span>
                    }
                    key="8"
                    style={customPanelStyle}
                  >
                    <FormFacesheet
                      wrappedComponentRef={(formRef) =>
                        this.saveFormRefFacesheet(formRef)
                      }
                      // data={facesheetItemSelected}
                      data={Object.assign(
                        facesheetItemSelected,
                        this.state["fFacesheet"]
                      )}
                      yougoMasterUpdate={this.yougoMasterUpdate}
                      attnList={attnList}
                      genkyouList={genkyouList}
                      keizaiJoukyouList={keizaiJoukyouList}
                      kazokuJoukyouList={kazokuJoukyouList}
                      shintaiJoukyouList={shintaiJoukyouList}
                      ryoyoJokyoList={ryoyoJokyoList}
                      kaigoJokyoList={kaigoJokyoList}
                      jukyoKankyouList={jukyoKankyouList}
                      seikatuRekiList={seikatuRekiList}
                      kiorekiList={kiorekiList}
                      shokuRekiList={shokuRekiList}
                      syumiEtcList={syumiEtcList}
                      taijinKankeiList={taijinKankeiList}
                      honninSyusoList={honninSyusoList}
                      kazokuSyusoList={kazokuSyusoList}
                      honninKazokuIkoList={honninKazokuIkoList}
                      kankeishaYoboList={kankeishaYoboList}
                      riyouServiceKoutekiList={riyouServiceKoutekiList}
                      riyouServiceHikoutekiList={riyouServiceHikoutekiList}
                      saveOnchangeToState={this.saveOnchangeToState}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen10]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "10")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="sun-o" key={guid()} /> ＡＤＬ
                      </span>
                    }
                    key="10"
                    style={customPanelStyle}
                  >
                    {kihonAdl && kihonAdl.length > 0
                      ? kihonAdl.map((item, index) => {
                        return (
                          <FormAdl
                            wrappedComponentRef={(formRef) =>
                              this.saveFormRefAdl(formRef, index)
                            }
                            data={
                              this.state[`fAdl${index}`]
                                ? this.state[`fAdl${index}`]
                                : ""
                            }
                            faceAdl={
                              facesheetItemSelected.faceAdl
                                ? facesheetItemSelected.faceAdl
                                : []
                            }
                            kihonAdl={item}
                            key={index}
                            index={index}
                            saveOnchangeToState={this.saveOnchangeToState}
                          />
                        );
                      })
                      : null}
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen11]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "11")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="newspaper-o" key={guid()} /> 状況
                      </span>
                    }
                    key="11"
                    style={customPanelStyle}
                  >
                    <FormStatus
                      wrappedComponentRef={(formRef) =>
                        this.saveFormRefJokyo(formRef)
                      }
                      // data={facesheetItemSelected}
                      data={Object.assign(
                        facesheetItemSelected,
                        this.state["fStatus"]
                      )}
                      saveOnchangeToState={this.saveOnchangeToState}
                      //
                      joukyouList1={joukyouList1}
                      joukyouList2={joukyouList2}
                      joukyouList3={joukyouList3}
                      joukyouList4={joukyouList4}
                      joukyouList5={joukyouList5}
                      joukyouList6={joukyouList6}
                      joukyouList7={joukyouList7}
                      joukyouList8={joukyouList8}
                      joukyouList9={joukyouList9}
                      joukyouList10={joukyouList10}
                      //
                      setFSOtherTitle={setFSOtherTitle}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen9]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "9")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="plus-square" key={guid()} /> その他
                      </span>
                    }
                    key="9"
                    style={customPanelStyle}
                  >
                    <FormOther
                      wrappedComponentRef={(formRef) =>
                        this.saveFormRefHoka(formRef)
                      }
                      // data={facesheetItemSelected}
                      data={Object.assign(
                        facesheetItemSelected,
                        this.state["fOther"]
                      )}
                      yougoMasterUpdate={this.yougoMasterUpdate}
                      //
                      sonotaList1={sonotaList1}
                      sonotaList2={sonotaList2}
                      sonotaList3={sonotaList3}
                      sonotaList4={sonotaList4}
                      sonotaList5={sonotaList5}
                      sonotaList6={sonotaList6}
                      sonotaList7={sonotaList7}
                      sonotaList8={sonotaList8}
                      sonotaList9={sonotaList9}
                      sonotaList10={sonotaList10}
                      saveOnchangeToState={this.saveOnchangeToState}
                      setFSSonotaTitle={setFSSonotaTitle}
                      fsSonotaTitleDis={fsSonotaTitleDis}
                    />
                    <FormOther2
                      wrappedComponentRef={(formRef) =>
                        this.saveFormRefHoka2(formRef)
                      }
                      data={Object.assign(
                        jigyosyofacesheetItemSelected,
                        this.state["fOther2"]
                      )}
                      yougoMasterUpdate={this.yougoMasterUpdate}
                      //
                      systemSonotaList1={systemSonotaList1}
                      systemSonotaList2={systemSonotaList2}
                      systemSonotaList3={systemSonotaList3}
                      systemSonotaList4={systemSonotaList4}
                      systemSonotaList5={systemSonotaList5}
                      systemSonotaList6={systemSonotaList6}
                      systemSonotaList7={systemSonotaList7}
                      systemSonotaList8={systemSonotaList8}
                      systemSonotaList9={systemSonotaList9}
                      systemSonotaList10={systemSonotaList10}
                      saveOnchangeToState={this.saveOnchangeToState}
                      system_setFSSonotaTitle={system_setFSSonotaTitle}
                      system_fsSonotaTitleDis={system_fsSonotaTitleDis}
                    />
                  </Panel>
                </Collapse>

                {/* <Collapse
                                        accordion
                                        className="rwa-c-col"
                                        expandIconPosition="right"

                                        bordered={false}
                                        activeKey={[isOpen12]}
                                        expandIcon={({ isActive }) => <Icon type="caret-up" rotate={isActive ? 180 : 0} />}

                                        onChange={this.onChangePanel.bind(this, '12')}
                                    >
                                        <Panel
                                            header={
                                                <span className="user_ad">
                                                    <FA name='heart' key={guid()} /> 保険情報
                                            </span>
                                            }
                                            key="12"
                                            style={customPanelStyle}>
                                        </Panel>
                                    </Collapse> */}
              </div>
            ) : null}
          </Col>
        </Row>
        <Modal
          open={this.state.visible}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          footer={[
            <Button
              key="delete"
              type={"primary"}
              onClick={() => this.handleConfirmGoBack()}
            >
              OK
            </Button>,
            <Button
              key="canceldelete"
              onClick={() => this.closeGoBackConfirm()}
            >
              キャンセル
            </Button>,
          ]}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
        {/* Remove Riyousya Avatar Confirm Dialog */}
        <Modal
          open={this.state.isConfirmDelAvatar}
          title="画像削除確認"
          onCancel={() => this.closeConfirmDelAvatar()}
          footer={[
            <Button
              key="delete"
              type={"primary"}
              onClick={() => this.handleConfirmDelAvatar()}
            >
              OK
            </Button>,
            <Button
              key="canceldelete"
              onClick={() => this.closeConfirmDelAvatar()}
            >
              キャンセル
            </Button>,
          ]}
        >
          <span style={{ display: "block" }}>
            {"画像を削除してもよろしいですか？"}
          </span>
        </Modal>
      </CommonPageContainer>)
    );
  }
}

export default RiyousyaUpdate;
