import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";
import _ from "lodash";

export const life_Seikatsu_InputFormPDF = (data, jobList) => {
  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];

  // dataからキーの値が一致するオブジェクトを返す
  const generateOption2 = (options, key, value) => {
    const result = options?.find(obj => obj[key] === value);
    return result;
  };

  const generateGenderString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += item.label + " ";
      }
    });
    return result;
  };

  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 78;
    const MAX_LINE = 10;
    let stringArr = string?.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    if (stringArr.length > 1) {
      stringArr.forEach((item) => {
        countOld = count;
        count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
        if (count < MAX_LINE) {
          result = result + item + "\n";
        } else {
          result = result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
          return;
        }
      });
    } else {
      stringArr.forEach((item) => {
        result = result + item.substring(0, (MAX_LINE - count) * LENGTH_LINE - 3);
        return;
      });
    }
    return result;
  };

  const formatJob = (num) => {
    let result = "";

    if (num && jobList?.naiyou?.split(";").length > 0) {
      jobList.naiyou?.split(";").map((item) => {
        if (item?.split(":")[0] == num) {
          result = item?.split(":")[1];
        }
      });
    }
    return result;
  };

  const getAdls = (datas, koumoku, id) => {
    let result = "";
    if (!datas) return result;
    result = datas.find(d => d.seikatuKinouAdlMasterId == id)?.[koumoku]
    return result ?? "";
  }

  const { seikatsu, kaigodo } = data;

  const generateObject = (seikatsu) => {
    return {
      利用者名: seikatsu?.riyousya?.name ?? "",
      生年月日: formatBirthday(seikatsu?.riyousya?.birthday) ?? "",
      性別: `${generateGenderString(GENDER_OPTIONS, seikatsu?.riyousya?.gender ?? "")}`,
      評価日: formatSeireki(seikatsu.evaluateDate) ?? "",
      評価スタッフ: seikatsu?.tantoMaster?.name ?? "",
      職種: formatJob(seikatsu?.tantoMaster?.jobCategory) ?? "",
      要介護度: generateOption2(kaigodo, "kaigodoNo", seikatsu?.careLevel)?.kaigodoName ?? "",
      自立度: seikatsu?.impairedDegree ?? "",
      認知度: seikatsu?.dementiaDegree ?? "",
      レベル1: getAdls(seikatsu?.seikatuKinouAdls, "answer", 1) ?? "",
      課題1: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 1) ?? "",
      環境1: seikatsu?.environmentViewpointAdl ?? "",
      "状況・生活課題1": seikatsu?.supportViewpointAdl ?? "",
      レベル2:  getAdls(seikatsu?.seikatuKinouAdls, "answer", 2) ?? "",
      課題2: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 2) ?? "",
      レベル3: getAdls(seikatsu?.seikatuKinouAdls, "answer", 3) ?? "",
      課題3: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 3) ?? "",
      レベル4: getAdls(seikatsu?.seikatuKinouAdls, "answer", 4) ?? "",
      課題4: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 4) ?? "",
      レベル5: getAdls(seikatsu?.seikatuKinouAdls, "answer", 5) ?? "",
      課題5: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 5) ?? "",
      レベル6: getAdls(seikatsu?.seikatuKinouAdls, "answer", 6) ?? "",
      課題6: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 6) ?? "",
      レベル7: getAdls(seikatsu?.seikatuKinouAdls, "answer", 7) ?? "",
      課題7: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 7) ?? "",
      レベル8: getAdls(seikatsu?.seikatuKinouAdls, "answer", 8) ?? "",
      課題8: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 8) ?? "",
      レベル9: getAdls(seikatsu?.seikatuKinouAdls, "answer", 9) ?? "",
      課題9: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 9) ?? "",
      レベル10: getAdls(seikatsu?.seikatuKinouAdls, "answer", 10) ?? "",
      課題10: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 10) ?? "",
      レベル11: getAdls(seikatsu?.seikatuKinouAdls, "answer", 11) ?? "",
      課題11: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 11) ?? "",
      環境2: seikatsu?.environmentViewpointIadl ?? "",
      "状況・生活課題2": seikatsu?.supportViewpointIadl ?? "",
      レベル12: getAdls(seikatsu?.seikatuKinouAdls, "answer", 12) ?? "",
      課題12: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 12) ?? "",
      レベル13: getAdls(seikatsu?.seikatuKinouAdls, "answer", 13) ?? "",
      課題13: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 13) ?? "",
      レベル14: getAdls(seikatsu?.seikatuKinouAdls, "answer", 14) ?? "",
      課題14: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 14) ?? "",
      レベル15: getAdls(seikatsu?.seikatuKinouAdls, "answer", 15) ?? "",
      課題15: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 15) ?? "",
      レベル16: getAdls(seikatsu?.seikatuKinouAdls, "answer", 16) ?? "",
      課題16: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 16) ?? "",
      レベル17: getAdls(seikatsu?.seikatuKinouAdls, "answer", 17) ?? "",
      課題17: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 17) ?? "",
      レベル18: getAdls(seikatsu?.seikatuKinouAdls, "answer", 18) ?? "",
      課題18: getAdls(seikatsu?.seikatuKinouAdls, "kadai", 18) ?? "",
      "状況・生活課題3": seikatsu?.supportViewpointStandup ?? "",
    }
  }

    if (typeof seikatsu === 'object' && Array.isArray(seikatsu)) {
      // 複数印刷
      let sampledata = [];
      seikatsu?.map((seikatsu) => {
        sampledata.push(generateObject(seikatsu))
      })
      return sampledata;
    } else if (typeof seikatsu === 'object') {
      // 単体印刷
      let sampledata = [generateObject(seikatsu)];
      return sampledata;
    } else {
      return [{}];
    }
};
