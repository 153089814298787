export const LOAD_KYOTAKU_PLAN2_SUCCESS = "LOAD_KYOTAKU_PLAN2_SUCCESS";
export const LOAD_KYOTAKU_PLAN2_BY_ID_SUCCESS =
  "LOAD_KYOTAKU_PLAN2_BY_ID_SUCCESS";
export const CREATE_KYOTAKU_PLAN2_SUCCESS = "CREATE_KYOTAKU_PLAN2_SUCCESS";
export const UPDATE_KYOTAKU_PLAN2_SUCCESS = "UPDATE_KYOTAKU_PLAN2_SUCCESS";
export const DELETE_KYOTAKU_PLAN2_SUCCESS = "DELETE_KYOTAKU_PLAN2_SUCCESS";
export const LOAD_KYOTAKU_PLAN2_TO_QUOTE_SUCCESS =
  "LOAD_KYOTAKU_PLAN2_TO_QUOTE_SUCCESS";

export const LOAD_KYOTAKU_PLAN2_ERROR = "LOAD_KYOTAKU_PLAN2_ERROR";
export const LOAD_KYOTAKU_PLAN2_BY_ID_ERROR = "LOAD_KYOTAKU_PLAN2_BY_ID_ERROR";

export const CREATE_KYOTAKU_PLAN2_ERROR = "CREATE_KYOTAKU_PLAN2_ERROR";
export const UPDATE_KYOTAKU_PLAN2_ERROR = "UPDATE_KYOTAKU_PLAN2_ERROR";
export const DELETE_KYOTAKU_PLAN2_ERROR = "DELETE_KYOTAKU_PLAN2_ERROR";
export const LOAD_KYOTAKU_PLAN2_TO_QUOTE_ERROR =
  "LOAD_KYOTAKU_PLAN2_TO_QUOTE_ERROR";

export const GET_ROWS_KYOTAKU_PLAN2 = "GET_ROWS_KYOTAKU_PLAN2";
export const DELETE_ROWS_KYOTAKU_PLAN2 = "DELETE_ROWS_KYOTAKU_PLAN2";
export const ADD_ROWS_KYOTAKU_PLAN2 = "ADD_ROWS_KYOTAKU_PLAN2";
export const UPDATE_ROWS_KYOTAKU_PLAN2 = "UPDATE_ROWS_KYOTAKU_PLAN2";
export const UP_ROWS_KYOTAKU_PLAN2 = "UP_ROWS_KYOTAKU_PLAN2";
export const DOWN_ROWS_KYOTAKU_PLAN2 = "DOWN_ROWS_KYOTAKU_PLAN2";

export const UP_ROWS_KYOTAKU_PLAN2_DETAIL = "UP_ROWS_KYOTAKU_PLAN2_DETAIL";
export const DOWN_ROWS_KYOTAKU_PLAN2_DETAIL = "DOWN_ROWS_KYOTAKU_PLAN2_DETAIL";
export const DELETE_DETAIL_KYOTAKU_PLAN2 = "DELETE_DETAIL_KYOTAKU_PLAN2";
export const ADD_DETAIL_KYOTAKU_PLAN2 = "ADD_DETAIL_KYOTAKU_PLAN2";
export const GET_DETAIL_KYOTAKU_PLAN2 = "GET_DETAIL_KYOTAKU_PLAN2";
export const HANDLE_CHANGE_DETAIL_KYOTAKU_PLAN2 =
  "HANDLE_CHANGE_DETAIL_KYOTAKU_PLAN2";

export const GET_ROWINFO_KYOTAKU_PLAN2 = "GET_ROWINFO_KYOTAKU_PLAN2";
export const CHANGE_EXPANDED_ROW_KEY = "CHANGE_EXPANDED_ROW_KEY";

export const UPDATE_KIKAN_KYOTAKU_PLAN2 = "UPDATE_KIKAN_KYOTAKU_PLAN2";
export const UPDATE_SINGLE_KIKAN_KYOTAKU_PLAN2 =
  "UPDATE_SINGLE_KIKAN_KYOTAKU_PLAN2";
export const UPDATE_ON_CHANGE_KYOTAKU_PLAN2 = "UPDATE_ON_CHANGE_KYOTAKU_PLAN2";

export const CHANGE_EDIT_MODE = "CHANGE_EDIT_MODE";
export const GET_KP2_YOUGO_MASTER_SUCCESS = "GET_KP2_YOUGO_MASTER_SUCCESS";
export const GET_KP2_YOUGO_MASTER_ERROR = "GET_KP2_YOUGO_MASTER_ERROR";
export const SAVE_KP2_YOUGO_MASTER_SUCCESS = "SAVE_KP2_YOUGO_MASTER_SUCCESS";
export const SAVE_KP2_YOUGO_MASTER_ERROR = "SAVE_KP2_YOUGO_MASTER_ERROR";
export const KP2_YOUGO_EDIT_STATE = "KP2_YOUGO_EDIT_STATE";
export const KP2_YOUGO_CANCEL_STATE = "KP2_YOUGO_CANCEL_STATE";

export const CHANGE_EDIT_MOBILE_MODE = "CHANGE_EDIT_MOBILE_MODE";
export const CHANGE_VALUE_DETAIL = "CHANGE_VALUE_DETAIL";

export const LOAD_SPIN_REQUEST_PLAN2 = "LOAD_SPIN_REQUEST_PLAN2";
export const CHANGE_KAIGOHOKEN = "CHANGE_KAIGOHOKEN";

export const RESET_STATUS = "RESET_STATUS";
export const KEEP_VALUE1 = "KEEP_VALUE1";
export const SET_ROW_KYOTAKU_PLAN2 = "SET_ROW_KYOTAKU_PLAN2";
export const CLEAR_DETAIL_KYOTAKU_PLAN2 = "CLEAR_DETAIL_KYOTAKU_PLAN2";

export const SETTING_MASTER_PLAN2 = "SETTING_MASTER_PLAN2";
export const SETTING_MASTER_PLAN2_ERROR = "SETTING_MASTER_PLAN2_ERROR";
