import {
  LOAD_SISETU_KAIGO_NAIYOU_REQUEST,
  LOAD_SISETU_KAIGO_NAIYOU_SUCCESS,
  LOAD_SISETU_KAIGO_NAIYOU_ERROR,
  LOAD_SISETU_KAIGO_NAIYOU_LIST_REQUEST,
  LOAD_SISETU_KAIGO_NAIYOU_LIST_SUCCESS,
  SAVE_SISETU_KAIGO_NAIYOU_SUCCESS,
  SISETU_KAIGO_NAIYOU_MASTER_INIT_SUCCESS,
  CREATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS,
  CREATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR,
  UPDATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS,
  UPDATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR,
} from "../../actions/sisetuKaigoNaiyouMasterActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

export default function sisetuKaigoNaiyouMasterReducers(
  state = {
    meta: {
      page: 1,
      pageSize: 20,
      total: 0,
    },
  },
  action
) {
  switch (action.type) {
    case SISETU_KAIGO_NAIYOU_MASTER_INIT_SUCCESS:
      return {
        ...state,
        kaigoInit: action.payload,
        loading: false,
      };
    case CREATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        kaigo: action.payload,
        loading: false,
      };
    case CREATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        kaigo: null,
        loading: false,
      };
    case UPDATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        kaigo: action.payload,
        loading: false,
      };
    case UPDATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        kaigo: null,
        loading: false,
      };
    case LOAD_SISETU_KAIGO_NAIYOU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SISETU_KAIGO_NAIYOU_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SISETU_KAIGO_NAIYOU_SUCCESS:
      return {
        ...state,
        kaigoNaiyou: action.payload.data,
        meta: {
          page: action.payload.page,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
        },
        loading: false,
      };
    case LOAD_SISETU_KAIGO_NAIYOU_LIST_SUCCESS:
      return {
        ...state,
        kaigoNaiyouList: action.payload.data,
        // meta: {
        //   page: action.payload.page,
        //   pageSize: action.payload.pageSize,
        //   total: action.payload.total
        // },
        loading: false,
      };
    case SAVE_SISETU_KAIGO_NAIYOU_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        kaigoNaiyouListSort: action.payload.data,
        loading: false,
      };
    case LOAD_SISETU_KAIGO_NAIYOU_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        kaigoNaiyou: action.payload,
      };
    default:
      return state;
  }
}
