import {
  CHANGE_FACESHEET,
  GET_LIST_RIYOUSYA_ID_ERROR,
  GET_LIST_RIYOUSYA_ID_SUCCESS,
  GET_RIYOUSYA_ERROR,
  // 3S UPDATE
  GET_RIYOUSYA_SUCCESS,
  ITAKUTANTO_MASTER_SPIN_REQUEST,
  KAIGOHOKEN_MASTER_SPIN_REQUEST,
  KOHIFUTAN_MASTER_SPIN_REQUEST,
  LOAD_ITAKUTANTO_MASTER_ERROR,
  LOAD_ITAKUTANTO_MASTER_SUCCESS,
  LOAD_KAIGODO_MASTER_RIYOU_PAGE,
  LOAD_KAIGOHOKEN_MASTER_ERROR,
  LOAD_KAIGOHOKEN_MASTER_SUCCESS,
  LOAD_KOHIFUTAN_MASTER_ERROR,
  LOAD_KOHIFUTAN_MASTER_SUCCESS,
  RIYOUSYA_ADD_SUCCESS,
  RIYOUSYA_DETAIL_PAGE_ERROR,
  RIYOUSYA_DETAIL_PAGE_REQUEST,
  RIYOUSYA_DETAIL_SUCCESS,
  RIYOUSYA_FILE_DELETE_SUCCESS,
  RIYOUSYA_FILE_DETAIL_ERROR,
  RIYOUSYA_FILE_DETAIL_SUCCESS,
  RIYOUSYA_FILE_INSERT_ERROR,
  RIYOUSYA_FILE_INSERT_REQUEST,
  RIYOUSYA_FILE_INSERT_SUCCESS,
  RIYOUSYA_FILE_UPDATE_ERROR,
  RIYOUSYA_FILE_UPDATE_REQUEST,
  RIYOUSYA_FILE_UPDATE_SUCCESS,
  RIYOUSYA_HOKEN_ABOVE_ERROR,
  RIYOUSYA_HOKEN_ABOVE_REQUEST,
  RIYOUSYA_HOKEN_ABOVE_SUCCESS,
  RIYOUSYA_HOKEN_BELOW_ERROR,
  RIYOUSYA_HOKEN_BELOW_REQUEST,
  RIYOUSYA_HOKEN_BELOW_SUCCESS,
  RIYOUSYA_INPUT_ADD,
  RIYOUSYA_INPUT_DELETE,
  RIYOUSYA_INPUT_SORT,
  RIYOUSYA_KAIGOHOKEN_DELETE,
  RIYOUSYA_KAIGOHOKEN_UPDATE_ERROR,
  RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST,
  RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS,
  RIYOUSYA_KOHIJOHO_DELETE,
  RIYOUSYA_KOHI_UPDATE_ERROR,
  RIYOUSYA_KOHI_UPDATE_REQUEST,
  RIYOUSYA_KOHI_UPDATE_SUCCESS,
  RIYOUSYA_LIST_IS_KEEP,
  RIYOUSYA_LIST_SUCCESS,
  RIYOUSYA_LOADMORE_SUCCESS,
  RIYOUSYA_MEAL_ABOVE_ERROR,
  RIYOUSYA_MEAL_ABOVE_REQUEST,
  RIYOUSYA_MEAL_ABOVE_SUCCESS,
  RIYOUSYA_MEAL_BELOW_ERROR,
  RIYOUSYA_MEAL_BELOW_REQUEST,
  RIYOUSYA_MEAL_BELOW_SUCCESS,
  RIYOUSYA_MEAL_DEL_SHOKUJI,
  RIYOUSYA_MEAL_DEL_SYOKUJISEN,
  RIYOUSYA_MEAL_RESET,
  RIYOUSYA_MEAL_UPDATE_ERROR,
  RIYOUSYA_MEAL_UPDATE_REQUEST,
  RIYOUSYA_MEAL_UPDATE_SHOKUJI,
  RIYOUSYA_MEAL_UPDATE_SUCCESS,
  RIYOUSYA_MEAL_UPDATE_SYOKUJISEN,
  RIYOUSYA_PAGE_ERROR,
  RIYOUSYA_PAGE_REQUEST,
  RIYOUSYA_SHIRYOU_PAGE_ERROR,
  RIYOUSYA_SHIRYOU_PAGE_REQUEST,
  RIYOUSYA_SHIRYOU_SUCCESS,
  RIYOUSYA_UPDATE_SERVER,
  RIYOU_CHART_TEMPLATE_DELETE,
  RIYOU_CHART_TEMPLATE_LOAD,
  RIYOU_CHART_TEMPLATE_SAVE,
} from "../../actions/riyousyaActions/actionName.js";

import {
  DetailApi,
  FileDelete,
  FileDetail,
  FileInsert,
  FileUpdate,
  ItakuTantoMasterApi,
  KaigoHokenMasterApi,
  KaigodoMasterApi,
  KohiMasterApi,
  KohifutanMasterApi,
  ListApi,
  ListHokenApi,
  ListKohiApi,
  ListSKRApi,
  ListShokujiApi,
  MealApi,
  RiyouUpdate,
  RiyouUpdateOpen,
  ServiceKikanMasterAPi,
  ShiryouApi,
} from "../../api/riyousyaList";

import { RiyousyaApi } from "../../api/riyousya";
import {
  formatStorage,
  getMenu,
  localStorageClearCus
} from "../../common/function_common/functionCommon";

function deleteFileCreator(fileManagementId, callback) {
  return (dispatch) => {
    FileDelete.delete(fileManagementId).then((data) => {
      dispatch({
        type: RIYOUSYA_FILE_DELETE_SUCCESS,
      });

      if (callback && typeof callback === "function") {
        return callback(data);
      }
    });
  };
}

function fileDetailCreator(fileManagementId) {
  return (dispatch) => {
    FileDetail.get(fileManagementId).then((data) =>
      dispatch({
        type:
          data.status && data.status !== 200
            ? RIYOUSYA_FILE_DETAIL_ERROR
            : RIYOUSYA_FILE_DETAIL_SUCCESS,
        payload: data,
      })
    );
  };
}

function updateFileCreator(params, callback) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_FILE_UPDATE_REQUEST,
    });

    FileUpdate.update(params).then((data) => {
      dispatch({
        type:
          data.status && data.status !== 200
            ? RIYOUSYA_FILE_UPDATE_ERROR
            : RIYOUSYA_FILE_UPDATE_SUCCESS,
        payload: data,
        paramsUpdate: params, // update at local
      });

      if (callback && typeof callback === "function") {
        return callback(data);
      }
    });
  };
}

function insertFileCreator(params, callback) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_FILE_INSERT_REQUEST,
    });

    FileInsert.insert(params).then((data) => {
      dispatch({
        type:
          data.status && data.status !== 200
            ? RIYOUSYA_FILE_INSERT_ERROR
            : RIYOUSYA_FILE_INSERT_SUCCESS,
        payload: data,
        paramsUpdate: params, // update at local
      });

      if (callback && typeof callback === "function") {
        return callback(data);
      }
    });
  };
}

function updateRiyousyaCreator(params) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_PAGE_REQUEST,
    });

    //
    // let mainSID = formatStorage(getMenu()).si;

    // ConstSet.PER_PAGE * 4
    RiyouUpdate.update(params)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_UPDATE_SERVER,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_PAGE_ERROR,
          messages: error,
        })
      );
  };
}

function updateOpenRiyousyaCreator(params) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_PAGE_REQUEST,
    });

    //
    // let mainSID = formatStorage(getMenu()).si;

    // ConstSet.PER_PAGE * 4
    RiyouUpdateOpen.update(params)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_UPDATE_SERVER,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_PAGE_ERROR,
          messages: error,
        })
      );
  };
}

function handleSortCreator(type, sortType, index) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_INPUT_SORT,
      params: { type, sortType, index },
    });
  };
}

function handleDeleteCreator(type, index) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_INPUT_DELETE,
      params: { type, index },
    });
  };
}

function addNewKankeiCreator(type) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_INPUT_ADD,
      params: { type },
    });
  };
}

/**
 *
 * @param {*} tid
 * @param {*} pageNo
 */
function riyousyaListCreator(page, isRiyouf, nyuusho, furigana, id) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_PAGE_REQUEST,
    });

    //
    const menu = formatStorage(getMenu());
    const mainSID = menu.si;
    const systemName = menu.sn;

    // ConstSet.PER_PAGE * 4
    ListApi.list(page, 20, mainSID, isRiyouf, nyuusho, furigana, systemName, id)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_PAGE_ERROR,
          messages: error,
        })
      );
  };
}

function addRiyousyaCreator(params, callback) {
  return (dispatch) => {
    ListApi.add(params)
      .then((data) =>
        dispatch(
          {
            type: RIYOUSYA_ADD_SUCCESS,
            payload: data,
          },
          callback(data)
        )
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_PAGE_ERROR,
          messages: error,
        })
      );
  };
}

/**
 *
 * @param {*} tid
 * @param {*} pageNo
 */
function loadMoreCreator(page, isRiyouf, nyuusho, furigana, callback) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_PAGE_REQUEST,
    });

    const menu = formatStorage(getMenu());
    const mainSID = menu.si;
    const systemName = menu.sn;

    // ConstSet.PER_PAGE * 4
    ListApi.list(page, 20, mainSID, isRiyouf, nyuusho, furigana, systemName, "")
      .then((data) => {
        dispatch({
          type: RIYOUSYA_LOADMORE_SUCCESS,
          payload: data,
          page,
        });

        if (callback && typeof callback === "function") {
          return callback(data);
        }
      })
      .catch((error) => {
        dispatch({
          type: RIYOUSYA_PAGE_ERROR,
          messages: error,
        });

        if (callback && typeof callback === "function") {
          return callback(error);
        }
      });
  };
}

/**
 *
 * @param {*} riyousyaId
 */
function riyousyaDetailCreator(pageNo, riyousyaId, processType, selectKubun) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_DETAIL_PAGE_REQUEST,
    });

    //
    const mainSID = formatStorage(getMenu()).si;
    DetailApi.detail(pageNo, 20, mainSID, riyousyaId, processType, selectKubun)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_DETAIL_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_DETAIL_PAGE_ERROR,
          messages: error,
        })
      );
  };
}

/**
 *
 * @param {*} riyousyaId
 */
function riyousyaShiryouCreator(pageNo, riyousyaId, processType, selectKubun) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_SHIRYOU_PAGE_REQUEST,
    });

    //
    const mainSID = formatStorage(getMenu()).si;

    //
    ShiryouApi.shiryou(
      pageNo,
      100,
      mainSID,
      riyousyaId,
      processType,
      selectKubun
    )
      .then((data) =>
        dispatch({
          type: RIYOUSYA_SHIRYOU_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_SHIRYOU_PAGE_ERROR,
          messages: error,
        })
      );
  };
}

function riyousyaMealReset() {
  return (dispatch) =>
    dispatch({
      type: RIYOUSYA_MEAL_RESET,
    });
}

/**
 *
 * @param {*} page
 * @param {*} riyousyaId
 */
function getShokujisenCreator(page, riyousyaId) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_MEAL_ABOVE_REQUEST,
    });

    //
    const mainSID = formatStorage(getMenu()).si;

    // ConstSet.PER_PAGE * 4
    ListShokujiApi.list(page, 100, mainSID, riyousyaId)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_MEAL_ABOVE_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_MEAL_ABOVE_ERROR,
          messages: error,
        })
      );
  };
}

/**
 *
 * @param {*} page
 * @param {*} riyousyaId
 */
function getSyokujiJoukyoRirekipageCreator(page, riyousyaId) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_MEAL_BELOW_REQUEST,
    });

    //
    const mainSID = formatStorage(getMenu()).si;

    // ConstSet.PER_PAGE * 4
    ListSKRApi.list(page, 100, mainSID, riyousyaId)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_MEAL_BELOW_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_MEAL_BELOW_ERROR,
          messages: error,
        })
      );
  };
}

function delSyokujisenCreator(id, index) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_MEAL_DEL_SYOKUJISEN,
      params: [id, index],
    });
  };
}

function delShokujiCreator(id, index) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_MEAL_DEL_SHOKUJI,
      params: [id, index],
    });
  };
}

function updateSyokujisenCreator(record) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_MEAL_UPDATE_SYOKUJISEN,
      params: record,
    });
  };
}

function updateShokujiCreator(record) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_MEAL_UPDATE_SHOKUJI,
      params: record,
    });
  };
}

function handleSaveMealCreator(params) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_MEAL_UPDATE_REQUEST,
    });

    MealApi.save(params)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_MEAL_UPDATE_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_MEAL_UPDATE_ERROR,
          messages: error,
        })
      );
  };
}

export function getRiyousya(riyousyaId) {
  return (dispatch) => {
    RiyousyaApi.getRiyousya(riyousyaId).then(
      (data) =>
        dispatch({
          type: GET_RIYOUSYA_SUCCESS,
          riyousya: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: GET_RIYOUSYA_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getRiyousyaIds() {
  return (dispatch) => {
    RiyousyaApi.getRiyousyaIds().then(
      (data) =>
        dispatch({
          type: GET_LIST_RIYOUSYA_ID_SUCCESS,
          riyousyaIds: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: GET_LIST_RIYOUSYA_ID_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function riyousyaListIsKeep(searchName, isKeep, id) {
  return (dispatch) =>
    dispatch({
      type: RIYOUSYA_LIST_IS_KEEP,
      data: {
        addRiyousyaId: id,
        searchName,
        isKeep,
      },
    });
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}

/**
 *
 * @param {*} page
 * @param {*} riyousyaId
 */
function getKaigoHokenCreator(page, riyousyaId) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_HOKEN_ABOVE_REQUEST,
    });

    //
    const mainSID = formatStorage(getMenu()).si;

    // ConstSet.PER_PAGE * 4
    ListHokenApi.list(page, 4, mainSID, riyousyaId)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_HOKEN_ABOVE_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_HOKEN_ABOVE_ERROR,
          messages: error,
        })
      );
  };
}

function updateKaigoHokenCreator(params) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST,
    });

    ListHokenApi.save(params)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_KAIGOHOKEN_UPDATE_ERROR,
          messages: error,
        })
      );
  };
}

function deleteKaigoHokenCreator(id) {
  return (dispatch) => {
    ListHokenApi.del(id).then(() => {
      // callback();
      return dispatch({
        type: RIYOUSYA_KAIGOHOKEN_DELETE,
        id,
      });
    });
  };
}

/**
 *
 * @param {*} page
 * @param {*} riyousyaId
 */
function getKohiJohoCreator(page, riyousyaId) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_HOKEN_BELOW_REQUEST,
    });

    //
    const mainSID = formatStorage(getMenu()).si;

    // ConstSet.PER_PAGE * 4
    ListKohiApi.list(page, 4, mainSID, riyousyaId)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_HOKEN_BELOW_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_HOKEN_BELOW_ERROR,
          messages: error,
        })
      );
  };
}

function updateKohiJohoCreator(params) {
  return (dispatch) => {
    dispatch({
      type: RIYOUSYA_KOHI_UPDATE_REQUEST,
    });

    ListKohiApi.save(params)
      .then((data) =>
        dispatch({
          type: RIYOUSYA_KOHI_UPDATE_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: RIYOUSYA_KOHI_UPDATE_ERROR,
          messages: error,
        })
      );
  };
}

function deleteKohiJohoCreator(id) {
  return (dispatch) => {
    ListKohiApi.del(id).then(() => {
      // callback();
      return dispatch({
        type: RIYOUSYA_KOHIJOHO_DELETE,
        id,
      });
    });
  };
}

function loadKaigoHokenMaster(hokenNo) {
  return (dispatch) => {
    dispatch({
      type: KAIGOHOKEN_MASTER_SPIN_REQUEST,
    });
    KaigoHokenMasterApi.list(hokenNo).then(
      (data) => {
        dispatch({
          type: LOAD_KAIGOHOKEN_MASTER_SUCCESS,
          data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KAIGOHOKEN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function getkaigodo() {
  return (dispatch) => {
    KaigodoMasterApi.list().then(
      (data) => {
        dispatch({
          type: LOAD_KAIGODO_MASTER_RIYOU_PAGE,
          data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          // type: LOAD_KAIGOHOKEN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

/**
 *
 * @param {*} name 担当者名
 * @param {*} id serviceKikanのid
 * @returns
 */
function loadItakuTantoMaster(name, id) {
  return (dispatch) => {
    dispatch({
      type: ITAKUTANTO_MASTER_SPIN_REQUEST,
    });
    ItakuTantoMasterApi.list(name, id).then(
      (data) => {
        dispatch({
          type: LOAD_ITAKUTANTO_MASTER_SUCCESS,
          data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_ITAKUTANTO_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

// jigyousyo_noでjigyousyo_name&syuruiCdをcallBackでそのまま返す
function getJigyousyoNameByNo(jigyousyoNo, syuruiCd, callback) {
  return () => {
    ServiceKikanMasterAPi.getJigyousyoName(jigyousyoNo, syuruiCd).then(
      (data) => {
        return callback(data);
      },
      (err) => {
        checkError(err);
      }
    );
  };
}

function getHokenNameByNo(hokensyaNo, callback) {
  return () => {
    ListHokenApi.getHokenName(hokensyaNo).then(
      (data) => {
        return callback(data);
      },
      (err) => {
        checkError(err);
      }
    );
  };
}

function getKohifutanMasterByNo(hutanNo, callback) {
  return () => {
    KohifutanMasterApi.getKohifutan(hutanNo).then(
      (data) => {
        return callback(data);
      },
      (err) => {
        checkError(err);
      }
    );
  };
}

function loadKohifutanMaster(hutanNo) {
  return (dispatch) => {
    dispatch({
      type: KOHIFUTAN_MASTER_SPIN_REQUEST,
    });
    KohifutanMasterApi.list(hutanNo).then(
      (data) => {
        dispatch({
          type: LOAD_KOHIFUTAN_MASTER_SUCCESS,
          data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KOHIFUTAN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function getKohiMaster(kohiNo, callback) {
  return () => {
    KohiMasterApi.getKohi(kohiNo).then(
      (data) => {
        return callback(data);
      },
      (err) => {
        checkError(err);
      }
    );
  };
}

function changefacesheet(state, type) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_FACESHEET,
      data: { state, type }
    });
  };
}

// riyou_chartテンプレート
function riyouChartTemplateSave(values) {
  return (dispatch) => {
    RiyousyaApi.riyouChartTemplateSave(values)
      .then((data) =>
        dispatch({
          type: RIYOU_CHART_TEMPLATE_SAVE,
          data,
        })
      ).catch((error) =>
        // dispatch({
        //   type: RIYOUSYA_PAGE_ERROR,
        //   messages: error,
        // })
        console.log(error)
      );
  };
}
function riyouChartTemplateLoad(callback) {
  return (dispatch) => {
    RiyousyaApi.riyouChartTemplateLoad()
      .then((data) => {
        dispatch({
          type: RIYOU_CHART_TEMPLATE_LOAD,
          data,
        });
        return callback(data);
      }).catch((error) =>
        // dispatch({
        //   type: RIYOUSYA_PAGE_ERROR,
        //   messages: error,
        // })
        console.log(error)
      );
  };
}

function riyouChartTemplateDelete(id) {
  return (dispatch) => {
    RiyousyaApi.riyouChartTemplateDelete(id)
      .then((data) => {
        dispatch({
          type: RIYOU_CHART_TEMPLATE_DELETE,
          data,
        });
      }).catch((error) =>
        // dispatch({
        //   type: RIYOUSYA_PAGE_ERROR,
        //   messages: error,
        // })
        console.log(error)
      );
  };
}

export {
  addNewKankeiCreator, addRiyousyaCreator, changefacesheet, delShokujiCreator, delSyokujisenCreator, deleteFileCreator, deleteKaigoHokenCreator, deleteKohiJohoCreator, fileDetailCreator, getHokenNameByNo, getJigyousyoNameByNo, getKaigoHokenCreator,
  getKohiJohoCreator, getKohiMaster, getKohifutanMasterByNo, getShokujisenCreator,
  getSyokujiJoukyoRirekipageCreator, getkaigodo, handleDeleteCreator, handleSaveMealCreator, handleSortCreator, insertFileCreator, loadItakuTantoMaster, loadKaigoHokenMaster, loadKohifutanMaster, loadMoreCreator, riyouChartTemplateDelete, riyouChartTemplateLoad, riyouChartTemplateSave, riyousyaDetailCreator, riyousyaListCreator, riyousyaListIsKeep, riyousyaMealReset, riyousyaShiryouCreator, updateFileCreator, updateKaigoHokenCreator, updateKohiJohoCreator, updateOpenRiyousyaCreator, updateRiyousyaCreator, updateShokujiCreator, updateSyokujisenCreator
};

