import "whatwg-fetch";
import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";
import { getSid } from "../common/function_common/functionCommon";

let SystemControlApi = {
  getSystemNameList() {
    return axios(apiUrls.INIT + apiUrls.GET_SYSTEM_NAMES, {
      method: "POST",
      headers: {
        "x-token": localStorage.getItem("jwtToken"),
      },
      withCredentials: true,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          if (err.response.status === 403) {
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/login");
          }
          return err;
        }
      });
  },

  getSystemControlSisetu() {
    let sid = getSid();
    return axios(
      apiUrls.INIT + apiUrls.GET_SYSTEM_CONTROL_SISETU + `?sid=${sid}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          if (err.response.status === 403) {
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/login");
          }
          return err;
        }
      });
  },
};

export { SystemControlApi };
