export const Plan1ActionTypes = {
  GET_ALL_PLAN1_REQUEST: "GET_ALL_PLAN1_REQUEST",
  GET_ALL_PLAN1_SUCCESS: "GET_ALL_PLAN1_SUCCESS",
  GET_ALL_PLAN1_FAILURE: "GET_ALL_PLAN1_FAILURE",

  GET_PLAN1_REQUEST: "GET_PLAN1_REQUEST",
  GET_PLAN1_SUCCESS: "GET_PLAN1_SUCCESS",
  GET_PLAN1_FAILURE: "GET_PLAN1_FAILURE",

  CREATE_PLAN1_REQUEST: "CREATE_PLAN1_REQUEST",
  CREATE_PLAN1_SUCCESS: "CREATE_PLAN1_SUCCESS",
  CREATE_PLAN1_FAILURE: "CREATE_PLAN1_FAILURE",

  UPDATE_PLAN1_REQUEST: "UPDATE_PLAN1_REQUEST",
  UPDATE_PLAN1_SUCCESS: "UPDATE_PLAN1_SUCCESS",
  UPDATE_PLAN1_FAILURE: "UPDATE_PLAN1_FAILURE",

  REMOVE_PLAN1_REQUEST: "REMOVE_PLAN1_REQUEST",
  REMOVE_PLAN1_SUCCESS: "REMOVE_PLAN1_SUCCESS",
  REMOVE_PLAN1_FAILURE: "REMOVE_PLAN1_FAILURE",

  GET_ALL_PLAN1_QUOTE_REQUEST: "GET_ALL_PLAN1_QUOTE_REQUEST",
  GET_ALL_PLAN1_QUOTE_SUCCESS: "GET_ALL_PLAN1_QUOTE_SUCCESS",
  GET_ALL_PLAN1_QUOTE_FAILURE: "GET_ALL_PLAN1_QUOTE_FAILURE",
};
