/* eslint-disable */
import { Checkbox, Col, Radio, Row, Select } from 'antd';
import _ from "lodash";
import React from 'react';
import InputSentaku from './InputSentaku';

const Option = Select.Option;
const RadioGroup = Radio.Group;

export default class FilterConditions extends React.Component {
    constructor(props) {
        super(props);

        const {
            settingNaiyo1,
            yougoMaster11,
            yougoMaster12,
            yougoMaster13,
            yougoMaster14,
            yougoMaster15,
            yougoMaster21,
            kirokuKubun,
            tantoShikaku,
            sonotaKubun,
            systems
        } = props;
        let yougoMasterOption = yougoMaster11;
        if (this.props.filterData.search.field10) {
            let valueKey = this.props.filterData.search.field10.split('=')[0];
            if (valueKey === '分類名2') {
                yougoMasterOption = yougoMaster12
            }
            if (valueKey === '分類名3') {
                yougoMasterOption = yougoMaster13
            }
            if (valueKey === '分類名4') {
                yougoMasterOption = yougoMaster14
            }
            if (valueKey === '分類名5') {
                yougoMasterOption = yougoMaster15
            }
        }

        this.state = {
            kubun: '', // 1
            settingNaiyo1: settingNaiyo1,
            classify1: settingNaiyo1 ? settingNaiyo1[0] : null, // 2
            classify2: '',
            yougoMaster11: yougoMaster11,
            yougoMaster12: yougoMaster12,
            yougoMaster13: yougoMaster13,
            yougoMaster14: yougoMaster14,
            yougoMaster15: yougoMaster15,
            yougoMasterOption: yougoMasterOption,
            yougoMaster21: yougoMaster21,
            floor: yougoMaster21 ? yougoMaster21[0] : null, // 3
            kirokuKubun,
            tantoShikaku,
            sonotaKubun,
            systems
        }

        // bind place

        this.handleCheckbox1 = this.handleCheckbox1.bind(this);
        this.handleChange10 = this.handleChange10.bind(this);
        this.handleChange11 = this.handleChange11.bind(this);

        this.handleCheckbox2 = this.handleCheckbox2.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);

        this.handleCheckbox3 = this.handleCheckbox3.bind(this);
        this.handleChange3 = this.handleChange3.bind(this);

        this.handleCheckbox4 = this.handleCheckbox4.bind(this);
        this.handleChange4 = this.handleChange4.bind(this);

        this.handleCheckbox5 = this.handleCheckbox5.bind(this);
        this.handleChange5 = this.handleChange5.bind(this);

        this.handleCheckbox6 = this.handleCheckbox6.bind(this);
        this.handleChange6 = this.handleChange6.bind(this);

        this.handleCheckbox7 = this.handleCheckbox7.bind(this);
        this.handleChange7 = this.handleChange7.bind(this);

        this.handleCheckbox8 = this.handleCheckbox8.bind(this);
        this.handleChange8 = this.handleChange8.bind(this);

        this.handleCheckbox9 = this.handleCheckbox9.bind(this);
        this.handleChange9 = this.handleChange9.bind(this);
    }

    /**
     *
     * @param {*} e
     */
    handleCheckbox1(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox1 = e.target.checked;
        this.props.handleUpdateState(newData);
    }

    /**
     *
     * @param {*} value
     */
    handleChange10(value) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox1 = true;
        newData.search.field10 = value;
        newData.search.field11 = null;
        this.props.handleUpdateState(newData);

        // handle field2 form value of field1
        let valueKey = value.split('=')[0];

        this.setState({
            classify1: value,
            classify2: ''
        })

        if (valueKey === '分類名1') {
            this.setState({
                yougoMasterOption: this.state.yougoMaster11

            })
        }

        if (valueKey === '分類名2') {
            this.setState({
                yougoMasterOption: this.state.yougoMaster12
            })
        }

        if (valueKey === '分類名3') {
            this.setState({
                yougoMasterOption: this.state.yougoMaster13
            })
        }

        if (valueKey === '分類名4') {
            this.setState({
                yougoMasterOption: this.state.yougoMaster14
            })
        }

        if (valueKey === '分類名5') {
            this.setState({
                yougoMasterOption: this.state.yougoMaster15
            })
        }
    }

    handleChange11(value) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox1 = true;
        newData.search.field11 = value;
        if (!newData.search.field10) {
            newData.search.field10 = this.state.settingNaiyo1[0]
        }
        this.props.handleUpdateState(newData);
    }

    /**
     *
     * @param {*} e
     */
    handleCheckbox2(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox2 = e.target.checked;
        this.props.handleUpdateState(newData);
    }

    handleChange2(value) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox2 = true;
        newData.search.field2 = value;
        this.props.handleUpdateState(newData);
    }

    /**
     *
     * @param {*} e
     */
    handleCheckbox3(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox3 = e.target.checked;
        this.props.handleUpdateState(newData);
    }

    handleChange3(value) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox3 = true;
        newData.search.field3 = value;
        this.props.handleUpdateState(newData);
    }

    handleCheckbox4(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox4 = e.target.checked;
        this.props.handleUpdateState(newData);
    }

    handleChange4(value, type) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox4 = true;
        if ((type && type === "input") || !newData.search.field4) {
            newData.search.field4 = value;
        } else {
            newData.search.field4 = newData.search.field4 + "　" + value;
        }

        this.props.handleUpdateState(newData);
    }

    handleCheckbox5(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox5 = e.target.checked;
        this.props.handleUpdateState(newData);
    }

    handleChange5(value) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox5 = true;
        newData.search.field5 = value;
        this.props.handleUpdateState(newData);
    }

    handleCheckbox6(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox6 = e.target.checked;
        this.props.handleUpdateState(newData);
    }

    handleChange6(value) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox6 = true;
        newData.search.field6 = value;
        this.props.handleUpdateState(newData);
    }

    handleCheckbox7(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox7 = e.target.checked;
        this.props.handleUpdateState(newData);
    }

    handleChange7(value, type) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox7 = true;
        if ((type && type === "input") || !newData.search.field7) {
            newData.search.field7 = value;
        } else {
            newData.search.field7 = newData.search.field7 + "　" + value;
        }
        this.props.handleUpdateState(newData);
    }

    handleCheckbox12 = (e) => {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox12 = e.target.checked;
        this.props.handleUpdateState(newData);
    }

    handleChange12 = (value) => {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox12 = true;
        newData.search.field12 = value;
        this.props.handleUpdateState(newData);
    }

    handleCheckbox8(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox8 = e.target.checked;
        newData.search.field8 = 'ケアマネ';
        this.props.handleUpdateState(newData);
    }

    handleChange8(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox8 = true;
        newData.search.field8 = e.target.value;
        this.props.handleUpdateState(newData);
    }

    handleCheckbox9(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox9 = e.target.checked;
        newData.search.field9 = e.target.checked ? true : false;
        this.props.handleUpdateState(newData);
    }

    handleChange9(e) {
        const { filterData } = this.props;
        // use cloneDeep to avoid modify props directly
        let newData = _.cloneDeep(filterData);
        newData.search.checkbox9 = e.target.checked ? true : false;
        newData.search.field9 = e.target.checked;
        this.props.handleUpdateState(newData);
    }

    render() {
        const { filterData, riyousyaBunrui, tantoMasters } = this.props;
        const tantoMastersSort = Array.isArray(tantoMasters) ? tantoMasters.sort((a, b) => {
            if (a.sortNum < b.sortNum) return -1;
            if (a.sortNum > b.sortNum) return 1;
            return 0;
        }) : null;
        return (
            <div className={'kn-search-con'}>
                <Row>
                    <Col xs={4}>
                        <Checkbox onChange={this.handleCheckbox1}
                            disabled={riyousyaBunrui}
                            checked={filterData.search.checkbox1}>検索</Checkbox>
                    </Col>
                    <Col xs={6}>
                        <Select
                            // showSearch
                            disabled={riyousyaBunrui}
                            style={{ width: '90%' }}
                            value={filterData.search.field10 ? filterData.search.field10 : this.state.settingNaiyo1[0]}
                            optionFilterProp="children"
                            onChange={this.handleChange10}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {this.state.settingNaiyo1 ?
                                this.state.settingNaiyo1.map((rel, index) => {
                                    let tmp = rel.split('=');
                                    return (
                                        <Option key={index} value={rel}>{tmp[1]}</Option>
                                    );

                                })
                                :
                                null
                            }
                        </Select>
                    </Col>
                    <Col xs={14}>

                        <Select
                            showSearch
                            disabled={riyousyaBunrui}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            value={filterData.search.field11}
                            onChange={this.handleChange11}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {this.state.yougoMasterOption ?
                                this.state.yougoMasterOption.map((rel, index) => {
                                    return (
                                        <Option key={index} value={rel}>{rel}</Option>
                                    );
                                })
                                :
                                null
                            }
                        </Select>

                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Checkbox onChange={this.handleCheckbox2}
                            disabled={riyousyaBunrui}
                            checked={filterData.search.checkbox2}>検索</Checkbox>

                    </Col>
                    <Col xs={6} >
                        <span>部屋分類: </span>
                    </Col>
                    <Col xs={14}>

                        <Select
                            showSearch
                            disabled={riyousyaBunrui}
                            style={{ width: '100%' }}
                            value={filterData.search.field2}
                            optionFilterProp="children"
                            onChange={this.handleChange2}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {this.state.yougoMaster21 ?
                                this.state.yougoMaster21.map((rel, index) => {
                                    return (
                                        <Option key={index} value={rel}>{rel}</Option>
                                    );
                                })
                                :
                                null
                            }
                        </Select>

                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Checkbox onChange={this.handleCheckbox3}
                            checked={filterData.search.checkbox3}>検索</Checkbox>

                    </Col>
                    <Col xs={6} >
                        <span>サービス種類: </span>
                    </Col>
                    <Col xs={14}>

                        <Select
                            // showSearch
                            style={{ width: '100%' }}
                            value={filterData.search.field3}
                            optionFilterProp="children"
                            onChange={this.handleChange3}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {this.state.systems ?
                                this.state.systems.map((rel, index) => {
                                    return (
                                        <Option key={index} value={rel.sid}>{rel.name}</Option>
                                    );
                                })
                                :
                                null
                            }
                        </Select>

                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Checkbox
                            onChange={this.handleCheckbox4}
                            checked={filterData.search.checkbox4}>検索</Checkbox>

                    </Col>
                    <Col xs={6} >
                        <span>記録区分: </span>
                    </Col>
                    <Col xs={14}>
                        <InputSentaku
                            onChange={this.handleChange4}
                            listName={"KK経過記録区分"}
                            value={filterData.search.field4}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Checkbox
                            onChange={this.handleCheckbox7}
                            checked={filterData.search.checkbox7}>検索</Checkbox>

                    </Col>
                    <Col xs={6} >
                        <span>その他区分: </span>
                    </Col>
                    <Col xs={14}>
                        <InputSentaku
                            onChange={this.handleChange7}
                            listName={"KK経過その他区分"}
                            value={filterData.search.field7}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Checkbox
                            onChange={this.handleCheckbox12}
                            checked={filterData.search.checkbox12}>検索</Checkbox>

                    </Col>
                    <Col xs={6} >
                        <span>文字列検索: </span>
                    </Col>
                    <Col xs={14}>
                        <InputSentaku
                            onChange={this.handleChange12}
                            // listName={"KK経過その他区分"}
                            value={filterData.search.field12}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Checkbox
                            onChange={this.handleCheckbox5}
                            checked={filterData.search.checkbox5}>検索</Checkbox>

                    </Col>
                    <Col xs={6} >
                        <span>担当者: </span>
                    </Col>
                    <Col xs={14}>

                        <Select
                            // showSearch
                            style={{ width: '100%' }}
                            value={filterData.search.field5}
                            optionFilterProp="children"
                            onChange={this.handleChange5}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            <Option key={0} value={0}>{"ログイン担当者"}</Option>
                            {tantoMastersSort ?
                                tantoMastersSort.map((rel, index) => {
                                    return (
                                        <Option key={index} value={rel.id}>{rel.name}</Option>
                                    );
                                })
                                :
                                null
                            }
                        </Select>

                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Checkbox onChange={this.handleCheckbox6}
                            checked={filterData.search.checkbox6}>検索</Checkbox>

                    </Col>
                    <Col xs={6} >
                        <span>担当者資格: </span>
                    </Col>

                    <Col xs={14}>

                        <Select
                            // showSearch
                            style={{ width: '100%' }}
                            value={filterData.search.field6}
                            optionFilterProp="children"
                            onChange={this.handleChange6}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {this.state.tantoShikaku ?
                                this.state.tantoShikaku.map((rel, index) => {
                                    return (
                                        <Option key={index} value={rel}>{rel}</Option>
                                    );
                                })
                                :
                                null
                            }
                        </Select>

                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Checkbox onChange={this.handleCheckbox8}
                            checked={filterData.search.checkbox8}>検索</Checkbox>

                    </Col>
                    <Col xs={18}>

                        <RadioGroup onChange={this.handleChange8} value={filterData.search.field8}>
                            <Radio value={'ケアマネ'}>ケアマネ記録のみ</Radio>
                            <Radio value={'記録'}>記録のみ</Radio>
                        </RadioGroup>

                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Checkbox onChange={this.handleCheckbox9}
                            checked={filterData.search.checkbox9}>検索</Checkbox>

                    </Col>
                    <Col xs={18}>

                        <Checkbox onChange={this.handleChange9}
                            checked={filterData.search.field9}>重要のみ表示</Checkbox>

                    </Col>
                </Row>
            </div>
        );
    }
}