import { DoctorMasterActionTypes } from "../action_types";
// DOCTOR_MASTER_SPIN_REQUEST,
//   LOAD_DOCTOR_MASTER_SUCCESS,
//   LOAD_DOCTOR_MASTER_ERROR,
//   LOAD_MAX_SORT_SUCCESS,
//   LOAD_MAX_SORT_ERROR,
//   LOAD_DOCTOR_MASTER_INIT_SUCCESS,
//   LOAD_DOCTOR_MASTER_INIT_ERROR,
//   CREATE_DOCTOR_MASTER_SUCCESS,
//   CREATE_DOCTOR_MASTER_ERROR,
//   UPDATE_DOCTOR_MASTER_SUCCESS,
//   UPDATE_DOCTOR_MASTER_ERROR,
//   UPDATE_LIST_DOCTOR_MASTER_SUCCESS,

// constants
import { ApiPaths } from "../../constants";
// utils
import { RequestUtils } from "../../utils";

export function loadDoctorMaster(searchText) {
  // console.log("ドクター新")
  return async (dispatch) => {
    try {
      dispatch({ type: DoctorMasterActionTypes.DOCTOR_MASTER_SPIN_REQUEST })

      let query = { searchText };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.DoctorMaster.GetDoctorMasterList, //ここで取得
        query,
      });

      dispatch({
        type: DoctorMasterActionTypes.LOAD_DOCTOR_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: DoctorMasterActionTypes.LOAD_DOCTOR_MASTER_ERROR,
        error,
      });
    }
  };
}

// export function loadMaxSort(callback) {
//   return (dispatch) => {
//     dispatch({
//       type: DOCTOR_MASTER_SPIN_REQUEST,
//     })
//     DoctorMasterApi.loadMaxSort().then(
//       (data) => {
//         dispatch({
//           type: LOAD_MAX_SORT_SUCCESS,
//           data: data,
//         });
//         return callback(data);
//       },
//       (err) => {
//         checkError(err);
//         dispatch({
//           type: LOAD_MAX_SORT_ERROR,
//           error:
//             err.response.status === 400
//               ? err.response.data.error
//               : { message: err.response.statusText },
//         });
//       }
//     );
//   };
// }

// export function loadDoctorMasterInit(callback) {
//   return (dispatch) => {
//     dispatch({
//       type: DOCTOR_MASTER_SPIN_REQUEST,
//     })
//     DoctorMasterApi.loadDoctorMasterInit().then(
//       (data) => {
//         dispatch({
//           type: LOAD_DOCTOR_MASTER_INIT_SUCCESS,
//           data: data,
//         });
//         return callback(data);
//       },
//       (err) => {
//         checkError(err);
//         dispatch({
//           type: LOAD_DOCTOR_MASTER_INIT_ERROR,
//           error:
//             err.response.status === 400
//               ? err.response.data.error
//               : { message: err.response.statusText },
//         });
//       }
//     );
//   };
// }

// export function createDoctorMaster(body, callback) {
//   return (dispatch) => {
//     dispatch({
//       type: DOCTOR_MASTER_SPIN_REQUEST,
//     })
//     DoctorMasterApi.createDoctorMaster(body).then(
//       (data) => {
//         dispatch({
//           type: CREATE_DOCTOR_MASTER_SUCCESS,
//           data: data
//         });
//         return callback(data);
//       },
//       err => {
//         checkError(err);
//         dispatch({
//           type: CREATE_DOCTOR_MASTER_ERROR,
//           error: err.response.status === 400 ? err.response.data.error : { message: err.response.statusText }
//         });
//       }
//     );
//   };
// }

// export function updateDoctorMaster(body, callback) {
//   return (dispatch) => {
//     dispatch({
//       type: DOCTOR_MASTER_SPIN_REQUEST,
//     })
//     DoctorMasterApi.updateDoctorMaster(body).then(
//       (data) => {
//         dispatch({
//           type: UPDATE_DOCTOR_MASTER_SUCCESS,
//           data: data
//         })
//         return callback(data);
//       },
//       err => {
//         checkError(err);
//         dispatch({
//           type: UPDATE_DOCTOR_MASTER_ERROR,
//           error: err.response.status === 400 ? err.response.data.error : { message: err.response.statusText }
//         });
//       }
//     );
//   };
// }

// export function updateDocotrMasterList(body) {
//   return (dispatch) => {
//     // dispatch({
//     //   type: DOCTOR_MASTER_SPIN_REQUEST,
//     // })
//     DoctorMasterApi.updateList(body).then(
//       (data) => {
//         dispatch({
//           type: UPDATE_LIST_DOCTOR_MASTER_SUCCESS,
//           payload: data,
//         })
//       },
//       err => {
//         checkError(err);
//         dispatch({
//           type: UPDATE_DOCTOR_MASTER_ERROR,
//           error: err.response.status === 400 ? err.response.data.error : { message: err.response.statusText}
//         })
//       }
//     )
//   }
// }

// function checkError(err) {
//   // Check Token Exist
//   if (err.response.status === 403) {
//     localStorageClearCus();
//     window.location.replace("/login");
//   }
// }

// export const DoctorMasterActions = {
//   loadDoctorMaster
// };
