import { connect } from "react-redux";

import ReportWeekly from "./ReportWeekly";
import {
  loadDataCreator,
  deleteRecordsCreator,
  deleteAllImagesCreator,
  deleteAllRecordsCreator,
} from "../../actions/reportWeeklyActions/actionCreators";
import { filterCreator } from "../../actions/reportActions/actionCreators";
import eventPlanRiyousyaCreator from "../../actions/eventPlanActions/actionCreators";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    // ReportData
    reports: state.reportWeekly.data,
    loading: state.reportWeekly.loading,

    // load setting
    settingData: state.report.nyutaishoFilter,

    // load event play + riyousya plan
    planRiyoysya: state.eventPlan.eventWRiyousya,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    /**
     * load data
     */
    loadData: (parameterObj) => dispatch(loadDataCreator(parameterObj)),

    /**
     *
     */
    deleteRecords: (delValue, parameterObj) =>
      dispatch(deleteRecordsCreator(delValue, parameterObj)),
    deleteAllImages: (record, parameterObj) =>
      dispatch(deleteAllImagesCreator(record, parameterObj)),
    deleteAllRecords: (record, parameterObj) =>
      dispatch(deleteAllRecordsCreator(record, parameterObj)),

    /**
     * setting data
     */
    loadSetting: () => dispatch(filterCreator()),

    /**
     * Load event plan + riyousyaPlan
     *
     */
    loadPlan: (data, riyousyaId) =>
      dispatch(eventPlanRiyousyaCreator(data, riyousyaId)),
  };
};

export const ReportWeeklyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportWeekly);
