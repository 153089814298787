import {
  BARTHELINDEX_SPIN_REQUEST,
  GET_BARTHELINDEX_MASTER,
  GET_BARTHELINDEX_MASTER_ERROR,
} from "../../actions/barthelIndexMasterActions/actionName";

export default function barthelIndexMaster(state = {}, action) {
  switch (action.type) {
    case BARTHELINDEX_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_BARTHELINDEX_MASTER:
      return {
        ...state,
        barthelIndexMaster: action.data,
        loading: false,
      };

    case GET_BARTHELINDEX_MASTER_ERROR:
      return {
        ...state,
        barthelIndexMaster: null,
        loading: false,
      };

    default:
      return state;
  }
}
