import NotFound from "../pages/Error/404";
import AccessDenied from "../pages/Error/403";

const routes = [
  {
    path: "/403",
    component: AccessDenied,
  },
  {
    path: "*",
    component: NotFound,
  },
];

export default routes.map((route) => {
  route.type = "error";
  return route;
});
