export const WeeklyPlanActionTypes = {
  GET_ALL_WEEKLYPLAN_REQUEST: "GET_ALL_WEEKLYPLAN_REQUEST",
  GET_ALL_WEEKLYPLAN_SUCCESS: "GET_ALL_WEEKLYPLAN_SUCCESS",
  GET_ALL_WEEKLYPLAN_FAILURE: "GET_ALL_WEEKLYPLAN_FAILURE",

  GET_WEEKLYPLAN_REQUEST: "GET_WEEKLYPLAN_REQUEST",
  GET_WEEKLYPLAN_SUCCESS: "GET_WEEKLYPLAN_SUCCESS",
  GET_WEEKLYPLAN_FAILURE: "GET_WEEKLYPLAN_FAILURE",

  CREATE_WEEKLYPLAN_REQUEST: "CREATE_WEEKLYPLAN_REQUEST",
  CREATE_WEEKLYPLAN_SUCCESS: "CREATE_WEEKLYPLAN_SUCCESS",
  CREATE_WEEKLYPLAN_FAILURE: "CREATE_WEEKLYPLAN_FAILURE",

  UPDATE_WEEKLYPLAN_REQUEST: "UPDATE_WEEKLYPLAN_REQUEST",
  UPDATE_WEEKLYPLAN_SUCCESS: "UPDATE_WEEKLYPLAN_SUCCESS",
  UPDATE_WEEKLYPLAN_FAILURE: "UPDATE_WEEKLYPLAN_FAILURE",

  REMOVE_WEEKLYPLAN_REQUEST: "REMOVE_WEEKLYPLAN_REQUEST",
  REMOVE_WEEKLYPLAN_SUCCESS: "REMOVE_WEEKLYPLAN_SUCCESS",
  REMOVE_WEEKLYPLAN_FAILURE: "REMOVE_WEEKLYPLAN_FAILURE",

  GET_ALL_WEEKLY_PLAN_DETAIL_REQUEST: "GET_ALL_WEEKLY_PLAN_DETAIL_REQUEST",
  GET_ALL_WEEKLY_PLAN_DETAIL_SUCCESS: "GET_ALL_WEEKLY_PLAN_DETAIL_SUCCESS",
  GET_ALL_WEEKLY_PLAN_DETAIL_FAILURE: "GET_ALL_WEEKLY_PLAN_DETAIL_FAILURE",

  GET_WEEKLY_PLAN_BY_ID_REQUEST: "GET_WEEKLY_PLAN_BY_ID_REQUEST",
  GET_WEEKLY_PLAN_BY_ID_SUCCESS: "GET_WEEKLY_PLAN_BY_ID_SUCCESS",
  GET_WEEKLY_PLAN_BY_ID_FAILURE: "GET_WEEKLY_PLAN_BY_ID_FAILURE",

  GET_ALL_WEEKLY_PLAN_TEMPLATE_REQUEST: "GET_ALL_WEEKLY_PLAN_TEMPLATE_REQUEST",
  GET_ALL_WEEKLY_PLAN_TEMPLATE_SUCCESS: "GET_ALL_WEEKLY_PLAN_TEMPLATE_SUCCESS",
  GET_ALL_WEEKLY_PLAN_TEMPLATE_FAILURE: "GET_ALL_WEEKLY_PLAN_TEMPLATE_FAILURE",

  GET_LIST_WEEKLY_PLAN_QUOTE_REQUEST: "GET_LIST_WEEKLY_PLAN_QUOTE_REQUEST",
  GET_LIST_WEEKLY_PLAN_QUOTE_SUCCESS: "GET_LIST_WEEKLY_PLAN_QUOTE_SUCCESS",
  GET_LIST_WEEKLY_PLAN_QUOTE_FAILURE: "GET_LIST_WEEKLY_PLAN_QUOTE_FAILURE",

  UPDATE_WEEKLY_PLAN_REQUEST: "UPDATE_WEEKLY_PLAN_REQUEST",
  UPDATE_WEEKLY_PLAN_SUCCESS: "UPDATE_WEEKLY_PLAN_SUCCESS",
  UPDATE_WEEKLY_PLAN_FAILURE: "UPDATE_WEEKLY_PLAN_FAILURE",

  GET_SERVICE_COLOR_MASTER_REQUEST: "GET_SERVICE_COLOR_MASTER_REQUEST",
  GET_SERVICE_COLOR_MASTER_SUCCESS: "GET_SERVICE_COLOR_MASTER_SUCCESS",
  GET_SERVICE_COLOR_MASTER_FAILURE: "GET_SERVICE_COLOR_MASTER_FAILURE",

  GET_SERVICE_CONTENT_BY_TYPE_REQUEST: "GET_SERVICE_CONTENT_BY_TYPE_REQUEST",
  GET_SERVICE_CONTENT_BY_TYPE_SUCCESS: "GET_SERVICE_CONTENT_BY_TYPE_SUCCESS",
  GET_SERVICE_CONTENT_BY_TYPE_FAILURE: "GET_SERVICE_CONTENT_BY_TYPE_FAILURE",

  GET_SERVICE_ADDITION_REQUEST: "GET_SERVICE_ADDITION_REQUEST",
  GET_SERVICE_ADDITION_SUCCESS: "GET_SERVICE_ADDITION_SUCCESS",
  GET_SERVICE_ADDITION_FAILURE: "GET_SERVICE_ADDITION_FAILURE",
  UPDATE_WEEKLY_PLAN_TEMPLATE_REQUEST: "UPDATE_WEEKLY_PLAN_TEMPLATE_REQUEST",
  UPDATE_WEEKLY_PLAN_TEMPLATE_SUCCESS: "UPDATE_WEEKLY_PLAN_TEMPLATE_SUCCESS",
  UPDATE_WEEKLY_PLAN_TEMPLATE_FAILURE: "UPDATE_WEEKLY_PLAN_TEMPLATE_FAILURE",

  GET_SERVICE_KIKAN_MASTER_BY_ID_REQUEST:
    "GET_SERVICE_KIKAN_MASTER_BY_ID_REQUEST",
  GET_SERVICE_KIKAN_MASTER_BY_ID_SUCCESS:
    "GET_SERVICE_KIKAN_MASTER_BY_ID_SUCCESS",
  GET_SERVICE_KIKAN_MASTER_BY_ID_FAILURE:
    "GET_SERVICE_KIKAN_MASTER_BY_ID_FAILURE",

  GET_LIST_WEEKLY_PLAN_QUOTE_TAB_REQUEST:
    "GET_LIST_WEEKLY_PLAN_QUOTE_TAB_REQUEST",
  GET_LIST_WEEKLY_PLAN_QUOTE_TAB_SUCCESS:
    "GET_LIST_WEEKLY_PLAN_QUOTE_TAB_SUCCESS",
  GET_LIST_WEEKLY_PLAN_QUOTE_TAB_FAILURE:
    "GET_LIST_WEEKLY_PLAN_QUOTE_TAB_FAILURE",

  KEEP_VALUE_BEFORE_SORT_WEEKLY_PLAN_SCHEDULE:
    "KEEP_VALUE_BEFORE_SORT_WEEKLY_PLAN_SCHEDULE",
  GET_IDS_WEEKLY_PLAN: "GET_IDS_WEEKLY_PLAN",
  GET_IDS_SUCCESS: "GET_IDS_SUCCESS",
  GET_IDS_ERROR: "GET_IDS_ERROR",

  GET_RENTAL: "GET_RENTAL",
  GET_RENTAL_SUCCESS: "GET_RENTAL_SUCCESS",
  GET_RENTAL_ERROR: "GET_RENTAL_ERROR",

  CLEAR_WEEKLY_PLAN_SCHEDULE: "CLEAR_WEEKLY_PLAN_SCHEDULE",
  UPDATE_WEEKLY_PLAN_SCHEDULE_REQUEST: "UPDATE_WEEKLY_PLAN_SCHEDULE_REQUEST",
  UPDATE_WEEKLY_PLAN_SCHEDULE_SUCCESS: "UPDATE_WEEKLY_PLAN_SCHEDULE_SUCCESS",
  UPDATE_WEEKLY_PLAN_SCHEDULE_FAILURE: "UPDATE_WEEKLY_PLAN_SCHEDULE_FAILURE",

  GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_REQUEST:
    "GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_REQUEST",
  GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_SUCCESS:
    "GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_SUCCESS",
  GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_FAILURE:
    "GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_FAILURE",

  GET_ALL_SERVICE_CONTENT_RENTAL_REQUEST:
    "GET_ALL_SERVICE_CONTENT_RENTAL_REQUEST",
  GET_ALL_SERVICE_CONTENT_RENTAL_SUCCESS:
    "GET_ALL_SERVICE_CONTENT_RENTAL_SUCCESS",
  GET_ALL_SERVICE_CONTENT_RENTAL_FAILURE:
    "GET_ALL_SERVICE_CONTENT_RENTAL_FAILURE",

  GET_LIST_RENTAL_SYOHIN_MASTER_REQUEST:
    "GET_LIST_RENTAL_SYOHIN_MASTER_REQUEST",
  GET_LIST_RENTAL_SYOHIN_MASTER_SUCCESS:
    "GET_LIST_RENTAL_SYOHIN_MASTER_SUCCESS",
  GET_LIST_RENTAL_SYOHIN_MASTER_FAILURE:
    "GET_LIST_RENTAL_SYOHIN_MASTER_FAILURE",

  GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_REQUEST:
    "GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_REQUEST",
  GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_SUCCESS:
    "GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_SUCCESS",
  GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_FAILURE:
    "GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_FAILURE",

  UPDATE_WEEKLY_RENTAL_REQUEST: "UPDATE_WEEKLY_RENTAL_REQUEST",
  UPDATE_WEEKLY_RENTAL_SUCCESS: "UPDATE_WEEKLY_RENTAL_SUCCESS",
  UPDATE_WEEKLY_RENTAL_FAILURE: "UPDATE_WEEKLY_RENTAL_FAILURE",

  GET_RENTAL_SYOHIN_MASTER_REQUEST: "GET_RENTAL_SYOHIN_MASTER_REQUEST",
  GET_RENTAL_SYOHIN_MASTER_SUCCESS: "GET_RENTAL_SYOHIN_MASTER_SUCCESS",
  GET_RENTAL_SYOHIN_MASTER_FAILURE: "GET_RENTAL_SYOHIN_MASTER_FAILURE",

  CREATE_RENTAL_SYOHIN_MASTER_REQUEST: "CREATE_RENTAL_SYOHIN_MASTER_REQUEST",
  CREATE_RENTAL_SYOHIN_MASTER_SUCCESS: "CREATE_RENTAL_SYOHIN_MASTER_SUCCESS",
  CREATE_RENTAL_SYOHIN_MASTER_FAILURE: "CREATE_RENTAL_SYOHIN_MASTER_FAILURE",

  UPDATE_RENTAL_SYOHIN_MASTER_REQUEST: "UPDATE_RENTAL_SYOHIN_MASTER_REQUEST",
  UPDATE_RENTAL_SYOHIN_MASTER_SUCCESS: "UPDATE_RENTAL_SYOHIN_MASTER_SUCCESS",
  UPDATE_RENTAL_SYOHIN_MASTER_FAILURE: "UPDATE_RENTAL_SYOHIN_MASTER_FAILURE",
  UPDATE_RENTAL_SYOHIN_LIST: "UPDATE_RENTAL_SYOHIN_LIST",

  GET_RENTAL_SYOHIN_MASTER_INIT_REQUEST: "UPDATE_RENTAL_SYOHIN_MASTER_REQUEST",
  GET_RENTAL_SYOHIN_MASTER_INIT_SUCCESS:
    "GET_RENTAL_SYOHIN_MASTER_INIT_SUCCESS",
  GET_RENTAL_SYOHIN_MASTER_INIT_FAILURE:
    "GET_RENTAL_SYOHIN_MASTER_INIT_FAILURE",

  TURN_OFF_RELOAD: "TURN_OFF_RELOAD",

  GET_KYOTAKU_WEEKLY_LIST_REQUEST: "GET_KYOTAKU_WEEKLY_LIST_REQUEST",
  GET_KYOTAKU_WEEKLY_LIST_SUCCESS: "GET_KYOTAKU_WEEKLY_LIST_SUCCESS",
  GET_KYOTAKU_WEEKLY_LIST_FAILURE: "GET_KYOTAKU_WEEKLY_LIST_FAILURE",
};
