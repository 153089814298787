import React, { Component } from 'react'
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Button, Row } from 'antd';
import _, { add } from "lodash";
import InputNo from "./InputNo";
import DoctorMasterModal from "../../../common/component/control/DoctorMasterModal";

class FormDocotrNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleDoctor: false,
      add: 0,
    };
  }

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  openDoctorMaster = () => {
    this.setState({
      visibleDoctor: true,
    });
  };
  onCancelDoctorMaster = () => {
    this.setState({
      visibleDoctor: false,
    });
  };
  onSubmitDoctorId = (values) => {
    //valueは選択したレコード
    if (values) {
      const value = {};
      value["doctorId"] = values.id;
      value["doctorName"] = values.name;
      value["hospital"] = values.hospital;
      this.props.form.setFieldsValue(value); //フォームに取得したデータを入れる
    }
  };
  deleteDoctorMaster = () => {
    const value = {};
    value["doctorId"] = 0;
    value["doctorName"] = "";
    value["hospital"] = "";
    this.props.form.setFieldsValue(value);
  };

  render() {
    const { visibleDoctor } = this.state;
    const { getFieldDecorator } = this.props.form;

    // console.log(this.props)

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <div id="input-doctor-page">
      <Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        <Row style={{ marginTop: -5 }}>
          <Form.Item style={{ display: "none" }}>
            {getFieldDecorator("doctorId", {
              rules: [{ required: false }],
              value: 0,
            })(<InputNo />)}
          </Form.Item>
          <Form.Item label={"主治医"} colon={false}>
            {getFieldDecorator("doctorName")(
              <Input
                disabled
                style={{
                  background: "white",
                  color: "rgba(0, 0, 0, 0.65)",
                  width: 365,
                }}
              />
            )}
            <Button
              type="primary"
              onClick={this.openDoctorMaster}
              style={{ marginTop: -4, marginLeft: 6 }}
            >
              マスタ
            </Button>
            <Button
              // type="primary"
              onClick={this.deleteDoctorMaster}
              style={{
                marginTop: -4,
                marginLeft: 4,
                background: "#777",
                color: "#fff",
              }}
            >
              消去
            </Button>
            {getFieldDecorator("hospital")(
              <Input
                disabled
                style={{
                  background: "white",
                  color: "rgba(0, 0, 0, 0.65)",
                  width: 365,
                }}
              />
            )}
          </Form.Item>
        </Row>
        <DoctorMasterModal
          visible={visibleDoctor}
          onHide={this.onCancelDoctorMaster}
          onSelect={this.onSubmitDoctorId}
        />
      </Form>
      </div>
    );
  }
}

export const FormDoctor = Form.create({
  name: "input-doctor-form",
  onValuesChange(props, changedValues, allValues) {
    // @TODO convert checkbox group to value
    props.saveOnchangeToState(allValues, "fDoctor", "");
  },
  mapPropsToFields(props) {
    return {
      doctorId: Form.createFormField({
        value:
          (props.data && props.data.doctorId) || props.data.doctorId == 0
            ? props.data.doctorId
            : props.data && props.data.doctorMaster
            ? props.data.doctorMaster.id
            : null, //マスタの選択がなければdoctorMasterの情報
      }),
      doctorName: Form.createFormField({
        value:
          (props.data && props.data.doctorName) || props.data.doctorName == ""
            ? props.data.doctorName
            : props.data && props.data.doctorMaster
            ? props.data.doctorMaster.name
            : null,
      }),
      hospital: Form.createFormField({
        value:
          (props.data && props.data.hospital) || props.data.hospital == ""
            ? props.data.hospital
            : props.data && props.data.doctorMaster
            ? props.data.doctorMaster.hospital
            : null,
      }),
    };
  },
})(FormDocotrNormal);
