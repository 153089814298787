import { ApiPaths } from "../../constants";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils";
import { DocumentActionTypes, ErrorActionTypes } from "../action_types";

function getAll(params) {
  return async (dispatch) => {
    try {
      dispatch({ type: DocumentActionTypes.GET_ALL_DOCUMENT_REQUEST });
      const menu = StorageUtils.getValue("menuMNGT");
      const si = menu.si || null;
      const query = {
        sid: si,
        inUsed: params.inUsed ? 1 : 0,
        searchName: params.searchName,
        tantoId: params.tantoId,
        date: params.date ? params.date.format("YYYYMM") : null,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(params.sort)),
        page: params.page,
      };
      const { data, meta } = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.DocumentList.GetAllDocumentList,
        query,
      });

      dispatch({
        type: DocumentActionTypes.GET_ALL_DOCUMENT_SUCCESS,
        data,
        meta,
      });
    }
    catch (error) {
      dispatch({ type: DocumentActionTypes.GET_ALL_DOCUMENT_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function keepValueSearch(params) {
  return (dispatch) => {
    dispatch({
      type: DocumentActionTypes.KEEP_VALUE_SEARCH,
      keepValue: params,
    });
  };
}

export const DocumentActions = {
  getAll,
  keepValueSearch,
};
