import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import { notification, Row, Button, Checkbox } from "antd";

import WrappedDengonForm from "./UI/DengonForm";
import ActionsRow from "./UI/ActionsRow";
import { getPath } from "../../common/function_common/functionCommon";

class KanriDengonDataSave extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      dengonId: parseInt(match.params.dengonId),
      currentPage: props.currentDengon,
      pageSize: props.pageSizeDengon, // @TODO setting in file,
      ids: props.dengonSelectedRows,
      path: getPath(),
    };

    this.registerDengon = this.registerDengon.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    // load data
    const { ids, dengonId } = this.state;

    this.props.getDengonSingleRecord(dengonId, ids, null);
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent, kanriDengon } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }

    if (kanriDengon != oldProps.kanriDengon) {
      if (kanriDengon && kanriDengon.id) {
        this.setState({
          dengonId: kanriDengon.id,
        });

        this.props.history.replace(
          this.state.path + "/kanri/dengondata/" + kanriDengon.id
        );
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  async registerDengon(values) {
    if (values.id) {
      await this.props.updateDengon(values);
    } else {
      await this.props.createDengon(values);
    }
    this.props.history.goBack();
  }

  async delete(id) {
    await this.props.deleteDengon(id.toString());

    this.props.history.goBack();
  }

  preToEnd = () => {
    const { ids, dengonId } = this.state;
    this.props.getDengonSingleRecord(dengonId, ids, "fastBack");
  };

  pre = () => {
    const { ids, dengonId } = this.state;
    this.props.getDengonSingleRecord(dengonId, ids, "stepBack");
  };

  next = () => {
    const { ids, dengonId } = this.state;
    this.props.getDengonSingleRecord(dengonId, ids, "stepForwad");
  };

  nextToEnd = () => {
    // const { kanriDengonList } = this.props;
    // const { dengonId } = this.state;

    // if (kanriDengonList && kanriDengonList.length > 0) {
    //     let index = kanriDengonList.findIndex((item) => item.id === dengonId);

    //     if (index !== kanriDengonList.length) {

    //         this.setState({
    //             dengonId: kanriDengonList[kanriDengonList.length - 1].id
    //         })

    //         this.props.history.replace("/kanri/dengondata/" + kanriDengonList[kanriDengonList.length - 1].id);
    //     }
    // }
    const { ids, dengonId } = this.state;
    this.props.getDengonSingleRecord(dengonId, ids, "fastForwad");
  };

  recordMark = (type, id) => {
    this.props.recordMark("dengon", type, id);
  };

  render() {
    const { kanriDengon, dengonSelectedRows } = this.props;
    const { dengonId } = this.state;

    return (
      <KanriLayoutContainer router="dengon" breadcrumbLink={"/kanri/dengondata"} breadcrumbText={"伝言データ"}>
        <ActionsRow
          key={dengonSelectedRows}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          selectedList={dengonSelectedRows}
          id={dengonId}
        />

        <Row>
          <WrappedDengonForm
            data={kanriDengon}
            history={this.props.history}
            regData={(values) => this.registerDengon(values)}
            delete={this.delete}
          />
        </Row>
      </KanriLayoutContainer>
    );
  }
}

export default KanriDengonDataSave;
