import { WarningOutlined } from "@ant-design/icons";
import { Layout, Row, Spin } from "antd";
import jwtDecode from "jwt-decode";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import LoginFrom from "./UI/LoginForm";

import "./Account.css";
import CodeForm from "./UI/CodeForm";
import Logo from "./media/logo.png";

class Account extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      uid: "",
      password: "",
      data: null // two fa data
    };

    this.getHandleSubmit = this.getHandleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getMaintainanceMode();
  }

  /**
   *
   * @param {*} uid
   * @param {*} password
   */
  getHandleSubmit = (uid, password) => {
    this.setState({
      uid,
      password
    });

    this.props.dataLogin(uid, password, "", "", (data) => {
      this.setState({
        data
      });
    });
  }

  getHandleCodeSubmit = (code, isCheck) => {
    const { uid, password } = this.state;

    this.props.dataLogin(uid, password, code, isCheck, (data) => {
      this.setState({
        data
      });
    });
  }

  /**
   *
   */
  render() {
    const { returnData, mode, systemTitle, loadMode } = this.props;
    let errorLogin = "";
    const { data } = this.state;

    if (returnData) {
      if (returnData.response) {
        if (returnData.response.data) {
          if (returnData.response.data.error) {
            if (returnData.response.data.error.code) {
              errorLogin = returnData.response.data.error.code;
            }
          }
        }
      }
    }

    const token = localStorage.getItem("jwtToken");
    const decodeT = token ? jwtDecode(token) : null;

    if (!token) {
      return (
        <div>
          <Link to={"/logout"}>
            <div style={{ textAlign: "right" }}>
              <span>
                <WarningOutlined />
              </span>
            </div>
          </Link>
          {
            data && data.isCodePage
              ?
              <Layout id={"code-form"} style={{ height: "100vh" }}>
                <div></div>
                <Row className={"lg-form"}>
                  <div className={"lg-f-header"}>
                    <img src={Logo} alt="Logo" />
                    <h2>認証コードを入力</h2>
                    <p style={{ marginBottom: 24 }}>スマートフォンの認証アプリを立ち上げ、
                      6桁のコードを入力してください</p>

                  </div>
                  <Spin tip="読み込み中" spinning={loadMode}>
                    <CodeForm
                      errorLogin={errorLogin}
                      handleSubmit={(code, isCheck) =>
                        this.getHandleCodeSubmit(code, isCheck)
                      }
                      data={data}
                    />
                  </Spin>
                </Row>
              </Layout>
              :
              <Layout id={"login-form"} style={{ height: "100vh" }}>
                <div></div>
                <Row className={"lg-form"}>
                  <div className={"lg-f-header"}>
                    <img src={Logo} alt="Logo" />
                    <h1> {systemTitle ? systemTitle : "施設記録"}</h1>
                  </div>
                  <Spin tip="読み込み中" spinning={loadMode}>
                    <LoginFrom
                      errorLogin={errorLogin}
                      handleSubmit={(uid, password) =>
                        this.getHandleSubmit(uid, password)
                      }
                    />
                  </Spin>
                  {mode && mode.length > 0 ? (
                    <div className="lg-mes-n">
                      <h2
                        className={"lg-mes"}
                        dangerouslySetInnerHTML={{ __html: mode }}
                      />
                    </div>
                  ) : null}
                </Row>
              </Layout>
          }
        </div>
      );
    }
    // Check Supper Admin user mode. Redirect to Admin mode
    if (decodeT && decodeT.userInfo && decodeT.userInfo.name === "kanri") {
      return <Redirect to={{ pathname: "/kanri/dashboard" }} />;
    }
    return (
      // pathname: "/login", state: { from: props.location }
      <Redirect to={{ pathname: "/dengon" }} />
    );

  }
}

export default Account;
