import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";
import { checkLogin } from "../common/function_common/functionCommon";

let MessageListApi = {
  messageList(tid, pageNo) {
    return axios(
      apiUrls.INIT + apiUrls.GET_MESSAGE_LIST + "/" + tid + "?pageNo=" + pageNo,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          //window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
};

let MessageAddApi = {
  messageAdd(jsonMessage, tid) {
    return axios(apiUrls.INIT + apiUrls.GET_MESSAGE_ADD, {
      method: "POST",
      data: {
        message: jsonMessage,
        tid: tid,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          //window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
};

let MessageMitayoApi = {
  messageMitayo(messageId) {
    return axios(apiUrls.INIT + apiUrls.UPDATE_MESSAGE_READF, {
      method: "PUT",
      data: {
        messageId: messageId,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
};

let MessageLoadMoreApi = {
  messageLoadMore(tid, pageNo) {
    return axios(
      apiUrls.INIT + apiUrls.GET_MESSAGE_LIST + "/" + tid + "?pageNo=" + pageNo,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          //window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
};

export { MessageListApi, MessageAddApi, MessageMitayoApi, MessageLoadMoreApi };
