/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Divider, Flex, Input, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Component } from "react";
import DoctorMasterModal from "../../../common/component/control/DoctorMasterModal";
import MediSyoubyoumeiModal from "../../../common/component/control/MediSyoubyoumeiModal";
import { ConstSet } from "../../../common/configs/constset";
import TextAreaSelect from "./TextAreaSelect";
import TextInput from "./TextInput";

const { Option } = Select;

class FormByoukiNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
      visibleMedi: false,
      openDate: false,
      visibleDoctor: false,
    };
  }

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouByoureki",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleSort = (type, sortType, index) => {
    this.props.handleSort(type, sortType, index);
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  searchByomeiMaster = (bunrui, name) => {
    this.props.searchByomeiMaster(bunrui, name);
  };

  /**
   * Update kurita syoubyoumei & doctor
   *
   */
  onCancelMedi = () => {
    // 傷病名モーダル
    this.setState({
      visibleMedi: false,
    });
  };
  onSearchMedi = () => {
    this.setState({
      visibleMedi: true,
    });
  };
  onSubmitMedi = (mainMedicd, mainSyoubyoumei) => {
    this.props.form.setFieldsValue({
      ["syoubyoumeiCd"]: mainMedicd,
      ["syoubyoumei"]: mainSyoubyoumei,
    });

    this.onCancelMedi();
  };
  onChangeCodeMedi = (value, field) => {
    if (value && value.length == 7) {
      this.props.getMediSyoubyoumeiByCode(value, (data) => {
        if (data) {
          this.props.form.setFieldsValue({
            [`${field}`]: data.syoubyoumei,
          });
        }
      });
    }
    else {
      this.props.form.setFieldsValue({
        [`${field}`]: null,
      });
    }
  };

  onOpenChange = (open) => {
    this.setState({
      openDate: open,
    });
  };
  onCloseChange = () => {
    // close Date = null
    this.setState({
      openDate: false,
    });
    this.props.form.setFieldsValue({
      ["symptomsAppearanceDate"]: null,
    });
  };

  openDoctorMaster = () => {
    this.setState({
      visibleDoctor: true,
    });
  };
  onCancelDoctorMaster = () => {
    this.setState({
      visibleDoctor: false,
    });
  };
  onSubmitDoctorId = (values) => {
    // valueは選択したレコード
    if (values) {
      const value = {};
      value["riyouDoctor"] = values.name;
      value["hospital"] = values.hospital;
      this.props.form.setFieldsValue(value); // フォームに取得したデータを入れる
    }
  };
  deleteDoctorMaster = () => {
    const value = {};
    value["riyouDoctor"] = "";
    value["hospital"] = "";
    this.props.form.setFieldsValue(value);
  };

  render() {
    const { visible, visibleDoctor } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { index, byomeiMaster, ymByoumeiBunrui } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {/* {
                    index && index >= 0
                        ?
                        <Row style={{ padding: "8px 0px" }}>
                            <Divider /><Divider /><Divider />
                        </Row>
                        :
                        null
                } */}
        <Row style={{ padding: "8px 0px" }}>
          <Divider />
          <Divider />
          <Divider />
        </Row>
        <Row className="title-inline" style={{ marginTop: 0 }}>
          <Col span={12}>
            <h3 style={{ background: "#d9d9d9", padding: "4px 6px" }}>{`病名 ${index + 1}`}</h3>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.showDeleteConfirm}
            >
              削除
            </Button>
          </Col>
          <Col span={12} className="title-inline-right">
            <h3>順序</h3>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.handleSort.bind(this, "riyouByoureki", "up", index)}
            >
              上へ <CaretUpOutlined />
            </Button>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.handleSort.bind(
                this,
                "riyouByoureki",
                "down",
                index
              )}
            >
              下へ <CaretDownOutlined />
            </Button>
          </Col>
        </Row>
        <Divider />
        <Divider />
        <Form.Item label={"病名"} colon={false}>
          {getFieldDecorator("byoumei", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={true}
              isSelect={false}
              rowSet={1}
              dataMaster={byomeiMaster}
              search={this.searchByomeiMaster}
              dataMasterType={"byoumei"}
              ymByoumeiBunrui={ymByoumeiBunrui}
              yougoMasterUpdate={this.props.yougoMasterUpdate}
            />
          )}
        </Form.Item>
        <Flex style={{width:'100%'}}>
          <Form.Item label={"メディカル傷病名"} colon={false} style={{display: 'flex', width:'100%'}}>
            {getFieldDecorator("syoubyoumeiCd")(
              <TextInput
                rows={1}
                element={"syoubyoumeiCd"}
                style={{ width: 100 }}
                onChange={(value) =>
                  this.onChangeCodeMedi(value, "syoubyoumei")
                }
              />
            )}
            {getFieldDecorator("syoubyoumei")(
              <Input
                style={{
                  background: "white",
                  color: "rgba(0, 0, 0, 0.65)",
                  width: 335,
                }}
                element={"syoubyoumei"}
                disabled
              />
            )}
            <Button
              type="primary"
              onClick={this.onSearchMedi}
              style={{ marginLeft: 6 }}
            >
              マスタ
            </Button>
          </Form.Item>
        </Flex>
        <Row>
          <Col span={7}>
            <Form.Item label={"発症日"} colon={false}>
              {getFieldDecorator("symptomsAppearanceDate")(
                <DatePicker
                  open={this.state.openDate}
                  style={{ width: "120px" }}
                  onOpenChange={this.onOpenChange}
                  popupClassName="date-picker-new-option"
                  renderExtraFooter={() => (
                    <Row>
                      <div>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={this.onCloseChange}
                        >
                          クリア
                        </span>
                      </div>
                    </Row>
                  )}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label={"日付"} labelAlign={"right"} colon={false}>
              {getFieldDecorator("dateMemo", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<TextInput />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={"経過"} labelAlign={"right"} colon={false}>
              {getFieldDecorator("keika")(
                <Select style={{ width: "120px", marginLeft: -3 }}>
                  <Option value={"治療中"}>治療中</Option>
                  <Option value={"経観中"}>経観中</Option>
                  <Option value={"その他"}>その他</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row className="dis-inline-form-item" style={{flexFlow: 'nowrap'}}>
          <Form.Item className="w-100">
            {getFieldDecorator("mainf", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group options={[{ label: "主病名", value: "mainf" }]} />
            )}
          </Form.Item>
          <Form.Item className="w-100">
            {getFieldDecorator("kansenf", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "感染症", value: "kansenf" }]}
              />
            )}
          </Form.Item>
          <Form.Item className="w-100">
            {getFieldDecorator("tokuteif", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "特定診療", value: "tokuteif" }]}
              />
            )}
          </Form.Item>
          <Form.Item className="w-100">
            {getFieldDecorator("ryoyoSyokuf", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "療養食", value: "ryoyoSyokuf" }]}
              />
            )}
          </Form.Item>
          <Form.Item className="w-100">
            {getFieldDecorator("kangoSijif", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "指示書", value: "kangoSijif" }]}
              />
            )}
          </Form.Item>
        </Row>
        <Divider />
        <Row>
          <Form.Item label={"主治医"} colon={false} className="w-100">
            {getFieldDecorator("riyouDoctor")(
              <Input
                disabled
                style={{
                  background: "white",
                  color: "rgba(0, 0, 0, 0.65)",
                  width: 365,
                }}
              />
            )}
            <Button
              type="primary"
              onClick={this.openDoctorMaster}
              style={{ marginTop: -4, marginLeft: 6 }}
            >
              マスタ
            </Button>
            {/* <Button onClick={this.deleteDoctorMaster} style={{ marginTop: -4, marginLeft: 4, background: "#777", color: "#fff" }}>
                                消去
                            </Button> */}
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label={"病医院名"} colon={false} className="w-100">
            {getFieldDecorator("hospital")(
              <Input
                disabled
                style={{
                  background: "white",
                  color: "rgba(0, 0, 0, 0.65)",
                  width: 365,
                }}
              />
            )}
          </Form.Item>
        </Row>
        <Divider />
        <Row style={{}}>
          <Form.Item label={"備考"} colon={false} className="w-100">
            {getFieldDecorator("bikou", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(<TextAreaSelect isMaster={false} isSelect={false} rowSet={2} />)}
          </Form.Item>
        </Row>
        <Divider />
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
        <MediSyoubyoumeiModal
          visible={this.state.visibleMedi}
          mediText={""}
          onCancel={this.onCancelMedi}
          onSearch={this.onSearchMedi}
          onSubmit={this.onSubmitMedi}
        />
        <DoctorMasterModal
          visible={visibleDoctor}
          onHide={this.onCancelDoctorMaster} // 隠す
          onSelect={this.onSubmitDoctorId} // 保存
        />
      </Form>)
    );
  }
}

export const FormByouki = Form.create({
  name: "input-riyou-byouki",
  onValuesChange(props, changedValues, allValues) {
    // Convert checkbox group to value
    const tmp = _.cloneDeep(allValues);
    if (allValues.mainf && allValues.mainf.length > 0) {
      tmp.mainf = 1;
    }

    if (allValues.kansenf && allValues.kansenf.length > 0) {
      tmp.kansenf = 1;
    }

    if (allValues.tokuteif && allValues.tokuteif.length > 0) {
      tmp.tokuteif = 1;
    }

    if (allValues.ryoyoSyokuf && allValues.ryoyoSyokuf.length > 0) {
      tmp.ryoyoSyokuf = 1;
    }

    if (allValues.kangoSijif && allValues.kangoSijif.length > 0) {
      tmp.kangoSijif = 1;
    }

    if (allValues.symptomsAppearanceDate) {
      tmp.symptomsAppearanceDate = allValues.symptomsAppearanceDate.format(
        ConstSet.DATE_FORMAT
      );
    }

    props.saveOnchangeToState(tmp, "fByouki", props.index);
  },
  mapPropsToFields(props) {
    let dateTime = new Date();
    if (
      props.data &&
      props.data.symptomsAppearanceDate &&
      props.data.symptomsAppearanceDate !== "0001-01-01"
    ) {
      dateTime = new Date(props.data.symptomsAppearanceDate);
    }

    return {
      byoumei: Form.createFormField({
        value: props.data ? props.data.byoumei : null,
      }),
      mainf: Form.createFormField({
        value: props.data && props.data.mainf === 1 ? ["mainf"] : [],
      }),
      kansenf: Form.createFormField({
        value: props.data && props.data.kansenf === 1 ? ["kansenf"] : [],
      }),
      tokuteif: Form.createFormField({
        value: props.data && props.data.tokuteif === 1 ? ["tokuteif"] : [],
      }),
      ryoyoSyokuf: Form.createFormField({
        value:
          props.data && props.data.ryoyoSyokuf === 1 ? ["ryoyoSyokuf"] : [],
      }),
      kangoSijif: Form.createFormField({
        value: props.data && props.data.kangoSijif === 1 ? ["kangoSijif"] : [],
      }),
      bikou: Form.createFormField({
        value: props.data ? props.data.bikou : null,
      }),
      syoubyoumeiCd: Form.createFormField({
        value:
          props.data && props.data.syoubyoumeiCd
            ? props.data.syoubyoumeiCd
            : null,
      }),
      syoubyoumei: Form.createFormField({
        value:
          props.data && props.data.syoubyoumei ? props.data.syoubyoumei : null,
      }),
      symptomsAppearanceDate: Form.createFormField({
        value:
          props.data &&
            props.data.symptomsAppearanceDate &&
            props.data.symptomsAppearanceDate !== "0001-01-01"
            ? dayjs(dateTime)
            : null,
      }),
      riyouDoctor: Form.createFormField({
        value:
          (props.data && props.data.riyouDoctor) || props.data.riyouDoctor == ""
            ? props.data.riyouDoctor
            : props.data && props.data.doctorMaster
              ? props.data.doctorMaster.name
              : null,
      }),
      hospital: Form.createFormField({
        value:
          (props.data && props.data.hospital) || props.data.hospital == ""
            ? props.data.hospital
            : props.data && props.data.doctorMaster
              ? props.data.doctorMaster.hospital
              : null,
      }),
      dateMemo: Form.createFormField({
        value: props.data && props.data.dateMemo ? props.data.dateMemo : null,
      }),
      keika: Form.createFormField({
        value: props.data && props.data.keika ? props.data.keika : null,
      }),
    };
  },
})(FormByoukiNormal);
