export const LOAD_TANTO_KAIGI_SUCCESS = "LOAD_TANTO_KAIGI_SUCCESS";
export const LOAD_TANTO_KAIGI_ERROR = "LOAD_TANTO_KAIGI_ERROR";

export const DELETE_TANTO_KAIGI_SUCCESS = "DELETE_TANTO_KAIGI_SUCCESS";
export const DELETE_TANTO_KAIGI_ERROR = "DELETE_TANTO_KAIGI_ERROR";

export const LOAD_SPIN_REQUEST = "LOAD_SPIN_REQUEST";

export const LOAD_TANTO_KAIGI_BY_DATE_SUCCESS =
  "LOAD_TANTO_KAIGI_BY_DATE_SUCCESS";
export const LOAD_TANTO_KAIGI_BY_DATE_ERROR = "LOAD_TANTO_KAIGI_BY_DATE_ERROR";

export const LOAD_TANTO_KAIGI_BY_ID_SUCCESS = "LOAD_TANTO_KAIGI_BY_ID_SUCCESS";
export const LOAD_TANTO_KAIGI_BY_ID_ERROR = "LOAD_TANTO_KAIGI_BY_ID_ERROR";

export const CREATE_TANTO_KAIGI_SUCCESS = "CREATE_TANTO_KAIGI_SUCCESS";
export const CREATE_TANTO_KAIGI_ERROR = "CREATE_TANTO_KAIGI_ERROR";

export const UPDATE_TANTO_KAIGI_SUCCESS = "UPDATE_TANTO_KAIGI_SUCCESS";
export const UPDATE_TANTO_KAIGI_ERROR = "UPDATE_TANTO_KAIGI_ERROR";

export const RESET_CURRENT_TANTO_KAIGI = "RESET_CURRENT_TANTO_KAIGI";

export const LOAD_TANTO_KAIGI_SPIN = "LOAD_TANTO_KAIGI_SPIN";

export const SAVE_TANTO_KAIGI_TEMPLATE_SUCCESS =
  "SAVE_TANTO_KAIGI_TEMPLATE_SUCCESS";
export const SAVE_TANTO_KAIGI_TEMPLATE_ERROR =
  "SAVE_TANTO_KAIGI_TEMPLATE_ERROR";

export const LOAD_TANTO_KAIGI_TEMPLATE_SUCCESS =
  "LOAD_TANTO_KAIGI_TEMPLATE_SUCCESS";
export const LOAD_TANTO_KAIGI_TEMPALTE_ERROR =
  "LOAD_TANTO_KAIGI_TEMPALTE_ERROR";

export const GET_DATA_KIKAN_MATER_REQUEST = "GET_DATA_KIKAN_MATER_REQUEST";
export const GET_DATA_KIKAN_MATER_SUCCESS = "GET_DATA_KIKAN_MATER_SUCCESS";
export const GET_DATA_KIKAN_MATER_ERROR = "GET_DATA_KIKAN_MATER_ERROR";

export const GET_IDS_REQUEST = "GET_IDS_REQUEST";
export const GET_IDS_SUCCESS = "GET_IDS_SUCCESS";
export const GET_IDS_ERROR = "GET_IDS_ERROR";
export const NEW_PAGE = "NEW_PAGE";
