import React, { PureComponent } from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Col, Row, Table, Button } from "antd";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";

import { tantoMasterList2 } from "../../../actions/tantoMasterListActions/actionCreators";
import { stringToAttendanceArray } from "../../../common/function_common/tantoKaigiAttendance";
import LineBreakText from "../../../common/component/LineBreakText";

class ViewTantoKaigi extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAttendanceIndex: null,
    };
  }

  componentDidMount() {
    this.props.getTantoMasterList2();
  }

  getEvaluateTantoName(tantoMasterList, tantoId) {
    if (!tantoMasterList || !tantoMasterList.tantoMasters2) return "";

    for (const item of tantoMasterList.tantoMasters2) {
      if (item.id === tantoId) {
        return item.name;
      }
    }
    return "";
  }

  getTableColumns() {
    return [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "85%",
        render: (text) => {
          if (typeof text === "string") {
            return <LineBreakText text={text} />;
          }
          return text;
        },
      },
    ];
  }

  getTableData(title, content) {
    return [
      {
        title: title || "",
        content: content || "",
      },
    ];
  }

  setSelectedAttendanceIndex(index) {
    if (this.state.selectedAttendanceIndex !== index) {
      this.setState({ selectedAttendanceIndex: index });
    } else {
      this.setState({ selectedAttendanceIndex: null });
    }
  }

  renderTime(start, end) {
    if (start && end) {
      return `${start} ~ ${end}`;
    }
    if (start) return start;
    if (end) return end;
    return null;
  }

  renderDesktopContent(data) {
    const columnTable1 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "25%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title3",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content3",
        width: "15%",
      },
    ];
    const dataTable1 = [
      {
        title: "開催日時",
        content: (
          <span>
            <span style={{ marginRight: 10 }}>{data.kaisaiDate || ""}</span>
            <span>{this.renderTime(data.kaisaiTime, data.kaisaiEndtime)}</span>
          </span>
        ),
        title2: "作成日",
        content2: data.gijirokuDate || "",
        title3: "担当者",
        content3: data.tantoId
          ? this.getEvaluateTantoName(this.props.tantoMasterList, data.tantoId)
          : "",
      },
    ];
    const columnTable = this.getTableColumns();
    const dataTable = this.getTableData("開催場所", data.kaisaiPlace);
    const anotherTables = [
      {
        columns: columnTable,
        data: this.getTableData("検討した項目", data.koumoku),
      },
      {
        columns: columnTable,
        data: this.getTableData("検討内容", data.naiyou),
      },
      {
        columns: columnTable,
        data: this.getTableData("結論", data.keturon),
      },
      {
        columns: columnTable,
        data: this.getTableData("不参加理由", data.fusankaRiyu),
      },
      {
        columns: columnTable,
        data: this.getTableData("残された課題", data.kadai),
      },
    ];
    const columnTable8 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "10%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "10%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title3",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content3",
        width: "35%",
      },
    ];
    const dataTable8 = [
      {
        title: "開催回数",
        content: data.kaisaiCount,
        title2: "開催費用",
        content2: data.hiyou,
        title3: "費用内訳",
        content3: data.hiyouDetail || "",
      },
    ];
    const columnTable9 = columnTable;
    const columnAttendance = [
      {
        className: "",
        title: "",
        dataIndex: "affiliation",
        width: "35%",
      },
      {
        className: "",
        title: "",
        dataIndex: "type",
        width: "35%",
      },
      {
        className: "",
        title: "",
        dataIndex: "name",
        width: "30%",
      },
    ];
    const dataAttendance = data.attendance
      ? stringToAttendanceArray(data.attendance)
      : [];
    const dataTable9 = [
      {
        title: "出席者",
        content: (
          <Table
            bordered={false}
            className={"view-table view-table--10"}
            pagination={false}
            showHeader={false}
            columns={columnAttendance}
            dataSource={dataAttendance}
            rowKey="id"
          />
        ),
      },
    ];

    return (
      <div className="view-tanto-kaigi-container">
        <Table
          bordered={false}
          className={"view-table view-table--1"}
          pagination={false}
          showHeader={false}
          columns={columnTable1}
          dataSource={dataTable1}
          rowKey="id"
        />
        <Table
          bordered={false}
          className={"view-table view-table--2"}
          pagination={false}
          showHeader={false}
          columns={columnTable}
          dataSource={dataTable}
          rowKey="id"
        />
        <Table
          bordered={false}
          className={"view-table view-table--9"}
          pagination={false}
          showHeader={false}
          columns={columnTable9}
          dataSource={dataTable9}
          rowKey="id"
        />

        {anotherTables.map((item, index) => (
          <Table
            key={index}
            bordered={false}
            className={`view-table view-table--${index + 3}`}
            pagination={false}
            showHeader={false}
            columns={item.columns}
            dataSource={item.data}
            rowKey="id"
          />
        ))}
        <Table
          bordered={false}
          className={"view-table view-table--8"}
          pagination={false}
          showHeader={false}
          columns={columnTable8}
          dataSource={dataTable8}
          rowKey="id"
        />
      </div>
    );
  }

  renderMobileContent(data) {
    const attendance = stringToAttendanceArray(data.attendance);

    return (
      <div className="view-tanto-kaigi-container-m">
        {/* Buttons */}
        <Row className="view-tanto-kaigi-buttons">
          {/* Gijiroku Date */}
          <Col span={24}>
            <span className="text-bold">作成日</span>
            <span>{data.gijirokuDate || ""}</span>
          </Col>

          {/* Tanto */}
          <Col span={24}>
            <span className="text-bold">作成者</span>
            <span>
              {data.tantoId
                ? this.getEvaluateTantoName(
                    this.props.tantoMasterList,
                    data.tantoId
                  )
                : ""}
            </span>
          </Col>

          {/* Kaisai Date */}
          <Col span={24}>
            <span className="text-bold">開催日時</span>
            <span>
              <span style={{ marginRight: 10 }}>{data.kaisaiDate || ""}</span>
              <span>
                {this.renderTime(data.kaisaiTime, data.kaisaiEndtime)}
              </span>
            </span>
          </Col>

          {/* Place */}
          <Col span={24}>
            <span className="text-bold">開催場所</span>
            <span>{data.kaisaiPlace || ""}</span>
          </Col>
        </Row>

        {/* Attendance */}
        <Row className="view-tanto-kaigi-attendee">
          <Col span={24}>
            <span className="text-bold">出席者</span>
            <div>
              {!data.attendance ? (
                <div className="view-tanto-kaigi-attendee__notfound">
                  &nbsp;
                </div>
              ) : (
                attendance.map((item, index) => (
                  <div
                    key={index}
                    className={`view-tanto-kaigi-attendance
                        ${
                          this.state.selectedAttendanceIndex === index
                            ? "view-tanto-kaigi-attendance--show"
                            : ""
                        }`}
                  >
                    <div
                      className="view-tanto-kaigi-attendance__no"
                      onClick={() => this.setSelectedAttendanceIndex(index)}
                    >
                      <span className="text-bold">出席者 {index + 1}</span>
                      <Button
                        type="link"
                        icon={<LegacyIcon
                          type={
                            this.state.selectedAttendanceIndex === index
                              ? "caret-up"
                              : "caret-down"
                          } />}
                      ></Button>
                    </div>
                    <div className="view-tanto-kaigi-attendance__affiliation">
                      <span className="text-bold">所属</span>
                      <span>{item.affiliation || ""}</span>
                    </div>
                    <div className="view-tanto-kaigi-attendance__type">
                      <span className="text-bold">種別</span>
                      <span>{item.type || ""}</span>
                    </div>
                    <div className="view-tanto-kaigi-attendance__name">
                      <span className="text-bold">名前</span>
                      <span>{item.name || ""}</span>
                    </div>
                  </div>
                ))
              )}
            </div>
          </Col>
        </Row>

        {/* Textareas */}
        <Row className="view-tanto-kaigi-textareas">
          {/* koumoku */}
          <Col span={24}>
            <span className="text-bold">検討した項目</span>
            <span>{data.koumoku || ""}</span>
          </Col>

          {/* naiyou */}
          <Col span={24}>
            <span className="text-bold">検討内容</span>
            <span>{data.naiyou || ""}</span>
          </Col>

          {/* keturon */}
          <Col span={24}>
            <span className="text-bold">結論</span>
            <span>{data.keturon || ""}</span>
          </Col>

          {/* fusankaRiyu */}
          <Col span={24}>
            <span className="text-bold">不参加理由</span>
            <span>{data.fusankaRiyu || ""}</span>
          </Col>

          {/* kadai */}
          <Col span={24}>
            <span className="text-bold">残された課題</span>
            <span>{data.kadai || ""}</span>
          </Col>
        </Row>

        {/* Hiyou */}
        <Row className="view-tanto-kaigi-hiyou">
          {/* Kaisai count */}
          <Col span={12}>
            <span className="text-bold">開催回数</span>
            <span>{data.kaisaiCount}</span>
          </Col>

          {/* Hiyou */}
          <Col span={12}>
            <span className="text-bold">開催費用</span>
            <span>{data.hiyou}</span>
          </Col>

          {/* Hiyou detail*/}
          <Col span={24}>
            <span className="text-bold">費用内訳</span>
            <span>{data.hiyouDetail || ""}</span>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    if (!data) return null;

    return (
      <MediaQuery query="(min-device-width: 768px)">
        {(matches) =>
          matches
            ? this.renderDesktopContent(data)
            : this.renderMobileContent(data)
        }
      </MediaQuery>
    );
  }
}

const mapStateToProps = () => (state) => ({
  tantoMasterList: state.tantoMasterList,
});

const mapDispatchToProps = () => (dispatch) => ({
  getTantoMasterList2: () => dispatch(tantoMasterList2()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewTantoKaigi);
