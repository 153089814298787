import React from "react";
import { Modal, Table, Tooltip } from "antd";
import { connect } from "react-redux";
import { getKyotakuPlan1ToQuote } from "../../../actions/kyotakuPlan1Actions/actionCreators";
import { getKyotakuPlan2ToQuote } from "../../../actions/kyotakuPlan2Actions/actionCreators";

class LastQuoteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.visible &&
      this.props.visible !== prevProps.visible &&
      this.props.riyousyaId
    ) {
      if (this.props.tab === "1") {
        this.props.getListKyotakuPlan1(
          this.props.riyousyaId,
          this.props.kyotakuPlan1Id
        );
      } else if (this.props.tab === "2") {
        this.props.getListKyotakuPlan2(
          this.props.riyousyaId,
          this.props.kyotakuPlan2Id
        );
      }
    }
  }

  handleOk() {
    this.props.handleSelectLastQuote(this.state.selected);
  }

  handleCancel() {
    this.props.hideModalLastQuote();
  }

  handleSelectLastQuote(record) {
    this.setState({
      selected: record,
    });
  }

  render() {
    const columns = [
      {
        title: "計画作成日",
        dataIndex: "planDate",
        key: "planDate",
        width: "120px",
      },
      {
        title: "コメント",
        dataIndex: "comment",
        key: "comment",
        render: (text) => (
          <Tooltip title={text} placement="topLeft">
            <div
              style={{
                maxWidth: "314px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {text}
            </div>
          </Tooltip>
        ),
      },
    ];
    let dataSource = [];
    if (this.props.tab === "1") {
      dataSource = this.props.kyotakuPlan1s;
    }
    if (this.props.tab === "2") {
      dataSource = this.props.kyotakuPlan2s;
    }
    return (
      (<Modal
        open={this.props.visible}
        title={this.props.title}
        onOk={() => this.handleOk()}
        onCancel={() => this.handleCancel()}
        width={500}
      >
        <Table
          id={"last-quote-table"}
          bordered={true}
          size={"small"}
          columns={columns}
          pagination={{ pageSize: 10 }}
          rowKey={"id"}
          dataSource={dataSource}
          onRow={(record) => {
            return {
              onClick: () => this.handleSelectLastQuote(record),
            };
          }}
          rowClassName={(record) =>
            this.state.selected != null && record.id === this.state.selected.id
              ? "pljp-table-row-hover"
              : "pointer-row"
          }
        />
      </Modal>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kyotakuPlan1s: state.kyotakuPlan1.kyotakuPlan1s
      ? state.kyotakuPlan1.kyotakuPlan1s
      : [],
    kyotakuPlan2s: state.kyotakuPlan2.kyotakuPlan2s
      ? state.kyotakuPlan2.kyotakuPlan2s
      : [],
    sisetuDailyPlans: state.sisetuDailyPlan.sisetuDailyPlans
      ? state.sisetuDailyPlan.sisetuDailyPlans
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListKyotakuPlan1: (riyousyaId, kyotakuPlan1Id) =>
      dispatch(getKyotakuPlan1ToQuote(riyousyaId, kyotakuPlan1Id)),
    getListKyotakuPlan2: (riyousyaId, kyotakuPlan2Id) =>
      dispatch(getKyotakuPlan2ToQuote(riyousyaId, kyotakuPlan2Id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LastQuoteModal);
