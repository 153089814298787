/* eslint-disable brace-style */
import axios from "axios";

// utils
import { UrlUtils } from "./url";
import { StorageUtils } from "./storage";

import { ApiPaths } from "../constants";
import { ConfigName } from "../../config-name";

const API_URL = ""; // get from process.env.REACT_APP_API_URL if not using proxy

// params {id: 1} => link/1
// query {id: 1} => link?id=1
async function callApi({ method, path, body, params, query, headers = {} }) {
  try {
    const tokenslife = StorageUtils.getValue(ConfigName.SWLIFE_TOKEN);

    headers["Content-Type"] = "application/json";
    if (tokenslife && tokenslife.token) {
      headers["x-token"] = tokenslife.token;
    }

    const url = API_URL + UrlUtils.getUrl({ path, params, query });
    const { data } = await axios({
      method,
      url,
      data: body,
      headers,
      withCredentials: tokenslife && tokenslife.token,
    });

    // check disable system
    if (path === ApiPaths.Account.Mainainance) {
      let isSystem = data.split("///");
      if (isSystem && !isSystem[1].includes("life")) {
        window.location.replace("/life/error");
      } else {
        return isSystem[0];
      }
    }

    return data;
  } catch (error) {
    const _error = getError(error);
    throw _error;
  }
}

// TODO handle error
function getError(error) {
  const { response, code, message } = error;
  const _error = {};

  // Throw from try block
  if (code && message) {
    _error.code = code;
    _error.message = message;
  }

  // No response
  else if (!response) {
    _error.code = 2;
    _error.message = "Connection refused";
  }

  // Error from server
  else {
    _error.code = response.data.error.code;
    _error.message = response.data.error.message;
  }

  return _error;
}

export const RequestUtils = { callApi };
