import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { guid } from "../../timePicker/formatTimePicker";
import logo from "../../images/SmileWeb.png";

import { Col } from "antd";

class LeftMenu extends Component {
  render() {
    const { title, sessionTimeout } = this.props;
    return (
      <Col className={"h-left"} xs={{ span: 12 }}>
        <ul>
          <MediaQuery query="(min-device-width: 768px)">
            {(matches) => {
              if (matches) {
                return (
                  <li>
                    <ul>
                      <li className={"h-l-logo"}>
                        <img src={logo} key={guid()} alt="" />
                      </li>
                      <li className={"h=l-title"}>
                        <h2>{title}</h2>
                      </li>
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li>
                    <span>
                      <img
                        src={logo}
                        key={guid()}
                        alt=""
                        style={{ height: "20px" }}
                      />
                    </span>
                    <span>
                      <h2
                        style={{
                          fontSize: "1.3em",
                          paddingLeft: "0px",
                          marginTop: "16px",
                        }}
                      >
                        {title}
                      </h2>
                    </span>
                  </li>
                );
              }
            }}
          </MediaQuery>

          {sessionTimeout ? (
            <li style={{ color: "red" }} className={"h-l-timeout"}>
              <span>サーバと切断</span>
              <span>されました</span>
            </li>
          ) : null}
        </ul>
      </Col>
    );
  }
}

export default LeftMenu;
