import React from "react";
import { Row, Col, Divider } from "antd";

const JoutaiInfo = (props) => {
  return (
    <div>
      {props.facesheet && props.setFSOtherTitle
        ? props.setFSOtherTitle
            .toString()
            .split(";")
            .map((item, index) => {
              return (
                <Row key={index}>
                  <Col span={6}>
                    <h4>{item}</h4>
                  </Col>
                  <Col span={18}>
                    {props.facesheet[`hokaJokyo${index + 1}`]}
                  </Col>
                  <Divider />
                </Row>
              );
            })
        : null}
    </div>
  );
};

export default JoutaiInfo;
