import { Input, Modal, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import { getMediSyoubyoumei } from "../../../actions/mediSyoubyoumeiActions/actionCreators";

class MediSyoubyoumeiModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newText: "",
    };
  }

  componentDidMount() {
    this.props.getMediSyoubyoumei();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.visible && prevProps.visible != this.props.visible) {
      this.setState({
        newText: "",
      });

      this.props.getMediSyoubyoumei();
    }
  }

  handleOnchangeNew(e) {
    if (e.target.value.trim() === 0) {
      return;
    }

    this.setState({
      newText: e.target.value,
    });
  }

  select = (value1, value2) => {
    this.props.onSubmit(value1, value2);
  };

  search = () => {
    const text = this.checkText(this.state.newText);

    this.props.getMediSyoubyoumei(text);
  };

  onCancel = () => {
    this.props.onCancel();
  };

  checkText = (value) => {
    let text = value;
    if (text && text.trim().length > 0) {
      if (text.match(/^[A-Za-z0-9]*$/)) {
        text = text + "%";
      }
      else {
        text = "%" + text + "%";
      }
    }
    return text;
  };

  render() {
    const { mediSyoubyoumeiMaster } = this.props;
    const text = (
      <span>
        <Input
          onChange={(e) => this.handleOnchangeNew(e)}
          value={this.state.newText}
          style={{ width: "250px", marginRight: "16px" }}
        />
        <a onClick={this.search}>検索</a>
      </span>
    );

    const columns = [
      {
        dataIndex: "code",
        width: 150,
        render: (text, record) => {
          return <div>{record.syoubyoumeiCd}</div>;
        },
      },
      {
        dataIndex: "name",
        width: 200,
        render: (text, record) => {
          return <div>{record.syoubyoumei}</div>;
        },
      },
      {
        dataIndex: "select",
        width: 50,
        render: (text, record) => {
          return (
            <div className="editable-row-operations">
              <span>
                <a
                  onClick={() =>
                    this.select(record.syoubyoumeiCd, record.syoubyoumei)
                  }
                >
                  選択
                </a>
              </span>
            </div>
          );
        },
      },
    ];

    const content = (
      <div>
        <Table
          bordered
          columns={columns}
          dataSource={mediSyoubyoumeiMaster}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 240 }}
          showHeader={false}
          size={"small"}
          style={{ marginTop: "8px" }}
        />
      </div>
    );
    return (
      (<Modal
        destroyOnClose={true}
        open={this.props.visible}
        footer={null}
        onCancel={this.onCancel}
        title={this.props.title}
      >
        {text}
        {content}
      </Modal>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mediSyoubyoumeiMaster: state.mediSyoubyoumeiMaster.mediSyoubyoumeiMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMediSyoubyoumei: (text) => dispatch(getMediSyoubyoumei(text)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediSyoubyoumeiModal);
