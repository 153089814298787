import { SYSTEM_NAME_LIST, SYSTEM_CONTROL_LIST } from "./actionName.js";
import { SystemControlApi } from "../../api/systemControl";

/*
 * @param: username: string
 * @param: password: string
 */
function systemNameListCreator() {
  return (dispatch) => {
    SystemControlApi.getSystemNameList().then((data) =>
      dispatch({
        type: SYSTEM_NAME_LIST,
        payload: data,
      })
    );
  };
}

function systemControlListSisetuCreator(callback) {
  return (dispatch) => {
    SystemControlApi.getSystemControlSisetu().then((data) => {
      dispatch({
        type: SYSTEM_CONTROL_LIST,
        payload: data,
      });
      return callback(data);
    });
  };
}

export { systemNameListCreator, systemControlListSisetuCreator };
