import {
  KAIGOHOKEN_MASTER_SPIN_REQUEST,
  KAIGOHOKEN_MASTER_LOAD_REQUEST,
  KAIGOHOKEN_MASTER_CREATE_REQUEST,
  KAIGOHOKEN_MASTER_UPDATE_REQUEST,
  KAIGOHOKEN_MASTER_INIT_REQUEST,
  KAIGOHOKEN_MASTER_UPDATELIST_REQUEST,
  LOAD_KAIGOHOKEN_MASTER_INIT_SUCCESS,
  LOAD_KAIGOHOKEN_MASTER_INIT_ERROR,
  CREATE_KAIGOHOKEN_MASTER_SUCCESS,
  CREATE_KAIGOHOKEN_MASTER_ERROR,
  UPDATE_KAIGOHOKEN_MASTER_SUCCESS,
  UPDATE_KAIGOHOKEN_MASTER_ERROR,
  UPDATE_LIST_KAIGOHOKEN_MASTER_SUCCESS,
  LOAD_KAIGOHOKEN_MASTER_SUCCESS,
  LOAD_KAIGOHOKEN_MASTER_ERROR,
} from "./actionName";

import { kaigoHokenMasterApi } from "../../api/kaigoHokenMaster";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

//追加時,初期化
export function loadKaigoHokenMasterInit(callback) {
  return (dispatch) => {
    dispatch({
      type: KAIGOHOKEN_MASTER_INIT_REQUEST,
    });
    kaigoHokenMasterApi.init().then(
      (data) => {
        dispatch({
          type: LOAD_KAIGOHOKEN_MASTER_INIT_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KAIGOHOKEN_MASTER_INIT_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//新規追加
export function createKaigoHokenMaster(body, callback) {
  return (dispatch) => {
    dispatch({
      type: KAIGOHOKEN_MASTER_CREATE_REQUEST,
    });
    kaigoHokenMasterApi.create(body).then(
      (data) => {
        dispatch({
          type: CREATE_KAIGOHOKEN_MASTER_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_KAIGOHOKEN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//修正
export function updateKaigoHokenMaster(body, callback) {
  return (dispatch) => {
    dispatch({
      type: KAIGOHOKEN_MASTER_UPDATE_REQUEST,
    });
    kaigoHokenMasterApi.update(body).then(
      (data) => {
        dispatch({
          type: UPDATE_KAIGOHOKEN_MASTER_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KAIGOHOKEN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//並び替え
export function updateKaigoHokenMasterList(body, callback) {
  return (dispatch) => {
    dispatch({
      type: KAIGOHOKEN_MASTER_UPDATELIST_REQUEST,
    });
    kaigoHokenMasterApi.updateList(body).then(
      (data) => {
        dispatch({
          type: UPDATE_LIST_KAIGOHOKEN_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KAIGOHOKEN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

// /**
//  * 介護保険マスタ検索
//  * 利用者アクションで代用がある
//  * @param {*} name 担当者名
//  * @param {*} id serviceKikanのid
//  * @returns
//  */
// export function loadKaigoHokenMaster(name, id) {
//   return (dispatch) => {
//     dispatch({
//       type: KAIGOHOKEN_MASTER_LOAD_REQUEST,
//     })
//     kaigoHokenMasterApi.list(name, id).then(
//       (data) => {
//         dispatch({
//           type: LOAD_KAIGOHOKEN_MASTER_SUCCESS,
//           data: data,
//         })
//       },
//       (err) => {
//         checkError(err);
//         dispatch({
//           type: LOAD_KAIGOHOKEN_MASTER_ERROR,
//           error:
//             err.response.status === 400
//               ? err.response.data.error
//               : { message: err.response.statusText },
//         });
//       }
//     );
//   };
// }

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
