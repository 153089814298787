import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";

export const kaigoHokenMasterApi = {
  List(text) {
    return axios
      .get(apiUrls.KAIGOHOKEN_MASTER_LIST, {
        params: {
          text: text,
        },
      })
      .then((res) => res.data);
  },

  init() {
    return axios
      .get(apiUrls.GET_KAIGOHOKEN_MASTER_INIT)
      .then((res) => res.data);
  },

  create(data) {
    return axios
      .post(apiUrls.CREATE_KAIGOHOKEN_MASTER, data)
      .then((res) => res.data);
  },

  update(data) {
    return axios
      .put(apiUrls.UPDATE_KAIGOHOKEN_MASTER, data)
      .then((res) => res.data);
  },

  updateList(data) {
    return axios
      .put(apiUrls.UPDATE_LIST_KAIGOHOKEN_MASTER, data)
      .then((res) => res.data);
  },
};
