import { UndoOutlined } from "@ant-design/icons";
import { Affix, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { getPlansToViewByRiyousya } from "../../../actions/kyotakuPlan1Actions/actionCreators";
import { getRiyousya } from "../../../actions/riyousyaActions/actionCreators";
import { CommonPageContainer } from "../../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../../common/component/SubHeaderV2";
import "./InfoKyotaku.css";
import PlanSelect from "./PlanSelect";
import PlanSwitch from "./PlanSwitch";
import ViewDailyPlan from "./ViewDailyPlan";
import ViewPlan1 from "./ViewPlan1";
import ViewPlan2 from "./ViewPlan2";

const dataKey = "showreportlist";

class ViewDocumentList extends React.Component {
  constructor(props) {
    super(props);
    const showReportList = JSON.parse(localStorage.getItem(dataKey) || null);
    const { params } = this.props.match;
    this.state = {
      riyousyaId: params.riyousyaId,
      tabActive: showReportList.tabActive,
    };
  }

  componentDidMount() {
    this.props.getPlansToViewByRiyousya(this.state.riyousyaId);
    this.props.getRiyousyaInfo(this.state.riyousyaId);
    window.scroll(0, 0);
  }

  handleChangeTab(value) {
    this.setState({
      tabActive: value,
    });
  }

  render() {
    const { dataView } = this.props;
    const { tabActive } = this.state;
    let view = null;
    if (dataView) {
      if (tabActive === "1") {
        view = <ViewPlan1 data={dataView.kyotakuPlan1} />;
      }
      if (tabActive === "2") {
        view = <ViewPlan2 detail={dataView.kyotakuPlan2} />;
      }
      if (tabActive === "3") {
        view = <ViewDailyPlan sisetuDailyPlan={dataView.sisetuDailyPlan} />;
      }
    }
    return (
      <CommonPageContainer
        id={"view-doc-list-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  {this.renderHeader()}
                  <Row>{view}</Row>
                </div>
              );
            }
            return (
              <div>
                {this.renderMobileHeader()}
                <Row>{view}</Row>
                {this.renderMobileFooter()}
              </div>
            );
          }}
        </MediaQuery>
      </CommonPageContainer>
    );
  }

  renderHeader() {
    const { riyousya } = this.props;
    const { tabActive } = this.state;
    let tabName = "【計画書１】";
    if (tabActive === "2") tabName = "【計画書２】";
    if (tabActive === "3") tabName = "【日課表】";
    return (
      <Affix offsetTop={0}>
        <Row className={"submenu"} style={{ display: "flex" }}>
          <SubHeaderV2
            TopLeft={
              <SubHeaderTitleV2
                title={"利用者　" + (riyousya ? riyousya.name : "") + tabName}
              />
            }
            TopMiddle={
              <PlanSwitch
                style={{ textAlign: "center", margin: "5px auto" }}
                tabActive={tabActive}
                handleChangeTab={(value) => this.handleChangeTab(value)}
              />
            }
            TopRight={
              <Link
                to={"/document-list"}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.goBack();
                }}
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  padding: "0 8px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <UndoOutlined />
                </span>
                <span>{"戻る"}</span>
              </Link>
            }
          />
        </Row>
      </Affix>
    );
  }

  renderMobileHeader() {
    const { riyousya } = this.props;
    const { tabActive } = this.state;
    let tabName = "【計画書１】";
    if (tabActive === "2") tabName = "【計画書２】";
    if (tabActive === "3") tabName = "【日課表】";
    return (
      <Affix offsetTop={0}>
        <Row className={"submenu"}>
          <SubHeaderV2
            TopLeft={
              <div id={"submenu-mobile"}>
                <SubHeaderTitleV2 title={tabName} />
                <PlanSelect
                  style={{ float: "right" }}
                  tabActive={tabActive}
                  handleChangeTab={(value) => this.handleChangeTab(value)}
                />
              </div>
            }
            IncBottom={
              <span>
                <span style={{ color: "white" }}>{"利用者　"}</span>
                <span style={{ color: "white", fontWeight: "bold" }}>
                  {riyousya ? riyousya.name : ""}
                </span>
              </span>
            }
          />
        </Row>
      </Affix>
    );
  }

  renderMobileFooter() {
    return (
      <Affix offsetBottom={0}>
        <Row className={"submenu"}>
          <Link
            to={"/document-list"}
            onClick={(e) => {
              e.preventDefault();
              this.props.history.goBack();
            }}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
        </Row>
      </Affix>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataView: state.documentList.dataView,
    riyousya: state.riyousya.riyousya,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPlansToViewByRiyousya: (riyousyaId) =>
      dispatch(getPlansToViewByRiyousya(riyousyaId)),
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocumentList);
