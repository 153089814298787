import {
  LOAD_SPIN_REQUEST,
  LOAD_KEIKA_KIROKU_SPIN,
  LOAD_KEIKA_KIROKU_SUCCESS,
  LOAD_KEIKA_KIROKU_ERROR,
  DELETE_KEIKA_KIROKU_SUCCESS,
  DELETE_KEIKA_KIROKU_ERROR,
  LOAD_KEIKA_KIROKU_BY_DATE_SUCCESS,
  LOAD_KEIKA_KIROKU_BY_DATE_ERROR,
  LOAD_KEIKA_KIROKU_BY_ID_SUCCESS,
  LOAD_KEIKA_KIROKU_BY_ID_ERROR,
  CREATE_KEIKA_KIROKU_SUCCESS,
  CREATE_KEIKA_KIROKU_ERROR,
  UPDATE_KEIKA_KIROKU_SUCCESS,
  UPDATE_KEIKA_KIROKU_ERROR,
  RESET_CURRENT_KEIKA_KIROKU,
} from "./actionName";

import { KeikaKirokuApi } from "../../api/keikaKiroku";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

export function loadKeikaKiroku(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KeikaKirokuApi.loadKeikaKiroku(data).then(
      (data) => {
        dispatch({
          type: LOAD_KEIKA_KIROKU_SUCCESS,
          keikaKirokus: data.data,
          meta: data.meta,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KEIKA_KIROKU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function loadKeikaKirokubyDate(data) {
  return (dispatch) => {
    KeikaKirokuApi.loadKeikaKirokuByDate(data).then(
      (data) =>
        dispatch({
          type: LOAD_KEIKA_KIROKU_BY_DATE_SUCCESS,
          keikaKirokus: data.data,
          meta: data.meta,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KEIKA_KIROKU_BY_DATE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function deleteKeikaKiroku(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KeikaKirokuApi.deleteKeikaKiroku(id).then(
      (data) =>
        dispatch({
          type: DELETE_KEIKA_KIROKU_SUCCESS,
          deleteKeikaKiroku: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: DELETE_KEIKA_KIROKU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function loadKeikaKirokuById(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    dispatch({
      type: LOAD_KEIKA_KIROKU_SPIN,
    });
    KeikaKirokuApi.loadKeikaKirokuById(id).then(
      (data) =>
        dispatch({
          type: LOAD_KEIKA_KIROKU_BY_ID_SUCCESS,
          keikaKiroku: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KEIKA_KIROKU_BY_ID_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function createKeikaKiroku(params, riyousyaId, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_KEIKA_KIROKU_SPIN,
    });
    KeikaKirokuApi.createKeikaKiroku(params, riyousyaId).then(
      (data) => {
        dispatch({
          type: CREATE_KEIKA_KIROKU_SUCCESS,
          createdKeikaKiroku: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_KEIKA_KIROKU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function updateKeikaKiroku(params, id, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_KEIKA_KIROKU_SPIN,
    });
    KeikaKirokuApi.updateKeikaKiroku(params, id).then(
      (data) =>
        dispatch({
          type: UPDATE_KEIKA_KIROKU_SUCCESS,
          updatedKeikaKiroku: data,
          callback,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KEIKA_KIROKU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function resetCurrentKeikaKiroku() {
  return (dispatch) => {
    dispatch({
      type: RESET_CURRENT_KEIKA_KIROKU,
    });
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
