/* eslint-disable no-case-declarations */
import {
  YOUGO_MASTER_DEPART_NAIYOU,
  YOUGO_MASTER_ECT_CANCEL,
  YOUGO_MASTER_ECT_SAVE,
  YOUGO_MASTER_ECT_SELECT,
  YOUGO_MASTER_ECT_STATE,
  YOUGO_MASTER_EDIT_CANCEL,
  YOUGO_MASTER_EDIT_STATE,
  YOUGO_MASTER_JOUTAI,
  YOUGO_MASTER_KUSURI,
  // YOUGO_MASTER_NAIYOU_ALL,
  YOUGO_MASTER_NAIYOU,
  YOUGO_MASTER_NAIYOU2,
  YOUGO_MASTER_NAIYOU_BUNRUI,
  YOUGO_MASTER_NAIYOU_NEW,
  YOUGO_MASTER_RIYOU_EDIT_PAGE_LIST,
  YOUGO_MASTER_ROOM_NAIYOU,
  YOUGO_MASTER_SHOKUJISEN_PAGE_LIST,
  YOUGO_MASTER_STAFFKIND_NAIYOU,
  YOUGO_MASTER_SYURUI,
  YOUGO_MASTER_TANTO,
  YOUGO_MASTER_TANTOBUNRUI_NAIYOU,
  YOUGO_MASTER_UPDATE_NEW,
  YOUGO_MASTER_UPDATE_ROOM,
  YOUGO_MASTER_WEATHER_NAIYOU,
} from "../../actions/yougoMasterNaiyouActions/actionName";
import { stateYougoMasterName } from "../../common/function_common/functionCommon";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  shushoku: [],
  fukushoku: [],
  shokujisenbunrui: [],
  shokujisenbikou: [],

  riyousyaBunrui1: [],
  riyousyaBunrui2: [],
  riyousyaBunrui3: [],
  riyousyaBunrui4: [],
  riyousyaBunrui5: [],
  systemBunrui1: [],
  systemBunrui2: [],
  systemBunrui3: [],
  systemBunrui4: [],
  systemBunrui5: [],
  nyukinBunrui: [],
  nyukinTekiyo: [],
  kankeList: [],
  kikanNameList: [],
  hindoList: [],
  stateList: [],
  nyuinRiyuList: [],
  tainRiyuList: [],
  attnList: [],
  genkyouList: [],
  keizaiJoukyouList: [],
  kazokuJoukyouList: [],
  shintaiJoukyouList: [],
  ryoyoJokyoList: [],
  kaigoJokyoList: [],
  jukyoKankyouList: [],
  seikatuRekiList: [],
  kiorekiList: [],
  shokuRekiList: [],
  syumiEtcList: [],
  taijinKankeiList: [],
  honninSyusoList: [],
  kazokuSyusoList: [],
  honninKazokuIkoList: [],
  kankeishaYoboList: [],
  riyouServiceKoutekiList: [],
  riyouServiceHikoutekiList: [],
  sonotaList1: [],
  sonotaList2: [],
  sonotaList3: [],
  sonotaList4: [],
  sonotaList5: [],
  sonotaList6: [],
  sonotaList7: [],
  sonotaList8: [],
  sonotaList9: [],
  sonotaList10: [],

  systemSonotaList1: [],
  systemSonotaList2: [],
  systemSonotaList3: [],
  systemSonotaList4: [],
  systemSonotaList5: [],
  systemSonotaList6: [],
  systemSonotaList7: [],
  systemSonotaList8: [],
  systemSonotaList9: [],
  systemSonotaList10: [],

  ymByoumeiBunrui: [],
  ymKusurimeiBunrui: [],
  yougoData: [],
};

export default function yougoMasterNaiyouReducers(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case YOUGO_MASTER_UPDATE_NEW:
      return {
        ...state,
        [stateYougoMasterName(action.params)]:
          action.payload && action.payload.naiyou
            ? action.payload.naiyou.toString().split(";")
            : [],
      };

    case YOUGO_MASTER_RIYOU_EDIT_PAGE_LIST:
      return {
        riyousyaBunrui1:
          action.payload && action.payload.riyousyaBunrui1
            ? action.payload.riyousyaBunrui1.toString().split(";")
            : state.riyousyaBunrui1,
        riyousyaBunrui2:
          action.payload && action.payload.riyousyaBunrui2
            ? action.payload.riyousyaBunrui2.toString().split(";")
            : state.riyousyaBunrui2,
        riyousyaBunrui3:
          action.payload && action.payload.riyousyaBunrui3
            ? action.payload.riyousyaBunrui3.toString().split(";")
            : state.riyousyaBunrui3,
        riyousyaBunrui4:
          action.payload && action.payload.riyousyaBunrui4
            ? action.payload.riyousyaBunrui4.toString().split(";")
            : state.riyousyaBunrui4,
        riyousyaBunrui5:
          action.payload && action.payload.riyousyaBunrui5
            ? action.payload.riyousyaBunrui5.toString().split(";")
            : state.riyousyaBunrui5,
        systemBunrui1:
          action.payload && action.payload.systemBunrui1
            ? action.payload.systemBunrui1.toString().split(";")
            : state.systemBunrui1,
        systemBunrui2:
          action.payload && action.payload.systemBunrui2
            ? action.payload.systemBunrui2.toString().split(";")
            : state.systemBunrui2,
        systemBunrui3:
          action.payload && action.payload.systemBunrui3
            ? action.payload.systemBunrui3.toString().split(";")
            : state.systemBunrui3,
        systemBunrui4:
          action.payload && action.payload.systemBunrui4
            ? action.payload.systemBunrui4.toString().split(";")
            : state.systemBunrui4,
        systemBunrui5:
          action.payload && action.payload.systemBunrui5
            ? action.payload.systemBunrui5.toString().split(";")
            : state.systemBunrui5,
        nyukinBunrui:
          action.payload && action.payload.nyukinBunrui
            ? action.payload.nyukinBunrui.toString().split(";")
            : state.nyukinBunrui,
        nyukinTekiyo:
          action.payload && action.payload.nyukinTekiyo
            ? action.payload.nyukinTekiyo.toString().split(";")
            : state.nyukinTekiyo,
        kankeList:
          action.payload && action.payload.kankeList
            ? action.payload.kankeList.toString().split(";")
            : state.kankeList,
        kikanNameList:
          action.payload && action.payload.kikanNameList
            ? action.payload.kikanNameList.toString().split(";")
            : state.kikanNameList,
        hindoList:
          action.payload && action.payload.hindoList
            ? action.payload.hindoList.toString().split(";")
            : state.hindoList,
        stateList:
          action.payload && action.payload.stateList
            ? action.payload.stateList.toString().split(";")
            : state.stateList,
        nyuinRiyuList:
          action.payload && action.payload.nyuinRiyuList
            ? action.payload.nyuinRiyuList.toString().split(";")
            : state.nyuinRiyuList,
        tainRiyuList:
          action.payload && action.payload.tainRiyuList
            ? action.payload.tainRiyuList.toString().split(";")
            : state.tainRiyuList,
        attnList:
          action.payload && action.payload.attnList
            ? action.payload.attnList.toString().split(";")
            : state.attnList,
        genkyouList:
          action.payload && action.payload.genkyouList
            ? action.payload.genkyouList.toString().split(";")
            : state.genkyouList,
        keizaiJoukyouList:
          action.payload && action.payload.keizaiJoukyouList
            ? action.payload.keizaiJoukyouList.toString().split(";")
            : state.keizaiJoukyouList,
        kazokuJoukyouList:
          action.payload && action.payload.kazokuJoukyouList
            ? action.payload.kazokuJoukyouList.toString().split(";")
            : state.kazokuJoukyouList,
        shintaiJoukyouList:
          action.payload && action.payload.shintaiJoukyouList
            ? action.payload.shintaiJoukyouList.toString().split(";")
            : state.shintaiJoukyouList,
        ryoyoJokyoList:
          action.payload && action.payload.ryoyoJokyoList
            ? action.payload.ryoyoJokyoList.toString().split(";")
            : state.ryoyoJokyoList,
        kaigoJokyoList:
          action.payload && action.payload.kaigoJokyoList
            ? action.payload.kaigoJokyoList.toString().split(";")
            : state.kaigoJokyoList,
        jukyoKankyouList:
          action.payload && action.payload.jukyoKankyouList
            ? action.payload.jukyoKankyouList.toString().split(";")
            : state.jukyoKankyouList,
        seikatuRekiList:
          action.payload && action.payload.seikatuRekiList
            ? action.payload.seikatuRekiList.toString().split(";")
            : state.seikatuRekiList,
        kiorekiList:
          action.payload && action.payload.kiorekiList
            ? action.payload.kiorekiList.toString().split(";")
            : state.kiorekiList,
        shokuRekiList:
          action.payload && action.payload.shokuRekiList
            ? action.payload.shokuRekiList.toString().split(";")
            : state.shokuRekiList,
        syumiEtcList:
          action.payload && action.payload.syumiEtcList
            ? action.payload.syumiEtcList.toString().split(";")
            : state.syumiEtcList,
        taijinKankeiList:
          action.payload && action.payload.taijinKankeiList
            ? action.payload.taijinKankeiList.toString().split(";")
            : state.taijinKankeiList,
        honninSyusoList:
          action.payload && action.payload.honninSyusoList
            ? action.payload.honninSyusoList.toString().split(";")
            : state.honninSyusoList,
        kazokuSyusoList:
          action.payload && action.payload.kazokuSyusoList
            ? action.payload.kazokuSyusoList.toString().split(";")
            : state.kazokuSyusoList,
        honninKazokuIkoList:
          action.payload && action.payload.honninKazokuIkoList
            ? action.payload.honninKazokuIkoList.toString().split(";")
            : state.honninKazokuIkoList,
        kankeishaYoboList:
          action.payload && action.payload.kankeishaYoboList
            ? action.payload.kankeishaYoboList.toString().split(";")
            : state.kankeishaYoboList,
        riyouServiceKoutekiList:
          action.payload && action.payload.riyouServiceKoutekiList
            ? action.payload.riyouServiceKoutekiList.toString().split(";")
            : state.riyouServiceKoutekiList,
        riyouServiceHikoutekiList:
          action.payload && action.payload.riyouServiceHikoutekiList
            ? action.payload.riyouServiceHikoutekiList.toString().split(";")
            : state.riyouServiceHikoutekiList,
        sonotaList1:
          action.payload && action.payload.sonotaList1
            ? action.payload.sonotaList1.toString().split(";")
            : state.sonotaList1,
        sonotaList2:
          action.payload && action.payload.sonotaList2
            ? action.payload.sonotaList2.toString().split(";")
            : state.sonotaList2,
        sonotaList3:
          action.payload && action.payload.sonotaList3
            ? action.payload.sonotaList3.toString().split(";")
            : state.sonotaList3,
        sonotaList4:
          action.payload && action.payload.sonotaList4
            ? action.payload.sonotaList4.toString().split(";")
            : state.sonotaList4,
        sonotaList5:
          action.payload && action.payload.sonotaList5
            ? action.payload.sonotaList5.toString().split(";")
            : state.sonotaList5,
        sonotaList6:
          action.payload && action.payload.sonotaList6
            ? action.payload.sonotaList6.toString().split(";")
            : state.sonotaList6,
        sonotaList7:
          action.payload && action.payload.sonotaList7
            ? action.payload.sonotaList7.toString().split(";")
            : state.sonotaList7,
        sonotaList8:
          action.payload && action.payload.sonotaList8
            ? action.payload.sonotaList8.toString().split(";")
            : state.sonotaList8,
        sonotaList9:
          action.payload && action.payload.sonotaList9
            ? action.payload.sonotaList9.toString().split(";")
            : state.sonotaList9,
        sonotaList10:
          action.payload && action.payload.sonotaList10
            ? action.payload.sonotaList10.toString().split(";")
            : state.sonotaList10,

        systemSonotaList1:
          action.payload && action.payload.systemSonotaList1
            ? action.payload.systemSonotaList1.toString().split(";")
            : state.systemSonotaList1,
        systemSonotaList2:
          action.payload && action.payload.systemSonotaList2
            ? action.payload.systemSonotaList2.toString().split(";")
            : state.systemSonotaList2,
        systemSonotaList3:
          action.payload && action.payload.systemSonotaList3
            ? action.payload.systemSonotaList3.toString().split(";")
            : state.systemSonotaList3,
        systemSonotaList4:
          action.payload && action.payload.systemSonotaList4
            ? action.payload.systemSonotaList4.toString().split(";")
            : state.systemSonotaList4,
        systemSonotaList5:
          action.payload && action.payload.systemSonotaList5
            ? action.payload.systemSonotaList5.toString().split(";")
            : state.systemSonotaList5,
        systemSonotaList6:
          action.payload && action.payload.systemSonotaList6
            ? action.payload.systemSonotaList6.toString().split(";")
            : state.systemSonotaList6,
        systemSonotaList7:
          action.payload && action.payload.systemSonotaList7
            ? action.payload.systemSonotaList7.toString().split(";")
            : state.systemSonotaList7,
        systemSonotaList8:
          action.payload && action.payload.systemSonotaList8
            ? action.payload.systemSonotaList8.toString().split(";")
            : state.systemSonotaList8,
        systemSonotaList9:
          action.payload && action.payload.systemSonotaList9
            ? action.payload.systemSonotaList9.toString().split(";")
            : state.systemSonotaList9,
        systemSonotaList10:
          action.payload && action.payload.systemSonotaList10
            ? action.payload.systemSonotaList10.toString().split(";")
            : state.systemSonotaList10,

        joukyouList1:
          action.payload && action.payload.joukyouList1
            ? action.payload.joukyouList1.toString().split(";")
            : state.joukyouList1,
        joukyouList2:
          action.payload && action.payload.joukyouList2
            ? action.payload.joukyouList2.toString().split(";")
            : state.joukyouList2,
        joukyouList3:
          action.payload && action.payload.joukyouList3
            ? action.payload.joukyouList3.toString().split(";")
            : state.joukyouList3,
        joukyouList4:
          action.payload && action.payload.joukyouList4
            ? action.payload.joukyouList4.toString().split(";")
            : state.joukyouList4,
        joukyouList5:
          action.payload && action.payload.joukyouList5
            ? action.payload.joukyouList5.toString().split(";")
            : state.joukyouList5,
        joukyouList6:
          action.payload && action.payload.joukyouList6
            ? action.payload.joukyouList6.toString().split(";")
            : state.joukyouList6,
        joukyouList7:
          action.payload && action.payload.joukyouList7
            ? action.payload.joukyouList7.toString().split(";")
            : state.joukyouList7,
        joukyouList8:
          action.payload && action.payload.joukyouList8
            ? action.payload.joukyouList8.toString().split(";")
            : state.joukyouList8,
        joukyouList9:
          action.payload && action.payload.joukyouList9
            ? action.payload.joukyouList9.toString().split(";")
            : state.joukyouList9,
        joukyouList10:
          action.payload && action.payload.joukyouList10
            ? action.payload.joukyouList10.toString().split(";")
            : state.joukyouList10,

        ymByoumeiBunrui:
          action.payload && action.payload.ymByoumeiBunrui
            ? action.payload.ymByoumeiBunrui.toString().split(";")
            : state.ymByoumeiBunrui,
        ymKusurimeiBunrui:
          action.payload && action.payload.ymKusurimeiBunrui
            ? action.payload.ymKusurimeiBunrui.toString().split(";")
            : state.ymKusurimeiBunrui,
      };

    case YOUGO_MASTER_SHOKUJISEN_PAGE_LIST:
      return {
        shushoku:
          action.payload && action.payload.shushoku
            ? action.payload.shushoku.toString().split(";")
            : state.shushoku,
        fukushoku:
          action.payload && action.payload.fukushoku
            ? action.payload.fukushoku.toString().split(";")
            : state.fukushoku,
        shokujisenbunrui:
          action.payload && action.payload.shokujisenbunrui
            ? action.payload.shokujisenbunrui.toString().split(";")
            : state.shokujisenbunrui,
        shokujisenbikou:
          action.payload && action.payload.shokujisenbikou
            ? action.payload.shokujisenbikou.toString().split(";")
            : state.shokujisenbikou,
        shokujiRirekiSituation:
          action.payload && action.payload.shokujiRirekiSituation
            ? action.payload.shokujiRirekiSituation.toString().split(";")
            : state.shokujiRirekiSituation,
        shokujiRirekiComent:
          action.payload && action.payload.shokujiRirekiComent
            ? action.payload.shokujiRirekiComent.toString().split(";")
            : state.shokujiRirekiComent,
      };

    case YOUGO_MASTER_NAIYOU:
      if (action && action.reportType && action.reportType > 0) {
        switch (action.reportType) {
          case 1:
            const preDataVC =
              action.payload && action.payload.naiyou
                ? action.payload.naiyou.split(";")
                : "";
            const dataVC = [];
            for (let i = 0; i < preDataVC.length; i++) {
              dataVC.push({
                key: i,
                naiyou: preDataVC[i],
                editable: false,
              });
            }

            return {
              ...state,
              dataVC,
            };

          case 2:
            const preDataMC =
              action.payload && action.payload.naiyou
                ? action.payload.naiyou.split(";")
                : "";
            const dataMC = [];
            for (let i = 0; i < preDataMC.length; i++) {
              dataMC.push({
                key: i,
                naiyou: preDataMC[i],
                editable: false,
              });
            }

            return {
              ...state,
              dataMC,
            };

          case 3:
            const preDataSC =
              action.payload && action.payload.naiyou
                ? action.payload.naiyou.split(";")
                : "";
            const dataSC = [];
            for (let i = 0; i < preDataSC.length; i++) {
              dataSC.push({
                key: i,
                naiyou: preDataSC[i],
                editable: false,
              });
            }

            return {
              ...state,
              dataSC,
            };

          case 4:
            const preDataHC =
              action.payload && action.payload.naiyou
                ? action.payload.naiyou.split(";")
                : "";
            const dataHC = [];
            for (let i = 0; i < preDataHC.length; i++) {
              dataHC.push({
                key: i,
                naiyou: preDataHC[i],
                editable: false,
              });
            }

            return {
              ...state,
              dataHC,
            };

          case 5:
            const preDataNC =
              action.payload && action.payload.naiyou
                ? action.payload.naiyou.split(";")
                : "";
            const dataNC = [];
            for (let i = 0; i < preDataNC.length; i++) {
              dataNC.push({
                key: i,
                naiyou: preDataNC[i],
                editable: false,
              });
            }

            return {
              ...state,
              dataNC,
            };

          case 6:
            const preDataFC =
              action.payload && action.payload.naiyou
                ? action.payload.naiyou.split(";")
                : "";
            const dataFC = [];
            for (let i = 0; i < preDataFC.length; i++) {
              dataFC.push({
                key: i,
                naiyou: preDataFC[i],
                editable: false,
              });
            }

            return {
              ...state,
              dataFC,
            };

          case 7:
            const preDataKC =
              action.payload && action.payload.naiyou
                ? action.payload.naiyou.split(";")
                : "";
            const dataKC = [];
            for (let i = 0; i < preDataKC.length; i++) {
              dataKC.push({
                key: i,
                naiyou: preDataKC[i],
                editable: false,
              });
            }

            return {
              ...state,
              dataKC,
            };

          case 8:
            const preDataTC =
              action.payload && action.payload.naiyou
                ? action.payload.naiyou.split(";")
                : "";
            const dataTC = [];
            for (let i = 0; i < preDataTC.length; i++) {
              dataTC.push({
                key: i,
                naiyou: preDataTC[i],
                editable: false,
              });
            }

            return {
              ...state,
              dataTC,
            };

          case 9:
            const preDataGC =
              action.payload && action.payload.naiyou
                ? action.payload.naiyou.split(";")
                : "";
            const dataGC = [];
            for (let i = 0; i < preDataGC.length; i++) {
              dataGC.push({
                key: i,
                naiyou: preDataGC[i],
                editable: false,
              });
            }

            return {
              ...state,
              dataGC,
            };

          default:
            return {
              ...state,
            };
        }
      }
      else {
        const prePareData =
          action.payload && action.payload.naiyou
            ? action.payload.naiyou.split(";")
            : "";
        const dataReturn = [];
        if (prePareData) {
          for (let i = 0; i < prePareData.length; i++) {
            dataReturn.push({
              key: i,
              naiyou: prePareData[i],
              editable: false,
            });
          }
        }

        return {
          ...state,
          yougoMasterNaiyou: dataReturn,
          yougoMasterNaiyouBkp: dataReturn,
          yougoMasterNaiyouC: prePareData,
        };
      }

    case YOUGO_MASTER_NAIYOU2: {
      const prePareData =
        action.payload && action.payload.naiyou
          ? action.payload.naiyou.split(";")
          : "";
      const dataReturn = [];
      if (prePareData) {
        for (let i = 0; i < prePareData.length; i++) {
          dataReturn.push({
            key: i,
            naiyou: prePareData[i],
            editable: false,
          });
        }
      }

      return {
        ...state,
        yougoMasterNaiyou2: dataReturn,
        yougoMasterNaiyou2C: prePareData,
      };
    }

    case YOUGO_MASTER_NAIYOU_NEW:
      const yougoMaster =
        action.payload && action.payload.naiyou
          ? action.payload.naiyou.split(";")
          : "";
      const yougoData = [];
      for (let i = 0; i < yougoMaster.length; i++) {
        yougoData.push({
          key: i,
          naiyou: yougoMaster[i],
          editable: false,
        });
      }

      return {
        ...state,
        yougoData,
      };

    case YOUGO_MASTER_NAIYOU_BUNRUI:
      const prePareData =
        action.payload && action.payload.naiyou
          ? action.payload.naiyou.split(";")
          : "";
      const dataReturn = [];
      if (prePareData) {
        for (let i = 0; i < prePareData.length; i++) {
          dataReturn.push({
            key: i,
            naiyou: prePareData[i],
            editable: false,
          });
        }
      }

      return {
        ...state,
        yougoMasterNaiyouBunrui: dataReturn,
        yougoMasterBunrui: prePareData,
      };

    case YOUGO_MASTER_TANTO:
      const tanto =
        action.payload && action.payload.naiyou
          ? action.payload.naiyou.split(";")
          : "";
      return {
        ...state,
        yougoMasterTanto: tanto,
      };

    case YOUGO_MASTER_SYURUI:
      return {
        ...state,
        yougoMasterSyurui: action.payload.naiyou,
      };

    case YOUGO_MASTER_JOUTAI:
      return {
        ...state,
        yougoMasterHJ: action.payload.naiyou,
      };

    case YOUGO_MASTER_KUSURI:
      const kusuri =
        action.payload && action.payload.naiyou
          ? action.payload.naiyou.split(";")
          : "";
      return {
        ...state,
        ymKusurimeiBunrui: kusuri,
      };

    case YOUGO_MASTER_EDIT_STATE:
      if (action && action.reportType && action.reportType > 0) {
        switch (action.reportType) {
          case 1:
            return {
              ...state,
              dataVC: state.dataVC.map((item, index) => {
                if (index === action.index) {
                  item.editable = true;
                }
                else {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 2:
            return {
              ...state,
              dataMC: state.dataMC.map((item, index) => {
                if (index === action.index) {
                  item.editable = true;
                }
                else {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 3:
            return {
              ...state,
              dataSC: state.dataSC.map((item, index) => {
                if (index === action.index) {
                  item.editable = true;
                }
                else {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 4:
            return {
              ...state,
              dataHC: state.dataHC.map((item, index) => {
                if (index === action.index) {
                  item.editable = true;
                }
                else {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 5:
            return {
              ...state,
              dataNC: state.dataNC.map((item, index) => {
                if (index === action.index) {
                  item.editable = true;
                }
                else {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 6:
            return {
              ...state,
              dataFC: state.dataFC.map((item, index) => {
                if (index === action.index) {
                  item.editable = true;
                }
                else {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 7:
            return {
              ...state,
              dataKC: state.dataKC.map((item, index) => {
                if (index === action.index) {
                  item.editable = true;
                }
                else {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 8:
            return {
              ...state,
              dataTC: state.dataTC.map((item, index) => {
                if (index === action.index) {
                  item.editable = true;
                }
                else {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 9:
            return {
              ...state,
              dataGC: state.dataGC.map((item, index) => {
                if (index === action.index) {
                  item.editable = true;
                }
                else {
                  item.editable = false;
                }

                return item;
              }),
            };

          default:
            return {
              ...state,
            };
        }
      }
      else {
        // key が一致してればtrueにし、配列を返すだけ
        return {
          ...state,
          yougoMasterNaiyou: state?.yougoMasterNaiyou?.map((item, index) => {
            if (index === action.index) {
              item.editable = true;
            }
            else {
              item.editable = false;
            }

            return item;
          }),
          yougoData: state?.yougoData?.map((item, index) => {
            if (index === action.index) {
              item.editable = true;
            }
            else {
              item.editable = false;
            }
            return item;
          }),
        };
      }

    case YOUGO_MASTER_EDIT_CANCEL:
      if (action && action.reportType && action.reportType > 0) {
        switch (action.reportType) {
          case 1:
            return {
              ...state,
              dataVC: state.dataVC.map((item, index) => {
                if (index === action.index) {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 2:
            return {
              ...state,
              dataMC: state.dataMC.map((item, index) => {
                if (index === action.index) {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 3:
            return {
              ...state,
              dataSC: state.dataSC.map((item, index) => {
                if (index === action.index) {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 4:
            return {
              ...state,
              dataHC: state.dataHC.map((item, index) => {
                if (index === action.index) {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 5:
            return {
              ...state,
              dataNC: state.dataNC.map((item, index) => {
                if (index === action.index) {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 6:
            return {
              ...state,
              dataFC: state.dataFC.map((item, index) => {
                if (index === action.index) {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 7:
            return {
              ...state,
              dataKC: state.dataKC.map((item, index) => {
                if (index === action.index) {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 8:
            return {
              ...state,
              dataTC: state.dataTC.map((item, index) => {
                if (index === action.index) {
                  item.editable = false;
                }

                return item;
              }),
            };

          case 9:
            return {
              ...state,
              dataGC: state.dataGC.map((item, index) => {
                if (index === action.index) {
                  item.editable = false;
                }

                return item;
              }),
            };

          default:
            return {
              ...state,
            };
        }
      }
      else {
        return {
          ...state,
          yougoMasterNaiyou: state?.yougoMasterNaiyou?.map((item, index) => {
            if (index === action.index) {
              item.editable = false;
            }

            return item;
          }),
          yougoData: state?.yougoData?.map((item, index) => {
            if (index === action.index) {
              item.editable = false;
            }
            return item;
          }),
        };
      }

    case YOUGO_MASTER_ECT_SELECT:
      const preEct1 =
        action.payload && action.payload.ect1
          ? action.payload.ect1.split(";")
          : "";
      const dataEtc1 = [];
      for (let i = 0; i < preEct1.length; i++) {
        dataEtc1.push({
          key: i,
          naiyou: preEct1[i],
          editable: false,
        });
      }

      const preEct2 =
        action.payload && action.payload.ect2
          ? action.payload.ect2.split(";")
          : "";
      const dataEtc2 = [];
      for (let i = 0; i < preEct2.length; i++) {
        dataEtc2.push({
          key: i,
          naiyou: preEct2[i],
          editable: false,
        });
      }

      const preEct3 =
        action.payload && action.payload.ect3
          ? action.payload.ect3.split(";")
          : "";
      const dataEtc3 = [];

      for (let i = 0; i < preEct3.length; i++) {
        dataEtc3.push({
          key: i,
          naiyou: preEct3[i],
          editable: false,
        });
      }

      const vComment =
        action.payload && action.payload.vComment
          ? action.payload.vComment.split(";")
          : "";
      const dataVC = [];
      for (let i = 0; i < vComment.length; i++) {
        dataVC.push({
          key: i,
          naiyou: vComment[i],
          editable: false,
        });
      }

      const mComment =
        action.payload && action.payload.mComment
          ? action.payload.mComment.split(";")
          : "";
      const dataMC = [];
      for (let i = 0; i < mComment.length; i++) {
        dataMC.push({
          key: i,
          naiyou: mComment[i],
          editable: false,
        });
      }

      const sComment =
        action.payload && action.payload.sComment
          ? action.payload.sComment.split(";")
          : "";
      const dataSC = [];
      for (let i = 0; i < sComment.length; i++) {
        dataSC.push({
          key: i,
          naiyou: sComment[i],
          editable: false,
        });
      }

      const hComment =
        action.payload && action.payload.hComment
          ? action.payload.hComment.split(";")
          : "";
      const dataHC = [];
      for (let i = 0; i < hComment.length; i++) {
        dataHC.push({
          key: i,
          naiyou: hComment[i],
          editable: false,
        });
      }

      const nComment =
        action.payload && action.payload.nComment
          ? action.payload.nComment.split(";")
          : "";
      const dataNC = [];
      for (let i = 0; i < nComment.length; i++) {
        dataNC.push({
          key: i,
          naiyou: nComment[i],
          editable: false,
        });
      }

      const fComment =
        action.payload && action.payload.fComment
          ? action.payload.fComment.split(";")
          : "";
      const dataFC = [];
      for (let i = 0; i < fComment.length; i++) {
        dataFC.push({
          key: i,
          naiyou: fComment[i],
          editable: false,
        });
      }

      const kComment =
        action.payload && action.payload.kComment
          ? action.payload.kComment.split(";")
          : "";
      const dataKC = [];
      for (let i = 0; i < kComment.length; i++) {
        dataKC.push({
          key: i,
          naiyou: kComment[i],
          editable: false,
        });
      }

      const tComment =
        action.payload && action.payload.tComment
          ? action.payload.tComment.split(";")
          : "";
      const dataTC = [];
      for (let i = 0; i < tComment.length; i++) {
        dataTC.push({
          key: i,
          naiyou: tComment[i],
          editable: false,
        });
      }

      const gComment =
        action.payload && action.payload.gComment
          ? action.payload.gComment.split(";")
          : "";
      const dataGC = [];
      for (let i = 0; i < gComment.length; i++) {
        dataGC.push({
          key: i,
          naiyou: gComment[i],
          editable: false,
        });
      }

      const suibunType =
        action.payload && action.payload.sSyurui
          ? action.payload.sSyurui
          : null;

      const haisetuType =
        action.payload && action.payload.hSyurui
          ? action.payload.hSyurui
          : null;
      const haisetuState =
        action.payload && action.payload.hJotai ? action.payload.hJotai : null;

      const nyuyokuType =
        action.payload && action.payload.nSyurui
          ? action.payload.nSyurui
          : null;

      return {
        ...state,
        ectDataSelect1: dataEtc1,
        ectDataSelect2: dataEtc2,
        ectDataSelect3: dataEtc3,
        dataVC,
        dataMC,
        dataSC,
        dataHC,
        dataNC,
        dataFC,
        dataKC,
        dataTC,
        dataGC,
        suibunType,
        haisetuType,
        haisetuState,
        nyuyokuType,
      };

    //
    case YOUGO_MASTER_ECT_STATE:
      return {
        ...state,
        ectDataSelect3: state.ectDataSelect3.map((item, index) => {
          if (index === action.index) {
            item.editable = true;
          }
          else {
            item.editable = false;
          }

          return item;
        }),
      };

    case YOUGO_MASTER_ECT_CANCEL:
      return {
        ...state,
        ectDataSelect3: state.ectDataSelect3.map((item, index) => {
          if (index === action.index) {
            item.editable = false;
          }

          return item;
        }),
      };

    case YOUGO_MASTER_ECT_SAVE:
      const preEct =
        action.payload && action.payload.naiyou
          ? action.payload.naiyou.split(";")
          : "";
      const dataEct = [];
      for (let i = 0; i < preEct.length; i++) {
        dataEct.push({
          key: i,
          naiyou: preEct[i],
          editable: false,
        });
      }

      if (action.ectType === "1") {
        return {
          ...state,
          ectDataSelect1: dataEct,
        };
      }
      else if (action.ectType === "2") {
        return {
          ...state,
          ectDataSelect2: dataEct,
        };
      }
      else if (action.ectType === "3") {
        return {
          ...state,
          ectDataSelect3: dataEct,
        };
      } return {
        ...state,
        yougoMasterDepartNaiyou: dataReturnDepart,
        yougoMasterNaiyouBkp: dataReturnDepart,
      };

    case YOUGO_MASTER_WEATHER_NAIYOU:
      const prePareDataWeather = action.payload
        ? action.payload.naiyou.split(";")
        : "";
      const dataReturnWeather = [];
      for (let i = 0; i < prePareDataWeather.length; i++) {
        dataReturnWeather.push({
          key: i,
          naiyou: prePareDataWeather[i],
          editable: false,
        });
      }

      return {
        ...state,
        yougoMasterWeatherNaiyou: dataReturnWeather,
        yougoMasterNaiyouBkp: dataReturnWeather,
      };
    case YOUGO_MASTER_ROOM_NAIYOU:
      return {
        ...state,
        yougoMasterRoomNaiyou:
          action.payload && action.payload.naiyou
            ? action.payload.naiyou.split(";").map((v, i) => {
              return {
                key: i,
                naiyou: v,
                editable: false,
              };
            })
            : [],
      };
    case YOUGO_MASTER_UPDATE_ROOM:
      return {
        ...state,
        yougoMasterRoomNaiyou:
          action.payload && action.payload.naiyou
            ? action.payload.naiyou.split(";").map((v, i) => {
              return {
                key: i,
                naiyou: v,
                editable: false,
              };
            })
            : [],
      };

    case YOUGO_MASTER_TANTOBUNRUI_NAIYOU:
      const prePareDataTantoBunrui = action.payload
        ? action.payload.naiyou.split(";")
        : "";
      const dataReturnTantoBunrui = [];
      for (let i = 0; i < prePareDataTantoBunrui.length; i++) {
        dataReturnTantoBunrui.push({
          key: i,
          naiyou: prePareDataTantoBunrui[i],
          editable: false,
        });
      }

      return {
        ...state,
        yougoMasterTantoBunrui: dataReturnTantoBunrui,
        yougoMasterNaiyouBkp: dataReturnTantoBunrui,
      };
    case YOUGO_MASTER_STAFFKIND_NAIYOU:
      const prePareDataStaffKind = action.payload
        ? action.payload.naiyou.split(";")
        : "";
      const dataReturnStaffKind = [];
      for (let i = 0; i < prePareDataStaffKind.length; i++) {
        dataReturnStaffKind.push({
          key: i,
          naiyou: prePareDataStaffKind[i],
          editable: false,
        });
      }

      return {
        ...state,
        yougoMasterStaffKindNaiyou: dataReturnStaffKind,
        yougoMasterNaiyouBkp: dataReturnStaffKind,
      };
    case YOUGO_MASTER_DEPART_NAIYOU:
      const prePareDataDepart = action.payload
        ? action.payload.naiyou.split(";")
        : "";
      const dataReturnDepart = [];
      for (let i = 0; i < prePareDataDepart.length; i++) {
        dataReturnDepart.push({
          key: i,
          naiyou: prePareDataDepart[i],
          editable: false,
        });
      }
      return {
        ...state,
        yougoMasterDepartNaiyou: dataReturnDepart,
        yougoMasterNaiyouBkp: dataReturnDepart,
      };

    default:
      return state;
  }
}
