export const RIYOUSYA_PAGE_REQUEST = "RIYOUSYA_PAGE_REQUEST";
export const RIYOUSYA_PAGE_ERROR = "RIYOUSYA_PAGE_ERROR";

export const RIYOUSYA_LIST_SUCCESS = "RIYOUSYA_LIST_SUCCESS";
export const RIYOUSYA_LOADMORE_SUCCESS = "RIYOUSYA_LOADMORE_SUCCESS";
export const RIYOUSYA_ADD_SUCCESS = "RIYOUSYA_ADD_SUCCESS";

export const RIYOUSYA_DETAIL_PAGE_REQUEST = "RIYOUSYA_DETAIL_PAGE_REQUEST";
export const RIYOUSYA_DETAIL_PAGE_ERROR = "RIYOUSYA_DETAIL_PAGE_ERROR";
export const RIYOUSYA_DETAIL_SUCCESS = "RIYOUSYA_DETAIL_SUCCESS";

export const RIYOUSYA_SHIRYOU_PAGE_REQUEST = "RIYOUSYA_SHIRYOU_PAGE_REQUEST";
export const RIYOUSYA_SHIRYOU_PAGE_ERROR = "RIYOUSYA_SHIRYOU_PAGE_ERROR";
export const RIYOUSYA_SHIRYOU_SUCCESS = "RIYOUSYA_SHIRYOU_SUCCESS";

export const RIYOUSYA_INPUT_ADD = "RIYOUSYA_INPUT_ADD";
export const RIYOUSYA_INPUT_DELETE = "RIYOUSYA_INPUT_DELETE";
export const RIYOUSYA_INPUT_SORT = "RIYOUSYA_INPUT_SORT";

export const RIYOUSYA_UPDATE_SERVER = "RIYOUSYA_UPDATE_SERVER";

export const RIYOUSYA_MEAL_RESET = "RIYOUSYA_MEAL_RESET";

export const RIYOUSYA_MEAL_ABOVE_REQUEST = "RIYOUSYA_MEAL_ABOVE_REQUEST";
export const RIYOUSYA_MEAL_ABOVE_SUCCESS = "RIYOUSYA_MEAL_ABOVE_SUCCESS";
export const RIYOUSYA_MEAL_ABOVE_ERROR = "RIYOUSYA_MEAL_ABOVE_ERROR";

export const RIYOUSYA_MEAL_BELOW_REQUEST = "RIYOUSYA_MEAL_BELOW_REQUEST";
export const RIYOUSYA_MEAL_BELOW_SUCCESS = "RIYOUSYA_MEAL_BELOW_SUCCESS";
export const RIYOUSYA_MEAL_BELOW_ERROR = "RIYOUSYA_MEAL_BELOW_ERROR";

export const RIYOUSYA_MEAL_DEL_SHOKUJI = "RIYOUSYA_MEAL_DEL_SHOKUJI";
export const RIYOUSYA_MEAL_DEL_SYOKUJISEN = "RIYOUSYA_MEAL_DEL_SYOKUJISEN";

export const RIYOUSYA_MEAL_UPDATE_SYOKUJISEN =
  "RIYOUSYA_MEAL_UPDATE_SYOKUJISEN";
export const RIYOUSYA_MEAL_UPDATE_SHOKUJI = "RIYOUSYA_MEAL_UPDATE_SHOKUJI";

export const RIYOUSYA_MEAL_UPDATE_REQUEST = "RIYOUSYA_MEAL_UPDATE_REQUEST";
export const RIYOUSYA_MEAL_UPDATE_SUCCESS = "RIYOUSYA_MEAL_UPDATE_SUCCESS";
export const RIYOUSYA_MEAL_UPDATE_ERROR = "RIYOUSYA_MEAL_UPDATE_ERROR";

export const RIYOUSYA_FILE_UPDATE_SUCCESS = "RIYOUSYA_FILE_UPDATE_SUCCESS";
export const RIYOUSYA_FILE_UPDATE_ERROR = "RIYOUSYA_FILE_UPDATE_ERROR";
export const RIYOUSYA_FILE_UPDATE_REQUEST = "RIYOUSYA_FILE_UPDATE_REQUEST";

export const RIYOUSYA_FILE_INSERT_SUCCESS = "RIYOUSYA_FILE_INSERT_SUCCESS";
export const RIYOUSYA_FILE_INSERT_ERROR = "RIYOUSYA_FILE_INSERT_ERROR";
export const RIYOUSYA_FILE_INSERT_REQUEST = "RIYOUSYA_FILE_INSERT_REQUEST";

export const RIYOUSYA_FILE_DETAIL_SUCCESS = "RIYOUSYA_FILE_DETAIL_SUCCESS";
export const RIYOUSYA_FILE_DETAIL_ERROR = "RIYOUSYA_FILE_DETAIL_ERROR";

export const RIYOUSYA_FILE_DELETE_SUCCESS = "RIYOUSYA_FILE_DELETE_SUCCESS";
export const GET_RIYOUSYA_SUCCESS = "GET_RIYOUSYA_SUCCESS";
export const GET_RIYOUSYA_ERROR = "GET_RIYOUSYA_ERROR";
export const GET_LIST_RIYOUSYA_ID_SUCCESS = "GET_LIST_RIYOUSYA_ID_SUCCESS";
export const GET_LIST_RIYOUSYA_ID_ERROR = "GET_LIST_RIYOUSYA_ID_ERROR";

export const RIYOUSYA_LIST_IS_KEEP = "RIYOUSYA_LIST_IS_KEEP";

export const RIYOUSYA_HOKEN_ABOVE_REQUEST = "RIYOUSYA_HOKEN_ABOVE_REQUEST";
export const RIYOUSYA_HOKEN_ABOVE_SUCCESS = "RIYOUSYA_HOKEN_ABOVE_SUCCESS";
export const RIYOUSYA_HOKEN_ABOVE_ERROR = "RIYOUSYA_HOKEN_ABOVE_ERROR";

export const RIYOUSYA_HOKEN_BELOW_REQUEST = "RIYOUSYA_HOKEN_BELOW_REQUEST";
export const RIYOUSYA_HOKEN_BELOW_SUCCESS = "RIYOUSYA_HOKEN_BELOW_SUCCESS";
export const RIYOUSYA_HOKEN_BELOW_ERROR = "RIYOUSYA_HOKEN_BELOW_ERROR";

export const KAIGOHOKEN_MASTER_SPIN_REQUEST = "KAIGOHOKEN_MASTER_SPIN_REQUEST";
export const LOAD_KAIGOHOKEN_MASTER_SUCCESS = "LOAD_KAIGOHOKEN_MASTER_SUCCESS";
export const LOAD_KAIGOHOKEN_MASTER_ERROR = "LOAD_KAIGOHOKEN_MASTER_ERROR";

export const ITAKUTANTO_MASTER_SPIN_REQUEST = "ITAKUTANTO_MASTER_SPIN_REQUEST";
export const LOAD_ITAKUTANTO_MASTER_SUCCESS = "LOAD_ITAKUTANTO_MASTER_SUCCESS";
export const LOAD_ITAKUTANTO_MASTER_ERROR = "LOAD_ITAKUTANTO_MASTER_ERROR";

export const LOAD_KAIGODO_MASTER_RIYOU_PAGE = "LOAD_KAIGODO_MASTER_RIYOU_PAGE";
export const RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST =
  "RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST";
export const RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS =
  "RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS";
export const RIYOUSYA_KAIGOHOKEN_UPDATE_ERROR =
  "RIYOUSYA_KAIGOHOKEN_UPDATE_ERROR";
export const RIYOUSYA_KAIGOHOKEN_DELETE = "RIYOUSYA_KAIGOHOKEN_DELETE";

export const RIYOUSYA_KOHI_UPDATE_REQUEST = "RIYOUSYA_KOHI_UPDATE_REQUEST";
export const RIYOUSYA_KOHI_UPDATE_SUCCESS = "RIYOUSYA_KOHI_UPDATE_SUCCESS";
export const RIYOUSYA_KOHI_UPDATE_ERROR = "RIYOUSYA_KOHI_UPDATE_ERROR";
export const RIYOUSYA_KOHIJOHO_DELETE = "RIYOUSYA_KOHIJOHO_DELETE";

export const CHECK_HOKEN_NO_SUCCESS = "CHECK_HOKEN_NO_SUCCESS";
export const CHECK_HOKEN_NO_ERROR = "CHECK_HOKEN_NO_ERROR";

export const KOHIFUTAN_MASTER_SPIN_REQUEST = "KOHIFUTAN_MASTER_SPIN_REQUEST";
export const LOAD_KOHIFUTAN_MASTER_SUCCESS = "LOAD_KOHIFUTAN_MASTER_SUCCESS";
export const LOAD_KOHIFUTAN_MASTER_ERROR = "LOAD_KOHIFUTAN_MASTER_ERROR";

export const CHANGE_FACESHEET = "CHANGE_FACESHEET";

export const RIYOU_CHART_TEMPLATE_SAVE = "RIYOU_CHART_TEMPLATE_SAVE";
export const RIYOU_CHART_TEMPLATE_LOAD = "RIYOU_CHART_TEMPLATE_LOAD";
export const RIYOU_CHART_TEMPLATE_DELETE = "RIYOU_CHART_TEMPLATE_DELETE";