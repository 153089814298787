import dayjs from "dayjs";
import { ConstSet } from "../../../common/configs/constset";
import { ConfigName } from "../../../config-name";
import { ApiPaths } from "../../constants";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils";
import { ErrorActionTypes, KagakutekiActionTypes } from "../action_types";

function getLifeYougoKagaku() {
  return async (dispatch) => {
    try {
      dispatch({ type: KagakutekiActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.Kagakuteki.getLifeYougoKagaku,
      });

      dispatch({ type: KagakutekiActionTypes.GET_LIFE_YOUGO_KAGAKU_SUCCESS, data });
    }
    catch (error) {
      dispatch({
        type: KagakutekiActionTypes.GET_LIFE_YOUGO_KAGAKU_ERROR,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getKagakutekiById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: KagakutekiActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.Kagakuteki.getKagakutekiById}?id=${id}`,
      });

      dispatch({ type: KagakutekiActionTypes.LOAD_KAGAKUTEKI_SUCCESS, data });
    }
    catch (error) {
      dispatch({
        type: KagakutekiActionTypes.LOAD_KAGAKUTEKI_ERROR,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteKagakuteki(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: KagakutekiActionTypes.REMOVE_KAGAKUTEKI_REQUEST });

      const data = await RequestUtils.callApi({
        method: "delete",
        path: `${ApiPaths.Kagakuteki.delKagakuteki}?id=${id}`,
      });

      dispatch({ type: KagakutekiActionTypes.REMOVE_KAGAKUTEKI_SUCCESS, data });
      if (callback && typeof callback === "function") {
        return callback(data);
      }
    }
    catch (error) {
      dispatch({ type: KagakutekiActionTypes.REMOVE_KAGAKUTEKI_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function create(value, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: KagakutekiActionTypes.LOAD_SPIN_REQUEST });
      const data = await RequestUtils.callApi({
        method: "post",
        path: ApiPaths.Kagakuteki.saveKagakuteki,
        body: {
          ...value
        },
      });
      dispatch({
        type: KagakutekiActionTypes.SAVE_KAGAKUTEKI_SUCCESS,
        data,
      });
      if (callback && typeof callback === "function") {
        return callback(data);
      }
    }
    catch (error) {
      dispatch({
        type: KagakutekiActionTypes.SAVE_KAGAKUTEKI_ERROR,
        error,
      });
    }
  };
}

function update(value, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: KagakutekiActionTypes.LOAD_SPIN_REQUEST });
      const data = await RequestUtils.callApi({
        method: "put",
        path: ApiPaths.Kagakuteki.updateKagakuteki,
        body: {
          ...value,
        },
      });
      dispatch({
        type: KagakutekiActionTypes.UPDATE_KAGAKUTEKI_SUCCESS,
        data,
      });
      if (callback && typeof callback === "function") {
        return callback(data);
      }
    }
    catch (error) {
      dispatch({
        type: KagakutekiActionTypes.UPDATE_KAGAKUTEKI_ERROR,
        error,
      });
    }
  };
}


function getListToQuote(riyousyaId, id) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = { riyousyaId, id, systemControlName: menu.scn, systemControlId: menu.si };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.Kagakuteki.getListToQuote}`,
        query,
      });
      dispatch({
        type: KagakutekiActionTypes.LOAD_KAGAKUTEKI_TO_QUOTE_SUCCESS,
        data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListIds(params) {
  const SWLIFEListRiyousya = StorageUtils.getValue(
    ConfigName.SWLIFE_LISTRIYOUSYA
  );
  const kagakuSort =
    SWLIFEListRiyousya.sort && SWLIFEListRiyousya.sort.kagaku
      ? SWLIFEListRiyousya.sort.kagaku
      : {};
  return async (dispatch) => {
    try {
      dispatch({ type: KagakutekiActionTypes.LOAD_SPIN_REQUEST });

      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = {
        sid: menu.si || null,
        inUsed: SWLIFEListRiyousya.isUsed ? 1 : 0,
        searchName: params.searchName,
        fromDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .startOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        toDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .endOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(kagakuSort)),
        riyousyaId: params.riyousyaId,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Kagakuteki.getListIdsKagakuteki,
        query,
      });

      dispatch({
        type: KagakutekiActionTypes.GET_LIST_IDS_KAGAKUTEKI,
        ids: data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getAdlInyo(riyousyaId, callback) {
  const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
  return async (dispatch) => {
    const body = { riyousyaId, sid: menu.si };
    try {
      dispatch({
        type: KagakutekiActionTypes.GET_KAGAKUTEKI_ADL_INYO,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.Kagakuteki.GetAdlInyo,
        query: body,
      });
      callback(data);
      dispatch({
        type: KagakutekiActionTypes.GET_KAGAKUTEKI_ADL_INYO_SUCCESS,
      });
    }
    catch (error) {
      dispatch({
        type: KagakutekiActionTypes.GET_KAGAKUTEKI_ADL_INYO_ERROR,
        error,
      });
    }
  };
}

function getFirstLoadData(riyousyaId, fdayMonth, ldayMonth, callback) {
  return async (dispatch) => {
    try {
      const query = {
        riyousyaId,
        fdayMonth,
        ldayMonth,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getFirstLoadData,
        query,
      });

      dispatch({ type: KagakutekiActionTypes.GET_FIRST_LOAD_DATA, data });
      callback(data);
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}


export const KagakutekiActions = {
  getLifeYougoKagaku,
  getKagakutekiById,
  deleteKagakuteki,
  create,
  update,
  getListToQuote,
  getListIds,
  getAdlInyo,
  getFirstLoadData
};
