import {
  LOAD_SPIN_REQUEST,
  LOAD_TANTO_KAIGI_SUCCESS,
  LOAD_TANTO_KAIGI_ERROR,
  DELETE_TANTO_KAIGI_SUCCESS,
  DELETE_TANTO_KAIGI_ERROR,
  LOAD_TANTO_KAIGI_BY_DATE_SUCCESS,
  LOAD_TANTO_KAIGI_BY_DATE_ERROR,
  LOAD_TANTO_KAIGI_BY_ID_SUCCESS,
  LOAD_TANTO_KAIGI_BY_ID_ERROR,
  CREATE_TANTO_KAIGI_SUCCESS,
  CREATE_TANTO_KAIGI_ERROR,
  UPDATE_TANTO_KAIGI_SUCCESS,
  UPDATE_TANTO_KAIGI_ERROR,
  RESET_CURRENT_TANTO_KAIGI,
  LOAD_TANTO_KAIGI_SPIN,
  SAVE_TANTO_KAIGI_TEMPLATE_SUCCESS,
  SAVE_TANTO_KAIGI_TEMPLATE_ERROR,
  LOAD_TANTO_KAIGI_TEMPLATE_SUCCESS,
  LOAD_TANTO_KAIGI_TEMPALTE_ERROR,
  GET_DATA_KIKAN_MATER_REQUEST,
  GET_DATA_KIKAN_MATER_SUCCESS,
  GET_DATA_KIKAN_MATER_ERROR,
} from "./actionName";

import { TantoKaigiApi } from "../../api/tantoKaigi";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

export function loadTantoKaigi(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    TantoKaigiApi.loadTantoKaigi(data).then(
      (data) => {
        dispatch({
          type: LOAD_TANTO_KAIGI_SUCCESS,
          tantoKaigis: data.data,
          meta: data.meta,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_TANTO_KAIGI_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function deleteTantoKaigi(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    TantoKaigiApi.deleteTantoKaigi(id).then(
      (data) =>
        dispatch({
          type: DELETE_TANTO_KAIGI_SUCCESS,
          deleteTantoKaigi: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: DELETE_TANTO_KAIGI_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function loadTantoKaigiByDate(data) {
  return (dispatch) => {
    TantoKaigiApi.loadTantoKaigiByDate(data).then(
      (data) =>
        dispatch({
          type: LOAD_TANTO_KAIGI_BY_DATE_SUCCESS,
          tantoKaigis: data.data,
          meta: data.meta,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_TANTO_KAIGI_BY_DATE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function loadTantoKaigiById(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    dispatch({
      type: LOAD_TANTO_KAIGI_SPIN,
    });
    TantoKaigiApi.loadTantoKaigiById(id).then(
      (data) =>
        dispatch({
          type: LOAD_TANTO_KAIGI_BY_ID_SUCCESS,
          tantoKaigi: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_TANTO_KAIGI_BY_ID_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function createTantoKaigi(params, riyousyaId, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_TANTO_KAIGI_SPIN,
    });
    TantoKaigiApi.createTantoKaigi(params, riyousyaId).then(
      (data) => {
        dispatch({
          type: CREATE_TANTO_KAIGI_SUCCESS,
          createdTantoKaigi: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_TANTO_KAIGI_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function updateTantoKaigi(params, id, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_TANTO_KAIGI_SPIN,
    });
    TantoKaigiApi.updateTantoKaigi(params, id).then(
      (data) =>
        dispatch({
          type: UPDATE_TANTO_KAIGI_SUCCESS,
          updatedTantoKaigi: data,
          callback,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_TANTO_KAIGI_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function resetCurrentTantoKaigi() {
  return (dispatch) => {
    dispatch({
      type: RESET_CURRENT_TANTO_KAIGI,
    });
  };
}

export function saveTantoKaigiTemplate(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_TANTO_KAIGI_SPIN,
    });
    TantoKaigiApi.saveTantoKaigiTemplate(data).then(
      () => {
        dispatch({
          type: SAVE_TANTO_KAIGI_TEMPLATE_SUCCESS,
        });
        return callback(true);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: SAVE_TANTO_KAIGI_TEMPLATE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
        return callback(false);
      }
    );
  };
}

export function loadTantoKaigiTemplate(callback) {
  return (dispatch) => {
    TantoKaigiApi.loadTantoKaigiTemplate().then(
      (data) => {
        dispatch({
          type: LOAD_TANTO_KAIGI_TEMPLATE_SUCCESS,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_TANTO_KAIGI_TEMPALTE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getDataKiKanMaster(id, callback) {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_KIKAN_MATER_REQUEST,
    });
    TantoKaigiApi.getDataKikanMaster(id).then(
      (data) => {
        dispatch({
          type: GET_DATA_KIKAN_MATER_SUCCESS,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: GET_DATA_KIKAN_MATER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
