import {
  LOAD_INDIVIDUAL_PLAN_SUCCESS,
  CREATE_INDIVIDUAL_PLAN_SUCCESS,
  UPDATE_INDIVIDUAL_PLAN_SUCCESS,
  DELETE_INDIVIDUAL_PLAN_SUCCESS,
  LOAD_EVENT_PLAN_SUCCESS,
  CREATE_EVENT_PLAN_SUCCESS,
  UPDATE_EVENT_PLAN_SUCCESS,
  DELETE_EVENT_PLAN_SUCCESS,
  LOAD_FILTER_MASTER_SUCCESS,
  LOAD_NYUTAISHO_DATA_SUCCESS,
  FILTER_RIYOUSYA_SUCCESS,
  FACILITY_PLAN_YOUGO_SUCCESS,
  FACILITY_PLAN_YOUGO_EDIT_STATE,
  FACILITY_PLAN_YOUGO_CANCEL_STATE,
} from "../../actions/planActions/actionName";
import _ from "lodash";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
export default function planReducers(state = { filterConditions: {} }, action) {
  switch (action.type) {
    case LOAD_INDIVIDUAL_PLAN_SUCCESS:
      let filteredIndividualPlans = action.individualPlans;
      if (!_.isEmpty(state.filterConditions)) {
        filteredIndividualPlans = searchAll(
          action.individualPlans,
          state.allNyutaisho,
          state.filterMaster,
          state.filterConditions
        );
      }
      return {
        ...state,
        individualPlans: action.individualPlans,
        filteredIndividualPlans,
        notifyDisplay: 0,
      };
    case CREATE_INDIVIDUAL_PLAN_SUCCESS:
      return {
        ...state,
        createEventRes: action.createEventRes,
        notifyDisplay: 1,
        notifyContent: "保存しました",
      };
    case UPDATE_INDIVIDUAL_PLAN_SUCCESS:
      return {
        ...state,
        updateEventRes: action.updateEventRes,
        notifyDisplay: 1,
        notifyContent: "保存しました",
      };
    case DELETE_INDIVIDUAL_PLAN_SUCCESS:
      return {
        ...state,
        deleteEventRes: action.deleteEventRes,
        notifyDisplay: 1,
        notifyContent: "保存しました",
      };
    case LOAD_EVENT_PLAN_SUCCESS:
      return {
        ...state,
        eventPlans: action.eventPlans,
        notifyDisplay: 0,
      };
    case CREATE_EVENT_PLAN_SUCCESS:
      return {
        ...state,
        createEventRes: action.createEventRes,
        notifyDisplay: 1,
        notifyContent: "保存しました",
      };
    case UPDATE_EVENT_PLAN_SUCCESS:
      return {
        ...state,
        updateEventRes: action.updateEventRes,
        notifyDisplay: 1,
        notifyContent: "保存しました",
      };
    case DELETE_EVENT_PLAN_SUCCESS:
      return {
        ...state,
        deleteEventRes: action.deleteEventRes,
        notifyDisplay: 1,
        notifyContent: "保存しました",
      };
    case LOAD_FILTER_MASTER_SUCCESS:
      return {
        ...state,
        filterMaster: action.filterMaster,
      };
    case LOAD_NYUTAISHO_DATA_SUCCESS:
      return {
        ...state,
        allNyutaisho: action.allNyutaisho,
      };
    case FILTER_RIYOUSYA_SUCCESS: // Search Function(local)
      return {
        ...state,
        filteredIndividualPlans: searchAll(
          state.individualPlans,
          state.allNyutaisho,
          state.filterMaster,
          action.searchData
        ),
        filterConditions: action.searchData,
      };
    case FACILITY_PLAN_YOUGO_SUCCESS:
      const prePareData =
        action.payload && action.payload.naiyou
          ? action.payload.naiyou.split(";")
          : [];
      const dataReturn = prePareData.map((v, i) => {
        return {
          key: i,
          naiyou: v,
          editable: false,
        };
      });
      return {
        ...state,
        yougoMasterNaiyou: dataReturn,
      };
    case FACILITY_PLAN_YOUGO_EDIT_STATE:
      return {
        ...state,
        yougoMasterNaiyou: state.yougoMasterNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = true;
          } else {
            item.editable = false;
          }
          return item;
        }),
      };
    case FACILITY_PLAN_YOUGO_CANCEL_STATE:
      return {
        ...state,
        yougoMasterNaiyou: state.yougoMasterNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = false;
          }
          return item;
        }),
      };
    default:
      return state;
  }
}

function searchAll(individualPlans, arraySearch, roomList, dataFilter) {
  if (!_.isEmpty(dataFilter)) {
    if (
      !dataFilter.search.checkbox1 &&
      !dataFilter.search.checkbox2 &&
      !dataFilter.search.checkbox3
    ) {
      return individualPlans;
    }
    if (arraySearch) {
      // SEARCH BY BUNRUI
      if (dataFilter.search.field10 && dataFilter.search.checkbox1) {
        const valueKey = dataFilter.search.field10.split("=")[0];

        if (valueKey === "分類名1" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui1 === dataFilter.search.field11
          );
        }

        if (valueKey === "分類名2" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui2 === dataFilter.search.field11
          );
        }

        if (valueKey === "分類名3" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui3 === dataFilter.search.field11
          );
        }

        if (valueKey === "分類名4" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui4 === dataFilter.search.field11
          );
        }

        if (valueKey === "分類名5" && dataFilter.search.field11) {
          arraySearch = arraySearch.filter(
            (item) => item.riyousya.bunrui5 === dataFilter.search.field11
          );
        }
      }

      // SEARCH BY nyutaisho type
      if (dataFilter.search.field2 && dataFilter.search.checkbox2) {
        arraySearch = arraySearch.filter(
          (item) => item.kubun === dataFilter.search.field2
        );
      }

      // SEARCH BY ROOM
      if (
        dataFilter.search.field3 &&
        dataFilter.search.checkbox3 &&
        roomList &&
        roomList.roomMasters
      ) {
        const filteredRooms = roomList.roomMasters.filter(
          (item) => item.bunrui === dataFilter.search.field3
        );
        const rooms = filteredRooms.map((i) => i.room);

        // Algorithm filter room in array
        arraySearch = arraySearch.filter(
          (item) => rooms.indexOf(item.room) > -1
        );
      }
    }
    const filteredRiyousyas = arraySearch.map((hist) => hist.riyousya.id);
    return _.filter(
      individualPlans,
      (i) => filteredRiyousyas.indexOf(i.riyousyaId) > -1
    );
  }
  return individualPlans;
}
