import "whatwg-fetch";
import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";

let OpenScreenLogApi = {
  getOpenScreenLog(tantoId) {
    return axios(apiUrls.INIT + apiUrls.GET_OPEN_SCREEN_LOG, {
      method: "POST",
      data: {
        tantoId,
      },
      headers: {
        "x-token": localStorage.getItem("jwtToken"),
      },
      withCredentials: true,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          clearLocalStorage(err);
        }
      });
  },
  saveOpenScreenLog(tantoId, screen) {
    return axios(apiUrls.INIT + apiUrls.SAVE_OPEN_SCREEN_LOG, {
      method: "POST",
      data: {
        tantoId,
        screen,
      },
      headers: {
        "x-token": localStorage.getItem("jwtToken"),
      },
      withCredentials: true,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          clearLocalStorage(err);
        }
      });
  },
};

function clearLocalStorage(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("doubleLogin");
    localStorage.removeItem("getAvatarResponse");
    localStorage.removeItem("getAvatarResponseData");
    window.location.replace("/login");
  }
  return err;
}

export { OpenScreenLogApi };
