import {
  TANTO_MASTER_LIST,
  FILTER_TANTO_MASTER_LIST,
  TANTO_MASTER_LIST_KIKAN,
  TANTO_MASTER_LIST2,
  TANTO_MASTER_LIST_KIKAN_REQUEST,
} from "../../actions/tantoMasterListActions/actionName.js";
import TantoMasterListApi from "../../api/tantoMasterList";

/*
 * @param: username: string
 * @param: password: string
 */
function tantoMasterListCreator() {
  return (dispatch) => {
    TantoMasterListApi.tantoMasterList().then((data) =>
      dispatch({
        type: TANTO_MASTER_LIST,
        payload: data,
      })
    );
  };
}
function tantoMasterListKikanCreator(bunrui, searchName, page) {
  return (dispatch) => {
    dispatch({
      type: TANTO_MASTER_LIST_KIKAN_REQUEST,
    });
    TantoMasterListApi.tantoMasterListByKikan(bunrui, searchName, page).then(
      (data) =>
        dispatch({
          type: TANTO_MASTER_LIST_KIKAN,
          payload: data,
        })
    );
  };
}
function tantoMasterList2() {
  return (dispatch) => {
    TantoMasterListApi.tantoMasterList2().then((data) =>
      dispatch({
        type: TANTO_MASTER_LIST2,
        payload: data,
      })
    );
  };
}

function tantoMasterFilterCreator(filterData) {
  return (dispatch) => {
    dispatch({
      type: FILTER_TANTO_MASTER_LIST,
      filterData,
    });
  };
}

export {
  tantoMasterListCreator,
  tantoMasterFilterCreator,
  tantoMasterListKikanCreator,
  tantoMasterList2,
};
