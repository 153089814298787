
export const LOAD_ANSWER_REQUEST = "LOAD_ANSWER_REQUEST";
export const LOAD_ANSWER_SUCCESS = "LOAD_ANSWER_SUCCESS";
export const LOAD_ANSWER_ERROR = "LOAD_ANSWER_ERROR";

export const LOAD_QUESTION_REQUEST = "LOAD_QUESTION_REQUEST";
export const LOAD_QUESTION_SUCCESS = "LOAD_QUESTION_SUCCESS";
export const LOAD_QUESTION_ERROR = "LOAD_QUESTION_ERROR";

export const UPDATE_ANSWER_REQUEST = "UPDATE_ANSWER_REQUEST";
export const UPDATE_ANSWER_SUCCESS = "UPDATE_ANSWER_SUCCESS";
export const UPDATE_ANSWER_ERROR = "UPDATE_ANSWER_ERROR";
export const RESET_STATUS = "RESET_STATUS";
export const RESET_CHOSA = "RESET_CHOSA";