import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { formatStorage, getMenu } from "../common/function_common/functionCommon";
import { ConfigName } from "../config-name";

const KihonAdlMasterApi = {
  async all() {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.KIHON_ADL_MASTER_ALL, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  }
};

const SettingMasterAPI = {
  async load(settingNames) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.SETTING_MASTER_LOAD + `?page=${1}&limit=${1000}&settingName=&naiyou=&settingNames=${settingNames}`, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
        localStorage.removeItem(ConfigName.DOUBLELOGIN);
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  },

  async all() {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.SETTING_MASTER_ALL, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  },

  async allNew(singleTextTitle, singleText) {
    const key = getMenu();
    const menu = formatStorage(key);
    const mainSID = menu.si;
    const systemName = menu.sn;
    try {
      const res = await axios(apiUrls.INIT + apiUrls.SETTING_MASTER_ALL_NEW + `?systemId=${mainSID}&singleTextTitle=${singleTextTitle}&singleText=${singleText}&systemName=${systemName}`, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  },
  async getByName(settingName) {
    const res = await axios(apiUrls.INIT + apiUrls.GET_SETTING_MASTER, {
      method: "GET",
      params: { settingName }
    });
    return res.data;
  }
};
export {
  KihonAdlMasterApi, SettingMasterAPI
};

