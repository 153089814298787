export const RihabiriActionTypes = {
  REHABILI_LOADING_MODE: "REHABILI_LOADING_MODE",
  REHA_ASSESS_ADL_ITEM_ANSWER_NAIYOU: "REHA_ASSESS_ADL_ITEM_ANSWER_NAIYOU",
  REHA_ASSESS_ADL_ITEM_ADL: "REHA_ASSESS_ADL_ITEM_ADL",
  REHA_ASSESS_ADL_ITEM_KANKYOU: "REHA_ASSESS_ADL_ITEM_KANKYOU",
  REHA_ASSESS_IADL_ITEM: "REHA_ASSESS_IADL_ITEM",
  LOAD_RIHABIKIASSESSMENTBYID: "LOAD_RIHABIKIASSESSMENTBYID",
  REHA_ASSESS_MMSE_ITEM: "REHA_ASSESS_MMSE_ITEM",
  RIHABILI_RPSDEL_LIFE: "RIHABILI_RPSDEL_LIFE",
  GET_COMMON_MEDISYOUBYOUMEI_MASTER1: "GET_COMMON_MEDISYOUBYOUMEI_MASTER1",
  GET_COMMON_RIYOUBYOUREKI_MASTER1: "GET_COMMON_RIYOUBYOUREKI_MASTER1",
  GET_RIHABIRI_SAISHIN_RECORD: "GET_RIHABIRI_SAISHIN_RECORD",
  GET_ICF_MASTER_SUCESS_FORDIS: "GET_ICF_MASTER_SUCESS_FORDIS",
  LOAD_NEEDS_ITEM_SUCCESS_FORDIS: "LOAD_NEEDS_ITEM_SUCCESS_FORDIS",
  GET_SIENNAIYO_MASTER_SUCESS_FORDIS: "GET_SIENNAIYO_MASTER_SUCESS_FORDIS",
  GET_SETTING_MASTER_SUCESS_CATE: "GET_SETTING_MASTER_SUCESS_CATE",
};
