export const Routes = {
  Plan1: {
    Path: "/management/input-plan1/",
    PathView: "/management/view-plan1/",
  },
  Plan2: {
    Path: "/management/input-plan2/",
    PathView: "/management/view-plan2/",
  },
  InquiryRequest: {
    Path: "/management/input-inquiry-request/",
    PathView: "/management/view-inquiry-request/",
  },
  Meeting: {
    Path: "/management/input-tanto-kaigi/",
    PathView: "/management/view-tanto-kaigi/",
  },
  Monitoring: {
    Path: "/management/input-monitoring/",
    PathView: "/management/view-monitoring/",
  },
  Progress: {
    Path: "/management/input-keika-kiroku/",
    PathView: "/management/view-keika-kiroku/",
  },
  Task: {
    Path: "/management/input-task/",
    PathView: "/management/view-task/",
  },
  WeeklyPlan: {
    Path: "/management/input-weekly-plan/",
    PathView: "/management/view-weekly-plan/",
  },
  DocumentTab: {
    Path: "/management/documents/",
  },
  JokusoPlan: {
    Path: "/life/input-jokuso-plan/",
    PathView: "/life/view-jokuso-plan/",
  },
};
