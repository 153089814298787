import { connect } from "react-redux";
import Dengon from "./dengon";
import {
  userListCreator,
  groupFilterCreator,
  searchNameCreator,
} from "../../actions/userListActions/actionCreators";
import {
  messageListCreator,
  messageAddCreator,
  messageLoadMoreCreator,
  messageMitayoCreator,
} from "../../actions/messageActions/actionCreators";
import {
  initializeSocket,
  socketSendMessage,
  logoutCreators,
} from "../../actions/commonActions/actionCreators";
import { sendGroupCreator } from "../../actions/sendGroupActions/actionCreators";
import { sendToCreators } from "../../actions/dengonSentToActions/actionCreators";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    users: state.userList.users,
    groups: state.sendGroup.groups,
    groupName: state.userList.groupName,
    name: state.userList.name,
    messages: state.messageList.messages,
    loading: state.messageList.loading,
    preventLogin: state.messageList.preventLogin,
    sendTo: state.dengonSendTo.sendTo,
    getAvatarResponse: state.getAvatar.data,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    userList: () => dispatch(userListCreator()),
    sendGroup: () => dispatch(sendGroupCreator()),
    groupFilter: (filter) => dispatch(groupFilterCreator(filter)),
    search: (text) => dispatch(searchNameCreator(text)),
    messageList: (tid, pageNo) => dispatch(messageListCreator(tid, pageNo)),
    addnew: (tid, jsonMessage) => dispatch(messageAddCreator(tid, jsonMessage)),
    initializeSocket: () => dispatch(initializeSocket()),
    sendMessage: (data) => dispatch(socketSendMessage(data)),
    mitayo: (messageId) => dispatch(messageMitayoCreator(messageId)),
    loadMore: (tid, pageNo) => dispatch(messageLoadMoreCreator(tid, pageNo)),
    sendToIds: (checkedList) => dispatch(sendToCreators(checkedList)),
    logout: () => dispatch(logoutCreators()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dengon);
