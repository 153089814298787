import {
  REPORT_WEEKLY_REQUEST,
  REPORT_WEEKLY_SUCCESS,
  REPORT_WEEKLY_ERROR,
} from "../../actions/reportWeeklyActions/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {};

export default function reportWeeklyReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REPORT_WEEKLY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REPORT_WEEKLY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case REPORT_WEEKLY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "エラー",
      };

    default:
      return state;
  }
}
