/* eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

const fabric = require("fabric").fabric;

class Living extends FabricCanvasTool {

  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => o.selectable = o.evented = false);
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.call_back = props.update_select;
  }

  doMouseDown(o) {
    const canvas = this._canvas;
    this.isDown = true;
    const pointer = canvas.getPointer(o.e);
    [this.startX, this.startY] = [pointer.x, pointer.y];

    // var line = new fabric.Path('m92.5,273.45313c61,-72 68,19 108,-3c40,-22 40,-23 40,-23', { fill: '', stroke: 'black',strokeWidth: 1, objectCaching: false });
    const line = new fabric.Path("m178.49964,162.99966c0,0 -24.49994,15.99997 -37.49992,12.49998c-12.99998,-3.49999 -45.99991,16.49996 -46.49991,17.99996c-0.5,1.5 -36.49993,41.99992 -19.49996,57.99989c16.99997,15.99996 101.9998,48.4999 127.99974,15.99996c25.99995,-32.49993 74.99985,-80.49984 52.4999,-120.49976c-22.49996,-39.99992 -45.99991,-53.49989 -69.49986,-40.99991c-23.49995,12.49998 -7.49999,43.49991 -7.5,42.99991c0.00001,0.5 0.00001,13.99997 0.00001,13.99997z", { fill: "", stroke: "black", strokeWidth: 1, objectCaching: false, strokeDashArray: [5, 5], });
    this.groupc = new fabric.Group([line], {
      left: this.startX,
      top: this.startY,
      angle: 0,
      selectable: true,
    });
    canvas.add(this.groupc);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    const canvas = this._canvas;
    const pointer = canvas.getPointer(o.e);
    this.groupc.set(
      {
        scaleX: pointer.x / this.startX,
        scaleY: pointer.x / this.startX,
      }
    );

    this.groupc.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.call_back();
  }
}

export default Living;