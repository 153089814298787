export const LOAD_KYOTAKU_PLAN1_SUCCESS = "LOAD_KYOTAKU_PLAN1_SUCCESS";
export const LOAD_KYOTAKU_PLAN1_BY_ID_SUCCESS =
  "LOAD_KYOTAKU_PLAN1_BY_ID_SUCCESS";
export const CREATE_KYOTAKU_PLAN1_SUCCESS = "CREATE_KYOTAKU_PLAN1_SUCCESS";
export const UPDATE_KYOTAKU_PLAN1_SUCCESS = "UPDATE_KYOTAKU_PLAN1_SUCCESS";
export const DELETE_KYOTAKU_PLAN1_SUCCESS = "DELETE_KYOTAKU_PLAN1_SUCCESS";
export const LOAD_KYOTAKU_PLAN1_TO_QUOTE_SUCCESS =
  "LOAD_KYOTAKU_PLAN1_TO_QUOTE_SUCCESS";
export const LOAD_SPIN_REQUEST = "LOAD_SPIN_REQUEST";

export const LOAD_YOUGO_ATTRIBUTE_SUCCESS = "LOAD_YOUGO_ATTRIBUTE_SUCCESS";
export const LOAD_YOUGO_BUNRUI1_SUCCESS = "LOAD_YOUGO_BUNRUI1_SUCCESS";
export const LOAD_YOUGO_BUNRUI2_SUCCESS = "LOAD_YOUGO_BUNRUI2_SUCCESS";
export const LOAD_YOUGO_KEYWORD_SUCCESS = "LOAD_YOUGO_KEYWORD_SUCCESS";
export const LOAD_YOUGO_FUSANKA_RIYU_SUCCESS =
  "LOAD_YOUGO_FUSANKA_RIYU_SUCCESS";

export const LOAD_SETTING_INPUT1_SUCCESS = "LOAD_SETTING_INPUT1_SUCCESS";

export const LOAD_KYOTAKU_PLAN1_ERROR = "LOAD_KYOTAKU_PLAN1_ERROR";
export const LOAD_KYOTAKU_PLAN1_BY_ID_ERROR = "LOAD_KYOTAKU_PLAN1_BY_ID_ERROR";
export const CREATE_KYOTAKU_PLAN1_ERROR = "CREATE_KYOTAKU_PLAN1_ERROR";
export const UPDATE_KYOTAKU_PLAN1_ERROR = "UPDATE_KYOTAKU_PLAN1_ERROR";
export const DELETE_KYOTAKU_PLAN1_ERROR = "DELETE_KYOTAKU_PLAN1_ERROR";
export const LOAD_KYOTAKU_PLAN1_TO_QUOTE_ERROR =
  "LOAD_KYOTAKU_PLAN1_TO_QUOTE_ERROR";

export const LOAD_YOUGO_ATTRIBUTE_ERROR = "LOAD_YOUGO_ATTRIBUTE_ERROR";
export const LOAD_YOUGO_BUNRUI1_ERROR = "LOAD_YOUGO_BUNRUI1_ERROR";
export const LOAD_YOUGO_BUNRUI2_ERROR = "LOAD_YOUGO_BUNRUI2_ERROR";
export const LOAD_YOUGO_KEYWORD_ERROR = "LOAD_YOUGO_KEYWORD_ERROR";
export const LOAD_YOUGO_FUSANKA_RIYU_ERROR = "LOAD_YOUGO_FUSANKA_RIYU_ERROR";

export const LOAD_SETTING_INPUT1_ERROR = "LOAD_SETTING_INPUT1_ERROR";

export const LOAD_VIEW_PLANS_BY_RIYOUSYA_SUCCESS =
  "LOAD_VIEW_PLANS_BY_RIYOUSYA_SUCCESS";
export const LOAD_VIEW_PLANS_BY_RIYOUSYA_ERROR =
  "LOAD_VIEW_PLANS_BY_RIYOUSYA_ERROR";
export const CHANGE_VALUE_DETAIL1 = "CHANGE_VALUE_DETAIL1";
export const RESET_STATUS = "RESET_STATUS";
