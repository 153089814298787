/* eslint-disable brace-style */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Input, Button, Table, Modal, Row, Select, Col, Divider } from "antd";
import { connect } from "react-redux";

//action
import {
  WeeklyPlanActions,
} from "../../redux/actions/index";
import RentalSyohinMasterModal from "../../pages/WeeklyPlan/RentalSyohinMasterModal";
const { Option } = Select;
function ProductMasterModal(props) {
  const [serviceCd, setServiceCd] = useState();
  const [serviceName, setServiceName] = useState();
  const [name, setName] = useState("");
  const [record, setRecord] = useState(null);
  const [rowActive, setRowActive] = useState("");
  const [productId, setProductId] = useState();
  const [visibleProduct, setVisibleProduct] = useState(false);
  const [pagination, setPagination] = useState();
  const [sort, setSort] = useState(false);
  const [sortTable, setSortTable] = useState(null);
  const [sortTableCopy, setSortTableCopy] = useState(null);

  useEffect(() => {
    if (props.visible) {
      props.getListRentalSyohinMaster(props.serviceCd);
      setServiceCd(props.serviceCd);
      setServiceName(props.serviceName);
      // setPagination({pageSize: 10, current: 1});
      // if (isDisableButton(rowActive)) setRowActive("");
    }
  }, [props.visible]);

  useEffect(() => {
    if (props.isReload && props.visible) {
      search();
      props.turnOffReload();
    }
  }, [props.isReload]);

  const handleCancel = () => {
    // setRecord(null);
    // setRowActive(null);
    setName("");
    setSort(false);
    setSortTable(null);
    setSortTableCopy(null);
    props.hideModal();
  };

  const search = () => {
    props.getListRentalSyohinMaster(serviceCd, name);
  };

  const onClickCellEvent = (record) => {
    // setRecord(record);
    setRowActive(record.id); //rowActive id set
  };

  const onOk = () => {
    props.onOk(props.recordSelected ? props.recordSelected.key : null, {
      ...record,
    });
    setName("");
    // setRecord(null);
    // setRowActive(null);
  };

  function handleChangeServiceCd(value, option) {
    setServiceCd(value);
    setServiceName(option.props.children);
    props.getListRentalSyohinMaster(value, name);
  }

  const handleAddProduct = () => {
    setProductId(null);
    setVisibleProduct(true);
  };

  const handleEditProduct = () => {
    setProductId(rowActive);
    setVisibleProduct(true);
  };

  const handleOkProduct = () => {
    // search();
    setVisibleProduct(false);
  };

  const handleCancelProduct = () => {
    setVisibleProduct(false);
  };

  const sortOn = () => {
    setSort(true);
    setSortTable(JSON.parse(JSON.stringify(props.listRentalSyohin)));
    setSortTableCopy(JSON.parse(JSON.stringify(props.listRentalSyohin)));
  };
  const sortOff = () => {
    setSort(false);
    setSortTable(null);
    setSortTableCopy(null);
  };
  const sortSave = () => {
    let tmp = [];

    for (let record of sortTable) {
      let defTable = sortTableCopy.find((t) => t.id == record.id);
      if (record.sortNum != defTable.sortNum) {
        tmp.push(record);
      }
    }

    props.updateRentalList(tmp, (data) => {
      props.getListRentalSyohinMaster(serviceCd, name);
    });

    setSort(false);
    setSortTable(null);
    setSortTableCopy(null);
  };

  const sortTop = (record, index) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = index;
    const minNum = sortTableTmp[0].sortNum;

    if (elementIndex != 0) {
      sortTableTmp.splice(elementIndex, 1);
      element.sortNum = minNum - 1;
      sortTableTmp.splice(0, 0, element);

      setSortTable(sortTableTmp);
    }
  };
  const sortUp = (record, index) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = index;

    if (elementIndex != 0) {
      const changeElement = sortTableTmp[elementIndex - 1];
      const changeElementIndex = elementIndex - 1;
      const changeElementSortNum = changeElement.sortNum;

      sortTableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum--;
        sortTableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        sortTableTmp.splice(changeElementIndex, 0, element);
        sortTableTmp[elementIndex].sortNum++;
      }

      setSortTable(sortTableTmp);
    }
  };
  const sortDown = (record, index) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = index;

    if (elementIndex != sortTable.length - 1) {
      const changeElement = sortTableTmp[elementIndex + 1];
      const changeElementIndex = elementIndex + 1;
      const changeElementSortNum = changeElement.sortNum;

      sortTableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum++;
        sortTableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        sortTableTmp.splice(changeElementIndex, 0, element);
        sortTableTmp[elementIndex].sortNum--;
      }

      setSortTable(sortTableTmp);
    }
  };
  const sortBottom = (record, index) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    let maxNum = 0;
    for (let i of sortTableTmp) {
      maxNum < i.sortNum ? (maxNum = i.sortNum) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = index;
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      sortTableTmp.splice(elementIndex, 1);
      element.sortNum = maxNum + 1;
      sortTableTmp.splice(sortTable.length, 0, element);

      setSortTable(sortTableTmp);
    }
  };

  const titleService = (
    <div>
      <Row style={{ marginBottom: "4px" }}>
        <Col span={6}>サービス名</Col>
        <Col span={18}>
          {/* <Input value={props.serviceType} readOnly={true} /> */}
          <Select
            value={serviceCd}
            style={{ width: 350 }}
            onChange={handleChangeServiceCd}
            disabled={sort == true ? true : false}
          >
            <Option key={null} value={null}>
              &nbsp;
            </Option>
            {props.serviceContentRental &&
              props.serviceContentRental.map((item) => (
                <Option
                  key={item.id}
                  value={item.kaigoServiceSyuruiCd + item.item}
                >
                  {item.serviceNaiyou}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={6}>商品名検索</Col>
        <Col span={18} style={{ display: "flex" }}>
          <Input
            disabled={sort == true ? true : false}
            onChange={(e) => setName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.getListRentalSyohinMaster(serviceCd, name);
              }
            }}
            value={name}
          />
          <Button
            value={name}
            onClick={() => search()}
            className={
              sort == true
                ? "search-service-kikan-none"
                : "search-service-kikan"
            }
            disabled={sort == true ? true : false}
            icon={<SearchOutlined />}
          >
            検索
          </Button>
        </Col>
      </Row>
    </div>
  );
  const columnsService = [
    {
      title: "商品名",
      dataIndex: "name",
      width: 140,
      key: "name",
      render: (textEx, record, index) => <div>{textEx}</div>,
      onCell: (record) => {
        return {
          onClick: (event) => onClickCellEvent(record), // click row
        };
      },
    },
    {
      title: "TAIS",
      dataIndex: "taisCd",
      width: 60,
      key: "taisCd",
      render: (textEx, record, index) => <div>{textEx}</div>,
      onCell: (record) => {
        return {
          onClick: (event) => onClickCellEvent(record), // click row
        };
      },
    },
    {
      title: "単位",
      dataIndex: "serviceTani",
      width: 45,
      key: "serviceTani",
      render: (textEx, record, index) => <div>{textEx}</div>,
      onCell: (record) => {
        return {
          onClick: (event) => onClickCellEvent(record), // click row
        };
      },
    },
  ];

  const columnsService2 = [
    {
      title: "商品名",
      dataIndex: "name",
      width: 150, //150
      key: "name",
      render: (textEx, record, index) => <div>{textEx}</div>,
      onCell: (record) => {
        return {
          onClick: (event) => onClickCellEvent(record), // click row
        };
      },
    },
    // { //テスト
    //   dataIndex: 'sortNum',
    //   width: 40,
    // },
    {
      title: "",
      dataIndex: "operation",
      width: 60,
      key: "operation",
      render: (textEx, record, index) => {
        return (
          <div>
            <span>
              <a onClick={() => sortTop(record, index)} style={{}}>
                <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortUp(record, index)} style={{}}>
                <CaretUpOutlined style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortDown(record, index)} style={{}}>
                <CaretDownOutlined style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortBottom(record, index)} style={{}}>
                <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
              </a>
            </span>
          </div>
        );
      },
    },
  ];

  const footer = (
    <div>
      <span>
        <Button key="back" onClick={() => handleCancel()}>
          キャンセル
        </Button>
        <Button
          key="submit"
          type="primary"
          onClick={() => onOk()}
          disabled={sort == true ? true : isDisableButton(rowActive)}
          style={{marginLeft: '5px'}}
        >
          OK
        </Button>
      </span>
    </div>
  );

  function isDisableButton(rowActive) {
    return props.listRentalSyohin
      ? !props.listRentalSyohin.some((item) => item.id == rowActive)
      : true; //listの中にrowActivのidがあれば
  }

  // function handleChangePagination(pagination) {
  //   setPagination(pagination);
  // }

  return (
    (<div className="text-area-select-ri-page">
      <Modal
        open={props.visible}
        title={titleService}
        className="modal-list"
        footer={footer}
        closable={false}
      >
        <Button
          key="add"
          type={"primary"}
          onClick={handleAddProduct}
          disabled={sort == true ? true : false}
          style={{ marginRight: 8 }}
        >
          商品追加
        </Button>
        <Button
          key="edit"
          type={"primary"}
          onClick={handleEditProduct}
          disabled={sort == true ? true : isDisableButton(rowActive)}
          style={{ marginRight: 8 }}
        >
          商品修正
        </Button>
        {sort == false ? (
          <a
            key="sort"
            // type={"primary"}
            onClick={() => sortOn()}
            style={{
              marginRight: 8,
              marginTop: 7,
              marginLeft: 10,
              color: "black",
            }}
          >
            並び替え
          </a>
        ) : (
          <span>
            <Button
              key="sortSave"
              type={"primary"}
              onClick={() => sortSave()}
              style={{ marginRight: 8 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={() => sortOff()}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
          </span>
        )}
        {sort === false ? (
          <Table
            bordered
            dataSource={props.listRentalSyohin}
            columns={columnsService}
            showHeader={true}
            className="riyou-meal-page modal-table"
            // onChange={handleChangePagination}
            // pagination={pagination}
            pagination={{ pageSize: 20 }}
            scroll={{ y: 300 }}
            size="small"
            rowClassName={(record) => {
              if (rowActive != null && record.id === rowActive) {
                setRecord(record);
                return "pljp-table-row-hover";
              }
              return "";
            }}
            rowKey={(record, index) => index}
            style={{ marginTop: 20 }}
          />
        ) : sort === true ? (
          <Table
            bordered
            dataSource={sortTable}
            columns={columnsService2}
            showHeader={true}
            className="riyou-meal-page modal-table"
            // onChange={handleChangePagination}
            // pagination={pagination}
            pagination={{ pageSize: 20 }}
            scroll={{ y: 300 }}
            size="small"
            rowClassName={(record) => {
              if (rowActive != null && record.id === rowActive) {
                setRecord(record);
                return "pljp-table-row-hover";
              }
              return "";
            }}
            rowKey={(record, index) => index}
            style={{ marginTop: 20 }}
          />
        ) : null}
      </Modal>
      <RentalSyohinMasterModal
        visible={visibleProduct}
        onOk={handleOkProduct}
        onCancel={handleCancelProduct}
        id={productId}
        serviceContentRental={props.serviceContentRental}
        serviceName={serviceName}
        serviceCd={serviceCd}
      />
    </div>)
  );
}

const mapStateToProps = (state) => {
  return {
    isReload: state.weeklyPlan.isReload,
    listRentalSyohin: state.weeklyPlan.listRentalSyohin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListRentalSyohinMaster: (serviceCd, name) =>
      dispatch(WeeklyPlanActions.getListRentalSyohinMaster(serviceCd, name)),
    turnOffReload: () => dispatch(WeeklyPlanActions.turnOffReload()),
    updateRentalList: (data, callback) =>
      dispatch(WeeklyPlanActions.updateRentalList(data, callback)),
  };
};

const _ProductMasterModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductMasterModal);

export { _ProductMasterModal as ProductMasterModal };
