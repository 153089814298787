import React, { Component } from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Divider } from "antd";
import _ from "lodash";
import TextAreaSelect from "./TextAreaSelect";
import {
  getMenu,
  formatStorage,
} from "../../../common/function_common/functionCommon";

class FormOther2Normal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { system_setFSSonotaTitle, system_fsSonotaTitleDis } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const sid = formatStorage(getMenu()).si;

    return (
      <Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {system_fsSonotaTitleDis &&
        system_fsSonotaTitleDis.toString().split(";").length > 0
          ? system_setFSSonotaTitle
              .toString()
              .split(";")
              .map((item, index) => {
                if (item !== "none" && index < 10) {
                  return (
                    <Form.Item
                      key={index}
                      label={
                        system_fsSonotaTitleDis.toString().split(";")[index]
                      }
                      colon={false}
                    >
                      {getFieldDecorator(`jigyosyoSonota${index + 1}`, {
                        rules: [
                          {
                            required: false,
                            message: "必須項目をセットして下さい",
                          },
                        ],
                      })(
                        <TextAreaSelect
                          isMaster={false}
                          isSelect={true}
                          rowSet={4}
                          dataSelect={
                            this.props[`systemSonotaList${index + 1}`]
                          }
                          yougoMasterUpdate={(naiyou) =>
                            this.yougoMasterUpdate(
                              `FSSその他${index + 1}S${sid}`,
                              naiyou
                            )
                          }
                        />
                      )}
                      <Divider style={{ margin: "4px 0px" }} />
                    </Form.Item>
                  );
                }
              })
          : null}
      </Form>
    );
  }
}

export const FormOther2 = Form.create({
  name: "input-riyou-other",
  onValuesChange(props, changedValues, allValues) {
    // @TODO convert checkbox group to value
    props.saveOnchangeToState(allValues, "fOther2", "");
  },
  mapPropsToFields(props) {
    return {
      jigyosyoSonota1: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota1 : null,
      }),
      jigyosyoSonota2: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota2 : null,
      }),
      jigyosyoSonota3: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota3 : null,
      }),
      jigyosyoSonota4: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota4 : null,
      }),
      jigyosyoSonota5: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota5 : null,
      }),
      jigyosyoSonota6: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota6 : null,
      }),
      jigyosyoSonota7: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota7 : null,
      }),
      jigyosyoSonota8: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota8 : null,
      }),
      jigyosyoSonota9: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota9 : null,
      }),
      jigyosyoSonota10: Form.createFormField({
        value: props.data ? props.data.jigyosyoSonota10 : null,
      }),
    };
  },
})(FormOther2Normal);
