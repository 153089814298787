import { Input } from "antd";
import reactComponentDebounce from "react-component-debounce";

const TextInputArea = reactComponentDebounce({
  valuePropName: "value",
  triggerMs: 200,
  rows: "rows",
  disabled: "disabled",
  style: "style",
  maxLength: "maxLength",
})(Input.TextArea);

export default TextInputArea;
