import { connect } from "react-redux";

import SendGroup from "./SendGroup";

import {
  sendGroupCreator,
  getAllSendGroupCreator,
  addMemberToGroupCreator,
  delMemberFromGroupCreator,
  addNewSendGroupCreator,
  updateSendGroupToServerCreator,
} from "../../actions/sendGroupActions/actionCreators";
import {
  userListCreator,
  groupFilterCreator,
  searchNameCreator,
} from "../../actions/userListActions/actionCreators";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    //
    users: state.userList.users,
    groups: state.sendGroup.groups,
    groupRecords: state.sendGroup.groupRecords,
    groupName: state.userList.groupName,
    name: state.userList.name,
    isUpdateGroup: state.sendGroup.isUpdateGroup,
    delIdsSendGroup: state.sendGroup.delIds,
    userExistedSendGroup: state.sendGroup.userExisted,
    isApiLoadingSG: state.sendGroup.loading,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    userList: (isGetAll) => dispatch(userListCreator(isGetAll)),
    sendGroup: () => dispatch(sendGroupCreator()),
    groupFilter: (filter) => dispatch(groupFilterCreator(filter)),
    searchMember: (text) => dispatch(searchNameCreator(text)),
    getAllSendGroup: () => dispatch(getAllSendGroupCreator()),
    addMemberToGroup: (checkListUsers, selectedGroup) =>
      dispatch(addMemberToGroupCreator(checkListUsers, selectedGroup)),
    delMemberFromGroup: (checkListGroups, selectedGroup) =>
      dispatch(delMemberFromGroupCreator(checkListGroups, selectedGroup)),
    addNewSendGroup: (text) => dispatch(addNewSendGroupCreator(text)),
    updateSendGroupToServer: (groupRecords, delIdsSendGroup) =>
      dispatch(updateSendGroupToServerCreator(groupRecords, delIdsSendGroup)),
  };
};

export const SendGroupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendGroup);
