import {
  GET_MEDISYOUBYOUMEI,
  GET_MEDISYOUBYOUMEI_ERROR,
  GET_MEDISYOUBYOUMEI_MASTER,
  GET_MEDISYOUBYOUMEI_MASTER_ERROR,
  MEDI_SYOUBYOUMEI_SPIN_REQUEST,
} from "../../../actions/mediSyoubyoumeiActions/actionName";

export default function mediSyoubyoumeiMaster(state = {}, action) {
  switch (action.type) {
    case MEDI_SYOUBYOUMEI_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_MEDISYOUBYOUMEI:
      return {
        ...state,
        medisyoubyoumei: action.data,
        loading: false,
      };

    case GET_MEDISYOUBYOUMEI_MASTER:
      return {
        ...state,
        mediSyoubyoumeiMaster: action.data,
        loading: false,
      };

    case GET_MEDISYOUBYOUMEI_ERROR:
      return {
        ...state,
        medisyoubyoumei: null,
        loading: false,
      };

    case GET_MEDISYOUBYOUMEI_MASTER_ERROR:
      return {
        ...state,
        mediSyoubyoumeiMaster: null,
        loading: false,
      };

    default:
      return state;
  }
}
