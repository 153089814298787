import React from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { cancelCreateMaster } from "../../../actions/referenceNumberActions/actionCreators";

class MasterCodeCancelButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async handleClose() {
    const { code, checkType, reference } = this.props;
    if (checkType) {
      this.props.cancelCreateMaster(reference.table, reference.field, code);
    }

    this.props.onClose();
  }

  render() {
    const { text } = this.props;

    return (
      <div>
        <Button onClick={() => this.handleClose()}>{text}</Button>
      </div>
    );
  }
}

const mapStateToProps = () => (state) => ({});

const mapDispatchToProps = () => (dispatch) => ({
  cancelCreateMaster: (table, field, code) =>
    dispatch(cancelCreateMaster(table, field, code)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MasterCodeCancelButton);
