import { Checkbox, Modal, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteKeikaKiroku } from "../../../actions/keikaKirokuActions/actionCreators";
import { deleteKyotakuPlan1 } from "../../../actions/kyotakuPlan1Actions/actionCreators";
import { deleteKyotakuPlan2 } from "../../../actions/kyotakuPlan2Actions/actionCreators";
import { deleteMonitoring } from "../../../actions/monitoringActions/actionCreators";
import { deleteSisetuDailyPlan } from "../../../actions/sisetuDailyPlanActions/actionCreators";
import { deleteTantoKaigi } from "../../../actions/tantoKaigiActions/actionCreators";
import RowContentRiyousya from "./RowContentRiyousya";

class DocumentListRecordTableByRiyousya extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteVisible: false,
      messageDelete: "",
      checked: false,
      expandedRowKeys: [],
      addOrCopyVisible: false,
      lastQuoteVisible: false,
      recordId: null,
      tab: this.props.tab,
    };
  }
  showDeleteConfirm(id) {
    const lastText = "を削除しますか？";
    const mess = `${this.props.tabName}${lastText}`;
    this.setState({
      messageDelete: mess,
      deleteVisible: true,
      recordId: id,
    });
  }

  closeDeleteConfirm() {
    this.setState({
      deleteVisible: false,
      checked: false,
      recordId: null,
    });
  }

  handleConfirmDelete() {
    switch (this.props.tab) {
      case "plan1":
        this.props.deleteKyotakuPlan1(this.state.recordId);
        break;
      case "plan2":
        this.props.deleteKyotakuPlan2(this.state.recordId);
        break;
      case "daily-plan":
        this.props.deleteSisetuDailyPlan(this.state.recordId);
        break;
      case "monitoring":
        this.props.deleteMonitoring(this.state.recordId);
        break;
      case "tanto-kaigi":
        this.props.deleteTantoKaigi(this.state.recordId);
        break;
      case "keika-kiroku":
        this.props.deleteKeikaKiroku(this.state.recordId);
        break;
      default:
        break;
    }
    this.setState({
      deleteVisible: false,
      checked: false,
      recordId: null,
    });
  }

  handleChangeCheckbox() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  handleExpand(expanded, record) {
    switch (this.props.tabActive) {
      case "1":
        if (this.props.isUserMode) {
          localStorage.setItem(
            "RowKeyRiyou1",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        else {
          localStorage.setItem(
            "RowKey1",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        break;
      case "2":
        if (this.props.isUserMode) {
          localStorage.setItem(
            "RowKeyRiyou2",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        else {
          localStorage.setItem(
            "RowKey2",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        break;
      case "3":
        if (this.props.isUserMode) {
          localStorage.setItem(
            "RowKeyRiyou3",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        else {
          localStorage.setItem(
            "RowKey3",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        break;
      case "4":
        if (this.props.isUserMode) {
          localStorage.setItem(
            "RowKeyRiyou4",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        else {
          localStorage.setItem(
            "RowKey4",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        break;
      case "5":
        if (this.props.isUserMode) {
          localStorage.setItem(
            "RowKeyRiyou5",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        else {
          localStorage.setItem(
            "RowKey5",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        break;
      case "6":
        if (this.props.isUserMode) {
          localStorage.setItem(
            "RowKeyRiyou6",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        else {
          localStorage.setItem(
            "RowKey6",
            record.id + "_" + record.riyousyaId + "_" + record.sid
          );
        }
        break;
      default:
        break;
    }
    this.setState({
      expandedRowKeys: expanded
        ? [record.id + "_" + record.riyousyaId + "_" + record.sid]
        : [],
    });
  }

  render() {
    const columns = [
      {
        title: "Content",
        dataIndex: "content",
      },
    ];
    const tableData = [];
    const { pagination, data } = this.props;
    if (data) {
      data.forEach((rc, index) => {
        tableData.push({
          id: rc.id + "_" + rc.riyousyaId + "_" + rc.sid,
          content: (
            <RowContentRiyousya
              isUserMode={this.props.isUserMode}
              history={this.props.history}
              handleAddOrCopy={(riyid, id) =>
                this.props.showModalAddOrCopy(riyid, id)
              }
              tab={this.props.tab}
              tabActive={this.props.tabActive}
              data={rc}
              index={(pagination.current - 1) * pagination.pageSize + index}
              handleExpand={(expanded, record) =>
                this.handleExpand(expanded, record)
              }
              expandedRows={this.state.expandedRowKeys}
              showDeleteConfirm={(id) => this.showDeleteConfirm(id)}
              on
            />
          ),
        });
      });
    }
    return (
      (<div>
        <Table
          showHeader={false}
          columns={columns}
          dataSource={tableData}
          rowKey={"id"}
          pagination={pagination}
          className="table-document-list"
        />
        <Modal
          open={this.state.deleteVisible}
          title="削除確認（同意するにチェックしてOKボタンを押してください）"
          onCancel={() => this.closeDeleteConfirm()}
          onOk={() => this.handleConfirmDelete()}
          okButtonProps={{ disabled: !this.state.checked }}
        >
          <div style={{ backgroundColor: "aliceblue" }}>
            <span style={{ display: "block" }}>{this.state.messageDelete}</span>
            <Checkbox
              checked={this.state.checked}
              onChange={() => this.handleChangeCheckbox()}
              value={this.state.checked}
            >
              {"削除に同意する"}
            </Checkbox>
          </div>
        </Modal>
      </div>)
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteKyotakuPlan1: (id) => dispatch(deleteKyotakuPlan1(id)),
    deleteKyotakuPlan2: (id) => dispatch(deleteKyotakuPlan2(id)),
    deleteSisetuDailyPlan: (id) => dispatch(deleteSisetuDailyPlan(id)),
    deleteMonitoring: (id) => dispatch(deleteMonitoring(id)),
    deleteTantoKaigi: (id) => dispatch(deleteTantoKaigi(id)),
    deleteKeikaKiroku: (id) => dispatch(deleteKeikaKiroku(id)),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(DocumentListRecordTableByRiyousya);
