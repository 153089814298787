import JapaneseDateConverter from "./japanese-date-converter";

// convert Japanese calendar into general calendar
const convertJapCalendarIntoGeneral = (values) => {
  const settings = {
    format: "yyyy-mm-dd",
  };
  const converter = new JapaneseDateConverter({
    inputValue: values,
    settings,
  });
  let setConvertedValue = null;
  try {
    const convertedValue = converter.execute();
    setConvertedValue = convertedValue;
  } catch (err) {
    setConvertedValue = null;
  }
  return setConvertedValue;
};

// convert general calendar into Japanese calendar
const convertCalendarIntoJap = (values) => {
  const settings = {
    format: "gee.mm.dd",
  };
  const converter = new JapaneseDateConverter({
    inputValue: values,
    settings,
  });
  let setConvertedValue = null;
  try {
    const convertedValue = converter.execute();
    setConvertedValue = convertedValue;
  } catch (err) {
    setConvertedValue = null;
  }
  return setConvertedValue;
};

const checkCalendarIntoJap = (value, settingData) => {
  const checkCalendar =
    settingData &&
    settingData.naiyou &&
    settingData.naiyou.indexOf("和暦") !== -1;
  const dateCheck = checkCalendar ? convertCalendarIntoJap(value) : value;
  return dateCheck;
};

const checkCalendarIntoGeneral = (value, settingData) => {
  const checkCalendar =
    settingData &&
    settingData.naiyou &&
    settingData.naiyou.indexOf("和暦") !== -1;
  const dateSplit = value
    .replace(/[^A-Z0-9]/gi, " ")
    .slice(1)
    .split(" ");
  const fisrtValues = value.slice(0, 1);
  const arrayDate = dateSplit.map((item) => {
    if (item.length === 1) {
      item = `0${item}`;
      return item;
    }
    return item;
  });
  arrayDate[0] = `${fisrtValues}${arrayDate[0]}`;
  arrayDate.join("-");
  const date = arrayDate.join("-");
  const dateCheck = checkCalendar ? convertJapCalendarIntoGeneral(date) : value;
  return dateCheck;
};

export {
  convertJapCalendarIntoGeneral,
  convertCalendarIntoJap,
  checkCalendarIntoJap,
  checkCalendarIntoGeneral,
};
