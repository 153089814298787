import React from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Row, List, Col, Popover, Button } from "antd";

class NormalTextareaKB extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {
    //     text: this.props.defaultValue
    // }

    this.onBlur = this.onBlur.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.defaultValue !== newProps.defaultValue) {
      this.props.form.resetFields();
    }
  }

  onBlur = (e) => {
    this.props.handleTextareaKB(e.target.value);
  };

  onSelect(text, type) {
    this.props.handleTextareaKB(text, type);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const { selectType, dataSelect, rowColSet } = this.props;

    let content = null;
    if (selectType && dataSelect) {
      content = (
        <List
          dataSource={dataSelect.toString().split(";")}
          renderItem={(item) => (
            <List.Item
              key={item}
              onClick={this.onSelect.bind(this, item, "select")}
            >
              {item}
            </List.Item>
          )}
          className="text-area-select"
        ></List>
      );
    }

    return (
      <Row>
        <Col span={selectType ? 19 : 24}>
          {getFieldDecorator("ect", {
            rules: [
              {
                required: false,
              },
            ],
            initialValue: this.props.defaultValue,
          })(
            <Input.TextArea
              rows={rowColSet ? rowColSet : 4}
              onBlur={this.onBlur}
            />
          )}
        </Col>

        {dataSelect ? (
          <Col span={5} style={{ textAlign: "center" }}>
            <Popover
              placement={"topRight"}
              title={null}
              content={content}
              trigger="click"
              closable={true}
              maskClosable={false}
            >
              <Button
                type="primary"
                style={{
                  margin: "0 8px",
                  paddingRight: "8px",
                  paddingLeft: "8px",
                }}
              >
                選択
              </Button>
            </Popover>
          </Col>
        ) : null}
      </Row>
    );
  }
}

const TextareaKB = Form.create()(NormalTextareaKB);
export default TextareaKB;
