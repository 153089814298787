import React, { Fragment } from "react";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import {
  Input,
  Button,
  Popover,
  Table,
  Popconfirm,
  Divider,
  Modal,
  Row,
  Select,
  Col,
  Spin,
} from "antd";
import { connect } from "react-redux";
// import { convertArrStrToArrObj, convertSortArr } from '../../../common/function_common/functionDisplay';
import _ from "lodash";
import TextInputArea from "./TextInputArea";
import TextInput from "./TextInput";
import DrugMasterEditModal from "../component/control/DrugMasterEditModal";
import { updateListDrugCreator } from "../../actions/allMasterActions/actionCreators";
import {
  yougoMasterNaiyouNewCreator,
  yougoMasterUpdateNewCreator,
  yougoMasterKusuriCreator,
} from "../../actions/yougoMasterNaiyouActions/actionCreators";
import { resolveOnChange } from "antd/lib/input/Input";

class TextAreaSelectKusuri extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newText: "",
      visible: false,
      yougoVisible: false,
      rowActive: null,
      record: null,
      bunrui: "",
      name: "",
      drugVisibleEdit: false,
      drugEditType: null,
      selected: null,
      selectSort: false,
      masterSort: false,
      sortTable: [],
      sortTableCopy: [],
      data: [],
      Expansion: false,
    };

    // bind place
  }

  componentDidMount() {
    this.props.yougoMasterKusuri("薬名分類");
  }

  /**
   * 開いたときに検索結果の初期化
   *
   *
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible != this.state.visible && this.state.visible) {
      this.props.handleDrugSearch();
    }
    // if(prevProps.dataMaster != this.props.dataMaster && this.props.dataMaster){ //もらったプロップスが違うならdataにセット
    // }
  }

  /**
   * triiger from table select row (yougo_master)
   *
   */
  handleSelectChange = (value) => {
    this.props.handleInputSelect(value);
  };

  /**
   * Should provide an event to pass value to Form.
   *
   */
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    try {
      if (onChange) {
        onChange(changedValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleOk = () => {
    const { record, selected } = this.state;
    const { dataMasterType, value } = this.props;

    // this.props.handleInputSelect(record.name)
    this.props.handleInputSelect(selected.name);

    // let cloneRecord = _.cloneDeep(selected)

    // let tmp = '';
    // if (value) {
    //     // tmp = value + '、' + cloneRecord.name
    //     tmp = cloneRecord.name
    // } else {
    //     tmp = cloneRecord.name
    // }

    // this.triggerChange(tmp);

    // reset
    this.setState({
      visible: false,
      record: null,
      bunrui: "",
      name: "",
      rowActive: null,
      selected: null,
      Expansion: false,
    });
  };

  handleCancel = () => {
    // reset
    this.setState({
      visible: false,
      record: null,
      bunrui: "",
      name: "",
      rowActive: null,
      selected: null,
      selectSort: false,
      masterSort: false,
      sortTable: [],
      sortTableCopy: [],
      Expansion: false,
    });
  };

  handleOpenTable = () => {
    // open modal
    this.setState({
      visible: true,
      record: null,
      bunrui: "",
      name: "",
      Expansion: false,
    });
  };

  onClickCellEvent = (record) => {
    this.setState({
      rowActive: record.id,
      record: record,
    });
  };

  search = () => {
    const { bunrui, name } = this.state;

    this.props.handleDrugSearch(bunrui, name);
  };

  onChangeNameSearch = (value) => {
    this.setState({
      name: value,
    });
  };

  onChangeBunruiSearch = (value) => {
    const { name } = this.state;

    this.props.handleDrugSearch(value, name);

    this.setState({
      bunrui: value,
    });
  };

  ////////////////////////////////////モーダル関連

  drugHandleAdd() {
    this.setState({
      drugVisibleEdit: true,
      drugEditType: 1, // add
    });
  }

  drugHandleEdit() {
    this.setState({
      drugVisibleEdit: true,
      drugEditType: 2, // edit
    });
  }

  drugHandleClose() {
    this.search();

    this.setState({
      drugVisibleEdit: false,
      drugEditType: null,
    });
  }

  isDisableButtonDrug(selected) {
    return selected && this.props.drugList
      ? !this.props.drugList.some((item) => item.id == selected.id)
      : true;
  }

  handleSelectRow(record) {
    //選択したレコードをステートselectedに保存
    this.setState({
      selected: record,
    });
  }

  //追加モーダル
  masterSortOn = () => {
    const { drugList } = this.props;
    let tmp = JSON.parse(JSON.stringify(drugList));
    let tmp2 = JSON.parse(JSON.stringify(drugList));
    this.setState({
      masterSort: true,
      sortTable: tmp,
      sortTableCopy: tmp2,
    });
  };

  masterSortOff = () => {
    this.setState({
      masterSort: false,
      sortTable: [],
      sortTableCopy: [],
    });
  };

  masterSortSave = (key) => {
    const { sortTable, bunrui, name, sortTableCopy } = this.state;

    let tmp = [];
    for (let record of sortTable) {
      let defTable = sortTableCopy.find((t) => t.id == record.id);
      if (record.sortNum != defTable.sortNum) {
        tmp.push(record);
      }
    }
    this.props.updateListDrugCreator(tmp, (call) => {
      this.props.handleDrugSearch(bunrui, name);
    });

    this.setState({
      masterSort: false,
      sortTable: [],
    });
  };

  //kusuri
  masterSortTop = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const minNum = sortTable[0].sortNum;

    if (elementIndex != 0) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = minNum - 1;
      tableTmp.splice(0, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  masterSortUp = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id); //現在のindex

    if (elementIndex != 0) {
      const changeElement = tableTmp[elementIndex - 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex - 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        //移動予定のsortNumの値と上のレコードを比べる ※同じ番号があった場合の対策
        element.sortNum--;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum; //上のレコードのsortNumに変更する
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum++; //古いsortNumを変更
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  masterSortDown = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      const changeElement = tableTmp[elementIndex + 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex + 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum++;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum--;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  masterSortBottom = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    let maxNum = 0;
    for (let i of tableTmp) {
      maxNum < i.sortNum ? (maxNum = i.sortNum) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = maxNum + 1;
      tableTmp.splice(bottomIndex, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  handleExpandTab = () => {
    const { Expansion } = this.state;

    if (Expansion) {
      this.setState({
        Expansion: false,
      });
    } else {
      this.setState({
        Expansion: true,
      });
    }
  };

  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  render() {
    const {
      rowSet,
      value,

      // dataMaster,
      drugList,

      // ymKusurimeiBunrui,
      dataBunrui,
      dataExt,

      // loading,
      drugLoading,

      disabled,
      style,
      ymKusuri,
    } = this.props;

    const {
      newText,
      editIndex,
      visible,
      rowActive,
      record,
      name,
      yougoVisible,
      bunrui,
      masterSort,
      sortTable,
      Expansion,
    } = this.state;

    const titleDrug = (
      <div>
        <Row style={{ marginBottom: "4px" }}>
          <Col span={6}>薬分類</Col>
          <Col span={18}>
            <Select
              value={bunrui || null}
              style={{ width: "100%" }}
              onChange={this.onChangeBunruiSearch}
              disabled={masterSort == true ? true : false}
            >
              <Select.Option key={"top"} value={""}>
                {"\u00a0"}
              </Select.Option>
              {dataBunrui
                ? dataBunrui
                    .toString()
                    .split(";")
                    .map((item, intex) => {
                      return (
                        <Select.Option key={intex} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })
                : null}
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={6}>薬名</Col>
          <Col span={18} style={{ display: "flex" }}>
            <TextInput
              value={name}
              onChange={this.onChangeNameSearch}
              disabled={masterSort == true ? true : false}
            />
            <Button
              onClick={this.search}
              className={
                masterSort == false
                  ? "search-modal-kikan"
                  : "search-modal-kikan-none"
              }
              icon={<SearchOutlined />}
              disabled={masterSort == true ? true : false}
            >
              検索
            </Button>
          </Col>
        </Row>
        <Button
          key="add"
          type={"primary"}
          onClick={() => this.drugHandleAdd()}
          style={{ marginRight: 8, marginTop: 10 }}
          disabled={masterSort == true ? true : false}
        >
          追加
        </Button>
        <Button
          key="edit"
          type={"primary"}
          onClick={() => this.drugHandleEdit()}
          disabled={
            masterSort == true
              ? true
              : this.isDisableButtonDrug(this.state.record)
          }
          style={{ marginRight: 12 }}
        >
          修正
        </Button>
        {masterSort == false ? (
          <a
            key="sortDrug"
            // type={"primary"}
            onClick={() => this.masterSortOn()}
            style={{
              marginRight: 8,
              marginTop: 7,
              marginLeft: 10,
              color: "black",
            }}
          >
            並び替え
          </a>
        ) : (
          <span>
            <Button
              key="sortSaveDrug"
              type={"primary"}
              onClick={() => this.masterSortSave("drug")}
              style={{ marginRight: 8 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={() => this.masterSortOff()}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
          </span>
        )}
      </div>
    );
    const columnsDrug = Expansion //拡張
      ? [
          {
            title: "name",
            dataIndex: "name",
            width: 300,
            key: "name",
            render: (textEx, record, index) => (
              <div
                style={{
                  maxWidth: "290px",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {record.name}
              </div>
            ),
            onCell: (record) => {
              return {
                onClick: (event) => {
                  this.onClickCellEvent(record);
                }, // click row
              };
            },
          },
          {
            title: "bunrui",
            dataIndex: "bunrui",
            key: "bunrui",
            render: (textEx, record, index) => (
              <div
                style={{
                  maxWidth: "290px",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {record.bunrui}
              </div>
            ),
            onCell: (record) => {
              return {
                onClick: (event) => {
                  this.onClickCellEvent(record);
                }, // click row
              };
            },
          },
        ]
      : [
          {
            title: "name",
            dataIndex: "name",
            width: 300,
            key: "name",
            render: (textEx, record, index) => (
              <div
                style={{
                  maxWidth: "290px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {record.name}
              </div>
            ),
            onCell: (record) => {
              return {
                onClick: (event) => {
                  this.onClickCellEvent(record);
                }, // click row
              };
            },
          },
          {
            title: "bunrui",
            dataIndex: "bunrui",
            key: "bunrui",
            render: (textEx, record, index) => (
              <div
                style={{
                  maxWidth: "290px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {record.bunrui}
              </div>
            ),
            onCell: (record) => {
              return {
                onClick: (event) => {
                  this.onClickCellEvent(record);
                }, // click row
              };
            },
          },
        ];

    const columnsDrug2 = [
      {
        title: "name",
        dataIndex: "name",
        key: "name",
        width: "200px", //200
      },
      // {
      //     dataIndex: "sortNum",
      //     width: 40,
      // },
      {
        title: "行動",
        dataIndex: "operation",
        width: 100,
        key: "operation",
        render: (record, index) => {
          return (
            <div>
              <span>
                <a onClick={() => this.masterSortTop(index)} style={{}}>
                  <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortUp(index)} style={{}}>
                  <CaretUpOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortDown(index)} style={{}}>
                  <CaretDownOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortBottom(index)} style={{}}>
                  <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
              </span>
            </div>
          );
        },
      },
    ];

    return (
      (<div className="text-area-select-ri-page">
        <TextInputArea
          rows={rowSet ? rowSet : 1}
          className="text-area-select-text"
          value={value}
          onChange={this.handleSelectChange}
          disabled={disabled ? disabled : false}
          style={style}
        />
        <span className="text-area-select-btn">
          <span>
            <Button
              type="primary"
              disabled={disabled ? disabled : false}
              onClick={this.handleOpenTable}
              style={{ marginLeft: 10 }}
            >
              マスタ
            </Button>
          </span>
        </span>
        <Modal
          open={visible}
          className="modal-list"
          title={titleDrug}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleOk}
              disabled={record ? false : true}
            >
              OK
            </Button>,
          ]}
          closable={false}
        >
          <Spin spinning={drugLoading}>
            {masterSort === false ? (
              <Table
                dataSource={drugList}
                columns={columnsDrug}
                showHeader={false}
                className="riyou-meal-page modal-table"
                pagination={{ pageSize: 20 }} //true = 10
                scroll={{ y: 300 }}
                size="small"
                onRow={(record) => {
                  return {
                    onClick: () => this.handleSelectRow(record),
                  };
                }}
                rowClassName={(record) =>
                  rowActive != null && record.id === rowActive
                    ? "pljp-table-row-hover"
                    : ""
                }
                rowKey={(record, index) => index}
              />
            ) : masterSort === true ? (
              <Table
                dataSource={sortTable}
                columns={columnsDrug2}
                showHeader={false}
                className="riyou-meal-page modal-table"
                pagination={{ pageSize: 20 }} //true = 10
                scroll={{ y: 300 }}
                size="small"
                rowClassName={(record) =>
                  rowActive != null && record.id === rowActive
                    ? "pljp-table-row-hover"
                    : ""
                }
                rowKey={(record, index) => index}
              />
            ) : null}
          </Spin>
          <Button
            type="primary"
            icon={<LegacyIcon type={Expansion ? "minus" : "plus"} />}
            className="modal-expansion"
            onClick={this.handleExpandTab}
            disabled={masterSort == true ? true : false}
          >
            {Expansion ? "格納" : "展開"}
          </Button>
        </Modal>
        <DrugMasterEditModal
          kiroku={true}
          visible={this.state.drugVisibleEdit}
          data={this.state.selected}
          editType={this.state.drugEditType}
          handleSelectRow={(record) => this.handleSelectRow(record)}
          handleClose={() => this.drugHandleClose()}
          dataSource={this.props.drugList}
          dataBunrui={dataBunrui.split(";")}
          ymKusuri={this.props.ymKusuri}
          yougoData={this.props.yougoData}
          yougoMasterUpdate={this.yougoMasterUpdate} //コネクト
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // drugList: state.allMaster.drugMaster,
    // loading: state.allMaster.loading,
    yougoData: state.yougoMasterNaiyou.yougoData,
    ymKusuri: state.yougoMasterNaiyou.ymKusurimeiBunrui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateListDrugCreator: (data, callback) =>
      dispatch(updateListDrugCreator(data, callback)),
    yougoMasterNaiyou: (listName) =>
      dispatch(yougoMasterNaiyouNewCreator(listName)),
    yougoMasterKusuri: (listName) =>
      dispatch(yougoMasterKusuriCreator(listName)),
    yougoMasterUpdate: (key, naiyou) =>
      dispatch(yougoMasterUpdateNewCreator(key, naiyou)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextAreaSelectKusuri);
