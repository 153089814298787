import { GET_AVATAR_SUCCESS } from "../../actions/getAvatarActions/actionName.js";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
export default function getAvatar(state = {}, action) {
  switch (action.type) {
    case GET_AVATAR_SUCCESS:
      return { ...state, data: action.getAvatar };
    default:
      return state;
  }
}
