import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default class TableRowButton extends React.Component {
  constructor(props) {
    super(props);

    // bind place
    this.onClickEditRow = this.onClickEditRow.bind(this);
  }

  onClickEditRow() {
    this.props.editRow();
  }

  render() {
    const { type, riyousyaId } = this.props;
    const tabActive = localStorage.getItem("tabActive");
    return !type || type !== "user" ? (
      <span style={{ textAlign: "center" }} className={"table-row-bt"}>
        <Button type="primary">利用者詳細</Button>
        <Button type="primary">利用者別へ</Button>
        <Button type="primary">経過記録へ</Button>
        <Button type="primary" danger onClick={this.onClickEditRow}>
          記録修正
        </Button>
        <Button type="primary">ウィークリー</Button>
        <Button type="primary">個別予定</Button>
        <Button type="primary">計画書閲覧</Button>
      </span>
    ) : (
      <span style={{ textAlign: "center" }} className={"table-row-bt"}>
        <Button type="primary" danger onClick={this.onClickEditRow}>
          記録修正
        </Button>
        {tabActive && tabActive === "食事摂取" ? (
          <Button type="primary">
            <Link to={`/riyousya-shokuji/${riyousyaId}`}>食事せん</Link>
          </Button>
        ) : null}
      </span>
    );
  }
}
