import React from "react";
import { Row, Col, Divider } from "antd";
import LineBreakText from "../../../common/component/LineBreakText";

const ServiceInfo = (props) => {
  let items =
    props.riyousya &&
    props.riyousya.riyouService &&
    props.riyousya.riyouService.length > 0
      ? props.riyousya.riyouService
      : null;

  return (
    <div>
      {items
        ? items.map((item, index) => {
            return (
              <Row key={index}>
                {index > 0 ? <Divider /> : null}
                <Col sm={12}>
                  <Row>
                    <Col xs={6} className="rdp-basic-tab-title">
                      機関名
                    </Col>
                    <Col xs={18}>{item.kikanName}</Col>
                    <Divider />
                  </Row>
                  <Row>
                    <Col xs={6} className="rdp-basic-tab-title">
                      開始日
                    </Col>
                    <Col xs={16}>{item.kaishiDate}</Col>
                    <Divider />
                  </Row>
                  <Row>
                    <Col xs={6} className="rdp-basic-tab-title">
                      状況
                    </Col>
                    <Col xs={18}>{item.state}</Col>
                    <Divider />
                  </Row>
                  <Row>
                    <Col xs={6} className="rdp-basic-tab-title">
                      備考
                    </Col>
                    <Col xs={18}>
                      <LineBreakText text={item.bikou ? item.bikou : "　"} />
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col xs={6} className="rdp-basic-tab-title">
                      担当者
                    </Col>
                    <Col xs={18}>{item.tantoName}</Col>
                    <Divider />
                  </Row>
                  <Row>
                    <Col xs={6} className="rdp-basic-tab-title">
                      利用頻度
                    </Col>
                    <Col xs={18}>{item.hindo}</Col>
                    <Divider />
                  </Row>
                  <Row>
                    <Col xs={6} className="rdp-basic-tab-title">
                      連絡先
                    </Col>
                    <Col xs={18}>
                      <LineBreakText
                        text={item.contact ? item.contact : "　"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })
        : null}
    </div>
  );
};

export default ServiceInfo;
