import { checkTemplate } from "@pdfme/generator";
import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";
import _ from "lodash";

export const life_Kobetsu_InputFormV3PDF = (data, jobList, tantoMaster) => {
  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];

  // dataからキーの値が一致するオブジェクトを返す
  const generateOption2 = (options, key, value) => {
    const result = options?.find(obj => obj[key] === value);
    return result;
  };

  // 目標達成度
  const goalAchievementGenerate = (options, value) => {
    let result = ""; 
    if (options?.length > 0) {
      options.split(";").map((item2) => {
        if(item2?.split(":")[0] === value) {
          result = item2?.split(":")[1]
        }
      })
    }
    return result;
  }


  const generateGenderString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += item.label + " ";
      }
    });
    return result;
  };

  // コード＆病名
  const byoumeiGenerateOption = (options) => {
    let result = "";
    if (options) {
      options?.forEach((item) => {
        if(item.complicationsMedicd || item.msm_syoubyoumei) {
          result += `${item.complicationsMedicd ?? ""}` + "　" + `${item.msm_syoubyoumei ?? ""}` + "\n"
        }
      })
    }
    return result;
  }

  const complicationsOption = (data) => {
    const regex = /脳血管疾患|骨折|誤嚥性肺炎|うっ血性心不全|尿路感染症|糖尿病|高血圧症|骨粗しょう症|関節リウマチ|がん|うつ病|認知症|褥瘡/;
    let result = "";
    if (data) {
      result = data.split(",").filter(item => 
        item.match(regex)
      );
    }
    return result.toString();
  }

  const complicationsOtherOption = (data) => {
    const regex = /神経疾患|運動器疾患|呼吸器疾患|循環器疾患|消火器疾患|腎疾患|内分泌疾患|皮膚疾患|精神疾患|その他/;
    let result = "";
    if (data) {
      result = data.split(",").filter(item => 
        item.match(regex)
      );
    }
    return result.toString();
  }

  // コード＆訓練
  const trainingGenerateOption = (datas, key) => {
    let result = "";
    let content = "";
    if (datas?.length > 0) {
      const obj = datas.filter(obj => obj.kubun === key);
      if (obj?.length > 0) {
        obj.forEach((item) => {
          if(item.icfCd || item.im_koui) {
            result += `${item.icfCd ?? ""}` + "　" + `${item.im_koui ?? ""}` + "\n"
          }
          content += item.contents ?? "";
        })
        result += content;
      }
    }
    return result;
  }

  // プログラム内容
  const programGenerateOption = (datas, sortNum, name, lifeYougoList) => {
    let result = "";
    if (datas) {
      const obj = datas.find((item) => item.sortNum === sortNum)
      if (obj) {
        if (name === "プログラム内容") {
          const regex = RegExp(`${obj.sienCd}:\[^:;\]*`);
          const sienNaiyou = lifeYougoList?.match(regex);
          result = sienNaiyou?.toString();
        }
        if (name === "留意点") {
          result = obj.note ?? "";
        }
        if (name === "頻度") {
          result = obj.times ? `週 ${obj.times}回` : "";
        }
        if (name === "時間") {
          result = obj.min ? `${obj.min}分` : "";
        }
        if (name === "主な実施者") {
          result = formatJob(obj.personnel) ?? "";
        }
      }
    }
    return result;
  }

  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 78;
    const MAX_LINE = 10;
    let stringArr = string?.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    if (stringArr.length > 1) {
      stringArr.forEach((item) => {
        countOld = count;
        count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
        if (count < MAX_LINE) {
          result = result + item + "\n";
        } else {
          result = result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
          return;
        }
      });
    } else {
      stringArr.forEach((item) => {
        result = result + item.substring(0, (MAX_LINE - count) * LENGTH_LINE - 3);
        return;
      });
    }
    return result;
  };

  const dateCheckFormat = (date) => {
    if (date && date !== "0001-01-01") {
      return date.replace(/-/g, "/");
    }
    return "";
  };

  const formatJob = (num) => {
    let result = "";

    if (num && jobList?.naiyou?.split(";").length > 0) {
      jobList.naiyou?.split(";").map((item) => {
        if (item?.split(":")[0] == num) {
          result = item?.split(":")[1];
        }
      });
    }
    return result;
  };

  function formatSeireki(dateString) {
    if (dateString && dateString !== "0001-01-01") {
      const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
      const match = dateString.match(regex);
  
      if (match) {
        const year = parseInt(match[1]);
        const month = parseInt(match[2]);
        const day = parseInt(match[3]);
  
        return `${year}年${month}月${day}日`;
      }
    }
  
    return "";
  }

    const { kobetsu, lifeYougoList, kaigodo } = data;

    if (typeof kobetsu === 'object' && Array.isArray(kobetsu)) {
      let sampledata = [];
      kobetsu?.map((kobetsu) => {
        sampledata.push({
          作成日: formatSeireki(kobetsu.createDate) ?? "",
          前回作成日: formatSeireki(kobetsu.lastTimeCreateDate) ?? "",
          初回作成日: formatSeireki(kobetsu.firstTimeCreateDate) ?? "",
          ふりがな: kobetsu?.riyousya?.furigana ?? "",
          氏名1: kobetsu?.riyousya?.name ?? "",
          性別: `${generateGenderString(GENDER_OPTIONS, kobetsu?.riyousya?.gender ?? "")}`,
          生年月日: formatBirthday(kobetsu?.riyousya?.birthday) ?? "",
          要介護度: generateOption2(kaigodo, "kaigodoNo", kobetsu?.careLevel)?.kaigodoName ?? "",
          計画作成者: kobetsu?.createTanto?.name ?? "",
          職種: formatJob(kobetsu?.createTanto?.jobCategory) ?? "",
          障害老人の日常生活自立度: kobetsu?.impairedDegree ?? "",
          認知症老人の日常生活自立度: kobetsu?.dementiaDegree ?? "",
          利用者本人の希望: `${formatString10Line(kobetsu.userRequest) ?? ""}`,
          家族の希望: `${formatString10Line(kobetsu.userFamilyRequest) ?? ""}`,
          利用者本人の社会参加の状況: `${formatString10Line(kobetsu.userSocialParticipationNotice) ?? ""}`,
          利用者の居宅の環境: `${formatString10Line(kobetsu.userEnvironmentNotice) ?? ""}`,
          病名: `${kobetsu?.mainMedicd ?? ""}　${kobetsu?.mainSyoubyoumei ?? ""}`,
          "発症日・受傷日": formatSeireki(kobetsu.onsetDate) ?? "",
          直近の入院日: formatSeireki(kobetsu.latestAdmissionDate) ?? "",
          直近の退院日: formatSeireki(kobetsu.latestDischargeDate) ?? "",
          治療経過: `${formatString10Line(kobetsu.progress) ?? ""}`,
          合併疾患: complicationsOption(kobetsu.complications) ?? "",
          合併疾患他: complicationsOtherOption(kobetsu.complications) ?? "",
          機能訓練実施上の留意点: `${formatString10Line(kobetsu.implementationStatus) ?? ""}`,
          // 2ページ
          氏名2: kobetsu?.riyousya?.name ?? "",
          "目標達成度(短期)": goalAchievementGenerate(lifeYougoList.progress, kobetsu.shortGoalAchievement) ?? "",
          "機能(短期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "短期機能") ?? "",
          "活動(短期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "短期活動") ?? "",
          "参加(短期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "短期参加") ?? "",
          "目標達成度(長期)": goalAchievementGenerate(lifeYougoList.progress, kobetsu.longGoalAchievement) ?? "",
          "機能(長期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "長期機能") ?? "",
          "活動(長期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "長期活動") ?? "",
          "参加(長期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "長期参加") ?? "",
          プログラム内容1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "プログラム内容", lifeYougoList?.siennaiyou0300) ?? "",
          プログラム内容2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "プログラム内容", lifeYougoList?.siennaiyou0300) ?? "",
          プログラム内容3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "プログラム内容", lifeYougoList?.siennaiyou0300) ?? "",
          プログラム内容4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "プログラム内容", lifeYougoList?.siennaiyou0300) ?? "",
          留意点1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "留意点") ?? "",
          留意点2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "留意点") ?? "",
          留意点3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "留意点") ?? "",
          留意点4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "留意点") ?? "",
          頻度1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "頻度") ?? "",
          頻度2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "頻度") ?? "",
          頻度3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "頻度") ?? "",
          頻度4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "頻度") ?? "",
          時間1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "時間") ?? "",
          時間2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "時間") ?? "",
          時間3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "時間") ?? "",
          時間4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "時間") ?? "",
          主な実施者1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "主な実施者") ?? "",
          主な実施者2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "主な実施者") ?? "",
          主な実施者3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "主な実施者") ?? "",
          主な実施者4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "主な実施者") ?? "",
          プログラム立案者: kobetsu?.plannerTanto?.name ?? "",
          "利用者本人・家族等がサービス利用時間以外に実施すること": kobetsu.notNotice ?? "",
          特記事項: kobetsu.tokki ?? "",
          個別機能訓練の実施による変化: kobetsu.change ?? "",
          個別機能訓練実施における課題とその原因: kobetsu.reEvaluation ?? "",
          事業所番号: kobetsu.serviceKikanMaster.jigyousyoNo ?? "",
          事業所名: kobetsu.serviceKikanMaster.jigyousyoName ?? "",
          住所: kobetsu.serviceKikanMaster ? `${kobetsu.serviceKikanMaster.address1 ?? ""}　${kobetsu.serviceKikanMaster.address2 ?? ""}` : "",
          電話番号: kobetsu.serviceKikanMaster?.tel ?? "",
        })
      })

      return sampledata;
    } else if (typeof kobetsu === 'object') {
      console.log(data);
      let sampledata = [
        {
          作成日: formatSeireki(kobetsu.createDate) ?? "",
          前回作成日: formatSeireki(kobetsu.lastTimeCreateDate) ?? "",
          初回作成日: formatSeireki(kobetsu.firstTimeCreateDate) ?? "",
          ふりがな: kobetsu?.riyousya?.furigana ?? "",
          氏名1: kobetsu?.riyousya?.name ?? "",
          性別: `${generateGenderString(GENDER_OPTIONS, kobetsu?.riyousya?.gender ?? "")}`,
          生年月日: formatBirthday(kobetsu?.riyousya?.birthday) ?? "",
          要介護度: generateOption2(kaigodo, "kaigodoNo", kobetsu?.careLevel)?.kaigodoName ?? "",
          計画作成者: kobetsu?.createTanto?.name ?? "",
          職種: formatJob(kobetsu?.createTanto?.jobCategory) ?? "",
          障害老人の日常生活自立度: kobetsu?.impairedDegree ?? "",
          認知症老人の日常生活自立度: kobetsu?.dementiaDegree ?? "",
          利用者本人の希望: `${formatString10Line(kobetsu.userRequest) ?? ""}`,
          家族の希望: `${formatString10Line(kobetsu.userFamilyRequest) ?? ""}`,
          利用者本人の社会参加の状況: `${formatString10Line(kobetsu.userSocialParticipationNotice) ?? ""}`,
          利用者の居宅の環境: `${formatString10Line(kobetsu.userEnvironmentNotice) ?? ""}`,
          病名: `${kobetsu?.mainMedicd ?? ""}　${kobetsu?.mainSyoubyoumei ?? ""}`,
          "発症日・受傷日": formatSeireki(kobetsu.onsetDate) ?? "",
          直近の入院日: formatSeireki(kobetsu.latestAdmissionDate) ?? "",
          直近の退院日: formatSeireki(kobetsu.latestDischargeDate) ?? "",
          治療経過: `${formatString10Line(kobetsu.progress) ?? ""}`,
          合併疾患: complicationsOption(kobetsu?.complications) ?? "", //byoumeiGenerateOption(kobetsu?.complications) ?? "",
          合併疾患他: complicationsOtherOption(kobetsu?.complications) ?? "",
          機能訓練実施上の留意点: `${formatString10Line(kobetsu.implementationStatus) ?? ""}`,
          // 2ページ
          氏名2: kobetsu?.riyousya?.name ?? "",
          "目標達成度(短期)": goalAchievementGenerate(lifeYougoList.progress, kobetsu.shortGoalAchievement) ?? "",
          "機能(短期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "短期機能") ?? "",

          "活動(短期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "短期活動") ?? "",

          "参加(短期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "短期参加") ?? "",

          "目標達成度(長期)": goalAchievementGenerate(lifeYougoList.progress, kobetsu.longGoalAchievement) ?? "",
          "機能(長期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "長期機能") ?? "",

          "活動(長期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "長期活動") ?? "",

          "参加(長期)": trainingGenerateOption(kobetsu?.goalFunctionalTraining, "長期参加") ?? "",

          プログラム内容1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "プログラム内容", lifeYougoList?.siennaiyou0300) ?? "",
          プログラム内容2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "プログラム内容", lifeYougoList?.siennaiyou0300) ?? "",
          プログラム内容3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "プログラム内容", lifeYougoList?.siennaiyou0300) ?? "",
          プログラム内容4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "プログラム内容", lifeYougoList?.siennaiyou0300) ?? "",
          留意点1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "留意点") ?? "",
          留意点2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "留意点") ?? "",
          留意点3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "留意点") ?? "",
          留意点4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "留意点") ?? "",
          頻度1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "頻度") ?? "",
          頻度2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "頻度") ?? "",
          頻度3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "頻度") ?? "",
          頻度4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "頻度") ?? "",
          時間1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "時間") ?? "",
          時間2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "時間") ?? "",
          時間3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "時間") ?? "",
          時間4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "時間") ?? "",
          主な実施者1: programGenerateOption(kobetsu.kobetukinouKunren2Program, 1, "主な実施者") ?? "",
          主な実施者2: programGenerateOption(kobetsu.kobetukinouKunren2Program, 2, "主な実施者") ?? "",
          主な実施者3: programGenerateOption(kobetsu.kobetukinouKunren2Program, 3, "主な実施者") ?? "",
          主な実施者4: programGenerateOption(kobetsu.kobetukinouKunren2Program, 4, "主な実施者") ?? "",
          プログラム立案者: kobetsu?.plannerTanto?.name ?? "",
          "利用者本人・家族等がサービス利用時間以外に実施すること": kobetsu.notNotice ?? "",
          特記事項: kobetsu.tokki ?? "",
          個別機能訓練の実施による変化: kobetsu.change ?? "",
          個別機能訓練実施における課題とその原因: kobetsu.reEvaluation ?? "",
          事業所番号: kobetsu.serviceKikanMaster.jigyousyoNo ?? "",
          事業所名: kobetsu.serviceKikanMaster.jigyousyoName ?? "",
          住所: kobetsu.serviceKikanMaster ? `${kobetsu.serviceKikanMaster.address1 ?? ""}　${kobetsu.serviceKikanMaster.address2 ?? ""}` : "",
          電話番号: kobetsu.serviceKikanMaster?.tel ?? "",
        },
      ];

      return sampledata;
    } else {
      return [{}];
    }
};
