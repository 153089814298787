/* eslint-disable react/prop-types */
import { CaretRightOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Input, Modal, Row, notification } from "antd";
import React from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import HokenTable1 from "./UI/HokenTable1";
import HokenTable2 from "./UI/HokenTable2";
import HokenTable3 from "./UI/HokenTable3";
import HokenTable4 from "./UI/HokenTable4";
import ItakuTantoMasterModal from "./UI/ItakuTantoMasterModal";
import ServiceKikanHoken from "./UI/ServiceKikanHoken";

const { Panel } = Collapse;

const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 0,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

export default class RiyousyaHoken extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const query = new URLSearchParams(this.props.location.search);

    this.state = {
      riyousyaId: match.params.riyousyaId,
      url: match.url,
      backLink: query.get("backLink"),
      page1No: 1,
      page2No: 1,
      page3No: 1,
      page4No: 1,
      isChange: false,
      visibleConfirm: false,
      isVisibleSKW: false,
      isVisibleITM: false,
      jigyousyoName: "",
      changeJigyousyoName: "",
      jigyousyoNo: "",
      jigyousyo: null,
      changeJigyousyo: null,
      skmId: -1,
      changeSkmId: "",
      itakuTantoMasterName: "",
      changeItakuTantoMasterName: "",
      itmId: -1,
      changeItemId: "",
      itakuId: "",
    };

    // bind place
  }

  /**
   *
   */
  componentDidMount() {
    const { riyousyaId } = this.state;
    this.props.getRiyousya(riyousyaId);

    const page = 1;
    this.props.getKaigoHoken(page, riyousyaId);
    this.props.getKohiJoho(page, riyousyaId);
    this.props.getListIryouHoken(page, riyousyaId);
    this.props.getListKougakuIryouhi(page, riyousyaId);
    this.props.serviceRyuruiMasterList();
    // this.props.serviceKikanMasterList("43", ""); //保険者情報で使う

    // load yougo master setting
    this.props.ymShokujiPage();

    // this.props.getKaigodo();
    // this.serviceKikanMasterSerch("43", "")
  }

  /**
   *
   */
  componentDidUpdate(preProps) {
    const { riyousyaDetail, ishokenUpdating, ishokenMessage } = this.props;
    const { page1No, page2No, page3No, page4No, riyousyaId } = this.state;

    // update or delete後、各レコード読み込み
    if (preProps.hokenAbove !== this.props.hokenAbove) {
      if (this.props.hokenLoading != preProps.hokenLoading) {
        this.props.getKaigoHoken(page1No, riyousyaId);
      }
    }
    if (preProps.hokenBelow !== this.props.hokenBelow) {
      if (this.props.kohiLoading != preProps.kohiLoading) {
        this.props.getKohiJoho(page2No, riyousyaId);
      }
    }
    if (preProps.iryouhoken !== this.props.iryouhoken) {
      if (this.props.iryouHokenLoading != preProps.iryouHokenLoading) {
        this.props.getListIryouHoken(page3No, riyousyaId);
      }
    }
    if (preProps.kougakuIryouhi !== this.props.kougakuIryouhi) {
      if (this.props.kougakuIryouhiLoading != preProps.kougakuIryouhiLoading) {
        this.props.getListKougakuIryouhi(page4No, riyousyaId);
      }
    }

    if (riyousyaDetail !== preProps.riyousyaDetail) {
      this.setState({
        recordUpdate: riyousyaDetail,
        jigyousyo:
          riyousyaDetail && riyousyaDetail.itakuTanto
            ? riyousyaDetail.itakuTanto
            : null,
        itakuId:
          riyousyaDetail && riyousyaDetail.itakuTanto
            ? riyousyaDetail.itakuTanto.id
            : "",
        jigyousyoName:
          riyousyaDetail && riyousyaDetail.itakuTanto
            ? riyousyaDetail.itakuTanto.jigyousyoName
            : "",
        jigyousyoNo:
          riyousyaDetail && riyousyaDetail.itakuTanto
            ? riyousyaDetail.itakuTanto.jigyousyoNo
            : "",
        skmId:
          riyousyaDetail && riyousyaDetail.itakuTanto
            ? riyousyaDetail.itakuTanto.id
            : -1,
        itakuTantoMasterName:
          riyousyaDetail && riyousyaDetail.kyotakuTanto
            ? riyousyaDetail.kyotakuTanto.name
            : "",
        itmId:
          riyousyaDetail && riyousyaDetail.kyotakuTanto
            ? riyousyaDetail.kyotakuTanto.id
            : -1,
      });
    }

    if (ishokenUpdating && ishokenMessage.type) {
      this.openNotificationWithIcon(
        ishokenMessage.type,
        ishokenMessage.message
      );
      this.props.riyousyahokenReset();
    }
  }

  serviceKikanMasterSerch = (bunrui, name) => {
    this.props.serviceKikanMasterList(bunrui, name);
  };

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message,
      placement: "topRight",
      duration: 1,
      prefixCls: "pljp-notification",
    });
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.state.isChange) {
      this.showGoBackConfirm();
    }
    else {
      // this.props.riyousyahokenReset();
      this.props.history.goBack();
    }
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack = async () => {
    this.setState({
      visibleConfirm: false,
    });
    // this.props.riyousyahokenReset();
    this.props.history.goBack();
  };

  // 各レコード削除
  handleDelHokenTable1 = async (id, index) => {
    const { page1No, riyousyaId } = this.state;
    await this.props.deleteKaigoHoken(id, index);

    setTimeout(() => {
      this.props.getKaigoHoken(page1No, riyousyaId);
    }, 300);
  };
  handleDelHokenTable2 = async (id, index) => {
    const { page2No, riyousyaId } = this.state;
    await this.props.deleteKohiJoho(id, index);

    setTimeout(() => {
      this.props.getKohiJoho(page2No, riyousyaId);
    }, 300);
  };
  handleDelHokenTable3 = async (id, index) => {
    const { page3No, riyousyaId } = this.state;
    await this.props.deleteIryouHoken(id, index);

    setTimeout(() => {
      this.props.getListIryouHoken(page3No, riyousyaId);
    }, 300);
  };
  handleDelHokenTable4 = async (id, index) => {
    const { page4No, riyousyaId } = this.state;
    await this.props.deleteKougakuIryouhi(id, index);

    setTimeout(() => {
      this.props.getListKougakuIryouhi(page4No, riyousyaId);
    }, 300);
  };

  // 各フォーム保存
  updateHokenTable1 = (formData) => {
    this.props.updateKaigoHoken(formData);
  };
  updateHokenTable2 = (formData) => {
    this.props.updateKohiJoho(formData);
  };
  updateHokenTable3 = (formData) => {
    this.props.updateIryouHoken(formData);
  };
  updateHokenTable4 = (formData) => {
    this.props.updateKougakuIryouhi(formData);
  };

  /**
   * Update yougoMaster
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  // 各テーブルページ切り替え読み込み
  onChangePage1 = (page) => {
    const { riyousyaId } = this.state;
    this.setState({
      page1No: page,
    });
    this.props.getKaigoHoken(page, riyousyaId);
  };
  onChangePage2 = (page) => {
    const { riyousyaId } = this.state;
    this.setState({
      page2No: page,
    });
    this.props.getKohiJoho(page, riyousyaId);
  };
  onChangePage3 = (page) => {
    const { riyousyaId } = this.state;
    this.setState({
      page3No: page,
    });
    this.props.getListIryouHoken(page, riyousyaId);
  };
  onChangePage4 = (page) => {
    const { riyousyaId } = this.state;
    this.setState({
      page4No: page,
    });
    this.props.getListKougakuIryouhi(page, riyousyaId);
  };

  showModalIsVisibleSKW = () => {
    this.setState({
      isVisibleSKW: true,
    });
  };

  // 委託先モーダルOK
  onSaveServiceKikan = (key, record) => {
    if (record) {
      this.setState({
        isVisibleSKW: false,
        jigyousyoName: record.jigyousyoName,
        changeJigyousyoName: record.jigyousyoName,
        jigyousyoNo: record.jigyousyoNo,
        // skmId: record.id,
        changeSkmId: record.id,
        itakuId: record.id,
        isChange: true,
        changeJigyousyo: record,
      });
    }
  };

  onCancelServiceKikan = () => {
    this.setState({
      isVisibleSKW: false,
    });
  };

  showModalIsVisibleITM = () => {
    this.setState({
      isVisibleITM: true,
    });
  };

  // 居宅担当者モーダルOK
  onSaveItakutanto = (record) => {
    if (record) {
      this.setState({
        isVisibleITM: false,
        itakuTantoMasterName: record.name,
        changeItakuTantoMasterName: record.name,
        changeItemId: record.id,
        // itmId: record.id,
        isChange: true,
      });
    }
  };

  onCancelItakuTanto = () => {
    this.setState({
      isVisibleITM: false,
    });
  };

  saveRiyousya = () => {
    const { skmId, changeSkmId, itmId, changeItemId, riyousyaId } = this.state;

    if (
      (changeSkmId && changeSkmId !== skmId) ||
      (changeItemId && changeItemId !== itmId)
    ) {
      const params = {
        id: riyousyaId,
        itakuSid: changeSkmId ? changeSkmId : skmId,
        kyotakuTantoId: changeItemId ? changeItemId : itmId,
      };
      this.props.updateOpenRiyousya(params);
    }
    else if (skmId > 0 && itmId > 0) {
      const params = {
        id: riyousyaId,
        itakuSid: skmId,
        kyotakuTantoId: itmId,
      };
      this.props.updateOpenRiyousya(params);
    }

    this.setState({
      changeItemId: "",
      changeSkmId: "",
      changeJigyousyoName: "",
      changeItakuTantoMasterName: "",
      isChange: false,
    });
  };

  render() {
    const {
      sessionTimeout,
      riyousyaDetail,

      hokenAbove,
      pageHokenAbove,
      pageTotalHokenAbove,

      hokenBelow,
      pageHokenBelow,
      pageTotalHokenBelow,

      iryouHokenLoading,
      iryouhoken,
      pageIryouhoken,
      pageTotalIryouhoken,

      kougakuIryouhiLoading,
      kougakuIryouhi,
      pageKougakuIryouhi,
      pageTotalKougakuIryouhi,

      serviceSyuruiMaster,
      serviceKikanMaster,

      kaigodoMaster,

      duplicationRiyousya,
    } = this.props;

    const {
      riyousyaId,
      changeJigyousyoName,
      changeItakuTantoMasterName,
      changeItemId,
      itakuId,
      changeJigyousyo,
    } = this.state;
    return (
      (<CommonPageContainer
        id="riyousya-hoken-page"
        sessionTimeout={sessionTimeout}
      >
        <Row className={"submenu"}>
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={"利用者保険情報"}
                        iconType="user"
                      />
                    }
                    TopRight={
                      <div className="submenu-t-r">
                        <Link
                          to={"/"}
                          onClick={this.handleGoBack}
                          className="sub-top-right-item"
                        >
                          <span>
                            <UndoOutlined />
                          </span>
                          <span>戻る</span>
                        </Link>
                        {/* <Link
                                                to={'/'}
                                                className="sub-top-right-item"
                                                onClick={this.handleModify}
                                            >
                                                <span><Icon type="save" theme="outlined" style={{ color: "red" }} /></span>
                                                <span>登録</span>
                                            </Link> */}
                      </div>
                    }
                  />
                );
              }
              /**
                 * @TODO design UI for mobile
                 *
                 * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
                 */
              return null;

            }}
          </MediaQuery>
        </Row>
        <div className="riyousya-shokuji-content">
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <Row>
              <Col md={8}>
                <h3 style={{ marginTop: 16 }}>
                  利用者名：
                  {`${riyousyaDetail ? riyousyaDetail.name : ""}`}
                </h3>
              </Col>
              <Col md={14}>
                <Row style={{ display: "flex" }}>
                  <Col md={6}>
                    <p className="tittle-header" style={{ fontWeight: "bold" }}>
                      委託先
                    </p>
                  </Col>
                  <Col md={14}>
                    <Input.TextArea
                      className="jirituSogaiYoin-input"
                      rows={1}
                      element={"jirituSogaiYoin"}
                      // onChange={changeJirituSogaiYoin}
                      // value={jirituSogaiYoin}
                      spellCheck="false"
                      value={
                        changeJigyousyoName
                          ? changeJigyousyoName
                          : this.state.jigyousyoName
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <Button
                      type="primary"
                      className="Issue-Summary__button"
                      onClick={this.showModalIsVisibleSKW}
                    >
                      M
                    </Button>
                    <ServiceKikanHoken
                      visible={this.state.isVisibleSKW}
                      hideModal={this.onCancelServiceKikan}
                      serviceType={"43"}
                      serviceSyurui={serviceSyuruiMaster}
                      onSaveServiceKikan={this.onSaveServiceKikan}
                    />
                  </Col>
                </Row>
                <Row style={{ display: "flex" }}>
                  <Col md={6}>
                    <p className="tittle-header" style={{ fontWeight: "bold" }}>
                      居宅担当者
                    </p>
                  </Col>
                  <Col md={14}>
                    <Input.TextArea
                      className="jirituSogaiYoin-input"
                      rows={1}
                      element={"jirituSogaiYoin"}
                      // onChange={changeJirituSogaiYoin}
                      value={
                        changeItakuTantoMasterName
                          ? changeItakuTantoMasterName
                          : this.state.itakuTantoMasterName
                      }
                      spellCheck="false"
                    />
                  </Col>
                  <Col md={4}>
                    <Button
                      type="primary"
                      className="Issue-Summary__button"
                      onClick={this.showModalIsVisibleITM}
                    >
                      M
                    </Button>
                    {/* <ItakuTantoMasterModal
                                            visible={this.state.isVisibleITM}
                                            hideModal={this.onCancelItakuTanto}

                                            onSaveServiceKikan={this.onSaveItakutanto}
                                        /> */}
                    <ItakuTantoMasterModal
                      itakuId={itakuId}
                      visible={this.state.isVisibleITM}
                      onSelect={this.onSaveItakutanto}
                      onHide={this.onCancelItakuTanto}
                      getServiceKikanMasterList={this.serviceKikanMasterSerch}
                      serviceKikanMaster={serviceKikanMaster}
                      serviceSyurui={serviceSyuruiMaster}
                      selectJigyousy={
                        changeJigyousyo ? changeJigyousyo : this.state.jigyousyo
                      }
                    />
                  </Col>
                </Row>
              </Col>
              {
                // riyousyaDetail && (!riyousyaDetail.kyotakuTanto || !riyousyaDetail.itakuTanto)
                //     ?
                <Col md={2}>
                  <Button
                    type="primary"
                    size="large"
                    style={{ marginTop: 8 }}
                    onClick={this.saveRiyousya}
                    disabled={
                      !(this.state.changeSkmId > 0 && changeItemId > 0)
                    }
                  >
                    保存
                  </Button>
                </Col>
                // :
                // null
              }
            </Row>
          </div>

          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            defaultActiveKey={["1", "2", "3", "4"]}
          >
            <Panel header="介護保険情報" key="1" style={customPanelStyle}>
              <HokenTable1
                data={hokenAbove}
                page={pageHokenAbove}
                pageTotal={pageTotalHokenAbove}
                loading={this.props.hokenLoading}
                handleDelHokenTable={this.handleDelHokenTable1}
                updateHokenTable1={this.updateHokenTable1}
                riyousyaId={riyousyaId}
                serviceKikanMaster={serviceKikanMaster}
                serviceSyurui={serviceSyuruiMaster}
                kaigodoMaster={kaigodoMaster}
                duplicationRiyousya={duplicationRiyousya}
                // yougoMasterUpdate={this.yougoMasterUpdate}
                onChangeKaigoPag={this.onChangePage1}
                getJigyousyoName={this.props.getJigyousyoName}
                getHokenName={this.props.getHokenName}
                loadKaigoHoken={this.props.loadKaigoHoken}
                getServiceKikanMasterList={this.serviceKikanMasterSerch}
                checkHokenNo={this.props.checkHokenNo} // 重複チェック
              />
            </Panel>
            <Panel header="公費情報" key="2" style={customPanelStyle}>
              <HokenTable2
                data={hokenBelow}
                page={pageHokenBelow}
                pageTotal={pageTotalHokenBelow}
                loading={this.props.kohiLoading}
                handleDelHokenTable={this.handleDelHokenTable2}
                updateHokenTable2={this.updateHokenTable2}
                riyousyaId={riyousyaId}
                yougoMasterUpdate={this.yougoMasterUpdate}
                onChangekohiPag={this.onChangePage2}
                getHokenName={this.props.getHokenName}
                // loadKohifutan={this.props.loadKohifutan}
                getKohiMaster={this.props.getKohiMaster}
                getKohifutanMaster={this.props.getKohifutanMaster}
              />
            </Panel>
            <Panel header="医療保険情報" key="3" style={customPanelStyle}>
              <HokenTable3
                data={iryouhoken}
                page={pageIryouhoken}
                pageTotal={pageTotalIryouhoken}
                loading={iryouHokenLoading}
                handleDelHokenTable={this.handleDelHokenTable3}
                updateHokenTable={this.updateHokenTable3}
                riyousyaId={riyousyaId}
                onChangePage={this.onChangePage3}
                getIryouhokenMaster={this.props.getIryouhokenMaster}
              />
            </Panel>
            <Panel
              header="高額医療費負担上限月額"
              key="4"
              style={customPanelStyle}
            >
              <HokenTable4
                data={kougakuIryouhi}
                page={pageKougakuIryouhi}
                pageTotal={pageTotalKougakuIryouhi}
                loading={kougakuIryouhiLoading}
                handleDelHokenTable={this.handleDelHokenTable4}
                updateHokenTable={this.updateHokenTable4}
                riyousyaId={riyousyaId}
                onChangePage={this.onChangePage4}
              // getHokenName={this.props.getHokenName}
              />
            </Panel>
          </Collapse>
        </div>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </CommonPageContainer>)
    );
  }
}
