/* eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
import Tools from "./tools";

const fabric = require("fabric").fabric;

class People1 extends FabricCanvasTool {
  constructor(canvas, svg) {
    super(canvas);
    this.svg = svg;
  }

  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => o.selectable = o.evented = false);
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.call_back = props.update_select;
  }

  doMouseDown(o) {
    const canvas = this._canvas;
    this.isDown = true;
    const pointer = canvas.getPointer(o.e);
    this.startX = pointer.x;
    this.startY = pointer.y;

    // canvas.item(0).sourcePath = './img/left_.svg';

    fabric.loadSVGFromString(this.svg, function (objects, options) {
      const obj = fabric.util.groupSVGElements(objects, options);
      canvas.add(obj).renderAll();
    });
  }

  doMouseMove(o) {
    // if (!this.isDown) return;
    // let canvas = this._canvas;
    // let pointer = canvas.getPointer(o.e);
    // this.groupc.set(
    //     {
    //         scaleX: pointer.x / this.startX,
    //         scaleY: pointer.x / this.startX,
    //     }
    // );

    // this.groupc.setCoords();
    // canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.call_back();
  }
}

export default People1;