import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";

const KanriApi = {
  /**
   * START: USER RIYOUSYA LINK API
   * @param {*} ids
   */
  deleteURL(ids) {
    return axios(apiUrls.INIT + apiUrls.DEL_USER_RIYOUSYA_LINK, {
      method: "DELETE",
      data: {
        ids: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateURL(params) {
    return axios(apiUrls.INIT + apiUrls.PUT_USER_RIYOUSYA_LINK, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createURL(params) {
    return axios(apiUrls.INIT + apiUrls.POST_USER_RIYOUSYA_LINK, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getURLList() {
    return axios(apiUrls.INIT + apiUrls.KANRI_USERRIYOUSYALINK_LIST, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postURLList(text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_USERRIYOUSYALINK_LIST, {
      method: "POST",
      data: {
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
  //END USERRIYOUSYALINK KANRI API

  /**
   * START: TANTO MASTER API
   * @param {*} ids
   */
  deleteTM(ids) {
    return axios(apiUrls.INIT + apiUrls.DEL_TANTO_MASTER, {
      method: "DELETE",
      data: {
        ids: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateTM(params) {
    return axios(apiUrls.INIT + apiUrls.PUT_TANTO_MASTER, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createTM(params) {
    return axios(apiUrls.INIT + apiUrls.POST_TANTO_MASTER, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getTMList() {
    return axios(apiUrls.INIT + apiUrls.KANRI_TANTOMASTER_LIST, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postTMList(text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_TANTOMASTER_LIST, {
      method: "POST",
      data: {
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
  //END TANTOMASTER KANRI API

  /**
   * START: YOUGO MASTER API
   * @param {*} ids
   */
  deleteYM(ids) {
    return axios(apiUrls.INIT + apiUrls.DEL_YOUGO_MASTER, {
      method: "DELETE",
      data: {
        ids: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateYM(params) {
    return axios(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createYM(params) {
    return axios(apiUrls.INIT + apiUrls.POST_YOUGO_MASTER, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getYMList() {
    return axios(apiUrls.INIT + apiUrls.KANRI_YOUGOMASTER_LIST, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postYMList(text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_YOUGOMASTER_LIST, {
      method: "POST",
      data: {
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
  //END YOUGOMASTER KANRI API

  /**
   * START: LIFE YOUGO MASTER API
   * @param {*} ids
   */
  deleteLYM(ids) {
    return axios(apiUrls.INIT + apiUrls.DEL_LIFE_YOUGO_MASTER, {
      method: "DELETE",
      data: {
        ids: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateLYM(params) {
    return axios(apiUrls.INIT + apiUrls.PUT_LIFE_YOUGO_MASTER, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createLYM(params) {
    return axios(apiUrls.INIT + apiUrls.POST_LIFE_YOUGO_MASTER, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getLYMList() {
    return axios(apiUrls.INIT + apiUrls.KANRI_LIFEYOUGOMASTER_LIST, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postLYMList(text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_LIFEYOUGOMASTER_LIST, {
      method: "POST",
      data: {
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
  //END LIFEYOUGOMASTER KANRI API

  /**
   * START: KAIGOYOUGOMASTER kanri api
   * @param {*} ids
   */
  deleteKYM(ids) {
    return axios(apiUrls.INIT + apiUrls.KYM_DELETE, {
      method: "DELETE",
      data: {
        kymIds: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateKYM(params) {
    return axios(apiUrls.INIT + apiUrls.KYM_UPDATE, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createKYM(params) {
    return axios(apiUrls.INIT + apiUrls.KYM_ADD, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getKYMList(current, pageSize) {
    return axios(
      apiUrls.INIT +
        apiUrls.KANRI_KYM_LIST +
        `?pageNo=${current && current > 1 ? current : 1}&pageSize=1000`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postKYMList(current, pageSize, text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_KYM_LIST, {
      method: "POST",
      data: {
        pageNo: current.toString(),
        perPage: "1000",
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postKYMByIds(ids) {
    return axios(apiUrls.INIT + apiUrls.KANRI_KYM_BY_IDS, {
      method: "POST",
      data: {
        pageNo: "1",
        perPage: "100",
        kymIds: ids.toString(),
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getKYMSingleRecord(id, ids, type) {
    return axios(apiUrls.INIT + apiUrls.KANRI_KYM_SINGLE_RECORD, {
      method: "POST",
      data: {
        id: id.toString(),
        ids: ids ? ids.toString() : "",
        type: type,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  /**
   * START: SETTING MASTER API
   * @param {*} ids
   */
  deleteSM(ids) {
    return axios(apiUrls.INIT + apiUrls.DEL_SETTING_MASTER, {
      method: "DELETE",
      data: {
        ids: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateSM(params) {
    return axios(apiUrls.INIT + apiUrls.PUT_SETTING_MASTER, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createSM(params) {
    return axios(apiUrls.INIT + apiUrls.POST_SETTING_MASTER, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getSMList() {
    return axios(apiUrls.INIT + apiUrls.KANRI_SETTINGMASTER_LIST, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postSMList(text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_SETTINGMASTER_LIST, {
      method: "POST",
      data: {
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
  //END SETTINGMASTER KANRI API

  /**
   * START: User kanri api
   * @param {*} ids
   */
  deleteUser(ids) {
    return axios(apiUrls.INIT + apiUrls.USER_DEL, {
      method: "DELETE",
      data: {
        ids: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateUser(params) {
    return axios(apiUrls.INIT + apiUrls.USER_UPDATE, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createUser(params) {
    return axios(apiUrls.INIT + apiUrls.USER_ADD, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getUserList() {
    return axios(apiUrls.INIT + apiUrls.KANRI_USERS_LIST, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postUserList(text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_USERS_LIST, {
      method: "POST",
      data: {
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
  //END USER KANRI API

  /**
   * START: File kanri api
   * @param {*} ids
   */
  deleteFile(ids) {
    return axios(apiUrls.INIT + apiUrls.FILE_DEL_MUL, {
      method: "DELETE",
      data: {
        fileManagementIds: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateFile(params) {
    return axios(apiUrls.INIT + apiUrls.FILE_UPDATE, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createFile(params) {
    return axios(apiUrls.INIT + apiUrls.FILE_ADD, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getFileList(current, pageSize) {
    return axios(
      apiUrls.INIT +
        apiUrls.KANRI_FILES_LIST +
        `?pageNo=${current && current > 1 ? current : 1}&pageSize=1000`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postFileList(current, pageSize, text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_FILES_LIST, {
      method: "POST",
      data: {
        pageNo: current.toString(),
        perPage: "1000",
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postFileByIds(ids) {
    return axios(apiUrls.INIT + apiUrls.KANRI_FILES_BY_IDS, {
      method: "POST",
      data: {
        pageNo: "1",
        perPage: "100",
        fileIds: ids.toString(),
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getFileSingleRecord(id, ids, type) {
    return axios(apiUrls.INIT + apiUrls.KANRI_FILES_SINGLE_RECORD, {
      method: "POST",
      data: {
        id: id.toString(),
        ids: ids ? ids.toString() : "",
        type: type,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  /**
   * START: Dengon kanri api
   * @param {*} ids
   */
  deleteDengon(ids) {
    return axios(apiUrls.INIT + apiUrls.DENGON_DELETE, {
      method: "DELETE",
      data: {
        dengonIds: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateDengon(params) {
    return axios(apiUrls.INIT + apiUrls.DENGON_UPDATE, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createDengon(params) {
    return axios(apiUrls.INIT + apiUrls.DENGON_ADD, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getDengonList(current, pageSize) {
    return axios(
      apiUrls.INIT +
        apiUrls.KANRI_DENGON_LIST +
        `?pageNo=${current && current > 1 ? current : 1}&pageSize=1000`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postDengonList(current, pageSize, text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_DENGON_LIST, {
      method: "POST",
      data: {
        pageNo: current.toString(),
        perPage: "1000",
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postDengonByIds(ids) {
    return axios(apiUrls.INIT + apiUrls.KANRI_DENGON_BY_IDS, {
      method: "POST",
      data: {
        pageNo: "1",
        perPage: "100",
        dengonIds: ids.toString(),
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getDengonSingleRecord(id, ids, type) {
    return axios(apiUrls.INIT + apiUrls.KANRI_DENGON_SINGLE_RECORD, {
      method: "POST",
      data: {
        id: id.toString(),
        ids: ids ? ids.toString() : "",
        type: type,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
  //END DENGON KANRI API

  /**
   * START: Moshiokuri kanri api
   * @param {*} ids
   */
  deleteMoshiokuri(ids) {
    return axios(apiUrls.INIT + apiUrls.MOSHIOKURI_DELETE, {
      method: "DELETE",
      data: {
        moshiokuriIds: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateMoshiokuri(params) {
    return axios(apiUrls.INIT + apiUrls.MOSHIOKURI_UPDATE, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createMoshiokuri(params) {
    return axios(apiUrls.INIT + apiUrls.MOSHIOKURI_ADD, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getMoshiokuriList(current, pageSize) {
    return axios(
      apiUrls.INIT +
        apiUrls.KANRI_MOSHIOKURI_LIST +
        `?pageNo=${current && current > 1 ? current : 1}&pageSize=1000`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postMoshiokuriList(current, pageSize, text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_MOSHIOKURI_LIST, {
      method: "POST",
      data: {
        pageNo: current.toString(),
        perPage: "1000",
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postMoshiokuriByIds(ids) {
    return axios(apiUrls.INIT + apiUrls.KANRI_MOSHIOKURI_BY_IDS, {
      method: "POST",
      data: {
        pageNo: "1",
        perPage: "100",
        moshiokuriIds: ids.toString(),
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getMoshiokuriSingleRecord(id, ids, type) {
    return axios(apiUrls.INIT + apiUrls.KANRI_MOSHIOKURI_SINGLE_RECORD, {
      method: "POST",
      data: {
        id: id.toString(),
        ids: ids ? ids.toString() : "",
        type: type,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
  //END MOSHIOKURI KANRI API

  /**
   * START: OpenScreenLog kanri api
   * @param {*} ids
   */
  deleteOpenSL(ids) {
    return axios(apiUrls.INIT + apiUrls.OPENSL_DELETE, {
      method: "DELETE",
      data: {
        openIds: ids,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  updateOpenSL(params) {
    return axios(apiUrls.INIT + apiUrls.OPENSL_UPDATE, {
      method: "PUT",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  createOpenSL(params) {
    return axios(apiUrls.INIT + apiUrls.OPENSL_ADD, {
      method: "POST",
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getOpenSLList(current, pageSize) {
    return axios(
      apiUrls.INIT +
        apiUrls.KANRI_OPENSL_LIST +
        `?pageNo=${current && current > 1 ? current : 1}&pageSize=1000`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postOpenSLList(current, pageSize, text1, text2, text3) {
    return axios(apiUrls.INIT + apiUrls.KANRI_OPENSL_LIST, {
      method: "POST",
      data: {
        pageNo: current.toString(),
        perPage: "1000",
        param1: text1,
        param2: text2,
        param3: text3,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  postOpenSLByIds(ids) {
    return axios(apiUrls.INIT + apiUrls.KANRI_OPENSL_BY_IDS, {
      method: "POST",
      data: {
        pageNo: "1",
        perPage: "100",
        openIds: ids.toString(),
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },

  getOpenSLSingleRecord(id, ids, type) {
    return axios(apiUrls.INIT + apiUrls.KANRI_OPENSL_SINGLE_RECORD, {
      method: "POST",
      data: {
        id: id.toString(),
        ids: ids ? ids.toString() : "",
        type: type,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
  //END OPENSCREENLOG KANRI API

  getSearchData(type) {
    return axios(apiUrls.INIT + apiUrls.KANRI_SEARCH_DATA + `?type=${type}`, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
};

export default KanriApi;
