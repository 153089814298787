import React, { Component } from "react";

import {
  Row,
  Col,
  Select,
  Button,
  List,
  Avatar,
  notification,
  Modal,
} from "antd";
import MediaQuery from "react-responsive";

import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import { getToken, getPath } from "../../common/function_common/functionCommon";

import "./UserManagement.css";
import ListItemContent from "./UI/ListItemContent";
import { Link } from "react-router-dom";

const { Option } = Select;

class UserManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: "全表示",
      path: getPath(),
    };
  }

  /**
   *
   */
  componentDidMount() {
    this.props.sendGroup();
    this.props.userList(true);
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  handleChangeGroup = (value) => {
    this.setState({
      selectedGroup: value,
    });

    this.props.filterUserGroup(value);
  };

  deleteUser = (ids) => {
    this.setState({
      delIds: ids,
      visibleDelConfirmPopup: true,
    });
  };

  handleOKDelConfim = () => {
    const { delIds } = this.state;

    if (delIds) {
      this.props.deleteUser(delIds.toString());
      this.props.updateUsers(delIds);
    }

    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  handleCancelDelConfim = () => {
    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  /**
   *
   */
  render() {
    const { sessionTimeout, groups, users } = this.props;
    const { selectedGroup, path } = this.state;

    const token = getToken();
    let jwtDecode = require("jwt-decode");
    let userName = jwtDecode(token).userInfo;

    return (
      (<CommonPageContainer
        id="user-management-page"
        sessionTimeout={sessionTimeout}
      >
        <Row className={"submenu"}>
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2 title={`ユーザ一覧`} iconType="team" />
                    }
                    TopRight={null}
                  />
                );
              } else {
                /**
                 * @TODO design UI for mobile
                 *
                 * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
                 */
                return null;
              }
            }}
          </MediaQuery>
        </Row>
        <Row className="user-management-content">
          <Col span={24} style={{ textAlign: "right", marginTop: 24 }}>
            <span>
              グループ
              <Select
                value={selectedGroup ? selectedGroup : null}
                style={{ width: 200, marginLeft: 8 }}
                onChange={this.handleChangeGroup}
              >
                <Option key={-1} value={"全表示"}>
                  全表示
                </Option>
                {groups && groups.length > 0
                  ? groups.map((item, index) => {
                      return (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      );
                    })
                  : null}
              </Select>
              <Link to={`${path}/send-group`}>
                <Button type="primary" style={{ marginLeft: 8 }}>
                  設定
                </Button>{" "}
              </Link>
            </span>
          </Col>
          <Col span={24} style={{ marginTop: 16, textAlign: "right" }}>
            <Link to={`${path}/user-management/add`}>
              <Button
                type="primary"
                disabled={userName && userName.sysMnAuth === 1 ? false : true}
              >
                追加
              </Button>
            </Link>
          </Col>
          <Col span={24} style={{ marginTop: 16 }}>
            <List
              itemLayout="horizontal"
              bordered
              dataSource={users}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={item.userImage} />}
                    description={
                      <ListItemContent
                        data={item}
                        userName={userName}
                        deleteUser={this.deleteUser}
                        path={path}
                      />
                    }
                  />
                </List.Item>
              )}
            />
          </Col>

          <Modal
            title="処理確認"
            open={this.state.visibleDelConfirmPopup}
            onOk={this.handleOKDelConfim}
            onCancel={this.handleCancelDelConfim}
            okText="OK"
            cancelText="キャンセル"
          >
            <p>削除してもよろしいでしょうか？</p>
          </Modal>
        </Row>
      </CommonPageContainer>)
    );
  }
}

export default UserManagement;
