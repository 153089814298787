import {
  MESSAGE_LIST_REQUEST,
  MESSAGE_LIST_SUCCESS,
  MESSAGE_ADD_REQUEST,
  MESSAGE_ADD_SUCCESS,
  LOAD_MORE_REQUEST,
  LOAD_MORE_SUCCESS,
  MESSAGE_MITAYO,
} from "../../actions/messageActions/actionName.js";

import {
  SOCKET_CONNECTION_INIT,
  SOCKET_CONNECTION_SUCESS,
  SOCKET_CONNECTION_ERROR,
  SOCKET_CONNECTION_CLOSED,
  SOCKET_MESSAGE,
} from "../../actions/websocketActions/actionName";
import { getValueLocalstorage } from "../../common/function_common/functionCommon.js";
import { StorageUtils } from "../../management/utils/storage.js";
import { ConfigName } from "../../config-name";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  messages: [],
};

export default function messageListReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MESSAGE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        messages: action.messages,
        loading: false,
      };

    case MESSAGE_ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MESSAGE_ADD_SUCCESS:
      let myArr = [...state.messages];
      myArr.unshift(action.message);

      return {
        ...state,
        messages: myArr,
      };

    case LOAD_MORE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOAD_MORE_SUCCESS:
      let preLoadArr = [...state.messages];

      preLoadArr.push.apply(preLoadArr, action.messages);

      return {
        ...state,
        messages: preLoadArr,
        loading: false,
      };

    case MESSAGE_MITAYO:
      let myArray = [...state.messages];
      let objIndex = myArray.findIndex(
        (obj) => obj.id === parseInt(action.messageId, 0)
      );
      myArray[objIndex].readf = 1;

      return {
        ...state,
        messages: myArray,
      };

    case SOCKET_CONNECTION_INIT:
      return Object.assign({}, state, {
        connected: false,
        socket: action.socket ? action.socket : state.socket,
      });

    case SOCKET_CONNECTION_SUCESS:
      return Object.assign({}, state, {
        connected: true,
      });

    case SOCKET_CONNECTION_ERROR:
      return Object.assign({}, state, {
        connected: false,
      });

    case SOCKET_CONNECTION_CLOSED:
      return Object.assign({}, state, {
        connected: false,
        socket: null,
      });

    case SOCKET_MESSAGE:
      var preObj = action.wsData;

      try {
        // Check Data is JSON or String
        var obj = JSON.parse(preObj);

        // Add new message
        if (obj.type === 5) {
          let myArr = [...state.messages];
          myArr.unshift(JSON.parse(obj.data));

          return {
            ...state,
            messages: myArr,
          };
        } else if (obj.type === 4) {
          var login = getValueLocalstorage("doubleLogin");

          // Check login with same with login from other system
          var isDbTs = obj.isDbTs;
          var isDbTsLocal = StorageUtils.getValue(ConfigName.ISDBTS);

          if (new Date().getTime() - login > 3000 && isDbTs !== isDbTsLocal) {
            StorageUtils.removeValue(ConfigName.ISDBTS);
            return {
              ...state,
              preventLogin: true,
            };
          } else {
            return {
              ...state,
              wsData: action.wsData,
            };
          }
        } else {
          return {
            ...state,
            wsData: action.wsData,
          };
        }
      } catch (e) {
        // CASE Data is tring
        return {
          ...state,
          wsData: action.wsData,
        };
      }

    default:
      return state;
  }
}
