/* eslint-disable no-nested-ternary */
import { Row, Table } from "antd";
import dayjs from "dayjs";
import React from "react";
import { convertTimeToIndex } from "../../../common/function_common/functionCommon";
import {
  exportColor,
  updateContentKubun,
} from "../../../common/function_common/reportWeeklyDataFormat";
import ActionsRow from "./ActionsRow";

export default class Content extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      indexRow: null,
    };
  }

  componentDidMount() {
    const { timeActive } = this.props;

    if (timeActive) {
      this.setState({
        indexRow: convertTimeToIndex(timeActive),
      });
    }
  }

  handleActions = (record, index) => {
    this.setState({
      indexRow: index,
    });
  };

  deleteRecords = (delValue) => {
    this.props.deleteRecords(delValue);
  };

  deleteAllImages = (record) => {
    this.props.deleteAllImages(record);
  };

  deleteAllRecords = (record) => {
    this.props.deleteAllRecords(record);
  };

  render() {
    const { indexRow } = this.state;
    const {
      tbDisType,
      reports,
      loading,
      date,
      riyousyaId,
      settingColor,
      gazouKubun,
    } = this.props;

    const columns = [
      {
        title: "時間",
        dataIndex: "col0",
        width: 60,
        render: (text, record) => (
          <span className="weekly-col-time-content">
            {record.key % 2 === 0 ? record.timeRange.substring(0, 5) : "\u00A0"}
          </span>
        ),
        className: "weekly-col-time",
        fixed: "left",
      },
      {
        title: "日課計画",
        dataIndex: "col8",
        width: 100,
        render: (text, record) => (
          <Row
            className={tbDisType ? "tb-dis-type-normal" : "tb-dis-type-nowrap"}
            style={{ width: "100px" }}
          >
            {record.plan}
          </Row>
        ),
        className: "tb-dis-type-normal",
        fixed: "left",
      },
      {
        title: `【本日】${dayjs(date).format("MM月DD日")}`,
        dataIndex: "col1",
        render: (text, record, index) => (
          <div
            className={tbDisType ? "tb-dis-type-normal" : "tb-dis-type-nowrap"}
            onClick={this.handleActions.bind(this, record, index)}
            style={{ background: exportColor(record.date1[0], settingColor) }}
          >
            {record.key !== 100 ? (
              <p className="weekly-action-text">
                {record.date1[0]
                  ? updateContentKubun(gazouKubun, record.date1[0].content)
                  : "\u00A0"}
              </p>
            ) : null}
            {record.key === 100 ? (
              record.date1.hainyoCount ? (
                <p className="weekly-action-text">
                  {"尿回数: " + record.date1.hainyoCount}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date1.haibenCount ? (
                <p className="weekly-action-text">
                  {"便回数: " + record.date1.haibenCount}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date1.intake ? (
                <p className="weekly-action-text">
                  {"水分摂取量: " + record.date1.intake + "cc"}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date1.meal ? (
                <p className="weekly-action-text">{record.date1.meal}</p>
              ) : null
            ) : null}

            {indexRow === index && record.key !== 100 ? (
              <ActionsRow
                riyousyaId={riyousyaId}
                timeRange={record.timeRange}
                date={date}
                record={record.date1 ? record.date1 : null}
                deleteRecords={this.deleteRecords}
                deleteAllImages={this.deleteAllImages}
                deleteAllRecords={this.deleteAllRecords}
                planType={record.planType ? record.planType.toString() : ""}
                history={this.props.history}
              />
            ) : null}
          </div>
        ),
        className: "weekly-action-row",
      },
      {
        title: `${dayjs(date).add(-1, "days").format("MM月DD日")}`,
        dataIndex: "col2",
        width: 150,
        className: "padding-0",
        render: (text, record) => (
          <div
            className={tbDisType ? "tb-dis-type-normal" : "tb-dis-type-nowrap"}
            style={{ background: exportColor(record.date2[0], settingColor) }}
          >
            {record.key !== 100 ? (
              <p className="weekly-action-text">
                {record.date2[0]
                  ? updateContentKubun(gazouKubun, record.date2[0].content)
                  : "\u00A0"}
              </p>
            ) : null}
            {record.key === 100 ? (
              record.date2.hainyoCount ? (
                <p className="weekly-action-text">
                  {"尿回数: " + record.date2.hainyoCount}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date2.haibenCount ? (
                <p className="weekly-action-text">
                  {"便回数: " + record.date2.haibenCount}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date2.intake ? (
                <p className="weekly-action-text">
                  {"水分摂取量: " + record.date2.intake + "cc"}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date2.meal ? (
                <p className="weekly-action-text">{record.date2.meal}</p>
              ) : null
            ) : null}
          </div>
        ),
      },
      {
        title: `${dayjs(date).add(-2, "days").format("MM月DD日")}`,
        dataIndex: "col3",
        width: 150,
        className: "padding-0",
        render: (text, record) => (
          <div
            className={tbDisType ? "tb-dis-type-normal" : "tb-dis-type-nowrap"}
            style={{ background: exportColor(record.date3[0], settingColor) }}
          >
            {record.key !== 100 ? (
              <p className="weekly-action-text">
                {record.date3[0]
                  ? updateContentKubun(gazouKubun, record.date3[0].content)
                  : "\u00A0"}
              </p>
            ) : null}
            {record.key === 100 ? (
              record.date3.hainyoCount ? (
                <p className="weekly-action-text">
                  {"尿回数: " + record.date3.hainyoCount}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date3.haibenCount ? (
                <p className="weekly-action-text">
                  {"便回数: " + record.date3.haibenCount}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date3.intake ? (
                <p className="weekly-action-text">
                  {"水分摂取量: " + record.date3.intake + "cc"}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date3.meal ? (
                <p className="weekly-action-text">{record.date3.meal}</p>
              ) : null
            ) : null}
          </div>
        ),
      },
      {
        title: `${dayjs(date).add(-3, "days").format("MM月DD日")}`,
        dataIndex: "col4",
        width: 150,
        className: "padding-0",
        render: (text, record) => (
          <div
            className={tbDisType ? "tb-dis-type-normal" : "tb-dis-type-nowrap"}
            style={{ background: exportColor(record.date4[0], settingColor) }}
          >
            {record.key !== 100 ? (
              <p className="weekly-action-text">
                {record.date4[0]
                  ? updateContentKubun(gazouKubun, record.date4[0].content)
                  : "\u00A0"}
              </p>
            ) : null}
            {record.key === 100 ? (
              record.date4.hainyoCount ? (
                <p className="weekly-action-text">
                  {"尿回数: " + record.date4.hainyoCount}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date4.haibenCount ? (
                <p className="weekly-action-text">
                  {"便回数: " + record.date4.haibenCount}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date4.intake ? (
                <p className="weekly-action-text">
                  {"水分摂取量: " + record.date4.intake + "cc"}
                </p>
              ) : null
            ) : null}

            {record.key === 100 ? (
              record.date4.meal ? (
                <p className="weekly-action-text">{record.date4.meal}</p>
              ) : null
            ) : null}
          </div>
        ),
      },
      {
        title: `${dayjs(date).add(-4, "days").format("MM月DD日")}`,
        dataIndex: "col5",
        width: 150,
        className: "padding-0",
        render: (text, record) => (
          <div
            className={tbDisType ? "tb-dis-type-normal" : "tb-dis-type-nowrap"}
            style={{ background: exportColor(record.date5[0], settingColor) }}
          >
            {record.key !== 100 ? (
              <p className="weekly-action-text">
                {record.date5[0]
                  ? updateContentKubun(gazouKubun, record.date5[0].content)
                  : "\u00A0"}
              </p>
            ) : null}
            {record.key === 100 ? (
              record.date5.hainyoCount ? (
                <p className="weekly-action-text">
                  {"尿回数: " + record.date5.hainyoCount}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date5.haibenCount ? (
                <p className="weekly-action-text">
                  {"便回数: " + record.date5.haibenCount}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date5.intake ? (
                <p className="weekly-action-text">
                  {"水分摂取量: " + record.date5.intake + "cc"}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date5.meal ? (
                <p className="weekly-action-text">{record.date5.meal}</p>
              ) : null
            ) : null}
          </div>
        ),
      },
      {
        title: `${dayjs(date).add(-5, "days").format("MM月DD日")}`,
        dataIndex: "col6",
        width: 150,
        className: "padding-0",
        render: (text, record) => (
          <div
            className={tbDisType ? "tb-dis-type-normal" : "tb-dis-type-nowrap"}
            style={{ background: exportColor(record.date6[0], settingColor) }}
          >
            {record.key !== 100 ? (
              <p className="weekly-action-text">
                {record.date6[0]
                  ? updateContentKubun(gazouKubun, record.date6[0].content)
                  : "\u00A0"}
              </p>
            ) : null}
            {record.key === 100 ? (
              record.date6.hainyoCount ? (
                <p className="weekly-action-text">
                  {"尿回数: " + record.date6.hainyoCount}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date6.haibenCount ? (
                <p className="weekly-action-text">
                  {"便回数: " + record.date6.haibenCount}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date6.intake ? (
                <p className="weekly-action-text">
                  {"水分摂取量: " + record.date6.intake + "cc"}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date6.meal ? (
                <p className="weekly-action-text">{record.date6.meal}</p>
              ) : null
            ) : null}
          </div>
        ),
      },
      {
        title: `${dayjs(date).add(-6, "days").format("MM月DD日")}`,
        dataIndex: "col7",
        width: 150,
        className: "padding-0",
        render: (text, record) => (
          <div
            className={tbDisType ? "tb-dis-type-normal" : "tb-dis-type-nowrap"}
            style={{ background: exportColor(record.date7[0], settingColor) }}
          >
            {record.key !== 100 ? (
              <p className="weekly-action-text">
                {record.date7[0]
                  ? updateContentKubun(gazouKubun, record.date7[0].content)
                  : "\u00A0"}
              </p>
            ) : null}
            {record.key === 100 ? (
              record.date7.hainyoCount ? (
                <p className="weekly-action-text">
                  {"尿回数: " + record.date7.hainyoCount}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date7.haibenCount ? (
                <p className="weekly-action-text">
                  {"便回数: " + record.date7.haibenCount}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date7.intake ? (
                <p className="weekly-action-text">
                  {"水分摂取量: " + record.date7.intake + "cc"}
                </p>
              ) : null
            ) : null}
            {record.key === 100 ? (
              record.date7.meal ? (
                <p className="weekly-action-text">{record.date7.meal}</p>
              ) : null
            ) : null}
          </div>
        ),
      },
    ];

    return (
      <Table
        id="tb-weekly"
        columns={columns}
        dataSource={reports}
        bordered
        scroll={{ x: 1400, y: 800 }}
        size="small"
        pagination={false}
        style={{ whiteSpace: "pre" }}
        loading={loading}
        rowClassName={(record, index) => {
          if (index === 11) {
            return "rwp-row11";
          }

          if (index === 15) {
            return "rwp-row15";
          }

          if (index === 23) {
            return "rwp-row23";
          }

          if (index === 35) {
            return "rwp-row35";
          }

          if (index === 43) {
            return "rwp-row43";
          }
        }}
      />
    );
  }
}
