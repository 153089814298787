import { ApiPaths } from "../../constants/api_paths";
import { UseSlipActionType, ErrorActionTypes } from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils, history } from "../../utils/index";
import { calculateCase11, calculateDateAttachedTableTypeNormal, calculatorDays, generateId, handleOkAddService, calCount } from "../../pages/UseSlip/ViewUseSlip/function";
import { SettingMasterAPI } from "../../api/settingMaster";
import { calListDataShow, calTotalSmallTotal, calTotalTable, calculateAndProcess, calculateAttachedType21, calculateSmallTotal, handleCheckArrayOver30Days, handleOkTenkey, handleUpdateDataAttached } from "../../pages/UseSlip/function";
import { KAIGO_CODE_SPECIAL } from "../../pages/UsageTicketManagement/constants";
import _ from "lodash";
import { apiUrls } from "../../common/configs/common-url";

function getAll(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_ALL_USESLIP_REQUEST });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.UseSlip.GetList,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_ALL_USESLIP_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_ALL_USESLIP_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getRiyouhyouById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_RIYOUHYOU_BY_ID });
      const query = { id };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetRiyouhyouById,
        query,
      });

      dispatch({
        type: UseSlipActionType.GET_RIYOUHYOU_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_RIYOUHYOU_BY_ID_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function clearRiyouhyou() {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.CLEAR_RIYOUHYOU })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  }
}

function setListHistory(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.SET_LIST_HISTORY, data })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  }
}

function setListManagement(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.SET_LIST_MANAGEMENT, data })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  }
}

function setOldParamsRedux(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.SET_OLD_PARAMS, data })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  }
}

function getListServiceType(serviceType) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_LIST_SERVICE_TYPE });
      const query = { serviceType };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetListServiceType,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_LIST_SERVICE_TYPE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_LIST_SERVICE_TYPE_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListUseSlipQuote(riyousyaId) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_ALL_USESLIP_QUOTE_REQUEST });
      const query = {
        riyousyaId,
        sid,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.UseSlip.GetUseSlipQuote,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_ALL_USESLIP_QUOTE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_ALL_USESLIP_QUOTE_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.REMOVE_USESLIP_REQUEST });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.UseSlip.Delete,
        query,
      });
      dispatch({ type: UseSlipActionType.REMOVE_USESLIP_SUCCESS, data });
    } catch (error) {
      dispatch({ type: UseSlipActionType.REMOVE_USESLIP_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceContentById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_SERVICE_CONTENT_BY_ID });
      const query = { id };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetServiceContentById,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_SERVICE_CONTENT_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_SERVICE_CONTENT_BY_ID_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceAdditionByType(additions, serviceType) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_SERVICE_ADDITION_BY_TYPE });
      const query = { additions, serviceType };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetServiceAdditionByType,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_SERVICE_ADDITION_BY_TYPE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_SERVICE_ADDITION_BY_TYPE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getSyuruiCd(syuruiCd) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_SERVICE_CONTENT_BY_ID });
      const query = { syuruiCd };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetSyuruiCd,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_SYURUI_CD_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_SYURUI_CD_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceCode(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_SERVICE_CODE });
      const query = { body };
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.GetServiceCode,
        body,
      });
      dispatch({
        type: UseSlipActionType.GET_SERVICE_CODE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_SERVICE_CODE_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getExpandCode(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_EXPAND_CODE });
      const query = { body };
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.GetExpandCode,
        body,
      });
      dispatch({
        type: UseSlipActionType.GET_EXPAND_CODE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_EXPAND_CODE_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListServiceAddition(serviceType, syuruiCd, nengetu) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_SERVICE_ADDITION_REQUEST });
      const query = { serviceType, syuruiCd, nengetu };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetServiceAddition,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_SERVICE_ADDITION_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_SERVICE_ADDITION_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListServiceAdditionOld(serviceType, syuruiCd, nengetu) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_SERVICE_ADDITION_REQUEST_OLD });
      const query = { serviceType, syuruiCd, nengetu };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetServiceAdditionOld,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_SERVICE_ADDITION_SUCCESS_OLD,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_SERVICE_ADDITION_FAILURE_OLD });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getAdditionByOffice(body) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.GET_SERVICE_ADDITION_BY_OFFICE_REQUEST,
      });
      const query = { body };
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.GetServiceAdditionByOffice,
        body,
      });
      dispatch({
        type: UseSlipActionType.GET_SERVICE_ADDITION_BY_OFFICE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_SERVICE_ADDITION_BY_OFFICE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceContentByTypeAndSyurui(serviceType, syuruiCd, nengetu) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_REQUEST,
      });
      const query = { serviceType, syuruiCd, nengetu };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetServiceContentByTypeAndSyurui,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getInformationKaigohoken(riyousyaId, nengetu, id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.GET_INFORMATION_KAIGOHOKEN_REQUEST,
      });
      const query = { riyousyaId, nengetu, id };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetInformationKaigohoken,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_INFORMATION_KAIGOHOKEN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_INFORMATION_KAIGOHOKEN_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceColorMaster() {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.GET_SERVICE_COLOR_MASTER_USESLIP_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetColorMasterByUseSlip,
      });
      dispatch({
        type: UseSlipActionType.GET_SERVICE_COLOR_MASTER_USESLIP_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_SERVICE_COLOR_MASTER_USESLIP_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function getServiceColorMasterSonota() {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.GET_SERVICE_COLOR_MASTER_SONOTA_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetServiceColorMasterSonota,
      });

      dispatch({
        type: UseSlipActionType.GET_SERVICE_COLOR_MASTER_SONOTA_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_SERVICE_COLOR_MASTER_SONOTA_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getNaiyou(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_NAIYOU_REQUEST });
      const query = { body };
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.GetNaiyou,
        body,
      });
      dispatch({
        type: UseSlipActionType.GET_NAIYOU_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_NAIYOU_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getWeeklySchedule(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_WEEKLY_SCHEDULE_REQUEST });
      const query = { body };
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.GetWeeklySchedule,
        body,
      });
      dispatch({
        type: UseSlipActionType.GET_WEEKLY_SCHEDULE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_WEEKLY_SCHEDULE_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getDuplicateCheckNotPossible(body) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.GET_DUPLICATE_CHECK_NOT_POSSIBLE_REQUEST,
      });
      // const query = {addition};
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.GetDuplicateCheckNotPossible,
        body,
      });
      dispatch({
        type: UseSlipActionType.GET_DUPLICATE_CHECK_NOT_POSSIBLE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_DUPLICATE_CHECK_NOT_POSSIBLE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function updateRiyouhyou(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.UPDATE_RIYOUHYOU_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.UpdateRiyouhyou,
        body,
      });
      dispatch({
        type: UseSlipActionType.UPDATE_RIYOUHYOU_SUCCESS,
        data,
      });
      if (history.location.pathname.includes("new")) {
        const historySearch = history.location.search;
        if(historySearch) {
          history.push(`${history.location.pathname.replace("new", data.id)}${historySearch}`);
        }else {
          history.push(history.location.pathname.replace("new", data.id));
        }
      } else return;
    } catch (error) {
      dispatch({ type: UseSlipActionType.UPDATE_RIYOUHYOU_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function setUnSucessRiyouhyou() {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.SET_RIYOUHYOU_UNSUCESS });
  };
}

function getHolidayMaster() {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_HOLIDAY_MASTER_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetHolidayMaster,
      });
      dispatch({
        type: UseSlipActionType.GET_HOLIDAY_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_HOLIDAY_MASTER_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

// function removeAdditionOffice() {
//   return async (dispatch) => {
//     dispatch({ type: UseSlipActionType.REMOVE_ADDITION_OFFICE });
//   };
// }

// function removeAdditionDuplicate() {
//   return async (dispatch) => {
//     dispatch({ type: UseSlipActionType.REMOVE_ADDITION_DUPLICATE });
//   };
// }

function removeRiyouHyou() {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.REMOVE_RIYOUHYOU });
  };
}

function getRiyouhyouIds(riyousyaId) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_RIYOYHYOU_IDS_REQUEST });
      const query = {
        riyousyaId,
        sid,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.UseSlip.GetRiyouhyouIds,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_RIYOYHYOU_IDS_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_RIYOYHYOU_IDS_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function removeNaiyou() {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.REMOVE_NAIYOU });
  };
}

function removeExpandCode() {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.REMOVE_EXPAND_CODE });
  };
}

function getAttached(riyousyaId) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_ATTACHED_REQUEST });

      // const data = await RequestUtils.callApi({
      //   method: "get",
      //   path: ApiPaths.UseSlip.GetRiyouhyouIds,
      //   query,
      // });
      const data = [
        {
          subtotal: false,
          offficeName: "あいうえおヘルパー",
          officeNo: 1234567895,
          serviceContent: "身体介護2",
          serviceCode: 111211,
          unit: 396,
          discountRate: null,
          discountUnit: null,
          frequency: 8,
          unitOrAmount: 3168,
          benefitUnit: 3168,
          superDivision: null,
          flag: null,
          unitCost: null,
          totalCost: null,
          benefit: null,
          benefitValue: null,
          burden: null,
          externalSalary: null,
        },
        {
          subtotal: false,
          offficeName: "あいうえおヘルパー",
          officeNo: 1234567895,
          serviceContent: "訪問介護特定事業所加算V",
          serviceCode: 116410,
          unit: 95,
          discountRate: null,
          discountUnit: null,
          frequency: 1,
          unitOrAmount: 95,
          benefitUnit: 95,
          superDivision: null,
          flag: null,
          unitCost: null,
          totalCost: null,
          benefit: null,
          benefitValue: null,
          burden: null,
          externalSalary: null,
        },
        {
          subtotal: true,
          offficeName: "あいうえおヘルパー",
          officeNo: 1234567895,
          serviceContent: "訪問介護　小計",
          serviceCode: null,
          unit: null,
          discountRate: null,
          discountUnit: null,
          frequency: null,
          unitOrAmount: 3263,
          benefitUnit: 3263,
          superDivision: null,
          flag: 3263,
          unitCost: 10.7,
          totalCost: 34914,
          benefit: 90,
          benefitValue: 31422,
          burden: 3492,
          externalSalary: null,
        },
        {
          subtotal: false,
          offficeName: "すまいるデイ",
          officeNo: 3415000015,
          serviceContent: "通所介護Ⅲ33",
          serviceCode: 153693,
          unit: 712,
          discountRate: null,
          discountUnit: null,
          frequency: 4,
          unitOrAmount: 2848,
          benefitUnit: 3092,
          superDivision: null,
          flag: null,
          unitCost: null,
          totalCost: null,
          benefit: null,
          benefitValue: null,
          burden: null,
          externalSalary: null,
        },
        {
          subtotal: false,
          offficeName: "すまいるデイ",
          officeNo: 3415000015,
          serviceContent: "個別機能訓練加算Ⅰ1",
          serviceCode: 155051,
          unit: 56,
          discountRate: null,
          discountUnit: null,
          frequency: 4,
          unitOrAmount: 224,
          benefitUnit: 224,
          superDivision: null,
          flag: null,
          unitCost: null,
          totalCost: null,
          benefit: null,
          benefitValue: null,
          burden: null,
          externalSalary: null,
        },
        {
          subtotal: false,
          offficeName: "すまいるデイ",
          officeNo: 3415000015,
          serviceContent: "入浴介助加算",
          serviceCode: 155301,
          unit: 40,
          discountRate: null,
          discountUnit: null,
          frequency: 4,
          unitOrAmount: 160,
          benefitUnit: 160,
          superDivision: null,
          flag: null,
          unitCost: null,
          totalCost: null,
          benefit: null,
          benefitValue: null,
          burden: null,
          externalSalary: null,
        },
        {
          subtotal: true,
          offficeName: "すまいるデイ",
          officeNo: 3415000015,
          serviceContent: "訪問介護　小計",
          serviceCode: null,
          unit: null,
          discountRate: null,
          discountUnit: null,
          frequency: null,
          unitOrAmount: 3232,
          benefitUnit: 3476,
          superDivision: null,
          flag: 3232,
          unitCost: 10.0,
          totalCost: 32320,
          benefit: 90,
          benefitValue: 39088,
          burden: 3232,
          externalSalary: null,
        },
        {
          subtotal: false,
          grey: true,
          offficeName: "あいうえおヘルパー",
          officeNo: 3415000015,
          serviceContent: "訪問介護処遇改善加算Ⅰ",
          serviceCode: 116275,
          unit: 447,
          discountRate: null,
          discountUnit: null,
          frequency: 1,
          unitOrAmount: 447,
          benefitUnit: null,
          superDivision: null,
          flag: null,
          unitCost: 10.7,
          totalCost: 4782,
          benefit: 90,
          benefitValue: 4303,
          burden: 479,
          externalSalary: null,
        },
        {
          subtotal: false,
          grey: true,
          offficeName: "あいうえおヘルパー",
          officeNo: 1234567895,
          serviceContent: "訪問介護特定処遇改善Ⅰ",
          serviceCode: 116278,
          unit: 206,
          discountRate: null,
          discountUnit: null,
          frequency: 1,
          unitOrAmount: 206,
          benefitUnit: null,
          superDivision: null,
          flag: null,
          unitCost: 10.7,
          totalCost: 2204,
          benefit: 90,
          benefitValue: 1983,
          burden: 221,
          externalSalary: null,
        },
        {
          subtotal: false,
          grey: true,
          offficeName: "すまいるデイ",
          officeNo: 3415000015,
          serviceContent: "サービス提供体制Ⅰ",
          serviceCode: 156099,
          unit: 22,
          discountRate: null,
          discountUnit: null,
          frequency: 4,
          unitOrAmount: 88,
          benefitUnit: null,
          superDivision: null,
          flag: null,
          unitCost: 10.0,
          totalCost: 880,
          benefit: 90,
          benefitValue: 792,
          burden: 88,
          externalSalary: null,
        },
      ];

      dispatch({
        type: UseSlipActionType.GET_ATTACHED_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_ATTACHED_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function setRiyouhyouForm(data) {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.SET_RIYOUHYOUFORM, data });
  };
}

function setListDataServiceSonota(data) {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.SET_LIST_DATA_SERVICE_SONOTA, data });
  };
}

function setRiyouhyouFormSuccess() {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.SET_RIYOUHYOUFORM_SUCESS });
  };
}
function removeRiyouhyouForm() {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.REMOVE_RIYOUHYOUFORM });
  };
}
function getURL(url) {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.GET_URL, url });
  };
}
function isLoading() {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.IS_LOADING });
  };
}
function getRenzokuLastMonth(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_RENZOKU_LAST_MONTH_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.GetRenzokuLastMonth,
        body,
      });
      dispatch({
        type: UseSlipActionType.GET_RENZOKU_LAST_MONTH_SUCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_RENZOKU_LAST_MONTH_FAIL });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function getKyufuDayLastMonth(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_KYUFUDAYS_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.GetKyufuDays,
        body,
      });
      dispatch({
        type: UseSlipActionType.GET_KYUFUDAYS_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_KYUFUDAYS_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function stopLoading() {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.STOP_LOADING });
  };
}
function getOverProcess(data) {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.GET_OVER_PROCESS, data });
  };
}
function getListAttached(data) {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.SET_LIST_ATTACHED, data });
  };
}
function getOverProcessActual(data) {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.GET_OVER_PROCESS_ACTUAL, data });
  };
}
function getRiyouhyouQuoteList(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_LIST_RIYOUHYOU_QUOTES_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.UseSlip.GetListRiyouhyouQuote,
        body,
      });
      dispatch({
        type: UseSlipActionType.GET_LIST_RIYOUHYOU_QUOTES_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: UseSlipActionType.GET_LIST_RIYOUHYOU_QUOTES_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function getRiyouhyouQuoteById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_RIYOUHYOU_QUOTE_BY_ID_REQUEST });
      const query = { id };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetRiyouhyouById,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_RIYOUHYOU_QUOTE_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_RIYOUHYOU_QUOTE_BY_ID_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function getRiyouhyouLastQuoteById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_RIYOUHYOU_LAST_QUOTE_BY_ID_REQUEST });
      const query = { id };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetRiyouhyouById,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_RIYOUHYOU_LAST_QUOTE_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_RIYOUHYOU_LAST_QUOTE_BY_ID_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function removeRiyouQuote() {
  return async (dispatch) => {
    dispatch({ type: UseSlipActionType.CLEAR_RIYOUHYOU_QUOTE });
  };
}

function getRiyouhyouRenzokuById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_RIYOUHYOU_OF_RENZOKU_DAY_REQUEST });
      const query = { id };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetRiyouhyouById,
        query,
      });
      dispatch({
        type: UseSlipActionType.GET_RIYOUHYOU_OF_RENZOKU_DAY_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_RIYOUHYOU_OF_RENZOKU_DAY_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getUrlFromUseSlip(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.GET_URL_FROM_USE_SLIP,
        data
      })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function removeUrlFromUseSlip() {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.REMOVE_URL_FROM_USE_SLIP
      })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function setStartFromManagement(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.GET_URL_FROM_MANAGEMENT,
        data
      })
    } catch (error) {

      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function setIsDuplicateMonthListManagement(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.DUPLICATE_MONTH_LIST_MANAGEMENT,
        data,
      });
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getRiyouhyouViewById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_RIYOUHYOU_VIEW_BY_ID_REQUEST });
      const query = { id };
      const res = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetRiyouhyouById,
        query,
      });
      const listColorMaster = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetColorMasterByUseSlip,
      });
      const listColorSonota = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetServiceColorMasterSonota,
      });
      if (res) {
        const groupBetuhyouDetailList = res?.betuhyouDetailList.reduce((accumulator, current) => {
          const existingElement = accumulator.find(
            (element) =>
              element.serviceCd === current.serviceCd &&
              element.planf !== current.planf &&
              element.serviceName === current.serviceName &&
              element.officeName === current.officeName &&
              element.startTime === current.startTime &&
              element.endTime === current.endTime &&
              element.type === current.type &&
              element.parentId === current.parentId
          );
          if (existingElement) {
            // existingElement.listActual = current.date;
            if (current.planf) {
              //t/h actual
              existingElement.listActual = current.date;
              existingElement.countActual = current.count;
              existingElement.santeiCountActual = current.santeiCount;
              //chỉ cần set listActual cho nt = current.date
            } else {
              //chỉ cần set thằng date = thằng current date là xong
              existingElement.date = current.date;
              existingElement.count = current.count;
              existingElement.santeiCount = current.santeiCount;
            }
            //handle xử lý nếu chưa có actual hoặc plan thì tự thêm mặc định 000 vào
          } else {
            //handle thêm nếu chưa có thì add thêm listactual bằng rỗng và ngược lại
            if (current.planf) {
              //t/h actual
              //set giá actual = date và set date thành 0 hết
              current.listActual = current.date;
              current.countActual = current.count;
              current.santeiCountActual = current.santeiCount;
              current.date = "0".repeat(current.date.length);
              current.count = null;
              current.santeiCount = null;
            } else {
              //set giá trị của listActual = 0 hết
              current.listActual = "0".repeat(current.date.length);
              current.countActual = null;
              current.santeiCountActual = null;
            }
            accumulator.push(current);
          }
          return accumulator;
        }, []);

        const groupByParentId = groupBetuhyouDetailList.reduce((acc, obj) => {
          const key = obj.parentId;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});

        const output = Object.values(groupByParentId);

        let listDataRecord = [];
        output.forEach(async (listRecord) => {
          const mainCode = listRecord
            .filter((e) => e.isParent === 1)
            .map((e) => {
              return {
                ...e,
                name: e.serviceName,
                offerTime: e.startTime && e.endTime ? `${e.startTime.substring(0, 5)}~${e.endTime.substring(0, 5)}` : "",
                shuruiCode: e.serviceCd.substring(0, 2),
                parentId: e.parentId + "",
                idService: e.additionCodes.id,
                betuhyoId: e.id,
                isParent: true,
                serviceCode: e.serviceCd,
                isShow: true,
                listPlan: e.date.split("").map(Number),
                listActual: e.listActual.split("").map(Number),
                serviceKikanMasterId: e.serviceKikanMasterId,
                serviceRiyoJoken: e.additionCodes.serviceRiyoJoken,
                sisetukubun: e.additionCodes.sisetukubun,
                doitutatemonoSantei: e.additionCodes.doitutatemonoSantei,
                goseiSikbetuKubun: e.additionCodes.goseiSikbetuKubun,
                tuho24: e.additionCodes.tuho24,
                rihaTeikyo: e.additionCodes.rihaTeikyo,
                kyoseigataSantei: e.additionCodes.kyoseigataSantei,
                tokuteiShogukaizen: e.additionCodes.tokuteiShogukaizen,
                rihamane: e.additionCodes.rihamane,
                shogukaizen: e.additionCodes.shogukaizen,
                kasanKanouCodeMap: e.additionCodes.kasanKanouCodeMap,
                sikyugendogakuKubun: e.additionCodes.sikyugendogakuKubun,
                serviceTeikyoKyoka: e.additionCodes.serviceTeikyoKyoka,
                taniSikibetu: e.additionCodes.taniSikibetu,
                seikatusoudaninHaiti: e.additionCodes.seikatusoudaninHaiti,
                nintishoSenmoncare: e.additionCodes.nintishoSenmoncare,
                kyufukanriTani: e.additionCodes.kyufukanriTani,
                serviceRiyoJoken: e.additionCodes.serviceRiyoJoken,
                higaito: e.additionCodes.higaito,
                yousien: e.additionCodes.yousien,
                yousien1: e.additionCodes.yousien1,
                youkaigo1: e.additionCodes.youkaigo1,
                youkaigo2: e.additionCodes.youkaigo2,
                youkaigo3: e.additionCodes.youkaigo3,
                youkaigo4: e.additionCodes.youkaigo4,
                youkaigo5: e.additionCodes.youkaigo5,
                is30DayOver: e.type === "30日越" ? true : false,
                jikanEntyou: e.additionCodes.jikanEntyou,
                gaibuserviceShuruicode: e.additionCodes.gaibuserviceShuruicode,
                hiwariSikibetu: e.additionCodes.hiwariSikibetu
              };
            });

          const expandCode = listRecord
            .filter((e) => e.isParent !== 1)
            .map((e) => {
              return {
                ...e,
                shuruiCode: e.serviceCd.substring(0, 2),
                parentId: e.parentId + "",
                idService: e.additionCodes.id,
                id: e.id,
                betuhyoId: e.id,
                isParent: false,
                serviceCode: e.serviceCd,
                isShow: true,
                tani: e.additionCodes.tani,
                listPlan: e.date.split("").map(Number),
                listActual: e.listActual.split("").map(Number),
                serviceRiyoJoken: e.additionCodes.serviceRiyoJoken,
                sisetukubun: e.additionCodes.sisetukubun,
                doitutatemonoSantei: e.additionCodes.doitutatemonoSantei,
                goseiSikbetuKubun: e.additionCodes.goseiSikbetuKubun,
                tuho24: e.additionCodes.tuho24,
                rihaTeikyo: e.additionCodes.rihaTeikyo,
                kyoseigataSantei: e.additionCodes.kyoseigataSantei,
                tokuteiShogukaizen: e.additionCodes.tokuteiShogukaizen,
                rihamane: e.additionCodes.rihamane,
                shogukaizen: e.additionCodes.shogukaizen,
                kasanKanouCodeMap: e.additionCodes.kasanKanouCodeMap,
                sikyugendogakuKubun: e.additionCodes.sikyugendogakuKubun,
                serviceTeikyoKyoka: e.additionCodes.serviceTeikyoKyoka,
                taniSikibetu: e.additionCodes.taniSikibetu,
                seikatusoudaninHaiti: e.additionCodes.seikatusoudaninHaiti,
                nintishoSenmoncare: e.additionCodes.nintishoSenmoncare,
                serviceRiyoJoken: e.additionCodes.serviceRiyoJoken,
                serviceAddition: e.additionCodes.serviceAddition,
                jikanEntyou: e.additionCodes.jikanEntyou,
                gaibuserviceShuruicode: e.additionCodes.gaibuserviceShuruicode,
                kyufukanriTani: e.additionCodes.kyufukanriTani,
                higaito: e.additionCodes.higaito,
                yousien: e.additionCodes.yousien,
                yousien1: e.additionCodes.yousien1,
                youkaigo1: e.additionCodes.youkaigo1,
                youkaigo2: e.additionCodes.youkaigo2,
                youkaigo3: e.additionCodes.youkaigo3,
                youkaigo4: e.additionCodes.youkaigo4,
                youkaigo5: e.additionCodes.youkaigo5,
                hiwariSikibetu: e.additionCodes.hiwariSikibetu,
                parentIds: e.parentIds ? e.parentIds?.split(";") : undefined,
                is30DayOver: res?.betuhyouDetailList.some(
                  (i) => (e.parentId === i.parentId || (e.parentIds && e.parentIds?.split(";").includes(i.parentId + ""))) && i.type === "30日越",
                )
                  ? true
                  : false,
                dataRow: {
                  sid: e.serviceKikanMasterId,
                  tani: e.tani,
                },
              };
            });
          const betuhyouDetailList = listRecord
            .filter((e) => e.isParent === 1)
            .map((e) => {
              const countDate = e.date.split("").map((e, index) => (e !== "0" ? index + 1 : null));
              const listDate = countDate.filter((e) => e !== null);
              return {
                formValues: {
                  shuiruiCode: e.serviceCd.substring(0, 2),
                  serviceCodeType: "",
                  additionList: e.additionList,
                  serviceCodeType: e.serviceCodeType,
                  serviceContent: e.serviceContent,
                  serviceContentType: e.serviceContentType,
                  sortNum: e.sortNum,
                  startTimeStr: e.startTime && e.startTime.substring(0, 5),
                  endTimeStr: e.endTime && e.endTime.substring(0, 5),
                  ryakuServiceKikan: e.officeName,
                  jigyousyoNoServiceKikan: e.jigyousyoNo,
                  serviceCodeTypeId: e.serviceCodeTypeId,
                  sid: e.serviceKikanMasterId,
                  furigana: e.furigana,
                  is30DayOver: e.type === "30日越" ? true : false,
                  productName: e.rentalSyohin,
                  tani: e?.tani,
                  contentRental: e.serviceCd.substring(0, 2) == "17" ? e.serviceName : null,
                },
                additionListMain: [],
                mainCode: { ...mainCode[0] },
                expandCode,
                listDate,
                countDate: countDate.length,
                isKeepFirst: false,
                isKeepLast: false,
                isGetDataWeekly: false,
              };
            });
          listDataRecord.push(betuhyouDetailList);
        });
        let updateData = { listDataService: [], listDataNotParent: [], listDataShow: [] };
        await Promise.all(
          listDataRecord.map(async (listRecord) => {
            for (const item of listRecord) {
              const query = {
                serviceType: item.formValues.serviceCodeType,
                syuruiCd: item.formValues.shuiruiCode,
                nengetu: res.nengetu,
              };
              const listServiceAddition = await RequestUtils.callApi({
                method: "GET",
                path: ApiPaths.UseSlip.GetServiceAddition,
                query,
              });
              let additionListMain = listServiceAddition
                ? listServiceAddition.filter((x) => item.formValues.additionList.includes(x.addition) && x.additionType == 0)
                : [];

              item.additionListMain = additionListMain;
            }
            const { listDataService, listDataShow, listDataNotParent } = handleOkAddService(listRecord, true, res, listColorMaster);

            updateData = {
              ...updateData,
              listDataService: [...updateData.listDataService, ...listDataService],
              listDataNotParent: [...updateData.listDataNotParent, ...listDataNotParent],
              listDataShow: [...updateData.listDataShow, ...(listDataShow || [])],
            };
          }),
        );
        const newListDataServices = calListDataShow({
          dataService: updateData.listDataService,
          changeSort: true,
          changeFilter: 0,
        })
        const dataServiceFlat = (newListDataServices || []).flatMap((e) => e);
        const dateOfMonth = new Date(res.nengetu.substring(0, 4), parseInt(res.nengetu.substring(4)) - 1, 1);
        let countDate = new Date(dateOfMonth.getFullYear(), dateOfMonth.getMonth() + 1, 0);
        const listRiyouhyouSonota = res?.riyouhyouPlanEtc;
        const newArray = listRiyouhyouSonota.reduce((result, item) => {
          const { jigyousyoName, serviceNaiyou, startTime, endTime, date, count, serviceKikanValue, serviceType } = item;
          const foundItem = result.find(
            (newItem) =>
              newItem.jigyousyoName === jigyousyoName &&
              newItem.serviceNaiyou === serviceNaiyou &&
              newItem.startTime === startTime &&
              newItem.endTime === endTime,
          );
          if (foundItem) {
            foundItem.id = generateId();
            foundItem.countList.push({ date, count });
            foundItem.offerTime = `${startTime.substring(0, 5)}~${endTime.substring(0, 5)}`;
            foundItem.defaultPaletteName = serviceNaiyou;

            foundItem.formListDate.countDate = countDate?.getDate();
            foundItem.formListDate.listDate = foundItem.countList.map((item) => parseInt(item.date.split("-")[2]));
            const { selectedDays, sumlistDays } = calculatorDays(foundItem.formListDate.listDate, countDate?.getDate(), foundItem.countList);
            foundItem.formListDate.selectedDays = selectedDays;
            foundItem.formListDate.sumlistDays = sumlistDays;
            if (serviceKikanValue) {
              foundItem.valueServiceSonota = serviceKikanValue;
              foundItem.serviceKikanMaster = serviceKikanValue;
              foundItem.serviceKikanMasterId = serviceKikanValue.id;
            }
            if (serviceType) {
              const index = listColorSonota.findIndex((item) => item.ryaku === serviceType);
              if (index !== -1) {
                foundItem.swColorBak = listColorSonota[index].swColorBak;
                foundItem.serviceType = serviceType;
                foundItem.ryaku = listColorSonota[index].ryaku;
                foundItem.paletteName = listColorSonota[index].paletteName;
              }
            }
          } else {
            let countList = [];
            countList.push({ date, count });
            const countDateItem = countDate?.getDate();
            const listDate = countList.map((item) => parseInt(item.date.split("-")[2]));
            const { selectedDays, sumlistDays } = calculatorDays(listDate, countDate?.getDate(), countList);
            const offerTime = startTime.substring(0, 5) + "~" + endTime.substring(0, 5);
            const id = Math.round(Math.random() * generateId());
            const dataColorSonota = listColorSonota.find((e) => e.ryaku === serviceType);
            result.push({
              id,
              jigyousyoName,
              serviceNaiyou,
              startTime,
              endTime,
              offerTime,
              defaultPaletteName: serviceNaiyou,
              ryaku: dataColorSonota?.ryaku,
              countList,
              formListDate: { countDate: countDateItem, selectedDays, sumlistDays, listDate },
              valueServiceSonota: serviceKikanValue,
              serviceKikanMaster: serviceKikanValue,
              paletteName: dataColorSonota?.paletteName,
              swColorBak: dataColorSonota?.swColorBak,
              serviceKikanMasterId: serviceKikanValue && serviceKikanValue.id ? serviceKikanValue.id : null,
            });
          }
          return result;
        }, []);
        let dataTimeSetting = null
        let dataAttachedTable = [];
        let dataAttachedTableActual = [];
        let smallTotalTable;
        let smallTotalTableActual;
        await SettingMasterAPI.getByName("別表公費表示").then((data) => {
          dataTimeSetting = data;
        });
        const listAfterSort = _.sortBy(newArray, ["offerTime", "valueServiceSonota.furigana", "defaultPaletteName"]);
        if (updateData.listDataService.some((e) => KAIGO_CODE_SPECIAL.includes(e?.dataRow?.shuiruiCode)) &&
          updateData.listDataService.some((e) => e.is30DayOver)) {

          const { is30DayOverTrueArray, is30DayOverFalseArray } = handleCheckArrayOver30Days({ listDataService: updateData.listDataService });

          if (is30DayOverTrueArray.length > 0 && is30DayOverFalseArray.length > 0) {
            //Mode Plan
            const value1 = await calculateAttachedType21(
              true,
              is30DayOverTrueArray,
              res.riyouKaigohoken?.kubunsikyu,
              res.riyousyaId,
              res.riyouKaigohoken.kyufu,
              dataTimeSetting,
              res.nengetu
            );
            const value2 = await calculateDateAttachedTableTypeNormal(
              true,
              is30DayOverFalseArray,
              res.riyouKaigohoken?.kubunsikyu,
              res,
              dataTimeSetting
            );
            let convertDataAttachedPlan = value2.dataAttached.concat(value1.dataAttached);
            let greyItems = _.filter(convertDataAttachedPlan, { color: "GREY" });
            greyItems = _.sortBy(greyItems, (e) => {
              return [e.dataRow.shuiruiCode, e.jigyousyoId, e.serviceCode, e.officeName];
            });

            const filteredObjects = greyItems.filter(
              (obj) => obj.color === "GREY" && obj.serviceFillDataList.length === 0 && obj.sikyugendogakuKubun === null,
            );

            let newGreyItems = greyItems.filter((item) => !filteredObjects.includes(item));
            let nonGreyItems = _.filter(convertDataAttachedPlan, (item) => item.color !== "GREY");

            let lastIndex = -1;
            for (let i = nonGreyItems.length - 1; i >= 0; i--) {
              if (nonGreyItems[i].color === "BLUE" && !nonGreyItems[i].serviceContent.includes("30日")) {
                lastIndex = i;
                break;
              }
            }

            if (lastIndex !== -1) {
              nonGreyItems.splice(lastIndex + 1, 0, ...filteredObjects);
            }
            convertDataAttachedPlan = nonGreyItems.concat(newGreyItems);

            let resultTotal = {
              smallTotal: {},
            };

            for (let key in value1.smallTotal) {
              resultTotal.smallTotal[key] = value1.smallTotal[key] + value2.smallTotal[key];
            }

            const formValuesPlan = {
              dataAttached: convertDataAttachedPlan,
              smallTotal: resultTotal.smallTotal,
            };

            // Actual
            const value3 = await calculateAttachedType21(
              false,
              is30DayOverTrueArray,
              res.riyouKaigohoken?.kubunsikyu,
              res.riyousyaId,
              res.riyouKaigohoken.kyufu,
              dataTimeSetting,
              res.nengetu
            );
            const value4 = await calculateDateAttachedTableTypeNormal(
              false,
              is30DayOverFalseArray,
              res.riyouKaigohoken?.kubunsikyu,
              res,
              dataTimeSetting
            );
            let convertDataAttachedActual = value4.dataAttached.concat(value3.dataAttached);

            let greyItemsActual = _.filter(convertDataAttachedActual, { color: "GREY" });
            greyItemsActual = _.sortBy(greyItemsActual, (e) => {
              return [e.dataRow.shuiruiCode, e.jigyousyoId, e.serviceCode, e.officeName];
            });

            const filteredObjectsActual = greyItemsActual.filter(
              (obj) => obj.color === "GREY" && obj.serviceFillDataList.length === 0 && obj.sikyugendogakuKubun === null,
            );

            let nonGreyItemsActual = _.filter(convertDataAttachedActual, (item) => item.color !== "GREY");
            let newGreyItemsActual = greyItemsActual.filter((item) => !filteredObjectsActual.includes(item));

            let lastIndexActual = -1;
            for (let i = nonGreyItemsActual.length - 1; i >= 0; i--) {
              if (nonGreyItemsActual[i].color === "BLUE" && !nonGreyItemsActual[i].serviceContent.includes("30日")) {
                lastIndexActual = i;
                break;
              }
            }

            if (lastIndexActual !== -1) {
              nonGreyItemsActual.splice(lastIndexActual + 1, 0, ...filteredObjectsActual);
            }
            convertDataAttachedActual = nonGreyItemsActual.concat(newGreyItemsActual);

            let resultTotalActual = {
              smallTotal: {},
            };

            for (let key in value3.smallTotal) {
              resultTotalActual.smallTotal[key] = value3.smallTotal[key] + value4.smallTotal[key];
            }
            const formValuesActual = {
              dataAttached: convertDataAttachedActual,
              smallTotal: resultTotalActual.smallTotal,
            };
            dataAttachedTable = formValuesPlan.dataAttached;
            smallTotalTable = formValuesPlan.smallTotal;
            dataAttachedTableActual = formValuesActual.dataAttached;
            smallTotalTableActual = formValuesActual.smallTotal;
          } else {
            const { dataAttached, smallTotal } = await calculateAttachedType21(
              true,
              updateData.listDataService,
              res.riyouKaigohoken?.kubunsikyu,
              res.riyousyaId,
              res.riyouKaigohoken.kyufu,
              dataTimeSetting,
              res.nengetu
            );
            smallTotalTable = smallTotal;
            dataAttachedTable = dataAttached;
            const dataAttachedActual = await calculateAttachedType21(
              true,
              updateData.listDataService,
              res.riyouKaigohoken?.kubunsikyu,
              res.riyousyaId,
              res.riyouKaigohoken.kyufu,
              dataTimeSetting,
              res.nengetu
            );
            dataAttachedTableActual = dataAttachedActual.dataAttached;
            smallTotalTableActual = dataAttachedActual.smallTotal;
          }
        } else {
          const { dataAttached, smallTotal } =
            calculateDateAttachedTableTypeNormal &&
            (await calculateDateAttachedTableTypeNormal(true, updateData.listDataService, res.riyouKaigohoken?.kubunsikyu, res, dataTimeSetting));
          const dataAttachedActual =
            calculateDateAttachedTableTypeNormal &&
            (await calculateDateAttachedTableTypeNormal(false, updateData.listDataService, res.riyouKaigohoken?.kubunsikyu, res, dataTimeSetting));
            smallTotalTable = smallTotal;
            dataAttachedTable = dataAttached;
            dataAttachedTableActual = dataAttachedActual.dataAttached;
            smallTotalTableActual = dataAttachedActual.smallTotal;
        }
      
        if (smallTotalTable.case19 < 0) {
          const blueRecord = dataAttachedTable.filter((e) => e.color === "BLUE");
          let isMutilOver = false;
          if (blueRecord.length > 1) {
            isMutilOver = true;
          }
          let dataOver = [];
          res.betuhyouList.forEach((el) => {
            blueRecord.forEach((e) => {
              if (el.serviceSyuruiCd === e.shuiruiCode && el.serviceKikanMaster.ryaku === e.officeName && el.futanGendoOver > 0 && !el.planf && !Number(el.type) && el.type !== "30日越") {
                // Kiểm tra xem phần tử e đã tồn tại trong dataOver chưa
                const combinedObject = {
                  el: el,
                  e: e
                };
                const isAlreadyAdded = dataOver.some((item) => item.e.shuiruiCode === e.shuiruiCode && item.e.officeName === e.officeName);

                // Nếu chưa tồn tại, thêm vào dataOver
                if (!isAlreadyAdded) {
                  dataOver.push(combinedObject);
                }
              }
            });
          });

          let calculationResult = null;
          const dataOverAttached = dataAttachedTable.filter((e) => e.color !== "WHITE");
          blueRecord.sort((a, b) => {
            let indexA = dataOver.findIndex(item => item.e.unitPrice === a.unitPrice);
            let indexB = dataOver.findIndex(item => item.e.unitPrice === b.unitPrice);
            if (indexA === -1 && indexB === -1) {
              return 0;
            } else if (indexA === -1) {
              return 1;
            } else if (indexB === -1) {
              return -1;
            } else {
              return indexA - indexB;
            }
          });
          
          for (const value of dataOver) {
            for (const record of blueRecord) {
                if (value.e.shuiruiCode === record.shuiruiCode &&
                    value.e.officeName === record.officeName &&
                    !record.is30DayOver) {
                    let result;
                    if (!calculationResult) {
                       const data = await calculateCase11(
                            dataAttachedTable,
                            record,
                            smallTotalTable,
                            dataOverAttached,
                            res.riyouKaigohoken?.kubunsikyu,
                            dataServiceFlat,
                            true,
                            isMutilOver,
                            res,
                            value,
                            dataTimeSetting
                        );
                        result = data.result;
                    } else {
                       const data = await calculateCase11(
                            dataAttachedTable,
                            record,
                            calculationResult,
                            dataOverAttached,
                            res.riyouKaigohoken?.kubunsikyu,
                            dataServiceFlat,
                            true,
                            isMutilOver,
                            res,
                            value,
                            dataTimeSetting
                        );
                        result = data.result;
                    }
                    const props = {
                      riyouhyouForm:{
                        hokenSelected: res.riyouKaigohoken
                      }
                    }
                    if(result){
                      await calculateAndProcess(dataAttachedTable, props, dataTimeSetting);
                      const { totalBurden, totalExternalSalary, totalAAAAA } = calTotalTable(dataAttachedTable);
                      result.totalBurden = totalBurden;
                      result.totalExternalSalary = totalExternalSalary;
                      result.totalAAAAA = totalAAAAA;
                      smallTotalTable = result;
                      calculationResult = result;
                    }
                }
            }
        }
      }
      //Actual
        if (smallTotalTableActual.case19 < 0) {

          const blueRecordActual = dataAttachedTableActual.filter((e) => e.color === "BLUE");
          let isMutiOverActual = false;

          if (blueRecordActual.length > 1) {
            isMutiOverActual = true;
          }

          let dataOverActual = [];

          res.betuhyouList.forEach((el) => {
            blueRecordActual.forEach((e) => {
              if (el.serviceSyuruiCd === e.shuiruiCode && el.serviceKikanMaster.ryaku === e.officeName && el.futanGendoOver > 0 && el.planf && !Number(el.type) && el.type !== "30日越") {
                // Kiểm tra xem phần tử e đã tồn tại trong dataOver chưa
                const combinedObject = {
                  el: el,
                  e: e
                };
                const isAlreadyAdded = dataOverActual.some((item) => item.shuiruiCode === e.shuiruiCode && item.officeName === e.officeName);

                // Nếu chưa tồn tại, thêm vào dataOver
                if (!isAlreadyAdded) {
                  dataOverActual.push(combinedObject);
                }
              }
            });
          });
          let calculationResultActual = null;
          const dataOverAttachedActual = dataAttachedTableActual.filter((e) => e.color !== "WHITE");

          blueRecordActual.sort((a, b) => {
            let indexA = dataOverActual.findIndex(item => item.e.unitPrice === a.unitPrice);
            let indexB = dataOverActual.findIndex(item => item.e.unitPrice === b.unitPrice);
            if (indexA === -1 && indexB === -1) {
              return 0;
            } else if (indexA === -1) {
              return 1;
            } else if (indexB === -1) {
              return -1;
            } else {
              return indexA - indexB;
            }
          });
          for (const value of dataOverActual) {
            for (const record of blueRecordActual) {
                if (value.e.shuiruiCode === record.shuiruiCode &&
                    value.e.officeName === record.officeName &&
                    !record.is30DayOver) {
                    let result;
                    if (!calculationResultActual) {
                        const data = await calculateCase11(
                            dataAttachedTableActual,
                            record,
                            smallTotalTableActual,
                            dataOverAttachedActual,
                            res.riyouKaigohoken?.kubunsikyu,
                            dataServiceFlat,
                            false,
                            isMutiOverActual,
                            res,
                            value,
                            dataTimeSetting
                        );
                        result = data.result;
                    } else {
                        const data = await calculateCase11(
                            dataAttachedTableActual,
                            record,
                            calculationResultActual,
                            dataOverAttachedActual,
                            res.riyouKaigohoken?.kubunsikyu,
                            dataServiceFlat,
                            false,
                            isMutiOverActual,
                            res,
                            value,
                            dataTimeSetting
                        );
                        result = data.result;
                    }
                    const props = {
                      riyouhyouForm:{
                        hokenSelected: res.riyouKaigohoken
                      }
                    }
                    if(result){
                      await calculateAndProcess(dataAttachedTableActual, props, dataTimeSetting);
                      const { totalBurden, totalExternalSalary, totalAAAAA } = calTotalTable(dataAttachedTableActual);
                      result.totalBurden = totalBurden;
                      result.totalExternalSalary = totalExternalSalary;
                      result.totalAAAAA = totalAAAAA;
                      smallTotalTableActual = result;
                      calculationResultActual = result;
                    }
                }
            }
        } 
      }
      res.betuhyouList.forEach((item) => {
        if (!item.planf) {
          handleUpdateDataAttached({ dataAttached: dataAttachedTable, item });
        } else {
          handleUpdateDataAttached({ dataAttached: dataAttachedTableActual, item });
        }
      });

      calculateSmallTotal({ smallTotal: smallTotalTable, dataAttached: dataAttachedTable, kubunsikyu: res.riyouKaigohoken?.kubunsikyu });
      calculateSmallTotal({ smallTotal: smallTotalTableActual, dataAttached: dataAttachedTableActual, kubunsikyu: res.riyouKaigohoken?.kubunsikyu });
      
        const startDate = res.riyouKaigohoken.yukoDateFrom.split("-")[0] + res.riyouKaigohoken.yukoDateFrom.split("-")[1];
        const endDate = res.riyouKaigohoken.yukoDateTo.split("-")[0] + res.riyouKaigohoken.yukoDateTo.split("-")[1];
        const bodyKyufuDay = {
          startDate,
          endDate,
          riyousyaId: res.riyousyaId,
          defineId: res.id,
          nengetu: res.nengetu
        }
        const responKyufuDay = await RequestUtils.callApi({
          method: "POST",
          path: ApiPaths.UseSlip.GetKyufuDays,
          body: bodyKyufuDay,
        });
        let sumListCountParent = 0;
        if (newListDataServices?.length > 0) {
          const listData = newListDataServices;
          const result = [];
          for (let i = 0; i < listData.length; i++) {
            const innerArray = listData[i];

            for (let j = 0; j < innerArray.length; j++) {
              const element = innerArray[j];
              if (element.isParent === true && !element.is30DayOver && KAIGO_CODE_SPECIAL.includes(element.dataRow.shuiruiCode)) {
                if (!res.keisanKubun) {
                  result.push(element.sumListFPlan);
                } else {
                  result.push(element.sumListFActual);
                }
              } else {
                sumListCountParent = 0;
              }
            }
          }

          if (result.length > 0) {
            const sum = result.reduce((accumulator, value) => {
              return accumulator + value;
            }, 0);
            sumListCountParent = sum;
          }
        } else {
          sumListCountParent = 0;
        }
        const bodyRenzoKudayLastMonth = { nengetu: res.nengetu, riyousyaId: res.riyousyaId };
        const dataRenzoKuday = await RequestUtils.callApi({
          method: "POST",
          path: ApiPaths.UseSlip.GetRenzokuLastMonth,
          body: bodyRenzoKudayLastMonth,
        });
        const data = {
          ...res,
          listDataRecord,
          listDataService: updateData.listDataService,
          listDataShow: newListDataServices,
          listDataSonota: listAfterSort,
          smallTotalTable,
          dataAttachedTable,
          smallTotalTableActual,
          dataAttachedTableActual,
          kyufuDays: responKyufuDay.kyufuDays,
          sumListCountParent,
          renzokuDays: dataRenzoKuday.renzokuDays
        }
        dispatch({
          type: UseSlipActionType.GET_RIYOUHYOU_VIEW_BY_ID_SUCCESS,
          data,
        });
      }
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_RIYOUHYOU_VIEW_BY_ID_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function stopLoadingDataView() {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.STOP_LOADING_VIEW
      })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getAllSyruiCd() {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.GET_ALL_SYURUI_MASTER_REQUEST
      })
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.UseSlip.GetAllSyruiCd
      });
      const dataReduce = data.reduce((accumulator, currentValue) => {
        accumulator[currentValue.serviceSyuruiCd] = currentValue;
        return accumulator;
      }, {});
      dispatch({
        type: UseSlipActionType.GET_ALL_SYURUI_MASTER_SUCCESS,
        dataReduce,
      });
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_ALL_SYURUI_MASTER_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function removeRiyouhyouView() {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.REMOVE_RIYOUHYOU_VIEW
      })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function setListOfficeActualChecked(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.SET_LIST_OFFICE_ACTUAL_CHECKED,
        data
      })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function handleUpdateQuoteActualByType(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: UseSlipActionType.GET_WEEKLY_QUOTE_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: apiUrls.SAVE_QUOTE_ACTUAL_BY_TYPE,
        body
      });
      if(data) {
        dispatch({
          type: UseSlipActionType.SET_LIST_WEEKLY_QUOTE_ACTUAL,
          data
        })
      }
    } catch (error) {
      dispatch({
        type: UseSlipActionType.GET_WEEKLY_QUOTE_FAILURE
      })
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function handleCoppyDataActual(data){
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.COPY_DATA_QUOTE_ACTUAL,
        data
      })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function handleSaveURLUseSlip(data){
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.SAVE_URL_USESLIP,
        data
      })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function saveSelectedKey(data){
  return async (dispatch) => {
    try {
      dispatch({
        type: UseSlipActionType.SAVE_SELECT_KEY,
        data
      })
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
export const UseSlipActions = {
  getAll,
  getRiyouhyouById,
  getListServiceType,
  getListUseSlipQuote,
  deleteId,
  getServiceContentById,
  getServiceAdditionByType,
  getSyuruiCd,
  getServiceCode,
  getListServiceAddition,
  getExpandCode,
  getAdditionByOffice,
  getServiceContentByTypeAndSyurui,
  getServiceColorMaster,
  getNaiyou,
  getWeeklySchedule,
  getDuplicateCheckNotPossible,
  getInformationKaigohoken,
  updateRiyouhyou,
  // removeAdditionOffice,
  // removeAdditionDuplicate,
  getHolidayMaster,
  removeRiyouHyou,
  getRiyouhyouIds,
  removeNaiyou,
  getListServiceAdditionOld,
  removeExpandCode,
  getAttached,
  setRiyouhyouForm,
  setRiyouhyouFormSuccess,
  removeRiyouhyouForm,
  getURL,
  isLoading,
  getRenzokuLastMonth,
  stopLoading,
  getOverProcess,
  getKyufuDayLastMonth,
  getServiceColorMasterSonota,
  setListDataServiceSonota,
  getListAttached,
  getOverProcessActual,
  getRiyouhyouQuoteList,
  getRiyouhyouQuoteById,
  getRiyouhyouLastQuoteById,
  removeRiyouQuote,
  getRiyouhyouRenzokuById,
  getUrlFromUseSlip,
  clearRiyouhyou,
  setUnSucessRiyouhyou,
  setListHistory,
  setStartFromManagement,
  setListManagement,
  removeUrlFromUseSlip,
  getAllSyruiCd,
  setOldParamsRedux,
  getRiyouhyouViewById,
  removeRiyouhyouView,
  stopLoadingDataView,
  setIsDuplicateMonthListManagement,
  setListOfficeActualChecked,
  handleUpdateQuoteActualByType,
  handleCoppyDataActual,
  handleSaveURLUseSlip,
  saveSelectedKey
};
