export const LOAD_IO_STATUS_REQUEST = "LOAD_IO_STATUS_REQUEST";
export const LOAD_IO_STATUS_SUCCESS = "LOAD_IO_STATUS_SUCCESS";
export const LOAD_PAGING_IO_STATUS_SUCCESS = "LOAD_PAGING_IO_STATUS_SUCCESS";
export const EDIT_IO_STATUS_REQUEST = "EDIT_IO_STATUS_REQUEST";
export const EDIT_IO_STATUS_SUCCESS = "EDIT_IO_STATUS_SUCCESS";
export const EDIT_IO_STATUS_ERROR = "EDIT_IO_STATUS_ERROR";
export const INSERT_IO_STATUS_REQUEST = "INSERT_IO_STATUS_REQUEST";
export const INSERT_IO_STATUS_SUCCESS = "INSERT_IO_STATUS_SUCCESS";
export const INSERT_IO_STATUS_ERROR = "INSERT_IO_STATUS_ERROR";
export const DELETE_IO_STATUS_SUCCESS = "DELETE_IO_STATUS_SUCCESS";
export const GET_DATA_SLEEP_OUTSIDE_SUCCESS = "GET_DATA_SLEEP_OUTSIDE_SUCCESS";
export const EDIT_KAIHAKU_KUBUN_SUCCESS = "EDIT_KAIHAKU_KUBUN_SUCCESS";
export const FILTER_SUCCESS = "FILTER_SUCCESS";
export const SORT_SUCCESS = "SORT_SUCCESS";
export const LOAD_FILTER_MASTER_SUCCESS = "LOAD_FILTER_MASTER_SUCCESS";
export const CHANGE_MEAL_STATUS = "CHANGE_MEAL_STATUS";
export const RESET_STATUS = "RESET_STATUS";
export const IN_OUT_QUOTE = "IN_OUT_QUOTE";
