import { TaskActionTypes, WeeklyPlanActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { WeeklyPlanActions } from "../actions/index";
function weeklyPlan(state = {}, action) {
  switch (action.type) {
    // get all weeklyPlan by user id
    case WeeklyPlanActionTypes.GET_ALL_WEEKLYPLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case WeeklyPlanActionTypes.GET_ALL_WEEKLYPLAN_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case WeeklyPlanActionTypes.GET_ALL_WEEKLYPLAN_FAILURE: {
      return {
        ...state,
        data: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }

    case WeeklyPlanActionTypes.GET_WEEKLYPLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getOne",
      };
    }
    case WeeklyPlanActionTypes.GET_WEEKLYPLAN_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case WeeklyPlanActionTypes.GET_WEEKLYPLAN_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // create weeklyPlan
    case WeeklyPlanActionTypes.CREATE_WEEKLYPLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case WeeklyPlanActionTypes.CREATE_WEEKLYPLAN_SUCCESS:
    case WeeklyPlanActionTypes.CREATE_WEEKLYPLAN_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // update weeklyPlan
    case WeeklyPlanActionTypes.UPDATE_WEEKLYPLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case WeeklyPlanActionTypes.UPDATE_WEEKLYPLAN_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case WeeklyPlanActionTypes.UPDATE_WEEKLYPLAN_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // remove weeklyPlan
    case WeeklyPlanActionTypes.REMOVE_WEEKLYPLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case WeeklyPlanActionTypes.REMOVE_WEEKLYPLAN_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case WeeklyPlanActionTypes.REMOVE_WEEKLYPLAN_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    case WeeklyPlanActionTypes.GET_ALL_WEEKLYPLAN_QUOTE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case WeeklyPlanActionTypes.GET_ALL_WEEKLYPLAN_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuote: action.data,
      };
    case WeeklyPlanActionTypes.GET_ALL_WEEKLYPLAN_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listQuoteTab: [],
      };
    case WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_TAB_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_TAB_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuoteTab: action.data,
      };
    case WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        listQuoteTab: [],
      };
    case WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    // Get kyotaku weekly plan by id
    case WeeklyPlanActionTypes.GET_WEEKLY_PLAN_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_WEEKLY_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        kyotakuWeeklyPlan: action.data,
        loading: false,
      };
    case WeeklyPlanActionTypes.GET_WEEKLY_PLAN_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        // kyotakuWeeklyPlan: [],
      };

    case WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        weeklyDetail: action.data,
      };
    case WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_TEMPLATE_SUCCESS:
      const weeklyDetailOld = state.weeklyDetail;
      weeklyDetailOld.kyotakuWeeklyPlanDetails = action.data;
      return {
        ...state,
        loading: false,
        weeklyDetail: { ...weeklyDetailOld },
      };
    case WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuote: action.data,
      };

    case WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listQuote: [],
      };
    case WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        weeklyDetail: action.data,
      };
    case WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_COLOR_MASTER_REQUEST:
      return {
        ...state,
        loadingColor: true,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_COLOR_MASTER_SUCCESS:
      return {
        ...state,
        loadingColor: false,
        listColorMaster: action.data,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_COLOR_MASTER_FAILURE:
      return {
        ...state,
        loadingColor: false,
        listColorMaster: [],
      };
    case WeeklyPlanActionTypes.GET_SERVICE_CONTENT_BY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_CONTENT_BY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        listServiceContent: action.data,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_CONTENT_BY_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        listServiceContent: [],
      };
    case WeeklyPlanActionTypes.GET_SERVICE_ADDITION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_ADDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        listServiceAddition: action.data,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_ADDITION_FAILURE:
      return {
        ...state,
        loading: false,
        listServiceAddition: [],
      };
    case WeeklyPlanActionTypes.GET_SERVICE_KIKAN_MASTER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_KIKAN_MASTER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceKikanMaster: action.data,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_KIKAN_MASTER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case WeeklyPlanActionTypes.KEEP_VALUE_BEFORE_SORT_WEEKLY_PLAN_SCHEDULE:
      return {
        ...state,
        listKeepValueSchedule: action.data,
      };
    case WeeklyPlanActionTypes.CLEAR_WEEKLY_PLAN_SCHEDULE:
      return {
        ...state,
        kyotakuWeeklyPlan: null,
      };
    case WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_SCHEDULE_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        isUpdated: true,
        weeklyUpdated: action.data,
      };
    }

    case WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_SCHEDULE_FAILURE:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        isUpdated: false,
      };
    case WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuoteRental: action.data,
      };
    case WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_TEMPLATE_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
      };
    case WeeklyPlanActionTypes.GET_IDS_SUCCESS:
      return { ...state, idsWeeklyData: action.idsWeeklyData };
    case WeeklyPlanActionTypes.GET_IDS_WEEKLY_PLAN:
      return { ...state };
    case WeeklyPlanActionTypes.GET_IDS_ERROR:
      return { ...state };
    case WeeklyPlanActionTypes.GET_RENTAL_SUCCESS:
      return { ...state, rentalData: action.rentalData };
    case WeeklyPlanActionTypes.GET_RENTAL:
      return { ...state };
    case WeeklyPlanActionTypes.GET_RENTAL_ERROR:
      return { ...state };

    // update weeklyPlan
    case TaskActionTypes.UPDATE_KADAI_SEIRI_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case TaskActionTypes.UPDATE_KADAI_SEIRI_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case TaskActionTypes.UPDATE_KADAI_SEIRI_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    case WeeklyPlanActionTypes.GET_ALL_SERVICE_CONTENT_RENTAL_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case WeeklyPlanActionTypes.GET_ALL_SERVICE_CONTENT_RENTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        listServiceContentRental: action.data,
      };
    case WeeklyPlanActionTypes.GET_ALL_SERVICE_CONTENT_RENTAL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case WeeklyPlanActionTypes.GET_LIST_RENTAL_SYOHIN_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_LIST_RENTAL_SYOHIN_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        listRentalSyohin: action.data,
      };
    case WeeklyPlanActionTypes.GET_LIST_RENTAL_SYOHIN_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceType: action.data,
      };
    case WeeklyPlanActionTypes.GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case WeeklyPlanActionTypes.UPDATE_WEEKLY_RENTAL_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case WeeklyPlanActionTypes.UPDATE_WEEKLY_RENTAL_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case WeeklyPlanActionTypes.UPDATE_WEEKLY_RENTAL_FAILURE:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        isUpdated: false,
      };
    case WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        rentalSyohinMaster: action.data,
      };
    case WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
        rentalSyohinMaster: null,
      };
    case WeeklyPlanActionTypes.CREATE_RENTAL_SYOHIN_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.CREATE_RENTAL_SYOHIN_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        rentalSyohinMaster: action.data,
        isReload: true,
      };
    case WeeklyPlanActionTypes.CREATE_RENTAL_SYOHIN_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
        rentalSyohinMaster: null,
      };
    case WeeklyPlanActionTypes.UPDATE_RENTAL_SYOHIN_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.UPDATE_RENTAL_SYOHIN_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        rentalSyohinMaster: action.data,
        isReload: true,
      };
    case WeeklyPlanActionTypes.UPDATE_RENTAL_SYOHIN_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
        rentalSyohinMaster: null,
      };
    case WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_INIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        rentalSyohinMaster: action.data,
      };
    case WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_INIT_FAILURE:
      return {
        ...state,
        loading: false,
        rentalSyohinMaster: null,
      };
    case WeeklyPlanActionTypes.TURN_OFF_RELOAD:
      return {
        ...state,
        isReload: false,
      };
    case WeeklyPlanActionTypes.GET_KYOTAKU_WEEKLY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WeeklyPlanActionTypes.GET_KYOTAKU_WEEKLY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        kyotakuWeekly: action.data,
      };
    case WeeklyPlanActionTypes.GET_KYOTAKU_WEEKLY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case WeeklyPlanActionTypes.UPDATE_RENTAL_SYOHIN_LIST:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        // loading: false,
        // listRentalSyohin: action.data,
      };
    default: {
      return { ...state };
    }
  }
}

export default weeklyPlan;
