import React from "react";

import { Row, Select } from "antd";

const Option = Select.Option;

export default class SelectGazouKubun extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.handleChangeSelect(value);
  }

  render() {
    const { data, value, styleCus, disabled } = this.props;
    return (
      <Row style={{ textAlign: styleCus ? styleCus : "center" }}>
        <Select
          disabled={disabled ? disabled : false}
          // showSearch
          style={{ width: 200 }}
          // placeholder="Select a person"
          // optionFilterProp="children"
          value={value}
          onChange={this.handleChange}
          // onFocus={this.handleFocus}
          // onBlur={this.handleBlur}
          // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option key={0} value={""}>
            &nbsp;
          </Option>
          {data
            ? data
                .toString()
                .split(";")
                .map((item, index) => {
                  return (
                    <Option
                      key={index + 1}
                      value={
                        item.toString().split("_")[1]
                          ? item.toString().split("_")[1]
                          : ""
                      }
                    >
                      {item.toString().split("_")[0]
                        ? item.toString().split("_")[0]
                        : ""}
                    </Option>
                  );
                })
            : null}
        </Select>
      </Row>
    );
  }
}
