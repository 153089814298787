/* eslint-disable */
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Checkbox, Col, Row } from "antd";
import _ from "lodash";
import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import SearchRiyousyaControl from '../../../common/component/control/SearchRiyousyaControl';
import SearchRiyousyaModal from '../../../common/component/control/SearchRiyousyaModal';

export class Toolbar2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            filterData: this.props.filterConditions,
            isVisibleSearchFurigana: false,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (JSON.stringify(newProps.filterConditions) != JSON.stringify(this.props.filterConditions)) {
            this.setState({
                filterData: newProps.filterConditions
            });
        }
    }

    searchByName(furigana) {
        let filterData = _.cloneDeep(this.state.filterData);
        filterData.searchName = furigana;
        this.setState({
            filterData: filterData
        });
        this.props.handleFilterData(filterData);
    }

    createReportCase() {
        if (this.props.riyousyaId) {
            this.props.handleGoToInputReportCase(this.props.riyousyaId);
        } else {
            this.setState({
                isVisibleSearchFurigana: true
            })
        }
    }

    onOkSearch(riyousya) {
        this.setState({
            isVisibleSearchFurigana: false
        });
        if (riyousya)
            this.props.handleGoToInputReportCase(riyousya.id.toString());
    }

    onCancelSearch() {
        this.setState({
            isVisibleSearchFurigana: false
        });
    }

    render() {
        let text, icon;
        if (this.props.expandAll) {
            text = '格納';
            icon = "minus-circle";
        } else {
            text = '展開';
            icon = "plus-circle";
        }

        return (
            <div className="w-100">
                {/* 1281以上 */}
                <MediaQuery query="(min-device-width: 1281px)">
                    {(matches) => matches ? (
                        <Row>
                            <Col sm={14} style={{ paddingBottom: "5px" }}>
                                <Button
                                    style={{ marginLeft: "10px" }}
                                    type={"primary"}
                                    icon={<LegacyIcon type={icon} />}
                                    onClick={() => this.props.handleChangeExpand()}
                                >{text}</Button>
                                <Button
                                    style={{ float: "right", marginRight: "5px" }}
                                    type={"primary"}
                                    icon={<LegacyIcon type={"plus"} />}
                                    onClick={() => this.createReportCase()}
                                >{"記録追加"}</Button>
                            </Col>
                            <Col sm={10} style={{ paddingBottom: "5px" }}>
                                {
                                    this.props.riyousyaId
                                        ?
                                        null
                                        :
                                        <div>
                                            <SearchRiyousyaControl
                                                style={{ margin: "0 auto", width: "fit-content" }}
                                                searchName={this.state.filterData ? this.state.filterData.searchName : ""}
                                                handleSearch={furigana => this.searchByName(furigana)}
                                            />
                                            <SearchRiyousyaModal
                                                visible={this.state.isVisibleSearchFurigana}
                                                onOk={riyousya => this.onOkSearch(riyousya)}
                                                onCancel={() => this.onCancelSearch()}
                                            />
                                        </div>
                                }
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col sm={7} style={{ paddingBottom: "5px" }}>
                                <Button
                                    style={{ marginLeft: "10px" }}
                                    type={"primary"}
                                    icon={<LegacyIcon type={icon} />}
                                    onClick={() => this.props.handleChangeExpand()}
                                >
                                    {text}
                                </Button>
                                <Button
                                    style={{ float: "right", marginRight: "5px" }}
                                    type={"primary"}
                                    icon={<LegacyIcon type={"plus"} />}
                                    onClick={() => this.createReportCase()}
                                >
                                    {"記録追加"}
                                </Button>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 9 }} style={{ paddingBottom: "5px" }}>
                                {
                                    this.props.riyousyaId
                                        ?
                                        null
                                        :
                                        <div>
                                            <SearchRiyousyaControl
                                                style={{ margin: "0 auto", width: "fit-content" }}
                                                searchName={this.state.filterData ? this.state.filterData.searchName : ""}
                                                handleSearch={furigana => this.searchByName(furigana)}
                                            />
                                            <SearchRiyousyaModal
                                                visible={this.state.isVisibleSearchFurigana}
                                                onOk={riyousya => this.onOkSearch(riyousya)}
                                                onCancel={() => this.onCancelSearch()}
                                            />
                                        </div>
                                }
                            </Col>
                            <Col sm={8} style={{ marginTop: 6 }}>
                                <Checkbox
                                    style={{ paddingLeft: "5px" }}
                                    checked={this.props.showMessageOnly}
                                    onChange={() => this.props.handleChangeMessageCheckbox()}
                                    value={this.props.showMessageOnly}
                                >{"申送りのみ"}</Checkbox>
                                <Checkbox
                                    checked={this.props.showDailyJournalOnly}
                                    onChange={() => this.props.handleChangeDailyJournalCheckbox()}
                                    value={this.props.showDailyJournalOnly}
                                >{"日誌のみ"}</Checkbox>
                                {
                                    this.props.use
                                        ?
                                        <Checkbox
                                            checked={this.props.showUseOnly}
                                            onChange={() => this.props.handleChangeUseCheckbox()}
                                            value={this.props.showUseOnly}
                                        >{"利用中のみ"}</Checkbox>
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    )}
                </MediaQuery>
            </div>
        );

    }
}
