import React, { useState, useEffect } from "react";
import { Checkbox} from "antd";
import { connect } from "react-redux";
import { UseSlipActions } from "../../redux/actions";
function WeeklyQuoteColorUseSlipModal(props) {
  const [data, setData] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [isClickChang, setIsClickChang] = useState(false);

  useEffect(() => {
    if (props.data) {
      let arrInit = props.data;
      let arr = [];
      let dataArr = [];
      for (let i = 0; i < arrInit.length; i++) {
        arr.push(arrInit[i]);
        if ((i != 0 && i % 4 == 3) || (i == arrInit.length - 1 && (arrInit.length - 1) % 4 != 3)) {
          dataArr.push(arr);
          arr = [];
        }
      }
      setData(dataArr);
    }
  }, [props.data]);

  useEffect(() => {
    setListChecked(props?.isListCheckType);
    setIsClickChang(false);
  }, [props?.isListCheckType]);

  useEffect(() => {
    if (isClickChang) {
      props.handelListChecked(listChecked);
    }
  }, [listChecked]);

  function handleChecked(e) {
    if (e.target.checked) {
      setListChecked([...listChecked, e.target.value]);
    } else {
      setListChecked([...listChecked.filter((item) => item !== e.target.value)]);
    }
    setIsClickChang(true);
  }

  const handleOnChange = (e) => {
    let newListOfficeActual = [...props.listOfficeActualChecked];
    if (e.target.checked) {
      newListOfficeActual.push(e.target.value);
    } else {
      newListOfficeActual = newListOfficeActual.filter((item) => item !== e.target.value);
    }
    props.setListOfficeActualChecked(newListOfficeActual);
  };

  return (
    <div className="color-master-useslip">
      {!props.visibleListOfficeActual ? (
        <>
          {data &&
            data.map((item, indexRow) => (
              <tr key={indexRow}>
                {item.map((record, index) => (
                  <td key={index} style={{ width: "250px" }}>
                    <Checkbox
                      value={record.ryaku}
                      style={{
                        padding: "3px 0px",
                        margin: "1px",
                        width: "100%",
                        backgroundColor: `${record.swColorBak}`,
                      }}
                      checked={listChecked.includes(record.ryaku)}
                      onChange={handleChecked}
                    >
                      {record.ryaku}
                    </Checkbox>
                  </td>
                ))}
              </tr>
            ))}
        </>
      ) : (
        <div style={{ border: "1px solid #c7c1c1", margin: "5px 0", borderRadius: "4px" }} className="modal-quote-actual">
          {props?.listOfficeActual.map((item, index) => (
            <div style={{ borderBottom: index !== props?.listOfficeActual.length - 1 && "1px solid #c7c1c1", padding: "0 5px" }} key={index}>
              <Checkbox
                key={item.id}
                value={item.id}
                checked={props.listOfficeActualChecked.length > 0 ? props.listOfficeActualChecked.includes(item.id) : false}
                onChange={handleOnChange}
              >
                <span className="ml-10">{`(${item.syuruiCd}) ${item.serviceKikanMaster.ryaku}`}</span>
              </Checkbox>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    listOfficeActual: state.useslip.listOfficeActual,
    listOfficeActualChecked: state.useslip.listOfficeActualChecked,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setListOfficeActualChecked: (list) => dispatch(UseSlipActions.setListOfficeActualChecked(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyQuoteColorUseSlipModal);
