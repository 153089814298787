import { UndoOutlined } from "@ant-design/icons";
import { Row } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import { ConstSet } from "../../common/configs/constset";
import {
  convertDateToX,
  extractRiyousyaInfo,
  getValueLocalstorage,
} from "../../common/function_common/functionCommon";
import "./ReportWeekly.css";
import Content from "./UI/Content";
import SubMenuInc from "./UI/SubMenuInc";

class Report extends Component {
  constructor(props) {
    super(props);

    const SetTimeout = getValueLocalstorage("sessionTimeout");

    /**
     *
     * get date
     * IF date from url is exited date = date on URL
     * Else IF date in localstorage is exited get it. (that is save from 記録日別)
     * else get TODAY
     *
     */
    const search = this.props.location.search; // could be '?timeRange=...'

    const params = new URLSearchParams(search);
    const dateUrl = params.get("date"); // timeRange
    const timeActive = localStorage.getItem("timeActive");

    const preDateLocal = localStorage.getItem("nyuDate");

    const weeklyDate = localStorage.getItem("weeklyDate");

    const today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

    let startDate;
    let endDate;
    if (dateUrl) {
      const tmp = convertDateToX(dateUrl);
      startDate = dayjs(tmp).add(-6, "days").format("YYYY-MM-DD");
      endDate = dayjs(tmp).format("YYYY-MM-DD");
    }
    else if (weeklyDate) {
      const tmp = convertDateToX(weeklyDate);
      startDate = dayjs(tmp).add(-6, "days").format("YYYY-MM-DD");
      endDate = dayjs(tmp).format("YYYY-MM-DD");
      localStorage.removeItem("weeklyDate");
    }
    else if (preDateLocal) {
      const tmp = convertDateToX(preDateLocal);
      startDate = dayjs(tmp).add(-6, "days").format("YYYY-MM-DD");
      endDate = dayjs(tmp).format("YYYY-MM-DD");
    }
    else {
      const tmp = convertDateToX(date);
      startDate = dayjs(tmp).add(-6, "days").format("YYYY-MM-DD");
      endDate = dayjs(tmp).format("YYYY-MM-DD");
    } // end date handle

    this.state = {
      visible: false,
      socket: this.props.socket,
      sesstionTimeout: false,
      SetTimeout: SetTimeout ? SetTimeout : 0,

      //
      tbDisType: false,

      //
      startDate,
      endDate,

      riyousayInfo: extractRiyousyaInfo(
        this.props.match.params.userId,
        localStorage.getItem("kirokuBasic")
      )[0],

      timeActive,
    };

    // bind place
    this.getReportRiyousya = this.getReportRiyousya.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePreviewDate = this.handlePreviewDate.bind(this);
    this.handleNextDate = this.handleNextDate.bind(this);
  }

  /**
   * Load for first Time
   */
  componentDidMount() {
    // Setting page befor redering DOM
    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    this.props.loadData(parameterObj);

    // Loading Setting Data
    this.props.loadSetting();

    // Loading plan
    this.props.loadPlan(this.state.endDate, this.props.match.params.userId);
  }

  /**
   * @TODO comment
   *
   */
  displayTableType = () => {
    this.setState({
      tbDisType: !this.state.tbDisType,
    });
  };

  /**
   * handle changing riyousyaId from search from
   *
   * @param {string} riyousyaId
   */
  getReportRiyousya = (value) => {
    if (value) {
      this.props.history.push(ConstSet.REPORT_WEEKLY_PAGE + value);
    }

    const parameterObj = {
      riyousyaId: value,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    this.props.loadData(parameterObj);

    // Loading plan
    this.props.loadPlan(this.state.endDate, value);
  };

  handleDateChange = (date) => {
    const startDate = dayjs(date).add(-6, "days").format("YYYY-MM-DD");
    const endDate = date;

    this.setState({
      startDate,
      endDate,
    });

    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      startDate,
      endDate,
    };

    this.props.loadData(parameterObj);

    // Loading plan
    this.props.loadPlan(endDate, this.props.match.params.userId);
  };

  /**
   * Preview Date
   *
   */
  handlePreviewDate = () => {
    const endDate = dayjs(this.state.endDate)
      .add(-1, "days")
      .format("YYYY-MM-DD");
    const startDate = dayjs(endDate).add(-6, "days").format("YYYY-MM-DD");

    this.setState({
      startDate,
      endDate,
    });

    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      startDate,
      endDate,
    };

    this.props.loadData(parameterObj);

    // Loading plan
    this.props.loadPlan(endDate, this.props.match.params.userId);
  };

  /**
   * Next date
   *
   */
  handleNextDate = () => {
    const endDate = dayjs(this.state.endDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
    const startDate = dayjs(endDate).add(-6, "days").format("YYYY-MM-DD");

    this.setState({
      startDate,
      endDate,
    });

    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      startDate,
      endDate,
    };

    this.props.loadData(parameterObj);

    // Loading plan
    this.props.loadPlan(endDate, this.props.match.params.userId);
  };

  /**
   * Double login or Timeout session
   */
  handleOk = () => {
    // Close Popup double login
    this.setState({
      visible: false,
    });
  };

  deleteRecords = (delValue) => {
    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    this.props.deleteRecords(delValue, parameterObj);
  };

  deleteAllImages = (record) => {
    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    this.props.deleteAllImages(record, parameterObj);
  };

  deleteAllRecords = (record) => {
    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    this.props.deleteAllRecords(record, parameterObj);
  };

  render() {
    const { tbDisType, endDate, timeActive } = this.state;
    const { reports, loading, settingData, planRiyoysya } = this.props;

    return (
      <CommonPageContainer sessionTimeout={this.props.sessionTimeout}>
        <Row className="submenu">
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={"記録入力（利用者）"}
                        iconType="form"
                      />
                    }
                    TopRight={
                      <Link
                        to={"/"}
                        className="sub-top-right-item"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.goBack();
                        }}
                      >
                        <span>
                          <UndoOutlined />
                        </span>
                        <span> 戻る</span>
                      </Link>
                    }
                    IncBottom={
                      <Row>
                        <SubMenuInc
                          tbDisType={tbDisType}
                          endDate={dayjs(endDate, ConstSet.DATE_FORMAT)}
                          riyousayInfo={this.state.riyousayInfo}
                          riyousyaId={this.props.match.params.userId}
                          modoru={
                            ConstSet.REPORT_WEEKLY_PAGE +
                            this.props.match.params.userId
                          }
                          planRiyoysya={planRiyoysya}
                          displayTableType={this.displayTableType}
                          handleDateChange={this.handleDateChange}
                          handlePreviewDate={this.handlePreviewDate}
                          handleNextDate={this.handleNextDate}
                          reportRiyousya={this.getReportRiyousya}
                        />
                      </Row>
                    }
                  />
                );
              }
              /**
                 * @TODO design UI for mobile version
                 */
              // return <SubHeaderMobile
              //   isDengon={false}
              //   isUser={false}
              //   isDailyRe={true}
              //   title={"記録入力（利用者）"}
              //   getTagDisplay={this.getTagDisplay}
              //   isTagDisPlay={true}
              // />

            }}
          </MediaQuery>
        </Row>

        <Row>
          <Content
            tbDisType={tbDisType}
            reports={reports}
            loading={loading}
            date={endDate}
            riyousyaId={this.props.match.params.userId}
            deleteRecords={this.deleteRecords}
            deleteAllImages={this.deleteAllImages}
            deleteAllRecords={this.deleteAllRecords}
            settingColor={
              settingData && settingData.weeklyColor
                ? settingData.weeklyColor
                : ""
            }
            // gazou kubun
            gazouKubun={
              settingData && settingData.gazouKubun
                ? settingData.gazouKubun
                : ""
            }
            //
            timeActive={timeActive}
            history={this.props.history}
          />
        </Row>
      </CommonPageContainer>
    );
  }
}

export default Report;
