import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";
import { checkLogin } from "../common/function_common/functionCommon";

let UserListApi = {
  userList(isGetAll) {
    return axios(
      apiUrls.INIT +
        apiUrls.GET_USER_LIST +
        `${isGetAll ? `?isGetAll=${isGetAll}` : ""}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        var data = [];
        res.data.map((ob, i) => {
          var obCus = Object.assign({}, ob);

          // format data following structure of ant component
          obCus.label = ob.name;
          obCus.value = ob.id;

          data.push(obCus);

          return null;
        });

        return data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },

  getDetail(userId) {
    return axios(
      apiUrls.INIT + apiUrls.GET_USER_LOGIN_DETAIL + `?userId=${userId}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
};
export default UserListApi;
