/* eslint-disable no-nested-ternary */
import { Button, DatePicker, Input, InputNumber, Modal, Select, TimePicker } from "antd";
import React from "react";
import { kanriQuerySearch } from "../../../common/function_common/functionCommon";

const { Option } = Select;

export default class SearchDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      row1: { value1: "", value2: "", value3: "", type: null },
      row2: { value1: "", value2: "", value3: "", type: null },
      row3: { value1: "", value2: "", value3: "", type: null },
    };
  }

  handleOk = () => {
    const { row1, row2, row3 } = this.state;

    this.props.handleOk(
      kanriQuerySearch(row1),
      kanriQuerySearch(row2),
      kanriQuerySearch(row3)
    );

    // reset
    // this.setState({
    //     row1: { value1: "", value2: "", value3: "", type: null },
    //     row2: { value1: "", value2: "", value3: "", type: null },
    //     row3: { value1: "", value2: "", value3: "", type: null },
    // })
  };

  handleCancel = () => {
    // this.setState({
    //     row1: { value1: "", value2: "", value3: "", type: null },
    //     row2: { value1: "", value2: "", value3: "", type: null },
    //     row3: { value1: "", value2: "", value3: "", type: null },
    // })
    //
    this.props.handleCancel();
  };

  onChangeParam = (type, e, options) => {
    //
    const { row1, row2, row3 } = this.state;

    switch (type) {
      case 11:
        row1.value1 = e;

        if (
          options.props.type === "Integer" ||
          options.props.type === "Short"
        ) {
          row1.type = 1;
        }
        else if (options.props.type === "Date") {
          row1.type = 3;
        }
        else if (options.props.type === "Time") {
          row1.type = 4;
        }
        else {
          row1.type = 2;
        }

        this.setState({
          row1,
        });

        break;

      case 12: // input text, value is e.target.value
        if (row1.type === 1) {
          row1.value2 = e;
        }

        if (row1.type === 3) {
          row1.value2 = e.format("YYYY-MM-DD");
        }

        if (row1.type === 4) {
          row1.value2 = e.format("HH:mm:ss");
        }

        if (row1.type === 2) {
          row1.value2 = e.target.value;
        }

        this.setState({
          row1,
        });

        break;

      case 13:
        row1.value3 = e;
        this.setState({
          row1,
        });

        break;

      case 21:
        row2.value1 = e;

        if (
          options.props.type === "Integer" ||
          options.props.type === "Short"
        ) {
          row2.type = 1;
        }
        else if (options.props.type === "Date") {
          row2.type = 3;
        }
        else if (options.props.type === "Time") {
          row2.type = 4;
        }
        else {
          row2.type = 2;
        }

        this.setState({
          row2,
        });

        break;

      case 22: // inpiut text, value is e.target.value
        if (row2.type === 1) {
          row2.value2 = e;
        }

        if (row2.type === 3) {
          row2.value2 = e.format("YYYY-MM-DD");
        }

        if (row2.type === 4) {
          row2.value2 = e.format("HH:mm:ss");
        }

        if (row2.type === 2) {
          row2.value2 = e.target.value;
        }

        this.setState({
          row2,
        });

        break;

      case 23:
        row2.value3 = e;
        this.setState({
          row2,
        });

        break;

      case 31:
        row3.value1 = e;

        if (
          options.props.type === "Integer" ||
          options.props.type === "Short"
        ) {
          row3.type = 1;
        }
        else if (options.props.type === "Date") {
          row3.type = 3;
        }
        else if (options.props.type === "Time") {
          row3.type = 4;
        }
        else {
          row3.type = 2;
        }

        this.setState({
          row3,
        });

        break;

      case 32: // input text, value is e.target.value
        if (row3.type === 1) {
          row3.value2 = e;
        }

        if (row3.type === 3) {
          row3.value2 = e.format("YYYY-MM-DD");
        }

        if (row3.type === 4) {
          row3.value2 = e.format("HH:mm:ss");
        }

        if (row3.type === 2) {
          row3.value2 = e.target.value;
        }

        this.setState({
          row3,
        });

        break;

      case 33:
        row3.value3 = e;
        this.setState({
          row3,
        });
        break;

      default:
        break;
    }
  };

  render() {
    const {
      title,
      loading,
      visible,
      subTitle1,
      subTitle2,
      subTitle3,
      searchData,
    } = this.props;
    const { row1, row2, row3 } = this.state;
    return (
      (<div>
        <Modal
          open={visible}
          title={title ? title : "検索画面"}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={this.handleOk}
            >
              OK
            </Button>,
          ]}
          className="kanri-search-dialog"
        >
          <div className="pljp-table pljp-table-bordered pljp-table-small">
            <div className="pljp-table-container">
              <div className="pljp-table-content">
                <table>
                  <colgroup></colgroup>
                  <thead className="pljp-table-thead">
                    <tr>
                      <th className="pljp-table-cell">
                        {subTitle1 ? subTitle1 : "対象項目"}
                      </th>
                      <th className="pljp-table-cell column-money">
                        {subTitle2 ? subTitle2 : "検索する文字"}
                      </th>
                      <th className="pljp-table-cell">
                        {subTitle3 ? subTitle3 : "検索方法"}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="pljp-table-tbody">
                    <tr
                      data-row-key="1"
                      className="pljp-table-row pljp-table-row-level-0"
                    >
                      <td className="pljp-table-cell" style={{ width: 200 }}>
                        <span>
                          <span></span>
                          <span>
                            <Select
                              style={{ width: 150, float: "right" }}
                              onChange={this.onChangeParam.bind(this, 11)}
                            >
                              {searchData && searchData.length > 0
                                ? searchData.map((item, index) => {
                                  return (
                                    <Option
                                      type={item.columType}
                                      value={item.columName}
                                      key={index}
                                    >
                                      {item.columText}
                                    </Option>
                                  );
                                })
                                : null}
                            </Select>
                          </span>
                        </span>
                      </td>
                      <td className="pljp-table-cell">
                        {
                          row1.type === 1 ? (
                            <InputNumber
                              onChange={this.onChangeParam.bind(this, 12)}
                            />
                          ) : row1.type === 3 ? (
                            <DatePicker
                              onChange={this.onChangeParam.bind(this, 12)}
                            />
                          ) : row1.type === 4 ? (
                            <TimePicker
                              showNow={false}
                              needConfirm={false}
                              onChange={this.onChangeParam.bind(this, 12)}
                            />
                          ) : (
                            <Input onChange={this.onChangeParam.bind(this, 12)} />
                          )}
                      </td>
                      <td className="pljp-table-cell">
                        <Select
                          style={{ width: 120 }}
                          onChange={this.onChangeParam.bind(this, 13)}
                        >
                          <Option value={1}>一致</Option>
                          <Option
                            value={2}
                            disabled={
                              !!(row1.type &&
                                (row1.type === 1 ||
                                  row1.type === 3 ||
                                  row1.type === 4))
                            }
                          >
                            を含む
                          </Option>
                          <Option
                            value={3}
                            disabled={
                              !!(row1.type &&
                                (row1.type === 1 ||
                                  row1.type === 3 ||
                                  row1.type === 4))
                            }
                          >
                            から始まる
                          </Option>
                          <Option
                            value={4}
                            disabled={
                              !!(row1.type &&
                                (row1.type === 1 ||
                                  row1.type === 3 ||
                                  row1.type === 4))
                            }
                          >
                            で終わる
                          </Option>
                          <Option value={5}>以外</Option>
                          <Option
                            value={6}
                            disabled={
                              !!(row1.type && row1.type === 2)
                            }
                          >
                            より大
                          </Option>
                          <Option
                            value={7}
                            disabled={
                              !!(row1.type && row1.type === 2)
                            }
                          >
                            より小
                          </Option>
                          <Option
                            value={8}
                            disabled={
                              !!(row1.type && row1.type === 2)
                            }
                          >
                            以上
                          </Option>
                          <Option
                            value={9}
                            disabled={
                              !!(row1.type && row1.type === 2)
                            }
                          >
                            以下
                          </Option>
                        </Select>
                      </td>
                    </tr>
                    <tr
                      data-row-key="2"
                      className="pljp-table-row pljp-table-row-level-0"
                    >
                      <td className="pljp-table-cell">
                        <span>
                          <span>かつ</span>
                          <span>
                            <Select
                              style={{ width: 150, float: "right" }}
                              onChange={this.onChangeParam.bind(this, 21)}
                            >
                              {searchData && searchData.length > 0
                                ? searchData.map((item, index) => {
                                  return (
                                    <Option
                                      type={item.columType}
                                      value={item.columName}
                                      key={index}
                                    >
                                      {item.columText}
                                    </Option>
                                  );
                                })
                                : null}
                            </Select>
                          </span>
                        </span>
                      </td>
                      <td className="pljp-table-cell">
                        {row2.type === 1 ? (
                          <InputNumber
                            onChange={this.onChangeParam.bind(this, 22)}
                          />
                        ) : row2.type === 3 ? (
                          <DatePicker
                            onChange={this.onChangeParam.bind(this, 22)}
                          />
                        ) : row2.type === 4 ? (
                          <TimePicker
                            showNow={false}
                            needConfirm={false}
                            onChange={this.onChangeParam.bind(this, 22)}
                          />
                        ) : (
                          <Input onChange={this.onChangeParam.bind(this, 22)} />
                        )}
                      </td>
                      <td className="pljp-table-cell">
                        <Select
                          style={{ width: 120 }}
                          onChange={this.onChangeParam.bind(this, 23)}
                          label="test"
                        >
                          <Option value={1}>一致</Option>
                          <Option
                            value={2}
                            disabled={
                              !!(row2.type &&
                                (row2.type === 1 ||
                                  row2.type === 3 ||
                                  row2.type === 4))
                            }
                          >
                            を含む
                          </Option>
                          <Option
                            value={3}
                            disabled={
                              !!(row2.type &&
                                (row2.type === 1 ||
                                  row2.type === 3 ||
                                  row2.type === 4))
                            }
                          >
                            から始まる
                          </Option>
                          <Option
                            value={4}
                            disabled={
                              !!(row2.type &&
                                (row2.type === 1 ||
                                  row2.type === 3 ||
                                  row2.type === 4))
                            }
                          >
                            で終わる
                          </Option>
                          <Option value={5}>以外</Option>
                          <Option
                            value={6}
                            disabled={
                              !!(row2.type && row2.type === 2)
                            }
                          >
                            より大
                          </Option>
                          <Option
                            value={7}
                            disabled={
                              !!(row2.type && row2.type === 2)
                            }
                          >
                            より小
                          </Option>
                          <Option
                            value={8}
                            disabled={
                              !!(row2.type && row2.type === 2)
                            }
                          >
                            以上
                          </Option>
                          <Option
                            value={9}
                            disabled={
                              !!(row2.type && row2.type === 2)
                            }
                          >
                            以下
                          </Option>
                        </Select>
                      </td>
                    </tr>
                    <tr
                      data-row-key="3"
                      className="pljp-table-row pljp-table-row-level-0"
                    >
                      <td className="pljp-table-cell">
                        <span>
                          <span>かつ</span>
                          <span>
                            <Select
                              style={{ width: 150, float: "right" }}
                              onChange={this.onChangeParam.bind(this, 31)}
                            >
                              {searchData && searchData.length > 0
                                ? searchData.map((item, index) => {
                                  return (
                                    <Option
                                      type={item.columType}
                                      value={item.columName}
                                      key={index}
                                    >
                                      {item.columText}
                                    </Option>
                                  );
                                })
                                : null}
                            </Select>
                          </span>
                        </span>
                      </td>
                      <td className="pljp-table-cell">
                        {row3.type === 1 ? (
                          <InputNumber
                            onChange={this.onChangeParam.bind(this, 32)}
                          />
                        ) : row3.type === 3 ? (
                          <DatePicker
                            onChange={this.onChangeParam.bind(this, 32)}
                          />
                        ) : row3.type === 4 ? (
                          <TimePicker
                            showNow={false}
                            needConfirm={false}
                            onChange={this.onChangeParam.bind(this, 32)}
                          />
                        ) : (
                          <Input onChange={this.onChangeParam.bind(this, 32)} />
                        )}
                      </td>
                      <td className="pljp-table-cell">
                        <Select
                          style={{ width: 120 }}
                          onChange={this.onChangeParam.bind(this, 33)}
                        >
                          <Option value={1}>一致</Option>
                          <Option
                            value={2}
                            disabled={
                              !!(row3.type &&
                                (row3.type === 1 ||
                                  row3.type === 3 ||
                                  row3.type === 4))
                            }
                          >
                            を含む
                          </Option>
                          <Option
                            value={3}
                            disabled={
                              !!(row3.type &&
                                (row3.type === 1 ||
                                  row3.type === 3 ||
                                  row3.type === 4))
                            }
                          >
                            から始まる
                          </Option>
                          <Option
                            value={4}
                            disabled={
                              !!(row3.type &&
                                (row3.type === 1 ||
                                  row3.type === 3 ||
                                  row3.type === 4))
                            }
                          >
                            で終わる
                          </Option>
                          <Option value={5}>以外</Option>
                          <Option
                            value={6}
                            disabled={
                              !!(row3.type && row3.type === 2)
                            }
                          >
                            より大
                          </Option>
                          <Option
                            value={7}
                            disabled={
                              !!(row3.type && row3.type === 2)
                            }
                          >
                            より小
                          </Option>
                          <Option
                            value={8}
                            disabled={
                              !!(row3.type && row3.type === 2)
                            }
                          >
                            以上
                          </Option>
                          <Option
                            value={9}
                            disabled={
                              !!(row3.type && row3.type === 2)
                            }
                          >
                            以下
                          </Option>
                        </Select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      </div>)
    );
  }
}
