import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";
// import { ConstSet } from '../common/configs/constset';

let ByoumeiMasterList = {
  loadList(bunrui, name) {
    return axios(apiUrls.INIT + apiUrls.GET_BYOUMEI_MASTER_LIST, {
      method: "POST",
      data: {
        bunrui: bunrui,
        name: name,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/login");
          }

          return err;
        }
      });
  },

  loadDataInit() {
    return axios(apiUrls.INIT + apiUrls.GET_BYOUMEI_MASTER_INIT, {
      method: "GET",
    }).then((res) => {
      return res.data;
    });
  },

  create(data) {
    return axios.post(apiUrls.CREATE_BYOUMEI_MASTER, data).then((res) => {
      return res.data;
    });
  },

  update(data) {
    return axios.put(apiUrls.UPDATE_BYOUMEI_MASTER, data).then((res) => {
      return res.data;
    });
  },

  updateList(data) {
    return axios.put(apiUrls.UPDATE_LIST_BYOUMEI, data).then((res) => {
      return res.data;
    });
  },
};

let DrugMasterList = {
  loadList(bunrui, name) {
    return axios(apiUrls.INIT + apiUrls.GET_DRUG_MASTER_LIST, {
      method: "POST",
      data: {
        bunrui: bunrui,
        name: name,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/login");
          }

          return err;
        }
      });
  },

  loadDataInit() {
    return axios(apiUrls.INIT + apiUrls.GET_DRUG_MASTER_INIT, {
      method: "GET",
    }).then((res) => {
      return res.data;
    });
  },

  create(data) {
    return axios.post(apiUrls.CREATE_DRUG_MASTER, data).then((res) => {
      return res.data;
    });
  },

  update(data) {
    return axios.put(apiUrls.UPDATE_DRUG_MASTER, data).then((res) => {
      return res.data;
    });
  },

  updateList(data) {
    return axios.put(apiUrls.UPDATE_LIST_DRUG, data).then((res) => {
      return res.data;
    });
  },
};

let MachineMasterList = {
  loadList(bunrui, name) {
    return axios(apiUrls.INIT + apiUrls.GET_MACHINE_MASTER_LIST, {
      method: "POST",
      data: {
        bunrui: bunrui,
        name: name,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/login");
          }

          return err;
        }
      });
  },

  loadDataInit() {
    return axios(apiUrls.INIT + apiUrls.GET_MACHINE_MASTER_INIT, {
      method: "GET",
    }).then((res) => {
      return res.data;
    });
  },

  create(data) {
    return axios.post(apiUrls.CREATE_MACHINE_MASTER, data).then((res) => {
      return res.data;
    });
  },

  update(data) {
    return axios.put(apiUrls.UPDATE_MACHINE_MASTER, data).then((res) => {
      return res.data;
    });
  },

  updateList(data) {
    return axios.put(apiUrls.UPDATE_LIST_MACHINE, data).then((res) => {
      return res.data;
    });
  },
};

let ServiceSyuruiMasterList = {
  loadList(bunrui, name) {
    return axios(apiUrls.INIT + apiUrls.GET_SERVICE_SYURUI_MASTER_LIST, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/login");
          }

          return err;
        }
      });
  },
};

let ServiceKikanMasterList = {
  loadList(bunrui, name) {
    return axios(apiUrls.INIT + apiUrls.GET_SERVICE_KIKAN_MASTER_LIST, {
      method: "POST",
      data: {
        bunrui: bunrui,
        name: name,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/login");
          }

          return err;
        }
      });
  },

  updateList(data) {
    return axios
      .put(apiUrls.UPDATE_LIST_SERVICE_KIKAN_MASTER, data)
      .then((res) => {
        return res.data;
      });
  },
};

let IryouKikanMasterList = {
  loadList(bunrui, name) {
    return axios(apiUrls.INIT + apiUrls.GET_IRYOU_KIKAN_MASTER_LIST, {
      method: "POST",
      data: {
        bunrui: bunrui,
        name: name,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/login");
          }

          return err;
        }
      });
  },

  loadDataInit() {
    return axios(apiUrls.INIT + apiUrls.GET_IRYOU_KIKAN_MASTER_INIT, {
      method: "GET",
    }).then((res) => {
      return res.data;
    });
  },

  create(data) {
    return axios.post(apiUrls.CREATE_IRYOU_KIKAN_MASTER, data).then((res) => {
      return res.data;
    });
  },

  update(data) {
    return axios.put(apiUrls.UPDATE_IRYOU_KIKAN_MASTER, data).then((res) => {
      return res.data;
    });
  },

  updateList(data) {
    return axios
      .put(apiUrls.UPDATE_LIST_IRYOU_KIKAN_MASTER, data)
      .then((res) => {
        return res.data;
      });
  },
};

export {
  ByoumeiMasterList,
  DrugMasterList,
  MachineMasterList,
  ServiceSyuruiMasterList,
  ServiceKikanMasterList,
  IryouKikanMasterList,
};
