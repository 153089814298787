import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";

export const life_KoKuTab_inputV3PDF = (data, jobList, tantoMaster) => {
  const GENDER_OPTIONS = [
    { label: "男", value: 1 },
    { label: "女", value: 2 },
  ];
  const GENERAL_OPTIONS = [
    { label: "あり", value: "1" },
    { label: "なし", value: "0" },
  ];
  const SCREENING_OPTIONS = [
    { label: "あり", value: "1" },
    { label: "なし", value: "0" },
    { label: "分からない", value: "2" },
  ];
  const ALT_SCREENING_OPTIONS = [
    { label: "できる", value: "0" },
    { label: "できない", value: "1" },
    { label: "分からない", value: "2" },
  ];
  const JOB_OPTIONS = [
    { label: "歯科衛生士", value: "130" },
    { label: "看護職員", value: "040" },
    { label: "言語聴覚士", value: "100" },
  ];
  const SPECIAL_OPTIONS = {
    caries: "歯（う蝕、修復物脱離等）、義歯（義歯不適合等）、歯周病、口腔粘膜疾患（潰瘍等） の疾患の可能性",
    diseasesRelated: "音声・言語機能に関する疾患の可能性",
    other: "その他"
  };
  const ODONTOPATHY_OPTIONS = [
    { label: "重症化防止", value: "重症化防止" },
    { label: "改善", value: "改善" },
    { label: "歯科受診", value: "歯科受診" },
  ];
  const TARGET_OPTIONS = [
    { label: "維持", value: "0" },
    { label: "改善", value: "1" },
  ];
  const DENTURE_OPTIONS = [
    { label: "部分", value: "2" },
    { label: "全部", value: "3" },
  ];
  const INGESTION_OPTIONS = [
    { label: "経口のみ", value: "2" },
    { label: "一部経口", value: "1" },
  ];

  const createOptions = (options) => {
    if (options && options) {
      return options
        .split(";")
        .filter((item) => !!item)
        .map((item) => {
          let temp = item.includes(":") ? item.split(":")[0] : item;
          let tmplabel = item.includes(":") ? item.split(":")[1] : item;
          return { label: tmplabel, value: temp };
        });
    }
    return [];
  };

  const selectOption = (optionSelected, options) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result = item.label;
      }
    });
    return result;
  };

  const getCareLevelAndDisease = (careLevel, kaigodo, disease) => {
    let result = "";
    const tmpKaigodo = kaigodo?.find(item => item.kaigodoNo === careLevel);
    if (tmpKaigodo) {
      result = `${tmpKaigodo.kaigodoName}・${disease ?? ""}`
    } else {
      result = disease ?? "";
    }
    return result;
  };

  const createCheckBox = (optionSelected, options, space) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
      if (space) {
        for(let i = 0; i < space; i++) {
          result += " ";
        }
      }
    });
    return result;
  };

  const createMultiCheckBox = (optionSelected, options) => {
    let result = "";
    options.forEach(item => {
      if (optionSelected?.includes(item.value)) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    return result;
  }

  const createSpecialCheckBox = (caries, drvlf, tpoc) => {
    let result = "";
    if (caries === "1") {
      result += "■" + SPECIAL_OPTIONS.caries + "\n";
    } else {
      result += "□" + SPECIAL_OPTIONS.caries + "\n";
    }
    if (drvlf === "1") {
      result += "■" + SPECIAL_OPTIONS.diseasesRelated + "\n";
    } else {
      result += "□" + SPECIAL_OPTIONS.diseasesRelated + "\n";
    }
    if (tpoc !== "" && tpoc !== null) {
      result += "■" + SPECIAL_OPTIONS.other + "(" + tpoc + ")";
    } else {
      result += "□" + SPECIAL_OPTIONS.other;
    }
    return result;
  };

  const createTargetCheckBox = (value, target, label) => {
    let result = "";
    if (value !== "" && value !== null) {
      result += "■" + label + " ";
    } else {
      result += "□" + label + " ";
    }
    result += `(${createCheckBox(value, TARGET_OPTIONS)}`;
    if (target) {
      result += `(${target}) `
    }
    result += ")";
    return result;
  };

  const singleCheckBox = (value, label) => {
    const SINGLE_OPTION = [
      { label: label, value: "1" }
    ]
    return createCheckBox(value, SINGLE_OPTION);
  };

  const dentureCheckBox = (denture) => {
    let result = "";
    if (denture === "2" || denture === "3") {
      result += "■" + "あり";
    } else {
      result += "□" + "あり";
    }
    result += ` (${createCheckBox(denture, DENTURE_OPTIONS)}) ${singleCheckBox(denture, "なし")}`
    return result;
  };

  const mealFormCheckBox = (meal, options) => {
    let result = "";
    if (meal && options) {
      const tmp = options.find(item => item.value === meal);
      result = tmp.label;
    }
    return result;
  };

  const { kokueiseikokukino, lifeYougoList, kaigodo } = data;

  if (typeof kokueiseikokukino === 'object' && Array.isArray(kokueiseikokukino)) {
    let sampledata = [];
    kokueiseikokukino?.map((kokueiseikokukino) => {
      const kokuScreening = kokueiseikokukino.kokuScreening[0];
      const kokuManagement = kokueiseikokukino.kokuManagement[0];
      const kokuImplementationRecord = kokueiseikokukino.kokuImplementationRecord[0];

      sampledata.push({
        評価日: formatSeireki(kokueiseikokukino?.makeDate ?? ""),
        氏名: `${kokueiseikokukino?.riyousya?.name ?? ""} (${kokueiseikokukino?.riyousya?.furigana ?? ""})` ?? "",
        生年月日: `${formatBirthday(kokueiseikokukino?.riyousya?.birthday ?? "")}`,
        性別: selectOption(kokueiseikokukino?.riyousya?.gender ?? "", GENDER_OPTIONS),
        "要介護度・病名等": getCareLevelAndDisease(kokueiseikokukino?.careLevel, kaigodo, kokueiseikokukino?.diseaseName),
        障害高齢者: kokueiseikokukino?.impairedDegree ?? "",
        認知症高齢者: kokueiseikokukino?.dementiaDegree ?? "",
        かかりつけ歯科医: createCheckBox(kokueiseikokukino?.familyDentist ?? "", GENERAL_OPTIONS),
        直近1年間の歯科受診: createCheckBox(kokueiseikokukino?.dentalCheckup != "0001-01-01" ? "1" : "0", GENERAL_OPTIONS, 32),
        最終受診年月: kokueiseikokukino?.dentalCheckup != "0001-01-01" ? formatSeireki(kokueiseikokukino?.dentalCheckup ?? "") : "",
        義歯の使用: dentureCheckBox(kokueiseikokukino?.dentureUsing),
        栄養補給法: `${createCheckBox(kokueiseikokukino?.foodFormIngestion, INGESTION_OPTIONS)} ${singleCheckBox(kokueiseikokukino?.foodFormEnteralNutrition, "経腸栄養")} ${singleCheckBox(kokueiseikokukino?.foodFormTubeFeeding, "静脈栄養")}`,
        食事形態: mealFormCheckBox(kokueiseikokukino?.mealForm, createOptions(lifeYougoList?.bunrui1)),
        "誤嚥性肺炎の発症・既往": createCheckBox(kokueiseikokukino?.aspirationPneumonitis ?? "", GENERAL_OPTIONS, 42),
        直近の発症年月: kokueiseikokukino?.dateOfOnset01 != "0001-01-01" ? formatSeireki(kokueiseikokukino?.dateOfOnset01 ?? "") : "",
        作成日1: formatSeireki(kokuScreening?.date ?? ""),
        記入者: `${kokuScreening?.tantoMaster?.name ?? ""}　${selectOption(kokuScreening?.tantoMaster?.jobCategory ?? "", JOB_OPTIONS)}`,
        口臭: createCheckBox(kokuScreening?.problemBadBreath ?? "", SCREENING_OPTIONS),
        歯の汚れ: createCheckBox(kokuScreening?.teethStaining ?? "", SCREENING_OPTIONS),
        義歯の汚れ: createCheckBox(kokuScreening?.dentureStaining ?? "", SCREENING_OPTIONS),
        舌苔: createCheckBox(kokuScreening?.coatingTongue ?? "", SCREENING_OPTIONS),
        奥歯のかみ合わせ: createCheckBox(kokuScreening?.teethAlignment ?? "", SCREENING_OPTIONS),
        食べこぼし: createCheckBox(kokuScreening?.problemSpillFood ?? "", SCREENING_OPTIONS),
        むせ: createCheckBox(kokuScreening?.choke ?? "", SCREENING_OPTIONS),
        口腔乾燥: createCheckBox(kokuScreening?.drynessOfMouth ?? "", SCREENING_OPTIONS),
        舌の動きが悪い: createCheckBox(kokuScreening?.moveTongue ?? "", SCREENING_OPTIONS),
        ぶくぶくうがい: createCheckBox(kokuScreening?.gargle ?? "", ALT_SCREENING_OPTIONS),
        歯科受診の必要性: createCheckBox(kokuScreening?.necessityDentalVisit ?? "", SCREENING_OPTIONS),
        特記事項: createSpecialCheckBox(
          kokuScreening?.caries, kokuScreening?.diseasesRelatedVoiceLanguageFunction, kokuScreening?.teethProblemOtherContents ?? ""
        ),
        作成日2: formatSeireki(kokuManagement?.assessDate ?? ""),
        計画立案者: `${kokuManagement?.planTantoMaster?.name ?? ""}　${selectOption(kokuManagement?.planTantoMaster?.jobCategory ?? "", JOB_OPTIONS)}`,
        サービス提供者1: `${kokuManagement?.providerTantoMaster?.name ?? ""}　${selectOption(kokuManagement?.providerTantoMaster?.jobCategory ?? "", JOB_OPTIONS)}`,
        歯科疾患: `${singleCheckBox(kokuManagement?.odontopathy ? "1" : "0", "歯科疾患")}(${createMultiCheckBox(kokuManagement?.odontopathy, ODONTOPATHY_OPTIONS)})`,
        口腔衛生: createTargetCheckBox(kokuManagement?.oralHygiene ?? "", kokuManagement?.oralHygieneNotice, "口腔衛生"),
        摂食嚥下等の口腔機能: createTargetCheckBox(kokuManagement?.oralCavityFunctionFlag ?? "", kokuManagement?.oralCavityFunctionNotice, "摂食嚥下等の口腔機能"),
        食形態: createTargetCheckBox(kokuManagement?.foodFormFlag ?? "", kokuManagement?.foodFormNotice, "食形態"),
        栄養状態: createTargetCheckBox(kokuManagement?.nutritionalStatus ?? "", kokuManagement?.nutritionalStatusNotice, "栄養状態"),
        "音声・言語機能": createTargetCheckBox(kokuManagement?.languageVoiceFunctionFlag ?? "", kokuManagement?.languageVoiceFunctionNotice, "音声・言語機能"),
        誤嚥性肺炎の予防: singleCheckBox(kokuManagement?.preventionOfAspirationPneumonia, "誤嚥性肺炎の予防"),
        その他1: `${singleCheckBox(kokuManagement?.goalOtherContents ? "1" : "0", "その他")}${kokuManagement?.goalOtherContents ? "("+kokuManagement?.goalOtherContents+")" : ""}`,
        口腔清掃: singleCheckBox(kokuManagement?.oralCleaning, "口腔清掃"),
        口腔清掃に関する指導: singleCheckBox(kokuManagement?.contentsGuidanceOralCleaning, "口腔清掃に関する指導"),
        摂食嚥下等の口腔機能に関する指導: singleCheckBox(kokuManagement?.contentsGuidanceEatingSwallowing, "摂食嚥下等の口腔機能に関する指導"),
        "音声・言語機能に関する指導": singleCheckBox(kokuManagement?.contentsGuidanceLanguageVoiceFunction, "音声・言語機能に関する指導"),
        誤嚥性肺炎の予防に関する指導: singleCheckBox(kokuManagement?.guidancePreventionAspirationPneumonia, "誤嚥性肺炎の予防に関する指導"),
        その他2: `${singleCheckBox(kokuManagement?.isContentsOtherNotices ? "1" : "0", "その他")}${kokuManagement?.isContentsOtherNotices ? "("+kokuManagement?.isContentsOtherNotices+")" : ""}`,
        実施年月日: formatSeireki(kokuImplementationRecord?.date ?? ""),
        サービス提供者2: `${kokuImplementationRecord?.tantoMaster?.name ?? ""}　${selectOption(kokuImplementationRecord?.tantoMaster?.jobCategory ?? "", JOB_OPTIONS)}`,
        口腔清掃実施: singleCheckBox(kokuImplementationRecord?.oralCleaning, "実施"),
        口腔清掃に関する指導実施: singleCheckBox(kokuImplementationRecord?.contentsGuidanceOralCleaning, "実施"),
        摂食嚥下等の口腔機能に関する指導実施: singleCheckBox(kokuImplementationRecord?.contentsGuidanceEatingSwallowing, "実施"),
        "音声・言語機能に関する指導実施": singleCheckBox(kokuImplementationRecord?.contentsGuidanceLanguageVoiceFunction, "実施"),
        誤嚥性肺炎の予防に関する指導実施: singleCheckBox(kokuImplementationRecord?.guidancePreventionAspirationPneumonia, "実施"),
        その他実施: singleCheckBox(kokuImplementationRecord?.oralCareOtherContents ? "1" : "0", "実施"),
        その他実施内容: kokuImplementationRecord?.oralCareOtherContents ?? "",
        その他特記事項: kokueiseikokukino?.specialNotes ?? ""
      })
    })
    return sampledata;
  } else if (typeof kokueiseikokukino === 'object') {
    const kokuScreening = kokueiseikokukino.kokuScreening[0];
    const kokuManagement = kokueiseikokukino.kokuManagement[0];
    const kokuImplementationRecord = kokueiseikokukino.kokuImplementationRecord[0];

    let sampledata = [
      {
        評価日: formatSeireki(kokueiseikokukino?.makeDate ?? ""),
        氏名: `${kokueiseikokukino?.riyousya?.name ?? ""} (${kokueiseikokukino?.riyousya?.furigana ?? ""})` ?? "",
        生年月日: `${formatBirthday(kokueiseikokukino?.riyousya?.birthday ?? "")}`,
        性別: selectOption(kokueiseikokukino?.riyousya?.gender ?? "", GENDER_OPTIONS),
        "要介護度・病名等": getCareLevelAndDisease(kokueiseikokukino?.careLevel, kaigodo, kokueiseikokukino?.diseaseName),
        障害高齢者: kokueiseikokukino?.impairedDegree ?? "",
        認知症高齢者: kokueiseikokukino?.dementiaDegree ?? "",
        かかりつけ歯科医: createCheckBox(kokueiseikokukino?.familyDentist ?? "", GENERAL_OPTIONS),
        直近1年間の歯科受診: createCheckBox(kokueiseikokukino?.dentalCheckup != "0001-01-01" ? "1" : "0", GENERAL_OPTIONS, 32),
        最終受診年月: kokueiseikokukino?.dentalCheckup != "0001-01-01" ? formatSeireki(kokueiseikokukino?.dentalCheckup ?? "") : "",
        義歯の使用: dentureCheckBox(kokueiseikokukino?.dentureUsing),
        栄養補給法: `${createCheckBox(kokueiseikokukino?.foodFormIngestion, INGESTION_OPTIONS)} ${singleCheckBox(kokueiseikokukino?.foodFormEnteralNutrition, "経腸栄養")} ${singleCheckBox(kokueiseikokukino?.foodFormTubeFeeding, "静脈栄養")}`,
        食事形態: mealFormCheckBox(kokueiseikokukino?.mealForm, createOptions(lifeYougoList?.bunrui1)),
        "誤嚥性肺炎の発症・既往": createCheckBox(kokueiseikokukino?.aspirationPneumonitis ?? "", GENERAL_OPTIONS, 42),
        直近の発症年月: kokueiseikokukino?.dateOfOnset01 != "0001-01-01" ? formatSeireki(kokueiseikokukino?.dateOfOnset01 ?? "") : "",
        作成日1: formatSeireki(kokuScreening?.date ?? ""),
        記入者: `${kokuScreening?.tantoMaster?.name ?? ""}　${selectOption(kokuScreening?.tantoMaster?.jobCategory ?? "", JOB_OPTIONS)}`,
        口臭: createCheckBox(kokuScreening?.problemBadBreath ?? "", SCREENING_OPTIONS),
        歯の汚れ: createCheckBox(kokuScreening?.teethStaining ?? "", SCREENING_OPTIONS),
        義歯の汚れ: createCheckBox(kokuScreening?.dentureStaining ?? "", SCREENING_OPTIONS),
        舌苔: createCheckBox(kokuScreening?.coatingTongue ?? "", SCREENING_OPTIONS),
        奥歯のかみ合わせ: createCheckBox(kokuScreening?.teethAlignment ?? "", SCREENING_OPTIONS),
        食べこぼし: createCheckBox(kokuScreening?.problemSpillFood ?? "", SCREENING_OPTIONS),
        むせ: createCheckBox(kokuScreening?.choke ?? "", SCREENING_OPTIONS),
        口腔乾燥: createCheckBox(kokuScreening?.drynessOfMouth ?? "", SCREENING_OPTIONS),
        舌の動きが悪い: createCheckBox(kokuScreening?.moveTongue ?? "", SCREENING_OPTIONS),
        ぶくぶくうがい: createCheckBox(kokuScreening?.gargle ?? "", ALT_SCREENING_OPTIONS),
        歯科受診の必要性: createCheckBox(kokuScreening?.necessityDentalVisit ?? "", SCREENING_OPTIONS),
        特記事項: createSpecialCheckBox(
          kokuScreening?.caries, kokuScreening?.diseasesRelatedVoiceLanguageFunction, kokuScreening?.teethProblemOtherContents ?? ""
        ),
        作成日2: formatSeireki(kokuManagement?.assessDate ?? ""),
        計画立案者: `${kokuManagement?.planTantoMaster?.name ?? ""}　${selectOption(kokuManagement?.planTantoMaster?.jobCategory ?? "", JOB_OPTIONS)}`,
        サービス提供者1: `${kokuManagement?.providerTantoMaster?.name ?? ""}　${selectOption(kokuManagement?.providerTantoMaster?.jobCategory ?? "", JOB_OPTIONS)}`,
        歯科疾患: `${singleCheckBox(kokuManagement?.odontopathy ? "1" : "0", "歯科疾患")}(${createMultiCheckBox(kokuManagement?.odontopathy, ODONTOPATHY_OPTIONS)})`,
        口腔衛生: createTargetCheckBox(kokuManagement?.oralHygiene ?? "", kokuManagement?.oralHygieneNotice, "口腔衛生"),
        摂食嚥下等の口腔機能: createTargetCheckBox(kokuManagement?.oralCavityFunctionFlag ?? "", kokuManagement?.oralCavityFunctionNotice, "摂食嚥下等の口腔機能"),
        食形態: createTargetCheckBox(kokuManagement?.foodFormFlag ?? "", kokuManagement?.foodFormNotice, "食形態"),
        栄養状態: createTargetCheckBox(kokuManagement?.nutritionalStatus ?? "", kokuManagement?.nutritionalStatusNotice, "栄養状態"),
        "音声・言語機能": createTargetCheckBox(kokuManagement?.languageVoiceFunctionFlag ?? "", kokuManagement?.languageVoiceFunctionNotice, "音声・言語機能"),
        誤嚥性肺炎の予防: singleCheckBox(kokuManagement?.preventionOfAspirationPneumonia, "誤嚥性肺炎の予防"),
        その他1: `${singleCheckBox(kokuManagement?.goalOtherContents ? "1" : "0", "その他")}${kokuManagement?.goalOtherContents ? "("+kokuManagement?.goalOtherContents+")" : ""}`,
        口腔清掃: singleCheckBox(kokuManagement?.oralCleaning, "口腔清掃"),
        口腔清掃に関する指導: singleCheckBox(kokuManagement?.contentsGuidanceOralCleaning, "口腔清掃に関する指導"),
        摂食嚥下等の口腔機能に関する指導: singleCheckBox(kokuManagement?.contentsGuidanceEatingSwallowing, "摂食嚥下等の口腔機能に関する指導"),
        "音声・言語機能に関する指導": singleCheckBox(kokuManagement?.contentsGuidanceLanguageVoiceFunction, "音声・言語機能に関する指導"),
        誤嚥性肺炎の予防に関する指導: singleCheckBox(kokuManagement?.guidancePreventionAspirationPneumonia, "誤嚥性肺炎の予防に関する指導"),
        その他2: `${singleCheckBox(kokuManagement?.isContentsOtherNotices ? "1" : "0", "その他")}${kokuManagement?.isContentsOtherNotices ? "("+kokuManagement?.isContentsOtherNotices+")" : ""}`,
        実施年月日: formatSeireki(kokuImplementationRecord?.date ?? ""),
        サービス提供者2: `${kokuImplementationRecord?.tantoMaster?.name ?? ""}　${selectOption(kokuImplementationRecord?.tantoMaster?.jobCategory ?? "", JOB_OPTIONS)}`,
        口腔清掃実施: singleCheckBox(kokuImplementationRecord?.oralCleaning, "実施"),
        口腔清掃に関する指導実施: singleCheckBox(kokuImplementationRecord?.contentsGuidanceOralCleaning, "実施"),
        摂食嚥下等の口腔機能に関する指導実施: singleCheckBox(kokuImplementationRecord?.contentsGuidanceEatingSwallowing, "実施"),
        "音声・言語機能に関する指導実施": singleCheckBox(kokuImplementationRecord?.contentsGuidanceLanguageVoiceFunction, "実施"),
        誤嚥性肺炎の予防に関する指導実施: singleCheckBox(kokuImplementationRecord?.guidancePreventionAspirationPneumonia, "実施"),
        その他実施: singleCheckBox(kokuImplementationRecord?.oralCareOtherContents ? "1" : "0", "実施"),
        その他実施内容: kokuImplementationRecord?.oralCareOtherContents ?? "",
        その他特記事項: kokueiseikokukino?.specialNotes ?? ""
      }
    ];
    return sampledata;
  } else {
    return [{}];
  }
}