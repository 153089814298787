import { YOUGO_MASTER_DEPART_NAIYOU, YOUGO_MASTER_ECT_CANCEL, YOUGO_MASTER_ECT_SAVE, YOUGO_MASTER_ECT_SELECT, YOUGO_MASTER_ECT_STATE, YOUGO_MASTER_EDIT_CANCEL, YOUGO_MASTER_EDIT_STATE, YOUGO_MASTER_JOUTAI, YOUGO_MASTER_KUSURI, YOUGO_MASTER_LOAD, YOUGO_MASTER_NAIYOU, YOUGO_MASTER_NAIYOU2, YOUGO_MASTER_NAIYOU_BUNRUI, YOUGO_MASTER_NAIYOU_NEW, YOUGO_MASTER_RIYOU_EDIT_PAGE_LIST, YOUGO_MASTER_ROOM_NAIYOU, YOUGO_MASTER_SHOKUJISEN_PAGE_LIST, YOUGO_MASTER_STAFFKIND_NAIYOU, YOUGO_MASTER_SYURUI, YOUGO_MASTER_TANTO, YOUGO_MASTER_TANTOBUNRUI_NAIYOU, YOUGO_MASTER_UPDATE_NEW, YOUGO_MASTER_UPDATE_ROOM, YOUGO_MASTER_WEATHER_NAIYOU } from "../../actions/yougoMasterNaiyouActions/actionName.js";
import { YougoMasterAPI, YougoMasterEctApi, YougoMasterEditApi, YougoMasterEditApiNew, YougoMasterEditEctApi, YougoMasterNaiyouApi, YougoMasterUpdateApi } from "../../api/yougoMasterNaiyou";
import { formatStorage, getMenu } from "../../common/function_common/functionCommon";

/**
 *
 * @param {*} listName
 */
// function yougoMasterNaiyouAllCreator(listName) {
//   return (dispatch) => {
//     YougoMasterNaiyouAllApi.yougoMasterNaiyouAll(listName).then(
//       (data) => dispatch({
//         type: YOUGO_MASTER_NAIYOU,
//         payload: data
//       })
//     )
//   }
// }

function ymRiyouUpdatePageCreator() {
  // get data following page
  const pageName = "riyouEditPage";
  const mainSid = formatStorage(getMenu()).si;

  return (dispatch) => {
    YougoMasterEctApi.yougoMasterEctApi(mainSid, pageName).then((data) =>
      dispatch({
        type: YOUGO_MASTER_RIYOU_EDIT_PAGE_LIST,
        payload: data,
      })
    );
  };
}

function ymRiyouUpdatePageCreator2(callback) {
  // get data following page
  const pageName = "riyouEditPage";
  const mainSid = formatStorage(getMenu()).si;

  return (dispatch) => {
    YougoMasterEctApi.yougoMasterEctApi(mainSid, pageName).then(
      (data) => dispatch({
        type: YOUGO_MASTER_RIYOU_EDIT_PAGE_LIST,
        payload: data,
      }, callback(data)
      )
    );
  };
}

function ymShokujiPageCreator() {
  // get data following page
  const pageName = "shokujiPage";
  const mainSid = formatStorage(getMenu()).si;

  return (dispatch) => {
    YougoMasterEctApi.yougoMasterEctApi(mainSid, pageName).then((data) =>
      dispatch({
        type: YOUGO_MASTER_SHOKUJISEN_PAGE_LIST,
        payload: data,
      })
    );
  };
}

/**
 *
 * @param {*} listName
 */
function yougoMasterNaiyouCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then((data) =>
      dispatch({
        type: YOUGO_MASTER_NAIYOU,
        payload: data,
      })
    );
  };
}

/**
 *
 * @param {*} listName
 */
function yougoMasterNaiyou2Creator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then((data) =>
      dispatch({
        type: YOUGO_MASTER_NAIYOU2,
        payload: data,
      })
    );
  };
}

/**
 *
 * @param {*} listName
 */
function yougoMasterNaiyouNewCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then(
      (data) => dispatch({
        type: YOUGO_MASTER_NAIYOU_NEW,
        payload: data
      })
    );
  };
}

function yougoMasterNaiyouKubunCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then(
      (data) =>
        dispatch({
          type: YOUGO_MASTER_NAIYOU_BUNRUI,
          payload: data
        })
    );
  };
}

function yougoMasterTantoCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then(
      (data) =>
        dispatch({
          type: YOUGO_MASTER_TANTO,
          payload: data
        })
    );
  };
}

function yougoMasterKusuriCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then(
      (data) =>
        dispatch({
          type: YOUGO_MASTER_KUSURI,
          payload: data
        })
    );
  };
}

function yougoMasterWeatherNaiyouCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then((data) =>
      dispatch({
        type: YOUGO_MASTER_WEATHER_NAIYOU,
        payload: data,
      })
    );
  };
}

function yougoMasterRoomNaiyouCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then((data) =>
      dispatch({
        type: YOUGO_MASTER_ROOM_NAIYOU,
        payload: data,
      })
    );
  };
}

function yougoMasterTantoBunruiNaiyouCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then((data) =>
      dispatch({
        type: YOUGO_MASTER_TANTOBUNRUI_NAIYOU,
        payload: data,
      })
    );
  };
}

function yougoMasterStaffKindNaiyouCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then((data) =>
      dispatch({
        type: YOUGO_MASTER_STAFFKIND_NAIYOU,
        payload: data,
      })
    );
  };
}

function yougoMasterDepartNaiyouCreator(listName) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then((data) =>
      dispatch({
        type: YOUGO_MASTER_DEPART_NAIYOU,
        payload: data,
      })
    );
  };
}

/**
 *
 * @param {*} listName
 */
function yougoMasterSyuruiCreator(syurui) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(syurui).then((data) =>
      dispatch({
        type: YOUGO_MASTER_SYURUI,
        payload: data,
      })
    );
  };
}

/**
 *
 * @param {*} listName
 */
function yougoMasterJoutaiCreator(joutai) {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou(joutai).then((data) =>
      dispatch({
        type: YOUGO_MASTER_JOUTAI,
        payload: data,
      })
    );
  };
}

/**
 *
 * edit comment on dialog
 */
/**
 *
 * @param {*} index
 */
function yougoMasterEditStateCreator(index, type) {
  return (dispatch) => {
    dispatch({
      type: YOUGO_MASTER_EDIT_STATE,
      index,
      reportType: type,
    });
  };
}

/**
 *
 * @param {*} index
 */
function yougoMasterEditCancelCreator(index, type) {
  return (dispatch) => {
    dispatch({
      type: YOUGO_MASTER_EDIT_CANCEL,
      index,
      reportType: type,
    });
  };
}

/**
 *
 * @param {*} naiyou
 */
function yougoMasterEditSaveCreator(naiyou, type) {
  return (dispatch) => {
    YougoMasterEditApi.yougoMasterEditApi(naiyou, type).then((data) =>
      dispatch({
        type: YOUGO_MASTER_NAIYOU,
        payload: data,
        reportType: type,
      })
    );
  };
}

function yougoMasterEditSaveNewCreator(listName, naiyou) {
  return (dispatch) => {
    YougoMasterEditApiNew.yougoMasterEditApiNew(listName, naiyou).then((data) =>
      dispatch({
        type: YOUGO_MASTER_NAIYOU_NEW,
        payload: data,
      })
    );
  };
}

// END edit comment

function yougoMasterUpdateNewCreator(key, naiyou) {
  return (dispatch) => {
    YougoMasterUpdateApi.update(key, naiyou).then(
      (data) => {
        dispatch({
          type: YOUGO_MASTER_UPDATE_NEW,
          payload: data,
          params: key
        });
      }
    );
  };
}

function yougoMasterUpdateNewCreatorRoom(key, naiyou) {
  return (dispatch) => {
    YougoMasterUpdateApi.update(key, naiyou).then(
      (data) => {
        dispatch({
          type: YOUGO_MASTER_UPDATE_ROOM,
          payload: data,
          params: key
        });
      }
    );
  };
}

/**
 *
 * edit Ect on dialog
 */
/**
 *
 * @param {*} index
 */
function yougoMasterEctStateCreator(index) {
  return (dispatch) => {
    dispatch({
      type: YOUGO_MASTER_ECT_STATE,
      index,
    });
  };
}

/**
 *
 * @param {*} index
 */
function yougoMasterEctCancelCreator(index) {
  return (dispatch) => {
    dispatch({
      type: YOUGO_MASTER_ECT_CANCEL,
      index,
    });
  };
}

/**
 *
 * @param {*} naiyou
 */
function yougoMasterEctSaveCreator(type, naiyou) {
  return (dispatch) => {
    YougoMasterEditEctApi.yougoMasterEditEctApi(type, naiyou).then((data) =>
      dispatch({
        type: YOUGO_MASTER_ECT_SAVE,
        payload: data,
        ectType: type,
      })
    );
  };
}

// END edit Ect

/**
 *
 * @param {sting} listName
 */
function loadEctSelectDataCreator(mainSid) {
  return (dispatch) => {
    YougoMasterEctApi.yougoMasterEctApi(mainSid).then((data) =>
      dispatch({
        type: YOUGO_MASTER_ECT_SELECT,
        payload: data,
      })
    );
  };
}

function loadYougoMasterCreator(listNames, page) {
  return (dispatch) => {
    YougoMasterAPI.load(listNames).then((data) =>
      dispatch({
        type: YOUGO_MASTER_LOAD,
        payload: data,
        listNames,
        page,
      })
    );
  };
}

export {

  loadEctSelectDataCreator, loadYougoMasterCreator, ymRiyouUpdatePageCreator, ymRiyouUpdatePageCreator2, ymShokujiPageCreator, yougoMasterDepartNaiyouCreator, yougoMasterEctCancelCreator,
  yougoMasterEctSaveCreator, yougoMasterEctStateCreator, yougoMasterEditCancelCreator,
  yougoMasterEditSaveCreator, yougoMasterEditSaveNewCreator, yougoMasterEditStateCreator, yougoMasterJoutaiCreator,
  yougoMasterKusuriCreator, yougoMasterNaiyou2Creator, yougoMasterNaiyouCreator, yougoMasterNaiyouKubunCreator, yougoMasterNaiyouNewCreator, yougoMasterRoomNaiyouCreator, yougoMasterStaffKindNaiyouCreator, yougoMasterSyuruiCreator, yougoMasterTantoBunruiNaiyouCreator, yougoMasterTantoCreator, yougoMasterUpdateNewCreator,
  yougoMasterUpdateNewCreatorRoom,
  // 3S UPDATE
  yougoMasterWeatherNaiyouCreator
};

