import { AuthActionTypes } from "../action_types";

//
import { StorageUtils } from "../../utils";

function config(state = {}, action) {
  switch (action.type) {
    case AuthActionTypes.GET_MENU: {
      return {
        ...state,
        menu: action.menu,
      };
    }
    case AuthActionTypes.HANDLE_MENU: {
      const auth = StorageUtils.getValue("auth");
      const menuMNGT = StorageUtils.getValue("menuMNGT");
      StorageUtils.setValues({
        auth: {
          ...auth,
          menu: {
            ...auth.menu,
            si: `${action.values[0]}`,
            sn: `${action.values[1]}`,
            sy: `${action.values[2]}`,
            scn: `${action.values[3]}`,
          },
        },
        menuMNGT: {
          ...menuMNGT,
          si: `${action.values[0]}`,
          sn: `${action.values[1]}`,
          sy: `${action.values[2]}`,
          scn: `${action.values[3]}`,
        },
      });
      return {
        ...state,
        values: action.values,
      };
    }
    default: {
      return { ...state };
    }
  }
}

export default config;
