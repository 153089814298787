import React, { Component } from "react";
import { Row } from "antd";
import DatePickerControl from "../../../common/component/control/DatePickerControl";
import { PlanTable } from "./PlanTable";

export class EventPlanMobile extends Component {
  render() {
    return (
      <div>
        <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
          <DatePickerControl
            style={{ display: "inline", paddingLeft: "20px" }}
            value={this.props.selectedDate}
            mode={"month"}
            onChange={(value) => this.props.handleSelectMonth(value)}
          />
        </Row>
        <Row>
          <PlanTable
            data={this.props.data}
            mode={this.props.mode}
            selectedMonth={this.props.selectedDate}
            handleInputEventPlan={(data) =>
              this.props.handleInputEventPlan(data)
            }
            handleDeleteEventPlan={(eventPlanId) =>
              this.props.handleDeleteEventPlan(eventPlanId)
            }
            yougoMasterEditState={(index) =>
              this.props.yougoMasterEditState(index)
            }
            yougoMasterEditCancel={(index) =>
              this.props.yougoMasterEditCancel(index)
            }
            yougoMasterEditSave={(naiyou) =>
              this.props.yougoMasterEditSave(naiyou)
            }
            yougoMasterNaiyou={this.props.yougoMasterNaiyou}
          />
        </Row>
      </div>
    );
  }
}
