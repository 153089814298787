import React from "react";
import { Select } from "antd";

const Option = Select.Option;

export default class SelectOptionsForm extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.props;
    this.state = {
      value: value ? value : null,
    };

    // bind place
  }

  handleSelectChange = (value) => {
    this.setState({
      value: value,
    });

    this.triggerChange(value);
  };

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  render() {
    const { data, value, wari, style } = this.props;

    return (
      <div>
        <Select value={value} onChange={this.handleSelectChange} style={style}>
          <Option value="">&nbsp;&nbsp;&nbsp;&nbsp;</Option>
          {data && data.length > 0
            ? data.map(function (item, index) {
                return (
                  <Option key={index} value={item}>
                    {item}{wari ? "割" : ""}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>
    );
  }
}
