import { Button, Col, Input, Modal, Pagination, Row, Table } from "antd";
import _ from "lodash";
import { white } from "material-ui/styles/colors";
import React from "react";

export default class SyokujiTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      rowActive: null,
      record: null,
    };

    // bind place
  }

  handleOk = () => {
    const { record } = this.state;
    const cloneRecord = _.cloneDeep(record);

    // this.triggerChange(cloneRecord);

    this.props.updateField("syokuji", cloneRecord);

    // reset
    this.setState({
      visible: false,
      record: null,
    });
  };

  handleCancel = () => {
    // reset
    this.setState({
      visible: false,
      record: null,
    });
  };

  handleOpenTable = () => {
    // open modal
    this.setState({
      visible: true,
      record: null,
    });
  };

  onClickCellEvent = (record) => {
    this.setState({
      rowActive: record.id,
      record,
    });
  };

  onChangePagPopup = (page) => {
    this.props.onChangePagPopup(page);
  };

  /**
   * Should provide an event to pass value to Form.
   *
   */
  // triggerChange = (changedValue) => {
  //     const { onChange } = this.props;
  //     if (onChange) {
  //         onChange(changedValue);
  //     }
  // };

  render() {
    const { rowSet, value, data, pageTotal, page } = this.props;

    const { visible, rowActive } = this.state;

    const columns = [
      {
        dataIndex: "1",
        key: "1",
        render: (text, record, index) => {
          return (
            <Row>
              <div className="riyou-meal-page-click-area">
                <Col span={8}>
                  <span>主食 </span>
                  <span>{record.stapleFood}</span>
                </Col>
                <Col span={8}>
                  <span>副食 </span>
                  <span>{record.sideFood}</span>
                </Col>
                <Col span={8}>{record.syokujiKubun}</Col>
              </div>
            </Row>
          );
        },
        onCell: (record) => {
          return {
            onClick: (event) => {
              this.onClickCellEvent(record);
            }, // click row
          };
        },
      },
    ];

    return (
      (<div className="text-area-select-ri-page">
        <Input.TextArea
          rows={rowSet ? rowSet : 1}
          className="text-area-select-text"
          value={value}
          onChange={this.handleSelectChange}
          disabled={true}
          style={{ backgroundColor: white, color: "rgba(0, 0, 0, 0.65)" }}
        />
        <span className="text-area-select-btn">
          <span>
            <Button type="primary" onClick={this.handleOpenTable}>
              選択
            </Button>
          </span>
        </span>
        <Modal
          open={visible}
          title="次の中から選択してください。"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <Table
            dataSource={data}
            columns={columns}
            showHeader={false}
            className="riyou-meal-page"
            pagination={false}
            size="small"
            rowClassName={(record) =>
              rowActive != null && record.id === rowActive
                ? "pljp-table-row-hover"
                : ""
            }
            rowKey={(record, index) => index}
          />
          <Pagination
            onChange={this.onChangePagPopup}
            total={pageTotal * 10}
            pageSize={10}
            current={page}
            style={{ textAlign: "right", marginTop: 16 }}
          />
        </Modal>
      </div>)
    );
  }
}
