/* eslint-disable object-curly-spacing */
import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import {
  getMenu,
  reportByRiyousyaFormat,
} from "../common/function_common/functionCommon";

const DataFilterApi = {
  async dataFilterApi() {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.NYUTAISHO_FILTER, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      //
      return err;

    }
  },
};

/**
 *
 * @param {*} parameterObj
 * parameterObj.tabName: active tabName
 * parameterObj.isPropData: if propData loaded, return false and reuse
 * parameterObj.isLoadBasic: check if have to reload riyousya info again
 *
 *
 */
const LoadDataApi = {
  loadDataApi(parameterObj) {
    return loadBasic(parameterObj);
  },
};

function loadBasic(parameterObj) {
  switch (parameterObj.tabName) {
    case "利用者":
      return getRiyousya(parameterObj);
    case "集計":
      return getRiyousya(parameterObj);
    case "バイタル":
      return getVital(parameterObj);
    case "食事摂取":
      return getMeal(parameterObj);
    case "水分摂取":
      return getSuibun(parameterObj);
    case "排泄":
      return getHaisetu(parameterObj);
    case "入浴":
      return getNyuyoku(parameterObj);
    case "服薬":
      return getFukuyaku(parameterObj);
    case "提供記録":
      return getKaigonaiyo(parameterObj);
    case "特記":
      return getTokki(parameterObj);
    case "画像等":
      return getGaizou(parameterObj);
    /**
     * @TODO handle exception tabName null
     */
    default:
      return null;
  }
}

/**
 * get gaihaku_rireki data. (type - status)
 *
 * @param {obj} parameterObj
 */
async function getGaihaku(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(
      apiUrls.INIT +
      apiUrls.GAIHAKU_RIREKI +
      parameterObj.riyousyaId +
      "?fromDate=" +
      parameterObj.startDate +
      "&toDate=" +
      parameterObj.endDate,
      {
        method: "GET",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          riyousyaIds: parameterObj.riyousyaId,
          startDate: parameterObj.startDate,
          endDate: parameterObj.endDate,
        },
      }
    );

    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

function getRiyousya(parameterObj) {
  return axios
    .all([getRiyousyaRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getRiyousyaRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_SHUUKEI, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getVital(parameterObj) {
  return axios
    .all([getVitalRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 * get record data
 *
 * @param {obj} parameterObj
 */
async function getVitalRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getMeal(parameterObj) {
  return axios
    .all([getMealRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getMealRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
        shubetu: parameterObj.shubetu && parameterObj.shubetu !== "全表示"
          ? parameterObj.shubetu
          : null, // mealType
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getSuibun(parameterObj) {
  return axios
    .all([getSuibunRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getSuibunRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getHaisetu(parameterObj) {
  return axios
    .all([getHaisetuRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getHaisetuRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
        haisetuKubun: parameterObj.haisetuKubun && parameterObj.haisetuKubun !== "両方"
          ? parameterObj.haisetuKubun
          : null,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getNyuyoku(parameterObj) {
  return axios
    .all([getNyuyokuRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getNyuyokuRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getFukuyaku(parameterObj) {
  return axios
    .all([getFukuyakuRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getFukuyakuRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getKaigonaiyo(parameterObj) {
  return axios
    .all([getKaigonaiyoRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getKaigonaiyoRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGONAIYOU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
        kaigoNaiyouCode: parameterObj.kaigoNaiyouCode &&
          parameterObj.kaigoNaiyouCode !== "全表示"
          ? parameterObj.kaigoNaiyouCode
          : null,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getTokki(parameterObj) {
  return axios
    .all([getTokkiRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getTokkiRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getGaizou(parameterObj) {
  return axios
    .all([getGaizouRecord(parameterObj), getGaihaku(parameterObj)])
    .then(
      axios.spread(function (records, gaihaku) {
        return reportByRiyousyaFormat(parameterObj, records, gaihaku);
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getGaizouRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 */
function clearLocalStorage(err) {
  /**
   * @TODO handle error
   */
  if (!err.response) {
    // network error
  }
  else {
    // Check Token Exist
    if (err.response.status === 403) {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("doubleLogin");
      localStorage.removeItem("getAvatarResponse");
      localStorage.removeItem("getAvatarResponseData");
      window.location.replace("/login");
    }

    return err;
  }
}

/**
 * Vitalの更新
 */
const VitalUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const VitalInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const VitalDelete = {
  async delete(vitalId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_DELETE, {
        method: "DELETE",
        data: {
          vitalId: "" + vitalId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};
// end vital API

/**
 * Mealの更新
 */
const MealUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const MealInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const MealDelete = {
  async delete(mealId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_DELETE, {
        method: "DELETE",
        data: {
          mealId: "" + mealId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Suibunの更新
 */
const SuibunUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const SuibunInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const SuibunDelete = {
  async delete(suibunId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_DELETE, {
        method: "DELETE",
        data: {
          suibunId: "" + suibunId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * haisetuの更新
 */
const HaisetuUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const HaisetuInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const HaisetuDelete = {
  async delete(haisetuId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_DELETE, {
        method: "DELETE",
        data: {
          haisetuId: "" + haisetuId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Nyuyokuの更新
 */
const NyuyokuUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const NyuyokuInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const NyuyokuDelete = {
  async delete(nyuyokuId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_DELETE, {
        method: "DELETE",
        data: {
          nyuyokuId: "" + nyuyokuId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Fukuyakuの更新
 */
const FukuyakuUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const FukuyakuInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const FukuyakuDelete = {
  async delete(fukuyakuId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_DELETE, {
        method: "DELETE",
        data: {
          fukuyakuId: "" + fukuyakuId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Kaigonaiyoの更新
 */
const KaigonaiyoUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const KaigonaiyoInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const KaigonaiyoDelete = {
  async delete(kaigoId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_DELETE, {
        method: "DELETE",
        data: {
          kaigoId: "" + kaigoId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Tokkiの更新
 */
const TokkiUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const TokkiInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const TokkiDelete = {
  async delete(tokkiId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_DELETE, {
        method: "DELETE",
        data: {
          tokkiId: "" + tokkiId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Gazouの更新
 */
const GazouUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const GazouInsert = {
  async insert(params) {
    const formData = new FormData();
    formData.append("systemControlName", params.systemControlName);
    formData.append("systemControlId", params.systemControlId);
    formData.append("riyousyaId", params.riyousyaId);
    formData.append("tantoId", params.tantoId);
    formData.append("date", params.date);
    formData.append("time", params.time);
    // formData.append('type', '.jpg')
    // formData.append('fileName', 'fileName')
    formData.append("thumbnail", params.thumbnail ? params.thumbnail : null);
    formData.append("priorityf", params.priorityf ? params.priorityf : "0");
    formData.append("kubun", params.kubun ? params.kubun : "");
    formData.append("comment", params.comment ? params.comment : "");
    formData.append(
      "reportCaseTyuif",
      params.reportCaseTyuif ? params.reportCaseTyuif : "0"
    );
    formData.append("file", params.file);

    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_INSERT, {
        method: "POST",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const GazouDelete = {
  async delete(fileManagementId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_DELETE, {
        method: "DELETE",
        data: {
          fileManagementId: "" + fileManagementId,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const ZenkaiLoad = {
  async load(date, riyousyaId, time) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.NYUTAISHO_ZENKAI_LOAD, {
        method: "POST",
        data: {
          systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
          systemControlId: JSON.parse(localStorage.getItem("menu")).si,
          riyousyaIds: "" + riyousyaId,
          date,
          time: time ? time : "00:00",
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

export {
  DataFilterApi, FukuyakuDelete, FukuyakuInsert, FukuyakuUpdate, GazouDelete, GazouInsert, GazouUpdate, HaisetuDelete, HaisetuInsert, HaisetuUpdate, KaigonaiyoDelete, KaigonaiyoInsert, KaigonaiyoUpdate, LoadDataApi, MealDelete, MealInsert, MealUpdate, NyuyokuDelete, NyuyokuInsert, NyuyokuUpdate, SuibunDelete, SuibunInsert, SuibunUpdate, TokkiDelete, TokkiInsert, TokkiUpdate, VitalDelete, VitalInsert, VitalUpdate, ZenkaiLoad
};

