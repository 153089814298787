import {
  ZIP_MASTER_SEARCH_REQUEST,
  ZIP_MASTER_SEARCH_ERROR,
  ZIP_MASTER_SEARCH_SUCCESS,
} from "./actionName";
import {
  // YougoMasterNaiyouAllApi,
  SearchApi,
} from "../../api/zipMaster";

/**
 *
 * @param {*} listName
 */
function zipMasterSearchCreator(text) {
  return (dispatch) => {
    // dispatch({
    //   type: ZIP_MASTER_SEARCH_REQUEST,
    // })

    SearchApi.search(text)
      .then((data) =>
        dispatch({
          type: ZIP_MASTER_SEARCH_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: ZIP_MASTER_SEARCH_ERROR,
          messages: error,
        })
      );
  };
}

export { zipMasterSearchCreator };
