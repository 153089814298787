export const LOAD_INDIVIDUAL_PLAN_SUCCESS = "LOAD_INDIVIDUAL_PLAN_SUCCESS";
export const CREATE_INDIVIDUAL_PLAN_SUCCESS = "CREATE_INDIVIDUAL_PLAN_SUCCESS";
export const UPDATE_INDIVIDUAL_PLAN_SUCCESS = "UPDATE_INDIVIDUAL_PLAN_SUCCESS";
export const DELETE_INDIVIDUAL_PLAN_SUCCESS = "DELETE_INDIVIDUAL_PLAN_SUCCESS";

export const LOAD_EVENT_PLAN_SUCCESS = "LOAD_EVENT_PLAN_SUCCESS";
export const CREATE_EVENT_PLAN_SUCCESS = "CREATE_EVENT_PLAN_SUCCESS";
export const UPDATE_EVENT_PLAN_SUCCESS = "UPDATE_EVENT_PLAN_SUCCESS";
export const DELETE_EVENT_PLAN_SUCCESS = "DELETE_EVENT_PLAN_SUCCESS";

export const LOAD_FILTER_MASTER_SUCCESS = "LOAD_FILTER_MASTER_SUCCESS";
export const LOAD_NYUTAISHO_DATA_SUCCESS = "LOAD_NYUTAISHO_DATA_SUCCESS";
export const FILTER_RIYOUSYA_SUCCESS = "FILTER_RIYOUSYA_SUCCESS";
export const FACILITY_PLAN_YOUGO_SUCCESS = "FACILITY_PLAN_YOUGO_SUCCESS";
export const FACILITY_PLAN_YOUGO_EDIT_STATE = "FACILITY_PLAN_YOUGO_EDIT_STATE";
export const FACILITY_PLAN_YOUGO_CANCEL_STATE =
  "FACILITY_PLAN_YOUGO_CANCEL_STATE";
