import React, { Component } from "react";
import { Link } from "react-router-dom";
import { UndoOutlined } from '@ant-design/icons';
import { Row } from "antd";
import DatePickerControl from "../../../common/component/control/DatePickerControl";
import { PlanTable } from "./PlanTable";
import { ConstSet } from "../../../common/configs/constset";

export class IndividualPlanMobile extends Component {
  render() {
    return (
      <div>
        <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
          <DatePickerControl
            style={{ display: "inline", paddingLeft: "20px" }}
            value={this.props.selectedDate}
            mode={"month"}
            onChange={(value) => this.props.handleSelectMonth(value)}
          />
        </Row>
        <Row>
          <PlanTable
            data={this.props.data}
            mode={this.props.mode}
            selectedMonth={this.props.selectedDate}
            riyousyaId={this.props.riyousyaId}
            handleInputUserPlan={(data) => this.props.handleInputUserPlan(data)}
            handleDeleteUserPlan={(riyousyaPlanId) =>
              this.props.handleDeleteUserPlan(riyousyaPlanId)
            }
            yougoMasterEditState={(index) =>
              this.props.yougoMasterEditState(index)
            }
            yougoMasterEditCancel={(index) =>
              this.props.yougoMasterEditCancel(index)
            }
            yougoMasterEditSave={(naiyou) =>
              this.props.yougoMasterEditSave(naiyou)
            }
            yougoMasterNaiyou={this.props.yougoMasterNaiyou}
          />
        </Row>
        {this.props.riyousyaId != null ? (
          <Row
            className={"submenu"}
            style={{ position: "sticky", bottom: "0" }}
          >
            <Link
              to={ConstSet.REPORT_PAGE}
              style={{ color: "white", textAlign: "center", float: "right" }}
            >
              <span style={{ display: "block" }}>
                <UndoOutlined />
              </span>
              <span>{"戻る"}</span>
            </Link>
          </Row>
        ) : null}
      </div>
    );
  }
}
