import {
  convert_wareki_3, 
  convert_wareki 
} from "../../../function_common/functionCommon";
import _ from "lodash";

export const plan1_InputFormPDF = (data) => {

  const getValueFromKey = (st, key) => {
    if (!st) return; 
    const keyValuePairs = st.split(',');
    for (let i = 0; i < keyValuePairs.length; i++) {
      const pair = keyValuePairs[i].split(':');
      const pairKey = pair[0].trim();
      const pairValue = pair[1].trim();
      if (pairKey === key) {
        if (pairValue === 'true') {
          return true;
        } else if (pairValue === 'false') {
          return false;
        } else {
          return pairValue;
        }
      }
    }
    return null;
  }

  const convert_douiDate = (dateString) => {
    if (dateString) {
      const str = dateString.split("-");
  
      const y = str[0];
      const m = str[1];
      const d = str[2];
  
      const tmp1 = convert_wareki(parseInt(y), parseInt(m), parseInt(d));
  
      const tmp = { jt: tmp1.t, jn: tmp1.n, y, m, d, dis: "" };
  
      if (!dateString || y !== "0001") {
        const dis = `${tmp.jt}　 ${parseInt(tmp.jn)}年　　${parseInt(tmp.m)}月　　${parseInt(
          tmp.d
        )}日`;
        tmp.dis = dis;
      }
  
      return tmp;
    }
  
    return { dis: "" };
  }

  const formatDate = (isSeireki, date) => {
    if (isSeireki) {
      // const result = formatSeireki(date);
      const result = "　　　　年　　 月　　　日"
      return result;
    } else {
      const result = convert_douiDate(date).dis;
      return result;
    }
  }

  const formatNintei = (checkSinsei) => {
    if (checkSinsei === 0) {
      return "認定済";
    } else if (checkSinsei === 1) {
      return "申請中";
    }

    return "";
  }

  const formatBirthday = (birthday, type) => {
    if (!birthday) return ""; 
    const birthDate = new Date(birthday);
    const today = new Date();
  
    const yearDiff = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
  
    const wareki = convert_wareki(birthDate.getFullYear(), birthDate.getMonth(), birthDate.getDate());
  
    let age = yearDiff;
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    if (type === "seireki") {
      const formattedDate = `${birthDate.getFullYear()}年${birthDate.getMonth() + 1}月${birthDate.getDate()}日`;
      return `${formattedDate}`;
    }
    const formattedDate = `${wareki.t}${wareki.n}年${birthDate.getMonth() + 1}月${birthDate.getDate()}日`;
    return `${formattedDate}`;
  
  }

  const convertSeikatuRiyu = (plan1) => {
    if (!plan1) return
    if (plan1.riyu === 1) {
      return "一人暮らし";
    } else if (plan1.riyu === 2) {
      return "家族等が障害、疾病等";
    } else if (plan1.riyu === 3) {
      return `その他(${plan1.riyuHoka ?? ""})`;
    } 
  }

    const { plan1, printOptions, douiran } = data;

    if (douiran) {
      // 同意欄あり
      if (typeof plan1 === 'object' && Array.isArray(plan1)) {
        let sampledata = [];
        plan1?.map((plan1, index) => {
          sampledata.push({
            作成年月日: formatDate(plan1.date, plan1?.planDate) ?? "",
            タイトル: plan1.title ?? "",
            区分: plan1?.kubun ?? "",
            認定: formatNintei(plan1?.riyouKaigohoken?.sinseif) ?? "",
            利用者名: plan1?.riyousyaName ?? "",
            敬称: plan1.keisyo ?? "",
            生年月日: formatBirthday(plan1?.riyousyaBirthday) ?? "",
            住所: `${plan1?.riyousyaAddress1 ?? ""}${plan1?.riyousyaAddress2 ?? ""}` ?? "",
            居宅サービス計画者名及び職種: `${plan1?.tantoName ?? ""}　　　　　　　${plan1?.tantoSikaku ?? ""}` ?? "",
            居宅サービス計画作成日: convert_wareki_3(plan1?.planDate)?.dis ?? "",
            初回居宅サービス計画作成日: convert_wareki_3(plan1?.firstPlanDate)?.dis ?? "",
            認定日: convert_wareki_3(plan1?.riyouKaigohoken?.ninteiDate)?.dis ?? "",
            認定の有効期間1: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateFrom)?.dis ?? "",
            認定の有効期間2: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateTo)?.dis ?? "",
            要介護度: plan1?.kaigodoName ?? "",
            利用者及び家族の生活に対する意向を踏まえた課題分析の結果: plan1?.ikou ?? "",
            介護認定審査会の意見及びサービスの種類の指定: plan1?.iken ?? "",
            総合的な援助の方針: plan1?.housin ?? "",
            生活支援中心型の算定理由: convertSeikatuRiyu(plan1) ?? "",
            同意年月日: plan1.douibi ? convert_wareki_3(plan1?.plan1DouiDate)?.dis : "",
            同意コメント: plan1.comment ?? "",
            同意印: plan1.douiin ? "印" : "",
            ページ数: String(index + 1),
          })
        })

        return sampledata;
      } else if (typeof plan1 === 'object') {
        let sampledata = [
          {
            // 作成年月日: formatDate(plan1.date, plan1?.planDate) ?? "",
            // タイトル: plan1.title ?? "",
            // 区分: plan1?.kubun ?? "",
            // 認定: formatNintei(plan1?.riyouKaigohoken?.sinseif) ?? "",
            // 利用者名: plan1?.riyousyaName ?? "",
            // 敬称: plan1.keisyo ?? "",
            // 生年月日: formatBirthday(plan1?.riyousyaBirthday) ?? "",
            // 住所: `${plan1?.riyousyaAddress1 ?? ""}${plan1?.riyousyaAddress2 ?? ""}` ?? "",
            // 居宅サービス計画者名及び職種: `${plan1?.tantoName ?? ""}　　　　　　　${plan1?.tantoSikaku ?? ""}` ?? "",
            // 居宅サービス計画作成日: convert_wareki_3(plan1?.planDate)?.dis ?? "",
            // 初回居宅サービス計画作成日: convert_wareki_3(plan1?.firstPlanDate)?.dis ?? "",
            // 認定日: convert_wareki_3(plan1?.riyouKaigohoken?.ninteiDate)?.dis ?? "",
            // 認定の有効期間1: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateFrom)?.dis ?? "",
            // 認定の有効期間2: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateTo)?.dis ?? "",
            // 要介護度: plan1?.kaigodoName ?? "",
            // 利用者及び家族の生活に対する意向を踏まえた課題分析の結果: plan1?.ikou ?? "",
            // 介護認定審査会の意見及びサービスの種類の指定: plan1?.iken ?? "",
            // 総合的な援助の方針: plan1?.housin ?? "",
            // 生活支援中心型の算定理由: convertSeikatuRiyu(plan1) ?? "",
            // 同意年月日: plan1.douibi ? convert_wareki_3(plan1?.plan1DouiDate)?.dis : "",
            // 同意コメント: plan1.comment ?? "",
            // 同意印: plan1.douiin ? "印" : "",
            // ページ数: "",
            作成年月日: formatDate(getValueFromKey(printOptions?.naiyou, "作成日"), plan1?.planDate) ?? "",
            タイトル: getValueFromKey(printOptions?.naiyou, "title") ?? "",
            区分: plan1?.kubun ?? "",
            認定: formatNintei(plan1?.riyouKaigohoken?.sinseif) ?? "",
            利用者名: plan1?.riyousyaName ?? "",
            敬称: getValueFromKey(printOptions?.naiyou, "敬称") ?? "",
            生年月日: formatBirthday(plan1?.riyousyaBirthday) ?? "",
            住所: `${plan1?.riyousyaAddress1 ?? ""}${plan1?.riyousyaAddress2 ?? ""}` ?? "",
            居宅サービス計画者名及び職種: `${plan1?.tantoName}　　　　　　　${plan1?.tantoSikaku}` ?? "",
            居宅サービス計画作成日: convert_wareki_3(plan1?.planDate)?.dis ?? "",
            初回居宅サービス計画作成日: convert_wareki_3(plan1?.firstPlanDate)?.dis ?? "",
            認定日: convert_wareki_3(plan1?.riyouKaigohoken?.ninteiDate)?.dis ?? "",
            認定の有効期間1: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateFrom)?.dis ?? "",
            認定の有効期間2: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateTo)?.dis ?? "",
            要介護度: plan1?.kaigodoName ?? "",
            利用者及び家族の生活に対する意向を踏まえた課題分析の結果: plan1?.ikou ?? "",
            介護認定審査会の意見及びサービスの種類の指定: plan1?.iken ?? "",
            総合的な援助の方針: plan1?.housin ?? "",
            生活支援中心型の算定理由: convertSeikatuRiyu(plan1) ?? "",
            同意年月日: getValueFromKey(printOptions?.naiyou, "同意日") ? convert_wareki_3(plan1?.plan1DouiDate)?.dis : "",
            同意コメント: getValueFromKey(printOptions?.naiyou, "同意コメント") ?? "",
            同意印: getValueFromKey(printOptions?.naiyou, "同意印") ? "印" : "",
            ページ数: "",
          },
        ];
        return sampledata;
      } else {
        return [{}];
      }
      // 同意欄なし
    } else {
      if (typeof plan1 === 'object' && Array.isArray(plan1)) {
        let sampledata = [];
        plan1?.map((plan1, index) => {
          sampledata.push({
            作成年月日: formatDate(getValueFromKey(printOptions?.naiyou, "作成日"), plan1?.planDate) ?? "",
            同意年月日タイトル: getValueFromKey(printOptions?.naiyou, "同意日") ? "同意年月日" : "",
            同意年月日: getValueFromKey(printOptions?.naiyou, "同意日") ? convert_douiDate(plan1?.plan1DouiDate)?.dis : "",
            タイトル: getValueFromKey(printOptions?.naiyou, "title") ?? "",
            区分: plan1?.kubun ?? "",
            認定: formatNintei(plan1?.riyouKaigohoken?.sinseif) ?? "",
            利用者名: plan1?.riyousyaName ?? "",
            敬称: getValueFromKey(printOptions?.naiyou, "敬称") ?? "",
            生年月日: formatBirthday(plan1?.riyousyaBirthday) ?? "",
            住所: `${plan1?.riyousyaAddress1 ?? ""}${plan1?.riyousyaAddress2 ?? ""}` ?? "",
            居宅サービス計画者名及び職種: `${plan1?.tantoName ?? ""}　　　　　　　${plan1?.tantoSikaku ?? ""}` ?? "",
            居宅サービス計画作成日: convert_wareki_3(plan1?.planDate)?.dis ?? "",
            初回居宅サービス計画作成日: convert_wareki_3(plan1?.firstPlanDate)?.dis ?? "",
            認定日: convert_wareki_3(plan1?.riyouKaigohoken?.ninteiDate)?.dis ?? "",
            認定の有効期間1: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateFrom)?.dis ?? "",
            認定の有効期間2: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateTo)?.dis ?? "",
            要介護度: plan1?.kaigodoName ?? "",
            利用者及び家族の生活に対する意向を踏まえた課題分析の結果: plan1?.ikou ?? "",
            介護認定審査会の意見及びサービスの種類の指定: plan1?.iken ?? "",
            総合的な援助の方針: plan1?.housin ?? "",
            生活支援中心型の算定理由: convertSeikatuRiyu(plan1) ?? "",
            ページ数: String(index + 1),
          })
        })
  
        return sampledata;
      } else if (typeof plan1 === 'object') {
        let sampledata = [
          {
            作成年月日: formatDate(getValueFromKey(printOptions?.naiyou, "作成日"), plan1?.planDate) ?? "",
            同意年月日タイトル: getValueFromKey(printOptions?.naiyou, "同意日") ? "同意年月日" : "",
            同意年月日: getValueFromKey(printOptions?.naiyou, "同意日") ? convert_douiDate(plan1?.plan1DouiDate)?.dis : "",
            タイトル: getValueFromKey(printOptions?.naiyou, "title") ?? "",
            区分: plan1?.kubun ?? "",
            認定: formatNintei(plan1?.riyouKaigohoken?.sinseif) ?? "",
            利用者名: plan1?.riyousyaName ?? "",
            敬称: getValueFromKey(printOptions?.naiyou, "敬称") ?? "",
            生年月日: formatBirthday(plan1?.riyousyaBirthday) ?? "",
            住所: `${plan1?.riyousyaAddress1 ?? ""}${plan1?.riyousyaAddress2 ?? ""}` ?? "",
            居宅サービス計画者名及び職種: `${plan1?.tantoName}　　　　　　　${plan1?.tantoSikaku}` ?? "",
            居宅サービス計画作成日: convert_wareki_3(plan1?.planDate)?.dis ?? "",
            初回居宅サービス計画作成日: convert_wareki_3(plan1?.firstPlanDate)?.dis ?? "",
            認定日: convert_wareki_3(plan1?.riyouKaigohoken?.ninteiDate)?.dis ?? "",
            認定の有効期間1: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateFrom)?.dis ?? "",
            認定の有効期間2: convert_wareki_3(plan1?.riyouKaigohoken?.yukoDateTo)?.dis ?? "",
            要介護度: plan1?.kaigodoName ?? "",
            利用者及び家族の生活に対する意向を踏まえた課題分析の結果: plan1?.ikou ?? "",
            介護認定審査会の意見及びサービスの種類の指定: plan1?.iken ?? "",
            総合的な援助の方針: plan1?.housin ?? "",
            生活支援中心型の算定理由: convertSeikatuRiyu(plan1) ?? "",
            ページ数: "",
          },
        ];
        return sampledata;
      } else {
        return [{}];
      }
    }

    
};
