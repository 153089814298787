import React from "react";

import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';


import { Col, Row, Select } from "antd";

import { changeSortTab } from "../../common/function_common/functionCommon";

const FormItem = Form.Item;
const Option = Select.Option;

export default class SortConditions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange10 = this.handleChange10.bind(this);
    this.handleChange11 = this.handleChange11.bind(this);
    this.handleChange20 = this.handleChange20.bind(this);
    this.handleChange21 = this.handleChange21.bind(this);
    this.handleChange30 = this.handleChange30.bind(this);
    this.handleChange31 = this.handleChange31.bind(this);
    this.handleChange40 = this.handleChange40.bind(this);
    this.handleChange41 = this.handleChange41.bind(this);
    this.handleChange50 = this.handleChange50.bind(this);
    this.handleChange51 = this.handleChange51.bind(this);
  }

  handleChange10(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].key1 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange11(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].value1 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange20(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].key2 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange21(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].value2 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange30(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].key3 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange31(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].value3 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange40(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].key4 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange41(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].value4 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange50(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].key5 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange51(value) {
    // console.log(`selected ${value}`);
    const { filterData, tabActive } = this.props;
    const sortTabName = changeSortTab(tabActive);
    filterData[sortTabName].value5 = value;

    this.props.handleUpdateState(filterData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  render() {
    const { burunTitle, sortData, sortOptions } = this.props;

    return (
      <div className={"kn-search-con"}>
        <Row>
          <Col xs={4}>
            <FormItem label={"第1キー"} />
          </Col>
          <Col xs={14}>
            <Select
              style={{ width: "100%" }}
              value={sortData.key1}
              onChange={this.handleChange10}
            >
              <Option key="0" value="0">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Option>
              <Option key="1" value="1">
                フリガナ
              </Option>
              <Option key="2" value="2">
                部屋
              </Option>
              <Option key="3" value="3">
                状況
              </Option>
              <Option key="4" value="4">
                入所日
              </Option>
              <Option key="5" value="5">
                退所日
              </Option>
              <Option key="6" value="6">
                入所ショート区分
              </Option>
              {burunTitle ? (
                <Option key="7" value="7">
                  {burunTitle}
                </Option>
              ) : null}
              {sortOptions.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.display}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xs={6}>
            <Select
              style={{ width: "100%" }}
              value={sortData.value1}
              onChange={this.handleChange11}
            >
              <Option value="1">昇順</Option>
              <Option value="2">降順</Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormItem label={"第2キー"} />
          </Col>
          <Col xs={14}>
            <Select
              style={{ width: "100%" }}
              value={sortData.key2}
              onChange={this.handleChange20}
            >
              <Option key="0" value="0">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Option>
              <Option key="1" value="1">
                フリガナ
              </Option>
              <Option key="2" value="2">
                部屋
              </Option>
              <Option key="3" value="3">
                状況
              </Option>
              <Option key="4" value="4">
                入所日
              </Option>
              <Option key="5" value="5">
                退所日
              </Option>
              <Option key="6" value="6">
                入所ショート区分
              </Option>
              {burunTitle ? (
                <Option key="7" value="7">
                  {burunTitle}
                </Option>
              ) : null}
              {sortOptions.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.display}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xs={6}>
            <Select
              showSearch
              style={{ width: "100%" }}
              value={sortData.value2}
              onChange={this.handleChange21}
            >
              <Option value="1">昇順</Option>
              <Option value="2">降順</Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormItem label={"第3キー"} />
          </Col>
          <Col xs={14}>
            <Select
              style={{ width: "100%" }}
              value={sortData.key3}
              onChange={this.handleChange30}
            >
              <Option key="0" value="0">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Option>
              <Option key="1" value="1">
                フリガナ
              </Option>
              <Option key="2" value="2">
                部屋
              </Option>
              <Option key="3" value="3">
                状況
              </Option>
              <Option key="4" value="4">
                入所日
              </Option>
              <Option key="5" value="5">
                退所日
              </Option>
              <Option key="6" value="6">
                入所ショート区分
              </Option>
              {burunTitle ? (
                <Option key="7" value="7">
                  {burunTitle}
                </Option>
              ) : null}
              {sortOptions.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.display}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xs={6}>
            <Select
              style={{ width: "100%" }}
              value={sortData.value3}
              onChange={this.handleChange31}
            >
              <Option value="1">昇順</Option>
              <Option value="2">降順</Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormItem label={"第4キー"} />
          </Col>
          <Col xs={14}>
            <Select
              style={{ width: "100%" }}
              value={sortData.key4}
              onChange={this.handleChange40}
            >
              <Option key="0" value="0">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Option>
              <Option key="1" value="1">
                フリガナ
              </Option>
              <Option key="2" value="2">
                部屋
              </Option>
              <Option key="3" value="3">
                状況
              </Option>
              <Option key="4" value="4">
                入所日
              </Option>
              <Option key="5" value="5">
                退所日
              </Option>
              <Option key="6" value="6">
                入所ショート区分
              </Option>
              {burunTitle ? (
                <Option key="7" value="7">
                  {burunTitle}
                </Option>
              ) : null}
              {sortOptions.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.display}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xs={6}>
            <Select
              style={{ width: "100%" }}
              value={sortData.value4}
              onChange={this.handleChange41}
            >
              <Option value="1">昇順</Option>
              <Option value="2">降順</Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormItem label={"第5キー"} />
          </Col>
          <Col xs={14}>
            <Select
              style={{ width: "100%" }}
              value={sortData.key5}
              onChange={this.handleChange50}
            >
              <Option key="0" value="0">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Option>
              <Option key="1" value="1">
                フリガナ
              </Option>
              <Option key="2" value="2">
                部屋
              </Option>
              <Option key="3" value="3">
                状況
              </Option>
              <Option key="4" value="4">
                入所日
              </Option>
              <Option key="5" value="5">
                退所日
              </Option>
              <Option key="6" value="6">
                入所ショート区分
              </Option>
              {burunTitle ? (
                <Option key="7" value="7">
                  {burunTitle}
                </Option>
              ) : null}
              {sortOptions.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.display}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xs={6}>
            <Select
              style={{ width: "100%" }}
              value={sortData.value5}
              onChange={this.handleChange51}
            >
              <Option value="1">昇順</Option>
              <Option value="2">降順</Option>
            </Select>
          </Col>
        </Row>
      </div>
    );
  }
}
