import { SaveOutlined, SyncOutlined, UndoOutlined } from "@ant-design/icons";
import { Affix, Modal, Row } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Link, Redirect } from "react-router-dom";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import { ConstSet } from "../../common/configs/constset";
import {
  convertStringInArr,
  extractRiyousyaInfo,
  getBase64,
  getUrlVarsNew,
  getValueLocalstorage,
  isEmpty,
} from "../../common/function_common/functionCommon";
import fileIcon from "../../common/images/document/file.png";
import "./ReportWeeklyAdd.css";
import Content from "./UI/Content";
import SubMenuInc from "./UI/SubMenuInc";

class Report extends Component {
  constructor(props) {
    super(props);

    const SetTimeout = getValueLocalstorage("sessionTimeout");

    /**
     *
     * get date, time
     * IF date from url is exited date = date on URL
     * Else IF date in localstorage is exited get it. (that is save from 記録日別)
     * else get TODAY
     *
     */
    const search = this.props.location.search; // could be '?timeRange=...'
    const params = new URLSearchParams(search);
    const dateUrl = params.get("date"); // date
    const timeRange = params.get("timeRange"); // timeRange
    localStorage.setItem("timeActive", timeRange);
    const openTab = params.get("openTab")
      ? params.get("openTab").split(",")
      : null; // timeRange
    const preDateLocal = localStorage.getItem("nyuDate");

    const date = new Date();

    let startDate;
    let endDate;
    if (dateUrl) {
      startDate = dayjs(dateUrl).add(-6, "days").format("YYYY-MM-DD");
      endDate = dayjs(dateUrl).format("YYYY-MM-DD");
    }
    else if (preDateLocal) {
      startDate = dayjs(preDateLocal).add(-6, "days").format("YYYY-MM-DD");
      endDate = dayjs(preDateLocal).format("YYYY-MM-DD");
    }
    else {
      startDate = dayjs(date).add(-6, "days").format("YYYY-MM-DD");
      endDate = dayjs(date).format("YYYY-MM-DD");
    } // end date handle
    localStorage.setItem("weeklyDate", endDate);

    this.state = {
      isOpen: false,
      visible: false,
      socket: this.props.socket,
      sesstionTimeout: false,
      SetTimeout: SetTimeout ? SetTimeout : 0,

      //
      tbDisType: false,

      //
      startDate,
      endDate,
      time: timeRange,

      riyousyaId: this.props.match.params.userId,
      riyousayInfo: extractRiyousyaInfo(
        this.props.match.params.userId,
        localStorage.getItem("kirokuBasic")
      )[0],

      // Parrams
      vital: {},
      meal: {},
      suibun: [{ start: true }],
      hainyou: {},
      haiben: {},
      nyuyoku: {},
      fukuyaku: [{ start: true }],
      kaigo: [{ start: true }],
      tokki: {},
      gazou: {},

      // save file
      file: null,
      imageUrl: null,

      // @TODO disable select time on adding page. disableTime = true : can not select(add & edit), =false(add - can change, edit - can not)
      disableTime: true,

      updateState: {
        vital: false,
        meal: false,
        suibun: false,
        hainyou: false,
        haiben: false,
        nyuyoku: false,
        fukuyaku: false,
        kaigo: false,
        tokki: false,
        gazou: false,
      },

      syokichiHaisetuType: null,

      // this state check when return page and give the warning
      isUpdating: false,

      /**
       * update time
       *
       * if time have been change and update, after saving data, all data of page has been reload
       *
       */
      newTime: null,

      // Check openTab
      openTab,

      // redirect to weekly page
      redirect: false,

      filePreview: null,
    };

    // bind place
    this.getReportRiyousya = this.getReportRiyousya.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePreviewDate = this.handlePreviewDate.bind(this);
    this.handleNextDate = this.handleNextDate.bind(this);

    // handle edit comment setting
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    // handle edit ect setting
    this.getYougoMasterEctState = this.getYougoMasterEctState.bind(this);
    this.getYougoMasterEctCancel = this.getYougoMasterEctCancel.bind(this);
    this.getYougoMasterEctSave = this.getYougoMasterEctSave.bind(this);

    //
    this.getHandleDrugSearch = this.getHandleDrugSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      vital,
      meal,
      suibun,
      hainyou,
      haiben,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou,
      syokichi,
    } = this.props;

    // 1
    if (vital !== prevProps.vital) {
      const peVital = vital;
      if (vital.spo2) {
        const tmp = vital.spo2.split("-");

        peVital.spo2B = tmp[0];

        if (tmp[1]) {
          peVital.spo2A = tmp[1];
        }
      }

      this.setState({
        vital: vital ? peVital : {},
      });
    }

    // 2
    if (meal !== prevProps.meal) {
      this.setState({
        meal: meal ? meal : {},
      });
    }

    // 3
    if (suibun !== prevProps.suibun) {
      this.setState({
        suibun: suibun ? suibun : {},
      });
    }

    // 40
    if (hainyou !== prevProps.hainyou) {
      const preHainyou = hainyou;

      if (hainyou) {
        preHainyou.haisetuF = hainyou.haisetuf;
      }

      this.setState({
        hainyou: hainyou ? preHainyou : {},
      });
    }

    // 41
    if (haiben !== prevProps.haiben) {
      const preHaiben = haiben;

      if (haiben) {
        preHaiben.haisetuF = haiben.haisetuf;
      }

      this.setState({
        haiben: haiben ? preHaiben : {},
      });
    }

    // 5
    if (nyuyoku !== prevProps.nyuyoku) {
      this.setState({
        nyuyoku: nyuyoku ? nyuyoku : {},
      });
    }

    // 6
    if (fukuyaku !== prevProps.fukuyaku) {
      this.setState({
        fukuyaku: fukuyaku ? fukuyaku : {},
      });
    }

    // 7
    if (kaigo !== prevProps.kaigo) {
      this.setState({
        kaigo: kaigo ? kaigo : {},
      });
    }

    // 8
    if (tokki !== prevProps.tokki) {
      this.setState({
        tokki: tokki ? tokki : {},
      });
    }

    // 9
    if (gazou !== prevProps.gazou) {
      this.setState({
        gazou: gazou ? gazou : {},
      });
    }

    // Check update syokichi
    if (this.props.syokichi !== prevProps.syokichi) {
      if (syokichi && syokichi[0]) {
        const syokichiHaisetuType = this.state.syokichiHaisetuType;

        if (
          syokichiHaisetuType === 1 &&
          syokichi[0].kubun &&
          syokichi[0].kubun === ConstSet.HAISETU_SYOKICHI_NAIYOU
        ) {
          this.setState({
            hainyou: Object.assign({}, this.state.hainyou, {
              shurui: syokichi[0].syokichi,
            }),
          });
        }

        if (
          syokichiHaisetuType === 2 &&
          syokichi[0].kubun &&
          syokichi[0].kubun === ConstSet.HAISETU_SYOKICHI_NAIYOU
        ) {
          this.setState({
            haiben: Object.assign({}, this.state.haiben, {
              shurui: syokichi[0].syokichi,
            }),
          });
        }

        if (
          syokichiHaisetuType === 3 &&
          syokichi[0].kubun &&
          syokichi[0].kubun === ConstSet.NYUYOKU_SYOKICHI_NAIYOU
        ) {
          this.setState({
            nyuyoku: Object.assign({}, this.state.nyuyoku, {
              nyuyokuNaiyou: syokichi[0].syokichi,
            }),
          });
        }
      }
    }
  }

  /**
   * Load for first Time
   */
  componentDidMount() {
    // Setting page befor redering DOM
    const { riyousyaId, endDate, time } = this.state;

    // Loading Data
    const urlParam = getUrlVarsNew();
    let preParam = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (urlParam["ids"]) {
      preParam = urlParam["ids"].split(",");
    }

    preParam.push(riyousyaId);
    preParam.push(endDate);
    preParam.push(time);

    /**
     * @TODO disable select time in editing mode
     */
    // if (urlParam["ids"]) {
    //   this.setState({
    //     disableTime: true
    //   })
    // }

    this.props.loadData(preParam, (data) => {
      if (data && data[0]) this.loadFilePreview(data[0]);
    });
    this.props.tantoMasterList();

    // Loading Setting Data
    this.props.loadSetting();

    //
    this.props.loadDrugMaster("", "");

    //
    this.props.yougoMasterNaiyou("バイタルコメント");
    this.props.loadEctSelectData(JSON.parse(localStorage.getItem("menu")).si);

    this.props.loadZenkai(endDate, riyousyaId, time);
  }

  loadFilePreview(record) {
    this.props.loadFilePreview(record, (data) =>
      this.setState({ filePreview: data })
    );
  }

  /**
   * handle changing riyousyaId from search from
   *
   * @param {string} riyousyaId
   */
  getReportRiyousya = (value) => {
    if (value) {
      this.props.history.push(ConstSet.REPORT_WEEKLY_ADD_PAGE + value);
    }
  };

  handleDateChange = (date) => {
    const startDate = dayjs(date).add(-6, "days").format("YYYY-MM-DD");
    const endDate = date;

    this.setState({
      startDate,
      endDate,
    });
  };

  /**
   * Preview Date
   *
   */
  handlePreviewDate = () => {
    const endDate = dayjs(this.state.endDate)
      .add(-1, "days")
      .format("YYYY-MM-DD");
    const startDate = dayjs(endDate).add(-6, "days").format("YYYY-MM-DD");

    this.setState({
      startDate,
      endDate,
    });
  };

  /**
   * Next date
   *
   */
  handleNextDate = () => {
    const endDate = dayjs(this.state.endDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
    const startDate = dayjs(endDate).add(-6, "days").format("YYYY-MM-DD");

    this.setState({
      startDate,
      endDate,
    });
  };

  /**
   * Double login or Timeout session
   */
  handleOk = () => {
    // Close Popup double login
    this.setState({
      visible: false,
    });
  };

  handleFormInputSuibun = (index, value, type, param) => {
    const suibun = this.state.suibun;
    if ((value !== null || value !== undefined) && param) {
      suibun[index][param] = value;
    }

    this.setState({
      suibun,
      isUpdating: true,
    });
  };

  handleFormInputFukuyaku = (index, value, type, param) => {
    const fukuyaku = this.state.fukuyaku;
    if ((value !== null || value !== undefined) && param) {
      fukuyaku[index][param] = value;
    }

    this.setState({
      fukuyaku,
      isUpdating: true,
    });
  };

  handleFormInputKaigo = (index, value, type, param) => {
    const kaigo = this.state.kaigo;
    if ((value !== null || value !== undefined) && param) {
      kaigo[index][param] = value;
    }

    this.setState({
      kaigo,
      isUpdating: true,
    });
  };

  /**
   * @param
   *
   *
   *
   */
  handleFormInput = (value, type, param) => {
    this.setState({
      isUpdating: true,
    });

    switch (type) {
      case "vital": {
        const vital = this.state.vital;
        if ((value !== null || value !== undefined) && param) {
          vital[param] = value;
        }

        this.setState({
          vital,
        });

        return;
      }
      case "meal": {
        const meal = this.state.meal;
        if ((value !== null || value !== undefined) && param) {
          meal[param] = value;
        }

        this.setState({
          meal,
        });

        return;
      }
      case "suibun": {
        const suibun = this.state.suibun;
        if ((value !== null || value !== undefined) && param) {
          suibun[param] = value;
        }

        this.setState({
          suibun,
        });

        return;
      }
      case "hainyou": {
        const hainyou = this.state.hainyou;
        if ((value !== null || value !== undefined) && param) {
          hainyou[param] = value;
        }

        this.setState({
          hainyou,
        });

        return;
      }
      case "haiben": {
        const haiben = this.state.haiben;
        if ((value !== null || value !== undefined) && param) {
          haiben[param] = value;
        }

        this.setState({
          haiben,
        });

        return;
      }
      case "nyuyoku": {
        const nyuyoku = this.state.nyuyoku;
        if ((value !== null || value !== undefined) && param) {
          nyuyoku[param] = value;
        }

        this.setState({
          nyuyoku,
        });

        return;
      }
      case "fukuyaku": {
        const fukuyaku = this.state.fukuyaku;
        if ((value !== null || value !== undefined) && param) {
          fukuyaku[param] = value;
        }

        this.setState({
          fukuyaku,
        });

        return;
      }
      case "tokki": {
        const tokki = this.state.tokki;
        if ((value !== null || value !== undefined) && param) {
          tokki[param] = value;
        }

        this.setState({
          tokki,
        });

        return;
      }
      case "gazou": {
        const gazou = this.state.gazou;

        if (
          (value !== null || value !== undefined) &&
          param &&
          param === "file"
        ) {
          const objData = {};
          objData[type] = value;

          const isLt2M = value.size / 1024 / 1024 < 200;
          if (!isLt2M) {
            // message.error('Image must smaller than 200MB!', 2000);

            return;
          }

          const isJPG1 = value.type === "image/jpeg";
          const isJPG2 = value.type === "image/jpg";
          const isJPG3 = value.type === "image/png";
          const isJPG4 = value.type === "image/git";
          if (isJPG1 || isJPG2 || isJPG3 || isJPG4) {
            getBase64(value, (imageUrl) =>
              this.setState({
                imageUrl,
              })
            );
          }
          else {
            this.setState({
              imageUrl: fileIcon,
            });
          }

          this.setState({
            file: value,
          });

          gazou[param] = value;
        }
        else if ((value !== null || value !== undefined) && param) {
          gazou[param] = value;
        }

        this.setState({
          gazou,
        });

        return;
      }
      default:
        return;
    }
  };

  handleSaveData = (e) => {
    const {
      vital,
      meal,
      suibun,
      hainyou,
      haiben,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou,
      endDate,
      time,
      riyousyaId,
      updateState,
      newTime,
    } = this.state;
    const systemControlName = JSON.parse(localStorage.getItem("menu")).sn;
    const systemControlId = JSON.parse(localStorage.getItem("menu")).si;

    const updateParams = {
      date: dayjs(endDate).format("YYYY-MM-DD"),
      time: newTime ? newTime : time,
      systemControlName,
      systemControlId,
      riyousyaId,
      isTimeChanged: !!(newTime && newTime !== time),
    };

    const tantoId = getValueLocalstorage("tid");

    const objParams = {};
    // Check Active data
    if (updateState.vital && !isEmpty(vital)) {
      if (!vital.tantoId) {
        vital.tantoId = tantoId;
      }

      let spo2 = "";
      if (vital.spo2B) {
        spo2 = vital.spo2B;
      }

      if (vital.spo2A) {
        spo2 = spo2 + "-" + vital.spo2A;
      }

      if (spo2) {
        updateParams.spo2 = spo2;
      }

      objParams.vital = Object.assign(
        {},
        convertStringInArr(vital),
        updateParams
      );
    }

    if (updateState.meal && !isEmpty(meal)) {
      if (!meal.tantoId) {
        meal.tantoId = tantoId.toString();
      }

      objParams.meal = Object.assign(
        {},
        convertStringInArr(meal),
        updateParams
      );
    }

    if (updateState.suibun && !isEmpty(suibun)) {
      // if (!suibun.tantoId) {
      //   suibun.tantoId = tantoId.toString();
      // }

      // console.log(suibun);

      // objParams.suibun = Object.assign(
      //   {},
      //   convertStringInArr(suibun),
      //   updateParams
      // );

      const preSuibun = [];

      suibun.forEach((item) => {
        // item.comment ? "" : (item.comment = "");
        // item.kiroku_kubun ? "" : (item.kiroku_kubun = "");
        // item.reportCaseNaiyou ? "" : (item.reportCaseNaiyou = "");
        // item.reportCaseNaiyou2 ? "" : (item.reportCaseNaiyou2 = "");
        // item.reportCaseNishif ? "" : (item.reportCaseNishif = "");
        // item.reportCaseTyuif ? "" : (item.reportCaseTyuif = "");
        if (!item.tantoId) {
          item.tantoId = tantoId.toString();
        }
        preSuibun.push(
          Object.assign({}, convertStringInArr(item), updateParams)
        );
      });

      objParams.suibun = preSuibun;
    }

    if (updateState.hainyou && !isEmpty(hainyou)) {
      if (!hainyou.tantoId) {
        hainyou.tantoId = tantoId.toString();
      }

      // set haisetuF = 0 to default
      if (!hainyou.haisetuF) {
        hainyou.haisetuF = "0";
      }

      objParams.hainyou = Object.assign(
        {},
        convertStringInArr(hainyou),
        updateParams,
        { haisetuKubun: "排尿" }
      );
    }

    if (updateState.haiben && !isEmpty(haiben)) {
      if (!haiben.tantoId) {
        haiben.tantoId = tantoId.toString();
      }

      // set haisetuF = 0 to default
      if (!haiben.haisetuF) {
        haiben.haisetuF = "0";
      }

      objParams.haiben = Object.assign(
        {},
        convertStringInArr(haiben),
        updateParams,
        { haisetuKubun: "排便" }
      );
    }

    if (updateState.nyuyoku && !isEmpty(nyuyoku)) {
      if (!nyuyoku.tantoId) {
        nyuyoku.tantoId = tantoId.toString();
      }

      if (nyuyoku.nyuyokuNaiyou) {
        nyuyoku.nyuyoku_naiyou = nyuyoku.nyuyokuNaiyou;
      }

      objParams.nyuyoku = Object.assign(
        {},
        convertStringInArr(nyuyoku),
        updateParams
      );
    }

    if (updateState.fukuyaku && !isEmpty(fukuyaku)) {
      // if (!fukuyaku.tantoId) {
      //   fukuyaku.tantoId = tantoId.toString();
      // }

      // objParams.fukuyaku = Object.assign(
      //   {},
      //   convertStringInArr(fukuyaku),
      //   updateParams
      // );

      const preFukuyaku = [];

      fukuyaku.forEach((item) => {
        // item.comment ? "" : (item.comment = "");
        // item.kiroku_kubun ? "" : (item.kiroku_kubun = "");
        // item.reportCaseNaiyou ? "" : (item.reportCaseNaiyou = "");
        // item.reportCaseNaiyou2 ? "" : (item.reportCaseNaiyou2 = "");
        // item.reportCaseNishif ? "" : (item.reportCaseNishif = "");
        // item.reportCaseTyuif ? "" : (item.reportCaseTyuif = "");
        if (!item.tantoId) {
          item.tantoId = tantoId.toString();
        }
        preFukuyaku.push(
          Object.assign({}, convertStringInArr(item), updateParams)
        );
      });

      objParams.fukuyaku = preFukuyaku;
    }

    if (updateState.kaigo && !isEmpty(kaigo)) {
      const preKaigo = [];

      kaigo.forEach((item) => {
        if (!item.youtei) {
          if (!item.tantoId) {
            item.tantoId = tantoId.toString();
          }

          item.comment ? "" : (item.comment = "");
          item.kiroku_kubun ? "" : (item.kiroku_kubun = "");
          item.reportCaseNaiyou ? "" : (item.reportCaseNaiyou = "");
          item.reportCaseNaiyou2 ? "" : (item.reportCaseNaiyou2 = "");
          item.reportCaseNishif ? "" : (item.reportCaseNishif = "");
          item.reportCaseTyuif ? "" : (item.reportCaseTyuif = "");

          preKaigo.push(
            Object.assign({}, convertStringInArr(item), updateParams)
          );
        }
      });

      objParams.kaigo = preKaigo;
    }

    if (updateState.tokki && !isEmpty(tokki)) {
      if (!tokki.tantoId) {
        tokki.tantoId = tantoId.toString();
      }

      objParams.tokki = Object.assign(
        {},
        convertStringInArr(tokki),
        updateParams
      );
    }

    if (updateState.gazou && !isEmpty(gazou)) {
      if (!gazou.tantoId) {
        gazou.tantoId = tantoId.toString();

        if (this.state.imageUrl) {
          updateParams.thumbnail = this.state.imageUrl;
        }
      }

      objParams.gazou = Object.assign(
        {},
        convertStringInArr(gazou),
        updateParams
      );
    }

    // let objParams = {
    //   vital: Object.assign({}, convertStringInArr(vital), isEmpty(vital) ? {} : updateParams),
    //   meal: Object.assign({}, convertStringInArr(meal), isEmpty(meal) ? {} : updateParams),
    //   suibun: Object.assign({}, convertStringInArr(suibun), isEmpty(suibun) ? {} : updateParams),
    //   haisetu: Object.assign({}, convertStringInArr(haisetu), isEmpty(haisetu) ? {} : updateParams),
    //   nyuyoku: Object.assign({}, convertStringInArr(nyuyoku), isEmpty(nyuyoku) ? {} : updateParams),
    //   fukuyaku: Object.assign({}, convertStringInArr(fukuyaku), isEmpty(fukuyaku) ? {} : updateParams),
    //   kaigo: Object.assign({}, convertStringInArr(kaigo), isEmpty(kaigo) ? {} : updateParams),
    //   tokki: Object.assign({}, convertStringInArr(tokki), isEmpty(tokki) ? {} : updateParams),
    //   gazou: Object.assign({}, convertStringInArr(gazou), isEmpty(gazou) ? {} : updateParams),
    // }

    if (!isEmpty(objParams)) {
      /**
       * If time have been change, the data has been reload after saving
       *
       **/
      if (updateParams.isTimeChanged) {
        const preParam = [0, 0, 0, 0, 0, 0, 0, 0, 0];

        preParam.push(riyousyaId);
        preParam.push(endDate);
        preParam.push(updateParams.time);

        objParams.paramReload = preParam;
      }

      this.props.handleSaveData(objParams);
    }

    this.setState({
      isUpdating: false,
    });

    e.preventDefault();
    this.props.history.goBack();
  };

  handleYoutei = (item) => {
    const { endDate, time, riyousyaId } = this.state;

    const updateParams = {
      date: dayjs(endDate).format("YYYY-MM-DD"),
      time,
      systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
      systemControlId: JSON.parse(localStorage.getItem("menu")).si,
      riyousyaId,
    };

    const tantoId = getValueLocalstorage("tid");

    const objParams = {};

    if (item && !isEmpty(item)) {
      const preKaigo = [];

      if (!item.tantoId) {
        item.tantoId = tantoId;
      }
      preKaigo.push(Object.assign({}, convertStringInArr(item), updateParams));

      objParams.kaigo = preKaigo;
    }

    if (!isEmpty(objParams)) {
      this.props.saveByYoutei(objParams);
    }
  };

  /**
   *
   */
  loadZenkai = () => {
    // load zenkai
    const { endDate, riyousyaId, time } = this.state;
    this.props.loadZenkai(endDate, riyousyaId, time);
  };

  /**
   *
   * handle edit comment default setting
   */
  /**
   *
   * @param {*} index
   */
  getYougoMasterEditState(index, type) {
    this.props.yougoMasterEditState(index, type);
  }

  /**
   *
   * @param {*} index
   */
  getYougoMasterEditCancel = (index, type) => {
    this.props.yougoMasterEditCancel(index, type);
  };

  /**
   *
   * @param {*} naiyou
   */
  getYougoMasterEditSave(naiyou, type) {
    this.props.yougoMasterEditSave(naiyou, type);
  }
  // END handle default comment setting

  /**
   *
   * handle edit comment default setting
   */
  /**
   *
   * @param {*} index
   */
  getYougoMasterEctState(index) {
    this.props.yougoMasterEctState(index);
  }

  /**
   *
   * @param {*} index
   */
  getYougoMasterEctCancel(index) {
    this.props.yougoMasterEctCancel(index);
  }

  /**
   *
   * @param {*} naiyou
   */
  getYougoMasterEctSave(type, naiyou) {
    this.props.yougoMasterEctSave(type, naiyou);
  }
  // END handle default comment setting

  onChangeTime = (timeSting) => {
    localStorage.setItem("timeActive", timeSting);
    this.setState({
      newTime: timeSting,
    });
  };

  /**
   * Drug search
   */
  getHandleDrugSearch(bunrui, name) {
    this.props.loadDrugMaster(bunrui, name);
  }

  handleOpenColl = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  saveButUpdate = (value) => {
    this.setState({
      updateState: value,
    });
  };

  onClickAddSuibun = () => {
    const tmpSuibun = this.state.suibun;
    tmpSuibun.push({ addNew: true });

    this.setState({
      suibun: tmpSuibun,
    });
  };

  onClickAddFukuyaku = () => {
    const tmpFukuyaku = this.state.fukuyaku;
    tmpFukuyaku.push({ addNew: true });

    this.setState({
      fukuyaku: tmpFukuyaku,
    });
  };

  onClickAddKaigo = () => {
    const tmpKaigo = this.state.kaigo;
    tmpKaigo.push({ addNew: true });

    this.setState({
      kaigo: tmpKaigo,
    });
  };

  delRecord = (type, recordId, kaigoIndex) => {
    switch (type) {
      case 1:
        if (!recordId) {
          this.setState({
            vital: {},
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      case 2:
        if (!recordId) {
          this.setState({
            meal: {},
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      case 3:
        if (!recordId) {
          this.setState({
            suibun: {},
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      case 41:
        if (!recordId) {
          this.setState({
            hainyou: {},
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      case 42:
        if (!recordId) {
          this.setState({
            haiben: {},
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      case 5:
        if (!recordId) {
          this.setState({
            nyuyoku: {},
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      case 6:
        if (!recordId) {
          this.setState({
            fukuyaku: {},
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      case 7:
        if (!recordId) {
          let preKaigo = this.state.kaigo;
          preKaigo = preKaigo.filter((item, index) => {
            return index !== kaigoIndex;
          });

          this.setState({
            kaigo: preKaigo,
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      case 8:
        if (!recordId) {
          this.setState({
            tokki: {},
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      case 9:
        if (!recordId) {
          this.setState({
            gazou: {},
          });
        }
        else {
          this.props.delRecord(type, recordId);
        }

        return;

      default:
        return;
    }
  };

  /**
   * get syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   */
  syokichiItem = (type, kubun) => {
    this.props.syokichiItem(this.state.riyousyaId, kubun);

    this.setState({
      syokichiHaisetuType: type,
    });
  };

  /**
   * update syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   * @param {*} syokichi
   */
  syokichiUpdate = (type, kubun) => {
    let syokichi = null;

    if (kubun === ConstSet.HAISETU_SYOKICHI_NAIYOU) {
      if (type === 1) {
        syokichi = this.state.hainyou.shurui ? this.state.hainyou.shurui : null;
      }

      if (type === 2) {
        syokichi = this.state.haiben.shurui ? this.state.haiben.shurui : null;
      }
    }

    if (kubun === ConstSet.NYUYOKU_SYOKICHI_NAIYOU) {
      syokichi = this.state.nyuyoku.nyuyokuNaiyou
        ? this.state.nyuyoku.nyuyokuNaiyou
        : null;
    }

    if (syokichi) {
      this.props.syokichiUpdate(this.state.riyousyaId, kubun, syokichi);
    }
  };

  handleGoBack = (e) => {
    e.preventDefault();

    const { isUpdating } = this.state;
    if (isUpdating) this.showGoBackConfirm();
    else this.props.history.goBack();
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.goBack();
  }

  render() {
    const { tbDisType, endDate } = this.state;

    const {
      reports,
      loading,
      tantoMaster,
      zenkai,
      loadingZenkai,
      settingData,

      yougoMasterVC,
      yougoMasterMC,
      yougoMasterSC,
      yougoMasterHC,
      yougoMasterNC,
      yougoMasterFC,
      yougoMasterKC,
      yougoMasterTC,
      yougoMasterGC,

      yougoMasterSy, // synrui の
      haisetuType,
      haisetuState,
      nyuyokuType,

      // yougoMasterJt,
      yougoMasterEctSelect1,
      yougoMasterEctSelect2,
      yougoMasterEctSelect3,

      // drug
      drugList,
      drugLoading,

      loadingSave,
    } = this.props;

    const search = this.props.location.search; // could be '?timeRange=...'
    const params = new URLSearchParams(search);
    const timeRange = params.get("timeRange"); // timeRange
    const dateUrl = params.get("date"); // timeRange

    const {
      vital,
      meal,
      suibun,
      hainyou,
      haiben,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou,
      file,
      imageUrl,
      disableTime,
      isOpen,
      riyousyaId,
      isUpdating,
      openTab,
      newTime,
      filePreview,
    } = this.state;

    return (
      (<CommonPageContainer sessionTimeout={this.props.sessionTimeout}>
        <div id="report-keekly-add-page">
          <Affix offsetTop={0}>
            <div
              style={{
                background: "rgba(33, 33, 33, 0.85)",
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Row
                className="submenu"
                style={{ width: "1000px", background: "none" }}
              >
                <MediaQuery query="(min-device-width: 0px)">
                  {(matches) => {
                    if (matches) {
                      return (
                        <SubHeaderV2
                          TopLeft={
                            <SubHeaderTitleV2
                              title={"記録入力（利用者）"}
                              iconType="form"
                            />
                          }
                          TopRight={
                            <div>
                              <Link
                                to={"/"}
                                onClick={this.handleGoBack}
                                className="sub-top-right-item"
                              >
                                <span
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <UndoOutlined />
                                </span>
                                <span>{"戻る"}</span>
                              </Link>

                              <a
                                onClick={this.handleSaveData}
                                className="sub-top-right-item"
                              >
                                <span>
                                  {loadingSave ? (
                                    <SyncOutlined spin />
                                  ) : (
                                    <SaveOutlined style={{ color: "red" }} />
                                  )}
                                </span>
                                <span>登録</span>
                              </a>
                            </div>
                          }
                          IncBottom={
                            <SubMenuInc
                              displayTableType={this.displayTableType}
                              tbDisType={tbDisType}
                              handleDateChange={this.handleDateChange}
                              handlePreviewDate={this.handlePreviewDate}
                              handleNextDate={this.handleNextDate}
                              endDate={dayjs(endDate, ConstSet.DATE_FORMAT)}
                              reportRiyousya={this.getReportRiyousya}
                              riyousayInfo={this.state.riyousayInfo}
                              riyousyaId={this.props.match.params.userId}
                              modoru={
                                ConstSet.REPORT_WEEKLY_ADD_PAGE +
                                this.props.match.params.userId
                              }
                              timeRange={timeRange}
                              newTime={newTime}
                              dateUrl={dateUrl}
                              handleSaveData={this.handleSaveData}
                              loadingSave={loadingSave}
                              isOpen={isOpen}
                              handleOpenColl={this.handleOpenColl}
                              //
                              onChangeTime={this.onChangeTime}
                              disableTime={disableTime} // disable time when edit
                              isUpdating={isUpdating}
                            />
                          }
                        />
                      );
                    }
                    /**
                       * @TODO develop UI version
                       *
                       */
                    // return <SubHeaderMobile
                    //   isDengon={false}
                    //   isUser={false}
                    //   isDailyRe={true}
                    //   title={"記録入力（利用者）"}
                    //   getTagDisplay={this.getTagDisplay}
                    //   isTagDisPlay={true}
                    // />

                  }}
                </MediaQuery>
              </Row>
            </div>
          </Affix>

          <Content
            tbDisType={tbDisType}
            reports={reports}
            loading={loading}
            date={endDate}
            //
            vital={vital}
            meal={meal}
            suibun={suibun}
            hainyou={hainyou}
            haiben={haiben}
            nyuyoku={nyuyoku}
            fukuyaku={fukuyaku}
            kaigo={kaigo}
            tokki={tokki}
            gazou={gazou}
            timeRange={timeRange}
            tantoMaster={tantoMaster}
            handleFormInput={this.handleFormInput}
            handleFormInputSuibun={this.handleFormInputSuibun}
            handleFormInputFukuyaku={this.handleFormInputFukuyaku}
            handleFormInputKaigo={this.handleFormInputKaigo}
            loadZenkai={this.loadZenkai}
            zenkai={zenkai}
            loadingZenkai={loadingZenkai}
            // setting data
            settingData={settingData}
            // Automaticall Select Comment From Setting Function
            yougoMasterEditState={this.getYougoMasterEditState}
            yougoMasterEditCancel={this.getYougoMasterEditCancel}
            yougoMasterEditSave={this.getYougoMasterEditSave}
            //
            yougoMasterVC={yougoMasterVC}
            yougoMasterMC={yougoMasterMC}
            yougoMasterSC={yougoMasterSC}
            yougoMasterHC={yougoMasterHC}
            yougoMasterNC={yougoMasterNC}
            yougoMasterFC={yougoMasterFC}
            yougoMasterKC={yougoMasterKC}
            yougoMasterTC={yougoMasterTC}
            yougoMasterGC={yougoMasterGC}
            // other comment input type. form combox type. data has been load from setting
            yougoMasterEctSelect1={yougoMasterEctSelect1}
            yougoMasterEctSelect2={yougoMasterEctSelect2}
            yougoMasterEctSelect3={yougoMasterEctSelect3}
            yougoMasterEctState={this.getYougoMasterEctState}
            yougoMasterEctCancel={this.getYougoMasterEctCancel}
            yougoMasterEctSave={this.getYougoMasterEctSave}
            //
            yougoMasterSy={yougoMasterSy}
            haisetuType={haisetuType}
            haisetuState={haisetuState}
            nyuyokuType={nyuyokuType}
            drugList={drugList} // loading list of drug master
            drugLoading={drugLoading}
            yougoDrugBunrui={
              settingData && settingData.yougoDrugBunrui
                ? settingData.yougoDrugBunrui
                : ""
            }
            //
            teikyouData={
              settingData && settingData.teikyou ? settingData.teikyou : null
            }
            // gazou kubun
            gazouKubun={
              settingData && settingData.gazouKubun
                ? settingData.gazouKubun
                : ""
            }
            file={file}
            imageUrl={imageUrl}
            handleDrugSearch={this.getHandleDrugSearch}
            isOpen={isOpen}
            saveButUpdate={this.saveButUpdate}
            onClickAddSuibun={this.onClickAddSuibun}
            onClickAddFukuyaku={this.onClickAddFukuyaku}
            onClickAddKaigo={this.onClickAddKaigo}
            riyousyaId={riyousyaId}
            // delete record
            delRecord={this.delRecord}
            handleYoutei={this.handleYoutei}
            syokichiUpdate={this.syokichiUpdate}
            syokichiItem={this.syokichiItem}
            loadingSave={loadingSave}
            openTab={openTab}
            filePreview={filePreview}
          />
        </div>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
        {this.state.redirect ? (
          <Redirect
            to={
              ConstSet.REPORT_WEEKLY_PAGE +
              riyousyaId +
              `?date=${dayjs(endDate).format("YYYY-MM-DD")}&timeActive=${newTime ? newTime : timeRange
              }`
            }
          />
        ) : null}
      </CommonPageContainer>)
    );
  }
}

export default Report;
