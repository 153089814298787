import { Select } from "antd";
import React from "react";

const Option = Select.Option;

export default class JikantaiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.jikantaiState(value);
  }

  changeTime(value) {
    if (!value)
      return "";

    const time = value.split("~")[0];
    const t = ("0" + time).slice(-5);
    const time2 = value.split("~")[1];
    const t2 = ("0" + time2).slice(-5);

    return t + "~" + t2;
  }

  render() {
    const { settingJikanTai, jikantai, disabled } = this.props;

    return (
      <Select
        // showSearch
        style={{ width: 120 }}
        defaultValue={jikantai}
        // optionFilterProp="children"
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        disabled={disabled}
      // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option key={1000} value={"全表示"}>
          {"全表示"}
        </Option>
        {settingJikanTai
          ? settingJikanTai.map((rel, index) => {
            const tmp = rel.split("_");
            tmp[1] = this.changeTime(tmp[1]);
            return (
              <Option key={index} value={tmp[1]}>
                {tmp[0]}
              </Option>
            );
          })
          : null}
      </Select>
    );
  }
}
