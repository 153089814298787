export const handleTimeFormatPicker = function (isOpen) {
  if (isOpen) {
    setTimeout(() => {
      const input = document.getElementsByClassName(
        "ant-time-picker-panel-input"
      );
      window.inputPicker = input;
      if (input[0]) {
        input[0].addEventListener(
          "keypress",
          function () {
            const val = (" " + input[0].value).slice(1);
            if (input[0].value && input[0].value.length === 2) {
              input[0].value = val + ":";
            }
            if (input[0].value && input[0].value.length > 3) {
              if (!val.includes(":")) {
                input[0].value =
                  val.substring(0, 2) + ":" + val.substring(2, 4);
              }
            }
          },
          { capture: true }
        );
      }
    }, 1000);
  }
};

export const guid = function () {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};
