/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
import { BarsOutlined, EnterOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getValueLocalstorage } from "../../common/function_common/functionCommon";
import { General, Label } from "../../constants";
import { ConfirmSignoutDialog } from "../../dialogs";
import { AuthActionTypes } from "../../redux/action_types";
import { AuthActions } from "../../redux/actions";
import { StorageUtils } from "../../utils";

function Header({ avatar, onSignout, config, onHandleMenu, history }) {
  const [visibleDialog, updateVisibledialog] = useState(false);
  const menuKiroku = StorageUtils.getValue("menuMNGT");
  const formatMenu = menuKiroku && menuKiroku.menu.split(";");
  const format = formatMenu && formatMenu.map((item) => item.split(","));
  const current = StorageUtils.getValue("currentmenuMNGT");

  const tokens =
    StorageUtils.getValue("tokens") && StorageUtils.getValue("tokens").token;
  let userName = "";
  if (tokens && getValueLocalstorage("tid")) {
    const decoded = jwtDecode(tokens);
    userName = decoded && decoded.userInfo && decoded.userInfo.name;
    const loginMode = getValueLocalstorage("mode");
    if (loginMode) {
      userName = loginMode + "_" + userName;
    }
  }

  useEffect(() => {
    if (format && current) {
      if (current != `${menuKiroku.scn}_${menuKiroku.si}`) {
        const menu = format.find(
          (item) =>
            item[3] == current.split("_")[0] && item[0] == current.split("_")[1]
        );

        if (menu) {
          onHandleMenu(menu);
        }
      }
    }
    else {
      StorageUtils.setValue(
        "currentmenuMNGT",
        `${format[0][3]}_${format[0][0]}`
      );
    }
  }, []);

  const handleLink = (item) => {
    StorageUtils.setValue("currentmenuMNGT", `${item[3]}_${item[0]}`);
    history.push("/management/dengon");
  };

  const handleUserLink = () => {
    history.push("/management/user-management");
  };

  const menuHeader =
    format &&
    format.map((item) => {
      return (
        <Menu.Item key={`${item[3]}_${item[0]}`}>
          <div
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              handleLink(item);
              onHandleMenu(item);
            }}
            className={`${(config && config.values && config.values[2] == item[2]) ||
              (menuKiroku && menuKiroku.sy == item[2])
              ? "active-menu"
              : ""
              }`}
          >
            {item[2]}
          </div>
        </Menu.Item>
      );
    });
  const menu = (
    <Menu selectedKeys={[current]}>
      {menuHeader}
      <Menu.Divider />
      <Menu.Item key="callback">
        <div
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            handleUserLink();
          }}
        >
          {Label.Header.DropdownItem.Profile}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => updateVisibledialog(true)}
        >
          {Label.Header.DropdownItem.Signout}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header
      style={
        menuKiroku?.sn === "yobou"
          ? { backgroundColor: "#1E88E5" }
          : menuKiroku?.sn === "syoukibo" || menuKiroku?.sn === "fukugo"
            ? { backgroundColor: "#7B1FA2" }
            : { backgroundColor: "#303F9F" }
      }
      className="kyotaku-header"
    >
      {/* header left */}
      <div className="kyotaku-header__left">
        <div className="kyotaku-header__logo">
          <img src={General.Logo} className="kyotaku-header__logo" />
        </div>
        {/* タイトル名 */}
        <h2 className="kyotaku-header__logo-title">
          {menuKiroku ? menuKiroku.sy : Label.General.LogoTitle}
        </h2>
      </div>

      {/* header right */}
      <div className="kyotaku-header__right">
        {getValueLocalstorage("mode") ? (
          <div className={"h-r-item"}>
            <Link to="/management/kanri/user">
              <Button type="dashed" style={{ marginTop: 8, marginRight: 8 }}>
                管理画面
                <EnterOutlined />
              </Button>
            </Link>
          </div>
        ) : null}

        <h2 className="kyotaku-header__nickname">{userName}</h2>

        <div className="kyotaku-header__avatar">
          <img alt="avatar" src={avatar} />
        </div>

        <div className="kyotaku-header__menu">
          <Dropdown overlay={menu} trigger={["click"]}>
            <BarsOutlined style={{ fontSize: "36px", padding: "2px", color: "#fff" }} />
          </Dropdown>
        </div>
      </div>

      {/* confirm signout dialog */}
      <ConfirmSignoutDialog
        visible={visibleDialog}
        onOk={onSignout}
        onCancel={() => updateVisibledialog(false)}
      />
    </header>
  );
}
const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSignout: () => dispatch(AuthActions.signout()),
  onHandleMenu: (values) =>
    dispatch({
      type: AuthActionTypes.HANDLE_MENU,
      values,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
