import {
  LOAD_SERVICE_STATUS_REQUEST,
  LOAD_SERVICE_STATUS_SUCCESS,
  LOAD_SERVICE_STATUS_ERROR,
  UPDATE_SERVICE_STATUS_SUCCESS,
  UPDATE_SERVICE_STATUS_REQUEST,
  UPDATE_SERVICE_STATUS_ERROR,
  RESET_STATUS
} from "../../actions/serviceStatusActions/actionName";
import { notification } from "antd/lib/index";

const INITIAL_STATE = {
  messages: [],
  notifyDisplay: 0

};
export default function serviceStatusReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        notifyContentInsert: {},
        notifyContentEdit: {}
      };

    case LOAD_SERVICE_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_SERVICE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceStatus: action.data
      };
    case LOAD_SERVICE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        serviceStatus: action.data
      };
      case UPDATE_SERVICE_STATUS_REQUEST:
        return {
          ...state,
          loading: true
        }
      case UPDATE_SERVICE_STATUS_SUCCESS:
        notification["success"]({
          message: '保存しました',
          placement: "topRight",
          duration: 3,
          style: { whiteSpace: "pre-wrap" },
        });
        return {
          ...state,
          serviceStatus: action.data,
          loading: false,
          notifyContentEdit: { type: "success" }
        }
      case UPDATE_SERVICE_STATUS_ERROR:
        notification["error"]({
          message: action.error.message,
          placement: "topRight",
          duration: 3,
          style: { whiteSpace: "pre-wrap" },
        });
        return {
          ...state,
          loading: false,
          notifyContentEdit: { type: "error" }
        }
    default:
      return state;

  }
}