import { Button, Col, Input, Radio, Row, Select } from "antd";
import React, { Component } from "react";
import MasterCodeInput from "../../../common/component/control/MasterCodeInput";

const { Option } = Select;

const gengo = ["令和", "平成", "昭和", "大正", "明治"];
const year = 64;
const month = 12;
const day = 31;

class FormAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gengo: this.selectOptions(gengo, 1),
      year: this.selectOptions(year, 2),
      month: this.selectOptions(month, 2),
      day: this.selectOptions(day, 2),
      errText1: "利用者名を入力してください",
      errText2: "フリガナを入力してください",
      errText3: "生年月日を入力してください",
    };
  }

  selectOptions = (value, type) => {
    const options = [
      <Option key={0} value="">
        &nbsp;
      </Option>,
    ];
    if (type == 1) {
      for (let i = 0; i < value.length; i++) {
        options.push(
          <Option key={i + 1} value={value[i]}>
            {value[i]}
          </Option>
        );
      }
    }

    if (type == 2) {
      for (let i = 1; i <= value; i++) {
        options.push(
          <Option key={i} value={i}>
            {i}
          </Option>
        );
      }
    }

    return options;
  };

  onChange = (e, field) => {
    this.props.onChange(e, field);
  };

  onBlur = (type) => {
    const { data } = this.props;

    if (type == 0 && data.name) {
      this.props.onBlur(type);
    }

    if (type == 1 && data.furigana) {
      this.props.onBlur(type);
    }

    if (type == 2 && data.gengo && data.year && data.month && data.day) {
      this.props.onBlur(type);
    }
  };

  handleSearchRiyou = (type) => {
    const { data } = this.props;

    if (type == 0) {
      if (data.name) {
        this.props.handleSearchRiyou(type);
      }
      else {
        this.props.errCheck(type);
      }
    }

    if (type == 1) {
      if (data.furigana) {
        this.props.handleSearchRiyou(type);
      }
      else {
        this.props.errCheck(type);
      }
    }

    if (type == 2) {
      if (data.gengo && data.year && data.month && data.day) {
        this.props.handleSearchRiyou(type);
      }
      else {
        this.props.errCheck(type);
      }
    }
  };

  render() {
    const { data, systemIds, reference, err } = this.props;
    const { gengo, year, month, day } = this.state;

    return (
      <div>
        {err[0] ? (
          <Row>
            <Col sm={{ span: 18, offset: 6 }}>
              <p style={{ color: "red" }}>{this.state.errText1}</p>
            </Col>
          </Row>
        ) : null}

        <Row style={{ marginBottom: 10 }}>
          <Col sm={{ span: 6 }}>
            <p style={{ fontWeight: "bold", paddingTop: 5 }}>利用者名</p>
          </Col>
          <Col sm={{ span: 18 }}>
            <Input
              value={data.name}
              style={{ width: "80%" }}
              onChange={(e) => this.onChange(e, "name")}
              onBlur={() => this.onBlur(0)}
            />
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              onClick={() => this.handleSearchRiyou(0)}
            >
              検索
            </Button>
          </Col>
        </Row>

        {err[1] ? (
          <Row>
            <Col sm={{ span: 18, offset: 6 }}>
              <p style={{ color: "red" }}>{this.state.errText2}</p>
            </Col>
          </Row>
        ) : null}

        <Row style={{ marginBottom: 10 }}>
          <Col sm={{ span: 6 }}>
            <p style={{ fontWeight: "bold", paddingTop: 5 }}>フリガナ</p>
          </Col>
          <Col sm={{ span: 18 }}>
            <Input
              value={data.furigana}
              style={{ width: "80%" }}
              onChange={(e) => this.onChange(e, "furigana")}
              onBlur={() => this.onBlur(1)}
            />
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              onClick={() => this.handleSearchRiyou(1)}
            >
              検索
            </Button>
          </Col>
        </Row>

        {err[2] ? (
          <Row>
            <Col sm={{ span: 18, offset: 6 }}>
              <p style={{ color: "red" }}>{this.state.errText3}</p>
            </Col>
          </Row>
        ) : null}

        <Row style={{ marginBottom: 10 }}>
          <Col sm={{ span: 6 }}>
            <p style={{ fontWeight: "bold", paddingTop: 5 }}>生年月日</p>
          </Col>
          <Col sm={{ span: 18 }}>
            <Select
              value={data.gengo}
              style={{ width: 80 }}
              onChange={(e) => this.onChange(e, "gengo")}
              onBlur={() => this.onBlur(2)}
            >
              {gengo}
            </Select>
            <Select
              value={data.year}
              style={{ width: 60 }}
              onChange={(e) => this.onChange(e, "year")}
              onBlur={() => this.onBlur(2)}
            >
              {year}
            </Select>
            <Select
              value={data.month}
              style={{ width: 60 }}
              onChange={(e) => this.onChange(e, "month")}
              onBlur={() => this.onBlur(2)}
            >
              {month}
            </Select>
            <Select
              value={data.day}
              style={{ width: 60 }}
              onChange={(e) => this.onChange(e, "day")}
              onBlur={() => this.onBlur(2)}
            >
              {day}
            </Select>
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              onClick={() => this.handleSearchRiyou(2)}
            >
              検索
            </Button>
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col sm={{ span: 6 }}>
            <p style={{ fontWeight: "bold" }}>性別</p>
          </Col>
          <Col sm={{ span: 18 }}>
            <Radio.Group
              value={data.gender ? data.gender : 1}
              onChange={(e) => this.onChange(e, "gender")}
            >
              <Radio value={1} defaultChecked>
                男
              </Radio>
              <Radio value={2}>女</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col sm={{ span: 6 }}>
            <p style={{ fontWeight: "bold" }}>利用者ID</p>
          </Col>
          <Col sm={{ span: 18 }}>
            <p>{data.id}</p>
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col sm={{ span: 6 }}>
            <p style={{ fontWeight: "bold" }}>システム利用者ID</p>
          </Col>
          <Col sm={{ span: 18 }}>
            <p>{systemIds}</p>
          </Col>
        </Row>

        {reference ? (
          <MasterCodeInput
            style={{ display: "none" }}
            maxLength={10}
            checkType={true}
            reference={reference}
            onChange={(value) => this.onChange(value, "code")}
          />
        ) : null}
      </div>
    );
  }
}

export default FormAdd;
