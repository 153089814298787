/* eslint-disable react/display-name */
import { Form } from "@ant-design/compatible";
import { Modal } from "antd";
import React from "react";

// import '@ant-design/compatible/assets/index.css';


import CommentKB from "../../../common/model-kiroku/CommentKB";

export const InputEventPlanDialog = Form.create()(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        plan: props.plan,
      };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      if (this.props.plan !== newProps.plan) {
        this.setState({
          plan: newProps.plan,
        });
      }
    }

    handleOnChange(value, type) {
      this.setState({
        plan:
          type && type === "select" && this.state.plan
            ? this.state.plan + "、" + value
            : value,
      });
    }

    handleSubmit() {
      this.props.onSubmit({ plan: this.state.plan });
      this.setState({
        plan: null,
      });
    }

    handleCancel() {
      this.setState({
        plan: this.props.plan,
      });
      this.props.onCancel();
    }

    render() {
      const { visible } = this.props;
      return (
        (<Modal
          open={visible}
          title="予定入力"
          onCancel={() => this.handleCancel()}
          onOk={() => this.handleSubmit()}
          cancelButtonProps={{ hidden: true }}
        >
          <Form layout="vertical">
            <Form.Item label="予定" className={"yotei-input"}>
              <CommentKB
                yougoMasterEditState={(index) =>
                  this.props.yougoMasterEditState(index)
                }
                yougoMasterEditCancel={(index) =>
                  this.props.yougoMasterEditCancel(index)
                }
                yougoMasterEditSave={(naiyou) =>
                  this.props.yougoMasterEditSave(naiyou)
                }
                defaultValue={this.state.plan}
                handleTextareaKB={(value, type) =>
                  this.handleOnChange(value, type)
                }
                yougoMasterNa={this.props.yougoMasterNaiyou}
              />
            </Form.Item>
          </Form>
        </Modal>)
      );
    }
  }
);
