import {
  LOAD_BASIC_ACTION_REQUEST,
  LOAD_BASIC_ACTION_SUCCESS,
  LOAD_BASIC_ACTION_ERROR,
  UPDATE_BASIC_ACTION_SUCCESS,
  UPDATE_BASIC_ACTION_REQUEST,
  UPDATE_BASIC_ACTION_ERROR,
  RESET_STATUS,
  KEEP_VALUE
} from "../../actions/basicActionActions/actionName";
import { notification } from "antd/lib/index";

const INITIAL_STATE = {
  messages: [],
  notifyDisplay: 0

};
export default function basicActionReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        notifyContentInsert: {},
        notifyContentEdit: {}
      };
      case KEEP_VALUE:
      return {
        ...state,
        basicAction: action.data
      };
    case LOAD_BASIC_ACTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_BASIC_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        basicAction: action.data
      };
    case LOAD_BASIC_ACTION_ERROR:
      return {
        ...state,
        loading: false,
        basicAction: action.data
      };
    case UPDATE_BASIC_ACTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_BASIC_ACTION_SUCCESS:
      notification["success"]({
        message: "保存しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        basicAction: action.data,
        loading: false,
        notifyContentEdit: { type: "success" }
      };
    case UPDATE_BASIC_ACTION_ERROR:
      notification["error"]({
        message: action.error.message,
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        loading: false,
        notifyContentEdit: { type: "error" }
      };
    default:
      return state;

  }
}