import React from "react";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Modal, Table, Input, Row, Col, Button, Spin, Divider } from "antd";
import { connect } from "react-redux";
import {
  iryouKikanMasterListCreator,
  updateIryouKikanMasterListCreator,
} from "../../../actions/allMasterActions/actionCreators";
import IryoKikanMasterEditModal from "./IryoKikanMasterEditModal";
import { sortedIndex } from "lodash";

class IryoKikanMasterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      search: null,
      visibleEdit: false,
      editType: null,
      sort: false,
      sortTable: [],
      sortTableTmp: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.props.getIryouKikanMaster();
    }
  }

  handleOk() {
    this.props.onSubmit(this.state.selected);

    this.setState({
      selected: null,
      search: null,
      sort: false,
      sortTable: [],
    });
  }

  handleCancel() {
    this.props.onCancel();

    this.setState({
      selected: null,
      search: null,
      sort: false,
      sortTable: [],
      sortTableTmp: [],
    });
  }

  handleSelectRow(record) {
    this.setState({
      selected: record,
    });
  }

  onChangeSearch(e) {
    this.setState({
      search: e.target.value,
    });
  }

  search() {
    const { search } = this.state;

    this.props.getIryouKikanMaster(null, search);
  }

  isDisableButton(selected) {
    return selected && this.props.iryouKikanMasterList
      ? !this.props.iryouKikanMasterList.some((item) => item.id == selected.id)
      : true;
  }

  handleAdd() {
    this.setState({
      visibleEdit: true,
      editType: 1, // add
    });
  }

  handleEdit() {
    this.setState({
      visibleEdit: true,
      editType: 2, // edit
    });
  }

  handleClose() {
    this.search();

    this.setState({
      visibleEdit: false,
      editType: null,
    });
  }

  sortOn = () => {
    const { iryouKikanMasterList } = this.props;
    let tmp = JSON.parse(JSON.stringify(iryouKikanMasterList));
    let tmp2 = JSON.parse(JSON.stringify(iryouKikanMasterList));
    this.setState({
      sort: true,
      sortTable: tmp,
      sortTableTmp: tmp,
    });
  };
  sortOff = () => {
    this.search();

    this.setState({
      sort: false,
      sortTable: [],
      sortTableTmp: [],
    });
  };
  sortSave = () => {
    const { sortTable, sortTableTmp, search } = this.state;

    let tmp = {};
    tmp[0] = [];

    for (let record of sortTable) {
      let defTable = sortTableTmp.find((t) => t.id == record.id);
      if (record.sortNum != defTable.sortNum) {
        tmp[0].push(record);
      }
    }

    this.props.updateIryouKikanMasterList(tmp, (data) => {
      this.props.getIryouKikanMaster(null, search);
    });

    this.setState({
      sort: false,
      sortTable: [],
    });
  };

  sortTop = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const minNum = sortTable[0].sortNum;

    if (elementIndex != 0) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = minNum - 1;
      tableTmp.splice(0, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  sortUp = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);

    if (elementIndex != 0) {
      const changeElement = tableTmp[elementIndex - 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex - 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum--;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum++;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  sortDown = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      const changeElement = tableTmp[elementIndex + 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex + 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum++;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum--;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  sortBottom = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    let maxNum = 0;
    for (let i of tableTmp) {
      maxNum < i.sortNum ? (maxNum = i.sortNum) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = maxNum + 1;
      tableTmp.splice(bottomIndex, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  render() {
    const { sort, sortTable, selected } = this.state;
    const { iryouKikanMasterList } = this.props;

    const title = (
      <div>
        <Row style={{ marginBottom: "4px" }}>
          <Col span={6}>病院選択リスト</Col>
          <Col span={18} style={{ display: "flex" }}>
            <Input
              value={this.state.search}
              onChange={(e) => this.onChangeSearch(e)}
              className={
                sort == false
                  ? "search-service-kikan"
                  : "search-service-kikan-none"
              }
              disabled={sort == true ? true : false}
            />
            <Button
              onClick={() => this.search()}
              className={
                sort == false
                  ? "search-service-kikan"
                  : "search-service-kikan-none"
              }
              icon={<SearchOutlined />}
              disabled={sort == true ? true : false}
            >
              検索
            </Button>
          </Col>
        </Row>
      </div>
    );

    const columns = [
      {
        title: "hospital",
        dataIndex: "hospital",
        key: "hospital",
        width: "200px",
      },
    ];

    //sort
    const columns2 = [
      {
        title: "hospital",
        dataIndex: "hospital",
        key: "hospital",
        width: "200px",
      },
      // { //テスト
      //     title: 'num',
      //     dataIndex: 'sortNum',
      //     width: "25%",
      //     key: "sortNum",
      // },
      {
        title: "行動",
        dataIndex: "operation",
        width: 100,
        key: "operation",
        render: (record, index) => {
          return (
            <div>
              <span>
                <a onClick={() => this.sortTop(index)} style={{}}>
                  <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.sortUp(index)} style={{}}>
                  <CaretUpOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.sortDown(index)} style={{}}>
                  <CaretDownOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.sortBottom(index)} style={{}}>
                  <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
              </span>
            </div>
          );
        },
      },
    ];

    return (
      (<div>
        <Modal
          open={this.props.visible}
          className="modal-list"
          title={title}
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
          width={500}
          closable={false}
          footer={[
            <Button key="back" onClick={() => this.handleCancel()}>
              キャンセル
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => this.handleOk()}
              disabled={sort == true ? true : selected ? false : true}
            >
              OK
            </Button>,
          ]}
        >
          <Button
            key="add"
            type={"primary"}
            onClick={() => this.handleAdd()}
            disabled={sort == true ? true : false}
            style={{ marginRight: 8 }}
          >
            追加
          </Button>
          <Button
            key="edit"
            type={"primary"}
            onClick={() => this.handleEdit()}
            disabled={
              sort == true ? true : this.isDisableButton(this.state.selected)
            }
            style={{ marginRight: 8 }}
          >
            修正
          </Button>
          {sort == false ? (
            <a
              key="sort"
              // type={"primary"}
              onClick={this.sortOn}
              style={{
                marginRight: 8,
                marginTop: 7,
                marginLeft: 10,
                color: "black",
              }}
            >
              並び替え
            </a>
          ) : (
            <span>
              <Button
                key="sortSave"
                type={"primary"}
                onClick={this.sortSave}
                style={{ marginRight: 8 }}
              >
                並び替え保存
              </Button>
              <a
                onClick={this.sortOff}
                style={{ paddingTop: "14px", color: "black" }}
              >
                戻る
              </a>
            </span>
          )}
          <Spin spinning={this.props.loading}>
            {sort === false ? (
              <Table
                bordered={true}
                className="modal-table"
                showHeader={false}
                size={"small"}
                columns={columns}
                pagination={{ pageSize: 20 }}
                scroll={{ y: 300 }}
                rowKey={"id"}
                dataSource={this.props.iryouKikanMasterList}
                onRow={(record) => {
                  return {
                    onClick: () => this.handleSelectRow(record),
                  };
                }}
                rowClassName={(record) =>
                  this.state.selected != null &&
                  record.id === this.state.selected.id
                    ? "pljp-table-row-hover"
                    : "pointer-row"
                }
                style={{ marginTop: 20 }}
              />
            ) : sort === true ? (
              <Table
                bordered={true}
                className="modal-table"
                showHeader={false}
                size={"small"}
                columns={columns2}
                pagination={{ pageSize: 20 }}
                scroll={{ y: 300 }}
                rowKey={"id"}
                dataSource={sortTable}
                // onRow={(record) => {
                //   return {
                //     onClick: () => this.handleSelectRow(record)
                //   };
                // }}
                rowClassName={(record) =>
                  this.state.selected != null &&
                  record.id === this.state.selected.id
                    ? "pljp-table-row-hover"
                    : "pointer-row"
                }
                style={{ marginTop: 20 }}
              />
            ) : null}
          </Spin>
        </Modal>
        <IryoKikanMasterEditModal
          visible={this.state.visibleEdit}
          data={this.state.selected}
          editType={this.state.editType}
          handleSelectRow={(record) => this.handleSelectRow(record)}
          handleClose={() => this.handleClose()}
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.allMaster.loading,
    iryouKikanMasterList: state.allMaster.iryouKikanMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIryouKikanMaster: (bunrui, name) =>
      dispatch(iryouKikanMasterListCreator(bunrui, name)),
    updateIryouKikanMasterList: (data, callback) =>
      dispatch(updateIryouKikanMasterListCreator(data, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IryoKikanMasterModal);
