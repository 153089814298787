import {
  LOAD_NEEDS_ITEM_ERROR,
  LOAD_NEEDS_ITEM_SUCCESS,
  NEEDS_ITEM_SPIN_REQUEST,
} from "../../actions/needsItemActions/actionName";

export default function needsItem(state = {}, action) {
  switch (action.type) {
    case NEEDS_ITEM_SPIN_REQUEST:
      return {
        ...state,
        needsItemLoading: true,
      };
    case LOAD_NEEDS_ITEM_SUCCESS:
      return {
        ...state,
        needsItemList: action.data,
        needsItemLoading: false
      };

    case LOAD_NEEDS_ITEM_ERROR:
      return {
        ...state,
        needsItemList: null,
        needsItemLoading: false
      };


    // case CREATE_DOCTOR_MASTER_SUCCESS:
    // case UPDATE_DOCTOR_MASTER_SUCCESS:
    //   openNotificationWithIcon("success", "保存しました");
    //   return {
    //     ...state,
    //     loading: false,
    //     doctorMaster: action.data
    //   };

    // case UPDATE_LIST_DOCTOR_MASTER_SUCCESS:
    //   openNotificationWithIcon("success", "保存しました");
    //   return {
    //     ...state,
    //     doctorMasterListUpdate: action.payload, //使ってない
    //     // loading: false,
    //   };

    // case CREATE_DOCTOR_MASTER_ERROR:
    // case UPDATE_DOCTOR_MASTER_ERROR:
    //   openNotificationWithIcon("error", "エラー");
    //   return {
    //     ...state,
    //     doctorMaster: null,
    //     loading: false
    //   };

    default:
      return state;
  }
}