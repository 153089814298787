export const REPORT_WEEKLY_ADD_LOADDATA_REQUEST =
  "REPORT_WEEKLY_ADD_LOADDATA_REQUEST";
export const REPORT_WEEKLY_ADD_LOADDATA_SUCCESS =
  "REPORT_WEEKLY_ADD_LOADDATA_SUCCESS";
export const REPORT_WEEKLY_ADD_LOADDATA_ERROR =
  "REPORT_WEEKLY_ADD_LOADDATA_ERROR";

export const REPORT_WEEKLY_ADD_REQUEST = "REPORT_WEEKLY_ADD_REQUEST";
export const REPORT_WEEKLY_ADD_SUCCESS = "REPORT_WEEKLY_ADD_SUCCESS";
export const REPORT_WEEKLY_ADD_ERROR = "REPORT_WEEKLY_ADD_ERROR";

export const REPORT_WEEKLY_ADD_DEL_REQUEST = "REPORT_WEEKLY_ADD_DEL_REQUEST";
export const REPORT_WEEKLY_ADD_DEL_SUCCESS = "REPORT_WEEKLY_ADD_DEL_SUCCESS";
export const REPORT_WEEKLY_ADD_DEL_ERROR = "REPORT_WEEKLY_ADD_DEL_ERROR";

export const REPORT_WEEKLY_ADD_YOUTEI_REQUEST =
  "REPORT_WEEKLY_ADD_YOUTEI_REQUEST";
export const REPORT_WEEKLY_ADD_YOUTEI_SUCCESS =
  "REPORT_WEEKLY_ADD_YOUTEI_SUCCESS";
export const REPORT_WEEKLY_ADD_YOUTEI_ERROR = "REPORT_WEEKLY_ADD_YOUTEI_ERROR";
