/* eslint-disable indent */
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Checkbox, Col, Modal, Row, Select, Table, Tag } from "antd";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import {
  getDateInMonth,
  getMenu,
  getTid,
} from "../../../common/function_common/functionCommon";
import KCommentKB from "./KCommentKB";

const { Option } = Select;

class RowContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      mainSid: JSON.parse(localStorage.getItem(getMenu())).si,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.expandAll && !newProps.expandAll) {
      this.setState({
        expand: false,
      });
    }
    else if (
      newProps.expandedRows.indexOf(this.props.data.reportCaseId) > -1
    ) {
      this.setState({
        expand: true,
      });
    }
    else {
      this.setState({
        expand: false,
      });
    }
  }

  handleOnclick = (e) => {
    if (e.target.type != "button") {
      this.props.handleExpand(!this.state.expand, this.props.data);
    }
  };

  render() {
    const { mainSid } = this.state;
    const dateString =
      this.props.data.date +
      "（" +
      getDateInMonth(
        this.props.data.date.split("-")[0],
        this.props.data.date.split("-")[1],
        this.props.data.date.split("-")[2]
      ) +
      "）";
    if (this.props.isUserMode) {
      return (
        <div className={"report-case-table-row"} onClick={this.handleOnclick}>
          <Row>
            <Col span={4}>
              <span>{dateString}</span>
              <span>
                {this.props.data.time &&
                  this.props.data.time.substring(0, 5) != "00:00"
                  ? this.props.data.time.substring(0, 5)
                  : ""}
              </span>
            </Col>
            <Col span={4}>
              <span style={{ fontWeight: "bold" }}>{"担："}</span>
              <span>{this.props.data.tantoName}</span>
            </Col>
            <Col span={3}>
              <span>
                <strong>
                  {this.props.data.kiroku_kubun
                    ? this.props.data.kiroku_kubun
                    : "　"}
                </strong>
              </span>
            </Col>
            <Col span={3}>
              <span>
                <strong>
                  {this.props.data.sonota_kubun
                    ? this.props.data.sonota_kubun
                    : "　"}
                </strong>
              </span>
            </Col>
            <Col span={2}></Col>
            <Col span={2}>
              {this.props.data.impf == 1 ? (
                <span style={{ paddingRight: "5px", color: "red" }}>重要</span>
              ) : null}
            </Col>
            <Col span={1}>
              {this.props.data.tyuif == 1 ? (
                <span style={{ paddingRight: "5px" }}>
                  <LegacyIcon type={"message"} />
                </span>
              ) : null}
            </Col>
            <Col span={1}>
              {this.props.data.nishif == 1 ? (
                <span style={{ paddingRight: "5px" }}>◆</span>
              ) : null}
            </Col>
            <Col span={4}>
              <Tag color={"#424242"}>{this.props.data.service_syurui}</Tag>
            </Col>
          </Row>
          <Row>
            <span style={{ fontWeight: "bold" }}>{"コメント："}</span>
            <span style={{ whiteSpace: "pre-wrap", display: "inline-flex" }}>
              {!this.props.expandAll &&
                !this.state.expand &&
                this.props.data.naiyou2 &&
                (this.props.data.naiyou2.length > 20 ||
                  this.props.data.naiyou2.indexOf("↵") > -1)
                ? this.props.data.naiyou2.split("↵")[0].substring(0, 20) + "..."
                : this.props.data.naiyou2}
            </span>
          </Row>
          <Row>
            {this.props.expandAll || this.state.expand ? (
              <div>
                <span style={{ fontWeight: "bold" }}>{"記録内容："}</span>
                <span
                  style={{ whiteSpace: "pre-wrap", display: "inline-flex" }}
                >
                  {this.props.data.naiyou}
                </span>
              </div>
            ) : null}
          </Row>
          <Row>
            {this.props.expandAll || this.state.expand ? (
              <div>
                <span style={{ fontWeight: "bold" }}>{"確認コメント："}</span>
                <span
                  style={{
                    whiteSpace: "pre-wrap",
                    display: "inline-flex",
                    color: "red",
                  }}
                >
                  {this.props.data.kakunin_comment}
                </span>
              </div>
            ) : null}
          </Row>
          <div>
            {this.props.expandAll || this.state.expand ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type={"primary"}
                  style={{ marginRight: "1px" }}
                  onClick={() =>
                    this.props.showKCommentConfirm(
                      this.props.data.tanto_id,
                      this.props.data.impf,
                      this.props.data.kakunin_comment
                    )
                  }
                >
                  {"確認コメント"}
                </Button>
                {this.props.data.service_kikan_master_id &&
                  mainSid &&
                  this.props.data.service_kikan_master_id.toString() ===
                  mainSid.toString() ? (
                  <Fragment>
                    <Button
                      icon={<LegacyIcon type={"delete"} />}
                      style={{ color: "white", backgroundColor: "gray" }}
                      onClick={() =>
                        this.props.showDeleteConfirm(
                          this.props.data.reportCaseId
                        )
                      }
                    >
                      {"削除"}
                    </Button>
                    <Button
                      icon={<LegacyIcon type={"edit"} />}
                      style={{ color: "white", backgroundColor: "#dc0606" }}
                      onClick={() =>
                        this.props.handleGoToInputReportCase(this.props.data)
                      }
                    >
                      {"修正"}
                    </Button>
                  </Fragment>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    return (
      <div className={"report-case-table-row"} onClick={this.handleOnclick}>
        <Row>
          <Col span={4}>
            <span>{this.props.data.riyousyaName}</span>
          </Col>
          <Col span={4}>
            <span style={{ fontWeight: "bold" }}>{"担："}</span>
            <span>{this.props.data.tantoName}</span>
          </Col>
          <Col span={4}>
            <span>{dateString}</span>
            <span>
              {this.props.data.time != null &&
                this.props.data.time.substring(0, 5) != "00:00"
                ? this.props.data.time.substring(0, 5)
                : ""}
            </span>
          </Col>
          <Col span={3}>
            <span>
              <strong>{this.props.data.kiroku_kubun}</strong>
            </span>
          </Col>
          <Col span={3}>
            <span>
              <strong>{this.props.data.sonota_kubun}</strong>
            </span>
          </Col>
          {/* <Col span={2}>
          </Col> */}
          <Col span={2}>
            {this.props.data.impf == 1 ? (
              <span style={{ paddingRight: "5px", color: "red" }}>重要</span>
            ) : null}
          </Col>
          <Col span={1}>
            {this.props.data.tyuif == 1 ? (
              <span style={{ paddingRight: "5px" }}>
                <LegacyIcon type={"message"} />
              </span>
            ) : null}
          </Col>
          <Col span={1}>
            {this.props.data.nishif == 1 ? (
              <span style={{ paddingRight: "5px" }}>◆</span>
            ) : null}
          </Col>
          <Col span={2}>
            <Tag color={"#424242"}>{this.props.data.service_syurui}</Tag>
          </Col>
        </Row>
        <Row>
          <span style={{ fontWeight: "bold" }}>{"コメント："}</span>
          <span style={{ whiteSpace: "pre-wrap", display: "inline-flex" }}>
            {!this.props.expandAll &&
              !this.state.expand &&
              this.props.data.naiyou2 &&
              this.props.data.naiyou2.length > 20
              ? this.props.data.naiyou2.substring(0, 20) + "..."
              : this.props.data.naiyou2}
          </span>
        </Row>
        <Row>
          {this.props.expandAll || this.state.expand ? (
            <div>
              <span style={{ fontWeight: "bold" }}>{"記録内容："}</span>
              <span style={{ whiteSpace: "pre-wrap", display: "inline-flex" }}>
                {this.props.data.naiyou}
              </span>
            </div>
          ) : null}
        </Row>
        <Row>
          {this.props.expandAll || this.state.expand ? (
            <div>
              <span style={{ fontWeight: "bold" }}>{"確認コメント："}</span>
              <span
                style={{
                  whiteSpace: "pre-wrap",
                  display: "inline-flex",
                  color: "red",
                }}
              >
                {this.props.data.kakunin_comment}
              </span>
            </div>
          ) : null}
        </Row>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          {this.props.expandAll || this.state.expand ? (
            <div>
              <Button
                type={"primary"}
                style={{ marginRight: "1px" }}
                onClick={() =>
                  this.props.showKCommentConfirm(
                    this.props.data.tanto_id,
                    this.props.data.impf,
                    this.props.data.kakunin_comment
                  )
                }
              >
                {"確認コメント"}
              </Button>
              <Button
                type={"primary"}
                onClick={() =>
                  this.props.handleGoToUser(
                    this.props.data.riyousyaId,
                    this.props.data.riyousyaName
                  )
                }
              >
                {"経過記録へ"}
              </Button>
              {this.props.data.service_kikan_master_id &&
                mainSid &&
                this.props.data.service_kikan_master_id.toString() ===
                mainSid.toString() ? (
                <Fragment>
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    style={{ color: "white", backgroundColor: "gray" }}
                    onClick={() =>
                      this.props.showDeleteConfirm(this.props.data.reportCaseId)
                    }
                  >
                    {"削除"}
                  </Button>
                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    style={{ color: "white", backgroundColor: "#dc0606" }}
                    onClick={() =>
                      this.props.handleGoToInputReportCase(this.props.data)
                    }
                  >
                    {"修正"}
                  </Button>
                </Fragment>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export class ProgressRecordTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteVisible: false,
      kCommentVisible: false,
      tantoVisible: false,
      checked: false,
      impfChecked: false,
      comment: "",
      expandedRowKeys: [],
      tid: getTid() ? getTid() : 0,
      sidCheck: true,
    };

    this.getHandleInputComment = this.getHandleInputComment.bind(this);
  }

  handleExpand(expanded, record) {
    this.setState({
      expandedRowKeys: expanded ? [record.reportCaseId] : [],
    });
  }

  showDeleteConfirm(reportCaseId) {
    this.setState({
      deleteVisible: true,
      expandedRowKeys: [reportCaseId],
    });
  }

  closeDeleteConfirm() {
    this.setState({
      deleteVisible: false,
      checked: false,
    });
  }

  handleConfirmDelete() {
    if (this.state.checked) {
      this.props.handleDeleteReportCase(this.state.expandedRowKeys[0]);
    }
    this.setState({
      deleteVisible: false,
      checked: false,
    });
  }

  handleChangeCheckbox() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  handleChangeImpfCheckbox() {
    this.setState({
      impfChecked: !this.state.impfChecked,
    });
  }

  showKCommentConfirm(tid, impf, comment) {
    this.setState({
      kCommentVisible: true,
      impfChecked: !!(impf && impf == 1),
      comment,
      sidCheck: tid != this.state.tid,
    });
  }

  closeKCommentConfirm() {
    this.setState({
      kCommentVisible: false,
      impfChecked: false,
      comment: "",
      sidCheck: true,
    });
  }

  getHandleInputComment(value) {
    this.setState({
      comment: value,
    });
  }

  handleConfirmKComment() {
    const { expandedRowKeys, impfChecked, comment } = this.state;

    this.props.handleKCommentReportCase({
      reportCaseId: expandedRowKeys[0],
      impf: impfChecked ? "1" : "0",
      comment,
    });

    this.setState({
      kCommentVisible: false,
      impfChecked: false,
      comment: "",
      sidCheck: true,
    });
  }

  showTantoConfirm() {
    this.setState({
      tantoVisible: true,
    });
  }

  closeTantoConfirm() {
    this.setState({
      tantoVisible: false,
      tid: getTid() ? getTid() : 0,
    });
  }

  changeTanto(value) {
    this.setState({
      tid: value,
    });
  }

  setConfirmTanto() {
    const { comment, tid } = this.state;
    const name = _.find(this.props.tantoMasters, { id: tid })
      ? _.find(this.props.tantoMasters, { id: tid }).name
      : null;

    const now = new Date();
    const time =
      ("0" + now.getHours()).slice(-2) +
      ":" +
      ("0" + now.getMinutes()).slice(-2);
    let value = "";
    if (comment) {
      value = comment + "\n" + name + "　" + time;
    }
    else {
      value = name + "　" + time;
    }

    this.setState({
      comment: value,
      tantoVisible: false,
      tid: getTid() ? getTid() : 0,
    });
  }

  render() {
    const { data } = this.props;
    const columns = [
      {
        title: "Content",
        dataIndex: "content",
      },
    ];
    const tableData = [];
    if (data) {
      data.forEach((rc) => {
        tableData.push({
          reportCaseId: rc.reportCaseId,
          riyousyaId: rc.riyousyaId,
          riyousyaName: rc.riyousyaName,
          naiyou: rc.naiyou,
          content: (
            <RowContent
              data={rc}
              isUserMode={!!this.props.riyousyaId}
              expandAll={this.props.expandAll}
              handleGoToUser={(id, name) => this.props.handleGoToUser(id, name)}
              handleGoToInputReportCase={(data) =>
                this.props.handleGoToInputReportCase(data)
              }
              showDeleteConfirm={(reportCaseId) =>
                this.showDeleteConfirm(reportCaseId)
              }
              showKCommentConfirm={(tid, impf, comment) =>
                this.showKCommentConfirm(tid, impf, comment)
              }
              handleExpand={(expanded, record) =>
                this.handleExpand(expanded, record)
              }
              expandedRows={this.state.expandedRowKeys}
            />
          ),
        });
      });
    }
    return (
      (<div style={{ margin: "10px auto", maxWidth: "1200px", width:'100%' }}>
        <Table
          columns={columns}
          dataSource={tableData}
          rowKey={"reportCaseId"}
          showHeader={false}
          pagination={{ pageSize: 100 }}
        />
        <Modal
          open={this.state.deleteVisible}
          title="削除確認（同意するにチェックしてOKボタンを押してください）"
          onCancel={() => this.closeDeleteConfirm()}
          onOk={() => this.handleConfirmDelete()}
          okButtonProps={{ disabled: !this.state.checked }}
        >
          <div style={{ backgroundColor: "aliceblue" }}>
            <span style={{ display: "block" }}>
              {"経過記録を削除しますか？"}
            </span>
            <Checkbox
              checked={this.state.checked}
              onChange={() => this.handleChangeCheckbox()}
              value={this.state.checked}
            >
              {"削除に同意する"}
            </Checkbox>
          </div>
        </Modal>
        <Modal
          open={this.state.kCommentVisible}
          title={
            <Row>
              <Col span={20}>
                <span>申し送り確認コメント</span>
              </Col>
              <Col span={4}>
                <Checkbox
                  disabled={this.state.sidCheck}
                  checked={this.state.impfChecked}
                  onChange={() => this.handleChangeImpfCheckbox()}
                  value={this.state.impfChecked}
                >
                  {"重要"}
                </Checkbox>
              </Col>
            </Row>
          }
          onOk={() => this.handleConfirmKComment()}
          onCancel={() => this.closeKCommentConfirm()}
        >
          <div>
            <Row>
              <Button
                type={"primary"}
                style={{ float: "right", marginBottom: "5px" }}
                onClick={() => this.showTantoConfirm()}
              >
                {"確認担当者"}
              </Button>
            </Row>
            <KCommentKB
              defaultValue={this.state.comment}
              handleKCommentKB={this.getHandleInputComment}
            />
          </div>
        </Modal>
        <Modal
          open={this.state.tantoVisible}
          title={"確認担当者"}
          onOk={() => this.setConfirmTanto()}
          onCancel={() => this.closeTantoConfirm()}
        >
          <Row>
            <p style={{ margin: "5px", fontWeight: "bold", fontSize: "15px" }}>
              担当者
            </p>
            <Select
              value={this.state.tid}
              dropdownMatchSelectWidth={false}
              style={{ width: "70px", marginRight: "5px" }}
              onChange={(value) => this.changeTanto(value)}
            >
              {Array.isArray(this.props.tantoMasters)
                ? this.props.tantoMasters.map((tanto, i) => {
                  return (
                    <Option key={i} value={tanto.id}>
                      {tanto.code}
                    </Option>
                  );
                })
                : null}
            </Select>
            <Select
              value={this.state.tid}
              dropdownMatchSelectWidth={false}
              style={{ width: "170px" }}
              onChange={(value) => this.changeTanto(value)}
            >
              {Array.isArray(this.props.tantoMasters)
                ? this.props.tantoMasters.map((tanto, i) => {
                  return (
                    <Option key={i} value={tanto.id}>
                      {tanto.name}
                    </Option>
                  );
                })
                : []}
            </Select>
          </Row>
        </Modal>
      </div>)
    );
  }
}
