import React, { useState, useEffect } from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Row, Select, Col, Button, Modal } from "antd";
import _ from "lodash";
import { CommonUtils } from "../utils/index";

const FormItem = Form.Item;
const Option = Select.Option;
const FA = require("react-fontawesome");

function SortControl(props) {
  const [sortData, setSortData] = useState(props.sortData);
  const [visible, setVisible] = useState(false);

  // sortDate component parent change
  useEffect(() => {
    setSortData(props.sortData);
  }, [props.sortData]);

  const handleChangeOption = (value, i) => {
    const newValue = _.cloneDeep(sortData);
    newValue["key" + i] = value;
    setSortData(newValue);
  };

  const handleChangeOrder = (value, i) => {
    const newValue = _.cloneDeep(sortData);
    newValue["value" + i] = value;
    setSortData(newValue);
  };

  const showModalSortConditions = () => {
    setVisible(true);
  };

  const handleSortOk = () => {
    props.handleSortData(sortData);
    setVisible(false);
  };

  const handleSortCancel = async () => {
    setVisible(false);
    setSortData(props.sortData);
  };

  const { sortOptions } = props;
  const count = props.count ? props.count : 5;
  const children = [];
  for (let i = 1; i <= count; i++) {
    const selected = [];
    for (let j = 1; j <= count; j++) {
      if (j !== i && sortData["key" + j] !== "0") {
        selected.push(sortData["key" + j]);
      }
    }
    children.push(
      <Row key={"condition-no-" + i}>
        <Col xs={4}>
          <FormItem label={"第" + i + "キー::"} />
        </Col>
        <Col xs={14}>
          <FormItem className="form-controller">
            <Select
              style={{ width: "100%" }}
              value={sortData["key" + i]}
              onChange={(value) => handleChangeOption(value, i)}
            >
              <Option key={CommonUtils.guid()} value="0">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Option>
              {sortOptions.map((item) => (
                <Option
                  key={CommonUtils.guid()}
                  value={item.value}
                  disabled={selected.indexOf(item.value) >= 0}
                >
                  {item.display}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col xs={6}>
          <FormItem>
            <Select
              style={{ width: "100%" }}
              value={sortData["value" + i]}
              onChange={(value) => handleChangeOrder(value, i)}
            >
              <Option value="asc">昇順</Option>
              <Option value="desc">降順</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
    );
  }
  return (
    (<div className="sort-handle" style={props.style}>
      <Button onClick={() => showModalSortConditions()}>
        <FA
          name="sort"
          key={CommonUtils.guid()}
          style={{ paddingRight: "4px" }}
        />
        並替
      </Button>
      <Modal
        title="並び替え"
        open={visible}
        onOk={() => handleSortOk()}
        onCancel={() => handleSortCancel()}
        className={"sort-model"}
      >
        <div className={"kn-search-con"}>{children}</div>
      </Modal>
    </div>)
  );
}

export { SortControl };
