import { YobousienPlanActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";

function yobousienPlan(state = {}, action) {
  switch (action.type) {
    // get all plan1 by user id
    case YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_SUCCESS: {
      return {
        ...state,
        loading: false,
        datas: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_FAILURE: {
      return {
        ...state,
        datas: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }

    // get one plan1
    case YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getOne",
      };
    }
    case YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }
    case YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_FAILURE: {
      return {
        ...state,
        loading: false,
        data: {},
        type: "",
      };
    }

    // create plan1
    case YobousienPlanActionTypes.CREATE_YOBOUSIEN_PLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case YobousienPlanActionTypes.CREATE_YOBOUSIEN_PLAN_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        data: action.data,
        loading: false,
        type: "",
      };
    }
    case YobousienPlanActionTypes.CREATE_YOBOUSIEN_PLAN_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // update plan1
    case YobousienPlanActionTypes.UPDATE_YOBOUSIEN_PLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case YobousienPlanActionTypes.UPDATE_YOBOUSIEN_PLAN_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }
    case YobousienPlanActionTypes.UPDATE_YOBOUSIEN_PLAN_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
        data: {},
        type: "",
      };
    }

    // remove plan1
    case YobousienPlanActionTypes.REMOVE_YOBOUSIEN_PLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case YobousienPlanActionTypes.REMOVE_YOBOUSIEN_PLAN_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case YobousienPlanActionTypes.REMOVE_YOBOUSIEN_PLAN_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    //複製
    case YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_QUOTE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuote: action.data,
      };
    case YobousienPlanActionTypes.GET_ALL_YOBOUSIEN_PLAN_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listQuote: [],
      };

    //前回引用
    case YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_BY_DATA_REQUEST:
      return {
        ...state,
        loadingByData: true,
      };
    case YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_BY_DATA_SUCCESS:
      return {
        ...state,
        loadingByData: false,
        datas: action.data || [],
        // metaData: CommonUtils.pagination(action.data.meta),
      };
    case YobousienPlanActionTypes.GET_YOBOUSIEN_PLAN_BY_DATA_FAILURE:
      return {
        ...state,
        loadingByData: false,
        metaData: {},
      };

    case YobousienPlanActionTypes.RESET_CURRENT_YOBOUSIEN_PLAN:
      return {
        ...state,
        data: {},
        loading: false,
      };

    case YobousienPlanActionTypes.GET_KIHON_CHECK_YOBOU_REQUEST:
      return {
        ...state,
        kihonLoading: true,
      };
    case YobousienPlanActionTypes.GET_KIHON_CHECK_YOBOU_SUCCESS:
    case YobousienPlanActionTypes.GET_KIHON_CHECK_YOBOU_FAILURE:
      return {
        ...state,
        kihonLoading: false,
      };

    case YobousienPlanActionTypes.GET_FIRST_DATE_REQUEST:
      return {
        ...state,
        loadingNew: true,
      };
    case YobousienPlanActionTypes.GET_FIRST_DATE_SUCCESS:
    case YobousienPlanActionTypes.GET_FIRST_DATE_FAILURE:
      return {
        ...state,
        loadingNew: false,
      };

    //モバイル編集モード
    case YobousienPlanActionTypes.CHANGE_EDIT_MOBILE_MODE:
      return {
        ...state,
        editDetail: action.edit,
      };

    case YobousienPlanActionTypes.NEW_PAGE:
      return {
        ...state,
        data: {},
      }

    default: {
      return { ...state };
    }
  }
}

export default yobousienPlan;
