export const FacesheetActionTypes = {
  GET_ALL_FACESHEET_REQUEST: "GET_ALL_FACESHEET_REQUEST",
  GET_ALL_FACESHEET_SUCCESS: "GET_ALL_FACESHEET_SUCCESS",
  GET_ALL_FACESHEET_FAILURE: "GET_ALL_FACESHEET_FAILURE",

  GET_FACESHEET_REQUEST: "GET_FACESHEET_REQUEST",
  GET_FACESHEET_SUCCESS: "GET_FACESHEET_SUCCESS",
  GET_FACESHEET_FAILURE: "GET_FACESHEET_FAILURE",

  CREATE_FACESHEET_REQUEST: "CREATE_FACESHEET_REQUEST",
  CREATE_FACESHEET_SUCCESS: "CREATE_FACESHEET_SUCCESS",
  CREATE_FACESHEET_FAILURE: "CREATE_FACESHEET_FAILURE",

  UPDATE_FACESHEET_REQUEST: "UPDATE_FACESHEET_REQUEST",
  UPDATE_FACESHEET_SUCCESS: "UPDATE_FACESHEET_SUCCESS",
  UPDATE_FACESHEET_FAILURE: "UPDATE_FACESHEET_FAILURE",

  REMOVE_FACESHEET_REQUEST: "REMOVE_FACESHEET_REQUEST",
  REMOVE_FACESHEET_SUCCESS: "REMOVE_FACESHEET_SUCCESS",
  REMOVE_FACESHEET_FAILURE: "REMOVE_FACESHEET_FAILURE",

  GET_FACESHEET_BY_DATA_REQUEST: "GET_FACESHEET_BY_DATA_REQUEST",
  GET_FACESHEET_BY_DATA_SUCCESS: "GET_FACESHEET_BY_DATA_SUCCESS",
  GET_FACESHEET_BY_DATA_FAILURE: "GET_FACESHEET_BY_DATA_FAILURE",

  NEW_PAGE: "NEW_PAGE",
};
