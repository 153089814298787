import React from "react";
import { Modal, Table, Spin } from "antd";
import { connect } from "react-redux";

class LastQuoteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.tempSelected,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ selected: nextProps.tempSelected });
  }

  handleOk() {
    this.props.handleSelectLastQuote(this.state.selected);
  }

  handleCancel() {
    this.props.hideModalLastQuote();
    this.setState({ selected: null });
  }

  handleSelectLastQuote(record) {
    this.setState({ selected: record });
  }

  getDataSource(templates) {
    if (!templates) return [];

    const dataSource = [];

    for (const key in templates) {
      dataSource.push({
        id: key,
        tempName: templates[key],
      });
    }
    return dataSource;
  }

  render() {
    const columns = [
      {
        dataIndex: "tempName",
        key: "tempName",
        render: (text) => (
          <div
            style={{
              maxWidth: "314px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {text}
          </div>
        ),
      },
    ];

    const dataSource = this.getDataSource(this.props.sisetuDailyPlanTemplates);

    return (
      (<Modal
        open={this.props.visible}
        title={this.props.title}
        onOk={() => this.handleOk()}
        onCancel={() => this.handleCancel()}
        width={500}
      >
        <Spin spinning={this.props.loadingTemp}>
          <Table
            id={"last-quote-table"}
            bordered={true}
            size={"small"}
            columns={columns}
            pagination={{ pageSize: 10 }}
            rowKey={"id"}
            showHeader={false}
            dataSource={dataSource}
            onRow={(record) => {
              return {
                onClick: () => this.handleSelectLastQuote(record),
              };
            }}
            rowClassName={(record) =>
              this.state.selected != null &&
              record.id === this.state.selected.id
                ? "pljp-table-row-hover"
                : "pointer-row"
            }
          />
        </Spin>
      </Modal>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kyotakuPlan1s: state.kyotakuPlan1.kyotakuPlan1s
      ? state.kyotakuPlan1.kyotakuPlan1s
      : [],
    kyotakuPlan2s: state.kyotakuPlan2.kyotakuPlan2s
      ? state.kyotakuPlan2.kyotakuPlan2s
      : [],
    sisetuDailyPlans: state.sisetuDailyPlan.sisetuDailyPlans
      ? state.sisetuDailyPlan.sisetuDailyPlans
      : [],
    loadingTemp: state.sisetuDailyPlan.loadingTemp,
  };
};

export default connect(mapStateToProps)(LastQuoteModal);
