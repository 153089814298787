export const ListNameSet = {
  // setting_master

  // yougo_master
  SYOKUJI_KUBUN: "食事せん区分",
  SYOKUJI_TOKKI: "食事せん備考",
  SHOKUJI_COMMENT: "食事履歴備考",
  SHOKUJI_JOKYO: "食事開始状況",

  // riyou update page
};
