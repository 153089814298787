import {
  convert_wareki_3, 
  convert_wareki 
} from "../../../function_common/functionCommon";
import _ from "lodash";

export const plan2_InputFormPDF = (data) => {

  const getValueFromKey = (st, key) => {
    if (!st) return; 
    const keyValuePairs = st.split(',');
    for (let i = 0; i < keyValuePairs.length; i++) {
      const pair = keyValuePairs[i].split(':');
      const pairKey = pair[0].trim();
      const pairValue = pair[1].trim();
      if (pairKey === key) {
        if (pairValue === 'true') {
          return true;
        } else if (pairValue === 'false') {
          return false;
        } else {
          return pairValue;
        }
      }
    }
    return null;
  }

  const convert_douiDate = (dateString, douibi) => {
    if (dateString && douibi) {
      const str = dateString.split("-");
  
      const y = str[0];
      const m = str[1];
      const d = str[2];
  
      const tmp1 = convert_wareki(parseInt(y), parseInt(m), parseInt(d));
  
      const tmp = { jt: tmp1.t, jn: tmp1.n, y, m, d, dis: "" };
  
      if (!dateString || y !== "0001") {
        const dis = `${tmp.jt}　 ${parseInt(tmp.jn)}年　${parseInt(tmp.m)}月　${parseInt(
          tmp.d
        )}日`;
        tmp.dis = dis;
      }
  
      return tmp;
    }
  
    return { dis: "" };
  }

  const convert_date = (dateString) => {
    if (dateString) {
      const str = dateString.split("-");
  
      const y = str[0];
      const m = str[1];
      const d = str[2];
  
      const tmp1 = convert_wareki(parseInt(y), parseInt(m), parseInt(d));
  
      const tmp = { jt: tmp1.t, jn: tmp1.n, y, m, d, dis: "" };
  
      if (!dateString || y !== "0001") {
        const dis = `${tmp.jt}　 ${parseInt(tmp.jn)}年　${parseInt(tmp.m)}月　${parseInt(
          tmp.d
        )}日`;
        tmp.dis = dis;
      }
  
      return tmp;
    }
  
    return { dis: "" };
  }

  const formatDate = (isSeireki, date) => {
    if (isSeireki) {
      // const result = formatSeireki(date);
      const result = "　　　　年　　 月　　　日"
      return result;
    } else {
      const result = convert_date(date).dis;
      return result;
    }
  }

  const setSeikatuMokuhyou = (datas, key, betuwaku) => {
    let result = "";
    if (datas) {
      datas.map((data, index) => {
        if (key === "seikatuKoi") {
          result += `（${index + 1}）${data[key] ?? ""}` + "\n"
        } else {
          result += data[key] ? data[key] + "\n" : ""
        }
        if (key === "longMokuhyou" && data.longKikan && !betuwaku) {
          result += `(${data.longKikan ?? ""})` + "\n"
        }
        if (key === "shortMokuhyou" && data.shortKikan && !betuwaku) {
          result +=  `(${data.shortKikan ?? ""})` + "\n"
        }
      })
    } else {
      return result;
    }

    return result;
  }

  const setDetail = (datas, key) => {
    let result = "";
    if (datas && Array.isArray(datas)) {
        datas.forEach((data, index) => {
            if (data.details && data.details.length > 0) {
                data.details.forEach((detail, detailIndex) => {
                    if (detail[key] !== null && detail[key] !== "") {
                        if (result !== "") {
                            result += "\n";
                        }
                        result += detail[key];
                    }
                });
            }
        });
    }
    return result;
  }

    const { plan2, printOptions, betuwaku } = data;

    if (betuwaku) {
      if (typeof plan2 === 'object' && Array.isArray(plan2)) {
        let sampledata = [];
        plan2?.map((plan2, index) => {
          sampledata.push({
            利用者名: plan2.riyousyaName ?? "",
            敬称: getValueFromKey(printOptions?.naiyou, "敬称") ?? "",
            作成年月日: formatDate(getValueFromKey(printOptions?.naiyou, "作成日"), plan2?.planDate) ?? "",
            タイトル: getValueFromKey(printOptions?.naiyou, "title") ?? "",
            生活全般の解決すべき課題: plan2.seikatuKoi ?? "",
            長期目標: plan2.longMokuhyou ?? "",
            長期期間: plan2.longKikan ?? "",
            短期目標: plan2.shortMokuhyou ?? "",
            短期期間: plan2.shortKikan ?? "",
            サービス内容: plan2.sienNaiyou ?? "",
            "※1": plan2.siensyaBunrui ?? "",
            サービス種別: plan2.service ?? "",
            "※2": plan2.jigyousyo ?? "",
            頻度: plan2.hindo ?? "",
            期間: plan2.kikan ?? "",
            同意年月日: convert_douiDate(plan2?.plan2DouiDate, getValueFromKey(printOptions?.naiyou, "同意日"))?.dis ?? "",
            利用者同意欄: "",
            ページ数: String(index + 1),
          })
        })

        return sampledata;
      } else if (typeof plan2 === 'object') {
        let sampledata = [
          {
            利用者名: plan2.riyousyaName ?? "",
            敬称: getValueFromKey(printOptions?.naiyou, "敬称") ?? "",
            作成年月日: formatDate(getValueFromKey(printOptions?.naiyou, "作成日"), plan2?.planDate) ?? "",
            タイトル: getValueFromKey(printOptions?.naiyou, "title") ?? "",
            生活全般の解決すべき課題: setSeikatuMokuhyou(plan2.seikatuMokuhyous, "seikatuKoi") ?? "",
            長期目標: setSeikatuMokuhyou(plan2.seikatuMokuhyous, "longMokuhyou", betuwaku) ?? "",
            長期期間: setSeikatuMokuhyou(plan2.seikatuMokuhyous, "longKikan") ?? "",
            短期目標: setSeikatuMokuhyou(plan2.seikatuMokuhyous, "shortMokuhyou", betuwaku) ?? "",
            短期期間: setSeikatuMokuhyou(plan2.seikatuMokuhyous, "shortKikan") ?? "",
            サービス内容: setDetail(plan2.seikatuMokuhyous, "sienNaiyou"),
            "※1": setDetail(plan2.seikatuMokuhyous, "siensyaBunrui"),
            サービス種別: setDetail(plan2.seikatuMokuhyous, "service"),
            "※2": setDetail(plan2.seikatuMokuhyous, "jigyousyo"),
            頻度: setDetail(plan2.seikatuMokuhyous, "hindo"),
            期間: setDetail(plan2.seikatuMokuhyous, "kikan"),
            同意年月日: convert_douiDate(plan2?.plan2DouiDate, getValueFromKey(printOptions?.naiyou, "同意日"))?.dis ?? "",
            利用者同意欄: "",
            ページ数: "1",
          },
        ];
        return sampledata;
      } else {
        return [{}];
      }
    } else {
      if (typeof plan2 === 'object' && Array.isArray(plan2)) {
        let sampledata = [];
        plan2?.map((plan2, index) => {
          sampledata.push({
            利用者名: plan2.riyousyaName ?? "",
            敬称: getValueFromKey(printOptions?.naiyou, "敬称") ?? "",
            作成年月日: formatDate(getValueFromKey(printOptions?.naiyou, "作成日"), plan2?.planDate) ?? "",
            タイトル: getValueFromKey(printOptions?.naiyou, "title") ?? "",
            生活全般の解決すべき課題: plan2.seikatuKoi ?? "",
            長期目標: plan2.longMokuhyou ?? "",
            短期目標: plan2.shortMokuhyou ?? "",
            サービス内容: plan2.sienNaiyou ?? "",
            "※1": plan2.siensyaBunrui ?? "",
            サービス種別: plan2.service ?? "",
            "※2": plan2.jigyousyo ?? "",
            頻度: plan2.hindo ?? "",
            期間: plan2.kikan ?? "",
            同意年月日: convert_douiDate(plan2?.plan2DouiDate, getValueFromKey(printOptions?.naiyou, "同意日"))?.dis ?? "",
            利用者同意欄: "",
            ページ数: String(index + 1),
          })
        })
  
        return sampledata;
      } else if (typeof plan2 === 'object') {
        let sampledata = [
          {
            利用者名: plan2.riyousyaName ?? "",
            敬称: getValueFromKey(printOptions?.naiyou, "敬称") ?? "",
            作成年月日: formatDate(getValueFromKey(printOptions?.naiyou, "作成日"), plan2?.planDate) ?? "",
            タイトル: getValueFromKey(printOptions?.naiyou, "title") ?? "",
            生活全般の解決すべき課題: setSeikatuMokuhyou(plan2.seikatuMokuhyous, "seikatuKoi") ?? "",
            長期目標: setSeikatuMokuhyou(plan2.seikatuMokuhyous, "longMokuhyou", betuwaku) ?? "",
            短期目標: setSeikatuMokuhyou(plan2.seikatuMokuhyous, "shortMokuhyou", betuwaku) ?? "",
            サービス内容: setDetail(plan2.seikatuMokuhyous, "sienNaiyou"),
            "※1": setDetail(plan2.seikatuMokuhyous, "siensyaBunrui"),
            サービス種別: setDetail(plan2.seikatuMokuhyous, "service"),
            "※2": setDetail(plan2.seikatuMokuhyous, "jigyousyo"),
            頻度: setDetail(plan2.seikatuMokuhyous, "hindo"),
            期間: setDetail(plan2.seikatuMokuhyous, "kikan"),
            同意年月日: convert_douiDate(plan2?.plan2DouiDate, getValueFromKey(printOptions?.naiyou, "同意日"))?.dis ?? "",
            利用者同意欄: "",
            ページ数: "1",
          },
        ];
        return sampledata;
      } else {
        return [{}];
      }
    }

    
};
