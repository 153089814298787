import { ApiPaths } from "../../constants/api_paths";
import { WeeklyPlanActionTypes, ErrorActionTypes } from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils } from "../../utils/index";

function getAll(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: WeeklyPlanActionTypes.GET_ALL_WEEKLYPLAN_REQUEST });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.WeeklyPlan.getListWeeklyPlan,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_WEEKLYPLAN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: WeeklyPlanActionTypes.GET_ALL_WEEKLYPLAN_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: WeeklyPlanActionTypes.REMOVE_WEEKLYPLAN_REQUEST });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.WeeklyPlan.delete,
        query,
      });
      dispatch({ type: WeeklyPlanActionTypes.REMOVE_WEEKLYPLAN_SUCCESS, data });
    } catch (error) {
      dispatch({ type: WeeklyPlanActionTypes.REMOVE_WEEKLYPLAN_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListDetailById(id) {
  // const menu = StorageUtils.getValue("menuMNGT");
  // const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_DETAIL_REQUEST,
      });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.WeeklyPlan.GetDetailById,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_DETAIL_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_DETAIL_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getWeeklyPlanById(id) {
  const menu = StorageUtils.getValue("menuMNGT").sn;
  return async (dispatch) => {
    try {
      dispatch({ type: WeeklyPlanActionTypes.GET_WEEKLY_PLAN_BY_ID_REQUEST });
      const query = { id, menu };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.WeeklyPlan.KyotakuWeeklyPlan,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_WEEKLY_PLAN_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_WEEKLY_PLAN_BY_ID_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getTemplate(id) {
  // const menu = StorageUtils.getValue("menuMNGT");
  // const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_TEMPLATE_REQUEST,
      });
      const query = { id };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.WeeklyPlan.GetTemplate,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_TEMPLATE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_WEEKLY_PLAN_TEMPLATE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListQuote(riyousyaId, id) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_REQUEST,
      });
      const query = { riyousyaId, sid, id };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.WeeklyPlan.KyotakuWeeklyPlanListQuote,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function updateWeeklyPlan(body) {
  // const menu = StorageUtils.getValue("menuMNGT");
  // const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.WeeklyPlan.UpdateWeeklyPlan,
        body,
      });
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceColorMaster(menu) {
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_COLOR_MASTER_REQUEST,
      });
      const query = { menu };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.GetServiceColorMaster,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_COLOR_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_COLOR_MASTER_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function updateWeeklyPlanTemplate(body) {
  // const menu = StorageUtils.getValue("menuMNGT");
  // const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_TEMPLATE_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.WeeklyPlan.UpdateWeeklyPlanTemplate,
        body,
      });
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_TEMPLATE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_TEMPLATE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceContentByType(serviceType) {
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_CONTENT_BY_TYPE_REQUEST,
      });
      const query = { serviceType };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.GetServiceContentByType,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_CONTENT_BY_TYPE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_CONTENT_BY_TYPE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListServiceAddition(serviceType, menu) {
  return async (dispatch) => {
    try {
      dispatch({ type: WeeklyPlanActionTypes.GET_SERVICE_ADDITION_REQUEST });
      const query = {
        serviceType,
        syurui: menu === "yobou" ? "yobou" : "kaigo",
      };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.GetServiceAddition,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_ADDITION_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: WeeklyPlanActionTypes.GET_SERVICE_ADDITION_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceKikanMaster() {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue("menuMNGT");
      const sid = menu.si || null;
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_KIKAN_MASTER_BY_ID_REQUEST,
      });
      const query = { sid };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ApiGeneral.GetServiceKikanMaster,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_KIKAN_MASTER_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_KIKAN_MASTER_BY_ID_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function getListQuoteTab(riyousyaId, id) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_TAB_REQUEST,
      });
      const query = { riyousyaId, sid, id };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.WeeklyPlan.KyotakuWeeklyPlanListQuoteTab,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_TAB_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_TAB_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
// get list ids view
function getListId(riyousyaId) {
  const sort = StorageUtils.getValue("list_sort_riyousya").weeklyPlan || {};
  const menu = StorageUtils.getValue("menuMNGT");
  return async (dispatch) => {
    const body = {
      riyousyaId,
      sid: menu.si,
      sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
    };
    try {
      dispatch({ type: WeeklyPlanActionTypes.GET_IDS_WEEKLY_PLAN });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.getWeeklyPlanListId,
        query: body,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_IDS_SUCCESS,
        idsWeeklyData: data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_IDS_ERROR,
        error,
      });
    }
  };
}

function getRentalByWeeklyPlanId(weeklyPlanId) {
  return async (dispatch) => {
    const body = { weeklyPlanId };
    try {
      dispatch({ type: WeeklyPlanActionTypes.GET_RENTAL });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.getRentalByWeeklyPlanId,
        query: body,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_SUCCESS,
        rentalData: data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_ERROR,
        error,
      });
    }
  };
}
function keepValueBeforeSort(list) {
  return (dispatch) => {
    dispatch({
      type: WeeklyPlanActionTypes.KEEP_VALUE_BEFORE_SORT_WEEKLY_PLAN_SCHEDULE,
      data: list,
    });
  };
}

function clearWeeklyPlan() {
  return (dispatch) => {
    dispatch({
      type: WeeklyPlanActionTypes.CLEAR_WEEKLY_PLAN_SCHEDULE,
    });
  };
}

function updateWeeklyPlanSchedule(body) {
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_SCHEDULE_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.WeeklyPlan.KyotakuWeeklyPlan,
        body,
      });
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_SCHEDULE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_PLAN_SCHEDULE_FAILURE,
        error,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListRentalQuote(riyousyaId, id) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_REQUEST,
      });
      const query = { riyousyaId, sid, id };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.WeeklyPlan.KyotakuWeeklyPlanListRentalQuote,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_WEEKLY_PLAN_QUOTE_RENTAL_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getAllServiceContentRental() {
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_SERVICE_CONTENT_RENTAL_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.ServiceContentRental,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_SERVICE_CONTENT_RENTAL_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_ALL_SERVICE_CONTENT_RENTAL_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListRentalSyohinMaster(serviceCd, name) {
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_RENTAL_SYOHIN_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.RentalSyohinMaster,
        query: { serviceCd, name },
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_RENTAL_SYOHIN_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_LIST_RENTAL_SYOHIN_MASTER_FAILURE,
        error,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceContentRentalBySyuruiCd(syuruiCd) {
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.ServiceContentRentalBySyuruiCd,
        query: { syuruiCd },
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_SERVICE_CONTENT_RENTAL_BY_SYURUI_CD_FAILURE,
        error,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function updateWeeklyPlanRental(body) {
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_RENTAL_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.WeeklyPlan.KyotakuWeeklyRental,
        body,
      });
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_RENTAL_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_WEEKLY_RENTAL_FAILURE,
        error,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getRentalSyohinMaster(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.GetRentalSyohinMaster,
        params: { id },
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function createRentalSyohinMaster(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: WeeklyPlanActionTypes.CREATE_RENTAL_SYOHIN_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.WeeklyPlan.CreateRentalSyohinMaster,
        body,
      });
      dispatch({
        type: WeeklyPlanActionTypes.CREATE_RENTAL_SYOHIN_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.CREATE_RENTAL_SYOHIN_MASTER_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function updateRentalSyohinMaster(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_RENTAL_SYOHIN_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.WeeklyPlan.UpdateRentalSyohinMaster,
        body,
      });
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_RENTAL_SYOHIN_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_RENTAL_SYOHIN_MASTER_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getRentalSyohinMasterInit() {
  return async (dispatch) => {
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_INIT_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.WeeklyPlan.GetRentalSyohinMasterInit,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_INIT_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_INIT_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function turnOffReload() {
  return (dispatch) =>
    dispatch({ type: WeeklyPlanActionTypes.TURN_OFF_RELOAD });
}

function getKyotakuWeeklyById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: WeeklyPlanActionTypes.GET_KYOTAKU_WEEKLY_LIST_REQUEST });
      const query = { id };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.WeeklyPlan.GetListKyotakuWeeklyPlan,
        query,
      });
      dispatch({
        type: WeeklyPlanActionTypes.GET_KYOTAKU_WEEKLY_LIST_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_KYOTAKU_WEEKLY_LIST_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function updateRentalList(data, callback) {
  // 保存してリストを返す
  return async (dispatch) => {
    const body = data;
    try {
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.WeeklyPlan.UpdateRentalList,
        body,
      });
      dispatch({
        type: WeeklyPlanActionTypes.UPDATE_RENTAL_SYOHIN_LIST,
        data,
      });
      return callback(data);
    } catch (error) {
      dispatch({
        type: WeeklyPlanActionTypes.GET_RENTAL_SYOHIN_MASTER_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

export const WeeklyPlanActions = {
  getAll,
  deleteId,
  getListDetailById,
  getWeeklyPlanById,
  getTemplate,
  getListQuote,
  updateWeeklyPlan,
  getServiceColorMaster,
  getServiceContentByType,
  getListServiceAddition,
  updateWeeklyPlanTemplate,
  getServiceKikanMaster,
  getListQuoteTab,
  getRentalByWeeklyPlanId,
  keepValueBeforeSort,
  getListId,
  clearWeeklyPlan,
  updateWeeklyPlanSchedule,
  getListRentalQuote,
  getAllServiceContentRental,
  getListRentalSyohinMaster,
  getServiceContentRentalBySyuruiCd,
  updateWeeklyPlanRental,
  getRentalSyohinMaster,
  createRentalSyohinMaster,
  updateRentalSyohinMaster,
  getRentalSyohinMasterInit,
  turnOffReload,
  getKyotakuWeeklyById,
  updateRentalList,
};

// test deploy
// test deploy
// test deploy
// test deploy
// test deploy
// test deploy
// test deploy
// test deploy
