import {
  ACTION_USER_DEFAULT,
  REPORT_USER_UPDATE_REQUEST,
  REPORT_USER_UPDATE_SUCCESS,
  REPORT_USER_UPDATE_ERROR,
  REPORT_USER_INSERT_REQUEST,
  REPORT_USER_INSERT_SUCCESS,
  REPORT_USER_INSERT_ERROR,
  REPORT_USER_DELETE_REQUEST,
  REPORT_USER_DELETE_SUCCESS,
  REPORT_USER_DELETE_ERROR,
  REPORT_USER_UPDATE_MUL_REQUEST,
  REPORT_USER_UPDATE_MUL_SUCCESS,
  REPORT_USER_INSERT_MUL_REQUEST,
  REPORT_USER_INSERT_MUL_SUCCESS,
  REPORT_USER_SHUUKEI_REQUEST,
  REPORT_USER_SHUUKEI_SUCCESS,
  // REPORT_USER_SHUUKEI_ERROR,
  REPORT_USER_VITAL_REQUEST,
  REPORT_USER_VITAL_SUCCESS,
  REPORT_USER_VITAL_ERROR,
  REPORT_USER_MEAL_ERROR,
  REPORT_USER_MEAL_SUCCESS,
  REPORT_USER_MEAL_REQUEST,
  REPORT_USER_SUIBUN_REQUEST,
  REPORT_USER_SUIBUN_SUCCESS,
  REPORT_USER_SUIBUN_ERROR,
  REPORT_USER_HAISETU_REQUEST,
  REPORT_USER_HAISETU_SUCCESS,
  REPORT_USER_HAISETU_ERROR,
  REPORT_USER_NYUYOKU_REQUEST,
  REPORT_USER_NYUYOKU_SUCCESS,
  REPORT_USER_NYUYOKU_ERROR,
  REPORT_USER_FUKUYAKU_REQUEST,
  REPORT_USER_FUKUYAKU_SUCCESS,
  REPORT_USER_FUKUYAKU_ERROR,
  REPORT_USER_KAIGO_REQUEST,
  REPORT_USER_KAIGO_SUCCESS,
  REPORT_USER_KAIGO_ERROR,
  REPORT_USER_TOKKI_REQUEST,
  REPORT_USER_TOKKI_SUCCESS,
  REPORT_USER_TOKKI_ERROR,
  REPORT_USER_GAZOU_REQUEST,
  REPORT_USER_GAZOU_SUCCESS,
  REPORT_USER_GAZOU_ERROR,
  REPORT_USER_VITAL_COPY,
  REPORT_USER_VITAL_DEL,
  REPORT_USER_MEAL_COPY,
  REPORT_USER_MEAL_DEL,
  REPORT_USER_SUIBUN_COPY,
  REPORT_USER_SUIBUN_DEL,
  REPORT_USER_HAISETU_COPY,
  REPORT_USER_HAISETU_DEL,
  REPORT_USER_NYUYOKU_COPY,
  REPORT_USER_NYUYOKU_DEL,
  REPORT_USER_FUKUYAKU_COPY,
  REPORT_USER_FUKUYAKU_DEL,
  REPORT_USER_KAIGO_COPY,
  REPORT_USER_KAIGO_DEL,
  REPORT_USER_TOKKI_COPY,
  REPORT_USER_TOKKI_DEL,
  REPORT_USER_GAZOU_COPY,
  REPORT_USER_GAZOU_DEL,
  REPORT_USER_FILTER_REQUEST,
  REPORT_USER_FILTER_SUCCESS,
  REPORT_USER_FILTER_ERROR,
  REPORT_USER_LOAD_ZENKAI_REQUEST,
  REPORT_USER_LOAD_ZENKAI_ERROR,
  REPORT_USER_LOAD_ZENKAI_SUCCESS,
} from "./actionName.js";

import { ConstSet } from "../../common/configs/constset";

import {
  LoadDataApi,
  VitalUpdate,
  VitalInsert,
  VitalDelete,
  MealUpdate,
  MealInsert,
  MealDelete,
  SuibunUpdate,
  SuibunInsert,
  SuibunDelete,
  HaisetuUpdate,
  HaisetuInsert,
  HaisetuDelete,
  NyuyokuUpdate,
  NyuyokuInsert,
  NyuyokuDelete,
  FukuyakuUpdate,
  FukuyakuInsert,
  FukuyakuDelete,
  KaigonaiyoUpdate,
  KaigonaiyoInsert,
  KaigonaiyoDelete,
  TokkiUpdate,
  TokkiInsert,
  TokkiDelete,
  GazouUpdate,
  GazouInsert,
  GazouDelete,
  ZenkaiLoad,
} from "../../api/reportsUser";
import DataFilterApi from "../../api/reportFilter";

import { USER_MULTIPLE_UPDATE_ERROR } from "../commonActions/actionName";

/**
 *
 * @param {*} null
 */
function filterCreator(tabName) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_FILTER_REQUEST,
    });

    DataFilterApi.dataFilterApi(tabName)
      .then((data) =>
        dispatch({
          type: REPORT_USER_FILTER_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: REPORT_USER_FILTER_ERROR,
        error: err,
      }));
  };
}

function loadDataCreator(parameterObj) {
  switch (parameterObj.tabName) {
    case "利用者":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_SHUUKEI_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_USER_SHUUKEI_SUCCESS,
            payload: data,
          })
        );
      };

    case "集計":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_SHUUKEI_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_USER_SHUUKEI_SUCCESS,
            payload: data,
          })
        );
      };

    case "バイタル":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_VITAL_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_USER_VITAL_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_VITAL_ERROR,
            error: err,
          }));
      };

    case "食事摂取":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_MEAL_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_USER_MEAL_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_MEAL_ERROR,
            error: err,
          }));
      };

    case "水分摂取":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_SUIBUN_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_USER_SUIBUN_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_SUIBUN_ERROR,
            error: err,
          }));
      };

    case "排泄":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_HAISETU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_USER_HAISETU_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_HAISETU_ERROR,
            error: err,
          }));
      };
    case "入浴":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_NYUYOKU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_USER_NYUYOKU_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_NYUYOKU_ERROR,
            error: err,
          }));
      };
    case "服薬":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_FUKUYAKU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_USER_FUKUYAKU_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_FUKUYAKU_ERROR,
            error: err,
          }));
      };
    case "提供記録":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_KAIGO_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_USER_KAIGO_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_KAIGO_ERROR,
            error: err,
          }));
      };
    case "特記":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_TOKKI_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_USER_TOKKI_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_TOKKI_ERROR,
            error: err,
          }));
      };
    case "画像等":
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_GAZOU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_USER_GAZOU_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_GAZOU_ERROR,
            error: err,
          }));
      };
    /**
     * @TODO handle exception tabName null
     */
    default:
      return (dispatch) => {
        dispatch({
          type: ACTION_USER_DEFAULT,
        });
      };
  }
}

/**
 *
 * @param {*} baitaruRecord
 */
function baitaruCopyCreator(baitaruRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_VITAL_COPY,
      baitaruRecord,
    });
  };
}

/**
 *
 * @param {*} baitaruRecord
 */
function baitaruDelCreator(baitaruRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_VITAL_DEL,
      baitaruRecord,
    });
  };
}

/**
 *
 * @param {*} mealRecord
 */
function mealCopyCreator(mealRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_MEAL_COPY,
      mealRecord,
    });
  };
}

/**
 *
 * @param {*} mealRecord
 */
function mealDelCreator(mealRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_MEAL_DEL,
      mealRecord,
    });
  };
}

/**
 *
 * @param {*} suibunRecord
 */
function suibunCopyCreator(suibunRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_SUIBUN_COPY,
      suibunRecord,
    });
  };
}

/**
 *
 * @param {*} suibunRecord
 */
function suibunDelCreator(suibunRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_SUIBUN_DEL,
      suibunRecord,
    });
  };
}

/**
 *
 * @param {*} haisetuRecord
 */
function haisetuCopyCreator(haisetuRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_HAISETU_COPY,
      haisetuRecord,
    });
  };
}

/**
 *
 * @param {*} haisetuRecord
 */
function haisetuDelCreator(haisetuRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_HAISETU_DEL,
      haisetuRecord,
    });
  };
}

/**
 *
 * @param {*} nyuyokuRecord
 */
function nyuyokuCopyCreator(nyuyokuRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_NYUYOKU_COPY,
      nyuyokuRecord,
    });
  };
}

/**
 *
 * @param {*} nyuyokuRecord
 */
function nyuyokuDelCreator(nyuyokuRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_NYUYOKU_DEL,
      nyuyokuRecord,
    });
  };
}

/**
 *
 * @param {*} fukuyakuRecord
 */
function fukuyakuCopyCreator(fukuyakuRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_FUKUYAKU_COPY,
      fukuyakuRecord,
    });
  };
}

/**
 *
 * @param {*} fukuyakuRecord
 */
function fukuyakuDelCreator(fukuyakuRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_FUKUYAKU_DEL,
      fukuyakuRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function kaigonaiyouCopyCreator(kaigonaiyouRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_KAIGO_COPY,
      kaigonaiyouRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function kaigonaiyouDelCreator(kaigonaiyouRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_KAIGO_DEL,
      kaigonaiyouRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function tokkiCopyCreator(tokkiRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_TOKKI_COPY,
      tokkiRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function tokkiDelCreator(tokkiRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_TOKKI_DEL,
      tokkiRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function gazoCopyCreator(gazoRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_GAZOU_COPY,
      gazoRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function gazoDelCreator(gazoRecord) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_GAZOU_DEL,
      gazoRecord,
    });
  };
}

function updateKirokuCreator(params) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_REQUEST,
        });

        VitalUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? REPORT_USER_UPDATE_ERROR
                : REPORT_USER_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_REQUEST,
        });

        MealUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? REPORT_USER_UPDATE_ERROR
                : REPORT_USER_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_REQUEST,
        });

        SuibunUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? REPORT_USER_UPDATE_ERROR
                : REPORT_USER_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_REQUEST,
        });

        HaisetuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? REPORT_USER_UPDATE_ERROR
                : REPORT_USER_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_REQUEST,
        });

        NyuyokuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? REPORT_USER_UPDATE_ERROR
                : REPORT_USER_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_REQUEST,
        });

        FukuyakuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? REPORT_USER_UPDATE_ERROR
                : REPORT_USER_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_REQUEST,
        });

        KaigonaiyoUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? REPORT_USER_UPDATE_ERROR
                : REPORT_USER_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_REQUEST,
        });

        TokkiUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? REPORT_USER_UPDATE_ERROR
                : REPORT_USER_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

function insertKirokuCreator(params) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_REQUEST,
        });

        VitalInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_INSERT_ERROR
                  : REPORT_USER_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: REPORT_USER_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_REQUEST,
        });

        MealInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_INSERT_ERROR
                  : REPORT_USER_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: REPORT_USER_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_REQUEST,
        });

        SuibunInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_INSERT_ERROR
                  : REPORT_USER_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: REPORT_USER_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_REQUEST,
        });

        HaisetuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_INSERT_ERROR
                  : REPORT_USER_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: REPORT_USER_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_REQUEST,
        });

        NyuyokuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_INSERT_ERROR
                  : REPORT_USER_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: REPORT_USER_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_REQUEST,
        });

        FukuyakuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_INSERT_ERROR
                  : REPORT_USER_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: REPORT_USER_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_REQUEST,
        });

        KaigonaiyoInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_INSERT_ERROR
                  : REPORT_USER_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: REPORT_USER_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_REQUEST,
        });

        TokkiInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_INSERT_ERROR
                  : REPORT_USER_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: REPORT_USER_INSERT_ERROR,
            error: err,
          }));
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

/**
 *
 * update and insert multiple row
 *
 */

function updateKirokuMulCreator(params) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_MUL_REQUEST,
        });

        VitalUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? USER_MULTIPLE_UPDATE_ERROR
                : REPORT_USER_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_MUL_REQUEST,
        });

        MealUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? USER_MULTIPLE_UPDATE_ERROR
                : REPORT_USER_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_MUL_REQUEST,
        });

        SuibunUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? USER_MULTIPLE_UPDATE_ERROR
                : REPORT_USER_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_MUL_REQUEST,
        });

        HaisetuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? USER_MULTIPLE_UPDATE_ERROR
                : REPORT_USER_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_MUL_REQUEST,
        });

        NyuyokuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? USER_MULTIPLE_UPDATE_ERROR
                : REPORT_USER_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_MUL_REQUEST,
        });

        FukuyakuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? USER_MULTIPLE_UPDATE_ERROR
                : REPORT_USER_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_MUL_REQUEST,
        });

        KaigonaiyoUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? USER_MULTIPLE_UPDATE_ERROR
                : REPORT_USER_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_MUL_REQUEST,
        });

        TokkiUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? USER_MULTIPLE_UPDATE_ERROR
                : REPORT_USER_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_FILE:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_UPDATE_MUL_REQUEST,
        });

        GazouUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? USER_MULTIPLE_UPDATE_ERROR
                : REPORT_USER_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

function insertKirokuMulCreator(params) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_MUL_REQUEST,
        });

        VitalInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? USER_MULTIPLE_UPDATE_ERROR
                  : REPORT_USER_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: USER_MULTIPLE_UPDATE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_MUL_REQUEST,
        });

        MealInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? USER_MULTIPLE_UPDATE_ERROR
                  : REPORT_USER_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: USER_MULTIPLE_UPDATE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_MUL_REQUEST,
        });

        SuibunInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? USER_MULTIPLE_UPDATE_ERROR
                  : REPORT_USER_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: USER_MULTIPLE_UPDATE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_MUL_REQUEST,
        });

        HaisetuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? USER_MULTIPLE_UPDATE_ERROR
                  : REPORT_USER_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: USER_MULTIPLE_UPDATE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_MUL_REQUEST,
        });

        NyuyokuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? USER_MULTIPLE_UPDATE_ERROR
                  : REPORT_USER_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: USER_MULTIPLE_UPDATE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_MUL_REQUEST,
        });

        FukuyakuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? USER_MULTIPLE_UPDATE_ERROR
                  : REPORT_USER_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: USER_MULTIPLE_UPDATE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_MUL_REQUEST,
        });

        KaigonaiyoInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? USER_MULTIPLE_UPDATE_ERROR
                  : REPORT_USER_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: USER_MULTIPLE_UPDATE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_MUL_REQUEST,
        });

        TokkiInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? USER_MULTIPLE_UPDATE_ERROR
                  : REPORT_USER_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: USER_MULTIPLE_UPDATE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FILE:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_INSERT_MUL_REQUEST,
        });

        GazouInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? USER_MULTIPLE_UPDATE_ERROR
                  : REPORT_USER_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: USER_MULTIPLE_UPDATE_ERROR,
            error: err,
          }));
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

// END update and insert multiple row

function deleteKirokuCreator(recordId) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_DELETE_REQUEST,
        });

        VitalDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_DELETE_ERROR
                  : REPORT_USER_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_DELETE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_DELETE_REQUEST,
        });

        MealDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_DELETE_ERROR
                  : REPORT_USER_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_DELETE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_DELETE_REQUEST,
        });

        SuibunDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_DELETE_ERROR
                  : REPORT_USER_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_DELETE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_DELETE_REQUEST,
        });

        HaisetuDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_DELETE_ERROR
                  : REPORT_USER_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_DELETE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_DELETE_REQUEST,
        });

        NyuyokuDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_DELETE_ERROR
                  : REPORT_USER_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_DELETE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_DELETE_REQUEST,
        });

        FukuyakuDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_DELETE_ERROR
                  : REPORT_USER_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_DELETE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_DELETE_REQUEST,
        });

        KaigonaiyoDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_DELETE_ERROR
                  : REPORT_USER_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_DELETE_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: REPORT_USER_DELETE_REQUEST,
        });

        TokkiDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? REPORT_USER_DELETE_ERROR
                  : REPORT_USER_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: REPORT_USER_DELETE_ERROR,
            error: err,
          }));
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

function updateKirokuGazoCreator(params, callback) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_UPDATE_REQUEST,
    });

    GazouUpdate.update(params).then((data) => {
      dispatch({
        type:
          data.status && data.status !== 200
            ? REPORT_USER_UPDATE_ERROR
            : REPORT_USER_UPDATE_SUCCESS,
        payload: data,
        paramsUpdate: params, // update at local
      });

      return callback(data);
    });
  };
}

function insertKirokuGazoCreator(params, callback) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_INSERT_REQUEST,
    });

    GazouInsert.insert(params)
      .then((data) => {
        dispatch({
          type:
            data.status && data.status !== 200
              ? REPORT_USER_INSERT_ERROR
              : REPORT_USER_INSERT_SUCCESS,
          payload: data,
          paramsUpdate: params, // update at local
        });

        return callback(data);
      })
      .catch((err) => ({
        type: REPORT_USER_INSERT_ERROR,
        error: err,
      }));
  };
}

function deleteKirokuGazoCreator(recordId) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_DELETE_REQUEST,
    });

    GazouDelete.delete(recordId)
      .then((data) =>
        dispatch({
          type:
            data.status && data.status !== 200
              ? REPORT_USER_DELETE_ERROR
              : REPORT_USER_DELETE_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: REPORT_USER_DELETE_ERROR,
        error: err,
      }));
  };
}

function loadZenkaiCreator(date, riyousyaId, time) {
  return (dispatch) => {
    dispatch({
      type: REPORT_USER_LOAD_ZENKAI_REQUEST,
    });

    ZenkaiLoad.load(date, riyousyaId, time)
      .then((data) =>
        dispatch({
          type:
            data.status && data.status !== 200
              ? REPORT_USER_LOAD_ZENKAI_ERROR
              : REPORT_USER_LOAD_ZENKAI_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: REPORT_USER_LOAD_ZENKAI_ERROR,
        error: err,
      }));
  };
}

export {
  loadDataCreator,
  filterCreator,
  baitaruCopyCreator,
  baitaruDelCreator,
  mealCopyCreator,
  mealDelCreator,
  suibunCopyCreator,
  suibunDelCreator,
  haisetuCopyCreator,
  haisetuDelCreator,
  nyuyokuCopyCreator,
  nyuyokuDelCreator,
  fukuyakuCopyCreator,
  fukuyakuDelCreator,
  kaigonaiyouCopyCreator,
  kaigonaiyouDelCreator,
  tokkiCopyCreator,
  tokkiDelCreator,
  gazoCopyCreator,
  gazoDelCreator,
  updateKirokuCreator,
  insertKirokuCreator,
  deleteKirokuCreator,
  insertKirokuGazoCreator,
  updateKirokuGazoCreator,
  deleteKirokuGazoCreator,
  loadZenkaiCreator,
  updateKirokuMulCreator,
  insertKirokuMulCreator,
};
