import { Button } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class TableRowButtonIO extends React.Component {
  render() {
    const {
      isMealStatus,
      showDeleteConfirm,
      showAddUpdateConfirm,
      showSleepOutsideConfirm,
      riyousyaId,
      selectedDate,
    } = this.props;
    return (
      <React.Fragment>
        {isMealStatus ? (
          <span className={"table-row-bt"}>
            <span
              style={{ marginLeft: "251px" }}
              className={"btn-group-second"}
            >
              <Button
                type="primary"
                onClick={localStorage.setItem("ios_date", selectedDate)}
              >
                <Link to={"/riyousya-detail/" + riyousyaId}>利用者詳細</Link>
              </Button>
              <Button
                type="primary"
                onClick={localStorage.setItem("ios_date", selectedDate)}
              >
                <Link to={"/riyousya-shokuji/" + riyousyaId}>食事せん</Link>
              </Button>
            </span>
          </span>
        ) : (
          <span className={"table-row-bt"}>
            <span className={"btn-group-first"}>
              <Button
                className={"btn-remove"}
                type="primary"
                onClick={() => showDeleteConfirm()}
              >
                削除
              </Button>
              <Button
                type="primary"
                onClick={(data) => showAddUpdateConfirm(data)}
              >
                入退所修正
              </Button>
              <Button
                type="primary"
                onClick={(id) => showSleepOutsideConfirm(id)}
              >
                外泊セット
              </Button>
            </span>

            <span className={"btn-group-second"}>
              <Button
                type="primary"
                onClick={localStorage.setItem("ios_date", selectedDate)}
              >
                <Link to={"/riyousya-detail/" + riyousyaId}>利用者詳細</Link>
              </Button>
              <Button
                type="primary"
                onClick={localStorage.setItem("ios_date", selectedDate)}
              >
                <Link to={"/riyousya-shokuji/" + riyousyaId}>食事せん</Link>
              </Button>
            </span>
          </span>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMealStatus: state.ioStatusReducers.isMealStatus,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableRowButtonIO);
