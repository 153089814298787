import {
  SOCKET_CONNECTION_INIT,
  SOCKET_SEND,
  SOCKET_DISCONNECT,
  LOGOUT,
} from "./actionName";

import LogoutApi from "../../api/logout.js";

/**
 * SOCKET 設定
 */
function initializeSocket() {
  return {
    type: SOCKET_CONNECTION_INIT,
  };
}

function socketSendMessage(data) {
  return {
    type: SOCKET_SEND,
    payload: data,
  };
}

function removeSocket() {
  return {
    type: SOCKET_DISCONNECT,
  };
}

/*
 * @param: username: string
 * @param: password: string
 */
function logoutCreators(isLoginPage) {
  return (dispatch) => {
    LogoutApi.logout(isLoginPage).then((data) =>
      dispatch({
        type: LOGOUT,
        logout: data,
      })
    );
  };
}

export { initializeSocket, socketSendMessage, removeSocket, logoutCreators };
