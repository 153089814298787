import React from "react";
import { Radio } from "antd";

const RadioGroup = Radio.Group;

export default class SelectKB extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    this.props.handleSelectKB(e.target.value);
  };

  render() {
    return (
      <div style={{ textAlign: "center" }} className="modal-check">
        <RadioGroup onChange={this.onChange} value={this.props.defaltValue}>
          <Radio value={"整"}>整</Radio>
          <Radio value={"不整"}>不整</Radio>
          <Radio value={"無し"}>無し</Radio>
        </RadioGroup>
      </div>
    );
  }
}
