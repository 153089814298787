import React, { useState } from "react";
import { Modal, Table, Button } from "antd";
import PropTypes from "prop-types";

LastQuoteModal.propTypes = {
  selected: PropTypes.object,
};

export function LastQuoteModal({
  SyoukiaDate,
  handleSelectLastQuote,
  hideModalLastQuote,
  visible,
  title,
}) {
  const [selected, setSelected] = useState({});

  const handleOk = () => {
    handleSelectLastQuote(selected);
  };

  const handleCancel = () => {
    hideModalLastQuote();
  };

  const handleSelectLastquote = (record) => {
    setSelected(record);
  };

  const columns = [
    {
      title: "照会日",
      dataIndex: "syoukaiDate",
      key: "syoukaiDate",
    },
  ];

  return (
    (<Modal
      open={visible}
      title={title}
      onCancel={() => handleCancel()}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={() => handleCancel()}
        >
          キャンセル
        </Button>,
        <Button
          key="back"
          type="primary"
          onClick={() => handleOk()}
          disabled={SyoukiaDate && SyoukiaDate.length === 0}
        >
          Ok
        </Button>,
      ]}
      width={500}
    >
      <Table
        id={"last-quote-table"}
        bordered={true}
        size={"small"}
        columns={columns}
        pagination={{ pageSize: 10 }}
        rowKey={"id"}
        dataSource={SyoukiaDate}
        onRow={(record) => {
          return {
            onClick: () => handleSelectLastquote(record),
          };
        }}
        rowClassName={(record) =>
          selected != null && record.id === selected.id
            ? "pljp-table-row-hover"
            : "pointer-row"
        }
      />
    </Modal>)
  );
}
