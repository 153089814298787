import { OVERVIEW_ALL_SUMMARY_GET,RESET_STATUS, OVERVIEW_ALL_SUMMARY_UPDATE, OVERVIEW_ALL_SUMMARY_UPDATE_ERROR } from "../../actions/overviewallsumaryActions/actionName";
import { notification } from "antd/lib/index";

export default function OverviewAllSummaryReducers(state = {
  loading: true
}, action) {
  switch (action.type) {
    case OVERVIEW_ALL_SUMMARY_GET:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case RESET_STATUS:
      return {
        ...state,
        notifyContentEdit: {}
      }
    case OVERVIEW_ALL_SUMMARY_UPDATE:
      notification["success"]({
        message: "保存しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        data: action.data,
        notifyContentEdit: { type: "success" },
        loading: false
      };
    case OVERVIEW_ALL_SUMMARY_UPDATE_ERROR:
      notification["error"]({
        message: "エラーが発生しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        state,
        data: action.data,
        loading: false,
        notifyContentEdit: { type: "error" }
      };
    default:
      return state;
  }
}