import { USER_MULTIPLE_UPDATE_ERROR } from "../../actions/commonActions/actionName";
import {
  // ACTION_USER_DEFAULT,

  REPORT_USER_UPDATE_REQUEST,
  REPORT_USER_UPDATE_SUCCESS,
  REPORT_USER_UPDATE_ERROR,
  REPORT_USER_INSERT_REQUEST,
  REPORT_USER_INSERT_SUCCESS,
  REPORT_USER_INSERT_ERROR,
  REPORT_USER_UPDATE_MUL_SUCCESS,
  REPORT_USER_INSERT_MUL_SUCCESS,
  REPORT_USER_DELETE_REQUEST,
  REPORT_USER_DELETE_SUCCESS,
  REPORT_USER_DELETE_ERROR,
  REPORT_USER_SHUUKEI_REQUEST,
  REPORT_USER_SHUUKEI_SUCCESS,
  // REPORT_USER_SHUUKEI_ERROR,
  REPORT_USER_VITAL_REQUEST,
  REPORT_USER_VITAL_SUCCESS,
  // REPORT_USER_VITAL_ERROR,

  // REPORT_USER_MEAL_ERROR,
  REPORT_USER_MEAL_SUCCESS,
  REPORT_USER_MEAL_REQUEST,
  REPORT_USER_SUIBUN_REQUEST,
  REPORT_USER_SUIBUN_SUCCESS,
  // REPORT_USER_SUIBUN_ERROR,
  REPORT_USER_HAISETU_REQUEST,
  REPORT_USER_HAISETU_SUCCESS,
  // REPORT_USER_HAISETU_ERROR,
  REPORT_USER_NYUYOKU_REQUEST,
  REPORT_USER_NYUYOKU_SUCCESS,
  // REPORT_USER_NYUYOKU_ERROR,
  REPORT_USER_FUKUYAKU_REQUEST,
  REPORT_USER_FUKUYAKU_SUCCESS,
  // REPORT_USER_FUKUYAKU_ERROR,
  REPORT_USER_KAIGO_REQUEST,
  REPORT_USER_KAIGO_SUCCESS,
  // REPORT_USER_KAIGO_ERROR,
  REPORT_USER_TOKKI_REQUEST,
  REPORT_USER_TOKKI_SUCCESS,
  // REPORT_USER_TOKKI_ERROR,
  REPORT_USER_GAZOU_REQUEST,
  REPORT_USER_GAZOU_SUCCESS,
  // REPORT_USER_GAZOU_ERROR,
  REPORT_USER_VITAL_COPY,
  REPORT_USER_VITAL_DEL,
  REPORT_USER_MEAL_COPY,
  REPORT_USER_MEAL_DEL,
  REPORT_USER_SUIBUN_COPY,
  REPORT_USER_SUIBUN_DEL,
  REPORT_USER_HAISETU_COPY,
  REPORT_USER_HAISETU_DEL,
  REPORT_USER_NYUYOKU_COPY,
  REPORT_USER_NYUYOKU_DEL,
  REPORT_USER_FUKUYAKU_COPY,
  REPORT_USER_FUKUYAKU_DEL,
  REPORT_USER_KAIGO_COPY,
  REPORT_USER_KAIGO_DEL,
  REPORT_USER_TOKKI_COPY,
  REPORT_USER_TOKKI_DEL,
  REPORT_USER_GAZOU_COPY,
  REPORT_USER_GAZOU_DEL,

  // REPORT_USER_FILTER_REQUEST,
  REPORT_USER_FILTER_SUCCESS,
  // REPORT_USER_FILTER_ERROR,
  REPORT_USER_LOAD_ZENKAI_REQUEST,
  REPORT_USER_LOAD_ZENKAI_ERROR,
  REPORT_USER_LOAD_ZENKAI_SUCCESS,
} from "../../actions/reportUserActions/actionName.js";

// import { isKanji, toKatakana } from 'wanakana'
// import _ from 'lodash'
// import sortAlgorithm from '../../common/function_common/kirokuSortAlg'
import { ConstSet } from "../../common/configs/constset.js";

import { formatRecords } from "../../common/function_common/functionCommon";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  messages: [],
  tabName: "",
};

export default function reportUserReducers(state = INITIAL_STATE, action) {
  let tabName = "";
  switch (action.type) {
    case REPORT_USER_FILTER_SUCCESS: // FILTER LOCAL FUNCTION
      return {
        ...state,
        nyutaishoFilter: action.payload,
      };

    case REPORT_USER_SHUUKEI_REQUEST: // DONE
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_SHUUKEI_SUCCESS:
      return {
        ...state,
        nyutaisho: action.payload,
        loading: false,
      };

    case REPORT_USER_VITAL_REQUEST: // DONE
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_VITAL_SUCCESS:
      return {
        ...state,
        baitaruTab: action.payload,
        loading: false,
      };

    case REPORT_USER_MEAL_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_MEAL_SUCCESS: //
      return {
        ...state,
        shokujiTab: action.payload,
        loading: false,
      };

    // suibun
    case REPORT_USER_SUIBUN_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_SUIBUN_SUCCESS: //
      return {
        ...state,
        suibun: action.payload,
        loading: false,
      };

    // haisetu
    case REPORT_USER_HAISETU_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_HAISETU_SUCCESS: //
      return {
        ...state,
        haisetu: action.payload,
        loading: false,
      };

    //nyuyoku
    case REPORT_USER_NYUYOKU_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_NYUYOKU_SUCCESS: //
      return {
        ...state,
        nyutaishoTab: action.payload,
        loading: false,
      };

    //fukuyaku
    case REPORT_USER_FUKUYAKU_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_FUKUYAKU_SUCCESS: //
      return {
        ...state,
        fukuyaku: action.payload,
        loading: false,
      };

    // kaigonaigo
    case REPORT_USER_KAIGO_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_KAIGO_SUCCESS: //
      return {
        ...state,
        kaigonaiyou: action.payload,
        loading: false,
      };

    // tokki
    case REPORT_USER_TOKKI_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_TOKKI_SUCCESS: //
      return {
        ...state,
        tokki: action.payload,
        loading: false,
      };

    // gazou
    case REPORT_USER_GAZOU_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_USER_GAZOU_SUCCESS: //
      return {
        ...state,
        gazou: action.payload,
        loading: false,
      };

    case REPORT_USER_VITAL_COPY:
      return {
        ...state,
        baitaruTab: copy(state.baitaruTab, action.baitaruRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_VITAL_DEL:
      return {
        ...state,
        baitaruTab: del(state.baitaruTab, action.baitaruRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_MEAL_COPY:
      return {
        ...state,
        shokujiTab: copy(state.shokujiTab, action.mealRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_MEAL_DEL:
      return {
        ...state,
        shokujiTab: del(state.shokujiTab, action.mealRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_SUIBUN_COPY:
      return {
        ...state,
        suibun: copy(state.suibun, action.suibunRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case REPORT_USER_SUIBUN_DEL:
      return {
        ...state,
        suibun: del(state.suibun, action.suibunRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case REPORT_USER_HAISETU_COPY:
      return {
        ...state,
        haisetu: copy(state.haisetu, action.haisetuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_HAISETU_DEL:
      return {
        ...state,
        haisetu: del(state.haisetu, action.haisetuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_NYUYOKU_COPY:
      return {
        ...state,
        nyutaishoTab: copy(state.nyutaishoTab, action.nyuyokuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_NYUYOKU_DEL:
      return {
        ...state,
        nyutaishoTab: del(state.nyutaishoTab, action.nyuyokuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_FUKUYAKU_COPY:
      return {
        ...state,
        fukuyaku: copy(state.fukuyaku, action.fukuyakuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_FUKUYAKU_DEL:
      return {
        ...state,
        fukuyaku: del(state.fukuyaku, action.fukuyakuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_KAIGO_COPY:
      return {
        ...state,
        kaigonaiyou: copy(state.kaigonaiyou, action.kaigonaiyouRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_KAIGO_DEL:
      return {
        ...state,
        kaigonaiyou: del(state.kaigonaiyou, action.kaigonaiyouRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case REPORT_USER_TOKKI_COPY:
      return {
        ...state,
        tokki: copy(state.tokki, action.tokkiRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case REPORT_USER_TOKKI_DEL:
      return {
        ...state,
        tokki: del(state.tokki, action.tokkiRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    //
    case REPORT_USER_GAZOU_COPY:
      return {
        ...state,
        gazou: copy(state.gazou, action.gazoRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case REPORT_USER_GAZOU_DEL:
      return {
        ...state,
        gazou: del(state.gazou, action.gazoRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case REPORT_USER_UPDATE_REQUEST:
      return {
        ...state,
        loadingButtonUpdate: true,
        notifyDisplay: 0,
        loading: true,
      };

    case REPORT_USER_UPDATE_SUCCESS:
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            loading: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            baitaruTab: state.baitaruTab.map((item) => {
              if (item.vitalId) {
                if (
                  action.paramsUpdate.vitalIds
                    .split(",")
                    .includes("" + item.vitalId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.vitalId === item.vitalId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            loading: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            shokujiTab: state.shokujiTab.map((item) => {
              if (item.mealId) {
                if (
                  action.paramsUpdate.mealIds
                    .split(",")
                    .includes("" + item.mealId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.mealId === item.mealId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            loadingButtonUpdate: false,
            loading: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            suibun: state.suibun.map((item) => {
              if (item.suibunId) {
                if (
                  action.paramsUpdate.suibunIds
                    .split(",")
                    .includes("" + item.suibunId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.suibunId === item.suibunId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            loadingButtonUpdate: false,
            loading: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            haisetu: state.haisetu.map((item) => {
              if (item.haisetuId) {
                if (
                  action.paramsUpdate.haisetuIds
                    .split(",")
                    .includes("" + item.haisetuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.haisetuId === item.haisetuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            loading: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            nyutaishoTab: state.nyutaishoTab.map((item) => {
              if (item.nyuyokuId) {
                if (
                  action.paramsUpdate.nyuyokuIds
                    .split(",")
                    .includes("" + item.nyuyokuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.nyuyokuId === item.nyuyokuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            loading: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            fukuyaku: state.fukuyaku.map((item) => {
              if (item.fukuyakuId) {
                if (
                  action.paramsUpdate.fukuyakuIds
                    .split(",")
                    .includes("" + item.fukuyakuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.fukuyakuId === item.fukuyakuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            loadingButtonUpdate: false,
            loading: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            kaigonaiyou: state.kaigonaiyou.map((item) => {
              if (item.kaigoId) {
                if (
                  action.paramsUpdate.kaigoIds
                    .split(",")
                    .includes("" + item.kaigoId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.kaigoId === item.kaigoId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            loadingButtonUpdate: false,
            loading: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            tokki: state.tokki.map((item) => {
              if (item.tokkiId) {
                if (
                  action.paramsUpdate.tokkiIds
                    .split(",")
                    .includes("" + item.tokkiId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.tokkiId === item.tokkiId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            loadingButtonUpdate: false,
            loading: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            gazou: state.gazou.map((item) => {
              if (item.fileManagementId) {
                if (
                  action.paramsUpdate.fileManagementId ===
                  item.fileManagementId.toString()
                ) {
                  return Object.assign(item, action.payload); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        default:
          return {
            ...state,
          };
      }

    case REPORT_USER_UPDATE_ERROR:
      let messageUpdate = null;
      if (
        action.payload.data &&
        action.payload.data.error &&
        action.payload.data.error.code
      ) {
        messageUpdate = convertMessageByCode(action.payload.data.error.code);
      }
      return {
        ...state,
        loadingButtonUpdate: false,
        loading: false,
        notifyDisplay: 2,
        notifyContent: messageUpdate ? messageUpdate : "エラーがあります",
      };

    case REPORT_USER_INSERT_REQUEST:
      return {
        ...state,
        loadingButtonUpdate: true,
        notifyDisplay: 0,
        loading: true,
      };

    case REPORT_USER_INSERT_SUCCESS:
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            baitaruTab: state.baitaruTab.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            shokujiTab: state.shokujiTab.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            suibun: state.suibun.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            haisetu: state.haisetu.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            nyutaishoTab: state.nyutaishoTab.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            fukuyaku: state.fukuyaku.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            kaigonaiyou: state.kaigonaiyou.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            tokki: state.tokki.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            gazou: state.gazou.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        default:
          return {
            ...state,
          };
      }

    case REPORT_USER_INSERT_ERROR:
      let messageInsert = null;
      if (
        action.payload.data &&
        action.payload.data.error &&
        action.payload.data.error.code
      ) {
        messageInsert = convertMessageByCode(action.payload.data.error.code);
      }
      return {
        ...state,
        loadingButtonUpdate: false,
        notifyDisplay: 2,
        notifyContent: messageInsert ? messageInsert : "エラーがあります",
        loading: false,
      };

    //
    case REPORT_USER_DELETE_REQUEST:
      return {
        ...state,
        loadingButtonUpdate: true,
        notifyDisplay: 0,
        loading: true,
      };

    case REPORT_USER_DELETE_SUCCESS: // del from server
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            baitaruTab: formatRecords(
              state.baitaruTab,
              action.payload.vitalId,
              "vitalId"
            ),
            loading: false,
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            shokujiTab: formatRecords(
              state.shokujiTab,
              action.payload.mealId,
              "mealId"
            ),
            loading: false,
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            suibun: formatRecords(
              state.suibun,
              action.payload.suibunId,
              "suibunId"
            ),
            loading: false,
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            haisetu: formatRecords(
              state.haisetu,
              action.payload.haisetuId,
              "haisetuId"
            ),
            loading: false,
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            nyutaishoTab: formatRecords(
              state.nyutaishoTab,
              action.payload.nyuyokuId,
              "nyuyokuId"
            ),
            loading: false,
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            fukuyaku: formatRecords(
              state.fukuyaku,
              action.payload.fukuyakuId,
              "fukuyakuId"
            ),
            loading: false,
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            kaigonaiyou: formatRecords(
              state.kaigonaiyou,
              action.payload.kaigoId,
              "kaigoId"
            ),
            loading: false,
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            tokki: formatRecords(
              state.tokki,
              action.payload.tokkiId,
              "tokkiId"
            ),
            loading: false,
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            gazou: formatRecords(
              state.gazou,
              action.payload.fileManagementId,
              "fileManagementId"
            ),
            loading: false,
          };

        default:
          return {
            ...state,
          };
      }

    case REPORT_USER_DELETE_ERROR:
      return {
        ...state,
        loadingButtonUpdate: false,
        notifyDisplay: 2,
        notifyContent:
          action.payload.data &&
          action.payload.data.error &&
          action.payload.data.error.code
            ? action.payload.data.error.code
            : "エラーがあります",
        loading: false,
      };

    case REPORT_USER_LOAD_ZENKAI_REQUEST:
      return {
        ...state,
      };
    case REPORT_USER_LOAD_ZENKAI_SUCCESS:
      return {
        ...state,
        zenkai: action.payload,
      };
    case REPORT_USER_LOAD_ZENKAI_ERROR:
      return {
        ...state,
        notifyDisplay: 2,
        notifyContent:
          action.payload.data &&
          action.payload.data.error &&
          action.payload.data.error.code
            ? action.payload.data.error.code
            : "エラーがあります",
      };

    /**
     * Multiple row update place
     */
    case USER_MULTIPLE_UPDATE_ERROR: // Error for all request
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            baitaruTab: JSON.parse(JSON.stringify(state.baitaruTab)),
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            shokujiTab: JSON.parse(JSON.stringify(state.shokujiTab)),
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            suibun: JSON.parse(JSON.stringify(state.suibun)),
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            haisetu: JSON.parse(JSON.stringify(state.haisetu)),
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            nyutaishoTab: JSON.parse(JSON.stringify(state.nyutaishoTab)),
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            fukuyaku: JSON.parse(JSON.stringify(state.fukuyaku)),
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            kaigonaiyou: JSON.parse(JSON.stringify(state.kaigonaiyou)),
          };

        default:
          return {
            ...state,
          };
      }

    case REPORT_USER_UPDATE_MUL_SUCCESS:
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            baitaruTab: state.baitaruTab.map((item) => {
              if (item.vitalId) {
                if (
                  action.paramsUpdate.vitalIds
                    .split(",")
                    .includes("" + item.vitalId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.vitalId === item.vitalId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            shokujiTab: state.shokujiTab.map((item) => {
              if (item.mealId) {
                if (
                  action.paramsUpdate.mealIds
                    .split(",")
                    .includes("" + item.mealId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.mealId === item.mealId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            suibun: state.suibun.map((item) => {
              if (item.suibunId) {
                if (
                  action.paramsUpdate.suibunIds
                    .split(",")
                    .includes("" + item.suibunId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.suibunId === item.suibunId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            haisetu: state.haisetu.map((item) => {
              if (item.haisetuId) {
                if (
                  action.paramsUpdate.haisetuIds
                    .split(",")
                    .includes("" + item.haisetuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.haisetuId === item.haisetuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            nyutaishoTab: state.nyutaishoTab.map((item) => {
              if (item.nyuyokuId) {
                if (
                  action.paramsUpdate.nyuyokuIds
                    .split(",")
                    .includes("" + item.nyuyokuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.nyuyokuId === item.nyuyokuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            fukuyaku: state.fukuyaku.map((item) => {
              if (item.fukuyakuId) {
                if (
                  action.paramsUpdate.fukuyakuIds
                    .split(",")
                    .includes("" + item.fukuyakuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.fukuyakuId === item.fukuyakuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            kaigonaiyou: state.kaigonaiyou.map((item) => {
              if (item.kaigoId) {
                if (
                  action.paramsUpdate.kaigoIds
                    .split(",")
                    .includes("" + item.kaigoId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.kaigoId === item.kaigoId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            tokki: state.tokki.map((item) => {
              if (item.tokkiId) {
                if (
                  action.paramsUpdate.tokkiIds
                    .split(",")
                    .includes("" + item.tokkiId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.tokkiId === item.tokkiId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            gazou: state.gazou.map((item) => {
              if (item.fileManagementId) {
                if (
                  action.paramsUpdate.fileManagementId ===
                  item.fileManagementId.toString()
                ) {
                  return Object.assign(item, action.payload); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        default:
          return {
            ...state,
          };
      }

    case REPORT_USER_INSERT_MUL_SUCCESS:
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            baitaruTab: state.baitaruTab.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            shokujiTab: state.shokujiTab.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            suibun: state.suibun.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            haisetu: state.haisetu.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            nyutaishoTab: state.nyutaishoTab.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            fukuyaku: state.fukuyaku.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            kaigonaiyou: state.kaigonaiyou.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            tokki: state.tokki.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            gazou: state.gazou.map((item) => {
              if (
                item.index.toString() === action.paramsUpdate.index.toString()
              ) {
                item.modeRecord = "";
                return Object.assign(item, action.payload);
              } else {
                return item;
              }
            }),
          };

        default:
          return {
            ...state,
          };
      }

    // END Multiple mode

    default:
      return state;
  }
}

/**
 * Search All Function - include searchByName
 *
 * @param {*} arraySearch
 * @param {*} roomList // Get from state = state.nyutaishoFilter
 */
// function searchAll(arraySearch, roomList) {
//   // get data filter
//   let dataFilter = JSON.parse(localStorage.getItem('is_keepMNGT'))
//   if (arraySearch && dataFilter) {
//     // SEARCH BY BUNRUI
//     if (dataFilter.search.field10 && dataFilter.search.checkbox1) {
//       let valueKey = dataFilter.search.field10.split('=')[0]

//       if (valueKey === '分類名1' && dataFilter.search.field11) {
//         arraySearch = arraySearch.filter(
//           item => item.riyousya.bunrui1 === dataFilter.search.field11
//         )
//       }

//       if (valueKey === '分類名2' && dataFilter.search.field11) {
//         arraySearch = arraySearch.filter(item => item.riyousya.bunrui2 === dataFilter.search.field11)
//       }

//       if (valueKey === '分類名3' && dataFilter.search.field11) {
//         arraySearch = arraySearch.filter(item => item.riyousya.bunrui3 === dataFilter.search.field11)
//       }

//       if (valueKey === '分類名4' && dataFilter.search.field11) {
//         arraySearch = arraySearch.filter(item => item.riyousya.bunrui4 === dataFilter.search.field11)
//       }

//       if (valueKey === '分類名5' && dataFilter.search.field11) {
//         arraySearch = arraySearch.filter(item => item.riyousya.bunrui5 === dataFilter.search.field11)
//       }

//     }

//     // SEARCH BY nyutaisho type
//     if (dataFilter.search.field2 && dataFilter.search.checkbox2) {
//       arraySearch = arraySearch.filter(item => item.kubun === dataFilter.search.field2)
//     }

//     // SEARCH BY ROOM
//     if (dataFilter.search.field3 && dataFilter.search.checkbox3 && roomList && roomList.roomMasters) {
//       roomList.roomMasters = roomList.roomMasters.filter(item => item.bunrui === dataFilter.search.field3)

//       // Algorithm filter room in array
//       arraySearch = arraySearch.filter(item => {
//         for (let j = 0; j < roomList.roomMasters.length; j++) {
//           if (item.room === roomList.roomMasters[j].room) {
//             return true;
//           }
//         }

//         return false;
//       })
//     }

//     if (arraySearch && dataFilter.searchName) {
//       arraySearch = arraySearch.filter(item => {
//         if (isKanji(dataFilter.searchName)) { // kanjiで漢字検索のみ
//           if (_.includes(item.riyousya.name, dataFilter.searchName))
//             return true;
//           else
//             return false;
//         }
//         else { // 他の場合すべて検索
//           if (_.includes(item.riyousya.name, dataFilter.searchName))
//             return true;

//           if (_.includes(item.riyousya.furigana, toKatakana(dataFilter.searchName))) {
//             return true
//           }

//           return false;
//         }
//       })
//     }
//   }

//   return arraySearch;
// }

/**
 *
 * @param {*} originArray
 * @param {*} index
 */
function copy(originArray, record) {
  let returnCopyArray = [];
  for (let tmpCopy in originArray) {
    if (originArray[tmpCopy].index === record.index) {
      returnCopyArray.push(originArray[tmpCopy]);
      returnCopyArray.push(record);
      record.index = 0;
    } else {
      returnCopyArray.push(originArray[tmpCopy]);
    }
  }

  return returnCopyArray;
}

/**
 *
 * @param {*} originArray
 * @param {*} index
 */
function del(originArray, record) {
  originArray = originArray.filter((item) => {
    return item.index !== record.index;
  });

  return originArray;
}

/**
 * recordIdで削除
 *
 * @param {*} originArray
 * @param {*} recordId
 */
// function delByRecordId(originArray, recordId) {
//   originArray = originArray.filter(item => {
//     return item.vitalId !== recordId
//   })

//   return originArray;
// }

function convertMessageByCode(message) {
  if (message === "PARAMS_MISSING") {
    return "必須項目がセットされていません";
  } else if (message === "DUPLICATE_ERROR") {
    return "重複登録はできません";
  }
  return message;
}
