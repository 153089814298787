import React, { Component, Fragment } from "react";
import { LinkOutlined } from '@ant-design/icons';
import FileViewer from "react-file-viewer";

// import "../../components/kiroku/Kiroku.css";

function DefaultFileView(props) {
  if (props && props.thumbnail) {
    return (
      <div className="kiroku-drawer__content">
        <img
          className="kiroku-drawer__thumb-icon"
          alt=""
          src={props.thumbnail}
        />
      </div>
    );
  }
  return null;
}

class ViewFile extends Component {
  zoom(type) {
    if (["png", "jpg", "jpeg", "bmp", "gif"].includes(type)) {
      const content = document.querySelector(".kiroku-drawer__content");

      if (content) {
        content.classList.toggle("kiroku-drawer__content--zoom-image");
      }
    }
  }

  renderContent(record, filePath) {
    return (
      <div
        className="kiroku-drawer__content"
        onClick={() =>
          filePath ? this.zoom(filePath.type.split("/")[1]) : null
        }
      >
        {filePath ? (
          <FileViewer
            fileType={filePath.type.split("/")[1] || ""}
            filePath={URL.createObjectURL(filePath)}
            errorComponent={DefaultFileView}
            unsupportedComponent={DefaultFileView}
            thumbnail={record && record.thumbnail ? record.thumbnail : null}
          />
        ) : (
          <DefaultFileView
            thumbnail={record && (record.thumbnail || record.thumbnailState)}
          />
        )}
      </div>
    );
  }

  render() {
    const { record, filePreview } = this.props;
    return (
      <Fragment>
        {/* Title */}
        <div className="kiroku-drawer__title">
          <LinkOutlined style={{ float: "left", marginRight: 8 }} />
          <span>
            {record && record.fileName ? `${record.fileName}` : "ファイル"}
          </span>
        </div>

        {/* Content */}
        {this.renderContent(record, filePreview)}
      </Fragment>
    );
  }
}

export default ViewFile;
