import React, { Component } from "react";
import { Col, Row, Table } from "antd";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";

import { tantoMasterList2 } from "../../../actions/tantoMasterListActions/actionCreators";
import LineBreakText from "../../../common/component/LineBreakText";

class ViewKeikaKiroku extends Component {
  componentDidMount() {
    this.props.getTantoMasterList2();
  }

  getEvaluateTantoName(tantoMasterList, tantoId) {
    if (!tantoMasterList || !tantoMasterList.tantoMasters2) return "";

    for (let item of tantoMasterList.tantoMasters2) {
      if (item.id === tantoId) {
        return item.name;
      }
    }
    return "";
  }

  renderDesktopContent(data) {
    const columnTable1 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "20%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title3",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content3",
        width: "20%",
      },
    ];
    const columnTable11 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "35%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "35%",
      },
    ];
    const columnTable12 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "35%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "35%",
      },
    ];
    const columnTable2 = [
      {
        className: "title-view1",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "content",
        width: "85%",
        className: "content-tb1",
        render: (text) => <LineBreakText text={text} />,
      },
    ];
    const columnTable3 = [
      {
        className: "title-view1",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "content",
        width: "85%",
        className: "content-tb1 table-cell-wordbreak",
        render: (text) => <LineBreakText text={text} />,
      },
    ];

    const dataTable1 = [
      {
        title: "記録日",
        content:
          this.props.data && this.props.data.kirokuDate
            ? this.props.data.kirokuDate
            : "　",
        title2: "時間",
        content2:
          this.props.data && this.props.data.kirokuTime
            ? this.props.data.kirokuTime
            : "　",
        title3: "訪問日",
        content3:
          this.props.data && this.props.data.houmonDate
            ? this.props.data.houmonDate
            : "　",
      },
    ];
    const dataTable11 = [
      {
        title: "記録区分",
        content:
          this.props.data && this.props.data.kirokuKubun
            ? this.props.data.kirokuKubun
            : "　",
        title2: "相談内容",
        content2:
          this.props.data && this.props.data.soudanNaiyou
            ? this.props.data.soudanNaiyou
            : "　",
      },
    ];
    const dataTable12 = [
      {
        title: "計画作成者",
        content:
          this.props.data && this.props.data.tantoId
            ? this.getEvaluateTantoName(
                this.props.tantoMasterList,
                this.props.data.tantoId
              )
            : "　",
        title2: "記録作成者",
        content2:
          this.props.data && this.props.data.kirokuTantoId
            ? this.getEvaluateTantoName(
                this.props.tantoMasterList,
                this.props.data.kirokuTantoId
              )
            : "　",
      },
    ];
    const dataTable2 = [
      {
        title: "関係機関・居宅サービス計画作成者の判断",
        content: data.sakuseisyaHandan || "",
      },
      {
        title: "利用者・家族の考え方",
        content: data.riyousyaKangae || "",
      },
    ];
    const dataTable3 = [
      {
        title: "内容",
        content: data.naiyou || "",
      },
    ];

    return (
      <div className="view-keika-kiroku-container">
        <Table
          bordered={false}
          className={"view-table1"}
          pagination={false}
          showHeader={false}
          columns={columnTable1}
          dataSource={dataTable1}
          rowKey="id"
        />
        <Table
          bordered={false}
          className={"view-table11"}
          pagination={false}
          showHeader={false}
          columns={columnTable11}
          dataSource={dataTable11}
          rowKey="id"
        />
        <Table
          bordered={false}
          className={"view-table12"}
          pagination={false}
          showHeader={false}
          columns={columnTable12}
          dataSource={dataTable12}
          rowKey="id"
        />
        <Table
          style={{ marginTop: 20 }}
          bordered={false}
          className={"view-table5"}
          pagination={false}
          showHeader={false}
          columns={columnTable3}
          dataSource={dataTable3}
          rowKey="id"
        />
        <Table
          style={{ marginTop: 20 }}
          bordered={false}
          className={"view-table2"}
          pagination={false}
          showHeader={false}
          columns={columnTable2}
          dataSource={dataTable2}
          rowKey="id"
        />
      </div>
    );
  }

  renderMobileContent(data) {
    return (
      <div className="view-keika-kiroku-container-m">
        <Row>
          <Col span={24}>
            <span className="text-bold">記録日</span>
            <span>{data.kirokuDate || ""}</span>
          </Col>
          <Col span={24}>
            <span className="text-bold">時間</span>
            <span>{data.kirokuTime || ""}</span>
          </Col>
          <Col span={24}>
            <span className="text-bold">訪問日</span>
            <span>{data.houmonDate || ""}</span>
          </Col>
          <Col span={24}>
            <span className="text-bold">記録区分</span>
            <span>{data.kirokuKubun || ""}</span>
          </Col>
          <Col span={24}>
            <span className="text-bold">相談内容</span>
            <span>{data.soudanNaiyou || ""}</span>
          </Col>
          <Col span={24}>
            <span className="text-bold">計画作成者</span>
            <span>
              {data.tantoId
                ? this.getEvaluateTantoName(
                    this.props.tantoMasterList,
                    data.tantoId
                  )
                : ""}
            </span>
          </Col>
          <Col span={24}>
            <span className="text-bold">記録作成者</span>
            <span>
              {data.kirokuTantoId
                ? this.getEvaluateTantoName(
                    this.props.tantoMasterList,
                    data.kirokuTantoId
                  )
                : ""}
            </span>
          </Col>
          <Col
            span={24}
            className="view-keika-kiroku-text"
            style={{ marginTop: 20 }}
          >
            <span className="text-bold">内容</span>
            <span>{data.naiyou || ""}</span>
          </Col>
          <Col span={24} className="view-keika-kiroku-text">
            <span className="text-bold">
              関係機関・居宅サービス・計画作成者の判断
            </span>
            <span>{data.sakuseisyaHandan || ""}</span>
          </Col>
          <Col span={24} className="view-keika-kiroku-text">
            <span className="text-bold">利用者・家族の考え方</span>
            <span>{data.riyousyaKangae || ""}</span>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    if (!data) return null;

    return (
      <MediaQuery query="(min-device-width: 768px)">
        {(matches) =>
          matches
            ? this.renderDesktopContent(data)
            : this.renderMobileContent(data)
        }
      </MediaQuery>
    );
  }
}

const mapStateToProps = () => (state) => ({
  tantoMasterList: state.tantoMasterList,
});

const mapDispatchToProps = () => (dispatch) => ({
  getTantoMasterList2: () => dispatch(tantoMasterList2()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewKeikaKiroku);
