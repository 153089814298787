/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  MinusOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  SaveOutlined,
  UndoOutlined,
} from "@ant-design/icons";

import { Form, Icon as LegacyIcon } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';


import { Affix, Button, Checkbox, Col, Input, Modal, Radio, Row, Spin, Table } from "antd";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { getKyotakuPlan2YougoMaster } from "../../../actions/kyotakuPlan2Actions/actionCreators";
import {
  getActTemplate,
  getKaigoNaiyou,
  getKaigoServiceTemplate,
  getKaigoServiceTemplateName,
  getSeikatuNaiyou,
  getSisetuDailyPlan,
  resetStatus,
  saveActTemplate,
  saveKaigoServiceTemplate,
  saveSisetuDailyPlan,
} from "../../../actions/sisetuDailyPlanActions/actionCreators";
import {
  yougoMasterEditCancelCreator,
  yougoMasterEditSaveCreator,
  yougoMasterEditStateCreator,
} from "../../../actions/yougoMasterNaiyouActions/actionCreators";
import { openNotificationWithIcon } from "../../../common/function_common/functionCommon";
import KaigoNaiyouModal from "./KaigoNaiyouModal";
import LastQuoteModal from "./LastQuoteModal";
import YougoModal from "./YougoModal";
import {
  getKaigoNaiyouText,
  processDataTableNew
} from "./processData";

const { TextArea } = Input;

class InputDailyPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kyotakuDailyPlanId: this.props.sisetuDailyPlanId,
      visible: false,
      inputVisible: false,
      listName: null,
      sisetuDailyPlan: this.props.sisetuDailyPlan,
      // sisetuDailyPlan: dataFake,
      selectedCell: null,
      typeCell: null,
      valueSeikatu: null,
      typeYougo: null,
      isFieldsTouched: false,
      visibleConfirm: false,
      lastQuoteVisible: false,
      visibleSave: false,
      tempName: null,
      indexTab: "1",
      tempId: null,
      expand: true,
      validateTempName: false,
      validateText: "",
      dataYougo: null,
      tempSelected: null,
      kaigoData: null,
      timeKaigo: null,
      checkView: false,
    };
  }
  // componentWillReceiveProps(nextProps) {
  //   if (this.state.checkView == false && this.props.sisetuDailyPlanId == "new" && nextProps.status !== this.props.status && nextProps.status == "success") {
  //     this.setState({
  //       checkView: false,
  //       isFieldsTouched: false
  //     })
  //     this.props.history.push(`/input-sisetu-daily-plan/${nextProps.sisetuDailyPlan.riyousyaId}/${nextProps.sisetuDailyPlan.id}`)
  //   }
  //   if (nextProps.status !== this.props.status && nextProps.status == "success") {
  //     this.setState({
  //       checkView: false,
  //       isFieldsTouched: false
  //     })
  //   }
  //   if (this.state.checkView == true && this.props.sisetuDailyPlanId == "new" && nextProps.status !== this.props.status && nextProps.status == "success") {
  //     this.setState({
  //       checkView: false,
  //       isFieldsTouched: false
  //     })
  //     this.props.history.push(`/input-sisetu-daily-plan/${nextProps.sisetuDailyPlan.riyousyaId}/${nextProps.sisetuDailyPlan.id}`)
  //     var win = window.open(`/check-info-document-list/${this.props.riyousyaId}/${nextProps.sisetuDailyPlan.id}`, '_blank');
  //     if (win.focus)
  //       win.focus();
  //   }
  // }

  componentDidUpdate(prevProp) {
    if (prevProp.sisetuDailyPlan !== this.props.sisetuDailyPlan) {
      this.props.form.setFieldsValue({
        // nyuyoku_youbi: this.props.sisetuDailyPlan && this.props.sisetuDailyPlan.nyuyokuYoubi
        //   ? this.props.sisetuDailyPlan.nyuyokuYoubi
        //   : this.props.form.getFieldValue("nyuyoku_youbi")
        //     ? this.props.form.getFieldValue("nyuyoku_youbi")
        //     : null,
        nyuyoku_youbi: this.props.sisetuDailyPlan
          ? this.props.sisetuDailyPlan.nyuyokuYoubi
          : "",
        progressF:
          this.props.sisetuDailyPlan &&
            this.props.sisetuDailyPlan.progressF !== 1
            ? []
            : ["progressF"],
        kaigo_naiyou: this.props.sisetuDailyPlan
          ? this.props.sisetuDailyPlan.kaigoNaiyou
          : null,
        kyotuf: this.props.sisetuDailyPlan
          ? this.props.sisetuDailyPlan.kyotuf
          : null,
        nitijou_seikatu_naiyou: this.props.sisetuDailyPlan
          ? this.props.sisetuDailyPlan.nitijouSeikatuNaiyou
          : null,
        kobetu_shousai: this.props.sisetuDailyPlan
          ? this.props.sisetuDailyPlan.kobetuShousai
          : null,
        zuiji_service: this.props.sisetuDailyPlan
          ? this.props.sisetuDailyPlan.zuijiService
          : null,
        sonota_service: this.props.sisetuDailyPlan
          ? this.props.sisetuDailyPlan.sonotaService
          : null,
        daily_plan_detail: null,
        riyousya_id: null,
      });

      this.setState({
        sisetuDailyPlan: this.props.sisetuDailyPlan,
      });
    }
  }

  hideModalKaigo() {
    this.setState({
      visible: false,
      kaigoData: null,
      timeKaigo: null,
    });
  }

  showYougoModal(listName, typeYougo) {
    this.props.getYougoMaster(listName);
    const dataYougo = this.props.form.getFieldValue(typeYougo);
    this.setState({
      inputVisible: true,
      listName,
      typeYougo,
      dataYougo,
    });
  }

  handleYougoCancel() {
    this.setState({
      inputVisible: false,
      listName: null,
    });
  }

  handleYougoOk(values) {
    const { typeYougo } = this.state;
    const nyuyokuYoubi = this.props.form.getFieldValue("nyuyoku_youbi");
    const zuijiService = this.props.form.getFieldValue("zuiji_service");
    const sonotaService = this.props.form.getFieldValue("sonota_service");
    if (typeYougo === "nyuyoku_youbi") {
      !nyuyokuYoubi
        ? this.props.form.setFieldsValue({
          [typeYougo]: `${values}`,
        })
        : this.props.form.setFieldsValue({
          [typeYougo]: nyuyokuYoubi + "、" + `${values}`,
        });
    }
    if (typeYougo === "zuiji_service") {
      !zuijiService
        ? this.props.form.setFieldsValue({
          [typeYougo]: `${values}`,
        })
        : this.props.form.setFieldsValue({
          [typeYougo]: zuijiService + "、" + `${values}`,
        });
    }
    if (typeYougo === "sonota_service") {
      !sonotaService
        ? this.props.form.setFieldsValue({
          [typeYougo]: `${values}`,
        })
        : this.props.form.setFieldsValue({
          [typeYougo]: sonotaService + "、" + `${values}`,
        });
    }

    this.setState({
      inputVisible: false,
      listName: null,
      isFieldsTouched: true,
    });
  }

  handleSave = (isOpenView) => {
    const { form } = this.props;
    const { sisetuDailyPlanId, riyousyaId } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      const { sisetuDailyPlan } = this.state;
      const { newDailyPlanDetails } = sisetuDailyPlan;
      const planId = sisetuDailyPlanId === "new" ? null : sisetuDailyPlanId;
      const payload = {};

      payload["progressF"] = values["progressF"].length > 0 ? 1 : 0;
      payload["nyuyoku_youbi"] = values["nyuyoku_youbi"] || "";
      payload["sonota_service"] = values["sonota_service"] || "";
      payload["zuiji_service"] = values["zuiji_service"] || "";
      payload["daily_plan_details"] = [];

      for (const item of newDailyPlanDetails) {
        if (
          item.kaigoNaiyouCode ||
          item.kobetuShousai ||
          item.nitijouSeikatuNaiyou
        ) {
          payload["daily_plan_details"].push({
            time: item.time + ":00",
            kyotuf: item.kyotuf,
            kaigo_naiyou_code: item.kaigoNaiyouCode,
            kobetu_shousai: item.kobetuShousai,
            nitijou_seikatu_naiyou: item.nitijouSeikatuNaiyou,
          });
        }
      }

      this.props.saveSisetuDailyPlan(payload, riyousyaId, planId, (data) => {
        if (sisetuDailyPlanId === "new") {
          this.props.history.push(
            `/input-sisetu-daily-plan/${riyousyaId}/${data.id}`
          );
        }
        else {
          this.props.getSisetuDailyPlan(data.id);
        }
      });

      this.setState({ isFieldsTouched: false });
    });

    if (isOpenView && sisetuDailyPlanId !== "new") {
      const win = window.open(
        `/check-info-document-list/${riyousyaId}/${sisetuDailyPlanId}`,
        "_blank"
      );
      win.focus();
    }
  };

  handleGoBack = async (e) => {
    const { riyousyaId } = this.props;
    e.preventDefault();
    if (this.props.form.isFieldsTouched() || this.state.isFieldsTouched)
      this.showGoBackConfirm();
    else this.props.history.push(`/document-list/${riyousyaId}`);
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack = async () => {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.push(`/document-list/${this.props.riyousyaId}`);
  };

  onChangeSeikatu = (e) => {
    const value = e.target.value;
    this.setState({
      valueSeikatu: value,
      isFieldsTouched: true,
    });
  };
  changeExpand = () => {
    this.setState({
      expand: !this.state.expand,
    });
  };

  saveNaiyouModal(data) {
    const { selectedCell, sisetuDailyPlan } = this.state;
    const sisetuDailyPlanClone = { ...sisetuDailyPlan };
    const { dailyPlanDetails } = sisetuDailyPlanClone;
    const { kyotuf, kaigoNaiyouCode, kaigoNaiyouName } =
      getKaigoNaiyouText(data);

    let found = false;
    for (let i = 0; i < dailyPlanDetails.length; i++) {
      if (dailyPlanDetails[i].time === selectedCell.time + ":00") {
        dailyPlanDetails[i].kyotuf = kyotuf;
        dailyPlanDetails[i].kaigoNaiyouCode = kaigoNaiyouCode;
        dailyPlanDetails[i].kaigoNaiyouName = kaigoNaiyouName;
        found = true;
        break;
      }
    }

    if (!found) {
      dailyPlanDetails.push({
        time: selectedCell.time + ":00",
        kyotuf,
        kaigoNaiyouCode,
        kaigoNaiyouName,
        kobetuShousai: selectedCell.kobetuShousai,
        nitijouSeikatuNaiyou: selectedCell.nitijouSeikatuNaiyou,
      });
    }

    this.setState({
      sisetuDailyPlan: sisetuDailyPlanClone,
      isFieldsTouched: true,
    });
  }

  saveSeikatu = (detail, type) => {
    const { sisetuDailyPlan, valueSeikatu } = this.state;
    const sisetuDailyPlanClone = { ...sisetuDailyPlan };
    const { dailyPlanDetails } = sisetuDailyPlanClone;

    let found = false;
    for (let i = 0; i < dailyPlanDetails.length; i++) {
      if (dailyPlanDetails[i].time === detail.time + ":00") {
        dailyPlanDetails[i][type] = valueSeikatu;
        found = true;
        break;
      }
    }

    if (!found) {
      dailyPlanDetails.push({
        time: detail.time + ":00",
        kyotuf: "",
        kaigoNaiyouCode: "",
        kaigoNaiyouName: "",
        kobetuShousai: type === "kobetuShousai" ? valueSeikatu : "",
        nitijouSeikatuNaiyou:
          type === "nitijouSeikatuNaiyou" ? valueSeikatu : "",
      });
    }

    this.setState({
      sisetuDailyPlan: sisetuDailyPlanClone,
      isFieldsTouched: true,
    });
  };

  onEnterCell = (detail, type) => {
    if (type !== "kaigoNaiyou") {
      this.saveSeikatu(detail, type);
    }
    this.setState({
      selectedCell: null,
      typeCell: null,
    });
  };

  renderHeader() {
    return (
      <Row className={"submenu fixed-header"} style={{ display: "flex" }}>
        <Col span={12} className={"sm-c-left"}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【日課表】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
        <Col span={12} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <div style={{ cursor: "pointer" }} onClick={() => this.handleSave()}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{ display: "block", fontWeight: "bold", color: "red" }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </div>
          {this.props.sisetuDailyPlanId !== "new" ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSave(true)}
            >
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </div>
          ) : null}
        </Col>
      </Row>
    );
  }

  renderAboveTable() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <Row>
          <Col sm={4} md={3} lg={2}>
            <Button
              style={{ fontWeight: "bold" }}
              icon={<LegacyIcon type={this.state.expand ? "plus" : "minus"} />}
              onClick={() => this.changeExpand()}
            >
              {this.state.expand ? "展開" : "格納"}
            </Button>
          </Col>
          <Col span={2} style={{ padding: "6px", width: "70px" }}>
            <span>入浴曜日</span>
          </Col>
          <Col sm={8} md={10} lg={8}>
            <Form.Item>
              {getFieldDecorator("nyuyoku_youbi", {
                rules: [
                  {
                    max: 14,
                    message: "入浴曜日が14文字を超えています!",
                  },
                ],
              })(<Input style={{ float: "right" }} />)}
            </Form.Item>
          </Col>
          <Col span={2} style={{ marginLeft: "10px" }}>
            <Button
              style={{ fontWeight: "bold" }}
              onClick={() => this.showYougoModal("入浴日", "nyuyoku_youbi")}
            >
              選択
            </Button>
          </Col>
          <Col style={{ float: "right" }}>
            <Form.Item>
              {getFieldDecorator("progressF")(
                <Checkbox.Group
                  options={[{ label: "採用", value: "progressF" }]}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  handleSelectRow(record) {
    this.setState({
      selectedCell: record,
    });
  }

  showModalDetail = (record) => {
    this.setState({
      visible: true,
      timeKaigo: record.time,
      kaigoData: processDataTableNew(this.state.sisetuDailyPlan),
    });
  };

  onSelectedCell = async (record, type) => {
    const { selectedCell, typeCell } = this.state;

    if (selectedCell && record.time == selectedCell.time && typeCell === type) {
      return;
    }
    else if (type === "kaigoNaiyou") {
      this.setState({
        selectedCell: record,
        typeCell: type,
      });
    }
    else {
      this.setState({
        selectedCell: record,
        typeCell: type,
        valueSeikatu: record[type],
      });
    }
  };

  showModalLastQuote() {
    this.props.getKaigoServiceTemplateName();
    this.setState({ lastQuoteVisible: true });
  }

  hideModalLastQuote() {
    this.setState({
      lastQuoteVisible: false,
    });
  }

  handleSelectLastQuote = (template) => {
    if (template && template.id) {
      this.setState({
        isFieldsTouched: true,
        lastQuoteVisible: false,
        tempSelected: template,
      });
      this.props.getKaigoServiceTemplate(template.id, (data) => {
        this.autoFillTemplateData(data, 1);
      });
    }
    else {
      openNotificationWithIcon("error", "テンプレートを選択してくだい");
    }

    // if (value && value.dailyPlanId != null) {
    //   await this.props.getKaigoNaiyou(value.dailyPlanId);
    //   this.setState({
    //     tempId: value.dailyPlanId,
    //     isFieldsTouched: true,
    //     lastQuoteVisible: false,
    //     tempSelected: value
    //   });
    // }
    // else {
    //   openNotificationWithIcon("error", "テンプレートを選択してくだい");
    // }
  };

  autoFillTemplateData(data, column) {
    if (data && Array.isArray(data)) {
      const sisetuDailyPlanClone = { ...this.state.sisetuDailyPlan };
      const { dailyPlanDetails } = sisetuDailyPlanClone;
      let _data = [...data];

      for (let i = 0; i < dailyPlanDetails.length; i++) {
        const foundItem = data.find(
          (item) => item.time === dailyPlanDetails[i].time
        );

        if (!foundItem) {
          if (column === 1) {
            dailyPlanDetails[i].kyotuf = "";
            dailyPlanDetails[i].kaigoNaiyouCode = "";
            dailyPlanDetails[i].kaigoNaiyouName = "";
          }
          else if (column === 2) {
            dailyPlanDetails[i].nitijouSeikatuNaiyou = "";
          }
        }
        else {
          if (column === 1) {
            dailyPlanDetails[i].kyotuf = foundItem.kyotuf;
            dailyPlanDetails[i].kaigoNaiyouCode = foundItem.kaigoNaiyouCode;
            dailyPlanDetails[i].kaigoNaiyouName = foundItem.kaigoNaiyouName;
          }
          else if (column === 2) {
            dailyPlanDetails[i].nitijouSeikatuNaiyou =
              foundItem.nitijouSeikatuNaiyou;
          }
          _data = _data.filter(
            (item) => item.time !== dailyPlanDetails[i].time
          );
        }
      }

      for (const d of _data) {
        if (column === 1) {
          dailyPlanDetails.push({
            kyotuf: d.kyotuf,
            kaigoNaiyouCode: d.kaigoNaiyouCode,
            kaigoNaiyouName: d.kaigoNaiyouName,
            kobetuShousai: "",
            nitijouSeikatuNaiyou: "",
            time: d.time,
          });
        }
        else if (column === 2) {
          dailyPlanDetails.push({
            kyotuf: "",
            kaigoNaiyouCode: "",
            kaigoNaiyouName: "",
            kobetuShousai: "",
            nitijouSeikatuNaiyou: d.nitijouSeikatuNaiyou,
            time: d.time,
          });
        }
      }

      this.setState({ sisetuDailyPlan: sisetuDailyPlanClone });
    }
  }

  showModalSaveRecord = () => {
    this.setState({ visibleSave: true });
  };

  handleSaveRecordOk = () => {
    const { tempName, validateTempName } = this.state;
    if (!tempName) {
      this.setState({
        validateTempName: true,
        validateText: "テンプレート名が必須です!",
      });
    }
    else if (validateTempName) {
      return;
    }
    else {
      this.saveKaigoServiceTemplate(this.state.tempName);
      this.setState({
        visibleSave: false,
        tempName: null,
      });
    }
  };

  handleSaveRecordCancel = () => {
    this.setState({
      visibleSave: false,
      tempName: null,
      validateTempName: false,
    });
  };

  handleSaveTempName = (e) => {
    const tempName = e.target.value;
    if (tempName.length > 40) {
      this.setState({
        validateTempName: true,
        validateText: "テンプレート名が40文字を超えています!",
      });
    }
    else {
      this.setState({
        validateTempName: false,
      });
    }
    this.setState({
      tempName,
    });
  };

  getModalLastQuote() {
    if (this.state.indexTab === "1") {
      this.showModalLastQuote();
    }
    else {
      this.handleGetSeikatuNaiyou();
    }
  }

  getModalSaveRecord() {
    if (this.state.indexTab === "1") {
      this.showModalSaveRecord();
    }
    else {
      this.saveNitijouSeikatuNaiyou();
    }
  }

  saveNitijouSeikatuNaiyou() {
    const sisetuDailyPlanClone = { ...this.state.sisetuDailyPlan };
    const { newDailyPlanDetails } = sisetuDailyPlanClone;
    const payload = {};

    payload["daily_plan_details"] = [];

    for (const item of newDailyPlanDetails) {
      if (item.nitijouSeikatuNaiyou) {
        payload["daily_plan_details"].push({
          time: item.time + ":00",
          nitijou_seikatu_naiyou: item.nitijouSeikatuNaiyou,
        });
      }
    }

    this.props.saveActTemplate(payload);
  }

  handleGetSeikatuNaiyou() {
    this.setState({ isFieldsTouched: true });
    this.props.getActTemplate((data) => {
      this.autoFillTemplateData(data, 2);
    });
  }

  getSeikatuNaiyou = () => {
    this.props.getSeikatuNaiyou();
    this.setState({
      isFieldsTouched: true,
    });
  };

  saveKaigoServiceTemplate(templateName) {
    const sisetuDailyPlanClone = { ...this.state.sisetuDailyPlan };
    const { newDailyPlanDetails } = sisetuDailyPlanClone;
    const payload = {};

    payload["templateName"] = templateName;
    payload["daily_plan_details"] = [];

    for (const item of newDailyPlanDetails) {
      if (item.kaigoNaiyouCode) {
        payload["daily_plan_details"].push({
          time: item.time + ":00",
          kyotuf: item.kyotuf,
          kaigo_naiyou_code: item.kaigoNaiyouCode,
        });
      }
    }

    this.props.saveKaigoServiceTemplate(payload);
  }

  renderTable() {
    const { selectedCell, typeCell } = this.state;
    const titleKaigo = (
      <div>
        <Row style={{ textAlign: "start" }}>介護サービス</Row>
        <Row style={{ float: "right" }}>
          <span>テンプレート</span>
          <Button
            style={{ marginLeft: "5px", fontWeight: "bold" }}
            onClick={() => this.showModalLastQuote()}
          >
            呼出
          </Button>
          <Button
            style={{ marginLeft: "10px", fontWeight: "bold" }}
            onClick={() => this.showModalSaveRecord()}
          >
            保存
          </Button>
        </Row>
      </div>
    );
    const titleSeikatu = (
      <div>
        <Row style={{ textAlign: "start" }}>主な日常生活活動</Row>
        <Row style={{ float: "right" }}>
          <span>テンプレート</span>
          <div style={{ float: "right" }}>
            <Button
              style={{ marginLeft: "5px", fontWeight: "bold" }}
              onClick={() => this.handleGetSeikatuNaiyou()}
            >
              呼出
            </Button>
            <Button
              style={{ marginLeft: "10px", fontWeight: "bold" }}
              onClick={() => this.saveNitijouSeikatuNaiyou()}
            >
              保存
            </Button>
          </div>
        </Row>
      </div>
    );
    const columns = [
      {
        title: "",
        dataIndex: "time",
        width: "5%",
      },
      {
        title: titleKaigo,
        dataIndex: "kaigoNaiyou",
        width: "35%",
        onCell: (record) => {
          return {
            onClick: () => this.onSelectedCell(record, "kaigoNaiyou"),
          };
        },
        render: (text, record) => {
          return (
            <div>
              <div
                className={this.state.expand ? "expand-dailyplan" : null}
                style={{ maxWidth: "340px" }}
              >
                {text}
              </div>
              <div style={{ float: "right" }}>
                {selectedCell &&
                  record.time === selectedCell.time &&
                  typeCell === "kaigoNaiyou" ?
                  (
                    <Button
                      style={{ fontWeight: "bold" }}
                      size="small"
                      onClick={() => this.showModalDetail(record)}
                    >
                      修正
                    </Button>
                  ) : (
                    <div />
                  )}
              </div>
            </div>
          );
        },
      },
      {
        title: titleSeikatu,
        dataIndex: "nitijouSeikatuNaiyou",
        width: "30%",
        onCell: (record) => {
          return {
            onClick: () => this.onSelectedCell(record, "nitijouSeikatuNaiyou"),
          };
        },
        render: (text, record) => {
          return (
            <div>
              <div>
                {selectedCell &&
                  record.time === selectedCell.time &&
                  typeCell === "nitijouSeikatuNaiyou" ?
                  (
                    <div>
                      <Input
                        autoFocus
                        value={this.state.valueSeikatu}
                        onChange={(e) => this.onChangeSeikatu(e)}
                        onPressEnter={() =>
                          this.onEnterCell(record, "nitijouSeikatuNaiyou")
                        }
                        onBlur={() =>
                          this.onEnterCell(record, "nitijouSeikatuNaiyou")
                        }
                      />
                    </div>
                  ) : (
                    <div
                      className={this.state.expand ? "expand-dailyplan" : null}
                      style={{ maxWidth: "290px", wordWrap: "break-word" }}
                    >
                      {text}
                    </div>
                  )}
              </div>
            </div>
          );
        },
      },
      {
        title: "個別詳細内容",
        dataIndex: "kobetuShousai",
        width: "25%",
        onCell: (record) => {
          return {
            onClick: () => this.onSelectedCell(record, "kobetuShousai"),
          };
        },
        render: (text, record) => {
          return (
            <div>
              <div>
                {selectedCell &&
                  record.time === selectedCell.time &&
                  typeCell === "kobetuShousai" ?
                  (
                    <Input
                      autoFocus
                      value={this.state.valueSeikatu}
                      onChange={(e) => this.onChangeSeikatu(e)}
                      onPressEnter={() =>
                        this.onEnterCell(record, "kobetuShousai")
                      }
                      onBlur={() => this.onEnterCell(record, "kobetuShousai")}
                    />
                  ) : (
                    <div
                      className={this.state.expand ? "expand-dailyplan" : null}
                      style={{ maxWidth: "250px", wordWrap: "break-word" }}
                    >
                      {text}
                    </div>
                  )}
              </div>
            </div>
          );
        },
      },
    ];

    const dataTable = processDataTableNew(this.state.sisetuDailyPlan);

    return (
      <div>
        <Table
          className="daily-table"
          rowKey={"id"}
          columns={columns}
          pagination={false}
          dataSource={dataTable.newDailyPlanDetails || []}
          size={"small"}
          rowClassName="table_daily_plan"
        />
      </div>
    );
  }

  renderBottomTable() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <span style={{ fontWeight: "bold" }}>随時実施するサービス </span>
          <Button
            style={{ float: "right", fontWeight: "bold" }}
            onClick={() => this.showYougoModal("随時サービス", "zuiji_service")}
          >
            選択
          </Button>
        </div>
        <Form.Item>
          {getFieldDecorator("zuiji_service")(<TextArea rows={3} />)}
        </Form.Item>
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <span style={{ fontWeight: "bold" }}>その他のサービス </span>
          <Button
            style={{ float: "right", fontWeight: "bold" }}
            onClick={() =>
              this.showYougoModal("その他サービス", "sonota_service")
            }
          >
            選択
          </Button>
        </div>
        <Form.Item>
          {getFieldDecorator("sonota_service")(<TextArea rows={3} />)}
        </Form.Item>
      </Form>
    );
  }

  renderHeaderMobile() {
    return (
      <Affix offsetTop={0}>
        <div className={"submenu"}>
          <Row>
            <Col span={12} className={"sm-c-left"} style={{ color: "white" }}>
              <span style={{ display: "block", fontWeight: "bold" }}>
                <h3 style={{ color: "white" }}>{"【日課表】"}</h3>
              </span>
            </Col>
          </Row>
          <Row style={{ padding: "0px 16px" }}>
            <Col>
              <span style={{ display: "block", color: "white" }}>
                <span
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    float: "left",
                  }}
                >
                  利用者 {this.props.riyousya ? this.props.riyousya.name : ""}
                </span>
              </span>
            </Col>
          </Row>
        </div>
      </Affix>
    );
  }

  renderAboveTableMobile() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form style={{ marginBottom: "10px" }}>
        <Row>
          <Col span={12} style={{ padding: "10px 0px", fontWeight: "bold" }}>
            入浴曜日
          </Col>
          <Col style={{ float: "right" }}>
            <Form.Item style={{ marginBottom: "0px" }}>
              {getFieldDecorator("progressF")(
                <Checkbox.Group
                  options={[{ label: "採用", value: "progressF" }]}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item style={{ width: "100%" }} colon={false}>
              {getFieldDecorator("nyuyoku_youbi", {
                rules: [
                  {
                    max: 14,
                    message: "入浴曜日が14文字を超えています!",
                  },
                ],
              })(<Input size="small" style={{ float: "right" }} />)}
            </Form.Item>
          </Col>
          <Col span={2} style={{ marginLeft: "10px" }}>
            <Button
              style={{ fontWeight: "bold" }}
              size="small"
              onClick={() => this.showYougoModal("入浴日", "nyuyoku_youbi")}
            >
              選択
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Button
              onClick={() => this.changeExpand()}
              size="small"
              style={{ fontWeight: "bold" }}
            >
              {this.state.expand ? (
                <PlusOutlined style={{ width: "15px" }} />
              ) : (
                <MinusOutlined />
              )}
              {this.state.expand ? "展開" : "格納"}
            </Button>
          </Col>
          {this.state.indexTab === "3" ? (
            <Col></Col>
          ) : (
            <div>
              <Col span={6}>
                <span style={{ float: "right", marginRight: "10px" }}>
                  テンプレート
                </span>
              </Col>
              <Col span={12}>
                <Button
                  style={{ fontWeight: "bold" }}
                  onClick={() => this.getModalLastQuote()}
                  size="small"
                >
                  呼出
                </Button>
                <Button
                  style={{ marginLeft: "10px", fontWeight: "bold" }}
                  onClick={() => this.getModalSaveRecord()}
                  size="small"
                >
                  保存
                </Button>
              </Col>
            </div>
          )}
        </Row>
      </Form>
    );
  }

  renderTableMobile() {
    const { selectedCell, typeCell } = this.state;
    const dataTable = processDataTableNew(this.state.sisetuDailyPlan);
    const columns = [
      {
        dataIndex: "time",
        width: 60,
      },
      this.state.indexTab === "3"
        ? {
          dataIndex: "kobetuShousai",
          onCell: (record) => {
            return {
              onClick: () => this.onSelectedCell(record, "kobetuShousai"),
            };
          },
          render: (text, record) => {
            return (
              <div>
                <div>
                  {selectedCell &&
                    record.time === selectedCell.time &&
                    typeCell === "kobetuShousai" ?
                    (
                      <Input
                        autoFocus
                        value={this.state.valueSeikatu}
                        onChange={(e) => this.onChangeSeikatu(e)}
                        onPressEnter={() =>
                          this.onEnterCell(record, "kobetuShousai")
                        }
                      />
                    ) : (
                      <div
                        className={
                          this.state.expand ? "expand-dailyplan" : null
                        }
                        style={{ maxWidth: "320px", wordWrap: "break-word" }}
                      >
                        {text}
                      </div>
                    )}
                </div>
              </div>
            );
          },
        }
        : this.state.indexTab === "2"
          ? {
            dataIndex: "nitijouSeikatuNaiyou",
            onCell: (record) => {
              return {
                onClick: () =>
                  this.onSelectedCell(record, "nitijouSeikatuNaiyou"),
              };
            },
            render: (text, record) => {
              return (
                <div>
                  <div>
                    {selectedCell &&
                      record.time === selectedCell.time &&
                      typeCell === "nitijouSeikatuNaiyou" ?
                      (
                        <div>
                          <Input
                            autoFocus
                            value={this.state.valueSeikatu}
                            onChange={(e) => this.onChangeSeikatu(e)}
                            onPressEnter={() =>
                              this.onEnterCell(record, "nitijouSeikatuNaiyou")
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className={
                            this.state.expand ? "expand-dailyplan" : null
                          }
                          style={{ maxWidth: "320px", wordWrap: "break-word" }}
                        >
                          {text}
                        </div>
                      )}
                  </div>
                </div>
              );
            },
          }
          : {
            dataIndex: "kaigoNaiyou",
            onCell: (record) => {
              return {
                onClick: () => this.onSelectedCell(record, "kaigoNaiyou"),
              };
            },
            render: (text, record) => {
              return (
                <div>
                  <div
                    className={this.state.expand ? "expand-dailyplan" : null}
                    style={{ maxWidth: "320px", wordWrap: "break-word" }}
                  >
                    {text}
                  </div>
                  <div style={{ float: "right" }}>
                    {selectedCell &&
                      record.time === selectedCell.time &&
                      typeCell === "kaigoNaiyou" ?
                      (
                        <Button
                          style={{ fontWeight: "bold" }}
                          size="small"
                          onClick={() => this.showModalDetail(record)}
                        >
                          修正
                        </Button>
                      ) : (
                        <div />
                      )}
                  </div>
                </div>
              );
            },
          },
    ];
    return (
      <div>
        <Radio.Group
          onChange={(value) => this.changeData(value)}
          defaultValue="1"
        >
          <Radio.Button
            style={{ fontWeight: "bold" }}
            className={"btn-change"}
            value="1"
          >
            介護サービス
          </Radio.Button>
          <Radio.Button
            style={{ fontWeight: "bold" }}
            className={"btn-change"}
            value="2"
          >
            主な日常生活活動
          </Radio.Button>
          <Radio.Button
            style={{ fontWeight: "bold" }}
            className={"btn-change"}
            value="3"
          >
            個別詳細内容
          </Radio.Button>
        </Radio.Group>
        <Table
          showHeader={false}
          className="daily-table"
          columns={columns}
          dataSource={dataTable.newDailyPlanDetails || []}
          pagination={false}
          size={"small"}
          rowKey={"id"}
          rowClassName="table_daily_plan"
        />
      </div>
    );
  }

  changeData = (e) => {
    this.setState({
      indexTab: e.target.value,
    });
  };

  renderFooterMobile() {
    return (
      <Affix offsetBottom={0}>
        <Row className={"submenu fixed-footer"}>
          <Col className={"sm-c-right"}>
            <Link
              to={"/"}
              onClick={this.handleGoBack}
              style={{ color: "white", textAlign: "center", float: "right" }}
            >
              <span style={{ display: "block", fontWeight: "bold" }}>
                <UndoOutlined />
              </span>
              <span>{"戻る"}</span>
            </Link>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSave()}
            >
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span
                  style={{ display: "block", fontWeight: "bold", color: "red" }}
                >
                  <SaveOutlined />
                </span>
                <span>{"登録"}</span>
              </span>
            </div>
            {this.props.sisetuDailyPlanId !== "new" ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => this.handleSave(true)}
              >
                <span
                  style={{
                    color: "white",
                    textAlign: "center",
                    float: "right",
                    paddingRight: "10px",
                  }}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    <MinusSquareOutlined />
                  </span>
                  <span>{"閲覧"}</span>
                </span>
              </div>
            ) : null}
          </Col>
        </Row>
      </Affix>
    );
  }

  render() {
    // const { sisetuDailyPlan, notifyDisplay, notifyContent } = this.props;
    const { inputVisible, listName } = this.state;
    return (
      (<div className="back">
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <Spin
                  spinning={
                    this.props.sisetuDailyPlanId === "new"
                      ? false
                      : this.props.loading
                  }
                >
                  {this.renderHeader()}
                  <div style={{ maxWidth: "1046px", margin: "0px auto" }} className="main">
                    {this.renderAboveTable()}
                    {this.renderTable()}
                    {this.renderBottomTable()}
                  </div>
                </Spin>
              );
            }
            return (
              <Spin spinning={this.props.loading}>
                {this.renderHeaderMobile()}
                <div style={{ margin: "5px 10px" }}>
                  {this.renderAboveTableMobile()}
                  {this.renderTableMobile()}
                  {this.renderBottomTable()}
                </div>
                {this.renderFooterMobile()}
              </Spin>
            );
          }}
        </MediaQuery>
        <KaigoNaiyouModal
          hideModalKaigo={() => this.hideModalKaigo()}
          visible={this.state.visible}
          timeRow={this.state.timeKaigo}
          dataDetail={this.state.kaigoData}
          dataNaiyouModal={(data) => this.saveNaiyouModal(data)}
        />
        <YougoModal
          visible={inputVisible}
          onCancel={() => this.handleYougoCancel()}
          onSubmit={(values) => this.handleYougoOk(values)}
          listName={listName}
          typeYougo={this.state.typeYougo}
          data={this.state.dataYougo}
        />
        <LastQuoteModal
          visible={this.state.lastQuoteVisible}
          title={"次の中から選択してください"}
          handleSelectLastQuote={(data) => this.handleSelectLastQuote(data)}
          hideModalLastQuote={() => this.hideModalLastQuote()}
          sisetuDailyPlanTemplates={this.props.sisetuDailyPlanTemplates}
          tempSelected={this.state.tempSelected}
        />
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
        <Modal
          title="テンプレート名を入力してください"
          open={this.state.visibleSave}
          onOk={this.handleSaveRecordOk}
          onCancel={this.handleSaveRecordCancel}
        >
          <div>
            <Input
              onChange={(e) => this.handleSaveTempName(e)}
              style={this.state.validateTempName ? { borderColor: "red" } : {}}
              value={this.state.tempName}
            />
            {this.state.validateTempName ? (
              <div style={{ color: "red" }}>{this.state.validateText}</div>
            ) : (
              <div />
            )}
          </div>
        </Modal>
      </div>)
    );
  }
}

const KyotakuDailyPlanInputFormWrapper = Form.create({
  name: "input-kyotaku-daily-plan",
})(InputDailyPlan);

const mapStateToProps = (state) => {
  return {
    documentList: state.documentList.dataDailyPlan,
    sisetuDailyPlan: state.sisetuDailyPlan.sisetuDailyPlan,
    kaigoNaiyou: state.sisetuDailyPlan.kaigoNaiyou,
    sisetuDailyPlanTemplates: state.sisetuDailyPlan.sisetuDailyPlanTemplates,
    notifyDisplay: state.sisetuDailyPlan.notifyDisplay,
    notifyContent: state.sisetuDailyPlan.notifyContent,
    yougoMasterNaiyou: state.yougoMasterNaiyou.yougoMasterNaiyou,
    seikatuNaiyou: state.sisetuDailyPlan.seikatuNaiyou,
    loading: state.sisetuDailyPlan.loading,
    status: state.sisetuDailyPlan.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSisetuDailyPlan: (id) => dispatch(getSisetuDailyPlan(id)),
    saveSisetuDailyPlan: (data, riyousyaId, planId, callback) =>
      dispatch(saveSisetuDailyPlan(data, riyousyaId, planId, callback)),
    getKaigoNaiyou: (id) => dispatch(getKaigoNaiyou(id)),
    getSeikatuNaiyou: () => dispatch(getSeikatuNaiyou()),
    getYougoMaster: (listName) =>
      dispatch(getKyotakuPlan2YougoMaster(listName)),
    yougoMasterEditState: (index) =>
      dispatch(yougoMasterEditStateCreator(index)),
    yougoMasterEditCancel: (index) =>
      dispatch(yougoMasterEditCancelCreator(index)),
    yougoMasterEditSave: (naiyou) =>
      dispatch(yougoMasterEditSaveCreator(naiyou)),
    getKaigoServiceTemplateName: () => dispatch(getKaigoServiceTemplateName()),
    resetStatus: () => dispatch(resetStatus()),
    getKaigoServiceTemplate: (templateId, callback) =>
      dispatch(getKaigoServiceTemplate(templateId, callback)),
    saveKaigoServiceTemplate: (args) =>
      dispatch(saveKaigoServiceTemplate(args)),
    saveActTemplate: (args) => dispatch(saveActTemplate(args)),
    getActTemplate: (callback) => dispatch(getActTemplate(callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KyotakuDailyPlanInputFormWrapper);
