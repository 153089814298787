
const SECRET_KEY = "sEcReTkEy";
const NODE_ENV = process.env.NODE_ENV;

/**
 * Store object to local storage
 * @param {string} key
 * @param {any} value
 *
 * @example
 * key: string = 'authlife'
 * value: {
 *   isUserLoggedIn: true,
 *   accessToken: '123',
 *   refreshToken: '456'
 * }
 * setValue(key, value)
 *
 * result: check browser local storage
 *
 */
function setValue(key, value) {
  try {
    // const encryptedValue = NODE_ENV !== "production"
    //   ? JSON.stringify(value)
    //   : CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
    localStorage.setItem(key, JSON.stringify(value));
  }
  catch (error) {
    // do something
  }
}

/**
 * Get value by key from local storage
 * @param {string} key
 * @returns {any}
 *
 * @example
 * key: string = 'authlife'
 * getValue(key)
 *
 * return {
 *   isUserLoggedIn: true,
 *   accessToken: '123',
 *   refreshToken: '456'
 * }
 *
 */
function getValue(key) {
  try {
    const encryptedValue = localStorage.getItem(key) || "";
    return JSON.parse(encryptedValue) || null;
    // if (NODE_ENV !== "production") {
    //   return JSON.parse(encryptedValue) || null;
    // }

    // const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
    // const value = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // return value;
  }
  catch (_) {
    return null;
  }
}

/**
 * Store object to local storage
 * @param {object} data
 *
 * @example
 * data: object = {
 *   authlife: {
 *     isUserLoggedIn: true,
 *     accessToken: '123',
 *     refreshToken: '456'
 *   },
 *   setting: {
 *     languge: 'en'
 *   }
 * }
 * setValues(data)
 *
 * result: check browser local storage
 *
 */
function setValues(data) {
  for (const key in data) {
    const value = data[key];
    setValue(key, value);
  }
}

/**
 * Remove value by key from local storage
 * @param {string} key
 *
 * @example
 * key: string = 'authlife'
 * removeValue(key)
 *
 * result: check browser local storage
 *
 */
function removeValue(key) {
  localStorage.removeItem(key);
}

/**
 * Remove value by key from local storage
 * @param {string[]} keys
 *
 * @example
 * keys: string[] = ['authlife', 'setting']
 * removeValues(keys)
 *
 * result: check browser local storage
 *
 */
function removeValues(keys) {
  for (const key of keys) {
    localStorage.removeItem(key);
  }
}

export const StorageUtils = {
  setValue,
  getValue,
  setValues,
  removeValue,
  removeValues,
};
