import { Row, notification } from "antd";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import TabRecords from "../../common/TabRecords/TabRecords";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import MenuRecord from "../../common/component/UI/MenuRecordV2";
import { ConstSet } from "../../common/configs/constset";
import {
  getUrlVars,
  getValueIskeep,
  getValueLocalstorage,
  reportParams,
  setValueIsKeep,
} from "../../common/function_common/functionCommon";
import jsonLocal from "../../data/jsonLocal";
import AdvancedFilter from "./UI/Filter/Advanced";
import SimpleFilter from "./UI/Filter/Simple";
import Fukuyaku from "./UI/Fukuyaku";
import Haisetu from "./UI/Haisetu";
import Kaigonaiyou from "./UI/Kaigonaiyou";
import Meal from "./UI/Meal";
import Nyuyoku from "./UI/Nyuyoku";
import Riyousya from "./UI/Riyousya";
import Suibun from "./UI/Suibun";
import Tokki from "./UI/Tokki";
import Vital from "./UI/Vital";

import "./Report.css";
import Gazou from "./UI/Gazou";

class Report extends Component {
  constructor(props) {
    super(props);

    const SetTimeout = getValueLocalstorage("sessionTimeout");
    const tabActive = localStorage.getItem("tabActive")
      ? localStorage.getItem("tabActive")
      : "利用者";

    localStorage.setItem("tabActive", tabActive);

    const preFilter = JSON.parse(localStorage.getItem("is_keep"));
    if (preFilter === null || preFilter === undefined) {
      localStorage.setItem("is_keep", JSON.stringify(jsonLocal));
    }

    const today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

    // setting display event_plan and riyousya_plan
    const isDisEventPlan = getValueIskeep("is_dis_event_plan");

    const mealType = getValueIskeep("mealType");
    const haisetuKubun = getValueLocalstorage("haisetuType");
    const kaigoNaiyouCode = getValueIskeep("kiroku_kaigo_naiyou");
    const gender = getValueIskeep("gender");

    this.state = {
      tid: getValueLocalstorage("tid"),
      socket: this.props.socket,
      visible: false,
      sesstionTimeout: false,
      SetTimeout: SetTimeout ? SetTimeout : 0,

      modalVisible: false,
      updateModalVisible: false,
      expandForm: false, // @TODO: pro is false
      selectedRows: [],
      formValues: {},
      stepFormValues: {},
      TableStyle: false,
      displayChecked: false,
      nyutaishoDate: localStorage.getItem("nyuDate")
        ? localStorage.getItem("nyuDate")
        : date,
      sortState: [],
      tabActive: tabActive ? tabActive : "利用者",

      // @TODO refactoring
      jikantaiS: getValueIskeep("jikantai")
        ? getValueIskeep("jikantai")
        : "全表示",
      jikantaiSb: getValueIskeep("jikantaiSb")
        ? getValueIskeep("jikantaiSb")
        : "全表示",
      jikantaiH: getValueIskeep("jikantaiH")
        ? getValueIskeep("jikantaiH")
        : "全表示",
      jikantaiN: getValueIskeep("jikantaiN")
        ? getValueIskeep("jikantaiN")
        : "全表示",
      jikantaiF: getValueIskeep("jikantaiF")
        ? getValueIskeep("jikantaiF")
        : "全表示",
      jikantaiK: getValueIskeep("jikantaiK")
        ? getValueIskeep("jikantaiK")
        : "全表示",
      jikantaiT: getValueIskeep("jikantaiT")
        ? getValueIskeep("jikantaiT")
        : "全表示",
      jikantaiG: getValueIskeep("jikantaiG")
        ? getValueIskeep("jikantaiG")
        : "全表示",

      filter: JSON.parse(localStorage.getItem("is_keep")), // filter data that is saved in localstorate
      mealType: mealType ? mealType : "全表示",
      haisetuKubun: haisetuKubun ? haisetuKubun : "両方",
      gender: gender ? gender : "両方",
      kaigoNaiyouCode: kaigoNaiyouCode ? kaigoNaiyouCode : "全表示",
      inputMode: false, // @TOTO pro is false
      mulInputAllMode: false,
      mulInputMode: false,
      mulInputModal: false,
      mulYoteiMode: false,
      mulDelMode: false,

      messageApi: false,

      disabledIkkatsuBtn: true, // when click on 一括入力, to active button user have to choice 利用者,
      updateMode: null, // insert = 1, update = 2, delete = 3

      // setting display event_plan and riyousya_plan. if true is all display, if false only riyoysya is display
      isDisEventPlan: !!isDisEventPlan,

      isDisLongText: false,

      countType: 0,
    };
  }

  /**
   * Load for first Time
   */
  componentDidMount() {
    // Setting page befor redering DOM
    const {
      nyutaishoDate,
      tabActive,
      mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    } = this.state;
    // prepare parameter for load data
    const objState = {
      isLoadBasic: false,
      nyutaishoDate,
      tabName: tabActive,
      startDate: nyutaishoDate,
      endDate: nyutaishoDate,
      shubetu: mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    };

    // Update Menu
    const preMenu = JSON.parse(localStorage.getItem("menu"));
    if (getUrlVars(window.location.href)["sn"]) {
      preMenu.sn = getUrlVars(window.location.href)["sn"];
    }
    if (getUrlVars(window.location.href)["si"]) {
      preMenu.si = getUrlVars(window.location.href)["si"];
    }
    localStorage.setItem("menu", JSON.stringify(preMenu));

    // Load setting data
    this.props.nyutaishoFilter(tabActive); // load filter data

    /**
     * clear riyousyaIds saved on localstorage to reload data
     *
     */
    localStorage.removeItem("riyousyaIds");
    this.props.loadData(reportParams(objState));
    this.props.getHaibenDay();
    // end

    // load tanto list
    this.props.tantoMasterList();

    // Load comment default
    const tabName = localStorage.getItem("tabActive");
    if (tabName) {
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          this.props.yougoMasterNaiyou("バイタルコメント");
          this.props.loadEctSelectData(
            JSON.parse(localStorage.getItem("menu")).si
          );
          break;

        case ConstSet.KIROKU_MEAL:
          this.props.yougoMasterNaiyou("食事コメント");
          break;

        case ConstSet.KIROKU_SUIBUN:
          this.props.yougoMasterNaiyou("水分コメント");
          this.props.yougoMasterSyurui("水分種類");
          break;

        case ConstSet.KIROKU_HAISETU:
          this.props.yougoMasterNaiyou("排泄コメント");
          this.props.yougoMasterSyurui("排泄種類");
          this.props.yougoMasterJoutai("排泄状態");
          break;

        case ConstSet.KIROKU_NYUYOKU:
          this.props.yougoMasterNaiyou("入浴コメント");
          this.props.yougoMasterSyurui("入浴対応");
          break;

        case ConstSet.KIROKU_FUKUYAKU:
          this.props.yougoMasterNaiyou("服薬コメント");
          this.props.loadDrugMaster("", "");
          break;

        case ConstSet.KIROKU_KAIGO:
          this.props.yougoMasterNaiyou("介護記録コメント");
          break;

        case ConstSet.KIROKU_TOKKI:
          this.props.yougoMasterNaiyou("その他コメント");
          break;

        case ConstSet.KIROKU_FILE:
          this.props.yougoMasterNaiyou("画像コメント");
          break;

        default:
          break;
      }
    }
  }

  /**
   * Load when action ある
   */
  componentDidUpdate(prevProps) {
    // Check When Action Complie
    const {
      notifyDisplay,
      notifyContent,
      riyousyaTab,
      baitaruTab,
      shokujiTab,
      suibunTab,
      haisetuTab,
      nyutaishoTab,
      fukuyakuTab,
      kaigonaiyouTab,
      tokkiTab,
      gazouTab,
    } = this.props;
    const {
      mulInputAllMode,
      nyutaishoDate,
      tabActive,
      mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    } = this.state;

    // case update vitalId when isert value to DOM
    if (prevProps.notifyDisplay !== notifyDisplay && !mulInputAllMode) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
    // データ更新される度に表示件数を更新
    if (
      this.props?.riyousyaTab &&
      this.props?.riyousyaTab !== prevProps.riyousyaTab
    ) {
      this.setState({ countType: riyousyaTab.length });
    }
    if (
      this.props?.baitaruTab &&
      this.props?.baitaruTab !== prevProps.baitaruTab
    ) {
      this.setState({ countType: baitaruTab.length });
    }
    if (
      this.props?.shokujiTab &&
      this.props?.shokujiTab !== prevProps.shokujiTab
    ) {
      this.setState({ countType: shokujiTab.length });
    }
    if (this.props?.suibunTab && this.props?.suibunTab !== prevProps.suibunTab) {
      this.setState({ countType: suibunTab.length });
    }
    if (
      this.props?.haisetuTab &&
      this.props?.haisetuTab !== prevProps.haisetuTab
    ) {
      this.setState({ countType: haisetuTab.length });
    }
    if (
      this.props?.nyutaishoTab &&
      this.props?.nyutaishoTab !== prevProps.nyutaishoTab
    ) {
      this.setState({ countType: nyutaishoTab.length });
    }
    if (
      this.props?.fukuyakuTab &&
      this.props?.fukuyakuTab !== prevProps.fukuyakuTab
    ) {
      this.setState({ countType: fukuyakuTab.length });
    }
    if (
      this.props?.kaigonaiyouTab &&
      this.props?.kaigonaiyouTab !== prevProps.kaigonaiyouTab
    ) {
      this.setState({ countType: kaigonaiyouTab.length });
    }
    if (this.props?.tokkiTab && this.props?.tokkiTab !== prevProps.tokkiTab) {
      this.setState({ countType: tokkiTab.length });
    }
    if (this.props?.gazouTab && this.props?.gazouTab !== prevProps.gazouTab) {
      this.setState({ countType: gazouTab.length });
    }

    // to detect url query params update and backbutton
    if (this.props.location.search !== prevProps.location.search) {
      // prepare parameter for load data
      const objState = {
        isLoadBasic: false,
        nyutaishoDate,
        tabName: tabActive,
        startDate: nyutaishoDate,
        endDate: nyutaishoDate,
        shubetu: mealType,
        haisetuKubun,
        gender,
        kaigoNaiyouCode,
      };
      // @TODO Save to localstorage

      this.props.loadData(reportParams(objState));
    }
  }

  // UNSAFE_componentWillReceiveProps(newProps) {
  // }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  /**
   * Double login or Timeout session
   */
  handleOk = () => {
    // Close Popup double login
    this.setState({
      visible: false,
    });
  };

  /**
   * Filter place expand function
   */
  handleExpandForm = (expandForm) => {
    this.setState({
      expandForm,
    });
  };

  /**
   *
   * @param {*} check
   */
  getHandleTableView = (check) => {
    this.setState({
      TableStyle: check,
      displayChecked: check,
    });
  };

  setDisplayEventPlan = () => {
    const { isDisEventPlan } = this.state;

    setValueIsKeep("is_dis_event_plan", !isDisEventPlan);

    this.setState({
      isDisEventPlan: !isDisEventPlan,
    });
  };

  /**
   *
   * @param {*} nyutaishoDate
   */
  getHandleDateChange = (date) => {
    const { tabActive, mealType, haisetuKubun, gender, kaigoNaiyouCode } =
      this.state;
    // prepare parameter for load data
    const objState = {
      isLoadBasic: true,
      nyutaishoDate: date,
      tabName: tabActive,
      startDate: date,
      endDate: date,
      shubetu: mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    };

    this.props.loadData(reportParams(objState, "date")); // Load data

    if (
      this.state.zenkaiMode &&
      localStorage.getItem("tabActive") === "バイタル"
    ) {
      this.props.loadZenkai(date);
    }

    localStorage.setItem("nyuDate", date);
    this.setState({
      nyutaishoDate: date,
    });
  };

  /**
   *
   * @param {*} time
   */
  getHandleTimeChange = (time) => {
    const parameterObj = {
      isLoadBasic: true,
      nyutaishoDate: this.state.nyutaishoDate,
      tabName: this.state.tabActive,
      startDate: this.state.nyutaishoDate,
      endDate: this.state.nyutaishoDate,
      shubetu: this.state.mealType,
      haisetuKubun: this.state.haisetuKubun,
      gender: this.state.gender,
    };

    switch (this.state.tabActive) {
      case "バイタル":
        if (time && time !== "全表示") {
          parameterObj.startTime = time.split("~")[0];
          parameterObj.endTime = time.split("~")[1];
        }

        this.setState({
          jikantaiS: time,
        });
        setValueIsKeep("jikantai", time);

        break;

      case "水分摂取":
        if (time && time !== "全表示") {
          parameterObj.startTimeSb = time.split("~")[0];
          parameterObj.endTimeSb = time.split("~")[1];
        }

        this.setState({
          jikantaiSb: time,
        });
        setValueIsKeep("jikantaiSb", time);
        break;
      case "排泄":
        if (time && time !== "全表示") {
          parameterObj.startTimeH = time.split("~")[0];
          parameterObj.endTimeH = time.split("~")[1];
        }

        this.setState({
          jikantaiH: time,
        });
        setValueIsKeep("jikantaiH", time);
        break;
      case "入浴":
        if (time && time !== "全表示") {
          parameterObj.startTimeN = time.split("~")[0];
          parameterObj.endTimeN = time.split("~")[1];
        }

        this.setState({
          jikantaiN: time,
        });
        setValueIsKeep("jikantaiN", time);
        break;
      case "服薬":
        if (time && time !== "全表示") {
          parameterObj.startTimeF = time.split("~")[0];
          parameterObj.endTimeF = time.split("~")[1];
        }

        this.setState({
          jikantaiF: time,
        });
        setValueIsKeep("jikantaiF", time);
        break;
      case "提供記録":
        if (time && time !== "全表示") {
          parameterObj.startTimeK = time.split("~")[0];
          parameterObj.endTimeK = time.split("~")[1];
        }

        this.setState({
          jikantaiK: time,
        });
        setValueIsKeep("jikantaiK", time);
        break;
      case "特記":
        if (time && time !== "全表示") {
          parameterObj.startTimeT = time.split("~")[0];
          parameterObj.endTimeT = time.split("~")[1];
        }

        this.setState({
          jikantaiT: time,
        });
        setValueIsKeep("jikantaiT", time);
        break;

      case "画像等":
        if (time && time !== "全表示") {
          parameterObj.startTimeG = time.split("~")[0];
          parameterObj.endTimeG = time.split("~")[1];
        }

        this.setState({
          jikantaiG: time,
        });
        setValueIsKeep("jikantaiG", time);
        break;

      default:
        break;
    }

    this.props.loadData(parameterObj); // Load data
  };

  /**
   *
   * @param {*} name
   */
  getHandleSearchByName = () => {
    const {
      nyutaishoDate,
      tabActive,
      mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    } = this.state;
    // prepare parameter for load data
    const objState = {
      isLoadBasic: false,
      nyutaishoDate,
      tabName: tabActive,
      startDate: nyutaishoDate,
      endDate: nyutaishoDate,
      shubetu: mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    };

    this.props.searchByName(
      this.state.filter.searchName,
      reportParams(objState)
    );
  };

  /**
   *
   * @param {*} filterData
   */
  getHandleSort = () => {
    const {
      nyutaishoDate,
      tabActive,
      mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    } = this.state;
    // prepare parameter for load data
    const objState = {
      isLoadBasic: false,
      nyutaishoDate,
      tabName: tabActive,
      startDate: nyutaishoDate,
      endDate: nyutaishoDate,
      shubetu: mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    };

    this.props.sort(this.state.filter, reportParams(objState));
  };

  getHandleRefresh = () => {
    this.setState({
      sortState: [],
    });

    const {
      nyutaishoDate,
      tabActive,
      mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    } = this.state;
    // prepare parameter for load data
    const objState = {
      isLoadBasic: false,
      nyutaishoDate,
      tabName: tabActive,
      startDate: nyutaishoDate,
      endDate: nyutaishoDate,
      shubetu: mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    };

    this.props.refresh(reportParams(objState));
  };

  /**
   * Search handle
   * @param {*} filterData
   */
  getHandleState = (filterData) => {
    if (!filterData.search.checkbox1) {
      filterData.search.field10 = null;
      filterData.search.field11 = null;
    }
    if (!filterData.search.checkbox2) {
      filterData.search.field2 = null;
    }
    if (!filterData.search.checkbox3) {
      filterData.search.field3 = null;
    }

    this.setState({
      filter: filterData,
    });
  };

  getHandleSearch = () => {
    const {
      nyutaishoDate,
      tabActive,
      mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    } = this.state;
    // prepare parameter for load data
    const objState = {
      isLoadBasic: false,
      nyutaishoDate,
      tabName: tabActive,
      startDate: nyutaishoDate,
      endDate: nyutaishoDate,
      shubetu: mealType,
      haisetuKubun,
      gender,
      kaigoNaiyouCode,
    };

    this.props.nyutaishoSearch(this.state.filter, reportParams(objState));
  };

  getHandleMealType = (value) => {
    this.setState({
      mealType: value,
    });

    /**
     * バイタルのデータを習得
     *
     * @todo remove. meal tab dont use time like paramenters
     */
    let startTime = "00:00";
    let endTime = "23:59";
    if (getValueIskeep("jikantai") && getValueIskeep("jikantai") !== "全表示") {
      startTime = getValueIskeep("jikantai").split("~")[0];
      endTime = getValueIskeep("jikantai").split("~")[1];
    }

    const parameterObj = {
      isLoadBasic: false,
      nyutaishoDate: this.state.nyutaishoDate,
      tabName: this.state.tabActive,
      startDate: this.state.nyutaishoDate,
      endDate: this.state.nyutaishoDate,
      startTime,
      endTime,
      shubetu: value,
      haisetuKubun: this.state.haisetuKubun,
      gender: this.state.gender,
    };

    this.props.loadData(parameterObj);
  };

  /**
   *
   * @param {*} value
   */
  getHandleHaisetuKubun = (value) => {
    this.setState({
      haisetuKubun: value,
    });

    const parameterObj = {
      isLoadBasic: false,
      nyutaishoDate: this.state.nyutaishoDate,
      tabName: this.state.tabActive,
      startDate: this.state.nyutaishoDate,
      endDate: this.state.nyutaishoDate,
      shubetu: this.state.mealType,
      haisetuKubun: value,
      gender: this.state.gender,
    };

    /**
     * バイタルのデータを習得
     */
    let startTimeH = "00:00";
    let endTimeH = "23:59";
    if (this.state.jikantaiH && this.state.jikantaiH !== "全表示") {
      startTimeH = this.state.jikantaiH.split("~")[0];
      endTimeH = this.state.jikantaiH.split("~")[1];
    }

    parameterObj.startTimeH = startTimeH;
    parameterObj.endTimeH = endTimeH;

    this.props.loadData(parameterObj);
  };

  getHandleTeikyou = (value) => {
    // Save to localstorage Search Condition
    setValueIsKeep(ConstSet.LS_PN_KIROKU_KAIGO_NAIYOU, value);

    // // Save to local storage
    // const { reportFilterData } = this.props;
    // // kaigonaiyoucode convert
    // let kaigoNaiyouCode = null;
    // if (reportFilterData && reportFilterData.teikyou) {
    //   let objectSettingNaiyou = reportFilterData.teikyou.find(item => item.kaigoNaiyou === value);

    //   if (objectSettingNaiyou && objectSettingNaiyou.kaigoNaiyouCode) {
    //     kaigoNaiyouCode = objectSettingNaiyou.kaigoNaiyouCode;
    //   } else {
    //     if (value !== "全表示") {
    //       kaigoNaiyouCode = "000"; // hardcode with kaigoNaiyou exsited in settingMaster but dont existed in report_kaigo
    //     }
    //   }
    // }

    const parameterObj = {
      isLoadBasic: false,
      nyutaishoDate: this.state.nyutaishoDate,
      tabName: this.state.tabActive,
      startDate: this.state.nyutaishoDate,
      endDate: this.state.nyutaishoDate,
      shubetu: this.state.mealType,
      kaigoNaiyouCode: value && value === "全表示" ? "" : value,
      gender: this.state.gender,
    };

    /**
     * バイタルのデータを習得
     */
    let startTimeK = "00:00";
    let endTimeK = "23:59";
    if (this.state.jikantaiK && this.state.jikantaiK !== "全表示") {
      startTimeK = this.state.jikantaiK.split("~")[0];
      endTimeK = this.state.jikantaiK.split("~")[1];
    }

    parameterObj.startTimeK = startTimeK;
    parameterObj.endTimeK = endTimeK;

    const preParedata = JSON.parse(localStorage.getItem("is_keep"));

    this.setState({
      value,
      kaigoNaiyouCode: value,
      filter: preParedata,
    });

    this.props.loadData(parameterObj);
  };

  /**
   *
   * @param {*} value
   */
  getHandleGender = (value) => {
    setValueIsKeep("gender", value);
    this.setState({
      gender: value,
    });

    const {
      nyutaishoDate,
      tabActive,
      mealType,
      haisetuKubun,
      kaigoNaiyouCode,
    } = this.state;
    // prepare parameter for load data
    const objState = {
      isLoadBasic: false,
      nyutaishoDate,
      tabName: tabActive,
      startDate: nyutaishoDate,
      endDate: nyutaishoDate,
      shubetu: mealType,
      haisetuKubun,
      gender: value,
      kaigoNaiyouCode,
    };

    this.props.searchByGender(value, reportParams(objState));
  };

  /**
   *
   * @param {*} value
   */
  getInputModeHandleChange = (value) => {
    this.setState({
      inputMode: value,
      mulInputAllMode: false,
      mulInputMode: false,
      mulYoteiMode: false,
      mulDelMode: false,
    });
  };

  /**
   *
   * @param {*} value
   */
  getMulInputModeHandleChange = (value) => {
    this.setState({
      inputMode: false,
      mulInputAllMode: value,
      mulInputMode: false,
      mulYoteiMode: false,
      mulDelMode: false,
    });
  };

  mulYoteiModeChange = (value) => {
    this.setState({
      inputMode: false,
      mulInputMode: false,
      mulYoteiMode: value,
      mulDelMode: false,
    });
  };

  mulDelModeChange = (value) => {
    this.setState({
      inputMode: false,
      mulInputMode: false,
      mulYoteiMode: false,
      mulDelMode: value,
    });
  };

  getHandleMulInputModal = (type) => {
    this.setState({
      mulInputModal: true,
      mulInputMode: type === 1,
      mulYoteiMode: type === 2,
      mulDelMode: type === 3,
    });
  };

  getHandleMulInputModeCancel = () => {
    this.setState({
      mulInputModal: false,
    });
  };

  /**
   *
   * @param {*} baitaruRecord
   */
  getHandleCopyBaitaru = (baitaruRecord) => {
    this.props.copyBaitaru(baitaruRecord);
  };

  /**
   *
   * @param {*} baitaruRecord
   */
  getHandleDelBaitaru = (baitaruRecord) => {
    this.props.delBaitaru(baitaruRecord);
  };

  /**
   *
   * @param {*} mealRecord
   */
  getHandleCopyMeal = (mealRecord) => {
    this.props.copyMeal(mealRecord);
  };

  /**
   *
   * @param {*} mealRecord
   */
  getHandleDelMeal = (mealRecord) => {
    this.props.delMeal(mealRecord);
  };

  /**
   *
   * @param {*} suibunRecord
   */
  getHandleCopySuibun = (suibunRecord) => {
    this.props.copySuibun(suibunRecord);
  };

  /**
   *
   * @param {*} suibunRecord
   */
  getHandleDelSuibun = (suibunRecord) => {
    this.props.delSuibun(suibunRecord);
  };

  /**
   *
   * @param {*} haisetuRecord
   */
  getHandleCopyHaisetu = (haisetuRecord) => {
    this.props.copyHaisetu(haisetuRecord);
  };

  /**
   *
   * @param {*} haisetuRecord
   */
  getHandleDelHaisetu = (haisetuRecord) => {
    this.props.delHaisetu(haisetuRecord);
  };

  /**
   *
   * @param {*} nyuyokuRecord
   */
  getHandleCopyNyuyoku = (nyuyokuRecord) => {
    this.props.copyNyuyoku(nyuyokuRecord);
  };

  /**
   *
   * @param {*} nyuyokuRecord
   */
  getHandleDelNyuyoku = (nyuyokuRecord) => {
    this.props.delNyuyoku(nyuyokuRecord);
  };

  /**
   *
   * @param {*} fukuyakuRecord
   */
  getHandleCopyFukuyaku = (fukuyakuRecord) => {
    this.props.copyFukuyaku(fukuyakuRecord);
  };

  /**
   *
   * @param {*} fukuyakuRecord
   */
  getHandleDelFukuyaku = (fukuyakuRecord) => {
    this.props.delFukuyaku(fukuyakuRecord);
  };

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleCopyKaigonaiyou = (kaigonaiyouRecord) => {
    this.props.copyKaigonaiyou(kaigonaiyouRecord);
  };

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleDelKaigonaiyou = (kaigonaiyouRecord) => {
    this.props.delKaigonaiyou(kaigonaiyouRecord);
  };

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleCopyTokki = (tokkiRecord) => {
    this.props.copyTokki(tokkiRecord);
  };

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleDelTokki = (tokkiRecord) => {
    this.props.delTokki(tokkiRecord);
  };

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleCopyGazo = (gazoRecord) => {
    this.props.copyGazo(gazoRecord);
  };

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleDelGazo = (gazoRecord) => {
    this.props.delGazo(gazoRecord);
  };

  /**
   * // insert = 1. update = 2, delete = 3
   */
  getHandleOkInsertRecord = (params) => {
    this.setState({
      updateMode: 1,
    });

    this.props.insertRecord(params);
  };

  /**
   *
   * @param {*} params
   */
  getHandleOkUpdateRecord = (params) => {
    this.setState({
      updateMode: 2,
    });

    this.props.updateRecord(params);
  };

  getHandleOkInsertRecordMul = (params) => {
    this.setState({
      updateMode: 1,
    });

    this.props.insertRecordMul(params);
  };

  getHandleOkUpdateRecordMul = (params) => {
    this.setState({
      updateMode: 2,
    });

    this.props.updateRecordMul(params);
  };

  /**
   *
   */
  getHandleDeleteBaitaru = (vitalId) => {
    this.setState({
      updateMode: 3,
    });

    this.props.deleteRecord(vitalId);
  };

  /**
   *
   */
  getHandleOkInsertGazou = (params, callback) => {
    this.setState({
      updateMode: 1,
    });

    this.props.insertRecord(params, callback);
  };

  /**
   *
   * @param {*} params
   */
  getHandleOkUpdateGazou = (params, callback) => {
    this.setState({
      updateMode: 2,
    });

    this.props.updateRecord(params, callback);
  };

  /**
   *
   */
  getHandleDeleteGazou = (fileManagementId) => {
    this.setState({
      updateMode: 3,
    });

    this.props.deleteRecord(fileManagementId);
  };

  /**
   *
   * handle edit comment default setting
   */
  /**
   *
   * @param {*} index
   */
  getYougoMasterEditState = (index) => {
    this.props.yougoMasterEditState(index);
  };

  /**
   *
   * @param {*} index
   */
  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  /**
   *
   * @param {*} naiyou
   */
  getYougoMasterEditSave = (naiyou) => {
    this.props.yougoMasterEditSave(naiyou);
  };
  // END handle default comment setting

  /**
   *
   * handle edit comment default setting
   */
  /**
   *
   * @param {*} index
   */
  getYougoMasterEctState = (index) => {
    this.props.yougoMasterEctState(index);
  };

  /**
   *
   * @param {*} index
   */
  getYougoMasterEctCancel = (index) => {
    this.props.yougoMasterEctCancel(index);
  };

  /**
   *
   * @param {*} naiyou
   */
  getYougoMasterEctSave = (type, naiyou) => {
    this.props.yougoMasterEctSave(type, naiyou);
  };
  // END handle default comment setting

  /**
   *
   * @param {*} value
   */
  getHandleIkkatsuBtn = (value) => {
    this.setState({
      disabledIkkatsuBtn: value,
    });
  };

  /**
   *
   * @param {*} riyousyaId
   * @param {*} time
   */
  getZendkaiLoad = (riyousyaId, time) => {
    this.props.loadZenkai(localStorage.getItem("nyuDate"), riyousyaId, time);
  };

  toggleOpenLongText = () => {
    const { isDisLongText } = this.state;

    this.setState({
      isDisLongText: !isDisLongText,
    });
  };

  /**
   *
   */
  renderFilterForm = () => {
    const { tabActive, isDisEventPlan, isDisLongText } = this.state;
    const { reportFilterData } = this.props;

    return (
      <MediaQuery query="(min-device-width: 0px)">
        {(matches) => {
          if (matches) {
            // eslint-disable-next-line no-constant-condition
            return true ? (
              <AdvancedFilter
                onExpandForm={this.handleExpandForm}
                handleTableView={this.getHandleTableView}
                displayChecked={this.state.displayChecked}
                handleDateChange={this.getHandleDateChange}
                nyutaishoDate={this.state.nyutaishoDate}
                nyutaishoFilter={reportFilterData}
                searchByName={() => this.getHandleSearchByName()}
                handleRefresh={() => this.getHandleRefresh()}
                tabActive={tabActive}
                jikantaiState={this.getHandleTimeChange}
                jikantai={this.state.jikantaiS}
                jikantaiSb={this.state.jikantaiSb}
                jikantaiH={this.state.jikantaiH}
                jikantaiN={this.state.jikantaiN}
                jikantaiF={this.state.jikantaiF}
                jikantaiK={this.state.jikantaiK}
                jikantaiT={this.state.jikantaiT}
                jikantaiG={this.state.jikantaiG}
                filterData={this.state.filter}
                handleState={(filterData) => this.getHandleState(filterData)}
                nyutaishoSearch={() => this.getHandleSearch()}
                handleSort={() => this.getHandleSort()}
                mealType={this.state.mealType}
                handleMealType={(value) => this.getHandleMealType(value)}
                haisetuKubun={this.state.haisetuKubun}
                handleHaisetuKubun={(value) =>
                  this.getHandleHaisetuKubun(value)
                }
                gender={this.state.gender}
                handleGender={(value) => this.getHandleGender(value)}
                handleTeikyou={(value) => this.getHandleTeikyou(value)}
                inputMode={this.state.inputMode}
                inputModeHandleChange={this.getInputModeHandleChange}
                mulInputAllMode={this.state.mulInputAllMode}
                mulInputMode={this.state.mulInputMode}
                MulInputModeHandleChange={this.getMulInputModeHandleChange}
                mulYoteiMode={this.state.mulYoteiMode}
                mulYoteiModeChange={this.mulYoteiModeChange}
                mulDelMode={this.state.mulDelMode}
                mulDelModeChange={this.mulDelModeChange}
                handleMulInputModal={this.getHandleMulInputModal}
                disabledIkkatsuBtn={this.state.disabledIkkatsuBtn}
                isDisEventPlan={isDisEventPlan}
                setDisplayEventPlan={this.setDisplayEventPlan}
                toggleOpenLongText={this.toggleOpenLongText}
                isDisLongText={isDisLongText}
              />
            ) : (
              <SimpleFilter
                onExpandForm={this.handleExpandForm}
                handleTableView={this.getHandleTableView}
                displayChecked={this.state.displayChecked}
                handleDateChange={this.getHandleDateChange}
                nyutaishoDate={this.state.nyutaishoDate}
                nyutaishoFilter={reportFilterData}
                searchByName={() => this.getHandleSearchByName()}
                handleRefresh={() => this.getHandleRefresh()}
                tabActive={tabActive}
                jikantaiState={this.getHandleTimeChange}
                jikantai={this.state.jikantaiS}
                jikantaiSb={this.state.jikantaiSb}
                jikantaiH={this.state.jikantaiH}
                jikantaiN={this.state.jikantaiN}
                jikantaiF={this.state.jikantaiF}
                jikantaiK={this.state.jikantaiK}
                jikantaiT={this.state.jikantaiT}
                jikantaiG={this.state.jikantaiG}
                filterData={this.state.filter}
                handleState={(filterData) => this.getHandleState(filterData)}
                nyutaishoSearch={() => this.getHandleSearch()}
                handleSort={() => this.getHandleSort()}
                mealType={this.state.mealType}
                handleMealType={(value) => this.getHandleMealType(value)}
                haisetuKubun={this.state.haisetuKubun}
                handleHaisetuKubun={(value) =>
                  this.getHandleHaisetuKubun(value)
                }
                gender={this.state.gender}
                handleGender={(value) => this.getHandleGender(value)}
                handleTeikyou={(value) => this.getHandleTeikyou(value)}
                inputMode={this.state.inputMode}
                inputModeHandleChange={this.getInputModeHandleChange}
                mulInputAllMode={this.state.mulInputAllMode}
                mulInputMode={this.state.mulInputMode}
                MulInputModeHandleChange={this.getMulInputModeHandleChange}
                mulYoteiMode={this.state.mulYoteiMode}
                mulYoteiModeChange={this.mulYoteiModeChange}
                mulDelMode={this.state.mulDelMode}
                mulDelModeChange={this.mulDelModeChange}
                handleMulInputModal={this.getHandleMulInputModal}
                disabledIkkatsuBtn={this.state.disabledIkkatsuBtn}
                toggleOpenLongText={this.toggleOpenLongText}
                isDisLongText={isDisLongText}
              />
            );
          }
          return null;
          /**
             * @TODO Filter mobile develop(next version)
             */

        }}
      </MediaQuery>
    );
  };

  /**
   * Drug search
   */
  getHandleDrugSearch = (bunrui, name) => {
    this.props.loadDrugMaster(bunrui, name);
  };

  /**
   *
   * @param {*} fileId
   * @param {*} fileName
   */
  download = (fileId, fileName) => {
    this.props.download(fileId, fileName);
  };

  /**
   *
   */
  renderTableRecords = () => {
    const { tabActive, updateMode, isDisEventPlan, isDisLongText } = this.state;
    const {
      loading,
      riyousyaTab,
      reportFilterData,
      baitaruTab,
      shokujiTab,
      suibunTab,
      haisetuTab,
      nyutaishoTab,
      fukuyakuTab,
      kaigonaiyouTab,
      tokkiTab,
      gazouTab,
      loadingButtonUpdate,
      tantoMaster,
      yougoMasterNa,
      yougoMasterSy,
      yougoMasterJt,
      zenkai,
      yougoMasterEctSelect1,
      yougoMasterEctSelect2,
      yougoMasterEctSelect3,
      drugList,
      drugLoading,
      syokichi,
    } = this.props;

    if (tabActive === "利用者") {
      return (
        <Riyousya
          history={this.props.history}
          TableStyle={this.state.TableStyle}
          nyutaisho={riyousyaTab}
          loading={loading}
          haiben={this.props.haiben}
          nyutaishoDate={this.state.nyutaishoDate}
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          isDisEventPlan={isDisEventPlan}
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "バイタル") {
      return (
        <Vital
          history={this.props.history}
          // table data
          nyutaisho={baitaruTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Other Comment Setting
          vitalEct={
            reportFilterData && reportFilterData.vitalEct
              ? reportFilterData.vitalEct
              : ""
          }
          vitalEctType={
            reportFilterData && reportFilterData.vitalEctType
              ? reportFilterData.vitalEctType
              : ""
          }
          vitalCol={
            reportFilterData && reportFilterData.vitalCol
              ? reportFilterData.vitalCol.toString().split(";")
              : ""
          }
          // Vital Check Waring High Low Data
          vitalHighLow={
            reportFilterData && reportFilterData.vitalHighLow
              ? reportFilterData.vitalHighLow
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulYoteiMode={this.state.mulYoteiMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          handleDelVital={this.getHandleDelBaitaru} // del from local for copy action
          handleCopyRecord={this.getHandleCopyBaitaru}
          // Load Zenkai Data
          zenkai={zenkai}
          zendkaiLoad={this.getZendkaiLoad}
          // other comment input type. form combox type. data has been load from setting
          yougoMasterEctSelect1={yougoMasterEctSelect1}
          yougoMasterEctSelect2={yougoMasterEctSelect2}
          yougoMasterEctSelect3={yougoMasterEctSelect3}
          yougoMasterEctState={this.getYougoMasterEctState}
          yougoMasterEctCancel={this.getYougoMasterEctCancel}
          yougoMasterEctSave={this.getYougoMasterEctSave}
          updateMode={updateMode}
          isDisLongText={isDisLongText}
          jikantai={this.state.jikantaiS}
        />
      );
    }
    else if (tabActive === "食事摂取") {
      return (
        <Meal
          history={this.props.history}
          // table data
          nyutaisho={shokujiTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulYoteiMode={this.state.mulYoteiMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleDelMeal={this.getHandleDelMeal} // del from local for copy action
          handleCopyMeal={this.getHandleCopyMeal}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          updateMode={updateMode}
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "水分摂取") {
      return (
        <Suibun
          history={this.props.history}
          // table data
          nyutaisho={suibunTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulYoteiMode={this.state.mulYoteiMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopySuibun={this.getHandleCopySuibun}
          handleDelSuibun={this.getHandleDelSuibun}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          yougoMasterSy={yougoMasterSy} // get value for 種類
          updateMode={updateMode}
          isDisLongText={isDisLongText}
          jikantai={this.state.jikantaiSb}
        />
      );
    }
    else if (tabActive === "排泄") {
      return (
        <Haisetu
          history={this.props.history}
          // table data
          nyutaisho={haisetuTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulYoteiMode={this.state.mulYoteiMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyHaisetu={this.getHandleCopyHaisetu}
          handleDelHaisetu={this.getHandleDelHaisetu}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          yougoMasterSy={yougoMasterSy} // get value for naiyouのtab
          yougoMasterJt={yougoMasterJt}
          // syokichi
          syokichiItem={this.syokichiItem}
          syokichiUpdate={this.syokichiUpdate}
          syokichi={syokichi}
          updateMode={updateMode}
          isDisLongText={isDisLongText}
          jikantai={this.state.jikantaiH}
          settingColor={
            reportFilterData && reportFilterData.weeklyColor
              ? reportFilterData.weeklyColor
              : ""
          }
        />
      );
    }
    else if (tabActive === "入浴") {
      return (
        <Nyuyoku
          history={this.props.history}
          // table data
          nyutaisho={nyutaishoTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Update With Multiple Row
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulYoteiMode={this.state.mulYoteiMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyNyuyoku={this.getHandleCopyNyuyoku}
          handleDelNyuyoku={this.getHandleDelNyuyoku}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          yougoMasterSy={yougoMasterSy} // get value for naiyouのtab
          // syokichi
          syokichiItem={this.syokichiItem}
          syokichiUpdate={this.syokichiUpdate}
          syokichi={syokichi}
          updateMode={updateMode}
          isDisLongText={isDisLongText}
          jikantai={this.state.jikantaiN}
        />
      );
    }
    else if (tabActive === "服薬") {
      return (
        <Fukuyaku
          history={this.props.history}
          // table data
          nyutaisho={fukuyakuTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulYoteiMode={this.state.mulYoteiMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyFukuyaku={this.getHandleCopyFukuyaku}
          handleDelFukuyaku={this.getHandleDelFukuyaku}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          yougoMasterSy={yougoMasterSy} // get value for naiyouのtab
          drugList={drugList} // loading list of drug master
          drugLoading={drugLoading}
          yougoDrugBunrui={
            reportFilterData && reportFilterData.yougoDrugBunrui
              ? reportFilterData.yougoDrugBunrui
              : ""
          }
          handleDrugSearch={this.getHandleDrugSearch}
          updateMode={updateMode}
          isDisLongText={isDisLongText}
          jikantai={this.state.jikantaiF}
        />
      );
    }
    else if (tabActive === "提供記録") {
      return (
        <Kaigonaiyou
          history={this.props.history}
          // table data
          nyutaisho={kaigonaiyouTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulYoteiMode={this.state.mulYoteiMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyKaigonaiyou={this.getHandleCopyKaigonaiyou}
          handleDelKaigonaiyou={this.getHandleDelKaigonaiyou}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          // Not Same Tab Setting. Other Info
          teikyouData={
            reportFilterData && reportFilterData.teikyou
              ? reportFilterData.teikyou
              : null
          }
          updateMode={updateMode}
          isDisLongText={isDisLongText}
          jikantai={this.state.jikantaiK}
        />
      );
    }
    else if (tabActive === "特記") {
      return (
        <Tokki
          history={this.props.history}
          // table data
          nyutaisho={tokkiTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputMode={this.state.mulInputMode}
          mulYoteiMode={this.state.mulYoteiMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyTokki={this.getHandleCopyTokki}
          handleDelTokki={this.getHandleDelTokki}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          updateMode={updateMode}
          isDisLongText={isDisLongText}
          jikantai={this.state.jikantaiT}
        />
      );
    }
    else if (tabActive === "画像等") {
      return (
        <Gazou
          history={this.props.history}
          // table data
          nyutaisho={gazouTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // gazou kubun
          gazouKubun={
            reportFilterData && reportFilterData.gazouKubun
              ? reportFilterData.gazouKubun
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputMode={this.state.mulInputMode}
          mulYoteiMode={this.state.mulYoteiMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          gazouUpdate={this.getHandleOkUpdateGazou}
          gazouInsert={this.getHandleOkInsertGazou}
          gazouDelete={this.getHandleDeleteGazou} // del from server
          handleCopyGazo={this.getHandleCopyGazo}
          handleDelGazo={this.getHandleDelGazo}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          download={this.download}
          updateMode={updateMode}
          isDisLongText={isDisLongText}
          jikantai={this.state.jikantaiG}
          //
          loadingFileManagement={this.props.loadingFileManagement}
          created={this.props.created}
          deleteFile={this.props.deleteFile}
          update={this.props.update}
          //
          loadFilePreview={this.props.loadFilePreview}
        />
      );
    }
  };

  /**
   * Submenu
   * @TODO
   *
   * @param {*} tabActive
   */
  getTagDisplay = (tabActive) => {
    this.setState({
      tabActive: !tabActive,
    });
  };

  /**
   * Tabs
   * @param {Active Tab} tabName
   */
  getHandleTabChange = (tabName) => {
    const parameterObj = {
      nyutaishoDate: this.state.nyutaishoDate,
      tabName,
      startDate: this.state.nyutaishoDate,
      endDate: this.state.nyutaishoDate,
      shubetu: this.state.mealType,
      isLoadBasic: false,
      haisetuKubun: this.state.haisetuKubun,
      gender: this.state.gender,
    };

    switch (tabName) {
      case "利用者":
        // reset multiple input mode
        this.setState({
          mulInputMode: false,
        });
        break;

      case "バイタル":
        if (this.state.jikantaiS !== "全表示") {
          parameterObj.startTime = this.state.jikantaiS.split("~")[0];
          parameterObj.endTime = this.state.jikantaiS.split("~")[1];
        }
        this.props.yougoMasterNaiyou("バイタルコメント"); // load comment
        this.props.loadEctSelectData(
          JSON.parse(localStorage.getItem("menu")).si
        ); // load ect select data
        break;

      case ConstSet.KIROKU_MEAL:
        this.props.yougoMasterNaiyou("食事コメント");
        break;

      case "水分摂取":
        this.props.yougoMasterNaiyou("水分コメント");
        this.props.yougoMasterSyurui("水分種類");
        if (this.state.jikantaiSb !== "全表示") {
          parameterObj.startTimeSb = this.state.jikantaiSb.split("~")[0];
          parameterObj.endTimeSb = this.state.jikantaiSb.split("~")[1];
        }
        break;
      case "排泄":
        this.props.yougoMasterNaiyou("排泄コメント");
        this.props.yougoMasterSyurui("排泄種類");
        this.props.yougoMasterJoutai("排泄状態");
        if (this.state.jikantaiH !== "全表示") {
          parameterObj.startTimeH = this.state.jikantaiH.split("~")[0];
          parameterObj.endTimeH = this.state.jikantaiH.split("~")[1];
        }
        break;
      case "入浴":
        this.props.yougoMasterNaiyou("入浴コメント");
        this.props.yougoMasterSyurui("入浴対応");
        if (this.state.jikantaiN !== "全表示") {
          parameterObj.startTimeN = this.state.jikantaiN.split("~")[0];
          parameterObj.endTimeN = this.state.jikantaiN.split("~")[1];
        }
        break;
      case "服薬":
        this.props.yougoMasterNaiyou("服薬コメント");
        this.props.loadDrugMaster("", "");
        if (this.state.jikantaiF !== "全表示") {
          parameterObj.startTimeF = this.state.jikantaiF.split("~")[0];
          parameterObj.endTimeF = this.state.jikantaiF.split("~")[1];
        }
        break;
      case "提供記録":
        this.props.yougoMasterNaiyou("介護記録コメント");
        if (this.state.jikantaiK !== "全表示") {
          parameterObj.startTimeK = this.state.jikantaiK.split("~")[0];
          parameterObj.endTimeK = this.state.jikantaiK.split("~")[1];
        }
        break;
      case "特記":
        this.props.yougoMasterNaiyou("その他コメント");
        if (this.state.jikantaiT !== "全表示") {
          parameterObj.startTimeT = this.state.jikantaiT.split("~")[0];
          parameterObj.endTimeT = this.state.jikantaiT.split("~")[1];
        }

        // reset multiple input mode
        this.setState({
          mulInputMode: false,
        });

        break;

      case "画像等":
        this.props.yougoMasterNaiyou("画像コメント");
        if (this.state.jikantaiG !== "全表示") {
          parameterObj.startTimeG = this.state.jikantaiG.split("~")[0];
          parameterObj.endTimeG = this.state.jikantaiG.split("~")[1];
        }

        // reset multiple input mode
        this.setState({
          mulInputMode: false,
        });

        break;

      default:
        break;
    }

    this.props.loadData(parameterObj);

    if (this.state.zenkaiMode && tabName === "バイタル") {
      this.props.loadZenkai(localStorage.getItem("nyuDate"));
    }

    this.setState({
      tabActive: tabName,
      disabledIkkatsuBtn: true,
    });
  };

  /**
   *
   * @param {*} mainSid
   */
  getLoadEctSelectData = (mainSid) => {
    this.props.loadEctSelectData(mainSid);
  };

  /**
   * get syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   */
  syokichiItem = (riyousyaId, kubun) => {
    this.props.syokichiItem(riyousyaId, kubun);
  };

  /**
   * update syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   * @param {*} syokichi
   */
  syokichiUpdate = (riyousyaId, kubun, syokichi) => {
    this.props.syokichiUpdate(riyousyaId, kubun, syokichi);
  };

  render() {
    const { countType } = this.state;

    return (
      <CommonPageContainer
        id="report-page"
        sessionTimeout={this.props.sessionTimeout}
      >
        <Row className={"submenu"}>
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={"記録一覧（日別）"}
                        iconType="form"
                        count={true}
                        countNum={countType ? countType : 0}
                      />
                    }
                    TopRight2={<MenuRecord isDailyRe={true} />}
                    IncBottom={
                      <TabRecords
                        tabActive={this.state.tabActive}
                        handleTabChange={(tabName) =>
                          this.getHandleTabChange(tabName)
                        }
                      />
                    }
                  />
                );
              }
              /**
                 * @TODO Check
                 *
                 */
              // return <SubHeaderMobile
              //   isDengon={false}
              //   isUser={false}
              //   isDailyRe={true}
              //   title={"記録一覧（日別）"}
              //   getTagDisplay={this.getTagDisplay}
              //   isTagDisPlay={true}
              // />

            }}
          </MediaQuery>
        </Row>

        <div style={{ padding: "8px 16px", display: "block" }}>
          <div className={"styles.tableListForm"}>
            {this.renderFilterForm()}
          </div>
        </div>

        <div style={{ padding: "0px 16px" }}>{this.renderTableRecords()}</div>
      </CommonPageContainer>
    );
  }
}

export default Report;
