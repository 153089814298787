/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Row, Col } from "antd";
import LineBreakText from "../../../common/component/LineBreakText";
import { getPath } from "../../../common/function_common/functionCommon";

// import { connect } from "react-redux";
// import { getIdForcus } from "../../../actions/documentList/actionCreators";

export class RowContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      path: getPath(),
      // mainSid: JSON.parse(localStorage.getItem(ConfigName.SWD_MENU)).si
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.expandedRows.indexOf(this.props.data.id) > -1) {
      this.setState({
        expand: true,
      });
    } else {
      this.setState({
        expand: false,
      });
    }
  }

  /**
   * レコード選択時
   * 状態とレコードをprops関数に渡す
   * @param {*} e
   */
  handleOnClick = (e) => {
    if (e.target.type !== "button") {
      this.props.handleExpand(!this.state.expand, this.props.data);
    }
  };

  goToAdlDetail(riyousyaId, id) {
    this.props.history.push(
      `${this.state.path}/report-adl-view/${riyousyaId}/${id}?back=RiyouAdl`
    );
  }

  render() {
    // const { mainSid } = this.state;
    const { data } = this.props;
    if (this.props.isUserMode) {
      return (
        <div
          // id={this.state.expand ? "record-active-tab5" : "record"} ?後で確認
          className={
            this.state.expand
              ? "report-case-table-row-active"
              : "report-case-table-row"
          }
          onClick={this.handleOnClick}
        >
          {/* 評価日、担当者 */}
          <Row type="flex" justify="center" style={{ marginBottom: 2 }}>
            {/* lg screen ≥ 992px */}
            {/* sm screen ≥ 576px */}
            {/* xs screen < 576px */}
            <Col lg={10} sm={11} xs={24}>
              <span style={{ fontWeight: "bold" }}>{"評価日  "}</span>
              <span>{data.evaluationDate}</span>
            </Col>
            <Col lg={10} sm={11} xs={24}>
              <span style={{ fontWeight: "bold" }}>{"担当者："}</span>
              <span>{data.tantoMasterName}</span>
            </Col>
          </Row>

          {/* 特記 */}
          <Row
            type="flex"
            justify="center"
            style={{ marginBottom: this.state.expand ? 5 : 0 }} //選択時に下に余白を作る
          >
            <Col lg={20} sm={22} xs={24} style={{ display: "flex" }}>
              <span style={{ fontWeight: "bold" }}>
                {"特記"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <div style={{ flex: 1 }}>
                <LineBreakText text={data.tokki} />
              </div>
            </Col>
          </Row>

          <Row>
            {this.state.expand ? (
              //選択時のレコードの拡大(削除,閲覧,修正)
              <div className="w-100">
                <span style={{ float: "left" }}>
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    style={{ color: "white", backgroundColor: "gray" }}
                    disabled={data.id === 0}
                    className={"btn-tab-delete btn-crud"}
                    onClick={() => this.props.showDeleteConfirm(data.id)}
                  >
                    削除
                  </Button>
                </span>
                <span style={{ float: "right" }}>
                  {/* <Link 
                      style={{ color: '#fff', margin: 5 }}
                      to={`/tanto-kaigi-view/${keepValue.riyousyaId}/${keepValue.id}?back=RiyouTantoKaigi`}
                    > */}
                  <Button
                    icon={<LegacyIcon type={"read"} />}
                    disabled={data.id === 0}
                    type={"primary"}
                    className={"btn-crud btn-tab-info"}
                    onClick={() => this.goToAdlDetail(data.riyousyaId, data.id)}
                  >
                    閲覧
                  </Button>
                  {/* </Link> */}

                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    style={{
                      color: "white",
                      backgroundColor: "#dc0606",
                      marginLeft: 3,
                    }}
                    disabled={data.id === 0}
                    onClick={() => this.props.handleInputAdl(data)} //AdlIndex.jsに元の関数
                    className={"btn-tab-edit btn-crud"}
                  >
                    修正
                  </Button>
                </span>
              </div>
            ) : null}
          </Row>
        </div>
      );
    }
  }
}
