import { connect } from "react-redux";

import Plan from "./Plan";
import RiyousyaPlan from "./RiyousyaPlan";
import {
  loadEventPlans,
  createEventPlan,
  updateEventPlan,
  deleteEventPlan,
  loadIndividualPlans,
  createRiyousyaPlan,
  updateRiyousyaPlan,
  deleteRiyousyaPlan,
  loadFilterMasterSetting,
  filterRiyousyaData,
  loadNyutaishoRiyousyaData,
  getFacilityPlanYougoMaster,
  saveFacilityPlanYougoMaster,
  yougoMasterEditStateCreator,
  yougoMasterEditCancelCreator,
} from "../../actions/planActions/actionCreators";
import { getRiyousya } from "../../actions/riyousyaActions/actionCreators";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    notifyDisplay: state.plan.notifyDisplay,
    notifyContent: state.plan.notifyContent,
    filterMasterData: state.plan.filterMaster,
    filteredIndividualPlans: state.plan.filteredIndividualPlans,
    filterConditions: state.plan.filterConditions,
    yougoMasterNaiyou: state.plan.yougoMasterNaiyou,

    individualPlans: state.plan.individualPlans,
    eventPlans: state.plan.eventPlans,
    riyousya: state.riyousya.riyousya,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getFilterMasterData: () => dispatch(loadFilterMasterSetting()),
    getNyutaishoData: (start, end) =>
      dispatch(loadNyutaishoRiyousyaData(start, end)),
    filterNyutaishoData: (searchData) =>
      dispatch(filterRiyousyaData(searchData)),
    getyougoMasterNaiyou: () => dispatch(getFacilityPlanYougoMaster()),
    yougoMasterEditState: (index) =>
      dispatch(yougoMasterEditStateCreator(index)),
    yougoMasterEditCancel: (index) =>
      dispatch(yougoMasterEditCancelCreator(index)),
    yougoMasterEditSave: (naiyou) =>
      dispatch(saveFacilityPlanYougoMaster(naiyou)),
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),

    getIndividualPlans: (riyousyaIds, startDate, endDate) =>
      dispatch(loadIndividualPlans(riyousyaIds, startDate, endDate)),
    createIndividualPlan: (riyousyaId, date, yotei, sonota, callback) =>
      dispatch(createRiyousyaPlan(riyousyaId, date, yotei, sonota, callback)),
    updateIndividualPlan: (
      riyousyaPlanId,
      riyousyaId,
      date,
      yotei,
      sonota,
      callback
    ) =>
      dispatch(
        updateRiyousyaPlan(
          riyousyaPlanId,
          riyousyaId,
          date,
          yotei,
          sonota,
          callback
        )
      ),
    deleteIndividualPlan: (riyousyaPlanId, callback) =>
      dispatch(deleteRiyousyaPlan(riyousyaPlanId, callback)),
    getEventPlans: (startDate, endDate) =>
      dispatch(loadEventPlans(startDate, endDate)),
    createEventPlan: (date, event, callback) =>
      dispatch(createEventPlan(date, event, callback)),
    updateEventPlan: (eventPlanId, date, event, callback) =>
      dispatch(updateEventPlan(eventPlanId, date, event, callback)),
    deleteEventPlan: (eventPlanId, callback) =>
      dispatch(deleteEventPlan(eventPlanId, callback)),
  };
};

export const PlanContainer = connect(mapStateToProps, mapDispatchToProps)(Plan);
export const RiyousyaPlanContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RiyousyaPlan);
