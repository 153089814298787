/* eslint-disable indent */
/* eslint-disable brace-style */
/* eslint-disable no-undef */
import { Button, Modal } from "antd";
import React, { Suspense, memo, useEffect, useState } from "react";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import Header from "../components/Header";
import { AuthActions } from "../redux/actions/index";
import {
  initializeSocket,
  removeSocket,
} from "../reuse/actions/commonActions/actionCreators";
import getAvatar from "../reuse/actions/getAvatarActions/actionCreators";

const PrivateLayout = ({
  component: Component,
  isUserLoggedIn,
  sessionTimeout,
  onSignout,
  socket,
  preventLogin,
  getAvatarResponse,
  getAvatar,
  onRemoveSocket,
  onInitializeSocket,
  jwtTokenType,
  isKanriPage,
  ...other
}) => {
  const [visible, setVisible] = useState(false);

  async function handleOnIdle() {
    if (isUserLoggedIn && jwtTokenType != "SuperAdmin") {
      setVisible(true);
    }
  }

  const signOut = () => {
    setVisible(false);
    onSignout();
    onRemoveSocket();
  };

  useEffect(() => {
    getAvatar();
  }, []);

  useEffect(() => {
    if (preventLogin) {
      setVisible(true);
    }
  }, [preventLogin]);

  useEffect(() => {
    if (!socket) {
      onInitializeSocket();
    }
  }, [socket]);

  let getAvatara = false;
  let getAvatarResponseData = "";
  if (getAvatarResponse) {
    if (getAvatarResponse.data) {
      getAvatara = true;
      getAvatarResponseData = getAvatarResponse && getAvatarResponse.data;
    } else if (
      getAvatarResponse.response &&
      getAvatarResponse.response.data &&
      getAvatarResponse.response.data.error &&
      getAvatarResponse.response.data.error.code === "INVALID_TOKEN"
    ) {
      window.location.href = "/life/login";
    }
  }
  return (
    (<div>
      <Route
        {...other}
        render={(props) =>
          isUserLoggedIn ? (
            <div className="private-layout">
              <IdleTimer
                onIdle={handleOnIdle}
                element={document}
                timeout={Number(sessionTimeout)}
              >
                {!isKanriPage ? (
                  <Header
                    avatar={getAvatarResponseData}
                    history={props.history}
                    getAvatarResponse={getAvatara}
                  />
                ) : null}
                <Suspense fallback={null}>
                  <main>
                    <Component {...props} />
                  </main>
                </Suspense>
              </IdleTimer>
            </div>
          ) : (
            <Redirect to="/life/login" />
          )
        }
      />
      <Modal
        open={visible}
        closable={false}
        title="エラー情報"
        onOk={() => signOut()}
        footer={[
          <Button
            style={{ backgroundColor: "#00838F", borderColor: "#00838F" }}
            key="submit"
            type="primary"
            onClick={() => signOut()}
          >
            OK
          </Button>,
        ]}
      >
        <p>以下の理由によりセッションが強制終了されました。</p>
        <p>・同じIDで別の端末からログインがあった </p>
        <p>・セッションの有効期限切れ</p>
      </Modal>
    </div>)
  );
};

const mapStateToProps = (state) => {
  return {
    socket: state.messageList.socket,
    preventLogin: state.messageList.preventLogin,
    getAvatarResponse: state.getAvatar.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignout: () => dispatch(AuthActions.signout()),
    getAvatar: () => dispatch(getAvatar()),
    onRemoveSocket: () => dispatch(removeSocket()),
    onInitializeSocket: () => dispatch(initializeSocket()),
  };
};

const _PrivateLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(PrivateLayout));
export { _PrivateLayout as PrivateLayout };
