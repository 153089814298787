export function formatDuration(dur) {
  switch (dur) {
    case "1週間":
      return {
        number: 7,
        format: "days",
      };
    case "2週間":
      return {
        number: 14,
        format: "days",
      };
    case "1ヵ月":
      return {
        number: 1,
        format: "months",
      };
    case "3ヵ月":
      return {
        number: 3,
        format: "months",
      };
    case "6ヵ月":
      return {
        number: 6,
        format: "months",
      };
    case "8ヵ月":
      return {
        number: 8,
        format: "months",
      };
    case "9ヵ月":
      return {
        number: 9,
        format: "months",
      };
    case "10ヵ月":
      return {
        number: 10,
        format: "months",
      };
    case "11ヵ月":
      return {
        number: 11,
        format: "months",
      };
    case "12ヵ月":
      return {
        number: 12,
        format: "months",
      };
    case "1年":
      return {
        number: 1,
        format: "years",
      };
    case "1年半":
      return {
        number: 1.5,
        format: "years",
      };
    case "2年":
      return {
        number: 2,
        format: "years",
      };
    default:
      return null;
  }
}

export function isValidDate(date) {
  const timestamp = Date.parse(date);
  return !isNaN(timestamp);
}
