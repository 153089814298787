import { CommonActionTypes } from "../action_types";
import { openNotificationWithIcon } from "../../../common/function_common/functionCommon";

function common(state = {}, action) {
  switch (action.type) {
    case CommonActionTypes.LIFE_KAIGODO_MASTER_LIST: {
      return {
        ...state,
        data: action.data,
      };
    }

    case CommonActionTypes.GET_MEDISYOUBYOUMEI_MASTER: {
      return {
        ...state,
        mediSyoubyoumei: action.data,
      };
    }

    case CommonActionTypes.GET_IYAKUHIN_MASTER: {
      return {
        ...state,
        iyakuhinMaster: action.data,
      };
    }

    case CommonActionTypes.GET_RIYOUBYOUREKI_MASTER: {
      return {
        ...state,
        riyouByoureki: action.data,
      };
    }

    case CommonActionTypes.GET_MEDISYOUBYOUMEI: {
      return {
        ...state,
        syoubyoumei: action.data,
      };
    }

    case CommonActionTypes.GET_IYAKUHIN: {
      return {
        ...state,
        iyakuhin: action.data,
      };
    }

    case CommonActionTypes.GET_DOCTOR_MASTER_LIST: {
      return {
        ...state,
        doctorMasterList: action.data,
      };
    }

    case CommonActionTypes.GET_DOCTORMASTER_MAX_SORT: {
      return {
        ...state,
        doctorMaxSort: action.data,
      };
    }

    case CommonActionTypes.GET_DOCTORMASTER_INIT: {
      return {
        ...state,
        doctorMaster: action.data,
      };
    }

    case CommonActionTypes.CREATE_DOCTORMASTER_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        doctorMaster: action.data,
      };
    }

    case CommonActionTypes.CREATE_DOCTORMASTER_ERROR: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        doctorMaster: null,
      };
    }

    case CommonActionTypes.UPDATE_DOCTORMASTER_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        doctorMaster: action.data,
      };
    }

    case CommonActionTypes.UPDATE_DOCTORMASTER_ERROR: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        doctorMaster: null,
      };
    }

    case CommonActionTypes.GET_IRYOKIKAN_MASTER_INIT: {
      return {
        ...state,
        iryoKikanMaster: action.data,
      };
    }

    case CommonActionTypes.CREATE_IRYOKIKAN_MASTER_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        iryoKikanMaster: action.data,
      };
    }

    case CommonActionTypes.CREATE_IRYOKIKAN_MASTER_ERROR: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        iryoKikanMaster: null,
      };
    }

    case CommonActionTypes.UPDATE_IRYOKIKAN_MASTER_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        iryoKikanMaster: action.data,
      };
    }

    case CommonActionTypes.UPDATE_IRYOKIKAN_MASTER_ERROR: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        iryoKikanMaster: null,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

export default common;
