import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { isKanji, toKatakana } from "wanakana";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import MenuBusiness from "../../common/component/UI/MenuBusinessV2";
import MenuRecord from "../../common/component/UI/MenuRecordV2";
import { getValueIskeep, getValueLocalstorage } from "../../common/function_common/functionCommon";
import { checkMenuDis } from "../../common/function_common/functionDisplay";
import MenuTop from "../../management/components/MenuTop/index";
import "./Riyousya.css";
import ListRiyousya from "./UI/ListRiyousya";

class Riyousya extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      shown: false,
      indeterminate: true,
      checkAll: false,
      tid: getValueLocalstorage("tid"),
      socket: this.props.socket,
      userSelect: false,
      messageContent: false,
      pageNo: 0,
      furigana: props.isKeep && !props.addRiyousyaId ? props.searchName : "",
      addId: props.isKeep ? props.addRiyousyaId : "",
      isWaitLoadmore: false,
    };
  }

  /**
   *
   */
  componentDidMount() {
    const { furigana, addId } = this.state;
    const page = 1;
    // Load list riyousya data
    const isRiyouf = getValueIskeep("is_hold_riyouf");
    const nyuusho = getValueIskeep("is_nyuusho_rireki");
    this.props.riyousyaListIsKeep(furigana, false, addId);
    this.props.getList(
      page,
      isRiyouf,
      nyuusho,
      isKanji(furigana) ? furigana : toKatakana(furigana),
      addId
    );
  }

  onChangeRiyouf = (isRiyouf) => {
    const { furigana } = this.state;
    const page = 1;
    // Load list riyousya data
    // const nyuusho = getValueIskeep("is_nyuusho_rireki");
    this.props.riyousyaListIsKeep(furigana, false, "");
    this.props.getList(
      page,
      isRiyouf,
      false,
      isKanji(furigana) ? furigana : toKatakana(furigana),
      ""
    );
  };

  onChangeNyuusho = (nyuusho) => {
    const { furigana } = this.state;
    const page = 1;
    // Load list riyousya data
    // const isRiyouf = getValueIskeep("is_hold_riyouf");
    this.props.riyousyaListIsKeep(furigana, false, "");
    this.props.getList(
      page,
      false,
      nyuusho,
      isKanji(furigana) ? furigana : toKatakana(furigana),
      ""
    );
  };

  searchByFurigana = () => {
    const { furigana } = this.state;
    const page = 1;
    // Load list riyousya data
    const isRiyouf = getValueIskeep("is_hold_riyouf");
    const nyuusho = getValueIskeep("is_nyuusho_rireki");
    this.props.riyousyaListIsKeep(furigana, false, "");
    this.props.getList(
      page,
      isRiyouf,
      nyuusho,
      isKanji(furigana) ? furigana : toKatakana(furigana),
      ""
    );
  };

  updateStateFurigana = (furigana) => {
    this.setState({
      furigana,
    });
  };

  resetSearchByFurigana = () => {
    const isRiyouf = getValueIskeep("is_hold_riyouf");
    const nyuusho = getValueIskeep("is_nyuusho_rireki");
    const page = 1;
    this.props.riyousyaListIsKeep("", false, "");
    this.props.getList(page, isRiyouf, nyuusho, "", "");

    this.setState({
      furigana: "",
    });
  };

  loadMore = () => {
    // waiting api loadmore done before next action
    this.setState({ isWaitLoadmore: true });

    const { furigana } = this.state;
    const { page } = this.props;
    // Load list riyousya data
    const isRiyouf = getValueIskeep("is_hold_riyouf");
    const nyuusho = getValueIskeep("is_nyuusho_rireki");
    this.props.loadMore(
      page + 1,
      isRiyouf,
      nyuusho,
      isKanji(furigana) ? furigana : toKatakana(furigana),
      () => {
        this.setState({
          isWaitLoadmore: false,
        });
      }
    );
  };

  riyousyaListIsKeep = () => {
    const { searchName, addRiyousyaId } = this.props;
    this.props.riyousyaListIsKeep(searchName, true, addRiyousyaId);
  };

  /**
   *
   */
  render() {
    const {
      sessionTimeout,
      riyousya,
      loading,
      page,
      page_total,
      total,
      history,
    } = this.props;
    const { furigana, isWaitLoadmore } = this.state;

    const currentMenu = checkMenuDis();
    return (
      <CommonPageContainer id="riyousya-page" sessionTimeout={sessionTimeout}>
        <div className={"submenu"}>
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <div>
                    {history.location.pathname.indexOf("management") === 1 ? (
                      <MenuTop
                        isDengon={true}
                        title={"利用者一覧"}
                        iconType="user"
                        history={this.props.history}
                        count={true}
                        countNum={total ? total : 0}
                      />
                    ) : (
                      <SubHeaderV2
                        TopLeft={
                          <SubHeaderTitleV2
                            title={"利用者一覧"}
                            iconType="user"
                            count={true}
                            countNum={total ? total : 0}
                          />
                        }
                        TopRight2={
                          currentMenu.indexOf("record") >= 0 ? (
                            <MenuRecord isUser={true} />
                          ) : (
                            <MenuBusiness isUser={true} />
                          )
                        }
                      />
                    )}
                  </div>
                );
              }
              return null;

            }}
          </MediaQuery>
        </div>
        <div>
          <ListRiyousya
            riyousya={riyousya}
            loading={loading}
            isWaitLoadmore={isWaitLoadmore}
            onChangeRiyouf={this.onChangeRiyouf}
            onChangeNyuusho={this.onChangeNyuusho}
            furigana={furigana}
            searchByFurigana={this.searchByFurigana}
            updateStateFurigana={this.updateStateFurigana}
            resetSearchByFurigana={this.resetSearchByFurigana}
            page={page}
            page_total={page_total}
            loadMore={this.loadMore}
            currentMenu={currentMenu}
            history={history}
            riyousyaListIsKeep={this.riyousyaListIsKeep}
          />
        </div>
      </CommonPageContainer>
    );
  }
}

export default Riyousya;
