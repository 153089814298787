import React from "react";
import { printErrorCSV, printErrorCheckMessCSV } from "../../../../../life/common/function_common/printOutput";
import { connect } from "react-redux";
import { StorageUtils, history } from "../../../../utils";
import { STATE_USAGE_TICKET_SAVE_TO_LOCAL } from "../../constants";
import { Icon } from '@ant-design/compatible';

function HeaderMissInput() {
  const tabName = [
    "利用者",
    "科学的介護",
    "個別機能訓練加算Ⅱ",
    "口腔機能向上一覧",
    "褥瘡マネジメント",
    "排せつ支援",
    "自立支援促進",
    "薬剤変更",
    "LIFEリハビリ計画書",
  ];  

  function handlePrint() {
    const usageTicketManagementState = StorageUtils.getValue(STATE_USAGE_TICKET_SAVE_TO_LOCAL) || {};
    if(usageTicketManagementState) {
      const {listBetuhyou} = usageTicketManagementState
      const filteredList = listBetuhyou.filter((item) => {
        return item.checkMess.some((mess) => mess.trim() !== "");
      });
      const listSpreadCheckMess = [];
      filteredList.forEach((element) => {
        let date = element.nengetu;
        date = date.substring(0, 4) + '-' + date.substring(4, 6);
        element.checkMess = element.checkMess.join(', ');
        listSpreadCheckMess.push({ id: element.id, name: element.riyousya.name, riyouhyouDate: date, errorMes: element.checkMess });
      });
      printErrorCheckMessCSV(listSpreadCheckMess);
    }
  }

  return (
    <div className="header fixed-header submenu">
      <div className="m-left">
        <span className="header-title-name">利用票管理</span>
      </div>
      <div className="m-right">
        <div className={`link-item`} onClick={handlePrint}>
          <Icon type="printer" theme="outlined" />
          <p>印刷</p>
        </div>
        <div className={`link-item`} onClick={() => window.close()}>
          <Icon type="close" theme="outlined" />
          <p>閉じる</p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // csvData: state.life.csvData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMissInput);
