import { Button, Input } from "antd";
import React, { Component } from "react";
import { EventPlanApi } from "../../../api/plan";
import { ConstSet } from "../../../common/configs/constset";
import { openNotificationWithIcon } from "../function/alert";
const { TextArea } = Input;
export default class EventInput extends Component {
  constructor(props) {
    super(props);
    const event = props.value || null;
    this.state = {
      event,
      clickBtn: false,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { event } = this.state;
    if (nextProps.value != event) {
      this.setState({
        event: nextProps.value,
      });
    }
  }
  getDataEventPlan(eventPlans) {
    const eventPlan = eventPlans ? eventPlans[0] : null;
    const oldContent = this.state.event;
    let event = null;
    if (eventPlan != null) {
      if (oldContent) {
        if (oldContent.trim().substr(oldContent.trim().length - 1, 1) == "、") {
          event = oldContent.concat(eventPlan.event);
        }
        else {
          event = oldContent.concat("、" + eventPlan.event);
        }
      }
      else {
        event = eventPlan.event;
      }
      this.triggerChange(event);
    }
    else {
      openNotificationWithIcon("error", "今日はイベントがありません");
    }
    this.setState({
      clickBtn: false,
    });
  }

  onChangeTextArea(e) {
    const event = e.target.value;
    this.setState({
      event,
    });
    this.triggerChange(event);
  }
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };
  async onClickButtonQuote() {
    const { selectedDate } = this.props;
    const eventPlans = await EventPlanApi.loadEventPlan(
      selectedDate.format(ConstSet.DATE_FORMAT),
      selectedDate.format(ConstSet.DATE_FORMAT)
    );
    this.setState({
      clickBtn: true,
    });
    this.getDataEventPlan(eventPlans);
  }
  render() {
    return (
      <div >
        <div className={"btnCopy"} style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{"行事内容"}</span>
          <Button onClick={() => this.onClickButtonQuote()} type={"primary"}>
            引用
          </Button>
        </div>
        <div>
          <TextArea
            value={this.state.event}
            onChange={(value) => this.onChangeTextArea(value)}
            rows={3}
            cols={80}
          />
        </div>
      </div>
    );
  }
}
