import { combineReducers } from "redux";
import SettingMasterReducers from "./SettingMasterReducers/SettingMasterReducers";
import accountReducers from "./accountReducers/accountReducers";
import allMasterReducers from "./allMasterReducers/allMasterReducers";
import barthelIndexMasterReducers from "./barthelIndexMasterReducers/barthelIndexMasterReducers";
import dengonSendToReducers from "./dengonSendToReducers/dengonSendToReducers";
import doctorMasterReducers from "./doctorMasterReducers/doctorMasterReducers";
import documentListReducers from "./documentListReducers/DocumentListReducers";
import drugMasterReducers from "./drugMasterReducers/drugMasterReducers";
import dutyReportReducers from "./dutyReportReducers/dutyReportReducers";
import eventPlanReducers from "./eventPlanReducers/eventPlanReducers";
import fileManagementReducers from "./fileManagementReducers/fileManagementReducers";
import getAvatarReducers from "./getAvatarReducers/getAvatarReducers";
import ioStatusReducers from "./ioStatusReducers/ioStatusReducers";
import iryoKougakuFutanMasterReducers from "./iryoKougakuFutanMasterReducers/iryoKougakuFutanMasterReducers";
import iryouhokenMasterReducers from "./iryouhokenMasterReducers/iryouhokenMasterReducers";
import itakuTantoMaster from "./itakuTantoMasterReducers/itakuTantoMaster";
import kaigoHokenMasterReducers from "./kaigoHokenMasterReducers/kaigoHokenMasterReducers";
import kaigoYougoMasterReducers from "./kaigoYougoMasterReducers/kaigoYougoMasterReducers";
import kanriReducers from "./kanriReducers/kanriReducers";
import kanriTable from "./kanriReducers/kanriTableReducers";
import keikaKirukuReducers from "./keikaKirokuReducers/keikaKirokuReducer";
import kohifutanMasterReducers from "./kohifutanMasterReducers/kohifutanMasterReducers";
import kokyakuReducers from "./kokyakuReducers/kokyakuReducers";
import kyotakuPlan1Reducers from "./kyotakuPlan1Reducers/kyotakuPlan1Reducers";
import kyotakuPlan2Reducers from "./kyotakuPlan2Reducers/kyotakuPlan2Reducers";
import mediSyoubyoumeiMasterReducers from "./mediSyoubyoumeiMasterReducers/mediSyoubyoumeiMasterReducers";
import messageListReducers from "./messageListReducers/messageListReducers";
import monitoringReducers from "./monitoringReducers/monitoringReducer";
import needsItemReducers from "./needsItemReducers/needsItemReducers";
import planReducers from "./planReducers/planReducers";
import progressReducers from "./progressReducers/progressReducers";
import referenceNumberReducers from "./referenceNumberReducers/referenceNumberReducers";
import reportAdlReducers from "./reportAdlReducers/reportAdlReducer";
import reportReducers from "./reportReducers/reportReducers";
import reportUserReducers from "./reportUserReducers/reportUserReducers";
import reportWeeklyAddReducers from "./reportWeeklyAddReducers/reportWeeklyAddReducers";
import reportWeeklyReducers from "./reportWeeklyReducers/reportWeeklyReducers";
import rihabiliReducers from "./rihabiliReducers/rihabiliReducers";
import rihabiri from "./rihabiri";
import riyouKaigohokenReducers from "./riyouKaigohokenReducers/riyouKaigohokenReducers";
import riyousyaHokenReducers from "./riyousyaHokenReducers/riyousyaHokenReducers";
import riyousyaMonthlyReducers from "./riyousyaMonthlyReducers/RiyousyaMonthlyReducers";
import riyousyaReducers from "./riyousyaReducers/riyousyaReducers";
import roomMasterReducers from "./roomMasterReducers/roomMasterReducers";
import sendGroupReducers from "./sendGroupReducers/sendGroupReducers";
import serviceKikanMaster from "./serviceKikanMasterReducers/serviceKikanMaster";
import sisetuDailyPlanReducers from "./sisetuDailyPlanReducers/sisetuDailyPlanReducers";
import sisetuKaigoNaiyouMasterReducers from "./sisetuKaigoNaiyouMasterReducers/sisetuKaigoNaiyouMasterReducers";
import syokichiReducers from "./syokichiReducers/syokichiReducers";
import systemNameListReducers from "./systemControlReducers/systemControlReducers";
import tantoKaigiReducers from "./tantoKaigiReducers/tantoKaigiReducer";
import tantoMasterListReducers from "./tantoMasterListReducers/tantoMasterListReducers";
import testingReducers from "./testingReducers/testingReducers";
import userListReducers from "./userListReducers/userListReducers";
import yougoMasterNaiyouReducers from "./yougoMasterNaiyouReducers/yougoMasterNaiyouReducers";
import ZipMasterReducers from "./zipMasterReducers/zipMasterReducers";

/**
 *
 */
export const rootReducer = combineReducers({
  dataLogin: accountReducers,
  userList: userListReducers,
  sendGroup: sendGroupReducers,
  messageList: messageListReducers,
  dengonSendTo: dengonSendToReducers,
  kokyaku: kokyakuReducers,
  testing: testingReducers,
  getAvatar: getAvatarReducers,
  report: reportReducers,
  reportUser: reportUserReducers,
  reportWeekly: reportWeeklyReducers,
  reportWeeklyAdd: reportWeeklyAddReducers,
  tantoMasterList: tantoMasterListReducers,
  yougoMasterNaiyou: yougoMasterNaiyouReducers,
  drugMaster: drugMasterReducers,
  allMaster: allMasterReducers,
  syokichi: syokichiReducers,
  plan: planReducers,
  progress: progressReducers,
  system: systemNameListReducers,
  eventPlan: eventPlanReducers,
  settingMaster: SettingMasterReducers,
  zipMaster: ZipMasterReducers,
  dutyReport: dutyReportReducers,
  ioStatusReducers,
  documentList: documentListReducers,
  kyotakuPlan1: kyotakuPlan1Reducers,
  kyotakuPlan2: kyotakuPlan2Reducers,
  sisetuDailyPlan: sisetuDailyPlanReducers,
  riyouKaigohokens: riyouKaigohokenReducers,
  riyousya: riyousyaReducers,
  riyousyaHoken: riyousyaHokenReducers,
  kaigoYougoMaster: kaigoYougoMasterReducers,
  sisetuKaigoNaiyouMaster: sisetuKaigoNaiyouMasterReducers,
  roomMaster: roomMasterReducers,
  kanri: kanriReducers,
  kanriTable,
  monitoring: monitoringReducers,
  keikaKiroku: keikaKirukuReducers,
  tantoKaigi: tantoKaigiReducers,
  fileManagement: fileManagementReducers,
  doctorMaster: doctorMasterReducers,
  referenceNumber: referenceNumberReducers,
  mediSyoubyoumeiMaster: mediSyoubyoumeiMasterReducers,
  kaigoHokenMaster: kaigoHokenMasterReducers,
  kohifutanMaster: kohifutanMasterReducers,
  reportAdl: reportAdlReducers,
  iryouhokenMaster: iryouhokenMasterReducers,
  iryoKougakuFutanMaster: iryoKougakuFutanMasterReducers,
  barthelIndexMaster: barthelIndexMasterReducers,
  serviceKikanMaster,
  itakuTantoMaster,
  rihabili: rihabiliReducers,
  riyousyamonthly: riyousyaMonthlyReducers,
  needsItem: needsItemReducers,
  rihabiriNew: rihabiri,
});
