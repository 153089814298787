import { Form } from "@ant-design/compatible";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import React, { Component } from "react";

// import '@ant-design/compatible/assets/index.css';


import { Button, Col, Divider, Modal, Row } from "antd";
import TextAreaSelect from "./TextAreaSelect";

class FormKikiNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
    };
  }

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouKiki",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleSort = (type, sortType, index) => {
    this.props.handleSort(type, sortType, index);
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  searchMachineMaster = (bunrui, name) => {
    this.props.searchMachineMaster(bunrui, name);
  };

  render() {
    const { visible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { index, machineMaster } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {index && index > 0 ? (
          <Row style={{ padding: "8px 0px" }}>
            <Divider />
            <Divider />
            <Divider />
          </Row>
        ) : null}
        <Row className="title-inline">
          <Col span={12}>
            <h3 style={{ background: "#d9d9d9", padding: "4px 6px" }}>{`機器 ${index + 1}`}</h3>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.showDeleteConfirm}
            >
              削除
            </Button>
          </Col>
          <Col span={12} className="title-inline-right">
            <h3>順序</h3>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.handleSort.bind(this, "riyouKiki", "up", index)}
            >
              上へ <CaretUpOutlined />
            </Button>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.handleSort.bind(this, "riyouKiki", "down", index)}
            >
              下へ <CaretDownOutlined />
            </Button>
          </Col>
        </Row>
        <Divider />
        <Divider />
        <Form.Item label={"医療機器"} colon={false}>
          {getFieldDecorator("kikimei", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={true}
              isSelect={false}
              rowSet={1}
              dataMaster={machineMaster}
              search={this.searchMachineMaster}
              dataMasterType={"machine"}
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"備考"} colon={false}>
          {getFieldDecorator("bikou", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextAreaSelect isMaster={false} isSelect={false} rowSet={2} />)}
        </Form.Item>
        <Divider />
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
      </Form>)
    );
  }
}

export const FormKiki = Form.create({
  name: "input-riyou-kiki",
  onValuesChange(props, changedValues, allValues) {
    // @TODO convert checkbox group to value
    props.saveOnchangeToState(allValues, "fKiki", props.index);
  },
  mapPropsToFields(props) {
    return {
      kikimei: Form.createFormField({
        value: props.data ? props.data.kikimei : null,
      }),
      bikou: Form.createFormField({
        value: props.data ? props.data.bikou : null,
      }),
    };
  },
})(FormKikiNormal);
