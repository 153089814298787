import { COGNITIVE_FUNCTION_GET, COGNITIVE_FUNCTION_UPDATE, KEEP_VALUE_COG, COGNITIVE_FUNCTION_UPDATE_ERROR } from "../../actions/cognitivefunctionActions/actionName";
import { notification } from "antd/lib/index";

export default function CognitiveFunctionReducers(state = {
  loading: true
}, action) {
  switch (action.type) {
    case COGNITIVE_FUNCTION_GET:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case COGNITIVE_FUNCTION_UPDATE:
      notification["success"]({
        message: "保存しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        data: action.data,
        notifyContentEdit: { type: "success" },
        loading: false
      };
    case COGNITIVE_FUNCTION_UPDATE_ERROR:
      notification["error"]({
        message: "エラーが発生しました",
        placement: "topRight",
        duration: 3,
        notifyContentEdit: { type: "error" },
        style: { whiteSpace: "pre-wrap" },
      });
      return state;

    case KEEP_VALUE_COG:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}