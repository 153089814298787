import { connect } from "react-redux";

import ReportWeeklyAdd from "./ReportWeeklyAdd";
import { tantoMasterListCreator } from "../../actions/tantoMasterListActions/actionCreators";
import { loadZenkaiCreator } from "../../actions/reportActions/actionCreators";

import { filterCreator } from "../../actions/reportActions/actionCreators";
import {
  yougoMasterNaiyouCreator,
  yougoMasterEditStateCreator,
  yougoMasterEditCancelCreator,
  yougoMasterEditSaveCreator,
  loadEctSelectDataCreator,
  yougoMasterEctStateCreator,
  yougoMasterEctCancelCreator,
  yougoMasterEctSaveCreator,
  yougoMasterSyuruiCreator,
  yougoMasterJoutaiCreator,
} from "../../actions/yougoMasterNaiyouActions/actionCreators";

import { drugMasterListCreator } from "../../actions/drugMasterActions/actionCreators";

import {
  syokichiItemCreator,
  syokichiUpdateCreator,
} from "../../actions/syokichiActions/actionCreators";

import {
  loadDataCreator,
  saveDataCreator,
  delRecordCreator,
  saveByYouteiCreator,
} from "../../actions/reportWeeklyAddActions/actionCreators";

import { loadFilePreview } from "../../actions/fileManagementActions/actionCreators";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    // ReportData
    reports: state.reportWeekly.data,

    vital: state.reportWeeklyAdd.vital,
    meal: state.reportWeeklyAdd.meal,
    suibun: state.reportWeeklyAdd.suibun,
    hainyou: state.reportWeeklyAdd.hainyou,
    haiben: state.reportWeeklyAdd.haiben,
    fukuyaku: state.reportWeeklyAdd.fukuyaku,
    nyuyoku: state.reportWeeklyAdd.nyuyoku,
    kaigo: state.reportWeeklyAdd.kaigo,
    tokki: state.reportWeeklyAdd.tokki,
    gazou: state.reportWeeklyAdd.gazou,
    mealYotei: state.reportWeeklyAdd.mealYotei,
    loadingSave: state.reportWeeklyAdd.loadingSave,
    loading: state.reportWeeklyAdd.loading,

    saving: state.reportWeekly.saving,

    // tantomaster reducers
    tantoMaster: state.tantoMasterList.tantoMaster,

    // load zenkai
    zenkai: state.report.zenkai,
    loadingZenkai: state.report.loadingZenkai,

    // load setting
    settingData: state.report.nyutaishoFilter,

    // yougomaster reducers
    yougoMasterVC: state.yougoMasterNaiyou.dataVC,
    yougoMasterMC: state.yougoMasterNaiyou.dataMC,
    yougoMasterSC: state.yougoMasterNaiyou.dataSC,
    yougoMasterHC: state.yougoMasterNaiyou.dataHC,
    yougoMasterNC: state.yougoMasterNaiyou.dataNC,
    yougoMasterFC: state.yougoMasterNaiyou.dataFC,
    yougoMasterKC: state.yougoMasterNaiyou.dataKC,
    yougoMasterTC: state.yougoMasterNaiyou.dataTC,
    yougoMasterGC: state.yougoMasterNaiyou.dataGC,
    yougoMasterSy: state.yougoMasterNaiyou.suibunType,
    haisetuType: state.yougoMasterNaiyou.haisetuType,
    haisetuState: state.yougoMasterNaiyou.haisetuState,
    nyuyokuType: state.yougoMasterNaiyou.nyuyokuType,

    yougoMasterJt: state.yougoMasterNaiyou.yougoMasterHJ, // haisetu joutai
    yougoMasterEctSelect1: state.yougoMasterNaiyou.ectDataSelect1,
    yougoMasterEctSelect2: state.yougoMasterNaiyou.ectDataSelect2,
    yougoMasterEctSelect3: state.yougoMasterNaiyou.ectDataSelect3,

    // drug reducers
    drugList: state.drugMaster.list,
    drugLoading: state.drugMaster.loading,

    // syokichi reducers
    syokichi: state.syokichi.item,
    // syokichiUpdateData: state.syokichi.update,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    /**
     * tanto-master actions
     *
     */
    tantoMasterList: () => dispatch(tantoMasterListCreator()),

    /**
     *
     */

    handleSaveData: (objParams, callback) =>
      dispatch(saveDataCreator(objParams, callback)),

    /**
     *
     */
    loadZenkai: (date, riyousyaId, time) =>
      dispatch(loadZenkaiCreator(date, riyousyaId, time)),

    /**
     * setting data
     */
    loadSetting: () => dispatch(filterCreator()),

    /**
     * yougo-master actions
     *
     */
    yougoMasterNaiyou: (listName) =>
      dispatch(yougoMasterNaiyouCreator(listName)),
    yougoMasterEditState: (index, type) =>
      dispatch(yougoMasterEditStateCreator(index, type)),
    yougoMasterEditCancel: (index, type) =>
      dispatch(yougoMasterEditCancelCreator(index, type)),
    yougoMasterEditSave: (naiyou, type) =>
      dispatch(yougoMasterEditSaveCreator(naiyou, type)),

    loadEctSelectData: (mainSid) => dispatch(loadEctSelectDataCreator(mainSid)),
    yougoMasterEctState: (index) => dispatch(yougoMasterEctStateCreator(index)),
    yougoMasterEctCancel: (index) =>
      dispatch(yougoMasterEctCancelCreator(index)),
    yougoMasterEctSave: (type, naiyou) =>
      dispatch(yougoMasterEctSaveCreator(type, naiyou)),

    yougoMasterSyurui: (syurui) => dispatch(yougoMasterSyuruiCreator(syurui)),
    yougoMasterJoutai: (joutai) => dispatch(yougoMasterJoutaiCreator(joutai)),

    /**
     * drug actions
     *
     */
    loadDrugMaster: (bunrui, name) =>
      dispatch(drugMasterListCreator(bunrui, name)),

    /**
     * syokichi actions
     *
     */
    syokichiItem: (riyousyaId, kubun) =>
      dispatch(syokichiItemCreator(riyousyaId, kubun)),
    syokichiUpdate: (riyousyaId, kubun, syokichi) =>
      dispatch(syokichiUpdateCreator(riyousyaId, kubun, syokichi)),

    /**
     * load data by array of ids
     */
    loadData: (params, callback) => dispatch(loadDataCreator(params, callback)),

    /**
     * delete record
     *
     */
    delRecord: (type, recordId) => dispatch(delRecordCreator(type, recordId)),

    /**
     * save youtei to record
     */
    saveByYoutei: (record) => dispatch(saveByYouteiCreator(record)),

    loadFilePreview: (params, callback) =>
      dispatch(loadFilePreview(params, callback)),
  };
};

export const ReportWeeklyAddContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportWeeklyAdd);
