import { Spin } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  changeEditMode,
  createMonitoring,
  getMonitoringById,
  quotePlan2ToMonitoringRows,
  updateMonitoring,
} from "../../../actions/monitoringActions/actionCreators";
import { getRiyousya } from "../../../actions/riyousyaActions/actionCreators";
import { CommonPageContainer } from "../../../common/component/IdleTimerPage";
import { getUrlVars } from "../../../common/function_common/functionCommon";
import MonitoringInputForm from "./MonitoringInputForm";
import "./monitoring.css";

class MonitoringContainer extends Component {
  constructor(props) {
    super(props);
    const { monitoringId, riyousyaId } = this.props.match.params;
    this.riyousyaId = riyousyaId;
    this.monitoringId = monitoringId;
  }
  componentDidMount() {
    const copyFrom = getUrlVars(window.location.href)["copy-from"];
    if (copyFrom) {
      this.props.getRiyousyaInfo(this.riyousyaId);
      this.props.quotePlan2ToMonitoringRows(copyFrom);
    }
    else {
      this.props.getRiyousyaInfo(this.riyousyaId);
      this.props.getMonitoringById(this.monitoringId);
    }
    window.scroll(0, 0);
  }

  async regData(err, values) {
    const { riyousyaId, monitoringId } = this.props.match.params;
    if (this.props.edit) {
      await this.props.changeEditMode(false);
    }
    const { monitoringRows, monitoringHyokas, monitoringCarePlanDate } =
      this.props;
    const newValues = {
      ...values,
      monitoring_mokuhyous: monitoringRows,
      monitoring_hyokas: monitoringHyokas,
      careplan_date: monitoringCarePlanDate,
    };
    if (err) {
      return;
    }
    if (monitoringId === "new")
      this.props.createMonitoring(newValues, riyousyaId);
    else this.props.updateMonitoring(newValues, monitoringId);
  }

  async regDataAndCheck(err, values) {
    const { riyousyaId, monitoringId } = this.props.match.params;
    if (this.props.edit) {
      await this.props.changeEditMode(false);
    }

    if (err) {
      return;
    }
    const { monitoringRows, monitoringHyokas, monitoringCarePlanDate } =
      this.props;
    const newValues = {
      ...values,
      monitoring_mokuhyous: monitoringRows,
      monitoring_hyokas: monitoringHyokas,
      careplan_date: monitoringCarePlanDate,
    };

    if (monitoringId === "new")
      this.props.createMonitoring(newValues, riyousyaId);
    else {
      this.props.updateMonitoring(newValues, monitoringId);
      const win = window.open(
        `/check-info-document-list/${riyousyaId}/${monitoringId}`,
        "_blank"
      );
      win.focus();
    }
  }

  render() {
    return (
      <CommonPageContainer
        id={"input-monitoring-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <Spin spinning={false}>
          <MonitoringInputForm
            riyousyaId={this.riyousyaId}
            match={this.props.match}
            riyousya={this.props.riyousya}
            history={this.props.history}
            data={this.props.monitoring}
            monitoringCarePlanDate={this.props.monitoringCarePlanDate}
            regData={(err, values) => this.regData(err, values)}
            regDataAndCheck={(err, values) =>
              this.regDataAndCheck(err, values)
            }
          ></MonitoringInputForm>
        </Spin>
      </CommonPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    riyousya: state.riyousya.riyousya,
    monitoring: state.monitoring.monitoring,
    monitoringRows: state.monitoring.monitoringRows,
    monitoringCarePlanDate: state.monitoring.monitoringCarePlanDate,
    edit: state.monitoring.editMode,
    monitoringHyokas: state.monitoring.monitoringHyokas,
    // sisetuDailyPlan: state.sisetuDailyPlan.sisetuDailyPlan,
    // notifyDisplay: state.sisetuDailyPlan.notifyDisplay,
    // notifyContent: state.sisetuDailyPlan.notifyContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
    getMonitoringById: (id) => dispatch(getMonitoringById(id)),
    updateMonitoring: (data, id, callback) =>
      dispatch(updateMonitoring(data, id, callback)),
    createMonitoring: (data, rid, callback) =>
      dispatch(createMonitoring(data, rid, callback)),
    changeEditMode: (mode) => dispatch(changeEditMode(mode)),
    quotePlan2ToMonitoringRows: (copyForm) =>
      dispatch(quotePlan2ToMonitoringRows(copyForm)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitoringContainer);
