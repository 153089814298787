import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { ConstSet } from "../common/configs/constset";
import { formatStorage, getMenu } from "../common/function_common/functionCommon";
import { ConfigName } from "../config-name";

const YougoMasterAPI = {
  async load(listNames) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.YOUGOMASTER_MASTER_LOAD +
        `?page=${1}&limit=${1000}&listName=&naiyou=&listNames=${listNames}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
        localStorage.removeItem(ConfigName.DOUBLELOGIN);
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  },
};


const YougoMasterNaiyouAllApi = {
  async yougoMasterNaiyouAll() {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_YOUGO_MASTER_NAIYOU_ALL, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  }
};

const YougoMasterNaiyouApi = {
  async yougoMasterNaiyou(listName) {
    const res = await axios.get(apiUrls.INIT + apiUrls.GET_YOUGO_MASTER_NAIYOU, {
      params: {
        listName
      }
    });
    return res.data;
  },
  async createYougoMaster(listName, naiyou) {
    const res = await axios.post(apiUrls.INIT + apiUrls.POST_YOUGO_MASTER, {
      listName,
      naiyou
    });
    return res.data;
  },
  async updateYougoMasterApi(listName, naiyou) {
    const res = await axios.put(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
      listName,
      naiyou
    });
    return res.data;
  }
};

//
const YougoMasterUpdateApi = {
  async update(key, naiyou) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
        method: "PUT",
        data: {
          listName: key,
          naiyou
        }
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  }
};

const YougoMasterEditApi = {
  async yougoMasterEditApi(naiyou, type) {
    let listName = "";
    if (type && type > 0) {
      switch (type) {
        case 1:
          listName = "バイタルコメント";
          break;

        case 2:
          listName = "食事コメント";
          break;

        case 3:
          listName = "水分コメント";
          break;

        case 4:
          listName = "排泄コメント";
          break;

        case 5:
          listName = "入浴コメント";
          break;

        case 6:
          listName = "服薬コメント";
          break;

        case 7:
          listName = "介護記録コメント";
          break;

        case 8:
          listName = "その他コメント";
          break;

        case 9:
          listName = "画像コメント";
          break;

        default:
          break;
      }
    }
    else {
      const tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          listName = "バイタルコメント";
          break;

        case ConstSet.KIROKU_MEAL:
          listName = "食事コメント";
          break;

        case ConstSet.KIROKU_SUIBUN:
          listName = "水分コメント";
          break;

        case ConstSet.KIROKU_HAISETU:
          listName = "排泄コメント";
          break;

        case ConstSet.KIROKU_NYUYOKU:
          listName = "入浴コメント";
          break;

        case ConstSet.KIROKU_FUKUYAKU:
          listName = "服薬コメント";
          break;

        case ConstSet.KIROKU_KAIGO:
          listName = "介護記録コメント";
          break;

        case ConstSet.KIROKU_TOKKI:
          listName = "その他コメント";
          break;

        case ConstSet.KIROKU_FILE:
          listName = "画像コメント";
          break;

        default:
          break;
      }
    }

    try {
      const res = await axios(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
        method: "PUT",
        data: {
          listName,
          naiyou
        }
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  }
};

const YougoMasterEditApiNew = {
  async yougoMasterEditApiNew(listName, naiyou) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
        method: "PUT",
        data: {
          listName,
          naiyou
        }
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  }
};

const YougoMasterEctApi = {
  async yougoMasterEctApi(sid, pageName) {
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_YOUGO_MASTER_ECT + `?sid=${sid}&systemName=${systemName}&pageName=${pageName ? pageName : "default"}`, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  }
};

const YougoMasterEditEctApi = {
  async yougoMasterEditEctApi(type, naiyou) {
    const key = getMenu();
    const listName =
      "バイタル他" + type + "_" + JSON.parse(localStorage.getItem(key)).si;

    try {
      const res = await axios(apiUrls.INIT + apiUrls.PUT_YOUGO_MASTER, {
        method: "PUT",
        data: {
          listName,
          naiyou
        }
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("doubleLogin");
        localStorage.removeItem("getAvatarResponse");
        localStorage.removeItem("getAvatarResponseData");
        window.location.replace("/login");
      }

      return err;

    }
  }
};

export {
  YougoMasterAPI, YougoMasterEctApi, YougoMasterEditApi, YougoMasterEditApiNew, YougoMasterEditEctApi, YougoMasterNaiyouAllApi,
  YougoMasterNaiyouApi, YougoMasterUpdateApi
};

