import {
  ALL_MASTER_SPIN_REQUEST,
  ALL_MASTER_SPIN_FALSE,
  BYOUMEI_MASTER_LIST_SUCCESS,
  BYOUMEI_MASTER_INIT_SUCCESS,
  ALL_DRUG_MASTER_LIST_SUCCESS,
  DRUG_MASTER_INIT_SUCCESS,
  MACHINE_MASTER_LIST_SUCCESS,
  MACHINE_MASTER_INIT_SUCCESS,
  SERVICE_SYURUI_MASTER_LIST_SUCCESS,
  SERVICE_KIKAN_MASTER_LIST_SUCCESS,
  IRYOU_KIKAN_MASTER_LIST_SUCCESS,
  IRYOU_KIKAN_MASTER_INIT_SUCCESS,
  CREATE_BYOUMEI_MASTER_SUCCESS,
  CREATE_BYOUMEI_MASTER_ERROR,
  UPDATE_BYOUMEI_MASTER_SUCCESS,
  UPDATE_BYOUMEI_MASTER_ERROR,
  CREATE_DRUG_MASTER_SUCCESS,
  CREATE_DRUG_MASTER_ERROR,
  UPDATE_DRUG_MASTER_SUCCESS,
  UPDATE_DRUG_MASTER_ERROR,
  CREATE_MACHINE_MASTER_SUCCESS,
  CREATE_MACHINE_MASTER_ERROR,
  UPDATE_MACHINE_MASTER_SUCCESS,
  UPDATE_MACHINE_MASTER_ERROR,
  CREATE_IRYOU_KIKAN_MASTER_SUCCESS,
  CREATE_IRYOU_KIKAN_MASTER_ERROR,
  UPDATE_IRYOU_KIKAN_MASTER_SUCCESS,
  UPDATE_LIST_IRYOU_KIKAN_MASTER_SUCCESS,
  UPDATE_IRYOU_KIKAN_MASTER_ERROR,
  UPDATE_LIST_BYOUMEI_SUCCESS,
  UPDATE_LIST_DRUG_SUCCESS,
  UPDATE_LIST_MACHINE_SUCCESS,
  UPDATE_SERVICE_KIKAN_LIST_REQUEST,
  UPDATE_SERVICE_KIKAN_LIST_SUCCESS,
  UPDATE_SERVICE_KIKAN_LIST_FAILURE,
  SERVICE_KIKAN_MASTER_LIST_SUCCESSA,
} from "../../actions/allMasterActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  byomeiMaster: [],
  drugMaster: [],
  machineMaster: [],
  serviceSyuruiMaster: [],
  serviceKikanMaster: [],
  iryouKikanMaster: [],
};

export default function drugMasterReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ALL_MASTER_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_MASTER_SPIN_FALSE:
      return {
        ...state,
        loading: false,
      };
    case BYOUMEI_MASTER_LIST_SUCCESS:
      return {
        ...state,
        byomeiMaster: action.payload,
        loading: false,
      };

    case BYOUMEI_MASTER_INIT_SUCCESS:
      return {
        ...state,
        byoumei: action.payload,
        loading: false,
      };

    case ALL_DRUG_MASTER_LIST_SUCCESS:
      return {
        ...state,
        drugMaster: action.payload,
        loading: false,
      };

    case DRUG_MASTER_INIT_SUCCESS:
      return {
        ...state,
        drug: action.payload,
        loading: false,
      };

    case MACHINE_MASTER_LIST_SUCCESS:
      return {
        ...state,
        machineMaster: action.payload,
        loading: false,
      };

    case MACHINE_MASTER_INIT_SUCCESS:
      return {
        ...state,
        machine: action.payload,
        loading: false,
      };

    case SERVICE_SYURUI_MASTER_LIST_SUCCESS:
      return {
        ...state,
        serviceSyuruiMaster: action.payload,
      };

    case SERVICE_KIKAN_MASTER_LIST_SUCCESS:
      return {
        ...state,
        serviceKikanMaster: action.payload,
        loading: false,
      };

    case SERVICE_KIKAN_MASTER_LIST_SUCCESSA:
      return {
        ...state,
        serviceList: action.payload,
        loading: false,
      };

    case IRYOU_KIKAN_MASTER_LIST_SUCCESS:
      return {
        ...state,
        iryouKikanMaster: action.payload,
        loading: false,
      };

    case IRYOU_KIKAN_MASTER_INIT_SUCCESS:
      return {
        ...state,
        iryouKikan: action.payload,
        loading: false,
      };

    case CREATE_BYOUMEI_MASTER_SUCCESS: /////病名
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        byomei: action.payload,
        loading: false,
      };

    case CREATE_BYOUMEI_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        byomei: null,
        loading: false,
      };

    case UPDATE_BYOUMEI_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        byomei: action.payload,
        loading: false,
      };

    case UPDATE_BYOUMEI_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        byomei: null,
        loading: false,
      };

    case CREATE_DRUG_MASTER_SUCCESS: ////////薬
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        byomei: action.payload,
        loading: false,
      };

    case CREATE_DRUG_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        byomei: null,
        loading: false,
      };

    case UPDATE_DRUG_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        byomei: action.payload,
        loading: false,
      };

    case UPDATE_DRUG_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        byomei: null,
        loading: false,
      };

    case CREATE_MACHINE_MASTER_SUCCESS: ////////機器
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        byomei: action.payload,
        loading: false,
      };

    case CREATE_MACHINE_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        byomei: null,
        loading: false,
      };

    case UPDATE_MACHINE_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        byomei: action.payload,
        loading: false,
      };

    case UPDATE_MACHINE_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        byomei: null,
        loading: false,
      };

    case CREATE_IRYOU_KIKAN_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        iryouKikan: action.payload,
        loading: false,
      };

    case CREATE_IRYOU_KIKAN_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        iryouKikan: null,
        loading: false,
      };

    case UPDATE_IRYOU_KIKAN_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        iryouKikan: action.payload,
        loading: false,
      };

    case UPDATE_LIST_IRYOU_KIKAN_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        iryouKikanMaster: action.payload,
        // loading: false,
      };

    case UPDATE_IRYOU_KIKAN_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        iryouKikan: null,
        loading: false,
      };

    case UPDATE_LIST_BYOUMEI_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateListByomeiMaster: action.payload,
        // loading: false,
      };

    case UPDATE_LIST_DRUG_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateListDrugMaster: action.payload,
        loading: false,
      };

    case UPDATE_LIST_MACHINE_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateListMachineMaster: action.payload,
        // loading: false,
      };
    case UPDATE_SERVICE_KIKAN_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_SERVICE_KIKAN_LIST_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        // loading: false,
        updateListService: action.payload,
      };
    }
    case UPDATE_SERVICE_KIKAN_LIST_FAILURE: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
        serviceKikanMaster: null,
      };
    }

    default:
      return state;
  }
}
