import { Button, Checkbox, Col, Input, Modal, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createSisetuKaigoNaiyouCreator,
  getSisetuKaigoNaiyouMasterInit,
  updateSisetuKaigoNaiyouCreator,
} from "../../../actions/sisetuKaigoNaiyouMasterActions/actionCreators";
import { yougoMasterUpdateNewCreator } from "../../../actions/yougoMasterNaiyouActions/actionCreators";
import MasterCodeCancelButton from "./UI/MasterCodeCancelButton";
import MasterCodeInput from "./UI/MasterCodeInput";
import YougoSelect from "./YougoSelect";

const ADD = 1;
const EDIT = 2;

const reference = {
  table: "sisetu_kaigo_naiyou_master",
  field: "kaigo_naiyou_code",
};

const { Option } = Select;

function KaigoNaiyouEditModal(props) {
  const [data, setData] = useState({});
  const [initCode, setInitCode] = useState(null);
  // const [visibleYougo, setVisibleYougo] = useState(false);

  useEffect(() => {
    // モーダルをtrueしたとき
    if (props.visible) {
      if (props.data && !(props.editType === ADD)) {
        if (props.editType === EDIT) {
          setData(props.data);
        }
      }
      else {
        props.getSisetuKaigoNaiyouMasterInit((callData) => {
          const initDate = callData ? { ...callData, id: null, kyotuf: 0 } : {};
          setData(initDate);
        });
      }
    }
  }, [props.visible]);

  useEffect(() => {
    if (data && props.newCode) {
      setData({ ...data, kaigoNaiyouCode: props.newCode });
    }
  }, [data.kaigoNaiyouCode]);

  const handleChangeKubun = (value) => {
    setData({ ...data, kirokuKubun: value });
  };

  const onChangeValue = (e, field) => {
    // use stopPropagation instead of preventDefault to avoid checkbox click twice
    e.stopPropagation();
    let newValue = data[field];
    switch (field) {
      // case "":
      //   if (/^\d*$/.test(e.target.value)) {
      //     newValue = e.target.value;
      //   }
      //   break;
      case "stop":
        newValue = e.target.checked ? 1 : 0;
        break;
      case "kyotuf":
        newValue = e.target.checked ? 1 : 0;
        break;
      default:
        newValue = e.target.value;
    }

    if (newValue !== data[field]) {
      setData((prevValue) => ({ ...prevValue, [field]: newValue }));
    }
  };

  const onChangeCode = (value, first) => {
    if (first) {
      setData({ ...data, kaigoNaiyouCode: value }), setInitCode(value);
    }
    else {
      setData({ ...data, kaigoNaiyouCode: value });
    }
  };

  async function handleSave() {
    if (checkRequired()) return;

    if (data.id) {
      await props.updateSisetuKaigoNaiyouCreator(data, (callData) => {
        props.handleSelectRow(callData);
      });
    }
    else {
      await props.createSisetuKaigoNaiyouCreator(data, (callData) => {
        props.handleSelectRow(callData);
      });
    }
    // props.onOk();
    handleOk();
    // setErrorProductNameVisible(false);
    // setErrorServiceNameVisible(false);
  }

  const handleCancel = () => {
    props.handleClose();

    setData({});
    setInitCode(null);
    // setInitCode(null);
  };

  const handleOk = () => {
    props.handleOk();

    setData({});
    setInitCode(null);
  };

  // const openYougoMaster = () => {
  //   setVisibleYougo(true);
  // };

  /**
   * Update yougoMaster
   *
   */
  const yougoMasterUpdate = (key, naiyou) => {
    props.yougoMasterUpdate(key, naiyou);
  };

  const onSubmitYougo = (value, key) => {
    if (key == "tantosya") {
      // const newValue = !data.tantosya ? `${value}` : data.tantosya + "、" + `${value}`
      const newValue = value;
      setData({ ...data, tantosya: newValue });
    }
    else if (key == "kaigoBunrui") {
      // const newValue = !data.kaigoBunrui ? `${value}` : data.kaigoBunrui + "、" + `${value}`
      const newValue = value;
      setData({ ...data, kaigoBunrui: newValue });
    }

    else if (key == "kaigoBunrui2") {
      // const newValue = !data.kaigoBunrui ? `${value}` : data.kaigoBunrui + "、" + `${value}`
      const newValue = value;
      setData({ ...data, kaigoBunrui2: newValue });
    }
  };

  function checkRequired() {
    // 空欄かチェック
    const isError = false;
    // if (_.isEmpty(product.name)) {
    //   setErrorProductNameVisible(true);
    //   isError = true;
    // } else {
    //   setErrorProductNameVisible(false);
    // }

    // if (_.isEmpty(product.serviceName) || product.serviceName == "") {
    //   setErrorServiceNameVisible(true);
    //   isError = true;
    // } else {
    //   setErrorServiceNameVisible(false);
    // }
    return isError;
  }

  return (
    (<div>
      <Modal
        style={{ zIndex: 100 }}
        closable={false} // 右上のXボタン
        maskClosable={false} // モールド外でクリック時に閉じる
        destroyOnClose={true}
        onCancel={() => handleCancel()}
        onOk={() => handleOk()}
        open={props.visible}
        width={800}
        footer={[
          <Row key={1} type="flex" justify="end">
            <div style={{ paddingRight: 8 }}>
              <MasterCodeCancelButton
                text={"戻る"}
                code={initCode}
                reference={reference}
                checkType={props.editType === ADD}
                onClose={() => handleCancel()}
              />
            </div>
            <div>
              <Button type={"primary"} onClick={() => handleSave()}>
                {"保存"}
              </Button>
            </div>
          </Row>,
        ]}
      >
        <Spin spinning={props.loading}>
          <Row>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>ID</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.id}
                onChange={(e) => onChangeValue(e, "id")}
                readOnly
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>整理番号</p>
            </Col>
            <Col span={4}>
              <MasterCodeInput
                value={data.kaigoNaiyouCode}
                maxLength={10}
                checkType={props.editType === ADD}
                reference={reference}
                onChange={(value, first) => onChangeCode(value, first)}
              />
            </Col>
            <Col span={14}>
              <Checkbox
                type={"checkbox"}
                checked={data.stop}
                style={{ marginTop: 5, marginLeft: 50 }}
                onChange={(e) => onChangeValue(e, "stop")}
              >
                利用中止
              </Checkbox>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>記録区分</p>
            </Col>
            <Col span={14}>
              <Select
                style={{ width: "250px" }}
                value={data.kirokuKubun}
                onChange={(value) => handleChangeKubun(value)}
              >
                <Option value="" key={0}>
                  &nbsp;
                </Option>
                {Array.isArray(props.listBunrui)
                  ? props.listBunrui.map((item) => {
                    return (
                      <Option key={item.key} value={item.naiyou}>
                        {item.naiyou}
                      </Option>
                    );
                  })
                  : []}
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>分類</p>
            </Col>
            <Col span={8}>
              <Input
                style={{ width: "250px" }}
                value={data.kaigoBunrui}
                onChange={(e) => onChangeValue(e, "kaigoBunrui")}
              />
            </Col>
            <Col span={2} style={{ marginLeft: 5 }}>
              <YougoSelect
                data={props.dataNaiyou}
                onSubmit={(value) => onSubmitYougo(value, "kaigoBunrui")}
                yougoMasterUpdate={(naiyou) =>
                  yougoMasterUpdate("施設介護内容分類", naiyou)
                }
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>分類2</p>
            </Col>
            <Col span={8}>
              <Input
                style={{ width: "250px" }}
                value={data.kaigoBunrui2}
                onChange={(e) => onChangeValue(e, "kaigoBunrui2")}
              />
            </Col>
            <Col span={2} style={{ marginLeft: 5 }}>
              <YougoSelect
                data={props.dataNaiyou2}
                onSubmit={(value) => onSubmitYougo(value, "kaigoBunrui2")}
                yougoMasterUpdate={(naiyou) =>
                  yougoMasterUpdate("施設介護内容分類2", naiyou)
                }
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>介護内容</p>
            </Col>
            <Col span={4}>
              <Input
                style={{ width: "250px" }}
                value={data.kaigoNaiyou}
                onChange={(e) => onChangeValue(e, "kaigoNaiyou")}
                maxLength={8}
              />
            </Col>
            <Col span={10} style={{ marginLeft: 5 }}>
              <Checkbox
                type={"checkbox"}
                checked={data.kyotuf}
                style={{ marginTop: 5, marginLeft: 140 }}
                onChange={(e) => onChangeValue(e, "kyotuf")}
              >
                共通
              </Checkbox>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>略称</p>
            </Col>
            <Col span={14}>
              <Input
                style={{ width: "250px" }}
                value={data.ryaku}
                onChange={(e) => onChangeValue(e, "ryaku")}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>介護者</p>
            </Col>
            <Col span={8}>
              <Input
                style={{ width: "250px" }}
                value={data.tantosya}
                onChange={(e) => onChangeValue(e, "tantosya")}
                maxLength={12}
              />
            </Col>
            <Col span={2} style={{ marginLeft: 5 }}>
              <YougoSelect
                data={props.dataTanto}
                onSubmit={(value) => onSubmitYougo(value, "tantosya")}
                yougoMasterUpdate={(naiyou) =>
                  yougoMasterUpdate("介護担当者", naiyou)
                } // ここでアップデートする
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>順番</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.sortNum}
                onChange={(e) => onChangeValue(e, "sortNum")}
              />
            </Col>
          </Row>
        </Spin>
      </Modal>
    </div>)
  );
}

const mapStateToProps = (state) => {
  return {
    loading:
      state.referenceNumber.loading || state.sisetuKaigoNaiyouMaster.loading,
    newCode: state.referenceNumber.code,
    kaigoTanto: state.yougoMasterNaiyou.yougoMasterTanto,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSisetuKaigoNaiyouMasterInit: (callback) =>
      dispatch(getSisetuKaigoNaiyouMasterInit(callback)),
    createSisetuKaigoNaiyouCreator: (data, callback) =>
      dispatch(createSisetuKaigoNaiyouCreator(data, callback)),
    updateSisetuKaigoNaiyouCreator: (data, callback) =>
      dispatch(updateSisetuKaigoNaiyouCreator(data, callback)),
    yougoMasterUpdate: (key, naiyou) =>
      dispatch(yougoMasterUpdateNewCreator(key, naiyou)),
  };
};

const _KaigoNaiyouEditModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(KaigoNaiyouEditModal);

export { _KaigoNaiyouEditModal as KaigoNaiyouEditModal };
