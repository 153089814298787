import { ProgressActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";

function progressLIFE(state = {}, action) {
  switch (action.type) {
    // get all progress by user id
    case ProgressActionTypes.GET_ALL_PROGRESS_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case ProgressActionTypes.GET_ALL_PROGRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case ProgressActionTypes.GET_ALL_PROGRESS_FAILURE: {
      return {
        ...state,
        data: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }

    case ProgressActionTypes.GET_PROGRESS_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getOne",
      };
    }
    case ProgressActionTypes.GET_PROGRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case ProgressActionTypes.GET_PROGRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // create progress
    case ProgressActionTypes.CREATE_PROGRESS_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case ProgressActionTypes.CREATE_PROGRESS_SUCCESS:
    case ProgressActionTypes.CREATE_PROGRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // update progress
    case ProgressActionTypes.UPDATE_PROGRESS_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case ProgressActionTypes.UPDATE_PROGRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case ProgressActionTypes.UPDATE_PROGRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // remove progress
    case ProgressActionTypes.REMOVE_PROGRESS_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case ProgressActionTypes.REMOVE_PROGRESS_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case ProgressActionTypes.REMOVE_PROGRESS_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    case ProgressActionTypes.GET_ALL_PROGRESS_QUOTE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case ProgressActionTypes.GET_ALL_PROGRESS_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuote: action.data,
      };
    case ProgressActionTypes.GET_ALL_PROGRESS_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listQuote: [],
      };
    default: {
      return { ...state };
    }
  }
}

export default progressLIFE;
