/* eslint-disable */
import { Col } from 'antd';
import React from 'react';
import './SubHeaderV2.css';

export default class SubHeaderMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            TopLeft,
            TopMiddle,
            TopRight,
            TopRight2,
            IncBottom,
            FooterMobile,
        } = this.props;

        // let iconType = "notification"; // default is dengon
        // if (isDailyRe) {
        //     iconType = "form"
        // } else if (isPlan) {
        //     iconType = "schedule"
        // } else if (isUser) {
        //     iconType = "user"
        // } else if (isProgress) {
        //     iconType = "profile"
        // }

        // let menuName = localStorage.getItem('currentmenu');

        return (
            <div id="sub-header-v2" className="w-100">
                <div className="sub-header-v2-top">
                    <div className="sm-c-left">{TopLeft || null}</div>
                    {TopMiddle ? <div className="sm-c-middle">{TopMiddle}</div> : null}
                    <div className="sm-c-right">{TopRight || TopRight2 || null}</div>
                </div>

                {// UI Row under title and submenu
                    IncBottom
                        ?
                        <div>
                            {IncBottom}
                        </div>
                        :
                        null
                }
                {// UI Submenu footer mobile
                    FooterMobile
                        ?
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} className={"sm-footer-m-right"}>
                            {FooterMobile}
                        </Col>
                        :
                        null
                }
            </div>
        );
    }
}
