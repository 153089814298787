import {
  GET_ID_DOCUMENT_LIST,
  GET_ALL_DOCUMENT_LIST_REQUEST,
  GET_ALL_DOCUMENT_LIST_SUCCESS,
  GET_ALL_DOCUMENT_LIST_ERROR,
} from "./actionName";
import { DocumentListApi } from "../../api/documentList";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
import { LOAD_SPIN_REQUEST } from "../kyotakuPlan1Actions/actionName";

export function getIdForcus(id) {
  return {
    type: GET_ID_DOCUMENT_LIST,
    id,
  };
}
export function loadAllDocumentList(data) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    DocumentListApi.loadAllDocumentList(data).then(
      (data) =>
        dispatch({
          type: GET_ALL_DOCUMENT_LIST_SUCCESS,
          documentLists: data.data,
          meta: data.meta,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: GET_ALL_DOCUMENT_LIST_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
