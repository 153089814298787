import { DocumentActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";

function document(state = {}, action) {
  switch (action.type) {
    // get all document
    case DocumentActionTypes.GET_ALL_DOCUMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case DocumentActionTypes.GET_ALL_DOCUMENT_SUCCESS: {
      return {
        ...state,
        data: action.data || [],
        metaData: CommonUtils.pagination(action.meta),
        loading: false,
        needReload: null,
      };
    }
    case DocumentActionTypes.GET_ALL_DOCUMENT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case DocumentActionTypes.KEEP_VALUE_SEARCH:
      return {
        ...state,
        keepValue: action.keepValue,
      };

    default: {
      return { ...state };
    }
  }
}

export default document;
