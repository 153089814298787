export const KobetsuActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  GET_KOBETSU_DETAIL_SUCESS: "GET_KOBETSU_DETAIL_SUCESS",
  GET_KOBETSU_DETAIL_ERROR: "GET_KOBETSU_DETAIL_ERROR",

  CREATE_KOBETSU_SUCCESS: "CREATE_KOBETSU_SUCCESS",
  CREATE_KOBETSU_ERROR: "CREATE_KOBETSU_ERROR",

  UPDATE_KOBETSU_SUCCESS: "UPDATE_KOBETSU_SUCCESS",
  UPDATE_KOBETSU_ERROR: "UPDATE_KOBETSU_ERROR",

  REMOVE_KOBETSU_REQUEST: "REMOVE_KOBETSU_REQUEST",
  REMOVE_KOBETSU_SUCCESS: "REMOVE_KOBETSU_SUCCESS",
  REMOVE_KOBETSU_ERROR: "REMOVE_KOBETSU_ERROR",

  GET_FIRST_LOAD_DATA: "GET_FIRST_LOAD_DATA",
  GET_ZENKAI_RECORD: "GET_ZENKAI_RECORD",
  LOAD_KOBETU_KINOU_TO_QUOTE_SUCCESS: "LOAD_KOBETU_KINOU_TO_QUOTE_SUCCESS",
  SET_ZENKAI_RECORD: "SET_ZENKAI_RECORD",

  GET_GOTORECORD_RECORD: "GET_GOTORECORD_RECORD",
  GET_LIST_IDS_KOBETU: "GET_LIST_IDS_KOBETU",

  LOAD_KOBETSU_BY_IDS_SUCCESS: "LOAD_KOBETSU_BY_IDS_SUCCESS",
  LOAD_KOBETSU_BY_IDS_ERROR: "LOAD_KOBETSU_BY_IDS_ERROR",
  LOAD_KOBETSU_BY_IDS_REQUEST: "LOAD_KOBETSU_BY_IDS_REQUEST",
  CLEAR_KOBETSU_BY_IDS: "CLEAR_KOBETSU_BY_IDS",

  GET_KOBETSU_LIFE_YOUGO_MASTER_REQUEST: "GET_KOBETSU_LIFE_YOUGO_MASTER_REQUEST",
  GET_KOBETSU_LIFE_YOUGO_MASTER_SUCCESS: "GET_KOBETSU_LIFE_YOUGO_MASTER_SUCCESS",
  GET_KOBETSU_LIFE_YOUGO_MASTER_ERROR: "GET_KOBETSU_LIFE_YOUGO_MASTER_ERROR",

  SET_NEW_DATA: "SET_NEW_DATA"
};
