import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Col, Input, Modal, Row, Table } from "antd";
import axios from "axios";
import React from "react";
import { apiUrls } from "../../configs/common-url";
import {
  formatStorage,
  getMenu,
  isLife,
  isManagement,
} from "../../function_common/functionCommon";
import FuriganaKeyboard from "../FuriganaKeyBoard";

export default class SearchRiyousyaModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listData: [],
      selectedRiyousya: null,
      furiganaText: "",
      isLoading: false,
      mode: "input",
    };
  }

  fetchUser(value) {
    const check = isManagement() || isLife();
    if (check) {
      const systemName = formatStorage(getMenu()).scn;
      const mainSid = formatStorage(getMenu()).si;
      axios(
        apiUrls.INIT +
        apiUrls.RIYOUSYA_SEARCH2 +
        "?textSearch=" +
        value +
        "&systemName=" +
        systemName,
        "&mainSid=" +
        mainSid,
        {
          method: "GET",
        }
      )
        .then((res) => {
          this.setState({
            listData: res.data,
            isLoading: false,
          });
        })
        .catch(() => {
          this.setState({
            listData: [],
            isLoading: false,
          });
        });
    }
    else {
      axios(apiUrls.INIT + apiUrls.RIYOUSYA_SEARCH + "?textSearch=" + value, {
        method: "GET",
      })
        .then((res) => {
          this.setState({
            listData: res.data,
            isLoading: false,
          });
        })
        .catch(() => {
          this.setState({
            listData: [],
            isLoading: false,
          });
        });
    }
  }

  handleClose() {
    this.setState({
      listData: [],
      selectedRiyousya: null,
      furiganaText: "",
      mode: "input",
    });
    this.props.onCancel();
  }

  handleCancelSearchFurigana() {
    if (this.state.mode === "input") {
      this.setState({
        listData: [],
        selectedRiyousya: null,
        furiganaText: "",
        mode: "input",
      });
      this.props.onCancel();
    }
    else if (this.state.mode === "result") {
      this.setState({
        selectedRiyousya: null,
        mode: "input",
      });
    }
  }

  handleOkSearchFurigana() {
    if (this.state.mode === "input") {
      this.setState({
        listData: [],
        mode: "result",
        isLoading: true,
      });
      this.fetchUser(this.state.furiganaText);
    }
    else {
      this.props.onOk(this.state.selectedRiyousya);
      this.setState({
        listData: [],
        selectedRiyousya: null,
        furiganaText: "",
        mode: "input",
      });
    }
  }

  onChangeFurigana(e) {
    this.setState({
      furiganaText: e.target.value,
    });
  }

  getHandleFurigana(furiganaText) {
    this.setState({
      furiganaText,
    });
  }

  handleSelectUser(record) {
    this.setState({
      selectedRiyousya: record,
    });
  }

  render() {
    const columns = [
      {
        dataIndex: "name",
        key: "col1",
        width: "30%",
      },
      {
        dataIndex: "furigana",
        key: "col2",
        width: "40%",
      },
      {
        dataIndex: "birthday",
        key: "col3",
        width: "30%",
      },
    ];
    return (
      (<Modal
        open={this.props.visible}
        title={"ふりがな検索"}
        onCancel={() => this.handleClose()}
        width={560}
        id="model-search-select-user"
        footer={[
          <Button key="back" onClick={() => this.handleCancelSearchFurigana()}>
            キャンセル
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => this.handleOkSearchFurigana()}
            disabled={
              this.state.mode === "result" &&
              !this.state.listData.some(
                (item) =>
                  this.state.selectedRiyousya != null &&
                  item.id === this.state.selectedRiyousya.id
              )
            }
          >
            OK
          </Button>,
        ]}
      >
        {this.state.mode === "input" ? (
          <div>
            <FuriganaKeyboard
              furigana={(furiganaText) => this.getHandleFurigana(furiganaText)}
              furiganaState={this.state.furiganaText}
            />
          </div>
        ) : (
          <div>
            <Row>
              <Col span={12}>
                <Input
                  value={this.state.furiganaText}
                  onChange={(e) => this.onChangeFurigana(e)}
                />
              </Col>
              <Col span={6} offset={6}>
                <Button
                  icon={<LegacyIcon type={"search"} />}
                  style={{ float: "right" }}
                  onClick={() => {
                    this.setState({
                      listData: [],
                      isLoading: true,
                    });
                    this.fetchUser(this.state.furiganaText);
                  }}
                >
                  {"検索"}
                </Button>
              </Col>
            </Row>
            <div style={{ marginTop: "16px" }}>
              <Table
                size={"small"}
                showHeader={false}
                header={null}
                footer={null}
                columns={columns}
                rowKey={"id"}
                dataSource={this.state.listData}
                loading={this.state.isLoading}
                onRow={(record) => {
                  return {
                    onClick: () => this.handleSelectUser(record),
                  };
                }}
                rowClassName={(record) =>
                  this.state.selectedRiyousya != null &&
                    record.id === this.state.selectedRiyousya.id
                    ? "pljp-table-row-hover"
                    : "pointer-row"
                }
                style={{ width: "100%" }}
                bordered
              />
            </div>
          </div>
        )}
      </Modal>)
    );
  }
}
