import { notification } from "antd";
import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";

import { getPath } from "../../common/function_common/functionCommon";
import ActionsRow from "./UI/ActionsRow";
import WrappedTMForm from "./UI/TMForm";

class KanriSettingMasterSave extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      tmId: parseInt(match.params.tmId),
      path: getPath(),
    };

    this.registerTM = this.registerTM.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    // load data
    this.props.getTMList();
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  async registerTM(values) {
    if (values.id) {
      await this.props.updateTM(values);
    }
    else {
      await this.props.createTM(values);
    }
    this.props.history.goBack();
  }

  async delete(id) {
    await this.props.deleteTM(id.toString());

    this.props.history.goBack();
  }

  preToEnd = () => {
    const { kanriTMList } = this.props;
    const { tmId, path } = this.state;

    if (kanriTMList && kanriTMList.length > 0) {
      const index = kanriTMList.findIndex((item) => item.id === tmId);

      if (index >= 1) {
        this.setState({
          tmId: kanriTMList[0].id,
        });

        this.props.history.replace(
          path + "/kanri/tantomaster/" + kanriTMList[0].id
        );
      }
    }
  };

  pre = () => {
    const { kanriTMList } = this.props;
    const { tmId, path } = this.state;

    if (kanriTMList && kanriTMList.length > 0) {
      const index = kanriTMList.findIndex((item) => item.id === tmId);

      if (index >= 1) {
        this.setState({
          tmId: kanriTMList[index - 1].id,
        });

        this.props.history.replace(
          path + "/kanri/tantomaster/" + kanriTMList[index - 1].id
        );
      }
    }
  };

  next = () => {
    const { kanriTMList } = this.props;
    const { tmId, path } = this.state;

    if (kanriTMList && kanriTMList.length > 0) {
      const index = kanriTMList.findIndex((item) => item.id === tmId);

      if (index >= 0 && index < kanriTMList.length - 1) {
        this.setState({
          tmId: kanriTMList[index + 1].id,
        });

        this.props.history.replace(
          path + "/kanri/tantomaster/" + kanriTMList[index + 1].id
        );
      }
    }
  };

  nextToEnd = () => {
    const { kanriTMList } = this.props;
    const { tmId, path } = this.state;

    if (kanriTMList && kanriTMList.length > 0) {
      const index = kanriTMList.findIndex((item) => item.id === tmId);

      if (index !== kanriTMList.length) {
        this.setState({
          tmId: kanriTMList[kanriTMList.length - 1].id,
        });

        this.props.history.replace(
          path + "/kanri/tantomaster/" + kanriTMList[kanriTMList.length - 1].id
        );
      }
    }
  };

  recordMark = (type, id) => {
    this.props.recordMark("tm", type, id);
  };

  render() {
    const { kanriTMList, tmSelectedRows } = this.props;
    const { tmId } = this.state;

    return (
      <KanriLayoutContainer router="tantomaster" breadcrumbLink={"/kanri/tantomaster"} breadcrumbText={"担当者マスタ"}>
        <ActionsRow
          key={tmSelectedRows}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          selectedList={tmSelectedRows}
          id={tmId}
        />

        <WrappedTMForm
          data={kanriTMList.find((item) => item.id === tmId)}
          history={this.props.history}
          regData={(values) => this.registerTM(values)}
          delete={this.delete}
        />
      </KanriLayoutContainer>
    );
  }
}

export default KanriSettingMasterSave;
