export const KAIGOHOKEN_MASTER_SPIN_REQUEST = "KAIGOHOKEN_MASTER_SPIN_REQUEST";

export const KAIGOHOKEN_MASTER_LOAD_REQUEST = "KAIGOHOKEN_MASTER_LOAD_REQUEST";
export const KAIGOHOKEN_MASTER_CREATE_REQUEST =
  "KAIGOHOKEN_MASTER_CREATE_REQUEST";
export const KAIGOHOKEN_MASTER_UPDATE_REQUEST =
  "KAIGOHOKEN_MASTER_UPDATE_REQUEST";
export const KAIGOHOKEN_MASTER_INIT_REQUEST = "KAIGOHOKEN_MASTER_INIT_REQUEST";
export const KAIGOHOKEN_MASTER_UPDATELIST_REQUEST =
  "KAIGOHOKEN_MASTER_UPDATELIST_REQUEST";

export const LOAD_KAIGOHOKEN_MASTER_SUCCESS = "LOAD_KAIGOHOKEN_MASTER_SUCCESS";
export const LOAD_KAIGOHOKEN_MASTER_ERROR = "LOAD_KAIGOHOKEN_MASTER_ERROR";

export const UPDATE_LIST_KAIGOHOKEN_MASTER_SUCCESS =
  "UPDATE_LIST_KAIGOHOKEN_MASTER_SUCCESS";

export const LOAD_KAIGOHOKEN_MASTER_INIT_SUCCESS =
  "LOAD_KAIGOHOKEN_MASTER_INIT_SUCCESS";
export const LOAD_KAIGOHOKEN_MASTER_INIT_ERROR =
  "LOAD_KAIGOHOKEN_MASTER_INIT_ERROR";

export const CREATE_KAIGOHOKEN_MASTER_SUCCESS =
  "CREATE_KAIGOHOKEN_MASTER_SUCCESS";
export const CREATE_KAIGOHOKEN_MASTER_ERROR = "CREATE_KAIGOHOKEN_MASTER_ERROR";

export const UPDATE_KAIGOHOKEN_MASTER_SUCCESS =
  "UPDATE_KAIGOHOKEN_MASTER_SUCCESS";
export const UPDATE_KAIGOHOKEN_MASTER_ERROR = "UPDATE_KAIGOHOKEN_MASTER_ERROR";
