import {
  LOAD_KYOTAKU_PLAN1_BY_ID_SUCCESS,
  LOAD_KYOTAKU_PLAN1_BY_ID_ERROR,
  LOAD_KYOTAKU_PLAN1_TO_QUOTE_SUCCESS,
  LOAD_KYOTAKU_PLAN1_TO_QUOTE_ERROR,
  CREATE_KYOTAKU_PLAN1_SUCCESS,
  CREATE_KYOTAKU_PLAN1_ERROR,
  UPDATE_KYOTAKU_PLAN1_SUCCESS,
  UPDATE_KYOTAKU_PLAN1_ERROR,
  LOAD_YOUGO_ATTRIBUTE_SUCCESS,
  LOAD_YOUGO_ATTRIBUTE_ERROR,
  LOAD_YOUGO_BUNRUI1_SUCCESS,
  LOAD_YOUGO_BUNRUI1_ERROR,
  LOAD_YOUGO_BUNRUI2_SUCCESS,
  LOAD_YOUGO_BUNRUI2_ERROR,
  LOAD_YOUGO_KEYWORD_SUCCESS,
  LOAD_YOUGO_KEYWORD_ERROR,
  CHANGE_VALUE_DETAIL1,
  RESET_STATUS,
  LOAD_YOUGO_FUSANKA_RIYU_SUCCESS,
  LOAD_YOUGO_FUSANKA_RIYU_ERROR,
  LOAD_SETTING_INPUT1_SUCCESS,
  LOAD_SETTING_INPUT1_ERROR,
} from "../../actions/kyotakuPlan1Actions/actionName";
import {
  LOAD_RIYOU_KAIGOHOKEN_LIST_SUCCESS,
  LOAD_RIYOU_KAIGOHOKEN_LIST_ERROR,
} from "../../actions/riyouKaigohoken/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

export default function kyotakuPlan1(
  state = { valuesChange1: false, status: null },
  action
) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        status: null,
      };
    case CHANGE_VALUE_DETAIL1:
      return {
        ...state,
        valuesChange1: action.types,
      };
    case LOAD_KYOTAKU_PLAN1_BY_ID_SUCCESS:
      return {
        ...state,
        kyotakuPlan1: action.kyotakuPlan1,
      };
    case LOAD_KYOTAKU_PLAN1_BY_ID_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        kyotakuPlan1: null,
      };
    case CREATE_KYOTAKU_PLAN1_SUCCESS:
      openNotificationWithIcon("success", "保存しました", action.callback);
      localStorage.setItem(
        "RowKey1",
        action.createdKyotakuPlan1.id +
          "_" +
          action.createdKyotakuPlan1.riyousyaId +
          "_" +
          action.createdKyotakuPlan1.sid
      );
      return {
        ...state,
        kyotakuPlan1: action.createdKyotakuPlan1,
        status: "success",
        valuesChange1: false,
      };
    case CREATE_KYOTAKU_PLAN1_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        status: "error",
      };
    case UPDATE_KYOTAKU_PLAN1_SUCCESS:
      openNotificationWithIcon("success", "保存しました", action.callback);
      return {
        ...state,
        kyotakuPlan1: action.updatedKyotakuPlan1,
        valuesChange1: false,
      };
    case UPDATE_KYOTAKU_PLAN1_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case LOAD_RIYOU_KAIGOHOKEN_LIST_SUCCESS:
      return {
        ...state,
        riyouKaigohokens: action.data,
      };
    case LOAD_RIYOU_KAIGOHOKEN_LIST_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        riyouKaigohokens: action.data,
      };
    case LOAD_YOUGO_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        yougoAttribute: action.naiyou ? action.naiyou.split(";") : [],
      };
    case LOAD_YOUGO_ATTRIBUTE_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoAttribute: action.naiyou,
      };
    case LOAD_YOUGO_BUNRUI1_SUCCESS:
      return {
        ...state,
        yougoBunrui1: action.naiyou ? action.naiyou.split(";") : [],
      };
    case LOAD_YOUGO_BUNRUI1_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoBunrui1: action.naiyou,
      };
    case LOAD_YOUGO_BUNRUI2_SUCCESS:
      return {
        ...state,
        yougoBunrui2: action.naiyou ? action.naiyou.split(";") : [],
      };
    case LOAD_YOUGO_BUNRUI2_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoBunrui2: action.naiyou,
      };
    case LOAD_YOUGO_KEYWORD_SUCCESS:
      return {
        ...state,
        yougoKeyword: action.naiyou ? action.naiyou.split(";") : [],
      };
    case LOAD_YOUGO_KEYWORD_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoKeyword: action.naiyou,
      };
    case LOAD_KYOTAKU_PLAN1_TO_QUOTE_SUCCESS:
      return {
        ...state,
        kyotakuPlan1s: action.kyotakuPlan1s,
      };
    case LOAD_KYOTAKU_PLAN1_TO_QUOTE_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case LOAD_YOUGO_FUSANKA_RIYU_SUCCESS:
      return {
        ...state,
        yougoFusankaRiyu: action.naiyou ? action.naiyou.split(";") : [],
      };
    case LOAD_YOUGO_FUSANKA_RIYU_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoFusankaRiyu: action.naiyou,
      };
    case LOAD_SETTING_INPUT1_SUCCESS:
      return {
        ...state,
        settingInput1: action.naiyou ? action.naiyou.split(";") : [],
      };
    case LOAD_SETTING_INPUT1_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        settingInput1: action.naiyou,
      };
    default:
      return state;
  }
}
