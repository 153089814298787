import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Table, notification, Menu, Dropdown, Modal } from "antd";

import SearchDialog from "./UI/SearchDialog";
import {
  setValueLocalstorage,
  getPath,
} from "../../common/function_common/functionCommon";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

class File extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      selectedRowKeys: [], // Check here to configure the default column
      displayList: [],
      visible: false,
      visibleDelConfirmPopup: false,
      currentPage: props.currentFile,
      pageSize: props.pageSizeFile, // @TODO setting in file,
      text1: "",
      text2: "",
      text3: "",
      path: getPath(),
    };

    this.reloadAll = this.reloadAll.bind(this);
  }

  componentDidMount() {
    // load data
    const { currentPage, pageSize } = this.state;

    // update from state
    const { fileDisplayList, fileSelectedRows, isFileSelectedDisplay } =
      this.props;

    this.props.getSearchData("file");

    if (
      fileDisplayList &&
      fileDisplayList.length > 0 &&
      isFileSelectedDisplay
    ) {
      this.props.selectedFileDisplay(fileDisplayList);
    } else {
      this.props.getFileList(currentPage, pageSize);
    }

    if (fileSelectedRows && fileSelectedRows.length > 0) {
      this.setState({
        selectedRowKeys: fileSelectedRows,
      });
    }
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  handleChange = (pagination, filters, sorter) => {
    // check pagination have been changed

    if (pagination) {
      const { currentPage, pageSize } = this.state;

      if (currentPage !== pagination.current) {
        this.props.getFileList(pagination.current, pageSize);
      }

      this.setState({
        currentPage: pagination.current,
      });
    }

    //
    // if (sorter && sorter.field) {
    //     const { currentPage, pageSize, text1, text2, text3 } = this.state;

    //     // console.log(sorter)
    //     if(sorter.order === "descend"){
    //         this.props.getFileList(currentPage, pageSize, text1, text2, `${sorter.field} desc`);
    //     }else if(sorter.order === "ascend"){
    //         this.props.getFileList(currentPage, pageSize, text1, text2, `${sorter.field} acs`);
    //     }

    // }

    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  /**
   * Reload all data from server
   *
   */
  async reloadAll() {
    //
    await this.props.clearSelectedRow("file");

    //
    this.setState({
      selectedRowKeys: [],
    });

    this.props.getFileList();
  }

  onSelectChange = (selectedRowKeys) => {
    // this this info have been save in global state
    this.setState({ selectedRowKeys });

    this.props.updateSelectedRows("file", selectedRowKeys);
  };

  handleOkSearch = (text1, text2, text3) => {
    const { currentPage, pageSize } = this.state;
    this.props.postFileList(currentPage, pageSize, text1, text2, text3);
    //
    this.setState({
      visible: false,
      text1: text1,
      text2: text2,
      text3: text3,
    });
  };

  handleCancelSearch = (e) => {
    //
    this.setState({
      visible: false,
    });
  };

  disSearchDialog = (e) => {
    //
    this.setState({
      visible: true,
    });
  };

  selectedDisplay = () => {
    const { selectedRowKeys } = this.state;

    // UPDATE ON LOCAL
    this.props.selectedFileDisplay(selectedRowKeys);
  };

  setDisplayList = () => {
    const displayList = [];
    const list = this.props.kanriFileList;
    for (let i in list) {
      displayList.push(list[i].id);
    }
    this.props.selectedFileDisplay(displayList);
  };

  loginKanri = (record) => {
    //
    setValueLocalstorage("tid", record.tantoMaster ? record.tantoMaster.id : 0); // fix tid
    setValueLocalstorage(
      "mode",
      record.tantoMaster ? record.tantoMaster.name : "kanri"
    ); // kanri login mode

    this.props.history.push("/dengon");
  };

  handleDel = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.setState({
        visibleDelConfirmPopup: true,
      });
    }
  };

  handleOKDelConfim = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.props.deleteFile(selectedRowKeys.toString());
    }

    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  handleCancelDelConfim = () => {
    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  render() {
    const {
      kanriFileList,
      loading,
      searchData,
      currentFile,
      pageSizeFile,
      totalFile,
    } = this.props;
    let { sortedInfo, filteredInfo, visible, selectedRowKeys, path } =
      this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    // prepare export data
    let dataExportCsv = [];
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      let tmpDataExportCsv = kanriFileList.filter((item) =>
        selectedRowKeys.includes(item.id)
      );
      if (tmpDataExportCsv && tmpDataExportCsv.length > 0)
        for (var i = 0; i < tmpDataExportCsv.length; i++) {
          dataExportCsv.push({
            id: tmpDataExportCsv[i].id,
            利用者ID: tmpDataExportCsv[i].riyousya
              ? tmpDataExportCsv[i].riyousya.id
              : "",
            担当者ID: tmpDataExportCsv[i].tantoMaster
              ? tmpDataExportCsv[i].tantoMaster.id
              : "",
            日付: tmpDataExportCsv[i].date,
            時間: tmpDataExportCsv[i].time,
            ファイルタイプ: tmpDataExportCsv[i].type,
            ファイル名: tmpDataExportCsv[i].fileName,
            パス: tmpDataExportCsv[i].path,
            コメント: tmpDataExportCsv[i].comment,
            優先: tmpDataExportCsv[i].priorityf,
            看護実績ID: tmpDataExportCsv[i].kangoJissekiId,
            ﾍﾙﾊﾟｰ実績ID: tmpDataExportCsv[i].helperJissekiId,
            小規模実績ID: tmpDataExportCsv[i].syoukiboJissekiId,
            訪問リハ実績ID: tmpDataExportCsv[i].visitrehaJissekiId,
            ケース記録ID: tmpDataExportCsv[i].reportCaseId,
            区分: tmpDataExportCsv[i].kubun,
          });
        }
    }

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id - b.id,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        ellipsis: true,
        width: 50,
      },
      {
        title: "利用者ID",
        dataIndex: "riyousya",
        key: "riyousya",
        sorter: (a, b) =>
          (a.riyousya ? a.riyousya.id : 0) - (b.riyousya ? b.riyousya.id : 0),
        sortOrder: sortedInfo.columnKey === "riyousya" && sortedInfo.order,
        ellipsis: true,
        width: 100,
        render: (text, record) =>
          record && record.riyousya ? record.riyousya.id : "",
      },
      {
        title: "担当者ID",
        dataIndex: "tantoMaster",
        key: "tantoMaster",
        sorter: (a, b) =>
          (a.tantoMaster ? a.tantoMaster.id : 0) -
          (b.tantoMaster ? b.tantoMaster.id : 0),
        sortOrder: sortedInfo.columnKey === "tantoMaster" && sortedInfo.order,
        ellipsis: true,
        width: 100,
        render: (text, record) =>
          record && record.tantoMaster ? record.tantoMaster.id : "",
      },

      {
        title: "日付",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) =>
          (a.date ? a.date : "") < (b.date ? b.date : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "date" && sortedInfo.order,
        ellipsis: true,
        width: 100,
      },

      {
        title: "時間",
        dataIndex: "time",
        key: "time",
        sorter: (a, b) =>
          (a.time ? a.time : "") < (b.time ? b.time : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "time" && sortedInfo.order,
        ellipsis: true,
        width: 100,
      },

      {
        title: "タイプ",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) =>
          (a.type ? a.type : "") < (b.type ? b.type : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "type" && sortedInfo.order,
        ellipsis: true,
        width: 100,
      },

      {
        title: "ファイル名",
        dataIndex: "fileName",
        key: "fileName",
        sorter: (a, b) =>
          (a.fileName ? a.fileName : "") < (b.fileName ? b.fileName : "")
            ? -1
            : 1,
        sortOrder: sortedInfo.columnKey === "fileName" && sortedInfo.order,
        ellipsis: true,
      },

      {
        title: "パス",
        dataIndex: "path",
        key: "path",
        sorter: (a, b) =>
          (a.path ? a.path : "") < (b.path ? b.path : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "path" && sortedInfo.order,
        ellipsis: true,
        width: 350,
      },

      {
        title: "コメント",
        dataIndex: "comment",
        key: "comment",
        sorter: (a, b) =>
          (a.comment ? a.comment : "") < (b.comment ? b.comment : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "comment" && sortedInfo.order,
        ellipsis: true,
        width: 350,
      },

      {
        title: "優先",
        dataIndex: "priorityf",
        key: "priorityf",
        sorter: (a, b) =>
          (a.priorityf ? a.priorityf : 0) - (b.priorityf ? b.priorityf : 0),
        sortOrder: sortedInfo.columnKey === "priorityf" && sortedInfo.order,
        ellipsis: true,
        width: 80,
      },

      {
        title: "看護実績ID",
        dataIndex: "kangoJissekiId",
        key: "kangoJissekiId",
        sorter: (a, b) =>
          (a.kangoJissekiId ? a.kangoJissekiId : 0) -
          (b.kangoJissekiId ? b.kangoJissekiId : 0),
        sortOrder:
          sortedInfo.columnKey === "kangoJissekiId" && sortedInfo.order,
        ellipsis: true,
        width: 150,
      },

      {
        title: "ﾍﾙﾊﾟｰ実績ID",
        dataIndex: "helperJissekiId",
        key: "helperJissekiId",
        sorter: (a, b) =>
          (a.helperJissekiId ? a.helperJissekiId : 0) -
          (b.helperJissekiId ? b.helperJissekiId : 0),
        sortOrder:
          sortedInfo.columnKey === "helperJissekiId" && sortedInfo.order,
        ellipsis: true,
        width: 150,
      },

      {
        title: "訪問リハ実績ID",
        dataIndex: "visitrehaJissekiId",
        key: "visitrehaJissekiId",
        sorter: (a, b) =>
          (a.visitrehaJissekiId ? a.visitrehaJissekiId : 0) -
          (b.visitrehaJissekiId ? b.visitrehaJissekiId : 0),
        sortOrder:
          sortedInfo.columnKey === "visitrehaJissekiId" && sortedInfo.order,
        ellipsis: true,
        width: 150,
      },

      {
        title: "ケース記録ID",
        dataIndex: "reportCaseId",
        key: "reportCaseId",
        sorter: (a, b) =>
          (a.reportCaseId ? a.reportCaseId : 0) -
          (b.reportCaseId ? b.reportCaseId : 0),
        sortOrder: sortedInfo.columnKey === "reportCaseId" && sortedInfo.order,
        ellipsis: true,
        width: 150,
      },

      {
        title: "区分",
        dataIndex: "kubun",
        key: "kubun",
        sorter: (a, b) =>
          (a.kubun ? a.kubun : "") < (b.kubun ? b.kubun : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "kubun" && sortedInfo.order,
        ellipsis: true,
        width: 80,
      },
      {
        title: "アクション",
        key: "operation",
        // fixed: "right",
        render: (text, record, index) => (
          <div>
            <Button type="primary" onClick={this.setDisplayList}>
              <Link to={`${path}/kanri/file/${record.id}`}>編集</Link>
            </Button>
          </div>
        ),
        width: 120,
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const menu = (
      <Menu>
        <Menu.Item>
          <CSVLink
            filename={`ファイル${Date.now()}.csv`}
            data={dataExportCsv}
            className="btn btn-primary"
            disabled={dataExportCsv && dataExportCsv.length > 0 ? false : true}
          >
            ダウンロード
          </CSVLink>
        </Menu.Item>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" onClick={this.handleDel}>
            削除
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      (<KanriLayoutContainer router="file" breadcrumbLink={"/kanri/file"} breadcrumbText={"ファイル管理"}>
        <div className="kanri-file-page">
          <div className="table-operations">
            <Button type="primary" onClick={this.reloadAll}>
              全表示
            </Button>
            <Button type="primary" onClick={this.disSearchDialog}>
              検索
            </Button>
            <Button type="primary" onClick={this.selectedDisplay}>
              選択表示
            </Button>
            <Button type="primary">
              <Link to={`${path}/kanri/file/new`}>新規登録</Link>
            </Button>
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button type="primary">
                その他機能 <CaretDownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className="kanri-table">
            <Table
              columns={columns}
              dataSource={kanriFileList}
              rowSelection={rowSelection}
              className="table"
              rowKey={"id"}
              pagination={{
                pageSize: pageSizeFile,
                current: currentFile,
                total: totalFile,
              }}
              onChange={this.handleChange}
              size="small"
              bordered
              loading={loading}
              scroll={{ x: 2300 }}
            />
          </div>
        </div>
        <SearchDialog
          title={"検索画面"}
          subTitle1={"対象項目"}
          subTitle2={"検索する文字"}
          subTitle3={"検索方法"}
          visible={visible}
          handleOk={this.handleOkSearch}
          handleCancel={this.handleCancelSearch}
          searchData={searchData}
        />
        <Modal
          title="処理確認"
          open={this.state.visibleDelConfirmPopup}
          onOk={this.handleOKDelConfim}
          onCancel={this.handleCancelDelConfim}
          okText="OK"
          cancelText="キャンセル"
        >
          <p>削除してもよろしいでしょうか？</p>
        </Modal>
      </KanriLayoutContainer>)
    );
  }
}

export default File;
