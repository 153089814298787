import {
  CaretDownOutlined,
  CaretUpOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { Button, Divider, Input, Popconfirm, Popover, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  convertArrStrToArrObj
} from "../../../common/function_common/functionDisplay";
import TextInput from "./TextInput";

const EditableCell = ({
  editIndex,
  editValue,
  value,
  index,
  onChange,
  Expansion,
  sort,
}) => (
  <div>
    {editIndex === index ? (
      <Input
        style={{ margin: "-5px 0" }}
        defaultValue={editValue}
        onBlur={(e) => onChange(e.target.value)}
      />
    ) : Expansion && !sort ? (
      <div
        style={{
          maxWidth: "310px",
          whiteSpace: "normal",
          overflowWrap: "normal",
        }}
      >
        {value}
      </div>
    ) : (
      <div
        style={{
          maxWidth: "310px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </div>
    )}
  </div>
);

class YougoSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editIndex: null,
      editValue: "",
      editKey: null,
      newText: "",
      visible: false,
      yougoVisible: false,
      rowActive: null,
      record: null,
      name: "",
      selected: null,
      sort: false,
      sortTable2: [],
      Expansion: false,
    };

    // bind place
  }

  /**
   * 開いたときに検索結果の初期化
   *
   *
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible != this.state.visible && this.state.visible) {
      // this.props.search(); //送られる元のsearch モーダルごとにサーチの項目を変える
    }
  }

  /**
   * Render colum of table
   *
   * (EDITABLE TABLE)
   */
  renderColumns = (record, index) => {
    const { editIndex, editValue, Expansion, sort } = this.state;

    return (
      <EditableCell
        sort={sort}
        Expansion={Expansion}
        editIndex={editIndex}
        editValue={editValue}
        value={record && record.naiyou ? record.naiyou : ""}
        index={index}
        onChange={(value) => this.handleChange(value)}
      />
    );
  };

  /**
   * When input change on EDITING MODE
   *
   */
  handleChange = (value) => {
    this.setState({
      editValue: value,
    });
  };

  /**
   * Handle Row Table
   *
   */
  edit = (record, index) => {
    this.setState({
      editValue: record.naiyou,
      editIndex: index,
      editKey: record.key,
    });
  };

  /**
   * Save function after eddting row
   *
   */
  save = () => {
    const { editValue, editKey } = this.state;
    if (editValue) {
      const { data } = this.props;

      // let tmpUpdate = data

      if (data[editKey]) {
        const tmpUpdate = data;
        tmpUpdate[editKey] = editValue;

        this.props.yougoMasterUpdate(tmpUpdate.join(";"));
      }
    }

    // reset state
    this.setState({
      editIndex: null,
      editValue: "",
      editKey: null,
    });
  };

  /**
   * Remove record of yougo_master naiyou
   *
   */
  delete = (record) => {
    const { data } = this.props;
    let preNaiyou = "";
    for (let i = 0; i < data.length; i++) {
      if (i !== record.key) {
        if (!preNaiyou) {
          preNaiyou = data[i];
        }
        else {
          preNaiyou = preNaiyou + ";" + data[i];
        }
      }
    }

    this.props.yougoMasterUpdate(preNaiyou);
  };

  /**
   * Cancel table input mode. (Remove input mode on row of table)
   *
   */
  cancel = () => {
    // reset state
    this.setState({
      editIndex: null,
      editValue: "",
    });
  };

  /**
   * Input for Add new function
   *
   */
  handleOnchangeNew = (value) => {
    this.setState({
      newText: value,
    });
  };

  /**
   * Add new record when click 追加 button(title place)
   *
   */
  add = () => {
    const { newText } = this.state;
    if (newText) {
      const { data } = this.props;
      let preNaiyou = newText;

      if (data) {
        for (let i = 0; i < data.length; i++) {
          preNaiyou = preNaiyou + ";" + data[i];
        }
      }

      this.props.yougoMasterUpdate(preNaiyou);

      // reset
      this.setState({
        newText: "",
      });
    }
  };

  /**
   * trigger dirrectly from input
   *
   */
  select = (record) => {
    // const { value, isOveride } = this.props
    let tpm = "";

    if (record && record.naiyou) {
      // if (value && !isOveride) {
      //     tpm = value + '、' + record.naiyou
      // } else {
      tpm = record.naiyou;
      // }
    }

    this.triggerChange(tpm);
  };

  /**
   * triiger from table select row (yougo_master)
   *
   */
  handleSelectChange = (value) => {
    try {
      this.triggerChange(value);
    }
    catch (e) {
      console.log(e);
    }
  };

  /**
   * Should provide an event to pass value to Form.
   *
   */
  triggerChange = (changedValue) => {
    const { onSubmit } = this.props;
    try {
      if (onSubmit) {
        onSubmit(changedValue);
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  onChangeNameSearch = (value) => {
    this.setState({
      name: value,
    });
  };

  onChangePopover = (value) => {
    // ture or false
    this.setState({
      yougoVisible: value,
      sort: false,
      Expansion: false,
    });
  };

  handleSelectRow(record) {
    // 選択したレコードをステートselectedに保存
    this.setState({
      selected: record,
    });
  }

  sortOn = () => {
    const { data } = this.props;
    this.setState({
      sort: true,
      sortTable2: data,
    });
  };

  sortOff = () => {
    this.setState({
      sort: false,
      sortTable2: [],
    });
  };

  sortSave = () => {
    const { sortTable2 } = this.state;
    this.props.yougoMasterUpdate(sortTable2.join(";"));

    this.setState({
      // テーブルを初期化
      sort: false,
      sortTable2: [],
    });
  };
  sortTop = (sortTable2, record) => {
    if (record.key != 0) {
      const newIndex = 0;
      const copyArray = sortTable2.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable2: copyArray,
      });
    }
  };
  sortUp = (sortTable2, record) => {
    if (record.key != 0) {
      // 1
      const newIndex = record.key - 1; // 0
      const copyArray = sortTable2.slice();
      const element = copyArray[record.key]; // element= 移動する要素
      copyArray.splice(record.key, 1); // (現在の要素番号の要素を削除, 削除する)
      copyArray.splice(newIndex, 0, element); // (移動先の要素番号, 削除する要素0, 移動する要素)
      this.setState({
        sortTable2: copyArray,
      });
    }
  };
  sortDown = (sortTable2, record) => {
    if (sortTable2.length - 1 != record.key) {
      const newIndex = record.key + 1;
      const copyArray = sortTable2.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable2: copyArray,
      });
    }
  };
  sortBottom = (sortTable2, record, index) => {
    if (sortTable2.length - 1 != index) {
      const newIndex = sortTable2.length - 1;
      const copyArray = sortTable2.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable2: copyArray,
      });
    }
  };

  handleExpandTab = () => {
    // パネル全体
    const { Expansion } = this.state;

    if (Expansion) {
      this.setState({
        Expansion: false,
      });
    }
    else {
      this.setState({
        Expansion: true,
      });
    }
  };

  render() {
    const { data } = this.props;
    const {
      newText,
      editIndex,
      yougoVisible,
      sort,
      sortTable2,
      Expansion,
    } = this.state;

    const columns = [
      {
        title: "naiyou",
        dataIndex: "naiyou",
        width: 320,
        key: "naiyou",
        render: (textEx, record, index) => this.renderColumns(record, index),
      },
      {
        title: "行動",
        dataIndex: "operation",
        width: 150,
        key: "operation",
        render: (textEx, record, index) => {
          return (
            <div className="editable-row-operations">
              {sort === true ? ( // <Icon type="caret-up" rotate={isActive ? 180 : 0} />style={{ fontSize: '16px', color: '#08c' }}
                <span>
                  <a
                    onClick={() => this.sortTop(sortTable2, record, index)}
                    style={{}}
                  >
                    <StepBackwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                  </a>
                  <Divider type="vertical" />
                  <a
                    onClick={() => this.sortUp(sortTable2, record, index)}
                    style={{}}
                  >
                    <CaretUpOutlined style={{ fontSize: "18px" }} />
                  </a>
                  <Divider type="vertical" />
                  <a
                    onClick={() => this.sortDown(sortTable2, record, index)}
                    style={{}}
                  >
                    <CaretDownOutlined style={{ fontSize: "18px" }} />
                  </a>
                  <Divider type="vertical" />
                  <a
                    onClick={() => this.sortBottom(sortTable2, record, index)}
                    style={{}}
                  >
                    <StepForwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                  </a>
                </span>
              ) : editIndex === index ? (
                <span>
                  <a onClick={() => this.save()}>保存</a>
                  <Divider type="vertical" />
                  <a onClick={() => this.cancel(index)}>閉じる</a>
                </span>
              ) : (
                <span>
                  <a onClick={() => this.select(record)}>選択</a>
                  <Divider type="vertical" />
                  <a onClick={() => this.edit(record, index)}>修正</a>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="削除してもいいですか？"
                    onConfirm={() => this.delete(record, index)}
                  >
                    <a>削除</a>
                  </Popconfirm>
                </span>
              )}
            </div>
          );
        },
      },
    ];

    // convert Array(String) to Array(Object)
    const dataTable1 = convertArrStrToArrObj(data);
    // console.log(data) // ['bbb','aaaa','aa']
    // console.log(dataTable1) // [{key:'0', naiyou:'あああ'},{key:'1', naiyou:'あああ'},{key:'2', naiyou:'あああ'}]
    const content = // falseは通常  trueはソートテーブル
      (
        <div>
          {sort == false ? (
            <div>
              <Table
                bordered
                columns={columns}
                dataSource={dataTable1}
                pagination={{ pageSize: 50 }} // 50
                scroll={{ y: 240 }}
                showHeader={false}
                size={"small"}
                rowKey={(record, index) => index}
              />
              <Button
                type="primary"
                icon={<LegacyIcon type={Expansion ? "minus" : "plus"} />}
                // className="modal-expansion"
                style={{ float: "left", marginTop: -40 }}
                onClick={this.handleExpandTab}
              >
                {Expansion ? "格納" : "展開"}
              </Button>
            </div>
          ) : null}

          {sort == true ? (
            <Table
              bordered
              columns={columns}
              dataSource={convertArrStrToArrObj(sortTable2)}
              pagination={{ pageSize: 50 }}
              scroll={{ y: 240 }}
              showHeader={false}
              size={"small"}
              rowKey={(record, index) => index}
            />
          ) : null}
        </div>
      );

    const title = (
      <div>
        {sort == false ? (
          <span>
            <TextInput
              onChange={this.handleOnchangeNew}
              value={newText}
              style={{ width: "250px", marginRight: "12px" }}
            />
            <a onClick={this.add}>追加</a>
            <a
              onClick={this.onChangePopover.bind(this, false)}
              style={{ float: "right", paddingTop: "14px", color: "black" }}
            >
              閉じる
            </a>
            <Button
              type={"primary"}
              onClick={this.sortOn}
              style={{ marginRight: 8, marginTop: 10, marginLeft: 15 }}
            >
              並び替え
            </Button>
          </span>
        ) : null}

        {sort == true ? (
          <span>
            <Button
              type={"primary"}
              onClick={this.sortSave}
              style={{ marginRight: 8, marginTop: 10, marginLeft: 15 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={this.sortOff}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
            <a
              onClick={this.onChangePopover.bind(this, false)}
              style={{ float: "right", paddingTop: "14px", color: "black" }}
            >
              閉じる
            </a>
          </span>
        ) : null}
      </div>
    );

    return (
      <div className="text-area-select-ri-page">
        <span className="text-area-select-btn">
          <Popover
            overlayStyle={{ width: "550px" }}
            visible={yougoVisible}
            placement={"bottomRight"}
            title={title}
            content={content}
            trigger="click"
            onVisibleChange={this.onChangePopover}
          >
            <Button type="primary">選択</Button>
          </Popover>
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kaigoBunrui: state.yougoMasterNaiyou.yougoMasterBunrui,
    kaigoTanto: state.yougoMasterNaiyou.yougoMasterTanto,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(YougoSelect);
