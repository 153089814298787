import React, { useState } from "react";
import { PlusSquareOutlined } from '@ant-design/icons';
import { Row, Button, Popover, Col } from "antd";

function AddTime(props) {
  const [visible, setVisible] = useState(false);

  function handleVisibleChange(visible) {
    setVisible(visible);
  }

  function handleHide() {
    setVisible(false);
  }
  const timeAdd = (
    <Col>
      {props.timeSetting && props.timeSetting.naiyou
        ? props.timeSetting.naiyou.split(";").map((item, index) => {
            const timeElement = item.split("_");
            return (
              <Row key={index} style={{ marginTop: 2 }}>
                <Button
                  type="primary"
                  onClick={handleInput}
                  value={timeElement[1]}
                  style={{ width: 70 }}
                >
                  {timeElement[0]}
                </Button>
              </Row>
            );
          })
        : ""}
      <Row style={{ marginTop: 2 }}>
        <Button type="primary" style={{ width: 70 }} onClick={handleHide}>
          閉じる
        </Button>
      </Row>
    </Col>
  );

  function handleInput(e) {
    props.onClickTime(e.currentTarget.value);
    handleHide();
  }
  const styleClass = `
  .pljp-popover-arrow{
  border-style: none !important;
  }

  // @media (min-width: 768px) and (max-width: 1366px) {
  //   .pljp-popover-arrow{
  //     border-style: none !important;
  //     }
  // }
`;

  return (
    <div>
      <style>{visible ? styleClass : ""}</style>
      <Popover
        id="time-kb-cus"
        placement="bottomLeft"
        content={timeAdd}
        trigger="click"
        type="temperature"
        open={visible}
        onOpenChange={handleVisibleChange}
      >
        <Button type="primary">
          <PlusSquareOutlined />
        </Button>
      </Popover>
    </div>
  );
}

export default AddTime;
