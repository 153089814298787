import {
  SETTING_MASTER_ALL,
  SETTING_MASTER_ALL_NEW,
  KIHON_ADL_MASTER_ALL,
} from "../../actions/SettingMasterActions/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  bunrui_is_dis: "",
  bunrui_title: "",
  systemBunrui_is_dis: "",
  systemBunrui_title: "",
  tekiyou_is_dis: "",
  tekiyou_title: "",
  fax_title: "",
};

export default function tantoMasterListReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SETTING_MASTER_ALL:
      return {
        ...state,
        setting: action.payload,
      };

    case SETTING_MASTER_ALL_NEW:
      return {
        ...state,
        bunrui_is_dis:
          action.payload && action.payload.bunrui_is_dis
            ? action.payload.bunrui_is_dis
            : state.bunrui_is_dis,
        bunrui_title:
          action.payload && action.payload.bunrui_title
            ? action.payload.bunrui_title
            : state.bunrui_title,
        systemBunrui_is_dis:
          action.payload && action.payload.systemBunrui_is_dis
            ? action.payload.systemBunrui_is_dis
            : "",
        systemBunrui_title:
          action.payload && action.payload.systemBunrui_title
            ? action.payload.systemBunrui_title
            : "",
        tekiyou_is_dis:
          action.payload && action.payload.tekiyou_is_dis
            ? action.payload.tekiyou_is_dis
            : state.tekiyou_is_dis,
        tekiyou_title:
          action.payload && action.payload.tekiyou_title
            ? action.payload.tekiyou_title
            : state.tekiyou_title,
        fax_title:
          action.payload && action.payload.fax_title_sm
            ? action.payload.fax_title_sm
            : state.fax_title,
        setFSOtherTitle:
          action.payload && action.payload.setFSOtherTitle
            ? action.payload.setFSOtherTitle
            : state.setFSOtherTitle,
        setFSSonotaTitle:
          action.payload && action.payload.setFSSonotaTitle
            ? action.payload.setFSSonotaTitle
            : state.setFSSonotaTitle,
        fsSonotaTitleDis:
          action.payload && action.payload.fsSonotaTitleDis
            ? action.payload.fsSonotaTitleDis
            : state.fsSonotaTitleDis,
        system_setFSSonotaTitle:
          action.payload && action.payload.system_setFSSonotaTitle
            ? action.payload.system_setFSSonotaTitle
            : "",
        system_fsSonotaTitleDis:
          action.payload && action.payload.system_fsSonotaTitleDis
            ? action.payload.system_fsSonotaTitleDis
            : "",
      };

    case KIHON_ADL_MASTER_ALL:
      return {
        ...state,
        kihonAdl: action.payload,
      };

    default:
      return state;
  }
}
