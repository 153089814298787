import React, { Component } from "react";
import { Select } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;

class SettingSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleSelectChange(value) {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    const { value } = this.state;
    const { data, style, containerId } = this.props;

    return (
      <div style={style}>
        <Select
          value={value}
          onChange={(value) => this.handleSelectChange(value)}
          dropdownMatchSelectWidth={false}
          showSearch
          style={{ width: 170 }}
          getPopupContainer={
            containerId
              ? () => document.getElementById(containerId)
              : () => document.body
          }
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option value={null}>&nbsp;</Option>
          {data.map((item, i) => (
            <Option key={i} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

SettingSelect.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
  containerId: PropTypes.string,
  onChange: PropTypes.func,
};

export default SettingSelect;
