/* eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
import Tools from "./tools";

const fabric = require("fabric").fabric;

class Rectangle extends FabricCanvasTool {
  constructor(canvas, isfill) {
    super(canvas);
    this._isfill = isfill;
  }

  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => o.selectable = o.evented = false);
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.call_back = props.update_select;
  }

  doMouseDown(o) {
    const canvas = this._canvas;
    this.isDown = true;
    const pointer = canvas.getPointer(o.e);
    [this.startX, this.startY] = [pointer.x, pointer.y];
    this.rect = new fabric.Rect({
      left: this.startX, top: this.startY,
      originX: "left", originY: "top",
      // width: pointer.x - this.startX,
      // height: pointer.y - this.startY,
      width: 100,
      height: 100,
      strokeWidth: this._width,
      stroke: this._color,
      fill: this._isfill ? "rgba(0,0,0,1)" : this._fill,
      transparentCorners: false,
      selectable: false,
      evented: false,
      angle: 0
    });
    canvas.add(this.rect);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    const canvas = this._canvas;
    const pointer = canvas.getPointer(o.e);
    if (this.startX > pointer.x) {
      this.rect.set({ left: Math.abs(pointer.x) });
    }
    if (this.startY > pointer.y) {
      this.rect.set({ top: Math.abs(pointer.y) });
    }
    this.rect.set({ width: Math.abs(this.startX - pointer.x) });
    this.rect.set({ height: Math.abs(this.startY - pointer.y) });
    this.rect.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.call_back();
  }
}

export default Rectangle;