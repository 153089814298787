/* eslint-disable brace-style */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { Input, Button, Table, Modal, Row, Select, Col, Divider } from "antd";
import { connect } from "react-redux";

//action
import {
  ServiceKikanMasterActions,
  InquiryRequest,
} from "../../redux/actions/index";
import ServiceKikanMasterModal from "../Common/ServiceKikanMasterModal";

function ServiceKikan(props) {
  const ADD = 1;
  const EDIT = 2;
  const DUPLICATE = 3;
  const [name, setName] = useState("");
  const [record, setRecord] = useState(null);
  const [rowActive, setRowActive] = useState("");
  const [sid, setSid] = useState(null);
  const [serviceKikanMasterVisible, setServiceKikanMasterVisible] =
    useState(false);
  const [confirmDuplicateServiceVisible, setConfirmDuplicateServiceVisible] =
    useState(false);
  const [serviceSyuruiListVisible, setServiceSyuruiListVisible] =
    useState(false);
  const [modeModify, setModeModify] = useState();
  const [bunrui, setBunrui] = useState("");
  const [valueinput, setValueinput] = useState("");
  const [pagination, setPagination] = useState();
  const [expansion, setExpansion] = useState(false);
  const [sort, setSort] = useState(false);
  const [sortTable, setSortTable] = useState([]);
  const [sortTableCopy, setSortTableCopy] = useState([]);

  useEffect(() => {
    props.getListBySyuruiCd((data) => {});
    props.getServiceSyuruiMaster();
  }, []);

  useEffect(() => {
    if (props.visible) {
      props.getListBySyuruiCd(props.serviceType, name, (data) => {});
      setPagination({ pageSize: 10, current: 1 });
    } else {
      setExpansion(false);
      sortNot();
    }
  }, [props.visible]);

  const handleCancel = () => {
    // setRecord(null);
    setName("");
    setBunrui("");
    setExpansion(false);
    sortNot();
    props.hideModal();
  };

  const search = () => {
    props.getListBySyuruiCd(bunrui, name, (data) => {});
  };

  const onClickCellEvent = (record) => {
    // setRecord(record);
    setRowActive(record.id);
  };

  const onOk = () => {
    props.onSaveServiceKikan(record.ryaku);
    setName("");
    setExpansion(false);
    sortNot();
  };

  const handleExpandTab = () => {
    if (expansion) {
      setExpansion(false);
    } else {
      setExpansion(true);
    }
  };

  function handleOkService() {
    // search();
    setServiceKikanMasterVisible(false);
  }

  function handleCancelService() {
    setServiceKikanMasterVisible(false);
  }

  function handleAddService() {
    setModeModify(ADD);
    setServiceKikanMasterVisible(true);
    setSid(null);
  }

  function handleEditService(sid) {
    setModeModify(EDIT);
    setServiceKikanMasterVisible(true);
    setSid(sid);
  }

  async function handleDuplicateService(sid) {
    setSid(sid);
    setModeModify(DUPLICATE);
    setServiceKikanMasterVisible(true);
  }

  // function handleOkDuplicateConfirm() {
  //   setConfirmDuplicateServiceVisible(false);
  //   setServiceSyuruiListVisible(true);
  // }

  // function handleCancelDuplicateConfirm() {
  //   setConfirmDuplicateServiceVisible(false);
  // }

  // async function handleOkSyuruiList(syurui) {
  //   const result = await props.duplicateServiceKikanMaster(sid, syurui.id);
  //   setSid(result.id);
  //   setServiceSyuruiListVisible(false);
  //   setServiceKikanMasterVisible(true);
  // }

  // function handleCancelSyuruiList () {
  //   setServiceSyuruiListVisible(false);
  // }

  async function softTableOn() {
    props.getListBySyuruiCd(bunrui, name, (data) => {
      let tmp = JSON.parse(JSON.stringify(data));
      let tmp2 = JSON.parse(JSON.stringify(data));
      setSortTable(tmp);
      setSortTableCopy(tmp2);
    });
    return true;
  }

  async function sortOn() {
    const tmp = await softTableOn();
    setRowActive(null);
    setSort(tmp);
  }
  const sortNot = () => {
    setSort(false);
    setSortTable([]);
    setSortTableCopy([]);
  };
  const sortOff = () => {
    props.getListBySyuruiCd(bunrui, name, (data) => {
      sortNot();
    });
    // setTimeout(()=>{sortNot()}, 300)
  };
  async function sortSave() {
    let tmp = [];
    for (let record of sortTable) {
      let defTable = sortTableCopy.find((t) => t.id == record.id);
      if (record.sort_num != defTable.sort_num) {
        record.sortNum = record.sort_num;
        delete record.sort_num;
        tmp.push(record);
      }
    }

    await props.updateServiceKikanList(tmp, (data) => {
      search();
    });

    setTimeout(() => {
      sortNot();
    }, 500);
  }

  const sortTop = (record) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const minNum = sortTableTmp[0].sort_num;

    if (elementIndex != 0) {
      sortTableTmp.splice(elementIndex, 1);
      element.sort_num = minNum - 1;
      sortTableTmp.splice(0, 0, element);

      setSortTable(sortTableTmp);
    }
  };
  const sortUp = (record) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTableTmp.findIndex((v) => v.id === element.id);

    if (elementIndex != 0) {
      const changeElement = sortTableTmp[elementIndex - 1];
      const changeElementSortNum = changeElement.sort_num;
      const changeElementIndex = elementIndex - 1;

      sortTableTmp.splice(elementIndex, 1);
      if (element.sort_num == changeElementSortNum) {
        element.sort_num--;
        sortTableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sort_num = changeElementSortNum;
        sortTableTmp.splice(changeElementIndex, 0, element);
        sortTableTmp[elementIndex].sort_num++;
      }

      setSortTable(sortTableTmp);
    }
  };
  const sortDown = (record) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTableTmp.findIndex((v) => v.id === element.id);

    if (elementIndex != sortTable.length - 1) {
      const changeElement = sortTable[elementIndex + 1];
      const changeElementSortNum = changeElement.sort_num;
      const changeElementIndex = elementIndex + 1;

      sortTableTmp.splice(elementIndex, 1);
      if (element.sort_num == changeElementSortNum) {
        element.sort_num++;
        sortTableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sort_num = changeElementSortNum;
        sortTableTmp.splice(changeElementIndex, 0, element);
        sortTableTmp[elementIndex].sort_num--;
      }

      setSortTable(sortTableTmp);
    }
  };
  const sortBottom = (record) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    let maxNum = 0;
    for (let i of sortTableTmp) {
      maxNum < i.sort_num ? (maxNum = i.sort_num) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      sortTableTmp.splice(elementIndex, 1);
      element.sort_num = maxNum + 1;
      sortTableTmp.splice(sortTable.length, 0, element);

      setSortTable(sortTableTmp);
    }
  };

  const onSearchBunrui = (value) => {
    setBunrui(value);
    const index =
      props.serviceSyuruiMaster &&
      props.serviceSyuruiMaster.findIndex(
        (item) => item.serviceSyuruiCd.indexOf(value) > -1
      );
    if (index !== -1 && value) {
      setValueinput(
        `(${value})${props.serviceSyuruiMaster[index].serviceSyurui}`
      );
    } else setValueinput(null);
    props.getListBySyuruiCd(value, name, (data) => {});
  };

  const titleService = (
    <div>
      <Row style={{ marginBottom: "4px" }}>
        <Col span={6}>サービス種類</Col>
        <Col span={18}>
          {/* <Input value={props.serviceType} readOnly={true} /> */}
          <Select
            style={{ width: "100%" }}
            onChange={(value) => {
              onSearchBunrui(value);
            }}
            disabled={sort == true ? true : false}
            value={valueinput || null}
            className={
              sort == false
                ? "search-service-kikan"
                : "search-service-kikan-none"
            }
          >
            <Select.Option key={"top"} value={""}>
              {"\u00a0"}
            </Select.Option>
            {props && props.serviceSyuruiMaster
              ? props.serviceSyuruiMaster.map((item, intex) => {
                  return (
                    <Select.Option key={intex} value={item.serviceSyuruiCd}>
                      ({item.serviceSyuruiCd}){item.serviceSyurui}
                    </Select.Option>
                  );
                })
              : null}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={6}>検索</Col>
        <Col span={18} style={{ display: "flex" }}>
          <Input
            onChange={(e) => setName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.getListByServiceType(props.serviceType, name);
              }
            }}
            value={name}
            disabled={sort == true ? true : false}
          />
          <Button
            value={name}
            onClick={() => search()}
            className={
              sort == false
                ? "search-service-kikan"
                : "search-service-kikan-none"
            }
            disabled={sort == true ? true : false}
            icon={<SearchOutlined />}
          >
            検索
          </Button>
        </Col>
      </Row>
    </div>
  );

  const columnsService = expansion
    ? [
        {
          title: "jigyousyoNo",
          dataIndex: "jigyousyoNo",
          width: 90,
          key: "jigyousyoNo",
          render: (textEx, record, index) => <div className={rowActive === record.id ? "pljp-table-row-hover" : ""}>{textEx}</div>,
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
        {
          title: "ryaku",
          dataIndex: "ryaku",
          width: 230,
          key: "ryaku",
          render: (textEx, record, index) => (
            <div
              style={{
                maxWidth: "220px",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
              className={rowActive === record.id ? "pljp-table-row-hover" : ""}
            >
              {textEx}
            </div>
          ),
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
        {
          title: "information",
          dataIndex: "info",
          width: 120,
          key: "info",
          render: (textEx, record, index) => (
            <div
              style={{
                maxWidth: "120px",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
              className={rowActive === record.id ? "pljp-table-row-hover" : ""}
            >
              {record.info}
            </div>
          ),
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
      ]
    : [
        {
          title: "jigyousyoNo",
          dataIndex: "jigyousyoNo",
          width: 90,
          key: "jigyousyoNo",
          render: (textEx, record, index) => <div className={rowActive === record.id ? "pljp-table-row-hover" : ""}>{textEx}</div>,
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
        {
          title: "ryaku",
          dataIndex: "ryaku",
          width: 230,
          key: "ryaku",
          render: (textEx, record, index) => (
            <div
              style={{
                maxWidth: "220px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              className={rowActive === record.id ? "pljp-table-row-hover" : ""}
            >
              {textEx}
            </div>
          ),
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
        {
          title: "information",
          dataIndex: "info",
          width: 120,
          key: "info",
          render: (textEx, record, index) => (
            <div
              style={{
                maxWidth: "120px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {record.info}
            </div>
          ),
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
      ];

  const columnsService2 = [
    {
      title: "jigyousyoNo",
      dataIndex: "jigyousyoNo",
      width: 90,
      key: "jigyousyoNo",
      render: (textEx, record, index) => <div>{textEx}</div>,
      onCell: (record) => {
        return {
          onClick: (event) => onClickCellEvent(record), // click row
        };
      },
    },
    {
      title: "ryaku",
      dataIndex: "ryaku",
      width: 180,
      key: "ryaku",
      render: (textEx, record, index) => (
        <div
          style={{
            maxWidth: "220px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {textEx}
        </div>
      ),
    },
    // { //テスト
    //     title: 'num',
    //     dataIndex: 'sort_num',
    //     width: "10%",
    //     key: "sort_num",
    // },
    {
      title: "行動",
      dataIndex: "operation",
      width: "30%",
      key: "operation",
      render: (record, index) => {
        return (
          <div>
            <span>
              <a onClick={() => sortTop(index)} style={{}}>
                <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortUp(index)} style={{}}>
                <CaretUpOutlined style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortDown(index)} style={{}}>
                <CaretDownOutlined style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortBottom(index)} style={{}}>
                <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
              </a>
            </span>
          </div>
        );
      },
    },
  ];

  function isDisableButton(rowActive) {
    return props.serviceKikan
      ? !props.serviceKikan.some((item) => item.id == rowActive)
      : true;
  }

  function handleChangePagination(pagination) {
    setPagination(pagination);
  }

  return (
    (<div className="text-area-select-ri-page">
      <Modal
        open={props.visible}
        title={titleService}
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            キャンセル
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => onOk()}
            disabled={isDisableButton(rowActive)}
          >
            OK
          </Button>,
        ]}
        closable={false}
      >
        <Button
          key="add"
          type={"primary"}
          onClick={handleAddService}
          style={{ marginRight: 8 }}
          disabled={sort == true ? true : false}
        >
          追加
        </Button>
        <Button
          key="edit"
          type={"primary"}
          onClick={() => handleEditService(rowActive)}
          disabled={sort == true ? true : isDisableButton(rowActive)}
          style={{ marginRight: 8 }}
        >
          修正
        </Button>
        <Button
          key="duplicate"
          type={"primary"}
          onClick={() => handleDuplicateService(rowActive)}
          disabled={sort == true ? true : isDisableButton(rowActive)}
        >
          複製
        </Button>
        {sort == false ? (
          <a
            key="sort"
            // type={"primary"}
            onClick={() => sortOn()}
            style={{
              marginRight: 8,
              marginTop: 7,
              marginLeft: 10,
              color: "black",
            }}
          >
            並び替え
          </a>
        ) : (
          <span>
            <Button
              key="sortSave"
              type={"primary"}
              // onClick={sortSave()}
              onClick={() => sortSave()}
              style={{ marginRight: 8, marginLeft: 8 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={() => sortOff()}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
          </span>
        )}
        {sort == false ? (
          <Table
            dataSource={props && props.serviceKikan}
            columns={columnsService}
            showHeader={false}
            className="riyou-meal-page break-word-office service-kikan-inquiry"
            // onChange={handleChangePagination}
            // pagination={pagination}
            pagination={{ pageSize: 20 }}
            scroll={{ y: 300 }}
            size="small"
            rowClassName={(record) => {
              if (rowActive != null && record.id === rowActive) {
                setRecord(record);
                return "pljp-table-row-hover";
              }
              return "";
            }}
            rowKey={(record, index) => index}
            style={{ marginTop: 20 }}
          />
        ) : (
          <Table
            dataSource={sortTable}
            columns={columnsService2}
            showHeader={false}
            className="riyou-meal-page break-word-office"
            pagination={{ pageSize: 20 }}
            scroll={{ y: 300 }}
            size="small"
            rowKey={(record, index) => index}
            style={{ marginTop: 20 }}
          />
        )}

        <Button
          type="primary"
          icon={<LegacyIcon type={expansion ? "minus" : "plus"} />}
          className="modal-expansion"
          onClick={handleExpandTab}
          disabled={sort == true ? true : false}
        >
          {expansion ? "格納" : "展開"}
        </Button>
      </Modal>
      <ServiceKikanMasterModal
        visible={serviceKikanMasterVisible}
        onOk={handleOkService}
        onCancel={handleCancelService}
        id={sid}
        syuruiCd={props.syuruiCd}
        modeModify={modeModify}
      />
      {/* <ServiceSyuruiListModal
        visible={serviceSyuruiListVisible}
        onOk={handleOkSyuruiList}
        onCancel={handleCancelSyuruiList}
      />
      <Modal
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        visible={confirmDuplicateServiceVisible}
        footer={[
          <Button key="back" onClick={handleCancelDuplicateConfirm}>
            キャンセル
          </Button>,
          <Button key="submit" type="primary" onClick={handleOkDuplicateConfirm}>
            OK
          </Button>,
        ]}
      >
        <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}>
          <Col><Icon type="question-circle" style={{fontSize: "2rem", color: "orange"}}/></Col>
          <Col>サービス種類を選択してください</Col>
        </Row>
      </Modal> */}
    </div>)
  );
}

const mapStateToProps = (state) => {
  return {
    serviceKikan: state.serviceKikanMaster.serviceKikanList,
    isReload: state.serviceKikanMaster.isReload,
    serviceSyuruiMaster: state.inquiryRequest.serviceSyuruiMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListByServiceType: (serviceType, name, callback) =>
      dispatch(
        ServiceKikanMasterActions.getListByServiceTypePlan2(
          serviceType,
          name,
          callback
        )
      ),
    duplicateServiceKikanMaster: (sid, syuruiId) =>
      dispatch(
        ServiceKikanMasterActions.duplicateServiceKikanMaster(sid, syuruiId)
      ),
    turnOffReload: () => dispatch(ServiceKikanMasterActions.turnOffReload()),
    getServiceSyuruiMaster: () =>
      dispatch(InquiryRequest.getServiceSyuruiMaster()),
    getListBySyuruiCd: (syuruiCd, name, callback) =>
      dispatch(
        ServiceKikanMasterActions.getListBySyuruiCd(syuruiCd, name, callback)
      ),
    updateServiceKikanList: (body, callback) =>
      dispatch(
        ServiceKikanMasterActions.updateServiceKikanList(body, callback)
      ),
  };
};

const _ServiceKikan = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceKikan);

export { _ServiceKikan as ServiceKikanInquiry };
