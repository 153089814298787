/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */
/* eslint-disable no-case-declarations */
/* eslint-disable no-var */
/* eslint-disable no-else-return */
/* eslint-disable brace-style */
import { notification } from "antd/lib/index";
import Holidays from "date-holidays";
import dayjs from "dayjs";
import JapaneseHolidays from "japanese-holidays";
import _ from "lodash";
import { ConfigName } from "../../../config-name";
import { StorageUtils } from "../../../life/utils";
import jsonData from "../../data/jsonLocal";
import { ConstSet } from "../configs/constset";
import { ListNameSet } from "../configs/listnameset";
import docIcon from "../images/document/doc.png";
import fileIcon from "../images/document/file.png";
import imgIcon from "../images/document/image.png";
import movieIcon from "../images/document/movie.png";
import pdfIcon from "../images/document/pdf.png";
import pptIcon from "../images/document/ppt.png";
import voiceIcon from "../images/document/voice.png";
import xlsIcon from "../images/document/xls.png";
import { convert_wareki_4 } from "../../../common/function_common/functionCommon";

// ユーザ画像取り込み時のリサイズ
var userImageResizeWidth = 150;
var userImageResizeHeight = 150;

export function extractSienNaiyou(naiyou, sienaiyouForDis) {
  let result = "";

  if (naiyou && sienaiyouForDis && sienaiyouForDis.length > 0) {
    for (const i in sienaiyouForDis) {
      if (sienaiyouForDis[i].sienCd === naiyou) {
        result = sienaiyouForDis[i].saiKomoku;
        break;
      }
    }
  }
  return result;
}

export function getTotalPoint(data, rehaAssessIADL) {
  let result = 0;
  if (
    data &&
    data.rehaAssessSub &&
    data.rehaAssessSub.length > 0 &&
    rehaAssessIADL &&
    rehaAssessIADL.length > 0
  ) {
    const rehaAssessSub = data.rehaAssessSub;
    for (const i in rehaAssessSub) {
      const found = rehaAssessIADL.some(
        (el) => el.id === rehaAssessSub[i].rehaAssessAdlItemId
      );

      if (found) {
        result = result + rehaAssessSub[i].point;
      }
    }
  }

  return result;
}

export function getTotalPointByForm(dataForm, rehaAssessIADL) {
  let result = 0;
  //
  if (rehaAssessIADL && rehaAssessIADL.length > 0) {
    for (const i in rehaAssessIADL) {
      if (rehaAssessIADL[i].answerNaiyou) {
        rehaAssessIADL[i].answerNaiyou.split(",").map((it, id) => {
          if (dataForm[`rehaAssessSub_answer_${rehaAssessIADL[i].id}`] === it) {
            if (rehaAssessIADL[i].point) {
              const exPoint = rehaAssessIADL[i].point.split(",");
              if (exPoint[id]) {
                result = result + parseInt(exPoint[id]);
              }
            }
          }
        });
      }
    }
  }

  return result;
}

export function extractRehaAssessPoint(
  rehaSubItemId,
  rehaSubItemAnswer,
  rehaAssessItemData
) {
  let result = null;

  if (rehaSubItemId && rehaSubItemAnswer && rehaAssessItemData) {
    // rehaAssessMMSE
    if (rehaAssessItemData.rehaAssessMMSE) {
      const rehaAssessMMSE = rehaAssessItemData.rehaAssessMMSE;
      if (rehaAssessMMSE && rehaAssessMMSE.length > 0) {
        rehaAssessMMSE.map((item) => {
          if (item.id === rehaSubItemId) {
            if (item.answerNaiyou) {
              item.answerNaiyou.split(",").map((it, i) => {
                if (it === rehaSubItemAnswer) {
                  if (item.point) {
                    const tmpPoint = item.point.split(",");
                    if (tmpPoint[i]) {
                      result = parseInt(tmpPoint[i]);
                    }
                  }
                }
              });
            }
          }
        });
      }
    }

    // rehaAssessAdlItemAnswerNaiyou
    if (rehaAssessItemData.rehaAssessAdlItemAnswerNaiyou) {
      const rehaAssessAdlItemAnswerNaiyou =
        rehaAssessItemData.rehaAssessAdlItemAnswerNaiyou;
      if (
        rehaAssessAdlItemAnswerNaiyou &&
        rehaAssessAdlItemAnswerNaiyou.length > 0
      ) {
        rehaAssessAdlItemAnswerNaiyou.map((item) => {
          if (item.id === rehaSubItemId) {
            if (item.answerNaiyou) {
              item.answerNaiyou.split(",").map((it, i) => {
                if (it === rehaSubItemAnswer) {
                  if (item.point) {
                    const tmpPoint = item.point.split(",");
                    if (tmpPoint[i]) {
                      result = parseInt(tmpPoint[i]);
                    }
                  }
                }
              });
            }
          }
        });
      }
    }

    // rehaAssessAdlItemAdl
    if (rehaAssessItemData.rehaAssessAdlItemAdl) {
      const rehaAssessAdlItemAdl = rehaAssessItemData.rehaAssessAdlItemAdl;
      if (rehaAssessAdlItemAdl && rehaAssessAdlItemAdl.length > 0) {
        rehaAssessAdlItemAdl.map((item) => {
          if (item.id === rehaSubItemId) {
            if (item.answerNaiyou) {
              item.answerNaiyou.split(",").map((it, i) => {
                if (it === rehaSubItemAnswer) {
                  if (item.point) {
                    const tmpPoint = item.point.split(",");
                    if (tmpPoint[i]) {
                      result = parseInt(tmpPoint[i]);
                    }
                  }
                }
              });
            }
          }
        });
      }
    }

    // rehaAssessAdlItemKanKyou
    if (rehaAssessItemData.rehaAssessAdlItemKanKyou) {
      const rehaAssessAdlItemKanKyou =
        rehaAssessItemData.rehaAssessAdlItemKanKyou;
      if (rehaAssessAdlItemKanKyou && rehaAssessAdlItemKanKyou.length > 0) {
        rehaAssessAdlItemKanKyou.map((item) => {
          if (item.id === rehaSubItemId) {
            if (item.answerNaiyou) {
              item.answerNaiyou.split(",").map((it, i) => {
                if (it === rehaSubItemAnswer) {
                  if (item.point) {
                    const tmpPoint = item.point.split(",");
                    if (tmpPoint[i]) {
                      result = parseInt(tmpPoint[i]);
                    }
                  }
                }
              });
            }
          }
        });
      }
    }

    // rehaAssessIADL
    if (rehaAssessItemData.rehaAssessIADL) {
      const rehaAssessIADL = rehaAssessItemData.rehaAssessIADL;
      if (rehaAssessIADL && rehaAssessIADL.length > 0) {
        rehaAssessIADL.map((item) => {
          if (item.id === rehaSubItemId) {
            if (item.answerNaiyou) {
              item.answerNaiyou.split(",").map((it, i) => {
                if (it === rehaSubItemAnswer) {
                  if (item.point) {
                    const tmpPoint = item.point.split(",");
                    if (tmpPoint[i]) {
                      result = parseInt(tmpPoint[i]);
                    }
                  }
                }
              });
            }
          }
        });
      }
    }
  }

  return result;
}

export function extractNeedItemNaiyou(naiyou, neeedItem) {
  let result = "";

  if (naiyou && neeedItem && neeedItem.length > 0) {
    for (const i in neeedItem) {
      if (neeedItem[i].cd === naiyou) {
        result = neeedItem[i].naiyou;
      }
    }
  }

  return result;
}

export function extractKoui(naiyou, ifcMater) {
  let result = "";

  if (naiyou && ifcMater && ifcMater.length > 0) {
    for (const i in ifcMater) {
      if (ifcMater[i].icfCd === naiyou) {
        result = ifcMater[i].koui;
      }
    }
  }

  return result;
}

export function extractSaishin(rehaSubItemId, saishin) {
  let result = "";

  if (rehaSubItemId && saishin && saishin.length > 0) {
    for (const i in saishin) {
      if (saishin[i].rehaAssessAdlItemId === rehaSubItemId) {
        result = saishin[i].answer;
      }
    }
  }

  return result;
}

export function extractItemId(rehaAssessSubItem, naiyou) {
  const result = {};

  if (rehaAssessSubItem && rehaAssessSubItem.length > 0) {
    rehaAssessSubItem.map((item) => {
      if (item.naiyou === naiyou) {
        (result.id = item.id), (result.answerNaiyou = item.answerNaiyou), (result.naiyou = item.naiyou);
      }
    });
  }

  return result;
}

export function exSyoubyoumeiText(
  mediSyoubyoumeiMaster,
  riyouByoureki,
  syoubyoumei
) {
  let result = "";

  if (riyouByoureki && riyouByoureki.length > 0) {
    for (const i in riyouByoureki) {
      if (riyouByoureki[i].syoubyoumeiCd === syoubyoumei) {
        result = riyouByoureki[i].syoubyoumei;
        break;
      }
    }
  }

  if (mediSyoubyoumeiMaster && mediSyoubyoumeiMaster.length > 0) {
    for (const i in mediSyoubyoumeiMaster) {
      if (mediSyoubyoumeiMaster[i].syoubyoumeiCd === syoubyoumei) {
        result = mediSyoubyoumeiMaster[i].syoubyoumei;
        break;
      }
    }
  }

  return result;
}

export function convert_prosecss_date(dateString) {
  if (dateString) {
    // eslint-disable-next-line no-undef
    const newDate = convert_wareki_4(dateString);
    if (newDate !== "") {
      return newDate.replace(/-/g, ".");
    }
    return "";
  }

  return "";
}

export function parseJson(args) {
  try {
    const data = JSON.parse(args);
    return data;
  } catch (_) {
    return null;
  }
}

/**
 * Convert param to query for kanri search function
 *
 * @param {*} objParam
 */
function kanriQuerySearch(objParam) {
  var result = "";

  if (objParam.value1 && objParam.value2 && objParam.value3 && objParam.type) {
    // objParam.value2.match(/^[0-9]+$/) != null => validate string
    if (objParam.type === 1 || objParam.type === 3 || objParam.type === 4) {
      // query col is char or text
      switch (objParam.value3) {
        case 1: // =
          result = `${objParam.value1} = '${objParam.value2}'`;
          break;

        case 5: // !=
          result = `${objParam.value1} != '${objParam.value2}'`;
          break;

        case 6: // >
          result = `${objParam.value1} > '${objParam.value2}'`;
          break;

        case 7: // <
          result = `${objParam.value1} < '${objParam.value2}'`;
          break;

        case 8: // >=
          result = `${objParam.value1} >= '${objParam.value2}'`;
          break;

        case 9: // <=
          result = `${objParam.value1} <= '${objParam.value2}'`;
          break;

        default:
          break;
      }
    } else if (objParam.type === 2) {
      switch (objParam.value3) {
        case 1: // =
          result = `${objParam.value1} LIKE '${objParam.value2}'`;
          break;

        case 2: // %LIKE%
          result = `${objParam.value1} LIKE '%${objParam.value2}%'`;
          break;

        case 3: // LIKE%
          result = `${objParam.value1} LIKE '${objParam.value2}%'`;
          break;

        case 4: // %LIKE
          result = `${objParam.value1} LIKE '%${objParam.value2}'`;
          break;

        case 5: // !=
          result = `${objParam.value1} != '${objParam.value2}'`;
          break;

        default:
          break;
      }
    }
  }

  return result;
}

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function getDateInMonth(year, month, date) {
  const newDate = new Date(year, month - 1, date);
  let dayName = "";
  switch (newDate.getDay()) {
    case 0:
      dayName = "日";
      break;
    case 1:
      dayName = "月";
      break;
    case 2:
      dayName = "火";
      break;
    case 3:
      dayName = "水";
      break;
    case 4:
      dayName = "木";
      break;
    case 5:
      dayName = "金";
      break;
    case 6:
      dayName = "土";
      break;
    default:
      dayName = "日";
      break;
  }
  return dayName;
}

function getHolidayInMonth(monthDisplay, yearDisplay) {
  const hd = new Holidays("JP");
  const holidaysList = hd.getHolidays(yearDisplay);
  const holidaysInMonth = [];
  for (let j = 0; j < holidaysList.length; j++) {
    if (
      new Date(holidaysList[j].date).getMonth() + 1 ===
      parseInt(monthDisplay, 0)
    ) {
      holidaysInMonth.push(holidaysList[j]);
    }
  }
  const holidayDate = [];
  for (let h = 0; h < holidaysInMonth.length; h++) {
    if (
      new Date(holidaysInMonth[h].end).getDate() >
      new Date(holidaysInMonth[h].start).getDate() + 1
    ) {
      for (
        let k = new Date(holidaysInMonth[h].start).getDate();
        k <= new Date(holidaysInMonth[h].end).getDate();
        k++
      ) {
        holidayDate.push(k);
      }
    } else {
      holidayDate.push(new Date(holidaysInMonth[h].date).getDate());
    }
  }
  return holidayDate;
}

function checkHoliday(date) {
  if (!date) {
    return "";
  }

  const newDate = new Date(`${date}`);
  if (newDate.getDay() === 6) {
    return " c-blue";
  }

  // let hd = new Holidays("JP");
  if (newDate.getDay() === 0 || JapaneseHolidays.isHoliday(newDate)) {
    return " c-red";
  }

  return "";
}

function getDistance(startDate, endDate, startDateSelected, endDateSelected) {
  let distance = "";
  if (startDateSelected && endDateSelected) {
    const startDateNumber = new Date(startDate).getDate();
    const endDateNumber = new Date(endDate).getDate();
    const startDateSelectedNumber = new Date(startDateSelected).getDate();
    const endDateSelectedNumber = new Date(endDateSelected).getDate();
    if (
      endDateSelectedNumber > endDateNumber &&
      startDateSelectedNumber <= startDateNumber
    ) {
      distance = endDateNumber - startDateNumber;
    } else if (
      endDateSelectedNumber > endDateNumber &&
      startDateSelectedNumber > startDateNumber
    ) {
      distance = endDateNumber - startDateSelectedNumber;
    } else if (
      endDateSelectedNumber <= endDateNumber &&
      startDateSelectedNumber <= startDateNumber
    ) {
      distance = endDateSelectedNumber - startDateNumber;
    } else {
      distance = endDateSelectedNumber - startDateSelectedNumber;
    }
  } else if (new Date(endDate).getFullYear() === new Date(startDate).getFullYear()) {
    if (new Date(endDate).getMonth() > new Date(startDate).getMonth()) {
      distance = new Date(endDate).getDate();
    } else {
      distance = new Date(endDate).getDate() - new Date(startDate).getDate();
    }
  } else {
    distance = daysInMonth(
      new Date(endDate).getMonth() + 1,
      new Date(endDate).getFullYear()
    );
  }
  return distance;
}

function convertMessageByCode(messageCode) {
  if (messageCode === "LOGIN_FAIL") {
    return "あなたは登録されていないか、パスワードが違っています。";
  } else if (messageCode === "PARAMS_MISSING") {
    return "必要なパラメータが足りない、又は間違っています。";
  } else if (messageCode === "INVALID_TOKEN") {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("doubleLogin");
    localStorage.removeItem("getAvatarResponse");
    localStorage.removeItem("getAvatarResponseData");
    window.location.href = "/login";
  } else if (messageCode === "DATE_INVALID_FORMAT") {
    return "日付の形式が正しくないです。";
  } else if (messageCode === "START_DATE_MUST_BE_SMALLER_END_DATE") {
    return "開始時間が終了時間より前でなければなりません。";
  } else if (messageCode === "NYUSHO_DATE_MUST_BE_SMALLER_TAISHO_DATE") {
    return "入所時間が退所時間より前でなければなりません。";
  } else if (messageCode === "END_DATE_MUST_SMALLER_TAISHO_DATE") {
    return "日付範囲に誤りがあります。";
  } else if (messageCode === "GAIHAKU_NYUIN_JOKYO_EXISTED") {
    return "既に予定がセットされている為変更できませんでした。";
  } else if (messageCode === "NYUTAISHO_JOKYO_EXISTED") {
    return "この利用者のその期間は、既に予定がセットされています。";
  } else if (messageCode === "CREATE_ERROR") {
    return "作成できません。";
  } else if (messageCode === "RECORD_NOT_FOUND") {
    return "レコードが見つけませんでした。";
  } else if (messageCode === "ROOM_BED_HAS_BEEN_USED") {
    return "この部屋には空きがありません。";
  } else if (messageCode === "DELETE_ERROR") {
    return "削除できません。";
  } else if (messageCode === "RIYOUSYA_NOT_FOUND") {
    return "利用者が存在しません。";
  } else if (messageCode === "THIS_FIELD_IS_REQUIRED") {
    return "このフィールドが入力必須です。";
  } else if (messageCode === "LOGOUT") {
    return "ログアウト";
  } else if (messageCode === "ARE_YOU_SURE") {
    return "ログアウトしてもよろしいですか。";
  } else if (messageCode === "UPDATE_ERROR") {
    return "修正できません。";
  } else if (messageCode === "ROOM_INVALID") {
    return "該当の部屋が見つかりませんでした。";
  } else if (messageCode === "BED_INVALID") {
    return "該当のベッドが見つかりませんでした。";
  } else if (messageCode === "NYUTAISHO_NOT_FOUND") {
    return "該当の入所状況が見つかりませんでした。";
  } else if (messageCode === "GAIHAKU_NOT_FOUND") {
    return "該当の外泊又は入院の予定が見つかりませんでした。";
  } else if (messageCode === "SEARCHING_MISSING_PARAMS") {
    return "検索文字列を入力してください。";
  } else if (messageCode === "END_DATE_INVALID") {
    return "日付範囲に誤りがあります。";
  } else if (messageCode === "PLAN_EXISTED") {
    return "既に予定がセットされている為変更できませんでした。";
  } else if (messageCode === "NYUSHO_DATE_MUST_BE_SMALLER_START_DATE") {
    return "入所日が開始日より前でなければなりません。";
  }
}

/**
 *
 * @param {*} shortFlag
 * @param {*} tempBookFlag
 * @param {*} nyushoKubunFlag
 * @param {*} gaihakuNyuinFlag
 */
function genderStatus(
  shortFlag,
  tempBookFlag,
  nyushoKubunFlag,
  gaihakuNyuinFlag
) {
  if (shortFlag) {
    return "短期利用";
  } else if (tempBookFlag) {
    return "仮予約のみ";
  } else if (gaihakuNyuinFlag) {
    if (gaihakuNyuinFlag === 1) {
      return "外泊";
    } else if (gaihakuNyuinFlag === 2) {
      return "入院";
    } else {
      return "入所";
    }
  } else {
    return "入所";
  }
}

/**
 *
 * @param {*} type
 * @param {*} record
 * @param {*} vitalHighLow
 * @param {*} vitalEct
 */
function checkTableField(type, record, vitalHighLow, vitalEct) {
  const range = extractRangeVital(vitalHighLow);
  // let ectTypeCheck = {};
  switch (type) {
    case ConstSet.MODAL_B_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_MAINF:
      return {
        type: "メイン",
        value: record && record.mainf ? record.mainf : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_WEIGHT:
      return {
        type: "体重",
        value: record && record.weight ? record.weight : null,
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_TEMP:
      return {
        type: "体温",
        value: record && record.temperature ? record.temperature : null,
        zenkai: true,
        suggestValues: range.temperature,
      };

    case ConstSet.MODAL_B_BPRESSURE_HIGHT:
      return {
        type: "血圧高",
        value: record && record.bpressureHigh ? record.bpressureHigh : null,
        zenkai: true,
        suggestValues: range.bpressureHigh,
      };

    case ConstSet.MODAL_B_BPRESSURE_LOW:
      return {
        type: "血圧低",
        value: record && record.bpressureLow ? record.bpressureLow : null,
        zenkai: true,
        suggestValues: range.bpressureLow,
      };

    case ConstSet.MODAL_B_PULSEF_NO:
      return {
        type: "脈拍",
        value: record && record.pulse ? record.pulse : null,
        zenkai: true,
        suggestValues: range.pulse,
      };

    case ConstSet.MODAL_B_PULSEF_TYPE:
      return {
        type: "脈拍",
        value: record && record.pulsef ? record.pulsef : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_SPO2_BEFORE:
      return {
        type: "SPO2前",
        value:
          record && record.spo2 && record.spo2.toString().split("-")[0]
            ? record.spo2.toString().split("-")[0]
            : null,
        zenkai: true,
        suggestValues: range.spo2B,
      };

    case ConstSet.MODAL_B_SPO2_AFTER:
      return {
        type: "SPO2後",
        value:
          record && record.spo2 && record.spo2.toString().split("-")[1]
            ? record.spo2.toString().split("-")[1]
            : null,
        zenkai: true,
        suggestValues: range.spo2A,
      };

    case ConstSet.MODAL_B_KOKYU_NO:
      return {
        type: "呼吸",
        value: record && record.kokyu ? record.kokyu : null,
        zenkai: true,
        suggestValues: range.respiration,
      };

    case ConstSet.MODAL_B_KOKYU_TYPE:
      return {
        type: "呼吸",
        value: record && record.kokyuf ? record.kokyuf : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC0:
      // ectTypeCheck = ectCheckInput(etcType);
      return {
        type: vitalEct.split(",")[0].split("=")[1],
        value: record && record.etc1 ? record.etc1 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC1:
      // ectTypeCheck = ectCheckInput(etcType);
      return {
        type: vitalEct.split(",")[1].split("=")[1],
        value: record && record.etc2 ? record.etc2 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC2:
      // ectTypeCheck = ectCheckInput(etcType);
      return {
        type: vitalEct.split(",")[2].split("=")[1],
        value: record && record.etc3 ? record.etc3 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC3:
      return {
        type: "その他4",
        value: record && record.etc4 ? record.etc4 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC4:
      return {
        type: "その他5",
        value: record && record.etc5 ? record.etc5 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC5:
      return {
        type: "その他6",
        value: record && record.etc6 ? record.etc6 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC6:
      return {
        type: "その他7",
        value: record && record.etc7 ? record.etc7 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC7:
      return {
        type: "その他8",
        value: record && record.etc8 ? record.etc8 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC8:
      return {
        type: "その他9",
        value: record && record.etc9 ? record.etc9 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC9:
      return {
        type: "その他10",
        value: record && record.etc10 ? record.etc10 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    // Meal
    case ConstSet.MODAL_M_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_SHUBETU:
      return {
        type: "種類",
        value: record && record.kubunMeal ? record.kubunMeal : null,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_STAPLEINTAKE:
      return {
        type: "主食摂取量",
        value: record && record.stapleIntake ? record.stapleIntake : null,
        zenkai:
          !!((record.kubunMeal && record.kubunMeal === "経管栄養") ||
            getValueIskeep("mealType") === "経管栄養"),
        suggestValues: null,
      };

    case ConstSet.MODAL_M_SIDEINTAKE:
      return {
        type: "副食摂取量",
        value: record && record.sideIntake ? record.sideIntake : null,
        zenkai:
          !!((record.kubunMeal && record.kubunMeal === "経管栄養") ||
            getValueIskeep("mealType") === "経管栄養"),
        suggestValues: null,
      };

    case ConstSet.MODAL_M_SHOKUNASI:
      return {
        type: "食無",
        value: record && record.shokuNasi ? record.shokuNasi : null,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : null,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoName ? record.tantoName : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_S_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_S_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_S_SYURUI:
      return {
        type: "種類",
        value: record && record.syurui ? record.syurui : null,
        zenkai: false,
      };

    case ConstSet.MODAL_S_INTAKE:
      return {
        type: "量",
        value: record && record.intake ? record.intake : null,
        zenkai: true,
      };

    case ConstSet.MODAL_S_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_S_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoName ? record.tantoName : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_H_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_H_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_H_HAISETUKUBUN:
      /**
       * Logic for 排泄分類と提供記録
       * if modalType = ...., the default value(from record -> plan -> select on UI) is taken
       *
       * auto save function have been check if OK save button is true (state) - have been modify by user
       */
      let haisetuKubun =
        record && record.haisetuKubun ? record.haisetuKubun : null;
      if (!haisetuKubun && record && record.settingName == "HB提供内容設定") {
        haisetuKubun = "排便";
      } else if (
        !haisetuKubun &&
        record &&
        record.settingName == "HN提供内容設定"
      ) {
        haisetuKubun = "排尿";
      }
      const tmpHaisetuKubun = getValueLocalstorage("haisetuType");
      if (tmpHaisetuKubun && tmpHaisetuKubun !== "両方") {
        haisetuKubun = tmpHaisetuKubun;
      } // end

      return { type: "排泄区分", value: haisetuKubun, zenkai: false };

    case ConstSet.MODAL_H_SHURUI:
      return {
        type: "種類",
        value: record && record.shurui ? record.shurui : null,
        zenkai: false,
      };

    case ConstSet.MODAL_H_HAISETUF:
      return {
        type: "有無",
        value:
          record && record.haisetuf
            ? record.haisetuf
            : record.haisetuf === 0
              ? 0
              : "",
        zenkai: false,
      };

    case ConstSet.MODAL_H_VOL:
      return {
        type: "量",
        value: record && record.vol ? record.vol : null,
        zenkai: true,
      };

    case ConstSet.MODAL_H_JOUTAI:
      return {
        type: "状態",
        value: record && record.joutai ? record.joutai : null,
        zenkai: false,
      };

    case ConstSet.MODAL_H_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_H_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoName ? record.tantoName : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_N_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_N_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_N_NYUYOKUNAIYOU:
      return {
        type: "種類",
        value: record && record.nyuyokuNaiyou ? record.nyuyokuNaiyou : null,
        zenkai: false,
      };

    case ConstSet.MODAL_N_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_N_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoName ? record.tantoName : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_F_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_F_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_F_YAKUMEI:
      return {
        type: "薬名",
        value: record && record.yakumei ? record.yakumei : null,
        zenkai: false,
      };

    case ConstSet.MODAL_F_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_F_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoName ? record.tantoName : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_K_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };
    case ConstSet.MODAL_K_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_K_KAIGONAIYOUCODE:
      /**
       * Logic for 排泄分類と提供記録
       * if modalType = ...., the default value(from record -> plan -> select on UI) is taken
       *
       * auto save function have been check if OK save button is true (state) - have been modify by user
       */
      let kaigoNaiyouCode =
        record && record.kaigo_naiyou_code
          ? record.kaigo_naiyou_code
          : record.kaigoNaiyouCode
            ? record.kaigoNaiyouCode
            : null;
      const tmpKaigoNaiyouCode = getValueIskeep("kiroku_kaigo_naiyou");
      if (tmpKaigoNaiyouCode && tmpKaigoNaiyouCode !== "全表示") {
        kaigoNaiyouCode = tmpKaigoNaiyouCode;
      } // end

      return { type: "提供内容", value: kaigoNaiyouCode, zenkai: false };

    case ConstSet.MODAL_K_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_K_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoName ? record.tantoName : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_T_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_T_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_T_COMMENT:
      return {
        type: "コメント",
        value: record && record.tokki ? record.tokki : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_T_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoName ? record.tantoName : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_G_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_PRIORITYF:
      return {
        type: "優先",
        value: record && record.priorityf ? record.priorityf : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_KUBUN:
      return {
        type: "区分",
        value: record && record.kubun ? record.kubun : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoName ? record.tantoName : null,
        zenkai: false,
        suggestValues: null,
      };

    default:
      return { type: null, value: null };
  }
}

/**
 *
 * @param {string} inputChar
 * @param {float} value
 */
function handleInputNumber(inputChar, value) {
  if (inputChar === "消去") {
    value = "";
  } else if (inputChar === ".") {
    if (!value) {
      value = "0.";
    } else if (!value.includes(".")) {
      value = value + ".";
    }
  } else if (!value) {
    // if (inputChar !== '0')
    value = inputChar;
  } else {
    value = value + "" + inputChar;
  }

  // let pointNum = parseFloat(value);

  // @TODO: not check much min input. check requirement for this and fix
  // if (!checkMaxMin) {
  //   if (pointNum > 200 || pointNum < 0) {
  //     return 0;
  //   }
  // }

  return value;
}

/**
 *
 * @param {*} url
 */
function getUrlVars(url) {
  var vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

/**
 *
 * @param {array, obj or int, string} Obj
 */
function clone(Obj) {
  var buf;
  if (Obj instanceof Array) {
    buf = []; // create an empty array
    var i = Obj.length;
    while (i--) {
      buf[i] = clone(Obj[i]); // recursively clone the elements
    }
    return buf;
  } else if (Obj instanceof Object) {
    buf = {}; // create an empty object
    for (var k in Obj) {
      buf[k] = clone(Obj[k]); // recursively clone the value
    }
    return buf;
  } else {
    return Obj;
  }
}

function extractRangeVital(vitalHighLow) {
  let temperature = null;
  let bpressureHigh = null;
  let bpressureLow = null;
  let pulse = null;
  let respiration = null;

  if (vitalHighLow) {
    const preVitalHighLow = vitalHighLow.split(";");
    for (let tmpVhl = 0; tmpVhl < preVitalHighLow.length; tmpVhl++) {
      if (preVitalHighLow[tmpVhl].split("_")[0] === "体温") {
        temperature = preVitalHighLow[tmpVhl].split("_")[1];
      }

      if (preVitalHighLow[tmpVhl].split("_")[0] === "血圧高") {
        bpressureHigh = preVitalHighLow[tmpVhl].split("_")[1];
      }

      if (preVitalHighLow[tmpVhl].split("_")[0] === "血圧低") {
        bpressureLow = preVitalHighLow[tmpVhl].split("_")[1];
      }

      if (preVitalHighLow[tmpVhl].split("_")[0] === "脈拍") {
        pulse = preVitalHighLow[tmpVhl].split("_")[1];
      }

      if (preVitalHighLow[tmpVhl].split("_")[0] === "呼吸") {
        respiration = preVitalHighLow[tmpVhl].split("_")[1];
      }
    }

    return {
      temperature: temperature,
      bpressureHigh: bpressureHigh,
      bpressureLow: bpressureLow,
      pulse: pulse,
      respiration: respiration,
      spo2B: null,
      spo2A: null,
    };
  }
}

/**
 * SPO2 の format: spo2B-spo2A, spo2B, -spo2A,
 * @param {string} spo2
 * @param {number} spo2B
 * @param {number} spo2A
 *
 * Return String
 */
function handleSpo2(spo2, spo2B, spo2A) {
  // handle Spo2B and Spo2A
  let spo2BPre = spo2 ? spo2.toString().split("-")[0] : "";
  let spo2APre =
    spo2 && spo2.toString().split("-")[1] ? spo2.split("-")[1] : "";

  if (spo2B !== null) {
    spo2BPre = spo2B;
  }
  if (spo2A !== null) {
    spo2APre = spo2A;
  }

  if (spo2BPre && spo2APre) {
    spo2 = spo2BPre + "-" + spo2APre;
  } else if (spo2BPre && !spo2APre) {
    spo2 = spo2BPre;
  } else if (!spo2BPre && spo2APre) {
    spo2 = "-" + spo2APre;
  } else {
    spo2 = "";
  }

  return spo2;
}

/**
 * zenkai merge
 */
function zenkaiMerge(type) {
  switch (type) {
    case ConstSet.MODAL_B_WEIGHT:
      return "weight";
    case ConstSet.MODAL_B_TEMP:
      return "temperature";
    case ConstSet.MODAL_B_BPRESSURE_HIGHT:
      return "bpressureHigh";
    case ConstSet.MODAL_B_BPRESSURE_LOW:
      return "bpressureLow";
    case ConstSet.MODAL_B_PULSEF_NO:
      return "pulse";
    case ConstSet.MODAL_B_KOKYU_NO:
      return "kokyu";
    case ConstSet.MODAL_B_SPO2_AFTER:
      return "spo2";
    case ConstSet.MODAL_B_SPO2_BEFORE:
      return "spo2";
    default:
      return null;
  }
}

/**
 * Convert MODAL TYPE TO PARAMETER
 */
function convertToParameter(type) {
  switch (type) {
    case ConstSet.MODAL_B_TIME:
      return "time";

    case ConstSet.MODAL_B_REPORTCASETYUIF:
      return "reportCaseTyuif";

    case ConstSet.MODAL_B_MAINF:
      return "mainf";

    case ConstSet.MODAL_B_TEMP:
      return "temperature";

    case ConstSet.MODAL_B_BPRESSURE_HIGHT:
      return "bpressureHigh";

    case ConstSet.MODAL_B_BPRESSURE_LOW:
      return "bpressureLow";

    case ConstSet.MODAL_B_PULSEF_NO:
      return "pulse";

    case ConstSet.MODAL_B_PULSEF_TYPE:
      return "pulsef";

    case ConstSet.MODAL_B_SPO2_BEFORE:
      return "spo2";

    case ConstSet.MODAL_B_SPO2_AFTER:
      return "spo2";

    case ConstSet.MODAL_B_KOKYU_NO:
      return "kokyu";

    case ConstSet.MODAL_B_KOKYU_TYPE:
      return "kokyuf";

    case ConstSet.MODAL_B_ETC0:
      return "etc1";

    case ConstSet.MODAL_B_ETC1:
      return "etc2";

    case ConstSet.MODAL_B_ETC2:
      return "etc3";

    case ConstSet.MODAL_B_COMMENT:
      return "comment";

    case ConstSet.MODAL_B_TANTOMASTER:
      return "tantoId";

    default:
      return "";
  }
}

/**
 *
 * @param {string} fieldName
 * @param {string} value
 */
function setValueLocalstorage(fieldName, value) {
  const key = isLife() ? ConfigName.SWLIFE_SWPSPS : "swpsps";
  let preParedata = isLife()
    ? StorageUtils.getValue(key)
    : JSON.parse(localStorage.getItem(key));

  if (preParedata) {
    preParedata[fieldName] = value;
  } else {
    preParedata = {};
    preParedata.started = true;
    preParedata[fieldName] = value;
  }

  if (isLife()) {
    StorageUtils.setValue(key, preParedata);
  } else {
    localStorage.setItem(key, JSON.stringify(preParedata));
  }
}

/**
 *
 * @param {string} fieldName
 * @param {string} value
 *
 * return value
 * if fieldName is null return all
 */
function getValueLocalstorage(fieldName) {
  const key = isLife() ? ConfigName.SWLIFE_SWPSPS : "swpsps";
  const preParedata = isLife()
    ? StorageUtils.getValue(key)
    : JSON.parse(localStorage.getItem(key));
  // return all if field name is  empty
  if (!fieldName) {
    return preParedata;
  }

  // return value
  if (preParedata && preParedata[fieldName]) {
    return preParedata[fieldName].toString();
  }

  // exception
  return null;
}

/**
 *
 * @param {string} fieldName
 * @param {string} value
 */
function setValueIsKeep(fieldName, value, fileChild) {
  let preParedata = JSON.parse(localStorage.getItem(ConfigName.SWLIFE_IS_KEEP));

  if (preParedata) {
    // check fileChild
    if (fileChild) {
      preParedata[fieldName][fileChild] = value;
    } else {
      preParedata[fieldName] = value;
    }
  } else {
    // setStart
    preParedata = jsonData;

    // check fileChild
    if (fileChild) {
      preParedata[fieldName][fileChild] = value;
    } else {
      preParedata[fieldName] = value;
    }
  }

  localStorage.setItem(ConfigName.SWLIFE_IS_KEEP, JSON.stringify(preParedata));
}

/**
 *
 * @param {string} fieldName
 * @param {string} value
 *
 * return value
 * if fieldName is null return all
 */
function getValueIskeep(fieldName) {
  const preParedata = JSON.parse(localStorage.getItem(ConfigName.SWLIFE_IS_KEEP));

  // return all if field name is  empty
  if (!fieldName) {
    return preParedata;
  }

  // return value
  if (preParedata && preParedata[fieldName]) {
    return preParedata[fieldName];
  }

  // exception
  return null;
}

/**
 *
 * @param {*} array
 * @param {*} key
 */
function convertArrayToString(array, key) {
  let data = "";
  if (array && array.length > 0) {
    for (const i in array) {
      data = data + array[i][key] + ";";
    }
  }

  return data;
}

/**
 *
 * @param {file} img
 * @param {*} callback
 */
function getBase64(file, callback) {
  const reader = new FileReader();
  // image turned to base64-encoded Data URI.
  reader.readAsDataURL(file);
  reader.name = file.name; // get the image's name
  reader.size = file.size; // get the image's size
  reader.onload = function (file) {
    var image = new Image(); // create a image
    var dataUrl = file.target.result;
    image.src = dataUrl;
    // img.name = event.target.name;//set name (optional)
    // img.size = event.target.size;//set size (optional)
    image.onload = function (el) {
      var canvas = document.createElement("canvas"); // create a canvas
      canvas.width = userImageResizeWidth;
      canvas.height = userImageResizeHeight;
      var context = canvas.getContext("2d");

      // 画像のサムネイル位置の判定
      var imageCutSize = 0;
      var startX = 0;
      var startY = 0;
      if (image.width < image.height) {
        imageCutSize = image.width;
        startY = (image.height - image.width) / 2;
      } else {
        imageCutSize = image.height;
        startX = (image.width - image.height) / 2;
      }

      // 画像の撮影向きに従って、表示を修正
      if (getOrientation(dataUrl)) {
        var orientation = getOrientation(dataUrl);
        if (orientation != 1) {
          context.translate(
            userImageResizeWidth / 2,
            userImageResizeHeight / 2
          );
          if (orientation == 6) context.rotate((90 * Math.PI) / 180);
          if (orientation == 3) context.rotate((180 * Math.PI) / 180);
          if (orientation == 8) context.rotate((270 * Math.PI) / 180);
          context.translate(
            -userImageResizeWidth / 2,
            -userImageResizeHeight / 2
          );
        }
      }

      // draw in canvas
      context.drawImage(
        el.target,
        startX,
        startY,
        imageCutSize,
        imageCutSize,
        0,
        0,
        userImageResizeWidth,
        userImageResizeHeight
      );

      // get the base64-encoded Data URI from the resize image
      var srcEncoded = context.canvas.toDataURL(el.target, "image/jpeg", 0);

      callback(srcEncoded);
    };
  };
}

/**
 * DataURL形式の画像データからOrientation情報(回転情報)を取得します
 * @param imgDataURL
 * @returns
 */
function getOrientation(imgDataURL) {
  var byteString = atob(imgDataURL.split(",")[1]);
  var orientaion = byteStringToOrientation(byteString);
  return orientaion;

  function byteStringToOrientation(img) {
    var head = 0;
    var orientation;
    // eslint-disable-next-line no-constant-condition
    while (1) {
      if ((img.charCodeAt(head) == 255) & (img.charCodeAt(head + 1) == 218)) {
        break;
      }
      if ((img.charCodeAt(head) == 255) & (img.charCodeAt(head + 1) == 216)) {
        head += 2;
      } else {
        var length = img.charCodeAt(head + 2) * 256 + img.charCodeAt(head + 3);
        var endPoint = head + length + 2;
        if ((img.charCodeAt(head) == 255) & (img.charCodeAt(head + 1) == 225)) {
          var segment = img.slice(head, endPoint);
          var bigEndian = segment.charCodeAt(10) == 77;
          let count = null;
          if (bigEndian) {
            count = segment.charCodeAt(18) * 256 + segment.charCodeAt(19);
          } else {
            count = segment.charCodeAt(18) + segment.charCodeAt(19) * 256;
          }
          for (var i = 0; i < count; i++) {
            var field = segment.slice(20 + 12 * i, 32 + 12 * i);
            if (
              (bigEndian && field.charCodeAt(1) == 18) ||
              (!bigEndian && field.charCodeAt(0) == 18)
            ) {
              orientation = bigEndian
                ? field.charCodeAt(9)
                : field.charCodeAt(8);
            }
          }
          break;
        }
        head = endPoint;
      }
      if (head > img.length) {
        break;
      }
    }
    return orientation;
  }
}

/**
 * Check filestyle to render icon thumbnail
 *
 * @param {*} fileType
 */
function checkFileStyle(fileType) {
  switch (fileType) {
    case "doc":
      return {
        isIcon: true,
        icon: docIcon,
        type: fileType,
      };

    case "docx":
      return {
        isIcon: true,
        icon: docIcon,
        type: fileType,
      };

    case "xls":
      return {
        isIcon: true,
        icon: xlsIcon,
        type: fileType,
      };

    case "xlsx":
      return {
        isIcon: true,
        icon: xlsIcon,
        type: fileType,
      };

    case "ppt":
      return {
        isIcon: true,
        icon: pptIcon,
      };

    case "pdf":
      return {
        isIcon: true,
        icon: pdfIcon,
        type: fileType,
      };

    case "mp3":
      return {
        isIcon: true,
        icon: voiceIcon,
        type: fileType,
      };

    case "m4a":
      return {
        isIcon: true,
        icon: voiceIcon,
        type: fileType,
      };

    case "mov":
      return {
        isIcon: true,
        icon: movieIcon,
        type: fileType,
      };

    case "MOV":
      return {
        isIcon: true,
        icon: movieIcon,
        type: fileType,
      };

    case "mp4":
      return {
        isIcon: true,
        icon: movieIcon,
        type: fileType,
      };

    case "jpg":
      return {
        isIcon: true,
        icon: imgIcon,
        type: fileType,
      };

    case "jpeg":
      return {
        isIcon: true,
        icon: imgIcon,
        type: fileType,
      };

    case "png":
      return {
        isIcon: true,
        icon: imgIcon,
        type: fileType,
      };

    case "gif":
      return {
        isIcon: true,
        icon: imgIcon,
        type: fileType,
      };

    default:
      return {
        isIcon: true,
        icon: fileIcon,
        type: fileType,
      };
  }
}

/**
 *
 * @param {*} fileType
 *
 * return icon url for convert to base64
 */
function checkIconUrl(fileType) {
  switch (fileType) {
    case "doc":
      return docIcon;

    case "docx":
      return docIcon;

    case "xls":
      return xlsIcon;

    case "xlsx":
      return xlsIcon;

    case "ppt":
      return pptIcon;

    case "pdf":
      return pdfIcon;

    case "mp3":
      return voiceIcon;

    case "m4a":
      return voiceIcon;

    case "mov":
      return movieIcon;

    case "MOV":
      return movieIcon;

    case "mp4":
      return movieIcon;

    default:
      return fileIcon;
  }
}

/**
 *
 * @param {*} url
 * @param {*} callback
 */
function urlTobase64(src, callback) {
  var canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d"),
    img = new Image();

  img.onload = function () {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    callback(canvas.toDataURL());
  };
  img.src = src;
}

/**
 *
 * @param {*} gazouKubun
 * @param {*} kubun
 */
function gazouKubunExtract(gazouKubun, kubun) {
  if (gazouKubun) {
    gazouKubun
      .toString()
      .split(";")
      .map((item) => {
        if (item) {
          if (
            item.toString().split("_")[1] &&
            item.toString().split("_")[1] === kubun
          ) {
            kubun = [item.toString().split("_")[0]];
            return false;
          }
        }

        // return false;
      });
  }

  return kubun;
}

/**
 *
 * @param {array} vitalCol. setting display vital col by [true, True, False]
 * 体重 - [0], 体温 - [1], 血圧 [2],  脈拍/type - [3][4], 呼吸/type ー[5][6], SPO2 - [7]
 * @param {int} modalType define type modal display
 * @param {string} type. next function or back function
 */
function vitalColSetting(vitalCol, modalType, type) {
  // 体重
  if (vitalCol[0] === "False" && modalType === ConstSet.MODAL_B_WEIGHT) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  // 体温
  if (vitalCol[1] === "False" && modalType === ConstSet.MODAL_B_TEMP) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  // 血圧
  if (
    vitalCol[2] === "False" &&
    modalType === ConstSet.MODAL_B_BPRESSURE_HIGHT
  ) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  // 血圧
  if (vitalCol[2] === "False" && modalType === ConstSet.MODAL_B_BPRESSURE_LOW) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  // 脈拍
  if (vitalCol[3] === "False" && modalType === ConstSet.MODAL_B_PULSEF_NO) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  // 脈拍
  if (vitalCol[4] === "False" && modalType === ConstSet.MODAL_B_PULSEF_TYPE) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  // 呼吸
  if (vitalCol[5] === "False" && modalType === ConstSet.MODAL_B_KOKYU_NO) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  // 呼吸
  if (vitalCol[6] === "False" && modalType === ConstSet.MODAL_B_KOKYU_TYPE) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  // SPO2
  if (vitalCol[7] === "False" && modalType === ConstSet.MODAL_B_SPO2_BEFORE) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  // SPO2
  if (vitalCol[7] === "False" && modalType === ConstSet.MODAL_B_SPO2_AFTER) {
    if (type === "next") {
      modalType = modalType + 1;
    }

    if (type === "pre") {
      modalType = modalType - 1;
    }
  }

  return modalType;
}

/**
 *
 * @param {string} data. setting_mater etc [1-number, 5-combobox, 4-text]
 *
 * @return object for each type
 */
// function ectCheckInput(data) {
//   let obj = {
//     etc1: {
//       number: false,
//       combobox: false,
//       text: false
//     },
//     etc2: {
//       number: false,
//       combobox: false,
//       text: false
//     },
//     etc3: {
//       number: false,
//       combobox: false,
//       text: false
//     },

//   }

//   if (data) {
//     let arr = data.toString().split(';')

//     // etc1
//     if (arr[0] && arr[0] === '1') {
//       obj.etc1.number = true;
//     }

//     else if (arr[0] && arr[0] === '5') {
//       obj.etc1.combobox = true;
//     }

//     else if (arr[0] && arr[0] === '4') {
//       obj.etc1.text = true;
//     }

//     // etc2
//     if (arr[1] && arr[1] === '1') {
//       obj.etc2.number = true;
//     }

//     else if (arr[1] && arr[1] === '5') {
//       obj.etc2.combobox = true;
//     }

//     else if (arr[1] && arr[1] === '4') {
//       obj.etc2.text = true;
//     }

//     // etc3
//     if (arr[2] && arr[2] === '1') {
//       obj.etc3.number = true;
//     }

//     else if (arr[2] && arr[2] === '5') {
//       obj.etc3.combobox = true;
//     }

//     else if (arr[2] && arr[2] === '4') {
//       obj.etc3.text = true;
//     }
//   }

//   return obj;

// }

/**
 * CheckSetting and turn display type of all vital colum
 *
 * @param {array} arr
 *
 * @returns {object} obj
 */
function colSetting(arr) {
  const obj = {
    taion: false,
    ketsuatsu: false,
    myakuhakuNo: false,
    myakuhakuType: false,
    spo2: false,
    kokyuNo: false,
    kokyuType: false,
  };

  if (arr.length > 0) {
    if (arr[0] && arr[0] === "True") {
      obj.taion = true;
    }

    if (arr[1] && arr[1] === "True") {
      obj.ketsuatsu = true;
    }

    if (arr[2] && arr[2] === "True") {
      obj.myakuhakuNo = true;
    }

    if (arr[3] && arr[3] === "True") {
      obj.myakuhakuType = true;
    }

    if (arr[4] && arr[4] === "True") {
      obj.spo2 = true;
    }

    if (arr[5] && arr[5] === "True") {
      obj.kokyuNo = true;
    }

    if (arr[6] && arr[6] === "True") {
      obj.kokyuType = true;
    }
  }

  return obj;
}

/**
 * extract riyousya base data from localstorage
 *
 * @param {*} riyousyaId
 * @param {*} string
 */
function extractRiyousyaInfo(riyousyaId, riyousyaInfo) {
  const data = [];

  if (riyousyaInfo) {
    const riyoysyaList = JSON.parse(riyousyaInfo);

    if (riyoysyaList.length > 0) {
      for (const i in riyoysyaList) {
        if (riyoysyaList[i].riyousya.id.toString() === riyousyaId) {
          data.push(riyoysyaList[i]);
          break;
        }
      }
    }
  }

  return data;
}

/**
 * extract riyousya base data from localstorage
 *
 * @param {*} riyousyaId
 * @param {*} string
 */
function exRiyousya(riyousyaId) {
  const data = {
    riyousyaName: "",
    riyousyaId: 0,
    nyuType: 0,
    kubun: "",
  };

  const riyoysyaList = JSON.parse(localStorage.getItem("kirokuBasic"));

  if (riyoysyaList.length > 0) {
    for (const i in riyoysyaList) {
      if (riyoysyaList[i].riyousya.id.toString() === riyousyaId.toString()) {
        data.riyousyaName = riyoysyaList[i].riyousya.name;
        data.riyousyaId = riyoysyaList[i].riyousya.id;
        data.nyuType = riyoysyaList[i].nyuType;
        data.kubun = riyoysyaList[i].kubun;
        break;
      }
    }
  }

  return data;
}

/**
 *
 *
 * @param {*} fromDate
 * @param {*} todate
 * @param {*} records
 * @param {*} riyousya
 */
function reportByRiyousyaFormat(parameterObj, records, gaihaku) {
  const fDate = dayjs(parameterObj.startDate);
  const tDate = dayjs(parameterObj.endDate);

  const data = [];

  let index = 0;

  // hardcode dubplicate kunun key
  const tabActive = localStorage.getItem("tabActive");

  for (
    let m = fDate;
    m.isBefore(tDate) || m.isSame(tDate);
    m = m.add(1, "day")
  ) {
    let obj = {};
    if (tabActive && tabActive === "画像等") {
      obj = {
        index: index,
        riyousyaId: parameterObj.riyousyaId.toString(),
        riyousyaName: gaihaku ? gaihaku[0].riyousyaName : "",
        nyuType: "",
        kubunRiyou: gaihaku ? gaihaku[0].kubun : "",
        date: m.format("YYYY-MM-DD"),
        holidayClass: checkHoliday(m.format("YYYY-MM-DD")),
      };
    } else {
      obj = {
        index: index,
        riyousyaId: parameterObj.riyousyaId.toString(),
        riyousyaName: gaihaku ? gaihaku[0].riyousyaName : "",
        nyuType: "",
        kubun: gaihaku ? gaihaku[0].kubun : "",
        date: m.format("YYYY-MM-DD"),
        holidayClass: checkHoliday(m.format("YYYY-MM-DD")),
      };
    }

    // update gaihaku resiki to record
    let nyuType = "空";
    if (gaihaku.length > 0) {
      for (const j in gaihaku) {
        if (gaihaku[j].date === m.format("YYYY-MM-DD")) {
          if (gaihaku[j].gaihakuKubun == "入院") {
            nyuType = "院";
          } else if (gaihaku[j].gaihakuKubun == "外泊") {
            nyuType = "外";
          } else if (gaihaku[j].gaihakuKubun == "外出") {
            nyuType = "出";
          }
        } else if (
          gaihaku[j].nyusyoDate === m.format("YYYY-MM-DD") &&
          gaihaku[j].gei == "0"
        ) {
          nyuType = "入";
        } else if (
          gaihaku[j].nyusyoDate === m.format("YYYY-MM-DD") &&
          gaihaku[j].gei == "1"
        ) {
          nyuType = "迎";
        } else if (
          gaihaku[j].taishoDate === m.format("YYYY-MM-DD") &&
          gaihaku[j].sou == "0"
        ) {
          nyuType = "退";
        } else if (
          gaihaku[j].taishoDate === m.format("YYYY-MM-DD") &&
          gaihaku[j].sou == "1"
        ) {
          nyuType = "送";
        } else if (
          nyuType == "空" &&
          gaihaku[j].nyusyoDate < m.format("YYYY-MM-DD") &&
          (gaihaku[j].taishoDate === "0001-01-01" ||
            gaihaku[j].taishoDate > m.format("YYYY-MM-DD"))
        ) {
          nyuType = "";
        }
      }
    }

    obj.nyuType = nyuType;

    let checkRecord = false;
    if (records.length > 0) {
      for (const i in records) {
        if (records[i].date === m.format("YYYY-MM-DD")) {
          checkRecord = true;
          obj.index = index;
          data.push(Object.assign({}, obj, records[i]));
          index++;
        }
      }
    }

    if (!checkRecord) {
      data.push(obj);
      index++;
    }
  }

  return sortAlgorithm(data);
}

/**
 *
 *
 * @param {*} fromDate
 * @param {*} todate
 * @param {*} records
 * @param {*} riyousya
 */
function countRecordByDate(records, recordId, field) {
  let res = 0;

  let date = "";
  records.forEach(function (v) {
    if (v[field] && v[field] === recordId) {
      date = v.date;
    }
  });

  records.forEach(function (v) {
    if (v.date === date) {
      res = (res || 0) + 1;
    }
  });

  return res;
}

/**
 *
 *
 * @param {*} fromDate
 * @param {*} todate
 * @param {*} records
 * @param {*} riyousya
 */
function formatRecords(records, recordId, field) {
  const count = countRecordByDate(records, recordId, field);

  const data = [];

  if (count > 1) {
    let reIndex = 0;
    records.forEach((item) => {
      if (!item[field] || item[field] !== recordId) {
        item.index = reIndex;
        data.push(item);
        reIndex = reIndex + 1;
      }
    });
  }

  if (count === 1) {
    records.forEach((item, index) => {
      if (item[field] && item[field] === recordId) {
        item = {
          index: index,
          riyousyaId: item.riyousyaId,
          riyousyaName: item.riyousyaName,
          kubun: item.kubun,
          nyuType: item.nyuType,
          date: item.date,
        };
        return data.push(item);
      } else {
        item.index = index;
        data.push(item);
      }
    });
  }

  return data;
}

/**
 * Covert int item in object to sting. server required all params is string
 *
 * @param {obj} params
 */
function convertStringInArr(params) {
  if (params) {
    for (const key in params) {
      // eslint-disable-next-line no-prototype-builtins
      if (params.hasOwnProperty(key)) {
        if (typeof params[key] === "number") {
          params[key] = params[key].toString();
        }
      }
    }
  }

  return params;
}

/**
 *
 * Sort ASC time for report follow riyousya
 *
 * @param {*} arraySort
 * @param {*} key
 * @param {*} type
 */
function sortAlgorithm(arraySort) {
  arraySort = arraySort.slice().sort(function (a, b) {
    // sortData.sort.key1
    if (a.date === b.date && a.time < b.time) return -1;
    else return 1;
  });

  return arraySort;
}

/**
 *
 *
isEmpty(""), // true
isEmpty(33), // true (arguably could be a TypeError)
isEmpty([]), // true
isEmpty({}), // true
isEmpty({length: 0, custom_property: []}), // true

isEmpty("Hello"), // false
isEmpty([1,2,3]), // false
isEmpty({test: 1}), // false
isEmpty({length: 3, custom_property: [1,2,3]}) // false
 *
 * @param {*} obj
 */
// Speed up calls to hasOwnProperty
var hasOwnProperty = Object.prototype.hasOwnProperty;
function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

/**
 * Convert YYYY-M-D to YYYY-MM-DD
 *
 * @param {string} date
 */
function convertDateToX(date) {
  var pad = function (n) {
    return function (str) {
      while (str.length < n) {
        str = "0" + str;
      }
      return str;
    };
  };

  date = date.split(/-/g).map(pad(2)).join("-"); // => 2013-01-01

  return date;
}

function getUrlVarsNew() {
  var vars = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );

  return vars;
}

/**
 *
 * @param {*} dateString
 */
function addZeroToDateString(dateString) {
  let y = "0000";
  let m = "00";
  let d = "00";

  if (dateString) {
    const arr = dateString.split("-");

    if (arr[0]) {
      y = arr[0];
    }

    if (arr[1]) {
      if (arr[1] < 10) {
        m = "0" + arr[1].replace(/^0+/, "");
      } else {
        m = arr[1];
      }
    }

    if (arr[2]) {
      if (arr[2] < 10) {
        d = "0" + arr[2].replace(/^0+/, "");
      } else {
        d = arr[2];
      }
    }
  }

  return y + "-" + m + "-" + d;
}

// update parameter on url
function updateUrlParameter(param, value) {
  const regExp = new RegExp(param + "(.+?)(&|$)", "g");
  const newUrl = window.location.href.replace(
    regExp,
    param + "=" + value + "$2"
  );
  window.history.pushState("", "", newUrl);
}

/**
 * Convert from time to active row
 *
 * @param {String: 00:00 or 00:00:00} activeTime
 */
function convertTimeToIndex(timeActive) {
  if (!timeActive) return;

  const timeString = timeActive.substring(0, 5).split(":");

  if (!timeString[1]) return;

  const hr = parseInt(timeString[0], 10);
  const mt = parseInt(timeString[1], 10);

  switch (hr) {
    case 0:
      if (mt < 30) return 0;
      else return 1;

    case 1:
      if (mt < 30) return 2;
      else return 3;

    case 2:
      if (mt < 30) return 4;
      else return 5;

    case 3:
      if (mt < 30) return 6;
      else return 7;

    case 4:
      if (mt < 30) return 8;
      else return 9;

    case 5:
      if (mt < 30) return 10;
      else return 11;

    case 6:
      if (mt < 30) return 12;
      else return 13;

    case 7:
      if (mt < 30) return 14;
      else return 15;

    case 8:
      if (mt < 30) return 16;
      else return 17;

    case 9:
      if (mt < 30) return 18;
      else return 19;

    case 10:
      if (mt < 30) return 20;
      else return 21;

    case 11:
      if (mt < 30) return 22;
      else return 23;

    case 12:
      if (mt < 30) return 24;
      else return 25;

    case 13:
      if (mt < 30) return 26;
      else return 27;

    case 14:
      if (mt < 30) return 28;
      else return 29;

    case 15:
      if (mt < 30) return 30;
      else return 31;

    case 16:
      if (mt < 30) return 32;
      else return 33;

    case 17:
      if (mt < 30) return 34;
      else return 35;

    case 18:
      if (mt < 30) return 36;
      else return 37;

    case 19:
      if (mt < 30) return 38;
      else return 39;

    case 20:
      if (mt < 30) return 40;
      else return 41;

    case 21:
      if (mt < 30) return 42;
      else return 43;

    case 22:
      if (mt < 30) return 44;
      else return 45;

    case 23:
      if (mt < 30) return 46;
      else return 47;

    default:
      return;
  }
}

/**
 * This function clear all localStorage without filter and sort history
 *
 */
function localStorageClearCus() {
  // Keep Filter and Sort history
  const filter = localStorage.getItem(ConfigName.SWLIFE_IS_KEEP);
  const iosFilter = localStorage.getItem("showFilter");
  // Keep Menu, Filter and Sort history
  const menu = localStorage.getItem("menu");
  let currentmenu = localStorage.getItem("currentmenu");
  if (!currentmenu) {
    currentmenu = "record0";
  }
  const showreportlist = localStorage.getItem("showreportlist");
  const officeAuth_roombunrui = localStorage.getItem("officeAuth_roombunrui");
  const showReportList2 = localStorage.getItem("showreportlist2");
  const dayKeikaFilter = JSON.parse(localStorage.getItem("dayKeikaFilter"));
  const riyouKeikaFilter = JSON.parse(localStorage.getItem("riyouKeikaFilter"));

  // Clear Other
  // localStorage.clear();

  // Save filter again
  localStorage.setItem(ConfigName.SWLIFE_IS_KEEP, filter);
  localStorage.setItem("showFilter", iosFilter);
  // Save menu, filter again
  localStorage.setItem("menu", menu);
  localStorage.setItem("filter", filter);
  localStorage.setItem("currentmenu", currentmenu);
  localStorage.setItem("showreportlist", showreportlist);
  localStorage.setItem("showreportlist2", showReportList2);

  if (dayKeikaFilter !== null) {
    localStorage.setItem("dayKeikaFilter", JSON.stringify(dayKeikaFilter));
  }
  if (riyouKeikaFilter !== null) {
    localStorage.setItem("riyouKeikaFilter", JSON.stringify(riyouKeikaFilter));
  }
  if (officeAuth_roombunrui !== null) {
    localStorage.setItem("officeAuth_roombunrui", officeAuth_roombunrui);
  }
}

function openNotificationWithIcon(type, notifyContent, callback) {
  notification[type]({
    message: notifyContent,
    placement: "topRight",
    duration: type === "success" ? 1 : 3,
    style: { whiteSpace: "pre-wrap" },
    prefixCls: "pljp-notification",
  });
  if (callback) {
    callback();
  }
}

function disPlanToString(eventPlan, riyousyaYoutei, riyousyaSonota) {
  let result = "";

  if (eventPlan) {
    result = eventPlan;
  }

  if (riyousyaYoutei) {
    if (result) {
      result = result + "、" + riyousyaYoutei;
    } else {
      result = riyousyaYoutei;
    }
  }

  if (riyousyaSonota) {
    if (result) {
      result = result + "、" + riyousyaSonota;
    } else {
      result = riyousyaSonota;
    }
  }

  return result;
}

/**
 * Fuction prepare parameters for loading kiroku data
 *
 * Using for search, sort...function
 * When user search or sort,
 * all data have been reload
 * and then the sort and the search algorithm have been apply on that data
 *
 */
function reportParams(objState, type) {
  switch (objState.tabName) {
    case ConstSet.KIROKU_RIOYUSYA:
      return objState;

    case ConstSet.KIROKU_VITAL:
      let startTime = "0:00";
      let endTime = "23:59";
      if (
        getValueIskeep("jikantai") &&
        getValueIskeep("jikantai") !== "全表示"
      ) {
        startTime = getValueIskeep("jikantai").split("~")[0];
        endTime = getValueIskeep("jikantai").split("~")[1];
      }
      return {
        isLoadBasic: objState.isLoadBasic,
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.startDate,
        endDate: objState.endDate,
        startTime: startTime, // vital
        endTime: endTime,
        shubetu: objState.shubetu,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_MEAL:
      return objState;

    case ConstSet.KIROKU_SUIBUN:
      let startTimeSb = "0:00";
      let endTimeSb = "23:59";
      if (
        getValueIskeep("jikantaiSb") &&
        getValueIskeep("jikantaiSb") !== "全表示"
      ) {
        startTimeSb = getValueIskeep("jikantaiSb").split("~")[0];
        endTimeSb = getValueIskeep("jikantaiSb").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeSb: startTimeSb, // suibun
        endTimeSb: endTimeSb,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_HAISETU:
      let startTimeH = "0:00";
      let endTimeH = "23:59";
      if (
        getValueIskeep("jikantaiH") &&
        getValueIskeep("jikantaiH") !== "全表示"
      ) {
        startTimeH = getValueIskeep("jikantaiH").split("~")[0];
        endTimeH = getValueIskeep("jikantaiH").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeH: startTimeH, // haisetu
        endTimeH: endTimeH,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_NYUYOKU:
      let startTimeN = "0:00";
      let endTimeN = "23:59";
      if (
        getValueIskeep("jikantaiN") &&
        getValueIskeep("jikantaiN") !== "全表示"
      ) {
        startTimeN = getValueIskeep("jikantaiN").split("~")[0];
        endTimeN = getValueIskeep("jikantaiN").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeN: startTimeN, // nyushoku
        endTimeN: endTimeN,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_FUKUYAKU:
      let startTimeF = "0:00";
      let endTimeF = "23:59";
      if (
        getValueIskeep("jikantaiF") &&
        getValueIskeep("jikantaiF") !== "全表示"
      ) {
        startTimeF = getValueIskeep("jikantaiF").split("~")[0];
        endTimeF = getValueIskeep("jikantaiF").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeF: startTimeF, // fukuyaku
        endTimeF: endTimeF,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_KAIGO:
      let startTimeK = "0:00";
      let endTimeK = "23:59";
      if (
        getValueIskeep("jikantaiK") &&
        getValueIskeep("jikantaiK") !== "全表示"
      ) {
        startTimeK = getValueIskeep("jikantaiK").split("~")[0];
        endTimeK = getValueIskeep("jikantaiK").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeK: startTimeK, // kaigonaigo
        endTimeK: endTimeK,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_TOKKI:
      let startTimeT = "0:00";
      let endTimeT = "23:59";
      if (
        getValueIskeep("jikantaiT") &&
        getValueIskeep("jikantaiT") !== "全表示"
      ) {
        startTimeT = getValueIskeep("jikantaiT").split("~")[0];
        endTimeT = getValueIskeep("jikantaiT").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeT: startTimeT, // tokki
        endTimeT: endTimeT,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_FILE:
      let startTimeG = "0:00";
      let endTimeG = "23:59";
      if (
        getValueIskeep("jikantaiG") &&
        getValueIskeep("jikantaiG") !== "全表示"
      ) {
        startTimeG = getValueIskeep("jikantaiG").split("~")[0];
        endTimeG = getValueIskeep("jikantaiG").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeG: startTimeG, // gazou
        endTimeG: endTimeG,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    default:
      return objState;
  }
}

/**
 * Function add element to data that return after insert kiroku
 *
 * DOM is updated directly, not reload data of page agian
 *
 */
function modifyAfterInsert(payload, item) {
  // update index key to return value
  payload.key = item.index;
  payload.index = item.index;

  // update riyousya information
  payload.riyousya = item.riyousya;

  //
  payload.kubun = item.kubun;

  //
  payload.bunrui = item.bunrui;

  //
  payload.room = item.room;

  //
  payload.nyuType = item.nyuType;

  // with gazo tab(image), kubun of riyou has been convert to kubunRiyou
  payload.kubunRiyou = item.kubunRiyou;

  return payload;
}

/**
 * extract value from setting by index and position
 *
 * @param {ABC_XYZ;BCD_BBD;JPJ_VNV} setting
 * @param {0->n of Arr} index
 * @param {0/1} pos
 *
 * @returns string
 */
function extractKubunSetting(setting, index, pos) {
  let result = "";

  if (setting) {
    const tmpArrParent = setting.toString().split(";");

    if (tmpArrParent[index]) {
      const tmpArrChild = tmpArrParent[index].toString().split("_");

      if (tmpArrChild[pos]) {
        result = tmpArrChild[pos];
      }
    }
  }

  return result;
}

/**
 *
 * @param {*} data // riyousya detail
 * @param {*} field // riyou tabs: riyouConcern,...など
 * @param {*} type // add, up/down, del
 * @param {*} index // handling index
 * @param {*} value // 薬剤のformのvalues
 */
function riyouAddSortDel(data, field, type, index, value) {
  const result = _.cloneDeep(data);

  switch (field) {
    case "riyouConcern": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouConcern = handleRiyouAdd(result.riyouConcern);
          return result;
        case "up":
          result.riyouConcern = handleSort(result.riyouConcern, type, index);
          return result;

        case "down":
          result.riyouConcern = handleSort(result.riyouConcern, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouByoureki": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouByoureki = handleRiyouAdd(result.riyouByoureki);
          return result;
        case "up":
          result.riyouByoureki = handleSort(result.riyouByoureki, type, index);
          return result;

        case "down":
          result.riyouByoureki = handleSort(result.riyouByoureki, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouKusuri": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouKusuri = handleRiyouAdd(result.riyouKusuri);
          return result;
        case "up":
          result.riyouKusuri = handleSort(result.riyouKusuri, type, index);
          return result;

        case "down":
          result.riyouKusuri = handleSort(result.riyouKusuri, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouKiki": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouKiki = handleRiyouAdd(result.riyouKiki);
          return result;
        case "up":
          result.riyouKiki = handleSort(result.riyouKiki, type, index);
          return result;

        case "down":
          result.riyouKiki = handleSort(result.riyouKiki, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouService": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouService = handleRiyouAdd(result.riyouService);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouNyuin": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouNyuin = handleRiyouAdd(result.riyouNyuin, "riyouNyuin");
          return result;
        case "up":
          result.riyouNyuin = handleSort(result.riyouNyuin, type, index);
          return result;

        case "down":
          result.riyouNyuin = handleSort(result.riyouNyuin, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "yakuzaiChangeDetail":
      switch (type) {
        case "add": // tmpのdetailをresultに反映させる
          result.yakuzaiChangeDetail = handleYakuzaiAdd(
            result.yakuzaiChangeDetail,
            "detail",
            value.yakuzaiChangeDetail
          );
          return result;

        case "del":
          result.yakuzaiChangeDetail = handleYakuzaiDel(
            result,
            field,
            index,
            value
          );
          return result;

        default:
          return result;
      }

    case "yakuzaiChangeKioureki":
      switch (type) {
        case "add":
          result.yakuzaiChangeKioureki = handleYakuzaiAdd(
            result.yakuzaiChangeKioureki,
            null,
            value.yakuzaiChangeKioureki
          );
          return result;

        case "del":
          result.yakuzaiChangeKioureki = handleYakuzaiDel(
            result,
            field,
            index,
            value
          );
          return result;

        default:
          return result;
      }

    case "test":
      return result;

    default:
  }
}

/**
 * mark add + reindex
 *
 * @param {*} data
 */
function handleRiyouAdd(data, type) {
  let tmp = [];
  if (data.length > 0) {
    const tmp = [];
    for (let i = 0; i <= data.length; i++) {
      if (type && type === "riyouNyuin") {
        // if riyouNyuin tabs, the record will be put to front of
        if (!i) {
          // case i = 0
          tmp.push({ sortNum: 1, inputType: "add" });

          tmp.push(Object.assign(data[i], { sortNum: i + 2 }));
        }

        if (i && i < data.length) {
          // case i !== 0
          tmp.push(Object.assign(data[i], { sortNum: i + 2 }));
        }
      } else {
        if (i < data.length) {
          tmp.push(Object.assign(data[i], { sortNum: i + 1 }));
        }

        if (i === data.length) {
          tmp.push({ sortNum: i + 1, inputType: "add" });
        }
      }
    }
    return tmp;
  } else {
    tmp = [{ sortNum: 1, inputType: "add" }];

    return tmp;
  }
}

/**
 * handle del, mark recordId that have been delete if saving button is click
 * rewrite sortNum field
 * return riyousyaDetail
 * @param {*} data
 * @param {*} field
 * @param {*} index
 */
function handleRiyouDel(data, field, index) {
  const tmp = [];
  if (data && data[field] && data[field].length > 0) {
    let count = 1;
    for (let i = 0; i < data[field].length; i++) {
      if (i !== index) {
        tmp.push(Object.assign(data[field][i], { sortNum: count }));
        count++;
      }

      if (i === index) {
        if (data[`${field}DelIds`]) {
          data[`${field}DelIds`] =
            data[`${field}DelIds`] + "," + data[field][i].id;
        } else {
          data[`${field}DelIds`] = "" + data[field][i].id;
        }
      }
    }

    data[field] = tmp;
  }

  return data;
}

/**
 *
  @param {} data
 */
function handleYakuzaiAdd(data, type, val) {
  let tmp = [];
  if (val && val.length > 0) {
    const tmp = [];
    for (let i = 0; i <= val.length; i++) {
      if (i < val.length) { // 古いデータにプッシュ
        tmp.push(val[i]);
      }

      if (i === val.length) { // 新しいデータに空をプッシュ
        if (type === "detail") {
          tmp.push({ changeStatus: "11" });
        } else {
          tmp.push([{}]);
        }
      }
    }
    return tmp;
  } else {
    if (type === "detail") {
      tmp = [{ changeStatus: "11" }];
    } else {
      tmp = [{}];
    }
    return tmp;
  }
}

/**
  @param {} data
  @param {} field
  @param {} index
 */
function handleYakuzaiDel(data, field, index, val) {
  let tmp = [];
  if (val && val[field] && val[field].length > 0) {
    for (let i = 0; i < val[field].length; i++) {
      if (i !== index) { // 引数のindexとiが違うならそのままデータをプッシュ
        tmp.push(val[field][i]);
      }

      if (i === index && val[field][i].id) { // 選択したindexと同じでidがあれば消すidを追加し、データをプッシュしない
        if (data[`${field}DelIds`]) {
          data[`${field}DelIds`] =
            data[`${field}DelIds`] + "," + data[field][i].id;
        } else {
          data[`${field}DelIds`] = "" + data[field][i].id;
        }
      }

      if (data[field].length === 1) { // 空のフォーム
        tmp = [{ changeStatus: "11" }];
      }
    }

  } else if (field === "yakuzaiChangeDetail") {
    tmp = [{ changeStatus: "11" }];
  } else {
    tmp = [{}];
  }

  return tmp;
}

/**
 *
 * @param {*} data
 * @param {*} sortType
 * @param {*} index
 */
function handleSort(data, sortType, index) {
  if (sortType === "up") {
    if (index !== 0) {
      data[index - 1].sortNum = data[index - 1].sortNum + 1;
      data[index].sortNum = data[index].sortNum - 1;

      return _.sortBy(data, ["sortNum"]);
    }
  }

  if (sortType === "down") {
    if (index !== data.length - 1) {
      data[index + 1].sortNum = data[index + 1].sortNum - 1;
      data[index].sortNum = data[index].sortNum + 1;

      return _.sortBy(data, ["sortNum"]);
    }
  }

  return data;
}

/**
 *
 * @param {*} data
 * @param {*} forms:
 * forms.formRefBasic - object,
 * forms.formRefKankei - arrayObject,
 * forms.formRefByoki - arrayObject,
 * forms.formRefKusuri - arrayObject,
 * forms.formRefKiki - arrayObject,
 * forms.formRefService - arrayObject,
 * forms.formRefNyuin - arrayObject,
 * forms.formRefFacesheet - object,
 * forms.formRefAdl - object,
 * forms.formRefJokyo - object,
 * forms.formRefHoka - object,
 *
 */
function riyouMergeFormToState(data, forms, facesheetId, facesheetType) {
  const riyousyaId = data.id;

  let result = _.cloneDeep(data);
  // formRefBasic
  if (forms && forms.formRefBasic) {
    result = Object.assign({}, result, forms.formRefBasic);
    result.facesheet = {};
  }

  // formRefKankei
  if (
    forms &&
    !_.isEmpty(forms.formRefKankei) &&
    forms.formRefKankei.length > 0
  ) {
    const tmpConcert = [];
    for (let i = 0; i < forms.formRefKankei.length; i++) {
      const cusCheckbox = {
        doukyof: forms.formRefKankei[i].doukyo
          ? forms.formRefKankei[i].doukyo.length
          : 0,
        kaigof:
          forms.formRefKankei[i].kaigof1 &&
            forms.formRefKankei[i].kaigof1.length
            ? 1
            : forms.formRefKankei[i].kaigof2 &&
              forms.formRefKankei[i].kaigof2.length
              ? 2
              : 0,
        seikyuf: forms.formRefKankei[i].seikyuf
          ? forms.formRefKankei[i].seikyuf.length
          : 0,
        keypersonf: forms.formRefKankei[i].keypersonf
          ? forms.formRefKankei[i].keypersonf.length
          : 0,
        moshikomif: forms.formRefKankei[i].moshikomif
          ? forms.formRefKankei[i].moshikomif.length
          : 0,
        hoshoninf: forms.formRefKankei[i].hoshoninf
          ? forms.formRefKankei[i].hoshoninf.length
          : 0,
        urgentf: forms.formRefKankei[i].urgentf
          ? forms.formRefKankei[i].urgentf.length
          : 0,
        furigana: forms.formRefKankei[i].furigana
          ? forms.formRefKankei[i].furigana
          : "",
        name: forms.formRefKankei[i].name ? forms.formRefKankei[i].name : "",
        kankei: forms.formRefKankei[i].kankei
          ? forms.formRefKankei[i].kankei
          : "",
      };

      if (data.riyouConcern && data.riyouConcern[i]) {
        tmpConcert.push(
          Object.assign(
            {},
            data.riyouConcern[i],
            forms.formRefKankei[i],
            { riyousyaId: riyousyaId },
            cusCheckbox
          )
        );
      }
    }

    result.riyouConcern = tmpConcert;
  }

  // formRefByoki
  if (
    forms &&
    !_.isEmpty(forms.formRefByoki) &&
    forms.formRefByoki.length > 0
  ) {
    const tmpByoki = [];
    for (let i = 0; i < forms.formRefByoki.length; i++) {
      const cusCheckbox = {
        mainf: forms.formRefByoki[i].mainf
          ? forms.formRefByoki[i].mainf.length
          : 0,
        kansenf: forms.formRefByoki[i].kansenf
          ? forms.formRefByoki[i].kansenf.length
          : 0,
        tokuteif: forms.formRefByoki[i].tokuteif
          ? forms.formRefByoki[i].tokuteif.length
          : 0,
        ryoyoSyokuf: forms.formRefByoki[i].ryoyoSyokuf
          ? forms.formRefByoki[i].ryoyoSyokuf.length
          : 0,
        kangoSijif: forms.formRefByoki[i].kangoSijif
          ? forms.formRefByoki[i].kangoSijif.length
          : 0,
      };
      if (data.riyouConcern && data.riyouByoureki[i]) {
        tmpByoki.push(
          Object.assign(
            {},
            data.riyouByoureki[i],
            forms.formRefByoki[i],
            { riyousyaId: riyousyaId },
            cusCheckbox
          )
        );
      }
    }

    result.riyouByoureki = tmpByoki;
  }

  // formRefKusuri
  if (
    forms &&
    !_.isEmpty(forms.formRefKusuri) &&
    forms.formRefKusuri.length > 0
  ) {
    const tmpKusuri = [];
    for (let i = 0; i < forms.formRefKusuri.length; i++) {
      if (data.riyouKusuri && data.riyouKusuri[i]) {
        const tmpUpdateField = {
          riyousyaId: riyousyaId,
          stop: 0,
          bikou: forms.formRefKusuri[i].bikou
            ? forms.formRefKusuri[i].bikou
            : "",
        };

        tmpKusuri.push(
          Object.assign(
            {},
            data.riyouKusuri[i],
            forms.formRefKusuri[i],
            tmpUpdateField
          )
        );
      }
    }

    result.riyouKusuri = tmpKusuri;
  }

  // formRefKiki
  if (forms && !_.isEmpty(forms.formRefKiki) && forms.formRefKiki.length > 0) {
    const tmpKiki = [];

    for (let i = 0; i < forms.formRefKiki.length; i++) {
      if (data.riyouKiki && data.riyouKiki[i]) {
        const tmpUpdateField = {
          riyousyaId: riyousyaId,
          bikou: forms.formRefKiki[i].bikou ? forms.formRefKiki[i].bikou : "",
        };

        tmpKiki.push(
          Object.assign(
            {},
            data.riyouKiki[i],
            forms.formRefKiki[i],
            tmpUpdateField
          )
        );
      }
    }

    result.riyouKiki = tmpKiki;
  }

  // formRefService
  if (
    forms &&
    !_.isEmpty(forms.formRefService) &&
    forms.formRefService.length > 0
  ) {
    const tmpService = [];

    for (let i = 0; i < forms.formRefService.length; i++) {
      if (forms.formRefService[i].kaishiDate) {
        forms.formRefService[i].kaishiDate = forms.formRefService[
          i
        ].kaishiDate.format(ConstSet.DATE_FORMAT);
      }

      if (data.riyouService && data.riyouService[i]) {
        tmpService.push(
          Object.assign({}, data.riyouService[i], forms.formRefService[i], {
            riyousyaId: riyousyaId,
          })
        );
      }
    }

    result.riyouService = tmpService;
  }

  // formRefNyuin
  if (
    forms &&
    !_.isEmpty(forms.formRefNyuin) &&
    forms.formRefNyuin.length > 0
  ) {
    const tmpNyuin = [];

    for (let i = 0; i < forms.formRefNyuin.length; i++) {
      // if (forms.formRefNyuin[i].nyuinDate) {
      //   forms.formRefNyuin[i].nyuinDate = forms.formRefNyuin[i].nyuinDate.format(ConstSet.DATE_FORMAT);
      // }

      // if (forms.formRefNyuin[i].tainDate) {
      //   forms.formRefNyuin[i].tainDate = forms.formRefNyuin[i].tainDate.format(ConstSet.DATE_FORMAT);
      // }

      if (data.riyouNyuin && data.riyouNyuin[i]) {
        tmpNyuin.push(
          Object.assign({}, data.riyouNyuin[i], forms.formRefNyuin[i], {
            riyousyaId: riyousyaId,
          })
        );
      }
    }

    result.riyouNyuin = tmpNyuin;
  }

  // formRefFacesheet
  let facesheetOri = {};
  if (data && data.facesheet && data.facesheet.length > 0) {
    if (facesheetId > 0) {
      facesheetOri = data.facesheet.find(
        (item) => item.id.toString() === facesheetId.toString()
      );

      if (!facesheetOri) {
        facesheetOri = data.facesheet[0];
      }
    } else {
      facesheetOri = data.facesheet[0];
    }
  }

  if (forms && !_.isEmpty(forms.formRefFacesheet)) {
    // if (forms.formRefFacesheet.date) {
    //   forms.formRefFacesheet.date = forms.formRefFacesheet.date.format(ConstSet.DATE_FORMAT);
    // }

    result.facesheet = Object.assign({}, facesheetOri, forms.formRefFacesheet, {
      riyousyaId: riyousyaId,
    });

    if (facesheetId && facesheetType === "copy") {
      // remove facesheetId to add new record
      result.facesheet.id = 0;
    }
  }

  // merge formRefAdl
  const tmpAdl = [];
  if (forms && !_.isEmpty(forms.formRefAdl) && forms.formRefAdl.length > 0) {
    for (let i = 0; i < forms.formRefAdl.length; i++) {
      tmpAdl.push({
        riyousyaId: riyousyaId,
        id: forms.formRefAdl[i].id,
        kihonAdlMasterId: forms.formRefAdl[i].kihonAdlMasterId,
        answer: forms.formRefAdl[i].answer,
        facesheetId: data.facesheet ? data.facesheet.id : 0,
      });
    }
  }
  result.riyouAdl = tmpAdl;

  // merge formRefJokyo
  if (forms && !_.isEmpty(forms.formRefJokyo)) {
    if (!_.isEmpty(result.facesheet)) {
      result.facesheet = Object.assign(
        {},
        result.facesheet,
        forms.formRefJokyo
      );
    } else {
      result.facesheet = Object.assign({}, facesheetOri, forms.formRefJokyo, {
        riyousyaId: riyousyaId,
      });
    }

    if (facesheetId && facesheetType === "copy") {
      // remove facesheetId to add new record
      result.facesheet.id = 0;
    }
  }

  // merge formRefHoka
  if (forms && !_.isEmpty(forms.formRefHoka)) {
    if (!_.isEmpty(result.facesheet)) {
      result.facesheet = Object.assign({}, result.facesheet, forms.formRefHoka);
    } else {
      result.facesheet = Object.assign({}, facesheetOri, forms.formRefHoka, {
        riyousyaId: riyousyaId,
      });
    }

    if (facesheetId && facesheetType === "copy") {
      // remove facesheetId to add new record
      result.facesheet.id = 0;
      result.facesheet.faceAdl = [];
      result.facesheet.jigyosyoFacesheet = [];
    }
  }

  // merge formRefHoka2
  if (forms && !_.isEmpty(forms.formRefHoka2)) {
    if (
      !_.isEmpty(result.facesheet.jigyosyoFacesheet) &&
      !_.isEmpty(result.facesheet.jigyosyoFacesheet[0])
    ) {
      result.jigyosyofacesheet = Object.assign(
        {},
        result.facesheet.jigyosyoFacesheet[0],
        forms.formRefHoka2
      );
    } else {
      result.jigyosyofacesheet = forms.formRefHoka2;
    }

    if (facesheetId && facesheetType === "copy") {
      result.jigyosyofacesheet.id = 0;
      result.jigyosyofacesheet.facesheetId = 0;
      result.jigyosyofacesheet.riyousyaId = riyousyaId;
    }
  } else {
    result.jigyosyofacesheet = {};
  }

  return result;
}

function stateYougoMasterName(listName) {
  const menu = formatStorage(getMenu());
  const systemName = menu.sn;
  const sid = menu.si;
  switch (listName) {
    case ListNameSet.SYOKUJI_KUBUN:
      return "shokujisenbunrui";

    case ListNameSet.SYOKUJI_TOKKI:
      return "shokujisenbikou";

    case ListNameSet.SHOKUJI_JOKYO:
      return "shokujiRirekiSituation";

    case ListNameSet.SHOKUJI_COMMENT:
      return "shokujiRirekiComent";

    // riyousya update page
    // @TODO update to const
    // Basic tab1
    case "利用者分類1":
      return "riyousyaBunrui1";

    case "利用者分類2":
      return "riyousyaBunrui2";

    case "利用者分類3":
      return "riyousyaBunrui3";

    case "利用者分類4":
      return "riyousyaBunrui4";

    case "利用者分類5":
      return "riyousyaBunrui5";

    case systemName + "分類1":
      return "systemBunrui1";

    case systemName + "分類2":
      return "systemBunrui2";

    case systemName + "分類3":
      return "systemBunrui3";

    case systemName + "分類4":
      return "systemBunrui4";

    case systemName + "分類5":
      return "systemBunrui5";

    case "入金分類":
      return "nyukinBunrui";

    case "入金摘要":
      return "nyukinTekiyo";

    // kankei tab2
    case "利用者関係":
      return "kankeList";

    // service tab6
    case "利用機関名":
      return "kikanNameList";

    case "機関利用頻度":
      return "hindoList";

    case "機関利用状況":
      return "stateList";

    // nyuin tab7
    case "入院理由":
      return "nyuinRiyuList";

    case "退院理由":
      return "tainRiyuList";

    //
    case "宛先":
      return "attnList";

    case "現況":
      return "genkyouList";

    case "経済状況":
      return "keizaiJoukyouList";

    case "家族状況":
      return "kazokuJoukyouList";

    case "身体状況":
      return "shintaiJoukyouList";

    case "療養状況":
      return "ryoyoJokyoList";

    case "介護状況":
      return "kaigoJokyoList";

    case "住居環境":
      return "jukyoKankyouList";

    case "生活歴":
      return "seikatuRekiList";

    case "既往歴":
      return "kiorekiList";

    case "職業歴":
      return "shokuRekiList";

    case "趣味":
      return "syumiEtcList";

    case "対人関係":
      return "taijinKankeiList";

    case "本人主訴":
      return "honninSyusoList";

    case "家族主訴":
      return "kazokuSyusoList";

    case "本人家族の意向":
      return "honninKazokuIkoList";

    case "関係者要望":
      return "kankeishaYoboList";

    case "公的サービス":
      return "riyouServiceKoutekiList";

    case "非公的サービス":
      return "riyouServiceHikoutekiList";

    //
    case "FSその他1":
      return "sonotaList1";

    case "FSその他2":
      return "sonotaList2";

    case "FSその他3":
      return "sonotaList3";

    case "FSその他4":
      return "sonotaList4";

    case "FSその他5":
      return "sonotaList5";

    case "FSその他6":
      return "sonotaList6";

    case "FSその他7":
      return "sonotaList7";

    case "FSその他8":
      return "sonotaList8";

    case "FSその他9":
      return "sonotaList9";

    case "FSその他10":
      return "sonotaList10";

    case "FSSその他1S" + sid:
      return "systemSonotaList1";

    case "FSSその他2S" + sid:
      return "systemSonotaList2";

    case "FSSその他3S" + sid:
      return "systemSonotaList3";

    case "FSSその他4S" + sid:
      return "systemSonotaList4";

    case "FSSその他5S" + sid:
      return "systemSonotaList5";

    case "FSSその他6S" + sid:
      return "systemSonotaList6";

    case "FSSその他7S" + sid:
      return "systemSonotaList7";

    case "FSSその他8S" + sid:
      return "systemSonotaList8";

    case "FSSその他9S" + sid:
      return "systemSonotaList9";

    case "FSSその他10S" + sid:
      return "systemSonotaList10";

    case "病名分類":
      return "ymByoumeiBunrui";

    case "薬名分類":
      return "ymKusurimeiBunrui";

    default:
      return "error";
  }
}

/**
 *
 * @param {*} records sendgroups records
 * @param {*} checkListUsers checklist user add
 * @param {*} selectedGroup group add TO
 */
function addSendGroupRecords(records, checkListUsers, selectedGroup) {
  const data = JSON.parse(JSON.stringify(records));
  let userExisted = "";

  if (checkListUsers && checkListUsers.length > 0) {
    for (let i = 0; i < checkListUsers.length; i++) {
      const extractTantoId = checkListUsers[i].split("_")[1];
      const extractTantoName = checkListUsers[i].split("_")[2];

      if (isSendGroupExisted(records, extractTantoId, selectedGroup)) {
        userExisted = userExisted
          ? userExisted + "," + extractTantoName + "さん"
          : extractTantoName + "さん";
      } else {
        const preObj = {
          type: "new",
          id: 0,
          tantoId: parseInt(extractTantoId),
          groupName: selectedGroup,
          tantoMasterOri: {
            id: parseInt(extractTantoId),
            name: extractTantoName,
          },
        };

        data.push(preObj);
      }
    }
  }

  return { data: data, userExisted: userExisted };
}

/**
 *
 * @param {*} records
 * @param {*} tantoId
 * @param {*} selectedGroup
 */
function isSendGroupExisted(records, tantoId, selectedGroup) {
  let isExsited = false;

  if (records && records.length > 0) {
    for (let i = 0; i < records.length; i++) {
      if (
        records[i].tantoId.toString() === tantoId.toString() &&
        records[i].groupName === selectedGroup
      ) {
        isExsited = true;
      }
    }
  }

  return isExsited;
}

/**
 *
 * @param {*} records sendgroups records
 * @param {*} checkListGroups checklist user add
 * @param {*} selectedGroup group add TO
 */
function delSendGroupRecords(records, checkListGroups, selectedGroup, delIds) {
  const data = JSON.parse(JSON.stringify(records));

  if (checkListGroups && checkListGroups.length > 0) {
    for (let i = 0; i < checkListGroups.length; i++) {
      const tmpExtractData = checkListGroups[i].split("_");

      const index = data.findIndex(
        (item) =>
          item.tantoId.toString() === tmpExtractData[1] &&
          item.groupName === selectedGroup
      );

      if (index > -1) {
        // alert(index)
        if (tmpExtractData[0] && tmpExtractData[0] !== "0") {
          delIds = delIds
            ? delIds + "," + tmpExtractData[0]
            : tmpExtractData[0];
        }

        data.splice(index, 1);
      }
    }
  }

  return { data: data, delIds: delIds };
}

/**
 *
 * @param {*} users
 * @param {*} group
 */
function filterUserByGroupLocal(users, group) {
  let data = [];

  if (group && group === "全表示") {
    data = users;
  } else if (users && users.length > 0) {
    for (let i = 0; i < users.length; i++) {
      if (users[i].sendGroups && users[i].sendGroups.length > 0) {
        for (let j = 0; j < users[i].sendGroups.length; j++) {
          if (users[i].sendGroups[j].groupName === group) {
            data.push(users[i]);
          }
        }
      }
    }
  }

  return data;
}

function validateNumberAndLetter(str) {
  return str.match("^[A-Za-z0-9]+$");
}

function sortByDate({
  list,
  key,
  format = ConstSet.DATE_FORMAT,
  orderBy = "asc",
}) {
  if (!Array.isArray(list)) return list;
  return _.orderBy(list, (item) => dayjs(item[key]).format(format), [orderBy]);
}

function changeSortTab(value) {
  switch (value) {
    case "利用者":
      return "sort";

    case "バイタル":
      return "sortVaital";

    case "食事摂取":
      return "sortMeal";

    case "水分摂取":
      return "sortSuibun";

    case "排泄":
      return "sortHaisetu";

    case "入浴":
      return "sortNyuyoku";

    case "服薬":
      return "sortFukuyaku";

    case "提供内容":
      return "sortKaigo";

    case "特記":
      return "sortTokki";

    case "画像等":
      return "sortGazou";

    default:
      return "sort";
  }
}

function reportSortOptions(value) {
  switch (value) {
    case "利用者":
      const sortOptions = [];
      return sortOptions;

    case "バイタル":
      const vitalSortOptions = [
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "メイン", key: "mainf" },
        { value: "11", display: "体重", key: "weight" },
        { value: "12", display: "体温", key: "temperature" },
        { value: "13", display: "血圧高", key: "bpressureHigh" },
        { value: "14", display: "血圧低", key: "bpressureLow" },
        { value: "15", display: "脈拍", key: "pulse" },
        { value: "16", display: "呼吸", key: "kokyu" },
        { value: "17", display: "SPO2", key: "spo2" },
        { value: "18", display: "コメント", key: "comment" },
        { value: "19", display: "担当者", key: "tantoName" },
      ];
      return vitalSortOptions;

    case "食事摂取":
      const mealSortOptions = [
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "種類", key: "kubunMeal" },
        { value: "11", display: "主食摂取量", key: "stapleIntake" },
        { value: "12", display: "副食摂取量", key: "sideIntake" },
        { value: "13", display: "有無", key: "shokuNasi" },
        { value: "14", display: "コメント", key: "comment" },
        { value: "15", display: "担当者", key: "tantoName" },
      ];
      return mealSortOptions;

    case "水分摂取":
      const suibunSortOptions = [
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "種類", key: "syurui" },
        { value: "11", display: "量", key: "intake" },
        { value: "12", display: "コメント", key: "comment" },
        { value: "13", display: "担当者", key: "tantoName" },
      ];
      return suibunSortOptions;

    case "排泄":
      const haisetuSortOptions = [
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "排泄区分", key: "haisetuKubun" },
        { value: "11", display: "種類", key: "shurui" },
        { value: "12", display: "有無", key: "haisetuf" },
        { value: "13", display: "量", key: "vol" },
        { value: "14", display: "状態", key: "joutai" },
        { value: "15", display: "コメント", key: "comment" },
        { value: "16", display: "担当者", key: "tantoName" },
      ];
      return haisetuSortOptions;

    case "入浴":
      const nyuyokuSortOptions = [
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "種類", key: "nyuyokuNaiyou" },
        { value: "11", display: "コメント", key: "comment" },
        { value: "12", display: "担当者", key: "tantoName" },
      ];
      return nyuyokuSortOptions;

    case "服薬":
      const fukuyakuSortOptions = [
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "薬名", key: "yakumei" },
        { value: "11", display: "コメント", key: "comment" },
        { value: "12", display: "担当者", key: "tantoName" },
      ];
      return fukuyakuSortOptions;

    case "提供内容":
      const kaigoSortOptions = [
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "提供内容", key: "kaigo_naiyou" },
        { value: "11", display: "コメント", key: "comment" },
        { value: "12", display: "担当者", key: "tantoName" },
      ];
      return kaigoSortOptions;

    case "特記":
      const tokkiSortOptions = [
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "コメント", key: "comment" },
        { value: "11", display: "担当者", key: "tantoName" },
      ];
      return tokkiSortOptions;

    case "画像等":
      const gazouSortOptions = [
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "優先", key: "priorityf" },
        { value: "11", display: "区分", key: "kubun" },
        { value: "12", display: "コメント", key: "comment" },
        { value: "13", display: "担当者", key: "tantoName" },
      ];
      return gazouSortOptions;
  }
}

const rangePicker = {
  compareRangeMonths(startDate, endDate, onMoveToRight) {
    const startMonth = dayjs(startDate).month();
    const endMonth = dayjs(endDate).month();

    if (startMonth === endMonth) {
      onMoveToRight(true);
    }
  },
  showDatesInRightSide(isOpen, canMoveToRight, onMoveToRight) {
    setTimeout(() => {
      if (isOpen && canMoveToRight) {
        const leftRange = document.querySelector(".pljp-calendar-range-left");
        const rightRange = document.querySelector(".pljp-calendar-range-right");

        if (leftRange) {
          const prevMonth = leftRange.querySelector(
            ".pljp-calendar-prev-month-btn"
          );
          if (prevMonth) {
            prevMonth.click();
            onMoveToRight(false);
          }
        }

        if (rightRange) {
          const prevMonth = rightRange.querySelector(
            ".pljp-calendar-prev-month-btn"
          );
          if (prevMonth) {
            prevMonth.click();
          }
        }
      }
    }, 0);
  },
};

function isLife() {
  const pathname = window.location.pathname;
  const paths = pathname.split("/");
  const [, adminPath] = paths;
  const pathPrefix = "life";

  return adminPath === pathPrefix || adminPath.includes(`${pathPrefix}-`);
}

function getMenu() {
  return isLife() ? ConfigName.SWLIFE_MENU : "menu";
}

function getSwpsps() {
  return isLife() ? ConfigName.SWLIFE_SWPSPS : "swpsps";
}

function formatStorage(value) {
  return isLife()
    ? StorageUtils.getValue(value)
    : JSON.parse(localStorage.getItem(value));
}

function checkLogin() {
  if (isLife()) {
    localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
    localStorage.removeItem(ConfigName.SWLIFE_AUTH);
    window.location.replace("/life/login");
    return;
  }
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("doubleLogin");
  localStorage.removeItem("getAvatarResponse");
  localStorage.removeItem("getAvatarResponseData");
  window.location.replace("/login");
}

function getTid() {
  const swpspsKey = getSwpsps();
  const swpsps = formatStorage(swpspsKey);
  return swpsps.tid ? parseInt(swpsps.tid, 10) : null;
}

function getSid() {
  const menuKey = getMenu();
  const menu = formatStorage(menuKey);
  return menu.sid ? parseInt(menu.sid, 10) : null;
}

export {
  addSendGroupRecords, addZeroToDateString, changeSortTab, checkFileStyle, checkHoliday, checkIconUrl, checkLogin, checkTableField, clone, colSetting, convertArrayToString, convertDateToX, convertMessageByCode, convertStringInArr, convertTimeToIndex, convertToParameter, delSendGroupRecords, disPlanToString, exRiyousya, extractKubunSetting, extractRangeVital, extractRiyousyaInfo, filterUserByGroupLocal, formatRecords, formatStorage, gazouKubunExtract, genderStatus, getBase64, getDateInMonth, getDistance, getHolidayInMonth, getMenu, getSid, getSwpsps, getTid, getUrlVars, getUrlVarsNew, getValueIskeep, getValueLocalstorage, handleInputNumber, handleSpo2, isEmpty, isLife, kanriQuerySearch, localStorageClearCus, modifyAfterInsert, openNotificationWithIcon, rangePicker, reportByRiyousyaFormat, reportParams, reportSortOptions, riyouAddSortDel,
  riyouMergeFormToState, setValueIsKeep, setValueLocalstorage, sortByDate, stateYougoMasterName, updateUrlParameter, urlTobase64, validateNumberAndLetter, vitalColSetting, zenkaiMerge
};

