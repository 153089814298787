import { ApiPaths } from "../../constants/api_paths";
import { TaskActionTypes, ErrorActionTypes } from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils } from "../../utils/index";

function getListTask(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: TaskActionTypes.GET_ALL_TASK_REQUEST });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Task.GetListTask,
        query,
      });
      dispatch({
        type: TaskActionTypes.GET_ALL_TASK_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: TaskActionTypes.GET_ALL_TASK_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: TaskActionTypes.REMOVE_TASK_REQUEST });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.Task.Delete,
        query,
      });
      dispatch({ type: TaskActionTypes.REMOVE_TASK_SUCCESS, data });
    } catch (error) {
      dispatch({ type: TaskActionTypes.REMOVE_TASK_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListQuoteTab(riyousyaId) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: TaskActionTypes.GET_LIST_TASK_QUOTE_TAB_REQUEST,
      });
      const query = { riyousyaId, sid };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Task.GetListQuote,
        query,
      });
      dispatch({
        type: TaskActionTypes.GET_LIST_TASK_QUOTE_TAB_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: TaskActionTypes.GET_LIST_TASK_QUOTE_TAB_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getTaskById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: TaskActionTypes.GET_TASK_BY_ID_REQUEST });
      const query = { id };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Task.GetTaskById,
        query,
      });
      dispatch({
        type: TaskActionTypes.GET_TASK_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: TaskActionTypes.GET_TASK_BY_ID_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function updateKadaiSeiri(body) {
  // const menu = StorageUtils.getValue("menuMNGT");
  // const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: TaskActionTypes.UPDATE_KADAI_SEIRI_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.Task.UpdateKadaiSeiri,
        body,
      });
      dispatch({
        type: TaskActionTypes.UPDATE_KADAI_SEIRI_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: TaskActionTypes.UPDATE_KADAI_SEIRI_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getQuoteDate(riyousyaId, id) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: TaskActionTypes.GET_TASK_BY_ID_QUOTE_REQUEST });

      const query = {
        riyousyaId,
        sid,
        id,
        // page,
      };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.Task.GetListTaskQuoteDate,
        query,
      });
      dispatch({
        type: TaskActionTypes.GET_TASK_BY_ID_QUOTE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: TaskActionTypes.GET_TASK_BY_ID_QUOTE_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function updateKadai(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: TaskActionTypes.UPDATE_KADAI_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.Task.UpdateKadai,
        body,
      });
      dispatch({
        type: TaskActionTypes.UPDATE_KADAI_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: TaskActionTypes.UPDATE_KADAI_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getMasterKadai(id) {
  // const menu = StorageUtils.getValue("menuMNGT");
  // const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: TaskActionTypes.GET_MASTER_KADAI_REQUEST });
      const query = { id };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.Task.GetMasterKadai,
        query,
      });
      dispatch({
        type: TaskActionTypes.GET_MASTER_KADAI_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: TaskActionTypes.GET_MASTER_KADAI_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListId(riyousyaId) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: TaskActionTypes.GET_LIST_TASK_ID_REQUEST });
      const query = {
        riyousyaId,
        sid,
      };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.Task.GetKadaiListId,
        query,
      });
      dispatch({
        type: TaskActionTypes.GET_LIST_TASK_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: TaskActionTypes.GET_LIST_TASK_ID_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function newPage() {
  return {
    type: TaskActionTypes.NEW_PAGE,
  };
}

export const TaskActions = {
  getListTask,
  deleteId,
  getListQuoteTab,
  getTaskById,
  updateKadaiSeiri,
  getMasterKadai,
  getQuoteDate,
  updateKadai,
  getListId,
  newPage,
};
