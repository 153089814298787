import { Form } from "@ant-design/compatible";
import { SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Affix, Checkbox, Col, DatePicker, Input, Modal, Row, Select, Spin, TimePicker } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import SubHeaderTitleV2 from "../../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../../common/component/SubHeaderV2";
import TantoInput from "../../../common/component/control/TantoInput";
import {
  formatStorage,
  getSwpsps,
} from "../../../common/function_common/functionCommon";
import datePickerJp from "../../../data/datePickerJp";

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleConfirm: false,
    };
  }

  handleSave = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      this.props.regData(err, values);
    });
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.props.form.isFieldsTouched()) this.showGoBackConfirm();
    else this.props.history.goBack();
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.goBack();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      (<div>
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return this.renderHeader();
            }
            return this.renderHeaderMobile();
          }}
        </MediaQuery>
        <div style={{ maxWidth: "920px", margin: "20px auto" }}>
          <Spin spinning={this.props.loading}>
            <Form
              layout="inline"
              hideRequiredMark={true}
              style={{ padding: "0 10px" }}
            >
              <Row>
                <Col
                  xs={{ span: 6 }}
                  sm={{ span: 2 }}
                  style={{ paddingTop: "7px" }}
                >
                  <span style={{ fontWeight: "bolder", fontSize: "1.1em" }}>
                    {"利用者"}
                  </span>
                </Col>
                <Col
                  xs={{ span: 18 }}
                  sm={{ span: 6 }}
                  style={{ paddingTop: "4px" }}
                >
                  <span style={{ fontSize: "1.4em" }}>
                    {this.props.riyousya ? this.props.riyousya.name : null}
                  </span>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                  <Form.Item label={"日付"} colon={false}>
                    {getFieldDecorator("date", {
                      rules: [
                        {
                          required: true,
                          message: "必須項目をセットして下さい",
                        },
                      ],
                    })(<DatePicker locale={datePickerJp} style={{ width: "150px" }} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 9 }}>
                  <Form.Item label={"時間帯"} colon={false}>
                    {getFieldDecorator("time", {
                      rules: [
                        {
                          required: false,
                          message: "必須項目をセットして下さい",
                        },
                      ],
                    })(<TimePicker showNow={false}
                      needConfirm={false} locale={datePickerJp} format={"HH:mm"} needsConfirmation={false} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ paddingTop: "10px" }}>
                <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                  <Form.Item label={"記録区分"} colon={false}>
                    {getFieldDecorator("kiroku_kubun", {
                      rules: [
                        {
                          required: true,
                          message: "必須項目をセットして下さい",
                        },
                      ],
                    })(
                      <Select style={{ width: "170px" }}>
                        {this.props.kirokuKubuns.map((rel, index) => {
                          return (
                            <Select.Option key={index} value={rel}>
                              {rel}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                  <Form.Item>
                    {getFieldDecorator("tyuif", {
                      rules: [{ required: false }],
                    })(
                      <Checkbox.Group
                        options={[{ label: "申送", value: "tyuif" }]}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("nishif", {
                      rules: [{ required: false }],
                    })(
                      <Checkbox.Group
                        options={[{ label: "日誌", value: "nishif" }]}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 9 }}>
                  <Form.Item label={"担当者"} colon={false}>
                    {getFieldDecorator("tanto_id", {
                      rules: [{ required: false }],
                    })(<TantoInput />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ paddingTop: "10px" }}>
                <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                  <Form.Item label={"その他区分"} colon={false}>
                    {getFieldDecorator("sonota_kubun", {
                      rules: [{ required: false }],
                    })(
                      <Select style={{ width: "170px" }}>
                        {this.props.sonotaKubuns.map((rel, index) => {
                          return (
                            <Select.Option key={index} value={rel}>
                              {rel}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ paddingTop: "10px" }} className="rc-input-form">
                <Form.Item label={"記録内容"} colon={false} style={{ width: "100%" }}>
                  {getFieldDecorator("naiyou", {
                    rules: [{ required: false }],
                  })(<Input.TextArea rows={10} />)}
                </Form.Item>
              </Row>
              <Row style={{ paddingTop: "10px" }} className="rc-input-form">
                <Form.Item label={"コメント"} colon={false}>
                  {getFieldDecorator("naiyou2", {
                    rules: [{ required: false }],
                  })(<Input.TextArea rows={10} />)}
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </div>
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return <div></div>;
            }
            return this.renderFooterMobile();
          }}
        </MediaQuery>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }

  renderHeader() {
    return (
      <Affix offsetTop={0}>
        <div className={"submenu fixed-header"}>
          <SubHeaderV2
            TopLeft={
              <SubHeaderTitleV2 title={"経過記録入力"} iconType="form" />
            }
            TopRight={
              <div>
                <Link
                  to={"#"}
                  onClick={this.handleGoBack}
                  style={{
                    color: "white",
                    textAlign: "center",
                    float: "right",
                    padding: "0px 8px",
                  }}
                >
                  <span style={{ display: "block", fontWeight: "bold", marginBottom: '4px' }}>
                    <UndoOutlined />
                  </span>
                  <span>{"戻る"}</span>
                </Link>
                <Link
                  to={"#"}
                  onClick={this.handleSave}
                  style={{
                    color: "white",
                    textAlign: "center",
                    float: "right",
                    padding: "0px 8px",
                  }}
                >
                  <span
                    style={{
                      color: "red",
                      display: "block",
                      fontWeight: "bold",
                      marginBottom: '4px'
                    }}
                  >
                    <SaveOutlined />
                  </span>
                  <span>{"登録"}</span>
                </Link>
              </div>
            }
          />
        </div>
      </Affix>
    );
  }

  renderHeaderMobile() {
    return (
      <Affix offsetTop={0}>
        <Row className={"submenu fixed-header"}>
          <SubHeaderV2
            TopLeft={
              <SubHeaderTitleV2 title={"経過記録入力"} iconType="form" />
            }
          />
        </Row>
      </Affix>
    );
  }

  renderFooterMobile() {
    return (
      <Affix offsetBottom={0}>
        <Row className={"submenu fixed-footer"}>
          <SubHeaderV2
            TopRight={
              <div>
                <Link
                  to={"#"}
                  onClick={this.handleGoBack}
                  style={{
                    color: "white",
                    textAlign: "center",
                    float: "right",
                    padding: "0px 8px",
                  }}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    <UndoOutlined />
                  </span>
                  <span>{"戻る"}</span>
                </Link>
                <Link
                  to={"#"}
                  onClick={this.handleSave}
                  style={{
                    color: "white",
                    textAlign: "center",
                    float: "right",
                    padding: "0px 8px",
                  }}
                >
                  <span
                    style={{
                      color: "red",
                      display: "block",
                      fontWeight: "bold",
                    }}
                  >
                    <SaveOutlined />
                  </span>
                  <span>{"登録"}</span>
                </Link>
              </div>
            }
          />
        </Row>
      </Affix>
    );
  }
}

export const InputFormWrapper = Form.create({
  name: "input-report-case",
  // onFieldsChange(props, changedFields) {
  //     props.onChange(changedFields);
  // },
  mapPropsToFields(props) {
    let dateTime = new Date();
    if (props.data && props.data.date) {
      dateTime = new Date(props.data.date);
      if (props.data && props.data.time) {
        dateTime.setHours(props.data.time.split(":")[0]);
        dateTime.setMinutes(props.data.time.split(":")[1]);
        dateTime.setSeconds(props.data.time.split(":")[2]);
      }
    }
    return {
      date: Form.createFormField({
        value: dayjs(dateTime),
      }),
      time: Form.createFormField({
        value:
          props.data && props.data.time.substring(0, 5) != "00:00"
            ? dayjs(dateTime, "HH:mm")
            : null,
      }),
      kiroku_kubun: Form.createFormField({
        value: props.data ? props.data.kiroku_kubun : null,
      }),
      sonota_kubun: Form.createFormField({
        value: props.data ? props.data.sonota_kubun : null,
      }),
      tyuif: Form.createFormField({
        value: props.data && props.data.tyuif == 1 ? ["tyuif"] : [],
      }),
      nishif: Form.createFormField({
        value: props.data && props.data.nishif == 1 ? ["nishif"] : [],
      }),
      tanto_id: Form.createFormField({
        value: props.data
          ? props.data.tanto_id
          : formatStorage(getSwpsps()).tid,
      }),
      naiyou: Form.createFormField({
        value: props.data ? props.data.naiyou : null,
      }),
      naiyou2: Form.createFormField({
        value: props.data ? props.data.naiyou2 : null,
      }),
    };
  },
  // onValuesChange(_, values) {

  // },
})(InputForm);
