import axios from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { localStorageClearCus } from "../common/function_common/functionCommon";
import { ConfigName } from "../config-name";
import jsonLocal from "../data/jsonLocal";
import { StorageUtils } from "../management/utils";

const AccountApi = {
  async login(username, password, code, isCheck) {
    // Mark Current for detech check double login. If the same brower, cancel check
    const isDbTs = Date.now().toString();
    StorageUtils.setValue(ConfigName.ISDBTS, isDbTs);

    try {
      const res = await axios(apiUrls.INIT + apiUrls.LOGIN, {
        method: "POST",
        data: {
          username,
          password,
          isDbTs,
          code,
          isCheck,
        },
      });

      // Case display code input
      if (res.data != null && res.data.isCodePage === "true") {
        return res.data;
      }

      // Case login success normal
      localStorageClearCus();
      const token = res.data.token;
      const sessionTimeout = res.data.sessionTimeout;
      const preMenu = JSON.parse(res.data.menu);
      if (preMenu.officeAuth === "0") {
        localStorage.setItem("currentmenu", "record0");
      }

      const menuStr = preMenu.ti1 = preMenu.menu.split(";")[0].split(",");
      preMenu.ti1 = menuStr[0];
      preMenu.ti2 = menuStr[1];
      preMenu.sn = menuStr[2];
      preMenu.si = menuStr[3];

      localStorage.setItem("menu", JSON.stringify(preMenu));
      localStorage.setItem("jwtToken", token); // token
      localStorage.setItem("doubleLogin", res.data.userInit); // Check doubleLogin
      localStorage.setItem(
        "swpsps",
        JSON.stringify({
          started: true,
          menu: JSON.stringify(preMenu),
          tid: jwtDecode(token).userInfo.tidCopy,
          uid: jwtDecode(token).userInfo.id,
          sessionTimeout,
          doubleLogin: res.data.userInit,
          gmk: res.data.gmk,
          mfs: res.data.mfs,
          imaxpi: res.data.imaxpi,
        })
      );

      //
      const isKeep = localStorage.getItem("is_keep");
      if (!isKeep) {
        localStorage.setItem("is_keep", JSON.stringify(jsonLocal));
      }

      // check and save cookies for 2Fa display
      if (isCheck && res?.data?.checkSetting2FAJikan) {
        // maxAge
        const cookies = new Cookies();
        cookies.set("twoFaExpiredCookieK", username, { path: "/", maxAge: res.data.checkSetting2FAJikan * 60 * 60 });
      }
      window.location.reload();
    }
    catch (err) {
      // CLEAR mark Current for detech check double login. If the same brower, cancel check
      StorageUtils.removeValue(ConfigName.ISDBTS);

      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err;
      }
    }
  },

  async mode() {
    return axios(apiUrls.INIT + apiUrls.MAINTAINANCE_MODE, {
      method: "GET",
    })
      .then((res) => {
        const isSystem = res.data.split("///");
        if (isSystem && !isSystem[1].includes("kiroku")) {
          window.location.replace("/error");
        }
        else {
          return isSystem[0];
        }
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        }
        else {
          return err;
        }
      });
  },
};
export default AccountApi;
