import _, { cloneDeep, isEmpty, sum } from "lodash";
import { getHolidayJPInMonth } from "../../common/function_common/functionCommon";
import { handleFilterDuplicateRecordEdit } from "./calculate";
import { RequestUtils } from "../../utils";
import { ApiPaths } from "../../constants";
import { SettingMasterAPI } from "../../api/settingMaster";

const KAIGO_CODE_SPECIAL = ["38", "27", "28", "68", "79"];
const ryoyoShoku = ["療養食朝", "療養食昼", "療養食夕"];
const sougeigenzan = ["片道減算迎", "片道減算送"];
const KAIGO_CODE_SHORT_TYPE = ["21", "22", "23", "38", "27", "28", "68", "79", "2A"];
const kaigoDoName = ["higaito", "yousien", "yousien1", "yousien2", "youkaigo1", "youkaigo2", "youkaigo3", "youkaigo4", "youkaigo5"];
const KAIGO_CODE_ALONE = [ "76", "13"];
const TYPE_MONTHLY = ["夜間訪問介護", "定期巡回", "定期巡回月額"];
const KAIGODOLIST = ["要介護1","要介護2","要介護3","要介護4"];
const RENTAL_TYPE = ["17","67"];
const CODE1 = ["5", "6", "8", "9", "B", "C"];
const CODE2 = ["6", "7"];
const CODE3 = ["処遇改善", "特定処遇改善", "ベースアップ等支援", "サービス提供体制"];
const LIST_SHUIRUI_CODE_QUOTE_WEEKLY = ["11","13","14","15","16","17","21", "22","72","78"];
const SPECIAL_TYPE_FILL = ["月1コード", "口腔栄養スクリーニング"]
const SPECIAL_TANISIKIBETU = ["03","07","08","09","13"];
/**
 * fill data theo nghiệp vụ || fill data based on file design || 算定条件のケース
 * @param {*array selected date} listDate
 * @param {*number number of days in the month} countDate
 * @param {*array selected expande code } expandCode
 * @param {*object submit form} formValues
 * @returns
 */
const fillListData = ({ listDate, countDate, mainCode, formValues, nengetu, listValueDay, isGetDataWeekly, sumListDate }) => {
  if (mainCode?.youbi && isGetDataWeekly) listDate = getDateOfMonth(mainCode.youbi, nengetu, listDate);

  const createArr = listValueDay ?? Array.from(Array(countDate), (e) => 1);
  // if (formValues.additionList.includes("日割り")) listDate = [...createArr];

  const newListDate = sumListDate ?? createArr.map((e, index) => (listDate.includes(index + 1) ? (e === 0 ? 1 : e) : 0));

  const holidayInMonth = checkColor(nengetu);

  if (isGetDataWeekly && formValues.holidayf === 1) {
    holidayInMonth.forEach((i) => {
      newListDate[i - 1] = 0;
    });
  }
  // 迎送通所介護
  let listDateSendAndGo = [];
  let listDateEat = [];
  let listDateSendAndGoSort = [];

  if (isGetDataWeekly) {
    mainCode.additionCodeList.forEach((e) => {
      if (e.serviceFillDataList[0] && e.serviceFillDataList[0].additionTime === "迎送通所介護") {
        let arr = [];
        e.youbiList.forEach((el) => {
          arr.push(
            createArr.map(
              (e, index) => (getDateOfMonthYoubi(el.youbi, nengetu, listDate).includes(index + 1) ? (e === 0 ? 1 : e) : 0) * el.serviceAddition,
            ),
          );
        });
        listDateSendAndGo = sumArrSpecial(arr);
      }
      if (formValues.holidayf === 1) {
        holidayInMonth.forEach((i) => {
          listDateSendAndGo[i - 1] = 0;
        });
      }
    });
    // 養食ショート
    mainCode.additionCodeList.forEach((e) => {
      if (e.serviceFillDataList[0] && e.serviceFillDataList[0].additionTime === "養食ショート") {
        let arr = [];
        e.youbiList.forEach((el) => {
          arr.push(
            createArr.map(
              (e, index) => (getDateOfMonthYoubi(el.youbi, nengetu, listDate).includes(index + 1) ? (e === 0 ? 1 : e) : 0) * el.serviceAddition,
            ),
          );
        });
        listDateEat = sumArrSpecial(arr);
        if (formValues.holidayf === 1) {
          holidayInMonth.forEach((i) => {
            listDateEat[i - 1] = 0;
          });
        }
      }
    });
    mainCode.additionCodeList.forEach((e) => {
      if (e.serviceFillDataList[0] && e.serviceFillDataList[0].additionTime === "迎送ショート") {
        let arr = [];
        e.youbiList.forEach((el) => {
          arr.push(
            createArr.map(
              (e, index) => (getDateOfMonthYoubi(el.youbi, nengetu, listDate).includes(index + 1) ? (e === 0 ? 1 : e) : 0) * el.serviceAddition,
            ),
          );
        });
        listDateSendAndGoSort = sumArrSpecial(arr);
        if (formValues.holidayf === 1) {
          holidayInMonth.forEach((i) => {
            listDateSendAndGoSort[i - 1] = 0;
          });
        }
      }
    });
  }

  // []
  const listNormal = [...newListDate];

  // 未セット
  const listNotSet = newListDate.map((e) => 0);

  // 初日
  const firstday = newListDate.findIndex((e) => e != 0);
  // const listFirstDay = newListDate.map((e, index) => (index == firstday ? e : 0));
  const listFirstDay = newListDate.map((e, index) => (index == firstday ? 1 : 0));
  // 初日,2日目
  const secondDay = newListDate.findIndex((e, index) => e != 0 && index != firstday);

  const listFirstAndSecond = newListDate.map((e, index) => (index == firstday || index == secondDay ? e : 0));
  //全日
  const listAllDay = newListDate.map((e) => (e === 0 ? 1 : e));

  //迎送通所介護
  const nurseAidWelcome = newListDate.map((i) => (i != 0 ? 1 : 0));

  const nurseAidSend = newListDate.map((i) => (i != 0 ? 2 : 0));

  // 21迎送
  // 迎
  const listShortWelcome = fillShortValue(newListDate, "welcome");
  // 送
  const listShortSend = fillShortValue(newListDate, "send");
  // 迎送
  const listShortWelcomAndSend = fillShortValue(newListDate, "welcomeAndSend");

  //主コード回数 || main
  const listValueMain = fillMainValue(newListDate, mainCode, formValues);
  //30日
  const list30DaysLimit = newListDate.length > 30 ? [...newListDate].fill(0, 30, 31) : newListDate;
  return {
    listDateSendAndGo,
    listDateEat,
    createArr,
    listNormal,
    listNotSet,
    listFirstDay,
    listFirstAndSecond,
    listShortWelcome,
    listShortWelcomAndSend,
    listDateSendAndGoSort,
    listShortSend,
    listValueMain,
    nurseAidWelcome,
    nurseAidSend,
    listAllDay,
    list30DaysLimit,
    listThirtyDay: listAllDay,
  };
};
const fillShortValue = (newListDate, type) => {
  const listFirstLastSingleDay = firstLastSingleDay(newListDate);
  let result;
  switch (type) {
    case "welcome":
      result = listFirstLastSingleDay.welcome;
      break;
    case "send":
      result = listFirstLastSingleDay.send;
      break;
    case "welcomeAndSend":
      result = listFirstLastSingleDay.welcomeAndSendCompare;
      break;
  }
  return result;
};

const firstLastSingleDay = (listDate) => {
  const newListDate = [...listDate];
  newListDate.push(0);
  newListDate.unshift(0);

  const welcome = newListDate.map((e, index) => (e != 0 && newListDate[index - 1] == 0 ? 1 : 0)).splice(1, listDate.length);

  const send = newListDate.map((e, index) => (e != 0 && newListDate[index + 1] == 0 ? 1 : 0)).splice(1, listDate.length);

  const welcomeAndSendCompare = welcome.map((value, index) => value + send[index]);

  const welcomeAndSend = newListDate.map((e, index) => (e != 0 && (newListDate[index + 1] == 0 || newListDate[index - 1] == 0) ? 1 : 0));
  const newWelcomeAndSend = welcomeAndSend
    .map((e, index) => (newListDate[index + 1] == 0 && newListDate[index - 1] == 0 && e != 0 ? 1 : e))
    .splice(1, listDate.length);
  const newArrayWelcomeAndSend = newWelcomeAndSend.map((element, index, array) => {
    if (index === 0 && element === 1 && array[index + 1] === 1) {
      return 0;
    } else if (index === array.length - 1 && element === 1 && array[index - 1] === 1) {
      return 0;
    } else {
      return element;
    }
  });
  return {
    welcome,
    send,
    welcomeAndSend: newArrayWelcomeAndSend,
    welcomeAndSendCompare
  };
};

const offerTimeFilter = (formValues, listColorMaster) => {
  let offerTimeFilter = formValues.startTimeStr + "~" + formValues.endTimeStr;

  if (KAIGO_CODE_SPECIAL.includes(formValues.shuiruiCode) || RENTAL_TYPE.includes(formValues?.shuiruiCode) || RENTAL_TYPE.includes(formValues?.shuruiCode)) {
    offerTimeFilter = "";
  }

  //special type: 76,71
  if (formValues.shuiruiCode === "76" && formValues.serviceContent.includes('定期巡回随時I') || (formValues.shuiruiCode == "71" && ["夜間訪問介護I", "夜間訪問介護Ⅱ"].includes(formValues.serviceContent))) {
    listColorMaster.forEach((item) => {
      if (item.paletteName === formValues.serviceCodeType) {
        return (offerTimeFilter = item.paletteName.slice(0, 4));
      }
    });
  }

  //special type: 21
  if (formValues.serviceCodeType === "ショート" || formValues.serviceContent === "ショート" ) {
    listColorMaster.forEach((item) => {
      if ((item.paletteName === formValues.serviceCodeType || item.paletteName === formValues.serviceContent) && !formValues.is30DayOver) offerTimeFilter = item.ryaku;
    });
    if (formValues.is30DayOver) {
      offerTimeFilter = "30日超";
    }
  }
  return offerTimeFilter;
};

function listFirstLastDay(listDay) {
  const filterArr = [[]];
  let count = 0;
  const newListDay = listDay.filter((item, index) => item != 0 || index != 0);
  const newListDateNumber = newListDay.map((i) => parseInt(i));
  const sortListDay = newListDateNumber.sort((a, b) => a - b);
  sortListDay.slice(1).forEach((n, i) => {
    if (n - sortListDay[i] == 1) {
      filterArr[count].push(sortListDay[i], n);
    } else {
      filterArr.push([]);
      count++;
    }
  });

  const consecutiveFilterArr = filterArr.map((item) => {
    return [...new Set([...item])];
  });

  const listSingleDay = sortListDay.filter((item, index) => {
    if (item + 1 != sortListDay[index + 1] && item - 1 != sortListDay[index - 1]) return item;
  });
  const listFirstDay = [];
  const listLastDay = [];
  consecutiveFilterArr.forEach((item) => {
    item.length && listFirstDay.push(item[0]);
    item.length && listLastDay.push(item[item.length - 1]);
  });
  return {
    listFirstDay,
    listLastDay,
    listSingleDay,
  };
}

function checkColor(nengetu) {
  let listHoliday = nengetu
    ? getHolidayJPInMonth(parseInt(nengetu.substring(4)), nengetu.substring(0, 4))
    : getHolidayJPInMonth(new Date().getMonth() + 1, new Date().getFullYear());
  return [...listHoliday];
}

const fillValueFollowMainCode = (objectChangeList, item, listSelected) => {
  let result = [...objectChangeList];
  if (item.record.fillAccording && item.isChangeParent) {
    switch (item.record.fillAccording) {
      case "emty":
        result[item.col] = item.value == 0 ? item.value : 1;
        break;
      case "未セット":
        if (item.value == 0 && item.isParent === false && !item.record.listPlan.includes(1)) result[item.col] = item.value;
        break;

      case "主コード回数":
        result[item.col] = listSelected.find((e) => e.record.isParent && e.record.parentId == item.record.parentId).value;
        break;

      case "フラグチェック":
        let count = 0;
        objectChangeList.forEach((e) => {
          if (e != 0) count++;
        });
        if (item.record.additionTime == "初日" && count == 0) result[item.col] = 1;
        if (item.record.additionTime == "初日,2日目" && count < 2) result[item.col] = 1;
        if (item.value == 0) result[item.col] = item.value;
        break;
      default:
        break;
    }
  } else {
    result[item.col] = item.value;
  }
  return result;
};
// listValueDay, mainCode, true, false, element, serviceCodeMain
const sumPlan = (listValueDay, mainCode, isUtilisation, isMain, expandCode, serviceCodeMain, isPlan, isCheckKaigo = 0) => {
  let sumMainListPlan = sum(listValueDay);

  if (isMain) {
    if (isUtilisation) {
      return sumMainListPlan;
      // if (mainCode.serviceFillDataList[0]?.numberOfTimeUsed === "空") sumMainListPlan = null;
    } else {
      if (mainCode.serviceFillDataList[0]?.numberOfCalculations === "1") sumMainListPlan = 1;
    }
  } else {
    //利用回数
    if (isUtilisation) {
      if (!expandCode?.additionListMain?.includes("日割り")) {
        if (expandCode.serviceFillDataList[0]?.numberOfTimeUsed === "空") {
          if (isPlan) {
            if (isCheckKaigo) {
              return sumMainListPlan;
            } else {
              sumMainListPlan =
                (serviceCodeMain && serviceCodeMain.sumListFPlan > 0) || (serviceCodeMain && serviceCodeMain.sumListFActual > 0) ? 1 : null;
            }
          } else {
            sumMainListPlan = sumMainListPlan = serviceCodeMain
              ? serviceCodeMain.sumListFPlan > 0 || serviceCodeMain.sumListFActual > 0
                ? 1
                : 0
              : "";
          }
        }
      } else return sumMainListPlan;

      //算定回数
    } else {
      switch (expandCode.serviceFillDataList[0]?.numberOfCalculations) {
        case "1":
          sumMainListPlan = serviceCodeMain ? (serviceCodeMain.sumListPlan > 0 || serviceCodeMain.sumListActual > 0 ? 1 : 0) : 1;
          break;

        case "kaisu_nisu":
          let count = 0;
          listValueDay.forEach((e) => {
            if (e != 0) count++;
          });
          sumMainListPlan = count > 30 ? 30 : count;
          break;
      }
    }
  }

  return sumMainListPlan;
};

/**
 *
 * @param {*} fillData
 * @param {*} element
 * @returns
 */
const fillListValueDay = (fillData, element, formValues, isEdit, isGetDataWeekly, isPlan) => {
  let result = fillData.listNormal;
  if (!isEdit && !element.serviceFillDataList.length) return result;

  const compareValue = isEdit ? element.additionTime : element.serviceFillDataList[0].additionTime;

  const isServiceOfMonth = !!(
    element?.serviceName.includes("サービス") &&
    ((element?.serviceFillDataList[0]?.code.includes("C003=03") && element?.serviceFillDataList[0]?.code.includes("C006=03")) ||
      element?.serviceFillDataList[0]?.code.includes("C006=02"))
  );

  switch (compareValue) {
    case "emty":
      result = fillData.listNormal;
      break;

    case "未セット":
      result = isEdit && element.serviceFillDataList[0]?.type === "日" ? (isPlan ? element.listPlan : element.listActual) : fillData.listNotSet;
      break;

    case "初日":
      result = isServiceOfMonth ? fillData.listNotSet : fillData.listFirstDay;
      break;

    case "初日,2日目":
      result = fillData.listFirstAndSecond;
      break;

    case "主コード回数":
      if (element.serviceTeikyoKyoka) {
        return (result = fillData.listValueMain);
      }
      if (element.serviceCode === "145010") {
        return (result = fillData.listValueMain);
      }
      result = element.serviceTeikyoKyoka ? fillData.listValueMain : fillData.listNormal;
      break;

    case "迎送通所介護":
      if (isGetDataWeekly) {
        result = fillData.listDateSendAndGo;
      } else {
        let countSendAndGo = 0;
        formValues.additionList.forEach((i) => {
          if (sougeigenzan.includes(i)) countSendAndGo++;
        });
        result = fillData.listNormal.map((i) => (i !== 0 ? countSendAndGo : 0));
      }
      break;

    case "迎送ショート":
      if (isGetDataWeekly) {
        result = fillData.listDateSendAndGoSort;
      } else {
        if (element.serviceFillDataList.length >= 2) result = fillData.listShortWelcomAndSend;
        else result = element.serviceFillDataList[0].additionName === "迎加算" ? fillData.listShortWelcome : fillData.listShortSend;
      }
      break;

    case "養食ショート":
      if (isGetDataWeekly) {
        result = fillData.listDateEat;
      } else {
        let count = 0;
        formValues.additionList.forEach((i) => {
          if (ryoyoShoku.includes(i)) count++;
        });
        result = fillData.listNormal.map((i) => (i !== 0 ? count : 0));
      }
      break;

    // case "全日":
    //   result = fillData.listThirtyDay;
    //   break;
    case "30日":
      result = fillData.list30DaysLimit;
      break;
  }
  return result;
};

/**
  *@type 13 
  *@全ての算定日に
  *@PTOT20の場合に1を立てる
  *@PTOT40の場合に2を立てる
  *@PTOT60の場合に3を立てる
  *@PTOT80の場合に3を立てる
  *@PTOT100の場合に3を立てる
  *@PTOT120の場合に3を立てる

  *@type 14
  *@全ての算定日に
  *@訪問リハ場合に1を立てる
  *@訪問リハ2の場合に2を立てる
  *@訪問リハ3の場合に3を立てる
  *@訪問リハ4の場合に3を立てる
  *@訪問リハ5の場合に3を立てる
*/
const fillMainValue = (newListDate, mainCode, formValues) => {
  let result = [...newListDate];
  const fill2Value = ["PTOT40", "訪問リハ2"];
  const fill3Value = ["PTOT60", "PTOT80", "PTOT100", "訪問リハ3", "訪問リハ4", "訪問リハ5"];

  if (fill2Value.includes(formValues.serviceContent)) result = newListDate.map((e) => (e == 1 ? 2 : 0));
  if (fill3Value.includes(formValues.serviceContent)) result = newListDate.map((e) => (e == 1 ? 3 : 0));

  switch (mainCode.serviceFillDataList[0]?.additionName) {
    case "定期巡回":
      result = newListDate.map((e) => 0);
      break;
    case "夜間訪問介護":
      result = newListDate.map((e) => 0);
      break;
    case "療養通所介護月額":
      //中本さんに確認する
      break;
    case "定期巡回月額":
      result = newListDate.map((e) => 0);
      break;
    // case "日割り":
    //   result = newListDate.map((e) => 1);
    //   break;
  }
  //rental-レンタ
  if (mainCode.shuruiCode == "17") result = newListDate.map((e) => 0);

  return result;
};

function getDateOfMonth(youbi, nengetu, listDate) {
  youbi = youbi.map((e) => {
    return e - 1;
  });
  const dateMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let DaysOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const listdays = [];
  while (DaysOfMonth.getMonth() === dateMonth.getMonth()) {
    if (youbi.includes(DaysOfMonth.getDay())) {
      listdays.push(DaysOfMonth.getDate());
    }

    DaysOfMonth.setDate(DaysOfMonth.getDate() + 1);
  }

  const listDateWeekly = [];
  listDate.forEach((e) => listdays.includes(e) && listDateWeekly.push(e));

  return listdays.includes(listDateWeekly) ? listdays : listDateWeekly;
}

function sumArrSpecial(arr) {
  const result = [];

  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      if (!result[j]) {
        result[j] = arr[i][j];
      } else {
        result[j] += arr[i][j];
      }
    }
  }
  return result;
}

function getDateOfMonthYoubi(youbi, nengetu, listDate) {
  youbi = youbi - 1;
  const dateMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let DaysOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const listdays = [];
  while (DaysOfMonth.getMonth() === dateMonth.getMonth()) {
    if (youbi === DaysOfMonth.getDay()) {
      listdays.push(DaysOfMonth.getDate());
    }

    DaysOfMonth.setDate(DaysOfMonth.getDate() + 1);
  }

  const listDateWeekly = [];
  listDate.forEach((e) => listdays.includes(e) && listDateWeekly.push(e));

  return listdays.includes(listDateWeekly) ? listdays : listDateWeekly;
}

const generateUuid = () => {
  let id = "";
  const length = 7;

  for (let i = 0; i < length; i++) {
    id += Math.floor(Math.random() * 10);
  }

  return id;
};

const createListExpandCode = ({
  expandCode,
  formValues,
  mainCode,
  fillData,
  selectedRow,
  parentId,
  listDataChildrenFilter,
  isCheckAddRecord,
  isGetDataWeekly,
  isPlan,
  props,
  expandCodeOfItem,
  serviceCodeMain,
  isFromApi = false,
  isTekiyou,
  isClickWeekly = false
}) => {
  let expandCodeNotParentAdd = [];
  let expandCodeAdd = [];
  let expandCodeOtherParent = [];
  const conditionModePlan = sum(serviceCodeMain.listPlan) > 0;
  const conditionModeActual = sum(serviceCodeMain.listActual) > 0;
  const calculateSericeExpand = (element, listValueDay) => {
    return {
      serviceKikanMasterId: formValues.sid ?? mainCode.serviceKikanMasterId,
      serviceName: element.serviceName,
      jigyousyoId: formValues.jigyousyoId ?? formValues.sid ?? mainCode.serviceKikanMasterId,
      officeName: formValues.ryakuServiceKikan,
      listPlan:
        isFromApi || isTekiyou
          ? element.listPlan
          : isPlan
          ? isCheckAddRecord && element.listPlan
            ? element.listPlan
            :  listValueDay
          : conditionModePlan ? listValueDay : fillData.listNotSet,
      listActual: isFromApi || isTekiyou
      ? element.listActual
      : !isPlan
      ? isCheckAddRecord && element.listPlan
        ? element.listActual
        :  listValueDay
      : conditionModeActual ? listValueDay : fillData.listNotSet,
      sumListPlan: isFromApi
        ? (element.shuruiCode === "17" || element.shuruiCode === "71" || element.shuruiCode === "76") &&
          element.count > 0 &&
          !isCheckGrayChildRecord(element)
          ? element.count
          : (element.shuruiCode === "17" || element.shuruiCode === "71" || element.shuruiCode === "76") &&
            element.count === 0 &&
            !isCheckGrayChildRecord(element)
          ? null
          : element.count && !isCheckGrayChildRecord(element)
          ? element.count
          : null
        : element.count && !isCheckGrayChildRecord(element)
        ? element.count
        : handleSumListPlan({ isPlan, isCheckAddRecord, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }),
      sumListFPlan: isFromApi
        ? element.santeiCount
        : handleSumListFPlan({ isPlan, isCheckAddRecord, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain, conditionModePlan }),
      sumListActual: isFromApi
        ? (element.shuruiCode === "17" || element.shuruiCode === "71" || element.shuruiCode === "76") &&
          element.countActual > 0 &&
          !isCheckGrayChildRecord(element)
          ? element.countActual
          : (element.shuruiCode === "17" || element.shuruiCode === "71" || element.shuruiCode === "76") &&
            element.countActual === 0 &&
            !isCheckGrayChildRecord(element)
          ? null
          : element.countActual && !isCheckGrayChildRecord(element)
          ? element.countActual
          : (element.shuruiCode === "17" || element.shuruiCode === "71" || element.shuruiCode === "76") &&
            element.countActual === 0 &&
            !isCheckGrayChildRecord(element)
          ? null
          : isCheckGrayChildRecord(element)
          ? null
          : element.countActual
        : element.countActual && !isCheckGrayChildRecord(element)
        ? element.countActual
        : handleSumListActual({ isPlan, isCheckAddRecord, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }),
      sumListFActual: isFromApi
        ? element.santeiCountActual
        : handleSumListFActual({ isPlan, isCheckAddRecord, element, listValueDay, mainCode, serviceCodeMain, conditionModeActual }),
      parentId: mainCode.parentId ? mainCode.parentId : selectedRow?.parentId ?? "" + parentId,
      komokuCode: element.komokuCode,
      sikyugendogakuKubun: element.sikyugendogakuKubun,
      taniSikibetu: element.taniSikibetu,
      santeiTani: element.santeiTani,
      serviceTeikyoKyoka: element.serviceTeikyoKyoka,
      sisetukubun: element.sisetukubun,
      terminal: element.terminal,
      kikanJiki: element.kikanJiki,
      jikanEntyou: element.jikanEntyou,
      kaisuNisu: element.kaisuNisu,
      sogei: element.sogei,
      ryoyoshoku: element.ryoyoshoku,
      serviceRiyoJoken: element.serviceRiyoJoken,
      hiwariSikibetu: element.hiwariSikibetu,
      idService: element.idService ? element.idService : element.id,
      id: element.id + "" + parentId,
      isParent: false,
      serviceCode: element.serviceCode,
      dataRow: formValues,
      isShow: true,
      mainCode: {...mainCode, listPlan: serviceCodeMain.listPlan, listActual: serviceCodeMain.listActual },
      planf: element.planf,
      tani: element.tani,
      kyufukanriTani: element.kyufukanriTani,
      serviceFillDataList: element.serviceFillDataList,
      fillAccording: element.serviceFillDataList[0]?.fillAccording ?? "emty",
      additionTime: element.serviceFillDataList[0]?.additionTime ?? "emty",
      serviceAddition: element.serviceAddition,
      discount: mainCode.discount,
      price: mainCode.price,
      doitutatemonoSantei: element.doitutatemonoSantei,
      goseiSikbetuKubun: element.goseiSikbetuKubun,
      tuho24: element.tuho24,
      rihaTeikyo: element.rihaTeikyo,
      kyoseigataSantei: element.kyoseigataSantei,
      tokuteiShogukaizen: element.tokuteiShogukaizen,
      rihamane: element.rihamane,
      shogukaizen: element.shogukaizen,
      nintishoSenmoncare: element.nintishoSenmoncare,
      listDataChildrenFilter,
      is30DayOver: isCheckAddRecord ? element.is30DayOver : formValues.is30DayOver,
      parentIds: element.parentIds,
      seikatusoudaninHaiti: element.seikatusoudaninHaiti,
      serviceRiyoJoken: element.serviceRiyoJoken,
      jikanEntyou: element.jikanEntyou,
      gaibuserviceShuruicode: element.gaibuserviceShuruicode,
      higaito: element.higaito,
      yousien: element.yousien,
      yousien1: element.yousien1,
      youkaigo1: element.youkaigo1,
      youkaigo2: element.youkaigo2,
      youkaigo3: element.youkaigo3,
      youkaigo4: element.youkaigo4,
      youkaigo5: element.youkaigo5,
      conditionType76:
        element.serviceCode.substring(0, 2) === "76"
          ? "shuruicode:" + element.serviceCode.substring(0, 2) + ",serviceRiyoJoken:" + element.serviceRiyoJoken
          : null,
          listPlanClone: element.listPlan,
          listActualClone: element.listActual,
          youbiList: element.youbiList,
          checkAddition: element.checkAddition,
          isQuoteActual: formValues?.isQuoteActual,
          existedEl: false
    };
  };
  if (expandCode.length) {
    expandCode.forEach((element) => {
      if (element.serviceCode) {
        const listValueDay = fillListValueDay(fillData, element, formValues, false, isGetDataWeekly);
        const serviceExpand = calculateSericeExpand(element, listValueDay);
        isCheckGrayChildRecord(element) ? expandCodeNotParentAdd.push(serviceExpand) : expandCodeAdd.push(serviceExpand);
      }
    });
  }
  if (expandCodeOfItem?.length) {
    expandCodeOfItem.forEach((element) => {
      if (element.serviceCode) {
        const listValueDay = fillListValueDay(fillData, element, formValues, false, isGetDataWeekly);
        const serviceExpand = calculateSericeExpand(element, listValueDay);
        expandCodeOtherParent.push(serviceExpand);
      }
    });
  }

  return { expandCodeNotParentAdd, expandCodeAdd, expandCodeOtherParent };
};

function calListDataShow({ dataService, officeName, changeSort, changeFilter, isClickWeekly = false }) {
  const listNotParentCurrent = [];
  const dataServiceFilter = [];

  if (dataService && dataService.length) {
    dataService.forEach((e) => {
      if (isCheckGrayChildRecord(e)) listNotParentCurrent.push(e);
      else dataServiceFilter.push(e);
    });

    const dataShow = Object.values(_.groupBy(dataServiceFilter, "parentId"));

    const dataShowNew = _.sortBy(dataShow, (e) => {
      let result;
      if (changeSort) {
        if (["", "ショート", "３０日超"].includes(e[0].offerTime)) result = [e[0].dataRow?.shuiruiCode, e[0].officeName, e[0].serviceCode];
        else result = [e[0].offerTime, e[0].dataRow?.shuiruiCode, e[0].officeName, e[0].serviceCode];
      } else {
        if (["", "ショート", "３０日超"].includes(e[0].offerTime)) result = [e[0].dataRow.furigana, e[0].offerTime];
        else result = [e[0].dataRow.furigana, e[0].offerTime];
      }
      return result;
    });

    let listDataNotParentNew = [];
    if (!changeFilter) {
      listDataNotParentNew = setFilter(
        officeName ? listNotParentCurrent.filter((x) => x.officeName === officeName) : listNotParentCurrent,
        changeFilter,
      );
    }
    listDataNotParentNew = sortNoParent(listDataNotParentNew, changeSort);
    dataShowNew.push(listDataNotParentNew);
    return dataShowNew.filter((x) => x.length != 0);
  }
}
function sortNoParent(listCode, changeSort) {
  let noSid = [];
  let hasSid = [];
  listCode.forEach((e) => {
    e.dataRow.sid ? hasSid.push(e) : noSid.push(e);
  });
  if (changeSort) {
    hasSid = _.sortBy(hasSid, ["dataRow.sid", "serviceCode"]);
  } else {
    hasSid = _.sortBy(hasSid, ["dataRow.furigana", "serviceCode"]);
  }
  noSid = _.sortBy(noSid, ["serviceCode"]);
  listCode = [...hasSid, ...noSid];
  return listCode;
}
function setFilter(listDataFilter, changeFilter) {
  listDataFilter.forEach((e) => {
    if (changeFilter === 0) {
      e.isShow = true;
    } else if (changeFilter === 1) {
      e.isShow = ["03", "07", "08", "09", "13"].includes(e.taniSikibetu) ? false : true;
    } else {
      if (e.isParent === false || ["03", "07", "08", "09", "13"].includes(e.taniSikibetu)) {
        e.isShow = false;
      }
    }
  });
  return listDataFilter;
}
const checkAndRefillBaseOnType21 = ({ sortData, serviceCodeMain, isPlan, isKeepFirst, isKeepLast, isEditCalender, isReCreated, listDataExisted }) => {
  const listValueDay = isPlan ? [...serviceCodeMain.listPlan] : [...serviceCodeMain.listActual];
  const listValueDayIndex = listValueDay.map((e, index) => (e != 0 ? index : 0));

  const listFirstLastSingleDay = listFirstLastDay(listValueDayIndex);

  let result = sortData;

  const indexServiceCodeMain = sortData.findIndex((e) => KAIGO_CODE_SHORT_TYPE.includes(e.dataRow.shuiruiCode) && e.id === serviceCodeMain.id);
 
  if (isKeepFirst) listFirstLastSingleDay.listFirstDay.forEach((i) => (listValueDay[i] = 0));

  if (isKeepLast) listFirstLastSingleDay.listLastDay.forEach((i) => (listValueDay[i] = 0));

  if (isKeepFirst || isKeepLast) listFirstLastSingleDay.listSingleDay.forEach((i) => (listValueDay[i] = 0));

  if (indexServiceCodeMain != -1) {
    const mapParentIds = sortData
    .map((e, index) => {
      if (e.parentIds && e.parentIds.includes(sortData[indexServiceCodeMain]?.parentId)) {
        return index;
      }
      if (e.parentId && e?.parentId?.includes(sortData[indexServiceCodeMain]?.parentId)) {
        return index;
      }
    })
    .filter((el) => el);
    result = sortData.map((element, index) => { 
      if (index < indexServiceCodeMain) {
        listValueDay.forEach((e, idx) => {
          if (e !== 0) {
            if (isPlan) {
              if(isCheckMonthlyType21({element}) && element.listPlan[idx] > 0){
                element.listPlan[idx + 1] = 1;
              }
              if (isCheckComeAndGo({ element }) && isReCreated && sortData[indexServiceCodeMain].officeName.includes(element.officeName)) {
                const listDayPlan = element.mainCode.listPlan;

                if(idx > 0 && listDayPlan[idx] > 0){
                  element.listPlan[idx - 1] = 1;
                }else{
                  if(idx - 1 == 0){
                    element.listPlan[idx - 1] = 2;
                  }else{
                    element.listPlan[idx - 1] = 1;
                  }
                  if (idx + 1 < listDayPlan.length && listDayPlan[idx + 1] > 0) {
                    element.listPlan[idx + 1] = listDayPlan[idx] == 0 && listDayPlan[idx + 2] == 0 ? 2 : 1;
                  }
                }
                element.listPlan[idx] = 0;
              }
              else if (isCheckComeOrGo({ element }) && isReCreated && sortData[indexServiceCodeMain].officeName.includes(element.officeName)) {
                const listDayPlan = element.mainCode.listPlan;

                if(element.serviceFillDataList[0]?.additionName.includes("送加算")){
                 if(listDayPlan[idx-1] && listDayPlan[idx-1] > 0){
                  element.listPlan[idx-1] = 1;
                  element.listPlan[idx] = 0;
                 }
                }else{
                  if(listDayPlan[idx + 1] && listDayPlan[idx + 1] > 0){
                    element.listPlan[idx + 1] = 1;
                    element.listPlan[idx] = 0;
                  }
                }
              }
              else {
                element.listPlan[idx] = 0;
              }
            } else {
              if(isCheckMonthlyType21({element}) && element.listActual[idx] > 0){
                element.listActual[idx + 1] = 1;
              }
              if (isCheckComeAndGo({ element }) && isReCreated && sortData[indexServiceCodeMain].officeName.includes(element.officeName)) {
                const listDayPlan = element.mainCode.listPlan;

                if(idx > 0 && listDayPlan[idx] > 0){
                  element.listActual[idx - 1] = 1;
                }else{
                  if(idx - 1 == 0){
                    element.listActual[idx - 1] = 2;
                  }else{
                    element.listActual[idx - 1] = 1;
                  }
                  if (idx + 1 < listDayPlan.length && listDayPlan[idx + 1] > 0) {
                    element.listActual[idx + 1] = listDayPlan[idx] == 0 && listDayPlan[idx + 2] == 0 ? 2 : 1;
                  }
                }
                element.listPlan[idx] = 0;
              }
              else if (isCheckComeOrGo({ element }) && isReCreated && sortData[indexServiceCodeMain].officeName.includes(element.officeName)) {
                const listDayActual = element.mainCode.listActual;
                if(element.serviceFillDataList[0]?.additionName.includes("送加算")){
                  if(listDayActual[idx - 1] && listDayActual[idx - 1] > 0){
                    element.listActual[idx - 1] = 1;
                    element.listActual[idx] = 0;
                  }
                }else{
                  if(listDayActual[idx + 1] && listDayActual[idx + 1] > 0){
                    element.listActual[idx + 1] = 1;
                    element.listActual[idx] = 0;
                  }
                }
              }
              else {
                element.listActual[idx] = 0;
              }
            }
        }
        });
      }
      const findSameElement = listDataExisted?.length > 0 && listDataExisted.some((item) => item.officeName === element.officeName && item.serviceName === element.serviceName);

      if (isEditCalender && !mapParentIds.concat(indexServiceCodeMain).includes(index)) {
        listValueDay.forEach((e, idx) => {
          if (e != 0) isPlan ? (element.listPlan[idx] = 0) : (element.listActual[idx] = 0);
        });
      }
      const newElement = handleCheckTypeMonthly(element)
        ? {
            ...element,
            sumListPlan: sum(element.listPlan) > 0 ? sum(element.listPlan) : null,
            sumListFPlan: isPlan ? 1 : null,
            sumListActual: sum(element.listActual) > 0 ? sum(element.listActual) : null,
            sumListFActual: !isPlan ? 1 : null,
          }
        : handleMonthlyType21(element) && indexServiceCodeMain > 0 && mapParentIds.includes(index) && findSameElement ? 
          {
            ...element,
            sumListPlan: sum(element.listPlan) > 0 ? sum(element.listPlan) : null,
            sumListFPlan: sum(element.listPlan) > 0 ? 0 : isPlan ? (handleCheckSpecialType(element) ? 0 : null) : null,
            sumListActual: sum(element.listActual) > 0 ? sum(element.listActual) : null,
            sumListFActual: sum(element.listActual) > 0 ? 0: !isPlan ? (handleCheckSpecialType(element) ? 0 : null) : null,
          }
        : {
            ...element,
            sumListPlan: sum(element.listPlan) > 0 ? sum(element.listPlan) : null,
            sumListFPlan: sum(element.listPlan) > 0 ? sum(element.listPlan) : isPlan ? (handleCheckSpecialType(element) ? 0 : null) : null,
            sumListActual: sum(element.listActual) > 0 ? sum(element.listActual) : null,
            sumListFActual: sum(element.listActual) > 0 ? sum(element.listActual) : !isPlan ? (handleCheckSpecialType(element) ? 0 : null) : null,
          };
      return isCheckGrayChildRecord(element) ? element : newElement;
    });
  }
  return result;
};

const checkAndRefillBaseOnKaigo = ({ sortData, serviceCodeMain, isPlan, isKeepFirst, isKeepLast, changeFilter, changeSort }) => {
  const listValueDayPlan = [...serviceCodeMain.listPlan];
  const listValueDayActual = [...serviceCodeMain.listActual];

  const listValueDayIndex = listValueDayPlan.map((e, index) => (e != 0 ? index : 0));

  const listFirstLastSingleDay = listFirstLastDay(listValueDayIndex);
  const listData = calListDataShow({ dataService: sortData, changeSort, changeFilter });
  let result = listData.flatMap((e) => e);

  const indexServiceCodeMain = sortData.findIndex((e) => kaigoDoName.some((atr) => !!e[atr]) && e.id === serviceCodeMain.id);
  const findDataIsNotChangeByKaigo = sortData.filter((e) => e.isParent && kaigoDoName.every((atr) => !e[atr]));
  const groupIdDataNotChangeByKaigo = sortData.flatMap((e) => {
    if (!e.parentIds) {
      if (findDataIsNotChangeByKaigo.some((el) => e.parentId === el.parentId)) {
        return e.id;
      }
    } else {
      if (findDataIsNotChangeByKaigo.some((el) => e.parentIds.includes(el.parentId))) {
        return e.id;
      }
    }
    return [];
  });

  if (isKeepFirst) listFirstLastSingleDay.listFirstDay.forEach((i) => (listValueDayPlan[i] = 0));

  if (isKeepLast) listFirstLastSingleDay.listLastDay.forEach((i) => (listValueDayPlan[i] = 0));

  if (isKeepFirst || isKeepLast) listFirstLastSingleDay.listSingleDay.forEach((i) => (listValueDayPlan[i] = 0));

  const findParentId = sortData.findIndex(
    (element, index) => element.id !== serviceCodeMain.id && kaigoDoName.some((atr) => !!element[atr]) && index,
  );
  let indexchild = [];
  if (findParentId) {
    if (sortData[findParentId]?.parentId) {
      indexchild = sortData.reduce((indexes, el, idx) => {
        const isMatchingParent =
          (el.parentId && sortData[findParentId].parentId === el.parentId) ||
          (!el.isParent && el.parentIds && el.parentIds.includes(sortData[findParentId].parentId));
        if (isMatchingParent) {
          indexes.push(idx);
        }
        return indexes;
      }, []);
    }
  }
  if (indexServiceCodeMain != -1) {
    result = sortData
      .map((element, index) => {
        if (!groupIdDataNotChangeByKaigo.includes(element.id)) {
          if (indexchild.includes(index)) {
            listValueDayPlan.forEach((e, idx) => {
              if (e != 0) element.listPlan[idx] = 0;
            });
            listValueDayActual.forEach((e, idx) => {
              if (e != 0) element.listActual[idx] = 0;
            });
          }
          if (index < indexServiceCodeMain && serviceCodeMain.dataRow.shuiruiCode.includes(sortData[index].dataRow.shuiruiCode)) {
            listValueDayPlan.forEach((e, idx) => {
              if (e != 0) element.listPlan[idx] = 0;
            });
            listValueDayActual.forEach((e, idx) => {
              if (e != 0) element.listActual[idx] = 0;
            });
          }
        }
        const newElement = !KAIGO_CODE_ALONE.includes(element.dataRow.shuiruiCode)
          ? {
              ...element,
              sumListFPlan: sum(element.listPlan) > 0 ? sum(element.listPlan) : null,
              sumListPlan: sum(element.listPlan) > 0 ? sum(element.listPlan) : null,
              sumListFActual: sum(element.listActual) > 0 ? sum(element.listActual) : null,
              sumListActual: sum(element.listActual) > 0 ? sum(element.listActual) : null,
            }
          : KAIGO_CODE_ALONE.includes(element.dataRow.shuiruiCode) && element.dataRow.additionList.includes("日割り")
          ? {
              ...element,
              sumListFPlan: sum(element.listPlan) > 0 ? sum(element.listPlan) : element.sumListFPlan,
              sumListPlan: sum(element.listPlan) > 0 ? sum(element.listPlan) : element.sumListPlan,
              sumListFActual: sum(element.listActual) > 0 ? sum(element.listActual) : element.sumListFActual,
              sumListActual: sum(element.listActual) > 0 ? sum(element.listActual) : element.sumListActual,
            }
          : {
              ...element,
              sumListPlan: sum(element.listPlan) > 0 ? sum(element.listPlan) : null,
              sumListActual: sum(element.listActual) > 0 ? sum(element.listActual) : null,
            };
        return isCheckGrayChildRecord(element) ? element : newElement;
      })
  }
  result.forEach((element) =>{
    let conditionReCalculator = false;
    if(isCheckComeAndGo({ element })){
      conditionReCalculator = true
      const itemParent = result.find((item) => item.parentId === element.parentId || !!element.parentIds && element.parentIds.includes(item.parentId));

      calculateCheckComeAndGo({listValueDay: itemParent.listPlan, listDay: element.listPlan});
      calculateCheckComeAndGo({listValueDay: itemParent.listActual, listDay: element.listActual});

    }
    if(isCheckComeOrGo({ element })){
      if(element.serviceFillDataList[0]?.additionName.includes("送加算")){
        conditionReCalculator = true;
        const itemParent = result.find((item) => item.parentId === element.parentId || !!element.parentIds && element.parentIds.includes(item.parentId));
        calulateCheckGoElement({listValueDay: itemParent.listPlan, listDay: element.listPlan});
        calulateCheckGoElement({listValueDay: itemParent.listActual, listDay: element.listActual});

      }
    }
    if(conditionReCalculator){
      updateSumValues(element, 'listPlan', 'sumListPlan', 'sumListFPlan');
      updateSumValues(element, 'listActual', 'sumListActual', 'sumListFActual');
    }
  })

  result= result.filter((el) => el.sumListFActual || el.sumListFPlan);
  return result;
};

const calculateCheckComeAndGo = ({ listValueDay, listDay }) => {
  let firstNonZeroIndex = listValueDay.findIndex(element => element !== 0);
  let firstNonZeroIndexNext = firstNonZeroIndex + 1;
  let firstNonZeroIndexPrev = firstNonZeroIndex - 1;
  let lastNonZeroIndex = listValueDay.lastIndexOf(1);
  let lastNonZeroIndexNext = lastNonZeroIndex + 1;
  let lastNonZeroIndexPrev = lastNonZeroIndex - 1;

    if (firstNonZeroIndex !== -1) {
      if(listDay[firstNonZeroIndex] == 2){
        listDay[firstNonZeroIndex] = 2;
      }
      else if(listValueDay[firstNonZeroIndexNext] == 0 && listValueDay[firstNonZeroIndexPrev] == 0 && listDay[firstNonZeroIndex] == 1){
        listDay[firstNonZeroIndex] = 2;
      }
      else{
        listDay[firstNonZeroIndex] = 1;
      }
    }
    
    if (lastNonZeroIndex !== -1) {
      if(listDay[lastNonZeroIndex] == 2){
        listDay[lastNonZeroIndex] = 2;
      }else if(listValueDay[lastNonZeroIndexNext] == 0 && listValueDay[lastNonZeroIndexPrev] == 0 && listDay[lastNonZeroIndex] == 1){
        listDay[lastNonZeroIndex] = 2;
      }
      else{
        listDay[lastNonZeroIndex] = 1;
      }
    }
    listValueDay.forEach((element,index)=>{
      if(!element){
        listDay[index] = 0;
      }
    })
};

const calulateCheckGoElement = ({ listValueDay, listDay }) =>{
  let firstNonZeroIndex = listValueDay.findIndex(element => element !== 0);
  let lastNonZeroIndex = listValueDay.lastIndexOf(1);

  if (firstNonZeroIndex !== -1) {
    listDay[firstNonZeroIndex] = 0;
  }
  
  if (lastNonZeroIndex !== -1) {
      listDay[lastNonZeroIndex] = 1;
  }
}

const updateSumValues = (element, listKey, sumKey, sumFKey) => {
  const list = element[listKey];
  element[sumKey] = sum(list) > 0 ? sum(list) : null;
  element[sumFKey] = element[sumKey];
};

const filterMonthlyExpandCode = ({ formValues, expandCode, props, isClickWeekly = false }) => {
  let newExpandCode = [...expandCode];
  let newExpandCodeChild = [...expandCode];
  let newAdditionList = [...formValues.additionList];
  let listDataChildrenFilter = [];
  let additionByList =
    props?.officeInfo && props?.officeInfo?.additionNameList?.length > 0
      ? newAdditionList.filter((e) => !props.officeInfo?.additionNameList?.includes(e))
      : newAdditionList.filter((e) => !formValues?.additionByList?.includes(e));
  const listMonthlyAddition = props?.riyouhyouForm?.listDataService?.filter((e) => {
    if (e?.serviceAddition && newAdditionList?.includes(e.serviceAddition[0]) && isCheckGrayChildRecord(e) && !e.isParent) {
      listDataChildrenFilter.push(e);
    }
    return e.serviceFillDataList && e.serviceFillDataList[0]?.type === "月" && !e.isParent;
  });

  if(isClickWeekly) {
    newExpandCode = newExpandCode.filter((x) => x.serviceFillDataList && x.serviceFillDataList[0]?.additionName !== "計算コード");
  }else {
    listMonthlyAddition?.forEach((e) => {
      if (e.dataRow.shuiruiCode && e.dataRow.shuiruiCode === formValues.shuiruiCode && e.dataRow.sid === formValues.sid) {
        newExpandCode = newExpandCode.filter((x) => x.serviceFillDataList && x.serviceFillDataList[0]?.additionName !== "計算コード");
        newExpandCodeChild = newExpandCodeChild.filter((x) => {
          return x.serviceName !== e.serviceName;
        });
      }
  });
  }
 
  return { newExpandCode, newExpandCodeChild, newAdditionList, listDataChildrenFilter, additionByList };
};

const sumTowArr = (arr1, arr2) => {
  let result = [];

  for (let i = 0; i < arr1.length; i++) {
    let sum = arr1[i] + arr2[i];
    result.push(sum);
  }
  return result;
};

const isObjectEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
      return false;
  }

  return keys1.every(key => obj1[key] === obj2[key]);
};

const hasDifference = (item, listItem) => {
  const differentFields = ["serviceName", "officeName", "offerTime", "isParent"];
  return differentFields.some((field) => listItem[field] !== item[field]);
};

const handleGroupParentIds = (data, expandCodeAdd = [], expandCodeNotParentAdd = [], isClickWeekly = false, props = {}) => {
  let result = data.reduce((acc, current) => {
    const isDuplicateShuiruiCode = LIST_SHUIRUI_CODE_QUOTE_WEEKLY.includes(current.dataRow.shuiruiCode);
    const isObjectEqual = props?.riyouhyouForm?.listDataService.some((listItem) => {
      return Object.keys(current).every((key) => listItem[key] === current[key]);
    });
    if (!isCheckGrayChildRecord(current)) {
      if (isClickWeekly && isDuplicateShuiruiCode) {
        if (!current.sumListFPlan) {
          if (isObjectEqual) {
            const hasDifferences = props?.riyouhyouForm?.listDataService.some((listItem) => hasDifference(current, listItem));
            if (hasDifferences) {
              acc.push(...props?.riyouhyouForm?.listDataService);
            }
          } else if (!current.isParent && expandCodeAdd.length > 0) {
            const indexDuplicate = expandCodeAdd.findIndex(
              (item) => item.serviceName === current.serviceName && item.serviceCode === current.serviceCode,
            );
            if (indexDuplicate >= 0) {
              acc.push({
                ...expandCodeAdd[indexDuplicate]
              });
            } else {
              acc.push({
                ...current,
              });
            }
          } else {
            acc.push(current);
          }
        } else {
          acc.push(current);
        }
      } else {
        acc.push({
          ...current,
        });
      }
    } else {
      // trường hợp thằng con màu xám
      const matchedIndex = acc.findIndex((item) => item.serviceCode === current.serviceCode && item.officeName === current.officeName);
      if (matchedIndex < 0) {
        // Trường hợp quoteWeeklyActual và ko có listPlan
        if (isClickWeekly && !current.sumListFPlan && isDuplicateShuiruiCode) {
          if (expandCodeNotParentAdd.length > 0) {
            const indexDuplicate = expandCodeNotParentAdd.findIndex(
              (item) => item.serviceName === current.serviceName && item.serviceCode === current.serviceCode,
            );
            if (indexDuplicate >= 0) {
              acc.push({
                ...expandCodeNotParentAdd[indexDuplicate],
                parentIds: !isEmpty(current.parentIds) ? current.parentIds : [current.parentId],
              });
            }
          }
        }
        // Chưa có bản ghi nào khớp
        // Tạo một bản ghi mới
        else {
          acc.push({
            ...current,
            parentIds: !isEmpty(current.parentIds) ? current.parentIds : [current.parentId],
          });
        }
      } else {
        // Đã có bản ghi khớp
        // Cập nhật mảng parentId của bản ghi đó
        acc[matchedIndex].parentIds = Array.from(new Set([...acc[matchedIndex].parentIds, current.parentId]));
        acc[matchedIndex].sumListFPlan = current.sumListFPlan ? current.sumListFPlan : acc[matchedIndex].sumListFPlan;
        acc[matchedIndex].sumListFActual = current.sumListFActual ? current.sumListFActual : acc[matchedIndex].sumListFActual;
        if (isCheckGrayChildRecord(acc[matchedIndex])) {
          if (isClickWeekly) {
            acc[matchedIndex].sumListFActual =
              acc.some((itemParent) => itemParent.isParent && itemParent.sumListFActual && itemParent.officeName === acc[matchedIndex].officeName) &&
              1;
          }
          acc[matchedIndex].is30DayOver = acc[matchedIndex].is30DayOver ? acc[matchedIndex].is30DayOver : current.is30DayOver;
        }
      }
    }
    return acc;
  }, []);

  return result;
};

const handleDeleteChildSame = ({ data, isCheckSpecialType, dayOfKaigo, nengetu }) => {
  const result = data.reduce((acc, current) => {
    if (isCheckGrayChildRecord(current)) {
      acc.push({
        ...current,
      });
    } else {
      if (current.isParent) {
        acc.push({
          ...current,
        });
      } else {
        if (!isCheckSpecialType) {
          const matchedIndex = acc.findIndex((item) => item.serviceCode === current.serviceCode && item.officeName === current.officeName);
          if (matchedIndex < 0) {
            acc.push({
              ...current,
            });
          }
        } else {
          const matchedIndex = acc.findIndex((item) => item.serviceCode === current.serviceCode && item.officeName === current.officeName);
          if (matchedIndex < 0) {
            acc.push({
              ...current,
            });
          } else {
            if (
              data[matchedIndex] &&
              !KAIGO_CODE_ALONE.includes(data[matchedIndex].dataRow.shuiruiCode) &&
              !data[matchedIndex].serviceFillDataList[0]?.code.includes("C003=03")
            ) {
              acc.push({
                ...current,
              });
            } else if (KAIGO_CODE_ALONE.includes(data[matchedIndex].dataRow.shuiruiCode) && isCheckBaseOnKaigo(data[matchedIndex])) {
              acc.push({
                ...current,
              });
            }
          }
        }
      }
    }
    return acc;
  }, []);
  return result;
};

const handleMonthlyType21 = (el) =>{
  return (
    (!isCheckGrayChildRecord(el) &&
      KAIGO_CODE_SHORT_TYPE.includes(el.dataRow.shuiruiCode) &&
      el.serviceFillDataList &&
      el.serviceFillDataList[0]?.code.includes("C003=03")
    )
  );
}

const handleCheckTypeMonthly = (el) => {
  return (
    (!isCheckGrayChildRecord(el) &&
      el.serviceFillDataList &&
      el.serviceFillDataList[0]?.additionTime.includes("未セット") &&
      el.serviceFillDataList[0]?.code.includes("C003=03")) ||
    el.dataRow.shuiruiCode.includes("17")
  );
};

const handleCheckSpecialType = (el) => {
  return (
    !isCheckGrayChildRecord(el) &&
    el.serviceFillDataList &&
    el.serviceFillDataList[0]?.additionTime.includes("未セット") &&
    el.serviceFillDataList[0]?.code.includes("C098=33")
  );
};

const handleDeleteRecordIsEmptyParentIds = (data) => {
  return data.filter((item) => !isCheckGrayChildRecord(item) || (isCheckGrayChildRecord(item) && !isEmpty(item.parentIds)));
};

const isCheckGrayChildRecord = (item) => {
  return item?.serviceFillDataList && item?.serviceFillDataList[0]?.additionName === "計算コード";
};

const handleMapSumListOfChildGrayByServiceCode = (data) => {
  const dataMap = data.map((item) => {
    if (!isCheckGrayChildRecord(item) || item.isParent) {
      return { ...item };
    }
    const listParentRelations = data.filter((e) => item?.parentIds?.some((element) => element === e?.parentId) && e?.isParent);
    const sumListFPlan = listParentRelations?.find((e) => e.sumListFPlan)?.sumListFPlan;
    const sumListFActual = listParentRelations?.find((e) => e.sumListFActual)?.sumListFActual;
    return {
      ...item,
      sumListFPlan: sumListFPlan ? 1 : item.sumListFPlan,
      sumListFActual: sumListFActual ? 1 : item.sumListFActual,
    };
  });
  return dataMap;
};

const handleSumListPlan = ({ isPlan, isCheckAddRecord, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }) => {
  if (isPlan) {
    if (isCheckAddRecord && !isGetDataWeekly && element && Array.isArray(element.listPlan)) {
      if (isCheckGrayChildRecord(element)) {
        return null;
      }
      if ((element.serviceCode.substring(0, 2) === "76" || element.serviceCode.substring(0, 2) === "71") && sum(element.listPlan) > 0) {
        return sum(element.listPlan);
      }
      if (
        element.serviceCode.substring(0, 2) === "71" ||
        element.serviceCode.substring(0, 2) === "17" ||
        element.serviceCode.substring(0, 2) === "76"
      ) {
        return null;
      }
      return element.santeiCount ? element.santeiCount : sum(element.listPlan);
    }
    if (element && element.serviceCode && element.serviceCode.length >= 2 && mainCode && serviceCodeMain) {
      if ((element.serviceCode.substring(0, 2) === "76" || element.serviceCode.substring(0, 2) === "71") && sum(listValueDay) > 0) {
        return sum(listValueDay);
      }
      if (
        isCheckGrayChildRecord(element) ||
        element.serviceCode.substring(0, 2) === "76" ||
        element.serviceCode.substring(0, 2) === "71" ||
        element.serviceCode.substring(0, 2) === "17"
      ) {
        return null;
      }
      if ((element.serviceCode.substring(0, 2) === "76" || element.serviceCode.substring(0, 2) === "71") && sum(listValueDay) > 0) {
        return sum(listValueDay);
      }
      return sumPlan(listValueDay, mainCode, true, false, element, serviceCodeMain);
    }
    return sumPlan(listValueDay, mainCode, false, false, element, serviceCodeMain);
  }
  return null;
};

const handleSumListFPlan = ({ isPlan, isCheckAddRecord, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }) => {
  if (isPlan) {
    if (isCheckAddRecord && !isGetDataWeekly) {
      if (isCheckGrayChildRecord(element)) {
        return 1;
      }
      return element.santeiCount ? element.santeiCount : sum(element.listPlan);
    }
    return sumPlan(listValueDay, mainCode, true, false, element, serviceCodeMain);
  }
  if (isCheckGrayChildRecord(element)) {
    return sum(serviceCodeMain.listPlan) >= 1 || serviceCodeMain.sumListFPlan > 0 ? 1 : null;
  }
  return sum(element.listPlan) || null;
};

const handleSumListActual = ({ isPlan, isCheckAddRecord, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }) => {
  if (!isPlan) {
    if (isCheckAddRecord && !isGetDataWeekly && element && Array.isArray(element.listActual)) {
      if (isCheckGrayChildRecord(element)) {
        return null;
      }
      if ((element.serviceCode.substring(0, 2) === "76" || element.serviceCode.substring(0, 2) === "71") && sum(element.listActual) > 0) {
        return sum(element.listActual);
      }
      if (
        element.serviceCode.substring(0, 2) === "71" ||
        element.serviceCode.substring(0, 2) === "17" ||
        element.serviceCode.substring(0, 2) === "76"
      ) {
        return null;
      }
      return element.santeiCount ? element.santeiCount : sum(element.listActual);
    }
    if (element && element.serviceCode && element.serviceCode.length >= 2 && mainCode && serviceCodeMain) {
      if ((element.serviceCode.substring(0, 2) === "76" || element.serviceCode.substring(0, 2) === "71") && sum(listValueDay) > 0) {
        return sum(listValueDay);
      }
      if (
        isCheckGrayChildRecord(element) ||
        element.serviceCode.substring(0, 2) === "76" ||
        element.serviceCode.substring(0, 2) === "71" ||
        element.serviceCode.substring(0, 2) === "17"
      ) {
        return null;
      }
      if ((element.serviceCode.substring(0, 2) === "76" || element.serviceCode.substring(0, 2) === "71") && sum(listValueDay) > 0) {
        return sum(listValueDay);
      }
      return sumPlan(listValueDay, mainCode, true, false, element, serviceCodeMain);
    }
    return sumPlan(listValueDay, mainCode, false, false, element, serviceCodeMain);
  }
  return null;
};

const handleSumListFActual = ({ isPlan, isCheckAddRecord, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }) => {
  if (!isPlan) {
    if (isCheckAddRecord && !isGetDataWeekly) {
      if (isCheckGrayChildRecord(element)) {
        return 1;
      }
      return element.santeiCount ? element.santeiCount : sum(element.listActual);
    }
    return sumPlan(listValueDay, mainCode, true, false, element, serviceCodeMain);
  }
  if (isCheckGrayChildRecord(element)) {
    return sum(serviceCodeMain.listActual) >= 1 || serviceCodeMain.sumListFActual > 0 ? 1 : null;
  }
  return sum(element.listActual) || null;
};

const handleSumListPlanCodeMain = ({ isPlan, isCheckAddRecord, element, fillData }) => {
  if (isPlan) {
    if (isCheckAddRecord && !element.isGetDataWeekly) {
      return sum(element.mainCode.listPlan);
    }
    return sumPlan(fillData.listValueMain, element.mainCode, true, true);
  }
  return 0;
};

const handleSumListFPlanCodeMain = ({ isPlan, fillData, element, isCheckAddRecord }) => {
  if (isPlan && sumPlan(fillData.listValueMain, element.mainCode, false, true) > 0) {
    if (isCheckAddRecord && !element.isGetDataWeekly) {
      return sum(element.mainCode.listPlan);
    } else {
      return sumPlan(fillData.listValueMain, element.mainCode, false, true);
    }
  } else if (element.mainCode.shuruiCode === "17" || element.mainCode.shuruiCode === "76" || element.mainCode.shuruiCode === "71") {
    return 1;
  } else {
    return 0;
  }
};

const handleSumListActualCodeMain = ({ isPlan, isCheckAddRecord, element, fillData }) => {
  if (isPlan) {
    return null;
  } else if (isCheckAddRecord) {
    return sum(element?.mainCode?.listPlan);
  } else {
    return sumPlan(fillData.listValueMain, element.mainCode, true, true);
  }
};

const handleSumListFActualCodeMain = ({ isPlan, isCheckAddRecord, element, fillData }) => {
  if (isPlan) {
    return null;
  } else if (isCheckAddRecord) {
    return sum(element.mainCode.listPlan);
  } else {
    return sumPlan(fillData.listValueMain, element.mainCode, false, true);
  }
};

const handleSumListPlanParent = ({ e, isPlan, fillData, isCheckAddRecord }) => {
  if (isPlan && sumPlan(fillData.listValueMain, e.mainCode, false, true) > 0 && e.mainCode.shuruiCode !== "17" && e.mainCode.shuruiCode !== "76") {
    if (isCheckAddRecord && !e.isGetDataWeekly) {
      return sum(e.mainCode.listPlan);
    } else {
      return sumPlan(fillData.listValueMain, e.mainCode, false, true);
    }
  } else if (e.santeiCount) {
    return e.santeiCount;
  } else if (e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "76") {
    if (isPlan) {
      if (isCheckAddRecord && sum(e.mainCode.listPlan) > 0) {
        return sum(e.mainCode.listPlan);
      } else if (!isCheckAddRecord && (sum(e.mainCode.listPlan) > 0 || sumPlan(fillData.listValueMain, e.mainCode, false, true) > 0)) {
        return sum(e.mainCode.listPlan) > 0
          ? sum(e.mainCode.listPlan)
          : sumPlan(fillData.listValueMain, e.mainCode, true, true) > 0
          ? sumPlan(fillData.listValueMain, e.mainCode, true, true)
          : null;
      } else if (isCheckAddRecord) {
        return null;
      } else {
        return null;
      }
    }
  } else {
    if (isPlan) return 0;
    else return null;
  }
};

const handleSumListActualParent = ({ e, isPlan, fillData, isCheckAddRecord }) => {
  if (!isPlan && sumPlan(fillData.listValueMain, e.mainCode, false, true) > 0 && e.mainCode.shuruiCode !== "17" && e.mainCode.shuruiCode !== "76") {
    if (isCheckAddRecord && !e.isGetDataWeekly) {
      return sum(e.mainCode.listActual);
    } else {
      return sumPlan(fillData.listValueMain, e.mainCode, false, true);
    }
  } else if (e.santeiCount) {
    return e.santeiCount;
  } else if (e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "76") {
    if (!isPlan) {
      if (isCheckAddRecord && sum(e.mainCode.listPlan) > 0) {
        // Hiện tại trong bug này ở mode Actual khi lưu và get ra data của Type 17 listActual đang chuyển sang listPlan
        return sum(e.mainCode.listPlan);
      } else if (!isCheckAddRecord && (sum(e.mainCode.listActual) > 0 || sumPlan(fillData.listValueMain, e.mainCode, false, true) > 0)) {
        return sum(e.mainCode.listActual) > 0
          ? sum(e.mainCode.listActual)
          : sumPlan(fillData.listValueMain, e.mainCode, true, true) > 0
          ? sumPlan(fillData.listValueMain, e.mainCode, true, true)
          : null;
      } else if (isCheckAddRecord) {
        return null;
      } else if (!isCheckAddRecord) {
        return null;
      } else {
        return null;
      }
    }
  } else {
    if (!isPlan) return 0;
    else return null;
  }
};

const handleSumListFPlanParent = ({ e, isPlan, fillData, isCheckAddRecord }) => {
  if (isPlan && sumPlan(fillData.listValueMain, e.mainCode, false, true) > 0) {
    if (isCheckAddRecord && !e.isGetDataWeekly) {
      return sum(e.mainCode.listPlan);
    } else {
      return sumPlan(fillData.listValueMain, e.mainCode, false, true);
    }
  } else if ((e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "76" || e.mainCode.shuruiCode === "71") && isPlan) {
    if (e.mainCode.serviceFillDataList[0]?.additionName.includes("日割り") && sum(e.listDate) === 0) {
      return 0;
    }
    return 1;
  } else {
    if (isPlan) return 0;
    else return null;
  }
};

const handleSumListFActualParent = ({ e, isPlan, fillData, isCheckAddRecord }) => {
  if (!isPlan && sumPlan(fillData.listValueMain, e.mainCode, false, true) > 0) {
    if (isCheckAddRecord && !e.isGetDataWeekly) {
      return sum(e.mainCode.listActual);
    } else {
      return sumPlan(fillData.listValueMain, e.mainCode, false, true);
    }
  } else if ((e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "76" || e.mainCode.shuruiCode === "71") && !isPlan) {
    if (e.mainCode.serviceFillDataList[0]?.additionName.includes("日割り") && sum(e.listDate) === 0) {
      return 0;
    }
    return 1;
  } else {
    if (!isPlan) return 0;
    else return null;
  }
};

const handleCalListAndSum = ({ item, arr1, arr2, formValues, isPlan, nengetu, isCheckAddRecord, isGetDataWeekly, serviceCodeMain }) => {
  const result = sumTowArr(arr1, arr2);

  const fillData = fillListData({
    listDate: result,
    countDate: 30,
    formValues: formValues,
    nengetu: nengetu,
    isGetDataWeekly: isGetDataWeekly,
    sumListDate: result,
    mainCode: item.mainCode,
  });

  const listValueDay = fillListValueDay(fillData, item, formValues, false, isGetDataWeekly);

  const listPlan = isPlan ? (isCheckAddRecord && item.listPlan ? item.listPlan : listValueDay) : fillData.listNotSet;

  const listActual = isPlan ? fillData.listNotSet : isCheckAddRecord ? item.listPlan : listValueDay;

  const listPlanCodeMain = isPlan
    ? isCheckAddRecord && serviceCodeMain.mainCode.listPlan
      ? serviceCodeMain.mainCode.listPlan
      : fillData.listValueMain
    : fillData.listNotSet;

  const listActualCodeMain = isPlan ? fillData.listNotSet : isCheckAddRecord ? serviceCodeMain.mainCode.listPlan : fillData.listValueMain;

  const sumListPlan = handleSumListPlan({ isPlan, isCheckAddRecord, isGetDataWeekly, element: item, listValueDay, serviceCodeMain });
  const sumListPlanCodeMain = handleSumListPlanCodeMain({ isPlan, isCheckAddRecord, element: item, fillData });

  const sumListFPlan = handleSumListFPlan({
    isPlan,
    isCheckAddRecord,
    isGetDataWeekly: isGetDataWeekly,
    element: item,
    listValueDay,
    serviceCodeMain,
  });
  const sumListFPlanCodeMain = handleSumListFPlanCodeMain({ isPlan, isCheckAddRecord, element: item, fillData });

  const sumListActual = handleSumListActual({ isPlan, isCheckAddRecord, listValueDay, element: item, serviceCodeMain, fillData });
  const sumListActualCodeMain = handleSumListActualCodeMain({ isPlan, isCheckAddRecord, element: item, fillData });

  const sumListFActual = handleSumListFActual({ isPlan, isCheckAddRecord, listValueDay, element: item, serviceCodeMain, fillData });
  const sumListFActualCodeMain = handleSumListFActualCodeMain({ isPlan, isCheckAddRecord, listValueDay, element: item, serviceCodeMain, fillData });

  return {
    listPlan,
    listActual,
    sumListPlan,
    sumListFPlan,
    sumListActual,
    sumListFActual,
    listPlanCodeMain,
    listActualCodeMain,
    sumListPlanCodeMain,
    sumListFPlanCodeMain,
    sumListActualCodeMain,
    sumListFActualCodeMain,
  };
};

function getListDateInMonth(nengetu) {
  const dateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let firstDateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let days = [];
  while (firstDateOfMonth.getMonth() == dateOfMonth.getMonth()) {
    days.push(firstDateOfMonth.getDate());
    firstDateOfMonth.setDate(firstDateOfMonth.getDate() + 1);
  }
  return days;
}

const changeDataTime = (nengetu) => {
  const dateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let firstDateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);

  let days = [];
  let weekOfMonth = 1; // Bắt đầu từ tuần thứ nhất trong tháng
  while (firstDateOfMonth.getMonth() == dateOfMonth.getMonth()) {
    days.push({
      date: firstDateOfMonth.getDate(),
      dayOfWeek: firstDateOfMonth.getDay(),
      weekOfMonth,
    });
    if (firstDateOfMonth.getDay() === 0) {
      // Nếu ngày là Chủ nhật (dayOfWeek = 0) thì tăng số tuần lên 1
      weekOfMonth++;
    }
    firstDateOfMonth.setDate(firstDateOfMonth.getDate() + 1);
  }
  return days;
};

function getListDay(nengetu) {
  let days = [];
  const dateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let firstDateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);

  let lastDateOfMonth = new Date(dateOfMonth.getFullYear(), dateOfMonth.getMonth() + 1, 0);
  let day = {};

  while (firstDateOfMonth.getMonth() == dateOfMonth.getMonth()) {
    switch (firstDateOfMonth.getDay()) {
      case 0:
        day = { ...day, sunday: firstDateOfMonth.getDate() };
        break;
      case 1:
        day = { ...day, monday: firstDateOfMonth.getDate() };
        break;
      case 2:
        day = { ...day, tuesday: firstDateOfMonth.getDate() };
        break;
      case 3:
        day = { ...day, wednesday: firstDateOfMonth.getDate() };
        break;
      case 4:
        day = { ...day, thursday: firstDateOfMonth.getDate() };
        break;
      case 5:
        day = { ...day, friday: firstDateOfMonth.getDate() };
        break;
      case 6:
        day = { ...day, saturday: firstDateOfMonth.getDate() };
        break;

      default:
        day = { ...day, sunday: firstDateOfMonth.getDate() };
        break;
    }

    if (firstDateOfMonth.getDay() == 0) {
      days.push(day);
      day = {};
    }
    if (firstDateOfMonth.getDate() == lastDateOfMonth.getDate()) {
      days.push(day);
    }
    firstDateOfMonth.setDate(firstDateOfMonth.getDate() + 1);
  }
  let dataList = [];
  days.forEach((element, index) => {
    let item = { ...element, number: "#" + index };
    dataList.push(item);
  });
  return dataList;
}
const findWeekAndDay = (days, weeks) => {
  const result = [];

  days.forEach((day) => {
    let foundWeek = null;
    let foundDay = null;

    weeks.forEach((week) => {
      if (Object.values(week).includes(day)) {
        foundWeek = week.number;
        const dayOfWeek = Object.keys(week).find((key) => week[key] === day);
        foundDay = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      }
    });

    result.push({ day: foundDay, week: foundWeek });
  });

  return result;
};

const convertDayQuote = ({ listDayQuote, listDatePickerOld, listDatePickerNew }) => {
  const result = findWeekAndDay(listDayQuote, listDatePickerOld);
  return result
    .map(({ week, day }) => {
      const weekNumber = parseInt(week.replace("#", ""));
      const weekDays = listDatePickerNew[weekNumber];
      return weekDays ? weekDays[day.toLowerCase()] : undefined;
    })
    .filter((value) => value !== null && value !== undefined);
};

const findWeekAndDayByCount = (days, weeks) => {
  const result = [];

  days.forEach((day) => {
    let foundWeek = null;
    let foundDay = null;

    weeks.forEach((week) => {
      if (Object.values(week).includes(day.date)) {
        foundWeek = week.number;
        const dayOfWeek = Object.keys(week).find((key) => week[key] === day.date);
        foundDay = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      }
    });

    result.push({ day: foundDay, week: foundWeek, count: day.count });
  });

  return result;
};

const convertDayQuoteCount = ({ listDayQuote, listDatePickerOld, listDatePickerNew }) => {
  const result = findWeekAndDayByCount(listDayQuote, listDatePickerOld);
  return result
    .map(({ week, day, count }) => {
      const weekNumber = parseInt(week.replace("#", ""));
      const weekDays = listDatePickerNew[weekNumber];
      return weekDays ? { date: weekDays[day.toLowerCase()], count } : undefined;
    })
    .filter((value) => !!value);
};

function findCorrespondingDaysCount(listDate, oldWeek, newWeek) {
  // Bước 1: Tìm các thứ trong oldWeek tương ứng với các ngày trong listDate
  const correspondingDaysFromoldWeek = listDate.map(({ date, count }) => {
    return { date: Object.keys(oldWeek[0]).find((key) => oldWeek[0][key] === date), count: count };
  });

  // Bước 2: Tìm các ngày trong newWeek tương ứng với các thứ đã tìm được
  const correspondingDaysFromnewWeek = correspondingDaysFromoldWeek.map((dayFromoldWeek) => {
    return { date: newWeek[0][dayFromoldWeek.date], count: dayFromoldWeek.count };
  });

  // Bước 3: Loại bỏ các giá trị "undefined" khỏi kết quả cuối cùng
  const finalResult = correspondingDaysFromnewWeek.filter((day) => day.date !== undefined);

  return finalResult;
}

function findCorrespondingDays(listDate, oldWeek, newWeek) {
  // Bước 1: Tìm các thứ trong oldWeek tương ứng với các ngày trong listDate
  const correspondingDaysFromoldWeek = listDate.map((day) => {
    return Object.keys(oldWeek[0]).find((key) => oldWeek[0][key] === day);
  });

  // Bước 2: Tìm các ngày trong newWeek tương ứng với các thứ đã tìm được
  const correspondingDaysFromnewWeek = correspondingDaysFromoldWeek.map((dayFromoldWeek) => {
    return newWeek[0][dayFromoldWeek];
  });

  // Bước 3: Loại bỏ các giá trị "undefined" khỏi kết quả cuối cùng
  const finalResult = correspondingDaysFromnewWeek.filter((day) => day !== undefined);

  return finalResult;
}

function isLastNengetuBeforeNengetu(nengetu, lastNengetu) {
  // Chuyển đổi chuỗi thành số nguyên để so sánh
  const nengetuNum = parseInt(nengetu, 10);
  const lastNengetuNum = parseInt(lastNengetu, 10);

  // Tách năm và tháng từ số nguyên
  const nengetuYear = Math.floor(nengetuNum / 100);
  const nengetuMonth = nengetuNum % 100;
  const lastNengetuYear = Math.floor(lastNengetuNum / 100);
  const lastNengetuMonth = lastNengetuNum % 100;

  // Kiểm tra nếu lastNengetu là tháng liền kề trước nengetu
  if (
    nengetuYear - lastNengetuYear === 1 && // Cách nhau 1 năm
    nengetuMonth === 1 && // nengetu là tháng 1
    lastNengetuMonth === 12 // lastNengetu là tháng 12
  ) {
    return true;
  }

  // Kiểm tra nếu lastNengetu là tháng liền kề trước nengetu trong cùng một năm
  if (nengetuYear === lastNengetuYear && nengetuMonth - lastNengetuMonth === 1) {
    return true;
  }

  // Không thỏa mãn điều kiện liền kề
  return false;
}

function mergeDuplicateElements(arr) {
  const result = [];

  for (const element of arr) {
    const { mainCode, formValues, expandCode } = element[0];

    const foundIndex = result.findIndex(
      (item) =>
        item.mainCode.serviceName === mainCode.serviceName &&
        item.mainCode.officeName === mainCode.officeName &&
        item.mainCode.offerTime === mainCode.offerTime &&
        item.mainCode.is30DayOver === mainCode.is30DayOver &&
        JSON.stringify(item.formValues.additionList) === JSON.stringify(formValues.additionList),
    );

    if (foundIndex !== -1) {
      // Perform addition of listPlan at the corresponding index
      for (let i = 0; i < mainCode.listPlan.length; i++) {
        result[foundIndex].mainCode.listPlan[i] += mainCode.listPlan[i];
      }
      result[foundIndex].listDate.push(...element[0].listDate);

      // Check and perform addition of expandCode's listPlan
      for (const expandElement of expandCode) {
        const foundExpandIndex = result[foundIndex].expandCode.findIndex((item) => item.serviceCd === expandElement.serviceCd);

        if (foundExpandIndex !== -1) {
          for (let i = 0; i < expandElement.listPlan.length; i++) {
            result[foundIndex].expandCode[foundExpandIndex].listPlan[i] += expandElement.listPlan[i];
          }
        } else {
          result[foundIndex].expandCode.push(expandElement);
        }
      }
    } else {
      result.push(element[0]);
    }
  }
  return result;
}

const handleSumListFPlanParentQuote = ({ e, isPlan }) => {
  if (
    isPlan &&
    sumPlan(e.mainCode.listPlan, e.mainCode, false, true) > 0 &&
    e.mainCode.shuruiCode !== "17" &&
    e.mainCode.shuruiCode !== "76" &&
    e.mainCode.shuruiCode !== "71"
  ) {
    return sum(e.mainCode.listPlan);
  } else if ((e.mainCode.shuruiCode === "17" || e.mainCode.shuruiCode === "76" || e.mainCode.shuruiCode === "71") && isPlan) {
    if (e.mainCode.serviceFillDataList[0]?.additionName.includes("日割り") && sum(e.mainCode.listPlan) === 0) {
      return 0;
    }
    return 1;
  } else {
    if (e.mainCode.name.includes("定期巡回訪看") || e.mainCode.shuruiCode === "78") return 1;
    else if (isPlan) return 0;
    else return null;
  }
};

const handleSumListPlanQuote = ({ isPlan, isCheckAddRecord, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }) => {
  if (isPlan) {
    if (isCheckAddRecord && !isGetDataWeekly && element && Array.isArray(element.listPlan)) {
      if (isCheckGrayChildRecord(element)) {
        return null;
      }
      if ((element.serviceCode.substring(0, 2) === "76" || element.serviceCode.substring(0, 2) === "71") && sum(element.listPlan) > 0) {
        return sum(element.listPlan);
      }
      if (
        element.serviceCode.substring(0, 2) === "71" ||
        element.serviceCode.substring(0, 2) === "17" ||
        element.serviceCode.substring(0, 2) === "76"
      ) {
        return null;
      }
      return sum(element.listPlan);
    }
    if (element && element.serviceCode && element.serviceCode.length >= 2 && mainCode && serviceCodeMain) {
      if ((element.serviceCode.substring(0, 2) === "76" || element.serviceCode.substring(0, 2) === "71") && sum(element.listPlan) > 0) {
        return sum(element.listPlan);
      }
      if (
        isCheckGrayChildRecord(element) ||
        element.serviceCode.substring(0, 2) === "76" ||
        element.serviceCode.substring(0, 2) === "71" ||
        element.serviceCode.substring(0, 2) === "17"
      ) {
        return null;
      }
      if ((element.serviceCode.substring(0, 2) === "76" || element.serviceCode.substring(0, 2) === "71") && sum(element.listPlan) > 0) {
        return sum(element.listPlan);
      }
      return sum(element.listPlan) > 0 ? sum(element.listPlan) : sum(listValueDay);
    }
    return sum(element.listPlan) > 0 ? sum(element.listPlan) : sum(listValueDay);
  }
  return null;
};

const handleSumListFPlanQuote = ({ isPlan, element, listValueDay, mainCode, serviceCodeMain }) => {
  if (isPlan) {
    if (
      (serviceCodeMain.mainCode.shuruiCode === "17" ||
        serviceCodeMain.mainCode.shuruiCode === "76" ||
        serviceCodeMain.mainCode.shuruiCode === "71") &&
      isPlan
    ) {
      if (serviceCodeMain.mainCode.serviceFillDataList[0]?.additionName.includes("日割り") && sum(serviceCodeMain.mainCode.listPlan) === 0) {
        return 0;
      }
      return 1;
    }
    if (element.serviceName.includes("サービス提供体制") && element.shuruiCode === "13") {
      if (serviceCodeMain.sumListPlan > 0) return 1;
      else return 0;
    }
    return sumPlan(element.listPlan, mainCode, false, false, element, serviceCodeMain);
  }
  return null;
};

const createListExpandCodeRiyouhyou = ({
  expandCode,
  formValues,
  mainCode,
  fillData,
  selectedRow,
  parentId,
  listDataChildrenFilter,
  isCheckAddRecord,
  isGetDataWeekly,
  isPlan,
  props,
  expandCodeOfItem,
  serviceCodeMain,
  isFromApi = false,
}) => {
  let expandCodeNotParentAdd = [];
  let expandCodeAdd = [];
  let expandCodeOtherParent = [];
  const calculateSericeExpand = (element, listValueDay) => {
    return {
      serviceKikanMasterId: formValues.sid ?? mainCode.serviceKikanMasterId,
      serviceName: element.serviceName,
      jigyousyoId: formValues.jigyousyoId,
      officeName: formValues.ryakuServiceKikan,
      listPlan: isCheckGrayChildRecord(element) ? fillData.listNotSet : sum(element.listPlan) > 0 && !isCheckComeOrGo({ element }) && !isCheckComeAndGo({ element }) ? element.listPlan : listValueDay,
      listActual: isFromApi ? element.listActual : isPlan ? fillData.listNotSet : isCheckAddRecord ? element.listActual : listValueDay,
      sumListPlan: handleSumListPlanQuote({ isPlan, isCheckAddRecord: 0, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }),
      sumListFPlan: isCheckGrayChildRecord(element)
        ? 1
        : handleSumListFPlanQuote({ isPlan, isCheckAddRecord: 0, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }),
      sumListActual: handleSumListActual({ isPlan, isCheckAddRecord, isGetDataWeekly, element, listValueDay, mainCode, serviceCodeMain }),
      sumListFActual: isFromApi
        ? element.santeiCountActual
        : handleSumListFActual({ isPlan, isCheckAddRecord, element, listValueDay, mainCode, serviceCodeMain }),
      parentId: mainCode.parentId ? mainCode.parentId : selectedRow?.parentId ?? formValues.nengetu + "" + parentId,
      komokuCode: element.komokuCode,
      sikyugendogakuKubun: element.sikyugendogakuKubun,
      taniSikibetu: element.taniSikibetu,
      santeiTani: element.santeiTani,
      serviceTeikyoKyoka: element.serviceTeikyoKyoka,
      sisetukubun: element.sisetukubun,
      terminal: element.terminal,
      kikanJiki: element.kikanJiki,
      jikanEntyou: element.jikanEntyou,
      kaisuNisu: element.kaisuNisu,
      sogei: element.sogei,
      ryoyoshoku: element.ryoyoshoku,
      serviceRiyoJoken: element.serviceRiyoJoken,
      hiwariSikibetu: element.hiwariSikibetu,
      idService: element.idService ? element.idService : element.id,
      id: element.id + "" + parentId,
      isParent: false,
      serviceCode: element.serviceCode,
      dataRow: formValues,
      isShow: true,
      mainCode: mainCode,
      tani: element.tani,
      kyufukanriTani: element.kyufukanriTani,
      serviceFillDataList: element.serviceFillDataList,
      fillAccording: element.serviceFillDataList[0]?.fillAccording ?? "emty",
      additionTime: element.serviceFillDataList[0]?.additionTime ?? "emty",
      serviceAddition: element.serviceAddition,
      discount: props.officeInfo?.discount ?? mainCode.discount,
      price: props.officeInfo?.price ?? mainCode.price,
      doitutatemonoSantei: element.doitutatemonoSantei,
      goseiSikbetuKubun: element.goseiSikbetuKubun,
      tuho24: element.tuho24,
      rihaTeikyo: element.rihaTeikyo,
      kyoseigataSantei: element.kyoseigataSantei,
      tokuteiShogukaizen: element.tokuteiShogukaizen,
      rihamane: element.rihamane,
      shogukaizen: element.shogukaizen,
      nintishoSenmoncare: element.nintishoSenmoncare,
      listDataChildrenFilter,
      is30DayOver: isCheckAddRecord ? element.is30DayOver : formValues.is30DayOver,
      parentIds: element.parentIds,
      seikatusoudaninHaiti: element.seikatusoudaninHaiti,
      serviceRiyoJoken: element.serviceRiyoJoken,
      jikanEntyou: element.jikanEntyou,
      higaito: element.higaito,
      yousien: element.yousien,
      yousien1: element.yousien1,
      youkaigo1: element.youkaigo1,
      youkaigo2: element.youkaigo2,
      youkaigo3: element.youkaigo3,
      youkaigo4: element.youkaigo4,
      youkaigo5: element.youkaigo5,
      gaibuserviceShuruicode: element.gaibuserviceShuruicode,
      conditionType76:
        element.serviceCode.substring(0, 2) === "76"
          ? "shuruicode:" + element.serviceCode.substring(0, 2) + ",serviceRiyoJoken:" + element.serviceRiyoJoken
          : null,
    };
  };
  if (expandCode.length) {
    expandCode.forEach((element) => {
      if (element.serviceCode) {
        const listValueDay = fillListValueDay(fillData, element, formValues, false, isGetDataWeekly);
        const serviceExpand = calculateSericeExpand(element, listValueDay);
        isCheckGrayChildRecord(element) ? expandCodeNotParentAdd.push(serviceExpand) : expandCodeAdd.push(serviceExpand);
      }
    });
  }
  if (expandCodeOfItem?.length) {
    expandCodeOfItem.forEach((element) => {
      if (element.serviceCode) {
        const listValueDay = fillListValueDay(fillData, element, formValues, false, isGetDataWeekly);
        const serviceExpand = calculateSericeExpand(element, listValueDay);
        expandCodeOtherParent.push(serviceExpand);
      }
    });
  }

  return { expandCodeNotParentAdd, expandCodeAdd, expandCodeOtherParent };
};

function processDataOver30Days(dataSelected, nengetu, day) {
  const dataOver30Days = _.cloneDeep(dataSelected);
  const dateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const lastDay = new Date(dateOfMonth.getFullYear(), dateOfMonth.getMonth() + 1, 0);
  const mainCode = dataOver30Days.find((e) => e.isParent);
  const newDataRow = dataOver30Days.find((e) => e.isParent).dataRow;
  newDataRow.is30DayOver = true;
  newDataRow.nengetu = nengetu;

  // Loại bỏ các thuộc tính không cần thiết trong mainCode
  const { mainCodeId, listPlan, listActual, parentId, ...mainCodeRest } = mainCode;

  const listDataRecord = {
    countDate: lastDay.getDate(),
    additionListMain: [],
    expandCode: dataOver30Days.filter((e) => !e.isParent).map(({ listPlan, listActual, dataRow, parentId, ...rest }) => rest),
    isGetDataWeekly: false,
    isKeepFirst: false,
    isKeepLast: false,
    listDate: [day ? day : lastDay.getDate() === 31 && 31],
    mainCode: {
      ...mainCodeRest,
      name: mainCode.serviceName,
      is30DayOver: true,
      shuruiCode: newDataRow.shuiruiCode,
      idService: +mainCode.idService,
    },
    formValues: newDataRow,
  };

  return listDataRecord;
}

function processDataKaigo(dataSelected, nengetu, day) {
  const dataOver30Days = _.cloneDeep(dataSelected);
  const dateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const lastDay = new Date(dateOfMonth.getFullYear(), dateOfMonth.getMonth() + 1, 0);
  const mainCode = dataOver30Days.find((e) => e.isParent);
  const newDataRow = dataOver30Days.find((e) => e.isParent).dataRow;
  newDataRow.is30DayOver = false;
  newDataRow.nengetu = nengetu;

  // Loại bỏ các thuộc tính không cần thiết trong mainCode
  const { mainCodeId, parentId, ...mainCodeRest } = mainCode;

  const listDataRecord = {
    countDate: lastDay.getDate(),
    additionListMain: [],
    expandCode: dataOver30Days.filter((e) => !e.isParent).map(({ dataRow, parentId, ...rest }) => rest),
    isGetDataWeekly: false,
    isKeepFirst: false,
    isKeepLast: false,
    listDate: day,
    mainCode: {
      ...mainCodeRest,
      name: mainCode.serviceName,
      is30DayOver: true,
      shuruiCode: newDataRow.shuiruiCode,
      idService: +mainCode.idService,
    },
    formValues: newDataRow,
  };

  return listDataRecord;
}
function fixedNumber(number) {
  if (+(number % 1).toFixed(12) === 0.999999999999 || +(number % 1).toFixed(12) === 0.999999999998 || +(number % 1).toFixed(12) === 0.999999999996) {
    return parseInt(Math.round(number));
  } else if (!number) {
    return number;
  } else {
    return parseInt(number);
  }
}
function roundDecimal(value) {
  const decimalPart = value.toString().split('.')[1];
  const firstDecimalDigit = parseInt(decimalPart?.charAt(0));
  const secoundDecimalDigit = parseInt(decimalPart?.charAt(1));
  const thirdDecimalDigit = parseInt(decimalPart?.charAt(2));
  const fourDecimalDigit = parseInt(decimalPart?.charAt(3));

  if (firstDecimalDigit === 9 && secoundDecimalDigit === 9 && thirdDecimalDigit === 9 && fourDecimalDigit === 9) {
    return Math.round(value);
  } else {
    return Math.floor(value);
  }
}

const handleChangeTypeMonthly = ({
  listDate,
  countDate,
  formValues,
  mainCode,
  expandCode,
  additionListMain,
  listData,
  rowCheck,
  props,
  nengetu,
  isPlan,
  changeSort,
  changeFilter,
  mainService,
  dayOfKaigo,
  hokenSecond,
}) => {
  const listDataService = cloneDeep(listData.filter((e) => formValues.shuiruiCode.includes(e.dataRow.shuiruiCode)));
  const listDataFilter = listData.filter((e) => !KAIGO_CODE_ALONE.includes(e.dataRow.shuiruiCode));
  const dayOfMonth = getListDateInMonth(nengetu).length + 1;
  let selectedRow = listDataService.find((i) => i.id === rowCheck);
  const kaigoSelectedRow = defineKaigoName(selectedRow);
  const dayKaigo = dayOfMonth - dayOfKaigo;
  const conditionKaigoday = !kaigoSelectedRow.includes(hokenSecond.kaigodoMaster.kaigodoName);
  selectedRow = {
    ...selectedRow,
    sumListFPlan: selectedRow.sumListFPlan && (conditionKaigoday ? dayKaigo : dayOfKaigo - 1),
    sumListFActual: selectedRow.sumListFActual && (conditionKaigoday ? dayKaigo : dayOfKaigo - 1),
    listPlanClone: selectedRow.listPlan,
    listActualClone: selectedRow.listActual,
  };
  const listSelected = listDataService.filter((e) => e.parentId === selectedRow.parentId);

  const objectAssign = {
    ...mainCode,
    serviceName: mainCode.name,
    officeName: formValues.ryakuServiceKikan,
    offerTime: props.listColorMaster.length > 0 && offerTimeFilter(formValues, props.listColorMaster),
    serviceCode: mainCode.serviceCode,
    dataRow: formValues,
    mainCode: mainCode,
    santeiTani: mainCode.santeiTani,
    sisetukubun: mainCode.sisetukubun,
    hiwariSikibetu: mainCode.hiwariSikibetu,
    additionListMain: additionListMain.map((e) => e["addition"]),
    is30DayOver: formValues.is30DayOver,
    price: mainCode.price,
    isParent: true,
    idService: mainCode.id,
  };

  Object.assign(selectedRow, {
    ...objectAssign,
  });

  const listCurrentExpandCodeName = listSelected.map((e) => e.serviceName);
  const listNewExpandCodeName = expandCode.map((e) => e.serviceName);
  const newExpandCodeFilter = listSelected.filter((e) => !e.isParent && !listNewExpandCodeName.includes(e.serviceName));

  const expandCodeFilter = expandCode.filter((e) => !listCurrentExpandCodeName.includes(e.serviceName));

  const { newExpandCode, newExpandCodeChild } = filterMonthlyExpandCode({ formValues, expandCode: expandCodeFilter, props });
  const mergedArray = [...newExpandCode, ...newExpandCodeChild];

  // Lọc các phần tử có serviceCode không trùng nhau
  const uniqueArray = mergedArray.filter((item, index, self) => index === self.findIndex((t) => t.serviceCode === item.serviceCode));
  const resultArrSoft = _.sortBy(uniqueArray, "serviceCode");

  const fillData = fillListData({ listDate, countDate, mainCode, formValues, nengetu });

  const isCheckAddRecord = false;

  const { expandCodeOtherParent } = createListExpandCode({
    expandCode: resultArrSoft,
    formValues,
    mainCode,
    fillData,
    selectedRow,
    isPlan,
    props,
    expandCodeOfItem: expandCode,
    parentId: generateUuid(),
    serviceCodeMain: mainService,
  });
  expandCodeOtherParent.forEach((e) => {
    if (!listCurrentExpandCodeName.includes(e.serviceName) && isCheckBaseOnKaigo(e)){
      e.listPlan = newExpandCodeFilter[0]?.listPlan;
      e.listActual = newExpandCodeFilter[0]?.listActual
    }
  })

  // Bỏ đi các bản ghi có parentId = parentId của selectedRow
  const listDataOtherParentIdSelected = cloneDeep(listDataService)
    .filter((e) => e.parentId !== selectedRow.parentId || isCheckGrayChildRecord(e))
    .map((item) => {
      if (isCheckGrayChildRecord(item)) {
        item.parentIds = item.parentIds.filter((e) => e !== selectedRow.parentId);
      }
      return item;
    })
    .filter((item) => !isCheckGrayChildRecord(item) || (isCheckGrayChildRecord(item) && !isEmpty(item.parentIds)));

  const listDataServiceOtherItemSelect = [...listDataOtherParentIdSelected, ...expandCodeOtherParent];

  let newListDataService = handleFilterDuplicateRecordEdit({
    listDataServiceOtherItemSelect,
    selectedRow,
    listDataOtherParentIdSelected,
    isPlan,
    nengetu,
    formValues,
    isCheckAddRecord,
    listDataService,
  });

  const dataGroupParentIds = handleGroupParentIds(newListDataService);

  const listDataShow = calListDataShow({ dataService: dataGroupParentIds, changeSort, changeFilter });
  const newListDataServiceShow = listDataShow.flatMap((e) => e);
  return { listDataServiceNew: newListDataServiceShow, listDataShow, listDataFilter };
};

const defineKaigoName = (atr) => {
  if (atr.higaito === "1" || atr.higaito === "") {
    return "非該当";
  } else if (atr.yousien === "1") {
    return "要支援";
  } else if (atr.yousien1 === "1") {
    return "要支援1";
  } else if (atr.yousien2 === "1") {
    return "要支援2";
  } else if (atr.youkaigo1 === "1") {
    return "要介護1";
  } else if (atr.youkaigo2 === "1") {
    return "要介護2";
  } else if (atr.youkaigo3 === "1") {
    return "要介護3";
  } else if (atr.youkaigo4 === "1") {
    return "要介護4";
  } else if (atr.youkaigo5 === "1") {
    return "要介護5";
  } else {
    return "";
  }
};
const defineKaigoNameType13 = (atr, isAll) => {
  if (atr.youkaigo1 === "1" && atr.youkaigo2 === "1" && atr.youkaigo3 === "1" && atr.youkaigo4 === "1") {
    if (isAll) {
      return "要介護1;要介護2;要介護3;要介護4";
    } else {
      return "要介護1";
    }
  } else if (atr.youkaigo5 === "1") {
    return "要介護5";
  }
};
const isCheckBaseOnKaigo = (atr)=>{
  return !!(atr.youkaigo1 === "1" || atr.youkaigo2 === "1" || atr.youkaigo3 === "1" || atr.youkaigo4 === "1" || atr.youkaigo5 === "1")
}

const handleEditRecordChangeKaigo = async ({
  mainCode,
  kaigoName, 
  nengetu, 
  props, 
  isPlan, 
  changeSort, 
  changeFilter, 
  dayOfKaigo, 
  hokenSecond,
  listData,
}) =>{
  let listDataServiceMain = [];
  let listDataShowMain = [];
  let listDataFilterCal = [];
  await Promise.all(mainCode.map(async (e) => {
    const listDate = (isPlan ? e.listPlan : e.listActual).map((el, index) => el !== 0 ? index + 1 : 0);
    const countDate = e.listPlan.length;
    const formValues = { ...e.dataRow, additionList: [...e.dataRow.additionList, "日割り"] };
    const serviceContent = await RequestUtils.callApi({
      method: "POST",
      path: `${ApiPaths.UseSlip.GetServiceContentBySdCode}`,
      body: {
        syuruiCd: e.dataRow.shuiruiCode,
        serviceNaiyou: e.dataRow.serviceContent,
      },
    });
    const dataCallApi = {
      ...e.dataRow,
      nengetu,
      price: e.price,
      tani: e.tani,
      endTime: serviceContent.calculationTime,
      kaigodoName: kaigoName,
      additionList: [...e.dataRow.additionList, "日割り"],
    }
    const newMainCode = await RequestUtils.callApi({
      method: "POST",
      path: `${ApiPaths.UseSlip.GetServiceCode}`,
      body: dataCallApi,
    });
    const newExpandCode = await RequestUtils.callApi({
      method: "POST",
      path: `${ApiPaths.UseSlip.GetExpandCode}`,
      body: dataCallApi,
    });
    const query = { serviceType: e.dataRow.serviceCodeType, syuruiCd: e.dataRow.shuiruiCode, nengetu };
    const newAddition = await RequestUtils.callApi({
      query,
      method: "GET",
      path: `${ApiPaths.UseSlip.GetServiceAddition}`,
    });
    const additionListMain = newAddition.filter((x) => formValues.additionList.includes(x.addition) && x.additionType == 0)
    const newExpandCodeFilter = newExpandCode.filter((el) => el.serviceCode);
    const { listDataServiceNew, listDataShow, listDataFilter } = handleChangeTypeMonthly({
      listDate,
      countDate,
      formValues,
      mainCode: newMainCode,
      expandCode: newExpandCodeFilter,
      additionListMain,
      listData,
      rowCheck: e.id,
      props,
      nengetu,
      isPlan,
      changeSort,
      changeFilter, 
      mainService: e,
      dayOfKaigo,
      hokenSecond,
    });
    listDataServiceMain = [...listDataServiceMain, ...listDataServiceNew];
    listDataShowMain = [...listDataShowMain, ...listDataShow];
    listDataFilterCal = [...listDataFilterCal, ...listDataFilter];
  }));
  const parentId = generateUuid();
  let parentIdOfelement = 0;
  const newlistServiceMain = listDataServiceMain.map((e) => {
    if (e.isParent) {
      const dayOfMonth = getListDateInMonth(nengetu).length + 1;
      const dayKaigo = dayOfMonth - dayOfKaigo;
      const kaigoSelectedRow = e.dataRow.shuiruiCode.includes("13") ? defineKaigoNameType13(e.mainCode, true) : defineKaigoName(e.mainCode);
      const conditionKaigoday = !kaigoSelectedRow.includes(hokenSecond.kaigodoMaster.kaigodoName);
      const sumListFPlan = e.sumListFPlan && (conditionKaigoday ? dayKaigo : dayOfKaigo - 1);
      const sumListFActual = e.sumListFActual && (conditionKaigoday ? dayKaigo : dayOfKaigo - 1);
      const id = e.mainCode.id + e.serviceCode + "" + parentId;
      parentIdOfelement = e.mainCode.id + (+parentId) + "";

      return { ...e, sumListFPlan, sumListFActual, id, parentId: parentIdOfelement };
    }
    if(!e.isParent){
      return { ...e, parentId: parentIdOfelement }
    }
    if(isCheckGrayChildRecord(e)){
      return { ...e, parentIds: [...parentIdOfelement], parentId: parentIdOfelement }
    }
    return e;
  });
  return { listDataServiceMain: newlistServiceMain, listDataShowMain, listDataFilter: listDataFilterCal };
}

const handleCalculateSumList = ({ data, dayOfKaigo, hokenSecond, isCheckChangeKaigo }) => {
  const result = data.reduce((acc, current) => {
    if (isCheckGrayChildRecord(current)) {
      acc.push({
        ...current,
      });
    } else {
      if (!isCheckChangeKaigo) {
        if (current.isParent) {
          const kaigoSelectedRow = current.dataRow.shuiruiCode === "13" ? defineKaigoNameType13(current.mainCode, true) : defineKaigoName(current.mainCode);
          const conditionKaigoday = !kaigoSelectedRow.includes(hokenSecond.kaigodoMaster.kaigodoName);
          const listPlan =
            sum(current.listPlan) > 0 && conditionKaigoday
              ? current.listPlan.map((e, index) => (index >= dayOfKaigo - 1 ? e : 0))
              : current.listPlan.map((e, index) => (index < dayOfKaigo - 1 ? e : 0));
          const listActual =
            sum(current.listActual) > 0 && conditionKaigoday
              ? current.listActual.map((e, index) => (index >= dayOfKaigo - 1 ? e : 0))
              : current.listActual.map((e, index) => (index < dayOfKaigo - 1 ? e : 0));
          const sumListPlan = sum(listPlan) > 0 ? sum(listPlan) : "";
          const sumListActual = sum(listActual) > 0 ? sum(listActual) : "";
          acc.push({
            ...current,
            listPlan,
            listActual,
            sumListPlan,
            sumListActual,
          });
        } else {
          if (isCheckBaseOnKaigo(current)) {
            const kaigoSelectedRow = current.dataRow.shuiruiCode === "13" ? defineKaigoNameType13(current.mainCode, true) : defineKaigoName(current.mainCode);
            const conditionKaigoday = !kaigoSelectedRow.includes(hokenSecond.kaigodoMaster.kaigodoName);
            const listPlan =
              sum(current.listPlan) > 0 && conditionKaigoday
                ? current.listPlan.map((e, index) => (index >= dayOfKaigo - 1 ? e : 0))
                : current.listPlan.map((e, index) => (index < dayOfKaigo - 1 ? e : 0));
            const listActual =
              sum(current.listActual) > 0 && conditionKaigoday
                ? current.listActual.map((e, index) => (index >= dayOfKaigo - 1 ? e : 0))
                : current.listActual.map((e, index) => (index < dayOfKaigo - 1 ? e : 0));
            const sumListPlan = sum(listPlan) > 0 ? sum(listPlan) : "";
            const sumListFPlan = sum(listPlan) > 0 ? sum(listPlan) : "";
            const sumListActual = sum(listActual) > 0 ? sum(listActual) : "";
            const sumListFActual = sum(listActual) > 0 ? sum(listActual) : "";
            acc.push({
              ...current,
              listPlan,
              listActual,
              sumListPlan,
              sumListFPlan,
              sumListActual,
              sumListFActual,
            });
          } else {
            if (current.serviceFillDataList[0]?.additionName.includes("日割り")) {
              const sumListPlan = sum(current.listPlan) > 0 ? sum(current.listPlan) : "";
              const sumListFPlan = sum(current.listPlan) > 0 ? sum(current.listPlan) : "";
              const sumListActual = sum(current.listActual) > 0 ? sum(current.listActual) : "";
              const sumListFActual = sum(current.listActual) > 0 ? sum(current.listActual) : "";
              acc.push({
                ...current,
                sumListPlan,
                sumListFPlan,
                sumListActual,
                sumListFActual,
              });
            } else {
              acc.push({
                ...current,
              });
            }
          }
        }
      } else {
        if(!current.serviceFillDataList[0]?.code.includes("C021=04")){
          const kaigoSelectedRow = current.dataRow.shuiruiCode === "13" ? defineKaigoNameType13(current.mainCode, true) : defineKaigoName(current.mainCode);
          const conditionKaigoday = !kaigoSelectedRow.includes(hokenSecond.kaigodoMaster.kaigodoName);
          const listPlan =
          sum(current.listPlan) > 0 && conditionKaigoday
          ? current.listPlan.map((e, index) => (index >= dayOfKaigo - 1 ? e : 0))
          : current.listPlan.map((e, index) => (index < dayOfKaigo - 1 ? e : 0));
          const listActual =
          sum(current.listActual) > 0 && conditionKaigoday
          ? current.listActual.map((e, index) => (index >= dayOfKaigo - 1 ? e : 0))
          : current.listActual.map((e, index) => (index < dayOfKaigo - 1 ? e : 0));
        const sumListPlan = sum(listPlan) > 0 ? sum(listPlan) : current.sumListPlan;
        const sumListFPlan = sum(listPlan) > 0 ? sum(listPlan) : current.sumListFPlan;
        const sumListActual = sum(listActual) > 0 ? sum(listActual) : current.sumListActual;
        const sumListFActual = sum(listActual) > 0 ? sum(listActual) : current.sumListFActual;
        acc.push({
          ...current,
          listPlan,
          listActual,
          sumListPlan,
          sumListFPlan,
          sumListActual,
          sumListFActual,
        });
      }{
        acc.push(({
          ...current
        }))
      }
      }
    }
    return acc;
  }, []);
  const filterResult = result.filter((e) => e.sumListFPlan || e.sumListFActual);
  return filterResult;
};

const handleCalParentId = ({ listDataService }) => {
  const newListDataService = cloneDeep(listDataService);
  const mapParentId = newListDataService.filter((e) => e.isParent).map((e) => e.parentId);

  newListDataService.forEach((e) => {
    if (isCheckGrayChildRecord(e)) {
      e.parentIds.push(e.parentId);
      e.parentIds = e.parentIds.filter((i) => mapParentId.includes(i));
    }
  });
  return newListDataService;
}


async function handleOkTenkey({ 
  dataAttached, 
  exceedingValue, 
  record, 
  props, 
  mode, 
  dataAttachedFull, 
  kubunsikyu, 
  isMutilOver, 
  smallTotal, 
  isInput, 
  calCount, 
  listDataService, 
  value, 
  timeString 
}) {
  const filterDataAttached = dataAttachedFull.filter((e) => e.officeName === record.officeName);
  const sortDataAttached = _.sortBy(filterDataAttached, (e) => {
    return [e.color === "BLUE" ? 0 : 1, e.priority];
  });
  let remainValue = 0;
  let totalBenefitUnit = 0;
  sortDataAttached.forEach((e) => {
    if (e.idService === record.idService || e.officeName === record.officeName) {
      if (e.serviceTeikyoKyoka === null) {
        switch (e.color) {
          case "BLUE":
            if(!e.is30DayOver){
              e.benefitUnit = value.el.kyufutani;
              e.isTenkey = true;
              e.exceedingValue = exceedingValue;
              e.remainValue = e.unitPriceClone - record.exceedingValue;
              totalBenefitUnit = e.benefitUnitClone - record.exceedingValue;
              remainValue = e.remainValue;
              e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
              e.benefitValue = Math.floor((Math.floor(e.remainValue * e.unitCost) * e.benefit) / 100);
              e.burden = fixedNumber(e.remainValue * e.unitCost) - e.benefitValue;
              e.totalCost = fixedNumber((e.unitPriceClone - e.exceedingValue) * e.unitCost);
            }
            break;
          case "GREY":
            if (CODE3.some((i) => e.serviceContent.includes(i))) {
              const objOver = filterDataAttached.filter((i) => e.target.includes(i.id) && i.color !== "BLUE" && i.color !== "WHITE").map((el) => el.unitPrice);
              e.unitPrice = Math.round((remainValue + _.sum(objOver)) * e.percent) > 0 ? Math.round((remainValue + _.sum(objOver)) * e.percent) : 0;
              e.exceedingValue = (e?.exceedingValue || 0) + e.prevUnit - e.unitPrice;
              e.externalSalary = fixedNumber(e.exceedingValue * e.unitCost);
              e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
              e.unit = e.unitPrice / e.sumListPlan;
              e.benefitValue = Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
              e.burden = Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
            } else{
              e.exceedingValue = e.exceedingValuePrev && e.exceedingValuePrev > 0 ? e.exceedingValuePrev : 0;
            } 
          case "WHITE":
            if(e.isParent){
              e.benefitUnit = value.el.kyufutani - _.sumBy(
                sortDataAttached.filter((e) => e.color === "WHITE" && !e.isParent),
                "benefitUnit",
              )
            }
            break;
          default:
            break;
        }
      } else {
        // patern2
        // C136 !== null
        const targetSkipItem = e.serviceFillDataList?.some((i) => i.code == "C003=03");
        if (CODE3.some((i) => e.serviceContent.includes(i)) && !targetSkipItem) {
          let isTenkey = true;
          const { count } = calCount(e, remainValue, isMutilOver, {}, dataAttached, dataAttachedFull, mode, isTenkey, listDataService);
          if (e.discountUnit) {
            e.exceedingValue = count * e.discountUnit;
          } else {
            e.exceedingValue = count * e.unit;
          }
          
          if (e.exceedingValuePrev && e.exceedingValuePrev > 0) {
            e.exceedingValue += e.exceedingValuePrev;
          }
          e.remainValue = null;
          e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
          e.unitPrice = e.discountUnit ? e.discountUnit * e.sumListPlan - e.exceedingValue : e.unit * e.sumListPlan - e.exceedingValue;
          e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
          e.benefitValue = e.color === "GREY" && Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
          e.burden = e.color === "GREY" && Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
        } else {
          e.exceedingValue = e.exceedingValuePrev && e.exceedingValuePrev > 0 ? e.exceedingValuePrev : 0;
          e.unitPrice = e.unit * e.sumListPlan;
          e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
          e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
          e.benefitValue = Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
          e.burden = Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
        }
      }
    }
  });

  const newDataAttached = await Promise.all(dataAttachedFull.map(async (e) => {
    await calculateFutan({ element: e, kyufuHoken: props.riyouhyouForm.hokenSelected?.kyufu, timeString });
    return e;
  }));

  const { totalBurden, totalExternalSalary, totalAAAAA } = calTotalTable(newDataAttached);
  const { totalBenefitUnitA, totalUnitPrice } = calTotalSmallTotal({ dataAttached: newDataAttached });
  const result = {
    totalBenefitUnit: totalBenefitUnitA,
    totalUnitPrice: totalUnitPrice,
    case19: kubunsikyu - totalBenefitUnitA,
    totalBurden,
    totalExternalSalary,
    totalAAAAA,
    totalCase19: smallTotal.totalCase19
  };
  if(isInput){
    if (mode) {
      props.getOverProcess(true);
    } else props.getOverProcessActual(true);
  }
  return { result }
}
const calTotalSmallTotal = ({ dataAttached }) => {
  let totalUnitPrice = 0;
  let totalBenefitUnitA = 0;

  dataAttached.forEach((e) => {
    if (e.color === "BLUE" && !e.is30DayOver) {
      const calTotalUnitPrice = e.unitPrice - (e.exceedingValue ? e.exceedingValue : 0);
      const calTotalBenefitUnit = e.benefitUnit - (e.exceedingValue ? e.exceedingValue : 0)
      totalUnitPrice += calTotalUnitPrice;
      totalBenefitUnitA += calTotalBenefitUnit;
    }
  });
  return { totalUnitPrice, totalBenefitUnitA };
};

const calTotalTable = (currentData) => {
  let totalBurden = 0;
  let totalExternalSalary = 0;
  let totalExceedingValue = 0;
  let totalUnitPrice = 0;
  let totalBenefitUnit = 0;
  currentData.forEach((e) => {
    totalBurden += e.color !== "WHITE" && e.burden;
    totalExternalSalary += e.color !== "WHITE" && e.externalSalary;
    totalExceedingValue += e.color === "BLUE" && !e.is30DayOver && (e.exceedingValue ?? 0);
    totalUnitPrice +=e.color === "BLUE" && !e.is30DayOver && e.unitPrice;
    totalBenefitUnit +=e.color === "BLUE" && !e.is30DayOver && e.benefitUnit;
  });
  const totalAAAAA = totalBurden + totalExternalSalary;

  return { totalBurden, totalExternalSalary, totalAAAAA, totalExceedingValue, totalUnitPrice, totalBenefitUnit };
};

const handleSpecialTypeRecal = ({ filterArr, number }) => {
  const result = [];
  const resultArr = [];
  const groupFilterArr = _.groupBy(filterArr, (e) => {
    return [e.serviceName, e.officeName];
  });
  const objectKey = Object.keys(groupFilterArr);

  objectKey.forEach((e) => {
    if (groupFilterArr[e].length > 1) result.push(groupFilterArr[e]);
    else resultArr.push(groupFilterArr[e])
  });
  result.forEach((e) => {
    let count = number;
    let countActual = number;
    e.forEach((e2) => {
      if (sum(e2.listPlan) > 0) {
        if (count <= 0) (e2.sumListFPlan = 0);
        else {
          if (Math.sign(count - e2.sumListFPlan) === -1 || e2.sumListFPlan === number) {
            e2.sumListFPlan = count;
            count = 0;
          } else {
            count = count - e2.sumListFPlan;
          }
        }
      }
      if (sum(e2.listActual) > 0) {
        if (countActual <= 0) (e2.sumListFActual = 0);
        else {
          if (Math.sign(countActual - e2.sumListFActual) === -1 || e2.sumListFActual === number) {
            e2.sumListFActual = countActual;
            countActual = 0;
          } else {
            countActual = countActual - e2.sumListFActual;
          }
        }
      }
    });
  });
  resultArr?.forEach((e) => {
    e.forEach((e2) => {
      if (sum(e2.listPlan) > 0) e2.sumListFPlan = number;
      if (sum(e2.listActual) > 0) e2.sumListFActual = number;
    });
  });
}

const handleFormatDataWeekly = ({ dataWeekly }) => {
  const newDataWeekly = dataWeekly.reduce((accumulator, current) => {
    const existingElement = accumulator.find(
      (element) =>
        element.serviceCd === current.serviceCd &&
        element.name === current.name &&
        element.ryakuServiceKikan === current.ryakuServiceKikan &&
        element.strStartTime === current.strStartTime &&
        element.strEndTime === current.strEndTime &&
        element.jigyousyoNoServiceKikan === current.jigyousyoNoServiceKikan && 
        element.tani === current.tani
    );

    if (existingElement) {
      existingElement.additionCodeList = [
        ...existingElement.additionCodeList,
        ...current.additionCodeList
      ];
      if (existingElement.shortKasanNaiyouList) {
        existingElement.shortKasanNaiyouList = [
          ...existingElement?.shortKasanNaiyouList || [],
          ...current?.shortKasanNaiyouList || []
        ]
      }

      existingElement.youbiList.push(current.youbi);

    } else {
      const newElement = {
        ...current,
        additionCodeList: [...current.additionCodeList],
        youbiList: [current.youbi],
        shortKasanNaiyouList: [...(current?.shortKasanNaiyouList || [])]
      };
      accumulator.push(newElement);
    }

    return accumulator;
  }, []);

  const formatNewDataWeekly = newDataWeekly.map((e) => {
    const newAdditionCodeList = e.additionCodeList.reduce((acc, curr) => {
      const existingAddition = acc.find((el) =>
        el.serviceCode === curr.serviceCode &&
        el.serviceName === curr.serviceName &&
        el.id === curr.id
      );
      if (existingAddition) {
        existingAddition.youbiList.push(curr.youbi);
      } else {
        const newAddition = {
          ...curr,
          youbiList: [curr.youbi]
        };
        acc.push(newAddition);
      }
      return acc;
    }, []);
    return { ...e, additionCodeList: newAdditionCodeList };
  });
  formatNewDataWeekly.forEach((el) => {
    el.shortKasanNaiyouList = _.groupBy(el.shortKasanNaiyouList, 'serviceCd');
  });

  const formatNewDataWeeklyType = formatNewDataWeekly.map((e) => {
    const newShortKasanNaiyouList = [];

    for (const groupKey in e.shortKasanNaiyouList) {
      if (e.shortKasanNaiyouList.hasOwnProperty(groupKey)) {
        const groupedItems = e.shortKasanNaiyouList[groupKey];

        const reducedGroup = groupedItems.reduce((acc, curr) => {
          const existingAddition = acc.find(
            (el) => el.serviceCd === curr.serviceCd && el.dateInfo === curr.dateInfo
          );

          if (existingAddition) {
            existingAddition.youbiList.push(curr.youbi);
          } else {
            const newAddition = {
              ...curr,
              youbiList: [curr.youbi],
            };
            acc.push(newAddition);
          }

          return acc;
        }, []);
        newShortKasanNaiyouList.push(...reducedGroup);
      }
    }
    const newShortKasan = newShortKasanNaiyouList.flatMap(createObjectsByDateInfo);
    const formatNewShortKasanNaiyouList = _.groupBy(newShortKasan, 'serviceCd');
    return { ...e, shortKasanNaiyouList: formatNewShortKasanNaiyouList };
  });
  return { formatNewDataWeekly: formatNewDataWeeklyType };
}


function createObjectsByDateInfo(originalObject) {
  const result = [];
  const { dateStrInfo, youbiList, serviceCd } = originalObject;

  if (youbiList.length % dateStrInfo.length === 0) {
    const groupSize = youbiList.length / dateStrInfo.length;

    for (let i = 0; i < groupSize; i++) {
      const startIndex = i * dateStrInfo.length;
      const endIndex = startIndex + dateStrInfo.length;

      const newObject = {
        ...originalObject,
        youbiList: youbiList.slice(startIndex, endIndex),
        serviceCd: serviceCd
      };

      result.push(newObject);
    }
  }

  return result;
}

function handleMapDateQuote({ object, arr }) {
  for (let i = 0; i <= object.youbiList.length + 1; i++) {
    if (object.youbiList[i - 1] >= 0 && object.youbiList[i - 1] < arr.length) {
      arr[object.youbiList[i - 1] - 1] = +object.dateStrInfo[i - 1];
    }
  }
}

const handleCompareQuoteWeeklyData = ({ listDataService, resultListDataService, newDateQuote }) => {
  let resultListQuoteWeeklyFinal;
  let existingElement = [];
  if (newDateQuote.length > 0) {

    if (!listDataService.some((item) => item.sumListFPlan)) {
      newDateQuote.forEach((element) => {
        const existingEl = listDataService.find((el) =>
          el.officeName === element.officeName
        )
        if (existingEl) {
          existingElement.push(existingEl)
        }
      }
      );
      const mapIdExite = existingElement.map((item) => item.parentId)


      if (mapIdExite.length > 0) {
        const newListDataService = listDataService.filter((item) => !mapIdExite.includes(item.parentId));
        return resultListQuoteWeeklyFinal = [...newDateQuote, ...newListDataService];
      } else {
        return resultListQuoteWeeklyFinal = [...newDateQuote, ...listDataService];
      }
    }
    else {
      const newResouseListQuoteWeekly = mergeDataQuoteWeekly(listDataService, newDateQuote);
      resultListQuoteWeeklyFinal = newResouseListQuoteWeekly;
    }
    return resultListQuoteWeeklyFinal;
  }
  else {
    resultListQuoteWeeklyFinal = [...listDataService];
    return resultListQuoteWeeklyFinal
  }
};

function mergeDataQuoteWeekly(listDataService, newDateQuote) {
  let mergedItems = [];
  let additionExisted = [];
  let additionComing = [];
  let existingElData = [];
  newDateQuote.forEach((element) => {
    const existingEl = listDataService.find((el) =>
      el.serviceName === element.serviceName &&
      el.officeName === element.officeName &&
      el.offerTime === element.offerTime &&
      el.isParent &&
      element.isParent &&
      element.isQuoteActual && el.sumListFPlan && 
      (!RENTAL_TYPE.includes(element.mainCode.shuruiCode) && !RENTAL_TYPE.includes(el.mainCode.shuruiCode) ||
      (element.tani === el.dataRow.tani || element.tani === el.tani))
    )
    const existingElOFfice = listDataService.find((el) =>
        el.officeName === element.officeName &&
        el.isParent &&  element.isParent && 
        (el.serviceName !== element.serviceName || el.offerTime !== element.offerTime) && !existingElData.includes(el.id));
    if (existingEl) {
      existingElData.push(existingEl.id);
      additionExisted = existingEl.dataRow.additionList;
      additionComing = element.dataRow.additionList;
      const findChildExistedParent = listDataService.filter((el) => {
        if (!el.isParent && !isCheckGrayChildRecord(el) && el.parentId === existingEl.parentId) {
          return true;
        }
        if (isCheckGrayChildRecord(el)) {
          if (!el.parentIds && el.parentId === existingEl.parentId) {
            return true;
          } else if (el.parentIds && el.parentIds.includes(existingEl.parentId)) {
            return true;
          }
        }
        return false;
      });

      const findChildNewDataParent = newDateQuote.filter((el) => {
        if (!el.isParent && !isCheckGrayChildRecord(el) && el.parentId === element.parentId) {
          return true;
        }
        if (isCheckGrayChildRecord(el)) {
          if (!el.parentIds && el.parentId === element.parentId) {
            return true;
          } else if (el.parentIds && el.parentIds.includes(element.parentId)) {
            return true;
          }
        }
        return false;
      });
      listDataService.forEach((item)=>{
        if(!item.isParent && !isCheckGrayChildRecord(item)){
          if(findChildExistedParent.some((i)=>i.id === item.id)){
            item.listActual = item.listActual.map((e) => e* 0);
            item.sumListActual = sum(item.listActual) > 0 ? sum(item.listActual) : null;
            item.sumListFActual = sum(item.listActual) > 0 ? sum(item.listActual) : null;
          }
        }
        if(isCheckGrayChildRecord(item)){
          item.sumListFActual = null;
        }
      })
      
      findChildNewDataParent.forEach((item) => {
        const existingChildEl = findChildExistedParent.find((el) => {
          return el.serviceName === item.serviceName &&
          	el.officeName === item.officeName &&
          	!el.isParent &&
          	!item.isParent &&
          	!isCheckGrayChildRecord(item) &&
          	!isCheckGrayChildRecord(el)
        })
        if(existingChildEl){
          existingChildEl.listActual = item.listActual;
          existingChildEl.sumListActual = sum(existingChildEl.listActual) > 0 ? sum(existingChildEl.listActual) : item.sumListActual;
          existingChildEl.sumListFActual = sum(existingChildEl.listActual) > 0 ? sum(existingChildEl.listActual) : item.sumListFActual;
          existingChildEl.parentId = existingEl.parentId;
          existingChildEl.existedEl = false;
          newDateQuote = newDateQuote.filter((data) => data.id !== item.id);
        }else{
          item.parentId = existingEl.parentId;
        }
      }


      )
      existingEl.listActual = element.listActual
      existingEl.sumListActual = sum(existingEl.listActual) > 0 ? sum(existingEl.listActual) : element.sumListActual;
      existingEl.sumListFActual = sum(existingEl.listActual) > 0 ?  sum(existingEl.listActual) : element.sumListFActual;
      existingEl.existedEl = false;
      newDateQuote = newDateQuote.filter((data)=> data.id !== element.id);
    }
    if(existingElOFfice){
      const findChildExistedParent = listDataService.filter((el) => {
        if (!el.isParent && !isCheckGrayChildRecord(el) && el.parentId === existingElOFfice.parentId) {
          return true;
        }
        if (isCheckGrayChildRecord(el)) {
          if (!el.parentIds && el.parentId === existingElOFfice.parentId) {
            return true;
          } else if (el.parentIds && el.parentIds.includes(existingElOFfice.parentId)) {
            return true;
          }
        }
        return false;
      });
      const mapIdFromChild = [existingElOFfice,...findChildExistedParent].map((child) => child.id);
      existingElOFfice.listActual = existingElOFfice.listActual.map((day)=> day * 0);
      existingElOFfice.sumListActual = sum(existingElOFfice.listActual) > 0 ? sum(existingElOFfice.listActual) : null;
      existingElOFfice.sumListFActual =  existingElOFfice.sumListActual
      listDataService.forEach((item)=>{
        if(mapIdFromChild.includes(item.id)){
          item.listActual = item.listActual.map((day)=> day * 0);
          item.sumListActual = sum(item.listActual) > 0 ? sum(item.listActual) : null;
          item.sumListFActual =  item.sumListActual;
        }
      })
    }
  })
  listDataService = listDataService.filter((el) => el.sumListFPlan || el.sumListFActual);
  mergedItems = [...listDataService, ...newDateQuote];
  mergedItems.forEach((el)=>{
    if(isCheckGrayChildRecord(el) && el.serviceAddition && additionComing.includes(el.serviceAddition[0]) && additionExisted.includes(el.serviceAddition[0])){
    const findParent = mergedItems.find((element) => 
    el.parentId === element.parentId)

    if(findParent){
      if(findParent.sumListFPlan > 0){
        el.sumListFPlan = 1
      }
      if(findParent.sumListFActual > 0){
        el.sumListFActual = 1
      }
  }
}
  })
   mergedItems = _.sortBy(mergedItems, [
    (item) => (item.isParent ? 0 : item.dataRow.shuiruiCode),
    'jigyousyoId',
    'serviceCode'
  ]);
  
  return mergedItems;
}

const handleTypeMonth = ({ data, mode}) => {
 const result =  data.reduce((acc, obj) => {
    const existingObjIndex = acc.findIndex(
      (item) =>
        item.serviceCode === obj.serviceCode &&
        item.officeName === obj.officeName &&
        item.serviceFillDataList[0]?.additionName === "月1コード" &&
        obj.serviceFillDataList[0]?.additionName === "月1コード"
        && sum(item[mode]) > 0
        ,
    );
    const newIndex = obj[mode].findIndex((num) => num !== 0);

    if (newIndex !== -1 && (existingObjIndex === -1 || newIndex < acc[existingObjIndex][mode].findIndex((num) => num !== 0))) {
      if (existingObjIndex !== -1) {
        acc.splice(existingObjIndex, 1);
      }
      acc.push(obj);
    } else if (existingObjIndex === -1) {
      acc.push(obj);
    }
    return acc;
  }, []);
  return result;
}

const handleCheckType78 = (serviceMonthlyList)  =>  {
  let result = true;
  if(serviceMonthlyList[0].sisetuKubun === "2") {
    result = false
  }
  return result;
}

const handleChangeChildCode = ({ listDataNotParent, listExpandCodeNew }) => {
  const listExpandCodeClone = listExpandCodeNew.map((e) => e.filter((i) => i.serviceCode)).flatMap((e) => e);

  const whiteListNotParent = listExpandCodeClone.filter((e) => !isCheckGrayChildRecord(e));

  const greyListNotParent = _.unionBy(listExpandCodeClone.filter((e) => isCheckGrayChildRecord(e)), 'serviceCode');

  const sumListChildWhiteAndGrey = [...whiteListNotParent, ...greyListNotParent];

  const newServiceName = sumListChildWhiteAndGrey.map((el) => el.serviceName);

  const serviceNameExisted = listDataNotParent.map((el) => el.serviceName);

  const expandCodeFilter = listDataNotParent.filter((e) => !newServiceName.includes(e.serviceName));

  const expandCodeExisted = sumListChildWhiteAndGrey.filter((e) => !serviceNameExisted.includes(e.serviceName));

  for (let i = 0; i < expandCodeFilter.length; i++) {
    for (const key in expandCodeFilter[i]) {
      if (expandCodeFilter[i].hasOwnProperty(key) && expandCodeExisted[i] && expandCodeExisted[i].hasOwnProperty(key)) {
        expandCodeFilter[i][key] = expandCodeExisted[i][key];
      }
    }
  }
}

const handleType13Teki = (newDateQuote, e) => {
  newDateQuote.forEach((el) => {
    if ((el?.dataRow?.shuiruiCode?.includes("13") || el?.dataRow?.shuruiCode?.includes("13")) 
    && el?.dataRow?.serviceContent?.includes("定期巡回訪看") 
    && el?.dataRow?.additionList?.includes("日割り")) {
      if (el.isParent) {
        el.listActual = el.listActual.map((day) => day * 0);
        el.sumListActual = e.mainCode.countType13;
        el.sumListFActual = e.mainCode.countType13;
      } else {
        el.listActual = el.listActual.map((day) => day * 0);
      }
    }
  })
}

const isCheckComeAndGo = ({ element }) => {
  return element?.serviceFillDataList?.length > 1 && element?.serviceFillDataList[0]?.additionName.includes("迎加算") && element?.serviceFillDataList[1]?.additionName.includes("送加算"); 
}

const isCheckComeOrGo = ({ element }) => {
  return element?.serviceFillDataList?.length > 0 && (element?.serviceFillDataList[0]?.additionName.includes("迎加算") || element?.serviceFillDataList[0]?.additionName.includes("送加算")); 
}

function filterOfficeNameDuplicate(arr) {
  const uniqueServiceCodes = [];
  const duplicateServiceCodes = [];

  arr.forEach((item) => {
    if (!uniqueServiceCodes.includes(item.serviceCode)) {
      uniqueServiceCodes.push(item.serviceCode);
    } else if (!duplicateServiceCodes.includes(item.serviceCode)) {
      duplicateServiceCodes.push(item.serviceCode);
    }
  });

  const filteredArr = arr.filter((item) => {
    if (!duplicateServiceCodes.includes(item.serviceCode)) {
      return true;
    }
    return !item.is30DayOver;
  });

  return filteredArr;
}


const calculateAttachedType21 = async (mode, listDataService, kubunsikyu, riyousyaId, kyufu, timeString, nengetu) => {
  const greyBlockList = [];
  const whiteBlockList = [];

  //call api for 15.1 case
  const kyufuData = await RequestUtils.callApi({
    method: "GET",
    path: `${ApiPaths.UseSlip.GetKyufuAttachedTable}?riyousyaId=${riyousyaId}&nengetu=${nengetu}`,
  });

  const idList = listDataService.map((e) => e.idService);
  const attachedTableCal = await RequestUtils.callApi({
    method: "POST",
    path: `${ApiPaths.UseSlip.GetCalculation}`,
    body: {
      idList: idList,
    },
  });

  attachedTableCal.sort((a, b) => {
    return parseInt(a.kaigoMasterId, 10) - parseInt(b.kaigoMasterId, 10);
  });

  const obj = attachedTableCal.reduce((acc, curr) => {
    acc[curr.kaigoMasterId] = curr;
    return acc;
  }, {});

  const sortNewListDataService = _.sortBy(listDataService, "idService");
  const converObjectList = Object.keys(obj);
  const result = converObjectList.filter((item) => !sortNewListDataService.some((x) => x.idService === Number(item)));
  const resultObject = sortNewListDataService.filter((item) => !converObjectList.includes(item.idService + ""));

  sortNewListDataService.forEach((e, i) => {
    if (resultObject.length > 0) {
      resultObject.forEach((element, index) => {
        if (result.length > 0 && resultObject.length > 0 && e.idService === element.idService) {
          let attachedTableCalculation =
            obj[result[index]]?.attachedTableCalculation.length > 1 ? obj[result[index]]?.attachedTableCalculation[1] : null;
          const subject = {
            subject1: attachedTableCalculation?.subject1 ?? {},
            subject2: attachedTableCalculation?.subject2 ?? {},
            priority: attachedTableCalculation?.priority ?? null,
          };

          if (
            obj[result[index]]?.attachedTableCalculation[0]?.additionName === "限度額外" ||
            obj[result[index]]?.attachedTableCalculation[0]?.additionName === "サービス提供体制"
          )
            greyBlockList.push({
              ...e,
              color: "GREY",
              ...subject,
            });
          else
            whiteBlockList.push({
              ...e,
              color: "WHITE",
              ...subject,
            });
        } else {
          let attached = obj[e.idService]?.attachedTableCalculation.length > 1 ? obj[e.idService]?.attachedTableCalculation[1] : null;
          const subject = {
            subject1: attached?.subject1 ?? {},
            subject2: attached?.subject2 ?? {},
            priority: attached?.priority ?? null,
          };

          if (
            obj[e.idService]?.attachedTableCalculation[0]?.additionName === "限度額外" ||
            obj[e.idService]?.attachedTableCalculation[0]?.additionName === "サービス提供体制"
          )
            greyBlockList.push({
              ...e,
              color: "GREY",
              ...subject,
            });
          else
            whiteBlockList.push({
              ...e,
              color: "WHITE",
              ...subject,
            });
        }
      });
    } else {
      let attached = obj[e.idService]?.attachedTableCalculation.length > 1 ? obj[e.idService]?.attachedTableCalculation[1] : null;
      const subject = {
        subject1: attached?.subject1 ?? {},
        subject2: attached?.subject2 ?? {},
        priority: attached?.priority ?? null,
      };

      if (
        obj[e.idService]?.attachedTableCalculation[0]?.additionName === "限度額外" ||
        obj[e.idService]?.attachedTableCalculation[0]?.additionName === "サービス提供体制"
      )
        greyBlockList.push({
          ...e,
          color: "GREY",
          ...subject,
        });
      else
        whiteBlockList.push({
          ...e,
          color: "WHITE",
          ...subject,
        });
    }
  });

  const groupFilterArr = _.groupBy(whiteBlockList, (e) => {
    return [e.id];
  });

  //8
  const whiteBlockListAfterSum = Object.keys(groupFilterArr).map((e) => groupFilterArr[e]);
  whiteBlockListAfterSum.forEach((e) => {
    if (e.length > 1) {
      const sumWithInitial = e.reduce((accumulator, currentValue) => {
        return mode ? accumulator + currentValue.sumListFPlan : accumulator + currentValue.sumListFActual;
      }, 0);
      mode ? (e[0].sumListFPlan = sumWithInitial) : (e[0].sumListFActual = sumWithInitial);
      e.splice(1, e.length);
    }
  });

  const whiteBlockListSorted = _.sortBy(
    whiteBlockListAfterSum.flatMap((e) => e),
    (e) => {
      return [e.is30DayOver];
    },
  );

  const greyBlockListSorted = _.sortBy(greyBlockList, (e) => {
    return [e.id];
  });
  let greyBlockListUnique = [];

  greyBlockListSorted.forEach((obj) => {
    let index = greyBlockListUnique.findIndex((item) => item.serviceCode === obj.serviceCode && item.officeName === obj.officeName);
    if (index !== -1) {
      greyBlockListUnique[index].sumListFPlan += obj.sumListFPlan;
      greyBlockListUnique[index].sumListFActual += obj.sumListFActual;
      greyBlockListUnique[index].is30DayOver = obj.is30DayOver ? obj.is30DayOver : true;
    } else {
      greyBlockListUnique.push({
        ...obj,
        serviceCode: obj.serviceCode,
        sumListFPlan: obj.sumListFPlan,
        sumListFActual: obj.sumListFActual,
        is30DayOver: obj.is30DayOver ? obj.is30DayOver : true,
      });
    }
  });

  //push sub-total
  const whiteBlockListGroup = _.groupBy(whiteBlockListSorted, (e) => {
    return e.is30DayOver;
  });
  const dataGroup = Object.keys(whiteBlockListGroup).map((e) => [...whiteBlockListGroup[e], { color: "BLUE" }]);
  dataGroup.push(greyBlockListUnique);

  const tableData = dataGroup.flatMap((e) => e);

  const subTotal = {
    unitPrice: 0,
    benefitUnit: 0,
  };

  const calUnit = (e) => {
    if (e.isParent) {
      if (+e.dataRow.shuiruiCode === 17) return e.dataRow.tani ? +e.dataRow.tani : e.mainCode.tani;
      return +e.mainCode.tani;
    } else {
      let result = e.tani;
      if (e.serviceFillDataList && e.serviceFillDataList[0]?.additionName === "計算コード" && !e.sikyugendogakuKubun) result = `${result}`;
      return result;
    }
  };

  const checkShuiruiCode = (shuiruiCode) => {
    let kyufu = 0;
    kyufuData.forEach((e) => {
      if (e.serviceSyurui.includes(shuiruiCode) && e.kyufu > kyufu) kyufu = e.kyufu;
    });
    return kyufu;
  };

  const dataAttached = tableData
    .filter((e) => e.serviceCode || e.color)
    .map((e) => {
      if (e.color === "BLUE") {
        const result = { ...subTotal, color: "BLUE" };
        subTotal.unitPrice = 0;
        subTotal.benefitUnit = 0;
        return result;
      } else {
        const discount = [0, 100].includes(e.discount) ? null : e.discount;
        const sumListPlan = mode ? e.sumListFPlan : e.sumListFActual;
        const unit = calUnit(e);
        const discountUnit = discount && Math.round((unit * discount) / 100);
        const unitPrice = discount
          ? discountUnit * sumListPlan
          : e.color === "GREY" && e.is30DayOver && sumListPlan
          ? unit * sumListPlan - unit
          : unit * sumListPlan;
        const kyufukanriTani = e.isParent ? e.mainCode.kyufukanriTani : e.kyufukanriTani;
        const benefitUnit = kyufukanriTani ? kyufukanriTani * sumListPlan : unitPrice;
        const unitCost = (+e.price).toFixed(2);
        const exceedingValue = unit;
        const result = {
          dataRow: e.dataRow,
          additionTime: e.additionTime ? e.additionTime : null,
          color: e.color,
          parentId: e.parentId + "",
          subject1: e?.subject1,
          subject2: e?.subject2,
          priority: e?.priority,
          idService: e.idService,
          officeName: e.officeName, //1
          officeNo: e.dataRow.jigyousyoNoServiceKikan, //2
          serviceContent: e.serviceName, //3
          serviceCode: e.serviceCode, //4
          unit, //5
          discountRate: discount, //6
          exceedingValue: e.color === "GREY" ? exceedingValue : null,
          discountUnit, //7
          sumListPlan, //8
          unitPrice, //9
          kyufukanriTani: kyufukanriTani,
          kasanKanouCodeMap: e.kasanKanouCodeMap,
          isParent: e.isParent,
          taniSikibetu: e.taniSikibetu,
          santeiTani: e.santeiTani,
          sisetukubun: e.sisetukubun,
          terminal: e.terminal,
          hiwariSikibetu: e.hiwariSikibetu,
          serviceTeikyonaiyo: e.serviceTeikyonaiyo,
          serviceTeikyoKyoka: e.serviceTeikyoKyoka,
          kikanJiki: e.kikanJiki,
          sogei: e.sogei,
          ryoyoshoku: e.ryoyoshoku,
          serviceRiyoJoken: e.serviceRiyoJoken,
          nintishoSenmoncare: e.nintishoSenmoncare,
          sikyugendogakuKubun: e.sikyugendogakuKubun,
          shuiruiCode: e.dataRow.shuiruiCode,
          discount: e.discount,
          price: e.price,
          serviceKikanMasterId: e.serviceKikanMasterId,
          is30DayOver: e.is30DayOver,
          serviceFillDataList: e.serviceFillDataList,
          serviceId: e.serviceId,
          id: e.id,
        };

        switch (e.color) {
          case "GREY":
            result.price = e.price;
            result.discount = e.discount;
            result.unitCost = !!unitCost ? unitCost : null;
            result.totalCost = !!unitCost
              ? unitPrice < 0
                ? Math.floor((unitPrice * -1 * unitCost).toFixed(2)) * -1
                : Math.floor((unitPrice * unitCost).toFixed(2))
              : null;
            result.benefit = !timeString?.naiyou?.includes("含む")
              ? kyufu
              : Math.max(checkShuiruiCode(e.dataRow.shuiruiCode), kyufu); //15
            result.benefitUnitGrey = benefitUnit;
            result.externalSalary = 0;
            result.benefitValue =
              +(Math.abs(unitPrice * unitCost) % 1).toFixed(12) === 0.999999999999
                ? parseInt(((Math.round(unitPrice * unitCost * 100) / 100) * kyufu) / 100)
                : parseInt((parseInt(unitPrice * unitCost) * kyufu) / 100);
            result.burden =
              (+(Math.abs(unitPrice * unitCost) % 1).toFixed(12) === 0.999999999999
                ? Math.round(unitPrice * unitCost * 100) / 100
                : parseInt(unitPrice * unitCost)) - result.benefitValue;

            result.sumListPlan = sumListPlan;
            result.listPlan = e.listPlan;
            result.is30DayOver = e.is30DayOver;
            result.dataRow = e.dataRow;
            result.kohiNo = checkKohiNo({element: e, kyufuUser: kyufuData, kyufuHoken: kyufu}).kohiNo;
            result.kohiPercent = checkKohiNo({element: e, kyufuUser: kyufuData, kyufuHoken: kyufu}).percentKohi;
            break;
          case "WHITE":
            result.price = e.price;
            result.discount = e.discount;
            result.benefitUnit = benefitUnit;
            result.offerTime = e.offerTime;
            result.listPlan = e.listPlan;
            result.sumListPlan = sumListPlan;
            result.is30DayOver = e.is30DayOver;
            result.dataRow = e.dataRow;
            break;
          default:
            break;
        }

        if (e.isParent) {
          subTotal.sisetukubun = e.sisetukubun;
          subTotal.idService = e.idService;
        }

        subTotal.officeName = e.officeName;
        subTotal.id = e.id;
        subTotal.price = e.price;
        subTotal.discount = e.discount;
        subTotal.shuiruiCode = e.dataRow.shuiruiCode;
        subTotal.serviceTeikyoKyoka = e.serviceTeikyoKyoka;
        subTotal.officeNo = e.dataRow.jigyousyoNoServiceKikan;
        subTotal.kyufukanriTani = e.mainCode.kyufukanriTani;
        subTotal.dataRow = e.dataRow;
        subTotal.serviceContent = e.is30DayOver ? `【30日越】 ${e.dataRow.serviceContentType || ""}　小計` : `${e.dataRow.serviceContentType || ""}　小計`;
        subTotal.unitPrice += unitPrice; //9
        subTotal.remainValue = e.is30DayOver ? null : subTotal.unitPrice; //12
        subTotal.benefitUnit += benefitUnit; //10
        subTotal.exceedingValue = e.is30DayOver ? subTotal.benefitUnit : null;
        subTotal.unitCost = !!unitCost ? unitCost : null; //13
        subTotal.totalCost = !!unitCost && !e.is30DayOver ? Math.floor((subTotal.unitPrice * subTotal.unitCost).toFixed(2)) : null; //14 ///////////
        subTotal.benefit = !timeString?.naiyou?.includes("含む")
          ? kyufu
          : Math.max(checkShuiruiCode(e.dataRow.shuiruiCode), kyufu); //15
        subTotal.benefitValue = !e.is30DayOver
          ? Math.floor((Math.floor((subTotal.remainValue * subTotal.unitCost).toFixed(2)) * kyufu) / 100)
          : null;
        subTotal.burden = e.is30DayOver ? null : Math.floor((subTotal.remainValue * subTotal.unitCost).toFixed(2)) - subTotal.benefitValue;
        subTotal.externalSalary = 0;
        subTotal.parentId = e.parentId;
        subTotal.is30DayOver = e.is30DayOver;
        subTotal.kohiNo = checkKohiNo({element: e, kyufuUser: kyufuData, kyufuHoken: kyufu}).kohiNo;
        subTotal.kohiPercent = checkKohiNo({element: e, kyufuUser: kyufuData, kyufuHoken: kyufu}).percentKohi;
        return result;
      }
    });

  // lấy ra những thằng cần sửa
  const overLimitCodeList = dataAttached.filter((e) => typeof e.unit === "string");
  const limitCodeList = dataAttached.filter((e) => typeof e.unit !== "string");
  const sortOverLimitCodeList = _.sortBy(overLimitCodeList, "priority");
  sortOverLimitCodeList.forEach((e) => {
    let lastUnit = 0;
    const target = [];
    const percent = ["08", "09"].includes(e.taniSikibetu) ? +Number.parseFloat(+e.unit / 1000).toFixed(3) : +e.unit / 100;

    const filterOfficeName = tableData.filter((record) => record.officeName === e.officeName && record.priority < e.priority);

    const filterOfficeNameFilter = filterOfficeNameDuplicate(filterOfficeName);

    filterOfficeNameFilter.forEach((element, index) => {
      let condition = false;
      const compareCode = element.kasanKanouCodeMap ? Object.values(element.kasanKanouCodeMap).includes(e.serviceCode) : false;
      const blueIndex = dataAttached.findIndex((obj) => obj.color === "BLUE");

      const filteredObjects = dataAttached.filter(
        (obj) => obj.color === "GREY" && obj.serviceFillDataList.length === 0 && obj.sikyugendogakuKubun === null,
      );

      const uniqueFilteredObjects = dataAttached.filter((obj) => !filteredObjects.includes(obj));
      const rearrangedArray = uniqueFilteredObjects.flatMap((obj, index) => (index === blueIndex ? [obj, ...filteredObjects] : obj));

      const benefitUnitObject = rearrangedArray.filter(
        (data) => data.color !== "BLUE" && data.officeName === e.officeName && e.priority > data.priority,
      )[index];
      const benefitUnit = benefitUnitObject.unitPrice;
      if (Object.keys(e.subject1).length) {
        Object.keys(e.subject1).forEach((key) => {
          if (e.subject1[key].includes(element[key]) && !condition) condition = true;
        });
        if (Object.keys(e.subject2).length) {
          const obj = {};
          Object.keys(e.subject2).forEach((key) => {
            obj[key] = `${element[key]}`;
          });
          condition = _.isEqual(e.subject2, obj) ? true : false;
          if (condition || compareCode) {
            lastUnit += benefitUnit;
            target.push(element.id);
          }
        } else {
          // chi co subject 1
          if (condition || compareCode) {
            lastUnit += benefitUnit;
            target.push(element.id);
          }
        }
      } else {
        const obj = {};
        Object.keys(e.subject2).forEach((key) => {
          obj[key] = `${element[key]}`;
        });
        condition = _.isEqual(e.subject2, obj) ? true : false;
        if (condition) {
          lastUnit += benefitUnit;
          target.push(element.id);
        }
      }
    });
    const unit = Math.round(lastUnit * percent);
    const discountUnit = e.discountRate && Math.round((unit * e.discountRate) / 100);
    const unitPrice = e.discountRate ? discountUnit * e.sumListPlan : unit * e.sumListPlan;
    const remainValue = unitPrice;
    const benefitUnit = e.color === "WHITE" && (e.kyufukanriTani ?? unitPrice);
    const subTotal = dataAttached.find((subTotal) => e.officeName === subTotal.officeName && subTotal.color === "BLUE");
    let sumExceedingValue = 0;
    let remainValueBlue = 0;
    let multipValueTeikyo = 0;
    let unitPriceTeikyo = 0;
    let unitPrice30days = 0;
    limitCodeList.forEach((el) => {
      sumExceedingValue += el.exceedingValue;
      if (el.color === "BLUE" && el.remainValue) remainValueBlue += el.remainValue;
      if (!el.sikyugendogakuKubun && !SPECIAL_TANISIKIBETU.includes(el.taniSikibetu) && el.color === "GREY") {
        multipValueTeikyo += el.sumListPlan * el.unit;
        unitPriceTeikyo += el.unitPrice;
      }
      if (el.is30DayOver && el.unitPrice && el.serviceContent.includes("30日越")) unitPrice30days += el.unitPrice;
    });
    // const exceedingValue = Math.round(sumExceedingValue * percent);
    // #79479
    const exceedingValue =
      Math.round((remainValueBlue + multipValueTeikyo + unitPrice30days) * percent) -  Math.round((remainValueBlue + unitPriceTeikyo) * percent); 
    const unitCost = e.color !== "WHITE" && (+e.price).toFixed(2);
    if (e.color === "WHITE") {
      subTotal.discountUnit = discountUnit && subTotal.discountUnit && subTotal.discountUnit - e.discountUnit + discountUnit;
      subTotal.unitPrice = subTotal.unitPrice - e.unitPrice + unitPrice;
      subTotal.benefitUnit = subTotal.benefitUnit - e.benefitUnit + benefitUnit;
      subTotal.remainValue = subTotal.remainValue - e.unit + remainValue;
      subTotal.totalCost = Math.floor(subTotal.unitPrice * subTotal.unitCost);
      subTotal.benefitValue = Math.floor((Math.floor(subTotal.remainValue * subTotal.unitCost) * kyufu) / 100);
      subTotal.burden = Math.floor(subTotal.remainValue * subTotal.unitCost) - subTotal.benefitValue;
    }
    e.exceedingValue = exceedingValue;
    e.unit = unit;
    e.prevUnit = unit;
    e.unitPrice = unit * e.sumListPlan;
    e.unitCost = !!unitCost ? unitCost : null;
    e.benefitUnit = benefitUnit;
    e.percent = percent;
    e.target = target;
    e.totalCost = e.color === "GREY" && !!unitCost ? Math.floor(e.unitPrice * e.price) : null;
    e.discountUnit = discountUnit && discountUnit;
    e.benefitValue = e.color === "GREY" && Math.floor((Math.floor(e.unitPrice * e.unitCost) * kyufu) / 100);
    e.burden = e.color === "GREY" && Math.floor(e.unitPrice * e.unitCost) - e.benefitValue;
  });
  let smallTotal = {
    totalUnitPrice: 0,
    totalBenefitUnit: 0,
    totalBurden: 0,
    totalExternalSalary: 0,
    totalCase19: 0
  };
  dataAttached.forEach((e) => {
    if(e.is30DayOver && e.color === "BLUE"){
      e.benefitUnit = null;
    }
    if(e.color !== "WHITE"){
      e.externalSalary = e.exceedingValue * e.unitCost > 0 ? Math.floor(e.exceedingValue * e.unitCost) : 0;
    }else{
      e.externalSalary = null;
    }
    e.benefitUnitClone = e.benefitUnit;
    e.unitPriceClone = e.unitPrice;
    e.exceedingValuePrev = e.exceedingValue;
    e.prevUnit = e.unit;
    e.remainValuePrev = e.remainValue;
    e.benefitValuePrev = e.benefitValue;
    e.externalSalaryPrev = e.externalSalary;

    if (e.color === "GREY" && e.is30DayOver) {
      e.externalSalary = Math.floor(e.exceedingValue * e.unitCost);
    }
});
  const newDataAttached = await Promise.all(dataAttached.map(async (e) => {
    await calculateFutan({ element: e, kyufuHoken: kyufu, timeString });
    return e;
  }));
  
  newDataAttached.forEach((e) => {
    if (e.color === "BLUE" && !e.is30DayOver) {
      smallTotal.totalUnitPrice += e.unitPrice;
      smallTotal.totalBenefitUnit += e.benefitUnit;
    }
    if(e.is30DayOver && e.color === "BLUE"){
      e.benefitUnit = null;
    }
    if(e.color !== "WHITE"){
      e.externalSalary = e.exceedingValue * e.unitCost > 0 ? Math.floor(e.exceedingValue * e.unitCost) : 0;
    }else{
      e.externalSalary = null;
    }
    e.benefitUnitClone = e.benefitUnit;
    e.unitPriceClone = e.unitPrice;
    e.exceedingValuePrev = e.exceedingValue;
    e.prevUnit = e.unit;
    e.remainValuePrev = e.remainValue;
    e.benefitValuePrev = e.benefitValue;
    e.externalSalaryPrev = e.externalSalary;

    if (e.color === "GREY" && e.is30DayOver) {
      e.externalSalary = Math.floor(e.exceedingValue * e.unitCost);
    }
});

  // Tìm vị trí của object đầu tiên có thuộc tính color là "BLUE"
  const blueIndex = newDataAttached.findIndex((obj) => obj.color === "BLUE");

  const filteredObjects = newDataAttached.filter(
    (obj) => obj.color === "GREY" && obj.serviceFillDataList.length === 0 && obj.sikyugendogakuKubun === null,
  );

  const uniqueFilteredObjects = newDataAttached.filter((obj) => !filteredObjects.includes(obj));

  const rearrangedArray = uniqueFilteredObjects.flatMap((obj, index) => (index === blueIndex ? [obj, ...filteredObjects] : obj));
  newDataAttached.forEach(e=>{
    e.benefitUnitClone = e.benefitUnit;
    e.unitPriceClone = e.unitPrice;
    e.exceedingValuePrev = e.exceedingValue;
    e.prevUnit = e.unit;
    e.remainValuePrev = e.remainValue;
    e.benefitValuePrev = e.benefitValue;
    e.externalSalaryPrev = e.externalSalary;
    smallTotal.totalBurden += (e.color === "BLUE" || e.color === "GREY") && e.burden;
    smallTotal.totalExternalSalary += e.externalSalary && e.externalSalary;
  })
  smallTotal.totalAAAAA = smallTotal.totalBurden + smallTotal.totalExternalSalary ?? null;
  smallTotal.case19 = kubunsikyu - smallTotal.totalBenefitUnit;
  smallTotal.totalCase19 = kubunsikyu - smallTotal.totalBenefitUnit;
  const dataAttachedFilter = rearrangedArray.filter((e) => e.unitPrice !== 0);

  return { dataAttached: dataAttachedFilter, smallTotal };
};

const handleCheckArrayOver30Days = ({ listDataService }) => {
  let is30DayOverTrueArray = [];
  let is30DayOverFalseArray = [];
  listDataService.forEach((item) => {
    let foundMatch = false;
    for (let i = 0; i < listDataService.length; i++) {
      if (
        item.officeName === listDataService[i].officeName &&
        listDataService[i].is30DayOver === true
      ) {
        is30DayOverTrueArray.push(item);
        foundMatch = true;
        break;
      }
    }
    if (!foundMatch) {
      is30DayOverFalseArray.push(item);
    }
  });
  return {
    is30DayOverTrueArray,
    is30DayOverFalseArray
  }
}

const isCheckMonthlyType21 = ({element}) =>{
  return KAIGO_CODE_SHORT_TYPE.includes(element.dataRow.shuiruiCode) && element?.serviceFillDataList && element?.serviceFillDataList[0]?.additionName.includes("月1コード");
}

const checkKohiNo = ({ element, kyufuUser, kyufuHoken }) => {
  const uniqueKyufuHoken = _.uniqBy(kyufuUser, 'kohiNo');  
  const serviceType = element.serviceCode.substring(0, 2);

  const filteredUsers = uniqueKyufuHoken.filter(user =>
    user.serviceSyurui.includes(serviceType) && user.kyufu > kyufuHoken
  );
  const maxResults = 3;
  const resultsKyufu = filteredUsers.slice(0, Math.min(filteredUsers.length, maxResults));
  
  const kohiNo = resultsKyufu.map(user => user.kohiNo);
  const percentKohi = resultsKyufu.map(user => user.kyufu);

  return { kohiNo: kohiNo.join(','), percentKohi: percentKohi.join(',') };
}

const calculateFutan = async ({ element, kyufuHoken, timeString }) => {
  const { unitPrice = 0, remainValue = 0, unitCost = 0, kohiPercent } = element;
  const kohiPercentArray = (kohiPercent || '0,0,0').split(",").map(Number);
  const [kohiPercent1, kohiPercent2, kohiPercent3] = kohiPercentArray;
  const percentHoken = kyufuHoken;
  let kohiFutan1 = 0;
  let kohiFutan2 = 0;
  let kohiFutan3 = 0;

  const calculateKohiBlue = (percent) => {
    return roundDecimal(roundDecimal(remainValue * unitCost) * (percent / 100)) - roundDecimal(roundDecimal(remainValue * unitCost) * (percentHoken / 100));
  };

  const calculateKohiGrey = (percent) => {
    return parseInt(parseInt((unitPrice) * unitCost) * (percent / 100)) - parseInt(parseInt((unitPrice) * unitCost) * (percentHoken / 100));
  };

  const calculateKohiFutanBlue = (percent, previousFutan) => {
    return roundDecimal(roundDecimal(remainValue * unitCost) * ((percent - percentHoken) / 100)) - previousFutan;
  };

  const calculateKohiFutanGrey = (percent, previousFutan) => {
    return parseInt(parseInt((unitPrice) * unitCost) * ((percent - percentHoken) / 100)) - previousFutan;
  };

  const benefitAmountBlue = roundDecimal(roundDecimal(remainValue * unitCost) * (percentHoken / 100));
  const benefitAmountGrey = parseInt(parseInt((unitPrice) * unitCost) * (percentHoken / 100));

  if (element.color === "BLUE" && !element.is30DayOver) {
    if (+percentHoken < 100) {
      if (kohiPercent1 > 0) {
        if (kohiPercent1 !== 100) {
          kohiFutan1 = calculateKohiFutanBlue(kohiPercent1, 0);
        } else {
          kohiFutan1 = calculateKohiBlue(kohiPercent1);
        }
      } else {
        kohiFutan1 = 0;
      }

      if (kohiPercent2 > 0) {
        if (kohiPercent1 === 100) {
          kohiFutan2 = 0;
        } else {
          if (kohiPercent2 !== 100) {
            kohiFutan2 = calculateKohiFutanBlue(kohiPercent2, kohiFutan1);
          } else {
            kohiFutan2 = roundDecimal(remainValue * unitCost) - benefitAmountBlue - kohiFutan1;
          }
        }
      } else {
        kohiFutan2 = 0;
      }

      if (kohiPercent3 > 0) {
        if (kohiPercent2 === 100) {
          kohiFutan3 = 0;
        } else {
          kohiFutan3 = roundDecimal(remainValue * unitCost) - benefitAmountBlue - kohiFutan1 - kohiFutan2;
        }
      } else {
        kohiFutan3 = 0;
      }
    }
    element.benefitAmount = benefitAmountBlue;
    element.kohiFutan1 = kohiFutan1;
    element.kohiFutan2 = kohiFutan2;
    element.kohiFutan3 = kohiFutan3;
    element.benefitValue = roundDecimal(roundDecimal(element.remainValue * element.unitCost) * (percentHoken / 100));
    element.benefitValue = timeString?.naiyou?.includes("含む") ? element.benefitValue + kohiFutan1 + kohiFutan2 + kohiFutan3 : element.benefitValue;
    element.burden = roundDecimal(element.remainValue * element.unitCost) - element.benefitValue;
  } else if (element.color === "GREY") {
    if (+percentHoken < 100) {
      if (kohiPercent1 > 0) {
        if (kohiPercent1 !== 100) {
          kohiFutan1 = calculateKohiFutanGrey(kohiPercent1, 0);
        } else {
          kohiFutan1 = calculateKohiGrey(kohiPercent1);
        }
      } else {
        kohiFutan1 = 0;
      }

      if (kohiPercent2 > 0) {
        if (kohiPercent1 === 100) {
          kohiFutan2 = 0;
        } else {
          if (kohiPercent2 !== 100) {
            kohiFutan2 = calculateKohiFutanGrey(kohiPercent2, kohiFutan1);
          } else {
            kohiFutan2 = parseInt((unitPrice) * unitCost) - benefitAmountGrey - kohiFutan1;
          }
        }
      } else {
        kohiFutan2 = 0;
      }

      if (kohiPercent3 > 0) {
        if (kohiPercent2 === 100) {
          kohiFutan3 = 0;
        } else {
          kohiFutan3 = parseInt((unitPrice) * unitCost) - benefitAmountGrey - kohiFutan1 - kohiFutan2;
        }
      } else {
        kohiFutan3 = 0;
      }
    }
    element.benefitAmount = benefitAmountGrey;
    element.kohiFutan1 = kohiFutan1;
    element.kohiFutan2 = kohiFutan2;
    element.kohiFutan3 = kohiFutan3;
    element.benefitValue = parseInt((parseInt((!element.is30DayOver ? (element.unitPriceClone - (element.exceedingValue ?? 0)) : element.unitPrice) * element.unitCost) * (percentHoken / 100)));
    element.benefitValue = timeString?.naiyou?.includes("含む") ? element.benefitValue + kohiFutan1 + kohiFutan2 + kohiFutan3 : element.benefitValue;
    element.burden = parseInt(parseInt(!element.is30DayOver ? (element.unitPriceClone - (element.exceedingValue ?? 0)) : element.unitPrice) * element.unitCost) - element.benefitValue;
  }
};

async function calculateAndProcess(dataAttachedFull, props, timeString) {
  await Promise.all(dataAttachedFull.map(async (e) => {
      await calculateFutan({ element: e, kyufuHoken: props.riyouhyouForm.hokenSelected?.kyufu, timeString });
  }));

}

const handleUpdateDataAttached = ({ dataAttached, item }) => {
  dataAttached.forEach((e) => {
    const condition = (e.color === "BLUE" || e.color === "GREY")
      && item.tani == e.unitPrice
      && item.serviceKikanMaster.ryaku === e.officeName;

    const bothValuesExistAndGreaterThanZero = e.exceedingValue && item.kubunGendoOver && e.exceedingValue > 0 && item.kubunGendoOver > 0;

    if (bothValuesExistAndGreaterThanZero ? item.kubunGendoOver === e.exceedingValue : true) {
      if (condition) {
        e.benefit = item.kyufu;
        e.benefitValue = item.kyufuGaku;
        e.burden = item.futanGendoNai;
        e.externalSalary = item.futanGendoOver;
      }
    }
  });
};

const checkShuiruiCode = (shuiruiCode, kyufuData) => {
  let kyufu = 0;
  kyufuData.forEach((e) => {
    if (e.serviceSyurui.includes(shuiruiCode) && e.kyufu > kyufu) kyufu = e.kyufu;
  });
  return kyufu;
};

const calculateSmallTotal = ({ smallTotal, dataAttached, kubunsikyu }) => {
  const { totalBurden, totalExternalSalary, totalAAAAA, totalExceedingValue, totalUnitPrice, totalBenefitUnit } = calTotalTable(dataAttached);
      smallTotal.totalBurden = totalBurden;
      smallTotal.totalExternalSalary = totalExternalSalary;
      smallTotal.totalUnitPrice = totalUnitPrice - totalExceedingValue;
      smallTotal.totalBenefitUnit = totalBenefitUnit - totalExceedingValue;
      smallTotal.case19 = smallTotal.totalBenefitUnit === kubunsikyu ? 0 : smallTotal.case19;
      smallTotal.totalAAAAA = totalAAAAA;
}

const handleDataKyufu = async ({ dataAttached, riyouhyouForm }) => {
  const data = await RequestUtils.callApi({
    method: "GET",
    path: `${ApiPaths.UseSlip.GetKyufuAttachedTable}?riyousyaId=${riyouhyouForm.riyousyaId}&nengetu=${riyouhyouForm.nengetu}`,
  });
  const blueRecordKyufu = (dataAttached || []).filter((e) => e.color === "BLUE" && !e.is30DayOver).map((e) => e.kohiNo);
  const filteredKohiNo = [...new Set(blueRecordKyufu.flatMap(numbers =>
    numbers.split(',').map(number =>
      data.filter(obj => obj.kohiNo === number && obj.kyufu > (riyouhyouForm?.hokenSelected?.kyufu ?? riyouhyouForm?.riyouKaigohoken?.kyufu))
    )
  ).flat())];
  const sortedArrayFilter = _.uniqBy(filteredKohiNo,'kohiNo').sort((a, b) => a.yusenNum - b.yusenNum).slice(0, 3);

  return _.uniqBy(sortedArrayFilter,'kohiNo');
}

export {
  fillListData,
  fillListValueDay,
  fillValueFollowMainCode,
  sumPlan,
  listFirstLastDay,
  offerTimeFilter,
  generateUuid,
  createListExpandCode,
  calListDataShow,
  setFilter,
  sortNoParent,
  checkAndRefillBaseOnType21,
  filterMonthlyExpandCode,
  sumTowArr,
  isCheckGrayChildRecord,
  handleMapSumListOfChildGrayByServiceCode,
  handleSumListPlan,
  handleSumListFPlan,
  handleSumListActual,
  handleSumListFActual,
  handleCalListAndSum,
  handleGroupParentIds,
  handleSumListPlanParent,
  handleSumListFPlanParent,
  handleDeleteRecordIsEmptyParentIds,
  handleSumListActualParent,
  handleSumListFActualParent,
  getDateOfMonth,
  checkColor,
  changeDataTime,
  getListDay,
  convertDayQuote,
  getListDateInMonth,
  isLastNengetuBeforeNengetu,
  findCorrespondingDays,
  mergeDuplicateElements,
  createListExpandCodeRiyouhyou,
  handleSumListFPlanParentQuote,
  handleSumListPlanQuote,
  processDataOver30Days,
  convertDayQuoteCount,
  findCorrespondingDaysCount,
  processDataKaigo,
  checkAndRefillBaseOnKaigo,
  fixedNumber,
  roundDecimal,
  KAIGO_CODE_SPECIAL,
  KAIGO_CODE_ALONE,
  handleChangeTypeMonthly,
  TYPE_MONTHLY,
  defineKaigoName,
  handleEditRecordChangeKaigo,
  handleDeleteChildSame,
  handleCalculateSumList,
  defineKaigoNameType13,
  KAIGODOLIST,
  handleCalParentId,
  handleOkTenkey,
  calTotalSmallTotal,
  calTotalTable,
  CODE1,
  CODE2,
  CODE3,
  handleSpecialTypeRecal,
  RENTAL_TYPE,
  handleFormatDataWeekly,
  createObjectsByDateInfo,
  handleMapDateQuote,
  handleCompareQuoteWeeklyData,
  handleTypeMonth,
  handleCheckType78,
  SPECIAL_TYPE_FILL,
  handleChangeChildCode,
  handleType13Teki,
  calculateAttachedType21,
  filterOfficeNameDuplicate,
  KAIGO_CODE_SHORT_TYPE,
  handleCheckArrayOver30Days,
  SPECIAL_TANISIKIBETU,
  checkKohiNo,
  calculateFutan,
  calculateAndProcess,
  handleUpdateDataAttached,
  checkShuiruiCode,
  calculateSmallTotal,
  handleDataKyufu
};
