/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CheckCircleTwoTone,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import MediaQuery from "react-responsive";
import TimeKBCus from "../../../common/component/TimeKBCus";
import { ConstSet } from "../../../common/configs/constset";
import MealForm from "../../../common/form-kiroku/MealForm";
import {
  checkTableField,
  getMenu,
  getValueIskeep,
  getValueLocalstorage,
  handleInputNumber,
} from "../../../common/function_common/functionCommon";
import {
  checkIkkatsuOkBtn,
  ikkatsuCheckboxReset,
} from "../../../common/function_common/functionDisplay";
import IkkatsuModal from "../../../common/modals/ikkatsuModal";
import CheckKB from "../../../common/model-kiroku/CheckKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import NumberKB from "../../../common/model-kiroku/NumberKB";
import SelectIntakeKB from "../../../common/model-kiroku/SelectIntakeKB";
import ShokunasiKB from "../../../common/model-kiroku/ShokunasiKB";
import ShubetuKB from "../../../common/model-kiroku/ShubetuKB";
import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import TantoMasterKB2 from "../../../common/model-kiroku/TantoMasterKB2";
import TimeKB from "../../../common/model-kiroku/TimeKB";
import TableRowButton from "./TableRowButton";

const expandedRowRender = (record) => (
  <p style={{ marginBottom: "0em" }}>{record.description}</p>
);
const showHeader = true;
const pagination = { position: "bottom" };
const FormItem = Form.Item;

export default class Meal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Table Setting
      bordered: true,
      loading: false,
      pagination,
      size: "small",
      expandedRowRender,
      title: undefined,
      showHeader,
      footer: undefined,
      scroll: undefined,
      hasData: true,
      expandIconAsCell: false,
      expandIconColumnIndex: -1,
      rowActive: null,

      expandRowClick: [],
      columns: [],
      activeListIndex: null,
      activeRowKey: null,
      shubetsuTitle: "",

      // edit add function
      inputMode: this.props.inputMode,
      showDrawer: false,
      visibleMInput: false,
      modalType: null,

      riyousya: null,
      record: null,
      time: null,
      indexRecord: 0,

      field: "",
      isZendkaiDis: false,
      suggestValues: "",

      //
      inputType: null,
      inputValue: null,
      inputField: null,

      count: 0,
      rowsSelect: 0,
      visibleMulState: false,
      mulInputData: {
        mealIds: null,
        time: null,
        stapleIntake: null,
        sideIntake: null,
        shokuNasi: null,
        comment: null,
        tantoId: null,
      },
      mulInputCheckbox: [false, false, false, false, false, false],
      mulInputNotification: "",
      arrCheckBox: [], // array index checkbox for multiple add and edit,
      isClickAll: false,
      delIds: [],
      isConfirmMulDel: false,

      formData: null,
      isFormUpdate: false,

      // handle for active OK SAVE Button when user start input value
      okSaveButton: true,

      tableCurrentPage: 1, // current page of table
      innerHeightTable: window.innerWidth < 1280 ? window.innerHeight - 420 : window.innerHeight - 320,
    };

    // bind
    this.handleClickRow = this.handleClickRow.bind(this);

    // Copy, Del local, Del Server record
    this.handleCopyMeal = this.handleCopyMeal.bind(this);
    this.handleDelMeal = this.handleDelMeal.bind(this);
    this.handleServerDel = this.handleServerDel.bind(this);

    /**
     * Add, Edit by Modal
     */
    this.handleMInput = this.handleMInput.bind(this);
    this.handleMInputCancel = this.handleMInputCancel.bind(this);

    this.getHandleHourInput = this.getHandleHourInput.bind(this);
    this.getHandleMinuteLeftInput = this.getHandleMinuteLeftInput.bind(this);
    this.getHandleMinuteInput = this.getHandleMinuteInput.bind(this);
    this.getHandleReportCaseTyuif = this.getHandleReportCaseTyuif.bind(this);

    this.getHandleShubetu = this.getHandleShubetu.bind(this);
    this.getHandleStaple = this.getHandleStaple.bind(this);
    this.getHandleSide = this.getHandleSide.bind(this);
    this.getHandleShokunari = this.getHandleShokunari.bind(this);

    this.getHandleInputComment = this.getHandleInputComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);

    this.handleOkUpdate = this.handleOkUpdate.bind(this);
    // END

    // add, edit Setting Comment
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    // 一括入力
    this.onChangeTimeMulMode = this.onChangeTimeMulMode.bind(this);
    this.getHandleMulStaple = this.getHandleMulStaple.bind(this);
    this.getHandleMulSide = this.getHandleMulSide.bind(this);
    this.getHandleMulShokunari = this.getHandleMulShokunari.bind(this);
    this.getHandleMulInputComment = this.getHandleMulInputComment.bind(this);
    this.getHandleMulTantoMasterKB = this.getHandleMulTantoMasterKB.bind(this);

    this.getHandleOnChangeMIC = this.getHandleOnChangeMIC.bind(this); // Active Clickable 一括入力 button
    this.handleMulInputModeOk = this.handleMulInputModeOk.bind(this);
    this.handleMulInputModeCancel = this.handleMulInputModeCancel.bind(this);
  }

  /**
   *
   * @param {*} preProps
   */
  componentDidUpdate(preProps) {
    const { count } = this.state;
    const { nyutaisho, mulInputAllMode } = this.props;

    //
    if (nyutaisho !== preProps.nyutaisho) {
      // case update mealId when insert value to DOM
      if (this.state.record) {
        this.setState({
          record: nyutaisho[this.state.record.index],
        });
      }

      // case multiple input
      if (count > 0) {
        this.ikkatsu();
      }
    }

    // reset multiple input mode
    if (mulInputAllMode !== preProps.mulInputAllMode) {
      this.setState({
        count: 0,
        rowsSelect: 0,
        visibleMulState: false,
        mulInputData: {
          mealIds: null,
          time: null,
          stapleIntake: null,
          sideIntake: null,
          shokuNasi: null,
          comment: null,
          tantoId: null,
        },
        mulInputCheckbox: [false, false, false, false, false, false],
        mulInputNotification: "",
        arrCheckBox: [], // array index checkbox for multiple add and edit,
        isClickAll: false,
        expandRowClick: [], // reset expand row on multiple input mode
        rowActive: null,
        activeRowKey: null,
        delIds: [],
        isConfirmMulDel: false,
      });

      this.props.handleIkkatsuBtn(true); // active popup click button
    }
  }

  /**
   *
   * @param {*} key
   * @param {*} index
   * @param {*} event
   */
  onRowClick(key) {
    // Setting active row key
    this.setState({
      rowActive: key,
    });

    /**
     * disable expander row on ikkaketsu mode
     */
    const { mulInputMode } = this.props;
    if (mulInputMode) {
      return;
    }

    const keys = [];
    if (key) {
      keys.push(key); // I have set my record.id as row key. Check the documentation for more details.
    }

    if (key === this.state.activeRowKey) {
      this.setState({
        expandRowClick: [],
        activeRowKey: null,
      });
    }
    else {
      this.setState({
        expandRowClick: [key],
        activeRowKey: key,
      });
    }
  }

  /**
   *
   * @param {*} key
   */
  handleClickRow(key) {
    if (key === this.state.activeListIndex) {
      this.setState({
        activeListIndex: null,
      });
    }
    else {
      this.setState({
        activeListIndex: key,
      });
    }
  }

  /**
   *
   */
  handleCopyMeal(mealRecord) {
    // Clone data
    const cloneMealCopy = {
      // basic infor
      riyousya: mealRecord.riyousya,
      kubun: mealRecord.kubun,
      bunrui: mealRecord.bunrui,
      nyuType: mealRecord.nyuType,
      room: mealRecord.room,
      time: mealRecord.time,

      // tab info. that have been describe in requirement
      reportCaseTyuif: mealRecord.reportCaseTyuif,
      kubunMeal: mealRecord.kubunMeal,

      // setting tab info on local
      index: mealRecord.index,
      modeRecord: "copy",
    };

    this.props.handleCopyMeal(cloneMealCopy);
  }

  /**
   * Delete record that is copy on local
   * @param {*} mealRecord
   */
  handleDelMeal(mealRecord) {
    this.props.handleDelMeal(mealRecord);
  }

  /**
   * Delete record from server
   * @param {*} mealId
   */
  handleServerDel(mealId) {
    this.props.deleteRecord(mealId);
  }

  /**
   * Edit, Update function with single field
   *
   */

  /**
   * function handle when click to edit field
   *
   * @param {int} MType // display modal type corresponding
   * @param {*} record // record for row
   * @param {*} index // index of row
   */
  handleMInput(MType, record, index) {
    const extractCurrent = checkTableField(MType, record);

    let okSaveButton = true;
    if (MType === ConstSet.MODAL_M_COMMENT) {
      okSaveButton = false;
    }

    this.setState({
      rowActive: index,
      expandRowClick: [],

      visibleMInput: true,
      modalType: MType,

      riyousya: record.riyousya.name,
      time: record.time,
      record,
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  }

  /**
   * (2)
   */
  handleMInputCancel() {
    this.setState({
      visibleMInput: false,
    });
  }

  /**
   * (3.1) Pre field <--
   */
  preInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    let modalType = this.state.modalType;

    if (
      modalType > ConstSet.MODAL_M_TIME &&
      modalType <= ConstSet.MODAL_M_TANTOMASTER
    ) {
      modalType = modalType - 1;
    }

    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_M_COMMENT) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    this.setState({
      //
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.2) Pre field ^|
   */
  upperInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_M_COMMENT) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      indexRecord: index,
      riyousya: nyutaisho[index].riyousya.name,
      record: nyutaisho[index],
      time: nyutaisho[index].time,

      inputField: extractCurrent.type,
      inputValue: extractCurrent.value,
      inputType: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.3) Pre field _|
   */
  lowerInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_M_COMMENT) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      indexRecord: index,
      riyousya: nyutaisho[index].riyousya.name,
      time: nyutaisho[index].time,

      record: nyutaisho[index],

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.4) Pre field -->
   */
  nextInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    let modalType = this.state.modalType;

    if (
      modalType >= ConstSet.MODAL_M_TIME &&
      modalType < ConstSet.MODAL_M_TANTOMASTER
    ) {
      modalType = modalType + 1;
    }

    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_M_COMMENT) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    this.setState({
      //
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * 時間ところ
   */

  handleSetCurrentTime = () => {
    const currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.setState({
      inputType: ConstSet.MODAL_M_TIME,
      inputValue: currentTime,
      inputField: "time",
      okSaveButton: false,
    });
  };

  /**
   * (1)
   * @param {(*)} value
   */
  getHandleHourInput(value) {
    let preData = this.state.inputValue
      ? this.state.inputValue
      : this.state.inputValue;
    if (!preData) {
      preData = value + ":00";
    }
    else {
      preData = value + preData.substring(2, 5);
    }
    this.setState({
      inputType: ConstSet.MODAL_M_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (2)
   * @param {(*)} value
   */
  getHandleMinuteLeftInput(value) {
    let preData = this.state.inputValue
      ? this.state.inputValue
      : this.state.inputValue;

    if (!preData) {
      preData = "00:" + value;
    }
    else {
      preData = preData.substring(0, 3) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_M_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (3)
   * @param {(*)} value
   */
  getHandleMinuteInput(value) {
    let preData = this.state.inputValue
      ? this.state.inputValue
      : this.state.inputValue;
    if (!preData) {
      preData = "00:0" + value;
    }
    else {
      preData = preData.substring(0, 4) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_M_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }
  // 時間end

  /**
   * 申送
   * @param {*} value
   */
  getHandleReportCaseTyuif(value) {
    this.setState({
      inputType: ConstSet.MODAL_M_REPORTCASETYUIF,
      inputValue: value ? "" + 1 : "" + 0,
      inputField: "reportCaseTyuif",
      okSaveButton: false,
    });
  }

  /**
   * 種類
   * @param {*} value
   */
  getHandleShubetu(value) {
    this.setState({
      inputType: ConstSet.MODAL_M_SHUBETU,
      inputValue: value,
      inputField: "kubunMeal",
      okSaveButton: false,
    });
  }

  /**
   * 主食摂取量
   * @param {*} value
   */
  getHandleStaple(value) {
    if (
      (this.state.record.kubunMeal &&
        this.state.record.kubunMeal === "経管栄養") ||
      getValueIskeep("mealType") === "経管栄養"
    ) {
      this.setState({
        inputType: ConstSet.MODAL_M_STAPLEINTAKE,
        inputValue: handleInputNumber(value, this.state.inputValue, true),
        inputField: "stapleIntake",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_M_STAPLEINTAKE,
        inputValue: value,
        inputField: "stapleIntake",
        okSaveButton: false,
      });
    }
  }

  /**
   * 副食摂取量
   * @param {*} value
   */
  getHandleSide(value) {
    if (
      (this.state.record.kubunMeal &&
        this.state.record.kubunMeal === "経管栄養") ||
      getValueIskeep("mealType") === "経管栄養"
    ) {
      this.setState({
        inputType: ConstSet.MODAL_M_SIDEINTAKE,
        inputValue: handleInputNumber(value, this.state.inputValue, true),
        inputField: "sideIntake",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_M_SIDEINTAKE,
        inputValue: value,
        inputField: "sideIntake",
        okSaveButton: false,
      });
    }
  }

  /**
   * 食無
   * @param {*} value
   */
  getHandleShokunari(value) {
    this.setState({
      inputType: ConstSet.MODAL_M_SHOKUNASI,
      inputValue: value ? "" + 1 : "" + 0,
      inputField: "shokuNasi",
      okSaveButton: false,
    });
  }

  /**
   * コメント
   * @param {*} value
   * @param {*} type
   */
  getHandleInputComment(value, type) {
    if (type && type === "select") {
      this.setState({
        inputType: ConstSet.MODAL_M_COMMENT,
        inputValue: this.state.inputValue
          ? this.state.inputValue + "、" + value
          : value,
        inputField: "comment",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_M_COMMENT,
        inputValue: value,
        inputField: "comment",
        okSaveButton: false,
      });
    }
  }

  /**
   * 担当者
   * @param {*} value
   */
  getHandleTantoMasterKB(value) {
    // let updaterecord = this.state.record;
    // updaterecord.tantoId = value;
    this.setState({
      inputType: ConstSet.MODAL_M_TANTOMASTER,
      inputValue: value,
      inputField: "tantoId",
      // record: updaterecord,
      okSaveButton: false,
    });
  }

  /**
   * OK UPDATE Button Click
   *
   * Save data
   */
  handleOkUpdate(type) {
    const checkMealId = this.state.record.mealId
      ? this.state.record.mealId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkMealId && !checkCopyType) {
      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        mealIds: checkMealId.toString(),
        date: localStorage.getItem("nyuDate"),
        index: this.state.record.index,
      };

      const updateField = this.state.inputValue;
      if (this.state.inputField && this.state.inputType) {
        if (this.state.inputType === ConstSet.MODAL_M_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        if (
          this.state.inputType === ConstSet.MODAL_M_SIDEINTAKE ||
          this.state.inputType === ConstSet.MODAL_M_STAPLEINTAKE
        ) {
          if (!this.state.inputValue) {
            params[this.state.inputField] = "" + 0;
          }
        }

        this.props.updateRecord(params);

        let okSaveButton = true;
        if (this.state.inputType === ConstSet.MODAL_M_COMMENT) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputField: null,
          inputValue: updateField,
          okSaveButton,
        });
      }
    } // POST
    else {
      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaId: this.state.record.riyousya.id.toString(),
        date: localStorage.getItem("nyuDate"),

        time: this.state.record.time ? this.state.record.time : "00:00",
        reportCaseTyuif: this.state.record.reportCaseTyuif
          ? this.state.record.reportCaseTyuif.toString()
          : "",
        kubunMeal: this.state.record.kubunMeal,
        tantoId: getValueLocalstorage("tid"),

        index: this.state.record.index,
      };

      const updateField = this.state.inputValue;

      if (this.state.inputField && this.state.inputType) {
        // Update MealType if 朝/昼/夕/間食/経管栄養を選択
        const kubunMeal = getValueIskeep("mealType");
        if (
          kubunMeal &&
          kubunMeal !== "全表示" &&
          this.state.inputType !== ConstSet.MODAL_M_SHUBETU
        ) {
          params.kubunMeal = kubunMeal;
        }

        if (this.state.inputType === ConstSet.MODAL_M_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        if (
          this.state.inputType === ConstSet.MODAL_M_SIDEINTAKE ||
          this.state.inputType === ConstSet.MODAL_M_STAPLEINTAKE
        ) {
          if (!this.state.inputValue) {
            params[this.state.inputField] = "" + 0;
          }
        }

        this.props.insertRecord(params);

        let okSaveButton = true;
        if (this.state.inputType === ConstSet.MODAL_M_COMMENT) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputField: null,
          inputValue: updateField,
          okSaveButton,
        });
      }
    }

    // close popup
    if (type !== true) {
      // save from next, pre, upper, lower not colse popup
      this.setState({
        visibleMInput: false,
      });
    }
  }
  // end Edit, Update function

  /**
   * Handle Edit, Update By Input From from right all Screen
   * ALl fields can be save on this Drawer
   *
   */

  /**
   * Form Inside Drawer
   * Show Form
   */
  showDrawer = (record) => {
    // Update MealType if 朝/昼/夕/間食/経管栄養を選択
    const kubunMeal = getValueIskeep("mealType");
    if (
      kubunMeal &&
      kubunMeal !== "全表示" &&
      this.state.inputType !== ConstSet.MODAL_M_SHUBETU
    ) {
      record.kubunMeal = kubunMeal;
    }

    this.setState({
      rowActive: record.index,
      showDrawer: true,
      record,
      isFormUpdate: false,
    });
  };

  /**
   * Close Form
   */
  closeDrawer = () => {
    this.setState({
      showDrawer: false,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  upperForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  lowerForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   * Update file to State, Prepare data to save when click on 更新 button
   * @param {*} value
   * @param {*} type
   */
  getHandleFormInput = (value, type) => {
    const prepareData = this.state.record;
    const objData = {};

    // handle input number
    if (type === "stapleIntake" || type === "sideIntake") {
      value = value ? value : "0";
    }

    objData[type] = value;

    this.setState({
      record: Object.assign({}, prepareData, objData),
      isFormUpdate: true,
    });
  };

  /**
   * Save data action
   */
  handleOkUpdateForm = () => {
    const recordOnState = this.state.record;

    const checkMealId = recordOnState.mealId ? recordOnState.mealId : null;
    const checkCopyType =
      !!(recordOnState.modeRecord && recordOnState.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkMealId && !checkCopyType) {
      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        mealIds: checkMealId.toString(),
        index: this.state.record.index,
        riyousyaId: recordOnState.riyousya.id,
        date: localStorage.getItem("nyuDate"), // @TODO check performance when get from record or localstorage
        time: recordOnState.time,
        reportCaseTyuif: recordOnState.reportCaseTyuif,
        kubunMeal: recordOnState.kubunMeal,
        stapleIntake: recordOnState.stapleIntake,
        sideIntake: recordOnState.sideIntake,
        shokuNasi: recordOnState.shokuNasi,
        comment: recordOnState.comment,
        tantoId: recordOnState.tantoId
          ? recordOnState.tantoId
          : getValueLocalstorage("tid"),

        reportCaseNishif: recordOnState.reportCaseNishif,
        reportCaseNaiyou2: recordOnState.reportCaseNaiyou2,
      };

      params = Object.assign(params, this.state.record);
      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.updateRecord(params);
    } // POST
    else {
      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: this.state.record.index,
        riyousyaId: recordOnState.riyousya.id,
        date: localStorage.getItem("nyuDate"), // @TODO check performance when get from record or localstorage
        reportCaseTyuif: recordOnState.reportCaseTyuif,
        kubunMeal: recordOnState.kubunMeal,
        stapleIntake: recordOnState.stapleIntake,
        sideIntake: recordOnState.sideIntake,
        shokuNasi: recordOnState.shokuNasi,
        comment: recordOnState.comment,
        tantoId: getValueLocalstorage("tid"),
        reportCaseNishif: recordOnState.reportCaseNishif,
        reportCaseNaiyou2: recordOnState.reportCaseNaiyou2,
      };

      params = Object.assign(params, this.state.record);
      params.time = recordOnState.time ? recordOnState.time : "00:00";
      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.insertRecord(params);
    }

    // clear inputing mode
    this.setState({
      isFormUpdate: false,
    });
  };
  // END Handle Edit, Update By Input Form from right all Screen

  /**
   * Handle Add, Edit Setting Comment
   */
  /**
   * move to input state of table
   * @param {*} index
   */
  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  /**
   * Cancel Save
   * @param {*} index
   */
  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  /**
   * Save function
   * @param {*} naiyou
   */
  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }
  // END Handle Add, Edit Setting Comment

  /**
   * Handle 一括入力
   *
   * handle checkbox: time, stapleIntake, sideIntake, shokuNasi, comment, tantoMaster
   * handle change time, stapleIntake, sideIntake, shokuNasi, comment, tantoMaster
   * handle OK to save data
   * handle CANCEL to cancel input mode
   *
   * handle autoCheck when input comment field. Performanceので、onChange has changed by onBlur
   *
   * handle function when user select 利用者
   *
   */
  onClickSelectAll = () => {
    const { arrCheckBox, tableCurrentPage, isClickAll } = this.state;
    const { nyutaisho } = this.props;

    const preArrCheckBox = arrCheckBox;
    let rowsSelect = 0; // count total row selected

    const delIds = [];
    for (let i = 0; i < nyutaisho.length; i++) {
      if (!isClickAll) {
        if (
          i < ConstSet.TABLE_PAGINATION * tableCurrentPage &&
          i >= ConstSet.TABLE_PAGINATION * (tableCurrentPage - 1)
        ) {
          preArrCheckBox[i] = true;

          // handle for del mode, list ids has push into array
          if (nyutaisho[i].mealId) {
            delIds[i] = nyutaisho[i].mealId;
          }
          else {
            delIds[i] = 0;
            // preArrCheckBox[i] = false;
          }

          rowsSelect++;
        }
        else {
          preArrCheckBox[i] = false;
        }
      }
      else {
        preArrCheckBox[i] = false;

        // reset delIds from state
        delIds[i] = 0;
      }
    }

    // preMulInputData.mealIds = mealIds;

    if (!isClickAll) {
      this.props.handleIkkatsuBtn(false);
    }
    else {
      this.props.handleIkkatsuBtn(true);
    }

    this.setState({
      arrCheckBox: preArrCheckBox,
      isClickAll: !isClickAll,
      rowsSelect,
      delIds,
    });

    // handle logic
  };

  onClickSelectOne = (recordIndex) => {
    const { arrCheckBox, delIds } = this.state;
    const { nyutaisho } = this.props;

    //
    const preArrCheckBox = arrCheckBox;
    preArrCheckBox[recordIndex] = !arrCheckBox[recordIndex];

    // Check is selected, id have been retrieve from state
    const preDelIds = delIds;
    if (preArrCheckBox[recordIndex]) {
      if (
        nyutaisho &&
        nyutaisho[recordIndex] &&
        nyutaisho[recordIndex].mealId
      ) {
        preDelIds[recordIndex] = nyutaisho[recordIndex].mealId;
      }
    }
    else {
      preDelIds[recordIndex] = 0;
    }

    this.setState({
      arrCheckBox: preArrCheckBox,
      delIds: preDelIds,
    });

    // display 変更 button
    let mulBtnState = false;

    // count select row
    let rowsSelect = 0;

    preArrCheckBox.forEach((item) => {
      if (item) {
        rowsSelect++;
        mulBtnState = true;
      }
    });

    this.setState({
      rowsSelect,
    });

    if (mulBtnState) {
      this.props.handleIkkatsuBtn(false);
    }
    else {
      this.props.handleIkkatsuBtn(true);
    }
  };

  /**
   *
   * @param {*} e
   * @param {*} type
   * @param {*} key
   */
  handleCheckBox = (type, key, e) => {
    const { mulInputData, mulInputCheckbox } = this.state;

    //
    const preDataCheckbox = mulInputCheckbox;
    preDataCheckbox[type] = e.target.checked;

    //
    const preData = ikkatsuCheckboxReset(mulInputData, key);

    this.setState({
      mulInputCheckbox: preDataCheckbox,
      mulInputData: preData,
    });
  };

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulMode(time, timeString) {
    // save data to state
    const preData = this.state.mulInputData;
    preData.time = timeString;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[0] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulModeNew = (timeString) => {
    // save data to state
    const preData = this.state.mulInputData;
    preData.time = timeString;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[0] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  };

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulStaple(value) {
    const preData = this.state.mulInputData;
    preData.stapleIntake = value;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[1] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulSide(value) {
    const preData = this.state.mulInputData;
    preData.sideIntake = value;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[2] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulShokunari(value) {
    const preData = this.state.mulInputData;
    preData.shokuNasi = value ? 1 : 0;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[3] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulInputComment(value, type) {
    const preData = this.state.mulInputData;
    if (type && type === "select") {
      preData.comment = preData.comment
        ? preData.comment + "、" + value
        : value;
    }
    else {
      preData.comment = value;
    }

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[4] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {int} value ID of tantoMaster
   */
  getHandleMulTantoMasterKB(value) {
    const preData = this.state.mulInputData;
    preData.tantoId = value;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[5] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   */
  getHandleOnChangeMIC = () => {
    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[4] = true;

    this.setState({
      mulInputCheckbox: preDataCheckbox,
    });
  };

  /**
   *
   */
  handleMulInputModeOk() {
    //
    this.ikkatsu();

    // close popup
    this.props.handleMulInputModeCancel();

    // open modal
    this.setState({
      visibleMulState: true,
      isConfirmMulDel: false,
    });
  }

  /**
   *
   */
  handleMulInputModeCancel() {
    this.props.handleMulInputModeCancel();

    // reset
    const reset = {
      mealIds: null,
      time: null,
      stapleIntake: null,
      sideIntake: null,
      shokuNasi: null,
      comment: null,
      tantoId: null,
    };

    this.setState({
      mulInputData: reset,
      mulInputCheckbox: [false, false, false, false, false, false],
      isConfirmMulDel: false,
    });
  }

  /**
   *
   */
  ikkatsu = () => {
    // prepare data
    const { arrCheckBox, mulInputData, mulInputCheckbox, delIds } = this.state;
    const { nyutaisho, mulInputMode, mulDelMode } = this.props;

    if (mulDelMode) {
      // Create count rows that is modify, and index
      let count = 0;
      delIds.forEach((item) => {
        if (item) {
          count++;
        }
      });

      // save the first row
      const preArrCheckBox = arrCheckBox;
      for (let i = 0; i < delIds.length; i++) {
        if (delIds[i]) {
          this.props.deleteRecord(delIds[i]);
          count--;

          const preDelIds = delIds;
          preDelIds[i] = 0;
          preArrCheckBox[i] = false;
          this.setState({
            count,
            delIds: preDelIds,
            arrCheckBox: preArrCheckBox,
          });
          break;
        }
      }

      // Check if only oneRow have been selected -> reset file
      if (count === 0) {
        // reset
        const reset = {
          mealIds: null,
          time: null,
          stapleIntake: null,
          sideIntake: null,
          shokuNasi: null,
          comment: null,
          tantoId: null,
        };

        this.setState({
          mulInputData: reset,
          mulInputCheckbox: [false, false, false, false, false, false],
          arrCheckBox: [],
          delIds: [],
        });

        this.props.handleIkkatsuBtn(true);
      }
    }
    else if (mulInputMode) {
      const preData = {};

      // Time
      if (!mulInputCheckbox[0]) {
        preData.time = null;
      }
      else {
        preData.time = mulInputData.time;
      }

      // stapleIntake
      if (!mulInputCheckbox[1]) {
        preData.stapleIntake = null;
      }
      else {
        preData.stapleIntake = mulInputData.stapleIntake;
      }

      // sideIntake
      if (!mulInputCheckbox[2]) {
        preData.sideIntake = null;
      }
      else {
        preData.sideIntake = mulInputData.sideIntake;
      }

      // shokuNasi
      if (!mulInputCheckbox[3]) {
        preData.shokuNasi = null;
      }
      else {
        preData.shokuNasi = mulInputData.shokuNasi
          ? "" + mulInputData.shokuNasi
          : "0";
      }

      // Comment
      if (!mulInputCheckbox[4]) {
        preData.comment = null;
      }
      else if (mulInputCheckbox[1] && !mulInputData.comment) {
        preData.comment = "";
      }
      else {
        preData.comment = mulInputData.comment;
      }

      // TantoID
      if (!mulInputCheckbox[5]) {
        preData.tantoId = null;
      }
      else if (mulInputData.tantoId) {
        preData.tantoId = mulInputData.tantoId;
      }
      else {
        preData.tantoId = getValueLocalstorage("tid");
      }

      // Condition nothing input
      // if (!preData.time && !preData.stapleIntake && !preData.sideIntake && !preData.shokuNasi && !mulInputCheckbox[4] && !preData.tantoId) {
      //     return;
      // }

      preData.tantoId = preData.tantoId ? "" + preData.tantoId : null;

      // Create count rows that is modify, and index
      let count = 0;
      arrCheckBox.forEach((item) => {
        if (item) {
          count++;
        }
      });

      // save the first row
      for (let i = 0; i < arrCheckBox.length; i++) {
        if (arrCheckBox[i]) {
          // Check if recordId exist -> call update function
          if (nyutaisho && nyutaisho[i].mealId) {
            this.props.updateRecordMul(
              Object.assign({ mealIds: "" + nyutaisho[i].mealId }, preData)
            );
            count--;
            const preArrCheckBox = arrCheckBox;
            preArrCheckBox[i] = false;
            this.setState({
              count,
              arrCheckBox: preArrCheckBox,
            });

            break;
          }
          else if (nyutaisho && !nyutaisho[i].mealId) {
            // recordId = null -> call crate new function
            // 0. systemControlName
            preData.systemControlName = JSON.parse(localStorage.getItem("menu")).sn;
            preData.systemControlId = JSON.parse(localStorage.getItem("menu")).si;

            // 1. index
            preData.index = nyutaisho[i].index;

            // 2. RiyousyaId
            preData.riyousyaId = "" + nyutaisho[i].riyousya.id;

            // 2. date
            preData.date = localStorage.getItem("nyuDate");

            // 3. time
            if (!preData.time) {
              if (nyutaisho[i].time) {
                preData.time = nyutaisho[i].time;
              }
              else {
                preData.time = "00:00";
              }
            }

            // Update MealType if 朝/昼/夕/間食/経管栄養を選択
            const kubunMeal = getValueIskeep("mealType");
            if (kubunMeal && kubunMeal !== "全表示") {
              preData.kubunMeal = kubunMeal;
            }

            // 7. tantoId
            if (!preData.tantoId) {
              preData.tantoId = getValueLocalstorage("tid");
            }

            this.props.insertRecordMul(Object.assign({}, preData));

            count--;
            const preArrCheckBox = arrCheckBox;
            preArrCheckBox[i] = false;
            this.setState({
              count,
              arrCheckBox: preArrCheckBox,
            });
            break;
          }
          else {
            break;
          }
        }
      }

      // Check if only oneRow have been selected -> reset file
      if (count === 0) {
        // reset
        const reset = {
          mealIds: null,
          time: null,
          stapleIntake: null,
          sideIntake: null,
          shokuNasi: null,
          comment: null,
          tantoId: null,
        };

        this.setState({
          mulInputData: reset,
          mulInputCheckbox: [false, false, false, false, false, false],
          delIds: [],
        });

        this.props.handleIkkatsuBtn(true);
      }
    }
  };

  // End Handle 一括入力

  /**
   * handle click cell event
   *
   * in mulInputMode, click do nothing
   * in input mode, base info cell click -> open expander data, edting cell -> display modal
   * in nothing mode -> open expander data
   *
   */
  onClickCellEvent = (recordIndex, type) => {
    const { nyutaisho, inputMode, mulInputMode } = this.props;
    const record = nyutaisho[recordIndex];

    switch (type) {
      case ConstSet.COL_SELECT:
        return;

      case ConstSet.COL_COPY:
        return;

      case ConstSet.COL_MODE:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_NAME:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ADDRESS:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ROOM:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_MEAL_TIME:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_M_TIME,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_MEAL_REPORTCASETYUIF:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_M_REPORTCASETYUIF,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_MEAL_SHUBETU:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_M_SHUBETU,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_MEAL_STAPLEINTAKE:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_M_STAPLEINTAKE,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_MEAL_SIDEINTAKE:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_M_SIDEINTAKE,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_MEAL_SHOKUNASI:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_M_SHOKUNASI,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_MEAL_COMMENT:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_M_COMMENT,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_MEAL_TANTOMASTER:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_M_TANTOMASTER,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      default:
        return;
    }
  };

  handChangePagination = (pagination) => {
    // reset 一括入力 mode
    const { arrCheckBox } = this.state;
    const preArrCheckBox = [];
    for (let i = 0; i < arrCheckBox.length; i++) {
      preArrCheckBox.push(0);
    }

    this.setState({
      tableCurrentPage: pagination.current,
      arrCheckBox: preArrCheckBox,
      isClickAll: false,
    });
  };

  closeModalMulState = () => {
    this.setState({
      visibleMulState: false,
      isConfirmMulDel: false,
    });
  };

  onChangeConfirmMulDel = (e) => {
    this.setState({
      isConfirmMulDel: e.target.checked,
    });
  };

  render() {
    const state = this.state;
    const {
      nyutaisho,
      loading,
      bunruiTitle,
      inputMode,
      mulInputAllMode,
      mulInputMode,
      mulInputModal,
      mulDelMode,
      tantoMaster,
      yougoMasterNa,
      isDisLongText,
    } = this.props;

    const mealType = getValueIskeep("mealType");
    /**
     * handle date
     */
    const data = [];

    // colums情報を設定
    const columnsLong = [];
    if (mulInputAllMode) {
      columnsLong.push({
        title: "全",
        dataIndex: "colSelect",
        key: "colSelect",
        width: 50,
        fixed: "left",
        className: "input-mode-mul",
        onHeaderCell: () => {
          return {
            onClick: () => {
              this.onClickSelectAll();
            }, // click row
          };
        },
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickSelectOne(record.key);
            }, // click row
          };
        },
      });
    }
    else if (inputMode) {
      columnsLong.push({
        title: "追加",
        dataIndex: "colCopy",
        key: "colCopy",
        width: 50,
        fixed: "left",
        className: "input-mode-fix action-col",
      });
    }
    else {
      columnsLong.push({
        title: "状況",
        dataIndex: "col1",
        key: "col1",
        width: 50,
        fixed: "left",
        className: `joutaiCol ${inputMode || mulInputAllMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      });
    }

    columnsLong.push(
      {
        title: "利用者名",
        dataIndex: "col2",
        key: "col2",
        width: 150,
        fixed: "left",
        className: `${inputMode || mulInputAllMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: bunruiTitle,
        dataIndex: "col3",
        key: "col3",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "部屋名",
        dataIndex: "col4",
        key: "col4",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "時間",
        dataIndex: "col5",
        key: "col5",
        width: 70,
        className: `timeCol ${inputMode || mulInputAllMode
          ? " input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MEAL_TIME);
            }, // click row
          };
        },
      },
      {
        title: "申送",
        dataIndex: "col6",
        key: "col6",
        width: 50,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(
                record.key,
                ConstSet.COL_MEAL_REPORTCASETYUIF
              );
            }, // click row
          };
        },
      },
      {
        title: "種類",
        dataIndex: "col7",
        key: "col7",
        width: 100,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MEAL_SHUBETU);
            }, // click row
          };
        },
      },
      {
        title: "主食摂取量",
        dataIndex: "col8",
        key: "col8",
        width: 100,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MEAL_STAPLEINTAKE);
            }, // click row
          };
        },
      },
      {
        title: "副食摂取量",
        dataIndex: "col9",
        key: "col9",
        width: 100,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MEAL_SIDEINTAKE);
            }, // click row
          };
        },
      },
      {
        title: "食無",
        dataIndex: "col10",
        key: "col10",
        width: 60,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MEAL_SHOKUNASI);
            }, // click row
          };
        },
      },
      {
        title: "コメント",
        dataIndex: "col11",
        key: "col11",
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MEAL_COMMENT);
            }, // click row
          };
        },
        // width: 70,
      },
      {
        title: "担当者",
        dataIndex: "col12",
        key: "col12",
        width: 120,
        className: `${inputMode || mulInputAllMode ? "input-mode" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MEAL_TANTOMASTER);
            }, // click row
          };
        },
      }
    );

    if (inputMode) {
      columnsLong.push({
        title: "削除",
        dataIndex: "colDel",
        key: "colDel",
        width: 50,
        // fixed: 'right',
        className: "input-mode-fix action-col",
      });
    }

    // テーブルデータの準備
    if (nyutaisho) {
      for (let i = 0; i < nyutaisho.length; i++) {
        let color = "nuytaisho-yellow";
        if (nyutaisho[i].kubun === "ショート") {
          color = "nuytaisho-blue";
        }

        let gender = "女";
        let colorGender = "nts-gender-red";
        if (nyutaisho[i].riyousya.gender === 1) {
          gender = "男";
          colorGender = "nts-gender-blue";
        }

        let planType = "";
        if (nyutaisho[i].time && nyutaisho[i].planType === true) {
          planType = "bg_green";
        }
        else if (nyutaisho[i].time) {
          planType = "bg_pink";
        }

        data.push({
          key: i,
          colSelect: <Checkbox checked={this.state.arrCheckBox[i]} />,
          colCopy: (
            <span className={"action-kiroku"}>
              <Button
                type={"primary"}
                ghost
                onClick={this.handleCopyMeal.bind(this, nyutaisho[i])}
              >
                <PlusOutlined />
              </Button>
            </span>
          ),
          col1: (
            <div className={`ntk-riyousya-status ${color} icon-table`}>
              {nyutaisho[i].nyuType === "1" ? (
                <ArrowRightOutlined />
              ) : (
                nyutaisho[i].nyuType
              )}
            </div>
          ),
          col2: (
            <span className={"ntk-riyousya-colum"}>
              {nyutaisho[i].modeRecord && nyutaisho[i].modeRecord === "copy" ? (
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  {nyutaisho[i].riyousya.name}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  {nyutaisho[i].riyousya.name}
                </span>
              )}
              <span className={`ntl-riyousya-gender ${colorGender}`}>
                {gender}
              </span>
            </span>
          ),
          col3: <div>{nyutaisho[i].bunrui}</div>, // 住所
          col4: <div>{nyutaisho[i].room}</div>, // 部屋名
          col5: (
            <div className={`icon-table ${planType}`}>
              {nyutaisho[i].time ? nyutaisho[i].time.substring(0, 5) : ""}
            </div>
          ),
          col6: (
            <div className={"icon-table"}>
              {nyutaisho[i].reportCaseTyuif ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                ""
              )}
            </div>
          ),
          col7: (
            <div>
              {mealType && mealType !== "全表示"
                ? mealType
                : nyutaisho[i].kubunMeal
                  ? nyutaisho[i].kubunMeal
                  : ""}
            </div>
          ),
          col8: (
            <div className={"icon-table"}>
              {nyutaisho[i].stapleIntake || nyutaisho[i].stapleIntake === 0
                ? nyutaisho[i].stapleIntake
                : ""}
            </div>
          ),
          col9: (
            <div className={"icon-table"}>
              {nyutaisho[i].sideIntake || nyutaisho[i].sideIntake === 0
                ? nyutaisho[i].sideIntake
                : ""}
            </div>
          ),
          col10: (
            <div className={"icon-table"}>
              {nyutaisho[i].shokuNasi ? "無" : ""}
            </div>
          ),
          col11: (
            // <Tooltip
            //     placement="bottomRight"
            //     title={nyutaisho[i].comment}
            // >
            //     {nyutaisho[i].comment && nyutaisho[i].comment.trim() ? nyutaisho[i].comment : ''}
            // </Tooltip>
            (<div>{nyutaisho[i].comment}</div>)
          ),
          col12:
            nyutaisho[i].tantoName && nyutaisho[i].tantoName.length > 6 ? (
              <Tooltip placement="bottomRight" title={nyutaisho[i].tantoName}>
                {nyutaisho[i].tantoName.substring(0, 4) + "..."}
              </Tooltip>
            ) : (
              <div className={"icon-table"}>
                {nyutaisho[i].tantoName ? nyutaisho[i].tantoName : ""}
              </div>
            ),
          colDel: (
            <span className={"action-kiroku"}>
              {nyutaisho[i].modeRecord || nyutaisho[i].mealId ? (
                !nyutaisho[i].modeRecord && nyutaisho[i].mealId ? (
                  <Popconfirm
                    overlayStyle={{ width: "210px" }}
                    title="削除してもいいですか？"
                    onConfirm={() => this.handleServerDel(nyutaisho[i].mealId)}
                  >
                    <span>
                      <Button type={"primary"} ghost>
                        <MinusOutlined />
                      </Button>
                    </span>
                  </Popconfirm>
                ) : (
                  <span>
                    <Button
                      type={"primary"}
                      ghost
                      onClick={this.handleDelMeal.bind(this, nyutaisho[i])}
                    >
                      <MinusOutlined />
                    </Button>
                  </span>
                )
              ) : null}
            </span>
          ),
          description: (
            <TableRowButton
              history={this.props.history}
              editRow={this.showDrawer.bind(this, nyutaisho[i])}
              riyousyaId={nyutaisho[i].riyousya.id}
            />
          ),
        });
      }
    }

    return (
      (<div>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Spin spinning={loading}>
                    <Table
                      {...this.state}
                      showHeader={true}
                      expandIconAsCell={false}
                      expandIconColumnIndex={-1}
                      bordered={true}
                      size="small"
                      expandedRowKeys={this.state.expandRowClick}
                      pagination={{
                        position: "bottom",
                        pageSize: ConstSet.TABLE_PAGINATION,
                      }}
                      scroll={{ x: 1400, y: this.state.innerHeightTable }}
                      className="table-kiroku-date"
                      rowClassName={(record, index) =>
                        this.state.rowActive != null &&
                          index === this.state.rowActive
                          ? "pljp-table-row-hover"
                          : ""
                      }
                      onChange={(pagination) => {
                        this.handChangePagination(pagination);
                      }}
                      columns={columnsLong}
                      dataSource={state.hasData ? data : null}
                    />
                  </Spin>
                </div>
              );
            }
            return null;

          }}
        </MediaQuery>
        <Drawer
          destroyOnClose
          title={this.state.record ? this.state.record.riyousya.name : null}
          width={720}
          onClose={this.closeDrawer}
          open={this.state.showDrawer}
          closable={false}
          rootClassName="kiroku-drawer-form"
        >
          <MealForm
            tantoMaster={tantoMaster}
            data={this.state.record ? this.state.record : {}}
            handleFormInput={this.getHandleFormInput}
            yougoMasterEditState={this.getYougoMasterEditState}
            yougoMasterEditCancel={this.getYougoMasterEditCancel}
            yougoMasterEditSave={this.getYougoMasterEditSave}
            yougoMasterNa={yougoMasterNa}
          />
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left" }}>
              <Button onClick={this.upperForm} style={{ marginRight: 8 }}>
                <ArrowUpOutlined />
              </Button>
              <Button onClick={this.lowerForm}>
                <ArrowDownOutlined />
              </Button>
            </span>

            <span>
              <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
                戻る
              </Button>
              <Button type="primary" danger onClick={this.handleOkUpdateForm}>
                更新
              </Button>
            </span>
          </div>
        </Drawer>
        <Modal
          title={
            <div className="kim-header-title">
              <span>{this.state.riyousya}</span>
              <span>
                {this.state.time ? this.state.time.substring(0, 5) : ""}
              </span>
            </div>
          }
          open={this.state.visibleMInput}
          onOk={this.handleOkUpdate}
          onCancel={this.handleMInputCancel}
          className={"kiroku-input-modal"}
          footer={[
            <Button key="pre" onClick={this.preInput}>
              前項目
            </Button>,
            <Button key="next" onClick={this.nextInput}>
              次項目
            </Button>,
            <Button
              className="kim-btn-ok"
              key="submit"
              type="primary"
              onClick={this.handleOkUpdate}
              disabled={this.state.okSaveButton}
            >
              OK
            </Button>,
            <Button key="up" onClick={this.upperInput}>
              上の行
            </Button>,
            <Button key="dow" onClick={this.lowerInput}>
              下の行
            </Button>,
          ]}
          maskClosable={false}
        >
          <div className="kim-title">
            <span>{this.state.field}</span>
          </div>
          {this.state.isZendkaiDis ? (
            <div className={"model-top-row"}>
              <div className="kim-zenkai"></div>
              <div
                className={`kim-value ${this.state.field === "時間" ? "kim-value-time" : ""}`}
              >
                <div className="kim-value-top">
                  {this.state.inputValue ? this.state.inputValue : "\u00A0"}
                </div>
                {this.state.field === "時間" ? (
                  <div className="kim-value-bottom">
                    <Button type="primary" onClick={this.handleSetCurrentTime}>現時刻</Button>
                  </div>
                ) : null}
              </div>
              <div className={"mtr-col1 kim-suggest"}></div>
            </div>
          ) : null}

          <div>
            {(() => {
              switch (this.state.modalType) {
                case ConstSet.MODAL_M_TIME:
                  return (
                    <TimeKB
                      handleHourInput={this.getHandleHourInput}
                      handleMinuteLeftInput={this.getHandleMinuteLeftInput}
                      handleMinuteInput={this.getHandleMinuteInput}
                    />
                  );

                case ConstSet.MODAL_M_REPORTCASETYUIF:
                  return (
                    <CheckKB
                      defaltValue={this.state.inputValue}
                      handleOnchangeCheck={this.getHandleReportCaseTyuif}
                    />
                  );

                case ConstSet.MODAL_M_SHUBETU:
                  return (
                    <ShubetuKB
                      defaltValue={this.state.inputValue}
                      handleOnchange={this.getHandleShubetu}
                    />
                  );

                case ConstSet.MODAL_M_STAPLEINTAKE:
                  if (
                    (this.state.record &&
                      this.state.record.kubunMeal === "経管栄養") ||
                    getValueIskeep("mealType") === "経管栄養"
                  ) {
                    return <NumberKB handleInputNum={this.getHandleStaple} />;
                  }
                  return (
                    <SelectIntakeKB
                      handleInputNum={this.getHandleStaple}
                      value={this.state.inputValue}
                    />
                  );


                case ConstSet.MODAL_M_SIDEINTAKE:
                  if (
                    (this.state.record &&
                      this.state.record.kubunMeal === "経管栄養") ||
                    getValueIskeep("mealType") === "経管栄養"
                  ) {
                    return <NumberKB handleInputNum={this.getHandleSide} />;
                  }
                  return (
                    <SelectIntakeKB
                      handleInputNum={this.getHandleSide}
                      value={this.state.inputValue}
                    />
                  );

                case ConstSet.MODAL_M_SHOKUNASI:
                  return (
                    <ShokunasiKB
                      defaltValue={this.state.inputValue}
                      handleOnchange={this.getHandleShokunari}
                    />
                  );

                case ConstSet.MODAL_M_COMMENT:
                  return (
                    <CommentKB
                      yougoMasterEditState={this.getYougoMasterEditState}
                      yougoMasterEditCancel={this.getYougoMasterEditCancel}
                      yougoMasterEditSave={this.getYougoMasterEditSave}
                      defaultValue={this.state.inputValue}
                      handleTextareaKB={this.getHandleInputComment}
                      yougoMasterNa={yougoMasterNa}
                      rowNum={4}
                    />
                  );

                case ConstSet.MODAL_M_TANTOMASTER:
                  return (
                    <TantoMasterKB2
                      tantoMaster={tantoMaster}
                      value={this.state.inputValue}
                      handleTantoMasterKB={this.getHandleTantoMasterKB}
                      widthCode={100}
                      widthText={250}
                    />
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </Modal>
        <Modal
          title={[
            <span key={1}>{"一括入力"}</span>,
            <span
              key={2}
              style={{ marginLeft: "24px", fontSize: "14px", color: "red" }}
            >
              {this.state.mulInputNotification}
            </span>,
          ]}
          open={this.props.mulInputModal && mulInputMode}
          onOk={this.handleMulInputModeOk}
          onCancel={this.handleMulInputModeCancel}
          footer={[
            <Button key="cancel" onClick={this.handleMulInputModeCancel}>
              閉じる
            </Button>,
            <Button
              key="ok"
              onClick={this.handleMulInputModeOk}
              type="primary"
              disabled={checkIkkatsuOkBtn(state.mulInputCheckbox)}
            >
              OK
            </Button>,
          ]}
        >
          <div>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.checkboxTime}
                    checked={this.state.mulInputCheckbox[0]}
                  >
                    時間
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={12}>
                <TimeKBCus
                  timeString={this.state.mulInputData.time}
                  handleInput={this.onChangeTimeMulModeNew}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 1, "stapleIntake")}
                    checked={this.state.mulInputCheckbox[1]}
                  >
                    主食摂取量
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={6}>
                <FormItem>
                  <SelectIntakeKB
                    handleInputNum={this.getHandleMulStaple}
                    value={this.state.mulInputData.stapleIntake}
                    inputWidth={100}
                  />
                </FormItem>
              </Col>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 2, "sideIntake")}
                    checked={this.state.mulInputCheckbox[2]}
                  >
                    副食摂取量
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={6}>
                <FormItem>
                  <SelectIntakeKB
                    handleInputNum={this.getHandleMulSide}
                    value={this.state.mulInputData.sideIntake}
                    inputWidth={100}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 3, "shokuNasi")}
                    checked={this.state.mulInputCheckbox[3]}
                  >
                    食無
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem>
                  <ShokunasiKB
                    defaltValue={this.state.mulInputData.shokuNasi}
                    handleOnchange={this.getHandleMulShokunari}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 4, "comment")}
                    checked={this.state.mulInputCheckbox[4]}
                  >
                    コメント
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={17}>
                <FormItem>
                  <CommentKB
                    yougoMasterEditState={this.getYougoMasterEditState}
                    yougoMasterEditCancel={this.getYougoMasterEditCancel}
                    yougoMasterEditSave={this.getYougoMasterEditSave}
                    handleTextareaKB={this.getHandleMulInputComment}
                    handleOnchange={this.getHandleOnChangeMIC}
                    yougoMasterNa={yougoMasterNa}
                    defaultValue={this.state.mulInputData.comment}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 5, "tantoId")}
                    checked={this.state.mulInputCheckbox[5]}
                  >
                    担当者
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={18}>
                <FormItem>
                  <TantoMasterKB
                    tantoMaster={tantoMaster}
                    value={this.state.mulInputData.tantoId}
                    handleTantoMasterKB={this.getHandleMulTantoMasterKB}
                    widthCode={100}
                    widthText={250}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          destroyOnClose
          title={[
            <span key={1}>{"一括削除"}</span>,
            <span
              key={2}
              style={{ marginLeft: "24px", fontSize: "14px", color: "red" }}
            >
              {this.state.mulInputNotification}
            </span>,
          ]}
          open={mulInputModal && mulDelMode}
          onOk={this.handleMulInputModeOk}
          onCancel={this.handleMulInputModeCancel}
          footer={[
            <Button key="cancel" onClick={this.handleMulInputModeCancel}>
              キャンセル
            </Button>,
            <Button
              key="ok"
              onClick={this.handleMulInputModeOk}
              type="primary"
              disabled={!this.state.isConfirmMulDel}
            >
              OK
            </Button>,
          ]}
        >
          <div>
            <h3>一括削除を実行しますか？</h3>
            <Checkbox
              value={this.state.isConfirmMulDel}
              onChange={this.onChangeConfirmMulDel}
            >
              削除に同意する
            </Checkbox>
          </div>
        </Modal>
        <IkkatsuModal
          visibleMulState={state.visibleMulState}
          rowsSelect={state.rowsSelect}
          count={state.count}
          closePopup={this.closeModalMulState}
        />
      </div>)
    );
  }
}
