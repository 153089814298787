import { combineReducers } from "redux";
import inquiryRequestReducers from "../../../reducers/inquiryRequestReducers/inquiryRequestReducers";
import kyotakuPlan1Reducers from "../../../reducers/kyotakuPlan1Reducers/kyotakuPlan1Reducers";
import kyotakuPlan2Reducers from "../../../reducers/kyotakuPlan2Reducers/kyotakuPlan2Reducers";
import needsItemReducers from "../../../reducers/needsItemReducers/needsItemReducers";
import rihabiliReducers from "../../../reducers/rihabiliReducers/rihabiliReducers";

import iryoKougakuFutanMasterReducers from "../../../reducers/iryoKougakuFutanMasterReducers/iryoKougakuFutanMasterReducers";
import iryouhokenMasterReducers from "../../../reducers/iryouhokenMasterReducers/iryouhokenMasterReducers";
import riyousyaHokenReducers from "../../../reducers/riyousyaHokenReducers/riyousyaHokenReducers";
import riyousyaMonthlyReducers from "../../../reducers/riyousyaMonthlyReducers/RiyousyaMonthlyReducers";
import tantoKaigiReducers from "../../../reducers/tantoKaigiReducers/tantoKaigiReducer";

import authlife from "./authlife";
import common from "./common";
import config from "./config";
import error from "./error";
import haisetsu from "./haisetsu";
import jirituSokusin from "./jirituSokusin";
import jokusoPlan from "./jokusoPlan";
import kobetsu from "./kobetsu";
import koku from "./koku";
import life from "./life";
import lifeYougoMaster from "./lifeYougoMaster";
import progressLIFE from "./progress";
import rihabiri from "./rihabiri";
import seikatuCheck from "./seikatuKinouCheck";
import tantoMaster from "./tantoMaster";
import yakuzai from "./yakuzai";
import yougoMaster from "./yougoMaster";
import kagakuteki from "./kagakuteki";

//
import accountReducers from "../../reuse/reducers/accountReducers/accountReducers";
import allMasterReducers from "../../reuse/reducers/allMasterReducers/allMasterReducers";
import dengonSendToReducers from "../../reuse/reducers/dengonSendToReducers/dengonSendToReducers";
import doctorMasterReducers from "../../reuse/reducers/doctorMasterReducers/doctorMasterReducers";
import drugMasterReducers from "../../reuse/reducers/drugMasterReducers/drugMasterReducers";
import dutyReportReducers from "../../reuse/reducers/dutyReportReducers/dutyReportReducers";
import fileManagementReducers from "../../reuse/reducers/fileManagementReducers/fileManagementReducers";
import getAvatarReducers from "../../reuse/reducers/getAvatarReducers/getAvatarReducers";
import itakuTantoMaster from '../../reuse/reducers/itakuTantoMasterReducers/itakuTantoMaster';
import kaigoHokenMasterReducers from '../../reuse/reducers/kaigoHokenMasterReducers/kaigoHokenMasterReducers';
import kaigoYougoMasterReducers from "../../reuse/reducers/kaigoYougoMasterReducers/kaigoYougoMasterReducers";
import kanriReducers from "../../reuse/reducers/kanriReducers/kanriReducers";
import kohifutanMasterReducers from '../../reuse/reducers/kohifutanMasterReducers/kohifutanMasterReducers';
import LifeListReducers from "../../reuse/reducers/lifeListReducers/LifeListReducers";
import mediSyoubyoumeiMasterReducers from "../../reuse/reducers/mediSyoubyoumeiMasterReducers/mediSyoubyoumeiMasterReducers";
import messageListReducers from "../../reuse/reducers/messageListReducers/messageListReducers";
import progressReducers from "../../reuse/reducers/progressReducers/progressReducers";
import referenceNumberReducers from "../../reuse/reducers/referenceNumberReducers/referenceNumberReducers";
import riyouKaigohokenReducers from "../../reuse/reducers/riyouKaigohokenReducers/riyouKaigohokenReducers";
import riyousyaReducers from "../../reuse/reducers/riyousyaReducers/riyousyaReducers";
import roomMasterReducers from "../../reuse/reducers/roomMasterReducers/roomMasterReducers";
import sendGroupReducers from "../../reuse/reducers/sendGroupReducers/sendGroupReducers";
import SettingMasterReducers from "../../reuse/reducers/SettingMasterReducers/SettingMasterReducers";
import syokichiReducers from "../../reuse/reducers/syokichiReducers/syokichiReducers";
import systemNameListReducers from "../../reuse/reducers/systemControlReducers/systemControlReducers";
import tantoMasterListReducers from "../../reuse/reducers/tantoMasterListReducers/tantoMasterListReducers";
import testingReducers from "../../reuse/reducers/testingReducers/testingReducers";
import userListReducers from "../../reuse/reducers/userListReducers/userListReducers";
import yougoMasterNaiyouReducers from "../../reuse/reducers/yougoMasterNaiyouReducers/yougoMasterNaiyouReducers";
import ZipMasterReducers from "../../reuse/reducers/zipMasterReducers/zipMasterReducers";
import barthelIndexMasterReducers from "../../../reducers/barthelIndexMasterReducers/barthelIndexMasterReducers";

// management
import serviceKikanMaster from '../../reuse/reducers/serviceKikanMasterReducers/serviceKikanMaster';

const rootReducer = combineReducers({
  error,
  config,
  authlife,
  life,
  tantoMaster,
  jokusoPlan,
  yougoMaster,
  lifeYougoMaster,
  kobetsu,
  common,
  seikatuCheck,
  haisetsu,
  koku,
  jirituSokusin,
  yakuzai,
  kagakuteki,
  rihabiriNew: rihabiri,
  rihabili: rihabiliReducers,
  needsItem: needsItemReducers,
  riyousyamonthly: riyousyaMonthlyReducers,
  riyousyaHoken: riyousyaHokenReducers,
  iryouhokenMaster: iryouhokenMasterReducers,
  iryoKougakuFutanMaster: iryoKougakuFutanMasterReducers,
  tantoKaigi: tantoKaigiReducers,
  inquiryRequest: inquiryRequestReducers,
  barthelIndexMaster: barthelIndexMasterReducers,

  // clone code
  dataLogin: accountReducers,
  userList: userListReducers,
  sendGroup: sendGroupReducers,
  messageList: messageListReducers,
  dengonSendTo: dengonSendToReducers,
  testing: testingReducers,
  getAvatar: getAvatarReducers,
  tantoMasterList: tantoMasterListReducers,
  yougoMasterNaiyou: yougoMasterNaiyouReducers,
  drugMaster: drugMasterReducers,
  allMaster: allMasterReducers,
  syokichi: syokichiReducers,
  progress: progressReducers,
  system: systemNameListReducers,
  settingMaster: SettingMasterReducers,
  zipMaster: ZipMasterReducers,
  dutyReport: dutyReportReducers,
  LifeList: LifeListReducers,
  riyouKaigohokens: riyouKaigohokenReducers,
  riyousya: riyousyaReducers,
  kaigoYougoMaster: kaigoYougoMasterReducers,
  roomMaster: roomMasterReducers,
  kanri: kanriReducers,
  fileManagement: fileManagementReducers,
  kyotakuPlan1: kyotakuPlan1Reducers,
  kyotakuPlan2: kyotakuPlan2Reducers,
  doctorMaster: doctorMasterReducers,
  mediSyoubyoumeiMaster: mediSyoubyoumeiMasterReducers,
  referenceNumber: referenceNumberReducers,
  kaigoHokenMaster: kaigoHokenMasterReducers,
  kohifutanMaster: kohifutanMasterReducers,
  progressLIFE,
  itakuTantoMaster,

  serviceKikanMaster: serviceKikanMaster,
});

export default rootReducer;
