import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Table } from "antd";
import { connect } from "react-redux";
import { loadAdl } from "../../../actions/reportAdlActions/actionCreators";

class ReportAdlByDateModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // page: 1,
      // sort: {
      //   gijiroku_date: "desc"
      // },
      selectedRecord: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && (this.props.visible !== prevProps.visible)) {
      this.props.loadAdl({
        riyousyaId: this.props.riyousyaId,
        excludeId: this.props.reportAdlId //現在のレコードを除外する為
      }, (callback) => {
        //callback
      });
    }
  }

  handleOk = () => {
    this.props.onHide();
    const { selectedRecord } = this.state;

    if (selectedRecord && selectedRecord.id) {
      this.props.onSelect(selectedRecord);
    }
  }

  handleCancel = () => {
    this.props.onHide();
  }

  handleSelectRow(record) {
    this.setState({ selectedRecord: record });
  }

  handlePageChange(page) {
    // this.setState({ page });
    this.props.loadAdl({
      riyousyaId: this.props.riyousyaId,
      excludeId: this.props.reportAdlId
    }, (data) => {
      //callback
    });
  }

  render() {
    const { loading, adls} = this.props;

    const columns = [{
      title: "",
      dataIndex: "evaluationDate",
      key: "evaluationDate"
    }];
    const { selectedRecord } = this.state;
    const pagingOptions = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      // total: metaTantoKaigi && metaTantoKaigi.total || 0,
      // current: metaTantoKaigi && metaTantoKaigi.page || 1,
      pageSize: 20
    };

    return (
      (<Modal
        open={this.props.visible}
        title="前回引用"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="tanto-kaigi-by-date-modal"
      >
        <Table
          showHeader={false}
          rowKey="id"
          loading={loading}
          style={{ cursor: "pointer" }}
          size={"small"}
          bordered={true}
          columns={columns}
          dataSource={adls || []}
          pagination={pagingOptions}
          onRow={record => ({
            onClick: () => this.handleSelectRow(record)
          })}
          rowClassName={(record) => selectedRecord != null && record.id === selectedRecord.id
            ? "pljp-table-row-hover"
            : "pointer-row"
          }
        />
      </Modal>)
    );
  }
}

/**
 * 正しいporpsの確認
 */
  ReportAdlByDateModal.propTypes = {
  visible: PropTypes.bool,
  riyousyaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  onHide: PropTypes.func
};

const mapStateToProps = () => state => ({
  adls: state.reportAdl.adls,
  // metaTantoKaigi: state.reportAdl.metaTantoKaigi,
  loading: state.reportAdl.loading
});

const mapDispatchToProps = () => dispatch => ({
  loadAdl: (data, callback) => dispatch(loadAdl(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportAdlByDateModal);