import React, { useState } from "react";
import { Modal, Table, Button } from "antd";

function QuotePlan2ModalV2(props) {
  const [selected, setSelected] = useState({});
  const columns = [
    {
      title: "計画作成日",
      dataIndex: "planDate",
      key: "planDate",
      width: "120px",
    },
  ];
  function handleOk() {
    props.onOk(selected.id, props.riyousyaId);
  }
  function handleCancel() {
    props.onHideModal();
  }
  function handleSelectLastQuote(record) {
    setSelected(record);
  }
  function handleClose() {
    props.onClose();
  }
  return (
    (<Modal
      open={props.visible}
      title={props.title}
      // onOk={() => handleOk()}
      onCancel={handleClose}
      width={500}
      // okButtonProps={{ disabled: Object.keys(selected).length === 0 && selected.constructor === Object }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          キャンセル
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          disabled={
            Object.keys(selected).length === 0 &&
            selected.constructor === Object
          }
        >
          OK
        </Button>,
      ]}
    >
      <Table
        loading={props.loading}
        id={"last-quote-table"}
        bordered={true}
        showHeader={false}
        size={"small"}
        columns={columns}
        pagination={{ pageSize: 10 }}
        rowKey={"id"}
        dataSource={props.data}
        onRow={(record) => {
          return {
            onClick: () => handleSelectLastQuote(record),
          };
        }}
        rowClassName={(record) =>
          record.id === selected.id ? "pljp-table-row-hover" : "pointer-row"
        }
      />
    </Modal>)
  );
}

export { QuotePlan2ModalV2 };
