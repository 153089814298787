import { ApiPaths } from "../../constants/api_paths";
import { Plan2ActionTypes, ErrorActionTypes } from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils } from "../../utils/index";

function getQuoteList(riyousyaId, kyotakuPlan2Id) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: Plan2ActionTypes.GET_ALL_PLAN2_QUOTE_REQUEST });
      const query = {
        riyousyaId,
        kyotakuPlan2Id,
        sid,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.KyotakuPlan2.GetKyotakuPlan2ListQuote,
        query,
      });
      dispatch({
        type: Plan2ActionTypes.GET_ALL_PLAN2_QUOTE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: Plan2ActionTypes.GET_ALL_PLAN2_QUOTE_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getAll(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: Plan2ActionTypes.GET_ALL_PLAN2_REQUEST });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.KyotakuPlan2.GetKyotakuPlan2List,
        query,
      });
      dispatch({ type: Plan2ActionTypes.GET_ALL_PLAN2_SUCCESS, data });
    } catch (error) {
      dispatch({ type: Plan2ActionTypes.GET_ALL_PLAN2_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: Plan2ActionTypes.REMOVE_PLAN2_REQUEST });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.KyotakuPlan2.Default,
        query,
      });
      dispatch({ type: Plan2ActionTypes.REMOVE_PLAN2_SUCCESS, data });
    } catch (error) {
      dispatch({ type: Plan2ActionTypes.REMOVE_PLAN2_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getServiceKikan(bunrui, name) {
  return async (dispatch) => {
    const body = { bunrui, name };
    try {
      dispatch({ type: Plan2ActionTypes.GET_SERVICE_KIKAN });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ApiGeneral.GetServiceKikanForPlan2,
        body,
      });
      dispatch({
        type: Plan2ActionTypes.GET_SERVICE_KIKAN_SUCCESS,
        serviceKikan: [...data],
      });
    } catch (error) {
      dispatch({
        type: Plan2ActionTypes.GET_SERVICE_KIKAN_ERROR,
        error,
      });
    }
  };
}

function getInitialIryou() {
  return async (dispatch) => {
    try {
      dispatch({ type: Plan2ActionTypes.GET_INITIAL_IRYOU_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ApiGeneral.GetInitialIryou,
      });
      dispatch({
        type: Plan2ActionTypes.GET_INITIAL_IRYOU_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: Plan2ActionTypes.GET_INITIAL_IRYOU_FAILURE,
        error,
      });
    }
  };
}

function getIryouById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: Plan2ActionTypes.GET_IRYOU_BY_ID_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ApiGeneral.GetIryouById,
        params: { id },
      });
      dispatch({
        type: Plan2ActionTypes.GET_IRYOU_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: Plan2ActionTypes.GET_IRYOU_BY_ID_FAILURE,
        error,
      });
    }
  };
}

function createIryou(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({ type: Plan2ActionTypes.CREATE_IRYOU_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ApiGeneral.CreateIryou,
        body,
      });
      dispatch({
        type: Plan2ActionTypes.CREATE_IRYOU_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: Plan2ActionTypes.CREATE_IRYOU_FAILURE,
        error,
      });
    }
  };
}

function updateIryou(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({ type: Plan2ActionTypes.UPDATE_IRYOU_REQUEST });
      console.log({
        method: "PUT",
        path: ApiPaths.ApiGeneral.UpdateIryou,
        body,
      });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.ApiGeneral.UpdateIryou,
        body,
      });
      dispatch({
        type: Plan2ActionTypes.UPDATE_IRYOU_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: Plan2ActionTypes.UPDATE_IRYOU_FAILURE,
        error,
      });
    }
  };
}

export const Plan2Actions = {
  getQuoteList,
  getAll,
  deleteId,
  getServiceKikan,
  getInitialIryou,
  getIryouById,
  createIryou,
  updateIryou,
};
