export const Transcode = {
  SUCCESS: "削除成功",
  ERROR: "削除失敗",
  PARAMS_MISSING: "必須項目がセットされていません",
  DUPLICATE_ERROR: "重複登録はできません",
  ADD_SUCCESS: "保存しました",
  ADD_ERROR: "新規追加失敗",
  EDIT_SUCCESS: "保存しました",
  EDIT_ERROR: "修正失敗",
  FILE_LOADING_ERROR: "ファイルローディングはエラーが発生している",

  TYPE_FILE_ERROR: "対応不可のファイルです",
};
