/* eslint-disable */
import { Calendar } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Component } from 'react';
import { ConstSet } from '../../../common/configs/constset';
import datePickerJp from '../../../data/datePickerJp';

export class EventPlan extends Component {
    UNSAFE_componentWillReceiveProps(newProps) {
        if (JSON.stringify(newProps.data) !== JSON.stringify(this.props.data)) {
            this.props.handleSelectValues([]);
            let selectedCells = document.getElementsByClassName("date-plan-content");
            _.forEach(selectedCells, c => {
                c.style.backgroundColor = "transparent";
            });
        }
    }

    dateCellRender(date) {
        let formatedDate = date.format(ConstSet.DATE_FORMAT);
        let plans = this.props.data;
        let data = _.find(plans, i => i.date === formatedDate);
        return (
            <div
                className={"date-plan-content " + formatedDate}
                style={{ height: "100%" }}
            >
                <div
                    style={{ height: "100%", wordWrap: "break-word", overflowY: "auto", whiteSpace: "pre" }}>{data ? data.event : null}</div>
            </div>
        );
    }

    handleOnSelect(value) {
        let formatedDate = value.format(ConstSet.DATE_FORMAT);
        let selectedDates = this.props.selectedValues.slice();
        let d = _.find(selectedDates, i => i.format(ConstSet.DATE_FORMAT) === formatedDate);
        if (d) {
            selectedDates = _.filter(selectedDates, i => i.format(ConstSet.DATE_FORMAT) !== formatedDate);
            document.getElementsByClassName(formatedDate)[0].style.backgroundColor = "transparent";
        } else {
            selectedDates.push(value);
            document.getElementsByClassName(formatedDate)[0].style.backgroundColor = "pink";
        }
        this.props.handleSelectValues(selectedDates);
        this.props.handleSelectMonth(value);
    }

    render() {
        let date = this.props.selectedDate.toDate();
        let startDate = dayjs(new Date(date.getFullYear(), date.getMonth(), 1));
        let endDate = dayjs(new Date(date.getFullYear(), date.getMonth() + 1, 0));
        return (
            <Calendar
                dateCellRender={date => this.dateCellRender(date)}
                locale={datePickerJp}
                value={this.props.selectedDate}
                onSelect={value => this.handleOnSelect(value)}
                mode={"month"}
                validRange={[startDate, endDate]}
            />
        );
    }
}