import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Table } from "antd";
import { Plan2Actions } from "../../redux/actions/index";

function QuotePlan2Modal(props) {
  const [selected, setSelected] = useState({});
  const columns = [
    {
      title: "計画作成日",
      dataIndex: "planDate",
      key: "planDate",
      width: "120px",
    },
  ];
  useEffect(() => {
    props.getPlan2Quote(props.riyousyaId);
  }, []);
  function handleOk() {
    props.onOk(selected.id);
  }
  function handleCancel() {
    props.onHideModal();
  }
  function handleSelectLastQuote(record) {
    setSelected(record);
  }
  return (
    (<Modal
      open={props.visible}
      title={props.title}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      width={500}
      okButtonProps={{ disabled: selected.id == null }}
    >
      <Table
        id={"last-quote-table"}
        bordered={true}
        showHeader={false}
        size={"small"}
        columns={columns}
        pagination={{ pageSize: 10 }}
        rowKey={"id"}
        dataSource={props.listQuotePlan2}
        onRow={(record) => {
          return {
            onClick: () => handleSelectLastQuote(record),
          };
        }}
        rowClassName={(record) =>
          record.id === selected.id ? "pljp-table-row-hover" : "pointer-row"
        }
      />
    </Modal>)
  );
}

const mapStateToProps = (state) => {
  return {
    listQuotePlan2: state.plan2.listQuote,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlan2Quote: (riyousyaId) =>
      dispatch(Plan2Actions.getQuoteList(riyousyaId)),
  };
};
const QuotePlan2ModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotePlan2Modal);
export { QuotePlan2ModalContainer as QuotePlan2Modal };
