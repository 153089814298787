import { Form } from "@ant-design/compatible";
import React from "react";

// import '@ant-design/compatible/assets/index.css';


import { Input } from "antd";

const { TextArea } = Input;

class NormalKCommentKB extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.defaultValue !== newProps.defaultValue) {
      this.props.form.resetFields();
    }
  }

  handleOnBlur = (e) => {
    this.props.handleKCommentKB(e.target.value);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        {getFieldDecorator("ect", {
          rules: [
            {
              required: false,
            },
          ],
          initialValue: this.props.defaultValue,
        })(<TextArea rows={4} onBlur={this.handleOnBlur} />)}
      </div>
    );
  }
}

const KCommentKB = Form.create()(NormalKCommentKB);
export default KCommentKB;
