import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Spin
} from "antd";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  roomCreate,
  roomMasterInitCreator,
  roomUpdate,
} from "../../../actions/roomMasterActions/actionCreators";
import TextAreaSelectYougo from "./TextAreaSelectYougo";

const ADD = 1;
const EDIT = 2;
const { Option } = Select;

class RoomMasterEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      initCode: null,
      errorNameVisible: false,
      newText: "",
      yougoVisible: false,
      editIndex: null,
      editValue: "",
      editKey: null,
      // spin: true,
    };
  }

  componentDidUpdate(prevProps) {
    // this.props.ymRiyouUpdatePage();
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      if (this.props.data && !(this.props.editType === ADD)) {
        if (this.props.editType === EDIT) {
          this.setState({
            data: this.props.data,
          });
        }
      }
      else {
        this.props.roomMasterInitCreator((data) => {
          data.serviceKikanMaster = this.props.serviceKikan;
          const initDate = data ? { ...data, id: null, bedNum: 0 } : {};
          this.setState({
            data: initDate,
          });
        });
      }
    }
  }

  onChangeValue(e, field) {
    const { data } = this.state;

    e.stopPropagation();
    let newValue = data[field];
    switch (field) {
      case "stop":
        newValue = e.target.checked ? 1 : 0;
        break;
      default:
        newValue = e.target.value;
    }

    if (newValue !== data[field]) {
      this.setState({
        data: { ...data, [field]: newValue },
      });
    }
  }

  onChangeValueBunrui(valu) {
    const { data } = this.state;

    let newValue = data.bunrui;
    newValue = valu;

    if (newValue !== data.bunrui) {
      this.setState({
        data: { ...data, bunrui: newValue },
      });
    }
  }
  onChangeValueKyositu(valu) {
    const { data } = this.state;

    let newValue = data.kyosituShubetu;
    newValue = valu;

    if (newValue !== data.kyosituShubetu) {
      this.setState({
        data: { ...data, kyosituShubetu: newValue },
      });
    }
  }

  // onChangeCode(value, first) {
  //   const { data } = this.state;

  //   if (first) {
  //     this.setState({
  //       data: {...data, code: value},
  //       initCode: value
  //     })
  //   } else {
  //     this.setState({
  //       data: {...data, code: value},
  //     })
  //   }
  // }

  async handleSave() {
    let { data } = this.state;
    data = { ...data, serviceKikanMasterId: data.serviceKikanMaster.id };

    // if (this.checkRequired())
    //   return;

    if (data.id && data.id > 0) {
      await this.props.roomUpdate(data, () => {
        this.props.handleSelectRow(data);
      });
    }
    else {
      await this.props.roomCreate(data, () => {
        //   this.props.handleSelectRow(data)
      });
    }

    this.handleClose();
  }

  // checkRequired() {
  //   const { data } = this.state;

  //   let isError = false;
  //   if (_.isEmpty(data.byoumei)) {
  //     isError = true
  //     this.setState({
  //       errorNameVisible: true
  //     })
  //   } else {
  //     this.setState({
  //       errorNameVisible: false
  //     })
  //   }

  //   return isError;
  // }

  handleClose() {
    this.setState({
      errorNameVisible: false,
    });
    this.props.handleClose();
  }

  render() {
    const {
      data,
      errorNameVisible
    } = this.state;

    return (
      (<Fragment>
        <Modal
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => this.handleClose()}
          open={this.props.visible}
          width={800}
          footer={[
            <Row key={1} type="flex" justify="end">
              <div style={{ paddingRight: 8 }}>
                {/* <MasterCodeCancelButton
                  text={"戻る"}
                  code={initCode}
                  reference={reference}
                  checkType={this.props.editType === ADD}
                  onClose={() => this.handleClose()}
                /> */}
                <Button type={"primary"} onClick={() => this.handleClose()}>
                  {"戻る"}
                </Button>
              </div>
              <div>
                <Button type={"primary"} onClick={() => this.handleSave()}>
                  {"保存"}
                </Button>
              </div>
            </Row>,
          ]}
        >
          <Spin
            spinning={
              this.props && this.props.loading ? this.props.loading : false
            }
          >
            <Row>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>ID</p>
              </Col>
              <Col span={4}>
                <Input
                  value={data ? data.id : null}
                  onChange={(e) => this.onChangeValue(e, "id")}
                  readOnly
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>部屋名</p>
              </Col>
              <Col span={14}>
                <Input
                  value={data.room}
                  onChange={(e) => this.onChangeValue(e, "room")}
                  style={errorNameVisible ? { border: "1px solid red" } : {}}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>居室種別</p>
              </Col>
              <Col span={14}>
                <Select
                  value={data.kyosituShubetu}
                  style={{ width: "280px", marginLeft: 0 }}
                  onChange={(valu) => this.onChangeValueKyositu(valu)}
                >
                  <Option value={null}>&nbsp;</Option>
                  <Option value={"多床室"}>多床室</Option>
                  <Option value={"ユニット個室"}>ユニット個室</Option>
                  <Option value={"ユニット準個室"}>ユニット準個室</Option>
                  <Option value={"従来型個室"}>従来型個室</Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>分類</p>
              </Col>
              <Col span={14}>
                <TextAreaSelectYougo
                  value={data.bunrui}
                  dataSelect={this.props.roomBunrui}
                  yougoMasterUpdate={(naiyou) =>
                    this.props.yougoMasterUpdate(naiyou)
                  }
                  onChangeBunrui={(valu) => this.onChangeValueBunrui(valu)}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>母体事業所</p>
              </Col>
              <Col span={14}>
                <Input
                  value={
                    data && data.serviceKikanMaster
                      ? data.serviceKikanMaster.jigyousyoName
                      : null
                  }
                  onChange={(e) => this.onChangeValue(e, "serviceKikanMaster")}
                  style={
                    errorNameVisible
                      ? {
                        border: "1px solid red",
                        width: "420px",
                        marginRight: 5,
                      }
                      : { width: "420px", marginRight: 5 }
                  }
                  readOnly
                />
              </Col>
              <Col span={4}>
                <Input
                  value={
                    data && data.serviceKikanMaster
                      ? data.serviceKikanMaster.id
                      : null
                  }
                  onChange={(e) =>
                    this.onChangeValue(e, "serviceKikanMasterId")
                  }
                  style={
                    errorNameVisible
                      ? {
                        border: "1px solid red",
                        width: "65px",
                        marginRight: 5,
                      }
                      : { width: "65px", marginRight: 5 }
                  }
                  readOnly
                />
              </Col>
            </Row>
            {/* <Row style={{ marginTop: 10 }}> //sortNumを使う場合
              <Col span={6}>
                <p style={{ marginTop: 5 }}>順番</p>
              </Col>
              <Col span={4}>
                <Input value={data.sortNum} onChange={(e) => this.onChangeValue(e, "sortNum")} />
              </Col>
            </Row> */}
          </Spin>
        </Modal>
      </Fragment>)
    );
  }
}

const mapStateToProps = () => {
  return {
    // loading: state.allMaster.loading || state.referenceNumber.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    roomMasterInitCreator: (callback) =>
      dispatch(roomMasterInitCreator(callback)),
    roomCreate: (body, callback) => dispatch(roomCreate(body, callback)),
    roomUpdate: (body, callback) => dispatch(roomUpdate(body, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomMasterEditModal);
