import React, { Component } from "react";
import { connect } from "react-redux";

import { getRiyousya } from "../../../actions/riyousyaActions/actionCreators";
import {
  createTantoKaigi,
  getDataKiKanMaster,
  loadTantoKaigiById,
  resetCurrentTantoKaigi,
  updateTantoKaigi,
} from "../../../actions/tantoKaigiActions/actionCreators";
import { CommonPageContainer } from "../../../common/component/IdleTimerPage";
import TantoKaigiInputForm from "./TantoKaigiInputForm";
import "./styles.css";

class KaigiContainer extends Component {
  constructor(props) {
    super(props);
    const { tantoKaigiId, riyousyaId } = this.props.match.params;
    this.tantoKaigiId = tantoKaigiId;
    this.riyousyaId = riyousyaId;
  }

  componentDidMount() {
    this.props.getRiyousyaInfo(this.riyousyaId);
    if (this.tantoKaigiId !== "new") {
      this.props.loadTantoKaigiById(this.tantoKaigiId);
    }
    else {
      this.props.resetCurrentTantoKaigi();
    }
    window.scroll(0, 0);
  }

  regData(err, values) {
    if (err) return;

    if (this.tantoKaigiId === "new") {
      this.props.createTantoKaigi(values, this.riyousyaId, (data) => {
        if (data.id) {
          this.props.history.push(
            `/input-tanto-kaigi/${this.riyousyaId}/${data.id}`
          );
        }
      });
    }
    else {
      values["riyousyaId"] = this.riyousyaId;
      this.props.updateTantoKaigi(values, this.tantoKaigiId);
    }
  }

  regDataAndCheck(err, values) {
    if (err) return;

    if (this.tantoKaigiId !== "new") {
      values["riyousyaId"] = this.riyousyaId;
      this.props.updateTantoKaigi(values, this.tantoKaigiId);

      const win = window.open(
        `/check-info-document-list/${this.riyousyaId}/${this.tantoKaigiId}`,
        "_blank"
      );
      win.focus();
    }
  }

  render() {
    const { riyousya, tantoKaigi } = this.props;
    const { tantoKaigiId, riyousyaId } = this.props.match.params;
    this.riyousyaId = riyousyaId;
    this.tantoKaigiId = tantoKaigiId;

    return (
      <CommonPageContainer
        id="input-tanto-kaigi-page"
        sessionTimeout={this.props.sessionTimeout}
      >
        <TantoKaigiInputForm
          riyousyaId={this.riyousyaId}
          tantoKaigiId={this.tantoKaigiId}
          riyousya={riyousya}
          data={this.tantoKaigiId !== "new" ? tantoKaigi : {}}
          regData={(err, values) => this.regData(err, values)}
          regDataAndCheck={(err, values) => this.regDataAndCheck(err, values)}
          history={this.props.history}
          match={this.props.match}
          getDataKiKanMaster={(id, callback) =>
            this.props.getDataKiKanMaster(id, callback)
          }
        />
      </CommonPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    riyousya: state.riyousya.riyousya,
    tantoKaigi: state.tantoKaigi.tantoKaigi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
    loadTantoKaigiById: (id) => dispatch(loadTantoKaigiById(id)),
    createTantoKaigi: (data, rid, callback) =>
      dispatch(createTantoKaigi(data, rid, callback)),
    updateTantoKaigi: (data, id, callback) =>
      dispatch(updateTantoKaigi(data, id, callback)),
    resetCurrentTantoKaigi: () => dispatch(resetCurrentTantoKaigi()),
    getDataKiKanMaster: (id, callback) =>
      dispatch(getDataKiKanMaster(id, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KaigiContainer);
