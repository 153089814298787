import "whatwg-fetch";
import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const RoomMasterApi = {
  getRoomList() {
    const key = getMenu();
    return axios
      .get(apiUrls.GET_ROOM_LIST, {
        params: {
          sysName: JSON.parse(localStorage.getItem(key)).sn,
        },
      })
      .then((res) => {
        return res.data;
      });
  },

  loadDataInit() {
    return axios(apiUrls.INIT + apiUrls.GET_ROOM_MASTER_INIT, {
      method: "GET",
    }).then((res) => {
      return res.data;
    });
  },

  createRoom(data) {
    return axios.post(apiUrls.CREATE_ROOM_MASTER, data).then((res) => {
      return res.data;
    });
  },

  updateRoom(data) {
    return axios.put(apiUrls.UPDATE_ROOM_MASTER, data).then((res) => {
      return res.data;
    });
  },

  saveRoomList(data) {
    return axios.put(apiUrls.SAVE_ROOM_LIST, data).then((res) => {
      return res.data;
    });
  },
};

export { RoomMasterApi };
