import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';


import { Button, Col, Modal, Row, Select } from "antd";
import _ from "lodash";
import React from "react";
import { guid } from "../../../common/timePicker/formatTimePicker";

const FormItem = Form.Item;
const Option = Select.Option;
const FA = require("react-fontawesome");

export default class SortControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      sortData: this.props.sortData,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.sortData) !== JSON.stringify(this.props.sortData)
    ) {
      this.setState({
        sortData: nextProps.sortData,
      });
    }
  }

  handleChangeOption(value, i) {
    const newValue = _.cloneDeep(this.state.sortData);
    newValue["key" + i] = value;
    this.setState({ sortData: newValue });
  }

  handleChangeOrder(value, i) {
    const newValue = _.cloneDeep(this.state.sortData);
    newValue["value" + i] = value;
    this.setState({ sortData: newValue });
  }

  showModalSortConditions() {
    this.setState({
      visible: true,
    });
  }

  handleSortOk() {
    this.props.handleSortData(this.state.sortData);
    this.setState({
      visible: false,
    });
  }

  handleSortCancel() {
    this.setState({
      visible: false,
      sortData: this.props.sortData,
    });
  }

  render() {
    const { sortData } = this.state;
    const { sortOptions } = this.props;
    const count = this.props.count ? this.props.count : 5;
    const children = [];
    for (let i = 1; i <= count; i++) {
      const selected = [];
      for (let j = 1; j <= count; j++) {
        if (j !== i && sortData["key" + j] !== "0") {
          selected.push(sortData["key" + j]);
        }
      }
      children.push(
        <Row key={"condition-no-" + i}>
          <Col xs={4}>
            <FormItem label={"第" + i + "キー::"} />
          </Col>
          <Col xs={14}>
            <Select
              style={{ width: "90%" }}
              value={sortData["key" + i]}
              onChange={(value) => this.handleChangeOption(value, i)}
            >
              <Option key={guid()} value="0">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Option>
              {sortOptions.map((item) => (
                <Option
                  key={guid()}
                  value={item.value}
                  disabled={selected.indexOf(item.value) >= 0}
                >
                  {item.display}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={6}>
            <Select
              style={{ width: "100%" }}
              value={sortData["value" + i]}
              onChange={(value) => this.handleChangeOrder(value, i)}
            >
              <Option value="asc">昇順</Option>
              <Option value="desc">降順</Option>
            </Select>
          </Col>
        </Row>
      );
    }
    return (
      (<div style={this.props.style}>
        <Button type="primary" onClick={() => this.showModalSortConditions()}>
          <FA name="sort" key={guid()} style={{ paddingRight: "4px" }} />
          並替
        </Button>
        <Modal
          title="並び替え"
          open={this.state.visible}
          onOk={() => this.handleSortOk()}
          onCancel={() => this.handleSortCancel()}
          className={"sort-model"}
        >
          <div className={"kn-search-con"}>{children}</div>
        </Modal>
      </div>)
    );
  }
}
