/* eslint-disable brace-style */
/* eslint-disable indent */
import dayjs from "dayjs";
import { openNotificationWithIcon } from "../../../common/function_common/functionCommon";
import { ConfigName } from "../../../config-name";
import { CommonUtils, StorageUtils } from "../../utils/index";
import { jokusoPlanActionTypes } from "../action_types";

function jokusoPlan(state = {}, action) {
  switch (action.type) {
    case jokusoPlanActionTypes.GET_ALL_JOKUSO_PLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case jokusoPlanActionTypes.GET_ALL_JOKUSO_PLAN_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case jokusoPlanActionTypes.GET_ALL_JOKUSO_PLAN_FAILURE: {
      return {
        ...state,
        data: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }
    case jokusoPlanActionTypes.REMOVE_JOKUSO_PLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case jokusoPlanActionTypes.REMOVE_JOKUSO_PLAN_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case jokusoPlanActionTypes.REMOVE_JOKUSO_PLAN_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case jokusoPlanActionTypes.SAVE_CREATE_JOKUSO_PLAN_REQUEST:
      return { ...state, loading: true };
    case jokusoPlanActionTypes.SAVE_CREATE_JOKUSO_PLAN_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        jokusoPlan: action.jokusoPlan,
      };
    case jokusoPlanActionTypes.SAVE_CREATE_JOKUSO_PLAN_ERROR:
      return { ...state, loading: false };

    case jokusoPlanActionTypes.SAVE_UPDATE_JOKUSO_PLAN_REQUEST:
      return { ...state, loading: true };
    case jokusoPlanActionTypes.SAVE_UPDATE_JOKUSO_PLAN_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        jokusoPlan: action.jokusoPlan,
      };
    case jokusoPlanActionTypes.SAVE_UPDATE_JOKUSO_PLAN_ERROR:
      return { ...state, loading: false };

    case jokusoPlanActionTypes.SET_NEW_DATA:
      return {
        ...state,
        jokusoPlan: {},
        loading: false,
      };

    case jokusoPlanActionTypes.GET_YOUGO_MASTER:
    case jokusoPlanActionTypes.GET_YOUGO_MASTER_SUCCESS:
      return {
        ...state,
        yougoNaiyou:
          action.yougoNaiyouMaster && action.yougoNaiyouMaster.naiyou
            ? action.yougoNaiyouMaster.naiyou.split(";").map((v, i) => {
                return {
                  key: i,
                  naiyou: v,
                  editable: false,
                };
              })
            : [],
      };
    case jokusoPlanActionTypes.GET_LIFE_YOUGO_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case jokusoPlanActionTypes.GET_LIFE_YOUGO_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        lifeYougoNaiyou: action.lifeYougoMasters,
      };
    case jokusoPlanActionTypes.GET_LIFE_YOUGO_MASTER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case jokusoPlanActionTypes.SAVE_YOUGO_MASTER:
      return { ...state };
    case jokusoPlanActionTypes.SAVE_YOUGO_MASTER_SUCCESS:
      return {
        ...state,
        yougoNaiyou: action.naiyou
          ? action.naiyou.split(";").map((v, i) => {
              return {
                key: i,
                naiyou: v,
                editable: false,
              };
            })
          : [],
      };
    case jokusoPlanActionTypes.GET_YOUGO_MASTER_ERROR:
    case jokusoPlanActionTypes.GET_LIFE_YOUGO_MASTER_ERROR:
    case jokusoPlanActionTypes.SAVE_YOUGO_MASTER_ERROR:
    case jokusoPlanActionTypes.HOME_YOUGO_EDIT_STATE:
      return {
        ...state,
        yougoNaiyou: (state.yougoNaiyou || []).map((item, index) => {
          if (index === action.index) {
            item.editable = true;
          } else {
            item.editable = false;
          }
          return item;
        }),
      };
    case jokusoPlanActionTypes.HOME_YOUGO_CANCEL_STATE:
      return {
        ...state,
        yougoNaiyou: state.yougoNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = false;
          }
          return item;
        }),
      };
    case jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_ID_REQUEST:
      return { ...state, loading: true };
    case jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_ID_SUCCESS:
      return {
        ...state,
        expandedRowKeys: null,
        jokusoPlan: action.jokusoPlan,
        loading: false,
      };
    case jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_ID_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        jokusoPlan: null,
        loading: false,
      };
    case jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_IDS_REQUEST:
      return { ...state, loading: true };
    case jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_IDS_SUCCESS: {
      return {
        ...state,
        jokusoPlanList: action.jokusoPlanList,
        loading: false,
      };
    }
    case jokusoPlanActionTypes.LOAD_JOKUSOPLAN_BY_IDS_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        jokusoPlanList: [],
        loading: false,
      };
    case jokusoPlanActionTypes.CLEAR_JOKUSOPLAN_BY_IDS:
      return {
        ...state,
        jokusoPlanList: [],
      };
    case jokusoPlanActionTypes.CLONE_JOKUSOPLAN_BY_ID_REQUEST:
      return { ...state, loading: true };
    case jokusoPlanActionTypes.CLONE_JOKUSOPLAN_BY_ID_SUCCESS:
      action.jokusoPlan.id = null;
      action.jokusoPlan.kakuteif = null;
      action.jokusoPlan.makeDate = dayjs(new Date()).format("YYYY-MM-DD");
      action.jokusoPlan.tantoId = parseInt(
        StorageUtils.getValue(ConfigName.SWLIFE_SWPSPS).tid
      );
      return {
        ...state,
        expandedRowKeys: null,
        jokusoPlan: action.jokusoPlan,
        loading: false,
      };

    case jokusoPlanActionTypes.CLONE_JOKUSOPLAN_BY_ID_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        jokusoPlan: null,
        loading: false,
      };
    case jokusoPlanActionTypes.GET_LIST_IDS_REQUEST:
      return { ...state };
    case jokusoPlanActionTypes.GET_LIST_IDS_ERROR:
      return { ...state };
    case jokusoPlanActionTypes.GET_LIST_IDS_SUCCESS:
      return { ...state, idsJokusoPlan: action.idsJokusoPlan };
    case jokusoPlanActionTypes.GET_FIRST_LOAD_DATA:
      return {
        ...state,
        firstData: action.data,
      };
    case jokusoPlanActionTypes.GET_FIRST_LOAD_DATA_ERROR:
      return { ...state };
    case jokusoPlanActionTypes.LOAD_JOKUSO_TO_QUOTE_REQUEST:
    case jokusoPlanActionTypes.LOAD_JOKUSO_TO_QUOTE_SUCCESS:
      return {
        ...state,
        jokusoPlans: action.data,
      };
    case jokusoPlanActionTypes.LOAD_JOKUSO_TO_QUOTE_FAILURE:
      return {
        ...state,
        jokusoPlans: [],
      };
    case jokusoPlanActionTypes.LOAD_JOKUSO_TO_QUOTE:
      openNotificationWithIcon("success", "前回引用を実施しました");
      return {
        ...state,
        jokusoPlan: action.data,
        loading: false,
      };

    default:
      return { ...state };
  }
}

export default jokusoPlan;
