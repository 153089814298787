import React from "react";
import { Row, Col, Divider } from "antd";
import LineBreakText from "../../../common/component/LineBreakText";

const KikiInfo = (props) => {
  let items =
    props.riyousya &&
    props.riyousya.riyouKiki &&
    props.riyousya.riyouKiki.length > 0
      ? props.riyousya.riyouKiki
      : null;

  return (
    <div>
      {items
        ? items.map((item, index) => {
            return (
              <Row key={index}>
                <Col sm={8}>
                  <span className="rdp-basic-tab-title">{item.kikimei}</span>
                </Col>
                <Col sm={16}>
                  <span className="rdp-basic-tab-content">
                    <LineBreakText text={item.bikou ? item.bikou : "　"} />
                  </span>
                </Col>
                <Divider />
              </Row>
            );
          })
        : null}
    </div>
  );
};

export default KikiInfo;
