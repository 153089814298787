export const MonitoringActionTypes = {
  GET_ALL_MONITORING_REQUEST: "GET_ALL_MONITORING_REQUEST",
  GET_ALL_MONITORING_SUCCESS: "GET_ALL_MONITORING_SUCCESS",
  GET_ALL_MONITORING_FAILURE: "GET_ALL_MONITORING_FAILURE",

  GET_MONITORING_REQUEST: "GET_MONITORING_REQUEST",
  GET_MONITORING_SUCCESS: "GET_MONITORING_SUCCESS",
  GET_MONITORING_FAILURE: "GET_MONITORING_FAILURE",

  CREATE_MONITORING_REQUEST: "CREATE_MONITORING_REQUEST",
  CREATE_MONITORING_SUCCESS: "CREATE_MONITORING_SUCCESS",
  CREATE_MONITORING_FAILURE: "CREATE_MONITORING_FAILURE",

  UPDATE_MONITORING_REQUEST: "UPDATE_MONITORING_REQUEST",
  UPDATE_MONITORING_SUCCESS: "UPDATE_MONITORING_SUCCESS",
  UPDATE_MONITORING_FAILURE: "UPDATE_MONITORING_FAILURE",

  REMOVE_MONITORING_REQUEST: "REMOVE_MONITORING_REQUEST",
  REMOVE_MONITORING_SUCCESS: "REMOVE_MONITORING_SUCCESS",
  REMOVE_MONITORING_FAILURE: "REMOVE_MONITORING_FAILURE",
};
