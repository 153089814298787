import { Button } from "antd";
import React from "react";

import { Link } from "react-router-dom";

import { addZeroToDateString } from "../../../common/function_common/functionCommon";

export default class TableRowButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: addZeroToDateString(localStorage.getItem("nyuDate")),
    };
  }

  onClickEditRow = () => {
    this.props.editRow();
  };

  onClickViewThePlan = () => {
    const { riyousyaId } = this.props;
    const currentMenu = "record0business1";
    localStorage.setItem("currentmenu", currentMenu);
    // this.props.history.push(`/document-list/${riyousyaId}`);
    const win = window.open(`/document-list/${riyousyaId}`, "_blank");
    win.focus();
  };

  render() {
    const { riyousyaId } = this.props;
    const tabActive = localStorage.getItem("tabActive");
    return (
      <span style={{ textAlign: "center" }} className={"table-row-bt"}>
        <Button type="primary">
          <Link to={`/riyousya-detail/${riyousyaId}`}>利用者詳細</Link>
        </Button>
        <Button type="primary">
          <Link to={`/report/${riyousyaId}`}>利用者別へ</Link>
        </Button>
        <Button type="primary">
          <Link to={`/progress/${riyousyaId}?dateSelected=${this.state.date}`}>
            経過記録へ
          </Link>
        </Button>
        {tabActive && tabActive === "利用者" ? null : (
          <Button type="primary" danger onClick={this.onClickEditRow}>
            記録修正
          </Button>
        )}
        <Button type="primary">
          <Link to={`/report-weekly/${riyousyaId}`}>ウィークリー</Link>
        </Button>
        {tabActive && tabActive === "食事摂取" ? (
          <Button type="primary">
            <Link to={`/riyousya-shokuji/${riyousyaId}`}>食事せん</Link>
          </Button>
        ) : null}
        <Button type="primary">
          <Link to={`/plan/${riyousyaId}?dateSelected=${this.state.date}`}>
            個別予定
          </Link>
        </Button>
        <Button type="primary" onClick={this.onClickViewThePlan}>
          計画書閲覧
        </Button>
      </span>
    );
  }
}
