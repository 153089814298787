/* eslint-disable no-nested-ternary */
import { Button, Col, Input, Popover, Row, Select, Table } from "antd";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import {
  tantoMasterFilterCreator,
  tantoMasterListKikanCreator,
} from "../../../actions/tantoMasterListActions/actionCreators";

const { Option } = Select;
const { Search } = Input;

const columns = [
  { title: "部署名", dataIndex: "department", key: "department", width: 155 },
  {
    title: "夜勤明け",
    dataIndex: "nightshiftafter",
    key: "nightshiftafter",
    width: 155,
  },
  { title: "早出", dataIndex: "goearly", key: "goearly", width: 155 },
  { title: "日勤", dataIndex: "dayshift", key: "dayshift", width: 155 },
  { title: "半勤", dataIndex: "hafttime", key: "hafttime", width: 155 },
  { title: "遅出", dataIndex: "late", key: "late", width: 155 },
  { title: "夜勤入り", dataIndex: "nightshift", key: "nightshift" },
];
const columnsModal = [
  { title: "整理番号", dataIndex: "code", key: "code", width: 120 },
  { title: "氏名", dataIndex: "name", key: "name", width: 180 },
  { title: "資格", dataIndex: "sikaku", key: "sikaku" },
];

class WorkingStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tantoMasters: null,
      visible: false,
      tantoSelected: null,
      rowActive: null,
      columnsSelected: null,
      dataWorkInit: {
        id: null,
        busho: null,
        akeTanto: null,
        hayadeTanto: null,
        nikinTanto: null,
        hankinTanto: null,
        osodeTanto: null,
        yakinTanto: null,
      },
      selectedRowKeys: [],
      selectedBunrui: "",
      searchText: "",
      page: 1,
      focusColumns: false,
      click: false,
      dataWork: [],
      dataWorkFormat: {},
      oldContentColumn: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tantoMasters != this.props.tantoMasters) {
      this.setState({
        tantoMasters: nextProps.tantoMasters,
      });
    }
    if (nextProps.value != this.state.dataWork) {
      this.setState({
        dataWork: nextProps.value,
        dataWorkFormat: {},
      });
    }
  }

  handleVisibleChange = (visible) => {
    const { selectedBunrui, page, searchText } = this.state;
    if (!this.state.visible) {
      this.props.getTantoMasterList(selectedBunrui, searchText, page);
    }
    this.setState({ visible, rowActive: null });
  };

  handleChangeBunrui(value) {
    this.setState({
      selectedBunrui: value,
    });
  }
  handlePageChange(page) {
    const { selectedBunrui, searchText } = this.state;
    this.setState({
      page,
      rowActive: null,
    });
    this.props.getTantoMasterList(selectedBunrui, searchText, page);
  }

  handleFilterTanto(value) {
    const { selectedBunrui, page } = this.state;
    this.props.getTantoMasterList(selectedBunrui, value, page);

    this.setState({
      searchText: value,
      rowActive: null,
    });
  }

  handleFocusColumns(e) {
    this.setState({
      columnsSelected: e.target.id.toString(),
    });
    if (!this.state.focusColumns) {
      this.setState({
        focusColumns: true,
      });
    }
  }

  handleSelectTanto = (record, rowIndex) => {
    const { columnsSelected, dataWorkInit } = this.state;
    if (columnsSelected == null) {
      return;
    }
    const depart = columnsSelected.split("-")[0];
    const shiftName = columnsSelected.split("-")[1];
    const dataWork = _.find(this.props.value, { busho: depart });
    let oldContentColumn = "";
    if (dataWork) {
      switch (shiftName) {
        case "ake_tanto":
          oldContentColumn = dataWork.akeTanto;
          break;
        case "hayade_tanto":
          oldContentColumn = dataWork.hayadeTanto;
          break;
        case "nikin_tanto":
          oldContentColumn = dataWork.nikinTanto;
          break;
        case "hankin_tanto":
          oldContentColumn = dataWork.hankinTanto;
          break;
        case "osode_tanto":
          oldContentColumn = dataWork.osodeTanto;
          break;
        case "yakin_tanto":
          oldContentColumn = dataWork.yakinTanto;
          break;
        default:
          oldContentColumn = "";
          break;
      }
    }
    else {
      oldContentColumn = "";
    }

    let newDataWork = { ...dataWorkInit };
    let contentColumn = null;
    if (oldContentColumn != null) {
      if (oldContentColumn.trim().length > 1) {
        if (
          oldContentColumn
            .trim()
            .substr(oldContentColumn.trim().length - 1, 1) == "、"
        ) {
          contentColumn = oldContentColumn.concat(record.name);
        }
        else {
          contentColumn = oldContentColumn.concat("、" + record.name);
        }
      }
      else {
        contentColumn = record.name;
      }
    }
    else {
      contentColumn = record.name;
    }
    let newValueForm = null;

    if (dataWork) {
      if (shiftName == "ake_tanto") {
        newDataWork = { ...dataWork, akeTanto: contentColumn };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "hayade_tanto") {
        newDataWork = { ...dataWork, hayadeTanto: contentColumn };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "nikin_tanto") {
        newDataWork = { ...dataWork, nikinTanto: contentColumn };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "hankin_tanto") {
        newDataWork = { ...dataWork, hankinTanto: contentColumn };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "osode_tanto") {
        newDataWork = { ...dataWork, osodeTanto: contentColumn };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "yakin_tanto") {
        newDataWork = { ...dataWork, yakinTanto: contentColumn };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      newValueForm = [
        ...this.props.value.filter((data) => data.busho != depart),
        newDataWork,
      ];
      this.props.getDataFromModalStaff("working_status", newValueForm);
    }
    else {
      if (shiftName == "ake_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          akeTanto: contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "hayade_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          hayadeTanto: contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "nikin_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          nikinTanto: contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "hankin_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          hankinTanto: contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "osode_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          osodeTanto: contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "yakin_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          yakinTanto: contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      newValueForm = [...this.props.value, newDataWork];
      this.props.getDataFromModalStaff("working_status", newValueForm);
    }
    this.setState({
      rowActive: rowIndex,
    });
  };

  onChangeTextArea(e) {
    const { columnsSelected, dataWorkInit } = this.state;
    const depart = columnsSelected.split("-")[0];
    const shiftName = columnsSelected.split("-")[1];
    const dataWork = _.find(this.props.value, { busho: depart });
    let newDataWork = { ...dataWorkInit };
    const contentColumn = e.target.value;
    let newValueForm = null;
    if (dataWork) {
      if (shiftName == "ake_tanto") {
        newDataWork = {
          ...dataWork,
          akeTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "hayade_tanto") {
        newDataWork = {
          ...dataWork,
          hayadeTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "nikin_tanto") {
        newDataWork = {
          ...dataWork,
          nikinTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "hankin_tanto") {
        newDataWork = {
          ...dataWork,
          hankinTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "osode_tanto") {
        newDataWork = {
          ...dataWork,
          osodeTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "yakin_tanto") {
        newDataWork = {
          ...dataWork,
          yakinTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }

      newValueForm = [
        ...this.props.value.filter((data) => data.busho != depart),
        newDataWork,
      ];
      this.props.getDataFromModalStaff("working_status", newValueForm);
    }
    else {
      if (shiftName == "ake_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          akeTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "hayade_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          hayadeTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "nikin_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          nikinTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "hankin_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          hankinTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "osode_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          osodeTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }
      if (shiftName == "yakin_tanto") {
        newDataWork = {
          ...newDataWork,
          busho: depart,
          yakinTanto: contentColumn.length == 0 ? null : contentColumn,
        };
        this.setState({
          dataWorkFormat: newDataWork,
        });
      }

      newValueForm = [...this.props.value, newDataWork];
      this.props.getDataFromModalStaff("working_status", newValueForm);
    }
  }

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange([...this.props.value, ...changedValue]);
    }
  };

  render() {
    // load data form
    const { yougoMasterDepartNaiyou, yougoMasterRoomNaiyou } = this.props;
    const { selectedBunrui, tantoMasters, dataWorkFormat, rowActive } =
      this.state;

    const selectedTextArea = document.activeElement;
    if (selectedTextArea.id == "searchInput") {
      document.activeElement.blur();
    }

    const paging = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: this.props.meta ? this.props.meta.total : 0,
      current: this.props.meta ? this.props.meta.page : 1,
      pageSize: this.props.meta ? this.props.meta.pageSize : 6,
    };
    const departFilter = Array.isArray(yougoMasterDepartNaiyou)
      ? yougoMasterDepartNaiyou
      : [];
    const dataTable = Array.isArray(departFilter)
      ? departFilter.map((e) => {
        const dataWork = _.find(this.props.value, { busho: e.naiyou });
        return {
          key: e.key,
          department: e.naiyou,
          nightshiftafter: (
            <div>
              <Input.TextArea
                id={e.naiyou + "-ake_tanto"}
                onFocus={(e) => this.handleFocusColumns(e)}
                value={
                  dataWorkFormat && dataWorkFormat.busho == e.naiyou
                    ? dataWorkFormat.akeTanto
                    : dataWork
                      ? dataWork.akeTanto
                      : ""
                }
                onChange={(value) => this.onChangeTextArea(value)}
                style={{ width: "100%" }}
              ></Input.TextArea>
            </div>
          ),
          goearly: (
            <div>
              <Input.TextArea
                id={e.naiyou + "-hayade_tanto"}
                onFocus={(e) => this.handleFocusColumns(e)}
                value={
                  dataWorkFormat && dataWorkFormat.busho == e.naiyou
                    ? dataWorkFormat.hayadeTanto
                    : dataWork
                      ? dataWork.hayadeTanto
                      : ""
                }
                onChange={(value) => this.onChangeTextArea(value)}
                style={{ width: "100%" }}
              ></Input.TextArea>
            </div>
          ),
          dayshift: (
            <div>
              <Input.TextArea
                id={e.naiyou + "-nikin_tanto"}
                onFocus={(e) => this.handleFocusColumns(e)}
                value={
                  dataWorkFormat && dataWorkFormat.busho == e.naiyou
                    ? dataWorkFormat.nikinTanto
                    : dataWork
                      ? dataWork.nikinTanto
                      : ""
                }
                onChange={(value) => this.onChangeTextArea(value)}
                style={{ width: "100%" }}
              ></Input.TextArea>
            </div>
          ),
          hafttime: (
            <div>
              <Input.TextArea
                id={e.naiyou + "-hankin_tanto"}
                onFocus={(e) => this.handleFocusColumns(e)}
                value={
                  dataWorkFormat && dataWorkFormat.busho == e.naiyou
                    ? dataWorkFormat.hankinTanto
                    : dataWork
                      ? dataWork.hankinTanto
                      : ""
                }
                onChange={(value) => this.onChangeTextArea(value)}
                style={{ width: "100%" }}
              ></Input.TextArea>
            </div>
          ),
          late: (
            <div>
              <Input.TextArea
                id={e.naiyou + "-osode_tanto"}
                onFocus={(e) => this.handleFocusColumns(e)}
                value={
                  dataWorkFormat && dataWorkFormat.busho == e.naiyou
                    ? dataWorkFormat.osodeTanto
                    : dataWork
                      ? dataWork.osodeTanto
                      : ""
                }
                onChange={(value) => this.onChangeTextArea(value)}
                style={{ width: "100%" }}
              ></Input.TextArea>
            </div>
          ),
          nightshift: (
            <div>
              <Input.TextArea
                id={e.naiyou + "-yakin_tanto"}
                onFocus={(e) => this.handleFocusColumns(e)}
                value={
                  dataWorkFormat && dataWorkFormat.busho == e.naiyou
                    ? dataWorkFormat.yakinTanto
                    : dataWork
                      ? dataWork.yakinTanto
                      : ""
                }
                onChange={(value) => this.onChangeTextArea(value)}
                style={{ width: "100%" }}
              ></Input.TextArea>
            </div>
          ),
        };
      })
      : null;

    const data =
      tantoMasters && Array.isArray(tantoMasters)
        ? tantoMasters.map((tanto) => {
          return {
            id: tanto.id,
            code: tanto.code,
            name: tanto.name,
            sikaku: tanto.sikaku,
          };
        })
        : [];
    const content = (
      <div style={{ width: "430px" }}>
        <Row className="pad-bt-8">
          <Col span={8}>
            <Select
              defaultValue={selectedBunrui}
              style={{ width: "100%" }}
              onChange={(value) => this.handleChangeBunrui(value)}
            >
              <Option value="" key={0}>
                全表示
              </Option>
              {Array.isArray(yougoMasterRoomNaiyou)
                ? yougoMasterRoomNaiyou.map((item) => {
                  return (
                    <Option key={item.key} value={item.naiyou}>
                      {item.naiyou}
                    </Option>
                  );
                })
                : []}
            </Select>
          </Col>
          <Col span={16}>
            <Search
              id={"searchInput"}
              enterButton={"検索"}
              onSearch={(value) => this.handleFilterTanto(value)}
            />
          </Col>
        </Row>
        <Row>
          <Table
            bordered
            columns={columnsModal}
            dataSource={data}
            pagination={paging}
            size={"small"}
            rowKey={"id"}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.handleSelectTanto(record, rowIndex);
                }, // click row
              };
            }}
            rowClassName={(record, index) =>
              rowActive != null && index === rowActive
                ? "pljp-table-row-hover"
                : "pointer-row"
            }
          />
          {/* </Spin>*/}
        </Row>
      </div>
    );
    return (
      <div style={{ hieght: "200px", paddingTop: 20 }}>
        <Row style={{ justifyContent: "flex-end" }}>
          <Popover
            placement="leftBottom"
            content={content}
            title="記録担当者"
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
          >
            <Button
              onFocus={() => this.setState({ focusColumns: true })}
              type="primary"
              style={{ marginBottom: 8 }}
            >
              職員セット
            </Button>
          </Popover>
        </Row>
        <Table
          columns={columns}
          bordered={true}
          header={null}
          footer={null}
          // scroll={{ x: 650 }}
          size={"middle"}
          pagination={{ defaultPageSize: 4 }}
          dataSource={dataTable}
          rowKey={"key"}
        ></Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tantoMasters: state.tantoMasterList.data,
    meta: state.tantoMasterList.meta,
    yougoMasterDepartNaiyou: state.yougoMasterNaiyou.yougoMasterDepartNaiyou,
    yougoMasterRoomNaiyou: state.yougoMasterNaiyou.yougoMasterRoomNaiyou,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTantoMasterList: (bunrui, searchName, page) =>
      dispatch(tantoMasterListKikanCreator(bunrui, searchName, page)),
    tantoMasterFilterCreator: (dataFilter) =>
      dispatch(tantoMasterFilterCreator(dataFilter)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkingStatus);
