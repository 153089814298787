import thunk from "redux-thunk";
import websocket from "./websocket";

let middlewares = [thunk, websocket];

if (process.env.NODE_ENV !== "production") {
  const { logger } = require("redux-logger");
  middlewares = [...middlewares, logger];
}

export default middlewares;
