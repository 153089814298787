import { dutyReportApi } from "../../api/dutyReport";
import {
  LOAD_DUTY_REPORT_REQUEST,
  LOAD_DUTY_REPORT_SUCCESS,
  CREATE_DUTY_REPORT_SUCCESS,
  CREATE_DUTY_REPORT_ERROR,
  EDIT_DUTY_REPORT_SUCCESS,
  EDIT_DUTY_REPORT_ERROR,
  STATISTICAL_OUT_IN,
  STATISTICAL_OUT_IN_BYKIND,
  PEOPLE_OUT_IN_BYKIND,
  RESET_STATUS,
  SAVE_ERROR,
} from "./actionName";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

export function loadDutyReportCreator(parameterObj) {
  return (dispatch) => {
    dispatch({
      type: LOAD_DUTY_REPORT_REQUEST,
    });
    dutyReportApi
      .loadDutyReport(parameterObj)
      .then((data) => {
        dispatch({
          type: LOAD_DUTY_REPORT_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LOAD_DUTY_REPORT_SUCCESS,
          payload: [],
        });
      });
  };
}

export function createDutyReportCreator(parameterObj) {
  return (dispatch) => {
    dutyReportApi.createDutyReport(parameterObj).then(
      (data) =>
        dispatch({
          type: CREATE_DUTY_REPORT_SUCCESS,
          payload: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_DUTY_REPORT_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function editDutyReportCreator(parameterObj) {
  return (dispatch) => {
    dutyReportApi.updateDutyReport(parameterObj).then(
      (data) =>
        dispatch({
          type: EDIT_DUTY_REPORT_SUCCESS,
          payload: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: EDIT_DUTY_REPORT_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function loadStatisticalOutInCreator(parameterObj) {
  return (dispatch) => {
    dutyReportApi.getStatisticalOutIn(parameterObj).then((data) =>
      dispatch({
        type: STATISTICAL_OUT_IN,
        payload: data,
      })
    );
  };
}

export function loadStatisticalOutInByKindCreator(parameterObj) {
  return (dispatch) => {
    dutyReportApi.getStatisticalOutInByKind(parameterObj).then((data) =>
      dispatch({
        type: STATISTICAL_OUT_IN_BYKIND,
        payload: data,
      })
    );
  };
}

export function loadListPeopleOutInByKindCreator(parameterObj) {
  return (dispatch) => {
    dutyReportApi.getListPeopleOutInByKind(parameterObj).then((data) =>
      dispatch({
        type: PEOPLE_OUT_IN_BYKIND,
        payload: data,
      })
    );
  };
}
export function resetStatus() {
  return (dispatch) =>
    dispatch({
      type: RESET_STATUS,
    });
}
export function saveError() {
  return (dispatch) =>
    dispatch({
      type: SAVE_ERROR,
    });
}
function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
