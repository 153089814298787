export const TaskActionTypes = {
  GET_ALL_TASK_REQUEST: "GET_ALL_TASK_REQUEST",
  GET_ALL_TASK_SUCCESS: "GET_ALL_TASK_SUCCESS",
  GET_ALL_TASK_FAILURE: "GET_ALL_TASK_FAILURE",
  REMOVE_TASK_REQUEST: "REMOVE_TASK_REQUEST",
  REMOVE_TASK_SUCCESS: "REMOVE_TASK_SUCCESS",
  REMOVE_TASK_FAILURE: "REMOVE_TASK_FAILURE",
  GET_LIST_TASK_QUOTE_TAB_REQUEST: "GET_LIST_TASK_QUOTE_TAB_REQUEST",
  GET_LIST_TASK_QUOTE_TAB_SUCCESS: "GET_LIST_TASK_QUOTE_TAB_SUCCESS",
  GET_LIST_TASK_QUOTE_TAB_FAILURE: "GET_LIST_TASK_QUOTE_TAB_FAILURE",
  GET_TASK_BY_ID_REQUEST: "GET_TASK_BY_ID_REQUEST",
  GET_TASK_BY_ID_SUCCESS: "GET_TASK_BY_ID_SUCCESS",
  GET_TASK_BY_ID_FAILURE: "GET_TASK_BY_ID_FAILURE",
  UPDATE_KADAI_SEIRI_REQUEST: "UPDATE_KADAI_SEIRI_REQUEST",
  UPDATE_KADAI_SEIRI_SUCCESS: "UPDATE_KADAI_SEIRI_SUCCESS",
  UPDATE_KADAI_SEIRI_FAILURE: "UPDATE_KADAI_SEIRI_FAILURE",
  GET_MASTER_KADAI_REQUEST: "GET_MASTER_KADAI_REQUEST",
  GET_MASTER_KADAI_SUCCESS: "GET_MASTER_KADAI_SUCCESS",
  GET_MASTER_KADAI_FAILURE: "GET_MASTER_KADAI_FAILURE",
  GET_TASK_BY_ID_QUOTE_REQUEST: "GET_TASK_BY_ID_QUOTE_REQUEST",
  GET_TASK_BY_ID_QUOTE_SUCCESS: "GET_TASK_BY_ID_QUOTE_SUCCESS",
  GET_TASK_BY_ID_QUOTE_FAILURE: "GET_TASK_BY_ID_QUOTE_FAILURE",
  UPDATE_KADAI_REQUEST: "UPDATE_KADAI_REQUEST",
  UPDATE_KADAI_SUCCESS: "UPDATE_KADAI_SUCCESS",
  UPDATE_KADAI_FAILURE: "UPDATE_KADAI_FAILURE",
  GET_LIST_TASK_ID_REQUEST: "GET_LIST_TASK_ID_REQUEST",
  GET_LIST_TASK_ID_SUCCESS: "GET_LIST_TASK_ID_SUCCESS",
  GET_LIST_TASK_ID_FAILURE: "GET_LIST_TASK_ID_FAILURE",
  NEW_PAGE: "NEW_PAGE",
};
