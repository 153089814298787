/* eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
import Tools from "./tools";

const fabric = require("fabric").fabric;

class ThreeLine2 extends FabricCanvasTool {


  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => o.selectable = o.evented = false);
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.call_back = props.update_select;
  }

  doMouseDown(o) {
    const canvas = this._canvas;
    this.isDown = true;
    const pointer = canvas.getPointer(o.e);
    this.startX = pointer.x;
    this.startY = pointer.y;
    const rect = new fabric.Rect({
      left: this.startX,
      top: this.startY,
      originX: "left",
      originY: "top",
      width: 100,
      height: 60,
      stroke: this._color,
      strokeWidth: this._width,
      fill: this._isfill ? "rgba(0,0,0,1)" : this._fill,
      transparentCorners: false,
      selectable: false,
      evented: false,
      angle: 0
    });
    const points = [this.startX, this.startY + 15, this.startX + 100, this.startY + 15];
    const line = new fabric.Line(points, {
      strokeWidth: this._width,
      fill: this._color,
      stroke: this._color,
      originX: "left",
      originY: "top",
      selectable: false,
      evented: false
    });
    const points2 = [this.startX, this.startY + 30, this.startX + 100, this.startY + 30];
    const line2 = new fabric.Line(points2, {
      strokeWidth: this._width,
      fill: this._color,
      stroke: this._color,
      originX: "left",
      originY: "top",
      selectable: false,
      evented: false
    });
    const points3 = [this.startX, this.startY + 45, this.startX + 100, this.startY + 45];
    const line3 = new fabric.Line(points3, {
      strokeWidth: this._width,
      fill: this._color,
      stroke: this._color,
      originX: "left",
      originY: "top",
      selectable: false,
      evented: false
    });
    this.groupc = new fabric.Group([rect, line, line2, line3], {
      left: this.startX,
      top: this.startY,
      angle: 0,
      selectable: true,
    });
    canvas.add(this.groupc);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    const canvas = this._canvas;
    const pointer = canvas.getPointer(o.e);
    this.groupc.set(
      {
        scaleX: pointer.x / this.startX,
        scaleY: pointer.x / this.startX,
      }
    );

    this.groupc.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.call_back();
  }
}

export default ThreeLine2;