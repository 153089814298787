export const ACTION_DEFAULT = "ACTION_DEFAULT";

export const GET_HAIBEN_DAY = "GET_HAIBEN_DAY";

export const REPORT_RIYOUSYA_REQUEST = "REPORT_RIYOUSYA_REQUEST";
export const REPORT_RIYOUSYA_SUCCESS = "REPORT_RIYOUSYA_SUCCESS";
export const REPORT_RIYOUSYA_ERROR = "REPORT_RIYOUSYA_ERROR";

export const REPORT_BAITARU_REQUEST = "REPORT_BAITARU_REQUEST";
export const REPORT_BAITARU_SUCCESS = "REPORT_BAITARU_SUCCESS";
export const REPORT_BAITARU_ERROR = "REPORT_BAITARU_ERROR";

export const BAITARU_COPY = "BAITARU_COPY";
export const BAITARU_DELETE = "BAITARU_DELETE";

export const VITAL_MUL_UPDATE_REQUEST = "VITAL_MUL_UPDATE_REQUEST";
export const VITAL_MUL_UPDATE_SUCCESS = "VITAL_MUL_UPDATE_SUCCESS";
export const VITAL_MUL_UPDATE_ERROR = "VITAL_MUL_UPDATE_ERROR";

export const KIROKU_UPDATE_REQUEST = "KIROKU_UPDATE_REQUEST";
export const KIROKU_UPDATE_SUCCESS = "KIROKU_UPDATE_SUCCESS";
export const KIROKU_UPDATE_ERROR = "KIROKU_UPDATE_ERROR";

export const KIROKU_INSERT_REQUEST = "KIROKU_INSERT_REQUEST";
export const KIROKU_INSERT_SUCCESS = "KIROKU_INSERT_SUCCESS";
export const KIROKU_INSERT_ERROR = "KIROKU_INSERT_ERROR";

export const KIROKU_UPDATE_MUL_REQUEST = "KIROKU_UPDATE_MUL_REQUEST";
export const KIROKU_UPDATE_MUL_SUCCESS = "KIROKU_UPDATE_MUL_SUCCESS";

export const KIROKU_INSERT_MUL_REQUEST = "KIROKU_INSERT_MUL_REQUEST";
export const KIROKU_INSERT_MUL_SUCCESS = "KIROKU_INSERT_MUL_SUCCESS";

export const KIROKU_DELETE_REQUEST = "KIROKU_DELETE_REQUEST";
export const KIROKU_DELETE_SUCCESS = "KIROKU_DELETE_SUCCESS";
export const KIROKU_DELETE_ERROR = "KIROKU_DELETE_ERROR";

export const GAZOU_DELETE_REQUEST = "GAZOU_DELETE_REQUEST";
export const GAZOU_DELETE_SUCCESS = "GAZOU_DELETE_SUCCESS";
export const GAZOU_DELETE_ERROR = "GAZOU_DELETE_ERROR";

export const REPORT_MEAL_REQUEST = "REPORT_MEAL_REQUEST";
export const REPORT_MEAL_SUCCESS = "REPORT_MEAL_SUCCESS";
export const REPORT_MEAL_ERROR = "REPORT_MEAL_ERROR";
export const MEAL_COPY = "MEAL_COPY";
export const MEAL_DELETE = "MEAL_DELETE";

export const REPORT_SUIBUN_REQUEST = "REPORT_SUIBUN_REQUEST";
export const REPORT_SUIBUN_SUCCESS = "REPORT_SUIBUN_SUCCESS";
export const REPORT_SUIBUN_ERROR = "REPORT_SUIBUN_ERROR";
export const SUIBUN_COPY = "SUIBUN_COPY";
export const SUIBUN_DELETE = "SUIBUN_DELETE";

export const REPORT_HAISETU_REQUEST = "REPORT_HAISETU_REQUEST";
export const REPORT_HAISETU_SUCCESS = "REPORT_HAISETU_SUCCESS";
export const REPORT_HAISETU_ERROR = "REPORT_HAISETU_ERROR";
export const HAISETU_COPY = "HAISETU_COPY";
export const HAISETU_DELETE = "HAISETU_DELETE";

export const REPORT_NYUYOKU_REQUEST = "REPORT_NYUYOKU_REQUEST";
export const REPORT_NYUYOKU_SUCCESS = "REPORT_NYUYOKU_SUCCESS";
export const REPORT_NYUYOKU_ERROR = "REPORT_NYUYOKU_ERROR";
export const NYUYOKU_COPY = "NYUYOKU_COPY";
export const NYUYOKU_DELETE = "NYUYOKU_DELETE";

export const REPORT_FUKUYAKU_REQUEST = "REPORT_FUKUYAKU_REQUEST";
export const REPORT_FUKUYAKU_SUCCESS = "REPORT_FUKUYAKU_SUCCESS";
export const REPORT_FUKUYAKU_ERROR = "REPORT_FUKUYAKU_ERROR";
export const FUKUYAKU_COPY = "FUKUYAKU_COPY";
export const FUKUYAKU_DELETE = "FUKUYAKU_DELETE";

export const REPORT_KAIGONAIYOU_REQUEST = "REPORT_KAIGONAIYOU_REQUEST";
export const REPORT_KAIGONAIYOU_SUCCESS = "REPORT_KAIGONAIYOU_SUCCESS";
export const REPORT_KAIGONAIYOU_ERROR = "REPORT_KAIGONAIYOU_ERROR";
export const KAIGONAIYOU_COPY = "KAIGONAIYOU_COPY";
export const KAIGONAIYOU_DELETE = "KAIGONAIYOU_DELETE";

export const REPORT_TOKKI_REQUEST = "REPORT_TOKKI_REQUEST";
export const REPORT_TOKKI_SUCCESS = "REPORT_TOKKI_SUCCESS";
export const REPORT_TOKKI_ERROR = "REPORT_TOKKI_ERROR";
export const TOKKI_COPY = "TOKKI_COPY";
export const TOKKI_DELETE = "TOKKI_DELETE";

export const REPORT_GAZOU_REQUEST = "REPORT_GAZOU_REQUEST";
export const REPORT_GAZOU_SUCCESS = "REPORT_GAZOU_SUCCESS";
export const REPORT_GAZOU_ERROR = "REPORT_GAZOU_ERROR";
export const GAZOU_COPY = "GAZOU_COPY";
export const GAZOU_DELETE = "GAZOU_DELETE";

export const REPORT_SEARCH_REQUEST = "REPORT_SEARCH_REQUEST";
export const REPORT_SEARCH_SUCCESS = "REPORT_SEARCH_SUCCESS";
export const REPORT_SEARCH_ERROR = "REPORT_SEARCH_ERROR";

export const REPORT_FILTER_REQUEST = "REPORT_FILTER_REQUEST";
export const REPORT_FILTER_SUCCESS = "REPORT_FILTER_SUCCESS";
export const REPORT_FILTER_ERROR = "REPORT_FILTER_ERROR";

export const REPORT_SEARCHBYNAME_REQUEST = "REPORT_SEARCHBYNAME_REQUEST";
export const REPORT_SEARCHBYNAME_SUCCESS = "REPORT_SEARCHBYNAME_SUCCESS";

export const REPORT_SEARCHBYGENDER_REQUEST = "REPORT_SEARCHBYGENDER_REQUEST";
export const REPORT_SEARCHBYGENDER_SUCCESS = "REPORT_SEARCHBYGENDER_SUCCESS";

export const REPORT_SORT = "REPORT_SORT";
export const REPORT_REFRESH = "REPORT_REFRESH";

export const REPORT_LOAD_ZENKAI_REQUEST = "REPORT_LOAD_ZENKAI_REQUEST";
export const REPORT_LOAD_ZENKAI_SUCCESS = "REPORT_LOAD_ZENKAI_SUCCESS";
export const REPORT_LOAD_ZENKAI_ERROR = "REPORT_LOAD_ZENKAI_ERROR";

export const GAZOU_SER_INSERT_REQUEST = "GAZOU_SER_INSERT_REQUEST";
export const GAZOU_SER_INSERT_SUCCESS = "GAZOU_SER_INSERT_SUCCESS";
export const GAZOU_SER_INSERT_ERROR = "GAZOU_SER_INSERT_ERROR";

export const GAZOU_SER_UPDATE_REQUEST = "GAZOU_SER_UPDATE_REQUEST";
export const GAZOU_SER_UPDATE_SUCCESS = "GAZOU_SER_UPDATE_SUCCESS";
export const GAZOU_SER_UPDATE_ERROR = "GAZOU_SER_UPDATE_ERROR";

export const GAZOU_SER_DELETE_REQUEST = "GAZOU_SER_DELETE_REQUEST";
export const GAZOU_SER_DELETE_SUCCESS = "GAZOU_SER_DELETE_SUCCESS";
export const GAZOU_SER_DELETE_ERROR = "GAZOU_SER_DELETE_ERROR";
