import { FacesheetActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";

function facesheet(state = {}, action) {
  switch (action.type) {
    // get all plan1 by user id
    case FacesheetActionTypes.GET_ALL_FACESHEET_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case FacesheetActionTypes.GET_ALL_FACESHEET_SUCCESS: {
      return {
        ...state,
        loading: false,
        datas: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case FacesheetActionTypes.GET_ALL_FACESHEET_FAILURE: {
      return {
        ...state,
        datas: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }

    // get one plan1
    case FacesheetActionTypes.GET_FACESHEET_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getOne",
      };
    }
    case FacesheetActionTypes.GET_FACESHEET_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }
    case FacesheetActionTypes.GET_FACESHEET_FAILURE: {
      return {
        ...state,
        loading: false,
        data: {},
        type: "",
      };
    }

    // create plan1
    case FacesheetActionTypes.CREATE_FACESHEET_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case FacesheetActionTypes.CREATE_FACESHEET_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }
    case FacesheetActionTypes.CREATE_FACESHEET_FAILURE: {
      return {
        ...state,
        loading: false,
        data: {},
        type: "",
      };
    }

    // update plan1
    case FacesheetActionTypes.UPDATE_FACESHEET_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case FacesheetActionTypes.UPDATE_FACESHEET_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }
    case FacesheetActionTypes.UPDATE_FACESHEET_FAILURE: {
      return {
        ...state,
        loading: false,
        data: {},
        type: "",
      };
    }

    // remove plan1
    case FacesheetActionTypes.REMOVE_FACESHEET_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case FacesheetActionTypes.REMOVE_FACESHEET_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case FacesheetActionTypes.REMOVE_FACESHEET_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case FacesheetActionTypes.GET_FACESHEET_BY_DATA_REQUEST: {
      return {
        ...state,
        loadingByData: true,
      };
    }
    case FacesheetActionTypes.GET_FACESHEET_BY_DATA_SUCCESS: {
      return {
        ...state,
        loadingByData: false,
        datas: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
      };
    }
    case FacesheetActionTypes.GET_FACESHEET_BY_DATA_FAILURE: {
      return {
        ...state,
        datas: [],
        metaData: {},
        loadingByData: false,
      };
    }

    case FacesheetActionTypes.NEW_PAGE: {
      return {
        ...state,
        data: {},
      };
    }

    default: {
      return { ...state };
    }
  }
}

export default facesheet;
