import { Form } from "@ant-design/compatible";
import { Button, Col, DatePicker, Input, InputNumber, Modal, Row, TimePicker } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import React from "react";
import { ConstSet } from "../../../common/configs/constset";
dayjs.extend(dayjsPluginUTC);

class DengonForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    visibleConfirm: false,
    isDelConfirm: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        //
        const data = {
          id: values.id ? values.id.toString() : null,
          // @TODO
          date: values.date ? values.date.format(ConstSet.DATE_FORMAT) : null,
          time: values.time ? values.time.format("HH:mm:ss") : null,

          sendTantoMasterId: values.sendTantoMasterId
            ? values.sendTantoMasterId.toString()
            : null,
          rcptTantoMasterId: values.rcptTantoMasterId
            ? values.rcptTantoMasterId.toString()
            : null,
          naiyo: values.naiyo ? values.naiyo : null,
          readf: values.readf ? values.readf.toString() : null,
        };

        // console.log(data);

        this.props.regData(data);
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("「パスワード」と「パスワード(確認)」の値が一致しません。");
    }
    else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.props.form.isFieldsTouched()) this.showGoBackConfirm();
    else this.props.history.goBack();
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.goBack();
  }

  delete = () => {
    this.setState({
      isDelConfirm: true,
    });
  };

  closeDelConfirm = () => {
    this.setState({
      isDelConfirm: false,
    });
  };

  okDelConfirm = () => {
    const { data } = this.props;
    this.setState({
      isDelConfirm: false,
    });

    this.props.delete(data.id);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      (<div>
        <Form
          {...formItemLayout}
          labelAlign="left"
          style={{ maxWidth: 980, margin: "0 auto" }}
        >
          <Row gutter={24}>
            <Col md={12}>
              <Form.Item label="ID（自動生成）" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("id", {
                  rules: [{ required: false }],
                })(<Input disabled />)}
              </Form.Item>

              <Form.Item label="送信担当者ID" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("sendTantoMasterId", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="受信担当者ID" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("rcptTantoMasterId", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="内容" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("naiyo", {
                  rules: [{ required: false }],
                })(<Input.TextArea rows={4} autoComplete="new-name" />)}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="日付" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("date", {
                  rules: [
                    { required: true, message: "日付を入力してください" },
                  ],
                })(<DatePicker />)}
              </Form.Item>

              <Form.Item label="時間 " wrapperCol={{ md: 24 }}>
                {getFieldDecorator("time", {
                  rules: [
                    { required: true, message: "時間を入力してください" },
                  ],
                })(<TimePicker showNow={false} needConfirm={false} />)}
              </Form.Item>

              <Form.Item label="既読F" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("readf", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>
            </Col>
          </Row>
          {data && data.id ? (
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" danger onClick={this.delete}>
                削除
              </Button>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                更新
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          ) : (
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                新規登録
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          )}
        </Form>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
        <Modal
          open={this.state.isDelConfirm}
          title="削除確認"
          onCancel={() => this.closeDelConfirm()}
          onOk={() => this.okDelConfirm()}
        >
          <span style={{ display: "block" }}>
            {"削除してもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }
}

const WrappedDengonForm = Form.create({
  name: "kanri-user-form",
  onValuesChange() { },
  mapPropsToFields(props) {
    return {
      id: Form.createFormField({
        value: props.data ? props.data.id : null,
      }),
      sendTantoMasterId: Form.createFormField({
        value:
          props.data && props.data.sendTantoMaster
            ? props.data.sendTantoMaster.id
            : null,
      }),
      rcptTantoMasterId: Form.createFormField({
        value:
          props.data && props.data.rcptTantoMaster
            ? props.data.rcptTantoMaster.id
            : null,
      }),
      date: Form.createFormField({
        // converty date time to standard
        value:
          props.data && props.data.date
            ? dayjs.utc(props.data.date, ConstSet.DATE_FORMAT)
            : dayjs(),
      }),
      time: Form.createFormField({
        // @TODO convert time to standard
        value:
          props.data && props.data.time
            ? dayjs(props.data.time, "HH:mm:ss")
            : null,
      }),
      naiyo: Form.createFormField({
        value: props.data ? props.data.naiyo : null,
      }),
      readf: Form.createFormField({
        value: props.data ? props.data.readf : null,
      }),
    };
  },
})(DengonForm);

export default WrappedDengonForm;
