import { Button, Modal, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  changeExpandedRowKey,
  getResultMonitoring,
  getRowInfolMonitoring,
  updateOptionOfMonitoring,
} from "../../../../actions/monitoringActions/actionCreators";
import RowInfo from "../UI/RowInfo";
import RowContent from "./RowContent";
const optionsOfAttainment = [
  "1.目標達成",
  "2.一部達成",
  "3.達成していない",
  "4.その他",
];
const optionsOfCondition = [
  "1.実施されている",
  "2.実施されてないことがある",
  "3.実施されていない",
  "4.その他",
];
const optionsOfSatisPerson = [
  "1.満足している",
  "2.ある程度満足している",
  "3.満足していない",
  "4.その他",
];
const optionsOfSatisFamily = [
  "1.満足している",
  "2.ある程度満足している",
  "3.満足していない",
  "4.その他",
];
const optionsOfCope = [
  "1.プラン継続",
  "2.プラン一部変更",
  "3.プラン変更",
  "4.プラン中止",
  "5.その他",
];
class editTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: [],
      deleteVisible: false,
      checked: false,
      expandedRowKeys: [],
      edit: false,
      recordContent: null,
      setModalVisible: false,
    };
  }

  handleExpand(expanded, record) {
    if (record) {
      this.props.getResultMonitoring(record.results);
      this.props.getRowInfolMonitoring(record);
      expanded
        ? this.props.changeExpandedRowKey(record.sortNum)
        : this.props.changeExpandedRowKey(null);
    }
    else {
      this.setState({
        expandedRowKeys: null,
        recordContent: null,
      });
    }
  }
  showModalSet() {
    this.setState({
      setModalVisible: true,
    });
  }
  hideModalSet() {
    this.setState({
      setModalVisible: false,
    });
  }
  handleClickOption(number) {
    // const { monitoringRows, expandedRowKeys, rowInformation } = this.props;
    const attainmentNew = optionsOfAttainment[number - 1];
    const conditionNew = optionsOfCondition[number - 1];
    const satisPersonNew = optionsOfSatisPerson[number - 1];
    const satisFamilyNew = optionsOfSatisFamily[number - 1];
    const copeNew = optionsOfCope[number - 1];
    this.props.updateOptionOfMonitoring(
      attainmentNew,
      conditionNew,
      satisPersonNew,
      satisFamilyNew,
      copeNew
    );
    this.setState({
      setModalVisible: false,
    });
    if (this.props.onChangeData) {
      this.props.onChangeData();
    }
  }

  render() {
    const { monitoringRows, expandedRowKeys, rowInformation } = this.props;
    const columns = [
      {
        title: "Content",
        dataIndex: "content",
      },
    ];
    const tableData = [];
    if (monitoringRows) {
      monitoringRows.map((info, index) => {
        tableData.push({
          // content: info.id
          content: (
            <RowContent
              info={info}
              indexRow={index}
              handleExpand={(expanded, record) =>
                this.handleExpand(expanded, record)
              }
            />
          ),
        });
      });
    }
    return (
      (<div
        id={"table-monitoring"}
        className={this.props.mode === "mobile" ? "table-monitoring-m" : ""}
      >
        <div className="short-term-goal" style={{ marginBottom: 24 }}>
          <label>短期目標</label>
          <Button
            type={"primary"}
            className={"edit-button"}
            onClick={() => this.showModalSet()}
          >
            {"すべてセット"}
          </Button>
        </div>
        <Table
          rowKey={"id"}
          showHeader={false}
          columns={columns}
          dataSource={tableData}
          bordered={true}
          size="small"
        // style={{ border: "1px solid gray", padding: 0 }}
        // size="small"
        // style={editDetail ? { display: "none" } : {}}
        />
        {/* <MediaQuery minDeviceWidth={editDetail ? 0 : 768}> */}
        {expandedRowKeys ? (
          <RowInfo
            record={rowInformation}
            mode={this.props.mode}
            riyousyaId={this.props.riyousyaId}
            monitoringDate={this.props.monitoringDate}
            onChangeData={this.props.onChangeData}
          />
        ) : (
          <div />
        )}
        {/* </MediaQuery> */}
        <Modal
          width={700}
          className={`modal-add-coppy ${this.props.mode === "mobile" ? "modal-add-coppy-m" : ""}`}
          open={this.state.setModalVisible}
          title={"すべての選択肢に指定の値をセットする"}
          onCancel={() => this.hideModalSet()}
          footer={[
            <Button
              key="1"
              type={"primary"}
              onClick={() => this.handleClickOption(1)}
            >
              １をセット
            </Button>,
            <Button
              key="2"
              type={"primary"}
              onClick={() => this.handleClickOption(2)}
            >
              ２をセット
            </Button>,
            <Button
              key="3"
              type={"primary"}
              onClick={() => this.handleClickOption(3)}
            >
              ３をセット
            </Button>,
            <Button
              key="4"
              type={"primary"}
              onClick={() => this.handleClickOption(4)}
            >
              ４をセット
            </Button>,

            <Button key="canceladd" onClick={() => this.hideModalSet()}>
              キャンセル
            </Button>,
          ]}
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    monitoringRows: state.monitoring.monitoringRows,
    rowInformation: state.monitoring.rowInformation,
    expandedRowKeys: state.monitoring.expandedRowKeys,
    // editDetail: state.kyotakuPlan2.editDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getResultMonitoring: (data) => dispatch(getResultMonitoring(data)),
    getRowInfolMonitoring: (data) => dispatch(getRowInfolMonitoring(data)),
    changeExpandedRowKey: (data) => dispatch(changeExpandedRowKey(data)),
    updateOptionOfMonitoring: (
      attainment,
      condition,
      satisPerson,
      satisFamily,
      cope,
      values
    ) =>
      dispatch(
        updateOptionOfMonitoring(
          attainment,
          condition,
          satisPerson,
          satisFamily,
          cope,
          values
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(editTable);
