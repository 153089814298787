import React, { useEffect, memo } from "react";
import { connect } from "react-redux";
import { notification } from "antd";

import "./assets/less/styles.less";
import Layout from "./layouts";

function App({ auth, error }) {
  useEffect(() => {
    if (error.message) {
      notification.error({
        message: "Error",
        description: error.message,
        placement: "bottomLeft",
        duration: 3,
      });
    }
  }, [error]);

  return (
    <Layout
      isUserLoggedIn={auth.isUserLoggedIn}
      sessionTimeout={auth.sessionTimeout}
      jwtTokenType={auth.jwtTokenType}
    />
  );
}

const mapStateToProps = () => (state) => ({
  auth: state.auth,
  error: state.error,
});

export default connect(mapStateToProps)(
  memo(App, (props, nextProps) => {
    if (props.auth.isUserLoggedIn !== nextProps.auth.isUserLoggedIn) {
      return false;
    }
    if (props.error.mesasge !== nextProps.error.mesasge) {
      return false;
    }
    return true;
  })
);
