import {
  DOWNLOAD_FILE_KIROKU,
  GET_FILE_REQUEST,
  GET_FILE_ERROR,
} from "./actionName";
import { DownloadApi, loadFileUrl } from "../../api/download";

/*
 * @param: username: string
 * @param: password: string
 */
export function downloadCreator(fileId, fileName) {
  return (dispatch) => {
    DownloadApi.download(fileId, fileName).then((data) =>
      dispatch({
        type: DOWNLOAD_FILE_KIROKU,
      })
    );
  };
}

export function download(args, callback) {
  return (dispatch) => {
    dispatch({
      type: GET_FILE_REQUEST,
    });
    loadFileUrl.loadFile(args).then(
      (data) => callback(data),
      (err) =>
        dispatch({
          type: GET_FILE_ERROR,
          error: true,
        })
    );
  };
}

export function loadFilePreview(args, callback) {
  return (dispatch) => {
    dispatch({
      type: GET_FILE_REQUEST,
    });
    loadFileUrl.loadFilePreview(args).then(
      (data) => callback(data),
      (err) => {
        callback(null);
      }
    );
  };
}
