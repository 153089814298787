import {
  LOAD_REPORT_ADL_SUCCESS,
  LOAD_REPORT_ADL_ERROR,
  DELETE_REPORT_ADL_SUCCESS,
  DELETE_REPORT_ADL_ERROR,
  LOAD_REPORT_ADL_BY_ID_SUCCESS,
  LOAD_REPORT_ADL_BY_ID_ERROR,
  CREATE_REPORT_ADL_ERROR,
  UPDATE_REPORT_ADL_SUCCESS,
  UPDATE_REPORT_ADL_ERROR,
  RESET_CURRENT_REPORT_ADL,
  LOAD_REPORT_ADL_SPIN,
  KEEP_VALUE_SEARCH,
  GET_SETTING_MASTER_SUCCESS,
  GET_ADL_MASTER_SUCCESS,
} from "./actionName";

import { reportAdlApi } from "../../api/reportAdl";
import {
  localStorageClearCus,
  getMenu,
  formatStorage,
} from "../../common/function_common/functionCommon";

/**
 * ADLレコード（複数）検索
 * data = {riyousyaId:} 必要に応じて増やす
 */
export function loadAdl(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_REPORT_ADL_SPIN,
    });
    reportAdlApi.loadAdl(data).then(
      (data) => {
        dispatch({
          type: LOAD_REPORT_ADL_SUCCESS,
          adls: data,
          // meta: data.meta,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_REPORT_ADL_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

/**
 * レコードの削除
 * @param {*} id
 * @param {*} callback
 * @returns
 */
export function deleteAdl(id, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_REPORT_ADL_SPIN,
    });
    reportAdlApi.deleteAdl(id).then(
      (data) => {
        dispatch({
          type: DELETE_REPORT_ADL_SUCCESS,
          deleteAdl: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: DELETE_REPORT_ADL_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

/**
 * 担当会議idでレコード呼び出し
 * @param id 担当会議id
 */
export function loadAdlById(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_REPORT_ADL_SPIN,
    });
    reportAdlApi.loadAdlById(id).then(
      (data) => {
        dispatch({
          type: LOAD_REPORT_ADL_BY_ID_SUCCESS,
          adl: data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_REPORT_ADL_BY_ID_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

/**
 * settingMasterのADL分類取得
 * @param {*} callback
 * @returns
 */
export function getSettingMasterAdl(callback) {
  const key = formatStorage(getMenu());
  return (dispatch) => {
    dispatch({
      type: LOAD_REPORT_ADL_SPIN,
    });
    reportAdlApi.getSettingMasterAdl(key.si).then(
      (data) => {
        dispatch({
          type: GET_SETTING_MASTER_SUCCESS,
          listName: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_REPORT_ADL_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
/**
 *
 * @param {*} data 分類(bunrui)
 * @returns
 */
export function getAdlMaster(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_REPORT_ADL_SPIN,
    });
    reportAdlApi.getAdlMaster(data).then(
      (data) => {
        dispatch({
          type: GET_ADL_MASTER_SUCCESS,
          adlMaster: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_REPORT_ADL_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

/**
 * レコードの新規登録＆修正
 * @param {*} params 登録するデータ
 * @param {*} callback
 * @returns
 */
export function updateAdl(params, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_REPORT_ADL_SPIN,
    });
    reportAdlApi.updateAdl(params).then(
      (data) => {
        dispatch({
          type: UPDATE_REPORT_ADL_SUCCESS,
          updateAdl: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_REPORT_ADL_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

/**
 * あまり意味なし
 * 新規レコード追加時にセットするだけ
 */
export function resetCurrentAdl() {
  return (dispatch) => {
    dispatch({
      type: RESET_CURRENT_REPORT_ADL,
    });
  };
}
/**
 * レコードの値を管理するだけ
 * @param {*} params {id:, riyousyaId:}
 * @returns
 */
export function keepValueSearch(params) {
  return (dispatch) => {
    dispatch({
      type: KEEP_VALUE_SEARCH,
      keepValue: params,
    });
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
