/* eslint-disable react/prop-types */
import "moment/locale/ja";
import React, { Component } from "react";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import { getPath } from "../../common/function_common/functionCommon";
import InputFormWrapper from "./InputForm";
import "./styles.css";

/**
 * 追加＆修正
 * ADL追加＆修正画面
 * AdlContainerでコネクト済み
 */
class InputAdl extends Component {
  constructor(props) {
    super(props);
    const { adlId, riyousyaId } = this.props.match.params;
    this.state = {
      path: getPath(),
      riyousyaId,
      adlId,
      isFieldsTouched: false,
    };
  }

  /**
   * adlMasterを取得する為のbunruiをsettingMasterから取得
   * 取得したbunruiでadlMasterを取得する
   * 利用者情報の読み込み
   * 新規追加でなければadlIdでレコード呼び出しセット
   * 新規だとデフォルトのデータをセット
   */
  componentDidMount() {
    this.props.getSettingMasterAdl((data) => {
      if (data != null) {
        const bunruiTmp = data.split(",");
        this.props.getAdlMaster(bunruiTmp, () => {
          // console.log(callback)
        });
      }
    });
    this.props.getRiyousyaInfo(this.state.riyousyaId);
    if (this.state.adlId !== "new") {
      this.props.loadAdlById(this.state.adlId);
    }
    else {
      this.props.resetCurrentAdl();
    }
  }

  /**
   * <InputFormWrapper/>のprops
   * @param formRef props
   */
  ref = (formRef) => {
    this.formRef = formRef;
  };

  /**
   * formで確認できない内容の変更があればtrue
   * 登録時に戻る確認モーダルを出すため
   */
  onChangeValue = (state) => {
    this.setState({
      isFieldsTouched: state,
    });
  };

  /**
   * <InputFormWrapper/>に渡す関数
   * 保存アイコンクリックで発火
   * 新規追加だとcreateで保存をしてurlを登録したidに変更する
   * 修正だとadlId,valueでupdate修正を行う
   * @param err
   * @param values フォームの値
   * @returns
   */
  regData(err, values) {
    if (err) return;
    const { adlId, riyousyaId } = this.state;

    const valuesTmp = { ...values };

    if (adlId === "new") {
      this.props.updateAdl(valuesTmp, (data) => {
        if (data.id) {
          // 登録した後にidありのurlページに行く(newをなくす)
          this.props.history.push(
            `${this.state.path}/report-adl/${riyousyaId}/${data.id}`
          );

          this.setState({
            adlId: data.id,
          });
        }
      });
    }
    else {
      this.props.updateAdl(valuesTmp, () => {
        // callback data
      });
    }
  }

  /**
   * <InputFormWrapper/>に渡す関数
   * 閲覧&登録
   * @param {*} err
   * @param {*} values フォームの値
   * @returns
   */
  regDataAndCheck(err, values) {
    const { adlId, riyousyaId } = this.state;
    if (err) return;

    // let valuesTmp = { ...values };

    if (adlId !== "new") {
      this.props.updateAdl(values, () => {
        const win = window.open(
          `${this.state.path}/report-adl-view/${riyousyaId}/${adlId}?preview=true`,
          "_blank"
        );
        win.focus();
      });
    }
  }

  render() {
    const { riyousya, adl } = this.props;
    const { adlId, riyousyaId } = this.props.match.params;
    this.riyousyaId = riyousyaId;
    this.adlId = adlId;

    return (
      <CommonPageContainer
        id={"input-report-adl-page"} // ここでstyles.cssの適応
        sessionTimeout={this.props.sessionTimeout}
      >
        <div>
          <InputFormWrapper
            isFieldsTouched={this.state.isFieldsTouched} // 変更の確認
            onChangeValue={(state) => this.onChangeValue(state)}
            wrappedComponentRef={this.ref} // 相手のporps
            riyousyaId={this.riyousyaId}
            adlId={this.adlId}
            riyousya={riyousya}
            data={this.adlId !== "new" ? adl : {}}
            regData={(err, values) => this.regData(err, values)} // 登録のみ
            regDataAndCheck={(err, values) => this.regDataAndCheck(err, values)} // 観覧の登録
            history={this.props.history}
            match={this.props.match}
            tab={this.props.listName}
            adlMaster={this.props.adlMaster}
            loading={this.props.loading}
            tantoFormat={this.props.tantoFormat}
          />
        </div>
      </CommonPageContainer>
    );
  }
}

export default InputAdl;
