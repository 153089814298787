import {
  ALL_MASTER_SPIN_REQUEST,
  BYOUMEI_MASTER_LIST_SUCCESS,
  BYOUMEI_MASTER_INIT_SUCCESS,
  ALL_DRUG_MASTER_LIST_SUCCESS,
  DRUG_MASTER_INIT_SUCCESS,
  MACHINE_MASTER_LIST_SUCCESS,
  MACHINE_MASTER_INIT_SUCCESS,
  SERVICE_SYURUI_MASTER_LIST_SUCCESS,
  SERVICE_KIKAN_MASTER_LIST_SUCCESS,
  IRYOU_KIKAN_MASTER_LIST_SUCCESS,
  IRYOU_KIKAN_MASTER_INIT_SUCCESS,
  CREATE_BYOUMEI_MASTER_SUCCESS,
  CREATE_BYOUMEI_MASTER_ERROR,
  UPDATE_BYOUMEI_MASTER_SUCCESS,
  UPDATE_BYOUMEI_MASTER_ERROR,
  CREATE_DRUG_MASTER_SUCCESS,
  CREATE_DRUG_MASTER_ERROR,
  UPDATE_DRUG_MASTER_SUCCESS,
  UPDATE_DRUG_MASTER_ERROR,
  CREATE_MACHINE_MASTER_SUCCESS,
  CREATE_MACHINE_MASTER_ERROR,
  UPDATE_MACHINE_MASTER_SUCCESS,
  UPDATE_MACHINE_MASTER_ERROR,
  CREATE_IRYOU_KIKAN_MASTER_SUCCESS,
  CREATE_IRYOU_KIKAN_MASTER_ERROR,
  UPDATE_IRYOU_KIKAN_MASTER_SUCCESS,
  UPDATE_IRYOU_KIKAN_MASTER_ERROR,
  UPDATE_LIST_IRYOU_KIKAN_MASTER_SUCCESS,
  UPDATE_LIST_BYOUMEI_SUCCESS,
  UPDATE_LIST_DRUG_SUCCESS,
  UPDATE_LIST_MACHINE_SUCCESS,
  UPDATE_SERVICE_KIKAN_LIST_REQUEST,
  UPDATE_SERVICE_KIKAN_LIST_SUCCESS,
  UPDATE_SERVICE_KIKAN_LIST_FAILURE,
  GET_SERVICE_KIKAN_LIST_FAILURE,
  SERVICE_KIKAN_MASTER_LIST_SUCCESSA,
} from "./actionName.js";
import {
  ByoumeiMasterList,
  DrugMasterList,
  MachineMasterList,
  ServiceSyuruiMasterList,
  ServiceKikanMasterList,
  IryouKikanMasterList,
} from "../../api/allMaster";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

/**
 *
 * @param {*} listName
 */
function byoumeiMasterListCreator(bunrui, name) {
  //byoumei
  return (dispatch) => {
    ByoumeiMasterList.loadList(bunrui, name).then((data) =>
      dispatch({
        type: BYOUMEI_MASTER_LIST_SUCCESS,
        payload: data,
      })
    );
  };
}

function byoumeiMasterInitCreator(callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    ByoumeiMasterList.loadDataInit().then((data) => {
      dispatch({
        type: BYOUMEI_MASTER_INIT_SUCCESS,
        payload: data,
      });
      return callback(data);
    });
  };
}

/**
 *
 * @param {*} listName
 */
function drugMasterListCreator(bunrui, name) {
  //drug
  return (dispatch) => {
    DrugMasterList.loadList(bunrui, name).then((data) =>
      dispatch({
        type: ALL_DRUG_MASTER_LIST_SUCCESS,
        payload: data,
      })
    );
  };
}

function drugMasterInitCreator(callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    DrugMasterList.loadDataInit().then((data) => {
      dispatch({
        type: DRUG_MASTER_INIT_SUCCESS,
        payload: data,
      });
      return callback(data);
    });
  };
}

/**
 *
 * @param {*} listName
 */
function machineMasterListCreator(bunrui, name) {
  //machine
  return (dispatch) => {
    MachineMasterList.loadList(bunrui, name).then((data) =>
      dispatch({
        type: MACHINE_MASTER_LIST_SUCCESS,
        payload: data,
      })
    );
  };
}

function machineMasterInitCreator(callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    MachineMasterList.loadDataInit().then((data) => {
      dispatch({
        type: MACHINE_MASTER_INIT_SUCCESS,
        payload: data,
      });
      return callback(data);
    });
  };
}

/**
 *
 * @param {*} listName
 */
function serviceRyuruiMasterListCreator(bunrui, name) {
  return (dispatch) => {
    ServiceSyuruiMasterList.loadList(bunrui, name).then((data) =>
      dispatch({
        type: SERVICE_SYURUI_MASTER_LIST_SUCCESS,
        payload: data,
      })
    );
  };
}

/**
 *
 * @param {*} listName
 */
function serviceKikanMasterListCreator(bunrui, name) {
  return async (dispatch) => {
    ServiceKikanMasterList.loadList(bunrui, name).then(
      (data) => {
        dispatch({
          type: SERVICE_KIKAN_MASTER_LIST_SUCCESS,
          payload: data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: GET_SERVICE_KIKAN_LIST_FAILURE,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

/**
 * 利用者保険で使用
 * @param {*} listName
 */
function serviceKikanMasterListCreator2(bunrui, name) {
  return async (dispatch) => {
    setTimeout(() => {
      dispatch({
        type: ALL_MASTER_SPIN_REQUEST,
      });
    }, 500);
    ServiceKikanMasterList.loadList(bunrui, name).then(
      (data) => {
        setTimeout(() => {
          dispatch({
            type: SERVICE_KIKAN_MASTER_LIST_SUCCESSA,
            payload: data,
          });
        }, 600);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: GET_SERVICE_KIKAN_LIST_FAILURE,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

/**
 *
 * @param {*} listName
 */
function iryouKikanMasterListCreator(bunrui, name) {
  return (dispatch) => {
    // dispatch({
    //   type: ALL_MASTER_SPIN_REQUEST,
    // })
    IryouKikanMasterList.loadList(bunrui, name).then((data) =>
      dispatch({
        type: IRYOU_KIKAN_MASTER_LIST_SUCCESS,
        payload: data,
      })
    );
  };
}

function iryouKikanMasterInitCreator(callback) {
  return (dispatch) => {
    // dispatch({
    //   type: ALL_MASTER_SPIN_REQUEST,
    // })
    IryouKikanMasterList.loadDataInit().then((data) => {
      dispatch({
        type: IRYOU_KIKAN_MASTER_INIT_SUCCESS,
        payload: data,
      });
      return callback(data);
    });
  };
}

//create.update

function createByoumeiMasterCreator(body, callback) {
  //病名
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    ByoumeiMasterList.create(body).then(
      (data) => {
        dispatch({
          type: CREATE_BYOUMEI_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_BYOUMEI_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function updateByoumeiMasterCreator(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    ByoumeiMasterList.update(body).then(
      (data) => {
        dispatch({
          type: UPDATE_BYOUMEI_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_BYOUMEI_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function createDrugMasterCreator(body, callback) {
  //薬
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    DrugMasterList.create(body).then(
      (data) => {
        dispatch({
          type: CREATE_DRUG_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_DRUG_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function updateDrugMasterCreator(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    DrugMasterList.update(body).then(
      (data) => {
        dispatch({
          type: UPDATE_DRUG_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_DRUG_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function createMachineMasterCreator(body, callback) {
  //機器
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    MachineMasterList.create(body).then(
      (data) => {
        dispatch({
          type: CREATE_MACHINE_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_MACHINE_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function updateMachineMasterCreator(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    MachineMasterList.update(body).then(
      (data) => {
        dispatch({
          type: UPDATE_MACHINE_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_MACHINE_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function createIryouKikanMasterCreator(body, callback) {
  return (dispatch) => {
    // dispatch({
    //   type: ALL_MASTER_SPIN_REQUEST,
    // })
    IryouKikanMasterList.create(body).then(
      (data) => {
        dispatch({
          type: CREATE_IRYOU_KIKAN_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_IRYOU_KIKAN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function updateIryouKikanMasterCreator(body, callback) {
  return (dispatch) => {
    // dispatch({
    //   type: ALL_MASTER_SPIN_REQUEST,
    // })
    IryouKikanMasterList.update(body).then(
      (data) => {
        dispatch({
          type: UPDATE_IRYOU_KIKAN_MASTER_SUCCESS,
          payload: data,
        });

        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_IRYOU_KIKAN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function updateIryouKikanMasterListCreator(body, callback) {
  return (dispatch) => {
    // dispatch({
    //   type: ALL_MASTER_SPIN_REQUEST,
    // })
    IryouKikanMasterList.updateList(body).then(
      (data) => {
        dispatch({
          type: UPDATE_LIST_IRYOU_KIKAN_MASTER_SUCCESS,
          payload: data, //[{id:0 naiyou:}, ...]
        });

        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_IRYOU_KIKAN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function updateListByoumeiCreator(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    ByoumeiMasterList.updateList(body).then(
      (data) => {
        dispatch({
          type: UPDATE_LIST_BYOUMEI_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_BYOUMEI_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function updateListDrugCreator(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    DrugMasterList.updateList(body).then(
      (data) => {
        dispatch({
          type: UPDATE_LIST_DRUG_SUCCESS,
          payload: data, //[{id:0 naiyou:},{id:0 naiyou:},{id:0 naiyou:}]
        });

        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_DRUG_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function updateListMachineCreator(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_MASTER_SPIN_REQUEST,
    });
    MachineMasterList.updateList(body).then(
      (data) => {
        dispatch({
          type: UPDATE_LIST_MACHINE_SUCCESS,
          payload: data, //[{id:0 naiyou:},{id:0 naiyou:},{id:0 naiyou:}]
        });

        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_MACHINE_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function updateServiceKikanList(body, callback) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE_KIKAN_LIST_REQUEST,
    });
    ServiceKikanMasterList.updateList(body).then(
      (data) => {
        dispatch({
          type: UPDATE_SERVICE_KIKAN_LIST_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_SERVICE_KIKAN_LIST_FAILURE,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}

export {
  byoumeiMasterListCreator,
  byoumeiMasterInitCreator,
  drugMasterListCreator,
  drugMasterInitCreator,
  machineMasterListCreator,
  machineMasterInitCreator,
  serviceRyuruiMasterListCreator,
  serviceKikanMasterListCreator,
  iryouKikanMasterListCreator,
  iryouKikanMasterInitCreator,
  createByoumeiMasterCreator,
  updateByoumeiMasterCreator,
  createDrugMasterCreator,
  updateDrugMasterCreator,
  createMachineMasterCreator,
  updateMachineMasterCreator,
  createIryouKikanMasterCreator,
  updateIryouKikanMasterCreator,
  updateIryouKikanMasterListCreator,
  updateListByoumeiCreator,
  updateListMachineCreator,
  updateListDrugCreator,
  updateServiceKikanList,
  serviceKikanMasterListCreator2,
};
