export const ACTION_USER_DEFAULT = "ACTION_USER_DEFAULT";

//
export const REPORT_USER_UPDATE_REQUEST = "REPORT_USER_UPDATE_REQUEST";
export const REPORT_USER_UPDATE_SUCCESS = "REPORT_USER_UPDATE_SUCCESS";
export const REPORT_USER_UPDATE_ERROR = "REPORT_USER_UPDATE_ERROR";

export const REPORT_USER_INSERT_REQUEST = "REPORT_USER_INSERT_REQUEST";
export const REPORT_USER_INSERT_SUCCESS = "REPORT_USER_INSERT_SUCCESS";
export const REPORT_USER_INSERT_ERROR = "REPORT_USER_INSERT_ERROR";

export const REPORT_USER_DELETE_REQUEST = "REPORT_USER_DELETE_REQUEST";
export const REPORT_USER_DELETE_SUCCESS = "REPORT_USER_DELETE_SUCCESS";
export const REPORT_USER_DELETE_ERROR = "REPORT_USER_DELETE_ERROR";

//
export const REPORT_USER_UPDATE_MUL_REQUEST = "REPORT_USER_UPDATE_MUL_REQUEST";
export const REPORT_USER_UPDATE_MUL_SUCCESS = "REPORT_USER_UPDATE_MUL_SUCCESS";

export const REPORT_USER_INSERT_MUL_REQUEST = "REPORT_USER_INSERT_MUL_REQUEST";
export const REPORT_USER_INSERT_MUL_SUCCESS = "REPORT_USER_INSERT_MUL_SUCCESS";

// shuukei tab
export const REPORT_USER_SHUUKEI_REQUEST = "REPORT_USER_SHUUKEI_REQUEST";
export const REPORT_USER_SHUUKEI_SUCCESS = "REPORT_USER_SHUUKEI_SUCCESS";
export const REPORT_USER_SHUUKEI_ERROR = "REPORT_USER_SHUUKEI_ERROR";

// Vital tab
export const REPORT_USER_VITAL_REQUEST = "REPORT_USER_VITAL_REQUEST";
export const REPORT_USER_VITAL_SUCCESS = "REPORT_USER_VITAL_SUCCESS";
export const REPORT_USER_VITAL_ERROR = "REPORT_USER_VITAL__ERROR";
export const REPORT_USER_VITAL_COPY = "REPORT_USER_VITAL_COPY";
export const REPORT_USER_VITAL_DEL = "REPORT_USER_VITAL_DEL"; // delete copy record(local only)

export const GAZOU_DELETE_REQUEST = "GAZOU_DELETE_REQUEST";
export const GAZOU_DELETE_SUCCESS = "GAZOU_DELETE_SUCCESS";
export const GAZOU_DELETE_ERROR = "GAZOU_DELETE_ERROR";

// Meal tab
export const REPORT_USER_MEAL_REQUEST = "REPORT_USER_MEAL_REQUEST";
export const REPORT_USER_MEAL_SUCCESS = "REPORT_USER_MEAL_SUCCESS";
export const REPORT_USER_MEAL_ERROR = "REPORT_USER_MEAL_ERROR";
export const REPORT_USER_MEAL_COPY = "REPORT_USER_MEAL_COPY";
export const REPORT_USER_MEAL_DEL = "REPORT_USER_MEAL_DEL";

// Suibun tab
export const REPORT_USER_SUIBUN_REQUEST = "REPORT_USER_SUIBUN_REQUEST";
export const REPORT_USER_SUIBUN_SUCCESS = "REPORT_USER_SUIBUN_SUCCESS";
export const REPORT_USER_SUIBUN_ERROR = "REPORT_USER_SUIBUN_ERROR";
export const REPORT_USER_SUIBUN_COPY = "REPORT_USER_SUIBUN_COPY";
export const REPORT_USER_SUIBUN_DEL = "REPORT_USER_SUIBUN_DEL";

// Haisetu
export const REPORT_USER_HAISETU_REQUEST = "REPORT_USER_HAISETU_REQUEST";
export const REPORT_USER_HAISETU_SUCCESS = "REPORT_USER_HAISETU_SUCCESS";
export const REPORT_USER_HAISETU_ERROR = "REPORT_USER_HAISETU_ERROR";
export const REPORT_USER_HAISETU_COPY = "REPORT_USER_HAISETU_COPY";
export const REPORT_USER_HAISETU_DEL = "REPORT_USER_HAISETU_DEL";

// NYUYOKU
export const REPORT_USER_NYUYOKU_REQUEST = "REPORT_USER_NYUYOKU_REQUEST";
export const REPORT_USER_NYUYOKU_SUCCESS = "REPORT_USER_NYUYOKU_SUCCESS";
export const REPORT_USER_NYUYOKU_ERROR = "REPORT_USER_NYUYOKU_ERROR";
export const REPORT_USER_NYUYOKU_COPY = "REPORT_USER_NYUYOKU_COPY";
export const REPORT_USER_NYUYOKU_DEL = "REPORT_USER_NYUYOKU_DEL";

// Fukuyaku
export const REPORT_USER_FUKUYAKU_REQUEST = "REPORT_USER_FUKUYAKU_REQUEST";
export const REPORT_USER_FUKUYAKU_SUCCESS = "REPORT_USER_FUKUYAKU_SUCCESS";
export const REPORT_USER_FUKUYAKU_ERROR = "REPORT_USER_FUKUYAKU_ERROR";
export const REPORT_USER_FUKUYAKU_COPY = "REPORT_USER_FUKUYAKU_COPY";
export const REPORT_USER_FUKUYAKU_DEL = "REPORT_USER_FUKUYAKU_DEL";

// Kaigonaiyo
export const REPORT_USER_KAIGO_REQUEST = "REPORT_USER_KAIGO_REQUEST";
export const REPORT_USER_KAIGO_SUCCESS = "REPORT_USER_KAIGO_SUCCESS";
export const REPORT_USER_KAIGO_ERROR = "REPORT_USER_KAIGO_ERROR";
export const REPORT_USER_KAIGO_COPY = "REPORT_USER_KAIGO_COPY";
export const REPORT_USER_KAIGO_DEL = "REPORT_USER_KAIGO_DEL";

// Tokki
export const REPORT_USER_TOKKI_REQUEST = "REPORT_USER_TOKKI_REQUEST";
export const REPORT_USER_TOKKI_SUCCESS = "REPORT_USER_TOKKI_SUCCESS";
export const REPORT_USER_TOKKI_ERROR = "REPORT_USER_TOKKI_ERROR";
export const REPORT_USER_TOKKI_COPY = "REPORT_USER_TOKKI_COPY";
export const REPORT_USER_TOKKI_DEL = "REPORT_USER_TOKKI_DEL";

// Gazou
export const REPORT_USER_GAZOU_REQUEST = "REPORT_USER_GAZOU_REQUEST";
export const REPORT_USER_GAZOU_SUCCESS = "REPORT_USER_GAZOU_SUCCESS";
export const REPORT_USER_GAZOU_ERROR = "REPORT_USER_GAZOU_ERROR";
export const REPORT_USER_GAZOU_COPY = "REPORT_USER_GAZOU_COPY";
export const REPORT_USER_GAZOU_DEL = "REPORT_USER_GAZOU_DEL";

// get setting data
export const REPORT_USER_FILTER_REQUEST = "REPORT_USER_FILTER_REQUEST";
export const REPORT_USER_FILTER_SUCCESS = "REPORT_USER_FILTER_SUCCESS";
export const REPORT_USER_FILTER_ERROR = "REPORT_USER_FILTER_ERROR";

export const REPORT_USER_LOAD_ZENKAI_REQUEST =
  "REPORT_USER_LOAD_ZENKAI_REQUEST";
export const REPORT_USER_LOAD_ZENKAI_SUCCESS =
  "REPORT_USER_LOAD_ZENKAI_SUCCESS";
export const REPORT_USER_LOAD_ZENKAI_ERROR = "REPORT_USER_LOAD_ZENKAI_ERROR";

export const REPORT_USER_GAZOU_INSERT_REQUEST =
  "REPORT_USER_GAZOU_INSERT_REQUEST";
export const REPORT_USER_GAZOU_INSERT_SUCCESS =
  "REPORT_USER_GAZOU_INSERT_SUCCESS";
export const REPORT_USER_GAZOU_INSERT_ERROR = "REPORT_USER_GAZOU_INSERT_ERROR";

export const REPORT_USER_GAZOU_UPDATE_REQUEST =
  "REPORT_USER_GAZOU_UPDATE_REQUEST";
export const REPORT_USER_GAZOU_UPDATE_SUCCESS =
  "REPORT_USER_GAZOU_UPDATE_SUCCESS";
export const REPORT_USER_GAZOU_UPDATE_ERROR = "REPORT_USER_GAZOU_UPDATE_ERROR";

export const REPORT_USER_GAZOU_DELETE_REQUEST =
  "REPORT_USER_GAZOU_DELETE_REQUEST";
export const REPORT_USER_GAZOU_DELETE_SUCCESS =
  "REPORT_USER_GAZOU_DELETE_SUCCESS";
export const REPORT_USER_GAZOU_DELETE_ERROR = "REPORT_USER_GAZOU_DELETE_ERROR";
