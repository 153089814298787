import { notification } from "antd/lib/index";
import {
  LOAD_ANSWER_ERROR,
  LOAD_ANSWER_REQUEST,
  LOAD_ANSWER_SUCCESS,
  LOAD_QUESTION_SUCCESS,
  RESET_CHOSA,
  UPDATE_ANSWER_ERROR,
  UPDATE_ANSWER_REQUEST,
  UPDATE_ANSWER_SUCCESS
} from "../../actions/kaigosochaAnswerActions/actionName";
const INITIAL_STATE = {
  messages: [],
  notifyDisplay: 0,
  chosaIdSelected: null

};
export default function kaigochosaAnswerReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_CHOSA:
      return {
        ...state,
        chosaIdSelected: null
      };
    case LOAD_ANSWER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        answer: action.data.answer,
        chosaIdSelected: action.data.chosaIdSelected
      };
    case LOAD_ANSWER_ERROR:
      return {
        ...state,
        loading: false,
        answer: action.data
      };
    case LOAD_QUESTION_SUCCESS:
      const myMap = new Map();
      Object.entries(action.data).forEach(([key, value]) => {
        myMap.set(key, value.sentakuItems.map(item => { return item.sortNum; }).filter(num => num !== 1));
      });
      return {
        ...state,
        question: action.data,
        sentakuItems: myMap
      };
    case UPDATE_ANSWER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_ANSWER_SUCCESS:
      notification["success"]({
        message: "保存しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        answer: action.data,
        loading: false,
        notifyContentEdit: { type: "success" }
      };
    case UPDATE_ANSWER_ERROR:
      notification["error"]({
        message: action.error.message,
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        loading: false,
        notifyContentEdit: { type: "error" }
      };
    default:
      return state;

  }
}