export const RIHABILI_REQUEST = "RIHABILI_REQUEST";
export const RIHABILI_ERROR = "RIHABILI_ERROR";

export const RIHABILI_LOADALL_SUCESS = "RIHABILI_LOADALL_SUCESS";
export const RIHABILI_LOAD_LIFE_SUCESS = "RIHABILI_LOAD_LIFE_SUCESS";
export const RIHABILI_KEEP_VALUE_SEARCH = "RIHABILI_KEEP_VALUE_SEARCH";
//興味
export const RIHABILI_LOAD_KYOMI_SUCESS = "RIHABILI_LOAD_KYOMI_SUCESS";
export const RIHABILI_LOAD_KYOMI_BY_ID_SUCESS =
  "RIHABILI_LOAD_KYOMI_BY_ID_SUCESS";
export const RESET_CURRENT_KYOMI = "RESET_CURRENT_KYOMI";
export const CREATE_KYOMI_SUCESS = "CREATE_KYOMI_SUCESS";
export const UPDATE_KYOMI_SUCESS = "UPDATE_KYOMI_SUCESS";
//会議
export const RIHABILI_LOAD_TANTOKAIGI_SUCESS =
  "RIHABILI_LOAD_TANTOKAIGI_SUCESS";
export const RIHABILI_LOAD_TANTOKAIGI_BY_ID_SUCESS =
  "RIHABILI_LOAD_TANTOKAIGI_BY_ID_SUCESS";
export const RESET_CURRENT_TANTOKAIGI = "RESET_CURRENT_TANTOKAIGI";
export const CREATE_TANTOKAIGI_SUCESS = "CREATE_TANTOKAIGI_SUCESS";
export const UPDATE_TANTOKAIGI_SUCESS = "UPDATE_TANTOKAIGI_SUCESS";
export const GET_DATA_KIKAN_MATER_REQUEST = "GET_DATA_KIKAN_MATER_REQUEST";
export const SAVE_KAIGI_TEMPLATE_SUCCESS = "SAVE_KAIGI_TEMPLATE_SUCCESS";
export const LOAD_KAIGI_TEMPLATE_SUCCESS = "LOAD_KAIGI_TEMPLATE_SUCCESS";
//プロセス
export const RIHABILI_LOAD_PROCESS_SUCESS = "RIHABILI_LOAD_PROCESS_SUCESS";
export const RIHABILI_LOAD_PROCESS_BY_ID_SUCESS =
  "RIHABILI_LOAD_PROCESS_BY_ID_SUCESS";
export const RESET_CURRENT_PROCESS = "RESET_CURRENT_PROCESS";
export const CREATE_PROCESS_SUCESS = "CREATE_PROCESS_SUCESS";
export const UPDATE_PROCESS_SUCESS = "UPDATE_PROCESS_SUCESS";
export const GET_SETTING_MASTER_PROCESS_SUCESS =
  "GET_SETTING_MASTER_PROCESS_SUCESS";
export const PROCESS_LOAD_KAIGI_REQUEST = "PROCESS_LOAD_KAIGI_REQUEST";
export const PROCESS_LOAD_KAIGI_SUCESS = "PROCESS_LOAD_KAIGI_SUCESS";

export const RIHABILI_LOAD_LIFE_RIYOU_SUCESS =
  "RIHABILI_LOAD_LIFE_RIYOU_SUCESS";

export const RIHABILI_DEL_LIFE = "RIHABILI_DEL_LIFE";
export const RIHABILI_DEL_KYOMI = "RIHABILI_DEL_KYOMI";
export const RIHABILI_DEL_KAIGI = "RIHABILI_DEL_KAIGI";
export const RIHABILI_DEL_PROCESS = "RIHABILI_DEL_PROCESS";

export const CREATE_LIFE_SUCESS = "CREATE_LIFE_SUCESS";
