import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import {
  formatStorage,
  getMenu,
  localStorageClearCus,
} from "../common/function_common/functionCommon";
// import { ApiPaths } from "../life/constants";

// 医療保険
const IryouhokenApi = {
  async list(pageNo, pageSize, mainSID, riyousyaId) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.IRYOUHOKEN_GET_LIST +
        `?pageNo=${pageNo}&pageSize=${pageSize}&sid=${mainSID}&riyousyaId=${riyousyaId}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
  async save(params) {
    const mainSID = formatStorage(getMenu()).si;
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.UPDATE_IRYOUHOKEN +
        `?mainSID=${mainSID}&systemName=${systemName}`,
        {
          method: "POST",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
  async del(id) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.DELETE_IRYOUHOKEN + `?id=${id}`, {
        method: "DELETE",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
  async getIryouhoken(hokenNo) {
    const res = await axios
      .get(apiUrls.GET_IRYOUHOKEN_BY_NO, {
        params: {
          hokenNo,
        },
      });
    return res.data;
  },
};
// 高額負担医療費
const IryoKougakuFutanApi = {
  async list(pageNo, pageSize, mainSID, riyousyaId) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.IRYO_KOUGAKU_FUTAN_GET_LIST +
        `?pageNo=${pageNo}&pageSize=${pageSize}&sid=${mainSID}&riyousyaId=${riyousyaId}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
  async save(params) {
    const mainSID = formatStorage(getMenu()).si;
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.UPDATE_IRYO_KOUGAKU_FUTAN +
        `?mainSID=${mainSID}&systemName=${systemName}`,
        {
          method: "POST",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
  async del(id) {
    try {
      const res = await axios(
        apiUrls.INIT + apiUrls.DELETE_IRYO_KOUGAKU_FUTAN + `?id=${id}`,
        {
          method: "DELETE",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

// 医療保険マスタ
const IryouhokenMasterApi = {
  async getList(hokenNo) {
    try {
      const res = await axios(
        apiUrls.INIT + apiUrls.GET_IRYOUHOKEN_LIST + `?hokenNo=${hokenNo}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
  async init() {
    const res = await axios.get(apiUrls.GET_IRYOUHOKEN_INIT);
    return res.data;
  },
  async create(data) {
    const res = await axios
      .post(apiUrls.CREATE_IRYOUHOKEN_MASTER, data);
    return res.data;
  },
  async update(data) {
    const res = await axios
      .put(apiUrls.UPDATE_IRYOUHOKEN_MASTER, data);
    return res.data;
  },
  async updateList(data) {
    const res = await axios
      .put(apiUrls.UPDATE_LIST_IRYOUHOKEN_MASTER, data);
    return res.data;
  },
  // loadMaxSort() {
  //   return axios
  //     .get(apiUrls.GET_KOHIFUTAN_MASTER_MAX_SORT)
  //     .then((res) => res.data);
  // },
};
// 医療高額負担マスタ
const IryoKougakuFutanMasterApi = {
  async getList(bunrui) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.IRYO_KOUGAKU_FUTAN_MASTER_GET_LIST +
        `?bunrui=${bunrui}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        console.log(err);
      }
      else {
        if (err.response.status === 403) {
          localStorageClearCus();

          window.location.replace("/login");
        }

        return err;
      }
    }
  },
};

export {
  IryoKougakuFutanApi, IryoKougakuFutanMasterApi, IryouhokenApi, IryouhokenMasterApi
};

