import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Table } from "antd";

import { RiyouConcerApi } from "../../../../api/riyouConcern";

class RiyouConcernModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      meta: {},
      selectedRecord: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.fetchRiyouConcern(this.state.page);
    }
  }

  fetchRiyouConcern(page) {
    if (this.props.riyousyaId) {
      RiyouConcerApi.loadRiyouConcern(page, this.props.riyousyaId).then(
        (result) => {
          const { data, meta } = result;
          if (Array.isArray(data)) {
            this.setState({ data, meta });
          }
        },
        () => {}
      );
    }
  }

  handlePageChange(page) {
    this.setState({ page });
    this.fetchRiyouConcern(page);
  }

  handleOk = () => {
    this.props.onHide();
    const { selectedRecord } = this.state;

    if (selectedRecord && selectedRecord.id) {
      this.props.onSelect(selectedRecord);
    }
  };

  handleCancel = () => {
    this.props.onHide();
  };

  handleSelectRow(record) {
    this.setState({ selectedRecord: record });
  }

  render() {
    const columns = [
      {
        title: "氏名",
        dataIndex: "name",
        key: "name",
        width: "40%",
      },
      {
        title: "関係",
        dataIndex: "kankei",
        key: "kankei",
        width: "60%",
      },
    ];
    const { data, meta, selectedRecord } = this.state;
    const pagingOptions = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: meta.total || 0,
      current: meta.page || 1,
      pageSize: meta.pageSize || 8,
    };

    return (
      (<Modal
        open={this.props.visible}
        title="関係者リスト"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="tanto-riyou-concern-modal"
      >
        <Table
          rowKey="id"
          style={{ cursor: "pointer" }}
          size={"small"}
          bordered={true}
          columns={columns}
          dataSource={data}
          pagination={pagingOptions}
          onRow={(record) => ({
            onClick: () => this.handleSelectRow(record),
          })}
          rowClassName={(record) =>
            selectedRecord != null && record.id === selectedRecord.id
              ? "pljp-table-row-hover"
              : "pointer-row"
          }
        />
      </Modal>)
    );
  }
}

RiyouConcernModal.propTypes = {
  visible: PropTypes.bool,
  riyousyaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  onHide: PropTypes.func,
};

export default RiyouConcernModal;
