import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

export const TantoKaigiApi = {
  async loadTantoKaigi(params) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.GET_TANTO_KAIGI_BY_RIYOUSYA, {
        params: {
          sid: menu.si,
          systemName: menu.sn,
          riyousyaId: params.riyousyaId,
          // riyousyaId: 286, // for test
          page: params.page,
          sort: params.sort,
          excludeId: params.excludeId,
        },
      });
    return res.data;
  },
  async deleteTantoKaigi(tantoKaigiId) {
    const res = await axios
      .delete(apiUrls.DELETE_TANTO_KAIGI, {
        params: {
          id: tantoKaigiId,
        },
      });
    return res.data;
  },
  async loadTantoKaigiByDate(params) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.LOAD_TANTO_KAIGI_BY_DATE, {
        params: {
          // sid: 1, // for test
          // riyousyaId: 18, // for test
          sid: menu.si,
          riyousyaId: params.riyousyaId,
          page: params.page,
          startDate: params.startDate,
          endDate: params.endDate,
        },
      });
    return res.data;
  },
  async updateTantoKaigi(params, tantoKaigiId) {
    const key = getMenu();
    const sid = JSON.parse(localStorage.getItem(key)).si;
    const payload = {
      tanto_kaigi_id: tantoKaigiId ? parseInt(tantoKaigiId) : null,
      riyousya_id: params.riyousyaId ? parseInt(params.riyousyaId) : null,
      service_kikan_master_id: sid ? parseInt(sid) : null,
      ...params,
    };
    delete payload["riyousyaId"];
    const res = await axios
      .post(apiUrls.UPDATE_TANTO_KAIGI, payload);
    return res.data;
  },
  async createTantoKaigi(data, riyousyaId) {
    const key = getMenu();
    const sid = JSON.parse(localStorage.getItem(key)).si;
    const res = await axios
      .post(apiUrls.CREATE_TANTO_KAIGI, {
        riyousya_id: riyousyaId ? parseInt(riyousyaId) : null,
        service_kikan_master_id: sid ? parseInt(sid) : null,
        ...data,
      });
    return res.data;
  },
  async loadTantoKaigiById(tantoKaigiId) {
    const res = await axios
      .get(apiUrls.GET_TANTO_KAIGI_BY_iD, {
        params: { id: tantoKaigiId },
      });
    return res.data;
  },
  async saveTantoKaigiTemplate(data) {
    const key = getMenu();
    const sid = JSON.parse(localStorage.getItem(key)).si;
    const res = await axios
      .post(apiUrls.CREATE_TANTO_KAIGI_TEMPLATE, {
        ...data,
        service_kikan_master_id: sid ? parseInt(sid) : null,
      });
    return res.data;
  },
  async loadTantoKaigiTemplate() {
    const key = getMenu();
    const sid = JSON.parse(localStorage.getItem(key)).si;
    const res = await axios
      .get(apiUrls.GET_TANTO_KAIGI_TEMPLATE, {
        params: {
          sid: sid ? parseInt(sid) : null,
        },
      });
    return res.data;
  },
  async getDataKikanMaster(id) {
    const res = await axios.get(`${apiUrls.GET_DATA_KIKAN_MASTER}/${id}`);
    return res.data;
  },
};
