import React from "react";
import { Col, Row, InputNumber, Button, Popover } from "antd";

export default class NumberDropKB extends React.Component {
  constructor(props) {
    super(props);

    this.handleInput = this.handleInput.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleInput(value) {
    this.props.handleNoDropInput(value, true);
  }

  onChange(value) {
    this.props.handleNoDropInput(value, false);
  }

  render() {
    const { value } = this.props;
    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            <Button disabled onClick={() => this.handleInput(".")}>
              .
            </Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
        </Col>
      </Row>
    );

    return (
      <Row>
        <InputNumber
          min={0}
          max={100000}
          onChange={this.onChange}
          value={value}
        />
        <Popover placement="bottomRight" content={numberKB} trigger="click">
          <Button>入力</Button>
        </Popover>
      </Row>
    );
  }
}
