import _ from "lodash";
/* eslint-disable no-nested-ternary */
export const processDataTable = function (sisetuDailyPlan, expand) {
  const dataTable = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 2; j++) {
      const hour = i < 10 ? "0" + i : i;
      const time = hour + ":" + (j === 0 ? "00" : "30");
      if (sisetuDailyPlan) {
        const tmp = sisetuDailyPlan.dailyPlanDetails.filter((rc) => {
          return rc.time.substring(0, 5) === time;
        });
        let kaigoNaiyou = "";
        let nitijouSeikatuNaiyou = "";
        let kobetuShousai = "";
        if (tmp && tmp.length > 1) {
          for (let k = 0; k < tmp.length; k++) {
            if (k > 0) {
              if (tmp[k].kaigoNaiyou === null && kaigoNaiyou === null) {
                kaigoNaiyou = null;
              } else if (kaigoNaiyou === null || kaigoNaiyou === "") {
                if (tmp[k].kyotuf === 1) {
                  kaigoNaiyou = tmp[k].kaigoNaiyou
                    ? "(共)" + tmp[k].kaigoNaiyou
                    : "　";
                } else {
                  kaigoNaiyou = tmp[k].kaigoNaiyou ? tmp[k].kaigoNaiyou : "　";
                }
              } else if (kaigoNaiyou !== null) {
                if (tmp[k].kyotuf === 1) {
                  kaigoNaiyou = tmp[k].kaigoNaiyou
                    ? kaigoNaiyou + "、" + "(共)" + tmp[k].kaigoNaiyou
                    : kaigoNaiyou;
                } else {
                  kaigoNaiyou = tmp[k].kaigoNaiyou
                    ? kaigoNaiyou + "、" + tmp[k].kaigoNaiyou
                    : kaigoNaiyou;
                }
              }
              nitijouSeikatuNaiyou =
                nitijouSeikatuNaiyou === null
                  ? tmp[k].nitijouSeikatuNaiyou != null ||
                    tmp[k].nitijouSeikatuNaiyou !== ""
                    ? tmp[k].nitijouSeikatuNaiyou
                    : null
                  : nitijouSeikatuNaiyou;
              kobetuShousai =
                kobetuShousai === null
                  ? tmp[k].kobetuShousai != null || tmp[k].kobetuShousai !== ""
                    ? tmp[k].kobetuShousai
                    : null
                  : kobetuShousai;
            } else {
              kaigoNaiyou =
                tmp[k].kaigoNaiyou === null
                  ? null
                  : tmp[k].kyotuf === 1
                  ? "(共)" + tmp[k].kaigoNaiyou
                  : tmp[k].kaigoNaiyou;
              nitijouSeikatuNaiyou =
                tmp[k].nitijouSeikatuNaiyou != null ||
                tmp[k].nitijouSeikatuNaiyou !== ""
                  ? tmp[k].nitijouSeikatuNaiyou
                  : null;
              kobetuShousai =
                tmp[k].kobetuShousai !== null || tmp[k].kobetuShousai !== ""
                  ? tmp[k].kobetuShousai
                  : null;
            }
          }
        } else if (tmp && tmp.length === 1) {
          kaigoNaiyou = tmp[0].kaigoNaiyou
            ? tmp[0].kyotuf === 1
              ? "(共)" + tmp[0].kaigoNaiyou
              : tmp[0].kaigoNaiyou
            : "　";
          nitijouSeikatuNaiyou = tmp[0].nitijouSeikatuNaiyou;
          kobetuShousai = tmp[0].kobetuShousai;
        }
        dataTable.push({
          id: time,
          time: time.substring(3, 4) !== "3" ? time : "",
          kaigoNaiyou: tmp.length > 0 ? kaigoNaiyou : "",
          nitijouSeikatuNaiyou: tmp.length > 0 ? nitijouSeikatuNaiyou : "",
          kobetuShousai: tmp.length > 0 ? kobetuShousai : "",
        });
      }
    }
  }
  return dataTable;
};

export const processSisetuDailyPlan = function (
  sisetuDailyPlan,
  values,
  tempName,
  seikatuNaiyou
) {
  let sisetu_daily_plan = null;
  if (sisetuDailyPlan) {
    let dataDetailClone = sisetuDailyPlan.dailyPlanDetails.filter(
      (item) =>
        !_.isEmpty(item.kaigoNaiyou) ||
        !_.isEmpty(item.nitijouSeikatuNaiyou) ||
        !_.isEmpty(item.kobetuShousai)
    );
    let dataDetail;
    if (tempName) {
      // dataDetailClone = dataDetailClone.filter(item => item.kaigoNaiyou);
      dataDetail = dataDetailClone.map((rc) => {
        return {
          time: rc.time
            ? rc.time.length === 5
              ? rc.time + ":00"
              : rc.time
            : null,
          kaigo_naiyou_code: rc.kaigoNaiyouCode || null,
          kyotuf: rc.kyotuf || 0,
          nitijou_seikatu_naiyou: rc.nitijouSeikatuNaiyou || null,
          kobetu_shousai: rc.kobetuShousai || null,
        };
      });
    } else if (seikatuNaiyou) {
      // dataDetailClone = dataDetailClone.filter(item => item.nitijouSeikatuNaiyou);
      dataDetail = dataDetailClone.map((rc) => {
        return {
          time: rc.time
            ? rc.time.length === 5
              ? rc.time + ":00"
              : rc.time
            : null,
          kaigo_naiyou_code: rc.kaigoNaiyouCode || null,
          kyotuf: rc.kyotuf || 0,
          nitijou_seikatu_naiyou: rc.nitijouSeikatuNaiyou || null,
          kobetu_shousai: rc.kobetuShousai || null,
        };
      });
    } else {
      dataDetail = dataDetailClone.map((rc) => {
        return {
          time: rc.time
            ? rc.time.length === 5
              ? rc.time + ":00"
              : rc.time
            : null,
          kaigo_naiyou_code: rc.kaigoNaiyouCode || null,
          kyotuf: rc.kyotuf || 0,
          nitijou_seikatu_naiyou: rc.nitijouSeikatuNaiyou || null,
          kobetu_shousai: rc.kobetuShousai || null,
        };
      });
    }
    values.progressF = values.progressF.length > 0 ? 1 : 0;
    values.riyousya_id = tempName ? -1 : sisetuDailyPlan.riyousyaId;
    sisetu_daily_plan = values;
    sisetu_daily_plan.daily_plan_details = dataDetail;
    sisetu_daily_plan.temp_name = tempName
      ? tempName
      : sisetuDailyPlan.tempName;
    // sisetu_daily_plan.sisetu_daily_plan_id = sisetuDailyPlan.id && sisetuDailyPlan.id > 0 ? sisetuDailyPlan.id : null;
  }

  const details =
    sisetu_daily_plan && sisetu_daily_plan.daily_plan_details
      ? sisetu_daily_plan.daily_plan_details
      : [];
  const sortedDetails = _.groupBy(details, "time");
  const newDetails = [];

  for (let time in sortedDetails) {
    let naiyou = "";
    let shousai = "";
    let kyotufs = "";
    let codes = "";

    for (let item of sortedDetails[time]) {
      if (!naiyou) {
        naiyou = item["nitijou_seikatu_naiyou"];
      }
      if (!shousai) {
        shousai = item["kobetu_shousai"];
      }

      if (!item["kaigo_naiyou_code"] && !item["kyotuf"]) continue;

      kyotufs = !kyotufs
        ? `${item["kyotuf"] || 0}`
        : kyotufs + ";" + item["kyotuf"];
      codes = !codes
        ? `${item["kaigo_naiyou_code"]}`
        : codes + ";" + item["kaigo_naiyou_code"];
    }

    newDetails.push({
      time,
      kaigo_naiyou_codes: codes,
      kyotufs: kyotufs || "0",
      nitijou_seikatu_naiyou: naiyou,
      kobetu_shousai: shousai,
    });
  }

  sisetu_daily_plan["daily_plan_details"] = newDetails;
  return sisetu_daily_plan;
};

export const processKaigoNaiyou = function (kaigoNaiyou, sisetuDailyPlan) {
  if (kaigoNaiyou) {
    const newDetail = kaigoNaiyou.dailyPlanDetails;
    const sisetuDailyPlanClone = sisetuDailyPlan.dailyPlanDetails;

    if (sisetuDailyPlanClone.length === 0) {
      kaigoNaiyou.dailyPlanDetails.map((detail) => {
        sisetuDailyPlanClone.push({
          id: null,
          time: detail.time,
          kaigoNaiyouCode: detail.kaigoNaiyouCode,
          kaigoNaiyou: detail.kaigoNaiyou,
          kaigoTantosya: detail.kaigoTantosya,
          kobetuShousai: detail.kobetuShousai || null,
          kyotuf: detail.kyotuf,
          nitijouSeikatuNaiyou: detail.nitijouSeikatuNaiyou || null,
        });
      });
    } else if (newDetail.length > 0) {
      newDetail.map((detail, index) => {
        sisetuDailyPlanClone.forEach((data) => {
          if (detail.time == data.time) {
            if (data.nitijouSeikatuNaiyou) {
              newDetail[index].nitijouSeikatuNaiyou = data.nitijouSeikatuNaiyou;
            }
            if (data.kobetuShousai) {
              newDetail[index].kobetuShousai = data.kobetuShousai;
            }
          }
        });
      });
      const lsTime = _.differenceBy(sisetuDailyPlanClone, newDetail, "time");
      const uniqueLstime = _.unionBy(lsTime, "time");
      uniqueLstime.forEach((rc) => {
        rc.kaigoNaiyouCode = null;
        (rc.kaigoNaiyou = null), (rc.kaigoTantosya = null);
        rc.kyotuf = 0;
        newDetail.push(rc);
      });
    } else if (newDetail.length === 0) {
      if (sisetuDailyPlanClone.length === 0) {
        newDetail.push({
          id: null,
          time: null,
          kaigoNaiyouCode: null,
          kaigoNaiyou: null,
          kaigoTantosya: null,
          kobetuShousai: null,
          kyotuf: 0,
          nitijouSeikatuNaiyou: null,
        });
      } else {
        sisetuDailyPlanClone.map((rc) => {
          rc.kaigoNaiyouCode = null;
          rc.kaigoNaiyou = null;
          rc.kaigoTantosya = null;
          rc.kyotuf = 0;
        });
      }
      const lsTime = _.differenceBy(sisetuDailyPlanClone, newDetail, "time");
      const uniqueLstime = _.unionBy(lsTime, "time");
      uniqueLstime.forEach((rc) => {
        rc.kaigoNaiyouCode = null;
        (rc.kaigoNaiyou = null), (rc.kaigoTantosya = null);
        rc.kyotuf = 0;
        newDetail.push(rc);
      });
    }
    return newDetail;
  }
};

export const processSeikatuNaiyou = function (seikatuNaiyou, sisetuDailyPlan) {
  if (seikatuNaiyou) {
    const newDetail = seikatuNaiyou.dailyPlanDetails;
    const sisetuDailyPlanClone = sisetuDailyPlan.dailyPlanDetails;
    if (sisetuDailyPlanClone.length === 0) {
      newDetail.map((detail) => {
        sisetuDailyPlanClone.push({
          id: null,
          time: detail.time,
          kaigoNaiyouCode: null,
          kaigoNaiyou: null,
          kaigoTantosya: null,
          kobetuShousai: null,
          kyotuf: 0,
          nitijouSeikatuNaiyou: detail.nitijouSeikatuNaiyou,
        });
      });
    } else {
      sisetuDailyPlanClone.map((rc) => {
        rc.nitijouSeikatuNaiyou = null;
      });

      const lsTime = _.differenceBy(newDetail, sisetuDailyPlanClone, "time");
      const uniqueLstime = _.unionBy(lsTime, "time");
      uniqueLstime.forEach((data) => {
        sisetuDailyPlanClone.push(data);
      });
      newDetail.map((detail) => {
        sisetuDailyPlanClone.forEach((data, index) => {
          if (detail.time == data.time && detail.nitijouSeikatuNaiyou) {
            sisetuDailyPlanClone[index].nitijouSeikatuNaiyou =
              detail.nitijouSeikatuNaiyou;
          }
        });
      });
    }
    return sisetuDailyPlanClone;
  }
};

export const processDataTableNew = (sisetuDailyPlan) => {
  if (!sisetuDailyPlan) return {};

  const { dailyPlanDetails } = sisetuDailyPlan;
  const newDailyPlanDetails = [];
  const timeStep = generateTimeStep();

  for (const step of timeStep) {
    const foundItem =
      dailyPlanDetails &&
      dailyPlanDetails.find((item) => item.time === `${step}:00`);

    if (foundItem) {
      const kaigoNaiyous = getKaigoNaiyous(
        foundItem.kaigoNaiyouCode,
        foundItem.kaigoNaiyouName,
        foundItem.kyotuf
      );
      const kaigoNaiyou = splitKaigoNaiyouName(
        foundItem.kaigoNaiyouCode,
        foundItem.kaigoNaiyouName,
        foundItem.kyotuf
      );
      newDailyPlanDetails.push({
        ...foundItem,
        time: step,
        kaigoNaiyous,
        kaigoNaiyou,
      });
    } else {
      newDailyPlanDetails.push({
        time: step,
        kyotuf: "",
        kaigoNaiyouCode: "",
        kaigoNaiyouName: "",
        kobetuShousai: "",
        nitijouSeikatuNaiyou: "",
        kaigoNaiyous: [],
        kaigoNaiyou: "",
      });
    }
  }

  sisetuDailyPlan["newDailyPlanDetails"] = newDailyPlanDetails;
  return sisetuDailyPlan;
};

export function getKaigoNaiyouText(kaigoNaiyous) {
  let kyotuf = "";
  let kaigoNaiyouName = "";
  let kaigoNaiyouCode = "";

  for (const kaigoNaiyou of kaigoNaiyous) {
    if (!kaigoNaiyou.code) continue;

    const comma = kaigoNaiyouCode ? ";" : "";

    kaigoNaiyouCode += comma + kaigoNaiyou.code;
    kaigoNaiyouName += comma + kaigoNaiyou.name;
    kyotuf += comma + kaigoNaiyou.kyotuf;
  }

  return {
    kyotuf,
    kaigoNaiyouName,
    kaigoNaiyouCode,
  };
}

export const processDataTableView = (sisetuDailyPlan) => {
  if (
    !sisetuDailyPlan ||
    !sisetuDailyPlan.dailyPlanDetails ||
    sisetuDailyPlan.dailyPlanDetails.length === 0
  )
    return [];

  const { dailyPlanDetails } = sisetuDailyPlan;
  const newDailyPlanDetails = [];
  const timeStep = generateTimeStep();

  for (const step of timeStep) {
    const foundItem = dailyPlanDetails.find(
      (item) => item.time === `${step}:00`
    );

    if (foundItem) {
      const { kaigoNaiyou, kaigoNaiyouF0 } = getKaigoNaiyouByKyotuf(
        foundItem.kaigoNaiyouName,
        foundItem.kyotuf,
        foundItem.tantosya
      );
      newDailyPlanDetails.push({
        time: step,
        kobetuShousai: foundItem.kobetuShousai,
        nitijouSeikatuNaiyou: foundItem.nitijouSeikatuNaiyou,
        kaigoNaiyou,
        kaigoNaiyouF0,
      });
    } else {
      newDailyPlanDetails.push({
        time: step,
        kobetuShousai: "",
        nitijouSeikatuNaiyou: "",
        kaigoNaiyou: "",
        kaigoNaiyouF0: "",
      });
    }
  }

  return newDailyPlanDetails;
};

function generateTimeStep() {
  const timeStep = [];

  for (let i = 0; i < 24; i++) {
    let hour = "";
    let time = "";

    hour = i < 10 ? `0${i}` : `${i}`;
    timeStep.push(`${hour}:00`);

    time = `${hour}:30`;
    timeStep.push(time);
  }

  return timeStep;
}

function getKaigoNaiyous(kaigoNaiyouCode, kaigoNaiyouName, kyotuf) {
  if (!kaigoNaiyouCode) return [];

  const kaigoNaiyous = [];
  const codes = kaigoNaiyouCode.split(";");
  const names = kaigoNaiyouName.split(";");
  const kyotufs = kyotuf.split(";");

  for (let i = 0; i < codes.length; i++) {
    kaigoNaiyous.push({
      code: codes[i],
      name: names[i],
      kyotuf: parseInt(kyotufs[i], 10),
    });
  }
  return kaigoNaiyous;
}

function splitKaigoNaiyouName(kaigoNaiyouCode, kaigoNaiyouName, kyotuf) {
  if (!kaigoNaiyouCode) return "";

  let kaigoNaiyou = "";
  const codes = kaigoNaiyouCode.split(";");
  const names = kaigoNaiyouName.split(";");
  const kyotufs = kyotuf.split(";");

  for (let i = 0; i < codes.length; i++) {
    const kyot = parseInt(kyotufs[i], 10);
    const comma = kaigoNaiyou ? "、" : "";

    if (kyot === 1) {
      kaigoNaiyou += comma + "(共)" + names[i];
    } else {
      kaigoNaiyou += comma + names[i];
    }
  }
  return kaigoNaiyou;
}

function getKaigoNaiyouByKyotuf(kaigoNaiyouName, kyotuf, tantosya) {
  let kaigoNaiyou = "";
  let kaigoNaiyouF0 = "";

  if (kaigoNaiyouName) {
    const names = kaigoNaiyouName.split(";");
    const kyotufs = kyotuf.split(";");
    const tantosyas = (tantosya && tantosya.split(";")) || [];

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const kyot = parseInt(kyotufs[i], 10);
      const comma = kaigoNaiyou ? "、" : "";
      const commaF0 = kaigoNaiyouF0 ? "、" : "";
      const tanto = tantosyas[i] ? ` (${tantosyas[i]})` : "";

      if (kyot === 1) {
        kaigoNaiyou += comma + name + tanto;
      } else {
        kaigoNaiyouF0 += commaF0 + name + tanto;
      }
    }
  }

  return {
    kaigoNaiyou,
    kaigoNaiyouF0,
  };
}
