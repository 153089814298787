import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Col, Row } from "antd";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { getIdForcus } from "../../../actions/documentList/actionCreators";
import LineBreakText from "../../../common/component/LineBreakText";
import { parseAttendance } from "../../../common/function_common/tantoKaigiAttendance";

const w = window.innerWidth;
const currentMenu = localStorage.getItem("currentmenu") || "";
class RowContentRiyousya extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
  }
  checkRecordActive = (data) => {
    switch (data.tabActive) {
      case "1":
        if (data.isUserMode) {
          if (
            localStorage.getItem("RowKeyRiyou1") ===
            data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
          ) {
            this.setState({
              expand: true,
            });
          }
          else {
            this.setState({
              expand: false,
            });
          }
          break;
        }
        else if (
          localStorage.getItem("RowKey1") ===
          data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
        ) {
          this.setState({
            expand: true,
          });
        }
        else {
          this.setState({
            expand: false,
          });
        }
        break;
      case "2":
        if (data.isUserMode) {
          if (
            localStorage.getItem("RowKeyRiyou2") ===
            data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
          ) {
            this.setState({
              expand: true,
            });
          }
          else {
            this.setState({
              expand: false,
            });
          }
        }
        else if (
          localStorage.getItem("RowKey2") ===
          data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
        ) {
          this.setState({
            expand: true,
          });
        }
        else {
          this.setState({
            expand: false,
          });
        }
        break;
      case "3":
        if (data.isUserMode) {
          if (
            localStorage.getItem("RowKeyRiyou3") ===
            data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
          ) {
            this.setState({
              expand: true,
            });
          }
          else {
            this.setState({
              expand: false,
            });
          }
        }
        else if (
          localStorage.getItem("RowKey3") ===
          data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
        ) {
          this.setState({
            expand: true,
          });
        }
        else {
          this.setState({
            expand: false,
          });
        }
        break;
      case "4":
        if (data.isUserMode) {
          if (
            localStorage.getItem("RowKeyRiyou4") ===
            data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
          ) {
            this.setState({
              expand: true,
            });
          }
          else {
            this.setState({
              expand: false,
            });
          }
        }
        else if (
          localStorage.getItem("RowKey4") ===
          data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
        ) {
          this.setState({
            expand: true,
          });
        }
        else {
          this.setState({
            expand: false,
          });
        }
        break;
      case "5":
        if (data.isUserMode) {
          if (
            localStorage.getItem("RowKeyRiyou5") ===
            data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
          ) {
            this.setState({
              expand: true,
            });
          }
          else {
            this.setState({
              expand: false,
            });
          }
        }
        else if (
          localStorage.getItem("RowKey5") ===
          data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
        ) {
          this.setState({
            expand: true,
          });
        }
        else {
          this.setState({
            expand: false,
          });
        }
        break;
      case "6":
        if (data.isUserMode) {
          if (
            localStorage.getItem("RowKeyRiyou6") ===
            data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
          ) {
            this.setState({
              expand: true,
            });
          }
          else {
            this.setState({
              expand: false,
            });
          }
        }
        else if (
          localStorage.getItem("RowKey6") ===
          data.data.id + "_" + data.data.riyousyaId + "_" + data.data.sid
        ) {
          this.setState({
            expand: true,
          });
        }
        else {
          this.setState({
            expand: false,
          });
        }
        break;
      default:
        break;
    }
  };
  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.expandedRows.indexOf(
        this.props.data.id +
        "_" +
        this.props.data.riyousyaId +
        "_" +
        this.props.data.sid
      ) > -1
    ) {
      this.setState({
        expand: true,
      });
    }
    else if (newProps.tabActive !== this.props.tabActive) {
      this.checkRecordActive(newProps);
    }
    else if (newProps.tabActive === this.props.tabActive) {
      this.checkRecordActive(newProps);
    }
    else {
      this.setState({
        expand: false,
      });
    }
  }

  async componentDidMount() {
    await this.checkRecordActive(this.props);
    switch (this.props.tabActive) {
      case "1":
        if (document.getElementById("record-active-tab1")) {
          document.getElementById("record-active-tab1").scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
        }
        else {
          window.scroll(0, 0);
        }

        break;
      case "2":
        if (document.getElementById("record-active-tab2")) {
          document.getElementById("record-active-tab2").scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
        }
        else {
          window.scroll(0, 0);
        }
        break;
      case "3":
        if (document.getElementById("record-active-tab3")) {
          document.getElementById("record-active-tab3").scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
        }
        else {
          window.scroll(0, 0);
        }
        break;
      case "4":
        if (document.getElementById("record-active-tab4")) {
          document.getElementById("record-active-tab4").scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
        }
        else {
          window.scroll(0, 0);
        }
        break;
      case "5":
        if (document.getElementById("record-active-tab5")) {
          document.getElementById("record-active-tab5").scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
        }
        else {
          window.scroll(0, 0);
        }
        break;
      case "6":
        if (document.getElementById("record-active-tab6")) {
          document.getElementById("record-active-tab6").scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
        }
        else {
          window.scroll(0, 0);
        }
        break;
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.tabActive !== this.props.tabActive) {
      switch (this.props.tabActive) {
        case "1":
          if (document.getElementById("record-active-tab1")) {
            document.getElementById("record-active-tab1").scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
          }
          else {
            window.scroll(0, 0);
          }
          break;
        case "2":
          if (document.getElementById("record-active-tab2")) {
            document.getElementById("record-active-tab2").scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
          }
          else {
            window.scroll(0, 0);
          }
          break;
        case "3":
          if (document.getElementById("record-active-tab3")) {
            document.getElementById("record-active-tab3").scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
          }
          else {
            window.scroll(0, 0);
          }
          break;
        case "4":
          if (document.getElementById("record-active-tab4")) {
            document.getElementById("record-active-tab4").scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
          }
          else {
            window.scroll(0, 0);
          }
          break;
        case "5":
          if (document.getElementById("record-active-tab5")) {
            document.getElementById("record-active-tab5").scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
          }
          else {
            window.scroll(0, 0);
          }
          break;
        case "6":
          if (document.getElementById("record-active-tab6")) {
            document.getElementById("record-active-tab6").scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
          }
          else {
            window.scroll(0, 0);
          }
          break;
      }
    }
  }

  handleOnclick = (e) => {
    if (e.target.type !== "button") {
      this.props.handleExpand(!this.state.expand, this.props.data);
    }
  };

  goToRiyousyaDocList(riyousyaId) {
    this.props.history.push("/document-list/" + riyousyaId);
  }

  goToViewScreen(index, riyousyaId) {
    if (this.props.isUserMode) {
      switch (this.props.tab) {
        case "plan1":
          this.props.history.push(
            "/info-document-list-riyousya/" + riyousyaId + "/" + index
          );
          break;
        case "plan2":
          this.props.history.push(
            "/info-document-list-riyousya/" + riyousyaId + "/" + index
          );
          break;
        case "daily-plan":
          this.props.history.push(
            "/info-document-list-riyousya/" + riyousyaId + "/" + index
          );
          break;
        case "monitoring":
          this.props.history.push(
            "/info-document-list-riyousya/" + riyousyaId + "/" + index
          );
          break;
        case "tanto-kaigi":
          this.props.history.push(
            "/info-document-list-riyousya/" + riyousyaId + "/" + index
          );
          break;
        case "keika-kiroku":
          this.props.history.push(
            "/info-document-list-riyousya/" + riyousyaId + "/" + index
          );
          break;
        default:
          break;
      }
    }
    else {
      switch (this.props.tab) {
        case "plan1":
          this.props.history.push("/info-document-list/" + index);
          break;
        case "plan2":
          this.props.history.push("/info-document-list/" + index);
          break;
        case "daily-plan":
          this.props.history.push("/info-document-list/" + index);
          break;
        default:
          break;
      }
    }
  }

  goToEditScreen(riyousyaId, id) {
    this.props.getIdForcus(id);
    switch (this.props.tab) {
      case "plan1":
        this.props.history.push(
          "/input-kyotaku-plan1/" + riyousyaId + "/" + id
        );
        break;
      case "plan2":
        this.props.history.push(
          "/input-kyotaku-plan2/" + riyousyaId + "/" + id
        );
        break;
      case "daily-plan":
        if (id === 0) {
          this.props.history.push(
            "/input-sisetu-daily-plan/" + riyousyaId + "/" + "new"
          );
        }
        else {
          this.props.history.push(
            "/input-sisetu-daily-plan/" + riyousyaId + "/" + id
          );
        }
        break;
      case "monitoring":
        this.props.history.push("/input-monitoring/" + riyousyaId + "/" + id);
        break;
      case "tanto-kaigi":
        this.props.history.push("/input-tanto-kaigi/" + riyousyaId + "/" + id);
        break;
      case "keika-kiroku":
        this.props.history.push("/input-keika-kiroku/" + riyousyaId + "/" + id);
        break;
      default:
        break;
    }
  }

  renderRiyousyaPlan1Content() {
    return (
      <div
        id={this.state.expand ? "record-active-tab1" : "record"}
        className={
          this.state.expand
            ? "document-table-record-active"
            : "document-table-record"
        }
        onClick={this.handleOnclick}
      >
        <Row>
          <Col span={1}>
            {this.props.data.progressF === 1 ? (
              <span style={{ paddingRight: "5px" }}>★</span>
            ) : null}
          </Col>
          <Col
            lg={{ span: 5, offset: 1 }}
            md={{ span: 6 }}
            sm={{ span: 11 }}
            xs={{ span: 11 }}
          >
            <span style={{ fontWeight: "bold" }}>{"初回作成日："}</span>
            <span>{this.props.data.firstPlanDate}</span>
          </Col>
          <Col
            lg={{ span: 5, offset: 1 }}
            md={{ span: 5 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <div style={{ float: "right" }}>
              <span style={{ fontWeight: "bold" }}>{"作成日："}</span>
              <span>{this.props.data.planDate}</span>
            </div>
          </Col>
          <Col
            lg={{ span: 5, offset: 1 }}
            md={{ span: 5, offset: 1 }}
            sm={{ span: 12 }}
            xs={{ span: 9 }}
          >
            <span style={{ fontWeight: "bold" }}>{"同意日："}</span>
            <span>{this.props.data.plan1DouiDate}</span>
          </Col>
          <Col
            lg={{ span: 5, offset: 0 }}
            md={{ span: 5, offset: 1 }}
            sm={{ span: 12 }}
            xs={{ span: 14 }}
          >
            <div style={{ float: "right" }}>
              <span style={{ fontWeight: "bold" }}>{"作成者："}</span>
              <span>
                <strong>{this.props.data.tantoName}</strong>
              </span>
            </div>
          </Col>
        </Row>

        <Row type="flex" justify="space-between">
          <Col span={14}>
            {this.props.data
              ? this.props.data.serviceKikanMasterName || ""
              : ""}
          </Col>
          <Col span={10} style={{ textAlign: "right" }}>
            {this.props.data ? this.props.data.kubun || "" : ""}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {this.state.expand ? (
              <div style={{ marginTop: "25px" }}>
                <span style={{ fontWeight: "bold" }}>{"コメント： "}</span>
                <LineBreakText text={this.props.data.comment} />
              </div>
            ) : (
              <div>
                <span style={{ fontWeight: "bold" }}>{"コメント： "}</span>
                <span>
                  {this.props.data.comment &&
                    this.props.data.comment.trim().length > 60
                    ? this.props.data.comment.substring(0, 60) + "..."
                    : this.props.data.comment}
                </span>
              </div>
            )}
          </Col>
        </Row>
        <div>
          {this.state.expand ? (
            <div>
              <span>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    className={"btn-tab-delete btn-crud"}
                    onClick={() =>
                      this.props.showDeleteConfirm(this.props.data.id)
                    }
                  >
                    削除
                  </Button>
                ) : (
                  ""
                )}
              </span>
              <span className={"btn-right"}>
                <Button
                  icon={<LegacyIcon type={"read"} />}
                  size={w >= 768 ? "default" : "small"}
                  disabled={this.props.data.id === 0}
                  type={"primary"}
                  className={"btn-crud btn-tab-info"}
                  onClick={() =>
                    this.goToViewScreen(
                      this.props.index,
                      this.props.data.riyousyaId
                    )
                  }
                >
                  閲覧
                </Button>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    onClick={() =>
                      this.goToEditScreen(
                        this.props.data.riyousyaId,
                        this.props.data.id
                      )
                    }
                    className={"btn-tab-edit btn-crud"}
                  >
                    修正
                  </Button>
                ) : (
                  ""
                )}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderRiyousyaPlan2Content() {
    return (
      <div
        id={this.state.expand ? "record-active-tab2" : "record"}
        className={
          this.state.expand
            ? "document-table-record-active"
            : "document-table-record"
        }
        onClick={this.handleOnclick}
      >
        <Row>
          <Col md={1} xs={this.props.data.progressF === 1 ? 1 : 0}>
            {this.props.data.progressF === 1 ? (
              <span style={{ paddingRight: "5px" }}>★</span>
            ) : null}
          </Col>
          <Col
            lg={{ span: 7, offset: 1 }}
            md={{ span: 7, offset: 0 }}
            xs={{ span: 23, offset: 0 }}
          >
            <span style={{ fontWeight: "bold" }}>{"予定更新月："}</span>
            <span>{this.props.data.renewYears}</span>
          </Col>
          <Col
            lg={{ span: 7, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            xs={{ span: 24 }}
          >
            <span style={{ fontWeight: "bold" }}>{"作成日："}</span>
            <span>{this.props.data.planDate}</span>
          </Col>
          <Col
            lg={{ span: 8, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            xs={{ span: 24 }}
          >
            <span style={{ fontWeight: "bold" }}>{"同意日："}</span>
            <span>{this.props.data.plan2DouiDate}</span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {this.props.data
              ? this.props.data.serviceKikanMasterName || ""
              : ""}
          </Col>
        </Row>
        <Row style={{ marginTop: this.state.expand ? 25 : 0 }}>
          <Col md={22} xs={24}>
            {this.state.expand ? (
              <Fragment>
                <span style={{ fontWeight: "bold" }}>{"コメント： "}</span>
                <LineBreakText text={this.props.data.comment} />
              </Fragment>
            ) : (
              <Fragment>
                <span style={{ fontWeight: "bold" }}>{"コメント： "}</span>
                <span style={{ wordBreak: "break-word" }}>
                  {this.props.data.comment &&
                    this.props.data.comment.trim().length > 60
                    ? this.props.data.comment.substring(0, 60) + "..."
                    : this.props.data.comment}
                </span>
              </Fragment>
            )}
          </Col>
          <Col lg={2} md={1} />
        </Row>
        <div>
          {this.state.expand ? (
            <div>
              <span>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    onClick={() =>
                      this.props.showDeleteConfirm(this.props.data.id)
                    }
                    className={"btn-tab-delete btn-crud"}
                  >
                    削除
                  </Button>
                ) : (
                  ""
                )}
              </span>
              <span className={"btn-right"}>
                <Button
                  type={"primary"}
                  size={w >= 768 ? "default" : "small"}
                  style={
                    this.props.data.id === 0
                      ? {
                        borderColor: "gray",
                        backgroundColor: "buttonface",
                        color: "graytext",
                      }
                      : null
                  }
                  disabled={this.props.data.id === 0}
                  icon={<LegacyIcon type={"read"} />}
                  className={"btn-crud btn-tab-info"}
                  onClick={() =>
                    this.goToViewScreen(
                      this.props.index,
                      this.props.data.riyousyaId
                    )
                  }
                >
                  閲覧
                </Button>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    size={w >= 768 ? "default" : "small"}
                    disabled={this.props.data.id === 0}
                    className={"btn-tab-edit btn-crud"}
                    onClick={() =>
                      this.goToEditScreen(
                        this.props.data.riyousyaId,
                        this.props.data.id
                      )
                    }
                  >
                    修正
                  </Button>
                ) : (
                  ""
                )}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderRiyousyaDailyPlanContent() {
    return (
      <div
        id={this.state.expand ? "record-active-tab3" : "record"}
        className={
          this.state.expand
            ? "document-table-record-active"
            : "document-table-record"
        }
        onClick={this.handleOnclick}
      >
        <Row>
          <Col span={1}>
            {this.props.data.progressF === 1 ? (
              <span style={{ paddingRight: "5px" }}>★</span>
            ) : null}
          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 23 }}
          >
            <span>
              {this.props.data.id ? this.props.data.serviceKikanMasterName : ""}
            </span>
          </Col>
          <Col
            lg={{ span: 11 }}
            md={{ span: 11 }}
            sm={{ span: 11 }}
            xs={{ span: 24 }}
          >
            <span style={{ fontWeight: "bold" }}>{"入浴曜日："}</span>
            <span>{this.props.data.nyuyokuYoubi}</span>
          </Col>
        </Row>
        <div>
          {this.state.expand ? (
            <div>
              <span>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    onClick={() =>
                      this.props.showDeleteConfirm(this.props.data.id)
                    }
                    className={"btn-tab-delete btn-crud"}
                  >
                    削除
                  </Button>
                ) : (
                  ""
                )}
              </span>
              <span className={"btn-right"}>
                <Button
                  icon={<LegacyIcon type={"read"} />}
                  size={w >= 768 ? "default" : "small"}
                  style={
                    this.props.data.id === 0
                      ? {
                        borderColor: "gray",
                        backgroundColor: "buttonface",
                        color: "graytext",
                      }
                      : null
                  }
                  disabled={this.props.data.id === 0}
                  type={"primary"}
                  className={"btn-tab-info btn-crud"}
                  onClick={() =>
                    this.goToViewScreen(
                      this.props.index,
                      this.props.data.riyousyaId
                    )
                  }
                >
                  閲覧
                </Button>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    className={"btn-tab-edit btn-crud"}
                    onClick={() =>
                      this.goToEditScreen(
                        this.props.data.riyousyaId,
                        this.props.data.id
                      )
                    }
                  >
                    修正
                  </Button>
                ) : (
                  ""
                )}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  renderRiyousyaMonitoringContent() {
    return (
      <div
        id={this.state.expand ? "record-active-tab4" : "record"}
        className={
          this.state.expand
            ? "document-table-record-active"
            : "document-table-record"
        }
        onClick={this.handleOnclick}
      >
        <Row type="flex" justify="center">
          <Col lg={10} sm={11} xs={24}>
            <span style={{ fontWeight: "bold" }}>{"モニタリング実施日："}</span>
            <span>{this.props.data.monitoringDate}</span>
          </Col>
          <Col lg={10} sm={11} xs={24}>
            <div>
              <span style={{ fontWeight: "bold" }}>{"担当者："}</span>
              <span>{this.props.data.tantoMasterName}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {this.props.data
              ? this.props.data.serviceKikanMasterName || ""
              : ""}
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={24}>
            {this.state.expand ? (
              <div style={{ marginTop: "25px" }}>
                <span style={{ fontWeight: "bold" }}>{"総評: "}</span>
                <LineBreakText text={this.props.data.comment} />
              </div>
            ) : (
              <div>
                <span style={{ fontWeight: "bold" }}>{"総評: "}</span>
                <LineBreakText
                  text={
                    this.props.data.comment &&
                      this.props.data.comment.trim().length > 60
                      ? this.props.data.comment.substring(0, 60) + "..."
                      : this.props.data.comment
                  }
                />
              </div>
            )}
          </Col>
          {/* <Col sm={{ span: 6 }} xs={{ span: 15 }} className={"kubunRiyousya"}>
            <span style={{ float: "right" }}>
              <strong>{this.props.data.kubun}</strong>
            </span>
          </Col> */}
        </Row>
        <div>
          {this.state.expand ? (
            <div>
              <span>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    className={"btn-tab-delete btn-crud"}
                    onClick={() =>
                      this.props.showDeleteConfirm(this.props.data.id)
                    }
                  >
                    削除
                  </Button>
                ) : (
                  ""
                )}
              </span>
              <span className={"btn-right"}>
                <Button
                  icon={<LegacyIcon type={"read"} />}
                  size={w >= 768 ? "default" : "small"}
                  disabled={this.props.data.id === 0}
                  type={"primary"}
                  className={"btn-crud btn-tab-info"}
                  onClick={() =>
                    this.goToViewScreen(
                      this.props.index,
                      this.props.data.riyousyaId
                    )
                  }
                >
                  閲覧
                </Button>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    onClick={() =>
                      this.goToEditScreen(
                        this.props.data.riyousyaId,
                        this.props.data.id
                      )
                    }
                    className={"btn-tab-edit btn-crud"}
                  >
                    修正
                  </Button>
                ) : (
                  ""
                )}
              </span>
            </div>
          ) : null}
        </div>
      </div >
    );
  }
  renderRiyousyaTantoKaigiContent() {
    const attendance = parseAttendance(this.props.data.attendance);

    return (
      <div
        id={this.state.expand ? "record-active-tab5" : "record"}
        className={
          this.state.expand
            ? "document-table-record-active"
            : "document-table-record"
        }
        onClick={this.handleOnclick}
      >
        <Row type="flex" justify="center">
          <Col lg={10} sm={11} xs={24}>
            <span style={{ fontWeight: "bold" }}>{"会議開催日時："}</span>
            <span>{this.props.data.kaisaiDate} </span>
            <span>{this.props.data.kaisaiTime}</span>
          </Col>
          <Col lg={10} sm={11} xs={24}>
            <span style={{ fontWeight: "bold" }}>{"担当者："}</span>
            <span>{this.props.data.tantoMasterName}</span>
          </Col>
        </Row>

        <Row type="flex" justify="center">
          <Col lg={20} sm={22} xs={24}>
            <span style={{ fontWeight: "bold" }}>{"開催場所："}</span>
            <span>{this.props.data.kaisaiPlace}</span>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {this.props.data
              ? this.props.data.serviceKikanMasterName || ""
              : ""}
          </Col>
        </Row>

        <Row
          type="flex"
          justify="center"
          style={{ marginTop: this.state.expand ? 25 : 0 }}
        >
          <Col span={24} style={{ display: "flex" }}>
            <span style={{ fontWeight: "bold" }}>{"出席者："}</span>
            <div style={{ flex: 1 }}>
              <LineBreakText text={attendance} />
            </div>
          </Col>
        </Row>

        <div>
          {this.state.expand ? (
            <div>
              <span>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    className={"btn-tab-delete btn-crud"}
                    onClick={() =>
                      this.props.showDeleteConfirm(this.props.data.id)
                    }
                  >
                    削除
                  </Button>
                ) : (
                  ""
                )}
              </span>
              <span className={"btn-right"}>
                <Button
                  icon={<LegacyIcon type={"read"} />}
                  size={w >= 768 ? "default" : "small"}
                  disabled={this.props.data.id === 0}
                  type={"primary"}
                  className={"btn-crud btn-tab-info"}
                  onClick={() =>
                    this.goToViewScreen(
                      this.props.index,
                      this.props.data.riyousyaId
                    )
                  }
                >
                  閲覧
                </Button>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    onClick={() =>
                      this.goToEditScreen(
                        this.props.data.riyousyaId,
                        this.props.data.id
                      )
                    }
                    className={"btn-tab-edit btn-crud"}
                  >
                    修正
                  </Button>
                ) : (
                  ""
                )}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  renderRiyousyaKeikoKirokuContent() {
    return (
      <div
        id={this.state.expand ? "record-active-tab6" : "record"}
        className={
          this.state.expand
            ? "document-table-record-active"
            : "document-table-record"
        }
        onClick={this.handleOnclick}
      >
        <Row type="flex" justify="center">
          <Col lg={7} md={9} sm={22} xs={24}>
            <span style={{ fontWeight: "bold" }}>{"記録日時："}</span>
            <span>{this.props.data.kirokuDate} </span>
            <span>{this.props.data.kirokuTime}</span>
          </Col>
          <Col md={7} sm={22} xs={24}>
            <span style={{ fontWeight: "bold" }}>{"計画作成者："}</span>
            <span>{this.props.data.tantoMasterName}</span>
          </Col>
          <Col md={6} sm={22} xs={24}>
            <span style={{ fontWeight: "bold" }}>{"記録作成者："}</span>
            <span>{this.props.data.tantoMasterNameByKiroku}</span>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col lg={7} md={9} sm={22} xs={24}>
            <span style={{ fontWeight: "bold" }}>{"記録区分："}</span>
            <span>{this.props.data.kirokuKubun}</span>
          </Col>
          <Col md={7} sm={22} xs={24}>
            <span style={{ fontWeight: "bold" }}>{"相談内容："}</span>
            <span>{this.props.data.soudanNaiyou}</span>
          </Col>
          <Col md={6} sm={22} xs={24}></Col>
        </Row>
        <Row>
          <Col span={24}>
            {this.props.data
              ? this.props.data.serviceKikanMasterName || ""
              : ""}
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={24}>
            {
              this.state.expand ? (
                <div style={{ marginTop: "25px" }}>
                  <span style={{ fontWeight: "bold" }}>{"記録内容： "}</span>
                  <LineBreakText
                    text={this.props.data.naiyou ? this.props.data.naiyou : ""}
                  />
                </div>
              ) : null
              // (
              //     <div>
              //       <span style={{ fontWeight: "bold" }}>
              //         {"記録内容： "}
              //       </span>
              //       <span>
              //         {this.props.data.naiyou &&
              //           this.props.data.naiyou.trim().length > 60
              //           ? this.props.data.naiyou.substring(0, 60) + "..."
              //           : this.props.data.naiyou}
              //       </span>
              //     </div>
              //   )
            }
          </Col>
        </Row>
        <div>
          {this.state.expand ? (
            <div>
              <span>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    className={"btn-tab-delete btn-crud"}
                    onClick={() =>
                      this.props.showDeleteConfirm(this.props.data.id)
                    }
                  >
                    削除
                  </Button>
                ) : (
                  ""
                )}
              </span>
              <span className={"btn-right"}>
                <Button
                  icon={<LegacyIcon type={"read"} />}
                  size={w >= 768 ? "default" : "small"}
                  disabled={this.props.data.id === 0}
                  type={"primary"}
                  className={"btn-crud btn-tab-info"}
                  onClick={() =>
                    this.goToViewScreen(
                      this.props.index,
                      this.props.data.riyousyaId
                    )
                  }
                >
                  閲覧
                </Button>
                {!currentMenu.includes("business1") ? (
                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    size={w >= 768 ? "default" : "small"}
                    style={
                      this.props.data.id === 0
                        ? {
                          borderColor: "gray",
                          backgroundColor: "buttonface",
                          color: "graytext",
                        }
                        : null
                    }
                    disabled={this.props.data.id === 0}
                    onClick={() =>
                      this.goToEditScreen(
                        this.props.data.riyousyaId,
                        this.props.data.id
                      )
                    }
                    className={"btn-tab-edit btn-crud"}
                  >
                    修正
                  </Button>
                ) : (
                  ""
                )}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  renderListPlan1Content() {
    return (
      <div
        id={this.state.expand ? "record-active-tab1" : "record"}
        className={
          this.state.expand
            ? "document-table-record-active"
            : "document-table-record"
        }
        onClick={this.handleOnclick}
      >
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Row>
                    <Col
                      lg={{ span: 4 }}
                      md={{ span: 6 }}
                      sm={{ span: 8 }}
                      xs={{ span: 12 }}
                    >
                      <span className={"nameRiyousya"}>
                        {this.props.data.riyousyaName}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      md={{ span: 6 }}
                      sm={{ span: 8 }}
                      xs={{ span: 12 }}
                    >
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"初回作成日："}
                      </span>
                      <span>{this.props.data.firstPlanDate}</span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      md={{ span: 6 }}
                      sm={{ span: 8 }}
                      xs={{ span: 12 }}
                    >
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"作成日："}
                      </span>
                      <span>{this.props.data.planDate}</span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      md={{ span: 6 }}
                      sm={{ span: 8 }}
                      xs={{ span: 12 }}
                    >
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"同意日："}
                      </span>
                      <span>{this.props.data.plan1DouiDate}</span>
                    </Col>
                    <Col
                      lg={{ span: 6 }}
                      md={{ span: 6 }}
                      sm={{ span: 8 }}
                      xs={{ span: 12 }}
                    >
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"作成者："}
                      </span>
                      <span>
                        <strong>{this.props.data.tantoName}</strong>
                      </span>
                    </Col>
                    <Col>
                      {this.props.data.riyouHoldingF === 1 ? (
                        <span style={{ fontWeight: "bold", float: "right" }}>
                          {"利用中"}
                        </span>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={17}>
                      <span>
                        {this.props.data.id
                          ? this.props.data.serviceKikanMasterName
                          : ""}
                      </span>
                    </Col>
                    <Col span={7}>
                      <span
                        className={"titleRecord"}
                        style={{ fontSize: "12px", float: "right" }}
                      >
                        <strong>{this.props.data.kubun}</strong>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={1}>
                      {this.props.data.progressF === 1 ? (
                        <span style={{ paddingRight: "5px" }}>★</span>
                      ) : null}
                    </Col>
                    <Col
                      style={{ display: "flex", wordBreak: "break-word" }}
                      span={22}
                      offset={1}
                    >
                      {this.state.expand ? (
                        <div>
                          <span
                            className={"titleRecord"}
                            style={{ fontWeight: "bold" }}
                          >
                            {"コメント： "}
                          </span>
                          <span style={{ whiteSpace: "pre-line" }}>
                            {this.props.data.comment}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span
                            className={"titleRecord"}
                            style={{ fontWeight: "bold" }}
                          >
                            {"コメント： "}
                          </span>
                          <span>
                            {this.props.data.comment &&
                              this.props.data.comment.trim().length > 60
                              ? this.props.data.comment.substring(0, 60) + "..."
                              : this.props.data.comment}
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {this.state.expand ? (
                    <div>
                      <span>
                        <Button
                          icon={<LegacyIcon type={"delete"} />}
                          style={
                            this.props.data.id === 0
                              ? {
                                backgroundColor: "buttonface",
                                color: "graytext",
                              }
                              : null
                          }
                          disabled={this.props.data.id === 0}
                          onClick={() =>
                            this.props.showDeleteConfirm(this.props.data.id)
                          }
                          className={"btn-tab-delete btn-crud"}
                        >
                          削除
                        </Button>
                      </span>
                      <span className={"btn-right"}>
                        <Button
                          className={"btn-crud"}
                          type={"primary"}
                          onClick={() =>
                            this.goToRiyousyaDocList(this.props.data.riyousyaId)
                          }
                        >
                          利用者別
                        </Button>
                        <Button
                          className={"btn-tab-info btn-crud"}
                          style={
                            this.props.data.id === 0
                              ? {
                                borderColor: "gray",
                                backgroundColor: "buttonface",
                                color: "graytext",
                              }
                              : null
                          }
                          disabled={this.props.data.id === 0}
                          type={"primary"}
                          icon={<LegacyIcon type={"read"} />}
                          onClick={() => this.goToViewScreen(this.props.index)}
                        >
                          閲覧
                        </Button>
                        <Button
                          icon={<LegacyIcon type={"edit"} />}
                          style={
                            this.props.data.id === 0
                              ? {
                                borderColor: "gray",
                                backgroundColor: "buttonface",
                                color: "graytext",
                              }
                              : null
                          }
                          disabled={this.props.data.id === 0}
                          className={"btn-tab-edit btn-crud"}
                          onClick={() =>
                            this.goToEditScreen(
                              this.props.data.riyousyaId,
                              this.props.data.id
                            )
                          }
                        >
                          修正
                        </Button>
                        <Button
                          icon={<LegacyIcon type={"plus"} />}
                          className={"btn-tab-create btn-crud"}
                          onClick={() =>
                            this.props.handleAddOrCopy(
                              this.props.data.riyousyaId,
                              this.props.data.id
                            )
                          }
                        >
                          追加
                        </Button>
                      </span>
                    </div>
                  ) : null}
                </div>
              );
            }

            return (
              <div>
                <Row>
                  <Col span={1}>
                    {this.props.data.progressF === 1 ? (
                      <span style={{ paddingRight: "5px" }}>★</span>
                    ) : null}
                  </Col>
                  <Col sm={{ span: 19 }} xs={{ span: 19 }}>
                    <span className={"nameRiyousya"}>
                      {this.props.data.riyousyaName}
                    </span>
                  </Col>
                  <Col span={4}>
                    {this.props.data.riyouHoldingF === 1 ? (
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold", float: "right" }}
                      >
                        {"利用中"}
                      </span>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ span: 15 }} xs={{ span: 15 }}>
                    <span
                      className={"titleRecord"}
                      style={{ fontWeight: "bold" }}
                    >
                      {"作成者："}
                    </span>
                    <span>
                      <strong>{this.props.data.tantoName}</strong>
                    </span>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col sm={{ span: 8 }} xs={{ span: 9 }}>
                    <span
                      className={"titleRecord"}
                      style={{ fontWeight: "bold" }}
                    >
                      {"作成日："}
                    </span>
                    <span>{this.props.data.planDate}</span>
                  </Col>
                  <Col
                    style={{ float: "right" }}
                    sm={{ span: 8 }}
                    xs={{ span: 9 }}
                    offset={1}
                  >
                    <div style={{ float: "right" }}>
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"同意日："}
                      </span>
                      <span>{this.props.data.plan1DouiDate}</span>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col sm={{ span: 12 }} xs={{ span: 12 }}>
                    <span
                      className={"titleRecord"}
                      style={{ fontWeight: "bold" }}
                    >
                      {"初回作成日："}
                    </span>
                    <span>{this.props.data.firstPlanDate}</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ fontSize: "12px", float: "right" }}>
                      <strong>{this.props.data.kubun}</strong>
                    </span>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ display: "flex", wordBreak: "break-word" }}>
                    {this.state.expand ? (
                      <div>
                        <span
                          className={"titleRecord"}
                          style={{ fontWeight: "bold" }}
                        >
                          {"コメント： "}
                        </span>
                        <span style={{ whiteSpace: "pre-line" }}>
                          {this.props.data.comment}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span
                          className={"titleRecord"}
                          style={{ fontWeight: "bold" }}
                        >
                          {"コメント： "}
                        </span>
                        <span>
                          {this.props.data.comment &&
                            this.props.data.comment.trim().length > 60
                            ? this.props.data.comment.substring(0, 60) + "..."
                            : this.props.data.comment}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
                {this.state.expand ? (
                  <Row style={{ marginTop: "10px" }}>
                    <span>
                      <Button
                        icon={<LegacyIcon type={"delete"} />}
                        size={"small"}
                        style={
                          this.props.data.id === 0
                            ? {
                              backgroundColor: "buttonface",
                              color: "graytext",
                            }
                            : null
                        }
                        disabled={this.props.data.id === 0}
                        onClick={() =>
                          this.props.showDeleteConfirm(this.props.data.id)
                        }
                        className={"btn-tab-delete btn-crud"}
                      >
                        削除
                      </Button>
                    </span>
                    <span className={"btn-right"}>
                      <Button
                        className={"btn-crud"}
                        size={"small"}
                        type={"primary"}
                        onClick={() =>
                          this.goToRiyousyaDocList(this.props.data.riyousyaId)
                        }
                      >
                        利用者別
                      </Button>
                      <Button
                        className={"btn-tab-info btn-crud"}
                        size={"small"}
                        style={
                          this.props.data.id === 0
                            ? {
                              borderColor: "gray",
                              backgroundColor: "buttonface",
                              color: "graytext",
                            }
                            : null
                        }
                        disabled={this.props.data.id === 0}
                        type={"primary"}
                        icon={<LegacyIcon type={"read"} />}
                        onClick={() => this.goToViewScreen(this.props.index)}
                      >
                        閲覧
                      </Button>
                      <Button
                        icon={<LegacyIcon type={"edit"} />}
                        size={"small"}
                        style={
                          this.props.data.id === 0
                            ? {
                              borderColor: "gray",
                              backgroundColor: "buttonface",
                              color: "graytext",
                            }
                            : null
                        }
                        disabled={this.props.data.id === 0}
                        className={"btn-tab-edit btn-crud"}
                        onClick={() =>
                          this.goToEditScreen(
                            this.props.data.riyousyaId,
                            this.props.data.id
                          )
                        }
                      >
                        修正
                      </Button>
                      <Button
                        icon={<LegacyIcon type={"plus"} />}
                        size={"small"}
                        className={"btn-tab-create btn-crud"}
                        onClick={() =>
                          this.props.handleAddOrCopy(
                            this.props.data.riyousyaId,
                            this.props.data.id
                          )
                        }
                      >
                        追加
                      </Button>
                    </span>
                  </Row>
                ) : null}
              </div>
            );
          }}
        </MediaQuery>
      </div>
    );
  }

  renderListPlan2Content() {
    return (
      <div
        id={this.state.expand ? "record-active-tab2" : "record"}
        className={
          this.state.expand
            ? "document-table-record-active"
            : "document-table-record"
        }
        onClick={this.handleOnclick}
      >
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Row>
                    <Col
                      lg={{ span: 4 }}
                      md={{ span: 5 }}
                      sm={{ span: 8 }}
                      xs={{ span: 12 }}
                    >
                      <span className={"nameRiyousya"}>
                        {this.props.data.riyousyaName}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      md={{ span: 5 }}
                      sm={{ span: 8 }}
                      xs={{ span: 12 }}
                    >
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"予定更新月："}
                      </span>
                      <span>{this.props.data.renewYears}</span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      md={{ span: 5 }}
                      sm={{ span: 8 }}
                      xs={{ span: 12 }}
                    >
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"作成日："}
                      </span>
                      <span>{this.props.data.planDate}</span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      md={{ span: 5 }}
                      sm={{ span: 8 }}
                      xs={{ span: 12 }}
                    >
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"同意日："}
                      </span>
                      <span>{this.props.data.plan2DouiDate}</span>
                    </Col>
                    <Col>
                      {this.props.data.riyouHoldingF === 1 ? (
                        <span style={{ fontWeight: "bold", float: "right" }}>
                          {"利用中"}
                        </span>
                      ) : null}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={1}>
                      {this.props.data.progressF === 1 ? (
                        <span style={{ paddingRight: "5px" }}>★</span>
                      ) : null}
                    </Col>
                    <Col
                      style={{ display: "flex", wordBreak: "break-word" }}
                      span={22}
                      offset={1}
                    >
                      {this.state.expand ? (
                        <div>
                          <span
                            className={"titleRecord"}
                            style={{ fontWeight: "bold" }}
                          >
                            {"コメント： "}
                          </span>
                          <span style={{ whiteSpace: "pre-line" }}>
                            {this.props.data.comment}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span
                            className={"titleRecord"}
                            style={{ fontWeight: "bold" }}
                          >
                            {"コメント： "}
                          </span>
                          <span>
                            {this.props.data.comment &&
                              this.props.data.comment.trim().length > 60
                              ? this.props.data.comment.substring(0, 60) + "..."
                              : this.props.data.comment}
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {this.state.expand ? (
                    <Row>
                      <span>
                        <Button
                          icon={<LegacyIcon type={"delete"} />}
                          style={
                            this.props.data.id === 0
                              ? {
                                backgroundColor: "buttonface",
                                color: "graytext",
                              }
                              : null
                          }
                          disabled={this.props.data.id === 0}
                          onClick={() =>
                            this.props.showDeleteConfirm(this.props.data.id)
                          }
                          className={"btn-tab-delete btn-crud"}
                        >
                          削除
                        </Button>
                      </span>
                      <span className={"btn-right"}>
                        <Button
                          type={"primary"}
                          className={"btn-crud"}
                          onClick={() =>
                            this.goToRiyousyaDocList(this.props.data.riyousyaId)
                          }
                        >
                          利用者別
                        </Button>
                        <Button
                          type={"primary"}
                          style={
                            this.props.data.id === 0
                              ? {
                                borderColor: "gray",
                                backgroundColor: "buttonface",
                                color: "graytext",
                              }
                              : null
                          }
                          disabled={this.props.data.id === 0}
                          icon={<LegacyIcon type={"read"} />}
                          className={"btn-tab-info btn-crud"}
                          onClick={() => this.goToViewScreen(this.props.index)}
                        >
                          閲覧
                        </Button>
                        <Button
                          icon={<LegacyIcon type={"edit"} />}
                          style={
                            this.props.data.id === 0
                              ? {
                                borderColor: "gray",
                                backgroundColor: "buttonface",
                                color: "graytext",
                              }
                              : null
                          }
                          disabled={this.props.data.id === 0}
                          className={"btn-tab-edit btn-crud"}
                          onClick={() =>
                            this.goToEditScreen(
                              this.props.data.riyousyaId,
                              this.props.data.id
                            )
                          }
                        >
                          修正
                        </Button>
                        <Button
                          icon={<LegacyIcon type={"plus"} />}
                          className={"btn-tab-create btn-crud"}
                          onClick={() =>
                            this.props.handleAddOrCopy(
                              this.props.data.riyousyaId,
                              this.props.data.id
                            )
                          }
                        >
                          追加
                        </Button>
                      </span>
                    </Row>
                  ) : null}
                </div>
              );
            }
            return (
              <div>
                <Row style={{ marginTop: "5px" }}>
                  <Col span={1}>
                    {this.props.data.progressF === 1 ? (
                      <span style={{ paddingRight: "5px" }}>★</span>
                    ) : null}
                  </Col>
                  <Col sm={{ span: 17 }} xs={{ span: 17 }}>
                    <span className={"nameRiyousya"}>
                      {this.props.data.riyousyaName}
                    </span>
                  </Col>
                  <Col style={{ float: "right" }} span={4}>
                    {this.props.data.riyouHoldingF === 1 ? (
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold", float: "right" }}
                      >
                        {"利用中"}
                      </span>
                    ) : null}
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col sm={{ span: 12 }} xs={{ span: 12 }}>
                    <span
                      className={"titleRecord"}
                      style={{ fontWeight: "bold" }}
                    >
                      {"作成日："}
                    </span>
                    <span>{this.props.data.planDate}</span>
                  </Col>
                  <Col sm={{ span: 12 }} xs={{ span: 12 }}>
                    <div style={{ float: "right" }}>
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"予定更新月："}
                      </span>
                      <span>{this.props.data.renewYears}</span>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col sm={{ span: 12 }} xs={{ span: 12 }}>
                    <span
                      className={"titleRecord"}
                      style={{ fontWeight: "bold" }}
                    >
                      {"同意日："}
                    </span>
                    <span>{this.props.data.plan2DouiDate}</span>
                  </Col>
                </Row>

                <Row style={{ marginTop: "10px" }}>
                  <Col style={{ display: "flex", wordBreak: "break-word" }}>
                    {this.state.expand ? (
                      <div>
                        <span
                          className={"titleRecord"}
                          style={{ fontWeight: "bold" }}
                        >
                          {"コメント： "}
                        </span>
                        <span style={{ whiteSpace: "pre-line" }}>
                          {this.props.data.comment}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span
                          className={"titleRecord"}
                          style={{ fontWeight: "bold" }}
                        >
                          {"コメント： "}
                        </span>
                        <span>
                          {this.props.data.comment &&
                            this.props.data.comment.trim().length > 60
                            ? this.props.data.comment.substring(0, 60) + "..."
                            : this.props.data.comment}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
                {this.state.expand ? (
                  <Row>
                    <span>
                      <Button
                        icon={<LegacyIcon type={"delete"} />}
                        size="small"
                        style={
                          this.props.data.id === 0
                            ? {
                              backgroundColor: "buttonface",
                              color: "graytext",
                            }
                            : null
                        }
                        disabled={this.props.data.id === 0}
                        onClick={() =>
                          this.props.showDeleteConfirm(this.props.data.id)
                        }
                        className={"btn-tab-delete btn-crud"}
                      >
                        削除
                      </Button>
                    </span>
                    <span className={"btn-right"}>
                      <Button
                        type={"primary"}
                        size="small"
                        className={"btn-crud"}
                        onClick={() =>
                          this.goToRiyousyaDocList(this.props.data.riyousyaId)
                        }
                      >
                        利用者別
                      </Button>
                      <Button
                        type={"primary"}
                        size="small"
                        style={
                          this.props.data.id === 0
                            ? {
                              borderColor: "gray",
                              backgroundColor: "buttonface",
                              color: "graytext",
                            }
                            : null
                        }
                        disabled={this.props.data.id === 0}
                        icon={<LegacyIcon type={"read"} />}
                        className={"btn-tab-info btn-crud"}
                        onClick={() => this.goToViewScreen(this.props.index)}
                      >
                        閲覧
                      </Button>
                      <Button
                        icon={<LegacyIcon type={"edit"} />}
                        size="small"
                        style={
                          this.props.data.id === 0
                            ? {
                              borderColor: "gray",
                              backgroundColor: "buttonface",
                              color: "graytext",
                            }
                            : null
                        }
                        disabled={this.props.data.id === 0}
                        className={"btn-tab-edit btn-crud"}
                        onClick={() =>
                          this.goToEditScreen(
                            this.props.data.riyousyaId,
                            this.props.data.id
                          )
                        }
                      >
                        修正
                      </Button>
                      <Button
                        icon={<LegacyIcon type={"plus"} />}
                        size="small"
                        className={"btn-tab-create btn-crud"}
                        onClick={() =>
                          this.props.handleAddOrCopy(
                            this.props.data.riyousyaId,
                            this.props.data.id
                          )
                        }
                      >
                        追加
                      </Button>
                    </span>
                  </Row>
                ) : null}
              </div>
            );
          }}
        </MediaQuery>
      </div>
    );
  }

  renderListDailyPlanContent() {
    return (
      <div
        id={this.state.expand ? "record-active-tab3" : "record"}
        className={
          this.state.expand
            ? "document-table-record-active"
            : "document-table-record"
        }
        onClick={this.handleOnclick}
      >
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Row>
                    <Col
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      sm={{ span: 4 }}
                      xs={{ span: 18 }}
                    >
                      <span className={"nameRiyousya"}>
                        {this.props.data.riyousyaName}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      xs={{ span: 18 }}
                    >
                      <span>
                        {this.props.data.id
                          ? this.props.data.serviceKikanMasterName
                          : ""}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      xs={{ span: 18 }}
                    >
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold" }}
                      >
                        {"入浴曜日："}
                      </span>
                      <span>{this.props.data.nyuyokuYoubi}</span>
                    </Col>
                    <Col
                      style={{ position: "absolute", right: "0" }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      sm={{ span: 4 }}
                      xs={{ span: 4 }}
                    >
                      {this.props.data.riyouHoldingF === 1 ? (
                        <span
                          className={"titleRecord"}
                          style={{ fontWeight: "bold", float: "right" }}
                        >
                          {"利用中"}
                        </span>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={{ span: 1 }} xs={{ span: 12 }}>
                      {this.props.data.progressF === 1 ? (
                        <span style={{ paddingRight: "5px" }}>★</span>
                      ) : null}
                    </Col>
                  </Row>

                  {this.state.expand ? (
                    <Row style={{ marginTop: "10px" }}>
                      <span>
                        <Button
                          icon={<LegacyIcon type={"delete"} />}
                          style={
                            this.props.data.id === 0
                              ? {
                                borderColor: "gray",
                                backgroundColor: "buttonface",
                                color: "graytext",
                              }
                              : null
                          }
                          disabled={this.props.data.id === 0}
                          onClick={() =>
                            this.props.showDeleteConfirm(this.props.data.id)
                          }
                          className={"btn-tab-delete btn-crud"}
                        >
                          削除
                        </Button>
                      </span>
                      <span className={"btn-right"}>
                        <Button
                          type={"primary"}
                          className={"btn-crud"}
                          onClick={() =>
                            this.goToRiyousyaDocList(this.props.data.riyousyaId)
                          }
                        >
                          利用者別
                        </Button>
                        <Button
                          type={"primary"}
                          style={
                            this.props.data.id === 0
                              ? {
                                borderColor: "gray",
                                backgroundColor: "buttonface",
                                color: "graytext",
                              }
                              : null
                          }
                          disabled={this.props.data.id === 0}
                          icon={<LegacyIcon type={"read"} />}
                          className={"btn-tab-info btn-crud"}
                          onClick={() => this.goToViewScreen(this.props.index)}
                        >
                          閲覧
                        </Button>
                        <Button
                          icon={<LegacyIcon type={"edit"} />}
                          className={"btn-tab-edit btn-crud"}
                          onClick={() =>
                            this.goToEditScreen(
                              this.props.data.riyousyaId,
                              this.props.data.id
                            )
                          }
                        >
                          修正
                        </Button>
                      </span>
                    </Row>
                  ) : null}
                </div>
              );
            }
            return (
              <div>
                <Row style={{ marginTop: "5px" }}>
                  <Col span={1}>
                    {this.props.data.progressF === 1 ? (
                      <span style={{ paddingRight: "5px" }}>★</span>
                    ) : null}
                  </Col>
                  <Col sm={{ span: 17 }} xs={{ span: 17 }}>
                    <span className={"nameRiyousya"}>
                      {this.props.data.riyousyaName}
                    </span>
                  </Col>
                  <Col sm={{ span: 4 }} xs={{ span: 4 }} offset={2}>
                    {this.props.data.riyouHoldingF === 1 ? (
                      <span
                        className={"titleRecord"}
                        style={{ fontWeight: "bold", float: "right" }}
                      >
                        {"利用中"}
                      </span>
                    ) : null}
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col sm={{ span: 14 }} xs={{ span: 14 }}>
                    <span
                      className={"titleRecord"}
                      style={{ fontWeight: "bold" }}
                    >
                      {"入浴曜日："}
                    </span>
                    <span>{this.props.data.nyuyokuYoubi}</span>
                  </Col>
                  <Col sm={{ span: 9 }} xs={{ span: 9 }} offset={1}>
                    <span style={{ float: "right" }}>
                      {this.props.data.id
                        ? this.props.data.serviceKikanMasterName
                        : ""}
                    </span>
                  </Col>
                </Row>
                {this.state.expand ? (
                  <Row style={{ marginTop: "10px" }}>
                    <span>
                      <Button
                        icon={<LegacyIcon type={"delete"} />}
                        size={"small"}
                        style={
                          this.props.data.id === 0
                            ? {
                              borderColor: "gray",
                              backgroundColor: "buttonface",
                              color: "graytext",
                            }
                            : null
                        }
                        disabled={this.props.data.id === 0}
                        onClick={() =>
                          this.props.showDeleteConfirm(this.props.data.id)
                        }
                        className={"btn-tab-delete btn-crud"}
                      >
                        削除
                      </Button>
                    </span>
                    <span className={"btn-right"}>
                      <Button
                        type={"primary"}
                        size={"small"}
                        className={"btn-crud"}
                        onClick={() =>
                          this.goToRiyousyaDocList(this.props.data.riyousyaId)
                        }
                      >
                        利用者別
                      </Button>
                      <Button
                        type={"primary"}
                        style={
                          this.props.data.id === 0
                            ? {
                              borderColor: "gray",
                              backgroundColor: "buttonface",
                              color: "graytext",
                            }
                            : null
                        }
                        disabled={this.props.data.id === 0}
                        icon={<LegacyIcon type={"read"} />}
                        size={"small"}
                        className={"btn-tab-info btn-crud"}
                        onClick={() => this.goToViewScreen(this.props.index)}
                      >
                        閲覧
                      </Button>
                      <Button
                        icon={<LegacyIcon type={"edit"} />}
                        size={"small"}
                        className={"btn-tab-edit btn-crud"}
                        onClick={() =>
                          this.goToEditScreen(
                            this.props.data.riyousyaId,
                            this.props.data.id
                          )
                        }
                      >
                        修正
                      </Button>
                    </span>
                  </Row>
                ) : null}
              </div>
            );
          }}
        </MediaQuery>
      </div>
    );
  }

  render() {
    if (this.props.isUserMode) {
      switch (this.props.tab) {
        case "plan1":
          return this.renderRiyousyaPlan1Content();
        case "plan2":
          return this.renderRiyousyaPlan2Content();
        case "daily-plan":
          return this.renderRiyousyaDailyPlanContent();
        case "monitoring":
          return this.renderRiyousyaMonitoringContent();
        case "tanto-kaigi":
          return this.renderRiyousyaTantoKaigiContent();
        case "keika-kiroku":
          return this.renderRiyousyaKeikoKirokuContent();
        default:
          return null;
      }
    }
    else {
      switch (this.props.tab) {
        case "plan1":
          return this.renderListPlan1Content();
        case "plan2":
          return this.renderListPlan2Content();
        case "daily-plan":
          return this.renderListDailyPlanContent();
        default:
          return null;
      }
    }
  }
}
const mapStateToProps = (state) => {
  return {
    idForcus: state.documentList.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIdForcus: (id) => dispatch(getIdForcus(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RowContentRiyousya);
