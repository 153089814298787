import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";
// import { ConstSet } from '../common/configs/constset';

let DrugMasterList = {
  loadList(bunrui, name) {
    return axios(apiUrls.INIT + apiUrls.GET_DRUG_MASTER_LIST, {
      method: "POST",
      data: {
        bunrui: bunrui,
        name: name,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/login");
          }

          return err;
        }
      });
  },
};

export { DrugMasterList };
