import { Button, Col, Input, Popover, Row, Select } from "antd";
import Table from "antd/es/table";
import React from "react";
import { connect } from "react-redux";
import { getDataKiKanMaster } from "../../../../actions/tantoKaigiActions/actionCreators";
import {
  tantoMasterListKikanCreator
} from "../../../../actions/tantoMasterListActions/actionCreators";
import { yougoMasterRoomNaiyouCreator } from "../../../../actions/yougoMasterNaiyouActions/actionCreators";
// import { ConstSet } from "../../../common/configs/constset";
// import { guid } from "../../../common/timePicker/formatTimePicker";
const { Search } = Input;
const { Option } = Select;
const columns = [
  { title: "整理番号", dataIndex: "code", key: "code", width: "30%" },
  { title: "氏名", dataIndex: "name", key: "name", width: "30%" },
  { title: "資格", dataIndex: "sikaku", key: "sikaku", width: "30%" },
];
let name = "";

class StaffForm extends React.Component {
  constructor(props) {
    super(props);

    const shokuin = props.value || {};
    this.state = {
      shokuin_num: shokuin.shokuin_num,
      shokuin: shokuin.shokuin,
      visible: false,
      tantoMasters: null,
      tantoSelected: null,
      rowActive: null,
      loading: false,
      selectedBunrui: "",
      searchText: "",
      page: 1,
    };
  }
  componentDidMount() {
    const menu = JSON.parse(localStorage.getItem("menu"));
    this.props.yougoMasterRoomNaiyouCreator("担当者分類");
    this.props.getDataKiKanMaster(menu.si, (kikanData) => {
      name = kikanData;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tantoMasters != this.props.tantoMasters) {
      this.setState({
        tantoMasters: nextProps.tantoMasters,
      });
    }
  }
  handleVisibleChange = (visible) => {
    const { selectedBunrui, page, searchText } = this.state;
    if (!this.state.visible) {
      this.props.getTantoMasterList(selectedBunrui, searchText, page);
    }
    this.setState({ visible, rowActive: null });
  };

  handleFilterTanto(value) {
    const { selectedBunrui, page } = this.state;
    this.props.getTantoMasterList(selectedBunrui, value, page);

    this.setState({
      searchText: value,
      rowActive: null,
    });
  }
  handleSelectTanto = (record, rowIndex) => {
    const newAttendance = {
      id: record.id || "",
      affiliation: name || "",
      type: record.sikaku || "",
      name: record.name || "",
    };
    this.setState({
      // tantoMasters: this.state.tantoMasters,
      rowActive: rowIndex,
    });
    // this.triggerChange({ });
    this.props.onSelect(newAttendance);
  };

  handleChangeBunrui(value) {
    this.setState({
      selectedBunrui: value,
    });
  }
  handlePageChange(page) {
    const { selectedBunrui, searchText } = this.state;
    this.setState({
      page,
      rowActive: null,
    });
    this.props.getTantoMasterList(selectedBunrui, searchText, page);
  }
  render() {
    const { tantoMasters, rowActive, selectedBunrui } =
      this.state;
    const { buttonName, yougoMasterRoomNaiyou } = this.props;
    const paging = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: this.props.meta ? this.props.meta.total : 0,
      current: this.props.meta ? this.props.meta.page : 1,
      pageSize: this.props.meta ? this.props.meta.pageSize : 6,
    };
    const data =
      tantoMasters && Array.isArray(tantoMasters)
        ? tantoMasters.map((tanto) => {
          return {
            id: tanto.id,
            code: tanto.code,
            name: tanto.name,
            sikaku: tanto.sikaku,
          };
        })
        : [];

    const content = (
      <div className="table-staff-form">
        <Row className="pad-bt-8">
          <Col span={8}>
            <Select
              defaultValue={selectedBunrui}
              style={{ width: "100%" }}
              onChange={(value) => this.handleChangeBunrui(value)}
            >
              <Option value="" key={0}>
                全表示
              </Option>
              {Array.isArray(yougoMasterRoomNaiyou)
                ? yougoMasterRoomNaiyou.map((item) => {
                  return (
                    <Option key={item.key} value={item.naiyou}>
                      {item.naiyou}
                    </Option>
                  );
                })
                : []}
            </Select>
          </Col>
          <Col span={16}>
            <Search
              enterButton={"検索"}
              onSearch={(value) => this.handleFilterTanto(value)}
            />
          </Col>
        </Row>
        <Table
          loading={this.props.loading}
          className={"tableStaff"}
          bordered
          columns={columns}
          dataSource={data}
          pagination={paging}
          size={"small"}
          rowKey={"id"}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                this.handleSelectTanto(record, rowIndex);
              }, // click row
            };
          }}
          rowClassName={(record, index) =>
            rowActive != null && index === rowActive
              ? "pljp-table-row-hover"
              : "pointer-row"
          }
        />
      </div>
    );
    return (
      <div className={"staffContainer"} style={{ display: "inline-block" }}>
        <Row>
          <Popover
            overlayClassName="staffContainer-popover"
            content={content}
            title="記録担当者"
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
          >
            <Button type={"primary"} size={this.props.size}>
              {buttonName}
            </Button>
          </Popover>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tantoMasters: state.tantoMasterList.data,
    meta: state.tantoMasterList.meta,
    yougoMasterRoomNaiyou: state.yougoMasterNaiyou.yougoMasterRoomNaiyou,
    loading: state.tantoMasterList.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTantoMasterList: (bunrui, searchName, page) =>
      dispatch(tantoMasterListKikanCreator(bunrui, searchName, page)),
    yougoMasterRoomNaiyouCreator: (listname) =>
      dispatch(yougoMasterRoomNaiyouCreator(listname)),
    getDataKiKanMaster: (id, callback) =>
      dispatch(getDataKiKanMaster(id, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffForm);
