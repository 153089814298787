import React from "react";
import { Button, Modal } from "antd";
// import { set } from "lodash";

function AddCopyLifeDialog(props) {
  return (
    (<Modal
      className={"modal-add-coppy"}
      open={props.visible}
      title={"追加選択ダイアログ"}
      onCancel={props.onHideModal}
      footer={[
        <Button key="add" type={"primary"} onClick={props.onRedirect}>
          追加
        </Button>,
        <Button
          key="copy"
          type={"primary"}
          onClick={() => props.onShowModalLastQuote()}
        >
          複製追加
        </Button>,
        <Button key="canceladd" onClick={props.onHideModal}>
          キャンセル
        </Button>,
      ]}
    ></Modal>)
  );
}

export { AddCopyLifeDialog };
