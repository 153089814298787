import { connect } from "react-redux";
import Home from "../home/home";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);
