import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, notification } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { TableListKanriActions } from "../../actions/kanriActions/actions";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import TableResizeAble from "./UI/TableResizable";

const filterInfo = [
  { text: "MDSHC関連", value: "MDSHC関連" },
  { text: "包括支援関連", value: "包括支援関連" },
  { text: "MDS関連", value: "MDS関連" },
  { text: "給与関連", value: "給与関連" },
  { text: "利用者関連", value: "利用者関連" },
  { text: "マスタ関連", value: "マスタ関連" },
  { text: "施設関連", value: "施設関連" },
  { text: "特定施設関連", value: "特定施設関連" },
  { text: "請求関連", value: "請求関連" },
  { text: "レセプト関連", value: "レセプト関連" },
  { text: "オプション関連", value: "オプション関連" },
  { text: "訪問看護関連", value: "訪問看護関連" },
  { text: "包括アセス関連", value: "包括アセス関連" },
  { text: "自立支援レセプト関連", value: "自立支援レセプト関連" },
  { text: "居宅関連", value: "居宅関連" },
  { text: "介護予防関連", value: "介護予防関連" },
  { text: "自立支援関連", value: "自立支援関連" },
  { text: "定期巡回関連", value: "定期巡回関連" },
  { text: "ヘルパー関連", value: "ヘルパー関連" },
  { text: "予防委託関連", value: "予防委託関連" },
  { text: "小規模関連", value: "小規模関連" },
  { text: "ショート関連", value: "ショート関連" },
  { text: "グループホーム関連", value: "グループホーム関連" },
  { text: "訪問リハ関連", value: "訪問リハ関連" },
  { text: "グループ関連", value: "グループ関連" },
  { text: "デイサービス関連", value: "デイサービス関連" },
  { text: "介護調査関連", value: "介護調査関連" },
  { text: "記録関連", value: "記録関連" },
  { text: "月次関連", value: "月次関連" },
  { text: "リハビリ関連", value: "リハビリ関連" }
];

class KanriTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredInfo: null,
      sortedInfo: null,
    };
  }

  componentDidMount() {
    this.props.getTableList();
  }

  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "name",
      },
    });
  };

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`検索 ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          検索
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          クリア
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleTableChild = (record) => {
    // console.log(record);

    this.props.history.push(`/kanri/table/detail?xmldoc=${record.xmldoc}&name=${record.name}&table=${record.テーブル}`);
  }

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    const { tableList } = this.props;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
        ellipsis: true,
        ...this.getColumnSearchProps("name"),
        width: 250,
      },
      {
        title: "テーブル",
        dataIndex: "テーブル",
        key: "テーブル",
        sorter: (a, b) => a.テーブル - b.テーブル,
        sortOrder: sortedInfo.columnKey === "テーブル" && sortedInfo.order,
        ellipsis: true,
        width: 250,
      },
      {
        title: "xmldoc",
        dataIndex: "xmldoc",
        key: "xmldoc",
        sorter: (a, b) => a.xmldoc - b.xmldoc,
        sortOrder: sortedInfo.columnKey === "xmldoc" && sortedInfo.order,
        ellipsis: true,
        width: 250,
      },
      {
        title: "info",
        dataIndex: "info",
        key: "info",
        filters: filterInfo,
        filteredValue: filteredInfo.info || null,
        onFilter: (value, record) => record.info.includes(value),
        sorter: (a, b) => a.info.length - b.info.length,
        sortOrder: sortedInfo.columnKey === "info" && sortedInfo.order,
        ellipsis: true,
        width: 250,
      },
      {
        title: "Action",
        dataIndex: "Action",
        render: (text, record) =>
          <Popconfirm title="詳細にする?" onConfirm={() => this.handleTableChild(record)}>
            <a>詳細</a>
          </Popconfirm>
      },
    ];

    return (
      <KanriLayoutContainer router="table" breadcrumbLink={"/kanri/table"} breadcrumbText={"パレット管理"}>
        <div>
          <div className="table-operations">
            {/* <Button onClick={this.setAgeSort}>並び替え</Button>
            <Button onClick={this.clearFilters}>クリア</Button> */}
            <Button onClick={this.clearAll}>クリア</Button>
          </div>
          <div id="table-resizeable-2">
            <TableResizeAble
              columns={columns}
              data={tableList}
              onChange={this.handleChange}
              widthx={1400}
            />
          </div>
        </div>
      </KanriLayoutContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tableList: state.kanriTable.tableList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTableList: (info) => dispatch(TableListKanriActions.getTableList(info)),
    // keepValueSearch: (data) => dispatch(LifeActions.keepValueSearch(data)),
  };
};

const propTypes = {
  //
};

const defaultProps = {
  minRows: 0,
  maxRows: Infinity,
};

KanriTable.propTypes = propTypes;
KanriTable.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(KanriTable);
