import {
  LOAD_DUTY_REPORT_REQUEST,
  LOAD_DUTY_REPORT_SUCCESS,
  CREATE_DUTY_REPORT_SUCCESS,
  CREATE_DUTY_REPORT_ERROR,
  EDIT_DUTY_REPORT_SUCCESS,
  EDIT_DUTY_REPORT_ERROR,
  STATISTICAL_OUT_IN,
  STATISTICAL_OUT_IN_BYKIND,
  PEOPLE_OUT_IN_BYKIND,
  RESET_STATUS,
  SAVE_ERROR,
} from "../../actions/dutyReportActions/actionName";
import { convertMessageByCode } from "../../common/function_common/functionCommon";
const INITIAL_STATE = {
  messages: [],
  notifyDisplay: 0,
};
export default function dutyReportReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        notifyDisplay: 0,
        notifyContent: {},
      };
    case SAVE_ERROR:
      return {
        ...state,
        notifyDisplay: 3,
        notifyContent: null,
      };
    case LOAD_DUTY_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_DUTY_REPORT_SUCCESS:
      return {
        ...state,
        dutyReportList: action.payload,
        loading: false,
        notifyDisplay: 0,
        notifyContent: null,
      };
    case CREATE_DUTY_REPORT_SUCCESS:
      return {
        ...state,
        dataCreated: action.payload,
        notifyDisplay: 1,
        notifyContent: { type: "success", message: "保存しました" },
      };
    case CREATE_DUTY_REPORT_ERROR: {
      const messageInsert = {
        type: "error",
        message: convertMessageByCode(action.error.code),
      };
      return {
        ...state,
        notifyDisplay: 2,
        notifyContent: messageInsert,
      };
    }
    case EDIT_DUTY_REPORT_SUCCESS:
      return {
        ...state,
        dataEdited: action.payload,
        notifyDisplay: 1,
        notifyContent: { type: "success", message: "保存しました" },
      };
    case EDIT_DUTY_REPORT_ERROR: {
      const messageEdit = {
        type: "error",
        message: convertMessageByCode(action.error.code),
      };
      return {
        ...state,
        notifyDisplay: 2,
        notifyContent: messageEdit,
      };
    }

    case STATISTICAL_OUT_IN:
      return {
        ...state,
        statisticalOutIn: action.payload,
      };
    case STATISTICAL_OUT_IN_BYKIND:
      return {
        ...state,
        statisticalOutInByKind: action.payload,
      };
    case PEOPLE_OUT_IN_BYKIND:
      return {
        ...state,
        peopleOutInListByKind: action.payload,
      };
    default:
      return state;
  }
}
