import React from "react";

import {
  CaretLeftOutlined,
  CaretRightOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Checkbox, Button } from "antd";

const formItemCusStyle = {
  marginBottom: "0",
};

class SelectForm extends React.Component {
  preToEnd = () => {
    this.props.preToEnd();
  };

  pre = () => {
    this.props.pre();
  };

  next = () => {
    this.props.next();
  };

  nextToEnd = () => {
    this.props.nextToEnd();
  };

  recordMark = () => {
    this.props.recordMark();
  };

  render() {
    const { recordId } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };

    return (
      <Form
        {...formItemLayout}
        onSubmit={this.handleSubmit}
        labelAlign="left"
        name="horizontal_login"
        layout="inline"
      >
        <Form.Item style={formItemCusStyle}>
          <Button type="primary" onClick={this.preToEnd}>
            <StepBackwardOutlined />
          </Button>
        </Form.Item>
        <Form.Item style={formItemCusStyle}>
          <Button type="primary" onClick={this.pre}>
            <CaretLeftOutlined />
          </Button>
        </Form.Item>
        {!Number.isNaN(recordId) ? (
          <Form.Item label="選択" style={formItemCusStyle}>
            {getFieldDecorator("isCheck", {
              rules: [{ required: false }],
            })(<Checkbox.Group options={[{ value: "isCheck" }]} />)}
          </Form.Item>
        ) : (
          ""
        )}
        <Form.Item style={formItemCusStyle}>
          <Button type="primary" onClick={this.next}>
            <CaretRightOutlined />
          </Button>
        </Form.Item>
        <Form.Item style={formItemCusStyle}>
          <Button type="primary" onClick={this.nextToEnd}>
            <StepForwardOutlined />
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedSelectForm = Form.create({
  name: "kanri-select-form",
  onValuesChange(props, changedValues, allValues) {
    props.recordMark(changedValues.isCheck.length);
  },
  mapPropsToFields(props) {
    return {
      isCheck: Form.createFormField({
        value: props.value && props.value === 1 ? ["isCheck"] : [],
      }),
    };
  },
})(SelectForm);

export default WrappedSelectForm;
