import React, { Component } from "react";
import { EnterOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row, Select, InputNumber } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;

class InputNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      visibleNoKeyboard: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  handleSelectChange = (value) => {
    if (value == "消去") {
      value = "";
    } else if (this.state.value) {
      value = this.state.value + value;
    }
    this.setState({
      value: value,
    });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  handleChange = (value) => {
    this.setState({
      value: value,
    });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  onVisibleChangePopover = () => {
    this.setState({
      visibleNoKeyboard: !this.state.visibleNoKeyboard,
    });
  };

  render() {
    const { value, visibleNoKeyboard } = this.state;
    const { data, style, containerId, text, text2 } = this.props;

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleSelectChange("7")}>7</Button>
            <Button onClick={() => this.handleSelectChange("8")}>8</Button>
            <Button onClick={() => this.handleSelectChange("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleSelectChange("4")}>4</Button>
            <Button onClick={() => this.handleSelectChange("5")}>5</Button>
            <Button onClick={() => this.handleSelectChange("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleSelectChange("1")}>1</Button>
            <Button onClick={() => this.handleSelectChange("2")}>2</Button>
            <Button onClick={() => this.handleSelectChange("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleSelectChange("0")}>0</Button>
            <Button onClick={() => this.handleSelectChange(".")}>.</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleSelectChange("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
          <Row>
            <Button
              onClick={this.onVisibleChangePopover}
              style={{ padding: "0px", height: 150 }}
            >
              <EnterOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    );

    return (
      <div style={style}>
        <span style={{ fontWeight: "bold", marginRight: 5 }}>{text}</span>
        <InputNumber
          value={value}
          min={0}
          max={999}
          onChange={this.handleChange}
        />
        <Popover
          visible={visibleNoKeyboard}
          placement="bottomRight"
          content={numberKB}
          trigger="click"
          onVisibleChange={this.onVisibleChangePopover}
        >
          <Button type="primary">入力</Button>
        </Popover>
        <span style={{ fontWeight: "bold", marginLeft: 5 }}>{text2}</span>
      </div>
    );
  }
}

InputNo.propTypes = {
  // value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.number,
  style: PropTypes.object,
  containerId: PropTypes.string,
  onChange: PropTypes.func,
};

export default InputNo;
