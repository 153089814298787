import { Icon as LegacyIcon } from "@ant-design/compatible";
import { UserOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Select, Spin, Table } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import FuriganaKeyboard from "../../../common/component/FuriganaKeyBoard";
import { apiUrls } from "../../../common/configs/common-url";
import { ConstSet } from "../../../common/configs/constset";

const { Option } = Select;

export default class SubMenuInc extends React.Component {
  constructor(props) {
    super(props);

    const preValue = {
      key: this.props.riyousayInfo
        ? this.props.riyousayInfo.riyousya.id
        : undefined,
      label: this.props.riyousayInfo
        ? this.props.riyousayInfo.riyousya.name
        : undefined,
    };

    this.state = {
      startDate: dayjs(localStorage.getItem("nyuDate"), "YYYY-MM-DD"),

      data: [],
      value: preValue,
      preValue: null,
      fetching: false,
      isVisibleSearchFurigana: false,
      furigana: "",
      isRioyusyaListDisplay: false,
      isLoadingUser: false,
      title: "ふりがな検索",
      rowActive: null,
    };

    // bind place
    this.displayTableType = this.displayTableType.bind(this);

    this.handlePreviewDate = this.handlePreviewDate.bind(this);
    this.handleNextDate = this.handleNextDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  displayTableType = () => {
    this.props.displayTableType(!this.state.tbDisType);
  };

  handleChange = (date) => {
    this.props.handleDateChange(
      dayjs(date.toDate()).format(ConstSet.DATE_FORMAT)
    );
  };

  handlePreviewDate = () => {
    this.props.handlePreviewDate();
  };

  handleNextDate = () => {
    this.props.handleNextDate();
  };

  fetchUser = (value) => {
    this.lastFetchId += 1;
    // const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });

    axios(apiUrls.INIT + apiUrls.RIYOUSYA_SEARCH + "?textSearch=" + value, {
      method: "GET",
    })
      .then((res) => {
        // if (fetchId !== this.lastFetchId) {
        //     // for fetch callback order
        //     return;
        // }

        this.setState({
          data: res.data,
          fetching: false,
          isLoadingUser: false,
        });
      })
      .catch(() => {
        setTimeout(() => {
          this.setState({
            fetching: false,
            isLoadingUser: false,
          });
        }, 1000);
      });
  };

  handleChangeSelectUser = (value) => {
    if (value.key > 0 || value.key !== "0") {
      this.setState({
        value,
        data: [],
        fetching: false,
      });

      this.props.reportRiyousya(value.key);
    }
  };

  isSearchFurigana = () => {
    this.setState({
      isVisibleSearchFurigana: true,
      isRioyusyaListDisplay: false,
    });
  };

  handleCancelSearch = () => {
    this.setState({
      isVisibleSearchFurigana: false,
      furigana: "",
    });
  };

  handleCancelSearchFurigana = () => {
    const { isRioyusyaListDisplay } = this.state;
    if (isRioyusyaListDisplay) {
      this.setState({
        isVisibleSearchFurigana: true,
        isRioyusyaListDisplay: false,
      });
    }
    else {
      this.setState({
        isVisibleSearchFurigana: false,
        furigana: "",
      });
    }
  };

  handleOkSearchFurigana = () => {
    this.fetchUser(this.state.furigana);
    this.setState({
      isRioyusyaListDisplay: true,
      isLoadingUser: true,
    });
  };

  onChangeFurigana(value) {
    if (this.state.isRioyusyaListDisplay) {
      this.setState({
        furigana: value.target.value,
      });
    }
    else {
      this.setState({
        furigana: value,
      });
    }
  }

  /**
   *
   * @param {*} furi
   */
  getHandleFurigana = (furi) => {
    /**
     * @TODO Clear State ???
     */

    this.setState({
      furigana: furi,
    });
  };

  handleSelectUser = (record, rowIndex) => {
    this.setState({
      preValue: { key: record.id, label: record.name },
      rowActive: rowIndex,
    });
  };

  handleSelectUserOk = () => {
    const preValue = this.state.preValue;

    if (preValue && preValue.key) {
      this.props.reportRiyousya(preValue.key.toString());

      this.setState({
        value: preValue,
        preValue: null,
        isVisibleSearchFurigana: false,
        furigana: "",
        isRioyusyaListDisplay: false,
        rowActive: null,
      });
    }
    else {
      this.setState({
        isVisibleSearchFurigana: false,
        furigana: "",
        isRioyusyaListDisplay: false,
        rowActive: null,
      });
    }
  };

  render() {
    const { fetching, data, value } = this.state;

    const columns = [
      {
        dataIndex: "name",
        key: "col1",
        width: "30%",
      },
      {
        dataIndex: "furigana",
        key: "col2",
        width: "40%",
      },
      {
        dataIndex: "birthday",
        key: "col3",
        width: "30%",
      },
    ];

    return (
      (<div className="rpu-submenuinc">
        <Button type="primary">
          <Link to={"/riyousya-detail/" + value.key}>利用者詳細</Link>
        </Button>
        <Select
          showSearch
          labelInValue
          value={value}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={this.fetchUser}
          onChange={this.handleChangeSelectUser}
          style={{ width: "180px", top: "1px" }}
          className="kmu-menu-detail"
        >
          {data.map((d) => (
            <Option key={d.id}>{d.name}</Option>
          ))}
        </Select>
        <Button type="primary" icon={<UserOutlined />} onClick={this.isSearchFurigana}>
          検索
        </Button>
        {/* Search Riyousya By Furigana Keybroad */}
        <Modal
          open={this.state.isVisibleSearchFurigana}
          title={this.state.title}
          onOk={this.handleOkSearchFurigana}
          onCancel={this.handleCancelSearch}
          footer={[
            <Button key="back" onClick={this.handleCancelSearchFurigana}>
              キャンセル
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={
                this.state.isRioyusyaListDisplay
                  ? this.handleSelectUserOk
                  : this.handleOkSearchFurigana
              }
              disabled={
                this.state.isRioyusyaListDisplay &&
                !this.state.data.some(
                  (item, index) =>
                    this.state.rowActive != null &&
                    index === this.state.rowActive
                )
              }
            >
              OK
            </Button>,
          ]}
          width={560}
        >
          {this.state.isRioyusyaListDisplay ? (
            <div>
              <Row>
                <Col span={12}>
                  <Input
                    value={this.state.furigana}
                    onChange={(e) => this.onChangeFurigana(e)}
                  />
                </Col>
                <Col span={6} offset={6}>
                  <Button
                    icon={<LegacyIcon type={"search"} />}
                    style={{ float: "right" }}
                    onClick={() => {
                      this.setState({
                        data: [],
                        isLoadingUser: true,
                        rowActive: null,
                      });
                      this.fetchUser(this.state.furigana);
                    }}
                  >
                    {"検索"}
                  </Button>
                </Col>
              </Row>
              <Row style={{ padding: "16px 5px 0px 5px" }}>
                <Table
                  size={"small"}
                  showHeader={false}
                  header={null}
                  footer={null}
                  columns={columns}
                  dataSource={this.state.data}
                  loading={this.state.isLoadingUser}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.handleSelectUser(record, rowIndex);
                      }, // click row
                    };
                  }}
                  rowClassName={(record, index) =>
                    this.state.rowActive != null &&
                      index === this.state.rowActive
                      ? "pljp-table-row-hover"
                      : ""
                  }
                />
              </Row>
            </div>
          ) : (
            <FuriganaKeyboard
              furigana={(furi) => this.getHandleFurigana(furi)}
              resetFurigana={this.onChangeFurigana}
              furiganaState={this.state.furigana}
            />
          )}
        </Modal>
      </div>)
    );
  }
}
