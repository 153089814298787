import { connect } from "react-redux";

import UserManagement from "./UserManagement";

import { sendGroupCreator } from "../../actions/sendGroupActions/actionCreators";

import {
  userListCreator,
  filterUserGroupCreator,
  getUserInformationCreator,
  updateListCreator,
} from "../../actions/userListActions/actionCreators";

import {
  updateUserCreator,
  deleteUserCreator,
  createUserCreator,
} from "../../actions/kanriActions/actionCreators";

import { tantoMasterListCreator } from "../../actions/tantoMasterListActions/actionCreators";

import Edit from "./Edit";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    //
    loading: state.kanri.loading,
    notifyDisplay: state.kanri.notifyDisplay,
    notifyContent: state.kanri.notifyContent,

    users: state.userList.users,
    userDetail: state.userList.userDetail,
    groups: state.sendGroup.groups,
    // tantomaster reducers
    tantoMaster: state.tantoMasterList.tantoMaster,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    userList: (isGetAll) => dispatch(userListCreator(isGetAll)),
    getUserInformation: (userId) => dispatch(getUserInformationCreator(userId)),
    updateUser: (data) => dispatch(updateUserCreator(data)),
    createUser: (data) => dispatch(createUserCreator(data)),
    sendGroup: () => dispatch(sendGroupCreator()),
    filterUserGroup: (value) => dispatch(filterUserGroupCreator(value)),

    deleteUser: (ids) => dispatch(deleteUserCreator(ids)),
    updateUsers: (id) => dispatch(updateListCreator(id)),
    /**
     * tanto-master actions
     *
     */
    tantoMasterList: () => dispatch(tantoMasterListCreator()),
  };
};

export const UserManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagement);
export const InputUserManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);
