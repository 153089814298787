/* eslint-disable brace-style */
/* eslint-disable react/display-name */
import React, { useState } from "react";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";

import { Icon as LegacyIcon } from "@ant-design/compatible";

import { Button, Divider, Input, Modal, Popconfirm, Table } from "antd";
import { connect } from "react-redux";

import { inquiryRequestActionTypes } from "../../redux/action_types";

// action
import { InquiryRequest } from "../../redux/actions";

const EditableCell = ({
  editable,
  value,
  onChange,
  valueEdit,
  Expansion,
  sort,
}) => (
  <div>
    {editable ? (
      <Input
        style={{ margin: "-5px 0" }}
        value={valueEdit}
        onChange={(e) => onChange(e.target.value)}
      />
    ) : Expansion && !sort ? (
      <div
        style={{
          maxWidth: "240px",
          whiteSpace: "normal",
          overflowWrap: "normal",
        }}
      >
        {value}
      </div>
    ) : (
      <div
        style={{
          maxWidth: "240px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </div>
    )}
  </div>
);

function NaiyouDialog(props) {
  const [result, setResult] = useState(props.values);
  const [textInput, setTextInput] = useState("");
  const [newText, setNewText] = useState("");
  const [key, setkey] = useState(null);
  const [sort, setSort] = useState(false);
  const [sortTable, setSortTable] = useState([]);
  const [Expansion, setExpansion] = useState(false);

  const select = (naiyou) => {
    let a = result;
    if (result && result.length === 0) {
      a = naiyou;
    } else {
      a = "" + naiyou;
    }
    setExpansion(false);

    props.onSubmit(a);
  };

  const onCancel = () => {
    props.onCancel();
    setResult("");
    setSort(false);
    setSortTable([]);
    setExpansion(false);
  };

  const renderColumns = (text, record) => {
    return (
      <EditableCell
        sort={sort}
        Expansion={Expansion}
        editable={record.editable}
        value={text}
        valueEdit={textInput}
        onChange={(value) => handleChange(value, record.key)}
      />
    );
  };

  const handleChange = (value, key) => {
    setkey(key);
    setTextInput(value);
  };

  const edit = (key, naiyou) => {
    setTextInput(naiyou);
    setkey(key);
    props.editYougo(key);
  };

  const save = () => {
    if (textInput) {
      const { yougoNaiyou, listName } = props;
      let naiyou = "";
      for (let i = 0; i < yougoNaiyou.length; i++) {
        if (i === key) {
          if (!naiyou) {
            naiyou = textInput;
          } else naiyou = naiyou + ";" + textInput;
        } else if (!naiyou) {
          naiyou = yougoNaiyou[i].naiyou;
        } else {
          naiyou = naiyou + ";" + yougoNaiyou[i].naiyou;
        }
      }
      props.saveYougoMaster(listName, naiyou);
    }
  };

  const deletenaiyou = (key) => {
    const { yougoNaiyou, listName } = props;
    let preNaiyou = "";
    for (let i = 0; i < yougoNaiyou.length; i++) {
      if (i !== key) {
        if (!preNaiyou) {
          preNaiyou = yougoNaiyou[i].naiyou;
        } else {
          preNaiyou = preNaiyou + ";" + yougoNaiyou[i].naiyou;
        }
      }
    }
    props.saveYougoMaster(listName, preNaiyou);
  };

  const cancel = (key) => {
    props.cancelEditYougo(key);
  };

  const handleOnchangeNew = (e) => {
    setNewText(e.target.value);
  };

  const add = () => {
    if (newText) {
      const { yougoNaiyou, listName } = props;
      let preNaiyou = newText;
      if (yougoNaiyou === undefined) {
        preNaiyou = "";
      }
      for (let i = 0; i < yougoNaiyou.length; i++) {
        preNaiyou = preNaiyou + ";" + yougoNaiyou[i].naiyou;
      }
      props.saveYougoMaster(listName, preNaiyou);
      setNewText("");
    }
  };

  const sortOn = () => {
    const { yougoNaiyou } = props;
    setSort(true);
    setSortTable(yougoNaiyou);
  };
  const sortOff = () => {
    setSort(false);
    setSortTable([]);
  };
  const sortSave = () => {
    const { listName } = props;
    const naiyou = [];
    if (sortTable) {
      sortTable.map((x) => naiyou.push(x.naiyou));
    }
    props.saveYougoMaster(listName, naiyou.join(";"));

    setSort(false);
    setSortTable([]);
  };
  const sortTop = (sortTable, record, index) => {
    if (index != 0) {
      const newIndex = 0;
      const copyArray = sortTable.slice();
      const element = copyArray[index];
      copyArray.splice(index, 1);
      copyArray.splice(newIndex, 0, element);
      setSortTable(copyArray);
    }
  };
  const sortUp = (sortTable, record, index) => {
    if (index != 0) {
      // 1
      const newIndex = index - 1;
      const copyArray = sortTable.slice();
      const element = copyArray[index];
      copyArray.splice(index, 1);
      copyArray.splice(newIndex, 0, element);
      setSortTable(copyArray);
    }
  };
  const sortDown = (sortTable, record, index) => {
    if (sortTable.length - 1 != index) {
      const newIndex = index + 1;
      const copyArray = sortTable.slice();
      const element = copyArray[index];
      copyArray.splice(index, 1);
      copyArray.splice(newIndex, 0, element);
      setSortTable(copyArray);
    }
  };
  const sortBottom = (sortTable, record, index) => {
    if (sortTable.length - 1 != index) {
      const newIndex = sortTable.length - 1;
      const copyArray = sortTable.slice();
      const element = copyArray[index];
      copyArray.splice(index, 1);
      copyArray.splice(newIndex, 0, element);
      setSortTable(copyArray);
    }
  };

  const handleExpandTab = () => {
    // パネル全体

    if (Expansion) {
      setExpansion(false);
    } else {
      setExpansion(true);
    }
  };

  const title = (
    <div>
      {sort == false ? (
        <span>
          <Input
            onChange={(e) => handleOnchangeNew(e)}
            value={newText}
            style={{ width: "250px", marginRight: "16px" }}
          />
          <a onClick={() => add()}>追加</a>
          <a
            key="sort"
            // type={"primary"}
            onClick={() => sortOn()}
            style={{
              marginRight: 8,
              marginTop: 10,
              marginLeft: 15,
              color: "black",
            }}
          >
            並び替え
          </a>
        </span>
      ) : null}
      {sort == true ? (
        <span>
          <Button
            type={"primary"}
            onClick={() => sortSave()}
            style={{ marginRight: 8, marginTop: 0, marginLeft: 15 }}
          >
            並び替え保存
          </Button>
          <a
            onClick={() => sortOff()}
            style={{ paddingTop: "14px", color: "black" }}
          >
            戻る
          </a>
        </span>
      ) : null}
    </div>
  );

  const columns = [
    {
      title: "naiyou",
      dataIndex: "naiyou",
      width: 250,
      render: (text, record) => renderColumns(text, record, "naiyou"),
    },
    {
      title: "行動",
      dataIndex: "operation",
      width: 150,
      render: (_text, record, index) => {
        const { editable } = record;
        return (
          (<div className="editable-row-operations">
            {sort === true ? ( // <Icon type="caret-up" rotate={isActive ? 180 : 0} />style={{ fontSize: '16px', color: '#08c' }}
              (<span>
                <a onClick={() => sortTop(sortTable, record, index)} style={{}}>
                  <StepBackwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => sortUp(sortTable, record, index)} style={{}}>
                  <CaretUpOutlined style={{ fontSize: "18px" }} />
                </a>
                <Divider type="vertical" />
                <a
                  onClick={() => sortDown(sortTable, record, index)}
                  style={{}}
                >
                  <CaretDownOutlined style={{ fontSize: "18px" }} />
                </a>
                <Divider type="vertical" />
                <a
                  onClick={() => sortBottom(sortTable, record, index)}
                  style={{}}
                >
                  <StepForwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                </a>
              </span>)
            ) : editable ? (
              <span>
                <a onClick={() => save()}>保存</a>
                <Divider type="vertical" />
                <a onClick={() => cancel(record.key)}>閉じる</a>
              </span>
            ) : (
              <span>
                <a onClick={() => select(record.naiyou)}>選択</a>
                <Divider type="vertical" />
                <a onClick={() => edit(record.key, record.naiyou)}>修正</a>{" "}
                <Divider type="vertical" />
                <Popconfirm
                  title="削除してもいいですか？"
                  onConfirm={() => deletenaiyou(record.key)}
                >
                  <a>削除</a>
                </Popconfirm>
              </span>
            )}
          </div>)
        );
      },
    },
  ];

  const content = (
    <div>
      {sort == false ? (
        <div>
          <Table
            bordered
            columns={columns}
            dataSource={props.yougoNaiyou}
            pagination={{ pageSize: 50 }} // 50
            scroll={{ y: 240 }}
            showHeader={false}
            size={"small"}
            // rowKey={(record, index) => index}
            style={{ marginTop: "8px" }}
          />
          {
            props.yougoNaiyou.length > 0 && (
              <Button
                type="primary"
                icon={<LegacyIcon type={Expansion ? "minus" : "plus"} />}
                // className="modal-expansion"
                style={{ marginTop: '10px' }}
                onClick={() => handleExpandTab()}
              >
                {Expansion ? "格納" : "展開"}
              </Button>
            )
          }
        </div>
      ) : null}
      {sort == true ? (
        <Table
          bordered
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={sortTable}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 240 }}
          showHeader={false}
          size={"small"}
        // rowKey={(record, index) => index}
        />
      ) : null}
    </div>
  );

  return (
    (<Modal
      destroyOnClose={true}
      open={props.visible}
      onCancel={() => onCancel()}
      footer={null}
      className={props.className ? props.className : "modal-naiyou"}
    >
      {title}
      {content}
    </Modal>)
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveYougoMaster: (listName, naiyou) =>
      dispatch(InquiryRequest.saveYougoMaster(listName, naiyou)),
    editYougo: (index) =>
      dispatch({
        type: inquiryRequestActionTypes.HOME_YOUGO_EDIT_STATE,
        index,
      }),
    cancelEditYougo: (index) =>
      dispatch({
        type: inquiryRequestActionTypes.HOME_YOUGO_CANCEL_STATE,
        index,
      }),
  };
};

const _NaiyouDialog = connect(null, mapDispatchToProps)(NaiyouDialog);

export { _NaiyouDialog as NaiyouDialog };
