/* eslint-disable no-nested-ternary */
import {
  REPORT_WEEKLY_ADD_DEL_ERROR,
  REPORT_WEEKLY_ADD_DEL_REQUEST,
  REPORT_WEEKLY_ADD_DEL_SUCCESS,
  REPORT_WEEKLY_ADD_ERROR,
  REPORT_WEEKLY_ADD_LOADDATA_ERROR,
  REPORT_WEEKLY_ADD_LOADDATA_REQUEST,
  REPORT_WEEKLY_ADD_LOADDATA_SUCCESS,
  REPORT_WEEKLY_ADD_REQUEST,
  REPORT_WEEKLY_ADD_SUCCESS,
  REPORT_WEEKLY_ADD_YOUTEI_REQUEST,
  REPORT_WEEKLY_ADD_YOUTEI_SUCCESS,
} from "../../actions/reportWeeklyAddActions/actionName";
import { isEmpty } from "../../common/function_common/functionCommon";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {};

export default function reportWeeklyAddReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REPORT_WEEKLY_ADD_LOADDATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REPORT_WEEKLY_ADD_LOADDATA_SUCCESS: {
      let preLoadKaigo = [];
      if (
        action.payload.kaigo &&
        action.payload.kaigo[0] &&
        action.payload.kaigo[0].kaigoId
      ) {
        if (action.payload.youtei) {
          preLoadKaigo = mergePlanKaigo(
            action.payload.kaigo,
            action.payload.youtei
          );
        }
        else {
          preLoadKaigo = action.payload.kaigo;
        }
      }
      else if (action.payload.youtei) {
        preLoadKaigo = mergePlanKaigo([], action.payload.youtei);
      }

      let hainyou = {};
      let haiben = {};
      if (action.payload.haisetu && action.payload.haisetu[0]) {
        if (
          action.payload.haisetu[0].haisetuKubun &&
          action.payload.haisetu[0].haisetuKubun === "排尿"
        ) {
          hainyou = action.payload.haisetu[0];
        }

        if (
          action.payload.haisetu[0].haisetuKubun &&
          action.payload.haisetu[0].haisetuKubun === "排便"
        ) {
          haiben = action.payload.haisetu[0];
        }
      }

      if (action.payload.haisetu && action.payload.haisetu[1]) {
        if (
          action.payload.haisetu[1].haisetuKubun &&
          action.payload.haisetu[1].haisetuKubun === "排尿"
        ) {
          hainyou = action.payload.haisetu[1];
        }

        if (
          action.payload.haisetu[1].haisetuKubun &&
          action.payload.haisetu[1].haisetuKubun === "排便"
        ) {
          haiben = action.payload.haisetu[1];
        }
      }

      let meal = {};
      if (
        !(
          action.payload.meal &&
          action.payload.meal[0] &&
          action.payload.meal[0].mealId
        )
      ) {
        meal.kubunMeal = planMeal(action.payload.mealYotei);
      }
      else {
        meal = action.payload.meal[0];
      }

      return {
        ...state,
        vital:
          action.payload.vital &&
            action.payload.vital[0] &&
            action.payload.vital[0].vitalId
            ? action.payload.vital[0]
            : {},
        meal,
        suibun:
          action.payload.suibun &&
            action.payload.suibun[0] &&
            action.payload.suibun[0].suibunId
            ? action.payload.suibun
            : [],
        // haisetu: preHaisetu,
        hainyou,
        haiben,
        nyuyoku:
          action.payload.nyuyoku &&
            action.payload.nyuyoku[0] &&
            action.payload.nyuyoku[0].nyuyokuId
            ? action.payload.nyuyoku[0]
            : {},
        fukuyaku:
          action.payload.fukuyaku &&
            action.payload.fukuyaku[0] &&
            action.payload.fukuyaku[0].fukuyakuId
            ? action.payload.fukuyaku
            : [],
        kaigo: preLoadKaigo,
        tokki:
          action.payload.tokki &&
            action.payload.tokki[0] &&
            action.payload.tokki[0].tokkiId
            ? action.payload.tokki[0]
            : {},
        gazou:
          action.payload.gazou &&
            action.payload.gazou[0] &&
            action.payload.gazou[0].fileManagementId
            ? action.payload.gazou[0]
            : {},
        youtei: action.payload.youtei ? action.payload.youtei : [],
        mealYotei: action.payload.mealYotei[0]
          ? action.payload.mealYotei[0]
          : {},
        loading: false,
        loadingSave: false,
      };
    }
    case REPORT_WEEKLY_ADD_LOADDATA_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "エラー",
      };

    case REPORT_WEEKLY_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        loadingSave: true,
      };

    case REPORT_WEEKLY_ADD_SUCCESS: {
      /**
       * @TODO handle error from request. error resuld data handle like respone
       *
       * 例：actionPayload.haisetu =
       * {data: {…}, status: 400, statusText: "Bad Request", headers: {…}, config: {…}, …}
       * config: {adapter: ƒ, transformRequest: {…}, transformResponse: {…}, timeout: 0, xsrfCookieName: "XSRF-TOKEN", …}
       * data:
       * error:
       * code: "PARAMS_MISSING"
       * message: "missing params"
       * __proto__: Object
       * __proto__: Object
       * headers: {date: "Fri, 02 Aug 2019 07:02:54 GMT", server: "Play! Framework;1.4.5;dev", x-powered-by: "Express", vary: "Accept-Encoding", content-type: "application/json; charset=utf-8", …}
       * request: XMLHttpRequest {onreadystatechange: ƒ, readyState: 4, timeout: 0, withCredentials: false, upload: XMLHttpRequestUpload, …}
       * status: 400
       * statusText: "Bad Request"
       *
       *
       * IN RESULT: Without report_ID it mean "ERROR"
       *
       */
      const newKaigo = [];
      if (isArray(action.payload.kaigo)) {
        state.kaigo.forEach((objk) => {
          let tmpObj = objk;
          action.payload.kaigo.forEach((objKr) => {
            if (
              objk.kaigoId &&
              objKr.kaigoId &&
              objk.kaigoId.toString() === objKr.kaigoId.toString()
            ) {
              tmpObj = objKr;
            }
          });

          newKaigo.push(tmpObj);
        });
      }

      return {
        ...state,
        loading: false,
        loadingSave: false,
        vital: isArray(action.payload.vital)
          ? action.payload.vital[0]
          : typeof action.payload.vital === "object" &&
            !isEmpty(action.payload.vital) &&
            action.payload.vital.vitalId
            ? action.payload.vital
            : state.vital,
        meal: isArray(action.payload.meal)
          ? action.payload.meal[0]
          : typeof action.payload.meal === "object" &&
            !isEmpty(action.payload.meal) &&
            action.payload.meal.mealId
            ? action.payload.meal
            : state.meal,
        suibun: isArray(action.payload.suibun)
          ? action.payload.suibun[0]
          : typeof action.payload.suibun === "object" &&
            !isEmpty(action.payload.suibun) &&
            action.payload.suibun.suibunId
            ? action.payload.suibun
            : state.suibun,
        hainyou: isArray(action.payload.hainyou)
          ? action.payload.hainyou[0]
          : typeof action.payload.hainyou === "object" &&
            !isEmpty(action.payload.hainyou) &&
            action.payload.hainyou.haisetuId
            ? action.payload.hainyou
            : state.hainyou,
        haiben: isArray(action.payload.haiben)
          ? action.payload.haiben[0]
          : typeof action.payload.haiben === "object" &&
            !isEmpty(action.payload.haiben) &&
            action.payload.haiben.haisetuId
            ? action.payload.haiben
            : state.haiben,
        nyuyoku: isArray(action.payload.nyuyoku)
          ? action.payload.nyuyoku[0]
          : typeof action.payload.nyuyoku === "object" &&
            !isEmpty(action.payload.nyuyoku) &&
            action.payload.nyuyoku.nyuyokuId
            ? action.payload.nyuyoku
            : state.nyuyoku,
        fukuyaku: isArray(action.payload.fukuyaku)
          ? action.payload.fukuyaku[0]
          : typeof action.payload.fukuyaku === "object" &&
            !isEmpty(action.payload.fukuyaku) &&
            action.payload.fukuyaku.fukuyakuId
            ? action.payload.fukuyaku
            : state.fukuyaku,
        kaigo: isArray(action.payload.kaigo) ? newKaigo : state.kaigo,
        tokki: isArray(action.payload.tokki)
          ? action.payload.tokki[0]
          : typeof action.payload.tokki === "object" &&
            !isEmpty(action.payload.tokki) &&
            action.payload.tokki.tokkiId
            ? action.payload.tokki
            : state.tokki,
        gazou: isArray(action.payload.gazou)
          ? action.payload.gazou[0]
          : typeof action.payload.gazou === "object" &&
            !isEmpty(action.payload.gazou) &&
            action.payload.gazou.fileManagementId
            ? action.payload.gazou
            : state.gazou,
      };
    }
    case REPORT_WEEKLY_ADD_ERROR:
      return {
        ...state,
        loading: false,
        loadingSave: false,
        error: action.error,
      };

    case REPORT_WEEKLY_ADD_DEL_REQUEST:
      return {
        ...state,
        loading: true,
        loadingSave: true,
      };

    case REPORT_WEEKLY_ADD_DEL_SUCCESS:
      switch (action.mode) {
        case 1: { // vital
          let preDelVital = state.vital;
          if (action.payload && action.payload.vitalId) {
            preDelVital = {};
          }

          return {
            ...state,
            vital: preDelVital,
            loading: false,
            loadingSave: false,
          };
        }
        case 2: {
          let preDelMeal = state.meal;
          if (action.payload && action.payload.mealId) {
            preDelMeal = {};
          }

          return {
            ...state,
            meal: preDelMeal,
            loading: false,
            loadingSave: false,
          };
        }
        case 3: {
          let preDelSuibun = state.suibun;
          if (action.payload && action.payload.suibunId) {
            preDelSuibun = preDelSuibun.filter((item) => {
              return item.suibunId !== action.payload.suibunId;
            });
          }

          return {
            ...state,
            suibun: preDelSuibun,
            loading: false,
            loadingSave: false,
          };
        }
        case 41: {
          let preDelHainyou = state.hainyou;
          if (action.payload && action.payload.haisetuId) {
            preDelHainyou = {};
          }

          return {
            ...state,
            hainyou: preDelHainyou,
            loading: false,
            loadingSave: false,
          };
        }
        case 42: {
          let preDelHaiben = state.haiben;
          if (action.payload && action.payload.haisetuId) {
            preDelHaiben = {};
          }

          return {
            ...state,
            haiben: preDelHaiben,
            loading: false,
            loadingSave: false,
          };
        }
        case 5: {
          let preDelNyuyoku = state.nyuyoku;
          if (action.payload && action.payload.nyuyokuId) {
            preDelNyuyoku = {};
          }

          return {
            ...state,
            nyuyoku: preDelNyuyoku,
            loading: false,
            loadingSave: false,
          };
        }
        case 6: {
          let preDelFukuyaku = state.fukuyaku;
          if (action.payload && action.payload.fukuyakuId) {
            preDelFukuyaku = preDelFukuyaku.filter((item) => {
              return item.fukuyakuId !== action.payload.fukuyakuId;
            });
          }

          return {
            ...state,
            fukuyaku: preDelFukuyaku,
            loading: false,
            loadingSave: false,
          };
        }
        case 7: {
          let preDelKaigo = state.kaigo;
          if (action.payload && action.payload.kaigoId) {
            preDelKaigo = preDelKaigo.filter((item) => {
              return item.kaigoId !== action.payload.kaigoId;
            });
          }

          return {
            ...state,
            kaigo: preDelKaigo,
            loading: false,
            loadingSave: false,
          };
        }
        case 8: {
          let preDelTokki = state.tokki;
          if (action.payload && action.payload.tokkiId) {
            preDelTokki = {};
          }

          return {
            ...state,
            tokki: preDelTokki,
            loading: false,
            loadingSave: false,
          };
        }
        case 9: {
          let preDelGazou = state.gazou;
          if (action.payload && action.payload.fileManagementId) {
            preDelGazou = {};
          }

          return {
            ...state,
            gazou: preDelGazou,
            loading: false,
            loadingSave: false,
          };
        }
        default:
          return {
            ...state,
            loading: false,
            loadingSave: false,
          };
      }

    case REPORT_WEEKLY_ADD_DEL_ERROR:
      return {
        ...state,
        loading: false,
        loadingSave: false,
      };

    case REPORT_WEEKLY_ADD_YOUTEI_REQUEST:
      return {
        ...state,
        loading: true,
        loadingSave: true,
      };

    case REPORT_WEEKLY_ADD_YOUTEI_SUCCESS: {
      const preYouteiUpdate = state.kaigo;
      const youTeiIndex = preYouteiUpdate.findIndex(
        (item) => item.kaigo_naiyou_code === action.payload.kaigo_naiyou_code
      );

      if (youTeiIndex || youTeiIndex === 0) {
        preYouteiUpdate[youTeiIndex] = action.payload;
      }

      return {
        ...state,
        kaigo: preYouteiUpdate,
        loading: false,
        loadingSave: false,
      };
    }
    default:
      return state;
  }
}

function isArray(obj) {
  return !!obj && obj.constructor === Array;
}

function mergePlanKaigo(kaigo, youtei) {
  const arr = [];

  if (youtei && youtei.length > 0) {
    youtei.forEach((item) => {
      let check = true;
      kaigo.forEach((obj) => {
        if (obj.kaigo_naiyou_code === item.kaigoNaiyouCode) {
          check = false;
        }
      });

      if (check) {
        arr.push({
          youtei: true,
          kaigo_naiyou_code: item.kaigoNaiyouCode,
          kaigo_naiyou: item.kaigoNaiyou,
        });
      }
    });
  }

  return arr.concat(kaigo);
}

function planMeal(mealYotei) {
  if (mealYotei && mealYotei[0] && mealYotei[0].settingName) {
    switch (mealYotei[0].settingName) {
      case "M朝提供内容設定":
        return "朝";

      case "M昼提供内容設定":
        return "昼";

      case "M夕提供内容設定":
        return "夕";

      case "M間提供内容設定":
        return "間食";

      case "M経提供内容設定":
        return "経管栄養";

      default:
        return;
    }
  }
}
