import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button, DatePicker } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Component } from "react";
import datePickerJp from "../../../data/datePickerJp.json";
import { getDateInMonth } from "../../function_common/functionCommon";

export default class DatePickerControl extends Component {
  handleNext() {
    const thisMonth = _.cloneDeep(this.props.value);
    const nextMonth = thisMonth.add(
      1,
      this.props.mode === "month" ? "months" : "days"
    );
    this.props.onChange(nextMonth);
  }

  handlePrevious() {
    const thisMonth = _.cloneDeep(this.props.value);
    const prevMonth = thisMonth.subtract(
      1,
      this.props.mode === "month" ? "months" : "days"
    );
    this.props.onChange(prevMonth);
  }

  render() {
    const { value, mode, classDatepicker } = this.props;
    return (
      <div style={this.props.style} className={classDatepicker}>
        <Button
          icon={<CaretLeftOutlined />}
          type={"primary"}
          onClick={() => this.handlePrevious()}
        />
        {mode === "month" ? (
          <DatePicker
            locale={datePickerJp}
            value={dayjs(value)}
            onChange={(value) => this.props.onChange(value)}
            readOnly={true}
            style={{ width: "100px", border: "none" }}
            picker="month"
          />
        ) : (
          <DatePicker
            locale={datePickerJp}
            value={dayjs(value)}
            onChange={(value) => this.props.onChange(value)}
            readOnly={true}
            suffixIcon={
              <span className="text-base-color">
                {"(" +
                  getDateInMonth(
                    value.year(),
                    value.month() + 1,
                    value.date()
                  ) +
                  ")"}
              </span>
            }
            style={{ width: "120px", top: "-1px" }}
          />
        )}
        <Button
          icon={<CaretRightOutlined />}
          type={"primary"}
          onClick={() => this.handleNext()}
        />
      </div>
    );
  }
}
