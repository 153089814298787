import { SCHEDULE_GET, SCHEDULE_UPDATE, SCHEDULE_UPDATE_ERROR } from "../../actions/scheduleActions/actionName";
import { notification } from "antd/lib/index";

export default function ScheduleReducers(state = {
  loading: true
}, action) {
  switch (action.type) {
    case SCHEDULE_GET:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case SCHEDULE_UPDATE:
      notification["success"]({
        message: "保存しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        data: action.data,
        notifyContentEdit: { type: "success" },
        loading: false
      };
    case SCHEDULE_UPDATE_ERROR:
      notification["error"]({
        message: "エラーが発生しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        state,
        data: action.data,
        loading: false,
        notifyContentEdit: { type: "error" }
      };
    default:
      return state;
  }
}