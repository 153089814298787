import {
  LOAD_SISETU_DAILY_PLAN_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_ERROR,
  LOAD_SISETU_DAILY_PLAN_BY_ID_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_BY_ID_ERROR,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_ERROR,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_ERROR,
  LOAD_KAIGO_NAIYOU_SUCCESS,
  LOAD_KAIGO_NAIYOU_ERROR,
  SAVE_SISETU_DAILY_PLAN_SUCCESS,
  SAVE_SISETU_DAILY_PLAN_ERROR,
  DELETE_SISETU_DAILY_PLAN_SUCCESS,
  DELETE_SISETU_DAILY_PLAN_ERROR,
  LOAD_SPIN_REQUEST,
  LOAD_SPIN_REQUEST_TEMPLATE,
  LOAD_SEIKATU_NAIYOU_SUCCESS,
  LOAD_SEIKATU_NAIYOU_ERROR,
  RESET_STATUS,
} from "./actionName";
import { SisetuDailyPlanApi } from "../../api/sisetuDailyPlan";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
export function resetStatus() {
  return (dispatch) =>
    dispatch({
      type: RESET_STATUS,
    });
}
export function loadSisetuDailyPlan(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    SisetuDailyPlanApi.loadSisetuDailyPlan(data).then(
      (data) => {
        dispatch({
          type: LOAD_SISETU_DAILY_PLAN_SUCCESS,
          sisetuDailyPlans: data.data,
          meta: data.meta,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SISETU_DAILY_PLAN_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function getSisetuDailyPlan(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    if (id === "new" || Number(id) === 0) {
      return dispatch({
        type: LOAD_SISETU_DAILY_PLAN_BY_ID_SUCCESS,
        sisetuDailyPlan: {
          dailyPlanDetails: [],
          nyuyokuYoubi: "",
          dailyPlanId: "new",
          progressF: 0,
          riyouHoldingF: 0,
          serviceKikanMasterName: "プラスワン福祉施設",
          sid: 59,
          sonotaService: "",
          tempName: null,
          zuijiService: "",
        },
      });
    }
    SisetuDailyPlanApi.getSisetuDailyPlanById(id).then(
      (data) =>
        dispatch({
          type: LOAD_SISETU_DAILY_PLAN_BY_ID_SUCCESS,
          sisetuDailyPlan: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SISETU_DAILY_PLAN_BY_ID_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getKaigoNaiyou(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    SisetuDailyPlanApi.getSisetuDailyPlanById(id).then(
      (data) =>
        dispatch({
          type: LOAD_KAIGO_NAIYOU_SUCCESS,
          kaigoNaiyou: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KAIGO_NAIYOU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getSeikatuNaiyou() {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    SisetuDailyPlanApi.getSisetuDailyPlanById(-1).then(
      (data) =>
        dispatch({
          type: LOAD_SEIKATU_NAIYOU_SUCCESS,
          seikatuNaiyou: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SEIKATU_NAIYOU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function saveSisetuDailyPlan(data, riyousyaId, planId, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    SisetuDailyPlanApi.saveSisetuDailyPlan(data, riyousyaId, planId).then(
      (data) => {
        dispatch({
          type: SAVE_SISETU_DAILY_PLAN_SUCCESS,
          savedSisetuDailyPlan: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: SAVE_SISETU_DAILY_PLAN_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function deleteSisetuDailyPlan(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    SisetuDailyPlanApi.deleteSisetuDailyPlan(id).then(
      (data) =>
        dispatch({
          type: DELETE_SISETU_DAILY_PLAN_SUCCESS,
          deletedSisetuDailyPlan: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: DELETE_SISETU_DAILY_PLAN_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function saveKaigoServiceTemplate(args) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    SisetuDailyPlanApi.saveKaigoServiceTemplate(args).then(
      () => {
        dispatch({ type: SAVE_SISETU_DAILY_PLAN_SUCCESS });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: SAVE_SISETU_DAILY_PLAN_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getKaigoServiceTemplateName() {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST_TEMPLATE,
    });
    SisetuDailyPlanApi.getKaigoServiceTemplateName().then(
      (data) =>
        dispatch({
          type: LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_SUCCESS,
          sisetuDailyPlanTemplates: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getKaigoServiceTemplate(templateId, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    SisetuDailyPlanApi.getKaigoServiceTemplate(templateId).then(
      (data) => {
        dispatch({ type: LOAD_SISETU_DAILY_PLAN_TEMPLATE_SUCCESS });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SISETU_DAILY_PLAN_TEMPLATE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function saveActTemplate(args) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    SisetuDailyPlanApi.saveActTemplate(args).then(
      () => {
        dispatch({ type: SAVE_SISETU_DAILY_PLAN_SUCCESS });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: SAVE_SISETU_DAILY_PLAN_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getActTemplate(callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    SisetuDailyPlanApi.getActTemplate().then(
      (data) => {
        dispatch({ type: LOAD_SISETU_DAILY_PLAN_TEMPLATE_SUCCESS });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SISETU_DAILY_PLAN_TEMPLATE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
