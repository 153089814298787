import { Button, Checkbox, List, Modal } from "antd";
import React from "react";
import { Redirect } from "react-router-dom";
import { ConstSet } from "../../../common/configs/constset";

export default class ActionsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      title: null,
      content: null,
      modalType: null,
      activeRow: null,
      delValue: null,
      toEdit: false,
      checkBoxArr: {},
      timeRange: "00:00",
      openTab: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
  }

  showModal = (type) => {
    if (type === "del") {
      const { record } = this.props;

      const delData = [
        {
          key: 1,
          content: "全削除",
          type: 10,
        },
        // {
        //     key: 2,
        //     content: "画像記録のみ削除",
        //     type: 11
        // }
      ];

      if (record && record.length > 0) {
        let delKey = 3;
        for (let i = 0; i < record.length; i++) {
          if (record[i].vitalId) {
            delData.push({
              key: delKey,
              content: `${record[i].time.substring(0, 5)} バイタル記録`,
              type: 1,
              id: record[i].vitalId,
            });
            delKey++;
          }

          if (record[i].mealId) {
            delData.push({
              key: delKey,
              content: `${record[i].time.substring(0, 5)} 食事摂取記録`,
              type: 2,
              id: record[i].mealId,
            });
            delKey++;
          }

          if (record[i].suibunId) {
            delData.push({
              key: delKey,
              content: `${record[i].time.substring(0, 5)} 水分摂取記録`,
              type: 3,
              id: record[i].suibunId,
            });
            delKey++;
          }

          if (record[i].haisetuId) {
            delData.push({
              key: delKey,
              content: `${record[i].time.substring(0, 5)} 排泄介助記録`,
              type: 4,
              id: record[i].haisetuId,
            });
            delKey++;
          }

          if (record[i].nyuyokuId) {
            delData.push({
              key: delKey,
              content: `${record[i].time.substring(0, 5)} 入浴介助記録`,
              type: 5,
              id: record[i].nyuyokuId,
            });
            delKey++;
          }

          if (record[i].fukuyakuId) {
            delData.push({
              key: delKey,
              content: `${record[i].time.substring(0, 5)} 服薬介助記録`,
              type: 6,
              id: record[i].fukuyakuId,
            });
            delKey++;
          }

          if (record[i].kaigoId) {
            delData.push({
              key: delKey,
              content: `${record[i].time.substring(0, 5)} 提供記録記録`,
              type: 7,
              id: record[i].kaigoId,
            });
            delKey++;
          }

          if (record[i].tokkiId) {
            delData.push({
              key: delKey,
              content: `${record[i].time.substring(0, 5)} 特記介助記録`,
              type: 8,
              id: record[i].tokkiId,
            });
            delKey++;
          }

          if (record[i].fileManagementId) {
            delData.push({
              key: delKey,
              content: `${record[i].time.substring(0, 5)} 画像等記録`,
              type: 9,
              id: record[i].fileManagementId,
            });
            delKey++;
          }
        }
      }

      const content = (
        <List
          size="small"
          bordered
          dataSource={delData}
          renderItem={(item) => (
            <List.Item
              onClick={this.selectRow.bind(this, item.key, "del", {
                type: item.type,
                id: item.id,
              })}
              className={
                this.state.activeRow === item.key ? "active-row" : null
              }
            >
              {item.content}
              {item.type !== 10 && item.type !== 11 ? (
                <Checkbox
                  checked={
                    this.state.checkBoxArr[`${item.type}-${item.id}`]
                      ? this.state.checkBoxArr[`${item.type}-${item.id}`]
                      : false
                  }
                  style={{ float: "right" }}
                />
              ) : null}
            </List.Item>
          )}
        />
      );

      this.setState({
        title: "削除する記録を選択してください",
        visible: true,
        content,
        activeRow: null,
        modalType: type,
      });
    }

    if (type === "add") {
      const { timeRange, planType } = this.props;

      this.props.history.push(
        window.location.pathname + window.location.search
      );

      this.setState({
        toEdit: true,
        timeRange,
        openTab: [
          planType.includes("1") ? 1 : 0,
          planType.includes("2") ? 1 : 0,
          planType.includes("3") ? 1 : 0,
          planType.includes("4") ? 1 : 0,
          planType.includes("5") ? 1 : 0,
          planType.includes("6") ? 1 : 0,
          planType.includes("7") ? 1 : 0,
          0,
          0,
        ],
      });
    }

    if (type === "edit") {
      const { record, planType } = this.props;

      const editData = [];

      if (record && record.length === 1) {
        const toEdit = true;

        this.props.history.push(
          window.location.pathname + window.location.search
        );

        this.setState({
          toEdit,
          timeRange: record[0].time ? record[0].time.substring(0, 5) : "00:00",
          openTab: [
            record[0].vitalId || planType.includes("1") ? 1 : 0,
            record[0].mealId || planType.includes("2") ? 1 : 0,
            record[0].suibunId || planType.includes("3") ? 1 : 0,
            record[0].haisetuId || planType.includes("4") ? 1 : 0,
            record[0].nyuyokuId || planType.includes("5") ? 1 : 0,
            record[0].fukuyakuId || planType.includes("6") ? 1 : 0,
            record[0].kaigoId || planType.includes("7") ? 1 : 0,
            record[0].tokkiId ? 1 : 0,
            record[0].fileManagementId ? 1 : 0,
          ],
        });
      }

      if (record && record.length > 0) {
        for (let i = 0; i < record.length; i++) {
          editData.push({
            key: i,
            content: `${record[i].time.substring(0, 5)} ${record[i].content}`,
            time: record[i].time,
          });
        }
      }

      const content = (
        <List
          size="small"
          bordered
          dataSource={editData}
          renderItem={(item) => (
            <List.Item
              onClick={this.selectRow.bind(this, item.key, "edit", item.time)}
              className={
                this.state.activeRow === item.key ? "active-row" : null
              }
            >
              {item.content}
            </List.Item>
          )}
        />
      );

      this.setState({
        title: "選択してください",
        visible: true,
        content,
        activeRow: null,
        modalType: "edit",
      });
    }
  };

  handleOk = () => {
    const { modalType, delValue, checkBoxArr } = this.state;
    const { record } = this.props;

    let toEdit = false;
    if (modalType === "edit") {
      this.props.history.push(
        window.location.pathname + window.location.search
      );

      toEdit = true;
    }

    if (modalType === "del") {
      // if type = 10 --> remove all
      if (delValue && delValue.type && delValue.type === 10) {
        this.props.deleteAllRecords(record);
      }
      else if (delValue && delValue.type && delValue.type === 11) {
        this.props.deleteAllImages(record);
      }
      else {
        this.props.deleteRecords(checkBoxArr);
      }
    }

    this.props.history.push(window.location.pathname + window.location.search);

    this.setState({
      loading: false,
      visible: false,
      activeRow: null,
      toEdit,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      title: null,
      content: null,
      modalType: null,
      activeRow: null,
      delValue: null,
      openTab: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    });
  };

  /**
   * type = edit or del
   *
   * type = edit : value is array of record id [233, 0, 0, 333, 0, 333, 0, 0 , 33]
   * type = del : value is object { type: item.type, id: item.id }
   *
   */
  selectRow = (key, type, value) => {
    if (type === "edit") {
      const { record } = this.props;
      this.setState({
        activeRow: key,
        timeRange: value.substring(0, 5),
        openTab: [
          record[key].vitalId ? 1 : 0,
          record[key].mealId ? 1 : 0,
          record[key].suibunId ? 1 : 0,
          record[key].haisetuId ? 1 : 0,
          record[key].nyuyokuId ? 1 : 0,
          record[key].fukuyakuId ? 1 : 0,
          record[key].kaigoId ? 1 : 0,
          record[key].tokkiId ? 1 : 0,
          record[key].fileManagementId ? 1 : 0,
        ],
      });
    }

    if (type === "del") {
      if (value.type === 10 || value.type === 11) {
        this.setState({
          activeRow: key,
          delValue: value,
          checkBoxArr: {},
        });
      }
      else {
        const preCheckBoxArr = Object.assign({}, this.state.checkBoxArr, {
          [`${value.type}-${value.id}`]: !this.state.checkBoxArr[
            `${value.type}-${value.id}`
          ],
        });

        this.setState({
          activeRow: null,
          delValue: value,
          checkBoxArr: preCheckBoxArr,
        });
      }
    }
  };

  render() {
    const { riyousyaId, date, record } = this.props;
    const { visible, loading, title, content, toEdit, timeRange, openTab } =
      this.state;

    return (
      (<div className="w-a-btn">
        {record && record.length > 0 ? (
          <div>
            {/* <Button type="primary" className="w-a-btn-del-all" onClick={this.showModal.bind(this, "delAll")}>一括消去</Button> */}
            <Button
              type="primary"
              className="w-a-btn-del"
              onClick={this.showModal.bind(this, "del")}
            >
              消去
            </Button>
            <Button
              type="primary"
              className="w-a-btn-edit"
              onClick={this.showModal.bind(this, "edit")}
            >
              修正{" "}
            </Button>
            <Button
              type="primary"
              className="w-a-btn-add"
              onClick={this.showModal.bind(this, "add")}
            >
              追加
            </Button>
          </div>
        ) : (
          <Button
            type="primary"
            className="w-a-btn-add"
            onClick={this.showModal.bind(this, "add")}
          >
            追加1
          </Button>
        )}
        {toEdit ? (
          <Redirect
            to={`${ConstSet.REPORT_WEEKLY_ADD_PAGE}${riyousyaId}?timeRange=${timeRange}&date=${date}&openTab=${openTab}`}
          />
        ) : null}
        <Modal
          open={visible}
          title={title}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={this.handleOk}
            >
              OK
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
          ]}
          className={"weekly-model-dae"}
        >
          {content}
        </Modal>
      </div>)
    );
  }
}
