import {
  KANRI_REQUEST,
  KANRI_REQUEST_ERROR,
  CLEAR_SELECTED_ROW,
  SAVE_SELECTED_ROW,
  SAVE_RECORD_MARK,
  KANRI_SEARCH_DATA,
  KANRI_USER_LIST_SUCCESS,
  KANRI_USER_SELECTED_DISPLAY,
  KANRI_USER_DISPLAY_LIST,
  KANRI_USER_UPDATE_SUCCESS,
  KANRI_USER_ADD_SUCCESS,
  KANRI_USER_DELETE_SUCCESS,
  KANRI_FILE_LIST_SUCCESS,
  KANRI_FILE_SINGLE_RECORD_SUCCESS,
  KANRI_FILE_SELECTED_DISPLAY,
  KANRI_FILE_UPDATE_SUCCESS,
  KANRI_FILE_ADD_SUCCESS,
  KANRI_FILE_DELETE_SUCCESS,
  KANRI_DENGON_LIST_SUCCESS,
  KANRI_DENGON_SINGLE_RECORD_SUCCESS,
  KANRI_DENGON_SELECTED_DISPLAY,
  KANRI_DENGON_UPDATE_SUCCESS,
  KANRI_DENGON_ADD_SUCCESS,
  KANRI_DENGON_DELETE_SUCCESS,
  KANRI_MOSHIOKURI_LIST_SUCCESS,
  KANRI_MOSHIOKURI_SINGLE_RECORD_SUCCESS,
  KANRI_MOSHIOKURI_SELECTED_DISPLAY,
  KANRI_MOSHIOKURI_UPDATE_SUCCESS,
  KANRI_MOSHIOKURI_ADD_SUCCESS,
  KANRI_MOSHIOKURI_DELETE_SUCCESS,
  KANRI_OPENSL_LIST_SUCCESS,
  KANRI_OPENSL_SINGLE_RECORD_SUCCESS,
  KANRI_OPENSL_SELECTED_DISPLAY,
  KANRI_OPENSL_UPDATE_SUCCESS,
  KANRI_OPENSL_ADD_SUCCESS,
  KANRI_OPENSL_DELETE_SUCCESS,
  KANRI_SM_LIST_SUCCESS,
  KANRI_SM_SELECTED_DISPLAY,
  KANRI_SM_DISPLAY_LIST,
  KANRI_SM_UPDATE_SUCCESS,
  KANRI_SM_ADD_SUCCESS,
  KANRI_SM_DELETE_SUCCESS,
  KANRI_YM_LIST_SUCCESS,
  KANRI_YM_SELECTED_DISPLAY,
  KANRI_YM_DISPLAY_LIST,
  KANRI_YM_UPDATE_SUCCESS,
  KANRI_YM_ADD_SUCCESS,
  KANRI_YM_DELETE_SUCCESS,
  KANRI_LYM_LIST_SUCCESS,
  KANRI_LYM_SELECTED_DISPLAY,
  KANRI_LYM_DISPLAY_LIST,
  KANRI_LYM_UPDATE_SUCCESS,
  KANRI_LYM_ADD_SUCCESS,
  KANRI_LYM_DELETE_SUCCESS,
  KANRI_KYM_LIST_SUCCESS,
  KANRI_KYM_SINGLE_RECORD_SUCCESS,
  KANRI_KYM_SELECTED_DISPLAY,
  KANRI_KYM_UPDATE_SUCCESS,
  KANRI_KYM_ADD_SUCCESS,
  KANRI_KYM_DELETE_SUCCESS,
  KANRI_TM_LIST_SUCCESS,
  KANRI_TM_SELECTED_DISPLAY,
  KANRI_TM_DISPLAY_LIST,
  KANRI_TM_UPDATE_SUCCESS,
  KANRI_TM_ADD_SUCCESS,
  KANRI_TM_DELETE_SUCCESS,
  KANRI_URL_LIST_SUCCESS,
  KANRI_URL_SELECTED_DISPLAY,
  KANRI_URL_DISPLAY_LIST,
  KANRI_URL_UPDATE_SUCCESS,
  KANRI_URL_ADD_SUCCESS,
  KANRI_URL_DELETE_SUCCESS,
} from "../../actions/kanriActions/actionName.js";
import KanriApi from "../../api/kanri.js";

function recordMarkCreator(typeF, typeA, id) {
  return (dispatch) => {
    dispatch({
      type: SAVE_RECORD_MARK,
      typeF: typeF,
      typeA: typeA,
      id: id,
    });
  };
}

function updateSelectedRowsCreator(type, selectedRows) {
  return (dispatch) => {
    dispatch({
      type: SAVE_SELECTED_ROW,
      params: type,
      selectedRows: selectedRows,
    });
  };
}

function clearSelectedRowCreator(type) {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_ROW,
      params: type,
    });
  };
}

/**
 * USER
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteUserCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteUser(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_USER_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateUserCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateUser(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_USER_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createUserCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createUser(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_USER_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_USER_SELECTED_DISPLAY,
      ids: ids,
    });
  };
}

function displayListCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_USER_DISPLAY_LIST,
      ids: ids,
    });
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postUserListCreator(text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postUserList(text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_USER_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getUserListCreator() {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getUserList()
      .then((data) =>
        dispatch({
          type: KANRI_USER_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END USER

/**
 * SM
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteSMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteSM(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_SM_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateSMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateSM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_SM_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createSMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createSM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_SM_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplaySMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_SM_SELECTED_DISPLAY,
      ids: ids,
    });
  };
}

function displayListSMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_SM_DISPLAY_LIST,
      ids: ids,
    });
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postSMListCreator(text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postSMList(text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_SM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getSMListCreator() {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getSMList()
      .then((data) =>
        dispatch({
          type: KANRI_SM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END SM

/**
 * YM
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteYMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteYM(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_YM_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateYMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateYM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_YM_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createYMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createYM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_YM_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayYMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_YM_SELECTED_DISPLAY,
      ids: ids,
    });
  };
}

function displayListYMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_YM_DISPLAY_LIST,
      ids: ids,
    });
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postYMListCreator(text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postYMList(text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_YM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getYMListCreator() {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getYMList()
      .then((data) =>
        dispatch({
          type: KANRI_YM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END YM

/**
 * LYM
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteLYMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteLYM(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_LYM_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateLYMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateLYM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_LYM_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createLYMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createLYM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_LYM_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayLYMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_LYM_SELECTED_DISPLAY,
      ids: ids,
    });
  };
}

function displayListLYMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_LYM_DISPLAY_LIST,
      ids: ids,
    });
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postLYMListCreator(text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postLYMList(text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_LYM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getLYMListCreator() {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getLYMList()
      .then((data) =>
        dispatch({
          type: KANRI_LYM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END LYM

/**
 * KYM
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteKYMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteKYM(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_KYM_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateKYMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateKYM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_KYM_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createKYMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createKYM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_KYM_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayKYMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postKYMByIds(ids)
      .then((data) =>
        dispatch({
          type: KANRI_KYM_SELECTED_DISPLAY,
          payload: data,
          ids: ids,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postKYMListCreator(current, pageSize, text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postKYMList(current, pageSize, text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_KYM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getKYMListCreator(current, pageSize, text1, text2, text3, orderBy) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getKYMList(current, pageSize, text1, text2, text3, orderBy)
      .then((data) =>
        dispatch({
          type: KANRI_KYM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

function getKYMSingleRecordCreator(id, ids, type) {
  return (dispatch) => {
    KanriApi.getKYMSingleRecord(id, ids, type)
      .then((data) =>
        dispatch({
          type: KANRI_KYM_SINGLE_RECORD_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END KYM

/**
 * TM
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteTMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteTM(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_TM_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateTMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateTM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_TM_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createTMCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createTM(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_TM_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayTMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_TM_SELECTED_DISPLAY,
      ids: ids,
    });
  };
}

function displayListTMCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_TM_DISPLAY_LIST,
      ids: ids,
    });
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postTMListCreator(text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postTMList(text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_TM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getTMListCreator() {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getTMList()
      .then((data) =>
        dispatch({
          type: KANRI_TM_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END TM

/**
 * URL
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteURLCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteURL(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_URL_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateURLCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateURL(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_URL_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createURLCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createURL(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_URL_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayURLCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_URL_SELECTED_DISPLAY,
      ids: ids,
    });
  };
}

function displayListURLCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_URL_DISPLAY_LIST,
      ids: ids,
    });
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postURLListCreator(text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postURLList(text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_URL_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getURLListCreator() {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getURLList()
      .then((data) =>
        dispatch({
          type: KANRI_URL_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END URL

/**
 *
 * @param {*} type
 */
function getSearchDataCreator(type) {
  return (dispatch) => {
    KanriApi.getSearchData(type)
      .then((data) =>
        dispatch({
          type: KANRI_SEARCH_DATA,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 * FILE
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteFileCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteFile(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_FILE_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateFileCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateFile(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_FILE_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createFileCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createFile(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_FILE_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayFileCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postFileByIds(ids)
      .then((data) =>
        dispatch({
          type: KANRI_FILE_SELECTED_DISPLAY,
          payload: data,
          ids: ids,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postFileListCreator(current, pageSize, text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postFileList(current, pageSize, text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_FILE_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getFileListCreator(current, pageSize, text1, text2, text3, orderBy) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getFileList(current, pageSize, text1, text2, text3, orderBy)
      .then((data) =>
        dispatch({
          type: KANRI_FILE_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

function getFileSingleRecordCreator(id, ids, type) {
  return (dispatch) => {
    KanriApi.getFileSingleRecord(id, ids, type)
      .then((data) =>
        dispatch({
          type: KANRI_FILE_SINGLE_RECORD_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END FILE

/**
 * DENGON
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteDengonCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteDengon(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_DENGON_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateDengonCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateDengon(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_DENGON_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createDengonCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createDengon(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_DENGON_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayDengonCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postDengonByIds(ids)
      .then((data) =>
        dispatch({
          type: KANRI_DENGON_SELECTED_DISPLAY,
          payload: data,
          ids: ids,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postDengonListCreator(current, pageSize, text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postDengonList(current, pageSize, text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_DENGON_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getDengonListCreator(current, pageSize, text1, text2, text3, orderBy) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getDengonList(current, pageSize, text1, text2, text3, orderBy)
      .then((data) =>
        dispatch({
          type: KANRI_DENGON_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

function getDengonSingleRecordCreator(id, ids, type) {
  return (dispatch) => {
    KanriApi.getDengonSingleRecord(id, ids, type)
      .then((data) =>
        dispatch({
          type: KANRI_DENGON_SINGLE_RECORD_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END DENGON

/**
 * MOSHIOKURI
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteMoshiokuriCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteMoshiokuri(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_MOSHIOKURI_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateMoshiokuriCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateMoshiokuri(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_MOSHIOKURI_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createMoshiokuriCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createMoshiokuri(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_MOSHIOKURI_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayMoshiokuriCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postMoshiokuriByIds(ids)
      .then((data) =>
        dispatch({
          type: KANRI_MOSHIOKURI_SELECTED_DISPLAY,
          payload: data,
          ids: ids,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postMoshiokuriListCreator(current, pageSize, text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postMoshiokuriList(current, pageSize, text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_MOSHIOKURI_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getMoshiokuriListCreator(
  current,
  pageSize,
  text1,
  text2,
  text3,
  orderBy
) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getMoshiokuriList(current, pageSize, text1, text2, text3, orderBy)
      .then((data) =>
        dispatch({
          type: KANRI_MOSHIOKURI_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

function getMoshiokuriSingleRecordCreator(id, ids, type) {
  return (dispatch) => {
    KanriApi.getMoshiokuriSingleRecord(id, ids, type)
      .then((data) =>
        dispatch({
          type: KANRI_MOSHIOKURI_SINGLE_RECORD_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END MOSHIOKURI

/**
 * OPENSCREENLOG
 *
 */

/**
 *
 * @param {*} ids
 */
function deleteOpenSLCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.deleteOpenSL(ids).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_OPENSL_DELETE_SUCCESS,
          payload: data,
          params: ids,
        });
      }
    });
  };
}

function updateOpenSLCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.updateOpenSL(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_OPENSL_UPDATE_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function createOpenSLCreator(params) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.createOpenSL(params).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: KANRI_REQUEST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: KANRI_OPENSL_ADD_SUCCESS,
          payload: data,
        });
      }
    });
  };
}

function selectedDisplayOpenSLCreator(ids) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postOpenSLByIds(ids)
      .then((data) =>
        dispatch({
          type: KANRI_OPENSL_SELECTED_DISPLAY,
          payload: data,
          ids: ids,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 * @param {*} text1
 * @param {*} text2
 * @param {*} text3
 */
function postOpenSLListCreator(current, pageSize, text1, text2, text3) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.postOpenSLList(current, pageSize, text1, text2, text3)
      .then((data) =>
        dispatch({
          type: KANRI_OPENSL_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

/**
 *
 */
function getOpenSLListCreator(current, pageSize, text1, text2, text3, orderBy) {
  return (dispatch) => {
    dispatch({
      type: KANRI_REQUEST,
    });

    KanriApi.getOpenSLList(current, pageSize, text1, text2, text3, orderBy)
      .then((data) =>
        dispatch({
          type: KANRI_OPENSL_LIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

function getOpenSLSingleRecordCreator(id, ids, type) {
  return (dispatch) => {
    KanriApi.getOpenSLSingleRecord(id, ids, type)
      .then((data) =>
        dispatch({
          type: KANRI_OPENSL_SINGLE_RECORD_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: KANRI_REQUEST_ERROR,
        error: err,
      }));
  };
}

// END DENGON

export {
  getSearchDataCreator,
  getUserListCreator,
  postUserListCreator,
  selectedDisplayCreator,
  displayListCreator,
  updateUserCreator,
  createUserCreator,
  deleteUserCreator,
  getFileListCreator,
  postFileListCreator,
  getFileSingleRecordCreator,
  selectedDisplayFileCreator,
  updateFileCreator,
  createFileCreator,
  deleteFileCreator,
  getDengonListCreator,
  postDengonListCreator,
  getDengonSingleRecordCreator,
  selectedDisplayDengonCreator,
  updateDengonCreator,
  createDengonCreator,
  deleteDengonCreator,
  getMoshiokuriListCreator,
  postMoshiokuriListCreator,
  getMoshiokuriSingleRecordCreator,
  selectedDisplayMoshiokuriCreator,
  updateMoshiokuriCreator,
  createMoshiokuriCreator,
  deleteMoshiokuriCreator,
  getOpenSLListCreator,
  postOpenSLListCreator,
  getOpenSLSingleRecordCreator,
  selectedDisplayOpenSLCreator,
  updateOpenSLCreator,
  createOpenSLCreator,
  deleteOpenSLCreator,
  getSMListCreator,
  postSMListCreator,
  selectedDisplaySMCreator,
  displayListSMCreator,
  updateSMCreator,
  createSMCreator,
  deleteSMCreator,
  getYMListCreator,
  postYMListCreator,
  selectedDisplayYMCreator,
  displayListYMCreator,
  updateYMCreator,
  createYMCreator,
  deleteYMCreator,
  getLYMListCreator,
  postLYMListCreator,
  selectedDisplayLYMCreator,
  displayListLYMCreator,
  updateLYMCreator,
  createLYMCreator,
  deleteLYMCreator,
  getKYMListCreator,
  postKYMListCreator,
  getKYMSingleRecordCreator,
  selectedDisplayKYMCreator,
  updateKYMCreator,
  createKYMCreator,
  deleteKYMCreator,
  getTMListCreator,
  postTMListCreator,
  selectedDisplayTMCreator,
  displayListTMCreator,
  updateTMCreator,
  createTMCreator,
  deleteTMCreator,
  getURLListCreator,
  postURLListCreator,
  selectedDisplayURLCreator,
  displayListURLCreator,
  updateURLCreator,
  createURLCreator,
  deleteURLCreator,
  clearSelectedRowCreator,
  updateSelectedRowsCreator,
  recordMarkCreator,
};
