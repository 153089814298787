import React from "react";
import { Select } from "antd";

const Option = Select.Option;

export default class SelectOptionsFormNew1 extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.props;
    this.state = {
      value: value ? value : null,
    };

    // bind place
  }

  handleSelectChange = (value) => {
    this.setState({
      value: value,
    });

    this.triggerChange(value);
  };

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  render() {
    const { data, value } = this.props;

    return (
      <div>
        <Select value={value} onChange={this.handleSelectChange}>
          <Option key={0} value={0}>
            &nbsp;
          </Option>
          {data && data.length > 0
            ? data.map(function (item, index) {
                return (
                  <Option key={index + 1} value={index + 1}>
                    {item}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>
    );
  }
}
