import { Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import { guid } from "../../../common/timePicker/formatTimePicker";
const columns = [
  {
    title: "入退所分類",
    dataIndex: "kind",
    key: "kind",
    // width: 139,
    align: "left",
  },
  { title: "人数", dataIndex: "amount", key: "amount", width: 61 },
];

class NumberByType extends React.Component {
  render() {
    const { statisticalOutInByKind } = this.props;
    const data = Array.isArray(statisticalOutInByKind)
      ? statisticalOutInByKind.map((e) => {
        return {
          key: guid(),
          kind: e.kind,
          amount: e.amount,
        };
      })
      : [];
    return (
      <div>
        <h4>入退所分類別数</h4>
        <Table
          className={"tableItem3032"}
          bordered={true}
          rowKey={"key"}
          dataSource={data}
          columns={columns}
          pagination={false}
          size={"small"}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statisticalOutInByKind: state.dutyReport.statisticalOutInByKind,
  };
};
export default connect(mapStateToProps, null)(NumberByType);
