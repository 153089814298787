export const usageTicketManagementActionTypes = {
  SAVE_FILTER_CONDIITON_PLAN: "SAVE_FILTER_CONDIITON_USAGE_TICKET_PLAN",
  SAVE_FILTER_CONDIITON_ACTUAL: "SAVE_FILTER_CONDIITON_USAGE_TICKET_ACTUAL",
  SAVE_FILTER_CONDIITON_SUPPORT_ADDITION_LIST: "SAVE_FILTER_CONDIITON_SUPPORT_ADDITION_LIST",
  SAVE_NUMBER_ITEM: "SAVE_NUMBER_ITEM_USAGE_TICKET",
  SAVE_MODE: "SAVE_MODE_USAGE_TICKET",
  GET_LIST_SYURUI_MASTER_REQUEST: "GET_LIST_SYURUI_MASTER_REQUEST",
  GET_LIST_SYURUI_MASTER_SUCCESS: "GET_LIST_SYURUI_MASTER_SUCCESS",
  GET_LIST_SYURUI_MASTER_FAILURE: "GET_LIST_SYURUI_MASTER_FAILURE",
  LOADING_SCREEN: "LOADING_SCREEN",
  GET_LIST_USER_ACTIVE: "GET_LIST_USER_ACTIVE",
  GET_LIST_USER_ACTIVE_SUCCESS: "GET_LIST_USER_ACTIVE_SUCCESS",
  GET_LIST_USER_ACTIVE_FAILURE: "GET_LIST_USER_ACTIVE_FAILURE",
  GET_LIST_OFFICE_BY_RIYOUHYOU_ID_REQUEST: "GET_LIST_OFFICE_BY_RIYOUHYOU_ID_REQUEST",
  GET_LIST_OFFICE_BY_RIYOUHYOU_ID_SUCCESS: "GET_LIST_OFFICE_BY_RIYOUHYOU_ID_SUCCESS",
  GET_LIST_OFFICE_BY_RIYOUHYOU_ID_FAILURE: "GET_LIST_OFFICE_BY_RIYOUHYOU_ID_FAILURE",
  GET_LIST_OFFICE_BY_FILTER_CONDITION_REQUEST: "GET_LIST_OFFICE_BY_FILTER_CONDITION_REQUEST",
  GET_LIST_OFFICE_BY_FILTER_CONDITION_SUCCESS: "GET_LIST_OFFICE_BY_FILTER_CONDITION_SUCCESS",
  GET_LIST_OFFICE_BY_FILTER_CONDITION_FAILURE: "GET_LIST_OFFICE_BY_FILTER_CONDITION_FAILURE",
  SAVE_RECORD_SELECTED_PLAN_MODE: "SAVE_RECORD_SELECTED_PLAN_MODE",
  SAVE_RECORD_SELECTED_ACTUAL_MODE: "SAVE_RECORD_SELECTED_ACTUAL_MODE",
  SAVE_RECORD_SELECTED_SUPPORT_ADDITION_LIST_MODE: "SAVE_RECORD_SELECTED_SUPPORT_ADDITION_LIST_MODE",
  GET_LIST_USER_ACTIVE_WITH_NOT_TANTO_ID: "GET_LIST_USER_ACTIVE_WITH_NOT_TANTO_ID"
};
