export const LOAD_KAIGO_YOUGO_REQUEST = "LOAD_KAIGO_YOUGO_REQUEST";
export const LOAD_KAIGO_YOUGO_SUCCESS = "LOAD_KAIGO_YOUGO_SUCCESS";
export const LOAD_KAIGO_YOUGO_ALL_SUCCESS = "LOAD_KAIGO_YOUGO_ALL_SUCCESS";
export const LOAD_KAIGO_YOUGO_ALL = "LOAD_KAIGO_YOUGO_ALL";
export const LOAD_KAIGO_YOUGO_ERROR = "LOAD_KAIGO_YOUGO_ERROR";
export const UPDATE_KAIGO_YOUGO_REQUEST = "UPDATE_KAIGO_YOUGO_REQUEST";
export const UPDATE_KAIGO_YOUGO_SUCCESS = "UPDATE_KAIGO_YOUGO_SUCCESS";
export const UPDATE_KAIGO_YOUGO_LIST_SUCCESS = "UPDATE_KAIGO_YOUGO_LIST_SUCCESS";
export const UPDATE_KAIGO_YOUGO_ERROR = "UPDATE_KAIGO_YOUGO_ERROR";
export const LOAD_KAIGO_YOUGO_ID_REQUEST = "LOAD_KAIGO_YOUGO_ID_REQUEST";
export const LOAD_KAIGO_YOUGO_ID_SUCCESS = "LOAD_KAIGO_YOUGO_ID_SUCCESS";
export const LOAD_KAIGO_YOUGO_ID_ERROR = "LOAD_KAIGO_YOUGO_ID_ERROR";
export const LOAD_KAIGO_YOUGO_MAX_SORT = "LOAD_KAIGO_YOUGO_MAX_SORT";
export const UPDATE_KAIGO_YOUGO_LIST_REQUEST = "UPDATE_KAIGO_YOUGO_LIST_REQUEST";