/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import { CaretUpOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Input, Modal, Row, Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";
import { loadEventPlans } from "../../../actions/planActions/actionCreators";
import SubHeaderTitleV2 from "../../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../../common/component/SubHeaderV2";
import MenuRecord from "../../../common/component/UI/MenuRecordV2";
import TantoInput from "../../../common/component/control/TantoInput";
import DatePickerCommon from "./DatePickerCommon";
import EventInput from "./EventInput";
import FilterRoomCommon from "./FilterRoomCommon";
import ListWeatherCommon from "./ListWeatherCommon";
import StaffForm from "./StaffForm";
import WorkingStatus from "./WorkingStatus";

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { TextArea } = Input;

const customPanelStyle = {
  background: "#ddd",
  borderRadius: 4,
  marginBottom: 2,
  border: 0,
  overflow: "hidden",
};

class DutyReportControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleConfirm: false,
      yougoMasterStaffKindNaiyou: [],
      pageSelected: null,
      changePage: false,
      isChange: false,
    };
  }
  resetIsChange() {
    this.setState({
      isChange: false,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      yougoMasterStaffKindNaiyou: nextProps.yougoMasterStaffKindNaiyou,
    });
  }
  componentDidUpdate(prevProps) {
    const { notifyDisplay } = this.props;
    if (prevProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        this.setState({
          isChange: false,
        });
      }
      if (notifyDisplay === 1 && this.state.changePage == true) {
        const { pageSelected } = this.state;
        this.props.history.push(pageSelected);
        this.setState({
          visibleConfirm: false,
          pageSelected: null,
          changePage: false,
        });
      }
      if (notifyDisplay === 2 && this.state.changePage == true) {
        this.setState({
          visibleConfirm: false,
          pageSelected: null,
          changePage: false,
        });
      }
    }
  }
  onHideConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }
  onCancelConfirm() {
    const { pageSelected } = this.state;
    this.props.history.push(pageSelected);
    this.setState({
      visibleConfirm: false,
      pageSelected: null,
      isChange: false,
    });
  }
  async onOkConfirm() {
    await this.setState({
      visibleConfirm: false,
      changePage: true,
    });
    this.props.handleSaveData();
  }
  goToPage(pageURL) {
    const { isChange } = this.state;
    if (this.props.isChangeForm() || isChange) {
      this.setState({
        visibleConfirm: true,
        pageSelected: pageURL,
      });
    }
    else {
      this.props.history.push(pageURL);
    }
  }
  getDataFromModalStaff(key, value) {
    const { form } = this.props;
    const { setFieldsValue } = form;
    if (key == "dutyreport_shokuin1") {
      setFieldsValue({ shokuin1: value });
    }
    if (key == "dutyreport_shokuin2") {
      setFieldsValue({ shokuin2: value });
    }
    if (key == "dutyreport_shokuin3") {
      setFieldsValue({ shokuin3: value });
    }
    if (key == "dutyreport_shokuin4") {
      setFieldsValue({ shokuin4: value });
    }
    if (key == "dutyreport_shokuin5") {
      setFieldsValue({ shokuin5: value });
    }
    if (key == "dutyreport_shokuin6") {
      setFieldsValue({ shokuin6: value });
    }
    if (key == "working_status") {
      setFieldsValue({ working_status: value });
    }
    this.setState({
      isChange: true,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { staffKind } = this.props;
    const { visibleConfirm } = this.state;
    return (
      (<div>
        <div className={"submenu"}>
          <SubHeaderV2
            TopLeft={
              <SubHeaderTitleV2 title={"業務日誌"} iconType="schedule" />
            }
            TopRight2={
              <MenuRecord
                goToPage={(pageURL) => this.goToPage(pageURL)}
                isDiary={true}
              />
            }
          />
        </div>
        <Form layout="inline" hideRequiredMark={true}>
          <Row style={{ backgroundColor: "#E6E6E6" }}>
            <div className={"toolBar"}>
              <Form.Item>
                <DatePickerCommon
                  handleSaveData={this.props.handleSaveData}
                  isChangeForm={() => this.props.isChangeForm()}
                  isChange={this.state.isChange}
                  resetIsChange={() => this.resetIsChange()}
                  handleChangeDate={(value) =>
                    this.props.handleChangeDate(value)
                  }
                  selectedDate={this.props.selectedDate}
                />
              </Form.Item>
              <Form.Item label={"天候"} colon={false}>
                {getFieldDecorator("weather", { rules: [{ required: false }] })(
                  <ListWeatherCommon />
                )}
              </Form.Item>
              <Form.Item style={{ width: "364px" }}>
                <FilterRoomCommon
                  isChangeForm={() => this.props.isChangeForm()}
                  isChange={this.state.isChange}
                  resetIsChange={() => this.resetIsChange()}
                  wrappedComponentRef={this.props.wrappedComponentRef}
                  handleSaveData={this.props.handleSaveData}
                  roomBunrui={this.props.roomBunrui}
                  handleChangeRoomBunrui={(value) =>
                    this.props.handleChangeRoomBunrui(value)
                  }
                />
              </Form.Item>
              <Form.Item label={"記録者"} colon={false}>
                {getFieldDecorator("tanto_id", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(<TantoInput />)}
              </Form.Item>
              <Form.Item style={{ float: "right", marginRight: "0" }}>
                <Button
                  onClick={() => this.props.handleSaveData()}
                  type="primary" danger
                >
                  登録
                </Button>
              </Form.Item>
            </div>
          </Row>
          <Collapse
            className="rwa-c-col"
            defaultActiveKey={["1", "2"]}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <CaretUpOutlined rotate={isActive ? 180 : 90} />
            )}
            style={{
              paddingTop: "20px",
              margin: "0 auto",
              maxWidth: "1200px",
              borderStyle: "none",
              backgroundColor: "#fff",
            }}
          >
            <Panel header="行事内容等" key="1" style={customPanelStyle}>
              <div className={"inputContent"}>
                {/* Row1*/}
                {getFieldDecorator("event")(
                  <EventInput selectedDate={this.props.selectedDate} />
                )}
                {/* Row2*/}

                <div style={{ paddingTop: "35px" }}>
                  <div>
                    <span>
                      {"カンファレンス"}
                    </span>
                    {getFieldDecorator("conference", {
                      rules: [{ required: false }],
                    })(<TextArea rows={3} cols={80} />)}
                  </div>
                </div>

                {/* Row3*/}

                <div style={{ paddingTop: "35px" }}>
                  <div>
                    <span>
                      {"事故報告"}
                    </span>
                    {getFieldDecorator("jiko_houkoku", {
                      rules: [{ required: false }],
                    })(<TextArea rows={3} cols={80} />)}
                  </div>
                </div>
                {/* Row4*/}

                <div style={{ paddingTop: "35px" }}>
                  <div>
                    <span>
                      {"管理者コメント"}
                    </span>
                    {getFieldDecorator("kanri_comment", {
                      rules: [{ required: false }],
                    })(<TextArea rows={3} cols={80} />)}
                  </div>
                </div>

                {/* Row5*/}

                <div style={{ paddingTop: "35px" }}>
                  <div>
                    <span>
                      {"連絡・特記"}
                    </span>
                    {getFieldDecorator("renraku_tokki", {
                      rules: [{ required: false }],
                    })(<TextArea rows={3} cols={80} />)}
                  </div>
                </div>
              </div>
            </Panel>
            <Panel header="職員体制等" key="2" style={customPanelStyle}>
              <div
                style={{
                  margin: "10px auto",
                  maxWidth: "1200px",
                  padding: "0 10px",
                }}
              >
                <Tabs type="card">
                  <TabPane tab="職員体制" key="1">
                    <div>
                      <Row>
                        <Col lg={4}>
                          {getFieldDecorator("shokuin1")(
                            <StaffForm
                              buttonName={
                                staffKind.length > 0
                                  ? staffKind[0].naiyou
                                  : ""
                              }
                            />
                          )}
                        </Col>
                        <Col lg={4}>
                          {getFieldDecorator("shokuin2")(
                            <StaffForm
                              buttonName={
                                staffKind.length > 0
                                  ? staffKind[1].naiyou
                                  : ""
                              }
                            />
                          )}
                        </Col>
                        <Col lg={4}>
                          {getFieldDecorator("shokuin3")(
                            <StaffForm
                              buttonName={
                                staffKind.length > 0
                                  ? staffKind[2].naiyou
                                  : ""
                              }
                            />
                          )}
                        </Col>
                        <Col lg={4}>
                          {getFieldDecorator("shokuin4")(
                            <StaffForm
                              buttonName={
                                staffKind.length > 0
                                  ? staffKind[3].naiyou
                                  : ""
                              }
                            />
                          )}
                        </Col>
                        <Col lg={4}>
                          {getFieldDecorator("shokuin5")(
                            <StaffForm
                              buttonName={
                                staffKind.length > 0
                                  ? staffKind[4].naiyou
                                  : ""
                              }
                            />
                          )}
                        </Col>
                        <Col lg={4}>
                          {getFieldDecorator("shokuin6")(
                            <StaffForm
                              buttonName={
                                staffKind.length > 0
                                  ? staffKind[5].naiyou
                                  : ""
                              }
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tab="勤務状況" key="2">
                    {getFieldDecorator("working_status", {
                      rules: [{ required: false }],
                    })(
                      <WorkingStatus
                        roomBunrui={this.props.roomBunrui}
                        getDataFromModalStaff={(key, value) =>
                          this.getDataFromModalStaff(key, value)
                        }
                      />
                    )}
                  </TabPane>
                </Tabs>
              </div>
            </Panel>
          </Collapse>
          <Row>
            <Modal
              title={"変更を確認する"}
              open={visibleConfirm}
              onCancel={() => this.onHideConfirm()}
              footer={[
                <Button key="cancel" onClick={() => this.onCancelConfirm()}>
                  キャンセル
                </Button>,
                <Button
                  key="ok"
                  type="primary"
                  onClick={() => this.onOkConfirm()}
                >
                  Ok
                </Button>,
              ]}
            >
              <p>保存しますか？</p>
            </Modal>
          </Row>
        </Form>
      </div>)
    );
  }
}

const DutyReportForm = Form.create({
  name: "dutyreport",
  mapPropsToFields(props) {
    return {
      tanto_id: Form.createFormField({
        value:
          props.dutyReport != null
            ? props.dutyReport.tantoId == 0
              ? null
              : props.dutyReport.tantoId
            : props.tantoIdLogin,
      }),
      weather: Form.createFormField({
        value: props.dutyReport != null ? props.dutyReport.weather : null,
      }),
      room: Form.createFormField({
        value: props.dutyReport != null ? props.dutyReport.roomBunrui : null,
      }),
      event: Form.createFormField({
        value: props.dutyReport != null ? props.dutyReport.event : null,
      }),
      conference: Form.createFormField({
        value: props.dutyReport != null ? props.dutyReport.conference : null,
      }),
      jiko_houkoku: Form.createFormField({
        value: props.dutyReport != null ? props.dutyReport.jikoHoukoku : null,
      }),
      kanri_comment: Form.createFormField({
        value: props.dutyReport != null ? props.dutyReport.kanriComment : null,
      }),
      renraku_tokki: Form.createFormField({
        value: props.dutyReport != null ? props.dutyReport.renrakuTokki : null,
      }),
      shokuin1: Form.createFormField({
        value: {
          shokuin_num:
            props.dutyReport != null ? props.dutyReport.shokuinNum1 : 0,
          shokuin: props.dutyReport != null ? props.dutyReport.shokuin1 : null,
        },
      }),
      shokuin2: Form.createFormField({
        value: {
          shokuin_num:
            props.dutyReport != null ? props.dutyReport.shokuinNum2 : 0,
          shokuin: props.dutyReport != null ? props.dutyReport.shokuin2 : null,
        },
      }),
      shokuin3: Form.createFormField({
        value: {
          shokuin_num:
            props.dutyReport != null ? props.dutyReport.shokuinNum3 : 0,
          shokuin: props.dutyReport != null ? props.dutyReport.shokuin3 : null,
        },
      }),
      shokuin4: Form.createFormField({
        value: {
          shokuin_num:
            props.dutyReport != null ? props.dutyReport.shokuinNum4 : 0,
          shokuin: props.dutyReport != null ? props.dutyReport.shokuin4 : null,
        },
      }),
      shokuin5: Form.createFormField({
        value: {
          shokuin_num:
            props.dutyReport != null ? props.dutyReport.shokuinNum5 : 0,
          shokuin: props.dutyReport != null ? props.dutyReport.shokuin5 : null,
        },
      }),
      shokuin6: Form.createFormField({
        value: {
          shokuin_num:
            props.dutyReport != null ? props.dutyReport.shokuinNum6 : 0,
          shokuin: props.dutyReport != null ? props.dutyReport.shokuin6 : null,
        },
      }),
      working_status: Form.createFormField({
        value:
          props.dutyReport != null
            ? props.dutyReport.dutyReportKinmujoukyous
            : [],
      }),
    };
  },
})(DutyReportControl);

const mapStateToProps = (state) => {
  return {
    eventPlans: state.plan.eventPlans,
    notifyDisplay: state.dutyReport.notifyDisplay,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadEventPlans: (startDate, endDate) =>
      dispatch(loadEventPlans(startDate, endDate)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DutyReportForm);
