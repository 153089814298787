import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { ConfigName } from "../../../config-name";
import { ApiPaths, ConstSet } from "../../constants";
import jsonLocal from "../../data/jsonLocal.json";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils";
import { AuthActionTypes, ErrorActionTypes } from "../action_types";

function signin(username, password, code, isCheck, callback) {
  return async (dispatch) => {
    try {
      // Mark Current for detech check double login. If the same brower, cancel check
      const isDbTs = Date.now().toString();
      StorageUtils.setValue(ConfigName.ISDBTS, isDbTs);

      dispatch({ type: AuthActionTypes.LIFE_SIGNIN_REQUEST });

      const body = { username, password, isDbTs, code, isCheck };
      const data = await RequestUtils.callApi({
        method: "post",
        path: ApiPaths.Account.SignIn,
        body,
      });

      // Case display code input
      if (data != null && data.isCodePage === "true") {
        callback(data);
        return;
      }

      StorageUtils.setValue(ConfigName.SWLIFE_AUTH, {
        isUserLoggedIn: true,
        ...data,
      });
      StorageUtils.setValue(ConfigName.SWLIFE_TOKEN, { token: data.token });

      let formattedMenu = CommonUtils.parseMenu(data.menu);
      const token = data.token;
      const tid = jwtDecode(token).userInfo.tidCopy;
      const uid = jwtDecode(token).userInfo.id;
      const sessionTimeout = data.sessionTimeout;
      const isKeep = StorageUtils.getValue(ConfigName.SWLIFE_IS_KEEP);
      if (!isKeep) {
        StorageUtils.setValue(
          ConfigName.SWLIFE_IS_KEEP,
          jsonLocal
        );
      }
      StorageUtils.setValues({
        [ConfigName.SWLIFE_MENU]: {
          ...formattedMenu,
        },
        [ConfigName.SWLIFE_SWPSPS]: {
          started: true,
          menu: JSON.stringify(formattedMenu),
          tid,
          uid,
          sessionTimeout,
          doubleLogin: data.userInit,
          gmk: data.gmk,
          mfs: data.mfs,
          imaxpi: data.imaxpi,
        },
      });
      // reset rehaMonth to current time
      localStorage.setItem("rehaMonth", dayjs(new Date()).format(ConstSet.MONTH_FORMAT_STR));

      // StorageUtils.removeValues(["jwtToken"]);
      dispatch({ type: AuthActionTypes.LIFE_GET_MENU, ...formattedMenu });
      delete formattedMenu.menu;
      const valuesConfig = [];
      valuesConfig.push(formattedMenu.si);
      valuesConfig.push(formattedMenu.sn);
      valuesConfig.push(formattedMenu.sy);
      valuesConfig.push(formattedMenu.scn);
      valuesConfig.push(formattedMenu.ySid);
      valuesConfig.push(formattedMenu.iSid);
      dispatch({
        type: AuthActionTypes.LIFE_HANDLE_MENU,
        values: valuesConfig,
      });
      formattedMenu = { ...formattedMenu, tid, uid };
      data.menu = formattedMenu;
      delete data.token;

      dispatch({ type: AuthActionTypes.LIFE_SIGNIN_SUCCESS, ...data });

      // check and save cookies for 2Fa display
      if (isCheck && data?.checkSetting2FAJikan) {
        // maxAge
        const cookies = new Cookies();
        cookies.set("twoFaExpiredCookieL", username, { path: "/", maxAge: data.checkSetting2FAJikan * 60 * 60 });
      }

      window.location.reload();
    }
    catch (error) {
      // CLEAR mark Current for detech check double login. If the same brower, cancel check
      StorageUtils.removeValue(ConfigName.ISDBTS);

      dispatch({ type: AuthActionTypes.LIFE_SIGNIN_FAILURE, error });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });

      // window.location.reload();
    }
  };
}

function signout() {
  return async (dispatch) => {
    try {
      dispatch({ type: AuthActionTypes.SIGNOUT });

      const listSortRiyousya = StorageUtils.getValue(ConfigName.SWLIFE_LISTRIYOUSYA);
      if (listSortRiyousya) {
        const newlistSortRiyousya = { ...listSortRiyousya, monthSelect: dayjs(new Date()).format(ConstSet.MONTH_FORMAT) };
        StorageUtils.setValue(ConfigName.SWLIFE_LISTRIYOUSYA, newlistSortRiyousya);
      }
      StorageUtils.removeValues([
        ConfigName.SWLIFE_AUTH,
        ConfigName.SWLIFE_TOKEN,
      ]);
      window.location.reload();
      // await RequestUtils.callApi({
      //   method: "get",
      //   path: ApiPaths.Account.SignOut,
      // });
    }
    catch (error) {
      window.location.reload();
    }
  };
}

function getMaintainanceModeCreator() {
  return async (dispatch) => {
    try {
      dispatch({ type: AuthActionTypes.LIFE_MAINTAINANCE_MODE_REQUEST });

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Account.Mainainance,
      });

      const systemTitle = data && data.split("===") ? data.split("===")[0] : "LIFE";
      const mode = data && data.split("===") ? data.split("===")[1] : "";
      document.title = "SmileWeb+" + systemTitle;
      localStorage.setItem("lifeTitle", systemTitle);

      dispatch({
        type: AuthActionTypes.LIFE_MAINTAINANCE_MODE_SUCCESS,
        dataMode: mode,
        systemTitle,
      });
    }
    catch (error) {
      dispatch({
        type: AuthActionTypes.LIFE_MAINTAINANCE_MODE_FAILURE,
        error,
      });
    }
  };
}

export const AuthActions = {
  signin,
  signout,
  getMaintainanceModeCreator,
};
