import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Table, notification } from "antd";
import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";

import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { getPath } from "../../common/function_common/functionCommon";
import SearchDialog from "./UI/SearchDialog";

class KanriSettingMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      selectedRowKeys: [], // Check here to configure the default column
      displayList: [],
      visible: false,
      visibleDelConfirmPopup: false,
      path: getPath(),
    };

    this.reloadAll = this.reloadAll.bind(this);
  }

  componentDidMount() {
    // load data
    this.props.getSearchData("settingmaster");
    this.props.getSMList();

    // update from state
    const { smSelectedRows } = this.props;
    if (smSelectedRows && smSelectedRows.length > 0) {
      this.setState({
        selectedRowKeys: smSelectedRows,
      });
    }
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  /**
   * Reload all data from server
   *
   */
  async reloadAll() {
    //
    await this.props.clearSelectedRow("sm");

    //
    this.setState({
      selectedRowKeys: [],
    });

    this.props.getSMList();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });

    this.props.updateSelectedRows("sm", selectedRowKeys);
  };

  handleOkSearch = (text1, text2, text3) => {
    this.props.postSMList(text1, text2, text3);
    //
    this.setState({
      visible: false,
    });
  };

  handleCancelSearch = (e) => {
    //
    this.setState({
      visible: false,
    });
  };

  disSearchDialog = (e) => {
    //
    this.setState({
      visible: true,
    });
  };

  selectedDisplay = () => {
    const { selectedRowKeys } = this.state;

    // UPDATE ON LOCAL
    this.props.selectedSMDisplay(selectedRowKeys);
  };

  setDisplayList = () => {
    const displayList = [];
    const list = this.props.kanriSMList;
    for (const i in list) {
      displayList.push(list[i].id);
    }
    this.props.displayListSM(displayList);
  };

  handleDel = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.setState({
        visibleDelConfirmPopup: true,
      });
    }
  };

  handleOKDelConfim = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.props.deleteSM(selectedRowKeys.toString());
    }

    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  handleCancelDelConfim = () => {
    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  render() {
    const { kanriSMList, loading, searchData } = this.props;
    let { sortedInfo, filteredInfo, selectedRowKeys, visible, path } =
      this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    // prepare export data
    const dataExportCsv = [];
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      const tmpDataExportCsv = kanriSMList.filter((item) =>
        selectedRowKeys.includes(item.id)
      );
      if (tmpDataExportCsv && tmpDataExportCsv.length > 0)
        for (let i = 0; i < tmpDataExportCsv.length; i++) {
          dataExportCsv.push({
            id: tmpDataExportCsv[i].id,
            設定名: tmpDataExportCsv[i].settingName,
            内容: tmpDataExportCsv[i].naiyou,
          });
        }
    }

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id - b.id,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        ellipsis: true,
        width: 80,
      },
      {
        title: "設定名",
        dataIndex: "settingName",
        key: "settingName",
        sorter: (a, b) =>
          (a.settingName ? a.settingName : "") <
            (b.settingName ? b.settingName : "")
            ? -1
            : 1,
        sortOrder: sortedInfo.columnKey === "settingName" && sortedInfo.order,
        ellipsis: true,
        width: 120,
      },
      {
        title: "内容",
        dataIndex: "naiyou",
        key: "naiyou",
        sorter: (a, b) =>
          (a.naiyou ? a.naiyou : "") < (b.naiyou ? b.naiyou : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "naiyou" && sortedInfo.order,
        ellipsis: true,
        width: 500,
      },
      {
        title: "アクション",
        key: "operation",
        // fixed: "right",
        render: (text, record, index) => (
          <div>
            <Button type="primary" onClick={this.setDisplayList}>
              <Link to={`${path}/kanri/settingmaster/${record.id}`}>編集</Link>
            </Button>
          </div>
        ),
        width: 100,
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const menu = (
      <Menu>
        <Menu.Item>
          <CSVLink
            filename={`設定マスタ${Date.now()}.csv`}
            data={dataExportCsv}
            className="btn btn-primary"
            disabled={!(dataExportCsv && dataExportCsv.length > 0)}
          >
            ダウンロード
          </CSVLink>
        </Menu.Item>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" onClick={this.handleDel}>
            削除
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      (<KanriLayoutContainer router="settingmaster" breadcrumbLink={"/kanri/settingmaster"} breadcrumbText={"セッティングマスタ"}>
        <div className="kanri-user-page">
          <div className="table-operations">
            <Button type="primary" onClick={this.reloadAll}>
              全表示
            </Button>
            <Button type="primary" onClick={this.disSearchDialog}>
              検索
            </Button>
            <Button type="primary" onClick={this.selectedDisplay}>
              選択表示
            </Button>
            <Button type="primary">
              <Link to={`${path}/kanri/settingmaster/new`}>新規登録</Link>
            </Button>
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button type="primary">
                その他機能 <CaretDownOutlined />
              </Button>
            </Dropdown>

            {/* <Button onClick={this.clearFilters}>Clear filters</Button> */}
            {/* <Button type='primary' onClick={this.clearAll}>クリア</Button> */}
          </div>
          <div className="kanri-table">
            <Table
              columns={columns}
              dataSource={kanriSMList}
              rowSelection={rowSelection}
              className="table"
              rowKey={"id"}
              pagination={{ pageSize: 1000 }}
              onChange={this.handleChange}
              size="small"
              bordered
              loading={loading}
              scroll={{ x: 1100 }}
            />
          </div>
        </div>
        <SearchDialog
          title={"検索画面"}
          subTitle1={"対象項目"}
          subTitle2={"検索する文字"}
          subTitle3={"検索方法"}
          visible={visible}
          handleOk={this.handleOkSearch}
          handleCancel={this.handleCancelSearch}
          searchData={searchData}
        />
        <Modal
          title="処理確認"
          open={this.state.visibleDelConfirmPopup}
          onOk={this.handleOKDelConfim}
          onCancel={this.handleCancelDelConfim}
          okText="OK"
          cancelText="キャンセル"
        >
          <p>削除してもよろしいでしょうか？</p>
        </Modal>
      </KanriLayoutContainer>)
    );
  }
}

export default KanriSettingMaster;
