export const AuthActionTypes = {
  SIGNIN_REQUEST: "SIGNIN_REQUEST",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "SIGNIN_FAILURE",
  SIGNOUT: "SIGNOUT",
  MAINTAINANCE_MODE_REQUEST: "MAINTAINANCE_MODE_REQUEST",
  MAINTAINANCE_MODE_SUCCESS: "MAINTAINANCE_MODE_SUCCESS",
  MAINTAINANCE_MODE_FAILURE: "MAINTAINANCE_MODE_FAILURE",
  GET_MENU: "GET_MENU",
  HANDLE_MENU: "HANDLE_MENU",
};
