import React from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Button, Modal } from "antd";
import { getDateInMonth } from "../../../common/function_common/functionCommon";
import CommentKB from "../../../common/model-kiroku/CommentKB";

export const InputUserPlanDialog = Form.create()(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        yotei: props.yotei,
        yoteiChange: false,
        sonota: props.sonota,
        sonotaChange: false,
      };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      if (
        newProps.yotei !== this.props.yotei ||
        newProps.sonota !== this.props.sonota
      ) {
        this.setState({
          yotei: newProps.yotei,
          sonota: newProps.sonota,
        });
      }
    }

    handleOnChangeYotei(value, type) {
      this.setState({
        yotei:
          type && type === "select" && this.state.yotei
            ? this.state.yotei + "、" + value
            : value,
      });
    }

    handleOnChangeSonota(value, type) {
      this.setState({
        sonota:
          type && type === "select" && this.state.sonota
            ? this.state.sonota + "、" + value
            : value,
      });
    }

    handleSubmit() {
      this.props.onSubmit({
        yotei: this.state.yotei,
        sonota: this.state.sonota,
      });
    }

    handleCancel() {
      this.setState({
        yotei: this.props.yotei,
        sonota: this.props.sonota,
      });
      this.props.onCancel();
    }

    render() {
      const { visible, date } = this.props;
      let dateString = date
        ? date +
          "（" +
          getDateInMonth(
            date.split("-")[0],
            date.split("-")[1],
            date.split("-")[2]
          ) +
          "）"
        : null;
      return (
        (<Modal
          open={visible}
          title={dateString ? dateString + "の予定入力" : "予定入力"}
          onCancel={() => this.handleCancel()}
          footer={[
            <Button key="submit" onClick={() => this.handleSubmit()}>
              OK
            </Button>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item label="予定1" className={"yotei-input"}>
              <CommentKB
                yougoMasterEditState={(index) =>
                  this.props.yougoMasterEditState(index)
                }
                yougoMasterEditCancel={(index) =>
                  this.props.yougoMasterEditCancel(index)
                }
                yougoMasterEditSave={(naiyou) =>
                  this.props.yougoMasterEditSave(naiyou)
                }
                defaultValue={this.state.yotei}
                handleTextareaKB={(value, type) =>
                  this.handleOnChangeYotei(value, type)
                }
                yougoMasterNa={this.props.yougoMasterNaiyou}
              />
            </Form.Item>
            <Form.Item label="予定2" className={"yotei-input"}>
              <CommentKB
                yougoMasterEditState={(index) =>
                  this.props.yougoMasterEditState(index)
                }
                yougoMasterEditCancel={(index) =>
                  this.props.yougoMasterEditCancel(index)
                }
                yougoMasterEditSave={(naiyou) =>
                  this.props.yougoMasterEditSave(naiyou)
                }
                defaultValue={this.state.sonota}
                handleTextareaKB={(value, type) =>
                  this.handleOnChangeSonota(value, type)
                }
                yougoMasterNa={this.props.yougoMasterNaiyou}
              />
            </Form.Item>
          </Form>
        </Modal>)
      );
    }
  }
);
