import React, { useState } from "react";
import { Modal, Table, Button } from "antd";
import PropTypes from "prop-types";

LastQuoteModalWeekly.propTypes = {
  selected: PropTypes.object,
};

export function LastQuoteModalWeekly({
  data,
  handleSelectLastQuote,
  hideModalLastQuote,
  visible,
  title,
  loading,
}) {
  const [selected, setSelected] = useState(null);

  const handleOk = () => {
    if (selected) {
      handleSelectLastQuote(selected);
      hideModalLastQuote();
    }
  };

  const handleCancel = () => {
    hideModalLastQuote();
  };

  const handleSelectLastquote = (record) => {
    setSelected(record);
  };

  const columns = [
    {
      // title: "照会日",
      dataIndex: "planDate",
      key: "planDate",
    },
  ];

  return (
    (<Modal
      open={visible}
      title={title}
      onCancel={() => handleCancel()}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={() => handleCancel()}
        >
          キャンセル
        </Button>,
        <Button
          key="back"
          type="primary"
          onClick={() => handleOk()}
          disabled={data && data.length === 0}
        >
          OK
        </Button>,
      ]}
      width={500}
    >
      <Table
        // id={"last-quote-table"}
        loading={loading}
        bordered={true}
        size={"small"}
        columns={columns}
        pagination={false}
        rowKey={"id"}
        dataSource={data}
        showHeader={false}
        onRow={(record) => {
          return {
            onClick: () => handleSelectLastquote(record),
          };
        }}
        rowClassName={(record) =>
          selected != null && record.id === selected.id
            ? "pljp-table-row-hover"
            : "pointer-row"
        }
      />
    </Modal>)
  );
}
