import {RESET_STATUS, KEEP_VALUE, GET_MEDICAL_HEALTH, UPDATE_MEDICAL_HEALTH_SUCCESS, UPDATE_MEDICAL_HEALTH_FAIL} from '../../actions/medicalHealthActions/actionName';
import { notification } from "antd/lib/index";

export default function MedicalHealthReducers(state = {
}, action) {
    switch (action.type) {
        case RESET_STATUS: 
            return {
                ...state,
                notifyContentEdit: {}
            }

        case KEEP_VALUE:
            return {
                ...state,
                data: action.data
            }
        case GET_MEDICAL_HEALTH:
            return {
                ...state,
                data: action.data,
                loading: false
            };
        case UPDATE_MEDICAL_HEALTH_SUCCESS:
            notification["success"]({
                message: '保存しました',
                placement: "topRight",
                duration: 3,
                style: { whiteSpace: "pre-wrap" },
              });
            return {
                ...state,
                data: action.data,
                loading: false,
                notifyContentEdit: { type: "success" }
            };
            case UPDATE_MEDICAL_HEALTH_FAIL:
                notification["error"]({
                    message: action.error.message,
                    placement: "topRight",
                    duration: 3,
                    style: { whiteSpace: "pre-wrap" },
                  });
            return {
               state,
               data: action.data,
               loading: false,
               notifyContentEdit: { type: "error" }
            };
        
        default:
            return state;
    }
}