/* eslint-disable react/no-direct-mutation-state */
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Checkbox, Col, Modal, Pagination, Row, Table } from "antd";
import _ from "lodash";
import React from "react";
import { ConstSet } from "../../../common/configs/constset";
import { SyokujisenForm } from "./SyokujisenForm";

export default class Syokujisen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandRowIndex: 0,
      deleteVisible: false,
      delRecordId: 0,
      newId: -1,
      rowIndex: null,
      checked: false,
      inputVisible: false,
      recordInput: [],
    };

    // bind place
  }

  expandRow = (recordId) => {
    const { expandRowIndex } = this.state;

    if (expandRowIndex === recordId) {
      this.setState({
        expandRowIndex: 0,
      });
    }
    else {
      this.setState({
        expandRowIndex: recordId,
      });
    }
  };

  showDeleteConfirm = (recordId, index) => {
    this.setState({
      deleteVisible: true,
      delRecordId: recordId,
      rowIndex: index,
      checked: false,
    });
  };

  handleEdit = (record) => {
    this.setState({
      inputVisible: true,
      recordInput: record,
    });
  };

  closeDeleteConfirm = () => {
    this.setState({
      deleteVisible: false,
      delRecordId: 0,
      rowIndex: null,
      checked: false,
    });
  };

  handleConfirmDelete = () => {
    const { delRecordId, rowIndex } = this.state;
    const id = _.clone(delRecordId);

    this.props.handleDelSyokujisen(id, rowIndex);

    this.setState({
      deleteVisible: false,
      delRecordId: 0,
      rowIndex: null,
      checked: false,
      expandRowIndex: 0,
    });
  };

  handleChangeCheckbox = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked,
    });
  };

  handleAddNew = () => {
    this.setState({
      inputVisible: true,
      recordInput: [],
    });
  };

  closeInput = () => {
    this.setState({
      inputVisible: false,
      recordInput: [],
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  handleInput = () => {
    // e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.update(values);
    });
  };

  update = (values) => {
    if (!values.id) {
      values.id = this.state.newId;
    }
    if (values.date) {
      values.date = values.date.format(ConstSet.DATE_FORMAT);
    }

    this.props.updateSyokujisen(values);

    this.setState({
      inputVisible: false,
      recordInput: [],
      expandRowIndex: 0,
      newId: --this.state.newId,
    });
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (values.date) {
        values.date = values.date.format(ConstSet.DATE_FORMAT);
      }
      this.setState({
        recordInput: values,
      });
    });
    this.props.yougoMasterUpdate(key, naiyou);
  };

  onChangePag = (page) => {
    this.props.onChangeSyokujiPag(page);
  };

  render() {
    const {
      data,
      riyousyaId,
      shushoku,
      fukushoku,
      shokujisenbunrui,
      shokujisenbikou,
      page,
      pageTotal,
    } = this.props;
    const { expandRowIndex, recordInput } = this.state;

    const columns = [
      {
        dataIndex: "1",
        key: "1",
        render: (text, record, index) => {
          return (
            <div>
              <Row
                onClick={() => this.expandRow(index + 1)}
                className="riyou-meal-page-click-area"
              >
                <Col span={8}>
                  <span>主食 </span>
                  <span>{record.stapleFood}</span>
                </Col>
                <Col span={8}>
                  <span>副食 </span>
                  <span>{record.sideFood}</span>
                </Col>
                <Col span={8}>{record.syokujiKubun}</Col>
                {expandRowIndex && expandRowIndex === index + 1 ? (
                  <div>
                    <Col span={24}>
                      <Row>
                        <span>備考 </span>
                        <span>{record.tokki}</span>
                      </Row>
                    </Col>
                  </div>
                ) : null}
              </Row>
              {expandRowIndex && expandRowIndex === index + 1 ? (
                <div style={{ float: "right", padding: 8 }}>
                  <Button
                    icon={<LegacyIcon type={"delete"} />}
                    style={{ color: "white", backgroundColor: "gray" }}
                    onClick={() => this.showDeleteConfirm(record.id, index)}
                  >
                    {"削除"}
                  </Button>
                  <Button
                    icon={<LegacyIcon type={"edit"} />}
                    style={{ color: "white", backgroundColor: "#00838F" }}
                    onClick={() => this.handleEdit(record)}
                  >
                    {"修正"}
                  </Button>
                </div>
              ) : null}
            </div>
          );
        },
      },
    ];

    return (
      (<div>
        <Row style={{ justifyContent: "flex-end" }}>
          <Button
            icon={<LegacyIcon type={"plus"} />}
            style={{
              color: "white",
              backgroundColor: "#dc0606",
              marginBottom: 8,
            }}
            onClick={() => this.handleAddNew()}
          >
            {"追加"}
          </Button>
        </Row>
        <Table
          dataSource={data}
          columns={columns}
          showHeader={false}
          className="riyou-meal-page"
          rowKey={(record, index) => index}
          pagination={false}
        />
        <Pagination
          onChange={this.onChangePag}
          total={pageTotal * 10}
          pageSize={10}
          current={page}
          style={{ textAlign: "right", marginTop: 16 }}
        />
        {/* modal del */}
        <Modal
          open={this.state.deleteVisible}
          title="削除確認（同意するにチェックしてOKボタンを押してください）"
          onCancel={() => this.closeDeleteConfirm()}
          footer={[
            <Button
              disabled={!this.state.checked}
              key="delete"
              type={"primary"}
              onClick={() => this.handleConfirmDelete()}
            >
              OK
            </Button>,
            <Button
              key="canceldelete"
              onClick={() => this.closeDeleteConfirm()}
            >
              Cancel
            </Button>,
          ]}
        >
          <div style={{ backgroundColor: "aliceblue" }}>
            <Checkbox
              checked={this.state.checked}
              onChange={() => this.handleChangeCheckbox()}
              value={this.state.checked}
            >
              {"削除に同意する"}
            </Checkbox>
          </div>
        </Modal>
        {/* modal input */}
        <Modal
          className="syokujisen-input-form"
          open={this.state.inputVisible}
          title={false}
          closable={false}
          maskClosable={false}
          onCancel={() => this.closeInput()}
          footer={[
            <Button
              key="input"
              type={"primary"}
              onClick={() => this.handleInput()}
            >
              OK
            </Button>,
            <Button key="cancelInput" onClick={() => this.closeInput()}>
              キャンセル
            </Button>,
          ]}
        >
          <SyokujisenForm
            data={recordInput}
            wrappedComponentRef={(formRef) => this.saveFormRef(formRef)}
            riyousyaId={riyousyaId}
            shushoku={shushoku}
            fukushoku={fukushoku}
            shokujisenbunrui={shokujisenbunrui}
            shokujisenbikou={shokujisenbikou}
            yougoMasterUpdate={this.yougoMasterUpdate}
          />
        </Modal>
      </div>)
    );
  }
}
