import { connect } from "react-redux";

import Kanri from "./Kanri";
import KanriFile from "./KanriFile";
import KanriFileSave from "./KanriFileSave";
import KanriKaigoYougoMaster from "./KanriKaigoYougoMaster";
import KanriKaigoYougoMasterSave from "./KanriKaigoYougoMasterSave";
import KanriLifeYougoMaster from "./KanriLifeYougoMaster";
import KanriLifeYougoMasterSave from "./KanriLifeYougoMasterSave";
import KanriSettingMaster from "./KanriSettingMaster";
import KanriSettingMasterSave from "./KanriSettingMasterSave";
import KanriTantoMaster from "./KanriTantoMaster";
import KanriTantoMasterSave from "./KanriTantoMasterSave";
import KanriUser from "./KanriUser";
import KanriUserRiyousyaLink from "./KanriUserRiyousyaLink";
import KanriUserRiyousyaLinkSave from "./KanriUserRiyousyaLinkSave";
import KanriUserSave from "./KanriUserSave";
import KanriYougoMaster from "./KanriYougoMaster";
import KanriYougoMasterSave from "./KanriYougoMasterSave";

import {
  clearSelectedRowCreator,
  createDengonCreator,
  createFileCreator,
  createKYMCreator,
  createLYMCreator,
  createMoshiokuriCreator,
  createOpenSLCreator,
  createSMCreator,
  createTMCreator,
  createURLCreator,
  createUserCreator,
  createYMCreator,
  deleteDengonCreator,
  deleteFileCreator,
  deleteKYMCreator,
  deleteLYMCreator,
  deleteMoshiokuriCreator,
  deleteOpenSLCreator,
  deleteSMCreator,
  deleteTMCreator,
  deleteURLCreator,
  deleteUserCreator,
  deleteYMCreator,
  displayListCreator,
  displayListLYMCreator,
  displayListSMCreator,
  displayListTMCreator,
  displayListURLCreator,
  displayListYMCreator,
  getDengonListCreator,
  getDengonSingleRecordCreator,
  getFileListCreator,
  getFileSingleRecordCreator,
  getKYMListCreator,
  getKYMSingleRecordCreator,
  getLYMListCreator,
  getMoshiokuriListCreator,
  getMoshiokuriSingleRecordCreator,
  getOpenSLListCreator,
  getOpenSLSingleRecordCreator,
  getSMListCreator,
  getSearchDataCreator,
  getTMListCreator,
  getURLListCreator,
  getUserListCreator,
  getYMListCreator,
  postDengonListCreator,
  postFileListCreator,
  postKYMListCreator,
  postLYMListCreator,
  postMoshiokuriListCreator,
  postOpenSLListCreator,
  postSMListCreator,
  postTMListCreator,
  postURLListCreator,
  postUserListCreator,
  postYMListCreator,
  recordMarkCreator,
  selectedDisplayCreator,
  selectedDisplayDengonCreator,
  selectedDisplayFileCreator,
  selectedDisplayKYMCreator,
  selectedDisplayLYMCreator,
  selectedDisplayMoshiokuriCreator,
  selectedDisplayOpenSLCreator,
  selectedDisplaySMCreator,
  selectedDisplayTMCreator,
  selectedDisplayURLCreator,
  selectedDisplayYMCreator,
  updateDengonCreator,
  updateFileCreator,
  updateKYMCreator,
  updateLYMCreator,
  updateMoshiokuriCreator,
  updateOpenSLCreator,
  updateSMCreator,
  updateSelectedRowsCreator,
  updateTMCreator,
  updateURLCreator,
  updateUserCreator,
  updateYMCreator
} from "../../actions/kanriActions/actionCreators";
import KanriDengonData from "./KanriDengonData";
import KanriDengonDataSave from "./KanriDengonDataSave";
import KanriKangoKiroku from "./KanriKangoKiroku";
import KanriKangoNaiyou from "./KanriKangoNaiyou";
import KanriMoshiokuriLink from "./KanriMoshiokuriLink";
import KanriMoshiokuriLinkSave from "./KanriMoshiokuriLinkSave";
import KanriOpenScreenLog from "./KanriOpenScreenLog";
import KanriOpenScreenLogSave from "./KanriOpenScreenLogSave";

import {
  addMemberToGroupCreator,
  addNewSendGroupCreator,
  delMemberFromGroupCreator,
  getAllSendGroupCreator,
  sendGroupCreator,
  updateSendGroupToServerCreator,
} from "../../actions/sendGroupActions/actionCreators";
import {
  groupFilterCreator,
  searchNameCreator,
  userListCreator,
} from "../../actions/userListActions/actionCreators";

import KanriSendGroup from "./KanriSendGroup";

const mapStateToProps = (state) => {
  return {
    //
    loading: state.kanri.loading,
    notifyDisplay: state.kanri.notifyDisplay,
    notifyContent: state.kanri.notifyContent,

    //
    searchData: state.kanri.searchData,

    //
    kanriUserList: state.kanri.kanriUserList,
    kanriFileList: state.kanri.kanriFileList,
    kanriFile: state.kanri.kanriFile,
    isFileSelectedDisplay: state.kanri.isFileSelectedDisplay,
    kanriDengonList: state.kanri.kanriDengonList,
    kanriDengon: state.kanri.kanriDengon,
    isDengonSelectedDisplay: state.kanri.isDengonSelectedDisplay,
    kanriMoshiokuriList: state.kanri.kanriMoshiokuriList,
    kanriMoshiokuri: state.kanri.kanriMoshiokuri,
    isMoshiokuriSelectedDisplay: state.kanri.isMoshiokuriSelectedDisplay,
    kanriOpenSLList: state.kanri.kanriOpenSLList,
    kanriOpenSL: state.kanri.kanriOpenSL,
    isOpenSLSelectedDisplay: state.kanri.isOpenSLSelectedDisplay,
    kanriKYMList: state.kanri.kanriKYMList,
    kanriKYM: state.kanri.kanriKYM,
    isKYMSelectedDisplay: state.kanri.isKYMSelectedDisplay,
    kanriSMList: state.kanri.kanriSMList,
    kanriYMList: state.kanri.kanriYMList,
    kanriLYMList: state.kanri.kanriLYMList,
    kanriTMList: state.kanri.kanriTMList,
    kanriURLList: state.kanri.kanriURLList,

    //
    userDisplayList: state.kanri.userDisplayList,
    fileDisplayList: state.kanri.fileDisplayList,
    dengonDisplayList: state.kanri.dengonDisplayList,
    moshiokuriDisplayList: state.kanri.moshiokuriDisplayList,
    openSLDisplayList: state.kanri.openSLDisplayList,
    kymDisplayList: state.kanri.kymDisplayList,
    smDisplayList: state.kanri.smDisplayList,
    ymDisplayList: state.kanri.ymDisplayList,
    lymDisplayList: state.kanri.lymDisplayList,
    tmDisplayList: state.kanri.tmDisplayList,
    urlDisplayList: state.kanri.urlDisplayList,

    //
    userSelectedRows: state.kanri.userSelectedRows,
    fileSelectedRows: state.kanri.fileSelectedRows,
    dengonSelectedRows: state.kanri.dengonSelectedRows,
    moshiokuriSelectedRows: state.kanri.moshiokuriSelectedRows,
    openSLSelectedRows: state.kanri.openSLSelectedRows,
    kymSelectedRows: state.kanri.kymSelectedRows,
    smSelectedRows: state.kanri.smSelectedRows,
    ymSelectedRows: state.kanri.ymSelectedRows,
    lymSelectedRows: state.kanri.lymSelectedRows,
    tmSelectedRows: state.kanri.tmSelectedRows,
    urlSelectedRows: state.kanri.urlSelectedRows,

    currentFile: state.kanri.currentFile,
    pageSizeFile: state.kanri.pageSizeFile,
    totalFile: state.kanri.totalFile,
    currentDengon: state.kanri.currentDengon,
    pageSizeDengon: state.kanri.pageSizeDengon,
    totalDengon: state.kanri.totalDengon,
    currentMoshiokuri: state.kanri.currentMoshiokuri,
    pageSizeMoshiokuri: state.kanri.pageSizeMoshiokuri,
    totalMoshiokuri: state.kanri.totalMoshiokuri,
    currentOpenSL: state.kanri.currentOpenSL,
    pageSizeOpenSL: state.kanri.pageSizeOpenSL,
    totalOpenSL: state.kanri.totalOpenSL,
    currentKYM: state.kanri.currentKYM,
    pageSizeKYM: state.kanri.pageSizeKYM,
    totalKYM: state.kanri.totalKYM,

    //
    users: state.userList.users,
    groups: state.sendGroup.groups,
    groupRecords: state.sendGroup.groupRecords,
    groupName: state.userList.groupName,
    name: state.userList.name,
    isUpdateGroup: state.sendGroup.isUpdateGroup,
    delIdsSendGroup: state.sendGroup.delIds,
    userExistedSendGroup: state.sendGroup.userExisted,
    isApiLoadingSG: state.sendGroup.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchData: (type) => dispatch(getSearchDataCreator(type)),

    getUserList: (text1, text2, text3) =>
      dispatch(getUserListCreator(text1, text2, text3)),
    postUserList: (text1, text2, text3) =>
      dispatch(postUserListCreator(text1, text2, text3)),
    selectedDisplay: (ids) => dispatch(selectedDisplayCreator(ids)),
    displayList: (ids) => dispatch(displayListCreator(ids)),
    updateUser: (data) => dispatch(updateUserCreator(data)),
    createUser: (data) => dispatch(createUserCreator(data)),
    deleteUser: (ids) => dispatch(deleteUserCreator(ids)),

    getFileList: (current, pageSize, text1, text2, text3, orderBy) =>
      dispatch(
        getFileListCreator(current, pageSize, text1, text2, text3, orderBy)
      ),
    getFileSingleRecord: (id, ids, type) =>
      dispatch(getFileSingleRecordCreator(id, ids, type)),
    postFileList: (current, pageSize, text1, text2, text3) =>
      dispatch(postFileListCreator(current, pageSize, text1, text2, text3)),
    selectedFileDisplay: (ids) => dispatch(selectedDisplayFileCreator(ids)),
    updateFile: (data) => dispatch(updateFileCreator(data)),
    createFile: (data) => dispatch(createFileCreator(data)),
    deleteFile: (ids) => dispatch(deleteFileCreator(ids)),

    getDengonList: (current, pageSize, text1, text2, text3, orderBy) =>
      dispatch(
        getDengonListCreator(current, pageSize, text1, text2, text3, orderBy)
      ),
    getDengonSingleRecord: (id, ids, type) =>
      dispatch(getDengonSingleRecordCreator(id, ids, type)),
    postDengonList: (current, pageSize, text1, text2, text3) =>
      dispatch(postDengonListCreator(current, pageSize, text1, text2, text3)),
    selectedDengonDisplay: (ids) => dispatch(selectedDisplayDengonCreator(ids)),
    updateDengon: (data) => dispatch(updateDengonCreator(data)),
    createDengon: (data) => dispatch(createDengonCreator(data)),
    deleteDengon: (ids) => dispatch(deleteDengonCreator(ids)),

    getMoshiokuriList: (current, pageSize, text1, text2, text3, orderBy) =>
      dispatch(
        getMoshiokuriListCreator(
          current,
          pageSize,
          text1,
          text2,
          text3,
          orderBy
        )
      ),
    getMoshiokuriSingleRecord: (id, ids, type) =>
      dispatch(getMoshiokuriSingleRecordCreator(id, ids, type)),
    postMoshiokuriList: (current, pageSize, text1, text2, text3) =>
      dispatch(
        postMoshiokuriListCreator(current, pageSize, text1, text2, text3)
      ),
    selectedMoshiokuriDisplay: (ids) =>
      dispatch(selectedDisplayMoshiokuriCreator(ids)),
    updateMoshiokuri: (data) => dispatch(updateMoshiokuriCreator(data)),
    createMoshiokuri: (data) => dispatch(createMoshiokuriCreator(data)),
    deleteMoshiokuri: (ids) => dispatch(deleteMoshiokuriCreator(ids)),

    getOpenSLList: (current, pageSize, text1, text2, text3, orderBy) =>
      dispatch(
        getOpenSLListCreator(current, pageSize, text1, text2, text3, orderBy)
      ),
    getOpenSLSingleRecord: (id, ids, type) =>
      dispatch(getOpenSLSingleRecordCreator(id, ids, type)),
    postOpenSLList: (current, pageSize, text1, text2, text3) =>
      dispatch(postOpenSLListCreator(current, pageSize, text1, text2, text3)),
    selectedOpenSLDisplay: (ids) => dispatch(selectedDisplayOpenSLCreator(ids)),
    updateOpenSL: (data) => dispatch(updateOpenSLCreator(data)),
    createOpenSL: (data) => dispatch(createOpenSLCreator(data)),
    deleteOpenSL: (ids) => dispatch(deleteOpenSLCreator(ids)),

    getKYMList: (current, pageSize, text1, text2, text3, orderBy) =>
      dispatch(
        getKYMListCreator(current, pageSize, text1, text2, text3, orderBy)
      ),
    getKYMSingleRecord: (id, ids, type) =>
      dispatch(getKYMSingleRecordCreator(id, ids, type)),
    postKYMList: (current, pageSize, text1, text2, text3) =>
      dispatch(postKYMListCreator(current, pageSize, text1, text2, text3)),
    selectedKYMDisplay: (ids) => dispatch(selectedDisplayKYMCreator(ids)),
    updateKYM: (data) => dispatch(updateKYMCreator(data)),
    createKYM: (data) => dispatch(createKYMCreator(data)),
    deleteKYM: (ids) => dispatch(deleteKYMCreator(ids)),

    getSMList: (text1, text2, text3) =>
      dispatch(getSMListCreator(text1, text2, text3)),
    postSMList: (text1, text2, text3) =>
      dispatch(postSMListCreator(text1, text2, text3)),
    selectedSMDisplay: (ids) => dispatch(selectedDisplaySMCreator(ids)),
    displayListSM: (ids) => dispatch(displayListSMCreator(ids)),
    updateSM: (data) => dispatch(updateSMCreator(data)),
    createSM: (data) => dispatch(createSMCreator(data)),
    deleteSM: (ids) => dispatch(deleteSMCreator(ids)),

    getYMList: (text1, text2, text3) =>
      dispatch(getYMListCreator(text1, text2, text3)),
    postYMList: (text1, text2, text3) =>
      dispatch(postYMListCreator(text1, text2, text3)),
    selectedYMDisplay: (ids) => dispatch(selectedDisplayYMCreator(ids)),
    displayListYM: (ids) => dispatch(displayListYMCreator(ids)),
    updateYM: (data) => dispatch(updateYMCreator(data)),
    createYM: (data) => dispatch(createYMCreator(data)),
    deleteYM: (ids) => dispatch(deleteYMCreator(ids)),

    getLYMList: (text1, text2, text3) =>
      dispatch(getLYMListCreator(text1, text2, text3)),
    postLYMList: (text1, text2, text3) =>
      dispatch(postLYMListCreator(text1, text2, text3)),
    selectedLYMDisplay: (ids) => dispatch(selectedDisplayLYMCreator(ids)),
    displayListLYM: (ids) => dispatch(displayListLYMCreator(ids)),
    updateLYM: (data) => dispatch(updateLYMCreator(data)),
    createLYM: (data) => dispatch(createLYMCreator(data)),
    deleteLYM: (ids) => dispatch(deleteLYMCreator(ids)),

    getTMList: (text1, text2, text3) =>
      dispatch(getTMListCreator(text1, text2, text3)),
    postTMList: (text1, text2, text3) =>
      dispatch(postTMListCreator(text1, text2, text3)),
    selectedTMDisplay: (ids) => dispatch(selectedDisplayTMCreator(ids)),
    displayListTM: (ids) => dispatch(displayListTMCreator(ids)),
    updateTM: (data) => dispatch(updateTMCreator(data)),
    createTM: (data) => dispatch(createTMCreator(data)),
    deleteTM: (ids) => dispatch(deleteTMCreator(ids)),

    getURLList: (text1, text2, text3) =>
      dispatch(getURLListCreator(text1, text2, text3)),
    postURLList: (text1, text2, text3) =>
      dispatch(postURLListCreator(text1, text2, text3)),
    selectedURLDisplay: (ids) => dispatch(selectedDisplayURLCreator(ids)),
    displayListURL: (ids) => dispatch(displayListURLCreator(ids)),
    updateURL: (data) => dispatch(updateURLCreator(data)),
    createURL: (data) => dispatch(createURLCreator(data)),
    deleteURL: (ids) => dispatch(deleteURLCreator(ids)),

    clearSelectedRow: (type) => dispatch(clearSelectedRowCreator(type)),
    updateSelectedRows: (type, selectedRows) =>
      dispatch(updateSelectedRowsCreator(type, selectedRows)),
    recordMark: (typeF, typeA, id) =>
      dispatch(recordMarkCreator(typeF, typeA, id)),

    userList: () => dispatch(userListCreator()),
    sendGroup: () => dispatch(sendGroupCreator()),
    groupFilter: (filter) => dispatch(groupFilterCreator(filter)),
    searchMember: (text) => dispatch(searchNameCreator(text)),
    getAllSendGroup: () => dispatch(getAllSendGroupCreator()),
    addMemberToGroup: (checkListUsers, selectedGroup) =>
      dispatch(addMemberToGroupCreator(checkListUsers, selectedGroup)),
    delMemberFromGroup: (checkListGroups, selectedGroup) =>
      dispatch(delMemberFromGroupCreator(checkListGroups, selectedGroup)),
    addNewSendGroup: (text) => dispatch(addNewSendGroupCreator(text)),
    updateSendGroupToServer: (groupRecords, delIdsSendGroup) =>
      dispatch(updateSendGroupToServerCreator(groupRecords, delIdsSendGroup)),
  };
};

export const KanriContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Kanri);

export const KanriUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriUser);
export const KanriUserSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriUserSave);
export const KanriSettingMasterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriSettingMaster);
export const KanriSettingMasterSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriSettingMasterSave);
export const KanriYougoMasterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriYougoMaster);
export const KanriYougoMasterSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriYougoMasterSave);
export const KanriLifeYougoMasterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriLifeYougoMaster);
export const KanriLifeYougoMasterSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriLifeYougoMasterSave);
export const KanriKaigoYougoMasterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriKaigoYougoMaster);
export const KanriKaigoYougoMasterSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriKaigoYougoMasterSave);
export const KanriTantoMasterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriTantoMaster);
export const KanriTantoMasterSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriTantoMasterSave);
export const KanriUserRiyousyaLinkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriUserRiyousyaLink);
export const KanriUserRiyousyaLinkSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriUserRiyousyaLinkSave);
export const KanriFileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriFile);
export const KanriFileSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriFileSave);
export const KanriSendGroupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriSendGroup);

export const KanriKangoNaiyouContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriKangoNaiyou);
export const KanriKangoKirokuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriKangoKiroku);
export const KanriDengonDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriDengonData);
export const KanriDengonDataSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriDengonDataSave);

export const KanriMoshiokuriLinkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriMoshiokuriLink);
export const KanriMoshiokuriLinkSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriMoshiokuriLinkSave);

export const KanriOpenScreenLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriOpenScreenLog);
export const KanriOpenScreenLogSaveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KanriOpenScreenLogSave);
