export const KokuActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  KOKU_LIFE_YOUGO_SUCCESS: "KOKU_LIFE_YOUGO_SUCCESS",
  KOKU_LIFE_YOUGO_ERROR: "KOKU_LIFE_YOUGO_ERROR",

  LOAD_KOKU_SUCCESS: "LOAD_KOKU_SUCCESS",
  LOAD_KOKU_ERROR: "LOAD_KOKU_ERROR",

  REMOVE_KOKU_REQUEST: "REMOVE_KOKU_REQUEST",
  REMOVE_KOKU_SUCCESS: "REMOVE_KOKU_SUCCESS",
  REMOVE_KOKU_ERROR: "REMOVE_KOKU_ERROR",

  SAVE_KOKU_SIEN_SUCCESS: "SAVE_KOKU_SIEN_SUCCESS",
  SAVE_KOKU_SIEN_ERROR: "SAVE_KOKU_SIEN_ERROR",

  LOAD_KOKU_TO_QUOTE_SUCCESS: "LOAD_KOKU_TO_QUOTE_SUCCESS",
  LOAD_KOKU_TO_QUOTE: "LOAD_KOKU_TO_QUOTE",

  SET_NEW_DATA: "SET_NEW_DATA",
  GET_LIST_IDS_KOKU: "GET_LIST_IDS_KOKU",
};
