/* eslint-disable no-case-declarations */
import {
  LOAD_KYOTAKU_PLAN2_BY_ID_SUCCESS,
  LOAD_KYOTAKU_PLAN2_BY_ID_ERROR,
  LOAD_KYOTAKU_PLAN2_TO_QUOTE_SUCCESS,
  LOAD_KYOTAKU_PLAN2_TO_QUOTE_ERROR,
  CREATE_KYOTAKU_PLAN2_SUCCESS,
  CREATE_KYOTAKU_PLAN2_ERROR,
  UPDATE_KYOTAKU_PLAN2_SUCCESS,
  UPDATE_KYOTAKU_PLAN2_ERROR,
  DELETE_ROWS_KYOTAKU_PLAN2,
  ADD_ROWS_KYOTAKU_PLAN2,
  GET_DETAIL_KYOTAKU_PLAN2,
  ADD_DETAIL_KYOTAKU_PLAN2,
  DELETE_DETAIL_KYOTAKU_PLAN2,
  GET_ROWINFO_KYOTAKU_PLAN2,
  CHANGE_EXPANDED_ROW_KEY,
  UP_ROWS_KYOTAKU_PLAN2,
  DOWN_ROWS_KYOTAKU_PLAN2,
  UPDATE_ROWS_KYOTAKU_PLAN2,
  UPDATE_KIKAN_KYOTAKU_PLAN2,
  UPDATE_SINGLE_KIKAN_KYOTAKU_PLAN2,
  GET_ROWS_KYOTAKU_PLAN2,
  CHANGE_EDIT_MODE,
  UPDATE_ON_CHANGE_KYOTAKU_PLAN2,
  GET_KP2_YOUGO_MASTER_SUCCESS,
  GET_KP2_YOUGO_MASTER_ERROR,
  SAVE_KP2_YOUGO_MASTER_SUCCESS,
  SAVE_KP2_YOUGO_MASTER_ERROR,
  KP2_YOUGO_EDIT_STATE,
  KP2_YOUGO_CANCEL_STATE,
  CHANGE_EDIT_MOBILE_MODE,
  CHANGE_VALUE_DETAIL,
  LOAD_SPIN_REQUEST_PLAN2,
  CHANGE_KAIGOHOKEN,
  UP_ROWS_KYOTAKU_PLAN2_DETAIL,
  DOWN_ROWS_KYOTAKU_PLAN2_DETAIL,
  RESET_STATUS,
  KEEP_VALUE1,
  SET_ROW_KYOTAKU_PLAN2,
  CLEAR_DETAIL_KYOTAKU_PLAN2,
} from "../../actions/kyotakuPlan2Actions/actionName";
import _ from "lodash";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

const initialState = {
  kyotakuPlan2Rows: [],
  kyotakuPlan2RowsFormated: [],
  kyotakuPlan2Details: [],
  rowInformation: null,
  expandedRowKeys: null,
  editMode: true,
  editDetail: false,
  valuesChange: false,
  kaigohoken: null,
  status: null,
};

function sortData(data) {
  return _.sortBy(data, [
    function (o) {
      return o.sortNum;
    },
  ]);
}

function processKikanData(data, longKikan, shortKikan, kikan) {
  let newItemdata = { ...data };
  if (data && data.longMokuhyou) newItemdata = { ...newItemdata, longKikan };
  else newItemdata = { ...newItemdata, longKikan: null };
  if (data && data.shortMokuhyou) newItemdata = { ...newItemdata, shortKikan };
  else newItemdata = { ...newItemdata, shortKikan: null };
  newItemdata.details = newItemdata.details.map((item) => {
    if (item.sienNaiyou) {
      return { ...item, kikan };
    }
    return { ...item, kikan: null };
  });
  return newItemdata;
}

export default function kyotakuPlan2(state = initialState, action) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        status: null,
      };

    case CHANGE_KAIGOHOKEN:
      return {
        ...state,
        kaigohoken: action.kaigohoken,
      };
    case LOAD_SPIN_REQUEST_PLAN2:
      return {
        ...state,
        loading: true,
      };
    case LOAD_KYOTAKU_PLAN2_BY_ID_SUCCESS:
      if (action.kyotakuPlan2 && action.kyotakuPlan2.seikatuMokuhyous) {
        return {
          ...state,
          kyotakuPlan2: action.kyotakuPlan2,
          kyotakuPlan2Rows:
            sortData(action.kyotakuPlan2.seikatuMokuhyous) || null,
          kyotakuPlan2Item: [],
          expandedRowKeys: null,
        };
      }
      return {
        ...state,
        kyotakuPlan2: action.kyotakuPlan2,
        kyotakuPlan2Rows: [],
        kyotakuPlan2Item: [],
      };

    case LOAD_KYOTAKU_PLAN2_BY_ID_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        kyotakuPlan2: null,
      };
    case CREATE_KYOTAKU_PLAN2_SUCCESS:
      openNotificationWithIcon("success", "保存しました", action.callback);
      localStorage.setItem(
        "RowKey2",
        action.createdKyotakuPlan2.id +
          "_" +
          action.createdKyotakuPlan2.riyousyaId +
          "_" +
          action.createdKyotakuPlan2.sid
      );
      return {
        ...state,
        kyotakuPlan2: action.createdKyotakuPlan2,
        expandedRowKeys: null,
        status: "success",
      };
    case CREATE_KYOTAKU_PLAN2_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        status: "error",
      };
    case UPDATE_KYOTAKU_PLAN2_SUCCESS:
      openNotificationWithIcon("success", "保存しました", action.callback);
      if (action.updatedKyotakuPlan2 && action.updatedKyotakuPlan2.seikatuMokuhyous) {
        return {
          ...state,
          kyotakuPlan2: action.updatedKyotakuPlan2,
          kyotakuPlan2Rows:
            sortData(action.updatedKyotakuPlan2.seikatuMokuhyous) || null,
          kyotakuPlan2Item: [],
          expandedRowKeys: null,
          status: "success",
        };
      }
      return {
        ...state,
        kyotakuPlan2: action.updatedKyotakuPlan2,
        // expandedRowKeys: null,
        kyotakuPlan2Rows: [],
        kyotakuPlan2Item: [],
        status: "success",
      };
    case UPDATE_KYOTAKU_PLAN2_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case LOAD_KYOTAKU_PLAN2_TO_QUOTE_SUCCESS:
      return {
        ...state,
        kyotakuPlan2s: action.kyotakuPlan2s,
      };
    case LOAD_KYOTAKU_PLAN2_TO_QUOTE_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        kyotakuPlan2s: [],
      };
    case GET_ROWS_KYOTAKU_PLAN2:
      return {
        ...state,
        kyotakuPlan2Rows: action.data,
      };
    case DELETE_ROWS_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const removeItemRow = state.kyotakuPlan2Rows.filter(
        (row) => row.sortNum !== action.sortNum
      );
      // eslint-disable-next-line no-case-declarations
      const itemRowFormated = removeItemRow.map((item, index) => {
        return { ...item, sortNum: index + 1 };
      });
      // eslint-disable-next-line no-case-declarations
      const rowInformationFormated = itemRowFormated.filter(
        (row) => row.sortNum === action.sortNum
      );
      return {
        ...state,
        kyotakuPlan2Rows: itemRowFormated,
        expandedRowKeys: null,
        rowInformation: null,
        kyotakuPlan2Details: rowInformationFormated.details,
      };
    case ADD_ROWS_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const newRow = {
        kyotakuPlan2Id: action?.data?.id ?? 0,
        seikatuKoi: "",
        longMokuhyou: "",
        longKikan: "",
        shortMokuhyou: "",
        shortKikan: "",
        sortNum: state.kyotakuPlan2Rows.length + 1,
        details: [
          {
            kyotakuPlan2SeikatuMokuhyouId: 0,
            hindo: "",
            jigyousyo: "",
            kikan: "",
            service: "",
            sienNaiyou: "",
            siensyaBunrui: "",
            sortNum: 1,
          },
        ],
      };
      let tmpAddRowsKyotakuPlan2 = [...state.kyotakuPlan2Rows];
      if (action.expandedRowKeys && action.rowData) {
        tmpAddRowsKyotakuPlan2[action.expandedRowKeys - 1] = action.rowData;
        tmpAddRowsKyotakuPlan2[action.expandedRowKeys - 1].details = [
          ...state.kyotakuPlan2Details,
        ];
      }
      return {
        ...state,
        kyotakuPlan2Rows: [...tmpAddRowsKyotakuPlan2, newRow],
        rowInformation: newRow,
        kyotakuPlan2Details: newRow.details,
      };
    case GET_DETAIL_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      let detailsNew;
      if (action.details && action.details.length === 0) {
        detailsNew = [
          {
            hindo: "",
            jigyousyo: "",
            kikan: "",
            service: "",
            sienNaiyou: "",
            siensyaBunrui: "",
            sortNum: 1,
          },
        ];
      } else {
        detailsNew = sortData(action.details);
      }
      return {
        ...state,
        kyotakuPlan2Details: detailsNew,
      };
    case CLEAR_DETAIL_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      let clearDetailsNew;
      if (action.details && action.details.length === 0) {
        clearDetailsNew = [
          {
            hindo: "",
            jigyousyo: "",
            kikan: "",
            service: "",
            sienNaiyou: "",
            siensyaBunrui: "",
            sortNum: 1,
          },
        ];
      } else {
        clearDetailsNew = sortData(action.details);
      }

      const newKyotakuPlan2Rows = [...state.kyotakuPlan2Rows];
      if (state.expandedRowKeys) {
        const selectedRow = newKyotakuPlan2Rows[state.expandedRowKeys - 1];
        selectedRow.details = clearDetailsNew;
      }

      return {
        ...state,
        kyotakuPlan2Details: clearDetailsNew,
        kyotakuPlan2Rows: newKyotakuPlan2Rows,
        rowInformation: {
          ...state.rowInformation,
          details: clearDetailsNew,
        },
      };
    case ADD_DETAIL_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const newDetail = {
        kyotakuPlan2SeikatuMokuhyouId: action.recordInfo?.id ?? 0,
        hindo: "",
        jigyousyo: "",
        kikan: "",
        service: "",
        sienNaiyou: "",
        siensyaBunrui: "",
        sortNum: state.kyotakuPlan2Details.length + 1,
      };
      let tmpKyotakuPlan2Rows = state.kyotakuPlan2Rows;
      tmpKyotakuPlan2Rows[action.expandedRowKeys - 1].details = [
        ...state.kyotakuPlan2Details,
        newDetail,
      ];

      return {
        ...state,
        kyotakuPlan2Details: [...state.kyotakuPlan2Details, newDetail],
        kyotakuPlan2Rows: tmpKyotakuPlan2Rows,
      };
    case DELETE_DETAIL_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const removeItem = state.kyotakuPlan2Details.filter(
        (row) => row.sortNum !== action.sortNum
      );
      // eslint-disable-next-line no-case-declarations
      const itemFormated = removeItem.map((item, index) => {
        return { ...item, sortNum: index + 1 };
      });
      return {
        ...state,
        kyotakuPlan2Details: itemFormated,
        rowInformation: { ...state.rowInformation, details: itemFormated },
      };

    case GET_ROWINFO_KYOTAKU_PLAN2:
      const _kyotakuPlan2Rows = [...state.kyotakuPlan2Rows];
      if (action.rowInfo && action.rowInfo.id) {
        for (let i = 0; i < _kyotakuPlan2Rows.length; i++) {
          if (_kyotakuPlan2Rows[i].id === action.rowInfo.id) {
            _kyotakuPlan2Rows[i] = action.rowInfo;
            break;
          }
        }
      }
      return {
        ...state,
        rowInformation: action.rowInfo,
        kyotakuPlan2Rows: _kyotakuPlan2Rows,
      };

    case CHANGE_EXPANDED_ROW_KEY:
      return {
        ...state,
        expandedRowKeys: action.key,
      };
    case UP_ROWS_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const kyotakuPlan2RowsCloneUp = [...state.kyotakuPlan2Rows];
      kyotakuPlan2RowsCloneUp[action.sortNum - 1] =
        state.kyotakuPlan2Rows[action.sortNum - 2];
      // kyotakuPlan2RowsCloneUp[action.sortNum - 2] = state.kyotakuPlan2Rows[action.sortNum - 1];
      kyotakuPlan2RowsCloneUp[action.sortNum - 2] = state.rowInformation;
      // eslint-disable-next-line no-case-declarations
      const rowFormatedUp = kyotakuPlan2RowsCloneUp.map((item, index) => {
        return { ...item, sortNum: index + 1 };
      });
      return {
        ...state,
        kyotakuPlan2Rows: rowFormatedUp,
        expandedRowKeys: action.sortNum - 1,
        rowInformation: rowFormatedUp[action.sortNum - 2],
      };
    case DOWN_ROWS_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const kyotakuPlan2RowsCloneDown = [...state.kyotakuPlan2Rows];
      kyotakuPlan2RowsCloneDown[action.sortNum - 1] =
        state.kyotakuPlan2Rows[action.sortNum];
      // kyotakuPlan2RowsCloneDown[action.sortNum] = state.kyotakuPlan2Rows[action.sortNum - 1];
      kyotakuPlan2RowsCloneDown[action.sortNum] = state.rowInformation;
      // eslint-disable-next-line no-case-declarations
      const rowFormatedDown = kyotakuPlan2RowsCloneDown.map((item, index) => {
        return { ...item, sortNum: index + 1 };
      });
      return {
        ...state,
        kyotakuPlan2Rows: rowFormatedDown,
        expandedRowKeys: action.sortNum + 1,
        rowInformation: rowFormatedDown[action.sortNum],
      };
    case UP_ROWS_KYOTAKU_PLAN2_DETAIL:
      const kyotakuPlan2RowsDetail = [...state.kyotakuPlan2Details];
      kyotakuPlan2RowsDetail[action.sortNum - 1] =
        state.kyotakuPlan2Details[action.sortNum - 2];
      kyotakuPlan2RowsDetail[action.sortNum - 2] =
        state.kyotakuPlan2Details[action.sortNum - 1];
      const rowFormatedUpDetail = kyotakuPlan2RowsDetail.map((item, index) => ({
        ...item,
        sortNum: index + 1,
      }));
      let tmpKyotakuPlan2RowsUp = state.kyotakuPlan2Rows;
      tmpKyotakuPlan2RowsUp[action.expandedRowKeys - 1].details = [
        ...rowFormatedUpDetail,
      ];
      return {
        ...state,
        kyotakuPlan2Rows: tmpKyotakuPlan2RowsUp,
        kyotakuPlan2Details: rowFormatedUpDetail,
      };
    case DOWN_ROWS_KYOTAKU_PLAN2_DETAIL:
      const kyotakuPlan2RowsDetailDown = [...state.kyotakuPlan2Details];
      kyotakuPlan2RowsDetailDown[action.sortNum - 1] =
        state.kyotakuPlan2Details[action.sortNum];
      kyotakuPlan2RowsDetailDown[action.sortNum] =
        state.kyotakuPlan2Details[action.sortNum - 1];
      const rowFormatedDownDetail = kyotakuPlan2RowsDetailDown.map(
        (item, index) => ({ ...item, sortNum: index + 1 })
      );
      let tmpKyotakuPlan2RowsDown = state.kyotakuPlan2Rows;
      tmpKyotakuPlan2RowsDown[action.expandedRowKeys - 1].details = [
        ...rowFormatedDownDetail,
      ];
      return {
        ...state,
        kyotakuPlan2Rows: tmpKyotakuPlan2RowsDown,
        kyotakuPlan2Details: rowFormatedDownDetail,
      };
    case UPDATE_ROWS_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const kyotakuPlan2RowsClone = [...state.kyotakuPlan2Rows];
      // eslint-disable-next-line no-case-declarations
      const kyotakuPlan2RowsFormatedClone = [...state.kyotakuPlan2Rows];
      kyotakuPlan2RowsClone[action.sortNum - 1] = action.data;
      kyotakuPlan2RowsFormatedClone[action.sortNum - 1] = action.data;
      return {
        ...state,
        kyotakuPlan2Rows: kyotakuPlan2RowsClone,
      };
    case UPDATE_KIKAN_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const kyotakuPlan2RowsKikanClone = [...state.kyotakuPlan2Rows];
      const kyotakuPlan2DetailsClone = [...state.kyotakuPlan2Details].map(
        (item) => {
          if (item.sienNaiyou) {
            return { ...item, kikan: action.kikan };
          }
          return { ...item, kikan: null };
        }
      );
      let longKikan = null;
      let shortKikan = null;
      if (state.rowInformation && state.rowInformation.longMokuhyou)
        longKikan = action.longKikan;
      else longKikan = null;
      if (state.rowInformation && state.rowInformation.shortMokuhyou)
        shortKikan = action.shortKikan;
      else shortKikan = null;
      if (state.expandedRowKeys) {
        return {
          ...state,
          // kyotakuPlan2: { ...state.kyotakuPlan2, planDate, renewYears, plan2DouiDate, comment, progressF },
          kyotakuPlan2Rows: kyotakuPlan2RowsKikanClone.map((item) =>
            processKikanData(
              item,
              action.longKikan,
              action.shortKikan,
              action.kikan
            )
          ),
          rowInformation: {
            ...state.rowInformation,
            longKikan,
            shortKikan,
            details: kyotakuPlan2DetailsClone,
          },
          kyotakuPlan2Details: kyotakuPlan2DetailsClone,
        };
      }
      return {
        ...state,
        // kyotakuPlan2: { ...state.kyotakuPlan2, planDate: action.planDate },
        kyotakuPlan2Rows: kyotakuPlan2RowsKikanClone.map((item) =>
          processKikanData(
            item,
            action.longKikan,
            action.shortKikan,
            action.kikan
          )
        ),
      };
    case UPDATE_ON_CHANGE_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const planDate = action.values.plan_date || null;
      // eslint-disable-next-line no-case-declarations
      const renewYears = action.values.renew_years || null;
      // eslint-disable-next-line no-case-declarations
      const plan2DouiDate = action.values.plan2_doui_date || null;
      // eslint-disable-next-line no-case-declarations
      const comment = action.values.comment || "";
      // eslint-disable-next-line no-case-declarations
      const progressF = action.values.progressF || [];
      // eslint-disable-next-line no-case-declarations
      const riyouKaigohokenId = action.values.riyou_kaigohoken_id || [];
      return {
        ...state,
        kyotakuPlan2: {
          ...state.kyotakuPlan2,
          planDate,
          renewYears,
          plan2DouiDate,
          comment,
          progressF,
          riyouKaigohokenId,
        },
      };

    case UPDATE_SINGLE_KIKAN_KYOTAKU_PLAN2:
      // eslint-disable-next-line no-case-declarations
      const kyotakuPlan2RowsSingleKikanClone = [...state.kyotakuPlan2Rows];
      // eslint-disable-next-line no-case-declarations
      const expandedRowKeys = state.expandedRowKeys;
      if (action.kikanType === "longKikan") {
        kyotakuPlan2RowsSingleKikanClone[expandedRowKeys - 1].longKikan =
          action.values;
        return {
          ...state,
          kyotakuPlan2Rows: kyotakuPlan2RowsSingleKikanClone,
          rowInformation: { ...state.rowInformation, longKikan: action.values },
        };
      }
      if (action.kikanType === "shortKikan") {
        kyotakuPlan2RowsSingleKikanClone[expandedRowKeys - 1].shortKikan =
          action.values;
        return {
          ...state,
          kyotakuPlan2Rows: kyotakuPlan2RowsSingleKikanClone,
          rowInformation: {
            ...state.rowInformation,
            shortKikan: action.values,
          },
        };
      }
      if (action.kikanType === "kikan") {
        // eslint-disable-next-line no-case-declarations
        const kikanRowsClone = [...state.kyotakuPlan2Rows];
        // eslint-disable-next-line no-case-declarations
        const kikanClone = [...state.kyotakuPlan2Details];
        if (kikanClone[action.indexDetail]) {
          kikanClone[action.indexDetail].kikan = action.values;
        }
        kikanRowsClone[expandedRowKeys - 1].details = kikanClone;
        return {
          ...state,
          kyotakuPlan2Rows: kikanRowsClone,
          kyotakuPlan2Details: kikanClone,
        };
      }
      return state;
    case CHANGE_EDIT_MODE:
      return {
        ...state,
        editMode: action.mode,
      };
    case GET_KP2_YOUGO_MASTER_SUCCESS:
      return {
        ...state,
        yougoNaiyou: action.naiyou
          ? action.naiyou.split(";").map((v, i) => {
              return {
                key: i,
                naiyou: v,
                editable: false,
              };
            })
          : [],
        loading: false,
      };
    case GET_KP2_YOUGO_MASTER_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoNaiyou: [],
      };
    case SAVE_KP2_YOUGO_MASTER_SUCCESS:
      return {
        ...state,
        yougoNaiyou: action.naiyou
          ? action.naiyou.split(";").map((v, i) => {
              return {
                key: i,
                naiyou: v,
                editable: false,
              };
            })
          : [],
      };
    case SAVE_KP2_YOUGO_MASTER_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoNaiyou: [],
      };
    case KP2_YOUGO_EDIT_STATE:
      return {
        ...state,
        yougoNaiyou: state.yougoNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = true;
          } else {
            item.editable = false;
          }
          return item;
        }),
      };
    case KP2_YOUGO_CANCEL_STATE:
      return {
        ...state,
        yougoNaiyou: state.yougoNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = false;
          }
          return item;
        }),
      };

    case CHANGE_EDIT_MOBILE_MODE:
      return {
        ...state,
        editDetail: action.edit,
      };
    case CHANGE_VALUE_DETAIL:
      return {
        ...state,
        valuesChange: action.types,
      };
    case KEEP_VALUE1:
      let kyotakuPlan2Tmp = state.kyotakuPlan2 || {};
      kyotakuPlan2Tmp.progressF = action.progressF.length;
      kyotakuPlan2Tmp.renewYears = action.renew_years;
      kyotakuPlan2Tmp.planDate = action.plan_date;
      kyotakuPlan2Tmp.plan2DouiDate = action.plan2_doui_date;
      kyotakuPlan2Tmp.comment = action.comment;
      return {
        ...state,
        kyotakuPlan2: kyotakuPlan2Tmp,
      };
    case SET_ROW_KYOTAKU_PLAN2:
      let kyotakuPlan2RowsTmp = state.kyotakuPlan2Rows;
      kyotakuPlan2RowsTmp[action.expandedRowKeys - 1] = action.data;
      kyotakuPlan2RowsTmp[action.expandedRowKeys - 1].details = [
        ...state.kyotakuPlan2Details,
      ];

      return {
        ...state,
        kyotakuPlan2Rows: kyotakuPlan2RowsTmp,
      };
    default:
      return state;
  }
}
