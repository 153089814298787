import { Form } from "@ant-design/compatible";
import { Col, Row } from "antd";
import React from "react";
import { ConstSet } from "../../../common/configs/constset";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import InputSelectKB from "../../../common/model-kiroku/InputSelectKB";
import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";

class NormalFormNyuyoku extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: !!this.props.data.reportCaseTyuif,
      type: null,
      extendValue: false,
    };

    this.onChangeShurui = this.onChangeShurui.bind(this);

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  componentDidUpdate() {
    // const oldProps = this.props

    // @TODO CHECK
    this.props.form.resetFields();
    // if (oldProps.data !== newProps.data) {
    //     this.props.form.resetFields();
    // }
  }

  onChangeShurui(value) {
    this.props.handleFormInput(value, "nyuyoku", "nyuyokuNaiyou");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "nyuyoku", "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "nyuyoku", "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "nyuyoku", "tantoId");
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index, 5);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index, 5);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou, 5);
  }

  /**
   *
   * get syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   */
  syokichiItem = () => {
    const kubun = ConstSet.NYUYOKU_SYOKICHI_NAIYOU;
    this.props.syokichiItem(kubun);
  };

  /**
   * update syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   * @param {*} syokichi
   */
  syokichiUpdate = () => {
    const kubun = ConstSet.NYUYOKU_SYOKICHI_NAIYOU;
    this.props.syokichiUpdate(kubun);
  };

  render() {
    // const { getFieldDecorator } = this.props.form;
    const { tantoMaster, data, yougoMasterNa, nyuyokuType, disabled } =
      this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 21 },
      },
    };

    return (
      <Form
        layout="vertical"
        {...formItemLayout}
      // onSubmit={this.handleSubmit}
      >
        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>担当者 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>種類 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <InputSelectKB
                value={data.nyuyokuNaiyou}
                handleInputSelect={this.onChangeShurui}
                data={nyuyokuType}
                disabled={disabled}
                syokichiItem={this.syokichiItem}
                syokichiUpdate={this.syokichiUpdate}
                typeSyokichi={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>コメント :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={2}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const FormNyuyoku = Form.create()(NormalFormNyuyoku);
export default FormNyuyoku;
