import { ErrorActionTypes } from "../action_types";

function error(state = {}, action) {
  switch (action.type) {
    case ErrorActionTypes.SET_ERROR: {
      return {
        ...state,
        code: action && action.error ? action.error.code : action,
        message: action && action.error ? action.error.message : action
      };
    }
    default: {
      return {
        code: null,
        message: "",
      };
    }
  }
}

export default error;
