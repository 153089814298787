/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import { Button, Checkbox, Col, DatePicker, Input, Modal, Row } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { ListNameSet } from "../../../common/configs/listnameset";
import { getMenu } from "../../../common/function_common/functionCommon";
import { convertStrShokijiBubun } from "../../../common/function_common/functionDisplay";
import { StorageUtils } from "../../../management/utils";
import SelectOptionsForm from "./SelectOptionsForm";
import SyokujiTable from "./SyokujiTable";
import TextAreaSelect from "./TextAreaSelect";

class ShokujiJokyouFormNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
    };
  }

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouConcern",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleSort = (type, sortType, index) => {
    this.props.handleSort(type, sortType, index);
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  /**
   * Update value
   *
   */
  updateField = (type, data) => {
    const { form } = this.props;
    switch (type) {
      case "syokuji":
        if (data) {
          let stapleFood = "";
          if (data && data.stapleFood) {
            stapleFood = data.stapleFood;
          }
          let sideFood = "";
          if (data && data.sideFood) {
            sideFood = data.sideFood;
          }
          form.setFieldsValue({
            syokujiName: `${stapleFood} ${sideFood}`,
          });

          form.setFieldsValue({
            syokujisenId: data.id,
          });
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return;
    }
  };

  onChangePagPopup = (page) => {
    this.props.onChangeSyokujiPag(page);
  };

  render() {
    const { visible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const {
      dataSyo,
      pageSyo,
      pageTotalSyo,
      shokujiRirekiSituation,
      shokujiRirekiComent,
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const formItemLayoutCus = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        <Row className="title-inline">
          <Col span={24}>
            <Form.Item
              label={"無し"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("id", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"利用者"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("riyousyaId", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"mainSID"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("serviceKikanMasterId", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"食事せん"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("syokujisenId", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={"startKubun"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("startKubun", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"endKubun"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("endKubun", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={"状況"} colon={false}>
              {getFieldDecorator("joukyou", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <SelectOptionsForm
                  data={shokujiRirekiSituation}
                  style={{ width: "300px" }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={"食事せん"} colon={false}>
              {getFieldDecorator("syokujiName", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <SyokujiTable
                  isMaster={false}
                  isSelect={true}
                  rowSet={1}
                  data={dataSyo}
                  page={pageSyo}
                  pageTotal={pageTotalSyo}
                  updateField={this.updateField}
                  onChangePagPopup={this.onChangePagPopup}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row
              className="dis-inline-form-item"
              id="riyou-shokuji-page-popup-1"
            >
              <Form.Item label={"開始日"} className="rioyu-shokuji-date-item">
                {getFieldDecorator("startDate", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(<DatePicker style={{ width: "110px" }} />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("sm11", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group options={[{ label: "朝", value: "mainf" }]} />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("sm12", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group
                    options={[{ label: "昼", value: "kansenf" }]}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("sm13", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group
                    options={[{ label: "間", value: "ryoyoSyokuf" }]}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("sm14", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group
                    options={[{ label: "夕", value: "tokuteif" }]}
                  />
                )}
              </Form.Item>
              <Form.Item style={{ minWidth: 100 }}>
                {getFieldDecorator("sm15", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group
                    options={[{ label: "経管栄養", value: "kangoSijif" }]}
                  />
                )}
              </Form.Item>
            </Row>
          </Col>
          <Col span={24}>
            <Row
              className="dis-inline-form-item"
              id="riyou-shokuji-page-popup-2"
            >
              <Form.Item label={"終了日"} className="rioyu-shokuji-date-item">
                {getFieldDecorator("endDate", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(<DatePicker style={{ width: "110px" }} />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("sm21", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group options={[{ label: "朝", value: "mainf" }]} />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("sm22", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group
                    options={[{ label: "昼", value: "kansenf" }]}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("sm23", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group
                    options={[{ label: "間", value: "ryoyoSyokuf" }]}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("sm24", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group
                    options={[{ label: "夕", value: "tokuteif" }]}
                  />
                )}
              </Form.Item>
              <Form.Item style={{ minWidth: 100 }}>
                {getFieldDecorator("sm25", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group
                    options={[{ label: "経管栄養", value: "kangoSijif" }]}
                  />
                )}
              </Form.Item>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item label={"コメント"} colon={false}>
              {getFieldDecorator("comment", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <TextAreaSelect
                  isMaster={false}
                  isSelect={true}
                  rowSet={1}
                  dataSelect={shokujiRirekiComent}
                  yougoMasterUpdate={(naiyou) =>
                    this.yougoMasterUpdate(ListNameSet.SHOKUJI_COMMENT, naiyou)
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
      </Form>)
    );
  }
}

export const ShokujiJokyouForm = Form.create({
  name: "input-riyou-syokujisen",
  onValuesChange(props, changedFields) {
    const { form } = props;
    if (changedFields && changedFields.sm11) {
      const sm11 = changedFields.sm11 && changedFields.sm11.length ? "朝食" : "";
      const sm12 =
        form.getFieldValue("sm12") && form.getFieldValue("sm12").length
          ? "昼食"
          : "";
      const sm13 =
        form.getFieldValue("sm13") && form.getFieldValue("sm13").length
          ? "間食"
          : "";
      const sm14 =
        form.getFieldValue("sm14") && form.getFieldValue("sm14").length
          ? "夕食"
          : "";
      const sm15 =
        form.getFieldValue("sm15") && form.getFieldValue("sm15").length
          ? "経管栄養"
          : "";

      form.setFieldsValue({
        startKubun: convertStrShokijiBubun(sm11, sm12, sm13, sm14, sm15),
      });
    }

    if (changedFields && changedFields.sm12) {
      const sm11 =
        form.getFieldValue("sm11") && form.getFieldValue("sm11").length
          ? "朝食"
          : "";
      const sm12 = changedFields.sm12 && changedFields.sm12.length ? "昼食" : "";
      const sm13 =
        form.getFieldValue("sm13") && form.getFieldValue("sm13").length
          ? "間食"
          : "";
      const sm14 =
        form.getFieldValue("sm14") && form.getFieldValue("sm14").length
          ? "夕食"
          : "";
      const sm15 =
        form.getFieldValue("sm15") && form.getFieldValue("sm15").length
          ? "経管栄養"
          : "";

      form.setFieldsValue({
        startKubun: convertStrShokijiBubun(sm11, sm12, sm13, sm14, sm15),
      });
    }

    if (changedFields && changedFields.sm13) {
      const sm11 =
        form.getFieldValue("sm11") && form.getFieldValue("sm11").length
          ? "朝食"
          : "";
      const sm12 =
        form.getFieldValue("sm12") && form.getFieldValue("sm12").length
          ? "昼食"
          : "";
      const sm13 = changedFields.sm13 && changedFields.sm13.length ? "間食" : "";
      const sm14 =
        form.getFieldValue("sm14") && form.getFieldValue("sm14").length
          ? "夕食"
          : "";
      const sm15 =
        form.getFieldValue("sm15") && form.getFieldValue("sm15").length
          ? "経管栄養"
          : "";

      form.setFieldsValue({
        startKubun: convertStrShokijiBubun(sm11, sm12, sm13, sm14, sm15),
      });
    }

    if (changedFields && changedFields.sm14) {
      const sm11 =
        form.getFieldValue("sm11") && form.getFieldValue("sm11").length
          ? "朝食"
          : "";
      const sm12 =
        form.getFieldValue("sm12") && form.getFieldValue("sm12").length
          ? "昼食"
          : "";
      const sm13 =
        form.getFieldValue("sm13") && form.getFieldValue("sm13").length
          ? "間食"
          : "";
      const sm14 = changedFields.sm14 && changedFields.sm14.length ? "夕食" : "";
      const sm15 =
        form.getFieldValue("sm15") && form.getFieldValue("sm15").length
          ? "経管栄養"
          : "";

      form.setFieldsValue({
        startKubun: convertStrShokijiBubun(sm11, sm12, sm13, sm14, sm15),
      });
    }

    if (changedFields && changedFields.sm15) {
      const sm11 =
        form.getFieldValue("sm11") && form.getFieldValue("sm11").length
          ? "朝食"
          : "";
      const sm12 =
        form.getFieldValue("sm12") && form.getFieldValue("sm12").length
          ? "昼食"
          : "";
      const sm13 =
        form.getFieldValue("sm13") && form.getFieldValue("sm13").length
          ? "間食"
          : "";
      const sm14 =
        form.getFieldValue("sm14") && form.getFieldValue("sm14").length
          ? "夕食"
          : "";
      const sm15 =
        changedFields.sm15 && changedFields.sm15.length ? "経管栄養" : "";

      form.setFieldsValue({
        startKubun: convertStrShokijiBubun(sm11, sm12, sm13, sm14, sm15),
      });
    }

    // endkubun
    if (changedFields && changedFields.sm21) {
      const sm21 = changedFields.sm21 && changedFields.sm21.length ? "朝食" : "";
      const sm22 =
        form.getFieldValue("sm22") && form.getFieldValue("sm22").length
          ? "昼食"
          : "";
      const sm23 =
        form.getFieldValue("sm23") && form.getFieldValue("sm23").length
          ? "間食"
          : "";
      const sm24 =
        form.getFieldValue("sm24") && form.getFieldValue("sm24").length
          ? "夕食"
          : "";
      const sm25 =
        form.getFieldValue("sm25") && form.getFieldValue("sm25").length
          ? "経管栄養"
          : "";

      form.setFieldsValue({
        endKubun: convertStrShokijiBubun(sm21, sm22, sm23, sm24, sm25),
      });
    }

    if (changedFields && changedFields.sm22) {
      const sm21 =
        form.getFieldValue("sm21") && form.getFieldValue("sm21").length
          ? "朝食"
          : "";
      const sm22 = changedFields.sm22 && changedFields.sm22.length ? "昼食" : "";
      const sm23 =
        form.getFieldValue("sm23") && form.getFieldValue("sm23").length
          ? "間食"
          : "";
      const sm24 =
        form.getFieldValue("sm24") && form.getFieldValue("sm24").length
          ? "夕食"
          : "";
      const sm25 =
        form.getFieldValue("sm25") && form.getFieldValue("sm25").length
          ? "経管栄養"
          : "";

      form.setFieldsValue({
        endKubun: convertStrShokijiBubun(sm21, sm22, sm23, sm24, sm25),
      });
    }

    if (changedFields && changedFields.sm23) {
      const sm21 =
        form.getFieldValue("sm21") && form.getFieldValue("sm21").length
          ? "朝食"
          : "";
      const sm22 =
        form.getFieldValue("sm22") && form.getFieldValue("sm22").length
          ? "昼食"
          : "";
      const sm23 = changedFields.sm23 && changedFields.sm23.length ? "間食" : "";
      const sm24 =
        form.getFieldValue("sm24") && form.getFieldValue("sm24").length
          ? "夕食"
          : "";
      const sm25 =
        form.getFieldValue("sm25") && form.getFieldValue("sm25").length
          ? "経管栄養"
          : "";

      form.setFieldsValue({
        endKubun: convertStrShokijiBubun(sm21, sm22, sm23, sm24, sm25),
      });
    }

    if (changedFields && changedFields.sm24) {
      const sm21 =
        form.getFieldValue("sm21") && form.getFieldValue("sm21").length
          ? "朝食"
          : "";
      const sm22 =
        form.getFieldValue("sm22") && form.getFieldValue("sm22").length
          ? "昼食"
          : "";
      const sm23 =
        form.getFieldValue("sm23") && form.getFieldValue("sm23").length
          ? "間食"
          : "";
      const sm24 = changedFields.sm24 && changedFields.sm24.length ? "夕食" : "";
      const sm25 =
        form.getFieldValue("sm25") && form.getFieldValue("sm25").length
          ? "経管栄養"
          : "";

      form.setFieldsValue({
        endKubun: convertStrShokijiBubun(sm21, sm22, sm23, sm24, sm25),
      });
    }

    if (changedFields && changedFields.sm25) {
      const sm21 =
        form.getFieldValue("sm21") && form.getFieldValue("sm21").length
          ? "朝食"
          : "";
      const sm22 =
        form.getFieldValue("sm22") && form.getFieldValue("sm22").length
          ? "昼食"
          : "";
      const sm23 =
        form.getFieldValue("sm23") && form.getFieldValue("sm23").length
          ? "間食"
          : "";
      const sm24 =
        form.getFieldValue("sm24") && form.getFieldValue("sm24").length
          ? "夕食"
          : "";
      const sm25 =
        changedFields.sm25 && changedFields.sm25.length ? "経管栄養" : "";

      form.setFieldsValue({
        endKubun: convertStrShokijiBubun(sm21, sm22, sm23, sm24, sm25),
      });
    }
  },
  mapPropsToFields(props) {
    let startDate = new Date();
    if (props.data && props.data.startDate) {
      startDate = new Date(props.data.startDate);
    }

    let endDate = null;
    if (
      props.data &&
      props.data.endDate &&
      props.data.endDate !== "0001-01-01"
    ) {
      endDate = new Date(props.data.endDate);
    }

    let stapleFood = "";
    if (
      props.data &&
      props.data.syokujisenOri &&
      props.data.syokujisenOri.stapleFood
    ) {
      stapleFood = props.data.syokujisenOri.stapleFood;
    }

    let sideFood = "";
    if (
      props.data &&
      props.data.syokujisenOri &&
      props.data.syokujisenOri.sideFood
    ) {
      sideFood = props.data.syokujisenOri.sideFood;
    }

    const serviceKikanMasterId = StorageUtils.getValue(getMenu()).si;

    return {
      id: Form.createFormField({
        value: props.data && props.data.id ? props.data.id : null,
      }),
      riyousyaId: Form.createFormField({
        value:
          props.data && props.data.riyousyaId
            ? props.data.riyousyaId
            : props.riyousyaId,
      }),
      serviceKikanMasterId: Form.createFormField({
        value:
          props.data && props.data.serviceKikanMasterId
            ? props.data.serviceKikanMasterId
            : serviceKikanMasterId,
      }),
      joukyou: Form.createFormField({
        value: props.data ? props.data.joukyou : null,
      }),
      syokujisenId: Form.createFormField({
        value:
          props.data && props.data.syokujisenOri
            ? props.data.syokujisenOri.id
            : props.data && props.data.syokujisenId
              ? props.data.syokujisenId
              : null,
      }),
      syokujiName: Form.createFormField({
        value:
          props.data && !props.data.syokujiName
            ? `${stapleFood} ${sideFood}`
            : props.data.syokujiName,
      }),
      startDate: Form.createFormField({
        value: dayjs(startDate),
      }),
      endDate: Form.createFormField({
        value: endDate && endDate !== "0001-01-01" ? dayjs(endDate) : null,
      }),
      comment: Form.createFormField({
        value: props.data ? props.data.comment : null,
      }),

      startKubun: Form.createFormField({
        value: props.data ? props.data.startKubun : null,
      }),

      endKubun: Form.createFormField({
        value: props.data ? props.data.endKubun : null,
      }),

      sm11: Form.createFormField({
        value:
          props.data &&
            props.data.startKubun &&
            props.data.startKubun.includes("朝食")
            ? ["mainf"]
            : [],
      }),

      sm12: Form.createFormField({
        value:
          props.data &&
            props.data.startKubun &&
            props.data.startKubun.includes("昼食")
            ? ["kansenf"]
            : [],
      }),

      sm13: Form.createFormField({
        value:
          props.data &&
            props.data.startKubun &&
            props.data.startKubun.includes("間食")
            ? ["ryoyoSyokuf"]
            : [],
      }),

      sm14: Form.createFormField({
        value:
          props.data &&
            props.data.startKubun &&
            props.data.startKubun.includes("夕食")
            ? ["tokuteif"]
            : [],
      }),

      sm15: Form.createFormField({
        value:
          props.data &&
            props.data.startKubun &&
            props.data.startKubun.includes("経管栄養")
            ? ["kangoSijif"]
            : [],
      }),

      // 朝食,昼食,夕食,間食,経管栄養
      // mainf,kansenf,tokuteif,ryoyoSyokuf,kangoSijif
      //
      sm21: Form.createFormField({
        value:
          props.data &&
            props.data.endKubun &&
            props.data.endKubun.includes("朝食")
            ? ["mainf"]
            : [],
      }),

      sm22: Form.createFormField({
        value:
          props.data &&
            props.data.endKubun &&
            props.data.endKubun.includes("昼食")
            ? ["kansenf"]
            : [],
      }),

      sm23: Form.createFormField({
        value:
          props.data &&
            props.data.endKubun &&
            props.data.endKubun.includes("間食")
            ? ["ryoyoSyokuf"]
            : [],
      }),

      sm24: Form.createFormField({
        value:
          props.data &&
            props.data.endKubun &&
            props.data.endKubun.includes("夕食")
            ? ["tokuteif"]
            : [],
      }),

      sm25: Form.createFormField({
        value:
          props.data &&
            props.data.endKubun &&
            props.data.endKubun.includes("経管栄養")
            ? ["kangoSijif"]
            : [],
      }),
    };
  },
})(ShokujiJokyouFormNormal);
