import React, { memo } from "react";
import { Modal } from "antd";

// constants
import { Label } from "../constants";

function ConfirmSignoutDialog(props) {
  return (
    <Modal title={Label.Dialog.ConfirmSignout.Title} {...props}>
      <p>{Label.Dialog.ConfirmSignout.Question}</p>
    </Modal>
  );
}

const _ConfirmSignoutDialog = memo(
  ConfirmSignoutDialog,
  (props, nextProps) => props.visible === nextProps.visible
);

export { _ConfirmSignoutDialog as ConfirmSignoutDialog };
