export const KihonCheckActionTypes = {
  GET_ALL_KIHON_CHECK_REQUEST: "GET_ALL_KIHON_CHECK_REQUEST",
  GET_ALL_KIHON_CHECK_SUCCESS: "GET_ALL_KIHON_CHECK_SUCCESS",
  GET_ALL_KIHON_CHECK_FAILURE: "GET_ALL_KIHON_CHECK_FAILURE",

  GET_KIHON_CHECK_REQUEST: "GET_KIHON_CHECK_REQUEST",
  GET_KIHON_CHECK_SUCCESS: "GET_KIHON_CHECK_SUCCESS",
  GET_KIHON_CHECK_FAILURE: "GET_KIHON_CHECK_FAILURE",

  GET_KIHON_CHECK_ITEM_REQUEST: "GET_KIHON_CHECK_ITEM_REQUEST",
  GET_KIHON_CHECK_ITEM_SUCCESS: "GET_KIHON_CHECK_ITEM_SUCCESS",
  GET_KIHON_CHECK_ITEM_FAILURE: "GET_KIHON_CHECK_ITEM_FAILURE",

  CREATE_KIHON_CHECK_REQUEST: "CREATE_KIHON_CHECK_REQUEST",
  CREATE_KIHON_CHECK_SUCCESS: "CREATE_KIHON_CHECK_SUCCESS",
  CREATE_KIHON_CHECK_FAILURE: "CREATE_KIHON_CHECK_FAILURE",

  UPDATE_KIHON_CHECK_REQUEST: "UPDATE_KIHON_CHECK_REQUEST",
  UPDATE_KIHON_CHECK_SUCCESS: "UPDATE_KIHON_CHECK_SUCCESS",
  UPDATE_KIHON_CHECK_FAILURE: "UPDATE_KIHON_CHECK_FAILURE",

  REMOVE_KIHON_CHECK_REQUEST: "REMOVE_KIHON_CHECK_REQUEST",
  REMOVE_KIHON_CHECK_SUCCESS: "REMOVE_KIHON_CHECK_SUCCESS",
  REMOVE_KIHON_CHECK_FAILURE: "REMOVE_KIHON_CHECK_FAILURE",

  GET_ALL_KIHON_CHECK_QUOTE_REQUEST: "GET_ALL_KIHON_CHECK_QUOTE_REQUEST",
  GET_ALL_KIHON_CHECK_QUOTE_SUCCESS: "GET_ALL_KIHON_CHECK_QUOTE_SUCCESS",
  GET_ALL_KIHON_CHECK_QUOTE_FAILURE: "GET_ALL_KIHON_CHECK_QUOTE_FAILURE",

  RESET_CURRENT_KiHON_CHECK: "RESET_CURRENT_KiHON_CHECK",

  NEW_PAGE: "NEW_PAGE",
};
