import React from "react";
import { InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Col, Row, Checkbox, Upload } from "antd";

import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import SelectGazouKubun from "../../../common/model-kiroku/SelectGazouKubun";

import ViewFile from "../../../common/form-kiroku/ViewFile";

const Dragger = Upload.Dragger;

class NormalFormGazou extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif ? true : false,
      type: null,
      extendValue: false,
      fileList: [],
      uploading: false,
      imageUrl: "",
      fileName: "",
    };

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);
    this.onChangeKubun = this.onChangeKubun.bind(this);
    this.onPriorityf = this.onPriorityf.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  componentDidUpdate(oldProps) {
    // const oldProps = this.props

    //@TODO CHECK
    this.props.form.resetFields();
    // if (oldProps.data !== newProps.data) {
    //     this.props.form.resetFields();
    // }
  }

  onPriorityf(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "gazou", "priorityf");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "gazou", "comment");
  }

  onChangeKubun(value) {
    this.props.handleFormInput(value, "gazou", "kubun");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "gazou", "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "gazou", "tantoId");
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index, 9);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index, 9);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou, 9);
  }

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    this.setState({
      uploading: true,
    });
  };

  onRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  };

  beforeUpload = (file) => {
    this.props.handleFormInput(file, "gazou", "file");
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      tantoMaster,
      data,
      yougoMasterNa,
      gazouKubun,
      file,
      imageUrl,
      disabled,
      filePreview,
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 21 },
      },
    };

    // const formItemLayoutSmall = {
    //     labelCol: {
    //         xs: { span: 24 },
    //         sm: { span: 4 },
    //     },
    //     wrapperCol: {
    //         xs: { span: 24 },
    //         sm: { span: 18 },
    //     },
    // };

    return (
      <Form
        layout="vertical"
        {...formItemLayout}
        // onSubmit={this.handleSubmit}
      >
        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>担当者 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>優先 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              {getFieldDecorator("priorityf", {
                rules: [{ required: false }],
              })(
                <Checkbox
                  disabled={disabled}
                  checked={data.priorityf}
                  onChange={this.onPriorityf}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>区分 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <SelectGazouKubun
                value={data.kubun}
                data={gazouKubun}
                handleChangeSelect={this.onChangeKubun}
                styleCus={"left"}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>コメント :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={2}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        {!data.fileManagementId ? (
          <Row gutter={16}>
            <Col span={24} style={{ padding: "16px 8px" }}>
              <Dragger
                disabled={disabled ? disabled : false}
                onRemove={this.onRemove}
                beforeUpload={this.beforeUpload}
                fileList={[]}
                // handleUpload={this.handleUpload}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  このエリアにファイルをクリックし、ドラッグして、アップロードしてください
                </p>
                {/* <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files</p> */}
              </Dragger>
            </Col>
            {file && file.name ? (
              <Col
                span={24}
                style={{ padding: "16px 8px", textAlign: "center" }}
              >
                <img
                  alt="ファイル"
                  src={imageUrl}
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                ></img>
                <p>{file.name}</p>
              </Col>
            ) : null}
          </Row>
        ) : null}

        <Row
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: 500,
          }}
        >
          <ViewFile record={data} filePreview={filePreview} />
        </Row>

        {/* <Row className="file-display">
                    <p>{data && data.fileName ? data.fileName : ''}</p>
                    {(
                        () => {
                            switch (data && data.type ? data.type : '') {
                                case 'doc':
                                    return (<img src={docIcon} alt="file" style={{ maxWidth: '100%' }} />)

                                case 'docx':
                                    return (<img src={docIcon} alt="file" style={{ maxWidth: '100%' }} />)

                                case 'xls':
                                    return (<img src={xlsIcon} alt="file" style={{ maxWidth: '100%' }} />)

                                case 'xlsx':
                                    return (<img src={xlsIcon} alt="file" style={{ maxWidth: '100%' }} />)

                                case 'ppt':
                                    return (<img src={pptIcon} alt="file" style={{ maxWidth: '100%' }} />)

                                case 'pdf':
                                    return (<img src={pdfIcon} alt="file" style={{ maxWidth: '100%' }} />)

                                case 'mp3':
                                    return (<audio src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/v1/file-management-url?fileManagementId=' + data.fileManagementId} alt="file" style={{ maxWidth: '100%' }} />)

                                case 'm4a':
                                    return (<audio src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/v1/file-management-url?fileManagementId=' + data.fileManagementId} alt="file" style={{ maxWidth: '100%' }} />)

                                case 'mov':
                                    return (<video alt="file" style={{ maxWidth: '100%' }} controls autoplay=''>
                                        <source
                                            src={
                                                window.location.protocol
                                                + '//' + window.location.hostname
                                                + ':' + window.location.port
                                                + '/api/v1/file-management-url?fileManagementId='
                                                + data.fileManagementId
                                            }
                                            type="video/mov" />
                                    </video>)

                                case 'MOV':
                                    return (<video alt="file" style={{ maxWidth: '100%' }}>
                                        <source
                                            src={
                                                window.location.protocol
                                                + '//' + window.location.hostname
                                                + ':' + window.location.port
                                                + '/api/v1/file-management-url?fileManagementId='
                                                + data.fileManagementId
                                            }
                                            type="video/mp4" />
                                    </video>)

                                case 'mp4':
                                    return (<video src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/v1/file-management-url?fileManagementId=' + data.fileManagementId} alt="file" style={{ maxWidth: '100%' }} />)

                                case 'jpg':
                                    return (<img src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/v1/file-management-url?fileManagementId=' + data.fileManagementId} alt="file" style={{ maxWidth: '100%', maxHeight: '500px' }} />)

                                case 'jpeg':
                                    return (<img src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/v1/file-management-url?fileManagementId=' + data.fileManagementId} alt="file" style={{ maxWidth: '100%', maxHeight: '500px' }} />)

                                case 'png':
                                    return (<img src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/v1/file-management-url?fileManagementId=' + data.fileManagementId} alt="file" style={{ maxWidth: '100%', maxHeight: '500px' }} />)

                                case 'gif':
                                    return (<img src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/v1/file-management-url?fileManagementId=' + data.fileManagementId} alt="file" style={{ maxWidth: '100%', maxHeight: '500px' }} />)

                                default:
                                    return null;

                            }
                        })()}
                </Row> */}
      </Form>
    );
  }
}

const FormGazou = Form.create()(NormalFormGazou);
export default FormGazou;
