import React, { useState, useEffect } from "react";
import { Modal, Button, Checkbox } from "antd";

function ServiceAdditionRentalModal(props) {
  const [serviceAddition, setServiceAddition] = useState();
  useEffect(() => {
    if (props.recordSelected) {
      setServiceAddition(props.recordSelected.kasanInfo);
    }
  }, [props.recordSelected]);

  function handleChange(e) {
    setServiceAddition(e.target.checked ? e.target.value : "");
  }
  function handleCancel() {
    props.onCancel();
  }

  function handleOk() {
    props.onOk(props.recordSelected.key, serviceAddition);
  }

  return (
    (<Modal
      open={props.visible}
      title={props.title}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={handleCancel}
        >
          キャンセル
        </Button>,
        <Button key="back" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
      width={300}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Checkbox
          checked={serviceAddition === "中山間地域居住者"}
          value={"中山間地域居住者"}
          onChange={handleChange}
        >
          中山間地域居住者
        </Checkbox>
      </div>
    </Modal>)
  );
}

export { ServiceAdditionRentalModal };
