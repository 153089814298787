import { Plan1ActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";

function plan1(state = {}, action) {
  switch (action.type) {
    // get all plan1 by user id
    case Plan1ActionTypes.GET_ALL_PLAN1_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case Plan1ActionTypes.GET_ALL_PLAN1_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case Plan1ActionTypes.GET_ALL_PLAN1_FAILURE: {
      return {
        ...state,
        data: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }

    // get one plan1
    case Plan1ActionTypes.GET_PLAN1_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getOne",
      };
    }
    case Plan1ActionTypes.GET_PLAN1_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case Plan1ActionTypes.GET_PLAN1_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // create plan1
    case Plan1ActionTypes.CREATE_PLAN1_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case Plan1ActionTypes.CREATE_PLAN1_SUCCESS:
    case Plan1ActionTypes.CREATE_PLAN1_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // update plan1
    case Plan1ActionTypes.UPDATE_PLAN1_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case Plan1ActionTypes.UPDATE_PLAN1_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case Plan1ActionTypes.UPDATE_PLAN1_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // remove plan1
    case Plan1ActionTypes.REMOVE_PLAN1_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case Plan1ActionTypes.REMOVE_PLAN1_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case Plan1ActionTypes.REMOVE_PLAN1_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    case Plan1ActionTypes.GET_ALL_PLAN1_QUOTE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Plan1ActionTypes.GET_ALL_PLAN1_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuote: action.data,
      };
    case Plan1ActionTypes.GET_ALL_PLAN1_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listQuote: [],
      };
    default: {
      return { ...state };
    }
  }
}

export default plan1;
