import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Table, notification, Menu, Dropdown, Modal } from "antd";

import SearchDialog from "./UI/SearchDialog";
import {
  setValueLocalstorage,
  getPath,
} from "../../common/function_common/functionCommon";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

class KanriOpenScreenLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      selectedRowKeys: [], // Check here to configure the default column
      displayList: [],
      visible: false,
      visibleDelConfirmPopup: false,
      currentPage: props.currentOpenSL,
      pageSize: props.pageSizeOpenSL, // @TODO setting in openSL,
      text1: "",
      text2: "",
      text3: "",
      path: getPath(),
    };

    this.reloadAll = this.reloadAll.bind(this);
  }

  componentDidMount() {
    // load data
    const { currentPage, pageSize } = this.state;

    // update from state
    const { openSLDisplayList, openSLSelectedRows, isOpenSLSelectedDisplay } =
      this.props;

    this.props.getSearchData("open");

    if (
      openSLDisplayList &&
      openSLDisplayList.length > 0 &&
      isOpenSLSelectedDisplay
    ) {
      this.props.selectedOpenSLDisplay(openSLDisplayList);
    } else {
      this.props.getOpenSLList(currentPage, pageSize);
    }

    if (openSLSelectedRows && openSLSelectedRows.length > 0) {
      this.setState({
        selectedRowKeys: openSLSelectedRows,
      });
    }
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  handleChange = (pagination, filters, sorter) => {
    // check pagination have been changed

    if (pagination) {
      const { currentPage, pageSize } = this.state;

      if (currentPage !== pagination.current) {
        this.props.getOpenSLList(pagination.current, pageSize);
      }

      this.setState({
        currentPage: pagination.current,
      });
    }

    //
    // if (sorter && sorter.field) {
    //     const { currentPage, pageSize, text1, text2, text3 } = this.state;

    //     // console.log(sorter)
    //     if(sorter.order === "descend"){
    //         this.props.getOpenSLList(currentPage, pageSize, text1, text2, `${sorter.field} desc`);
    //     }else if(sorter.order === "ascend"){
    //         this.props.getOpenSLList(currentPage, pageSize, text1, text2, `${sorter.field} acs`);
    //     }

    // }

    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  /**
   * Reload all data from server
   *
   */
  async reloadAll() {
    //
    await this.props.clearSelectedRow("open");

    //
    this.setState({
      selectedRowKeys: [],
    });

    this.props.getOpenSLList();
  }

  onSelectChange = (selectedRowKeys) => {
    // this this info have been save in global state
    this.setState({ selectedRowKeys });

    this.props.updateSelectedRows("open", selectedRowKeys);
  };

  handleOkSearch = (text1, text2, text3) => {
    const { currentPage, pageSize } = this.state;
    this.props.postOpenSLList(currentPage, pageSize, text1, text2, text3);
    //
    this.setState({
      visible: false,
      text1: text1,
      text2: text2,
      text3: text3,
    });
  };

  handleCancelSearch = (e) => {
    //
    this.setState({
      visible: false,
    });
  };

  disSearchDialog = (e) => {
    //
    this.setState({
      visible: true,
    });
  };

  selectedDisplay = () => {
    const { selectedRowKeys } = this.state;

    // UPDATE ON LOCAL
    this.props.selectedOpenSLDisplay(selectedRowKeys);
  };

  setDisplayList = () => {
    const displayList = [];
    const list = this.props.kanriOpenSLList;
    for (let i in list) {
      displayList.push(list[i].id);
    }
    this.props.selectedOpenSLDisplay(displayList);
  };

  loginKanri = (record) => {
    //
    setValueLocalstorage("tid", record.tantoMaster ? record.tantoMaster.id : 0); // fix tid
    setValueLocalstorage(
      "mode",
      record.tantoMaster ? record.tantoMaster.name : "kanri"
    ); // kanri login mode

    this.props.history.push("/dengon");
  };

  handleDel = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.setState({
        visibleDelConfirmPopup: true,
      });
    }
  };

  handleOKDelConfim = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.props.deleteOpenSL(selectedRowKeys.toString());
    }

    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  handleCancelDelConfim = () => {
    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  render() {
    const {
      kanriOpenSLList,
      loading,
      searchData,
      currentOpenSL,
      pageSizeOpenSL,
      totalOpenSL,
    } = this.props;
    let { sortedInfo, filteredInfo, visible, selectedRowKeys, path } =
      this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    // prepare export data
    let dataExportCsv = [];
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      let tmpDataExportCsv = kanriOpenSLList.filter((item) =>
        selectedRowKeys.includes(item.id)
      );
      if (tmpDataExportCsv && tmpDataExportCsv.length > 0)
        for (var i = 0; i < tmpDataExportCsv.length; i++) {
          dataExportCsv.push({
            id: tmpDataExportCsv[i].id,
            画面: tmpDataExportCsv[i].screen,
            担当者ID: tmpDataExportCsv[i].tantoMaster
              ? tmpDataExportCsv[i].tantoMaster.id
              : "",
            日付: tmpDataExportCsv[i].date,
            時間: tmpDataExportCsv[i].time,
          });
        }
    }

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id - b.id,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        ellipsis: true,
        width: 50,
      },

      {
        title: "画面",
        dataIndex: "screen",
        key: "screen",
        sorter: (a, b) =>
          (a.screen ? a.screen : "") < (b.screen ? b.screen : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "screen" && sortedInfo.order,
        ellipsis: true,
        // width: 120
      },

      {
        title: "担当者ID",
        dataIndex: "tantoMaster",
        key: "tantoMaster",
        sorter: (a, b) =>
          (a.tantoMaster ? a.tantoMaster.id : 0) -
          (b.tantoMaster ? b.tantoMaster.id : 0),
        sortOrder: sortedInfo.columnKey === "tantoMaster" && sortedInfo.order,
        ellipsis: true,
        width: 100,
        render: (text, record) =>
          record && record.tantoMaster ? record.tantoMaster.id : "",
      },

      {
        title: "日付",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) =>
          (a.date ? a.date : "") < (b.date ? b.date : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "date" && sortedInfo.order,
        ellipsis: true,
        width: 100,
      },

      {
        title: "時間",
        dataIndex: "time",
        key: "time",
        sorter: (a, b) =>
          (a.time ? a.time : "") < (b.time ? b.time : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "time" && sortedInfo.order,
        ellipsis: true,
        width: 100,
      },

      {
        title: "アクション",
        key: "operation",
        // fixed: "right",
        render: (text, record, index) => (
          <div>
            <Button type="primary" onClick={this.setDisplayList}>
              <Link to={`${path}/kanri/openscreenlog/${record.id}`}>編集</Link>
            </Button>
          </div>
        ),
        width: 120,
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const menu = (
      <Menu>
        <Menu.Item>
          <CSVLink
            filename={`画面遷移ログ${Date.now()}.csv`}
            data={dataExportCsv}
            className="btn btn-primary"
            disabled={dataExportCsv && dataExportCsv.length > 0 ? false : true}
          >
            ダウンロード
          </CSVLink>
        </Menu.Item>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" onClick={this.handleDel}>
            削除
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      (<KanriLayoutContainer router="open" breadcrumbLink={"/kanri/openscreenlog"} breadcrumbText={"画面遷移ログ"}>
        <div className="kanri-file-page">
          <div className="table-operations">
            <Button type="primary" onClick={this.reloadAll}>
              全表示
            </Button>
            <Button type="primary" onClick={this.disSearchDialog}>
              検索
            </Button>
            <Button type="primary" onClick={this.selectedDisplay}>
              選択表示
            </Button>
            <Button type="primary">
              <Link to={`${path}/kanri/openscreenlog/new`}>新規登録</Link>
            </Button>
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button type="primary">
                その他機能 <CaretDownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className="kanri-table">
            <Table
              columns={columns}
              dataSource={kanriOpenSLList}
              rowSelection={rowSelection}
              className="table"
              rowKey={"id"}
              pagination={{
                pageSize: pageSizeOpenSL,
                current: currentOpenSL,
                total: totalOpenSL,
              }}
              onChange={this.handleChange}
              size="small"
              bordered
              loading={loading}
              scroll={{ x: 800 }}
            />
          </div>
        </div>
        <SearchDialog
          title={"検索画面"}
          subTitle1={"対象項目"}
          subTitle2={"検索する文字"}
          subTitle3={"検索方法"}
          visible={visible}
          handleOk={this.handleOkSearch}
          handleCancel={this.handleCancelSearch}
          searchData={searchData}
        />
        <Modal
          title="処理確認"
          open={this.state.visibleDelConfirmPopup}
          onOk={this.handleOKDelConfim}
          onCancel={this.handleCancelDelConfim}
          okText="OK"
          cancelText="キャンセル"
        >
          <p>削除してもよろしいでしょうか？</p>
        </Modal>
      </KanriLayoutContainer>)
    );
  }
}

export default KanriOpenScreenLog;
