import React, { useState, useEffect } from "react";
import { Modal, Button, Checkbox } from "antd";
function ServiceAdditionModal(props) {
  const [serviceAddition, setServiceAddition] = useState([]);
  useEffect(() => {
    if (props.recordSelected && props.recordSelected.kasanInfo) {
      setServiceAddition(props.recordSelected.kasanInfo.split(","));
    } else {
      setServiceAddition([]);
    }
  }, [props.recordSelected]);

  function handleChangeServiceAddition(e) {
    let serviceAdditionNew = serviceAddition;
    if (e.target.checked) {
      serviceAdditionNew.push(e.target.value);
      // eslint-disable-next-line brace-style
    } else {
      serviceAdditionNew = serviceAdditionNew.filter(
        (item) => item !== e.target.value
      );
    }

    setServiceAddition([...serviceAdditionNew]);
  }

  function handleCancel() {
    props.onCancel();
  }

  function handleOk() {
    props.onOk(props.recordSelected, serviceAddition.join(","));
  }

  return (
    (<Modal
      open={props.visible}
      title={props.title}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={handleCancel}
        >
          キャンセル
        </Button>,
        <Button key="back" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
      width={350}
    >
      <div>
        <div
          style={{
            border: "2px solid #ccc",
            width: "300px",
            height: "400px",
            overflowY: "scroll",
          }}
        >
          {props.serviceAddition.map((item) => (
            // eslint-disable-next-line react/jsx-key
            (<div style={{ marginLeft: 2 }}>
              <Checkbox
                key={item.id}
                value={item.addition}
                checked={serviceAddition.includes(item.addition)}
                onChange={handleChangeServiceAddition}
              >
                {item.addition}
              </Checkbox>
            </div>)
          ))}
        </div>
      </div>
    </Modal>)
  );
}

export { ServiceAdditionModal };
