import { RoomMasterApi } from "../../api/roomMaster";
import {
  ALL_ROOM_REQUEST,
  LOAD_ROOM_SUCCESS,
  LOAD_ROOM_REQUEST,
  LOAD_ROOM_ERROR,
  SAVE_ROOM_REQUEST,
  SAVE_ROOM_LIST_SUCCESS,
  SAVE_ROOM_ERROR,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_ERROR,
  UPDATE_ROOM_SUCCESS,
  UPDATE_ROOM_ERROR,
  ROOM_MASTER_INIT_SUCCESS,
} from "./actionName";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

export function getRoomList() {
  return (dispatch) => {
    dispatch({
      type: ALL_ROOM_REQUEST,
    });
    RoomMasterApi.getRoomList().then(
      (data) =>
        dispatch({
          type: LOAD_ROOM_SUCCESS,
          payload: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_ROOM_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function roomCreate(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_ROOM_REQUEST,
    });
    RoomMasterApi.createRoom(body).then(
      (data) => {
        dispatch({
          type: CREATE_ROOM_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_ROOM_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function roomUpdate(body, callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_ROOM_REQUEST,
    });
    RoomMasterApi.updateRoom(body).then(
      (data) => {
        dispatch({
          type: UPDATE_ROOM_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_ROOM_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function roomListSave(data) {
  return (dispatch) => {
    dispatch({
      type: ALL_ROOM_REQUEST,
    });
    RoomMasterApi.saveRoomList(data).then(
      (data) =>
        dispatch({
          type: SAVE_ROOM_LIST_SUCCESS,
          payload: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: SAVE_ROOM_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function roomMasterInitCreator(callback) {
  return (dispatch) => {
    dispatch({
      type: ALL_ROOM_REQUEST,
    });
    RoomMasterApi.loadDataInit().then((data) => {
      dispatch({
        type: ROOM_MASTER_INIT_SUCCESS,
        payload: data,
      });
      return callback(data);
    });
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
