import { Button, Col, Input, Popover, Row, Select } from "antd";
import Table from "antd/es/table";
import React from "react";
import { connect } from "react-redux";
import {
  tantoMasterFilterCreator,
  tantoMasterListKikanCreator,
} from "../../../actions/tantoMasterListActions/actionCreators";
import jaconv from "../lib/jaconv.min";
const { Search } = Input;
const { Option } = Select;
const columns = [
  { title: "整理番号", dataIndex: "code", key: "code", width: "30%" },
  { title: "氏名", dataIndex: "name", key: "name", width: "30%" },
  { title: "資格", dataIndex: "sikaku", key: "sikaku", width: "30%" },
];

class StaffForm extends React.Component {
  constructor(props) {
    super(props);

    const shokuin = props.value || {};
    this.state = {
      shokuin_num: shokuin.shokuin_num,
      shokuin: shokuin.shokuin,
      visible: false,
      tantoMasters: null,
      tantoSelected: null,
      rowActive: null,
      loading: false,
      selectedBunrui: "",
      searchText: "",
      page: 1,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tantoMasters != this.props.tantoMasters) {
      this.setState({
        tantoMasters: nextProps.tantoMasters,
      });
    }
    if (
      (nextProps.value && nextProps.value.shokuin != this.state.shokuin) ||
      nextProps.value.shokuin_num != this.state.shokuin_num
    ) {
      this.setState({
        shokuin_num: nextProps.value.shokuin_num,
        shokuin: nextProps.value.shokuin,
      });
    }
  }
  handleVisibleChange = (visible) => {
    const { selectedBunrui, page, searchText } = this.state;
    if (!this.state.visible) {
      this.props.getTantoMasterList(selectedBunrui, searchText, page);
    }
    this.setState({ visible, rowActive: null });
  };

  handleFilterTanto(value) {
    const { selectedBunrui, page } = this.state;
    this.props.getTantoMasterList(selectedBunrui, value, page);

    this.setState({
      searchText: value,
      rowActive: null,
    });
  }
  handleSelectTanto = (record, rowIndex) => {
    const oldContent = this.state.shokuin;
    let newContent = "";
    if (oldContent != null) {
      if (oldContent.trim().length > 1) {
        if (oldContent.trim().substr(oldContent.trim().length - 1, 1) == "、") {
          newContent = oldContent.concat(record.name);
        }
        else {
          newContent = oldContent.concat("、" + record.name);
        }
      }
      else {
        newContent = record.name;
      }
    }
    else {
      newContent = record.name;
    }
    this.setState({
      tantoMasters: this.state.tantoMasters,
      rowActive: rowIndex,
    });
    this.triggerChange({ shokuin: newContent });
  };
  onChangeTextArea(e) {
    const shokuin = e.target.value;
    this.setState({
      shokuin,
    });
    this.triggerChange({ shokuin });
  }
  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange({
        ...this.state,
        ...changedValue,
      });
    }
  };
  convertFullsizeNumberToHaftsize(numFull) {
    return jaconv["normalize"](numFull);
  }
  onChangeInputNumber(e) {
    const newShokuinNum = parseInt(
      jaconv["normalize"](e.target.value) || 0,
      10
    );
    if (isNaN(newShokuinNum)) {
      return;
    }
    this.setState({
      shokuin_num: newShokuinNum,
    });
    this.triggerChange({ shokuin_num: newShokuinNum });
  }

  handleChangeBunrui(value) {
    this.setState({
      selectedBunrui: value,
    });
  }
  handlePageChange(page) {
    const { selectedBunrui, searchText } = this.state;
    this.setState({
      page,
      rowActive: null,
    });
    this.props.getTantoMasterList(selectedBunrui, searchText, page);
  }
  render() {
    const { tantoMasters, shokuin_num, shokuin, rowActive, selectedBunrui } =
      this.state;
    const { buttonName, yougoMasterTantoBunrui } = this.props;

    const selectedTextArea = document.activeElement;
    if (selectedTextArea.id == "searchInput") {
      document.activeElement.blur();
    }

    const paging = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: this.props.meta ? this.props.meta.total : 0,
      current: this.props.meta ? this.props.meta.page : 1,
      pageSize: this.props.meta ? this.props.meta.pageSize : 6,
    };
    const data =
      tantoMasters && Array.isArray(tantoMasters)
        ? tantoMasters.map((tanto) => {
          return {
            id: tanto.id,
            code: tanto.code,
            name: tanto.name,
            sikaku: tanto.sikaku,
          };
        })
        : [];

    const content = (
      <div style={{ width: "430px" }}>
        <Row className="pad-bt-8">
          <Col span={8}>
            <Select
              defaultValue={selectedBunrui}
              style={{ width: "100%" }}
              onChange={(value) => this.handleChangeBunrui(value)}
            >
              <Option value="" key={0}>
                全表示
              </Option>
              {Array.isArray(yougoMasterTantoBunrui)
                ? yougoMasterTantoBunrui.map((item) => {
                  return (
                    <Option key={item.key} value={item.naiyou}>
                      {item.naiyou}
                    </Option>
                  );
                })
                : []}
            </Select>
          </Col>
          <Col span={16}>
            <Search
              id={"searchInput"}
              enterButton={"検索"}
              onSearch={(value) => this.handleFilterTanto(value)}
            />
          </Col>
        </Row>
        <Row>
          <Table
            className={"tableStaff"}
            bordered
            columns={columns}
            dataSource={data}
            pagination={paging}
            size={"small"}
            rowKey={"id"}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.handleSelectTanto(record, rowIndex);
                }, // click row
              };
            }}
            rowClassName={(record, index) =>
              rowActive != null && index === rowActive
                ? "pljp-table-row-hover"
                : "pointer-row"
            }
          />
        </Row>
      </div>
    );
    return (
      <div className={"staffContainer"}>
        <Row style={{ height: 40 }}>
          <Col span={16}>
            <Popover
              content={content}
              title="記録担当者"
              trigger="click"
              visible={this.state.visible}
              onVisibleChange={this.handleVisibleChange}
            >
              <Button
                style={{ width: "95%", fontSize: "0.75rem", paddingLeft: 1, height: 45 }}
                type={"primary"}
              >
                {buttonName}
              </Button>
            </Popover>
          </Col>
          <Col span={5}>
            <Input
              value={shokuin_num}
              onChange={(value) => this.onChangeInputNumber(value)}
              style={{ padding: "8px" }}
            />
          </Col>
          <Col span={3}>
            <p style={{ paddingLeft: "3px", marginTop: 7 }}>名</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input.TextArea
              value={shokuin}
              onChange={(value) => this.onChangeTextArea(value)}
              style={{ width: "100%", marginTop: "-5px", height: "85px" }}
            />
          </Col>
        </Row>
        {/* <Row>

          </Row> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tantoMasters: state.tantoMasterList.data,
    meta: state.tantoMasterList.meta,
    yougoMasterTantoBunrui: state.yougoMasterNaiyou.yougoMasterTantoBunrui,
    tantoMasterFilter: state.tantoMasterList.tantoMasterFilter,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTantoMasterList: (bunrui, searchName, page) =>
      dispatch(tantoMasterListKikanCreator(bunrui, searchName, page)),
    tantoMasterFilterCreator: (dataFilter) =>
      dispatch(tantoMasterFilterCreator(dataFilter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffForm);
