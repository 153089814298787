/* eslint-disable no-unused-vars */
import React from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Col, Row, Table, Button, Radio } from "antd";
import "./InfoKyotaku.css";
import MediaQuery from "react-responsive";
import { processDataTableView } from "../DailyPlan/processData";
import LineBreakText from "../../../common/component/LineBreakText";
export default class ViewDailyPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataIndex: "kaigoNaiyou",
      expand: false,
    };
  }

  changeExpand() {
    this.setState({
      expand: !this.state.expand,
    });
  }

  renderAboveTable() {
    return (
      <Row>
        <Col span={2}>
          <Button
            onClick={() => this.changeExpand()}
            type={"primary"}
            icon={<LegacyIcon type={this.state.expand ? "minus" : "plus"} />}
          >
            {this.state.expand ? "格納" : "展開"}
          </Button>
        </Col>
        <Col span={20}>
          <div style={{ float: "right" }}>
            <span style={{ fontWeight: "bold" }}>入浴曜日:</span>
            <span style={{ paddingLeft: "20px" }}>
              {this.props.sisetuDailyPlan
                ? this.props.sisetuDailyPlan.nyuyokuYoubi
                : null}
            </span>
          </div>
        </Col>
        <Col style={{ float: "right" }}>
          <span>
            {this.props.sisetuDailyPlan &&
            this.props.sisetuDailyPlan.progressF === 1
              ? "採用"
              : null}{" "}
          </span>
        </Col>
      </Row>
    );
  }

  renderTableDailyPlanDetail() {
    const styles = this.state.expand
      ? {
          wordBreak: "break-word",
          whiteSpace: "pre-line",
        }
      : null;
    const columns = [
      {
        title: "",
        dataIndex: "time",
        width: "5%",
      },
      {
        title: "介護サービス（共通）",
        dataIndex: "kaigoNaiyou",
        width: "25%",
        ellipsis: true,
        render: (text) => {
          return (
            <div
              style={styles}
              className={this.state.expand ? null : "expand-dailyplan1"}
            >
              {text}
            </div>
          );
        },
      },
      {
        title: "介護サービス（個別）",
        dataIndex: "kaigoNaiyouF0",
        width: "30%",
        ellipsis: true,
        render: (text) => {
          return (
            <div
              style={styles}
              className={this.state.expand ? null : "expand-dailyplan2"}
            >
              {text}
            </div>
          );
        },
      },
      {
        title: "主な日常生活活動",
        dataIndex: "nitijouSeikatuNaiyou",
        width: "20%",
        ellipsis: true,
        render: (text) => {
          return (
            <div
              style={styles}
              className={this.state.expand ? null : "expand-dailyplan3"}
            >
              {text}
            </div>
          );
        },
      },

      {
        title: "個別詳細内容",
        dataIndex: "kobetuShousai",
        width: "20%",
        ellipsis: true,
        render: (text) => {
          return (
            <div
              style={styles}
              className={this.state.expand ? null : "expand-dailyplan4"}
            >
              {text}
            </div>
          );
        },
      },
    ];
    const dataTable = processDataTableView(this.props.sisetuDailyPlan);

    return (
      <Table
        className={"table-record"}
        rowKey={"id"}
        columns={columns}
        dataSource={dataTable}
        pagination={false}
        size={"small"}
      />
    );
  }

  renderBottomTable() {
    return (
      <div>
        <Row>
          <Col className={"title-view-tab3"} span={2}>
            <div>随時実施するサービス</div>
          </Col>
          <Col className={"input-value-tab3"} span={21}>
            <LineBreakText
              text={
                this.props.sisetuDailyPlan &&
                this.props.sisetuDailyPlan.zuijiService
                  ? this.props.sisetuDailyPlan.zuijiService
                  : "　"
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className={"title-view-tab3"} span={2}>
            <div>その他のサービス</div>
          </Col>
          <Col className={"input-value-tab3"} span={21}>
            <LineBreakText
              test={
                this.props.sisetuDailyPlan &&
                this.props.sisetuDailyPlan.sonotaService
                  ? this.props.sisetuDailyPlan.sonotaService
                  : "　"
              }
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderViewDailyPlanMobile() {
    const columns = [
      {
        title: "",
        dataIndex: "time",
        width: 60,
        className: "timeColumn",
      },
      {
        title: "",
        dataIndex: this.state.dataIndex,
        render: (text) => {
          return (
            <div className={this.state.expand ? null : "expand-dailyplan-m"}>
              {text}
            </div>
          );
        },
      },
    ];

    const dataTable = processDataTableView(this.props.sisetuDailyPlan);

    return (
      <div>
        <Row>
          <Col span={4}>
            <span style={{ fontWeight: "bold" }}>入浴曜日</span>
          </Col>
          <Col
            style={{ borderBottom: "1px solid", marginLeft: "10px" }}
            span={16}
          >
            <span>
              {this.props.sisetuDailyPlan &&
              this.props.sisetuDailyPlan.nyuyokuYoubi
                ? this.props.sisetuDailyPlan.nyuyokuYoubi
                : "　"}
            </span>
          </Col>
          <Col style={{ float: "right" }} span={2}>
            <span>採用</span>
          </Col>
        </Row>
        <div>
          <Button
            onClick={() => this.changeExpand()}
            style={{ margin: " 10px 0px" }}
            type={"primary"}
            icon={<LegacyIcon type={this.state.expand ? "minus" : "plus"} />}
          >
            {this.state.expand ? "格納" : "展開"}
          </Button>
        </div>
        <Radio.Group
          onChange={(value) => this.changeData(value)}
          defaultValue="1"
        >
          <Radio.Button className={"btn-change"} value="1">
            介護(共)
          </Radio.Button>
          <Radio.Button className={"btn-change"} value="2">
            介護(個)
          </Radio.Button>
          <Radio.Button className={"btn-change"} value="3">
            主な日常生活
          </Radio.Button>
          <Radio.Button className={"btn-change"} value="4">
            個別詳細内容
          </Radio.Button>
        </Radio.Group>
        <Table
          className={"table-record-m"}
          showHeader={false}
          columns={columns}
          dataSource={dataTable}
          pagination={false}
          size={"small"}
          rowKey={"id"}
        />
      </div>
    );
  }

  changeData(e) {
    switch (e.target.value) {
      case "1":
        this.setState({
          dataIndex: "kaigoNaiyou",
        });
        break;
      case "2":
        this.setState({
          dataIndex: "kaigoNaiyouF0",
        });
        break;
      case "3":
        this.setState({
          dataIndex: "nitijouSeikatuNaiyou",
        });
        break;
      case "4":
        this.setState({
          dataIndex: "kobetuShousai",
        });
        break;
    }
  }

  render() {
    const styles = {
      wordBreak: "break-word",
      whiteSpace: "pre-line",
    };
    const columnsTableBottom = [
      {
        className: "title-view1",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "content",
        width: "85%",
        className: "content-tb1",
        render: (text) => {
          return (
            <div className={this.state.expand ? null : "expand-dailyplan"}>
              <LineBreakText text={text} />
            </div>
          );
        },
      },
    ];
    const dataTableBottom = [
      {
        className: "content-tableview",
        title: "随時実施するサービス",
        content:
          this.props.sisetuDailyPlan && this.props.sisetuDailyPlan.zuijiService
            ? this.props.sisetuDailyPlan.zuijiService
            : null,
      },
      {
        title: "その他のサービス",
        content:
          this.props.sisetuDailyPlan && this.props.sisetuDailyPlan.sonotaService
            ? this.props.sisetuDailyPlan.sonotaService
            : null,
      },
    ];
    return (
      <div className={"view-dailyplan"}>
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <div className={"view-daily"}>
                  {this.renderAboveTable()}
                  {this.renderTableDailyPlanDetail()}
                  <Table
                    className={"view-plan-table1"}
                    pagination={false}
                    showHeader={false}
                    columns={columnsTableBottom}
                    dataSource={dataTableBottom}
                  />
                </div>
              );
            }
            return (
              <div className={"view-daily-m"}>
                {this.renderViewDailyPlanMobile()}
                {/* <Table
                className={"view-plan-table1"}
                pagination={false}
                showHeader={false}
                columns={columnsTableBottom}
                dataSource={dataTableBottom}
              /> */}
                <div style={{ marginTop: "20px" }}>
                  <Row>
                    <Col>
                      <span style={{ fontWeight: "bold" }}>
                        随時実施するサービス
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ borderBottom: "1px solid" }}>
                      <LineBreakText
                        text={
                          this.props.sisetuDailyPlan &&
                          this.props.sisetuDailyPlan.zuijiService
                            ? this.props.sisetuDailyPlan.zuijiService
                            : "　"
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <span style={{ fontWeight: "bold" }}>
                        随時実施するサービス
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ borderBottom: "1px solid" }}>
                      <LineBreakText
                        test={
                          this.props.sisetuDailyPlan &&
                          this.props.sisetuDailyPlan.sonotaService
                            ? this.props.sisetuDailyPlan.sonotaService
                            : "　"
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            );
          }}
        </MediaQuery>
      </div>
    );
  }
}
