import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import {
  LOAD_KEIKA_KIROKU_SPIN,
  LOAD_KEIKA_KIROKU_BY_DATE_SUCCESS,
  LOAD_KEIKA_KIROKU_BY_DATE_ERROR,
  LOAD_KEIKA_KIROKU_BY_ID_SUCCESS,
  LOAD_KEIKA_KIROKU_BY_ID_ERROR,
  CREATE_KEIKA_KIROKU_SUCCESS,
  CREATE_KEIKA_KIROKU_ERROR,
  UPDATE_KEIKA_KIROKU_SUCCESS,
  UPDATE_KEIKA_KIROKU_ERROR,
  RESET_CURRENT_KEIKA_KIROKU,
  GET_IDS_REQUEST,
  GET_IDS_SUCCESS,
  GET_IDS_ERROR,
} from "../../actions/keikaKirokuActions/actionName";

const initialState = {
  keikaKirokusByDate: [],
  metaKeikaKirokuByDate: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
  loading: false,
  keikaKiroku: {},
  createdKeikaKiroku: {},
  updatedKeikaKiroku: {},
  listIds: [],
};

export default function keikaKiroku(state = initialState, action) {
  switch (action.type) {
    case LOAD_KEIKA_KIROKU_SPIN:
      return {
        ...state,
        loading: true,
      };
    case LOAD_KEIKA_KIROKU_BY_DATE_SUCCESS:
      return {
        ...state,
        keikaKirokusByDate: action.keikaKirokus,
        metaKeikaKirokuByDate: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
      };
    case LOAD_KEIKA_KIROKU_BY_ID_SUCCESS:
      return {
        ...state,
        keikaKiroku: action.keikaKiroku,
        loading: false,
      };
    case CREATE_KEIKA_KIROKU_SUCCESS:
      openNotificationWithIcon("success", "保存しました", null);
      return {
        ...state,
        loading: false,
        createdKeikaKiroku: action.createdKeikaKiroku,
        keikaKiroku: action.createdKeikaKiroku,
      };
    case UPDATE_KEIKA_KIROKU_SUCCESS:
      openNotificationWithIcon("success", "保存しました", action.callback);
      return {
        ...state,
        loading: false,
        updatedKeikaKiroku: action.updatedKeikaKiroku,
        keikaKiroku: action.updatedKeikaKiroku,
      };
    case LOAD_KEIKA_KIROKU_BY_DATE_ERROR:
    case LOAD_KEIKA_KIROKU_BY_ID_ERROR:
    case CREATE_KEIKA_KIROKU_ERROR:
    case UPDATE_KEIKA_KIROKU_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case RESET_CURRENT_KEIKA_KIROKU:
      return {
        ...state,
        keikaKiroku: {},
        createdKeikaKiroku: {},
        updatedKeikaKiroku: {},
      };
    case GET_IDS_SUCCESS:
      return { ...state, listIds: action.listIds };
    case GET_IDS_ERROR:
      return { ...state };
    default:
      return state;
  }
}
