/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import { DeleteOutlined, InboxOutlined, LoadingOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Checkbox, Col, DatePicker, Input, Modal, Row, Select, TimePicker, Upload } from "antd";
import dayjs from "dayjs";
import React from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import SubHeaderTitleV2 from "../../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../../common/component/SubHeaderV2";
import { ConstSet } from "../../../common/configs/constset";
import ViewFile from "../../../common/form-kiroku/ViewFile";
import {
  checkIconUrl,
  formatStorage,
  getBase64,
  getMenu,
  getSwpsps,
  getValueLocalstorage,
  openNotificationWithIcon,
  setValueIsKeep,
  urlTobase64,
} from "../../../common/function_common/functionCommon";

const Dragger = Upload.Dragger;

const { Option } = Select;
const { TextArea } = Input;

class FormInputForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      extendValue: false,
      visibleNoKeyboard: false,

      fileList: [],
      uploading: false,
      imageUrl: "",
      file: null,
    };
  }

  onRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  };

  beforeUpload = (file) => {
    // this.props.handleChangeFile(file, 'file')
    const { form } = this.props;

    // const isLt2M = file.size / 1024 / 1024 < 200;
    // if (!isLt2M) {
    //     alert('画像は200MB未満でなければなりません！');
    //     return;
    // }

    // const isJPG1 = file.type === 'image/jpeg';
    // const isJPG2 = file.type === 'image/jpg';
    // const isJPG3 = file.type === 'image/png';
    // const isJPG4 = file.type === 'image/git';
    // if (isJPG1 || isJPG2 || isJPG3 || isJPG4) {
    //     getBase64(file, imageUrl => {
    //         this.setState({
    //             file: file
    //         });

    //         form.setFieldsValue({
    //             thumbnail: imageUrl
    //         })
    //     }
    //     );
    // } else {
    //     this.setState({
    //         file: file
    //     });
    // }

    // objData[type] = value;

    const mfs =
      !isNaN(parseInt(getValueLocalstorage("mfs"), 10)) &&
        parseInt(getValueLocalstorage("mfs"), 10) > 0
        ? parseInt(getValueLocalstorage("mfs"), 10)
        : 20;

    const isLt2M = file.size / 1024 / 1024 < mfs;
    if (!isLt2M) {
      openNotificationWithIcon(
        "error",
        `ファイルサイズが${mfs}MBを超えています`
      );
      return;
    }

    const isJPG1 = file.type === "image/jpeg";
    const isJPG2 = file.type === "image/jpg";
    const isJPG3 = file.type === "image/png";
    const isJPG4 = file.type === "image/git";
    if (isJPG1 || isJPG2 || isJPG3 || isJPG4) {
      getBase64(file, (imageUrl) => {
        this.setState({
          imageUrl,
        });

        form.setFieldsValue({
          thumbnail: imageUrl,
        });
      });
    }
    else {
      urlTobase64(checkIconUrl(file.name.split(".").pop()), (imgBase64) => {
        this.setState({
          imageUrl: imgBase64,
        });

        form.setFieldsValue({
          thumbnail: imgBase64,
        });
      });
    }

    this.setState({
      file,
    });
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.props.form.isFieldsTouched()) this.showGoBackConfirm();
    else this.props.history.goBack();
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.goBack();
  }

  handleUpdate = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const { file } = this.state;
      this.updateToServer(Object.assign(values, { file }));
    });
  };

  updateToServer(values) {
    const { data, riyousyaId } = this.props;

    // set kubun to localstorage
    setValueIsKeep(
      "fileRiyouDisChild",
      values && values.kubun ? values.kubun : "noinput"
    );

    this.setState({
      loadingSave: true,
    });

    if (data && data.id) {
      const preData = {};
      preData.systemControlName = values.systemControlName;
      preData.systemControlId = values.systemControlId;
      preData.date = values.date.format(ConstSet.DATE_FORMAT);
      preData.time = values.time.format("HH:mm:ss");
      preData.riyousyaId = riyousyaId;
      preData.tantoId = values.tantoId;
      preData.priorityf = values.priorityf.length;

      // Check if edit kiroku
      if (data && data.reportCaseId > 0) {
        preData.kirokuType = true;
      }

      const params = Object.assign(data, values, preData);

      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.updateFile(params, () => {
        this.setState({
          loadingSave: false,
        });

        this.props.history.goBack();
      });
    }
    else if (values && values.file) {
      const params = {};
      params.systemControlName = values.systemControlName;
      params.systemControlId = values.systemControlId;
      params.date = values.date.format(ConstSet.DATE_FORMAT);
      params.time = values.time.format("HH:mm:ss");
      params.riyousyaId = riyousyaId;
      params.tantoId = values.tantoId;

      params.file = values.file;
      params.kubun = values.kubun;
      params.comment = values.comment;
      params.priorityf = values.priorityf.length;
      params.thumbnail = values.thumbnail ? values.thumbnail : 0;

      this.props.insertFile(params, () => {
        this.setState({
          loadingSave: false,
        });

        this.props.history.goBack();
      });
    }
  }

  handleDelete = (e) => {
    e.preventDefault();
    this.setState({
      isDeleteModalConfirm: true,
    });
  };

  handleDeleteOk = (e) => {
    e.preventDefault();

    this.setState({
      isDeleteModalConfirm: false,
      loadingSave: true,
    });

    const { data } = this.props;
    this.props.deleteFile(data.id, () => {
      this.setState({
        loadingSave: false,
      });

      this.props.history.goBack();
    });
  };

  handleDeleteCancel = () => {
    this.setState({
      isDeleteModalConfirm: false,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { gazouRiyouKubun, data, gazouKirokuKubun, filePreview } = this.props;
    const { file, imageUrl } = this.state;

    return (
      (<div>
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return this.renderHeader();
            }
            return this.renderHeaderMobile();
          }}
        </MediaQuery>
        <div className="riyousya-shiryou-a-c kiroku-drawer kiroku-drawer-form kiroku-drawer-form-file">
          <Form
            layout="vertical"
            hideRequiredMark
            style={{ maxWidth: 780, margin: "0 auto" }}
          >
            {!data || !data.id ? (
              <Form.Item label="ファイル">
                {getFieldDecorator("file", {
                  rules: [
                    { required: false, message: "ファイル入力してください" },
                  ],
                })(
                  <Col span={16} style={{ height: 120 }}>
                    <Dragger
                      onRemove={this.onRemove}
                      beforeUpload={this.beforeUpload}
                      fileList={[]}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        このエリアにファイルをクリックし、ドラッグして、アップロードしてください
                      </p>
                      {/* <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files</p> */}
                    </Dragger>
                  </Col>
                )}

                {file && file.name ? (
                  <Col
                    span={8}
                    style={{
                      height: 120,
                      display: "table-cell",
                      verticalAlign: "middle",
                      textAlign: "center",
                    }}
                  >
                    <img
                      alt="ファイル"
                      src={imageUrl}
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    ></img>
                    <p>{file.name}</p>
                  </Col>
                ) : null}
              </Form.Item>
            ) : null}

            <Form.Item label="区分">
              {getFieldDecorator("kubun", {
                rules: [{ required: false, message: "区分入力してください" }],
              })(
                <Select style={{ width: 200 }}>
                  <Option key={0} value={""}>
                    &nbsp;
                  </Option>
                  {data && data.reportCaseId > 0 && gazouKirokuKubun
                    ? gazouKirokuKubun
                      .toString()
                      .split(";")
                      .map((item, index) => {
                        return (
                          <Option
                            key={index + 1}
                            value={item.toString().split("_").pop()}
                          >
                            {item.toString().split("_").shift()}
                          </Option>
                        );
                      })
                    : gazouRiyouKubun
                      ? gazouRiyouKubun
                        .toString()
                        .split(";")
                        .map((item, index) => {
                          return (
                            <Option
                              key={index + 1}
                              value={item.toString().split("_").pop()}
                            >
                              {item.toString().split("_").shift()}
                            </Option>
                          );
                        })
                      : null}
                </Select>
              )}
            </Form.Item>

            <Form.Item label="コメント">
              {getFieldDecorator("comment", {
                rules: [
                  { required: false, message: "コメント入力してください" },
                ],
              })(<TextArea rows={4} />)}
            </Form.Item>

            <Form.Item label="優先">
              {getFieldDecorator("priorityf", {
                rules: [{ required: false }],
              })(<Checkbox.Group options={[{ value: "priorityf" }]} />)}
            </Form.Item>

            <Form.Item label="thumbnail" style={{ display: "none" }}>
              {getFieldDecorator("thumbnail", {
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={"date"}
              colon={false}
              style={{ display: "none" }}
            >
              {getFieldDecorator("date", {
                rules: [
                  { required: true, message: "必須項目をセットして下さい" },
                ],
              })(<DatePicker style={{ width: "150px" }} />)}
            </Form.Item>

            <Form.Item
              label={"time"}
              colon={false}
              style={{ display: "none" }}
            >
              {getFieldDecorator("time", {
                rules: [
                  { required: true, message: "必須項目をセットして下さい" },
                ],
              })(<TimePicker showNow={false}
                needConfirm={false} format={"HH:mm"} />)}
            </Form.Item>

            <Form.Item
              label={"tantoId"}
              colon={false}
              style={{ display: "none" }}
            >
              {getFieldDecorator("tantoId", {
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={"systemControlName"}
              colon={false}
              style={{ display: "none" }}
            >
              {getFieldDecorator("systemControlName", {
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"systemControlId"}
              colon={false}
              style={{ display: "none" }}
            >
              {getFieldDecorator("systemControlId", {
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>

            {data && data.id ? (
              <Row
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  maxWidth: 780,
                  height: "100vh",
                }}
              >
                <ViewFile record={data} filePreview={filePreview} />
              </Row>
            ) : null}
          </Form>
        </div>
        <Modal
          open={this.state.isDeleteModalConfirm}
          title="削除確認"
          onCancel={this.handleDeleteCancel}
          onOk={this.handleDeleteOk}
        >
          <span style={{ display: "block" }}>
            {"本当に削除してもよろしいですか？"}
          </span>
        </Modal>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }

  renderHeader() {
    const { loadingSave } = this.state;
    const { riyousyaDetail, data } = this.props;
    return (
      <Row className={"submenu"}>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <SubHeaderV2
                  TopLeft={
                    <SubHeaderTitleV2
                      title={`${riyousyaDetail ? riyousyaDetail.name : ""}さんのファイルフォルダ`}
                      iconType="file"
                    />
                  }
                  TopRight={
                    <div className="submenu-t-r">
                      <Link
                        to={"/"}
                        onClick={this.handleGoBack}
                        className="sub-top-right-item"
                      >
                        <span style={{ display: "block", fontWeight: "bold" }}>
                          <UndoOutlined />
                        </span>
                        <span>{"戻る"}</span>
                      </Link>

                      <Link
                        to={"/"}
                        onClick={this.handleUpdate}
                        className="sub-top-right-item"
                      >
                        <span
                          style={{
                            color: "red",
                            display: "block",
                            fontWeight: "bold",
                          }}
                        >
                          {loadingSave ? (
                            <LoadingOutlined />
                          ) : (
                            <SaveOutlined />
                          )}
                        </span>
                        <span>{"登録"}</span>
                      </Link>

                      {data && data.id ? (
                        <Link
                          to={"/"}
                          onClick={this.handleDelete}
                          className="sub-top-right-item"
                        >
                          <span>
                            <DeleteOutlined />
                          </span>
                          <span> 削除</span>
                        </Link>
                      ) : null}
                    </div>
                  }
                />
              );
            }
            return null;

          }}
        </MediaQuery>
      </Row>
    );
  }
}

export const FormInput = Form.create({
  name: "user-shiryou-input",

  // onValuesChange(_, values) { },

  mapPropsToFields(props) {
    let dateTime = new Date();
    const swpsps = getSwpsps();
    if (props.data && props.data.date) {
      dateTime = new Date(props.data.date);
      if (props.data && props.data.time) {
        dateTime.setHours(props.data.time.split(":")[0]);
        dateTime.setMinutes(props.data.time.split(":")[1]);
        dateTime.setSeconds(props.data.time.split(":")[2]);
      }
    }

    return {
      systemControlName: Form.createFormField({
        value:
          props.data && props.data.systemControlName
            ? props.data.systemControlName
            : formatStorage(getMenu()).sn,
      }),
      systemControlId: Form.createFormField({
        value:
          props.data && props.data.systemControlId
            ? props.data.systemControlId
            : formatStorage(getMenu()).si,
      }),
      date: Form.createFormField({
        value: dayjs(dateTime),
      }),
      time: Form.createFormField({
        value: dayjs(dateTime),
      }),
      tantoId: Form.createFormField({
        value: props.data ? props.data.tantoId : formatStorage(swpsps).tid,
      }),
      kubun: Form.createFormField({
        value: props.data ? props.data.kubun : null,
      }),
      comment: Form.createFormField({
        value: props.data ? props.data.comment : null,
      }),
      priorityf: Form.createFormField({
        value: props.data && props.data.priorityf == 1 ? ["priorityf"] : [],
      }),
    };
  },
})(FormInputForm);
