import dayjs from "dayjs";

/**
 *
 * Function format data for weeekly page
 * json format: /jsonformat/reportWeekly.json
 *
 * @param {*} params
 * @param {*} vital
 * @param {*} meal
 * @param {*} suibun
 * @param {*} haisetu
 * @param {*} nyuyoku
 * @param {*} fukuyaku
 * @param {*} kaigo
 * @param {*} tokki
 * @param {*} gazou
 */
function reportWeeklyFormat(
  params,
  vital,
  meal,
  suibun,
  haisetu,
  nyuyoku,
  fukuyaku,
  kaigo,
  tokki,
  gazou,
  plan
) {
  // 00:00 00:30 ....23:00 23:30
  let timeRange = "00:00:00";
  const data = [];
  // 30分刻みの時間分ループして回し、日付ごとにデータを格納
  // date1 =本日, date2 =昨日
  for (let i = 0; i < 48; i++) {
    const rowData = {};
    timeRange = convertMinsToHrsMins(i * 30);
    rowData.key = i;
    rowData.plan = "";
    rowData.planType = "";
    rowData.timeRange = timeRange;

    rowData.date1 = extractReportValue(
      i,
      params.endDate,
      vital,
      meal,
      suibun,
      haisetu,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou
    );

    rowData.date2 = extractReportValue(
      i,
      dayjs(params.endDate).add(-1, "days").format("YYYY-MM-DD"),
      vital,
      meal,
      suibun,
      haisetu,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou
    );

    rowData.date3 = extractReportValue(
      i,
      dayjs(params.endDate).add(-2, "days").format("YYYY-MM-DD"),
      vital,
      meal,
      suibun,
      haisetu,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou
    );

    rowData.date4 = extractReportValue(
      i,
      dayjs(params.endDate).add(-3, "days").format("YYYY-MM-DD"),
      vital,
      meal,
      suibun,
      haisetu,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou
    );

    rowData.date5 = extractReportValue(
      i,
      dayjs(params.endDate).add(-4, "days").format("YYYY-MM-DD"),
      vital,
      meal,
      suibun,
      haisetu,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou
    );

    rowData.date6 = extractReportValue(
      i,
      dayjs(params.endDate).add(-5, "days").format("YYYY-MM-DD"),
      vital,
      meal,
      suibun,
      haisetu,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou
    );

    rowData.date7 = extractReportValue(
      i,
      dayjs(params.endDate).add(-6, "days").format("YYYY-MM-DD"),
      vital,
      meal,
      suibun,
      haisetu,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou
    );

    // import plan
    if (plan && plan.length > 0) {
      for (let ip = 0; ip < plan.length; ip++) {
        if (plan[ip].time === convertMinsToHrsMins(i * 30)) {
          rowData.plan = rowData.plan
            ? rowData.plan + "," + plan[ip].kaigoNaiyou
            : plan[ip].kaigoNaiyou;
          rowData.planType = rowData.planType
            ? rowData.planType + "," + plan[ip].planType
            : plan[ip].planType;
        }
      }
    }

    data.push(rowData);
  }

  // update calculator total row
  // ここで集計のデータをプッシュしてる
  const totalRow = exportTotalData(meal, suibun, haisetu, params);

  data.push(Object.assign({ key: 100, plan: "", timeRange: "集計" }, totalRow));

  // console.log(Object.assign({ key: 48, plan: "", assigntimeRange: "" }, totalRow))

  return data;
}

function extractReportValue(
  timeRange,
  date,
  vital,
  meal,
  suibun,
  haisetu,
  nyuyoku,
  fukuyaku,
  kaigo,
  tokki,
  gazou
) {
  const data = [];
  // update data for main colums
  for (let i = 0; i <= 29; i++) {
    const preData = {
      content: "",
      contentV: "",
      contentM: "",
      contentS: "",
      contentH: "",
      contentN: "",
      contentF: "",
      contentK: "",
      contentT: "",
      contentG: "",
      time: "",
      vitalId: null,
      mealId: null,
      suibunId: null,
      haisetuId: null,
      haisetuType: false,
      nyuyokuId: null,
      fukuyakuId: null,
      kaigo_naiyou_code: "0000",
      kaigoId: null,
      tokkiId: null,
      fileManagementId: null,
    };
    const time = convertMinsToHrsMins(timeRange * 30 + i);
    preData.time = time;
    preData.date = date;
    // vital
    if (vital && vital.length > 0) {
      for (let iv = 0; iv < vital.length; iv++) {
        if (vital[iv].time === time && vital[iv].date === date) {
          preData.contentV = preData.contentV
            ? preData.contentV + ", " + toReportCaseNaiyou(vital[iv], "vital")
            : toReportCaseNaiyou(vital[iv], "vital");
          preData.vitalId = preData.vitalId
            ? preData.vitalId + "," + vital[iv].vitalId
            : vital[iv].vitalId;
          // break;
        }
      }
    }

    // meal
    if (meal && meal.length > 0) {
      for (let im = 0; im < meal.length; im++) {
        if (meal[im].time === time && meal[im].date === date) {
          preData.contentM = preData.contentM
            ? preData.contentM + ", " + toReportCaseNaiyou(meal[im], "meal")
            : toReportCaseNaiyou(meal[im], "meal");
          preData.mealId = preData.mealId
            ? preData.mealId + "," + meal[im].mealId
            : meal[im].mealId;
        }
      }
    }

    // suibun
    if (suibun && suibun.length > 0) {
      for (let is = 0; is < suibun.length; is++) {
        if (suibun[is].time === time && suibun[is].date === date) {
          preData.contentS = preData.contentS
            ? preData.contentS + ", " + toReportCaseNaiyou(suibun[is], "suibun")
            : toReportCaseNaiyou(suibun[is], "suibun");
          preData.suibunId = preData.suibunId
            ? preData.suibunId + "," + suibun[is].suibunId
            : suibun[is].suibunId;
        }
      }
    }

    // haisetu
    if (haisetu && haisetu.length > 0) {
      for (let ih = 0; ih < haisetu.length; ih++) {
        if (haisetu[ih].time === time && haisetu[ih].date === date) {
          preData.contentH = preData.contentH
            ? preData.contentH +
            ", " +
            toReportCaseNaiyou(haisetu[ih], "haisetu")
            : toReportCaseNaiyou(haisetu[ih], "haisetu");
          preData.haisetuId = preData.haisetuId
            ? preData.haisetuId + "," + haisetu[ih].haisetuId
            : haisetu[ih].haisetuId;
          preData.haisetuType =
            !!(haisetu[ih].haisetuKubun && haisetu[ih].haisetuKubun === "排便");
        }
      }
    }

    // nyuyoku
    if (nyuyoku && nyuyoku.length > 0) {
      for (let inyu = 0; inyu < nyuyoku.length; inyu++) {
        if (nyuyoku[inyu].time === time && nyuyoku[inyu].date === date) {
          preData.contentN = preData.contentN
            ? preData.contentN +
            ", " +
            toReportCaseNaiyou(nyuyoku[inyu], "nyuyoku")
            : toReportCaseNaiyou(nyuyoku[inyu], "nyuyoku");
          preData.nyuyokuId = preData.nyuyokuId
            ? preData.nyuyokuId + "," + nyuyoku[inyu].nyuyokuId
            : nyuyoku[inyu].nyuyokuId;
        }
      }
    }

    // fukuyaku
    if (fukuyaku && fukuyaku.length > 0) {
      for (let ifuk = 0; ifuk < fukuyaku.length; ifuk++) {
        if (fukuyaku[ifuk].time === time && fukuyaku[ifuk].date === date) {
          preData.contentF = preData.contentF
            ? preData.contentF +
            ", " +
            toReportCaseNaiyou(fukuyaku[ifuk], "fukuyaku")
            : toReportCaseNaiyou(fukuyaku[ifuk], "fukuyaku");
          preData.fukuyakuId = preData.fukuyakuId
            ? preData.fukuyakuId + "," + fukuyaku[ifuk].fukuyakuId
            : fukuyaku[ifuk].fukuyakuId;
        }
      }
    }

    // kaigo
    if (kaigo && kaigo.length > 0) {
      for (let ik = 0; ik < kaigo.length; ik++) {
        if (kaigo[ik].time === time && kaigo[ik].date === date) {
          preData.contentK = preData.contentK
            ? preData.contentK + ", " + toReportCaseNaiyou(kaigo[ik], "kaigo")
            : toReportCaseNaiyou(kaigo[ik], "kaigo");
          preData.kaigoId = preData.kaigoId
            ? preData.kaigoId + "," + kaigo[ik].kaigoId
            : kaigo[ik].kaigoId;
          preData.kaigo_naiyou_code = kaigo[ik].kaigo_naiyou_code
            ? kaigo[ik].kaigo_naiyou_code
            : "0000";
        }
      }
    }

    // tokki
    if (tokki && tokki.length > 0) {
      for (let it = 0; it < tokki.length; it++) {
        if (tokki[it].time === time && tokki[it].date === date) {
          preData.contentT = preData.contentT
            ? preData.contentT + ", " + toReportCaseNaiyou(tokki[it], "tokki")
            : toReportCaseNaiyou(tokki[it], "tokki");
          preData.tokkiId = preData.tokkiId
            ? preData.tokkiId + "," + tokki[it].tokkiId
            : tokki[it].tokkiId;
        }
      }
    }

    // gazou
    if (gazou && gazou.length > 0) {
      for (let ig = 0; ig < gazou.length; ig++) {
        if (gazou[ig].time === time && gazou[ig].date === date) {
          preData.contentG = preData.contentG
            ? preData.contentG +
            ", =*&/" +
            toReportCaseNaiyou(gazou[ig], "gazou")
            : "=*&/" + toReportCaseNaiyou(gazou[ig], "gazou");
          preData.fileManagementId = preData.fileManagementId
            ? preData.fileManagementId + "," + gazou[ig].fileManagementId
            : gazou[ig].fileManagementId;
        }
      }
    }

    //
    if (
      preData.contentV ||
      preData.contentM ||
      preData.contentS ||
      preData.contentH ||
      preData.contentN ||
      preData.contentF ||
      preData.contentK ||
      preData.contentT ||
      preData.contentG
    ) {
      if (preData.contentV) {
        preData.content = preData.content ? preData.content + "," + preData.contentV : preData.contentV;
      }

      if (preData.contentM) {
        preData.content = preData.content ? preData.content + "," + preData.contentM : preData.contentM;
      }

      if (preData.contentS) {
        preData.content = preData.content ? preData.content + "," + preData.contentS : preData.contentS;
      }

      if (preData.contentH) {
        preData.content = preData.content ? preData.content + "," + preData.contentH : preData.contentH;
      }

      if (preData.contentN) {
        preData.content = preData.content ? preData.content + "," + preData.contentN : preData.contentN;
      }

      if (preData.contentF) {
        preData.content = preData.content ? preData.content + "," + preData.contentF : preData.contentF;
      }

      if (preData.contentK) {
        preData.content = preData.content ? preData.content + "," + preData.contentK : preData.contentK;
      }

      if (preData.contentT) {
        preData.content = preData.content ? preData.content + "," + preData.contentT : preData.contentT;
      }

      if (preData.contentG) {
        preData.content = preData.content ? preData.content + "," + preData.contentG : preData.contentG;
      }

      data.push(preData);
    }
  }

  // if (data.length > 0) {
  //   let content = "";
  //   let contentV = "";
  //   let contentM = "";
  //   let contentS = "";
  //   let contentH = "";
  //   let contentN = "";
  //   let contentF = "";
  //   let contentK = "";
  //   let contentT = "";
  //   let contentG = "";
  //   for (let np = 0; np < data.length; np++) {
  //     if (data[np].contentV) {
  //       contentV = contentV
  //         ? contentV + "," + data[np].contentV
  //         : data[np].contentV;
  //     }

  //     if (data[np].contentM) {
  //       contentM = contentM
  //         ? contentM + "," + data[np].contentM
  //         : data[np].contentM;
  //     }

  //     if (data[np].contentS) {
  //       contentS = contentS
  //         ? contentS + "," + data[np].contentS
  //         : data[np].contentS;
  //     }

  //     if (data[np].contentH) {
  //       contentH = contentH
  //         ? contentH + "," + data[np].contentH
  //         : data[np].contentH;
  //     }

  //     if (data[np].contentN) {
  //       contentN = contentN
  //         ? contentN + "," + data[np].contentN
  //         : data[np].contentN;
  //     }

  //     if (data[np].contentF) {
  //       contentF = contentF
  //         ? contentF + "," + data[np].contentF
  //         : data[np].contentF;
  //     }

  //     if (data[np].contentK) {
  //       contentK = contentK
  //         ? contentK + "," + data[np].contentK
  //         : data[np].contentK;
  //     }

  //     if (data[np].contentT) {
  //       contentT = contentT
  //         ? contentT + "," + data[np].contentT
  //         : data[np].contentT;
  //     }

  //     if (data[np].contentG) {
  //       contentG = contentG
  //         ? contentG + "," + data[np].contentG
  //         : data[np].contentG;
  //     }
  //   }

  //   if (contentV) {
  //     content = content ? content + "," + contentV : contentV;
  //   }

  //   if (contentM) {
  //     content = content ? content + "," + contentM : contentM;
  //   }

  //   if (contentS) {
  //     content = content ? content + "," + contentS : contentS;
  //   }

  //   if (contentH) {
  //     content = content ? content + "," + contentH : contentH;
  //   }

  //   if (contentN) {
  //     content = content ? content + "," + contentN : contentN;
  //   }

  //   if (contentF) {
  //     content = content ? content + "," + contentF : contentF;
  //   }

  //   if (contentK) {
  //     content = content ? content + "," + contentK : contentK;
  //   }

  //   if (contentT) {
  //     content = content ? content + "," + contentT : contentT;
  //   }

  //   if (contentG) {
  //     content = content ? content + "," + contentG : contentG;
  //   }

  //   data[0].content = content;
  // }

  return data;
}

/**
 * convertString to display
 * テーブル表示用のデータに変換
 * 食事で0が元からセットされてるので元を変える
 */
function toReportCaseNaiyou(record, type) {
  switch (type) {
    case "vital": {
      let stringVital = "";

      //
      stringVital =
        stringVital + (record.weight ? "WT" + record.weight + " " : "");
      stringVital =
        stringVital +
        (record.temperature ? "KT" + record.temperature + " " : "");
      stringVital =
        stringVital +
        (record.bpressureHigh || record.bpressureLow
          ? "BP" +
          (record.bpressureHigh ? record.bpressureHigh : "") +
          "/" +
          (record.bpressureLow ? record.bpressureLow : "") +
          " "
          : "");
      stringVital =
        stringVital + (record.pulse ? "P" + record.pulse + " " : "");
      stringVital =
        stringVital + (record.kokyu ? "R" + record.kokyu + " " : "");
      stringVital =
        stringVital + (record.spo2 ? "SPO" + record.spo2 + " " : "");
      stringVital =
        stringVital + (record.comment ? "(" + record.comment + ")" : "");

      return stringVital;
    }
    case "meal": {
      let stringMeal = "";
      stringMeal = stringMeal + (record.kubunMeal ? record.kubunMeal : "");
      // syokuNasiが1ならば 0/0の場合表示なし
      if (
        record.shokuNasi &&
        record.stapleIntake == "0" &&
        record.sideIntake == "0"
      ) {
        stringMeal =
          stringMeal + (record.comment ? "(" + record.comment + ")" : "");
        stringMeal = stringMeal + (record.shokuNasi ? " 食無し" : "");
      }
      else {
        stringMeal =
          stringMeal +
          (record.stapleIntake || record.stapleIntake == "0"
            ? record.stapleIntake
            : "");
        stringMeal =
          stringMeal +
          (record.sideIntake || record.sideIntake == "0"
            ? "/" + record.sideIntake
            : "");
        stringMeal =
          stringMeal + (record.comment ? "(" + record.comment + ")" : "");
        stringMeal = stringMeal + (record.shokuNasi ? " 食無し" : "");
      }

      return stringMeal;
    }
    case "suibun": {
      let stringSuibun = "";
      stringSuibun = stringSuibun + (record.syurui ? record.syurui : "");
      stringSuibun = stringSuibun + (record.intake ? record.intake : "");
      stringSuibun =
        stringSuibun + (record.comment ? "(" + record.comment + ")" : "");

      return stringSuibun;
    }
    case "haisetu": {
      let stringHaisetu = "";
      stringHaisetu = stringHaisetu + (record.shurui ? record.shurui : "");
      stringHaisetu =
        stringHaisetu +
        (record.haisetuKubun
          ? "(" +
          record.haisetuKubun.substring(1, 2) +
          (record.haisetuf ? "+" : "-")
          : "");
      stringHaisetu = stringHaisetu + (record.joutai ? record.joutai : "");
      stringHaisetu = stringHaisetu + (record.vol ? record.vol : "");
      stringHaisetu =
        stringHaisetu + (record.comment ? record.comment + ")" : ")");

      return stringHaisetu;
    }
    case "nyuyoku": {
      let stringNyuyoku = "";
      stringNyuyoku =
        stringNyuyoku + (record.nyuyokuNaiyou ? record.nyuyokuNaiyou : "");
      stringNyuyoku =
        stringNyuyoku + (record.comment ? "(" + record.comment + ")" : "");

      return stringNyuyoku;
    }
    case "fukuyaku": {
      let stringFukuyaku = "";
      stringFukuyaku = stringFukuyaku + (record.yakumei ? record.yakumei : "");
      stringFukuyaku =
        stringFukuyaku + (record.comment ? "(" + record.comment + ")" : "");

      return stringFukuyaku;
    }
    case "kaigo": {
      let stringKaigo = "";
      stringKaigo =
        stringKaigo + (record.kaigo_naiyou ? record.kaigo_naiyou : "");
      stringKaigo =
        stringKaigo + (record.comment ? "(" + record.comment + ")" : "");

      return stringKaigo;
    }
    case "tokki": {
      let stringTokki = "";
      stringTokki =
        stringTokki + (record.tokki ? "(" + record.tokki + ")" : "");

      return stringTokki;
    }
    case "gazou": {
      let stringGazou = "";
      stringGazou = stringGazou + (record.kubun ? record.kubun : "");
      stringGazou =
        stringGazou + (record.comment ? "(" + record.comment + ")" : "");

      return stringGazou;
    }
    default:
      return null;
  }
}

/**
 *
 * @param {*} mins
 */
function convertMinsToHrsMins(mins) {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  return `${h}:${m}:00`;
}

// function convertTime12to24(time12h) {
//   const [time, modifier] = time12h.split(' ');

//   let [hours, minutes, seconds] = time.split(':');

//   if (hours === '12') {
//     hours = '00';
//   }

//   if (modifier === 'PM') {
//     hours = parseInt(hours, 10) + 12;
//   }

//   return `${hours}:${minutes}:${seconds}`;
// }

/**
 *
 * @param {*} report
 * @param {*} settingColor
 */
function exportColor(report, settingColor) {
  let colorSetV = "";
  let colorSetHN = "";
  let colorSetHB = "";
  let colorSetN = "";
  let colorSetF = "";
  let colorSetK = "";

  if (settingColor) {
    // (?<=HB_)(.*?)(?=;)
    // var regexV = new RegExp(/(?<=V_)(.*?)(?=;)/)
    // var regexHN = new RegExp(/(?<=HN_)(.*?)(?=;)/)
    // var regexHB = new RegExp(/(?<=HB_)(.*?)(?=;)/)
    // var regexN = new RegExp(/(?<=N_)(.*?)(?=;)/)
    // var regexF = new RegExp(/(?<=F_)(.*?)(?=;)/)
    // var regexK = new RegExp(/(?<=K_)(.*?)(?=;)/)
    // colorSetV = regexV.exec(settingColor) && regexV.exec(settingColor)[0] ? regexV.exec(settingColor)[0] : colorSetV;

    // rewrite to support on ios
    const res = settingColor.split(";");
    if (res[0]) {
      // vital
      const resV = res[0].split("V_");
      if (resV[1]) {
        colorSetV = resV[1];
      }
    }

    if (res[1]) {
      // HN
      const resHN = res[1].split("HN_");
      if (resHN[1]) {
        colorSetHN = resHN[1];
      }
    }

    if (res[2]) {
      // HB
      const resHB = res[2].split("HB_");
      if (resHB[1]) {
        colorSetHB = resHB[1];
      }
    }

    if (res[3]) {
      // N
      const resN = res[3].split("N_");
      if (resN[1]) {
        colorSetN = resN[1];
      }
    }

    if (res[4]) {
      // F
      const resF = res[4].split("F_");
      if (resF[1]) {
        colorSetF = resF[1];
      }
    }

    if (res[5] && report && report.kaigo_naiyou_code) {
      // K
      const resK = res[5].split(`${report.kaigo_naiyou_code}_`);
      if (resK[1]) {
        colorSetK = resK[1];
      }
    }
  }

  let colorResult = "ffffff";

  if (report) {
    if (report.vitalId) {
      colorResult = colorSetV ? colorSetV : colorResult;
    }

    if (report.haisetuId) {
      if (report.haisetuType) {
        colorResult = colorSetHB ? colorSetHB : colorResult;
      }
      else {
        colorResult = colorSetHN ? colorSetHN : colorResult;
      }
    }

    if (report.nyuyokuId) {
      colorResult = colorSetN ? colorSetN : colorResult;
    }

    if (report.fukuyakuId) {
      colorResult = colorSetF ? colorSetF : colorResult;
    }

    if (report.kaigoId) {
      colorResult = colorSetK ? colorSetK : colorResult;
    }
  }

  return "#" + colorResult;
}

/**
 * Convert kubun file to display type
 *
 */
function updateContentKubun(setting, content) {
  if (content.includes("=*&/")) {
    let gazouKubun = content.split("=*&/").pop().split("(")[0];

    const comment = content.split("=*&/" + gazouKubun)[1];

    setting
      .toString()
      .split(";")
      .map((item) => {
        if (item) {
          if (
            item.toString().split("_")[1] &&
            item.toString().split("_")[1] === gazouKubun
          ) {
            gazouKubun = [item.toString().split("_")[0]];
            return false;
          }
        }

        return false;
      });

    return content.split("=*&/")[0] + "「画」" + gazouKubun + comment;
  }
  return content;

}

/**
 * 接種量
 * @param {*} meal
 * @param {*} suibun
 * @param {*} haisetu
 */
function exportTotalData(meal, suibun, haisetu, params) {
  const finalRow = {
    date1: {
      hainyo: 0,
      hainyoCount: 0,

      haiben: 0,
      haibenCount: 0,

      intake: 0,

      asaStapleIntake: 0,
      asaSideIntake: 0,
      asaNasi: null,

      hiruStapleIntake: 0,
      hiruSideIntake: 0,
      hiruNasi: null,

      yuuStapleIntake: 0,
      yuuSideIntake: 0,
      yuuNasi: null,

      banStapleIntake: 0,
      banSideIntake: 0,
      banNasi: null,

      keikanStapleIntake: 0,
      keikanSideIntake: 0,
      keikanNasi: null,

      meal: "",
    },

    date2: {
      hainyo: 0,
      hainyoCount: 0,

      haiben: 0,
      haibenCount: 0,

      intake: 0,

      asaStapleIntake: 0,
      asaSideIntake: 0,
      asaNasi: null,

      hiruStapleIntake: 0,
      hiruSideIntake: 0,
      hiruNasi: null,

      yuuStapleIntake: 0,
      yuuSideIntake: 0,
      yuuNasi: null,

      banStapleIntake: 0,
      banSideIntake: 0,
      banNasi: null,

      keikanStapleIntake: 0,
      keikanSideIntake: 0,
      keikanNasi: null,

      meal: "",
    },

    date3: {
      hainyo: 0,
      hainyoCount: 0,

      haiben: 0,
      haibenCount: 0,

      intake: 0,

      asaStapleIntake: 0,
      asaSideIntake: 0,
      asaNasi: null,

      hiruStapleIntake: 0,
      hiruSideIntake: 0,
      hiruNasi: null,

      yuuStapleIntake: 0,
      yuuSideIntake: 0,
      yuuNasi: null,

      banStapleIntake: 0,
      banSideIntake: 0,
      banNasi: null,

      keikanStapleIntake: 0,
      keikanSideIntake: 0,
      keikanNasi: null,

      meal: "",
    },

    date4: {
      hainyo: 0,
      hainyoCount: 0,

      haiben: 0,
      haibenCount: 0,

      intake: 0,

      asaStapleIntake: 0,
      asaSideIntake: 0,
      asaNasi: null,

      hiruStapleIntake: 0,
      hiruSideIntake: 0,
      hiruNasi: null,

      yuuStapleIntake: 0,
      yuuSideIntake: 0,
      yuuNasi: null,

      banStapleIntake: 0,
      banSideIntake: 0,
      banNasi: null,

      keikanStapleIntake: 0,
      keikanSideIntake: 0,
      keikanNasi: null,

      meal: "",
    },

    date5: {
      hainyo: 0,
      hainyoCount: 0,

      haiben: 0,
      haibenCount: 0,

      intake: 0,

      asaStapleIntake: 0,
      asaSideIntake: 0,
      asaNasi: null,

      hiruStapleIntake: 0,
      hiruSideIntake: 0,
      hiruNasi: null,

      yuuStapleIntake: 0,
      yuuSideIntake: 0,
      yuuNasi: null,

      banStapleIntake: 0,
      banSideIntake: 0,
      banNasi: null,

      keikanStapleIntake: 0,
      keikanSideIntake: 0,
      keikanNasi: null,

      meal: "",
    },

    date6: {
      hainyo: 0,
      hainyoCount: 0,

      haiben: 0,
      haibenCount: 0,

      intake: 0,

      asaStapleIntake: 0,
      asaSideIntake: 0,
      asaNasi: null,

      hiruStapleIntake: 0,
      hiruSideIntake: 0,
      hiruNasi: null,

      yuuStapleIntake: 0,
      yuuSideIntake: 0,
      yuuNasi: null,

      banStapleIntake: 0,
      banSideIntake: 0,
      banNasi: null,

      keikanStapleIntake: 0,
      keikanSideIntake: 0,
      keikanNasi: null,

      meal: "",
    },

    date7: {
      hainyo: 0,
      hainyoCount: 0,

      haiben: 0,
      haibenCount: 0,

      intake: 0,

      asaStapleIntake: 0,
      asaSideIntake: 0,
      asaNasi: null,

      hiruStapleIntake: 0,
      hiruSideIntake: 0,
      hiruNasi: null,

      yuuStapleIntake: 0,
      yuuSideIntake: 0,
      yuuNasi: null,

      banStapleIntake: 0,
      banSideIntake: 0,
      banNasi: null,

      keikanStapleIntake: 0,
      keikanSideIntake: 0,
      keikanNasi: null,

      meal: "",
    },
  };

  // meal
  // 集計のデータ
  // dayjs(params.endDate).add(-1, 'days').format('YYYY-MM-DD'),
  if (meal && meal.length > 0) {
    for (let im = 0; im < meal.length; im++) {
      // 集計の食事データ表記の為のデータ操作
      // 日付ごとに朝だった場合のデータを入れる
      // 朝
      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "朝" &&
        meal[im].date === params.endDate
      ) {
        finalRow.date1.asaStapleIntake =
          finalRow.date1.asaStapleIntake + meal[im].stapleIntake;
        finalRow.date1.asaSideIntake =
          finalRow.date1.asaSideIntake + meal[im].sideIntake;
        finalRow.date1.asaNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "朝" &&
        meal[im].date ===
        dayjs(params.endDate).add(-1, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date2.asaStapleIntake =
          finalRow.date2.asaStapleIntake + meal[im].stapleIntake;
        finalRow.date2.asaSideIntake =
          finalRow.date2.asaSideIntake + meal[im].sideIntake;
        finalRow.date2.asaNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "朝" &&
        meal[im].date ===
        dayjs(params.endDate).add(-2, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date3.asaStapleIntake =
          finalRow.date3.asaStapleIntake + meal[im].stapleIntake;
        finalRow.date3.asaSideIntake =
          finalRow.date3.asaSideIntake + meal[im].sideIntake;
        finalRow.date3.asaNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "朝" &&
        meal[im].date ===
        dayjs(params.endDate).add(-3, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date4.asaStapleIntake =
          finalRow.date4.asaStapleIntake + meal[im].stapleIntake;
        finalRow.date4.asaSideIntake =
          finalRow.date4.asaSideIntake + meal[im].sideIntake;
        finalRow.date4.asaNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "朝" &&
        meal[im].date ===
        dayjs(params.endDate).add(-4, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date5.asaStapleIntake =
          finalRow.date5.asaStapleIntake + meal[im].stapleIntake;
        finalRow.date5.asaSideIntake =
          finalRow.date5.asaSideIntake + meal[im].sideIntake;
        finalRow.date5.asaNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "朝" &&
        meal[im].date ===
        dayjs(params.endDate).add(-5, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date6.asaStapleIntake =
          finalRow.date6.asaStapleIntake + meal[im].stapleIntake;
        finalRow.date6.asaSideIntake =
          finalRow.date6.asaSideIntake + meal[im].sideIntake;
        finalRow.date6.asaNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "朝" &&
        meal[im].date ===
        dayjs(params.endDate).add(-6, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date7.asaStapleIntake =
          finalRow.date7.asaStapleIntake + meal[im].stapleIntake;
        finalRow.date7.asaSideIntake =
          finalRow.date7.asaSideIntake + meal[im].sideIntake;
        finalRow.date7.asaNasi = !!meal[im].shokuNasi;
      }

      // 昼
      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "昼" &&
        meal[im].date === params.endDate
      ) {
        finalRow.date1.hiruStapleIntake =
          finalRow.date1.hiruStapleIntake + meal[im].stapleIntake;
        finalRow.date1.hiruSideIntake =
          finalRow.date1.hiruSideIntake + meal[im].sideIntake;
        finalRow.date1.hiruNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "昼" &&
        meal[im].date ===
        dayjs(params.endDate).add(-1, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date2.hiruStapleIntake =
          finalRow.date2.hiruStapleIntake + meal[im].stapleIntake;
        finalRow.date2.hiruSideIntake =
          finalRow.date2.hiruSideIntake + meal[im].sideIntake;
        finalRow.date2.hiruNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "昼" &&
        meal[im].date ===
        dayjs(params.endDate).add(-2, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date3.hiruStapleIntake =
          finalRow.date3.hiruStapleIntake + meal[im].stapleIntake;
        finalRow.date3.hiruSideIntake =
          finalRow.date3.hiruSideIntake + meal[im].sideIntake;
        finalRow.date3.hiruNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "昼" &&
        meal[im].date ===
        dayjs(params.endDate).add(-3, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date4.hiruStapleIntake =
          finalRow.date4.hiruStapleIntake + meal[im].stapleIntake;
        finalRow.date4.hiruSideIntake =
          finalRow.date4.hiruSideIntake + meal[im].sideIntake;
        finalRow.date4.hiruNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "昼" &&
        meal[im].date ===
        dayjs(params.endDate).add(-4, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date5.hiruStapleIntake =
          finalRow.date5.hiruStapleIntake + meal[im].stapleIntake;
        finalRow.date5.hiruSideIntake =
          finalRow.date5.hiruSideIntake + meal[im].sideIntake;
        finalRow.date5.hiruNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "昼" &&
        meal[im].date ===
        dayjs(params.endDate).add(-5, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date6.hiruStapleIntake =
          finalRow.date6.hiruStapleIntake + meal[im].stapleIntake;
        finalRow.date6.hiruSideIntake =
          finalRow.date6.hiruSideIntake + meal[im].sideIntake;
        finalRow.date6.hiruNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "昼" &&
        meal[im].date ===
        dayjs(params.endDate).add(-6, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date7.hiruStapleIntake =
          finalRow.date7.hiruStapleIntake + meal[im].stapleIntake;
        finalRow.date7.hiruSideIntake =
          finalRow.date7.hiruSideIntake + meal[im].sideIntake;
        finalRow.date7.hiruNasi = !!meal[im].shokuNasi;
      }

      //
      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "夕" &&
        meal[im].date === params.endDate
      ) {
        finalRow.date1.yuuStapleIntake =
          finalRow.date1.yuuStapleIntake + meal[im].stapleIntake;
        finalRow.date1.yuuSideIntake =
          finalRow.date1.yuuSideIntake + meal[im].sideIntake;
        finalRow.date1.yuuNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "夕" &&
        meal[im].date ===
        dayjs(params.endDate).add(-1, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date2.yuuStapleIntake =
          finalRow.date2.yuuStapleIntake + meal[im].stapleIntake;
        finalRow.date2.yuuSideIntake =
          finalRow.date2.yuuSideIntake + meal[im].sideIntake;
        finalRow.date2.yuuNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "夕" &&
        meal[im].date ===
        dayjs(params.endDate).add(-2, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date3.yuuStapleIntake =
          finalRow.date3.yuuStapleIntake + meal[im].stapleIntake;
        finalRow.date3.yuuSideIntake =
          finalRow.date3.yuuSideIntake + meal[im].sideIntake;
        finalRow.date3.yuuNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "夕" &&
        meal[im].date ===
        dayjs(params.endDate).add(-3, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date4.yuuStapleIntake =
          finalRow.date4.yuuStapleIntake + meal[im].stapleIntake;
        finalRow.date4.yuuSideIntake =
          finalRow.date4.yuuSideIntake + meal[im].sideIntake;
        finalRow.date4.yuuNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "夕" &&
        meal[im].date ===
        dayjs(params.endDate).add(-4, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date5.yuuStapleIntake =
          finalRow.date5.yuuStapleIntake + meal[im].stapleIntake;
        finalRow.date5.yuuSideIntake =
          finalRow.date5.yuuSideIntake + meal[im].sideIntake;
        finalRow.date5.yuuNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "夕" &&
        meal[im].date ===
        dayjs(params.endDate).add(-5, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date6.yuuStapleIntake =
          finalRow.date6.yuuStapleIntake + meal[im].stapleIntake;
        finalRow.date6.yuuSideIntake =
          finalRow.date6.yuuSideIntake + meal[im].sideIntake;
        finalRow.date6.yuuNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "夕" &&
        meal[im].date ===
        dayjs(params.endDate).add(-6, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date7.yuuStapleIntake =
          finalRow.date7.yuuStapleIntake + meal[im].stapleIntake;
        finalRow.date7.yuuSideIntake =
          finalRow.date7.yuuSideIntake + meal[im].sideIntake;
        finalRow.date7.yuuNasi = !!meal[im].shokuNasi;
      }

      //
      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "間食" &&
        meal[im].date === params.endDate
      ) {
        finalRow.date1.banStapleIntake =
          finalRow.date1.banStapleIntake + meal[im].stapleIntake;
        finalRow.date1.banSideIntake =
          finalRow.date1.banSideIntake + meal[im].sideIntake;
        finalRow.date1.banNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "間食" &&
        meal[im].date ===
        dayjs(params.endDate).add(-1, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date2.banStapleIntake =
          finalRow.date2.banStapleIntake + meal[im].stapleIntake;
        finalRow.date2.banSideIntake =
          finalRow.date2.banSideIntake + meal[im].sideIntake;
        finalRow.date2.banNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "間食" &&
        meal[im].date ===
        dayjs(params.endDate).add(-2, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date3.banStapleIntake =
          finalRow.date3.banStapleIntake + meal[im].stapleIntake;
        finalRow.date3.banSideIntake =
          finalRow.date3.banSideIntake + meal[im].sideIntake;
        finalRow.date3.banNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "間食" &&
        meal[im].date ===
        dayjs(params.endDate).add(-3, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date4.banStapleIntake =
          finalRow.date4.banStapleIntake + meal[im].stapleIntake;
        finalRow.date4.banSideIntake =
          finalRow.date4.banSideIntake + meal[im].sideIntake;
        finalRow.date4.banNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "間食" &&
        meal[im].date ===
        dayjs(params.endDate).add(-4, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date5.banStapleIntake =
          finalRow.date5.banStapleIntake + meal[im].stapleIntake;
        finalRow.date5.banSideIntake =
          finalRow.date5.banSideIntake + meal[im].sideIntake;
        finalRow.date5.banNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "間食" &&
        meal[im].date ===
        dayjs(params.endDate).add(-5, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date6.banStapleIntake =
          finalRow.date6.banStapleIntake + meal[im].stapleIntake;
        finalRow.date6.banSideIntake =
          finalRow.date6.banSideIntake + meal[im].sideIntake;
        finalRow.date6.banNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "間食" &&
        meal[im].date ===
        dayjs(params.endDate).add(-6, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date7.banStapleIntake =
          finalRow.date7.banStapleIntake + meal[im].stapleIntake;
        finalRow.date7.banSideIntake =
          finalRow.date7.banSideIntake + meal[im].sideIntake;
        finalRow.date7.banNasi = !!meal[im].shokuNasi;
      }

      //
      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "経管栄養" &&
        meal[im].date === params.endDate
      ) {
        finalRow.date1.keikanStapleIntake =
          finalRow.date1.keikanStapleIntake + meal[im].stapleIntake;
        finalRow.date1.keikanSideIntake =
          finalRow.date1.keikanSideIntake + meal[im].sideIntake;
        finalRow.date1.keikanNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "経管栄養" &&
        meal[im].date ===
        dayjs(params.endDate).add(-1, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date2.keikanStapleIntake =
          finalRow.date2.keikanStapleIntake + meal[im].stapleIntake;
        finalRow.date2.keikanSideIntake =
          finalRow.date2.keikanSideIntake + meal[im].sideIntake;
        finalRow.date2.keikanNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "経管栄養" &&
        meal[im].date ===
        dayjs(params.endDate).add(-2, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date3.keikanStapleIntake =
          finalRow.date3.keikanStapleIntake + meal[im].stapleIntake;
        finalRow.date3.keikanSideIntake =
          finalRow.date3.keikanSideIntake + meal[im].sideIntake;
        finalRow.date3.keikanNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "経管栄養" &&
        meal[im].date ===
        dayjs(params.endDate).add(-3, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date4.keikanStapleIntake =
          finalRow.date4.keikanStapleIntake + meal[im].stapleIntake;
        finalRow.date4.keikanSideIntake =
          finalRow.date4.keikanSideIntake + meal[im].sideIntake;
        finalRow.date4.keikanNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "経管栄養" &&
        meal[im].date ===
        dayjs(params.endDate).add(-4, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date5.keikanStapleIntake =
          finalRow.date5.keikanStapleIntake + meal[im].stapleIntake;
        finalRow.date5.keikanSideIntake =
          finalRow.date5.keikanSideIntake + meal[im].sideIntake;
        finalRow.date5.keikanNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "経管栄養" &&
        meal[im].date ===
        dayjs(params.endDate).add(-5, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date6.keikanStapleIntake =
          finalRow.date6.keikanStapleIntake + meal[im].stapleIntake;
        finalRow.date6.keikanSideIntake =
          finalRow.date6.keikanSideIntake + meal[im].sideIntake;
        finalRow.date6.keikanNasi = !!meal[im].shokuNasi;
      }

      if (
        meal[im].kubunMeal &&
        meal[im].kubunMeal === "経管栄養" &&
        meal[im].date ===
        dayjs(params.endDate).add(-6, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date7.keikanStapleIntake =
          finalRow.date7.keikanStapleIntake + meal[im].stapleIntake;
        finalRow.date7.keikanSideIntake =
          finalRow.date7.keikanSideIntake + meal[im].sideIntake;
        finalRow.date7.keikanNasi = !!meal[im].shokuNasi;
      }
    }
  }

  // suibun
  if (suibun && suibun.length > 0) {
    for (let is = 0; is < suibun.length; is++) {
      if (suibun[is].intake && suibun[is].date === params.endDate) {
        finalRow.date1.intake = finalRow.date1.intake + suibun[is].intake;
      }

      if (
        suibun[is].intake &&
        suibun[is].date ===
        dayjs(params.endDate).add(-1, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date2.intake = finalRow.date2.intake + suibun[is].intake;
      }

      if (
        suibun[is].intake &&
        suibun[is].date ===
        dayjs(params.endDate).add(-2, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date3.intake = finalRow.date3.intake + suibun[is].intake;
      }

      if (
        suibun[is].intake &&
        suibun[is].date ===
        dayjs(params.endDate).add(-3, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date4.intake = finalRow.date4.intake + suibun[is].intake;
      }

      if (
        suibun[is].intake &&
        suibun[is].date ===
        dayjs(params.endDate).add(-4, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date5.intake = finalRow.date5.intake + suibun[is].intake;
      }

      if (
        suibun[is].intake &&
        suibun[is].date ===
        dayjs(params.endDate).add(-5, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date6.intake = finalRow.date6.intake + suibun[is].intake;
      }

      if (
        suibun[is].intake &&
        suibun[is].date ===
        dayjs(params.endDate).add(-6, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date7.intake = finalRow.date7.intake + suibun[is].intake;
      }
    }
  }

  // haisetu
  if (haisetu && haisetu.length > 0) {
    for (let ih = 0; ih < haisetu.length; ih++) {
      if (
        haisetu[ih].haisetuKubun === "排尿" &&
        haisetu[ih].date === params.endDate
      ) {
        finalRow.date1.hainyo = finalRow.date1.hainyo + haisetu[ih].vol;
        finalRow.date1.hainyoCount = finalRow.date1.hainyoCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排尿" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-1, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date2.hainyo = finalRow.date2.hainyo + haisetu[ih].vol;
        finalRow.date2.hainyoCount = finalRow.date2.hainyoCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排尿" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-2, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date3.hainyo = finalRow.date3.hainyo + haisetu[ih].vol;
        finalRow.date3.hainyoCount = finalRow.date3.hainyoCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排尿" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-3, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date4.hainyo = finalRow.date4.hainyo + haisetu[ih].vol;
        finalRow.date4.hainyoCount = finalRow.date4.hainyoCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排尿" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-4, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date5.hainyo = finalRow.date5.hainyo + haisetu[ih].vol;
        finalRow.date5.hainyoCount = finalRow.date5.hainyoCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排尿" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-5, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date6.hainyo = finalRow.date6.hainyo + haisetu[ih].vol;
        finalRow.date6.hainyoCount = finalRow.date6.hainyoCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排尿" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-6, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date7.hainyo = finalRow.date7.hainyo + haisetu[ih].vol;
        finalRow.date7.hainyoCount = finalRow.date7.hainyoCount + 1;
      }

      //
      if (
        haisetu[ih].haisetuKubun === "排便" &&
        haisetu[ih].date === params.endDate
      ) {
        finalRow.date1.haiben = finalRow.date1.haiben + haisetu[ih].vol;
        finalRow.date1.haibenCount = finalRow.date1.haibenCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排便" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-1, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date2.haiben = finalRow.date2.haiben + haisetu[ih].vol;
        finalRow.date2.haibenCount = finalRow.date2.haibenCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排便" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-2, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date3.haiben = finalRow.date3.haiben + haisetu[ih].vol;
        finalRow.date3.haibenCount = finalRow.date3.haibenCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排便" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-3, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date4.haiben = finalRow.date4.haiben + haisetu[ih].vol;
        finalRow.date4.haibenCount = finalRow.date4.haibenCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排便" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-4, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date5.haiben = finalRow.date5.haiben + haisetu[ih].vol;
        finalRow.date5.haibenCount = finalRow.date5.haibenCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排便" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-5, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date6.haiben = finalRow.date6.haiben + haisetu[ih].vol;
        finalRow.date6.haibenCount = finalRow.date6.haibenCount + 1;
      }

      if (
        haisetu[ih].haisetuKubun === "排便" &&
        haisetu[ih].date ===
        dayjs(params.endDate).add(-6, "days").format("YYYY-MM-DD")
      ) {
        finalRow.date7.haiben = finalRow.date7.haiben + haisetu[ih].vol;
        finalRow.date7.haibenCount = finalRow.date7.haibenCount + 1;
      }
    }
  }

  // date1
  if (finalRow.date1.asaStapleIntake || finalRow.date1.asaSideIntake) {
    finalRow.date1.meal =
      "朝(" +
      finalRow.date1.asaStapleIntake +
      "/" +
      finalRow.date1.asaSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date1.asaNasi == false &&
    finalRow.date1.asaStapleIntake === 0 &&
    finalRow.date1.asaSideIntake === 0
  ) {
    finalRow.date1.meal =
      "朝(" +
      finalRow.date1.asaStapleIntake +
      "/" +
      finalRow.date1.asaSideIntake +
      ")\u00A0";
  }

  if (finalRow.date1.hiruStapleIntake || finalRow.date1.hiruSideIntake) {
    finalRow.date1.meal =
      finalRow.date1.meal +
      "昼(" +
      finalRow.date1.hiruStapleIntake +
      "/" +
      finalRow.date1.hiruSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date1.hiruNasi == false &&
    finalRow.date1.hiruStapleIntake === 0 &&
    finalRow.date1.hiruSideIntake === 0
  ) {
    finalRow.date1.meal =
      finalRow.date1.meal +
      "昼(" +
      finalRow.date1.hiruStapleIntake +
      "/" +
      finalRow.date1.hiruSideIntake +
      ")\u00A0";
  }

  if (finalRow.date1.yuuStapleIntake || finalRow.date1.yuuSideIntake) {
    finalRow.date1.meal =
      finalRow.date1.meal +
      "夕(" +
      finalRow.date1.yuuStapleIntake +
      "/" +
      finalRow.date1.yuuSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date1.yuuNasi == false &&
    finalRow.date1.yuuStapleIntake === 0 &&
    finalRow.date1.yuuSideIntake === 0
  ) {
    finalRow.date1.meal =
      finalRow.date1.meal +
      "夕(" +
      finalRow.date1.yuuStapleIntake +
      "/" +
      finalRow.date1.yuuSideIntake +
      ")\u00A0";
  }

  if (finalRow.date1.banStapleIntake || finalRow.date1.banSideIntake) {
    finalRow.date1.meal =
      finalRow.date1.meal +
      "間食(" +
      finalRow.date1.banStapleIntake +
      "/" +
      finalRow.date1.banSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date1.banNasi == false &&
    finalRow.date1.banStapleIntake === 0 &&
    finalRow.date1.banSideIntake === 0
  ) {
    finalRow.date1.meal =
      finalRow.date1.meal +
      "間食(" +
      finalRow.date1.banStapleIntake +
      "/" +
      finalRow.date1.banSideIntake +
      ")\u00A0";
  }

  if (finalRow.date1.keikanStapleIntake || finalRow.date1.keikanSideIntake) {
    finalRow.date1.meal =
      finalRow.date1.meal +
      "経管栄養(" +
      finalRow.date1.keikanStapleIntake +
      "/" +
      finalRow.date1.keikanSideIntake +
      ")";
  }
  else if (
    finalRow.date1.keikanNasi == false &&
    finalRow.date1.keikanStapleIntake === 0 &&
    finalRow.date1.keikanSideIntake === 0
  ) {
    finalRow.date1.meal =
      finalRow.date1.meal +
      "経管栄養(" +
      finalRow.date1.keikanStapleIntake +
      "/" +
      finalRow.date1.keikanSideIntake +
      ")";
  }

  // date2
  if (finalRow.date2.asaStapleIntake || finalRow.date2.asaSideIntake) {
    finalRow.date2.meal =
      "朝(" +
      finalRow.date2.asaStapleIntake +
      "/" +
      finalRow.date2.asaSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date2.asaNasi == false &&
    finalRow.date2.asaStapleIntake === 0 &&
    finalRow.date2.asaSideIntake === 0
  ) {
    finalRow.date2.meal =
      "朝(" +
      finalRow.date2.asaStapleIntake +
      "/" +
      finalRow.date2.asaSideIntake +
      ")\u00A0";
  }

  if (finalRow.date2.hiruStapleIntake || finalRow.date2.hiruSideIntake) {
    finalRow.date2.meal =
      finalRow.date2.meal +
      "昼(" +
      finalRow.date2.hiruStapleIntake +
      "/" +
      finalRow.date2.hiruSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date2.hiruNasi == false &&
    finalRow.date2.hiruStapleIntake === 0 &&
    finalRow.date2.hiruSideIntake === 0
  ) {
    finalRow.date2.meal =
      finalRow.date2.meal +
      "昼(" +
      finalRow.date2.hiruStapleIntake +
      "/" +
      finalRow.date2.hiruSideIntake +
      ")\u00A0";
  }

  if (finalRow.date2.yuuStapleIntake || finalRow.date2.yuuSideIntake) {
    finalRow.date2.meal =
      finalRow.date2.meal +
      "夕(" +
      finalRow.date2.yuuStapleIntake +
      "/" +
      finalRow.date2.yuuSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date2.yuuNasi == false &&
    finalRow.date2.yuuStapleIntake === 0 &&
    finalRow.date2.yuuSideIntake === 0
  ) {
    finalRow.date2.meal =
      finalRow.date2.meal +
      "夕(" +
      finalRow.date2.yuuStapleIntake +
      "/" +
      finalRow.date2.yuuSideIntake +
      ")\u00A0";
  }

  if (finalRow.date2.banStapleIntake || finalRow.date2.banSideIntake) {
    finalRow.date2.meal =
      finalRow.date2.meal +
      "間食(" +
      finalRow.date2.banStapleIntake +
      "/" +
      finalRow.date2.banSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date2.banNasi == false &&
    finalRow.date2.banStapleIntake === 0 &&
    finalRow.date2.banSideIntake === 0
  ) {
    finalRow.date2.meal =
      finalRow.date2.meal +
      "間食(" +
      finalRow.date2.banStapleIntake +
      "/" +
      finalRow.date2.banSideIntake +
      ")\u00A0";
  }

  if (finalRow.date2.keikanStapleIntake || finalRow.date2.keikanSideIntake) {
    finalRow.date2.meal =
      finalRow.date2.meal +
      "経管栄養(" +
      finalRow.date2.keikanStapleIntake +
      "/" +
      finalRow.date2.keikanSideIntake +
      ")";
  }
  else if (
    finalRow.date2.keikanNasi == false &&
    finalRow.date2.keikanStapleIntake === 0 &&
    finalRow.date2.keikanSideIntake === 0
  ) {
    finalRow.date2.meal =
      finalRow.date2.meal +
      "経管栄養(" +
      finalRow.date2.keikanStapleIntake +
      "/" +
      finalRow.date2.keikanSideIntake +
      ")";
  }

  // date3
  if (finalRow.date3.asaStapleIntake || finalRow.date3.asaSideIntake) {
    finalRow.date3.meal =
      "朝(" +
      finalRow.date3.asaStapleIntake +
      "/" +
      finalRow.date3.asaSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date3.asaNasi == false &&
    finalRow.date3.asaStapleIntake === 0 &&
    finalRow.date3.asaSideIntake === 0
  ) {
    finalRow.date3.meal =
      "朝(" +
      finalRow.date3.asaStapleIntake +
      "/" +
      finalRow.date3.asaSideIntake +
      ")\u00A0";
  }

  if (finalRow.date3.hiruStapleIntake || finalRow.date3.hiruSideIntake) {
    finalRow.date3.meal =
      finalRow.date3.meal +
      "昼(" +
      finalRow.date3.hiruStapleIntake +
      "/" +
      finalRow.date3.hiruSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date3.hiruNasi == false &&
    finalRow.date3.hiruStapleIntake === 0 &&
    finalRow.date3.hiruSideIntake === 0
  ) {
    finalRow.date3.meal =
      finalRow.date3.meal +
      "昼(" +
      finalRow.date3.hiruStapleIntake +
      "/" +
      finalRow.date3.hiruSideIntake +
      ")\u00A0";
  }

  if (finalRow.date3.yuuStapleIntake || finalRow.date3.yuuSideIntake) {
    finalRow.date3.meal =
      finalRow.date3.meal +
      "夕(" +
      finalRow.date3.yuuStapleIntake +
      "/" +
      finalRow.date3.yuuSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date3.yuuNasi == false &&
    finalRow.date3.yuuStapleIntake === 0 &&
    finalRow.date3.yuuSideIntake === 0
  ) {
    finalRow.date3.meal =
      finalRow.date3.meal +
      "夕(" +
      finalRow.date3.yuuStapleIntake +
      "/" +
      finalRow.date3.yuuSideIntake +
      ")\u00A0";
  }

  if (finalRow.date3.banStapleIntake || finalRow.date3.banSideIntake) {
    finalRow.date3.meal =
      finalRow.date3.meal +
      "間食(" +
      finalRow.date3.banStapleIntake +
      "/" +
      finalRow.date3.banSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date3.banNasi == false &&
    finalRow.date3.banStapleIntake === 0 &&
    finalRow.date3.banSideIntake === 0
  ) {
    finalRow.date3.meal =
      finalRow.date3.meal +
      "間食(" +
      finalRow.date3.banStapleIntake +
      "/" +
      finalRow.date3.banSideIntake +
      ")\u00A0";
  }

  if (finalRow.date3.keikanStapleIntake || finalRow.date3.keikanSideIntake) {
    finalRow.date3.meal =
      finalRow.date3.meal +
      "経管栄養(" +
      finalRow.date3.keikanStapleIntake +
      "/" +
      finalRow.date3.keikanSideIntake +
      ")";
  }
  else if (
    finalRow.date3.keikanNasi == false &&
    finalRow.date3.keikanStapleIntake === 0 &&
    finalRow.date3.keikanSideIntake === 0
  ) {
    finalRow.date3.meal =
      finalRow.date3.meal +
      "経管栄養(" +
      finalRow.date3.keikanStapleIntake +
      "/" +
      finalRow.date3.keikanSideIntake +
      ")";
  }

  // date4
  if (finalRow.date4.asaStapleIntake || finalRow.date4.asaSideIntake) {
    finalRow.date4.meal =
      "朝(" +
      finalRow.date4.asaStapleIntake +
      "/" +
      finalRow.date4.asaSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date4.asaNasi == false &&
    finalRow.date4.asaStapleIntake === 0 &&
    finalRow.date4.asaSideIntake === 0
  ) {
    finalRow.date4.meal =
      "朝(" +
      finalRow.date4.asaStapleIntake +
      "/" +
      finalRow.date4.asaSideIntake +
      ")\u00A0";
  }

  if (finalRow.date4.hiruStapleIntake || finalRow.date4.hiruSideIntake) {
    finalRow.date4.meal =
      finalRow.date4.meal +
      "昼(" +
      finalRow.date4.hiruStapleIntake +
      "/" +
      finalRow.date4.hiruSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date4.hiruNasi == false &&
    finalRow.date4.hiruStapleIntake === 0 &&
    finalRow.date4.hiruSideIntake === 0
  ) {
    finalRow.date4.meal =
      finalRow.date4.meal +
      "昼(" +
      finalRow.date4.hiruStapleIntake +
      "/" +
      finalRow.date4.hiruSideIntake +
      ")\u00A0";
  }

  if (finalRow.date4.yuuStapleIntake || finalRow.date4.yuuSideIntake) {
    finalRow.date4.meal =
      finalRow.date4.meal +
      "夕(" +
      finalRow.date4.yuuStapleIntake +
      "/" +
      finalRow.date4.yuuSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date4.yuuNasi == false &&
    finalRow.date4.yuuStapleIntake === 0 &&
    finalRow.date4.yuuSideIntake === 0
  ) {
    finalRow.date4.meal =
      finalRow.date4.meal +
      "夕(" +
      finalRow.date4.yuuStapleIntake +
      "/" +
      finalRow.date4.yuuSideIntake +
      ")\u00A0";
  }

  if (finalRow.date4.banStapleIntake || finalRow.date4.banSideIntake) {
    finalRow.date4.meal =
      finalRow.date4.meal +
      "間食(" +
      finalRow.date4.banStapleIntake +
      "/" +
      finalRow.date4.banSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date4.banNasi == false &&
    finalRow.date4.banStapleIntake === 0 &&
    finalRow.date4.banSideIntake === 0
  ) {
    finalRow.date4.meal =
      finalRow.date4.meal +
      "間食(" +
      finalRow.date4.banStapleIntake +
      "/" +
      finalRow.date4.banSideIntake +
      ")\u00A0";
  }

  if (finalRow.date4.keikanStapleIntake || finalRow.date4.keikanSideIntake) {
    finalRow.date4.meal =
      finalRow.date4.meal +
      "経管栄養(" +
      finalRow.date4.keikanStapleIntake +
      "/" +
      finalRow.date4.keikanSideIntake +
      ")";
  }
  else if (
    finalRow.date4.keikanNasi == false &&
    finalRow.date4.keikanStapleIntake === 0 &&
    finalRow.date4.keikanSideIntake === 0
  ) {
    finalRow.date4.meal =
      finalRow.date4.meal +
      "経管栄養(" +
      finalRow.date4.keikanStapleIntake +
      "/" +
      finalRow.date4.keikanSideIntake +
      ")";
  }

  // date5
  if (finalRow.date5.asaStapleIntake || finalRow.date5.asaSideIntake) {
    finalRow.date5.meal =
      "朝(" +
      finalRow.date5.asaStapleIntake +
      "/" +
      finalRow.date5.asaSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date5.asaNasi == false &&
    finalRow.date5.asaStapleIntake === 0 &&
    finalRow.date5.asaSideIntake === 0
  ) {
    finalRow.date5.meal =
      "朝(" +
      finalRow.date5.asaStapleIntake +
      "/" +
      finalRow.date5.asaSideIntake +
      ")\u00A0";
  }

  if (finalRow.date5.hiruStapleIntake || finalRow.date5.hiruSideIntake) {
    finalRow.date5.meal =
      finalRow.date5.meal +
      "昼(" +
      finalRow.date5.hiruStapleIntake +
      "/" +
      finalRow.date5.hiruSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date5.hiruNasi == false &&
    finalRow.date5.hiruStapleIntake === 0 &&
    finalRow.date5.hiruSideIntake === 0
  ) {
    finalRow.date5.meal =
      finalRow.date5.meal +
      "昼(" +
      finalRow.date5.hiruStapleIntake +
      "/" +
      finalRow.date5.hiruSideIntake +
      ")\u00A0";
  }

  if (finalRow.date5.yuuStapleIntake || finalRow.date5.yuuSideIntake) {
    finalRow.date5.meal =
      finalRow.date5.meal +
      "夕(" +
      finalRow.date5.yuuStapleIntake +
      "/" +
      finalRow.date5.yuuSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date5.yuuNasi == false &&
    finalRow.date5.yuuStapleIntake === 0 &&
    finalRow.date5.yuuSideIntake === 0
  ) {
    finalRow.date5.meal =
      finalRow.date5.meal +
      "夕(" +
      finalRow.date5.yuuStapleIntake +
      "/" +
      finalRow.date5.yuuSideIntake +
      ")\u00A0";
  }

  if (finalRow.date5.banStapleIntake || finalRow.date5.banSideIntake) {
    finalRow.date5.meal =
      finalRow.date5.meal +
      "間食(" +
      finalRow.date5.banStapleIntake +
      "/" +
      finalRow.date5.banSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date5.banNasi == false &&
    finalRow.date5.banStapleIntake === 0 &&
    finalRow.date5.banSideIntake === 0
  ) {
    finalRow.date5.meal =
      finalRow.date5.meal +
      "間食(" +
      finalRow.date5.banStapleIntake +
      "/" +
      finalRow.date5.banSideIntake +
      ")\u00A0";
  }

  if (finalRow.date5.keikanStapleIntake || finalRow.date5.keikanSideIntake) {
    finalRow.date5.meal =
      finalRow.date5.meal +
      "経管栄養(" +
      finalRow.date5.keikanStapleIntake +
      "/" +
      finalRow.date5.keikanSideIntake +
      ")";
  }
  else if (
    finalRow.date5.keikanNasi == false &&
    finalRow.date5.keikanStapleIntake === 0 &&
    finalRow.date5.keikanSideIntake === 0
  ) {
    finalRow.date5.meal =
      finalRow.date5.meal +
      "経管栄養(" +
      finalRow.date5.keikanStapleIntake +
      "/" +
      finalRow.date5.keikanSideIntake +
      ")";
  }

  // date6
  if (finalRow.date6.asaStapleIntake || finalRow.date6.asaSideIntake) {
    finalRow.date6.meal =
      "朝(" +
      finalRow.date6.asaStapleIntake +
      "/" +
      finalRow.date6.asaSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date6.asaNasi == false &&
    finalRow.date6.asaStapleIntake === 0 &&
    finalRow.date6.asaSideIntake === 0
  ) {
    finalRow.date6.meal =
      "朝(" +
      finalRow.date6.asaStapleIntake +
      "/" +
      finalRow.date6.asaSideIntake +
      ")\u00A0";
  }

  if (finalRow.date6.hiruStapleIntake || finalRow.date6.hiruSideIntake) {
    finalRow.date6.meal =
      finalRow.date6.meal +
      "昼(" +
      finalRow.date6.hiruStapleIntake +
      "/" +
      finalRow.date6.hiruSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date6.hiruNasi == false &&
    finalRow.date6.hiruStapleIntake === 0 &&
    finalRow.date6.hiruSideIntake === 0
  ) {
    finalRow.date6.meal =
      finalRow.date6.meal +
      "昼(" +
      finalRow.date6.hiruStapleIntake +
      "/" +
      finalRow.date6.hiruSideIntake +
      ")\u00A0";
  }

  if (finalRow.date6.yuuStapleIntake || finalRow.date6.yuuSideIntake) {
    finalRow.date6.meal =
      finalRow.date6.meal +
      "夕(" +
      finalRow.date6.yuuStapleIntake +
      "/" +
      finalRow.date6.yuuSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date6.yuuNasi == false &&
    finalRow.date6.yuuStapleIntake === 0 &&
    finalRow.date6.yuuSideIntake === 0
  ) {
    finalRow.date6.meal =
      finalRow.date6.meal +
      "夕(" +
      finalRow.date6.yuuStapleIntake +
      "/" +
      finalRow.date6.yuuSideIntake +
      ")\u00A0";
  }

  if (finalRow.date6.banStapleIntake || finalRow.date6.banSideIntake) {
    finalRow.date6.meal =
      finalRow.date6.meal +
      "間食(" +
      finalRow.date6.banStapleIntake +
      "/" +
      finalRow.date6.banSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date6.banNasi == false &&
    finalRow.date6.banStapleIntake === 0 &&
    finalRow.date6.banSideIntake === 0
  ) {
    finalRow.date6.meal =
      finalRow.date6.meal +
      "間食(" +
      finalRow.date6.banStapleIntake +
      "/" +
      finalRow.date6.banSideIntake +
      ")\u00A0";
  }

  if (finalRow.date6.keikanStapleIntake || finalRow.date6.keikanSideIntake) {
    finalRow.date6.meal =
      finalRow.date6.meal +
      "経管栄養(" +
      finalRow.date6.keikanStapleIntake +
      "/" +
      finalRow.date6.keikanSideIntake +
      ")";
  }
  else if (
    finalRow.date6.keikanNasi == false &&
    finalRow.date6.keikanStapleIntake === 0 &&
    finalRow.date6.keikanSideIntake === 0
  ) {
    finalRow.date6.meal =
      finalRow.date6.meal +
      "経管栄養(" +
      finalRow.date6.keikanStapleIntake +
      "/" +
      finalRow.date6.keikanSideIntake +
      ")";
  }

  // date7
  if (finalRow.date7.asaStapleIntake || finalRow.date7.asaSideIntake) {
    finalRow.date7.meal =
      "朝(" +
      finalRow.date7.asaStapleIntake +
      "/" +
      finalRow.date7.asaSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date7.asaNasi == false &&
    finalRow.date7.asaStapleIntake === 0 &&
    finalRow.date7.asaSideIntake === 0
  ) {
    finalRow.date7.meal =
      "朝(" +
      finalRow.date7.asaStapleIntake +
      "/" +
      finalRow.date7.asaSideIntake +
      ")\u00A0";
  }

  if (finalRow.date7.hiruStapleIntake || finalRow.date7.hiruSideIntake) {
    finalRow.date7.meal =
      finalRow.date7.meal +
      "昼(" +
      finalRow.date7.hiruStapleIntake +
      "/" +
      finalRow.date7.hiruSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date7.hiruNasi == false &&
    finalRow.date7.hiruStapleIntake === 0 &&
    finalRow.date7.hiruSideIntake === 0
  ) {
    finalRow.date7.meal =
      finalRow.date7.meal +
      "昼(" +
      finalRow.date7.hiruStapleIntake +
      "/" +
      finalRow.date7.hiruSideIntake +
      ")\u00A0";
  }

  if (finalRow.date7.yuuStapleIntake || finalRow.date7.yuuSideIntake) {
    finalRow.date7.meal =
      finalRow.date7.meal +
      "夕(" +
      finalRow.date7.yuuStapleIntake +
      "/" +
      finalRow.date7.yuuSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date7.yuuNasi == false &&
    finalRow.date7.yuuStapleIntake === 0 &&
    finalRow.date7.yuuSideIntake === 0
  ) {
    finalRow.date7.meal =
      finalRow.date7.meal +
      "夕(" +
      finalRow.date7.yuuStapleIntake +
      "/" +
      finalRow.date7.yuuSideIntake +
      ")\u00A0";
  }

  if (finalRow.date7.banStapleIntake || finalRow.date7.banSideIntake) {
    finalRow.date7.meal =
      finalRow.date7.meal +
      "間食(" +
      finalRow.date7.banStapleIntake +
      "/" +
      finalRow.date7.banSideIntake +
      ")\u00A0";
  }
  else if (
    finalRow.date7.banNasi == false &&
    finalRow.date7.banStapleIntake === 0 &&
    finalRow.date7.banSideIntake === 0
  ) {
    finalRow.date7.meal =
      finalRow.date7.meal +
      "間食(" +
      finalRow.date7.banStapleIntake +
      "/" +
      finalRow.date7.banSideIntake +
      ")\u00A0";
  }

  if (finalRow.date7.keikanStapleIntake || finalRow.date7.keikanSideIntake) {
    finalRow.date7.meal =
      finalRow.date7.meal +
      "経管栄養(" +
      finalRow.date7.keikanStapleIntake +
      "/" +
      finalRow.date7.keikanSideIntake +
      ")";
  }
  else if (
    finalRow.date7.keikanNasi == false &&
    finalRow.date7.keikanStapleIntake === 0 &&
    finalRow.date7.keikanSideIntake === 0
  ) {
    finalRow.date7.meal =
      finalRow.date7.meal +
      "経管栄養(" +
      finalRow.date7.keikanStapleIntake +
      "/" +
      finalRow.date7.keikanSideIntake +
      ")";
  }

  return finalRow;
}

export { exportColor, reportWeeklyFormat, updateContentKubun };

