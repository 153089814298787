// redux action types
import { ErrorActionTypes, LifeActionTypes } from "../action_types";

// constants
import { ApiPaths } from "../../constants";

// utils
import { ConfigName } from "../../../config-name";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils";

var FileSaver = require("file-saver");

function getAll(params) {
  return async (dispatch) => {
    try {
      dispatch({ type: LifeActionTypes.GET_ALL_DOCUMENT_REQUEST });
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const si = menu.si || null;
      const query = {
        sid: si,
        inUsed: params.tab && params.tab == "1" && params.inUsed ? 1 : 0,
        searchName: params.searchName,
        riyousyaId: params.riyousyaId,
        fromDate: params.fromDate ? params.fromDate : null,
        toDate: params.toDate ? params.toDate : null,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(params.sort)),
        page: params.page,
        tab: params.tab,
      };
      const { data, meta } = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.GetAllLifeList,
        query,
      });

      dispatch({
        type: LifeActionTypes.GET_ALL_DOCUMENT_SUCCESS,
        data,
        meta,
      });
    } catch (error) {
      dispatch({ type: LifeActionTypes.GET_ALL_DOCUMENT_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function exportData(fromDate, toDate, callback) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const si = menu.si || null;
      const idouSid = menu.iSid && menu.iSid != "-1" ? menu.iSid : null;
      const yobouSid = menu.ySid && menu.ySid != "-1" ? menu.ySid : null;
      const query = {
        sid: si,
        idouSid,
        yobouSid,
        fromDate,
        toDate,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getExportData,
        query,
      });

      callback(data);
    } catch (error) {
      console.log(error);
    }
  };
}

function exportData2(sid, fromDate, toDate, type, ids, yobouSid, idouSid, kokuType, sn, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: LifeActionTypes.EXPORT_CSV_DATE_REQUEST,
      });
      const query = {
        sid,
        fromDate,
        toDate,
        type,
        ids,
        idouSid,
        yobouSid,
        kokuType,
        sn,
      }
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getExportData,
        query,
      });
      dispatch({
        type: LifeActionTypes.EXPORT_CSV_DATE,
        data,
      });
      callback(data);
    } catch (error) {
      console.log(error);
      callback("error");
    }
  };
}

function downloadCsv(type) {
  return async (dispatch) => {
    try {
      const query = {
        type: type,
      };

      const response = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.downloadCsv,
        query,
      });

      FileSaver(response, "SERVICE_USER_INFO.csv");
    } catch (error) {
      console.log(error);
    }
  };
}

function kobetsuCopy(
  fromDate,
  toDate,
  createDate,
  createTantoId,
  fromDateCheck,
  toDateCheck,
  callback
) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const si = menu.si || null;
      const query = {
        sid: si,
        fromDate,
        toDate,
        createDate,
        createTantoId,
        fromDateCheck,
        toDateCheck,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.GetKobetsuCopy,
        query,
      });

      callback(data);
    } catch (error) {
      console.log(error);
    }
  };
}

function jokusoPlanCopy(
  fromDate,
  toDate,
  createDate,
  currentDate,
  createTantoId,
  fromDateCheck,
  toDateCheck,
  callback
) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const si = menu.si || null;
      const query = {
        sid: si,
        fromDate,
        toDate,
        createDate,
        currentDate,
        createTantoId,
        fromDateCheck,
        toDateCheck,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.GetJokusoPlanCopy,
        query,
      });

      callback(data);
    } catch (error) {
      console.log(error);
    }
  };
}

function haisetuCopy(
  fromDate,
  toDate,
  createDate,
  currentDate,
  createTantoId,
  fromDateCheck,
  toDateCheck,
  callback
) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const si = menu.si || null;
      const query = {
        sid: si,
        fromDate,
        toDate,
        createDate,
        currentDate,
        createTantoId,
        fromDateCheck,
        toDateCheck,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.GetHaisetuCopy,
        query,
      });

      callback(data);
    } catch (error) {
      console.log(error);
    }
  };
}

function kokuCopy(
  fromDate,
  toDate,
  createDate,
  currentDate,
  createTantoId,
  fromDateCheck,
  toDateCheck,
  callback
) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const si = menu.si || null;
      const query = {
        sid: si,
        fromDate,
        toDate,
        createDate,
        currentDate,
        createTantoId,
        fromDateCheck,
        toDateCheck,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.GetKokuCopy,
        query,
      });

      callback(data);
    } catch (error) {
      console.log(error);
    }
  };
}

function jirituCopy(
  fromDate,
  toDate,
  createDate,
  currentDate,
  createTantoId,
  fromDateCheck,
  toDateCheck,
  callback
) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const si = menu.si || null;
      const query = {
        sid: si,
        fromDate,
        toDate,
        createDate,
        currentDate,
        createTantoId,
        fromDateCheck,
        toDateCheck,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.GetJirituCopy,
        query,
      });

      callback(data);
    } catch (error) {
      console.log(error);
    }
  };
}

function yakuzaiCopy(
  fromDate,
  toDate,
  createDate,
  currentDate,
  createTantoId,
  fromDateCheck,
  toDateCheck,
  callback
) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const si = menu.si || null;
      const query = {
        sid: si,
        fromDate,
        toDate,
        createDate,
        currentDate,
        createTantoId,
        fromDateCheck,
        toDateCheck,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.GetYakuzaiCopy,
        query,
      });

      callback(data);
    } catch (error) {
      console.log(error);
    }
  };
}

function kagakutekiCopy(
  fromDate,
  toDate,
  createDate,
  currentDate,
  createTantoId,
  fromDateCheck,
  toDateCheck,
  callback
) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const si = menu.si || null;
      const query = {
        sid: si,
        fromDate,
        toDate,
        createDate,
        currentDate,
        createTantoId,
        fromDateCheck,
        toDateCheck,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.GetKagakutekiCopy,
        query,
      });

      callback(data);
    } catch (error) {
      console.log(error);
    }
  };
}

function keepValueSearch(params) {
  return (dispatch) => {
    dispatch({ type: LifeActionTypes.KEEP_VALUE_SEARCH, keepValue: params });
  };
}

export const LifeActions = {
  getAll,
  keepValueSearch,
  kagakutekiCopy,
  kobetsuCopy,
  jokusoPlanCopy,
  haisetuCopy,
  kokuCopy,
  jirituCopy,
  yakuzaiCopy,
  exportData,
  downloadCsv,
  exportData2,
};
