import { MinusSquareOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import {
  addRowsKyotakuPlan2,
  changeEditMobileMode,
  changeEditMode,
  changeExpandedRowKey,
  changeKaigohoken,
  changeValuePlan2,
  createKyotakuPlan2,
  getKyotakuPlan2,
  updateKyotakuPlan2,
} from "../../../actions/kyotakuPlan2Actions/actionCreators";
import { loadRiyouKaigohokenList } from "../../../actions/riyouKaigohoken/actionCreators";
import { getRiyousya } from "../../../actions/riyousyaActions/actionCreators";
import { CommonPageContainer } from "../../../common/component/IdleTimerPage";
import { getUrlVars } from "../../../common/function_common/functionCommon";
import { resetStatus } from "./../../../actions/kyotakuPlan2Actions/actionCreators";
import KyotakuPlan2InputFormWrapper from "./KyotakuPlan2InputForm";
import { processRow } from "./processData";


import "../inputPlan1/InputForm.css";
class KyotakuPlan2 extends Component {
  constructor(props) {
    super(props);
    const { riyousyaId, kyotakuPlan2Id } = this.props.match.params;

    this.state = {
      riyousyaId,
      kyotakuPlan2Id,
      visibleConfirm: false,
      visibleEdit: false,
      checkView: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { kyotakuPlan2Id } = this.props.match.params;
    const { checkView } = this.state;
    if (
      checkView == false &&
      kyotakuPlan2Id == "new" &&
      nextProps.status !== this.props.status &&
      nextProps.status === "success"
    ) {
      this.props.history.push(
        `/input-kyotaku-plan2/${nextProps.kyotakuPlan2.riyousyaId}/${nextProps.kyotakuPlan2.id}`
      );
    }

    if (
      checkView == true &&
      kyotakuPlan2Id == "new" &&
      nextProps.status !== this.props.status &&
      nextProps.status === "success"
    ) {
      this.setState({
        checkView: false,
      });
      this.props.history.push(
        `/input-kyotaku-plan2/${nextProps.kyotakuPlan2.riyousyaId}/${nextProps.kyotakuPlan2.id}`
      );
      const win = window.open(
        `/check-info-document-list/${nextProps.kyotakuPlan2.riyousyaId}/${nextProps.kyotakuPlan2.id}`,
        "_blank"
      );
      win.focus();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.status !== null) {
      this.props.resetStatus();
    }
    const { riyousyaId, kyotakuPlan2Id } = this.props.match.params;
    if (
      riyousyaId !== prevProps.match.params.riyousyaId ||
      (kyotakuPlan2Id !== prevProps.match.params.kyotakuPlan2Id &&
        kyotakuPlan2Id !== "new")
    ) {
      const copyFrom = getUrlVars(window.location.href)["copy-from"];
      if (copyFrom) {
        this.props.getRiyousyaInfo(riyousyaId);
        this.props.getKyotakuPlan2(copyFrom);
      }
      else {
        this.props.getRiyousyaInfo(riyousyaId);
        this.props.getKyotakuPlan2(kyotakuPlan2Id);
      }
      this.props.getListRiyouKaigohoken(riyousyaId);
    }
  }

  async componentDidMount() {
    const copyFrom = getUrlVars(window.location.href)["copy-from"];
    if (copyFrom) {
      this.props.getRiyousyaInfo(copyFrom);
      this.props.getKyotakuPlan2(copyFrom);
    }
    else {
      this.props.getRiyousyaInfo(this.state.riyousyaId);
      this.props.getKyotakuPlan2(this.state.kyotakuPlan2Id);
    }
    this.props.getListRiyouKaigohoken(this.state.riyousyaId);
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  showModalEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };

  handleEditOk = async () => {
    this.setState({
      visibleEdit: false,
    });
    if (this.props.valuesChange) {
      this.props.changeValuePlan2(false);
    }
    // if (this.props.edit) {
    //   await this.props.changeEditMode(false);
    // }
    this.props.changeEditMobileMode(false);
    this.props.changeExpandedRowKey(null);
    this.props.changeKaigohoken(null);
    this.props.history.push(`/document-list/${this.state.riyousyaId}`);
  };

  handleEditCancel = () => {
    this.setState({
      visibleEdit: false,
    });
  };

  handleSave = async () => {
    const { form } = this.formRef.props;
    let valuesTmp;
    form.validateFields((err, values) => {
      valuesTmp = { ...values };
    });
    const { kyotakuPlan2Id } = this.props.match.params;
    if (this.props.edit) {
      await this.props.changeEditMode(false);
    }

    if (this.props.valuesChange) {
      await this.props.changeValuePlan2(false);
    }
    // const { form } = this.formRef.props;
    const { kyotakuPlan2Rows } = this.props;
    form.validateFields((err) => {
      if (err) {
        return;
      }
      const formatDataRow = kyotakuPlan2Rows.map((item) => processRow(item, kyotakuPlan2Id));
      // const newValues = { ...values, seikatu_mokuhyous: formatDataRow };
      // if (Array.isArray(values.riyou_kaigohoken_id)) {
      //   newValues.riyou_kaigohoken_id = null;
      // }
      const newValues = { ...valuesTmp, seikatu_mokuhyous: formatDataRow };
      if (Array.isArray(valuesTmp.riyou_kaigohoken_id)) {
        newValues.riyou_kaigohoken_id = null;
      }
      const renewYears = newValues.renew_years
        ? dayjs(newValues.renew_years).format("YYYYMM")
        : null;
      delete newValues.seikatuMokuhyous;
      if (kyotakuPlan2Id === "new") {
        this.props.createKyotakuPlan2(
          {
            ...newValues,
            progressF: newValues.progressF.length,
            renew_years: renewYears,
          },
          this.state.riyousyaId
        );
      }
      else {
        this.props.updateKyotakuPlan2(
          {
            ...newValues,
            progressF: newValues.progressF.length,
            renew_years: renewYears,
          },
          kyotakuPlan2Id
        );
      }
      this.props.changeKaigohoken(null);
    });
    if (!this.props.edit) {
      await this.props.changeEditMode(true);
    }
  };

  handleSaveAndNewTab = async (e) => {
    e.preventDefault();
    const { kyotakuPlan2Id } = this.props.match.params;
    this.setState({
      checkView: true,
    });
    if (this.props.edit) {
      await this.props.changeEditMode(false);
    }

    if (this.props.valuesChange) {
      await this.props.changeValuePlan2(false);
    }
    const { form } = this.formRef.props;
    const { kyotakuPlan2Rows } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const formatDataRow = kyotakuPlan2Rows.map((item) => processRow(item, kyotakuPlan2Id));
      const newValues = { ...values, seikatu_mokuhyous: formatDataRow };
      if (Array.isArray(values.riyou_kaigohoken_id)) {
        newValues.riyou_kaigohoken_id = null;
      }
      const renewYears = newValues.renew_years
        ? dayjs(newValues.renew_years).format("YYYYMM")
        : null;
      delete newValues.seikatuMokuhyous;
      if (kyotakuPlan2Id === "new") {
        this.props.createKyotakuPlan2(
          {
            ...newValues,
            progressF: newValues.progressF.length,
            renew_years: renewYears,
          },
          this.state.riyousyaId
        );
      }
      else {
        this.props.updateKyotakuPlan2(
          {
            ...newValues,
            progressF: newValues.progressF.length,
            renew_years: renewYears,
          },
          kyotakuPlan2Id
        );
      }
      this.props.changeKaigohoken(null);
    });

    if (kyotakuPlan2Id != "new") {
      const win = window.open(
        `/check-info-document-list/${this.state.riyousyaId}/${kyotakuPlan2Id}`,
        "_blank"
      );
      await win.focus();
    }
    if (!this.props.edit) {
      await this.props.changeEditMode(true);
    }
  };

  handleGoBack = async (e) => {
    e.preventDefault();
    const { form } = this.formRef.props;
    if (form.isFieldsTouched() || this.props.valuesChange) {
      this.showModalEdit();
    }
    else {
      // if (this.props.edit) {
      //   await this.props.changeEditMode(false);
      // }
      this.props.changeEditMobileMode(false);
      this.props.changeExpandedRowKey(null);
      this.props.history.push(`/document-list/${this.state.riyousyaId}`);
    }
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.changeExpandedRowKey(null);
    this.props.history.goBack();
  }

  goBackAfterEdit = async () => {
    // if (this.props.edit) {
    //   await this.props.changeEditMode(false);
    // }
    this.props.changeEditMobileMode(false);
  };

  handleCancel = () => {
    this.setState({
      visibleEdit: false,
    });
  };

  render() {
    const { loading } = this.props;
    const { kyotakuPlan2Id } = this.props.match.params;
    const copyFrom = getUrlVars(window.location.href)["copy-from"];
    return (
      (<CommonPageContainer
        id={"input-kyotaku-plan2-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <div className="back">
          <MediaQuery query="(min-device-width: 768px)">
            {(matches) => {
              if (matches) {
                return (
                  <Spin spinning={loading ? loading : false}>
                    {this.renderHeader()}
                    <div style={{ maxWidth: "1046px", margin: "0px auto", padding: "20px 15px 350px 15px" }} className="main">
                      <div style={{ margin: "0 10px" }}>
                        <KyotakuPlan2InputFormWrapper
                          data={this.props.kyotakuPlan2}
                          tantoMasters={this.props.tantoMasters}
                          wrappedComponentRef={this.saveFormRef}
                          riyousyaId={this.state.riyousyaId}
                          kyotakuPlan2Id={kyotakuPlan2Id}
                          copyFrom={copyFrom}
                        />
                      </div>
                    </div>
                  </Spin>
                );
              }
              return (
                <Spin spinning={loading ? loading : false}>
                  {this.renderHeaderMobile()}
                  <div style={{ margin: "10px", minHeight: "100vh" }}>
                    <KyotakuPlan2InputFormWrapper
                      data={this.props.kyotakuPlan2}
                      tantoMasters={this.props.tantoMasters}
                      wrappedComponentRef={this.saveFormRef}
                      riyousyaId={this.state.riyousyaId}
                      kyotakuPlan2Id={kyotakuPlan2Id}
                      copyFrom={copyFrom}
                    />
                  </div>
                  {this.renderFooterMobile()}
                </Spin>
              );
            }}
          </MediaQuery>
          <Modal
            open={this.state.visibleConfirm}
            title="キャンセル確認"
            onCancel={() => this.closeGoBackConfirm()}
            footer={[
              <Button
                key="delete"
                type={"primary"}
                onClick={() => this.handleConfirmGoBack()}
              >
                OK
              </Button>,
              <Button
                key="canceldelete"
                onClick={() => this.closeGoBackConfirm()}
              >
                Cancel
              </Button>,
            ]}
          >
            <span style={{ display: "block" }}>
              {"キャンセルしてもよろしいですか？"}
            </span>
          </Modal>
          <Modal
            open={this.state.visibleEdit}
            title="キャンセル確認"
            onOk={this.handleEditOk}
            onCancel={this.handleCancel}
          >
            <span style={{ display: "block" }}>
              {"キャンセルしてもよろしいですか？"}
            </span>
          </Modal>
        </div>
      </CommonPageContainer>)
    );
  }

  renderHeader() {
    const { kyotakuPlan2Id } = this.props.match.params;
    return (
      <Row className={"submenu fixed-header"} style={{ display: "flex" }}>
        <Col span={12} className={"sm-c-left"}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【計画書2】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
        <Col span={12} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right", marginBottom: '4px' }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <div style={{ cursor: "pointer" }} onClick={this.handleSave}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{ display: "block", fontWeight: "bold", color: "red", marginBottom: '4px' }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </div>
          {kyotakuPlan2Id != "new" && (
            <Link to={"/"} onClick={this.handleSaveAndNewTab}>
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold", marginBottom: '4px' }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          )}
        </Col>
      </Row>
    );
  }

  renderHeaderMobile() {
    return (
      <Row className={"submenu fixed-header"} style={{ display: "flex" }}>
        <Col span={16} className={"sm-c-left"} style={{ color: "white" }}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【計画書2】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
        <Col
          span={8}
          type="fl"
          className={"sm-c-right"}
          style={this.props.editDetail ? {} : { display: "none" }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Col style={{ marginRight: "5px" }}>
              {this.props.edit ? (
                <Button
                  type="primary"
                  danger
                  onClick={() => this.props.changeEditMode(false)}
                >
                  適用
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => this.props.changeEditMode(true)}
                >
                  修正
                </Button>
              )}
            </Col>
            <Col style={{ color: "white" }}>
              <span onClick={() => this.goBackAfterEdit()}>{"戻る"}</span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  renderFooterMobile() {
    const { kyotakuPlan2Id } = this.props.match.params;
    return (
      <Row className={"submenu fixed-footer"}>
        <Col
          span={24}
          className={"sm-c-right"}
          style={this.props.editDetail ? { display: "none" } : {}}
        >
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <div style={{ cursor: "pointer" }} onClick={this.handleSave}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{ display: "block", fontWeight: "bold", color: "red" }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </div>
          {kyotakuPlan2Id != "new" && (
            <Link to={"/"} onClick={this.handleSaveAndNewTab}>
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          )}
        </Col>
        <Col
          span={24}
          type="fl"
          className={"sm-c-right"}
          style={this.props.editDetail ? {} : { display: "none" }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Col style={{ marginRight: "5px" }}>
              {this.props.edit ? (
                <Button
                  type="primary"
                  danger
                  onClick={() => this.props.changeEditMode(false)}
                >
                  適用
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => this.props.changeEditMode(true)}
                >
                  修正
                </Button>
              )}
            </Col>
            <Col style={{ color: "white" }}>
              <div onClick={() => this.props.changeEditMobileMode(false)}>
                {"戻る"}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documentListPlan2: state.documentList.dataPlan2,
    kyotakuPlan2: state.kyotakuPlan2.kyotakuPlan2,
    kyotakuPlan2Rows: state.kyotakuPlan2.kyotakuPlan2Rows,
    notifyDisplay: state.kyotakuPlan2.notifyDisplay,
    notifyContent: state.kyotakuPlan2.notifyContent,
    riyousya: state.riyousya.riyousya,
    edit: state.kyotakuPlan2.editMode,
    loading: state.documentList.loading,
    editDetail: state.kyotakuPlan2.editDetail,
    valuesChange: state.kyotakuPlan2.valuesChange,
    expandedRowKeys: state.kyotakuPlan2.expandedRowKeys,
    status: state.kyotakuPlan2.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
    getKyotakuPlan2: (id) => dispatch(getKyotakuPlan2(id)),
    createKyotakuPlan2: (params, id, callback) =>
      dispatch(createKyotakuPlan2(params, id, callback)),
    addRowsKyotakuPlan2: () => dispatch(addRowsKyotakuPlan2()),
    updateKyotakuPlan2: (values, id, callback) =>
      dispatch(updateKyotakuPlan2(values, id, callback)),
    getListRiyouKaigohoken: (riyousyaId) =>
      dispatch(loadRiyouKaigohokenList(riyousyaId)),
    changeEditMode: (mode) => dispatch(changeEditMode(mode)),
    changeExpandedRowKey: (key) => dispatch(changeExpandedRowKey(key)),
    changeEditMobileMode: (editDetail) =>
      dispatch(changeEditMobileMode(editDetail)),
    changeValuePlan2: (type) => dispatch(changeValuePlan2(type)),
    changeKaigohoken: (value) => dispatch(changeKaigohoken(value)),
    resetStatus: () => dispatch(resetStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KyotakuPlan2);
