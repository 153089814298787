import {RESET_STATUS, KEEP_VALUE, UPDATE_LIFE_FUNCTION_SUCCESS, UPDATE_LIFE_FUNCTION_FAIL, GET_LIFE_FUNCTION_SUCCESS, GET_LIFE_FUNCTION_ERROR } from "../../actions/lifeFunctionActions/actionName";
import { notification } from "antd/lib/index";

export default function LifeFunctionReducers(state = {
  loading: true
}, action) {
  switch (action.type) {
    // case GET_LIFE_FUNCTION:
    //   return {
    //     ...state,
    //     data: action.data,
    //     loading: false
    //   };

    case RESET_STATUS:
      return {
        ...state,
        notifyContentEdit: {}
      }
    case KEEP_VALUE:
      return {
        ...state,
        data: action.data
      };

    case GET_LIFE_FUNCTION_SUCCESS:
      return{
        ...state,
        data: action.data,
        loading: false
      }
    case GET_LIFE_FUNCTION_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false
      }
    case UPDATE_LIFE_FUNCTION_SUCCESS:
      notification["success"]({
        message: "保存しました",
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        ...state,
        data: action.data,
        loading: false,
        notifyContentEdit: { type: "success" }
      };

    case UPDATE_LIFE_FUNCTION_FAIL:
      notification["error"]({
        message: action.error.message,
        placement: "topRight",
        duration: 3,
        style: { whiteSpace: "pre-wrap" },
      });
      return {
        state,
        data: action.data,
        loading: false,
        notifyContentEdit: { type: "error" }
      };
    default:
      return state;
  }
}