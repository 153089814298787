
/*
  input:
  attendanceString = "a1, t1, n1; a2, t2, n2"

  output:
  attendanceArray = [{
    affiliation: "a1",
    type: "t1",
    name: "n1"
  }, {
    affiliation: "a2",
    type: "t2",
    name: "n2"
  }]
*/
function stringToAttendanceArray(attendanceString, listName, adlMaster) {
  if (!attendanceString) { //空かチェック
    return null  
  }

  const attendanceArray = [];  //結果
  let naiyouArray = null
    if(listName && adlMaster) {
      naiyouArray = adlMaster[listName]
    }
  
  if(!naiyouArray || !naiyouArray.length > 0){//空かチェック
    return null
  }

  naiyouArray.map((item) => { //先にadlMasterを回す
    if(item.naiyou){  //naiyou確認
      let tmp = attendanceString.find(a => a.adlMasterId == item.id)
      if(tmp) {
        let naiyou = item.naiyou
        let answer = tmp.answer
        let comment = tmp.comment

        attendanceArray.push({
          naiyou: naiyou && naiyou.trim(),
          answer: answer && answer.trim(),
          comment: comment && comment.trim(),
        });
      }
    }
  })
  
  return attendanceArray;
}

function lineBreakAttendance(text) {
  // remove last ;
  let newText = text.replace(/;$/, "");

  // replace all ; with \n
  newText = newText.replace(/;/g, "\n");

  return newText;
}

export {
  stringToAttendanceArray,
  lineBreakAttendance
};