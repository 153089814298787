import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

class ButtonGroup extends PureComponent {
  render() {
    const { onClick } = this.props;

    return (
      <div className="tanto-kaigi-button-group">
        <Button onClick={() => onClick(1)}>テンプレート呼出</Button>
        <Button onClick={() => onClick(2)}>テンプレート保存</Button>
      </div>
    );
  }
}

ButtonGroup.propTypes = {
  onClick: PropTypes.func,
};

export default ButtonGroup;
