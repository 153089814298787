import React from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Row, Col, Button, Popover, Table, Spin, Select } from "antd";
import { black } from "material-ui/styles/colors";

const Search = Input.Search;
const Option = Select.Option;

class NormalInputFukuyakuKB extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: "",
      visible: false,
    };

    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onRowTableSelect = this.onRowTableSelect.bind(this);
    this.handleChangeBunrui = this.handleChangeBunrui.bind(this);
    this.handleSearchDrug = this.handleSearchDrug.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.value !== newProps.value) {
      this.props.form.resetFields();
    }
  }

  onBlur = (e) => {
    this.props.handleInputSelect(e.target.value);
  };

  handleChange = (value) => {
    this.props.handleInputSelect(value);
  };

  onRowTableSelect(name) {
    this.props.handleInputSelect(name);
  }

  handleSearchDrug(value) {
    this.props.handleDrugSearch(this.state.selected, value);
  }

  handleChangeBunrui(value) {
    this.setState({
      selected: value,
    });
  }

  handleClickChange = (visible) => {
    this.setState({
      visible: visible,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { value, drugList, drugLoading, dataBunrui, disabled } = this.props;

    const columns = [
      {
        title: "name",
        dataIndex: "name",
        width: 300,
      },
      {
        title: "bunrui",
        dataIndex: "bunrui",
      },
    ];

    const content = (
      <div style={{ width: "430px" }}>
        <Row className="pad-bt-8">
          <Col span={16}>
            <Search onSearch={this.handleSearchDrug} enterButton={"検索"} />
          </Col>
          <Col span={8}>
            <Select
              defaultValue={this.state.selected}
              style={{ width: "100%" }}
              onChange={this.handleChangeBunrui}
            >
              <Option value="" key={0}>
                全表示
              </Option>
              {dataBunrui
                ? dataBunrui
                    .toString()
                    .split(";")
                    .map((item, index) => {
                      return (
                        <Option key={index + 1} value={item}>
                          {item}
                        </Option>
                      );
                    })
                : null}
            </Select>
          </Col>
        </Row>
        <Row>
          <Spin spinning={drugLoading}>
            <Table
              bordered
              columns={columns}
              dataSource={drugList}
              pagination={{ pageSize: 6 }}
              scroll={{ y: 240 }}
              showHeader={false}
              size={"small"}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    this.onRowTableSelect(record.name);
                  },
                };
              }}
            />
          </Spin>
        </Row>
      </div>
    );

    return (
      <Row>
        <Col span={20}>
          {getFieldDecorator("ect", {
            rules: [
              {
                required: false,
              },
            ],
            initialValue: value,
          })(
            <Input.TextArea
              disabled={disabled ? disabled : false}
              rows={2}
              onBlur={this.onBlur}
              style={{ borderRadius: "4px" }}
            />
          )}
        </Col>
        <Col span={4} style={{ textAlign: "center" }}>
          <Popover
            // visible={false}
            placement={"bottomRight"}
            title={
              <a
                onClick={this.handleClickChange.bind(this, false)}
                style={{ float: "right", color: black }}
              >
                閉じる
              </a>
            }
            content={content}
            trigger="click"
            onVisibleChange={this.handleClickChange}
            visible={this.state.visible}
          >
            <Button
              disabled={disabled ? disabled : false}
              type="primary"
              style={{ margin: "0 10px" }}
            >
              選択
            </Button>
          </Popover>
        </Col>
      </Row>
    );
  }
}

const InputFukuyakuKB = Form.create()(NormalInputFukuyakuKB);
export default InputFukuyakuKB;
