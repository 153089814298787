/* eslint-disable indent */
import { TableListActionTypes } from "../../actions/kanriActions/action_types";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

const INITIAL_STATE = {
    tableList: [],
    tableDetail: [],
    tableRecord: [],
    pagination: {
        current: 1,
        pageSize: 99,
        total: 0,
        position: "both",
    },
};

function kanriTable(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TableListActionTypes.GET_TABLE_LIST_KANRI: {
            return {
                ...state,
                tableList: action.payload
            };
        }

        case TableListActionTypes.GET_TABLE_DETAIL_KANRI: {
            return {
                ...state,
                tableDetail: action.payload
            };
        }

        case TableListActionTypes.GET_TABLE_RECORD_KANRI: {
            return {
                ...state,
                tableRecord: action.payload.result,
                pagination: {
                    current: action.payload.page,
                    pageSize: action.payload.pageSize,
                    total: action.payload.totalCount,
                },
            };
        }

        case TableListActionTypes.TABLE_KANRI_ERROR_REQUEST: {
            openNotificationWithIcon("error", "エラー");
            return {
                ...state,
                loading: false,
            };
        }

        default: {
            return {
                ...state,
                loading: false,
            };
        }
    }
}

export default kanriTable;
