export const DocumentTabConstants = {
  IndexTab: {
    Plan1: "1",
    Plan2: "2",
    WeeklyPlan: "3",
    UseSlip: "4",
    Monitoring: "5",
    Meeting: "6",
    InquiryRequest: "7",
    Progress: "8",
    Task: "9",
  },
  IndexTabYobou: {
    Facesheet: "1",
    KihonCheck: "2",
    YobousienPlan: "3",
    WeeklyPlan: "4",
    UseSlip: "5",
    Hyoukahyou: "6",
    Meeting: "7",
    Progress: "8",
  },

  Array: [
    "plan1",
    "plan2",
    "weekly-plan",
    "useslip",
    "monitoring",
    "meeting",
    "inquiry-request",
    "progress",
    "task",
  ],
  ArrayYobou: [
    "Facesheet",
    "KihonCheck",
    "YobousienPlan",
    "WeeklyPlan",
    "UseSlip",
    "Hyoukahyou",
    "Meeting",
    "Progress",
  ],

  Input: [
    "input-plan1",
    "input-plan2",
    "input-weekly-schedule",
    "input-useslip",
    "input-monitoring",
    "input-tanto-kaigi",
    "input-inquiry-request",
    "input-keika-kiroku",
    "input-task",
    "",
  ],
  InputYobou: [
    "input-facesheet",
    "input-kihon-check",
    "input-yobousien-plan",
    "input-weekly-schedule",
    "input-useslip",
    "input-hyoukahyou",
    "input-tanto-kaigi",
    "input-keika-kiroku",
    "",
  ],
};
