import React from "react";
import { Modal, Table } from "antd";
import { connect } from "react-redux";

class RiyouKaigohokenModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRiyouKaigohoken: this.props.selected,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selected !== this.props.selected)
      this.setState({
        selectedRiyouKaigohoken: nextProps.selected,
      });
  }

  handleOk() {
    this.props.handleSelectKaigohoken(this.state.selectedRiyouKaigohoken);
  }

  handleCancel() {
    this.props.hideModalKaigohoken();
    this.setState({
      selectedRiyouKaigohoken: this.props.selected,
    });
  }

  handleSelectKaigohoken(record) {
    this.setState({
      selectedRiyouKaigohoken: record,
    });
  }

  render() {
    const columns = [
      {
        title: "介護度",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "有効期限",
        dataIndex: "expiration",
        key: "expiration",
      },
      {
        title: "保険者",
        dataIndex: "hokensyaNo",
        key: "hokensyaNo",
      },
      {
        title: "被保番",
        dataIndex: "hihokenNo",
        key: "hihokenNo",
      },
    ];
    const dataSource = this.props.riyouKaigohokens
      ? this.props.riyouKaigohokens.map((k) => {
          return {
            id: k.id,
            name: k.name,
            expiration: k.yukoDateFrom + "～" + k.yukoDateTo,
            hokensyaNo: k.hokensyaNo,
            hihokenNo: k.hihokenNo,
          };
        })
      : [];
    return (
      (<Modal
        open={this.props.visible}
        title={"保険を選択してください"}
        onOk={() => this.handleOk()}
        onCancel={() => this.handleCancel()}
        width={500}
      >
        <Table
          id="riyou-kaigo"
          size={"small"}
          columns={columns}
          pagination={false}
          rowKey={"id"}
          dataSource={dataSource}
          onRow={(record) => {
            return {
              onClick: () => this.handleSelectKaigohoken(record.id),
            };
          }}
          rowClassName={(record) =>
            this.state.selectedRiyouKaigohoken != null &&
            record.id === this.state.selectedRiyouKaigohoken
              ? "pljp-table-row-hover"
              : "pointer-row"
          }
        />
      </Modal>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    riyousya: state.progress.riyousya,
    riyouKaigohokens: state.riyouKaigohokens.riyouKaigohokens,
  };
};

export default connect(mapStateToProps, null)(RiyouKaigohokenModal);
