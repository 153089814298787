/* eslint-disable no-nested-ternary */
import { Col, Divider, Row } from "antd";
import dayjs from "dayjs";
import React from "react";
import LineBreakText from "../../../common/component/LineBreakText";
import {
  formatStorage,
  getMenu,
  isManagement,
} from "../../../common/function_common/functionCommon";
import { displayJpDateKanji } from "../../../common/function_common/functionDisplay";

const BasicInfo = (props) => {
  const {
    bunruiIsDis,
    bunruiTitle,
    systemBunruiIsDis,
    systemBunruiTitle,
    tekiyouIsDis,
    tekiyouTitle,
    faxTitle,
  } = props;

  const isMNGT = isManagement();
  const systemName = formatStorage(getMenu()).sn;

  return (
    <div>
      <Row>
        {isMNGT ? (
          <>
            <Col sm={8} md={8}>
              <span className="rdp-basic-tab-title">整理番号</span>
            </Col>
            <Col sm={16} md={16}>
              <span className="rdp-basic-tab-content">
                {systemName === "kyotaku" &&
                  props?.riyousya?.riyousyaKyotaku &&
                  props.riyousya.riyousyaKyotaku[0]
                  ? props.riyousya.riyousyaKyotaku[0].code
                  : ""}
                {(systemName === "syoukibo" || systemName === "fukugo") &&
                  props?.riyousya?.riyousyaSyoukibo &&
                  props.riyousya.riyousyaSyoukibo[0]
                  ? props.riyousya.riyousyaSyoukibo[0].code
                  : ""}
                {systemName === "yobou" &&
                  props?.riyousya?.riyousyaYobou &&
                  props.riyousya.riyousyaYobou[0]
                  ? props.riyousya.riyousyaYobou[0].code
                  : ""}
              </span>
            </Col>
            <Divider />
          </>
        ) : (
          ""
        )}
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">名前</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya ? props.riyousya.name : ""}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">フリガナ</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya ? props.riyousya.furigana : ""}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">生年月日</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya &&
              props.riyousya.birthday &&
              props.riyousya.birthday !== "0001-01-01"
              ? `${displayJpDateKanji(
                props.riyousya.birthday
              )}  ${dayjs().diff(
                dayjs(props.riyousya.birthday, "YYYY-MM-DD"),
                "years"
              )}歳`
              : null}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">性別</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya &&
              props.riyousya.gender &&
              props.riyousya.gender === 1
              ? "男"
              : "女"}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">郵便番号</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya ? props.riyousya.zip : ""}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">住所1</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya ? props.riyousya.address1 : ""}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">住所2</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya ? props.riyousya.address2 : ""}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">その他住所</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya ? props.riyousya.sonotaAddress : ""}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">電話番号</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya ? props.riyousya.tel : ""}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">
            {faxTitle ? faxTitle : "FAX"}
          </span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya ? props.riyousya.fax : ""}
          </span>
        </Col>
        <Divider />
      </Row>

      {props && props.bunruiIsDis && bunruiIsDis
        ? bunruiIsDis
          .toString()
          .split(";")
          .map((item, index) => {
            if (item !== "none" && index < 5) {
              // 空でもOK
              return (
                <Row key={index}>
                  <Col sm={8} md={8}>
                    <span className="rdp-basic-tab-title">
                      {props && props.bunruiTitle && bunruiTitle
                        ? bunruiTitle.toString().split(",")[index]
                          ? bunruiTitle
                            .toString()
                            .split(",")[index].toString()
                            .split("=")[1]
                          : null
                        : null}
                    </span>
                  </Col>
                  <Col sm={16} md={16}>
                    <span className="rdp-basic-tab-content">
                      {props && props.riyousya
                        ? props.riyousya[`${"bunrui" + (index + 1)}`] // ここで分類の表示
                        : ""}
                    </span>
                  </Col>
                  <Divider />
                </Row>
              );
            }
          })
        : null}

      {props && props.systemBunruiIsDis && systemBunruiIsDis
        ? systemBunruiIsDis
          .toString()
          .split(";")
          .map((item, index) => {
            if (item !== "none" && index < 5) {
              return (
                <Row key={index}>
                  <Col sm={8} md={8}>
                    <span className="rdp-basic-tab-title">
                      {props && props.systemBunruiTitle && systemBunruiTitle
                        ? systemBunruiTitle.toString().split(",")[index]
                          ? systemBunruiTitle
                            .toString()
                            .split(",")[index].toString()
                            .split("=")[1]
                          : null
                        : null}
                    </span>
                  </Col>
                  <Col sm={16} md={16}>
                    <span className="rdp-basic-tab-content">
                      {props && props.riyousya
                        ? props.riyousya[`${"systemBunrui" + (index + 1)}`]
                        : ""}
                    </span>
                  </Col>
                  <Divider />
                </Row>
              );
            }
          })
        : null}

      {props && props.tekiyouIsDis && tekiyouIsDis
        ? tekiyouIsDis
          .toString()
          .split(";")
          .map((item, index) => {
            if (item !== "none" && index < 3) {
              return (
                <Row key={index}>
                  <Col sm={8} md={8}>
                    <span className="rdp-basic-tab-title">
                      {props && props.tekiyouTitle && tekiyouTitle
                        ? tekiyouTitle.toString().split(",")[index]
                          ? tekiyouTitle
                            .toString()
                            .split(",")[index].toString()
                            .split("=")[1]
                          : null
                        : null}
                    </span>
                  </Col>
                  <Col sm={16} md={16}>
                    <LineBreakText
                      text={
                        props &&
                          props.riyousya &&
                          props.riyousya[`${"tekiyo" + (index + 1)}`]
                          ? props.riyousya[`${"tekiyo" + (index + 1)}`]
                          : "　"
                      }
                    />
                  </Col>
                  <Divider />
                </Row>
              );
            }
          })
        : null}
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">入金分類</span>
        </Col>
        <Col sm={16} md={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya ? props.riyousya.nyukinBunrui : ""}
          </span>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col sm={8} md={8}>
          <span className="rdp-basic-tab-title">入金摘要</span>
        </Col>
        <Col sm={16} md={16}>
          <LineBreakText
            text={props.riyousya ? props.riyousya.nyukinTekiyou : "　"}
          />
        </Col>
        <Divider />
      </Row>
    </div>
  );
};

export default BasicInfo;
