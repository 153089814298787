import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const RiyousyaApi = {
  async getRiyousya(riyousyaId) {
    const res = await axios(
      apiUrls.INIT + apiUrls.RIYOUSYA_GET + "?riyousyaId=" + riyousyaId,
      {
        method: "GET",
      }
    );
    return res.data;
  },
  async getRiyousyaIds() {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.INIT + apiUrls.GET_LIST_RIYOUSYA_ID, {
        params: {
          systemName: menu.sn,
          mainSid: menu.si,
        },
      });
    return res.data;
  },

  async riyouChartTemplateSave(values) {
    const res = await axios(
      apiUrls.INIT + apiUrls.SAVE_TEMPLATE_RIYOU_CHART,
      {
        method: "POST", data: values
      }
    );
    return res.data;
  },
  async riyouChartTemplateLoad() {
    const res = await axios(
      apiUrls.INIT + apiUrls.LOAD_TEMPLATE_RIYOU_CHART,
      {
        method: "GET",
      });
    return res.data;
  },
  async riyouChartTemplateDelete(id) {
    const res = await axios(
      apiUrls.INIT + apiUrls.DELETE_TEMPLATE_RIYOU_CHART,
      {
        method: "DELETE",
        params: {
          id
        }
      });
    return res.data;
  },
};

export { RiyousyaApi };
