/* eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
import { linearDistance } from "./utils";

const fabric = require("fabric").fabric;

class CircleText extends FabricCanvasTool {
  constructor(canvas, text) {
    super(canvas);
    this._text = text;
  }

  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => o.selectable = o.evented = false);
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.call_back = props.update_select;
  }

  doMouseDown(o) {
    const canvas = this._canvas;
    this.isDown = true;
    this.ismove = false;
    const pointer = canvas.getPointer(o.e);
    [this.startX, this.startY] = [pointer.x, pointer.y];
    const circle = new fabric.Circle({
      left: 0, top: 0,
      originX: "left", originY: "center",
      strokeWidth: 1,
      stroke: this._color,
      fill: this._fill,
      selectable: false,
      evented: false,
      radius: 20
    });
    const txt = new fabric.Text(this._text, {
      left: 10,
      top: 0,
      originX: "left",
      originY: "center",
      fontSize: 20,
    });
    this.groupc = new fabric.Group([circle, txt], {
      left: this.startX,
      top: this.startY,
      angle: 0,
      selectable: true,
    });
    //canvas.add(this.groupc);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    this.ismove = true;
    const canvas = this._canvas;
    const pointer = canvas.getPointer(o.e);
    this.groupc.set(
      {
        scaleX: pointer.x / this.startX,
        scaleY: pointer.x / this.startX,
      }
    );
    canvas.remove(this.groupc);
    canvas.add(this.groupc);
    canvas.renderAll();
  }

  doMouseUp(o) {
    const canvas = this._canvas;
    this.isDown = false;
    if (!this.ismove) {
      canvas.remove(this.groupc);
      return;
    }
    this.isDown = false;
    canvas.remove(this.groupc);
    canvas.add(this.groupc);
    this.call_back();
  }
}

export default CircleText;