import React, { useState, useEffect } from "react";
import { Modal, Button, Select } from "antd";
const { Option } = Select;
function ServiceNaiyouModal(props) {
  const [selectOptionArr, setSelectOptionArr] = useState([]);
  const [serviceNaiyou, setServiceNaiyou] = useState();

  useEffect(() => {
    if (props.recordSelected) {
      setServiceNaiyou(props.recordSelected.serviceNaiyou);
    }
    if (props.serviceNaiyou.length && props.recordSelected) {
      if (props.serviceNaiyou[0].serviceType == "ショート") {
        return setSelectOptionArr(
          props.serviceNaiyou.filter(
            (item) => item.kaigoServiceSyuruiCd == props.recordSelected.syuruiCd
          )
        );
      }
    }
  }, [props.recordSelected]);

  function handleChangeServiceNaiyou(value) {
    setServiceNaiyou(value);
  }
  function handleCancel() {
    props.onCancel();
  }

  function handleOk() {
    props.onOk(props.recordSelected.key, serviceNaiyou);
  }
  return (
    (<Modal
      open={props.visible}
      title={props.title}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={handleCancel}
        >
          キャンセル
        </Button>,
        <Button key="back" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
      width={350}
    >
      <div>
        {props.titleName}
        <Select
          value={serviceNaiyou}
          style={{ width: 150, marginLeft: 10 }}
          onChange={handleChangeServiceNaiyou}
        >
          {props.typeShort &&
            selectOptionArr.map((item) => (
              <Option key={item.id} value={item.serviceNaiyou}>
                {item.serviceNaiyou}
              </Option>
            ))}
          {!props.typeShort &&
            props.serviceNaiyou.map((item) => (
              <Option key={item.id} value={item.serviceNaiyou}>
                {item.serviceNaiyou}
              </Option>
            ))}
        </Select>
      </div>
    </Modal>)
  );
}

export { ServiceNaiyouModal };
