import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import { notification, Row, Button, Checkbox } from "antd";

import WrappedOpenSLForm from "./UI/OpenSLForm";
import ActionsRow from "./UI/ActionsRow";
import { getPath } from "../../common/function_common/functionCommon";

class KanriOpenScreenLogSave extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      openId: parseInt(match.params.openId),
      currentPage: props.currentOpenSL,
      pageSize: props.pageSizeOpenSL, // @TODO setting in openSL,
      ids: props.openSLSelectedRows,
      path: getPath(),
    };

    this.registerOpenSL = this.registerOpenSL.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    // load data
    const { ids, openId } = this.state;

    this.props.getOpenSLSingleRecord(openId, ids, null);
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent, kanriOpenSL } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }

    if (kanriOpenSL != oldProps.kanriOpenSL) {
      if (kanriOpenSL && kanriOpenSL.id) {
        this.setState({
          openId: kanriOpenSL.id,
        });

        this.props.history.replace(
          this.state.path + "/kanri/openscreenlog/" + kanriOpenSL.id
        );
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  async registerOpenSL(values) {
    if (values.id) {
      await this.props.updateOpenSL(values);
    } else {
      await this.props.createOpenSL(values);
    }
    this.props.history.goBack();
  }

  async delete(id) {
    await this.props.deleteOpenSL(id.toString());

    this.props.history.goBack();
  }

  preToEnd = () => {
    const { ids, openId } = this.state;
    this.props.getOpenSLSingleRecord(openId, ids, "fastBack");
  };

  pre = () => {
    const { ids, openId } = this.state;
    this.props.getOpenSLSingleRecord(openId, ids, "stepBack");
  };

  next = () => {
    const { ids, openId } = this.state;
    this.props.getOpenSLSingleRecord(openId, ids, "stepForwad");
  };

  nextToEnd = () => {
    // const { kanriOpenSLList } = this.props;
    // const { openId } = this.state;

    // if (kanriOpenSLList && kanriOpenSLList.length > 0) {
    //     let index = kanriOpenSLList.findIndex((item) => item.id === openId);

    //     if (index !== kanriOpenSLList.length) {

    //         this.setState({
    //             openId: kanriOpenSLList[kanriOpenSLList.length - 1].id
    //         })

    //         this.props.history.replace("/kanri/openscreenlog/" + kanriOpenSLList[kanriOpenSLList.length - 1].id);
    //     }
    // }
    const { ids, openId } = this.state;
    this.props.getOpenSLSingleRecord(openId, ids, "fastForwad");
  };

  recordMark = (type, id) => {
    this.props.recordMark("open", type, id);
  };

  render() {
    const { kanriOpenSL, openSLSelectedRows } = this.props;
    const { openId } = this.state;

    return (
      <KanriLayoutContainer router="open" breadcrumbLink={"/kanri/openscreenlog"} breadcrumbText={"画面遷移ログ"}>
        <ActionsRow
          key={openSLSelectedRows}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          selectedList={openSLSelectedRows}
          id={openId}
        />

        <Row>
          <WrappedOpenSLForm
            data={kanriOpenSL}
            history={this.props.history}
            regData={(values) => this.registerOpenSL(values)}
            delete={this.delete}
          />
        </Row>
      </KanriLayoutContainer>
    );
  }
}

export default KanriOpenScreenLogSave;
