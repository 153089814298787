import {
  LOAD_SPIN_REQUEST,
  LOAD_MONITORING_SUCCESS,
  LOAD_MONITORING_ERROR,
  DELETE_MONITORING_SUCCESS,
  DELETE_MONITORING_ERROR,
  LOAD_MONITORING_BY_ID_SUCCESS,
  LOAD_MONITORING_BY_ID_ERROR,
  UPDATE_MONITORING_SUCCESS,
  UPDATE_MONITORING_ERROR,
  GET_RESULT_MONITORING,
  CHANGE_EXPANDED_ROW_KEY,
  GET_ROWINFO_MONITORING,
  UPDATE_ROWS_MONITORING,
  CHANGE_EDIT_MODE,
  UPDATE_OPTION_OF_MONITORING,
  CREATE_MONITORING_SUCCESS,
  CREATE_MONITORING_ERROR,
  RESET_STATUS,
  QUOTE_PLAN2_TO_ROWS,
  UPDATE_MONITORING_HYOKAS,
  ADD_NEW_MONITORING_HYOKA,
  KEEP_VALUE_MONITORING,
  LOAD_MONITORING_SPIN,
  REMOVE_MONITORING_HYOKA,
} from "./actionName";

import { MonitoringApi } from "../../api/monitoring";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
import { KyotakuPlan2Api } from "../../api/kyotakuPlan2";
export function resetStatus() {
  return (dispatch) =>
    dispatch({
      type: RESET_STATUS,
    });
}
export function loadMonitoring(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    MonitoringApi.loadMonitoring(data).then(
      (data) => {
        dispatch({
          type: LOAD_MONITORING_SUCCESS,
          monitorings: data.data,
          meta: data.meta,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_MONITORING_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function deleteMonitoring(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    MonitoringApi.deleteMonitoring(id).then(
      (data) =>
        dispatch({
          type: DELETE_MONITORING_SUCCESS,
          deleteMonitoring: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: DELETE_MONITORING_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function getMonitoringById(id) {
  return (dispatch) => {
    if (id === "new" || id === 0) {
      return dispatch({
        type: LOAD_MONITORING_BY_ID_SUCCESS,
        monitoring: null,
      });
    }
    dispatch({
      type: LOAD_MONITORING_SPIN,
    });
    MonitoringApi.getMonitoringById(id).then(
      (data) =>
        dispatch({
          type: LOAD_MONITORING_BY_ID_SUCCESS,
          monitoring: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_MONITORING_BY_ID_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function createMonitoring(params, riyousyaId, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_MONITORING_SPIN,
    });
    MonitoringApi.createMonitoring(params, riyousyaId).then(
      (data) =>
        dispatch({
          type: CREATE_MONITORING_SUCCESS,
          createdMonitoring: data,
          callback,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_MONITORING_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function updateMonitoring(params, id, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_MONITORING_SPIN,
    });
    MonitoringApi.updateMonitoring(params, id).then(
      (data) =>
        dispatch({
          type: UPDATE_MONITORING_SUCCESS,
          updatedMonitoring: data,
          callback,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_MONITORING_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function getResultMonitoring(data) {
  return {
    type: GET_RESULT_MONITORING,
    results: data,
  };
}
export function changeExpandedRowKey(key) {
  return {
    type: CHANGE_EXPANDED_ROW_KEY,
    key,
  };
}
export function getRowInfolMonitoring(data) {
  return {
    type: GET_ROWINFO_MONITORING,
    rowInfo: data,
  };
}
export function updateRowMonitoring(sortNum, data) {
  return {
    type: UPDATE_ROWS_MONITORING,
    data,
    sortNum,
  };
}
export function updateMonitoringHyoka(data) {
  return {
    type: UPDATE_MONITORING_HYOKAS,
    data,
  };
}
export function changeEditMode(mode) {
  return {
    type: CHANGE_EDIT_MODE,
    mode,
  };
}
export function updateOptionOfMonitoring(
  attainment,
  condition,
  satisPerson,
  satisFamily,
  cope,
  values
) {
  return {
    type: UPDATE_OPTION_OF_MONITORING,
    attainment,
    condition,
    satisPerson,
    satisFamily,
    cope,
    values,
  };
}
export function quotePlan2ToMonitoringRows(plan2Id) {
  return (dispatch) => {
    KyotakuPlan2Api.getKyotakuPlan2ById(plan2Id).then(
      (data) =>
        dispatch({
          type: QUOTE_PLAN2_TO_ROWS,
          kyotakuPlan2: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: QUOTE_PLAN2_TO_ROWS,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function addMonitoringHyoka() {
  return {
    type: ADD_NEW_MONITORING_HYOKA,
  };
}

export function keepValueMonitoring(data) {
  return {
    type: KEEP_VALUE_MONITORING,
    ...data,
  };
}

export function removeMonitoringHyoka(index) {
  return {
    type: REMOVE_MONITORING_HYOKA,
    index,
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
