export function processRow(data, kyotakuPlan2Id) {
  const NewItemdata = {};
  NewItemdata.details = data?.details.map((item) => processDetails(item));
  NewItemdata.id = data.id ?? 0;
  NewItemdata.kyotaku_plan2_id = data.kyotakuPlan2Id ?? (kyotakuPlan2Id !== "new" ? parseInt(kyotakuPlan2Id) : 0);
  NewItemdata.seikatu_koi = data.seikatuKoi || null;
  NewItemdata.long_mokuhyou = data.longMokuhyou || null;
  NewItemdata.long_kikan = data.longKikan || null;
  NewItemdata.short_mokuhyou = data.shortMokuhyou || null;
  NewItemdata.short_kikan = data.shortKikan || null;
  NewItemdata.sort_num = data.sortNum;
  return NewItemdata;
}

export function processDetails(data) {
  const NewItemdata = {};
  NewItemdata.id = data.id ?? 0;
  NewItemdata.kyotaku_plan2_seikatu_mokuhyou_id = data.kyotakuPlan2SeikatuMokuhyouId ?? 0;
  NewItemdata.sien_naiyou = data.sienNaiyou;
  NewItemdata.siensya_bunrui = data.siensyaBunrui;
  NewItemdata.service = data.service;
  NewItemdata.jigyousyo = data.jigyousyo;
  NewItemdata.hindo = data.hindo;
  NewItemdata.kikan = data.kikan;
  NewItemdata.sort_num = data.sortNum;
  return NewItemdata;
}
