import {
  LOAD_KAIGOCHOSA_REQUEST,
  LOAD_KAIGOCHOSA_SUCCESS,
  LOAD_KAIGOCHOSA_ERROR,
  RESET_STATUS
} from "../../actions/kaigochosaActions/actionName";

const INITIAL_STATE = {
  messages: [],
  notifyDisplay: 0

};
export default function kaigochosaReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        notifyContentInsert: {},
        notifyContentEdit: {}
      };
    case LOAD_KAIGOCHOSA_REQUEST:
      return {
        ...state
      };
    case LOAD_KAIGOCHOSA_SUCCESS:
      return {
        ...state,
        loading: false,
        kaigochosa: action.data
      };
    case LOAD_KAIGOCHOSA_ERROR:
      return {
        ...state,
        loading: false,
        kaigochosa: action.data
      };
    default:
      return state;

  }
}