import { Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import { guid } from "../../../common/timePicker/formatTimePicker";

const columns = [
  { title: "", dataIndex: "kubun", key: "kubun", width: 68, align: "left" },
  {
    title: "前日在所数",
    dataIndex: "atHospitalYesterday",
    key: "atHospitalYesterday",
    width: 80,
  },
  { title: "入所数", dataIndex: "inHospital", key: "inHospital", width: 80 },
  { title: "退所数", dataIndex: "outHospital", key: "outHospital", width: 68 },
  {
    title: "本日在所数",
    dataIndex: "atHospitalToday",
    key: "atHospitalToday",
    width: 68,
  },
  {
    title: "入院",
    dataIndex: "inHospitalTreatment",
    key: "inHospitalTreatment",
    width: 35,
  },
  { title: "外泊", dataIndex: "sleepOutside", key: "sleepOutside", width: 35 },
  { title: "外出", dataIndex: "goOut", key: "goOut", width: 35 },
];

class NumberPeopleOutIn extends React.Component {
  sum(total, num) {
    return total + num;
  }
  render() {
    const { statisticalOutIn } = this.props;
    const data = Array.isArray(statisticalOutIn)
      ? statisticalOutIn.map((e) => {
        return {
          key: guid(),
          kubun: e.kubun,
          atHospitalYesterday: e.atHospitalYesterday,
          inHospital: e.inHospital,
          outHospital: e.outHospital,
          atHospitalToday: e.atHospitalToday,
          inHospitalTreatment: e.inHospitalTreatment,
          sleepOutside: e.sleepOutside,
          goOut: e.goOut,
        };
      })
      : [];
    const dataTable = [
      ...data,
      {
        key: guid(),
        kubun: "合計",
        atHospitalYesterday: data.reduce(function (total, num) {
          return total + num.atHospitalYesterday;
        }, 0),
        inHospital: data.reduce(function (total, num) {
          return total + num.inHospital;
        }, 0),
        outHospital: data.reduce(function (total, num) {
          return total + num.outHospital;
        }, 0),
        atHospitalToday: data.reduce(function (total, num) {
          return total + num.atHospitalToday;
        }, 0),
        inHospitalTreatment: data.reduce(function (total, num) {
          return total + num.inHospitalTreatment;
        }, 0),
        sleepOutside: data.reduce(function (total, num) {
          return total + num.sleepOutside;
        }, 0),
        goOut: data.reduce(function (total, num) {
          return total + num.goOut;
        }, 0),
      },
    ];
    return (
      <div>
        <h4>本日入退所状況数</h4>
        <Table
          rowKey={"key"}
          className={"tableItem3032"}
          columns={columns}
          dataSource={dataTable}
          size="small"
          pagination={false}
          bordered={true}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    statisticalOutIn: state.dutyReport.statisticalOutIn,
  };
};
export default connect(mapStateToProps, null)(NumberPeopleOutIn);
