import {
  ALL_ROOM_REQUEST,
  LOAD_ROOM_SUCCESS,
  LOAD_ROOM_REQUEST,
  LOAD_ROOM_ERROR,
  SAVE_ROOM_REQUEST,
  SAVE_ROOM_LIST_SUCCESS,
  SAVE_ROOM_ERROR,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_ERROR,
  UPDATE_ROOM_SUCCESS,
  UPDATE_ROOM_ERROR,
  ROOM_MASTER_INIT_SUCCESS,
} from "../../actions/roomMasterActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

export default function roomMasterReducers(state = {}, action) {
  switch (action.type) {
    case ALL_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROOM_MASTER_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        room: action.payload,
      };
    case LOAD_ROOM_SUCCESS:
      return {
        ...state,
        rooms: action.payload,
        loading: false,
      };
    case SAVE_ROOM_LIST_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateRoomList: action.payload,
        loading: false,
      };
    case CREATE_ROOM_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        createRoom: action.payload,
        loading: false,
      };
    case UPDATE_ROOM_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateRoom: action.payload,
        loading: false,
      };
    case LOAD_ROOM_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        rooms: action.payload,
        loading: false,
      };
    case CREATE_ROOM_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        createRoom: null,
        loading: false,
      };
    case UPDATE_ROOM_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        updateRoom: null,
        loading: false,
      };
    case SAVE_ROOM_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        updateRoomList: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
