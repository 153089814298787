import { ApiPaths } from "../../constants/api_paths";
import { RequestUtils } from "../../utils/index";
import { ItakuTantoMasterActionTypes } from "../action_types/itakuTantoMaster";
import { ErrorActionTypes, WeeklyPlanActionTypes } from "../action_types";

function getById(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_ID_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetById,
        params: { id },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_ID_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_ID_FAILURE,
        error,
      });
    }
  };
}

function getInitialServiceKikan() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_INITIAL_ITAKU_TANTO_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetInitialServiceKikan,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_INITIAL_ITAKU_TANTO_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_INITIAL_ITAKU_TANTO_FAILURE,
        error,
      });
    }
  };
}

function createServiceKikanMaster(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.CREATE_ITAKU_TANTO_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ItakuTantoMaster.Create,
        body,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.CREATE_ITAKU_TANTO_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.CREATE_ITAKU_TANTO_FAILURE,
        error,
      });
    }
  };
}

function updateServiceKikanMaster(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.UPDATE_ITAKU_TANTO_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.ItakuTantoMaster.Update,
        body,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.UPDATE_ITAKU_TANTO_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.UPDATE_ITAKU_TANTO_FAILURE,
        error,
      });
    }
  };
}

function getServiceSyuruiMasters() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetServiceSyuruiMasters,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_FAILURE,
        error,
      });
    }
  };
}

function getSyuruiCdByServiceType(serviceType) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetSyuruiCdByServiceType,
        query: { serviceType },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_FAILURE,
        error,
      });
    }
  };
}

function getServiceAdditions(syuruiCd) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetServiceAdditions,
        query: { syuruiCd },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_FAILURE,
        error,
      });
    }
  };
}

function searchHojinInfoMasters(searchText) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.SearchHojinInfoMasters,
        query: { searchText },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_FAILURE,
        error,
      });
    }
  };
}

function checkLinkedWeeklySchedule(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.CHECK_LINKED_WEEKLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.CheckLinkedWeeklySchedule,
        query: { id },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.CHECK_LINKED_WEEKLY_SUCCESS,
        data: { sid: id, isLinked: data },
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.CHECK_LINKED_WEEKLY_FAILURE,
        error,
      });
    }
  };
}

function createHojinInfoMaster(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.CREATE_HOJIN_INFO_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ItakuTantoMaster.CreateHojinInfoMaster,
        body,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.CREATE_HOJIN_INFO_MASTER_SUCCESS,
        data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.CREATE_HOJIN_INFO_MASTER_FAILURE,
        error,
      });
      return null;
    }
  };
}

function getHojinInfoMasterById(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_HOJIN_INFO_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetHojinInfoMasterById,
        params: { id },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_HOJIN_INFO_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_HOJIN_INFO_MASTER_FAILURE,
        error,
      });
    }
  };
}

function duplicateServiceKikanMaster(sid, syuruiId) {
  return async (dispatch) => {
    try {
      return await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ItakuTantoMaster.Duplicate,
        query: { sid, syuruiId },
      });
    } catch (error) {
      return null;
    }
  };
}

function getServiceKikanMonthlyById(id) {}

function createServiceKikanMonthly(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.CREATE_SERVICE_MONTHLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ItakuTantoMaster.CreateServiceKikanMonthly,
        body,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.CREATE_SERVICE_MONTHLY_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.CREATE_SERVICE_MONTHLY_FAILURE,
        error,
      });
    }
  };
}

function updateServiceKikanMonthly(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.UPDATE_SERVICE_MONTHLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.ItakuTantoMaster.UpdateServiceKikanMonthly,
        body,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.UPDATE_SERVICE_MONTHLY_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.UPDATE_SERVICE_MONTHLY_FAILURE,
        error,
      });
    }
  };
}

function deleteServiceKikanMonthly(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.DELETE_SERVICE_MONTHLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "DELETE",
        path: ApiPaths.ItakuTantoMaster.DeleteServiceKikanMonthly,
        params: { id },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.DELETE_SERVICE_MONTHLY_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.DELETE_SERVICE_MONTHLY_FAILURE,
        error,
      });
    }
  };
}

function getListServiceKikanMonthly(sid) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_LIST_SERVICE_MONTHLY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetListServiceKikanMonthly,
        query: { sid },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_LIST_SERVICE_MONTHLY_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_LIST_SERVICE_MONTHLY_FAILURE,
        error,
      });
    }
  };
}

function getTaiseiMaster(query) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_TAISEI_MASTERS_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetTaiseiMaster,
        query,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_TAISEI_MASTERS_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_TAISEI_MASTERS_FAILURE,
        error,
      });
    }
  };
}

function getTikibetuTikikubun(query) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetTikibetuTikikubun,
        query,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_FAILURE,
        error,
      });
    }
  };
}

function getTikiKubunMaster(query) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_TIKI_KUBUN_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetTikiKubunMaster,
        query,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_TIKI_KUBUN_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_TIKI_KUBUN_MASTER_FAILURE,
        error,
      });
    }
  };
}

function getListByServiceType(serviceType, name, kaigoCode) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SERVICE_TYPE_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetListByServiceType,
        query: { serviceType, name, kaigoCode },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SERVICE_TYPE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SERVICE_TYPE_FAILURE,
        error,
      });
    }
  };
}

function getListBySyuruiCd(syuruiCd, name) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SYURUI_CD_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetListBySyuruiCd,
        query: { syuruiCd, name },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SYURUI_CD_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SYURUI_CD_FAILURE,
        error,
      });
    }
  };
}

function save(data) {
  return async (dispatch) => {
    const body = { ...data };
    try {
      dispatch({ type: ItakuTantoMasterActionTypes.SAVE_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ItakuTantoMaster.Save,
        body,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.SAVE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.SAVE_FAILURE,
        error,
      });
    }
  };
}

function getListByServiceTypePlan2(serviceType, name) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SERVICE_TYPE_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetListByServiceTypePlan2,
        query: { serviceType, name },
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SERVICE_TYPE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_ITAKU_TANTO_BY_SERVICE_TYPE_FAILURE,
        error,
      });
    }
  };
}

function turnOffReload() {
  return (dispatch) =>
    dispatch({
      type: ItakuTantoMasterActionTypes.TURN_OFF_RELOAD_SERVICE_KIKAN,
    });
}

function getMaxSortNum() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_MAX_SORT_NUM_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetMaxSortNum,
      });
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_MAX_SORT_NUM_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ItakuTantoMasterActionTypes.GET_MAX_SORT_NUM_FAILURE,
        error,
      });
    }
  };
}

// function getHojinInfoMasterByName(jigyousyoName) { //oldメソッド
//   return async () => {
//     try {
//       return RequestUtils.callApi({
//         method: "GET",
//         path: ApiPaths.ItakuTantoMaster.GetHojinInfoMasterByName,
//         query: { jigyousyoName }
//       });
//     } catch (error) {
//       return null;
//     }
//   };
// }

function getHojinInfoMasterByName(jigyousyoName, callback) {
  return () => {
    try {
      RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ItakuTantoMaster.GetHojinInfoMasterByName,
        query: { jigyousyoName },
      }).then((data) => {
        return callback(data);
      });
    } catch (error) {
      return null;
    }
  };
}

export const ItakuTantoMasterActions = {
  getById,
  getInitialServiceKikan,
  createServiceKikanMaster,
  updateServiceKikanMaster,
  getServiceSyuruiMasters,
  getServiceAdditions,
  searchHojinInfoMasters,
  checkLinkedWeeklySchedule,
  createHojinInfoMaster,
  getHojinInfoMasterById,
  duplicateServiceKikanMaster,
  getServiceKikanMonthlyById,
  createServiceKikanMonthly,
  updateServiceKikanMonthly,
  deleteServiceKikanMonthly,
  getListServiceKikanMonthly,
  getTaiseiMaster,
  getTikibetuTikikubun,
  getTikiKubunMaster,
  getListByServiceType,
  getListBySyuruiCd,
  save,
  getListByServiceTypePlan2,
  getSyuruiCdByServiceType,
  turnOffReload,
  getMaxSortNum,
  getHojinInfoMasterByName,
};
