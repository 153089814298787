import React, { Fragment } from "react";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import {
  Input,
  Button,
  Popover,
  Table,
  Popconfirm,
  Divider,
  Modal,
  Row,
  Select,
  Col,
  Spin,
} from "antd";
import { connect } from "react-redux";
import {
  convertArrStrToArrObj,
  convertSortArr,
} from "../../../common/function_common/functionDisplay";
import _ from "lodash";
import TextInputArea from "./TextInputArea";
import TextInput from "./TextInput";
import ByoumeiMasterEditModal from "../../../common/component/control/ByoumeiMasterEditModal";
import DrugMasterEditModal from "../../../common/component/control/DrugMasterEditModal";
import MachineMasterEditModal from "../../../common/component/control/MachineMasterEditModal";
import {
  updateListByoumeiCreator,
  updateListMachineCreator,
  updateListDrugCreator,
} from "../../../actions/allMasterActions/actionCreators";
import { resolveOnChange } from "antd/lib/input/Input";

const EditableCell = ({
  editIndex,
  editValue,
  value,
  index,
  onChange,
  Expansion,
  sort,
}) => (
  <div>
    {editIndex === index ? (
      <Input
        style={{ margin: "-5px 0" }}
        defaultValue={editValue}
        onBlur={(e) => onChange(e.target.value)}
      />
    ) : Expansion && !sort ? (
      <div
        style={{
          maxWidth: "310px",
          whiteSpace: "normal",
          overflowWrap: "normal",
        }}
      >
        {value}
      </div>
    ) : (
      <div
        style={{
          maxWidth: "310px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </div>
    )}
  </div>
);

class TextAreaSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editIndex: null,
      editValue: "",
      editKey: null,
      newText: "",
      visible: false,
      yougoVisible: false,
      rowActive: null,
      record: null,
      bunrui: "",
      name: "",
      byoumeiVisibleEdit: false,
      drugVisibleEdit: false,
      machineVisibleEdit: false,
      byoumeiEditType: null,
      drugEditType: null,
      machineEditType: null,
      selected: null,
      selectSort: false,
      masterSort: false,
      sortTable: [],
      sortTableCopy: [],
      data: [],
      Expansion: false,
      masterExpansion: false,
    };

    // bind place
  }

  componentDidMount() {}

  /**
   * 開いたときに検索結果の初期化
   *
   *
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible != this.state.visible && this.state.visible) {
      this.props.search();
    }
    // if(prevProps.dataMaster != this.props.dataMaster && this.props.dataMaster){ //もらったプロップスが違うならdataにセット

    // }
  }

  /**
   * Render colum of table
   *
   * (EDITABLE TABLE)
   */
  renderColumns = (record, index) => {
    const { editIndex, editValue, Expansion, selectSort } = this.state;

    return (
      <EditableCell
        sort={selectSort}
        Expansion={Expansion}
        editIndex={editIndex}
        editValue={editValue}
        value={record && record.naiyou ? record.naiyou : ""}
        index={index}
        onChange={(value) => this.handleChange(value)}
      />
    );
  };

  /**
   * When input change on EDITING MODE
   *
   */
  handleChange = (value) => {
    this.setState({
      editValue: value,
    });
  };

  /**
   * Handle Row Table
   *
   */
  edit = (record, index) => {
    this.setState({
      editValue: record.naiyou,
      editIndex: index,
      editKey: record.key,
    });
  };

  /**
   * Save function after eddting row
   *
   */
  save = () => {
    const { editIndex, editValue, editKey } = this.state;
    if (editValue) {
      const { dataSelect } = this.props;

      // let tmpUpdate = dataSelect

      if (dataSelect[editKey]) {
        let tmpUpdate = dataSelect;
        tmpUpdate[editKey] = editValue;

        this.props.yougoMasterUpdate(tmpUpdate.join(";"));
      }
    }

    // reset state
    this.setState({
      editIndex: null,
      editValue: "",
      editKey: null,
    });
  };

  /**
   * Remove record of yougo_master naiyou
   *
   */
  delete = (record, index) => {
    const { dataSelect } = this.props;
    let preNaiyou = "";
    for (let i = 0; i < dataSelect.length; i++) {
      if (i !== record.key) {
        if (!preNaiyou) {
          preNaiyou = dataSelect[i];
        } else {
          preNaiyou = preNaiyou + ";" + dataSelect[i];
        }
      }
    }

    this.props.yougoMasterUpdate(preNaiyou);
  };

  /**
   * Cancel table input mode. (Remove input mode on row of table)
   *
   */
  cancel = (key) => {
    // reset state
    this.setState({
      editIndex: null,
      editValue: "",
    });
  };

  /**
   * Input for Add new function
   *
   */
  handleOnchangeNew = (value) => {
    this.setState({
      newText: value,
    });
  };

  /**
   * Add new record when click 追加 button(title place)
   *
   */
  add = () => {
    const { newText } = this.state;
    if (newText) {
      const { dataSelect } = this.props;
      let preNaiyou = newText;

      if (dataSelect) {
        for (let i = 0; i < dataSelect.length; i++) {
          preNaiyou = preNaiyou + ";" + dataSelect[i];
        }
      }

      this.props.yougoMasterUpdate(preNaiyou);

      // reset
      this.setState({
        newText: "",
      });
    }
  };

  /**
   * trigger dirrectly from input
   *
   */
  select = (record) => {
    const { value, isOveride } = this.props;
    let tpm = "";

    if (record && record.naiyou) {
      if (value && !isOveride) {
        tpm = value + "、" + record.naiyou;
      } else {
        tpm = record.naiyou;
      }
    }

    this.setState({
      Expansion: false,
    });

    this.triggerChange(tpm);
  };

  /**
   * triiger from table select row (yougo_master)
   *
   */
  handleSelectChange = (value) => {
    try {
      this.triggerChange(value);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Should provide an event to pass value to Form.
   *
   */
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    try {
      if (onChange) {
        onChange(changedValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleOk = () => {
    const { record } = this.state;
    const { dataMasterType, value } = this.props;

    let cloneRecord = _.cloneDeep(record);

    if (dataMasterType === "byoumei" && cloneRecord) {
      let tmp = "";
      if (value) {
        // tmp = value + '、' + cloneRecord.byoumei
        tmp = cloneRecord.byoumei;
      } else {
        tmp = cloneRecord.byoumei;
      }

      this.triggerChange(tmp);
    }

    if (dataMasterType === "drug" && cloneRecord) {
      let tmp = "";
      if (value) {
        // tmp = value + '、' + cloneRecord.name
        tmp = cloneRecord.name;
      } else {
        tmp = cloneRecord.name;
      }

      this.triggerChange(tmp);
    }

    if (dataMasterType === "machine" && cloneRecord) {
      let tmp = "";
      if (value) {
        // tmp = value + '、' + cloneRecord.name
        tmp = cloneRecord.name;
      } else {
        tmp = cloneRecord.name;
      }

      this.triggerChange(tmp);
    }

    if (dataMasterType === "serviceKikan" && cloneRecord) {
      let tmp = "";
      if (value) {
        // tmp = value + '、' + cloneRecord.jigyousyoName
        tmp = cloneRecord.jigyousyoName;
      } else {
        tmp = cloneRecord.jigyousyoName;
      }

      this.triggerChange(tmp);
    }

    if (dataMasterType === "iryouKikan" && cloneRecord) {
      let tmp = "";
      if (value) {
        // tmp = value + '、' + cloneRecord.hospital
        tmp = cloneRecord.hospital;
      } else {
        tmp = cloneRecord.hospital;
      }

      this.triggerChange(tmp);
    }

    // reset
    this.setState({
      visible: false,
      record: null,
      bunrui: "",
      name: "",
      rowActive: null,
      selected: null,
      masterExpansion: false,
    });
  };

  handleCancel = () => {
    // reset
    this.setState({
      visible: false,
      record: null,
      bunrui: "",
      name: "",
      rowActive: null,
      selected: null,
      selectSort: false,
      masterSort: false,
      sortTable: [],
      sortTableCopy: [],
      masterExpansion: false,
    });
  };

  handleOpenTable = () => {
    // open modal
    this.setState({
      visible: true,
      record: null,
      bunrui: "",
      name: "",
    });
  };

  onClickCellEvent = (record) => {
    this.setState({
      rowActive: record.id,
      record: record,
    });
  };

  search = () => {
    const { bunrui, name } = this.state;

    this.props.search(bunrui, name);
  };

  onChangeNameSearch = (value) => {
    this.setState({
      name: value,
    });
  };

  onChangeBunruiSearch = (value) => {
    const { name } = this.state;

    this.props.search(value, name);

    this.setState({
      bunrui: value,
    });
  };

  onChangePopover = (value) => {
    //ture or false
    this.setState({
      yougoVisible: value,
      selectSort: false,
      masterSort: false,
      Expansion: false,
    });
  };

  ////////////////////////////////////モーダル関連

  byoumeiHandleAdd() {
    this.setState({
      byoumeiVisibleEdit: true,
      byoumeiEditType: 1, // add
    });
  }

  byoumeiHandleEdit() {
    this.setState({
      byoumeiVisibleEdit: true,
      byoumeiEditType: 2, // edit
    });
  }

  byoumeiHandleClose() {
    this.search();

    this.setState({
      byoumeiVisibleEdit: false,
      byoumeiEditType: null,
    });
  }

  drugHandleAdd() {
    this.setState({
      drugVisibleEdit: true,
      drugEditType: 1, // add
    });
  }

  drugHandleEdit() {
    this.setState({
      drugVisibleEdit: true,
      drugEditType: 2, // edit
    });
  }

  drugHandleClose() {
    this.search();

    this.setState({
      drugVisibleEdit: false,
      drugEditType: null,
    });
  }

  machineHandleAdd() {
    this.setState({
      machineVisibleEdit: true,
      machineEditType: 1, // add
    });
  }

  machineHandleEdit() {
    this.setState({
      machineVisibleEdit: true,
      machineEditType: 2, // edit
    });
  }

  machineHandleClose() {
    this.search();

    this.setState({
      machineVisibleEdit: false,
      machineEditType: null,
    });
  }

  isDisableButtonByoumei(selected) {
    return selected && this.props.byoumeiList
      ? !this.props.byoumeiList.some((item) => item.id == selected.id)
      : true;
  }

  isDisableButtonDrug(selected) {
    return selected && this.props.drugList
      ? !this.props.drugList.some((item) => item.id == selected.id)
      : true;
  }

  isDisableButtonMachine(selected) {
    return selected && this.props.machineList
      ? !this.props.machineList.some((item) => item.id == selected.id)
      : true;
  }

  handleSelectRow(record) {
    //選択したレコードをステートselectedに保存
    this.setState({
      selected: record,
    });
  }

  //追加モーダル
  masterSortOn = () => {
    const { dataMaster } = this.props;
    let tmp = JSON.parse(JSON.stringify(dataMaster));
    let tmp2 = JSON.parse(JSON.stringify(dataMaster));
    this.setState({
      masterSort: true,
      sortTable: tmp,
      sortTableCopy: tmp2,
    });
  };
  selectSortOn = () => {
    const { dataSelect } = this.props;
    this.setState({
      selectSort: true,
      sortTable: dataSelect,
    });
  };

  masterSortOff = () => {
    this.setState({
      masterSort: false,
      sortTable: [],
      sortTableCopy: [],
    });
  };
  selectSortOff = () => {
    this.setState({
      selectSort: false,
      sortTable: [],
    });
  };

  masterSortSave = (key) => {
    const { sortTable, bunrui, name, sortTableCopy } = this.state;

    let tmp = [];
    for (let record of sortTable) {
      let defTable = sortTableCopy.find((t) => t.id == record.id);
      if (record.sortNum != defTable.sortNum) {
        tmp.push(record);
      }
    }

    switch (key) {
      case "byoumei":
        this.props.updateListByoumeiCreator(tmp, (call) => {
          this.props.search(bunrui, name);
        });
        break;
      case "drug":
        this.props.updateListDrugCreator(tmp, (call) => {
          this.props.search(bunrui, name);
        });
        break;
      case "machine":
        this.props.updateListMachineCreator(tmp, (call) => {
          this.props.search(bunrui, name);
        });
        break;
      default:
        break;
    }

    this.setState({
      masterSort: false,
      sortTable: [],
    });
  };
  selectSortSave = () => {
    const { sortTable } = this.state;
    this.props.yougoMasterUpdate(sortTable.join(";"));

    this.setState({
      selectSort: false,
      sortTable: [],
    });
  };
  sortTop = (sortTable, record, index) => {
    if (record.key != 0) {
      let newIndex = 0;
      const copyArray = sortTable.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable: copyArray,
      });
    }
  };
  sortUp = (sortTable, record, index) => {
    if (record.key != 0) {
      //1
      let newIndex = record.key - 1;
      const copyArray = sortTable.slice();
      const element = copyArray[record.key]; //element= 移動する要素
      copyArray.splice(record.key, 1); //(現在の要素番号の要素を削除, 削除する)
      copyArray.splice(newIndex, 0, element); //(移動先の要素番号, 削除する要素0, 移動する要素)
      this.setState({
        sortTable: copyArray,
      });
    }
  };
  sortDown = (sortTable, record, index) => {
    if (sortTable.length - 1 != record.key) {
      let newIndex = parseInt(record.key) + 1;
      const copyArray = sortTable.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable: copyArray,
      });
    }
  };
  sortBottom = (sortTable, record, index) => {
    if (sortTable.length - 1 != index) {
      let newIndex = sortTable.length - 1;
      const copyArray = sortTable.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable: copyArray,
      });
    }
  };

  //byoumei & machine & kusuri
  masterSortTop = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const minNum = sortTable[0].sortNum;

    if (elementIndex != 0) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = minNum - 1;
      tableTmp.splice(0, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  masterSortUp = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id); //現在のindex

    if (elementIndex != 0) {
      const changeElement = tableTmp[elementIndex - 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex - 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        //移動予定のsortNumの値と上のレコードを比べる ※同じ番号があった場合の対策
        element.sortNum--;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum; //上のレコードのsortNumに変更する
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum++; //古いsortNumを変更
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  masterSortDown = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      const changeElement = tableTmp[elementIndex + 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex + 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum++;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum--;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  masterSortBottom = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    let maxNum = 0;
    for (let i of tableTmp) {
      maxNum < i.sortNum ? (maxNum = i.sortNum) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = maxNum + 1;
      tableTmp.splice(bottomIndex, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  handleExpandTab = () => {
    //パネル全体
    const { Expansion } = this.state;

    if (Expansion) {
      this.setState({
        Expansion: false,
      });
    } else {
      this.setState({
        Expansion: true,
      });
    }
  };

  masterHandleExpandTab = () => {
    const { masterExpansion } = this.state;

    if (masterExpansion) {
      this.setState({
        masterExpansion: false,
      });
    } else {
      this.setState({
        masterExpansion: true,
      });
    }
  };

  render() {
    const {
      rowSet,
      value,
      isMaster,
      isSelect,

      dataSelect,
      dataMaster,
      dataMasterType,

      ymByoumeiBunrui,
      ymKusurimeiBunrui,
      dataExt,

      loading,
    } = this.props;

    const {
      newText,
      editIndex,
      visible,
      rowActive,
      record,
      name,
      yougoVisible,
      bunrui,
      selectSort,
      masterSort,
      sortTable,
      Expansion,
      masterExpansion,
    } = this.state;

    const titleByoumei = (
      <div>
        <Row style={{ marginBottom: "4px" }}>
          <Col span={6}>分類</Col>
          <Col span={18}>
            <Select
              value={bunrui || null}
              style={{ width: "100%" }}
              onChange={this.onChangeBunruiSearch}
              disabled={masterSort == true ? true : false}
            >
              <Select.Option key={"top"} value={""}>
                {"\u00a0"}
              </Select.Option>
              {ymByoumeiBunrui
                ? ymByoumeiBunrui.map((item, intex) => {
                    return (
                      <Select.Option key={intex} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })
                : null}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={6}>病名</Col>
          <Col span={18} style={{ display: "flex" }}>
            <TextInput
              value={name}
              onChange={this.onChangeNameSearch}
              disabled={masterSort == true ? true : false}
            />
            <Button
              onClick={this.search}
              className={
                masterSort == false
                  ? "search-modal-kikan"
                  : "search-modal-kikan-none"
              }
              icon={<SearchOutlined />}
              disabled={masterSort == true ? true : false}
            >
              検索
            </Button>
          </Col>
        </Row>
        <Button
          key="add"
          type={"primary"}
          onClick={() => this.byoumeiHandleAdd()}
          style={{ marginRight: 8, marginTop: 10 }}
          disabled={masterSort == true ? true : false}
        >
          追加
        </Button>
        <Button
          key="edit"
          type={"primary"}
          onClick={() => this.byoumeiHandleEdit()}
          disabled={
            masterSort == true
              ? true
              : this.isDisableButtonByoumei(this.state.record)
          }
          style={{ marginRight: 8 }}
        >
          修正
        </Button>
        {masterSort == false ? (
          <a
            key="sortbyoumei"
            // type={"primary"}
            onClick={() => this.masterSortOn()}
            style={{
              marginRight: 8,
              marginTop: 7,
              marginLeft: 10,
              color: "black",
            }}
          >
            並び替え
          </a>
        ) : (
          <span>
            <Button
              key="sortSaveByoumei"
              type={"primary"}
              onClick={() => this.masterSortSave("byoumei")}
              style={{ marginRight: 8 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={() => this.masterSortOff()}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
          </span>
        )}
      </div>
    );
    const columnsByoumei = [
      {
        title: "byoumei",
        dataIndex: "byoumei",
        width: 300,
        key: "byoumei",
        render: (textEx, record, index) => <div>{record.byoumei}</div>, //recordはクリックしたレコード
        onCell: (record) => {
          return {
            onClick: (event) => {
              this.onClickCellEvent(record);
            }, // click row
          };
        },
      },
      // {
      //     dataIndex: "sortNum",
      //     width: 40,
      // },
      {
        title: "bunrui",
        dataIndex: "bunrui",
        key: "bunrui",
        render: (textEx, record, index) => <div>{record.bunrui}</div>,
        onCell: (record) => {
          return {
            onClick: (event) => {
              this.onClickCellEvent(record);
            }, // click row
          };
        },
      },
    ];
    const columnsByoumei2 = [
      {
        title: "byoumei",
        dataIndex: "byoumei",
        key: "byoumei",
        width: "200px",
      },
      // {
      //     dataIndex: "sortNum",
      //     width: 40,
      // },
      {
        title: "行動",
        dataIndex: "operation",
        width: 100,
        key: "operation",
        render: (record, index) => {
          return (
            <div>
              <span>
                <a onClick={() => this.masterSortTop(index)} style={{}}>
                  <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortUp(index)} style={{}}>
                  <CaretUpOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortDown(index)} style={{}}>
                  <CaretDownOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortBottom(index)} style={{}}>
                  <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
              </span>
            </div>
          );
        },
      },
    ];

    const titleDrug = (
      <div>
        <Row style={{ marginBottom: "4px" }}>
          <Col span={6}>薬分類</Col>
          <Col span={18}>
            <Select
              value={bunrui || null}
              style={{ width: "100%" }}
              onChange={this.onChangeBunruiSearch}
              disabled={masterSort == true ? true : false}
            >
              <Select.Option key={"top"} value={""}>
                {"\u00a0"}
              </Select.Option>
              {ymKusurimeiBunrui
                ? ymKusurimeiBunrui.map((item, intex) => {
                    return (
                      <Select.Option key={intex} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })
                : null}
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={6}>薬名</Col>
          <Col span={18} style={{ display: "flex" }}>
            <TextInput
              value={name}
              onChange={this.onChangeNameSearch}
              disabled={masterSort == true ? true : false}
            />
            <Button
              onClick={this.search}
              className={
                masterSort == false
                  ? "search-modal-kikan"
                  : "search-modal-kikan-none"
              }
              icon={<SearchOutlined />}
              disabled={masterSort == true ? true : false}
            >
              検索
            </Button>
          </Col>
        </Row>
        <Button
          key="add"
          type={"primary"}
          onClick={() => this.drugHandleAdd()}
          style={{ marginRight: 8, marginTop: 10 }}
          disabled={masterSort == true ? true : false}
        >
          追加
        </Button>
        <Button
          key="edit"
          type={"primary"}
          onClick={() => this.drugHandleEdit()}
          disabled={
            masterSort == true
              ? true
              : this.isDisableButtonDrug(this.state.record)
          }
          style={{ marginRight: 12 }}
        >
          修正
        </Button>
        {masterSort == false ? (
          <a
            key="sortDrug"
            // type={"primary"}
            onClick={() => this.masterSortOn()}
            style={{
              marginRight: 8,
              marginTop: 7,
              marginLeft: 10,
              color: "black",
            }}
          >
            並び替え
          </a>
        ) : (
          <span>
            <Button
              key="sortSaveDrug"
              type={"primary"}
              onClick={() => this.masterSortSave("drug")}
              style={{ marginRight: 8 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={() => this.masterSortOff()}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
          </span>
        )}
      </div>
    );
    const columnsDrug = masterExpansion
      ? [
          {
            title: "name",
            dataIndex: "name",
            width: 300,
            key: "name",
            render: (textEx, record, index) => (
              <div
                style={{
                  maxWidth: "290px",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {record.name}
              </div>
            ),
            onCell: (record) => {
              return {
                onClick: (event) => {
                  this.onClickCellEvent(record);
                }, // click row
              };
            },
          },
          {
            title: "bunrui",
            dataIndex: "bunrui",
            key: "bunrui",
            render: (textEx, record, index) => (
              <div
                style={{
                  maxWidth: "290px",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {record.bunrui}
              </div>
            ),
            onCell: (record) => {
              return {
                onClick: (event) => {
                  this.onClickCellEvent(record);
                }, // click row
              };
            },
          },
        ]
      : [
          {
            title: "name",
            dataIndex: "name",
            width: 300,
            key: "name",
            render: (textEx, record, index) => (
              <div
                style={{
                  maxWidth: "290px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {record.name}
              </div>
            ),
            onCell: (record) => {
              return {
                onClick: (event) => {
                  this.onClickCellEvent(record);
                }, // click row
              };
            },
          },
          {
            title: "bunrui",
            dataIndex: "bunrui",
            key: "bunrui",
            render: (textEx, record, index) => (
              <div
                style={{
                  maxWidth: "290px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {record.bunrui}
              </div>
            ),
            onCell: (record) => {
              return {
                onClick: (event) => {
                  this.onClickCellEvent(record);
                }, // click row
              };
            },
          },
        ];
    const columnsDrug2 = [
      {
        title: "name",
        dataIndex: "name",
        key: "name",
        width: "200px", //200
      },
      // {
      //     dataIndex: "sortNum",
      //     width: 40,
      // },
      {
        title: "行動",
        dataIndex: "operation",
        width: 100,
        key: "operation",
        render: (record, index) => {
          return (
            <div>
              <span>
                <a onClick={() => this.masterSortTop(index)} style={{}}>
                  <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortUp(index)} style={{}}>
                  <CaretUpOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortDown(index)} style={{}}>
                  <CaretDownOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortBottom(index)} style={{}}>
                  <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
              </span>
            </div>
          );
        },
      },
    ];

    const titleMachine = (
      <div>
        <Row>
          <Col span={6}>機器名</Col>
          <Col span={18} style={{ display: "flex" }}>
            <TextInput
              value={name}
              onChange={this.onChangeNameSearch}
              disabled={masterSort == true ? true : false}
            />
            <Button
              onClick={this.search}
              className={
                masterSort == false
                  ? "search-modal-kikan"
                  : "search-modal-kikan-none"
              }
              icon={<SearchOutlined />}
              disabled={masterSort == true ? true : false}
            >
              検索
            </Button>
          </Col>
        </Row>
        <Button
          key="add"
          type={"primary"}
          onClick={() => this.machineHandleAdd()}
          style={{ marginRight: 8, marginTop: 10 }}
          disabled={masterSort == true ? true : false}
        >
          追加
        </Button>
        <Button
          key="edit"
          type={"primary"}
          onClick={() => this.machineHandleEdit()}
          disabled={
            masterSort == true
              ? true
              : this.isDisableButtonMachine(this.state.record)
          }
          style={{ marginRight: 8 }}
        >
          修正
        </Button>
        {masterSort == false ? (
          <a
            key="sortMachine"
            // type={"primary"}
            onClick={() => this.masterSortOn()}
            style={{
              marginRight: 8,
              marginTop: 7,
              marginLeft: 10,
              color: "black",
            }}
          >
            並び替え
          </a>
        ) : (
          <span>
            <Button
              key="sortSaveMachine"
              type={"primary"}
              onClick={() => this.masterSortSave("machine")}
              style={{ marginRight: 8 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={() => this.masterSortOff()}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
          </span>
        )}
      </div>
    );
    const columnsMachine = [
      {
        title: "name",
        dataIndex: "name",
        key: "name",
        render: (textEx, record, index) => <div>{record.name}</div>,
        onCell: (record) => {
          return {
            onClick: (event) => {
              this.onClickCellEvent(record);
            }, // click row
          };
        },
      },
    ];
    const columnsMachine2 = [
      {
        title: "name",
        dataIndex: "name",
        key: "name",
        width: "200px",
      },
      {
        title: "行動",
        dataIndex: "operation",
        width: 100,
        key: "operation",
        render: (record, index) => {
          return (
            <div>
              <span>
                <a onClick={() => this.masterSortTop(index)} style={{}}>
                  <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortUp(index)} style={{}}>
                  <CaretUpOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortDown(index)} style={{}}>
                  <CaretDownOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.masterSortBottom(index)} style={{}}>
                  <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
              </span>
            </div>
          );
        },
      },
    ];

    const titleService = (
      <div>
        <Row style={{ marginBottom: "4px" }}>
          <Col span={6}>事業所選択</Col>
          <Col span={18}>
            <Select
              style={{ width: "100%" }}
              onChange={this.onChangeBunruiSearch}
            >
              <Select.Option key={"top"} value={""}>
                {"\u00a0"}
              </Select.Option>
              {dataExt
                ? dataExt.map((item, intex) => {
                    return (
                      <Select.Option key={intex} value={item.serviceSyuruiCd}>
                        ({item.serviceSyuruiCd}){item.serviceSyurui}
                      </Select.Option>
                    );
                  })
                : null}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={6}>事業所名</Col>
          <Col span={18}>
            <TextInput
              onChange={this.onChangeNameSearch}
              addonAfter={
                <span onClick={this.search} value={name}>
                  <SearchOutlined /> 検索
                </span>
              }
            />
          </Col>
        </Row>
      </div>
    );
    const columnsService = [
      {
        title: "jigyousyoName",
        dataIndex: "byoujigyousyoNamemei",
        width: 300,
        key: "jigyousyoName",
        render: (textEx, record, index) => (
          <div>{`${record.jigyousyoName} (${record.syuruiCd})`}</div>
        ),
        onCell: (record) => {
          return {
            onClick: (event) => {
              this.onClickCellEvent(record);
            }, // click row
          };
        },
      },
    ];

    const titleIryou = (
      <div>
        <Row>
          <Col span={6}>病院</Col>
          <Col span={18}>
            <TextInput
              onChange={this.onChangeNameSearch}
              addonAfter={
                <span onClick={this.search} value={name}>
                  <SearchOutlined /> 検索
                </span>
              }
            />
          </Col>
        </Row>
      </div>
    );
    const columnsIryou = [
      {
        title: "hospital",
        dataIndex: "hospital",
        width: 300,
        key: "hospital",
        render: (textEx, record, index) => <div>{`${record.hospital}`}</div>,
        onCell: (record) => {
          return {
            onClick: (event) => {
              this.onClickCellEvent(record);
            }, // click row
          };
        },
      },
    ];

    const columns = Expansion //trueは展開
      ? [
          {
            title: "naiyou",
            dataIndex: "naiyou",
            width: 320,
            key: "naiyou",
            render: (textEx, record, index) =>
              this.renderColumns(record, index),
          },
          {
            title: "行動",
            dataIndex: "operation",
            width: 150,
            key: "operation",
            render: (textEx, record, index) => {
              return (
                (<div className="editable-row-operations">
                  {selectSort === true ? ( //<Icon type="caret-up" rotate={isActive ? 180 : 0} />style={{ fontSize: '16px', color: '#08c' }}
                    (<span>
                      <a
                        onClick={() => this.sortTop(sortTable, record, index)}
                        style={{}}
                      >
                        <StepBackwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                      </a>
                      <Divider type="vertical" />
                      <a
                        onClick={() => this.sortUp(sortTable, record, index)}
                        style={{}}
                      >
                        <CaretUpOutlined style={{ fontSize: "18px" }} />
                      </a>
                      <Divider type="vertical" />
                      <a
                        onClick={() => this.sortDown(sortTable, record, index)}
                        style={{}}
                      >
                        <CaretDownOutlined style={{ fontSize: "18px" }} />
                      </a>
                      <Divider type="vertical" />
                      <a
                        onClick={() =>
                          this.sortBottom(sortTable, record, index)
                        }
                        style={{}}
                      >
                        <StepForwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                      </a>
                    </span>)
                  ) : editIndex === index ? (
                    <span>
                      <a onClick={() => this.save()}>保存</a>
                      <Divider type="vertical" />
                      <a onClick={() => this.cancel(index)}>閉じる</a>
                    </span>
                  ) : (
                    <span>
                      <a onClick={() => this.select(record)}>選択</a>
                      <Divider type="vertical" />
                      <a onClick={() => this.edit(record, index)}>修正</a>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="削除してもいいですか？"
                        onConfirm={() => this.delete(record, index)}
                      >
                        <a>削除</a>
                      </Popconfirm>
                    </span>
                  )}
                </div>)
              );
            },
          },
        ]
      : [
          {
            title: "naiyou",
            dataIndex: "naiyou",
            width: 320,
            key: "naiyou",
            render: (textEx, record, index) =>
              this.renderColumns(record, index),
          },
          {
            title: "行動",
            dataIndex: "operation",
            width: 150,
            key: "operation",
            render: (textEx, record, index) => {
              return (
                (<div className="editable-row-operations">
                  {selectSort === true ? ( //<Icon type="caret-up" rotate={isActive ? 180 : 0} />style={{ fontSize: '16px', color: '#08c' }}
                    (<span>
                      <a
                        onClick={() => this.sortTop(sortTable, record, index)}
                        style={{}}
                      >
                        <StepBackwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                      </a>
                      <Divider type="vertical" />
                      <a
                        onClick={() => this.sortUp(sortTable, record, index)}
                        style={{}}
                      >
                        <CaretUpOutlined style={{ fontSize: "18px" }} />
                      </a>
                      <Divider type="vertical" />
                      <a
                        onClick={() => this.sortDown(sortTable, record, index)}
                        style={{}}
                      >
                        <CaretDownOutlined style={{ fontSize: "18px" }} />
                      </a>
                      <Divider type="vertical" />
                      <a
                        onClick={() =>
                          this.sortBottom(sortTable, record, index)
                        }
                        style={{}}
                      >
                        <StepForwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                      </a>
                    </span>)
                  ) : editIndex === index ? (
                    <span>
                      <a onClick={() => this.save()}>保存</a>
                      <Divider type="vertical" />
                      <a onClick={() => this.cancel(index)}>閉じる</a>
                    </span>
                  ) : (
                    <span>
                      <a onClick={() => this.select(record)}>選択</a>
                      <Divider type="vertical" />
                      <a onClick={() => this.edit(record, index)}>修正</a>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="削除してもいいですか？"
                        onConfirm={() => this.delete(record, index)}
                      >
                        <a>削除</a>
                      </Popconfirm>
                    </span>
                  )}
                </div>)
              );
            },
          },
        ];

    let dataTable1 = convertArrStrToArrObj(dataSelect);
    // console.log(dataSelect) // ['bbb','aaaa','aa']
    // console.log(dataTable1) // [{key:'0', naiyou:'あああ'},{key:'1', naiyou:'あああ'},{key:'2', naiyou:'あああ'}]
    const content = (
      <div>
        {selectSort == false ? (
          <div>
            <Table
              bordered
              columns={columns}
              dataSource={dataTable1}
              pagination={{ pageSize: 50 }} //50
              scroll={{ y: 240 }}
              showHeader={false}
              size={"small"}
              rowKey={(record, index) => index}
            />
            <Button
              type="primary"
              icon={<LegacyIcon type={Expansion ? "minus" : "plus"} />}
              // className="modal-expansion"
              style={{ float: "left", marginTop: -40 }}
              onClick={this.handleExpandTab}
            >
              {Expansion ? "格納" : "展開"}
            </Button>
          </div>
        ) : null}

        {selectSort == true ? (
          <Table
            bordered
            columns={columns}
            dataSource={convertArrStrToArrObj(sortTable)}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 240 }}
            showHeader={false}
            size={"small"}
            rowKey={(record, index) => index}
          />
        ) : null}
      </div>
    );

    const title = (
      <div>
        {selectSort == false ? (
          <span>
            <TextInput
              onChange={this.handleOnchangeNew}
              value={newText}
              style={{ width: "250px", marginRight: "12px" }}
            />
            <a onClick={this.add}>追加</a>
            <a
              onClick={this.onChangePopover.bind(this, false)}
              style={{ float: "right", paddingTop: "7px", color: "black" }}
            >
              閉じる
            </a>
            <a
              type={"primary"}
              onClick={this.selectSortOn}
              style={{
                marginRight: 8,
                marginTop: 5,
                marginLeft: 15,
                color: "black",
              }}
            >
              並び替え
            </a>
          </span>
        ) : null}

        {selectSort == true ? (
          <span>
            <Button
              type={"primary"}
              onClick={this.selectSortSave}
              style={{ marginRight: 8, marginTop: 0, marginLeft: 15 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={this.selectSortOff}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
            <a
              onClick={this.onChangePopover.bind(this, false)}
              style={{ float: "right", paddingTop: "7px", color: "black" }}
            >
              閉じる
            </a>
          </span>
        ) : null}
      </div>
    );

    return (
      (<div className="text-area-select-ri-page">
        <TextInputArea
          rows={rowSet ? rowSet : 1}
          className="text-area-select-text"
          value={value}
          onChange={this.handleSelectChange}
        />
        <span className="text-area-select-btn">
          <span>
            {isMaster ? (
              <Button type="primary" onClick={this.handleOpenTable}>
                マスタ
              </Button>
            ) : null}

            {isSelect ? (
              <Popover
                overlayStyle={{ width: "550px" }}
                visible={yougoVisible}
                placement={"bottomRight"}
                title={title}
                content={content}
                trigger="click"
                onVisibleChange={this.onChangePopover}
              >
                <Button type="primary">選択</Button>
              </Popover>
            ) : null}
          </span>
        </span>
        <Modal //dataMasterTypeのタイプごとにモーダルを切り替えてる
          open={visible}
          className="modal-list"
          title={
            dataMasterType === "byoumei"
              ? titleByoumei
              : dataMasterType === "drug"
              ? titleDrug
              : dataMasterType === "machine"
              ? titleMachine
              : dataMasterType === "serviceKikan"
              ? titleService
              : dataMasterType === "iryouKikan"
              ? titleIryou
              : null
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleOk}
              disabled={record ? false : true}
            >
              OK
            </Button>,
          ]}
          closable={false}
        >
          <Spin spinning={loading}>
            {masterSort === false ? (
              <div>
                <Table
                  dataSource={dataMaster}
                  columns={
                    dataMasterType === "byoumei"
                      ? columnsByoumei
                      : dataMasterType === "drug"
                      ? columnsDrug
                      : dataMasterType === "machine"
                      ? columnsMachine
                      : dataMasterType === "serviceKikan"
                      ? columnsService
                      : dataMasterType === "iryouKikan"
                      ? columnsIryou
                      : null
                  }
                  showHeader={false}
                  className="riyou-meal-page modal-table"
                  pagination={{ pageSize: 20 }} //true = 10
                  scroll={{ y: 300 }}
                  size="small"
                  onRow={(record) => {
                    return {
                      onClick: () => this.handleSelectRow(record),
                    };
                  }}
                  rowClassName={(record) =>
                    rowActive != null && record.id === rowActive
                      ? "pljp-table-row-hover"
                      : ""
                  }
                  rowKey={(record, index) => index}
                />
                {dataMasterType === "drug" ? (
                  <Button
                    type="primary"
                    icon={<LegacyIcon type={masterExpansion ? "minus" : "plus"} />}
                    // className="modal-expansion"
                    style={{ float: "left", marginTop: -40 }}
                    onClick={this.masterHandleExpandTab}
                  >
                    {masterExpansion ? "格納" : "展開"}
                  </Button>
                ) : null}
              </div>
            ) : masterSort === true ? (
              <Table
                dataSource={sortTable}
                columns={
                  dataMasterType === "byoumei"
                    ? columnsByoumei2
                    : dataMasterType === "drug"
                    ? columnsDrug2
                    : dataMasterType === "machine"
                    ? columnsMachine2
                    : dataMasterType === "serviceKikan"
                    ? columnsService
                    : dataMasterType === "iryouKikan"
                    ? columnsIryou
                    : null
                }
                showHeader={false}
                className="riyou-meal-page modal-table"
                pagination={{ pageSize: 20 }} //true = 10
                scroll={{ y: 300 }}
                size="small"
                // onRow={(record) => {
                //     return {
                //         onClick: () => this.handleSelectRow(record)
                //     };
                // }}
                rowClassName={(record) =>
                  rowActive != null && record.id === rowActive
                    ? "pljp-table-row-hover"
                    : ""
                }
                rowKey={(record, index) => index}
              />
            ) : null}
          </Spin>
        </Modal>
        <ByoumeiMasterEditModal
          visible={this.state.byoumeiVisibleEdit}
          data={this.state.selected}
          editType={this.state.byoumeiEditType}
          handleSelectRow={(record) => this.handleSelectRow(record)}
          handleClose={() => this.byoumeiHandleClose()}
          dataSource={this.props.byoumeiList}
          ymByoumeiBunrui={ymByoumeiBunrui}
          yougoMasterUpdate={this.props.yougoMasterUpdate}
        />
        <DrugMasterEditModal
          visible={this.state.drugVisibleEdit}
          data={this.state.selected}
          editType={this.state.drugEditType}
          handleSelectRow={(record) => this.handleSelectRow(record)}
          handleClose={() => this.drugHandleClose()}
          dataSource={this.props.drugList}
          ymKusurimeiBunrui={ymKusurimeiBunrui}
          yougoMasterUpdate={this.props.yougoMasterUpdate}
        />
        <MachineMasterEditModal
          visible={this.state.machineVisibleEdit}
          data={this.state.selected}
          editType={this.state.machineEditType}
          handleSelectRow={(record) => this.handleSelectRow(record)}
          handleClose={() => this.machineHandleClose()}
          dataSource={this.props.machineList}
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    byoumeiList: state.allMaster.byomeiMaster,
    drugList: state.allMaster.drugMaster,
    machineList: state.allMaster.machineMaster,
    loading: state.allMaster.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateListByoumeiCreator: (data, callback) =>
      dispatch(updateListByoumeiCreator(data, callback)),
    updateListMachineCreator: (data, callback) =>
      dispatch(updateListMachineCreator(data, callback)),
    updateListDrugCreator: (data, callback) =>
      dispatch(updateListDrugCreator(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextAreaSelect);
