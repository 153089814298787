import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../../../common/configs/common-url";

let LifeYougoMasterNaiyouApi = {
  lifeYougoMasterNaiyou(kubun, listName) {
    return axios
      .get(apiUrls.INIT + apiUrls.GET_LIFE_YOUGO_MASTER_NAIYOU, {
        params: {
          kubun,
          listName,
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  createLifeYougoMaster(kubun, listName, naiyou) {
    return axios
      .post(apiUrls.INIT + apiUrls.POST_LIFE_YOUGO_MASTER, {
        kubun: kubun,
        listName: listName,
        naiyou: naiyou,
      })
      .then((res) => {
        return res.data;
      });
  },
  updateLifeYougoMasterApi(kubun, listName, naiyou) {
    return axios
      .put(apiUrls.INIT + apiUrls.PUT_LIFE_YOUGO_MASTER, {
        kubun: kubun,
        listName: listName,
        naiyou: naiyou,
      })
      .then((res) => {
        return res.data;
      });
  },
};

export { LifeYougoMasterNaiyouApi };
