export const MESSAGE_LIST_REQUEST = "MESSAGE_LIST_REQUEST";
export const MESSAGE_LIST_SUCCESS = "MESSAGE_LIST_SUCCESS";
export const MESSAGE_LIST_ERROR = "MESSAGE_LIST_ERROR";

export const MESSAGE_ADD_REQUEST = "MESSAGE_ADD_REQUEST";
export const MESSAGE_ADD_SUCCESS = "MESSAGE_ADD_SUCCESS";
export const MESSAGE_ADD_ERROR = "MESSAGE_ADD_ERROR";

export const LOAD_MORE_REQUEST = "LOAD_MORE_REQUEST";
export const LOAD_MORE_SUCCESS = "LOAD_MORE_SUCCESS";
export const LOAD_MORE_ERROR = "LOAD_MORE_ERROR";

export const MESSAGE_MITAYO = "MESSAGE_MITAYO";
