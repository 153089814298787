import React, { Component } from "react";
import { Radio } from "antd";

const { Group, Button } = Radio;

export default class PlanSwitch extends Component {
  render() {
    return (
      <div style={this.props.style}>
        <Group
          buttonStyle="solid"
          onChange={(e) => this.props.handleChangeTab(e.target.value)}
          value={this.props.tabActive}
        >
          <Button value="1">{"計画書１"}</Button>
          <Button value="2">{"計画書２"}</Button>
          <Button value="3" style={{ width: "88px" }}>
            {"日課"}
          </Button>
        </Group>
      </div>
    );
  }
}
