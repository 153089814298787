import React, { Component } from 'react'
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Divider, Col, Row } from 'antd';
import _, { add } from "lodash";
import InputNo from "./InputNo";
import TantoInput from "../../../common/component/control/TantoInput";

class FormCaremanagerNormal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tantoData: null
        }
    }
    componentDidMount() {
        this.setState({
            tantoData: null
        })
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        const { tantoData } = this.state;
        if(data.mainTantoId && !tantoData) {
            this.setState({
                tantoData: data.mainTantoId
            })
        }
    }

    handleSelectTanto = (item) => {
        const { form } = this.props
        this.setState({
            tantoData: item.id
        })
        form.setFieldsValue({
            main_tanto_id: item.id
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data } = this.props
        const { tantoData } = this.state

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };


        return (
            <div id="input-doctor-page">
            <Form
                onSubmit={this.handleSubmit}
                hideRequiredMark={true}
                {...formItemLayout}
                labelAlign="left"
            >
                <Row style={{ padding: "8px 0px"}}>
                    <Divider /><Divider /><Divider />
                </Row>
                <Row>
                {/* riyousya-update-page */}
                    <Col id="ceamane-tanto" style={{ width: '100%' }}>
                    <Form.Item label={"ケアマネ 担当者"} colon={false}>
                        <TantoInput 
                            containerId={"ceamane-tanto"} 
                            style={{ display: "inline-block" }}
                            value={tantoData}
                            onChangeItem={item => this.handleSelectTanto(item)}
                        />
                    </Form.Item>
                    </Col>
                </Row>
                <div style={{ display: "none" }}>
                    {getFieldDecorator('main_tanto_id', {
                        rules: [{ required: false }],
                    })(<InputNo />)}
                </div>
            </Form>
            </div>
        )
    }
}

export const FormCaremanager = Form.create({
    name: 'input-caremanager-form',
    onValuesChange(props, changedValues, allValues) {
        // @TODO convert checkbox group to value
        props.saveOnchangeToState(allValues, 'fCaremanager', '')
    },
    mapPropsToFields(props) {
        return {
            main_tanto_id: Form.createFormField({
                value: props.data && props.data.mainTantoId
                ?
                props.data.mainTantoId
                : 
                null
            })
        }
    },
})(FormCaremanagerNormal)
