import "whatwg-fetch";
import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";

const KaigoYougoMasterApi = {
  getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page) {
    return axios
      .get(apiUrls.GET_KAIGO_YOUGO_MASTER, {
        params: {
          attribute,
          bunrui1,
          bunrui2,
          keyword,
          page,
        },
      })
      .then((res) => {
        console.log(res.data);
        return res.data;
      });
  },
  getKaigoYougoListAll(attribute, bunrui1, bunrui2, keyword) {
    return axios
      .get(apiUrls.GET_KAIGO_YOUGO_MASTER_ALL, {
        params: {
          attribute,
          bunrui1,
          bunrui2,
          keyword,
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  getKaigoYougoAll() {
    //全て取得
    return axios.get(apiUrls.GET_KAIGO_YOUGO_MASTER_LIST, {}).then((res) => {
      return res.data;
    });
  },
  getKaigoYougoId(id) {
    return axios
      .get(apiUrls.GET_KAIGO_YOUGO_MASTER_ID, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data;
      });
  },

  updateKaigoYougo(id, attribute, bunrui1, bunrui2, keyword, naiyou) {
    return axios
      .post(apiUrls.UPDATE_KAIGO_YOUGO_MASTER, {
        id: id,
        attribute: attribute,
        bunrui1: bunrui1,
        bunrui2: bunrui2,
        keyword: keyword,
        naiyou: naiyou,
      })
      .then((res) => {
        return res.data;
      });
  },

  updateKaigoYougoList(data) {
    return axios
      .post(apiUrls.UPDATE_KAIGO_YOUGO_LIST_MASTER, {
        data,
      })
      .then((res) => {
        return res.data;
      });
  },

  getMaxSortNum() {
    return axios.get(apiUrls.GET_KAIGO_YOUGO_MAX_SORT, {}).then((res) => {
      return res.data;
    });
  },
};

export { KaigoYougoMasterApi };
