/* eslint-disable no-case-declarations */
/* eslint-disable brace-style */
import {
  LOAD_FILE_MANAGEMENT_REQUEST,
  LOAD_FILE_MANAGEMENT_SUCCESS,
  LOAD_FILE_MANAGEMENT_ERROR,
  GAZOU_COPY,
  GAZOU_DELETE,
  REPORT_FILTER_SUCCESS,
  TANTO_MASTER_LIST,
  YOUGO_MASTER_NAIYOU_FILE,
  FILE_MNGMT_INSERT_SUCCESS,
  FILE_MNGMT_INSERT_ERROR,
  FILE_MNGMT_DELETE_REQUEST,
  FILE_MNGMT_DELETE_SUCCESS,
  YOUGO_MASTER_EDIT_STATE_FILE,
  SETTING_MASTER_LIST_SUCCESS,
  YOUGO_MASTER_EDIT_CANCEL_FILE,
  FILE_MNGMT_UPDATE_ERROR,
  FILE_MNGMT_UPDATE_REQUEST,
  FILE_MNGMT_UPDATE_SUCCESS,
  FILE_MNGMT_INSERT_REQUEST,
  FILE_MNGMT_DELETE_ERROR,
  GET_FILE_ERROR,
  HANLDE_YOUGO_MASTER_ERROR,
  SETTING_MASTER_LIST_ERROR,
} from "../../actions/fileManagementActions/actionName";
import {
  CopyLine,
  deleteLine,
  deleteRecord,
} from "../../../common/handleLine/handleLine";
import { openNotificationWithIcon } from "../../../common/function_common/functionCommon";
import { ConstSet } from "../../../common/configs/constset";
import { Transcode } from "../../../common/configs/transcode";

const initialState = {
  meta: {
    page: 1,
    pageSize: ConstSet.LIMIT_PER_PAGE,
    total: 0,
  },
};

export default function fileManagement(
  state = {
    loading: false,
    created: false,
    delete: false,
    update: false,
    initialState,
  },
  action
) {
  switch (action.type) {
    case LOAD_FILE_MANAGEMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_FILE_MANAGEMENT_SUCCESS:
      return {
        ...state,
        fileManagement: action.fileManagement.lstFileManagementDto,
        imageMaxPixel: action.fileManagement.imageMaxPixel,
        isDownloadable: action.fileManagement.isDownloadable,
        meta: {
          page:
            action.fileManagement.pagingMetaDto.page || initialState.meta.page,
          pageSize:
            action.fileManagement.pagingMetaDto.pageSize ||
            initialState.meta.pageSize,
          total:
            action.fileManagement.pagingMetaDto.total ||
            initialState.meta.total,
        },
        loading: false,
      };
    case LOAD_FILE_MANAGEMENT_ERROR:
      return {
        ...state,
        fileManagement: null,
        loading: false,
        error: action.error,
      };
    // create a line
    case GAZOU_COPY:
      return {
        ...state,
        fileManagement: CopyLine(state.fileManagement, action.gazoRecord).map(
          (item, index) => ({
            ...item,
            index,
          })
        ),
      };
    case GAZOU_DELETE:
      return {
        ...state,
        fileManagement: deleteLine(state.fileManagement, action.gazoRecord).map(
          (item, index) => ({
            ...item,
            index,
          })
        ),
      };
    case REPORT_FILTER_SUCCESS: {
      return {
        ...state,
        nyutaishoFilter: action.payload,
      };
    }
    case TANTO_MASTER_LIST:
      return {
        ...state,
        tantoMaster: action.payload,
      };
    case YOUGO_MASTER_NAIYOU_FILE: {
      const yougoMasterNaiyouId = action.payload.id;
      const prePareData =
        action.payload && action.payload.naiyou
          ? action.payload.naiyou.split(";")
          : "";
      const dataReturn = [];
      for (let i = 0; i < prePareData.length; i++) {
        dataReturn.push({
          key: i,
          naiyou: prePareData[i],
          editable: false,
        });
      }
      return {
        ...state,
        yougoMasterNa: dataReturn,
        yougoMasterNaiyouId,
      };
    }
    case FILE_MNGMT_DELETE_REQUEST:
      return {
        ...state,
        delete: true,
      };
    case FILE_MNGMT_DELETE_SUCCESS:
      openNotificationWithIcon("success", Transcode.SUCCESS);
      const record = { ...action.record };
      return {
        ...state,
        fileManagement: deleteRecord(state.fileManagement, record),
        delete: false,
      };
    case FILE_MNGMT_DELETE_ERROR:
      openNotificationWithIcon("error", Transcode.ERROR);
      return {
        ...state,
        delete: false,
        error: action.error,
      };
    case YOUGO_MASTER_EDIT_STATE_FILE: {
      return {
        ...state,
        yougoMasterNa: state.yougoMasterNa.map((item, index) => {
          if (index === action.index) {
            item.editable = true;
          } else {
            item.editable = false;
          }

          return item;
        }),
      };
    }
    case SETTING_MASTER_LIST_SUCCESS: {
      const prePareData =
        action.payload && action.payload.naiyou
          ? action.payload.naiyou.split(";")
          : "";
      const dataReturn = [];
      for (let i = 0; i < prePareData.length; i++) {
        dataReturn.push({
          key: i,
          naiyou: prePareData[i],
          editable: false,
        });
      }
      return {
        ...state,
        gazouKubun: dataReturn,
      };
    }
    case YOUGO_MASTER_EDIT_CANCEL_FILE: {
      return {
        ...state,
        yougoMasterNa: state.yougoMasterNa.map((item, index) => {
          if (index === action.index) {
            item.editable = false;
          }
          return item;
        }),
      };
    }
    case FILE_MNGMT_INSERT_REQUEST:
      return {
        ...state,
        created: true,
      };
    case FILE_MNGMT_INSERT_SUCCESS:
      openNotificationWithIcon("success", Transcode.ADD_SUCCESS);
      const recordCreate = { ...action.createRecord, ...action.payload };
      return {
        ...state,
        newRecord: action.payload,
        fileManagement: state.fileManagement.map((item, index) => {
          if (index === recordCreate.key) {
            item = recordCreate;
          }
          return item;
        }),
        created: false,
      };
    case FILE_MNGMT_INSERT_ERROR:
      openNotificationWithIcon("error", Transcode.ADD_ERROR);
      return {
        ...state,
        created: false,
        error: action.error,
      };
    case FILE_MNGMT_UPDATE_REQUEST:
      return {
        ...state,
        update: true,
      };
    case FILE_MNGMT_UPDATE_SUCCESS:
      openNotificationWithIcon("success", Transcode.EDIT_SUCCESS);
      return {
        ...state,
        fileManagement: state.fileManagement.map((item) => {
          if (item.id === action.payload.id) {
            item = action.recordUpdate;
          }
          return item;
        }),
        update: false,
      };
    case FILE_MNGMT_UPDATE_ERROR:
      openNotificationWithIcon("error", Transcode.EDIT_ERROR);
      return {
        ...state,
        update: false,
        error: action.error,
      };
    case GET_FILE_ERROR:
      openNotificationWithIcon("error", Transcode.FILE_LOADING_ERROR);
      return {
        ...state,
        errorFile: true,
      };
    case HANLDE_YOUGO_MASTER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SETTING_MASTER_LIST_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
