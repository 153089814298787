/* eslint-disable brace-style */
export function CopyLine(originArray, record) {
  const returnCopyArray = [];
  for (const tmpCopy in originArray) {
    if (tmpCopy == record.index) {
      returnCopyArray.push(originArray[tmpCopy]);
      returnCopyArray.push(record);
      record.index = 0;
    } else {
      returnCopyArray.push(originArray[tmpCopy]);
    }
  }
  return returnCopyArray;
}

export function deleteLine(originArray, record) {
  originArray = originArray.filter((item) => {
    return item.index !== record.index;
  });

  return originArray;
}

export function deleteRecord(items, record) {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.id === record.id) {
      if (!item.copy) {
        item.attendance = record.attendance;
        item.comment = null;
        item.date = record.date;
        item.fileName = null;
        item.gender = record.gender;
        item.gurupu = record.gurupu;
        item.id = null;
        item.kirokuKubun = null;
        item.kubun = null;
        item.naiyou = null;
        item.naiyou2 = null;
        item.priorityf = null;
        item.riyousyaId = record.riyousyaId;
        item.riyousyaName = record.riyousyaName;
        item.sonotaKubun = null;
        item.tantoId = null;
        item.tantoMasterName = null;
        item.thumbnail = null;
        item.time = null;
        item.type = null;
        item.tyuiF = null;
        item.webf = null;
        item.file = null;
        item.path = null;
        item.thumbnailState = null;
        result.push(item);
      }
    } else {
      result.push(item);
    }
  }
  return result;
}
