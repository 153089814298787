import { Input } from "antd";
import reactComponentDebounce from "react-component-debounce";

const TextInput = reactComponentDebounce({
  valuePropName: "value",
  triggerMs: 200,
  disabled: "disabled",
  addonAfter: "addonAfter",
  style: "style",
})(Input);

export default TextInput;
