import React from "react";
import { Row, Col, Divider } from "antd";
import LineBreakText from "../../../common/component/LineBreakText";
import { displayRiyouConcernTitle } from "../../../common/function_common/functionDisplay";

const KankeiInfo = (props) => {
  return (
    <div>
      {props.riyousya &&
      props.riyousya.riyouConcern &&
      props.riyousya.riyouConcern.length > 0
        ? props.riyousya.riyouConcern.map((item, index) => {
            return (
              <Row key={index} className="rdp-tab-kankei">
                {index >= 1 ? <Divider /> : null}
                <Col sm={12} md={8}>
                  <span className="rdp-tab-kankei-furigana">
                    {item.furigana ? item.furigana : ""}
                  </span>
                  <span className="rdp-tab-kankei-name">
                    {item.name ? item.name : ""}
                  </span>
                  <span className="rdp-tab-kankei-kankei">
                    {item.kankei ? item.kankei : ""}
                  </span>
                </Col>
                <Col sm={12} md={16}>
                  <span>{"\u00a0"}</span>
                  <span>{displayRiyouConcernTitle(item)}</span>
                  {item && item.tel ? (
                    <Row>
                      <Col sm={12} className="rdp-tab-title">
                        [TEL]
                      </Col>
                      <Col sm={12}>{item.tel}</Col>
                    </Row>
                  ) : null}
                  {item && item.keitai ? (
                    <Row>
                      <Col sm={12} className="rdp-tab-title">
                        [携帯]
                      </Col>
                      <Col sm={12}>{item.keitai}</Col>
                    </Row>
                  ) : null}
                  {item && item.urgentf == 1 ? (
                    <Row>
                      <Col sm={12} className="rdp-tab-title">
                        [緊急連絡先]
                      </Col>
                      <Col sm={12}>
                        <LineBreakText
                          text={item.urgentContact ? item.urgentContact : "　"}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col sm={12}></Col>
                      <Col sm={12}>
                        <LineBreakText
                          text={item.urgentContact ? item.urgentContact : "　"}
                        />
                      </Col>
                    </Row>
                  )}

                  {item && item.bikou ? (
                    <Row>
                      <Col sm={12} className="rdp-tab-title">
                        <span>備考</span>
                      </Col>
                      <Col sm={12}>
                        <LineBreakText text={item.bikou} />
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            );
          })
        : null}
    </div>
  );
};

export default KankeiInfo;
