import React from "react";
import { Row, Col, Divider } from "antd";
import LineBreakText from "../../../common/component/LineBreakText";
import { riyouStateRetrive } from "../../../common/function_common/functionDisplay";

const FacesheetInfo = (props) => {
  let riyouState =
    props.riyousya &&
    props.riyousya.riyouState &&
    props.riyousya.riyouState.length > 0
      ? props.riyousya.riyouState
      : null;

  let facesheet = props.facesheet;
  let riyouStateRetriveValue = riyouStateRetrive(riyouState);

  return (
    <div>
      <Row className="rdp-riyou-state-tab">
        <Col xs={12} sm={8}>
          <span
            style={{
              backgroundColor: "#00838F",
              padding: 4,
              marginRight: 4,
              color: "#ffffff",
            }}
          >
            寝たきり度
          </span>
          <span>{riyouStateRetriveValue[0]}</span>
        </Col>
        <Col xs={12} sm={8}>
          <span
            style={{
              backgroundColor: "#00838F",
              padding: 4,
              marginRight: 4,
              color: "#ffffff",
            }}
          >
            認知度
          </span>
          <span>{riyouStateRetriveValue[1]}</span>
        </Col>
        <Col xs={12} sm={8}>
          <span
            style={{
              backgroundColor: "#00838F",
              padding: 4,
              marginRight: 4,
              color: "#ffffff",
            }}
          >
            精神障害度
          </span>
          <span>{riyouStateRetriveValue[2]}</span>
        </Col>
      </Row>

      {facesheet ? (
        <Row className="rdp-facesheet-tab">
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">現況</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.genkyou ? facesheet.genkyou : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">経済状況</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.keizaiJoukyou ? facesheet.keizaiJoukyou : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">家族関係等の状況</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.kazokuJoukyou ? facesheet.kazokuJoukyou : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">身体状況</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.shintaiJoukyou ? facesheet.shintaiJoukyou : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">療養状況</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.ryoyoJokyo ? facesheet.ryoyoJokyo : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">介護状況</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.kaigoJokyo ? facesheet.kaigoJokyo : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">住居環境</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.jukyoKankyou ? facesheet.jukyoKankyou : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">生活歴</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.seikatuReki ? facesheet.seikatuReki : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">既往歴</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.kioreki ? facesheet.kioreki : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">職業歴</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.shokuReki ? facesheet.shokuReki : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">趣味楽しみ特技</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.syumiEtc ? facesheet.syumiEtc : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">友人・地域との関係</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.taijinKankei ? facesheet.taijinKankei : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">本人主訴</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.honninSyuso ? facesheet.honninSyuso : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">家族主訴</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.kazokuSyuso ? facesheet.kazokuSyuso : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">本人家族の意向</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={
                facesheet.honninKazokuIko ? facesheet.honninKazokuIko : "　"
              }
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">関係者要望</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={facesheet.kankeishaYobo ? facesheet.kankeishaYobo : "　"}
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">公的サービス</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={
                facesheet.riyouServiceKouteki
                  ? facesheet.riyouServiceKouteki
                  : "　"
              }
            />
          </Col>
          <Divider />
          <Col sm={8}>
            <span className="rdp-basic-tab-title">非公的サービス</span>
          </Col>
          <Col sm={16}>
            <LineBreakText
              text={
                facesheet.riyouServiceHikouteki
                  ? facesheet.riyouServiceHikouteki
                  : "　"
              }
            />
          </Col>
          <Divider />
        </Row>
      ) : null}
    </div>
  );
};

export default FacesheetInfo;
