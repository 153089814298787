import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

class ButtonGroup extends PureComponent {
  render() {
    const { onClick, tab, select } = this.props;

    return ( //tabをmapしボタンを増やす。特記ボタンはデフォルト
      <div className="report-adl-button-group tab">
        {
          tab && tab.length > 0 
            ?
            tab.map((item, index) => (
              <Button 
                style={{ marginBottom: 3 }}
                key={`tabNaiyou_${index}`}
                className={select == item ? "report-adl-button-group-li-active" : "report-adl-button-group-li"} 
                onClick={() => onClick(item, index + 1)}
              >
                {item}
              </Button>
            ))
            :
            null
        }
        <Button 
          onClick={() => onClick("特記", 0)}
          className={select == '特記' ? "report-adl-button-group-li-active" : "report-adl-button-group-li"} 
        >
          特記
        </Button>
      </div>
    );
  }
}

ButtonGroup.propTypes = {
  onClick: PropTypes.func
};

export default ButtonGroup;