import {
  REPORT_WEEKLY_ADD_LOADDATA_REQUEST,
  REPORT_WEEKLY_ADD_LOADDATA_SUCCESS,
  REPORT_WEEKLY_ADD_LOADDATA_ERROR,
  REPORT_WEEKLY_ADD_REQUEST,
  REPORT_WEEKLY_ADD_SUCCESS,
  REPORT_WEEKLY_ADD_ERROR,
  REPORT_WEEKLY_ADD_DEL_REQUEST,
  REPORT_WEEKLY_ADD_DEL_SUCCESS,
  REPORT_WEEKLY_ADD_DEL_ERROR,
  REPORT_WEEKLY_ADD_YOUTEI_REQUEST,
  REPORT_WEEKLY_ADD_YOUTEI_SUCCESS,
} from "./actionName.js";

import {
  LoadDataApi,
  SaveDataApi,
  DelRecordApi,
  SaveYouteiToRecord,
} from "../../api/reportsWeeklyAdd";

function loadDataCreator(parameterObj, callback) {
  return (dispatch) => {
    dispatch({
      type: REPORT_WEEKLY_ADD_LOADDATA_REQUEST,
    });

    LoadDataApi.loadDataApi(parameterObj)
      .then((data) => {
        dispatch({
          type: REPORT_WEEKLY_ADD_LOADDATA_SUCCESS,
          payload: data,
        });

        return callback(data.gazou);
      })
      .catch((err) => ({
        type: REPORT_WEEKLY_ADD_LOADDATA_ERROR,
        error: err,
      }));
  };
}

function saveDataCreator(parameterObj, callback) {
  return (dispatch) => {
    dispatch({
      type: REPORT_WEEKLY_ADD_REQUEST,
    });

    SaveDataApi.saveDataApi(parameterObj)
      .then((data) => {
        dispatch({
          type: parameterObj.paramReload
            ? REPORT_WEEKLY_ADD_LOADDATA_SUCCESS
            : REPORT_WEEKLY_ADD_SUCCESS,
          payload: data,
        });

        return callback(data.gazou);
      })
      .catch((err) => ({
        type: REPORT_WEEKLY_ADD_ERROR,
        error: err,
      }));
  };
}

function delRecordCreator(type, recordId) {
  return (dispatch) => {
    dispatch({
      type: REPORT_WEEKLY_ADD_DEL_REQUEST,
    });

    DelRecordApi.delRecordApi(type, recordId)
      .then((data) =>
        dispatch({
          type: REPORT_WEEKLY_ADD_DEL_SUCCESS,
          payload: data,
          mode: type,
        })
      )
      .catch((err) => ({
        type: REPORT_WEEKLY_ADD_DEL_ERROR,
        error: err,
      }));
  };
}

function saveByYouteiCreator(item) {
  return (dispatch) => {
    dispatch({
      type: REPORT_WEEKLY_ADD_YOUTEI_REQUEST,
    });

    SaveYouteiToRecord.saveYouteiToRecord(item)
      .then((data) =>
        dispatch({
          type:
            data[0] && data[0].kaigoId
              ? REPORT_WEEKLY_ADD_YOUTEI_SUCCESS
              : REPORT_WEEKLY_ADD_ERROR,
          payload: data[0],
          params: item,
          error: data[0],
        })
      )
      .catch((err) => ({
        type: REPORT_WEEKLY_ADD_ERROR,
        error: err,
      }));
  };
}

export {
  loadDataCreator,
  saveDataCreator,
  delRecordCreator,
  saveByYouteiCreator,
};
