import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";

export const ReferenceNumberApi = {
  getMasterNewCode(table, field) {
    return axios
      .get(apiUrls.GET_MASTER_NEW_CODE, {
        params: {
          table: table,
          field: field,
        },
      })
      .then((res) => res.data);
  },

  cancelCreateMaster(table, field, code) {
    return axios
      .put(
        apiUrls.CANCEL_CREATE_MASTER +
          "?table=" +
          table +
          "&field=" +
          field +
          "&code=" +
          code
      )
      .then((res) => res.data);
  },
};
