import { ListKeysNameAttachedPDF } from "../../../../management/constants/useslip";
import { handleConvertListDataAttached, handleIgnoreKeyOfObject } from "../../../function_common/functionCommon";

export const management_Attached_InputFormPDF = (data, pageNumber) => {
  const { listDataAttached } = data;

  const convertToListObject = (item, index, maxLengthOfPage) => {
    let result = {};
    const pagePDF = Math.floor(index / maxLengthOfPage) + 1;
    const titlePage = index >= maxLengthOfPage ? `_page${pagePDF}` : "";
    const keyValue = index >= maxLengthOfPage ? (index % maxLengthOfPage === 0) ? '' : `_${(index % maxLengthOfPage)}`: index  === 0 ? "" : `_${index}`;

    ListKeysNameAttachedPDF.forEach((keyName) => {
      const key = `${keyName}${keyValue}${titlePage}`;
      result[key] = `${item[keyName]}`;
    });
  
    return { result };
  };
  
  
  let newListDataAttached;
  if(pageNumber > 1) {
    let result = {};
    let maxLengthOfPage = 22;
    // handle trường hợp quá index > 22 đổi key mới cho page mới.
    listDataAttached.forEach((item, index) => {
      let tempResult = convertToListObject(item, index, maxLengthOfPage);
      result = {...result, ...tempResult.result};
    });

    newListDataAttached = result;
  }else {
    newListDataAttached = handleConvertListDataAttached(listDataAttached)
  }
  const newListData = handleIgnoreKeyOfObject(data, ["listDataAttached", "templateColumns"]);
  const sampledata = [
    {
      ...newListDataAttached,
      ...newListData,
    },
  ];
  return sampledata;
};
