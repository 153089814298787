import React from "react";

import {
  CloseOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  ReloadOutlined,
  RightOutlined,
  UndoOutlined,
} from '@ant-design/icons';

import { Button } from "antd";
import { Link } from "react-router-dom";
import { UrlUtils } from "../../utils";
import "./lifeList.less";

function HeaderMenuView({
  data,
  goFirst,
  goPrev,
  listId,
  id,
  goNext,
  goLast,
  update,
  handleGoBack,
  onCloseTab,
  history,
  screenName,
  riyousyaName,
  activeKey,
  hadleActiveKey,
}) {
  return (
    <div className="menu-header">
      <div className="sm-c-left">
        <p>利用者</p>
        <p>{riyousyaName}</p>
        <p>{screenName}</p>
      </div>
      {
        activeKey
        ?
        <div className="sm-c-center">
          <div className="item-button">
            <span           
              style={
                activeKey == "1"
                  ? { background: "white", color: "#00838F" }
                  : {}
              }
              onClick={() => hadleActiveKey("1")}
            >
              個別機能Ⅱ
          </span>
          </div>
          <div className="item-button">
            <span 
              style={
                activeKey == "2"
                  ? { background: "white", color: "#00838F" }
                  : {}
              }
              onClick={() => hadleActiveKey("2")}
            >
                生活機能チェック
            </span>
          </div>
        </div>
        :
        null
      }
      {!UrlUtils.parse(history.location.search).preview ? (
        <div className="sm-c-right">
          <Link
            to={"#"}
            // disabled={!isFirst}
            style={
              listId && listId.indexOf(Number(id)) <= 0
                ? {cursor: "no-drop"}
                : {}
            }
            onClick={(e) => {
              e.preventDefault();
              if (listId && listId.indexOf(Number(id)) > 0)
                goFirst();
            }}
          >
            <div className="item-link">
              <DoubleLeftOutlined />
              <p>最初</p>
            </div>
          </Link>
          <Link
            to={"#"}
            // disabled={!isFirst}
            style={
              listId && listId.indexOf(Number(id)) <= 0
                ? {cursor: "no-drop"}
                : {}
            }
            onClick={(e) => {
              e.preventDefault();
              if (listId && listId.indexOf(Number(id)) > 0)
                goPrev();
            }}
          >
            <div className="item-link">
              <LeftOutlined />
              <p>前へ</p>
            </div>
          </Link>
          <Link
            to={"#"}
            // disabled={listId.indexOf(Number(id)) === listId.length - 1}
            style={
              listId && listId.indexOf(Number(id)) === listId.length - 1
                ? { cursor: "no-drop" }
                : {}
            }
            onClick={(e) => {
              e.preventDefault();
              if (listId && !(listId.indexOf(Number(id)) === listId.length - 1))

                goNext();
            }}
          >
            <div className="item-link">
              <RightOutlined />
              <p>次へ</p>
            </div>
          </Link>
          <Link
            to={"#"}
            // disabled={listId.indexOf(Number(id)) === listId.length - 1}
            style={
              listId && listId.indexOf(Number(id)) === listId.length - 1
                ? { cursor: "no-drop" }
                : {}
            }
            onClick={(e) => {
              e.preventDefault();
              if (listId && !(listId.indexOf(Number(id)) === listId.length - 1))
                goLast();
            }}
          >
            <div className="item-link">
              <DoubleRightOutlined />
              <p>最後</p>
            </div>
          </Link>
          <a
            onClick={() => handleGoBack()}
            className="button-callback-view"
          >
            <div className="item-link">
              <UndoOutlined />
              <p>戻る</p>
            </div>
          </a>
        </div>
      ) : (
        <div className="sm-c-right">
          <a onClick={() => update()} className="button-callback-view">
            <div className="item-link">
              <ReloadOutlined />
              <p>更新</p>
            </div>
          </a>
          <a onClick={() => onCloseTab()} className="button-callback-view">
            <div className="item-link">
              <CloseOutlined />
              <p>閉じる</p>
            </div>
          </a>
        </div>
      )}
    </div>
  );
}

export { HeaderMenuView };
