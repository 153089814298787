import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";

export const MediSyoubyoumeiApi = {
  loadMediSyoubyoumeiMaster(text) {
    return axios
      .get(apiUrls.GET_MEDISYOUBYOUMEI, {
        params: {
          text: text,
        },
      })
      .then((res) => res.data);
  },

  loadMediSyoubyoumeiMasterByCode(code) {
    return axios
      .get(apiUrls.GET_MEDISYOUBYOUMEI_CODE, {
        params: {
          code: code,
        },
      })
      .then((res) => res.data);
  },

  //ドクターマスターのコピー

  // loadMaxSort() {
  //   return axios.get(apiUrls.GET_DOCTOR_MASTER_MAX_SORT).then((res) => res.data);
  // },

  // loadDoctorMasterInit() {
  //   return axios.get(apiUrls.GET_DOCTOR_MASTER_INIT).then((res) => res.data);
  // },

  // createDoctorMaster(data) {
  //   return axios.post(apiUrls.CREATE_DOCTOR_MASTER, data).then((res) => res.data);
  // },

  // updateDoctorMaster(data) {
  //   return axios.put(apiUrls.UPDATE_DOCTOR_MASTER, data).then((res) => res.data);
  // }
};
