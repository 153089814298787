import { connect } from "react-redux";
import {
  SettingMasterNewCreator,
  kihonAdlMasterCreator,
} from "../../actions/SettingMasterActions/actionCreators";
import { getMediSyoubyoumeiByCode } from "../../actions/mediSyoubyoumeiActions/actionCreators";
import {
  addNewKankeiCreator,
  addRiyousyaCreator,
  changefacesheet,
  delShokujiCreator,
  delSyokujisenCreator,
  deleteKaigoHokenCreator,
  deleteKohiJohoCreator,
  getHokenNameByNo,
  getJigyousyoNameByNo,
  getKaigoHokenCreator,
  getKohiJohoCreator,
  getKohiMaster,
  getKohifutanMasterByNo,
  getShokujisenCreator,
  getSyokujiJoukyoRirekipageCreator,
  getkaigodo,
  handleDeleteCreator,
  handleSaveMealCreator,
  handleSortCreator,
  loadMoreCreator,
  riyousyaDetailCreator,
  riyousyaListCreator,
  riyousyaListIsKeep,
  riyousyaMealReset,
  updateKaigoHokenCreator,
  updateKohiJohoCreator,
  updateOpenRiyousyaCreator,
  updateRiyousyaCreator,
  updateShokujiCreator,
  updateSyokujisenCreator,
} from "../../actions/riyousyaActions/actionCreators";
import {
  deleteIryouHoken,
  deleteKougakuIryouhi,
  getIryouhokenMasterByNo,
  getListIryouHoken,
  getListKougakuIryouhi,
  updateIryouHoken,
  updateKougakuIryouhi,
} from "../../actions/riyousyaHokenActions/actionCreators";
import { zipMasterSearchCreator } from "../../actions/zipMasterActions/actionCreators";
import Riyousya from "./riyousya";
import RiyousyaAdd from "./riyousyaAdd";
import RiyousyaDetail from "./riyousyaDetail";
import RiyousyaShokuji from "./riyousyaShokuji";
import RiyousyaUpdate from "./riyousyaUpdate";

import {
  ymRiyouUpdatePageCreator,
  ymShokujiPageCreator,
  yougoMasterUpdateNewCreator,
} from "../../actions/yougoMasterNaiyouActions/actionCreators";

import {
  byoumeiMasterListCreator,
  drugMasterListCreator,
  iryouKikanMasterListCreator,
  machineMasterListCreator,
  serviceKikanMasterListCreator,
  serviceRyuruiMasterListCreator,
} from "../../actions/allMasterActions/actionCreators";
import { cancelCreateMaster } from "../../actions/referenceNumberActions/actionCreators";
import { checkHokenNoCreator } from "../../actions/riyouKaigohoken/actionCreators";
import { systemControlListSisetuCreator } from "../../actions/systemControlActions/actionCreators";

import RiyousyaHoken from "./riyousyaHoken";

import { tantoMasterList2 } from "../../actions/tantoMasterListActions/actionCreators";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    riyousya: state.riyousya.riyousya,
    loading: state.riyousya.loading,
    loadingSetting: state.settingMaster.loadingSetting,
    page: state.riyousya.page,
    page_total: state.riyousya.page_total,
    total: state.riyousya.total,
    riyousyaDetail: state.riyousya.detail,
    isWaitLoadmore: state.riyousya.isWaitLoadmore,
    zipLoading: state.zipMaster.loading,
    zipSearch: state.zipMaster.zip,
    kaigodoMaster: state.riyousya.kaigodoMaster,
    tantoMastersList: state.tantoMasterList.tantoMasters2,

    // setting place
    bunrui_is_dis: state.settingMaster.bunrui_is_dis,
    bunrui_title: state.settingMaster.bunrui_title,
    systemBunrui_is_dis: state.settingMaster.systemBunrui_is_dis,
    systemBunrui_title: state.settingMaster.systemBunrui_title,
    tekiyou_is_dis: state.settingMaster.tekiyou_is_dis,
    tekiyou_title: state.settingMaster.tekiyou_title,
    fax_title: state.settingMaster.fax_title,
    kihonAdl: state.settingMaster.kihonAdl,
    setFSOtherTitle: state.settingMaster.setFSOtherTitle,
    setFSSonotaTitle: state.settingMaster.setFSSonotaTitle,
    fsSonotaTitleDis: state.settingMaster.fsSonotaTitleDis,
    system_setFSSonotaTitle: state.settingMaster.system_setFSSonotaTitle,
    system_fsSonotaTitleDis: state.settingMaster.system_fsSonotaTitleDis,

    mealAbove: state.riyousya.mealAbove,
    pageMealAbove: state.riyousya.pageMealAbove,
    pageTotalMealAbove: state.riyousya.pageTotalMealAbove,
    delSyokujisenIds: state.riyousya.delSyokujisenIds,
    updateMealAbove: state.riyousya.updateMealAbove,

    mealBelow: state.riyousya.mealBelow,
    pageMealBelow: state.riyousya.pageMealBelow,
    pageTotalMealBelow: state.riyousya.pageTotalMealBelow,
    delShokujiIds: state.riyousya.delShokujiIds,
    updateMealBelow: state.riyousya.updateMealBelow,
    // 保険
    hokenLoading: state.riyousya.hokenLoading,
    hokenAbove: state.riyousya.hokenAbove,
    pageHokenAbove: state.riyousya.pageHokenAbove,
    pageTotalHokenAbove: state.riyousya.pageTotalHokenAbove,
    updatehokenAbove: state.riyousya.updatehokenAbove,
    // 公費
    kohiLoading: state.riyousya.kohiLoading,
    hokenBelow: state.riyousya.hokenBelow,
    pageHokenBelow: state.riyousya.pageHokenBelow,
    pageTotalHokenBelow: state.riyousya.pageTotalHokenBelow,
    updatehokenBelow: state.riyousya.updatehokenBelow,
    // 医療保険
    iryouHokenLoading: state.riyousyaHoken.iryouHokenLoading,
    iryouhoken: state.riyousyaHoken.iryouhoken,
    pageIryouhoken: state.riyousyaHoken.pageIryouhoken,
    pageTotalIryouhoken: state.riyousyaHoken.pageTotalIryouhoken,
    // updateIryouHoken: state.riyousyaHoken.updateIryouHoken,
    // 高額医療費上限
    kougakuIryouhiLoading: state.riyousyaHoken.kougakuIryouhiLoading,
    kougakuIryouhi: state.riyousyaHoken.kougakuIryouhi,
    pageKougakuIryouhi: state.riyousyaHoken.pageKougakuIryouhi,
    pageTotalKougakuIryouhi: state.riyousyaHoken.pageTotalKougakuIryouhi,
    // updateKougakuIryouhi: state.riyousyaHoken.updateKougakuIryouhi,

    isMealUpdating: state.riyousya.isMealUpdating,
    isMealMessage: state.riyousya.isMealMessage,

    isKeep: state.riyousya.isKeep,
    searchName: state.riyousya.searchName,
    addRiyousyaId: state.riyousya.addRiyousyaId,

    shushoku: state.yougoMasterNaiyou.shushoku,
    fukushoku: state.yougoMasterNaiyou.fukushoku,
    shokujisenbunrui: state.yougoMasterNaiyou.shokujisenbunrui,
    shokujisenbikou: state.yougoMasterNaiyou.shokujisenbikou,
    shokujiRirekiComent: state.yougoMasterNaiyou.shokujiRirekiComent,
    shokujiRirekiSituation: state.yougoMasterNaiyou.shokujiRirekiSituation,

    // yougomater
    riyousyaBunrui1: state.yougoMasterNaiyou.riyousyaBunrui1,
    riyousyaBunrui2: state.yougoMasterNaiyou.riyousyaBunrui2,
    riyousyaBunrui3: state.yougoMasterNaiyou.riyousyaBunrui3,
    riyousyaBunrui4: state.yougoMasterNaiyou.riyousyaBunrui4,
    riyousyaBunrui5: state.yougoMasterNaiyou.riyousyaBunrui5,
    systemBunrui1: state.yougoMasterNaiyou.systemBunrui1,
    systemBunrui2: state.yougoMasterNaiyou.systemBunrui2,
    systemBunrui3: state.yougoMasterNaiyou.systemBunrui3,
    systemBunrui4: state.yougoMasterNaiyou.systemBunrui4,
    systemBunrui5: state.yougoMasterNaiyou.systemBunrui5,
    nyukinBunrui: state.yougoMasterNaiyou.nyukinBunrui,
    nyukinTekiyo: state.yougoMasterNaiyou.nyukinTekiyo,
    kankeList: state.yougoMasterNaiyou.kankeList,
    kikanNameList: state.yougoMasterNaiyou.kikanNameList,
    hindoList: state.yougoMasterNaiyou.hindoList,
    stateList: state.yougoMasterNaiyou.stateList,
    nyuinRiyuList: state.yougoMasterNaiyou.nyuinRiyuList,
    tainRiyuList: state.yougoMasterNaiyou.tainRiyuList,
    attnList: state.yougoMasterNaiyou.attnList,
    genkyouList: state.yougoMasterNaiyou.genkyouList,
    keizaiJoukyouList: state.yougoMasterNaiyou.keizaiJoukyouList,
    kazokuJoukyouList: state.yougoMasterNaiyou.kazokuJoukyouList,
    shintaiJoukyouList: state.yougoMasterNaiyou.shintaiJoukyouList,
    ryoyoJokyoList: state.yougoMasterNaiyou.ryoyoJokyoList,
    kaigoJokyoList: state.yougoMasterNaiyou.kaigoJokyoList,
    jukyoKankyouList: state.yougoMasterNaiyou.jukyoKankyouList,
    seikatuRekiList: state.yougoMasterNaiyou.seikatuRekiList,
    kiorekiList: state.yougoMasterNaiyou.kiorekiList,
    shokuRekiList: state.yougoMasterNaiyou.shokuRekiList,
    syumiEtcList: state.yougoMasterNaiyou.syumiEtcList,
    taijinKankeiList: state.yougoMasterNaiyou.taijinKankeiList,
    honninSyusoList: state.yougoMasterNaiyou.honninSyusoList,
    kazokuSyusoList: state.yougoMasterNaiyou.kazokuSyusoList,
    honninKazokuIkoList: state.yougoMasterNaiyou.honninKazokuIkoList,
    kankeishaYoboList: state.yougoMasterNaiyou.kankeishaYoboList,
    riyouServiceKoutekiList: state.yougoMasterNaiyou.riyouServiceKoutekiList,
    riyouServiceHikoutekiList:
      state.yougoMasterNaiyou.riyouServiceHikoutekiList,

    sonotaList1: state.yougoMasterNaiyou.sonotaList1,
    sonotaList2: state.yougoMasterNaiyou.sonotaList2,
    sonotaList3: state.yougoMasterNaiyou.sonotaList3,
    sonotaList4: state.yougoMasterNaiyou.sonotaList4,
    sonotaList5: state.yougoMasterNaiyou.sonotaList5,
    sonotaList6: state.yougoMasterNaiyou.sonotaList6,
    sonotaList7: state.yougoMasterNaiyou.sonotaList7,
    sonotaList8: state.yougoMasterNaiyou.sonotaList8,
    sonotaList9: state.yougoMasterNaiyou.sonotaList9,
    sonotaList10: state.yougoMasterNaiyou.sonotaList10,

    systemSonotaList1: state.yougoMasterNaiyou.systemSonotaList1,
    systemSonotaList2: state.yougoMasterNaiyou.systemSonotaList2,
    systemSonotaList3: state.yougoMasterNaiyou.systemSonotaList3,
    systemSonotaList4: state.yougoMasterNaiyou.systemSonotaList4,
    systemSonotaList5: state.yougoMasterNaiyou.systemSonotaList5,
    systemSonotaList6: state.yougoMasterNaiyou.systemSonotaList6,
    systemSonotaList7: state.yougoMasterNaiyou.systemSonotaList7,
    systemSonotaList8: state.yougoMasterNaiyou.systemSonotaList8,
    systemSonotaList9: state.yougoMasterNaiyou.systemSonotaList9,
    systemSonotaList10: state.yougoMasterNaiyou.systemSonotaList10,

    joukyouList1: state.yougoMasterNaiyou.joukyouList1,
    joukyouList2: state.yougoMasterNaiyou.joukyouList2,
    joukyouList3: state.yougoMasterNaiyou.joukyouList3,
    joukyouList4: state.yougoMasterNaiyou.joukyouList4,
    joukyouList5: state.yougoMasterNaiyou.joukyouList5,
    joukyouList6: state.yougoMasterNaiyou.joukyouList6,
    joukyouList7: state.yougoMasterNaiyou.joukyouList7,
    joukyouList8: state.yougoMasterNaiyou.joukyouList8,
    joukyouList9: state.yougoMasterNaiyou.joukyouList9,
    joukyouList10: state.yougoMasterNaiyou.joukyouList10,

    //
    byomeiMaster: state.allMaster.byomeiMaster,
    ymByoumeiBunrui: state.yougoMasterNaiyou.ymByoumeiBunrui,
    drugMaster: state.allMaster.drugMaster,
    ymKusurimeiBunrui: state.yougoMasterNaiyou.ymKusurimeiBunrui,
    machineMaster: state.allMaster.machineMaster,
    serviceSyuruiMaster: state.allMaster.serviceSyuruiMaster,
    serviceKikanMaster: state.allMaster.serviceKikanMaster,
    serviceKikanList: state.serviceKikanMaster.serviceKikanList,
    iryouKikanMaster: state.allMaster.iryouKikanMaster,

    duplicationRiyousya: state.riyouKaigohokens.duplicationRiyousya,
    changeFacesheetState: state.riyousya.changeFacesheetState,
    saveFacesheetState: state.riyousya.saveFacesheetState,
    openFacesheetState: state.riyousya.openFacesheetState,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (page, isRiyouf, nyuusho, furigana, id) =>
      dispatch(riyousyaListCreator(page, isRiyouf, nyuusho, furigana, id)),
    loadMore: (page, isRiyouf, nyuusho, furigana, callback) =>
      dispatch(loadMoreCreator(page, isRiyouf, nyuusho, furigana, callback)),
    riyousyaListIsKeep: (searchName, isKeep, addId) =>
      dispatch(riyousyaListIsKeep(searchName, isKeep, addId)),

    // getDetailPage
    getRiyousya: (riyousyaId) => dispatch(riyousyaDetailCreator(riyousyaId)),
    searchYuuBinBango: (text) => dispatch(zipMasterSearchCreator(text)),
    addRiyousya: (params, callback) =>
      dispatch(addRiyousyaCreator(params, callback)),

    //
    getSettingALL: (singleTextTitle, singleText) =>
      dispatch(SettingMasterNewCreator(singleTextTitle, singleText)),
    getKihonAdlAll: () => dispatch(kihonAdlMasterCreator()),

    // add new kankei, only handle on UI
    handleAdd: (type) => dispatch(addNewKankeiCreator(type)),
    handleDelete: (type, index) => dispatch(handleDeleteCreator(type, index)),
    handleSort: (type, sortType, index) =>
      dispatch(handleSortCreator(type, sortType, index)),

    updateRiyousya: (params) => dispatch(updateRiyousyaCreator(params)),
    updateOpenRiyousya: (params) => dispatch(updateOpenRiyousyaCreator(params)),

    // 利用者食事セン
    riyousyaMealReset: () => dispatch(riyousyaMealReset()),
    getShokujisen: (page, riyousyaId) =>
      dispatch(getShokujisenCreator(page, riyousyaId)),
    getSyokujiJoukyoRirekipage: (page, riyousyaId) =>
      dispatch(getSyokujiJoukyoRirekipageCreator(page, riyousyaId)),
    handleDelSyokujisen: (id, index) =>
      dispatch(delSyokujisenCreator(id, index)),
    handleDelShokuji: (id, index) => dispatch(delShokujiCreator(id, index)),
    updateSyokujisen: (record) => dispatch(updateSyokujisenCreator(record)),
    updateShokuji: (record) => dispatch(updateShokujiCreator(record)),
    handleSaveMeal: (params) => dispatch(handleSaveMealCreator(params)),

    // 食事センpage
    ymShokujiPage: () => dispatch(ymShokujiPageCreator()),
    ymRiyouUpdatePage: () => dispatch(ymRiyouUpdatePageCreator()),

    yougoMasterUpdate: (key, naiyou) =>
      dispatch(yougoMasterUpdateNewCreator(key, naiyou)),

    //
    byoumeiMasterList: (bunrui, name) =>
      dispatch(byoumeiMasterListCreator(bunrui, name)),
    drugMasterList: (bunrui, name) =>
      dispatch(drugMasterListCreator(bunrui, name)),
    machineMasterList: (bunrui, name) =>
      dispatch(machineMasterListCreator(bunrui, name)),
    serviceRyuruiMasterList: (bunrui, name) =>
      dispatch(serviceRyuruiMasterListCreator(bunrui, name)),
    serviceKikanMasterList: (bunrui, name) =>
      dispatch(serviceKikanMasterListCreator(bunrui, name)),
    iryouKikanMasterList: (bunrui, name) =>
      dispatch(iryouKikanMasterListCreator(bunrui, name)),

    // mediSyoubyoumei
    getMediSyoubyoumeiByCode: (code, callback) =>
      dispatch(getMediSyoubyoumeiByCode(code, callback)),

    // reference_number
    cancelCreateMaster: (table, field, code) =>
      dispatch(cancelCreateMaster(table, field, code)),

    // system_control
    getSystemControlListSisetu: (callback) =>
      dispatch(systemControlListSisetuCreator(callback)),

    // 利用者保険
    // kaigoHoken
    getKaigoHoken: (page, riyousyaId) =>
      dispatch(getKaigoHokenCreator(page, riyousyaId)),
    updateKaigoHoken: (record) => dispatch(updateKaigoHokenCreator(record)),
    deleteKaigoHoken: (id) => dispatch(deleteKaigoHokenCreator(id)),
    getJigyousyoName: (jigyousyoNo, syuruiCd, callback) =>
      dispatch(getJigyousyoNameByNo(jigyousyoNo, syuruiCd, callback)),
    getHokenName: (hokensyaNo, callback) =>
      dispatch(getHokenNameByNo(hokensyaNo, callback)),
    checkHokenNo: (hokenNo, hihokenNo, riyousyaId, callback) =>
      dispatch(checkHokenNoCreator(hokenNo, hihokenNo, riyousyaId, callback)),
    // kohifutan
    getKohiJoho: (page, riyousyaId) =>
      dispatch(getKohiJohoCreator(page, riyousyaId)),
    updateKohiJoho: (record) => dispatch(updateKohiJohoCreator(record)),
    deleteKohiJoho: (id) => dispatch(deleteKohiJohoCreator(id)),
    getKohiMaster: (kohiNo, callback) =>
      dispatch(getKohiMaster(kohiNo, callback)),
    getKohifutanMaster: (hutanNo, callback) =>
      dispatch(getKohifutanMasterByNo(hutanNo, callback)),
    // iryouhoken
    getListIryouHoken: (page, riyousyaId) =>
      dispatch(getListIryouHoken(page, riyousyaId)),
    updateIryouHoken: (value) => dispatch(updateIryouHoken(value)),
    deleteIryouHoken: (id) => dispatch(deleteIryouHoken(id)),
    getIryouhokenMaster: (hokenNo, callback) =>
      dispatch(getIryouhokenMasterByNo(hokenNo, callback)),
    // 高額医療費
    getListKougakuIryouhi: (page, riyousyaId) =>
      dispatch(getListKougakuIryouhi(page, riyousyaId)),
    updateKougakuIryouhi: (value) => dispatch(updateKougakuIryouhi(value)),
    deleteKougakuIryouhi: (id) => dispatch(deleteKougakuIryouhi(id)),

    // etc
    getKaigodo: () => dispatch(getkaigodo()),
    tantoMasterList2: () => dispatch(tantoMasterList2()),
    changefacesheet: (state, type) => dispatch(changefacesheet(state, type)),
  };
};

export const RiyousyaContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Riyousya);
export const RiyousyaDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RiyousyaDetail);
export const RiyousyaUpdateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RiyousyaUpdate);
export const RiyousyaShokujiContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RiyousyaShokuji);
export const RiyousyaAddContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RiyousyaAdd);
export const RiyousyaHokenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RiyousyaHoken);
