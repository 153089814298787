import { HyoukahyouActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";

function hyoukahyou(state = {}, action) {
  switch (action.type) {
    // get all plan1 by user id
    case HyoukahyouActionTypes.GET_ALL_HYOUKAHYOU_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case HyoukahyouActionTypes.GET_ALL_HYOUKAHYOU_SUCCESS: {
      return {
        ...state,
        loading: false,
        datas: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case HyoukahyouActionTypes.GET_ALL_HYOUKAHYOU_FAILURE: {
      return {
        ...state,
        datas: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }

    // get one plan1
    case HyoukahyouActionTypes.GET_HYOUKAHYOU_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getOne",
      };
    }
    case HyoukahyouActionTypes.GET_HYOUKAHYOU_SUCCESS: {
      return {
        ...state,
        data: action.data,
        loading: false,
        type: "",
      };
    }
    case HyoukahyouActionTypes.GET_HYOUKAHYOU_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        type: "",
      };
    }

    // create plan1
    case HyoukahyouActionTypes.CREATE_HYOUKAHYOU_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case HyoukahyouActionTypes.CREATE_HYOUKAHYOU_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }
    case HyoukahyouActionTypes.CREATE_HYOUKAHYOU_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // update plan1
    case HyoukahyouActionTypes.UPDATE_HYOUKAHYOU_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case HyoukahyouActionTypes.UPDATE_HYOUKAHYOU_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }
    case HyoukahyouActionTypes.UPDATE_HYOUKAHYOU_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // remove
    case HyoukahyouActionTypes.REMOVE_HYOUKAHYOU_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case HyoukahyouActionTypes.REMOVE_HYOUKAHYOU_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case HyoukahyouActionTypes.REMOVE_HYOUKAHYOU_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    //前回引用
    case HyoukahyouActionTypes.GET_HYOUKAHYOU_BY_DATA_REQUEST:
      return {
        ...state,
        loadingByData: true,
      };
    case HyoukahyouActionTypes.GET_HYOUKAHYOU_BY_DATA_SUCCESS:
      return {
        ...state,
        loadingByData: false,
        datas: action.data || [],
        // metaData: CommonUtils.pagination(action.data.meta),
      };
    case HyoukahyouActionTypes.GET_HYOUKAHYOU_BY_DATA_FAILURE:
      return {
        ...state,
        loadingByData: false,
        metaData: {},
      };

    case HyoukahyouActionTypes.GET_ALL_HYOUKAHYOU_QUOTE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case HyoukahyouActionTypes.GET_ALL_HYOUKAHYOU_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuote: action.data,
      };
    case HyoukahyouActionTypes.GET_ALL_HYOUKAHYOU_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listQuote: [],
      };

    case HyoukahyouActionTypes.RESET_CURRENT_HYOUKAHYOU:
      return {
        ...state,
        data: {},
        loading: false,
      };

    case HyoukahyouActionTypes.GET_HYOUKAHYOU_YOBOU_REQUEST:
      return {
        ...state,
        loadingYobousien: true,
      };
    case HyoukahyouActionTypes.GET_HYOUKAHYOU_YOBOU_SUCCESS:
      return {
        ...state,
        loadingYobousien: false,
        yobouData: action.data,
      };
    case HyoukahyouActionTypes.GET_HYOUKAHYOU_YOBOU_FAILURE:
      return {
        ...state,
        loadingYobousien: false,
        yobouData: {},
      };

    default: {
      return { ...state };
    }
  }
}

export default hyoukahyou;
