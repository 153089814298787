import React from "react";
import { Button, Popover, Table } from "antd";
import { convertArrStrToArrObj } from "../../../common/function_common/functionDisplay";
import _ from "lodash";
import TextInputArea from "./TextInputArea";
import TextInput from "./TextInput";
import KaigoHokenMasterModal from "./KaigoHokenMasterModal";
import KohifutanMasterModal from "./KohifutanMasterModal";
import IryouhokenMasterModal from "./IryouhokenMasterModal";

export default class TextAreaSelectNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visibleM: false,
      visibleKohi: false,
      visibleIryou: false,
    };

    // bind place
  }

  /**
   * trigger dirrectly from input
   *
   */
  select = (record) => {
    const { value } = this.props;
    let tpm = "";

    if (record && record.naiyou) {
      if (value) {
        tpm = value + "、" + record.naiyou;
      } else {
        tpm = record.naiyou;
      }
    }

    this.triggerChange(tpm);
  };

  /**
   * triiger from table select row (yougo_master)
   *
   */
  handleSelectChange = (value) => {
    this.triggerChange(value);
  };

  /**
   * Should provide an event to pass value to Form.
   *
   */
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  handleOk = () => {
    // reset
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.triggerChange("");

    // reset
    this.setState({
      visible: false,
    });
  };

  handleOpenTable = () => {
    // open modal
    this.setState({
      visible: true,
    });
  };

  onClickCellEvent = (record) => {
    this.triggerChange(record.naiyou);

    this.setState({
      rowActive: record.id,
    });
  };

  handleModalKaigo = () => {
    if (this?.props?.type == "kohi") {
      this.setState({
        visibleKohi: true,
      });
    } else if (this?.props?.type == "iryou") {
      this.setState({
        visibleIryou: true,
      })
    } else {
      this.setState({
        visibleM: true,
      });
    }
  };

  onSelectKaigoHoken = (record) => {
    this.props.onChangeCus(
      record.hokenName + "-" + record.hokenNo + "-" + record.kyufu
    );

    this.setState({
      visibleM: false,
    });
  };

  onSelectKohifutan = (record) => {
    this.props.onChangeCus(record.futanName + "-" + record.futanNo);

    this.setState({
      visibleKohi: false,
    });
  };

  onSelectIryou = (record) => {
    this.props.onChangeCus(record.hokenNo + "-" + record.hokenName);

    this.setState({
      visibleIryou: false,
    });
  };

  onHideKaigoHoken = () => {
    this.setState({
      visibleM: false,
    });
  };

  onHideKohifutan = () => {
    this.setState({
      visibleKohi: false,
    });
  };

  onHideIryou = () => {
    this.setState({
      visibleIryou: false,
    });
  };

  render() {
    const { value, dataSelect, disabled, style } = this.props;

    // console.log(value)

    const columns = [
      {
        title: "naiyou",
        dataIndex: "naiyou",
        width: 450,
        key: "naiyou",
        render: (textEx, record, index) => record.naiyou,
        onCell: (record) => {
          return {
            onClick: (event) => {
              this.onClickCellEvent(record);
            }, // click row
          };
        },
      },
    ];

    // convert Array(String) to Array(Object)
    let dataTable1 = convertArrStrToArrObj(dataSelect);
    const content = (
      <Table
        bordered
        columns={columns}
        dataSource={dataTable1}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 240 }}
        showHeader={false}
        size={"small"}
        rowKey={(record, index) => index}
      />
    );

    return (
      <div className="text-area-select-ri-page">
        <span className="text-area-select-btn">
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            onClick={this.handleModalKaigo}
          >
            M
          </Button>
        </span>
        <KohifutanMasterModal
          visible={this.state.visibleKohi}
          onSelect={this.onSelectKohifutan}
          onHide={this.onHideKohifutan}
        />
        <KaigoHokenMasterModal
          visible={this.state.visibleM}
          onSelect={this.onSelectKaigoHoken}
          onHide={this.onHideKaigoHoken}
        />
        <IryouhokenMasterModal
          visible={this.state.visibleIryou}
          onSelect={this.onSelectIryou}
          onHide={this.onHideIryou}
        />

        <TextInput
          rows={1}
          className="text-area-select-text"
          value={value ? value : ""}
          onChange={this.handleSelectChange}
          readOnly
          // style={{ background: 'white', color: 'rgba(0, 0, 0, 0.7)'}}
          style={style}
        />
      </div>
    );
  }
}
