import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Table, Input, Button, Row, Col, Spin, Divider } from "antd";
// import DoctorMasterEditModal from "./DoctorMasterEditModal";
import {
  loadItakuTantoMaster,
  // updateDocotrMasterList,
} from "../../../actions/riyousyaActions/actionCreators";
import { ItakuTantoMasterActions } from "../../../management/redux/actions";

class ServiceRyuruiMasterModal extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;

    this.state = {
      selectedRowKeys: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.props.getServiceSyuruiMasters();
      this.setState({
        selectedRowKeys: this?.props?.value
          ? this.props.value.split(";")
          : null, //デフォルトセット
      });
    }

    if (this.props.value && this.props.value !== prevProps.value) {
      this.setState({
        selectedRowKeys: this.props.value.split(";"), //デフォルトセット
      });
    }
  }

  handleOk = () => {
    this.props.onHide();
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      //親のonChangeで","を変換してる
      this.props.onSave(selectedRowKeys.toString());
    } else {
      this.props.onSave("");
    }
    this.setState({
      selectedRowKeys: [],
    });
  };

  handleCancel = () => {
    this.props.onHide();
    this.setState({
      selectedRowKeys: [],
    });
  };

  handleSelectRow(record) {
    this.setState({
      selectedRowKeys: this.state.selectedRowKeys.push(record.serviceSyuruiCd),
    });
  }

  //全ての種類のチェックをマスターから取得
  selectAll = () => {
    const { serviceSyuruiMasters } = this.props;
    let tmp = [];
    if (serviceSyuruiMasters && serviceSyuruiMasters.length > 0) {
      for (var i = 0, l = serviceSyuruiMasters.length; i < l; i++) {
        tmp.push(serviceSyuruiMasters[i].serviceSyuruiCd);
      }
    }

    this.setState({
      selectedRowKeys: tmp,
    });
  };

  rmSelectAll = () => {
    this.setState({
      selectedRowKeys: [],
    });
  };

  //selectクリック時
  onSelectChange = (selectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const { valueTo } = this.props;

    // console.log(selectedRowKeys)

    const columns = [
      {
        dataIndex: "operation",
        key: "operation",
        render: (text, record, index) => {
          return (
            <div>
              <span>
                {record ? `${record.serviceSyuruiCd}_${record.ryaku}` : ""}
              </span>
            </div>
          );
        },
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange, //
    };
    // const hasSelected = selectedRowKeys.length > 0;

    const title = (
      <div>
        <Row style={{ marginBottom: "-10px" }}>
          <Col>該当サービス</Col>
        </Row>
      </div>
    );

    return (
      (<div>
        <Modal
          open={this.props.visible}
          title={title}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="tanto-doctor-master-modal modal-list"
          closable={false}
          footer={[
            <Button key="back" onClick={() => this.handleCancel()}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary" onClick={() => this.handleOk()}>
              OK
            </Button>,
          ]}
          width={350}
        >
          <span>
            <Button type="primary" onClick={this.selectAll}>
              全選択
            </Button>
            <Button
              type="primary"
              onClick={this.rmSelectAll}
              style={{ marginLeft: 16 }}
            >
              全解除
            </Button>
          </span>

          {
            <Table
              rowKey="serviceSyuruiCd"
              style={{ cursor: "pointer", marginTop: 5, marginBottom: -30 }}
              size={"small"}
              bordered={true}
              columns={columns}
              dataSource={this.props.serviceSyuruiMasters}
              pagination={{ pageSize: 20 }}
              scroll={{ y: 350 }}
              // onRow={record => ({
              //   onClick: () => this.handleSelectRow(record)
              // })}

              rowSelection={rowSelection} //左のチェック
              showHeader={false}
            />
          }
        </Modal>
      </div>)
    );
  }
}

ServiceRyuruiMasterModal.propTypes = {
  visible: PropTypes.bool,
  onSelect: PropTypes.func,
  onHide: PropTypes.func,
};

const mapStateToProps = () => (state) => ({
  // loading: state.riyousya.loadingItaku,
  serviceSyuruiMasters: state.itakuTantoMaster.serviceSyuruiMasters,
});

const mapDispatchToProps = () => (dispatch) => ({
  getServiceSyuruiMasters: () =>
    dispatch(ItakuTantoMasterActions.getServiceSyuruiMasters()),
  // updateDocotrMasterList: (data) => dispatch(updateDocotrMasterList(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceRyuruiMasterModal);
