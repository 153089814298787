import { Button, Layout, Modal } from "antd";
import _ from "lodash";
import React from "react";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import {
  initializeSocket, logoutCreators, removeSocket
} from "../../actions/commonActions/actionCreators";
import getAvatar from "../../actions/getAvatarActions/actionCreators";
import { loadReportCasesStartup } from "../../actions/progressActions/actionCreators";
import { isLife, isManagement, localStorageClearCus } from "../function_common/functionCommon";
import Header from "./Header";

const mapStateToProps = (state) => {
  return {
    preventLogin: state.messageList.preventLogin,
    socket: state.messageList.socket,
    getAvatarResponse: state.getAvatar.data,
    moshiokuri: state.progress.moshiokuri,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeSocket: () => dispatch(initializeSocket()),
    logout: () => dispatch(logoutCreators()),
    closeSocket: () => dispatch(removeSocket()),
    getAvatar: () => dispatch(getAvatar()),
    getReportCasesStartup: () => dispatch(loadReportCasesStartup()),
  };
};

export const MoshiokuriContext = React.createContext(false);

export class IdleTimerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isTimeout: false,
    };
    this.idleTimer = null;
    this.onIdle = IdleTimerPage._onIdle.bind(this);
  }

  removeStorage() {
    if (!isManagement() && !isLife()) {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("doubleLogin");
      localStorage.removeItem("getAvatarResponse");
      localStorage.removeItem("getAvatarResponseData");
    }
  }

  /**
   * Idle Time
   * @param {*} e
   */
  static _onIdle() {
    if (!this.state.isTimeout && !isManagement() && !isLife()) {
      this.setState({
        visible: true,
        isTimeout: true,
      });
      this.props.closeSocket();
      this.props.logout();
    }
  }

  async componentDidMount() {
    if (!this.props.socket && !isManagement() && !isLife()) {
      this.props.initializeSocket();
    }
    await this.props.getReportCasesStartup();

    if (!this.props.getAvatarResponse) {
      this.props.getAvatar();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.preventLogin &&
      !this.state.isTimeout &&
      !isManagement() &&
      !isLife()
    ) {
      this.setState({
        visible: true,
        isTimeout: true,
      });
      this.props.closeSocket();
      this.props.logout();
    }
  }

  // Close Popup double login
  handleOk() {
    this.setState({
      visible: false,
    });
    this.removeStorage();
    window.location.href = "/login";
  }

  render() {
    if (isManagement()) {
      return <div id={this.props.id}>{this.props.children}</div>;
    }

    if (isLife()) {
      return <div id={this.props.id}>{this.props.children}</div>;
    }

    const { getAvatarResponse } = this.props;

    /** Get Avatar */
    let getAvatar = false;
    let getAvatarResponseData = "";
    if (getAvatarResponse) {
      if (getAvatarResponse.data) {
        getAvatar = true;
        getAvatarResponseData = getAvatarResponse.data;
      }
      else if (getAvatarResponse.response) {
        if (getAvatarResponse.response.data) {
          if (getAvatarResponse.response.data.error) {
            if (
              getAvatarResponse.response.data.error.code === "INVALID_TOKEN"
            ) {
              localStorageClearCus();

              window.location.href = "/login";
            }
          }
        }
      }
    }

    return (
      (<IdleTimer
        ref={(ref) => {
          this.idleTimer = ref;
        }}
        element={document}
        onIdle={this.onIdle}
        timeout={this.props.sessionTimeout}
      >
        <Layout id={this.props.id}>
          <Layout.Header className={"pljp-layout-header"}>
            <Header
              sessionTimeout={this.state.isTimeout}
              getAvatarResponse={getAvatar}
              getAvatarResponseData={getAvatarResponseData}
            />
          </Layout.Header>
          <MoshiokuriContext.Provider value={!_.isEmpty(this.props.moshiokuri)}>
            <Layout.Content>{this.props.children}</Layout.Content>
          </MoshiokuriContext.Provider>
        </Layout>
        <Modal
          open={this.state.visible}
          title="エラー情報"
          onOk={() => this.handleOk()}
          footer={[
            <Button
              style={{ backgroundColor: "#00838F", borderColor: "#00838F" }}
              key="submit"
              type="primary"
              onClick={() => this.handleOk()}
            >
              OK
            </Button>,
          ]}
        >
          <p>以下の理由によりセッションが強制終了されました。</p>
          <p>・同じIDで別の端末からログインがあった </p>
          <p>・セッションの有効期限切れ</p>
        </Modal>
      </IdleTimer>)
    );
  }
}

export const CommonPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IdleTimerPage);
