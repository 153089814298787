import React, { useState } from "react";
import { Modal, Checkbox } from "antd";

function DeleteConfirmModal(props) {
  const [checked, setChecked] = useState(false);
  function handleClose() {
    props.onHide();
    setChecked(false);
  }

  function handleOk() {
    props.onOk();
    setChecked(false);
  }

  function handleChangeCheckbox(e) {
    setChecked(e.target.checked);
  }
  return (
    (<Modal
      open={props.visible}
      title="削除確認"
      onCancel={handleClose}
      onOk={handleOk}
      okButtonProps={{ disabled: !checked }}
    >
      <div>
        <span style={{ display: "block" }}>{"この行を削除しますか？"}</span>
        <Checkbox checked={checked} onChange={handleChangeCheckbox}>
          {"削除に同意する"}
        </Checkbox>
      </div>
    </Modal>)
  );
}

export { DeleteConfirmModal };
