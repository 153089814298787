import { UndoOutlined } from "@ant-design/icons";
import { Row, notification } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderRiyousya from "../../common/component/SubHeaderRiyousya";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import { ConstSet } from "../../common/configs/constset";
import { getUrlVars } from "../../common/function_common/functionCommon";
import "./Plan.css";
import { IndividualPlan } from "./UI/IndividualPlan";
import { IndividualPlanMobile } from "./UI/IndividualPlanMobile";
import Toolbar from "./UI/Toolbar";

class RiyousyaPlan extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const { riyousyaId } = this.props.match.params;
    const queryDate = getUrlVars(window.location.href)["dateSelected"];

    this.state = {
      screenMode: "individual",
      selectedDate: queryDate ? dayjs(queryDate, "YYYY-MM-DD") : dayjs(today),
      riyousyaId,
      riyousayInfo: null,
      selectedValues: [],
    };

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  loadData(mode, dateMoment, riyousyaId) {
    const date = dateMoment.toDate();
    const startDate = dayjs(
      new Date(date.getFullYear(), date.getMonth(), -15)
    ).format(ConstSet.DATE_FORMAT);
    const endDate = dayjs(
      new Date(date.getFullYear(), date.getMonth() + 1, 15)
    ).format(ConstSet.DATE_FORMAT);
    if (riyousyaId == null) {
      this.props.getIndividualPlans(
        this.state.riyousyaId.toString(),
        startDate,
        endDate
      );
    }
    else {
      this.props.getIndividualPlans(riyousyaId.toString(), startDate, endDate);
    }
  }

  /**
   * Load for first Time
   */
  componentDidMount() {
    this.props.getyougoMasterNaiyou();
    this.props.getRiyousyaInfo(this.state.riyousyaId);
    this.loadData(this.state.screenMode, this.state.selectedDate);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.riyousya && newProps.riyousya.id == this.state.riyousyaId) {
      this.setState({
        riyousayInfo: {
          riyousya: newProps.riyousya,
        },
      });
    }
  }

  /**
   * Load when action ある
   */
  componentDidUpdate(prevProps) {
    // Check When Action Complie
    const { notifyDisplay, notifyContent } = this.props;

    if (prevProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        this.openNotificationWithIcon("success", notifyContent);
      }
      if (notifyDisplay === 2) {
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  selectMonth(month) {
    const oldMonth = this.state.selectedDate.toDate().getMonth();
    if (month.toDate().getMonth() !== oldMonth)
      this.loadData(this.state.screenMode, month);
    this.setState({
      selectedDate: month,
    });
  }

  handleSelectValues(values) {
    this.setState({
      selectedValues: values,
    });
  }

  handleInputUserPlan(data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].riyousyaPlanId) {
        if (i === data.length - 1) {
          this.props.updateIndividualPlan(
            data[i].riyousyaPlanId.toString(),
            data[i].riyousyaId.toString(),
            data[i].date,
            data[i].yotei,
            data[i].sonota,
            () => {
              this.loadData(this.state.screenMode, this.state.selectedDate);
            }
          );
        }
        else {
          this.props.updateIndividualPlan(
            data[i].riyousyaPlanId.toString(),
            data[i].riyousyaId.toString(),
            data[i].date,
            data[i].yotei,
            data[i].sonota,
            () => {
              /* do nothing*/
            }
          );
        }
      }
      else if (i === data.length - 1) {
        this.props.createIndividualPlan(
          data[i].riyousyaId.toString(),
          data[i].date,
          data[i].yotei,
          data[i].sonota,
          () => {
            this.loadData(this.state.screenMode, this.state.selectedDate);
          }
        );
      }
      else {
        this.props.createIndividualPlan(
          data[i].riyousyaId.toString(),
          data[i].date,
          data[i].yotei,
          data[i].sonota,
          () => {
            /* do nothing*/
          }
        );
      }
    }
  }

  handleDeleteUserPlan(riyousyaPlanIds) {
    if (riyousyaPlanIds || riyousyaPlanIds.length > 0) {
      for (let i = 0; i < riyousyaPlanIds.length; i++) {
        if (i === riyousyaPlanIds.length - 1) {
          if (riyousyaPlanIds[i]) {
            this.props.deleteIndividualPlan(
              riyousyaPlanIds[i].toString(),
              () => {
                this.loadData(this.state.screenMode, this.state.selectedDate);
              }
            );
          }
          else {
            this.loadData(this.state.screenMode, this.state.selectedDate);
          }
        }
        else if (riyousyaPlanIds[i]) {
          this.props.deleteIndividualPlan(riyousyaPlanIds[i].toString(), () => {
            /* do nothing*/
          });
        }
      }
    }
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }

  getReportRiyousya = (value) => {
    if (value) {
      this.props.history.push("/plan/" + value);
      this.setState({
        riyousyaId: value,
      });
      this.props.getRiyousyaInfo(value);
      this.loadData(this.state.screenMode, this.state.selectedDate, value);
    }
  };

  render() {
    return (
      <CommonPageContainer
        id="plan-page"
        sessionTimeout={this.props.sessionTimeout}
      >
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <div className={"container"}>
                  <Row className={"submenu"}>
                    <SubHeaderV2
                      TopLeft={
                        <SubHeaderTitleV2
                          title={"個別予定（利用者別）"}
                          iconType="schedule"
                        />
                      }
                      TopRight={
                        <Link
                          to={"/"}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.goBack();
                          }}
                          className="sub-top-right-item"
                        >
                          <span>
                            <UndoOutlined />
                          </span>
                          <span>戻る</span>
                        </Link>
                      }
                      IncBottom={
                        <div>
                          <SubHeaderRiyousya
                            style={{ display: "inline" }}
                            reportRiyousya={(value) =>
                              this.getReportRiyousya(value)
                            }
                            riyousayInfo={this.state.riyousayInfo}
                          />
                          <Toolbar
                            style={{ display: "inline", paddingLeft: "20px" }}
                            mode={this.state.screenMode}
                            riyousyaId={this.state.riyousyaId}
                            handleChangeMode={(mode) => this.changeMode(mode)}
                            selectedDate={this.state.selectedDate}
                            selectedValues={this.state.selectedValues}
                            data={this.props.individualPlans}
                            handleSelectMonth={(month) =>
                              this.selectMonth(month)
                            }
                            handleInputUserPlan={(data) =>
                              this.handleInputUserPlan(data)
                            }
                            handleDeleteUserPlan={(planId) =>
                              this.handleDeleteUserPlan(planId)
                            }
                            yougoMasterEditState={this.getYougoMasterEditState}
                            yougoMasterEditCancel={
                              this.getYougoMasterEditCancel
                            }
                            yougoMasterEditSave={this.getYougoMasterEditSave}
                            yougoMasterNaiyou={this.props.yougoMasterNaiyou}
                          />
                        </div>
                      }
                    />
                  </Row>
                  <Row>
                    <IndividualPlan
                      mode={"individual"}
                      riyousyaId={this.state.riyousyaId}
                      selectedDate={this.state.selectedDate}
                      handleSelectMonth={(month) => this.selectMonth(month)}
                      selectedValues={this.state.selectedValues}
                      handleSelectValues={(values) =>
                        this.handleSelectValues(values)
                      }
                      data={this.props.individualPlans}
                    />
                  </Row>
                </div>
              );
            }
            return (
              <div className={"container"}>
                <Row className={"submenu"}>
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={"個別予定（利用者別）"}
                        iconType="schedule"
                      />
                    }
                    IncBottom={
                      <SubHeaderRiyousya
                        style={{ display: "inline" }}
                        reportRiyousya={(value) =>
                          this.getReportRiyousya(value)
                        }
                        riyousayInfo={this.state.riyousayInfo}
                      />
                    }
                  />
                </Row>
                <Row>
                  <IndividualPlanMobile
                    mode={"individual"}
                    riyousyaId={this.state.riyousyaId}
                    selectedDate={this.state.selectedDate}
                    handleSelectMonth={(month) => this.selectMonth(month)}
                    data={this.props.filteredIndividualPlans}
                    handleInputUserPlan={(data) =>
                      this.handleInputUserPlan(data)
                    }
                    handleDeleteUserPlan={(riyousyaPlanId) =>
                      this.handleDeleteUserPlan(riyousyaPlanId)
                    }
                    yougoMasterEditState={this.getYougoMasterEditState}
                    yougoMasterEditCancel={this.getYougoMasterEditCancel}
                    yougoMasterEditSave={this.getYougoMasterEditSave}
                    yougoMasterNaiyou={this.props.yougoMasterNaiyou}
                  />
                </Row>
              </div>
            );
          }}
        </MediaQuery>
      </CommonPageContainer>
    );
  }
}

export default RiyousyaPlan;
