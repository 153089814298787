import { Form } from "@ant-design/compatible";
import { DatePicker, Divider } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Component } from "react";
import { ConstSet } from "../../../common/configs/constset";
import TextAreaSelect from "./TextAreaSelect";
import TextInputArea from "./TextInputArea";

class FormFacesheetNormal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      attnList,
      genkyouList,
      keizaiJoukyouList,
      kazokuJoukyouList,
      shintaiJoukyouList,
      ryoyoJokyoList,
      kaigoJokyoList,
      jukyoKankyouList,
      seikatuRekiList,
      kiorekiList,
      shokuRekiList,
      syumiEtcList,
      taijinKankeiList,
      honninSyusoList,
      kazokuSyusoList,
      honninKazokuIkoList,
      kankeishaYoboList,
      riyouServiceKoutekiList,
      riyouServiceHikoutekiList,
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        <Form.Item label={"作成日"}>
          {getFieldDecorator("date", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<DatePicker style={{ width: "150px" }} />)}
        </Form.Item>
        <Divider />
        <Form.Item label={"コメント"} colon={false}>
          {getFieldDecorator("comment", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextInputArea autoSize={{ minRows: 4, maxRows: 99 }} />)}
        </Form.Item>
        <Divider />
        <Form.Item label={"宛先"} colon={false}>
          {getFieldDecorator("attn", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={attnList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("宛先", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"現況"} colon={false}>
          {getFieldDecorator("genkyou", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={genkyouList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("現況", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"経済状況"} colon={false}>
          {getFieldDecorator("keizaiJoukyou", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={keizaiJoukyouList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("経済状況", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"家族関係等の状況"} colon={false}>
          {getFieldDecorator("kazokuJoukyou", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={kazokuJoukyouList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("家族状況", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"身体状況"} colon={false}>
          {getFieldDecorator("shintaiJoukyou", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={shintaiJoukyouList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("身体状況", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"療養状況"} colon={false}>
          {getFieldDecorator("ryoyoJokyo", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={ryoyoJokyoList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("療養状況", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"介護状況"} colon={false}>
          {getFieldDecorator("kaigoJokyo", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={kaigoJokyoList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("介護状況", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"住居環境"} colon={false}>
          {getFieldDecorator("jukyoKankyou", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={jukyoKankyouList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("住居環境", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"生活歴"} colon={false}>
          {getFieldDecorator("seikatuReki", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={seikatuRekiList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("生活歴", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"既往歴"} colon={false}>
          {getFieldDecorator("kioreki", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={kiorekiList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("既往歴", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"職業歴"} colon={false}>
          {getFieldDecorator("shokuReki", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={shokuRekiList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("職業歴", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"趣味楽しみ特技"} colon={false}>
          {getFieldDecorator("syumiEtc", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={syumiEtcList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("趣味", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"友人・地域との関係"} colon={false}>
          {getFieldDecorator("taijinKankei", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={taijinKankeiList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("対人関係", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"本人主訴"} colon={false}>
          {getFieldDecorator("honninSyuso", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={honninSyusoList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("本人主訴", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"家族主訴"} colon={false}>
          {getFieldDecorator("kazokuSyuso", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={kazokuSyusoList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("家族主訴", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />

        <Form.Item label={"本人家族の意向"} colon={false}>
          {getFieldDecorator("honninKazokuIko", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={honninKazokuIkoList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("本人家族の意向", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"関係者要望"} colon={false}>
          {getFieldDecorator("kankeishaYobo", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={kankeishaYoboList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("関係者要望", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"公的サービス"} colon={false}>
          {getFieldDecorator("riyouServiceKouteki", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={riyouServiceKoutekiList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("公的サービス", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"非公的サービス"} colon={false}>
          {getFieldDecorator("riyouServiceHikouteki", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={4}
              dataSelect={riyouServiceHikoutekiList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("非公的サービス", naiyou)
              }
            />
          )}
        </Form.Item>
        <Divider />
      </Form>
    );
  }
}

export const FormFacesheet = Form.create({
  name: "input-riyou-facesheet",
  onValuesChange(props, changedValues, allValues) {
    // Format Date time
    const tmp = _.cloneDeep(allValues);
    if (tmp.date) {
      tmp.date = allValues.date.format(ConstSet.DATE_FORMAT);
    }

    props.saveOnchangeToState(tmp, "fFacesheet", "");
  },
  mapPropsToFields(props) {
    let dateTime = new Date();
    if (props.data && props.data.date) {
      dateTime = new Date(props.data.date);
    }

    return {
      date: Form.createFormField({
        value: dayjs(dateTime),
      }),
      comment: Form.createFormField({
        value: props.data ? props.data.comment : null,
      }),
      genkyou: Form.createFormField({
        value: props.data ? props.data.genkyou : null,
      }),
      honninKazokuIko: Form.createFormField({
        value: props.data ? props.data.honninKazokuIko : null,
      }),
      honninSyuso: Form.createFormField({
        value: props.data ? props.data.honninSyuso : null,
      }),
      jukyoKankyou: Form.createFormField({
        value: props.data ? props.data.jukyoKankyou : null,
      }),
      kaigoJokyo: Form.createFormField({
        value: props.data ? props.data.kaigoJokyo : null,
      }),
      kankeishaYobo: Form.createFormField({
        value: props.data ? props.data.kankeishaYobo : null,
      }),
      kazokuJoukyou: Form.createFormField({
        value: props.data ? props.data.kazokuJoukyou : null,
      }),
      kazokuSyuso: Form.createFormField({
        value: props.data ? props.data.kazokuSyuso : null,
      }),
      keizaiJoukyou: Form.createFormField({
        value: props.data ? props.data.keizaiJoukyou : null,
      }),
      kioreki: Form.createFormField({
        value: props.data ? props.data.kioreki : null,
      }),
      riyouServiceHikouteki: Form.createFormField({
        value: props.data ? props.data.riyouServiceHikouteki : null,
      }),
      riyouServiceKouteki: Form.createFormField({
        value: props.data ? props.data.riyouServiceKouteki : null,
      }),
      shintaiJoukyou: Form.createFormField({
        value: props.data ? props.data.shintaiJoukyou : null,
      }),
      shokuReki: Form.createFormField({
        value: props.data ? props.data.shokuReki : null,
      }),
      syumiEtc: Form.createFormField({
        value: props.data ? props.data.syumiEtc : null,
      }),
      taijinKankei: Form.createFormField({
        value: props.data ? props.data.taijinKankei : null,
      }),
      seikatuReki: Form.createFormField({
        value: props.data ? props.data.seikatuReki : null,
      }),
      ryoyoJokyo: Form.createFormField({
        value: props.data ? props.data.ryoyoJokyo : null,
      }),
      attn: Form.createFormField({
        value: props.data ? props.data.attn : null,
      }),
    };
  },
})(FormFacesheetNormal);
