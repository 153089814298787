export const ProgressActionTypes = {
  GET_ALL_PROGRESS_REQUEST: "GET_ALL_PROGRESS_REQUEST",
  GET_ALL_PROGRESS_SUCCESS: "GET_ALL_PROGRESS_SUCCESS",
  GET_ALL_PROGRESS_FAILURE: "GET_ALL_PROGRESS_FAILURE",

  GET_PROGRESS_REQUEST: "GET_PROGRESS_REQUEST",
  GET_PROGRESS_SUCCESS: "GET_PROGRESS_SUCCESS",
  GET_PROGRESS_FAILURE: "GET_PROGRESS_FAILURE",

  CREATE_PROGRESS_REQUEST: "CREATE_PROGRESS_REQUEST",
  CREATE_PROGRESS_SUCCESS: "CREATE_PROGRESS_SUCCESS",
  CREATE_PROGRESS_FAILURE: "CREATE_PROGRESS_FAILURE",

  UPDATE_PROGRESS_REQUEST: "UPDATE_PROGRESS_REQUEST",
  UPDATE_PROGRESS_SUCCESS: "UPDATE_PROGRESS_SUCCESS",
  UPDATE_PROGRESS_FAILURE: "UPDATE_PROGRESS_FAILURE",

  REMOVE_PROGRESS_REQUEST: "REMOVE_PROGRESS_REQUEST",
  REMOVE_PROGRESS_SUCCESS: "REMOVE_PROGRESS_SUCCESS",
  REMOVE_PROGRESS_FAILURE: "REMOVE_PROGRESS_FAILURE",
};
