import React from "react";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Radio } from "antd";

const RadioGroup = Radio.Group;

export default class HaisetufKB extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    this.props.handleHaisetuf(e.target.value);
  };

  render() {
    const { value, styleCus, disabled } = this.props;

    return (
      <div
        style={{ extAlign: styleCus ? styleCus : "center" }}
        className="modal-check"
      >
        <RadioGroup
          disabled={disabled ? disabled : false}
          onChange={this.onChange}
          value={value === 0 ? 0 : value}
          className="kdf-f-extent"
        >
          <Radio value={1}>
            <PlusOutlined />
          </Radio>
          <Radio value={0}>
            <MinusOutlined />
          </Radio>
        </RadioGroup>
      </div>
    );
  }
}
