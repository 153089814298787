import { AuthActionTypes } from "../action_types";

//
import { StorageUtils } from "../../utils";
import { ConfigName } from "../../../config-name";

function config(state = {}, action) {
  switch (action.type) {
    case AuthActionTypes.LIFE_GET_MENU: {
      return {
        ...state,
        menu: action.menu,
      };
    }
    case AuthActionTypes.LIFE_HANDLE_MENU: {
      const authlife = StorageUtils.getValue(ConfigName.SWLIFE_AUTH);
      const menuLIFE = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      StorageUtils.setValues({
        [ConfigName.SWLIFE_AUTH]: {
          ...authlife,
          menu: {
            ...authlife.menu,
            si: `${action.values[0]}`,
            sn: `${action.values[1]}`,
            sy: `${action.values[2]}`,
            scn: `${action.values[3]}`,
            ySid: `${action.values[4]}`,
            iSid: `${action.values[5]}`,
          },
        },
        [ConfigName.SWLIFE_MENU]: {
          ...menuLIFE,
          si: `${action.values[0]}`,
          sn: `${action.values[1]}`,
          sy: `${action.values[2]}`,
          scn: `${action.values[3]}`,
          ySid: `${action.values[4]}`,
          iSid: `${action.values[5]}`,
        },
      });
      return {
        ...state,
        values: action.values,
      };
    }
    default: {
      return { ...state };
    }
  }
}

export default config;
