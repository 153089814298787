import { SisetuKaigoNaiyouMasterApi } from "../../api/sisetuKaigoNaiyouMaster";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
import {
  CREATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR,
  CREATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS,
  LOAD_SISETU_KAIGO_NAIYOU_ERROR,
  LOAD_SISETU_KAIGO_NAIYOU_LIST_SUCCESS,
  LOAD_SISETU_KAIGO_NAIYOU_REQUEST,
  LOAD_SISETU_KAIGO_NAIYOU_SUCCESS,
  SAVE_SISETU_KAIGO_NAIYOU_SUCCESS,
  SISETU_KAIGO_NAIYOU_MASTER_INIT_SUCCESS,
  UPDATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR,
  UPDATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS
} from "./actionName";

export function getSisetuKaigoNaiyouMasterInit(callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SISETU_KAIGO_NAIYOU_REQUEST,
    });
    SisetuKaigoNaiyouMasterApi.loadDataInit().then((data) => {
      dispatch({
        type: SISETU_KAIGO_NAIYOU_MASTER_INIT_SUCCESS,
        payload: data,
      });
      return callback(data);
    });
  };
}

export function getSisetuKaigoNaiyouList(bunrui, keyword, page, kyotuf, bunrui2) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SISETU_KAIGO_NAIYOU_REQUEST,
    });
    SisetuKaigoNaiyouMasterApi.getKaigoNaiyouList(
      bunrui,
      keyword,
      page,
      kyotuf,
      bunrui2
    ).then(
      (data) =>
        dispatch({
          type: LOAD_SISETU_KAIGO_NAIYOU_SUCCESS,
          payload: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SISETU_KAIGO_NAIYOU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getSisetuKaigoNaiyouListAll(bunrui, keyword, bunrui2, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SISETU_KAIGO_NAIYOU_REQUEST,
    });
    SisetuKaigoNaiyouMasterApi.getKaigoNaiyouListAll(bunrui, keyword, bunrui2).then(
      (data) => {
        dispatch({
          type: LOAD_SISETU_KAIGO_NAIYOU_LIST_SUCCESS,
          payload: data,
        }), callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SISETU_KAIGO_NAIYOU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function saveSisetuKaigoNaiyouList(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SISETU_KAIGO_NAIYOU_REQUEST,
    });
    SisetuKaigoNaiyouMasterApi.saveKaigoNaiyouList(data).then(
      (data) => {
        dispatch({
          type: SAVE_SISETU_KAIGO_NAIYOU_SUCCESS,
          payload: data,
        });
        callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SISETU_KAIGO_NAIYOU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function createSisetuKaigoNaiyouCreator(body, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SISETU_KAIGO_NAIYOU_REQUEST,
    });
    SisetuKaigoNaiyouMasterApi.create(body).then(
      (data) => {
        dispatch({
          type: CREATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function updateSisetuKaigoNaiyouCreator(body, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SISETU_KAIGO_NAIYOU_REQUEST,
    });
    SisetuKaigoNaiyouMasterApi.update(body).then(
      (data) => {
        dispatch({
          type: UPDATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
