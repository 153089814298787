import { Select } from "antd";
import React from "react";
import { isKanji, toKatakana } from "wanakana";
import { getValueLocalstorage } from "../function_common/functionCommon";

const Option = Select.Option;

export default class SelectKB extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    // console.log(`selected ${value}`);

    this.props.handleTantoMasterKB(value);
  }
  render() {
    const { tantoMaster, value, widthCode, widthText, styleCus, disabled } =
      this.props;

    let defaulTantoCode = "";
    let defaulTantoName = "";

    if (tantoMaster && tantoMaster.length > 0) {
      tantoMaster.forEach((item) => {
        if (item.id.toString() === getValueLocalstorage("tid")) {
          defaulTantoCode = item.code;
          defaulTantoName = item.name;
        }
      });
    }

    return (
      <div style={{ textAlign: styleCus ? styleCus : "center" }}>
        <Select
          disabled={disabled ? disabled : false}
          style={{ width: widthCode }}
          optionFilterProp="children"
          onChange={this.handleChange}
          // onFocus={handleFocus}
          // onBlur={handleBlur}
          filterOption={(input, option) => {
            if (
              option.props.children &&
              option.props.children.indexOf(input) >= 0
            )
              return true;
          }}
          value={value && value.tantoId ? value.tantoId : defaulTantoCode}
          showSearch={true}
        >
          {tantoMaster
            ? tantoMaster.map((item, index) => {
              return (
                <Option key={index} value={item.id}>
                  {item.code}
                </Option>
              );
            })
            : null}
        </Select>
        <Select
          disabled={disabled ? disabled : false}
          showSearch
          style={{ width: widthText }}
          // placeholder="Select a person"
          optionFilterProp="children"
          onChange={this.handleChange}
          // onFocus={handleFocus}
          // onBlur={handleBlur}
          filterOption={(input, option) => {
            if (isKanji(input)) {
              // kanji input
              if (
                option.props.children[0] &&
                option.props.children[0].indexOf(input) >= 0
              )
                return true;
            }
            else {
              // hiragana or katakana input
              // eslint-disable-next-line no-lonely-if
              if (
                option.props.children[1].props.children &&
                option.props.children[1].props.children.startsWith(
                  toKatakana(input)
                )
              )
                return true;
            }
          }}
          value={value && value.tantoId ? value.tantoId : defaulTantoName}
        // filterOption={true}
        // showSearch={true}
        >
          {tantoMaster
            ? tantoMaster.map((item, index) => {
              return (
                <Option key={index} value={item.id}>
                  {item.name}
                  <span style={{ display: "none" }}>{item.furigana}</span>
                </Option>
              );
            })
            : null}
        </Select>
      </div>
    );
  }
}
