/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleTwoTone,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import MediaQuery from "react-responsive";
import TimeKBCus from "../../../common/component/TimeKBCus";
import { ConstSet } from "../../../common/configs/constset";
import NyuyokuForm from "../../../common/form-kiroku/NyuyokuForm";
import {
  checkTableField,
  exRiyousya,
  getDateInMonth,
  getMenu,
  getValueLocalstorage,
} from "../../../common/function_common/functionCommon";
import {
  checkIkkatsuOkBtn,
  ikkatsuCheckboxReset,
} from "../../../common/function_common/functionDisplay";
import IkkatsuModal from "../../../common/modals/ikkatsuModal";
import CheckKB from "../../../common/model-kiroku/CheckKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import InputSelectKB from "../../../common/model-kiroku/InputSelectKB";
import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import TantoMasterKB2 from "../../../common/model-kiroku/TantoMasterKB2";
import TimeKB from "../../../common/model-kiroku/TimeKB";
import TableRowButton from "./TableRowButton";

const expandedRowRender = (record) => (
  <p style={{ marginBottom: "0em" }}>{record.description}</p>
);
const showHeader = true;
const pagination = { position: "bottom" };
const FormItem = Form.Item;

export default class Nyuyoku extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Table Setting
      bordered: true,
      loading: false,
      pagination,
      size: "small",
      expandedRowRender,
      title: undefined,
      showHeader,
      footer: undefined,
      scroll: undefined,
      hasData: true,
      expandIconAsCell: false,
      expandIconColumnIndex: -1,
      rowActive: null,

      expandRowClick: [],
      columns: [],
      activeListIndex: null,
      activeRowKey: null,
      shubetsuTitle: "",

      // edit add function
      inputMode: this.props.inputMode,
      showDrawer: false,
      visibleMInput: false,
      modalType: null,

      riyousyaName: null,
      riyousyaId: null,
      date: null,
      time: null,
      record: null,
      indexRecord: 0,

      inputType: null,
      inputField: null,
      inputValue: null,

      field: "",
      isZendkaiDis: false,
      suggestValues: "",

      count: 0,
      rowsSelect: 0,
      visibleMulState: false,
      mulInputData: {
        nyuyokuIds: null,
        time: null,
        nyuyoku_naiyou: null,
        comment: null,
        tantoId: null,
      },
      mulInputCheckbox: [false, false, false, false],
      mulInputNotification: "",
      arrCheckBox: [], // array index checkbox for multiple add and edit,
      isClickAll: false,
      delIds: [],
      isConfirmMulDel: false,

      formData: null,
      isFormUpdate: false,

      // handle for active OK SAVE Button when user start input value
      okSaveButton: true,

      // this setting is used in multiple input editing
      isShunrui: false,

      tableCurrentPage: 1, // current page of table
      innerHeightTable: window.innerWidth < 1280 ? window.innerHeight - 420 : window.innerHeight - 350,
    };

    // Copy, Del local, Del Server record
    this.handleCopyNyuyoku = this.handleCopyNyuyoku.bind(this);
    this.handleDelNyuyoku = this.handleDelNyuyoku.bind(this);
    this.handleServerDel = this.handleServerDel.bind(this);

    /**
     * Add, Edit by Modal
     */
    this.handleMInput = this.handleMInput.bind(this);
    this.handleMInputCancel = this.handleMInputCancel.bind(this);

    /**
     * Add, Edit by Modal
     */
    this.preInput = this.preInput.bind(this);
    this.upperInput = this.upperInput.bind(this);
    this.lowerInput = this.lowerInput.bind(this);
    this.nextInput = this.nextInput.bind(this);

    this.getHandleHourInput = this.getHandleHourInput.bind(this);
    this.getHandleMinuteLeftInput = this.getHandleMinuteLeftInput.bind(this);
    this.getHandleMinuteInput = this.getHandleMinuteInput.bind(this);
    this.getHandleReportCaseTyuif = this.getHandleReportCaseTyuif.bind(this);

    this.getHandleNaiyou = this.getHandleNaiyou.bind(this);

    this.getHandleInputComment = this.getHandleInputComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);

    this.handleOkUpdate = this.handleOkUpdate.bind(this);
    // END

    // add, edit Setting Comment
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    // 一括入力
    this.onChangeTimeMulMode = this.onChangeTimeMulMode.bind(this);
    this.getHandleMulNyuyokuNaiyou = this.getHandleMulNyuyokuNaiyou.bind(this);
    this.getHandleMulInputComment = this.getHandleMulInputComment.bind(this);
    this.getHandleMulTantoMasterKB = this.getHandleMulTantoMasterKB.bind(this);

    this.getHandleOnChangeMIC = this.getHandleOnChangeMIC.bind(this); // Active Clickable 一括入力 button
    this.handleMulInputModeOk = this.handleMulInputModeOk.bind(this);
    this.handleMulInputModeCancel = this.handleMulInputModeCancel.bind(this);
  }

  /**
   *
   * @param {*} oldProps
   */
  componentDidUpdate(preProps) {
    const { count } = this.state;
    const { nyutaisho, mulInputAllMode, syokichi } = this.props;

    //
    if (nyutaisho !== preProps.nyutaisho) {
      // case update nyuyokuId when insert value to DOM
      if (this.state.record) {
        this.setState({
          record: nyutaisho[this.state.record.index],
        });
      }

      // alert(count)
      // case multiple input
      if (count > 0) {
        this.ikkatsu();
      }
    }

    // update value if syokichi select
    if (syokichi !== preProps.syokichi && this.state.record) {
      const { showDrawer } = this.state;

      if (showDrawer) {
        if (syokichi.length > 0) {
          this.getHandleFormInput(syokichi[0].syokichi, "nyuyokuNaiyou");
        }
      }
      else {
        this.setState({
          inputValue:
            syokichi.length > 0 ? syokichi[0].syokichi : this.state.inputValue,
        });
      }
    }

    // reset multiple input mode
    if (mulInputAllMode !== preProps.mulInputAllMode) {
      this.setState({
        count: 0,
        rowsSelect: 0,
        visibleMulState: false,
        mulInputData: {
          nyuyokuIds: null,
          time: null,
          nyuyoku_naiyou: null,
          comment: null,
          tantoId: null,
        },
        mulInputCheckbox: [false, false, false, false],
        mulInputNotification: "",
        arrCheckBox: [], // array index checkbox for multiple add and edit,
        isClickAll: false,
        isShunrui: false,
        expandRowClick: [], // reset expand row on multiple input mode
        rowActive: null,
        activeRowKey: null,
        delIds: [],
        isConfirmMulDel: false,
      });

      this.props.handleIkkatsuBtn(true); // active popup click button
    }
  }

  /**
   *
   * @param {*} key
   * @param {*} index
   * @param {*} event
   */
  onRowClick(key) {
    // Setting active row key
    this.setState({
      rowActive: key,
    });

    /**
     * disable expander row on ikkaketsu mode
     */
    const { mulInputMode } = this.props;
    if (mulInputMode) {
      return;
    }

    const keys = [];
    if (key) {
      keys.push(key); // I have set my record.id as row key. Check the documentation for more details.
    }

    if (key === this.state.activeRowKey) {
      this.setState({
        expandRowClick: [],
        activeRowKey: null,
      });
    }
    else {
      this.setState({
        expandRowClick: [key],
        activeRowKey: key,
      });
    }
  }

  /**
   *
   * @param {*} nyuyokuRecord
   */
  handleCopyNyuyoku(record) {
    // Clone data
    const recordCopy = {
      // basic
      riyousyaId: record.riyousyaId,
      nyuType: record.nyuType,
      date: record.date,

      // common
      time: record.time,
      bunrui: record.bunrui,

      // detail
      kubun: record.kubun,

      // tab info. that have been describe in requirement
      reportCaseTyuif: record.reportCaseTyuif,

      // commone flag field on local
      index: record.index,
      modeRecord: "copy",
    };

    this.props.handleCopyNyuyoku(recordCopy);
  }

  /**
   *
   * @param {*} nyuyokuRecord
   */
  handleDelNyuyoku(nyuyokuRecord) {
    this.props.handleDelNyuyoku(nyuyokuRecord);
  }

  /**
   * Delete record from server
   * @param {*} nyuyokuId
   */
  handleServerDel(nyuyokuId) {
    this.props.deleteRecord(nyuyokuId);
  }

  /**
   * Edit, Update function with single field
   *
   */

  /**
   * function handle when click to edit field
   *
   * @param {int} MType // display modal type corresponding
   * @param {*} report // record for row
   * @param {*} index // index of row
   */
  handleMInput(MType, record, index) {
    const extractCurrent = checkTableField(MType, record);

    let okSaveButton = true;
    if (
      MType === ConstSet.MODAL_N_COMMENT ||
      MType === ConstSet.MODAL_N_NYUYOKUNAIYOU
    ) {
      okSaveButton = false;
    }

    // Convert Type to Parameter
    const riyousyaInfo = exRiyousya(record.riyousyaId);

    this.setState({
      rowActive: index,
      expandRowClick: [],

      visibleMInput: true,
      modalType: MType,

      riyousyaName: riyousyaInfo.riyousyaName,
      riyousyaId: riyousyaInfo.riyousyaId,
      date: record.date,
      time: record.time ? record.time : "00:00",
      record,
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  }

  /**
   * (2)
   */
  handleMInputCancel() {
    this.setState({
      visibleMInput: false,
    });
  }

  /**
   * (3.1) Pre field <--
   */
  preInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    let modalType = this.state.modalType;

    if (
      modalType > ConstSet.MODAL_N_TIME &&
      modalType <= ConstSet.MODAL_N_TANTOMASTER
    ) {
      modalType = modalType - 1;
    }

    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_N_COMMENT ||
      modalType === ConstSet.MODAL_N_NYUYOKUNAIYOU
    ) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    this.setState({
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.2) Pre field ^|
   */
  upperInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_N_COMMENT ||
      modalType === ConstSet.MODAL_N_NYUYOKUNAIYOU
    ) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      rowActive: index,

      riyousyaName: nyutaisho[index].riyousyaName,
      riyousyaId: nyutaisho[index].riyousyaId,
      date: nyutaisho[index].date,
      time: nyutaisho[index].time ? nyutaisho[index].time : "00:00",
      record: nyutaisho[index],
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.3) Pre field _|
   */
  lowerInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_N_COMMENT ||
      modalType === ConstSet.MODAL_N_NYUYOKUNAIYOU
    ) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      riyousyaName: nyutaisho[index].riyousyaName,
      riyousyaId: nyutaisho[index].riyousyaId,
      date: nyutaisho[index].date,
      time: nyutaisho[index].time ? nyutaisho[index].time : "00:00",
      record: nyutaisho[index],
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.4) Pre field -->
   */
  nextInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    let modalType = this.state.modalType;

    if (
      modalType >= ConstSet.MODAL_N_TIME &&
      modalType < ConstSet.MODAL_N_TANTOMASTER
    ) {
      modalType = modalType + 1;
    }

    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_N_COMMENT ||
      modalType === ConstSet.MODAL_N_NYUYOKUNAIYOU
    ) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    this.setState({
      //
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * 時間ところ
   */

  handleSetCurrentTime = () => {
    const currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.setState({
      inputType: ConstSet.MODAL_N_TIME,
      inputValue: currentTime,
      inputField: "time",
      okSaveButton: false,
    });
  };

  /**
   * (1)
   * @param {(*)} value
   */
  getHandleHourInput(value) {
    let preData = this.state.inputValue;
    if (!preData) {
      preData = value + ":00";
    }
    else {
      preData = value + preData.substring(2, 5);
    }
    this.setState({
      inputType: ConstSet.MODAL_N_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (2)
   * @param {(*)} value
   */
  getHandleMinuteLeftInput(value) {
    let preData = this.state.inputValue;

    if (!preData) {
      preData = "00:" + value;
    }
    else {
      preData = preData.substring(0, 3) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_N_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (3)
   * @param {(*)} value
   */
  getHandleMinuteInput(value) {
    let preData = this.state.inputValue;
    if (!preData) {
      preData = "00:0" + value;
    }
    else {
      preData = preData.substring(0, 4) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_N_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }
  // 時間end

  /**
   * 申送
   * @param {*} value
   */
  getHandleReportCaseTyuif(value) {
    this.setState({
      inputType: ConstSet.MODAL_N_REPORTCASETYUIF,
      inputValue: value ? "" + 1 : "" + 0,
      inputField: "reportCaseTyuif",
      okSaveButton: false,
    });
  }

  /**
   * 申送
   * @param {*} value
   */
  getHandleNaiyou(value) {
    this.setState({
      inputType: ConstSet.MODAL_N_NYUYOKUNAIYOU,
      inputValue: value,
      inputField: "nyuyoku_naiyou",
      okSaveButton: false,
    });
  }

  /**
   * コメント
   * @param {*} value
   * @param {*} type
   */
  getHandleInputComment(value, type) {
    if (type && type === "select") {
      this.setState({
        inputType: ConstSet.MODAL_N_COMMENT,
        inputValue: this.state.inputValue
          ? this.state.inputValue + "、" + value
          : value,
        inputField: "comment",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_N_COMMENT,
        inputValue: value,
        inputField: "comment",
        okSaveButton: false,
      });
    }
  }

  /**
   * 担当者
   * @param {*} value
   */
  getHandleTantoMasterKB(value) {
    // let updateCurrentRecord = this.state.record;
    // updateCurrentRecord.tantoId = value;
    this.setState({
      inputType: ConstSet.MODAL_N_TANTOMASTER,
      inputValue: value,
      inputField: "tantoId",
      // record: updateCurrentRecord,
      okSaveButton: false,
    });
  }

  /**
   * OK UPDATE Button Click
   *
   * Save data
   */
  handleOkUpdate(type) {
    const checkNyuyokuId = this.state.record.nyuyokuId
      ? this.state.record.nyuyokuId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkNyuyokuId && !checkCopyType) {
      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        nyuyokuIds: checkNyuyokuId.toString(),
        index: this.state.record.index,
        date: this.state.record.date,
      };

      const updateField = this.state.inputValue;

      if (this.state.inputField && this.state.inputType) {
        if (this.state.inputType === ConstSet.MODAL_N_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        this.props.updateRecord(params);

        let okSaveButton = true;
        if (
          this.state.inputType === ConstSet.MODAL_N_COMMENT ||
          this.state.inputType === ConstSet.MODAL_N_NYUYOKUNAIYOU
        ) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputField: null,
          inputValue: updateField,
          okSaveButton,
        });
      }
    } // POST
    else {
      // setting time auto if not input
      const preTime = "00:00";

      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: this.state.record.index,
        riyousyaId: this.state.record.riyousyaId.toString(),
        date: this.state.record.date,
        time: this.state.record.time ? this.state.record.time : preTime,
        tantoId: getValueLocalstorage("tid"),
        kubunNyuyoku: this.state.record.kubunNyuyoku,
      };

      const updateField = this.state.inputValue;

      if (
        this.state.inputField &&
        this.state.inputValue &&
        this.state.inputType
      ) {
        if (this.state.inputType === ConstSet.MODAL_N_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        this.props.insertRecord(params);

        let okSaveButton = true;
        if (
          this.state.inputType === ConstSet.MODAL_N_COMMENT ||
          this.state.inputType === ConstSet.MODAL_N_NYUYOKUNAIYOU
        ) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputField: null,
          inputValue: updateField,
          okSaveButton,
        });
      }
    }

    // close popup
    if (type !== true) {
      // save from next, pre, upper, lower not colse popup
      this.setState({
        visibleMInput: false,
      });
    }
  }
  // end Edit, Update function

  /**
   * Handle Edit, Update By Input From from right all Screen
   * ALl fields can be save on this Drawer
   *
   */

  /**
   * Form Inside Drawer
   * Show Form
   */
  showDrawer = (record) => {
    this.setState({
      rowActive: record.index,
      showDrawer: true,
      record,
      isFormUpdate: false,
    });
  };

  /**
   * Close Form
   */
  closeDrawer = () => {
    this.setState({
      showDrawer: false,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  upperForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  lowerForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   * Update file to State, Prepare data to save when click on 更新 button
   * @param {*} value
   * @param {*} type
   */
  getHandleFormInput = (value, type) => {
    const prepareData = this.state.record;
    const objData = {};
    objData[type] = value;

    this.setState({
      record: Object.assign({}, prepareData, objData),
      isFormUpdate: true,
    });
  };

  /**
   * Save data action
   */
  handleOkUpdateForm = () => {
    const checkNyuyokuId = this.state.record.nyuyokuId
      ? this.state.record.nyuyokuId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkNyuyokuId && !checkCopyType) {
      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        nyuyokuIds: checkNyuyokuId.toString(),
        index: this.state.record.index,
        date: this.state.record.date,
      };

      params = Object.assign(params, this.state.record);

      params.nyuyoku_naiyou = params.nyuyokuNaiyou;
      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.updateRecord(params);
    } // POST
    else {
      const preTime = "00:00";

      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: this.state.record.index,
        riyousyaId: this.state.record.riyousyaId,
        date: this.state.record.date,
        time: this.state.record.time ? this.state.record.time : preTime,
        tantoId: getValueLocalstorage("tid"),
      };

      params = Object.assign(params, this.state.record);

      params.nyuyoku_naiyou = params.nyuyokuNaiyou;
      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.insertRecord(params);
    }

    // clear inputing mode
    this.setState({
      isFormUpdate: false,
    });
  };
  // END Handle Edit, Update By Input Form from right all Screen

  /**
   * Handle Add, Edit Setting Comment
   */
  /**
   * move to input state of table
   * @param {*} index
   */
  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  /**
   * Cancel Save
   * @param {*} index
   */
  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  /**
   * Save function
   * @param {*} naiyou
   */
  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }
  // END Handle Add, Edit Setting Comment

  /**
   * Handle 一括入力
   * 8 functions
   * handle checkbox: time, stapleIntake, sideIntake, shokuNasi, comment, tantoMaster
   * handle change time, stapleIntake, sideIntake, shokuNasi, comment, tantoMaster
   * handle OK to save data
   * handle CANCEL to cancel input mode
   *
   * handle autoCheck when input comment field. Performanceので、onChange has changed by onBlur
   *
   * handle function when user select 利用者
   *
   */
  onClickSelectAll = () => {
    const { arrCheckBox, tableCurrentPage, isClickAll } = this.state;
    const { nyutaisho } = this.props;

    const preArrCheckBox = arrCheckBox;
    let rowsSelect = 0; // count total row selected

    const delIds = [];
    for (let i = 0; i < nyutaisho.length; i++) {
      if (!isClickAll) {
        if (
          i < ConstSet.TABLE_PAGINATION * tableCurrentPage &&
          i >= ConstSet.TABLE_PAGINATION * (tableCurrentPage - 1)
        ) {
          preArrCheckBox[i] = true;

          // handle for del mode, list ids has push into array
          if (nyutaisho[i].nyuyokuId) {
            delIds[i] = nyutaisho[i].nyuyokuId;
          }
          else {
            delIds[i] = 0;
            // preArrCheckBox[i] = false;
          }

          rowsSelect++;
        }
        else {
          preArrCheckBox[i] = false;
        }
      }
      else {
        preArrCheckBox[i] = false;

        // reset delIds from state
        delIds[i] = 0;
      }
    }

    // preMulInputData.mealIds = mealIds;

    if (!isClickAll) {
      this.props.handleIkkatsuBtn(false);
    }
    else {
      this.props.handleIkkatsuBtn(true);
    }

    this.setState({
      arrCheckBox: preArrCheckBox,
      isClickAll: !isClickAll,
      rowsSelect,
      delIds,
    });

    // handle logic
  };

  onClickSelectOne = (recordIndex) => {
    const { arrCheckBox, delIds } = this.state;
    const { nyutaisho } = this.props;

    //
    const preArrCheckBox = arrCheckBox;
    preArrCheckBox[recordIndex] = !arrCheckBox[recordIndex];

    // Check is selected, id have been retrieve from state
    const preDelIds = delIds;
    if (preArrCheckBox[recordIndex]) {
      if (
        nyutaisho &&
        nyutaisho[recordIndex] &&
        nyutaisho[recordIndex].nyuyokuId
      ) {
        preDelIds[recordIndex] = nyutaisho[recordIndex].nyuyokuId;
      }
    }
    else {
      preDelIds[recordIndex] = 0;
    }

    this.setState({
      arrCheckBox: preArrCheckBox,
      delIds: preDelIds,
    });

    // display 変更 button
    let mulBtnState = false;

    // count select row
    let rowsSelect = 0;

    preArrCheckBox.forEach((item) => {
      if (item) {
        rowsSelect++;
        mulBtnState = true;
      }
    });

    this.setState({
      rowsSelect,
    });

    if (mulBtnState) {
      this.props.handleIkkatsuBtn(false);
    }
    else {
      this.props.handleIkkatsuBtn(true);
    }
  };

  /**
   *
   * @param {*} e
   * @param {*} type
   * @param {*} key
   */
  handleCheckBox = (type, key, e) => {
    const { mulInputData, mulInputCheckbox } = this.state;

    //
    const preDataCheckbox = mulInputCheckbox;
    preDataCheckbox[type] = e.target.checked;

    //
    const preData = ikkatsuCheckboxReset(mulInputData, key);

    this.setState({
      mulInputCheckbox: preDataCheckbox,
      mulInputData: preData,
    });
  };

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulMode(time, timeString) {
    // save data to state
    const preData = this.state.mulInputData;
    preData.time = timeString;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[0] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulModeNew = (timeString) => {
    // save data to state
    const preData = this.state.mulInputData;
    preData.time = timeString;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[0] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  };

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulNyuyokuNaiyou(value) {
    const preData = this.state.mulInputData;
    preData.nyuyoku_naiyou = value;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[1] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulInputComment(value, type) {
    const preData = this.state.mulInputData;
    if (type && type === "select") {
      preData.comment = preData.comment
        ? preData.comment + "、" + value
        : value;
    }
    else {
      preData.comment = value;
    }

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[2] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {int} value ID of tantoMaster
   */
  getHandleMulTantoMasterKB(value) {
    const preData = this.state.mulInputData;
    preData.tantoId = value;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[3] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   */
  getHandleOnChangeMIC = () => {
    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[2] = true;

    this.setState({
      mulInputCheckbox: preDataCheckbox,
    });
  };

  /**
   *
   */
  handleMulInputModeOk() {
    //
    this.ikkatsu();

    // close popup
    this.props.handleMulInputModeCancel();

    // open modal
    this.setState({
      visibleMulState: true,
      isConfirmMulDel: false,
    });
  }

  /**
   *
   */
  handleMulInputModeCancel() {
    this.props.handleMulInputModeCancel();

    // reset
    const reset = {
      nyuyokuIds: null,
      time: null,
      nyuyoku_naiyou: null,
      comment: null,
      tantoId: null,
    };

    this.setState({
      mulInputData: reset,
      mulInputCheckbox: [false, false, false, false],
      isConfirmMulDel: false,
    });
  }

  /**
   *
   */
  ikkatsu = () => {
    // prepare data
    const { arrCheckBox, mulInputData, mulInputCheckbox, isShunrui, delIds } =
      this.state;
    const { nyutaisho, mulInputMode, mulDelMode } = this.props;

    if (mulDelMode) {
      // Create count rows that is modify, and index
      let count = 0;
      delIds.forEach((item) => {
        if (item) {
          count++;
        }
      });

      // save the first row
      const preArrCheckBox = arrCheckBox;
      for (let i = 0; i < delIds.length; i++) {
        if (delIds[i]) {
          this.props.deleteRecord(delIds[i]);
          count--;

          const preDelIds = delIds;
          preDelIds[i] = 0;
          preArrCheckBox[i] = false;
          this.setState({
            count,
            delIds: preDelIds,
            arrCheckBox: preArrCheckBox,
          });
          break;
        }
      }

      // Check if only oneRow have been selected -> reset file
      if (count === 0) {
        // reset
        const reset = {
          nyuyokuIds: null,
          time: null,
          nyuyoku_naiyou: null,
          comment: null,
          tantoId: null,
        };

        this.setState({
          mulInputData: reset,
          mulInputCheckbox: [false, false, false, false],
          arrCheckBox: [],
          delIds: [],
        });

        this.props.handleIkkatsuBtn(true);
      }
    }
    else if (mulInputMode) {
      const preData = {};

      // time
      if (!mulInputCheckbox[0]) {
        preData.time = null;
      }
      else {
        preData.time = mulInputData.time;
      }

      // nyuyoku_naiyou
      if (!mulInputCheckbox[1]) {
        preData.nyuyoku_naiyou = null;
      }
      else {
        preData.nyuyoku_naiyou = mulInputData.nyuyoku_naiyou;
      }

      // isShunrui
      if (mulInputCheckbox[1] && !preData.nyuyoku_naiyou && isShunrui) {
        preData.isShunrui = "1";
      }

      // comment
      if (!mulInputCheckbox[2]) {
        preData.comment = null;
      }
      else if (mulInputCheckbox[2] && !mulInputData.comment) {
        preData.comment = "";
      }
      else {
        preData.comment = mulInputData.comment;
      }

      // tantoId
      if (!mulInputCheckbox[3]) {
        preData.tantoId = null;
      }
      else {
        preData.tantoId = mulInputData.tantoId
          ? mulInputData.tantoId
          : getValueLocalstorage("tid");
      }

      // input nothing
      // if (!preData.time
      //     && !preData.nyuyoku_naiyou
      //     && !preData.isShunrui
      //     && !mulInputCheckbox[2]
      //     && !preData.tantoId) {
      //     return;
      // }

      preData.tantoId = preData.tantoId ? "" + preData.tantoId : null;

      // Create count rows that is modify, and index
      let count = 0;
      arrCheckBox.forEach((item) => {
        if (item) {
          count++;
        }
      });

      // save the first row
      for (let i = 0; i < arrCheckBox.length; i++) {
        if (arrCheckBox[i]) {
          // Check if recordId exist -> call update function
          if (nyutaisho && nyutaisho[i].nyuyokuId) {
            this.props.updateRecordMul(
              Object.assign(
                { nyuyokuIds: "" + nyutaisho[i].nyuyokuId },
                preData
              )
            );
            count--;
            const preArrCheckBox = arrCheckBox;
            preArrCheckBox[i] = false;
            this.setState({
              count,
              arrCheckBox: preArrCheckBox,
            });

            break;
          }
          else if (nyutaisho && !nyutaisho[i].nyuyokuId) {
            // recordId = null -> call crate new function
            // 0. systemControlName
            preData.systemControlName = JSON.parse(localStorage.getItem("menu")).sn;
            preData.systemControlId = JSON.parse(localStorage.getItem("menu")).si;

            // 1. index
            preData.index = nyutaisho[i].index;

            // 2. RiyousyaId
            preData.riyousyaId = "" + nyutaisho[i].riyousyaId;

            // 3. date
            preData.date = nyutaisho[i].date;

            // 4. time
            if (!preData.time) {
              if (nyutaisho[i].time) {
                preData.time = nyutaisho[i].time;
              }
              else {
                preData.time = "00:00";
              }
            }

            // 7. tantoId
            if (!preData.tantoId) {
              preData.tantoId = getValueLocalstorage("tid");
            }

            this.props.insertRecordMul(Object.assign({}, preData));

            count--;
            const preArrCheckBox = arrCheckBox;
            preArrCheckBox[i] = false;
            this.setState({
              count,
              arrCheckBox: preArrCheckBox,
            });
            break;
          }
          else {
            break;
          }
        }
      }

      // Check if only oneRow have been selected -> reset file
      if (count === 0) {
        // reset
        const reset = {
          nyuyokuIds: null,
          time: null,
          nyuyoku_naiyou: null,
          comment: null,
          tantoId: null,
        };

        this.setState({
          mulInputData: reset,
          mulInputCheckbox: [false, false, false, false],
          delIds: [],
        });

        this.props.handleIkkatsuBtn(true);
      }
    }
  };
  // End Handle 一括入力

  /**
   *
   * get syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   */
  syokichiItem = (type) => {
    const { record } = this.state;
    const riyousyaId = record.riyousyaId.toString();
    const kubun = ConstSet.NYUYOKU_SYOKICHI_NAIYOU;
    this.props.syokichiItem(riyousyaId, kubun);

    // update field to save value
    if (!type) {
      // case popup
      this.setState({
        inputField: "nyuyoku_naiyou",
        inputType: ConstSet.MODAL_N_NYUYOKUNAIYOU,
      });
    }
  };

  /**
   * update syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   * @param {*} syokichi
   */
  syokichiUpdate = (type) => {
    const { record, inputValue } = this.state;
    const riyousyaId = record.riyousyaId.toString();
    const kubun = ConstSet.NYUYOKU_SYOKICHI_NAIYOU;
    let syokichi = "";

    if (type) {
      syokichi = record.nyuyokuNaiyou;
    }
    else {
      syokichi = inputValue;
    }

    this.props.syokichiUpdate(riyousyaId, kubun, syokichi);
  };

  /**
   * handle click cell event
   *
   * in mulInputMode, click do nothing
   * in input mode, base info cell click -> open expander data, edting cell -> display modal
   * in nothing mode -> open expander data
   *
   */
  onClickCellEvent = (recordIndex, type) => {
    const { nyutaisho, inputMode, mulInputMode } = this.props;
    const record = nyutaisho[recordIndex];

    switch (type) {
      case ConstSet.COL_SELECT:
        return;

      case ConstSet.COL_COPY:
        return;

      case ConstSet.COL_MODE:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_NAME:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ADDRESS:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ROOM:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_NYUYOKU_TIME:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_N_TIME,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_NYUYOKU_REPORTCASETYUIF:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_N_REPORTCASETYUIF,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_NYUYOKU_NYUYOKUNAIYOU:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_N_NYUYOKUNAIYOU,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_NYUYOKU_COMMENT:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_N_COMMENT,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_NYUYOKU_TANTOMASTER:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_N_TANTOMASTER,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      default:
        return;
    }
  };

  handChangePagination = (pagination) => {
    // reset 一括入力 mode
    const { arrCheckBox } = this.state;
    const preArrCheckBox = [];
    for (let i = 0; i < arrCheckBox.length; i++) {
      preArrCheckBox.push(0);
    }

    this.setState({
      tableCurrentPage: pagination.current,
      arrCheckBox: preArrCheckBox,
      isClickAll: false,
    });
  };

  closeModalMulState = () => {
    this.setState({
      visibleMulState: false,
      isConfirmMulDel: false,
    });
  };

  onChangeConfirmMulDel = (e) => {
    this.setState({
      isConfirmMulDel: e.target.checked,
    });
  };

  // set default value for users
  mulSetDefaultValue = () => {
    // change state
    const { isShunrui, mulInputCheckbox, mulInputData } = this.state;

    const preMulInputCheckbox = mulInputCheckbox;

    // ON -> OFF
    if (isShunrui) {
      // shunrui manual is active
      preMulInputCheckbox[1] = false;

      this.setState({
        isShunrui: !isShunrui,
        mulInputCheckbox: preMulInputCheckbox,
      });
    }
    else {
      // OFF - ON
      // reset shunrui input value
      const preMulInputData = mulInputData;
      preMulInputCheckbox[1] = true;
      preMulInputData.nyuyoku_naiyou = null;

      this.setState({
        isShunrui: !isShunrui,
        mulInputCheckbox: preMulInputCheckbox,
        mulInputData: preMulInputData,
      });
    }
  };

  render() {
    const state = this.state;
    const {
      nyutaisho,
      loading,
      inputMode,
      mulInputAllMode,
      mulInputMode,
      mulInputModal,
      mulDelMode,
      tantoMaster,
      yougoMasterNa,
      yougoMasterSy,
      isDisLongText,
    } = this.props;

    /**
     * handle date
     */
    const data = [];

    // colums情報を設定
    const columnsLong = [];
    if (mulInputAllMode) {
      columnsLong.push({
        title: "全",
        dataIndex: "colSelect",
        key: "colSelect",
        width: 50,
        fixed: "left",
        className: "input-mode-mul",
        onHeaderCell: () => {
          return {
            onClick: () => {
              this.onClickSelectAll();
            }, // click row
          };
        },
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickSelectOne(record.key);
            }, // click row
          };
        },
      });
    }
    else if (inputMode) {
      columnsLong.push({
        title: "追加",
        dataIndex: "colCopy",
        key: "colCopy",
        width: 50,
        fixed: "left",
        className: "input-mode-fix action-col",
      });
    }
    else {
      columnsLong.push({
        title: "状況",
        dataIndex: "col1",
        key: "col1",
        width: 50,
        fixed: "left",
        className: `joutaiCol ${inputMode || mulInputAllMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      });
    }

    columnsLong.push(
      {
        title: "日",
        dataIndex: "col2",
        key: "col2",
        width: 70,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "曜",
        dataIndex: "col3",
        key: "col3",
        width: 70,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "時間",
        dataIndex: "col5",
        key: "col5",
        width: 70,
        className: `timeCol ${inputMode || mulInputAllMode
          ? " input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_NYUYOKU_TIME);
            }, // click row
          };
        },
      },
      {
        title: "申送",
        dataIndex: "col6",
        key: "col6",
        width: 50,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(
                record.key,
                ConstSet.COL_NYUYOKU_REPORTCASETYUIF
              );
            }, // click row
          };
        },
      },
      {
        title: "種類",
        dataIndex: "col7",
        key: "col7",
        width: 100,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(
                record.key,
                ConstSet.COL_NYUYOKU_NYUYOKUNAIYOU
              );
            }, // click row
          };
        },
      },
      {
        title: "コメント",
        dataIndex: "col8",
        key: "col8",
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_NYUYOKU_COMMENT);
            }, // click row
          };
        },
      },
      {
        title: "担当者",
        dataIndex: "col9",
        key: "col9",
        width: 120,
        className: `${inputMode || mulInputAllMode ? "input-mode" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(
                record.key,
                ConstSet.COL_NYUYOKU_TANTOMASTER
              );
            }, // click row
          };
        },
      }
    );

    if (inputMode) {
      columnsLong.push({
        title: "削除",
        dataIndex: "colDel",
        key: "colDel",
        width: 50,
        // fixed: 'right',
        className: "input-mode-fix action-col",
      });
    }

    // テーブルデータの準備
    if (nyutaisho) {
      for (let i = 0; i < nyutaisho.length; i++) {
        let color = "";
        if (nyutaisho[i].nyuType != "空") {
          if (nyutaisho[i].kubun === "入所") {
            color = "nuytaisho-yellow";
          }
          else if (nyutaisho[i].kubun === "ショート") {
            color = "nuytaisho-blue";
          }
        }

        let planType = "";
        if (nyutaisho[i].time && nyutaisho[i].planType === true) {
          planType = "bg_green";
        }
        else if (nyutaisho[i].time) {
          planType = "bg_pink";
        }

        data.push({
          key: i,
          colSelect: <Checkbox checked={this.state.arrCheckBox[i]} />,
          colCopy: (
            <span className={"action-kiroku"}>
              <Button
                type={"primary"}
                ghost
                onClick={this.handleCopyNyuyoku.bind(this, nyutaisho[i])}
              >
                <PlusOutlined />
              </Button>
            </span>
          ),
          col1: (
            <div className={`ntk-riyousya-status ${color} icon-table`}>
              {nyutaisho[i].nyuType ? (
                nyutaisho[i].nyuType == "空" ? (
                  ""
                ) : (
                  nyutaisho[i].nyuType
                )
              ) : (
                <ArrowDownOutlined />
              )}
            </div>
          ),
          col2:
            nyutaisho[i].modeRecord && nyutaisho[i].modeRecord === "copy" ? (
              <div style={{ color: "blue" }} className={"icon-table"}>
                {nyutaisho[i].date ? nyutaisho[i].date.split("-")[2] : ""}
              </div>
            ) : (
              <div className={"icon-table"}>
                {nyutaisho[i].date ? nyutaisho[i].date.split("-")[2] : ""}
              </div>
            ),
          col3: (
            <div
              className={`icon-table${nyutaisho[i].holidayClass ? nyutaisho[i].holidayClass : ""}`}
            >
              {nyutaisho[i].date
                ? getDateInMonth(
                  nyutaisho[i].date.split("-")[0],
                  nyutaisho[i].date.split("-")[1],
                  nyutaisho[i].date.split("-")[2]
                )
                : ""}
            </div>
          ),
          col5: (
            <div className={`icon-table ${planType}`}>
              {nyutaisho[i].time ? nyutaisho[i].time.substring(0, 5) : ""}
            </div>
          ),
          col6: (
            <div className={"icon-table"}>
              {nyutaisho[i].reportCaseTyuif ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                ""
              )}
            </div>
          ),
          col7: (
            <div className={"icon-table"}>
              {nyutaisho[i].nyuyokuNaiyou && nyutaisho[i].nyuyokuNaiyou.trim()
                ? nyutaisho[i].nyuyokuNaiyou
                : ""}
            </div>
          ),
          col8: <div>{nyutaisho[i].comment}</div>,
          col9:
            nyutaisho[i].tantoName && nyutaisho[i].tantoName.length > 6 ? (
              <Tooltip placement="bottomRight" title={nyutaisho[i].tantoName}>
                {nyutaisho[i].tantoName.substring(0, 4) + "..."}
              </Tooltip>
            ) : (
              <div className={"icon-table"}>
                {nyutaisho[i].tantoName ? nyutaisho[i].tantoName : ""}
              </div>
            ),
          colDel: (
            <span className={"action-kiroku"}>
              {nyutaisho[i].modeRecord || nyutaisho[i].nyuyokuId ? (
                !nyutaisho[i].modeRecord && nyutaisho[i].nyuyokuId ? (
                  <Popconfirm
                    overlayStyle={{ width: "210px" }}
                    title="削除してもいいですか？"
                    onConfirm={() =>
                      this.handleServerDel(nyutaisho[i].nyuyokuId)
                    }
                  >
                    <span>
                      <Button type={"primary"} ghost>
                        <MinusOutlined />
                      </Button>
                    </span>
                  </Popconfirm>
                ) : (
                  <span>
                    <Button
                      type={"primary"}
                      ghost
                      onClick={this.handleDelNyuyoku.bind(this, nyutaisho[i])}
                    >
                      <MinusOutlined />
                    </Button>
                  </span>
                )
              ) : null}
            </span>
          ),
          description: (
            <TableRowButton
              editRow={this.showDrawer.bind(this, nyutaisho[i])}
              type="user"
            />
          ),
        });
      }
    }

    return (
      (<div>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Spin spinning={loading}>
                    <Table
                      {...this.state}
                      showHeader={true}
                      expandIconAsCell={false}
                      expandIconColumnIndex={-1}
                      bordered={true}
                      size="small"
                      expandedRowKeys={this.state.expandRowClick}
                      pagination={{ position: "bottom", pageSize: 50 }}
                      scroll={{ x: 1400, y: this.state.innerHeightTable }}
                      className="table-kiroku-ri"
                      rowClassName={(record, index) =>
                        this.state.rowActive != null &&
                          index === this.state.rowActive
                          ? "pljp-table-row-hover"
                          : ""
                      }
                      onChange={(pagination) => {
                        this.handChangePagination(pagination);
                      }}
                      columns={columnsLong}
                      dataSource={state.hasData ? data : null}
                    />
                  </Spin>
                </div>
              );
            }
            return null;

          }}
        </MediaQuery>
        <Drawer
          destroyOnClose
          title={this.state.record ? this.state.record.riyousyaName : "\u00A0"}
          width={720}
          onClose={this.closeDrawer}
          open={this.state.showDrawer}
          closable={false}
          rootClassName="kiroku-drawer-form"
        >
          <NyuyokuForm
            tantoMaster={tantoMaster}
            data={this.state.record ? this.state.record : {}}
            handleFormInput={this.getHandleFormInput}
            yougoMasterEditState={this.getYougoMasterEditState}
            yougoMasterEditCancel={this.getYougoMasterEditCancel}
            yougoMasterEditSave={this.getYougoMasterEditSave}
            yougoMasterNa={yougoMasterNa}
            yougoMasterSy={yougoMasterSy}
            syokichiItem={this.syokichiItem}
            syokichiUpdate={this.syokichiUpdate}
          />
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left" }}>
              <Button onClick={this.upperForm} style={{ marginRight: 8 }}>
                <ArrowUpOutlined />
              </Button>
              <Button onClick={this.lowerForm}>
                <ArrowDownOutlined />
              </Button>
            </span>

            <span>
              <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
                戻る
              </Button>
              <Button type="primary" danger onClick={this.handleOkUpdateForm}>
                更新
              </Button>
            </span>
          </div>
        </Drawer>
        <Modal
          title={
            <div className="kim-header-title">
              <span>{this.state.riyousyaName}</span>
              <span>
                {this.state.time ? this.state.time.substring(0, 5) : ""}
              </span>
            </div>
          }
          open={this.state.visibleMInput}
          onOk={this.handleOkUpdate}
          onCancel={this.handleMInputCancel}
          className={"kiroku-input-modal"}
          footer={[
            <Button key="pre" onClick={this.preInput}>
              前項目
            </Button>,
            <Button key="next" onClick={this.nextInput}>
              次項目
            </Button>,
            <Button
              className="kim-btn-ok"
              key="submit"
              type="primary"
              onClick={this.handleOkUpdate}
              disabled={this.state.okSaveButton}
            >
              OK
            </Button>,
            <Button key="up" onClick={this.upperInput}>
              上の行
            </Button>,
            <Button key="dow" onClick={this.lowerInput}>
              下の行
            </Button>,
          ]}
          maskClosable={false}
        >
          <div className="kim-title">
            <span>{this.state.field}</span>
          </div>

          {this.state.isZendkaiDis ? (
            <div className={"model-top-row"}>
              <div className="kim-zenkai"></div>
              <div
                className={`kim-value ${this.state.field === "時間" ? "kim-value-time" : ""}`}
              >
                <div className="kim-value-top">
                  {this.state.inputValue ? this.state.inputValue : "\u00A0"}
                </div>
                {this.state.field === "時間" ? (
                  <div className="kim-value-bottom">
                    <Button type="primary" onClick={this.handleSetCurrentTime}>現時刻</Button>
                  </div>
                ) : null}
              </div>
              <div className={"mtr-col1 kim-suggest"}></div>
            </div>
          ) : null}

          <div>
            {(() => {
              switch (this.state.modalType) {
                case ConstSet.MODAL_N_TIME:
                  return (
                    <TimeKB
                      handleHourInput={this.getHandleHourInput}
                      handleMinuteLeftInput={this.getHandleMinuteLeftInput}
                      handleMinuteInput={this.getHandleMinuteInput}
                    />
                  );

                case ConstSet.MODAL_N_REPORTCASETYUIF:
                  return (
                    <CheckKB
                      defaltValue={this.state.inputValue}
                      handleOnchangeCheck={this.getHandleReportCaseTyuif}
                    />
                  );

                case ConstSet.MODAL_N_NYUYOKUNAIYOU:
                  return (
                    <InputSelectKB
                      value={this.state.inputValue}
                      handleInputSelect={this.getHandleNaiyou}
                      data={yougoMasterSy}
                      syokichiItem={this.syokichiItem}
                      syokichiUpdate={this.syokichiUpdate}
                      typeSyokichi={true}
                    />
                  );

                case ConstSet.MODAL_N_COMMENT:
                  return (
                    <CommentKB
                      yougoMasterEditState={this.getYougoMasterEditState}
                      yougoMasterEditCancel={this.getYougoMasterEditCancel}
                      yougoMasterEditSave={this.getYougoMasterEditSave}
                      defaultValue={this.state.inputValue}
                      handleTextareaKB={this.getHandleInputComment}
                      yougoMasterNa={yougoMasterNa}
                      rowNum={4}
                    />
                  );

                case ConstSet.MODAL_N_TANTOMASTER:
                  return (
                    <TantoMasterKB2
                      tantoMaster={tantoMaster}
                      value={this.state.inputValue}
                      handleTantoMasterKB={this.getHandleTantoMasterKB}
                      widthCode={100}
                      widthText={250}
                    />
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </Modal>
        <Modal
          title={[
            <span key={1}>{"一括入力"}</span>,
            <span
              key={2}
              style={{ marginLeft: "24px", fontSize: "14px", color: "red" }}
            >
              {this.state.mulInputNotification}
            </span>,
          ]}
          open={this.props.mulInputModal && mulInputMode}
          onOk={this.handleMulInputModeOk}
          onCancel={this.handleMulInputModeCancel}
          footer={[
            <Button key="cancel" onClick={this.handleMulInputModeCancel}>
              閉じる
            </Button>,
            <Button
              key="ok"
              onClick={this.handleMulInputModeOk}
              type="primary"
              disabled={checkIkkatsuOkBtn(state.mulInputCheckbox)}
            >
              OK
            </Button>,
          ]}
        >
          <div>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 0, "time")}
                    checked={this.state.mulInputCheckbox[0]}
                  >
                    時間
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={12}>
                <TimeKBCus
                  timeString={this.state.mulInputData.time}
                  handleInput={this.onChangeTimeMulModeNew}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(
                      this,
                      1,
                      "nyuyoku_naiyou"
                    )}
                    checked={this.state.mulInputCheckbox[1]}
                  >
                    種類
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={18}>
                <FormItem>
                  <InputSelectKB
                    value={this.state.mulInputData.nyuyoku_naiyou}
                    handleInputSelect={this.getHandleMulNyuyokuNaiyou}
                    data={yougoMasterSy}
                    disabled={this.state.isShunrui}
                  />
                  <span>
                    <Checkbox
                      onClick={this.mulSetDefaultValue}
                      checked={this.state.isShunrui}
                    >
                      初期値をセット
                    </Checkbox>
                  </span>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 2, "comment")}
                    checked={this.state.mulInputCheckbox[2]}
                  >
                    コメント
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={17}>
                <FormItem>
                  <CommentKB
                    yougoMasterEditState={this.getYougoMasterEditState}
                    yougoMasterEditCancel={this.getYougoMasterEditCancel}
                    yougoMasterEditSave={this.getYougoMasterEditSave}
                    handleTextareaKB={this.getHandleMulInputComment}
                    handleOnchange={this.getHandleOnChangeMIC}
                    yougoMasterNa={yougoMasterNa}
                    defaultValue={this.state.mulInputData.comment}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 3, "tantoId")}
                    checked={this.state.mulInputCheckbox[3]}
                  >
                    担当者
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={18}>
                <FormItem>
                  <TantoMasterKB
                    tantoMaster={tantoMaster}
                    value={this.state.mulInputData.tantoId}
                    handleTantoMasterKB={this.getHandleMulTantoMasterKB}
                    widthCode={100}
                    widthText={250}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          destroyOnClose
          title={[
            <span key={1}>{"一括削除"}</span>,
            <span
              key={2}
              style={{ marginLeft: "24px", fontSize: "14px", color: "red" }}
            >
              {this.state.mulInputNotification}
            </span>,
          ]}
          open={mulInputModal && mulDelMode}
          onOk={this.handleMulInputModeOk}
          onCancel={this.handleMulInputModeCancel}
          footer={[
            <Button key="cancel" onClick={this.handleMulInputModeCancel}>
              キャンセル
            </Button>,
            <Button
              key="ok"
              onClick={this.handleMulInputModeOk}
              type="primary"
              disabled={!this.state.isConfirmMulDel}
            >
              OK
            </Button>,
          ]}
        >
          <div>
            <h3>一括削除を実行しますか？</h3>
            <Checkbox
              value={this.state.isConfirmMulDel}
              onChange={this.onChangeConfirmMulDel}
            >
              削除に同意する
            </Checkbox>
          </div>
        </Modal>
        <IkkatsuModal
          visibleMulState={state.visibleMulState}
          rowsSelect={state.rowsSelect}
          count={state.count}
          closePopup={this.closeModalMulState}
        />
      </div>)
    );
  }
}
