import { Form } from "@ant-design/compatible";
import React, { Component } from "react";

// import '@ant-design/compatible/assets/index.css';


import { Col, Divider, Row } from "antd";
import TextAreaSelect from "./TextAreaSelect";

class FormOtherNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      //
      sonotaList1,
      sonotaList2,
      sonotaList3,
      sonotaList4,
      sonotaList5,
      sonotaList6,
      sonotaList7,
      sonotaList8,
      sonotaList9,
      sonotaList10,

      setFSSonotaTitle,
      fsSonotaTitleDis,
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const fsSonotaTitleDisCon = fsSonotaTitleDis
      ? fsSonotaTitleDis.toString().split(";")
      : [];

    return (
      <Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {setFSSonotaTitle && fsSonotaTitleDisCon.length > 0
          ? setFSSonotaTitle
            .toString()
            .split(";")
            .map((item, index) => {
              if (item !== "none") {
                return (
                  <Row className="rdu-tab-2">
                    <Col sm={6} md={6} className="rdu-tab-title">
                      <span>{fsSonotaTitleDisCon[index]}</span>
                    </Col>
                    <Col sm={18} md={18} className="rdu-tab-content">
                      {getFieldDecorator(`sonota${index + 1}`, {
                        rules: [
                          {
                            required: false,
                            message: "必須項目をセットして下さい",
                          },
                        ],
                      })(
                        <TextAreaSelect
                          isMaster={false}
                          isSelect={true}
                          rowSet={4}
                          dataSelect={
                            index + 1 === 1
                              ? sonotaList1
                              : index + 1 === 2
                                ? sonotaList2
                                : index + 1 === 3
                                  ? sonotaList3
                                  : index + 1 === 4
                                    ? sonotaList4
                                    : index + 1 === 5
                                      ? sonotaList5
                                      : index + 1 === 6
                                        ? sonotaList6
                                        : index + 1 === 7
                                          ? sonotaList7
                                          : index + 1 === 8
                                            ? sonotaList8
                                            : index + 1 === 9
                                              ? sonotaList9
                                              : index + 1 === 10
                                                ? sonotaList10
                                                : null
                          }
                          yougoMasterUpdate={(naiyou) =>
                            this.yougoMasterUpdate(
                              `FSその他${index + 1}`,
                              naiyou
                            )
                          }
                        />
                      )}
                    </Col>
                    <Divider style={{ marginTop: "6px" }} />
                  </Row>
                );
              }
            })
          : null}

        {/* <Divider />
                <Form.Item label={"緊急時の対応"} colon={false}>
                    {getFieldDecorator('sonota2', {
                        rules: [{ required: false, message: "必須項目をセットして下さい" }],
                    })(<TextAreaSelect
                        isMaster={false}
                        isSelect={true}
                        rowSet={4}

                        dataSelect={sonotaList2}
                        yougoMasterUpdate={(naiyou) => this.yougoMasterUpdate('FSその他2', naiyou)}
                    />)}
                </Form.Item>
                <Divider />
                <Form.Item label={"起こりうる問題点"} colon={false}>
                    {getFieldDecorator('sonota3', {
                        rules: [{ required: false, message: "必須項目をセットして下さい" }],
                    })(<TextAreaSelect
                        isMaster={false}
                        isSelect={true}
                        rowSet={4}

                        dataSelect={sonotaList3}
                        yougoMasterUpdate={(naiyou) => this.yougoMasterUpdate('FSその他3', naiyou)}
                    />)}
                </Form.Item>
                <Divider />
                <Form.Item label={"共通他4"} colon={false}>
                    {getFieldDecorator('sonota4', {
                        rules: [{ required: false, message: "必須項目をセットして下さい" }],
                    })(<TextAreaSelect
                        isMaster={false}
                        isSelect={true}
                        rowSet={4}

                        dataSelect={sonotaList4}
                        yougoMasterUpdate={(naiyou) => this.yougoMasterUpdate('FSその他4', naiyou)}
                    />)}
                </Form.Item>
                <Divider />
                <Form.Item label={"共通他5"} colon={false}>
                    {getFieldDecorator('sonota5', {
                        rules: [{ required: false, message: "必須項目をセットして下さい" }],
                    })(<TextAreaSelect
                        isMaster={false}
                        isSelect={true}
                        rowSet={4}

                        dataSelect={sonotaList5}
                        yougoMasterUpdate={(naiyou) => this.yougoMasterUpdate('FSその他5', naiyou)}
                    />)}
                </Form.Item>
                <Divider />
                <Form.Item label={"担当看護師"} colon={false}>
                    {getFieldDecorator('sonota6', {
                        rules: [{ required: false, message: "必須項目をセットして下さい" }],
                    })(<TextAreaSelect
                        isMaster={false}
                        isSelect={true}
                        rowSet={4}

                        dataSelect={sonotaList6}
                        yougoMasterUpdate={(naiyou) => this.yougoMasterUpdate('FSその他6', naiyou)}
                    />)}
                </Form.Item>
                <Divider />
                <Form.Item label={"緊急時の対応コメント"} colon={false}>
                    {getFieldDecorator('sonota7', {
                        rules: [{ required: false, message: "必須項目をセットして下さい" }],
                    })(<TextAreaSelect
                        isMaster={false}
                        isSelect={true}
                        rowSet={4}

                        dataSelect={sonotaList7}
                        yougoMasterUpdate={(naiyou) => this.yougoMasterUpdate('FSその他7', naiyou)}
                    />)}
                </Form.Item>
                <Divider />
                <Form.Item label={"対処方法"} colon={false}>
                    {getFieldDecorator('sonota8', {
                        rules: [{ required: false, message: "必須項目をセットして下さい" }],
                    })(<TextAreaSelect
                        isMaster={false}
                        isSelect={true}
                        rowSet={4}

                        dataSelect={sonotaList8}
                        yougoMasterUpdate={(naiyou) => this.yougoMasterUpdate('FSその他8', naiyou)}
                    />)}
                </Form.Item>
                <Divider /> */}
      </Form>
    );
  }
}

export const FormOther = Form.create({
  name: "input-riyou-other",
  onValuesChange(props, changedValues, allValues) {
    // @TODO convert checkbox group to value
    props.saveOnchangeToState(allValues, "fOther", "");
  },
  mapPropsToFields(props) {
    return {
      sonota1: Form.createFormField({
        value: props.data ? props.data.sonota1 : null,
      }),
      sonota2: Form.createFormField({
        value: props.data ? props.data.sonota2 : null,
      }),
      sonota3: Form.createFormField({
        value: props.data ? props.data.sonota3 : null,
      }),
      sonota4: Form.createFormField({
        value: props.data ? props.data.sonota4 : null,
      }),
      sonota5: Form.createFormField({
        value: props.data ? props.data.sonota5 : null,
      }),
      sonota6: Form.createFormField({
        value: props.data ? props.data.sonota6 : null,
      }),
      sonota7: Form.createFormField({
        value: props.data ? props.data.sonota7 : null,
      }),
      sonota8: Form.createFormField({
        value: props.data ? props.data.sonota8 : null,
      }),
      sonota9: Form.createFormField({
        value: props.data ? props.data.sonota9 : null,
      }),
      sonota10: Form.createFormField({
        value: props.data ? props.data.sonota10 : null,
      }),
    };
  },
})(FormOtherNormal);
