export const Label = {
  // for kobetsu
  Life: {
    KobetsuAddForm1: "個別機能II",
    KobetsuAddForm2: "生活機能チェック",
  },

  LifeTab1: {
    Title: "利用者一覧",
  },
  LifeTab2: {
    Title: "科学的介護",
  },
  LifeTab3: {
    Title: "個別機能Ⅱ",
  },
  LifeTab4: {
    Title: "口腔",
  },
  LifeTab5: {
    Title: "褥瘡マネジメント",
  },
  LifeTab6: {
    Title: "排せつ支援",
  },
  LifeTab7: {
    Title: "自立支援促進",
  },
  LifeTab8: {
    Title: "薬剤変更",
  },
  Account: {
    Username: "ログインIDを入力してください",
    Password: "パスワードを入力してください",
    SigninButton: "ログイン",
    Description: "LIFE",
    Note1: "これは、プラスワン開発用テストサーバです",
    Note2: "予告なく再起動する場合がありますのでご了承ください",
  },
  General: {
    Sort: "並替",
    Cancel: "キャンセル",
    Search: "検索",
    LogoTitle: "LIFE",
  },
  Header: {
    DropdownItem: {
      Profile: "ユーザ管理",
      Signout: "ログアウト",
    },
  },
  Dialog: {
    ConfirmSignout: {
      Title: "ログアウト確認",
      Question: "ログアウトします。よろしいですか？",
    },
  },
};
