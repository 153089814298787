import { CaretUpOutlined } from "@ant-design/icons";
import { Col, Collapse, Row } from "antd";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { tantoMasterList2 } from "../../../actions/tantoMasterListActions/actionCreators";
import DutyReportForm from "./DutyReportControl";
import ListPeopleOutIn from "./ListPeopleOutIn";
import NumberByType from "./NumberByType";
import NumberPeopleOutIn from "./NumberPeopleOutIn";
const loginIf = "swpsps";
const { Panel } = Collapse;

const customPanelStyle = {
  background: "#ddd",
  borderRadius: 4,
  marginBottom: 2,
  border: 0,
  overflow: "hidden",
};

class DutyReportMain extends React.Component {
  constructor(props) {
    const loginInfo = JSON.parse(localStorage.getItem(loginIf));
    super(props);
    this.state = {
      visibleConfirm: false,
      tantoIdLogin: loginInfo ? loginInfo.tid : null,
    };
  }
  componentDidMount() {
    this.props.tantoMasterList2();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { tantoMasters2 } = nextProps;
    const { tantoIdLogin } = this.state;
    if (tantoMasters2 !== undefined) {
      const code = _.find(tantoMasters2, { id: tantoIdLogin })
        ? _.find(tantoMasters2, { id: tantoIdLogin }).code
        : null;
      this.setState({ tantoIdLogin: code != null ? tantoIdLogin : null });
    }
  }
  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  handleSaveData() {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const parameterObj = {
        tantoId: values.tanto_id != null ? values.tanto_id.toString() : null,
        weather:
          values.weather != null && values.weather.length > 0
            ? values.weather
            : null,
        event:
          values.event != null && values.event.trim().length > 0
            ? values.event.trim()
            : null,
        conference:
          values.conference != null && values.conference.trim().length > 0
            ? values.conference.trim()
            : null,
        jikoHoukoku:
          values.jiko_houkoku != null && values.jiko_houkoku.trim().length > 0
            ? values.jiko_houkoku.trim()
            : null,
        kanriComment:
          values.kanri_comment != null && values.kanri_comment.trim().length > 0
            ? values.kanri_comment.trim()
            : null,
        renrakuTokki:
          values.renraku_tokki != null && values.renraku_tokki.trim().length > 0
            ? values.renraku_tokki.trim()
            : null,
        shokuinNum1: values.shokuin1.shokuin_num.toString(),
        shokuinNum2: values.shokuin2.shokuin_num.toString(),
        shokuinNum3: values.shokuin3.shokuin_num.toString(),
        shokuinNum4: values.shokuin4.shokuin_num.toString(),
        shokuinNum5: values.shokuin5.shokuin_num.toString(),
        shokuinNum6: values.shokuin6.shokuin_num.toString(),
        shokuin1:
          values.shokuin1.shokuin != null &&
            values.shokuin1.shokuin.trim().length > 0
            ? values.shokuin1.shokuin.trim()
            : null,
        shokuin2:
          values.shokuin2.shokuin != null &&
            values.shokuin2.shokuin.trim().length > 0
            ? values.shokuin2.shokuin.trim()
            : null,
        shokuin3:
          values.shokuin3.shokuin != null &&
            values.shokuin3.shokuin.trim().length > 0
            ? values.shokuin3.shokuin.trim()
            : null,
        shokuin4:
          values.shokuin4.shokuin != null &&
            values.shokuin4.shokuin.trim().length > 0
            ? values.shokuin4.shokuin.trim()
            : null,
        shokuin5:
          values.shokuin5.shokuin != null &&
            values.shokuin5.shokuin.trim().length > 0
            ? values.shokuin5.shokuin.trim()
            : null,
        shokuin6:
          values.shokuin6.shokuin != null &&
            values.shokuin6.shokuin.trim().length > 0
            ? values.shokuin6.shokuin.trim()
            : null,
        workingStatus: values.working_status
          ? values.working_status
            .map((e) => {
              return JSON.stringify(e);
            })
            .join(" ; ")
          : null,
        dutyReportKinmujoukyous: values.working_status
          ? values.working_status
          : null,
      };
      this.props.saveDutyReport(parameterObj);
    });
  }
  isChangeForm() {
    return this.formRef.props.form.isFieldsTouched();
  }

  render() {
    const { tantoIdLogin } = this.state;
    return (
      <div>
        <DutyReportForm
          history={this.props.history}
          selectedDate={this.props.selectedDate}
          wrappedComponentRef={this.saveFormRef}
          handleSaveData={() => this.handleSaveData()}
          handleChangeDate={(value) => this.props.handleChangeDate(value)}
          dutyReport={this.props.dutyReport}
          handleChangeRoomBunrui={(value) =>
            this.props.handleChangeRoomBunrui(value)
          }
          roomBunrui={this.props.roomBunrui}
          isChangeForm={() => this.isChangeForm()}
          staffKind={this.props.staffKind ? this.props.staffKind : []}
          tantoIdLogin={tantoIdLogin}
        />
        <Collapse
          className="rwa-c-col"
          defaultActiveKey={["3"]}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <CaretUpOutlined rotate={isActive ? 180 : 90} />
          )}
          style={{
            paddingBottom: "20px",
            margin: "0 auto",
            maxWidth: "1200px",
            borderStyle: "none",
          }}
        >
          <Panel header="入退所状況等" key="3" style={customPanelStyle}>
            <Row
              style={{
                paddingTop: "10px",
                paddingBottom: "30px",
                paddingLeft: "10px",
                paddingRight: "10px",
                margin: "0 auto",
                maxWidth: "1200px",
              }}
            >
              <Col
                md={15}
                style={{ paddingTop: "10px" }}
              >
                <div>
                  <NumberPeopleOutIn />
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <ListPeopleOutIn />
                </div>
              </Col>
              <Col
                md={{ span: 8, offset: 1 }}
                style={{ paddingTop: "10px" }}
              >
                <NumberByType />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    tantoMasters2: state.tantoMasterList.tantoMasters2,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tantoMasterList2: () => dispatch(tantoMasterList2()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DutyReportMain);
