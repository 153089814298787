import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createKeikaKiroku,
  loadKeikaKirokuById,
  resetCurrentKeikaKiroku,
  updateKeikaKiroku,
} from "../../../actions/keikaKirokuActions/actionCreators";
import { getRiyousya } from "../../../actions/riyousyaActions/actionCreators";
import { SettingMasterAPI } from "../../../api/settingMaster";
import { CommonPageContainer } from "../../../common/component/IdleTimerPage";
import KeikaKirokuInputForm from "./KeikaKirokuInputForm";
import "./styles.css";

class KeikaKirokuContainer extends Component {
  constructor(props) {
    super(props);
    const { keikaKirokuId, riyousyaId } = this.props.match.params;
    this.riyousyaId = riyousyaId;
    this.keikaKirokuId = keikaKirokuId;
    this.state = {
      kirokuKubuns: [],
      soudanNaiyous: [],
    };
  }

  componentDidMount() {
    this.props.getRiyousyaInfo(this.riyousyaId);
    this.getKiroKuKubunSettingMaster();
    this.getSoudanNaiyouSettingMaster();
    if (this.keikaKirokuId !== "new") {
      this.props.loadKeikaKirokuById(this.keikaKirokuId);
    }
    else {
      this.props.resetCurrentKeikaKiroku();
    }
    window.scroll(0, 0);
  }

  regData(err, values) {
    if (err) return;

    if (values["kiroku_time"]) {
      values["kiroku_time"] = dayjs(values["kiroku_time"]).format("HH:mm:ss");
    }

    if (this.keikaKirokuId === "new") {
      this.props.createKeikaKiroku(values, this.riyousyaId, (data) => {
        if (data.id) {
          this.props.history.push(
            `/input-keika-kiroku/${this.riyousyaId}/${data.id}`
          );
        }
      });
    }
    else {
      values["riyousyaId"] = this.riyousyaId;
      this.props.updateKeikaKiroku(values, this.keikaKirokuId);
    }
  }

  regDataAndCheck(err, values) {
    if (err) return;

    if (this.keikaKirokuId !== "new") {
      if (values["kiroku_time"]) {
        values["kiroku_time"] = dayjs(values["kiroku_time"]).format(
          "HH:mm:ss"
        );
      }

      values["riyousyaId"] = this.riyousyaId;
      this.props.updateKeikaKiroku(values, this.keikaKirokuId);

      const win = window.open(
        `/check-info-document-list/${this.riyousyaId}/${this.keikaKirokuId}`,
        "_blank"
      );
      win.focus();
    }
  }

  getKiroKuKubunSettingMaster() {
    SettingMasterAPI.getByName("Vi経過記録区分").then((data) => {
      const kirokuKubuns = this.parseData(data);
      if (kirokuKubuns && kirokuKubuns.length > 0) {
        this.setState({ kirokuKubuns });
      }
    });
  }

  getSoudanNaiyouSettingMaster() {
    SettingMasterAPI.getByName("Vi経過記録内容").then((data) => {
      const soudanNaiyous = this.parseData(data);
      if (soudanNaiyous && soudanNaiyous.length > 0) {
        this.setState({ soudanNaiyous });
      }
    });
  }

  canonicalizeNewlines(str) {
    return str.replace(/(\r\n|\r|\n)/g, "\n");
  }

  parseData(data) {
    if (!data || !data.naiyou) return;

    const { naiyou } = data;
    const parsedData = this.canonicalizeNewlines(naiyou);
    return parsedData.split("\n");
  }

  render() {
    const { keikaKiroku, riyousya } = this.props;
    const { kirokuKubuns, soudanNaiyous } = this.state;
    const { keikaKirokuId, riyousyaId } = this.props.match.params;
    this.riyousyaId = riyousyaId;
    this.keikaKirokuId = keikaKirokuId;

    return (
      <CommonPageContainer
        id="input-keika-kiroku-page"
        sessionTimeout={this.props.sessionTimeout}
      >
        <KeikaKirokuInputForm
          riyousyaId={this.riyousyaId}
          keikaKirokuId={this.keikaKirokuId}
          riyousya={riyousya}
          data={this.keikaKirokuId !== "new" ? keikaKiroku : {}}
          regData={(err, values) => this.regData(err, values)}
          regDataAndCheck={(err, values) => this.regDataAndCheck(err, values)}
          history={this.props.history}
          match={this.props.match}
          kirokuKubuns={kirokuKubuns}
          soudanNaiyous={soudanNaiyous}
        />
      </CommonPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    riyousya: state.riyousya.riyousya,
    keikaKiroku: state.keikaKiroku.keikaKiroku,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
    loadKeikaKirokuById: (id) => dispatch(loadKeikaKirokuById(id)),
    createKeikaKiroku: (data, rid, callback) =>
      dispatch(createKeikaKiroku(data, rid, callback)),
    updateKeikaKiroku: (data, id, callback) =>
      dispatch(updateKeikaKiroku(data, id, callback)),
    resetCurrentKeikaKiroku: () => dispatch(resetCurrentKeikaKiroku()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeikaKirokuContainer);
