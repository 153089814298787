import React from "react";
import { Row, Button, Checkbox } from "antd";
import WrappedSelectForm from "./SelectForm";

export default class ActionsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    // bind place
  }

  preToEnd = () => {
    this.props.preToEnd();
  };

  pre = () => {
    this.props.pre();
  };

  next = () => {
    this.props.next();
  };

  nextToEnd = () => {
    this.props.nextToEnd();
  };

  recordMark = (isCheck) => {
    const { id } = this.props;
    if (isCheck) {
      this.props.recordMark("add", id);
    } else {
      this.props.recordMark("remove", id);
    }
  };

  render() {
    const { selectedList, id } = this.props;
    return (
      <Row
        style={{
          textAlign: "center",
          marginBottom: 16,
          padding: 8,
          background: "rgb(190, 200, 200)",
        }}
      >
        <WrappedSelectForm
          value={selectedList && selectedList.includes(id) ? 1 : 0}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          recordId={id}
        />
      </Row>
    );
  }
}
