import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Modal } from "antd";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import datePickerJp from "../../../data/datePickerJp.json";

const ButtonGroup = Button.Group;

class DatePickerCommon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: dayjs(props.selectedDate, "YYYY-MM-DD"),
      visibleConfirm: false,
      date: null,
      changeDate: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ selectedDate: nextProps.selectedDate });
  }
  componentDidUpdate(prevProps) {
    const { notifyDisplay } = this.props;
    if (prevProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1 && this.state.changeDate == true) {
        const { date } = this.state;
        this.props.handleChangeDate(dayjs(date, "YYYY-MM-DD"));
        this.setState({
          visibleConfirm: false,
          date: this.state.selectedDate,
          changeDate: false,
        });
      }
      if (notifyDisplay === 2 && this.state.changeDate == true) {
        this.setState({
          visibleConfirm: false,
          date: this.state.selectedDate,
          changeDate: false,
        });
      }
    }
  }

  handleNextDate() {
    const selectedDate = dayjs(this.state.selectedDate);
    const date = selectedDate.add(1, "days");
    if (this.props.isChangeForm() || this.props.isChange) {
      this.setState({
        visibleConfirm: true,
        date,
      });
    }
    else {
      this.setState({
        selectedDate: date,
      });
      this.props.handleChangeDate(date);
    }
  }

  handlePreviousDate() {
    const selectedDate = dayjs(this.state.selectedDate);
    const date = selectedDate.subtract(1, "days");
    if (this.props.isChangeForm() || this.props.isChange) {
      this.setState({
        visibleConfirm: true,
        date,
      });
    }
    else {
      this.setState({
        selectedDate: date,
      });
      this.props.handleChangeDate(date);
    }
  }
  handleChangeDate(value) {
    if (dayjs(value, "YYYY-MM-DD").valueOf() != this.state.selectedDate) {
      if (this.props.isChangeForm() || this.props.isChange) {
        this.setState({
          visibleConfirm: true,
          date: dayjs(value, "YYYY-MM-DD"),
        });
      }
      else {
        this.setState({
          selectedDate: value,
        });
        this.props.handleChangeDate(value);
      }
    }
  }
  onCancelConfirm() {
    const { date } = this.state;
    this.props.handleChangeDate(date);
    this.setState({
      visibleConfirm: false,
      date: null,
    });
    this.props.resetIsChange();
  }
  async onOkConfirm() {
    await this.setState({
      visibleConfirm: false,
      changeDate: true,
    });
    this.props.handleSaveData();
  }
  onHideConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  render() {
    const { selectedDate } = this.props;
    const { visibleConfirm } = this.state;
    return (
      (<div>
        <ButtonGroup className={"sf-datapicker"}>
          <Button
            onClick={() => this.handlePreviousDate()}
            icon={<CaretLeftOutlined />}
            type={"primary"}
          />
          <DatePicker
            locale={datePickerJp}
            style={{ width: "120px" }}
            value={dayjs(selectedDate)}
            onChange={(value) => this.handleChangeDate(value)}
            readOnly={true}
            className="rn-datepicker"
          />
          <Button
            onClick={() => this.handleNextDate()}
            icon={<CaretRightOutlined />}
            type={"primary"}
          />
        </ButtonGroup>
        <Modal
          title={"変更を確認する"}
          open={visibleConfirm}
          onCancel={() => this.onHideConfirm()}
          footer={[
            <Button key="cancel" onClick={() => this.onCancelConfirm()}>
              キャンセル
            </Button>,
            <Button key="ok" type="primary" onClick={() => this.onOkConfirm()}>
              Ok
            </Button>,
          ]}
        >
          <p>保存しますか？</p>
        </Modal>
      </div>)
    );
  }
}
const mapStateToProps = (state) => {
  return {
    notifyDisplay: state.dutyReport.notifyDisplay,
    notifyContent: state.dutyReport.notifyContent,
  };
};
export default connect(mapStateToProps, null)(DatePickerCommon);
