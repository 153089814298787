export const LOAD_BASIC_ACTION_REQUEST = "LOAD_BASIC_ACTION_REQUEST";
export const LOAD_BASIC_ACTION_SUCCESS = "LOAD_BASIC_ACTION_SUCCESS";
export const LOAD_BASIC_ACTION_ERROR = "LOAD_BASIC_ACTION_ERROR";

export const LOAD_ANSWER_REQUEST = "LOAD_ANSWER_REQUEST";
export const LOAD_ANSWER_SUCCESS = "LOAD_ANSWER_SUCCESS";
export const LOAD_ANSWER_ERROR = "LOAD_ANSWER_ERROR";

export const UPDATE_BASIC_ACTION_REQUEST = "UPDATE_BASIC_ACTION_REQUEST";
export const UPDATE_BASIC_ACTION_SUCCESS = "UPDATE_BASIC_ACTION_SUCCESS";
export const UPDATE_BASIC_ACTION_ERROR = "UPDATE_BASIC_ACTION_ERROR";
export const RESET_STATUS = "RESET_STATUS";

export const KEEP_VALUE ="KEEP_VALUE";