import {
  DRUG_MASTER_LIST_SUCCESS,
  // DRUG_MASTER_LIST_ERROR,
} from "../../actions/drugMasterActions/actionName.js";
import { DrugMasterList } from "../../api/drugMaster";

/**
 *
 * @param {*} listName
 */
function drugMasterListCreator(bunrui, name) {
  return (dispatch) => {
    DrugMasterList.loadList(bunrui, name).then((data) =>
      dispatch({
        type: DRUG_MASTER_LIST_SUCCESS,
        payload: data,
      })
    );
  };
}

export { drugMasterListCreator };
