import React from "react";
import { connect } from "react-redux";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Button, Table, Modal, Row, Select, Col, Divider, Spin } from "antd";
import _ from "lodash";
import TextInputArea from "./TextInputArea";
import TextInput from "./TextInput";
import ServiceKikanMasterModal from "../../../management/dialogs/Common/ServiceKikanMasterModal";
import {
  ServiceKikanMasterActions,
  getMaxSortNum,
} from "../../../management/redux/actions/serviceKikanMaster";
import {
  updateServiceKikanList, //sort時
  serviceKikanMasterListCreator2,
  // serviceRyuruiMasterListCreator,
} from "../../../actions/allMasterActions/actionCreators";

const ADD = 1;
const EDIT = 2;
const DUPLICATE = 3;

class ServiceKikanHolen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceKikanVisible: false,
      rowActive: null,
      record: null,
      bunrui: "", //select選択
      name: "", //input検索
      modeModify: null,
      sid: null,
      sort: false,
      sortTable: [],
      sortTableTmp: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible != this.props.visible && this.props.visible) {
      this.props.getServiceKikanMasterList(this.props.serviceType, "");
      this.setState({
        bunrui: this.props.serviceType,
      });
    }

    if (this.props.isReload && this.props.visible) {
      this.props.turnOffReload();
    }
  }

  //現在のステートで検索
  search = () => {
    const { bunrui, name } = this.state;
    this.props.getServiceKikanMasterList(bunrui, name);
  };

  //モーダルOK
  handleOk = () => {
    const { record } = this.state;

    // レコードを親関数に返す、ここでモーダルfalse
    this.props.onSaveServiceKikan(
      this.props.recordSelected ? this.props.recordSelected.key : null,
      record,
      this.props.recordSelected
    );

    // reset
    this.setState({
      record: null,
      bunrui: "",
      name: "",
      rowActive: null,
    });
  };

  handleCancel = () => {
    this.props.hideModal();
    // reset
    this.setState({
      record: null,
      bunrui: "",
      name: "",
      rowActive: null,
      sort: false,
      sortTable: [],
      sortTableTmp: [],
    });
  };

  onClickCellEvent = (record) => {
    this.setState({
      rowActive: record.id,
      record: record,
    });
  };

  onChangeNameSearch = (value) => {
    this.setState({
      name: value,
    });
  };

  //サービス種類切り替え時
  onChangeBunruiSearch = (bunrui) => {
    const { name } = this.state;
    this.props.getServiceKikanMasterList(bunrui, name);

    this.setState({
      bunrui: bunrui,
    });
  };

  isDisableButton = (rowActive) => {
    return this.props.serviceList
      ? !this.props.serviceList.some((item) => item.id == rowActive)
      : true;
  };

  handleAddService = () => {
    this.setState({
      serviceKikanVisible: true,
      modeModify: ADD,
      sid: null,
    });
  };

  handleEditService = (sid) => {
    this.setState({
      serviceKikanVisible: true,
      modeModify: EDIT,
      sid: sid,
    });
  };

  handleDuplicateService = (sid) => {
    this.setState({
      serviceKikanVisible: true,
      modeModify: DUPLICATE,
      sid: sid,
    });
  };

  handleOkService = () => {
    const { serviceKikanVisible } = this.state;

    if (serviceKikanVisible) {
      this.search();

      this.setState({
        serviceKikanVisible: false,
      });
    }
  };

  handleCancelService = () => {
    if (this.state.serviceKikanVisible) {
      this.setState({
        serviceKikanVisible: false,
      });
      this.props.turnOffReload();
    }
  };

  sortOn = () => {
    const { serviceList } = this.props;
    let tmp = JSON.parse(JSON.stringify(serviceList));
    let tmp2 = JSON.parse(JSON.stringify(serviceList));
    this.setState({
      sort: true,
      sortTable: tmp,
      sortTableTmp: tmp2,
      record: null,
      rowActive: null,
    });
  };
  sortOff = () => {
    this.search();

    this.setState({
      sort: false,
      sortTable: [],
      sortTableTmp: [],
      record: null,
      rowActive: null,
    });
  };
  sortSave() {
    const { bunrui, name, sortTable, sortTableTmp } = this.state;
    let tmp = [];

    for (let record of sortTable) {
      let defTable = sortTableTmp.find((t) => t.id == record.id);
      if (record.sortNum != defTable.sortNum) {
        tmp.push(record);
      }
    }

    this.props.updateServiceKikanList(tmp, (data) => {
      this.props.getServiceKikanMasterList(bunrui, name);
    });

    setTimeout(
      this.setState({
        sort: false,
        sortTable: [],
        sortTableTmp: [],
      }),
      1000
    );
  }

  sortTop = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const minNum = sortTable[0].sortNum;

    if (elementIndex != 0) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = minNum - 1;
      tableTmp.splice(0, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  sortUp = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);

    if (elementIndex != 0) {
      const changeElement = tableTmp[elementIndex - 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex - 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum--;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum++;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  sortDown = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      const changeElement = tableTmp[elementIndex + 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex + 1;

      tableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum++;
        tableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sortNum = changeElementSortNum;
        tableTmp.splice(changeElementIndex, 0, element);
        tableTmp[elementIndex].sortNum--;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  sortBottom = (record) => {
    const { sortTable } = this.state;
    let tableTmp = JSON.parse(JSON.stringify(sortTable));
    let maxNum = 0;
    for (let i of tableTmp) {
      maxNum < i.sortNum ? (maxNum = i.sortNum) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === record.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      tableTmp.splice(elementIndex, 1);
      element.sortNum = maxNum + 1;
      tableTmp.splice(bottomIndex, 0, element);

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  render() {
    const {
      rowSet,
      value,
      serviceKikanMaster,
      serviceSyurui,
      visible,
      serviceKikanList,
    } = this.props;
    const {
      serviceKikanVisible,
      rowActive,
      record,
      name,
      bunrui,
      modeModify,
      sid,
      sort,
      sortTable,
      sortTableTmp,
    } = this.state;

    const titleService = (
      <div>
        <Row style={{ marginBottom: "4px" }}>
          <Col span={6}>サービス種類</Col>
          <Col span={18}>
            <Select
              value={bunrui || null}
              style={{ width: "100%" }}
              className={
                sort == false
                  ? "search-service-kikan"
                  : "search-service-kikan-none"
              }
              disabled={sort == true ? true : false}
              onChange={this.onChangeBunruiSearch}
            >
              <Select.Option key={"top"} value={""}>
                {"\u00a0"}
              </Select.Option>
              {serviceSyurui
                ? serviceSyurui.map((item, intex) => {
                    return (
                      <Select.Option
                        key={intex}
                        value={item.serviceSyuruiCd}
                        // defaultValue="43"
                      >
                        ({item.serviceSyuruiCd}){item.serviceSyurui}
                      </Select.Option>
                    );
                  })
                : null}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={6}>事業所名</Col>
          <Col span={18} style={{ display: "flex" }}>
            <TextInput
              value={name}
              onChange={this.onChangeNameSearch}
              className={
                sort == false
                  ? "search-service-kikan"
                  : "search-service-kikan-none"
              }
              disabled={sort == true ? true : false}
            />
            <Button
              onClick={this.search}
              className={
                sort == false
                  ? "search-service-kikan"
                  : "search-service-kikan-none"
              }
              disabled={sort == true ? true : false}
              icon={<SearchOutlined />}
            >
              検索
            </Button>
          </Col>
        </Row>
      </div>
    );

    const columnsService = [
      {
        title: "jigyousyoNo",
        dataIndex: "jigyousyoNo",
        width: 90,
        key: "jigyousyoNo",
        render: (textEx, record, index) => <div>{textEx}</div>,
        onCell: (record) => {
          return {
            onClick: (event) => this.onClickCellEvent(record), // click row
          };
        },
      },
      {
        title: "ryaku",
        dataIndex: "ryaku",
        width: 250,
        key: "ryaku",
        render: (textEx, record, index) => <div>{textEx}</div>,
        onCell: (record) => {
          return {
            onClick: (event) => this.onClickCellEvent(record), // click row
          };
        },
      },
    ];

    const columnsService2 = [
      {
        title: "ryaku",
        dataIndex: "ryaku",
        width: "65%",
        key: "ryaku",
        // render: (textEx, record, index) => <div>{`${record.jigyousyoName} (${record.syuruiCd})`}</div>,
        render: (textEx, record, index) => <div>{textEx}</div>,
      },
      // { //テスト
      //     title: 'num',
      //     dataIndex: 'sortNum',
      //     width: "25%",
      //     key: "sortNum",
      // },
      {
        title: "行動",
        dataIndex: "operation",
        width: "35%",
        key: "operation",
        render: (record, index) => {
          return (
            <div>
              <span>
                <a onClick={() => this.sortTop(index)} style={{}}>
                  <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.sortUp(index)} style={{}}>
                  <CaretUpOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.sortDown(index)} style={{}}>
                  <CaretDownOutlined style={{ fontSize: "16px" }} />
                </a>
                <Divider type="vertical" />
                <a onClick={() => this.sortBottom(index)} style={{}}>
                  <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                </a>
              </span>
            </div>
          );
        },
      },
    ];

    return (
      (<div className="text-area-select-ri-page">
        <Modal
          open={visible}
          className="modal-list"
          title={titleService}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleOk}
              disabled={sort == true ? true : record ? false : true}
            >
              OK
            </Button>,
          ]}
          closable={false}
        >
          <Button
            key="add"
            type={"primary"}
            onClick={this.handleAddService}
            disabled={sort == true ? true : false}
            style={{ marginRight: 8 }}
          >
            追加
          </Button>
          <Button
            key="edit"
            type={"primary"}
            onClick={() => this.handleEditService(rowActive)}
            disabled={sort == true ? true : this.isDisableButton(rowActive)}
            style={{ marginRight: 8 }}
          >
            修正
          </Button>
          <Button
            key="duplicate"
            type={"primary"}
            onClick={() => this.handleDuplicateService(rowActive)}
            disabled={sort == true ? true : this.isDisableButton(rowActive)}
          >
            複製
          </Button>
          {sort == false ? (
            <a
              key="sort"
              // type={"primary"}
              onClick={this.sortOn}
              style={{
                marginRight: 8,
                marginTop: 7,
                marginLeft: 10,
                color: "black",
              }}
            >
              並び替え
            </a>
          ) : (
            <span>
              <Button
                key="sortSave"
                type={"primary"}
                // onClick={sortSave()}
                onClick={() => this.sortSave()}
                style={{ marginRight: 8, marginLeft: 8 }}
              >
                並び替え保存
              </Button>
              <a
                onClick={this.sortOff}
                style={{ paddingTop: "14px", color: "black" }}
              >
                戻る
              </a>
            </span>
          )}
          <Spin spinning={this.props.loading}>
            {sort === false ? (
              <Table
                dataSource={this.props.serviceList}
                columns={columnsService}
                showHeader={false}
                className={
                  this.props.className
                    ? this.props.className +
                      " riyou-meal-page service-kikan-table"
                    : "riyou-meal-page service-kikan-table"
                }
                // className="riyou-meal-page modal-table"
                pagination={{ pageSize: 20 }}
                scroll={{ y: 300 }}
                // size="small"
                rowClassName={(record) =>
                  rowActive != null && record.id === rowActive
                    ? "pljp-table-row-hover"
                    : ""
                }
                rowKey={(record, index) => index}
                style={{ marginTop: 20 }}
              />
            ) : sort == true ? (
              <Table
                dataSource={sortTable}
                columns={columnsService2}
                showHeader={false}
                className={
                  this.props.className
                    ? this.props.className +
                      " riyou-meal-page service-kikan-table"
                    : "riyou-meal-page service-kikan-table"
                }
                // className="riyou-meal-page modal-table"
                pagination={{ pageSize: 20 }}
                scroll={{ y: 300 }}
                // size="small"
                // rowClassName={(record) => rowActive != null && record.id === rowActive ? 'pljp-table-row-hover' : ''}
                rowKey={(record, index) => index}
                style={{ marginTop: 20 }}
              />
            ) : null}
          </Spin>
        </Modal>
        <ServiceKikanMasterModal
          visible={serviceKikanVisible}
          onOk={this.handleOkService}
          onCancel={this.handleCancelService}
          id={sid}
          syuruiCd={bunrui}
          modeModify={modeModify}
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isReload: state.serviceKikanMaster.isReload,
    loading: state.allMaster.loading,
    maxSort: state.serviceKikanMaster.serviceKikanSortNum,
    // serviceKikanMaster: state.allMaster.serviceKikanMaster,
    serviceKikanList: state.serviceKikanMaster.serviceKikanList,
    serviceList: state.allMaster.serviceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    turnOffReload: () => dispatch(ServiceKikanMasterActions.turnOffReload()),
    updateServiceKikanList: (data, callback) =>
      dispatch(updateServiceKikanList(data, callback)),
    getMaxSortNum: () => dispatch(ServiceKikanMasterActions.getMaxSortNum()),
    getServiceKikanMasterList: (bunrui, name) =>
      dispatch(serviceKikanMasterListCreator2(bunrui, name)),
    // getServiceKikanMaster: (bunrui, name) => dispatch(ServiceKikanMasterActions.getListBySyuruiCd(bunrui, name))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceKikanHolen);
