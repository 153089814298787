import {
  DELETE_KYOTAKU_PLAN1_SUCCESS,
  DELETE_KYOTAKU_PLAN1_ERROR,
  CREATE_KYOTAKU_PLAN1_SUCCESS,
  CREATE_KYOTAKU_PLAN1_ERROR,
  UPDATE_KYOTAKU_PLAN1_SUCCESS,
  UPDATE_KYOTAKU_PLAN1_ERROR,
  LOAD_KYOTAKU_PLAN1_SUCCESS,
  LOAD_KYOTAKU_PLAN1_ERROR,
  LOAD_SPIN_REQUEST,
  LOAD_KYOTAKU_PLAN1_BY_ID_SUCCESS,
  LOAD_KYOTAKU_PLAN1_BY_ID_ERROR,
  LOAD_VIEW_PLANS_BY_RIYOUSYA_SUCCESS,
  LOAD_VIEW_PLANS_BY_RIYOUSYA_ERROR,
} from "../../actions/kyotakuPlan1Actions/actionName";
import {
  DELETE_KYOTAKU_PLAN2_SUCCESS,
  DELETE_KYOTAKU_PLAN2_ERROR,
  CREATE_KYOTAKU_PLAN2_SUCCESS,
  CREATE_KYOTAKU_PLAN2_ERROR,
  UPDATE_KYOTAKU_PLAN2_SUCCESS,
  UPDATE_KYOTAKU_PLAN2_ERROR,
  LOAD_KYOTAKU_PLAN2_SUCCESS,
  LOAD_KYOTAKU_PLAN2_ERROR,
  LOAD_KYOTAKU_PLAN2_BY_ID_SUCCESS,
  LOAD_KYOTAKU_PLAN2_BY_ID_ERROR,
} from "../../actions/kyotakuPlan2Actions/actionName";
import {
  DELETE_SISETU_DAILY_PLAN_SUCCESS,
  DELETE_SISETU_DAILY_PLAN_ERROR,
  LOAD_SISETU_DAILY_PLAN_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_ERROR,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_ERROR,
  SAVE_SISETU_DAILY_PLAN_ERROR,
  SAVE_SISETU_DAILY_PLAN_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_BY_ID_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_BY_ID_ERROR,
} from "../../actions/sisetuDailyPlanActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

import {
  GET_ID_DOCUMENT_LIST,
  GET_ALL_DOCUMENT_LIST_REQUEST,
  GET_ALL_DOCUMENT_LIST_SUCCESS,
  GET_ALL_DOCUMENT_LIST_ERROR,
} from "../../actions/documentList/actionName";
import {
  LOAD_MONITORING_SUCCESS,
  LOAD_MONITORING_ERROR,
  LOAD_MONITORING_BY_ID_SUCCESS,
  LOAD_MONITORING_BY_ID_ERROR,
  DELETE_MONITORING_SUCCESS,
  DELETE_MONITORING_ERROR,
} from "../../actions/monitoringActions/actionName";
import {
  LOAD_TANTO_KAIGI_SUCCESS,
  LOAD_TANTO_KAIGI_ERROR,
  DELETE_TANTO_KAIGI_SUCCESS,
  DELETE_TANTO_KAIGI_ERROR,
  LOAD_TANTO_KAIGI_BY_ID_SUCCESS,
  LOAD_TANTO_KAIGI_BY_ID_ERROR,
} from "../../actions/tantoKaigiActions/actionName";
import {
  LOAD_KEIKA_KIROKU_SUCCESS,
  LOAD_KEIKA_KIROKU_ERROR,
  DELETE_KEIKA_KIROKU_SUCCESS,
  DELETE_KEIKA_KIROKU_ERROR,
  LOAD_KEIKA_KIROKU_BY_ID_SUCCESS,
  LOAD_KEIKA_KIROKU_BY_ID_ERROR,
} from "../../actions/keikaKirokuActions/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */

export default function documentListReducers(
  state = {
    metaDocs: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaPlan1: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaPlan2: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaDailyPlan: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaMonitoring: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaTantoKaigi: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaKeikaKiroku: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    id: null,
    loading: false,
  },
  action
) {
  switch (action.type) {
    case LOAD_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        dataDocs: action.documentLists,
        metaDocs: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };
    case LOAD_KYOTAKU_PLAN1_SUCCESS:
      return {
        ...state,
        dataPlan1: action.kyotakuPlan1s,
        metaPlan1: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };
    case LOAD_KYOTAKU_PLAN1_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case LOAD_KYOTAKU_PLAN2_SUCCESS:
      return {
        ...state,
        dataPlan2: action.kyotakuPlan2s,
        metaPlan2: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };
    case LOAD_KYOTAKU_PLAN2_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case LOAD_SISETU_DAILY_PLAN_SUCCESS:
      return {
        ...state,
        dataDailyPlan: action.sisetuDailyPlans,
        metaDailyPlan: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };
    case LOAD_SISETU_DAILY_PLAN_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case LOAD_MONITORING_SUCCESS:
      return {
        ...state,
        monitorings: action.monitorings,
        metaMonitoring: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };
    case LOAD_MONITORING_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case LOAD_TANTO_KAIGI_SUCCESS:
      return {
        ...state,
        tantoKaigis: action.tantoKaigis,
        metaTantoKaigi: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };
    case LOAD_TANTO_KAIGI_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case LOAD_KEIKA_KIROKU_SUCCESS:
      return {
        ...state,
        keikaKirokus: action.keikaKirokus,
        metaKeikaKiroku: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };
    case LOAD_KEIKA_KIROKU_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case DELETE_KYOTAKU_PLAN1_SUCCESS:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        needReload: true,
      };
    case DELETE_KYOTAKU_PLAN1_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case DELETE_KYOTAKU_PLAN2_SUCCESS:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        needReload: true,
      };
    case DELETE_KYOTAKU_PLAN2_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case DELETE_SISETU_DAILY_PLAN_SUCCESS:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        needReload: true,
      };
    case DELETE_SISETU_DAILY_PLAN_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case DELETE_MONITORING_SUCCESS:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        needReload: true,
      };
    case DELETE_MONITORING_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case DELETE_TANTO_KAIGI_SUCCESS:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        needReload: true,
      };
    case DELETE_TANTO_KAIGI_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case DELETE_KEIKA_KIROKU_SUCCESS:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        needReload: true,
      };
    case DELETE_KEIKA_KIROKU_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case LOAD_KYOTAKU_PLAN1_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOAD_KYOTAKU_PLAN1_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CREATE_KYOTAKU_PLAN1_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_KYOTAKU_PLAN1_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case UPDATE_KYOTAKU_PLAN1_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_KYOTAKU_PLAN1_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
      };
    case LOAD_KYOTAKU_PLAN2_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOAD_KYOTAKU_PLAN2_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CREATE_KYOTAKU_PLAN2_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_KYOTAKU_PLAN2_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_KYOTAKU_PLAN2_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_KYOTAKU_PLAN2_ERROR:
      return {
        ...state,
        loading: false,
      };
    case LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SAVE_SISETU_DAILY_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SAVE_SISETU_DAILY_PLAN_ERROR:
      return {
        ...state,
        loading: false,
      };
    case LOAD_SISETU_DAILY_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOAD_SISETU_DAILY_PLAN_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };
    case LOAD_VIEW_PLANS_BY_RIYOUSYA_SUCCESS:
      return {
        ...state,
        dataView: action.dataView,
      };
    case LOAD_VIEW_PLANS_BY_RIYOUSYA_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        dataView: action.dataView,
      };
    case GET_ID_DOCUMENT_LIST:
      return {
        ...state,
        id: action.id,
      };
    case LOAD_MONITORING_BY_ID_SUCCESS:
    case LOAD_MONITORING_BY_ID_ERROR:
    case LOAD_KEIKA_KIROKU_BY_ID_SUCCESS:
    case LOAD_KEIKA_KIROKU_BY_ID_ERROR:
    case LOAD_TANTO_KAIGI_BY_ID_SUCCESS:
    case LOAD_TANTO_KAIGI_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
