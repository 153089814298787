import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";

export const kohifutanMasterApi = {
  List(text) {
    return axios
      .get(apiUrls.GET_KOHIFUTAN_MASTER, {
        params: {
          text: text,
        },
      })
      .then((res) => res.data);
  },

  loadMaxSort() {
    return axios
      .get(apiUrls.GET_KOHIFUTAN_MASTER_MAX_SORT)
      .then((res) => res.data);
  },

  init() {
    return axios.get(apiUrls.GET_KOHIFUTAN_MASTER_INIT).then((res) => res.data);
  },

  create(data) {
    return axios
      .post(apiUrls.CREATE_KOHIFUTAN_MASTER, data)
      .then((res) => res.data);
  },

  update(data) {
    return axios
      .put(apiUrls.UPDATE_KOHIFUTAN_MASTER, data)
      .then((res) => res.data);
  },

  updateList(data) {
    return axios
      .put(apiUrls.UPDATE_LIST_KOHIFUTAN_MASTER, data)
      .then((res) => res.data);
  },
};
