/* eslint-disable indent */
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { FileTextOutlined, NotificationOutlined, UserOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { Fragment, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { StorageUtils } from "../../utils";

function getFocus(name) {
  const pathname = window.location.pathname;
  if (pathname.indexOf(name) !== -1) {
    return "active";
  }
  return null;
}

function MenuTop(props) {
  const menuKiroku = StorageUtils.getValue("menuMNGT");
  const isButton = !!(
    props.history && props.history.location.pathname == "/management/documents"
  );

  function handleRedirect() {
    props.history.push("/management/monitoring-management");
  }

  const menu = useMemo(() => {
    const documents =
      (props &&
        props.config &&
        props.config.values &&
        props.config.values[1] == "kyotaku") ||
        (menuKiroku &&
          (menuKiroku.sn == "kyotaku" ||
            menuKiroku.sn == "yobou" ||
            menuKiroku.sn == "syoukibo" ||
            menuKiroku.sn == "fukugo" ||
            menuKiroku.sn == "houkatu")) ? (
        <Fragment>
          <Link to={"/management/documents"}>
            <div className={`${getFocus("documents")} link-item`}>
              <FileTextOutlined />
              <p>書類</p>
            </div>
          </Link>
          {/* <Link to={"/management/shakyou"}>
            <div className={`${getFocus("shakyou")} link-item`}>
              <FileTextOutlined />
              <p>社協</p>
            </div>
          </Link> */}
        </Fragment>
      ) : (
        ""
      );
    return documents;
  }, [props.config]);

  return (
    <div
      className={`${props.isDengon ? "sub-menu-top-isdengon" : "sub-menu-top"}`}
    >
      <div className="m-left" style={{ display: "flex", alignItems: "center" }}>
        <div className="text-tool-bar">
          <span style={{ fontSize: "20px", color: "white" }}>
            <LegacyIcon type={props.iconType} theme="outlined" /> {props.title}
          </span>
          {props.count ? (
            <span
              style={{ fontSize: "15px", color: "white", marginLeft: 20 }}
            >{`${props.countNum}件表示中`}</span>
          ) : null}
          <span style={{ fontSize: "25px", color: "white" }}>
            {props.content}
          </span>
        </div>
        <div style={{ marginLeft: "20px" }}>
          {isButton && menuKiroku.sn == "kyotaku" ? (
            <Button
              type="primary"
              onClick={handleRedirect}
              className="button-link-monitoring"
            >
              モニタリング管理
            </Button>
          ) : null}
        </div>
        <div style={{ marginLeft: "20px" }}>
          {isButton ? (
            <Button className="button-temp"
              onClick={() => { props.history.push("/management/usage-ticket-management"); }}
            >
              利用票管理
            </Button>
          ) : null}
        </div>
        {/* <div style={{ marginLeft: "20px" }}>
          {isButton ? (
            <Link to={"/management/assessment"}>
              <Button type="primary" className="button-sha">
                アセスメント
              </Button>
            </Link>
          ) : null}
        </div> */}

      </div>
      <div className="m-right">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={"/management/dengon"}>
            <div className={`${getFocus("dengon")} link-item`}>
              <NotificationOutlined />
              <p>伝言</p>
            </div>
          </Link>
          <Link to={"/management/progress"}>
            <div className={`${getFocus("progress")} link-item`}>
              <LegacyIcon type="message" theme={"outlined"} twoToneColor="red" />
              <p>申送</p>
            </div>
          </Link>
          <Link to={"/management/riyousya"}>
            <div className={`${getFocus("riyousya")} link-item`}>
              <UserOutlined />
              <p>一覧</p>
            </div>
          </Link>
          {menu}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

export default connect(mapStateToProps, null)(MenuTop);
