import { connect } from "react-redux";
import {
  yougoMasterWeatherNaiyouCreator,
  yougoMasterRoomNaiyouCreator,
  yougoMasterTantoBunruiNaiyouCreator,
  yougoMasterStaffKindNaiyouCreator,
  yougoMasterDepartNaiyouCreator,
} from "../../actions/yougoMasterNaiyouActions/actionCreators";
import {
  tantoMasterFilterCreator,
  tantoMasterListKikanCreator,
} from "../../actions/tantoMasterListActions/actionCreators";
import {
  loadDutyReportCreator,
  createDutyReportCreator,
  editDutyReportCreator,
  loadStatisticalOutInCreator,
  loadStatisticalOutInByKindCreator,
  loadListPeopleOutInByKindCreator,
  resetStatus,
} from "../../actions/dutyReportActions/actionCreators";
import { loadEventPlans } from "../../actions/planActions/actionCreators";
import DutyReport from "./DutyReport";

const mapStateToProps = (state) => {
  return {
    dutyReportList: state.dutyReport.dutyReportList,
    dataCreated: state.dutyReport.dataCreated,
    dataEdited: state.dutyReport.dataEdited,
    loading: state.dutyReport.loading,
    eventPlans: state.plan.eventPlans,
    staffKind: state.yougoMasterNaiyou.yougoMasterStaffKindNaiyou,
    notifyDisplay: state.dutyReport.notifyDisplay,
    notifyContent: state.dutyReport.notifyContent,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    yougoMasterWeatherNaiyouCreator: (listname) =>
      dispatch(yougoMasterWeatherNaiyouCreator(listname)),
    yougoMasterRoomNaiyouCreator: (listname) =>
      dispatch(yougoMasterRoomNaiyouCreator(listname)),
    yougoMasterTantoBunruiNaiyouCreator: (listname) =>
      dispatch(yougoMasterTantoBunruiNaiyouCreator(listname)),
    yougoMasterStaffKindNaiyouCreator: (listname) =>
      dispatch(yougoMasterStaffKindNaiyouCreator(listname)),
    yougoMasterDepartNaiyouCreator: (listname) =>
      dispatch(yougoMasterDepartNaiyouCreator(listname)),
    loadDutyReportCreator: (parameterObj) =>
      dispatch(loadDutyReportCreator(parameterObj)),
    createDutyReportCreator: (parameterObj) =>
      dispatch(createDutyReportCreator(parameterObj)),
    loadEventPlans: (startDate, endDate) =>
      dispatch(loadEventPlans(startDate, endDate)),
    editDutyReportCreator: (parameterObj) =>
      dispatch(editDutyReportCreator(parameterObj)),
    loadStatisticalOutInCreator: (dateObj) =>
      dispatch(loadStatisticalOutInCreator(dateObj)),
    loadStatisticalOutInByKindCreator: (parameterObj) =>
      dispatch(loadStatisticalOutInByKindCreator(parameterObj)),
    loadListPeopleOutInByKindCreator: (parameterObj) =>
      dispatch(loadListPeopleOutInByKindCreator(parameterObj)),
    tantoMasterFilterCreator: (dataFilter) =>
      dispatch(tantoMasterFilterCreator(dataFilter)),
    resetStatus: () => dispatch(resetStatus()),
  };
};
export const DutyReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DutyReport);
