import { Modal } from "antd";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import {
  changeValuePlan2,
  updateKikanKyotakuPlan2,
} from "../../../../actions/kyotakuPlan2Actions/actionCreators";
import {
  checkCalendarIntoGeneral,
  checkCalendarIntoJap,
} from "../common/convertDate";
import { openNotificationWithIcon } from "../function/alert";
import jaconv from "../lib/jaconv.min";
import { formatDuration, isValidDate } from "./common";
import TimeComponent from "./timeDetails";

const listTime = [
  "1週間",
  "2週間",
  "1ヵ月",
  "3ヵ月",
  "6ヵ月",
  "8ヵ月",
  "9ヵ月",
  "10ヵ月",
  "11ヵ月",
  "12ヵ月",
  "1年",
  "1年半",
  "2年",
];
const defaultState = {
  period: {
    longKikan: null,
    shortKikan: null,
    kikan: null,
  },
  startDate: {
    longKikan: null,
    shortKikan: null,
    kikan: null,
  },
  endDate: {
    longKikan: null,
    shortKikan: null,
    kikan: null,
  },
};

class MultiTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      period: {
        longKikan: null,
        shortKikan: null,
        kikan: null,
      },
      startDate: {
        longKikan: null,
        shortKikan: null,
        kikan: null,
      },
      endDate: {
        longKikan: null,
        shortKikan: null,
        kikan: null,
      },
      values: {
        longKikan: null,
        shortKikan: null,
        kikan: null,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { settingData } = this.props;
    if (nextProps.startDate !== this.state.startDate) {
      const startDateClone = { ...this.state.startDate };
      startDateClone.longKikan = nextProps.startDate;
      startDateClone.shortKikan = nextProps.startDate;
      startDateClone.kikan = nextProps.startDate;

      const endDateClone = { ...this.state.endDate };
      endDateClone.longKikan = nextProps.endDate;
      endDateClone.shortKikan = nextProps.endDate;
      endDateClone.kikan = nextProps.endDate;

      const valuesClone = { ...this.state.values };
      valuesClone.longKikan =
        (nextProps.period ? nextProps.period + "\n" : "") +
        (isValidDate(nextProps.startDate)
          ? checkCalendarIntoJap(nextProps.startDate, settingData) + " ～\n"
          : "") +
        (isValidDate(nextProps.endDate)
          ? checkCalendarIntoJap(nextProps.endDate, settingData)
          : "");
      valuesClone.shortKikan =
        (nextProps.period ? nextProps.period + "\n" : "") +
        (isValidDate(nextProps.startDate)
          ? checkCalendarIntoJap(nextProps.startDate, settingData) + " ～\n"
          : "") +
        (isValidDate(nextProps.endDate)
          ? checkCalendarIntoJap(nextProps.endDate, settingData)
          : "");
      valuesClone.kikan =
        (nextProps.period ? nextProps.period + "\n" : "") +
        (isValidDate(nextProps.startDate)
          ? checkCalendarIntoJap(nextProps.startDate, settingData) + " ～\n"
          : "") +
        (isValidDate(nextProps.endDate)
          ? checkCalendarIntoJap(nextProps.endDate, settingData)
          : "");
      this.setState({
        startDate: startDateClone,
        startDateIp: startDateClone,
        endDate: endDateClone,
        values: valuesClone,
      });
    }
  }

  onChangeSetPeriod = (value, type) => {
    const periodClone = { ...this.state.period };
    const valuesClone = { ...this.state.values };
    const startDateType = this.state.startDate[type];
    periodClone[type] = value;
    const endDateClone = { ...this.state.endDate };
    const { settingData } = this.props;
    if (startDateType && formatDuration(value)) {
      const num = formatDuration(value).number;
      const format = formatDuration(value).format;
      if (format && format === "days") {
        endDateClone[type] = dayjs(startDateType)
          .subtract(-num, format)
          .format("YYYY-MM-DD");
      }
      else {
        endDateClone[type] = dayjs(startDateType)
          .subtract(-num, format)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }
      this.setState({
        period: periodClone,
        endDate: endDateClone,
      });
    }
    valuesClone[type] =
      (value ? value + "\n" : "") +
      (isValidDate(startDateType)
        ? checkCalendarIntoJap(startDateType, settingData) + " ～\n"
        : "") +
      (isValidDate(endDateClone[type])
        ? checkCalendarIntoJap(endDateClone[type], settingData)
        : "");
    this.setState({
      period: periodClone,
      values: valuesClone,
    });
  };

  onChangeDateStart = (date, dateString, type) => {
    const startDateClone = { ...this.state.startDate };
    const startDateIpClone = { ...this.state.startDateIp };
    const endDateClone = { ...this.state.endDate };
    const valuesClone = { ...this.state.values };
    const periodType = this.state.period[type];
    const { settingData } = this.props;
    if (periodType && formatDuration(periodType)) {
      const num = formatDuration(periodType).number;
      const format = formatDuration(periodType).format;
      startDateClone[type] = date.format("YYYY-MM-DD");
      startDateIpClone[type] = date;
      if (format && format === "days") {
        endDateClone[type] = dayjs(date)
          .subtract(-num, format)
          .format("YYYY-MM-DD");
      }
      else {
        endDateClone[type] = dayjs(date)
          .subtract(-num, format)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }
    }
    else {
      startDateClone[type] = date.format("YYYY-MM-DD");
      startDateIpClone[type] = date;
    }
    valuesClone[type] =
      (periodType ? periodType + "\n" : "") +
      (isValidDate(date)
        ? checkCalendarIntoJap(dayjs(date).format("YYYY-MM-DD"), settingData) +
        " ～\n"
        : "") +
      (isValidDate(endDateClone[type])
        ? checkCalendarIntoJap(endDateClone[type], settingData)
        : "");
    this.setState({
      startDate: startDateClone,
      startDateIp: startDateIpClone,
      endDate: endDateClone,
      endOpen: true,
      values: valuesClone,
    });
  };

  onChangeDateEnd = (date, dateString, type) => {
    const endDateClone = { ...this.state.endDate };
    const valuesClone = { ...this.state.values };
    const periodType = this.state.period[type];
    const startDateType = this.state.startDate[type];
    const { settingData } = this.props;
    endDateClone[type] = date.format("YYYY-MM-DD");
    valuesClone[type] =
      (periodType ? periodType + "\n" : "") +
      (isValidDate(startDateType)
        ? checkCalendarIntoJap(startDateType, settingData) + " ～\n"
        : "") +
      (isValidDate(date)
        ? checkCalendarIntoJap(dayjs(date).format("YYYY-MM-DD"), settingData)
        : "");
    this.setState({
      endDate: endDateClone,
      values: valuesClone,
    });
  };

  handleSetMultiTimeOk = async () => {
    const values = this.state.values;
    if (!this.props.valuesChange) {
      this.props.changeValuePlan2(true);
    }
    const { valuePlan2 } = this.props;
    // const { period, startDate, endDate } = this.state;
    // const longKikan = `${period.longKikan ? period.longKikan + "\n" : ""}${isValidDate(startDate.longKikan) ? `${startDate.longKikan} ～ ` : ""
    //   }${isValidDate(endDate.longKikan) ? endDate.longKikan : ""}`;
    // const shortKikan = `${period.shortKikan ? period.shortKikan + "\n" : ""}${isValidDate(startDate.shortKikan) ? `${startDate.shortKikan} ~ ` : ""
    //   }${isValidDate(endDate.shortKikan) ? endDate.shortKikan : ""}`;
    // const kikan = `${period.kikan ? period.kikan + "\n" : ""}${isValidDate(startDate.kikan) ? `${startDate.kikan} ~ ` : ""
    //   }${isValidDate(endDate.kikan) ? endDate.kikan : ""}`;
    this.props.handleSetMultiTimeOk();
    await this.props.updateKikanKyotakuPlan2(
      values.longKikan || null,
      values.shortKikan || null,
      values.kikan || null,
      valuePlan2
    );
    this.setState({
      period: defaultState.period,
      startDate: defaultState.startDate,
      endDate: defaultState.endDate,
    });
  };

  handleSetMultiTimeCancel = () => {
    const startDateClone = { ...this.state.startDate };
    startDateClone.longKikan = this.props.startDate;
    startDateClone.shortKikan = this.props.startDate;
    startDateClone.kikan = this.props.startDate;

    const endDateClone = { ...this.state.endDate };
    endDateClone.longKikan = this.props.endDate;
    endDateClone.shortKikan = this.props.endDate;
    endDateClone.kikan = this.props.endDate;
    this.setState({
      startDate: startDateClone,
      endDate: endDateClone,
      period: {
        longKikan: null,
        shortKikan: null,
        kikan: null,
      },
    });
    this.props.handleSetMultiTimeCancel();
  };

  clearStartDate = (type) => {
    const startDateClone = { ...this.state.startDate };
    const endDateType = this.state.endDate[type];
    const periodType = this.state.period[type];
    const valuesClone = { ...this.state.values };
    startDateClone[type] = null;
    valuesClone[type] =
      (periodType ? periodType + "\n" : "") +
      "" +
      (isValidDate(endDateType) ? endDateType : "");
    this.setState({
      startDate: startDateClone,
      values: valuesClone,
    });
  };

  clearEndDate = (type) => {
    const endDateClone = { ...this.state.endDate };
    const valuesClone = { ...this.state.values };
    const startDateType = this.state.startDate[type];
    const periodType = this.state.period[type];

    endDateClone[type] = null;
    valuesClone[type] =
      (periodType ? periodType + "\n" : "") +
      (isValidDate(startDateType) ? startDateType + " ～\n" : "") +
      "";
    this.setState({
      endDate: endDateClone,
      values: valuesClone,
    });
  };
  onChangeTextArea(value, type) {
    const endDateClone = { ...this.state.endDate };
    const periodClone = { ...this.state.period };
    const startDateClone = { ...this.state.startDate };
    const valuesClone = { ...this.state.values };
    const valueJac = jaconv["normalize"](value).split("ー").join("-");
    const { settingData } = this.props;
    if (type === "longKikan" || type === "shortKikan") {
      if (value.length > 40) {
        if (type === "longKikan") {
          openNotificationWithIcon("error", "長期期間が40文字を超えています!");
          return;
        }
        if (type === "shortKikan") {
          openNotificationWithIcon("error", "短期期間が40文字を超えています!");
          return;
        }
      }
    }
    if (!value || (value !== null && value.trim().length == 0)) {
      startDateClone[type] = null;
      endDateClone[type] = null;
      periodClone[type] = null;
      this.setState({
        startDate: startDateClone,
        endDate: endDateClone,
      });
    }
    else {
      const valueClone1 = valueJac
        .split("、")[0]
        .replace(/\n/g, " ")
        .replace(/~/g, "～")
        .split(" ")
        .filter((val) => val);
      const valueClone = valueClone1.filter((val) => val !== "～");
      let i = -1;
      if (listTime.indexOf(`${valueClone[0]}`) !== -1) {
        i = i + 1;
      }
      periodClone[type] = valueClone[i];
      this.setState({
        period: periodClone,
      });
      if (valueClone[i + 1]) {
        startDateClone[type] = checkCalendarIntoGeneral(
          valueClone[i + 1],
          settingData
        );
        this.setState({
          startDate: startDateClone,
        });
      }
      else {
        startDateClone[type] = null;
        this.setState({
          startDate: startDateClone,
        });
      }
      if (valueClone[i + 2]) {
        endDateClone[type] = checkCalendarIntoGeneral(
          valueClone[i + 2],
          settingData
        );
        this.setState({
          endDate: endDateClone,
        });
      }
      else {
        endDateClone[type] = null;
        this.setState({
          endDate: endDateClone,
        });
      }
    }
    valuesClone[type] = value;
    this.setState({
      values: valuesClone,
    });
  }

  render() {
    const { modalSetMultiTimeVisible } = this.props;
    const { period, startDate, endDate, values } = this.state;
    return (
      (<div id="modal-time">
        <Modal
          destroyOnClose={true}
          open={modalSetMultiTimeVisible}
          onOk={this.handleSetMultiTimeOk}
          onCancel={this.handleSetMultiTimeCancel}
          title="一括期間セット"
        >
          <div>
            <TimeComponent
              valueString={values.longKikan}
              timeKikan={"長期目標期間"}
              onChangeSetPeriod={(value, type) =>
                this.onChangeSetPeriod(value, type)
              }
              typeTime={"longKikan"}
              period={period.longKikan}
              onChangeDateStart={(date, dateString, value) =>
                this.onChangeDateStart(date, dateString, value)
              }
              onChangeDateEnd={(date, dateString, value) =>
                this.onChangeDateEnd(date, dateString, value)
              }
              startDate={startDate.longKikan}
              endDate={endDate.longKikan}
              clearStartDate={(type) => this.clearStartDate(type)}
              clearEndDate={(type) => this.clearEndDate(type)}
              onChangeTextArea={(value, type) =>
                this.onChangeTextArea(value, type)
              }
            />
            <TimeComponent
              valueString={values.shortKikan}
              timeKikan={"短期目標期間"}
              onChangeSetPeriod={(value, type) =>
                this.onChangeSetPeriod(value, type)
              }
              typeTime={"shortKikan"}
              period={period.shortKikan}
              onChangeDateStart={(date, dateString, value) =>
                this.onChangeDateStart(date, dateString, value)
              }
              onChangeDateEnd={(date, dateString, value) =>
                this.onChangeDateEnd(date, dateString, value)
              }
              startDate={startDate.shortKikan}
              endDate={endDate.shortKikan}
              clearStartDate={(type) => this.clearStartDate(type)}
              clearEndDate={(type) => this.clearEndDate(type)}
              onChangeTextArea={(value, type) =>
                this.onChangeTextArea(value, type)
              }
            />
            <TimeComponent
              valueString={values.kikan}
              timeKikan={"内容目標期間"}
              onChangeSetPeriod={(value, type) =>
                this.onChangeSetPeriod(value, type)
              }
              typeTime={"kikan"}
              period={period.kikan}
              onChangeDateStart={(date, dateString, value) =>
                this.onChangeDateStart(date, dateString, value)
              }
              onChangeDateEnd={(date, dateString, value) =>
                this.onChangeDateEnd(date, dateString, value)
              }
              startDate={startDate.kikan}
              endDate={endDate.kikan}
              clearStartDate={(type) => this.clearStartDate(type)}
              clearEndDate={(type) => this.clearEndDate(type)}
              onChangeTextArea={(value, type) =>
                this.onChangeTextArea(value, type)
              }
            />
          </div>
        </Modal>
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kyotakuPlan2: state.kyotakuPlan2.kyotakuPlan2,
    kyotakuPlan2Rows: state.kyotakuPlan2.kyotakuPlan2Rows,
    notifyDisplay: state.kyotakuPlan2.notifyDisplay,
    notifyContent: state.kyotakuPlan2.notifyContent,
    valuesChange: state.kyotakuPlan2.valuesChange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateKikanKyotakuPlan2: (longKikan, shortKikan, kikan, planDate) =>
      dispatch(updateKikanKyotakuPlan2(longKikan, shortKikan, kikan, planDate)),
    changeValuePlan2: (type) => dispatch(changeValuePlan2(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiTime);
