/* eslint-disable no-nested-ternary */
import { Table } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Component } from "react";
import { ConstSet } from "../../../common/configs/constset";
import { getDateInMonth } from "../../../common/function_common/functionCommon";
import { CommandButtons } from "./CommandButtons";

export class PlanTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRowKeys: [],
    };
  }

  handleExpand(expanded, record) {
    this.setState({
      expandedRowKeys: expanded ? [record.date] : [],
    });
  }

  render() {
    const { data, mode, selectedMonth } = this.props;
    const columns = [
      {
        title: "Date",
        dataIndex: "date",
        width: "90px",
        align: "center",
      },
      {
        title: "Day",
        dataIndex: "day",
        width: "46px",
        align: "center",
      },
      {
        title: "Content",
        dataIndex: "content",
      },
    ];
    const tableData = [],
      year = selectedMonth.toDate().getFullYear(),
      month = selectedMonth.toDate().getMonth(),
      numberOfDays = new Date(
        selectedMonth.toDate().getFullYear(),
        selectedMonth.toDate().getMonth() + 1,
        0
      ).getDate();
    for (let i = 1; i <= numberOfDays; i++) {
      const dateString = dayjs(new Date(year, month, i)).format(
        ConstSet.DATE_FORMAT
      );
      if (mode === "event") {
        let content = "",
          eventPlanId = null;
        if (data) {
          const item = _.find(data, (i) => i.date === dateString);
          if (item) {
            content = item.event;
            eventPlanId = item.eventPlanId.toString();
          }
        }
        tableData.push({
          date: dateString,
          day: getDateInMonth(year, month, i),
          eventPlanId,
          content,
          event: content,
        });
      }
      else if (this.props.riyousyaId == null) {
        let content = "";
        if (data) {
          let listData = _.filter(data, (i) => i.date === dateString);
          if (listData && listData.length > 0) {
            listData = listData.sort((a, b) => {
              if (a.furigana < b.furigana) return -1;
              if (a.furigana > b.furigana) return 1;
              return 0;
            });
            content = (
              <ul style={{ marginTop: "0", marginBottom: "0" }}>
                {listData.map((item) => (
                  <li key={item.riyousyaPlanId}>
                    {item.riyousyaName}
                    {"："}
                    {item.yotei}
                    {item.yotei && item.sonota ? "、" : ""}
                    {item.sonota}
                  </li>
                ))}
              </ul>
            );
          }
        }
        tableData.push({
          date: dateString,
          day: getDateInMonth(year, month, i),
          content,
        });
      }
      else {
        let riyousyaPlanId = null,
          yotei = "",
          sonota = "",
          content = "";
        if (data) {
          const userPlan = _.find(data, (i) => i.date === dateString);
          if (userPlan) {
            riyousyaPlanId = userPlan.riyousyaPlanId;
            yotei = userPlan.yotei;
            sonota = userPlan.sonota;
            content = (
              <div>
                {userPlan.yotei}
                {userPlan.yotei && userPlan.sonota ? "、" : ""}
                {userPlan.sonota}
              </div>
            );
          }
        }
        tableData.push({
          date: dateString,
          day: getDateInMonth(year, month, i),
          riyousyaId: this.props.riyousyaId,
          riyousyaPlanId,
          yotei,
          sonota,
          content,
        });
      }
    }
    return (
      <div>
        {mode === "event" ? (
          <Table
            className={"event-plan-table"}
            columns={columns}
            dataSource={tableData}
            size="small"
            rowKey={"date"}
            pagination={false}
            showHeader={false}
            expandedRowRender={(record) => {
              return (
                <CommandButtons
                  mobile={"mobile"}
                  selectedPlans={[record]}
                  handleInputPlan={(selectedPlans) =>
                    this.props.handleInputEventPlan(selectedPlans)
                  }
                  handleDeletePlan={(eventPlanIds) =>
                    this.props.handleDeleteEventPlan(eventPlanIds)
                  }
                  yougoMasterEditState={(index) =>
                    this.props.yougoMasterEditState(index)
                  }
                  yougoMasterEditCancel={(index) =>
                    this.props.yougoMasterEditCancel(index)
                  }
                  yougoMasterEditSave={(naiyou) =>
                    this.props.yougoMasterEditSave(naiyou)
                  }
                  yougoMasterNaiyou={this.props.yougoMasterNaiyou}
                />
              );
            }}
            expandRowByClick={true}
            expandedRowKeys={this.state.expandedRowKeys}
            onExpand={(expanded, record) => this.handleExpand(expanded, record)}
          />
        ) : this.props.riyousyaId == null ? (
          <Table
            columns={columns}
            dataSource={tableData}
            size="small"
            rowKey={"date"}
            pagination={false}
            showHeader={false}
          />
        ) : (
          <Table
            className={"event-plan-table"}
            columns={columns}
            dataSource={tableData}
            size="small"
            rowKey={"date"}
            pagination={false}
            showHeader={false}
            expandedRowRender={(record) => {
              return (
                <CommandButtons
                  mobile={"mobile"}
                  riyousyaId={this.props.riyousyaId}
                  date={record.date}
                  selectedPlans={[record]}
                  handleInputPlan={(selectedPlans) =>
                    this.props.handleInputUserPlan(selectedPlans)
                  }
                  handleDeletePlan={(riyousyaPlanIds) =>
                    this.props.handleDeleteUserPlan(riyousyaPlanIds)
                  }
                  yougoMasterEditState={(index) =>
                    this.props.yougoMasterEditState(index)
                  }
                  yougoMasterEditCancel={(index) =>
                    this.props.yougoMasterEditCancel(index)
                  }
                  yougoMasterEditSave={(naiyou) =>
                    this.props.yougoMasterEditSave(naiyou)
                  }
                  yougoMasterNaiyou={this.props.yougoMasterNaiyou}
                />
              );
            }}
            expandRowByClick={true}
            expandedRowKeys={this.state.expandedRowKeys}
            onExpand={(expanded, record) => this.handleExpand(expanded, record)}
          />
        )}
      </div>
    );
  }
}
