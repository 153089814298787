import $ from "jquery";
import _ from "lodash";

/**
 *
doukyof=1 同居
kaigof=1 主介護者、kaigof=2 副介護者
keypersonf=1 キーパーソン
moshikomif=1 申込者
hoshoninf=1 保証人
 *
 * @param {*} riyouConcern
 */
function displayRiyouConcernTitle(riyouConcern) {
  let result = "";

  if (riyouConcern && riyouConcern.doukyof && riyouConcern.doukyof === 1) {
    result = "同居";
  }

  if (riyouConcern && riyouConcern.kaigof && riyouConcern.kaigof === 1) {
    if (result) {
      result = result + " / 主介護者";
    }
    else {
      result = "主介護者";
    }
  }

  if (riyouConcern && riyouConcern.kaigof && riyouConcern.kaigof === 2) {
    if (result) {
      result = result + " / 副介護者";
    }
    else {
      result = "副介護者";
    }
  }

  if (
    riyouConcern &&
    riyouConcern.keypersonf &&
    riyouConcern.keypersonf === 1
  ) {
    if (result) {
      result = result + " / キーパーソン";
    }
    else {
      result = "キーパーソン";
    }
  }

  if (
    riyouConcern &&
    riyouConcern.moshikomif &&
    riyouConcern.moshikomif === 1
  ) {
    if (result) {
      result = result + " / 申込者";
    }
    else {
      result = "申込者";
    }
  }

  if (riyouConcern && riyouConcern.hoshoninf && riyouConcern.hoshoninf === 1) {
    if (result) {
      result = result + " / 保証人";
    }
    else {
      result = "保証人";
    }
  }

  if (riyouConcern && riyouConcern.seikyuf && riyouConcern.seikyuf === 1) {
    if (result) {
      result = result + " / 請求先";
    }
    else {
      result = "請求先";
    }
  }

  return result;
}

function resizeFilePopup() {
  const parrentWidth = $("#object_popup").width();
  const drawrHeight = $(".pljp-drawer-wrapper-body").height();
  const drawrHeaderHeight = $(".pljp-drawer-header").height();

  $("#object_popup").children("div").width(parrentWidth);
  $("#object_popup")
    .children("div")
    .height(drawrHeight - drawrHeaderHeight - 48);
}

/**
 *
 * @param {1950-04-05} dateSting
 */
function displayJpDateKanji(dateSting) {
  let result = "";
  if (dateSting && dateSting !== "0001-01-01") {
    const date = new Date(dateSting);
    // result = date.toLocaleDateString("ja-JP-u-ca-japanese", { era: "long", year: "2-digit", month: "2-digit", day: "2-digit" });
    result = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
      era: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  }

  return result;
}

/**
 * 和暦→西暦
 */
function displaySeireki(gengo, year, month, day) {
  if (gengo && year && month && day) {
    year = parseInt(year);
    month = parseInt(month);
    day = parseInt(day);

    if (gengo == "令和") {
      year += 2018;
    }
    else if (gengo == "平成") {
      year += 1988;
    }
    else if (gengo == "昭和") {
      year += 1925;
    }
    else if (gengo == "大正") {
      year += 1911;
    }
    else if (gengo == "明治") {
      year += 1867;
    }

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return year + "-" + month + "-" + day;
  }
  return "";

}

/**
 *
 *
 * @param {*} record
 */
function displaySystemName(record) {
  let result = "";

  if (record && record.riyouHolding && record.riyouHolding.length > 0) {
    record.riyouHolding.forEach((item1) => {
      if (item1.systemControl && item1.systemControl.length > 0) {
        let exSystemName = "";

        item1.systemControl.forEach((item2) => {
          if (checkSystem(item2.systemName)) {
            exSystemName = exSystemName
              ? exSystemName + "," + item2.name
              : item2.name;
          }
        });

        result = result ? result + "," + exSystemName : exSystemName;
      }
    });
  }

  return result;
}

function checkSystem(systemName) {
  const systems = [
    "kyotaku",
    "helper",
    "hpsien",
    "jiritusien",
    "kango",
    "syoukibo",
    "dayservice",
    "rehabili",
    "nyusho",
    "short",
    "shortrouken",
    "ryoyo",
    "rouken",
    "group",
    "tokutei",
    "seikyu",
    "fukugo",
    "junkai",
    "yakan",
    "visitreha",
    "rehabili",
    "kyotaku",
    "yobou",
    "syoukibo",
    "tokutei",
    "group",
    "short",
    "shortrouken",
    "rouken",
    "nyusho",
    "ryoyo",
    "jiritusien",
    "seikyu",
    "houkatu",
  ];

  return systems.includes(systemName);
}

/**
 *
 * @param {*} is_dis
 * @param {*} title
 * @param {*} index
 */
function disRiyouBunruiTekiyou(is_dis, title, index) {
  let result = null;

  if (is_dis) {
    const str_is_dis = is_dis.toString().split(";")[index];

    if (str_is_dis !== "none") {
      if (title) {
        const str_title = title.toString().split(",")[index];

        if (str_title) {
          const str_title_extract = str_title.toString().split("=");

          if (str_title_extract[1]) {
            result = str_title_extract[1];
          }
        }
      }
    }
  }

  return result;
}

/**
 *
 * officeAuth = 1, display all
 * officeAuth = 0 or null display record menu only
 *
 */
function checkMenuDis() {
  const menu = JSON.parse(localStorage.getItem("menu"));
  let current = "record0";
  if (menu && menu.officeAuth === "1") {
    current = localStorage.getItem("currentmenu")
      ? localStorage.getItem("currentmenu")
      : "record0";
  }

  return current;
}

/**
 * Convert Array String ["aaa", "bbb", "ccc"...]
 * TO
 * Array Object [{key: 0, naiyou: "aaa"}...]
 *
 * @param {} data
 */
function convertArrStrToArrObj(data) {
  const tmp = [];

  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i] !== "") {
        tmp.push({ key: i, naiyou: data[i] });
      }
    }
  }

  if (tmp.length > 0) {
    return tmp;
  }
  return data;

}

function convertSortArr(data) {
  const tmp = [];

  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      tmp.push(data[i].naiyou);
    }
    return tmp.join(";");
  }
}

/**
 *
 * @param {*} sm1
 * @param {*} sm2
 * @param {*} sm3
 * @param {*} sm4
 * @param {*} sm5
 */
function convertStrShokijiBubun(sm1, sm2, sm3, sm4, sm5) {
  let result = "";
  result = sm1;
  if (result && sm2) {
    result = result + "," + sm2;
  }
  else if (sm2) {
    result = sm2;
  }

  if (result && sm3) {
    result = result + "," + sm3;
  }
  else if (sm3) {
    result = sm3;
  }

  if (result && sm4) {
    result = result + "," + sm4;
  }
  else if (sm4) {
    result = sm4;
  }

  if (result && sm5) {
    result = result + "," + sm5;
  }
  else if (sm5) {
    result = sm5;
  }

  return result;
}

/**
 *
 * @param {*} record
 * @param {*} mulYoteiMode
 * @param {*} mulDelMode
 */
function isDisableKiroku(record, mulYoteiMode, mulDelMode) {
  let result = false;

  if (record) {
    if (mulYoteiMode) {
      if (!record.planType) {
        result = true;
      }
    }
    else if (mulDelMode) {
      if (
        !record.vitalId &&
        !record.mealId &&
        !record.suibunId &&
        !record.haisetuId &&
        !record.nyuyokuId &&
        !record.fukuyakuId &&
        !record.kaigoId &&
        !record.tokkiId &&
        !record.filemanagementId
      ) {
        result = true;
      }
    }
  }

  return result;
}

/**
 * this function is used when checkbox on left side of multiple input modal ticked
 *
 * @param {*} objValue
 * @param {*} key
 */
function ikkatsuCheckboxReset(objValue, key) {
  const tmp = _.cloneDeep(objValue);

  if (tmp[key]) {
    tmp[key] = null;
  }

  return tmp;
}

/**
 *
 * @param {*} arrCheckbox
 */
function checkIkkatsuOkBtn(arrCheckbox) {
  for (let i = 0; i < arrCheckbox.length; i++) {
    if (arrCheckbox[i]) {
      return false;
    }
  }

  return true;
}

/**
 * riyouState 最新の値
 *
 */
function riyouStateRetrive(listRiyouState) {
  const result = [];

  let netakiri = "";
  let ninchi = "";
  let seishinshoukai = "";
  if (listRiyouState && listRiyouState.length > 0) {
    let netakiriId = 0;
    let ninchiId = 0;
    let seishinshoukaiId = 0;
    for (let i = 0; i < listRiyouState.length; i++) {
      if (listRiyouState[i].type === "寝たきり度") {
        if (listRiyouState[i].id > netakiriId) {
          netakiri = listRiyouState[i].state;
          netakiriId = listRiyouState[i].id;
        }
      }

      if (listRiyouState[i].type === "認知度") {
        if (listRiyouState[i].id > ninchiId) {
          ninchi = listRiyouState[i].state;
          ninchiId = listRiyouState[i].id;
        }
      }

      if (listRiyouState[i].type === "精神障害度") {
        if (listRiyouState[i].id > seishinshoukaiId) {
          seishinshoukai = listRiyouState[i].state;
          seishinshoukaiId = listRiyouState[i].id;
        }
      }
    }
  }

  result[0] = netakiri;
  result[1] = ninchi;
  result[2] = seishinshoukai;

  return result;
}

export {
  checkIkkatsuOkBtn, checkMenuDis,
  convertArrStrToArrObj,
  convertSortArr,
  convertStrShokijiBubun, disRiyouBunruiTekiyou, displayJpDateKanji, displayRiyouConcernTitle, displaySeireki,
  displaySystemName, ikkatsuCheckboxReset, isDisableKiroku, resizeFilePopup, riyouStateRetrive
};

