import React from "react";
import { Button, Popover, Table } from "antd";
import { convertArrStrToArrObj } from "../../../common/function_common/functionDisplay";
import _ from "lodash";
import TextInputArea from "./TextInputArea";
import TextInput from "./TextInput";
import KaigoHokenMasterModal from "./KaigoHokenMasterModal";
import ServiceRyuruiMasterModal from "./ServiceRyuruiMasterModal";

export default class TextAreaSelectNewService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    // bind place
  }

  /**
   * trigger dirrectly from input
   *
   */
  select = (record) => {
    const { value } = this.props;
    let tpm = "";

    if (record && record.naiyou) {
      if (value) {
        tpm = value + "、" + record.naiyou;
      } else {
        tpm = record.naiyou;
      }
    }

    this.triggerChange(tpm);
  };

  /**
   * triiger from table select row (yougo_master)
   *
   */
  handleSelectChange = (value) => {
    this.triggerChange(value);
  };

  /**
   * Should provide an event to pass value to Form.
   *
   */
  triggerChange = (changedValue) => {
    let changedValueTo;
    if (changedValue) {
      changedValueTo = changedValue.replace(/,/g, ";");
    }
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValueTo);
    }
  };

  handleOk = () => {
    // reset
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.triggerChange("");

    // reset
    this.setState({
      visible: false,
    });
  };

  handleOpenTable = () => {
    // open modal
    this.setState({
      visible: true,
    });
  };

  onClickCellEvent = (record) => {
    this.triggerChange(record.naiyou);

    this.setState({
      rowActive: record.id,
    });
  };

  handleModalKaigo = () => {
    this.setState({
      visible: true,
    });
  };

  onSaveServiceSyurui = (selectedRowKeys) => {
    if (selectedRowKeys) {
      this.triggerChange(selectedRowKeys);

      this.setState({
        visible: false,
      });
    } else if (selectedRowKeys == "") {
      this.triggerChange(selectedRowKeys);

      this.setState({
        visible: false,
      });
    }
  };

  onCancelServiceSyurui = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { value, dataSelect, style } = this.props;

    const columns = [
      {
        title: "naiyou",
        dataIndex: "naiyou",
        width: 450,
        key: "naiyou",
        render: (textEx, record, index) => record.naiyou,
        onCell: (record) => {
          return {
            onClick: (event) => {
              this.onClickCellEvent(record);
            }, // click row
          };
        },
      },
    ];

    // convert Array(String) to Array(Object)
    let dataTable1 = convertArrStrToArrObj(dataSelect);
    const content = (
      <Table
        bordered
        columns={columns}
        dataSource={dataTable1}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 240 }}
        showHeader={false}
        size={"small"}
        rowKey={(record, index) => index}
      />
    );

    return (
      <div className="text-area-select-ri-page" style={{ marginTop: 4 }}>
        <ServiceRyuruiMasterModal
          visible={this.state.visible}
          value={value}
          onHide={this.onCancelServiceSyurui}
          onSave={this.onSaveServiceSyurui}
        />
        <TextInput
          rows={1}
          className="text-area-select-text"
          value={value}
          onChange={this.handleSelectChange}
          readOnly
          style={style}
          // style={{ background: 'white', color: 'rgba(0, 0, 0, 0.65)'}}
          // style={{width: 130}}
        />
        <span className="text-area-select-btn">
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            onClick={this.handleModalKaigo}
          >
            入力
          </Button>
        </span>
      </div>
    );
  }
}
