import { Form } from "@ant-design/compatible";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Radio, Row } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import React from "react";
import { ConstSet } from "../../../common/configs/constset";
import {
  rangePicker,
  setValueIsKeep,
  setValueLocalstorage,
} from "../../../common/function_common/functionCommon";
import TeikyouSelect from "./TeikyouSelect";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export default class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    // bind place
    this.canMoveDatesToRight = true;
  }

  onChangeRanger = (dates, dateString) => {
    if (!dates || !Array.isArray(dates) || dates.length === 0) return;

    const startDate = dayjs(dates[0], ConstSet.DATE_FORMAT).format(
      ConstSet.DATE_FORMAT
    );
    const endDate = dayjs(dates[1], ConstSet.DATE_FORMAT).format(
      ConstSet.DATE_FORMAT
    );

    rangePicker.compareRangeMonths(
      startDate,
      endDate,
      this.handleMoveDatesToRight
    );

    this.props.rangeDateChange(dateString);
  };

  onChangeInputMode = (e) => {
    this.props.inputModeHandleChange(e.target.checked);
  };

  onChangeMulInputAllMode = (e) => {
    this.props.MulInputModeHandleChange(e.target.checked);
  };

  handleMulInputModal = (type) => {
    // type = 1 一括入力、type = 2 予定一括入力、type = 3 一括削除
    this.props.handleMulInputModal(type);
  };

  onChangeMealType = (e) => {
    this.props.handleMealType(e.target.value);
    setValueIsKeep("mealType", e.target.value);
  };

  onChangeHaisetuKubun = (e) => {
    this.props.handleHaisetuKubun(e.target.value);
    setValueLocalstorage("haisetuType", e.target.value);
  };

  getHandleChangeTeikyou = (value) => {
    this.props.handleTeikyou(value);
  };

  toggleOpenLongText = () => {
    this.props.toggleOpenLongText();
  };

  // workaround (not cheat code), any better ideas? replaces the block below
  handleOpenRange = (isOpen) => {
    rangePicker.showDatesInRightSide(
      isOpen,
      this.canMoveDatesToRight,
      this.handleMoveDatesToRight
    );
  };

  handleMoveDatesToRight = (value) => {
    this.canMoveDatesToRight = value;
  };

  render() {
    const {
      fromDate,
      toDate,
      tabActive,
      inputMode,
      mulInputAllMode,
      mealType,
      haisetuKubun,
      nyutaishoFilter,
      kaigoNaiyouCode,
      isDisLongText,
    } = this.props;

    let settingTeikyou = null;

    if (nyutaishoFilter) {
      settingTeikyou =
        nyutaishoFilter && nyutaishoFilter.teikyou
          ? nyutaishoFilter.teikyou
          : null;
    }

    return (
      (<Row className="ru-filter">
        <Col xl={15} style={{ display: "flex" }}>
          <RangePicker
            allowClear={false}
            inputReadOnly
            readOnly
            defaultValue={[
              dayjs(fromDate, ConstSet.DATE_FORMAT),
              dayjs(toDate, ConstSet.DATE_FORMAT),
            ]}
            format={dateFormat}
            onChange={this.onChangeRanger}
            className="ru-datepicker"
            popupClassName="kiroku-filter__datepicker-range-dropdown"
            onOpenChange={this.handleOpenRange}
          />

          <FormItem style={{ marginLeft: "16px" }}>
            {tabActive !== "利用者" ? (
              <Checkbox onClick={this.onChangeInputMode} checked={inputMode}>
                入力
              </Checkbox>
            ) : null}

            {tabActive !== "利用者" &&
              tabActive !== "特記" &&
              tabActive !== "画像等" ?
              (
                <Checkbox
                  onClick={this.onChangeMulInputAllMode}
                  checked={mulInputAllMode}
                  style={{ marginRight: "10px" }}
                >
                  一括入力モード
                </Checkbox>
              ) : null}

            {mulInputAllMode &&
              tabActive !== "利用者" &&
              tabActive !== "特記" &&
              tabActive !== "画像等" ?
              (
                <span style={{ marginRight: "10px" }}>
                  <Button
                    style={{ backgroundColor: "red", color: "white" }}
                    onClick={this.handleMulInputModal.bind(this, 1)}
                    disabled={this.props.disabledIkkatsuBtn}
                  >
                    一括入力
                  </Button>

                  <Button
                    style={{ backgroundColor: "gray", color: "white" }}
                    onClick={this.handleMulInputModal.bind(this, 3)}
                    disabled={this.props.disabledIkkatsuBtn}
                  >
                    一括削除
                  </Button>
                </span>
              ) : null}
          </FormItem>
        </Col>
        <Col xl={9} style={{ display: "flex" }}>
          {/* {
                        mulInputAllMode
                            || mulDelMode
                            ?
                            (
                                tabActive !== '利用者' && tabActive !== '特記' && tabActive !== '画像等'
                                    ?
                                    < FormItem style={{ marginLeft: '16px' }}>
                                        <Button type="primary" danger onClick={this.handleMulInputModal} disabled={this.props.disabledIkkatsuBtn}>変更</Button>
                                    </FormItem>
                                    :
                                    null
                            )
                            :
                            null
                    } */}

          {
            /* tab 食事摂取 */
            tabActive === "食事摂取" ? (
              <FormItem>
                <RadioGroup onChange={this.onChangeMealType} value={mealType}>
                  <Radio value={"全表示"}>全表示</Radio>
                  <Radio value={"朝"}>朝</Radio>
                  <Radio value={"昼"}>昼</Radio>
                  <Radio value={"夕"}>夕</Radio>
                  <Radio value={"間食"}>間食</Radio>
                  <Radio value={"経管栄養"}>経管栄養</Radio>
                </RadioGroup>
              </FormItem>
            ) : null
          }

          {
            /* tab 食事摂取 */
            tabActive === "排泄" ? (
              <FormItem>
                <RadioGroup
                  onChange={this.onChangeHaisetuKubun}
                  value={haisetuKubun}
                >
                  <Radio value={"両方"}>両方</Radio>
                  <Radio value={"排尿"}>排尿</Radio>
                  <Radio value={"排便"}>排便</Radio>
                </RadioGroup>
              </FormItem>
            ) : null
          }

          {
            /* tab バイタル 水分摂取 排泄 入浴 服薬 提供記録 特記 画像等  */
            tabActive === "提供記録" ? (
              <FormItem label="提供内容" style={{ display: "flex" }}>
                <TeikyouSelect
                  settingTeikyou={settingTeikyou}
                  handleChangeTeikyou={(value) =>
                    this.getHandleChangeTeikyou(value)
                  }
                  kaigoNaiyouCode={kaigoNaiyouCode}
                />
              </FormItem>
            ) : null
          }

          <FormItem className="sa-f-item-l">
            {!inputMode && !mulInputAllMode ? (
              <Button
                type="primary"
                onClick={this.toggleOpenLongText}
                style={{ marginLeft: "8px" }}
              >
                {isDisLongText ? (
                  <span>
                    <MinusOutlined /> 格納
                  </span>
                ) : (
                  <span>
                    <PlusOutlined /> 展開
                  </span>
                )}
              </Button>
            ) : null}
          </FormItem>
        </Col>
      </Row>)
    );
  }
}
