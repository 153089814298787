import { Form } from "@ant-design/compatible";
import { MinusSquareOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, Modal, Row, Spin, TimePicker } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import TantoInput from "../../../common/component/control/TantoInput";
import { ConstSet } from "../../../common/configs/constset";
import { getValueLocalstorage } from "../../../common/function_common/functionCommon";
import { parseAttendance } from "../../../common/function_common/tantoKaigiAttendance";
import CareTermDictModal from "../UI/CareTermDictModal";
import KeikaKirokuModal from "../UI/KeikaKirokuModal";
import StaffMeetingModal from "../UI/StaffMeetingModal";
import SettingSelect from "./UI/SettingSelect";
dayjs.extend(dayjsPluginUTC);

class KeikaKirokuInputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      careTermVisible: false,
      keikaKirokuVisible: false,
      staffMeetingVisible: false,
      visibleConfirm: false,
      linkingCareTerm: "",
      field: "",
      isChangedData: false,
      planDateOpen: false,
      CheckKirokuDate: false,
    };
  }

  handleGoBack = (e) => {
    e.preventDefault();

    if (this.props.form.isFieldsTouched() || this.state.isChangedData) {
      this.setState({ visibleConfirm: true });
    }
    else {
      this.props.history.push(`/document-list/${this.props.riyousyaId}`);
    }
  };

  handleSave = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      this.props.regData(err, values);
      this.setState({ isChangedData: false });
    });
  };

  handleSaveAndNewTab = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      this.props.regDataAndCheck(err, values);
      this.setState({ isChangedData: false });
    });
  };

  hideKeikaKirokuModal() {
    this.setState({
      keikaKirokuVisible: false,
    });
  }

  showKeikaKirokuModal() {
    this.setState({
      keikaKirokuVisible: true,
    });
  }

  handleSelectCareTerm(data) {
    this.hideModalCareTerm();

    if (data) {
      const { field } = this.state;
      const preValue = this.props.form.getFieldValue(field);
      const value = {};
      value[field] = _.isEmpty(preValue) ? data : preValue + "、" + data;
      this.props.form.setFieldsValue(value);
      this.setState({ field: "", isChangedData: true });
    }
  }

  handleSelectStaffMeeting(data) {
    if (data && data.id) {
      let naiyou = "";
      let kaisaiDate = "";
      const attendanceString = parseAttendance(data.attendance);
      if (data.kaisaiDate) {
        kaisaiDate = dayjs(data.kaisaiDate).format("YYYY/MM/DD");
      }
      naiyou +=
        "【開催日】" + kaisaiDate + "   " + (data.kaisaiTime || "") + "\n";
      attendanceString
        ? (naiyou += "【出席者】\n" + attendanceString + "\n")
        : "";
      data.koumoku ? (naiyou += "【検討項目】\n" + data.koumoku + "\n") : "";
      data.naiyou ? (naiyou += "【検討内容】\n" + data.naiyou + "\n") : "";
      data.keturon ? (naiyou += "【結論】\n" + data.keturon + "\n") : "";
      data.kadai ? (naiyou += "【残された課題】\n" + data.kadai + "\n") : "";
      this.props.form.setFieldsValue({ naiyou });
      this.setState({ isChangedData: true });
    }
  }

  handleSelectKeikaKiroku(data) {
    if (data) {
      this.props.form.setFieldsValue({
        naiyou: data.naiyou,
        sakuseisya_handan: data.sakuseisyaHandan,
        riyousya_kangae: data.riyousyaKangae,
      });
      this.setState({ isChangedData: true });
    }
  }

  hideModalCareTerm() {
    this.setState({ linkingCareTerm: "", careTermVisible: false });
  }

  showModalCareTerm(field, linkingCareTerm) {
    this.setState({
      linkingCareTerm,
      field,
      careTermVisible: true,
    });
  }

  showStaffMeetingModal() {
    this.setState({ staffMeetingVisible: true });
  }

  hideStaffMeetingModal() {
    this.setState({ staffMeetingVisible: false });
  }

  closeGoBackConfirm() {
    this.setState({ visibleConfirm: false });
  }

  handleConfirmGoBack() {
    this.props.history.push(`/document-list/${this.props.riyousyaId}`);
  }

  clearPlanDate = () => {
    this.props.form.setFieldsValue({
      houmon_date: null,
    });
    this.setState({
      planDateOpen: false,
    });
  };

  openChangeplanDate = (open) => {
    this.setState({ planDateOpen: open });
  };

  renderHeader() {
    return (
      <Row className={"submenu fixed-header"} style={{ display: "flex" }}>
        <Col span={6} className={"sm-c-left"}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【施設経過記録】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
        <Col
          style={{ paddingTop: 10 }}
          span={6}
          className="progress-record-button"
        >
          <Button onClick={() => this.showKeikaKirokuModal()}>
            前回経過記録を参照
          </Button>
        </Col>
        <Col span={12} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <Link to={"/"} onClick={this.handleSave}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{ display: "block", fontWeight: "bold", color: "red" }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </Link>
          {this.props.keikaKirokuId !== "new" ? (
            <Link to={"/"} onClick={this.handleSaveAndNewTab}>
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          ) : null}
        </Col>
      </Row>
    );
  }

  renderFooterMobile() {
    return (
      <Row className={"submenu fixed-footer"}>
        <Col span={24} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <Link to={"/"} onClick={this.handleSave}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{
                  display: "block",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </Link>
          {this.props.keikaKirokuId !== "new" ? (
            <Link to={"/"} id={"checkBtn"} onClick={this.handleSaveAndNewTab}>
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          ) : null}
        </Col>
      </Row>
    );
  }

  renderHeaderMobile() {
    return (
      <Row className={"submenu fixed-header"}>
        <Col span={24} className={"sm-c-left"} style={{ color: "white" }}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【施設経過記録】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
      </Row>
    );
  }

  handlekirokuDate = () => {
    this.setState({
      CheckKirokuDate: true,
    });
  };

  renderContent() {
    const { form, kirokuKubuns, soudanNaiyous } = this.props;
    const { getFieldDecorator } = form;

    return (
      (<div
        className="main"
        style={{ maxWidth: "1081px", margin: "0px auto" }}
      >
        {/* Buttons */}
        <div className="progress-record-buttons">
          {/* Top */}
          <Row
            style={{ marginBottom: 15 }}
            className="progress-record-bottons__top"
          >
            <Col md={24} lg={6} className="progress-record-buttons__control1">
              <span className="text-bold">記録日</span>
              {getFieldDecorator("kiroku_date")(
                <DatePicker onChange={(date) => this.handlekirokuDate(date)} />
              )}
            </Col>
            <Col md={24} lg={6} className="progress-record-buttons__control2">
              <span className="text-bold">時間</span>
              {getFieldDecorator("kiroku_time")(
                <TimePicker
                  showNow={false}
                  needConfirm={false}
                  format={ConstSet.TIME_FORMAT} />
              )}
            </Col>
            <Col md={24} lg={6} className="progress-record-buttons__control3">
              <span className="text-bold">記録区分</span>
              {getFieldDecorator("kiroku_kubun")(
                <SettingSelect
                  style={{ display: "inline-block" }}
                  containerId="input-keika-kiroku-page"
                  data={kirokuKubuns}
                />
              )}
            </Col>
            <Col md={24} lg={6} className="progress-record-buttons__control4">
              <span className="text-bold">相談内容</span>
              {getFieldDecorator("soudan_naiyou")(
                <SettingSelect
                  style={{ display: "inline-block" }}
                  containerId="input-keika-kiroku-page"
                  data={soudanNaiyous}
                />
              )}
            </Col>
          </Row>

          {/* Bottom */}
          <Row className="progress-record-bottons__bottom">
            <Col md={24} lg={6} className="progress-record-buttons__control5">
              <span className="text-bold">訪問日</span>
              {getFieldDecorator("houmon_date")(
                <DatePicker
                  open={this.state.planDateOpen}
                  onOpenChange={this.openChangeplanDate}
                  popupClassName="date-picker-new-option"
                  renderExtraFooter={() => (
                    <div>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => this.clearPlanDate()}
                      >
                        クリア
                      </span>
                    </div>
                  )}
                />
              )}
            </Col>
            <Col md={24} lg={9} className="progress-record-buttons__control6">
              <span className="text-bold">計画作成者</span>
              {getFieldDecorator("plan_tanto_id")(
                <TantoInput
                  allowBlank={true}
                  style={{ display: "inline-block" }}
                  containerId={"input-keika-kiroku-page"}
                />
              )}
            </Col>
            <Col md={24} lg={9} className="progress-record-buttons__control7">
              <span className="text-bold">記録作成者</span>
              {getFieldDecorator("kiroku_tanto_id")(
                <TantoInput
                  allowBlank
                  style={{ display: "inline-block" }}
                  containerId={"input-keika-kiroku-page"}
                />
              )}
            </Col>
          </Row>
        </div>
        <Button
          type="primary"
          onClick={() => this.showKeikaKirokuModal()}
          className="progress-record-button-dialog"
        >
          前回経過記録を参照
        </Button>
        {/* Form */}
        <div className="progress-record-form">
          <Row gutter={10}>
            <Col span={24} lg={14} xl={15}>
              <div className="progress-record-form__naiyou">
                <div className="progress-record-form__naiyou-title">
                  <p className="text-bold">内容</p>
                  <div className="progress-record-form__naiyou-buttons">
                    <Button
                      type="primary"
                      danger
                      onClick={() => this.showStaffMeetingModal()}
                      style={{ marginRight: 5 }}
                    >
                      担当者会議を引用する
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => this.showModalCareTerm("naiyou", "内容")}
                    >
                      用語
                    </Button>
                  </div>
                </div>
                {getFieldDecorator("naiyou")(<Input.TextArea rows={11} />)}
              </div>
            </Col>
            <Col span={24} lg={10} xl={9}>
              <Row gutter={10}>
                <Col span={24} md={12} lg={24}>
                  <div className="progress-record-form__handan">
                    <div className="progress-record-form__handan-title">
                      <p className="text-bold">
                        関係機関・居宅サービス計画作成者の判断
                      </p>
                      <Button
                        type="primary"
                        onClick={() =>
                          this.showModalCareTerm("sakuseisya_handan", "判断")
                        }
                      >
                        用語
                      </Button>
                    </div>
                    {getFieldDecorator("sakuseisya_handan")(
                      <Input.TextArea rows={4} />
                    )}
                  </div>
                </Col>
                <Col span={24} md={12} lg={24}>
                  <div className="progress-record-form__kangae">
                    <div className="progress-record-form__kangae-title">
                      <p className="text-bold">利用者・家族の考え方</p>
                      <Button
                        type="primary"
                        onClick={() =>
                          this.showModalCareTerm("riyousya_kangae", "考え方")
                        }
                      >
                        用語
                      </Button>
                    </div>
                    {getFieldDecorator("riyousya_kangae")(
                      <Input.TextArea rows={4} />
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>)
    );
  }

  getKirokuDate = () => {
    if (this.state.CheckKirokuDate) {
      return dayjs(this.props.form.getFieldValue("kiroku_date")).format(
        ConstSet.DATE_FORMAT
      );
    }
    return this.props.data && this.props.data.kirokuDate;
  };

  render() {
    const { loading } = this.props;
    const { careTermVisible, linkingCareTerm } = this.state;
    const kirokuDate = this.getKirokuDate();
    return (
      (<div className="back">
        <Spin spinning={loading}>
          <MediaQuery query="(min-device-width: 768px)">
            {(matches) =>
              matches ? (
                <Fragment>
                  {this.renderHeader()}
                  {this.renderContent()}
                </Fragment>
              ) : (
                <Fragment>
                  {this.renderHeaderMobile()}
                  {this.renderContent()}
                  {this.renderFooterMobile()}
                </Fragment>
              )
            }
          </MediaQuery>
        </Spin>
        <CareTermDictModal
          visible={careTermVisible}
          linkingCareTerm={linkingCareTerm}
          handleSelectCareTerm={(data) => this.handleSelectCareTerm(data)}
          hideModalCareTerm={() => this.hideModalCareTerm()}
        />
        <StaffMeetingModal
          visible={this.state.staffMeetingVisible}
          riyousyaId={this.props.riyousyaId}
          onSelectItem={(data) => this.handleSelectStaffMeeting(data)}
          onHide={() => this.hideStaffMeetingModal()}
        />
        <KeikaKirokuModal
          visible={this.state.keikaKirokuVisible}
          kirokuKubuns={this.props.kirokuKubuns}
          riyousyaId={this.props.riyousyaId}
          onSelectItem={(data) => this.handleSelectKeikaKiroku(data)}
          onHide={() => this.hideKeikaKirokuModal()}
          kirokuDate={kirokuDate}
        />
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }
}

const mapStateToProps = () => (state) => ({
  loading: state.keikaKiroku.loading,
});

const KeikaKirokuInputFormWrapper = Form.create({
  name: "input-keika-kiroku",
  mapPropsToFields(props) {
    return {
      kiroku_date: Form.createFormField({
        value:
          props.data && props.data.kirokuDate
            ? dayjs.utc(props.data.kirokuDate, ConstSet.DATE_FORMAT)
            : dayjs.utc(new Date(), ConstSet.DATE_FORMAT),
      }),
      kiroku_time: Form.createFormField({
        value:
          props.data && props.data.kirokuTime
            ? dayjs(props.data.kirokuTime, ConstSet.TIME_FORMAT)
            : dayjs(new Date()),
      }),
      houmon_date: Form.createFormField({
        value:
          props.data && props.data.houmonDate
            ? dayjs.utc(props.data.houmonDate, ConstSet.DATE_FORMAT)
            : null,
      }),
      plan_tanto_id: Form.createFormField({
        value:
          props.keikaKirokuId === "new"
            ? parseInt(getValueLocalstorage("tid"))
            : props.data && props.data.tantoId,
      }),
      kiroku_tanto_id: Form.createFormField({
        value:
          props.data && props.data.kirokuTantoId
            ? props.data.kirokuTantoId
            : null,
      }),
      naiyou: Form.createFormField({
        value: props.data ? props.data.naiyou : "",
      }),
      riyousya_kangae: Form.createFormField({
        value: props.data ? props.data.riyousyaKangae : "",
      }),
      sakuseisya_handan: Form.createFormField({
        value: props.data ? props.data.sakuseisyaHandan : "",
      }),
      kiroku_kubun: Form.createFormField({
        value: props.data ? props.data.kirokuKubun : "",
      }),
      soudan_naiyou: Form.createFormField({
        value: props.data ? props.data.soudanNaiyou : "",
      }),
    };
  },
})(connect(mapStateToProps)(KeikaKirokuInputForm));

export default KeikaKirokuInputFormWrapper;
