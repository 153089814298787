export const ApiPaths = {
  IryouHoken: {
    GetList: "/api/v1/iryou-hoken-get-list",
    Update: "/api/v1/iryou-hoken-update",
    Delete: "/api/v1/iryou-hoken-delete",
  },
  KougakuIryouhi: {
    GetList: "/api/v1/kougaku-iryouhi-get-list",
    Update: "/api/v1/kougaku-iryouhi-update",
    Delete: "/api/v1/kougaku-iryouhi-delete",
  },
  IryouhokenMaster: {
    Init: "/api/v1/iryouhoken-master-init",
    GetList: "/api/v1/iryouhoken-master-get-list",
    Create: "/api/v1/iryouhoken-master-create",
    Update: "/api/v1/iryouhoken-master-update",
    UpdateList: "/api/v1/iryouhoken-master-update-list",
    Delete: "/api/v1/iryouhoken-master-delete",
  },
  // 医療高額医療費負担マスタ
  IryoKougakuFutanMaster: {
    GetList: "/api/v1/iryo-kougaku-master-get-list",
  },

  Account: {
    SignIn: "/api/v1/life-login",
    SignOut: "/api/v1/logout",
    Mainainance: "/api/v1/maintenance-modeLIFE",
  },
  //
  Rihabiri: {
    GetRehaAssessAdlItemAnswerNaiyou:
      "/api/v1/rihabiri/reha-assess-adl-item-answer-naiyou",
    GetRehaAssessAdlItemAdl: "/api/v1/rihabiri/reha-assess-adl-item-adl",
    loadRehabiliAssessmentById: "/api/v1/rihabiri/load-ribabiri-assessment",
    loadSaiShinRecord: "/api/v1/rihabiri/get-saishin-record",
  },
  // @TODO Check Use
  LifeList: {
    GetAllLifeList: "/api/v1/life-list-home",
    GetKaidodoList: "/api/v1/kaigodo-list",
    GetMediSyoubyoumei: "/api/v1/medi-syoubyomei-master-naiyou",
    GetIyakuhinMaster: "/api/v1/iyakuhin-master-naiyou",
    GetIyakuhinMasterByCode: "/api/v1/iyakuhin-master-by-code",
    GetMediSyoubyoumeiByCode: "/api/v1/medi-syoubyomei-by-code",
    GetRiyouByoureki: "/api/v1/riyou-byoureki",
    delKobetsu: "/api/v1/kobetsu-del",
    getFirstLoadData: "/api/v1/kobetsu-first-load-data",
    getIcfMaster: "/api/v1/icf-master",
    getIcfMasterByIcfCd: "/api/v1/icf-master-by-icf-cd",
    getLifeYougoMaster: "/api/v1/life-yougomaster-naiyou",
    getLifeYougoMasterList: "/api/v1/life-yougomaster-naiyou-list",
    getSienNaiyoMaster: "/api/v1/life-siennaiyou-master",
    getSienNaiyoMasterBySienCd: "/api/v1/siennaiyou-master-by-sien-cd",
    getSettingMaster: "/api/v1/setting-master",
    getZenkaiRecord: "/api/v1/kobetsu-zenkai",
    GetListKobetuKinouToQuote: "/api/v1/kobetu-kinou-to-quote",
    getGoToRecord: "/api/v1/kobetsu-gotorecord",
    GetKobetsuCopy: "/api/v1/kobetsu-copy",
    GetListIdsKobetu: "/api/v1/kobetsu-list-ids",
    GetRiyouIdsKobetu: "/api/v1/kobetsu-riyou-ids",
    GetJokusoPlanCopy: "/api/v1/jokuso-plan-copy",
    GetHaisetuCopy: "/api/v1/haisetu-copy",
    GetKokuCopy: "/api/v1/koku-copy",
    GetJirituCopy: "/api/v1/jiritu-sokusin-copy",
    GetYakuzaiCopy: "/api/v1/yakuzai-change-copy",
    getExportData: "/api/v1/life-export-data",
    downloadCsv: "/api/v1/life-export-url",
    GetDoctorMaster: "/api/v1/doctor-master-list",
    getByomeiMaster: "/api/v1/byoumei-master",
  },
  JokusoPlan: {
    GetList: "/api/v1/jokuso-plan-list-home",
    GetJokusoPlan: "/api/v1/jokuso-plan-by-id",
    GetJokusoPlanList: "/api/v1/jokuso-plan-by-ids",
    GetListIdsJokusoPlan: "/api/v1/jokuso-plan-list-ids",
    GetRiyouIdsJokusoPlan: "/api/v1/jokuso-plan-riyou-ids",
    GetJokusoLifeYougo: "/api/v1/jokuso-plan-life-yougo",
    SaveUpdateJokusoPlan: "/api/v1/jokuso-plan-update",
    SaveCreateJokusoPlan: "/api/v1/jokuso-plan-create",
    DeleteJokusoPlan: "/api/v1/jokuso-plan-delete",
    GetAddZiritudo: "/api/v1/jokuso-plan-ziritudo",
    GetListJokusoPlanToQuote: "/api/v1/jokuso-plan-to-quote",
  },
  SeikatuKinouCheck: {
    GetSeikatuKinouAdlMaster: "/api/v1/seikatu-kinou-adl-master",
    GetSeikatuKinouCheck: "/api/v1/seikatu-kinou-check-by-id",
    GetSeikatuKinouCheckByKKId: "/api/v1/seikatu-kinou-check-by-kkid",
    GetListSeikatuCheckToQuote: "/api/v1/seikatu-kinou-check-to-quote",
    GetIdsSeikatuCheck: "/api/v1/seikatu-check-list-id",
    SaveSeikatuCheck: "/api/v1/seikatu-check-save",
    GetAdlInyo: "/api/v1/adl-inyo",
  },
  HaisetsuSienPlan: {
    getHaisetuLifeYougo: "/api/v1/haisetu-life-yougo",
    getHaisetsuById: "/api/v1/haisetsu-by-id",
    getHaisetuList: "/api/v1/haisetsu-by-ids",
    delHaisetsu: "/api/v1/haisetsu-del",
    saveHaisetsuSien: "/api/v1/save-haisetsu",
    getListHaisetuToQuote: "/api/v1/haisetu-to-quote",
    getListIdsHaisetu: "/api/v1/haisetsu-list-ids",
  },
  KobetsuKinou: {
    getKobetsuList: "/api/v1/kobetsu-by-ids",
  },

  Koku: {
    getKokuLifeYougo: "/api/v1/koku-life-yougo",
    getKokuById: "/api/v1/kokueiseikokukino",
    delKoku: "/api/v1/kokueiseikokukino",
    saveKoku: "/api/v1/kokueiseikokukino",
    getListKokuToQuote: "/api/v1/koku-to-quote",
    getListIdsKoku: "/api/v1/koku-list-ids",
  },

  JirituSokusin: {
    getJirituSokusinAdlMaster: "/api/v1/jiritu-sokusin-adl-master",
    getJirituSokusinLifeYougo: "/api/v1/jiritu-sokusin-life-yougo",
    getJirituSokusinById: "/api/v1/jiritu-sokusin-by-id",
    delJirituSokusin: "/api/v1/jiritu-sokusin-del",
    saveJirituSokusin: "/api/v1/save-jiritu-sokusin",
    getListJirituSokusinToQuote: "/api/v1/jiritu-sokusin-to-quote",
    getListIdsJirituSokusin: "/api/v1/jiritu-sokusin-list-ids",
  },

  YakuzaiChange: {
    getYakuzaiChangeById: "/api/v1/yakuzai-change-by-id",
    getYakuzaiChangeLifeYougo: "/api/v1/yakuzai-change-life-yougo",
    delYakuzaiChange: "/api/v1/yakuzai-change-del",
    saveYakuzaiChange: "/api/v1/save-yakuzai-change",
    getListYakuzaiChangeToQuote: "/api/v1/yakuzai-to-quote",
    getListIdsYakuzai: "/api/v1/yakuzai-change-list-ids",
  },

  DoctorMaster: {
    GetDoctorMasterMaxSort: "/api/v1/doctor-master-max-sort",
    GetDoctorMasterInit: "/api/v1/doctor-master-init",
    CreateDoctorMaster: "/api/v1/doctor-master-create",
    UpdateDoctorMaster: "/api/v1/doctor-master-update",
  },

  IryouKikanMaster: {
    GetIryouKikanMasterInit: "/api/v1/iryou-kikan-master-init",
    CreateIryouKikanMaster: "/api/v1/iryou-kikan-master-create",
    UpdateIryouKikanMaster: "/api/v1/iryou-kikan-master-update",
  },

  // @TODO 削除する
  KyotakuPlan1: {
    GetKyotakuPlan1List: "/api/v1/life-plan1-list-home",
    GetKyotakuPlan1ListQuote: "/api/v1/life-plan1-quote-home",
    Default: "/api/v1/life-plan1",
  },
  KyotakuPlan2: {
    GetKyotakuPlan2List: "/api/v1/life-plan2-list-home",
    GetKyotakuPlan2ListQuote: "/api/v1/life-plan2-quote-home",
    Default: "/api/v1/life-plan2",
  },
  SisetuDailyPlan: {
    GetList: "/api/v1/sisetu-daily-plan-doc-list-home",
    Get: "/api/v1/sisetu-daily-plan-by-id",
    // Put: "/api/v1/monitoring-update",
    Post: "/api/v1/save-sisetu-daily-plan",
    Delete: "/api/v1/delete-sisetu-daily-plan",
  },
  Monitoring: {
    GetList: "/api/v1/monitoring-list-home",
    Get: "/api/v1/monitoring-by-id",
    Put: "/api/v1/monitoring-update",
    Post: "/api/v1/monitoring-insert",
    Delete: "/api/v1/monitoring",
  },
  // monitoring management
  MonitoringLIFE: {
    CreateBatch: "/api/v1/monitoring-management-create-batch",
    GetAll: "/api/v1/monitoring-management-list",
  },
  KeikaKiroku: {
    GetList: "/api/v1/keika-kiroku-list-home",
    Get: "/api/v1/keika-kiroku-get-by-id",
    // Put: "/api/v1/monitoring-update",
    Post: "/api/v1/keika-kiroku-create",
    Delete: "/api/v1/keika-kiroku",
  },
  TantoKaigi: {
    GetList: "/api/v1/tanto-kaigi-list-home",
    Get: "/api/v1/tanto-kaigi-get-by-id",
    Put: "/api/v1/update-tanto-kaigi",
    Post: "/api/v1/create-tanto-kaigi",
    Delete: "/api/v1/tanto-kaigi",
  },
  ApiGeneral: {
    GetYougoMaster: "/api/v1/yougomaster-naiyou",
    UpdateYougomaster: "/api/v1/yougomaster-update",
    GetTantoMaster: "/api/v1/tanto-master-list-by-sid",
    GetServiceKikan: "/api/v1/service-kikan-master",
    GetServiceSyuruiMaster: "/api/v1/service-syurui-master",
    GetServiceKikanForPlan2: "/api/v1/service-kikan-master-for-plan2",
  },
  TantoMaster: {
    GetTantoMaster: "/api/v1/tanto-master-list-by-sid",
  },
  InquiryRequest: {
    GetDataInquiRequest: "/api/v1/syoukai-irai",
    GetSyoukaiDate: "/api/v1/syoukai-irai-date",
    SaveUpdateDataInquiryRequest: "/api/v1/syoukai-irai",
    GetDataViewInquiryRequest: "/api/v1/syoukai-irai",
    GetIdsInquiryRequest: "/api/v1/syoukai-irai-list-id",
    GetListInquiryRequest: "/api/v1/syoukai-irai-list",
    SaveCreateDataInquiryRequest: "/api/v1/syoukai-irai",
    Default: "/api/v1/syoukai-irai",
  },
  BarthelIndexMaster: {
    GetBarthelIndexMaster: "/api/v1/get-barthel-index-master",
  },
  KanriUrl: {
    GetTableList: "/api/v1/kanri/tablelist",
    GetTableDetail: "/api/v1/kanri/tablelist/detail",
    GetTableRecord: "/api/v1/kanri/tablelist/record",
  }
};

