import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import { CloseSquareOutlined, MinusSquareOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, Modal, Row, Spin, Table, TimePicker } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import _ from "lodash";
import React, { Component, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { getKyotakuPlan2YougoMaster } from "../../../actions/kyotakuPlan2Actions/actionCreators";
import { saveTantoKaigiTemplate } from "../../../actions/tantoKaigiActions/actionCreators";
import DoctorMasterModal from "../../../common/component/control/DoctorMasterModal";
import TantoInput from "../../../common/component/control/TantoInput";
import { ConstSet } from "../../../common/configs/constset";
import { getValueLocalstorage } from "../../../common/function_common/functionCommon";
import {
  arrayToAttendanceString,
  stringToAttendanceArray,
} from "../../../common/function_common/tantoKaigiAttendance";
import YougoModal from "../DailyPlan/YougoModal";
import CareTermDictModal from "../UI/CareTermDictModal";
import ButtonGroup from "./UI/ButtonGroup";
import RiyouConcernModal from "./UI/RiyouConcernModal";
import SaveTantoKaigiTemplateModal from "./UI/SaveTantoKaigiTemplateModal";
import StaffForm from "./UI/StaffForm";
import TantoKaigiByDateModal from "./UI/TantoKaigiByDateModal";
import TantoKaigiTemplateModal from "./UI/TantoKaigiTemplateModal";
dayjs.extend(dayjsPluginUTC);

// eslint-disable-next-line react/display-name
const withResize = (WrappedComponent) => (props) => {
  function autoResize() {
    const element = document.querySelector(
      `textarea[element=${props.element}]`
    );
    element.style.minHeight = "128px";
    if (!element) return;
    const offset = element.offsetHeight - element.clientHeight;
    element.style.height = "0px";
    element.style.boxSizing = "border-box";
    element.style.height = element.scrollHeight + offset + "px";
    element.addEventListener("input", function (event) {
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + offset + "px";
    });
  }

  useEffect(() => {
    autoResize();
  }, [props.value]);

  return <WrappedComponent {...props} />;
};

const TextAreaResize = withResize(Input.TextArea);

class TantoKaigiInputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      careTermVisible: false,
      confirmVisible: false,
      tantoKaigiTemplateVisible: false,
      tantoKaigiSaveTemplateVisible: false,
      riyouConcernVisible: false,
      doctorMasterVisible: false,
      tantoKaigiByDateVisible: false,
      inputVisible: false,
      listName: null,
      typeYougo: null,
      dataYougo: null,
      linkingCareTerm: "",
      field: "",
      attendanceData: [
        {
          affiliation: "",
          type: "",
          name: "",
        },
      ],
      tantoData: {},
      selectedAttendanceIndex: null,
      isChangedData: false,
    };
  }

  componentDidMount() {
    const { data } = this.props;

    if (data && data.id) {
      this.parseAttendaceData(data);
    }
  }

  componentDidUpdate(prevProps) {
    const { data, tantoFormat } = this.props;

    if (data && prevProps.data && data.id !== prevProps.data.id) {
      this.parseAttendaceData(data);

      const id =
        this.props.tantoKaigiId === "new"
          ? parseInt(getValueLocalstorage("tid"))
          : data.tantoId;
      if (id && tantoFormat) {
        const index = tantoFormat.findIndex((item) => item.id === id);
        this.setState({
          tantoData: { ...tantoFormat[index] },
        });
      }
    }
  }

  parseAttendaceData(data) {
    const { attendance } = data;
    const attendanceData = stringToAttendanceArray(attendance);
    this.setState({ attendanceData });
  }

  showModal(name) {
    this.setState({ [name]: true });
  }

  hideModal(name) {
    if (name === "careTermVisible") {
      this.setState({ [name]: false, linkingCareTerm: "" });
    }
    else {
      this.setState({ [name]: false });
    }
  }

  handleConfirmGoBack() {
    this.props.history.push(`/document-list/${this.props.riyousyaId}`);
  }

  handleSelectCareTerm(data) {
    this.hideModal("careTermVisible");
    const { field } = this.state;

    if (data && field) {
      const preValue = this.props.form.getFieldValue(field);
      const value = {};
      value[field] = _.isEmpty(preValue) ? data : preValue + "、" + data;
      this.props.form.setFieldsValue(value);
      this.setState({ field: "", isChangedData: true });
    }
  }

  showModalCareTerm(field, linkingCareTerm) {
    this.setState({
      linkingCareTerm,
      field,
      careTermVisible: true,
    });
  }

  handleGoBack = (e) => {
    e.preventDefault();

    if (this.props.form.isFieldsTouched() || this.state.isChangedData) {
      this.showModal("confirmVisible");
    }
    else {
      this.props.history.push(`/document-list/${this.props.riyousyaId}`);
    }
  };

  handleSave = (e, type) => {
    e.preventDefault();

    const { form } = this.props;
    const { attendanceData } = this.state;

    form.validateFields((err, values) => {
      values = {
        ...values,
        ...this.parseData(values, attendanceData),
      };

      this.setState({ isChangedData: false });

      if (type === "save") {
        this.props.regData(err, values);
      }
      else if (type === "save_and_new_tab") {
        this.props.regDataAndCheck(err, values);
      }
    });
  };

  setDefaultNumber(value) {
    if (typeof value !== "number") {
      if (!value) {
        return 0;
      }
      const dint = parseInt(value);
      return !isNaN(dint) ? dint : 0;
    }
    return value;
  }

  parseData(values, attendanceData) {
    const data = { ...values };

    data["attendance"] = arrayToAttendanceString(attendanceData);
    data["kaisai_count"] = this.setDefaultNumber(data["kaisai_count"]);
    data["hiyou"] = this.setDefaultNumber(data["hiyou"]);

    if (data["kaisai_time"]) {
      data["kaisai_time"] = dayjs(data["kaisai_time"]).format("HH:mm:ss");
    }
    if (data["kaisai_endtime"]) {
      data["kaisai_endtime"] = dayjs(data["kaisai_endtime"]).format(
        "HH:mm:ss"
      );
    }
    return data;
  }

  handleProgressTemplate(index) {
    if (index === 1) {
      this.showModal("tantoKaigiTemplateVisible");
    }
    else if (index === 2) {
      this.showModal("tantoKaigiSaveTemplateVisible");
    }
  }

  addAttendanceEmptyRow() {
    this.setState((prevState) => {
      const attendanceData = [
        ...prevState.attendanceData,
        {
          affiliation: "",
          type: "",
          name: "",
        },
      ];
      return {
        attendanceData,
        selectedAttendanceIndex: attendanceData.length - 1,
      };
    });
    this.triggleAttendanceScrollToBottom();
  }

  handleChangeAttendance(e, index) {
    const { name, value } = e.target;
    const { attendanceData } = this.state;
    const currentAttendance = attendanceData[index];

    currentAttendance[name] = value;
    this.setState({ attendanceData, isChangedData: true });
  }

  saveTemplate(templateName) {
    const { form } = this.props;
    const { attendanceData } = this.state;

    form.validateFields((_, values) => {
      values["comment"] = templateName;
      values = {
        ...values,
        ...this.parseData(values, attendanceData),
      };
      delete values["tanto_id"];
      delete values["gijiroku_date"];
      delete values["kaisai_date"];
      delete values["kaisai_time"];
      delete values["kaisai_endtime"];

      this.props.saveTantoKaigiTemplate(values, () => { });
    });
  }

  handleSelectTemplate(data) {
    const { form } = this.props;
    const { attendance } = data;
    const attendanceData = stringToAttendanceArray(attendance);

    this.setState({ attendanceData, isChangedData: true });

    form.setFieldsValue({
      kaisai_place: data.kaisaiPlace || "",
      koumoku: data.koumoku || "",
      naiyou: data.naiyou || "",
      keturon: data.keturon || "",
      kadai: data.kadai || "",
      fusanka_riyu: data.fusankaRiyu || "",
      kaisai_count: data.kaisaiCount || "",
      hiyou: data.hiyou || "",
      hiyou_detail: data.hiyouDetail || "",
    });
  }

  handleSelectTantoItem(item) {
    this.setState({ tantoData: item });
  }

  addAttendanceRowBy(name, data) {
    const { attendanceData } = this.state;
    if (data && data.id) {
      let newAttendance = {};

      if (name === "riyouConcern") {
        newAttendance = {
          affiliation: "家族",
          type: data.kankei || "",
          name: data.name || "",
        };
        this.isCheckAttendanceData(attendanceData, newAttendance);
      }
      else if (name === "doctor") {
        newAttendance = {
          affiliation: data.hospital || "",
          type: "医師",
          name: data.name || "",
        };
        this.isCheckAttendanceData(attendanceData, newAttendance);
      }
      else if (name === "tanto") {
        const menu = JSON.parse(localStorage.getItem("menu"));
        this.props.getDataKiKanMaster(menu.si, (kikanData) => {
          newAttendance = {
            affiliation: kikanData || "",
            type: data.sikaku || "",
            name: data.name || "",
          };
          this.isCheckAttendanceData(attendanceData, newAttendance);
        });
      }
      else if (name === "tantoNew") {
        delete data.id;
        this.isCheckAttendanceData(attendanceData, data);
      }
      this.setState({ isChangedData: true });
    }
    this.triggleAttendanceScrollToBottom();
  }

  isCheckAttendanceData = (attendanceData, newAttendance) => {
    if (Array.isArray(attendanceData)) {
      if (attendanceData.length > 1) {
        this.setState((prevState) => {
          const attendanceData = [...prevState.attendanceData, newAttendance];
          return {
            attendanceData,
            selectedAttendanceIndex: attendanceData.length - 1,
          };
        });
      }
      else if (attendanceData.length === 1) {
        const [firstAttendance] = attendanceData;
        this.setState({ selectedAttendanceIndex: 0 });
        if (
          !firstAttendance.affiliation &&
          !firstAttendance.type &&
          !firstAttendance.name
        ) {
          this.setState({
            attendanceData: [newAttendance],
          });
        }
        else {
          this.setState((prevState) => {
            const attendanceData = [...prevState.attendanceData, newAttendance];
            return {
              attendanceData,
              selectedAttendanceIndex: attendanceData.length - 1,
            };
          });
        }
      }
    }
    else {
      this.setState({
        attendanceData: [newAttendance],
      });
    }
  };

  triggleAttendanceScrollToBottom() {
    setTimeout(() => {
      const table = document.querySelector(".tanto-kaigi-attendance__table");
      if (table) {
        const tableBody = table.querySelector(".pljp-table-body");
        if (tableBody) {
          tableBody.scrollTop = tableBody.scrollHeight;
        }
      }
    }, 300);
  }

  handleSelectFusankaRiyu(data) {
    if (data) {
      const { form } = this.props;
      form.setFieldsValue({
        fusanka_riyu: data,
      });
    }
  }

  handleSelectTantoKaigi(data) {
    if (data && data.id) {
      const { form } = this.props;
      const attendanceData = stringToAttendanceArray(data.attendance);
      this.setState({
        attendanceData,
        selectedAttendanceIndex: null,
        isChangedData: true,
      });

      form.setFieldsValue({
        fusanka_riyu: data.fusankaRiyu || "",
        koumoku: data.koumoku || "",
        naiyou: data.naiyou || "",
        keturon: data.keturon || "",
        kadai: data.kadai || "",
      });
    }
  }

  setSelectedAttendanceIndex(index) {
    if (this.state.selectedAttendanceIndex !== index) {
      this.setState({ selectedAttendanceIndex: index });
    }
    else {
      this.setState({ selectedAttendanceIndex: null });
    }
  }

  handleRemoveAttendance(index) {
    let { attendanceData } = this.state;

    if (attendanceData.length <= 1) {
      attendanceData = [{ affiliation: "", name: "", type: "" }];
    }
    else {
      attendanceData.splice(index, 1);
    }

    this.setState({ attendanceData, isChangedData: true });
  }

  showYougoModal(listName, typeYougo) {
    this.props.getYougoMaster(listName);
    const dataYougo = this.props.form.getFieldValue(typeYougo);
    this.setState({
      inputVisible: true,
      listName,
      typeYougo,
      dataYougo,
    });
  }

  handleYougoCancel() {
    this.setState({
      inputVisible: false,
      listName: null,
    });
  }

  handleYougoOk(values) {
    const { typeYougo } = this.state;
    const { form } = this.props;
    const fusankaRiyu = form.getFieldValue("fusanka_riyu");

    if (typeYougo === "fusanka_riyu") {
      this.props.form.setFieldsValue({
        fusanka_riyu: !fusankaRiyu
          ? `${values}`
          : fusankaRiyu + "、" + `${values}`,
      });
    }
    this.setState({ isChangedData: true });
    this.handleYougoCancel();
  }

  renderHeader() {
    return (
      <Row className={"submenu fixed-header"} style={{ display: "flex" }}>
        <Col span={8} className={"sm-c-left"}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【担当者会議】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
        <Col style={{ paddingTop: 13, textAlign: "center" }} span={8}>
          <ButtonGroup
            onClick={(index) => this.handleProgressTemplate(index)}
          />
        </Col>
        <Col span={8} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <Link to={"/"} onClick={(e) => this.handleSave(e, "save")}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{ display: "block", fontWeight: "bold", color: "red" }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </Link>
          {this.props.tantoKaigiId !== "new" ? (
            <Link
              to={"/"}
              onClick={(e) => this.handleSave(e, "save_and_new_tab")}
            >
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          ) : null}
        </Col>
      </Row>
    );
  }

  renderHeaderMobile() {
    return (
      <Row className={"submenu fixed-header"} style={{ display: "flex" }}>
        <Col span={24} className={"sm-c-left"} style={{ color: "white" }}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【担当者会議】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
      </Row>
    );
  }

  renderAttendanceMobile(attendanceData) {
    return (
      <div className="tanto-kaigi-attendance tanto-kaigi-attendance-m">
        <span className="text-bold">出席者</span>
        <div className="tanto-kaigi-attendance__list">
          {Array.isArray(attendanceData) &&
            attendanceData.map((item, index) => (
              <div
                key={index}
                className={`tanto-kaigi-attendance__item
                  ${this.state.selectedAttendanceIndex === index ? "tanto-kaigi-attendance__item--show" : ""}`}
              >
                <div
                  className="tanto-kaigi-attendance__no"
                  role="button"
                  onClick={() => this.setSelectedAttendanceIndex(index)}
                >
                  <span>出席者 {index + 1}</span>
                  <Button
                    type="link"
                    icon={<LegacyIcon
                      type={
                        this.state.selectedAttendanceIndex === index
                          ? "caret-up"
                          : "caret-down"
                      } />}
                  ></Button>
                </div>
                <div className="tanto-kaigi-attendance__affiliation">
                  <span className="text-bold">所属</span>
                  <Input
                    name="affiliation"
                    value={item.affiliation || ""}
                    onChange={(e) => this.handleChangeAttendance(e, index)}
                  />
                </div>
                <div className="tanto-kaigi-attendance__type">
                  <span className="text-bold">種別</span>
                  <Input
                    name="type"
                    value={item.type || ""}
                    onChange={(e) => this.handleChangeAttendance(e, index)}
                  />
                </div>
                <div className="tanto-kaigi-attendance__name">
                  <span className="text-bold">名前</span>
                  <Input
                    name="name"
                    value={item.name || ""}
                    onChange={(e) => this.handleChangeAttendance(e, index)}
                  />
                </div>
                <div className="tanto-kaigi-attendance__remove">
                  <Button
                    size="small"
                    onClick={() => this.handleRemoveAttendance(index)}
                  >
                    削 除
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <div className="tanto-kaigi-attendance__buttons">
          <Button
            size="small"
            type="primary"
            onClick={() => this.addAttendanceEmptyRow()}
          >
            出席者追加
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() =>
              this.addAttendanceRowBy("tanto", this.state.tantoData)
            }
          >
            担当者追加
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => this.showModal("riyouConcernVisible")}
          >
            関係者引用
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => this.showModal("doctorMasterVisible")}
          >
            医師追加
          </Button>
          <StaffForm
            size="small"
            buttonName="担当マスタ"
            onSelect={(data) => this.addAttendanceRowBy("tantoNew", data)}
          />
        </div>
      </div>
    );
  }

  renderContent(mode) {
    const { attendanceData } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const attendanceColumns = [
      {
        title: "所属",
        dataIndex: "affiliation",
        key: "affiliation",
        render: (text, _, index) => (
          <Input
            name="affiliation"
            value={text || ""}
            onChange={(e) => this.handleChangeAttendance(e, index)}
          />
        ),
      },
      {
        title: "種別",
        dataIndex: "type",
        key: "type",
        render: (text, _, index) => (
          <Input
            name="type"
            value={text || ""}
            onChange={(e) => this.handleChangeAttendance(e, index)}
          />
        ),
      },
      {
        title: "名前",
        dataIndex: "name",
        key: "name",
        render: (text, _, index) => (
          <Input
            name="name"
            value={text || ""}
            onChange={(e) => this.handleChangeAttendance(e, index)}
          />
        ),
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: 40,
        render: (text, record, index) => (
          <Button
            size="small"
            type="link"
            icon={<CloseSquareOutlined />}
            onClick={() => this.handleRemoveAttendance(index)}
          />
        ),
      },
    ];

    return (
      <div
        className="main"
        style={{
          maxWidth: "1081px",
          margin: "0 auto",
          padding: "20px 15px 30px 15px",
        }}
      >
        {/* Select and save template */}
        <div className="tanto-kaigi-button-group-m">
          <ButtonGroup
            onClick={(index) => this.handleProgressTemplate(index)}
          />
        </div>

        {/* Buttons  */}
        <div className="tanto-kaigi-buttons">
          <Row style={{ marginBottom: 15 }}>
            {/* gijiroku_date */}
            <Col
              md={24}
              lg={5}
              className="tanto-kaigi-button tanto-kaigi-button--1"
            >
              <span className="text-bold">作成日</span>
              {getFieldDecorator("gijiroku_date")(
                <DatePicker style={{ width: 120 }} />
              )}
            </Col>

            {/* tanto_id */}
            <Col
              md={24}
              lg={10}
              className="tanto-kaigi-button tanto-kaigi-button--2"
            >
              <span className="text-bold">担当者</span>
              {getFieldDecorator("tanto_id")(
                <TantoInput
                  allowBlank={true}
                  style={{ display: "inline-block" }}
                  containerId={"input-tanto-kaigi-page"}
                  onChangeItem={(item) => this.handleSelectTantoItem(item)}
                />
              )}
            </Col>
          </Row>

          <Row>
            {/* kaisai_date */}
            <Col
              md={24}
              lg={5}
              className="tanto-kaigi-button tanto-kaigi-button--3"
            >
              <span className="text-bold">開催日</span>
              {getFieldDecorator("kaisai_date")(
                <DatePicker style={{ width: 120 }} />
              )}
            </Col>

            {/* kaisai_place */}
            <Col
              md={24}
              lg={11}
              className="tanto-kaigi-button tanto-kaigi-button--4"
            >
              <span className="text-bold">開催場所</span>
              {getFieldDecorator("kaisai_place")(<Input />)}
            </Col>

            {/* kaisai_time + kaisai_endtime */}
            <Col
              md={24}
              lg={8}
              className="tanto-kaigi-button tanto-kaigi-button--5"
            >
              <span className="text-bold">開催時間</span>
              {getFieldDecorator("kaisai_time")(
                <TimePicker
                  showNow={false}
                  needConfirm={false}
                  format={ConstSet.TIME_FORMAT}
                  style={{ width: 100 }}
                  placeholder="開始時間"
                />
              )}
              <span style={{ marginLeft: 8, marginRight: 8 }}>~</span>
              {getFieldDecorator("kaisai_endtime")(
                <TimePicker
                  showNow={false}
                  needConfirm={false}
                  format={ConstSet.TIME_FORMAT}
                  style={{ width: 100 }}
                  placeholder="終了時間"
                />
              )}
            </Col>
          </Row>
        </div>

        {/* Quote button */}
        <div className="tanto-kaigi-quote-button">
          <Button
            type="primary"
            onClick={() => this.showModal("tantoKaigiByDateVisible")}
          >
            前回引用
          </Button>
        </div>

        {/* Attendance table */}
        {mode === "mobile" ? (
          this.renderAttendanceMobile(attendanceData)
        ) : (
          <div className="tanto-kaigi-attendance">
            <div className="text-bold title">出席者</div>
            <div className="tanto-kaigi-attendance__table">
              <Table
                bordered
                columns={attendanceColumns}
                dataSource={attendanceData}
                rowKey={(_, index) => `${index}`}
                size="small"
                scroll={{ y: 145 }}
                pagination={false}
              />
            </div>
            <div className="tanto-kaigi-attendance__buttons">
              <Button
                type="primary"
                onClick={() => this.addAttendanceEmptyRow()}
              >
                出席者追加
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  this.addAttendanceRowBy("tanto", this.state.tantoData)
                }
              >
                担当者追加
              </Button>
              <Button
                type="primary"
                onClick={() => this.showModal("riyouConcernVisible")}
              >
                関係者引用
              </Button>
              <Button
                type="primary"
                onClick={() => this.showModal("doctorMasterVisible")}
              >
                医師追加
              </Button>
              <StaffForm
                size="default"
                buttonName="担当マスタ"
                onSelect={(data) => this.addAttendanceRowBy("tantoNew", data)}
              />
            </div>
          </div>
        )}

        {/* Textareas */}
        <div className="tanto-kaigi-textareas">
          <Row>
            {/* koumoku */}
            <Col span={24} className="tanto-kaigi-textarea">
              <div className="tanto-kaigi-textarea__title">
                <span className="text-bold">検討した項目</span>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => this.showModalCareTerm("koumoku", "検討項目")}
                >
                  用語
                </Button>
              </div>
              {getFieldDecorator("koumoku")(
                <TextAreaResize element="koumoku" rows={4} />
              )}
            </Col>

            {/* naiyou */}
            <Col span={24} className="tanto-kaigi-textarea">
              <div className="tanto-kaigi-textarea__title">
                <span className="text-bold">検討内容</span>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => this.showModalCareTerm("naiyou", "検討内容")}
                >
                  用語
                </Button>
              </div>
              {getFieldDecorator("naiyou")(
                <TextAreaResize element="naiyou" rows={4} />
              )}
            </Col>

            {/* keturon */}
            <Col span={24} className="tanto-kaigi-textarea">
              <div className="tanto-kaigi-textarea__title">
                <span className="text-bold">結論</span>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => this.showModalCareTerm("keturon", "結論")}
                >
                  用語
                </Button>
              </div>
              {getFieldDecorator("keturon")(
                <TextAreaResize element="keturon" rows={4} />
              )}
            </Col>

            {/* fusanka_riyu */}
            <Col span={24} className="tanto-kaigi-textarea">
              <div className="tanto-kaigi-textarea__title">
                <span className="text-bold">不参加理由</span>
                <Button
                  type="primary"
                  size="small"
                  onClick={() =>
                    this.showYougoModal("不参加理由", "fusanka_riyu")
                  }
                >
                  用語
                </Button>
              </div>
              {getFieldDecorator("fusanka_riyu")(
                <TextAreaResize element="fusanka_riyu" rows={4} />
              )}
            </Col>

            {/* kadai */}
            <Col span={24} className="tanto-kaigi-textarea">
              <div className="tanto-kaigi-textarea__title">
                <span className="text-bold">残された課題</span>
                <Button
                  type="primary"
                  size="small"
                  onClick={() =>
                    this.showModalCareTerm("kadai", "残された課題")
                  }
                >
                  用語
                </Button>
              </div>
              {getFieldDecorator("kadai")(
                <TextAreaResize element="kadai" rows={4} />
              )}
            </Col>

            {/* kaisai + hiyou */}
            <Col span={24} className="tanto-kaigi-hiyou">
              <div className="tanto-kaigi-hiyou__item">
                <span className="text-bold">開催回数</span>
                {getFieldDecorator("kaisai_count")(<Input type="number" />)}
              </div>
              <div className="tanto-kaigi-hiyou__item">
                <span className="text-bold">開催費用</span>
                {getFieldDecorator("hiyou")(<Input type="number" />)}
              </div>
              <div className="tanto-kaigi-hiyou__item">
                <span className="text-bold">費用内訳</span>
                {getFieldDecorator("hiyou_detail")(<Input />)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderFooterMobile() {
    return (
      <Row className={"submenu fixed-footer"}>
        <Col span={24} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <Link to={"/"} onClick={(e) => this.handleSave(e, "save")}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{
                  display: "block",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </Link>
          {this.props.tantoKaigiId !== "new" ? (
            <Link
              to={"/"}
              id={"checkBtn"}
              onClick={(e) => this.handleSave(e, "save_and_new_tab")}
            >
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          ) : null}
        </Col>
      </Row>
    );
  }

  render() {
    const { loading, tantoKaigiId } = this.props;

    return (
      (<div className="back">
        <Spin spinning={loading}>
          <MediaQuery query="(min-device-width: 768px)">
            {(matches) =>
              matches ? (
                <Fragment>
                  {this.renderHeader()}
                  {this.renderContent("desktop")}
                </Fragment>
              ) : (
                <Fragment>
                  {this.renderHeaderMobile()}
                  {this.renderContent("mobile")}
                  {this.renderFooterMobile()}
                </Fragment>
              )
            }
          </MediaQuery>
        </Spin>
        <TantoKaigiTemplateModal
          visible={this.state.tantoKaigiTemplateVisible}
          onSelect={(data) => this.handleSelectTemplate(data)}
          onHide={() => this.hideModal("tantoKaigiTemplateVisible")}
        />
        <SaveTantoKaigiTemplateModal
          visible={this.state.tantoKaigiSaveTemplateVisible}
          onSubmit={(templateName) => this.saveTemplate(templateName)}
          onHide={() => this.hideModal("tantoKaigiSaveTemplateVisible")}
        />
        <CareTermDictModal
          visible={this.state.careTermVisible}
          linkingCareTerm={this.state.linkingCareTerm}
          handleSelectCareTerm={(data) => this.handleSelectCareTerm(data)}
          hideModalCareTerm={() => this.hideModal("careTermVisible")}
        />
        <RiyouConcernModal
          visible={this.state.riyouConcernVisible}
          riyousyaId={this.props.riyousyaId}
          onSelect={(data) => this.addAttendanceRowBy("riyouConcern", data)}
          onHide={() => this.hideModal("riyouConcernVisible")}
        />
        <DoctorMasterModal
          visible={this.state.doctorMasterVisible}
          onSelect={(data) => this.addAttendanceRowBy("doctor", data)}
          onHide={() => this.hideModal("doctorMasterVisible")}
        />
        <TantoKaigiByDateModal
          visible={this.state.tantoKaigiByDateVisible}
          tantoKaigiId={
            tantoKaigiId && tantoKaigiId !== "new" ? tantoKaigiId : null
          }
          riyousyaId={this.props.riyousyaId}
          onSelect={(data) => this.handleSelectTantoKaigi(data)}
          onHide={() => this.hideModal("tantoKaigiByDateVisible")}
        />
        <YougoModal
          visible={this.state.inputVisible}
          onCancel={() => this.handleYougoCancel()}
          onSubmit={(values) => this.handleYougoOk(values)}
          listName={this.state.listName}
          typeYougo={this.state.typeYougo}
          data={this.state.dataYougo}
        />
        <Modal
          open={this.state.confirmVisible}
          title="キャンセル確認"
          onCancel={() => this.hideModal("confirmVisible")}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }
}

const mapStateToProps = () => (state) => ({
  loading: state.tantoKaigi.loading,
  tantoFormat: state.tantoMasterList.tantoMasters2,
});

const mapDispatchToProps = () => (dispatch) => ({
  saveTantoKaigiTemplate: (data, callback) =>
    dispatch(saveTantoKaigiTemplate(data, callback)),
  getYougoMaster: (listName) => dispatch(getKyotakuPlan2YougoMaster(listName)),
});

const TantoKaigiInputFormWrapper = Form.create({
  name: "input-tanto-kaigi",
  mapPropsToFields(props) {
    const tantoId = (props.data && props.data.tantoId) || null;

    // alert(dayjs.utc(props.data.kaisaiTime, ConstSet.TIME_FORMAT));
    return {
      gijiroku_date: Form.createFormField({
        value:
          props.data && props.data.gijirokuDate
            ? dayjs.utc(props.data.gijirokuDate, ConstSet.DATE_FORMAT)
            : dayjs.utc(new Date(), ConstSet.DATE_FORMAT),
      }),
      tanto_id: Form.createFormField({
        value:
          props.tantoKaigiId === "new"
            ? parseInt(getValueLocalstorage("tid"))
            : tantoId,
      }),
      kaisai_date: Form.createFormField({
        value:
          props.data && props.data.kaisaiDate
            ? dayjs.utc(props.data.kaisaiDate, ConstSet.DATE_FORMAT)
            : dayjs.utc(new Date(), ConstSet.DATE_FORMAT),
      }),
      kaisai_place: Form.createFormField({
        value:
          props.data && props.data.kaisaiPlace ? props.data.kaisaiPlace : "",
      }),
      kaisai_time: Form.createFormField({
        value:
          props.data && props.data.kaisaiTime
            ? dayjs(props.data.kaisaiTime, ConstSet.TIME_FORMAT)
            : null,
      }),
      kaisai_endtime: Form.createFormField({
        value:
          props.data && props.data.kaisaiEndtime
            ? dayjs(props.data.kaisaiEndtime, ConstSet.TIME_FORMAT)
            : null,
      }),
      koumoku: Form.createFormField({
        value: props.data && props.data.koumoku ? props.data.koumoku : "",
      }),
      naiyou: Form.createFormField({
        value: props.data && props.data.naiyou ? props.data.naiyou : "",
      }),
      keturon: Form.createFormField({
        value: props.data && props.data.keturon ? props.data.keturon : "",
      }),
      kadai: Form.createFormField({
        value: props.data && props.data.kadai ? props.data.kadai : "",
      }),
      fusanka_riyu: Form.createFormField({
        value:
          props.data && props.data.fusankaRiyu ? props.data.fusankaRiyu : "",
      }),
      kaisai_count: Form.createFormField({
        value:
          props.data && props.data.kaisaiCount ? props.data.kaisaiCount : 0,
      }),
      hiyou: Form.createFormField({
        value: props.data && props.data.hiyou ? props.data.hiyou : 0,
      }),
      hiyou_detail: Form.createFormField({
        value:
          props.data && props.data.hiyouDetail ? props.data.hiyouDetail : "",
      }),
    };
  },
})(connect(mapStateToProps, mapDispatchToProps)(TantoKaigiInputForm));

export default TantoKaigiInputFormWrapper;
