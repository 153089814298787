import React, { Component } from "react";
import { Row, Col } from "antd";

export default class Validate extends Component {
  render() {
    const { userSelect, messageContent } = this.props;

    return (
      <Row className={"maxw-960"}>
        <Col span={24}>
          {userSelect ? (
            <p style={{ color: "red" }}>宛先 is required.</p>
          ) : null}
          {messageContent ? (
            <p style={{ color: "red" }}>メッセージ is required.</p>
          ) : null}
        </Col>
      </Row>
    );
  }
}
