import React from "react";
import { Checkbox } from "antd";

const ListServicesAddition = (props) => {
  const { listServiceAddition, formValues, handleChangeServiceAddition, loadingServiceCode, loadingServiceCodeExpand } = props;
  return (
    <>
      {listServiceAddition.map((item, index) => (
        <div style={{ marginLeft: 2 }} key={index}>
          <Checkbox
            key={index}
            value={item.addition}
            checked={formValues.additionList ? formValues.additionList.includes(item.addition) : false}
            onChange={handleChangeServiceAddition}
            disabled={loadingServiceCode || loadingServiceCodeExpand}
          >
            {item.addition}
          </Checkbox>
        </div>
      ))}
    </>
  );
};

export default ListServicesAddition;
