import React from "react";
import { Route } from "react-router-dom";

const ErrorLayout = ({ component: Component, ...other }) => (
  <Route
    {...other}
    render={() => (
      <div className="error-layout">
        <Component />
      </div>
    )}
  />
);

export { ErrorLayout };
