import {
  MESSAGE_LIST_REQUEST,
  MESSAGE_LIST_SUCCESS,
  MESSAGE_ADD_REQUEST,
  MESSAGE_ADD_SUCCESS,
  LOAD_MORE_REQUEST,
  LOAD_MORE_SUCCESS,
  MESSAGE_MITAYO,
  MESSAGE_LIST_ERROR,
} from "../../actions/messageActions/actionName.js";

import {
  MessageListApi,
  MessageAddApi,
  MessageMitayoApi,
  MessageLoadMoreApi,
} from "../../api/messageList";

/**
 *
 * @param {*} tid
 * @param {*} pageNo
 */
function messageListCreator(tid, pageNo) {
  return (dispatch) => {
    dispatch({
      type: MESSAGE_LIST_REQUEST,
    });

    MessageListApi.messageList(tid, pageNo).then((data) => {
      if (data && data.response && data.response.status !== 200) {
        dispatch({
          type: MESSAGE_LIST_ERROR,
          err: data.response.message,
        });
      } else {
        dispatch({
          type: MESSAGE_LIST_SUCCESS,
          messages: data,
        });
      }
    });
  };
}

function messageAddCreator(jsonMessage, tid) {
  return (dispatch) => {
    dispatch({
      type: MESSAGE_ADD_REQUEST,
    });

    MessageAddApi.messageAdd(jsonMessage, tid).then((data) =>
      dispatch({
        type: MESSAGE_ADD_SUCCESS,
        message: data,
      })
    );
  };
}

/**
 *
 * @param {*} tid
 * @param {*} pageNo
 */
function messageLoadMoreCreator(tid, pageNo) {
  return (dispatch) => {
    dispatch({
      type: LOAD_MORE_REQUEST,
    });

    MessageLoadMoreApi.messageLoadMore(tid, pageNo).then((data) =>
      dispatch({
        type: LOAD_MORE_SUCCESS,
        messages: data,
      })
    );
  };
}

/**
 *
 * @param {*} messageId
 */
function messageMitayoCreator(messageId) {
  return (dispatch) => {
    MessageMitayoApi.messageMitayo(messageId).then((data) =>
      dispatch({
        type: MESSAGE_MITAYO,
        messageId: messageId,
      })
    );
  };
}

export {
  messageListCreator,
  messageAddCreator,
  messageLoadMoreCreator,
  messageMitayoCreator,
};
