import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React from "react";
import { connect } from "react-redux";
import { convertMessageByCode } from "../../../common/function_common/functionCommon";

const FormItem = Form.Item;

function LoginForm(props) {
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.handleSubmit(values.username, values.password);
  //     }
  //   });
  // };

  const onFinish = (values) => {
    props.handleSubmit(values.username, values.password);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const { errorLogin } = props;

  return (
    <Form
      // onSubmit={this.handleSubmit}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="login-form"
      autoComplete="off"
    >
      <FormItem
        name={"username"}
        rules={[
          { required: true, message: "ログインIDを入力してください" },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="ログインIDを入力してください"
        />
      </FormItem>
      <FormItem
        name={"password"}
        rules={[
          { required: true, message: "パスワードを入力してください" },
        ]}
      >
        <Input
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder="パスワードを入力してください"
        />
      </FormItem>
      <div className={"lg-f-error"}>
        {errorLogin === "LOGIN_FAIL" ? (
          <span>{convertMessageByCode("LOGIN_FAIL")}</span>
        ) : null}
      </div>
      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          <h3>ログイン</h3>
        </Button>
      </FormItem>
    </Form>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = () => (state) => ({
  isSeed: "isSeedState", // state.propsName
});

const mapDispatchToProps = () => (dispatch) => ({
  isSeedFun: (isSeed) => dispatch(() => { console.log(isSeed); }), // funtionAxios(data)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
