import { ApiPaths } from "../../constants/api_paths";
import { Plan1ActionTypes, ErrorActionTypes } from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils } from "../../utils/index";

function getQuoteList(riyousyaId, kyotakuPlan1Id) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: Plan1ActionTypes.GET_ALL_PLAN1_QUOTE_REQUEST });
      const query = {
        riyousyaId,
        kyotakuPlan1Id,
        sid,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.KyotakuPlan1.GetKyotakuPlan1ListQuote,
        query,
      });
      dispatch({
        type: Plan1ActionTypes.GET_ALL_PLAN1_QUOTE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: Plan1ActionTypes.GET_ALL_PLAN1_QUOTE_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getAll(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: Plan1ActionTypes.GET_ALL_PLAN1_REQUEST });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.KyotakuPlan1.GetKyotakuPlan1List,
        query,
      });
      dispatch({ type: Plan1ActionTypes.GET_ALL_PLAN1_SUCCESS, data });
    } catch (error) {
      dispatch({ type: Plan1ActionTypes.GET_ALL_PLAN1_QUOTE_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: Plan1ActionTypes.REMOVE_PLAN1_REQUEST });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.KyotakuPlan1.Default,
        query,
      });
      dispatch({ type: Plan1ActionTypes.REMOVE_PLAN1_SUCCESS, data });
    } catch (error) {
      dispatch({ type: Plan1ActionTypes.REMOVE_PLAN1_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

export const Plan1Actions = {
  getQuoteList,
  getAll,
  deleteId,
};
