import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const KyotakuPlan1Api = {
  async loadKyotakuPlan1(params) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.GET_KYOTAKU_PLAN1_LIST, {
        params: {
          sid: menu.si,
          systemName: menu.sn,
          riyousyaId: params.riyousyaId,
          inUsed: params.inUsed,
          searchName: params.searchName,
          page: params.page,
          sort: params.sort,
        },
      });
    return res.data;
  },

  async createKyotakuPlan1(data, riyousyaId) {
    const key = getMenu();
    const res = await axios
      .post(apiUrls.CREATE_KYOTAKU_PLAN1, {
        riyousya_id: riyousyaId,
        service_kikan_master_id: JSON.parse(localStorage.getItem(key)).si,
        riyou_kaigohoken_id: data.riyou_kaigohoken_id,
        progressF: data.progressF.length,
        first_plan_date: data.first_plan_date,
        plan_date: data.plan_date,
        tanto_id: data.tanto_id,
        kubun: data.kubun.join(""),
        ikou: data.ikou,
        iken: data.iken,
        housin: data.housin,
        // eslint-disable-next-line no-nested-ternary
        riyu: data.riyu1 ? 1 : data.riyu2 ? 2 : data.riyu3 ? 3 : null,
        riyu_hoka: data.riyu3 ? data.riyu_hoka : null,
        plan1_doui_date: data.plan1_doui_date,
        comment: data.comment,
      });
    return res.data;
  },
  async updateKyotakuPlan1(data, kyotakuPlan1Id) {
    const res = await axios
      .put(apiUrls.UPDATE_KYOTAKU_PLAN1 + "?id=" + kyotakuPlan1Id, {
        riyou_kaigohoken_id: data.riyou_kaigohoken_id,
        progressF: data.progressF.length,
        first_plan_date: data.first_plan_date,
        plan_date: data.plan_date,
        tanto_id: data.tanto_id,
        kubun: data.kubun.join(""),
        ikou: data.ikou,
        iken: data.iken,
        housin: data.housin,
        // eslint-disable-next-line no-nested-ternary
        riyu: data.riyu1 ? 1 : data.riyu2 ? 2 : data.riyu3 ? 3 : null,
        riyu_hoka: data.riyu3 ? data.riyu_hoka : null,
        plan1_doui_date: data.plan1_doui_date,
        comment: data.comment,
      });
    return res.data;
  },
  async deleteKyotakuPlan1(kyotakuPlan1Id) {
    const res = await axios
      .delete(apiUrls.DELETE_KYOTAKU_PLAN1, {
        params: {
          id: kyotakuPlan1Id,
        },
      });
    return res.data;
  },
  async getKyotakuPlan1ById(kyotakuPlan1Id) {
    const res = await axios
      .get(apiUrls.GET_KYOTAKU_PLAN1_BY_ID, {
        params: {
          id: kyotakuPlan1Id,
        },
      });
    return res.data;
  },
  async getKyotakuPlan1ToQuote(riyousyaId, kyotakuPlan1Id) {
    const key = getMenu();
    const res = await axios
      .get(apiUrls.GET_KYOTAKU_PLAN1_TO_QUOTE, {
        params: {
          riyousyaId,
          kyotakuPlan1Id,
          systemName: JSON.parse(localStorage.getItem(key)).sn,
          mainSid: JSON.parse(localStorage.getItem(key)).si,
        },
      });
    return res.data;
  },
};

const DocumentListApi = {
  async getPlansToViewByRiyousya(riyousyaId) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.GET_VIEW_PLANS_BY_RIYOUSYA, {
        params: {
          riyousyaId,
          sid: menu.si,
          systemName: menu.sn,
          mainSid: menu.si,
        },
      });
    return res.data;
  },
};

export { DocumentListApi, KyotakuPlan1Api };

