import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";

let GetSyokichi = {
  get(riyousyaId, kubun) {
    return axios(apiUrls.INIT + apiUrls.SYOKICHI_ITEM, {
      method: "POST",
      data: {
        riyousyaIds: riyousyaId,
        kubun: kubun,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
};

let UpdateSyokichi = {
  update(riyousyaId, kubun, syokichi) {
    return axios(apiUrls.INIT + apiUrls.SYOKICHI_UPDATE, {
      method: "POST",
      data: {
        riyousyaIds: riyousyaId,
        kubun: kubun,
        syokichi: syokichi,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          return err;
        }
      });
  },
};

export { GetSyokichi, UpdateSyokichi };
