import { riyousyaHokenActionTypes } from "./actionName.js";

import {
  ListHokenApi,
  ListKohiApi,
  KaigoHokenMasterApi,
} from "../../api/riyousyaList";
import { IryouhokenApi, IryoKougakuFutanApi } from "../../api/riyousyaHoken";

import {
  localStorageClearCus,
  getMenu,
  formatStorage,
} from "../../common/function_common/functionCommon";

//介護保険
function getKaigoHokenCreator(page, riyousyaId) {
  return (dispatch) => {
    dispatch({
      type: riyousyaHokenActionTypes.RIYOUSYA_HOKEN_ABOVE_REQUEST,
    });

    //
    let mainSID = formatStorage(getMenu()).si;

    // ConstSet.PER_PAGE * 4
    ListHokenApi.list(page, 4, mainSID, riyousyaId)
      .then((data) =>
        dispatch({
          type: riyousyaHokenActionTypes.RIYOUSYA_HOKEN_ABOVE_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: riyousyaHokenActionTypes.RIYOUSYA_HOKEN_ABOVE_ERROR,
          messages: error,
        })
      );
  };
}

function updateKaigoHokenCreator(params) {
  return (dispatch) => {
    dispatch({
      type: riyousyaHokenActionTypes.RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST,
    });

    ListHokenApi.save(params)
      .then((data) =>
        dispatch({
          type: riyousyaHokenActionTypes.RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: riyousyaHokenActionTypes.RIYOUSYA_KAIGOHOKEN_UPDATE_ERROR,
          messages: error,
        })
      );
  };
}

function deleteKaigoHokenCreator(id) {
  return (dispatch) => {
    ListHokenApi.del(id).then(() => {
      //callback();
      return dispatch({
        type: riyousyaHokenActionTypes.RIYOUSYA_KAIGOHOKEN_DELETE,
        id: id,
      });
    });
  };
}
function loadKaigoHokenMaster(hokenNo) {
  return (dispatch) => {
    dispatch({
      type: riyousyaHokenActionTypes.KAIGOHOKEN_MASTER_SPIN_REQUEST,
    });
    KaigoHokenMasterApi.list(hokenNo).then(
      (data) => {
        dispatch({
          type: riyousyaHokenActionTypes.LOAD_KAIGOHOKEN_MASTER_SUCCESS,
          data: data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: riyousyaHokenActionTypes.LOAD_KAIGOHOKEN_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

//公費
function getKohiJohoCreator(page, riyousyaId) {
  return (dispatch) => {
    dispatch({
      type: riyousyaHokenActionTypes.RIYOUSYA_HOKEN_BELOW_REQUEST,
    });

    //
    let mainSID = formatStorage(getMenu()).si;

    // ConstSet.PER_PAGE * 4
    ListKohiApi.list(page, 4, mainSID, riyousyaId)
      .then((data) =>
        dispatch({
          type: riyousyaHokenActionTypes.RIYOUSYA_HOKEN_BELOW_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: riyousyaHokenActionTypes.RIYOUSYA_HOKEN_BELOW_ERROR,
          messages: error,
        })
      );
  };
}

function updateKohiJohoCreator(params) {
  return (dispatch) => {
    dispatch({
      type: riyousyaHokenActionTypes.RIYOUSYA_KOHI_UPDATE_REQUEST,
    });

    ListKohiApi.save(params)
      .then((data) =>
        dispatch({
          type: riyousyaHokenActionTypes.RIYOUSYA_KOHI_UPDATE_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: riyousyaHokenActionTypes.RIYOUSYA_KOHI_UPDATE_ERROR,
          messages: error,
        })
      );
  };
}

function deleteKohiJohoCreator(id) {
  return (dispatch) => {
    ListKohiApi.del(id).then(() => {
      //callback();
      return dispatch({
        type: riyousyaHokenActionTypes.RIYOUSYA_KOHIJOHO_DELETE,
        id: id,
      });
    });
  };
}

//医療保険
function getListIryouHoken(page, riyousyaId) {
  return (dispatch) => {
    dispatch({
      type: riyousyaHokenActionTypes.GET_LIST_IRYOU_HOKEN_REQUEST,
    });
    let mainSID = formatStorage(getMenu()).si;
    IryouhokenApi.list(page, 4, mainSID, riyousyaId)
      .then((data) =>
        dispatch({
          type: riyousyaHokenActionTypes.GET_LIST_IRYOU_HOKEN_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: riyousyaHokenActionTypes.GET_LIST_IRYOU_HOKEN_FAILURE,
          messages: error,
        })
      );
  };
}
function updateIryouHoken(value) {
  return (dispatch) => {
    dispatch({
      type: riyousyaHokenActionTypes.UPDATE_IRYOU_HOKEN_REQUEST,
    });
    IryouhokenApi.save(value)
      .then((data) =>
        dispatch({
          type: riyousyaHokenActionTypes.UPDATE_IRYOU_HOKEN_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: riyousyaHokenActionTypes.UPDATE_IRYOU_HOKEN_FAILURE,
          messages: error,
        })
      );
  };
}
function deleteIryouHoken(id) {
  return (dispatch) => {
    IryouhokenApi.del(id).then(() => {
      return dispatch({
        type: riyousyaHokenActionTypes.DELETE_IRYOU_HOKEN_SUCCESS,
        id: id,
      });
    })
    .catch((error) =>
      dispatch({
        type: riyousyaHokenActionTypes.DELETE_IRYOU_HOKEN_FAILURE,
        messages: error,
      })
    );
  };
}
function getIryouhokenMasterByNo(hokenNo, callback) {
  return () => {
    IryouhokenApi.getIryouhoken(hokenNo).then(
      (data) => {
        return callback(data);
      },
      (err) => {
        checkError(err);
      }
    );
  };
}

//高額療養費
function getListKougakuIryouhi(page, riyousyaId) {
  const sid = formatStorage(getMenu()).si;
  return (dispatch) => {
    dispatch({
      type: riyousyaHokenActionTypes.GET_LIST_KOUGAKU_IRYOUHI_REQUEST,
    });
    IryoKougakuFutanApi.list(page, 4, sid, riyousyaId)
      .then((data) =>
        dispatch({
          type: riyousyaHokenActionTypes.GET_LIST_KOUGAKU_IRYOUHI_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: riyousyaHokenActionTypes.GET_LIST_KOUGAKU_IRYOUHI_FAILURE,
          messages: error,
        })
      );
  };
}
function updateKougakuIryouhi(value) {
  // const sid = formatStorage(getMenu()).si;
  return (dispatch) => {
    dispatch({
      type: riyousyaHokenActionTypes.UPDATE_KOUGAKU_IRYOUHI_REQUEST,
    });
    IryoKougakuFutanApi.save(value)
      .then((data) =>
        dispatch({
          type: riyousyaHokenActionTypes.UPDATE_KOUGAKU_IRYOUHI_SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: riyousyaHokenActionTypes.UPDATE_KOUGAKU_IRYOUHI_FAILURE,
          messages: error,
        })
      );
  };
}
function deleteKougakuIryouhi(id) {
  return (dispatch) => {
    IryoKougakuFutanApi.del(id).then(() => {
      return dispatch({
        type: riyousyaHokenActionTypes.DELETE_KOUGAKU_IRYOUHI_SUCCESS,
        id: id,
      });
    })
    .catch((error) =>
      dispatch({
        type: riyousyaHokenActionTypes.DELETE_KOUGAKU_IRYOUHI_FAILURE,
        messages: error,
      })
    );
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}

export {
  deleteKohiJohoCreator,
  deleteKaigoHokenCreator,
  updateKohiJohoCreator,
  updateKaigoHokenCreator,
  loadKaigoHokenMaster,
  getKaigoHokenCreator,
  getKohiJohoCreator,
  //医療保険
  getListIryouHoken,
  updateIryouHoken,
  deleteIryouHoken,
  getIryouhokenMasterByNo,
  //高額医療費
  getListKougakuIryouhi,
  updateKougakuIryouhi,
  deleteKougakuIryouhi,
};
