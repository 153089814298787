import {
  LOAD_KAIGO_YOUGO_REQUEST,
  LOAD_KAIGO_YOUGO_SUCCESS,
  LOAD_KAIGO_YOUGO_ALL_SUCCESS,
  LOAD_KAIGO_YOUGO_ALL,
  LOAD_KAIGO_YOUGO_ERROR,
  UPDATE_KAIGO_YOUGO_REQUEST,
  UPDATE_KAIGO_YOUGO_SUCCESS,
  UPDATE_KAIGO_YOUGO_LIST_SUCCESS,
  UPDATE_KAIGO_YOUGO_ERROR,
  LOAD_KAIGO_YOUGO_ID_REQUEST,
  LOAD_KAIGO_YOUGO_ID_SUCCESS,
  LOAD_KAIGO_YOUGO_ID_ERROR,
  LOAD_KAIGO_YOUGO_MAX_SORT,
  UPDATE_KAIGO_YOUGO_LIST_REQUEST,
} from "../../actions/kaigoYougoMasterActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

export default function kaigoYougoMasterReducers(
  state = {
    meta: {
      page: 1,
      pageSize: 20,
      total: 0,
    },
  },
  action
) {
  switch (action.type) {
    case LOAD_KAIGO_YOUGO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_KAIGO_YOUGO_ALL_SUCCESS:
      return {
        ...state,
        kaigoYougoAllList: action.payload.data,
        loading: false,
      };
    case LOAD_KAIGO_YOUGO_ALL:
      return {
        ...state,
        kaigoYougoList: action.payload.data,
        loading: false,
      };
    case LOAD_KAIGO_YOUGO_SUCCESS:
      return {
        ...state,
        kaigoYougo: action.payload.data,
        meta: {
          page: action.payload.page,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
        },
        notifyDisplay: 0,
        notifyContent: null,
        loading: false,
      };
    case LOAD_KAIGO_YOUGO_ERROR:
      return {
        ...state,
        kaigoYougo: action.payload,
        notifyDisplay: 2,
        notifyContent: action.error.message,
      };
    case LOAD_KAIGO_YOUGO_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_KAIGO_YOUGO_ID_SUCCESS:
      return {
        ...state,
        kaigoYougoSelected: action.payload,
      };
    case LOAD_KAIGO_YOUGO_ID_ERROR:
      return {
        ...state,
      };
    case UPDATE_KAIGO_YOUGO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_KAIGO_YOUGO_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        kaigoYougoInsert: action.payload.data,
      };
    case UPDATE_KAIGO_YOUGO_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        // loadingList: true
      };
    case UPDATE_KAIGO_YOUGO_LIST_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        kaigoYougoSortList: action.payload.data,
        loading: false,
        // loadingList: false
      };
    case LOAD_KAIGO_YOUGO_MAX_SORT:
      return {
        ...state,
        maxSortNum: action.payload.data,
      };
    case UPDATE_KAIGO_YOUGO_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        kaigoYougoInsert: action.payload,
      };
    default:
      return state;
  }
}
