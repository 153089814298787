import {
  DRUG_MASTER_LIST_REQUEST,
  DRUG_MASTER_LIST_SUCCESS,
} from "../../actions/drugMasterActions/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {};

export default function drugMasterReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DRUG_MASTER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DRUG_MASTER_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
