import {
  REFERENCE_NUMBER_SPIN_REQUEST,
  GET_MASTER_NEW_CODE_SUCCESS,
  GET_MASTER_NEW_CODE_ERROR,
  CANCEL_CREATE_MASTER_SUCCESS,
  CANCEL_CREATE_MASTER_ERROR,
} from "../../../../actions/referenceNumberActions/actionName";

export default function referenceNumber(state = {}, action) {
  switch (action.type) {
    case REFERENCE_NUMBER_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MASTER_NEW_CODE_SUCCESS:
      return {
        ...state,
        code: action.data,
        loading: false,
      };

    case GET_MASTER_NEW_CODE_ERROR:
      return {
        ...state,
        code: null,
        loading: false,
      };

    case CANCEL_CREATE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CANCEL_CREATE_MASTER_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
