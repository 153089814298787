import React from "react";
import { Modal, Table, Tooltip } from "antd";
import { connect } from "react-redux";
import { getKyotakuPlan2ToQuote } from "../../../../actions/kyotakuPlan2Actions/actionCreators";

class QuotePlan2Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.visible &&
      this.props.visible !== prevProps.visible &&
      this.props.riyousyaId
    ) {
      this.props.getListKyotakuPlan2(
        this.props.riyousyaId,
        this.props.kyotakuPlan2Id
      );
    }
  }

  handleOk() {
    this.props.handleSelectLastQuote(this.state.selected);
  }

  handleCancel() {
    this.props.hideModalQuotePlan2();
  }

  handleSelectLastQuote(record) {
    this.setState({
      selected: record,
    });
  }

  render() {
    const columns = [
      {
        title: "計画作成日",
        dataIndex: "planDate",
        key: "planDate",
        width: "120px",
      },
    ];
    let dataSource = [];
    dataSource = this.props.kyotakuPlan2s;

    return (
      (<Modal
        open={this.props.visible}
        title={this.props.title}
        onOk={() => this.handleOk()}
        onCancel={() => this.handleCancel()}
        width={500}
      >
        <Table
          id={"last-quote-table"}
          bordered={true}
          showHeader={false}
          size={"small"}
          columns={columns}
          pagination={{ pageSize: 10 }}
          rowKey={"id"}
          dataSource={dataSource}
          onRow={(record) => {
            return {
              onClick: () => this.handleSelectLastQuote(record),
            };
          }}
          rowClassName={(record) =>
            this.state.selected != null && record.id === this.state.selected.id
              ? "pljp-table-row-hover"
              : "pointer-row"
          }
        />
      </Modal>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kyotakuPlan1s: state.kyotakuPlan1.kyotakuPlan1s
      ? state.kyotakuPlan1.kyotakuPlan1s
      : [],
    kyotakuPlan2s: state.kyotakuPlan2.kyotakuPlan2s
      ? state.kyotakuPlan2.kyotakuPlan2s
      : [],
    sisetuDailyPlans: state.sisetuDailyPlan.sisetuDailyPlans
      ? state.sisetuDailyPlan.sisetuDailyPlans
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListKyotakuPlan2: (riyousyaId, kyotakuPlan2Id) =>
      dispatch(getKyotakuPlan2ToQuote(riyousyaId, kyotakuPlan2Id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotePlan2Modal);
