import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, DatePicker, Select, Spin } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import TimeKBCus from "../../../common/component/TimeKBCus";
import { apiUrls } from "../../../common/configs/common-url";
import { ConstSet } from "../../../common/configs/constset";
import { getDateInMonth } from "../../../common/function_common/functionCommon";

const { Option } = Select;

export default class SubMenuInc extends React.Component {
  constructor(props) {
    super(props);

    const preValue = {
      key: this.props.riyousayInfo
        ? this.props.riyousayInfo.riyousya.id
        : undefined,
      label: this.props.riyousayInfo
        ? this.props.riyousayInfo.riyousya.name
        : undefined,
    };

    this.state = {
      startDate: dayjs(localStorage.getItem("nyuDate"), "YYYY-MM-DD"),

      data: [],
      value: preValue,
      fetching: false,
      isVisibleSearchFurigana: false,
      furigana: "",
      isRioyusyaListDisplay: false,
      isLoadingUser: false,
      title: "ふりがな検索",
      redirectAfterSave: false,

      isOpen: false,

      redirect: false,
    };

    // bind place
    this.displayTableType = this.displayTableType.bind(this);

    this.handlePreviewDate = this.handlePreviewDate.bind(this);
    this.handleNextDate = this.handleNextDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.loadingSave !== prevProps.loadingSave) {
      this.setState({
        redirectAfterSave: true,
      });
    }
  }

  displayTableType = () => {
    this.props.displayTableType(!this.state.tbDisType);
  };

  handleChange = (date) => {
    this.props.handleDateChange(
      dayjs(date.toDate()).format(ConstSet.DATE_FORMAT)
    );
  };

  handlePreviewDate = () => {
    this.props.handlePreviewDate();
  };

  handleNextDate = () => {
    this.props.handleNextDate();
  };

  fetchUser = (value) => {
    this.lastFetchId += 1;
    // const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });

    axios(apiUrls.INIT + apiUrls.RIYOUSYA_SEARCH + "?textSearch=" + value, {
      method: "GET",
    })
      .then((res) => {
        // if (fetchId !== this.lastFetchId) {
        //     // for fetch callback order
        //     return;
        // }

        this.setState({
          data: res.data,
          fetching: false,
          isLoadingUser: false,
        });
      })
      .catch(() => { });
  };

  handleChangeSelectUser = (value) => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });

    this.props.reportRiyousya(value.key);
  };

  isSearchFurigana = () => {
    this.setState({
      isVisibleSearchFurigana: true,
      isRioyusyaListDisplay: false,
    });
  };

  handleCancelSearchFurigana = () => {
    const { isRioyusyaListDisplay } = this.state;
    if (isRioyusyaListDisplay) {
      this.setState({
        isVisibleSearchFurigana: true,
        isRioyusyaListDisplay: false,
      });
    }
    else {
      this.setState({
        isVisibleSearchFurigana: false,
      });
    }
  };

  handleOkSearchFurigana = () => {
    this.fetchUser(this.state.furigana);
    this.setState({
      isRioyusyaListDisplay: true,
      isLoadingUser: true,
    });
  };

  onChangeFurigana(value) {
    this.setState({
      furigana: value,
    });
  }

  /**
   *
   * @param {*} furi
   */
  getHandleFurigana = (furi) => {
    /**
     * @TODO Clear State ???
     */

    this.setState({
      furigana: furi,
    });
  };

  handleSelectUser = (record) => {
    this.setState({
      value: { key: record.id, label: record.name },
      isVisibleSearchFurigana: false,
      furigana: "",
      isRioyusyaListDisplay: false,
    });

    this.props.reportRiyousya(record.id.toString());
  };

  handleSaveData = () => {
    this.props.handleSaveData();
  };

  handleOpenColl = () => {
    this.props.handleOpenColl();
  };

  onChangeTime = (timeString) => {
    this.props.onChangeTime(timeString);
  };

  render() {
    const { fetching, data, value } = this.state;
    const { endDate, timeRange, newTime, isOpen } = this.props;

    // const columns = [
    //     {
    //         dataIndex: 'name',
    //         key: 'col1',
    //         width: '50%',
    //     },
    //     {
    //         dataIndex: 'furigana',
    //         key: 'col2',
    //         width: '50%',
    //     },
    // ];

    return (
      <div id="Sub-m-weekly-add">
        <Select
          showSearch
          labelInValue
          value={value}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={this.fetchUser}
          onChange={this.handleChangeSelectUser}
          style={{ width: "180px", marginLeft: "2px", marginRight: "2px" }}
          className="kmu-menu-detail"
          disabled
        >
          {data.map((d) => (
            <Option key={d.id}>{d.name}</Option>
          ))}
        </Select>

        <DatePicker
          style={{
            maxWidth: "180px",
            marginLeft: "2px",
            marginRight: "3px",
            borderRadius: "4px",
          }}
          value={endDate}
          onChange={this.handleChange}
          inputReadOnly={true}
          readOnly={true}
          suffixIcon={
            <span className="text-base-color">
              (
              {getDateInMonth(
                endDate.format("YYYY-MM-DD").split("-")[0],
                endDate.format("YYYY-MM-DD").split("-")[1],
                endDate.format("YYYY-MM-DD").split("-")[2]
              )}
              ){timeRange ? " " + timeRange.substring(0, 5) : " 00:00"}
            </span>
          }
          disabled
        />

        <span style={{ marginLeft: "24px" }}>
          <TimeKBCus
            timeString={newTime ? newTime : timeRange}
            handleInput={this.onChangeTime}
          />
          <Button
            icon={<LegacyIcon type={isOpen ? "minus" : "plus"} />}
            onClick={this.handleOpenColl}
            style={{ color: "#fff", background: "#00838F", marginLeft: "16px" }}
          >
            {isOpen ? "格納" : "展開"}
          </Button>
        </span>

        {/* <Button
                    className="kmu-bu-save"
                    onClick={this.handleSaveData}
                >
                    {
                        loadingSave
                            ?
                            <Icon type="sync" spin />
                            :
                            <Icon type="save" />
                    }
                    登録
                </Button> */}
      </div>
    );
  }
}
