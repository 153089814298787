import React from "react";
import { Row, Col, Divider } from "antd";

const AdlInfo = (props) => {
  return (
    <div>
      {props.kihonAdl && props.kihonAdl.length > 0
        ? props.kihonAdl.map((item, index) => {
            return (
              <Row key={index}>
                <Col span={6}>
                  <h4>{item.title}</h4>
                </Col>
                <Col span={18}>
                  {props.facesheet &&
                  props.facesheet.faceAdl &&
                  props.facesheet.faceAdl.length > 0
                    ? props.facesheet.faceAdl.map((item2) => {
                        return item2.kihonAdlMaster &&
                          item.id == item2.kihonAdlMaster.id
                          ? item2.answer
                          : "";
                      })
                    : null}
                </Col>
                <Divider />
              </Row>
            );
          })
        : null}
    </div>
  );
};

export default AdlInfo;
