export const Sort = {
  Title: "並び替え",
  Row1: "第1キー",
  Row2: "第2キー",
  Row3: "第3キー",
  Row4: "第4キー",
  SortBy: [
    { display: "降順", value: "desc" },
    { display: "昇順", value: "asc" },
  ],
  DocumentList: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
  ],
  Plan1: [
    { display: "初回作成日", value: "firstPlanDate" },
    { display: "作成日", value: "planDate" },
    { display: "同意日", value: "plan1DouiDate" },
    { display: "作成者", value: "tanto" },
    { display: "最新", value: "progressF" },
    { display: "区分", value: "kubun" },
  ],
  Plan2: [
    { display: "予定更新月", value: "renewYears" },
    { display: "作成日", value: "planDate" },
    { display: "同意日", value: "plan2DouiDate" },
    { display: "最新", value: "progressF" },
  ],
  WeeklyPlan: [
    { display: "最新", value: "progressF" },
    { display: "作成日", value: "planDate" },
  ],
  Monitoring: [
    { display: "実施日", value: "monitoringDate" },
    { display: "担当者", value: "tanto" },
  ],
  Meeting: [
    { display: "開催日", value: "kaisaiDate" },
    { display: "時間", value: "kaisaiTime" },
    { display: "担当者", value: "tanto" },
  ],
  InquiryRequest: [
    { display: "照会日", value: "syoukaiDate" },
    { display: "記録日", value: "date" },
    { display: "担当者", value: "tantoId" },
  ],
  Progress: [
    { display: "記録日", value: "kirokuDate" },
    { display: "時間", value: "kirokuTime" },
    { display: "記録区分", value: "kirokuKubun" },
    { display: "計画作成者", value: "planTanto" },
    { display: "記録作成者", value: "kirokuTanto" },
  ],
  Task: [{ display: "作成日", value: "date" }],
  WeeklyPlanSchedule: [
    { display: "曜日", value: "youbi" },
    { display: "開始時間", value: "startTime" },
    { display: "終了時間", value: "endTime" },
    { display: "事業所番号", value: "jigyousyoNoServiceKikan" },
    { display: "サービス内容", value: "serviceNaiyou" },
  ],
  WeeklyPlanRental: [
    { display: "事業所番号", value: "serviceKikanMaster.jigyousyoNo" },
    { display: "事業所名", value: "serviceKikanMaster.furigana" },
    { display: "サービス名", value: "serviceCd" },
    { display: "商品名", value: "rentalSyohinMaster.furigana" },
    { display: "単位数", value: "serviceTani" },
  ],
  UseSlip: [
    { display: "作成年月", value: "nengetu" },
    { display: "採用", value: "adoptf" },
    { display: "作成年月日", value: "riyouhyouDate" },
    { display: "利用票作成担当者", value: "tantoId" },
  ],
  Facesheet: [
    { display: "作成日", value: "date" },
    { display: "相談日", value: "soudanDate" },
  ],
  KihonCheck: [{ display: "作成日", value: "date" }],
  YobousienPlan: [
    { display: "最新", value: "saisinF" },
    { display: "作成日", value: "planDate" },
    { display: "初回作成日", value: "firstPlanDate" },
  ],
  Hyoukahyou: [
    { display: "評価日", value: "date" },
    { display: "担当者", value: "tantoId" },
  ],
  DefaultValue: {
    key1: "0",
    value1: "asc",
    key2: "0",
    value2: "asc",
    key3: "0",
    value3: "asc",
    key4: "0",
    value4: "asc",
  },
  DefaultFiveValue: {
    key1: "0",
    value1: "asc",
    key2: "0",
    value2: "asc",
    key3: "0",
    value3: "asc",
    key4: "0",
    value4: "asc",
    key5: "0",
    value5: "asc",
  },
};
