import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";
import {
  getMenu,
  formatStorage,
} from "../common/function_common/functionCommon";

let KihonAdlMasterApi = {
  all() {
    return axios(apiUrls.INIT + apiUrls.KIHON_ADL_MASTER_ALL, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("tokens");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/management/login");
          }

          return err;
        }
      });
  },
};

let SettingMasterAPI = {
  all() {
    return axios(apiUrls.INIT + apiUrls.SETTING_MASTER_ALL, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("tokens");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/management/login");
          }

          return err;
        }
      });
  },

  allNew(singleTextTitle, singleText) {
    const key = getMenu();
    let mainSID = formatStorage(key).si;
    return axios(
      apiUrls.INIT +
        apiUrls.SETTING_MASTER_ALL_NEW +
        `?systemId=${mainSID}&singleTextTitle=${singleTextTitle}&singleText=${singleText}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem("tokens");
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace("/management/login");
          }

          return err;
        }
      });
  },
  getByName(settingName) {
    return axios(apiUrls.INIT + apiUrls.GET_SETTING_MASTER, {
      method: "GET",
      params: { settingName },
    }).then((res) => res.data);
  },

  updateSettingMster(id, name, naiyou) {
    return axios(apiUrls.INIT + apiUrls.PUT_SETTING_MASTER, {
      method: "PUT",
      data: {
        id: id,
        settingName: name,
        naiyou: naiyou,
      },
    }).then((res) => res.data);
  },

  createSettingMaster(name, naiyou) {
    return axios(apiUrls.INIT + apiUrls.POST_SETTING_MASTER, {
      method: "post",
      data: {
        settingName: name,
        naiyou: naiyou,
      },
    }).then((res) => res.data);
  },
};
export { SettingMasterAPI, KihonAdlMasterApi };
