import {
  ZIP_MASTER_SEARCH_REQUEST,
  ZIP_MASTER_SEARCH_ERROR,
  ZIP_MASTER_SEARCH_SUCCESS,
} from "../../actions/zipMasterActions/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  loading: false,
  zip: [],
};

export default function zipMasterReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ZIP_MASTER_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ZIP_MASTER_SEARCH_SUCCESS:
      return {
        ...state,
        zip: action.payload && !action.payload.response ? action.payload : [],
        loading: false,
      };

    case ZIP_MASTER_SEARCH_ERROR:
      return {
        ...state,
        zip: [],
        loading: true,
      };

    default:
      return state;
  }
}
