/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-nested-ternary */
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Checkbox, Col, Modal, Pagination, Row, Table } from "antd";
import _ from "lodash";
import React from "react";
import { ConstSet } from "../../../common/configs/constset";
import {
  convert_jititai,
  convert_wareki_3,
} from "../../../common/function_common/functionCommon";
import { HokenTableForm2 } from "./HokenTableForm2";

export default class HokenTable2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandRowIndex: 0,
      deleteVisible: false,
      delRecordId: 0,
      newId: -1,
      rowIndex: null,
      checked: false,
      inputVisible: false,
      recordInput: [],
      futanMasterData: "",
      updateVisible: false,
    };

    // bind place
  }

  expandRow = (recordId) => {
    const { expandRowIndex } = this.state;

    if (expandRowIndex === recordId) {
      this.setState({
        expandRowIndex: 0,
      });
    }
    else {
      this.setState({
        expandRowIndex: recordId,
      });
    }
  };

  showDeleteConfirm = (recordId, index) => {
    this.setState({
      deleteVisible: true,
      delRecordId: recordId,
      rowIndex: index,
      checked: false,
    });
  };

  // 修正
  handleEdit = (record) => {
    if (record.futansyaNo) {
      this.props.getKohifutanMaster(record.futansyaNo, (data) => {
        this.setState({
          futanMasterData: data?.id ? data.futanName : null,
        });
      });
    }
    this.setState({
      inputVisible: true,
      recordInput: record,
    });
  };

  closeDeleteConfirm = () => {
    this.setState({
      deleteVisible: false,
      delRecordId: 0,
      rowIndex: null,
      checked: false,
    });
  };

  handleConfirmDelete = () => {
    const { delRecordId, rowIndex } = this.state;
    const id = _.clone(delRecordId);

    this.props.handleDelHokenTable(id, rowIndex);

    this.setState({
      deleteVisible: false,
      delRecordId: 0,
      rowIndex: null,
      checked: false,
      expandRowIndex: 0,
    });
  };

  handleChangeCheckbox = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked,
    });
  };

  // 新規追加
  handleAddNew = () => {
    this.setState({
      futanMasterData: "",
      inputVisible: true,
      recordInput: [],
    });
  };

  closeInput = () => {
    this.setState({
      inputVisible: false,
      recordInput: [],
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  updateCancel = () => {
    this.setState({
      updateVisible: false,
    });
  };
  // 必須確認OK
  updateOk = () => {
    this.handleInputModalOk();
    setTimeout(() => {
      this.setState({
        updateVisible: false,
      });
    }, 100);
  };

  // 必須無視の保存
  handleInputModalOk = () => {
    // e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.update(values);
    });
  };

  handleInput = () => {
    // e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (
        !values.futansyaNo ||
        values.futansyaNo == "0" ||
        !values.jukyusyaNo ||
        values.jukyusyaNo == "0"
      ) {
        this.setState({
          updateVisible: true,
        });
      }
      else {
        this.update(values);
      }
    });
  };

  update = (values) => {
    if (!values.id) {
      values.id = this.state.newId;
    }

    if (values.syutokuDate) {
      values.syutokuDate = values.syutokuDate.format(ConstSet.DATE_FORMAT);
    }

    if (values.yukoDate) {
      values.yukoDate = values.yukoDate.format(ConstSet.DATE_FORMAT);
    }

    values.id = values.id.toString();
    values.futangaku = values.futangaku ? values.futangaku.toString() : "0";
    values.jukyusyaNo = values.jukyusyaNo ? values.jukyusyaNo.toString() : "";
    values.futansyaNo = values.futansyaNo ? values.futansyaNo.toString() : "";
    values.iryouFutangaku = values.iryouFutangaku
      ? values.iryouFutangaku.toString()
      : "0";
    values.iryouFutanwari = values.iryouFutanwari
      ? values.iryouFutanwari.toString()
      : "0";
    values.kyufu = values.kyufu ? values.kyufu.toString() : "0";
    values.riyousyaId = values.riyousyaId.toString();
    values.syogaimei = values.syogaimei ? values.syogaimei.toString() : "";

    values.syokuhiFutangaku = values.syokuhiFutangaku
      ? values.syokuhiFutangaku.toString()
      : "0";
    values.syubetu = values.syubetu ? values.syubetu.toString() : "";
    values.tokyu = values.tokyu ? values.tokyu.toString() : "";
    values.jititaif = values.jititaif1 ? "1" : values.jititaif2 ? "2" : "0";
    values.tukiKohitanf = values.tukiKohitanf ? "1" : "0";
    values.serviceSyurui = values.serviceSyurui
      ? values.serviceSyurui.replace(/,/g, ";")
      : "";

    this.props.updateHokenTable2(values);

    this.setState({
      inputVisible: false,
      recordInput: [],
      expandRowIndex: 0,
      newId: --this.state.newId,
    });
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (values.date) {
        values.date = values.date.format(ConstSet.DATE_FORMAT);
      }
      this.setState({
        recordInput: values,
      });
    });
    this.props.yougoMasterUpdate(key, naiyou);
  };

  onChangePag = (page) => {
    this.props.onChangekohiPag(page);
  };

  /**
   * Update file to State, Prepare data to save when click on 更新 button
   * @param {*} value 値
   * @param {*} type どの箇所か
   */
  getHandleFormInput = (value, type) => {
    const prepareData = this.state.recordInput;
    const objData = {};

    // handle input number
    if (
      type === "futansyaNo" ||
      type === "jukyusyaNo" ||
      type === "kyufu" ||
      type === "futangaku" ||
      type === "iryouFutangaku" ||
      type === "syokuhiFutangaku"
    ) {
      value = value ? value : "0";
    }

    objData[type] = Number(value);
    this.setState({
      recordInput: Object.assign({}, prepareData, objData),
      isFormUpdate: true,
    });
  };

  render() {
    const {
      data,
      riyousyaId,
      page,
      pageTotal,
    } = this.props;
    const { expandRowIndex, recordInput } = this.state;

    const columns = [
      {
        dataIndex: "1",
        key: "1",
        render: (text, record, index) => {
          return (
            <div>
              <div
                onClick={() => this.expandRow(index + 1)}
                className="riyou-hoken-table-row"
              >
                <Row>
                  <Col md={5}>
                    <span>公費番号: </span>
                    <span>{record.kohiNo}</span>
                  </Col>
                  <Col md={6}>
                    <span>負担者番号: {record.futansyaNo}</span>
                  </Col>
                  <Col md={6}>
                    <span>
                      取得日: {convert_wareki_3(record.syutokuDate).dis}
                    </span>
                  </Col>
                  <Col md={7}>
                    <span>
                      有効期限: {convert_wareki_3(record.yukoDate).dis}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <span>受給者番号: </span>
                    <span>{record.jukyusyaNo}</span>
                  </Col>
                  <Col md={6}>
                    <span>給付率 {record.kyufu}%</span>
                  </Col>
                  <Col md={13}>
                    <span>
                      {record ? convert_jititai(record.jititaif) : ""}
                    </span>
                  </Col>
                </Row>
              </div>
              {expandRowIndex && expandRowIndex === index + 1 ? (
                <div>
                  <Row>
                    <Col span={24} md={24}>
                      <span style={{ marginLeft: 5 }}>該当サービス: </span>
                      <span style={{ fontSize: 12 }}>
                        {record.serviceSyurui}
                      </span>
                    </Col>
                  </Row>
                  <div style={{ float: "right", padding: "0 5px 5px 0" }}>
                    <Button
                      icon={<LegacyIcon type={"delete"} />}
                      style={{ color: "white", backgroundColor: "gray" }}
                      onClick={() => this.showDeleteConfirm(record.id, index)}
                    >
                      {"削除"}
                    </Button>
                    <Button
                      icon={<LegacyIcon type={"edit"} />}
                      style={{ color: "white", backgroundColor: "#00838F" }}
                      onClick={() => this.handleEdit(record)}
                    >
                      {"修正"}
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          );
        },
      },
    ];

    return (
      (<div>
        <Row style={{ justifyContent: "flex-end" }}>
          <Button
            icon={<LegacyIcon type={"plus"} />}
            style={{
              color: "white",
              backgroundColor: "#dc0606",
              marginBottom: 8,
            }}
            onClick={() => this.handleAddNew()}
          >
            {"追加"}
          </Button>
        </Row>
        <Table
          dataSource={data}
          columns={columns}
          showHeader={false}
          className="riyou-hoken-table"
          rowKey={(record, index) => index}
          pagination={false}
        />
        <Pagination
          onChange={this.onChangePag}
          total={pageTotal * 10}
          pageSize={10}
          current={page}
          style={{ textAlign: "right", marginTop: 16 }}
        />
        {/* modal del */}
        <Modal
          open={this.state.deleteVisible}
          title="削除確認（同意するにチェックしてOKボタンを押してください）"
          onCancel={() => this.closeDeleteConfirm()}
          footer={[
            <Button
              disabled={!this.state.checked}
              key="delete"
              type={"primary"}
              onClick={() => this.handleConfirmDelete()}
            >
              OK
            </Button>,
            <Button
              key="canceldelete"
              onClick={() => this.closeDeleteConfirm()}
            >
              Cancel
            </Button>,
          ]}
        >
          <div style={{ backgroundColor: "aliceblue" }}>
            <Checkbox
              checked={this.state.checked}
              onChange={() => this.handleChangeCheckbox()}
              value={this.state.checked}
            >
              {"削除に同意する"}
            </Checkbox>
          </div>
        </Modal>
        {/* modal input */}
        <Modal
          className="hoken1-input-form"
          open={this.state.inputVisible}
          title={false}
          closable={false}
          maskClosable={false}
          onCancel={() => this.closeInput()}
          footer={null}
          // footer={[
          //     <Button key="input" type={"primary"}
          //         onClick={() => this.handleInput()}>
          //         OK
          //     </Button>,
          //     <Button key="cancelInput" onClick={() => this.closeInput()}>
          //         キャンセル
          //     </Button>
          // ]}

          width={960}
        >
          <HokenTableForm2
            data={recordInput}
            wrappedComponentRef={(formRef) => this.saveFormRef(formRef)}
            riyousyaId={riyousyaId}
            futanMasterData={this.state.futanMasterData}
            yougoMasterUpdate={this.yougoMasterUpdate}
            handleFormInput={this.getHandleFormInput}
            getKaigoHokenMaster={this.props.getHokenName}
            // loadKohifutan={this.props.loadKohifutan}
            getKohifutanMaster={this.props.getKohifutanMaster}
            getKohiMaster={this.props.getKohiMaster}
            update={this.update}
            closeInput={this.closeInput}
            updateOk={this.handleInputModalOk}
          />
          {/* <Modal
                        visible={this.state.updateVisible}
                        title={false}
                        closable={false}
                        onOk={() => this.updateOk()}
                        onCancel={() => this.updateCancel()}
                        // footer={null}
                    >
                        <div style={{}}>
                            <div style={{}}>
                                <p>必須項目が未入力ですがそのまま登録しますか？</p>
                            </div>
                        </div>
                    </Modal> */}
        </Modal>
      </div>)
    );
  }
}
