import { AutoComplete, Row } from "antd";
import React from "react";
import { connect } from "react-redux";

class ListWeatherCommon extends React.Component {
  constructor(props) {
    super(props);
    const weather = props.value || null;
    this.state = {
      weather,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { weather } = this.state;
    if (nextProps.value != weather) {
      this.setState({
        weather: nextProps.value,
      });
    }
  }

  handleChangeWeather(weather) {
    this.setState({
      weather,
    });
    this.triggerChange(weather);
  }

  onSearch(weather) {
    this.setState({
      weather,
    });
    this.triggerChange(weather);
  }

  onBlur() {
    this.setState({
      weather: this.state.weather,
    });
    this.triggerChange(this.state.weather);
  }

  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  render() {
    const { weather } = this.state;
    const { yougoMasterWeatherNaiyou } = this.props;
    const data = Array.isArray(yougoMasterWeatherNaiyou)
      ? yougoMasterWeatherNaiyou.map((naiyou) => {
        return naiyou.naiyou;
      })
      : [];
    return (
      <div>
        <Row>
          <AutoComplete
            value={weather}
            onChange={(weather) => this.handleChangeWeather(weather)}
            dataSource={data}
            style={{ width: "126px" }}
            filterOption={
              (inputValue) => inputValue && inputValue.length === 0
              // option.props.children
              //   .toUpperCase()
              //   .indexOf(inputValue.toUpperCase()) !== -1
            }
          ></AutoComplete>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    yougoMasterWeatherNaiyou: state.yougoMasterNaiyou.yougoMasterWeatherNaiyou,
  };
};
export default connect(mapStateToProps, null)(ListWeatherCommon);
