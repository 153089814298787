import React from "react";
import { connect } from "react-redux";
import Table from "antd/es/table";
import { Button, Input, Modal, Row } from "antd";
import { openNotificationWithIcon } from "../function/alert";

const columns = [{ dataIndex: "room", key: "room" }];
class FilterRoomCommon extends React.Component {
  constructor(props) {
    super(props);
    const roomKind = props.roomBunrui;
    this.state = {
      visible: false,
      preValue: null,
      title: "ふりがな検索",
      rowActive: null,
      roomKind,
      roomKindSelected: null,
      visibleConfirm: false,
      notifyDisplay: 0,
      changeRoom: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ roomKind: nextProps.roomBunrui });
    if (nextProps.notifyDisplay !== this.state.notifyDisplay) {
      this.setState({
        notifyDisplay: nextProps.notifyDisplay,
      });
    }
  }
  componentDidUpdate(prevProps) {
    const { notifyDisplay } = this.props;
    if (prevProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1 && this.state.changeRoom == true) {
        const { roomKindSelected } = this.state;
        this.props.handleChangeRoomBunrui(roomKindSelected);
        this.setState({
          visible: false,
          visibleConfirm: false,
          rowActive: null,
          roomKindSelected: null,
          changeRoom: false,
        });
      }
      if (notifyDisplay === 2 && this.state.changeRoom == true) {
        const { roomKind } = this.state;
        this.setState({
          visible: false,
          visibleConfirm: false,
          rowActive: null,
          roomKindSelected: roomKind,
          changeRoom: false,
        });
      }
    }
  }
  handleShowModal() {
    const { roomBunrui } = this.props;
    this.setState({
      visible: true,
    });
    if (roomBunrui != null) {
      this.setState({
        roomKindSelected: roomBunrui,
      });
    }
  }

  onCancelModalRoom() {
    this.setState({
      visible: false,
      roomKindSelected: null,
      rowActive: null,
    });
  }

  onOkModalRoom() {
    const { roomKindSelected } = this.state;
    const { roomBunrui } = this.props;
    if (roomKindSelected == null) {
      openNotificationWithIcon("error", "値を選択していません");
    } else if (roomBunrui != roomKindSelected) {
      if (this.props.isChangeForm() || this.props.isChange) {
        this.setState({
          visibleConfirm: true,
        });
      } else {
        const { roomKindSelected } = this.state;
        this.props.handleChangeRoomBunrui(roomKindSelected);
        this.setState({
          visible: false,
          roomKindSelected: null,
          rowActive: null,
        });
      }
    } else {
      this.setState({
        visible: false,
        roomKindSelected: null,
        rowActive: null,
      });
    }
  }

  onCancelConfirm() {
    const { roomKindSelected } = this.state;
    this.props.handleChangeRoomBunrui(roomKindSelected);
    this.setState({
      visibleConfirm: false,
      visible: false,
      roomKindSelected: null,
      rowActive: null,
    });
    this.props.resetIsChange();
  }
  onHideConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleCancelFilterRoom() {
    const { roomKind } = this.state;
    if (roomKind != null) {
      if (this.props.isChangeForm() || this.props.isChange) {
        this.setState({
          visibleConfirm: true,
          roomKindSelected: null,
          rowActive: null,
        });
      } else {
        this.props.handleChangeRoomBunrui(null);
        this.setState({
          roomKindSelected: null,
          rowActive: null,
        });
      }
    }
  }

  async onOkConfirm() {
    await this.setState({
      visible: false,
      visibleConfirm: false,
      rowActive: null,
      changeRoom: true,
    });
    this.props.handleSaveData();
  }

  handleSelectRoom = (record, rowIndex) => {
    this.setState({
      preValue: { key: record.id, label: record.naiyou },
      rowActive: rowIndex,
      roomKindSelected: record.room,
    });
  };

  render() {
    const { yougoMasterRoomNaiyou } = this.props;
    const { rowActive, visibleConfirm, roomKind, visible, roomKindSelected } =
      this.state;
    const data = Array.isArray(yougoMasterRoomNaiyou)
      ? yougoMasterRoomNaiyou.map((room) => {
          return {
            key: room.key,
            room: room.naiyou,
          };
        })
      : [];
    return (
      (<div>
        <span
          style={{
            color: "black",
            fontSize: "smaller",
            position: "absolute",
            top: "-4px",
            left: "1px",
            zIndex: "1",
            backgroundColor: "white",
            lineHeight: "1.5em",
            width: "100px",
            paddingLeft: "2px",
          }}
        >
          {"部屋分類絞り込み"}
        </span>
        <Input
          style={{ marginRight: "5px", width: "calc(100% - 162px)" }}
          readOnly
          value={roomKind}
        />
        <Button
          onClick={() => this.handleShowModal()}
          style={{ marginRight: "5px" }}
          type={"primary"}
        >
          部屋分類
        </Button>
        <Button
          onClick={() => this.handleCancelFilterRoom()}
          style={{ backgroundColor: "#848484", color: "white" }}
        >
          解除
        </Button>
        <Row>
          <Modal
            title={"次の中から選択してください"}
            open={visible}
            onCancel={() => this.onCancelModalRoom()}
            onOk={() => this.onOkModalRoom()}
            okText={"Ok"}
          >
            <Table
              className={"tableRoom"}
              style={{ marginTop: "25px", cursor: "pointer" }}
              columns={columns}
              bordered={true}
              size={"small"}
              dataSource={data}
              showHeader={false}
              rowKey={"key"}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    this.handleSelectRoom(record, rowIndex);
                  }, // click row
                };
              }}
              rowClassName={(record, index) =>
                (rowActive != null && index === rowActive) ||
                roomKindSelected === record.room
                  ? "pljp-table-row-hover"
                  : ""
              }
            ></Table>
          </Modal>
        </Row>
        <Row>
          <Modal
            title={"変更を確認する"}
            open={visibleConfirm}
            onCancel={() => this.onHideConfirm()}
            footer={[
              <Button key="cancel" onClick={() => this.onCancelConfirm()}>
                キャンセル
              </Button>,
              <Button
                key="ok"
                type="primary"
                onClick={() => this.onOkConfirm()}
              >
                Ok
              </Button>,
            ]}
          >
            <p>保存しますか？</p>
          </Modal>
        </Row>
      </div>)
    );
  }
}
const mapStateToProps = (state) => {
  return {
    yougoMasterRoomNaiyou: state.yougoMasterNaiyou.yougoMasterRoomNaiyou,
    notifyDisplay: state.dutyReport.notifyDisplay,
    notifyContent: state.dutyReport.notifyContent,
  };
};
export default connect(mapStateToProps, null)(FilterRoomCommon);
