export const KOHIFUTAN_MASTER_SPIN_REQUEST = "KOHIFUTAN_MASTER_SPIN_REQUEST";

export const KOHIFUTAN_MASTER_LOAD_REQUEST = "KOHIFUTAN_MASTER_LOAD_REQUEST";
export const KOHIFUTAN_MASTER_CREATE_REQUEST =
  "KOHIFUTAN_MASTER_CREATE_REQUEST";
export const KOHIFUTAN_MASTER_UPDATE_REQUEST =
  "KOHIFUTAN_MASTER_UPDATE_REQUEST";
export const KOHIFUTAN_MASTER_INIT_REQUEST = "KOHIFUTAN_MASTER_INIT_REQUEST";
export const KOHIFUTAN_MASTER_UPDATELIST_REQUEST =
  "KOHIFUTAN_MASTER_UPDATELIST_REQUEST";

export const LOAD_KOHIFUTAN_MASTER_SUCCESS = "LOAD_KOHIFUTAN_MASTER_SUCCESS";
export const LOAD_KOHIFUTAN_MASTER_ERROR = "LOAD_KOHIFUTAN_MASTER_ERROR";

export const LOAD_MAX_SORT_SUCCESS = "LOAD_MAX_SORT_SUCCESS";
export const LOAD_MAX_SORT_ERROR = "LOAD_MAX_SORT_ERROR";

export const LOAD_KOHIFUTAN_MASTER_INIT_SUCCESS =
  "LOAD_KOHIFUTAN_MASTER_INIT_SUCCESS";
export const LOAD_KOHIFUTAN_MASTER_INIT_ERROR =
  "LOAD_KOHIFUTAN_MASTER_INIT_ERROR";

export const CREATE_KOHIFUTAN_MASTER_SUCCESS =
  "CREATE_KOHIFUTAN_MASTER_SUCCESS";
export const CREATE_KOHIFUTAN_MASTER_ERROR = "CREATE_KOHIFUTAN_MASTER_ERROR";

export const UPDATE_KOHIFUTAN_MASTER_SUCCESS =
  "UPDATE_KOHIFUTAN_MASTER_SUCCESS";
export const UPDATE_KOHIFUTAN_MASTER_ERROR = "UPDATE_KOHIFUTAN_MASTER_ERROR";

export const UPDATE_LIST_KOHIFUTAN_MASTER_SUCCESS =
  "UPDATE_LIST_KOHIFUTAN_MASTER_SUCCESS";
