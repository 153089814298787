import { GET_MENU_HISTORY_ASSESSMENT } from '../../actions/assessmentActions/actionName';

export default function AssessmentReducers(state = {
}, action) {
    switch (action.type) {
        case GET_MENU_HISTORY_ASSESSMENT:
            return {
                ...state,
                menudata: action.data
            };
        default:
            return state;
    }
}