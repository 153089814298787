import { EVENT_PLAN_RIYOUSYA } from "../../actions/eventPlanActions/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
export default function eventPlanReducers(state = {}, action) {
  switch (action.type) {
    case EVENT_PLAN_RIYOUSYA:
      return {
        ...state,
        eventWRiyousya: action.payload,
      };
    default:
      return state;
  }
}
