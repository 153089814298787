import React from "react";
import { Radio } from "antd";

const RadioGroup = Radio.Group;

export default class ShubetuKB extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.handleOnchange(e.target.value);
  }

  render() {
    return (
      <div className="meal-raido-list">
        <RadioGroup onChange={this.onChange} value={this.props.defaltValue}>
          <Radio value={"朝"}>朝</Radio>
          <Radio value={"昼"}>昼</Radio>
          <Radio value={"夕"}>夕</Radio>
          <Radio value={"間食"}>間食</Radio>
          <Radio value={"経管栄養"}>経管栄養</Radio>
        </RadioGroup>
      </div>
    );
  }
}
