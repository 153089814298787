import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const DocumentListApi = {
  loadAllDocumentList(params) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    return axios
      .get(apiUrls.GET_ALL_DOCUMENT_LIST, {
        params: {
          sid: menu.si,
          systemName: menu.sn,
          mainSID: menu.si,
          riyousyaId: params.riyousyaId,
          inUsed: params.inUsed,
          searchName: params.searchName,
          page: params.page,
          sort: params.sort,
        },
      })
      .then((res) => {
        return res.data;
      });
  },
};
export { DocumentListApi };
