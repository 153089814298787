import React, { Fragment } from "react";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import {
  Input,
  Button,
  Popover,
  Table,
  Popconfirm,
  Divider,
  Modal,
  Row,
  Select,
  Col,
} from "antd";
import { connect } from "react-redux";
import { convertArrStrToArrObj } from "../../../common/function_common/functionDisplay";
import _ from "lodash";
import TextInputArea from "./TextInputArea";
import TextInput from "./TextInput";
const EditableCell = ({
  editIndex,
  editValue,
  value,
  index,
  onChange,
  Expansion,
  sort,
}) => (
  <div>
    {editIndex === index ? (
      <Input
        style={{ margin: "-5px 0" }}
        defaultValue={editValue}
        onBlur={(e) => onChange(e.target.value)}
      />
    ) : Expansion && !sort ? (
      <div
        style={{
          maxWidth: "240px",
          whiteSpace: "normal",
          overflowWrap: "normal",
        }}
      >
        {value}
      </div>
    ) : (
      <div
        style={{
          maxWidth: "240px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </div>
    )}
  </div>
);

class TextAreaSelectYougo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editIndex: null,
      editValue: "",
      editKey: null,
      newText: "",
      visible: false,
      yougoVisible: false,
      rowActive: null,
      record: null,
      bunrui: "",
      name: "",
      sort: false,
      sortTable: [],
      sortTableCopy: [],
      // selected: null,
      Expansion: false,
    };

    // bind place
  }

  /**
   * Render colum of table
   *
   * (EDITABLE TABLE)
   */
  renderColumns = (record, index) => {
    const { editIndex, editValue, Expansion, sort } = this.state;

    return (
      <EditableCell
        sort={sort}
        Expansion={Expansion}
        editIndex={editIndex}
        editValue={editValue}
        value={record && record.naiyou ? record.naiyou : ""}
        index={index}
        onChange={(value) => this.handleChange(value)}
      />
    );
  };

  /**
   * When input change on EDITING MODE
   *
   */
  handleChange = (value) => {
    this.setState({
      editValue: value,
    });
  };

  /**
   * Handle Row Table
   *
   */
  edit = (record, index) => {
    this.setState({
      editValue: record.naiyou,
      editIndex: index,
      editKey: record.key,
    });
  };

  /**
   * Save function after eddting row
   *
   */
  save = () => {
    const { editIndex, editValue, editKey } = this.state;
    if (editValue) {
      const { dataSelect } = this.props;

      // let tmpUpdate = dataSelect

      if (dataSelect[editKey]) {
        let tmpUpdate = dataSelect;
        tmpUpdate[editKey] = editValue;

        this.props.yougoMasterUpdate(tmpUpdate.join(";"));
      }
    }

    // reset state
    this.setState({
      editIndex: null,
      editValue: "",
      editKey: null,
    });
  };

  /**
   * Remove record of yougo_master naiyou
   *
   */
  delete = (record, index) => {
    const { dataSelect } = this.props;
    let preNaiyou = "";
    for (let i = 0; i < dataSelect.length; i++) {
      if (i !== record.key) {
        if (!preNaiyou) {
          preNaiyou = dataSelect[i];
        } else {
          preNaiyou = preNaiyou + ";" + dataSelect[i];
        }
      }
    }

    this.props.yougoMasterUpdate(preNaiyou);
  };

  /**
   * Cancel table input mode. (Remove input mode on row of table)
   *
   */
  cancel = (key) => {
    // reset state
    this.setState({
      editIndex: null,
      editValue: "",
      sort: false,
    });
  };

  /**
   * Input for Add new function
   *
   */
  handleOnchangeNew = (value) => {
    this.setState({
      newText: value,
    });
  };

  /**
   * Add new record when click 追加 button(title place)
   *
   */
  add = () => {
    const { newText } = this.state;
    if (newText) {
      const { dataSelect } = this.props;
      let preNaiyou = newText;

      if (dataSelect) {
        for (let i = 0; i < dataSelect.length; i++) {
          preNaiyou = preNaiyou + ";" + this.props.dataSelect[i];
        }
      }

      this.props.yougoMasterUpdate(preNaiyou);

      // reset
      this.setState({
        newText: "",
      });
    }
  };

  /**
   * trigger dirrectly from input
   *
   */
  select = (record) => {
    const { value, isOveride } = this.props;
    let tpm = "";

    if (record && record.naiyou) {
      if (value && !isOveride) {
        tpm = value + "、" + record.naiyou;
      } else {
        tpm = record.naiyou;
      }
    }

    this.setState({
      Expansion: false,
    });

    this.triggerChange(tpm);
  };

  /**
   * triiger from table select row (yougo_master)
   *
   */
  handleSelectChange = (value) => {
    //valueは入力した値
    this.props.onChangeBunrui(value);
    // this.triggerChange(value);
  };

  /**
   * Should provide an event to pass value to Form.
   *
   */
  triggerChange = (changedValue) => {
    // const { onChange } = this.props;
    // if (onChange) {
    //     onChange(changedValue);
    // }
    this.props.onChangeBunrui(changedValue);
  };

  handleCancel = () => {
    // reset
    this.setState({
      visible: false,
      record: null,
      bunrui: "",
      name: "",
    });
  };

  onClickCellEvent = (record) => {
    this.setState({
      rowActive: record.id,
      record: record,
    });
  };

  onChangePopover = (value) => {
    if (value == false) {
      this.setState({
        sort: false,
        Expansion: false,
      });
    }

    this.setState({
      yougoVisible: value,
    });
  };

  handleSelectRow(record) {
    //選択したレコードをステートselectedに保存
    this.setState({
      selected: record,
    });
  }

  sortOn = () => {
    const { dataSelect } = this.props;
    this.setState({
      sort: true,
      sortTable: dataSelect,
    });
  };
  sortOff = () => {
    this.setState({
      sort: false,
      sortTable: [],
    });
  };
  sortSave = () => {
    const { sortTable } = this.state;
    this.props.yougoMasterUpdate(sortTable.join(";"));

    this.setState({
      sort: false,
      sortTable: [],
    });
  };
  sortTop = (sortTable, record, index) => {
    if (record.key != 0) {
      let newIndex = 0;
      const copyArray = sortTable.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable: copyArray,
      });
    }
  };
  sortUp = (sortTable, record, index) => {
    if (record.key != 0) {
      //1
      let newIndex = record.key - 1;
      const copyArray = sortTable.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable: copyArray,
      });
    }
  };
  sortDown = (sortTable, record, index) => {
    if (sortTable.length - 1 != record.key) {
      let newIndex = record.key + 1;
      const copyArray = sortTable.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable: copyArray,
      });
    }
  };
  sortBottom = (sortTable, record, index) => {
    if (sortTable.length - 1 != index) {
      let newIndex = sortTable.length - 1;
      const copyArray = sortTable.slice();
      const element = copyArray[record.key];
      copyArray.splice(record.key, 1);
      copyArray.splice(newIndex, 0, element);
      this.setState({
        sortTable: copyArray,
      });
    }
  };

  handleExpandTab = () => {
    //パネル全体
    const { Expansion } = this.state;

    if (Expansion) {
      this.setState({
        Expansion: false,
      });
    } else {
      this.setState({
        Expansion: true,
      });
    }
  };

  render() {
    const {
      rowSet,
      value,
      isSelect,

      dataSelect,
      dataExt,
    } = this.props;

    const {
      newText,
      editIndex,
      visible,
      rowActive,
      record,
      yougoVisible,
      sort,
      sortTable,
      Expansion,
    } = this.state;

    const columns = [
      {
        title: "naiyou",
        dataIndex: "naiyou",
        width: 250,
        key: "naiyou",
        render: (textEx, record, index) => this.renderColumns(record, index),
      },
      {
        title: "行動",
        dataIndex: "operation",
        width: 150,
        key: "operation",
        render: (textEx, record, index) => {
          return (
            <div className="editable-row-operations">
              {sort === true ? ( //<Icon type="caret-up" rotate={isActive ? 180 : 0} />style={{ fontSize: '16px', color: '#08c' }}
                <span>
                  <a
                    onClick={() => this.sortTop(sortTable, record, index)}
                    style={{}}
                  >
                    <StepBackwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                  </a>
                  <Divider type="vertical" />
                  <a
                    onClick={() => this.sortUp(sortTable, record, index)}
                    style={{}}
                  >
                    <CaretUpOutlined style={{ fontSize: "18px" }} />
                  </a>
                  <Divider type="vertical" />
                  <a
                    onClick={() => this.sortDown(sortTable, record, index)}
                    style={{}}
                  >
                    <CaretDownOutlined style={{ fontSize: "18px" }} />
                  </a>
                  <Divider type="vertical" />
                  <a
                    onClick={() => this.sortBottom(sortTable, record, index)}
                    style={{}}
                  >
                    <StepForwardOutlined rotate={90} style={{ fontSize: "18px" }} />
                  </a>
                </span>
              ) : editIndex === index ? (
                <span>
                  <a onClick={() => this.save()}>保存</a>
                  <Divider type="vertical" />
                  <a onClick={() => this.cancel(index)}>閉じる</a>
                </span>
              ) : (
                <span>
                  <a onClick={() => this.select(record)}>選択</a>
                  <Divider type="vertical" />
                  <a onClick={() => this.edit(record, index)}>修正</a>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="削除してもいいですか？"
                    onConfirm={() => this.delete(record, index)}
                  >
                    <a>削除</a>
                  </Popconfirm>
                </span>
              )}
            </div>
          );
        },
      },
    ];

    // convert Array(String) to Array(Object)
    let dataTable1 = convertArrStrToArrObj(dataSelect); //dataSelect
    const content = (
      <div>
        {sort == false ? (
          <div>
            <Table
              bordered
              columns={columns}
              dataSource={dataTable1}
              pagination={{ pageSize: 50 }} //50
              scroll={{ y: 240 }}
              showHeader={false}
              size={"small"}
              rowKey={(record, index) => index}
            />
            <Button
              type="primary"
              icon={<LegacyIcon type={Expansion ? "minus" : "plus"} />}
              // className="modal-expansion"
              style={{ float: "left", marginTop: -40 }}
              onClick={this.handleExpandTab}
            >
              {Expansion ? "格納" : "展開"}
            </Button>
          </div>
        ) : null}

        {sort == true ? (
          <Table
            bordered
            columns={columns}
            dataSource={convertArrStrToArrObj(sortTable)}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 240 }}
            showHeader={false}
            size={"small"}
            rowKey={(record, index) => index}
          />
        ) : null}
      </div>
    );

    const title = (
      <div>
        {sort == false ? (
          <span>
            <TextInput
              onChange={this.handleOnchangeNew}
              value={newText}
              style={{ width: "250px", marginRight: "12px" }}
            />
            <a onClick={this.add}>追加</a>
            <a
              onClick={this.onChangePopover.bind(this, false)}
              style={{ float: "right", paddingTop: "7px", color: "black" }}
            >
              閉じる
            </a>
            <a
              key="sort"
              // type={"primary"}
              onClick={this.sortOn}
              style={{
                marginRight: 8,
                marginTop: 10,
                marginLeft: 15,
                color: "black",
              }}
            >
              並び替え
            </a>
          </span>
        ) : null}

        {sort == true ? (
          <span>
            <Button
              type={"primary"}
              onClick={this.sortSave}
              style={{ marginRight: 8, marginTop: 5, marginLeft: 15 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={this.sortOff}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
            <a
              onClick={this.onChangePopover.bind(this, false)}
              style={{ float: "right", paddingTop: "7px", color: "black" }}
            >
              閉じる
            </a>
          </span>
        ) : null}
      </div>
    );

    return (
      <div className="text-area-select-ri-page">
        <TextInputArea
          rows={rowSet ? rowSet : 1}
          className="text-area-select-text"
          value={value}
          onChange={this.handleSelectChange}
        />
        <span className="text-area-select-btn">
          <span>
            <Popover
              overlayStyle={{ width: "480px" }}
              visible={yougoVisible}
              placement={"bottomRight"}
              title={title}
              content={content}
              trigger="click"
              onVisibleChange={this.onChangePopover}
            >
              <Button type="primary">選択</Button>
            </Popover>
          </span>
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    byoumeiList: state.allMaster.byomeiMaster,
    drugList: state.allMaster.drugMaster,
    machineList: state.allMaster.machineMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextAreaSelectYougo);
