import { Button, Modal, Progress } from "antd";
import React from "react";

/**
 *
 * @param {*} props
 *
 * INPUT:
 * visibleMulState: true / false
 * toall row - that has been selected
 * count the row - that is being handle
 * function closePopup to close the popup
 *
 */
const IkkatsuModal = (props) => {
  return (
    (<Modal open={props.visibleMulState} closable={false} footer={null}>
      <div style={{ display: "flex", marginTop: 24 }}>
        {/* <Progress type="circle" percent={Math.floor(((state.rowsSelect - state.count) / state.rowsSelect) * 100)} format={percent => `${percent} の行`} /> */}
        <Progress
          percent={Math.floor(
            ((props.rowsSelect - props.count) / props.rowsSelect) * 100
          )}
          status="active"
        />
      </div>
      <div>
        {Math.floor(
          ((props.rowsSelect - props.count) / props.rowsSelect) * 100
        ) !== 100 ? (
          <div style={{ display: "block", textAlign: "center", marginTop: 16 }}>
            {props.rowsSelect - props.count}の行を変更中です...
          </div>
        ) : (
          <div style={{ display: "block", textAlign: "center", marginTop: 16 }}>
            <div>全ての行を変更しました</div>
            <Button
              key="submit"
              type="primary"
              onClick={props.closePopup}
              style={{ marginTop: 16 }}
            >
              閉じる
            </Button>
          </div>
        )}
      </div>
    </Modal>)
  );
};

export default IkkatsuModal;
