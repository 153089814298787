import { TantoMasterActionTypes, ErrorActionTypes } from "../action_types";

// constants
import { ApiPaths } from "../../constants";

// utils
import { RequestUtils, StorageUtils } from "../../utils";
import { ConfigName } from "../../../config-name";

function getBySid() {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const sid = Number(menu.si) || null;
      dispatch({ type: TantoMasterActionTypes.GET_TANTO_MASTER_REQUEST });
      const query = { sid };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.TantoMaster.GetTantoMaster,
        query,
      });
      dispatch({
        type: TantoMasterActionTypes.GET_TANTO_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: TantoMasterActionTypes.GET_TANTO_MASTER_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

export const TantoMasterActions = {
  getBySid,
};
