import { RiyouKaigohokenApi } from "../../api/riyouKaigohoken";
import {
  LOAD_RIYOU_KAIGOHOKEN_LIST_SUCCESS,
  GET_DATE_RIYOU_KAIGOHOKEN,
  CHECK_HOKEN_NO_SUCCESS,
  CHECK_HOKEN_NO_ERROR,
} from "../riyouKaigohoken/actionName";

export function loadRiyouKaigohokenList(riyousyaId) {
  return (dispatch) => {
    RiyouKaigohokenApi.loadRiyouKaigohoken(riyousyaId).then((data) =>
      dispatch({
        type: LOAD_RIYOU_KAIGOHOKEN_LIST_SUCCESS,
        data,
      })
    );
  };
}

export function getDateRiyouKaigohoken(startDate, endDate) {
  return {
    type: GET_DATE_RIYOU_KAIGOHOKEN,
    startDate,
    endDate,
  };
}

export function checkHokenNoCreator(hokenNo, hihokenNo, riyousyaId, callback) {
  return (dispatch) => {
    RiyouKaigohokenApi.checkHokenNo(hokenNo, hihokenNo, riyousyaId).then(
      (data) => {
        if (data && data?.riyousya) {
          dispatch({
            type: CHECK_HOKEN_NO_SUCCESS,
            data: data,
          });
          return callback(data);
        }
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CHECK_HOKEN_NO_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
