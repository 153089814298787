import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Table, notification, Menu, Dropdown, Modal } from "antd";

import SearchDialog from "./UI/SearchDialog";
import {
  setValueLocalstorage,
  getPath,
} from "../../common/function_common/functionCommon";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

class KaigoYougoMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      selectedRowKeys: [], // Check here to configure the default column
      displayList: [],
      visible: false,
      visibleDelConfirmPopup: false,
      currentPage: props.currentKYM,
      pageSize: props.pageSizeKYM, // @TODO setting in kaigoyougomaster,
      text1: "",
      text2: "",
      text3: "",
      path: getPath(),
    };

    this.reloadAll = this.reloadAll.bind(this);
  }

  componentDidMount() {
    // load data
    const { currentPage, pageSize } = this.state;

    // update from state
    const { kymDisplayList, kymSelectedRows, isKYMSelectedDisplay } =
      this.props;

    this.props.getSearchData("kaigoyougomaster");

    if (kymDisplayList && kymDisplayList.length > 0 && isKYMSelectedDisplay) {
      this.props.selectedKYMDisplay(kymDisplayList);
    } else {
      this.props.getKYMList(currentPage, pageSize);
    }

    if (kymSelectedRows && kymSelectedRows.length > 0) {
      this.setState({
        selectedRowKeys: kymSelectedRows,
      });
    }
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  handleChange = (pagination, filters, sorter) => {
    // check pagination have been changed

    if (pagination) {
      const { currentPage, pageSize } = this.state;

      if (currentPage !== pagination.current) {
        this.props.getKYMList(pagination.current, pageSize);
      }

      this.setState({
        currentPage: pagination.current,
      });
    }

    //
    // if (sorter && sorter.field) {
    //     const { currentPage, pageSize, text1, text2, text3 } = this.state;

    //     // console.log(sorter)
    //     if(sorter.order === "descend"){
    //         this.props.getKYMList(currentPage, pageSize, text1, text2, `${sorter.field} desc`);
    //     }else if(sorter.order === "ascend"){
    //         this.props.getKYMList(currentPage, pageSize, text1, text2, `${sorter.field} acs`);
    //     }

    // }

    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  /**
   * Reload all data from server
   *
   */
  async reloadAll() {
    //
    await this.props.clearSelectedRow("kym");

    //
    this.setState({
      selectedRowKeys: [],
    });

    this.props.getKYMList();
  }

  onSelectChange = (selectedRowKeys) => {
    // this this info have been save in global state
    this.setState({ selectedRowKeys });

    this.props.updateSelectedRows("kym", selectedRowKeys);
  };

  handleOkSearch = (text1, text2, text3) => {
    const { currentPage, pageSize } = this.state;
    this.props.postKYMList(currentPage, pageSize, text1, text2, text3);
    //
    this.setState({
      visible: false,
      text1: text1,
      text2: text2,
      text3: text3,
    });
  };

  handleCancelSearch = (e) => {
    //
    this.setState({
      visible: false,
    });
  };

  disSearchDialog = (e) => {
    //
    this.setState({
      visible: true,
    });
  };

  selectedDisplay = () => {
    const { selectedRowKeys } = this.state;

    // UPDATE ON LOCAL
    this.props.selectedKYMDisplay(selectedRowKeys);
  };

  setDisplayList = () => {
    const displayList = [];
    const list = this.props.kanriKYMList;
    for (let i in list) {
      displayList.push(list[i].id);
    }
    this.props.selectedKYMDisplay(displayList);
  };

  loginKanri = (record) => {
    //
    setValueLocalstorage("tid", record.tantoMaster ? record.tantoMaster.id : 0); // fix tid
    setValueLocalstorage(
      "mode",
      record.tantoMaster ? record.tantoMaster.name : "kanri"
    ); // kanri login mode

    this.props.history.push("/dengon");
  };

  handleDel = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.setState({
        visibleDelConfirmPopup: true,
      });
    }
  };

  handleOKDelConfim = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.props.deleteKYM(selectedRowKeys.toString());
    }

    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  handleCancelDelConfim = () => {
    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  render() {
    const {
      kanriKYMList,
      loading,
      searchData,
      currentKYM,
      pageSizeKYM,
      totalKYM,
    } = this.props;
    let { sortedInfo, filteredInfo, visible, selectedRowKeys, path } =
      this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    // prepare export data
    let dataExportCsv = [];
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      let tmpDataExportCsv = kanriKYMList.filter((item) =>
        selectedRowKeys.includes(item.id)
      );
      if (tmpDataExportCsv && tmpDataExportCsv.length > 0)
        for (var i = 0; i < tmpDataExportCsv.length; i++) {
          dataExportCsv.push({
            id: tmpDataExportCsv[i].id,
            属性: tmpDataExportCsv[i].attribute,
            分類1: tmpDataExportCsv[i].bunrui1,
            分類2: tmpDataExportCsv[i].bunrui2,
            キーワード: tmpDataExportCsv[i].keyword,
            内容: tmpDataExportCsv[i].naiyou,
            順番: tmpDataExportCsv[i].sortNum,
          });
        }
    }

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id - b.id,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        ellipsis: true,
        width: 50,
      },

      {
        title: "属性",
        dataIndex: "attribute",
        key: "attribute",
        sorter: (a, b) =>
          (a.attribute ? a.attribute : "") < (b.attribute ? b.attribute : "")
            ? -1
            : 1,
        sortOrder: sortedInfo.columnKey === "attribute" && sortedInfo.order,
        ellipsis: true,
        width: 150,
      },

      {
        title: "分類1",
        dataIndex: "bunrui1",
        key: "bunrui1",
        sorter: (a, b) =>
          (a.bunrui1 ? a.bunrui1 : "") < (b.bunrui1 ? b.bunrui1 : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "bunrui1" && sortedInfo.order,
        ellipsis: true,
        width: 150,
      },

      {
        title: "分類2",
        dataIndex: "bunrui2",
        key: "bunrui2",
        sorter: (a, b) =>
          (a.bunrui2 ? a.bunrui2 : "") < (b.bunrui2 ? b.bunrui2 : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "bunrui2" && sortedInfo.order,
        ellipsis: true,
        width: 150,
      },

      {
        title: "キーワード",
        dataIndex: "keyword",
        key: "keyword",
        sorter: (a, b) =>
          (a.keyword ? a.keyword : "") < (b.keyword ? b.keyword : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "keyword" && sortedInfo.order,
        ellipsis: true,
        width: 150,
      },

      {
        title: "内容",
        dataIndex: "naiyou",
        key: "naiyou",
        sorter: (a, b) =>
          (a.naiyou ? a.naiyou : "") < (b.naiyou ? b.naiyou : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "naiyou" && sortedInfo.order,
        ellipsis: true,
      },

      {
        title: "順番",
        dataIndex: "sortNum",
        key: "sortNum",
        sorter: (a, b) =>
          (a.sortNum ? a.sortNum : 0) - (b.sortNum ? b.sortNum : 0),
        sortOrder: sortedInfo.columnKey === "sortNum" && sortedInfo.order,
        ellipsis: true,
        width: 100,
      },

      {
        title: "アクション",
        key: "operation",
        // fixed: "right",
        render: (text, record, index) => (
          <div>
            <Button type="primary" onClick={this.setDisplayList}>
              <Link to={`${path}/kanri/kaigoyougomaster/${record.id}`}>
                編集
              </Link>
            </Button>
          </div>
        ),
        width: 120,
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const menu = (
      <Menu>
        <Menu.Item>
          <CSVLink
            filename={`介護用語マスタ${Date.now()}.csv`}
            data={dataExportCsv}
            className="btn btn-primary"
            disabled={dataExportCsv && dataExportCsv.length > 0 ? false : true}
          >
            ダウンロード
          </CSVLink>
        </Menu.Item>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" onClick={this.handleDel}>
            削除
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      (<KanriLayoutContainer router="kaigoyougomaster" breadcrumbLink={"/kanri/kaigoyougomaster"} breadcrumbText={"介護用語マスタ"}>
        <div className="kanri-file-page">
          <div className="table-operations">
            <Button type="primary" onClick={this.reloadAll}>
              全表示
            </Button>
            <Button type="primary" onClick={this.disSearchDialog}>
              検索
            </Button>
            <Button type="primary" onClick={this.selectedDisplay}>
              選択表示
            </Button>
            <Button type="primary">
              <Link to={`${path}/kanri/kaigoyougomaster/new`}>新規登録</Link>
            </Button>
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button type="primary">
                その他機能 <CaretDownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className="kanri-table">
            <Table
              columns={columns}
              dataSource={kanriKYMList}
              rowSelection={rowSelection}
              className="table"
              rowKey={"id"}
              pagination={{
                pageSize: pageSizeKYM,
                current: currentKYM,
                total: totalKYM,
              }}
              onChange={this.handleChange}
              size="small"
              bordered
              loading={loading}
              scroll={{ x: 1400 }}
            />
          </div>
        </div>
        <SearchDialog
          title={"検索画面"}
          subTitle1={"対象項目"}
          subTitle2={"検索する文字"}
          subTitle3={"検索方法"}
          visible={visible}
          handleOk={this.handleOkSearch}
          handleCancel={this.handleCancelSearch}
          searchData={searchData}
        />
        <Modal
          title="処理確認"
          open={this.state.visibleDelConfirmPopup}
          onOk={this.handleOKDelConfim}
          onCancel={this.handleCancelDelConfim}
          okText="OK"
          cancelText="キャンセル"
        >
          <p>削除してもよろしいでしょうか？</p>
        </Modal>
      </KanriLayoutContainer>)
    );
  }
}

export default KaigoYougoMaster;
