export const inquiryRequestActionTypes = {
  GET_YOUGO_MASTER: "GET_YOUGO_MASTER",
  GET_YOUGO_MASTER_SUCCESS: "GET_YOUGO_MASTER_SUCCESS",
  GET_YOUGO_MASTER_ERROR: "GET_YOUGO_MASTER_ERROR",

  SAVE_YOUGO_MASTER: "SAVE_YOUGO_MASTER",
  SAVE_YOUGO_MASTER_SUCCESS: "SAVE_YOUGO_MASTER_SUCCESS",
  SAVE_YOUGO_MASTER_ERROR: "SAVE_YOUGO_MASTER_ERROR",

  HOME_YOUGO_EDIT_STATE: "HOME_YOUGO_EDIT_STATE",
  HOME_YOUGO_CANCEL_STATE: "HOME_YOUGO_CANCEL_STATE",

  GET_TANTO_MASTER: "GET_TANTO_MASTER",
  GET_TANTO_MASTER_SUCCESS: "GET_TANTO_MASTER_SUCCESS",
  GET_TANTO_MASTER_ERROR: "GET_TANTO_MASTER_ERROR",

  GET_SERVICE_KIKAN: "GET_SERVICE_KIKAN",
  GET_SERVICE_KIKAN_SUCCESS: "GET_SERVICE_KIKAN_SUCCESS",
  GET_SERVICE_KIKAN_ERROR: "GET_SERVICE_KIKAN_ERROR",

  GET_SERVICE_SYURUI_MASTER: "GET_SERVICE_SYURUI_MASTER",
  GET_SERVICE_SYURUI_MASTER_SUCCESS: "GET_SERVICE_SYURUI_MASTER_SUCCESS",
  GET_SERVICE_SYURUI_MASTER_ERROR: "GET_SERVICE_SYURUI_MASTER_ERROR",

  GET_DATA_INQUIRY_REQUEST: "GET_DATA_INQUIRY_REQUEST",
  GET_DATA_INQUIRY_SUCCESS: "GET_DATA_INQUIRY_SUCCESS",
  GET_DATA_INQUIRY_ERROR: "GET_DATA_INQUIRY_ERROR",

  ADD_RECORD: "ADD_RECORD",
  ADD_RECORD_ERROR: "ADD_RECORD_ERROR",

  DELETE_RECORD: "DELETE_RECORD",
  DELETE_RECORD_ERROR: "DELETE_RECORD_ERROR",

  GET_SYOUKAI_DATE: "GET_SYOUKAI_DATE",
  GET_SYOUKAI_DATE_SUCCESS: "GET_SYOUKAI_DATE_SUCCESS",
  GET_SYOUKAI_DATE_ERROR: "GET_SYOUKAI_DATE_ERROR",

  UPDATE_DATA: "UPDATE_DATA",

  SAVE_UPDATE_DATA_INQUIRY_REQUEST: "SAVE_UPDATE_DATA_INQUIRY_REQUEST",
  SAVE_UPDATE_DATA_INQUIRY_SUCCESS: "SAVE_UPDATE_DATA_INQUIRY_SUCCESS",
  SAVE_UPDATE_DATA_INQUIRY_ERROR: "SAVE_UPDATE_DATA_INQUIRY_ERROR",

  SAVE_CREATE_DATA_INQUIRY_REQUEST: "SAVE_CREATE_DATA_INQUIRY_REQUEST",
  SAVE_CREATE_DATA_INQUIRY_SUCCESS: "SAVE_CREATE_DATA_INQUIRY_SUCCESS",
  SAVE_CREATE_DATA_INQUIRY_ERROR: "SAVE_CREATE_DATA_INQUIRY_ERROR",

  GET_DATA_VIEW_INQUIRY_REQUEST: "GET_DATA_VIEW_INQUIRY_REQUEST",

  GET_IDS_REQUEST: "GET_IDS_REQUEST",
  GET_IDS_SUCCESS: "GET_IDS_SUCCESS",
  GET_IDS_ERROR: "GET_IDS_ERROR",

  DELETE_INQUIRY_REQUEST: "DELETE_INQUIRY_REQUEST",
  DELETE_INQUIRY_SUCCESS: "DELETE_INQUIRY_SUCCESS",
  DELETE_INQUIRY_FAILURE: "DELETE_INQUIRY_FAILURE",

  GET_LIST_INQUIRY_REQUEST: "GET_LIST_INQUIRY_REQUEST",
  GET_LIST_INQUIRY_SUCCESS: "GET_LIST_INQUIRY_SUCCESS",
  GET_LIST_INQUIRY_FAILURE: "GET_LIST_INQUIRY_FAILURE",

  CLONE_DATA: "CLONE_DATA",

  SET_NEW_DATA: "SET_NEW_DATA",

  CLONE_DATA_INQUIRY_REQUEST: "CLONE_DATA_INQUIRY_REQUEST",
  CLONE_DATA_INQUIRY_SUCCESS: "CLONE_DATA_INQUIRY_SUCCESS",
  CLONE_DATA_INQUIRY_ERROR: "CLONE_DATA_INQUIRY_ERROR",
};
