/* eslint-disable brace-style */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from "react";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { Input, Button, Table, Modal, Row, Col, Divider } from "antd";
import { connect } from "react-redux";

//action
import { ServiceKikanMasterActions } from "../../redux/actions/index";
import ServiceKikanMasterModal from "../Common/ServiceKikanMasterModal";
import * as addServicesContants from "../../constants/AddServicesModal/addServicesModalWithConstant";

function ServiceKikanWeekly(props) {
  const { changeStartTime, changeEndTime } = props;
  const { setStartTime, setEndTime } = props;
  const ADD = 1;
  const EDIT = 2;
  const DUPLICATE = 3;
  const [name, setName] = useState("");
  const [record, setRecord] = useState(null);
  const [rowActive, setRowActive] = useState();
  const [sid, setSid] = useState(null);
  const [serviceKikanMasterVisible, setServiceKikanMasterVisible] = useState(false);
  const [modeModify, setModeModify] = useState();
  const [pagination, setPagination] = useState();
  const [expansion, setExpansion] = useState(false);
  const [sort, setSort] = useState(false);
  const [sortTable, setSortTable] = useState([]);
  const [sortTableCopy, setSortTableCopy] = useState([]);
  const addBtnRef = useRef();

  useEffect(() => {
    if (props.serviceKikan.length > 0) {
      props.serviceKikan.find((e) => e.jigyousyoNo === props.jigyousyoNoServiceKikan) &&
        setRowActive(props.serviceKikan.find((e) => e.jigyousyoNo === props.jigyousyoNoServiceKikan).id);
    }
  }, [props.serviceKikan]);

  useEffect(() => {
    if (props.visible) {
      props.getListByServiceType(checkMenu(props.serviceType), null, props.kaigoCode);
      setPagination({ pageSize: 10, current: 1 });
    } else {
      setExpansion(false);
      sortNot();
    }
  }, [props.visible]);

  // Get time from id of api service-kikan-master
  // useEffect(() => {
  //   if (
  //     props.serviceMaster &&
  //     props.serviceMaster?.kasanDefault &&
  //     record?.id &&
  //     props.startTime === addServicesContants.TIME_DEFAULT &&
  //     props.endTime === addServicesContants.TIME_DEFAULT
  //   ) {
  //     const { startTime, endTime } = extractServicesKikanDefault(props.serviceMaster.kasanDefault);
  //     setStartTime(startTime);
  //     setEndTime(endTime);
  //   }
  // }, [props.serviceMaster]);

  const extractServicesKikanDefault = (kasanDefault) => {
    if (kasanDefault) {
      const startTimeArray = kasanDefault.match("<時間帯開始>(.*)</時間帯開始>");
      const endTimeArray = kasanDefault.match("<時間帯終了>(.*)</時間帯終了>");
      const startTimeValue = startTimeArray && startTimeArray[1] ? startTimeArray[1].substring(0, 2) + ":" + startTimeArray[1].substring(2) : "";
      const endTimeValue = endTimeArray && endTimeArray[1] ? endTimeArray[1].substring(0, 2) + ":" + endTimeArray[1].substring(2) : "";

      return {
        startTime: !startTimeValue ? addServicesContants.TIME_DEFAULT : startTimeValue,
        endTime: !endTimeValue ? addServicesContants.TIME_DEFAULT : endTimeValue,
      };
    }
    return {};
  };

  useEffect(() => {
    if (props.isReload && props.visible) {
      props.getListByServiceType(checkMenu(props.serviceType), name, props.kaigoCode);
      props.turnOffReload();
    }
  }, [props.isReload]);

  useEffect(() => {
    if (sort) {
      let tmp = JSON.parse(JSON.stringify(props.serviceKikan));
      let tmp2 = JSON.parse(JSON.stringify(props.serviceKikan));
      setSortTable(tmp);
      setSortTableCopy(tmp2);
    }
  }, [props.serviceKikan]);

  const handleCancel = () => {
    // setRecord(null);
    // setRowActive(null);
    setName("");
    setExpansion(false);
    sortNot();
    props.hideModal();
  };

  const checkMenu = (value) => {
    if (props.menu === "syoukibo" && (value === "通い" || value === "泊まり" || value === "訪問")) {
      return "小規模"
    } else if (props.menu === "fukugo" && (value === "通い" || value === "泊まり" || value === "訪問" || value === "看護")) {
      return "複合型"
    }
    return value;
  }

  const search = () => {
    props.getListByServiceType(checkMenu(props.serviceType), name, props.kaigoCode);
  };

  const onClickCellEvent = (record) => {
    // setRecord(record);
    setRowActive(record.id);
  };

  const onOk = () => {
    if (addBtnRef.current.disabled) {
      return;
    } else {
      addBtnRef.current.disabled = true;
    }

    record && props.onSaveServiceKikan(record);
    setName("");
    setExpansion(false);
    sortNot();
    props.getServiceKikanById(record.id);
    setTimeout(() => {
      if (addBtnRef.current && addBtnRef.current.disabled) addBtnRef.current.disabled = false;
    }, 1000);
    // setRecord(null);
    // setRowActive(null);
  };

  const handleExpandTab = () => {
    if (expansion) {
      setExpansion(false);
    } else {
      setExpansion(true);
    }
  };

  function handleOkService() {
    // search();
    setServiceKikanMasterVisible(false);
  }

  function handleCancelService() {
    setServiceKikanMasterVisible(false);
    props.turnOffReload();
  }

  function handleAddService() {
    setModeModify(ADD);
    setServiceKikanMasterVisible(true);
    setSid(null);
  }

  function handleEditService(sid) {
    setModeModify(EDIT);
    setServiceKikanMasterVisible(true);
    setSid(sid);
  }

  async function handleDuplicateService(sid) {
    setSid(sid);
    setModeModify(DUPLICATE);
    setServiceKikanMasterVisible(true);
  }

  // function handleOkDuplicateConfirm() {
  //   setConfirmDuplicateServiceVisible(false);
  //   setServiceSyuruiListVisible(true);
  // }

  // function handleCancelDuplicateConfirm() {
  //   setConfirmDuplicateServiceVisible(false);
  // }

  // async function handleOkSyuruiList(syurui) {
  //   const result = await props.duplicateServiceKikanMaster(sid, syurui.id);
  //   setSid(result.id);
  //   setServiceSyuruiListVisible(false);
  //   setServiceKikanMasterVisible(true);
  // }

  // function handleCancelSyuruiList() {
  //   setServiceSyuruiListVisible(false);
  // }

  // useEffect(() => {
  //   console.log("props.idSelected : ", props.idSelected);
  //   if (props.idSelected) props.getServiceKikanById(props.idSelected);
  // }, [props.idSelected]);

  async function sortOn() {
    setSort(true);
    setRowActive(null);
    props.getListByServiceType(checkMenu(props.serviceType), name, props.kaigoCode);
  }
  const sortNot = () => {
    setSort(false);
    setSortTable([]);
    setSortTableCopy([]);
  };
  const sortOff = () => {
    props.getListByServiceType(checkMenu(props.serviceType), name, props.kaigoCode);

    setTimeout(() => {
      sortNot();
    }, 500);
  };
  async function sortSave() {
    let tmp = [];
    for (let record of sortTable) {
      let defTable = sortTableCopy.find((t) => t.id == record.id);
      if (record.sort_num != defTable.sort_num) {
        record.sortNum = record.sort_num;
        delete record.sort_num;
        tmp.push(record);
      }
    }

    await props.updateServiceKikanList(tmp, (data) => {
      search();
    });

    setTimeout(() => {
      sortNot();
    }, 500);
  }

  const sortTop = (record) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const minNum = sortTableTmp[0].sort_num;

    if (elementIndex != 0) {
      sortTableTmp.splice(elementIndex, 1);
      element.sort_num = minNum - 1;
      sortTableTmp.splice(0, 0, element);

      setSortTable(sortTableTmp);
    }
  };
  const sortUp = (record) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTableTmp.findIndex((v) => v.id === element.id);

    if (elementIndex != 0) {
      const changeElement = sortTableTmp[elementIndex - 1];
      const changeElementSortNum = changeElement.sort_num;
      const changeElementIndex = elementIndex - 1;

      sortTableTmp.splice(elementIndex, 1);
      if (element.sort_num == changeElementSortNum) {
        element.sort_num--;
        sortTableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sort_num = changeElementSortNum;
        sortTableTmp.splice(changeElementIndex, 0, element);
        sortTableTmp[elementIndex].sort_num++;
      }

      setSortTable(sortTableTmp);
    }
  };
  const sortDown = (record) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTableTmp.findIndex((v) => v.id === element.id);

    if (elementIndex != sortTable.length - 1) {
      const changeElement = sortTable[elementIndex + 1];
      const changeElementSortNum = changeElement.sort_num;
      const changeElementIndex = elementIndex + 1;

      sortTableTmp.splice(elementIndex, 1);
      if (element.sort_num == changeElementSortNum) {
        element.sort_num++;
        sortTableTmp.splice(changeElementIndex, 0, element);
      } else {
        element.sort_num = changeElementSortNum;
        sortTableTmp.splice(changeElementIndex, 0, element);
        sortTableTmp[elementIndex].sort_num--;
      }

      setSortTable(sortTableTmp);
    }
  };
  const sortBottom = (record) => {
    let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    let maxNum = 0;
    for (let i of sortTableTmp) {
      maxNum < i.sort_num ? (maxNum = i.sort_num) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      sortTableTmp.splice(elementIndex, 1);
      element.sort_num = maxNum + 1;
      sortTableTmp.splice(sortTable.length, 0, element);

      setSortTable(sortTableTmp);
    }
  };

  const titleService = (
    <div>
      <Row style={{ marginBottom: "4px" }}>
        <Col span={6}>サービス種類</Col>
        <Col span={18}>
          <Input
            value={checkMenu(props.serviceType)}
            readOnly={true}
            disabled={sort == true ? true : false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>検索</Col>
        <Col span={18} style={{ display: "flex" }}>
          <Input
            onChange={(e) => setName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.getListByServiceType(
                  checkMenu(props.serviceType),
                  name,
                  props.kaigoCode
                );
              }
            }}
            value={name}
            disabled={sort == true ? true : false}
          />
          <Button
            value={name}
            onClick={() => search()}
            className={
              sort == false
                ? "search-service-kikan"
                : "search-service-kikan-none"
            }
            disabled={sort == true ? true : false}
            icon={<SearchOutlined />}
          >
            検索
          </Button>
        </Col>
      </Row>
    </div>
  );

  const columnsService = expansion
    ? [
        {
          title: "jigyousyoNo",
          dataIndex: "jigyousyoNo",
          width: 100,
          key: "jigyousyoNo",
          render: (textEx, record, index) => <div>{textEx}</div>,
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
        {
          title: "ryaku",
          dataIndex: "ryaku",
          width: 220,
          key: "ryaku",
          render: (textEx, record, index) => (
            <div
              style={{
                maxWidth: "210px",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {textEx}
            </div>
          ),
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
        {
          title: "information",
          dataIndex: "info",
          width: 130,
          key: "info",
          render: (textEx, record, index) => (
            <div
              style={{
                maxWidth: "130px",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {textEx}
            </div>
          ),
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
      ]
    : [
        {
          title: "jigyousyoNo",
          dataIndex: "jigyousyoNo",
          width: 100,
          key: "jigyousyoNo",
          render: (textEx, record, index) => <div>{textEx}</div>,
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
        {
          title: "ryaku",
          dataIndex: "ryaku",
          width: 220,
          key: "ryaku",
          render: (textEx, record, index) => (
            <div
              style={{
                maxWidth: "210px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {textEx}
            </div>
          ),
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
        {
          title: "information",
          dataIndex: "info",
          width: 130,
          key: "info",
          render: (textEx, record, index) => (
            <div
              style={{
                maxWidth: "130px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {record.info}
            </div>
          ),
          onCell: (record) => {
            return {
              onClick: (event) => onClickCellEvent(record), // click row
            };
          },
        },
      ];

  const columnsService2 = [
    {
      title: "jigyousyoNo",
      dataIndex: "jigyousyoNo",
      width: 100,
      key: "jigyousyoNo",
      render: (textEx, record, index) => <div>{textEx}</div>,
      onCell: (record) => {
        return {
          onClick: (event) => onClickCellEvent(record), // click row
        };
      },
    },
    {
      title: "ryaku",
      dataIndex: "ryaku",
      width: 180,
      key: "ryaku",
      render: (textEx, record, index) => (
        <div
          style={{
            maxWidth: "220px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {textEx}
        </div>
      ),
    },
    // { //テスト
    //     title: 'num',
    //     dataIndex: 'sort_num',
    //     width: "10%",
    //     key: "sort_num",
    // },
    {
      title: "行動",
      dataIndex: "operation",
      width: "30%",
      key: "operation",
      render: (record, index) => {
        return (
          <div>
            <span>
              <a onClick={() => sortTop(index)} style={{}}>
                <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortUp(index)} style={{}}>
                <CaretUpOutlined style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortDown(index)} style={{}}>
                <CaretDownOutlined style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortBottom(index)} style={{}}>
                <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
              </a>
            </span>
          </div>
        );
      },
    },
  ];

  function isDisableButton(rowActive) {
    return props.serviceKikan
      ? !props.serviceKikan.some((item) => item.id == rowActive)
      : true;
  }

  function handleChangePagination(pagination) {
    setPagination(pagination);
  }

  return (
    (<div className="text-area-select-ri-page">
      <Modal
        open={props.visible}
        title={titleService}
        className="service-kikan-list"
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            キャンセル
          </Button>,
          <Button key="submit" type="primary" onClick={() => onOk()} ref={addBtnRef} disabled={isDisableButton(rowActive)}>
            OK
          </Button>,
        ]}
        closable={false}
      >
        <Button key="add" type={"primary"} onClick={handleAddService} style={{ marginRight: 8 }} disabled={sort == true ? true : false}>
          追加
        </Button>
        <Button
          key="edit"
          type={"primary"}
          onClick={() => handleEditService(rowActive)}
          disabled={sort == true ? true : isDisableButton(rowActive)}
          style={{ marginRight: 8 }}
        >
          修正
        </Button>
        <Button
          key="duplicate"
          type={"primary"}
          onClick={() => handleDuplicateService(rowActive)}
          disabled={sort == true ? true : isDisableButton(rowActive)}
        >
          複製
        </Button>
        {sort == false ? (
          <a
            key="sort"
            // type={"primary"}
            onClick={() => sortOn()}
            style={{
              marginRight: 8,
              marginTop: 7,
              marginLeft: 10,
              color: "black",
            }}
          >
            並び替え
          </a>
        ) : (
          <span>
            <Button
              key="sortSave"
              type={"primary"}
              onClick={() => sortSave()}
              style={{ marginRight: 8, marginLeft: 8 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={() => sortOff()}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
          </span>
        )}
        {sort == false ? (
          <Table
            dataSource={props.serviceKikan}
            columns={columnsService}
            showHeader={false}
            className={
              props.className
                ? props.className + " riyou-meal-page service-kikan-table"
                : "riyou-meal-page service-kikan-table"
            }
            // onChange={handleChangePagination}
            pagination={{ pageSize: 20 }}
            scroll={{ y: 300 }}
            size="small"
            rowClassName={(record) => {
              if (rowActive != null && record.id === rowActive) {
                setRecord(record);
                return "pljp-table-row-hover";
              }
              return "";
            }}
            rowKey={(record, index) => index}
            style={{ marginTop: 20 }}
          />
        ) : (
          <Table
            dataSource={sortTable}
            columns={columnsService2}
            showHeader={false}
            className={
              props.className
                ? props.className + " riyou-meal-page service-kikan-table"
                : "riyou-meal-page service-kikan-table"
            }
            pagination={{ pageSize: 20 }}
            scroll={{ y: 300 }}
            size="small"
            rowKey={(record, index) => index}
            style={{ marginTop: 20 }}
          />
        )}

        <Button
          type="primary"
          icon={<LegacyIcon type={expansion ? "minus" : "plus"} />}
          className="modal-expansion"
          onClick={handleExpandTab}
          disabled={sort == true ? true : false}
        >
          {expansion ? "格納" : "展開"}
        </Button>
      </Modal>
      <ServiceKikanMasterModal
        startTime={props.startTime}
        endTime={props.endTime}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        changeStartTime={changeStartTime}
        changeEndTime={changeEndTime}
        visible={serviceKikanMasterVisible}
        onOk={handleOkService}
        onCancel={handleCancelService}
        id={sid}
        syuruiCd={props.syuruiCd}
        modeModify={modeModify}
      />
      {/* <ServiceSyuruiListModal
        visible={serviceSyuruiListVisible}
        onOk={handleOkSyuruiList}
        onCancel={handleCancelSyuruiList}
      /> */}
      {/* <Modal
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        open={confirmDuplicateServiceVisible}
        footer={[
          <Button key="back" onClick={handleCancelDuplicateConfirm}>
            キャンセル
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOkDuplicateConfirm}
          >
            OK
          </Button>,
        ]}
      >
        <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}>
          <Col>
            <Icon
              type="question-circle"
              style={{ fontSize: "2rem", color: "orange" }}
            />
          </Col>
          <Col>サービス種類を選択してください</Col>
        </Row>
      </Modal> */}
    </div>)
  );
}

const mapStateToProps = (state) => {
  return {
    serviceKikan: state.serviceKikanMaster.serviceKikanList,
    isReload: state.serviceKikanMaster.isReload,
    serviceMaster: state.serviceKikanMaster.serviceKikanMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListByServiceType: (serviceType, name, kaigoCode) =>
      dispatch(
        ServiceKikanMasterActions.getListByServiceType(
          serviceType,
          name,
          kaigoCode
        )
      ),
    duplicateServiceKikanMaster: (sid, syuruiId) =>
      dispatch(
        ServiceKikanMasterActions.duplicateServiceKikanMaster(sid, syuruiId)
      ),
    turnOffReload: () => dispatch(ServiceKikanMasterActions.turnOffReload()),
    updateServiceKikanList: (body, callback) =>
      dispatch(
        ServiceKikanMasterActions.updateServiceKikanList(body, callback)
      ),
    getServiceKikanById: (id) => dispatch(ServiceKikanMasterActions.getById(id)),
  };
};

const _ServiceKikan = connect(mapStateToProps, mapDispatchToProps)(ServiceKikanWeekly);

export { _ServiceKikan as ServiceKikanWeekly };
