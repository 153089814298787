import { Form } from '@ant-design/compatible';
import React from "react";

// import '@ant-design/compatible/assets/index.css';


import { Button, Checkbox, Input, Modal } from "antd";
import { validateNumberAndLetter } from "../../../common/function_common/functionCommon";

class UserForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    visibleConfirm: false,
    isDelConfirm: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let data = {
          id: values.id ? values.id : null,
          uid: values.uid ? values.uid : null,
          name: values.name ? values.name : null,
          tid: values.tid ? values.tid : null,
          password: values.password ? values.password : null,
          usageAuth: values.usageAuth ? values.usageAuth : null,
          riyousyaAccessCtrl:
            values.riyousyaAccessCtrl && values.riyousyaAccessCtrl.length > 0
              ? 1
              : 0,
          officeMnAuth:
            values.officeMnAuth && values.officeMnAuth.length > 0 ? 1 : 0,
          sysMnAuth: values.sysMnAuth && values.sysMnAuth.length > 0 ? 1 : 0,
          addAuth: values.addAuth && values.addAuth.length > 0 ? 1 : 0,
        };

        this.props.regData(data);
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  validateUID = (rule, value, callback) => {
    if (value && value.length < 6) {
      callback("※半角英数字で6文字以上入力して下さい。");
    } else if (!validateNumberAndLetter(value)) {
      callback("※数字と文字のみを入力してください。");
    } else {
      callback();
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("「パスワード」と「パスワード(確認)」の値が一致しません。");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;

    if (value && value.length < 8) {
      callback("※半角英数字で8文字以上入力して下さい。");
    } else if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.props.form.isFieldsTouched()) this.showGoBackConfirm();
    else this.props.history.goBack();
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.goBack();
  }

  delete = () => {
    this.setState({
      isDelConfirm: true,
    });
  };

  closeDelConfirm = () => {
    this.setState({
      isDelConfirm: false,
    });
  };

  okDelConfirm = () => {
    const { data } = this.props;
    this.setState({
      isDelConfirm: false,
    });

    this.props.delete(data.id);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      (<div>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          labelAlign="left"
          style={{ maxWidth: 680, margin: "0 auto" }}
        >
          <Form.Item label="ID（自動生成）">
            {getFieldDecorator("id", {
              rules: [{ required: false }],
            })(<Input disabled />)}
          </Form.Item>

          <Form.Item
            label="ユーザID"
            extra={
              <span>
                <span style={{ color: "red" }}>※</span>
                半角英数字で6文字以上入力して下さい
              </span>
            }
          >
            {getFieldDecorator("uid", {
              validateTrigger: "onBlur",
              rules: [
                {
                  required: true,
                  message: "ユーザIDは必須です。",
                  whitespace: true,
                },
                {
                  validator: this.validateUID,
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="名前">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "名前は必須です。",
                  whitespace: true,
                },
              ],
            })(<Input autoComplete="new-name" />)}
          </Form.Item>

          <Form.Item label="担当者ID">
            {getFieldDecorator("tid", {
              rules: [{ required: false }],
            })(<Input type="number" />)}
          </Form.Item>

          <Form.Item
            label="パスワード"
            hasFeedback
            extra={
              <span>
                <span style={{ color: "red" }}>※</span>
                半角英数字で8文字以上入力して下さい
              </span>
            }
          >
            {getFieldDecorator("password", {
              validateTrigger: "onBlur",
              rules: [
                {
                  required: false,
                  message: "Please input your password!",
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input type="password" autoComplete="new-password" />)}
          </Form.Item>
          <Form.Item
            label="パスワード(確認)"
            hasFeedback
            extra={
              <span>
                <span style={{ color: "red" }}>※</span>
                半角英数字で8文字以上入力して下さい
              </span>
            }
          >
            {getFieldDecorator("confirm", {
              validateTrigger: "onBlur",
              rules: [
                {
                  required: false,
                  message: "Please confirm your password!",
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(
              <Input
                type="password"
                onBlur={this.handleConfirmBlur}
                autoComplete="new-password"
              />
            )}
          </Form.Item>

          <Form.Item label="メニュー権限">
            {getFieldDecorator("usageAuth", {
              rules: [{ required: false }],
            })(<Input />)}
          </Form.Item>

          {/* <Form.Item label="用者アクセス制限">
                    {getFieldDecorator('riyousyaAccessCtrl', {
                        rules: [{ required: false }],
                    })(<Checkbox />)}
                </Form.Item> */}

          <Form.Item label="利用者アクセス制限">
            {getFieldDecorator("riyousyaAccessCtrl", {
              rules: [{ required: false }],
            })(<Checkbox.Group options={[{ value: "riyousyaAccessCtrl" }]} />)}
          </Form.Item>

          <Form.Item label="業務アクセス権限">
            {getFieldDecorator("officeMnAuth", {
              rules: [{ required: false }],
            })(<Checkbox.Group options={[{ value: "officeMnAuth" }]} />)}
          </Form.Item>

          <Form.Item label="システム管理者権限">
            {getFieldDecorator("sysMnAuth", {
              rules: [{ required: false }],
            })(<Checkbox.Group options={[{ value: "sysMnAuth" }]} />)}
          </Form.Item>

          <Form.Item label="追加権限">
            {getFieldDecorator("addAuth", {
              rules: [{ required: false }],
            })(<Checkbox.Group options={[{ value: "addAuth" }]} />)}
          </Form.Item>

          {data && data.id ? (
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" danger onClick={this.delete}>
                削除
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                更新
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          ) : (
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                新規登録
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          )}
        </Form>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
        <Modal
          open={this.state.isDelConfirm}
          title="削除確認"
          onCancel={() => this.closeDelConfirm()}
          onOk={() => this.okDelConfirm()}
        >
          <span style={{ display: "block" }}>
            {"削除してもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }
}

const WrappedUserForm = Form.create({
  name: "kanri-user-form",
  onValuesChange(props, changedValues, allValues) { },
  mapPropsToFields(props) {
    return {
      id: Form.createFormField({
        value: props.data ? props.data.id : null,
      }),
      uid: Form.createFormField({
        value: props.data ? props.data.uid : null,
      }),
      name: Form.createFormField({
        value: props.data ? props.data.name : null,
      }),
      tid: Form.createFormField({
        value: props.data ? props.data.tid : null,
      }),
      usageAuth: Form.createFormField({
        value: props.data ? props.data.usageAuth : null,
      }),
      riyousyaAccessCtrl: Form.createFormField({
        value:
          props.data && props.data.riyousyaAccessCtrl === 1
            ? ["riyousyaAccessCtrl"]
            : [],
      }),
      officeMnAuth: Form.createFormField({
        value:
          props.data && props.data.officeMnAuth === 1 ? ["officeMnAuth"] : [],
      }),
      sysMnAuth: Form.createFormField({
        value: props.data && props.data.sysMnAuth === 1 ? ["sysMnAuth"] : [],
      }),
      addAuth: Form.createFormField({
        value: props.data && props.data.addAuth === 1 ? ["addAuth"] : [],
      }),
    };
  },
})(UserForm);

export default WrappedUserForm;
