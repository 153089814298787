import React, { useState, useEffect } from "react";
import { Modal, Button, Input } from "antd";
function CharacterInputModal(props) {
  const [ryaku, setRyaku] = useState();

  useEffect(() => {
    if (props.recordSelected) {
      setRyaku(props.recordSelected.ryakuDetail);
    }
  }, [props.recordSelected]);

  function handleCancel() {
    props.onCancel();
  }

  function handleOk() {
    props.onOk(props.recordSelected.key, ryaku);
  }
  function handleChangeRyaku(e) {
    setRyaku(e.target.value);
  }
  return (
    (<Modal
      open={props.visible}
      title={props.title}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={handleCancel}
        >
          キャンセル
        </Button>,
        <Button key="back" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
      width={350}
    >
      <div>
        文字入力
        <Input
          style={{ width: 200, marginLeft: 10 }}
          value={ryaku || ""}
          onChange={handleChangeRyaku}
        />
      </div>
    </Modal>)
  );
}

export { CharacterInputModal };
