export default {
  Circle: "circle",
  CircleFill: "circlefill",
  CircleLine: "circleline",
  Line: "line",
  TwoLine: "twoline",
  TwoLine2: "twoline2",
  ThreeLine: "threeline",
  Arrow: "arrow",
  Pencil: "pencil",
  Rectangle: "rectangle",
  RectangleLine: "rectangleline",
  RectangleFill: "rectanglefill",
  Select: "select",
  Pan: "pan",
  TwoCircle: "twocircle",
  Triangle: "triangle",
  TwoRectangle: "tworectangle",
  Sin: "sin",
  ThreeLine2: "threeline2",
  TwoDiagonal: "twodiagonal",
  People1: "people1",
  PeopleHealth: "peoplehealth",
  People2: "people2",
  GuestRoom: "guestroom",
  LivingRoom: "livingroom",
  KitchenRoom: "kitchenroom",
  Canteen: "canteen",
  Toilet: "toilet",
  Entrance: "entrance",
  Space: "space",
  Kaidan: "kaidan",
  Living: "living",
  F1: "f1",
  F2: "f2",
  F3: "f3",
  F4: "f4",
  F5: "f5",
  Pencil2: "pencil2",
  XTriangle: "xtriangle",
};