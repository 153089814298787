import {
  LOAD_KYOTAKU_PLAN1_SUCCESS,
  LOAD_KYOTAKU_PLAN1_ERROR,
  LOAD_KYOTAKU_PLAN1_BY_ID_SUCCESS,
  LOAD_KYOTAKU_PLAN1_BY_ID_ERROR,
  LOAD_KYOTAKU_PLAN1_TO_QUOTE_SUCCESS,
  LOAD_KYOTAKU_PLAN1_TO_QUOTE_ERROR,
  CREATE_KYOTAKU_PLAN1_SUCCESS,
  CREATE_KYOTAKU_PLAN1_ERROR,
  UPDATE_KYOTAKU_PLAN1_SUCCESS,
  UPDATE_KYOTAKU_PLAN1_ERROR,
  DELETE_KYOTAKU_PLAN1_SUCCESS,
  DELETE_KYOTAKU_PLAN1_ERROR,
  LOAD_YOUGO_ATTRIBUTE_SUCCESS,
  LOAD_YOUGO_ATTRIBUTE_ERROR,
  LOAD_YOUGO_BUNRUI1_SUCCESS,
  LOAD_YOUGO_BUNRUI1_ERROR,
  LOAD_YOUGO_BUNRUI2_SUCCESS,
  LOAD_YOUGO_BUNRUI2_ERROR,
  LOAD_YOUGO_KEYWORD_SUCCESS,
  LOAD_YOUGO_KEYWORD_ERROR,
  LOAD_SPIN_REQUEST,
  LOAD_VIEW_PLANS_BY_RIYOUSYA_SUCCESS,
  LOAD_VIEW_PLANS_BY_RIYOUSYA_ERROR,
  CHANGE_VALUE_DETAIL1,
  RESET_STATUS,
  LOAD_YOUGO_FUSANKA_RIYU_SUCCESS,
  LOAD_YOUGO_FUSANKA_RIYU_ERROR,
  LOAD_SETTING_INPUT1_SUCCESS,
  LOAD_SETTING_INPUT1_ERROR,
} from "./actionName";

import { KyotakuPlan1Api, DocumentListApi } from "../../api/kyotakuPlan1";
import { SettingMasterAPI } from "../../api/settingMaster";
import { YougoMasterNaiyouApi } from "../../api/yougoMasterNaiyou";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
export function resetStatus() {
  return (dispatch) =>
    dispatch({
      type: RESET_STATUS,
    });
}
export function loadKyotakuPlan1(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KyotakuPlan1Api.loadKyotakuPlan1(data).then(
      (data) => {
        dispatch({
          type: LOAD_KYOTAKU_PLAN1_SUCCESS,
          kyotakuPlan1s: data.data,
          meta: data.meta,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KYOTAKU_PLAN1_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getKyotakuPlan1(id) {
  return (dispatch) => {
    if (id === "new" || id === 0) {
      return dispatch({
        type: LOAD_KYOTAKU_PLAN1_BY_ID_SUCCESS,
        kyotakuPlan1: null,
      });
    }
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KyotakuPlan1Api.getKyotakuPlan1ById(id).then(
      (data) =>
        dispatch({
          type: LOAD_KYOTAKU_PLAN1_BY_ID_SUCCESS,
          kyotakuPlan1: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KYOTAKU_PLAN1_BY_ID_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getKyotakuPlan1ToQuote(riyousyaId, kyotakuPlan1Id) {
  return (dispatch) => {
    KyotakuPlan1Api.getKyotakuPlan1ToQuote(riyousyaId, kyotakuPlan1Id).then(
      (data) =>
        dispatch({
          type: LOAD_KYOTAKU_PLAN1_TO_QUOTE_SUCCESS,
          kyotakuPlan1s: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KYOTAKU_PLAN1_TO_QUOTE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function createKyotakuPlan1(params, riyousyaId, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KyotakuPlan1Api.createKyotakuPlan1(params, riyousyaId).then(
      (data) =>
        dispatch({
          type: CREATE_KYOTAKU_PLAN1_SUCCESS,
          createdKyotakuPlan1: data,
          callback,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_KYOTAKU_PLAN1_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function updateKyotakuPlan1(params, id, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KyotakuPlan1Api.updateKyotakuPlan1(params, id).then(
      (data) =>
        dispatch({
          type: UPDATE_KYOTAKU_PLAN1_SUCCESS,
          updatedKyotakuPlan1: data,
          callback,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KYOTAKU_PLAN1_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function deleteKyotakuPlan1(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KyotakuPlan1Api.deleteKyotakuPlan1(id).then(
      (data) =>
        dispatch({
          type: DELETE_KYOTAKU_PLAN1_SUCCESS,
          deletedKyotakuPlan1: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: DELETE_KYOTAKU_PLAN1_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getYougoMaster() {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou("介護用語属性K").then(
      (data) =>
        dispatch({
          type: LOAD_YOUGO_ATTRIBUTE_SUCCESS,
          naiyou: data ? data.naiyou : null,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_YOUGO_ATTRIBUTE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
    YougoMasterNaiyouApi.yougoMasterNaiyou("介護用語分類1K").then(
      (data) =>
        dispatch({
          type: LOAD_YOUGO_BUNRUI1_SUCCESS,
          naiyou: data ? data.naiyou : null,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_YOUGO_BUNRUI1_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
    YougoMasterNaiyouApi.yougoMasterNaiyou("介護用語分類2K").then(
      (data) =>
        dispatch({
          type: LOAD_YOUGO_BUNRUI2_SUCCESS,
          naiyou: data ? data.naiyou : null,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_YOUGO_BUNRUI2_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
    YougoMasterNaiyouApi.yougoMasterNaiyou("介護用語キーワードK").then(
      (data) =>
        dispatch({
          type: LOAD_YOUGO_KEYWORD_SUCCESS,
          naiyou: data ? data.naiyou : null,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_YOUGO_KEYWORD_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getPlansToViewByRiyousya(riyousyaId) {
  return (dispatch) => {
    DocumentListApi.getPlansToViewByRiyousya(riyousyaId).then(
      (data) =>
        dispatch({
          type: LOAD_VIEW_PLANS_BY_RIYOUSYA_SUCCESS,
          dataView: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_VIEW_PLANS_BY_RIYOUSYA_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function changeValuePlan1(types) {
  return {
    type: CHANGE_VALUE_DETAIL1,
    types,
  };
}

export function getYougoMasterFusankaRiyu() {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou("不参加理由").then(
      (data) =>
        dispatch({
          type: LOAD_YOUGO_FUSANKA_RIYU_SUCCESS,
          naiyou: data ? data.naiyou : null,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_YOUGO_FUSANKA_RIYU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getSettingMozisu() {
  return (dispatch) => {
    SettingMasterAPI.getByName("SW計画書1文字数行数").then(
      (data) =>
        dispatch({
          type: LOAD_SETTING_INPUT1_SUCCESS,
          naiyou: data ? data.naiyou : null,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_SETTING_INPUT1_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
