import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import { notification } from "antd";

import WrappedLYMForm from "./UI/LYMForm";
import ActionsRow from "./UI/ActionsRow";
import { getPath } from "../../common/function_common/functionCommon";

class KanriLifeYougoMasterSave extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      lymId: parseInt(match.params.lymId),
      path: getPath(),
    };

    this.registerLYM = this.registerLYM.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    // load data
    this.props.getLYMList();
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  async registerLYM(values) {
    if (values.id) {
      await this.props.updateLYM(values);
    } else {
      await this.props.createLYM(values);
    }
    this.props.history.goBack();
  }

  async delete(id) {
    await this.props.deleteLYM(id.toString());

    this.props.history.goBack();
  }

  preToEnd = () => {
    const { kanriLYMList } = this.props;
    const { lymId, path } = this.state;

    if (kanriLYMList && kanriLYMList.length > 0) {
      let index = kanriLYMList.findIndex((item) => item.id === lymId);

      if (index >= 1) {
        this.setState({
          lymId: kanriLYMList[0].id,
        });

        this.props.history.replace(
          path + "/kanri/lifeyougomaster/" + kanriLYMList[0].id
        );
      }
    }
  };

  pre = () => {
    const { kanriLYMList } = this.props;
    const { lymId, path } = this.state;

    if (kanriLYMList && kanriLYMList.length > 0) {
      let index = kanriLYMList.findIndex((item) => item.id === lymId);

      if (index >= 1) {
        this.setState({
          lymId: kanriLYMList[index - 1].id,
        });

        this.props.history.replace(
          path + "/kanri/lifeyougomaster/" + kanriLYMList[index - 1].id
        );
      }
    }
  };

  next = () => {
    const { kanriLYMList } = this.props;
    const { lymId, path } = this.state;

    if (kanriLYMList && kanriLYMList.length > 0) {
      let index = kanriLYMList.findIndex((item) => item.id === lymId);

      if (index >= 0 && index < kanriLYMList.length - 1) {
        this.setState({
          lymId: kanriLYMList[index + 1].id,
        });

        this.props.history.replace(
          path + "/kanri/lifeyougomaster/" + kanriLYMList[index + 1].id
        );
      }
    }
  };

  nextToEnd = () => {
    const { kanriLYMList } = this.props;
    const { lymId, path } = this.state;

    if (kanriLYMList && kanriLYMList.length > 0) {
      let index = kanriLYMList.findIndex((item) => item.id === lymId);

      if (index !== kanriLYMList.length) {
        this.setState({
          lymId: kanriLYMList[kanriLYMList.length - 1].id,
        });

        this.props.history.replace(
          path +
            "/kanri/lifeyougomaster/" +
            kanriLYMList[kanriLYMList.length - 1].id
        );
      }
    }
  };

  recordMark = (type, id) => {
    this.props.recordMark("lym", type, id);
  };

  render() {
    const { kanriLYMList, lymSelectedRows } = this.props;
    const { lymId } = this.state;

    return (
      <KanriLayoutContainer router="lifeyougomaster" breadcrumbLink={"/kanri/lifeyougomaster"} breadcrumbText={"LIFE用語マスタ"}>
        <ActionsRow
          key={lymSelectedRows}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          selectedList={lymSelectedRows}
          id={lymId}
        />

        <WrappedLYMForm
          data={kanriLYMList.find((item) => item.id === lymId)}
          history={this.props.history}
          regData={(values) => this.registerLYM(values)}
          delete={this.delete}
        />
      </KanriLayoutContainer>
    );
  }
}

export default KanriLifeYougoMasterSave;
