export const DOCTOR_MASTER_SPIN_REQUEST = "DOCTOR_MASTER_SPIN_REQUEST";

export const LOAD_DOCTOR_MASTER_SUCCESS = "LOAD_DOCTOR_MASTER_SUCCESS";
export const LOAD_DOCTOR_MASTER_ERROR = "LOAD_DOCTOR_MASTER_ERROR";

export const LOAD_MAX_SORT_SUCCESS = "LOAD_MAX_SORT_SUCCESS";
export const LOAD_MAX_SORT_ERROR = "LOAD_MAX_SORT_ERROR";

export const LOAD_DOCTOR_MASTER_INIT_SUCCESS =
  "LOAD_DOCTOR_MASTER_INIT_SUCCESS";
export const LOAD_DOCTOR_MASTER_INIT_ERROR = "LOAD_DOCTOR_MASTER_INIT_ERROR";

export const CREATE_DOCTOR_MASTER_SUCCESS = "CREATE_DOCTOR_MASTER_SUCCESS";
export const CREATE_DOCTOR_MASTER_ERROR = "CREATE_DOCTOR_MASTER_ERROR";

export const UPDATE_DOCTOR_MASTER_SUCCESS = "UPDATE_DOCTOR_MASTER_SUCCESS";
export const UPDATE_LIST_DOCTOR_MASTER_SUCCESS =
  "UPDATE_LIST_DOCTOR_MASTER_SUCCESS";
export const UPDATE_DOCTOR_MASTER_ERROR = "UPDATE_DOCTOR_MASTER_ERROR";
