import { UndoOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderRiyousya from "../../common/component/SubHeaderRiyousya";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import { ConstSet } from "../../common/configs/constset";
import {
  getPath,
  getUrlVars,
} from "../../common/function_common/functionCommon";
import "./Progress.css";
import { ProgressRecordByUser } from "./UI/ProgressRecordByUser";

const filterObj = {
  search: {
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
    checkbox9: false,
    field2: null,
    field3: null,
    field4: null,
    field5: null,
    field6: null,
    field7: null,
    field8: null,
    field9: null,
    field10: null,
    field11: null,
  },
  sort: {
    key1: "0",
    key2: "0",
    key3: "0",
    key4: "0",
    key5: "0",
    value1: "asc",
    value2: "asc",
    value3: "asc",
    value4: "asc",
    value5: "asc",
  },
};

class ProgressRecordRiyousya extends Component {
  constructor(props) {
    let riyouKeikaFilter = JSON.parse(localStorage.getItem("riyouKeikaFilter"));
    if (!riyouKeikaFilter) {
      riyouKeikaFilter = filterObj;
      localStorage.setItem(
        "riyouKeikaFilter",
        JSON.stringify(riyouKeikaFilter)
      );
    }
    super(props);
    const { params } = this.props.match;
    let queryDateType = "";
    const queryDate = getUrlVars(window.location.href)["dateSelected"];
    if (queryDate) {
      queryDateType = dayjs(queryDate);
      const url = new URL(window.location.href);
      url.searchParams.delete("dateSelected");
      window.history.replaceState(null, null, url.href);
    }

    this.state = {
      startDate: queryDateType
        ? queryDateType
        : this.props.filterConditions.startDate,
      endDate: queryDateType
        ? queryDateType
        : this.props.filterConditions.endDate,
      showMessageOnly: this.props.filterConditions.tyuif,
      showDailyJournalOnly: this.props.filterConditions.nishif,
      riyousyaId: params.riyousyaId,
      riyousayInfo: null,
      expandAll: false,
      filterData: riyouKeikaFilter,
    };
  }

  componentDidMount() {
    // Setting page after redering DOM
    if (!this.props.filterMasterData) this.props.getFilterMasterData();
    if (!this.props.tantoMasters) this.props.getTantoMasterList();
    if (!this.props.systemNames) this.props.getSystemNameList();
    this.props.getRiyousyaInfo(this.state.riyousyaId);

    const filter = this.props.filterConditions;
    filter.search = this.state.filterData.search;
    filter.sort = this.state.filterData.sort;

    this.props.getReportCases(
      {
        riyousyaId: this.state.riyousyaId,
        startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
        endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
        tyuif: this.state.showMessageOnly ? "1" : null,
        nishif: this.state.showDailyJournalOnly ? "1" : null,
      },
      filter
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.riyousya && newProps.riyousya.id == this.state.riyousyaId) {
      this.setState({
        riyousayInfo: {
          riyousya: newProps.riyousya,
        },
      });
    }
  }

  /**
   * 3S UPDATE
   * @param {*} value
   */
  getReportRiyousya = (value) => {
    if (value) {
      const path = getPath();
      this.props.history.push(path + "/progress/" + value);
      this.setState({
        riyousyaId: value,
      });
      this.props.getRiyousyaInfo(value);
      this.props.getReportCases(
        {
          riyousyaId: value.toString(),
          startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
          endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
          tyuif: this.state.showMessageOnly ? "1" : null,
          nishif: this.state.showDailyJournalOnly ? "1" : null,
        },
        this.props.filterConditions
      );
    }
  };

  /**
   *
   * @param {*} date
   */
  handleRangePickerChange(date) {
    this.setState({
      startDate: date[0],
      endDate: date[1],
    });
    this.props.getReportCases(
      {
        riyousyaId: this.state.riyousyaId,
        startDate: date[0].format(ConstSet.DATE_FORMAT),
        endDate: date[1].format(ConstSet.DATE_FORMAT),
        tyuif: this.state.showMessageOnly ? "1" : null,
        nishif: this.state.showDailyJournalOnly ? "1" : null,
      },
      this.props.filterConditions
    );
  }

  handleChangeMessageCheckbox() {
    const newValue = !this.state.showMessageOnly;
    this.setState({
      showMessageOnly: newValue,
    });
    this.props.getReportCases(
      {
        riyousyaId: this.state.riyousyaId,
        startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
        endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
        tyuif: newValue ? "1" : null,
        nishif: this.state.showDailyJournalOnly ? "1" : null,
      },
      this.props.filterConditions
    );
  }

  handleChangeDailyJournalCheckbox() {
    const newValue = !this.state.showDailyJournalOnly;
    this.setState({
      showDailyJournalOnly: newValue,
    });
    this.props.getReportCases(
      {
        riyousyaId: this.state.riyousyaId,
        startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
        endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
        tyuif: this.state.showMessageOnly ? "1" : null,
        nishif: newValue ? "1" : null,
      },
      this.props.filterConditions
    );
  }

  handleFilterOk(filterData) {
    const riyouKeikaFilter = JSON.parse(
      localStorage.getItem("riyouKeikaFilter")
    );
    riyouKeikaFilter.search = filterData.search;
    localStorage.setItem("riyouKeikaFilter", JSON.stringify(riyouKeikaFilter));

    this.props.getReportCases(
      {
        riyousyaId: this.state.riyousyaId,
        startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
        endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
        tyuif: this.state.showMessageOnly ? "1" : null,
        nishif: this.state.showDailyJournalOnly ? "1" : null,
      },
      filterData
    );
  }

  handleSortOk(filterData) {
    const riyouKeikaFilter = JSON.parse(
      localStorage.getItem("riyouKeikaFilter")
    );
    riyouKeikaFilter.sort = filterData.sort;
    localStorage.setItem("riyouKeikaFilter", JSON.stringify(riyouKeikaFilter));

    this.props.getReportCases(
      {
        riyousyaId: this.state.riyousyaId,
        startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
        endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
        tyuif: this.state.showMessageOnly ? "1" : null,
        nishif: this.state.showDailyJournalOnly ? "1" : null,
      },
      filterData
    );
  }

  deleteReportCase(reportCaseId) {
    if (reportCaseId) {
      this.props.deleteReportCase(reportCaseId.toString());
    }
  }

  updateKakuninComment(data) {
    if (data && data.reportCaseId) {
      this.props.updateKakuninComment(data);
    }
  }

  goToInputReportCase(data) {
    const path = getPath();
    if (data.riyousyaId) {
      const reportCase = _.find(
        this.props.reportCases,
        (rc) => rc.reportCaseId == data.reportCaseId
      );
      this.setState({
        riyousyaId: data.riyousyaId,
        riyousayInfo: {
          riyousya: {
            id: data.riyousyaId,
            name: data.riyousyaName,
          },
        },
        reportCase,
      });
      this.props.history.push(
        `${path}/input-report-case/${data.riyousyaId}/${data.reportCaseId}`
      );
    }
    else {
      this.props.getRiyousyaInfo(data);
      this.setState({
        riyousyaId: data,
        reportCase: null,
      });
      this.props.history.push(`${path}/input-report-case/${data}/new`);
    }
  }

  onExpandChange() {
    this.setState({
      expandAll: !this.state.expandAll,
    });
  }

  render() {
    const { reportCases } = this.props;
    return (
      <CommonPageContainer
        id={"report-case-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <div className={"submenu"}>
          <SubHeaderV2
            TopLeft={
              <SubHeaderTitleV2
                title={"経過記録一覧（利用者別）"}
                iconType="message"
                count={true}
                countNum={reportCases ? reportCases.length : 0}
              />
            }
            TopRight={
              <Link
                to={"#"}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.goBack();
                }}
                className="sub-top-right-item"
              >
                <span>
                  <UndoOutlined />
                </span>
                <span>戻る</span>
              </Link>
            }
            IncBottom={
              <SubHeaderRiyousya
                style={{ display: "inline" }}
                reportRiyousya={(value) => this.getReportRiyousya(value)}
                riyousayInfo={this.state.riyousayInfo}
              />
            }
          />
        </div>
        <ProgressRecordByUser
          data={this.props.reportCases}
          riyousyaId={this.state.riyousyaId}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          handleRangePickerChange={(date, dateString) =>
            this.handleRangePickerChange(date, dateString)
          }
          filterMasterData={this.props.filterMasterData}
          tantoMasters={this.props.tantoMasters}
          systemNames={this.props.systemNames}
          filterConditions={this.props.filterConditions}
          handleFilterData={(filterData) => this.handleFilterOk(filterData)}
          riyousyaBunrui={true}
          // handleSortData={filterData => this.props.sortReportCases(filterData)}
          // reload data when sort. reload -> sort
          handleSortData={(filterData) => this.handleSortOk(filterData)}
          showMessageOnly={this.state.showMessageOnly}
          showDailyJournalOnly={this.state.showDailyJournalOnly}
          handleChangeMessageCheckbox={() => this.handleChangeMessageCheckbox()}
          handleChangeDailyJournalCheckbox={() =>
            this.handleChangeDailyJournalCheckbox()
          }
          handleGoToInputReportCase={(data) => this.goToInputReportCase(data)}
          handleDeleteReportCase={(reportCaseId) =>
            this.deleteReportCase(reportCaseId)
          }
          handleKCommentReportCase={(data) => this.updateKakuninComment(data)}
          expandAll={this.state.expandAll}
          handleChangeExpand={() => this.onExpandChange()}
        />
      </CommonPageContainer>
    );
  }
}

export default ProgressRecordRiyousya;
