import React, { Component } from "react";
import { ProgressRecordTable } from "./ProgressRecordTable";
import { Toolbar1 } from "./Toolbar1";
import { Toolbar2 } from "./Toolbar2";

export class ProgressRecordByUser extends Component {
  render() {
    const { riyousyaBunrui } = this.props;
    return (
      <div>
        <div style={{ paddingBottom: "5px", paddingTop: "5px" }}>
          <Toolbar1
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            handleRangePickerChange={(date, dateString) =>
              this.props.handleRangePickerChange(date, dateString)
            }
            filterMasterData={this.props.filterMasterData}
            tantoMasters={this.props.tantoMasters}
            systemNames={this.props.systemNames}
            filterConditions={this.props.filterConditions}
            handleFilterData={(filterData) =>
              this.props.handleFilterData(filterData)
            }
            handleSortData={(filterData) =>
              this.props.handleSortData(filterData)
            }
            showMessageOnly={this.props.showMessageOnly}
            showDailyJournalOnly={this.props.showDailyJournalOnly}
            handleChangeMessageCheckbox={() =>
              this.props.handleChangeMessageCheckbox()
            }
            handleChangeDailyJournalCheckbox={() =>
              this.props.handleChangeDailyJournalCheckbox()
            }
            riyousyaBunrui={riyousyaBunrui}
          />
        </div>
        <div style={{ paddingBottom: "5px" }}>
          <Toolbar2
            riyousyaId={this.props.riyousyaId}
            expandAll={this.props.expandAll}
            handleChangeExpand={this.props.handleChangeExpand}
            handleGoToInputReportCase={(data) =>
              this.props.handleGoToInputReportCase(data)
            }
            showMessageOnly={this.props.showMessageOnly}
            showDailyJournalOnly={this.props.showDailyJournalOnly}
            handleChangeMessageCheckbox={() =>
              this.props.handleChangeMessageCheckbox()
            }
            handleChangeDailyJournalCheckbox={() =>
              this.props.handleChangeDailyJournalCheckbox()
            }
          />
        </div>
        <div>
          <ProgressRecordTable
            data={this.props.data}
            riyousyaId={this.props.riyousyaId}
            expandAll={this.props.expandAll}
            tantoMasters={this.props.tantoMasters}
            handleGoToInputReportCase={(data) =>
              this.props.handleGoToInputReportCase(data)
            }
            handleDeleteReportCase={(reportCaseId) =>
              this.props.handleDeleteReportCase(reportCaseId)
            }
            handleKCommentReportCase={(data) =>
              this.props.handleKCommentReportCase(data)
            }
          />
        </div>
      </div>
    );
  }
}
