/* eslint-disable react/jsx-key */
import { Button, Col, Input, Modal, Row, Select } from "antd";
import { debounce } from "lodash";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { changeEditMode, getResultMonitoring, getRowInfolMonitoring, updateRowMonitoring } from "../../../../actions/monitoringActions/actionCreators";
import LineBreakText from "../../../../common/component/LineBreakText";
import CareTermDictModal from "../../UI/CareTermDictModal";
import MonitoringModal from "../../UI/MonitoringModal";
import TangoKaigiModal from "../../UI/TangoKaigiModal";
import ProgressRecord from "./ProgressRecord";

const { Option } = Select;
const listTime = [
  "1週間",
  "2週間",
  "1ヵ月",
  "3ヵ月",
  "6ヵ月",
  "8ヵ月",
  "9ヵ月",
  "10ヵ月",
  "11ヵ月",
  "12ヵ月",
  "1年",
  "1年半",
  "2年",
];

const newDetail = [
  {
    naiyou: "",
    service: "",
    condition: "",
    satisPerson: "",
    satisFamily: "",
    cope: "",
    sortNum: 1,
  },
];
class RowInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      list_content: props.monitoringResults,
      recordInfo: this.props.record,
      modalSetTimeVisible: false,
      careTermVisible: false,
      tantoKaigiVisible: false,
      monitoringVisible: false,
      visibleProgressRecord: false,
      kikanType: null,
      indexDetail: null,
      field: null,
      detailField: null,
      inputVisible: false,
      listName: null,
      confirmModalVisible: false,
      sortNum: null,
      linkingCareTerm: null,
      timeModal: {
        startDate: null,
        endDate: null,
        period: null,
      },
      valueYougo: "",
    };

    this.updateRowInfoMonitoring = debounce(this.updateRowInfoMonitoring, 700);
  }

  async UNSAFE_componentWillUpdate(nextProps) {
    if (!nextProps.edit && this.props.edit) {
      await this.handleSubmit();
      await this.props.changeEditMode(true);
    }
    if (nextProps.monitoringResults !== this.props.monitoringResults) {
      this.setState({
        list_content: nextProps.monitoringResults,
      });
    }
    if (nextProps.record !== this.props.record) {
      this.setState({
        recordInfo: nextProps.record,
      });
    }
  }

  handleSubmit = () => {
    const { list_content, recordInfo } = this.state;
    if (this.props.edit) {
      const { expandedRowKeys, record } = this.props;
      this.props.getRowInfolMonitoring({
        ...recordInfo,
        results: list_content,
        id: record.id,
        sortNum: expandedRowKeys,
      });
      this.props.updateRowMonitoring(expandedRowKeys, {
        ...recordInfo,
        results: list_content,
        id: record.id,
        sortNum: expandedRowKeys,
      });
    }
  };
  handleChangeAttainment(value) {
    const { list_content } = this.state;
    const { expandedRowKeys, record } = this.props;
    const newRecord = { ...this.state.recordInfo };
    newRecord["attainment"] = value;
    this.setState({
      recordInfo: newRecord,
    });
    this.props.getRowInfolMonitoring({
      ...newRecord,
      details: list_content,
      id: record.id,
      sortNum: expandedRowKeys,
    });
    // this.props.updateRowMonitoring(expandedRowKeys, { ...newRecord, details: list_content, id: record.id, sortNum: expandedRowKeys })
    this.triggerDataChange();
  }

  handleChangeRowInfor = (e) => {
    const { list_content, recordInfo } = this.state;
    const { expandedRowKeys, record } = this.props;
    const value = e.target.value;
    const name = e.target.name;
    const newRecord = { ...recordInfo };
    if (name === "longKikan" || name === "shortKikan") {
      if (value.length < 41) {
        newRecord[name] = value;
      }
      else {
        if (name === "longKikan") {
          // openNotificationWithIcon("error", "長期期間が40文字を超えています!");
        }
        if (name === "shortKikan") {
          // openNotificationWithIcon("error", "短期期間が40文字を超えています!");
        }
      }
    }
    else {
      newRecord[name] = value;
    }
    this.setState({
      recordInfo: newRecord,
    });
    const data = {
      ...newRecord,
      results: list_content,
      id: record.id,
      sortNum: expandedRowKeys,
    };
    this.updateRowInfoMonitoring(data);
    this.triggerDataChange();
  };

  updateRowInfoMonitoring = (data) => {
    this.props.getRowInfolMonitoring(data);
  };

  handleChange(e, index) {
    // const { list_content, recordInfo } = this.state;
    // const { expandedRowKeys, record } = this.props;
    // if (!this.props.valuesChange) {
    //   this.props.changeValuePlan2(true);
    // }
    const name = e.target.name;
    const newList = [...this.state.list_content];
    newList[index][name] = e.target.value;
    this.setState({
      list_content: newList,
    });
    this.props.getResultMonitoring(newList);
    // this.props.updateRowMonitoring(expandedRowKeys, { ...this.state.recordInfo, results: newList, id: record.id, sortNum: expandedRowKeys })
  }
  handleChangeSelectResult(name, value, index) {
    const { list_content } = this.state;
    // const { expandedRowKeys, record } = this.props;
    const newList = [...list_content];
    newList[index][name] = value;
    this.setState({
      list_content: newList,
    });
    this.props.getResultMonitoring(newList);
    // this.props.updateRowMonitoring(expandedRowKeys, { ...recordInfo, results: newList, id: record.id, sortNum: expandedRowKeys })
    this.triggerDataChange();
  }

  showModalSetTime = (types, index, value) => {
    const timeClone = { ...this.state.timeModal };
    if (!value) {
      timeClone.startDate = this.props.startDate;
      timeClone.endDate = this.props.endDate;
      timeClone.period = this.props.period;
    }
    if (!value || (value !== null && value.trim().length == 0)) {
      timeClone.startDate = this.props.startDate;
      timeClone.endDate = this.props.endDate;
      timeClone.period = this.props.period;
    }
    else {
      const valueJac = value.split("ー").join("-");
      const valueClone1 = valueJac
        .split("、")[0]
        .replace(/\n/g, " ")
        .replace(/~/g, "～")
        .split(" ")
        .filter((val) => val);
      const valueClone = valueClone1.filter((val) => val !== "～");
      let i = -1;
      if (listTime.indexOf(`${valueClone[0]}`) !== -1) {
        i = i + 1;
      }
      timeClone.period = valueClone[i];
      if (valueClone[i + 1]) {
        timeClone.startDate =
          valueClone[i + 1].length >= 8 ? valueClone[i + 1] : null;
      }
      else {
        timeClone.startDate = null;
      }
      if (valueClone[i + 2]) {
        timeClone.endDate =
          valueClone[i + 2].length >= 8 ? valueClone[i + 2] : null;
      }
      else {
        timeClone.endDate = null;
      }
    }

    this.setState({
      modalSetTimeVisible: true,
      kikanType: types,
      indexDetail: index,
      timeModal: timeClone,
    });
  };

  handleSetTimeOk = () => {
    this.setState({
      modalSetTimeVisible: false,
    });
  };

  handleSetTimeCancel = () => {
    this.setState({
      modalSetTimeVisible: false,
    });
  };

  handleSelectCareTerm(careTerm) {
    const { field, detailField, indexDetail } = this.state;
    const { list_content } = this.state;
    const { expandedRowKeys, record } = this.props;
    if (careTerm) {
      // if (!this.props.valuesChange) {
      //   this.props.changeValuePlan2(true);
      // }
      if (detailField === "results") {
        const newList = [...this.state.list_content];
        if (
          !newList[indexDetail][field] ||
          newList[indexDetail][field].length === 0
        ) {
          newList[indexDetail][field] = `${careTerm}`;
        }
        else {
          newList[indexDetail][
            field
          ] = `${newList[indexDetail][field]} 、${careTerm}`;
        }
        this.setState({
          list_content: newList,
          careTermVisible: false,
        });
        this.props.getResultMonitoring(newList);
      }
      else {
        const newRecord = { ...this.state.recordInfo };
        if (!newRecord[field] || newRecord[field].length === 0) {
          newRecord[field] = `${careTerm}`;
        }
        else {
          newRecord[field] = `${newRecord[field]} 、${careTerm}`;
        }
        this.props.getRowInfolMonitoring(newRecord);
        this.props.updateRowMonitoring(expandedRowKeys, {
          ...newRecord,
          results: list_content,
          id: record.id,
          sortNum: expandedRowKeys,
        });
        this.setState({
          recordInfo: newRecord,
          careTermVisible: false,
        });
      }
    }
    else {
      this.setState({
        careTermVisible: false,
      });
    }
    this.triggerDataChange();
  }

  hideModalCareTerm() {
    this.setState({
      linkingCareTerm: "",
      careTermVisible: false,
    });
  }

  showModalCareTerm(field, type, index, linkingCareTerm) {
    this.setState({
      careTermVisible: true,
      field,
      detailField: type,
      indexDetail: index,
      linkingCareTerm,
    });
  }

  showYougoModal(listName, field, values, type, index) {
    this.props.getYougoMaster(listName);
    this.setState({
      inputVisible: true,
      listName,
      field,
      indexDetail: index,
      detailField: type,
      valueYougo: values,
    });
  }

  handleYougoCancel() {
    this.setState({
      inputVisible: false,
      listName: null,
      valueYougo: "",
    });
  }

  handleYougoOk(values) {
    const { field, detailField, indexDetail } = this.state;
    if (values) {
      if (!this.props.valuesChange) {
        this.props.changeValuePlan2(true);
      }
      if (detailField === "detail") {
        const newList = [...this.state.list_content];
        newList[indexDetail][field] = `${values}`;
        this.setState({
          list_content: newList,
          inputVisible: false,
          listName: null,
          valueYougo: "",
        });
        this.props.getDetailKyotakuPlan2(newList);
      }
      else {
        const newRecord = { ...this.state.recordInfo };
        newRecord[field] = `${values}`;
        this.props.getRowInfolKyotakuPlan2(newRecord);
        this.setState({
          recordInfo: newRecord,
          inputVisible: false,
          listName: null,
          valueYougo: "",
        });
      }
    }
    else {
      this.setState({
        inputVisible: false,
        listName: null,
      });
    }
  }

  showModalDelete = (sortNum) => {
    const { kyotakuPlan2Details } = this.props;
    if (kyotakuPlan2Details != newDetail) {
      this.setState({ sortNum, confirmModalVisible: true });
    }
  };

  handleDeleteDetailOk = () => {
    const { kyotakuPlan2Details } = this.props;
    if (!this.props.valuesChange) {
      this.props.changeValuePlan2(true);
    }

    if (kyotakuPlan2Details && kyotakuPlan2Details.length !== 1) {
      this.props.deleteDetailKyotakuPlan2(this.state.sortNum);
    }
    else {
      this.props.getDetailKyotakuPlan2(newDetail);
    }
    this.setState({
      confirmModalVisible: false,
    });
  };
  handleDeleteDetailCancel = () => {
    this.setState({
      confirmModalVisible: false,
    });
  };

  triggerDataChange() {
    if (this.props.onChangeData) {
      this.props.onChangeData();
    }
  }

  renderEvaluation(recordInfo) {
    return (
      <Fragment>
        <div className="evaluation">
          <span className="text-bold">評価、対応、課題等</span>
          <Input.TextArea autoSize={{ minRows: 5 }} className={"w-full"}
            value={recordInfo.evaluate}
            name="evaluate"
            onChange={this.handleChangeRowInfor}
          />
          <div className="evaluation__buttons">
            <Button
              size={"small"}
              onClick={() =>
                this.showModalCareTerm("evaluate", null, null, "評価対応課題等")
              }
              type={"primary"}
              style={{ marginLeft: 1, marginBottom: 2, width: "18%" }}
            >
              用語
            </Button>
            <ProgressRecord
              visible={this.state.visibleProgressRecord}
              riyousyaId={this.props.riyousyaId}
              monitoringDate={this.props.monitoringDate}
              onOkQuoteComment={(value) => this.handleSelectCareTerm(value)}
              onHideProgessRecord={() =>
                this.hideModal("visibleProgressRecord")
              }
              onShow={() => this.setState({ field: "evaluate" })}
            />
            <Button
              size={"small"}
              onClick={() => this.showModal("evaluate", "tantoKaigiVisible")}
              type={"primary"}
              style={{ marginLeft: 1, marginBottom: 2, width: "26%" }}
            >
              担当者会議
            </Button>
            <Button
              size={"small"}
              onClick={() => this.showModal("evaluate", "monitoringVisible")}
              type={"primary"}
              style={{ marginLeft: 1, marginBottom: 2, width: "28%" }}
            >
              モニタリング
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }

  showModal(field, modalName) {
    this.setState({ field, [modalName]: true });
  }
  hideModal(modalName) {
    this.setState({ [modalName]: false });
  }

  render() {
    // const autoSize = { minRows: 2 };
    const {
      list_content,
      recordInfo,
      confirmModalVisible,
    } = this.state;

    const { expandedRowKeys } = this.props;
    return (
      (<div className="mt-1">
        <hr />
        <Row type="flex" justify="space-between" className="mb-1" style={{ marginTop: 8 }}>
          <Col sx={6} ms={6} md={6} className="text-bold">
            {`＜短期目標${expandedRowKeys}表示中＞`}
          </Col>
          <Col
            sx={2}
            ms={2}
            md={2}
            className="flex-end"
            style={this.props.editDetail ? { display: "none" } : {}}
          ></Col>
        </Row>
        <Row gutter={10}>
          <Col sx={24} ms={24} md={8}>
            <div style={{ marginBottom: 10 }}>
              <span className="text-bold" style={{ marginRight: 10 }}>
                目標達成度
              </span>
              <Select
                value={recordInfo ? recordInfo.attainment : ""}
                name="attainment"
                onChange={(value) => this.handleChangeAttainment(value)}
                style={{ width: 170 }}
              >
                <Option value="">&nbsp;</Option>
                <Option value="1.目標達成">{"1.目標達成"}</Option>
                <Option value="2.一部達成">{"2.一部達成"}</Option>
                <Option value="3.達成していない">{"3.達成していない"}</Option>
                <Option value="4.その他">{"4.その他"}</Option>
              </Select>
            </div>
            <div className="short-term">
              <span className="text-bold">短期目標</span>
              <div className="text-label">
                <LineBreakText text={recordInfo.shortMokuhyou} />
              </div>
            </div>
            {this.renderEvaluation(recordInfo)}
          </Col>
          <Col sx={24} ms={24} md={16} className="results">
            {list_content && Array.isArray(list_content)
              ? list_content.map((item, index) => (
                <Row
                  key={index}
                  className="border-black mb-1 result"
                  gutter={10}
                >
                  <Col md={24} >
                    <div className="text-bold result__title">{`援助内容${index + 1}`}</div>
                  </Col>
                  <Col md={14}>
                    <Row gutter={10}>
                      <Col sx={24} ms={24} lg={16} className="result__input">
                        <span className="text-bold">サービス内容</span>
                        <div className="text-label">
                          <LineBreakText text={item.naiyou} />
                        </div>
                      </Col>
                      <Col sx={24} ms={24} lg={8} className="result__input">
                        <span className="text-bold">担当者</span>
                        <div className="text-label">
                          <LineBreakText text={item.service} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={10} className="result__selects">
                    <div>
                      <table>
                        <colgroup>
                          <col width="40%" />
                          <col witdth="60%" />
                        </colgroup>
                        <tbody>
                          <tr style={{ marginBottom: 8 }}>
                            <td
                              className={"text-bold"}
                              style={{ backgroundColor: "#d3d0d0" }}
                            >
                              実施状況
                            </td>
                            <td>
                              <div>
                                {" "}
                                <Select
                                  value={item.condition}
                                  name="condition"
                                  onChange={(value) =>
                                    this.handleChangeSelectResult(
                                      "condition",
                                      value,
                                      index
                                    )
                                  }
                                  style={{ width: "100%" }}
                                >
                                  <Option value="">&nbsp;</Option>
                                  <Option value="1.実施されている">{"1.実施されている"}</Option>
                                  <Option value="2.実施されてないことがある">{"2.実施されてないことがある"}</Option>
                                  <Option value="3.実施されていない">{"3.実施されていない"}</Option>
                                  <Option value="4.その他">{"4.その他"}</Option>
                                </Select>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className={"text-bold"}
                              style={{ backgroundColor: "#d3d0d0" }}
                            >
                              満足度（本人）
                            </td>
                            <td>
                              <div style={{ marginTop: 4 }}>
                                {" "}
                                <Select
                                  value={item.satisPerson}
                                  name="satisPerson"
                                  onChange={(value) =>
                                    this.handleChangeSelectResult(
                                      "satisPerson",
                                      value,
                                      index
                                    )
                                  }
                                  style={{ width: "100%" }}
                                >
                                  <Option value="">&nbsp;</Option>
                                  <Option value="1.満足している">{"1.満足している"}</Option>
                                  <Option value="2.ある程度満足している">{"2.ある程度満足している"}</Option>
                                  <Option value="3.満足していない">{"3.満足していない"}</Option>
                                  <Option value="4.その他">{"4.その他"}</Option>
                                </Select>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className={"text-bold"}
                              style={{ backgroundColor: "#d3d0d0" }}
                            >
                              満足度（家族）
                            </td>
                            <td>
                              <div style={{ marginTop: 4 }}>
                                {" "}
                                <Select
                                  value={item.satisFamily}
                                  name="satisFamily"
                                  onChange={(value) =>
                                    this.handleChangeSelectResult(
                                      "satisFamily",
                                      value,
                                      index
                                    )
                                  }
                                  style={{ width: "100%" }}
                                >
                                  <Option value="">&nbsp;</Option>
                                  <Option value="1.満足している">{"1.満足している"}</Option>
                                  <Option value="2.ある程度満足している">{"2.ある程度満足している"}</Option>
                                  <Option value="3.満足していない">{"3.満足していない"}</Option>
                                  <Option value="4.その他">{"4.その他"}</Option>
                                </Select>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className={"text-bold"}
                              style={{ backgroundColor: "#d3d0d0" }}
                            >
                              今後の対応
                            </td>
                            <td>
                              <div style={{ marginTop: 4 }}>
                                {" "}
                                <Select
                                  value={item.cope}
                                  name="cope"
                                  onChange={(value) =>
                                    this.handleChangeSelectResult(
                                      "cope",
                                      value,
                                      index
                                    )
                                  }
                                  style={{ width: "100%" }}
                                >
                                  <Option value="">&nbsp;</Option>
                                  <Option value="1.プラン継続">{"1.プラン継続"}</Option>
                                  <Option value="2.プラン一部変更">{"2.プラン一部変更"}</Option>
                                  <Option value="3.プラン変更">{"3.プラン変更"}</Option>
                                  <Option value="4.プラン中止">{"4.プラン中止"}</Option>
                                  <Option value="5.その他">{"5.その他"}</Option>
                                </Select>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              ))
              : null}
          </Col>
        </Row>
        <Modal
          open={confirmModalVisible}
          onOk={this.handleDeleteDetailOk}
          onCancel={this.handleDeleteDetailCancel}
        >
          <p>選択中のデータを削除しますか?</p>
        </Modal>
        <CareTermDictModal
          visible={this.state.careTermVisible}
          linkingCareTerm={this.state.linkingCareTerm}
          handleSelectCareTerm={(data) => this.handleSelectCareTerm(data)}
          hideModalCareTerm={() => this.hideModalCareTerm()}
        />
        <TangoKaigiModal
          visible={this.state.tantoKaigiVisible}
          riyousyaId={this.props.riyousyaId}
          monitoringDate={this.props.monitoringDate}
          onSelectItem={(data) => this.handleSelectCareTerm(data)}
          onHide={() => this.hideModal("tantoKaigiVisible")}
        />
        <MonitoringModal
          visible={this.state.monitoringVisible}
          riyousyaId={this.props.riyousyaId}
          monitoringDate={this.props.monitoringDate}
          onSelectItem={(data) => this.handleSelectCareTerm(data)}
          onHide={() => this.hideModal("monitoringVisible")}
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    monitoringResults: state.monitoring.monitoringResults,
    expandedRowKeys: state.monitoring.expandedRowKeys,
    edit: state.monitoring.editMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRowInfolMonitoring: (data) => dispatch(getRowInfolMonitoring(data)),
    getResultMonitoring: (data) => dispatch(getResultMonitoring(data)),
    changeEditMode: (mode) => dispatch(changeEditMode(mode)),
    // addDetailKyotakuPlan2: () => dispatch(addDetailKyotakuPlan2()),
    // deleteDetailKyotakuPlan2: (sortNum) => dispatch(deleteDetailKyotakuPlan2(sortNum)),
    // getDetailKyotakuPlan2: (data) => dispatch(getDetailKyotakuPlan2(data)),
    // getRowInfolKyotakuPlan2: (data) => dispatch(getRowInfolKyotakuPlan2(data)),
    updateRowMonitoring: (sortNum, data) =>
      dispatch(updateRowMonitoring(sortNum, data)),
    // yougoMasterEditState: (index) => dispatch(yougoMasterEditStateCreator(index)),
    // yougoMasterEditCancel: (index) => dispatch(yougoMasterEditCancelCreator(index)),
    // yougoMasterEditSave: (naiyou) => dispatch(yougoMasterEditSaveCreator(naiyou)),
    // changeEditMode: (mode) => dispatch(changeEditMode(mode)),
    // getYougoMaster: (listName) => dispatch(getKyotakuPlan2YougoMaster(listName)),
    // changeValuePlan2: (type) => dispatch(changeValuePlan2(type)),
    // upRowKyotakuPlan2Detail: (sortNum) => dispatch(upRowKyotakuPlan2Detail(sortNum)),
    // downRowKyotakuPlan2Detail: (sortNum) => dispatch(downRowKyotakuPlan2Detail(sortNum))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RowInfo);
