import { Col, DatePicker, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import React from "react";
import { isValidDate } from "./common";

const { Option } = Select;
const listTime = [
  "1週間",
  "2週間",
  "1ヵ月",
  "3ヵ月",
  "6ヵ月",
  "8ヵ月",
  "9ヵ月",
  "10ヵ月",
  "11ヵ月",
  "12ヵ月",
  "1年",
  "1年半",
  "2年",
];

export default class TimeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDateOpen: false,
      endDateOpen: false,
    };
  }

  openChangeStartDate = (open) => {
    this.setState({ startDateOpen: open });
  };

  clearStartDate = (typeTime) => {
    this.props.clearStartDate(typeTime);
    this.setState({
      startDateOpen: false,
    });
  };

  openChangeEndDate = (open) => {
    this.setState({ endDateOpen: open });
  };

  clearEndDate = (typeTime) => {
    this.props.clearEndDate(typeTime);
    this.setState({
      endDateOpen: false,
    });
  };

  disabledStartDate = (startValue) => {
    const { endDate } = this.props;
    if (!startValue || !dayjs(endDate)) {
      return false;
    }
    return startValue.valueOf() > dayjs(endDate).valueOf();
  };

  disabledEndDate = (endValue) => {
    const { startDate } = this.props;
    if (!endValue || !dayjs(startDate)) {
      return false;
    }
    return endValue.valueOf() <= dayjs(startDate).valueOf();
  };

  onChangeDateStart = (date, dateString, typeTime) => {
    this.props.onChangeDateStart(date, dateString, typeTime);
  };

  onChangeDateEnd = (date, dateString, typeTime) => {
    this.props.onChangeDateEnd(date, dateString, typeTime);
  };
  onChangeTextArea(e, typeTime) {
    this.props.onChangeTextArea(e.target.value, typeTime);
  }

  render() {
    const {
      timeKikan,
      typeTime,
      period,
      startDate,
      endDate,
      singleModalTitle,
      kikanType,
      valueString,
    } = this.props;
    const { startDateOpen, endDateOpen } = this.state;
    return (
      (<Row id="time-component" type="flex" justify="space-between">
        {singleModalTitle ? (
          <Col xs={24} md={6} className="mb-1">
            {kikanType === "longKikan" ? <div className="mb-1">長期期間</div> : <div />}
            {kikanType === "shortKikan" ? <div className="mb-1">短期期間</div> : <div />}
            {kikanType === "kikan" ? <div className="mb-1">内容期間</div> : <div />}
          </Col>
        ) : (
          <Col xs={24} md={6} className="mb-1">
            {timeKikan}
          </Col>
        )}
        <Col xs={24} md={7}>
          <Row>
            <Col xs={24} md={24} className="mb-1">
              <Select
                value={period}
                onChange={(e) => this.props.onChangeSetPeriod(e, typeTime)}
                style={{ width: "100%" }}
              >
                <Select.Option key="blank" value={null}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Select.Option>
                {listTime.map((time) => (
                  <Option key={time} value={time ? time : null}>
                    {time}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} md={24} className="mb-1">
              <DatePicker
                open={startDateOpen}
                onChange={(date, dateString) =>
                  this.onChangeDateStart(date, dateString, typeTime)
                }
                value={
                  isValidDate(startDate)
                    ? dayjs(startDate, "YYYY-MM-DD")
                    : null
                }
                popupClassName="date-picker-new-option"
                disabledDate={this.disabledStartDate}
                renderExtraFooter={() => (
                  <div>
                    <span style={{ cursor: "pointer" }} onClick={() => this.clearStartDate(typeTime)}>
                      クリア
                    </span>
                  </div>
                )}
                style={{ width: "100%" }}
                onOpenChange={this.openChangeStartDate}
              />
            </Col>
            <Col xs={24} md={24}>
              <DatePicker
                readonly
                open={endDateOpen}
                onChange={(date, dateString) =>
                  this.onChangeDateEnd(date, dateString, typeTime)
                }
                value={
                  isValidDate(endDate) ? dayjs(endDate, "YYYY-MM-DD") : null
                }
                popupClassName="date-picker-new-option"
                disabledDate={this.disabledEndDate}
                renderExtraFooter={() => (
                  <div>
                    <span style={{ cursor: "pointer" }} onClick={() => this.clearEndDate(typeTime)}>
                      クリア
                    </span>
                  </div>
                )}
                style={{ width: "100%" }}
                onOpenChange={this.openChangeEndDate}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={0} md={2}>
          <div className="flex-center font-1">~</div>
        </Col>
        <Col xs={24} md={8}>
          <div className="border h-100 radius-3">
            <div className="title-time">出来上がり</div>
            <Input.TextArea
              className="content-time"
              style={{ color: "rgba(0, 0, 0, 0.65)" }}
              autoSize={{ maxRows: 5 }}
              value={valueString}
              onChange={(e) => this.onChangeTextArea(e, typeTime)}
            />
          </div>
        </Col>
      </Row>)
    );
  }
}
