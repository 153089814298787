import { KagakutekiActionTypes } from "../action_types";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { riyouAddSortDel } from "../../common/function_common/functionCommon.js";

// const INITIAL_STATE = {
//   yakuzaiChangeDetail: [{ changeStatus: "11" }],
//   yakuzaiChangeKioureki: [{}],
// };

function kagakuteki(state = {}, action) {
  switch (action.type) {
    case KagakutekiActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    
    case KagakutekiActionTypes.GET_LIFE_YOUGO_KAGAKU_SUCCESS: {
      return {
        ...state,
        loading: false,
        lifeYougoKagaku: action.data,
      };
    }
    case KagakutekiActionTypes.GET_LIFE_YOUGO_KAGAKU_ERROR: {
      return {
        ...state,
        loading: false,
        lifeYougoKagaku: null,
      };
    }
    case KagakutekiActionTypes.GET_LIFE_YOUGO_KOKU_SUCCESS: {
      return {
        ...state,
        loading: false,
        lifeYougoKoku: action.data,
      };
    }
    case KagakutekiActionTypes.GET_LIFE_YOUGO_KOKU_ERROR: {
      return {
        ...state,
        loading: false,
        lifeYougoKoku: null,
      };
    }
    case KagakutekiActionTypes.GET_LIFE_YOUGO_VITAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        lifeYougoVital: action.data,
      };
    }
    case KagakutekiActionTypes.GET_LIFE_YOUGO_VITAL_ERROR: {
      return {
        ...state,
        loading: false,
        lifeYougoVital: null,
      };
    }

    case KagakutekiActionTypes.LOAD_KAGAKUTEKI_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case KagakutekiActionTypes.LOAD_KAGAKUTEKI_ERROR: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    case KagakutekiActionTypes.SAVE_KAGAKUTEKI_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case KagakutekiActionTypes.SAVE_KAGAKUTEKI_ERROR: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
        data: null,
      };
    }
    case KagakutekiActionTypes.UPDATE_KAGAKUTEKI_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        // data: action.data,
      };
    }
    case KagakutekiActionTypes.UPDATE_KAGAKUTEKI_ERROR: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
      };
    }

    case KagakutekiActionTypes.LOAD_KAGAKUTEKI_TO_QUOTE_SUCCESS: {
      return {
        ...state,
        kagakutekiDetails: action.data,
      };
    }
    case KagakutekiActionTypes.LOAD_KAGAKUTEKI_TO_QUOTE: {
      openNotificationWithIcon("success", "前回引用を実施しました");
      return {
        ...state,
        data: action.data,
      };
    }
    case KagakutekiActionTypes.REMOVE_KAGAKUTEKI_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case KagakutekiActionTypes.REMOVE_KAGAKUTEKI_SUCCESS: {
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
      }
    }
    case KagakutekiActionTypes.REMOVE_KAGAKUTEKI_ERROR: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
      }
    }

    case KagakutekiActionTypes.SET_NEW_DATA: {
      return {
        ...state,
        data: {},
      };
    }

    case KagakutekiActionTypes.GET_FIRST_LOAD_DATA: {
      return {
        ...state,
        fistLoadData: action.data,
      }
    }

    case KagakutekiActionTypes.GET_LIST_IDS_KAGAKUTEKI: {
      return {
        ...state,
        loading: false,
        kagakutekiIds: action.ids,
      };
    }

    case KagakutekiActionTypes.GET_KAGAKUTEKI_ADL_INYO:
      return { ...state, loading: true };
    case KagakutekiActionTypes.GET_KAGAKUTEKI_ADL_INYO_SUCCESS:
      openNotificationWithIcon("success", "引用しました");
      return {
        ...state,
        loading: false,
      };
    case KagakutekiActionTypes.GET_KAGAKUTEKI_ADL_INYO_ERROR:
      openNotificationWithIcon("error", "記録がありません");
      return { ...state, loading: false };

    default: {
      return { ...state };
    }
  }
}

export default kagakuteki;
