export const KagakutekiActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  GET_LIFE_YOUGO_KAGAKU_SUCCESS: "GET_LIFE_YOUGO_KAGAKU_SUCCESS",
  GET_LIFE_YOUGO_KAGAKU_ERROR: "GET_LIFE_YOUGO_KAGAKU_ERROR",
  GET_LIFE_YOUGO_KOKU_SUCCESS: "GET_LIFE_YOUGO_KOKU_SUCCESS",
  GET_LIFE_YOUGO_KOKU_ERROR: "GET_LIFE_YOUGO_KOKU_ERROR",
  GET_LIFE_YOUGO_VITAL_SUCCESS: "GET_LIFE_YOUGO_VITAL_SUCCESS",
  GET_LIFE_YOUGO_VITAL_ERROR: "GET_LIFE_YOUGO_VITAL_ERROR",

  LOAD_KAGAKUTEKI_SUCCESS: "LOAD_KAGAKUTEKI_SUCCESS",
  LOAD_KAGAKUTEKI_ERROR: "LOAD_KAGAKUTEKI_ERROR",

  REMOVE_KAGAKUTEKI_REQUEST: "REMOVE_KAGAKUTEKI_REQUEST",
  REMOVE_KAGAKUTEKI_SUCCESS: "REMOVE_KAGAKUTEKI_SUCCESS",
  REMOVE_KAGAKUTEKI_ERROR: "REMOVE_KAGAKUTEKI_ERROR",

  SAVE_KAGAKUTEKI_SUCCESS: "SAVE_KAGAKUTEKI_SUCCESS",
  SAVE_KAGAKUTEKI_ERROR: "SAVE_KAGAKUTEKI_ERROR",

  UPDATE_KAGAKUTEKI_SUCCESS: "UPDATE_KAGAKUTEKI_SUCCESS",
  UPDATE_KAGAKUTEKI_ERROR: "UPDATE_KAGAKUTEKI_ERROR",

  LOAD_KAGAKUTEKI_TO_QUOTE_SUCCESS: "LOAD_KAGAKUTEKI_TO_QUOTE_SUCCESS",
  LOAD_KAGAKUTEKI_TO_QUOTE: "LOAD_KAGAKUTEKI_TO_QUOTE",

  SET_NEW_DATA: "SET_NEW_DATA",
  GET_LIST_IDS_KAGAKUTEKI: "GET_LIST_IDS_KAGAKUTEKI",

  LOAD_KAGAKUTEKI_BY_IDS_REQUEST: "LOAD_KAGAKUTEKI_BY_IDS_REQUEST",
  LOAD_KAGAKUTEKI_BY_IDS_SUCCESS: "LOAD_KAGAKUTEKI_BY_IDS_SUCCESS",
  LOAD_KAGAKUTEKI_BY_IDS_ERROR: "LOAD_KAGAKUTEKI_BY_IDS_ERROR",
  CLEAR_KAGAKUTEKI_BY_IDS: "CLEAR_KAGAKUTEKI_BY_IDS",

  GET_KAGAKUTEKI_ADL_INYO: "GET_KAGAKUTEKI_ADL_INYO",
  GET_KAGAKUTEKI_ADL_INYO_SUCCESS: "GET_KAGAKUTEKI_ADL_INYO_SUCCESS",
  GET_KAGAKUTEKI_ADL_INYO_ERROR: "GET_KAGAKUTEKI_ADL_INYO_ERROR",

  GET_FIRST_LOAD_DATA: "GET_FIRST_LOAD_DATA",
};
