import { CloseOutlined, UndoOutlined } from "@ant-design/icons";
import { Affix, Row, Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import {
  loadKeikaKiroku,
  loadKeikaKirokuById,
} from "../../../actions/keikaKirokuActions/actionCreators";
import {
  getKyotakuPlan1,
  loadKyotakuPlan1,
} from "../../../actions/kyotakuPlan1Actions/actionCreators";
import {
  getKyotakuPlan2,
  loadKyotakuPlan2,
} from "../../../actions/kyotakuPlan2Actions/actionCreators";
import {
  getMonitoringById,
  loadMonitoring,
} from "../../../actions/monitoringActions/actionCreators";
import { getRiyousya } from "../../../actions/riyousyaActions/actionCreators";
import {
  getSisetuDailyPlan,
  loadSisetuDailyPlan,
} from "../../../actions/sisetuDailyPlanActions/actionCreators";
import {
  loadTantoKaigi,
  loadTantoKaigiById,
} from "../../../actions/tantoKaigiActions/actionCreators";
import { CommonPageContainer } from "../../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../../common/component/SubHeaderV2";
import { convertSortDataToParams } from "../DocumentListRecord";
import "./InfoKyotaku.css";
import PlanNavigator from "./PlanNavigator";
import ViewDailyPlan from "./ViewDailyPlan";
import ViewKeikaKiroku from "./ViewKeikaKiroku";
import ViewMonitoring from "./ViewMonitoring";
import ViewPlan1 from "./ViewPlan1";
import ViewPlan2 from "./ViewPlan2";
import ViewTantoKaigi from "./ViewTantoKaigi";

const dataKey = "showreportlist2";

class InfoDocumentList extends React.Component {
  constructor(props) {
    super(props);
    const showReportList = JSON.parse(localStorage.getItem(dataKey));
    const { params } = this.props.match;
    const { pathname } = this.props.history.location;
    const route = pathname.split("/")[1];
    this.canLoad = false;
    this.index = this.getIndex();
    this.state = {
      tabActive: showReportList.tabActive,
      index: Number(params.index),
      riyousyaId: Number(params.riyousyaId),
      route,
      recordId: Number(params.recordId),
    };
  }

  getIndex() {
    return this.props.match.params.index;
  }

  componentDidMount() {
    if (
      this.state.route === "check-info-document-list" &&
      this.state.tabActive === "1"
    ) {
      this.props.getKyotakuPlan1(this.state.recordId);
      this.props.getRiyousyaInfo(this.state.riyousyaId);
    }
    if (
      this.state.route !== "check-info-document-list" &&
      this.state.tabActive === "1"
    ) {
      const page =
        Math.floor(this.state.index / this.props.metaPlan1.pageSize) + 1;
      if (!this.props.dataPlan1 || page !== this.props.metaPlan1.page) {
        this.getData(this.state.index, page);
      }
      else {
        const realIndex = this.state.index % this.props.metaPlan1.pageSize;
        const record = this.props.dataPlan1[realIndex];
        this.props.getKyotakuPlan1(record.id);
        this.props.getRiyousyaInfo(record.riyousyaId);
      }
    }
    if (
      this.state.route === "check-info-document-list" &&
      this.state.tabActive === "2"
    ) {
      this.props.getKyotakuPlan2(this.state.recordId);
      this.props.getRiyousyaInfo(this.state.riyousyaId);
    }
    if (
      this.state.route !== "check-info-document-list" &&
      this.state.tabActive === "2"
    ) {
      const page =
        Math.floor(this.state.index / this.props.metaPlan2.pageSize) + 1;
      if (!this.props.dataPlan2 || page !== this.props.metaPlan2.page) {
        this.getData(this.state.index, page);
      }
      else {
        const realIndex = this.state.index % this.props.metaPlan2.pageSize;
        const record = this.props.dataPlan2[realIndex];
        this.props.getKyotakuPlan2(record.id);
        this.props.getRiyousyaInfo(record.riyousyaId);
      }
    }
    if (
      this.state.route === "check-info-document-list" &&
      this.state.tabActive === "3"
    ) {
      this.props.getSisetuDailyPlan(this.state.recordId);
      this.props.getRiyousyaInfo(this.state.riyousyaId);
    }
    if (
      this.state.route !== "check-info-document-list" &&
      this.state.tabActive === "3"
    ) {
      const page =
        Math.floor(this.state.index / this.props.metaPlan3.pageSize) + 1;
      if (!this.props.dataDailyPlan || page !== this.props.metaPlan3.page) {
        this.getData(this.state.index, page);
      }
      else {
        const realIndex = this.state.index % this.props.metaPlan3.pageSize;
        const record = this.props.dataDailyPlan[realIndex];
        this.props.getSisetuDailyPlan(record.id);
        this.props.getRiyousyaInfo(record.riyousyaId);
      }
    }
    if (
      this.state.route === "check-info-document-list" &&
      this.state.tabActive === "4"
    ) {
      this.props.getMonitoringById(this.state.recordId);
      this.props.getRiyousyaInfo(this.state.riyousyaId);
    }
    if (
      this.state.route !== "check-info-document-list" &&
      this.state.tabActive === "4"
    ) {
      const page =
        Math.floor(this.state.index / this.props.metaMonitoring.pageSize) + 1;
      if (!this.props.monitorings || page !== this.props.metaMonitoring.page) {
        this.getData(this.state.index, page);
      }
      else {
        const realIndex = this.state.index % this.props.metaMonitoring.pageSize;
        const record = this.props.monitorings[realIndex];
        this.props.getMonitoringById(record.id);
        this.props.getRiyousyaInfo(record.riyousyaId);
      }
    }
    if (
      this.state.route === "check-info-document-list" &&
      this.state.tabActive === "5"
    ) {
      this.props.loadTantoKaigiById(this.state.recordId);
      this.props.getRiyousyaInfo(this.state.riyousyaId);
    }
    if (
      this.state.route !== "check-info-document-list" &&
      this.state.tabActive === "5"
    ) {
      const page =
        Math.floor(this.state.index / this.props.metaTantoKaigi.pageSize) + 1;
      if (!this.props.tantoKaigis || page !== this.props.metaTantoKaigi.page) {
        this.getData(this.state.index, page);
      }
      else {
        const realIndex = this.state.index % this.props.metaTantoKaigi.pageSize;
        const record = this.props.tantoKaigis[realIndex];
        this.props.loadTantoKaigiById(record.id);
        this.props.getRiyousyaInfo(record.riyousyaId);
      }
    }
    if (
      this.state.route === "check-info-document-list" &&
      this.state.tabActive === "6"
    ) {
      this.props.loadKeikaKirokuById(this.state.recordId);
      this.props.getRiyousyaInfo(this.state.riyousyaId);
    }
    if (
      this.state.route !== "check-info-document-list" &&
      this.state.tabActive === "6"
    ) {
      const page =
        Math.floor(this.state.index / this.props.metaKeikaKiroku.pageSize) + 1;
      if (
        !this.props.keikaKirokus ||
        page !== this.props.metaKeikaKiroku.page
      ) {
        this.getData(this.state.index, page);
      }
      else {
        const realIndex =
          this.state.index % this.props.metaKeikaKiroku.pageSize;
        const record = this.props.keikaKirokus[realIndex];
        this.props.loadKeikaKirokuById(record.id);
        this.props.getRiyousyaInfo(record.riyousyaId);
      }
    }
    window.scroll(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // init screen
    if (
      this.state.tabActive === "1" &&
      !this.props.dataPlan1 &&
      nextProps.dataPlan1
    ) {
      const realIndex = this.state.index % nextProps.metaPlan1.pageSize;
      const record = nextProps.dataPlan1[realIndex];
      this.props.getKyotakuPlan1(record.id);
      this.props.getRiyousyaInfo(record.riyousyaId);
    }
    if (
      this.state.tabActive === "2" &&
      !this.props.dataPlan2 &&
      nextProps.dataPlan2
    ) {
      const realIndex = this.state.index % nextProps.metaPlan2.pageSize;
      const record = nextProps.dataPlan2[realIndex];
      this.props.getKyotakuPlan2(record.id);
      this.props.getRiyousyaInfo(record.riyousyaId);
    }
    if (
      this.state.tabActive === "3" &&
      !this.props.dataDailyPlan &&
      nextProps.dataDailyPlan
    ) {
      const realIndex = this.state.index % nextProps.metaPlan3.pageSize;
      const record = nextProps.dataDailyPlan[realIndex];
      this.props.getSisetuDailyPlan(record.id);
      this.props.getRiyousyaInfo(record.riyousyaId);
    }
    if (
      this.state.tabActive === "4" &&
      !this.props.monitorings &&
      nextProps.monitorings
    ) {
      const realIndex = this.state.index % nextProps.metaPlan3.pageSize;
      const record = nextProps.monitorings[realIndex];
      this.props.getMonitoringById(record.id);
      this.props.getRiyousyaInfo(record.riyousyaId);
    }
    if (
      this.state.tabActive === "5" &&
      !this.props.tantoKaigis &&
      nextProps.tantoKaigis
    ) {
      const realIndex = this.state.index % nextProps.metaTantoKaigi.pageSize;
      const record = nextProps.tantoKaigis[realIndex];
      this.props.loadTantoKaigiById(record.id);
      this.props.getRiyousyaInfo(record.riyousyaId);
    }
    if (
      this.state.tabActive === "6" &&
      !this.props.keikaKirokus &&
      nextProps.keikaKirokus
    ) {
      const realIndex = this.state.index % nextProps.metaKeikaKiroku.pageSize;
      const record = nextProps.keikaKirokus[realIndex];
      this.props.loadKeikaKirokuById(record.id);
      this.props.getRiyousyaInfo(record.riyousyaId);
    }

    // change page
    if (
      this.state.tabActive === "1" &&
      this.props.metaPlan1.page !== nextProps.metaPlan1.page
    ) {
      this.goToIndex(
        this.state.index,
        nextProps.metaPlan1.pageSize,
        nextProps.dataPlan1
      );
    }
    if (
      this.state.tabActive === "2" &&
      this.props.metaPlan2.page !== nextProps.metaPlan2.page
    ) {
      this.goToIndex(
        this.state.index,
        nextProps.metaPlan2.pageSize,
        nextProps.dataPlan2
      );
    }
    if (
      this.state.tabActive === "3" &&
      this.props.metaPlan3.page !== nextProps.metaPlan3.page
    ) {
      this.goToIndex(
        this.state.index,
        nextProps.metaPlan3.pageSize,
        nextProps.dataDailyPlan
      );
    }
    if (
      this.state.tabActive === "4" &&
      this.props.metaMonitoring.page !== nextProps.metaMonitoring.page
    ) {
      this.goToIndex(
        this.state.index,
        nextProps.metaMonitoring.pageSize,
        nextProps.monitorings
      );
    }
    if (
      this.state.tabActive === "5" &&
      this.props.metaTantoKaigi.page !== nextProps.metaTantoKaigi.page
    ) {
      this.goToIndex(
        this.state.index,
        nextProps.metaTantoKaigi.pageSize,
        nextProps.tantoKaigis
      );
    }
    if (
      this.state.tabActive === "6" &&
      this.props.metaKeikaKiroku.page !== nextProps.metaKeikaKiroku.page
    ) {
      this.goToIndex(
        this.state.index,
        nextProps.metaKeikaKiroku.pageSize,
        nextProps.keikaKirokus
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.index &&
      Number(this.getIndex()) !== Number(prevProps.match.params.index)
    ) {
      this.canLoad = true;
    }
    if (this.canLoad) {
      this.canLoad = false;
      this.index = Number(this.getIndex());
      this.setState({
        index: Number(this.getIndex()),
      });
      if (this.state.tabActive === "1" && this.props.dataPlan1)
        this.props.getKyotakuPlan1(this.props.dataPlan1[this.index].id);
      if (this.state.tabActive === "2" && this.props.dataPlan2)
        this.props.getKyotakuPlan2(this.props.dataPlan2[this.index].id);
      if (this.state.tabActive === "3" && this.props.dataDailyPlan)
        this.props.getSisetuDailyPlan(this.props.dataDailyPlan[this.index].id);
      if (this.state.tabActive === "4" && this.props.monitorings)
        this.props.getMonitoringById(this.props.monitorings[this.index].id);
      if (this.state.tabActive === "5" && this.props.tantoKaigis)
        this.props.loadTantoKaigiById(this.props.tantoKaigis[this.index].id);
      if (this.state.tabActive === "6" && this.props.keikaKirokus)
        this.props.loadKeikaKirokuById(this.props.keikaKirokus[this.index].id);
    }
  }

  async goFirst() {
    this.canLoad = true;
    if (this.state.tabActive === "1" && this.props.dataPlan1) {
      if (this.props.metaPlan1.page !== 1) {
        this.getData(0, 1);
      }
      else {
        this.goToIndex(0, this.props.metaPlan1.pageSize, this.props.dataPlan1);
      }
    }
    if (this.state.tabActive === "2" && this.props.dataPlan2) {
      if (this.props.metaPlan2.page !== 1) {
        this.getData(0, 1);
      }
      else {
        this.goToIndex(0, this.props.metaPlan2.pageSize, this.props.dataPlan2);
      }
    }
    if (this.state.tabActive === "3" && this.props.dataDailyPlan) {
      if (this.props.metaPlan3.page !== 1) {
        this.getData(0, 1);
      }
      else {
        this.goToIndex(
          0,
          this.props.metaPlan3.pageSize,
          this.props.dataDailyPlan
        );
      }
    }
    if (this.state.tabActive === "4" && this.props.monitorings) {
      if (this.props.metaMonitoring.page !== 1) {
        this.getData(0, 1);
      }
      else {
        this.goToIndex(
          0,
          this.props.metaMonitoring.pageSize,
          this.props.monitorings
        );
      }
    }
    if (this.state.tabActive === "5" && this.props.tantoKaigis) {
      if (this.props.metaTantoKaigi.page !== 1) {
        this.getData(0, 1);
      }
      else {
        this.goToIndex(
          0,
          this.props.metaTantoKaigi.pageSize,
          this.props.tantoKaigis
        );
      }
    }
    if (this.state.tabActive === "6" && this.props.keikaKirokus) {
      if (this.props.metaKeikaKiroku.page !== 1) {
        this.getData(0, 1);
      }
      else {
        this.goToIndex(
          0,
          this.props.metaKeikaKiroku.pageSize,
          this.props.keikaKirokus
        );
      }
    }
  }

  goPrev() {
    this.canLoad = true;
    if (this.state.tabActive === "1" && this.props.dataPlan1) {
      const prev = this.state.index - 1;
      const page = Math.floor(prev / this.props.metaPlan1.pageSize) + 1;
      if (page !== this.props.metaPlan1.page) {
        this.getData(prev, page);
      }
      else {
        this.goToIndex(
          prev,
          this.props.metaPlan1.pageSize,
          this.props.dataPlan1
        );
      }
    }
    if (this.state.tabActive === "2" && this.props.dataPlan2) {
      const prev = this.state.index - 1;
      const page = Math.floor(prev / this.props.metaPlan2.pageSize) + 1;
      if (page !== this.props.metaPlan2.page) {
        this.getData(prev, page);
      }
      else {
        this.goToIndex(
          prev,
          this.props.metaPlan2.pageSize,
          this.props.dataPlan2
        );
      }
    }
    if (this.state.tabActive === "3" && this.props.dataDailyPlan) {
      const prev = this.state.index - 1;
      const page = Math.floor(prev / this.props.metaPlan2.pageSize) + 1;
      if (page !== this.props.metaPlan2.page) {
        this.getData(prev, page);
      }
      else {
        this.goToIndex(
          prev,
          this.props.metaPlan3.pageSize,
          this.props.dataDailyPlan
        );
      }
    }
    if (this.state.tabActive === "4" && this.props.monitorings) {
      const prev = this.state.index - 1;
      const page = Math.floor(prev / this.props.metaMonitoring.pageSize) + 1;
      if (page !== this.props.metaMonitoring.page) {
        this.getData(prev, page);
      }
      else {
        this.goToIndex(
          prev,
          this.props.metaMonitoring.pageSize,
          this.props.monitorings
        );
      }
    }
    if (this.state.tabActive === "5" && this.props.tantoKaigis) {
      const prev = this.state.index - 1;
      const page = Math.floor(prev / this.props.metaTantoKaigi.pageSize) + 1;
      if (page !== this.props.metaTantoKaigi.page) {
        this.getData(prev, page);
      }
      else {
        this.goToIndex(
          prev,
          this.props.metaTantoKaigi.pageSize,
          this.props.tantoKaigis
        );
      }
    }
    if (this.state.tabActive === "6" && this.props.keikaKirokus) {
      const prev = this.state.index - 1;
      const page = Math.floor(prev / this.props.metaKeikaKiroku.pageSize) + 1;
      if (page !== this.props.metaKeikaKiroku.page) {
        this.getData(prev, page);
      }
      else {
        this.goToIndex(
          prev,
          this.props.metaKeikaKiroku.pageSize,
          this.props.keikaKirokus
        );
      }
    }
  }

  goNext() {
    this.canLoad = true;
    if (this.state.tabActive === "1" && this.props.dataPlan1) {
      const next = this.state.index + 1;
      const page = Math.floor(next / this.props.metaPlan1.pageSize) + 1;
      if (page !== this.props.metaPlan1.page) {
        this.getData(next, page);
      }
      else {
        this.goToIndex(
          next,
          this.props.metaPlan1.pageSize,
          this.props.dataPlan1
        );
      }
    }
    if (this.state.tabActive === "2" && this.props.dataPlan2) {
      const next = this.state.index + 1;
      const page = Math.floor(next / this.props.metaPlan2.pageSize) + 1;
      if (page !== this.props.metaPlan2.page) {
        this.getData(next, page);
      }
      else {
        this.goToIndex(
          next,
          this.props.metaPlan2.pageSize,
          this.props.dataPlan2
        );
      }
    }
    if (this.state.tabActive === "3" && this.props.dataDailyPlan) {
      const next = this.state.index + 1;
      const page = Math.floor(next / this.props.metaPlan3.pageSize) + 1;
      if (page !== this.props.metaPlan3.page) {
        this.getData(next, page);
      }
      else {
        this.goToIndex(
          next,
          this.props.metaPlan3.pageSize,
          this.props.dataDailyPlan
        );
      }
    }
    if (this.state.tabActive === "4" && this.props.monitorings) {
      const next = this.state.index + 1;
      const page = Math.floor(next / this.props.metaMonitoring.pageSize) + 1;
      if (page !== this.props.metaMonitoring.page) {
        this.getData(next, page);
      }
      else {
        this.goToIndex(
          next,
          this.props.metaMonitoring.pageSize,
          this.props.monitorings
        );
      }
    }
    if (this.state.tabActive === "5" && this.props.tantoKaigis) {
      const next = this.state.index + 1;
      const page = Math.floor(next / this.props.metaTantoKaigi.pageSize) + 1;
      if (page !== this.props.metaTantoKaigi.page) {
        this.getData(next, page);
      }
      else {
        this.goToIndex(
          next,
          this.props.metaTantoKaigi.pageSize,
          this.props.tantoKaigis
        );
      }
    }
    if (this.state.tabActive === "6" && this.props.keikaKirokus) {
      const next = this.state.index + 1;
      const page = Math.floor(next / this.props.metaKeikaKiroku.pageSize) + 1;
      if (page !== this.props.metaKeikaKiroku.page) {
        this.getData(next, page);
      }
      else {
        this.goToIndex(
          next,
          this.props.metaKeikaKiroku.pageSize,
          this.props.keikaKirokus
        );
      }
    }
  }

  async goLast() {
    this.canLoad = true;
    if (this.state.tabActive === "1" && this.props.dataPlan1) {
      const last = this.props.metaPlan1.total - 1;
      const page = Math.floor(last / this.props.metaPlan1.pageSize) + 1;
      if (page !== this.props.metaPlan1.page) {
        this.getData(last, page);
      }
      else {
        this.goToIndex(
          last,
          this.props.metaPlan1.pageSize,
          this.props.dataPlan1
        );
      }
    }
    if (this.state.tabActive === "2" && this.props.dataPlan2) {
      const last = this.props.metaPlan2.total - 1;
      const page =
        Math.floor(this.props.metaPlan2.total / this.props.metaPlan2.pageSize) +
        1;
      if (page !== this.props.metaPlan2.page) {
        this.getData(last, page);
      }
      else {
        this.goToIndex(
          last,
          this.props.metaPlan2.pageSize,
          this.props.dataPlan2
        );
      }
    }
    if (this.state.tabActive === "3" && this.props.dataDailyPlan) {
      const last = this.props.metaPlan3.total - 1;
      const page = Math.floor(last / this.props.metaPlan3.pageSize) + 1;
      if (page !== this.props.metaPlan3.page) {
        this.getData(last, page);
      }
      else {
        this.goToIndex(
          last,
          this.props.metaPlan3.pageSize,
          this.props.dataDailyPlan
        );
      }
    }
    if (this.state.tabActive === "4" && this.props.monitorings) {
      const last = this.props.metaMonitoring.total - 1;
      const page = Math.floor(last / this.props.metaMonitoring.pageSize) + 1;
      if (page !== this.props.metaMonitoring.page) {
        this.getData(last, page);
      }
      else {
        this.goToIndex(
          last,
          this.props.metaMonitoring.pageSize,
          this.props.monitorings
        );
      }
    }
    if (this.state.tabActive === "5" && this.props.tantoKaigis) {
      const last = this.props.metaTantoKaigi.total - 1;
      const page = Math.floor(last / this.props.metaTantoKaigi.pageSize) + 1;
      if (page !== this.props.metaTantoKaigi.page) {
        this.getData(last, page);
      }
      else {
        this.goToIndex(
          last,
          this.props.metaTantoKaigi.pageSize,
          this.props.tantoKaigis
        );
      }
    }
    if (this.state.tabActive === "6" && this.props.keikaKirokus) {
      const last = this.props.metaKeikaKiroku.total - 1;
      const page = Math.floor(last / this.props.metaKeikaKiroku.pageSize) + 1;
      if (page !== this.props.metaKeikaKiroku.page) {
        this.getData(last, page);
      }
      else {
        this.goToIndex(
          last,
          this.props.metaKeikaKiroku.pageSize,
          this.props.keikaKirokus
        );
      }
    }
  }

  async getData(index, page) {
    if (index !== this.state.index) {
      await this.setState({
        index,
      });
    }
    const showReportList = JSON.parse(localStorage.getItem(dataKey));
    if (this.state.tabActive === "1") {
      if (this.state.riyousyaId) {
        this.props.loadKyotakuPlan1(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(showReportList.sort1),
            page,
          },
          () => { }
        );
      }
      else {
        this.props.loadKyotakuPlan1(
          {
            inUsed: Number(showReportList.inUsed),
            searchName: showReportList.searchName,
            sort: convertSortDataToParams(showReportList.sort1),
            page,
          },
          () => { }
        );
      }
    }
    if (this.state.tabActive === "2") {
      if (this.state.riyousyaId) {
        this.props.loadKyotakuPlan2(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(showReportList.sort2),
            page,
          },
          () => { }
        );
      }
      else {
        this.props.loadKyotakuPlan2(
          {
            inUsed: Number(showReportList.inUsed),
            searchName: showReportList.searchName,
            sort: convertSortDataToParams(showReportList.sort2),
            page,
          },
          () => { }
        );
      }
    }
    if (this.state.tabActive === "3") {
      if (this.state.riyousyaId) {
        this.props.loadSisetuDailyPlan(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(showReportList.sort3),
            page,
          },
          () => { }
        );
      }
      else {
        this.props.loadSisetuDailyPlan(
          {
            inUsed: Number(showReportList.inUsed),
            searchName: showReportList.searchName,
            sort: convertSortDataToParams(showReportList.sort3),
            page,
          },
          () => { }
        );
      }
    }
    if (this.state.tabActive === "4") {
      if (this.state.riyousyaId) {
        this.props.loadMonitoring(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(showReportList.sort4),
            page,
          },
          () => { }
        );
      }
      else {
        this.props.loadMonitoring(
          {
            inUsed: Number(showReportList.inUsed),
            searchName: showReportList.searchName,
            sort: convertSortDataToParams(showReportList.sort4),
            page,
          },
          () => { }
        );
      }
    }
    if (this.state.tabActive === "5") {
      if (this.state.riyousyaId) {
        this.props.loadTantoKaigi(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(showReportList.sort5),
            page,
          },
          () => { }
        );
      }
      else {
        this.props.loadTantoKaigi(
          {
            inUsed: Number(showReportList.inUsed),
            searchName: showReportList.searchName,
            sort: convertSortDataToParams(showReportList.sort5),
            page,
          },
          () => { }
        );
      }
    }
    if (this.state.tabActive === "6") {
      if (this.state.riyousyaId) {
        this.props.loadKeikaKiroku(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(showReportList.sort6),
            page,
          },
          () => { }
        );
      }
      else {
        this.props.loadKeikaKiroku(
          {
            inUsed: Number(showReportList.inUsed),
            searchName: showReportList.searchName,
            sort: convertSortDataToParams(showReportList.sort6),
            page,
          },
          () => { }
        );
      }
    }
  }

  goToIndex(index, pageSize, listData) {
    const realIndex = index % pageSize;
    const record = listData[realIndex];
    if (index !== this.state.index) {
      this.setState({
        index,
      });
    }
    if (this.state.tabActive === "1") this.props.getKyotakuPlan1(record.id);
    if (this.state.tabActive === "2") this.props.getKyotakuPlan2(record.id);
    if (this.state.tabActive === "3") this.props.getSisetuDailyPlan(record.id);
    if (this.state.tabActive === "4") this.props.getMonitoringById(record.id);
    if (this.state.tabActive === "5") this.props.loadTantoKaigiById(record.id);
    if (this.state.tabActive === "6") this.props.loadKeikaKirokuById(record.id);
    this.props.getRiyousyaInfo(record.riyousyaId);
    if (this.state.riyousyaId) {
      this.props.history.push(
        "/info-document-list-riyousya/" + this.state.riyousyaId + "/" + index
      );
    }
    else {
      this.props.history.push("/info-document-list/" + index);
    }
  }

  render() {
    const {
      kyotakuPlan1,
      kyotakuPlan2,
      sisetuDailyPlan,
      monitoring,
      keikaKiroku,
      tantoKaigi,
    } = this.props;
    const { tabActive } = this.state;
    let view = null;

    if (tabActive === "1") {
      view = <ViewPlan1 data={kyotakuPlan1} />;
    }
    if (tabActive === "2") {
      view = <ViewPlan2 detail={kyotakuPlan2} />;
    }
    if (tabActive === "3") {
      view = <ViewDailyPlan sisetuDailyPlan={sisetuDailyPlan} />;
    }
    if (tabActive === "4") {
      view = <ViewMonitoring data={monitoring} />;
    }
    if (tabActive === "5") {
      view = <ViewTantoKaigi data={tantoKaigi} />;
    }
    if (tabActive === "6") {
      view = <ViewKeikaKiroku data={keikaKiroku} />;
    }

    return (
      <CommonPageContainer
        id={"view-doc-list-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  {this.renderHeader()}
                  <Spin spinning={this.props.loading}>{view}</Spin>
                </div>
              );
            }
            return (
              <div>
                {this.renderMobileHeader()}
                <Spin spinning={this.props.loading}>{view}</Spin>
                {this.renderMobileFooter()}
              </div>
            );
          }}
        </MediaQuery>
      </CommonPageContainer>
    );
  }

  renderHeader() {
    const { riyousya } = this.props;
    const {
      metaPlan1,
      metaPlan2,
      metaPlan3,
      metaMonitoring,
      metaKeikaKiroku,
      metaTantoKaigi,
    } = this.props;
    const { tabActive } = this.state;

    let tabName = "【計画書１】";
    if (tabActive === "2") tabName = "【計画書２】";
    if (tabActive === "3") tabName = "【日課表】";
    if (tabActive === "4") tabName = "【モニタリング】";
    if (tabActive === "5") tabName = "【担当者会議】";
    if (tabActive === "6") tabName = "【施設経過記録】";

    let total = metaPlan1.total;
    if (tabActive === "2") total = metaPlan2.total;
    if (tabActive === "3") total = metaPlan3.total;
    if (tabActive === "4") total = metaMonitoring.total;
    if (tabActive === "5") total = metaTantoKaigi.total;
    if (tabActive === "6") total = metaKeikaKiroku.total;
    return (
      <Affix offsetTop={0}>
        <div className={"submenu"}>
          <SubHeaderV2
            TopLeft={
              <SubHeaderTitleV2
                title={"利用者　"}
                content={(riyousya ? riyousya.name : "") + tabName}
              />
            }
            TopRight={
              this.state.route === "check-info-document-list" ? (
                <div>
                  <Link
                    to={"/"}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("", "_parent", "");
                      window.close();
                    }}
                    style={{
                      color: "white",
                      textAlign: "center",
                      float: "right",
                      padding: "0px 8px",
                      marginLeft: "16px",
                    }}
                  >
                    <span style={{ display: "block", fontWeight: "bold" }}>
                      <CloseOutlined />
                    </span>
                    <span>{"閉じる"}</span>
                  </Link>
                </div>
              ) : (
                <div>
                  <Link
                    to={"/document-list"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.state.riyousyaId) {
                        this.props.history.push(
                          "/document-list/" + this.state.riyousyaId
                        );
                      }
                      else {
                        this.props.history.push("/document-list");
                      }
                    }}
                    style={{
                      color: "white",
                      textAlign: "center",
                      float: "right",
                      padding: "0px 8px",
                      marginLeft: "16px",
                    }}
                  >
                    <span style={{ display: "block", fontWeight: "bold" }}>
                      <UndoOutlined />
                    </span>
                    <span>{"戻る"}</span>
                  </Link>
                  <PlanNavigator
                    isFirst={this.state.index === 0}
                    isLast={this.state.index === total - 1}
                    goFirst={() => this.goFirst()}
                    goPrev={() => this.goPrev()}
                    goNext={() => this.goNext()}
                    goLast={() => this.goLast()}
                  />
                </div>
              )
            }
          />
        </div>
      </Affix>
    );
  }

  renderMobileHeader() {
    const { riyousya } = this.props;
    const { tabActive } = this.state;
    let tabName = "【計画書１】";
    if (tabActive === "2") tabName = "【計画書２】";
    if (tabActive === "3") tabName = "【日課表】";
    if (tabActive === "4") tabName = "【モニタリング】";
    if (tabActive === "5") tabName = "【担当者会議】";
    if (tabActive === "6") tabName = "【施設経過記録】";
    return (
      <Affix offsetTop={0}>
        <Row className={"submenu"}>
          <SubHeaderV2
            TopLeft={<SubHeaderTitleV2 title={tabName} />}
            IncBottom={
              <span>
                <span style={{ color: "white" }}>{"利用者　"}</span>
                <span style={{ color: "white", fontWeight: "bold" }}>
                  {riyousya ? riyousya.name : ""}
                </span>
              </span>
            }
          />
        </Row>
      </Affix>
    );
  }

  renderMobileFooter() {
    const {
      metaPlan1,
      metaPlan2,
      metaPlan3,
      metaMonitoring,
      metaKeikaKiroku,
      metaTantoKaigi,
    } = this.props;
    const { tabActive } = this.state;

    let total = metaPlan1.total;
    if (tabActive === "2") total = metaPlan2.total;
    if (tabActive === "3") total = metaPlan3.total;
    if (tabActive === "4") total = metaMonitoring.total;
    if (tabActive === "5") total = metaTantoKaigi.total;
    if (tabActive === "6") total = metaKeikaKiroku.total;
    return (
      <Affix offsetBottom={0}>
        <Row className={"submenu"}>
          <SubHeaderV2
            FooterMobile={
              this.state.route === "check-info-document-list" ? (
                <div>
                  <Link
                    to={"/"}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("", "_parent", "");
                      window.close();
                    }}
                    style={{
                      color: "white",
                      textAlign: "center",
                      float: "right",
                      padding: "0px 8px",
                      marginLeft: "16px",
                    }}
                  >
                    <span style={{ display: "block", fontWeight: "bold" }}>
                      <CloseOutlined />
                    </span>
                    <span>{"閉じる"}</span>
                  </Link>
                </div>
              ) : (
                <div>
                  <Link
                    to={"/document-list"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.state.riyousyaId) {
                        this.props.history.push(
                          "/document-list/" + this.state.riyousyaId
                        );
                      }
                      else {
                        this.props.history.push("/document-list");
                      }
                    }}
                    style={{
                      color: "white",
                      textAlign: "center",
                      float: "right",
                      padding: "0px 8px",
                      marginLeft: "16px",
                    }}
                  >
                    <span style={{ display: "block", fontWeight: "bold" }}>
                      <UndoOutlined />
                    </span>
                    <span>{"戻る"}</span>
                  </Link>
                  <PlanNavigator
                    isFirst={this.state.index === 0}
                    isLast={this.state.index === total - 1}
                    goFirst={() => this.goFirst()}
                    goPrev={() => this.goPrev()}
                    goNext={() => this.goNext()}
                    goLast={() => this.goLast()}
                  />
                </div>
              )
            }
          />
        </Row>
      </Affix>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    riyousya: state.riyousya.riyousya,
    kyotakuPlan1: state.kyotakuPlan1.kyotakuPlan1,
    kyotakuPlan2: state.kyotakuPlan2.kyotakuPlan2,
    sisetuDailyPlan: state.sisetuDailyPlan.sisetuDailyPlan,
    loading: state.documentList.loading,
    dataPlan1: state.documentList.dataPlan1,
    dataPlan2: state.documentList.dataPlan2,
    dataDailyPlan: state.documentList.dataDailyPlan,
    metaPlan1: state.documentList.metaPlan1,
    metaPlan2: state.documentList.metaPlan2,
    metaPlan3: state.documentList.metaDailyPlan,
    // needReload: state.documentList.needReload
    metaMonitoring: state.documentList.metaMonitoring,
    monitorings: state.documentList.monitorings,
    monitoring: state.monitoring.monitoring,

    keikaKiroku: state.keikaKiroku.keikaKiroku,
    keikaKirokus: state.documentList.keikaKirokus,
    metaKeikaKiroku: state.documentList.metaKeikaKiroku,

    tantoKaigi: state.tantoKaigi.tantoKaigi,
    tantoKaigis: state.documentList.tantoKaigis,
    metaTantoKaigi: state.documentList.metaTantoKaigi,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadKyotakuPlan1: (data, callback) =>
      dispatch(loadKyotakuPlan1(data, callback)),
    loadKyotakuPlan2: (data, callback) =>
      dispatch(loadKyotakuPlan2(data, callback)),
    loadSisetuDailyPlan: (data, callback) =>
      dispatch(loadSisetuDailyPlan(data, callback)),
    getKyotakuPlan1: (id) => dispatch(getKyotakuPlan1(id)),
    getKyotakuPlan2: (id) => dispatch(getKyotakuPlan2(id)),
    getSisetuDailyPlan: (id, type) => dispatch(getSisetuDailyPlan(id, type)),
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
    getMonitoringById: (id) => dispatch(getMonitoringById(id)),
    loadMonitoring: (data, callback) =>
      dispatch(loadMonitoring(data, callback)),
    loadKeikaKiroku: (data, callback) =>
      dispatch(loadKeikaKiroku(data, callback)),
    loadKeikaKirokuById: (id) => dispatch(loadKeikaKirokuById(id)),
    loadTantoKaigi: (data, callback) =>
      dispatch(loadTantoKaigi(data, callback)),
    loadTantoKaigiById: (id) => dispatch(loadTantoKaigiById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoDocumentList);
