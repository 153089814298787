import React from "react";
import { UndoOutlined } from '@ant-design/icons';
import { Tag } from "antd";
import { Link } from "react-router-dom";

import "./TabRecords.css";
import { ConstSet } from "../configs/constset";

const CheckableTag = Tag.CheckableTag;
const tagsFromServer = [
  "利用者",
  "バイタル",
  "食事摂取",
  "水分摂取",
  "排泄",
  "入浴",
  "服薬",
  "提供記録",
  "特記",
  "画像等",
];

const tagsUserFromServer = [
  "バイタル",
  "食事摂取",
  "水分摂取",
  "排泄",
  "入浴",
  "服薬",
  "提供記録",
  "特記",
  "画像等",
  "利用者",
];

export default class TabRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: [this.props.tabActive],
      tabData:
        this.props.type && this.props.type === ConstSet.SUBMENU_KIROKU_RIYOUSYA
          ? tagsUserFromServer
          : tagsFromServer,
    };
  }

  handleChange(tag, checked) {
    if (checked) {
      const { selectedTags } = this.state;
      const nextSelectedTags = checked
        ? [tag]
        : selectedTags.filter((t) => t !== tag);

      this.setState({ selectedTags: nextSelectedTags });

      localStorage.setItem("tabActive", tag);
      this.props.handleTabChange(tag);
    }
  }

  render() {
    const { selectedTags, tabData } = this.state;

    const { modoru } = this.props;

    return (
      <div className={"tabrecords-content"}>
        {tabData.map((tag, index) => (
          <CheckableTag
            key={tag}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={(checked) => this.handleChange(tag, checked)}
          >
            {tag === "利用者" && index > 0 ? "集計" : tag}
          </CheckableTag>
        ))}

        {modoru ? (
          <Link to={modoru} style={{ float: "right", color: "#fff" }}>
            <span>
              <UndoOutlined />
            </span>
            <span> 戻る</span>
          </Link>
        ) : null}
      </div>
    );
  }
}
