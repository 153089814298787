import { openNotificationWithIcon } from "../../../common/function_common/functionCommon";

import {
  GET_ID_DOCUMENT_LIST,
  GET_ALL_DOCUMENT_LIST_SUCCESS,
  LOAD_SPIN_REQUEST,
} from "../../actions/lifeList/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */

export default function LifeListReducers(
  state = {
    metaDocs: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaPlan1: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaPlan2: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaDailyPlan: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaMonitoring: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaTantoKaigi: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    metaKeikaKiroku: {
      page: 1,
      pageSize: 100,
      total: 0,
    },
    id: null,
    loading: false,
  },
  action
) {
  switch (action.type) {
    case LOAD_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        dataDocs: action.LifeLists,
        metaDocs: {
          page: action.meta.page,
          pageSize: action.meta.pageSize,
          total: action.meta.total,
        },
        loading: false,
        needReload: null,
      };

    case GET_ID_DOCUMENT_LIST:
      return {
        ...state,
        id: action.id,
      };

    default:
      return state;
  }
}
