import {
  LOAD_RIYOU_KAIGOHOKEN_LIST_SUCCESS,
  LOAD_RIYOU_KAIGOHOKEN_LIST_ERROR,
  GET_DATE_RIYOU_KAIGOHOKEN,
  CHECK_HOKEN_NO_SUCCESS,
  CHECK_HOKEN_NO_ERROR,
} from "../../actions/riyouKaigohoken/actionName";
const initalState = {
  startDate: null,
};
export default function kyotakuPlan1(state = initalState, action) {
  switch (action.type) {
    case LOAD_RIYOU_KAIGOHOKEN_LIST_SUCCESS:
      return {
        ...state,
        riyouKaigohokens: action.data,
        notifyDisplay: 0,
        notifyContent: null,
      };
    case LOAD_RIYOU_KAIGOHOKEN_LIST_ERROR:
      return {
        ...state,
        riyouKaigohokens: action.data,
        notifyDisplay: 2,
        notifyContent: action.error.message,
      };
    case GET_DATE_RIYOU_KAIGOHOKEN:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case CHECK_HOKEN_NO_SUCCESS:
      return {
        ...state,
        duplicationData: action?.data,
        duplicationRiyousya: action?.data?.riyousya,
      };
    case CHECK_HOKEN_NO_ERROR:
      return {
        ...state,
        duplication: null,
      };
    default:
      return state;
  }
}
