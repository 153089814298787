import React, { Component } from "react";
import { connect } from "react-redux";
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Col, Row, Modal } from "antd";
import {
  deleteRowsKyotakuPlan2,
  upRowKyotakuPlan2,
  downRowKyotakuPlan2,
  changeEditMobileMode,
  changeValuePlan2,
  changeEditMode,
} from "../../../actions/kyotakuPlan2Actions/actionCreators";
import MediaQuery from "react-responsive";
import LineBreakText from "../../../common/component/LineBreakText";
// import "./plan2.css";

class RowContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      confirmModalVisible: false,
      sortNum: null,
    };
  }

  componentDidMount() {
    this.props.changeEditMobileMode(false);
  }

  showModal = () => {
    this.setState({
      confirmModalVisible: true,
    });
  };

  handleOk = (e) => {
    if (!this.props.valuesChange) {
      this.props.changeValuePlan2(true);
    }
    this.setState({
      confirmModalVisible: false,
    });
    this.props.deleteRowsKyotakuPlan2(this.state.sortNum);
    this.props.handleExpand(null, null);
  };

  handleCancel = (e) => {
    this.setState({
      confirmModalVisible: false,
    });
  };

  handleOnclick = (e) => {
    if (e.target.type !== "button") {
      this.props.handleExpand(!this.state.expand, this.props.info);
    }
  };

  handleRemoveRow = (sortNum, e) => {
    e.preventDefault();
    this.setState({
      sortNum: sortNum,
    });
    this.showModal();
  };

  getLongMokuhyou(item) {
    let text = item.longMokuhyou || "";
    if (item.longKikan && item.longKikan.trim().length > 0) {
      if (item.longMokuhyou) {
        text += `\n(${item.longKikan})`;
      } else {
        text += `${item.longKikan}`;
      }
    }
    return text;
  }

  getShortMokuhyou(item) {
    let text = item.shortMokuhyou || "";
    if (item.shortKikan && item.shortKikan.trim().length > 0) {
      if (item.shortMokuhyou) {
        text += `\n(${item.shortKikan})`;
      } else {
        text += `${item.shortKikan}`;
      }
    }
    return text;
  }

  render() {
    const { info, indexRow, expandedRowKeys, kyotakuPlan2Rows, editDetail } =
      this.props;
    const { confirmModalVisible } = this.state;

    return (
      (<div
        className="pointer p-1 management-plan2"
        style={
          expandedRowKeys === info.sortNum ? { backgroundColor: "#dbe6e6" } : { backgroundColor: "#fff" }
        }
        onClick={expandedRowKeys === info.sortNum ? null : this.handleOnclick}
      >
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) =>
            matches ? (
              <Row className="data-table-plan2">
                <Col sx={1} ms={1} md={1} className="row-plan-2">
                  {indexRow + 1}
                </Col>
                <Col sx={23} ms={23} md={11} className="row-plan-2">
                  <LineBreakText text={info.seikatuKoi} />
                </Col>
                <Col sx={23} ms={23} md={6} className="row-plan-2">
                  <LineBreakText text={this.getLongMokuhyou(info)} />
                </Col>
                <Col sx={20} ms={23} md={6} className="row-plan-2">
                  <LineBreakText text={this.getShortMokuhyou(info)} />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col sx={1} ms={1} md={1}>
                  {indexRow + 1}
                </Col>
                <Col sx={23} ms={23} md={11} className="row-plan-2">
                  <span className="text-bold pr-1">
                    生活全般の解決すべき課題
                  </span>
                  <LineBreakText text={info.seikatuKoi} />
                </Col>
                <Col sx={23} ms={23} md={6} className="row-plan-2">
                  <span className="text-bold pr-1">長期目標</span>
                  <LineBreakText text={this.getLongMokuhyou(info)} />
                </Col>
                <Col sx={20} ms={23} md={6} className="row-plan-2">
                  <span className="text-bold pr-1">短期目標</span>
                  <LineBreakText text={this.getShortMokuhyou(info)} />
                </Col>
              </Row>
            )
          }
        </MediaQuery>
        {expandedRowKeys === info.sortNum ? (
          <div className="mt-1 management-footer-table">
            <Row>
              <Col sx={1} ms={1} md={1}></Col>
              <Col sx={23} ms={23} md={11}>
                <span>
                  <Button
                    className="btn-tab-delete white"
                    onClick={(e) => this.handleRemoveRow(info.sortNum, e)}
                  >
                    削除
                  </Button>
                </span>
              </Col>
              <Col sx={23} ms={23} md={6}></Col>
              <MediaQuery maxDeviceWidth={767}>
                <Col ms={2} md={4}>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.props.changeEditMode(true);
                      this.props.changeEditMobileMode(true);
                    }}
                  >
                    修正
                  </Button>
                </Col>
              </MediaQuery>
              <Col sx={23} ms={23} md={6}>
                <span>
                  <MediaQuery query="(min-device-width: 768px)">
                    {(matches) => {
                      if (matches) {
                        return (
                          <div>
                            <Button
                              className="btn-tab-delete white mr-1"
                              disabled={info.sortNum === 1}
                              onClick={() =>
                                this.props.upRowKyotakuPlan2(info.sortNum)
                              }
                            >
                              上へ
                              <CaretUpOutlined />
                            </Button>
                            <Button
                              className="btn-tab-delete white mr-1"
                              onClick={() =>
                                this.props.downRowKyotakuPlan2(info.sortNum)
                              }
                              disabled={
                                info.sortNum === kyotakuPlan2Rows.length
                              }
                            >
                              下へ
                              <CaretDownOutlined />
                            </Button>
                          </div>
                        );
                      }
                      return (
                        <div>
                          <span style={{ marginRight: "10px" }}>順序</span>
                          <Button
                            className="btn-tab-delete white mr-1"
                            disabled={info.sortNum === 1}
                            onClick={() =>
                              this.props.upRowKyotakuPlan2(info.sortNum)
                            }
                          >
                            <CaretUpOutlined />
                          </Button>
                          <Button
                            className="btn-tab-delete white mr-1"
                            onClick={() =>
                              this.props.downRowKyotakuPlan2(info.sortNum)
                            }
                            disabled={info.sortNum === kyotakuPlan2Rows.length}
                          >
                            <CaretDownOutlined />
                          </Button>
                        </div>
                      );
                    }}
                  </MediaQuery>
                </span>
              </Col>
            </Row>
          </div>
        ) : (
          <div />
        )}
        <Modal
          open={confirmModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>この行を削除しますか？</p>
        </Modal>
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    expandedRowKeys: state.kyotakuPlan2.expandedRowKeys,
    kyotakuPlan2Rows: state.kyotakuPlan2.kyotakuPlan2Rows,
    valuesChange: state.kyotakuPlan2.valuesChange,
    editDetail: state.kyotakuPlan2.editDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRowsKyotakuPlan2: (sortNum) =>
      dispatch(deleteRowsKyotakuPlan2(sortNum)),
    upRowKyotakuPlan2: (sortNum) => dispatch(upRowKyotakuPlan2(sortNum)),
    downRowKyotakuPlan2: (sortNum) => dispatch(downRowKyotakuPlan2(sortNum)),
    changeEditMobileMode: (editDetail) =>
      dispatch(changeEditMobileMode(editDetail)),
    changeValuePlan2: (type) => dispatch(changeValuePlan2(type)),
    changeEditMode: (mode) => dispatch(changeEditMode(mode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RowContent);
