export const LOAD_REPORT_ADL_SUCCESS = "LOAD_REPORT_ADL_SUCCESS";
export const LOAD_REPORT_ADL_ERROR = "LOAD_REPORT_ADL_ERROR";

export const DELETE_REPORT_ADL_SUCCESS = "DELETE_REPORT_ADL_SUCCESS";
export const DELETE_REPORT_ADL_ERROR = "DELETE_REPORT_ADL_ERROR";

export const LOAD_REPORT_ADL_BY_DATE_SUCCESS =
  "LOAD_REPORT_ADL_BY_DATE_SUCCESS";
export const LOAD_REPORT_ADL_BY_DATE_ERROR = "LOAD_REPORT_ADL_BY_DATE_ERROR";

export const LOAD_REPORT_ADL_BY_ID_SUCCESS = "LOAD_REPORT_ADL_BY_ID_SUCCESS";
export const LOAD_REPORT_ADL_BY_ID_ERROR = "LOAD_REPORT_ADL_BY_ID_ERROR";

export const CREATE_REPORT_ADL_SUCCESS = "CREATE_REPORT_ADL_SUCCESS";
export const CREATE_REPORT_ADL_ERROR = "CREATE_REPORT_ADL_ERROR";

export const UPDATE_REPORT_ADL_SUCCESS = "UPDATE_REPORT_ADL_SUCCESS";
export const UPDATE_REPORT_ADL_ERROR = "UPDATE_REPORT_ADL_ERROR";

export const RESET_CURRENT_REPORT_ADL = "RESET_CURRENT_REPORT_ADL";

export const LOAD_REPORT_ADL_SPIN = "LOAD_REPORT_ADL_SPIN";

export const KEEP_VALUE_SEARCH = "KEEP_VALUE_SEARCH";

export const GET_LIST_IDS_REPORT_ADL = "GET_LIST_IDS_REPORT_ADL";

export const GET_SETTING_MASTER_SUCCESS = "GET_SETTING_MASTER_SUCCESS";
export const GET_ADL_MASTER_SUCCESS = "GET_ADL_MASTER_SUCCESS";
