import { CaretRightOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Collapse, Modal, notification } from "antd";
import React from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import ShokujiJokyouRireki from "./UI/ShokujiJokyouRireki";
import Syokujisen from "./UI/Syokujisen";

const { Panel } = Collapse;

const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 0,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

export default class RiyousyaShokuji extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const query = new URLSearchParams(this.props.location.search);

    this.state = {
      riyousyaId: match.params.riyousyaId,
      url: match.url,
      backLink: query.get("backLink"),
      page1No: 0,
      page2No: 0,
      isChange: false,
      visibleConfirm: false,
    };

    // bind place
  }

  /**
   *
   */
  componentDidMount() {
    const { riyousyaId } = this.state;
    this.props.getRiyousya(riyousyaId);

    const page = 1;
    this.props.getShokujisen(page, riyousyaId);
    this.props.getSyokujiJoukyoRirekipage(page, riyousyaId);

    // load yougo master setting
    this.props.ymShokujiPage();
  }

  /**
   *
   */
  componentDidUpdate(preProps) {
    const { riyousyaDetail, isMealUpdating, isMealMessage } = this.props;

    if (riyousyaDetail !== preProps.riyousyaDetail) {
      this.setState({
        recordUpdate: riyousyaDetail,
      });
    }

    if (isMealUpdating && isMealMessage.type) {
      this.openNotificationWithIcon(isMealMessage.type, isMealMessage.message);
      this.props.riyousyaMealReset();
    }
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message,
      placement: "topRight",
      duration: 1,
      prefixCls: "pljp-notification",
    });
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.state.isChange) {
      this.showGoBackConfirm();
    }
    else {
      this.props.riyousyaMealReset();
      this.props.history.goBack();
    }
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack = async () => {
    this.setState({
      visibleConfirm: false,
    });
    this.props.riyousyaMealReset();
    this.props.history.goBack();
  };

  handleDelSyokujisen = (id, index) => {
    this.setState({
      isChange: true,
    });
    this.props.handleDelSyokujisen(id, index);
  };

  handleDelShokuji = (id, index) => {
    this.setState({
      isChange: true,
    });
    this.props.handleDelShokuji(id, index);
  };

  updateSyokujisen = (formData) => {
    this.setState({
      isChange: true,
    });
    this.props.updateSyokujisen(formData);
  };

  updateShokuji = (formData) => {
    this.setState({
      isChange: true,
    });
    this.props.updateShokuji(formData);
  };

  handleModify = (e) => {
    const { mealAbove, delSyokujisenIds, mealBelow, delShokujiIds } =
      this.props;
    e.preventDefault();

    this.setState({
      isChange: false,
    });

    // update syokujisenId
    const tmpMealBelow = mealBelow;

    for (let i = 0; i < tmpMealBelow.length; i++) {
      if (!tmpMealBelow[i].syokujisenId && tmpMealBelow[i].syokujisenOri) {
        tmpMealBelow[i].syokujisenId = tmpMealBelow[i].syokujisenOri.id;
      }
      if (!tmpMealBelow[i].endDate) {
        tmpMealBelow[i].endDate = "0001-01-01";
      }
    }

    const params = {
      syokujisen: mealAbove,
      delSyokujisenIds,
      shokuji: tmpMealBelow,
      delShokujiIds,
    };

    this.props.handleSaveMeal(params);
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  onChangeSyokujiPag = (page) => {
    const { riyousyaId } = this.state;
    this.props.getShokujisen(page, riyousyaId);
  };

  onChangeShokujiPag = (page) => {
    const { riyousyaId } = this.state;
    this.props.getSyokujiJoukyoRirekipage(page, riyousyaId);
  };

  render() {
    const {
      sessionTimeout,
      riyousyaDetail,

      mealAbove,
      pageMealAbove,
      pageTotalMealAbove,

      mealBelow,
      pageMealBelow,
      pageTotalMealBelow,

      shushoku,
      fukushoku,
      shokujisenbunrui,
      shokujisenbikou,
      shokujiRirekiSituation,
      shokujiRirekiComent,
    } = this.props;

    const { riyousyaId } = this.state;
    return (
      (<CommonPageContainer
        id="riyousya-shokuji-page"
        sessionTimeout={sessionTimeout}
      >
        <div className={"submenu"}>
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={"利用者食事せん"}
                        iconType="user"
                      />
                    }
                    TopRight={
                      <div className="submenu-t-r">
                        <Link
                          to={"/"}
                          onClick={this.handleGoBack}
                          className="sub-top-right-item"
                        >
                          <span>
                            <UndoOutlined />
                          </span>
                          <span>戻る</span>
                        </Link>
                        <Link
                          to={"/"}
                          className="sub-top-right-item"
                          onClick={this.handleModify}
                        >
                          <span>
                            <SaveOutlined style={{ color: "red" }} />
                          </span>
                          <span>登録</span>
                        </Link>
                      </div>
                    }
                  />
                );
              }
              /**
                 * @TODO design UI for mobile
                 *
                 * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
                 */
              return null;

            }}
          </MediaQuery>
        </div>
        <div className="riyousya-shokuji-content">
          <div>
            <h3 style={{ marginTop: 16 }}>
              利用者名：
              {`${riyousyaDetail ? riyousyaDetail.name : ""}`}
            </h3>
          </div>

          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            defaultActiveKey={["1", "2"]}
          >
            <Panel header="食事せん" key="1" style={customPanelStyle}>
              <Syokujisen
                data={mealAbove}
                page={pageMealAbove}
                pageTotal={pageTotalMealAbove}
                handleDelSyokujisen={this.handleDelSyokujisen}
                updateSyokujisen={this.updateSyokujisen}
                riyousyaId={riyousyaId}
                shushoku={shushoku}
                fukushoku={fukushoku}
                shokujisenbunrui={shokujisenbunrui}
                shokujisenbikou={shokujisenbikou}
                yougoMasterUpdate={this.yougoMasterUpdate}
                onChangeSyokujiPag={this.onChangeSyokujiPag}
              />
            </Panel>
            <Panel header="食事履歴" key="2" style={customPanelStyle}>
              <ShokujiJokyouRireki
                data={mealBelow}
                page={pageMealBelow}
                pageTotal={pageTotalMealBelow}
                dataSyo={mealAbove}
                pageSyo={pageMealAbove}
                pageTotalSyo={pageTotalMealAbove}
                handleDelShokuji={this.handleDelShokuji}
                updateShokuji={this.updateShokuji}
                riyousyaId={riyousyaId}
                shokujiRirekiSituation={shokujiRirekiSituation}
                shokujiRirekiComent={shokujiRirekiComent}
                yougoMasterUpdate={this.yougoMasterUpdate}
                onChangeShokujiPag={this.onChangeShokujiPag}
                onChangeSyokujiPag={this.onChangeSyokujiPag}
              />
            </Panel>
          </Collapse>
        </div>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </CommonPageContainer>)
    );
  }
}
