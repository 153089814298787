export const LOAD_DUTY_REPORT_REQUEST = "LOAD_DUTY_REPORT_REQUEST";
export const LOAD_DUTY_REPORT_SUCCESS = "LOAD_DUTY_REPORT_SUCCESS";
export const CREATE_DUTY_REPORT_SUCCESS = "CREATE_DUTY_REPORT_SUCCESS";
export const CREATE_DUTY_REPORT_ERROR = "CREATE_DUTY_REPORT_ERROR";
export const EDIT_DUTY_REPORT_SUCCESS = "EDIT_DUTY_REPORT_SUCCESS";
export const EDIT_DUTY_REPORT_ERROR = "EDIT_DUTY_REPORT_ERROR";
export const RESET_STATUS = "RESET_STATUS";
export const SAVE_ERROR = "SAVE_ERROR";

export const STATISTICAL_OUT_IN = "STATISTICAL_OUT_IN";
export const STATISTICAL_OUT_IN_BYKIND = "STATISTICAL_OUT_IN_BYKIND";
export const PEOPLE_OUT_IN_BYKIND = "PEOPLE_OUT_IN_BYKIND";
