/* eslint-disable */
import dayjs from "dayjs";
import { OpenScreenLogApi } from "../../api/openScreenLog";
import { ReportCaseApi } from "../../api/reportCase";
import DataFilterApi from "../../api/reportFilter";
import { getValueLocalstorage } from "../../common/function_common/functionCommon";
import {
    CREATE_REPORT_CASE_SUCCESS,
    DELETE_REPORT_CASE_SUCCESS,
    GET_OPEN_SCREEN_LOG,
    GET_REPORT_CASE_BY_ID_SUCCESS,
    LOAD_FILTER_MASTER_SUCCESS,
    LOAD_MOSHIOKURI_SUCCESS,
    LOAD_REPORT_CASE_SUCCESS,
    SAVE_OPEN_SCREEN_LOG,
    SORT_RIYOUSYA_SUCCESS,
    UPDATE_KAKUNIN_COMMENT_SUCCESS,
    UPDATE_REPORT_CASE_SUCCESS
} from './actionName.js';

export function loadReportCasesStartup() {
    return (dispatch) => {
        let tantoId = getValueLocalstorage('tid');
        ReportCaseApi.loadReportCaseStartup(tantoId).then(
            (data) => dispatch({
                type: LOAD_MOSHIOKURI_SUCCESS,
                moshiokuri: data,
                isStartup: true,
                isRed: data && data.length > 0
            })
        )
    }
}

export function loadReportCases(params, searchData) {
    return (dispatch) => {
        params["bunrui"] = searchData.search.field11;
        params["room_bunrui"] = searchData.search.field2;
        params["sid"] = searchData.search.field3;
        params["kiroku_kubun"] = searchData.search.field4;
        params["tanto_id"] = searchData.search.field5 == null ? null : (searchData.search.field5 == 0 ? getValueLocalstorage('tid') : searchData.search.field5.toString());
        params["tanto_sikaku"] = searchData.search.field6;
        params["sonota_kubun"] = searchData.search.field7;
        params["caremana_only"] = searchData.search.field8;
        params["impf"] = searchData.search.field9 ? "1" : "";
        params["naiyou"] = searchData.search.field12;
        params["searchName"] = searchData.searchName;
        searchData["startDate"] = dayjs(params.startDate, "YYYY-MM-DD");
        searchData["endDate"] = dayjs(params.endDate, "YYYY-MM-DD");
        searchData["tyuif"] = params.tyuif === "1";
        searchData["nishif"] = params.nishif === "1";
        searchData["riyouf"] = params.riyouf === "1";

        ReportCaseApi.loadReportCase(params).then(
            (data) => dispatch({
                type: LOAD_REPORT_CASE_SUCCESS,
                reportCases: data,
                isStartup: false,
                isRed: false,
                searchData: searchData
            })
        )
    }
}

export function createReportCase(params) {
    return (dispatch) => {
        ReportCaseApi.createReportCase(params).then(
            (data) => {
                //callback();
                return dispatch({
                    type: CREATE_REPORT_CASE_SUCCESS,
                    createReportCaseRes: data
                })
            }
        )
    }
}

export function updateReportCase(params) {
    return (dispatch) => {
        ReportCaseApi.updateReportCase(params).then(
            (data) => {
                //callback();
                return dispatch({
                    type: UPDATE_REPORT_CASE_SUCCESS,
                    updateReportCaseRes: data
                })
            }
        )
    }
}

export function deleteReportCase(reportCaseId) {
    return (dispatch) => {
        ReportCaseApi.deleteReportCase(reportCaseId).then(
            (data) => {
                //callback();
                return dispatch({
                    type: DELETE_REPORT_CASE_SUCCESS,
                    deleteReportCaseRes: data
                })
            }
        )
    }
}

export function updateKakuninComment(data) {
    return (dispatch) => {
        ReportCaseApi.updateKakuninComment(data).then(
            (data) => {
                //callback();
                return dispatch({
                    type: UPDATE_KAKUNIN_COMMENT_SUCCESS,
                    updateKakuninCommentRes: data
                })
            }
        )
    }
}

export function getReportCaseById(reportCaseId) {
    return (dispatch) => {
        ReportCaseApi.getReportCaseById(reportCaseId).then(
            (data) => {
                return dispatch({
                    type: GET_REPORT_CASE_BY_ID_SUCCESS,
                    reportCase: data
                })
            }
        )
    }
}

export function loadFilterMasterSetting() {
    return (dispatch) => {
        DataFilterApi.dataFilterApi("").then(
            (data) => dispatch({
                type: LOAD_FILTER_MASTER_SUCCESS,
                filterMaster: data
            })
        )
    }
}

export function sortReportCaseData(searchData) {
    return (dispatch) => dispatch({
        type: SORT_RIYOUSYA_SUCCESS,
        searchData: searchData
    })
}

export function getOpenScreenLog(tantoId) {
    return (dispatch) => {
        OpenScreenLogApi.getOpenScreenLog(tantoId).then(
            (data) => dispatch({
                type: GET_OPEN_SCREEN_LOG,
                openScreenLog: data
            })
        )
    }
}

export function saveOpenScreenLog(tantoId) {
    return (dispatch) => {
        OpenScreenLogApi.saveOpenScreenLog(tantoId, "mousiokuri").then(
            (data) => dispatch({
                type: SAVE_OPEN_SCREEN_LOG,
                openScreenLog: data
            })
        )
    }
}
