import React, { useState } from "react";
import { Modal, Button, Table, Checkbox } from "antd";
function ColorModal(props) {
  const [idChecked, setIdChecked] = useState();
  const columns = [
    {
      dataIndex: "id",
      width: "10%",
      // eslint-disable-next-line react/display-name
      render: (text, record) => (
        <Checkbox
          value={text}
          checked={record.id == idChecked}
          onChange={handleSelectedRow}
        ></Checkbox>
      ),
    },
    {
      // title: "照会日",
      dataIndex: "ryaku",
      width: "90%",
      render: (text, record) => {
        return {
          props: {
            style: {
              backgroundColor: record.swColorBak,
            },
          },
          children: text,
        };
      },
    },
  ];
  function handleSelectedRow(e) {
    if (e.target.checked) setIdChecked(e.target.value);
    else setIdChecked(null);
  }

  function handleCancel() {
    props.onHideColorModal();
    setIdChecked(null);
  }
  function handleOk() {
    const rowSelected = props.data.find((i) => i.id == idChecked);
    props.onSelectedColor(rowSelected);
    setIdChecked(null);
  }
  return (
    (<Modal
      open={props.visible}
      // title={props.title}
      onCancel={() => handleCancel()}
      closable={false}
      footer={[
        <Button
          key="ok"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={() => handleCancel()}
        >
          キャンセル
        </Button>,
        <Button
          key="back"
          type="primary"
          onClick={() => handleOk()}
          disabled={(props.data && props.data.length === 0) || !idChecked}
        >
          OK
        </Button>,
      ]}
      width={200}
      className={props.className ? props.className : "color-master-modal"}
    >
      <Table
        loading={props.loading}
        bordered={true}
        size={"small"}
        columns={columns}
        pagination={false}
        rowKey={"id"}
        dataSource={props.data}
        showHeader={false}
      />
    </Modal>)
  );
}

export { ColorModal };
