import { BarsOutlined, EnterOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Modal } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getValueLocalstorage,
  localStorageClearCus,
} from "../../function_common/functionCommon";

class RightMenu extends Component {
  constructor(props) {
    super(props);
    const menuInfo = JSON.parse(localStorage.getItem("menu"));
    const multipleSystem = menuInfo.multipleSystem;
    const officeAuth = menuInfo.officeAuth;
    const systems = menuInfo.menu.split(";");

    this.state = {
      modalConfirmLogout: false,
      show: false,
      loading: false,
      visible: false,
      activekey: [this.props.current !== "record0" ? this.props.current : `record${menuInfo.si}`],
      systems,
      multipleSystem,
      officeAuth,
      menuInfo
    };
  }

  handleClick = (e) => {
    if (e.key !== "LogOut" && e.key !== "UserManagement") {
      localStorage.setItem("currentmenu", e.key);
      const preMenu = JSON.parse(localStorage.getItem("menu"));
      if (preMenu) {
        preMenu.ti1 = e.item.props.ti1;
        preMenu.ti2 = e.item.props.ti2;
        preMenu.sn = e.item.props.sn;
        preMenu.si = e.item.props.si;
        localStorage.setItem("menu", JSON.stringify(preMenu));
      }
    }
  };

  showLogoutModal = (e) => {
    e.preventDefault();
    this.setState({
      visible: true,
    });
  };

  handleLogoutOk = () => {
    // this.setState({ loading: true });
    this.setState({
      loading: false,
      visible: false,
      modalConfirmLogout: false,
    });

    localStorageClearCus();

    window.location.href = "/login";
  };

  handleLogoutCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { systems, multipleSystem, officeAuth, activekey } = this.state;
    const items = [];

    systems.forEach((value, index) => {
      if (index === 0 || multipleSystem === "true") {
        if (items.length > 0) {
          items.push({ type: "divider" });
        }
        const menus = value.split(",");
        items.push({
          key: "record" + menus[3],
          sn: menus[2],
          si: menus[3],
          ti1: menus[0],
          ti2: menus[1],
          label: <Link to={"/report?sn=" + menus[2] + "&si=" + menus[3]}>
            <span>{menus[0]}</span>
          </Link>,
          onClick: this.handleClick,
          disabled: activekey == "record" + menus[3]
        });

        // 黒帯メニューに移動
        if (officeAuth === "1") {
          if (items.length > 0) {
            items.push({ type: "divider" });
          }
          items.push({
            key: "business" + index,
            sn: menus[2],
            si: menus[3],
            ti1: menus[0],
            ti2: menus[1],
            label: <Link to={"/document-list"}>
              <span>{menus[1]}</span>
            </Link>,
            onClick: this.handleClick,
            disabled: activekey == "business" + index
          });
        }
      }
    });

    items.push({ type: "divider" });
    items.push({
      key: "UserManagement",
      label: <Link to={"/user-management"}>
        <span>{"ユーザ管理"}</span>
      </Link>
    });
    items.push({ type: "divider" });
    items.push({
      key: "LogOut",
      label: <Link to={"/"} onClick={this.showLogoutModal}>
        {"ログアウト"}
      </Link>
    });
    const { avatar, userName } = this.props;
    const { visible } = this.state;
    return (
      (<Col className={"h-right"} xs={{ span: 12 }}>
        <div className={"h-r-item"} id="h-r-item-id">
          <Dropdown menu={{ items }} trigger={["click"]} getPopupContainer={() => document.getElementById("h-r-item-id")}>
            <a className="ant-dropdown-link">
              <BarsOutlined style={{ fontSize: "36px", padding: "2px", color: "#fff" }} />
            </a>
          </Dropdown>
        </div>
        <div className={"h-r-item"}>{avatar}</div>
        <div className={"h-r-item"}>
          <h2>{userName}</h2>
        </div>
        {getValueLocalstorage("mode") ? (
          <div className={"h-r-item"}>
            <Link to="/kanri/user">
              <Button type="dashed" style={{ marginTop: 8 }}>
                管理画面
                <EnterOutlined />
              </Button>
            </Link>
          </div>
        ) : null}
        <Modal
          open={visible}
          title="ログアウト確認"
          onOk={this.handleLogoutOk}
          onCancel={this.handleLogoutCancel}
          className={"logout-modal"}
        >
          <p>ログアウトします。よろしいですか？</p>
        </Modal>
      </Col>)
    );
  }
}


export default RightMenu;
