export const DOWNLOAD_FILE_KIROKU = "DOWNLOAD_FILE_KIROKU";

export const LOAD_FILE_MANAGEMENT_REQUEST = "LOAD_FILE_MANAGEMENT_REQUEST";
export const LOAD_FILE_MANAGEMENT_SUCCESS = "LOAD_FILE_MANAGEMENT_SUCCESS";
export const LOAD_FILE_MANAGEMENT_ERROR = "LOAD_FILE_MANAGEMENT_ERROR";

export const GAZOU_COPY = "GAZOU_COPY";
export const GAZOU_DELETE = "GAZOU_DELETE";

export const REPORT_FILTER_REQUEST = "REPORT_FILTER_REQUEST";
export const REPORT_FILTER_SUCCESS = "REPORT_FILTER_SUCCESS";
export const REPORT_FILTER_ERROR = "REPORT_FILTER_ERROR";

export const YOUGO_MASTER_EDIT_STATE = "YOUGO_MASTER_EDIT_STATE";
export const YOUGO_MASTER_EDIT = "YOUGO_MASTER_EDIT";
export const YOUGO_MASTER_EDIT_CANCEL = "YOUGO_MASTER_EDIT_CANCEL";

export const TANTO_MASTER_LIST = "TANTO_MASTER_LIST";

export const YOUGO_MASTER_NAIYOU_FILE = "YOUGO_MASTER_NAIYOU_FILE";
export const YOUGO_MASTER_EDIT_STATE_FILE = "YOUGO_MASTER_EDIT_STATE_FILE";
export const YOUGO_MASTER_EDIT_CANCEL_FILE = "YOUGO_MASTER_EDIT_CANCEL_FILE";
export const YOUGO_MASTER_NAIYOU = "YOUGO_MASTER_NAIYOU";

export const FILE_MNGMT_INSERT_REQUEST = "FILE_MNGMT_INSERT_REQUEST";
export const FILE_MNGMT_INSERT_SUCCESS = "FILE_MNGMT_INSERT_SUCCESS";
export const FILE_MNGMT_INSERT_ERROR = "FILE_MNGMT_INSERT_ERROR";

export const FILE_MNGMT_DELETE_REQUEST = "FILE_MNGMT_DELETE_REQUEST";
export const FILE_MNGMT_DELETE_SUCCESS = "FILE_MNGMT_DELETE_SUCCESS";
export const FILE_MNGMT_DELETE_ERROR = "FILE_MNGMT_DELETE_ERROR";

export const FILE_MNGMT_UPDATE_REQUEST = "FILE_MNGMT_UPDATE_REQUEST";
export const FILE_MNGMT_UPDATE_SUCCESS = "FILE_MNGMT_UPDATE_SUCCESS";
export const FILE_MNGMT_UPDATE_ERROR = "FILE_MNGMT_UPDATE_ERROR";

export const SETTING_MASTER_LIST_REQUEST = "SETTING_MASTER_LIST_REQUEST";
export const SETTING_MASTER_LIST_SUCCESS = "SETTING_MASTER_LIST_SUCCESS";
export const SETTING_MASTER_LIST_ERROR = "SETTING_MASTER_LIST_ERROR";

export const HANLDE_YOUGO_MASTER_REQUEST = "HANLDE_YOUGO_MASTER_REQUET";
export const HANLDE_YOUGO_MASTER_ERROR = "HANLDE_YOUGO_MASTER_ERROR";

export const GET_FILE_REQUEST = " GET_FILE_REQUEST";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const GET_FILE_ERROR = "GET_FILE_ERROR";
