/* eslint-disable no-case-declarations */
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { CaretUpOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Collapse, Modal, Row, Spin, Table } from "antd";
import dayjs from "dayjs";
import React from "react";
import {
  getValueIskeep,
  isManagement,
  setValueIsKeep,
} from "../../../common/function_common/functionCommon";
import { displayJpDateKanji } from "../../../common/function_common/functionDisplay";
import noImage from "../../../common/images/no-image.png";
import { guid } from "../../../common/timePicker/formatTimePicker";
import AdlInfo from "./AdlInfo";
import BasicInfo from "./BasicInfo";
import ByoukiInfo from "./ByoukiInfo";
import ChartInfo from "./ChartInfo";
import FacesheetInfo from "./FacesheetInfo";
import HokenInfo from "./HokenInfo";
import JoutaiInfo from "./JoutaiInfo";
import KankeiInfo from "./KankeiInfo";
import KikiInfo from "./KikiInfo";
import KusuriInfo from "./KusuriInfo";
import NyuutaiinInfo from "./NyuutaiinInfo";
import ServiceInfo from "./ServiceInfo";
import SonotaInfo from "./SonotaInfo";

const FA = require("react-fontawesome");

const { Panel } = Collapse;

const customPanelStyle = {
  background: "#ddd",
  borderRadius: 4,
  marginBottom: 2,
  border: 0,
  overflow: "hidden",
};

export default class DetailRiyousya extends React.Component {
  constructor(props) {
    super(props);

    /**
     * Open tab when start is taken form localStorate
     *
     */
    const riyousyaTabInfo = getValueIskeep("riyousya_tab_info");
    const isOpen1 = riyousyaTabInfo ? riyousyaTabInfo.tab1 : "";
    const isOpen2 = riyousyaTabInfo ? riyousyaTabInfo.tab2 : "";
    const isOpen3 = riyousyaTabInfo ? riyousyaTabInfo.tab3 : "";
    const isOpen4 = riyousyaTabInfo ? riyousyaTabInfo.tab4 : "";
    const isOpen5 = riyousyaTabInfo ? riyousyaTabInfo.tab5 : "";
    const isOpen6 = riyousyaTabInfo ? riyousyaTabInfo.tab6 : "";
    const isOpen7 = riyousyaTabInfo ? riyousyaTabInfo.tab7 : "";
    const isOpen8 = riyousyaTabInfo ? riyousyaTabInfo.tab8 : "";
    const isOpen9 = riyousyaTabInfo ? riyousyaTabInfo.tab9 : "";
    const isOpen10 = riyousyaTabInfo ? riyousyaTabInfo.tab10 : "";
    const isOpen11 = riyousyaTabInfo ? riyousyaTabInfo.tab11 : "";
    const isOpen12 = riyousyaTabInfo ? riyousyaTabInfo.tab12 : "";
    const isOpen13 = riyousyaTabInfo ? riyousyaTabInfo.tab13 : "";

    this.state = {
      isOpen: false,
      isOpen1,
      isOpen2,
      isOpen3,
      isOpen4,
      isOpen5,
      isOpen6,
      isOpen7,
      isOpen8,
      isOpen9,
      isOpen10,
      isOpen11,
      isOpen12,
      isOpen13,
      isModalFacesheet: false,
      isInputFaceSheet: 1,
      titleModalFacesheet: "",
      faceRecord: null,
    };

    // bind place
  }

  onChangePanel = (key) => {
    switch (key) {
      case "1":
        const isOpen1 = this.state.isOpen1;
        if (isOpen1) {
          this.setState({
            isOpen1: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab1");
        }
        else {
          this.setState({
            isOpen1: "1",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "1", "tab1");
        }
        return;

      case "2":
        const isOpen2 = this.state.isOpen2;
        if (isOpen2) {
          this.setState({
            isOpen2: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab2");
        }
        else {
          this.setState({
            isOpen2: "2",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "2", "tab2");
        }
        return;

      case "3":
        const isOpen3 = this.state.isOpen3;
        if (isOpen3) {
          this.setState({
            isOpen3: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab3");
        }
        else {
          this.setState({
            isOpen3: "3",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "3", "tab3");
        }
        return;

      case "4":
        const isOpen4 = this.state.isOpen4;
        if (isOpen4) {
          this.setState({
            isOpen4: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab4");
        }
        else {
          this.setState({
            isOpen4: "4",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "4", "tab4");
        }
        return;

      case "5":
        const isOpen5 = this.state.isOpen5;
        if (isOpen5) {
          this.setState({
            isOpen5: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab5");
        }
        else {
          this.setState({
            isOpen5: "5",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "5", "tab5");
        }
        return;

      case "6":
        const isOpen6 = this.state.isOpen6;
        if (isOpen6) {
          this.setState({
            isOpen6: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab6");
        }
        else {
          this.setState({
            isOpen6: "6",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "6", "tab6");
        }
        return;

      case "7":
        const isOpen7 = this.state.isOpen7;
        if (isOpen7) {
          this.setState({
            isOpen7: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab7");
        }
        else {
          this.setState({
            isOpen7: "7",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "7", "tab7");
        }
        return;

      case "8":
        const isOpen8 = this.state.isOpen8;
        if (isOpen8) {
          this.setState({
            isOpen8: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab8");
        }
        else {
          this.setState({
            isOpen8: "8",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "8", "tab8");
        }
        return;

      case "9":
        const isOpen9 = this.state.isOpen9;
        if (isOpen9) {
          this.setState({
            isOpen9: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab9");
        }
        else {
          this.setState({
            isOpen9: "9",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "9", "tab9");
        }
        return;

      case "10":
        const isOpen10 = this.state.isOpen10;
        if (isOpen10) {
          this.setState({
            isOpen10: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab10");
        }
        else {
          this.setState({
            isOpen10: "10",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "10", "tab10");
        }
        return;

      case "11":
        const isOpen11 = this.state.isOpen11;
        if (isOpen11) {
          this.setState({
            isOpen11: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab11");
        }
        else {
          this.setState({
            isOpen11: "11",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "11", "tab11");
        }
        return;

      case "12":
        const isOpen12 = this.state.isOpen12;
        if (isOpen12) {
          this.setState({
            isOpen12: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab12");
        }
        else {
          this.setState({
            isOpen12: "12",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "12", "tab12");
        }
        return;

      case "13":
        const isOpen13 = this.state.isOpen13;
        if (isOpen13) {
          this.setState({
            isOpen13: "",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "", "tab13");
        }
        else {
          this.setState({
            isOpen13: "13",
          });
          // setValueToLocalStorae
          setValueIsKeep("riyousya_tab_info", "13", "tab13");
        }
        return;

      default:
        return;
    }
  };

  handleOpenColl = () => {
    const { isOpen } = this.state;

    if (!isOpen) {
      this.setState({
        isOpen: !isOpen,
        isOpen1: "1",
        isOpen2: "2",
        isOpen3: "3",
        isOpen4: "4",
        isOpen5: "5",
        isOpen6: "6",
        isOpen7: "7",
        isOpen8: "8",
        isOpen9: "9",
        isOpen10: "10",
        isOpen11: "11",
        isOpen12: "12",
        isOpen13: "13",
      });
      // setValueToLocalStorae
      const riyousyaTabInfo = {
        tab1: "1",
        tab2: "2",
        tab3: "3",
        tab4: "4",
        tab5: "5",
        tab6: "6",
        tab7: "7",
        tab8: "8",
        tab9: "9",
        tab10: "10",
        tab11: "11",
        tab12: "12",
        tab13: "13",
      };
      setValueIsKeep("riyousya_tab_info", riyousyaTabInfo);
    }
    else {
      this.setState({
        isOpen: !isOpen,
        isOpen1: "",
        isOpen2: "",
        isOpen3: "",
        isOpen4: "",
        isOpen5: "",
        isOpen6: "",
        isOpen7: "",
        isOpen8: "",
        isOpen9: "",
        isOpen10: "",
        isOpen11: "",
        isOpen12: "",
        isOpen13: "",
      });

      const riyousyaTabInfo = {
        tab1: "",
        tab2: "",
        tab3: "",
        tab4: "",
        tab5: "",
        tab6: "",
        tab7: "",
        tab8: "",
        tab9: "",
        tab10: "",
        tab11: "",
        tab12: "",
        tab13: "",
      };
      setValueIsKeep("riyousya_tab_info", riyousyaTabInfo);
    }
  };

  faceSheetSelect = () => {
    this.setState({
      isModalFacesheet: true,
      isInputFaceSheet: 1, // 1 is display, this record have been edit if click on 編集ボタン, 2 is add new
      titleModalFacesheet: "選択ダイアログ",
    });
  };

  faceSheetAdd = () => {
    this.setState({
      isModalFacesheet: true,
      isInputFaceSheet: 2,
      titleModalFacesheet: "複製選択ダイアログ",
    });
  };

  handleOkModalFacesheet = () => {
    const { isInputFaceSheet, faceRecord } = this.state;
    // const { riyousya } = this.props;

    if (isInputFaceSheet === 1 && faceRecord) {
      this.props.handleChangeFacesheetId(faceRecord.id);
      this.props.changeFacesheet(true, "change");
    }

    if (isInputFaceSheet === 2 && faceRecord) {
      this.props.handleCopyFacesheet(faceRecord.id);
    }

    // close popup
    this.setState({
      isModalFacesheet: false,
      titleModalFacesheet: "",
    });
  };

  handleCancelModalFacesheet = () => {
    // close popup
    this.setState({
      isModalFacesheet: false,
      titleModalFacesheet: "",
      faceRecord: null,
      isInputFaceSheet: 1,
    });
  };

  onRowClickFacesheet = (record) => {
    this.setState({
      faceRecord: record,
    });
  };

  // infoRefChart = (formRef) => {
  //   console.log(formRef)
  //   this.refChart = formRef;
  // };

  render() {
    const {
      riyousya,
      loading,
      bunruiIsDis,
      bunruiTitle,
      systemBunruiIsDis,
      systemBunruiTitle,
      tekiyouIsDis,
      tekiyouTitle,
      faxTitle,
      setFSOtherTitle,
      setFSSonotaTitle,
      fsSonotaTitleDis,
      system_setFSSonotaTitle,
      system_fsSonotaTitleDis,
      facesheetId,
      kihonAdl,
      tantoMastersList,
      changeFacesheetState,
      saveFacesheetState,
      openFacesheetState,
    } = this.props;
    const {
      isOpen,
      isOpen1,
      isOpen2,
      isOpen3,
      isOpen4,
      isOpen5,
      isOpen6,
      isOpen7,
      isOpen8,
      isOpen9,
      isOpen10,
      isOpen11,
      isOpen12,
      isOpen13,
      isModalFacesheet,
      titleModalFacesheet,
      faceRecord,
    } = this.state;

    let facesheetItemSelected = {};
    if (riyousya && riyousya.facesheet && riyousya.facesheet.length > 0) {
      facesheetItemSelected = riyousya.facesheet.find(
        (item) => item.id.toString() === facesheetId.toString()
      );

      if (!facesheetItemSelected) {
        facesheetItemSelected = riyousya.facesheet[0];
      }
    }

    let stethoscopeTitle = "主治医・病名";
    if (isManagement())
      stethoscopeTitle = "主治医・病名・ケアマネ";

    return (
      (<div className="riyousya-detail-content">
        <Row>
          <Col sm={24} md={8} className="rdc-avatar">
            <div className="rdc-avatar-img">
              <Spin spinning={loading}>
                <Avatar
                  src={
                    riyousya && riyousya.riyousyaImage
                      ? riyousya.riyousyaImage
                      : noImage
                  }
                />
              </Spin>
            </div>
            {riyousya ? (
              <div className="rdc-avatar-info">
                <Col>
                  <span className="user_kana">{riyousya.furigana}</span>
                  <span className="user_kanji">{riyousya.name}</span>
                  <span className="user_sei men">
                    {riyousya.gender && riyousya.gender === 1 ? (
                      <div className="c-blue">男性</div>
                    ) : (
                      <div className="c-red">女性</div>
                    )}
                  </span>
                  <span className="user_age">
                    {riyousya.birthday && riyousya.birthday !== "0001-01-01" ? (
                      <span>
                        {`${displayJpDateKanji(
                          riyousya.birthday
                        )}  ${dayjs().diff(
                          dayjs(riyousya.birthday, "YYYY-MM-DD"),
                          "years"
                        )}歳`}
                      </span>
                    ) : null}
                  </span>
                </Col>

                <Row>
                  {/* <Button type="primary" >選択</Button> */}
                  <Button type="primary" onClick={this.faceSheetSelect}>
                    選択
                  </Button>
                  <Button type="primary" onClick={this.faceSheetAdd}>
                    追加
                  </Button>

                  {/* <Link to={`/riyousya-update/${riyousya.id}`} className="sub-top-right-item">
                                        <Button type="primary" >追加</Button>
                                    </Link> */}
                </Row>

                <Row>
                  <span>
                    日付
                    {facesheetItemSelected && facesheetItemSelected.date
                      ? `  ${facesheetItemSelected.date.split("-")[0]}年 ${facesheetItemSelected.date.split("-")[1]
                      }月 ${facesheetItemSelected.date.split("-")[2]}日`
                      : null}
                  </span>
                  <span>
                    {facesheetItemSelected && facesheetItemSelected.comment
                      ? facesheetItemSelected.comment
                      : null}
                  </span>
                  <span>
                    {facesheetItemSelected && facesheetItemSelected.attn
                      ? facesheetItemSelected.attn
                      : null}
                  </span>
                </Row>
              </div>
            ) : null}
          </Col>
          <Col sm={24} md={16} className="rdc-list">
            <div className="expand-btn">
              <Button
                type="primary"
                icon={<LegacyIcon type={isOpen ? "minus" : "plus"} />}
                onClick={this.handleOpenColl}
              >
                {isOpen ? "格納" : "展開"}
              </Button>
            </div>
            {riyousya ? (
              <div>
                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen1]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "1")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="file-text-o" key={guid()} /> 基本情報
                      </span>
                    }
                    key="1"
                    style={customPanelStyle}
                  >
                    <BasicInfo
                      riyousya={riyousya}
                      bunruiIsDis={bunruiIsDis}
                      bunruiTitle={bunruiTitle}
                      systemBunruiIsDis={systemBunruiIsDis}
                      systemBunruiTitle={systemBunruiTitle}
                      tekiyouIsDis={tekiyouIsDis}
                      tekiyouTitle={tekiyouTitle}
                      faxTitle={faxTitle}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen2]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "2")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="medkit" key={guid()} /> 家族・関係者
                      </span>
                    }
                    key="2"
                    style={customPanelStyle}
                  >
                    <KankeiInfo riyousya={riyousya} />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen13]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "13")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="users" key={guid()} /> 家族構成図
                      </span>
                    }
                    key="13"
                    style={customPanelStyle}
                  >
                    <ChartInfo
                      riyousya={riyousya}
                      riyouChart={facesheetItemSelected?.riyouChart?.length > 0 ? facesheetItemSelected.riyouChart[0] : {}}
                      // wrappedComponentRef={(formRef) =>
                      //   this.infoRefChart(formRef)
                      // }
                      // wrappedComponentRef={this.cildRef}
                      onRef={ref => (this.infoChild = ref)}
                      changeFacesheetState={changeFacesheetState}
                      saveFacesheetState={saveFacesheetState}
                      openFacesheetState={openFacesheetState}
                      changeFacesheet={this.props.changeFacesheet}
                      ref={(ref) => (this.childComponentRef = ref)}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen3]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "3")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="stethoscope" key={guid()} />{stethoscopeTitle}
                      </span>
                    }
                    key="3"
                    style={customPanelStyle}
                  >
                    <ByoukiInfo
                      riyousya={riyousya}
                      tantoMastersList={tantoMastersList}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen4]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "4")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="flask" key={guid()} /> 薬情報
                      </span>
                    }
                    key="4"
                    style={customPanelStyle}
                  >
                    <KusuriInfo riyousya={riyousya} />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen5]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "5")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="plug" key={guid()} /> 機器情報
                      </span>
                    }
                    key="5"
                    style={customPanelStyle}
                  >
                    <KikiInfo riyousya={riyousya} />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen6]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "6")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="sign-language" key={guid()} /> サービス情報
                      </span>
                    }
                    key="6"
                    style={customPanelStyle}
                  >
                    <ServiceInfo riyousya={riyousya} />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen7]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "7")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="ambulance" key={guid()} /> 入退院情報
                      </span>
                    }
                    key="7"
                    style={customPanelStyle}
                  >
                    <NyuutaiinInfo riyousya={riyousya} />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen8]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "8")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="meh-o" key={guid()} /> フェイスシート
                      </span>
                    }
                    key="8"
                    style={customPanelStyle}
                  >
                    <FacesheetInfo
                      facesheet={facesheetItemSelected}
                      riyousya={riyousya}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen10]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "10")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="sun-o" key={guid()} /> ＡＤＬ
                      </span>
                    }
                    key="10"
                    style={customPanelStyle}
                  >
                    <AdlInfo
                      facesheet={facesheetItemSelected}
                      kihonAdl={kihonAdl}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen11]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "11")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="newspaper-o" key={guid()} /> 状況
                      </span>
                    }
                    key="11"
                    style={customPanelStyle}
                  >
                    <JoutaiInfo
                      facesheet={facesheetItemSelected}
                      setFSOtherTitle={setFSOtherTitle}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen9]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "9")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="plus-square" key={guid()} /> その他
                      </span>
                    }
                    key="9"
                    style={customPanelStyle}
                  >
                    <SonotaInfo
                      facesheet={facesheetItemSelected}
                      setFSSonotaTitle={setFSSonotaTitle}
                      fsSonotaTitleDis={fsSonotaTitleDis}
                      system_setFSSonotaTitle={system_setFSSonotaTitle}
                      system_fsSonotaTitleDis={system_fsSonotaTitleDis}
                    />
                  </Panel>
                </Collapse>

                <Collapse
                  accordion
                  className="rwa-c-col"
                  expandIconPosition="right"
                  bordered={false}
                  activeKey={[isOpen12]}
                  expandIcon={({ isActive }) => (
                    <CaretUpOutlined rotate={isActive ? 180 : 0} />
                  )}
                  onChange={this.onChangePanel.bind(this, "12")}
                >
                  <Panel
                    header={
                      <span className="user_ad">
                        <FA name="heart" key={guid()} /> 保険情報
                      </span>
                    }
                    key="12"
                    style={customPanelStyle}
                  >
                    <HokenInfo riyousya={riyousya} />
                  </Panel>
                </Collapse>
              </div>
            ) : null}
          </Col>
        </Row>
        {/* modal facesheet */}
        <Modal
          open={isModalFacesheet}
          title={titleModalFacesheet}
          onOk={this.handleOkModalFacesheet}
          onCancel={this.handleCancelModalFacesheet}
          footer={[
            <Button key="back" onClick={this.handleCancelModalFacesheet}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleOkModalFacesheet}
            >
              OK
            </Button>,
          ]}
        >
          <Table
            columns={[
              {
                title: "日付",
                dataIndex: "date",
                key: "date",
              },
              {
                title: "コメント",
                dataIndex: "comment",
                key: "comment",
              },
            ]}
            dataSource={
              riyousya && riyousya.facesheet ? riyousya.facesheet : []
            }
            size="small"
            pagination={{ pageSize: 10 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  this.onRowClickFacesheet(record);
                }, // click row
              };
            }}
            rowClassName={(record) =>
              faceRecord != null && faceRecord.id != null && record.id === faceRecord.id
                ? "pljp-table-row-hover"
                : ""
            }
            rowKey="id"
          />
        </Modal>
      </div>)
    );
  }
}
