import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";
import {
  getMenu,
  formatStorage,
} from "../common/function_common/functionCommon";

let DataFilterApi = {
  dataFilterApi(tabName) {
    const key = getMenu();
    let systemId = formatStorage(key);
    return axios(
      apiUrls.INIT + apiUrls.NYUTAISHO_FILTER + "?systemId=" + systemId.si,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        } else {
          //

          return err;
        }
      });
  },
};

export default DataFilterApi;
