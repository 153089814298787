import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";

export const RiyouConcerApi = {
  loadRiyouConcern(page = 1, riyousyaId) {
    return axios
      .get(apiUrls.GET_RIYOU_CONCERN_LIST, {
        params: {
          page,
          riyousyaId: riyousyaId ? parseInt(riyousyaId) : null,
        },
      })
      .then((res) => res.data);
  },
};
