import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row } from "antd";
import React from "react";

export default class TimeKBCus extends React.Component {
  /**
   * 時間ところ
   */

  handleInput = (type, value) => {
    const { timeString } = this.props;
    let preData = timeString ? timeString.substring(0, 5) : null;

    // case input hours
    if (type === 1) {
      if (!preData) {
        preData = value + ":00";
      }
      else {
        preData = value + preData.substring(2, 5);
      }

      this.props.handleInput(preData);
    }

    // case input left minutes
    if (type === 2) {
      if (!preData) {
        preData = "00:" + value;
      }
      else {
        preData = preData.substring(0, 3) + value;
      }

      this.props.handleInput(preData);
    }

    // case input right minutes
    if (type === 3) {
      if (!preData) {
        preData = "00:0" + value;
      }
      else {
        preData = preData.substring(0, 4) + value;
      }

      this.props.handleInput(preData);
    }
  };

  render() {
    const { timeString } = this.props;

    const timeKB = (
      <Row gutter={16} id="report-page-time-pro">
        <Col span={3}>時間: </Col>
        <Col span={10}>
          <div>
            <Button onClick={() => this.handleInput(1, "00")}>00</Button>
            <Button onClick={() => this.handleInput(1, "08")}>08</Button>
            <Button onClick={() => this.handleInput(1, "16")}>16</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(1, "01")}>01</Button>
            <Button onClick={() => this.handleInput(1, "09")}>09</Button>
            <Button onClick={() => this.handleInput(1, "17")}>17</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(1, "02")}>02</Button>
            <Button onClick={() => this.handleInput(1, "10")}>10</Button>
            <Button onClick={() => this.handleInput(1, "18")}>18</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(1, "03")}>03</Button>
            <Button onClick={() => this.handleInput(1, "11")}>11</Button>
            <Button onClick={() => this.handleInput(1, "19")}>19</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(1, "04")}>04</Button>
            <Button onClick={() => this.handleInput(1, "12")}>12</Button>
            <Button onClick={() => this.handleInput(1, "20")}>20</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(1, "05")}>05</Button>
            <Button onClick={() => this.handleInput(1, "13")}>13</Button>
            <Button onClick={() => this.handleInput(1, "21")}>21</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(1, "06")}>06</Button>
            <Button onClick={() => this.handleInput(1, "14")}>14</Button>
            <Button onClick={() => this.handleInput(1, "22")}>22</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(1, "07")}>07</Button>
            <Button onClick={() => this.handleInput(1, "15")}>15</Button>
            <Button onClick={() => this.handleInput(1, "23")}>23</Button>
          </div>
        </Col>
        <Col span={3}>分: </Col>
        <Col span={8}>
          <div>
            <Button onClick={() => this.handleInput(2, "00")}>00</Button>
            <Button onClick={() => this.handleInput(3, "0")}>0</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(2, "10")}>10</Button>
            <Button onClick={() => this.handleInput(3, "1")}>1</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(2, "15")}>15</Button>
            <Button onClick={() => this.handleInput(3, "2")}>2</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(2, "20")}>20</Button>
            <Button onClick={() => this.handleInput(3, "3")}>3</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(2, "29")}>29</Button>
            <Button onClick={() => this.handleInput(3, "4")}>4</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(2, "30")}>30</Button>
            <Button onClick={() => this.handleInput(3, "5")}>5</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(2, "40")}>40</Button>
            <Button onClick={() => this.handleInput(3, "6")}>6</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(2, "45")}>45</Button>
            <Button onClick={() => this.handleInput(3, "7")}>7</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(2, "50")}>50</Button>
            <Button onClick={() => this.handleInput(3, "8")}>8</Button>
          </div>
          <div>
            <Button onClick={() => this.handleInput(2, "59")}>59</Button>
            <Button onClick={() => this.handleInput(3, "9")}>9</Button>
          </div>
        </Col>
      </Row>
    );
    return (
      <Popover
        id="time-kb-cus"
        placement="bottomLeft"
        content={timeKB}
        trigger="click"
        type="temperature"
      >
        <Button icon={<ClockCircleOutlined />}>
          {timeString ? timeString.substring(0, 5) : "00:00"}
        </Button>
      </Popover>
    );
  }
}
