import { MeetingActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";

function meeting(state = {}, action) {
  switch (action.type) {
    // get all meeting by user id
    case MeetingActionTypes.GET_ALL_MEETING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case MeetingActionTypes.GET_ALL_MEETING_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case MeetingActionTypes.GET_ALL_MEETING_FAILURE: {
      return {
        ...state,
        data: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }

    case MeetingActionTypes.GET_MEETING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getOne",
      };
    }
    case MeetingActionTypes.GET_MEETING_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case MeetingActionTypes.GET_MEETING_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // create meeting
    case MeetingActionTypes.CREATE_MEETING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case MeetingActionTypes.CREATE_MEETING_SUCCESS:
    case MeetingActionTypes.CREATE_MEETING_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // update meeting
    case MeetingActionTypes.UPDATE_MEETING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case MeetingActionTypes.UPDATE_MEETING_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case MeetingActionTypes.UPDATE_MEETING_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // remove meeting
    case MeetingActionTypes.REMOVE_MEETING_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case MeetingActionTypes.REMOVE_MEETING_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case MeetingActionTypes.REMOVE_MEETING_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    case MeetingActionTypes.GET_ALL_MEETING_QUOTE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case MeetingActionTypes.GET_ALL_MEETING_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuote: action.data,
      };
    case MeetingActionTypes.GET_ALL_MEETING_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listQuote: [],
      };
    default: {
      return { ...state };
    }
  }
}

export default meeting;
