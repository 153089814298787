export const ConstSet = {
  BASE_URL: "http://localhost:9000",
  BASE_FILE_URL: "/api/v1/file-management-url?fileManagementId=",
  SYSTEM_CONTROL_NAME: "kiroku",
  DELAY_SPIN: "3000", // miliseconds
  DATE_FORMAT: "YYYY-MM-DD",
  TIME_FORMAT: "HH:mm",
  DATE_FORMAT_YEAR_MONTH: "YYYYMM",

  GOOGLE_MAP_API_KEY: "AIzaSyCGV33RAK-Ej0tGjTSJcMKROcRzWtgSsF0",

  PER_PAGE: 100,

  // URL SET
  REPORT_PAGE: "/report",
  REPORT_USER_PAGE: "/report/",
  REPORT_WEEKLY_PAGE: "/report-weekly/",
  REPORT_WEEKLY_ADD_PAGE: "/report-weekly-save/",
  PROGRESS_PAGE: "/progress",
  RIYOUSYA_PAGE: "/riyousya",

  SUBMENU_KIROKU_NICHI: 1,
  SUBMENU_KIROKU_RIYOUSYA: 2,

  // syokochi
  HAISETU_SYOKICHI_NAIYOU: "排便種類",
  NYUYOKU_SYOKICHI_NAIYOU: "入浴種類",

  TABLE_PAGINATION: 100, // 50/page
  KIROKU_JIKAN_TYPE_V: "jikantaiV", // vital
  KIROKU_JIKAN_TYPE_S: "jikantaiS", // suibun
  KIROKU_JIKAN_TYPE_H: "jikantaiH", // haisetu
  KIROKU_JIKAN_TYPE_N: "jikantaiN", // nyuyoku
  KIROKU_JIKAN_TYPE_F: "jikantaiF", // fukuyaku
  KIROKU_JIKAN_TYPE_K: "jikantaiK", // kaigo
  KIROKU_JIKAN_TYPE_T: "jikantaiT", // tokki

  TABLE_PAGINATION_RIYOUSYA: 1000, // 50/page

  KIROKU_RIOYUSYA: "利用者",
  KIROKU_VITAL: "バイタル",
  KIROKU_MEAL: "食事摂取",
  KIROKU_SUIBUN: "水分摂取",
  KIROKU_HAISETU: "排泄",
  KIROKU_NYUYOKU: "入浴",
  KIROKU_FUKUYAKU: "服薬",
  KIROKU_KAIGO: "提供記録",
  KIROKU_TOKKI: "特記",
  KIROKU_FILE: "画像等",

  LS_PN_KIROKU_KAIGO_NAIYOU: "kiroku_kaigo_naiyou",

  MODAL_B_TIME: 1, // lowese
  MODAL_B_REPORTCASETYUIF: 2,
  MODAL_B_MAINF: 3,
  MODAL_B_WEIGHT: 4,
  MODAL_B_TEMP: 5,
  MODAL_B_BPRESSURE_HIGHT: 6,
  MODAL_B_BPRESSURE_LOW: 7,
  MODAL_B_PULSEF_NO: 8,
  MODAL_B_PULSEF_TYPE: 9,
  MODAL_B_KOKYU_NO: 10,
  MODAL_B_KOKYU_TYPE: 11,
  MODAL_B_SPO2_BEFORE: 12,
  MODAL_B_SPO2_AFTER: 13,
  MODAL_B_ETC0: 14,
  MODAL_B_ETC1: 15,
  MODAL_B_ETC2: 16,
  MODAL_B_ETC3: 17,
  MODAL_B_ETC4: 18,
  MODAL_B_ETC5: 19,
  MODAL_B_ETC6: 20,
  MODAL_B_ETC7: 21,
  MODAL_B_ETC8: 22,
  MODAL_B_ETC9: 23,
  MODAL_B_COMMENT: 24,
  MODAL_B_TANTOMASTER: 25, // highest of baitaru tab

  MODAL_M_TIME: 30,
  MODAL_M_REPORTCASETYUIF: 31,
  MODAL_M_SHUBETU: 32,
  MODAL_M_STAPLEINTAKE: 33,
  MODAL_M_SIDEINTAKE: 34,
  MODAL_M_SHOKUNASI: 35,
  MODAL_M_COMMENT: 36,
  MODAL_M_TANTOMASTER: 37,

  MODAL_S_TIME: 40,
  MODAL_S_REPORTCASETYUIF: 41,
  MODAL_S_SYURUI: 42,
  MODAL_S_INTAKE: 43,
  MODAL_S_COMMENT: 44,
  MODAL_S_TANTOMASTER: 45,

  MODAL_H_TIME: 50,
  MODAL_H_REPORTCASETYUIF: 51,
  MODAL_H_HAISETUKUBUN: 52,
  MODAL_H_SHURUI: 53,
  MODAL_H_HAISETUF: 54,
  MODAL_H_VOL: 55,
  MODAL_H_JOUTAI: 56,
  MODAL_H_COMMENT: 57,
  MODAL_H_TANTOMASTER: 58,

  MODAL_N_TIME: 60,
  MODAL_N_REPORTCASETYUIF: 61,
  MODAL_N_NYUYOKUNAIYOU: 62,
  MODAL_N_COMMENT: 63,
  MODAL_N_TANTOMASTER: 64,

  MODAL_F_TIME: 70,
  MODAL_F_REPORTCASETYUIF: 71,
  MODAL_F_YAKUMEI: 72,
  MODAL_F_COMMENT: 73,
  MODAL_F_TANTOMASTER: 74,

  MODAL_K_TIME: 80,
  MODAL_K_REPORTCASETYUIF: 81,
  MODAL_K_KAIGONAIYOUCODE: 82,
  MODAL_K_COMMENT: 83,
  MODAL_K_TANTOMASTER: 84,

  MODAL_T_TIME: 90,
  MODAL_T_REPORTCASETYUIF: 91,
  MODAL_T_COMMENT: 92,
  MODAL_T_TANTOMASTER: 93,

  MODAL_G_TIME: 100,
  MODAL_G_REPORTCASETYUIF: 101,
  // MODAL_G_THUMBNAIL: 102,
  MODAL_G_PRIORITYF: 102,
  MODAL_G_KUBUN: 103,
  MODAL_G_COMMENT: 104,
  MODAL_G_TANTOMASTER: 105,

  // COL-NAME SET
  COL_SELECT: 4,
  COL_COPY: 5,
  COL_MODE: 6,
  COL_NAME: 7,
  COL_ADDRESS: 8,
  COL_ROOM: 9,

  COL_VITAL_ETC1: 1,
  COL_VITAL_ETC2: 2,
  COL_VITAL_ETC3: 3,

  COL_VITAL_TIME: 10,
  COL_VITAL_CASEF: 11,
  COL_VITAL_MAINF: 12,
  COL_VITAL_TEM: 13,
  COL_VITAL_PRESSURE: 14,
  COL_VITAL_PULSEF: 15,
  COL_VITAL_SPO2: 16,
  COL_VITAL_GULSEF: 17,
  COL_VITAL_COMENT: 18,
  COL_VITAL_TANTO: 19,
  COL_VITAL_DEL: 20,
  COL_VITAL_WEIGHT: 21,

  COL_MEAL_TIME: 10,
  COL_MEAL_REPORTCASETYUIF: 11,
  COL_MEAL_SHUBETU: 12,
  COL_MEAL_STAPLEINTAKE: 13,
  COL_MEAL_SIDEINTAKE: 14,
  COL_MEAL_SHOKUNASI: 15,
  COL_MEAL_COMMENT: 16,
  COL_MEAL_TANTOMASTER: 17,

  COL_SUIBUN_TIME: 10,
  COL_SUIBUN_REPORTCASETYUIF: 11,
  COL_SUIBUN_SYURUI: 12,
  COL_SUIBUN_INTAKE: 13,
  COL_SUIBUN_COMMENT: 14,
  COL_SUIBUN_TANTOMASTER: 15,

  COL_HAISETU_TIME: 10,
  COL_HAISETU_REPORTCASETYUIF: 11,
  COL_HAISETU_HAISETUKUBUN: 12,
  COL_HAISETU_SHURUI: 13,
  COL_HAISETU_HAISETUF: 14,
  COL_HAISETU_VOL: 15,
  COL_HAISETU_JOUTAI: 16,
  COL_HAISETU_COMMENT: 17,
  COL_HAISETU_TANTOMASTER: 18,

  COL_NYUYOKU_TIME: 10,
  COL_NYUYOKU_REPORTCASETYUIF: 11,
  COL_NYUYOKU_NYUYOKUNAIYOU: 12,
  COL_NYUYOKU_COMMENT: 13,
  COL_NYUYOKU_TANTOMASTER: 14,

  COL_FUKUYAKU_TIME: 10,
  COL_FUKUYAKU_REPORTCASETYUIF: 11,
  COL_FUKUYAKU_YAKUMEI: 12,
  COL_FUKUYAKU_COMMENT: 13,
  COL_FUKUYAKU_TANTOMASTER: 14,

  COL_KAIGO_TIME: 10,
  COL_KAIGO_REPORTCASETYUIF: 11,
  COL_KAIGO_KAIGONAIYOUCODE: 12,
  COL_KAIGO_COMMENT: 13,
  COL_KAIGO_TANTOMASTER: 14,

  COL_TOKKI_TIME: 10,
  COL_TOKKI_REPORTCASETYUIF: 11,
  COL_TOKKI_COMMENT: 12,
  COL_TOKKI_TANTOMASTER: 13,

  COL_GAZO_TIME: 10,
  COL_GAZO_REPORTCASETYUIF: 11,
  COL_GAZO_PRIORITYF: 12,
  COL_GAZO_KUBUN: 13,
  COL_GAZO_COMMENT: 14,
  COL_GAZO_TANTOMASTER: 15,
  COL_GAZO_THUMBNAIL: 16,

  MAXROWS: 99,
};
