import { Form } from '@ant-design/compatible';
import { InboxOutlined } from '@ant-design/icons';
import React from "react";

// import '@ant-design/compatible/assets/index.css';


import { Avatar, Button, Col, Input, Modal, Row, Upload } from "antd";

import MediaQuery from "react-responsive";
import SubHeaderTitleV2 from "../../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../../common/component/SubHeaderV2";

import {
  getBase64,
  validateNumberAndLetter,
} from "../../../common/function_common/functionCommon";

const Dragger = Upload.Dragger;

class FormInputForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      uploading: false,
      imageUrl: "",
      file: null,

      thumbnail: null,
      fileName: null,
    };
  }

  componentDidUpdate(oldProps) {
    const { data } = this.props;

    if (data !== oldProps.data) {
      this.setState({
        thumbnail: data.userImage,
      });
    }
  }

  onRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  };

  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 200;
    if (!isLt2M) {
      alert("画像は200MB未満でなければなりません！");
      return;
    }

    const isJPG1 = file.type === "image/jpeg";
    const isJPG2 = file.type === "image/jpg";
    const isJPG3 = file.type === "image/png";
    const isJPG4 = file.type === "image/git";
    if (isJPG1 || isJPG2 || isJPG3 || isJPG4) {
      getBase64(file, (imageUrl) => {
        this.setState({
          thumbnail: imageUrl,
          fileName: file.name,
        });
      });
    } else {
      alert("画像タイプをインポートしてください!");
      return;
    }
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.props.form.isFieldsTouched()) this.showGoBackConfirm();
    else this.props.history.goBack();
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.goBack();
  }

  handleUpdate = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.updateToServer(values);
    });
  };

  async updateToServer(values) {
    const { data } = this.props;
    const { thumbnail } = this.state;

    let updateData = {
      password: values.password ? values.password : null,
      userImage: thumbnail,
      tid: values.tid,
      uid: values.uid,
    };

    if (data && data.id > 0) {
      await this.props.updateUser(Object.assign(data, updateData));
    } else {
      await this.props.createUser(updateData);
    }

    this.props.history.goBack();
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  validateUID = (rule, value, callback) => {
    if (value && value.length < 6) {
      callback("※半角英数字で6文字以上入力して下さい。");
    } else if (!validateNumberAndLetter(value)) {
      callback("※数字と文字のみを入力してください。");
    } else {
      callback();
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("「パスワード」と「パスワード(確認)」の値が一致しません。");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;

    if (value && value.length < 8) {
      callback("※半角英数字で8文字以上入力して下さい。");
    } else if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }

    callback();
  };

  onChangeTid = (tid) => {
    if (!this.props.data.id) {
      const tidName = this.props.tantoMasterList.filter(
        (item) => item.id.toString().indexOf(tid) >= 0
      );
      if (tidName[0] != null) {
        this.props.data.name = tidName[0].name;
      } else {
        this.props.data.name = null;
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { thumbnail, fileName } = this.state;

    return (
      (<div>
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return this.renderHeader();
            }
            return this.renderHeaderMobile();
          }}
        </MediaQuery>
        <Row className="user-management-edit">
          <Form
            layout="vertical"
            hideRequiredMark
            style={{ maxWidth: 780, margin: "24px auto" }}
          >
            <Row>
              <Col sm={8} md={6}>
                <Avatar src={thumbnail} />
              </Col>
              <Col sm={16} md={18}>
                <Form.Item label="画像">
                  {getFieldDecorator("thumbnail", {
                    rules: [
                      { required: false, message: "ファイル入力してください" },
                    ],
                  })(
                    <Dragger
                      onRemove={this.onRemove}
                      beforeUpload={this.beforeUpload}
                      fileList={[]}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        このエリアにファイルをクリックし、ドラッグして、アップロードしてください
                      </p>
                    </Dragger>
                  )}

                  {fileName ? <p>{fileName}</p> : null}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="担当者ID">
              {getFieldDecorator("tid", {
                rules: [{ required: false }],
              })(
                <Input
                  type="number"
                  onChange={(e) => this.onChangeTid(e.target.value)}
                />
              )}
            </Form.Item>

            <p>
              <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>名前　</span>
              {this.props.data.name}
            </p>

            <Form.Item
              label="ユーザID"
              extra={
                <span>
                  <span style={{ color: "red" }}>※</span>
                  半角英数字で6文字以上入力して下さい
                </span>
              }
            >
              {getFieldDecorator("uid", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: true,
                    message: "ユーザIDは必須です。",
                    whitespace: true,
                  },
                  {
                    validator: this.validateUID,
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label="パスワード"
              hasFeedback
              extra={
                <span>
                  <span style={{ color: "red" }}>※</span>
                  半角英数字で8文字以上入力して下さい
                </span>
              }
            >
              {getFieldDecorator("password", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: false,
                    message: "パスワードを入力して下さい。",
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input type="password" autoComplete="new-password" />)}
            </Form.Item>
            <Form.Item
              label="パスワード(確認)"
              hasFeedback
              extra={
                <span>
                  <span style={{ color: "red" }}>※</span>
                  半角英数字で8文字以上入力して下さい
                </span>
              }
            >
              {getFieldDecorator("confirm", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: false,
                    message: "パスワード(確認)を入力して下さい",
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(
                <Input
                  type="password"
                  onBlur={this.handleConfirmBlur}
                  autoComplete="new-password"
                />
              )}
            </Form.Item>

            <div style={{ textAlign: "right" }}>
              <span>
                <Button type="primary" danger onClick={this.handleUpdate}>
                  登録
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: 16 }}
                  onClick={this.handleGoBack}
                >
                  閉じる
                </Button>
              </span>
            </div>
          </Form>
        </Row>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }

  renderHeader() {
    return (
      <Row className={"submenu"}>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <SubHeaderV2
                  TopLeft={
                    <SubHeaderTitleV2 title={`ユーザ詳細`} iconType="team" />
                  }
                  TopRight={null}
                />
              );
            } else {
              /**
               * @TODO design UI for mobile
               *
               * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
               */
              return null;
            }
          }}
        </MediaQuery>
      </Row>
    );
  }
}

export const FormInput = Form.create({
  name: "user-shiryou-input",

  onValuesChange(_, values) { },

  mapPropsToFields(props) {
    return {
      thumbnail: Form.createFormField({
        value: props.data ? props.data.thumbnail : null,
      }),
      tid: Form.createFormField({
        value: props.data ? props.data.tid : null,
      }),
      uid: Form.createFormField({
        value: props.data ? props.data.uid : null,
      }),
    };
  },
})(FormInputForm);
