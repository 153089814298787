import { TaskActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
function task(state = {}, action) {
  switch (action.type) {
    case TaskActionTypes.GET_ALL_TASK_REQUEST:
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    case TaskActionTypes.GET_ALL_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        metaData: CommonUtils.pagination(action.data.meta),
        listTask: action.data.data || [],
        reload: false,
        type: "",
      };
    case TaskActionTypes.GET_ALL_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        listTask: [],
        type: "",
      };
    // remove weeklyPlan
    case TaskActionTypes.REMOVE_TASK_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case TaskActionTypes.REMOVE_TASK_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case TaskActionTypes.REMOVE_TASK_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case TaskActionTypes.GET_LIST_TASK_QUOTE_TAB_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TaskActionTypes.GET_LIST_TASK_QUOTE_TAB_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuoteTask: action.data,
      };
    case TaskActionTypes.GET_LIST_TASK_QUOTE_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        listQuoteTask: [],
      };
    case TaskActionTypes.GET_TASK_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TaskActionTypes.GET_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        task: action.data,
      };
    case TaskActionTypes.GET_TASK_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        task: {},
      };

    case TaskActionTypes.UPDATE_KADAI_SEIRI_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TaskActionTypes.UPDATE_KADAI_SEIRI_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        task: action.data,
      };
    case TaskActionTypes.UPDATE_KADAI_SEIRI_FAILURE:
      return {
        ...state,
        loading: false,
        task: {},
      };
    case TaskActionTypes.GET_MASTER_KADAI_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TaskActionTypes.GET_MASTER_KADAI_SUCCESS:
      return {
        ...state,
        loading: false,
        listMasterKadai: action.data,
      };
    case TaskActionTypes.GET_MASTER_KADAI_FAILURE:
      return {
        ...state,
        loading: false,
        listMasterKadai: [],
      };
    case TaskActionTypes.GET_TASK_BY_ID_QUOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TaskActionTypes.GET_TASK_BY_ID_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listTaskQuote: action.data.result,
      };
    case TaskActionTypes.GET_TASK_BY_ID_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listTaskQuote: {},
      };
    case TaskActionTypes.UPDATE_KADAI_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TaskActionTypes.UPDATE_KADAI_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        task: action.data,
      };
    case TaskActionTypes.UPDATE_KADAI_FAILURE:
      return {
        ...state,
        loading: false,
        task: {},
      };
    case TaskActionTypes.GET_LIST_TASK_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TaskActionTypes.GET_LIST_TASK_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        taskIds: action.data,
      };
    case TaskActionTypes.GET_LIST_TASK_ID_FAILURE:
      return {
        ...state,
        loading: false,
        task: {},
      };
    case TaskActionTypes.NEW_PAGE:
      return {
        ...state,
        task: {},
      }

    default: {
      return { ...state };
    }
  }
}

export default task;
