export const Label = {
  Plan1: {
    Title: "計画書１",
  },
  Plan2: {
    Title: "計画書２",
  },
  WeeklyPlan: {
    Title: "週間",
  },
  UseSlip: {
    Title: "利用票",
  },
  Monitoring: {
    Title: "モニタリング",
  },
  Meeting: {
    Title: "担当者会議",
  },
  InquiryRequest: {
    Title: "照会依頼",
  },
  Progress: {
    Title: "居宅経過記録",
  },
  Task: {
    Title: "課題",
  },
  Facesheet: {
    Title: "フェイスシート",
  },
  KihonCheck: {
    Title: "基本チェック",
  },
  YobousienPlan: {
    Title: "支援計画書",
  },
  Hyoukahyou: {
    Title: "評価表",
  },
  Account: {
    Username: "ログインIDを入力してください",
    Password: "パスワードを入力してください",
    SigninButton: "ログイン",
    Description: "居宅管理",
    Note1: "これは、プラスワン開発用テストサーバです",
    Note2: "予告なく再起動する場合がありますのでご了承ください",
  },
  General: {
    Sort: "並替",
    Cancel: "キャンセル",
    Search: "検索",
    LogoTitle: "居宅管理",
  },
  Header: {
    DropdownItem: {
      Profile: "ユーザ管理",
      Signout: "ログアウト",
    },
  },
  Dialog: {
    ConfirmSignout: {
      Title: "ログアウト確認",
      Question: "ログアウトします。よろしいですか？",
    },
  },
};
