import { Col, DatePicker, Modal, Pagination, Row, Table } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { loadKeikaKirokubyDate } from "../../../actions/keikaKirokuActions/actionCreators";
import LineBreakText from "../../../common/component/LineBreakText";
import { ConstSet } from "../../../common/configs/constset";
import SettingSelect from "../inputKeikaKiroku/UI/SettingSelect";
dayjs.extend(dayjsPluginUTC);

class KeikaKirokuModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.kirokuDate
        ? props.kirokuDate
        : dayjs.utc(new Date(), ConstSet.DATE_FORMAT).subtract(1, "M"),
      endDate: props.kirokuDate
        ? props.kirokuDate
        : dayjs.utc(new Date(), ConstSet.DATE_FORMAT),
      kirokuKubun: null,
      page: 1,
      selectedRecord: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.props.loadKeikaKirokubyDate({
        riyousyaId: this.props.riyousyaId,
        page: this.state.page,
        startDate:
          typeof this.state.startDate === "string"
            ? dayjs(this.state.startDate).format(ConstSet.DATE_FORMAT)
            : this.state.startDate.format(ConstSet.DATE_FORMAT),
        endDate:
          typeof this.state.endDate === "string"
            ? dayjs(this.state.endDate).format(ConstSet.DATE_FORMAT)
            : this.state.endDate.format(ConstSet.DATE_FORMAT),
        kirokuKubun: this.state.kirokuKubun,
      });
    }
    if (
      this.props.kirokuDate &&
      this.props.kirokuDate !== prevProps.kirokuDate
    ) {
      this.setState({
        startDate: dayjs(this.props.kirokuDate, ConstSet.DATE_FORMAT).subtract(
          1,
          "M"
        ),
        endDate: dayjs(this.props.kirokuDate, ConstSet.DATE_FORMAT),
      });
    }
  }

  handleOk() {
    const { selectedRecord } = this.state;

    if (selectedRecord && selectedRecord.id) {
      this.props.onSelectItem(selectedRecord);
    }
    this.props.onHide();
  }

  handleCancel() {
    this.props.onHide();
  }

  handlePageChange(page) {
    this.setState({ page });
    this.props.loadKeikaKirokubyDate({
      riyousyaId: this.props.riyousyaId,
      page,
      startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
      endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
      kirokuKubun: this.state.kirokuKubun,
    });
  }

  handleStartDateChange(startDate) {
    this.setState({ startDate, page: 1 });
    if (this.state.endDate) {
      this.props.loadKeikaKirokubyDate({
        riyousyaId: this.props.riyousyaId,
        page: 1,
        startDate: startDate.format(ConstSet.DATE_FORMAT),
        endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
        kirokuKubun: this.state.kirokuKubun,
      });
    }
  }

  handleEndDateChange(endDate) {
    this.setState({ endDate, page: 1 });
    if (this.state.startDate) {
      this.props.loadKeikaKirokubyDate({
        riyousyaId: this.props.riyousyaId,
        page: 1,
        startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
        endDate: endDate.format(ConstSet.DATE_FORMAT),
        kirokuKubun: this.state.kirokuKubun,
      });
    }
  }

  handleChangeKubun(value) {
    this.setState({ kirokuKubun: value, page: 1 });
    this.props.loadKeikaKirokubyDate({
      riyousyaId: this.props.riyousyaId,
      page: 1,
      startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
      endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
      kirokuKubun: value,
    });
  }

  handleSelectRow(record) {
    this.setState({ selectedRecord: record });
  }

  handleRangePickerChange(date) {
    const [startDate, endDate] = date;

    this.setState({
      startDate,
      endDate,
      page: 1,
    });

    this.props.loadKeikaKirokubyDate({
      riyousyaId: this.props.riyousyaId,
      page: 1,
      startDate: startDate.format(ConstSet.DATE_FORMAT),
      endDate: endDate.format(ConstSet.DATE_FORMAT),
      kirokuKubun: this.state.kirokuKubun,
    });
  }

  render() {
    const { selectedRecord } = this.state;
    const { keikaKirokusByDate, metaKeikaKirokuByDate, kirokuKubuns } =
      this.props;
    const columns = [
      {
        title: "記録日",
        dataIndex: "kirokuDate",
        key: "kirokuDate",
        width: 90,
      },
      {
        title: "内容",
        dataIndex: "naiyou",
        key: "naiyou",
        className: "table-cell-wordbreak",
        width: 200,
        render: (text) => <LineBreakText text={text} />,
      },
      {
        title: "判断",
        dataIndex: "sakuseisyaHandan",
        key: "sakuseisyaHandan",
        className: "table-cell-wordbreak",
        width: 100,
        render: (text) => <LineBreakText text={text} />,
      },
      {
        title: "考え方",
        dataIndex: "riyousyaKangae",
        key: "riyousyaKangae",
        className: "table-cell-wordbreak",
        width: 100,
        render: (text) => <LineBreakText text={text} />,
      },
    ];
    const pagingOptions = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: metaKeikaKirokuByDate && metaKeikaKirokuByDate.total,
      current: metaKeikaKirokuByDate && metaKeikaKirokuByDate.page,
      pageSize: metaKeikaKirokuByDate && metaKeikaKirokuByDate.pageSize,
    };

    return (
      (<Modal
        open={this.props.visible}
        title="経過記録"
        onOk={() => this.handleOk()}
        onCancel={() => this.handleCancel()}
        className="keika-kiroku-modal"
      >
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) =>
            matches ? (
              <Row>
                <Col span={24} style={{ marginBottom: 5, display: "flex" }}>
                  <div>
                    <span className="text-bold" style={{ marginRight: 10 }}>
                      記録日
                    </span>
                    <DatePicker.RangePicker
                      value={[
                        typeof this.state.startDate === "string"
                          ? dayjs(this.state.startDate)
                          : this.state.startDate,
                        typeof this.state.endDate === "string"
                          ? dayjs(this.state.endDate)
                          : this.state.endDate,
                      ]}
                      onChange={(date, dateString) =>
                        this.handleRangePickerChange(date, dateString)
                      }
                    />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <span className="text-bold" style={{ marginRight: 10 }}>
                      分類指定
                    </span>
                    <SettingSelect
                      style={{ display: "inline-block" }}
                      containerId="input-keika-kiroku-page"
                      data={kirokuKubuns}
                      onChange={(value) => this.handleChangeKubun(value)}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <Table
                    style={{ cursor: "pointer" }}
                    size={"small"}
                    rowKey={"id"}
                    bordered={true}
                    columns={columns}
                    dataSource={keikaKirokusByDate}
                    pagination={pagingOptions}
                    onRow={(record) => ({
                      onClick: () => this.handleSelectRow(record),
                    })}
                    rowClassName={(record) =>
                      selectedRecord != null && record.id === selectedRecord.id
                        ? "pljp-table-row-hover"
                        : "pointer-row"
                    }
                  />
                </Col>
              </Row>
            ) : (
              <Fragment>
                <div>
                  <Row>
                    <Col span={24} style={{ marginBottom: 20 }}>
                      <div>
                        <p className="text-bold" style={{ marginBottom: 0 }}>
                          記録日
                        </p>
                        <DatePicker
                          value={
                            typeof this.state.startDate === "string"
                              ? dayjs(this.state.startDate)
                              : this.state.startDate
                          }
                          onChange={(date) => this.handleStartDateChange(date)}
                        />
                        <DatePicker
                          value={
                            typeof this.state.endDate === "string"
                              ? dayjs(this.state.endDate)
                              : this.state.endDate
                          }
                          onChange={(date) => this.handleEndDateChange(date)}
                        />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <p className="text-bold" style={{ marginBottom: 0 }}>
                          分類指定
                        </p>
                        <SettingSelect
                          style={{ display: "inline-block" }}
                          containerId="input-keika-kiroku-page"
                          data={kirokuKubuns}
                          onChange={(value) => this.handleChangeKubun(value)}
                        />
                      </div>
                    </Col>
                  </Row>
                  {Array.isArray(keikaKirokusByDate) &&
                    keikaKirokusByDate.map((item, index) => (
                      <Row
                        key={index}
                        className="keika-kiroku__item"
                        onClick={() => this.handleSelectRow(item)}
                      >
                        <Col span={24}>
                          <span className="text-bold">記録日</span>
                          <span className="keika-kiroku__value">
                            {item.kirokuDate}
                          </span>
                        </Col>
                        <Col span={24}>
                          <span className="text-bold">内容</span>
                          <span className="keika-kiroku__value">
                            {<LineBreakText text={item.naiyou} />}
                          </span>
                        </Col>
                        <Col span={24}>
                          <span className="text-bold">判断</span>
                          <span className="keika-kiroku__value">
                            {<LineBreakText text={item.sakuseisyaHandan} />}
                          </span>
                        </Col>
                        <Col span={24}>
                          <span className="text-bold">考え方</span>
                          <span className="keika-kiroku__value">
                            {<LineBreakText text={item.riyousyaKangae} />}
                          </span>
                        </Col>
                      </Row>
                    ))}
                </div>
                <div style={{ textAlign: "right" }}>
                  <Pagination {...pagingOptions} />
                </div>
              </Fragment>
            )
          }
        </MediaQuery>
      </Modal>)
    );
  }
}

const mapStateToProps = () => (state) => ({
  keikaKirokusByDate: state.keikaKiroku.keikaKirokusByDate,
  metaKeikaKirokuByDate: state.keikaKiroku.metaKeikaKirokuByDate,
});

const mapDispatchToProps = () => (dispatch) => ({
  loadKeikaKirokubyDate: (data) => dispatch(loadKeikaKirokubyDate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeikaKirokuModal);
