export const LOAD_REPORT_CASE_SUCCESS = "LOAD_REPORT_CASE_SUCCESS";
export const LOAD_MOSHIOKURI_SUCCESS = "LOAD_MOSHIOKURI_SUCCESS";
export const CREATE_REPORT_CASE_SUCCESS = "CREATE_REPORT_CASE_SUCCESS";
export const UPDATE_REPORT_CASE_SUCCESS = "UPDATE_REPORT_CASE_SUCCESS";
export const UPDATE_KAKUNIN_COMMENT_SUCCESS = "UPDATE_KAKUNIN_COMMENT_SUCCESS";
export const DELETE_REPORT_CASE_SUCCESS = "DELETE_REPORT_CASE_SUCCESS";
export const GET_REPORT_CASE_BY_ID_SUCCESS = "GET_REPORT_CASE_BY_ID_SUCCESS";

export const LOAD_FILTER_MASTER_SUCCESS = "LOAD_FILTER_MASTER_SUCCESS";
export const SORT_RIYOUSYA_SUCCESS = "SORT_RIYOUSYA_SUCCESS";

export const GET_OPEN_SCREEN_LOG = "GET_OPEN_SCREEN_LOG";
export const SAVE_OPEN_SCREEN_LOG = "SAVE_OPEN_SCREEN_LOG";
export const GET_RIYOUSYA_SUCCESS = "GET_RIYOUSYA_SUCCESS";
