import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";

const KeikaKirokuApi = {
  async loadKeikaKiroku(params) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.GET_KEIKA_KIROKU, {
        params: {
          sid: menu.si,
          systemName: menu.sn,
          riyousyaId: params.riyousyaId,
          page: params.page,
          sort: params.sort,
        },
      });
    return res.data;
  },
  async loadKeikaKirokuByDate(params) {
    const key = getMenu();
    const menu = JSON.parse(localStorage.getItem(key));
    const res = await axios
      .get(apiUrls.LOAD_KEIKA_KIROKU_BY_DATE, {
        params: {
          // sid: 1, // for test
          // riyousyaId: 296, // for test
          sid: menu.si,
          riyousyaId: params.riyousyaId,
          page: params.page,
          startDate: params.startDate,
          endDate: params.endDate,
          kirokuKubun: params.kirokuKubun,
        },
      });
    return res.data;
  },
  async deleteKeikaKiroku(keikaKirokuId) {
    const res = await axios
      .delete(apiUrls.DELETE_KEIKA_KIROKU, {
        params: {
          id: keikaKirokuId,
        },
      });
    return res.data;
  },
  async loadKeikaKirokuById(keikaKirokuId) {
    const res = await axios
      .get(apiUrls.GET_KEIKA_KIROKU_BY_ID, {
        params: { id: keikaKirokuId },
      });
    return res.data;
  },
  async createKeikaKiroku(data, riyousyaId) {
    const key = getMenu();
    const sid = JSON.parse(localStorage.getItem(key)).si;
    const res = await axios
      .post(apiUrls.CREATE_KEIKA_KIROKU, {
        riyousya_id: riyousyaId ? parseInt(riyousyaId) : null,
        service_kikan_master_id: sid ? parseInt(sid) : null,
        report_case: {
          riyousyaId: riyousyaId ? parseInt(riyousyaId) : null,
          service_kikan_master_id: sid ? parseInt(sid) : null,
          tanto_id: data.kiroku_tanto_id
            ? parseInt(data.kiroku_tanto_id)
            : null,
          date: data.kiroku_date,
          time: data.kiroku_time,
          kiroku_kubun: data.kiroku_kubun,
          naiyou: data.naiyou,
          sonota_kubun: data.soudan_naiyou,
          caremaneF: 1,
        },
        ...data,
      });
    return res.data;
  },
  async updateKeikaKiroku(data, keikaKirokuId) {
    const key = getMenu();
    const sid = JSON.parse(localStorage.getItem(key)).si;
    const payload = {
      keikaKiroku_id: keikaKirokuId ? parseInt(keikaKirokuId) : null,
      riyousya_id: data.riyousyaId ? parseInt(data.riyousyaId) : null,
      service_kikan_master_id: sid ? parseInt(sid) : null,
      plan_tanto_id: data.plan_tanto_id ? parseInt(data.plan_tanto_id) : null,
      kiroku_tanto_id: data.kiroku_tanto_id
        ? parseInt(data.kiroku_tanto_id)
        : null,
      kiroku_date: data.kiroku_date,
      kiroku_time: data.kiroku_time,
      houmon_date: data.houmon_date,
      kiroku_kubun: data.kiroku_kubun,
      soudan_naiyou: data.soudan_naiyou,
      naiyou: data.naiyou,
      sakuseisya_handan: data.sakuseisya_handan,
      riyousya_kangae: data.riyousya_kangae,
      report_case: {
        riyousyaId: data.riyousyaId ? parseInt(data.riyousyaId) : null,
        service_kikan_master_id: sid ? parseInt(sid) : null,
        tanto_id: data.kiroku_tanto_id ? parseInt(data.kiroku_tanto_id) : null,
        date: data.kiroku_date,
        time: data.kiroku_time,
        kiroku_kubun: data.kiroku_kubun,
        naiyou: data.naiyou,
        sonota_kubun: data.soudan_naiyou,
        caremaneF: 1,
      },
    };
    const res = await axios
      .post(apiUrls.UPDATE_KEIKA_KIROKU, payload);
    return res.data;
  },
};
export { KeikaKirokuApi };
