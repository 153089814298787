/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Table, Button, Row, Col, Select } from "antd";
import { IryoKougakuFutanMasterActions } from "../../../actions/iryoKougakuFutanMasterActions/actionCreators";

class IryoKougakuFutanMasterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecord: null,
      search: "70歳未満",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.props.getList("70歳未満");
    }
  }

  handleOk = () => {
    this.props.onHide();
    const { selectedRecord } = this.state;

    if (selectedRecord && selectedRecord.id) {
      this.props.onSelect(selectedRecord);
    }
    this.setState({
      selectedRecord: {},
      search: "",
    });
  };

  handleCancel = () => {
    this.props.onHide();
    this.setState({
      selectedRecord: {},
      search: "",
    });
  };

  handleSelectRow(record) {
    this.setState({ selectedRecord: record });
  }

  handleSearchInput(value) {
    this.setState({
      search: value,
    });

    this.props.getList(value);
  }

  search() {
    this.props.getList(this.state.search);
  }

  isDisableButton(selected) {
    return selected && this.props.iryouhokenMasterList
      ? !this.props.iryouhokenMasterList.some((item) => item.id == selected.id)
      : true;
  }

  handleClose = () => {
    this.props.getList(this.state.search);

    this.setState({
      visibleEdit: false,
      editType: null,
    });
  };

  render() {
    const columns = [
      {
        title: "分類",
        dataIndex: "item",
        key: "item",
        width: "35%",
      },
      {
        title: "負担額",
        dataIndex: "futangaku",
        key: "futangaku",
        width: "65%",
      },
    ];

    const { selectedRecord } = this.state;

    const title = (
      <div>
        <Row style={{ marginBottom: "-10px" }}>
          <Col span={6} style={{ marginTop: 5 }}>
            上限負担額
          </Col>
          <Col span={18} style={{ display: "flex" }}>
            <Select
              value={this.state.search}
              onChange={(value) => this.handleSearchInput(value)}
              style={{ width: 150 }}
            >
              <Select.Option value={"70歳未満"}>70歳未満</Select.Option>
              <Select.Option value={"前期高齢者"}>前期高齢者</Select.Option>
              <Select.Option value={"後期高齢者"}>後期高齢者</Select.Option>
            </Select>
          </Col>
        </Row>
      </div>
    );

    return (
      (<div>
        <Modal
          open={this.props.visible}
          title={title}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="tanto-doctor-master-modal modal-list"
          closable={false}
          footer={[
            <Button key="back" onClick={() => this.handleCancel()}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary" onClick={() => this.handleOk()}>
              OK
            </Button>,
          ]}
        >
          {/* <Spin spinning={this.props.loading}> */}
          <Table
            rowKey="id"
            style={{ cursor: "pointer", marginTop: 5, marginBottom: -30 }}
            size={"small"}
            bordered={true}
            columns={columns}
            dataSource={this.props.iryoKougakuFutanMasterList}
            pagination={{ pageSize: 20 }}
            scroll={{ y: 300 }}
            onRow={(record) => ({
              onClick: () => this.handleSelectRow(record),
            })}
            rowClassName={(record) =>
              selectedRecord != null && record.id === selectedRecord.id
                ? "pljp-table-row-hover"
                : "pointer-row"
            }
          />
          {/* </Spin> */}
        </Modal>
      </div>)
    );
  }
}

IryoKougakuFutanMasterModal.propTypes = {
  visible: PropTypes.bool,
  onSelect: PropTypes.func,
  onHide: PropTypes.func,
  getList: PropTypes.func,
};

const mapStateToProps = () => (state) => ({
  loading: state.iryoKougakuFutanMaster.loading,
  iryoKougakuFutanMasterList: state.iryoKougakuFutanMaster.datas,
});

const mapDispatchToProps = () => (dispatch) => ({
  getList: (string) => dispatch(IryoKougakuFutanMasterActions.getList(string)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IryoKougakuFutanMasterModal);
