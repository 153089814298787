/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  messages: [],
};

export default function kokyakuReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state;
  }
}
