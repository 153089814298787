export const ItakuTantoMasterActionTypes = {
  GET_ITAKU_TANTO_BY_ID_REQUEST: "GET_ITAKU_TANTO_BY_ID_REQUEST",
  GET_ITAKU_TANTO_BY_ID_SUCCESS: "GET_ITAKU_TANTO_BY_ID_SUCCESS",
  GET_ITAKU_TANTO_BY_ID_FAILURE: "GET_ITAKU_TANTO_BY_ID_FAILURE",

  GET_INITIAL_ITAKU_TANTO_REQUEST: "GET_INITIAL_ITAKU_TANTO_REQUEST",
  GET_INITIAL_ITAKU_TANTO_SUCCESS: "GET_INITIAL_ITAKU_TANTO_SUCCESS",
  GET_INITIAL_ITAKU_TANTO_FAILURE: "GET_INITIAL_ITAKU_TANTO_FAILURE",

  CREATE_ITAKU_TANTO_REQUEST: "CREATE_ITAKU_TANTO_REQUEST",
  CREATE_ITAKU_TANTO_SUCCESS: "CREATE_ITAKU_TANTO_SUCCESS",
  CREATE_ITAKU_TANTO_FAILURE: "CREATE_ITAKU_TANTO_FAILURE",

  UPDATE_ITAKU_TANTO_REQUEST: "UPDATE_ITAKU_TANTO_REQUEST",
  UPDATE_ITAKU_TANTO_SUCCESS: "UPDATE_ITAKU_TANTO_SUCCESS",
  UPDATE_ITAKU_TANTO_FAILURE: "UPDATE_ITAKU_TANTO_FAILURE",

  GET_SERVICE_SYURUI_MASTERS_REQUEST: "GET_SERVICE_SYURUI_MASTERS_REQUEST",
  GET_SERVICE_SYURUI_MASTERS_SUCCESS: "GET_SERVICE_SYURUI_MASTERS_SUCCESS",
  GET_SERVICE_SYURUI_MASTERS_FAILURE: "GET_SERVICE_SYURUI_MASTERS_FAILURE",

  GET_SYURUI_CD_BY_SERVICE_TYPE_REQUEST:
    "GET_SYURUI_CD_BY_SERVICE_TYPE_REQUEST",
  GET_SYURUI_CD_BY_SERVICE_TYPE_SUCCESS:
    "GET_SYURUI_CD_BY_SERVICE_TYPE_SUCCESS",
  GET_SYURUI_CD_BY_SERVICE_TYPE_FAILURE:
    "GET_SYURUI_CD_BY_SERVICE_TYPE_FAILURE",

  GET_SERVICE_ADDITION_BY_SYURUI_REQUEST:
    "GET_SERVICE_ADDITION_BY_SYURUI_REQUEST",
  GET_SERVICE_ADDITION_BY_SYURUI_SUCCESS:
    "GET_SERVICE_ADDITION_BY_SYURUI_SUCCESS",
  GET_SERVICE_ADDITION_BY_SYURUI_FAILURE:
    "GET_SERVICE_ADDITION_BY_SYURUI_FAILURE",

  SEARCH_HOJIN_INFO_MASTERS_REQUEST: "SEARCH_HOJIN_INFO_MASTERS_REQUEST",
  SEARCH_HOJIN_INFO_MASTERS_SUCCESS: "SEARCH_HOJIN_INFO_MASTERS_SUCCESS",
  SEARCH_HOJIN_INFO_MASTERS_FAILURE: "SEARCH_HOJIN_INFO_MASTERS_FAILURE",

  CHECK_LINKED_WEEKLY_REQUEST: "CHECK_LINKED_WEEKLY_REQUEST",
  CHECK_LINKED_WEEKLY_SUCCESS: "CHECK_LINKED_WEEKLY_SUCCESS",
  CHECK_LINKED_WEEKLY_FAILURE: "CHECK_LINKED_WEEKLY_FAILURE",

  CREATE_HOJIN_INFO_MASTER_REQUEST: "CREATE_HOJIN_INFO_MASTER_REQUEST",
  CREATE_HOJIN_INFO_MASTER_SUCCESS: "CREATE_HOJIN_INFO_MASTER_SUCCESS",
  CREATE_HOJIN_INFO_MASTER_FAILURE: "CREATE_HOJIN_INFO_MASTER_FAILURE",

  GET_HOJIN_INFO_MASTER_REQUEST: "GET_HOJIN_INFO_MASTER_REQUEST",
  GET_HOJIN_INFO_MASTER_SUCCESS: "GET_HOJIN_INFO_MASTER_SUCCESS",
  GET_HOJIN_INFO_MASTER_FAILURE: "GET_HOJIN_INFO_MASTER_FAILURE",

  GET_SERVICE_MONTHLY_BY_ID_REQUEST: "GET_SERVICE_MONTHLY_BY_ID_REQUEST",
  GET_SERVICE_MONTHLY_BY_ID_SUCCESS: "GET_SERVICE_MONTHLY_BY_ID_SUCCESS",
  GET_SERVICE_MONTHLY_BY_ID_FAILURE: "GET_SERVICE_MONTHLY_BY_ID_FAILURE",

  CREATE_SERVICE_MONTHLY_REQUEST: "CREATE_SERVICE_MONTHLY_REQUEST",
  CREATE_SERVICE_MONTHLY_SUCCESS: "CREATE_SERVICE_MONTHLY_SUCCESS",
  CREATE_SERVICE_MONTHLY_FAILURE: "CREATE_SERVICE_MONTHLY_FAILURE",

  UPDATE_SERVICE_MONTHLY_REQUEST: "UPDATE_SERVICE_MONTHLY_REQUEST",
  UPDATE_SERVICE_MONTHLY_SUCCESS: "UPDATE_SERVICE_MONTHLY_SUCCESS",
  UPDATE_SERVICE_MONTHLY_FAILURE: "UPDATE_SERVICE_MONTHLY_FAILURE",

  DELETE_SERVICE_MONTHLY_REQUEST: "DELETE_SERVICE_MONTHLY_REQUEST",
  DELETE_SERVICE_MONTHLY_SUCCESS: "DELETE_SERVICE_MONTHLY_SUCCESS",
  DELETE_SERVICE_MONTHLY_FAILURE: "DELETE_SERVICE_MONTHLY_FAILURE",

  GET_LIST_SERVICE_MONTHLY_REQUEST: "GET_LIST_SERVICE_MONTHLY_REQUEST",
  GET_LIST_SERVICE_MONTHLY_SUCCESS: "GET_LIST_SERVICE_MONTHLY_SUCCESS",
  GET_LIST_SERVICE_MONTHLY_FAILURE: "GET_LIST_SERVICE_MONTHLY_FAILURE",

  GET_TAISEI_MASTERS_REQUEST: "GET_TAISEI_MASTERS_REQUEST",
  GET_TAISEI_MASTERS_SUCCESS: "GET_TAISEI_MASTERS_SUCCESS",
  GET_TAISEI_MASTERS_FAILURE: "GET_TAISEI_MASTERS_FAILURE",

  GET_TIKIBETU_TIKIKUBUN_REQUEST: "GET_TIKIBETU_TIKIKUBUN_REQUEST",
  GET_TIKIBETU_TIKIKUBUN_SUCCESS: "GET_TIKIBETU_TIKIKUBUN_SUCCESS",
  GET_TIKIBETU_TIKIKUBUN_FAILURE: "GET_TIKIBETU_TIKIKUBUN_FAILURE",

  GET_TIKI_KUBUN_MASTER_REQUEST: "GET_TIKI_KUBUN_MASTER_REQUEST",
  GET_TIKI_KUBUN_MASTER_SUCCESS: "GET_TIKI_KUBUN_MASTER_SUCCESS",
  GET_TIKI_KUBUN_MASTER_FAILURE: "GET_TIKI_KUBUN_MASTER_FAILURE",

  GET_ITAKU_TANTO_BY_SERVICE_TYPE_REQUEST:
    "GET_ITAKU_TANTO_BY_SERVICE_TYPE_REQUEST",
  GET_ITAKU_TANTO_BY_SERVICE_TYPE_SUCCESS:
    "GET_ITAKU_TANTO_BY_SERVICE_TYPE_SUCCESS",
  GET_ITAKU_TANTO_BY_SERVICE_TYPE_FAILURE:
    "GET_ITAKU_TANTO_BY_SERVICE_TYPE_FAILURE",

  GET_ITAKU_TANTO_BY_SYURUI_CD_REQUEST: "GET_ITAKU_TANTO_BY_SYURUI_CD_REQUEST",
  GET_ITAKU_TANTO_BY_SYURUI_CD_SUCCESS: "GET_ITAKU_TANTO_BY_SYURUI_CD_SUCCESS",
  GET_ITAKU_TANTO_BY_SYURUI_CD_FAILURE: "GET_ITAKU_TANTO_BY_SYURUI_CD_FAILURE",

  SAVE_REQUEST: "SAVE_REQUEST",
  SAVE_SUCCESS: "SAVE_SUCCESS",
  SAVE_FAILURE: "SAVE_FAILURE",

  GET_MAX_SORT_NUM_REQUEST: "GET_MAX_SORT_NUM_REQUEST",
  GET_MAX_SORT_NUM_SUCCESS: "GET_MAX_SORT_NUM_SUCCESS",
  GET_MAX_SORT_NUM_FAILURE: "GET_MAX_SORT_NUM_FAILURE",

  TURN_OFF_RELOAD_SERVICE_KIKAN: "TURN_OFF_RELOAD_SERVICE_KIKAN",
};
