/* eslint-disable no-nested-ternary */
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleTwoTone,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Button, Drawer, Modal, Popconfirm, Spin, Table, Tooltip } from "antd";
import React from "react";
import MediaQuery from "react-responsive";
import { ConstSet } from "../../../common/configs/constset";
import TokkiForm from "../../../common/form-kiroku/TokkiForm";
import {
  checkTableField,
  exRiyousya,
  getDateInMonth,
  getMenu,
  getValueLocalstorage,
} from "../../../common/function_common/functionCommon";
import CheckKB from "../../../common/model-kiroku/CheckKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import TantoMasterKB2 from "../../../common/model-kiroku/TantoMasterKB2";
import TimeKB from "../../../common/model-kiroku/TimeKB";
import TableRowButton from "./TableRowButton";

const expandedRowRender = (record) => (
  <p style={{ marginBottom: "0em" }}>{record.description}</p>
);
const showHeader = true;
const pagination = { position: "bottom" };

export default class Tokki extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Table Setting
      bordered: true,
      loading: false,
      pagination,
      size: "small",
      expandedRowRender,
      title: undefined,
      showHeader,
      footer: undefined,
      scroll: undefined,
      hasData: true,
      expandIconAsCell: false,
      expandIconColumnIndex: -1,
      rowActive: null,

      expandRowClick: [],
      columns: [],
      activeListIndex: null,
      activeRowKey: null,
      shubetsuTitle: "",

      // edit add function
      inputMode: this.props.inputMode,
      showDrawer: false,
      visibleMInput: false,
      modalType: null,

      riyousyaName: null,
      riyousyaId: null,
      date: null,
      time: null,
      record: null,
      indexRecord: 0,

      inputType: null,
      inputField: null,
      inputValue: null,

      field: "",
      isZendkaiDis: false,
      suggestValues: "",

      mulInputData: {
        tokkiIds: null,
        time: null,
        stapleIntake: null,
        sideIntake: null,
        shokuNasi: null,
        comment: null,
        tantoId: null,
      },
      mulInputCheckbox: [false, false, false, false, false, false],
      mulInputNotification: "",

      formData: null,
      isFormUpdate: false,

      // handle for active OK SAVE Button when user start input value
      okSaveButton: true,
      innerHeightTable: window.innerWidth < 1280 ? window.innerHeight - 420 : window.innerHeight - 380,
    };

    // Copy, Del local, Del Server record
    this.handleCopyTokki = this.handleCopyTokki.bind(this);
    this.handleDelTokki = this.handleDelTokki.bind(this);
    this.handleServerDel = this.handleServerDel.bind(this);

    /**
     * Add, Edit by Modal
     */
    this.handleMInput = this.handleMInput.bind(this);
    this.handleMInputCancel = this.handleMInputCancel.bind(this);

    /**
     * Add, Edit by Modal
     */
    this.preInput = this.preInput.bind(this);
    this.upperInput = this.upperInput.bind(this);
    this.lowerInput = this.lowerInput.bind(this);
    this.nextInput = this.nextInput.bind(this);

    this.getHandleHourInput = this.getHandleHourInput.bind(this);
    this.getHandleMinuteLeftInput = this.getHandleMinuteLeftInput.bind(this);
    this.getHandleMinuteInput = this.getHandleMinuteInput.bind(this);
    this.getHandleReportCaseTyuif = this.getHandleReportCaseTyuif.bind(this);

    this.getHandleInputComment = this.getHandleInputComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);

    this.handleOkUpdate = this.handleOkUpdate.bind(this);
    // END

    // add, edit Setting Comment
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  /**
   *
   * @param {*} oldProps
   */
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    const { nyutaisho } = this.props;

    // case update tokkiId when insert value to DOM
    if (oldProps.nyutaisho !== newProps.nyutaisho && this.state.record) {
      this.setState({
        record: nyutaisho[this.state.record.index],
      });
    }
  }

  /**
   *
   * @param {*} key
   * @param {*} index
   * @param {*} event
   */
  onRowClick(key) {
    // Setting active row key
    this.setState({
      rowActive: key,
    });

    const keys = [];
    if (key) {
      keys.push(key); // I have set my record.id as row key. Check the documentation for more details.
    }

    if (key === this.state.activeRowKey) {
      this.setState({
        expandRowClick: [],
        activeRowKey: null,
      });
    }
    else {
      this.setState({
        expandRowClick: [key],
        activeRowKey: key,
      });
    }
  }

  /**
   *
   * @param {*} tokkiRecord
   */
  handleCopyTokki(record) {
    // Clone data
    const recordCopy = {
      // basic
      riyousyaId: record.riyousyaId,
      nyuType: record.nyuType,
      date: record.date,

      // common
      time: record.time,
      bunrui: record.bunrui,

      // detail
      kubun: record.kubun,

      // tab info. that have been describe in requirement
      reportCaseTyuif: record.reportCaseTyuif,

      // commone flag field on local
      index: record.index,
      modeRecord: "copy",
    };

    this.props.handleCopyTokki(recordCopy);
  }

  /**
   *
   * @param {*} tokkiRecord
   */
  handleDelTokki(tokkiRecord) {
    this.props.handleDelTokki(tokkiRecord);
  }

  /**
   * Delete record from server
   * @param {*} tokkiId
   */
  handleServerDel(tokkiId) {
    this.props.deleteRecord(tokkiId);
  }

  /**
   * Edit, Update function with single field
   *
   */

  /**
   * function handle when click to edit field
   *
   * @param {int} MType // display modal type corresponding
   * @param {*} report // record for row
   * @param {*} index // index of row
   */
  handleMInput(MType, record, index) {
    const extractCurrent = checkTableField(MType, record);

    let okSaveButton = true;
    if (MType === ConstSet.MODAL_T_COMMENT) {
      okSaveButton = false;
    }

    // Convert Type to Parameter
    const riyousyaInfo = exRiyousya(record.riyousyaId);

    this.setState({
      rowActive: index,
      expandRowClick: [],

      visibleMInput: true,
      modalType: MType,

      riyousyaName: riyousyaInfo.riyousyaName,
      riyousyaId: riyousyaInfo.riyousyaId,
      date: record.date,
      time: record.time ? record.time : "00:00",
      record,
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  }

  /**
   * (2)
   */
  handleMInputCancel() {
    this.setState({
      visibleMInput: false,
    });
  }

  /**
   * (3.1) Pre field <--
   */
  preInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    let modalType = this.state.modalType;

    if (
      modalType > ConstSet.MODAL_T_TIME &&
      modalType <= ConstSet.MODAL_T_TANTOMASTER
    ) {
      modalType = modalType - 1;
    }

    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_T_COMMENT) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    this.setState({
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.2) Pre field ^|
   */
  upperInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_T_COMMENT) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      rowActive: index,

      riyousyaName: nyutaisho[index].riyousyaName,
      riyousyaId: nyutaisho[index].riyousyaId,
      date: nyutaisho[index].date,
      time: nyutaisho[index].time ? nyutaisho[index].time : "00:00",
      record: nyutaisho[index],
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.3) Pre field _|
   */
  lowerInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_T_COMMENT) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      riyousyaName: nyutaisho[index].riyousyaName,
      riyousyaId: nyutaisho[index].riyousyaId,
      date: nyutaisho[index].date,
      time: nyutaisho[index].time ? nyutaisho[index].time : "00:00",
      record: nyutaisho[index],
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.4) Pre field -->
   */
  nextInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    let modalType = this.state.modalType;

    if (
      modalType >= ConstSet.MODAL_T_TIME &&
      modalType < ConstSet.MODAL_T_TANTOMASTER
    ) {
      modalType = modalType + 1;
    }

    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_T_COMMENT) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    this.setState({
      //
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * 時間ところ
   */

  handleSetCurrentTime = () => {
    const currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.setState({
      inputType: ConstSet.MODAL_T_TIME,
      inputValue: currentTime,
      inputField: "time",
      okSaveButton: false,
    });
  };

  /**
   * (1)
   * @param {(*)} value
   */
  getHandleHourInput(value) {
    let preData = this.state.inputValue;
    if (!preData) {
      preData = value + ":00";
    }
    else {
      preData = value + preData.substring(2, 5);
    }
    this.setState({
      inputType: ConstSet.MODAL_T_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (2)
   * @param {(*)} value
   */
  getHandleMinuteLeftInput(value) {
    let preData = this.state.inputValue;

    if (!preData) {
      preData = "00:" + value;
    }
    else {
      preData = preData.substring(0, 3) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_T_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (3)
   * @param {(*)} value
   */
  getHandleMinuteInput(value) {
    let preData = this.state.inputValue;
    if (!preData) {
      preData = "00:0" + value;
    }
    else {
      preData = preData.substring(0, 4) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_T_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }
  // 時間end

  /**
   * 申送
   * @param {*} value
   */
  getHandleReportCaseTyuif(value) {
    this.setState({
      inputType: ConstSet.MODAL_T_REPORTCASETYUIF,
      inputValue: value ? "" + 1 : "" + 0,
      inputField: "reportCaseTyuif",
      okSaveButton: false,
    });
  }

  /**
   * コメント
   * @param {*} value
   * @param {*} type
   */
  getHandleInputComment(value, type) {
    if (type && type === "select") {
      this.setState({
        inputType: ConstSet.MODAL_T_COMMENT,
        inputValue: this.state.inputValue
          ? this.state.inputValue + "、" + value
          : value,
        inputField: "tokki",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_T_COMMENT,
        inputValue: value,
        inputField: "tokki",
        okSaveButton: false,
      });
    }
  }

  /**
   * 担当者
   * @param {*} value
   */
  getHandleTantoMasterKB(value) {
    // let updateCurrentRecord = this.state.record;
    // updateCurrentRecord.tantoId = value;
    this.setState({
      inputType: ConstSet.MODAL_T_TANTOMASTER,
      inputValue: value,
      inputField: "tantoId",
      // record: updateCurrentRecord,
      okSaveButton: false,
    });
  }

  /**
   * OK UPDATE Button Click
   *
   * Save data
   */
  handleOkUpdate(type) {
    const checkTokkiId = this.state.record.tokkiId
      ? this.state.record.tokkiId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkTokkiId && !checkCopyType) {
      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        tokkiIds: checkTokkiId.toString(),
        index: this.state.record.index,
        date: this.state.record.date,
      };

      const updateField = this.state.inputValue;

      if (this.state.inputField && this.state.inputType) {
        if (this.state.inputType === ConstSet.MODAL_T_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        this.props.updateRecord(params);

        let okSaveButton = true;
        if (this.state.inputType === ConstSet.MODAL_T_COMMENT) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputValue: updateField,
          inputField: null,
          okSaveButton,
        });
      }
    } // POST
    else {
      const preTime = "00:00";

      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: this.state.record.index,
        riyousyaId: this.state.record.riyousyaId.toString(),
        date: this.state.record.date,
        time: this.state.record.time ? this.state.record.time : preTime,
        tantoId: getValueLocalstorage("tid"),
        kubunTokki: this.state.record.kubunTokki,
      };

      const updateField = this.state.inputValue;

      if (
        this.state.inputField &&
        this.state.inputValue &&
        this.state.inputType
      ) {
        if (this.state.inputType === ConstSet.MODAL_T_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        this.props.insertRecord(params);

        let okSaveButton = true;
        if (this.state.inputType === ConstSet.MODAL_T_COMMENT) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputValue: updateField,
          inputField: null,
          okSaveButton,
        });
      }
    }

    // close popup
    if (type !== true) {
      // save from next, pre, upper, lower not colse popup
      this.setState({
        visibleMInput: false,
      });
    }
  }
  // end Edit, Update function

  /**
   * Handle Edit, Update By Input From from right all Screen
   * ALl fields can be save on this Drawer
   *
   */

  /**
   * Form Inside Drawer
   * Show Form
   */
  showDrawer = (record) => {
    this.setState({
      rowActive: record.index,
      showDrawer: true,
      record,
      isFormUpdate: false,
    });
  };

  /**
   * Close Form
   */
  closeDrawer = () => {
    this.setState({
      showDrawer: false,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  upperForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  lowerForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   * Update file to State, Prepare data to save when click on 更新 button
   * @param {*} value
   * @param {*} type
   */
  getHandleFormInput = (value, type) => {
    const prepareData = this.state.record;
    const objData = {};
    objData[type] = value;

    if (type === "comment") {
      objData.tokki = objData.comment;
    }

    this.setState({
      record: Object.assign({}, prepareData, objData),
      isFormUpdate: true,
    });
  };

  /**
   * Save data action
   */
  handleOkUpdateForm = () => {
    const checkTokkiId = this.state.record.tokkiId
      ? this.state.record.tokkiId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkTokkiId && !checkCopyType) {
      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        tokkiIds: checkTokkiId.toString(),
        index: this.state.record.index,
        date: this.state.record.date,
      };

      params = Object.assign(params, this.state.record);

      params.tokki = params.comment;
      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.updateRecord(params);
    } // POST
    else {
      const preTime = "00:00";

      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: this.state.record.index,
        riyousyaId: this.state.record.riyousyaId,
        date: this.state.record.date,
        time: this.state.record.time ? this.state.record.time : preTime,
        tantoId: getValueLocalstorage("tid"),
      };

      params = Object.assign(params, this.state.record);

      params.tokki = params.comment;
      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.insertRecord(params);
    }

    // clear inputing mode
    this.setState({
      isFormUpdate: false,
    });
  };
  // END Handle Edit, Update By Input Form from right all Screen

  /**
   * Handle Add, Edit Setting Comment
   */
  /**
   * move to input state of table
   * @param {*} index
   */
  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  /**
   * Cancel Save
   * @param {*} index
   */
  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  /**
   * Save function
   * @param {*} naiyou
   */
  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }
  // END Handle Add, Edit Setting Comment

  /**
   * handle click cell event
   *
   * in mulInputMode, click do nothing
   * in input mode, base info cell click -> open expander data, edting cell -> display modal
   * in nothing mode -> open expander data
   *
   */
  onClickCellEvent = (recordIndex, type) => {
    const { nyutaisho, inputMode, mulInputMode } = this.props;
    const record = nyutaisho[recordIndex];

    switch (type) {
      case ConstSet.COL_SELECT:
        return;

      case ConstSet.COL_COPY:
        return;

      case ConstSet.COL_MODE:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_NAME:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ADDRESS:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ROOM:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_TOKKI_TIME:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_T_TIME,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_TOKKI_REPORTCASETYUIF:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_T_REPORTCASETYUIF,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_TOKKI_COMMENT:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_T_COMMENT,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_TOKKI_TANTOMASTER:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_T_TANTOMASTER,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      default:
        return;
    }
  };

  handChangePagination = (pagination) => {
    // reset 一括入力 mode
    const { arrCheckBox } = this.state;
    const preArrCheckBox = [];
    for (let i = 0; i < arrCheckBox.length; i++) {
      preArrCheckBox.push(0);
    }

    this.setState({
      tableCurrentPage: pagination.current,
      arrCheckBox: preArrCheckBox,
      isClickAll: false,
    });
  };

  render() {
    const state = this.state;
    const {
      nyutaisho,
      loading,
      inputMode,
      mulInputAllMode,
      tantoMaster,
      yougoMasterNa,
      isDisLongText,
    } = this.props;

    /**
     * handle date
     */
    const data = [];

    // colums情報を設定
    const columnsLong = [];

    if (inputMode) {
      columnsLong.push({
        title: "追加",
        dataIndex: "colCopy",
        key: "colCopy",
        width: 50,
        fixed: "left",
        className: "input-mode-fix action-col",
      });
    }
    else {
      columnsLong.push({
        title: "状況",
        dataIndex: "col1",
        key: "col1",
        width: 50,
        fixed: "left",
        className: `joutaiCol ${inputMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      });
    }

    columnsLong.push(
      {
        title: "日",
        dataIndex: "col2",
        key: "col2",
        width: 70,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "曜",
        dataIndex: "col3",
        key: "col3",
        width: 70,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "時間",
        dataIndex: "col5",
        key: "col5",
        width: 70,
        className: `timeCol ${inputMode || mulInputAllMode
          ? " input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_TOKKI_TIME);
            }, // click row
          };
        },
      },
      {
        title: "申送",
        dataIndex: "col6",
        key: "col6",
        width: 50,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(
                record.key,
                ConstSet.COL_TOKKI_REPORTCASETYUIF
              );
            }, // click row
          };
        },
      },
      {
        title: "コメント",
        dataIndex: "col7",
        key: "col7",
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_TOKKI_COMMENT);
            }, // click row
          };
        },
      },
      {
        title: "担当者",
        dataIndex: "col8",
        key: "col8",
        width: 120,
        className: `${inputMode ? "input-mode" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_TOKKI_TANTOMASTER);
            }, // click row
          };
        },
      }
    );

    if (inputMode) {
      columnsLong.push({
        title: "削除",
        dataIndex: "colDel",
        key: "colDel",
        width: 50,
        // fixed: 'right',
        className: "input-mode-fix action-col",
      });
    }

    // テーブルデータの準備
    if (nyutaisho) {
      for (let i = 0; i < nyutaisho.length; i++) {
        let color = "";
        if (nyutaisho[i].nyuType != "空") {
          if (nyutaisho[i].kubun === "入所") {
            color = "nuytaisho-yellow";
          }
          else if (nyutaisho[i].kubun === "ショート") {
            color = "nuytaisho-blue";
          }
        }

        let planType = "";
        if (nyutaisho[i].time && nyutaisho[i].planType === true) {
          planType = "bg_green";
        }
        else if (nyutaisho[i].time) {
          planType = "bg_pink";
        }

        data.push({
          key: i,
          colCopy: (
            <span className={"action-kiroku"}>
              <Button
                type={"primary"}
                ghost
                onClick={this.handleCopyTokki.bind(this, nyutaisho[i])}
              >
                <PlusOutlined />
              </Button>
            </span>
          ),
          col1: (
            <div className={`ntk-riyousya-status ${color} icon-table`}>
              {nyutaisho[i].nyuType ? (
                nyutaisho[i].nyuType == "空" ? (
                  ""
                ) : (
                  nyutaisho[i].nyuType
                )
              ) : (
                <ArrowDownOutlined />
              )}
            </div>
          ),
          col2:
            nyutaisho[i].modeRecord && nyutaisho[i].modeRecord === "copy" ? (
              <div style={{ color: "blue" }} className={"icon-table"}>
                {nyutaisho[i].date ? nyutaisho[i].date.split("-")[2] : ""}
              </div>
            ) : (
              <div className={"icon-table"}>
                {nyutaisho[i].date ? nyutaisho[i].date.split("-")[2] : ""}
              </div>
            ),
          col3: (
            <div
              className={`icon-table${nyutaisho[i].holidayClass ? nyutaisho[i].holidayClass : ""}`}
            >
              {nyutaisho[i].date
                ? getDateInMonth(
                  nyutaisho[i].date.split("-")[0],
                  nyutaisho[i].date.split("-")[1],
                  nyutaisho[i].date.split("-")[2]
                )
                : ""}
            </div>
          ),
          col5: (
            <div className={`icon-table ${planType}`}>
              {nyutaisho[i].time ? nyutaisho[i].time.substring(0, 5) : ""}
            </div>
          ),
          col6: (
            <div className={"icon-table"}>
              {nyutaisho[i].reportCaseTyuif ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                ""
              )}
            </div>
          ),
          col7: <div>{nyutaisho[i].tokki}</div>,
          col8:
            nyutaisho[i].tantoName && nyutaisho[i].tantoName.length > 6 ? (
              <Tooltip placement="bottomRight" title={nyutaisho[i].tantoName}>
                {nyutaisho[i].tantoName.substring(0, 4) + "..."}
              </Tooltip>
            ) : (
              <div className={"icon-table"}>
                {nyutaisho[i].tantoName ? nyutaisho[i].tantoName : ""}
              </div>
            ),
          colDel: (
            <span className={"action-kiroku"}>
              {nyutaisho[i].modeRecord || nyutaisho[i].tokkiId ? (
                !nyutaisho[i].modeRecord && nyutaisho[i].tokkiId ? (
                  <Popconfirm
                    overlayStyle={{ width: "210px" }}
                    title="削除してもいいですか？"
                    onConfirm={() => this.handleServerDel(nyutaisho[i].tokkiId)}
                  >
                    <span>
                      <Button type={"primary"} ghost>
                        <MinusOutlined />
                      </Button>
                    </span>
                  </Popconfirm>
                ) : (
                  <span>
                    <Button
                      type={"primary"}
                      ghost
                      onClick={this.handleDelTokki.bind(this, nyutaisho[i])}
                    >
                      <MinusOutlined />
                    </Button>
                  </span>
                )
              ) : null}
            </span>
          ),
          description: (
            <TableRowButton
              editRow={this.showDrawer.bind(this, nyutaisho[i])}
              type="user"
            />
          ),
        });
      }
    }

    return (
      (<div>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Spin spinning={loading}>
                    <Table
                      {...this.state}
                      showHeader={true}
                      expandIconAsCell={false}
                      expandIconColumnIndex={-1}
                      bordered={true}
                      size="small"
                      expandedRowKeys={this.state.expandRowClick}
                      pagination={{ position: "bottom", pageSize: 50 }}
                      scroll={{ x: 1400, y: this.state.innerHeightTable }}
                      className="table-kiroku-ri"
                      rowClassName={(record, index) =>
                        this.state.rowActive != null &&
                          index === this.state.rowActive
                          ? "pljp-table-row-hover"
                          : ""
                      }
                      // onRow={(record, index) => {
                      //     return {
                      //         onClick: (event) => {
                      //             this.onRowClick(record, index, event)
                      //         }
                      //     }
                      // }}

                      columns={columnsLong}
                      dataSource={state.hasData ? data : null}
                    />
                  </Spin>
                </div>
              );
            }
            return null;

          }}
        </MediaQuery>
        <Drawer
          destroyOnClose
          title={this.state.record ? this.state.record.riyousyaName : "\u00A0"}
          width={720}
          onClose={this.closeDrawer}
          open={this.state.showDrawer}
          closable={false}
          rootClassName="kiroku-drawer-form"
        >
          <TokkiForm
            tantoMaster={tantoMaster}
            data={this.state.record ? this.state.record : {}}
            handleFormInput={this.getHandleFormInput}
            yougoMasterEditState={this.getYougoMasterEditState}
            yougoMasterEditCancel={this.getYougoMasterEditCancel}
            yougoMasterEditSave={this.getYougoMasterEditSave}
            yougoMasterNa={yougoMasterNa}
          />
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left" }}>
              <Button onClick={this.upperForm} style={{ marginRight: 8 }}>
                <ArrowUpOutlined />
              </Button>
              <Button onClick={this.lowerForm}>
                <ArrowDownOutlined />
              </Button>
            </span>

            <span>
              <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
                戻る
              </Button>
              <Button type="primary" danger onClick={this.handleOkUpdateForm}>
                更新
              </Button>
            </span>
          </div>
        </Drawer>
        <Modal
          title={
            <div className="kim-header-title">
              <span>{this.state.riyousyaName}</span>
              <span>
                {this.state.time ? this.state.time.substring(0, 5) : ""}
              </span>
            </div>
          }
          open={this.state.visibleMInput}
          onOk={this.handleOkUpdate}
          onCancel={this.handleMInputCancel}
          className={"kiroku-input-modal"}
          footer={[
            <Button key="pre" onClick={this.preInput}>
              前項目
            </Button>,
            <Button key="next" onClick={this.nextInput}>
              次項目
            </Button>,
            <Button
              className="kim-btn-ok"
              key="submit"
              type="primary"
              onClick={this.handleOkUpdate}
              disabled={this.state.okSaveButton}
            >
              OK
            </Button>,
            <Button key="up" onClick={this.upperInput}>
              上の行
            </Button>,
            <Button key="dow" onClick={this.lowerInput}>
              下の行
            </Button>,
          ]}
          maskClosable={false}
        >
          <div className="kim-title">
            <span>{this.state.field}</span>
          </div>

          {this.state.isZendkaiDis ? (
            <div className={"model-top-row"}>
              <div className="kim-zenkai"></div>
              <div
                className={`kim-value ${this.state.field === "時間" ? "kim-value-time" : ""}`}
              >
                <div className="kim-value-top">
                  {this.state.inputValue ? this.state.inputValue : "\u00A0"}
                </div>
                {this.state.field === "時間" ? (
                  <div className="kim-value-bottom">
                    <Button type="primary" onClick={this.handleSetCurrentTime}>現時刻</Button>
                  </div>
                ) : null}
              </div>
              <div className={"mtr-col1 kim-suggest"}></div>
            </div>
          ) : null}

          <div>
            {(() => {
              switch (this.state.modalType) {
                case ConstSet.MODAL_T_TIME:
                  return (
                    <TimeKB
                      handleHourInput={this.getHandleHourInput}
                      handleMinuteLeftInput={this.getHandleMinuteLeftInput}
                      handleMinuteInput={this.getHandleMinuteInput}
                    />
                  );

                case ConstSet.MODAL_T_REPORTCASETYUIF:
                  return (
                    <CheckKB
                      defaltValue={this.state.inputValue}
                      handleOnchangeCheck={this.getHandleReportCaseTyuif}
                    />
                  );

                case ConstSet.MODAL_T_COMMENT:
                  return (
                    <CommentKB
                      yougoMasterEditState={this.getYougoMasterEditState}
                      yougoMasterEditCancel={this.getYougoMasterEditCancel}
                      yougoMasterEditSave={this.getYougoMasterEditSave}
                      defaultValue={this.state.inputValue}
                      handleTextareaKB={this.getHandleInputComment}
                      yougoMasterNa={yougoMasterNa}
                      rowNum={4}
                    />
                  );

                case ConstSet.MODAL_T_TANTOMASTER:
                  return (
                    <TantoMasterKB2
                      tantoMaster={tantoMaster}
                      value={this.state.inputValue}
                      handleTantoMasterKB={this.getHandleTantoMasterKB}
                      widthCode={100}
                      widthText={250}
                    />
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </Modal>
      </div>)
    );
  }
}
