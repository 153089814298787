import "whatwg-fetch";
import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";

const RiyouKaigohokenApi = {
  loadRiyouKaigohoken(_riyousyaId) {
    return axios
      .get(apiUrls.GET_RIYOU_KAIGOHOKEN_LIST, {
        params: {
          riyousyaId: _riyousyaId,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          window.location.reload();
        } else {
          clearLocalStorage(err);
        }
      });
  },

  checkHokenNo(hokenNo, hihokenNo, riyousyaId) {
    return axios
      .get(apiUrls.CHECK_HOKEN_NO, {
        params: {
          hokenNo: hokenNo,
          hihokenNo: hihokenNo,
          riyousyaId: riyousyaId,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          //window.location.reload();
        } else {
          clearLocalStorage(err);
        }
      });
  },
};

function clearLocalStorage(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("doubleLogin");
    localStorage.removeItem("getAvatarResponse");
    localStorage.removeItem("getAvatarResponseData");
    window.location.replace("/login");
  }
  return err;
}

export { RiyouKaigohokenApi };
