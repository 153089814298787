import React, { Fragment } from "react";
import {
  Modal,
  Input,
  Row,
  Col,
  Button,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import PropTypes from "prop-types";
// import { toKatakana } from "wanakana";
import { connect } from "react-redux";
import { IryouhokenMasterActions } from "../../../actions/iryouhokenMasterActions/actionCreators";

import _ from "lodash";

const ADD = 1;
const EDIT = 2;

const kokuho = ["市町村", "組合"];
const syaho = ["政府", "船員", "日雇", "日特", "共済", "組合", "退職"];

class IryouhokenMasterEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      initCode: null,
      errorNameVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      if (this.props.data && !(this.props.editType === ADD)) {
        if (this.props.editType === EDIT) {
          this.setState({
            data: this.props.data,
          });
        }
      } else {
        this.props.init((data) => {
          const initDate = data ? { ...data, id: null } : {};
          this.setState({
            data: initDate,
          });
        });
      }
    }
  }

  //フォーム入力時
  onChangeValue(e, field) {
    const { data } = this.state;
    let newValue = data[field];

    if (
      field === "takenF" ||
      field === "kazKyufu" ||
      field === "honKyufu" ||
      field === "kubun" ||
      field === "bunrui"
    ) {
      newValue = e;
    } else {
      e.stopPropagation();
      newValue = e.target.value;
    }

    if (newValue !== data[field]) {
      this.setState({
        data: { ...data, [field]: newValue },
      });
    }
  }

  //保存
  async handleSave() {
    const { data } = this.state;

    if (this.checkRequired()) return;
    const dataTmp = { ...data };

    dataTmp.takenF = data?.takenF?.length > 0 ? 1 : 0;
    dataTmp.bunrui = data.bunrui ? data.bunrui : "";
    dataTmp.kubun = data.kubun ? data.kubun : "";
    dataTmp.honKyufu = data.honKyufu ? data.honKyufu : 0;
    dataTmp.kazKyufu = data.kazKyufu ? data.kazKyufu : 0;

    if (data.id && data.id > 0) {
      await this.props.update(dataTmp, (data) => {
        this.props.handleSelectRow(data);
      });
    } else {
      await this.props.create(dataTmp, (data) => {
        this.props.handleSelectRow(data);
      });
    }

    this.handleClose();
  }
  checkRequired() {
    const { data } = this.state;

    let isError = false;
    if (_.isEmpty(data.hokenName)) {
      isError = true;
      this.setState({
        errorNameVisible: true,
      });
    } else {
      this.setState({
        errorNameVisible: false,
      });
    }

    return isError;
  }

  //閉じる
  handleClose() {
    this.setState({
      errorNameVisible: false,
    });
    this.props.handleClose();
  }

  render() {
    const { data, errorNameVisible } = this.state;

    return (
      (<Fragment>
        <Modal
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          open={this.props.visible}
          width={800}
          footer={[
            <Row key={1} type="flex" justify="end">
              <div style={{ paddingRight: 8 }}>
                <Button type={"primary"} onClick={() => this.handleClose()}>
                  {"戻る"}
                </Button>
              </div>
              <div>
                <Button type={"primary"} onClick={() => this.handleSave()}>
                  {"保存"}
                </Button>
              </div>
            </Row>,
          ]}
        >
          <Row>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>ID</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.id}
                onChange={(e) => this.onChangeValue(e, "id")}
                readOnly
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>保険者番号</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.hokenNo}
                onChange={(e) => this.onChangeValue(e, "hokenNo")}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>保険者名</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.hokenName}
                onChange={(e) => this.onChangeValue(e, "hokenName")}
                style={errorNameVisible ? { border: "1px solid red" } : {}}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>分類</p>
            </Col>
            <Col span={14}>
              <Select
                value={data.bunrui}
                onChange={(e) => this.onChangeValue(e, "bunrui")}
                style={{ width: 150 }}
              >
                <Select.Option value="">&nbsp;&nbsp;&nbsp;&nbsp;</Select.Option>
                <Select.Option value="国保">国保</Select.Option>
                <Select.Option value="社保">社保</Select.Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>区分</p>
            </Col>
            <Col span={4}>
              <Select
                value={data.kubun}
                onChange={(e) => this.onChangeValue(e, "kubun")}
                style={{ width: 150 }}
              >
                {data.bunrui && data.bunrui === "国保" ? (
                  kokuho.map((item, index) => (
                    <Select.Option value={item} key={`koku_${index}`}>
                      {item}
                    </Select.Option>
                  ))
                ) : data.bunrui && data.bunrui === "社保" ? (
                  syaho.map((item, index) => (
                    <Select.Option value={item} key={`sya_${index}`}>
                      {item}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option value="">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </Select.Option>
                )}
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>本人給付割合</p>
            </Col>
            <Col span={14}>
              <InputNumber
                value={data.honKyufu}
                onChange={(e) => this.onChangeValue(e, "honKyufu")}
                // style={errorNameVisible ? { border: "1px solid red" } : {}}
              />
              %
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>家族給付割合</p>
            </Col>
            <Col span={14}>
              <InputNumber
                value={data.kazKyufu}
                onChange={(e) => this.onChangeValue(e, "kazKyufu")}
                // style={errorNameVisible ? { border: "1px solid red" } : {}}
              />
              %
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>順番</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.sortNum}
                onChange={(e) => this.onChangeValue(e, "sortNum")}
                style={{ width: 80 }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={6}>
              <p style={{ marginTop: 5 }}>他県</p>
            </Col>
            <Col span={14}>
              <Checkbox.Group
                options={[{ label: "他県", value: "takenF" }]}
                onChange={(e) => this.onChangeValue(e, "takenF")}
              />
            </Col>
          </Row>
        </Modal>
      </Fragment>)
    );
  }
}

IryouhokenMasterEditModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.object,
  editType: PropTypes.number,
  handleSelectRow: PropTypes.func,
  handleClose: PropTypes.func,
  init: PropTypes.func,
  create: PropTypes.func,
  update: PropTypes.func,
};

const mapStateToProps = () => () => ({
  // loading: state.referenceNumber.loading || state.doctorMaster.loading,
});

const mapDispatchToProps = () => (dispatch) => ({
  init: (callback) => dispatch(IryouhokenMasterActions.init(callback)),
  create: (body, callback) =>
    dispatch(IryouhokenMasterActions.create(body, callback)),
  update: (body, callback) =>
    dispatch(IryouhokenMasterActions.update(body, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IryouhokenMasterEditModal);
