import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Input, Modal } from "antd";
import _ from "lodash";
import "moment/locale/ja";
import React from "react";
import SearchConditions from "../../../common/component/SearchConditions";
import jsonLocal from "../../../data/jsonLocal";

export class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleModalSearch: false,
      filterData: jsonLocal,
    };

    // bind place
    this.getHandleState = this.getHandleState.bind(this);
    this.handleModalSearchOk = this.handleModalSearchOk.bind(this);
    this.handleModalSearchCancel = this.handleModalSearchCancel.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      !_.isEmpty(this.props.filterDataBackup) &&
      JSON.stringify(newProps.filterDataBackup) !==
      JSON.stringify(this.state.filterData)
    ) {
      this.setState({ filterData: newProps.filterDataBackup });
    }
  }

  showModalSearchConditions = () => {
    this.setState({
      visibleModalSearch: true,
    });
  };

  handleModalSearchOk = () => {
    this.props.filterNyutaisho(this.state.filterData);
    this.setState({
      visibleModalSearch: false,
    });
  };

  handleModalSearchCancel = () => {
    this.setState({
      visibleModalSearch: false,
      filterData: _.isEmpty(this.props.filterDataBackup)
        ? jsonLocal
        : this.props.filterDataBackup,
    });
  };

  /**
   * Update state between component
   */
  getHandleState = (filterData) => {
    if (!filterData.search.checkbox1) {
      filterData.search.field10 = null;
      filterData.search.field11 = null;
    }
    if (!filterData.search.checkbox2) {
      filterData.search.field2 = null;
    }
    if (!filterData.search.checkbox3) {
      filterData.search.field3 = null;
    }
    this.setState({
      filterData,
    });
  };

  render() {
    const filterData = this.state.filterData;
    const { nyutaishoFilterData } = this.props;
    let settingNaiyo1 = null;
    let yougoMaster11 = null;
    let yougoMaster12 = null;
    let yougoMaster13 = null;
    let yougoMaster14 = null;
    let yougoMaster15 = null;
    let yougoMaster21 = null;
    if (nyutaishoFilterData) {
      settingNaiyo1 = nyutaishoFilterData.settingNaiyo1
        ? nyutaishoFilterData.settingNaiyo1.split(",")
        : "";
      yougoMaster11 = nyutaishoFilterData.yougoMaster11
        ? nyutaishoFilterData.yougoMaster11.split(";")
        : "";
      yougoMaster12 = nyutaishoFilterData.yougoMaster12
        ? nyutaishoFilterData.yougoMaster12.split(";")
        : "";
      yougoMaster13 = nyutaishoFilterData.yougoMaster13
        ? nyutaishoFilterData.yougoMaster13.split(";")
        : "";
      yougoMaster14 = nyutaishoFilterData.yougoMaster14
        ? nyutaishoFilterData.yougoMaster14.split(";")
        : "";
      yougoMaster15 = nyutaishoFilterData.yougoMaster15
        ? nyutaishoFilterData.yougoMaster15.split(";")
        : "";
      yougoMaster21 = nyutaishoFilterData.yougoMaster21
        ? nyutaishoFilterData.yougoMaster21.split(";")
        : "";
    }
    return (
      (<div style={this.props.style}>
        <span style={{ color: "white", fontSize: "small" }}>
          {"絞り込み条件"}
        </span>
        <div style={this.props.innerStyle}>
          <Input
            style={{ maxWidth: "500px" }}
            readOnly
            value={
              (filterData.search.field11 && filterData.search.field10
                ? filterData.search.field10.split("=")[1] +
                "=" +
                filterData.search.field11 +
                " / "
                : "") +
              (filterData.search.field2
                ? "絞込区分: " + filterData.search.field2 + " / "
                : "") +
              (filterData.search.field3
                ? "部屋分類:" + filterData.search.field3
                : "")
            }
          />
          <Button
            style={{ width: "70px", padding: "0px 0px", marginLeft: "2px" }}
            onClick={this.showModalSearchConditions}
            icon={<LegacyIcon type={"setting"} />}
          >
            {"条件"}
          </Button>
        </div>
        <Modal
          title="絞り込み検索"
          open={this.state.visibleModalSearch}
          okText={"検索"}
          cancelText={"閉じる"}
          onOk={this.handleModalSearchOk}
          onCancel={this.handleModalSearchCancel}
          className={"filter-model"}
          destroyOnClose={true}
        >
          <SearchConditions
            settingNaiyo1={settingNaiyo1}
            yougoMaster11={yougoMaster11}
            yougoMaster12={yougoMaster12}
            yougoMaster13={yougoMaster13}
            yougoMaster14={yougoMaster14}
            yougoMaster15={yougoMaster15}
            yougoMaster21={yougoMaster21}
            handleUpdateState={(data) => this.getHandleState(data)}
            filterData={filterData}
          />
        </Modal>
      </div>)
    );
  }
}
