
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import History from "./history";
import { uuid4 } from "./utils";
import Select from "./select";
import Pencil from "./pencil";
import Line from "./line";
import Arrow from "./arrow";
import Rectangle from "./rectangle";
import Circle from "./circle";
import Pan from "./pan";
import Tool from "./tools";
import TwoCircle from "./twocircle";
import Triangle from "./triangle";
import TwoRectangle from "./tworectangle";
import RectangleLine from "./rectangleline";
import CircleLine from "./circleline";
import TwoLine from "./twoline";
import TwoLine2 from "./twoline2";
import ThreeLine from "./threeline";
import Sin from "./sin";
import ThreeLine2 from "./threeline2";
import TwoDiagonal from "./twodiagonal";
import People1 from "./people1";
import PeopleHealth from "./peopleHealth";
import RectangleText from "./reactangletext";
import Kaidan from "./kaidan";
import Living from "./living";
import CircleText from "./circletext";
import XTriangle from "./xtriangle";
const fabric = require("fabric").fabric;

/**
 * Sketch Tool based on FabricJS for React Applications
 */
class SketchField extends PureComponent {

  static propTypes = {
    // the color of the line
    lineColor: PropTypes.string,
    // The width of the line
    lineWidth: PropTypes.number,
    // the fill color of the shape when applicable
    fillColor: PropTypes.string,
    // the background color of the sketch
    backgroundColor: PropTypes.string,
    // the opacity of the object
    opacity: PropTypes.number,
    // number of undo/redo steps to maintain
    undoSteps: PropTypes.number,
    // The tool to use, can be pencil, rectangle, circle, brush;
    tool: PropTypes.string,
    // image format when calling toDataURL
    imageFormat: PropTypes.string,
    // Sketch data for controlling sketch from
    // outside the component
    value: PropTypes.object,
    // Set to true if you wish to force load the given value, even if it is  the same
    forceValue: PropTypes.bool,
    // Specify some width correction which will be applied on auto resize
    widthCorrection: PropTypes.number,
    // Specify some height correction which will be applied on auto resize
    heightCorrection: PropTypes.number,
    // Specify action on change
    onChange: PropTypes.func,
    // Default initial value
    defaultValue: PropTypes.object,
    // Sketch width
    width: PropTypes.number,
    // Sketch height
    height: PropTypes.number,
    // Class name to pass to container div of canvas
    className: PropTypes.string,
    // Style options to pass to container div of canvas
    style: PropTypes.object,
  };

  static defaultProps = {
    lineColor: "black",
    lineWidth: 10,
    fillColor: "transparent",
    backgroundColor: "transparent",
    opacity: 1.0,
    undoSteps: 100,
    tool: Tool.Select,
    widthCorrection: 0,
    heightCorrection: 0,
    forceValue: false,
    readOnly: false,
  };

  state = {
    parentWidth: 550,
    action: true
  };
  _initTools = (fabricCanvas) => {
    this._tools = {};
    this._tools[Tool.Select] = new Select(fabricCanvas);
    this._tools[Tool.Pencil] = new Pencil(fabricCanvas, false);
    this._tools[Tool.Pencil2] = new Pencil(fabricCanvas, true);
    this._tools[Tool.TwoCircle] = new TwoCircle(fabricCanvas);
    this._tools[Tool.Line] = new Line(fabricCanvas);
    this._tools[Tool.TwoLine] = new TwoLine(fabricCanvas);
    this._tools[Tool.TwoLine2] = new TwoLine2(fabricCanvas);
    this._tools[Tool.ThreeLine] = new ThreeLine(fabricCanvas);
    this._tools[Tool.Arrow] = new Arrow(fabricCanvas);
    this._tools[Tool.Rectangle] = new Rectangle(fabricCanvas, false);
    this._tools[Tool.RectangleLine] = new RectangleLine(fabricCanvas);
    this._tools[Tool.RectangleFill] = new Rectangle(fabricCanvas, true);
    this._tools[Tool.Circle] = new Circle(fabricCanvas, false);
    this._tools[Tool.CircleFill] = new Circle(fabricCanvas, true);
    this._tools[Tool.CircleLine] = new CircleLine(fabricCanvas);
    this._tools[Tool.Pan] = new Pan(fabricCanvas);
    this._tools[Tool.Triangle] = new Triangle(fabricCanvas);
    this._tools[Tool.TwoRectangle] = new TwoRectangle(fabricCanvas);
    this._tools[Tool.Sin] = new Sin(fabricCanvas);
    this._tools[Tool.ThreeLine2] = new ThreeLine2(fabricCanvas);
    this._tools[Tool.TwoDiagonal] = new TwoDiagonal(fabricCanvas);
    this._tools[Tool.PeopleHealth] = new PeopleHealth(fabricCanvas, `<svg width="512" height="512" xmlns="http://www.w3.org/2000/svg">
    <g>
     <title>background</title>
     <rect x="-1" y="-1" width="514" height="514" id="canvas_background" fill="none"/>
    </g>
   
    <g>
     <title>Layer 1</title>
     <g id="svg_1" stroke="null">
      <g id="svg_2" stroke="null">
       <ellipse fill="none" stroke-width="3" stroke-miterlimit="10" cx="124.96224" cy="138.59041" rx="36.76159" ry="41.04311" id="svg_3" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m114.42258,177.91835l0,6.30232c0,0 -18.37053,1.67907 -28.87052,7.13541" id="svg_4" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m46.65901,255.00218c10.42885,-40.47053 34.24159,-60.45947 34.24159,-60.45947c1.21075,-1.18902 2.82645,-2.23876 4.65146,-3.18663" id="svg_5" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m46.65901,255.00218c0,0 -11.12383,9.33935 -20.16271,39.32277c0,0 -24.50773,12.28869 -21.03144,17.20339c0,0 0.34749,2.78556 9.90761,-3.76824c0,0 8.9609,-2.41802 11.99393,-7.3727" id="svg_6" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m83.68053,230.58987c1.21622,3.2769 5.91009,44.73011 5.91009,44.73011c-3.47628,18.84248 -1.38997,42.76345 -1.38997,42.76345l3.92775,37.52119" id="svg_7" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m62.30159,260.90085c13.38389,-8.84801 21.37893,-30.31098 21.37893,-30.31098" id="svg_8" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m19.02112,332.91136c0,0 -5.82253,1.72034 -5.64879,-3.19565" id="svg_9" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m87.24437,424.58325c0,0 -0.74697,-49.48103 4.88403,-68.97864" id="svg_10" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m20.70796,305.70189l-9.42194,17.05122c-3.99752,6.30748 2.08632,6.96389 2.08632,6.96389" id="svg_11" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m125.92263,315.22952c-1.21075,0.25663 -4.34912,1.21223 -5.74045,4.49171l-13.38389,110.59562" id="svg_12" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m126.43977,315.13409c0,0 -0.21479,0.03224 -0.51713,0.09543" id="svg_13" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m25.54,333.73026c0,0 -5.91009,2.458 -6.51752,-0.8189" id="svg_14" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m62.30159,260.90085c0,0 -8.86514,23.59341 -16.51269,30.47476c0,0 -9.64631,21.62804 -9.47256,29.08327c0,0 -4.08508,15.81061 -10.77634,13.27137" id="svg_15" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m87.24437,424.58325l-23.11776,27.19787c-12.60135,10.89591 -2.43381,17.36717 -2.43381,17.36717c13.90513,6.06245 22.16147,-1.06522 22.16147,-1.06522l25.02896,-21.70929c1.91257,-3.76824 -2.08632,-16.05693 -2.08632,-16.05693" id="svg_16" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="20.75994" y1="316.28055" x2="13.37233" y2="329.71571" id="svg_17" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="25.85877" y1="318.35554" x2="19.02112" y2="332.91136" id="svg_18" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="30.03003" y1="318.90233" x2="25.54" y2="333.73026" id="svg_19" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m85.55616,191.33029c0,0 -0.00274,0.02063 -0.0041,0.02579" id="svg_20" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m83.68053,230.58987c-1.89205,-11.72126 1.79492,-38.68184 1.87153,-39.2325" id="svg_21" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m46.65901,255.00218c0,0 10.08136,5.89867 15.64396,5.89867" id="svg_22" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m115.42401,359.03756c-13.62194,3.10667 -23.29561,-3.43423 -23.29561,-3.43423" id="svg_23" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m87.24437,424.58325c0,0 0.60879,5.89867 19.55392,5.73489" id="svg_24" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m137.42269,177.91835l0,6.30232c0,0 18.37053,1.67907 28.87052,7.13541" id="svg_25" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m166.29458,191.35608c1.82638,0.94915 3.44208,1.99761 4.65146,3.18663c0,0 23.81274,19.98895 34.24159,60.45947" id="svg_26" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m224.48162,300.38741c3.03303,4.95468 11.99393,7.3727 11.99393,7.3727c9.56012,6.5538 9.90761,3.76824 9.90761,3.76824c3.47628,-4.91599 -21.03144,-17.20339 -21.03144,-17.20339c-9.03888,-29.98342 -20.16271,-39.32277 -20.16271,-39.32277" id="svg_27" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m145.04835,430.31815c0,0 -3.99752,12.28869 -2.08632,16.05693l25.02896,21.70929c0,0 8.25634,7.12767 22.16147,1.06522c0,0 10.16755,-6.47255 -2.43381,-17.36717l-23.11776,-27.19787" id="svg_28" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m145.04835,430.31815l-13.38389,-110.59562c-1.39133,-3.27948 -4.5297,-4.23637 -5.74045,-4.49171" id="svg_29" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m231.13731,305.70189l9.42194,17.05122c3.99752,6.30748 -2.08632,6.96389 -2.08632,6.96389" id="svg_30" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m125.40687,315.13409c0,0 0.21479,0.03224 0.51713,0.09543" id="svg_31" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m164.60227,424.58325c0,0 0.74697,-49.48103 -4.88403,-68.97864" id="svg_32" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m168.16474,230.58987c-1.21622,3.2769 -5.91009,44.73011 -5.91009,44.73011c3.47628,18.84248 1.38997,42.76345 1.38997,42.76345l-3.92775,37.52119" id="svg_33" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m238.47294,329.71571c0.17375,4.91599 -5.64879,3.19565 -5.64879,3.19565" id="svg_34" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m226.30663,333.73026c-6.69126,2.53924 -10.77634,-13.27137 -10.77634,-13.27137c0.17375,-7.45523 -9.47256,-29.08327 -9.47256,-29.08327c-7.64755,-6.88136 -16.51269,-30.47476 -16.51269,-30.47476" id="svg_35" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m232.82415,332.91136c-0.60879,3.2769 -6.51752,0.8189 -6.51752,0.8189" id="svg_36" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m168.16474,230.58987c0,0 7.99504,21.46426 21.37893,30.31098" id="svg_37" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="231.08669" y1="316.28055" x2="238.47294" y2="329.71571" id="svg_38" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="232.82415" y1="332.91136" x2="225.98787" y2="318.35554" id="svg_39" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="221.8166" y1="318.90233" x2="226.30663" y2="333.73026" id="svg_40" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m166.29458,191.35608c-0.00137,-0.00516 -0.0041,-0.02579 -0.0041,-0.02579" id="svg_41" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m168.16474,230.58987c1.89205,-11.72126 -1.79492,-38.68184 -1.87153,-39.2325" id="svg_42" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m189.54504,260.90085c5.5626,0 15.64396,-5.89867 15.64396,-5.89867" id="svg_43" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m159.71823,355.60333c0,0 -9.67367,6.53961 -23.29561,3.43423" id="svg_44" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m164.60227,424.58325c0,0 -0.60879,5.89867 -19.55392,5.73489" id="svg_45" stroke="#000000"/>
      </g>
      <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m89.59062,275.31869c0,0 28.98955,11.68773 72.6654,0" id="svg_46" stroke="#000000"/>
     </g>
     <g id="svg_47" stroke="null">
      <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="286.92203" y1="307.57933" x2="279.57817" y2="321.74885" id="svg_48" stroke="#000000"/>
      <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="291.99159" y1="309.76773" x2="285.19454" y2="325.11918" id="svg_49" stroke="#000000"/>
      <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="296.13891" y1="310.34441" x2="291.67465" y2="325.98285" id="svg_50" stroke="#000000"/>
      <g id="svg_51" stroke="null">
       <g id="svg_52" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m349.80522,189.96875c0,0 -0.09522,0.73445 -0.24892,2.01839" id="svg_53" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.0593,3.0593" d="m349.08974,196.11776c-0.8515,7.92258 -2.09203,21.78472 -1.55338,31.14901" id="svg_54" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m347.69415,229.34773c0.06801,0.71405 0.15098,1.39138 0.24756,2.02655" id="svg_55" stroke="#000000"/>
       </g>
      </g>
      <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m356.3411,363.22103" id="svg_56" stroke="#000000"/>
      <g id="svg_57" stroke="null">
       <g id="svg_58" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m311.89031,251.74042c0,0 0.50328,0.58892 1.36703,1.50699" id="svg_59" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.6473,3.6473" d="m316.78848,256.72927c1.72068,1.57092 3.75422,3.24384 5.86257,4.58218" id="svg_60" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m324.80565,262.53825c0.62842,0.31418 1.25685,0.58484 1.87847,0.80382" id="svg_61" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_62" stroke="null">
       <g id="svg_63" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m355.55489,356.98225c0,0 0.63795,0.41347 1.79142,0.96839" id="svg_64" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="2.9308,2.9308" d="m361.04748,359.41411c3.45769,1.10032 8.29737,1.94222 13.78588,0.88134" id="svg_65" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m376.78528,359.84254c0.64203,-0.17409 1.29221,-0.37675 1.94784,-0.60796" id="svg_66" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_67" stroke="null">
       <g id="svg_68" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m350.94645,427.65305c0,0 0.59306,0.45019 1.75877,1.02008" id="svg_69" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.7288,3.7288" d="m357.48641,430.32429c2.05258,0.46651 4.57852,0.77254 7.56013,0.65693" id="svg_70" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m367.5888,430.78945c0.65563,-0.07345 1.32894,-0.16729 2.02265,-0.2829" id="svg_71" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_72" stroke="null">
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m430.80117,189.99595c-10.44109,-5.75458 -28.70482,-7.52543 -28.70482,-7.52543l0,-6.64681l0,0c13.76003,-5.80491 23.44211,-20.42326 23.44211,-37.69518c0,-22.34509 -16.36486,-40.4589 -36.5506,-40.4589c-20.1871,0 -36.5506,18.11381 -36.5506,40.4589c0,18.27431 11.0423,33.75769 26.07279,38.77782l0,0l0,5.56417c0,0 -25.20905,2.41962 -33.33095,10.88624c0,0 -22.91707,15.70236 -33.28606,58.38366c0,0 -10.19625,4.66514 -21.08348,36.28883c0,0 -22.54845,9.35477 -19.09212,14.53947c0,0 0.3455,2.93782 9.85075,-3.97421c0,0 8.90947,-2.55019 11.92509,-7.77569" id="svg_73" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m390.30183,316.02691c0.01768,0 0.03129,-0.00408 0.04761,-0.00408" id="svg_74" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m286.8717,296.42243l-9.36787,17.98324c-3.97458,6.65225 2.07434,7.34454 2.07434,7.34454c-0.17275,5.1847 5.61637,3.36897 5.61637,3.36897c0.6053,3.45601 6.48011,0.86366 6.48011,0.86366c6.65286,2.67804 10.71449,-13.99679 10.71449,-13.99679c-0.17275,-7.86274 5.87073,-17.04478 5.87073,-17.04478c7.60366,-7.25749 18.42424,-31.59921 18.42424,-31.59921c13.30708,-9.33165 21.25623,-31.96779 21.25623,-31.96779c1.20924,3.45601 5.87617,47.17507 5.87617,47.17507c-3.45633,19.87242 -1.38199,45.10092 -1.38199,45.10092l2.31374,34.70976c-5.59869,20.56335 -3.80183,69.29302 -3.80183,69.29302s-17.97265,45.61912 7.43091,43.71906c0,0 21.94723,5.70562 11.23274,-40.86557l13.65258,-106.66314c0,0 0.17411,-7.60568 7.0378,-7.81514" id="svg_75" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m430.80117,189.99595c1.8159,1.00103 3.42233,2.1068 4.62476,3.36081c0,0 22.91707,15.70236 33.28606,58.38366c0,0 10.19625,4.66514 21.08348,36.28883c0,0 22.54845,9.35477 19.09212,14.53947c0,0 -0.3455,2.93782 -9.85075,-3.97421c0,0 -8.90947,-2.55019 -11.92509,-7.77569" id="svg_76" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m501.02413,321.74885c0.17275,5.1847 -5.61637,3.36897 -5.61637,3.36897" id="svg_77" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m493.73061,296.42243l9.36787,17.98324c3.97458,6.65225 -2.07434,7.34454 -2.07434,7.34454" id="svg_78" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m495.40776,325.11918c-0.6053,3.45601 -6.48011,0.86366 -6.48011,0.86366" id="svg_79" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m432.66059,231.37292c-1.20924,3.45601 -5.87617,47.17507 -5.87617,47.17507c3.45633,19.87242 1.38199,45.10092 1.38199,45.10092l-2.31374,34.70976c5.59869,20.56335 3.80183,69.29302 3.80183,69.29302s17.97265,45.61912 -7.43091,43.71906c0,0 -21.94723,5.70562 -11.23274,-40.86557l-13.65258,-106.66314c0,0 -0.17411,-7.60568 -7.0378,-7.81514" id="svg_80" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m390.25286,316.02283c0.01768,0 0.03129,0.00408 0.04761,0.00408" id="svg_81" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m488.92765,325.98285c-6.65286,2.67804 -10.71449,-13.99679 -10.71449,-13.99679c0.17275,-7.86274 -5.87073,-17.04478 -5.87073,-17.04478c-7.60366,-7.25749 -18.42424,-31.59921 -18.42424,-31.59921c-13.30708,-9.33165 -21.25623,-31.96779 -21.25623,-31.96779" id="svg_82" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="493.68028" y1="307.57933" x2="501.02413" y2="321.74885" id="svg_83" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="495.40776" y1="325.11918" x2="488.61072" y2="309.76773" id="svg_84" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="484.46339" y1="310.34441" x2="488.92765" y2="325.98285" id="svg_85" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m430.80117,189.99595c-0.00136,-0.00544 -0.00408,-0.0272 -0.00408,-0.0272" id="svg_86" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m430.80117,189.99595c0.07481,0.57532 3.74198,29.01502 1.86079,41.37833" id="svg_87" stroke="#000000"/>
      </g>
      <g id="svg_88" stroke="null">
       <g id="svg_89" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m468.71199,251.74042c0,0 -0.50328,0.58892 -1.36703,1.50699" id="svg_90" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.6473,3.6473" d="m463.81382,256.72927c-1.72068,1.57092 -3.75422,3.24384 -5.86257,4.58218" id="svg_91" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m455.79666,262.53825c-0.62842,0.31418 -1.25685,0.58484 -1.87847,0.80382" id="svg_92" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_93" stroke="null">
       <g id="svg_94" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m425.04742,356.98225c0,0 -0.63795,0.41347 -1.79142,0.96839" id="svg_95" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="2.9308,2.9308" d="m419.55483,359.41411c-3.45769,1.10032 -8.29737,1.94222 -13.78588,0.88134" id="svg_96" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m403.81703,359.84254c-0.64203,-0.17409 -1.29221,-0.37675 -1.94784,-0.60796" id="svg_97" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_98" stroke="null">
       <g id="svg_99" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m429.65586,427.65305c0,0 -0.59306,0.45019 -1.75877,1.02008" id="svg_100" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.7288,3.7288" d="m423.1159,430.32429c-2.05258,0.46651 -4.57852,0.77254 -7.56013,0.65693" id="svg_101" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m413.01351,430.78945c-0.65563,-0.07345 -1.3303,-0.16729 -2.02265,-0.2829" id="svg_102" stroke="#000000"/>
       </g>
      </g>
      <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m353.81788,278.54799c0,0 22.30225,10.5639 72.9679,0" id="svg_103" stroke="#000000"/>
     </g>
     <text font-weight="bold" fill="#000000" stroke-width="0" x="93.90708" y="84.13089" id="svg_104" font-size="24" font-family="Helvetica, Arial, sans-serif" text-anchor="start" xml:space="preserve" stroke="#000">(正面)</text>
     <text font-weight="bold" fill="#000000" stroke-width="0" x="358.32277" y="83.91505" id="svg_105" font-size="24" font-family="Helvetica, Arial, sans-serif" text-anchor="start" xml:space="preserve" stroke="#000">(背面)</text>
    </g>
   </svg>
    `);

    this._tools[Tool.People1] = new People1(fabricCanvas, `<svg width="512" height="512" xmlns="http://www.w3.org/2000/svg">
    <g>
     <title>background</title>
     <rect x="-1" y="-1" width="514" height="514" id="canvas_background" fill="none"/>
    </g>
   
    <g>
     <title>Layer 1</title>
     <g id="svg_1" stroke="null">
      <g id="svg_2" stroke="null">
       <ellipse fill="none" stroke-width="3" stroke-miterlimit="10" cx="124.96224" cy="138.59041" rx="36.76159" ry="41.04311" id="svg_3" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m114.42258,177.91835l0,6.30232c0,0 -18.37053,1.67907 -28.87052,7.13541" id="svg_4" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m46.65901,255.00218c10.42885,-40.47053 34.24159,-60.45947 34.24159,-60.45947c1.21075,-1.18902 2.82645,-2.23876 4.65146,-3.18663" id="svg_5" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m46.65901,255.00218c0,0 -11.12383,9.33935 -20.16271,39.32277c0,0 -24.50773,12.28869 -21.03144,17.20339c0,0 0.34749,2.78556 9.90761,-3.76824c0,0 8.9609,-2.41802 11.99393,-7.3727" id="svg_6" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m83.68053,230.58987c1.21622,3.2769 5.91009,44.73011 5.91009,44.73011c-3.47628,18.84248 -1.38997,42.76345 -1.38997,42.76345l3.92775,37.52119" id="svg_7" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m62.30159,260.90085c13.38389,-8.84801 21.37893,-30.31098 21.37893,-30.31098" id="svg_8" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m19.02112,332.91136c0,0 -5.82253,1.72034 -5.64879,-3.19565" id="svg_9" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m87.24437,424.58325c0,0 -0.74697,-49.48103 4.88403,-68.97864" id="svg_10" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m20.70796,305.70189l-9.42194,17.05122c-3.99752,6.30748 2.08632,6.96389 2.08632,6.96389" id="svg_11" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m125.92263,315.22952c-1.21075,0.25663 -4.34912,1.21223 -5.74045,4.49171l-13.38389,110.59562" id="svg_12" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m126.43977,315.13409c0,0 -0.21479,0.03224 -0.51713,0.09543" id="svg_13" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m25.54,333.73026c0,0 -5.91009,2.458 -6.51752,-0.8189" id="svg_14" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m62.30159,260.90085c0,0 -8.86514,23.59341 -16.51269,30.47476c0,0 -9.64631,21.62804 -9.47256,29.08327c0,0 -4.08508,15.81061 -10.77634,13.27137" id="svg_15" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m87.24437,424.58325l-23.11776,27.19787c-12.60135,10.89591 -2.43381,17.36717 -2.43381,17.36717c13.90513,6.06245 22.16147,-1.06522 22.16147,-1.06522l25.02896,-21.70929c1.91257,-3.76824 -2.08632,-16.05693 -2.08632,-16.05693" id="svg_16" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="20.75994" y1="316.28055" x2="13.37233" y2="329.71571" id="svg_17" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="25.85877" y1="318.35554" x2="19.02112" y2="332.91136" id="svg_18" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="30.03003" y1="318.90233" x2="25.54" y2="333.73026" id="svg_19" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m85.55616,191.33029c0,0 -0.00274,0.02063 -0.0041,0.02579" id="svg_20" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m83.68053,230.58987c-1.89205,-11.72126 1.79492,-38.68184 1.87153,-39.2325" id="svg_21" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m46.65901,255.00218c0,0 10.08136,5.89867 15.64396,5.89867" id="svg_22" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m115.42401,359.03756c-13.62194,3.10667 -23.29561,-3.43423 -23.29561,-3.43423" id="svg_23" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m87.24437,424.58325c0,0 0.60879,5.89867 19.55392,5.73489" id="svg_24" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m137.42269,177.91835l0,6.30232c0,0 18.37053,1.67907 28.87052,7.13541" id="svg_25" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m166.29458,191.35608c1.82638,0.94915 3.44208,1.99761 4.65146,3.18663c0,0 23.81274,19.98895 34.24159,60.45947" id="svg_26" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m224.48162,300.38741c3.03303,4.95468 11.99393,7.3727 11.99393,7.3727c9.56012,6.5538 9.90761,3.76824 9.90761,3.76824c3.47628,-4.91599 -21.03144,-17.20339 -21.03144,-17.20339c-9.03888,-29.98342 -20.16271,-39.32277 -20.16271,-39.32277" id="svg_27" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m145.04835,430.31815c0,0 -3.99752,12.28869 -2.08632,16.05693l25.02896,21.70929c0,0 8.25634,7.12767 22.16147,1.06522c0,0 10.16755,-6.47255 -2.43381,-17.36717l-23.11776,-27.19787" id="svg_28" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m145.04835,430.31815l-13.38389,-110.59562c-1.39133,-3.27948 -4.5297,-4.23637 -5.74045,-4.49171" id="svg_29" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m231.13731,305.70189l9.42194,17.05122c3.99752,6.30748 -2.08632,6.96389 -2.08632,6.96389" id="svg_30" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m125.40687,315.13409c0,0 0.21479,0.03224 0.51713,0.09543" id="svg_31" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m164.60227,424.58325c0,0 0.74697,-49.48103 -4.88403,-68.97864" id="svg_32" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m168.16474,230.58987c-1.21622,3.2769 -5.91009,44.73011 -5.91009,44.73011c3.47628,18.84248 1.38997,42.76345 1.38997,42.76345l-3.92775,37.52119" id="svg_33" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m238.47294,329.71571c0.17375,4.91599 -5.64879,3.19565 -5.64879,3.19565" id="svg_34" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m226.30663,333.73026c-6.69126,2.53924 -10.77634,-13.27137 -10.77634,-13.27137c0.17375,-7.45523 -9.47256,-29.08327 -9.47256,-29.08327c-7.64755,-6.88136 -16.51269,-30.47476 -16.51269,-30.47476" id="svg_35" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m232.82415,332.91136c-0.60879,3.2769 -6.51752,0.8189 -6.51752,0.8189" id="svg_36" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m168.16474,230.58987c0,0 7.99504,21.46426 21.37893,30.31098" id="svg_37" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="231.08669" y1="316.28055" x2="238.47294" y2="329.71571" id="svg_38" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="232.82415" y1="332.91136" x2="225.98787" y2="318.35554" id="svg_39" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="221.8166" y1="318.90233" x2="226.30663" y2="333.73026" id="svg_40" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m166.29458,191.35608c-0.00137,-0.00516 -0.0041,-0.02579 -0.0041,-0.02579" id="svg_41" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m168.16474,230.58987c1.89205,-11.72126 -1.79492,-38.68184 -1.87153,-39.2325" id="svg_42" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m189.54504,260.90085c5.5626,0 15.64396,-5.89867 15.64396,-5.89867" id="svg_43" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m159.71823,355.60333c0,0 -9.67367,6.53961 -23.29561,3.43423" id="svg_44" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m164.60227,424.58325c0,0 -0.60879,5.89867 -19.55392,5.73489" id="svg_45" stroke="#000000"/>
      </g>
      <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m89.59062,275.31869c0,0 28.98955,11.68773 72.6654,0" id="svg_46" stroke="#000000"/>
     </g>
     <g id="svg_47" stroke="null">
      <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="286.92203" y1="307.57933" x2="279.57817" y2="321.74885" id="svg_48" stroke="#000000"/>
      <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="291.99159" y1="309.76773" x2="285.19454" y2="325.11918" id="svg_49" stroke="#000000"/>
      <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="296.13891" y1="310.34441" x2="291.67465" y2="325.98285" id="svg_50" stroke="#000000"/>
      <g id="svg_51" stroke="null">
       <g id="svg_52" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m349.80522,189.96875c0,0 -0.09522,0.73445 -0.24892,2.01839" id="svg_53" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.0593,3.0593" d="m349.08974,196.11776c-0.8515,7.92258 -2.09203,21.78472 -1.55338,31.14901" id="svg_54" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m347.69415,229.34773c0.06801,0.71405 0.15098,1.39138 0.24756,2.02655" id="svg_55" stroke="#000000"/>
       </g>
      </g>
      <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m356.3411,363.22103" id="svg_56" stroke="#000000"/>
      <g id="svg_57" stroke="null">
       <g id="svg_58" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m311.89031,251.74042c0,0 0.50328,0.58892 1.36703,1.50699" id="svg_59" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.6473,3.6473" d="m316.78848,256.72927c1.72068,1.57092 3.75422,3.24384 5.86257,4.58218" id="svg_60" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m324.80565,262.53825c0.62842,0.31418 1.25685,0.58484 1.87847,0.80382" id="svg_61" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_62" stroke="null">
       <g id="svg_63" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m355.55489,356.98225c0,0 0.63795,0.41347 1.79142,0.96839" id="svg_64" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="2.9308,2.9308" d="m361.04748,359.41411c3.45769,1.10032 8.29737,1.94222 13.78588,0.88134" id="svg_65" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m376.78528,359.84254c0.64203,-0.17409 1.29221,-0.37675 1.94784,-0.60796" id="svg_66" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_67" stroke="null">
       <g id="svg_68" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m350.94645,427.65305c0,0 0.59306,0.45019 1.75877,1.02008" id="svg_69" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.7288,3.7288" d="m357.48641,430.32429c2.05258,0.46651 4.57852,0.77254 7.56013,0.65693" id="svg_70" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m367.5888,430.78945c0.65563,-0.07345 1.32894,-0.16729 2.02265,-0.2829" id="svg_71" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_72" stroke="null">
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m430.80117,189.99595c-10.44109,-5.75458 -28.70482,-7.52543 -28.70482,-7.52543l0,-6.64681l0,0c13.76003,-5.80491 23.44211,-20.42326 23.44211,-37.69518c0,-22.34509 -16.36486,-40.4589 -36.5506,-40.4589c-20.1871,0 -36.5506,18.11381 -36.5506,40.4589c0,18.27431 11.0423,33.75769 26.07279,38.77782l0,0l0,5.56417c0,0 -25.20905,2.41962 -33.33095,10.88624c0,0 -22.91707,15.70236 -33.28606,58.38366c0,0 -10.19625,4.66514 -21.08348,36.28883c0,0 -22.54845,9.35477 -19.09212,14.53947c0,0 0.3455,2.93782 9.85075,-3.97421c0,0 8.90947,-2.55019 11.92509,-7.77569" id="svg_73" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m390.30183,316.02691c0.01768,0 0.03129,-0.00408 0.04761,-0.00408" id="svg_74" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m286.8717,296.42243l-9.36787,17.98324c-3.97458,6.65225 2.07434,7.34454 2.07434,7.34454c-0.17275,5.1847 5.61637,3.36897 5.61637,3.36897c0.6053,3.45601 6.48011,0.86366 6.48011,0.86366c6.65286,2.67804 10.71449,-13.99679 10.71449,-13.99679c-0.17275,-7.86274 5.87073,-17.04478 5.87073,-17.04478c7.60366,-7.25749 18.42424,-31.59921 18.42424,-31.59921c13.30708,-9.33165 21.25623,-31.96779 21.25623,-31.96779c1.20924,3.45601 5.87617,47.17507 5.87617,47.17507c-3.45633,19.87242 -1.38199,45.10092 -1.38199,45.10092l2.31374,34.70976c-5.59869,20.56335 -3.80183,69.29302 -3.80183,69.29302s-17.97265,45.61912 7.43091,43.71906c0,0 21.94723,5.70562 11.23274,-40.86557l13.65258,-106.66314c0,0 0.17411,-7.60568 7.0378,-7.81514" id="svg_75" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m430.80117,189.99595c1.8159,1.00103 3.42233,2.1068 4.62476,3.36081c0,0 22.91707,15.70236 33.28606,58.38366c0,0 10.19625,4.66514 21.08348,36.28883c0,0 22.54845,9.35477 19.09212,14.53947c0,0 -0.3455,2.93782 -9.85075,-3.97421c0,0 -8.90947,-2.55019 -11.92509,-7.77569" id="svg_76" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m501.02413,321.74885c0.17275,5.1847 -5.61637,3.36897 -5.61637,3.36897" id="svg_77" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m493.73061,296.42243l9.36787,17.98324c3.97458,6.65225 -2.07434,7.34454 -2.07434,7.34454" id="svg_78" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m495.40776,325.11918c-0.6053,3.45601 -6.48011,0.86366 -6.48011,0.86366" id="svg_79" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m432.66059,231.37292c-1.20924,3.45601 -5.87617,47.17507 -5.87617,47.17507c3.45633,19.87242 1.38199,45.10092 1.38199,45.10092l-2.31374,34.70976c5.59869,20.56335 3.80183,69.29302 3.80183,69.29302s17.97265,45.61912 -7.43091,43.71906c0,0 -21.94723,5.70562 -11.23274,-40.86557l-13.65258,-106.66314c0,0 -0.17411,-7.60568 -7.0378,-7.81514" id="svg_80" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m390.25286,316.02283c0.01768,0 0.03129,0.00408 0.04761,0.00408" id="svg_81" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m488.92765,325.98285c-6.65286,2.67804 -10.71449,-13.99679 -10.71449,-13.99679c0.17275,-7.86274 -5.87073,-17.04478 -5.87073,-17.04478c-7.60366,-7.25749 -18.42424,-31.59921 -18.42424,-31.59921c-13.30708,-9.33165 -21.25623,-31.96779 -21.25623,-31.96779" id="svg_82" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="493.68028" y1="307.57933" x2="501.02413" y2="321.74885" id="svg_83" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="495.40776" y1="325.11918" x2="488.61072" y2="309.76773" id="svg_84" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="484.46339" y1="310.34441" x2="488.92765" y2="325.98285" id="svg_85" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m430.80117,189.99595c-0.00136,-0.00544 -0.00408,-0.0272 -0.00408,-0.0272" id="svg_86" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m430.80117,189.99595c0.07481,0.57532 3.74198,29.01502 1.86079,41.37833" id="svg_87" stroke="#000000"/>
      </g>
      <g id="svg_88" stroke="null">
       <g id="svg_89" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m468.71199,251.74042c0,0 -0.50328,0.58892 -1.36703,1.50699" id="svg_90" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.6473,3.6473" d="m463.81382,256.72927c-1.72068,1.57092 -3.75422,3.24384 -5.86257,4.58218" id="svg_91" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m455.79666,262.53825c-0.62842,0.31418 -1.25685,0.58484 -1.87847,0.80382" id="svg_92" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_93" stroke="null">
       <g id="svg_94" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m425.04742,356.98225c0,0 -0.63795,0.41347 -1.79142,0.96839" id="svg_95" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="2.9308,2.9308" d="m419.55483,359.41411c-3.45769,1.10032 -8.29737,1.94222 -13.78588,0.88134" id="svg_96" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m403.81703,359.84254c-0.64203,-0.17409 -1.29221,-0.37675 -1.94784,-0.60796" id="svg_97" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_98" stroke="null">
       <g id="svg_99" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m429.65586,427.65305c0,0 -0.59306,0.45019 -1.75877,1.02008" id="svg_100" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.7288,3.7288" d="m423.1159,430.32429c-2.05258,0.46651 -4.57852,0.77254 -7.56013,0.65693" id="svg_101" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m413.01351,430.78945c-0.65563,-0.07345 -1.3303,-0.16729 -2.02265,-0.2829" id="svg_102" stroke="#000000"/>
       </g>
      </g>
      <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m353.81788,278.54799c0,0 22.30225,10.5639 72.9679,0" id="svg_103" stroke="#000000"/>
     </g>
     <text font-weight="bold" fill="#000000" stroke-width="0" x="93.90708" y="84.13089" id="svg_104" font-size="24" font-family="Helvetica, Arial, sans-serif" text-anchor="start" xml:space="preserve" stroke="#000">(正面)</text>
     <text font-weight="bold" fill="#000000" stroke-width="0" x="358.32277" y="83.91505" id="svg_105" font-size="24" font-family="Helvetica, Arial, sans-serif" text-anchor="start" xml:space="preserve" stroke="#000">(背面)</text>
    </g>
   </svg>
    `);

    this._tools[Tool.People2] = new People1(fabricCanvas, `<svg width="512" height="512" xmlns="http://www.w3.org/2000/svg">
    <g>
     <title>background</title>
     <rect x="-1" y="-1" width="514" height="514" id="canvas_background" fill="none"/>
    </g>
   
    <g>
     <title>Layer 1</title>
     <g id="svg_1" stroke="null">
      <g id="svg_2" stroke="null">
       <ellipse fill="none" stroke-width="3" stroke-miterlimit="10" cx="124.96224" cy="138.59041" rx="36.76159" ry="41.04311" id="svg_3" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m114.42258,177.91835l0,6.30232c0,0 -18.37053,1.67907 -28.87052,7.13541" id="svg_4" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m46.65901,255.00218c10.42885,-40.47053 34.24159,-60.45947 34.24159,-60.45947c1.21075,-1.18902 2.82645,-2.23876 4.65146,-3.18663" id="svg_5" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m46.65901,255.00218c0,0 -11.12383,9.33935 -20.16271,39.32277c0,0 -24.50773,12.28869 -21.03144,17.20339c0,0 0.34749,2.78556 9.90761,-3.76824c0,0 8.9609,-2.41802 11.99393,-7.3727" id="svg_6" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m83.68053,230.58987c1.21622,3.2769 5.91009,44.73011 5.91009,44.73011c-3.47628,18.84248 -1.38997,42.76345 -1.38997,42.76345l3.92775,37.52119" id="svg_7" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m62.30159,260.90085c13.38389,-8.84801 21.37893,-30.31098 21.37893,-30.31098" id="svg_8" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m19.02112,332.91136c0,0 -5.82253,1.72034 -5.64879,-3.19565" id="svg_9" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m87.24437,424.58325c0,0 -0.74697,-49.48103 4.88403,-68.97864" id="svg_10" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m20.70796,305.70189l-9.42194,17.05122c-3.99752,6.30748 2.08632,6.96389 2.08632,6.96389" id="svg_11" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m125.92263,315.22952c-1.21075,0.25663 -4.34912,1.21223 -5.74045,4.49171l-13.38389,110.59562" id="svg_12" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m126.43977,315.13409c0,0 -0.21479,0.03224 -0.51713,0.09543" id="svg_13" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m25.54,333.73026c0,0 -5.91009,2.458 -6.51752,-0.8189" id="svg_14" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m62.30159,260.90085c0,0 -8.86514,23.59341 -16.51269,30.47476c0,0 -9.64631,21.62804 -9.47256,29.08327c0,0 -4.08508,15.81061 -10.77634,13.27137" id="svg_15" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m87.24437,424.58325l-23.11776,27.19787c-12.60135,10.89591 -2.43381,17.36717 -2.43381,17.36717c13.90513,6.06245 22.16147,-1.06522 22.16147,-1.06522l25.02896,-21.70929c1.91257,-3.76824 -2.08632,-16.05693 -2.08632,-16.05693" id="svg_16" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="20.75994" y1="316.28055" x2="13.37233" y2="329.71571" id="svg_17" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="25.85877" y1="318.35554" x2="19.02112" y2="332.91136" id="svg_18" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="30.03003" y1="318.90233" x2="25.54" y2="333.73026" id="svg_19" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m85.55616,191.33029c0,0 -0.00274,0.02063 -0.0041,0.02579" id="svg_20" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m83.68053,230.58987c-1.89205,-11.72126 1.79492,-38.68184 1.87153,-39.2325" id="svg_21" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m46.65901,255.00218c0,0 10.08136,5.89867 15.64396,5.89867" id="svg_22" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m115.42401,359.03756c-13.62194,3.10667 -23.29561,-3.43423 -23.29561,-3.43423" id="svg_23" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m87.24437,424.58325c0,0 0.60879,5.89867 19.55392,5.73489" id="svg_24" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m137.42269,177.91835l0,6.30232c0,0 18.37053,1.67907 28.87052,7.13541" id="svg_25" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m166.29458,191.35608c1.82638,0.94915 3.44208,1.99761 4.65146,3.18663c0,0 23.81274,19.98895 34.24159,60.45947" id="svg_26" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m224.48162,300.38741c3.03303,4.95468 11.99393,7.3727 11.99393,7.3727c9.56012,6.5538 9.90761,3.76824 9.90761,3.76824c3.47628,-4.91599 -21.03144,-17.20339 -21.03144,-17.20339c-9.03888,-29.98342 -20.16271,-39.32277 -20.16271,-39.32277" id="svg_27" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m145.04835,430.31815c0,0 -3.99752,12.28869 -2.08632,16.05693l25.02896,21.70929c0,0 8.25634,7.12767 22.16147,1.06522c0,0 10.16755,-6.47255 -2.43381,-17.36717l-23.11776,-27.19787" id="svg_28" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m145.04835,430.31815l-13.38389,-110.59562c-1.39133,-3.27948 -4.5297,-4.23637 -5.74045,-4.49171" id="svg_29" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m231.13731,305.70189l9.42194,17.05122c3.99752,6.30748 -2.08632,6.96389 -2.08632,6.96389" id="svg_30" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m125.40687,315.13409c0,0 0.21479,0.03224 0.51713,0.09543" id="svg_31" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m164.60227,424.58325c0,0 0.74697,-49.48103 -4.88403,-68.97864" id="svg_32" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m168.16474,230.58987c-1.21622,3.2769 -5.91009,44.73011 -5.91009,44.73011c3.47628,18.84248 1.38997,42.76345 1.38997,42.76345l-3.92775,37.52119" id="svg_33" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m238.47294,329.71571c0.17375,4.91599 -5.64879,3.19565 -5.64879,3.19565" id="svg_34" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m226.30663,333.73026c-6.69126,2.53924 -10.77634,-13.27137 -10.77634,-13.27137c0.17375,-7.45523 -9.47256,-29.08327 -9.47256,-29.08327c-7.64755,-6.88136 -16.51269,-30.47476 -16.51269,-30.47476" id="svg_35" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m232.82415,332.91136c-0.60879,3.2769 -6.51752,0.8189 -6.51752,0.8189" id="svg_36" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m168.16474,230.58987c0,0 7.99504,21.46426 21.37893,30.31098" id="svg_37" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="231.08669" y1="316.28055" x2="238.47294" y2="329.71571" id="svg_38" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="232.82415" y1="332.91136" x2="225.98787" y2="318.35554" id="svg_39" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="221.8166" y1="318.90233" x2="226.30663" y2="333.73026" id="svg_40" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m166.29458,191.35608c-0.00137,-0.00516 -0.0041,-0.02579 -0.0041,-0.02579" id="svg_41" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m168.16474,230.58987c1.89205,-11.72126 -1.79492,-38.68184 -1.87153,-39.2325" id="svg_42" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m189.54504,260.90085c5.5626,0 15.64396,-5.89867 15.64396,-5.89867" id="svg_43" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m159.71823,355.60333c0,0 -9.67367,6.53961 -23.29561,3.43423" id="svg_44" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m164.60227,424.58325c0,0 -0.60879,5.89867 -19.55392,5.73489" id="svg_45" stroke="#000000"/>
      </g>
      <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m89.59062,275.31869c0,0 28.98955,11.68773 72.6654,0" id="svg_46" stroke="#000000"/>
     </g>
     <g id="svg_47" stroke="null">
      <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="286.92203" y1="307.57933" x2="279.57817" y2="321.74885" id="svg_48" stroke="#000000"/>
      <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="291.99159" y1="309.76773" x2="285.19454" y2="325.11918" id="svg_49" stroke="#000000"/>
      <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="296.13891" y1="310.34441" x2="291.67465" y2="325.98285" id="svg_50" stroke="#000000"/>
      <g id="svg_51" stroke="null">
       <g id="svg_52" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m349.80522,189.96875c0,0 -0.09522,0.73445 -0.24892,2.01839" id="svg_53" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.0593,3.0593" d="m349.08974,196.11776c-0.8515,7.92258 -2.09203,21.78472 -1.55338,31.14901" id="svg_54" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m347.69415,229.34773c0.06801,0.71405 0.15098,1.39138 0.24756,2.02655" id="svg_55" stroke="#000000"/>
       </g>
      </g>
      <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m356.3411,363.22103" id="svg_56" stroke="#000000"/>
      <g id="svg_57" stroke="null">
       <g id="svg_58" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m311.89031,251.74042c0,0 0.50328,0.58892 1.36703,1.50699" id="svg_59" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.6473,3.6473" d="m316.78848,256.72927c1.72068,1.57092 3.75422,3.24384 5.86257,4.58218" id="svg_60" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m324.80565,262.53825c0.62842,0.31418 1.25685,0.58484 1.87847,0.80382" id="svg_61" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_62" stroke="null">
       <g id="svg_63" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m355.55489,356.98225c0,0 0.63795,0.41347 1.79142,0.96839" id="svg_64" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="2.9308,2.9308" d="m361.04748,359.41411c3.45769,1.10032 8.29737,1.94222 13.78588,0.88134" id="svg_65" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m376.78528,359.84254c0.64203,-0.17409 1.29221,-0.37675 1.94784,-0.60796" id="svg_66" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_67" stroke="null">
       <g id="svg_68" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m350.94645,427.65305c0,0 0.59306,0.45019 1.75877,1.02008" id="svg_69" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.7288,3.7288" d="m357.48641,430.32429c2.05258,0.46651 4.57852,0.77254 7.56013,0.65693" id="svg_70" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m367.5888,430.78945c0.65563,-0.07345 1.32894,-0.16729 2.02265,-0.2829" id="svg_71" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_72" stroke="null">
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m430.80117,189.99595c-10.44109,-5.75458 -28.70482,-7.52543 -28.70482,-7.52543l0,-6.64681l0,0c13.76003,-5.80491 23.44211,-20.42326 23.44211,-37.69518c0,-22.34509 -16.36486,-40.4589 -36.5506,-40.4589c-20.1871,0 -36.5506,18.11381 -36.5506,40.4589c0,18.27431 11.0423,33.75769 26.07279,38.77782l0,0l0,5.56417c0,0 -25.20905,2.41962 -33.33095,10.88624c0,0 -22.91707,15.70236 -33.28606,58.38366c0,0 -10.19625,4.66514 -21.08348,36.28883c0,0 -22.54845,9.35477 -19.09212,14.53947c0,0 0.3455,2.93782 9.85075,-3.97421c0,0 8.90947,-2.55019 11.92509,-7.77569" id="svg_73" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m390.30183,316.02691c0.01768,0 0.03129,-0.00408 0.04761,-0.00408" id="svg_74" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m286.8717,296.42243l-9.36787,17.98324c-3.97458,6.65225 2.07434,7.34454 2.07434,7.34454c-0.17275,5.1847 5.61637,3.36897 5.61637,3.36897c0.6053,3.45601 6.48011,0.86366 6.48011,0.86366c6.65286,2.67804 10.71449,-13.99679 10.71449,-13.99679c-0.17275,-7.86274 5.87073,-17.04478 5.87073,-17.04478c7.60366,-7.25749 18.42424,-31.59921 18.42424,-31.59921c13.30708,-9.33165 21.25623,-31.96779 21.25623,-31.96779c1.20924,3.45601 5.87617,47.17507 5.87617,47.17507c-3.45633,19.87242 -1.38199,45.10092 -1.38199,45.10092l2.31374,34.70976c-5.59869,20.56335 -3.80183,69.29302 -3.80183,69.29302s-17.97265,45.61912 7.43091,43.71906c0,0 21.94723,5.70562 11.23274,-40.86557l13.65258,-106.66314c0,0 0.17411,-7.60568 7.0378,-7.81514" id="svg_75" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m430.80117,189.99595c1.8159,1.00103 3.42233,2.1068 4.62476,3.36081c0,0 22.91707,15.70236 33.28606,58.38366c0,0 10.19625,4.66514 21.08348,36.28883c0,0 22.54845,9.35477 19.09212,14.53947c0,0 -0.3455,2.93782 -9.85075,-3.97421c0,0 -8.90947,-2.55019 -11.92509,-7.77569" id="svg_76" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m501.02413,321.74885c0.17275,5.1847 -5.61637,3.36897 -5.61637,3.36897" id="svg_77" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m493.73061,296.42243l9.36787,17.98324c3.97458,6.65225 -2.07434,7.34454 -2.07434,7.34454" id="svg_78" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m495.40776,325.11918c-0.6053,3.45601 -6.48011,0.86366 -6.48011,0.86366" id="svg_79" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m432.66059,231.37292c-1.20924,3.45601 -5.87617,47.17507 -5.87617,47.17507c3.45633,19.87242 1.38199,45.10092 1.38199,45.10092l-2.31374,34.70976c5.59869,20.56335 3.80183,69.29302 3.80183,69.29302s17.97265,45.61912 -7.43091,43.71906c0,0 -21.94723,5.70562 -11.23274,-40.86557l-13.65258,-106.66314c0,0 -0.17411,-7.60568 -7.0378,-7.81514" id="svg_80" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m390.25286,316.02283c0.01768,0 0.03129,0.00408 0.04761,0.00408" id="svg_81" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m488.92765,325.98285c-6.65286,2.67804 -10.71449,-13.99679 -10.71449,-13.99679c0.17275,-7.86274 -5.87073,-17.04478 -5.87073,-17.04478c-7.60366,-7.25749 -18.42424,-31.59921 -18.42424,-31.59921c-13.30708,-9.33165 -21.25623,-31.96779 -21.25623,-31.96779" id="svg_82" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="493.68028" y1="307.57933" x2="501.02413" y2="321.74885" id="svg_83" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="495.40776" y1="325.11918" x2="488.61072" y2="309.76773" id="svg_84" stroke="#000000"/>
       <line fill="none" stroke-width="3" stroke-miterlimit="10" x1="484.46339" y1="310.34441" x2="488.92765" y2="325.98285" id="svg_85" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m430.80117,189.99595c-0.00136,-0.00544 -0.00408,-0.0272 -0.00408,-0.0272" id="svg_86" stroke="#000000"/>
       <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m430.80117,189.99595c0.07481,0.57532 3.74198,29.01502 1.86079,41.37833" id="svg_87" stroke="#000000"/>
      </g>
      <g id="svg_88" stroke="null">
       <g id="svg_89" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m468.71199,251.74042c0,0 -0.50328,0.58892 -1.36703,1.50699" id="svg_90" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.6473,3.6473" d="m463.81382,256.72927c-1.72068,1.57092 -3.75422,3.24384 -5.86257,4.58218" id="svg_91" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m455.79666,262.53825c-0.62842,0.31418 -1.25685,0.58484 -1.87847,0.80382" id="svg_92" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_93" stroke="null">
       <g id="svg_94" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m425.04742,356.98225c0,0 -0.63795,0.41347 -1.79142,0.96839" id="svg_95" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="2.9308,2.9308" d="m419.55483,359.41411c-3.45769,1.10032 -8.29737,1.94222 -13.78588,0.88134" id="svg_96" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m403.81703,359.84254c-0.64203,-0.17409 -1.29221,-0.37675 -1.94784,-0.60796" id="svg_97" stroke="#000000"/>
       </g>
      </g>
      <g id="svg_98" stroke="null">
       <g id="svg_99" stroke="null">
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m429.65586,427.65305c0,0 -0.59306,0.45019 -1.75877,1.02008" id="svg_100" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3.7288,3.7288" d="m423.1159,430.32429c-2.05258,0.46651 -4.57852,0.77254 -7.56013,0.65693" id="svg_101" stroke="#000000"/>
        <path fill="none" stroke-width="3" stroke-miterlimit="10" d="m413.01351,430.78945c-0.65563,-0.07345 -1.3303,-0.16729 -2.02265,-0.2829" id="svg_102" stroke="#000000"/>
       </g>
      </g>
      <path fill="none" stroke-width="3" stroke-miterlimit="10" stroke-dasharray="3" d="m353.81788,278.54799c0,0 22.30225,10.5639 72.9679,0" id="svg_103" stroke="#000000"/>
     </g>
     <text font-weight="bold" fill="#000000" stroke-width="0" x="93.90708" y="84.13089" id="svg_104" font-size="24" font-family="Helvetica, Arial, sans-serif" text-anchor="start" xml:space="preserve" stroke="#000">(正面)</text>
     <text font-weight="bold" fill="#000000" stroke-width="0" x="358.32277" y="83.91505" id="svg_105" font-size="24" font-family="Helvetica, Arial, sans-serif" text-anchor="start" xml:space="preserve" stroke="#000">(背面)</text>
    </g>
   </svg>
    `);
    this._tools[Tool.GuestRoom] = new RectangleText(fabricCanvas, "居室");
    this._tools[Tool.LivingRoom] = new RectangleText(fabricCanvas, "居間");
    this._tools[Tool.KitchenRoom] = new RectangleText(fabricCanvas, "キッチン");
    this._tools[Tool.Canteen] = new RectangleText(fabricCanvas, "食堂");
    this._tools[Tool.Toilet] = new RectangleText(fabricCanvas, "トイレ");
    this._tools[Tool.Entrance] = new RectangleText(fabricCanvas, "入口");
    this._tools[Tool.Space] = new RectangleText(fabricCanvas, "スペース");
    this._tools[Tool.Kaidan] = new Kaidan(fabricCanvas, "スペース");
    this._tools[Tool.Living] = new Living(fabricCanvas);
    this._tools[Tool.F1] = new CircleText(fabricCanvas, "1F");
    this._tools[Tool.F2] = new CircleText(fabricCanvas, "2F");
    this._tools[Tool.F3] = new CircleText(fabricCanvas, "3F");
    this._tools[Tool.F4] = new CircleText(fabricCanvas, "4F");
    this._tools[Tool.F5] = new CircleText(fabricCanvas, "5F");
    this._tools[Tool.XTriangle] = new XTriangle(fabricCanvas);

  };

  /**
   * Enable touch Scrolling on Canvas
   */
  enableTouchScroll = () => {
    const canvas = this._fc;
    if (canvas.allowTouchScrolling) return;
    canvas.allowTouchScrolling = true;
  };

  /**
   * Disable touch Scrolling on Canvas
   */
  disableTouchScroll = () => {
    const canvas = this._fc;
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false;
    }
  };

  /**
   * Add an image as object to the canvas
   *
   * @param dataUrl the image url or Data Url
   * @param options object to pass and change some options when loading image, the format of the object is:
   *
   * {
   *   left: <Number: distance from left of canvas>,
   *   top: <Number: distance from top of canvas>,
   *   scale: <Number: initial scale of image>
   * }
   */
  addImg = (dataUrl, options = {}) => {
    const canvas = this._fc;
    fabric.Image.fromURL(dataUrl, (oImg) => {
      const opts = {
        left: Math.random() * (canvas.getWidth() - oImg.width * 0.5),
        top: Math.random() * (canvas.getHeight() - oImg.height * 0.5),
        scale: 0.5
      };
      Object.assign(opts, options);
      oImg.scale(opts.scale);
      oImg.set({
        left: opts.left,
        top: opts.top
      });
      canvas.add(oImg);
    });
  };

  /**
   * Action when an object is added to the canvas
   */
  _onObjectAdded = (e) => {
    if (!this.state.action) {
      this.setState({ action: true });
      return;
    }
    const obj = e.target;
    obj.__version = 1;
    // record current object state as json and save as originalState
    const objState = obj.toJSON();
    obj.__originalState = objState;
    const state = JSON.stringify(objState);
    // object, previous state, current state
    this._history.keep([obj, state, state]);
  };

  /**
   * Action when an object is moving around inside the canvas
   */
  _onObjectMoving = () => {

  };

  /**
   * Action when an object is scaling inside the canvas
   */
  _onObjectScaling = () => {

  };

  /**
   * Action when an object is rotating inside the canvas
   */
  _onObjectRotating = () => {

  };

  _onObjectModified = (e) => {
    const obj = e.target;
    obj.__version += 1;
    const prevState = JSON.stringify(obj.__originalState);
    const objState = obj.toJSON();
    // record current object state as json and update to originalState
    obj.__originalState = objState;
    const currState = JSON.stringify(objState);
    this._history.keep([obj, prevState, currState]);
  };

  /**
   * Action when an object is removed from the canvas
   */
  _onObjectRemoved = (e) => {
    const obj = e.target;
    if (obj.__removed) {
      obj.__version += 1;
      return;
    }
    obj.__version = 0;
  };

  /**
   * Action when the mouse button is pressed down
   */
  _onMouseDown = (e) => {
    this._selectedTool.doMouseDown(e);
  };

  /**
   * Action when the mouse cursor is moving around within the canvas
   */
  _onMouseMove = (e) => {
    this._selectedTool.doMouseMove(e);
  };

  /**
   * Action when the mouse cursor is moving out from the canvas
   */
  _onMouseOut = (e) => {
    this._selectedTool.doMouseOut(e);
    if (this.props.onChange) {
      const onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
  };

  _onMouseUp = (e) => {
    this._selectedTool.doMouseUp(e);
    // Update the final state to new-generated object
    // Ignore Path object since it would be created after mouseUp
    // Assumed the last object in canvas.getObjects() in the newest object
    if (this.props.tool !== Tool.Pencil) {
      const canvas = this._fc;
      const objects = canvas.getObjects();
      const newObj = objects[objects.length - 1];
      if (newObj && newObj.__version === 1) {
        newObj.__originalState = newObj.toJSON();
      }
    }
    if (this.props.onChange) {
      const onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
  };

  /**
   * Track the resize of the window and update our state
   *
   * @param e the resize event
   * @private
   */
  _resize = (e) => {
    if (e) e.preventDefault();
    const { widthCorrection, heightCorrection } = this.props;
    const canvas = this._fc;
    const { offsetWidth, clientHeight } = this._container;
    const prevWidth = canvas.getWidth();
    const prevHeight = canvas.getHeight();
    const wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
    const hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);
    canvas.setWidth(offsetWidth - widthCorrection);
    canvas.setHeight(clientHeight - heightCorrection);
    if (canvas.backgroundImage) {
      // Need to scale background images as well
      const bi = canvas.backgroundImage;
      bi.width = bi.width * wfactor;
      bi.height = bi.height * hfactor;
    }
    const objects = canvas.getObjects();
    for (const i in objects) {
      const obj = objects[i];
      const scaleX = obj.scaleX;
      const scaleY = obj.scaleY;
      const left = obj.left;
      const top = obj.top;
      const tempScaleX = scaleX * wfactor;
      const tempScaleY = scaleY * hfactor;
      const tempLeft = left * wfactor;
      const tempTop = top * hfactor;
      obj.scaleX = tempScaleX;
      obj.scaleY = tempScaleY;
      obj.left = tempLeft;
      obj.top = tempTop;
      obj.setCoords();
    }
    this.setState({
      parentWidth: offsetWidth
    });

    canvas.renderAll();
    canvas.calcOffset();
  };

  /**
   * Sets the background color for this sketch
   * @param color in rgba or hex format
   */
  _backgroundColor = (color) => {
    if (!color) return;
    const canvas = this._fc;
    canvas.setBackgroundColor(color, () => canvas.renderAll());
  };

  /**
   * Zoom the drawing by the factor specified
   *
   * The zoom factor is a percentage with regards the original, for example if factor is set to 2
   * it will double the size whereas if it is set to 0.5 it will half the size
   *
   * @param factor the zoom factor
   */
  zoom = (factor, isScale = false) => {
    const canvas = this._fc;
    const objects = canvas.getObjects();
    for (const i in objects) {
      objects[i].scaleX = objects[i].scaleX * factor;
      objects[i].scaleY = objects[i].scaleY * factor;
      objects[i].left = objects[i].left * factor;
      objects[i].top = objects[i].top * factor;
      objects[i].setCoords();
    }
    // if (isScale) {
    //   const w = canvas.getWidth() * factor;
    //   const h = canvas.getHeight() * factor;
    //   canvas.setWidth(w);
    //   canvas.setHeight(h);
    // }
    canvas.renderAll();
    canvas.calcOffset();

  };
  renderAll = () => {
    const canvas = this._fc;
    canvas.discardActiveObject();
    canvas.renderAll();


  }

  /**
   * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
   */
  undo = () => {
    const history = this._history;
    const [obj, prevState] = history.getCurrent();
    if (obj.width === 801.11) return;
    history.undo();
    if (obj.__removed) {
      this.setState({ action: false }, () => {
        this._fc.add(obj);
        obj.__version -= 1;
        obj.__removed = false;
      });
    }
    else if (obj.__version <= 1) {
      this._fc.remove(obj);
    }
    else {
      obj.__version -= 1;
      obj.setOptions(JSON.parse(prevState));
      obj.setCoords();
      this._fc.renderAll();
    }
    if (this.props.onChange) {
      this.props.onChange();
    }
  };

  /**
   * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
   */
  redo = () => {
    const history = this._history;
    if (history.canRedo()) {
      const canvas = this._fc;
      // noinspection Eslint
      const [obj, currState] = history.redo();
      if (obj.__version === 0) {
        this.setState({ action: false }, () => {
          canvas.add(obj);
          obj.__version = 1;
        });
      }
      else {
        obj.__version += 1;
        obj.setOptions(JSON.parse(currState));
      }
      obj.setCoords();
      canvas.renderAll();
      if (this.props.onChange) {
        this.props.onChange();
      }
    }
  };

  /**
   * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can undo otherwise false
   */
  canUndo = () => {
    return this._history.canUndo();
  };

  /**
   * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can redo otherwise false
   */
  canRedo = () => {
    return this._history.canRedo();
  };

  /**
   * Exports canvas element to a dataurl image. Note that when multiplier is used, cropping is scaled appropriately
   *
   * Available Options are
   * <table style="width:100%">
   *
   * <tr><td><b>Name</b></td><td><b>Type</b></td><td><b>Argument</b></td><td><b>Default</b></td><td><b>Description</b></td></tr>
   * <tr><td>format</td> <td>String</td> <td><optional></td><td>png</td><td>The format of the output image. Either "jpeg" or "png"</td></tr>
   * <tr><td>quality</td><td>Number</td><td><optional></td><td>1</td><td>Quality level (0..1). Only used for jpeg.</td></tr>
   * <tr><td>multiplier</td><td>Number</td><td><optional></td><td>1</td><td>Multiplier to scale by</td></tr>
   * <tr><td>left</td><td>Number</td><td><optional></td><td></td><td>Cropping left offset. Introduced in v1.2.14</td></tr>
   * <tr><td>top</td><td>Number</td><td><optional></td><td></td><td>Cropping top offset. Introduced in v1.2.14</td></tr>
   * <tr><td>width</td><td>Number</td><td><optional></td><td></td><td>Cropping width. Introduced in v1.2.14</td></tr>
   * <tr><td>height</td><td>Number</td><td><optional></td><td></td><td>Cropping height. Introduced in v1.2.14</td></tr>
   *
   * </table>
   *
   * @returns {String} URL containing a representation of the object in the format specified by options.format
   */
  toDataURL = (options) => this._fc.toDataURL(options);

  /**
   * Returns JSON representation of canvas
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string
   */
  toJSON = (propertiesToInclude) => this._fc.toJSON(propertiesToInclude);

  /**
   * Populates canvas with data from the specified JSON.
   *
   * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
   *
   * @param json JSON string or object
   */
  fromJSON = (json) => {
    if (!json) return;
    const canvas = this._fc;
    setTimeout(() => {
      canvas.loadFromJSON(json, () => {
        canvas.renderAll();
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
    }, 100);
  };

  /**
   * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
   * used as needed in order to undo the clear if possible
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string of the canvas just cleared
   */
  clear = (propertiesToInclude) => {
    const discarded = this.toJSON(propertiesToInclude);
    this._fc.clear();
    this._history.clear();
    return discarded;
  };

  /**
   * Remove selected object from the canvas
   */
  removeSelected = () => {
    const canvas = this._fc;
    const activeObj = canvas.getActiveObject();
    if (activeObj) {
      const selected = [];
      if (activeObj.type === "activeSelection") {
        activeObj.forEachObject(obj => selected.push(obj));
      }
      else {
        selected.push(activeObj);
      }
      selected.forEach(obj => {
        obj.__removed = true;
        const objState = obj.toJSON();
        obj.__originalState = objState;
        const state = JSON.stringify(objState);
        this._history.keep([obj, state, state]);
        canvas.remove(obj);
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
  };

  copy = () => {
    const canvas = this._fc;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().clone(cloned => this._clipboard = cloned);
    }
  };

  paste = () => {
    // clone again, so you can do multiple copies.
    if (this._clipboard) {
      this._clipboard.clone(clonedObj => {
        const canvas = this._fc;
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        if (clonedObj.type === "activeSelection") {
          // active selection needs a reference to the canvas.
          clonedObj.canvas = canvas;
          clonedObj.forEachObject(obj => canvas.add(obj));
          clonedObj.setCoords();
        }
        else {
          canvas.add(clonedObj);
        }
        this._clipboard.top += 10;
        this._clipboard.left += 10;
        canvas.setActiveObject(clonedObj);
        canvas.requestRenderAll();
      });
    }
  };

  /**
   * Sets the background from the dataUrl given
   *
   * @param dataUrl the dataUrl to be used as a background
   * @param options
   */
  setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    const canvas = this._fc;
    if (options.stretched) {
      delete options.stretched;
      Object.assign(options, {
        width: canvas.width,
        height: canvas.height
      });
    }
    if (options.stretchedX) {
      delete options.stretchedX;
      Object.assign(options, {
        width: canvas.width
      });
    }
    if (options.stretchedY) {
      delete options.stretchedY;
      Object.assign(options, {
        height: canvas.height
      });
    }
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => canvas.setBackgroundImage(new fabric.Image(img),
      () => canvas.renderAll(), options);
    img.src = dataUrl;
  };

  addText = (text, options = {}) => {
    const canvas = this._fc;
    const iText = new fabric.IText(text, options);
    const opts = {
      left: (canvas.getWidth() - iText.width) * 0.5,
      top: (canvas.getHeight() - iText.height) * 0.5,
    };
    Object.assign(options, opts);
    iText.set({
      left: options.left,
      top: options.top
    });

    canvas.add(iText);
  };

  componentDidMount = () => {
    const {
      tool,
      value,
      undoSteps,
      defaultValue,
      backgroundColor
    } = this.props;

    const canvas = this._fc = new fabric.Canvas(this._canvas/* , {
         preserveObjectStacking: false,
         renderOnAddRemove: false,
         skipTargetFind: true
         }*/);

    fabric.Object.prototype.set({
      padding: 15
    });

//     fabric.Object.setControlsVisibility({
//     bl: true,
//     br: true,
//     mb: false,
//     ml: false,
//     mr: false,
//     mt: false,
//     tl: true,
//     tr: true,
//     mtr: true,
// })
    

    this._initTools(canvas);

    // set initial backgroundColor
    this._backgroundColor(backgroundColor);

    const selectedTool = this._tools[tool];
    selectedTool.configureCanvas(this.props);
    this._selectedTool = selectedTool;

    // Control resize
    window.addEventListener("resize", this._resize, false);
    window.addEventListener("keyup", (e) => {
      if (e.keyCode === 46) {
        this.removeSelected();
      }

    });

    // Initialize History, with maximum number of undo steps
    this._history = new History(undoSteps);

    // Events binding
    canvas.on("object:added", this._onObjectAdded);
    canvas.on("object:modified", this._onObjectModified);
    canvas.on("object:removed", this._onObjectRemoved);
    canvas.on("mouse:down", this._onMouseDown);
    canvas.on("mouse:move", this._onMouseMove);
    canvas.on("mouse:up", this._onMouseUp);
    canvas.on("mouse:out", this._onMouseOut);
    canvas.on("object:moving", this._onObjectMoving);
    canvas.on("object:scaling", this._onObjectScaling);
    canvas.on("object:rotating", this._onObjectRotating);

    this.disableTouchScroll();

    this._resize();

    // initialize canvas with controlled value if exists
    (value || defaultValue) && this.fromJSON(value || defaultValue);

  };

  componentWillUnmount = () => window.removeEventListener("resize", this._resize);

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.parentWidth !== prevState.parentWidth
      || this.props.width !== prevProps.width
      || this.props.height !== prevProps.height) {

      this._resize();
    }

    if (this.props.tool !== prevProps.tool) {
      this._selectedTool = this._tools[this.props.tool] || this._tools[Tool.Pencil];
    }

    // Bring the cursor back to default if it is changed by a tool
    this._fc.defaultCursor = "default";
    this._selectedTool.configureCanvas(this.props);

    if (this.props.backgroundColor !== prevProps.backgroundColor) {
      this._backgroundColor(this.props.backgroundColor);
    }

    if ((this.props.value !== prevProps.value) || (this.props.value && this.props.forceValue)) {
      this.fromJSON(this.props.value);
    }
  };

  render = () => {
    const {
      className,
      style,
      width,
      height
    } = this.props;

    const canvasDivStyle = Object.assign({}, style ? style : {},
      width ? { width } : {},
      height ? { height } : { height: 512 });

    return (
      <div
        className={className}
        ref={(c) => this._container = c}
        style={canvasDivStyle}>
        <canvas
          id={uuid4()}
          ref={(c) => this._canvas = c}>
          Sorry, Canvas HTML5 element is not supported by your browser
          :(
        </canvas>
      </div>
    );
  }
}

export default SketchField;