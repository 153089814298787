export const MonitoringMNGTActionType = {
  CREATE_BATCH_REQUEST: "CREATE_BATCH_REQUEST",
  CREATE_BATCH_SUCCESS: "CREATE_BATCH_SUCCESS",
  CREATE_BATCH_FAILURE: "CREATE_BATCH_FAILURE",

  GET_ALL_REQUEST: "GET_ALL_MONITORING_MANAGEMENT_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_MONITORING_MANAGEMENT_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_MONITORING_MANAGEMENT_FAILURE",

  KEEP_VALUE_SEARCH: "KEEP_VALUE_SEARCH_MONITORING_MANAGEMENT",
};
