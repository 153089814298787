import { Col, DatePicker, Modal, Row, Table } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { loadTantoKaigiByDate } from "../../../actions/tantoKaigiActions/actionCreators";
import LineBreakText from "../../../common/component/LineBreakText";
import { ConstSet } from "../../../common/configs/constset";
dayjs.extend(dayjsPluginUTC);

class TangoKaigiModal extends Component {
  constructor(props) {
    super(props);
    const startDate =
      props.monitoringDate ||
      dayjs(new Date(), ConstSet.DATE_FORMAT).format(ConstSet.DATE_FORMAT);
    const endDate =
      props.monitoringDate ||
      dayjs(new Date(), ConstSet.DATE_FORMAT).format(ConstSet.DATE_FORMAT);
    this.state = {
      page: 1,
      startDate: dayjs
        .utc(startDate, ConstSet.DATE_FORMAT)
        .subtract(6, "months"),
      endDate: dayjs.utc(endDate, ConstSet.DATE_FORMAT),
      selectedCell: "",
    };
    this.selectedCellRef = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      const { page, startDate, endDate } = this.state;

      this.props.getTantoKaigis({
        riyousyaId: this.props.riyousyaId,
        page,
        startDate: startDate.format(ConstSet.DATE_FORMAT),
        endDate: endDate.format(ConstSet.DATE_FORMAT),
      });
    }
    if (
      this.props.monitoringDate &&
      this.props.monitoringDate !== prevProps.monitoringDate
    ) {
      this.setState({
        startDate: dayjs(
          this.props.monitoringDate,
          ConstSet.DATE_FORMAT
        ).subtract(6, "months"),
        endDate: dayjs(this.props.monitoringDate, ConstSet.DATE_FORMAT),
      });
    }
  }

  handleOk() {
    if (this.state.selectedCell) {
      this.props.onSelectItem(this.state.selectedCell);
      this.setState({ selectedCell: "" });

      if (this.selectedCellRef) {
        this.selectedCellRef.classList.remove(
          "tanto-kaigi-modal__td--selected"
        );
        this.selectedCellRef = null;
      }
    }
    this.props.onHide();
  }

  handleCancel() {
    this.props.onHide();
  }

  handleRangePickerChange(date) {
    const startDate = date[0];
    const endDate = date[1];

    this.setState({ startDate, endDate, page: 1 });
    this.props.getTantoKaigis({
      riyousyaId: this.props.riyousyaId,
      page: 1,
      startDate: startDate.format(ConstSet.DATE_FORMAT),
      endDate: endDate.format(ConstSet.DATE_FORMAT),
    });
  }

  handleSelectCell(name, record, rowIndex, e) {
    if (this.selectedCellRef) {
      this.selectedCellRef.classList.remove("tanto-kaigi-modal__td--selected");
    }
    this.selectedCellRef = e.target;
    this.selectedCellRef.classList.add("tanto-kaigi-modal__td--selected");

    this.setState({
      selectedCell: record[name],
    });
  }

  handlePageChange(page) {
    this.setState({ page });
    this.props.getTantoKaigis({
      riyousyaId: this.props.riyousyaId,
      page,
      startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
      endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
    });
  }

  handleStartDateChange(startDate) {
    this.setState({ startDate, page: 1 });
    if (this.state.endDate) {
      this.props.getTantoKaigis({
        riyousyaId: this.props.riyousyaId,
        page: 1,
        startDate: startDate.format(ConstSet.DATE_FORMAT),
        endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
      });
    }
  }

  handleEndDateChange(endDate) {
    this.setState({ endDate, page: 1 });
    if (this.state.startDate) {
      this.props.getTantoKaigis({
        riyousyaId: this.props.riyousyaId,
        page: 1,
        startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
        endDate: endDate.format(ConstSet.DATE_FORMAT),
      });
    }
  }

  render() {
    const { tantoKaigi } = this.props;
    const { startDate, endDate } = this.state;
    const columns = [
      {
        title: "開催日",
        dataIndex: "kaisaiDate",
        key: "kaisaiDate",
        width: 100,
        className: "table-cell-wordbreak",
        render: (text) => (
          <div title={text} className="tanto-kaigi-modal__td">
            {<LineBreakText text={text} />}
          </div>
        ),
        onCell: (record, rowIndex) => ({
          onClick: (e) =>
            this.handleSelectCell("kaisaiDate", record, rowIndex, e),
        }),
      },
      {
        title: "検討した項目",
        dataIndex: "koumoku",
        key: "koumoku",
        width: 200,
        className: "table-cell-wordbreak",
        render: (text) => (
          <div title={text} className="tanto-kaigi-modal__td">
            {<LineBreakText text={text} />}
          </div>
        ),
        onCell: (record, rowIndex) => ({
          onClick: (e) => this.handleSelectCell("koumoku", record, rowIndex, e),
        }),
      },
      {
        title: "検討内容",
        dataIndex: "naiyou",
        key: "naiyou",
        width: 200,
        className: "table-cell-wordbreak",
        render: (text) => (
          <div title={text} className="tanto-kaigi-modal__td">
            {<LineBreakText text={text} />}
          </div>
        ),
        onCell: (record, rowIndex) => ({
          onClick: (e) => this.handleSelectCell("naiyou", record, rowIndex, e),
        }),
      },
      {
        title: "結論",
        dataIndex: "keturon",
        key: "keturon",
        width: 150,
        className: "table-cell-wordbreak",
        render: (text) => (
          <div title={text} className="tanto-kaigi-modal__td">
            {<LineBreakText text={text} />}
          </div>
        ),
        onCell: (record, rowIndex) => ({
          onClick: (e) => this.handleSelectCell("keturon", record, rowIndex, e),
        }),
      },
      {
        title: "残された課題",
        dataIndex: "kadai",
        key: "kadai",
        width: 150,
        className: "table-cell-wordbreak",
        render: (text) => (
          <div title={text} className="tanto-kaigi-modal__td">
            {<LineBreakText text={text} />}
          </div>
        ),
        onCell: (record, rowIndex) => ({
          onClick: (e) => this.handleSelectCell("kadai", record, rowIndex, e),
        }),
      },
    ];
    const dataSource =
      tantoKaigi && tantoKaigi.tantoKaigisByDate
        ? tantoKaigi.tantoKaigisByDate
        : [];
    const pagingOptions = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: tantoKaigi.metaTantoKaigiByDate.total,
      current: tantoKaigi.metaTantoKaigiByDate.page,
      pageSize: tantoKaigi.metaTantoKaigiByDate.pageSize,
    };

    return (
      (<Modal
        open={this.props.visible}
        title="担当者会議"
        width={1000}
        style={{ maxWidth: "calc(100% - 30px)" }}
        onOk={() => this.handleOk()}
        onCancel={() => this.handleCancel()}
        className="tanto-kaigi-modal"
      >
        <Row>
          <MediaQuery query="(min-device-width: 768px)">
            {(matches) =>
              matches ? (
                <Col span={24} style={{ marginBottom: 5 }}>
                  <span className="text-bold" style={{ marginRight: 10 }}>
                    開催日
                  </span>
                  <DatePicker.RangePicker
                    value={[startDate, endDate]}
                    onChange={(date) => this.handleRangePickerChange(date)}
                  />
                </Col>
              ) : (
                <Col span={24} style={{ marginBottom: 20 }}>
                  <p className="text-bold" style={{ marginBottom: 0 }}>
                    開催日
                  </p>
                  <DatePicker
                    value={startDate}
                    onChange={(date) => this.handleStartDateChange(date)}
                  />
                  <DatePicker
                    value={endDate}
                    onChange={(date) => this.handleEndDateChange(date)}
                  />
                </Col>
              )
            }
          </MediaQuery>

          <Col span={24}>
            <Table
              style={{ cursor: "pointer" }}
              size={"small"}
              bordered={true}
              columns={columns}
              dataSource={dataSource}
              pagination={pagingOptions}
            />
          </Col>
        </Row>
      </Modal>)
    );
  }
}

const mapStateToProps = () => (state) => ({
  tantoKaigi: state.tantoKaigi,
});

const mapDispatchToProps = () => (dispatch) => ({
  getTantoKaigis: ({ riyousyaId, page, startDate, endDate }) =>
    dispatch(loadTantoKaigiByDate({ riyousyaId, page, startDate, endDate })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TangoKaigiModal);
