import { Form } from "@ant-design/compatible";
import { Button, Col, Input, Modal, Row } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { ListNameSet } from "../../../common/configs/listnameset";
import SelectOptionsForm from "./SelectOptionsForm";
import TextAreaSelect from "./TextAreaSelect";

class SyokujisenFormNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
    };
  }

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouConcern",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleSort = (type, sortType, index) => {
    this.props.handleSort(type, sortType, index);
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  render() {
    const { visible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { shushoku, fukushoku, shokujisenbunrui, shokujisenbikou } =
      this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const formItemLayoutCus = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        <Row className="title-inline">
          {/* hidden field place */}
          <Col span={24}>
            <Form.Item
              label={"無し"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("id", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"無し"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("date", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"利用者"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("riyousyaId", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            {/* <Form.Item label={"mainSID"} colon={false} {...formItemLayoutCus} style={{ display: "none" }}>
                            {getFieldDecorator('serviceKikanMasterId', {
                                rules: [{ required: false, message: "必須項目をセットして下さい" }],
                            })(<Input />)}
                        </Form.Item> */}
          </Col>
          <Col span={12}>
            <Form.Item label={"主食"} colon={false} {...formItemLayoutCus}>
              {getFieldDecorator("stapleFood", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<SelectOptionsForm data={shushoku} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={"副食"}
              colon={false}
              {...formItemLayoutCus}
              labelAlign="right"
            >
              {getFieldDecorator("sideFood", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<SelectOptionsForm data={fukushoku} />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={"食事区分"} colon={false}>
              {getFieldDecorator("syokujiKubun", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <TextAreaSelect
                  isMaster={false}
                  isSelect={true}
                  rowSet={1}
                  dataSelect={shokujisenbunrui}
                  yougoMasterUpdate={(naiyou) =>
                    this.yougoMasterUpdate(ListNameSet.SYOKUJI_KUBUN, naiyou)
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={"備考"} colon={false}>
              {getFieldDecorator("tokki", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <TextAreaSelect
                  isMaster={false}
                  isSelect={true}
                  rowSet={1}
                  dataSelect={shokujisenbikou}
                  yougoMasterUpdate={(naiyou) =>
                    this.yougoMasterUpdate(ListNameSet.SYOKUJI_TOKKI, naiyou)
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
      </Form>)
    );
  }
}

export const SyokujisenForm = Form.create({
  name: "input-riyou-syokujisen",
  onValuesChange() { },
  mapPropsToFields(props) {
    let date = new Date();
    if (props.data && props.data.date) {
      const propsDate = new Date(props.data.date);
      date = propsDate;
    }

    // const serviceKikanMasterId = StorageUtils.getValue(getMenu()).si;

    return {
      id: Form.createFormField({
        value: props.data && props.data.id ? props.data.id : null,
      }),
      date: Form.createFormField({
        value: dayjs(date),
      }),
      riyousyaId: Form.createFormField({
        value:
          props.data && props.data.riyousyaId
            ? props.data.riyousyaId
            : props.riyousyaId,
      }),
      // serviceKikanMasterId: Form.createFormField({
      //     value: props.data && props.data.serviceKikanMasterId ? props.data.serviceKikanMasterId : serviceKikanMasterId
      // }),
      stapleFood: Form.createFormField({
        value: props.data ? props.data.stapleFood : null,
      }),
      sideFood: Form.createFormField({
        value: props.data ? props.data.sideFood : null,
      }),
      syokujiKubun: Form.createFormField({
        value: props.data ? props.data.syokujiKubun : null,
      }),
      tokki: Form.createFormField({
        value: props.data ? props.data.tokki : null,
      }),
    };
  }
})(SyokujisenFormNormal);
