export const YougoMasterActionTypes = {
  LOAD_YOUGO_ATTRIBUTE_ERROR: "LOAD_YOUGO_ATTRIBUTE_ERROR",
  GET_YOUGO_MASTER_ERROR: "GET_YOUGO_MASTER_ERROR",
  GET_YOUGO_MASTER_SUCCESS: "GET_YOUGO_MASTER_SUCCESS",
  GET_YOUGO_MASTER_LOADING: "GET_YOUGO_MASTER_LOADING",

  GET_YOUGO_MASTER_BUNRUI1: "GET_YOUGO_MASTER_BUNRUI1",
  GET_YOUGO_MASTER_BUNRUI2: "GET_YOUGO_MASTER_BUNRUI2",
  GET_YOUGO_MASTER_BUNRUI3: "GET_YOUGO_MASTER_BUNRUI3",
  GET_YOUGO_MASTER_BUNRUI4: "GET_YOUGO_MASTER_BUNRUI4",
  GET_YOUGO_MASTER_BUNRUI5: "GET_YOUGO_MASTER_BUNRUI5",
  GET_YOUGO_MASTER_BUNRUI6: "GET_YOUGO_MASTER_BUNRUI6",
  GET_YOUGO_MASTER_BUNRUI7: "GET_YOUGO_MASTER_BUNRUI7",
  GET_YOUGO_MASTER_BUNRUI8: "GET_YOUGO_MASTER_BUNRUI8",
  GET_YOUGO_MASTER_BUNRUI9: "GET_YOUGO_MASTER_BUNRUI9",
  GET_YOUGO_MASTER_BUNRUI10: "GET_YOUGO_MASTER_BUNRUI10",
  GET_YOUGO_MASTER_BUNRUI111: "GET_YOUGO_MASTER_BUNRUI11",
  GET_YOUGO_MASTER_BUNRUI12: "GET_YOUGO_MASTER_BUNRUI12",

  SAVE_YOUGO_MASTER_SUCCESS: "SAVE_YOUGO_MASTER_SUCCESS",
  SAVE_YOUGO_MASTER_ERROR: "SAVE_YOUGO_MASTER_ERROR",

  YOUGO_EDIT_STATE: "YOUGO_EDIT_STATE",
  YOUGO_CANCEL_STATE: "YOUGO_CANCEL_STATE",
};
