import React from "react";
import { EnterOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Col, Row, InputNumber, Checkbox, Radio, Button, Popover } from "antd";

// import { convertMessageByCode } from '../../../common/function_common/functionCommon'
import {
  handleInputNumber,
  getValueIskeep,
} from "../function_common/functionCommon";
import TantoMasterKB from "../model-kiroku/TantoMasterKB";
import CommentKB from "../model-kiroku/CommentKB";
import SelectIntakeKB from "../model-kiroku/SelectIntakeKB";
import TimeKBCus from "../component/TimeKBCus";

const RadioGroup = Radio.Group;
class NormalMealForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif ? true : false,
      shokuNasi: this.props.data.shokuNasi ? true : false,
      type: null,
      extendValue: false,
      visibleNoKeyboard: false,
    };

    this.onChangeTime = this.onChangeTime.bind(this);
    this.onClickRctf = this.onClickRctf.bind(this);
    this.onChangeKubun = this.onChangeKubun.bind(this);
    this.onChangeStapleIntake = this.onChangeStapleIntake.bind(this);
    this.onChangeSideIntake = this.onChangeSideIntake.bind(this);
    this.onChangeShokuNasi = this.onChangeShokuNasi.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
    this.handleType = this.handleType.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.data !== newProps.data) {
      this.setState({
        reportCaseTyuif: newProps.data.reportCaseTyuif ? true : false,
        shokuNasi: newProps.data.shokuNasi ? true : false,
      });

      this.props.form.resetFields();
    }
  }

  onChangeTime(time, timeString) {
    this.props.handleFormInput(timeString, "time");
  }

  onClickRctf(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "reportCaseTyuif");
    this.setState({
      reportCaseTyuif: e.target.checked,
    });
  }

  onChangeKubun(e) {
    this.props.handleFormInput(e.target.value, "kubunMeal");
  }

  onChangeStapleIntake(value) {
    this.props.handleFormInput(value, "stapleIntake");
  }

  onChangeSideIntake(value) {
    this.props.handleFormInput(value, "sideIntake");
  }

  onChangeShokuNasi(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "shokuNasi");
    this.setState({
      shokuNasi: e.target.checked,
    });
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "tantoId");
  }

  handleInput(value) {
    let checkValue = handleInputNumber(value, this.state.value, true);
    this.props.handleFormInput(checkValue, this.state.type);
    this.setState({
      value: checkValue,
    });
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }

  handleType(type) {
    this.setState({
      type: type,
      value: null,
      visibleNoKeyboard: true,
    });
  }

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulModeNew = (timeString) => {
    this.props.handleFormInput(timeString, "time");
  };

  /**
   * getCurrentTime
   *
   */
  handleSetCurrentTime = () => {
    let currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.props.handleFormInput(currentTime, "time");
  };

  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  onVisibleChangePopover = () => {
    const { visibleNoKeyboard } = this.state;

    if (visibleNoKeyboard) {
      this.setState({
        visibleNoKeyboard: false,
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { tantoMaster, data, yougoMasterNa } = this.props;
    const { visibleNoKeyboard, type } = this.state;

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            <Button onClick={() => this.handleInput(".")}>.</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
          <Row>
            <Button
              onClick={this.handleEnter}
              style={{ padding: "0px", height: 150 }}
            >
              <EnterOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    );

    return (
      <Form layout="vertical" hideRequiredMark>
        <Row>
          <Col className="kdf-label" span={4}>
            <span>時間</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item className="form-item-300">
              <TimeKBCus
                timeString={data && data.time ? data.time : null}
                handleInput={this.onChangeTimeMulModeNew}
              />
              <Button
                className="kdf-number-k"
                type="primary"
                onClick={this.handleSetCurrentTime}
              >
                現時刻
              </Button>
            </Form.Item>
            <Form.Item className="form-item-100">
              {getFieldDecorator("reportCaseTyuif", {
                rules: [{ required: false }],
              })(
                <Checkbox
                  className="kdf-f-extent"
                  checked={data && data.reportCaseTyuif ? true : false}
                  onChange={this.onClickRctf}
                >
                  申送
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className="kdf-label" span={4}>
            <span>種類</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <RadioGroup
                value={data.kubunMeal}
                onChange={this.onChangeKubun}
                className="kdf-f-extent"
              >
                <Radio value={"朝"}>朝</Radio>
                <Radio value={"昼"}>昼</Radio>
                <Radio value={"夕"}>夕</Radio>
                <Radio value={"間食"}>間食</Radio>
                <Radio value={"経管栄養"}>経管栄養</Radio>
              </RadioGroup>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className="kdf-label" span={4}>
            <span>主食摂取量</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            {(data.kubunMeal && data.kubunMeal === "経管栄養") ||
            getValueIskeep("mealType") === "経管栄養" ? (
              <Form.Item>
                {getFieldDecorator("stapleIntake", {
                  rules: [{ required: false }],
                  initialValue: data.stapleIntake,
                })(
                  <InputNumber
                    min={0}
                    max={100000}
                    onChange={this.onChangeStapleIntake}
                    placeholder="主食摂取量"
                  />
                )}
                <Popover
                  visible={
                    visibleNoKeyboard && type === "stapleIntake" ? true : false
                  }
                  placement="bottomRight"
                  content={numberKB}
                  trigger="click"
                  onVisibleChange={this.onVisibleChangePopover}
                >
                  <Button
                    type="primary"
                    onClick={() => this.handleType("stapleIntake")}
                  >
                    入力
                  </Button>
                </Popover>
              </Form.Item>
            ) : (
              <Form.Item>
                <SelectIntakeKB
                  handleInputNum={this.onChangeStapleIntake}
                  value={data.stapleIntake}
                  inputWidth={"100px"}
                  textAlignCus="left"
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row>
          <Col className="kdf-label" span={4}>
            <span>副食摂取量</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            {(data.kubunMeal && data.kubunMeal === "経管栄養") ||
            getValueIskeep("mealType") === "経管栄養" ? (
              <Form.Item>
                {getFieldDecorator("sideIntake", {
                  rules: [{ required: false }],
                  initialValue: data.sideIntake,
                })(
                  <InputNumber
                    min={0}
                    max={100000}
                    onChange={this.onChangeSideIntake}
                    placeholder="副食摂取量"
                  />
                )}
                <Popover
                  visible={
                    visibleNoKeyboard && type === "sideIntake" ? true : false
                  }
                  placement="bottomRight"
                  content={numberKB}
                  trigger="click"
                  onVisibleChange={this.onVisibleChangePopover}
                >
                  <Button
                    type="primary"
                    onClick={() => this.handleType("sideIntake")}
                  >
                    入力
                  </Button>
                </Popover>
              </Form.Item>
            ) : (
              <Form.Item>
                <SelectIntakeKB
                  handleInputNum={this.onChangeSideIntake}
                  value={data.sideIntake}
                  inputWidth={"100px"}
                  textAlignCus="left"
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row>
          <Col className="kdf-label" span={4}>
            <span></span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              {getFieldDecorator("shokuNasi", {
                rules: [{ required: false }],
              })(
                <Checkbox
                  className="kdf-f-extent"
                  checked={this.state.shokuNasi}
                  onChange={this.onChangeShokuNasi}
                >
                  食無
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className="kdf-label" span={4}>
            <span>コメント</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={4}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className="kdf-label" span={4}>
            <span>担当者</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const MealForm = Form.create()(NormalMealForm);
export default MealForm;
