import React, { Fragment } from "react";
import { Modal, Input, Row, Col, Button, Checkbox, Spin } from "antd";
import { connect } from "react-redux";
import { toKatakana } from "wanakana";
import {
  machineMasterInitCreator,
  createMachineMasterCreator,
  updateMachineMasterCreator,
} from "../../../actions/allMasterActions/actionCreators";
import MasterCodeInput from "./MasterCodeInput";
import MasterCodeCancelButton from "./MasterCodeCancelButton";
import _ from "lodash";

const ADD = 1;
const EDIT = 2;

const reference = { table: "machine_master", field: "code" };

class MachineMasterEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      initCode: null,
      errorNameVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      if (this.props.data && !(this.props.editType === ADD)) {
        if (this.props.editType === EDIT) {
          this.setState({
            data: this.props.data,
          });
        }
      } else {
        this.props.getMachineMasterInit((data) => {
          const initDate = data
            ? { ...data, id: null, code: this.state.data.code }
            : {};
          this.setState({
            data: initDate,
          });
        });
      }
    }
  }

  onChangeValue(e, field) {
    const { data } = this.state;

    e.stopPropagation();
    let newValue = data[field];
    switch (field) {
      case "stop":
        newValue = e.target.checked ? 1 : 0;
        break;
      default:
        newValue = e.target.value;
    }

    if (newValue !== data[field]) {
      this.setState({
        data: { ...data, [field]: newValue },
      });
    }
  }

  onChangeCode(value, first) {
    const { data } = this.state;

    if (first) {
      this.setState({
        data: { ...data, code: value },
        initCode: value,
      });
    } else {
      this.setState({
        data: { ...data, code: value },
      });
    }
  }

  async handleSave() {
    const { data } = this.state;

    if (this.checkRequired()) return;

    if (data.id && data.id > 0) {
      await this.props.updateMachineMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    } else {
      if (data && !data.furigana) {
        data.furigana = "";
      }
      await this.props.createMachineMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    }

    this.handleClose();
  }

  checkRequired() {
    const { data } = this.state;

    let isError = false;
    if (_.isEmpty(data.name)) {
      isError = true;
      this.setState({
        errorNameVisible: true,
      });
    } else {
      this.setState({
        errorNameVisible: false,
      });
    }

    return isError;
  }

  handleFurigana(e, field) {
    const { data } = this.state;

    e.preventDefault();
    const value = toKatakana(e.target.value);
    this.setState({
      data: { ...data, [field]: value },
    });
  }

  handleClose() {
    this.setState({
      errorNameVisible: false,
    });
    this.props.handleClose();
  }

  render() {
    const { data, errorNameVisible, initCode } = this.state;

    return (
      (<Fragment>
        <Modal
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => this.handleClose()}
          open={this.props.visible}
          width={800}
          footer={[
            <Row key={1} type="flex" justify="end">
              <div style={{ paddingRight: 8 }}>
                <MasterCodeCancelButton
                  text={"戻る"}
                  code={initCode}
                  reference={reference}
                  checkType={this.props.editType === ADD}
                  onClose={() => this.handleClose()}
                />
              </div>
              <div>
                <Button type={"primary"} onClick={() => this.handleSave()}>
                  {"保存"}
                </Button>
              </div>
            </Row>,
          ]}
        >
          <Spin
            spinning={
              this.props && this.props.loading ? this.props.loading : false
            }
          >
            <Row>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>ID</p>
              </Col>
              <Col span={4}>
                <Input
                  value={data.id}
                  onChange={(e) => this.onChangeValue(e, "id")}
                  readOnly
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>整理番号</p>
              </Col>
              <Col span={4}>
                <MasterCodeInput
                  value={data.code}
                  maxLength={10}
                  checkType={this.props.editType === ADD}
                  reference={reference}
                  onChange={(value, first) => this.onChangeCode(value, first)}
                />
              </Col>
              <Col span={14}>
                <Checkbox
                  type={"checkbox"}
                  checked={data.stop}
                  style={{ marginTop: 5, marginLeft: 50 }}
                  onChange={(e) => this.onChangeValue(e, "stop")}
                >
                  利用中止
                </Checkbox>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>機器名</p>
              </Col>
              <Col span={14}>
                <Input
                  value={data.name}
                  onChange={(e) => this.onChangeValue(e, "name")}
                  style={errorNameVisible ? { border: "1px solid red" } : {}}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>フリガナ</p>
              </Col>
              <Col span={14}>
                <Input
                  value={data.furigana}
                  onChange={(e) => this.onChangeValue(e, "furigana")}
                  onBlur={(e) => this.handleFurigana(e, "furigana")}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>順番</p>
              </Col>
              <Col span={4}>
                <Input
                  value={data.sortNum}
                  onChange={(e) => this.onChangeValue(e, "sortNum")}
                />
              </Col>
            </Row>
          </Spin>
        </Modal>
      </Fragment>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.allMaster.loading || state.referenceNumber.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMachineMasterInit: (callback) =>
      dispatch(machineMasterInitCreator(callback)),
    createMachineMaster: (body, callback) =>
      dispatch(createMachineMasterCreator(body, callback)),
    updateMachineMaster: (body, callback) =>
      dispatch(updateMachineMasterCreator(body, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MachineMasterEditModal);
