import React, { Component, forwardRef } from "react";
import { SketchField, Tools, Icons } from "./lib";
import { FullscreenOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Button, Modal, Input } from "antd";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import TemplateModal from "./UI/TemplateModal";
import { riyouChartTemplateSave } from "../../actions/riyousyaActions/actionCreators";

import "./lib/style/style.css";

class PaintApp extends Component {
  constructor(props) {
    super(props);
    if (props.toolselect === "pen") {
      this.state = {
        tool: Tools.Pencil,
        drawings: [],
        zoomIn: 5,
        zoomOut: 5,
        w: 800,
        h: 400,
        hoverText: "",
        saveTmpVisible: false,
        loadTmpVisible: false,
        templateName: "",
        templateError: false,
        value: props.value,
      };
    }
    else {
      this.state = {
        tool: Tools.Select,
        drawings: [],
        zoomIn: 5,
        zoomOut: 5,
        w: 800,
        h: 400,
        hoverText: "",
        saveTmpVisible: false,
        loadTmpVisible: false,
        templateName: "",
        templateError: false,
        value: props.value,
      };
    }
    if (props.width !== undefined) {
      this.state = {
        tool: Tools.Select,
        drawings: [],
        zoomIn: 5,
        zoomOut: 5,
        w: props.width,
        h: props.height,
        hoverText: "",
        saveTmpVisible: false,
        loadTmpVisible: false,
        templateName: "",
        templateError: false,
        value: props.value,
      };
      if (props.toolselect === "pen") {
        this.state = {
          tool: Tools.Pencil,
          drawings: [],
          zoomIn: 5,
          zoomOut: 5,
          w: props.width,
          h: props.height,
          hoverText: "",
          saveTmpVisible: false,
          loadTmpVisible: false,
          templateName: "",
          templateError: false,
          value: props.value,
        };
      }
    }
  }
  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  GetValuesJson = async () => {

    // await this.zoomPainview(1, true);
    return JSON.stringify(this._sketch.toJSON());
  }
  zoomPainview = (value, isScale = false) => {
    this._sketch.renderAll();

    let z = 5 - this.state.zoomIn;
    for (let i = 0; i < z; i++) {
      this._sketch.zoom(0.8, isScale);
    }
    z = 5 - this.state.zoomOut;
    for (let i = 0; i < z; i++) {
      this._sketch.zoom(1.25, isScale);
    }

  }
  _selectToolTwoRectangle = () => {
    this.setState({
      tool: Tools.TwoRectangle,
      hoverText: "本人の男性",
    });
  };
  _selectXTriangle = () => {
    this.setState({
      tool: Tools.XTriangle,
    });
  };
  _removeSelected = () => {
    this._sketch.removeSelected();
  };
  _selectToolText = () => {
    const doc = prompt("テキストを入力してください", "");
    if (doc !== null) {
      this._sketch.addText(doc);
      this.setState({
        hoverText: "選択",
      })
    }
    if (this.props.toolselect === "pen") {
      this.setState({
        tool: Tools.Select,
        hoverText: "テキスト",
      });
    }

  };
  _OnkeyPress = event => {
  }
  _selectTwoLine = () => {
    this.setState({
      tool: Tools.TwoLine,
      hoverText: "結婚",
    });
  };
  _selectF1 = () => {
    this.setState({
      tool: Tools.F1,
    });
  };
  _selectF2 = () => {
    this.setState({
      tool: Tools.F2,
    });
  };
  _selectF3 = () => {
    this.setState({
      tool: Tools.F3,
    });
  };
  _selectF4 = () => {
    this.setState({
      tool: Tools.F4,
    });
  };
  _selectF5 = () => {
    this.setState({
      tool: Tools.F5,
    });
  };
  _selectGuestRoom = () => {
    this.setState({
      tool: Tools.GuestRoom,
    });
  };
  _selectLivingRoom = () => {
    this.setState({
      tool: Tools.LivingRoom,
    });
  };
  _selectKitchenRoom = () => {
    this.setState({
      tool: Tools.KitchenRoom,
    });
  };
  _selectLiving = () => {
    this.setState({
      tool: Tools.Living,
    });
  };
  _selectCanteen = () => {
    this.setState({
      tool: Tools.Canteen,
    });
  };
  _selectToilet = () => {
    this.setState({
      tool: Tools.Toilet,
    });
  };
  _selectKaidan = () => {
    this.setState({
      tool: Tools.Kaidan,
    });
  };
  _selectSpace = () => {
    this.setState({
      tool: Tools.Space,
    });
  };

  _selectEntrance = () => {
    this.setState({
      tool: Tools.Entrance,
    });
  };
  _selectThreeLine = () => {
    this.setState({
      tool: Tools.ThreeLine,
    });
  };
  _selectSin = () => {
    this.setState({
      tool: Tools.Sin,
      hoverText: "内縁",
    });
  };
  _selectTwoLine2 = () => {
    this.setState({
      tool: Tools.TwoLine2,
      hoverText: "離婚",
    });
  };
  _selectToolCircle = () => {
    this.setState({
      tool: Tools.Circle,
      hoverText: "女性",
    });
  };
  _selectToolCircleFill = () => {
    this.setState({
      tool: Tools.CircleFill,
      hoverText: "死亡者の女性",
    });
  };
  _selectToolSquarteFill = () => {
    this.setState({
      tool: Tools.RectangleFill,
      hoverText: "死亡者の男性",
    });
  };
  _selectToolSquarte = () => {
    this.setState({
      tool: Tools.Rectangle,
      hoverText: "男性",
    });
  };
  //初期化
  _selectToolSelect = () => {
    this.setState({
      tool: Tools.Select,
      hoverText: "選択",
    });
  };
  _selectToolPencil = () => {
    this.setState({
      tool: Tools.Pencil,
    });
  };
  _selectToolPencil2 = () => {
    this.setState({
      tool: Tools.Pencil2,
      hoverText: "同居",
    });
  };
  _selectCircleLine = () => {
    this.setState({
      tool: Tools.CircleLine,
      hoverText: "養子の女性",
    });
  };
  _selectToolLine = () => {
    this.setState({
      tool: Tools.Line,
      hoverText: "ライン",
    });
  };
  _selectToolPan = () => {
    this.setState({
      tool: Tools.Pan,
      hoverText: "移動",
    });
  };
  _selectRectangleLine = () => {
    this.setState({
      tool: Tools.RectangleLine,
      hoverText: "養子の男性",
    });
  };
  _selectTriangle = () => {
    this.setState({
      tool: Tools.Triangle,
      hoverText: "不明",
    });
  };
  _selectToolTwoCircle = () => {
    this.setState({
      tool: Tools.TwoCircle,
      hoverText: "本人の女性",
    });
  };
  _selectThreeLine2 = () => {
    this.setState({
      tool: Tools.ThreeLine2,
    });
  }
  _selectTwoDiagonal = () => {
    this.setState({
      tool: Tools.TwoDiagonal,
    });
  }
  _selectPeopleHealth = () => {
    this.setState({
      tool: Tools.PeopleHealth,
    });
  }
  _selectPeople1 = () => {
    this.setState({
      tool: Tools.People1,
    });
  }
  _selectPeople2 = () => {
    this.setState({
      tool: Tools.People2,
    });
  }
  _delteObject = () => {
    this._sketch.removeSelected();
  };
  _undoPaint = () => {
    if (this._sketch.canUndo()) {
      this._sketch.undo();
    }
  };
  _copy = () => {
    this._sketch.copy();
  }
  _paste = () => {
    this._sketch.paste();
  }

  templateSave = () => {
    // let data = JSON.stringify(this._sketch.toJSON());
    this.setState({
      saveTmpVisible: true,
      templateName: "",
      templateError: false,
    })
  }
  templateLoad = () => {
    this.setState({
      loadTmpVisible: true
    })
  }
  handleChangeTemplateName = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  saveTmpOk = () => {
    const { templateName } = this.state;
    if(templateName === "") {
      this.setState({
        templateError: true
      })
      return 
    }
    //セーブする
    this.props.templateSave({
      bikou: templateName,
      swpict: JSON.stringify(this._sketch.toJSON())
    })

    this.setState({
      templateError: false,
      saveTmpVisible: false,
      templateName: "",
    })
  }
  saveTmpHide = () => {
    this.setState({
      templateError: false,
      saveTmpVisible: false,
      saveTmpVisible: "",
    })
  }

  handleSelectTemplate = (data) => {
    if (data) {
      this.setState({
        value: data?.swpict ?? ""
      })
    }
  }

  renderTool() {
    const { hoverText } = this.state;

    if (this.props.screen === "healthStatus") {
      return (
        <Row style={{ border: 1, borderBlockStyle: "solid" }}>
          <Col>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="選択">
                <div className={this.state.tool === Tools.Select ? "selectedPaint" : "normalPaint"} onClick={this._selectToolSelect}>
                  <Icons.Cursor style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="鉛筆">
                <div className={this.state.tool === Tools.Pencil ? "selectedPaint" : "normalPaint"} onClick={this._selectToolPencil}>
                  <Icons.PencilLine style={{ fontSize: 24, color: "#000000" }} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="鉛筆">
                <div className={this.state.tool === Tools.Pencil2 ? "selectedPaint" : "normalPaint"} onClick={this._selectToolPencil2}>
                  <Icons.PencilDashline style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="テクスト">
                <div className={"normalPaint"} onClick={this._selectToolText}>
                  <Icons.Text style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>

            <Row style={{ textAlign: "center" }}>
              <Tooltip title="障害部位">
                <div className={this.state.tool === Tools.Triangle ? "selectedPaint" : "normalPaint"} onClick={this._selectTriangle}>
                  <Icons.TriangleLine style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="梅倉部位">
                <div className={this.state.tool === Tools.CircleFill ? "selectedPaint" : "normalPaint"} onClick={this._selectToolCircleFill}>
                  <Icons.Circle style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="欠損部位">
                <div className={this.state.tool === Tools.XTriangle ? "selectedPaint" : "normalPaint"} onClick={this._selectXTriangle}>
                  <Icons.XPath style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>
          </Col>
        </Row>

      );
    }


    if (this.props.screen === "family") {
      return (
        <div>
          <Row style={{ border: 1, borderBlockStyle: "solid" }}>
          <Col>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 850px)">
                {(matches) =>
                  matches ? (
                    <div 
                      className={this.state.tool === Tools.Select ? "selectedPaint" : "normalPaint"} 
                      onClick={this._selectToolSelect}
                      onMouseEnter={()=> this.setState({ hoverText: "選択" })}
                      onMouseLeave={() => this.setState({ hoverText: "" })}
                    >
                      <Icons.Cursor style={{ fontSize: 24 }} />
                    </div>
                  ) 
                  : (
                    <div 
                      className={this.state.tool === Tools.Select ? "selectedPaint" : "normalPaint"} 
                      onClick={this._selectToolSelect}
                    >
                      <Icons.Cursor style={{ fontSize: 24 }} />
                    </div>
                  )
                }
              </MediaQuery>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                      className={this.state.tool === Tools.Rectangle ? "selectedPaint" : "normalPaint"} 
                      onClick={this._selectToolSquarte}
                      onMouseEnter={()=> this.setState({ hoverText: "男性" })}
                      onMouseLeave={() => this.setState({ hoverText: "" })}
                    >
                      <Icons.RectangleLine style={{ fontSize: 24 }} />
                    </div>
                  ) : (
                    <div 
                      className={this.state.tool === Tools.Rectangle ? "selectedPaint" : "normalPaint"} 
                      onClick={this._selectToolSquarte}
                    >
                      <Icons.RectangleLine style={{ fontSize: 24 }} />
                    </div>
                  )
                }
              </MediaQuery>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.TwoRectangle ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolTwoRectangle}
                  onMouseEnter={()=> this.setState({ hoverText: "本人の男性" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.TwoRectangle style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.TwoRectangle ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolTwoRectangle}
                >
                  <Icons.TwoRectangle style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.RectangleFill ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolSquarteFill}
                  onMouseEnter={()=> this.setState({ hoverText: "死亡者の男性" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.Rectangle style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.RectangleFill ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolSquarteFill}
                >
                  <Icons.Rectangle style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.Line ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolLine}
                  onMouseEnter={()=> this.setState({ hoverText: "ライン" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.Line style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.Line ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolLine}
                >
                  <Icons.Line style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.Sin ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectSin}
                  onMouseEnter={()=> this.setState({ hoverText: "内縁" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.Sin style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.Sin ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectSin}
                >
                  <Icons.Sin style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
          </Col>
          <Col>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.Pencil2 ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolPencil2}
                  onMouseEnter={()=> this.setState({ hoverText: "同居" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.PencilDashline style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.Pencil2 ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolPencil2}
                >
                  <Icons.PencilDashline style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.Circle ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolCircle}
                  onMouseEnter={()=> this.setState({ hoverText: "女性" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.CircleLine style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.Circle ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolCircle}
                >
                  <Icons.CircleLine style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.TwoCircle ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolTwoCircle}
                  onMouseEnter={()=> this.setState({ hoverText: "本人の女性" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.TwoCircle style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.TwoCircle ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolTwoCircle}
                >
                  <Icons.TwoCircle style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.CircleFill ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolCircleFill}
                  onMouseEnter={()=> this.setState({ hoverText: "死亡者の女性" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.Circle style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.CircleFill ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectToolCircleFill}
                >
                  <Icons.Circle style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.TwoLine ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectTwoLine}
                  onMouseEnter={()=> this.setState({ hoverText: "結婚" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.TwoLine style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.TwoLine ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectTwoLine}
                >
                  <Icons.TwoLine style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>

            <MediaQuery query="(min-device-width: 1030px)">
              {(matches) =>
                matches ? (
                  null
                ) 
                : 
                (
                  <Row style={{ textAlign: "center" }}>
                    <div 
                      className={this.state.tool === Tools.Pan ? "selectedPaint" : "normalPaint"} 
                      onClick={this._selectToolPan}
                    >
                      <FullscreenOutlined style={{ fontSize: 24 }} rotate={45} />
                    </div>
                  </Row>
                )
              }
            </MediaQuery>
          </Col>
          <Col>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div className={"normalPaint"} onClick={this._selectToolText} onMouseEnter={()=> this.setState({ hoverText: "テキスト" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}>
                  <Icons.Text style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div className={"normalPaint"} onClick={this._selectToolText}>
                  <Icons.Text style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.Triangle ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectTriangle}
                  onMouseEnter={()=> this.setState({ hoverText: "不明" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.TriangleLine style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.Triangle ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectTriangle}
                >
                  <Icons.TriangleLine style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.RectangleLine ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectRectangleLine}
                  onMouseEnter={()=> this.setState({ hoverText: "養子の男性" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.RectangleSeparator style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.RectangleLine ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectRectangleLine}
                >
                  <Icons.RectangleSeparator style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
                
            </Row>

            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                  className={this.state.tool === Tools.CircleLine ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectCircleLine}
                  onMouseEnter={()=> this.setState({ hoverText: "養子の女性" })}
                  onMouseLeave={() => this.setState({ hoverText: "" })}
                >
                  <Icons.CircleSeparator style={{ fontSize: 24 }} />
                </div>
                  ) : (
                    <div 
                  className={this.state.tool === Tools.CircleLine ? "selectedPaint" : "normalPaint"} 
                  onClick={this._selectCircleLine}
                >
                  <Icons.CircleSeparator style={{ fontSize: 24 }} />
                </div>
                  )
                }
              </MediaQuery>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <MediaQuery query="(min-device-width: 1030px)">
                {(matches) =>
                  matches ? (
                    <div 
                      className={this.state.tool === Tools.TwoLine2 ? "selectedPaint" : "normalPaint"} 
                      onClick={this._selectTwoLine2}
                      onMouseEnter={()=> this.setState({ hoverText: "離婚" })}
                      onMouseLeave={() => this.setState({ hoverText: "" })}
                    >
                      <Icons.TwoLine2 style={{ fontSize: 24 }} />
                    </div>
                  ) : (
                    <div 
                      className={this.state.tool === Tools.TwoLine2 ? "selectedPaint" : "normalPaint"} 
                      onClick={this._selectTwoLine2}
                    >
                      <Icons.TwoLine2 style={{ fontSize: 24 }} />
                    </div>
                  )
                }
              </MediaQuery>
            </Row>
          </Col>
        </Row>

        <Row className={hoverText ? "hoverName" : ""}>
          <p>{hoverText ? hoverText : " "}</p>
        </Row>

        <Col style={hoverText ? {marginTop: 30} : {marginTop: 62}}>
          <Col className="template">
            <Button
              type="primary"
              onClick={() => this.templateSave()}
              style={{ padding: "3px 3px" }}
            >
              テンプレート保存
            </Button>
          </Col>
          <Col className="template" style={{ marginTop: 2 }}>
            <Button
              type="primary"
              onClick={() => this.templateLoad()}
              style={{ padding: "3px 3px" }}
            >
              テンプレート読込
            </Button>
          </Col>
        </Col>
        </div>
      );
    }

    if (this.props.screen === "HousingStatus") {
      return (
        <Row style={{ border: 1, borderBlockStyle: "solid" }}>
          <Col span={8}>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="選択">
                <div className={this.state.tool === Tools.Select ? "selectedPaint" : "normalPaint"} onClick={this._selectToolSelect}>
                  <Icons.Cursor style={{ fontSize: 24 }} />
                </div>
              </Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title='テクスト'>
                <div className={"normalPaint"} onClick={this._selectToolText}>
                  <Icons.Text style={{ fontSize: 24 }} />
                </div>
              </Tooltip>
            </Row>
            {/* <Row style={{ textAlign: "center" }}>
              <div className={this.state.tool === Tools.TwoLine ? "selectedPaint" : "normalPaint"} onClick={this._selectTwoLine}>
                <Icons.TwoLine style={{ fontSize: 24 }} />
              </div>
            </Row> */}
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="フロア">
                <div className={this.state.tool === Tools.F3 ? "selectedPaint" : "normalPaint"} onClick={this._selectF3}>
                  <Icons.Circle3F style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="階段">
                <div className={this.state.tool === Tools.Kaidan ? "selectedPaint" : "normalPaint"} onClick={this._selectKaidan}>
                  <img alt="" src={require("./lib/img/kaidan.png")} width={32} height={32} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="居室">
                <div className={this.state.tool === Tools.GuestRoom ? "selectedPaint" : "normalPaint"} onClick={this._selectGuestRoom}>
                  <img alt="" src={require("./lib/img/guestroom.png")} width={32} height={32} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="キッチン・台所">
                <div className={this.state.tool === Tools.KitchenRoom ? "selectedPaint" : "normalPaint"} onClick={this._selectKitchenRoom}>
                  <img alt="" src={require("./lib/img/kitchen.png")} width={32} height={32} />
                </div></Tooltip>
            </Row>
          </Col>
          <Col span={8}>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="鉛筆">
                <div className={this.state.tool === Tools.Pencil ? "selectedPaint" : "normalPaint"} onClick={this._selectToolPencil}>
                  <Icons.PencilLine style={{ fontSize: 24, color: "#000000" }} />
                </div></Tooltip>
            </Row>

            <Row style={{ textAlign: "center" }}>
              <Tooltip title="矩形">
                <div className={this.state.tool === Tools.Rectangle ? "selectedPaint" : "normalPaint"} onClick={this._selectToolSquarte}>
                  <Icons.RectangleLine style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="フロア">
                <div className={this.state.tool === Tools.F1 ? "selectedPaint" : "normalPaint"} onClick={this._selectF1}>
                  <Icons.Circle1F style={{ fontSize: 24 }} />
                </div>
              </Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="フロア">
                <div className={this.state.tool === Tools.F4 ? "selectedPaint" : "normalPaint"} onClick={this._selectF4}>
                  <Icons.Circle4F style={{ fontSize: 24 }} />
                </div>
              </Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="スペース">
                <div className={this.state.tool === Tools.Space ? "selectedPaint" : "normalPaint"} onClick={this._selectSpace}>
                  <img alt="" src={require("./lib/img/space.png")} width={32} height={32} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="居間">
                <div className={this.state.tool === Tools.LivingRoom ? "selectedPaint" : "normalPaint"} onClick={this._selectLivingRoom}>
                  <img alt="" src={require("./lib/img/livingroom.png")} width={32} height={32} />
                </div></Tooltip>
            </Row>

            <Row style={{ textAlign: "center" }}>
              <Tooltip title="トイレ">
                <div className={this.state.tool === Tools.Toilet ? "selectedPaint" : "normalPaint"} onClick={this._selectToilet}>
                  <img alt="" src={require("./lib/img/toilet.png")} width={32} height={32} />
                </div></Tooltip>
            </Row>

          </Col>
          <Col span={8}>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="鉛筆">
                <div className={this.state.tool === Tools.Pencil2 ? "selectedPaint" : "normalPaint"} onClick={this._selectToolPencil2}>
                  <Icons.PencilDashline style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="ライン">
                <div className={this.state.tool === Tools.Line ? "selectedPaint" : "normalPaint"} onClick={this._selectToolLine}>
                  <Icons.Line style={{ fontSize: 24 }} />
                </div></Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="フロア">
                <div className={this.state.tool === Tools.F2 ? "selectedPaint" : "normalPaint"} onClick={this._selectF2}>
                  <Icons.Circle2F style={{ fontSize: 24 }} />
                </div>
              </Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="フロア">
                <div className={this.state.tool === Tools.F5 ? "selectedPaint" : "normalPaint"} onClick={this._selectF5}>
                  <Icons.Circle5F style={{ fontSize: 24 }} />
                </div>
              </Tooltip>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Tooltip title="玄関・入口">
                <div className={this.state.tool === Tools.Entrance ? "selectedPaint" : "normalPaint"} onClick={this._selectEntrance}>
                  <img alt="" src={require("./lib/img/entrance.png")} width={32} height={32} />
                </div></Tooltip>
            </Row>


            <Row style={{ textAlign: "center" }}>
              <Tooltip title="食堂">
                <div className={this.state.tool === Tools.Canteen ? "selectedPaint" : "normalPaint"} onClick={this._selectCanteen}>
                  <img alt="" src={require("./lib/img/canteen.png")} width={32} height={32} />
                </div></Tooltip>
            </Row>


          </Col>
        </Row>

      );
    }

    return (
      <Row style={{ border: 1, borderBlockStyle: "solid" }}>
        <Col span={8}>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Select ? "selectedPaint" : "normalPaint"} onClick={this._selectToolSelect}>
              <img alt="" src={require("./lib/img/select.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Rectangle ? "selectedPaint" : "normalPaint"} onClick={this._selectToolSquarte}>
              <img alt="" src={require("./lib/img/rectangle.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.RectangleFill ? "selectedPaint" : "normalPaint"} onClick={this._selectToolSquarteFill}>
              <img alt="" src={require("./lib/img/rectangle-fill.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Circle ? "selectedPaint" : "normalPaint"} onClick={this._selectToolCircle}>
              <img alt="" src={require("./lib/img/ellipse.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.CircleFill ? "selectedPaint" : "normalPaint"} onClick={this._selectToolCircleFill}>
              <img alt="" src={require("./lib/img/cicle-fill.png")} width={32} height={32} />
            </div>
          </Row>

          <Row style={{ textAlign: "center" }}>
            <div className={"normalPaint"} onClick={this._undoPaint}>
              <img alt="" src={require("./lib/img/undo.png")} width={32} height={32} />
            </div>
          </Row>
        </Col>
        <Col span={8}>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Line ? "selectedPaint" : "normalPaint"} onClick={this._selectToolLine}>
              <img alt="" src={require("./lib/img/line.png")} width={32} height={32} />
            </div>
          </Row>
          {/* /pencil.png */}
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Pencil ? "selectedPaint" : "normalPaint"} onClick={this._selectToolPencil}>
              <img alt="" src={require("./lib/img/pencil.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Pencil2 ? "selectedPaint" : "normalPaint"} onClick={this._selectToolPencil2}>
              <img alt="" src={require("./lib/img/pencil2.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.TwoRectangle ? "selectedPaint" : "normalPaint"} onClick={this._selectToolTwoRectangle}>
              <img alt="" src={require("./lib/img/tworectangle.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.TwoCircle ? "selectedPaint" : "normalPaint"} onClick={this._selectToolTwoCircle}>
              <img alt="" src={require("./lib/img/twocircle.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Triangle ? "selectedPaint" : "normalPaint"} onClick={this._selectTriangle}>
              <img alt="" src={require("./lib/img/triangle-non-fill.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.RectangleLine ? "selectedPaint" : "normalPaint"} onClick={this._selectRectangleLine}>
              <img alt="" src={require("./lib/img/srq_line.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.TwoDiagonal ? "selectedPaint" : "normalPaint"} onClick={this._selectTwoDiagonal}>
              <img alt="" src={require("./lib/img/2cheo.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Entrance ? "selectedPaint" : "normalPaint"} onClick={this._selectEntrance}>
              <img alt="" src={require("./lib/img/entrance.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Tooltip title="スペース">
              <div className={this.state.tool === Tools.Space ? "selectedPaint" : "normalPaint"} onClick={this._selectSpace}>
                <img alt="" src={require("./lib/img/space.png")} width={32} height={32} />
              </div></Tooltip>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Kaidan ? "selectedPaint" : "normalPaint"} onClick={this._selectKaidan}>
              <img alt="" src={require("./lib/img/kaidan.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.XTriangle ? "selectedPaint" : "normalPaint"} onClick={this._selectXTriangle}>
              <img alt="" src={require("./lib/img/xpaint.png")} width={32} height={32} />
            </div>
          </Row>

          <Row style={{ textAlign: "center" }}>
            <Tooltip title='削除する'>
              <div className={"normalPaint"} onClick={this._delteObject}>
                <img alt="" src={require("./lib/img/trash.png")} width={32} height={32} />
              </div>
            </Tooltip>
          </Row>


        </Col>
        <Col span={8}>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.CircleLine ? "selectedPaint" : "normalPaint"} onClick={this._selectCircleLine}>
              <img alt="" src={require("./lib/img/circle_line.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={"normalPaint"} onClick={this._selectToolText}>
              <img alt="" src={require("./lib/img/img_text.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.TwoLine ? "selectedPaint" : "normalPaint"} onClick={() => this._selectTwoLine()}>
              <img alt="" src={require("./lib/img/twoline.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.TwoLine2 ? "selectedPaint" : "normalPaint"} onClick={this._selectTwoLine2}>
              <img alt="" src={require("./lib/img/twoline2.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.ThreeLine ? "selectedPaint" : "normalPaint"} onClick={this._selectThreeLine}>
              <img alt="" src={require("./lib/img/threeline.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Sin ? "selectedPaint" : "normalPaint"} onClick={this._selectSin}>
              <img alt="" src={require("./lib/img/sin.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.ThreeLine2 ? "selectedPaint" : "normalPaint"} onClick={this._selectThreeLine2}>
              <img alt="" src={require("./lib/img/threeline2.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.People1 ? "selectedPaint" : "normalPaint"} onClick={this._selectPeople1}>
              <img alt="" src={require("./lib/img/people1.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.People2 ? "selectedPaint" : "normalPaint"} onClick={this._selectPeople2}>
              <img alt="" src={require("./lib/img/people2.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.GuestRoom ? "selectedPaint" : "normalPaint"} onClick={this._selectGuestRoom}>
              <img alt="" src={require("./lib/img/guestroom.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.LivingRoom ? "selectedPaint" : "normalPaint"} onClick={this._selectLivingRoom}>
              <img alt="" src={require("./lib/img/livingroom.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.KitchenRoom ? "selectedPaint" : "normalPaint"} onClick={this._selectKitchenRoom}>
              <img alt="" src={require("./lib/img/kitchen.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Canteen ? "selectedPaint" : "normalPaint"} onClick={this._selectCanteen}>
              <img alt="" src={require("./lib/img/canteen.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Toilet ? "selectedPaint" : "normalPaint"} onClick={this._selectToilet}>
              <img alt="" src={require("./lib/img/toilet.png")} width={32} height={32} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.Living ? "selectedPaint" : "normalPaint"} onClick={this._selectLiving}>
              <Icons.Living style={{ fontSize: 24 }} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.F1 ? "selectedPaint" : "normalPaint"} onClick={this._selectF1}>
              <Icons.Circle1F style={{ fontSize: 24 }} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.F2 ? "selectedPaint" : "normalPaint"} onClick={this._selectF2}>
              <Icons.Circle2F style={{ fontSize: 24 }} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.F3 ? "selectedPaint" : "normalPaint"} onClick={this._selectF3}>
              <Icons.Circle3F style={{ fontSize: 24 }} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.F4 ? "selectedPaint" : "normalPaint"} onClick={this._selectF4}>
              <Icons.Circle4F style={{ fontSize: 24 }} />
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className={this.state.tool === Tools.F5 ? "selectedPaint" : "normalPaint"} onClick={this._selectF5}>
              <Icons.Circle5F style={{ fontSize: 24 }} />
            </div>
          </Row>
        </Col>
      </Row>
    );

  }

  render() {
    const { templateError, saveTmpVisible, loadTmpVisible, templateName } = this.state;

    return (
      (<>
        <Row style={{
          display: "flex",
          padding: "1rem",
          backgroundColor: "#F0F0F0"
        }}>
          <Col span={"auto"}>
            {this.renderTool()}
          </Col>
          <Col style={{
            marginLeft: "0.6rem",
            width: '800px'
          }}>
            <div
              className="player"
              style={{ position: "absolute" }}
              onKeyDown={(e) => { this._OnkeyPress(e); }}
            ></div>
            <Row>
              <Col style={{
                border: 1,
                borderBlockStart: "solid",
                borderBlockEnd: "solid",
                borderInlineStart: "solid",
                borderInlineEnd: "solid",
                backgroundColor: "#FFFFFF",
                overflow: "auto",
                width: this.props.width !== undefined ? this.props.width + 30 : 820,
                height: this.props.height !== undefined ? this.props.height + 20 : 410,
                maxHeight: this.props.width !== undefined ? (this.props.height >= 1000 ? 700 : 810) : 810,
              }}>
                
                <SketchField
                  width={this.state.w}
                  height={this.state.h}
                  ref={c => (this._sketch = c)}
                  value={this.state.value}
                  tool={this.state.tool}
                  update_select={this._selectToolSelect}
                  lineColor='black'
                  lineWidth={3} />
              </Col>
            </Row>
            <Row style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "0.6rem"
            }}>
              <Col>
                  <div className={"normalPaint"} onClick={this._undoPaint}>
                    <img alt="" src={require("./lib/img/undo.png")} width={32} height={32} />
                  </div>
                  <div style={{ textAlign: "center", fontSize: 12 }}>
                    戻る
                  </div>
              </Col>
              <Col>
                  <div className={"normalPaint"} onClick={this._delteObject}>
                    <img alt="" src={require("./lib/img/trash.png")} width={32} height={32} />
                  </div>
                  <div style={{ textAlign: "center", fontSize: 12 }}>
                    削除
                  </div>
              </Col>
              <Col>
                <div className={"normalPaint"} onClick={this._copy}>
                  <img alt="" src={require("./lib/img/copy.png")} width={32} height={32} />
                </div>
                <div style={{ textAlign: "center", fontSize: 12 }}>
                  コピー
                </div>
              </Col>
              <Col>
                <div className={"normalPaint"} onClick={this._paste}>
                  <img alt="" src={require("./lib/img/paste.png")} width={32} height={32} />
                </div>
                <div style={{ textAlign: "center", fontSize: 12 }}>
                  貼付け
                </div>
              </Col>
              <Col>
                  <div className={"normalPaint"} onClick={() => {
                    this.setState({
                      zoomIn: this.state.zoomIn > 0 ? this.state.zoomIn - 1 : 0,
                      zoomOut: this.state.zoomOut > 10 ? 10 : this.state.zoomOut + 1,

                    });
                    if (this.state.zoomIn > 0) {
                      
                      this._sketch.zoom(1.25, true);
                      this._sketch.renderAll();

                      this.setState({
                        w: this.state.w * 1.25,
                        h: this.state.h * 1.25,
                      });
                    }

                  }}>
                    <img alt="" src={require("./lib/img/zoom-in.png")} width={32} height={32} />
                  </div>
                  <div style={{ textAlign: "center", fontSize: 12 }}>
                    拡大
                  </div>
              </Col>
              <Col>
                  <div className={"normalPaint"} onClick={() => {
                    this.setState({
                      zoomOut: this.state.zoomOut > 0 ? this.state.zoomOut - 1 : 0,
                      zoomIn: this.state.zoomIn > 10 ? 10 : this.state.zoomIn + 1,
                    });
                    if (this.state.zoomOut > 0) {
                      
                      this._sketch.zoom(0.8, true);
                      this._sketch.renderAll();
                      // this.setState({
                      //   w: this.state.w * 0.8,
                      //   h: this.state.h * 0.8,
                      // });
                    }

                  }}>
                    <img alt="" src={require("./lib/img/zoom-out.png")} width={32} height={32} />
                  </div>
                  <div style={{ textAlign: "center", fontSize: 12 }}>
                    縮小
                  </div>
              </Col>
            </Row>
          </Col>
          <Modal
          open={saveTmpVisible}
          title="テンプレート名を入力してください"
          onOk={() => this.saveTmpOk()}
          onCancel={() => this.saveTmpHide()}
        >
          {
            templateError
            ?
            <p style={{ color: "red" }}>テンプレート名を入力してください</p>
            :
            null
          }
          <Input
            name="templateName"
            value={templateName}
            onChange={this.handleChangeTemplateName}
            style={templateError ? { borderColor: "red" } : {}}
          />
        </Modal>
        </Row>
        <TemplateModal
          visible={loadTmpVisible}
          onSelect={(data) => this.handleSelectTemplate(data)}
          onHide={() => this.setState({ loadTmpVisible: false })}
        />
      </>)
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    templateSave: (data) => dispatch(riyouChartTemplateSave(data))
  };
};

export default connect(null, mapDispatchToProps)(PaintApp);

// export default forwardRef((props, ref) => (
//   <PaintApp forwardedRef={ref} {...props} />
// ));
