import { lazy } from "react";

const Signin = lazy(() => import("../pages/Signin"));

const LogoutContainer = lazy(() =>
  import("../../components/logout/logoutContainer").then((module) => ({
    default: module.LogoutContainer,
  }))
);

const routes = [
  {
    path: "/life/login",
    component: Signin,
  },

  {
    path: "/life/logout-btn",
    component: LogoutContainer,
  },
];

export default routes.map((route) => {
  route.type = "public";
  return route;
});
