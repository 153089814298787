export const KANRI_REQUEST = "KANRI_REQUEST";
export const KANRI_REQUEST_ERROR = "KANRI_REQUEST_ERROR";
export const CLEAR_SELECTED_ROW = "CLEAR_SELECTED_ROW";
export const SAVE_SELECTED_ROW = "SAVE_SELECTED_ROW";
export const SAVE_RECORD_MARK = "SAVE_RECORD_MARK";

export const KANRI_SEARCH_DATA = "KANRI_SEARCH_DATA";

export const KANRI_USER_LIST_SUCCESS = "KANRI_USER_LIST_SUCCESS";
export const KANRI_USER_SELECTED_DISPLAY = "KANRI_USER_SELECTED_DISPLAY";
export const KANRI_USER_DISPLAY_LIST = "KANRI_USER_DISPLAY_LIST";
export const KANRI_USER_UPDATE_SUCCESS = "KANRI_USER_UPDATE_SUCCESS";
export const KANRI_USER_ADD_SUCCESS = "KANRI_USER_ADD_SUCCESS";
export const KANRI_USER_DELETE_SUCCESS = "KANRI_USER_DELETE_SUCCESS";

export const KANRI_FILE_LIST_SUCCESS = "KANRI_FILE_LIST_SUCCESS";
export const KANRI_FILE_SINGLE_RECORD_SUCCESS =
  "KANRI_FILE_SINGLE_RECORD_SUCCESS";
export const KANRI_FILE_SELECTED_DISPLAY = "KANRI_FILE_SELECTED_DISPLAY";
export const KANRI_FILE_UPDATE_SUCCESS = "KANRI_FILE_UPDATE_SUCCESS";
export const KANRI_FILE_ADD_SUCCESS = "KANRI_FILE_ADD_SUCCESS";
export const KANRI_FILE_DELETE_SUCCESS = "KANRI_FILE_DELETE_SUCCESS";

export const KANRI_DENGON_LIST_SUCCESS = "KANRI_DENGON_LIST_SUCCESS";
export const KANRI_DENGON_SINGLE_RECORD_SUCCESS =
  "KANRI_DENGON_SINGLE_RECORD_SUCCESS";
export const KANRI_DENGON_SELECTED_DISPLAY = "KANRI_DENGON_SELECTED_DISPLAY";
export const KANRI_DENGON_UPDATE_SUCCESS = "KANRI_DENGON_UPDATE_SUCCESS";
export const KANRI_DENGON_ADD_SUCCESS = "KANRI_DENGON_ADD_SUCCESS";
export const KANRI_DENGON_DELETE_SUCCESS = "KANRI_DENGON_DELETE_SUCCESS";

export const KANRI_MOSHIOKURI_LIST_SUCCESS = "KANRI_MOSHIOKURI_LIST_SUCCESS";
export const KANRI_MOSHIOKURI_SINGLE_RECORD_SUCCESS =
  "KANRI_MOSHIOKURI_SINGLE_RECORD_SUCCESS";
export const KANRI_MOSHIOKURI_SELECTED_DISPLAY =
  "KANRI_MOSHIOKURI_SELECTED_DISPLAY";
export const KANRI_MOSHIOKURI_UPDATE_SUCCESS =
  "KANRI_MOSHIOKURI_UPDATE_SUCCESS";
export const KANRI_MOSHIOKURI_ADD_SUCCESS = "KANRI_MOSHIOKURI_ADD_SUCCESS";
export const KANRI_MOSHIOKURI_DELETE_SUCCESS =
  "KANRI_MOSHIOKURI_DELETE_SUCCESS";

export const KANRI_OPENSL_LIST_SUCCESS = "KANRI_OPENSL_LIST_SUCCESS";
export const KANRI_OPENSL_SINGLE_RECORD_SUCCESS =
  "KANRI_OPENSL_SINGLE_RECORD_SUCCESS";
export const KANRI_OPENSL_SELECTED_DISPLAY = "KANRI_OPENSL_SELECTED_DISPLAY";
export const KANRI_OPENSL_UPDATE_SUCCESS = "KANRI_OPENSL_UPDATE_SUCCESS";
export const KANRI_OPENSL_ADD_SUCCESS = "KANRI_OPENSL_ADD_SUCCESS";
export const KANRI_OPENSL_DELETE_SUCCESS = "KANRI_OPENSL_DELETE_SUCCESS";

export const KANRI_SM_LIST_SUCCESS = "KANRI_SM_LIST_SUCCESS";
export const KANRI_SM_SELECTED_DISPLAY = "KANRI_SM_SELECTED_DISPLAY";
export const KANRI_SM_DISPLAY_LIST = "KANRI_SM_DISPLAY_LIST";
export const KANRI_SM_UPDATE_SUCCESS = "KANRI_SM_UPDATE_SUCCESS";
export const KANRI_SM_ADD_SUCCESS = "KANRI_SM_ADD_SUCCESS";
export const KANRI_SM_DELETE_SUCCESS = "KANRI_SM_DELETE_SUCCESS";

export const KANRI_YM_LIST_SUCCESS = "KANRI_YM_LIST_SUCCESS";
export const KANRI_YM_SELECTED_DISPLAY = "KANRI_YM_SELECTED_DISPLAY";
export const KANRI_YM_DISPLAY_LIST = "KANRI_YM_DISPLAY_LIST";
export const KANRI_YM_UPDATE_SUCCESS = "KANRI_YM_UPDATE_SUCCESS";
export const KANRI_YM_ADD_SUCCESS = "KANRI_YM_ADD_SUCCESS";
export const KANRI_YM_DELETE_SUCCESS = "KANRI_YM_DELETE_SUCCESS";

export const KANRI_LYM_LIST_SUCCESS = "KANRI_LYM_LIST_SUCCESS";
export const KANRI_LYM_SELECTED_DISPLAY = "KANRI_LYM_SELECTED_DISPLAY";
export const KANRI_LYM_DISPLAY_LIST = "KANRI_LYM_DISPLAY_LIST";
export const KANRI_LYM_UPDATE_SUCCESS = "KANRI_LYM_UPDATE_SUCCESS";
export const KANRI_LYM_ADD_SUCCESS = "KANRI_LYM_ADD_SUCCESS";
export const KANRI_LYM_DELETE_SUCCESS = "KANRI_LYM_DELETE_SUCCESS";

export const KANRI_KYM_LIST_SUCCESS = "KANRI_KYM_LIST_SUCCESS";
export const KANRI_KYM_SINGLE_RECORD_SUCCESS =
  "KANRI_KYM_SINGLE_RECORD_SUCCESS";
export const KANRI_KYM_SELECTED_DISPLAY = "KANRI_KYM_SELECTED_DISPLAY";
export const KANRI_KYM_UPDATE_SUCCESS = "KANRI_KYM_UPDATE_SUCCESS";
export const KANRI_KYM_ADD_SUCCESS = "KANRI_KYM_ADD_SUCCESS";
export const KANRI_KYM_DELETE_SUCCESS = "KANRI_KYM_DELETE_SUCCESS";

export const KANRI_TM_LIST_SUCCESS = "KANRI_TM_LIST_SUCCESS";
export const KANRI_TM_SELECTED_DISPLAY = "KANRI_TM_SELECTED_DISPLAY";
export const KANRI_TM_DISPLAY_LIST = "KANRI_TM_DISPLAY_LIST";
export const KANRI_TM_UPDATE_SUCCESS = "KANRI_TM_UPDATE_SUCCESS";
export const KANRI_TM_ADD_SUCCESS = "KANRI_TM_ADD_SUCCESS";
export const KANRI_TM_DELETE_SUCCESS = "KANRI_TM_DELETE_SUCCESS";

export const KANRI_URL_LIST_SUCCESS = "KANRI_URL_LIST_SUCCESS";
export const KANRI_URL_SELECTED_DISPLAY = "KANRI_URL_SELECTED_DISPLAY";
export const KANRI_URL_DISPLAY_LIST = "KANRI_URL_DISPLAY_LIST";
export const KANRI_URL_UPDATE_SUCCESS = "KANRI_URL_UPDATE_SUCCESS";
export const KANRI_URL_ADD_SUCCESS = "KANRI_URL_ADD_SUCCESS";
export const KANRI_URL_DELETE_SUCCESS = "KANRI_URL_DELETE_SUCCESS";
