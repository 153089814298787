export const Plan2ActionTypes = {
  GET_ALL_PLAN2_REQUEST: "GET_ALL_PLAN2_REQUEST",
  GET_ALL_PLAN2_SUCCESS: "GET_ALL_PLAN2_SUCCESS",
  GET_ALL_PLAN2_FAILURE: "GET_ALL_PLAN2_FAILURE",

  GET_PLAN2_REQUEST: "GET_PLAN2_REQUEST",
  GET_PLAN2_SUCCESS: "GET_PLAN2_SUCCESS",
  GET_PLAN2_FAILURE: "GET_PLAN2_FAILURE",

  CREATE_PLAN2_REQUEST: "CREATE_PLAN2_REQUEST",
  CREATE_PLAN2_SUCCESS: "CREATE_PLAN2_SUCCESS",
  CREATE_PLAN2_FAILURE: "CREATE_PLAN2_FAILURE",

  UPDATE_PLAN2_REQUEST: "UPDATE_PLAN2_REQUEST",
  UPDATE_PLAN2_SUCCESS: "UPDATE_PLAN2_SUCCESS",
  UPDATE_PLAN2_FAILURE: "UPDATE_PLAN2_FAILURE",

  REMOVE_PLAN2_REQUEST: "REMOVE_PLAN2_REQUEST",
  REMOVE_PLAN2_SUCCESS: "REMOVE_PLAN2_SUCCESS",
  REMOVE_PLAN2_FAILURE: "REMOVE_PLAN2_FAILURE",

  GET_ALL_PLAN2_QUOTE_REQUEST: "GET_ALL_PLAN2_QUOTE_REQUEST",
  GET_ALL_PLAN2_QUOTE_SUCCESS: "GET_ALL_PLAN2_QUOTE_SUCCESS",
  GET_ALL_PLAN2_QUOTE_FAILURE: "GET_ALL_PLAN2_QUOTE_FAILURE",

  GET_SERVICE_KIKAN: "GET_SERVICE_KIKAN",
  GET_SERVICE_KIKAN_SUCCESS: "GET_SERVICE_KIKAN_SUCCESS",
  GET_SERVICE_KIKAN_ERROR: "GET_SERVICE_KIKAN_ERROR",

  GET_INITIAL_IRYOU_REQUEST: "GET_INITIAL_IRYOU_REQUEST",
  GET_INITIAL_IRYOU_SUCCESS: "GET_INITIAL_IRYOU_SUCCESS",
  GET_INITIAL_IRYOU_FAILURE: "GET_INITIAL_IRYOU_FAILURE",

  GET_IRYOU_BY_ID_REQUEST: "GET_IRYOU_BY_ID_REQUEST",
  GET_IRYOU_BY_ID_SUCCESS: "GET_IRYOU_BY_ID_SUCCESS",
  GET_IRYOU_BY_ID_FAILURE: "GET_IRYOU_BY_ID_FAILURE",

  CREATE_IRYOU_REQUEST: "CREATE_IRYOU_REQUEST",
  CREATE_IRYOU_SUCCESS: "CREATE_IRYOU_SUCCESS",
  CREATE_IRYOU_FAILURE: "CREATE_IRYOU_FAILURE",

  UPDATE_IRYOU_REQUEST: "UPDATE_IRYOU_REQUEST",
  UPDATE_IRYOU_SUCCESS: "UPDATE_IRYOU_SUCCESS",
  UPDATE_IRYOU_FAILURE: "UPDATE_IRYOU_FAILURE",
};
