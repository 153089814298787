import { KihonAdlMasterApi, SettingMasterAPI } from '../../api/settingMaster';
import {
  KIHON_ADL_MASTER_ALL, SETTING_MASTER_ALL, SETTING_MASTER_ALL_NEW, SETTING_MASTER_CALLBACK_LOAD, SETTING_MASTER_PAGE_REQUEST
} from './actionName.js';

export function loadSettingMasterCb(settingNames, callback) {
  return (dispatch) => {
    SettingMasterAPI.load(settingNames).then((data) => {
      dispatch({
        type: SETTING_MASTER_CALLBACK_LOAD,
        payload: data,
      })

      if (callback && typeof callback === "function") {
        return callback(data.data);
      }
    });
  };
}

export function loadSettingMasterCreator(settingNames, page, callback) {
  return (dispatch) => {
    SettingMasterAPI.load(settingNames).then((data) => {
      dispatch({
        type: SETTING_MASTER_LOAD,
        payload: data,
        settingNames: settingNames,
        page: page,
      })

      if (callback && typeof callback === "function") {
        return callback(data.data);
      }
    });
  };
}

function kihonAdlMasterCreator() {
  return (dispatch) => {
    KihonAdlMasterApi.all().then((data) =>
      dispatch({
        type: KIHON_ADL_MASTER_ALL,
        payload: data,
      })
    );
  };
}

function SettingMasterAllCreator(callback) {
  return (dispatch) => {
    SettingMasterAPI.all().then((data) => {
      dispatch({
        type: SETTING_MASTER_ALL,
        payload: data,
      });

      if (callback && typeof callback === "function") {
        return callback(data);
      }
    });
  };
}

function SettingMasterNewCreator(singleTextTitle, singleText) {
  return (dispatch) => {
    dispatch({
      type: SETTING_MASTER_PAGE_REQUEST,
    });

    SettingMasterAPI.allNew(singleTextTitle, singleText).then((data) =>
      dispatch({
        type: SETTING_MASTER_ALL_NEW,
        payload: data,
      })
    );
  };
}

export { SettingMasterAllCreator, SettingMasterNewCreator, kihonAdlMasterCreator };

