import {
  LOAD_ROOM_SUCCESS,
  LOAD_ROOM_REQUEST,
  LOAD_ROOM_ERROR,
} from "../../actions/roomMasterActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

export default function roomMasterReducers(state = {}, action) {
  switch (action.type) {
    case LOAD_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_ROOM_SUCCESS:
      return {
        ...state,
        rooms: action.payload,
        loading: false,
      };
    case LOAD_ROOM_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        rooms: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
