import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";
import { checkLogin } from "../common/function_common/functionCommon";

let SendGroupApi = {
  update(groupRecords, delIdsSendGroup) {
    return axios(apiUrls.INIT + apiUrls.SENDGROUP_UPDATE, {
      method: "POST",
      data: {
        delIds: delIdsSendGroup,
        addObjects: groupRecords,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
  sendGroup() {
    return axios(apiUrls.INIT + apiUrls.GET_MESSAGE_GROUP, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
  getAllSendGroup() {
    return axios(apiUrls.INIT + apiUrls.SENDGROUP_GETALL, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
};
export default SendGroupApi;
