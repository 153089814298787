import {
  REFERENCE_NUMBER_SPIN_REQUEST,
  GET_MASTER_NEW_CODE_SUCCESS,
  GET_MASTER_NEW_CODE_ERROR,
  CANCEL_CREATE_MASTER_SUCCESS,
  CANCEL_CREATE_MASTER_ERROR,
} from "../../actions/referenceNumberActions/actionName";

import { ReferenceNumberApi } from "../../api/referenceNumber";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

export function getMasterNewCode(table, field, callback) {
  return (dispatch) => {
    dispatch({
      type: REFERENCE_NUMBER_SPIN_REQUEST,
    });
    ReferenceNumberApi.getMasterNewCode(table, field).then(
      (data) => {
        dispatch({
          type: GET_MASTER_NEW_CODE_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: GET_MASTER_NEW_CODE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function cancelCreateMaster(table, field, code) {
  return (dispatch) => {
    dispatch({
      type: REFERENCE_NUMBER_SPIN_REQUEST,
    });
    ReferenceNumberApi.cancelCreateMaster(table, field, code).then(
      (data) => {
        dispatch({
          type: CANCEL_CREATE_MASTER_SUCCESS,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: CANCEL_CREATE_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
