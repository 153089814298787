export const YobousienPlanActionTypes = {
  GET_ALL_YOBOUSIEN_PLAN_REQUEST: "GET_ALL_YOBOUSIEN_PLAN_REQUEST",
  GET_ALL_YOBOUSIEN_PLAN_SUCCESS: "GET_ALL_YOBOUSIEN_PLAN_SUCCESS",
  GET_ALL_YOBOUSIEN_PLAN_FAILURE: "GET_ALL_YOBOUSIEN_PLAN_FAILURE",

  GET_YOBOUSIEN_PLAN_REQUEST: "GET_YOBOUSIEN_PLAN_REQUEST",
  GET_YOBOUSIEN_PLAN_SUCCESS: "GET_YOBOUSIEN_PLAN_SUCCESS",
  GET_YOBOUSIEN_PLAN_FAILURE: "GET_YOBOUSIEN_PLAN_FAILURE",

  CREATE_YOBOUSIEN_PLAN_REQUEST: "CREATE_YOBOUSIEN_PLAN_REQUEST",
  CREATE_YOBOUSIEN_PLAN_SUCCESS: "CREATE_YOBOUSIEN_PLAN_SUCCESS",
  CREATE_YOBOUSIEN_PLAN_FAILURE: "CREATE_YOBOUSIEN_PLAN_FAILURE",

  UPDATE_YOBOUSIEN_PLAN_REQUEST: "UPDATE_YOBOUSIEN_PLAN_REQUEST",
  UPDATE_YOBOUSIEN_PLAN_SUCCESS: "UPDATE_YOBOUSIEN_PLAN_SUCCESS",
  UPDATE_YOBOUSIEN_PLAN_FAILURE: "UPDATE_YOBOUSIEN_PLAN_FAILURE",

  REMOVE_YOBOUSIEN_PLAN_REQUEST: "REMOVE_YOBOUSIEN_PLAN_REQUEST",
  REMOVE_YOBOUSIEN_PLAN_SUCCESS: "REMOVE_YOBOUSIEN_PLAN_SUCCESS",
  REMOVE_YOBOUSIEN_PLAN_FAILURE: "REMOVE_YOBOUSIEN_PLAN_FAILURE",

  GET_YOBOUSIEN_PLAN_BY_DATA_REQUEST: "GET_YOBOUSIEN_PLAN_BY_DATA_REQUEST",
  GET_YOBOUSIEN_PLAN_BY_DATA_SUCCESS: "GET_YOBOUSIEN_PLAN_BY_DATA_SUCCESS",
  GET_YOBOUSIEN_PLAN_BY_DATA_FAILURE: "GET_YOBOUSIEN_PLAN_BY_DATA_FAILURE",

  GET_ALL_YOBOUSIEN_PLAN_QUOTE_REQUEST: "GET_ALL_YOBOUSIEN_PLAN_QUOTE_REQUEST",
  GET_ALL_YOBOUSIEN_PLAN_QUOTE_SUCCESS: "GET_ALL_YOBOUSIEN_PLAN_QUOTE_SUCCESS",
  GET_ALL_YOBOUSIEN_PLAN_QUOTE_FAILURE: "GET_ALL_YOBOUSIEN_PLAN_QUOTE_FAILURE",

  RESET_CURRENT_YOBOUSIEN_PLAN: "RESET_CURRENT_YOBOUSIEN_PLAN",

  GET_KIHON_CHECK_YOBOU_REQUEST: "GET_KIHON_CHECK_YOBOU_REQUEST",
  GET_KIHON_CHECK_YOBOU_SUCCESS: "GET_KIHON_CHECK_YOBOU_SUCCESS",
  GET_KIHON_CHECK_YOBOU_FAILURE: "GET_KIHON_CHECK_YOBOU_FAILURE",

  GET_FIRST_DATE_REQUEST: "GET_FIRST_DATE_REQUEST",
  GET_FIRST_DATE_SUCCESS: "GET_FIRST_DATE_SUCCESS",
  GET_FIRST_DATE_FAILURE: "GET_FIRST_DATE_FAILURE",

  SET_ROW_YOBOUSIEN_PLAN: "SET_ROW_YOBOUSIEN_PLAN",
  CHANGE_ROW_KEY: "CHANGE_ROW_KEY",
  ADD_ROWS_YOBOUSIEN_PLAN: "ADD_ROWS_YOBOUSIEN_PLAN",
  DELETE_ROWS_YOBOUSIEN_PLAN: "DELETE_ROWS_YOBOUSIEN_PLAN",
  GET_DETAIL_YOBOUSIEN_PLAN: "GET_DETAIL_YOBOUSIEN_PLAN",
  GET_ROWINFO_YOBOUSIEN_PLAN: "GET_ROWINFO_YOBOUSIEN_PLAN",
  UP_ROWS_YOBOUSIEN_PLAN: "UP_ROWS_YOBOUSIEN_PLAN",
  UP_ROWS_YOBOUSIEN_PLAN_DETAIL: "UP_ROWS_YOBOUSIEN_PLAN_DETAIL",
  DOWN_ROWS_YOBOUSIEN_PLAN: "DOWN_ROWS_YOBOUSIEN_PLAN",
  DOWN_ROWS_YOBOUSIEN_PLAN_DETAIL: "DOWN_ROWS_YOBOUSIEN_PLAN_DETAIL",
  ADD_DETAIL_YOBOUSIEN_PLAN: "ADD_DETAIL_YOBOUSIEN_PLAN",
  DELETE_DETAIL_YOBOUSIEN_PLAN: "ADD_DETAIL_YOBOUSIEN_PLAN",
  CLEAR_DETAIL_YOBOUSIEN_PLAN: "CLEAR_DETAIL_YOBOUSIEN_PLAN",

  CHANGE_EDIT_MOBILE_MODE: "CHANGE_EDIT_MOBILE_MODE",

  NEW_PAGE: "NEW_PAGE",
};
