import React from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Col, Row, Checkbox, Button } from "antd";

// import { convertMessageByCode } from '../../../common/function_common/functionCommon'
import TantoMasterKB from "../model-kiroku/TantoMasterKB";
import CommentKB from "../model-kiroku/CommentKB";
import InputSelectKB from "../model-kiroku/InputSelectKB";
import TimeKBCus from "../component/TimeKBCus";

class NormalNyuyokuForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif ? true : false,
      type: null,
      extendValue: false,
      visibleNoKeyboard: false,
    };

    this.onChangeTime = this.onChangeTime.bind(this);
    this.onClickRctf = this.onClickRctf.bind(this);

    this.onChangeShurui = this.onChangeShurui.bind(this);

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.data !== newProps.data) {
      this.setState({
        reportCaseTyuif: newProps.data.reportCaseTyuif ? true : false,
      });

      this.props.form.resetFields();
    }
  }

  onChangeTime(time, timeString) {
    this.props.handleFormInput(timeString, "time");
  }

  onClickRctf(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "reportCaseTyuif");
    this.setState({
      reportCaseTyuif: e.target.checked,
    });
  }

  onChangeShurui(value) {
    this.props.handleFormInput(value, "nyuyokuNaiyou");
  }

  syokichiUpdate = () => {
    this.props.syokichiUpdate(true);
  };

  syokichiItem = () => {
    this.props.syokichiItem(true);
  };

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "tantoId");
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulModeNew = (timeString) => {
    this.props.handleFormInput(timeString, "time");
  };

  /**
   * getCurrentTime
   *
   */
  handleSetCurrentTime = () => {
    let currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.props.handleFormInput(currentTime, "time");
  };

  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { tantoMaster, data, yougoMasterNa, yougoMasterSy } = this.props;

    return (
      <Form layout="vertical" hideRequiredMark>
        <Row>
          <Col className="kdf-label" span={4}>
            <span>時間</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item className="form-item-300">
              <TimeKBCus
                timeString={data && data.time ? data.time : null}
                handleInput={this.onChangeTimeMulModeNew}
              />
              <Button
                className="kdf-number-k"
                type="primary"
                onClick={this.handleSetCurrentTime}
              >
                現時刻
              </Button>
            </Form.Item>
            <Form.Item className="form-item-100">
              {getFieldDecorator("reportCaseTyuif", {
                rules: [{ required: false }],
              })(
                <Checkbox
                  className="kdf-f-extent"
                  checked={data && data.reportCaseTyuif ? true : false}
                  onChange={this.onClickRctf}
                >
                  申送
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className="kdf-label" span={4}>
            <span>種類</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <InputSelectKB
                value={data.nyuyokuNaiyou}
                handleInputSelect={this.onChangeShurui}
                data={yougoMasterSy}
                syokichiItem={this.syokichiItem}
                syokichiUpdate={this.syokichiUpdate}
                typeSyokichi={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className="kdf-label" span={4}>
            <span>コメント</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={4}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className="kdf-label" span={4}>
            <span>担当者</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const NyuyokuForm = Form.create()(NormalNyuyokuForm);
export default NyuyokuForm;
