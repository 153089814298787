import { UndoOutlined } from "@ant-design/icons";
import { Row, notification } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import TabRecords from "../../common/TabRecords/TabRecords";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import { ConstSet } from "../../common/configs/constset";
import {
  addZeroToDateString,
  extractRiyousyaInfo,
  getUrlVars,
  getValueIskeep,
  getValueLocalstorage,
  setValueIsKeep,
} from "../../common/function_common/functionCommon";
import jsonLocal from "../../data/jsonLocal";
import "./ReportUser.css";
import Filter from "./UI/Filter";
import Fukuyaku from "./UI/Fukuyaku";
import Gazou from "./UI/Gazou";
import Haisetu from "./UI/Haisetu";
import Kaigonaiyou from "./UI/Kaigonaiyou";
import Meal from "./UI/Meal";
import Nyuyoku from "./UI/Nyuyoku";
import Riyousya from "./UI/Riyousya";
import SubMenuInc from "./UI/SubMenuInc";
import Suibun from "./UI/Suibun";
import Tokki from "./UI/Tokki";
import Vital from "./UI/Vital";

class Report extends Component {
  constructor(props) {
    super(props);

    const SetTimeout = getValueLocalstorage("sessionTimeout");
    const tabActive = localStorage.getItem("tabActive")
      ? localStorage.getItem("tabActive")
      : "利用者";

    localStorage.setItem("tabActive", tabActive);

    if (!localStorage.getItem("is_keep")) {
      localStorage.setItem("is_keep", JSON.stringify(jsonLocal));
    }

    const today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    let startDate;
    let endDate;
    let preDateLocal = localStorage.getItem("nyuDate");
    if (preDateLocal) {
      preDateLocal = addZeroToDateString(preDateLocal); // convert to standard
      startDate = dayjs(preDateLocal).startOf("month").format("YYYY-MM-DD");
      endDate = dayjs(preDateLocal).endOf("month").format("YYYY-MM-DD");
    }
    else {
      startDate = dayjs(date).startOf("month").format("YYYY-MM-DD");
      endDate = dayjs(date).endOf("month").format("YYYY-MM-DD");
    }

    const mealType = getValueIskeep("mealType");
    const haisetuKubun = getValueLocalstorage("haisetuType");
    const kaigoNaiyouCode = getValueIskeep("kiroku_kaigo_naiyou");

    this.state = {
      tid: getValueLocalstorage("tid"),
      socket: this.props.socket,
      visible: false,
      sesstionTimeout: false,
      SetTimeout: SetTimeout ? SetTimeout : 0,

      modalVisible: false,
      updateModalVisible: false,
      expandForm: false, // @TODO: pro is false
      selectedRows: [],
      formValues: {},
      stepFormValues: {},
      TableStyle: false,
      displayChecked: false,
      // nyutaishoDate: localStorage.getItem('nyuDate') ? localStorage.getItem('nyuDate') : date,
      sortState: [],
      tabActive: tabActive ? tabActive : "利用者",

      filter: JSON.parse(localStorage.getItem("is_keep")), // filter data that is saved in localstorate
      mealType: mealType ? mealType : "全表示",
      haisetuKubun: haisetuKubun ? haisetuKubun : "両方",
      gender: "両方",
      kaigoNaiyouCode: kaigoNaiyouCode ? kaigoNaiyouCode : "全表示",
      inputMode: false, // @TOTO pro is false
      mulInputAllMode: false,
      mulInputMode: false,
      mulDelMode: false,
      mulInputModal: false,

      messageApi: false,

      disabledIkkatsuBtn: true, // when click on 一括入力, to active button user have to choice 利用者,

      fromDate: startDate,
      toDate: endDate,
      riyousayInfo: extractRiyousyaInfo(
        this.props.match.params.userId,
        localStorage.getItem("kirokuBasic")
      )[0],

      isDisLongText: false,

      countType: 0,
    };

    // bind place
    this.renderFilterForm = this.renderFilterForm.bind(this);
    this.getHandleTableView = this.getHandleTableView.bind(this);
    this.getTagDisplay = this.getTagDisplay.bind(this);
    this.getHandleSort = this.getHandleSort.bind(this);
    this.getHandleRefresh = this.getHandleRefresh.bind(this);
    this.handleExpandForm = this.handleExpandForm.bind(this);
    this.getInputModeHandleChange = this.getInputModeHandleChange.bind(this);
    this.getMulInputModeHandleChange =
      this.getMulInputModeHandleChange.bind(this);

    this.getHandleCopyBaitaru = this.getHandleCopyBaitaru.bind(this);
    this.getHandleDelBaitaru = this.getHandleDelBaitaru.bind(this);
    this.getHandleCopyMeal = this.getHandleCopyMeal.bind(this);
    this.getHandleDelMeal = this.getHandleDelMeal.bind(this);
    this.getHandleCopySuibun = this.getHandleCopySuibun.bind(this);
    this.getHandleDelSuibun = this.getHandleDelSuibun.bind(this);
    this.getHandleCopyHaisetu = this.getHandleCopyHaisetu.bind(this);
    this.getHandleDelHaisetu = this.getHandleDelHaisetu.bind(this);
    this.getHandleCopyNyuyoku = this.getHandleCopyNyuyoku.bind(this);
    this.getHandleDelNyuyoku = this.getHandleDelNyuyoku.bind(this);
    this.getHandleCopyFukuyaku = this.getHandleCopyFukuyaku.bind(this);
    this.getHandleDelFukuyaku = this.getHandleDelFukuyaku.bind(this);
    this.getHandleCopyKaigonaiyou = this.getHandleCopyKaigonaiyou.bind(this);
    this.getHandleDelKaigonaiyou = this.getHandleDelKaigonaiyou.bind(this);
    this.getHandleCopyTokki = this.getHandleCopyTokki.bind(this);
    this.getHandleDelTokki = this.getHandleDelTokki.bind(this);
    this.getHandleCopyGazo = this.getHandleCopyGazo.bind(this);
    this.getHandleDelGazo = this.getHandleDelGazo.bind(this);

    this.getHandleOkUpdateRecord = this.getHandleOkUpdateRecord.bind(this);
    this.getHandleOkInsertRecord = this.getHandleOkInsertRecord.bind(this);

    this.getHandleOkUpdateGazou = this.getHandleOkUpdateGazou.bind(this);
    this.getHandleOkInsertGazou = this.getHandleOkInsertGazou.bind(this);
    this.getHandleDeleteGazou = this.getHandleDeleteGazou.bind(this);

    // handle edit comment setting
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    // handle edit ect setting
    this.getYougoMasterEctState = this.getYougoMasterEctState.bind(this);
    this.getYougoMasterEctCancel = this.getYougoMasterEctCancel.bind(this);
    this.getYougoMasterEctSave = this.getYougoMasterEctSave.bind(this);

    this.getHandleMulInputModal = this.getHandleMulInputModal.bind(this);
    this.getHandleMulInputModeCancel =
      this.getHandleMulInputModeCancel.bind(this);
    this.getHandleDeleteBaitaru = this.getHandleDeleteBaitaru.bind(this);

    this.getHandleIkkatsuBtn = this.getHandleIkkatsuBtn.bind(this);
    this.getZendkaiLoad = this.getZendkaiLoad.bind(this);

    this.getHandleTabChange = this.getHandleTabChange.bind(this);

    this.getLoadEctSelectData = this.getLoadEctSelectData.bind(this); // ect is select input type

    // Drug
    this.getHandleDrugSearch = this.getHandleDrugSearch.bind(this);
    this.download = this.download.bind(this);

    //
    this.getRangeDateChange = this.getRangeDateChange.bind(this);
  }

  /**
   * Load for first Time
   */
  componentDidMount() {
    // Update Menu
    const preMenu = JSON.parse(localStorage.getItem("menu"));
    if (getUrlVars(window.location.href)["sn"]) {
      preMenu.sn = getUrlVars(window.location.href)["sn"];
    }
    if (getUrlVars(window.location.href)["si"]) {
      preMenu.si = getUrlVars(window.location.href)["si"];
    }
    localStorage.setItem("menu", JSON.stringify(preMenu));

    this.props.nyutaishoFilter(this.state.tabActive); // load filter data

    const startTime = "0:00";
    const endTime = "23:59";

    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      nyutaishoDate: this.state.nyutaishoDate,
      tabName: this.state.tabActive,
      startDate: this.state.fromDate,
      endDate: this.state.toDate,
      startTime, // vital
      endTime,

      //
      mealType: this.state.mealType,
      kubun: this.state.mealType,
      haisetuKubun: this.state.haisetuKubun,
      gender: this.state.gender,
      kaigoNaiyouCode: this.state.kaigoNaiyouCode,
      shubetu: this.state.shubetu ? this.state.shubetu : this.state.mealType,
    };

    this.props.loadData(parameterObj);
    this.props.tantoMasterList();

    // Load comment default
    const tabName = localStorage.getItem("tabActive");
    if (tabName) {
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          this.props.yougoMasterNaiyou("バイタルコメント");
          this.props.loadEctSelectData(
            JSON.parse(localStorage.getItem("menu")).si
          );
          break;

        case ConstSet.KIROKU_MEAL:
          this.props.yougoMasterNaiyou("食事コメント");
          break;

        case ConstSet.KIROKU_SUIBUN:
          this.props.yougoMasterNaiyou("水分コメント");
          this.props.yougoMasterSyurui("水分種類");
          break;

        case ConstSet.KIROKU_HAISETU:
          this.props.yougoMasterNaiyou("排泄コメント");
          this.props.yougoMasterSyurui("排泄種類");
          this.props.yougoMasterJoutai("排泄状態");
          break;

        case ConstSet.KIROKU_NYUYOKU:
          this.props.yougoMasterNaiyou("入浴コメント");
          this.props.yougoMasterSyurui("入浴対応");
          break;

        case ConstSet.KIROKU_FUKUYAKU:
          this.props.yougoMasterNaiyou("服薬コメント");
          this.props.loadDrugMaster("", "");
          break;

        case ConstSet.KIROKU_KAIGO:
          this.props.yougoMasterNaiyou("介護記録コメント");
          break;

        case ConstSet.KIROKU_TOKKI:
          this.props.yougoMasterNaiyou("その他コメント");
          break;

        case ConstSet.KIROKU_FILE:
          this.props.yougoMasterNaiyou("画像コメント");
          break;

        default:
          break;
      }
    }
  }

  /**
   * Load when action ある
   */
  componentDidUpdate(oldProps) {
    // Check When Action Complie
    const { notifyDisplay, notifyContent } = this.props;

    const { mulInputAllMode } = this.state;

    if (oldProps.notifyDisplay !== notifyDisplay && !mulInputAllMode) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  /**
   * Double login or Timeout session
   */
  handleOk = () => {
    // Close Popup double login
    this.setState({
      visible: false,
    });
  };

  /**
   * Filter place expand function
   */
  handleExpandForm = (expandForm) => {
    this.setState({
      expandForm,
    });
  };

  /**
   *
   * @param {*} check
   */
  getHandleTableView(check) {
    this.setState({
      TableStyle: check,
      displayChecked: check,
    });
  }

  /**
   *
   * @param {*} name
   */
  getHandleSearchByName() {
    this.props.searchByName(this.state.filter.searchName);
  }

  /**
   *
   * @param {*} filterData
   */
  getHandleSort() {
    this.props.sort(this.state.filter);
  }

  getHandleRefresh = () => {
    this.setState({
      sortState: [],
    });
    this.props.refresh();
  };

  /**
   * Search handle
   * @param {*} filterData
   */
  getHandleState(filterData) {
    if (!filterData.search.checkbox1) {
      filterData.search.field10 = null;
      filterData.search.field11 = null;
    }
    if (!filterData.search.checkbox2) {
      filterData.search.field2 = null;
    }
    if (!filterData.search.checkbox3) {
      filterData.search.field3 = null;
    }

    this.setState({
      filter: filterData,
    });
  }

  getHandleSearch() {
    this.props.nyutaishoSearch(this.state.filter);
  }

  getHandleMealType(value) {
    this.setState({
      mealType: value,
    });

    /**
     * バイタルのデータを習得
     *
     * @todo remove. meal tab dont use time like paramenters
     */
    const startTime = "0:00";
    const endTime = "23:59";

    //
    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      nyutaishoDate: this.state.nyutaishoDate,
      tabName: this.state.tabActive,
      startDate: this.state.fromDate,
      endDate: this.state.toDate,
      startTime, // vital
      endTime,
      shubetu: value,
    };

    this.props.loadData(parameterObj);
  }

  getHandleHaisetuKubun(value) {
    this.setState({
      haisetuKubun: value,
    });

    /**
     * バイタルのデータを習得
     *
     * @todo remove. meal tab dont use time like paramenters
     */
    const startTime = "0:00";
    const endTime = "23:59";

    //
    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      nyutaishoDate: this.state.nyutaishoDate,
      tabName: this.state.tabActive,
      startDate: this.state.fromDate,
      endDate: this.state.toDate,
      startTime,
      endTime,
      haisetuKubun: value,
    };

    this.props.loadData(parameterObj);
  }

  getHandleTeikyou(value) {
    // Save to localstorage Search Condition
    setValueIsKeep(ConstSet.LS_PN_KIROKU_KAIGO_NAIYOU, value);
    this.setState({
      kaigoNaiyouCode: value,
    });

    /**
     * バイタルのデータを習得
     *
     * @todo remove. meal tab dont use time like paramenters
     */
    const startTime = "0:00";
    const endTime = "23:59";

    //
    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      nyutaishoDate: this.state.nyutaishoDate,
      tabName: this.state.tabActive,
      startDate: this.state.fromDate,
      endDate: this.state.toDate,
      startTime,
      endTime,
      kaigoNaiyouCode: value && value === "全表示" ? "" : value,
    };

    this.props.loadData(parameterObj);
  }

  /**
   *
   * @param {*} value
   */
  getHandleGender(value) {
    this.setState({
      gender: value,
    });

    this.props.searchByGender(value);
  }

  /**
   *
   * @param {*} value
   */
  getInputModeHandleChange(value) {
    this.setState({
      inputMode: value,
      mulInputAllMode: false,
      mulInputMode: false,
      mulDelMode: false,
    });
  }

  /**
   *
   * @param {*} value
   */
  getMulInputModeHandleChange(value) {
    this.setState({
      inputMode: false,
      mulInputAllMode: value,
      mulInputMode: false,
      mulDelMode: false,
    });
  }

  mulDelModeChange = (value) => {
    this.setState({
      inputMode: false,
      mulInputMode: false,
      mulDelMode: value,
    });
  };

  getHandleMulInputModal(type) {
    this.setState({
      mulInputModal: true,
      mulInputMode: type === 1,
      // mulYoteiMode: type === 2 ? true : false,
      mulDelMode: type === 3,
    });
  }

  getHandleMulInputModeCancel() {
    this.setState({
      mulInputModal: false,
    });
  }

  /**
   *
   * @param {*} baitaruRecord
   */
  getHandleCopyBaitaru(baitaruRecord) {
    this.props.copyBaitaru(baitaruRecord);
  }

  /**
   *
   * @param {*} baitaruRecord
   */
  getHandleDelBaitaru(baitaruRecord) {
    this.props.delBaitaru(baitaruRecord);
  }

  /**
   *
   * @param {*} mealRecord
   */
  getHandleCopyMeal(mealRecord) {
    this.props.copyMeal(mealRecord);
  }

  /**
   *
   * @param {*} mealRecord
   */
  getHandleDelMeal(mealRecord) {
    this.props.delMeal(mealRecord);
  }

  /**
   *
   * @param {*} suibunRecord
   */
  getHandleCopySuibun(suibunRecord) {
    this.props.copySuibun(suibunRecord);
  }

  /**
   *
   * @param {*} suibunRecord
   */
  getHandleDelSuibun(suibunRecord) {
    this.props.delSuibun(suibunRecord);
  }

  /**
   *
   * @param {*} haisetuRecord
   */
  getHandleCopyHaisetu(haisetuRecord) {
    this.props.copyHaisetu(haisetuRecord);
  }

  /**
   *
   * @param {*} haisetuRecord
   */
  getHandleDelHaisetu(haisetuRecord) {
    this.props.delHaisetu(haisetuRecord);
  }

  /**
   *
   * @param {*} nyuyokuRecord
   */
  getHandleCopyNyuyoku(nyuyokuRecord) {
    this.props.copyNyuyoku(nyuyokuRecord);
  }

  /**
   *
   * @param {*} nyuyokuRecord
   */
  getHandleDelNyuyoku(nyuyokuRecord) {
    this.props.delNyuyoku(nyuyokuRecord);
  }

  /**
   *
   * @param {*} fukuyakuRecord
   */
  getHandleCopyFukuyaku(fukuyakuRecord) {
    this.props.copyFukuyaku(fukuyakuRecord);
  }

  /**
   *
   * @param {*} fukuyakuRecord
   */
  getHandleDelFukuyaku(fukuyakuRecord) {
    this.props.delFukuyaku(fukuyakuRecord);
  }

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleCopyKaigonaiyou(kaigonaiyouRecord) {
    this.props.copyKaigonaiyou(kaigonaiyouRecord);
  }

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleDelKaigonaiyou(kaigonaiyouRecord) {
    this.props.delKaigonaiyou(kaigonaiyouRecord);
  }

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleCopyTokki(tokkiRecord) {
    this.props.copyTokki(tokkiRecord);
  }

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleDelTokki(tokkiRecord) {
    this.props.delTokki(tokkiRecord);
  }

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleCopyGazo(gazoRecord) {
    this.props.copyGazo(gazoRecord);
  }

  /**
   *
   * @param {*} kaigonaiyouRecord
   */
  getHandleDelGazo = (gazoRecord) => {
    this.props.delGazo(gazoRecord);
  };

  /**
   *
   * @param {*} params
   */
  getHandleOkUpdateRecord = (params) => {
    this.props.updateRecord(params);
  };

  /**
   *
   */
  getHandleDeleteBaitaru = (vitalId) => {
    this.props.deleteRecord(vitalId);
  };

  /**
   *
   */
  getHandleOkInsertRecord = (params) => {
    this.props.insertRecord(params);
  };

  getHandleOkInsertRecordMul = (params) => {
    this.setState({
      updateMode: 1,
    });

    this.props.insertRecordMul(params);
  };

  getHandleOkUpdateRecordMul = (params) => {
    this.setState({
      updateMode: 2,
    });

    this.props.updateRecordMul(params);
  };

  /**
   *
   * @param {*} params
   */
  getHandleOkUpdateGazou = (params, callback) => {
    this.props.gazouUpdate(params, callback);
  };

  /**
   *
   */
  getHandleDeleteGazou = (fileManagementId) => {
    this.props.gazouDelete(fileManagementId);
  };

  /**
   *
   */
  getHandleOkInsertGazou = (params, callback) => {
    this.props.gazouInsert(params, callback);
  };

  /**
   *
   * handle edit comment default setting
   */
  /**
   *
   * @param {*} index
   */
  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  /**
   *
   * @param {*} index
   */
  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  /**
   *
   * @param {*} naiyou
   */
  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }
  // END handle default comment setting

  /**
   *
   * handle edit comment default setting
   */
  /**
   *
   * @param {*} index
   */
  getYougoMasterEctState(index) {
    this.props.yougoMasterEctState(index);
  }

  /**
   *
   * @param {*} index
   */
  getYougoMasterEctCancel(index) {
    this.props.yougoMasterEctCancel(index);
  }

  /**
   *
   * @param {*} naiyou
   */
  getYougoMasterEctSave(type, naiyou) {
    this.props.yougoMasterEctSave(type, naiyou);
  }
  // END handle default comment setting

  getHandleIkkatsuBtn(value) {
    this.setState({
      disabledIkkatsuBtn: value,
    });
  }

  getZendkaiLoad(riyousyaId, date, time) {
    this.props.loadZenkai(date, riyousyaId, time);
  }

  /**
   *
   * @param {*} dateString
   */
  getRangeDateChange(dateString) {
    this.setState({
      fromDate: dateString[0],
      toDate: dateString[1],
    });

    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      startDate: dateString[0],
      endDate: dateString[1],
      tabName: this.state.tabActive,
      shubetu: this.state.shubetu,
      haisetuKubun: this.state.haisetuKubun,
      kaigoNaiyouCode: this.state.kaigoNaiyouCode,
    };

    this.props.loadData(parameterObj);
  }

  renderFilterForm() {
    const { isDisLongText } = this.state;
    const { reportFilterData } = this.props;

    return (
      <Filter
        rangeDateChange={this.getRangeDateChange}
        fromDate={this.state.fromDate}
        toDate={this.state.toDate}
        inputMode={this.state.inputMode}
        inputModeHandleChange={this.getInputModeHandleChange}
        mulInputMode={this.state.mulInputMode}
        MulInputModeHandleChange={this.getMulInputModeHandleChange}
        handleMulInputModal={this.getHandleMulInputModal}
        disabledIkkatsuBtn={this.state.disabledIkkatsuBtn}
        mulInputAllMode={this.state.mulInputAllMode}
        tabActive={this.state.tabActive}
        mealType={this.state.mealType}
        handleMealType={(value) => this.getHandleMealType(value)}
        haisetuKubun={this.state.haisetuKubun}
        handleHaisetuKubun={(value) => this.getHandleHaisetuKubun(value)}
        handleTeikyou={(value) => this.getHandleTeikyou(value)}
        kaigoNaiyouCode={this.state.kaigoNaiyouCode}
        nyutaishoFilter={reportFilterData}
        toggleOpenLongText={this.toggleOpenLongText}
        isDisLongText={isDisLongText}
      />
    );
  }

  /**
   * Drug search
   */
  getHandleDrugSearch(bunrui, name) {
    this.props.loadDrugMaster(bunrui, name);
  }

  download(fileId, fileName) {
    this.props.download(fileId, fileName);
  }

  renderTableRecords() {
    const { tabActive, isDisLongText } = this.state;
    const {
      loading,
      riyousyaTab,
      reportFilterData,
      baitaruTab,
      shokujiTab,
      suibunTab,
      haisetuTab,
      nyutaishoTab,
      fukuyakuTab,
      kaigonaiyouTab,
      tokkiTab,
      gazouTab,
      loadingButtonUpdate,
      tantoMaster,
      yougoMasterNa,
      yougoMasterSy, // synrui の
      yougoMasterJt,
      zenkai,
      yougoMasterEctSelect1,
      yougoMasterEctSelect2,
      yougoMasterEctSelect3,
      drugList,
      drugLoading,
      syokichi,
    } = this.props;

    if (tabActive === "利用者") {
      return (
        <Riyousya
          TableStyle={this.state.TableStyle}
          nyutaisho={riyousyaTab}
          loading={loading}
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "バイタル") {
      return (
        <Vital
          // table data
          nyutaisho={baitaruTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Other Comment Setting
          vitalEct={
            reportFilterData && reportFilterData.vitalEct
              ? reportFilterData.vitalEct
              : ""
          }
          vitalEctType={
            reportFilterData && reportFilterData.vitalEctType
              ? reportFilterData.vitalEctType
              : ""
          }
          vitalCol={
            reportFilterData && reportFilterData.vitalCol
              ? reportFilterData.vitalCol.toString().split(";")
              : ""
          }
          // Vital Check Waring High Low Data
          vitalHighLow={
            reportFilterData && reportFilterData.vitalHighLow
              ? reportFilterData.vitalHighLow
              : ""
          }
          // Update With Multiple Row
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputMode={this.state.mulInputMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          handleDelVital={this.getHandleDelBaitaru} // del from local for copy action
          handleCopyRecord={this.getHandleCopyBaitaru}
          // Load Zenkai Data
          zenkai={zenkai}
          zendkaiLoad={this.getZendkaiLoad}
          // other comment input type. form combox type. data has been load from setting
          yougoMasterEctSelect1={yougoMasterEctSelect1}
          yougoMasterEctSelect2={yougoMasterEctSelect2}
          yougoMasterEctSelect3={yougoMasterEctSelect3}
          yougoMasterEctState={this.getYougoMasterEctState}
          yougoMasterEctCancel={this.getYougoMasterEctCancel}
          yougoMasterEctSave={this.getYougoMasterEctSave}
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "食事摂取") {
      return (
        <Meal
          // table data
          nyutaisho={shokujiTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleDelMeal={this.getHandleDelMeal} // del from local for copy action
          handleCopyMeal={this.getHandleCopyMeal}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          // MealType
          mealType={this.state.mealType}
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "水分摂取") {
      return (
        <Suibun
          // table data
          nyutaisho={suibunTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopySuibun={this.getHandleCopySuibun}
          handleDelSuibun={this.getHandleDelSuibun}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          yougoMasterSy={yougoMasterSy} // get value for 種類
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "排泄") {
      return (
        <Haisetu
          // table data
          nyutaisho={haisetuTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          mulDelMode={this.state.mulDelMode}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyHaisetu={this.getHandleCopyHaisetu}
          handleDelHaisetu={this.getHandleDelHaisetu}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          yougoMasterSy={yougoMasterSy} // get value for naiyouのtab
          yougoMasterJt={yougoMasterJt}
          // TYPE
          haisetuKubun={this.state.haisetuKubun}
          // syokichi
          syokichiItem={this.syokichiItem}
          syokichiUpdate={this.syokichiUpdate}
          syokichi={syokichi}
          isDisLongText={isDisLongText}
          settingColor={
            reportFilterData && reportFilterData.weeklyColor
              ? reportFilterData.weeklyColor
              : ""
          }
        />
      );
    }
    else if (tabActive === "入浴") {
      return (
        <Nyuyoku
          // table data
          nyutaisho={nyutaishoTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyNyuyoku={this.getHandleCopyNyuyoku}
          handleDelNyuyoku={this.getHandleDelNyuyoku}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          yougoMasterSy={yougoMasterSy} // get value for naiyouのtab
          // syokichi
          syokichiItem={this.syokichiItem}
          syokichiUpdate={this.syokichiUpdate}
          syokichi={syokichi}
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "服薬") {
      return (
        <Fukuyaku
          // table data
          nyutaisho={fukuyakuTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyFukuyaku={this.getHandleCopyFukuyaku}
          handleDelFukuyaku={this.getHandleDelFukuyaku}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          yougoMasterSy={yougoMasterSy} // get value for naiyouのtab
          drugList={drugList} // loading list of drug master
          drugLoading={drugLoading}
          yougoDrugBunrui={
            reportFilterData && reportFilterData.yougoDrugBunrui
              ? reportFilterData.yougoDrugBunrui
              : ""
          }
          handleDrugSearch={this.getHandleDrugSearch}
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "提供記録") {
      return (
        <Kaigonaiyou
          // table data
          nyutaisho={kaigonaiyouTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          mulDelMode={this.state.mulDelMode}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputAllMode={this.state.mulInputAllMode}
          mulInputMode={this.state.mulInputMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyKaigonaiyou={this.getHandleCopyKaigonaiyou}
          handleDelKaigonaiyou={this.getHandleDelKaigonaiyou}
          // handle edit, insert mul
          updateRecordMul={this.getHandleOkUpdateRecordMul}
          insertRecordMul={this.getHandleOkInsertRecordMul}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          // Not Same Tab Setting. Other Info
          teikyouData={
            reportFilterData && reportFilterData.teikyou
              ? reportFilterData.teikyou
              : null
          }
          // TYPE
          kaigoNaiyouCode={this.state.kaigoNaiyouCode}
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "特記") {
      return (
        <Tokki
          // table data
          nyutaisho={tokkiTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Loading icon on Button When Click Save Data
          loadingButtonUpdate={loadingButtonUpdate}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputMode={this.state.mulInputMode}
          mulDelMode={this.state.mulDelMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          updateRecord={this.getHandleOkUpdateRecord}
          insertRecord={this.getHandleOkInsertRecord}
          deleteRecord={this.getHandleDeleteBaitaru} // del from server
          handleCopyTokki={this.getHandleCopyTokki}
          handleDelTokki={this.getHandleDelTokki}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          isDisLongText={isDisLongText}
        />
      );
    }
    else if (tabActive === "画像等") {
      return (
        <Gazou
          // table data
          nyutaisho={gazouTab}
          TableStyle={this.state.TableStyle}
          // loading data icon on table
          loading={loading}
          // Title Of Colum From Setting
          bunruiTitle={
            reportFilterData && reportFilterData.burunTitle
              ? reportFilterData.burunTitle
              : ""
          }
          // gazou kubun
          gazouKubun={
            reportFilterData && reportFilterData.gazouKubun
              ? reportFilterData.gazouKubun
              : ""
          }
          // Update With Multiple Row
          mulInputModal={this.state.mulInputModal}
          handleMulInputModeCancel={this.getHandleMulInputModeCancel}
          // Input Mode Type From Filter Component
          inputMode={this.state.inputMode}
          // Handle Multiple Input Mode
          mulInputMode={this.state.mulInputMode}
          handleIkkatsuBtn={this.getHandleIkkatsuBtn}
          // Automaticall Select Comment From Setting Function
          yougoMasterEditState={this.getYougoMasterEditState}
          yougoMasterEditCancel={this.getYougoMasterEditCancel}
          yougoMasterEditSave={this.getYougoMasterEditSave}
          yougoMasterNa={yougoMasterNa}
          // TanToMaster List Data
          tantoMaster={tantoMaster}
          // handle edit, insert, delete data
          gazouUpdate={this.getHandleOkUpdateGazou}
          gazouInsert={this.getHandleOkInsertGazou}
          gazouDelete={this.getHandleDeleteGazou} // del from server
          handleCopyGazo={this.getHandleCopyGazo}
          handleDelGazo={this.getHandleDelGazo}
          // Load Zenkai Data
          zenkai={zenkai}
          zenkaiMode={this.state.zenkaiMode}
          download={this.download}
          isDisLongText={isDisLongText}
          //
          loadingFileManagement={this.props.loadingFileManagement}
          created={this.props.created}
          deleteFile={this.props.deleteFile}
          update={this.props.update}
          //
          loadFilePreview={this.props.loadFilePreview}
        />
      );
    }
    else if (tabActive === "集計") {
      return (
        <Riyousya
          TableStyle={this.state.TableStyle}
          nyutaisho={riyousyaTab}
          loading={loading}
        // / bunruiTitle={reportFilterData && reportFilterData.burunTitle ? reportFilterData.burunTitle : ''}
        />
      );
    }
  }

  /**
   * Submenu
   * @TODO
   *
   * @param {*} tabActive
   */
  getTagDisplay(tabActive) {
    this.setState({
      tabActive: !tabActive,
    });
  }

  /**
   * Tabs
   * @param {Active Tab} tabName
   */
  getHandleTabChange(tabName) {
    const parameterObj = {
      riyousyaId: this.props.match.params.userId,
      tabName,
      startDate: this.state.fromDate,
      endDate: this.state.toDate,
      isLoadBasic: false,

      //
      mealType: this.state.mealType,
      kubun: this.state.mealType,
      haisetuKubun: this.state.haisetuKubun,
      gender: this.state.gender,
      kaigoNaiyouCode: this.state.kaigoNaiyouCode,
      shubetu: this.state.mealType,
    };

    switch (tabName) {
      case "バイタル":
        this.props.yougoMasterNaiyou("バイタルコメント"); // load comment
        this.props.loadEctSelectData(
          JSON.parse(localStorage.getItem("menu")).si
        ); // load ect select data
        break;

      case ConstSet.KIROKU_MEAL:
        this.props.yougoMasterNaiyou("食事コメント");
        break;

      case "水分摂取":
        this.props.yougoMasterNaiyou("水分コメント");
        this.props.yougoMasterSyurui("水分種類");
        break;
      case "排泄":
        this.props.yougoMasterNaiyou("排泄コメント");
        this.props.yougoMasterSyurui("排泄種類");
        this.props.yougoMasterJoutai("排泄状態");
        break;
      case "入浴":
        this.props.yougoMasterNaiyou("入浴コメント");
        this.props.yougoMasterSyurui("入浴対応");
        break;
      case "服薬":
        this.props.yougoMasterNaiyou("服薬コメント");
        this.props.loadDrugMaster("", "");
        break;
      case "提供記録":
        this.props.yougoMasterNaiyou("介護記録コメント");
        break;
      case "特記":
        this.props.yougoMasterNaiyou("その他コメント");
        break;

      case "画像等":
        this.props.yougoMasterNaiyou("画像コメント");
        break;

      default:
        break;
    }

    this.props.loadData(parameterObj); // Load data

    if (this.state.zenkaiMode && tabName === "バイタル") {
      this.props.loadZenkai(localStorage.getItem("nyuDate"));
    }

    this.setState({
      tabActive: tabName,
      disabledIkkatsuBtn: true,
    });
  }

  getLoadEctSelectData(mainSid) {
    this.props.loadEctSelectData(mainSid);
  }

  /**
   * handle changing riyousyaId from search from
   *
   * @param {string} riyousyaId
   */
  getReportRiyousya = (value) => {
    if (value) {
      this.props.history.push(ConstSet.REPORT_USER_PAGE + value);
    }

    const parameterObj = {
      riyousyaId: value,
      startDate: this.state.fromDate,
      endDate: this.state.toDate,
      tabName: this.state.tabActive,
    };

    this.props.loadData(parameterObj);
  };

  /**
   * get syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   */
  syokichiItem = (riyousyaId, kubun) => {
    this.props.syokichiItem(riyousyaId, kubun);
  };

  /**
   * update syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   * @param {*} syokichi
   */
  syokichiUpdate = (riyousyaId, kubun, syokichi) => {
    this.props.syokichiUpdate(riyousyaId, kubun, syokichi);
  };

  toggleOpenLongText = () => {
    const { isDisLongText } = this.state;

    this.setState({
      isDisLongText: !isDisLongText,
    });
  };

  render() {
    // const { countType } = this.state;
    return (
      <CommonPageContainer
        id="reportUser-page"
        sessionTimeout={this.props.sessionTimeout}
      >
        <div id="report-page-user">
          <Row className={"submenu"}>
            <MediaQuery query="(min-device-width: 0px)">
              {(matches) => {
                if (matches) {
                  return (
                    <SubHeaderV2
                      TopLeft={
                        <SubHeaderTitleV2
                          title={"記録一覧（利用者別）"}
                          iconType="form"
                        />
                      }
                      TopRight={
                        <Link
                          to={ConstSet.REPORT_PAGE}
                          className="sub-top-right-item"
                        >
                          <span>
                            <UndoOutlined />
                          </span>
                          <span> 戻る</span>
                        </Link>
                      }
                      IncBottom={
                        <div>
                          <SubMenuInc
                            riyousayInfo={this.state.riyousayInfo}
                            riyousyaId={this.props.match.params.userId}
                            modoru={
                              ConstSet.REPORT_WEEKLY_PAGE +
                              this.props.match.params.userId
                            }
                            reportRiyousya={this.getReportRiyousya}
                          />
                          <TabRecords
                            tabActive={this.state.tabActive}
                            handleTabChange={(tabName) =>
                              this.getHandleTabChange(tabName)
                            }
                            type={ConstSet.SUBMENU_KIROKU_RIYOUSYA} // define report following date or report following user mode
                          />
                        </div>
                      }
                    />
                  );
                }
                /**
                   * @TODO develop UI for mobile version
                   *
                   */
                // return <SubHeaderMobile
                //   isDengon={false}
                //   isUser={false}
                //   isDailyRe={true}
                //   title={"記録一覧（利用者別）"}
                //   getTagDisplay={this.getTagDisplay}
                //   isTagDisPlay={true}
                // />

              }}
            </MediaQuery>
          </Row>

          <div style={{ padding: "16px 16px 8px 16px" }} className={"styles.tableListForm"}>
            {this.renderFilterForm()}
          </div>

          <div style={{ padding: "0 16px" }}>{this.renderTableRecords()}</div>
        </div>
      </CommonPageContainer>
    );
  }
}

export default Report;
