/* eslint-disable no-nested-ternary */
import { Button, Checkbox } from "antd";
import React, { Component } from "react";
import SearchRiyousyaControl from "../../../common/component/control/SearchRiyousyaControl";
import SortControl from "../../../common/component/control/SortControl";

export class Toolbar1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleSort: false,
      sort: props.sort,
      sort1: props.sort1,
      sort2: props.sort2,
      sort3: props.sort3,
      sort4: props.sort4,
      sort5: props.sort5,
      sort6: props.sort6,
    };
  }

  handleSortOk(sortData) {
    this.setState({
      sort: sortData,
    });
    this.props.onChangeSort(sortData);
    const { tabActive } = this.props;
    if (tabActive === "1") {
      this.setState({
        sort1: sortData,
      });
      this.props.onChangeSort(sortData);
    }
    else if (tabActive === "2") {
      this.setState({
        sort2: sortData,
      });
      this.props.onChangeSort(sortData);
    }
    else if (tabActive === "3") {
      this.setState({
        sort3: sortData,
      });
      this.props.onChangeSort(sortData);
    }
    else if (tabActive === "4") {
      this.setState({
        sort4: sortData,
      });
      this.props.onChangeSort(sortData);
    }
    else if (tabActive === "5") {
      this.setState({
        sort5: sortData,
      });
      this.props.onChangeSort(sortData);
    }
    else if (tabActive === "6") {
      this.setState({
        sort6: sortData,
      });
      this.props.onChangeSort(sortData);
    }
  }

  render() {
    const sortOptionsAll = [
      { value: "riyouHoldingF", display: "利用中" },
      { value: "riyousya", display: "ふりがな" },
    ];
    const sortOptionsPlan1 = [
      { value: "firstPlanDate", display: "初回作成日" },
      { value: "planDate", display: "作成日" },
      { value: "plan1DouiDate", display: "同意日" },
      { value: "tanto", display: "作成者" },
      { value: "progressF", display: "最新" },
      { value: "kubun", display: "区分" },
    ];
    const sortOptionsPlan2 = [
      { value: "renewYears", display: "予定更新月" },
      { value: "planDate", display: "作成日" },
      { value: "plan2DouiDate", display: "同意日" },
      { value: "progressF", display: "最新" },
    ];
    const sortOptionsSisetuDailyPlan = [
      { value: "serviceKikan", display: "事業所" },
      { value: "nyuyokuYoubi", display: "入浴曜日" },
      { value: "progressF", display: "採用" },
    ];
    const sortOptionsMonitoring = [
      { value: "monitoringDate", display: "実施日" },
      { value: "tanto", display: "担当者" },
    ];
    const sortOptionTantoKaigi = [
      { value: "kaisaiDate", display: "開催日" },
      { value: "kaisaiTime", display: "時間" },
      { value: "tanto", display: "担当者" },
    ];
    const sortOptionKeikaKiroku = [
      { value: "kirokuDate", display: "記録日" },
      { value: "kirokuTime", display: "時間" },
      { value: "kirokuKubun", display: "記録区分" },
      { value: "planTanto", display: "計画作成者" },
      { value: "kirokuTanto", display: "記録作成者" },
    ];
    let sort = this.state.sort,
      sortOptions = sortOptionsAll;
    if (this.props.tabActive === "1") {
      sort = this.state.sort1;
      sortOptions = sortOptionsPlan1;
    }
    if (this.props.tabActive === "2") {
      sort = this.state.sort2;
      sortOptions = sortOptionsPlan2;
    }
    if (this.props.tabActive === "3") {
      sort = this.state.sort3;
      sortOptions = sortOptionsSisetuDailyPlan;
    }
    if (this.props.tabActive === "4") {
      sort = this.state.sort4;
      sortOptions = sortOptionsMonitoring;
    }
    if (this.props.tabActive === "5") {
      sort = this.state.sort5;
      sortOptions = sortOptionTantoKaigi;
    }
    if (this.props.tabActive === "6") {
      sort = this.state.sort6;
      sortOptions = sortOptionKeikaKiroku;
    }
    const currentMenu = localStorage.getItem("currentmenu") || "";
    return (
      <div>
        <span className={"toolbar-checkbox"}>
          {this.props.isUserMode ? null : (
            <Checkbox
              style={{ paddingLeft: "10px" }}
              checked={this.props.inUsed}
              onChange={(e) => this.props.onChangeInUsed(e.target.checked)}
            >
              {"利用中"}
            </Checkbox>
          )}
        </span>
        <span className={"toolbar-sort"}>
          {this.props.isUserMode ? (
            !currentMenu.includes("business1") ? (
              <Button
                type={"primary"}
                disabled={
                  (this.props.tabActive === "3" &&
                    this.props.metaPlan3.total > 0) ||
                  this.props.loading
                }
                onClick={() => this.props.showModalAddOrCopy()}
              >
                追加
              </Button>
            ) : (
              ""
            )
          ) : (
            <SearchRiyousyaControl
              style={{ display: "inline-flex" }}
              searchName={this.props.searchName}
              handleSearch={(furigana) =>
                this.props.onChangeSearchName(furigana)
              }
            />
          )}
          <SortControl
            style={{ display: "inline-block", marginLeft: "5px" }}
            count={4}
            sortOptions={sortOptions}
            sortData={sort}
            handleSortData={(sortData) => this.handleSortOk(sortData)}
          />
        </span>
      </div>
    );
  }
}

export default Toolbar1;
