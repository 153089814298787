import { checkTemplate } from "@pdfme/generator";
import {
  formatSeireki,
  formatBirthday,
} from "../../../function_common/functionCommon";
import _ from "lodash";
import dayjs from "dayjs";
import { RequestUtils } from "../../../../life/utils";
import { ApiPaths } from "../../../../life/constants";
import { StorageUtils } from "../../../../life/utils";
import { ConfigName } from "../../../../config-name";
import { ConstSet } from "../../../../life/constants";

export const life_JokusoPlan_JokusoPlanInputFormV3PDF = (data) => {
  const SWLIFEListRiyousya = StorageUtils.getValue(
    ConfigName.SWLIFE_LISTRIYOUSYA
  );
  const reNewYear = dayjs(new Date(SWLIFEListRiyousya.monthSelect));

  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];
  const generateOption = (options, field) => {
    if (options && options[field]) {
      return options[field]
        .split(";")
        .filter((item) => !!item)
        .map((item) => {
          let temp = item.includes(":") ? item.split(":")[0] : item;
          return { label: item, value: temp };
        });
    }
    return [];
  };
  const generateOption2 = (options, field, firstPosition, endPosition) => {
    if (options && options[field]) {
      let optionsArr = options[field].split(";").filter((item) => !!item);
      const startIndex = firstPosition || 0;
      const endIndex = endPosition || optionsArr.length;
      let resultOptions = [];
      for (let i = startIndex; i < endIndex; i++) {
        let temp = optionsArr[i].includes(":")
          ? optionsArr[i].split(":")[0]
          : optionsArr[i];
        resultOptions.push({ label: optionsArr[i] + "\n", value: temp });
      }
      return resultOptions;
    }
    return [];
  };

  const generateOption3 = (options, field) => {
    if (options && options[field]) {
      let optionsArr = options[field].split(";").filter((item) => !!item);
      let resultOptions = [];
      optionsArr.forEach((item) => {
        resultOptions.push({ label: item, value: item.split(":")[1] });
      });

      return resultOptions;
    }
    return [];
  };

  // options: { label: item, value: temp }
  const generateCheckBoxString = (options, optionSelected) => {
    let result = "";
    options?.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    return result;
  };

  const generateCheckBoxString2 = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (optionSelected?.indexOf(item.value) > -1) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    return result;
  };

  const generateCheckBoxArray = (options, optionSelected) => {
    const joinarray = options.map((item) => {
      if (item.value === optionSelected) return " ■" + item.label;
      else return " □" + item.label;
    });
    return joinarray.join("");
  };

  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 78;
    const MAX_LINE = 10;
    let stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    if (stringArr.length > 1) {
      stringArr.forEach((item) => {
        countOld = count;
        count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
        if (count < MAX_LINE) {
          result = result + item + "\n";
        } else {
          result =
            result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
          return;
        }
      });
    } else {
      stringArr.forEach((item) => {
        result =
          result + item.substring(0, (MAX_LINE - count) * LENGTH_LINE - 3);
        return;
      });
    }
    return result;
  };

  const convertKaigodo = (data, kaigodo) => {
    return kaigodo?.find((k) => k.kaigodoNo == data)?.kaigodoName ?? "";
  };

  const convertText = (data, options) => {
    return options?.find((v) => v.value == data)?.label ?? "";
  };

  const generateCheckBoxStringUmu = (options, optionSelected, num) => {
    let result = "";

    options?.forEach((item) => {
      if (num === optionSelected) {
        if (item.label?.split(":")[0] === optionSelected) {
          result += "■" + item.value;
        }
      } else {
        result = "";
      }
    });
    return result;
  };

  const { jokusoPlan, lifeYougoList, riyouKaigohoken, kaigodo } = data;

  if (typeof jokusoPlan === "object" && Array.isArray(jokusoPlan)) {
    let sampledata = [];
    jokusoPlan?.map((jokusoPlan) => {
      sampledata.push({
        記入者名: jokusoPlan.tantoName ?? "",
        氏名: jokusoPlan.riyousyaName ?? "",
        生年月日: formatBirthday(jokusoPlan.riyousyaBirthDay) ?? "",
        性別: `${generateCheckBoxString(
          GENDER_OPTIONS,
          jokusoPlan.riyousyaGender
        )}`,
        保険者番号: jokusoPlan?.riyouKaigoHoken?.hokensyaNo ?? "",
        被保険者番号: jokusoPlan?.riyouKaigoHoken?.hihokenNo ?? "",
        要介護度: convertKaigodo(jokusoPlan.careLevel, kaigodo),
        障害高年者の日常生活自立度: `${generateCheckBoxString(
          generateOption(lifeYougoList, "ziritsudo"),
          jokusoPlan.dailyDegree
        )}`,
        認知症高齢者の日常生活自立度: `${generateCheckBoxString(
          generateOption(lifeYougoList, "nintido"),
          jokusoPlan.dementiaDegree
        )}`,
        評価日: formatSeireki(jokusoPlan.hyoukaDate) ?? "",
        評価時点: convertText(
          jokusoPlan.status,
          generateOption(lifeYougoList, "hyoukaziten")
        ),
        褥瘡の有無1: generateCheckBoxStringUmu(
          generateOption3(lifeYougoList, "jokusoumu"),
          jokusoPlan.isBedsore,
          "0"
        ),
        褥瘡の有無2: generateCheckBoxStringUmu(
          generateOption3(lifeYougoList, "jokusoumu"),
          jokusoPlan.isBedsore,
          "1"
        ),
        褥瘡発生日: formatSeireki(jokusoPlan.bedsoreDateOfOnset) ?? "",
        部位: `${generateCheckBoxString2(
          generateOption3(lifeYougoList, "jokusobui"),
          jokusoPlan.bedsorePart
        )}(${jokusoPlan.bedsorePartFreeDescription ?? "    "})`,
        食事: `${generateCheckBoxString(
          generateOption(lifeYougoList, "shokuzisesshu"),
          jokusoPlan.adlDietaryIntake
        )}`,
        入浴: `${generateCheckBoxString(
          generateOption(lifeYougoList, "adlnyuyoku"),
          jokusoPlan.adlBathe
        )}`,
        更衣: `${generateCheckBoxString(
          generateOption(lifeYougoList, "koui"),
          jokusoPlan.adlDressingUpper
        )}`,
        寝返り: `${generateCheckBoxString(
          generateOption(lifeYougoList, "negaeri"),
          jokusoPlan.kihonTurningOver
        )}`,
        座位の保持: `${generateCheckBoxString(
          generateOption(lifeYougoList, "zai"),
          jokusoPlan.kihonSittingContinuous
        )}`,
        立ち上がり: `${generateCheckBoxString(
          generateOption(lifeYougoList, "zai"),
          jokusoPlan.kihonRising
        )}`,
        立位の保持: `${generateCheckBoxString(
          generateOption(lifeYougoList, "ritsui"),
          jokusoPlan.kihonKeepStanding
        )}`,
        浮腫: `${generateCheckBoxString(
          generateOption(lifeYougoList, "swelling"),
          jokusoPlan.swelling
        )}`,
        低栄養のリスクレベル: `${generateCheckBoxString(
          generateOption(lifeYougoList, "lowNutritionRiskLevel"),
          jokusoPlan.lowNutritionRiskLevel
        )}`,
        おむつ: `${generateCheckBoxString(
          generateOption(lifeYougoList, "haisetuDiapersUse"),
          jokusoPlan.haisetuDiapersUse
        )}`,
        ポータブルトイレ: `${generateCheckBoxString(
          generateOption(lifeYougoList, "haisetuPortableToilet"),
          jokusoPlan.haisetuPortableToilet
        )}`,
        尿道カテーテル: `${generateCheckBoxString(
          generateOption(lifeYougoList, "catheter"),
          jokusoPlan.haisetuBalloonCatheter
        )}`,
        深さ1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusohukasa", null, 3),
          jokusoPlan.depthEvaluation
        )}`,
        浸出液1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "sinshutueki", null, 3),
          jokusoPlan.leachateEvaluation
        )}`,
        大きさ1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusoookisa", null, 6),
          jokusoPlan.sizeEvaluation
        )}`,
        炎症感染1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "enshou", null, 2),
          jokusoPlan.infectionEvaluation
        )}`,
        肉芽組織1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "nikuga", null, 2),
          jokusoPlan.granulationEvaluation
        )}`,
        壊死組織1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "esi", null, 1),
          jokusoPlan.necroticTissueEvaluation
        )}`,
        ポケット1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusopocket", null, 1),
          jokusoPlan.pocketSizeEvaluation
        )}`,
        深さ2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusohukasa", 3, null),
          jokusoPlan.depthEvaluation
        )}`,
        浸出液2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "sinshutueki", 3, null),
          jokusoPlan.leachateEvaluation
        )}`,
        大きさ2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusoookisa", 6, null),
          jokusoPlan.sizeEvaluation
        )}`,
        炎症感染2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "enshou", 2, null),
          jokusoPlan.infectionEvaluation
        )}`,
        肉芽組織2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "nikuga", 2, null),
          jokusoPlan.granulationEvaluation
        )}`,
        壊死組織2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "esi", 1, null),
          jokusoPlan.necroticTissueEvaluation
        )}`,
        ポケット2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusopocket", 1, null),
          jokusoPlan.pocketSizeEvaluation
        )}`,
        //2ページ
        氏名2: jokusoPlan.riyousyaName ?? "",
        計画作成日: formatSeireki(jokusoPlan.makeDate) ?? "",
        体位変換の頻度: String(jokusoPlan.changePosition ?? "") ?? "",
        関連職種が共同して取り組むべき事項: `${
          formatString10Line(jokusoPlan.mattersRelatedJobs) ?? ""
        }`,
        評価を行う間隔: `${
          formatString10Line(jokusoPlan.evaluationInterval) ?? ""
        }`,
        ベット上: `${
          formatString10Line(jokusoPlan.removedPressureOnBed) ?? ""
        }`,
        イス上: `${
          formatString10Line(jokusoPlan.removedPressureOnChair) ?? ""
        }`,
        スキンケア: `${formatString10Line(jokusoPlan.skinCare) ?? ""}`,
        栄養状態改善: `${
          formatString10Line(jokusoPlan.improvedNutrition) ?? ""
        }`,
        リハビリテーション: `${
          formatString10Line(jokusoPlan.rehabilitation) ?? ""
        }`,
        その他: `${formatString10Line(jokusoPlan.bedsoreOther) ?? ""}`,
      });
    });

    return sampledata;
  } else if (typeof jokusoPlan === "object") {
    let sampledata = [
      {
        記入者名: jokusoPlan.tantoName ?? "",
        氏名: jokusoPlan.riyousyaName ?? "",
        生年月日: formatBirthday(jokusoPlan.riyousyaBirthDay) ?? "",
        性別: `${generateCheckBoxString(
          GENDER_OPTIONS,
          jokusoPlan.riyousyaGender
        )}`,
        保険者番号: riyouKaigohoken?.hokensyaNo ?? "",
        被保険者番号: riyouKaigohoken?.hihokenNo ?? "",
        要介護度: convertKaigodo(jokusoPlan.careLevel, kaigodo),
        障害高年者の日常生活自立度: `${generateCheckBoxString(
          generateOption(lifeYougoList, "ziritsudo"),
          jokusoPlan.dailyDegree
        )}`,
        認知症高齢者の日常生活自立度: `${generateCheckBoxString(
          generateOption(lifeYougoList, "nintido"),
          jokusoPlan.dementiaDegree
        )}`,
        評価日: formatSeireki(jokusoPlan.hyoukaDate) ?? "",
        評価時点: convertText(
          jokusoPlan.status,
          generateOption(lifeYougoList, "hyoukaziten")
        ),
        褥瘡の有無1: generateCheckBoxStringUmu(
          generateOption3(lifeYougoList, "jokusoumu"),
          jokusoPlan.isBedsore,
          "0"
        ),
        褥瘡の有無2: generateCheckBoxStringUmu(
          generateOption3(lifeYougoList, "jokusoumu"),
          jokusoPlan.isBedsore,
          "1"
        ),
        褥瘡発生日: formatSeireki(jokusoPlan.bedsoreDateOfOnset) ?? "",
        部位: `${generateCheckBoxString2(
          generateOption3(lifeYougoList, "jokusobui"),
          jokusoPlan.bedsorePart
        )}(${jokusoPlan.bedsorePartFreeDescription ?? "    "})`,
        食事: `${generateCheckBoxString(
          generateOption(lifeYougoList, "shokuzisesshu"),
          jokusoPlan.adlDietaryIntake
        )}`,
        入浴: `${generateCheckBoxString(
          generateOption(lifeYougoList, "adlnyuyoku"),
          jokusoPlan.adlBathe
        )}`,
        更衣: `${generateCheckBoxString(
          generateOption(lifeYougoList, "koui"),
          jokusoPlan.adlDressingUpper
        )}`,
        寝返り: `${generateCheckBoxString(
          generateOption(lifeYougoList, "negaeri"),
          jokusoPlan.kihonTurningOver
        )}`,
        座位の保持: `${generateCheckBoxString(
          generateOption(lifeYougoList, "zai"),
          jokusoPlan.kihonSittingContinuous
        )}`,
        立ち上がり: `${generateCheckBoxString(
          generateOption(lifeYougoList, "zai"),
          jokusoPlan.kihonRising
        )}`,
        立位の保持: `${generateCheckBoxString(
          generateOption(lifeYougoList, "ritsui"),
          jokusoPlan.kihonKeepStanding
        )}`,
        浮腫: `${generateCheckBoxString(
          generateOption(lifeYougoList, "swelling"),
          jokusoPlan.swelling
        )}`,
        低栄養のリスクレベル: `${generateCheckBoxString(
          generateOption(lifeYougoList, "lowNutritionRiskLevel"),
          jokusoPlan.lowNutritionRiskLevel
        )}`,
        おむつ: `${generateCheckBoxString(
          generateOption(lifeYougoList, "haisetuDiapersUse"),
          jokusoPlan.haisetuDiapersUse
        )}`,
        ポータブルトイレ: `${generateCheckBoxString(
          generateOption(lifeYougoList, "haisetuPortableToilet"),
          jokusoPlan.haisetuPortableToilet
        )}`,
        尿道カテーテル: `${generateCheckBoxString(
          generateOption(lifeYougoList, "catheter"),
          jokusoPlan.haisetuBalloonCatheter
        )}`,
        深さ1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusohukasa", null, 3),
          jokusoPlan.depthEvaluation
        )}`,
        浸出液1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "sinshutueki", null, 3),
          jokusoPlan.leachateEvaluation
        )}`,
        大きさ1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusoookisa", null, 6),
          jokusoPlan.sizeEvaluation
        )}`,
        炎症感染1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "enshou", null, 2),
          jokusoPlan.infectionEvaluation
        )}`,
        肉芽組織1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "nikuga", null, 2),
          jokusoPlan.granulationEvaluation
        )}`,
        壊死組織1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "esi", null, 1),
          jokusoPlan.necroticTissueEvaluation
        )}`,
        ポケット1: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusopocket", null, 1),
          jokusoPlan.pocketSizeEvaluation
        )}`,
        深さ2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusohukasa", 3, null),
          jokusoPlan.depthEvaluation
        )}`,
        浸出液2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "sinshutueki", 3, null),
          jokusoPlan.leachateEvaluation
        )}`,
        大きさ2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusoookisa", 6, null),
          jokusoPlan.sizeEvaluation
        )}`,
        炎症感染2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "enshou", 2, null),
          jokusoPlan.infectionEvaluation
        )}`,
        肉芽組織2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "nikuga", 2, null),
          jokusoPlan.granulationEvaluation
        )}`,
        壊死組織2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "esi", 1, null),
          jokusoPlan.necroticTissueEvaluation
        )}`,
        ポケット2: `${generateCheckBoxArray(
          generateOption2(lifeYougoList, "jokusopocket", 1, null),
          jokusoPlan.pocketSizeEvaluation
        )}`,
        //2ページ
        氏名2: jokusoPlan.riyousyaName ?? "",
        計画作成日: formatSeireki(jokusoPlan.makeDate) ?? "",
        体位変換の頻度: String(jokusoPlan.changePosition ?? "") ?? "",
        関連職種が共同して取り組むべき事項: `${
          formatString10Line(jokusoPlan.mattersRelatedJobs) ?? ""
        }`,
        評価を行う間隔: `${
          formatString10Line(jokusoPlan.evaluationInterval) ?? ""
        }`,
        ベット上: `${
          formatString10Line(jokusoPlan.removedPressureOnBed) ?? ""
        }`,
        イス上: `${
          formatString10Line(jokusoPlan.removedPressureOnChair) ?? ""
        }`,
        スキンケア: `${formatString10Line(jokusoPlan.skinCare) ?? ""}`,
        栄養状態改善: `${
          formatString10Line(jokusoPlan.improvedNutrition) ?? ""
        }`,
        リハビリテーション: `${
          formatString10Line(jokusoPlan.rehabilitation) ?? ""
        }`,
        その他: `${formatString10Line(jokusoPlan.bedsoreOther) ?? ""}`,
      },
    ];

    return sampledata;
  } else {
    return [{}];
  }
};
