import { Button, Col, Divider, Input, Modal, Row, Select, Spin, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  getRoomList,
} from "../../../actions/roomMasterActions/actionCreators";
import {
  yougoMasterRoomNaiyouCreator,
  yougoMasterUpdateNewCreatorRoom,
} from "../../../actions/yougoMasterNaiyouActions/actionCreators";
import {
  formatStorage,
  getMenu,
} from "../../../common/function_common/functionCommon";
import { ServiceKikanMasterActions } from "../../../management/redux/actions/serviceKikanMaster";
import RoomMasterEditModal from "./RoomMasterEditModal";
const { Search } = Input;
const { Option } = Select;

class RoomMasterModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      searchText: "",
      selectedBunrui: null,
      search: false,
      visibleEdit: false,
      editType: null,
      sort: false,
      // sortTable: [],
      // sortTableCopy: [],
    };
  }

  componentDidMount() {
    this.props.yougoMasterRoomNaiyouCreator("部屋分類");
    this.props.getRoomList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      const mainSID = formatStorage(getMenu()).si;
      this.props.getById(mainSID);
    }
  }

  handleOk() {
    this.props.onChange(this.state.selected.room);

    this.setState({
      searchText: "",
      selectedBunrui: null,
      selected: null,
      search: null,
      // sort: false,
      // sortTable: [],
      // sortTableCopy: [],
    });
  }
  handleCancel() {
    this.props.onCancel();

    this.setState({
      searchText: "",
      selectedBunrui: null,
      selected: null,
      search: null,
      // sort: false,
      // sortTable: [],
      // sortTableCopy: [],
    });
  }

  handleSelectRow(record) {
    this.setState({
      selected: record,
    });
  }

  search() {
    this.props.getRoomList();
  }

  handleChangeBunrui(value) {
    this.setState({
      selectedBunrui: value,
      search: false,
    });
  }

  handleFilterRoom(value) {
    this.setState({
      searchText: value,
      search: true,
    });
  }

  isDisableButton(selected) {
    return selected && this.props.rooms
      ? !this.props.rooms.some((item) => item.id == selected.id)
      : true;
  }

  handleAdd() {
    this.setState({
      visibleEdit: true,
      editType: 1, // add
    });
  }

  handleEdit() {
    this.setState({
      visibleEdit: true,
      editType: 2, // edit
    });
  }
  handleEditOk() {
    this.setState({
      selected: null,
      visibleEdit: false,
      editType: null,
    });
  }
  handleEditClose() {
    this.search();

    this.setState({
      selected: null,
      visibleEdit: false,
      editType: null,
    });
  }

  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  // sortOn = (dataSource) => {
  //   let tmp = JSON.parse(JSON.stringify(dataSource));
  //   let tmp2 = JSON.parse(JSON.stringify(dataSource));
  //   this.setState({
  //     sort: true,
  //     sortTable: tmp,
  //     sortTableCopy: tmp2,
  //   })
  // }
  // sortOff = () => {
  //   this.search();

  //   this.setState({
  //     sort: false,
  //     sortTable: [],
  //     sortTableCopy: [],
  //   })
  // }
  // sortSave = () => {
  //   const { sortTable, sortTableCopy, search } = this.state

  //   let tmp = [];
  //   for(let record of sortTable){
  //     let defTable = sortTableCopy.find(t => t.id == record.id)
  //     if(record.sortNum != defTable.sortNum){
  //       tmp.push(record);
  //     }
  //   }

  //   this.props.roomListSave(tmp);

  //   this.setState({
  //     sort: false,
  //     sortTable: [],
  //     sortTableCopy: [],
  //   })
  // }

  // sortTop = (record) => {
  //   const { sortTable } = this.state
  //   let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
  //   const element = JSON.parse(JSON.stringify(record));
  //   const elementIndex = sortTable.findIndex((v) => v.id === element.id)
  //   const minNum = sortTable[0].sortNum

  //   if(elementIndex != 0) {
  //     sortTableTmp.splice(elementIndex, 1);
  //     element.sortNum = minNum - 1;
  //     sortTableTmp.splice(0, 0, element);

  //     this.setState({
  //       sortTable: sortTableTmp
  //     })
  //   }
  // }

  // sortUp = (record) => {
  //   const { sortTable } = this.state
  //   let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
  //   const element = JSON.parse(JSON.stringify(record));
  //   const elementIndex = sortTable.findIndex((v) => v.id === element.id)

  //   if(elementIndex != 0) {
  //     const changeElement = sortTableTmp[elementIndex - 1]
  //     const changeElementSortNum = changeElement.sortNum
  //     const changeElementIndex = elementIndex - 1

  //     sortTableTmp.splice(elementIndex, 1);
  //     if(element.sortNum == changeElementSortNum) {
  //       element.sortNum --;
  //       sortTableTmp.splice(changeElementIndex, 0, element);
  //     } else {
  //       element.sortNum = changeElementSortNum;
  //       sortTableTmp.splice(changeElementIndex, 0, element);
  //       sortTableTmp[elementIndex].sortNum ++;
  //     }

  //     this.setState({
  //       sortTable: sortTableTmp
  //     })
  //   }
  // }

  // sortDown = (record) => {
  //   const { sortTable } = this.state
  //     let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
  //     const element = JSON.parse(JSON.stringify(record));
  //     const elementIndex = sortTable.findIndex((v) => v.id === record.id)
  //     const bottomIndex = sortTable.length - 1

  //     if(elementIndex != bottomIndex) {
  //       const changeElement = sortTableTmp[elementIndex + 1]
  //       const changeElementSortNum = changeElement.sortNum
  //       const changeElementIndex = elementIndex + 1

  //       sortTableTmp.splice(elementIndex, 1);
  //       if(element.sortNum == changeElementSortNum) {
  //         element.sortNum ++;
  //         sortTableTmp.splice(changeElementIndex, 0, element);
  //       } else {
  //         element.sortNum = changeElementSortNum;
  //         sortTableTmp.splice(changeElementIndex, 0, element);
  //         sortTableTmp[elementIndex].sortNum --;
  //       }

  //       this.setState({
  //         sortTable: sortTableTmp
  //       })
  //     }
  // }

  // sortBottom = (record) => {
  //   const { sortTable } = this.state
  //   let sortTableTmp = JSON.parse(JSON.stringify(sortTable));
  //   let maxNum = 0;
  //   for(let i of sortTableTmp) {
  //     maxNum < i.sortNum ? maxNum = i.sortNum : maxNum
  //   }
  //   const element = JSON.parse(JSON.stringify(record));
  //   const elementIndex = sortTable.findIndex((v) => v.id === record.id)
  //   const bottomIndex = sortTable.length - 1

  //   if(elementIndex != bottomIndex) {
  //     sortTableTmp.splice(elementIndex, 1);
  //     element.sortNum = maxNum + 1;
  //     sortTableTmp.splice(bottomIndex, 0, element);

  //     this.setState({
  //       sortTable: sortTableTmp
  //     })
  //   }
  // }

  render() {
    const { rooms, yougoMasterRoomNaiyou } = this.props;
    const { selectedBunrui, searchText, search, selected, sort } = this.state;

    let dataSource = rooms;
    if (selectedBunrui && search) {
      dataSource = rooms.filter((room) => room.bunrui === selectedBunrui);
    }
    if (searchText && searchText.length > 0) {
      dataSource = dataSource.filter((room) => {
        return (
          (room.room && room.room.indexOf(searchText) >= 0) ||
          (room.bunrui && room.bunrui.indexOf(searchText) >= 0) ||
          (room.kyosituShubetu && room.kyosituShubetu.indexOf(searchText) >= 0)
        );
      });
    }

    const columns = [
      { title: "部屋名", dataIndex: "room", key: "room", width: "30%" },
      {
        title: "居室種別",
        dataIndex: "kyosituShubetu",
        key: "kyosituShubetu",
        width: "30%",
      },
      { title: "分類", dataIndex: "bunrui", key: "bunrui", width: "30%" },
    ];
    // const columns2 =
    // [
    //   {
    //     title: "部屋名",
    //     dataIndex: "room",
    //     key: "room",
    //     width: "30%"
    //   },
    //   {
    //     title: "居室種別",
    //     dataIndex: "kyosituShubetu",
    //     key: "kyosituShubetu",
    //     width: "30%"
    //   },
    //   // { //テスト
    //   //     title: '順番',
    //   //     dataIndex: 'sortNum',
    //   //     width: "25%",
    //   //     key: "sortNum",
    //   // },
    //   {
    //     title: '並び替え',
    //     dataIndex: 'operation',
    //     width: "30%",
    //     key: "operation",
    //     render: (record, index) => {
    //       return (
    //         <div>
    //           <span>
    //             <a onClick={() => this.sortTop(index)} style={{}}><Icon type="step-backward" rotate={90} style={{ fontSize: '16px'}}/></a>
    //             <Divider type="vertical" />
    //             <a onClick={() => this.sortUp(index)} style={{}}><Icon type="caret-up" style={{ fontSize: '16px' }}/></a>
    //             <Divider type="vertical" />
    //             <a onClick={() => this.sortDown(index)} style={{}}><Icon type="caret-down" style={{ fontSize: '16px' }}/></a>
    //             <Divider type="vertical" />
    //             <a onClick={() => this.sortBottom(index)} style={{}}><Icon type="step-forward" rotate={90} style={{ fontSize: '16px'}}/></a>
    //           </span>
    //         </div>
    //       )
    //     }
    //   }
    // ]

    const footer = (
      <div>
        {/* {
          sort == false ?
            <span>
              <a
                key="sort"
                style={{float: "left", color: "black", marginTop: 5, marginLeft: 5}}
                onClick={() => this.sortOn(dataSource)}
              >
                並び替え
              </a>
            </span>
            :
            sort == true ?
              <span>
                <Button key="sortSave" type={"primary"} style={{float: "left", marginRight: 10}} onClick={this.sortSave}>
                  並び替え保存
                </Button>
                <Button key="back" style={{float: "left" }} onClick={() => this.sortOff()}>
                  戻る
                </Button>
              </span>
              :
              null
        } */}
        <Button
          key="submit"
          style={{}}
          type="primary"
          onClick={() => this.handleOk()}
          disabled={sort == true ? true : !selected}
        >
          OK
        </Button>
        <Button
          key="back"
          style={{ marginRight: 10 }}
          onClick={() => this.handleCancel()}
        >
          キャンセル
        </Button>
      </div>
    );

    // {this.props.children} // ?

    return (
      (<div>
        <Modal
          open={this.props.visible}
          className="modal-list modal-right"
          title={"部屋選択ダイアログ"}
          style={{ right: 20 }}
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
          width={500}
          closable={false}
          footer={footer}
        >
          <Row className="pad-bt-8" style={{ marginBottom: 5, marginTop: -5 }}>
            <Col span={8}>
              <Select
                defaultValue={this.state.selectedBunrui}
                style={{ width: "100%" }}
                onChange={(value) => this.handleChangeBunrui(value)}
              >
                <Option value={null} key={-1}>
                  全表示
                </Option>
                {Array.isArray(yougoMasterRoomNaiyou)
                  ? yougoMasterRoomNaiyou.map((item) => {
                    return (
                      <Option key={item.key} value={item.naiyou}>
                        {item.naiyou}
                      </Option>
                    );
                  })
                  : []}
              </Select>
            </Col>
            <Col span={16}>
              <Search
                enterButton={"検索"}
                onSearch={(value) => this.handleFilterRoom(value)}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: -1, marginRight: -20 }} />
          <Row style={{ display: "flex" }}>
            <Button
              key="add"
              type={"primary"}
              onClick={() => this.handleAdd()}
              disabled={sort == true}
              style={{ marginRight: 8, marginTop: -15 }}
            >
              追加
            </Button>
            <Button
              key="edit"
              type={"primary"}
              onClick={() => this.handleEdit()}
              disabled={sort == true ? true : this.isDisableButton(selected)}
              style={{ marginRight: 8, marginTop: -15 }}
            >
              修正
            </Button>
          </Row>
          <Row style={{ marginTop: -5 }}>
            <Spin spinning={this.props.loading}>
              {/* {
                sort === false ? */}
              <Table
                bordered={true}
                className="modal-table"
                // showHeader={false}
                size={"small"}
                columns={columns}
                pagination={{ pageSize: 20 }}
                scroll={{ y: 300 }}
                rowKey={"id"}
                dataSource={dataSource}
                onRow={(record) => {
                  return {
                    onClick: () => this.handleSelectRow(record),
                  };
                }}
                rowClassName={(record) =>
                  this.state.selected != null &&
                    record.id === this.state.selected.id
                    ? "pljp-table-row-hover"
                    : "pointer-row"
                }
                style={{ marginTop: 15, marginBottom: -20, paddingBottom: -10 }}
              />
              {/* : sort === true ?
                    <Table
                      bordered={true}
                      className="modal-table"
                      // showHeader={false}
                      size={"small"}
                      columns={columns2}
                      pagination={{ pageSize: 20 }}
                      scroll={{ y: 300 }}
                      rowKey={"id"}
                      dataSource={sortTable}
                      rowClassName={(record) => this.state.selected != null && record.id === this.state.selected.id ? "pljp-table-row-hover" : "pointer-row"}
                      style={{ marginTop: 20 }}
                    />
                    :
                    null
              } */}
            </Spin>
          </Row>
        </Modal>
        <RoomMasterEditModal
          visible={this.state.visibleEdit}
          data={this.state.selected}
          serviceKikan={this.props.serviceKikan}
          editType={this.state.editType}
          handleSelectRow={(record) => this.handleSelectRow(record)}
          handleClose={() => this.handleEditClose()}
          handleOk={() => this.handleEditOk()}
          roomBunrui={this.props.yougoMasterRoomNaiyou}
          yougoMasterUpdate={(naiyou) =>
            this.yougoMasterUpdate("部屋分類", naiyou)
          }
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rooms: state.roomMaster.rooms, // 部屋レコード
    yougoMasterRoomNaiyou: state.yougoMasterNaiyou.yougoMasterRoomNaiyou, // 分類
    loading: state.roomMaster.loading,
    serviceKikan: state.serviceKikanMaster.serviceKikanMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoomList: () => dispatch(getRoomList()),
    yougoMasterRoomNaiyouCreator: (listName) =>
      dispatch(yougoMasterRoomNaiyouCreator(listName)),
    // roomListSave: (data) => dispatch(roomListSave(data)),
    getById: (id) => dispatch(ServiceKikanMasterActions.getById(id)),
    yougoMasterUpdate: (key, naiyou) =>
      dispatch(yougoMasterUpdateNewCreatorRoom(key, naiyou)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomMasterModal);
