export const AuthActionTypes = {
  LIFE_SIGNIN_REQUEST: "LIFE_SIGNIN_REQUEST",
  LIFE_SIGNIN_SUCCESS: "LIFE_SIGNIN_SUCCESS",
  LIFE_SIGNIN_FAILURE: "LIFE_SIGNIN_FAILURE",
  SIGNOUT: "SIGNOUT",
  LIFE_MAINTAINANCE_MODE_REQUEST: "LIFE_MAINTAINANCE_MODE_REQUEST",
  LIFE_MAINTAINANCE_MODE_SUCCESS: "LIFE_MAINTAINANCE_MODE_SUCCESS",
  LIFE_MAINTAINANCE_MODE_FAILURE: "LIFE_MAINTAINANCE_MODE_FAILURE",
  LIFE_GET_MENU: "LIFE_GET_MENU",
  LIFE_HANDLE_MENU: "LIFE_HANDLE_MENU",
};
