import React from "react";
import { CaretDownOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Col, Row, Table, Collapse, Typography, Button } from "antd";
import MediaQuery from "react-responsive";
import _ from "lodash";
import LineBreakText from "../../../common/component/LineBreakText";

class ViewPlan2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tbDisType: false,
    };
    this.displayTableType = this.displayTableType.bind(this);
  }

  displayTableType() {
    this.setState({
      tbDisType: !this.state.tbDisType,
    });
  }

  render() {
    const { detail } = this.props;
    const { Panel } = Collapse;
    const { Paragraph } = Typography;
    const columnsTable1 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "20%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "18%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "17%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title3",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content3",
        width: "15%",
      },
    ];
    const dataTableTop = [
      {
        title: "計画作成日",
        content: detail && detail.planDate,
        title2: "計画予定更新月",
        content2: detail && detail.renewYears,
        title3: "同意年月日",
        content3: detail && detail.plan2DouiDate,
      },
    ];
    const data = [
      {
        title: "コメント",
        content: detail && detail.comment,
      },
    ];
    const columnsTableBottom = [
      {
        className: "title-view1",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "content",
        width: "85%",
        className: "content-tb1",
        render: (text) => <LineBreakText text={text} />,
      },
    ];

    const columnsTableMiddle = [
      {
        className: "title-view1",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "content",
        width: "85%",
        className: "content-tb1",
        render: (text) => <LineBreakText text={text} />,
      },
    ];

    const planMoblie =
      detail &&
      detail.seikatuMokuhyous
        .map((item) => (
          <Panel
            style={{ padding: "20px 10px 10px 10px", marginBottom: "unset" }}
            header={
              <div>
                <span>課題 {item.sortNum}</span>
                <span style={{ float: "right" }}>
                  <CaretDownOutlined />
                </span>
              </div>
            }
            showArrow={false}
            key={item.sortNum}
          >
            <Row
              className={
                "mobile-table " + this.state.tbDisType
                  ? "tb-dis-type-normal"
                  : "tb-dis-type-wrap"
              }
            >
              <Col span={24}>
                <Paragraph>
                  {"生活全般の解決すべき課題:"}{" "}
                  <div className={this.state.tbDisType ? null : "item-expand"}>
                    <span
                      style={
                        this.state.tbDisType ? { whiteSpace: "pre-line" } : null
                      }
                    >
                      {item.seikatuKoi}
                    </span>
                  </div>
                </Paragraph>
                <Paragraph>
                  {"長期目標:"}{" "}
                  <div className={this.state.tbDisType ? null : "item-expand"}>
                    <LineBreakText
                      text={item.longMokuhyou}
                      style={
                        !this.state.tbDisType ? { whiteSpace: "unset" } : null
                      }
                    />
                    <span>
                      {" "}
                      {item.longMokuhyou
                        ? item.longKikan && item.longKikan.trim()
                          ? "(" + item.longKikan + ")"
                          : null
                        : item.longKikan}
                    </span>
                  </div>
                </Paragraph>
                <Paragraph>
                  {"短期目標:"}{" "}
                  <div className={this.state.tbDisType ? null : "item-expand"}>
                    <LineBreakText
                      text={item.shortMokuhyou}
                      style={
                        !this.state.tbDisType ? { whiteSpace: "unset" } : null
                      }
                    />
                    <span>
                      {" "}
                      {item.shortMokuhyou
                        ? item.shortKikan && item.shortKikan.trim()
                          ? "(" + item.shortKikan + ")"
                          : null
                        : item.shortKikan}
                    </span>
                  </div>
                </Paragraph>
              </Col>
              {item.details
                .map((itemDetail) => (
                  <li className={"item-detail"} key={itemDetail.sortNum}>
                    <Col xs={2} sm={2} style={{ borderTop: "1px solid" }}>
                      {itemDetail.sortNum}
                    </Col>
                    <Col
                      xs={22}
                      sm={22}
                      style={{
                        borderTop: "1px solid",
                        borderLeft: "1px solid",
                      }}
                    >
                      <Paragraph>
                        {"サービス内容:"}{" "}
                        <div
                          className={
                            this.state.tbDisType ? "item-normal" : "item-expand"
                          }
                        >
                          {itemDetail.sienNaiyou}
                        </div>
                      </Paragraph>
                      <Paragraph>
                        {"担当者:"}{" "}
                        <div
                          className={
                            this.state.tbDisType ? "item-normal" : "item-expand"
                          }
                        >
                          {itemDetail.jigyousyo}
                        </div>
                      </Paragraph>
                      <Paragraph>
                        {"頻度:"}{" "}
                        <div
                          className={
                            this.state.tbDisType ? "item-normal" : "item-expand"
                          }
                        >
                          {itemDetail.hindo}
                        </div>
                      </Paragraph>
                      <Paragraph>
                        {"期間:"}{" "}
                        <div
                          className={
                            this.state.tbDisType ? "item-normal" : "item-expand"
                          }
                        >
                          {itemDetail.kikan}
                        </div>
                      </Paragraph>
                    </Col>
                  </li>
                ))
                .sort((a, b) => a.key - b.key)}
            </Row>
          </Panel>
        ))
        .sort((a, b) => a.key - b.key);

    const getLongMokuhyou = (item) => {
      let text = item.longMokuhyou || "";
      if (item.longKikan && item.longKikan.trim().length > 0) {
        text += text ? "\n" : "";
        if (item.longMokuhyou) {
          text += `(${item.longKikan})`;
        } else {
          text += `${item.longKikan}`;
        }
      }
      return text;
    };

    const getShortMokuhyou = (item) => {
      let text = item.shortMokuhyou || "";
      if (item.shortKikan && item.shortKikan.trim().length > 0) {
        text += text ? "\n" : "";
        if (item.shortMokuhyou) {
          text += ` (${item.shortKikan})`;
        } else {
          text += `${item.shortKikan}`;
        }
      }
      return text;
    };

    const planDesktop =
      detail &&
      detail.seikatuMokuhyous
        .map((item) => {
          const detailRows = _.isEmpty(item.details) ? (
            <tr
              className="pljp-table-row pljp-table-row-level-0"
              key={item.id}
              data-row-key={item.id}
            >
              <td>
                <p
                  style={{
                    wordBreak: "break-all",
                    color: "rgba(0, 0, 0, 0.65)",
                    fontWeight: "100",
                  }}
                >
                  {item.sortNum}
                </p>
              </td>
              <td>
                <LineBreakText text={item.seikatuKoi} />
              </td>
              <td>
                <LineBreakText
                  text={item.longMokuhyou}
                  style={{
                    textAlign: "left",
                    color: "rgba(0, 0, 0, 0.65)",
                    fontWeight: "100",
                  }}
                />
              </td>
              <td>
                <LineBreakText text={item.shortMokuhyou} />
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ) : (
            item.details
              .sort((a, b) => a.sortNum - b.sortNum)
              .map((d, index) => {
                if (index === 0)
                  return (
                    <tr
                      className="pljp-table-row"
                      key={item.id + "-" + d.id}
                      data-row-key={item.id + "-" + d.id}
                    >
                      <td rowSpan={item.details.length}>{item.sortNum}</td>
                      <td rowSpan={item.details.length}>
                        <LineBreakText text={item.seikatuKoi} />
                      </td>
                      <td rowSpan={item.details.length}>
                        <LineBreakText text={getLongMokuhyou(item)} />
                      </td>
                      <td rowSpan={item.details.length}>
                        <LineBreakText text={getShortMokuhyou(item)} />
                      </td>
                      <td>
                        <LineBreakText text={d.sienNaiyou} />
                      </td>
                      <td>
                        <LineBreakText text={d.jigyousyo} />
                      </td>
                      <td>
                        <LineBreakText text={d.hindo} />
                      </td>
                      <td>
                        <LineBreakText text={d.kikan} />
                      </td>
                    </tr>
                  );
                return (
                  <tr
                    className="pljp-table-row"
                    key={item.id + "-" + d.id}
                    data-row-key={item.id + "-" + d.id}
                  >
                    <td>
                      <LineBreakText text={d.sienNaiyou} />
                    </td>
                    <td>
                      <LineBreakText text={d.jigyousyo} />
                    </td>
                    <td>
                      <LineBreakText text={d.hindo} />
                    </td>
                    <td>
                      <LineBreakText text={d.kikan} />
                    </td>
                  </tr>
                );
              })
          );
          return (
            <tbody className="pljp-table-tbody" key={item.sortNum}>
              {detailRows}
            </tbody>
          );
        })
        .sort((a, b) => a.key - b.key);

    return (
      <div className={"data-plan2"}>
        <React.Fragment>
          {/* <Table
            className={"view-plan-table1"}
            pagination={false}
            showHeader={false}
            columns={columnsTable1}
            dataSource={dataTableTop}
          /> */}

          <MediaQuery query="(min-device-width: 768px)">
            {(matches) =>
              matches ? (
                <div>
                  <div
                    style={{
                      float: "right",
                      fontWeight: "bold",
                      margin: "5px 0px",
                    }}
                  >
                    {this.props.detail && this.props.detail.progressF === 1
                      ? "最新"
                      : "　"}
                  </div>
                  <Table
                    className={"view-plan-table3"}
                    pagination={false}
                    showHeader={false}
                    columns={columnsTable1}
                    dataSource={dataTableTop}
                  />
                  <Row style={{ marginTop: "20px" }} id={"plan-table"}>
                    <div className="pljp-table pljp-table-small pljp-table-scroll-position-left">
                      <div className="pljp-table-content">
                        <div className="pljp-table-body">
                          <table>
                            <colgroup>
                              <col style={{ width: "1%" }} />
                              <col style={{ width: "20%" }} />
                              <col style={{ width: "10%" }} />
                              <col style={{ width: "10%" }} />
                              <col style={{ width: "20%" }} />
                              <col style={{ width: "10%" }} />
                              <col style={{ width: "5%" }} />
                              <col style={{ width: "5%" }} />
                            </colgroup>
                            <thead className="pljp-table-thead">
                              <tr>
                                <th>
                                  <span className="pljp-table-header-column">
                                    <div>
                                      <span className="pljp-table-column-title">
                                        No
                                      </span>
                                      <span className="pljp-table-column-sorter"></span>
                                    </div>
                                  </span>
                                </th>
                                <th>
                                  <span className="pljp-table-header-column">
                                    <div>
                                      <span className="pljp-table-column-title">
                                        生活全般の解決すべき課題
                                      </span>
                                      <span className="pljp-table-column-sorter"></span>
                                    </div>
                                  </span>
                                </th>
                                <th>
                                  <span className="pljp-table-header-column">
                                    <div>
                                      <span className="pljp-table-column-title">
                                        長期目標
                                      </span>
                                      <span className="pljp-table-column-sorter"></span>
                                    </div>
                                  </span>
                                </th>
                                <th>
                                  <span className="pljp-table-header-column">
                                    <div>
                                      <span className="pljp-table-column-title">
                                        短期目標
                                      </span>
                                      <span className="pljp-table-column-sorter"></span>
                                    </div>
                                  </span>
                                </th>
                                <th>
                                  <span className="pljp-table-header-column">
                                    <div>
                                      <span className="pljp-table-column-title">
                                        サービス内容
                                      </span>
                                      <span className="pljp-table-column-sorter"></span>
                                    </div>
                                  </span>
                                </th>
                                <th>
                                  <span className="pljp-table-header-column">
                                    <div>
                                      <span className="pljp-table-column-title">
                                        担当者
                                      </span>
                                      <span className="pljp-table-column-sorter"></span>
                                    </div>
                                  </span>
                                </th>
                                <th>
                                  <span className="pljp-table-header-column">
                                    <div>
                                      <span className="pljp-table-column-title">
                                        頻度
                                      </span>
                                      <span className="pljp-table-column-sorter"></span>
                                    </div>
                                  </span>
                                </th>
                                <th>
                                  <span className="pljp-table-header-column">
                                    <div>
                                      <span className="pljp-table-column-title">
                                        期間
                                      </span>
                                      <span className="pljp-table-column-sorter"></span>
                                    </div>
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            {planDesktop}
                          </table>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Table
                    className={"plan2-tb-bottom"}
                    pagination={false}
                    showHeader={false}
                    columns={columnsTableBottom}
                    dataSource={data}
                  />
                </div>
              ) : (
                <div>
                  <Row>
                    <Col span={7}>
                      <span style={{ fontWeight: "bold" }}>計画作成日</span>
                    </Col>
                    <Col
                      span={6}
                      offset={1}
                      style={{ borderBottom: "1px solid" }}
                    >
                      <span>
                        {detail && detail.planDate ? detail.planDate : "　"}
                      </span>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        borderBottom: "1px solid",
                      }}
                    >
                      <span>
                        {this.props.detail && this.props.detail.progressF === 1
                          ? "最新"
                          : "　"}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ margin: "15px 0px" }}>
                    <Col span={7}>
                      <span style={{ fontWeight: "bold" }}>計画予定更新月</span>
                    </Col>
                    <Col
                      span={6}
                      offset={1}
                      style={{ borderBottom: "1px solid" }}
                    >
                      <span>
                        {detail && detail.renewYears ? detail.renewYears : "　"}
                      </span>
                    </Col>
                  </Row>
                  <Row className={"plan-mobile"}>
                    <Button
                      icon={<LegacyIcon type={this.state.tbDisType ? "minus" : "plus"} />}
                      onClick={this.displayTableType}
                      style={{
                        color: "#fff",
                        background: "#00838F",
                        marginBottom: "5px",
                      }}
                    >
                      {this.state.tbDisType ? "格納" : "展開"}
                    </Button>
                    <Collapse defaultActiveKey={["1"]}>{planMoblie}</Collapse>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <span style={{ fontWeight: "bold" }}>同意年月日</span>
                    </Col>
                    <Col
                      span={6}
                      offset={1}
                      style={{ borderBottom: "1px solid" }}
                    >
                      <span>
                        {detail && detail.plan2DouiDate
                          ? detail.plan2DouiDate
                          : "　"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <span style={{ fontWeight: "bold" }}>コメント</span>
                  </Row>
                  <Row style={{ borderBottom: "1px solid" }}>
                    <LineBreakText
                      text={detail && detail.comment ? detail.comment : "　"}
                    />
                  </Row>
                </div>
              )
            }
          </MediaQuery>
        </React.Fragment>
      </div>
    );
  }
}
export default ViewPlan2;
