export const LOAD_SISETU_DAILY_PLAN_SUCCESS = "LOAD_SISETU_DAILY_PLAN_SUCCESS";
export const LOAD_SISETU_DAILY_PLAN_BY_ID_SUCCESS =
  "LOAD_SISETU_DAILY_PLAN_BY_ID_SUCCESS";
export const LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_SUCCESS =
  "LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_SUCCESS";
export const LOAD_SISETU_DAILY_PLAN_TEMPLATE_SUCCESS =
  "LOAD_SISETU_DAILY_PLAN_TEMPLATE_SUCCESS";
export const SAVE_SISETU_DAILY_PLAN_SUCCESS =
  "CREATE_SISETU_DAILY_PLAN_SUCCESS";
export const DELETE_SISETU_DAILY_PLAN_SUCCESS =
  "DELETE_SISETU_DAILY_PLAN_SUCCESS";
export const LOAD_KAIGO_NAIYOU_SUCCESS = "LOAD_KAIGO_NAIYOU_SUCCESS";
export const LOAD_SEIKATU_NAIYOU_SUCCESS = "LOAD_SEIKATU_NAIYOU_SUCCESS";

export const LOAD_SISETU_DAILY_PLAN_ERROR = "LOAD_SISETU_DAILY_PLAN_ERROR";
export const LOAD_SISETU_DAILY_PLAN_BY_ID_ERROR =
  "LOAD_SISETU_DAILY_PLAN_BY_ID_ERROR";
export const LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_ERROR =
  "LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_ERROR";
export const LOAD_SISETU_DAILY_PLAN_TEMPLATE_ERROR =
  "LOAD_SISETU_DAILY_PLAN_TEMPLATE_ERROR";
export const SAVE_SISETU_DAILY_PLAN_ERROR = "CREATE_SISETU_DAILY_PLAN_ERROR";
export const DELETE_SISETU_DAILY_PLAN_ERROR = "DELETE_SISETU_DAILY_PLAN_ERROR";
export const LOAD_KAIGO_NAIYOU_ERROR = "LOAD_KAIGO_NAIYOU_ERROR";

export const LOAD_SPIN_REQUEST = "LOAD_SPIN_REQUEST";
export const LOAD_SPIN_REQUEST_TEMPLATE = "LOAD_SPIN_REQUEST_TEMPLATE";
export const LOAD_SEIKATU_NAIYOU_ERROR = "LOAD_SEIKATU_NAIYOU_ERROR";
export const RESET_STATUS = "RESET_STATUS";
