import React from "react";
import { Row, Table, Col } from "antd";

function AddtionCodeList(props) {
  const columns = [
    {
      dataIndex: "serviceCode",
      key: "serviceCode",
      width: 70,
    },
    {
      dataIndex: "name",
      key: "name",
      width: 350,
    },
    {
      dataIndex: "tani",
      key: "tani",
    },
  ];

  const dataCode = [];
  const listAdditionCode = props.dataExpandCode ? props.dataExpandCode : [];

  let len = listAdditionCode.length == 0 ? 10 : listAdditionCode.length;
  if (listAdditionCode.length == 0) {
    for (let i = 0; i < len; i++) {
      dataCode.push({
        key: i,
        serviceCode: "",
        name: "",
        tani: null,
      });
    }
  } else {
    for (let i = 0; i < len; i++) {
      dataCode.push({
        key: i,
        serviceCode: listAdditionCode[i].serviceCode,
        name: listAdditionCode[i].serviceName,
        tani:
          listAdditionCode[i].serviceFillDataList && listAdditionCode[i].serviceFillDataList[0]?.additionName === "計算コード"
            ? null
            : listAdditionCode[i].tani,
      });
    }
  }
  return (
    <Row style={{ marginTop: 10, display: "flex" }}>
      <Col span={6}>加算コード</Col>
      <Col span={17}>
        <Table rowClassName={'row-service-modal'} columns={columns} dataSource={dataCode} scroll={{ y: 300 }} showHeader={false} pagination={false} size="small" bordered />
      </Col>
    </Row>
  );
}

export { AddtionCodeList };
