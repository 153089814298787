import { Form } from "@ant-design/compatible";
import { Input } from "antd";
import React from "react";
const { TextArea } = Input;

class NormalFormReportCase extends React.Component {
  onBlurValue = (e) => {
    this.props.onChangeNaiyou2(e.target.value);
  };

  componentDidMount() {
    this.props.form.setFieldsValue({
      reportCaseNaiyou: this.props.value,
    });
  }

  componentDidUpdate(preProps) {
    const { value } = this.props;

    if (value !== preProps.value) {
      this.props.form.setFieldsValue({
        reportCaseNaiyou: value,
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        {getFieldDecorator("reportCaseNaiyou")(
          <TextArea rows={4} onBlur={this.onBlurValue} />
        )}
      </div>
    );
  }
}

const FormReportCase = Form.create()(NormalFormReportCase);

export default FormReportCase;
