import React, { Component } from "react";
import { Button } from "antd";

import Logo from "./media/logo.png";
import "./Logout.css";
import {
  localStorageClearCus,
  getPath,
} from "../../common/function_common/functionCommon";

const path = getPath();

class Logout extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   *
   */
  handleLogout() {
    localStorageClearCus();
    localStorage.removeItem("jwtToken");
    window.location.href = `${path}/login`;
  }

  /**
   *
   */
  handleClearLogout() {
    // @TODO not clear localstorage when click on logout button
    localStorage.clear();
    window.location.href = `${path}/login`;
  }

  /**
   *
   */
  render() {
    return (
      <div id={"logout-page"}>
        <img src={Logo} alt="Logo" />
        <div>
          <Button type="primary" onClick={this.handleLogout}>
            ログアウト
          </Button>
          <Button type="primary" onClick={this.handleClearLogout}>
            ログアウト、クリアデータ
          </Button>
        </div>
      </div>
    );
  }
}

export default Logout;
