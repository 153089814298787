import React from "react";

import {
  EnvironmentOutlined,
  FolderOutlined,
  FormOutlined,
  HighlightOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  getPath,
  isLife,
  isManagement,
} from "../../../common/function_common/functionCommon";

const Actions = (props) => {
  const path = getPath();
  return (
    <div className="ripa-list-actions">
      <ul>
        {/* {props.currentMenu &&
        props.currentMenu.indexOf("record") >= 0 && isManagement() ? null : (
          <Link to={`#`} onClick={() => props.riyousyaListIsKeep()}>
            <li className="rip-action">
              <span>
                <Icon type="edit" />
              </span>
              入退所
            </li>
          </Link>
        )} */}

        {!isManagement() ? (
          <Link
            to={`${path}/riyousya-shokuji/${props.id}`}
            onClick={() => props.riyousyaListIsKeep()}
          >
            <li className="rip-action">
              <span>
                <HighlightOutlined />
              </span>
              食事箋
            </li>
          </Link>
        ) : (
          ""
        )}

        <Link
          to={`${path}/riyousya-detail/${props.id}`}
          onClick={() => props.riyousyaListIsKeep()}
        >
          <li className="rip-action">
            <span>
              <UserOutlined />
            </span>
            詳細
          </li>
        </Link>

        {isManagement() ? (
          <Link
            to={`${path}/assessment/${props.id}`}
          // onClick={() => props.riyousyaListIsKeep()}
          >
            <li className="rip-action">
              <span>
                <HighlightOutlined />
              </span>
              全社協
            </li>
          </Link>
        ) : (
          ""
        )}

        {!isLife() ? (
          <Link
            to={`${path}/report-adl/${props.id}`}
            onClick={() => props.riyousyaListIsKeep()}
          >
            <li className="rip-action">
              <span>
                <SettingOutlined />
              </span>
              ADL
            </li>
          </Link>
        ) : (
          ""
        )}
        <Link
          to={`${path}/progress/${props.id}?dateSelected=${props.currentDate}`}
          onClick={() => props.riyousyaListIsKeep()}
        >
          <li className="rip-action">
            <span>
              <FormOutlined />
            </span>
            経過
          </li>
        </Link>
        <Link
          to={`${path}/riyousya-shiryou/${props.id}`}
          onClick={() => props.riyousyaListIsKeep()}
        >
          <li className="rip-action">
            <span>
              <FolderOutlined />
            </span>
            資料
          </li>
        </Link>
        <a onClick={() => props.displayMap(props.id)}>
          <li className="rip-action-detail">
            <span>
              <EnvironmentOutlined />
            </span>
            地図
          </li>
        </a>
      </ul>
    </div>
  );
};

export default Actions;
