import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import axios from "axios/index";
import { checkLogin } from "../common/function_common/functionCommon";
const GetAvatar = {
  getAvatar() {
    return axios({
      method: "get",
      url: apiUrls.GET_AVATAR,
      withCredentials: true,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (!err.response) {
        } else {
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
};
export default GetAvatar;
