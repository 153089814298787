import { Form } from "@ant-design/compatible";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import React, { Component } from "react";

// import '@ant-design/compatible/assets/index.css';


import { Button, Checkbox, Col, Divider, Input, Modal, Radio, Row, Select, Table } from "antd";
import _ from "lodash";
import { toKatakana } from "wanakana";
import TextAreaSelect from "./TextAreaSelect";
import TextInput from "./TextInput";

import InputNo from "./InputNo";

import { convertYear } from "../../../common/function_common/functionCommon";

const { Option } = Select;

const gengo = ["令和", "平成", "昭和", "大正", "明治"];
const year = 64; // defaulut
const month = 12;
const day = 31;

class FormKankeiNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
      dialog: false,
      visibleZips: false,
      textSearch: "",
      rowActive: null,
      record: null,
      modalOn: false,
      gengo: this.selectOptions(gengo, 1),
      year: this.selectOptions(year, 2),
      month: this.selectOptions(month, 2),
      day: this.selectOptions(day, 2),
      selectGengo: "",
    };
  }

  componentDidUpdate(preProps, preState) {
    const { zipSearch, data } = this.props;
    const { dialog, selectGengo } = this.state;

    if (zipSearch !== preProps.zipSearch) {
      if (zipSearch.length === 1 && !dialog) {
        const record = zipSearch[0];

        const { setFieldsValue } = this.props.form;

        if (record) {
          setFieldsValue({
            zip: record.zip,
            address1: record.address,
          });
        }

        this.setState({
          rowActive: null,
          record: null,
          visibleZips: false,
        });
      }
      else {
        const { visibleZips, modalOn } = this.state;
        if (!visibleZips && modalOn) {
          this.setState({
            visibleZips: true,
            modalOn: false,
          });
        }
      }
    }
    // 最初にprops.dataからセットされている生年月日から和暦をセットする
    if (selectGengo == "" && data.birthday && data.birthday !== "0001-01-01") {
      const setGengo = convertYear(data.birthday, 1);
      console.log(setGengo);
      this.setState({
        selectGengo: setGengo,
      });
    }
  }

  selectOptions = (value, type) => {
    const options = [
      <Option key={0} value="">
        &nbsp;
      </Option>,
    ];
    if (type == 1) {
      for (let i = 0; i < value.length; i++) {
        options.push(
          <Option key={i + 1} value={value[i]}>
            {value[i]}
          </Option>
        );
      }
    }

    if (type == 2) {
      for (let i = 1; i <= value; i++) {
        options.push(
          <Option key={i} value={i}>
            {i}
          </Option>
        );
      }
    }

    return options;
  };

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouConcern",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleSort = (type, sortType, index) => {
    this.props.handleSort(type, sortType, index);
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  onBlurFurigana = (e) => {
    this.props.form.setFields({
      furigana: {
        value: toKatakana(e.target.value),
      },
    });
  };

  closeSearchYuubinBango = () => {
    this.setState({
      visibleZips: false,
      modalOn: false,
    });
  };
  onChangeInputSearch = (e) => {
    this.setState({
      textSearch: e.target.value,
    });
  };
  handleSelectRowZip = () => {
    const { record } = this.state;
    const { setFieldsValue } = this.props.form;
    if (record) {
      setFieldsValue({
        zip: record.zip,
        address1: record.address,
      });
    }

    this.setState({
      rowActive: null,
      record: null,
      visibleZips: false,
      modalOn: false,
    });
  };
  // 郵便モーダル行選択
  onRowClick = (record, rowIndex) => {
    this.setState({
      rowActive: rowIndex,
      record,
    });
  };

  // 郵便モーダルはcomponentDidUpdate時に判別
  searchYubin = (text, dialog) => {
    const { getFieldValue } = this.props.form;

    if (!text) {
      text = getFieldValue("zip");
      this.setState({
        textSearch: text,
      });
    }

    this.setState({
      dialog: dialog ? dialog : false,
      modalOn: true,
    });

    this.props.searchYuuBinBango(text);
  };
  contactCopy = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    let setUrgentContact = "";
    const address1 = getFieldValue("address1");
    const address2 = getFieldValue("address2");
    const tel = getFieldValue("tel");
    const keitai = getFieldValue("keitai");

    if (address1) {
      setUrgentContact = setUrgentContact + address1 + "\r";
    }
    if (address2) {
      setUrgentContact = setUrgentContact + address2 + "\r";
    }
    if (tel) {
      setUrgentContact = setUrgentContact + tel + "\r";
    }
    if (keitai) {
      setUrgentContact = setUrgentContact + keitai;
    }

    setFieldsValue({
      urgentContact: setUrgentContact,
    });
  };

  addressCopy = () => {
    const { getFieldValue } = this.props.form;
    const zip = getFieldValue("zip");
    const address1 = getFieldValue("address1");
    const address2 = getFieldValue("address2");

    navigator.clipboard.writeText(zip + "," + address1 + "," + address2);
  };

  changeGengo = (e) => {
    this.setState({
      selectGengo: e,
    });
  };

  render() {
    const {
      visible,
      visibleZips,
      textSearch,
      gengo,
      year,
      month,
      day,
      selectGengo,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { index, kankeList, zipLoading, zipSearch } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
        style: {width: '140px'}
      },
    };

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {index && index > 0 ? (
          <Row style={{ padding: "8px 0px" }}>
            <Divider />
            <Divider />
            <Divider />
          </Row>
        ) : null}
        <Row className="title-inline">
          <Col span={12}>
            <h3
              style={{ background: "#d9d9d9", padding: "4px 6px" }}
            >{`関係者 ${index + 1}`}</h3>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.showDeleteConfirm}
            >
              削除
            </Button>
          </Col>
          <Col span={12} className="title-inline-right">
            <h3>順序</h3>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.handleSort.bind(this, "riyouConcern", "up", index)}
            >
              上へ <CaretUpOutlined />
            </Button>
            <Button
              style={{ background: "#777", color: "#fff" }}
              onClick={this.handleSort.bind(
                this,
                "riyouConcern",
                "down",
                index
              )}
            >
              下へ <CaretDownOutlined />
            </Button>
          </Col>
        </Row>
        <Divider />
        <Divider />
        <Row>
          <Col span={12}>
            <Form.Item label={"名前"} colon={false}>
              {getFieldDecorator("name", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<TextInput />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={"性別"} labelAlign={"right"} colon={false}>
              {getFieldDecorator("gender", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(
                <Radio.Group>
                  <Radio value={1} defaultChecked>
                    男
                  </Radio>
                  <Radio value={2}>女</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <Form.Item label={"フリガナ"} colon={false}>
              {getFieldDecorator("furigana", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<TextInput onBlur={this.onBlurFurigana} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={"生年月日"} labelAlign={"right"} colon={false}>
              {getFieldDecorator("gengo")(
                <Select
                  style={{ width: 70 }}
                  onChange={(e) => this.changeGengo(e)}
                >
                  {gengo}
                </Select>
              )}
              {getFieldDecorator("year")(
                <Select style={{ width: 60 }}>
                  {selectGengo == "令和" ? (
                    this.selectOptions(new Date().getFullYear() - 2018, 2)
                  ) : selectGengo == "平成" ? (
                    this.selectOptions(31, 2)
                  ) : selectGengo == "昭和" ? (
                    this.selectOptions(64, 2)
                  ) : selectGengo == "大正" ? (
                    this.selectOptions(15, 2)
                  ) : selectGengo == "明治" ? (
                    this.selectOptions(45, 2)
                  ) : (
                    // this.state.year
                    (<Option key={0} value="">
                    </Option>)
                  )}
                </Select>
              )}
              {getFieldDecorator("month")(
                <Select style={{ width: 60 }}>{month}</Select>
              )}
              {getFieldDecorator("day")(
                <Select style={{ width: 60 }}>{day}</Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Form.Item label={"続柄"} colon={false}>
          {getFieldDecorator("kankei", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(
            <TextAreaSelect
              isMaster={false}
              isSelect={true}
              rowSet={1}
              dataSelect={kankeList}
              yougoMasterUpdate={(naiyou) =>
                this.yougoMasterUpdate("利用者関係", naiyou)
              }
              isOveride={true}
            />
          )}
        </Form.Item>
        <Divider />
        <Row className="dis-inline-form-item" style={{flexWrap: 'wrap', width: '85%'}}>
          <Form.Item>
            {getFieldDecorator("doukyof", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group options={[{ label: "同居", value: "doukyof" }]} />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("kaigof1", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "主介護者", value: "kaigof1" }]}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("kaigof2", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "副介護者", value: "kaigof2" }]}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("seikyuf", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "請求先", value: "seikyuf" }]}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("keypersonf", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "キーパーソン", value: "keypersonf" }]}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("moshikomif", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "申込者", value: "moshikomif" }]}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("hoshoninf", {
              rules: [{ required: false }],
            })(
              <Checkbox.Group
                options={[{ label: "保証人", value: "hoshoninf" }]}
              />
            )}
          </Form.Item>
        </Row>
        <Divider />
        {/* //// */}
        <Form.Item label={"住所"} colon={false}>
          <span style={{ marginLeft: "-19px", paddingRight: "5px" }}>〒</span>
          {getFieldDecorator("zip", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextInput style={{ width: 100 }} />)}
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            onClick={this.searchYubin.bind(this, "", false)}
          >
            〒検索
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: 20 }}
            onClick={this.addressCopy}
          >
            住所複製
          </Button>
        </Form.Item>
        <Form.Item label={" "} colon={false}>
          {getFieldDecorator("address1", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextInput style={{ width: 350 }} />)}
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            onClick={this.contactCopy}
          >
            緊急連絡先へコピー
          </Button>
        </Form.Item>
        <Form.Item label={" "} colon={false}>
          {getFieldDecorator("address2", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextInput />)}
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label={"TEL"} colon={false}>
              {getFieldDecorator("tel", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<TextInput />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={"携帯"} labelAlign={"right"} colon={false}>
              {getFieldDecorator("keitai", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<TextInput />)}
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <Form.Item label={"FAX"} colon={false}>
              {getFieldDecorator("fax", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<TextInput />)}
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Form.Item>
          {getFieldDecorator("urgentf", {
            rules: [{ required: false }],
          })(
            <Checkbox.Group
              options={[{ label: "緊急連絡先", value: "urgentf" }]}
            />
          )}
        </Form.Item>
        <Divider />
        <Form.Item label={"緊急連絡先"} colon={false}>
          {getFieldDecorator("urgentContact", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextAreaSelect isMaster={false} isSelect={false} rowSet={2} />)}
        </Form.Item>
        <Divider />
        <Form.Item label={"備考"} colon={false}>
          {getFieldDecorator("bikou", {
            rules: [{ required: false, message: "必須項目をセットして下さい" }],
          })(<TextAreaSelect isMaster={false} isSelect={false} rowSet={2} />)}
        </Form.Item>
        <Divider />
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
        <Modal
          title={
            <div>
              <h3 style={{ display: "inline", paddingRight: 16 }}>
                〒郵便番号
              </h3>
              <Input
                onChange={this.onChangeInputSearch}
                value={textSearch}
                style={{ maxWidth: 280 }}
              />
              <Button
                type="primary"
                onClick={this.searchYubin.bind(this, textSearch, true)}
              >
                検索
              </Button>
            </div>
          }
          open={visibleZips}
          onOk={this.handleSelectRowZip}
          onCancel={this.closeSearchYuubinBango}
          footer={[
            <Button key="back" onClick={this.closeSearchYuubinBango}>
              キャンセル
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleSelectRowZip}
            >
              OK
            </Button>,
          ]}
          closable={false}
          className="modal-search-zip"
        >
          <Table
            columns={[
              {
                title: "zip",
                dataIndex: "zip",
              },
              {
                title: "address",
                dataIndex: "address",
              },
            ]}
            dataSource={zipSearch}
            loading={zipLoading}
            size="small"
            pagination={{ pageSize: 6 }}
            showHeader={false}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.onRowClick(record, rowIndex);
                }, // click row
              };
            }}
            rowClassName={(record, index) =>
              this.state.rowActive != null && index === this.state.rowActive
                ? "pljp-table-row-hover"
                : ""
            }
          />
        </Modal>
        <div style={{ display: "none" }}>
          {getFieldDecorator("birthday", {
            rules: [{ required: false }],
          })(<InputNo />)}
        </div>
      </Form>)
    );
  }
}

export const FormKankei = Form.create({
  name: "input-riyou-kankei",
  onValuesChange(props, changedValues, allValues) {
    // ここでonChangeの役割
    if (changedValues.kaigof1) {
      props.form.setFields({
        kaigof2: {
          value: [],
        },
      });

      allValues.kaigof2 = [];

      if (changedValues.kaigof1.length <= 0) {
        allValues.kaigof = null;
      }
    }

    if (changedValues.kaigof2) {
      props.form.setFields({
        kaigof1: {
          value: [],
        },
      });

      allValues.kaigof1 = [];

      if (changedValues.kaigof2.length <= 0) {
        allValues.kaigof = null;
      }
    }
    if (
      changedValues.gengo &&
      changedValues.gengo !== props.form.getFieldValue("gengo")
    ) {
      props.form.setFieldsValue({
        gengo: changedValues.gengo,
      });
    }
    if (
      changedValues.year &&
      changedValues.year !== props.form.getFieldValue("year")
    ) {
      props.form.setFieldsValue({
        year: changedValues.year,
      });
    }
    if (
      changedValues.month &&
      changedValues.month !== props.form.getFieldValue("month")
    ) {
      props.form.setFieldsValue({
        month: changedValues.month,
      });
    }
    if (
      changedValues.day &&
      changedValues.day !== props.form.getFieldValue("day")
    ) {
      props.form.setFieldsValue({
        day: changedValues.day,
      });
    }

    // Convert checkbox group to value
    const tmp = _.cloneDeep(allValues);
    if (allValues.doukyof && allValues.doukyof.length > 0) {
      tmp.doukyof = 1;
    }

    if (allValues.kaigof1 && allValues.kaigof1.length > 0) {
      tmp.kaigof = 1;
    }

    if (allValues.kaigof2 && allValues.kaigof2.length > 0) {
      tmp.kaigof = 2;
    }

    if (allValues.seikyuf && allValues.seikyuf.length > 0) {
      tmp.seikyuf = 1;
    }

    if (allValues.keypersonf && allValues.keypersonf.length > 0) {
      tmp.keypersonf = 1;
    }

    if (allValues.moshikomif && allValues.moshikomif.length > 0) {
      tmp.moshikomif = 1;
    }

    if (allValues.hoshoninf && allValues.hoshoninf.length > 0) {
      tmp.hoshoninf = 1;
    }

    if (allValues.urgentf && allValues.urgentf.length > 0) {
      tmp.urgentf = 1;
    }

    props.saveOnchangeToState(tmp, "fKankei", props.index);
  },
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({
        value: props.data ? props.data.name : null,
      }),
      furigana: Form.createFormField({
        value: props.data ? props.data.furigana : null,
      }),
      kankei: Form.createFormField({
        value: props.data ? props.data.kankei : null,
      }),
      doukyof: Form.createFormField({
        value: props.data && props.data.doukyof === 1 ? ["doukyof"] : [],
      }),
      kaigof1: Form.createFormField({
        value:
          props.data && props.data.kaigof && props.data.kaigof === 1
            ? ["kaigof1"]
            : [],
      }),
      kaigof2: Form.createFormField({
        value:
          props.data && props.data.kaigof && props.data.kaigof === 2
            ? ["kaigof2"]
            : [],
      }),
      seikyuf: Form.createFormField({
        value: props.data && props.data.seikyuf === 1 ? ["seikyuf"] : [],
      }),
      keypersonf: Form.createFormField({
        value: props.data && props.data.keypersonf === 1 ? ["keypersonf"] : [],
      }),
      moshikomif: Form.createFormField({
        value: props.data && props.data.moshikomif === 1 ? ["moshikomif"] : [],
      }),
      hoshoninf: Form.createFormField({
        value: props.data && props.data.hoshoninf === 1 ? ["hoshoninf"] : [],
      }),
      urgentf: Form.createFormField({
        value: props.data && props.data.urgentf === 1 ? ["urgentf"] : [],
      }),
      tel: Form.createFormField({
        value: props.data ? props.data.tel : null,
      }),
      keitai: Form.createFormField({
        value: props.data ? props.data.keitai : null,
      }),
      urgentContact: Form.createFormField({
        value: props.data ? props.data.urgentContact : null,
      }),
      bikou: Form.createFormField({
        value: props.data ? props.data.bikou : null,
      }),
      gender: Form.createFormField({
        value: props.data ? props.data.gender : null,
      }),
      zip: Form.createFormField({
        value: props.data ? props.data.zip : null,
      }),
      address1: Form.createFormField({
        value: props.data ? props.data.address1 : null,
      }),
      address2: Form.createFormField({
        value: props.data ? props.data.address2 : null,
      }),
      fax: Form.createFormField({
        value: props.data ? props.data.fax : null,
      }),
      birthday: Form.createFormField({
        value: props.data ? props.data.birthday : null,
      }),
      gengo: Form.createFormField({
        value:
          (props.data && props.data.gengo) || props.data.gengo == ""
            ? props.data.gengo
            : // props.data && props.data.birthday && props.data.birthday !== "0001-01-01" ? convertYear(props.data.birthday.split("-")[0], 1) : null
            props.data &&
              props.data.birthday &&
              props.data.birthday !== "0001-01-01"
              ? convertYear(props.data.birthday, 1)
              : null,
      }),

      year: Form.createFormField({
        value:
          props.data && props.data.year
            ? Number(props.data.year)
            : props.data && props.data.year == ""
              ? null
              : // props.data && props.data.birthday && props.data.birthday !== "0001-01-01" ? convertYear(props.data.birthday.split("-")[0], 2) : null
              props.data &&
                props.data.birthday &&
                props.data.birthday !== "0001-01-01"
                ? convertYear(props.data.birthday, 2)
                : null,
      }),
      month: Form.createFormField({
        value:
          props.data && props.data.month
            ? Number(props.data.month)
            : props.data && props.data.month == ""
              ? null
              : props.data &&
                props.data.birthday &&
                props.data.birthday !== "0001-01-01"
                ? Number(props.data.birthday.split("-")[1])
                : null,
      }),
      day: Form.createFormField({
        value:
          props.data && props.data.day
            ? Number(props.data.day)
            : props.data && props.data.day == ""
              ? null
              : props.data &&
                props.data.birthday &&
                props.data.birthday !== "0001-01-01"
                ? Number(props.data.birthday.split("-")[2])
                : null,
      }),
    };
  },
})(FormKankeiNormal);
