import { Row, Spin } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import {
  changeMealStatus,
  getDataInOut,
} from "../../actions/ioStatusActions/actionCreators";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import MenuBusinessV2 from "../../common/component/UI/MenuBusinessV2";
import { ConstSet } from "../../common/configs/constset";
import "./InOutStatus.css";
import IORecordTable from "./UI/IORecordTable";
import Toolbar from "./UI/Toolbar";

const dataKey = "showFilter";
const filterObj = {
  search: {
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    field2: null,
    field3: null,
    field10: null,
    field11: null,
  },
};
const sortObj = {
  key1: "0",
  value1: "asc",
  key2: "0",
  value2: "asc",
  key3: "0",
  value3: "asc",
  key4: "0",
  value4: "asc",
  key5: "0",
  value5: "asc",
};

class InOutStatus extends Component {
  constructor(props) {
    let showFilter = JSON.parse(localStorage.getItem(dataKey));
    if (!showFilter) {
      showFilter = {
        searchName: "",
        sortObj,
        filterObj,
      };
      localStorage.setItem(dataKey, JSON.stringify(showFilter));
    }
    super(props);

    let day;
    const ios_date = localStorage.getItem("ios_date");
    if (ios_date != null) {
      day = ios_date;
      localStorage.removeItem("ios_date");
    }
    else {
      day = new Date();
    }

    this.state = {
      screenMode: "event",
      page: 1,
      selectedDate: dayjs(day),
      isMealStatus: false,
      searchName: showFilter ? showFilter.searchName : "",
      filterObj: showFilter ? showFilter.filterObj : showFilter,
      sortObj: showFilter ? showFilter.sortObj : sortObj,
      isDisLongText: false,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.meta && nextProps.meta.page !== this.state.page) {
      this.setState({
        page: nextProps.meta.page,
      });
    }
  }

  handleChangeMealStatusCheckBox() {
    const newMealStatus = !this.state.isMealStatus;
    this.props.changeMealStatus(newMealStatus);
    this.setState({
      isMealStatus: newMealStatus,
    });
  }

  selectDay(day) {
    const { filterObj, sortObj, searchName, page } = this.state;
    // const oldDay = this.state.selectedDate.toDate().getDay();
    const time = `${day.get("year")}-${day.get("month") + 1}-${day.get("date")}`;
    this.props.getDataIoStatus(time, filterObj, sortObj, searchName, page);

    /* this.loadData(this.state.screenMode, day);*/
    this.setState({
      selectedDate: day,
    });
  }

  handleFilterOk(filterObj) {
    const showFilter = JSON.parse(localStorage.getItem(dataKey));
    showFilter.filterObj = filterObj;
    localStorage.setItem(dataKey, JSON.stringify(showFilter));
    const { selectedDate, sortObj, searchName } = this.state;
    this.setState({
      queryData: true,
      filterObj,
    });
    this.props.getDataIoStatus(
      selectedDate.format(ConstSet.DATE_FORMAT),
      filterObj,
      sortObj,
      searchName
    );
  }
  handleSortOk(sortObj) {
    const showFilter = JSON.parse(localStorage.getItem(dataKey));
    showFilter.sortObj = sortObj;
    localStorage.setItem(dataKey, JSON.stringify(showFilter));
    const { selectedDate, filterObj, searchName, page } = this.state;
    this.setState({
      queryData: true,
      sortObj,
    });
    this.props.getDataIoStatus(
      selectedDate.format(ConstSet.DATE_FORMAT),
      filterObj,
      sortObj,
      searchName,
      page
    );
  }
  handlePageChange(page) {
    const { selectedDate, filterObj, searchName, sortObj } = this.state;

    this.setState({
      page,
    });
    this.props.getDataIoStatus(
      selectedDate.format(ConstSet.DATE_FORMAT),
      filterObj,
      sortObj,
      searchName,
      page
    );
  }
  handleChangeSearchName(value) {
    const showFilter = JSON.parse(localStorage.getItem(dataKey));
    showFilter.searchName = value;
    localStorage.setItem(dataKey, JSON.stringify(showFilter));
    const { selectedDate, filterObj, sortObj, page } = this.state;
    this.setState({
      searchName: value,
    });
    this.props.getDataIoStatus(
      selectedDate.format(ConstSet.DATE_FORMAT),
      filterObj,
      sortObj,
      value,
      page
    );
  }
  toggleOpenLongText = () => {
    const { isDisLongText } = this.state;

    this.setState({
      isDisLongText: !isDisLongText,
    });
  };
  componentDidMount() {
    const date = this.state.selectedDate;
    const { filterObj, sortObj, searchName, page } = this.state;
    const time = `${date.get("year")}-${date.get("month") + 1}-${date.get("date")}`;
    this.props.getDataIoStatus(time, filterObj, sortObj, searchName, page);
  }

  render() {
    const {
      screenMode,
      selectedDate,
      filterObj,
      sortObj,
      searchName,
      page,
      isDisLongText,
    } = this.state;
    const { loading } = this.props;
    const paging = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: this.props.meta ? this.props.meta.total : 0,
      current: this.props.meta ? this.props.meta.page : 1,
      pageSize: this.props.meta ? this.props.meta.pageSize : 100,
    };
    return (
      <CommonPageContainer
        id={"in-out-status-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <Row className={"submenu"}>
          <MediaQuery query="(min-device-width: 768px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={screenMode === "event" ? "入退所状況" : ""}
                        iconType="schedule"
                        count={true}
                        countNum={
                          this.props?.meta?.total ? this.props.meta.total : 0
                        }
                      />
                    }
                    TopRight={<MenuBusinessV2 isStatus={true} />}
                  />
                );
              }
              {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={screenMode === "event" ? "入退所状況" : ""}
                        iconType="schedule"
                      />
                    }
                  />
                );
              }
            }}
          </MediaQuery>
        </Row>
        <Spin spinning={loading}>
          <Toolbar
            handleChangeMealStatusCheckBox={() =>
              this.handleChangeMealStatusCheckBox()
            }
            handleFilterOk={(filterObj) => this.handleFilterOk(filterObj)}
            handleSortOk={(sortObj) => this.handleSortOk(sortObj)}
            toggleOpenLongText={this.toggleOpenLongText}
            isDisLongText={isDisLongText}
            selectedDate={selectedDate}
            // searchName={this.state.searchName}
            handleSelectDay={(day) => this.selectDay(day)}
            onChangeSearchName={(searchName) =>
              this.handleChangeSearchName(searchName)
            }
            filterObj={filterObj}
            sortObj={sortObj}
            searchName={searchName}
            page={page}
          />
          <IORecordTable
            selectedDate={selectedDate}
            pagination={paging}
            isDisLongText={isDisLongText}
            // isMealStatus={isMealStatus}
            filterObj={filterObj}
            sortObj={sortObj}
            searchName={searchName}
            page={page}
          />
        </Spin>
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return <div></div>;
            }
            {
              return (
                <Row
                  style={{ height: "55px" }}
                  className={"submenu menu-inout-footer"}
                >
                  <SubHeaderV2
                    FooterMobile={<MenuBusinessV2 isStatus={true} />}
                  />
                </Row>
              );
            }
          }}
        </MediaQuery>
      </CommonPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMealStatus: state.ioStatusReducers.isMealStatus,
    data: state.ioStatusReducers.data,
    meta: state.ioStatusReducers.meta,
    loading: state.ioStatusReducers.loading,
    inserting: state.ioStatusReducers.inserting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeMealStatus: (isMealStatus) =>
      dispatch(changeMealStatus(isMealStatus)),
    getDataIoStatus: (value, filterObj, sort, searchName, page) =>
      dispatch(getDataInOut(value, filterObj, sort, searchName, page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InOutStatus);
