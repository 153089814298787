export const SettingSelected = ["印刷設定（利用票、提供票、別表)",  "印刷設定（月間スケジュール）", "出力帳票設定"];
export const SettingFormOuput = [];
export const SettingIn = ["利用票、提供票、別表", "月間スケジュール"];
export const INIT_SETTING = "利用票:1,利用票別表:1,利用票/別表:1,提供票:1,提供票別表（選択）:1,提供票別表（全）:0,提供票/別表（選択）:1,提供票/別表（全）:0,利用票/別表・提供票/別表（選択）:1,利用票/別表・提供票/別表（全）:0,月間スケジュール（印刷）:1,月間スケジュール（エクセル）:0";
export const INIT_SETTING_FORM_SLIP = "敬称:様,利用票:1,作成年月日印刷:false,利用者名隠し文字処理:false,網掛け印刷:true, 色:#eceff1";
export const INIT_SETTINGS = `時間:true,サービス種類:true,事業所名:true,レンタル事業所名:true,レンタル商品名:true,レンタル利用期間:true,印字順:0,コメントタイトル: ,コメント内容: `;
export const TitleProvide = "利用票、提供票、別表　印刷設定";
export const SETTING_MASTER_NAME_FORM= "swmp利用票出力帳票";
export const SETTING_MASTER_NAME_FORM_SLIP = "swmp利用票印刷設定";
export const SETTING_MASTER_NAME = "swmp月間スケジュール印刷設定";
export const SETTING_SCHEDULE = ["月間スケジュール（印刷）", "月間スケジュール（エクセル）"];
export const CHOOSE_PDF_SETTING = [{
  key: "利用票",
  value: "1"
},
{
  key: "利用票別表",
  value: "1"
},
{
  key: "提供票",
  value: "1"
},
{
  key: "提供票別表",
  value: "1"
},
{
  key: "月間スケジュール",
  value: "1"
}
];

export const ListAllDataUseslipAndFormProvid = ["利用票別表","提供票別表（選択）","提供票別表（全）","月間スケジュール（印刷）","月間スケジュール（エクセル）"];

export const ListDefaultDataAttachedPDF = ["提供票別表（選択）","提供票/別表（選択）","利用票/別表・提供票/別表（選択)", "提供票別表（全）", "提供票/別表（全）", "利用票/別表・提供票/別表（選択）", "利用票/別表・提供票/別表（全）", "月間スケジュール（印刷）", "月間スケジュール（エクセル）"];

export const ListOfficeNameSort = ["提供票別表（全）", "提供票別表（選択）"];

export const ListDataAttachedFormProvid = ["提供票別表（選択）","提供票別表（選択）", "提供票別表（全）"];

export const ListKeysNameAttachedPDF = [
  "サービスコード", "サービス内容/種類", "サービス単位/金額", "事業所名", "事業所番号", "保険対象分",
  "保険給付額", "全額負担分", "割引適用後単位数", "割引適用後率", "区分支給限度基準を超える単位数",
  "区分支給限度基準内単位数", "単位数", "単位数単価", "回数", "種類支給限度基準を超える単位数",
  "種類支給限度基準内単位数", "給付率", "給付管理単位数", "費用総額"
];

export const OfficeDialogPDF3 = "利用票/別表";

export const OfficeDialogPDF4 = "提供票";

export const OfficeDialogPDF6 = "提供票別表（全）";

export const OfficeDialogPDF7 = "提供票/別表（選択）";

export const OfficeDialogPDF8 = "提供票/別表（全）";

export const OfficeDialogPDF9 = "利用票/別表・提供票/別表（選択）";

export const OfficeDialogPDF10 = "利用票/別表・提供票/別表（全）";

export const OfficeDialogPDF11 = "月間スケジュール（印刷）";

export const OfficeDialogPDF12 = "月間スケジュール（エクセル）";

export const ListDaysOfWeek = ["monday", "tuesday", "wednesday","thursday", "friday", "saturday", "sunday"];

export const fontColorSchedule = "#E72323"

