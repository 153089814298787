import { Select } from "antd";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { tantoMasterList2 } from "../../../actions/tantoMasterListActions/actionCreators";

const { Option } = Select;

class TantoInput extends React.Component {
  constructor(props) {
    super(props);
    const value = props.value || null;
    this.state = {
      id: value == 0 ? null : value,
      code: "",
    };
  }

  componentDidMount() {
    this.props.tantoMasterList2();
    const { onChangeItem, value, tantoMasters2 } = this.props;
    if (onChangeItem && value && tantoMasters2) {
      this.handleChangeItem(value);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { tantoMasters2 } = nextProps;
    const code = _.find(tantoMasters2, { id: nextProps.value })
      ? _.find(tantoMasters2, { id: nextProps.value }).code
      : null;
    this.setState({ id: nextProps.value || null, code });
  }

  componentDidUpdate(prevProps) {
    const { onChangeItem, value, tantoMasters2 } = this.props;

    if (
      onChangeItem &&
      value &&
      tantoMasters2 &&
      JSON.stringify(tantoMasters2) !== JSON.stringify(prevProps.tantoMasters2)
    ) {
      this.handleChangeItem(value);
    }
  }

  handleNumberChange(value) {
    const { tantoMasters2 } = this.props;
    const code = _.find(tantoMasters2, { id: value })
      ? _.find(tantoMasters2, { id: value }).code
      : null;
    this.setState({ id: value, code });
    this.triggerChange(value);
    this.handleChangeItem(value);
  }

  handleTantoChange(value) {
    const { tantoMasters2 } = this.props;
    const code = _.find(tantoMasters2, { id: value })
      ? _.find(tantoMasters2, { id: value }).code
      : null;
    this.setState({ id: value, code });
    this.triggerChange(value);
    this.handleChangeItem(value);
  }

  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  handleChangeItem(id) {
    if (this.props.onChangeItem) {
      const { tantoMasters2 } = this.props;
      const item = _.find(tantoMasters2, { id });

      if (item && item.id) {
        this.props.onChangeItem(item);
      }
    }
  }

  render() {
    const { id } = this.state;
    const { tantoMasters2, allowBlank, isDisJobCate } = this.props;
    return (
      <div style={this.props.style}>
        <Select
          value={id}
          dropdownMatchSelectWidth={false}
          onChange={(value) => this.handleNumberChange(value)}
          showSearch
          style={{ width: "80px", marginRight: "5px" }}
          getPopupContainer={
            this.props.containerId
              ? () => document.getElementById(this.props.containerId)
              : () => document.body
          }
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {allowBlank ? <Option value={null}>&nbsp;</Option> : null}
          {Array.isArray(tantoMasters2)
            ? tantoMasters2.map((tanto, i) => {
              return (
                <Option key={i} value={tanto.id}>
                  {tanto.code}
                </Option>
              );
            })
            : null}
        </Select>
        <Select
          value={id}
          dropdownMatchSelectWidth={false}
          getPopupContainer={
            this.props.containerId
              ? () => document.getElementById(this.props.containerId)
              : () => document.body
          }
          style={{ width: "180px" }}
          onChange={(value) => this.handleTantoChange(value)}
        >
          {allowBlank ? <Option value={null}>&nbsp;</Option> : null}
          {Array.isArray(tantoMasters2)
            ? tantoMasters2.map((tanto, i) => {
              return (
                <Option key={i} value={tanto.id}>
                  {tanto.name}
                </Option>
              );
            })
            : []}
        </Select>

        {isDisJobCate ? (
          <p>
            職種:{" "}
            <span>
              {_.find(tantoMasters2, { id })
                ? _.find(tantoMasters2, { id }).jobCategory
                : null}
            </span>
          </p>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tantoMasters2: state.tantoMasterList.tantoMasters2,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tantoMasterList2: () => dispatch(tantoMasterList2()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TantoInput);
