export const LOAD_SISETU_KAIGO_NAIYOU_REQUEST =
  "LOAD_SISETU_KAIGO_NAIYOU_REQUEST";
export const LOAD_SISETU_KAIGO_NAIYOU_SUCCESS =
  "LOAD_SISETU_KAIGO_NAIYOU_SUCCESS";
export const LOAD_SISETU_KAIGO_NAIYOU_ERROR = "LOAD_SISETU_KAIGO_NAIYOU_ERROR";
export const LOAD_SISETU_KAIGO_NAIYOU_LIST_REQUEST =
  "LOAD_SISETU_KAIGO_NAIYOU_LIST_REQUEST";
export const LOAD_SISETU_KAIGO_NAIYOU_LIST_SUCCESS =
  "LOAD_SISETU_KAIGO_NAIYOU_LIST_SUCCESS";
export const SAVE_SISETU_KAIGO_NAIYOU_SUCCESS =
  "SAVE_SISETU_KAIGO_NAIYOU_SUCCESS";
export const SISETU_KAIGO_NAIYOU_MASTER_INIT_SUCCESS =
  "SISETU_KAIGO_NAIYOU_MASTER_INIT_SUCCESS";
export const CREATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS =
  "CREATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS";
export const CREATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR =
  "CREATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR";
export const UPDATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS =
  "UPDATE_SISETU_KAIGO_NAIYOU_MASTER_SUCCESS";
export const UPDATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR =
  "UPDATE_SISETU_KAIGO_NAIYOU_MASTER_ERROR";
