import { Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import { guid } from "../../../common/timePicker/formatTimePicker";

const columns = [
  { title: "区分", dataIndex: "kind", key: "kind", width: 68, align: "left" },
  { title: "利用者", dataIndex: "name", key: "name", width: 80, align: "left" },
  { title: "部屋", dataIndex: "room", key: "room", width: 80, align: "left" },
  {
    title: "転帰",
    dataIndex: "change",
    key: "change",
    width: 68,
    align: "left",
  },
  {
    title: "入退所分類",
    dataIndex: "bunrui",
    key: "bunrui",
    width: 68,
    align: "left",
  },
];

class ListPeopleOutIn extends React.Component {
  render() {
    const { peopleOutInListByKind } = this.props;
    const data = Array.isArray(peopleOutInListByKind)
      ? peopleOutInListByKind.map((e) => {
        return {
          key: guid(),
          kind: e.kind,
          name: e.riyousyaName,
          room: e.room,
          change: e.change,
          bunrui:
            e.kind == "入院" || e.kind == "外泊" || e.kind == "外出"
              ? ""
              : e.bunrui,
        };
      })
      : [];
    return (
      <div>
        <h4>入退所者リスト</h4>
        <Table
          rowKey={"key"}
          pagination={false}
          scroll={{ y: 140 }}
          bordered={true}
          columns={columns}
          size={"small"}
          dataSource={data}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    peopleOutInListByKind: state.dutyReport.peopleOutInListByKind,
  };
};
export default connect(mapStateToProps, null)(ListPeopleOutIn);
