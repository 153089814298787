import { KihonCheckActionTypes } from "../action_types";
import { CommonUtils } from "../../utils/index";

function kihonCheck(state = {}, action) {
  switch (action.type) {
    // get all plan1 by user id
    case KihonCheckActionTypes.GET_ALL_KIHON_CHECK_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case KihonCheckActionTypes.GET_ALL_KIHON_CHECK_SUCCESS: {
      return {
        ...state,
        loading: false,
        datas: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case KihonCheckActionTypes.GET_ALL_KIHON_CHECK_FAILURE: {
      return {
        ...state,
        datas: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }

    //by id
    case KihonCheckActionTypes.GET_KIHON_CHECK_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getOne",
      };
    }
    case KihonCheckActionTypes.GET_KIHON_CHECK_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }
    case KihonCheckActionTypes.GET_KIHON_CHECK_FAILURE: {
      return {
        ...state,
        loading: false,
        data: {},
        type: "",
      };
    }

    //getItemList
    case KihonCheckActionTypes.GET_KIHON_CHECK_ITEM_REQUEST: {
      return {
        ...state,
        itemLoading: true,
      };
    }
    case KihonCheckActionTypes.GET_KIHON_CHECK_ITEM_SUCCESS: {
      let resultItem = [];
      if (action.data && action.data.length > 0)
        resultItem = action.data.sort((a, b) => a.no - b.no);
      return {
        ...state,
        itemLoading: false,
        itemList: resultItem ? resultItem : action.data,
      };
    }
    case KihonCheckActionTypes.GET_KIHON_CHECK_ITEM_FAILURE: {
      return {
        ...state,
        itemLoading: false,
        itemList: [],
      };
    }

    // create
    case KihonCheckActionTypes.CREATE_KIHON_CHECK_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case KihonCheckActionTypes.CREATE_KIHON_CHECK_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }

    case KihonCheckActionTypes.CREATE_KIHON_CHECK_FAILURE: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // update
    case KihonCheckActionTypes.UPDATE_KIHON_CHECK_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case KihonCheckActionTypes.UPDATE_KIHON_CHECK_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
        type: "",
      };
    }
    case KihonCheckActionTypes.UPDATE_KIHON_CHECK_FAILURE: {
      return {
        ...state,
        loading: false,
        data: {},
        type: "",
      };
    }

    // remove
    case KihonCheckActionTypes.REMOVE_KIHON_CHECK_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case KihonCheckActionTypes.REMOVE_KIHON_CHECK_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case KihonCheckActionTypes.REMOVE_KIHON_CHECK_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    case KihonCheckActionTypes.GET_ALL_KIHON_CHECK_QUOTE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case KihonCheckActionTypes.GET_ALL_KIHON_CHECK_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        listQuote: action.data,
      };
    case KihonCheckActionTypes.GET_ALL_KIHON_CHECK_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        listQuote: [],
      };

    case KihonCheckActionTypes.RESET_CURRENT_KiHON_CHECK:
      return {
        ...state,
        data: {},
        loading: false,
      };
    case KihonCheckActionTypes.NEW_PAGE:
      return {
        ...state,
        data: {},
      }
    default: {
      return { ...state };
    }
  }
}

export default kihonCheck;
