
import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";
import _ from "lodash";

export const life_Rehabili_InputFormPDF = (data, jobList) => {
  const { rehabiliPlan, assessMMSE, adlItems, adlitemAnswerNaiyou, adlKankyou, saisinRecord, icfMaster, needItem, IADL, sienNaiyou, menu } = data;


  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];

  const generateGenderString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += item.label + " ";
      }
    });
    return result;
  };

  const formatJob = (num) => {
    let result = "";

    if (num && jobList?.naiyou?.split(";").length > 0) {
      jobList.naiyou?.split(";").map((item) => {
        if (item?.split(":")[0] == num) {
          result = item?.split(":")[1];
        }
      });
    }
    return result ?? "";
  };


  const byoumeiGenerate = (string, options) => {
    let result = "";
    string.split(",").map((code) => {
      const obj = options?.find(item => item.ikosakiCd === code);
      if (obj) result += obj.syoubyoumei + "\n"
    })

    return result;
  }

  const getMMSE = (datas, items, option, key) => {
    let result = "";
    const obj = items.filter((item) => option.includes(item.naiyou));

    obj?.map((i) => {
      datas?.map((j) => {
        if (i.id === j.rehaAssessAdlItemId) {
          
          if (key === "title") {
            option.split(",")?.forEach((text) => {
              if (text === i.naiyou) {
                result += "■" + text;
              } else {
                result += "□" + text;
              }
            })
          }
          if (key === "naiyou") {
            result = `${j?.answer ?? ""}${j?.comment ? `(${j.comment})` : ""}`;
          }
        }
      })
    })
    
    return result;
  }

  const getTitle = (items, num) => {
    let result = "";
    if (items?.length > 0) {
      items.map((item, index) => {
        if (num === index) {
          result = item.naiyou;
        }
      })
    }
    return result;
  }

  const getMMSENaiyou = (optins, text, data) => {
    let result = "";
    const id = optins.find((item) => text.includes(item.naiyou))?.id;
    if(id) {
      result = data.find((as) => as.rehaAssessAdlItemId === id);
    }
    if (text === "コミュニケーションの状況") {
      return `${result?.comment ?? ""}`;
    }
    return `${result?.answer ?? ""}${result?.comment ? `(${result.comment})` : ""}`;
  }
  // rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 0
  const getRehaAssessmentSubNaiyou = (datas, items, key, index) => {
    let result = "";
    if (datas?.length > 0 && items?.length > 0) {
      datas.map((data) => {
        if (data.rehaAssessAdlItemId === items[index].id) {
          result = data[key];
        }
      })
    }
    return result;
  }

  const getMMSETitle = (items, num) => {
    let result = "";
    if (items?.length > 0) {
      items.map((item, index) => {
        if (num === index) {
          result = item.naiyou;
        }
      })
    }
    return result;
  }

  const getTotalPoint = (datas, items) => {
    let result = 0;
    if (datas?.length > 0 && items?.length > 0) {
      items.map((item) => {
        datas.map((data) => {
          if (data.rehaAssessAdlItemId === item.id) {
            //一致したらdata.answerをポイントに変えて足す
            const answerArr = item.answerNaiyou.split(",");
            const pointArr = item.point.split(",");
            for (let i = 0; i < answerArr.length; i++) {
              if (answerArr[i] === data.answer) {
                return result += parseInt(pointArr[i]);
              }
            }
          }
        })
      })
    }
    return result.toString();
  }

  const getMasterNaiyou = (string, items, naiyou) => {
    let result = "";
    if (string && items?.length > 0) {
      string.split(",").map((num) => {
        if (naiyou === "icf") {
          const obj = items.find((item) => item.icfCd === num)
          if (obj)  result += obj.koui + "\n"
        } else if (naiyou === "need") {
          const obj = items.find((item) => item.cd === num)
          if (obj)  result += obj.naiyou + "\n"
        }
      })
    }
    return result;
  }

  const getRehabiliPlanSub = (datas, key, index) => {
    let result = "";

    const getSienNaiyou = (string, data) => {
      let sien = "";
      if (string && sienNaiyou?.length > 0) {

        string?.split(",")?.map((st) => {
          sienNaiyou.map((item) => {
            if (item.sienCd === st) {
              if(sien) {
                sien += "," + item.saiKomoku
              } else {
                sien = item.saiKomoku
              }
            }
          })
        })
      }
      if(sien) {
        sien += "\n" + data.sienNaiyou;
      } else {
        sien = data.sienNaiyou;
      }
      return sien;
    }

    const getIcf = (string) => {
      let icf = "";
      if(string && icfMaster?.length > 0) {
        icfMaster.map((item) => {
          if (item.icfCd === string) {
            icf = item.koui
          }
        })
      }
      return icf;
    }

    if (datas && datas.length > index) {
      let result2 = "";
      const obj = datas[index]
      if (obj) result = obj[key]?.toString() ?? "";
      if (key === "mokuhyou") {
        result = getIcf(obj.mokuhyouIcfcd) + "\n" + result;
      }
      if (key === "rehaProgram") {
        result.split(",").map((st) => {
          result2 += formatJob(st) + "\n"
        })
        result = result2;
      }
      if (key === "sienCds") {
        result = getSienNaiyou(result, obj) + "\n"
      }
      if (key === "hindo") {
        result = result ? "週" + result + "回" : "";
      }
      if (key === "jikan") {
        result = result ? result + "\n" + "分/回" : "";
      }
    }
    return result;
  }

  const getSystemName = (sn) => {
    if (sn === "rehabili") {
      return "通所";
    } else if (sn === "visitreha") {
      return "訪問";
    } else if (sn === "rouken") {
      return "入所";
    }
  }

  if (typeof rehabiliPlan === 'object' && Array.isArray(rehabiliPlan)) {
    let sampledata = [];
    rehabiliPlan?.map((rehabiliPlan) => {
      sampledata.push({
        事業所番号: rehabiliPlan?.serviceKikanMaster?.jigyousyoNo ?? "",
        システム名: getSystemName(menu?.sn) ?? "",
        計画作成日: formatSeireki(rehabiliPlan?.date) ?? "",
        利用者名: rehabiliPlan?.riyousya?.name ?? "",
        性別: `${generateGenderString(GENDER_OPTIONS, rehabiliPlan?.riyousya?.gender ?? "")}`,
        生年月日: formatBirthday(rehabiliPlan?.riyousya?.birthday) ?? "",
        要介護度: rehabiliPlan?.riyouKaigohoken?.kaigodoMaster?.kaigodoName ?? "",
        リハビリテーション担当医: `${rehabiliPlan?.doctorMaster?.name ?? ""}　${rehabiliPlan?.doctorMaster?.hospital ?? ""}` ?? "",
        リハ担当者: `${rehabiliPlan?.tantoMaster?.name ?? ""}　${formatJob(rehabiliPlan?.tantoMaster?.jobCategory) ?? ""}` ?? "",
        利用者の希望: rehabiliPlan?.honinKibo ?? "",
        ご家族の希望: rehabiliPlan?.kazokuKibo ?? "",
        原疾患名: rehabiliPlan?.shikkan ?? "",
        発症日: formatSeireki(rehabiliPlan.hasshoDate) ?? "",
        入院日: formatSeireki(rehabiliPlan.nyuinDate) ?? "",
        退院日: formatSeireki(rehabiliPlan.taiinDate) ?? "",
        治療経過: rehabiliPlan?.keika ?? "",
        合併症のコントロール状況: byoumeiGenerate(rehabiliPlan?.gapeiShipeiCds, rehabiliPlan?.mediSyoubyoumeiMaster) ?? "",
        これまでのリハビリテーション実施状況: rehabiliPlan?.jisshijoukyou ?? "",
        日常生活自立度: rehabiliPlan?.jiritudoSyougai ?? "",
        認知度: rehabiliPlan?.jirutudoNinti ?? "",
        "筋力低下(状況)": rehabiliPlan?.kinryokuTeika?.split(",")[0] ?? "",
        "筋力低下(支障)": rehabiliPlan?.kinryokuTeika?.split(",")[1] ?? "",
        "筋力低下(見込み)": rehabiliPlan?.kinryokuTeika?.split(",")[2] ?? "",
        "麻痺(状況)": rehabiliPlan?.mahi?.split(",")[0] ?? "",
        "麻痺(支障)": rehabiliPlan?.mahi?.split(",")[1] ?? "",
        "麻痺(見込み)": rehabiliPlan?.mahi?.split(",")[2] ?? "",
        "感覚機能障害(状況)": rehabiliPlan?.kankakukinoShogai?.split(",")[0] ?? "",
        "感覚機能障害(支障)": rehabiliPlan?.kankakukinoShogai?.split(",")[1] ?? "",
        "感覚機能障害(見込み)": rehabiliPlan?.kankakukinoShogai?.split(",")[2] ?? "",
        "関節可動域制限(状況)": rehabiliPlan?.kansetuKoushuku?.split(",")[0] ?? "",
        "関節可動域制限(支障)": rehabiliPlan?.kansetuKoushuku?.split(",")[1] ?? "",
        "関節可動域制限(見込み)": rehabiliPlan?.kansetuKoushuku?.split(",")[2] ?? "",
        "摂食嚥下障害(状況)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[0] ?? "",
        "摂食嚥下障害(支障)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[1] ?? "",
        "摂食嚥下障害(見込み)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[2] ?? "",
        "失語症構音障害(状況)": rehabiliPlan?.situgoKoonShogai?.split(",")[0] ?? "",
        "失語症構音障害(支障)": rehabiliPlan?.situgoKoonShogai?.split(",")[1] ?? "",
        "失語症構音障害(見込み)": rehabiliPlan?.situgoKoonShogai?.split(",")[2] ?? "",
        "見当識障害(状況)": rehabiliPlan?.kentosikiShogai?.split(",")[0] ?? "",
        "見当識障害(支障)": rehabiliPlan?.kentosikiShogai?.split(",")[1] ?? "",
        "見当識障害(見込み)": rehabiliPlan?.kentosikiShogai?.split(",")[2] ?? "",
        "記憶障害(状況)": rehabiliPlan?.kiokuShogai?.split(",")[0] ?? "",
        "記憶障害(支障)": rehabiliPlan?.kiokuShogai?.split(",")[1] ?? "",
        "記憶障害(見込み)": rehabiliPlan?.kiokuShogai?.split(",")[2] ?? "",
        "その他の高次脳機能障害(状況)": rehabiliPlan?.kojinoShogai?.split(",")[0] ?? "",
        "その他の高次脳機能障害(支障)": rehabiliPlan?.kojinoShogai?.split(",")[1] ?? "",
        "その他の高次脳機能障害(見込み)": rehabiliPlan?.kojinoShogai?.split(",")[2] ?? "",
        "栄養障害(状況)": rehabiliPlan?.eiyoShogai?.split(",")[0] ?? "",
        "栄養障害(支障)": rehabiliPlan?.eiyoShogai?.split(",")[1] ?? "",
        "栄養障害(見込み)": rehabiliPlan?.eiyoShogai?.split(",")[2] ?? "",
        "褥瘡(状況)": rehabiliPlan?.jokuso?.split(",")[0] ?? "",
        "褥瘡(支障)": rehabiliPlan?.jokuso?.split(",")[1] ?? "",
        "褥瘡(見込み)": rehabiliPlan?.jokuso?.split(",")[2] ?? "",
        "疼痛(状況)": rehabiliPlan?.totu?.split(",")[0] ?? "",
        "疼痛(支障)": rehabiliPlan?.totu?.split(",")[1] ?? "",
        "疼痛(見込み)": rehabiliPlan?.totu?.split(",")[2] ?? "",
        "精神行動障害(状況)": rehabiliPlan?.seisinkodoShogai?.split(",")[0] ?? "",
        "精神行動障害(支障)": rehabiliPlan?.seisinkodoShogai?.split(",")[1] ?? "",
        "精神行動障害(見込み)": rehabiliPlan?.seisinkodoShogai?.split(",")[2] ?? "",
        心身機能1: getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "title") ?? "",
        "心身機能1(内容)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "naiyou") ?? "",
        服薬管理: getMMSENaiyou(assessMMSE, "服薬管理", rehabiliPlan?.rehaAssessSub) ?? "",
        心身機能2: getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "title") ?? "",
        "心身機能2(内容)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "naiyou") ?? "",
        コミュニケーションの状況: getMMSENaiyou(assessMMSE, "コミュニケーションの状況", rehabiliPlan?.rehaAssessSub) ?? "",
        MMSE1: `${getMMSETitle(adlitemAnswerNaiyou, 0)}`,
        "MMSE1(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 0) ?? "",
        "MMSE1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 0) ?? "",
        "MMSE1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 0) ?? "",
        MMSE2: getMMSETitle(adlitemAnswerNaiyou, 1),
        "MMSE2(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 1) ?? "",
        "MMSE2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 1) ?? "",
        "MMSE2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 1) ?? "",
        MMSE3: getMMSETitle(adlitemAnswerNaiyou, 2),
        "MMSE3(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 2) ?? "",
        "MMSE3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 2) ?? "",
        "MMSE3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 2) ?? "",
        MMSE4: getMMSETitle(adlitemAnswerNaiyou, 3),
        "MMSE4(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 3) ?? "",
        "MMSE4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 3) ?? "",
        "MMSE4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 3) ?? "",
        MMSE5: getMMSETitle(adlitemAnswerNaiyou, 4),
        "MMSE5(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 4) ?? "",
        "MMSE5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 4) ?? "",
        "MMSE5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 4) ?? "",
        ADL1: getTitle(adlItems, 0),
        "ADL1(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 0) ?? "",
        "ADL1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 0) ?? "",
        "ADL1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 0) ?? "",
        ADL2: getTitle(adlItems, 1),
        "ADL2(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 1) ?? "",
        "ADL2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 1) ?? "",
        "ADL2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 1) ?? "",
        ADL3: getTitle(adlItems, 2),
        "ADL3(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 2) ?? "",
        "ADL3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 2) ?? "",
        "ADL3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 2) ?? "",
        ADL4: getTitle(adlItems, 3),
        "ADL4(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 3) ?? "",
        "ADL4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 3) ?? "",
        "ADL4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 3) ?? "",
        ADL5: getTitle(adlItems, 4),
        "ADL5(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 4) ?? "",
        "ADL5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 4) ?? "",
        "ADL5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 4) ?? "",
        ADL6: getTitle(adlItems, 5),
        "ADL6(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 5) ?? "",
        "ADL6(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 5) ?? "",
        "ADL6(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 5) ?? "",
        ADL7: getTitle(adlItems, 6),
        "ADL7(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 6) ?? "",
        "ADL7(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 6) ?? "",
        "ADL7(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 6) ?? "",
        ADL8: getTitle(adlItems, 7),
        "ADL8(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 7) ?? "",
        "ADL8(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 7) ?? "",
        "ADL8(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 7) ?? "",
        ADL9: getTitle(adlItems, 8),
        "ADL9(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 8) ?? "",
        "ADL9(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 8) ?? "",
        "ADL9(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 8) ?? "",
        ADL10: getTitle(adlItems, 9),
        "ADL10(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 9) ?? "",
        "ADL10(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 9) ?? "",
        "ADL10(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 9) ?? "",
        "ADL合計点(リハ開始時)": getTotalPoint(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems) ?? "",
        "ADL合計点(現在の状況)": getTotalPoint(rehabiliPlan?.rehaAssessSub, adlItems) ?? "",
        "リハビリテーション短期目標(心身機能)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouFunction, icfMaster, "icf") ?? "",
        "リハビリテーション短期目標(活動)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouActivity, icfMaster, "icf") ?? "",
        "リハビリテーション短期目標(参加)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouJoin, icfMaster, "icf") ?? "",
        "リハビリテーション長期目標(心身機能)": getMasterNaiyou(rehabiliPlan?.longMokuhyouFunction, icfMaster, "icf") ?? "",
        "リハビリテーション長期目標(活動)": getMasterNaiyou(rehabiliPlan?.longMokuhyouActivity, icfMaster, "icf") ?? "",
        "リハビリテーション長期目標(参加)": getMasterNaiyou(rehabiliPlan?.longMokuhyouJoin, icfMaster, "icf") ?? "",
        "リハビリテーションの方針(今後3ヶ月間)": rehabiliPlan?.rehaShortMokuhyou ?? "",
        "本人・家族への生活指導の内容": rehabiliPlan?.lifeGuidance ?? "",
        リハビリテーション実施上の留意点: rehabiliPlan?.rehaRyui ?? "",
        "リハビリテーションの見通し・継続理由": rehabiliPlan?.review ?? "",
        "リハビリテーション(終了の目安となる時期)": rehabiliPlan?.timeEstimate?.toString() ?? "",
        "リハビリテーション終了の目安・時期": rehabiliPlan?.rehaMeyasu ?? "",
        //2ページ
        計画作成日2: formatSeireki(rehabiliPlan?.rehabiliPlan[0]?.date) ?? "",
        見直し予定時期: formatSeireki(rehabiliPlan?.rehabiliPlan[0]?.date) ?? "",
        "家族・介護者(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 0) ?? "",
        "家族・介護者(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 0) ?? "",
        "福祉用具等(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 1) ?? "",
        "福祉用具等(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 1) ?? "",
        "福祉用具等(調整)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "kaizenKanosei", 1) ?? "",
        "住環境(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 2) ?? "",
        "住環境(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 2) ?? "",
        "住環境(調整)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "kaizenKanosei", 2) ?? "",
        "自宅周辺(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 3) ?? "",
        "自宅周辺(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 3) ?? "",
        "地域への社会参加等(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 4) ?? "",
        "地域への社会参加等(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 4) ?? "",
        "交通機関の利用(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 5) ?? "",
        "交通機関の利用(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 5) ?? "",
        "サービスの利用(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 6) ?? "",
        "サービスの利用(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 6) ?? "",
        "その他(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 7) ?? "",
        "その他(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 7) ?? "",
        家庭内の役割の内容: getMasterNaiyou(rehabiliPlan?.kateinaiYakuwariCds, needItem, "need") ?? "",
        "余暇活動(内容及び頻度)": getMasterNaiyou(rehabiliPlan?.yokaKatudoCds, needItem, "need") ?? "",
        "社会・地域活動(内容及び頻度)": getMasterNaiyou(rehabiliPlan?.shakaiKatudoCds, needItem, "need") ?? "",
        リハビリテーション終了後に行いたい社会参加等の取組: getMasterNaiyou(rehabiliPlan?.shakaiSankaCds, needItem, "need") ?? "",
        "IADL1": getTitle(IADL, 0) ?? "",
        "IADL1(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 0) ?? "",
        "IADL1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 0) ?? "",
        "IADL1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 0) ?? "",
        "IADL2": getTitle(IADL, 1) ?? "",
        "IADL2(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 1) ?? "",
        "IADL2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 1) ?? "",
        "IADL2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 1) ?? "",
        "IADL3": getTitle(IADL, 2) ?? "",
        "IADL3(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 2) ?? "",
        "IADL3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 2) ?? "",
        "IADL3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 2) ?? "",
        "IADL4": getTitle(IADL, 3) ?? "",
        "IADL4(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 3) ?? "",
        "IADL4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 3) ?? "",
        "IADL4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 3) ?? "",
        "IADL5": getTitle(IADL, 4) ?? "",
        "IADL5(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 4) ?? "",
        "IADL5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 4) ?? "",
        "IADL5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 4) ?? "",
        "IADL6": getTitle(IADL, 5) ?? "",
        "IADL6(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 5) ?? "",
        "IADL6(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 5) ?? "",
        "IADL6(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 5) ?? "",
        "IADL7": getTitle(IADL, 6) ?? "",
        "IADL7(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 6) ?? "",
        "IADL7(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 6) ?? "",
        "IADL7(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 6) ?? "",
        "IADL8": getTitle(IADL, 7) ?? "",
        "IADL8(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 7) ?? "",
        "IADL8(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 7) ?? "",
        "IADL8(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 7) ?? "",
        "IADL9": getTitle(IADL, 8) ?? "",
        "IADL9(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 8) ?? "",
        "IADL9(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 8) ?? "",
        "IADL9(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 8) ?? "",
        "IADL10": getTitle(IADL, 9) ?? "",
        "IADL10(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 9) ?? "",
        "IADL10(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 9) ?? "",
        "IADL10(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 9) ?? "",
        "IADL11": getTitle(IADL, 10) ?? "",
        "IADL11(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 10) ?? "",
        "IADL11(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 10) ?? "",
        "IADL11(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 10) ?? "",
        "IADL12": getTitle(IADL, 11) ?? "",
        "IADL12(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 11) ?? "",
        "IADL12(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 11) ?? "",
        "IADL12(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 11) ?? "",
        "IADL13": getTitle(IADL, 12) ?? "",
        "IADL13(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 12) ?? "",
        "IADL13(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 12) ?? "",
        "IADL13(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 12) ?? "",
        "IADL14": getTitle(IADL, 13) ?? "",
        "IADL14(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 13) ?? "",
        "IADL14(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 13) ?? "",
        "IADL14(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 13) ?? "",
        "IADL15": getTitle(IADL, 14) ?? "",
        "IADL15(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 14) ?? "",
        "IADL15(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 14) ?? "",
        "IADL15(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 14) ?? "",
        "IADL合計点(リハ開始時)": getTotalPoint(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL) ?? "",
        "IADL合計点(現在の状況)": getTotalPoint(rehabiliPlan?.rehaAssessSub, IADL) ?? "",
        活動と参加において重要性の高い課題: rehabiliPlan?.kadaiYoinBunseki ?? "",
        活動と参加に影響を及ぼす機能障害の課題: rehabiliPlan?.kadaiYoinBunseki2 ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因: rehabiliPlan?.kadaiYoinBunseki3 ?? "",
        "訪問・通所頻度": rehabiliPlan?.rehabiliPlan[0]?.hindo ?? "",
        利用時間: rehabiliPlan?.rehabiliPlan[0]?.riyoJikan ?? "",
        "1No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 0) ?? "",
        "1目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 0) ?? "",
        "1期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 0) ?? "",
        "1担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 0) ?? "",
        "1具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 0) ?? "",
        "1頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 0) ?? "",
        "1時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 0) ?? "",
        "2No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 1) ?? "",
        "2目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 1) ?? "",
        "2期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 1) ?? "",
        "2担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 1) ?? "",
        "2具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 1) ?? "",
        "2頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 1) ?? "",
        "2時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 1) ?? "",
        "3No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 2) ?? "",
        "3目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 2) ?? "",
        "3期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 2) ?? "",
        "3担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 2) ?? "",
        "3具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 2) ?? "",
        "3頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 2) ?? "",
        "3時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 2) ?? "",
        "4No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 3) ?? "",
        "4目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 3) ?? "",
        "4期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 3) ?? "",
        "4担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 3) ?? "",
        "4具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 3) ?? "",
        "4頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 3) ?? "",
        "4時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 3) ?? "",
        "5No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 4) ?? "",
        "5目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 4) ?? "",
        "5期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 4) ?? "",
        "5担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 4) ?? "",
        "5具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 4) ?? "",
        "5頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 4) ?? "",
        "5時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 4) ?? "",
        週合計時間: rehabiliPlan?.rehabiliPlan[0]?.jikanTotal ?? "",
        他事業所の担当者と共有すべき事項: rehabiliPlan?.rehabiliPlan[0]?.careShare ?? "",
        介護支援専門員と共有すべき事項: rehabiliPlan?.rehabiliPlan[0]?.caremaneShare ?? "",
        "その他、共有すべき事項": rehabiliPlan?.rehabiliPlan[0]?.otherShare ?? "",
        情報提供先: rehabiliPlan?.rehabiliPlan[0]?.informationProviding ?? "",
      })
    })
    return sampledata
  } else if (typeof rehabiliPlan === 'object') {
    let sampledata = [
      {
        事業所番号: rehabiliPlan?.serviceKikanMaster?.jigyousyoNo ?? "",
        システム名: getSystemName(menu?.sn) ?? "",
        計画作成日: formatSeireki(rehabiliPlan?.date) ?? "",
        利用者名: rehabiliPlan?.riyousya?.name ?? "",
        性別: `${generateGenderString(GENDER_OPTIONS, rehabiliPlan?.riyousya?.gender ?? "")}`,
        生年月日: formatBirthday(rehabiliPlan?.riyousya?.birthday) ?? "",
        要介護度: rehabiliPlan?.riyouKaigohoken?.kaigodoMaster?.kaigodoName ?? "",
        リハビリテーション担当医: `${rehabiliPlan?.doctorMaster?.name ?? ""}　${rehabiliPlan?.doctorMaster?.hospital ?? ""}` ?? "",
        リハ担当者: `${rehabiliPlan?.tantoMaster?.name ?? ""}　${formatJob(rehabiliPlan?.tantoMaster?.jobCategory) ?? ""}` ?? "",
        利用者の希望: rehabiliPlan?.honinKibo ?? "",
        ご家族の希望: rehabiliPlan?.kazokuKibo ?? "",
        原疾患名: rehabiliPlan?.shikkan ?? "",
        発症日: formatSeireki(rehabiliPlan.hasshoDate) ?? "",
        入院日: formatSeireki(rehabiliPlan.nyuinDate) ?? "",
        退院日: formatSeireki(rehabiliPlan.taiinDate) ?? "",
        治療経過: rehabiliPlan?.keika ?? "",
        合併症のコントロール状況: byoumeiGenerate(rehabiliPlan?.gapeiShipeiCds, rehabiliPlan?.mediSyoubyoumeiMaster) ?? "",
        これまでのリハビリテーション実施状況: rehabiliPlan?.jisshijoukyou ?? "",
        日常生活自立度: rehabiliPlan?.jiritudoSyougai ?? "",
        認知度: rehabiliPlan?.jirutudoNinti ?? "",
        "筋力低下(状況)": rehabiliPlan?.kinryokuTeika?.split(",")[0] ?? "",
        "筋力低下(支障)": rehabiliPlan?.kinryokuTeika?.split(",")[1] ?? "",
        "筋力低下(見込み)": rehabiliPlan?.kinryokuTeika?.split(",")[2] ?? "",
        "麻痺(状況)": rehabiliPlan?.mahi?.split(",")[0] ?? "",
        "麻痺(支障)": rehabiliPlan?.mahi?.split(",")[1] ?? "",
        "麻痺(見込み)": rehabiliPlan?.mahi?.split(",")[2] ?? "",
        "感覚機能障害(状況)": rehabiliPlan?.kankakukinoShogai?.split(",")[0] ?? "",
        "感覚機能障害(支障)": rehabiliPlan?.kankakukinoShogai?.split(",")[1] ?? "",
        "感覚機能障害(見込み)": rehabiliPlan?.kankakukinoShogai?.split(",")[2] ?? "",
        "関節可動域制限(状況)": rehabiliPlan?.kansetuKoushuku?.split(",")[0] ?? "",
        "関節可動域制限(支障)": rehabiliPlan?.kansetuKoushuku?.split(",")[1] ?? "",
        "関節可動域制限(見込み)": rehabiliPlan?.kansetuKoushuku?.split(",")[2] ?? "",
        "摂食嚥下障害(状況)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[0] ?? "",
        "摂食嚥下障害(支障)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[1] ?? "",
        "摂食嚥下障害(見込み)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[2] ?? "",
        "失語症構音障害(状況)": rehabiliPlan?.situgoKoonShogai?.split(",")[0] ?? "",
        "失語症構音障害(支障)": rehabiliPlan?.situgoKoonShogai?.split(",")[1] ?? "",
        "失語症構音障害(見込み)": rehabiliPlan?.situgoKoonShogai?.split(",")[2] ?? "",
        "見当識障害(状況)": rehabiliPlan?.kentosikiShogai?.split(",")[0] ?? "",
        "見当識障害(支障)": rehabiliPlan?.kentosikiShogai?.split(",")[1] ?? "",
        "見当識障害(見込み)": rehabiliPlan?.kentosikiShogai?.split(",")[2] ?? "",
        "記憶障害(状況)": rehabiliPlan?.kiokuShogai?.split(",")[0] ?? "",
        "記憶障害(支障)": rehabiliPlan?.kiokuShogai?.split(",")[1] ?? "",
        "記憶障害(見込み)": rehabiliPlan?.kiokuShogai?.split(",")[2] ?? "",
        "その他の高次脳機能障害(状況)": rehabiliPlan?.kojinoShogai?.split(",")[0] ?? "",
        "その他の高次脳機能障害(支障)": rehabiliPlan?.kojinoShogai?.split(",")[1] ?? "",
        "その他の高次脳機能障害(見込み)": rehabiliPlan?.kojinoShogai?.split(",")[2] ?? "",
        "栄養障害(状況)": rehabiliPlan?.eiyoShogai?.split(",")[0] ?? "",
        "栄養障害(支障)": rehabiliPlan?.eiyoShogai?.split(",")[1] ?? "",
        "栄養障害(見込み)": rehabiliPlan?.eiyoShogai?.split(",")[2] ?? "",
        "褥瘡(状況)": rehabiliPlan?.jokuso?.split(",")[0] ?? "",
        "褥瘡(支障)": rehabiliPlan?.jokuso?.split(",")[1] ?? "",
        "褥瘡(見込み)": rehabiliPlan?.jokuso?.split(",")[2] ?? "",
        "疼痛(状況)": rehabiliPlan?.totu?.split(",")[0] ?? "",
        "疼痛(支障)": rehabiliPlan?.totu?.split(",")[1] ?? "",
        "疼痛(見込み)": rehabiliPlan?.totu?.split(",")[2] ?? "",
        "精神行動障害(状況)": rehabiliPlan?.seisinkodoShogai?.split(",")[0] ?? "",
        "精神行動障害(支障)": rehabiliPlan?.seisinkodoShogai?.split(",")[1] ?? "",
        "精神行動障害(見込み)": rehabiliPlan?.seisinkodoShogai?.split(",")[2] ?? "",
        心身機能1: getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "title") ?? "",
        "心身機能1(内容)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "naiyou") ?? "",
        服薬管理: getMMSENaiyou(assessMMSE, "服薬管理", rehabiliPlan?.rehaAssessSub) ?? "",
        心身機能2: getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "title") ?? "",
        "心身機能2(内容)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "naiyou") ?? "",
        コミュニケーションの状況: getMMSENaiyou(assessMMSE, "コミュニケーションの状況", rehabiliPlan?.rehaAssessSub) ?? "",
        MMSE1: `${getMMSETitle(adlitemAnswerNaiyou, 0)}`,
        "MMSE1(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 0) ?? "",
        "MMSE1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 0) ?? "",
        "MMSE1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 0) ?? "",
        MMSE2: getMMSETitle(adlitemAnswerNaiyou, 1),
        "MMSE2(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 1) ?? "",
        "MMSE2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 1) ?? "",
        "MMSE2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 1) ?? "",
        MMSE3: getMMSETitle(adlitemAnswerNaiyou, 2),
        "MMSE3(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 2) ?? "",
        "MMSE3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 2) ?? "",
        "MMSE3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 2) ?? "",
        MMSE4: getMMSETitle(adlitemAnswerNaiyou, 3),
        "MMSE4(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 3) ?? "",
        "MMSE4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 3) ?? "",
        "MMSE4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 3) ?? "",
        MMSE5: getMMSETitle(adlitemAnswerNaiyou, 4),
        "MMSE5(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 4) ?? "",
        "MMSE5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 4) ?? "",
        "MMSE5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 4) ?? "",
        ADL1: getTitle(adlItems, 0),
        "ADL1(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 0) ?? "",
        "ADL1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 0) ?? "",
        "ADL1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 0) ?? "",
        ADL2: getTitle(adlItems, 1),
        "ADL2(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 1) ?? "",
        "ADL2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 1) ?? "",
        "ADL2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 1) ?? "",
        ADL3: getTitle(adlItems, 2),
        "ADL3(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 2) ?? "",
        "ADL3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 2) ?? "",
        "ADL3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 2) ?? "",
        ADL4: getTitle(adlItems, 3),
        "ADL4(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 3) ?? "",
        "ADL4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 3) ?? "",
        "ADL4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 3) ?? "",
        ADL5: getTitle(adlItems, 4),
        "ADL5(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 4) ?? "",
        "ADL5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 4) ?? "",
        "ADL5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 4) ?? "",
        ADL6: getTitle(adlItems, 5),
        "ADL6(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 5) ?? "",
        "ADL6(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 5) ?? "",
        "ADL6(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 5) ?? "",
        ADL7: getTitle(adlItems, 6),
        "ADL7(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 6) ?? "",
        "ADL7(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 6) ?? "",
        "ADL7(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 6) ?? "",
        ADL8: getTitle(adlItems, 7),
        "ADL8(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 7) ?? "",
        "ADL8(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 7) ?? "",
        "ADL8(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 7) ?? "",
        ADL9: getTitle(adlItems, 8),
        "ADL9(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 8) ?? "",
        "ADL9(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 8) ?? "",
        "ADL9(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 8) ?? "",
        ADL10: getTitle(adlItems, 9),
        "ADL10(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 9) ?? "",
        "ADL10(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 9) ?? "",
        "ADL10(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 9) ?? "",
        "ADL合計点(リハ開始時)": getTotalPoint(saisinRecord?.rehaAssessSub, adlItems) ?? "",
        "ADL合計点(現在の状況)": getTotalPoint(rehabiliPlan?.rehaAssessSub, adlItems) ?? "",
        "リハビリテーション短期目標(心身機能)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouFunction, icfMaster, "icf") ?? "",
        "リハビリテーション短期目標(活動)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouActivity, icfMaster, "icf") ?? "",
        "リハビリテーション短期目標(参加)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouJoin, icfMaster, "icf") ?? "",
        "リハビリテーション長期目標(心身機能)": getMasterNaiyou(rehabiliPlan?.longMokuhyouFunction, icfMaster, "icf") ?? "",
        "リハビリテーション長期目標(活動)": getMasterNaiyou(rehabiliPlan?.longMokuhyouActivity, icfMaster, "icf") ?? "",
        "リハビリテーション長期目標(参加)": getMasterNaiyou(rehabiliPlan?.longMokuhyouJoin, icfMaster, "icf") ?? "",
        "リハビリテーションの方針(今後3ヶ月間)": rehabiliPlan?.rehaShortMokuhyou ?? "",
        "本人・家族への生活指導の内容": rehabiliPlan?.lifeGuidance ?? "",
        リハビリテーション実施上の留意点: rehabiliPlan?.rehaRyui ?? "",
        "リハビリテーションの見通し・継続理由": rehabiliPlan?.review ?? "",
        "リハビリテーション(終了の目安となる時期)": rehabiliPlan?.timeEstimate?.toString() ?? "",
        "リハビリテーション終了の目安・時期": rehabiliPlan?.rehaMeyasu ?? "",
        //2ページ
        計画作成日2: formatSeireki(rehabiliPlan?.rehabiliPlan[0]?.date) ?? "",
        見直し予定時期: formatSeireki(rehabiliPlan?.rehabiliPlan[0]?.date) ?? "",
        "家族・介護者(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 0) ?? "",
        "家族・介護者(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 0) ?? "",
        "福祉用具等(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 1) ?? "",
        "福祉用具等(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 1) ?? "",
        "福祉用具等(調整)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "kaizenKanosei", 1) ?? "",
        "住環境(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 2) ?? "",
        "住環境(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 2) ?? "",
        "住環境(調整)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "kaizenKanosei", 2) ?? "",
        "自宅周辺(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 3) ?? "",
        "自宅周辺(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 3) ?? "",
        "地域への社会参加等(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 4) ?? "",
        "地域への社会参加等(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 4) ?? "",
        "交通機関の利用(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 5) ?? "",
        "交通機関の利用(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 5) ?? "",
        "サービスの利用(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 6) ?? "",
        "サービスの利用(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 6) ?? "",
        "その他(課題)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "answer", 7) ?? "",
        "その他(状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlKankyou, "comment", 7) ?? "",
        家庭内の役割の内容: getMasterNaiyou(rehabiliPlan?.kateinaiYakuwariCds, needItem, "need") ?? "",
        "余暇活動(内容及び頻度)": getMasterNaiyou(rehabiliPlan?.yokaKatudoCds, needItem, "need") ?? "",
        "社会・地域活動(内容及び頻度)": getMasterNaiyou(rehabiliPlan?.shakaiKatudoCds, needItem, "need") ?? "",
        リハビリテーション終了後に行いたい社会参加等の取組: getMasterNaiyou(rehabiliPlan?.shakaiSankaCds, needItem, "need") ?? "",
        "IADL1": getTitle(IADL, 0) ?? "",
        "IADL1(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 0) ?? "",
        "IADL1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 0) ?? "",
        "IADL1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 0) ?? "",
        "IADL2": getTitle(IADL, 1) ?? "",
        "IADL2(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 1) ?? "",
        "IADL2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 1) ?? "",
        "IADL2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 1) ?? "",
        "IADL3": getTitle(IADL, 2) ?? "",
        "IADL3(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 2) ?? "",
        "IADL3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 2) ?? "",
        "IADL3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 2) ?? "",
        "IADL4": getTitle(IADL, 3) ?? "",
        "IADL4(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 3) ?? "",
        "IADL4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 3) ?? "",
        "IADL4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 3) ?? "",
        "IADL5": getTitle(IADL, 4) ?? "",
        "IADL5(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 4) ?? "",
        "IADL5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 4) ?? "",
        "IADL5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 4) ?? "",
        "IADL6": getTitle(IADL, 5) ?? "",
        "IADL6(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 5) ?? "",
        "IADL6(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 5) ?? "",
        "IADL6(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 5) ?? "",
        "IADL7": getTitle(IADL, 6) ?? "",
        "IADL7(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 6) ?? "",
        "IADL7(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 6) ?? "",
        "IADL7(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 6) ?? "",
        "IADL8": getTitle(IADL, 7) ?? "",
        "IADL8(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 7) ?? "",
        "IADL8(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 7) ?? "",
        "IADL8(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 7) ?? "",
        "IADL9": getTitle(IADL, 8) ?? "",
        "IADL9(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 8) ?? "",
        "IADL9(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 8) ?? "",
        "IADL9(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 8) ?? "",
        "IADL10": getTitle(IADL, 9) ?? "",
        "IADL10(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 9) ?? "",
        "IADL10(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 9) ?? "",
        "IADL10(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 9) ?? "",
        "IADL11": getTitle(IADL, 10) ?? "",
        "IADL11(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 10) ?? "",
        "IADL11(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 10) ?? "",
        "IADL11(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 10) ?? "",
        "IADL12": getTitle(IADL, 11) ?? "",
        "IADL12(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 11) ?? "",
        "IADL12(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 11) ?? "",
        "IADL12(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 11) ?? "",
        "IADL13": getTitle(IADL, 12) ?? "",
        "IADL13(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 12) ?? "",
        "IADL13(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 12) ?? "",
        "IADL13(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 12) ?? "",
        "IADL14": getTitle(IADL, 13) ?? "",
        "IADL14(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 13) ?? "",
        "IADL14(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 13) ?? "",
        "IADL14(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 13) ?? "",
        "IADL15": getTitle(IADL, 14) ?? "",
        "IADL15(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 14) ?? "",
        "IADL15(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 14) ?? "",
        "IADL15(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 14) ?? "",
        "IADL合計点(リハ開始時)": getTotalPoint(saisinRecord?.rehaAssessSub, IADL) ?? "",
        "IADL合計点(現在の状況)": getTotalPoint(rehabiliPlan?.rehaAssessSub, IADL) ?? "",
        活動と参加において重要性の高い課題: rehabiliPlan?.kadaiYoinBunseki ?? "",
        活動と参加に影響を及ぼす機能障害の課題: rehabiliPlan?.kadaiYoinBunseki2 ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因: rehabiliPlan?.kadaiYoinBunseki3 ?? "",
        "訪問・通所頻度": rehabiliPlan?.rehabiliPlan[0]?.hindo ?? "",
        利用時間: rehabiliPlan?.rehabiliPlan[0]?.riyoJikan ?? "",
        "1No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 0) ?? "",
        "1目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 0) ?? "",
        "1期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 0) ?? "",
        "1担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 0) ?? "",
        "1具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 0) ?? "",
        "1頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 0) ?? "",
        "1時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 0) ?? "",
        "2No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 1) ?? "",
        "2目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 1) ?? "",
        "2期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 1) ?? "",
        "2担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 1) ?? "",
        "2具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 1) ?? "",
        "2頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 1) ?? "",
        "2時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 1) ?? "",
        "3No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 2) ?? "",
        "3目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 2) ?? "",
        "3期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 2) ?? "",
        "3担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 2) ?? "",
        "3具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 2) ?? "",
        "3頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 2) ?? "",
        "3時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 2) ?? "",
        "4No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 3) ?? "",
        "4目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 3) ?? "",
        "4期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 3) ?? "",
        "4担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 3) ?? "",
        "4具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 3) ?? "",
        "4頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 3) ?? "",
        "4時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 3) ?? "",
        "5No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 4) ?? "",
        "5目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 4) ?? "",
        "5期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikan", 4) ?? "",
        "5担当職種": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "rehaProgram", 4) ?? "",
        "5具体的支援内容(何を目的に(~のために)~をする)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 4) ?? "",
        "5頻度": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 4) ?? "",
        "5時間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 4) ?? "",
        週合計時間: rehabiliPlan?.rehabiliPlan[0]?.jikanTotal ?? "",
        他事業所の担当者と共有すべき事項: rehabiliPlan?.rehabiliPlan[0]?.careShare ?? "",
        介護支援専門員と共有すべき事項: rehabiliPlan?.rehabiliPlan[0]?.caremaneShare ?? "",
        "その他、共有すべき事項": rehabiliPlan?.rehabiliPlan[0]?.otherShare ?? "",
        情報提供先: rehabiliPlan?.rehabiliPlan[0]?.informationProviding ?? "",
      },
    ];
    return sampledata;
  } else {
    return [{}];
  }
};
