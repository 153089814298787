export const DoctorMasterActionTypes = {
  DOCTOR_MASTER_SPIN_REQUEST: "DOCTOR_MASTER_SPIN_REQUEST",

  LOAD_DOCTOR_MASTER_SUCCESS: "LOAD_DOCTOR_MASTER_SUCCESS",
  LOAD_DOCTOR_MASTER_ERROR: "LOAD_DOCTOR_MASTER_ERROR",

  LOAD_MAX_SORT_SUCCESS: "LOAD_MAX_SORT_SUCCESS",
  LOAD_MAX_SORT_ERROR: "LOAD_MAX_SORT_ERROR",

  LOAD_DOCTOR_MASTER_INIT_SUCCESS: "LOAD_DOCTOR_MASTER_INIT_SUCCESS",
  LOAD_DOCTOR_MASTER_INIT_ERROR: "LOAD_DOCTOR_MASTER_INIT_ERROR",

  CREATE_DOCTOR_MASTER_SUCCESS: "CREATE_DOCTOR_MASTER_SUCCESS",
  CREATE_DOCTOR_MASTER_ERROR: "CREATE_DOCTOR_MASTER_ERROR",

  UPDATE_DOCTOR_MASTER_SUCCESS: "UPDATE_DOCTOR_MASTER_SUCCESS",
  UPDATE_LIST_DOCTOR_MASTER_SUCCESS: "UPDATE_LIST_DOCTOR_MASTER_SUCCESS",
  UPDATE_DOCTOR_MASTER_ERROR: "UPDATE_DOCTOR_MASTER_ERROR",
};
