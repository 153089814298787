import {
  TANTO_MASTER_LIST,
  TANTO_MASTER_LIST_KIKAN,
  TANTO_MASTER_LIST2,
  TANTO_MASTER_LIST_KIKAN_REQUEST,
} from "../../actions/tantoMasterListActions/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {};

export default function tantoMasterListReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TANTO_MASTER_LIST:
      return {
        ...state,
        tantoMaster: action.payload,
      };
    case TANTO_MASTER_LIST_KIKAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TANTO_MASTER_LIST_KIKAN:
      return {
        ...state,
        data: action.payload ? action.payload.data : action.payload,
        meta: action.payload ? action.payload.meta : action.payload,
        loading: false,
      };
    case TANTO_MASTER_LIST2:
      return {
        ...state,
        tantoMasters2: action.payload,
      };
    default:
      return state;
  }
}
