import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import { notification, Row, Button, Checkbox } from "antd";

import WrappedMoshiokuriForm from "./UI/MoshiokuriForm";
import ActionsRow from "./UI/ActionsRow";
import { getPath } from "../../common/function_common/functionCommon";

class KanriMoshiokuriLinkSave extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      moshiokuriId: parseInt(match.params.moshiokuriId),
      currentPage: props.currentMoshiokuri,
      pageSize: props.pageSizeMoshiokuri, // @TODO setting in moshiokuri,
      ids: props.moshiokuriSelectedRows,
      path: getPath(),
    };

    this.registerMoshiokuri = this.registerMoshiokuri.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    // load data
    const { ids, moshiokuriId } = this.state;

    this.props.getMoshiokuriSingleRecord(moshiokuriId, ids, null);
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent, kanriMoshiokuri } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }

    if (kanriMoshiokuri != oldProps.kanriMoshiokuri) {
      if (kanriMoshiokuri && kanriMoshiokuri.id) {
        this.setState({
          moshiokuriId: kanriMoshiokuri.id,
        });

        this.props.history.replace(
          this.state.path + "/kanri/moshiokurilink/" + kanriMoshiokuri.id
        );
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  async registerMoshiokuri(values) {
    if (values.id) {
      await this.props.updateMoshiokuri(values);
    } else {
      await this.props.createMoshiokuri(values);
    }
    this.props.history.goBack();
  }

  async delete(id) {
    await this.props.deleteMoshiokuri(id.toString());

    this.props.history.goBack();
  }

  preToEnd = () => {
    const { ids, moshiokuriId } = this.state;
    this.props.getMoshiokuriSingleRecord(moshiokuriId, ids, "fastBack");
  };

  pre = () => {
    const { ids, moshiokuriId } = this.state;
    this.props.getMoshiokuriSingleRecord(moshiokuriId, ids, "stepBack");
  };

  next = () => {
    const { ids, moshiokuriId } = this.state;
    this.props.getMoshiokuriSingleRecord(moshiokuriId, ids, "stepForwad");
  };

  nextToEnd = () => {
    // const { kanriMoshiokuriList } = this.props;
    // const { moshiokuriId } = this.state;

    // if (kanriMoshiokuriList && kanriMoshiokuriList.length > 0) {
    //     let index = kanriMoshiokuriList.findIndex((item) => item.id === moshiokuriId);

    //     if (index !== kanriMoshiokuriList.length) {

    //         this.setState({
    //             moshiokuriId: kanriMoshiokuriList[kanriMoshiokuriList.length - 1].id
    //         })

    //         this.props.history.replace("/kanri/moshiokurilink/" + kanriMoshiokuriList[kanriMoshiokuriList.length - 1].id);
    //     }
    // }
    const { ids, moshiokuriId } = this.state;
    this.props.getMoshiokuriSingleRecord(moshiokuriId, ids, "fastForwad");
  };

  recordMark = (type, id) => {
    this.props.recordMark("moshiokuri", type, id);
  };

  render() {
    const { kanriMoshiokuri, moshiokuriSelectedRows } = this.props;
    const { moshiokuriId } = this.state;

    return (
      <KanriLayoutContainer router="moshiokuri" breadcrumbLink={"/kanri/moshiokurilink"} breadcrumbText={"申し送りリンク"}>
        <ActionsRow
          key={moshiokuriSelectedRows}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          selectedList={moshiokuriSelectedRows}
          id={moshiokuriId}
        />

        <Row>
          <WrappedMoshiokuriForm
            data={kanriMoshiokuri}
            history={this.props.history}
            regData={(values) => this.registerMoshiokuri(values)}
            delete={this.delete}
          />
        </Row>
      </KanriLayoutContainer>
    );
  }
}

export default KanriMoshiokuriLinkSave;
