/* eslint-disable object-curly-spacing */
/* eslint-disable no-nested-ternary */
import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import {
  getDateInMonth,
  getValueIskeep,
  getValueLocalstorage,
} from "../common/function_common/functionCommon";

const DataFilterApi = {
  async dataFilterApi() {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.NYUTAISHO_FILTER, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      //
      return err;

    }
  },
};

/**
 *
 * @param {*} parameterObj
 * parameterObj.tabName: active tabName
 * parameterObj.isPropData: if propData loaded, return false and reuse
 * parameterObj.isLoadBasic: check if have to reload riyousya info again
 *
 *
 */
const LoadDataApi = {
  loadDataApi(parameterObj) {
    if (!parameterObj.isLoadBasic && localStorage.getItem("riyousyaIds")) {
      return loadBasic(parameterObj);
    }
    return loadAll(parameterObj);

  },
};

function loadAll(parameterObj) {
  const systemControlName = JSON.parse(localStorage.getItem("menu")).sn;
  switch (parameterObj.tabName) {
    case "利用者":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          // return mergeData(res.data.data, getRiyousyaReport(riyousyaIds, parameterObj), parameterObj.tabName)
          return getRiyousyaReport(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });

    case "バイタル":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          return getBaitaru(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });

    case "食事摂取":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          return getMeal(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });

    case "水分摂取":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          // return mergeData(res.data.data, getRiyousyaReport(riyousyaIds, parameterObj), parameterObj.tabName)
          return getSuibun(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });
    case "排泄":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          // return mergeData(res.data.data, getRiyousyaReport(riyousyaIds, parameterObj), parameterObj.tabName)
          return getHaisetu(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });
    case "入浴":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          // return mergeData(res.data.data, getRiyousyaReport(riyousyaIds, parameterObj), parameterObj.tabName)
          return getNyuyoku(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });
    case "服薬":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          // return mergeData(res.data.data, getRiyousyaReport(riyousyaIds, parameterObj), parameterObj.tabName)
          return getFukuyaku(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });
    case "提供記録":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          // return mergeData(res.data.data, getRiyousyaReport(riyousyaIds, parameterObj), parameterObj.tabName)
          return getKaigonaiyo(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });
    case "特記":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          // return mergeData(res.data.data, getRiyousyaReport(riyousyaIds, parameterObj), parameterObj.tabName)
          return getTokki(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });
    case "画像等":
      return axios(
        apiUrls.INIT +
        apiUrls.NYUTAISHO_BASIC_DATA +
        "?pageNo=" +
        (parameterObj.pageNo ? parameterObj.pageNo : 0) +
        "&nyuDate=" +
        parameterObj.nyutaishoDate + `&systemControlName=${systemControlName}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          // Save data to localstorage for reuse
          localStorage.setItem("nyuDate", parameterObj.nyutaishoDate);
          localStorage.setItem("kirokuBasic", JSON.stringify(res.data.data));

          let riyousyaIds = "";
          for (const i in res.data.data) {
            riyousyaIds = riyousyaIds + res.data.data[i].riyousya.id + ",";
          }
          localStorage.setItem("riyousyaIds", riyousyaIds);

          // return mergeData(res.data.data, getRiyousyaReport(riyousyaIds, parameterObj), parameterObj.tabName)
          return getGaizou(riyousyaIds, parameterObj, res.data.data);
        })
        .catch((err) => {
          /**
           * @TODO handle exception
           */
          clearLocalStorage(err);
        });
    /**
     * @TODO handle exception tabName null
     */
    default:
      return null;
  }
}

function loadBasic(parameterObj) {
  switch (parameterObj.tabName) {
    case "利用者":
      return getRiyousyaReport(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    case "バイタル":
      return getBaitaru(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    case "食事摂取":
      return getMeal(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    case "水分摂取":
      return getSuibun(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    case "排泄":
      return getHaisetu(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    case "入浴":
      return getNyuyoku(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    case "服薬":
      return getFukuyaku(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    case "提供記録":
      return getKaigonaiyo(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    case "特記":
      return getTokki(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    case "画像等":
      return getGaizou(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    /**
     * @TODO handle exception tabName null
     */
    default:
      return null;
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getRiyousyaReport(riyousyaIds, parameterObj, data) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_RIYOUSYA, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate: parameterObj.nyutaishoDate,
        endDate: parameterObj.nyutaishoDate,
      },
    });
    return mergeData(data, res.data, parameterObj.tabName);
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getBaitaru(riyousyaIds, parameterObj, data, idInsert) {
  return axios
    .all([
      getBaitaruReport(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTime,
        parameterObj.endTime
      ),
      getDailyPlan(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTime,
        parameterObj.endTime,
        parameterObj.tabName
      ),
    ])
    .then(
      axios.spread(function (baitaruResponse, planResponse) {
        return mergeData(
          data,
          mergeDailyPlayToRecord(baitaruResponse, planResponse, idInsert)
        );
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getBaitaruReport(riyousyaIds, startDate, endDate, startTime, endTime) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate,
        endDate,
        startTime,
        endTime,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getMeal(riyousyaIds, parameterObj, data, idInsert) {
  return axios
    .all([
      getMealReport(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.shubetu
      ),
      getDailyPlan(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        "00:00",
        "23:59",
        parameterObj.tabName,
        parameterObj.shubetu && parameterObj.shubetu !== "全表示"
          ? parameterObj.shubetu
          : null
      ),
    ])
    .then(
      axios.spread(function (mealResponse, planResponse) {
        return mergeData(
          data,
          mergeDailyPlayToRecord(mealResponse, planResponse, idInsert)
        );
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getMealReport(riyousyaIds, startDate, endDate, shubetu) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate,
        endDate,
        shubetu: shubetu && shubetu !== "全表示" ? shubetu : null,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getSuibun(riyousyaIds, parameterObj, data, idInsert) {
  return axios
    .all([
      getSuibunReport(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeSb,
        parameterObj.endTimeSb
      ),
      getDailyPlan(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeSb,
        parameterObj.endTimeSb,
        parameterObj.tabName
      ),
    ])
    .then(
      axios.spread(function (suibunResponse, planResponse) {
        return mergeData(
          data,
          mergeDailyPlayToRecord(suibunResponse, planResponse, idInsert)
        );
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getSuibunReport(riyousyaIds, startDate, endDate, startTime, endTime) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate,
        endDate,
        startTime,
        endTime,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getHaisetu(riyousyaIds, parameterObj, data, idInsert) {
  return axios
    .all([
      getHaisetuReport(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeH,
        parameterObj.endTimeH,
        parameterObj.haisetuKubun
      ),
      getDailyPlan(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeH,
        parameterObj.endTimeH,
        parameterObj.tabName,
        parameterObj.haisetuKubun && parameterObj.haisetuKubun !== "両方"
          ? parameterObj.haisetuKubun
          : null
      ),
    ])
    .then(
      axios.spread(function (haisetuResponse, planResponse) {
        return mergeData(
          data,
          mergeDailyPlayToRecord(haisetuResponse, planResponse, idInsert)
        );
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getHaisetuReport(
  riyousyaIds,
  startDate,
  endDate,
  startTime,
  endTime,
  haisetuKubun
) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate,
        endDate,
        startTime,
        endTime,
        haisetuKubun: haisetuKubun && haisetuKubun !== "両方" ? haisetuKubun : null,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getNyuyoku(riyousyaIds, parameterObj, data, idInsert) {
  return axios
    .all([
      getNyuyokuReport(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeN,
        parameterObj.endTimeN,
        parameterObj.gender
      ),
      getDailyPlan(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeN,
        parameterObj.endTimeN,
        parameterObj.tabName,
        parameterObj.gender && parameterObj.gender !== "両方"
          ? parameterObj.gender
          : null
      ),
    ])
    .then(
      axios.spread(function (nyuyokuResponse, planResponse) {
        return mergeData(
          data,
          mergeDailyPlayToRecord(nyuyokuResponse, planResponse),
          idInsert
        );
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getNyuyokuReport(
  riyousyaIds,
  startDate,
  endDate,
  startTime,
  endTime,
  gender
) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate,
        endDate,
        startTime,
        endTime,
        gender: gender && gender !== "両方" ? gender : null,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getFukuyaku(riyousyaIds, parameterObj, data, idInsert) {
  return axios
    .all([
      getFukuyakuReport(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeF,
        parameterObj.endTimeF
      ),
      getDailyPlan(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeF,
        parameterObj.endTimeF,
        parameterObj.tabName
      ),
    ])
    .then(
      axios.spread(function (fukuyakuResponse, planResponse) {
        return mergeData(
          data,
          mergeDailyPlayToRecord(fukuyakuResponse, planResponse, idInsert)
        );
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getFukuyakuReport(
  riyousyaIds,
  startDate,
  endDate,
  startTime,
  endTime
) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate,
        endDate,
        startTime,
        endTime,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getKaigonaiyo(riyousyaIds, parameterObj, data, idInsert) {
  const kaigoCodeLocal = getValueIskeep("kiroku_kaigo_naiyou");
  return axios
    .all([
      getKaigonaiyoReport(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeK,
        parameterObj.endTimeK,
        parameterObj.kaigoNaiyouCode &&
          parameterObj.kaigoNaiyouCode !== "全表示"
          ? parameterObj.kaigoNaiyouCode
          : kaigoCodeLocal && kaigoCodeLocal !== "全表示"
            ? kaigoCodeLocal
            : null
      ),
      getDailyPlan(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeK,
        parameterObj.endTimeK,
        parameterObj.tabName,
        parameterObj.kaigoNaiyouCode &&
          parameterObj.kaigoNaiyouCode !== "全表示"
          ? parameterObj.kaigoNaiyouCode
          : kaigoCodeLocal && kaigoCodeLocal !== "全表示"
            ? kaigoCodeLocal
            : null
      ),
    ])
    .then(
      axios.spread(function (kaigonaiyoResponse, planResponse) {
        return mergeData(
          data,
          mergeDailyPlayToRecord(kaigonaiyoResponse, planResponse, idInsert)
        );
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getKaigonaiyoReport(
  riyousyaIds,
  startDate,
  endDate,
  startTime,
  endTime,
  kaigoNaiyouCode
) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGONAIYOU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate,
        endDate,
        startTime,
        endTime,
        kaigoNaiyouCode,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getTokki(riyousyaIds, parameterObj, data, idInsert) {
  return axios
    .all([
      getTokkiReport(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeT,
        parameterObj.endTimeT
      ),
      // getDailyPlan(
      //   riyousyaIds,
      //   parameterObj.startDate,
      //   parameterObj.endDate,
      //   parameterObj.startTime,
      //   parameterObj.endTime,
      //   parameterObj.tabName
      // )
    ])
    .then(
      axios.spread(function (tokkiResponse) {
        return mergeData(
          data,
          mergeDailyPlayToRecord(tokkiResponse, [], idInsert)
        );
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getTokkiReport(riyousyaIds, startDate, endDate, startTime, endTime) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate,
        endDate,
        startTime,
        endTime,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
function getGaizou(riyousyaIds, parameterObj, data, idInsert) {
  return axios
    .all([
      getGazouReport(
        riyousyaIds,
        parameterObj.startDate,
        parameterObj.endDate,
        parameterObj.startTimeG,
        parameterObj.endTimeG
      ),
      // getDailyPlan(
      //   riyousyaIds,
      //   parameterObj.startDate,
      //   parameterObj.endDate,
      //   parameterObj.startTime,
      //   parameterObj.endTime,
      //   parameterObj.tabName
      // )
    ])
    .then(
      axios.spread(function (gazouResponse) {
        return mergeData(
          data,
          mergeDailyPlayToRecord(gazouResponse, [], idInsert)
        );
      })
    )
    .catch((err) => {
      clearLocalStorage(err);
    });
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getGazouReport(riyousyaIds, startDate, endDate, startTime, endTime) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
        systemControlId: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds,
        startDate,
        endDate,
        startTime,
        endTime,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} startTime
 * @param {*} endTime
 */
async function getDailyPlan(
  riyousyaIds,
  startDate,
  endDate,
  startTime,
  endTime,
  tabName,
  kubun
) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.NYUTAISHO_DAILYPLAN_NEW, {
      method: "POST",
      data: {
        mainSID: JSON.parse(localStorage.getItem("menu")).si,
        riyousyaIds: riyousyaIds ? riyousyaIds : null,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        startTime: startTime ? startTime : null,
        endTime: endTime ? endTime : null,
        tabName: tabName ? tabName : null,
        kubun: kubun ? kubun : null,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} basicArray // riyousya info
 * @param {*} mergeArray // record + plan info
 */
function mergeData(basicArray, mergeArray) {
  const resultValue = [];

  // hardCode dubplicate kubun parameter
  const tabActive = localStorage.getItem("tabActive");

  for (const tmp1 in basicArray) {
    let checkRiyousyaRecordExsit = true;

    // reset comment from riyousya information to avoid overwrite comment filed of record when display
    basicArray[tmp1].comment = "";
    basicArray[tmp1].settingName = "";

    /**
     * @TODO check comment bellow
     *
     */
    if (tabActive === "画像等") {
      basicArray[tmp1].kubunRiyou = basicArray[tmp1].kubun;
      basicArray[tmp1].kubun = "";
    }

    if (tabActive === "排泄") {
      for (const tmp2 in mergeArray) {
        if (basicArray[tmp1].riyousya.id === mergeArray[tmp2].riyousyaId) {
          checkRiyousyaRecordExsit = false;
          const margeValue = Object.assign(
            {},
            basicArray[tmp1],
            mergeArray[tmp2]
          );

          if (margeValue && margeValue.settingName && !margeValue.haisetuId) {
            if (margeValue.settingName == "HB提供内容設定") {
              margeValue.haisetuKubun = "排便";
            }
            if (margeValue.settingName == "HN提供内容設定") {
              margeValue.haisetuKubun = "排尿";
            }
          }

          resultValue.push(margeValue);
        }
      }
    }
    else if (tabActive === "提供記録") {
      for (const tmp2 in mergeArray) {
        if (basicArray[tmp1].riyousya.id === mergeArray[tmp2].riyousyaId) {
          checkRiyousyaRecordExsit = false;
          const margeValue = Object.assign(
            {},
            basicArray[tmp1],
            mergeArray[tmp2]
          );

          if (margeValue && margeValue.kaigo_naiyou && margeValue.kaigoId) {
            margeValue.kaigoNaiyou = margeValue.kaigo_naiyou;
          }

          resultValue.push(margeValue);
        }
      }
    }
    else {
      for (const tmp2 in mergeArray) {
        if (basicArray[tmp1].riyousya.id === mergeArray[tmp2].riyousyaId) {
          checkRiyousyaRecordExsit = false;
          resultValue.push(
            Object.assign({}, basicArray[tmp1], mergeArray[tmp2])
          );
        }
      }
    }

    if (checkRiyousyaRecordExsit) {
      resultValue.push(basicArray[tmp1]);
    }
  }

  // update index to record
  // for(let tmpIndex in resultValue){
  //   resultValue[tmpIndex].index = tmpIndex;
  // }

  return resultValue.map((item, index) => ({
    // create unique field: index. for copying and deleting function
    ...item,
    index,
    key: index,
  }));
}

/**
 *
 * following riyousyaId
 * if total reshiki(records) >= total plan only display reshiki
 * if total reshiki < toal plan:
 * if time is the same => display reshiki only
 * if time is different => display all
 *
 * @param {*} recordsArr // daily record
 * @param {*} dailyPlanArr // daily plan
 */
function mergeDailyPlayToRecord(recordsArr, dailyPlanArr, idInsert) {
  let resultArray = [];
  //
  const dailyPlanConvert = [];
  if (
    localStorage.getItem("tabActive") &&
    localStorage.getItem("tabActive") === "入浴"
  ) {
    // Plan have been set for each date of week, check and remove that plan not in conditional
    // Only implement for 入浴 tab
    let dateLocalstorage = localStorage.getItem("nyuDate");
    if (!dateLocalstorage) {
      dateLocalstorage = new Date().format("Y-m-d");
    }

    const dateToText = getDateInMonth(
      dateLocalstorage.split("-")[0],
      dateLocalstorage.split("-")[1],
      dateLocalstorage.split("-")[2]
    );

    for (const tmp in dailyPlanArr) {
      const preMerge = {
        riyousyaId: dailyPlanArr[tmp].riyousyaId,
        dailyPlanId: dailyPlanArr[tmp].dailyPlanId,
        nyuyokuYoubi: dailyPlanArr[tmp].nyuyokuYoubi,
        planType: true,
      };

      // only get data if dailyPlanDetail exist
      if (
        dailyPlanArr[tmp].dailyPlanDetailId &&
        dailyPlanArr[tmp].nyuyokuYoubi.includes(dateToText)
      ) {
        dailyPlanConvert.push(
          Object.assign({}, preMerge, {
            dailyPlanDetailId: dailyPlanArr[tmp].dailyPlanDetailId,
            time: dailyPlanArr[tmp].time,
            kaigoNaiyouCode: dailyPlanArr[tmp].kaigoNaiyouCode,
            kaigoNaiyou: dailyPlanArr[tmp].kaigoNaiyou,
          })
        );
      }
    }
  }
  else {
    // other tab
    for (const tmp in dailyPlanArr) {
      const preMerge = {
        riyousyaId: dailyPlanArr[tmp].riyousyaId,
        dailyPlanId: dailyPlanArr[tmp].dailyPlanId,
        nyuyokuYoubi: dailyPlanArr[tmp].nyuyokuYoubi,
        planType: true,
      };

      // only get data if dailyPlanDetail exist
      if (dailyPlanArr[tmp].dailyPlanDetailId) {
        dailyPlanConvert.push(
          Object.assign({}, preMerge, {
            dailyPlanDetailId: dailyPlanArr[tmp].dailyPlanDetailId,
            time: dailyPlanArr[tmp].time,
            kaigoNaiyouCode: dailyPlanArr[tmp].kaigoNaiyouCode,
            kaigoNaiyou: dailyPlanArr[tmp].kaigoNaiyou,
            settingName: dailyPlanArr[tmp].settingName
              ? dailyPlanArr[tmp].settingName
              : "",
          })
        );
      }
    }
  }

  // merge plan to report with same rioyusyaId
  const arrayCheck = [];
  for (const tmp1 in recordsArr) {
    // flag * with id that have just created
    if (idInsert) {
      if (recordsArr[tmp1].id === idInsert) {
        recordsArr[tmp1].flagInsert = true;
      }
    }

    if (!arrayCheck.includes(recordsArr[tmp1].riyousyaId)) {
      const recordsbyRiyousyaId = recordsArr.filter(function (item) {
        return item.riyousyaId === recordsArr[tmp1].riyousyaId;
      });

      // Count plan by riyousyaId
      const plansByRiyousyaId = dailyPlanConvert.filter(function (item) {
        return item.riyousyaId === recordsArr[tmp1].riyousyaId;
      });

      resultArray = resultArray.concat(recordsbyRiyousyaId);
      if (plansByRiyousyaId.length > recordsbyRiyousyaId.length) {
        // when the number record of plan bigger than number records of reports
        //
        const prePlan = [];
        for (const tmp2 in plansByRiyousyaId) {
          if (!itemExists(recordsbyRiyousyaId, plansByRiyousyaId[tmp2].time)) {
            // take the plan not duplicated time with record_id
            prePlan.push(plansByRiyousyaId[tmp2]);
          }
        }

        resultArray = resultArray.concat(prePlan);
      }
    }

    arrayCheck.push(recordsArr[tmp1].riyousyaId);
  }

  // merge plan dont exist in report
  for (const tmp3 in dailyPlanConvert) {
    if (!arrayCheck.includes(dailyPlanConvert[tmp3].riyousyaId)) {
      resultArray.push(dailyPlanConvert[tmp3]);
    }
  }

  return resultArray;
}

/**
 *
 */
function clearLocalStorage(err) {
  /**
   * @TODO handle error
   */
  if (!err.response) {
    // network error
  }
  else {
    // Check Token Exist
    if (err.response.status === 403) {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("doubleLogin");
      localStorage.removeItem("getAvatarResponse");
      localStorage.removeItem("getAvatarResponseData");
      window.location.replace("/login");
    }

    return err;
  }
}

/**
 *
 * @param {*} arr
 * @param {*} keyItem
 * @param {*} value
 */
function itemExists(arr, value) {
  return arr.some(function (el) {
    return el.time === value;
  });
}

/**
 * Vitalの更新
 */
const VitalUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const VitalInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const VitalDelete = {
  async delete(vitalId) {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_DELETE, {
        method: "DELETE",
        data: {
          vitalId: "" + vitalId,
        },
      });
      // Reload Data
      const parameterObj = {
        startDate: localStorage.getItem("nyuDate"),
        endDate: localStorage.getItem("nyuDate"),
        startTime: getValueIskeep("jikantai") &&
          getValueIskeep("jikantai") !== "全表示"
          ? getValueIskeep("jikantai").split("~")[0]
          : "00:00",
        endTime: getValueIskeep("jikantai") &&
          getValueIskeep("jikantai") !== "全表示"
          ? getValueIskeep("jikantai").split("~")[1]
          : "23:59",
        tabName: localStorage.getItem("tabActive"),
      };
      return await getBaitaru(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};
// end vital API

/**
 * Mealの更新
 */
const MealUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const MealInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const MealDelete = {
  async delete(mealId) {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_DELETE, {
        method: "DELETE",
        data: {
          mealId: "" + mealId,
        },
      });
      // Reload Data
      const parameterObj = {
        startDate: localStorage.getItem("nyuDate"),
        endDate: localStorage.getItem("nyuDate"),
        shubetu: getValueIskeep("mealType"),
        kubun: getValueIskeep("mealType"),
        tabName: localStorage.getItem("tabActive"),
      };
      return await getMeal(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Suibunの更新
 */
const SuibunUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const SuibunInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const SuibunDelete = {
  async delete(suibunId) {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_DELETE, {
        method: "DELETE",
        data: {
          suibunId: "" + suibunId,
        },
      });
      // Reload Data
      const filterTime = getValueIskeep("jikantaiSb");
      const parameterObj = {
        startDate: localStorage.getItem("nyuDate"),
        endDate: localStorage.getItem("nyuDate"),
        startTimeSb: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[0]
          : "00:00",
        endTimeSb: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[1]
          : "23:59",
        tabName: localStorage.getItem("tabActive"),
      };
      return await getSuibun(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * haisetuの更新
 */
const HaisetuUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const HaisetuInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const HaisetuDelete = {
  async delete(haisetuId) {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_DELETE, {
        method: "DELETE",
        data: {
          haisetuId: "" + haisetuId,
        },
      });
      // Reload Data
      const filterTime = getValueIskeep("jikantaiH");
      const parameterObj = {
        startDate: localStorage.getItem("nyuDate"),
        endDate: localStorage.getItem("nyuDate"),
        startTimeH: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[0]
          : "00:00",
        endTimeH: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[1]
          : "23:59",
        haisetuKubun: getValueLocalstorage("haisetuType"),
        tabName: localStorage.getItem("tabActive"),
      };
      return await getHaisetu(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Nyuyokuの更新
 */
const NyuyokuUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const NyuyokuInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const NyuyokuDelete = {
  async delete(nyuyokuId) {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_DELETE, {
        method: "DELETE",
        data: {
          nyuyokuId: "" + nyuyokuId,
        },
      });
      // Reload Data
      const filterTime = getValueIskeep("jikantaiN");
      const parameterObj = {
        startDate: localStorage.getItem("nyuDate"),
        endDate: localStorage.getItem("nyuDate"),
        startTimeN: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[0]
          : "00:00",
        endTimeN: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[1]
          : "23:59",
        tabName: localStorage.getItem("tabActive"),
      };
      return await getNyuyoku(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Fukuyakuの更新
 */
const FukuyakuUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const FukuyakuInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const FukuyakuDelete = {
  async delete(fukuyakuId) {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_DELETE, {
        method: "DELETE",
        data: {
          fukuyakuId: "" + fukuyakuId,
        },
      });
      // Reload Data
      const filterTime = getValueIskeep("jikantaiF");
      const parameterObj = {
        startDate: localStorage.getItem("nyuDate"),
        endDate: localStorage.getItem("nyuDate"),
        startTimeF: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[0]
          : "00:00",
        endTimeF: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[1]
          : "23:59",
        tabName: localStorage.getItem("tabActive"),
      };
      return await getFukuyaku(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Kaigonaiyoの更新
 */
const KaigonaiyoUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const KaigonaiyoInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const KaigonaiyoDelete = {
  async delete(kaigoId, type) {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_DELETE, {
        method: "DELETE",
        data: {
          kaigoId: "" + kaigoId,
        },
      });
      // Reload Data
      const filterTime = getValueIskeep("jikantaiK");
      const parameterObj = {
        startDate: localStorage.getItem("nyuDate"),
        endDate: localStorage.getItem("nyuDate"),
        startTimeK: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[0]
          : "00:00",
        endTimeK: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[1]
          : "23:59",
        kaigoNaiyouCode: type ? type : null,
        tabName: localStorage.getItem("tabActive"),
      };
      return await getKaigonaiyo(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Tokkiの更新
 */
const TokkiUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const TokkiInsert = {
  async insert(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_INSERT, {
        method: "POST",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const TokkiDelete = {
  async delete(tokkiId) {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_DELETE, {
        method: "DELETE",
        data: {
          tokkiId: "" + tokkiId,
        },
      });
      // Reload Data
      const filterTime = getValueIskeep("jikantaiT");
      const parameterObj = {
        startDate: localStorage.getItem("nyuDate"),
        endDate: localStorage.getItem("nyuDate"),
        startTimeT: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[0]
          : "00:00",
        endTimeT: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[1]
          : "23:59",
        tabName: localStorage.getItem("tabActive"),
      };
      return await getTokki(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Gazouの更新
 */
const GazouUpdate = {
  async update(params) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const GazouInsert = {
  async insert(params) {
    const formData = new FormData();
    formData.append("systemControlName", params.systemControlName);
    formData.append("systemControlId", params.systemControlId);
    formData.append("riyousyaId", params.riyousyaId);
    formData.append("tantoId", params.tantoId);
    formData.append("date", params.date);
    formData.append("time", params.time);
    // formData.append('type', '.jpg')
    // formData.append('fileName', 'fileName')
    formData.append("thumbnail", params.thumbnail ? params.thumbnail : null);
    formData.append("priorityf", params.priorityf ? params.priorityf : "0");
    formData.append("kubun", params.kubun ? params.kubun : "");
    formData.append("comment", params.comment ? params.comment : "");
    formData.append(
      "reportCaseTyuif",
      params.reportCaseTyuif ? params.reportCaseTyuif : "0"
    );
    formData.append("file", params.file);

    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_INSERT, {
        method: "POST",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const GazouDelete = {
  async delete(fileManagementId) {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_DELETE, {
        method: "DELETE",
        data: {
          fileManagementId: "" + fileManagementId,
        },
      });
      // Reload Data
      const filterTime = getValueIskeep("jikantaiG");
      const parameterObj = {
        startDate: localStorage.getItem("nyuDate"),
        endDate: localStorage.getItem("nyuDate"),
        startTimeG: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[0]
          : "00:00",
        endTimeG: filterTime && filterTime !== "全表示"
          ? filterTime.split("~")[1]
          : "23:59",
        tabName: localStorage.getItem("tabActive"),
      };
      return await getGaizou(
        localStorage.getItem("riyousyaIds"),
        parameterObj,
        JSON.parse(localStorage.getItem("kirokuBasic"))
      );
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const ZenkaiLoad = {
  async load(date, riyousyaId, time) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.NYUTAISHO_ZENKAI_LOAD, {
        method: "POST",
        data: {
          systemControlName: JSON.parse(localStorage.getItem("menu")).sn,
          systemControlId: JSON.parse(localStorage.getItem("menu")).si,
          riyousyaIds: "" + riyousyaId,
          date,
          time: time ? time : "00:00",
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

export {
  DataFilterApi, FukuyakuDelete, FukuyakuInsert, FukuyakuUpdate, GazouDelete, GazouInsert, GazouUpdate, HaisetuDelete, HaisetuInsert, HaisetuUpdate, KaigonaiyoDelete, KaigonaiyoInsert, KaigonaiyoUpdate, LoadDataApi, MealDelete, MealInsert, MealUpdate, NyuyokuDelete, NyuyokuInsert, NyuyokuUpdate, SuibunDelete, SuibunInsert, SuibunUpdate, TokkiDelete, TokkiInsert, TokkiUpdate, VitalDelete, VitalInsert, VitalUpdate, ZenkaiLoad
};

