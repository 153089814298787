import { TantoMasterActionTypes } from "../action_types";

function tantoMaster(state = {}, action) {
  switch (action.type) {
    case TantoMasterActionTypes.GET_TANTO_MASTER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case TantoMasterActionTypes.GET_TANTO_MASTER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    }
    case TantoMasterActionTypes.GET_TANTO_MASTER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}

export default tantoMaster;
