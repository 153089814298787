import React from "react";
import { Modal, Table, Input, Row, Col, Button } from "antd";

class SearchYubinModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputZipSearch: "",
      zipRecord: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.setState({
        inputZipSearch: this.props.zip,
      });
    }
  }

  searchZip(zip) {
    this.props.searchYubinBango(zip);
  }

  onChangeInputZipSearch(e) {
    this.setState({
      inputZipSearch: e.target.value,
    });
  }

  onRowZipClick(record) {
    this.setState({
      zipRecord: record,
    });
  }

  onSubmitZip() {
    const { zipRecord } = this.state;

    this.props.onSubmit(zipRecord);
    this.setState({
      zipRecord: null,
      inputZipSearch: null,
    });
  }

  onCancel() {
    this.props.onCancel();

    this.setState({
      zipRecord: null,
      inputZipSearch: null,
    });
  }

  render() {
    return (
      (<Modal
        maskClosable={false}
        zIndex={1001}
        title={
          <Row style={{ marginBottom: "4px" }}>
            <Col span={6}>〒郵便番号</Col>
            <Col span={18}>
              <Input
                onChange={(e) => this.onChangeInputZipSearch(e)}
                value={this.state.inputZipSearch}
                style={{ maxWidth: 280, marginRight: 8 }}
              />
              <Button
                type="primary"
                onClick={() => this.searchZip(this.state.inputZipSearch)}
              >
                検索
              </Button>
            </Col>
          </Row>
        }
        open={this.props.visible}
        onOk={() => this.onSubmitZip()}
        onCancel={() => this.onCancel()}
        footer={[
          <Button key="back" onClick={() => this.onCancel()}>
            キャンセル
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => this.onSubmitZip()}
          >
            OK
          </Button>,
        ]}
        closable={false}
        className="modal-search-zip"
      >
        <Table
          columns={[
            {
              title: "zip",
              dataIndex: "zip",
            },
            {
              title: "address",
              dataIndex: "address",
            },
          ]}
          dataSource={this.props.data}
          loading={this.props.loading}
          size="small"
          pagination={{ pageSize: 6 }}
          showHeader={false}
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: () => {
                this.onRowZipClick(record);
              }, // click row
            };
          }}
          rowClassName={(record) =>
            this.state.zipRecord && record.id === this.state.zipRecord.id
              ? "pljp-table-row-hover"
              : ""
          }
        />
      </Modal>)
    );
  }
}

export default SearchYubinModal;
