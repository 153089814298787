/* eslint-disable brace-style */
import { General } from "../constants";
import React from "react";
import { notification } from "antd/lib/index";
import { UrlUtils } from "./index";

function isLifeAdmin(pathname) {
  const paths = pathname.split("/");
  const [, adminPath, kanriMode] = paths;

  if (adminPath && kanriMode) {
    return (
      (adminPath === General.PathPrefix ||
        adminPath.includes(`${General.PathPrefix}-`)) &&
      (kanriMode === General.PathAdminPrefix ||
        kanriMode.includes(`${General.PathAdminPrefix}-`))
    );
  }

  return false;
}

function isAdmin(pathname) {
  const paths = pathname.split("/");
  const [, adminPath] = paths;

  return (
    adminPath === General.PathPrefix ||
    adminPath.includes(`${General.PathPrefix}-`)
  );
}

function parseJson(str) {
  try {
    const data = JSON.parse(str);
    return data;
  } catch (error) {
    return null;
  }
}

function parseMenu(str) {
  if (!str && typeof str !== "string") return {};

  const obj = parseJson(str);

  if (!obj) return {};

  const menu = obj.menu;

  obj.si = menu.split(";")[0].split(",")[0];
  obj.sn = menu.split(";")[0].split(",")[1];
  obj.sy = menu.split(";")[0].split(",")[2];
  obj.scn = menu.split(";")[0].split(",")[3];
  obj.ySid = menu.split(";")[0].split(",")[4];
  obj.iSid = menu.split(";")[0].split(",")[5];
  return obj;
}

function CopyLine(originArray, record) {
  const returnCopyArray = [];
  for (const tmpCopy in originArray) {
    returnCopyArray.push(originArray[tmpCopy]);
    returnCopyArray.push(originArray[record]);
  }
  return returnCopyArray;
}

function deleteLine(originArray, record) {
  originArray = originArray.filter((item) => {
    return item.index !== record.index;
  });

  return originArray;
}
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

function convertSortDataToParams(sortData) {
  const sortParams = {};
  if (sortData.key1 !== "0") sortParams[sortData.key1] = sortData.value1;
  if (sortData.key2 !== "0") sortParams[sortData.key2] = sortData.value2;
  if (sortData.key3 !== "0") sortParams[sortData.key3] = sortData.value3;
  if (sortData.key4 !== "0") sortParams[sortData.key4] = sortData.value4;
  return sortParams;
}

// format font
function LineBreakText({ text, style }) {
  return (
    // <span
    //   style={{
    //     whiteSpace: "pre-line",
    //     wordBreak: "break-all",
    //     ...style,
    //   }}
    //   dangerouslySetInnerHTML={{ __html: text || "" }}
    // />
    <span
      style={{
        ...style,
      }}
    >
      {text
        ? text.split(/\r\n|\n|\r/).map((str, index) => (
            <React.Fragment key={index}>
              {str}
              <br />
            </React.Fragment>
          ))
        : null}
    </span>
  );
}

function pagination(meta) {
  return {
    current: meta.page,
    pageSize: meta.pageSize,
    total: meta.total,
  };
}

function parseAttendance(data) {
  if (!data) return data;
  const attendances = data.split(";");

  for (let i = 0; i < attendances.length; i++) {
    let attendance = attendances[i];
    attendance = attendance.trim();

    if (attendance) {
      const match = attendance.match(/,/g);

      if (match && match.length > 1) {
        let newAttendance = attendance.replace(/,\s*/, " (");
        newAttendance += ")";
        attendances[i] = newAttendance;
      }
    }
  }

  const parsedData = attendances.join("\n");
  return parsedData;
}

function parseAttendanceV2(data) {
  return data.replace(/;/g, "\n");
}

function openNotificationWithIcon(type, notifyContent, callback) {
  notification[type]({
    message: notifyContent,
    placement: "topRight",
    duration: type === "success" ? 1 : 3,
    style: { whiteSpace: "pre-wrap" },
    prefixCls: "pljp-notification",
  });
  if (callback) {
    callback();
  }
}

function backMoniManagement(linkSearch, riyousyaId, callbacks) {
  // Check back link monitoring management
  if (UrlUtils.parse(linkSearch).backMonitoring === "1") {
    callbacks("/management/monitoring-management");
  } else if (UrlUtils.parse(linkSearch).backMonitoring === "2") {
    callbacks(`/management/documents/${riyousyaId}?backMonitoring=1`);
  } else {
    callbacks(`/management/documents/${riyousyaId}`);
  }
}

export const CommonUtils = {
  isAdmin,
  parseJson,
  parseMenu,
  CopyLine,
  deleteLine,
  guid,
  convertSortDataToParams,
  LineBreakText,
  pagination,
  parseAttendance,
  openNotificationWithIcon,
  parseAttendanceV2,
  backMoniManagement,
  isLifeAdmin,
};
