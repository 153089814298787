import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';


import { Button, Checkbox, Divider, Input, Modal, Row } from "antd";
import FileSaver from "file-saver";
import React from "react";

import { convertMessageByCode } from "../../../common/function_common/functionCommon";

const FormItem = Form.Item;
const { Search } = Input;

class NormalCodeForm extends React.Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      isCheck: false
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.handleSubmit(values.code, values.isCheck);
      }
    });
  };

  downloadSecretKey = () => {
    const { data } = this.props;
    // const blob = new Blob([data?.tFaQaCode], {
    //   type: "image/jpeg;base64"
    // });
    const contentType = "image/png";
    const b64Data = data?.tFaQaCode;
    const blob = this.b64toBlob(b64Data, contentType);

    FileSaver.saveAs(blob, `${data?.secret}.png`);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onChangeCode = (value) => {
    const valueStr = value.target.value;
    if (valueStr && valueStr.length === 6) {
      // console.log(valueStr);
      this.setState({
        code: valueStr
      });
    }
  }

  onChangeCheckbox = (value) => {
    // console.log(value.target.checked);
    this.setState({
      isCheck: value.target.checked
    });
  }

  handleOk = () => {
    const { code, isCheck } = this.state;


    console.log(code, isCheck);
    if (code != null && code.length === 6) {
      this.props.form.setFieldsValue({
        code,
        isCheck
      });

      this.props.handleSubmit(code, isCheck);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;
    const headerModal = "新しい認証方法（アプリ）を設定";

    const footer = (
      <div>
        <Button
          className="pljp-btn pljp-btn-primary"
          onClick={() => this.handleOk()}
          type="primary"
          danger
          style={{ width: 280, display: "table", margin: "0 auto" }}
        >
          <span>送信する</span>
        </Button>
      </div>
    );

    return (
      (<div>
        <Form onSubmit={this.handleSubmit} className="code-form">
          <FormItem>
            {getFieldDecorator("code", {
              rules: [
                { required: true, message: "認証コードを入力してください" },
              ],
            })(
              <Input
                addonBefore="セキュリティコード: "
                placeholder="6桁のコードを入力"
              />
            )}
          </FormItem>
          <div className={"lg-f-error"}>
            {data?.error === "LOGIN_CODE_FAIL" ? (
              <span>{convertMessageByCode("LOGIN_CODE_FAIL")}</span>
            ) : null}
          </div>
          <FormItem className="code-form-item-login">
            <Button
              type="primary"
              danger
              htmlType="submit"
              className="login-form-button"
            >
              送信する
            </Button>
          </FormItem>
          <FormItem>
            {getFieldDecorator("isCheck", {
              rules: [
                { required: false, message: "認証コードを入力してください" },
              ],
            })(
              <Checkbox >{`今後${data?.checkSetting2FAJikan}時間、このブラウザーでの二要素認証を不要にする`}</Checkbox>
            )}
          </FormItem>

          {/* <Modal
          visible={visible}
        >
          <img src={data && data.tFaQaCode ? `data:image/png;base64, ${data.tFaQaCode}` : ""} alt="Base64 Image" />
        </Modal> */}

        </Form>
        <Modal
          closable={false}
          onCancel={() => this.handleCancel()}
          open={!!(data && data.tFaQaCode)}
          title={headerModal}
          footer={footer}
          width={440}
          style={{ top: 48 }}
        >
          <Row>
            <p>Google Authenticatorのアプリで、以下のQAコードを用いで、に要所認証の登録をしたくない。</p>
          </Row>
          <Row>
            <div style={{ backgroundColor: "#00838F", padding: 32, margin: "8px 0", borderRadius: 4 }}>
              <img src={data && data.tFaQaCode ? `data:image/png;base64, ${data.tFaQaCode}` : ""} alt="Base64 Image" style={{ width: 200, display: "table", margin: "0 auto" }} />
            </div>
            <div>
              <Search
                value={data?.secret}
                enterButton="保存"
                onSearch={(value) => this.downloadSecretKey(value)}
              />
            </div>
          </Row>
          <Divider style={{ margin: "16px 0" }} />
          <Row>
            <Input
              addonBefore="セキュリティコード: "
              placeholder="6桁のコードを入力してください"
              onChange={this.onChangeCode}
            />
            <Checkbox onChange={this.onChangeCheckbox} style={{ marginTop: 8 }} >{`今後${data?.checkSetting2FAJikan}時間、このブラウザーでの二要素認証を不要にする`}</Checkbox>
          </Row>
        </Modal>
      </div>)
    );
  }
}

const CodeForm = Form.create()(NormalCodeForm);
export default CodeForm;
