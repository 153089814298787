import { Button, Row } from "antd";
import React from "react";

export default class NumberKB extends React.Component {
  constructor(props) {
    super(props);

    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(value) {
    this.props.handleInputNum(value);
  }

  render() {
    return (
      <div>
        <Row className="kim-row">
          <Button className={"hm-item"} onClick={() => this.handleInput("7")}>
            7
          </Button>
          <Button className={"hm-item"} onClick={() => this.handleInput("8")}>
            8
          </Button>
          <Button className={"hm-item"} onClick={() => this.handleInput("9")}>
            9
          </Button>
          <Button
            className={"hm-item hm-item-del"}
            onClick={() => this.handleInput("消去")}
          >
            消去
          </Button>
        </Row>
        <Row className="kim-row">
          <Button className={"hm-item"} onClick={() => this.handleInput("4")}>
            4
          </Button>
          <Button className={"hm-item"} onClick={() => this.handleInput("5")}>
            5
          </Button>
          <Button className={"hm-item"} onClick={() => this.handleInput("6")}>
            6
          </Button>
          <Button className="hm-item hm-item-extent"></Button>
        </Row>
        <Row className="kim-row">
          <Button className={"hm-item"} onClick={() => this.handleInput("1")}>
            1
          </Button>
          <Button className={"hm-item"} onClick={() => this.handleInput("2")}>
            2
          </Button>
          <Button className={"hm-item"} onClick={() => this.handleInput("3")}>
            3
          </Button>
          <Button className="hm-item hm-item-extent"></Button>
        </Row>

        <Row className="kim-row">
          <Button className={"hm-item"} onClick={() => this.handleInput("0")}>
            0
          </Button>
          <Button className={"hm-item"} onClick={() => this.handleInput(".")}>
            .
          </Button>
          <Button className={"hm-item"} disabled>
            -
          </Button>
          <Button className="hm-item hm-item-extent"></Button>
        </Row>
      </div>
    );
  }
}
