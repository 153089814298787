import React from "react";

import { Row, Select } from "antd";

const Option = Select.Option;

export default class SelectIntakeKB extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.handleInputNum(value);
  }

  render() {
    const { inputWidth, disabled, textAlignCus } = this.props;
    return (
      (<Row style={{ textAlign: `${textAlignCus ? textAlignCus : "center"}` }}>
        <Select
          disabled={disabled ? true : false}
          maxTagCount={5}
          style={{ width: inputWidth ? inputWidth : 200 }}
          dropdownMenuStyle={{
            height: 360,
            overflowY: "visible",
            maxHeight: 800,
          }}
          popupClassName=""
          // size="large" //プルダウンのサイズ
          value={this.props.value}
          onChange={this.handleChange}
          // onFocus={this.handleFocus}
          // onBlur={this.handleBlur}
        >
          <Option value="0">0</Option>
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
          <Option value="4">4</Option>
          <Option value="5">5</Option>
          <Option value="6">6</Option>
          <Option value="7">7</Option>
          <Option value="8">8</Option>
          <Option value="9">9</Option>
          <Option value="10">10</Option>
        </Select>
      </Row>)
    );
  }
}
