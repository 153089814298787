import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, notification } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { TableListKanriActions } from "../../actions/kanriActions/actions";
import { KanriLayoutContainer } from "./UI/KanriLayout";
import TableResizeAble from "./UI/TableResizable";

class KanriTableDetail extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);

    this.state = {
      xmldoc: query.get("xmldoc"),
      name: query.get("name"),
      table: query.get("table"),
    };
  }

  componentDidMount() {
    const { xmldoc, name } = this.state;
    this.props.getTableDetail(xmldoc, name);
  }

  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "name",
      },
    });
  };

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`検索 ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          検索
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          クリア
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleTableChild = (record) => {
    console.log(record);
  }

  handleGoback = () => {
    this.props.history.goBack();
  }

  handleDisplayRecord = () => {
    const { xmldoc, name, table } = this.state;
    const { tableDetail } = this.props;
    let fields = "";
    if (tableDetail != null && tableDetail.length > 0) {
      tableDetail.forEach((ele) => {
        // console.log(ele.name);
        fields = fields ? fields + "," + ele.name : ele.name;
      });
    }

    this.props.history.push(`/kanri/table/record?xmldoc=${xmldoc}&name=${name}&table=${table}&fields=${fields}`);
  }

  render() {
    let { sortedInfo } = this.state;
    const { tableDetail } = this.props;
    sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
        ellipsis: true,
        ...this.getColumnSearchProps("name"),
        width: 200,
      },
      {
        title: "jname",
        dataIndex: "jname",
        key: "jname",
        sorter: (a, b) => a.jname - b.jname,
        sortOrder: sortedInfo.columnKey === "jname" && sortedInfo.order,
        ellipsis: true,
        width: 200,
      },
      {
        title: "size",
        dataIndex: "size",
        key: "size",
        sorter: (a, b) => a.size - b.size,
        sortOrder: sortedInfo.columnKey === "size" && sortedInfo.order,
        ellipsis: true,
        width: 80,
      },
      {
        title: "type",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type - b.type,
        sortOrder: sortedInfo.columnKey === "type" && sortedInfo.order,
        ellipsis: true,
        width: 120,
      },
      {
        title: "relate",
        dataIndex: "relate",
        key: "relate",
        sorter: (a, b) => a.relate - b.relate,
        sortOrder: sortedInfo.columnKey === "relate" && sortedInfo.order,
        ellipsis: true,
        width: 200,
      },
      {
        title: "key",
        dataIndex: "key",
        key: "key",
        sorter: (a, b) => a.key - b.key,
        sortOrder: sortedInfo.columnKey === "key" && sortedInfo.order,
        ellipsis: true,
        // width: 250,
      }
      // {
      //   title: "Action",
      //   dataIndex: "Action",
      //   render: (text, record) =>
      //     <Popconfirm title="詳細にする?" onConfirm={() => this.handleTableChild(record)}>
      //       <a>詳細</a>
      //     </Popconfirm>
      // },
    ];

    return (
      <KanriLayoutContainer router="table" breadcrumbLink={"/kanri/table"} breadcrumbText={"パレット管理"}>
        <div>
          <div className="table-operations">
            {/* <Button onClick={this.setAgeSort}>並び替え</Button>
            <Button onClick={this.clearFilters}>クリア</Button> */}
            <Button onClick={this.clearAll}>クリア</Button>
            <Button type="primary" onClick={this.handleDisplayRecord}>表示記録</Button>

            <Button type="primary" style={{ float: "right" }} onClick={this.handleGoback}>戻る</Button>
          </div>
          <div id="table-resizeable-2">
            <TableResizeAble
              columns={columns}
              data={tableDetail}
              onChange={this.handleChange}
              widthx={1400}
            />
          </div>
        </div>
      </KanriLayoutContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tableDetail: state.kanriTable.tableDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTableDetail: (xmldoc, name) => dispatch(TableListKanriActions.getTableDetail(xmldoc, name)),
    // keepValueSearch: (data) => dispatch(LifeActions.keepValueSearch(data)),
  };
};

const propTypes = {
  //
};

const defaultProps = {
  minRows: 0,
  maxRows: Infinity,
};

KanriTableDetail.propTypes = propTypes;
KanriTableDetail.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(KanriTableDetail);
