import dayjs from "dayjs";
import _ from "lodash";
import {
  CREATE_REPORT_CASE_SUCCESS,
  DELETE_REPORT_CASE_SUCCESS,
  GET_OPEN_SCREEN_LOG,
  GET_REPORT_CASE_BY_ID_SUCCESS,
  LOAD_FILTER_MASTER_SUCCESS,
  LOAD_MOSHIOKURI_SUCCESS,
  LOAD_REPORT_CASE_SUCCESS,
  SAVE_OPEN_SCREEN_LOG,
  SORT_RIYOUSYA_SUCCESS,
  UPDATE_KAKUNIN_COMMENT_SUCCESS,
  UPDATE_REPORT_CASE_SUCCESS,
} from "../../actions/progressActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
export default function progressReducers(
  state = {
    filterConditions: {
      search: {
        checkbox1: false,
        field10: null,
        field11: null,
        checkbox2: false,
        field2: null,
        checkbox3: false,
        field3: null,
        checkbox4: false,
        field4: null,
        checkbox5: false,
        field5: null,
        checkbox6: false,
        field6: null,
        checkbox7: false,
        field7: null,
        checkbox8: false,
        field8: null,
        checkbox9: false,
        field9: null,
      },
      sort: {
        key1: "0",
        value1: "asc",
        key2: "0",
        value2: "asc",
        key3: "0",
        value3: "asc",
        key4: "0",
        value4: "asc",
        key5: "0",
        value5: "asc",
      },
      searchName: "",
      startDate: dayjs(),
      endDate: dayjs(),
      tyuif: false,
      nishif: false,
    },
  },
  action
) {
  switch (action.type) {
    case LOAD_MOSHIOKURI_SUCCESS:
      return {
        ...state,
        moshiokuri: action.moshiokuri,
      };
    case LOAD_REPORT_CASE_SUCCESS:
      return {
        ...state,
        reportCases: sortAlgorithm(action.reportCases, action.searchData.sort),
        filterConditions: action.searchData,
      };
    case CREATE_REPORT_CASE_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        createEventRes: action.createReportCaseRes,
        notifyDisplay: 1,
        notifyContent: "保存しました",
      };
    case UPDATE_REPORT_CASE_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateEventRes: action.updateReportCaseRes,
        notifyDisplay: 1,
        notifyContent: "保存しました",
      };
    case UPDATE_KAKUNIN_COMMENT_SUCCESS:
      openNotificationWithIcon("success", "保存しました");

      state.reportCases = state.reportCases.map((item) => {
        if (item.reportCaseId == action.updateKakuninCommentRes.reportCaseId) {
          Object.assign(item, action.updateKakuninCommentRes);
        }
        return item;
      });

      return {
        ...state,
        notifyDisplay: 1,
        notifyContent: "保存しました",
        reportCases: state.reportCases,
      };
    case DELETE_REPORT_CASE_SUCCESS:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        notifyDisplay: 1,
        notifyContent: "保存しました",
        reportCases: _.filter(
          state.reportCases,
          (r) => r.reportCaseId != action.deleteReportCaseRes.reportCaseId
        ),
      };
    case GET_REPORT_CASE_BY_ID_SUCCESS:
      return {
        ...state,
        reportCase: action.reportCase,
        notifyDisplay: 0,
      };
    case LOAD_FILTER_MASTER_SUCCESS:
      return {
        ...state,
        filterMaster: action.filterMaster,
      };
    case SORT_RIYOUSYA_SUCCESS:
      return {
        ...state,
        reportCases: sortAlgorithm(state.reportCases, action.searchData.sort),
        filterConditions: action.searchData,
      };
    case GET_OPEN_SCREEN_LOG:
      return {
        ...state,
        openScreenLog: action.openScreenLog,
      };
    case SAVE_OPEN_SCREEN_LOG:
      return {
        ...state,
        openScreenLog: action.openScreenLog,
      };
    default:
      return state;
  }
}

/**
 *
 * @param {*} key
 */
function sortKeyCheck(key) {
  switch (key) {
    case "1":
      return "riyousyaFurigana";
    case "2":
      return "date";
    case "3":
      return "time";
    case "4":
      return "kiroku_kubun";
    case "5":
      return "tyuif";
    case "6":
      return "nishif";
    case "7":
      return "tantoFurigana";
    case "8":
      return "room";
    // case "9":
    //     return "roomBunrui";
    case "10":
      return "sonota_kubun";
    default:
      return null;
  }
}

function sortAlgorithm(arraySort, sortData) {
  if (arraySort && sortData) {
    const fieldArray = [],
      orderArray = [];
    if (sortData.key1 && sortData.key1 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key1));
      orderArray.push(sortData.value1);
    }
    if (sortData.key2 && sortData.key2 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key2));
      orderArray.push(sortData.value2);
    }
    if (sortData.key3 && sortData.key3 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key3));
      orderArray.push(sortData.value3);
    }
    if (sortData.key4 && sortData.key4 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key4));
      orderArray.push(sortData.value4);
    }
    if (sortData.key5 && sortData.key5 !== "0") {
      fieldArray.push(sortKeyCheck(sortData.key5));
      orderArray.push(sortData.value5);
    }
    if (fieldArray.length > 0) {
      arraySort = _.orderBy(arraySort.slice(), fieldArray, orderArray);
    }
  }
  return arraySort;
}
