import { Icon as LegacyIcon } from "@ant-design/compatible";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import {
  addDetailKyotakuPlan2, changeEditMode, changeValuePlan2, clearDetailKyotakuPlan2, deleteDetailKyotakuPlan2, downRowKyotakuPlan2Detail, getDetailKyotakuPlan2, getKyotakuPlan2YougoMaster, getRowInfolKyotakuPlan2,
  upRowKyotakuPlan2Detail,
  updateRowKyotakuPlan2
} from "../../../actions/kyotakuPlan2Actions/actionCreators";
import {
  yougoMasterEditCancelCreator,
  yougoMasterEditSaveCreator, yougoMasterEditStateCreator
} from "../../../actions/yougoMasterNaiyouActions/actionCreators";
import CareTermDictModal from "../UI/CareTermDictModal";
import YougoModal from "./YougoModal";
import { checkCalendarIntoGeneral } from "./common/convertDate";
import SingleTime from "./dialog/setSingleTime";
import { openNotificationWithIcon } from "./function/alert";
import jaconv from "./lib/jaconv.min";

const listTime = [
  "1週間",
  "2週間",
  "1ヵ月",
  "3ヵ月",
  "6ヵ月",
  "8ヵ月",
  "9ヵ月",
  "10ヵ月",
  "11ヵ月",
  "12ヵ月",
  "1年",
  "1年半",
  "2年",
];

const newDetail = [
  {
    hindo: "",
    jigyousyo: "",
    kikan: "",
    service: "",
    sienNaiyou: "",
    siensyaBunrui: "",
    sortNum: 1,
  },
];

// eslint-disable-next-line react/display-name
const withResize = (WrappedComponent) => (props) => {
  function autoResize() {
    const element = document.querySelector(
      `textarea[element=${props.element}]`
    );
    element.style.minHeight = "58px";
    if (!element) return;
    const offset = element.offsetHeight - element.clientHeight;
    element.style.height = "0px";
    element.style.boxSizing = "border-box";
    element.style.height = element.scrollHeight + offset + "px";
    element.addEventListener("input", function (event) {
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + offset + "px";
    });
  }

  useEffect(() => {
    autoResize();
  }, [props.value]);

  return <WrappedComponent {...props} />;
};

const TextAreaResize = withResize(Input.TextArea);

class RowInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      list_content: this.props.kyotakuPlan2Details,
      recordInfo: this.props.record,
      modalSetTimeVisible: false,
      careTermVisible: false,
      kikanType: null,
      indexDetail: null,
      field: null,
      detailField: null,
      inputVisible: false,
      listName: null,
      confirmModalVisible: false,
      sortNum: null,
      linkingCareTerm: null,
      timeModal: {
        startDate: null,
        endDate: null,
        period: null,
      },
      valueYougo: "",
    };
  }

  async UNSAFE_componentWillUpdate(nextProps) {
    if (!nextProps.edit && this.props.edit) {
      await this.handleSubmit();
      await this.props.changeEditMode(true);
    }
    if (nextProps.kyotakuPlan2Details !== this.props.kyotakuPlan2Details) {
      this.setState({
        list_content: nextProps.kyotakuPlan2Details,
      });
    }
    if (nextProps.record !== this.props.record) {
      this.setState({
        recordInfo: nextProps.record,
      });
    }
  }

  handleSubmit = () => {
    const { list_content, recordInfo } = this.state;
    if (this.props.edit) {
      const { expandedRowKeys, record } = this.props;
      this.props.getRowInfolKyotakuPlan2({
        ...recordInfo,
        details: list_content,
        id: record.id,
        sortNum: expandedRowKeys,
      });
      this.props.updateRowKyotakuPlan2(expandedRowKeys, {
        ...recordInfo,
        details: list_content,
        id: record.id,
        sortNum: expandedRowKeys,
      });
    }
  };

  handleChangeRowInfor(e) {
    const value = e.target.value;
    if (!this.props.valuesChange) {
      this.props.changeValuePlan2(true);
    }
    const name = e.target.name;
    const newRecord = { ...this.state.recordInfo };
    if (name === "longKikan" || name === "shortKikan") {
      if (value.length < 41) {
        newRecord[name] = value;
      }
      else {
        if (name === "longKikan") {
          openNotificationWithIcon("error", "長期期間が40文字を超えています!");
        }
        if (name === "shortKikan") {
          openNotificationWithIcon("error", "短期期間が40文字を超えています!");
        }
      }
    }
    else {
      newRecord[name] = value;
    }
    this.setState({
      recordInfo: newRecord,
    });
    this.props.getRowInfolKyotakuPlan2(newRecord);
  }

  handleChange(e, index) {
    if (!this.props.valuesChange) {
      this.props.changeValuePlan2(true);
    }
    const name = e.target.name;
    const newList = [...this.state.list_content];
    newList[index][name] = e.target.value;
    this.setState({
      list_content: newList,
    });
    this.props.getDetailKyotakuPlan2(newList);
  }

  showModalSetTime = (types, index, value) => {
    const timeClone = { ...this.state.timeModal };
    if (!value) {
      timeClone.startDate = this.props.startDate;
      timeClone.endDate = this.props.endDate;
      timeClone.period = this.props.period;
    }
    if (!value || (value !== null && value.trim().length == 0)) {
      timeClone.startDate = this.props.startDate;
      timeClone.endDate = this.props.endDate;
      timeClone.period = this.props.period;
    }
    else {
      const valueJac = jaconv["normalize"](value).split("ー").join("-");
      const valueClone1 = valueJac
        .split("、")[0]
        .replace(/\n/g, " ")
        .replace(/~/g, "～")
        .split(" ")
        .filter((val) => val);
      const valueClone = valueClone1.filter((val) => val !== "～");
      let i = -1;
      if (listTime.indexOf(`${valueClone[0]}`) !== -1) {
        i = i + 1;
      }
      timeClone.period = valueClone[i];
      if (valueClone[i + 1]) {
        timeClone.startDate = checkCalendarIntoGeneral(valueClone[i + 1]);
      }
      else {
        timeClone.startDate = null;
      }
      if (valueClone[i + 2]) {
        timeClone.endDate = checkCalendarIntoGeneral(valueClone[i + 2]);
      }
      else {
        timeClone.endDate = null;
      }
    }
    this.setState({
      modalSetTimeVisible: true,
      kikanType: types,
      indexDetail: index,
      timeModal: timeClone,
    });
  };

  handleSetTimeOk = () => {
    this.setState({
      modalSetTimeVisible: false,
    });
  };

  handleSetTimeCancel = () => {
    this.setState({
      modalSetTimeVisible: false,
    });
  };

  handleSelectCareTerm(careTerm) {
    const { field, detailField, indexDetail } = this.state;
    if (careTerm) {
      if (!this.props.valuesChange) {
        this.props.changeValuePlan2(true);
      }
      if (detailField === "detail") {
        const newList = [...this.state.list_content];
        if (
          !newList[indexDetail][field] ||
          newList[indexDetail][field].length === 0
        ) {
          newList[indexDetail][field] = `${careTerm}`;
        }
        else {
          newList[indexDetail][
            field
          ] = `${newList[indexDetail][field]} 、${careTerm}`;
        }
        this.setState({
          list_content: newList,
          careTermVisible: false,
        });
        this.props.getDetailKyotakuPlan2(newList);
      }
      else {
        const newRecord = { ...this.state.recordInfo };
        if (!newRecord[field] || newRecord[field].length === 0) {
          newRecord[field] = `${careTerm}`;
        }
        else {
          newRecord[field] = `${newRecord[field]} 、${careTerm}`;
        }
        this.props.getRowInfolKyotakuPlan2(newRecord);
        this.setState({
          recordInfo: newRecord,
          careTermVisible: false,
        });
      }
    }
    else {
      this.setState({
        careTermVisible: false,
      });
    }
  }

  hideModalCareTerm() {
    this.setState({
      careTermVisible: false,
    });
  }

  showModalCareTerm(field, type, index, linkingCareTerm) {
    this.setState({
      careTermVisible: true,
      field,
      detailField: type,
      indexDetail: index,
      linkingCareTerm,
    });
  }

  showYougoModal(listName, field, values, type, index) {
    this.props.getYougoMaster(listName);
    this.setState({
      inputVisible: true,
      listName,
      field,
      indexDetail: index,
      detailField: type,
      valueYougo: values,
    });
  }

  handleYougoCancel() {
    this.setState({
      inputVisible: false,
      listName: null,
      valueYougo: "",
    });
  }

  handleYougoOk(values) {
    const { field, detailField, indexDetail } = this.state;
    if (values) {
      if (!this.props.valuesChange) {
        this.props.changeValuePlan2(true);
      }
      if (detailField === "detail") {
        const newList = [...this.state.list_content];
        newList[indexDetail][field] = `${values}`;
        this.setState({
          list_content: newList,
          inputVisible: false,
          listName: null,
          valueYougo: "",
        });
        this.props.getDetailKyotakuPlan2(newList);
      }
      else {
        const newRecord = { ...this.state.recordInfo };
        newRecord[field] = `${values}`;
        this.props.getRowInfolKyotakuPlan2(newRecord);
        this.setState({
          recordInfo: newRecord,
          inputVisible: false,
          listName: null,
          valueYougo: "",
        });
      }
    }
    else {
      this.setState({
        inputVisible: false,
        listName: null,
      });
    }
  }

  showModalDelete = (sortNum) => {
    const { kyotakuPlan2Details } = this.props;
    if (kyotakuPlan2Details != newDetail) {
      this.setState({ sortNum, confirmModalVisible: true });
    }
  };

  handleDeleteDetailOk = () => {
    const { kyotakuPlan2Details } = this.props;
    if (!this.props.valuesChange) {
      this.props.changeValuePlan2(true);
    }

    if (kyotakuPlan2Details && kyotakuPlan2Details.length !== 1) {
      this.props.deleteDetailKyotakuPlan2(this.state.sortNum);
    }
    else {
      this.props.clearDetailKyotakuPlan2(newDetail);
    }
    this.setState({ confirmModalVisible: false });
  };

  handleDeleteDetailCancel = () => {
    this.setState({
      confirmModalVisible: false,
    });
  };

  render() {
    const {
      list_content,
      recordInfo,
      modalSetTimeVisible,
      kikanType,
      indexDetail,
      timeModal,
      inputVisible,
      listName,
      confirmModalVisible,
    } = this.state;
    const { expandedRowKeys, edit, settingData } = this.props;
    return (
      (<div className="mt-1">
        <hr />
        <Row type="flex" justify="space-between" className="mb-1">
          <Col sx={6} ms={6} md={6} className="text-bold">
            {`＜課題${expandedRowKeys}表示中＞`}
          </Col>
          <Col
            sx={2}
            ms={2}
            md={2}
            className="flex-end"
            style={this.props.editDetail ? { display: "none" } : {}}
          ></Col>
        </Row>
        <Row gutter={10}>
          <Col sx={24} md={10} lg={12}>
            <Row gutter={10}>
              <Col sx={24} lg={8} style={{ marginBottom: 10 }}>
                <Row
                  type="flex"
                  justify="space-between"
                  style={{ marginBottom: 3 }}
                >
                  <Col
                    span={edit ? 16 : 24}
                    className="text-bold"
                    style={{ fontSize: 13.5 }}
                  >
                    課題
                  </Col>
                  <Col span={4} className="flex-end">
                    <Button
                      type="primary"
                      size="small"
                      onClick={() =>
                        this.showModalCareTerm(
                          "seikatuKoi",
                          null,
                          null,
                          "解決すべき課題"
                        )
                      }
                    >
                      用語
                    </Button>
                  </Col>
                </Row>
                <TextAreaResize
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  // disabled={!edit}
                  value={recordInfo ? recordInfo.seikatuKoi : ""}
                  name="seikatuKoi"
                  element="data-seikatuKoi"
                  onChange={(e) => this.handleChangeRowInfor(e)}
                />
              </Col>
              <Col xs={24} lg={16}>
                <Row gutter={10}>
                  <Col xs={24} md={12} style={{ marginBottom: 10 }}>
                    <Row
                      type="flex"
                      justify="space-between"
                      style={{ marginBottom: 3 }}
                    >
                      <Col span={10} className="text-bold">
                        長期目標
                      </Col>
                      <Col span={4} className="flex-end">
                        <Button
                          type="primary"
                          size="small"
                          onClick={() =>
                            this.showModalCareTerm(
                              "longMokuhyou",
                              null,
                              null,
                              "長期目標"
                            )
                          }
                        >
                          用語
                        </Button>
                      </Col>
                    </Row>
                    <TextAreaResize
                      style={{ color: "rgba(0, 0, 0, 0.65)" }}
                      // disabled={!edit}
                      value={recordInfo ? recordInfo.longMokuhyou : ""}
                      name="longMokuhyou"
                      element="data-longMokuhyou"
                      onChange={(e) => this.handleChangeRowInfor(e)}
                    />
                  </Col>
                  <Col xs={24} md={12} style={{ marginBottom: 10 }}>
                    <Row
                      type="flex"
                      justify="space-between"
                      style={{ marginBottom: 3 }}
                    >
                      <Col span={10} className="text-bold">
                        短期目標
                      </Col>
                      <Col span={4} className="flex-end">
                        <Button
                          type="primary"
                          size="small"
                          onClick={() =>
                            this.showModalCareTerm(
                              "shortMokuhyou",
                              null,
                              null,
                              "短期目標"
                            )
                          }
                        >
                          用語
                        </Button>
                      </Col>
                    </Row>
                    <TextAreaResize
                      style={{ color: "rgba(0, 0, 0, 0.65)" }}
                      // disabled={!edit}
                      value={recordInfo ? recordInfo.shortMokuhyou : ""}
                      name="shortMokuhyou"
                      element="data-shortMokuhyou"
                      onChange={(e) => this.handleChangeRowInfor(e)}
                    />
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col xs={24} md={12} style={{ marginBottom: 10 }}>
                    <Row
                      type="flex"
                      justify="space-between"
                      style={{ marginBottom: 3 }}
                    >
                      <Col span={20} className="text-bold">
                        長期期間
                      </Col>
                      <Col span={4} className="flex-end">
                        <Button
                          type="primary"
                          size="small"
                          shape="circle"
                          style={{ padding: "0 4px" }}
                          className="mr-1"
                          onClick={() =>
                            this.showModalSetTime(
                              "longKikan",
                              null,
                              recordInfo ? recordInfo.longKikan : ""
                            )
                          }
                        >
                          入
                        </Button>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          style={{ padding: "0 4px" }}
                          onClick={() =>
                            this.showYougoModal(
                              "計画2長期期間",
                              "longKikan",
                              recordInfo ? recordInfo.longKikan : ""
                            )
                          }
                        >
                          M
                        </Button>
                      </Col>
                    </Row>
                    <TextAreaResize
                      style={{ color: "rgba(0, 0, 0, 0.65)" }}
                      value={recordInfo ? recordInfo.longKikan : ""}
                      name="longKikan"
                      element="data-longKikan"
                      onChange={(e) => this.handleChangeRowInfor(e)}
                    />
                  </Col>
                  <Col xs={24} md={12} style={{ marginBottom: 10 }}>
                    <Row
                      type="flex"
                      justify="space-between"
                      style={{ marginBottom: 3 }}
                    >
                      <Col span={12} className="text-bold">
                        短期期間
                      </Col>
                      <Col sm={4} className="flex-end">
                        <Button
                          type="primary"
                          size="small"
                          shape="circle"
                          style={{ padding: "0 4px" }}
                          className="mr-1"
                          onClick={() =>
                            this.showModalSetTime(
                              "shortKikan",
                              null,
                              recordInfo ? recordInfo.shortKikan : ""
                            )
                          }
                        >
                          入
                        </Button>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          style={{ padding: "0 4px" }}
                          onClick={() =>
                            this.showYougoModal(
                              "計画2短期期間",
                              "shortKikan",
                              recordInfo ? recordInfo.shortKikan : ""
                            )
                          }
                        >
                          M
                        </Button>
                      </Col>
                    </Row>
                    <TextAreaResize
                      style={{ color: "rgba(0, 0, 0, 0.65)" }}
                      value={recordInfo ? recordInfo.shortKikan : ""}
                      name="shortKikan"
                      element="data-shortKikan"
                      onChange={(e) => this.handleChangeRowInfor(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col sx={24} md={14} lg={12}>
            {list_content.map((item, index) => (
              <Row
                className="border-black mb-1"
                style={{ padding: "0.5rem" }}
                key={index}
                gutter={10}
              >
                <Col
                  sx={24}
                  ms={24}
                  md={24}
                  style={{ marginBottom: 10 }}
                  className="header-table-tab2 text-bold w-full"
                >
                  <Row type="flex" justify="space-between">
                    <Col span={20} style={{ display: "flex" }}>
                      援助内容{index + 1} 順序
                      <MediaQuery query="(min-device-width: 1024px)">
                        {(matches) => {
                          if (matches) {
                            return (
                              <div style={{ display: "flex", marginLeft: 10 }}>
                                <Button
                                  size="small"
                                  className="btn-tab-delete white mr-1"
                                  disabled={item.sortNum === 1}
                                  onClick={() =>
                                    this.props.upRowKyotakuPlan2Detail(
                                      item.sortNum,
                                      this.props.expandedRowKeys
                                    )
                                  }
                                >
                                  上へ
                                  <CaretUpOutlined />
                                </Button>
                                <Button
                                  size="small"
                                  className="btn-tab-delete white mr-1"
                                  onClick={() =>
                                    this.props.downRowKyotakuPlan2Detail(
                                      item.sortNum,
                                      this.props.expandedRowKeys
                                    )
                                  }
                                  disabled={
                                    item.sortNum === list_content.length
                                  }
                                >
                                  下へ
                                  <CaretDownOutlined />
                                </Button>
                              </div>
                            );
                          }
                          return (
                            <div style={{ display: "flex", marginLeft: 10 }}>
                              <Button
                                size="small"
                                className="btn-tab-delete white mr-1"
                                disabled={item.sortNum === 1}
                                onClick={() =>
                                  this.props.upRowKyotakuPlan2Detail(
                                    item.sortNum,
                                    this.props.expandedRowKeys
                                  )
                                }
                              >
                                <CaretUpOutlined />
                              </Button>
                              <Button
                                size="small"
                                className="btn-tab-delete white mr-1"
                                onClick={() =>
                                  this.props.downRowKyotakuPlan2Detail(
                                    item.sortNum,
                                    this.props.expandedRowKeys
                                  )
                                }
                                disabled={item.sortNum === list_content.length}
                              >
                                <CaretDownOutlined />
                              </Button>
                            </div>
                          );
                        }}
                      </MediaQuery>
                    </Col>
                    <Col span={4} className="flex-end">
                      <Button
                        className="btn-tab-delete white"
                        size="small"
                        onClick={() => this.showModalDelete(item.sortNum)}
                        style={{ cursor: "pointer" }}
                      >
                        削除
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col sx={24} ms={24} md={12} style={{ marginBottom: 10 }}>
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginBottom: 3 }}
                  >
                    <Col span={20} className="text-bold">
                      サービス内容
                    </Col>
                    <Col span={4} className="flex-end">
                      <Button
                        type="primary"
                        size="small"
                        style={{ padding: "0 4px" }}
                        onClick={() =>
                          this.showModalCareTerm(
                            "sienNaiyou",
                            "detail",
                            index,
                            "サービス内容"
                          )
                        }
                      >
                        用語
                      </Button>
                    </Col>
                  </Row>
                  <TextAreaResize
                    style={{ color: "rgba(0, 0, 0, 0.65)" }}
                    name="sienNaiyou"
                    element={`data-sienNaiyou${index}`}
                    value={item.sienNaiyou}
                    onChange={(e) => this.handleChange(e, index)}
                  />
                </Col>
                <Col sx={24} ms={24} md={6} style={{ marginBottom: 10 }}>
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginBottom: 3 }}
                  >
                    <Col span={16} className="text-bold">
                      担当者
                    </Col>
                    <Col span={8} className="flex-end">
                      <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        style={{ padding: "0 4px" }}
                        onClick={() =>
                          this.showYougoModal(
                            "施設サービス担当",
                            "jigyousyo",
                            item.jigyousyo,
                            "detail",
                            index
                          )
                        }
                      >
                        M
                      </Button>
                    </Col>
                  </Row>
                  <TextAreaResize
                    style={{ color: "rgba(0, 0, 0, 0.65)" }}
                    name="jigyousyo"
                    element={`data-jigyousyo${index}`}
                    value={item.jigyousyo}
                    onChange={(e) => this.handleChange(e, index)}
                  />
                </Col>
                <Col sx={24} ms={24} md={6} style={{ marginBottom: 10 }}>
                  <div style={{ marginBottom: 10 }}>
                    <Row
                      type="flex"
                      justify="space-between"
                      style={{ marginBottom: 3 }}
                    >
                      <Col span={16} className="text-bold">
                        頻度
                      </Col>
                      <Col span={8} className="flex-end">
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          style={{ padding: "0 4px" }}
                          onClick={() =>
                            this.showYougoModal(
                              "介護内容頻度施設",
                              "hindo",
                              item.hindo,
                              "detail",
                              index
                            )
                          }
                        >
                          M
                        </Button>
                      </Col>
                    </Row>
                    <TextAreaResize
                      style={{ color: "rgba(0, 0, 0, 0.65)" }}
                      name="hindo"
                      element={`data-hindo${index}`}
                      value={item.hindo}
                      onChange={(e) => this.handleChange(e, index)}
                    />
                  </div>
                  <div>
                    <Row
                      type="flex"
                      justify="space-between"
                      style={{ marginBottom: 3 }}
                    >
                      <Col span={20} className="text-bold">
                        期間
                      </Col>
                      <Col span={4} className="flex-end">
                        <Button
                          type="primary"
                          size="small"
                          shape="circle"
                          style={{ padding: "0 4px" }}
                          onClick={() =>
                            this.showModalSetTime("kikan", index, item.kikan)
                          }
                          className="mr-1"
                        >
                          入
                        </Button>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          style={{ padding: "0 4px" }}
                          onClick={() =>
                            this.showYougoModal(
                              "介護内容期間施設",
                              "kikan",
                              item.kikan,
                              "detail",
                              index
                            )
                          }
                        >
                          M
                        </Button>
                      </Col>
                    </Row>
                    <TextAreaResize
                      style={{ color: "rgba(0, 0, 0, 0.65)" }}
                      name="kikan"
                      element={`data-kikan${index}`}
                      value={item.kikan}
                      onChange={(e) => this.handleChange(e, index)}
                    />
                  </div>
                </Col>
              </Row>
            ))}
            <Button
              icon={<LegacyIcon type={"plus"} />}
              type="primary"
              danger
              size="small"
              onClick={() =>
                this.props.addDetailKyotakuPlan2(this.props.expandedRowKeys, recordInfo)
              }
              style={{ cursor: "pointer", float: "right" }}
            >
              援助内容追加
            </Button>
          </Col>
        </Row>
        <Modal
          open={confirmModalVisible}
          onOk={this.handleDeleteDetailOk}
          onCancel={this.handleDeleteDetailCancel}
        >
          <p>選択中のデータを削除しますか?</p>
        </Modal>
        <SingleTime
          listContent={list_content}
          modalSetTimeVisible={modalSetTimeVisible}
          handleSetTimeOk={this.handleSetTimeOk}
          handleSetTimeCancel={this.handleSetTimeCancel}
          kikanType={kikanType}
          indexDetail={indexDetail}
          startDate={timeModal.startDate ? checkCalendarIntoGeneral(timeModal.startDate, settingData) : null}
          endDate={timeModal.endDate ? checkCalendarIntoGeneral(timeModal.endDate, settingData) : null}
          period={timeModal.period}
          timeModal={timeModal}
          recordInfo={recordInfo}
          settingData={settingData}
        />
        <CareTermDictModal
          visible={this.state.careTermVisible}
          linkingCareTerm={this.state.linkingCareTerm}
          handleSelectCareTerm={(data) => this.handleSelectCareTerm(data)}
          hideModalCareTerm={() => this.hideModalCareTerm()}
        />
        <YougoModal
          visible={inputVisible}
          onCancel={() => this.handleYougoCancel()}
          onSubmit={(values) => this.handleYougoOk(values)}
          listName={listName}
          field={this.state.field}
          values={this.state.valueYougo}
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kyotakuPlan2Details: state.kyotakuPlan2.kyotakuPlan2Details,
    expandedRowKeys: state.kyotakuPlan2.expandedRowKeys,
    startDate: state.riyouKaigohokens.startDate,
    endDate: state.riyouKaigohokens.endDate,
    yougoMasterNaiyou: state.yougoMasterNaiyou.yougoMasterNaiyou,
    edit: state.kyotakuPlan2.editMode,
    editDetail: state.kyotakuPlan2.editDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDetailKyotakuPlan2: (expandedRowKeys, recordInfo) =>
      dispatch(addDetailKyotakuPlan2(expandedRowKeys, recordInfo)),
    deleteDetailKyotakuPlan2: (sortNum) =>
      dispatch(deleteDetailKyotakuPlan2(sortNum)),
    getDetailKyotakuPlan2: (data) => dispatch(getDetailKyotakuPlan2(data)),
    clearDetailKyotakuPlan2: (data) => dispatch(clearDetailKyotakuPlan2(data)),
    getRowInfolKyotakuPlan2: (data) => dispatch(getRowInfolKyotakuPlan2(data)),
    updateRowKyotakuPlan2: (sortNum, data) =>
      dispatch(updateRowKyotakuPlan2(sortNum, data)),
    yougoMasterEditState: (index) =>
      dispatch(yougoMasterEditStateCreator(index)),
    yougoMasterEditCancel: (index) =>
      dispatch(yougoMasterEditCancelCreator(index)),
    yougoMasterEditSave: (naiyou) =>
      dispatch(yougoMasterEditSaveCreator(naiyou)),
    changeEditMode: (mode) => dispatch(changeEditMode(mode)),
    getYougoMaster: (listName) =>
      dispatch(getKyotakuPlan2YougoMaster(listName)),
    changeValuePlan2: (type) => dispatch(changeValuePlan2(type)),
    upRowKyotakuPlan2Detail: (sortNum, expandedRowKeys) =>
      dispatch(upRowKyotakuPlan2Detail(sortNum, expandedRowKeys)),
    downRowKyotakuPlan2Detail: (sortNum, expandedRowKeys) =>
      dispatch(downRowKyotakuPlan2Detail(sortNum, expandedRowKeys)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RowInfo);
