import axios from "axios";
import DataFilterApi from "../../api/reportFilter";
import {
  FukuyakuDelete,
  FukuyakuInsert,
  FukuyakuUpdate,
  GazouDelete,
  GazouInsert,
  GazouUpdate,
  HaisetuDelete,
  HaisetuInsert,
  HaisetuUpdate,
  KaigonaiyoDelete,
  KaigonaiyoInsert,
  KaigonaiyoUpdate,
  LoadDataApi,
  MealDelete,
  MealInsert,
  MealUpdate,
  NyuyokuDelete,
  NyuyokuInsert,
  NyuyokuUpdate,
  SuibunDelete,
  SuibunInsert,
  SuibunUpdate,
  TokkiDelete,
  TokkiInsert,
  TokkiUpdate,
  VitalDelete,
  VitalInsert,
  VitalUpdate,
  ZenkaiLoad,
} from "../../api/reports";
import { apiUrls } from "../../common/configs/common-url";
import { ConstSet } from "../../common/configs/constset";
import { changeSortTab } from "../../common/function_common/functionCommon";
import jsonLocal from "../../data/jsonLocal";
import {
  API_CALL_REQUEST,
  ERROR_REQUEST,
  MULTIPLE_UPDATE_ERROR,
} from "../commonActions/actionName";
import {
  ACTION_DEFAULT,
  BAITARU_COPY,
  BAITARU_DELETE,
  FUKUYAKU_COPY,
  FUKUYAKU_DELETE,
  GAZOU_COPY,
  GAZOU_DELETE,
  GET_HAIBEN_DAY,
  HAISETU_COPY,
  HAISETU_DELETE,
  KAIGONAIYOU_COPY,
  KAIGONAIYOU_DELETE,
  KIROKU_DELETE_REQUEST,
  KIROKU_DELETE_SUCCESS,
  KIROKU_INSERT_ERROR,
  KIROKU_INSERT_MUL_REQUEST,
  KIROKU_INSERT_MUL_SUCCESS,
  KIROKU_INSERT_REQUEST,
  KIROKU_INSERT_SUCCESS,
  KIROKU_UPDATE_ERROR,
  KIROKU_UPDATE_MUL_REQUEST,
  KIROKU_UPDATE_MUL_SUCCESS,
  KIROKU_UPDATE_REQUEST,
  KIROKU_UPDATE_SUCCESS,
  MEAL_COPY,
  MEAL_DELETE,
  NYUYOKU_COPY,
  NYUYOKU_DELETE,
  REPORT_BAITARU_REQUEST,
  REPORT_BAITARU_SUCCESS,
  REPORT_FILTER_REQUEST,
  REPORT_FILTER_SUCCESS,
  REPORT_FUKUYAKU_REQUEST,
  REPORT_FUKUYAKU_SUCCESS,
  REPORT_GAZOU_REQUEST,
  REPORT_GAZOU_SUCCESS,
  REPORT_HAISETU_REQUEST,
  REPORT_HAISETU_SUCCESS,
  REPORT_KAIGONAIYOU_REQUEST,
  REPORT_KAIGONAIYOU_SUCCESS,
  REPORT_LOAD_ZENKAI_REQUEST,
  REPORT_LOAD_ZENKAI_SUCCESS,
  REPORT_MEAL_REQUEST,
  REPORT_MEAL_SUCCESS,
  REPORT_NYUYOKU_REQUEST,
  REPORT_NYUYOKU_SUCCESS,
  REPORT_REFRESH,
  REPORT_RIYOUSYA_REQUEST,
  REPORT_RIYOUSYA_SUCCESS,
  REPORT_SEARCH_SUCCESS,
  REPORT_SEARCHBYGENDER_SUCCESS,
  REPORT_SEARCHBYNAME_SUCCESS,
  REPORT_SORT,
  REPORT_SUIBUN_REQUEST,
  REPORT_SUIBUN_SUCCESS,
  REPORT_TOKKI_REQUEST,
  REPORT_TOKKI_SUCCESS,
  SUIBUN_COPY,
  SUIBUN_DELETE,
  TOKKI_COPY,
  TOKKI_DELETE,
} from "./actionName.js";

function getHaibenDay() {
  return (dispatch) => {
    // dispatch({
    //   type: REPORT_RIYOUSYA_REQUEST,
    // })

    axios(apiUrls.INIT + apiUrls.GET_HAIBEN_DAY, {
      method: "GET",
    }).then((data) =>
      dispatch({
        type: GET_HAIBEN_DAY,
        data,
      })
    );
  };
}

function loadDataCreator(parameterObj) {
  switch (parameterObj.tabName) {
    case "利用者":
      return (dispatch) => {
        dispatch({
          type: REPORT_RIYOUSYA_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_RIYOUSYA_SUCCESS,
            payload: data,
          })
        ).catch((err) => ({
          type: ERROR_REQUEST,
          error: err,
        }));
      };

    case "バイタル":
      return (dispatch) => {
        dispatch({
          type: REPORT_BAITARU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj)
          .then((data) =>
            dispatch({
              type: REPORT_BAITARU_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case "食事摂取":
      return (dispatch) => {
        dispatch({
          type: REPORT_MEAL_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_MEAL_SUCCESS,
            payload: data,
          })
        );
      };

    case "水分摂取":
      return (dispatch) => {
        dispatch({
          type: REPORT_SUIBUN_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_SUIBUN_SUCCESS,
            payload: data,
          })
        );
      };
    case "排泄":
      return (dispatch) => {
        dispatch({
          type: REPORT_HAISETU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_HAISETU_SUCCESS,
            payload: data,
          })
        );
      };
    case "入浴":
      return (dispatch) => {
        dispatch({
          type: REPORT_NYUYOKU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_NYUYOKU_SUCCESS,
            payload: data,
          })
        );
      };
    case "服薬":
      return (dispatch) => {
        dispatch({
          type: REPORT_FUKUYAKU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_FUKUYAKU_SUCCESS,
            payload: data,
          })
        );
      };
    case "提供記録":
      return (dispatch) => {
        dispatch({
          type: REPORT_KAIGONAIYOU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_KAIGONAIYOU_SUCCESS,
            payload: data,
          })
        );
      };
    case "特記":
      return (dispatch) => {
        dispatch({
          type: REPORT_TOKKI_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_TOKKI_SUCCESS,
            payload: data,
          })
        );
      };
    case "画像等":
      return (dispatch) => {
        dispatch({
          type: REPORT_GAZOU_REQUEST,
        });

        LoadDataApi.loadDataApi(parameterObj).then((data) =>
          dispatch({
            type: REPORT_GAZOU_SUCCESS,
            payload: data,
          })
        );
      };
    /**
     * @TODO handle exception tabName null
     */
    default:
      return (dispatch) => {
        dispatch({
          type: ACTION_DEFAULT,
        });
      };
  }
}

/**
 *
 * @param {*} classify1
 * @param {*} classify2
 * @param {*} nyutype
 * @param {*} floor
 */
function searchCreator(searchData, parameterObj) {
  localStorage.setItem("is_keep", JSON.stringify(searchData)); // save to localstorate
  return (dispatch) => {
    dispatch({
      type: API_CALL_REQUEST,
    });

    LoadDataApi.loadDataApi(parameterObj).then((data) =>
      dispatch({
        type: REPORT_SEARCH_SUCCESS,
        payload: data,
        searchData,
      })
    );
  };
}

function searchByNameCreator(name, parameterObj) {
  const tmpUpdate = JSON.parse(localStorage.getItem("is_keep"));
  tmpUpdate.searchName = name;
  localStorage.setItem("is_keep", JSON.stringify(tmpUpdate)); // save to localstorate
  return (dispatch) => {
    dispatch({
      type: API_CALL_REQUEST,
    });

    LoadDataApi.loadDataApi(parameterObj).then((data) =>
      dispatch({
        type: REPORT_SEARCHBYNAME_SUCCESS,
        payload: data,
        name,
      })
    );
  };
}

function searchByGenderCreator(gender, parameterObj) {
  return (dispatch) => {
    dispatch({
      type: API_CALL_REQUEST,
    });

    LoadDataApi.loadDataApi(parameterObj).then((data) =>
      dispatch({
        type: REPORT_SEARCHBYGENDER_SUCCESS,
        payload: data,
        gender,
      })
    );
  };
}

/**
 *
 * @param {*} null
 */
function filterCreator(tabName) {
  return (dispatch) => {
    dispatch({
      type: REPORT_FILTER_REQUEST,
    });

    DataFilterApi.dataFilterApi(tabName).then((data) =>
      dispatch({
        type: REPORT_FILTER_SUCCESS,
        payload: data,
      })
    );
  };
}

/**
 *
 * @param {*} sortData
 */
function sortCreator(sortData, parameterObj) {
  const tmpUpdate = JSON.parse(localStorage.getItem("is_keep"));
  const sortTabName = changeSortTab(parameterObj.tabName);
  tmpUpdate[sortTabName] = sortData[sortTabName];
  localStorage.setItem("is_keep", JSON.stringify(tmpUpdate)); // save to localstorate
  return (dispatch) => {
    dispatch({
      type: API_CALL_REQUEST,
    });

    LoadDataApi.loadDataApi(parameterObj).then((data) =>
      dispatch({
        type: REPORT_SORT,
        payload: data,
        sortData,
      })
    );
  };
}

/**
 *
 * @param {*} null
 */
function refreshCreator(parameterObj) {
  localStorage.setItem("is_keep", JSON.stringify(jsonLocal)); // save to localstorate
  return (dispatch) => {
    dispatch({
      type: API_CALL_REQUEST,
    });

    LoadDataApi.loadDataApi(parameterObj).then((data) =>
      dispatch({
        type: REPORT_REFRESH,
        payload: data,
      })
    );
  };
}

/**
 *
 * @param {*} baitaruRecord
 */
function baitaruCopyCreator(baitaruRecord) {
  return (dispatch) => {
    dispatch({
      type: BAITARU_COPY,
      baitaruRecord,
    });
  };
}

/**
 *
 * @param {*} baitaruRecord
 */
function baitaruDelCreator(baitaruRecord) {
  return (dispatch) => {
    dispatch({
      type: BAITARU_DELETE,
      baitaruRecord,
    });
  };
}

/**
 *
 * @param {*} mealRecord
 */
function mealCopyCreator(mealRecord) {
  return (dispatch) => {
    dispatch({
      type: MEAL_COPY,
      mealRecord,
    });
  };
}

/**
 *
 * @param {*} mealRecord
 */
function mealDelCreator(mealRecord) {
  return (dispatch) => {
    dispatch({
      type: MEAL_DELETE,
      mealRecord,
    });
  };
}

/**
 *
 * @param {*} suibunRecord
 */
function suibunCopyCreator(suibunRecord) {
  return (dispatch) => {
    dispatch({
      type: SUIBUN_COPY,
      suibunRecord,
    });
  };
}

/**
 *
 * @param {*} suibunRecord
 */
function suibunDelCreator(suibunRecord) {
  return (dispatch) => {
    dispatch({
      type: SUIBUN_DELETE,
      suibunRecord,
    });
  };
}

/**
 *
 * @param {*} haisetuRecord
 */
function haisetuCopyCreator(haisetuRecord) {
  return (dispatch) => {
    dispatch({
      type: HAISETU_COPY,
      haisetuRecord,
    });
  };
}

/**
 *
 * @param {*} haisetuRecord
 */
function haisetuDelCreator(haisetuRecord) {
  return (dispatch) => {
    dispatch({
      type: HAISETU_DELETE,
      haisetuRecord,
    });
  };
}

/**
 *
 * @param {*} nyuyokuRecord
 */
function nyuyokuCopyCreator(nyuyokuRecord) {
  return (dispatch) => {
    dispatch({
      type: NYUYOKU_COPY,
      nyuyokuRecord,
    });
  };
}

/**
 *
 * @param {*} nyuyokuRecord
 */
function nyuyokuDelCreator(nyuyokuRecord) {
  return (dispatch) => {
    dispatch({
      type: NYUYOKU_DELETE,
      nyuyokuRecord,
    });
  };
}

/**
 *
 * @param {*} fukuyakuRecord
 */
function fukuyakuCopyCreator(fukuyakuRecord) {
  return (dispatch) => {
    dispatch({
      type: FUKUYAKU_COPY,
      fukuyakuRecord,
    });
  };
}

/**
 *
 * @param {*} fukuyakuRecord
 */
function fukuyakuDelCreator(fukuyakuRecord) {
  return (dispatch) => {
    dispatch({
      type: FUKUYAKU_DELETE,
      fukuyakuRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function kaigonaiyouCopyCreator(kaigonaiyouRecord) {
  return (dispatch) => {
    dispatch({
      type: KAIGONAIYOU_COPY,
      kaigonaiyouRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function kaigonaiyouDelCreator(kaigonaiyouRecord) {
  return (dispatch) => {
    dispatch({
      type: KAIGONAIYOU_DELETE,
      kaigonaiyouRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function tokkiCopyCreator(tokkiRecord) {
  return (dispatch) => {
    dispatch({
      type: TOKKI_COPY,
      tokkiRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function tokkiDelCreator(tokkiRecord) {
  return (dispatch) => {
    dispatch({
      type: TOKKI_DELETE,
      tokkiRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function gazoCopyCreator(gazoRecord) {
  return (dispatch) => {
    dispatch({
      type: GAZOU_COPY,
      gazoRecord,
    });
  };
}

/**
 *
 * @param {*} kaigonaiyouRecord
 */
function gazoDelCreator(gazoRecord) {
  return (dispatch) => {
    dispatch({
      type: GAZOU_DELETE,
      gazoRecord,
    });
  };
}

function updateKirokuCreator(params, callback) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_REQUEST,
        });

        VitalUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? KIROKU_UPDATE_ERROR
                : KIROKU_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_REQUEST,
        });

        MealUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? KIROKU_UPDATE_ERROR
                : KIROKU_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_REQUEST,
        });

        SuibunUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? KIROKU_UPDATE_ERROR
                : KIROKU_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_REQUEST,
        });

        HaisetuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? KIROKU_UPDATE_ERROR
                : KIROKU_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_REQUEST,
        });

        NyuyokuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? KIROKU_UPDATE_ERROR
                : KIROKU_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_REQUEST,
        });

        FukuyakuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? KIROKU_UPDATE_ERROR
                : KIROKU_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_REQUEST,
        });

        KaigonaiyoUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? KIROKU_UPDATE_ERROR
                : KIROKU_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_REQUEST,
        });

        TokkiUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? KIROKU_UPDATE_ERROR
                : KIROKU_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_FILE:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_REQUEST,
        });

        GazouUpdate.update(params).then((data) => {
          dispatch({
            type:
              data.status && data.status !== 200
                ? KIROKU_UPDATE_ERROR
                : KIROKU_UPDATE_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          });

          return callback(data);
        });
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

function insertKirokuCreator(params, callback) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_REQUEST,
        });

        VitalInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? KIROKU_INSERT_ERROR
                  : KIROKU_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: KIROKU_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_REQUEST,
        });

        MealInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? KIROKU_INSERT_ERROR
                  : KIROKU_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: KIROKU_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_REQUEST,
        });

        SuibunInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? KIROKU_INSERT_ERROR
                  : KIROKU_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: KIROKU_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_REQUEST,
        });

        HaisetuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? KIROKU_INSERT_ERROR
                  : KIROKU_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: KIROKU_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_REQUEST,
        });

        NyuyokuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? KIROKU_INSERT_ERROR
                  : KIROKU_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: KIROKU_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_REQUEST,
        });

        FukuyakuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? KIROKU_INSERT_ERROR
                  : KIROKU_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: KIROKU_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_REQUEST,
        });

        KaigonaiyoInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? KIROKU_INSERT_ERROR
                  : KIROKU_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: KIROKU_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_REQUEST,
        });

        TokkiInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? KIROKU_INSERT_ERROR
                  : KIROKU_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: KIROKU_INSERT_ERROR,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FILE:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_REQUEST,
        });

        GazouInsert.insert(params)
          .then((data) => {
            dispatch({
              type:
                data.status && data.status !== 200
                  ? KIROKU_INSERT_ERROR
                  : KIROKU_INSERT_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            });

            return callback(data);
          })
          .catch((err) => ({
            type: KIROKU_INSERT_ERROR,
            error: err,
          }));
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

function updateKirokuMulCreator(params, callback) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_MUL_REQUEST,
        });

        VitalUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? MULTIPLE_UPDATE_ERROR
                : KIROKU_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_MUL_REQUEST,
        });

        MealUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? MULTIPLE_UPDATE_ERROR
                : KIROKU_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_MUL_REQUEST,
        });

        SuibunUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? MULTIPLE_UPDATE_ERROR
                : KIROKU_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_MUL_REQUEST,
        });

        HaisetuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? MULTIPLE_UPDATE_ERROR
                : KIROKU_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_MUL_REQUEST,
        });

        NyuyokuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? MULTIPLE_UPDATE_ERROR
                : KIROKU_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_MUL_REQUEST,
        });

        FukuyakuUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? MULTIPLE_UPDATE_ERROR
                : KIROKU_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_MUL_REQUEST,
        });

        KaigonaiyoUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? MULTIPLE_UPDATE_ERROR
                : KIROKU_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_MUL_REQUEST,
        });

        TokkiUpdate.update(params).then((data) =>
          dispatch({
            type:
              data.status && data.status !== 200
                ? MULTIPLE_UPDATE_ERROR
                : KIROKU_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          })
        );
      };

    case ConstSet.KIROKU_FILE:
      return (dispatch) => {
        dispatch({
          type: KIROKU_UPDATE_MUL_REQUEST,
        });

        GazouUpdate.update(params).then((data) => {
          dispatch({
            type:
              data.status && data.status !== 200
                ? MULTIPLE_UPDATE_ERROR
                : KIROKU_UPDATE_MUL_SUCCESS,
            payload: data,
            paramsUpdate: params, // update at local
          });

          return callback(data);
        });
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

function insertKirokuMulCreator(params, callback) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_MUL_REQUEST,
        });

        VitalInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? MULTIPLE_UPDATE_ERROR
                  : KIROKU_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_MUL_REQUEST,
        });

        MealInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? MULTIPLE_UPDATE_ERROR
                  : KIROKU_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_MUL_REQUEST,
        });

        SuibunInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? MULTIPLE_UPDATE_ERROR
                  : KIROKU_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_MUL_REQUEST,
        });

        HaisetuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? MULTIPLE_UPDATE_ERROR
                  : KIROKU_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_MUL_REQUEST,
        });

        NyuyokuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? MULTIPLE_UPDATE_ERROR
                  : KIROKU_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_MUL_REQUEST,
        });

        FukuyakuInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? MULTIPLE_UPDATE_ERROR
                  : KIROKU_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_MUL_REQUEST,
        });

        KaigonaiyoInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? MULTIPLE_UPDATE_ERROR
                  : KIROKU_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_MUL_REQUEST,
        });

        TokkiInsert.insert(params)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? MULTIPLE_UPDATE_ERROR
                  : KIROKU_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FILE:
      return (dispatch) => {
        dispatch({
          type: KIROKU_INSERT_MUL_REQUEST,
        });

        GazouInsert.insert(params)
          .then((data) => {
            dispatch({
              type:
                data.status && data.status !== 200
                  ? MULTIPLE_UPDATE_ERROR
                  : KIROKU_INSERT_MUL_SUCCESS,
              payload: data,
              paramsUpdate: params, // update at local
            });

            return callback(data);
          })
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

function deleteKirokuCreator(recordId) {
  const tabName = localStorage.getItem("tabActive");
  switch (tabName) {
    case ConstSet.KIROKU_VITAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_DELETE_REQUEST,
        });

        VitalDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? ERROR_REQUEST
                  : KIROKU_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_MEAL:
      return (dispatch) => {
        dispatch({
          type: KIROKU_DELETE_REQUEST,
        });

        MealDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? ERROR_REQUEST
                  : KIROKU_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_SUIBUN:
      return (dispatch) => {
        dispatch({
          type: KIROKU_DELETE_REQUEST,
        });

        SuibunDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? ERROR_REQUEST
                  : KIROKU_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_HAISETU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_DELETE_REQUEST,
        });

        HaisetuDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? ERROR_REQUEST
                  : KIROKU_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_NYUYOKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_DELETE_REQUEST,
        });

        NyuyokuDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? ERROR_REQUEST
                  : KIROKU_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FUKUYAKU:
      return (dispatch) => {
        dispatch({
          type: KIROKU_DELETE_REQUEST,
        });

        FukuyakuDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? ERROR_REQUEST
                  : KIROKU_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_KAIGO:
      return (dispatch) => {
        dispatch({
          type: KIROKU_DELETE_REQUEST,
        });

        KaigonaiyoDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? ERROR_REQUEST
                  : KIROKU_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_TOKKI:
      return (dispatch) => {
        dispatch({
          type: KIROKU_DELETE_REQUEST,
        });

        TokkiDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? ERROR_REQUEST
                  : KIROKU_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    case ConstSet.KIROKU_FILE:
      return (dispatch) => {
        dispatch({
          type: KIROKU_DELETE_REQUEST,
        });

        GazouDelete.delete(recordId)
          .then((data) =>
            dispatch({
              type:
                data.status && data.status !== 200
                  ? ERROR_REQUEST
                  : KIROKU_DELETE_SUCCESS,
              payload: data,
            })
          )
          .catch((err) => ({
            type: ERROR_REQUEST,
            error: err,
          }));
      };

    /**
     * @TODO: handle exception error
     */
    default:
      return;
  }
}

function loadZenkaiCreator(date, riyousyaId, time) {
  return (dispatch) => {
    dispatch({
      type: REPORT_LOAD_ZENKAI_REQUEST,
    });

    ZenkaiLoad.load(date, riyousyaId, time)
      .then((data) =>
        dispatch({
          type:
            data.status && data.status !== 200
              ? ERROR_REQUEST
              : REPORT_LOAD_ZENKAI_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: ERROR_REQUEST,
        error: err,
      }));
  };
}

export {
  baitaruCopyCreator,
  baitaruDelCreator, deleteKirokuCreator, filterCreator, fukuyakuCopyCreator,
  fukuyakuDelCreator, gazoCopyCreator,
  gazoDelCreator, getHaibenDay, haisetuCopyCreator,
  haisetuDelCreator, insertKirokuCreator, insertKirokuMulCreator, kaigonaiyouCopyCreator,
  kaigonaiyouDelCreator, loadDataCreator, loadZenkaiCreator, mealCopyCreator,
  mealDelCreator, nyuyokuCopyCreator,
  nyuyokuDelCreator, refreshCreator,
  searchByGenderCreator, searchByNameCreator, searchCreator, sortCreator, suibunCopyCreator,
  suibunDelCreator, tokkiCopyCreator,
  tokkiDelCreator, updateKirokuCreator, updateKirokuMulCreator
};

