import axios from "axios/index";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
const GetAvatar = {
  getAvatar() {
    return axios({
      method: "get",
      url: apiUrls.GET_AVATAR,
      withCredentials: true,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        }
        else {
          return err;
        }
      });
  },
};
export default GetAvatar;
