import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Radio } from "antd";
function DayOfWeekModal(props) {
  const [frequency, setFrequency] = useState();
  const FrequencyOptions = [
    { key: 1, label: "月", value: 1 },
    { key: 2, label: "火", value: 2 },
    { key: 3, label: "水", value: 3 },
    { key: 4, label: "木", value: 4 },
    { key: 5, label: "金", value: 5 },
    { key: 6, label: "土", value: 6 },
    { key: 0, label: "日", value: 0 },
  ];
  useEffect(() => {
    if (props.recordSelected) {
      const obj = FrequencyOptions.find(
        (item) => item.label === props.recordSelected.youbi
      );
      setFrequency(obj ? obj.value : null);
    }
  }, [props.recordSelected]);

  function handleChangeDayOfWeek(e) {
    setFrequency(e.target.value);
  }

  function handleCancel() {
    props.onCancel();
  }

  function handleOk() {
    props.onOk(props.recordSelected.key, frequency + 1);
  }

  return (
    (<Modal
      open={props.visible}
      title={props.title}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={handleCancel}
        >
          キャンセル
        </Button>,
        <Button
          key="back"
          type="primary"
          onClick={handleOk}
          disabled={!(frequency >= 0)}
        >
          OK
        </Button>,
      ]}
      width={450}
    >
      <Row>
        <Radio.Group
          options={FrequencyOptions}
          onChange={handleChangeDayOfWeek}
          value={frequency}
        />
      </Row>
    </Modal>)
  );
}

export { DayOfWeekModal };
