import { AuthActionTypes } from "../action_types";

function auth(state = {}, action) {
  switch (action.type) {
    // signin
    case AuthActionTypes.SIGNIN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case AuthActionTypes.SIGNIN_SUCCESS: {
      return {
        ...state,
        isUserLoggedIn: true,
        loading: false,
        gmk: action.gmk,
        menu: action.menu,
        mfs: action.mfs,
        sessionTimeout: action.sessionTimeout,
        jwtTokenType: action.jwtTokenType,
        error: "",
      };
    }
    case AuthActionTypes.SIGNIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: "LOGIN_FAIL",
      };
    }

    // signout
    case AuthActionTypes.SIGNOUT: {
      return {
        ...state,
        isUserLoggedIn: false,
        gmk: "",
        menu: {},
        mfs: "",
        sessionTimeout: "",
      };
    }

    // maintainance mode
    case AuthActionTypes.MAINTAINANCE_MODE_REQUEST: {
      return {
        ...state,
        loadMode: true,
      };
    }
    case AuthActionTypes.MAINTAINANCE_MODE_SUCCESS: {
      return {
        ...state,
        dataMode: action.dataMode,
        systemTitle: action.systemTitle,
        loadMode: false,
      };
    }
    case AuthActionTypes.MAINTAINANCE_MODE_FAILURE: {
      return {
        ...state,
        dataMode: "",
        loadMode: false,
      };
    }
    default: {
      return { ...state };
    }
  }
}

export default auth;
