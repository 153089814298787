import React from "react";

function NotFound() {
  return (
    <div className="wrapper text-center">
      <img
        src={require("../../../styles/img/not_found_page.jpg")}
        alt="Page Not Found 404"
        height="100%"
        width="100%"
      />
    </div>
  );
}

export default NotFound;
