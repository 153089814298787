export const LocalStorage = {
  ListSortRiyousya: "list_sort_riyousya",
  YobouListSortRiyousya: "yobou-list_sort_riyousya",
  ListRowExpandDocumentTab: "list_row_expand_document_tab",
  MonitoringManagement: "monitoring_management",
  ChangeCell: "change_cell_weekly",
  TabWeekly: "tab_weekly",
  WeeklySchedule: "weekly_schedule",
  WeeklyRental: "weekly_rental",
};
