import { ApiPaths } from "../../constants/api_paths";
import { KihonCheckActionTypes, ErrorActionTypes } from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils } from "../../utils/index";

function getQuoteList(riyousyaId, kihonCheckId) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({
        type: KihonCheckActionTypes.GET_ALL_KIHON_CHECK_QUOTE_REQUEST,
      });
      const query = {
        riyousyaId,
        kihonCheckId,
        sid,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.KihonCheck.GetQuote,
        query,
      });
      dispatch({
        type: KihonCheckActionTypes.GET_ALL_KIHON_CHECK_QUOTE_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: KihonCheckActionTypes.GET_ALL_KIHON_CHECK_QUOTE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getAll(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: KihonCheckActionTypes.GET_ALL_KIHON_CHECK_REQUEST });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.KihonCheck.GetList,
        query,
      });
      dispatch({
        type: KihonCheckActionTypes.GET_ALL_KIHON_CHECK_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: KihonCheckActionTypes.GET_ALL_KIHON_CHECK_QUOTE_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: KihonCheckActionTypes.GET_KIHON_CHECK_REQUEST });
      const query = {
        id: Number(id),
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.KihonCheck.GetById,
        query,
      });
      dispatch({
        type: KihonCheckActionTypes.GET_KIHON_CHECK_SUCCESS,
        data,
      });
      // return callback(data)
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: KihonCheckActionTypes.GET_KIHON_CHECK_FAILURE,
        error,
      });
    }
  };
}

function getItemList() {
  return async (dispatch) => {
    try {
      dispatch({ type: KihonCheckActionTypes.GET_KIHON_CHECK_ITEM_REQUEST });

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.KihonCheck.GetItemList,
      });
      dispatch({
        type: KihonCheckActionTypes.GET_KIHON_CHECK_ITEM_SUCCESS,
        data,
      });
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: KihonCheckActionTypes.GET_KIHON_CHECK_ITEM_FAILURE,
        error,
      });
    }
  };
}

function create(value, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: KihonCheckActionTypes.CREATE_KIHON_CHECK_REQUEST });
      const data = await RequestUtils.callApi({
        method: "post",
        path: ApiPaths.KihonCheck.Create,
        body: {
          sid: sid ? sid : null,
          ...value,
        },
      });
      dispatch({
        type: KihonCheckActionTypes.CREATE_KIHON_CHECK_SUCCESS,
        data,
      });
      return callback(data);
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: KihonCheckActionTypes.CREATE_KIHON_CHECK_FAILURE,
        error,
      });
    }
  };
}

function update(value, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: KihonCheckActionTypes.UPDATE_KIHON_CHECK_REQUEST });
      const data = await RequestUtils.callApi({
        method: "put",
        path: ApiPaths.KihonCheck.Update,
        body: {
          sid: sid ? sid : null,
          ...value,
        },
      });
      dispatch({
        type: KihonCheckActionTypes.UPDATE_KIHON_CHECK_SUCCESS,
        data,
      });
      return callback(data);
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: KihonCheckActionTypes.UPDATE_KIHON_CHECK_FAILURE,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: KihonCheckActionTypes.REMOVE_KIHON_CHECK_REQUEST });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.KihonCheck.Delete,
        query,
      });
      dispatch({
        type: KihonCheckActionTypes.REMOVE_KIHON_CHECK_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: KihonCheckActionTypes.REMOVE_KIHON_CHECK_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function newPage() {
  return {
    type: KihonCheckActionTypes.NEW_PAGE,
  };
}

function resetData() {
  return (dispatch) => {
    dispatch({
      type: KihonCheckActionTypes.RESET_CURRENT_KiHON_CHECK,
    });
  };
}

export const KihonCheckActions = {
  getQuoteList,
  getAll,
  deleteId,
  getById,
  getItemList,
  create,
  update,
  resetData,
  newPage,
};
