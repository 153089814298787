/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import {
  Button, // Input,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Row
} from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import {
  addRowsKyotakuPlan2,
  changeExpandedRowKey,
  changeKaigohoken,
  changeValuePlan2,
  getRowKyotakuPlan2,
  keepValue1,
  settingMasterNaiyou,
  updateOnChange,
} from "../../../actions/kyotakuPlan2Actions/actionCreators";
import { getDateRiyouKaigohoken } from "../../../actions/riyouKaigohoken/actionCreators";
import { ConstSet } from "../../../common/configs/constset";
import "../DocumentList.css";
import LastQuoteModal from "../UI/LastQuoteModal";
import RiyouKaigohokenModal from "../UI/RiyouKaigohokenModal";
import ModalSetMultiTime from "./dialog/setMultiTime";
import EditTable from "./editTable";
dayjs.extend(dayjsPluginUTC);

const { TextArea } = Input;
const { MonthPicker } = DatePicker;
class KyotakuPlan2InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kaigohokenVisible: false,
      kaigohokenDisplay: "",
      modalSetMultiTimeVisible: false,
      lastQuoteVisible: false,
      planDateOpen: false,
      douiDateOpen: false,
      loadPage: false,
      changeKaigohoken: false,
      settingData: {},
    };
  }

  componentDidMount() {
    this.props.settingMasterNaiyou("sw期間入力", (data) => {
      this.setState({
        settingData: data,
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // const { changeKaigohoken } = this.state;
    if (this.props.form.isFieldsTouched()) {
      const progressF = this.props.form.getFieldValue("progressF");
      const renew_years = this.props.form.getFieldValue("renew_years");
      const plan_date = this.props.form.getFieldValue("plan_date");
      const plan2_doui_date = this.props.form.getFieldValue("plan2_doui_date");
      const comment = this.props.form.getFieldValue("comment");
      this.props.keepValue1(
        progressF,
        renew_years,
        plan_date,
        plan2_doui_date,
        comment
      );
    }
    if (
      nextProps.riyouKaigohokens &&
      nextProps.riyouKaigohokens !== this.props.riyouKaigohokens
    ) {
      const riyouKaigohokenId = this.props.form.getFieldValue(
        "riyou_kaigohoken_id"
      );
      const item = riyouKaigohokenId
        ? _.find(nextProps.riyouKaigohokens, (k) => k.id == riyouKaigohokenId)
        : nextProps.riyouKaigohokens[0];
      if (item) {
        this.props.getDateRiyouKaigohoken(item.yukoDateFrom, item.yukoDateTo);
        this.setState({
          kaigohokenDisplay: item
            ? item.name +
            "　" +
            "\t有効期限：" +
            item.yukoDateFrom +
            "～" +
            item.yukoDateTo +
            "　" +
            "\tID" +
            item.id
            : "",
        });
      }
      else {
        this.props.getDateRiyouKaigohoken(null, null);
        this.setState({
          kaigohokenDisplay: "",
        });
      }
    }
  }

  showModalSetMultiTime = () => {
    this.setState({
      modalSetMultiTimeVisible: true,
    });
  };

  handleSetMultiTimeOk = () => {
    this.props.form.validateFields(() => {
      // console.log("value", value);
    });
    this.setState({
      modalSetMultiTimeVisible: false,
    });
  };

  handleSetMultiTimeCancel = () => {
    this.setState({
      modalSetMultiTimeVisible: false,
    });
  };

  async handleSelectKaigohoken(kaigohoken) {
    const item = _.find(this.props.riyouKaigohokens, (k) => k.id == kaigohoken);
    this.props.form.setFieldsValue({
      riyou_kaigohoken_id: kaigohoken,
    });
    this.props.changeKaigohoken(kaigohoken);
    await this.setState({
      changeKaigohoken: true,
      kaigohokenVisible: false,
      kaigohokenDisplay: item
        ? item.name +
        "　" +
        "\t有効期限：" +
        item.yukoDateFrom +
        "～" +
        item.yukoDateTo +
        "　" +
        "\tID" +
        item.id
        : "",
    });
    this.props.changeValuePlan2(true);
    if (item) {
      this.props.getDateRiyouKaigohoken(item.yukoDateFrom, item.yukoDateTo);
    }
  }
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };
  showModalLastQuote() {
    this.setState({
      lastQuoteVisible: true,
    });
  }

  hideModalLastQuote() {
    this.setState({
      lastQuoteVisible: false,
    });
  }

  handleSelectLastQuote = async (value) => {
    if (value) {
      const rowsKyotakuPlan2 = value.seikatuMokuhyous
        ? value.seikatuMokuhyous
        : [];
      rowsKyotakuPlan2?.map((item) => {
        (item.id = 0), (item.kyotakuPlan2Id =
          this.props.kyotakuPlan2Id !== "new" ? parseInt(this.props.kyotakuPlan2Id) : 0);
        if (item.details && item.details.length > 0) {
          item.details?.map((item2) => {
            item2.id = 0;
          });
        }
      });
      await this.props.getRowKyotakuPlan2(rowsKyotakuPlan2);
      const kaigohoken = _.find(
        this.props.riyouKaigohokens,
        (k) => k.id == value.riyouKaigohokenId
      );
      this.setState({
        lastQuoteVisible: false,
        kaigohokenDisplay: kaigohoken
          ? kaigohoken.name +
          "　" +
          "\t有効期限：" +
          kaigohoken.yukoDateFrom +
          "～" +
          kaigohoken.yukoDateTo +
          "　" +
          "\tID" +
          kaigohoken.id
          : "",
      });
      this.props.changeExpandedRowKey(null);
      this.props.changeValuePlan2(true);
      setTimeout(() => {
        this.props.form.setFieldsValue({
          riyou_kaigohoken_id: value.riyouKaigohokenId,
          renew_years: value.renewYears
            ? dayjs(value.renewYears, ConstSet.DATE_FORMAT_YEAR_MONTH)
            : null,
          comment: value.comment,
        });
      }, 300);
    }
    else {
      this.setState({
        lastQuoteVisible: false,
      });
    }
  };

  showModalKaigohoken() {
    this.setState({
      kaigohokenVisible: true,
    });
  }

  hideModalKaigohoken() {
    this.setState({
      kaigohokenVisible: false,
    });
  }

  openChangeplanDate = (open) => {
    this.setState({ planDateOpen: open });
  };

  clearPlanDate = () => {
    this.props.form.setFieldsValue({
      renew_years: null,
    });
    this.setState({
      planDateOpen: false,
    });
  };

  openChangeDouiDate = (open) => {
    this.setState({ douiDateOpen: open });
  };

  clearDouiDate = () => {
    this.props.form.setFieldsValue({
      plan2_doui_date: null,
    });
    this.setState({
      douiDateOpen: false,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      modalSetMultiTimeVisible,
      planDateOpen,
      douiDateOpen,
      settingData,
    } = this.state;
    const { editDetail } = this.props;
    const values = this.props.form.getFieldsValue();
    return (
      (<div>
        <Form layout="inline" hideRequiredMark={true}>
          <Row
            id="plan2-date-picker-list"
            style={
              editDetail
                ? { paddingBottom: "10px", display: "none" }
                : { paddingBottom: "10px" }
            }
            type="flex"
            justify="space-between"
          >
            <Col xs={16} sm={20} md={18}>
              <Row>
                <Col xs={24} sm={12} md={10} lg={7}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "10px",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>計画作成日</div>
                    <div>
                      <Form.Item colon={false} style={{ marginRight: 0 }}>
                        {getFieldDecorator(
                          "plan_date",
                          {}
                        )(<DatePicker style={{ width: "120px" }} />)}
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={11} lg={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "10px",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>計画予定更新月</div>
                    <div>
                      <Form.Item colon={false} style={{ marginRight: 0 }}>
                        {getFieldDecorator(
                          "renew_years",
                          {}
                        )(
                          <MonthPicker
                            open={planDateOpen}
                            onOpenChange={this.openChangeplanDate}
                            style={{ width: "120px" }}
                            popupClassName="date-picker-new-option"
                            renderExtraFooter={() => (
                              <div>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.clearPlanDate()}
                                >
                                  クリア
                                </span>
                              </div>
                            )}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={10} lg={7}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "10px",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>同意年月日</div>
                    <div>
                      <Form.Item colon={false} style={{ marginRight: 0 }}>
                        {getFieldDecorator(
                          "plan2_doui_date",
                          {}
                        )(
                          <DatePicker
                            style={{ width: "120px" }}
                            open={douiDateOpen}
                            onOpenChange={this.openChangeDouiDate}
                            popupClassName="date-picker-new-option"
                            renderExtraFooter={() => (
                              <div>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.clearDouiDate()}
                                >
                                  クリア
                                </span>
                              </div>
                            )}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={4} md={6} className="flex-end">
              <Form.Item style={{ marginRight: 0 }}>
                {getFieldDecorator("progressF", {
                  rules: [{ required: false }],
                })(
                  <Checkbox.Group
                    options={[{ label: "最新", value: "progressF" }]}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={
              editDetail
                ? { paddingBottom: "10px", display: "none" }
                : { paddingBottom: "10px" }
            }
            type="flex"
            justify="space-between"
          >
            <Col xs={20} sm={20} md={20}>
              <Button
                type={"primary"}
                onClick={() => this.showModalLastQuote()}
                style={{ marginRight: "0.5rem" }}
              >
                {"前回引用"}
              </Button>
              <Button type={"primary"} onClick={this.showModalSetMultiTime}>
                {"一括期間セット"}
              </Button>
            </Col>
            <Col xs={4} sm={4} md={4} className="flex-end">
              <Button
                type="primary"
                danger
                onClick={() => {
                  if (!this.props.valuesChange) {
                    this.props.changeValuePlan2(true);
                  }
                  const { kyotakuPlan2Rows } = this.props;
                  this.props.addRowsKyotakuPlan2(
                    this.props.expandedRowKeys,
                    this.props.rowInformation,
                    this.props.data
                  );
                  this.props.changeExpandedRowKey(kyotakuPlan2Rows.length + 1);
                }}
              >
                {"追加"}
              </Button>
            </Col>
          </Row>
          <div>
            <EditTable
              data={this.props.data}
              history={this.props.history}
              riyousyaId={this.props.riyousyaId}
              kyotakuPlan2Id={this.props.kyotakuPlan2Id}
              settingData={settingData}
            />
          </div>
          <Row
            style={
              editDetail
                ? { display: "none", marginTop: "10px" }
                : { marginTop: "10px" }
            }
          >
            <Col sm={24} xs={24} md={2}>
              <div style={{ fontWeight: "bold", marginTop: "10px" }}>
                コメント
              </div>
            </Col>
            <Col sm={24} xs={24} md={22}>
              {getFieldDecorator("comment")(
                <TextArea style={{ width: "100%" }} rows={4} />
              )}
            </Col>
          </Row>
          <div style={editDetail ? { display: "none" } : { marginTop: 8 }}>
            <MediaQuery query="(min-device-width: 768px)">
              {(matches) => {
                if (matches) {
                  return (
                    <Col>
                      <Form.Item label={"介護保険情報"} colon={false}>
                        {getFieldDecorator("riyou_kaigohoken_id")(
                          <div>
                            <span className={"pljp-col pljp-form-item-label"}>
                              {this.state.kaigohokenDisplay}
                            </span>
                            <span>
                              <Button
                                type={"primary"}
                                style={{ marginLeft: "16px" }}
                                onClick={() => this.showModalKaigohoken()}
                              >
                                {"選択"}
                              </Button>
                            </span>
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                  );
                }
                return (
                  <Col>
                    <Form.Item label={"介護保険情報"} colon={false}>
                      {getFieldDecorator("riyou_kaigohoken_id")(
                        <div>
                          <Button
                            type={"primary"}
                            style={{ marginLeft: "16px" }}
                            onClick={() => this.showModalKaigohoken()}
                          >
                            {"選択"}
                          </Button>
                        </div>
                      )}
                    </Form.Item>
                    <div
                      className={"pljp-col pljp-form-item-label"}
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      {this.state.kaigohokenDisplay}
                    </div>
                  </Col>
                );
              }}
            </MediaQuery>
          </div>
          {/* <Row>
        <EditTable/>
      </Row> */}
        </Form>
        <RiyouKaigohokenModal
          selected={this.props.form.getFieldValue("riyou_kaigohoken_id")}
          visible={this.state.kaigohokenVisible}
          handleSelectKaigohoken={(data) => this.handleSelectKaigohoken(data)}
          hideModalKaigohoken={() => this.hideModalKaigohoken()}
        />
        <LastQuoteModal
          visible={this.state.lastQuoteVisible}
          title={"前回引用"}
          riyousyaId={this.props.riyousyaId}
          kyotakuPlan2Id={this.props.kyotakuPlan2Id}
          tab={"2"}
          handleSelectLastQuote={(data) => this.handleSelectLastQuote(data)}
          hideModalLastQuote={() => this.hideModalLastQuote()}
        />
        <ModalSetMultiTime
          modalSetMultiTimeVisible={modalSetMultiTimeVisible}
          handleSetMultiTimeOk={this.handleSetMultiTimeOk}
          handleSetMultiTimeCancel={this.handleSetMultiTimeCancel}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          valuePlan2={values}
          settingData={settingData}
        />
      </div>)
    );
  }
}

const KyotakuPlan2InputFormWrapper = Form.create({
  name: "input-kyotaku-plan-2",
  // onValuesChange(props, changedValues, allValues) {
  //   if (!props.valuesChange) {
  //     props.changeValuePlan2(true);
  //   }
  //   let values = { ...allValues };
  //   if (allValues && allValues.progressF[0] == "progressF") {
  //     values = { ...values, progressF: 1 };
  //     props.updateOnChange(values);
  //   }
  //   else {
  //     values = { ...values, progressF: 0 };
  //     props.updateOnChange(values);
  //   }
  // },
  mapPropsToFields(props) {
    const { copyFrom, kaigohoken } = props;
    const defaultKaigohoken = !_.isEmpty(props.riyouKaigohokens)
      ? props.riyouKaigohokens[0].id
      : null;
    const dump = copyFrom;

    return {
      riyou_kaigohoken_id: Form.createFormField({
        value:
          props.data &&
            !dump &&
            props.data.riyouKaigohokenId &&
            kaigohoken == null
            ? props.data.riyouKaigohokenId
            : kaigohoken != null
              ? kaigohoken
              : defaultKaigohoken,
      }),
      progressF: Form.createFormField({
        value:
          props.data && !copyFrom && props.data.progressF != 1
            ? []
            : ["progressF"],
      }),
      renew_years: Form.createFormField({
        value:
          props.data && props.data.renewYears
            ? dayjs(props.data.renewYears, ConstSet.DATE_FORMAT_YEAR_MONTH)
            : null,
      }),
      plan_date: Form.createFormField({
        value:
          props.data && !copyFrom && props.data.planDate
            ? dayjs.utc(props.data.planDate, ConstSet.DATE_FORMAT)
            : dayjs(),
      }),
      plan2_doui_date: Form.createFormField({
        value:
          props.data && !copyFrom && props.data.plan2DouiDate
            ? dayjs.utc(props.data.plan2DouiDate, ConstSet.DATE_FORMAT)
            : null,
      }),
      comment: Form.createFormField({
        value: props.data ? props.data.comment : null,
      }),
    };
  },
})(KyotakuPlan2InputForm);

const mapStateToProps = (state) => {
  return {
    kyotakuPlan2Rows: state.kyotakuPlan2.kyotakuPlan2Rows,
    editDetail: state.kyotakuPlan2.editDetail,
    riyouKaigohokens: state.riyouKaigohokens.riyouKaigohokens,
    startDate: state.riyouKaigohokens.startDate,
    endDate: state.riyouKaigohokens.endDate,
    riyousya: state.riyousya.riyousya,
    valuesChange: state.kyotakuPlan2.valuesChange,
    kaigohoken: state.kyotakuPlan2.kaigohoken,
    keepValue: state.kyotakuPlan2.keepValue,
    rowInformation: state.kyotakuPlan2.rowInformation,
    expandedRowKeys: state.kyotakuPlan2.expandedRowKeys,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRowsKyotakuPlan2: (expandedRowKeys, rowData, data) =>
      dispatch(addRowsKyotakuPlan2(expandedRowKeys, rowData, data)),
    changeExpandedRowKey: (key) => dispatch(changeExpandedRowKey(key)),
    getRowKyotakuPlan2: (data) => dispatch(getRowKyotakuPlan2(data)),
    updateOnChange: (data) => dispatch(updateOnChange(data)),
    changeValuePlan2: (type) => dispatch(changeValuePlan2(type)),
    getDateRiyouKaigohoken: (start, end) =>
      dispatch(getDateRiyouKaigohoken(start, end)),
    changeKaigohoken: (value) => dispatch(changeKaigohoken(value)),
    keepValue1: (progressF, renew_years, plan_date, plan2_doui_date, comment) =>
      dispatch(
        keepValue1(progressF, renew_years, plan_date, plan2_doui_date, comment)
      ),
    settingMasterNaiyou: (value, callBack) =>
      dispatch(settingMasterNaiyou(value, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KyotakuPlan2InputFormWrapper);
