import {
  LOAD_SISETU_DAILY_PLAN_BY_ID_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_BY_ID_ERROR,
  SAVE_SISETU_DAILY_PLAN_SUCCESS,
  SAVE_SISETU_DAILY_PLAN_ERROR,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_ERROR,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_SUCCESS,
  LOAD_SISETU_DAILY_PLAN_TEMPLATE_ERROR,
  LOAD_SPIN_REQUEST,
  LOAD_SPIN_REQUEST_TEMPLATE,
  LOAD_KAIGO_NAIYOU_SUCCESS,
  LOAD_KAIGO_NAIYOU_ERROR,
  LOAD_SEIKATU_NAIYOU_SUCCESS,
  LOAD_SEIKATU_NAIYOU_ERROR,
  RESET_STATUS,
} from "../../actions/sisetuDailyPlanActions/actionName";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

export default function sisetuDailyPlan(
  state = { loading: false, status: null },
  action
) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        status: null,
      };
    case LOAD_SPIN_REQUEST_TEMPLATE:
      return {
        ...state,
        loadingTemp: true,
      };
    case LOAD_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SISETU_DAILY_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        sisetuDailyPlan: action.sisetuDailyPlan,
        notifyDisplay: 0,
        notifyContent: null,
        loading: false,
      };
    case LOAD_SISETU_DAILY_PLAN_BY_ID_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        sisetuDailyPlan: null,
        notifyDisplay: 2,
        notifyContent: action.error.message,
        loading: false,
      };
    case LOAD_KAIGO_NAIYOU_SUCCESS:
      return {
        ...state,
        kaigoNaiyou: action.kaigoNaiyou,
        notifyDisplay: 0,
        notifyContent: null,
        loading: false,
      };
    case LOAD_KAIGO_NAIYOU_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        kaigoNaiyou: null,
        notifyDisplay: 2,
        notifyContent: action.error.message,
        loading: false,
      };
    case LOAD_SEIKATU_NAIYOU_SUCCESS:
      return {
        ...state,
        seikatuNaiyou: action.seikatuNaiyou,
        notifyDisplay: 0,
        notifyContent: null,
        loading: false,
      };
    case LOAD_SEIKATU_NAIYOU_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        seikatuNaiyou: null,
        notifyDisplay: 2,
        notifyContent: action.error.message,
        loading: false,
      };
    case SAVE_SISETU_DAILY_PLAN_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      if (action.savedSisetuDailyPlan) {
        localStorage.setItem(
          "RowKey3",
          action.savedSisetuDailyPlan.id +
            "_" +
            action.savedSisetuDailyPlan.riyousyaId +
            "_" +
            action.savedSisetuDailyPlan.sid
        );
      }
      return {
        ...state,
        loading: false,
        // sisetuDailyPlan: action.savedSisetuDailyPlan,
        status: "success",
      };
    case SAVE_SISETU_DAILY_PLAN_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        notifyContent: action.error.message,
        loading: false,
        status: "error",
      };
    case LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_SUCCESS:
      return {
        ...state,
        sisetuDailyPlanTemplates: action.sisetuDailyPlanTemplates,
        notifyDisplay: 0,
        notifyContent: null,
        loadingTemp: false,
      };
    case LOAD_SISETU_DAILY_PLAN_TEMPLATE_NAME_ERROR:
      return {
        ...state,
        sisetuDailyPlanTemplates: null,
        notifyDisplay: 2,
        notifyContent: action.error.message,
        loadingTemp: false,
      };
    case LOAD_SISETU_DAILY_PLAN_TEMPLATE_SUCCESS:
    case LOAD_SISETU_DAILY_PLAN_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
