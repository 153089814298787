import React, { useEffect, useState } from "react";
import { Modal, Table, Tooltip } from "antd";
import { connect } from "react-redux";

function LastQuoteTabDialog(props) {
  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [
    {
      title: "作成日",
      dataIndex: "makeDate",
      width: "220px",
    },
    // {
    //   title: "コメント",
    //   dataIndex: "comment",
    //   key: "comment",
    //   render: (text) => (
    //     <Tooltip title={text} placement="topLeft">
    //       <div
    //         style={{
    //           maxWidth: "314px",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           whiteSpace: "nowrap",
    //         }}
    //       >
    //         {text}
    //       </div>
    //     </Tooltip>
    //   ),
    // },
  ];

  function handleSelectRow(record) {
    setSelectedRow(record);
  }

  function handleOk() {
    props.onSelectedLastQuote(selectedRow);
  }

  function handleCancel() {
    props.onHideModalLastQuote();
  }
  return (
    (<Modal
      open={props.visibleQuote}
      title={props.title}
      onOk={handleOk}
      onCancel={handleCancel}
      width={500}
      okButtonProps={
        typeof props.data !== "undefined" && props.data.length > 0
          ? { disabled: false }
          : { disabled: true }
      }
    >
      <Table
        id={"last-quote-table"}
        bordered={true}
        size={"small"}
        columns={columns}
        pagination={{ pageSize: 10 }}
        rowKey={"id"}
        dataSource={props.data}
        onRow={(record) => {
          return {
            onClick: () => handleSelectRow(record),
          };
        }}
        rowClassName={(record) =>
          selectedRow != null && record.id === selectedRow.id
            ? "pljp-table-row-hover"
            : "pointer-row"
        }
      />
    </Modal>)
  );
}

export { LastQuoteTabDialog };
