export const HyoukahyouActionTypes = {
  GET_ALL_HYOUKAHYOU_REQUEST: "GET_ALL_HYOUKAHYOU_REQUEST",
  GET_ALL_HYOUKAHYOU_SUCCESS: "GET_ALL_HYOUKAHYOU_SUCCESS",
  GET_ALL_HYOUKAHYOU_FAILURE: "GET_ALL_HYOUKAHYOU_FAILURE",

  GET_HYOUKAHYOU_REQUEST: "GET_HYOUKAHYOU_REQUEST",
  GET_HYOUKAHYOU_SUCCESS: "GET_HYOUKAHYOU_SUCCESS",
  GET_HYOUKAHYOU_FAILURE: "GET_HYOUKAHYOU_FAILURE",

  CREATE_HYOUKAHYOU_REQUEST: "CREATE_HYOUKAHYOU_REQUEST",
  CREATE_HYOUKAHYOU_SUCCESS: "CREATE_HYOUKAHYOU_SUCCESS",
  CREATE_HYOUKAHYOU_FAILURE: "CREATE_HYOUKAHYOU_FAILURE",

  UPDATE_HYOUKAHYOU_REQUEST: "UPDATE_HYOUKAHYOU_REQUEST",
  UPDATE_HYOUKAHYOU_SUCCESS: "UPDATE_HYOUKAHYOU_SUCCESS",
  UPDATE_HYOUKAHYOU_FAILURE: "UPDATE_HYOUKAHYOU_FAILURE",

  REMOVE_HYOUKAHYOU_REQUEST: "REMOVE_HYOUKAHYOU_REQUEST",
  REMOVE_HYOUKAHYOU_SUCCESS: "REMOVE_HYOUKAHYOU_SUCCESS",
  REMOVE_HYOUKAHYOU_FAILURE: "REMOVE_HYOUKAHYOU_FAILURE",

  GET_ALL_HYOUKAHYOU_QUOTE_REQUEST: "GET_ALL_HYOUKAHYOU_QUOTE_REQUEST",
  GET_ALL_HYOUKAHYOU_QUOTE_SUCCESS: "GET_ALL_HYOUKAHYOU_QUOTE_SUCCESS",
  GET_ALL_HYOUKAHYOU_QUOTE_FAILURE: "GET_ALL_HYOUKAHYOU_QUOTE_FAILURE",

  GET_HYOUKAHYOU_BY_DATA_REQUEST: "GET_HYOUKAHYOU_BY_DATA_REQUEST",
  GET_HYOUKAHYOU_BY_DATA_SUCCESS: "GET_HYOUKAHYOU_BY_DATA_SUCCESS",
  GET_HYOUKAHYOU_BY_DATA_FAILURE: "GET_HYOUKAHYOU_BY_DATA_FAILURE",

  GET_HYOUKAHYOU_YOBOU_REQUEST: "GET_HYOUKAHYOU_YOBOU_REQUEST",
  GET_HYOUKAHYOU_YOBOU_SUCCESS: "GET_HYOUKAHYOU_YOBOU_SUCCESS",
  GET_HYOUKAHYOU_YOBOU_FAILURE: "GET_HYOUKAHYOU_YOBOU_FAILURE",

  RESET_CURRENT_HYOUKAHYOU: "RESET_CURRENT_HYOUKAHYOU",
};
