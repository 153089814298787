export const riyousyaHokenActionTypes = {
  RIYOUSYA_HOKEN_ABOVE_REQUEST: "RIYOUSYA_HOKEN_ABOVE_REQUEST",
  RIYOUSYA_HOKEN_ABOVE_SUCCESS: "RIYOUSYA_HOKEN_ABOVE_SUCCESS",
  RIYOUSYA_HOKEN_ABOVE_ERROR: "RIYOUSYA_HOKEN_ABOVE_ERROR",
  RIYOUSYA_HOKEN_BELOW_REQUEST: "RIYOUSYA_HOKEN_BELOW_REQUEST",
  RIYOUSYA_HOKEN_BELOW_SUCCESS: "RIYOUSYA_HOKEN_BELOW_SUCCESS",
  RIYOUSYA_HOKEN_BELOW_ERROR: "RIYOUSYA_HOKEN_BELOW_ERROR",

  KAIGOHOKEN_MASTER_SPIN_REQUEST: "KAIGOHOKEN_MASTER_SPIN_REQUEST",
  LOAD_KAIGOHOKEN_MASTER_SUCCESS: "LOAD_KAIGOHOKEN_MASTER_SUCCESS",
  LOAD_KAIGOHOKEN_MASTER_ERROR: "LOAD_KAIGOHOKEN_MASTER_ERROR",

  RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST:
    "RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST",
  RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS:
    "RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS",
  RIYOUSYA_KAIGOHOKEN_UPDATE_ERROR:
    "RIYOUSYA_KAIGOHOKEN_UPDATE_ERROR",
  RIYOUSYA_KAIGOHOKEN_DELETE: "RIYOUSYA_KAIGOHOKEN_DELETE",

  RIYOUSYA_KOHI_UPDATE_REQUEST: "RIYOUSYA_KOHI_UPDATE_REQUEST",
  RIYOUSYA_KOHI_UPDATE_SUCCESS: "RIYOUSYA_KOHI_UPDATE_SUCCESS",
  RIYOUSYA_KOHI_UPDATE_ERROR: "RIYOUSYA_KOHI_UPDATE_ERROR",
  RIYOUSYA_KOHIJOHO_DELETE: "RIYOUSYA_KOHIJOHO_DELETE",
  //医療保険
  GET_LIST_IRYOU_HOKEN_REQUEST: "GET_LIST_IRYOU_HOKEN_REQUEST",
  GET_LIST_IRYOU_HOKEN_SUCCESS: "GET_LIST_IRYOU_HOKEN_SUCCESS",
  GET_LIST_IRYOU_HOKEN_FAILURE: "GET_LIST_IRYOU_HOKEN_FAILURE",

  UPDATE_IRYOU_HOKEN_REQUEST: "UPDATE_IRYOU_HOKEN_REQUEST",
  UPDATE_IRYOU_HOKEN_SUCCESS: "UPDATE_IRYOU_HOKEN_SUCCESS",
  UPDATE_IRYOU_HOKEN_FAILURE: "UPDATE_IRYOU_HOKEN_FAILURE",

  DELETE_IRYOU_HOKEN_REQUEST: "DELETE_IRYOU_HOKEN_REQUEST",
  DELETE_IRYOU_HOKEN_SUCCESS: "DELETE_IRYOU_HOKEN_SUCCESS",
  DELETE_IRYOU_HOKEN_FAILURE: "DELETE_IRYOU_HOKEN_FAILURE",
  //高額医療費負担上限
  GET_LIST_KOUGAKU_IRYOUHI_REQUEST: "GET_LIST_KOUGAKU_IRYOUHI_REQUEST",
  GET_LIST_KOUGAKU_IRYOUHI_SUCCESS: "GET_LIST_KOUGAKU_IRYOUHI_SUCCESS",
  GET_LIST_KOUGAKU_IRYOUHI_FAILURE: "GET_LIST_KOUGAKU_IRYOUHI_FAILURE",

  UPDATE_KOUGAKU_IRYOUHI_REQUEST: "UPDATE_KOUGAKU_IRYOUHI_REQUEST",
  UPDATE_KOUGAKU_IRYOUHI_SUCCESS: "UPDATE_KOUGAKU_IRYOUHI_SUCCESS",
  UPDATE_KOUGAKU_IRYOUHI_FAILURE: "UPDATE_KOUGAKU_IRYOUHI_FAILURE",

  DELETE_KOUGAKU_IRYOUHI_REQUEST: "DELETE_KOUGAKU_IRYOUHI_REQUEST",
  DELETE_KOUGAKU_IRYOUHI_SUCCESS: "DELETE_KOUGAKU_IRYOUHI_SUCCESS",
  DELETE_KOUGAKU_IRYOUHI_FAILURE: "DELETE_KOUGAKU_IRYOUHI_FAILURE",


}





