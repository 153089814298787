import {
  ACCOUNT_LOGIN,
  LOAD_SPIN_MODE,
  MAINTAINANCE_MODE,
} from "../../actions/accountActions/actionName.js";
import AccountApi from "../../api/account.js";

/*
 * @param: username: string
 * @param: password: string
 */
function loginAccountCreator(username, password, code, isCheck, callback) {
  return (dispatch) => {
    AccountApi.login(username, password, code, isCheck).then((data) => {
      dispatch({
        type: ACCOUNT_LOGIN,
        dataLogin: data,
      });

      callback = callback || function _callback() { };
      callback(data);
    });
  };
}

function getMaintainanceModeCreator() {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_MODE,
    });

    AccountApi.mode().then((data) => {
      const systemTitle = data && data.split("===") ? data.split("===")[0] : "LIFE";
      const mode = data && data.split("===") ? data.split("===")[1] : "";
      document.title = "SmileWeb+" + systemTitle;
      localStorage.setItem("kirokuTitle", systemTitle);

      dispatch({
        type: MAINTAINANCE_MODE,
        payload: mode,
        systemTitle
      });
    });
  };
}

export { getMaintainanceModeCreator, loginAccountCreator };

