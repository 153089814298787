import React, { Fragment } from "react";
import { Modal, Input, Row, Col, Button, Checkbox, Spin } from "antd";
import { connect } from "react-redux";
import { toKatakana } from "wanakana";
import {
  iryouKikanMasterInitCreator,
  createIryouKikanMasterCreator,
  updateIryouKikanMasterCreator,
} from "../../../actions/allMasterActions/actionCreators";
import { zipMasterSearchCreator } from "../../../actions/zipMasterActions/actionCreators";
import MasterCodeInput from "./MasterCodeInput";
import MasterCodeCancelButton from "./MasterCodeCancelButton";
import SearchYubinModal from "./SearchYubinModal";
import _ from "lodash";

const ADD = 1;
const EDIT = 2;

const reference = { table: "iryou_kikan_master", field: "code" };

class IryoKikanMasterEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      initCode: null,
      visibleZip: false,
      checkZip: false,
      errorNameVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      if (this.props.data && !(this.props.editType === ADD)) {
        if (this.props.editType === EDIT) {
          this.setState({
            data: this.props.data,
          });
        }
        console.log(this.state.data);
      } else {
        this.props.getIryouKikanMasterInit((data) => {
          console.log(this.state);
          const initDate = data
            ? { ...data, id: null, code: this.state.data.code }
            : {};
          this.setState({
            data: initDate,
          });
        });
        console.log(this.state.data);
      }
    }

    if (this.props.zipSearch !== prevProps.zipSearch && this.state.checkZip) {
      if (this.props.zipSearch.length === 1) {
        let record = this.props.zipSearch[0];
        this.setState({
          data: {
            ...this.state.data,
            zip: record.zip || "",
            address: record.address || "",
            checkZip: false,
          },
        });
      } else {
        this.setState({
          visibleZip: true,
          checkZip: false,
        });
      }
    }
  }

  onChangeValue(e, field) {
    const { data } = this.state;

    e.stopPropagation();
    let newValue = data[field];
    switch (field) {
      case "stop":
        newValue = e.target.checked ? 1 : 0;
        break;
      default:
        newValue = e.target.value;
    }

    if (newValue !== data[field]) {
      this.setState({
        data: { ...data, [field]: newValue },
      });
    }
  }

  onChangeCode(value, first) {
    const { data } = this.state;

    if (first) {
      this.setState({
        data: { ...data, code: value },
        initCode: value,
      });
    } else {
      this.setState({
        data: { ...data, code: value },
      });
    }
  }

  async handleSave() {
    const { data } = this.state;
    console.log(data);

    if (this.checkRequired()) return;

    if (data.id && data.id > 0) {
      await this.props.updateIryouKikanMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    } else {
      await this.props.createIryouKikanMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    }

    this.handleClose();
  }

  checkRequired() {
    const { data } = this.state;

    let isError = false;
    if (_.isEmpty(data.hospital)) {
      isError = true;
      this.setState({
        errorNameVisible: true,
      });
    } else {
      this.setState({
        errorNameVisible: false,
      });
    }

    return isError;
  }

  handleFurigana(e, field) {
    const { data } = this.state;

    e.preventDefault();
    const value = toKatakana(e.target.value);
    this.setState({
      data: { ...data, [field]: value },
    });
  }

  openSearchYubin() {
    const { data } = this.state;

    this.props.getZipMasterSearch(data.zip);
    this.setState({
      checkZip: true,
    });
  }

  closeSearchYubinBango() {
    this.setState({
      visibleZip: false,
    });
  }

  onSubmitZip(record) {
    const { data } = this.state;

    if (record) {
      this.setState({
        data: { ...data, zip: record.zip || "", address: record.address || "" },
      });
    }
    this.closeSearchYubinBango();
  }

  handleClose() {
    this.setState({
      errorNameVisible: false,
    });
    this.props.handleClose();
  }

  render() {
    const { data, errorNameVisible, initCode } = this.state;

    return (
      (<Fragment>
        <Modal
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => this.handleClose()}
          open={this.props.visible}
          width={800}
          footer={[
            <Row key={1} type="flex" justify="end">
              <div style={{ paddingRight: 8 }}>
                <MasterCodeCancelButton
                  text={"戻る"}
                  code={initCode}
                  reference={reference}
                  checkType={this.props.editType === ADD}
                  onClose={() => this.handleClose()}
                />
              </div>
              <div>
                <Button type={"primary"} onClick={() => this.handleSave()}>
                  {"保存"}
                </Button>
              </div>
            </Row>,
          ]}
        >
          <Spin spinning={this.props.loading}>
            <Row>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>ID</p>
              </Col>
              <Col span={4}>
                <Input
                  value={data.id}
                  onChange={(e) => this.onChangeValue(e, "id")}
                  readOnly
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>整理番号</p>
              </Col>
              <Col span={4}>
                <MasterCodeInput
                  value={data.code}
                  maxLength={10}
                  checkType={this.props.editType === ADD}
                  reference={reference}
                  onChange={(value, first) => this.onChangeCode(value, first)}
                />
              </Col>
              <Col span={14}>
                <Checkbox
                  type={"checkbox"}
                  checked={data.stop}
                  style={{ marginTop: 5, marginLeft: 50 }}
                  onChange={(e) => this.onChangeValue(e, "stop")}
                >
                  利用中止
                </Checkbox>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>病医院名</p>
              </Col>
              <Col span={14}>
                <Input
                  value={data.hospital}
                  onChange={(e) => this.onChangeValue(e, "hospital")}
                  style={errorNameVisible ? { border: "1px solid red" } : {}}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>フリガナ</p>
              </Col>
              <Col span={14}>
                <Input
                  value={data.furigana}
                  onChange={(e) => this.onChangeValue(e, "furigana")}
                  onBlur={(e) => this.handleFurigana(e, "furigana")}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>郵便番号</p>
              </Col>
              <Col span={4}>
                <Input
                  value={data.zip}
                  onChange={(e) => this.onChangeValue(e, "zip")}
                  maxLength={8}
                />
              </Col>
              <Col span={4} style={{ marginLeft: 5 }}>
                <Button type={"primary"} onClick={() => this.openSearchYubin()}>
                  〒
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>住所</p>
              </Col>
              <Col span={14}>
                <Input
                  value={data.address}
                  onChange={(e) => this.onChangeValue(e, "address")}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>電話</p>
              </Col>
              <Col span={4}>
                <Input
                  value={data.tel}
                  onChange={(e) => this.onChangeValue(e, "tel")}
                  maxLength={12}
                />
              </Col>
              <Col span={2} style={{ marginLeft: 30 }}>
                <p style={{ marginTop: 5 }}>FAX</p>
              </Col>
              <Col span={4}>
                <Input
                  value={data.fax}
                  onChange={(e) => this.onChangeValue(e, "fax")}
                  maxLength={12}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={6}>
                <p style={{ marginTop: 5 }}>順番</p>
              </Col>
              <Col span={4}>
                <Input
                  value={data.sortNum}
                  onChange={(e) => this.onChangeValue(e, "sortNum")}
                />
              </Col>
            </Row>
          </Spin>
        </Modal>
        <SearchYubinModal
          visible={this.state.visibleZip}
          loading={this.props.zipLoading}
          data={this.props.zipSearch}
          zip={data.zip}
          searchYubinBango={(zip) => this.props.getZipMasterSearch(zip)}
          onSubmit={(record) => this.onSubmitZip(record)}
          onCancel={() => this.closeSearchYubinBango()}
        />
      </Fragment>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.referenceNumber.loading || state.allMaster.loading,
    zipLoading: state.zipMaster.loading,
    zipSearch: state.zipMaster.zip,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIryouKikanMasterInit: (callback) =>
      dispatch(iryouKikanMasterInitCreator(callback)),
    createIryouKikanMaster: (body, callback) =>
      dispatch(createIryouKikanMasterCreator(body, callback)),
    updateIryouKikanMaster: (body, callback) =>
      dispatch(updateIryouKikanMasterCreator(body, callback)),
    getZipMasterSearch: (zip) => dispatch(zipMasterSearchCreator(zip)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IryoKikanMasterEditModal);
