// Setting for all project
export const RIYOUSYA_MONTHLY_LIST = "RIYOUSYA_MONTHLY_LIST";
export const RIYOUSYA_MONTHLY_RECORD_COPY = "RIYOUSYA_MONTHLY_RECORD_COPY";
export const RIYOUSYA_MONTHLY_EDIT = "RIYOUSYA_MONTHLY_EDIT";
export const RIYOUSYA_MONTHLY_ADD = "RIYOUSYA_MONTHLY_ADD";
export const RIYOUSYA_MONTHLY_DEL = "RIYOUSYA_MONTHLY_DEL";

export const RIYOUSYA_MONTHLY_LOAD = "RIYOUSYA_MONTHLY_LOAD";
export const RIYOUSYA_MONTHLY_ERROR = "RIYOUSYA_MONTHLY_ERROR";
export const RIYOU_MONTHLY_KEEP_VALUE_SEARCH = "RIYOU_MONTHLY_KEEP_VALUE_SEARCH";


