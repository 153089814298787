import React from "react";
import { Route, Redirect } from "react-router-dom";
import {
  getValueLocalstorage,
  localStorageClearCus,
} from "../function_common/functionCommon";

export const PrivateRoute = ({ component: Component, menu: menu, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        let jwtDecode = require("jwt-decode");
        const decoded = jwtDecode(token);
        // Check expired time of token
        if (decoded.exp >= new Date().getTime() / 1000) {
          let currentMenu = localStorage.getItem("currentmenu");
          if (menu && currentMenu.indexOf(menu) < 0) {
            return <Redirect to={{ pathname: "/" }} />;
          }
          return (
            <Component
              {...props}
              sessionTimeout={Number(getValueLocalstorage("sessionTimeout"))}
            />
          );
        }
      }

      localStorageClearCus();

      return <Redirect to={{ pathname: "/login" }} />;
    }}
  />
);
