/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  MinusOutlined,
  PlusOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Input, Modal, Row, Select, Spin, Table } from "antd";
import axios from "axios/index";
import _ from "lodash";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  getKaigoYougoId,
  getKaigoYougoList,
  getKaigoYougoListAll,
  getMaxSortNum,
  updateKaigoYougo,
  updateKaigoYougoList,
} from "../../../actions/kaigoYougoMasterActions/actionCreators";
import { getYougoMaster } from "../../../actions/kyotakuPlan1Actions/actionCreators";
import { apiUrls } from "../../../common/configs/common-url";
import { guid } from "../../../common/timePicker/formatTimePicker";

const { TextArea } = Input;
class CareTermDictModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCareTerm: null,
      attribute: null,
      bunrui1: null,
      bunrui2: null,
      keyword: null,
      page: this.props.meta.page,
      visible1: false,
      attributeAdd: null,
      bunrui1Add: null,
      bunrui2Add: null,
      keywordAdd: null,
      naiyouAdd: null,
      status: null,
      visibleConfirm: false,
      expandAll: false,
      attributeErrorMessage: "",
      sort: false,
      sortTableAll: [],
      sortTableList: [],
      copyTable: [],
      // listTableTmp: [], //保持用のリスト
      keepTable: false, // 保持
      maxNum: null,
      // offLoading: false,
    };
  }

  async componentDidMount() {
    await this.props.getYougoMaster();
    axios
      .get(apiUrls.GET_SETTING_MASTER, {
        params: {
          settingName: "介護用語属性リンク",
        },
      })
      .then((res) => {
        const linkings = res.data.naiyou.split(";");
        this.setState({
          linkings,
        });
        const patt = new RegExp("^" + this.props.linkingCareTerm + "_");
        const v = _.find(linkings, (l) => patt.test(l));
        if (v) {
          const lk = v.split("_");
          if (this.props.yougoAttribute.indexOf(lk[lk.length - 1]) >= 0)
            this.setState({
              attribute: lk[lk.length - 1],
            });
        }
      })
      .catch(() => {
        // console.log(err);
      });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.visible && !this.props.visible) {
      if (nextProps.linkingCareTerm !== this.props.linkingCareTerm) {
        await this.setState({
          page: 1,
        });
        const patt = new RegExp("^" + this.props.linkingCareTerm + "_");
        const v = _.find(this.state.linkings, (l) => patt.test(l));
        if (v) {
          const lk = v.split("_");

          if (this.props.yougoAttribute.indexOf(lk[lk.length - 1]) >= 0) {
            await this.setState({
              attribute: lk[lk.length - 1],
            });
          }
          else {
            await this.setState({
              attribute: null,
            });
          }
        }
        else {
          await this.setState({
            attribute: null,
          });
        }
      }
      const { attribute, bunrui1, bunrui2, keyword } = this.state;
      // this.props.getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page);
      this.props.getKaigoYougoListAll(
        attribute,
        bunrui1,
        bunrui2,
        keyword,
        () => { }
      );
    }
    if (
      nextProps.kaigoYougoSelected &&
      nextProps.kaigoYougoSelected != this.props.kaigoYougoSelected
    ) {
      this.setState({
        attributeAdd: nextProps.kaigoYougoSelected.attribute,
        bunrui1Add: nextProps.kaigoYougoSelected.bunrui1,
        bunrui2Add: nextProps.kaigoYougoSelected.bunrui2,
        keywordAdd: nextProps.kaigoYougoSelected.keyword,
        naiyouAdd: nextProps.kaigoYougoSelected.naiyou,
      });
    }
    if (
      this.state.isSelected &&
      nextProps.kaigoYougo &&
      nextProps.kaigoYougo != this.props.kaigoYougo
    ) {
      this.setState({
        selectedCareTerm:
          Array.isArray(nextProps.kaigoYougo) &&
            nextProps.kaigoYougo.length != 0
            ? {
              id: nextProps.kaigoYougo[0].id,
              naiyou: nextProps.kaigoYougo[0].naiyou,
            }
            : null,
        isSelected: false,
      });
    }
  }

  handleOk() {
    const { selectedCareTerm } = this.state;
    this.props.handleSelectCareTerm(
      selectedCareTerm ? selectedCareTerm.naiyou : null
    );
    this.setState({
      selectedCareTerm: null,
    });
  }

  async handleOk1() {
    // console.log('handleOk1', this.state);
    const {
      selectedCareTerm,
      attributeAdd,
      bunrui1Add,
      bunrui2Add,
      keywordAdd,
      naiyouAdd,
      status,
    } = this.state;

    if (!attributeAdd) {
      return this.setState({
        attributeErrorMessage: "属性を入力してください。",
      });
    }

    const newCareTerm = {
      attribute: attributeAdd,
      bunrui1: bunrui1Add,
      bunrui2: bunrui2Add,
      keyword: keywordAdd,
      naiyou: naiyouAdd,
    };

    if (selectedCareTerm && selectedCareTerm.id) {
      this.setState({
        selectedCareTerm: {
          ...selectedCareTerm,
          ...newCareTerm,
        },
      });
    }
    else {
      this.setState({ selectedCareTerm: newCareTerm });
    }

    this.setState({
      visible1: false,
      attributeAdd: null,
      bunrui1Add: null,
      bunrui2Add: null,
      keywordAdd: null,
      naiyouAdd: null,
      attribute: attributeAdd,
      bunrui1: bunrui1Add,
      bunrui2: bunrui2Add,
      keyword: keywordAdd,
      attributeErrorMessage: "",
    });
    await this.props.updateKaigoYougo(
      status,
      attributeAdd,
      bunrui1Add,
      bunrui2Add,
      keywordAdd,
      naiyouAdd
    );
    // await this.setState({
    //   selectedCareTerm: Array.isArray(this.props.kaigoYougo) && this.props.kaigoYougo.length != 0 ? {
    //                                                               id: this.props.kaigoYougo[0].id,
    //                                                               naiyou: this.props.kaigoYougo[0].naiyou
    //                                                            } : null
    // })
    if (!status) {
      this.setState({
        isSelected: true,
      });
    }
    if (this.props.showModalCareTerm) {
      await this.props.showModalCareTerm(
        this.props.field,
        this.props.linkingCareTerm
      );
    }

    this.fetchKaigoYougoList();
  }

  fetchKaigoYougoList() {
    const { attribute, bunrui1, bunrui2, keyword } = this.state;
    this.props.getKaigoYougoListAll(
      attribute,
      bunrui1,
      bunrui2,
      keyword,
      () => { }
    );
    // this.props.getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page);
  }

  handleCancel() {
    this.props.hideModalCareTerm();
    this.setState({
      selectedCareTerm: null,
      sort: false,
      sortTableAll: [],
      sortTableList: [],
      // listTableTmp: [],
      maxNum: null,
    });
  }
  handleCancel1() {
    // this.setState({
    //   visibleConfirm: true
    // })
    this.setState({
      visible1: false,
      attributeAdd: null,
      bunrui1Add: null,
      bunrui2Add: null,
      keywordAdd: null,
      naiyouAdd: null,
      visibleConfirm: true,
      attributeErrorMessage: "",
    });
    if (this.props.showModalCareTerm) {
      this.props.showModalCareTerm(
        this.props.field,
        this.props.linkingCareTerm
      );
    }

    this.fetchKaigoYougoList();
  }
  // handleCloseConfirm() {
  //   this.setState({
  //     visibleConfirm: false,
  //   })
  // }
  // handleOpenConfirm() {
  //   this.setState({
  //     visible1: false,
  //     attributeAdd: null,
  //     bunrui1Add: null,
  //     bunrui2Add: null,
  //     keywordAdd: null,
  //     naiyouAdd: null,
  //     visibleConfirm: false,
  //   })
  //   this.props.showModalCareTerm(this.props.field, this.props.linkingCareTerm);

  // }

  handleSelectCareTerm(record) {
    const { selectedCareTerm } = this.state;

    if (
      !selectedCareTerm ||
      !selectedCareTerm.id ||
      selectedCareTerm.id !== record.id
    ) {
      this.setState({ selectedCareTerm: record });
    }
    else {
      this.setState({ selectedCareTerm: null });
    }
  }

  onAttributeChange(value) {
    this.setState({
      attribute: value,
    });
  }

  onAttributeChangeAdd(value) {
    this.setState({
      attributeAdd: value,
      attributeErrorMessage: value ? "" : "属性を入力してください。",
    });
  }

  onBunrui1Change(value) {
    this.setState({
      bunrui1: value,
    });
  }

  onBunrui1ChangeAdd(value) {
    this.setState({
      bunrui1Add: value,
    });
  }

  onBunrui2Change(value) {
    this.setState({
      bunrui2: value,
    });
  }

  onBunrui2ChangeAdd(value) {
    this.setState({
      bunrui2Add: value,
    });
  }

  onKeywordChange(value) {
    this.setState({
      keyword: value,
    });
  }

  onKeywordChangeAdd(value) {
    this.setState({
      keywordAdd: value,
    });
  }

  onNaiyouChangeAdd(e) {
    this.setState({
      naiyouAdd: e.target.value,
    });
  }

  handleSearchKaigoYougo() {
    const { attribute, bunrui1, bunrui2, keyword } = this.state;
    this.props.getKaigoYougoListAll(
      attribute,
      bunrui1,
      bunrui2,
      keyword,
      () => { }
    );
    // this.props.getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page);
    this.setState({
      selectedCareTerm: null,
    });
  }

  handlePageChange(page) {
    this.setState({
      page,
    });
    const { attribute, bunrui1, bunrui2, keyword } = this.state;
    this.props.getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page);
  }

  handleShowModalAddEdit(value, naiyou) {
    const { attribute, bunrui1, bunrui2, keyword } = this.state;
    this.setState({
      status: value,
      visible1: true,
    });
    if (value) {
      this.props.getKaigoYougoId(value);
    }
    else {
      this.setState({
        attributeAdd: attribute,
        bunrui1Add: bunrui1,
        bunrui2Add: bunrui2,
        keywordAdd: keyword,
        naiyouAdd: naiyou ? naiyou : "",
      });
    }

    // this.props.hideModalCareTerm();
  }

  handleExpand(value) {
    this.setState({
      expandAll: !value,
    });
  }

  async collKaigoYougo() {
    const { attribute, bunrui1, bunrui2, keyword } = this.state;
    let tmp;
    let tmp2;
    await this.props.getKaigoYougoListAll(
      attribute,
      bunrui1,
      bunrui2,
      keyword,
      (data) => {
        tmp = JSON.parse(JSON.stringify(data.data));
        tmp2 = JSON.parse(JSON.stringify(data.data));
        this.setState({
          sortTableAll: tmp,
          copyTable: tmp2,
        });
      }
    );
    return true;
  }

  // tmpLoading(num){
  //   let ms = 2000;
  //   if(num > 1000){
  //     ms = 6000
  //   }else if(num > 500){
  //     ms = 3000
  //   }

  //   setTimeout(() =>
  //     this.setState({
  //       offLoading: false
  //     })
  //   , ms)
  // }

  async sortOn() {
    const on = await this.collKaigoYougo();
    this.props.getMaxSortNum((callback) => {
      this.setState({
        sort: on,
        maxNum: callback++,
      });
    });
  }
  async sortOff() {
    const { attribute, bunrui1, bunrui2, keyword } = this.state;

    // await this.props.getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page);
    this.props.getKaigoYougoListAll(
      attribute,
      bunrui1,
      bunrui2,
      keyword,
      () => { }
    );

    this.setState({
      sort: false,
      sortTableAll: [],
      sortTableList: [],
      copyTable: [],
      maxNum: null,
    });
  }
  sortSave() {
    const {
      attribute,
      bunrui1,
      bunrui2,
      keyword,
      sortTableAll,
      copyTable,
    } = this.state;

    const tmp = [];
    for (const record of sortTableAll) {
      const defTable = copyTable.find((t) => t.id == record.id);
      if (record.sortNum != defTable.sortNum) {
        tmp.push({
          id: record.id,
          sortNum: record.sortNum,
          attribute: record.attribute,
          bunrui1: record.bunrui1,
          bunrui2: record.bunrui2,
          keyword: record.keyword,
          naiyou: record.naiyou,
          // selectedCareTerm: null,
        });
      }
    }

    // this.props.updateKaigoYougoList2(tmp);

    this.props.updateKaigoYougoList(tmp, () => {
      // this.props.getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page);
      this.props.getKaigoYougoListAll(
        attribute,
        bunrui1,
        bunrui2,
        keyword,
        () => { }
      );
    });

    this.setState({
      // listTableTmp: sortTableAll,
      sort: false,
      sortTableAll: [],
      sortTableList: [],
      copyTable: [],
      maxNum: null,
      // offLoading: true,
    });

    // this.tmpLoading(tmp.length)
  }

  sortTop(index) {
    const sortTableAll = JSON.parse(JSON.stringify(this.state.sortTableAll));
    const element = JSON.parse(JSON.stringify(index));
    const elementIndex = sortTableAll.findIndex((v) => v.id === element.id);
    const minNum = sortTableAll[0].sortNum;

    if (elementIndex != 0) {
      element.sortNum = minNum - 1;

      sortTableAll.splice(elementIndex, 1);
      sortTableAll.splice(0, 0, element);

      this.setState({
        sortTableAll,
      });
    }
  }
  sortUp(index) {
    const sortTableAll = JSON.parse(JSON.stringify(this.state.sortTableAll));
    const element = JSON.parse(JSON.stringify(index));
    // const elementSortNum = element.sortNum;
    const elementIndex = sortTableAll.findIndex((v) => v.id === element.id);
    if (elementIndex != 0) {
      const changeElement = sortTableAll[elementIndex - 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex - 1;

      sortTableAll.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        // 移動予定のsortNumの値と上のレコードを比べる ※同じ番号があった場合の対策
        element.sortNum--;
        sortTableAll.splice(changeElementIndex, 0, element);
      }
      else {
        element.sortNum = changeElementSortNum; // 上のレコードのsortNumに変更する
        sortTableAll.splice(changeElementIndex, 0, element);
        sortTableAll[elementIndex].sortNum++; // 古いsortNumを変更
      }

      this.setState({
        sortTableAll,
      });
    }
  }
  sortDown(index) {
    const sortTableAll = JSON.parse(JSON.stringify(this.state.sortTableAll));
    const element = JSON.parse(JSON.stringify(index));
    // const elementSortNum = element.sortNum;
    const elementIndex = sortTableAll.findIndex((v) => v.id === element.id);
    if (elementIndex != sortTableAll.length - 1) {
      const changeElement = sortTableAll[elementIndex + 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex + 1;

      sortTableAll.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum++;
        sortTableAll.splice(changeElementIndex, 0, element);
      }
      else {
        element.sortNum = changeElementSortNum;
        sortTableAll.splice(changeElementIndex, 0, element);
        sortTableAll[elementIndex].sortNum--;
      }

      this.setState({
        sortTableAll,
      });
    }
  }

  sortBottom(index) {
    const sortTableAll = JSON.parse(JSON.stringify(this.state.sortTableAll));
    let maxNum = 0;
    for (const i of sortTableAll) {
      maxNum < i.sortNum ? (maxNum = i.sortNum) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(index));
    const elementIndex = sortTableAll.findIndex((v) => v.id === element.id);
    const bottomIndex = sortTableAll.length - 1;

    if (elementIndex != bottomIndex) {
      sortTableAll.splice(elementIndex, 1);
      element.sortNum = maxNum + 1;
      sortTableAll.splice(sortTableAll.length, 0, element);

      this.setState({
        sortTableAll,
      });
    }
  }

  render() {
    // console.log('modal', this.state.attribute1, this.props.linkingCareTerm, this.props.field);
    // console.log("disable", this.state.selectedCareTerm);
    // console.log("attribute", this.state.attribute)
    const { expandAll, selectedCareTerm, sort, sortTableAll } =
      this.state;
    // const { loadingList, loading, kaigoYougo } = this.props;
    const expandStyle = {
      maxWidth: "433px",
      whiteSpace: "normal",
      overflowWrap: "normal",
    };
    const normalStyle = {
      maxWidth: "433px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    };

    const columns =
      sort == false
        ?
        [
          {
            dataIndex: "naiyou",
            key: "naiyou",
            render: (text) => (
              <div style={!expandAll ? normalStyle : expandStyle}>{text}</div>
            ),
          },
          // { //テスト
          //   dataIndex: "sortNum",
          //   key: "sortNum",
          //   width: 40,
          // }
        ]
        : sort == true
          ? [
            {
              dataIndex: "naiyou",
              key: "naiyou",
              // width: 10,
              render: (text) => <div style={normalStyle}>{text}</div>,
            },
            // {  //テスト
            //   dataIndex: "sortNum",
            //   key: "sortNum",
            //   width: 50,
            // },
            {
              title: "行動",
              dataIndex: "operation",
              width: 130,
              key: "operation",
              render: (record, index) => {
                return (
                  <div>
                    <span>
                      <a onClick={() => this.sortTop(index)} style={{}}>
                        <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                      </a>
                      <Divider type="vertical" />
                      <a onClick={() => this.sortUp(index)} style={{}}>
                        <CaretUpOutlined style={{ fontSize: "16px" }} />
                      </a>
                      <Divider type="vertical" />
                      <a onClick={() => this.sortDown(index)} style={{}}>
                        <CaretDownOutlined style={{ fontSize: "16px" }} />
                      </a>
                      <Divider type="vertical" />
                      <a onClick={() => this.sortBottom(index)} style={{}}>
                        <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                      </a>
                    </span>
                  </div>
                );
              },
            },
          ]
          : [];

    // const dataSource = this.props.kaigoYougo
    //   ? this.props.kaigoYougo.map((k) => {
    //     return {
    //       id: k.id,
    //       naiyou: k.naiyou,
    //       description: k.attribute,
    //     };
    //   })
    //   : [];

    const dataSource2 =
      sort == false
        ? this.props.kaigoYougoAll
        : sort == true && sortTableAll
          ? sortTableAll
          : [];

    // const pagingOptions = {
    //   onChange: (page) => {
    //     this.handlePageChange(page);
    //   },
    //   total: this.props.meta.total,
    //   current: this.props.meta.page,
    //   pageSize: this.props.meta.pageSize,
    // };

    const footer = (
      <div>
        {sort == false ? (
          <span>
            <a
              key="sort"
              // type={"primary"}
              onClick={() => this.sortOn()}
              style={{
                marginRight: 8,
                marginTop: 5,
                marginLeft: 3,
                float: "left",
                color: "black",
              }}
            >
              並び替え
            </a>
            <Button key="back" onClick={() => this.handleCancel()}>
              キャンセル
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={() => this.handleOk()}
              disabled={!selectedCareTerm}
            >
              OK
            </Button>
          </span>
        ) : null}

        {sort == true ? (
          <span>
            <Button
              type={"primary"}
              onClick={() => this.sortSave()}
              style={{
                marginRight: 8,
                marginTop: 0,
                marginLeft: 0,
                float: "left",
              }}
            >
              並び替え保存
            </Button>
            <a
              onClick={() => this.sortOff()}
              style={{ paddingTop: "6px", color: "black", float: "left" }}
            >
              戻る
            </a>
            <Button key="back" onClick={() => this.handleCancel()}>
              キャンセル
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={() => this.handleOk()}
              disabled={true}
            >
              OK
            </Button>
          </span>
        ) : null}
      </div>
    );

    return (
      (<Fragment>
        {this.state.visible1 ? null : (
          <Modal
            // defaultExpandAllRows={}
            open={this.props.visible}
            title={"介護用語辞書選択"}
            className="modal-list"
            // onOk={() => this.handleOk()}
            onCancel={() => this.handleCancel()}
            width={500}
            footer={footer}
          >
            <Row>
              <div style={{ width: "80px", display: "inline-block" }}>
                {"属性"}
              </div>
              <Select
                value={this.state.attribute}
                style={{ width: "170px" }}
                onChange={(value) => this.onAttributeChange(value)}
                disabled={sort == true}
              >
                <Select.Option key={guid()} value={""}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Select.Option>
                {this.props.yougoAttribute.map((rel, index) => {
                  return (
                    <Select.Option key={index} value={rel}>
                      {rel}
                    </Select.Option>
                  );
                })}
              </Select>
            </Row>
            <Row>
              <div style={{ width: "80px", display: "inline-block" }}>
                {"分類1"}
              </div>
              <Select
                value={this.state.bunrui1}
                style={{ width: "170px" }}
                onChange={(value) => this.onBunrui1Change(value)}
                disabled={sort == true}
              >
                <Select.Option key={guid()} value={""}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Select.Option>
                {this.props.yougoBunrui1.map((rel, index) => {
                  return (
                    <Select.Option key={index} value={rel}>
                      {rel}
                    </Select.Option>
                  );
                })}
              </Select>
            </Row>
            <Row>
              <div style={{ width: "80px", display: "inline-block" }}>
                {"分類2"}
              </div>
              <Select
                value={this.state.bunrui2}
                style={{ width: "170px" }}
                onChange={(value) => this.onBunrui2Change(value)}
                disabled={sort == true}
              >
                <Select.Option key={guid()} value={""}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Select.Option>
                {this.props.yougoBunrui2.map((rel, index) => {
                  return (
                    <Select.Option key={index} value={rel}>
                      {rel}
                    </Select.Option>
                  );
                })}
              </Select>
            </Row>
            <Row>
              <div style={{ width: "80px", display: "inline-block" }}>
                {"キーワード"}
              </div>
              <Select
                value={this.state.keyword}
                style={{ width: "170px" }}
                onChange={(value) => this.onKeywordChange(value)}
                disabled={sort == true}
              >
                <Select.Option key={guid()} value={""}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Select.Option>
                {this.props.yougoKeyword.map((rel, index) => {
                  return (
                    <Select.Option key={index} value={rel}>
                      {rel}
                    </Select.Option>
                  );
                })}
              </Select>
            </Row>
            <Row style={{ marginTop: 5, marginBottom: 5 }}>
              <Col span={6}>
                <Button
                  disabled={sort == true}
                  type={"primary"}
                  onClick={() => this.handleSearchKaigoYougo()}
                >
                  {"検索"}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{ marginLeft: 10 }}
                  type={"primary"}
                  disabled={sort == true}
                  onClick={() =>
                    this.handleShowModalAddEdit(
                      null,
                      this.state.selectedCareTerm
                        ? this.state.selectedCareTerm.naiyou
                        : null
                    )
                  }
                >
                  {"用語追加"}
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  type={"primary"}
                  onClick={() =>
                    this.handleShowModalAddEdit(
                      this.state.selectedCareTerm
                        ? this.state.selectedCareTerm.id
                        : null,
                      null
                    )
                  }
                  disabled={
                    sort == true ? true : !selectedCareTerm
                  }
                >
                  {"用語修正"}
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  style={{ marginLeft: 10 }}
                  type={"primary"}
                  onClick={() => this.handleExpand(expandAll)}
                  disabled={sort == true}
                >
                  {!expandAll ? (
                    <Fragment>
                      <PlusOutlined />
                      {"展開"}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <MinusOutlined />
                      {"格納"}
                    </Fragment>
                  )}
                </Button>
              </Col>
            </Row>
            <Spin spinning={!!this.props.loading}>
              <Table
                // defaultExpandAllRows={true}
                // expandRowByClick={true}
                // expandedRowRender={(record) => <div>{record.description}</div>}
                id={"care-term-table"}
                className="riyou-meal-page modal-table"
                size={"small"}
                // loading={this.props.loading}
                showHeader={false}
                columns={columns}
                pagination={{ pageSize: 20 }}
                scroll={{ y: 300 }}
                rowKey={"id"}
                dataSource={dataSource2}
                onRow={(record) => {
                  return {
                    onClick: () => this.handleSelectCareTerm(record),
                  };
                }}
                rowClassName={(record) =>
                  this.state.selectedCareTerm != null &&
                    record.id === this.state.selectedCareTerm.id
                    ? "pljp-table-row-hover"
                    : ""
                }
                bordered
              />
            </Spin>
          </Modal>
        )}
        <Modal
          // destroyOnClose={true}
          maskClosable={false}
          open={this.state.visible1}
          title={"介護用語辞書"}
          width={500}
          onCancel={() => this.handleCancel1()}
          footer={[
            <Button key="back" type="primary" onClick={() => this.handleOk1()}>
              保存
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => this.handleCancel1()}
            >
              戻る
            </Button>,
          ]}
        >
          <Row>
            <div
              style={{ width: "80px", display: "inline-block", marginTop: 10 }}
            >
              {"属性"}
            </div>
            <Form.Item
              validateStatus={this.state.attributeErrorMessage ? "error" : ""}
              style={{ display: "inline-block" }}
              className="no-margin-bottom"
            >
              <Select
                value={this.state.attributeAdd}
                style={{ width: "170px" }}
                onChange={(value) => this.onAttributeChangeAdd(value)}
              >
                <Select.Option key={guid()} value={""}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Select.Option>
                {this.props.yougoAttribute.map((rel, index) => {
                  return (
                    <Select.Option key={index} value={rel}>
                      {rel}
                    </Select.Option>
                  );
                })}
              </Select>
              {this.state.attributeErrorMessage ? (
                <p
                  style={{
                    fontSize: 10,
                    marginTop: 3,
                    marginBottom: 10,
                    lineHeight: 1.5,
                    color: "#f5222d",
                  }}
                >
                  {this.state.attributeErrorMessage}
                </p>
              ) : null}
            </Form.Item>
          </Row>
          <Row>
            <div style={{ width: "80px", display: "inline-block" }}>
              {"分類1"}
            </div>
            <Select
              value={this.state.bunrui1Add}
              style={{ width: "170px" }}
              onChange={(value) => this.onBunrui1ChangeAdd(value)}
            >
              <Select.Option key={guid()} value={""}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Select.Option>
              {this.props.yougoBunrui1.map((rel, index) => {
                return (
                  <Select.Option key={index} value={rel}>
                    {rel}
                  </Select.Option>
                );
              })}
            </Select>
          </Row>
          <Row>
            <div style={{ width: "80px", display: "inline-block" }}>
              {"分類2"}
            </div>
            <Select
              value={this.state.bunrui2Add}
              style={{ width: "170px" }}
              onChange={(value) => this.onBunrui2ChangeAdd(value)}
            >
              <Select.Option key={guid()} value={""}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Select.Option>
              {this.props.yougoBunrui2.map((rel, index) => {
                return (
                  <Select.Option key={index} value={rel}>
                    {rel}
                  </Select.Option>
                );
              })}
            </Select>
          </Row>
          <Row>
            <div style={{ width: "80px", display: "inline-block" }}>
              {"キーワード"}
            </div>
            <Select
              value={this.state.keywordAdd}
              style={{ width: "170px" }}
              onChange={(value) => this.onKeywordChangeAdd(value)}
            >
              <Select.Option key={guid()} value={""}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Select.Option>
              {this.props.yougoKeyword.map((rel, index) => {
                return (
                  <Select.Option key={index} value={rel}>
                    {rel}
                  </Select.Option>
                );
              })}
            </Select>
          </Row>
          <Row>
            <div>{"用語内容"}</div>
            <TextArea
              rows={4}
              value={this.state.naiyouAdd}
              onChange={(value) => this.onNaiyouChangeAdd(value)}
            />
          </Row>
        </Modal>
        {/* <Modal
                    visible={this.state.visibleConfirm}
                    title="キャンセル確認"
                    onCancel={() => this.handleCloseConfirm()}
                    onOk={() => this.handleOpenConfirm()}>
                    <span style={{ display: "block" }}>{"キャンセルしてもよろしいですか？"}</span>
                </Modal> */}
      </Fragment>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    yougoAttribute: state.kyotakuPlan1.yougoAttribute || [],
    yougoBunrui1: state.kyotakuPlan1.yougoBunrui1 || [],
    yougoBunrui2: state.kyotakuPlan1.yougoBunrui2 || [],
    yougoKeyword: state.kyotakuPlan1.yougoKeyword || [],
    kaigoYougo: state.kaigoYougoMaster.kaigoYougo,
    kaigoYougoAll: state.kaigoYougoMaster.kaigoYougoAllList || [],
    meta: state.kaigoYougoMaster.meta,
    loading: state.kaigoYougoMaster.loading,
    // loadingList: state.kaigoYougoMaster.loadingList,
    kaigoYougoSelected: state.kaigoYougoMaster.kaigoYougoSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getYougoMaster: () => dispatch(getYougoMaster()),
    getKaigoYougoList: (attribute, bunrui1, bunrui2, keyword, page) =>
      dispatch(getKaigoYougoList(attribute, bunrui1, bunrui2, keyword, page)),
    updateKaigoYougo: (id, attribute, bunrui1, bunrui2, keyword, naiyou) =>
      dispatch(
        updateKaigoYougo(id, attribute, bunrui1, bunrui2, keyword, naiyou)
      ),
    getKaigoYougoId: (id) => dispatch(getKaigoYougoId(id)),
    getKaigoYougoListAll: (attribute, bunrui1, bunrui2, keyword, callback) =>
      dispatch(
        getKaigoYougoListAll(attribute, bunrui1, bunrui2, keyword, callback)
      ),
    updateKaigoYougoList: (data, callback) =>
      dispatch(updateKaigoYougoList(data, callback)),
    getMaxSortNum: (callback) => dispatch(getMaxSortNum(callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CareTermDictModal);
