import React from "react";
import { Row, Col, Divider, Flex } from "antd";
import LineBreakText from "../../../common/component/LineBreakText";
import { isEmpty } from "../../../common/function_common/functionCommon";
import { isManagement, formatStorage, getMenu } from '../../../common/function_common/functionCommon';

const ByoukiInfo = (props) => {
  let systemName = formatStorage(getMenu()).sn;
  return (
    <div>
      <Row>
        <Col sm={8}>
          <span className="rdp-basic-tab-title">主治医</span>
        </Col>
        <Col sm={16}>
          {props.riyousya &&
            props.riyousya.doctorMaster &&
            !isEmpty(props.riyousya.doctorMaster) ? (
            <div>
              <Row>
                {/* <Col sm={12} className="rdp-tab-title">[病医院名]</Col> */}
                <Col sm={12} className="rdp-tab-title">
                  [病医院名]
                </Col>
                <Col sm={12}>{props.riyousya.doctorMaster.hospital}</Col>
              </Row>
              <Divider />
              <Row>
                <Col sm={12} className="rdp-tab-title">
                  [主治医名]
                </Col>
                <Col sm={12}>{props.riyousya.doctorMaster.name}</Col>
              </Row>
              <Divider />
              <Row>
                <Col sm={12} className="rdp-tab-title">
                  [TEL]
                </Col>
                <Col sm={12}>{props.riyousya.doctorMaster.tel}</Col>
              </Row>
              <Divider />
              <Row>
                <Col sm={12} className="rdp-tab-title">
                  [緊急連絡先]
                </Col>
                <Col sm={12}>{props.riyousya.doctorMaster.urgent}</Col>
              </Row>
            </div>
          ) : null}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col sm={8} md={6}>
          <span className="rdp-basic-tab-title">病名</span>
        </Col>
        <Col sm={16}>
          {props.riyousya.riyouByoureki &&
            props.riyousya.riyouByoureki.length > 0
            ? props.riyousya.riyouByoureki.map((item, index) => {
              return (
                <Row key={index}>
                  {item.mainf === 1 ? (
                    <>
                    <Flex className="w-100">
                      <Col sm={12} className="rdp-tab-title">
                        [主病名]
                      </Col>
                      <Col sm={12}>
                        {item.byoumei ? <p>{item.byoumei}</p> : null}
                        {item.syoubyoumei ? <p>{item.syoubyoumei}</p> : null}
                        {item.syoubyoumei && item.symptomsAppearanceDate ? (
                          <p>発症日 {item.symptomsAppearanceDate}</p>
                        ) : item.syoubyoumei ? (
                          <p>発症日 </p>
                        ) : null}
                        {item.dateMemo ? <p>{item.dateMemo}</p> : null}
                        {item.bikou ? (
                          <p>
                            <LineBreakText text={item.bikou} />
                          </p>
                        ) : null}
                      </Col>
                    </Flex>
                    <Divider />
                    </>
                  ) : null}

                  {item.kansenf === 1 && item.mainf !== 1 ? (
                    <>
                    <div style={{display: 'flex', width: '100%'}}>
                      <Col sm={12} className="rdp-tab-title">
                        [感染症]
                      </Col>
                      <Col sm={12}>
                        {item.byoumei ? <p>{item.byoumei}</p> : null}
                        {item.syoubyoumei ? <p>{item.syoubyoumei}</p> : null}
                        {item.syoubyoumei && item.symptomsAppearanceDate ? (
                          <p>発症日 {item.symptomsAppearanceDate}</p>
                        ) : item.syoubyoumei ? (
                          <p>発症日 </p>
                        ) : null}
                        {item.dateMemo ? <p>{item.dateMemo}</p> : null}
                        {item.bikou ? (
                          <p>
                            <LineBreakText text={item.bikou} />
                          </p>
                        ) : null}
                      </Col>
                    </div>
                    <Divider />
                    </>
                  ) : null}

                  {!item.kansenf && !item.mainf ? (
                    <>
                    <div style={{display: 'flex', width: '100%'}}>
                      <Col sm={12} className="rdp-tab-title">
                        [その他病名]
                      </Col>
                      <Col sm={12}>
                        {item.byoumei ? <p>{item.byoumei}</p> : null}
                        {item.syoubyoumei ? <p>{item.syoubyoumei}</p> : null}
                        {item.syoubyoumei && item.symptomsAppearanceDate ? (
                          <p>発症日 {item.symptomsAppearanceDate}</p>
                        ) : item.syoubyoumei ? (
                          <p>発症日 </p>
                        ) : null}
                        {item.dateMemo ? <p>{item.dateMemo}</p> : null}
                        {item.bikou ? (
                          <p>
                            <LineBreakText text={item.bikou} />
                          </p>
                        ) : null}
                      </Col>
                    </div>
                    <Divider />
                    </>
                  ) : null}
                </Row>
              );
            })
            : null}
        </Col>
      </Row>

      <Row>
        {
          isManagement()
            ?
            <Row className="w-100">
              <Col sm={8} md={6} >
                <span className="rdp-basic-tab-title">ケアマネ</span>
              </Col>
              <Col sm={8} className="rdp-tab-title">[担当者]</Col>
              <Col sm={8}>
                {
                  systemName === "kyotaku" && props.riyousya?.riyousyaKyotakuDto
                    ? props.riyousya.riyousyaKyotakuDto.mainTnatoName
                    : ""
                }
                {
                  systemName === "yobou" && props.riyousya?.riyousyaYobouDto
                    ? props.riyousya.riyousyaYobouDto.mainTnatoName
                    : ""
                }
                {
                  (systemName === "syoukibo" || systemName === "fukugo") && props.riyousya?.riyousyaSyoukiboDto
                    ? props.riyousya.riyousyaSyoukiboDto.mainTnatoName
                    : ""
                }
              </Col>
            </Row>
            :
            null
        }
      </Row>
    </div>
  );
};

export default ByoukiInfo;
