import { Form } from "@ant-design/compatible";
import { LinkOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Row,
  TimePicker
} from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import React from "react";
import { ConstSet } from "../../../common/configs/constset";
import docIcon from "../../../common/images/document/doc.png";
import pdfIcon from "../../../common/images/document/pdf.png";
import pptIcon from "../../../common/images/document/ppt.png";
import xlsIcon from "../../../common/images/document/xls.png";
dayjs.extend(dayjsPluginUTC);

class FileForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    visibleConfirm: false,
    isDelConfirm: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        //
        const data = {
          id: values.id ? values.id.toString() : null,
          // @TODO
          date: values.date ? values.date.format(ConstSet.DATE_FORMAT) : null,

          comment: values.comment ? values.comment : null,
          fileName: values.fileName ? values.fileName : null,
          kubun: values.kubun ? values.kubun : null,
          path: values.path ? values.path : null,
          priorityf: values.priorityf ? values.priorityf.toString() : null,
          riyousyaId: values.riyousyaId ? values.riyousyaId.toString() : null,
          tantoMasterId: values.tantoMasterId
            ? values.tantoMasterId.toString()
            : null,
          serviceKikanMasterId: values.serviceKikanMasterId
            ? values.serviceKikanMasterId.toString()
            : null,
          kangoJissekiId: values.kangoJissekiId
            ? values.kangoJissekiId.toString()
            : null,
          helperJissekiId: values.helperJissekiId
            ? values.helperJissekiId.toString()
            : null,
          syoukiboJissekiId: values.syoukiboJissekiId
            ? values.syoukiboJissekiId.toString()
            : null,
          visitrehaJissekiId: values.visitrehaJissekiId
            ? values.visitrehaJissekiId.toString()
            : null,
          reportCaseId: values.reportCaseId
            ? values.reportCaseId.toString()
            : null,

          // @TODO
          time: values.time ? values.time.format("HH:mm:ss") : null,
          type: values.type ? values.type : null,
        };

        // console.log(data);

        this.props.regData(data);
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("「パスワード」と「パスワード(確認)」の値が一致しません。");
    }
    else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.props.form.isFieldsTouched()) this.showGoBackConfirm();
    else this.props.history.goBack();
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.goBack();
  }

  delete = () => {
    this.setState({
      isDelConfirm: true,
    });
  };

  closeDelConfirm = () => {
    this.setState({
      isDelConfirm: false,
    });
  };

  okDelConfirm = () => {
    const { data } = this.props;
    this.setState({
      isDelConfirm: false,
    });

    this.props.delete(data.id);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      (<div>
        <Form
          {...formItemLayout}
          labelAlign="left"
          style={{ maxWidth: 980, margin: "0 auto" }}
        >
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item label="ID（自動生成）" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("id", {
                  rules: [{ required: false }],
                })(<Input disabled />)}
              </Form.Item>

              <Form.Item label="日付" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("date", {
                  rules: [{ required: false }],
                })(<DatePicker />)}
              </Form.Item>

              <Form.Item label="優先" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("priorityf", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="訪問リハ実績ID" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("visitrehaJissekiId", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>

              {data && data.id ? (
                <div>
                  <Form.Item
                    wrapperCol={{ md: 24 }}
                    style={{ height: "100%", borderStyle: "dotted" }}
                  >
                    {(() => {
                      switch (data && data.type ? data.type : "") {
                        case "doc":
                          return (
                            <img
                              src={docIcon}
                              alt="file"
                              style={{ maxWidth: "100%" }}
                            />
                          );

                        case "docx":
                          return (
                            <img
                              src={docIcon}
                              alt="file"
                              style={{ maxWidth: "100%" }}
                            />
                          );

                        case "xls":
                          return (
                            <img
                              src={xlsIcon}
                              alt="file"
                              style={{ maxWidth: "100%" }}
                            />
                          );

                        case "xlsx":
                          return (
                            <img
                              src={xlsIcon}
                              alt="file"
                              style={{ maxWidth: "100%" }}
                            />
                          );

                        case "ppt":
                          return (
                            <img
                              src={pptIcon}
                              alt="file"
                              style={{ maxWidth: "100%" }}
                            />
                          );

                        case "pdf":
                          return (
                            <img
                              src={pdfIcon}
                              alt="file"
                              style={{ maxWidth: "100%" }}
                            />
                          );

                        case "mp3":
                          return (
                            <audio
                              src={
                                window.location.protocol +
                                "//" +
                                window.location.hostname +
                                ":" +
                                window.location.port +
                                "/api/v1/file-management-url?fileManagementId=" +
                                data.id
                              }
                              alt="file"
                              style={{ maxWidth: "100%" }}
                            />
                          );

                        case "m4a":
                          return (
                            <audio
                              src={
                                window.location.protocol +
                                "//" +
                                window.location.hostname +
                                ":" +
                                window.location.port +
                                "/api/v1/file-management-url?fileManagementId=" +
                                data.id
                              }
                              alt="file"
                              style={{ maxWidth: "100%" }}
                            />
                          );

                        case "mov":
                          return (
                            <video
                              alt="file"
                              style={{ maxWidth: "100%" }}
                              controls
                              autoPlay=""
                            >
                              <source
                                src={
                                  window.location.protocol +
                                  "//" +
                                  window.location.hostname +
                                  ":" +
                                  window.location.port +
                                  "/api/v1/file-management-url?fileManagementId=" +
                                  data.id
                                }
                                type="video/mov"
                              />
                            </video>
                          );

                        case "MOV":
                          return (
                            <video alt="file" style={{ maxWidth: "100%" }}>
                              <source
                                src={
                                  window.location.protocol +
                                  "//" +
                                  window.location.hostname +
                                  ":" +
                                  window.location.port +
                                  "/api/v1/file-management-url?fileManagementId=" +
                                  data.id
                                }
                                type="video/mp4"
                              />
                            </video>
                          );

                        case "mp4":
                          return (
                            <video
                              src={
                                window.location.protocol +
                                "//" +
                                window.location.hostname +
                                ":" +
                                window.location.port +
                                "/api/v1/file-management-url?fileManagementId=" +
                                data.id
                              }
                              alt="file"
                              style={{ maxWidth: "100%" }}
                            />
                          );

                        case "jpg":
                          return (
                            <img
                              src={
                                window.location.protocol +
                                "//" +
                                window.location.hostname +
                                ":" +
                                window.location.port +
                                "/api/v1/file-management-url?fileManagementId=" +
                                data.id
                              }
                              alt="file"
                              style={{ maxWidth: "100%", maxHeight: "500px" }}
                            />
                          );

                        case "jpeg":
                          return (
                            <img
                              src={
                                window.location.protocol +
                                "//" +
                                window.location.hostname +
                                ":" +
                                window.location.port +
                                "/api/v1/file-management-url?fileManagementId=" +
                                data.id
                              }
                              alt="file"
                              style={{ maxWidth: "100%", maxHeight: "500px" }}
                            />
                          );

                        case "png":
                          return (
                            <img
                              src={
                                window.location.protocol +
                                "//" +
                                window.location.hostname +
                                ":" +
                                window.location.port +
                                "/api/v1/file-management-url?fileManagementId=" +
                                data.id
                              }
                              alt="file"
                              style={{ maxWidth: "100%", maxHeight: "500px" }}
                            />
                          );

                        case "gif":
                          return (
                            <img
                              src={
                                window.location.protocol +
                                "//" +
                                window.location.hostname +
                                ":" +
                                window.location.port +
                                "/api/v1/file-management-url?fileManagementId=" +
                                data.id
                              }
                              alt="file"
                              style={{ maxWidth: "100%", maxHeight: "500px" }}
                            />
                          );

                        default:
                          return null;
                      }
                    })()}
                  </Form.Item>
                </div>
              ) : (
                <div>
                  <Form.Item label="ファイル名" wrapperCol={{ md: 24 }}>
                    {getFieldDecorator("fileName", {
                      rules: [{ required: false }],
                    })(<Input />)}
                  </Form.Item>

                  <Form.Item label="ファイルタイプ" wrapperCol={{ md: 24 }}>
                    {getFieldDecorator("type", {
                      rules: [{ required: false }],
                    })(<Input />)}
                  </Form.Item>

                  <Form.Item label="パス" wrapperCol={{ md: 24 }}>
                    {getFieldDecorator("path", {
                      rules: [{ required: false }],
                    })(<Input />)}
                  </Form.Item>
                </div>
              )}
            </Col>
            <Col md={8}>
              <Form.Item label="利用者ID" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("riyousyaId", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="時間 " wrapperCol={{ md: 24 }}>
                {getFieldDecorator("time", {
                  rules: [{ required: false }],
                })(<TimePicker showNow={false}
                  needConfirm={false} />)}
              </Form.Item>

              <Form.Item label="看護実績ID" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("kangoJissekiId", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>
              <Form.Item label="ケース記録ID" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("reportCaseId", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>
              <Form.Item label="区分" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("kubun", {
                  rules: [{ required: false }],
                })(<Input />)}
              </Form.Item>
              <Form.Item wrapperCol={{ md: 24 }}>
                {data && data.id ? (
                  <div>
                    <p>
                      <LinkOutlined /> ファイル名: {data.fileName}
                    </p>
                    <p>
                      <LinkOutlined /> ファイルタイプ: {data.type}
                    </p>
                    <p>
                      <LinkOutlined /> パス: {data.path}
                    </p>
                  </div>
                ) : null}
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="担当者ID" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("tantoMasterId", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="コメント" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("comment", {
                  rules: [{ required: false }],
                })(<Input />)}
              </Form.Item>

              <Form.Item label="ﾍﾙﾊﾟｰ実績ID" wrapperCol={{ md: 24 }}>
                {getFieldDecorator("helperJissekiId", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>
              {data && data.id ? (
                <div>
                  <Form.Item label="ファイル名" wrapperCol={{ md: 24 }}>
                    {getFieldDecorator("fileName", {
                      rules: [{ required: false }],
                    })(<Input disabled />)}
                  </Form.Item>

                  <Form.Item label="ファイルタイプ" wrapperCol={{ md: 24 }}>
                    {getFieldDecorator("type", {
                      rules: [{ required: false }],
                    })(<Input disabled />)}
                  </Form.Item>

                  <Form.Item label="パス" wrapperCol={{ md: 24 }}>
                    {getFieldDecorator("path", {
                      rules: [{ required: false }],
                    })(<Input />)}
                  </Form.Item>
                </div>
              ) : null}
            </Col>
          </Row>
          {data && data.id ? (
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" danger onClick={this.delete}>
                削除
              </Button>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                更新
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          ) : (
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                新規登録
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          )}
        </Form>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
        <Modal
          open={this.state.isDelConfirm}
          title="削除確認"
          onCancel={() => this.closeDelConfirm()}
          onOk={() => this.okDelConfirm()}
        >
          <span style={{ display: "block" }}>
            {"削除してもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }
}

const WrappedFileForm = Form.create({
  name: "kanri-user-form",
  onValuesChange() { },
  mapPropsToFields(props) {
    return {
      id: Form.createFormField({
        value: props.data ? props.data.id : null,
      }),
      riyousyaId: Form.createFormField({
        value:
          props.data && props.data.riyousya ? props.data.riyousya.id : null,
      }),
      tantoMasterId: Form.createFormField({
        value:
          props.data && props.data.tantoMaster
            ? props.data.tantoMaster.id
            : null,
      }),
      date: Form.createFormField({
        // converty date time to standard
        value:
          props.data && props.data.date
            ? dayjs.utc(props.data.date, ConstSet.DATE_FORMAT)
            : dayjs(),
      }),
      time: Form.createFormField({
        // @TODO convert time to standard
        value:
          props.data && props.data.time
            ? dayjs(props.data.time, "HH:mm:ss")
            : null,
      }),
      fileName: Form.createFormField({
        value: props.data ? props.data.fileName : null,
      }),
      type: Form.createFormField({
        value: props.data ? props.data.type : null,
      }),
      path: Form.createFormField({
        value: props.data ? props.data.path : null,
      }),
      comment: Form.createFormField({
        value: props.data ? props.data.comment : null,
      }),
      priorityf: Form.createFormField({
        value: props.data ? props.data.priorityf : null,
      }),
      kangoJissekiId: Form.createFormField({
        value: props.data ? props.data.kangoJissekiId : null,
      }),
      helperJissekiId: Form.createFormField({
        value: props.data ? props.data.helperJissekiId : null,
      }),
      syoukiboJissekiId: Form.createFormField({
        value: props.data ? props.data.syoukiboJissekiId : null,
      }),
      visitrehaJissekiId: Form.createFormField({
        value: props.data ? props.data.visitrehaJissekiId : null,
      }),
      reportCaseId: Form.createFormField({
        value: props.data ? props.data.reportCaseId : null,
      }),
      kubun: Form.createFormField({
        value: props.data ? props.data.kubun : null,
      }),
    };
  },
})(FileForm);

export default WrappedFileForm;
