/* eslint-disable no-nested-ternary */
import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Input, Popconfirm, Popover, Row, Table } from "antd";
import { black } from "material-ui/styles/colors";
import React from "react";

const { TextArea } = Input;

const EditableCell = ({
  editable,
  value,
  onChange,
  valueEdit,
  Expansion,
  sort,
}) => (
  <div>
    {editable ? (
      <Input
        style={{ margin: "-5px 0" }}
        value={valueEdit}
        onChange={(e) => onChange(e.target.value)}
      />
    ) : Expansion && !sort ? (
      <div
        style={{
          maxWidth: "240px",
          whiteSpace: "normal",
          overflowWrap: "normal",
        }}
      >
        {value}
      </div>
    ) : (
      <div
        style={{
          maxWidth: "240px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </div>
    )}
  </div>
);

class NormalCommentKB extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      key: null,
      newText: "",
      visible: false,
      sort: false,
      sortTable: [],
      Expansion: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.edit = this.edit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.renderColumns = this.renderColumns.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.delete = this.delete.bind(this);
    this.handleOnchangeNew = this.handleOnchangeNew.bind(this);
    this.add = this.add.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.defaultValue !== newProps.defaultValue) {
      this.props.form.resetFields();
    }
  }

  // handle active checkbox in multiInput only
  handleOnChange = () => {
    if (this.props.handleOnchange) {
      this.props.handleOnchange();
    }
  };

  /**
   * Performance choise
   *
   */
  handleOnBlur = (e) => {
    this.props.handleTextareaKB(e.target.value);
  };

  select = (naiyou) => {
    this.props.handleTextareaKB(naiyou, "select");
  };

  onSelect = (value) => {
    this.props.handleTextareaKB(value);
  };

  renderColumns(text, record, column) {
    const { Expansion, sort } = this.state;
    return (
      <EditableCell
        sort={sort}
        Expansion={Expansion}
        editable={record.editable}
        value={text}
        valueEdit={this.state.text}
        onChange={(value) => this.handleChange(value, record.key, column)}
      />
    );
  }
  handleChange(value, key) {
    this.setState({
      text: value,
      key,
    });
  }
  edit(key, naiyou) {
    this.setState({
      text: naiyou,
      key,
    });
    this.props.yougoMasterEditState(key);
  }

  save() {
    if (this.state.text) {
      const { yougoMasterNa } = this.props;
      let naiyou = "";
      for (let i = 0; i < yougoMasterNa.length; i++) {
        if (i === this.state.key) {
          if (!naiyou) {
            naiyou = this.state.text;
          }
          else naiyou = naiyou + ";" + this.state.text;
        }
        else if (!naiyou) {
          naiyou = yougoMasterNa[i].naiyou;
        }
        else {
          naiyou = naiyou + ";" + yougoMasterNa[i].naiyou;
        }
      }

      this.props.yougoMasterEditSave(naiyou);
    }
    else {
      // @TODO return error
    }
  }

  delete(key) {
    const { yougoMasterNa } = this.props;
    let preNaiyou = "";
    for (let i = 0; i < yougoMasterNa.length; i++) {
      if (i !== key) {
        if (!preNaiyou) {
          preNaiyou = yougoMasterNa[i].naiyou;
        }
        else {
          preNaiyou = preNaiyou + ";" + yougoMasterNa[i].naiyou;
        }
      }
    }

    this.props.yougoMasterEditSave(preNaiyou);
  }

  cancel(key) {
    this.props.yougoMasterEditCancel(key);
  }

  handleOnchangeNew = (e) => {
    this.setState({
      newText: e.target.value,
    });
  };

  add = () => {
    if (this.state.newText) {
      const { yougoMasterNa } = this.props;
      let preNaiyou = this.state.newText;
      for (let i = 0; i < yougoMasterNa.length; i++) {
        preNaiyou = preNaiyou + ";" + yougoMasterNa[i].naiyou;
      }

      this.props.yougoMasterEditSave(preNaiyou);

      this.setState({
        newText: "",
      });
    }
  };

  handleClickChange = (visible) => {
    this.setState({
      visible,
    });
    if (!visible) {
      this.setState({
        sort: false,
        sortTable: [],
        Expansion: false,
      });
    }
  };

  sortOn = () => {
    const { yougoMasterNa } = this.props;
    this.setState({
      sort: true,
      sortTable: yougoMasterNa,
    });
  };
  sortOff = () => {
    this.setState({
      sort: false,
      sortTable: [],
    });
  };
  sortSave = () => {
    const { sortTable } = this.state;
    let preNaiyou = "";

    if (sortTable.length > 0) {
      for (const i in sortTable) {
        if (!preNaiyou) {
          preNaiyou = sortTable[i].naiyou;
        }
        else {
          preNaiyou = preNaiyou + ";" + sortTable[i].naiyou;
        }
      }
    }

    this.props.yougoMasterEditSave(preNaiyou);

    this.setState({
      sort: false,
      sortTable: [],
    });
  };
  sortTop = (record, index) => {
    const { sortTable } = this.state;
    const tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));

    if (index != 0) {
      const newIndex = 0;
      tableTmp.splice(index, 1);
      tableTmp.splice(newIndex, 0, element);
      for (const i in tableTmp) {
        tableTmp[i].key = i;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };
  sortUp = (record, index) => {
    const { sortTable } = this.state;
    const tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));

    if (index != 0) {
      const newIndex = record.key - 1;
      tableTmp.splice(index, 1);
      tableTmp.splice(newIndex, 0, element);
      for (const i in tableTmp) {
        tableTmp[i].key = i;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };
  sortDown = (record, index) => {
    const { sortTable } = this.state;
    const tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));

    if (sortTable.length - 1 != index) {
      const newIndex = parseInt(record.key) + 1;
      tableTmp.splice(index, 1);
      tableTmp.splice(newIndex, 0, element);
      for (const i in tableTmp) {
        tableTmp[i].key = i;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };
  sortBottom = (record, index) => {
    const { sortTable } = this.state;
    const tableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));

    if (sortTable.length - 1 != index) {
      const newIndex = sortTable.length - 1;
      tableTmp.splice(index, 1);
      tableTmp.splice(newIndex, 0, element);
      for (const i in tableTmp) {
        tableTmp[i].key = i;
      }

      this.setState({
        sortTable: tableTmp,
      });
    }
  };

  handleExpandTab = () => {
    // パネル全体
    const { Expansion } = this.state;

    if (Expansion) {
      this.setState({
        Expansion: false,
      });
    }
    else {
      this.setState({
        Expansion: true,
      });
    }
  };

  render() {
    const { yougoMasterNa, rowNum, disabled } = this.props;
    const { sort, sortTable, Expansion } = this.state;

    const title = (
      <div>
        {sort == false ? (
          <span>
            <Input
              onChange={(e) => this.handleOnchangeNew(e)}
              value={this.state.newText}
              style={{ width: "250px", marginRight: "16px" }}
            />
            <a onClick={() => this.add()}>追加</a>
            <a
              onClick={this.sortOn}
              style={{
                marginRight: 8,
                marginTop: 5,
                marginLeft: 15,
                color: "black",
              }}
            >
              並び替え
            </a>
            <a
              onClick={this.handleClickChange.bind(this, false)}
              style={{ float: "right", paddingTop: 6, color: black }}
            >
              閉じる
            </a>
          </span>
        ) : null}
        {sort == true ? (
          <span>
            <Button
              type={"primary"}
              onClick={this.sortSave}
              style={{ marginRight: 8, marginTop: 0, marginLeft: 15 }}
            >
              並び替え保存
            </Button>
            <a
              onClick={this.sortOff}
              style={{ paddingTop: "14px", color: "black" }}
            >
              戻る
            </a>
            <a
              onClick={this.handleClickChange.bind(this, false)}
              style={{ float: "right", paddingTop: 6, color: black }}
            >
              閉じる
            </a>
          </span>
        ) : null}
      </div>
    );

    const columns = [
      {
        title: "naiyou",
        dataIndex: "naiyou",
        width: 250,
        render: (text, record) =>
          this.renderColumns(text, record, "naiyou"),
      },
      {
        title: "行動",
        dataIndex: "operation",
        width: 150,
        render: (text, record, index) => {
          const { editable } = record;
          return (
            <div className="editable-row-operations">
              {sort === true ? (
                <span>
                  <a onClick={() => this.sortTop(record, index)} style={{}}>
                    <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                  </a>
                  <Divider type="vertical" />
                  <a onClick={() => this.sortUp(record, index)} style={{}}>
                    <CaretUpOutlined style={{ fontSize: "16px" }} />
                  </a>
                  <Divider type="vertical" />
                  <a onClick={() => this.sortDown(record, index)} style={{}}>
                    <CaretDownOutlined style={{ fontSize: "16px" }} />
                  </a>
                  <Divider type="vertical" />
                  <a onClick={() => this.sortBottom(record, index)} style={{}}>
                    <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
                  </a>
                </span>
              ) : editable ? (
                <span>
                  <a onClick={() => this.save()}>保存</a>
                  <Divider type="vertical" />
                  <a onClick={() => this.cancel(record.key)}>閉じる</a>
                </span>
              ) : (
                <span>
                  <a onClick={() => this.select(record.naiyou)}>選択</a>
                  <Divider type="vertical" />
                  <a onClick={() => this.edit(record.key, record.naiyou)}>
                    修正
                  </a>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="削除してもいいですか？"
                    onConfirm={() => this.delete(record.key)}
                  >
                    <a>削除</a>
                  </Popconfirm>
                </span>
              )}
            </div>
          );
        },
      },
    ];

    const content = (
      <div>
        {sort == false ? (
          <div>
            <Table
              bordered
              columns={columns}
              dataSource={yougoMasterNa}
              pagination={{ pageSize: 50 }}
              scroll={{ y: 240 }}
              showHeader={false}
              size={"small"}
              rowKey={(record, index) => index} // ?
            />
            <Button
              type="primary"
              icon={<LegacyIcon type={Expansion ? "minus" : "plus"} />}
              // className="modal-expansion"
              style={{ float: "left", marginTop: -40 }}
              onClick={this.handleExpandTab}
            >
              {Expansion ? "格納" : "展開"}
            </Button>
          </div>
        ) : null}
        {sort == true ? (
          <Table
            bordered
            columns={columns}
            dataSource={sortTable}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 240 }}
            showHeader={false}
            size={"small"}
            rowKey={(record, index) => index}
          />
        ) : null}
      </div>
    );

    const { getFieldDecorator } = this.props.form;

    return (
      <Row>
        <Col span={20}>
          {getFieldDecorator("ect", {
            rules: [
              {
                required: false,
              },
            ],
            initialValue: this.props.defaultValue,
          })(
            <TextArea
              disabled={disabled ? disabled : false}
              rows={rowNum ? rowNum : 2}
              onBlur={this.handleOnBlur}
              onChange={this.handleOnChange}
            />
          )}
        </Col>
        <Col span={4} style={{ textAlign: "center" }}>
          <Popover
            overlayStyle={{ width: "480px" }}
            placement={"bottomRight"}
            title={title}
            content={content}
            trigger="click"
            onVisibleChange={this.handleClickChange}
            visible={this.state.visible}
          >
            <Button
              disabled={disabled ? disabled : false}
              type="primary"
              style={{ margin: "0 10px" }}
            >
              選択
            </Button>
          </Popover>
        </Col>
      </Row>
    );
  }
}

const CommentKB = Form.create()(NormalCommentKB);
export default CommentKB;
