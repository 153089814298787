import React, { Fragment } from "react";
import { Modal, Input, Row, Col, Button, Checkbox, Spin } from "antd";
import { toKatakana } from "wanakana";
import { connect } from "react-redux";
import {
  loadItakuTantoMasterInit,
  createItakuTantoMaster,
  updateItakuTantoMaster,
} from "../../../actions/itakuTantoMasterActions/actionCreators";

// import MasterCodeInput from "./MasterCodeInput";
// import MasterCodeCancelButton from "./MasterCodeCancelButton";
// import IryoKikanMasterModal from "./IryoKikanMasterModal";
import ServiceKikanHoken from "./ServiceKikanHoken";
import _ from "lodash";

const ADD = 1;
const EDIT = 2;
const COPY = 3;

const reference = { table: "doctor_master", field: "code" };

class itakuTantoMasterEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      initCode: null,
      visibleServiceKikan: false,
      visibleZip: false,
      checkZip: false,
      visibleYougo: false,
      listName: "",
      titleYougo: "",
      errorNameVisible: false,
      serviceType: "43",
    };
  }

  componentDidUpdate(prevProps) {
    const { selectJigyousy } = this.props;

    if (this.props.visible && this.props.visible !== prevProps.visible) {
      if (this.props.data && !(this.props.editType === ADD)) {
        if (this.props.editType === EDIT) {
          this.setState({
            data: this.props.data,
          });
        } else if (this.props.editType === COPY) {
          this.props.loadMaxSort((data) => {
            let copyData = {
              ...this.props.data,
              id: null,
              code: data.code || "",
              sortNum: data.sortNum || "",
            };
            this.setState({
              data: copyData,
            });
          });
        }
      } else {
        //新規追加の場合は委託先の事業所をpropsからもらってformにセットする
        this.props.loadItakuTantoMasterInit((data) => {
          let initDate = {};
          if (this.props.selectJigyousy) {
            initDate = data
              ? {
                  ...data,
                  id: null,
                  jigyousyoName: selectJigyousy.jigyousyoName,
                  jigyousyoNo: selectJigyousy.jigyousyoNo,
                  itakuJigyoushoId: selectJigyousy.id,
                }
              : {};
          } else {
            initDate = data ? { ...data, id: null } : {};
          }
          this.setState({
            data: initDate,
          });
        });
      }
    }
  }

  //入力チェンジ
  onChangeValue(e, field) {
    const { data } = this.state;

    e.stopPropagation();
    let newValue = data[field];
    switch (field) {
      case "stop":
        newValue = e.target.checked ? 1 : 0;
        break;
      case "zaitakuSien":
        newValue = e.target.checked ? 1 : 0;
        break;
      default:
        newValue = e.target.value;
    }

    if (newValue !== data[field]) {
      this.setState({
        data: { ...data, [field]: newValue },
      });
    }
  }
  onChangeCode(value, first) {
    const { data } = this.state;

    if (first) {
      this.setState({
        data: { ...data, sienSenmoninNum: value },
        initCode: value,
      });
    } else {
      this.setState({
        data: { ...data, sienSenmoninNum: value },
      });
    }
  }
  handleFurigana(e, field) {
    const { data } = this.state;

    e.preventDefault();
    const value = toKatakana(e.target.value);
    this.setState({
      data: { ...data, [field]: value },
    });
  }

  //保存
  async handleSave() {
    const { data } = this.state;

    if (this.checkRequired()) return;

    if (data.id && data.id > 0) {
      await this.props.updateItakuTantoMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    } else {
      await this.props.createItakuTantoMaster(data, (data) => {
        this.props.handleSelectRow(data);
      });
    }

    this.handleClose();
  }
  checkRequired() {
    const { data } = this.state;

    let isError = false;
    if (_.isEmpty(data.name)) {
      isError = true;
      this.setState({
        errorNameVisible: true,
      });
    } else {
      this.setState({
        errorNameVisible: false,
      });
    }

    return isError;
  }

  //サービス機関マスタ
  openServiceKikan() {
    this.setState({
      visibleServiceKikan: true,
    });
  }
  closeServiceKikan = () => {
    this.setState({
      visibleServiceKikan: false,
    });
  };
  onSubmiServiceKikan = (key, record, recordSelected) => {
    const { data } = this.state;

    if (record) {
      this.setState({
        data: {
          ...data,
          jigyousyoName: record.jigyousyoName || "",
          jigyousyoNo: record.jigyousyoNo || "",
          itakuJigyoushoId: record.id || "",
        },
      });
    }
    this.closeServiceKikan();
  };

  //閉じる
  handleClose() {
    this.setState({
      errorNameVisible: false,
    });
    this.props.handleClose();
  }

  render() {
    const { data, errorNameVisible, initCode } = this.state;

    return (
      (<Fragment>
        <Modal
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          open={this.props.visible}
          width={800}
          footer={[
            <Row key={1} type="flex" justify="end">
              <div style={{ paddingRight: 8 }}>
                <Button onClick={() => this.handleClose()}>戻る</Button>
              </div>
              <div>
                <Button type={"primary"} onClick={() => this.handleSave()}>
                  {"保存"}
                </Button>
              </div>
            </Row>,
          ]}
        >
          <Row>
            <Col span={5}>
              <p style={{ marginTop: 5 }}>ID</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.id}
                onChange={(e) => this.onChangeValue(e, "id")}
                readOnly
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={5}>
              <p style={{ marginTop: 5 }}>支援専門員番号</p>
            </Col>
            <Col span={4}>
              <Input
                value={data.sienSenmoninNum}
                // maxLength={10}
                // checkType={this.props.editType === ADD}
                // reference={reference}
                onChange={(e) => this.onChangeValue(e, "sienSenmoninNum")}
              />
            </Col>
            <Col span={14}>
              <Checkbox
                type={"checkbox"}
                checked={data.stop}
                style={{ marginTop: 5, marginLeft: 50 }}
                onChange={(e) => this.onChangeValue(e, "stop")}
              >
                利用中止
              </Checkbox>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={5}>
              <p style={{ marginTop: 5 }}>名前</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.name}
                onChange={(e) => this.onChangeValue(e, "name")}
                style={errorNameVisible ? { border: "1px solid red" } : {}}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={5}>
              <p style={{ marginTop: 5 }}>フリガナ</p>
            </Col>
            <Col span={14}>
              <Input
                value={data.furigana}
                onChange={(e) => this.onChangeValue(e, "furigana")}
                onBlur={(e) => this.handleFurigana(e, "furigana")}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 25 }}>
            <Col span={5}>
              <p style={{ marginTop: 5 }}>事業所番号</p>
            </Col>
            <Col span={19} style={{ display: "flex" }}>
              <Input
                value={
                  this.props.data && this.props.data.serviceKikanMaster
                    ? this.props.data.serviceKikanMaster.jigyousyoNo
                    : data.jigyousyoNo
                }
                style={{ width: 160 }}
                readOnly
              />
              <Input
                value={
                  this.props.data && this.props.data.serviceKikanMaster
                    ? this.props.data.serviceKikanMaster.jigyousyoName
                    : data.jigyousyoName
                }
                readOnly
              />
              <Button type={"primary"} onClick={() => this.openServiceKikan()}>
                {"M"}
              </Button>
              <Input
                value={
                  this.props.data && this.props.data.serviceKikanMaster
                    ? this.props.data.serviceKikanMaster.id
                    : data.itakuJigyoushoId
                }
                style={{ width: 70, marginLeft: 5 }}
                readOnly
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={5}>
              <p style={{ marginTop: 5 }}>メモ</p>
            </Col>
            <Col span={18}>
              <Input
                value={data.memo}
                onChange={(e) => this.onChangeValue(e, "memo")}
                // onBlur={(e) => this.handleFurigana(e, "mome")}
              />
            </Col>
          </Row>
        </Modal>
        <ServiceKikanHoken
          visible={this.state.visibleServiceKikan}
          onSaveServiceKikan={this.onSubmiServiceKikan}
          hideModal={this.closeServiceKikan}
          serviceType={this.state.serviceType}
          serviceSyurui={this.props.serviceSyurui}
        />
      </Fragment>)
    );
  }
}

const mapStateToProps = () => (state) => ({
  loading: state.referenceNumber.loading || state.doctorMaster.loading,
});

const mapDispatchToProps = () => (dispatch) => ({
  loadItakuTantoMasterInit: (callback) =>
    dispatch(loadItakuTantoMasterInit(callback)),
  createItakuTantoMaster: (body, callback) =>
    dispatch(createItakuTantoMaster(body, callback)),
  updateItakuTantoMaster: (body, callback) =>
    dispatch(updateItakuTantoMaster(body, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(itakuTantoMasterEditModal);
