import { inquiryRequestActionTypes } from "../action_types/index";

// constants
import { ApiPaths } from "../../constants";
import { RequestUtils, CommonUtils } from "../../utils";
import { StorageUtils } from "../../utils/storage";

function getYougoMaster(listName) {
  return async (dispatch) => {
    try {
      dispatch({ type: inquiryRequestActionTypes.GET_YOUGO_MASTER });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.ApiGeneral.GetYougoMaster}?listName=${listName}`,
      });
      dispatch({
        type: inquiryRequestActionTypes.GET_YOUGO_MASTER_SUCCESS,
        ...data,
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.GET_YOUGO_MASTER_ERROR,
        error,
      });
    }
  };
}

function saveYougoMaster(listName, naiyou) {
  return async (dispatch) => {
    try {
      const body = { listName, naiyou };
      dispatch({ type: inquiryRequestActionTypes.SAVE_YOUGO_MASTER });
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.ApiGeneral.UpdateYougomaster,
        body,
      });
      dispatch({
        type: inquiryRequestActionTypes.SAVE_YOUGO_MASTER_SUCCESS,
        ...data,
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.SAVE_YOUGO_MASTER_ERROR,
        error,
      });
    }
  };
}

function getTantoMaster() {
  return async (dispatch) => {
    const menu = StorageUtils.getValue("menuMNGT");
    try {
      dispatch({ type: inquiryRequestActionTypes.GET_TANTO_MASTER });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.ApiGeneral.GetTantoMaster}?sid=${menu.si}`,
      });
      dispatch({
        type: inquiryRequestActionTypes.GET_TANTO_MASTER_SUCCESS,
        tantoMaster: [...data],
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.GET_TANTO_MASTER_ERROR,
        error,
      });
    }
  };
}

function getServiceKikan(bunrui, name) {
  return async (dispatch) => {
    const body = { bunrui, name };
    try {
      dispatch({ type: inquiryRequestActionTypes.GET_SERVICE_KIKAN });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.ApiGeneral.GetServiceKikan,
        body,
      });
      dispatch({
        type: inquiryRequestActionTypes.GET_SERVICE_KIKAN_SUCCESS,
        serviceKikan: [...data],
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.GET_SERVICE_KIKAN_ERROR,
        error,
      });
    }
  };
}

function getServiceSyuruiMaster() {
  return async (dispatch) => {
    try {
      dispatch({ type: inquiryRequestActionTypes.GET_SERVICE_SYURUI_MASTER });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.ApiGeneral.GetServiceSyuruiMaster,
      });
      dispatch({
        type: inquiryRequestActionTypes.GET_SERVICE_SYURUI_MASTER_SUCCESS,
        serviceSyuruiMaster: [...data],
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.GET_SERVICE_SYURUI_MASTER_ERROR,
        error,
      });
    }
  };
}

function getDataInquiryRequest(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: inquiryRequestActionTypes.GET_DATA_INQUIRY_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.InquiryRequest.GetDataInquiRequest}/${id}`,
      });
      dispatch({
        type: inquiryRequestActionTypes.GET_DATA_INQUIRY_SUCCESS,
        dataInquiryRequest: data,
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.GET_DATA_INQUIRY_ERROR,
        error,
      });
    }
  };
}

function cloneDataInquiryRequest(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: inquiryRequestActionTypes.CLONE_DATA_INQUIRY_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.InquiryRequest.GetDataInquiRequest}/${id}`,
      });
      dispatch({
        type: inquiryRequestActionTypes.CLONE_DATA_INQUIRY_SUCCESS,
        dataInquiryRequest: data,
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.CLONE_DATA_INQUIRY_ERROR,
        error,
      });
    }
  };
}

function addRecord(record) {
  return async (dispatch) => {
    try {
      dispatch({ type: inquiryRequestActionTypes.ADD_RECORD, record });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.ADD_RECORD_ERROR,
        error,
      });
    }
  };
}

function deleteRecord(index) {
  return async (dispatch) => {
    try {
      dispatch({ type: inquiryRequestActionTypes.DELETE_RECORD, index });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.DELETE_RECORD_ERROR,
        error,
      });
    }
  };
}

function getSyoukaiDate(body) {
  return async (dispatch) => {
    try {
      dispatch({ type: inquiryRequestActionTypes.GET_SYOUKAI_DATE });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.InquiryRequest.GetSyoukaiDate,
        query: body,
      });
      dispatch({
        type: inquiryRequestActionTypes.GET_SYOUKAI_DATE_SUCCESS,
        SyoukiaDate: data,
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.GET_SYOUKAI_DATE_ERROR,
        error,
      });
    }
  };
}

function onSaveData(body, riyousyaId, callback) {
  if (body.id) {
    return async (dispatch) => {
      try {
        dispatch({
          type: inquiryRequestActionTypes.SAVE_UPDATE_DATA_INQUIRY_REQUEST,
        });
        const data = await RequestUtils.callApi({
          method: "PUT",
          path: ApiPaths.InquiryRequest.SaveUpdateDataInquiryRequest,
          body,
        });
        dispatch({
          type: inquiryRequestActionTypes.SAVE_UPDATE_DATA_INQUIRY_SUCCESS,
          dataInquiryRequest: data,
        });
      } catch (error) {
        dispatch({
          type: inquiryRequestActionTypes.SAVE_UPDATE_DATA_INQUIRY_ERROR,
          error,
        });
      }
    };
  }
  return async (dispatch) => {
    const menu = StorageUtils.getValue("menuMNGT");
    try {
      dispatch({
        type: inquiryRequestActionTypes.SAVE_CREATE_DATA_INQUIRY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.InquiryRequest.SaveCreateDataInquiryRequest,
        body: {
          ...body,
          serviceKikanMasterId: Number(menu.si),
          riyousyaId: Number(riyousyaId),
        },
      });
      dispatch({
        type: inquiryRequestActionTypes.SAVE_CREATE_DATA_INQUIRY_SUCCESS,
        dataInquiryRequest: data,
      });
      callback({ ...data });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.SAVE_CREATE_DATA_INQUIRY_ERROR,
        error,
      });
    }
  };
}

// get data view
function getDataViewInquiryRequest(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: inquiryRequestActionTypes.GET_DATA_VIEW_INQUIRY_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.InquiryRequest.GetDataViewInquiryRequest}/${id}`,
      });
      callback({ ...data });
    } catch (error) {
      // do something
    }
  };
}

// get list ids view
function getListId(riyousyaId) {
  const sort = StorageUtils.getValue("list_sort_riyousya").inquiryRequest || {};
  const menu = StorageUtils.getValue("menuMNGT");
  return async (dispatch) => {
    const body = {
      riyousyaId,
      sid: menu.si,
      sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
    };
    try {
      dispatch({ type: inquiryRequestActionTypes.GET_IDS_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.InquiryRequest.GetIdsInquiryRequest,
        query: body,
      });
      dispatch({
        type: inquiryRequestActionTypes.GET_IDS_SUCCESS,
        idsInquiRequest: data,
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.GET_IDS_ERROR,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: inquiryRequestActionTypes.DELETE_INQUIRY_REQUEST });
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.InquiryRequest.GetDataInquiRequest + "/" + id,
      });
      dispatch({
        type: inquiryRequestActionTypes.DELETE_INQUIRY_SUCCESS,
        dataDelete: data,
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.DELETE_INQUIRY_FAILURE,
        error,
      });
    }
  };
}

function getListInquiryRequest(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  return async (dispatch) => {
    try {
      dispatch({ type: inquiryRequestActionTypes.GET_LIST_INQUIRY_REQUEST });
      const query = {
        sid: menu.si,
        riyousyaId,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.InquiryRequest.GetListInquiryRequest,
        query,
      });
      dispatch({
        type: inquiryRequestActionTypes.GET_LIST_INQUIRY_SUCCESS,
        dataList: data,
      });
    } catch (error) {
      dispatch({
        type: inquiryRequestActionTypes.GET_LIST_INQUIRY_FAILURE,
        error,
      });
    }
  };
}

export const InquiryRequest = {
  getYougoMaster,
  saveYougoMaster,
  getTantoMaster,
  getServiceKikan,
  getServiceSyuruiMaster,
  getDataInquiryRequest,
  addRecord,
  deleteRecord,
  getSyoukaiDate,
  onSaveData,
  getDataViewInquiryRequest,
  getListId,
  deleteId,
  getListInquiryRequest,
  cloneDataInquiryRequest,
};
