import { openNotificationWithIcon } from "../../../../common/function_common/functionCommon";
import {
  // UPDATE_LIST_DOCTOR_MASTER_SUCCESS,

  KOHIFUTAN_MASTER_SPIN_REQUEST,
  KOHIFUTAN_MASTER_LOAD_REQUEST,
  KOHIFUTAN_MASTER_CREATE_REQUEST,
  KOHIFUTAN_MASTER_UPDATE_REQUEST,
  KOHIFUTAN_MASTER_INIT_REQUEST,
  KOHIFUTAN_MASTER_UPDATELIST_REQUEST,
  LOAD_KOHIFUTAN_MASTER_INIT_SUCCESS,
  LOAD_KOHIFUTAN_MASTER_INIT_ERROR,
  CREATE_KOHIFUTAN_MASTER_SUCCESS,
  CREATE_KOHIFUTAN_MASTER_ERROR,
  UPDATE_KOHIFUTAN_MASTER_SUCCESS,
  UPDATE_KOHIFUTAN_MASTER_ERROR,
  UPDATE_LIST_KOHIFUTAN_MASTER_SUCCESS,
  LOAD_MAX_SORT_SUCCESS,
  LOAD_MAX_SORT_ERROR,
  LOAD_KOHIFUTAN_MASTER_SUCCESS,
  LOAD_KOHIFUTAN_MASTER_ERROR,
} from "../../../../actions/kohifutanMasterActions/actionName";

export default function kohifutanMaster(state = {}, action) {
  switch (action.type) {
    case KOHIFUTAN_MASTER_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KOHIFUTAN_MASTER_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KOHIFUTAN_MASTER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KOHIFUTAN_MASTER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KOHIFUTAN_MASTER_INIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case KOHIFUTAN_MASTER_UPDATELIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //初期値
    case LOAD_KOHIFUTAN_MASTER_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        initKohifutan: action.data,
      };
    case LOAD_KOHIFUTAN_MASTER_INIT_ERROR:
      return {
        ...state,
        loading: false,
        initKohifutan: null,
      };

    //追加
    case CREATE_KOHIFUTAN_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        createKohifutan: action.data,
      };
    case CREATE_KOHIFUTAN_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        createKohifutan: null,
        loading: false,
      };

    //修正
    case UPDATE_KOHIFUTAN_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateKohifutan: action.payload,
        loading: false,
      };
    case UPDATE_KOHIFUTAN_MASTER_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        updateKohifutan: null,
        loading: false,
      };

    case UPDATE_LIST_KOHIFUTAN_MASTER_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateListKohifutan: action.payload,
        loading: false,
      };

    //順番
    case LOAD_MAX_SORT_SUCCESS:
      return {
        ...state,
        maxSort: action.data,
        loading: false,
      };
    case LOAD_MAX_SORT_ERROR:
      return {
        ...state,
        maxSort: null,
        loading: false,
      };

    //マスター読み込みはriyousyaで行われている
    //使われてないリデューサー
    case LOAD_KOHIFUTAN_MASTER_SUCCESS:
      return {
        ...state,
        doctorMasterList: action.data,
        loading: false,
      };

    case LOAD_KOHIFUTAN_MASTER_ERROR:
      return {
        ...state,
        doctorMasterList: null,
        loading: false,
      };

    default:
      return state;
  }
}
