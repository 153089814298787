/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Table, Spin } from "antd";
import { HeaderMenuView } from "../view/HeaderMenuViewV2";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import { UrlUtils } from "../../utils";
import LineBreakText from "../../common/component/LineBreakText";
import { stringToAttendanceArray } from "../../common/function_common/reportAdlAttendance";
import { getPath } from "../../common/function_common/functionCommon";
import "./InfoKyotaku2.css";

// const dataKey = ConfigName.SWLIFE_LISTRIYOUSYA;

function detail(props) {
  const back = new URLSearchParams(props.history.location.search).get("back");
  const { riyousyaId, adlId } = props.match.params;
  const {
    adl,
    adls,
    riyousya,
    listName, //ADL区分 settingMasterから読み込み
    adlMaster, //AdlMaster
  } = props;
  const { reportAdlSub } = props.adl;

  const [id, setId] = useState(adlId);
  const [ids, setIds] = useState([]);
  const path = getPath();

  /**
   * riyousyaIdで担当会議レコード取得
   */
  useEffect(() => {
    let userId = 0;
    if (back && back === "RiyouAdl") {
      userId = Number(riyousyaId);
    }
    props.getSettingMasterAdl((data) => {
      if (data != null) {
        const bunruiTmp = data.split(",");
        props.getAdlMaster(bunruiTmp, () => {});
      }
    });
    props.getRiyousyaInfo(userId);
    props.loadAdl({ riyousyaId: userId }, () => {
      //callback
    });
  }, []);

  /**
   * porpsの担当会議リストをidsにセットする
   */
  useEffect(() => {
    if (adls && adls.length > 0) {
      const data = adls.map((x) => x.id);
      setIds(data);
    }
  }, [adls]);

  /**
   * propsのadlIdが変更されたら再度レコード取得
   */
  useEffect(() => {
    props.loadAdlById(id);
  }, [id]);

  /**
   * レコードが変更されたらURLを変えて別の閲覧を表示
   */
  useEffect(() => {
    if (adl) {
      console.log(adl);
      if (!UrlUtils.parse(props.history.location.search).preview) {
        props.history.push(
          `${path}/report-adl-view/${adl.riyousyaId}/${id}?back=RiyouAdl`
        );
      } else {
        props.history.push(
          `${path}/report-adl-view/${adl.riyousyaId}/${id}?preview=true`
        );
      }
    }
  }, [adl]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  function goFirst() {
    if (ids) {
      setId(ids[0]);
    }
  }
  function goPrev() {
    if (ids) {
      const index = ids && ids.indexOf(Number(adlId)) - 1;
      setId(ids[index]);
    }
  }
  function goNext() {
    if (ids) {
      const index = ids && ids.indexOf(Number(adlId)) + 1;
      setId(ids[index]);
    }
  }
  function goLast() {
    if (ids) {
      const index = ids && ids.length;
      setId(ids[index - 1]);
    }
  }

  function update() {
    props.loadAdlById(id);
  }

  function onCloseTab() {
    window.close();
  }

  const headerMenuDetail = (
    <HeaderMenuView
      // riyousyaName={props &&
      //               props.adl &&
      //               props.adl.riyousya ?
      //               props.adl.riyousya.name : ""
      // }
      screenName={"【ADL記録閲覧】"}
      goFirst={() => goFirst()}
      goPrev={() => goPrev()}
      goNext={() => goNext()}
      goLast={() => goLast()}
      handleGoBack={() => handleGoBack()}
      update={() => update()}
      onCloseTab={() => onCloseTab()}
      history={props.history}
      listId={ids || []}
      id={id}
    />
  );

  function handleGoBack() {
    //
    if (back && back === "RiyouAdl")
      props.history.push(`${path}/report-adl/${riyousyaId}`);
    else props.history.push(`${path}/report-adl/${riyousyaId}`); //利用者別用
  }

  /**
   * 記録区分ごとのカラム
   * @returns カラムを返す
   */
  const getTableColumns = () => {
    return [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "85%",
        render: (text) => {
          if (typeof text === "string") {
            return <LineBreakText text={text} />;
          }
          return text;
        },
      },
    ];
  };

  //ページの内容
  const renderDesktopContent = () => {
    // console.log(adl)
    //日付 & 担当者
    const columnTable1 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "35%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "35%",
      },
    ];
    const dataTable1 = [
      {
        title: "日付",
        content: adl.evaluationDate || "",
        title2: "担当者",
        content2: adl && adl.tantoMaster ? adl.tantoMaster.name : "",
      },
    ];

    // 記録タイトルのみ
    const columnTable2 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "20%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title3",
        width: "30%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title4",
        width: "35%",
      },
    ];
    const dataTable2 = [
      {
        title: "ADL区分",
        title2: "内容",
        title3: "状態",
        title4: "コメント",
      },
    ];

    //特記
    const columnTable3 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "85%",
      },
    ];
    const dataTable3 = [
      {
        title: "特記",
        content: adl.tokki || "",
      },
    ];

    //コメント
    const columnTable4 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "85%",
      },
    ];
    const dataTable4 = [
      {
        title: "コメント",
        content: adl.comment || "",
      },
    ];

    const columnAttendance = [
      {
        className: "",
        title: "",
        dataIndex: "naiyou",
        width: "20%",
      },
      {
        className: "",
        title: "",
        dataIndex: "answer",
        width: "30%",
      },
      {
        className: "",
        title: "",
        dataIndex: "comment",
        width: "35%",
      },
    ];
    const columunTableTab = getTableColumns(); //タブごとのカラム15％|85%
    const dataAttendance = (listName) =>
      adl && adl.reportAdlSub
        ? stringToAttendanceArray(adl.reportAdlSub, listName, adlMaster)
        : [];
    const dataTableTab = (listName) => {
      //listName = "基本動作...etc"
      return [
        {
          title: listName,
          content: (
            <Table
              style={{ padding: 0 }}
              className={"view-table view-table--b"}
              pagination={false}
              showHeader={false}
              columns={columnAttendance}
              dataSource={dataAttendance(listName)}
              rowKey="id"
            />
          ),
        },
      ];
    };

    return (
      <div id="view-doc-list-page">
        <Spin spinning={props.loading}>
          <div className="view-report-adl-container">
            {/* 利用者名 */}
            <div className="nameContent">
              <span className="riyouTitle">
                <h3>利用者名:</h3>
              </span>
              <span className="riyousya">
                {adl && adl.riyousya
                  ? adl.riyousya.name
                  : riyousya
                  ? riyousya.name
                  : ""}
              </span>
            </div>

            {/* 日付＆担当者 */}
            <div style={{ marginTop: 10 }}>
              <Table
                className={"view-table view-table--1"}
                bordered={false}
                pagination={false}
                showHeader={false}
                columns={columnTable1}
                dataSource={dataTable1}
              />
            </div>

            {/* ADL区分ごとのテーブル（タイトルのみ） */}
            <div style={{ marginTop: 16 }}>
              <Table
                bordered={false}
                className={"view-table-title view-table--2"}
                pagination={false}
                showHeader={false}
                columns={columnTable2}
                dataSource={dataTable2}
                rowKey="id"
              />
              {listName && listName.length > 0
                ? listName.split(",").map((item, index) => {
                    {
                      if (
                        adlMaster &&
                        adlMaster[item] &&
                        adlMaster[item].length > 0 &&
                        reportAdlSub &&
                        reportAdlSub.length > 0
                      ) {
                        for (let i of reportAdlSub) {
                          if (i.adlMaster && i.adlMaster.bunrui == item) {
                            return (
                              <div key={`adlKiroku${index}`}>
                                <Table //
                                  // bordered={false}
                                  className={"view-table view-table--a"}
                                  pagination={false}
                                  showHeader={false}
                                  columns={columunTableTab}
                                  dataSource={dataTableTab(item)}
                                  rowKey="id"
                                />
                              </div>
                            );
                          }
                        }
                      } else {
                        //AdlMasterのnaiyouがない
                        return null;
                      }
                    }
                  })
                : null}
            </div>
            {/* ここまでADL区分 */}

            {/* 特記 */}
            <div style={{ marginTop: 20 }}>
              <Table
                className={"view-table view-table--9"}
                bordered={false}
                pagination={false}
                showHeader={false}
                columns={columnTable3}
                dataSource={dataTable3}
              />
            </div>

            {/* コメント */}
            <div style={{ marginTop: 20 }}>
              <Table
                className={"view-table view-table--9"}
                bordered={false}
                pagination={false}
                showHeader={false}
                columns={columnTable4}
                dataSource={dataTable4}
              />
            </div>
          </div>
          {/* ↑ここでページ内容終了 */}
        </Spin>
      </div>
    );
  };

  return (
    <div>
      <CommonPageContainer
        id={"view-doc-list-page"}
        sessionTimeout={props.sessionTimeout}
      >
        {headerMenuDetail}
        {renderDesktopContent()}
      </CommonPageContainer>
    </div>
  );
}

export default detail;
