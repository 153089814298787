import {
  ACCOUNT_LOGIN,
  MAINTAINANCE_MODE,
} from "../../actions/accountActions/actionName.js";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  mode: "",
};
export default function accountReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACCOUNT_LOGIN:
      return {
        ...state,
        data: action.dataLogin,
      };

    case MAINTAINANCE_MODE:
      return {
        ...state,
        mode: action.payload,
      };

    default:
      return state;
  }
}
