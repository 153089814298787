import {
  SEND_GROUP_API_REQUEST,
  SEND_GROUP_API_ERROR,
  SEND_GROUP,
  SEND_GROUP_ALL,
  SEND_GROUP_ADD_LOCAL,
  SEND_GROUP_DEL_LOCAL,
  SEND_GROUP_ADD_TEXT,
  SEND_GROUP_UPDATE_SERVER,
} from "../../actions/sendGroupActions/actionName";
import SendGroupApi from "../../api/sendGroup";

/*
 * @param: username: string
 * @param: password: string
 */
function sendGroupCreator() {
  return (dispatch) => {
    SendGroupApi.sendGroup().then((data) =>
      dispatch({
        type: SEND_GROUP,
        groups: data,
      })
    );
  };
}

function getAllSendGroupCreator() {
  return (dispatch) => {
    SendGroupApi.getAllSendGroup().then((data) =>
      dispatch({
        type: SEND_GROUP_ALL,
        payload: data,
      })
    );
  };
}

function addMemberToGroupCreator(checkListUsers, selectedGroup) {
  return {
    type: SEND_GROUP_ADD_LOCAL,
    checkListUsers: checkListUsers,
    selectedGroup: selectedGroup,
  };
}

function delMemberFromGroupCreator(checkListGroups, selectedGroup) {
  return {
    type: SEND_GROUP_DEL_LOCAL,
    checkListGroups: checkListGroups,
    selectedGroup: selectedGroup,
  };
}

function addNewSendGroupCreator(text) {
  return {
    type: SEND_GROUP_ADD_TEXT,
    text: text,
  };
}

function updateSendGroupToServerCreator(groupRecords, delIdsSendGroup) {
  return (dispatch) => {
    dispatch({
      type: SEND_GROUP_API_REQUEST,
    });

    SendGroupApi.update(groupRecords, delIdsSendGroup)
      .then((data) =>
        dispatch({
          type: SEND_GROUP_UPDATE_SERVER,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({
          type: SEND_GROUP_API_ERROR,
          messages: error,
        })
      );
  };
}

export {
  sendGroupCreator,
  getAllSendGroupCreator,
  addMemberToGroupCreator,
  delMemberFromGroupCreator,
  addNewSendGroupCreator,
  updateSendGroupToServerCreator,
};
