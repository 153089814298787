import { EVENT_PLAN_RIYOUSYA } from "./actionName";
import { EventPlanRiyousyaApi } from "../../api/eventPlan.js";

/*
 * @param: username: string
 * @param: password: string
 */
export default function eventPlanRiyousyaCreator(date, riyousyaId) {
  return (dispatch) => {
    EventPlanRiyousyaApi.eventPlanRiyousyaApi(date, riyousyaId).then((data) =>
      dispatch({
        type: EVENT_PLAN_RIYOUSYA,
        payload: data,
      })
    );
  };
}
