/* eslint-disable */
import { Icon as LegacyIcon } from '@ant-design/compatible';

import { Button, Input, Modal } from 'antd';
import _ from "lodash";
import 'moment/locale/ja';
import React from 'react';
import { formatStorage, getSwpsps } from "../../../common/function_common/functionCommon";
import FilterConditions from './FilterConditions';

export class Filter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleModalSearch: false
        };

        // bind place
        this.getHandleState = this.getHandleState.bind(this);
        this.handleModalSearchOk = this.handleModalSearchOk.bind(this);
        this.handleModalSearchCancel = this.handleModalSearchCancel.bind(this);
    }

    showModalSearchConditions = () => {
        this.setState({
            visibleModalSearch: true,
        });
    }

    handleModalSearchOk = (e) => {
        this.props.handleFilterOk();
        this.setState({
            visibleModalSearch: false
        });
    }

    handleModalSearchCancel = (e) => {
        this.props.handleFilterCancel();
        this.setState({
            visibleModalSearch: false
        });
    }

    /**
     * Update state between component
     */
    getHandleState(filterData) {
        if (!filterData.search.checkbox1) {
            filterData.search.field10 = null;
            filterData.search.field11 = null;
        }
        if (!filterData.search.checkbox2) {
            filterData.search.field2 = null;
        }
        if (!filterData.search.checkbox3) {
            filterData.search.field3 = null;
        }
        if (!filterData.search.checkbox4) {
            filterData.search.field4 = null;
        }
        if (!filterData.search.checkbox5) {
            filterData.search.field5 = null;
        }
        if (!filterData.search.checkbox6) {
            filterData.search.field6 = null;
        }
        if (!filterData.search.checkbox7) {
            filterData.search.field7 = null;
        }
        if (!filterData.search.checkbox8) {
            filterData.search.field8 = null;
        }
        this.props.handleUpdateState(filterData);
    }

    render() {
        const { filterData, nyutaishoFilterData, tantoMasters, systemNames, riyousyaBunrui } = this.props;
        let settingNaiyo1 = null;
        let yougoMaster11 = null;
        let yougoMaster12 = null;
        let yougoMaster13 = null;
        let yougoMaster14 = null;
        let yougoMaster15 = null;
        let yougoMaster21 = null;
        let kirokuKubun = null;
        let tantoShikaku = null;
        let sonotaKubun = null;
        if (nyutaishoFilterData) {
            settingNaiyo1 = nyutaishoFilterData.settingNaiyo1 ? nyutaishoFilterData.settingNaiyo1.split(',') : '';
            yougoMaster11 = nyutaishoFilterData.yougoMaster11 ? nyutaishoFilterData.yougoMaster11.split(';') : '';
            yougoMaster12 = nyutaishoFilterData.yougoMaster12 ? nyutaishoFilterData.yougoMaster12.split(';') : '';
            yougoMaster13 = nyutaishoFilterData.yougoMaster13 ? nyutaishoFilterData.yougoMaster13.split(';') : '';
            yougoMaster14 = nyutaishoFilterData.yougoMaster14 ? nyutaishoFilterData.yougoMaster14.split(';') : '';
            yougoMaster15 = nyutaishoFilterData.yougoMaster15 ? nyutaishoFilterData.yougoMaster15.split(';') : '';
            yougoMaster21 = nyutaishoFilterData.yougoMaster21 ? nyutaishoFilterData.yougoMaster21.split(';') : '';
            kirokuKubun = nyutaishoFilterData.kirokuKubun ? nyutaishoFilterData.kirokuKubun.split(';') : '';
            tantoShikaku = nyutaishoFilterData.tantoShikaku ? nyutaishoFilterData.tantoShikaku.split(';') : '';
            sonotaKubun = nyutaishoFilterData.sonotaKubun ? nyutaishoFilterData.sonotaKubun.split(';') : '';
        }
        let systems = [];
        if (systemNames) {
            systems = systemNames;
        }
        let tantoMasterList = [];
        if (tantoMasters) {
            tantoMasterList = tantoMasters;
        }
        return (
            (<div style={{ padding: "0 10px", width: "100%" }} id={"filter"}>
                <span style={{ fontSize: "small" }}>{"絞り込み条件"}</span>
                <Input
                    readOnly
                    value={
                        (filterData.search.field11 && filterData.search.field10
                            ?
                            filterData.search.field10.split('=')[1] + '=' + filterData.search.field11 + ' / '
                            : "")
                        + (filterData.search.field2 ? '部屋分類:' + filterData.search.field2 + ' / ' : "")
                        + (filterData.search.field3 && systems.length > 0 ? 'サービス種類:' + _.find(systems, s => s.sid == filterData.search.field3).name + ' / ' : "")
                        + (filterData.search.field4 ? '記録区分:' + filterData.search.field4 + ' / ' : "")
                        + (filterData.search.field5 != null && tantoMasterList.length > 0 ? '担当者:' + _.find(tantoMasterList, t => t.id == (filterData.search.field5 == 0 ? formatStorage(getSwpsps()).tid : filterData.search.field5)).name + ' / ' : "")
                        + (filterData.search.field6 ? '担当者資格:' + filterData.search.field6 + ' / ' : "")
                        + (filterData.search.field7 ? 'その他区分:' + filterData.search.field7 + ' / ' : "")
                        + (filterData.search.field8 == 'ケアマネ' ? 'ケアマネ経過記録のみ' + ' / ' : filterData.search.field8 == '記録' ? '記録のみ' + ' / ' : '')
                    }
                />
                <Button
                    size={this.props.isMobile ? "small" : "default"}
                    style={{ width: "70px", padding: "0px 0px", marginLeft: "2px" }}
                    onClick={this.showModalSearchConditions}
                    icon={<LegacyIcon type={"setting"} />}
                >{"条件"}</Button>
                <Modal
                    title="絞り込み検索"
                    open={this.state.visibleModalSearch}
                    okText={"検索"}
                    cancelText={"閉じる"}
                    onOk={this.handleModalSearchOk}
                    onCancel={this.handleModalSearchCancel}
                    className={"filter-model"}
                    destroyOnClose={true}
                >
                    <FilterConditions
                        settingNaiyo1={settingNaiyo1}
                        yougoMaster11={yougoMaster11}
                        yougoMaster12={yougoMaster12}
                        yougoMaster13={yougoMaster13}
                        yougoMaster14={yougoMaster14}
                        yougoMaster15={yougoMaster15}
                        yougoMaster21={yougoMaster21}
                        kirokuKubun={kirokuKubun}
                        tantoShikaku={tantoShikaku}
                        tantoMasters={tantoMasters}
                        sonotaKubun={sonotaKubun}
                        systems={systems}
                        handleUpdateState={(data) => this.getHandleState(data)}
                        filterData={filterData}
                        riyousyaBunrui={riyousyaBunrui}
                    />
                </Modal>
            </div>)
        );
    }
}