export const ALL_MASTER_SPIN_REQUEST = "ALL_MASTER_SPIN_REQUEST";
export const ALL_MASTER_SPIN_FALSE = "ALL_MASTER_SPIN_FALSE";

export const BYOUMEI_MASTER_LIST_SUCCESS = "BYOUMEI_MASTER_LIST_SUCCESS";
export const BYOUMEI_MASTER_INIT_SUCCESS = "BYOUMEI_MASTER_INIT_SUCCESS";
export const ALL_DRUG_MASTER_LIST_SUCCESS = "ALL_DRUG_MASTER_LIST_SUCCESS";
export const DRUG_MASTER_INIT_SUCCESS = "DRUG_MASTER_INIT_SUCCESS";
export const MACHINE_MASTER_LIST_SUCCESS = "MACHINE_MASTER_LIST_SUCCESS";
export const MACHINE_MASTER_INIT_SUCCESS = "MACHINE_MASTER_INIT_SUCCESS";
export const SERVICE_SYURUI_MASTER_LIST_SUCCESS =
  "SERVICE_SYURUI_MASTER_LIST_SUCCESS";
export const SERVICE_KIKAN_MASTER_LIST_SUCCESS =
  "SERVICE_KIKAN_MASTER_LIST_SUCCESS";
export const IRYOU_KIKAN_MASTER_LIST_SUCCESS =
  "IRYOU_KIKAN_MASTER_LIST_SUCCESS";
export const IRYOU_KIKAN_MASTER_INIT_SUCCESS =
  "IRYOU_KIKAN_MASTER_INIT_SUCCESS";
export const CREATE_BYOUMEI_MASTER_SUCCESS = "CREATE_BYOUMEI_MASTER_SUCCESS";
export const CREATE_BYOUMEI_MASTER_ERROR = "CREATE_BYOUMEI_MASTER_ERROR";
export const UPDATE_BYOUMEI_MASTER_SUCCESS = "UPDATE_BYOUMEI_MASTER_SUCCESS";
export const UPDATE_BYOUMEI_MASTER_ERROR = "UPDATE_BYOUMEI_MASTER_ERROR";
export const CREATE_DRUG_MASTER_SUCCESS = "CREATE_DRUG_MASTER_SUCCESS";
export const CREATE_DRUG_MASTER_ERROR = "CREATE_DRUG_MASTER_ERROR";
export const UPDATE_DRUG_MASTER_SUCCESS = "UPDATE_DRUG_MASTER_SUCCESS";
export const UPDATE_DRUG_MASTER_ERROR = "UPDATE_DRUG_MASTER_ERROR";
export const CREATE_MACHINE_MASTER_SUCCESS = "CREATE_MACHINE_MASTER_SUCCESS";
export const CREATE_MACHINE_MASTER_ERROR = "CREATE_MACHINE_MASTER_ERROR";
export const UPDATE_MACHINE_MASTER_SUCCESS = "UPDATE_MACHINE_MASTER_SUCCESS";
export const UPDATE_MACHINE_MASTER_ERROR = "UPDATE_MACHINE_MASTER_ERROR";
export const CREATE_IRYOU_KIKAN_MASTER_SUCCESS =
  "CREATE_IRYOU_KIKAN_MASTER_SUCCESS";
export const CREATE_IRYOU_KIKAN_MASTER_ERROR =
  "CREATE_IRYOU_KIKAN_MASTER_ERROR";
export const UPDATE_IRYOU_KIKAN_MASTER_SUCCESS =
  "UPDATE_IRYOU_KIKAN_MASTER_SUCCESS";
export const UPDATE_IRYOU_KIKAN_MASTER_ERROR =
  "UPDATE_IRYOU_KIKAN_MASTER_ERROR";
export const UPDATE_LIST_IRYOU_KIKAN_MASTER_SUCCESS =
  "UPDATE_LIST_IRYOU_KIKAN_MASTER_SUCCESS";
export const UPDATE_LIST_BYOUMEI_SUCCESS = "UPDATE_LIST_BYOUMEI_SUCCESS";
export const UPDATE_LIST_DRUG_SUCCESS = "UPDATE_LIST_DRUG_SUCCESS";
export const UPDATE_LIST_MACHINE_SUCCESS = "UPDATE_LIST_MACHINE_SUCCESS";
export const UPDATE_SERVICE_KIKAN_LIST_REQUEST =
  "UPDATE_SERVICE_KIKAN_LIST_REQUEST";
export const UPDATE_SERVICE_KIKAN_LIST_SUCCESS =
  "UPDATE_SERVICE_KIKAN_LIST_SUCCESS";
export const UPDATE_SERVICE_KIKAN_LIST_FAILURE =
  "UPDATE_SERVICE_KIKAN_LIST_FAILURE";
export const GET_SERVICE_KIKAN_LIST_FAILURE = "GET_SERVICE_KIKAN_LIST_FAILURE";
export const SERVICE_KIKAN_MASTER_LIST_SUCCESSA =
  "SERVICE_KIKAN_MASTER_LIST_SUCCESSA";
