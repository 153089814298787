import { Form } from '@ant-design/compatible';
import React from "react";

// import '@ant-design/compatible/assets/index.css';


import { Button, Input, Modal } from "antd";

class YMForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    visibleConfirm: false,
    isDelConfirm: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let data = {
          id: values.id ? values.id : null,
          listName: values.listName ? values.listName : null,
          naiyou: values.naiyou ? values.naiyou : null,
        };

        this.props.regData(data);
      }
    });
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.props.form.isFieldsTouched()) this.showGoBackConfirm();
    else this.props.history.goBack();
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.goBack();
  }

  delete = () => {
    this.setState({
      isDelConfirm: true,
    });
  };

  closeDelConfirm = () => {
    this.setState({
      isDelConfirm: false,
    });
  };

  okDelConfirm = () => {
    const { data } = this.props;
    this.setState({
      isDelConfirm: false,
    });

    this.props.delete(data.id);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      (<div>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          labelAlign="left"
          style={{ maxWidth: 680, margin: "0 auto" }}
        >
          <Form.Item label="ID（自動生成）">
            {getFieldDecorator("id", {
              rules: [{ required: false }],
            })(<Input disabled />)}
          </Form.Item>

          <Form.Item label="リスト名">
            {getFieldDecorator("listName", {
              rules: [
                {
                  required: true,
                  message: "リスト名は必須です。",
                  whitespace: true,
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="用語内容">
            {getFieldDecorator("naiyou", {
              rules: [
                {
                  required: true,
                  message: "用語内容は必須です。",
                  whitespace: true,
                },
              ],
            })(<Input.TextArea autoComplete="new-name" rows={4} />)}
          </Form.Item>
          {data && data.id ? (
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" danger onClick={this.delete}>
                削除
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                更新
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          ) : (
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                新規登録
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          )}
        </Form>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
        <Modal
          open={this.state.isDelConfirm}
          title="削除確認"
          onCancel={() => this.closeDelConfirm()}
          onOk={() => this.okDelConfirm()}
        >
          <span style={{ display: "block" }}>
            {"削除してもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }
}

const WrappedYMForm = Form.create({
  name: "kanri-user-form",
  onValuesChange(props, changedValues, allValues) { },
  mapPropsToFields(props) {
    return {
      id: Form.createFormField({
        value: props.data ? props.data.id : null,
      }),
      listName: Form.createFormField({
        value: props.data ? props.data.listName : null,
      }),
      naiyou: Form.createFormField({
        value: props.data ? props.data.naiyou : null,
      }),
    };
  },
})(YMForm);

export default WrappedYMForm;
