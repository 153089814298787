/* eslint-disable indent */
import { ApiPaths } from "../../../constants";
import { RequestUtils } from "../../../utils";
import { TableListActionTypes } from "../action_types";


function getTableList(info) {
    return async (dispatch) => {
        try {
            const query = { info };

            const data = await RequestUtils.callApi({
                method: "get",
                query,
                path: ApiPaths.KanriUrl.GetTableList,
            });

            dispatch({
                type: TableListActionTypes.GET_TABLE_LIST_KANRI,
                payload: data,
            });
        }
        catch (error) {
            dispatch({
                type: TableListActionTypes.TABLE_KANRI_ERROR_REQUEST,
                error,
            });
        }
    };
}

function getTableDetail(xmldoc, name) {
    return async (dispatch) => {
        try {
            const query = { xmldoc, name };

            const data = await RequestUtils.callApi({
                method: "get",
                query,
                path: ApiPaths.KanriUrl.GetTableDetail,
            });

            dispatch({
                type: TableListActionTypes.GET_TABLE_DETAIL_KANRI,
                payload: data,
            });
        }
        catch (error) {
            dispatch({
                type: TableListActionTypes.TABLE_KANRI_ERROR_REQUEST,
                error,
            });
        }
    };
}

function getTableRecord(xmldoc, name, filters, sorter, pagination) {
    let strSort = "";
    if (sorter) {
        strSort += sorter.key1 && sorter.key1 !== "0" ? `${sorter.key1} ${sorter.value1} ` : "";
        strSort += sorter.key2 && sorter.key2 !== "0" ? `${sorter.key2} ${sorter.value2} ` : "";
        strSort += sorter.key3 && sorter.key3 !== "0" ? `${sorter.key3} ${sorter.value3} ` : "";
    }

    const page = pagination.current;
    const pageSize = pagination.pageSize;

    return async (dispatch) => {
        try {
            dispatch({
                type: TableListActionTypes.GET_TABLE_LIST_REQUEST,
            });

            const fiterJson = JSON.stringify(filters);
            const query = { xmldoc, name, fiterJson, strSort, page, pageSize };

            const data = await RequestUtils.callApi({
                method: "get",
                query,
                path: ApiPaths.KanriUrl.GetTableRecord,
            });

            dispatch({
                type: TableListActionTypes.GET_TABLE_RECORD_KANRI,
                payload: data,
            });
        }
        catch (error) {
            dispatch({
                type: TableListActionTypes.TABLE_KANRI_ERROR_REQUEST,
                error,
            });
        }
    };
}

export const TableListKanriActions = {
    getTableList,
    getTableDetail,
    getTableRecord,
};