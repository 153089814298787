import "whatwg-fetch";
import axios from "axios";
// import { fileSaver } from 'fileSaver'

import { apiUrls } from "../common/configs/common-url";

var FileSaver = require("file-saver");

const DownloadApi = {
  download(fileId, fileName) {
    return axios(
      apiUrls.INIT +
        apiUrls.DOWNLOAD_FILE_KIROKU +
        "?fileManagementId=" +
        fileId,
      {
        method: "GET",
        responseType: "blob", // important
      }
    )
      .then((response) => {
        FileSaver(response.data, fileName);
      })
      .catch((err) => {
        // if (!err.response) {
        //   // network error
        //   window.location.reload();
        // } else {
        //   return err;
        // }

        alert("ファイルが見つけませんでした。");
      });
  },
};

const loadFileUrl = {
  loadFile(params) {
    return axios
      .get(apiUrls.LOAD_FILE, {
        responseType: "blob",
        params: {
          fileManagementId: params.id,
        },
      })
      .then((res) => res.data);
  },

  loadFilePreview(params) {
    return axios
      .get(apiUrls.LOAD_FILE_PREVIEW, {
        responseType: "blob",
        params: {
          fileManagementId: params.fileManagementId,
        },
      })
      .then((res) => res.data);
  },
};

export { DownloadApi, loadFileUrl };
