import { Form } from "@ant-design/compatible";
import { MinusSquareOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, Modal, Row, Spin } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import {
  keepValueMonitoring,
  resetStatus,
} from "../../../actions/monitoringActions/actionCreators";
import TantoInput from "../../../common/component/control/TantoInput";
import { ConstSet } from "../../../common/configs/constset";
import { getValueLocalstorage } from "../../../common/function_common/functionCommon";
import CareTermDictModal from "../UI/CareTermDictModal";
import MonitoringModal from "../UI/MonitoringModal";
import TangoKaigiModal from "../UI/TangoKaigiModal";
import { ModeSwitch } from "./UI/ModeSwitch";
import ProgressRecord from "./UI/ProgressRecord";
import EditTable from "./UI/editTable";
import EditTable2 from "./UI/editTable2";
dayjs.extend(dayjsPluginUTC);

class MonitoringInputForm extends Component {
  constructor(props) {
    super(props);
    const { monitoringId } = this.props.match.params;
    this.state = {
      careTermVisible: false,
      screenMode: "1",
      checkView: false,
      visibleProgressRecord: false,
      tantoKaigiVisible: false,
      monitoringVisible: false,
      visibleConfirm: false,
      monitoringId,
      isChangedData: false,
    };
  }

  keepValueMonitoring() {
    this.props.keepValueMonitoring({
      monitoringDate: dayjs(
        this.props.form.getFieldValue("monitoring_date"),
        ConstSet.DATE_FORMAT
      ).format(ConstSet.DATE_FORMAT),
      tantoId: this.props.form.getFieldValue("tanto_id"),
      comment: this.props.form.getFieldValue("comment"),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.form.isFieldsTouched()) {
      this.props.keepValueMonitoring({
        monitoringDate: this.props.form.getFieldValue("monitoring_date"),
        tantoId: this.props.form.getFieldValue("tanto_id"),
        comment: this.props.form.getFieldValue("comment"),
      });
    }

    if (
      this.state.checkView == false &&
      this.state.monitoringId == "new" &&
      nextProps.status !== this.props.status &&
      nextProps.status == "success"
    ) {
      this.setState({
        checkView: false,
        isFieldsTouched: false,
      });
      this.props.history.push(
        `/input-monitoring/${nextProps.data.riyousyaId}/${nextProps.data.id}`
      );
    }
    if (
      this.state.checkView == true &&
      this.state.monitoringId == "new" &&
      nextProps.status !== this.props.status &&
      nextProps.status == "success"
    ) {
      this.setState({
        checkView: false,
        isFieldsTouched: false,
      });
      this.props.history.push(
        `/input-monitoring/${nextProps.data.riyousyaId}/${nextProps.data.id}`
      );
      //   let win = window.open(`/check-info-document-list/${nextProps.data.riyousyaId}/${nextProps.data.id}`, '_blank');
      //   if (win !== null) {
      //     win.focus();
      //   }
    }
  }
  componentDidUpdate(prevProps) {
    const { status } = this.props;
    const { monitoringId } = this.props.match.params;
    const { monitoringId: prevMonitoringId } = prevProps.match.params;

    if (monitoringId !== prevMonitoringId) {
      this.setState({ monitoringId });
    }

    if (status !== null) {
      this.props.resetStatus();
    }
  }
  showModalCareTerm(field, linkingCareTerm) {
    this.setState({
      careTermVisible: true,
      field,
      linkingCareTerm,
    });
  }
  handleSelectCareTerm(careTerm) {
    if (careTerm) {
      const { field } = this.state;
      const preValue = this.props.form.getFieldValue(field);
      const value = {};
      value[field] = _.isEmpty(preValue)
        ? careTerm
        : preValue + "、" + careTerm;
      this.props.form.setFieldsValue(value);
      this.keepValueMonitoring();
    }
    this.setState({
      careTermVisible: false,
      isFieldsTouched: !!careTerm,
      isChangedData: true,
    });
  }

  hideModalCareTerm() {
    this.setState({
      careTermVisible: false,
    });
  }
  onOkQuoteComment(value) {
    if (value) {
      const field = "comment";
      const preValue = this.props.form.getFieldValue(field);
      const valueNew = {};
      valueNew[field] =
        _.isEmpty(preValue) || preValue == null
          ? value
          : preValue + "、" + value;
      this.props.form.setFieldsValue(valueNew);
      this.keepValueMonitoring();
      this.setState({ isChangedData: true });
    }
  }
  handleGoBack = async (e) => {
    e.preventDefault();

    if (this.props.form.isFieldsTouched() || this.state.isChangedData) {
      this.setState({ visibleConfirm: true });
    }
    else {
      this.props.history.push(`/document-list/${this.props.riyousyaId}`);
    }
  };
  handleSave = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      this.props.regData(err, values);
      this.setState({ isChangedData: false });
    });
  };
  handleChangeMode(mode) {
    this.setState({ screenMode: mode });
  }
  hideModalProgressRecord() {
    this.setState({
      visibleProgressRecord: false,
    });
  }
  handleSaveAndNewTab = (e) => {
    e.preventDefault();
    this.setState({
      checkView: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      this.props.regDataAndCheck(err, values);
      this.setState({ isChangedData: false });
    });
  };

  onChangeData = () => {
    this.setState({ isChangedData: true });
  };

  renderCarePlanDate() {
    const { data, monitoringCarePlanDate } = this.props; // monitoringCarePlanDate is the date copy from kyotaku_plan2.plan_date

    if (data && data.carePlanDate) {
      return dayjs
        .utc(data.carePlanDate, ConstSet.DATE_FORMAT)
        .format("YYYY-MM-DD");
    }
    else if (monitoringCarePlanDate) {
      return dayjs
        .utc(monitoringCarePlanDate, ConstSet.DATE_FORMAT)
        .format("YYYY-MM-DD");
    }
    return "";
  }

  renderHeader() {
    return (
      <Row className={"submenu fixed-header"} style={{ display: "flex" }}>
        <Col span={8} className={"sm-c-left"}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【モニタリング】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
        <Col span={8} style={{ paddingTop: 10, textAlign: "center" }}>
          <ModeSwitch
            style={{ display: "inline" }}
            mode={this.state.screenMode}
            handleChangeMode={(mode) => this.handleChangeMode(mode)}
          ></ModeSwitch>
        </Col>
        <Col span={8} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <Link to={"/"} onClick={this.handleSave}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{ display: "block", fontWeight: "bold", color: "red" }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </Link>
          {this.state.monitoringId !== "new" ? (
            <Link to={"/"} onClick={this.handleSaveAndNewTab}>
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          ) : null}
        </Col>
      </Row>
    );
  }

  renderHeaderMobile() {
    return (
      <Row className={"submenu fixed-header"} style={{ display: "flex" }}>
        <Col span={24} className={"sm-c-left"} style={{ color: "white" }}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【モニタリング】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
      </Row>
    );
  }

  renderDesktopContent() {
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.props;
    const monitoringDate = this.getMonitoringDate();

    return (
      <Spin spinning={loading ? loading : false}>
        {this.renderHeader()}
        <div style={{ maxWidth: "1081px", margin: "0px auto" }} className="main">
          <Form
            layout="inline"
            hideRequiredMark={true}
            style={{ padding: "0 10px" }}
          >
            <Row style={{ paddingBottom: "10px" }}>
              <Col span={6}>
                <Form.Item label={"日付"} colon={false}>
                  {getFieldDecorator("monitoring_date")(
                    <DatePicker style={{ width: "120px" }} />
                  )}
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label={"ケアプラン作成日"} colon={false}>
                  <span style={{ borderBottom: "1px solid" }}>
                    {this.renderCarePlanDate()}
                  </span>
                </Form.Item>
              </Col>
              <Col span={13}>
                <Form.Item label={"モニタリング作成者"} colon={false}>
                  {getFieldDecorator("tanto_id")(
                    <TantoInput
                      allowBlank
                      containerId={"input-monitoring-page"}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row style={{ marginTop: "10px", padding: "0 10px" }}>
            <Col sm={24} xs={24} md={1}>
              <Form.Item
                label={"総評"}
                colon={false}
                style={{ width: "100%" }}
                className="item-comment"
              />
            </Col>
            <Col sm={24} xs={24} md={19}>
              <div
                label={"総評"}
                colon={false}
                style={{ width: "100%" }}
                className="item-comment"
              >
                {getFieldDecorator("comment")(
                  <Input.TextArea style={{ width: "100%" }} rows={5} />
                )}
              </div>
            </Col>
            <Col sm={24} xs={24} md={{ span: 3, offset: 1 }}>
              <Button
                size={"small"}
                onClick={() => this.showModalCareTerm("comment", "総評")}
                type={"primary"}
                style={{ marginLeft: 1, marginBottom: 4, width: "100%" }}
              >
                用語
              </Button>
              <ProgressRecord
                visible={this.state.visibleProgressRecord}
                riyousyaId={this.props.riyousyaId}
                onOkQuoteComment={(value) => this.onOkQuoteComment(value)}
                onHideProgessRecord={() => this.hideModalProgressRecord()}
                monitoringDate={monitoringDate}
              ></ProgressRecord>
              <Button
                onClick={() => this.showTantoKaigiModal()}
                size={"small"}
                type={"primary"}
                style={{ marginLeft: 1, marginBottom: 4, width: "100%" }}
              >
                担当者会議
              </Button>
              <Button
                onClick={() => this.showMonitoringModal()}
                size={"small"}
                type={"primary"}
                style={{ marginLeft: 1, marginBottom: 4, width: "100%" }}
              >
                モニタリング
              </Button>
            </Col>
          </Row>
          {this.state.screenMode == "1" ? (
            <div style={{ paddingBottom: "10px", padding: "0 10px", margin: "16px 0" }}>
              <EditTable
                //   data={this.props.data}
                history={this.props.history}
                riyousyaId={this.props.riyousyaId}
                monitoringId={this.state.monitoringId}
                monitoringDate={monitoringDate}
                onChangeData={this.onChangeData}
              />
            </div>
          ) : (
            <div style={{ paddingBottom: "10px", padding: "0 10px" }}>
              <EditTable2
                //   data={this.props.data}
                history={this.props.history}
                riyousyaId={this.props.riyousyaId}
                monitoringId={this.state.monitoringId}
                onChangeData={this.onChangeData}
              />
            </div>
          )}
        </div>
      </Spin>
    );
  }

  renderMobileContent() {
    const { getFieldDecorator } = this.props.form;
    const monitoringDate = this.getMonitoringDate();

    return (
      <div>
        {this.renderHeaderMobile()}
        <div style={{ margin: "20px auto" }}>
          <Spin spinning={this.props.loading}>
            <Form
              layout="inline"
              hideRequiredMark={true}
              style={{ padding: "0 10px" }}
            >
              {/* Switch mode */}
              <Row>
                <ModeSwitch
                  style={{ display: "block", textAlign: "center" }}
                  mode={this.state.screenMode}
                  handleChangeMode={(mode) => this.handleChangeMode(mode)}
                />
              </Row>

              {/* Monitoring date */}
              <Row style={{ marginTop: 20, paddingBottom: 10 }}>
                <Form.Item label={"日付"} colon={false}>
                  {getFieldDecorator("monitoring_date")(
                    <DatePicker style={{ width: "120px" }} />
                  )}
                </Form.Item>
              </Row>

              {/* Care plan date */}
              <Row style={{ paddingBottom: 10 }}>
                <Form.Item label={"ケアプラン作成日"} colon={false}>
                  <span style={{ borderBottom: "1px solid" }}>
                    {this.renderCarePlanDate()}
                  </span>
                </Form.Item>
              </Row>

              {/* Tanto */}
              <Row style={{ paddingBottom: 10 }}>
                <Form.Item label={"モニタリング作成者"} colon={false}>
                  {getFieldDecorator("tanto_id")(
                    <TantoInput
                      allowBlank
                      containerId={"input-monitoring-page"}
                    />
                  )}
                </Form.Item>
              </Row>

              {/* Comment */}
              <Row style={{ paddingBottom: 30 }}>
                <Col span={24}>
                  <Form.Item
                    label={"総評"}
                    className="item-comment monitoring-comment"
                  >
                    {getFieldDecorator("comment")(
                      <Input.TextArea style={{ width: "100%" }} rows={4} />
                    )}
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    size="small"
                    onClick={() => this.showModalCareTerm("comment", "総評")}
                    type="primary"
                  >
                    用語
                  </Button>
                  <ProgressRecord
                    visible={this.state.visibleProgressRecord}
                    riyousyaId={this.props.riyousyaId}
                    onOkQuoteComment={(value) => this.onOkQuoteComment(value)}
                    onHideProgessRecord={() => this.hideModalProgressRecord()}
                    monitoringDate={monitoringDate}
                  ></ProgressRecord>
                  <Button
                    onClick={() => this.showTantoKaigiModal()}
                    size="small"
                    type="primary"
                  >
                    担当者会議
                  </Button>
                  <Button
                    onClick={() => this.showMonitoringModal()}
                    size="small"
                    type="primary"
                  >
                    モニタリング
                  </Button>
                </Col>
              </Row>

              {/* Switch layout content*/}
              <Row>
                {this.state.screenMode == "1" ? (
                  <EditTable
                    // data={this.props.data}
                    history={this.props.history}
                    riyousyaId={this.props.riyousyaId}
                    monitoringId={this.state.monitoringId}
                    monitoringDate={monitoringDate}
                    mode="mobile"
                    onChangeData={this.onChangeData}
                  />
                ) : (
                  <EditTable2
                    // data={this.props.data}
                    history={this.props.history}
                    riyousyaId={this.props.riyousyaId}
                    monitoringId={this.state.monitoringId}
                    mode="mobile"
                    onChangeData={this.onChangeData}
                  />
                )}
              </Row>
            </Form>
          </Spin>
        </div>
        {this.renderFooterMobile()}
      </div>
    );
  }

  renderFooterMobile() {
    return (
      <Row className={"submenu fixed-footer"}>
        <Col span={24} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <Link to={"/"} onClick={this.handleSave}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{
                  display: "block",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </Link>
          {this.state.monitoringId !== "new" ? (
            <Link to={"/"} id={"checkBtn"} onClick={this.handleSaveAndNewTab}>
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          ) : null}
        </Col>
      </Row>
    );
  }

  getMonitoringDate() {
    if (this.state.monitoringId === "new") {
      const date = this.props.form.getFieldValue("monitoring_date");
      return dayjs(date || new Date(), ConstSet.DATE_FORMAT).format(
        ConstSet.DATE_FORMAT
      );
    }
    return this.props.data && this.props.data.monitoringDate;
  }

  render() {
    const monitoringDate = this.getMonitoringDate();

    return (
      (<div className="back">
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) =>
            matches ? this.renderDesktopContent() : this.renderMobileContent()
          }
        </MediaQuery>
        <CareTermDictModal
          visible={this.state.careTermVisible}
          linkingCareTerm={this.state.linkingCareTerm}
          handleSelectCareTerm={(data) => this.handleSelectCareTerm(data)}
          hideModalCareTerm={() => this.hideModalCareTerm()}
        />
        <TangoKaigiModal
          visible={this.state.tantoKaigiVisible}
          riyousyaId={this.props.riyousyaId}
          onSelectItem={(data) => this.onOkQuoteComment(data)}
          onHide={() => this.hideTantoKaigiModal()}
          monitoringDate={monitoringDate}
        />
        <MonitoringModal
          visible={this.state.monitoringVisible}
          riyousyaId={this.props.riyousyaId}
          onSelectItem={(data) => this.onOkQuoteComment(data)}
          onHide={() => this.hideMonitoringModal()}
          monitoringDate={monitoringDate}
        />
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }

  showTantoKaigiModal() {
    this.setState({ tantoKaigiVisible: true });
  }
  hideTantoKaigiModal() {
    this.setState({ tantoKaigiVisible: false });
  }
  showMonitoringModal() {
    this.setState({ monitoringVisible: true });
  }
  hideMonitoringModal() {
    this.setState({ monitoringVisible: false });
  }
  closeGoBackConfirm() {
    this.setState({ visibleConfirm: false });
  }
  handleConfirmGoBack() {
    this.props.history.push(`/document-list/${this.props.riyousyaId}`);
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.monitoring.status,
    loading: state.monitoring.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetStatus: () => dispatch(resetStatus()),
    keepValueMonitoring: (args) => dispatch(keepValueMonitoring(args)),
  };
};

const MonitoringInputFormWrapper = Form.create({
  name: "input-monitoring",
  mapPropsToFields(props) {
    return {
      monitoring_date: Form.createFormField({
        value:
          props.data && props.data.monitoringDate
            ? dayjs.utc(props.data.monitoringDate, ConstSet.DATE_FORMAT)
            : dayjs.utc(new Date(), ConstSet.DATE_FORMAT),
      }),
      tanto_id: Form.createFormField({
        value:
          props.match.params.monitoringId === "new"
            ? parseInt(getValueLocalstorage("tid"))
            : props.data && props.data.tantoId,
      }),
      comment: Form.createFormField({
        value: props.data ? props.data.comment : null,
      }),
    };
  },
})(connect(mapStateToProps, mapDispatchToProps)(MonitoringInputForm));

export default MonitoringInputFormWrapper;
