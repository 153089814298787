import { notification } from "antd";
import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";

import { getPath } from "../../common/function_common/functionCommon";
import ActionsRow from "./UI/ActionsRow";
import WrappedYMForm from "./UI/YMForm";

class KanriYougoMasterSave extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      ymId: parseInt(match.params.ymId),
      path: getPath(),
    };

    this.registerYM = this.registerYM.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    // load data
    this.props.getYMList();
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  async registerYM(values) {
    if (values.id) {
      await this.props.updateYM(values);
    }
    else {
      await this.props.createYM(values);
    }
    this.props.history.goBack();
  }

  async delete(id) {
    await this.props.deleteYM(id.toString());

    this.props.history.goBack();
  }

  preToEnd = () => {
    const { kanriYMList } = this.props;
    const { ymId, path } = this.state;

    if (kanriYMList && kanriYMList.length > 0) {
      const index = kanriYMList.findIndex((item) => item.id === ymId);

      if (index >= 1) {
        this.setState({
          ymId: kanriYMList[0].id,
        });

        this.props.history.replace(
          path + "/kanri/yougomaster/" + kanriYMList[0].id
        );
      }
    }
  };

  pre = () => {
    const { kanriYMList } = this.props;
    const { ymId, path } = this.state;

    if (kanriYMList && kanriYMList.length > 0) {
      const index = kanriYMList.findIndex((item) => item.id === ymId);

      if (index >= 1) {
        this.setState({
          ymId: kanriYMList[index - 1].id,
        });

        this.props.history.replace(
          path + "/kanri/yougomaster/" + kanriYMList[index - 1].id
        );
      }
    }
  };

  next = () => {
    const { kanriYMList } = this.props;
    const { ymId, path } = this.state;

    if (kanriYMList && kanriYMList.length > 0) {
      const index = kanriYMList.findIndex((item) => item.id === ymId);

      if (index >= 0 && index < kanriYMList.length - 1) {
        this.setState({
          ymId: kanriYMList[index + 1].id,
        });

        this.props.history.replace(
          path + "/kanri/yougomaster/" + kanriYMList[index + 1].id
        );
      }
    }
  };

  nextToEnd = () => {
    const { kanriYMList } = this.props;
    const { ymId, path } = this.state;

    if (kanriYMList && kanriYMList.length > 0) {
      const index = kanriYMList.findIndex((item) => item.id === ymId);

      if (index !== kanriYMList.length) {
        this.setState({
          ymId: kanriYMList[kanriYMList.length - 1].id,
        });

        this.props.history.replace(
          path + "/kanri/yougomaster/" + kanriYMList[kanriYMList.length - 1].id
        );
      }
    }
  };

  recordMark = (type, id) => {
    this.props.recordMark("ym", type, id);
  };

  render() {
    const { kanriYMList, ymSelectedRows } = this.props;
    const { ymId } = this.state;

    return (
      <KanriLayoutContainer router="yougomaster" breadcrumbLink={"/kanri/yougomaster"} breadcrumbText={"用語マスタ"}>
        <ActionsRow
          key={ymSelectedRows}
          preToEnd={this.preToEnd}
          pre={this.pre}
          next={this.next}
          nextToEnd={this.nextToEnd}
          recordMark={this.recordMark}
          selectedList={ymSelectedRows}
          id={ymId}
        />

        <WrappedYMForm
          data={kanriYMList.find((item) => item.id === ymId)}
          history={this.props.history}
          regData={(values) => this.registerYM(values)}
          delete={this.delete}
        />
      </KanriLayoutContainer>
    );
  }
}

export default KanriYougoMasterSave;
