import React, { Component} from "react";
import { SketchField, Tools, Icons } from "./lib";
import { Row } from "antd";

import "./lib/style/style.css";

// export const PaintView = React.memo(
//   class extends React.Component {
//     // constructor(props) {
//     //     super(props);
//     // }
//     componentDidMount() {
//       this.props.onRef(this);
//     }
//     componentWillUnmount() {
//       this.props.onRef(null);
//     }
//     zoomPainview = (value, isScale = false) => {
//       this._sketch.zoom(value, isScale);
//     }
//     render() {
//       return (
//         <Row style={this.props.style ? this.props.style : { pointerEvents: "none", }} className={this.props.format}>
//           <SketchField
//             width={this.props.width ? this.props.width : 300}
//             height={this.props.height ? this.props.height : 150}
//             readOnly={true}
//             tool={Tools.None}
//             ref={c => (this._sketch = c)}
//             value={this?.props?.value ? this.props.value : null}
//             forceValue={true}
//             lineColor='black'
//             lineWidth={3}
//             // style={this.props.style}
//           />
//         </Row>
//       );
//     }
//   }
// )

export class PaintView extends Component {
  // constructor(props) {
  //     super(props);
  // }
  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }
  zoomPainview = (value, isScale = false) => {
    this._sketch.zoom(value, isScale);
  }
  render() {
    return (
      <Row style={this.props.style ? this.props.style : { pointerEvents: "none", }} className={this.props.format}>
        <SketchField
          width={this.props.width ? this.props.width : 300}
          height={this.props.height ? this.props.height : 150}
          readOnly={true}
          tool={Tools.None}
          ref={c => (this._sketch = c)}
          value={this?.props?.value ? this.props.value : null}
          forceValue={true}
          lineColor='black'
          lineWidth={3}
          // style={this.props.style}
        />
      </Row>
    );
  }
}