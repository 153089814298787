import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "antd";
import TimeKBCus from "../../components/WeeklyPlan/TimeKBCus";
import AddTime from "../../components/WeeklyPlan/AddTime";
function TimeModal(props) {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [isErrorTime, setIsErrorTime] = useState();

  useEffect(() => {
    if (props.recordSelected) {
      setStartTime(props.recordSelected.startTime);
      setEndTime(props.recordSelected.endTime);
    }
  }, [props.recordSelected]);

  useEffect(() => {
    const minutesOfStartTime = startTime
      ? parseInt(startTime.substring(0, 2)) * 60 +
        parseInt(startTime.substring(3))
      : 0;
    const minutesOfEndTime = endTime
      ? parseInt(endTime.substring(0, 2)) * 60 + parseInt(endTime.substring(3))
      : 0;
    if (minutesOfStartTime >= minutesOfEndTime) {
      setIsErrorTime(true);
    } else {
      setIsErrorTime(false);
    }
  });
  function handleOnClickTime(value) {
    const timeAddNumber = parseInt(value);
    let totalminutes;
    let result;
    if (startTime) {
      totalminutes =
        parseInt(startTime.substring(0, 2)) * 60 +
        parseInt(startTime.substring(3)) +
        timeAddNumber;
    } else {
      totalminutes = timeAddNumber;
    }
    const hours =
      totalminutes / 60 >= 10
        ? "" + (parseInt(totalminutes / 60) % 24)
        : "0" + parseInt(totalminutes / 60);
    const minutes =
      totalminutes % 60 >= 10
        ? "" + parseInt(totalminutes % 60)
        : "0" + parseInt(totalminutes % 60);
    result = hours + ":" + minutes;
    setEndTime(result);
  }

  function handleChangeStartTime(value) {
    setStartTime(value);
  }

  function handleChangeEndTime(value) {
    setEndTime(value);
  }

  function handleCancel() {
    props.onCancel();
  }

  function handleOk() {
    props.onOk(props.recordSelected, startTime + ":00", endTime + ":00");
  }

  return (
    (<Modal
      open={props.visible}
      title={props.title}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={handleCancel}
        >
          キャンセル
        </Button>,
        <Button
          key="back"
          type="primary"
          onClick={handleOk}
          disabled={isErrorTime}
        >
          OK
        </Button>,
      ]}
      width={450}
    >
      <Row style={{ marginTop: 10, display: "flex", alignItems: "center" }}>
        <Col span={4} style={{ marginLeft: -8 }}>
          開始時間
        </Col>
        <Col span={4}>
          <TimeKBCus
            timeString={startTime}
            handleInput={handleChangeStartTime}
          />
        </Col>
        <Col
          offset={3}
          span={13}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: 8 }}>終了時間</div>
          <TimeKBCus timeString={endTime} handleInput={handleChangeEndTime} />
          <AddTime
            timeSetting={props.timeSetting}
            onClickTime={handleOnClickTime}
          />
        </Col>
      </Row>
      {isErrorTime ? (
        <Row style={{ marginTop: 10, color: "red", textAlign: "center" }}>
          {"開始時間 < 終了時間"}
        </Row>
      ) : (
        ""
      )}
    </Modal>)
  );
}

export { TimeModal };
