/* eslint-disable react/prop-types */
import React from "react";
import { UndoOutlined } from '@ant-design/icons';
import { Row, Spin } from "antd";
import _ from "lodash";

import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderRiyousya from "../../common/component/SubHeaderRiyousya";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import { Link } from "react-router-dom";
import {
  getPath,
  getUrlVars,
} from "../../common/function_common/functionCommon";
import { Toolbar1 } from "./UI/Toolbar1";
import { AdlTable } from "./UI/AdlTable";

class AdlIndex extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.match;
    let queryDate = getUrlVars(window.location.href)["dateSelected"];
    if (queryDate) {
      const url = new URL(window.location.href);
      url.searchParams.delete("dateSelected");
      window.history.replaceState(null, null, url.href);
    }

    this.state = {
      path: getPath(),
      riyousyaId: params.riyousyaId,
      riyousayInfo: null,
      page: this.props.page,
      pageSize: 100,
    };
  }

  /**
   * 利用者情報を取得
   * reportAdl(複数)を利用者で検索
   */
  componentDidMount() {
    // Setting page after redering DOM
    this.props.getSettingMasterAdl((data) => {
      if (data != null) {
        const bunruiTmp = data.split(",");
        this.props.getAdlMaster(bunruiTmp, () => {
          // console.log(callback)
          // console.log(typeof(callback));
        });
      }
    });

    this.props.getRiyousyaInfo(this.state.riyousyaId);
    this.props.loadAdl({ riyousyaId: this.state.riyousyaId }, () => {
      //callBack
    });
  }

  /**
   * propsが更新される時のみ実行
   * @param {*} newProps
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.riyousya && newProps.riyousya.id == this.state.riyousyaId) {
      this.setState({
        riyousayInfo: {
          riyousya: newProps.riyousya,
        },
      });
    }
  }

  /**
   * テーブルレコードの情報をreducerに保持
   * @param {*} data {id, riyousyaId}
   */
  keepValue(data) {
    this.props.keepValueSearch(data);
  }

  /**
   * 3S UPDATE
   * 利用者検索
   * @param {*} value riyousyaId
   */
  getReportRiyousya = (value) => {
    if (value) {
      this.props.history.push(this.state.path + "/report-adl/" + value);
      this.setState({
        riyousyaId: value,
      });
      this.props.getRiyousyaInfo(value);
      this.props.loadAdl(
        {
          riyousyaId: value,
        },
        () => {
          //callBack
        }
      );
    }
  };

  /**
   * レコード削除 その後ADLリスト呼び出し
   * @param {} adlId レコードのid
   */
  handleDeleteAdl(adlId) {
    if (adlId) {
      this.props.deleteAdl(adlId, () => {
        // console.log(data)
        this.props.loadAdl({ riyousyaId: this.state.riyousyaId }, () => {
          //callBack
        });
      });
    }
  }

  /**
   * input画面に移動
   * @param {*} data ADL単一のレコード
   */
  handleInputAdl(data) {
    if (data.riyousyaId) {
      const adl = _.find(this.props.adls, (ad) => ad.id === data.id);
      this.setState({
        riyousyaId: data.riyousyaId,
        riyousayInfo: {
          riyousya: {
            id: data.riyousyaId,
            name: data.riyousyaName,
          },
        },
        adl,
      });
      this.props.history.push(
        this.state.path + "/report-adl/" + data.riyousyaId + "/" + data.id
      );
    } else {
      this.setState({
        riyousyaId: data,
        adl: null,
      });
      this.props.history.push(this.state.path + "/report-adl/" + data + "/new");
    }
  }

  // handleChangePage(page, pageSize) {
  //   this.setState({
  //     page: page,
  //     pageSize: pageSize,
  //   })

  //   this.props.loadTantoKaigi({
  //     riyousyaId: this.state.riyousyaId,
  //     page: this.state.sort,
  //     sort: {},
  //   });
  // }

  render() {
    const { riyousyaId } = this.state;
    const { adls, loading, keepValue } = this.props;
    return (
      <CommonPageContainer
        id={"report-case-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <Row className={"submenu"}>
          <SubHeaderV2
            //タイトル
            TopLeft={
              <SubHeaderTitleV2
                title={"ADL記録一覧（利用者）"}
                iconType="setting"
                count={true}
                countNum={adls ? adls.length : 0}
              />
            }
            //右側アイコン(戻る)
            TopRight={
              <Link
                to={"#"}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(`${this.state.path}/riyousya`);
                }}
                className="sub-top-right-item"
              >
                <span>
                  <UndoOutlined />
                </span>
                <span>戻る</span>
              </Link>
            }
            //利用者検索
            IncBottom={
              <SubHeaderRiyousya
                style={{ display: "inline" }}
                reportRiyousya={(value) => this.getReportRiyousya(value)}
                riyousayInfo={this.state.riyousayInfo}
              />
            }
          />
        </Row>
        <Spin spinning={loading}>
          <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
            <Toolbar1
              riyousyaId={riyousyaId}
              handleInputAdl={(data) => this.handleInputAdl(data)}
              history={this.props.history}
              filterConditions={this.props.filterConditions}
            />
          </Row>
          <Row>
            <AdlTable
              data={adls} //adlリストレコード
              riyousyaId={riyousyaId}
              keepValue={keepValue}
              handleInputAdl={(data) => this.handleInputAdl(data)}
              handleDeleteAdl={(adlId) => this.handleDeleteAdl(adlId)}
              keepValuesearch={(data) => this.keepValue(data)}
              history={this.props.history}

              // page={this.props.page}
              // total={this.props.total}
              // handleChangePage={(page, pageSize) => this.handleChangePage(page, pageSize)}
            />
          </Row>
        </Spin>
      </CommonPageContainer>
    );
  }
}

export default AdlIndex;
