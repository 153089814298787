import { KyotakuPlan2Api } from "../../api/kyotakuPlan2";
import { YougoMasterNaiyouApi } from "../../api/yougoMasterNaiyou";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
import { LOAD_SPIN_REQUEST } from "../kyotakuPlan1Actions/actionName";
import {
  ADD_DETAIL_KYOTAKU_PLAN2,
  ADD_ROWS_KYOTAKU_PLAN2,
  CHANGE_EDIT_MOBILE_MODE,
  CHANGE_EDIT_MODE,
  CHANGE_EXPANDED_ROW_KEY,
  CHANGE_KAIGOHOKEN,
  CHANGE_VALUE_DETAIL,
  CLEAR_DETAIL_KYOTAKU_PLAN2,
  CREATE_KYOTAKU_PLAN2_ERROR,
  CREATE_KYOTAKU_PLAN2_SUCCESS,
  DELETE_DETAIL_KYOTAKU_PLAN2,
  DELETE_KYOTAKU_PLAN2_ERROR,
  DELETE_KYOTAKU_PLAN2_SUCCESS,
  DELETE_ROWS_KYOTAKU_PLAN2,
  DOWN_ROWS_KYOTAKU_PLAN2,
  DOWN_ROWS_KYOTAKU_PLAN2_DETAIL,
  GET_DETAIL_KYOTAKU_PLAN2,
  GET_KP2_YOUGO_MASTER_ERROR,
  GET_KP2_YOUGO_MASTER_SUCCESS,
  GET_ROWINFO_KYOTAKU_PLAN2,
  GET_ROWS_KYOTAKU_PLAN2,
  KEEP_VALUE1,
  LOAD_KYOTAKU_PLAN2_BY_ID_ERROR,
  LOAD_KYOTAKU_PLAN2_BY_ID_SUCCESS,
  LOAD_KYOTAKU_PLAN2_ERROR,
  LOAD_KYOTAKU_PLAN2_SUCCESS,
  LOAD_KYOTAKU_PLAN2_TO_QUOTE_ERROR,
  LOAD_KYOTAKU_PLAN2_TO_QUOTE_SUCCESS,
  RESET_STATUS,
  SAVE_KP2_YOUGO_MASTER_ERROR,
  SAVE_KP2_YOUGO_MASTER_SUCCESS,
  SETTING_MASTER_PLAN2,
  SETTING_MASTER_PLAN2_ERROR,
  SET_ROW_KYOTAKU_PLAN2,
  UPDATE_KIKAN_KYOTAKU_PLAN2,
  UPDATE_KYOTAKU_PLAN2_ERROR,
  UPDATE_KYOTAKU_PLAN2_SUCCESS,
  UPDATE_ON_CHANGE_KYOTAKU_PLAN2,
  UPDATE_ROWS_KYOTAKU_PLAN2,
  UPDATE_SINGLE_KIKAN_KYOTAKU_PLAN2,
  UP_ROWS_KYOTAKU_PLAN2,
  UP_ROWS_KYOTAKU_PLAN2_DETAIL
} from "./actionName";
export function changeKaigohoken(value) {
  return {
    type: CHANGE_KAIGOHOKEN,
    kaigohoken: value,
  };
}
export function loadKyotakuPlan2(data, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KyotakuPlan2Api.loadKyotakuPlan2(data).then(
      (data) => {
        dispatch({
          type: LOAD_KYOTAKU_PLAN2_SUCCESS,
          kyotakuPlan2s: data.data,
          meta: data.meta,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KYOTAKU_PLAN2_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
export function getKyotakuPlan2(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    if (id === "new" || id === 0) {
      return dispatch({
        type: LOAD_KYOTAKU_PLAN2_BY_ID_SUCCESS,
        kyotakuPlan2: null,
      });
    }
    KyotakuPlan2Api.getKyotakuPlan2ById(id).then(
      (data) =>
        dispatch({
          type: LOAD_KYOTAKU_PLAN2_BY_ID_SUCCESS,
          kyotakuPlan2: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KYOTAKU_PLAN2_BY_ID_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getKyotakuPlan2ToQuote(riyousyaId, kyotakuPlan2Id) {
  return (dispatch) => {
    KyotakuPlan2Api.getKyotakuPlan2ToQuote(riyousyaId, kyotakuPlan2Id).then(
      (data) =>
        dispatch({
          type: LOAD_KYOTAKU_PLAN2_TO_QUOTE_SUCCESS,
          kyotakuPlan2s: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: LOAD_KYOTAKU_PLAN2_TO_QUOTE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function createKyotakuPlan2(params, riyousyaId, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KyotakuPlan2Api.createKyotakuPlan2(params, riyousyaId).then(
      (data) =>
        dispatch({
          type: CREATE_KYOTAKU_PLAN2_SUCCESS,
          createdKyotakuPlan2: data,
          callback,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: CREATE_KYOTAKU_PLAN2_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function updateKyotakuPlan2(params, id, callback) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KyotakuPlan2Api.updateKyotakuPlan2(params, id).then(
      (data) =>
        dispatch({
          type: UPDATE_KYOTAKU_PLAN2_SUCCESS,
          updatedKyotakuPlan2: data,
          callback,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: UPDATE_KYOTAKU_PLAN2_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function deleteKyotakuPlan2(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SPIN_REQUEST,
    });
    KyotakuPlan2Api.deleteKyotakuPlan2(id).then(
      (data) =>
        dispatch({
          type: DELETE_KYOTAKU_PLAN2_SUCCESS,
          deletedKyotakuPlan2: data,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: DELETE_KYOTAKU_PLAN2_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function deleteRowsKyotakuPlan2(sortNum) {
  return {
    type: DELETE_ROWS_KYOTAKU_PLAN2,
    sortNum,
  };
}

export function addRowsKyotakuPlan2(expandedRowKeys, rowData, data) {
  return {
    type: ADD_ROWS_KYOTAKU_PLAN2,
    expandedRowKeys,
    rowData,
    data,
  };
}

export function getDetailKyotakuPlan2(data) {
  return {
    type: GET_DETAIL_KYOTAKU_PLAN2,
    details: data,
  };
}

export function clearDetailKyotakuPlan2(data) {
  return {
    type: CLEAR_DETAIL_KYOTAKU_PLAN2,
    details: data,
  };
}

export function addDetailKyotakuPlan2(expandedRowKeys, recordInfo) {
  return {
    type: ADD_DETAIL_KYOTAKU_PLAN2,
    expandedRowKeys,
    recordInfo
  };
}

export function deleteDetailKyotakuPlan2(sortNum) {
  return {
    type: DELETE_DETAIL_KYOTAKU_PLAN2,
    sortNum,
  };
}

export function getRowInfolKyotakuPlan2(data) {
  return {
    type: GET_ROWINFO_KYOTAKU_PLAN2,
    rowInfo: data,
  };
}

export function changeExpandedRowKey(key) {
  return {
    type: CHANGE_EXPANDED_ROW_KEY,
    key,
  };
}

export function upRowKyotakuPlan2(sortNum) {
  return {
    type: UP_ROWS_KYOTAKU_PLAN2,
    sortNum,
  };
}

export function upRowKyotakuPlan2Detail(sortNum, expandedRowKeys) {
  return {
    type: UP_ROWS_KYOTAKU_PLAN2_DETAIL,
    sortNum,
    expandedRowKeys,
  };
}

export function updateOnChange(values) {
  return {
    type: UPDATE_ON_CHANGE_KYOTAKU_PLAN2,
    values,
  };
}

export function downRowKyotakuPlan2(sortNum) {
  return {
    type: DOWN_ROWS_KYOTAKU_PLAN2,
    sortNum,
  };
}

export function downRowKyotakuPlan2Detail(sortNum, expandedRowKeys) {
  return {
    type: DOWN_ROWS_KYOTAKU_PLAN2_DETAIL,
    sortNum,
    expandedRowKeys,
  };
}

export function updateRowKyotakuPlan2(sortNum, data) {
  return {
    type: UPDATE_ROWS_KYOTAKU_PLAN2,
    data,
    sortNum,
  };
}

export function updateKikanKyotakuPlan2(longKikan, shortKikan, kikan, values) {
  return {
    type: UPDATE_KIKAN_KYOTAKU_PLAN2,
    longKikan,
    shortKikan,
    kikan,
    values,
  };
}

export function updateSingleKikanKyotakuPlan2(values, kikanType, indexDetail) {
  return {
    type: UPDATE_SINGLE_KIKAN_KYOTAKU_PLAN2,
    values,
    kikanType,
    indexDetail,
  };
}

export function getRowKyotakuPlan2(data) {
  return {
    type: GET_ROWS_KYOTAKU_PLAN2,
    data,
  };
}

export function changeEditMode(mode) {
  return {
    type: CHANGE_EDIT_MODE,
    mode,
  };
}

export function getKyotakuPlan2YougoMaster(listName) {
  return (dispatch) => {
    // dispatch({
    //   type: LOAD_SPIN_REQUEST_PLAN2
    // });
    YougoMasterNaiyouApi.yougoMasterNaiyou(listName).then(
      (data) =>
        dispatch({
          type: GET_KP2_YOUGO_MASTER_SUCCESS,
          naiyou: data ? data.naiyou : null,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: GET_KP2_YOUGO_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function saveKyotakuPlan2YougoMaster(listName, naiyou) {
  return (dispatch) => {
    YougoMasterNaiyouApi.updateYougoMasterApi(listName, naiyou).then(
      (data) =>
        dispatch({
          type: SAVE_KP2_YOUGO_MASTER_SUCCESS,
          naiyou: data.naiyou,
        }),
      (err) => {
        if (
          err.response.status === 400 &&
          err.response.data.error.code === "YOUGOMASTER_NOT_FOUND"
        ) {
          YougoMasterNaiyouApi.createYougoMaster(listName, naiyou).then(
            (data) =>
              dispatch({
                type: SAVE_KP2_YOUGO_MASTER_SUCCESS,
                naiyou: data.naiyou,
              }),
            (err2) => {
              checkError(err2);
              dispatch({
                type: SAVE_KP2_YOUGO_MASTER_ERROR,
                error:
                  err2.response.status === 400
                    ? err2.response.data.error
                    : { message: err2.response.statusText },
              });
            }
          );
        } else {
          checkError(err);
          dispatch({
            type: SAVE_KP2_YOUGO_MASTER_ERROR,
            error:
              err.response.status === 400
                ? err.response.data.error
                : { message: err.response.statusText },
          });
        }
      }
    );
  };
}

export function changeEditMobileMode(edit) {
  return {
    type: CHANGE_EDIT_MOBILE_MODE,
    edit,
  };
}

export function changeValuePlan2(types) {
  return {
    type: CHANGE_VALUE_DETAIL,
    types,
  };
}
function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}

export function resetStatus() {
  return (dispatch) =>
    dispatch({
      type: RESET_STATUS,
    });
}

export function keepValue1(progressF, renew_years, plan_date, plan2_doui_date, comment) {
  return (dispatch) => dispatch({
    type: KEEP_VALUE1,
    progressF,
    renew_years,
    plan_date,
    plan2_doui_date,
    comment
  })
}

export function setRowKyotakuPlan2(expandedRowKeys, data) {
  return (dispatch) =>
    dispatch({
      type: SET_ROW_KYOTAKU_PLAN2,
      expandedRowKeys,
      data,
    });
}

export function settingMasterNaiyou(value, callBack) {
  return (dispatch) => {
    dispatch({
      type: SETTING_MASTER_PLAN2,
    });
    KyotakuPlan2Api.settingMasterNaiyou(value).then(
      (data) => {
        return callBack(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: SETTING_MASTER_PLAN2_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
