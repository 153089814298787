/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import { EnterOutlined } from "@ant-design/icons";
import { Button, Col, InputNumber, Popover, Radio, Row } from "antd";
import React, { Fragment } from "react";
import {
  convertArrayToString,
  extractRangeVital,
  handleInputNumber,
} from "../../../common/function_common/functionCommon";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import InputSelectKB from "../../../common/model-kiroku/InputSelectKB";
import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";

const RadioGroup = Radio.Group;
class NormalFormVital extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      extendValue: false,
      visibleNoKeyboard: false,
    };

    this.onChangeTem = this.onChangeTem.bind(this);
    this.onChangeBph = this.onChangeBph.bind(this);
    this.onChangeBpl = this.onChangeBpl.bind(this);
    this.onChangePulse = this.onChangePulse.bind(this);
    this.onChangePulsef = this.onChangePulsef.bind(this);
    this.onChangeSpo2B = this.onChangeSpo2B.bind(this);
    this.onChangeSpo2A = this.onChangeSpo2A.bind(this);
    this.onChangeKokyu = this.onChangeKokyu.bind(this);
    this.onChangeKokyuf = this.onChangeKokyuf.bind(this);
    this.onChangeEtc1 = this.onChangeEtc1.bind(this);
    this.onChangeSelectEtc1 = this.onChangeSelectEtc1.bind(this);
    this.onChangeEtc2 = this.onChangeEtc2.bind(this);
    this.onChangeSelectEtc2 = this.onChangeSelectEtc2.bind(this);
    this.onChangeEtc3 = this.onChangeEtc3.bind(this);
    this.onChangeSelectEtc3 = this.onChangeSelectEtc3.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    this.getYougoMasterEctState = this.getYougoMasterEctState.bind(this);
    this.getYougoMasterEctCancel = this.getYougoMasterEctCancel.bind(this);
    this.getYougoMasterEctSave = this.getYougoMasterEctSave.bind(this);

    this.handleType = this.handleType.bind(this);
  }

  componentDidUpdate() {
    // const oldProps = this.props

    // @TODO CHECK
    this.props.form.resetFields();
    // if (oldProps.data !== newProps.data) {
    //     this.props.form.resetFields();
    // }
  }

  onChangeWeight = (value) => {
    this.props.handleFormInput(value ? value : "0", "vital", "weight");
  };

  onChangeTem = (value) => {
    this.props.handleFormInput(value ? value : "0", "vital", "temperature");
  };

  onChangeBph = (value) => {
    this.props.handleFormInput(value, "vital", "bpressureHigh");
  };

  onChangeBpl = (value) => {
    this.props.handleFormInput(value, "vital", "bpressureLow");
  };

  onChangePulse = (value) => {
    this.props.handleFormInput(value, "vital", "pulse");
  };

  onChangePulsef = (e) => {
    this.props.handleFormInput(e.target.value, "vital", "pulsef");
  };

  onChangeSpo2B = (value) => {
    this.props.handleFormInput(value, "vital", "spo2B");
  };

  onChangeSpo2A = (value) => {
    this.props.handleFormInput(value, "vital", "spo2A");
  };

  onChangeKokyu = (value) => {
    this.props.handleFormInput(value, "vital", "kokyu");
  };

  onChangeKokyuf(e) {
    this.props.handleFormInput(e.target.value, "vital", "kokyuf");
  }

  onChangeEtc1(value, type) {
    if (type && type === "select" && this.props.data.etc1) {
      value = this.props.data.etc1 + "、" + value;
    }

    this.props.handleFormInput(value, "vital", "etc1");
  }

  onChangeSelectEtc1(value) {
    this.props.handleFormInput(value, "vital", "etc1");
  }

  onChangeEtc2(value, type) {
    if (type && type === "select" && this.props.data.etc2) {
      value = this.props.data.etc2 + "、" + value;
    }

    this.props.handleFormInput(value, "vital", "etc2");
  }

  onChangeSelectEtc2(value) {
    this.props.handleFormInput(value, "vital", "etc2");
  }

  onChangeEtc3(value, type) {
    if (type && type === "select" && this.props.data.etc3) {
      value = this.props.data.etc3 + "、" + value;
    }

    this.props.handleFormInput(value, "vital", "etc3");
  }

  onChangeSelectEtc3(value) {
    this.props.handleFormInput(value, "vital", "etc3");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "vital", "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "vital", "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "vital", "tantoId");
  }

  handleInput(value) {
    value = value ? value : "0";
    const checkValue = handleInputNumber(value, this.state.value, true);

    this.props.handleFormInput(checkValue, "vital", this.state.type);

    this.setState({
      value: checkValue,
    });
  }

  getYougoMasterEditState = (index) => {
    this.props.yougoMasterEditState(index, 1);
  };

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index, 1);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou, 1);
  }

  getYougoMasterEctState(index) {
    this.props.yougoMasterEctState(index);
  }

  getYougoMasterEctCancel(index) {
    this.props.yougoMasterEctCancel(index);
  }

  getYougoMasterEctSave(type, naiyou) {
    this.props.yougoMasterEctSave(type, naiyou);
  }

  handleType(type) {
    this.setState({
      type,
      value: null,
      visibleNoKeyboard: true,
    });
  }

  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  onVisibleChangePopover = () => {
    const { visibleNoKeyboard } = this.state;

    if (visibleNoKeyboard) {
      this.setState({
        visibleNoKeyboard: false,
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      tantoMaster,
      data,
      yougoMasterNa,
      vitalEct,
      ect1Type,
      ect2Type,
      ect3Type,
      yougoMasterEctSelect1,
      yougoMasterEctSelect2,
      yougoMasterEctSelect3,
      vitalCol,
      zenkai,
      disabled,
      settingData,
    } = this.props;

    const { visibleNoKeyboard, type } = this.state;

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            <Button onClick={() => this.handleInput(".")}>.</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
          <Row>
            <Button
              onClick={this.handleEnter}
              style={{ padding: "0px", height: 150 }}
            >
              <EnterOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    );

    const formItemLayoutEctComent = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 21 },
      },
    };

    const preZenkai = {
      weight: zenkai && zenkai[0] && zenkai[0].weight ? zenkai[0].weight : "",
      temperature:
        zenkai && zenkai[0] && zenkai[0].temperature
          ? zenkai[0].temperature
          : "",
      bpressureHigh:
        zenkai && zenkai[0] && zenkai[0].bpressureHigh
          ? zenkai[0].bpressureHigh
          : "",
      bpressureLow:
        zenkai && zenkai[0] && zenkai[0].bpressureLow
          ? zenkai[0].bpressureLow
          : "",
      pulse: zenkai && zenkai[0] && zenkai[0].pulse ? zenkai[0].pulse : "",
      pulsef: zenkai && zenkai[0] && zenkai[0].pulsef ? zenkai[0].pulsef : "",
      kokyu: zenkai && zenkai[0] && zenkai[0].kokyu ? zenkai[0].kokyu : "",
      kokyuf: zenkai && zenkai[0] && zenkai[0].kokyuf ? zenkai[0].kokyuf : "",
      spo2: zenkai && zenkai[0] && zenkai[0].spo2 ? zenkai[0].spo2 : "",
    };

    let normalWeight = null;
    let normalTemperature = null;
    let normalBpressureHigh = null;
    let normalBpressureLow = null;
    let normalPulse = null;
    let normalRespiration = null;

    if (settingData && settingData.vitalHighLow) {
      const extractRange = extractRangeVital(settingData.vitalHighLow);
      normalWeight = extractRange.weight;
      normalTemperature = extractRange.temperature;
      normalBpressureHigh = extractRange.bpressureHigh;
      normalBpressureLow = extractRange.bpressureLow;
      normalPulse = extractRange.pulse;
      normalRespiration = extractRange.respiration;
    }

    return (
      (<Form layout="vertical">
        <Row gutter={8} style={{ margin: 8 }}>
          <Col sm={6} md={3} className="rwf-title">
            <div>担当者 :</div>
          </Col>
          <Col sm={18} md={11}>
            <div >
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
                disabled={disabled}
              />
            </div>
          </Col>
          <Col sm={24} md={2} className="rwf-title">
            前回
          </Col>
          <Col sm={24} md={2} className="rwf-title">
            正常値
          </Col>
        </Row>
        {/* update weight */}
        {vitalCol && vitalCol[0] === "True" ? ( // @TODO ===
          (<Row gutter={8} style={{ margin: 8 }}>
            <Col sm={6} md={3} className="rwf-title">
              <div>体重 :</div>
            </Col>
            <Col sm={18} md={11}>
              <div >
                {getFieldDecorator("weight", {
                  rules: [{ required: false }],
                  initialValue:
                    data.weight && data.weight > 0 ? data.weight : null,
                })(
                  <InputNumber
                    disabled={disabled}
                    min={0}
                    onChange={this.onChangeWeight}
                  />
                )}
                {disabled ? null : (
                  <Popover
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    visible={
                      !!(visibleNoKeyboard && type === "weight")
                    }
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      onClick={() => this.handleType("weight")}
                      type="primary"
                    >
                      入力
                    </Button>
                  </Popover>
                )}
              </div>
            </Col>
            <Col sm={24} md={2} className="zenkai-value">
              {preZenkai.weight ? (
                <span className="ant-form-text">{preZenkai.weight}</span>
              ) : null}
            </Col>
            <Col sm={24} md={2} className="normal-value">
              <span className="ant-form-text">{normalWeight}</span>
            </Col>
          </Row>)
        ) : null}
        {vitalCol && vitalCol[1] === "True" ? ( // @TODO ===
          (<Row gutter={8} style={{ margin: 8 }}>
            <Col sm={6} md={3} className="rwf-title">
              <div>体温 :</div>
            </Col>
            <Col sm={18} md={11}>
              <div >
                {getFieldDecorator("temperature", {
                  rules: [{ required: false }],
                  initialValue:
                    data.temperature && data.temperature > 0
                      ? data.temperature
                      : null,
                })(
                  <InputNumber
                    disabled={disabled}
                    min={0}
                    onChange={this.onChangeTem}
                  />
                )}
                {disabled ? null : (
                  <Popover
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    visible={
                      !!(visibleNoKeyboard && type === "temperature")
                    }
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      onClick={() => this.handleType("temperature")}
                      type="primary"
                    >
                      入力
                    </Button>
                  </Popover>
                )}
              </div>
            </Col>
            <Col sm={24} md={2} className="zenkai-value">
              {preZenkai.temperature ? (
                <span className="ant-form-text">{preZenkai.temperature}</span>
              ) : null}
            </Col>
            <Col sm={24} md={2} className="normal-value">
              {normalTemperature}
            </Col>
          </Row>)
        ) : null}
        {vitalCol && vitalCol[2] === "True" ? ( // @TODO ===
          (<Row gutter={8} style={{ margin: 8 }}>
            <Col sm={6} md={3} className="rwf-title">
              <div>
                <span>血圧 : </span>
                <span style={{ float: "right" }}>高</span>
              </div>
            </Col>
            <Col sm={24} md={11}>
              <div className="kwf-vital-input">
                {getFieldDecorator("bpressureHigh", {
                  rules: [{ required: false }],
                  initialValue:
                    data.bpressureHigh && data.bpressureHigh > 0
                      ? data.bpressureHigh
                      : null,
                })(
                  <InputNumber
                    disabled={disabled}
                    min={0}
                    onChange={this.onChangeBph}
                  />
                )}

                {disabled ? null : (
                  <Popover
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    visible={
                      !!(visibleNoKeyboard && type === "bpressureHigh")
                    }
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      onClick={() => this.handleType("bpressureHigh")}
                      type="primary"
                    >
                      入力
                    </Button>
                  </Popover>
                )}
              </div>
              <div className="kwf-vital-input">
                <span style={{ paddingRight: 8 }} className="rwf-title">
                  低
                </span>
                {getFieldDecorator("bpressureLow", {
                  rules: [{ required: false }],
                  initialValue:
                    data.bpressureLow && data.bpressureLow > 0
                      ? data.bpressureLow
                      : null,
                })(
                  <InputNumber
                    disabled={disabled}
                    min={0}
                    onChange={this.onChangeBpl}
                  />
                )}
                {disabled ? null : (
                  <Popover
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    visible={
                      !!(visibleNoKeyboard && type === "bpressureLow")
                    }
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      onClick={() => this.handleType("bpressureLow")}
                      type="primary"
                    >
                      入力
                    </Button>
                  </Popover>
                )}
              </div>
            </Col>
            <Col sm={24} md={2} className="zenkai-value">
              {preZenkai.bpressureHigh || preZenkai.bpressureLow ? (
                <span className="ant-form-text">{`${preZenkai.bpressureHigh}/${preZenkai.bpressureLow}`}</span>
              ) : null}
            </Col>
            <Col sm={24} md={2} className="normal-value2">
              {normalBpressureHigh} {normalBpressureLow}
            </Col>
          </Row>)
        ) : null}
        {vitalCol && (vitalCol[3] === "True" || vitalCol[4] === "True") ? (
          <Row gutter={8} style={{ margin: 8 }}>
            <Col sm={6} md={3} className="rwf-title">
              <div>脈拍 :</div>
            </Col>
            <Col sm={24} md={11}>
              {vitalCol && vitalCol[3] === "True" ? ( // @TODO ===
                (<div className="kwf-vital-input">
                  {getFieldDecorator("pulse", {
                    rules: [{ required: false }],
                    initialValue:
                      data.pulse && data.pulse > 0 ? data.pulse : null,
                  })(
                    <InputNumber
                      disabled={disabled}
                      min={0}
                      onChange={this.onChangePulse}
                    />
                  )}
                  {disabled ? null : (
                    <Popover
                      placement="bottomRight"
                      content={numberKB}
                      trigger="click"
                      visible={
                        !!(visibleNoKeyboard && type === "pulse")
                      }
                      onVisibleChange={this.onVisibleChangePopover}
                    >
                      <Button
                        onClick={() => this.handleType("pulse")}
                        type="primary"
                      >
                        入力
                      </Button>
                    </Popover>
                  )}
                </div>)
              ) : null}
              {vitalCol && vitalCol[4] === "True" ? ( // @TODO ===
                (<div className="kwf-vital-input">
                  {getFieldDecorator("pulsef", {
                    rules: [{ required: false }],
                    initialValue: data.pulsef,
                  })(
                    <RadioGroup
                      disabled={disabled}
                      onChange={this.onChangePulsef}
                    >
                      <Radio value={"整"}>整</Radio>
                      <Radio value={"不整"}>不整</Radio>
                      <Radio value={"無し"}>無し</Radio>
                    </RadioGroup>
                  )}
                </div>)
              ) : null}
            </Col>
            <Col sm={24} md={2} className="zenkai-value">
              {preZenkai.pulse ? (
                <span className="ant-form-text">{`${preZenkai.pulse}${preZenkai.pulsef}`}</span>
              ) : null}
            </Col>
            <Col sm={24} md={2} className="normal-value">
              {normalPulse}
            </Col>
          </Row>
        ) : null}
        {vitalCol && (vitalCol[5] === "True" || vitalCol[6] === "True") ? (
          <Row gutter={8} style={{ margin: 8 }}>
            <Col sm={6} md={3} className="rwf-title">
              <div>呼吸 :</div>
            </Col>
            <Col sm={24} md={11}>
              {vitalCol && vitalCol[5] === "True" ? ( // @TODO ===
                (<div className="kwf-vital-input">
                  {getFieldDecorator("kokyu", {
                    rules: [{ required: false }],
                    initialValue:
                      data.kokyu && data.kokyu > 0 ? data.kokyu : null,
                  })(
                    <InputNumber
                      disabled={disabled}
                      min={0}
                      onChange={this.onChangeKokyu}
                    />
                  )}
                  {disabled ? null : (
                    <Popover
                      placement="bottomRight"
                      content={numberKB}
                      trigger="click"
                      visible={
                        !!(visibleNoKeyboard && type === "kokyu")
                      }
                      onVisibleChange={this.onVisibleChangePopover}
                    >
                      <Button
                        onClick={() => this.handleType("kokyu")}
                        type="primary"
                      >
                        入力
                      </Button>
                    </Popover>
                  )}
                </div>)
              ) : null}
              {vitalCol && vitalCol[6] === "True" ? ( // @TODO ===
                (<div className="kwf-vital-input">
                  {getFieldDecorator("kokyuf", {
                    rules: [{ required: false }],
                    initialValue: data.kokyuf,
                  })(
                    <RadioGroup
                      disabled={disabled}
                      onChange={this.onChangeKokyuf}
                    >
                      <Radio value={"整"}>整</Radio>
                      <Radio value={"不整"}>不整</Radio>
                      <Radio value={"無し"}>無し</Radio>
                    </RadioGroup>
                  )}
                </div>)
              ) : null}
            </Col>
            <Col sm={24} md={2} className="zenkai-value">
              {preZenkai.kokyu ? (
                <span className="ant-form-text">{`${preZenkai.kokyu}${preZenkai.kokyuf}`}</span>
              ) : null}
            </Col>
            <Col sm={24} md={2} className="normal-value">
              {normalRespiration}
            </Col>
          </Row>
        ) : null}
        {vitalCol && vitalCol[7] === "True" ? ( // @TODO ===
          (<Row gutter={8} style={{ margin: 8 }}>
            <Col sm={6} md={3} className="rwf-title">
              <div>SPO2 :</div>
            </Col>
            <Col sm={24} md={11}>
              <div className="kwf-vital-input">
                {getFieldDecorator("spo2B", {
                  rules: [{ required: false }],
                  // initialValue: data.spo2 ? data.spo2.toString().split('-')[0] : null
                  initialValue:
                    data.spo2B && data.spo2B > 0 ? data.spo2B : null,
                })(
                  <InputNumber
                    disabled={disabled}
                    min={0}
                    onChange={this.onChangeSpo2B}
                  />
                )}
                {disabled ? null : (
                  <Popover
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    visible={
                      !!(visibleNoKeyboard && type === "spo2B")
                    }
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      onClick={() => this.handleType("spo2B")}
                      type="primary"
                    >
                      入力
                    </Button>
                  </Popover>
                )}
              </div>
              <div className="kwf-vital-input">
                <span style={{ paddingRight: 8 }} className="rwf-title">
                  〜
                </span>
                {getFieldDecorator("spo2A:", {
                  rules: [{ required: false }],
                  // initialValue: data.spo2 && data.spo2 !== '' && data.spo2.toString().split('-')[1] ? data.spo2.split('-')[1] : null
                  initialValue:
                    data.spo2A && data.spo2A > 0 ? data.spo2A : null,
                })(
                  <InputNumber
                    disabled={disabled}
                    min={0}
                    onChange={this.onChangeSpo2A}
                  />
                )}
                {disabled ? null : (
                  <Popover
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    visible={
                      !!(visibleNoKeyboard && type === "spo2A")
                    }
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      onClick={() => this.handleType("spo2A")}
                      type="primary"
                    >
                      入力
                    </Button>
                  </Popover>
                )}
              </div>
            </Col>
            <Col sm={24} md={2} className="zenkai-value">
              {preZenkai.spo2 ? (
                <span className="ant-form-text">{`${preZenkai.spo2}`}</span>
              ) : null}
            </Col>
          </Row>)
        ) : null}
        <Row gutter={8} style={{ margin: 8 }}>
          {vitalEct &&
            ect1Type &&
            vitalEct.split(",")[0] &&
            vitalEct.split(",")[0].split("=")[1] ?
            (
              <Fragment>
                <Col sm={6} md={3} className="rwf-title">
                  <div>{vitalEct.split(",")[0].split("=")[1] + " :"}</div>
                </Col>
                <Col sm={18} md={21}>
                  <div {...formItemLayoutEctComent}>
                    {ect1Type === 1 ? ( // number input
                      (<div>
                        {getFieldDecorator("etc1", {
                          rules: [{ required: false }],
                          initialValue: data.etc1,
                        })(
                          <InputNumber
                            disabled={disabled}
                            onChange={this.onChangeSelectEtc1}
                          />
                        )}
                        {disabled ? null : (
                          <Popover
                            placement="bottomRight"
                            content={numberKB}
                            trigger="click"
                            visible={
                              !!(visibleNoKeyboard && type === "etc1")
                            }
                            onVisibleChange={this.onVisibleChangePopover}
                          >
                            <Button
                              onClick={() => this.handleType("etc1")}
                              type="primary"
                            >
                              入力
                            </Button>
                          </Popover>
                        )}
                      </div>)
                    ) : ect1Type === 4 ? ( // select Case
                      (<CommentKB
                        yougoMasterEditState={this.getYougoMasterEctState}
                        yougoMasterEditCancel={this.getYougoMasterEctCancel}
                        yougoMasterEditSave={this.getYougoMasterEctSave.bind(
                          this,
                          "3"
                        )}
                        yougoMasterNa={yougoMasterEctSelect1}
                        rowNum={1}
                        pstion="topRight"
                        handleTextareaKB={this.onChangeEtc1}
                        defaultValue={data.etc1}
                        disabled={disabled}
                      />)
                    ) : (
                      // ect1Type = text case
                      (<InputSelectKB
                        value={data.etc1}
                        handleInputSelect={this.onChangeSelectEtc1}
                        data={convertArrayToString(
                          yougoMasterEctSelect1,
                          "naiyou"
                        )}
                        disabled={disabled}
                      />)
                    )}
                  </div>
                </Col>
              </Fragment>
            ) : null}
        </Row>
        <Row gutter={8} style={{ margin: 8 }}>
          {vitalEct &&
            ect2Type &&
            vitalEct.split(",")[1] &&
            vitalEct.split(",")[1].split("=")[1] ?
            (
              <div>
                <Col sm={6} md={3} className="rwf-title">
                  <div>{vitalEct.split(",")[1].split("=")[1] + " :"}</div>
                </Col>
                <Col sm={18} md={21}>
                  <div {...formItemLayoutEctComent}>
                    {ect2Type === 1 ? ( // number input
                      (<div>
                        {getFieldDecorator("etc1", {
                          rules: [{ required: false }],
                          initialValue: data.etc2,
                        })(
                          <InputNumber
                            disabled={disabled}
                            onChange={this.onChangeSelectEtc2}
                          />
                        )}
                        {disabled ? null : (
                          <Popover
                            placement="bottomRight"
                            content={numberKB}
                            trigger="click"
                            visible={
                              !!(visibleNoKeyboard && type === "etc2")
                            }
                            onVisibleChange={this.onVisibleChangePopover}
                          >
                            <Button
                              onClick={() => this.handleType("etc2")}
                              type="primary"
                            >
                              入力
                            </Button>
                          </Popover>
                        )}
                      </div>)
                    ) : ect2Type === 4 ? ( // select Case
                      (<CommentKB
                        yougoMasterEditState={this.getYougoMasterEctState}
                        yougoMasterEditCancel={this.getYougoMasterEctCancel}
                        yougoMasterEditSave={this.getYougoMasterEctSave.bind(
                          this,
                          "2"
                        )}
                        yougoMasterNa={yougoMasterEctSelect2}
                        rowNum={1}
                        pstion="topRight"
                        handleTextareaKB={this.onChangeEtc2}
                        defaultValue={data.etc2}
                        disabled={disabled}
                      />)
                    ) : (
                      // ect1Type = text case
                      (<InputSelectKB
                        value={data.etc2}
                        handleInputSelect={this.onChangeSelectEtc2}
                        data={convertArrayToString(
                          yougoMasterEctSelect2,
                          "naiyou"
                        )}
                        disabled={disabled}
                      />)
                    )}
                  </div>
                </Col>
              </div>
            ) : null}

          {vitalEct &&
            ect3Type &&
            vitalEct.split(",")[2] &&
            vitalEct.split(",")[2].split("=")[1] ?
            (
              <div>
                <Col sm={6} md={3} className="rwf-title">
                  <div>{vitalEct.split(",")[2].split("=")[1] + " :"}</div>
                </Col>
                <Col sm={18} md={21}>
                  <div {...formItemLayoutEctComent}>
                    {ect3Type === 1 ? ( // number input
                      (<div>
                        {getFieldDecorator("etc3", {
                          rules: [{ required: false }],
                          initialValue: data.etc3,
                        })(
                          <InputNumber
                            disabled={disabled}
                            onChange={this.onChangeSelectEtc3}
                          />
                        )}
                        {disabled ? null : (
                          <Popover
                            placement="bottomRight"
                            content={numberKB}
                            trigger="click"
                            visible={
                              !!(visibleNoKeyboard && type === "etc3")
                            }
                            onVisibleChange={this.onVisibleChangePopover}
                          >
                            <Button
                              onClick={() => this.handleType("etc3")}
                              type="primary"
                            >
                              入力
                            </Button>
                          </Popover>
                        )}
                      </div>)
                    ) : ect3Type === 4 ? ( // select Case
                      (<CommentKB
                        yougoMasterEditState={this.getYougoMasterEctState}
                        yougoMasterEditCancel={this.getYougoMasterEctCancel}
                        yougoMasterEditSave={this.getYougoMasterEctSave.bind(
                          this,
                          "3"
                        )}
                        yougoMasterNa={yougoMasterEctSelect3}
                        rowNum={2}
                        pstion="topRight"
                        handleTextareaKB={this.onChangeEtc3}
                        defaultValue={data.etc3}
                        disabled={disabled}
                      />)
                    ) : (
                      // ect1Type = text case
                      (<InputSelectKB
                        disabled={disabled}
                        value={data.etc3}
                        handleInputSelect={this.onChangeSelectEtc3}
                        data={convertArrayToString(
                          yougoMasterEctSelect3,
                          "naiyou"
                        )}
                      />)
                    )}
                  </div>
                </Col>
              </div>
            ) : null}
        </Row>
        <Row gutter={8} style={{ margin: 8 }}>
          <Col sm={6} md={3} className="rwf-title">
            <div>コメント :</div>
          </Col>
          <Col sm={18} md={21}>
            <div {...formItemLayoutEctComent}>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={2}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
                disabled={disabled}
              />
            </div>
          </Col>
        </Row>
      </Form>)
    );
  }
}

const FormVital = Form.create()(NormalFormVital);
export default FormVital;
