import { connect } from "react-redux";
import {
  getMaintainanceModeCreator,
  loginAccountCreator,
} from "../../actions/accountActions/actionCreators";
import Account from "./account";

const mapStateToProps = (state) => {
  return {
    returnData: state.dataLogin.data,
    mode: state.dataLogin.mode,
    systemTitle: state.dataLogin.systemTitle,
    loadMode: state.dataLogin.loadMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dataLogin: (username, password, code, isCheck, callback) =>
      dispatch(loginAccountCreator(username, password, code, isCheck, callback)),
    getMaintainanceMode: () => dispatch(getMaintainanceModeCreator()),
  };
};

export const AccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
