import React, { useState, useEffect } from "react";
import { Row, Button, Input, Col, Modal } from "antd";
import { Fragment } from "react";
import { NumberKB } from "../../dialogs/index";
// import { handleInputNumber } from '../function_common/functionCommon';

function InputNumberModal(props) {
  const [serviceTani, setServiceTani] = useState();
  const [numberKBVisible, setNumberKBVisible] = useState();

  useEffect(() => {
    if (props.recordSelected) setServiceTani(props.recordSelected.serviceTani);
  }, [props.recordSelected]);

  function handleOk() {
    props.onOk(
      props.recordSelected ? props.recordSelected.key : null,
      serviceTani
    );
    props.onHide();
  }

  function handleCancel() {
    props.onHide();
  }

  function handleServiceTani() {
    setNumberKBVisible(true);
  }

  function handleHideNumberKB() {
    setNumberKBVisible(false);
    props.onShow();
  }

  function handleOkNumberKB(id, value) {
    setServiceTani(value);
    setNumberKBVisible(false);
    props.onShow();
  }

  function handleChangeServiceTani(e) {
    const lengthValue = e.target.value.length;
    const lengthServiceTani = serviceTani ? ("" + serviceTani).length : 0;
    let newValue = "";
    if (lengthValue > lengthServiceTani) {
      newValue = handleInputNumber(
        e.target.value.substring(lengthServiceTani, lengthValue),
        serviceTani || ""
      );
    } else {
      newValue = e.target.value;
    }
    setServiceTani(newValue);
  }

  function handleInputNumber(inputChar, value, checkMaxMin) {
    const array = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
    if (array.includes(inputChar)) {
      if (inputChar === "消去") {
        value = "";
      } else if (inputChar === ".") {
        if (!value) {
          value = "0.";
        } else {
          if (!value.includes(".")) {
            value = value + ".";
          }
        }
      } else {
        if (!value) {
          // if (inputChar !== '0')
          value = inputChar;
        } else {
          value = value + "" + inputChar;
        }
      }
    }

    return value;
  }

  return (
    (<Fragment>
      <Modal
        open={props.visible}
        // title={props.title}
        // onCancel={() => handleCancel()}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <Button
            key="back"
            type="primary"
            style={{
              backgroundColor: "#ffffff",
              color: "#616161",
              border: "1px solid #dbdbdb",
            }}
            onClick={handleCancel}
          >
            キャンセル
          </Button>,
          <Button
            key="back"
            type="primary"
            onClick={handleOk}
            //   disabled={
            //     frequency.every((element) => element === false) || isErrorTime
            //   }
          >
            OK
          </Button>,
        ]}
        width={400}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Input
            value={serviceTani}
            style={{ width: "200px" }}
            onChange={handleChangeServiceTani}
          />
          <Button type="primary" onClick={handleServiceTani}>
            入力
          </Button>
        </div>
      </Modal>
      <NumberKB
        visible={numberKBVisible}
        value={serviceTani}
        onHide={handleHideNumberKB}
        onOk={handleOkNumberKB}
      />
    </Fragment>)
  );
}

export { InputNumberModal };
