export const MeetingActionTypes = {
  GET_ALL_MEETING_REQUEST: "GET_ALL_MEETING_REQUEST",
  GET_ALL_MEETING_SUCCESS: "GET_ALL_MEETING_SUCCESS",
  GET_ALL_MEETING_FAILURE: "GET_ALL_MEETING_FAILURE",

  GET_MEETING_REQUEST: "GET_MEETING_REQUEST",
  GET_MEETING_SUCCESS: "GET_MEETING_SUCCESS",
  GET_MEETING_FAILURE: "GET_MEETING_FAILURE",

  CREATE_MEETING_REQUEST: "CREATE_MEETING_REQUEST",
  CREATE_MEETING_SUCCESS: "CREATE_MEETING_SUCCESS",
  CREATE_MEETING_FAILURE: "CREATE_MEETING_FAILURE",

  UPDATE_MEETING_REQUEST: "UPDATE_MEETING_REQUEST",
  UPDATE_MEETING_SUCCESS: "UPDATE_MEETING_SUCCESS",
  UPDATE_MEETING_FAILURE: "UPDATE_MEETING_FAILURE",

  REMOVE_MEETING_REQUEST: "REMOVE_MEETING_REQUEST",
  REMOVE_MEETING_SUCCESS: "REMOVE_MEETING_SUCCESS",
  REMOVE_MEETING_FAILURE: "REMOVE_MEETING_FAILURE",
};
