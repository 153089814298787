import { Spin } from "antd";
import dayjs from "dayjs";
import React from "react";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import { ConstSet } from "../../common/configs/constset";
import "./DutyReport.css";
import DutyReportMain from "./UI/DutyReportMain";
import { openNotificationWithIcon } from "./function/alert";

export default class DutyReport extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const roombunrui = localStorage.getItem("officeAuth_roombunrui");
    this.state = {
      dutyReportFake: null,
      selectedDate: dayjs(today),
      roomBunrui: roombunrui != null ? roombunrui : null,
      loading: false,
      parameterObj: {
        date: dayjs(today).format(ConstSet.DATE_FORMAT),
        roomBunrui: roombunrui != null ? roombunrui : null,
      },
      dutyReport: null,
      dateObj: {
        today: dayjs(today).format(ConstSet.DATE_FORMAT),
        yesterday: dayjs(today)
          .subtract(1, "days")
          .format(ConstSet.DATE_FORMAT),
        roomBunrui: roombunrui != null ? roombunrui : null,
      },
    };
  }

  componentDidMount() {
    const { parameterObj, dateObj } = this.state;
    this.props.yougoMasterWeatherNaiyouCreator("天候");
    this.props.yougoMasterRoomNaiyouCreator("部屋分類");
    this.props.yougoMasterTantoBunruiNaiyouCreator("担当者分類");
    this.props.yougoMasterStaffKindNaiyouCreator("職員種別");
    this.props.yougoMasterDepartNaiyouCreator("日誌部署");
    this.props.loadDutyReportCreator(parameterObj);
    this.props.loadStatisticalOutInCreator(dateObj);
    this.props.loadStatisticalOutInByKindCreator(parameterObj);
    this.props.loadListPeopleOutInByKindCreator(parameterObj);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dutyReportList !== this.props.dutyReportList) {
      const { dutyReportList } = this.props;
      const { roomBunrui } = this.state;
      let dutyReport = null;

      // alert("FUCK");
      if (dutyReportList && dutyReportList.length > 0) {
        for (let i = 0; i < dutyReportList.length; i++) {
          if (roomBunrui && dutyReportList[i].roomBunrui == roomBunrui) {
            dutyReport = dutyReportList[i];
          }

          if (!roomBunrui) {
            dutyReport = dutyReportList[i];
          }
        }
      }

      this.setState({
        dutyReport: dutyReport != undefined ? dutyReport : null,
      });
    }
    if (
      nextProps.dataCreated != this.props.dataCreated &&
      nextProps.notifyContent.type === "success"
    ) {
      this.setState({
        dutyReport: nextProps.dataCreated,
      });
    }
    if (nextProps.notifyContent && nextProps.notifyContent.type === "error") {
      this.setState({
        dutyReport: this.state.dutyReportFake,
      });
    }

    if (
      nextProps.dataEdited != this.props.dataEdited &&
      nextProps.notifyContent.type === "success"
    ) {
      this.setState({
        dutyReport: nextProps.dataEdited,
      });
    }
    if (nextProps.notifyContent && nextProps.notifyContent.type === "error") {
      this.setState({
        dutyReport: this.state.dutyReportFake,
      });
    }
  }
  componentDidUpdate(preProps) {
    const { notifyContent } = this.props;
    if (notifyContent && notifyContent.type) {
      openNotificationWithIcon(notifyContent.type, notifyContent.message);
      this.props.resetStatus();
    }

    if (preProps.dutyReportList !== this.props.dutyReportList) {
      const { dutyReportList } = this.props;
      const { roomBunrui } = this.state;
      let dutyReport = null;

      // alert("FUCK");
      if (dutyReportList && dutyReportList.length > 0) {
        for (let i = 0; i < dutyReportList.length; i++) {
          if (roomBunrui && dutyReportList[i].roomBunrui == roomBunrui) {
            dutyReport = dutyReportList[i];
          }

          if (!roomBunrui) {
            dutyReport = dutyReportList[i];
          }
        }
      }

      this.setState({
        dutyReport: dutyReport != undefined ? dutyReport : null,
      });
    }

  }

  async saveDutyReport(parameterObj) {
    const { dutyReport } = this.state;

    if (dutyReport && dutyReport.dutyReportId != null) {
      const parameterObjSave = {
        ...parameterObj,
        dutyReportId: dutyReport.dutyReportId.toString(),
        date: dayjs(this.state.selectedDate).format(ConstSet.DATE_FORMAT),
        roomBunrui: dutyReport.roomBunrui,
      };
      await this.setState({
        dutyReportFake: parameterObjSave,
      });
      this.props.editDutyReportCreator(parameterObjSave);
    }
    else {
      const parameterObjSave = {
        ...parameterObj,
        dutyReportId: null,
        date: dayjs(this.state.selectedDate).format(ConstSet.DATE_FORMAT),
        roomBunrui: this.state.roomBunrui,
      };
      await this.setState({
        dutyReportFake: parameterObjSave,
      });
      this.props.createDutyReportCreator(parameterObjSave);
    }
  }

  handleChangeDate(value) {
    const { roomBunrui } = this.state;
    const parameterObj = {
      ...this.state.parameterObj,
      date: dayjs(value).format(ConstSet.DATE_FORMAT),
    };
    const dateObj = {
      today: dayjs(value).format(ConstSet.DATE_FORMAT),
      yesterday: dayjs(value).subtract(1, "days").format(ConstSet.DATE_FORMAT),
      roomBunrui,
    };
    this.props.loadDutyReportCreator(parameterObj);
    this.props.loadEventPlans(
      value.format(ConstSet.DATE_FORMAT),
      value.format(ConstSet.DATE_FORMAT)
    );
    this.props.loadStatisticalOutInCreator(dateObj);
    this.props.loadStatisticalOutInByKindCreator(parameterObj);
    this.props.loadListPeopleOutInByKindCreator(parameterObj);
    this.setState({ selectedDate: value, parameterObj, dateObj });
  }

  handleChangeRoomBunrui(value) {
    if (value) {
      localStorage.setItem("officeAuth_roombunrui", value);
    }
    else {
      localStorage.removeItem("officeAuth_roombunrui");
    }
    const dateObj = { ...this.state.dateObj, roomBunrui: value };
    const parameterObj = { ...this.state.parameterObj, roomBunrui: value };
    this.props.loadDutyReportCreator(parameterObj);
    this.props.loadStatisticalOutInCreator(dateObj);
    this.props.loadStatisticalOutInByKindCreator(parameterObj);
    this.props.loadListPeopleOutInByKindCreator(parameterObj);
    this.setState({ roomBunrui: value, parameterObj });
  }

  render() {
    const { loading } = this.props;
    return (
      <CommonPageContainer
        id="duty-report-page"
        sessionTimeout={this.props.sessionTimeout}
      >
        <Spin spinning={loading ? loading : false}>
          <DutyReportMain
            history={this.props.history}
            selectedDate={this.state.selectedDate}
            handleChangeDate={(value) => this.handleChangeDate(value)}
            dutyReport={this.state.dutyReport ? this.state.dutyReport : null}
            handleChangeRoomBunrui={(value) =>
              this.handleChangeRoomBunrui(value)
            }
            roomBunrui={
              this.state.dutyReport
                ? this.state.dutyReport.roomBunrui
                : this.state.roomBunrui
            }
            saveDutyReport={(parameterObj) => this.saveDutyReport(parameterObj)}
            staffKind={this.props.staffKind ? this.props.staffKind : []}
          />
        </Spin>
      </CommonPageContainer>
    );
  }
}
