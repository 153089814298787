import { Form } from '@ant-design/compatible';
import React from "react";

// import '@ant-design/compatible/assets/index.css';


import { Button, Col, Input, InputNumber, Modal, Row } from "antd";

class KYMForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    visibleConfirm: false,
    isDelConfirm: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        //
        let data = {
          id: values.id ? values.id.toString() : null,

          attribute: values.attribute ? values.attribute : null,
          bunrui1: values.bunrui1 ? values.bunrui1 : null,
          bunrui2: values.bunrui2 ? values.bunrui2 : null,
          keyword: values.keyword ? values.keyword : null,
          naiyou: values.naiyou ? values.naiyou : null,
          sortNum: values.sortNum ? values.sortNum.toString() : null,
        };

        this.props.regData(data);
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("「パスワード」と「パスワード(確認)」の値が一致しません。");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleGoBack = (e) => {
    e.preventDefault();
    if (this.props.form.isFieldsTouched()) this.showGoBackConfirm();
    else this.props.history.goBack();
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    this.props.history.goBack();
  }

  delete = () => {
    this.setState({
      isDelConfirm: true,
    });
  };

  closeDelConfirm = () => {
    this.setState({
      isDelConfirm: false,
    });
  };

  okDelConfirm = () => {
    const { data } = this.props;
    this.setState({
      isDelConfirm: false,
    });

    this.props.delete(data.id);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      (<div>
        <Form
          {...formItemLayout}
          labelAlign="left"
          style={{ maxWidth: 680, margin: "0 auto" }}
        >
          <Row>
            <Col>
              <Form.Item label="ID（自動生成）">
                {getFieldDecorator("id", {
                  rules: [{ required: false }],
                })(<Input disabled />)}
              </Form.Item>

              <Form.Item label="属性">
                {getFieldDecorator("attribute", {
                  rules: [{ required: false }],
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="分類1">
                {getFieldDecorator("bunrui1", {
                  rules: [{ required: false }],
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="分類2">
                {getFieldDecorator("bunrui2", {
                  rules: [{ required: false }],
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="キーワード">
                {getFieldDecorator("keyword", {
                  rules: [{ required: false }],
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="内容">
                {getFieldDecorator("naiyou", {
                  rules: [{ required: false }],
                })(<Input.TextArea rows={4} autoComplete="new-name" />)}
              </Form.Item>

              <Form.Item label="順番">
                {getFieldDecorator("sortNum", {
                  rules: [{ required: false }],
                })(<InputNumber style={{ width: "100%" }} />)}
              </Form.Item>
            </Col>
          </Row>
          {data && data.id ? (
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" danger onClick={this.delete}>
                削除
              </Button>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                更新
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          ) : (
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 40, marginRight: 20 }}
              >
                新規登録
              </Button>
              <Button type="dashed" onClick={this.handleGoBack}>
                キャンセル
              </Button>
            </Form.Item>
          )}
        </Form>
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
        <Modal
          open={this.state.isDelConfirm}
          title="削除確認"
          onCancel={() => this.closeDelConfirm()}
          onOk={() => this.okDelConfirm()}
        >
          <span style={{ display: "block" }}>
            {"削除してもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }
}

const WrappedKYMForm = Form.create({
  name: "kanri-user-form",
  onValuesChange(props, changedValues, allValues) { },
  mapPropsToFields(props) {
    return {
      id: Form.createFormField({
        value: props.data ? props.data.id : null,
      }),
      attribute: Form.createFormField({
        value: props.data ? props.data.attribute : null,
      }),
      bunrui1: Form.createFormField({
        value: props.data ? props.data.bunrui1 : null,
      }),
      bunrui2: Form.createFormField({
        value: props.data ? props.data.bunrui2 : null,
      }),
      keyword: Form.createFormField({
        value: props.data ? props.data.keyword : null,
      }),
      naiyou: Form.createFormField({
        value: props.data ? props.data.naiyou : null,
      }),
      sortNum: Form.createFormField({
        value: props.data ? props.data.sortNum : null,
      }),
    };
  },
})(KYMForm);

export default WrappedKYMForm;
