import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import { MinusSquareOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Input, Modal, Row, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import _ from "lodash";
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import {
  getSettingMozisu,
  resetStatus,
} from "../../../actions/kyotakuPlan1Actions/actionCreators";
import TantoInput from "../../../common/component/control/TantoInput";
import { ConstSet } from "../../../common/configs/constset";
import {
  getValueLocalstorage,
  isManagement
} from "../../../common/function_common/functionCommon";
import CareTermDictModal from "../UI/CareTermDictModal";
import LastQuoteModal from "../UI/LastQuoteModal";
import RiyouKaigohokenModal from "../UI/RiyouKaigohokenModal";
dayjs.extend(dayjsPluginUTC);

// eslint-disable-next-line react/display-name
const withResize = (WrappedComponent) => (props) => {
  function autoResize() {
    const element = document.querySelector(
      `textarea[element=${props.element}]`
    );
    element.style.minHeight = "128px";
    if (!element) return;
    const offset = element.offsetHeight - element.clientHeight;
    element.style.height = "0px";
    element.style.boxSizing = "border-box";
    element.style.height = element.scrollHeight + offset + "px";
    element.addEventListener("input", function (event) {
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + offset + "px";
    });
  }

  useEffect(() => {
    autoResize();
  }, [props.value]);

  return <WrappedComponent {...props} />;
};

const TextAreaResize = withResize(Input.TextArea);

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

class KyotakuPlan1InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kaigohokenVisible: false,
      kaigohokenDisplay: "",
      careTermVisible: false,
      field: null,
      lastQuoteVisible: false,
      planDateOpen: false,
      douiDateOpen: false,
      disableRiyuHoka: !(props.data && props.data.riyu === 3),
      visibleConfirm: false,
      isFieldsTouched: false,
      checkView: false,
      ikouRowsCount: "",
      checkIkou: false,
      ikenRowsCount: "",
      checkIken: false,
      housinRowsCount: "",
      checkHousin: false,
    };
    this.documentListPath = isManagement()
      ? "/management/documents"
      : "/document-list";
    this.inputPath = isManagement()
      ? "/management/input-plan1"
      : "/input-kyotaku-plan1";
    this.reviewPath = isManagement()
      ? "/management/view-plan1"
      : "/check-info-document-list";
  }

  async componentDidMount() {
    this.props.getSettingMozisu();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { kyotakuPlan1Id } = this.props.match.params;
    if (
      this.props.riyouKaigohokens ||
      (!this.props.riyouKaigohokens && nextProps.riyouKaigohokens)
    ) {
      const riyouKaigohokenId = this.props.form.getFieldValue(
        "riyou_kaigohoken_id"
      );
      const item = riyouKaigohokenId
        ? _.find(nextProps.riyouKaigohokens, (k) => k.id == riyouKaigohokenId)
        : nextProps.riyouKaigohokens[0];
      this.setState({
        kaigohokenDisplay: item
          ? item.name +
          "　" +
          "\t有効期限：" +
          item.yukoDateFrom +
          "～" +
          item.yukoDateTo +
          "　" +
          "\tID" +
          item.id
          : "",
      });
    }
    if (this.props.data !== nextProps.data && nextProps.data) {
      if (nextProps.data.riyu === 3) {
        this.setState({
          disableRiyuHoka: false,
        });
      }
      else {
        this.setState({
          disableRiyuHoka: true,
        });
      }

      const ikouCount = this.firstCheckLength(
        "ikou",
        "利用者家族の意向",
        nextProps.data.ikou
      );
      const ikenCount = this.firstCheckLength(
        "iken",
        "介護認定審査会の意見",
        nextProps.data.iken
      );
      const housinCount = this.firstCheckLength(
        "housin",
        "総合的な援助の方針",
        nextProps.data.housin
      );
      this.setState({
        ikouRowsCount: ikouCount,
        checkIkou: ikouCount.indexOf("オーバー") > -1,
        ikenRowsCount: ikenCount,
        checkIken: ikenCount.indexOf("オーバー") > -1,
        housinRowsCount: housinCount,
        checkHousin: housinCount.indexOf("オーバー") > -1,
      });
    }
    if (
      this.state.checkView == false &&
      kyotakuPlan1Id == "new" &&
      nextProps.status !== this.props.status &&
      nextProps.status == "success"
    ) {
      this.setState({
        checkView: false,
        isFieldsTouched: false,
      });
      this.props.history.push(
        `${this.inputPath}/${nextProps.data.riyousyaId}/${nextProps.data.id}`
      );
    }
    if (
      this.state.checkView == true &&
      kyotakuPlan1Id == "new" &&
      nextProps.status !== this.props.status &&
      nextProps.status == "success"
    ) {
      this.setState({
        checkView: false,
        isFieldsTouched: false,
      });
      this.props.history.push(
        `${this.inputPath}/${nextProps.data.riyousyaId}/${nextProps.data.id}`
      );
      const win = window.open(
        `${this.reviewPath}/${nextProps.data.riyousyaId}/${nextProps.data.id}`,
        "_blank"
      );
      if (win !== null) {
        win.focus();
      }
    }
  }
  openTab(rid, id) {
    const win = window.open(`${this.reviewPath}/${rid}/${id}`, "_blank");
    win.focus();
  }
  componentDidUpdate() {
    const { status } = this.props;
    if (status !== null) {
      this.props.resetStatus();
    }
  }
  handleSelectKaigohoken(kaigohoken) {
    const item = _.find(this.props.riyouKaigohokens, (k) => k.id == kaigohoken);
    this.props.form.setFieldsValue({
      riyou_kaigohoken_id: kaigohoken,
    });
    this.setState({
      kaigohokenVisible: false,
      isFieldsTouched: true,
      kaigohokenDisplay: item
        ? item.name +
        "　" +
        "\t有効期限：" +
        item.yukoDateFrom +
        "～" +
        item.yukoDateTo +
        "　" +
        "\tID" +
        item.id
        : "",
    });
  }

  showModalKaigohoken() {
    this.setState({
      kaigohokenVisible: true,
    });
  }

  hideModalKaigohoken() {
    this.setState({
      kaigohokenVisible: false,
    });
  }

  handleSelectCareTerm(careTerm) {
    if (careTerm) {
      const { field } = this.state;
      const preValue = this.props.form.getFieldValue(field);
      const value = {};
      value[field] = _.isEmpty(preValue)
        ? careTerm
        : preValue + "、" + careTerm;
      this.props.form.setFieldsValue(value);
    }
    this.setState({
      careTermVisible: false,
      isFieldsTouched: !!careTerm,
    });
  }

  showModalCareTerm(field, linkingCareTerm) {
    this.setState({
      careTermVisible: true,
      field,
      linkingCareTerm,
    });
  }

  hideModalCareTerm() {
    this.setState({
      careTermVisible: false,
    });
  }

  handleSelectLastQuote(value) {
    if (value) {
      this.props.form.setFieldsValue({
        first_plan_date: value.firstPlanDate
          ? dayjs(value.firstPlanDate, ConstSet.DATE_FORMAT)
          : null,
        riyu1: value.riyu === 1,
        riyu2: value.riyu === 2,
        riyu3: value.riyu === 3,
        riyu_hoka: value.riyuHoka,
        ikou: value.ikou,
        iken: value.iken,
        comment: value.comment,
        housin: value.housin,
      });
      this.setState({
        lastQuoteVisible: false,
        isFieldsTouched: true,
        disableRiyuHoka: value.riyu !== 3,
      });
    }
    else {
      this.setState({
        lastQuoteVisible: false,
      });
    }
  }

  showModalLastQuote() {
    this.setState({
      lastQuoteVisible: true,
    });
  }

  hideModalLastQuote() {
    this.setState({
      lastQuoteVisible: false,
    });
  }

  handleChangeRiyu1 = (e) => {
    if (e.target.checked) {
      this.props.form.setFieldsValue({
        riyu2: false,
        riyu3: false,
      });
      this.setState({
        disableRiyuHoka: true,
      });
    }
  };

  handleChangeRiyu2 = (e) => {
    if (e.target.checked) {
      this.props.form.setFieldsValue({
        riyu1: false,
        riyu3: false,
      });
      this.setState({
        disableRiyuHoka: true,
      });
    }
  };

  handleChangeRiyu3 = (e) => {
    if (e.target.checked) {
      this.props.form.setFieldsValue({
        riyu1: false,
        riyu2: false,
      });
      this.setState({
        disableRiyuHoka: false,
      });
    }
    else {
      this.setState({
        disableRiyuHoka: true,
      });
    }
  };

  openChangeplanDate = (open) => {
    this.setState({ planDateOpen: open });
  };

  clearPlanDate = () => {
    this.props.form.setFieldsValue({
      first_plan_date: null,
    });
    this.setState({
      planDateOpen: false,
      isFieldsTouched: true,
    });
  };

  openChangeDouiDate = (open) => {
    this.setState({ douiDateOpen: open });
  };

  clearDouiDate = () => {
    this.props.form.setFieldsValue({
      plan1_doui_date: null,
    });
    this.setState({
      douiDateOpen: false,
      isFieldsTouched: true,
    });
  };

  firstCheckLength = (value, settingName, propsText) => {
    const { settingInput1 } = this.props;
    let rowText = "";
    if (settingInput1) {
      const setting = settingInput1.find((item) => {
        return item.indexOf(settingName) > -1;
      });

      if (setting) {
        const maxlength = setting.split("_")[1].split(",")[0];
        const maxrow = setting.split("_")[1].split(",")[1];
        rowText = this.checkText(propsText, maxlength, maxrow);
      }
    }
    return rowText;
  };

  checkLength = (value, settingName) => {
    const { settingInput1 } = this.props;
    if (settingInput1) {
      const setting = settingInput1.find((item) => {
        return item.indexOf(settingName) > -1;
      });

      if (setting) {
        const maxlength = setting.split("_")[1].split(",")[0];
        const maxrow = setting.split("_")[1].split(",")[1];
        const field = value;
        const checkText = this.props.form.getFieldValue(field);
        const rowText = this.checkText(checkText, maxlength, maxrow);

        switch (field) {
          case "ikou":
            this.setState({
              ikouRowsCount: rowText,
              checkIkou: rowText.indexOf("オーバー") > -1,
            });
            break;
          case "iken":
            this.setState({
              ikenRowsCount: rowText,
              checkIken: rowText.indexOf("オーバー") > -1,
            });
            break;
          case "housin":
            this.setState({
              housinRowsCount: rowText,
              checkHousin: rowText.indexOf("オーバー") > -1,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  checkText(text, maxlength, maxrow) {
    let rowText = "";
    if (text) {
      let rowsCount = 0;
      const textArray = text.split(/\r|\n/);
      for (let i = 0; i < textArray.length; i++) {
        const oneLineCount = this.strLength(textArray[i]);
        if (0 < Math.ceil(oneLineCount / maxlength)) {
          rowsCount += Math.ceil(oneLineCount / maxlength);
        }
        else {
          rowsCount += 1;
        }
      }

      if (rowsCount !== 0) {
        if (rowsCount > Number(maxrow)) {
          rowText = "行数オーバー(" + rowsCount + "行)";
        }
        else {
          rowText = "行数(" + rowsCount + "行)";
        }
      }
    }
    return rowText;
  }

  strLength(strSrc) {
    let len = 0;
    strSrc = escape(strSrc);
    for (let i = 0; i < strSrc.length; i++, len++) {
      if (strSrc.charAt(i) == "%") {
        if (strSrc.charAt(++i) == "u") {
          i += 3;
          len++;
        }
        i++;
      }
    }
    len = Math.ceil(len.toString() / 2);
    return len;
  }

  handleSave = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (values["first_plan_date"]) {
        values["first_plan_date"] = dayjs(values["first_plan_date"]).format(
          ConstSet.DATE_FORMAT
        );
      }
      this.props.regData(err, values);
    });
    this.setState({
      isFieldsTouched: false,
    });
  };
  handleSaveAndNewTab = (e) => {
    e.preventDefault();
    this.setState({
      checkView: true,
    });
    const { form, kyotakuPlan1Id } = this.props;
    form.validateFields((err, values) => {
      if (values["first_plan_date"]) {
        values["first_plan_date"] = dayjs(values["first_plan_date"]).format(
          ConstSet.DATE_FORMAT
        );
      }
      this.props.regDataAndCheck(err, values);
    });
    if (kyotakuPlan1Id !== "new") {
      const win = window.open(
        `${this.reviewPath}/${this.props.riyousyaId}/${kyotakuPlan1Id}`,
        "_blank"
      );
      win.focus();
    }
  };

  handleGoBack = (e) => {
    const { riyousyaId } = this.props;
    e.preventDefault();

    if (
      this.props.form.isFieldsTouched() ||
      this.state.isFieldsTouched ||
      this.props.valuesChange1
    )
      this.showGoBackConfirm();
    else this.props.history.push(`${this.documentListPath}/${riyousyaId}`);
  };

  showGoBackConfirm() {
    this.setState({
      visibleConfirm: true,
    });
  }

  closeGoBackConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  handleConfirmGoBack() {
    this.setState({
      visibleConfirm: false,
    });
    if (this.props.valuesChange1) {
      this.props.changeValuePlan1(false);
    }
    this.props.history.push(
      `${this.documentListPath}/${this.props.riyousyaId}`
    );
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { planDateOpen, douiDateOpen, checkIkou, checkIken, checkHousin } =
      this.state;
    const data_riyu_hoka = getFieldValue("riyu_hoka");

    const black = "rgba(0, 0, 0, 0.65)";
    const red = "red";
    let ikouOver = black;
    let ikenOver = black;
    let housinOver = black;
    if (checkIkou) {
      ikouOver = red;
    }
    if (checkIken) {
      ikenOver = red;
    }
    if (checkHousin) {
      housinOver = red;
    }
    return (
      (<div className="back">
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                (<div>
                  {this.renderHeader()}
                  <div style={{ maxWidth: "1081px", margin: "0px auto" }} className="main">
                    <Spin spinning={this.props.loading}>
                      <Form
                        layout="inline"
                        hideRequiredMark={true}
                        style={{ padding: "0 10px" }}
                      >
                        <Row style={{ paddingBottom: "10px" }}>
                          <div label={"計画作成日"} colon={false}>
                            {getFieldDecorator("plan_date")(
                              <DatePicker style={{ width: "120px" }} />
                            )}
                          </div>
                          <div label={"初回作成日"} colon={false}>
                            {getFieldDecorator("first_plan_date")(
                              <DatePicker
                                open={planDateOpen}
                                onOpenChange={this.openChangeplanDate}
                                style={{ width: "120px" }}
                                popupClassName="date-picker-new-option"
                                renderExtraFooter={() => (
                                  <div>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.clearPlanDate()}
                                    >
                                      クリア
                                    </span>
                                  </div>
                                )}
                              />
                            )}
                          </div>
                          <div>
                            {getFieldDecorator("kubun")(
                              <Checkbox.Group
                                options={[
                                  { label: "初回", value: "初回" },
                                  { label: "紹介", value: "紹介" },
                                  { label: "継続", value: "継続" },
                                ]}
                              />
                            )}
                          </div>
                          <div label={"計画作成者"} colon={false}>
                            {getFieldDecorator("tanto_id")(
                              <TantoInput
                                allowBlank
                                containerId={"input-kyotaku-plan1-page"}
                              />
                            )}
                          </div>
                          <div>
                            {getFieldDecorator("progressF")(
                              <Checkbox.Group
                                options={[
                                  { label: "最新", value: "progressF" },
                                ]}
                              />
                            )}
                          </div>
                        </Row>
                        <Row style={{ paddingBottom: "20px" }}>
                          <Col>
                            <Button
                              type={"primary"}
                              onClick={() => this.showModalLastQuote()}
                            >
                              {"前回引用"}
                            </Button>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "20px" }}>
                          <Col span={24}>
                            <div style={{ paddingBottom: "10px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                利用者及び家族の生活に対する意向
                              </span>
                              <span
                                style={{ paddingLeft: "30px", color: ikouOver }}
                              >
                                {this.state.ikouRowsCount}
                              </span>
                              <Button
                                type={"primary"}
                                icon={<LegacyIcon type={"book"} />}
                                style={{ float: "right", marginTop: "-10px" }}
                                onClick={() =>
                                  this.showModalCareTerm(
                                    "ikou",
                                    "利用者家族の意向"
                                  )
                                }
                              >
                                {"介護用語"}
                              </Button>
                            </div>
                            <div colon={false} style={{ width: "100%" }}>
                              {getFieldDecorator("ikou")(
                                <TextAreaResize
                                  element="ikou"
                                  rows={4}
                                  onKeyUp={() =>
                                    this.checkLength("ikou", "利用者家族の意向")
                                  }
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "20px" }}>
                          <Col span={24}>
                            <div style={{ paddingBottom: "10px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                介護認定審査会の意見及びサービス種類の指定
                              </span>
                              <span
                                style={{ paddingLeft: "30px", color: ikenOver }}
                              >
                                {this.state.ikenRowsCount}
                              </span>
                            </div>
                            <div colon={false} style={{ width: "100%" }}>
                              {getFieldDecorator("iken")(
                                <TextAreaResize
                                  element="iken"
                                  rows={4}
                                  style={{ width: "100%" }}
                                  onKeyUp={() =>
                                    this.checkLength(
                                      "iken",
                                      "介護認定審査会の意見"
                                    )
                                  }
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "20px" }}>
                          <Col span={24}>
                            <div style={{ paddingBottom: "10px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                総合的な援助の方針
                              </span>
                              <span
                                style={{
                                  paddingLeft: "30px",
                                  color: housinOver,
                                }}
                              >
                                {this.state.housinRowsCount}
                              </span>
                              <Button
                                type={"primary"}
                                icon={<LegacyIcon type={"book"} />}
                                style={{ float: "right", marginTop: "-10px" }}
                                onClick={() =>
                                  this.showModalCareTerm(
                                    "housin",
                                    "総合的な援助の方針"
                                  )
                                }
                              >
                                {"介護用語"}
                              </Button>
                            </div>
                            <div colon={false} style={{ width: "100%" }}>
                              {getFieldDecorator("housin")(
                                <TextAreaResize
                                  element="housin"
                                  rows={4}
                                  style={{ width: "100%" }}
                                  onKeyUp={() =>
                                    this.checkLength(
                                      "housin",
                                      "総合的な援助の方針"
                                    )
                                  }
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "10px" }}>
                          <Col span={24}>
                            {getFieldDecorator("riyu1", {
                              valuePropName: "checked",
                            })(
                              <Checkbox onChange={this.handleChangeRiyu1}>
                                {"１.一人暮らし"}
                              </Checkbox>
                            )}
                            {getFieldDecorator("riyu2", {
                              valuePropName: "checked",
                            })(
                              <Checkbox onChange={this.handleChangeRiyu2}>
                                {"２.家族等が障害、疾病等"}
                              </Checkbox>
                            )}
                            {getFieldDecorator("riyu3", {
                              valuePropName: "checked",
                            })(
                              <Checkbox onChange={this.handleChangeRiyu3}>
                                {"３.その他"}
                              </Checkbox>
                            )}
                            <div
                              style={{
                                margin: "0",
                                width: "calc(100% - 626px)",
                                display: "inline-block"
                              }}
                              className={"item-riyu-hoka"}
                            >
                              <Tooltip
                                placement="topLeft"
                                title={data_riyu_hoka ? data_riyu_hoka : null}
                              >
                                {getFieldDecorator("riyu_hoka")(
                                  <Input
                                    className={"item-riyu-hoka-text"}
                                    disabled={this.state.disableRiyuHoka}
                                  />
                                )}
                              </Tooltip>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "10px" }}>
                          <Col>
                            <Form.Item label={"同意年月日"} colon={false}>
                              {getFieldDecorator("plan1_doui_date")(
                                <DatePicker
                                  style={{ width: "120px" }}
                                  open={douiDateOpen}
                                  onOpenChange={this.openChangeDouiDate}
                                  popupClassName="date-picker-new-option"
                                  renderExtraFooter={() => (
                                    <div>
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.clearDouiDate()}
                                      >
                                        クリア
                                      </span>
                                    </div>
                                  )}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <div style={{ paddingBottom: "10px" }}>
                          <Form labelAlign="left">
                            <Form.Item
                              label={"コメント"}
                              colon={false}
                              style={{ width: "100%", display: "flex" }}
                              className={"item-comment"}
                              {...formItemLayout}
                            >
                              {getFieldDecorator("comment")(
                                <TextArea style={{ width: "100%" }} rows={4} />
                              )}
                            </Form.Item>
                          </Form>
                        </div>
                        <Row style={{ paddingBottom: "10px" }}>
                          <Col>
                            <Form>
                              <Form.Item label={"介護保険情報"} colon={false}>
                                {getFieldDecorator("riyou_kaigohoken_id")(
                                  <div>
                                    <span
                                      className={"pljp-col pljp-form-item-label"}
                                    >
                                      {this.state.kaigohokenDisplay}
                                    </span>
                                    <span>
                                      <Button
                                        type={"primary"}
                                        style={{ marginLeft: "16px" }}
                                        onClick={() => this.showModalKaigohoken()}
                                      >
                                        {"選択"}
                                      </Button>
                                    </span>
                                  </div>
                                )}
                              </Form.Item>
                            </Form>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  </div>
                </div>)
              );
            }
            return (
              (<div>
                {this.renderHeaderMobile()}
                <div style={{ margin: "20px auto" }}>
                  <Spin spinning={this.props.loading}>
                    <Form
                      layout="inline"
                      hideRequiredMark={true}
                      style={{ padding: "0 10px" }}
                    >
                      <Row style={{ paddingBottom: "10px" }}>
                        <div label={"計画作成日"} colon={false}>
                          {getFieldDecorator("plan_date")(
                            <DatePicker style={{ width: "120px" }} />
                          )}
                        </div>
                        <div style={{ float: "right" }}>
                          {getFieldDecorator("progressF")(
                            <Checkbox.Group
                              options={[{ label: "最新", value: "progressF" }]}
                            />
                          )}
                        </div>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <div label={"初回作成日"} colon={false}>
                          {getFieldDecorator("first_plan_date")(
                            <DatePicker
                              open={planDateOpen}
                              onOpenChange={this.openChangeplanDate}
                              style={{ width: "120px" }}
                              popupClassName="date-picker-new-option"
                              renderExtraFooter={() => (
                                <div>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.clearPlanDate()}
                                  >
                                    クリア
                                  </span>
                                </div>
                              )}
                            />
                          )}
                        </div>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <div>
                          {getFieldDecorator("kubun")(
                            <Checkbox.Group
                              options={[
                                { label: "初回", value: "初回" },
                                { label: "紹介", value: "紹介" },
                                { label: "継続", value: "継続" },
                              ]}
                            />
                          )}
                        </div>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <div label={"計画作成者"} colon={false}>
                          {getFieldDecorator("tanto_id")(
                            <TantoInput
                              allowBlank
                              containerId={"input-kyotaku-plan1-page"}
                            />
                          )}
                        </div>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <Col>
                          <Button
                            type={"primary"}
                            onClick={() => this.showModalLastQuote()}
                          >
                            {"前回引用"}
                          </Button>
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <Col span={24}>
                          <Button
                            type={"primary"}
                            icon={<LegacyIcon type={"book"} />}
                            className={"kaigo-button"}
                            onClick={() =>
                              this.showModalCareTerm("ikou", "利用者家族の意向")
                            }
                          >
                            {"介護用語"}
                          </Button>
                          <div
                            label={"利用者及び家族の生活に対する意向"}
                            colon={false}
                            style={{ width: "100%" }}
                          >
                            {getFieldDecorator("ikou")(
                              <TextAreaResize element="ikou" rows={4} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <Col span={24}>
                          <div
                            label={"介護認定審査会の意見及びサービス種類の指定"}
                            colon={false}
                            style={{ width: "100%" }}
                          >
                            {getFieldDecorator("iken")(
                              <TextAreaResize
                                element="iken"
                                rows={4}
                                style={{ width: "100%" }}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <Col span={24}>
                          <Button
                            type={"primary"}
                            icon={<LegacyIcon type={"book"} />}
                            className={"kaigo-button"}
                            onClick={() =>
                              this.showModalCareTerm(
                                "housin",
                                "総合的な援助の方針"
                              )
                            }
                          >
                            {"介護用語"}
                          </Button>
                          <div
                            label={"総合的な援助の方針"}
                            colon={false}
                            style={{ width: "100%" }}
                          >
                            {getFieldDecorator("housin")(
                              <TextAreaResize
                                element="housin"
                                rows={4}
                                style={{ width: "100%" }}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <Col span={24}>
                          <div
                            label={"生活支援中心型の算定理由"}
                            colon={false}
                          >
                            {getFieldDecorator("riyu1", {
                              valuePropName: "checked",
                            })(
                              <Checkbox onChange={this.handleChangeRiyu1}>
                                {"１.一人暮らし"}
                              </Checkbox>
                            )}
                          </div>
                          <div>
                            {getFieldDecorator("riyu2", {
                              valuePropName: "checked",
                            })(
                              <Checkbox onChange={this.handleChangeRiyu2}>
                                {"２.家族等が障害、疾病等"}
                              </Checkbox>
                            )}
                          </div>
                          <div>
                            {getFieldDecorator("riyu3", {
                              valuePropName: "checked",
                            })(
                              <Checkbox onChange={this.handleChangeRiyu3}>
                                {"３.その他"}
                              </Checkbox>
                            )}
                          </div>
                          <div style={{ width: "100%" }}>
                            <Tooltip
                              placement="topLeft"
                              title={data_riyu_hoka ? data_riyu_hoka : null}
                            >
                              {getFieldDecorator("riyu_hoka")(
                                <Input.TextArea
                                  disabled={this.state.disableRiyuHoka}
                                />
                              )}
                            </Tooltip>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <div label={"同意年月日"} colon={false}>
                          {getFieldDecorator("plan1_doui_date")(
                            <DatePicker
                              open={douiDateOpen}
                              onOpenChange={this.openChangeDouiDate}
                              style={{ width: "120px" }}
                              popupClassName="date-picker-new-option"
                              renderExtraFooter={() => (
                                <div>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.clearDouiDate()}
                                  >
                                    クリア
                                  </span>
                                </div>
                              )}
                            />
                          )}
                        </div>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <Col span={24}>
                          <div
                            label={"コメント"}
                            colon={false}
                            style={{ width: "100%" }}
                          >
                            {getFieldDecorator("comment")(<Input.TextArea />)}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <Col>
                          <div label={"介護保険情報"} colon={false}>
                            {getFieldDecorator("riyou_kaigohoken_id")(
                              <Button
                                type={"primary"}
                                onClick={() => this.showModalKaigohoken()}
                              >
                                {"選択"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: "10px" }}>
                        <div style={{ display: "inline-block" }}>
                          {this.state.kaigohokenDisplay}
                        </div>
                      </Row>
                    </Form>
                  </Spin>
                </div>
                {this.renderFooterMobile()}
              </div>)
            );
          }}
        </MediaQuery>
        <RiyouKaigohokenModal
          selected={this.props.form.getFieldValue("riyou_kaigohoken_id")}
          visible={this.state.kaigohokenVisible}
          handleSelectKaigohoken={(data) => this.handleSelectKaigohoken(data)}
          hideModalKaigohoken={() => this.hideModalKaigohoken()}
        />
        <CareTermDictModal
          visible={this.state.careTermVisible}
          linkingCareTerm={this.state.linkingCareTerm}
          field={this.state.field}
          handleSelectCareTerm={(data) => this.handleSelectCareTerm(data)}
          hideModalCareTerm={() => this.hideModalCareTerm()}
          showModalCareTerm={(field, linkingCareTerm) =>
            this.showModalCareTerm(field, linkingCareTerm)
          }
        />
        <LastQuoteModal
          visible={this.state.lastQuoteVisible}
          title={"前回引用"}
          riyousyaId={this.props.riyousyaId}
          kyotakuPlan1Id={this.props.kyotakuPlan1Id}
          tab={"1"}
          handleSelectLastQuote={(data) => this.handleSelectLastQuote(data)}
          hideModalLastQuote={() => this.hideModalLastQuote()}
        />
        <Modal
          open={this.state.visibleConfirm}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }

  renderHeader() {
    return (
      <Row className={"submenu fixed-header"} style={{ display: "flex" }}>
        <Col span={12} className={"sm-c-left"}>
          <span style={{ display: "block", fontWeight: "bold" }}>
            <h3 style={{ color: "white" }}>{"【計画書１】"}</h3>
          </span>
          <span style={{ display: "block", color: "white" }}>
            <span>利用者</span>
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {this.props.riyousya ? this.props.riyousya.name : ""}
            </span>
          </span>
        </Col>
        <Col span={12} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <Link to={"/"} onClick={this.handleSave}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{ display: "block", fontWeight: "bold", color: "red" }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </Link>
          {this.props.kyotakuPlan1Id !== "new" ? (
            <Link to={"/"} onClick={this.handleSaveAndNewTab}>
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          ) : null}
        </Col>
      </Row>
    );
  }

  renderHeaderMobile() {
  }

  renderFooterMobile() {
    return (
      <Row className={"submenu fixed-footer"}>
        <Col span={24} className={"sm-c-right"}>
          <Link
            to={"/"}
            onClick={this.handleGoBack}
            style={{ color: "white", textAlign: "center", float: "right" }}
          >
            <span style={{ display: "block", fontWeight: "bold" }}>
              <UndoOutlined />
            </span>
            <span>{"戻る"}</span>
          </Link>
          <Link to={"/"} onClick={this.handleSave}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <span
                style={{
                  display: "block",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                <SaveOutlined />
              </span>
              <span>{"登録"}</span>
            </span>
          </Link>
          {this.props.kyotakuPlan1Id !== "new" ? (
            <Link to={"/"} id={"checkBtn"} onClick={this.handleSaveAndNewTab}>
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  <MinusSquareOutlined />
                </span>
                <span>{"閲覧"}</span>
              </span>
            </Link>
          ) : null}
        </Col>
      </Row>
    );
  }
}

const KyotakuPlan1InputFormWrapper = Form.create({
  name: "input-kyotaku-plan-1",
  // onFieldsChange(props, changedFields) {
  //     props.onChange(changedFields);
  // },
  mapPropsToFields(props) {
    const { copyFrom } = props;
    const defaultKaigohoken = !_.isEmpty(props.riyouKaigohokens)
      ? props.riyouKaigohokens[0].id
      : null;
    return {
      riyou_kaigohoken_id: Form.createFormField({
        value:
          props.data && !copyFrom
            ? props.data.riyouKaigohokenId
            : defaultKaigohoken,
      }),
      progressF: Form.createFormField({
        value:
          props.data && !copyFrom && props.data.progressF != 1
            ? []
            : ["progressF"],
      }),
      first_plan_date: Form.createFormField({
        value:
          props.data && props.data.firstPlanDate
            ? dayjs.utc(props.data.firstPlanDate, ConstSet.DATE_FORMAT)
            : null,
      }),
      plan_date: Form.createFormField({
        value:
          props.data && !copyFrom && props.data.planDate
            ? dayjs.utc(props.data.planDate, ConstSet.DATE_FORMAT)
            : dayjs(),
      }),
      plan1_doui_date: Form.createFormField({
        value:
          props.data && !copyFrom && props.data.plan1DouiDate
            ? dayjs.utc(props.data.plan1DouiDate, ConstSet.DATE_FORMAT)
            : null,
      }),
      kubun: Form.createFormField({
        value:
          props.data && props.data.kubun ? props.data.kubun.match(/.{2}/g) : [],
      }),
      tanto_id: Form.createFormField({
        value:
          props.kyotakuPlan1Id === "new"
            ? parseInt(getValueLocalstorage("tid"))
            : props.data && props.data.tantoId,
      }),
      riyu1: Form.createFormField({
        value: props.data && props.data.riyu === 1,
      }),
      riyu2: Form.createFormField({
        value: props.data && props.data.riyu === 2,
      }),
      riyu3: Form.createFormField({
        value: props.data && props.data.riyu === 3,
      }),
      riyu_hoka: Form.createFormField({
        value: props.data ? props.data.riyuHoka : null,
      }),
      ikou: Form.createFormField({
        value: props.data ? props.data.ikou : null,
      }),
      iken: Form.createFormField({
        value: props.data ? props.data.iken : null,
      }),
      comment: Form.createFormField({
        value: props.data ? props.data.comment : null,
      }),
      housin: Form.createFormField({
        value: props.data ? props.data.housin : null,
      }),
    };
  },
  // onValuesChange(_, values) {
  //     console.log(values);
  // },
})(KyotakuPlan1InputForm);
const mapStateToProps = (state) => {
  return {
    dataPlan1: state.documentList.dataPlan1,
    status: state.kyotakuPlan1.status,
    settingInput1: state.kyotakuPlan1.settingInput1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetStatus: () => dispatch(resetStatus()),
    getSettingMozisu: () => dispatch(getSettingMozisu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KyotakuPlan1InputFormWrapper);
