import "whatwg-fetch";
import axios from "axios";
import { apiUrls } from "../common/configs/common-url";
import {
  localStorageClearCusLogout,
  checkLogin,
  getPath,
} from "../common/function_common/functionCommon";

let LogoutApi = {
  logout(isLoginPage) {
    const path = getPath();
    return axios(apiUrls.INIT + apiUrls.LOGOUT, {
      method: "GET",
    })
      .then((res) => {
        localStorageClearCusLogout();
        if (isLoginPage) {
          /**
           * redirect to login page
           */
          window.location.replace(path + "/login");
        } else {
          return res.data;
        }
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          window.location.reload();
        } else {
          // Check Token Exist
          if (err.response.status === 403) {
            checkLogin();
          }

          return err;
        }
      });
  },
};
export default LogoutApi;
