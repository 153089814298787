import { NeedsItemApi } from "../../../api/needsItem";
import { ConfigName } from "../../../config-name";
import { apiUrls } from "../../common/configs/common-url";
import { ApiPaths } from "../../constants";
import { RequestUtils, StorageUtils } from "../../utils";
import { ErrorActionTypes, RihabiriActionTypes } from "../action_types";

function getRehaAssessAdlItemAnswerNaiyou() {
  return async (dispatch) => {
    try {
      const serviceKikanMasterId = JSON.parse(
        localStorage.getItem(ConfigName.SWLIFE_MENU)
      ).si;
      const data = await RequestUtils.callApi({
        method: "GET",
        path:
          ApiPaths.Rihabiri.GetRehaAssessAdlItemAnswerNaiyou +
          `?serviceKikanMasterId=${parseInt(serviceKikanMasterId)}`,
      });
      dispatch({
        type: RihabiriActionTypes.REHA_ASSESS_ADL_ITEM_ANSWER_NAIYOU,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
      });
    }
  };
}

function getRehaAssessAdlItemAdl() {
  return async (dispatch) => {
    try {
      const serviceKikanMasterId = JSON.parse(
        localStorage.getItem(ConfigName.SWLIFE_MENU)
      ).si;
      const data = await RequestUtils.callApi({
        method: "GET",
        path:
          ApiPaths.Rihabiri.GetRehaAssessAdlItemAdl +
          `?serviceKikanMasterId=${parseInt(serviceKikanMasterId)}`,
      });
      dispatch({
        type: RihabiriActionTypes.REHA_ASSESS_ADL_ITEM_ADL,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
      });
    }
  };
}

function getRehaAssessAdlItemKanKyou(bunrui) {
  return async (dispatch) => {
    try {
      const serviceKikanMasterId = JSON.parse(
        localStorage.getItem(ConfigName.SWLIFE_MENU)
      ).si;
      const data = await RequestUtils.callApi({
        method: "GET",
        path:
          ApiPaths.Rihabiri.GetRehaAssessAdlItemAdl +
          `?serviceKikanMasterId=${parseInt(
            serviceKikanMasterId
          )}&bunrui=${bunrui}`,
        params: {
          serviceKikanMasterId: parseInt(serviceKikanMasterId),
          bunrui,
        },
      });
      dispatch({
        type: RihabiriActionTypes.REHA_ASSESS_ADL_ITEM_KANKYOU,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
      });
    }
  };
}

function getRehaAssessIADL(bunrui) {
  return async (dispatch) => {
    try {
      const serviceKikanMasterId = JSON.parse(
        localStorage.getItem(ConfigName.SWLIFE_MENU)
      ).si;
      const data = await RequestUtils.callApi({
        method: "GET",
        path:
          ApiPaths.Rihabiri.GetRehaAssessAdlItemAdl +
          `?serviceKikanMasterId=${parseInt(
            serviceKikanMasterId
          )}&bunrui=${bunrui}`,
        params: {
          serviceKikanMasterId: parseInt(serviceKikanMasterId),
          bunrui,
        },
      });
      dispatch({
        type: RihabiriActionTypes.REHA_ASSESS_IADL_ITEM,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
      });
    }
  };
}

function getRehaAssessMMSE(bunrui) {
  return async (dispatch) => {
    try {
      const serviceKikanMasterId = JSON.parse(
        localStorage.getItem(ConfigName.SWLIFE_MENU)
      ).si;
      const data = await RequestUtils.callApi({
        method: "GET",
        path:
          ApiPaths.Rihabiri.GetRehaAssessAdlItemAdl +
          `?serviceKikanMasterId=${parseInt(
            serviceKikanMasterId
          )}&bunrui=${bunrui}`,
        params: {
          serviceKikanMasterId: parseInt(serviceKikanMasterId),
          bunrui,
        },
      });
      dispatch({
        type: RihabiriActionTypes.REHA_ASSESS_MMSE_ITEM,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
      });
    }
  };
}

function loadRehabiliAssessmentById(id) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.Rihabiri.loadRehabiliAssessmentById + `/${id}`,
      });
      dispatch({
        type: RihabiriActionTypes.LOAD_RIHABIKIASSESSMENTBYID,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteRPSDel(id, callback) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "DELETE",
        path: apiUrls.INIT + apiUrls.RIHABILI_LOAD_LIFE_RPSDEL + id,
      });

      if (callback && typeof callback === "function") {
        return callback(data);
      }

      dispatch({
        type: RihabiriActionTypes.RIHABILI_RPSDEL_LIFE,
        data,
      });
    }
    catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

export function getMediSyoubyoumeiRiha(text) {
  return async (dispatch) => {
    try {
      const query = { text };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetMediSyoubyoumei,
        query,
      });
      dispatch({
        type: RihabiriActionTypes.GET_COMMON_MEDISYOUBYOUMEI_MASTER1,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getIcfMasterForDis(typeIcfCd, mokuhyou, textSearch, callback) {
  return async (dispatch) => {
    try {
      const query = {
        typeIcfCd,
        mokuhyou,
        textSearch,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getIcfMaster,
        query,
      });

      dispatch({
        type: RihabiriActionTypes.GET_ICF_MASTER_SUCESS_FORDIS,
        data,
      });

      if (callback && typeof callback === "function") {
        callback();
      }
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

export function getRiyouByourekiRiha(riyousyaId) {
  return async (dispatch) => {
    try {
      const query = { riyousyaId };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.LifeList.GetRiyouByoureki,
        query,
      });
      dispatch({
        type: RihabiriActionTypes.GET_COMMON_RIYOUBYOUREKI_MASTER1,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function loadSaiShinRecord(id, riyousyaId) {
  return async (dispatch) => {
    try {
      const sid = JSON.parse(localStorage.getItem(ConfigName.SWLIFE_MENU)).si;

      const query = {
        id,
        riyousyaId: parseInt(riyousyaId),
        sid: parseInt(sid),
      };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.Rihabiri.loadSaiShinRecord,
        query,
      });
      dispatch({
        type: RihabiriActionTypes.GET_RIHABIRI_SAISHIN_RECORD,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: RihabiriActionTypes.GET_RIHABIRI_SAISHIN_RECORD,
        data: [],
      });
    }
  };
}

function loadNeedsItemForDis() {
  const sid = JSON.parse(localStorage.getItem(ConfigName.SWLIFE_MENU)).si;
  return (dispatch) => {
    NeedsItemApi.list(sid).then(
      (data) => {
        dispatch({
          type: RihabiriActionTypes.LOAD_NEEDS_ITEM_SUCCESS_FORDIS,
          data,
        });
      },
      (err) => {
        console.log(err);
        dispatch({
          type: ErrorActionTypes.SET_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function loadSienNaiyoForDis(saiKomoku) {
  return async (dispatch) => {
    try {
      // Call API
      const query = { saiKomoku };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getSienNaiyoMaster,
        query,
      });

      dispatch({
        type: RihabiriActionTypes.GET_SIENNAIYO_MASTER_SUCESS_FORDIS,
        data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getSettingMaster(settingName, callback) {
  return async (dispatch) => {
    try {
      // Call API
      const query = { settingName };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getSettingMaster,
        query,
      });

      dispatch({
        type: RihabiriActionTypes.GET_SETTING_MASTER_SUCESS_CATE,
        data,
      });
      callback(data);
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListRihabiliToQuote(riyousyaId, id) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = { riyousyaId, id, systemControlName: menu.scn, systemControlId: menu.si };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.Rihabiri.getListRihabiliToQuote}`,
        query,
      });
      dispatch({
        type: RihabiriActionTypes.LOAD_REHABILI_TO_QUOTE_SUCCESS,
        data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getYongoMaster(listName, callback) {
  return async (dispatch) => {
    try {
      // Call API
      const query = { listName };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getYongoMaster,
        query,
      });

      dispatch({
        type: RihabiriActionTypes.GET_YOUGO_MASTER_SUCESS_CATE,
        data,
      });

      callback(data);
    }
    catch (error) {
      // console.log(error);
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getLifeYougoMaster() {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Rihabiri.GetRihabiliLifeYougo,
      });
      dispatch({
        type: RihabiriActionTypes.GET_RIHABILI_LIFE_YOUGO_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getFirstLoadData(riyousyaId, fdayMonth, ldayMonth) {
  return async (dispatch) => {
    try {
      const query = {
        riyousyaId,
        fdayMonth,
        ldayMonth,
      }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.Rihabiri.getFirstLoadData,
        query,
      })

      dispatch({ type: RihabiriActionTypes.GET_FIRST_LOAD_DATA, data });
    } catch (error) {
      dispatch({ type: RihabiriActionTypes.GET_FIRST_LOAD_DATA_ERROR });
      dispatch({ type: ErrorActionTypes.SET_ERROR, error})
    }
  }
}


export const RihabiriActions = {
  getYongoMaster,
  getListRihabiliToQuote,
  getSettingMaster,
  loadSienNaiyoForDis,
  loadNeedsItemForDis,
  getIcfMasterForDis,
  loadSaiShinRecord,
  getRehaAssessAdlItemAnswerNaiyou,
  getRehaAssessAdlItemAdl,
  getRehaAssessAdlItemKanKyou,
  getRehaAssessIADL,
  loadRehabiliAssessmentById,
  getRehaAssessMMSE,
  deleteRPSDel,
  getLifeYougoMaster,
  getFirstLoadData,
};
