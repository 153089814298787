import {
  GET_MEDISYOUBYOUMEI,
  GET_MEDISYOUBYOUMEI_ERROR,
  GET_MEDISYOUBYOUMEI_MASTER,
  GET_MEDISYOUBYOUMEI_MASTER_ERROR,
  MEDI_SYOUBYOUMEI_SPIN_REQUEST,
} from "./actionName";

import { MediSyoubyoumeiApi } from "../../api/mediSyoubyoumeiMaster";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

export function getMediSyoubyoumei(text) {
  return (dispatch) => {
    dispatch({
      type: MEDI_SYOUBYOUMEI_SPIN_REQUEST,
    });
    MediSyoubyoumeiApi.loadMediSyoubyoumeiMaster(text).then(
      (data) => {
        dispatch({
          type: GET_MEDISYOUBYOUMEI_MASTER,
          data: data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: GET_MEDISYOUBYOUMEI_MASTER_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function getMediSyoubyoumeiByCode(code, callback) {
  return (dispatch) => {
    dispatch({
      type: MEDI_SYOUBYOUMEI_SPIN_REQUEST,
    });
    MediSyoubyoumeiApi.loadMediSyoubyoumeiMasterByCode(code).then(
      (data) => {
        dispatch({
          type: GET_MEDISYOUBYOUMEI,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: GET_MEDISYOUBYOUMEI_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
