import { KokuActionTypes } from "../action_types";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

function koku(state = {}, action) {
  switch (action.type) {
    case KokuActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case KokuActionTypes.KOKU_LIFE_YOUGO_SUCCESS: {
      return {
        ...state,
        loading: false,
        kokuLifeYougo: action.data,
      };
    }
    case KokuActionTypes.KOKU_LIFE_YOUGO_ERROR: {
      return {
        ...state,
        loading: false,
        kokuLifeYougo: null,
      };
    }

    case KokuActionTypes.LOAD_KOKU_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case KokuActionTypes.LOAD_KOKU_ERROR: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    case KokuActionTypes.SAVE_KOKU_SIEN_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case KokuActionTypes.SAVE_KOKU_SIEN_ERROR: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    case KokuActionTypes.LOAD_KOKU_TO_QUOTE_SUCCESS: {
      return {
        ...state,
        kokuDetails: action.data,
      };
    }

    case KokuActionTypes.LOAD_KOKU_TO_QUOTE:
      openNotificationWithIcon("success", "前回引用を実施しました");
      return {
        ...state,
        data: action.data,
      };

    case KokuActionTypes.SET_NEW_DATA:
      return {
        ...state,
        data: null,
      };

    case KokuActionTypes.GET_LIST_IDS_KOKU: {
      return {
        ...state,
        loading: false,
        kokuIds: action.ids,
      };
    }

    default: {
      return { ...state };
    }
  }
}

export default koku;
