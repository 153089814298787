import React, { Component } from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Row, Col } from "antd";
import _ from "lodash";

import TextAreaSelectSimple from "./TextAreaSelectSimple";

class FormStatusNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      setFSOtherTitle,
      joukyouList1,
      joukyouList2,
      joukyouList3,
      joukyouList4,
      joukyouList5,
      joukyouList6,
      joukyouList7,
      joukyouList8,
      joukyouList9,
      joukyouList10,
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },

      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {setFSOtherTitle
          ? setFSOtherTitle
              .toString()
              .split(";")
              .map((item, index) => {
                return (
                  <Row className="rdu-tab">
                    <Col sm={6} md={6} className="rdu-tab-title">
                      <span>{item}</span>
                    </Col>
                    <Col sm={18} md={18} className="rdu-tab-content">
                      {getFieldDecorator(`hokaJokyo${index + 1}`, {
                        rules: [{ required: false }],
                      })(
                        <TextAreaSelectSimple
                          dataSelect={
                            index + 1 === 1
                              ? joukyouList1
                              : index + 1 === 2
                              ? joukyouList2
                              : index + 1 === 3
                              ? joukyouList3
                              : index + 1 === 4
                              ? joukyouList4
                              : index + 1 === 5
                              ? joukyouList5
                              : index + 1 === 6
                              ? joukyouList6
                              : index + 1 === 7
                              ? joukyouList7
                              : index + 1 === 8
                              ? joukyouList8
                              : index + 1 === 9
                              ? joukyouList9
                              : index + 1 === 10
                              ? joukyouList10
                              : null
                          }
                        />
                      )}
                    </Col>
                  </Row>
                );
              })
          : null}
      </Form>
    );
  }
}

export const FormStatus = Form.create({
  name: "input-riyou-status",
  onValuesChange(props, changedValues, allValues) {
    // @TODO convert checkbox group to value
    props.saveOnchangeToState(allValues, "fStatus", "");
  },
  mapPropsToFields(props) {
    return {
      hokaJokyo1: Form.createFormField({
        value: props.data ? props.data.hokaJokyo1 : null,
      }),
      hokaJokyo2: Form.createFormField({
        value: props.data ? props.data.hokaJokyo2 : null,
      }),
      hokaJokyo3: Form.createFormField({
        value: props.data ? props.data.hokaJokyo3 : null,
      }),
      hokaJokyo4: Form.createFormField({
        value: props.data ? props.data.hokaJokyo4 : null,
      }),
      hokaJokyo5: Form.createFormField({
        value: props.data ? props.data.hokaJokyo5 : null,
      }),
      hokaJokyo6: Form.createFormField({
        value: props.data ? props.data.hokaJokyo6 : null,
      }),
      hokaJokyo7: Form.createFormField({
        value: props.data ? props.data.hokaJokyo7 : null,
      }),
      hokaJokyo8: Form.createFormField({
        value: props.data ? props.data.hokaJokyo8 : null,
      }),
      hokaJokyo9: Form.createFormField({
        value: props.data ? props.data.hokaJokyo9 : null,
      }),
      hokaJokyo10: Form.createFormField({
        value: props.data ? props.data.hokaJokyo10 : null,
      }),
    };
  },
})(FormStatusNormal);
