import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { ConfigName } from "../../../config-name";
import { StorageUtils } from "../../utils";
import rootReducer from "../reducers";
import initialStates from "./initial_states";
import middlewares from "./middlewares";

const getStorageStates = () => {
  let states = {};
  const authlife = StorageUtils.getValue(ConfigName.SWLIFE_AUTH);

  if (authlife) {
    states = { ...states, authlife };
  }

  return states;
};

const storageStates = getStorageStates();
const preloadedStates = { ...initialStates, ...storageStates };
const store = createStore(
  rootReducer,
  preloadedStates,
  applyMiddleware(...middlewares)
);

export default store;
