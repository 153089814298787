/* eslint-disable brace-style */
/* eslint-disable indent */
import dayjs from "dayjs";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { CommonUtils, StorageUtils } from "../../utils/index";
import { inquiryRequestActionTypes } from "../action_types";

function inquiryRequest(state = {}, action) {
  switch (action.type) {
    case inquiryRequestActionTypes.GET_DATA_INQUIRY_REQUEST:
      return { ...state, loadingData: true };
    case inquiryRequestActionTypes.GET_SYOUKAI_DATE:
    case inquiryRequestActionTypes.GET_TANTO_MASTER:
    case inquiryRequestActionTypes.GET_SERVICE_KIKAN:
    case inquiryRequestActionTypes.GET_YOUGO_MASTER:
    case inquiryRequestActionTypes.GET_SERVICE_SYURUI_MASTER:
    case inquiryRequestActionTypes.GET_IDS_REQUEST:
      return { ...state };
    case inquiryRequestActionTypes.GET_YOUGO_MASTER_SUCCESS:
    case inquiryRequestActionTypes.SAVE_YOUGO_MASTER_SUCCESS:
      return {
        ...state,
        yougoNaiyou: action.naiyou
          ? action.naiyou.split(";").map((v, i) => {
            return {
              key: i,
              naiyou: v,
              editable: false,
            };
          })
          : [],
      };
    case inquiryRequestActionTypes.GET_DATA_INQUIRY_SUCCESS:
      return {
        ...state,
        dataInquiryRequest: action.dataInquiryRequest,
        syoukaiIraiDetailList: action.dataInquiryRequest.syoukaiIraiDetailList,
        loadingData: false,
      };
    case inquiryRequestActionTypes.GET_SYOUKAI_DATE_SUCCESS:
      return { ...state, SyoukiaDate: action.SyoukiaDate.result };
    case inquiryRequestActionTypes.GET_SERVICE_KIKAN_SUCCESS:
      return { ...state, serviceKikan: action.serviceKikan };
    case inquiryRequestActionTypes.GET_TANTO_MASTER_SUCCESS:
      return { ...state, tantoMaster: action.tantoMaster };
    case inquiryRequestActionTypes.GET_SERVICE_SYURUI_MASTER_SUCCESS:
      return { ...state, serviceSyuruiMaster: action.serviceSyuruiMaster };
    case inquiryRequestActionTypes.GET_SERVICE_KIKAN_ERROR:
    case inquiryRequestActionTypes.GET_TANTO_MASTER_ERROR:
    case inquiryRequestActionTypes.GET_YOUGO_MASTER_ERROR:
    case inquiryRequestActionTypes.SAVE_YOUGO_MASTER_ERROR:
    case inquiryRequestActionTypes.GET_SERVICE_SYURUI_MASTER_ERROR:
    case inquiryRequestActionTypes.GET_SYOUKAI_DATE_ERROR:
    case inquiryRequestActionTypes.GET_IDS_ERROR:
      return { ...state };
    case inquiryRequestActionTypes.GET_DATA_INQUIRY_ERROR:
      return { ...state, loadingData: false };
    case inquiryRequestActionTypes.HOME_YOUGO_EDIT_STATE:
      return {
        ...state,
        yougoNaiyou: state.yougoNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = true;
          } else {
            item.editable = false;
          }
          return item;
        }),
      };
    case inquiryRequestActionTypes.HOME_YOUGO_CANCEL_STATE:
      return {
        ...state,
        yougoNaiyou: state.yougoNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = false;
          }
          return item;
        }),
      };
    case inquiryRequestActionTypes.ADD_RECORD:
      return {
        ...state,
        syoukaiIraiDetailList: [...state.syoukaiIraiDetailList, action.record],
      };
    case inquiryRequestActionTypes.DELETE_RECORD:
      return {
        ...state,
        syoukaiIraiDetailList: state.syoukaiIraiDetailList.filter(
          (item, index) => index !== action.index
        ),
      };
    case inquiryRequestActionTypes.UPDATE_DATA: {
      const { value, types, tableIndex } = action;
      return {
        ...state,
        syoukaiIraiDetailList: state.syoukaiIraiDetailList.map(
          (item, index) => {
            if (index === tableIndex) {
              item[types] = value;
            }
            return item;
          }
        ),
      };
    }
    case inquiryRequestActionTypes.SAVE_UPDATE_DATA_INQUIRY_REQUEST:
      return { ...state, loadingData: true };
    case inquiryRequestActionTypes.SAVE_UPDATE_DATA_INQUIRY_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return { ...state, loadingData: false };
    case inquiryRequestActionTypes.SAVE_UPDATE_DATA_INQUIRY_ERROR:
      return { ...state, loadingData: false };
    case inquiryRequestActionTypes.GET_IDS_SUCCESS:
      return { ...state, idsInquiRequest: action.idsInquiRequest };

    case inquiryRequestActionTypes.GET_LIST_INQUIRY_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case inquiryRequestActionTypes.GET_LIST_INQUIRY_SUCCESS:
      return {
        ...state,
        loadingData: false,
        dataList: action.dataList.data || [],
        metaDataList: action.dataList.meta || {},
        reload: false,
      };
    case inquiryRequestActionTypes.GET_LIST_INQUIRY_FAILURE:
      return {
        ...state,
        loadingData: false,
        dataList: [],
        metaDataList: {},
      };
    case inquiryRequestActionTypes.DELETE_INQUIRY_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case inquiryRequestActionTypes.DELETE_INQUIRY_SUCCESS:
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loadingData: false,
        dataDelete: action.dataDelete,
        reload: true,
      };
    case inquiryRequestActionTypes.DELETE_INQUIRY_FAILURE:
      CommonUtils.openNotificationWithIcon("error", "action.error.message");
      return {
        ...state,
        loadingData: false,
      };
    case inquiryRequestActionTypes.SET_NEW_DATA:
      return {
        ...state,
        dataInquiryRequest: {
          id: null,
          date: new Date(),
          syoukaiDate: new Date(),
          tantoId: parseInt(StorageUtils.getValue("swpspsMNGT").tid),
          syoukaiIraiDetailList: [],
          riyu: "",
        },
        syoukaiIraiDetailList: [],
        loadingData: false,
      };
    case inquiryRequestActionTypes.SAVE_CREATE_DATA_INQUIRY_REQUEST:
      return { ...state, loadingData: true };
    case inquiryRequestActionTypes.SAVE_CREATE_DATA_INQUIRY_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        dataInquiryRequest: action.dataInquiryRequest,
        syoukaiIraiDetailList: action.dataInquiryRequest.syoukaiIraiDetailList,
        loadingData: false,
      };
    case inquiryRequestActionTypes.SAVE_CREATE_DATA_INQUIRY_ERROR:
      return { ...state, loadingData: false };
    case inquiryRequestActionTypes.CLONE_DATA:
      action.value.id = state.dataInquiryRequest.id;
      return {
        ...state,
        dataInquiryRequest: { ...action.value },
        syoukaiIraiDetailList: action.value.syoukaiIraiDetailList,
      };
    case inquiryRequestActionTypes.CLONE_DATA_INQUIRY_REQUEST: {
      return { ...state, loadingData: true };
    }
    case inquiryRequestActionTypes.CLONE_DATA_INQUIRY_SUCCESS: {
      action.dataInquiryRequest.id = null;
      action.dataInquiryRequest.date = dayjs(new Date()).format("YYYY-MM-DD");
      action.dataInquiryRequest.tantoId = parseInt(
        StorageUtils.getValue("swpspsMNGT").tid
      );
      return {
        ...state,
        dataInquiryRequest: action.dataInquiryRequest,
        syoukaiIraiDetailList: action.dataInquiryRequest.syoukaiIraiDetailList,
        loadingData: false,
      };
    }
    case inquiryRequestActionTypes.CLONE_DATA_INQUIRY_ERROR: {
      return { ...state, loadingData: false };
    }
    default:
      return { ...state };
  }
}

export default inquiryRequest;
