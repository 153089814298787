/* eslint-disable brace-style */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Input, Button, Table, Modal, Row, Select, Col } from "antd";
import { connect } from "react-redux";

//action
import { ServiceKikanMasterActions } from "../../redux/actions/index";
import ServiceKikanMasterModal from "../Common/ServiceKikanMasterModal";

function ServiceKikanRental(props) {
  const ADD = 1;
  const EDIT = 2;
  const DUPLICATE = 3;
  const [name, setName] = useState("");
  const [record, setRecord] = useState(null);
  const [rowActive, setRowActive] = useState("");

  const [sid, setSid] = useState(null);
  const [serviceKikanMasterVisible, setServiceKikanMasterVisible] = useState(false);
  const [modeModify, setModeModify] = useState();
  const [pagination, setPagination] = useState();

  useEffect(() => {
    if (props.serviceKikan.length > 0) {
      props.serviceKikan.find((e) => e.jigyousyoNo === props.jigyousyoNoServiceKikan) &&
        setRowActive(props.serviceKikan.find((e) => e.jigyousyoNo === props.jigyousyoNoServiceKikan).id);
    }
  }, [props.serviceKikan]);

  useEffect(() => {
    if (props.visible) {
      props.getListBySyuruiCd(props.syuruiCd, name, (data) => {});
      setPagination({ pageSize: 10, current: 1 });
    }
  }, [props.visible]);

  useEffect(() => {
    if (props.isReload && props.visible) {
      props.getListBySyuruiCd(props.syuruiCd, name, (data) => {});
      props.turnOffReload();
    }
  }, [props.isReload]);

  const handleCancel = () => {
    // setRecord(null);
    // setRowActive(null);
    setName("");
    props.hideModal();
  };

  const search = () => {
    props.getListBySyuruiCd(props.syuruiCd, name, (data) => {});
  };

  const onClickCellEvent = (record) => {
    // setRecord(record);
    setRowActive(record.id);
  };

  const onOk = () => {
    props.onSaveServiceKikan(props.recordSelected ? props.recordSelected.key : null, record);
    setName("");
    // setRecord(null);
    // setRowActive(null);
  };

  function handleOkService() {
    search();
    setServiceKikanMasterVisible(false);
  }

  function handleCancelService() {
    setServiceKikanMasterVisible(false);
  }

  function handleAddService() {
    setModeModify(ADD);
    setServiceKikanMasterVisible(true);
    setSid(null);
  }

  function handleEditService(sid) {
    setModeModify(EDIT);
    setServiceKikanMasterVisible(true);
    setSid(sid);
  }

  function handleDuplicateService(sid) {
    setSid(sid);
    setModeModify(DUPLICATE);
    setServiceKikanMasterVisible(true);
  }

  // function handleOkDuplicateConfirm() {
  //   setConfirmDuplicateServiceVisible(false);
  //   setServiceSyuruiListVisible(true);
  // }

  // function handleCancelDuplicateConfirm() {
  //   setConfirmDuplicateServiceVisible(false);
  // }

  // async function handleOkSyuruiList(syurui) {
  //   const result = await props.duplicateServiceKikanMaster(sid, syurui.id);
  //   setSid(result.id);
  //   setServiceSyuruiListVisible(false);
  //   setServiceKikanMasterVisible(true);
  // }

  // function handleCancelSyuruiList () {
  //   setServiceSyuruiListVisible(false);
  // }

  const titleService = (
    <div>
      <Row style={{ marginBottom: "4px" }}>
        <Col span={6}>サービス種類</Col>
        <Col span={18}>
          <Input value={props.serviceType} readOnly={true} />
        </Col>
      </Row>
      <Row>
        <Col span={6}>検索</Col>
        <Col span={18} style={{ display: "flex" }}>
          <Input
            onChange={(e) => setName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.getListBySyuruiCd(props.syuruiCd, name, (data) => {});
              }
            }}
            value={name}
          />
          <Button
            value={name}
            onClick={() => search()}
            className="search-service-kikan"
            icon={<SearchOutlined />}
          >
            検索
          </Button>
        </Col>
      </Row>
    </div>
  );
  const columnsService = [
    {
      title: "jigyousyoNo",
      dataIndex: "jigyousyoNo",
      width: 50,
      key: "jigyousyoNo",
      render: (textEx, record, index) => <div>{textEx}</div>,
      onCell: (record) => {
        return {
          onClick: (event) => onClickCellEvent(record), // click row
        };
      },
    },
    {
      title: "ryaku",
      dataIndex: "ryaku",
      width: 250,
      key: "ryaku",
      render: (textEx, record, index) => <div>{textEx}</div>,
      onCell: (record) => {
        return {
          onClick: (event) => onClickCellEvent(record), // click row
        };
      },
    },
  ];

  function isDisableButton(rowActive) {
    return props.serviceKikan ? !props.serviceKikan.some((item) => item.id == rowActive) : true;
  }

  function handleChangePagination(pagination) {
    setPagination(pagination);
  }

  return (
    (<div className="text-area-select-ri-page">
      <Modal
        open={props.visible}
        title={titleService}
        className="service-kikan-list"
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            キャンセル
          </Button>,
          <Button key="submit" type="primary" onClick={() => onOk()} disabled={isDisableButton(rowActive)}>
            OK
          </Button>,
        ]}
        closable={false}
      >
        <Button key="add" type={"primary"} onClick={handleAddService} style={{ marginRight: 8 }}>
          追加
        </Button>
        <Button
          key="edit"
          type={"primary"}
          onClick={() => handleEditService(rowActive)}
          disabled={isDisableButton(rowActive)}
          style={{ marginRight: 8 }}
        >
          修正
        </Button>
        <Button key="duplicate" type={"primary"} onClick={() => handleDuplicateService(rowActive)} disabled={isDisableButton(rowActive)}>
          複製
        </Button>
        <Table
          dataSource={props.serviceKikan}
          columns={columnsService}
          showHeader={false}
          className={
            props.className
              ? props.className + " riyou-meal-page service-kikan-table"
              : "riyou-meal-page service-kikan-table"
          }
          onChange={handleChangePagination}
          pagination={pagination}
          // size="small"
          rowClassName={(record) => {
            if (rowActive != null && record.id === rowActive) {
              setRecord(record);
              return "pljp-table-row-hover";
            }
            return "";
          }}
          rowKey={(record, index) => index}
          style={{ marginTop: 20 }}
        />
      </Modal>
      <ServiceKikanMasterModal
        visible={serviceKikanMasterVisible}
        onOk={handleOkService}
        onCancel={handleCancelService}
        id={sid}
        syuruiCd={props.syuruiCd}
        modeModify={modeModify}
      />
      {/* <ServiceSyuruiListModal
        visible={serviceSyuruiListVisible}
        onOk={handleOkSyuruiList}
        onCancel={handleCancelSyuruiList}
      />
      <Modal
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        open={confirmDuplicateServiceVisible}
        footer={[
          <Button key="back" onClick={handleCancelDuplicateConfirm}>
            キャンセル
          </Button>,
          <Button key="submit" type="primary" onClick={handleOkDuplicateConfirm}>
            OK
          </Button>,
        ]}
      >
        <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}>
          <Col><Icon type="question-circle" style={{fontSize: "2rem", color: "orange"}}/></Col>
          <Col>サービス種類を選択してください</Col>
        </Row>
      </Modal> */}
    </div>)
  );
}

const mapStateToProps = (state) => {
  return {
    serviceKikan: state.serviceKikanMaster.serviceKikanList,
    isReload: state.serviceKikanMaster.isReload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListBySyuruiCd: (syuruiCd, name, callback) =>
      dispatch(
        ServiceKikanMasterActions.getListBySyuruiCd(syuruiCd, name, callback)
      ),
    duplicateServiceKikanMaster: (sid, syuruiId) =>
      dispatch(
        ServiceKikanMasterActions.duplicateServiceKikanMaster(sid, syuruiId)
      ),
    turnOffReload: () => dispatch(ServiceKikanMasterActions.turnOffReload()),
  };
};

const _ServiceKikan = connect(mapStateToProps, mapDispatchToProps)(ServiceKikanRental);

export { _ServiceKikan as ServiceKikanRental };
