export const LifeYougoMasterActionTypes = {
  LOAD_LIFE_YOUGO_ATTRIBUTE_ERROR: "LOAD_LIFE_YOUGO_ATTRIBUTE_ERROR",
  GET_LIFE_YOUGO_MASTER_ERROR: "GET_LIFE_YOUGO_MASTER_ERROR",
  GET_LIFE_YOUGO_MASTER_SUCCESS: "GET_LIFE_YOUGO_MASTER_SUCCESS",
  GET_LIFE_YOUGO_MASTER_LOADING: "GET_LIFE_YOUGO_MASTER_LOADING",

  GET_LIFE_YOUGO_MASTER_BUNRUI1: "GET_LIFE_YOUGO_MASTER_BUNRUI1",
  GET_LIFE_YOUGO_MASTER_BUNRUI2: "GET_LIFE_YOUGO_MASTER_BUNRUI2",
  GET_LIFE_YOUGO_MASTER_BUNRUI3: "GET_LIFE_YOUGO_MASTER_BUNRUI3",
  GET_LIFE_YOUGO_MASTER_BUNRUI4: "GET_LIFE_YOUGO_MASTER_BUNRUI4",
  GET_LIFE_YOUGO_MASTER_BUNRUI5: "GET_LIFE_YOUGO_MASTER_BUNRUI5",
  GET_LIFE_YOUGO_MASTER_BUNRUI6: "GET_LIFE_YOUGO_MASTER_BUNRUI6",
  GET_LIFE_YOUGO_MASTER_BUNRUI7: "GET_LIFE_YOUGO_MASTER_BUNRUI7",
  GET_LIFE_YOUGO_MASTER_BUNRUI8: "GET_LIFE_YOUGO_MASTER_BUNRUI8",
  GET_LIFE_YOUGO_MASTER_BUNRUI9: "GET_LIFE_YOUGO_MASTER_BUNRUI9",
  GET_LIFE_YOUGO_MASTER_BUNRUI10: "GET_LIFE_YOUGO_MASTER_BUNRUI10",
  GET_LIFE_YOUGO_MASTER_BUNRUI111: "GET_LIFE_YOUGO_MASTER_BUNRUI11",
  GET_LIFE_YOUGO_MASTER_BUNRUI12: "GET_LIFE_YOUGO_MASTER_BUNRUI12",

  SAVE_LIFE_YOUGO_MASTER_SUCCESS: "SAVE_LIFE_YOUGO_MASTER_SUCCESS",
  SAVE_LIFE_YOUGO_MASTER_ERROR: "SAVE_LIFE_YOUGO_MASTER_ERROR",

  LIFE_YOUGO_EDIT_STATE: "LIFE_YOUGO_EDIT_STATE",
  LIFE_YOUGO_CANCEL_STATE: "LIFE_YOUGO_CANCEL_STATE",

  GET_ICF_MASTER_REQUEST: "GET_ICF_MASTER_REQUEST",
  GET_ICF_MASTER_SUCESS: "GET_ICF_MASTER_SUCESS",
  GET_SIENNAIYO_MASTER_SUCESS: "GET_SIENNAIYO_MASTER_SUCESS",
  GET_SETTING_MASTER_SUCESS: "GET_SETTING_MASTER_SUCESS",

  GET_ICF_MASTER_KOUI: "GET_ICF_MASTER_KOUI",
  GET_SIENNAIYO_MASTER_SAIKOMOKU: "GET_SIENNAIYO_MASTER_SAIKOMOKU",

  LOAD_SEIKATU_ADL_MASTER_REQUEST: "LOAD_SEIKATU_ADL_MASTER_REQUEST",
  LOAD_SEIKATU_ADL_MASTER_SUCCESS: "LOAD_SEIKATU_ADL_MASTER_SUCCESS",
  LOAD_SEIKATU_ADL_MASTER_ERROR: "LOAD_SEIKATU_ADL_MASTER_ERROR",
};
