import { Button, Col, Row } from "antd";
import React from "react";

export default class TimeKB extends React.Component {
  constructor(props) {
    super(props);

    this.handleHourInput = this.handleHourInput.bind(this);
    this.handleMinuteLeftInput = this.handleMinuteLeftInput.bind(this);
    this.handleMinuteInput = this.handleMinuteInput.bind(this);
  }

  handleHourInput = (value) => {
    this.props.handleHourInput(value);
  };

  handleMinuteLeftInput = (value) => {
    this.props.handleMinuteLeftInput(value);
  };

  handleMinuteInput = (value) => {
    this.props.handleMinuteInput(value);
  };

  render() {
    return (
      <Row gutter={16} id="time-bk-modal">
        <Col span={4} className="text-align-right">
          時間:{" "}
        </Col>
        <Col span={8}>
          <div>
            <Button onClick={() => this.handleHourInput("00")}>00</Button>
            <Button onClick={() => this.handleHourInput("08")}>08</Button>
            <Button onClick={() => this.handleHourInput("16")}>16</Button>
          </div>
          <div>
            <Button onClick={() => this.handleHourInput("01")}>01</Button>
            <Button onClick={() => this.handleHourInput("09")}>09</Button>
            <Button onClick={() => this.handleHourInput("17")}>17</Button>
          </div>
          <div>
            <Button onClick={() => this.handleHourInput("02")}>02</Button>
            <Button onClick={() => this.handleHourInput("10")}>10</Button>
            <Button onClick={() => this.handleHourInput("18")}>18</Button>
          </div>
          <div>
            <Button onClick={() => this.handleHourInput("03")}>03</Button>
            <Button onClick={() => this.handleHourInput("11")}>11</Button>
            <Button onClick={() => this.handleHourInput("19")}>19</Button>
          </div>
          <div>
            <Button onClick={() => this.handleHourInput("04")}>04</Button>
            <Button onClick={() => this.handleHourInput("12")}>12</Button>
            <Button onClick={() => this.handleHourInput("20")}>20</Button>
          </div>
          <div>
            <Button onClick={() => this.handleHourInput("05")}>05</Button>
            <Button onClick={() => this.handleHourInput("13")}>13</Button>
            <Button onClick={() => this.handleHourInput("21")}>21</Button>
          </div>
          <div>
            <Button onClick={() => this.handleHourInput("06")}>06</Button>
            <Button onClick={() => this.handleHourInput("14")}>14</Button>
            <Button onClick={() => this.handleHourInput("22")}>22</Button>
          </div>
          <div>
            <Button onClick={() => this.handleHourInput("07")}>07</Button>
            <Button onClick={() => this.handleHourInput("15")}>15</Button>
            <Button onClick={() => this.handleHourInput("23")}>23</Button>
          </div>
        </Col>
        <Col span={3} className="text-align-right">
          分:{" "}
        </Col>
        <Col span={6}>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("00")}>
              00
            </Button>
            <Button onClick={() => this.handleMinuteInput("0")}>0</Button>
          </div>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("10")}>
              10
            </Button>
            <Button onClick={() => this.handleMinuteInput("1")}>1</Button>
          </div>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("15")}>
              15
            </Button>
            <Button onClick={() => this.handleMinuteInput("2")}>2</Button>
          </div>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("20")}>
              20
            </Button>
            <Button onClick={() => this.handleMinuteInput("3")}>3</Button>
          </div>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("29")}>
              29
            </Button>
            <Button onClick={() => this.handleMinuteInput("4")}>4</Button>
          </div>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("30")}>
              30
            </Button>
            <Button onClick={() => this.handleMinuteInput("5")}>5</Button>
          </div>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("40")}>
              40
            </Button>
            <Button onClick={() => this.handleMinuteInput("6")}>6</Button>
          </div>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("45")}>
              45
            </Button>
            <Button onClick={() => this.handleMinuteInput("7")}>7</Button>
          </div>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("50")}>
              50
            </Button>
            <Button onClick={() => this.handleMinuteInput("8")}>8</Button>
          </div>
          <div>
            <Button onClick={() => this.handleMinuteLeftInput("59")}>
              59
            </Button>
            <Button onClick={() => this.handleMinuteInput("9")}>9</Button>
          </div>
        </Col>
      </Row>
    );
  }
}
