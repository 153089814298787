/* eslint-disable indent */
import { Table } from "antd";
import React from "react";
import ResizableTitle from "../../../common/component/ResizableTitle";

class TableResizeAble extends React.Component {
    constructor(props) {
        super(props);
        const { columns, widthx } = this.props;

        this.state = {
            columns,
            widthx,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.columns !== this.props.columns) {
            this.setState({
                columns: this.props.columns
            });
        }
    }

    handleChange = (pagination, filters, sorter) => {
        this.props.onChange(pagination, filters, sorter);
    };

    handleResize =
        (index) =>
            (e, { size }) => {
                this.setState(({ columns }) => {
                    const nextColumns = [...columns];
                    nextColumns[index] = {
                        ...nextColumns[index],
                        width: size.width,
                    };
                    return { columns: nextColumns, widthx: this.state.widthx + (size.width - columns[index].width) };
                });
            };

    render() {
        const columns = this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: (column) => ({
                width: column.width,
                onResize: this.handleResize(index),
            }),
        }));

        const components = {
            header: {
                cell: ResizableTitle,
            }
        };

        const { data, pagination, loading } = this.props;

        return (
            <Table
                bordered
                components={components}
                columns={columns}
                dataSource={data}
                style={{ width: this.state.widthx }}
                onChange={this.handleChange}
                pagination={pagination ? pagination : { defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ["50", "100", "200"] }}
                // scroll={{ y: 620 }}
                size="small"
                rowKey={(record, index) => index}
                loading={loading}
            />

        );
    }
}


export default TableResizeAble;