/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { convertToJapaneseEra, convertToJapaneseEraDay, defaultPrintOffice, handleConvertRecordListDataRiyouhform, maskEvenCharacters } from "../../../function_common/functionCommon";

const handleTekiyouDate = (nengetu, listKaigohoken, riyouhyouForm) => {
  if (nengetu !== riyouhyouForm.tekiyouDate.split("-")[0] + "" + riyouhyouForm.tekiyouDate.split("-")[1]) return;
  if (listKaigohoken.length) {
    const firstDayofDate = new Date(riyouhyouForm.tekiyouDate);
    const isFirstDayofDate = firstDayofDate.getDate() === 1;
    const hokenSecond = listKaigohoken?.find((e) => e.id !== (riyouhyouForm?.hokenSelected?.id || riyouhyouForm?.riyouKaigohoken?.id));
    return hokenSecond && hokenSecond?.id && !isFirstDayofDate ? true : null;
  }
};
export const management_Useslip_InputFormPDF = (data, jobList) => {
  const { riyouhyouForm, selectedRowKeys, newSettingFormSlip } = data;
  const { settingsFormSlip, listKaigohoken, isUseslip } = riyouhyouForm;
  const hokenFind = handleTekiyouDate(riyouhyouForm.nengetu, listKaigohoken, riyouhyouForm);
  const firstHoken = hokenFind && listKaigohoken && listKaigohoken?.length > 0 ? listKaigohoken[listKaigohoken?.length - 1] : (riyouhyouForm?.riyouKaigohoken ?? riyouhyouForm?.hokenSelected);
  const { listDateInMonth, newKeyWeeks, listDataPDF, listCheckedOfficeName, colorSelectorSetting } = riyouhyouForm.listAllDays;
  const settingPrint = selectedRowKeys && selectedRowKeys?.value === "0" ? newSettingFormSlip : settingsFormSlip;
  const colorPrint = selectedRowKeys && selectedRowKeys?.value === "0" ? newSettingFormSlip.selectedColor : settingsFormSlip.selectedColor;
  const conditionFillColorRecord = selectedRowKeys && defaultPrintOffice.includes(selectedRowKeys?.key);

  const numberInsure = isUseslip ? firstHoken?.hihokenNo : (selectedRowKeys && defaultPrintOffice.includes(selectedRowKeys?.key) && settingPrint.characterHideUser) ? maskEvenCharacters(firstHoken?.hihokenNo) : firstHoken?.hihokenNo;
  const furiganaName = isUseslip ? riyouhyouForm?.riyousya?.furigana : (selectedRowKeys && defaultPrintOffice.includes(selectedRowKeys?.key) && settingPrint.characterHideUser) ? maskEvenCharacters(riyouhyouForm?.riyousya?.furigana) : riyouhyouForm?.riyousya?.furigana;
  const nameRiyousya = isUseslip ? riyouhyouForm?.riyousya?.name : (selectedRowKeys && defaultPrintOffice.includes(selectedRowKeys?.key) && settingPrint.characterHideUser) ? maskEvenCharacters(riyouhyouForm?.riyousya?.name) : riyouhyouForm?.riyousya?.name;
  const originalDate = new Date();
  const originalDateFormat = originalDate.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });

  const parts = originalDateFormat.split("/");
  const convertedDate = parts[2] + "-" + parts[0] + "-" + parts[1];

  const createdDate = settingPrint.createDatePrint ? convertToJapaneseEraDay(riyouhyouForm?.riyouhyouDate ?? convertedDate) : "年　　月　　日";

  const hokenSecondSelected = listKaigohoken?.find((e) => e.id !== firstHoken.id);

  let newKeyDays = listDateInMonth.map((item,) => `${item.date}`);
  let newKeyWeeksUpdate = newKeyWeeks;
  newKeyDays = newKeyDays.concat(Array(31 - newKeyDays.length).fill(""));
  newKeyDays = generateAddNewKeyForArray(newKeyDays);

  const tantoName = riyouhyouForm?.tantoList?.find((element) => element.id === riyouhyouForm.tantoId)?.name;
  const nameTitlePdf = isUseslip ? "サービス利用票（兼居宅サービス計画）" : !defaultPrintOffice.includes(selectedRowKeys?.key) ? "サービス利用票（兼居宅サービス計画）" : "サービス提供票";
  const userTitlePdf = isUseslip ? "居宅介護支援事業者→利用者" : !defaultPrintOffice.includes(selectedRowKeys?.key) ? "居宅介護支援事業者→利用者" : "居宅介護支援事業者→サービス事業者";
  const serviceKikanInfo = `${riyouhyouForm.serviceKikanMaster.jigyousyoName}\n事業所番号: ${riyouhyouForm.serviceKikanMaster.jigyousyoNo}\n電話番号: ${riyouhyouForm.serviceKikanMaster.tel}`;
  const rangeDateKaigo = `${convertToJapaneseEraDay(riyouhyouForm.yukoDateFrom)}\n${convertToJapaneseEraDay(riyouhyouForm.yukoDateTo)}`;
  let informationRiyouhform = {
    認定済: `${firstHoken?.sinseif ? "申請中" : "認定済"}`,
    年月: `${convertToJapaneseEra(riyouhyouForm?.nengetu, "")}`,
    作成年月日: `${createdDate ?? ""}`,
    保険者番号: `${riyouhyouForm?.hokensyaNo ?? ""}`,
    保険者名: `${riyouhyouForm?.hokenNameMaster?.hokenName ?? ""}`,
    被保険者番号: `${numberInsure ?? ""}`,
    生年月日: `${convertToJapaneseEraDay(firstHoken?.riyousya?.birthday) ?? ""}`,
    性別: `${firstHoken?.riyousya?.gender ? "男" : "女"}`,
    フリガナ: `${furiganaName ?? ""}`,
    被保険者氏名: `${nameRiyousya ?? ""}`,
    要介護状態区分: `${firstHoken.kaigodoMaster.kaigodoName ?? ""}`,
    変更後要介護区分: `${hokenFind ? hokenSecondSelected?.kaigodoMaster.kaigodoName : ""}`,
    変更日: `${hokenFind ? convertToJapaneseEraDay(riyouhyouForm?.tekiyouDate) : ""}`,
    居宅介護支援事業者: `${serviceKikanInfo}`,
    担当者名: `${tantoName ?? ""}`,
    区分支給限度基準額: `${riyouhyouForm.kubunsikyu}`,
    限度額適用期間: `${rangeDateKaigo}`,
    前月までの短期日数: `${riyouhyouForm.kyufuDays ?? 0}`,
    日28: "",
    日29: "",
    日30: "",
    ページ番号: "1",
    利用者: `${userTitlePdf}`,
    サービス利用票: `${nameTitlePdf}`,
    保険者確認印: "",
    届出年月日: "",
    利用者確認: "",
    敬称: `${(selectedRowKeys && selectedRowKeys?.value === "1" ? riyouhyouForm?.settingsFormSlip?.honorificTitle : newSettingFormSlip?.honorificTitle) ?? riyouhyouForm?.settingsFormSlip?.honorificTitle}`,
  };

  function generateAddNewKeyForArray(data) {
    const obj = {};
    data.forEach((value, index) => {
      obj[index] = value;
    });

    return obj;
  }

  function generateAddNewKeyForObject(data, pageSize) {
    const result = { ...data };
    for (let i = 2; i <= pageSize; i++) {
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          result[`${key}_page${i}`] = data[key];
          if (key.includes("ページ番号")) {
            result[`${key}_page${i}`] = `${i}`;
          }
        }
      }
    }
    return result;
  }

  if (typeof riyouhyouForm === "object") {
    let pageSize = 1;
    if (listDataPDF.length >= 15) {
      const maxLengthOfPage = 15;
      pageSize = Math.ceil(listDataPDF.length / maxLengthOfPage);
      newKeyDays = generateAddNewKeyForObject(newKeyDays, pageSize);
      newKeyWeeksUpdate = generateAddNewKeyForObject(newKeyWeeksUpdate, pageSize);
      informationRiyouhform = generateAddNewKeyForObject(informationRiyouhform, pageSize);
    }
    const { recordTable } = handleConvertRecordListDataRiyouhform(listDataPDF, pageSize, colorPrint, listCheckedOfficeName, conditionFillColorRecord);
    const sampledata = [
      {
        ...informationRiyouhform,
        ...newKeyDays,
        ...newKeyWeeksUpdate,
        ...recordTable,
      },
    ];

    return sampledata;
  }
};

