import React from "react";
import { Row, Col, Divider } from "antd";
import { displayJpDateKanji } from "../../../common/function_common/functionDisplay";

const HokenInfo = (props) => {
  const kohiNo = [];
  return (
    <div>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[介護]保険者名</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya &&
            props.riyousya.riyouKaigohokenDto &&
            props.riyousya.riyouKaigohokenDto[0] &&
            props.riyousya.riyouKaigohokenDto[0].kaigohokenMaster
              ? props.riyousya.riyouKaigohokenDto[0].kaigohokenMaster.hokenName
              : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[介護]保険者番号</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya &&
            props.riyousya.riyouKaigohokenDto &&
            props.riyousya.riyouKaigohokenDto[0]
              ? props.riyousya.riyouKaigohokenDto[0].hokensyaNo
              : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[介護]被保険者番号</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya &&
            props.riyousya.riyouKaigohokenDto &&
            props.riyousya.riyouKaigohokenDto[0]
              ? props.riyousya.riyouKaigohokenDto[0].hihokenNo
              : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[介護]要介護度</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya &&
            props.riyousya.riyouKaigohokenDto &&
            props.riyousya.riyouKaigohokenDto[0] &&
            props.riyousya.riyouKaigohokenDto[0].kaigodoName
              ? props.riyousya.riyouKaigohokenDto[0].kaigodoName
              : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[介護]有効期限</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya &&
            props.riyousya.riyouKaigohokenDto &&
            props.riyousya.riyouKaigohokenDto[0]
              ? `${
                  props.riyousya.riyouKaigohokenDto[0].yukoDateFrom
                    ? displayJpDateKanji(
                        props.riyousya.riyouKaigohokenDto[0].yukoDateFrom
                      )
                    : ""
                }${
                  props.riyousya.riyouKaigohokenDto[0].yukoDateTo
                    ? " 〜 " +
                      displayJpDateKanji(
                        props.riyousya.riyouKaigohokenDto[0].yukoDateTo
                      )
                    : ""
                }`
              : null}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[介護]認定状態</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya &&
            props.riyousya.riyouKaigohokenDto &&
            props.riyousya.riyouKaigohokenDto[0] &&
            props.riyousya.riyouKaigohokenDto[0].sinseif === 1
              ? "申請中"
              : ""}
          </span>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[医療]保険者名</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya.riyouIryouhokenDto &&
            props.riyousya.riyouIryouhokenDto[0] &&
            props.riyousya.riyouIryouhokenDto[0].iryouhokenMaster
              ? props.riyousya.riyouIryouhokenDto[0].iryouhokenMaster.hokenName
              : null}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[医療]保険者番号</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya.riyouIryouhokenDto &&
            props.riyousya.riyouIryouhokenDto[0] &&
            props.riyousya.riyouIryouhokenDto[0].iryouhokenMaster
              ? props.riyousya.riyouIryouhokenDto[0].iryouhokenMaster.hokenNo
              : null}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[医療]保険の種類</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya.riyouIryouhokenDto &&
            props.riyousya.riyouIryouhokenDto[0] &&
            props.riyousya.riyouIryouhokenDto[0]
              ? props.riyousya.riyouIryouhokenDto[0].hokensyurui
              : null}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[医療]記号</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya.riyouIryouhokenDto &&
            props.riyousya.riyouIryouhokenDto[0] &&
            props.riyousya.riyouIryouhokenDto[0]
              ? props.riyousya.riyouIryouhokenDto[0].kigou
              : null}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <span className="rdp-basic-tab-title">[医療]番号</span>
        </Col>
        <Col span={16}>
          <span className="rdp-basic-tab-content">
            {props.riyousya.riyouIryouhokenDto &&
            props.riyousya.riyouIryouhokenDto[0] &&
            props.riyousya.riyouIryouhokenDto[0]
              ? props.riyousya.riyouIryouhokenDto[0].bango
              : null}
          </span>
        </Col>
      </Row>
      <Divider />
      {props.riyousya && props.riyousya.riyouKohiDto ? (
        props.riyousya.riyouKohiDto.map((item) => {
          if (item.kohiNo && !kohiNo.includes(item.kohiNo)) {
            kohiNo.push(item.kohiNo);
            return (
              <div>
                <Row>
                  <Col span={8}>
                    <span className="rdp-basic-tab-title">{`[公費${kohiNo.length}]負担者名`}</span>
                  </Col>
                  <Col span={16}>
                    <span className="rdp-basic-tab-content">
                      {item?.kohifutanMaster
                        ? item.kohifutanMaster.futanName
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <span className="rdp-basic-tab-title">{`[公費${kohiNo.length}]負担者番号`}</span>
                  </Col>
                  <Col span={16}>
                    <span className="rdp-basic-tab-content">
                      {item?.kohifutanMaster
                        ? item.kohifutanMaster.futanNo
                        : item?.futansyaNo
                        ? item.futansyaNo
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <span className="rdp-basic-tab-title">{`[公費${kohiNo.length}]受給者番号`}</span>
                  </Col>
                  <Col span={16}>
                    <span className="rdp-basic-tab-content">
                      {item ? item.jukyusyaNo : ""}
                    </span>
                  </Col>
                </Row>
                <Divider />
              </div>
            );
          }
        })
      ) : (
        // 空の場合
        <div>
          <Row>
            <Col span={8}>
              <span className="rdp-basic-tab-title">[公費]負担者名</span>
            </Col>
            <Col span={16}>
              <span className="rdp-basic-tab-content"></span>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <span className="rdp-basic-tab-title">[公費]負担者番号</span>
            </Col>
            <Col span={16}>
              <span className="rdp-basic-tab-content"></span>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <span className="rdp-basic-tab-title">[公費]受給者番号</span>
            </Col>
            <Col span={16}>
              <span className="rdp-basic-tab-content"></span>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default HokenInfo;
