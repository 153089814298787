export const ApiPaths = {
  Account: {
    SignIn: "/api/v1/management-login",
    SignOut: "/api/v1/logout",
    Mainainance: "/api/v1/maintenance-modeMNGT",
  },
  DocumentList: {
    GetAllDocumentList: "/api/v1/doc-list-home",
  },
  Pdfme: {
    saveUpdatePdfmeTemplate: "/api/v1/update-json-by-riyousya",
    getPdfmeByScreen: "/api/v1/pdf-me-by",
    getTemplateById: "/api/v1/screen-pdf-me-by-id",
    saveUpdatePdfmeTemplate: "/api/v1/update-pdf-format-by-id",
  },
  KyotakuPlan1: {
    getPlan1ById: "/api/v1/kyotaku-plan1",
    GetKyotakuPlan1List: "/api/v1/kyotaku-plan1-list-home",
    GetKyotakuPlan1ListQuote: "/api/v1/kyotaku-plan1-quote-home",
    Default: "/api/v1/kyotaku-plan1",
  },
  KyotakuPlan2: {
    getPlan2ById: "/api/v1/kyotaku-plan2",
    GetKyotakuPlan2List: "/api/v1/kyotaku-plan2-list-home",
    GetKyotakuPlan2ListQuote: "/api/v1/kyotaku-plan2-quote-home",
    Default: "/api/v1/kyotaku-plan2",
  },
  SisetuDailyPlan: {
    GetList: "/api/v1/sisetu-daily-plan-doc-list-home",
    Get: "/api/v1/sisetu-daily-plan-by-id",
    // Put: "/api/v1/monitoring-update",
    Post: "/api/v1/save-sisetu-daily-plan",
    Delete: "/api/v1/delete-sisetu-daily-plan",
  },
  Monitoring: {
    GetList: "/api/v1/monitoring-list-home",
    Get: "/api/v1/monitoring-by-id",
    Put: "/api/v1/monitoring-update",
    Post: "/api/v1/monitoring-insert",
    Delete: "/api/v1/monitoring",
  },
  // monitoring management
  MonitoringMNGT: {
    CreateBatch: "/api/v1/monitoring-management-create-batch",
    GetAll: "/api/v1/monitoring-management-list",
  },
  KeikaKiroku: {
    GetList: "/api/v1/keika-kiroku-list-home",
    Get: "/api/v1/keika-kiroku-get-by-id",
    // Put: "/api/v1/monitoring-update",
    Post: "/api/v1/keika-kiroku-create",
    Delete: "/api/v1/keika-kiroku",
  },
  TantoKaigi: {
    GetList: "/api/v1/tanto-kaigi-list-home",
    Get: "/api/v1/tanto-kaigi-get-by-id",
    Put: "/api/v1/update-tanto-kaigi",
    Post: "/api/v1/create-tanto-kaigi",
    Delete: "/api/v1/tanto-kaigi",
  },
  ApiGeneral: {
    GetYougoMaster: "/api/v1/yougomaster-naiyou",
    UpdateYougomaster: "/api/v1/yougomaster-update",
    GetTantoMaster: "/api/v1/tanto-master-list-by-sid",
    GetServiceKikan: "/api/v1/service-kikan-master",
    GetServiceSyuruiMaster: "/api/v1/service-syurui-master",
    GetServiceKikanForPlan2: "/api/v1/service-kikan-master-for-plan2",
    GetInitialIryou: "/api/v1/iryou-kikan-master-init",
    GetIryouById: "/api/v1/iryou-kikan-master/:id",
    CreateIryou: "/api/v1/iryou-kikan-master-create",
    UpdateIryou: "/api/v1/iryou-kikan-master-update",
    GetServiceKikanMaster: "/api/v1/service-kikan-master-by-id",
  },
  TantoMaster: {
    GetTantoMaster: "/api/v1/tanto-master-list-by-sid",
  },
  InquiryRequest: {
    GetDataInquiRequest: "/api/v1/syoukai-irai",
    GetSyoukaiDate: "/api/v1/syoukai-irai-date",
    SaveUpdateDataInquiryRequest: "/api/v1/syoukai-irai",
    GetDataViewInquiryRequest: "/api/v1/syoukai-irai",
    GetIdsInquiryRequest: "/api/v1/syoukai-irai-list-id",
    GetListInquiryRequest: "/api/v1/syoukai-irai-list",
    SaveCreateDataInquiryRequest: "/api/v1/syoukai-irai",
    Default: "/api/v1/syoukai-irai",
  },

  WeeklyPlan: {
    GetDetailById: "/api/v1/kyotaku-weekly-plan-get-day",
    KyotakuWeeklyPlan: "/api/v1/kyotaku-weekly-plan",
    GetTemplate: "/api/v1/kyotaku-weekly-plan-get-template",
    KyotakuWeeklyPlanListQuote: "/api/v1/kyotaku-weekly-plan-list-quote",
    UpdateWeeklyPlan: "/api/v1/kyotaku-weekly-plan-update",
    GetServiceColorMaster: "/api/v1/service-color-master",
    GetServiceContentByType: "/api/v1/service-content-by-type",
    GetServiceAddition: "/api/v1/service-addition-list",
    UpdateWeeklyPlanTemplate: "/api/v1/kyotaku-weekly-plan-update-template",
    getListWeeklyPlan: "/api/v1/kyotaku-weekly-plan-list",
    delete: "/api/v1/kyotaku-weekly-plan-delete",
    KyotakuWeeklyPlanListQuoteTab: "/api/v1/kyotaku-weekly-plan-list-quote-tab",
    getWeeklyPlanListId: "/api/v1/kyotaku-weekly-plan-list-id",
    getRentalByWeeklyPlanId: "/api/v1/kyotaku-weekly-plan-get-rental",
    KyotakuWeeklyPlanListRentalQuote:
      "/api/v1/kyotaku-weekly-plan-list-rental-quote",
    ServiceContentRental: "/api/v1/service-content-rental",
    RentalSyohinMaster: "/api/v1/rental-syohin-master",
    ServiceContentRentalBySyuruiCd:
      "/api/v1/service-content-rental-by-syurui-cd",
    KyotakuWeeklyRental: "/api/v1/kyotaku-weekly-update-rental",
    GetRentalSyohinMaster: "/api/v1/rental-syohin-master/:id",
    CreateRentalSyohinMaster: "/api/v1/rental-syohin-master",
    UpdateRentalSyohinMaster: "/api/v1/rental-syohin-master",
    GetRentalSyohinMasterInit: "/api/v1/rental-syohin-master-init",
    GetListKyotakuWeeklyPlan: "/api/v1/kyotaku-weekly-plan-by-id",
    UpdateRentalList: "/api/v1/rental-syohin-master-list",
  },

  ServiceKikanMaster: {
    GetById: "/api/v1/service-kikan-master/:id",
    GetInitialServiceKikan: "/api/v1/service-kikan-master-init",
    Create: "/api/v1/service-kikan-master-create",
    Update: "/api/v1/service-kikan-master-update",
    Duplicate: "/api/v1/service-kikan-master-duplicate",
    GetServiceSyuruiMasters: "/api/v1/service-syurui-master-all",
    GetServiceAdditions: "/api/v1/service-addition-by-kaigo-syurui",
    SearchHojinInfoMasters: "/api/v1/hojin-info-master/search",
    CheckLinkedWeeklySchedule:
      "/api/v1/service-kikan-master/check-linked-weekly",
    CreateHojinInfoMaster: "/api/v1/hojin-info-master/create",
    GetHojinInfoMasterById: "/api/v1/hojin-info-master/:id",
    GetHojinInfoMasterByName: "/api/v1/hojin-info-master-by-name",
    GetServiceKikanMonthlyById: "/api/v1/service-kikan-monthly",
    CreateServiceKikanMonthly: "/api/v1/service-kikan-monthly",
    UpdateServiceKikanMonthly: "/api/v1/service-kikan-monthly",
    Save: "/api/v1/service-kikan-monthly-save",
    DeleteServiceKikanMonthly: "/api/v1/service-kikan-monthly/:id",
    GetListServiceKikanMonthly: "/api/v1/service-kikan-monthly-by-sid",
    GetTaiseiMaster: "/api/v1/taisei-master",
    GetTikibetuTikikubun: "/api/v1/tikibetu-tikikubun",
    GetTikiKubunMaster: "/api/v1/tiki-kubun-master",
    GetListByServiceType: "/api/v1/service-kikan-master-by-service-type",
    GetListByServiceTypePlan2: "/api/v1/service-kikan-master-plan2",
    GetListBySyuruiCd: "/api/v1/service-kikan-master-by-syurui-cd",
    GetSyuruiCdByServiceType: "/api/v1/service-syurui-master-by-service-type",
    GetMaxSortNum: "/api/v1/service-kikan-master-max-sort-num",
    UpdateList: "/api/v1/service-kikan-master-update-list",
  },

  ItakuTantoMaster: {
    GetById: "/api/v1/itaku-tanto-master/:id",
    GetInitialServiceKikan: "/api/v1/itaku-tanto-master-init",
    Create: "/api/v1/itaku-tanto-master-create",
    Update: "/api/v1/itaku-tanto-master-update",
    Duplicate: "/api/v1/itaku-tanto-master-duplicate",
    GetServiceSyuruiMasters: "/api/v1/service-syurui-master-all",
    GetServiceAdditions: "/api/v1/service-addition-by-kaigo-syurui",
    SearchHojinInfoMasters: "/api/v1/hojin-info-master/search",
    CheckLinkedWeeklySchedule: "/api/v1/itaku-tanto-master/check-linked-weekly",
    CreateHojinInfoMaster: "/api/v1/hojin-info-master/create",
    GetHojinInfoMasterById: "/api/v1/hojin-info-master/:id",
    GetHojinInfoMasterByName: "/api/v1/hojin-info-master-by-name",
    GetServiceKikanMonthlyById: "/api/v1/itaku-tanto-monthly",
    CreateServiceKikanMonthly: "/api/v1/itaku-tanto-monthly",
    UpdateServiceKikanMonthly: "/api/v1/itaku-tanto-monthly",
    Save: "/api/v1/itaku-tanto-monthly-save",
    DeleteServiceKikanMonthly: "/api/v1/itaku-tanto-monthly/:id",
    GetListServiceKikanMonthly: "/api/v1/itaku-tanto-monthly-by-sid",
    GetTaiseiMaster: "/api/v1/taisei-master",
    GetTikibetuTikikubun: "/api/v1/tikibetu-tikikubun",
    GetTikiKubunMaster: "/api/v1/tiki-kubun-master",
    // GetListByServiceType: "/api/v1/itaku-tanto-master-by-service-type",
    GetListByServiceType: "/api/v1/itaku-tanto-master",
    GetListByServiceTypePlan2: "/api/v1/itaku-tanto-master-plan2",
    GetListBySyuruiCd: "/api/v1/itaku-tanto-master-by-syurui-cd",
    GetSyuruiCdByServiceType: "/api/v1/service-syurui-master-by-service-type",
    GetMaxSortNum: "/api/v1/itaku-tanto-master-max-sort-num",
    // UpdateList: "/api/v1/itaku-tanto-master-update-list",
  },

  Task: {
    GetListTask: "/api/v1/kadai-seiri-list",
    Delete: "/api/v1/kadai-seiri-delete",
    GetListQuote: "/api/v1/kadai-seiri-list-quote",
    GetTaskById: "/api/v1/kadai-seiri",
    UpdateKadaiSeiri: "/api/v1/kadai-seiri-update",
    GetMasterKadai: "/api/v1/kadai-seiri-master",
    GetListTaskQuoteDate: "/api/v1/kadai-seiri-date",
    UpdateKadai: "/api/v1/kadai-update",
    GetKadaiListId: "/api/v1/list-kadai-seiri-id",
  },
  UseSlip: {
    GetRiyouhyouById: "/api/v1/riyouhyou-by-id",
    GetListServiceType: "/api/v1/riyouhyou/services-type",
    GetList: "/api/v1/riyouhyou",
    GetUseSlipQuote: "/api/v1/riyouhyou/quote",
    Delete: "/api/v1/riyouhyou/delete",
    GetServiceContentById: "/api/v1/riyouhyou/service-content-by-id",
    GetServiceAdditionByType: "/api/v1/riyouhyou/service-addition-by-type",
    GetSyuruiCd: "/api/v1/riyouhyou/syuruicd",
    GetServiceCode: "/api/v1/riyouhyou/services-code",
    GetServiceAddition: "/api/v1/riyouhyou/service-addition-list",
    GetExpandCode: "/api/v1/riyouhyou/expand-code",
    GetServiceAdditionByOffice:
      "/api/v1/riyouhyou/service-addition-list-by-office",
    GetServiceContentByTypeAndSyurui:
      "/api/v1/service-content-by-type-and-syurui",
    GetColorMasterByUseSlip: "/api/v1/service-color-master-useslip",
    GetNaiyou: "/api/v1/riyouhyou/naiyou",
    GetWeeklySchedule: "/api/v1/riyouhyou/weekly-schedule",
    GetDuplicateCheckNotPossible:
      "/api/v1/riyouhyou/duplicate-check-not-possible",
    GetInformationKaigohoken: "/api/v1/riyouhyou/information-kaigohoken",
    UpdateRiyouhyou: "/api/v1/riyouhyou/update",
    GetHolidayMaster: "/api/v1/holiday-master",
    GetRiyouhyouIds: "/api/v1/riyouhyou/ids",
    GetServiceAdditionOld: "/api/v1/riyouhyou/service-addition-list-old",
    GetKyufuAttachedTable: "/api/v1/riyouhyou/attached-table/kyufu",
    GetCalculation: "/api/v1/riyouhyou/attached-table/calculation",
    GetRenzokuLastMonth: "/api/v1/riyouhyou/get-renzoku-days-last-month",
    GetKyufuDays: "/api/v1/riyouhyou/get-kyufu-days",
    GetServiceColorMasterSonota: "/api/v1/service-color-master-sonota",
    GetListRiyouhyouQuote: "/api/v1/riyouhyou/get-list-riyouhyou-by-month",
    GetServiceContentBySdCode: "/api/v1/riyouhyou/get-service-content-by-sd",
    GetServiceCodeList: "/api/v1/riyouhyou/services-code-list",
    GetGetExpandCodeList: "/api/v1/riyouhyou/expand-code-list",
    GetAllSyruiCd: "/api/v1/riyouhyou/get-all-service-syurui-master",
    GetHokenByHokenNo: "/api/v1/riyouhyou/get-hoken-name-by-hoken-no",
    GetDefaultExcel: "/api/v1/get-excel-sample"
  },
  RiyousyaYobou: {
    GetById: "/api/v1/yobou-get-by-id",
    GetService: "/api/v1/yobou-get-service",
  },
  Facesheet: {
    GetList: "/api/v1/facesheet-list",
    GetById: "/api/v1/facesheet-by-id",
    Create: "/api/v1/facesheet-create",
    Update: "/api/v1/facesheet-update",
    Delete: "/api/v1/facesheet-delete",
    GetSoudanDate: "/api/v1/facesheet-get-soudan-date",
    GetLatest: "/api/v1/facesheet-latest",
  },
  KihonCheck: {
    GetList: "/api/v1/kihon-check-list",
    GetById: "/api/v1/kihon-check-by-id",
    GetItemList: "/api/v1/kihon-check-item-list",
    Create: "/api/v1/kihon-check-create",
    Update: "/api/v1/kihon-check-update",
    Delete: "/api/v1/kihon-check-delete",
    GetQuote: "/api/v1/kihon-check-quote",
    GetLatest: "/api/v1/kihon-check-latest",
  },
  YobousienPlan: {
    GetList: "/api/v1/yobousien-plan-list",
    GetById: "/api/v1/yobousien-plan-by-id",
    Create: "/api/v1/yobousien-plan-create",
    Update: "/api/v1/yobousien-plan-update",
    Delete: "/api/v1/yobousien-plan-delete",
    GetQuote: "/api/v1/yobousien-plan-quote",
    GetKihonCheck: "/api/v1/yobousien-get-kihon-check",
    GetFirstPlanDate: "/api/v1/yobousien-get-first-date",
  },
  Hyoukahyou: {
    GetList: "/api/v1/hyoukahyou-list",
    GetById: "/api/v1/hyoukahyou-by-id",
    GetByIdYobou: "/api/v1/hyoukahyou-get-yobou",
    Create: "/api/v1/hyoukahyou-create",
    Update: "/api/v1/hyoukahyou-update",
    Delete: "/api/v1/hyoukahyou-delete",
    GetQuote: "/api/v1/hyoukahyou-quote",
    GetLatest: "/api/v1/hyoukahyou-latest",
  },
};
