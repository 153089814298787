import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";
import { formatStorage, getMenu } from "../common/function_common/functionCommon";

export const reportAdlApi = {
  async loadAdl(params) {
    const key = formatStorage(getMenu());
    const res = await axios
      .get(apiUrls.GET_REPORT_ADL_BY_RIYOUSYA, {
        params: {
          sid: key.si,
          systemName: key.sn,
          riyousyaId: params.riyousyaId,
          excludeId: params.excludeId,
        },
      });
    return res.data;
  },
  async loadAdlById(adlId) {
    const res = await axios.get(apiUrls.GET_REPORT_ADL_BY_iD, {
      params: {
        id: adlId,
      }
    });
    return res.data;
  },
  async getSettingMasterAdl(sid) {
    const res = await axios.get(apiUrls.GET_ADL_SETTING_MASTER, {
      params: {
        sid,
      }
    });
    return res.data;
  },
  async getAdlMaster(data) {
    const res = await axios.get(apiUrls.GET_ADL_MASTER, {
      params: {
        bunrui: data,
      }
    });
    return res.data;
  },
  async deleteAdl(adlId) {
    const res = await axios.delete(apiUrls.DELETE_REPORT_ADL, {
      params: {
        id: adlId
      }
    });
    return res.data;
  },

  async updateAdl(params) {
    // const sid = formatStorage(getMenu()).si;
    const payload = { ...params };
    const res = await axios.post(apiUrls.UPDATE_REPORT_ADL, payload);
    return res.data;
  },

};
