import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { iryouhokenActionTypes } from "../../actions/iryouhokenMasterActions/actionName";

export default function iryouhokenMaster(state = {}, action) {
  switch (action.type) {
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_SPIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //getList
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_GET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_GET_LIST_SUCCESS:
      return {
        ...state,
        datas: action.data,
        loading: false,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_GET_LIST_ERROR:
      return {
        ...state,
        datas: null,
        loading: false,
      };

    //init
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_INIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_INIT_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_INIT_ERROR:
      return {
        ...state,
        loading: true,
      };

    // create
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_CREATE_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        create: action.data,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_CREATE_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        create: null,
        loading: false,
      };

    // update
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATE_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: true,
        update: action.data,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATE_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
        update: null,
      };

    // updateList
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        updateList: action.data,
        loading: false,
      };
    case iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_ERROR:
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        updateList: null,
        loading: false,
      };

    // maxSort
    case iryouhokenActionTypes.LOAD_MAX_SORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case iryouhokenActionTypes.LOAD_MAX_SORT_SUCCESS:
      return {
        ...state,
        maxSort: action.data,
        loading: false,
      };
    case iryouhokenActionTypes.LOAD_MAX_SORT_ERROR:
      return {
        ...state,
        maxSort: null,
        loading: false,
      };
    default:
      return state;
  }
}
