/* eslint-disable */
import { Row } from 'antd';
import _ from "lodash";
import 'moment/locale/ja';
import React, { Component } from 'react';
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from '../../common/component/SubHeaderTitleV2';
import SubHeaderV2 from '../../common/component/SubHeaderV2';
import MenuBusiness from "../../common/component/UI/MenuBusinessV2";
import MenuRecord from "../../common/component/UI/MenuRecordV2";
import { ConstSet } from '../../common/configs/constset';
import { getPath } from "../../common/function_common/functionCommon";
import { checkMenuDis } from '../../common/function_common/functionDisplay';
import MenuTop from "../../management/components/MenuTop/index";
import "./Progress.css";
import { ProgressRecordByDay } from "./UI/ProgressRecordByDay";

const filterObj = {
    search: {
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,
        checkbox8: false,
        checkbox9: false,
        checkbox12: false,
        field2: null,
        field3: null,
        field4: null,
        field5: null,
        field6: null,
        field7: null,
        field8: null,
        field9: null,
        field10: null,
        field11: null,
        field12: null
    },
    sort: {
        key1: "0",
        key2: "0",
        key3: "0",
        key4: "0",
        key5: "0",
        value1: "asc",
        value2: "asc",
        value3: "asc",
        value4: "asc",
        value5: "asc"
    },
    riyouUse: false,  //利用中
};

class ProgressRecord extends Component {
    constructor(props) {
        let dayKeikaFilter = JSON.parse(localStorage.getItem("dayKeikaFilter"));
        if (!dayKeikaFilter) {
            dayKeikaFilter = filterObj;
            localStorage.setItem("dayKeikaFilter", JSON.stringify(dayKeikaFilter));
        }
        super(props);
        this.state = {
            startDate: this.props.filterConditions.startDate,
            endDate: this.props.filterConditions.endDate,
            showMessageOnly: this.props.filterConditions.tyuif,
            showDailyJournalOnly: this.props.filterConditions.nishif,
            showUseOnly: dayKeikaFilter?.riyouUse ? dayKeikaFilter.riyouUse : false,
            expandAll: false,
            queryData: false,
            filterData: dayKeikaFilter
        };
    }

    getReportRiyousya(value) {
        const path = getPath();
        if (value) {
            this.props.history.push(path + "/progress/" + value);
        }
    }

    componentDidMount() {
        if (!this.props.filterMasterData)
            this.props.getFilterMasterData();
        if (!this.props.tantoMasters)
            this.props.getTantoMasterList();
        if (!this.props.systemNames)
            this.props.getSystemNameList();
        if (_.isEmpty(this.props.moshiokuri)) {
            var filter = this.props.filterConditions;
            filter.search = this.state.filterData.search;
            filter.sort = this.state.filterData.sort;

            this.setState({
                queryData: true
            });
            this.props.getReportCases({
                startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
                endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
                tyuif: this.state.showMessageOnly ? "1" : null,
                nishif: this.state.showDailyJournalOnly ? "1" : null,
                riyouf: this.state.showUseOnly ? "1" : null,
            }, filter);
        }
        this.props.saveOpenScreenLog();
    }

    handleRangePickerChange(date, dateString) {
        this.setState({
            startDate: date[0],
            endDate: date[1],
            queryData: true
        });
        this.props.getReportCases({
            riyousyaId: this.state.riyousyaId,
            startDate: date[0].format(ConstSet.DATE_FORMAT),
            endDate: date[1].format(ConstSet.DATE_FORMAT),
            tyuif: this.state.showMessageOnly ? "1" : null,
            nishif: this.state.showDailyJournalOnly ? "1" : null,
            riyouf: this.state.showUseOnly ? "1" : null,
        }, this.props.filterConditions);
    }

    handleChangeMessageCheckbox() {
        let newValue = !this.state.showMessageOnly;
        this.setState({
            showMessageOnly: newValue,
            queryData: true
        });
        this.props.getReportCases({
            riyousyaId: this.state.riyousyaId,
            startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
            endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
            tyuif: newValue ? "1" : null,
            nishif: this.state.showDailyJournalOnly ? "1" : null,
            riyouf: this.state.showUseOnly ? "1" : null,
        }, this.props.filterConditions)
    }

    handleChangeDailyJournalCheckbox() {
        let newValue = !this.state.showDailyJournalOnly;
        this.setState({
            showDailyJournalOnly: newValue,
            queryData: true
        });
        this.props.getReportCases({
            riyousyaId: this.state.riyousyaId,
            startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
            endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
            tyuif: this.state.showMessageOnly ? "1" : null,
            nishif: newValue ? "1" : null,
            riyouf: this.state.showUseOnly ? "1" : null,
        }, this.props.filterConditions)
    }

    handleChangeUseCheckbox() {
        let newValue = !this.state.showUseOnly;
        this.setState({
            showUseOnly: newValue,
            queryData: true
        });

        const dayKeikaFilter = JSON.parse(localStorage.getItem("dayKeikaFilter"));
        dayKeikaFilter.riyouUse = newValue;
        localStorage.setItem("dayKeikaFilter", JSON.stringify(dayKeikaFilter));

        this.props.getReportCases({
            riyousyaId: this.state.riyousyaId,
            startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
            endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
            tyuif: this.state.showMessageOnly ? "1" : null,
            nishif: this.state.showDailyJournalOnly ? "1" : null,
            riyouf: newValue ? "1" : null,
        }, this.props.filterConditions)
    }

    //条件検索時
    handleFilterOk(filterData) {
        const dayKeikaFilter = JSON.parse(localStorage.getItem("dayKeikaFilter"));
        dayKeikaFilter.search = filterData.search;
        localStorage.setItem("dayKeikaFilter", JSON.stringify(dayKeikaFilter));

        this.setState({
            queryData: true
        });
        this.props.getReportCases({
            riyousyaId: this.state.riyousyaId,
            startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
            endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
            tyuif: this.state.showMessageOnly ? "1" : null,
            nishif: this.state.showDailyJournalOnly ? "1" : null,
            riyouf: this.state.showUseOnly ? "1" : null,
        }, filterData)
    }

    //並替時
    handleSortOk(filterData) {
        const dayKeikaFilter = JSON.parse(localStorage.getItem("dayKeikaFilter"));
        dayKeikaFilter.sort = filterData.sort;
        localStorage.setItem("dayKeikaFilter", JSON.stringify(dayKeikaFilter));

        this.props.getReportCases({
            riyousyaId: this.state.riyousyaId,
            startDate: this.state.startDate.format(ConstSet.DATE_FORMAT),
            endDate: this.state.endDate.format(ConstSet.DATE_FORMAT),
            tyuif: this.state.showMessageOnly ? "1" : null,
            nishif: this.state.showDailyJournalOnly ? "1" : null,
            riyouf: this.state.showUseOnly ? "1" : null,
        }, filterData)
    }

    deleteReportCase(reportCaseId) {
        if (reportCaseId) {
            this.props.deleteReportCase(reportCaseId.toString());
        }
    }

    updateKakuninComment(data) {
        if (data && data.reportCaseId) {
            this.props.updateKakuninComment(data);
        }
    }

    goToInputReportCase(data) {
        const path = getPath();
        if (data.riyousyaId) {
            let reportCase = _.find(this.props.reportCases, rc => rc.reportCaseId == data.reportCaseId);
            this.setState({
                riyousyaId: data.riyousyaId,
                riyousayInfo: {
                    riyousya: {
                        id: data.riyousyaId,
                        name: data.riyousyaName
                    }
                },
                reportCase: reportCase
            })
            this.props.history.push(`${path}/input-report-case/${data.riyousyaId}/${data.reportCaseId}`);
        } else {
            this.props.getRiyousyaInfo(data);
            this.setState({
                riyousyaId: data,
                reportCase: null
            });
            this.props.history.push(`${path}/input-report-case/${data}/new`);
        }
    }

    handleGoBack() {
        this.props.history.goBack();
    }

    onExpandChange() {
        this.setState({
            expandAll: !this.state.expandAll
        })
    }

    render() { // DOM
        const { reportCases } = this.props
        let currentMenu = checkMenuDis();

        return (
            <CommonPageContainer id={"report-case-page"} sessionTimeout={this.props.sessionTimeout}>
                <Row className={"submenu"}>
                    {this.props.history.location.pathname.indexOf("management") === 1 ? (
                        <MenuTop
                            isDengon={true}
                            title={"経過記録一覧（日別）"}
                            iconType="message"
                            history={this.props.history}
                            count={true}
                            countNum={reportCases ? reportCases.length : 0}
                        />
                    ) : (
                        <SubHeaderV2
                            TopLeft={
                                <SubHeaderTitleV2
                                    title={"経過記録一覧（日別）"}
                                    iconType="message"
                                    count={true}
                                    countNum={reportCases ? reportCases.length : 0}
                                />
                            }
                            TopRight2={
                                currentMenu.indexOf("record") >= 0 ?
                                    <MenuRecord isProgress={true} />
                                    :
                                    <MenuBusiness isProgress={true} />
                            }
                        />)}
                </Row>
                <ProgressRecordByDay
                    data={_.isEmpty(this.props.moshiokuri) || this.state.queryData ? this.props.reportCases : this.props.moshiokuri}

                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    handleRangePickerChange={(date, dateString) => this.handleRangePickerChange(date, dateString)}

                    filterMasterData={this.props.filterMasterData}
                    tantoMasters={this.props.tantoMasters}
                    systemNames={this.props.systemNames}
                    filterConditions={this.props.filterConditions}
                    handleFilterData={filterData => this.handleFilterOk(filterData)}
                    riyousyaBunrui={false}

                    // handleSortData={filterData => this.props.sortReportCases(filterData)}
                    // reload data when sort. reload -> sort
                    handleSortData={filterData => this.handleSortOk(filterData)}

                    showMessageOnly={this.state.showMessageOnly}
                    showDailyJournalOnly={this.state.showDailyJournalOnly}
                    showUseOnly={this.state.showUseOnly}
                    handleChangeMessageCheckbox={() => this.handleChangeMessageCheckbox()}
                    handleChangeDailyJournalCheckbox={() => this.handleChangeDailyJournalCheckbox()}
                    handleChangeUseCheckbox={() => this.handleChangeUseCheckbox()}

                    handleGoToUser={(value) => this.getReportRiyousya(value)}
                    handleGoToInputReportCase={data => this.goToInputReportCase(data)}
                    handleDeleteReportCase={reportCaseId => this.deleteReportCase(reportCaseId)}
                    handleKCommentReportCase={data => this.updateKakuninComment(data)}

                    expandAll={this.state.expandAll}
                    handleChangeExpand={() => this.onExpandChange()} />
            </CommonPageContainer>
        )
    }
}

export default ProgressRecord;
