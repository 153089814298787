import {
  CaretDownOutlined,
  CaretUpOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

import { Button, Col, Divider, Input, Modal, Row, Select, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSisetuKaigoNaiyouList,
  getSisetuKaigoNaiyouListAll,
  saveSisetuKaigoNaiyouList,
} from "../../../actions/sisetuKaigoNaiyouMasterActions/actionCreators";
import {
  yougoMasterNaiyou2Creator,
  yougoMasterNaiyouCreator,
  yougoMasterNaiyouKubunCreator,
  yougoMasterTantoCreator,
} from "../../../actions/yougoMasterNaiyouActions/actionCreators";
import { getValueIskeep, setValueIsKeep } from "../../../common/function_common/functionCommon";
import { KaigoNaiyouEditModal } from "./KaigoNaiyouEditModal";

const { Search } = Input;
const { Option } = Select;

function PopoverKaigoNaiyou(props) {
  // const [data, setData] = useState([]);
  let sKaigoBunrui = getValueIskeep("sKaigoBunrui");
  sKaigoBunrui = sKaigoBunrui ? JSON.parse(sKaigoBunrui) : null;
  const [bunrui, setBunrui] = useState(sKaigoBunrui && sKaigoBunrui.bunrui ? sKaigoBunrui.bunrui : "");
  const [bunrui2, setBunrui2] = useState(sKaigoBunrui && sKaigoBunrui.bunrui2 ? sKaigoBunrui.bunrui2 : "");
  const [searchName, setSearchName] = useState(sKaigoBunrui && sKaigoBunrui.name ? sKaigoBunrui.name : "");
  const [page, setPage] = useState(1);
  const [kaigoNaiyouData, setKaigoNaiyouData] = useState(null);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editType, setEditType] = useState(null);
  const [sort, setSort] = useState(false);
  const [sortTable, setSortTable] = useState([]);
  const [sortTableCopy, setSortTableCopy] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (props.visiblePopover) {
      props.getSisetuKaigoNaiyouList(bunrui, searchName, page, bunrui2);
      props.yougoMasterNaiyouCreator("施設介護内容分類");
      props.yougoMasterNaiyou2Creator("施設介護内容分類2");
      props.yougoMasterNaiyouKubunCreator("施設記録区分");
      props.yougoMasterTantoCreator("介護担当者");
    }
  }, [props.visiblePopover]);

  useEffect(() => {
    props.getSisetuKaigoNaiyouList(bunrui, searchName, page, bunrui2);
  }, [props.kaigoNaiyouAll]);

  useEffect(() => {
    if (props.kaigoNaiyou) {
      setKaigoNaiyouData(props.kaigoNaiyou);
    }
  }, [props.kaigoNaiyou]);

  // const handleVisibleChange = visible => {
  //   if (!state.visible) {
  //     props.getSisetuKaigoNaiyouList(bunrui, searchName, page);
  //   }
  //   props.handleVisible(visible);
  // };
  // const handleSelectKaigoNaiyou = (record) => {
  //   // ここでレコードを選択している
  //   props.selectRecordKaigo(record);
  // };
  const handlePageChange = (page) => {
    setPage(page);
    props.getSisetuKaigoNaiyouList(bunrui, searchName, page, bunrui2);
  };
  const handleChangeBunrui = (value) => {
    setBunrui(value);
  };
  const handleChangeBunrui2 = (value) => {
    setBunrui2(value);
  };
  const handleFilterTanto = (value) => {
    props.getSisetuKaigoNaiyouList(bunrui, value, page, bunrui2);
    setSearchName(value);

    // save to localstoeage to reuse
    setValueIsKeep("sKaigoBunrui", JSON.stringify({ bunrui, name: value, bunrui2 }));
  };

  const handleSearchChange = (e) => {
    setSearchName(e.target.value);
  };

  const handleSelectRow = (record) => {
    setSelected(record);
  };
  const handleAdd = () => {
    setVisibleEdit(true);
    setEditType(1); // add
  };

  const handleEdit = () => {
    setVisibleEdit(true);
    setEditType(2); // edit
  };
  const handleCancel = () => {
    // props.getSisetuKaigoNaiyouList("", "", 1);

    setSort(false);
    // setBunrui("");
    // setBunrui2("");
    // setSearchName("");
    setPage(1);
    setSelected(null);

    // save to localstoeage to reuse
    // setValueLocalstorage("sKaigoBunrui", JSON.stringify({ bunrui: "", name: "", bunrui2: "" }));

    props.handleClose();
  };
  const handleOk = () => {
    props.selectRecordKaigo(selected ? selected : null);

    setSelected(null);
    setSort(false);
  };
  const handleEditOk = () => {
    props.getSisetuKaigoNaiyouList(bunrui, searchName, page, bunrui2);

    setSelected(null);
    setVisibleEdit(false);
    setEditType(null);
  };
  const handleEditClose = () => {
    // データの読み込みをする
    setSelected(null);
    setVisibleEdit(false);
    setEditType(null);
  };
  async function softTableOn() {
    await props.getSisetuKaigoNaiyouListAll(bunrui, searchName, bunrui2, (callback) => {
      const tmp = JSON.parse(JSON.stringify(callback.data));
      const tmp2 = JSON.parse(JSON.stringify(callback.data));
      setSortTable(tmp);
      setSortTableCopy(tmp2);
    });
    return true;
  }

  async function sortOn() {
    const tmp = await softTableOn();

    setSort(tmp);
  }
  const sortNot = () => {
    setSort(false);
    setSortTable([]);
    setSortTableCopy([]);
  };
  const sortOff = () => {
    props.getSisetuKaigoNaiyouList(bunrui, searchName, page, bunrui2);

    setTimeout(() => {
      sortNot();
    }, 300);
  };
  async function sortSave() {
    const tmp = [];
    for (const record of sortTable) {
      const defTable = sortTableCopy.find((t) => t.id == record.id);
      if (record.sortNum != defTable.sortNum) {
        tmp.push({
          // entityId: record.entityId,
          id: record.id,
          sortNum: record.sortNum,
          kaigoBunrui: record.kaigoBunrui,
          kaigoBunrui2: record.kaigoBunrui2,
          kaigoNaiyou: record.kaigoNaiyou,
          kaigoNaiyouCode: record.kaigoNaiyouCode,
          kirokuKubun: record.kirokuKubun,
          kyotuf: record.kyotuf,
          // persistent: record.persistent,
          ryaku: record.ryaku,
          serviceKikanMasterId: record.serviceKikanMasterId,
          stop: record.stop,
          tantosya: record.tantosya,
        });
      }
    }

    await props.saveSisetuKaigoNaiyouList(sortTable, () => {
      props.getSisetuKaigoNaiyouList(bunrui, searchName, page, bunrui2);
    });

    setTimeout(() => {
      sortNot();
    }, 500);
  }
  const sortTop = (record) => {
    const sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const minNum = sortTableTmp[0].sortNum;

    if (elementIndex != 0) {
      sortTableTmp.splice(elementIndex, 1);
      element.sortNum = minNum - 1;
      sortTableTmp.splice(0, 0, element);

      setSortTable(sortTableTmp);
    }
  };
  const sortUp = (record) => {
    const sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTableTmp.findIndex((v) => v.id === element.id);

    if (elementIndex != 0) {
      const changeElement = sortTableTmp[elementIndex - 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex - 1;

      sortTableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum--;
        sortTableTmp.splice(changeElementIndex, 0, element);
      }
      else {
        element.sortNum = changeElementSortNum;
        sortTableTmp.splice(changeElementIndex, 0, element);
        sortTableTmp[elementIndex].sortNum++;
      }

      setSortTable(sortTableTmp);
    }
  };
  const sortDown = (record) => {
    const sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTableTmp.findIndex((v) => v.id === element.id);

    if (elementIndex != sortTable.length - 1) {
      const changeElement = sortTable[elementIndex + 1];
      const changeElementSortNum = changeElement.sortNum;
      const changeElementIndex = elementIndex + 1;

      sortTableTmp.splice(elementIndex, 1);
      if (element.sortNum == changeElementSortNum) {
        element.sortNum++;
        sortTableTmp.splice(changeElementIndex, 0, element);
      }
      else {
        element.sortNum = changeElementSortNum;
        sortTableTmp.splice(changeElementIndex, 0, element);
        sortTableTmp[elementIndex].sortNum--;
      }

      setSortTable(sortTableTmp);
    }
  };
  const sortBottom = (record) => {
    const sortTableTmp = JSON.parse(JSON.stringify(sortTable));
    let maxNum = 0;
    for (const i of sortTableTmp) {
      maxNum < i.sortNum ? (maxNum = i.sortNum) : maxNum;
    }
    const element = JSON.parse(JSON.stringify(record));
    const elementIndex = sortTable.findIndex((v) => v.id === element.id);
    const bottomIndex = sortTable.length - 1;

    if (elementIndex != bottomIndex) {
      sortTableTmp.splice(elementIndex, 1);
      element.sortNum = maxNum + 1;
      sortTableTmp.splice(sortTable.length, 0, element);

      setSortTable(sortTableTmp);
    }
  };

  const paging = {
    onChange: (page) => {
      handlePageChange(page);
    },
    total: props.meta ? props.meta.total : 0,
    current: props.meta ? props.meta.page : 1,
    pageSize: props.meta ? props.meta.pageSize : 20,
  };

  const columns = [
    {
      title: "介護内容コード",
      dataIndex: "kaigoNaiyouCode",
      width: "30%",
    },
    // { //テスト用
    //   dataIndex: "sortNum",
    //   key: "sortNum",
    //   width: "30%",
    // },
    {
      title: "介護内容",
      dataIndex: "kaigoNaiyou",
      width: "30%",
    },
    {
      title: "担当者",
      dataIndex: "tantosya",
      width: "30%",
    },
  ];
  const columns2 = [
    {
      title: "介護内容コード",
      dataIndex: "kaigoNaiyouCode",
      width: "30%",
    },
    // { //テスト用
    //   dataIndex: "sortNum",
    //   key: "sortNum",
    //   width: "30%",
    // },
    {
      title: "介護内容",
      dataIndex: "kaigoNaiyou",
      width: "30%",
    },
    {
      title: "",
      dataIndex: "operation",
      width: "30%",
      key: "operation",
      render: (record, index) => {
        return (
          <div>
            <span>
              <a onClick={() => sortTop(index)} style={{}}>
                <StepBackwardOutlined rotate={90} style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortUp(index)} style={{}}>
                <CaretUpOutlined style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortDown(index)} style={{}}>
                <CaretDownOutlined style={{ fontSize: "16px" }} />
              </a>
              <Divider type="vertical" />
              <a onClick={() => sortBottom(index)} style={{}}>
                <StepForwardOutlined rotate={90} style={{ fontSize: "16px" }} />
              </a>
            </span>
          </div>
        );
      },
    },
  ];

  const footer = (
    <div>
      {sort == false ? (
        <span>
          <a
            key="sort"
            // type={"primary"}
            style={{
              float: "left",
              color: "black",
              marginTop: 5,
              marginLeft: 5,
            }}
            onClick={() => sortOn()}
          >
            並び替え
          </a>
        </span>
      ) : sort == true ? (
        <span>
          <Button
            key="sortSave"
            type={"primary"}
            style={{ float: "left", marginRight: 10 }}
            onClick={() => sortSave()}
          >
            並び替え保存
          </Button>
          <Button
            key="back"
            style={{ float: "left" }}
            onClick={() => sortOff()}
          >
            戻る
          </Button>
        </span>
      ) : null}
      <Button
        key="submit"
        style={{}}
        type="primary"
        onClick={() => handleOk()}
        disabled={sort == true ? true : !selected}
      >
        OK
      </Button>
      <Button
        key="back"
        style={{ marginRight: 10 }}
        onClick={() => handleCancel()}
      >
        キャンセル
      </Button>
    </div>
  );

  function isDisableButton(selected) {
    return selected && props.kaigoNaiyou
      ? !props.kaigoNaiyou.some((item) => item.id == selected.id)
      : true;
  }

  return (
    (<div>
      <Modal
        open={props.visiblePopover}
        title="施設介護内容"
        className="modal-list"
        style={{ marginBottom: 20 }}
        footer={footer}
        closable={false}
      >
        <Row className="pad-bt-8">
          <Col span={12}>
            <Select
              value={bunrui}
              style={{ width: "100%" }}
              onChange={(value) => handleChangeBunrui(value)}
              disabled={sort == true}
            >
              <Option value="" key={0}>
                全表示
              </Option>
              {Array.isArray(props.yougoMasterNaiyou)
                ? props.yougoMasterNaiyou.map((item) => {
                  return (
                    <Option key={item.key} value={item.naiyou}>
                      {item.naiyou}
                    </Option>
                  );
                })
                : []}
            </Select>
          </Col>
          <Col span={12}>
            <Select
              value={bunrui2}
              style={{ width: "100%" }}
              onChange={(value) => handleChangeBunrui2(value)}
              disabled={sort == true}
            >
              <Option value="" key={0}>
                全表示
              </Option>
              {Array.isArray(props.yougoMasterNaiyou2)
                ? props.yougoMasterNaiyou2.map((item) => {
                  return (
                    <Option key={item.key} value={item.naiyou}>
                      {item.naiyou}
                    </Option>
                  );
                })
                : []}
            </Select>
          </Col>
          <Col span={24}>
            <Search
              enterButton={"検索"}
              onSearch={(value) => handleFilterTanto(value)}
              onChange={(value) => handleSearchChange(value)}
              disabled={sort == true}
              style={{ marginTop: 4 }}
              value={searchName}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: -1, marginRight: -20 }} />
        <Row style={{ display: "flex" }}>
          <Button
            key="add"
            type={"primary"}
            onClick={() => handleAdd()}
            disabled={sort == true}
            style={{ marginRight: 8, marginTop: -15 }}
          >
            追加
          </Button>
          <Button
            key="edit"
            type={"primary"}
            onClick={() => handleEdit()}
            disabled={sort == true ? true : isDisableButton(selected)}
            style={{ marginRight: 8, marginTop: -15 }}
          >
            修正
          </Button>
        </Row>
        <Row style={{ marginTop: 5 }}>
          <Spin spinning={props.loading}>
            {sort == false ? (
              <Table
                className="modal-table"
                bordered
                columns={columns}
                dataSource={kaigoNaiyouData ? kaigoNaiyouData : null}
                pagination={paging}
                scroll={{ y: 227 }}
                size={"small"}
                rowKey={"kaigoNaiyouCode"}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleSelectRow(record);
                    }, // click row
                  };
                }}
                // rowClassName="pointer-row"
                rowClassName={(record) =>
                  selected != null && record.id === selected.id
                    ? "pljp-table-row-hover"
                    : "pointer-row"
                }
              />
            ) : sort == true ? (
              <Table
                className="modal-table"
                bordered
                columns={columns2}
                dataSource={sortTable}
                pagination={paging}
                scroll={{ y: 227 }}
                size={"small"}
                rowKey={"kaigoNaiyouCode"}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleSelectRow(record);
                    }, // click row
                  };
                }}
                rowClassName="pointer-row"
              />
            ) : null}
          </Spin>
        </Row>
      </Modal>
      <KaigoNaiyouEditModal
        visible={visibleEdit}
        data={selected}
        editType={editType}
        handleSelectRow={(record) => handleSelectRow(record)}
        handleClose={() => handleEditClose()} // 作る
        handleOk={() => handleEditOk()}
        listBunrui={props.yougoMasterNaiyouBunrui}
        dataNaiyou={props.yougoMasterNaiyouC}
        dataNaiyou2={props.yougoMasterNaiyou2C}
        dataTanto={props.yougoMasterTanto}
      />
    </div>)
  );
}

const mapStateToProps = (state) => {
  return {
    kaigoNaiyou: state.sisetuKaigoNaiyouMaster.kaigoNaiyou,
    meta: state.sisetuKaigoNaiyouMaster.meta,
    yougoMasterNaiyou: state.yougoMasterNaiyou.yougoMasterNaiyou,
    yougoMasterNaiyouC: state.yougoMasterNaiyou.yougoMasterNaiyouC,
    yougoMasterNaiyou2: state.yougoMasterNaiyou.yougoMasterNaiyou2,
    yougoMasterNaiyou2C: state.yougoMasterNaiyou.yougoMasterNaiyou2C,
    yougoMasterNaiyouBunrui: state.yougoMasterNaiyou.yougoMasterNaiyouBunrui,
    yougoMasterBunrui: state.yougoMasterNaiyou.yougoMasterBunrui,
    yougoMasterTanto: state.yougoMasterNaiyou.yougoMasterTanto,
    kaigoNaiyouAll: state.sisetuKaigoNaiyouMaster.kaigoNaiyouList,
    loading: state.sisetuKaigoNaiyouMaster.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSisetuKaigoNaiyouList: (bunrui, searchName, page, bunrui2) =>
      dispatch(getSisetuKaigoNaiyouList(bunrui, searchName, page, null, bunrui2)),
    yougoMasterNaiyouCreator: (listName) =>
      dispatch(yougoMasterNaiyouCreator(listName)),
    yougoMasterNaiyou2Creator: (listName) =>
      dispatch(yougoMasterNaiyou2Creator(listName)),
    yougoMasterNaiyouKubunCreator: (listName) =>
      dispatch(yougoMasterNaiyouKubunCreator(listName)),
    yougoMasterTantoCreator: (listName) =>
      dispatch(yougoMasterTantoCreator(listName)),
    getSisetuKaigoNaiyouListAll: (bunrui, searchName, bunrui2, callback) =>
      dispatch(getSisetuKaigoNaiyouListAll(bunrui, searchName, bunrui2, callback)),
    saveSisetuKaigoNaiyouList: (data, callback) =>
      dispatch(saveSisetuKaigoNaiyouList(data, callback)),
  };
};

const _PopoverKaigoNaiyou = connect(
  mapStateToProps,
  mapDispatchToProps
)(PopoverKaigoNaiyou);

export { _PopoverKaigoNaiyou as PopoverKaigoNaiyou };
