export const ITAKUTANTO_MASTER_SPIN_REQUEST = "ITAKUTANTO_MASTER_SPIN_REQUEST";

export const ITAKUTANTO_MASTER_LOAD_REQUEST = "ITAKUTANTO_MASTER_LOAD_REQUEST";
export const ITAKUTANTO_MASTER_CREATE_REQUEST =
  "ITAKUTANTO_MASTER_CREATE_REQUEST";
export const ITAKUTANTO_MASTER_UPDATE_REQUEST =
  "ITAKUTANTO_MASTER_UPDATE_REQUEST";
export const ITAKUTANTO_MASTER_INIT_REQUEST = "ITAKUTANTO_MASTER_INIT_REQUEST";

export const LOAD_ITAKUTANTO_MASTER_SUCCESS = "LOAD_ITAKUTANTO_MASTER_SUCCESS";
export const LOAD_ITAKUTANTO_MASTER_ERROR = "LOAD_ITAKUTANTO_MASTER_ERROR";

export const LOAD_MAX_SORT_SUCCESS = "LOAD_MAX_SORT_SUCCESS";
export const LOAD_MAX_SORT_ERROR = "LOAD_MAX_SORT_ERROR";

export const LOAD_ITAKUTANTO_MASTER_INIT_SUCCESS =
  "LOAD_ITAKUTANTO_MASTER_INIT_SUCCESS";
export const LOAD_ITAKUTANTO_MASTER_INIT_ERROR =
  "LOAD_ITAKUTANTO_MASTER_INIT_ERROR";

export const CREATE_ITAKUTANTO_MASTER_SUCCESS =
  "CREATE_ITAKUTANTO_MASTER_SUCCESS";
export const CREATE_ITAKUTANTO_MASTER_ERROR = "CREATE_ITAKUTANTO_MASTER_ERROR";

export const UPDATE_ITAKUTANTO_MASTER_SUCCESS =
  "UPDATE_ITAKUTANTO_MASTER_SUCCESS";
export const UPDATE_ITAKUTANTO_MASTER_ERROR = "UPDATE_ITAKUTANTO_MASTER_ERROR";
