import { ApiPaths } from "../../constants/api_paths";
import { MonitoringMNGTActionType } from "../action_types";
import { RequestUtils } from "../../utils/index";
import { CommonUtils, StorageUtils } from "../../utils/index";

function createBatch(date, tantoId) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue("menuMNGT");
      const si = menu.si || null;
      const body = {
        date,
        tantoId,
        sid: si ? +si : null,
      };
      dispatch({ type: MonitoringMNGTActionType.CREATE_BATCH_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.MonitoringMNGT.CreateBatch,
        body,
      });

      dispatch({
        type: MonitoringMNGTActionType.CREATE_BATCH_SUCCESS,
        createBatch: { ...data },
      });
    } catch (error) {
      dispatch({
        type: MonitoringMNGTActionType.CREATE_BATCH_FAILURE,
        error,
      });
    }
  };
}

function getAll(params) {
  return async (dispatch) => {
    try {
      dispatch({ type: MonitoringMNGTActionType.GET_ALL_REQUEST });
      const menu = StorageUtils.getValue("menuMNGT");
      const si = menu.si || null;
      const query = {
        sid: si,
        searchName: params.searchName,
        tantoId: params.tantoId,
        dateMonth: params.date.format("YYYY-MM"),
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(params.sort)),
        page: params.page,
      };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.MonitoringMNGT.GetAll,
        query,
      });
      dispatch({
        type: MonitoringMNGTActionType.GET_ALL_SUCCESS,
        getAll: data,
      });
    } catch (error) {
      dispatch({
        type: MonitoringMNGTActionType.GET_ALL_FAILURE,
        error,
      });
    }
  };
}

function keepValueSearch(params) {
  return (dispatch) => {
    dispatch({
      type: MonitoringMNGTActionType.KEEP_VALUE_SEARCH,
      keepValue: params,
    });
  };
}

export const MonitoringMNGT = {
  createBatch,
  getAll,
  keepValueSearch,
};
