import React, { Component } from "react";
import { Select } from "antd";

const { Option } = Select;

export default class PlanSelect extends Component {
  render() {
    return (
      <div style={this.props.style}>
        <Select
          style={{ width: "133px" }}
          size={"small"}
          value={this.props.tabActive}
          onChange={(value) => this.props.handleChangeTab(value)}
        >
          <Option value="1">{"計画書１"}</Option>
          <Option value="2">{"計画書２"}</Option>
          <Option value="3">{"日課"}</Option>
        </Select>
      </div>
    );
  }
}
