import {
  LOAD_INDIVIDUAL_PLAN_SUCCESS,
  CREATE_INDIVIDUAL_PLAN_SUCCESS,
  UPDATE_INDIVIDUAL_PLAN_SUCCESS,
  DELETE_INDIVIDUAL_PLAN_SUCCESS,
  LOAD_EVENT_PLAN_SUCCESS,
  CREATE_EVENT_PLAN_SUCCESS,
  UPDATE_EVENT_PLAN_SUCCESS,
  DELETE_EVENT_PLAN_SUCCESS,
  LOAD_FILTER_MASTER_SUCCESS,
  LOAD_NYUTAISHO_DATA_SUCCESS,
  FILTER_RIYOUSYA_SUCCESS,
  FACILITY_PLAN_YOUGO_SUCCESS,
  FACILITY_PLAN_YOUGO_EDIT_STATE,
  FACILITY_PLAN_YOUGO_CANCEL_STATE,
} from "./actionName.js";
import {
  IndividualPlanApi,
  EventPlanApi,
  LoadRiyousyaDataApi,
} from "../../api/plan";
import { YougoMasterNaiyouApi } from "../../api/yougoMasterNaiyou";
import DataFilterApi from "../../api/reportFilter";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

export function loadIndividualPlans(riyousyaIds, startDate, endDate) {
  return (dispatch) => {
    IndividualPlanApi.loadIndividualPlan(riyousyaIds, startDate, endDate).then(
      (data) =>
        dispatch({
          type: LOAD_INDIVIDUAL_PLAN_SUCCESS,
          individualPlans: data,
        })
    );
  };
}

export function createRiyousyaPlan(riyousyaId, date, yotei, sonota, callback) {
  return (dispatch) => {
    IndividualPlanApi.createIndividualPlan(
      riyousyaId,
      date,
      yotei,
      sonota
    ).then((data) => {
      callback();
      return dispatch({
        type: CREATE_INDIVIDUAL_PLAN_SUCCESS,
        createEventRes: data,
      });
    });
  };
}

export function updateRiyousyaPlan(
  riyousyaPlanId,
  riyousyaId,
  date,
  yotei,
  sonota,
  callback
) {
  return (dispatch) => {
    IndividualPlanApi.updateIndividualPlan(
      riyousyaPlanId,
      riyousyaId,
      date,
      yotei,
      sonota
    ).then((data) => {
      callback();
      return dispatch({
        type: UPDATE_INDIVIDUAL_PLAN_SUCCESS,
        updateEventRes: data,
      });
    });
  };
}

export function deleteRiyousyaPlan(riyousyaPlanId, callback) {
  return (dispatch) => {
    IndividualPlanApi.deleteIndividualPlan(riyousyaPlanId).then((data) => {
      callback();
      return dispatch({
        type: DELETE_INDIVIDUAL_PLAN_SUCCESS,
        deleteEventRes: data,
      });
    });
  };
}

export function loadEventPlans(startDate, endDate) {
  return (dispatch) => {
    EventPlanApi.loadEventPlan(startDate, endDate).then((data) => {
      return dispatch({
        type: LOAD_EVENT_PLAN_SUCCESS,
        eventPlans: data,
      });
    });
  };
}

export function createEventPlan(date, event, callback) {
  return (dispatch) => {
    EventPlanApi.createEventPlan(date, event).then((data) => {
      callback();
      return dispatch({
        type: CREATE_EVENT_PLAN_SUCCESS,
        createEventRes: data,
      });
    });
  };
}

export function updateEventPlan(eventPlanId, date, event, callback) {
  return (dispatch) => {
    EventPlanApi.updateEventPlan(eventPlanId, date, event).then((data) => {
      callback();
      return dispatch({
        type: UPDATE_EVENT_PLAN_SUCCESS,
        updateEventRes: data,
      });
    });
  };
}

export function deleteEventPlan(eventPlanId, callback) {
  return (dispatch) => {
    EventPlanApi.deleteEventPlan(eventPlanId).then((data) => {
      callback();
      return dispatch({
        type: DELETE_EVENT_PLAN_SUCCESS,
        deleteEventRes: data,
      });
    });
  };
}

export function loadFilterMasterSetting() {
  return (dispatch) => {
    DataFilterApi.dataFilterApi("").then((data) =>
      dispatch({
        type: LOAD_FILTER_MASTER_SUCCESS,
        filterMaster: data,
      })
    );
  };
}

export function loadNyutaishoRiyousyaData(startDate, endDate) {
  return (dispatch) => {
    LoadRiyousyaDataApi.loadNyutaishoRiyousya(startDate, endDate).then((data) =>
      dispatch({
        type: LOAD_NYUTAISHO_DATA_SUCCESS,
        allNyutaisho: data,
      })
    );
  };
}

export function filterRiyousyaData(searchData) {
  return (dispatch) =>
    dispatch({
      type: FILTER_RIYOUSYA_SUCCESS,
      searchData,
    });
}

export function getFacilityPlanYougoMaster() {
  return (dispatch) => {
    YougoMasterNaiyouApi.yougoMasterNaiyou("施設予定").then(
      (data) =>
        dispatch({
          type: FACILITY_PLAN_YOUGO_SUCCESS,
          payload: data,
        }),
      (err) => {
        checkError(err);
      }
    );
  };
}

export function saveFacilityPlanYougoMaster(naiyou) {
  const listName = "施設予定";
  return (dispatch) => {
    YougoMasterNaiyouApi.updateYougoMasterApi(listName, naiyou).then(
      (data) =>
        dispatch({
          type: FACILITY_PLAN_YOUGO_SUCCESS,
          payload: data,
        }),
      (err) => {
        if (
          err.response.status === 400 &&
          err.response.data.error.code === "YOUGOMASTER_NOT_FOUND"
        ) {
          YougoMasterNaiyouApi.createYougoMaster(listName, naiyou).then(
            (data) =>
              dispatch({
                type: FACILITY_PLAN_YOUGO_SUCCESS,
                payload: data,
              }),
            (err2) => {
              checkError(err2);
            }
          );
        } else {
          checkError(err);
        }
      }
    );
  };
}

export function yougoMasterEditStateCreator(index) {
  return (dispatch) => {
    dispatch({
      type: FACILITY_PLAN_YOUGO_EDIT_STATE,
      index,
    });
  };
}

/**
 *
 * @param {*} index
 */
export function yougoMasterEditCancelCreator(index) {
  return (dispatch) => {
    dispatch({
      type: FACILITY_PLAN_YOUGO_CANCEL_STATE,
      index,
    });
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/login");
  }
}
