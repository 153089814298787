export const LifeActionTypes = {
  GET_ALL_DOCUMENT_REQUEST: "GET_ALL_DOCUMENT_REQUEST",
  GET_ALL_DOCUMENT_SUCCESS: "GET_ALL_DOCUMENT_SUCCESS",
  GET_ALL_DOCUMENT_FAILURE: "GET_ALL_DOCUMENT_FAILURE",

  EXPORT_CSV_DATE: "EXPORT_CSV_DATE",
  EXPORT_CSV_DATE_REQUEST: "EXPORT_CSV_DATE_REQUEST",

  KEEP_VALUE_SEARCH: "KEEP_VALUE_SEARCH",
};
