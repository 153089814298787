import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Divider, Input, Modal, Popconfirm, Spin, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  getKyotakuPlan2YougoMaster,
  saveKyotakuPlan2YougoMaster,
} from "../../../actions/kyotakuPlan2Actions/actionCreators";
import {
  KP2_YOUGO_CANCEL_STATE,
  KP2_YOUGO_EDIT_STATE,
} from "../../../actions/kyotakuPlan2Actions/actionName";

const EditableCell = ({ editable, value, onChange, valueEdit, Expansion }) => (
  <div>
    {editable ? (
      <Input
        style={{ margin: "-5px 0" }}
        value={valueEdit}
        onChange={(e) => onChange(e.target.value)}
      />
    ) : Expansion ? (
      <div
        style={{
          maxWidth: "240px",
          whiteSpace: "normal",
          overflowWrap: "normal",
        }}
      >
        {value}
      </div>
    ) : (
      <div
        style={{
          maxWidth: "240px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </div>
    )}
  </div>
);

class YougoPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      key: null,
      newText: "",
      Expansion: false,
    };
  }

  componentDidMount() {
    this.props.getYougoMaster(this.props.listName);
  }

  select(naiyou) {
    this.setState({
      Expansion: false,
    });
    this.props.onSubmit(naiyou);
  }

  renderColumns(text, record) {
    const { Expansion } = this.state;
    return (
      <EditableCell
        Expansion={Expansion}
        editable={record.editable}
        value={text}
        valueEdit={this.state.text}
        onChange={(value) => this.handleChange(value, record.key)}
      />
    );
  }

  handleChange(value, key) {
    this.setState({
      text: value,
      key,
    });
  }

  edit(key, naiyou) {
    this.setState({
      text: naiyou,
      key,
    });
    this.props.editYougo(key);
  }

  save() {
    if (this.state.text) {
      const { yougoNaiyou, listName } = this.props;
      let naiyou = "";
      for (let i = 0; i < yougoNaiyou.length; i++) {
        if (i === this.state.key) {
          if (!naiyou) {
            naiyou = this.state.text;
          }
          else naiyou = naiyou + ";" + this.state.text;
        }
        else if (!naiyou) {
          naiyou = yougoNaiyou[i].naiyou;
        }
        else {
          naiyou = naiyou + ";" + yougoNaiyou[i].naiyou;
        }
      }
      this.props.saveYougoMaster(listName, naiyou);
    }
  }

  delete(key) {
    const { yougoNaiyou, listName } = this.props;
    let preNaiyou = "";
    for (let i = 0; i < yougoNaiyou.length; i++) {
      if (i !== key) {
        if (!preNaiyou) {
          preNaiyou = yougoNaiyou[i].naiyou;
        }
        else {
          preNaiyou = preNaiyou + ";" + yougoNaiyou[i].naiyou;
        }
      }
    }
    this.props.saveYougoMaster(listName, preNaiyou);
  }

  cancel(key) {
    this.props.cancelEditYougo(key);
  }

  handleOnchangeNew(e) {
    if (e.target.value.trim() === 0) {
      return;
    }
    this.setState({
      newText: e.target.value,
    });
  }

  add = () => {
    if (this.state.newText && this.state.newText.trim().length > 0) {
      const { yougoNaiyou, listName } = this.props;
      let preNaiyou = this.state.newText.trim();
      for (let i = 0; i < yougoNaiyou.length; i++) {
        preNaiyou = preNaiyou + ";" + yougoNaiyou[i].naiyou;
      }
      this.props.saveYougoMaster(listName, preNaiyou);
      this.setState({
        newText: "",
      });
    }
  };

  onCancel = () => {
    this.setState({
      Expansion: false,
    });
    this.props.onCancel();
  };

  handleExpandTab = () => {
    // パネル全体
    const { Expansion } = this.state;

    if (Expansion) {
      this.setState({
        Expansion: false,
      });
    }
    else {
      this.setState({
        Expansion: true,
      });
    }
  };

  render() {
    const { Expansion } = this.state;
    const { yougoNaiyou } = this.props;
    const text = (
      <span>
        <Input
          onChange={(e) => this.handleOnchangeNew(e)}
          value={this.state.newText}
          style={{ width: "250px", marginRight: "16px" }}
        />
        <a onClick={this.add}>追加</a>
      </span>
    );

    const columns = [
      {
        title: "naiyou",
        dataIndex: "naiyou",
        width: 250,
        render: (text, record) => this.renderColumns(text, record, "naiyou"),
      },
      {
        title: "行動",
        dataIndex: "operation",
        width: 150,
        render: (text, record) => {
          const { editable } = record;
          return (
            <div className="editable-row-operations">
              {editable ? (
                <span>
                  <a onClick={() => this.save()}>保存</a>
                  <Divider type="vertical" />
                  <a onClick={() => this.cancel(record.key)}>閉じる</a>
                </span>
              ) : (
                <span>
                  <a onClick={() => this.select(record.naiyou)}>選択</a>
                  <Divider type="vertical" />
                  <a onClick={() => this.edit(record.key, record.naiyou)}>
                    修正
                  </a>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="削除してもいいですか？"
                    onConfirm={() => this.delete(record.key)}
                  >
                    <a>削除</a>
                  </Popconfirm>
                </span>
              )}
            </div>
          );
        },
      },
    ];
    const content = (
      <div>
        <Table
          bordered
          columns={columns}
          dataSource={yougoNaiyou}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 240 }}
          showHeader={false}
          size={"small"}
          style={{ marginTop: "8px" }}
        />
        <Button
          type="primary"
          icon={<LegacyIcon type={Expansion ? "minus" : "plus"} />}
          // className="modal-expansion"
          style={{ float: "left", marginTop: -40 }}
          onClick={this.handleExpandTab}
        >
          {Expansion ? "格納" : "展開"}
        </Button>
      </div>
    );
    return (
      (<Modal
        destroyOnClose={true}
        open={this.props.visible}
        footer={null}
        onCancel={this.onCancel}
      >
        <Spin spinning={this.props.loadingYougo}>
          {text}
          {content}
        </Spin>
      </Modal>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    yougoNaiyou: state.kyotakuPlan2.yougoNaiyou,
    loadingYougo: state.kyotakuPlan2.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getYougoMaster: (listName) =>
      dispatch(getKyotakuPlan2YougoMaster(listName)),
    saveYougoMaster: (listName, naiyou) =>
      dispatch(saveKyotakuPlan2YougoMaster(listName, naiyou)),
    editYougo: (index) =>
      dispatch({
        type: KP2_YOUGO_EDIT_STATE,
        index,
      }),
    cancelEditYougo: (index) =>
      dispatch({
        type: KP2_YOUGO_CANCEL_STATE,
        index,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(YougoPopover);
