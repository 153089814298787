import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Table } from "antd";
import { connect } from "react-redux";
import { riyouChartTemplateLoad, riyouChartTemplateDelete } from "../../../actions/riyousyaActions/actionCreators";

class TantoKaigiTemplateModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      selectedRecord: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.props.templateLoad((data) => {
        if (Array.isArray(data)) {
          this.setState({ templates: data });
        }
      });
    }
  }

  handleOk = () => {
    this.props.onHide();
    const { selectedRecord } = this.state;

    if (selectedRecord && selectedRecord.id) {
      this.props.onSelect(selectedRecord);
    }
  };

  handleCancel = () => {
    this.props.onHide();
  };

  handleSelectRow(record) {
    this.setState({ selectedRecord: record });
  }

  async templateDelete(data) {
    if (data?.id) {
      await this.props.templateDelete(data.id);
      this.props.templateLoad((data) => {
        if (Array.isArray(data)) {
          this.setState({ templates: data });
        }
      });
    }
  }

  render() {
    const columns = [
      {
        title: "",
        dataIndex: "bikou",
        key: "bikou",
        width: "80%",
      },
      {
        title: "",
        key: "delete",
        render: (record) => {
          return(
            <div style={{ textAlign: "right" }}>
              <span style={{ color: "red" }} onClick={() => this.templateDelete(record)}>削除</span>
            </div>
          )
        }
      }
    ];
    const { templates, selectedRecord } = this.state;

    return (
      (<Modal
        open={this.props.visible}
        title="テンプレート選択ダイアログ"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="tanto-kaigi-template-modal"
      >
        <Table
          rowKey="id"
          showHeader={false}
          style={{ cursor: "pointer" }}
          size={"small"}
          bordered={false}
          columns={columns}
          dataSource={templates}
          pagination={false}
          scroll={{ y: 200 }}
          onRow={(record) => ({
            onClick: () => this.handleSelectRow(record),
          })}
          rowClassName={(record) =>
            selectedRecord != null && record.id === selectedRecord.id
              ? "pljp-table-row-hover"
              : "pointer-row"
          }
        />
      </Modal>)
    );
  }
}

TantoKaigiTemplateModal.propTypes = {
  visible: PropTypes.bool,
  onSelect: PropTypes.func,
  onHide: PropTypes.func,
};

const mapDispatchToProps = () => (dispatch) => ({
  templateLoad: (callback) => dispatch(riyouChartTemplateLoad(callback)),
  templateDelete: (id) => dispatch(riyouChartTemplateDelete(id)), 
});

export default connect(null, mapDispatchToProps)(TantoKaigiTemplateModal);
