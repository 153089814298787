import { ErrorActionTypes } from "../action_types";

function error(state = {}, action) {
  switch (action.type) {
    case ErrorActionTypes.SET_ERROR: {
      return {
        ...state,
        code: action.error.code,
        message: action.error.message,
      };
    }
    default: {
      return {
        code: null,
        message: "",
      };
    }
  }
}

export default error;
