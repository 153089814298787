import { checkTemplate } from "@pdfme/generator";
import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";
import _ from "lodash";

export const life_Haisetsu_InputFormPDF = (data) => {
  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];
  const generateOption = (options, field) => {
    if (options && options[field]) {
      return options[field]
        .split(";")
        .filter((item) => !!item)
        .map((item) => {
          // let temp = (item && item.split(":")[0]) ?? item;
          let temp = item.includes(":") ? item.split(":")[0] : item;
          return { label: item, value: temp };
        });
    }
    return [];
  };

  const generateGenderString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += item.label + " ";
      }
    });
    return result;
  };

  //{ label: item, value: temp }
  //データ
  const generateCheckBoxString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label + "\n";
      } else {
        result += "□" + item.label + "\n";
      }
    });
    return result;
  };

  const generateCheckBoxString2 = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label.split(":")[1] + " ";
      } else {
        result += "□" + item.label.split(":")[1] + " ";
      }
    });
    return result;
  };

  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 78;
    const MAX_LINE = 10;
    let stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    if (stringArr.length > 1) {
      stringArr.forEach((item) => {
        countOld = count;
        count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
        if (count < MAX_LINE) {
          result = result + item + "\n";
        } else {
          result = result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
          return;
        }
      });
    } else {
      stringArr.forEach((item) => {
        result = result + item.substring(0, (MAX_LINE - count) * LENGTH_LINE - 3);
        return;
      });
    }
    return result;
  };

  const dateCheckFormat = (date) => {
    if (date && date !== "0001-01-01") {
      return date.replace(/-/g, "/");
    }
    return "";
  };

    const obj = {
      normal: "1:あり;0:なし",
    };

    const { haisetsu, lifeYougoList } = data;

    if (typeof haisetsu === 'object' && Array.isArray(haisetsu)) {
      let sampledata = [];
      haisetsu?.map((haisetsu) => {
        sampledata.push({
          評価日: formatSeireki(haisetsu.hyoukaDate) ?? "",
          計画作成日: formatSeireki(haisetsu.makeDate) ?? "",
          氏名: haisetsu?.riyousya?.name ?? "",
          生年月日: formatBirthday(haisetsu?.riyousya?.birthday) ?? "",
          性別: `${generateGenderString(GENDER_OPTIONS, haisetsu?.riyousya?.gender ?? "")}`,
          作成者名: haisetsu?.tantoMaster?.name ?? "",
          医師名: `${haisetsu?.doctorMaster?.hospital ?? ""}\n${haisetsu?.doctorMaster?.name ?? ""}` ?? "",
          看護師名: haisetsu?.nurseTantoMaster?.name ?? "",
          排尿の状態1: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.urinationAdmissionStatus)}`,
          排尿の状態2: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.urinationEvaluationStatus)}`,
          排尿の状態3: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.urinationThreeSupportStatus)}`,
          排尿の状態4: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.urinationThreeNoSupportStatus)}`,
          排便の状態1: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.defecationAdmissionStatus)}`,
          排便の状態2: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.defecationEvaluationStatus)}`,
          排便の状態3: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.defecationThreeSupportStatus)}`,
          排便の状態4: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.defecationThreeNoSupportStatus)}`,
          おむつ使用の有無1: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.diaperUserAdmissionStatus)}`,
          おむつ使用の有無2: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.diaperUserEvaluationStatus)}`,
          おむつ使用の有無3: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.diaperUserThreeSupportStatus)}`,
          おむつ使用の有無4: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.diaperUserThreeNoSupportStatus)}`,
          ポータブルトイレ使用の有無1: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.portableToiletAdmissionStatus)}`,
          ポータブルトイレ使用の有無2: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.portableToiletEvaluationStatus)}`,
          ポータブルトイレ使用の有無3: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.portableToiletThreeSupportStatus)}`,
          ポータブルトイレ使用の有無4: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.portableToiletThreeNoSupportStatus)}`,
          排せつの状態に関する支援の必要性: `${generateCheckBoxString2(generateOption(obj, "normal"), haisetsu.excretionStatusNecessity)}`,
          排せつに介護を要する要因: `${formatString10Line(haisetsu.excretionCauseSupport) ?? ""}`,
          支援計画: `${formatString10Line(haisetsu.supportProgram) ?? ""}`,
        })
      })

      return sampledata;
    } else if (typeof haisetsu === 'object') {
      let sampledata = [
        {
          評価日: formatSeireki(haisetsu.hyoukaDate) ?? "",
          計画作成日: formatSeireki(haisetsu.makeDate) ?? "",
          氏名: haisetsu?.riyousya?.name ?? "",
          生年月日: formatBirthday(haisetsu?.riyousya?.birthday) ?? "",
          性別: `${generateGenderString(GENDER_OPTIONS, haisetsu?.riyousya?.gender ?? "")}`,
          作成者名: haisetsu?.tantoMaster?.name ?? "",
          医師名: `${haisetsu?.doctorMaster?.hospital ?? ""}\n${haisetsu?.doctorMaster?.name ?? ""}` ?? "",
          看護師名: haisetsu?.nurseTantoMaster?.name ?? "",
          排尿の状態1: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.urinationAdmissionStatus)}`,
          排尿の状態2: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.urinationEvaluationStatus)}`,
          排尿の状態3: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.urinationThreeSupportStatus)}`,
          排尿の状態4: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.urinationThreeNoSupportStatus)}`,
          排便の状態1: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.defecationAdmissionStatus)}`,
          排便の状態2: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.defecationEvaluationStatus)}`,
          排便の状態3: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.defecationThreeSupportStatus)}`,
          排便の状態4: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui1"), haisetsu.defecationThreeNoSupportStatus)}`,
          おむつ使用の有無1: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.diaperUserAdmissionStatus)}`,
          おむつ使用の有無2: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.diaperUserEvaluationStatus)}`,
          おむつ使用の有無3: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.diaperUserThreeSupportStatus)}`,
          おむつ使用の有無4: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.diaperUserThreeNoSupportStatus)}`,
          ポータブルトイレ使用の有無1: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.portableToiletAdmissionStatus)}`,
          ポータブルトイレ使用の有無2: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.portableToiletEvaluationStatus)}`,
          ポータブルトイレ使用の有無3: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.portableToiletThreeSupportStatus)}`,
          ポータブルトイレ使用の有無4: `${generateCheckBoxString(generateOption(lifeYougoList, "bunrui3"), haisetsu.portableToiletThreeNoSupportStatus)}`,
          排せつの状態に関する支援の必要性: `${generateCheckBoxString2(generateOption(obj, "normal"), haisetsu.excretionStatusNecessity)}`,
          排せつに介護を要する要因: `${formatString10Line(haisetsu.excretionCauseSupport) ?? ""}`,
          支援計画: `${formatString10Line(haisetsu.supportProgram) ?? ""}`,
        },
      ];
      return sampledata;
    } else {
      return [{}];
    }
};
