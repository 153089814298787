export const JirituSokusinActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  JIRITU_SOKUSIN_ADL_MASTER_SUCCESS: "JIRITU_SOKUSIN_ADL_MASTER_SUCCESS",
  JIRITU_SOKUSIN_ADL_MASTER_ERROR: "JIRITU_SOKUSIN_ADL_MASTER_ERROR",

  JIRITU_SOKUSIN_LIFE_YOUGO_SUCCESS: "JIRITU_SOKUSIN_LIFE_YOUGO_SUCCESS",
  JIRITU_SOKUSIN_LIFE_YOUGO_ERROR: "JIRITU_SOKUSIN_LIFE_YOUGO_ERROR",

  LOAD_JIRITU_SOKUSIN_SUCCESS: "LOAD_JIRITU_SOKUSIN_SUCCESS",
  LOAD_JIRITU_SOKUSIN_ERROR: "LOAD_JIRITU_SOKUSIN_ERROR",

  REMOVE_JIRITU_SOKUSIN_REQUEST: "REMOVE_JIRITU_SOKUSIN_REQUEST",
  REMOVE_JIRITU_SOKUSIN_SUCCESS: "REMOVE_JIRITU_SOKUSIN_SUCCESS",
  REMOVE_JIRITU_SOKUSIN_ERROR: "REMOVE_JIRITU_SOKUSIN_ERROR",

  SAVE_JIRITU_SOKUSIN_SUCCESS: "SAVE_JIRITU_SOKUSIN_SUCCESS",
  SAVE_JIRITU_SOKUSIN_ERROR: "SAVE_JIRITU_SOKUSIN_ERROR",

  LOAD_JIRITU_SOKUSIN_TO_QUOTE_SUCCESS: "LOAD_JIRITU_SOKUSIN_TO_QUOTE_SUCCESS",
  LOAD_JIRITU_SOKUSIN_TO_QUOTE: "LOAD_JIRITU_SOKUSIN_TO_QUOTE",

  SET_NEW_DATA: "SET_NEW_DATA",
  GET_LIST_IDS_JIRITU_SOKUSIN: "GET_LIST_IDS_JIRITU_SOKUSIN",

  GET_FIRST_LOAD_DATA: "GET_FIRST_LOAD_DATA",
  GET_FIRST_LOAD_DATA_ERROR: "GET_FIRST_LOAD_DATA_ERROR",
};
