import { CheckOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import React from "react";

export default class CheckKB extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    // console.log(e.target.checked);
    this.props.handleOnchangeCheck(e.target.checked);
  }

  render() {
    let check = false;
    if (this.props.defaltValue === "1" || this.props.defaltValue === 1) {
      check = true;
    }
    if (this.props.defaltValue === "0" || this.props.defaltValue === 0) {
      check = false;
    }
    return (
      <div style={{ textAlign: "center" }} className="modal-check">
        <Checkbox onChange={this.onChange} checked={check}>
          <CheckOutlined />
        </Checkbox>
      </div>
    );
  }
}
