/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Table, Modal, Checkbox } from "antd";
import { RowContent } from "./RowContent";

export class AdlTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteVisible: false,
      checked: false,
      expandedRowKeys: [],
      view: null,
      // tid: JSON.parse(localStorage.getItem(ConfigName.swpsps)).tid ? JSON.parse(localStorage.getItem(ConfigName.swpsps)).tid : 0,
    };
  }

  /**
   * <RowContent/>に渡す関数
   * レコード選択時
   * @param {*} expanded true or false
   * @param {*} record   単一レコード
   */
  handleExpand(expanded, record) {
    this.setState({
      expandedRowKeys: expanded ? [record.id] : [],
    });
    //閲覧に渡す情報を保持
    this.props.keepValuesearch(record);
  }

  /**
   * <RowContent/>に渡す関数
   * 削除確認モーダルopen
   * @param {*} adlId レコードのid
   */
  showDeleteConfirm(adlId) {
    this.setState({
      deleteVisible: true,
      expandedRowKeys: [adlId],
    });
  }

  //削除確認modalのonCancel
  closeDeleteConfirm() {
    this.setState({
      deleteVisible: false,
      checked: false,
    });
  }

  //削除確認modalのonOk
  handleConfirmDelete() {
    if (this.state.checked) {
      this.props.handleDeleteAdl(this.state.expandedRowKeys[0]);
    }
    this.setState({
      deleteVisible: false,
      checked: false,
    });
  }

  //
  /**
   * 削除確認modalのチェックボックス
   * <Checkbox/> のonChangeで発火
   */
  handleChangeCheckbox() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  render() {
    const {
      data,
      // total,
      // page,
      // handleChangePage,
    } = this.props;

    /**
     * ADLテーブルのレコードのカラム
     * <Table/>に使用
     */
    const columns = [
      {
        title: "Content",
        dataIndex: "content", //content => <RowContent/>
      },
    ];

    /**
     * propsのレコードリストを要素ごとにtableDataしRowContentにレコードごとに渡す
     * <Table/> のdataSource
     */
    const tableData = [];
    if (data) {
      data.forEach((ra) => {
        tableData.push({
          id: ra.id,
          riyousyaId: ra.riyousyaId,
          evaluationDate: ra.evaluationDate,
          tokki: ra.tokki,
          content: (
            <RowContent
              data={ra}
              isUserMode={!!this.props.riyousyaId}
              keepValue={this.props.keepValue}
              handleInputAdl={(data) => this.props.handleInputAdl(data)} //修正
              showDeleteConfirm={(adlId) => this.showDeleteConfirm(adlId)} //削除
              handleExpand={(expanded, record) =>
                this.handleExpand(expanded, record)
              } //選択
              expandedRows={this.state.expandedRowKeys} //拡大(選択)しているレコード
              history={this.props.history}
            />
          ),
        });
      });
    }

    return (
      <div style={{ margin: "10px auto", maxWidth: "1200px", width: '100%' }}>
        <Table
          columns={columns}
          dataSource={tableData}
          rowKey={"id"}
          showHeader={false}
          // pagination={{ current: page, pageSize: ConstSet.PER_PAGE, total: total, onChange: handleChangePage }}
        />
        <Modal //削除確認モーダル
          open={this.state.deleteVisible}
          title="削除確認（同意するにチェックしてOKボタンを押してください）"
          onCancel={() => this.closeDeleteConfirm()}
          onOk={() => this.handleConfirmDelete()}
          okButtonProps={{ disabled: !this.state.checked }}
        >
          <div style={{ backgroundColor: "aliceblue" }}>
            <span style={{ display: "block" }}>
              {"ADL記録を削除しますか？"}
            </span>
            <Checkbox
              checked={this.state.checked}
              onChange={() => this.handleChangeCheckbox()}
              value={this.state.checked}
            >
              {"削除に同意する"}
            </Checkbox>
          </div>
        </Modal>
      </div>
    );
  }
}
