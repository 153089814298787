import { Form } from "@ant-design/compatible";
import React from "react";

// import '@ant-design/compatible/assets/index.css';


import { Button, Col, Input, Row, Select } from "antd";

const Option = Select.Option;

class NormalInputSelectKB extends React.Component {
  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);

    // syokichi
    this.syokichiItem = this.syokichiItem.bind(this);
    this.syokichiUpdate = this.syokichiUpdate.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.value !== newProps.value) {
      this.props.form.resetFields();
    }
  }

  onBlur = (e) => {
    this.props.handleInputSelect(e.target.value);
  };

  handleChange = (value) => {
    this.props.handleInputSelect(value);
  };

  /**
   *
   * get syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   */
  syokichiItem() {
    this.props.syokichiItem();
  }

  /**
   * update syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   * @param {*} syokichi
   */
  syokichiUpdate() {
    this.props.syokichiUpdate();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { value, data, typeSyokichi, disabled } = this.props;
    return (
      <Row gutter={8}>
        <Col span={17}>
          {getFieldDecorator("ect", {
            rules: [
              {
                required: false,
              },
            ],
            initialValue: value,
          })(
            <Input.TextArea
              disabled={disabled ? disabled : false}
              rows={2}
              onBlur={this.onBlur}
              style={{ borderRadius: "4px", display: "inline-block" }}
            />
          )}
        </Col>
        <Col span={7} id={"input-select-cp"}>
          <Select
            disabled={disabled ? disabled : false}
            // showSearch
            // placeholder="選択"
            // optionFilterProp="children"
            onChange={this.handleChange}
            // onFocus={this.handleFocus}
            // onBlur={this.handleBlur}
            value={"選択"}
            dropdownMatchSelectWidth={false}
            // getPopupContainer={() => document.getElementById('input-select-cp')}
            dropdownStyle={{
              maxWidth: "100%",
              zIndex: 9999,
            }}
            showArrow={false}
            className={disabled ? "input-select-disabled" : "input-select"}
          // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {data
              ? data
                .toString()
                .split(";")
                .map((item, index) => {
                  return (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  );
                })
              : null}
          </Select>
        </Col>

        {typeSyokichi ? (
          <Col span={24} style={{ textAlign: "center", marginTop: "4px" }}>
            <Button
              disabled={disabled ? disabled : false}
              style={{ marginRight: "16px" }}
              onClick={this.syokichiItem}
            >
              初期値をセット
            </Button>
            <Button
              disabled={disabled ? disabled : false}
              onClick={this.syokichiUpdate}
            >
              これを初期値にする
            </Button>
          </Col>
        ) : null}
      </Row>
    );
  }
}

const InputSelectKB = Form.create()(NormalInputSelectKB);
export default InputSelectKB;
