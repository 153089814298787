
import FabricCanvasTool from "./fabrictool";

class Pencil extends FabricCanvasTool {
  constructor(canvas, is_so) {
    super(canvas);
    this._so = is_so;
  }
  configureCanvas(props) {
    this._canvas.isDrawingMode = true;
    this._canvas.freeDrawingBrush.width = props.lineWidth;
    this._canvas.freeDrawingBrush.color = props.lineColor;
    this._canvas.freeDrawingBrush.selectionDashArray = [2, 4, 6];
    if (this._so) {
      this._canvas.freeDrawingBrush.strokeDashArray = [5, 5];
    }
    else {
      this._canvas.freeDrawingBrush.strokeDashArray = null;
    }

  }
}

export default Pencil;