import { Checkbox, Row, Col, Modal, Button, Input, Select, Radio } from "antd";
import React, { useState, useEffect } from "react";

// Component
import TimeKBCus from "../../components/WeeklyPlan/TimeKBCus";
import AddTime from "../../components/WeeklyPlan/AddTime";
import { Fragment } from "react";
import { ServiceKikanWeekly } from "../index";
import _ from "lodash";
import CommunicationStayCurrentLandscape from "material-ui/svg-icons/communication/stay-current-landscape";

const { Option } = Select;

function EditMultiWeeklyModal(props) {
  const FrequencyOptions = [
    { key: 1, label: "月", value: 1 },
    { key: 2, label: "火", value: 2 },
    { key: 3, label: "水", value: 3 },
    { key: 4, label: "木", value: 4 },
    { key: 5, label: "金", value: 5 },
    { key: 6, label: "土", value: 6 },
    { key: 0, label: "日", value: 0 },
  ];
  const [frequency, setFrequency] = useState();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [serviceAddition, setServiceAddition] = useState([]);
  const [ryaku, setRyaku] = useState("");
  const [serviceNaiyou, setServiceNaiyou] = useState();
  const [jigyousyoNoServiceKikan, setJigyousyoNoServiceKikan] = useState();
  const [ryakuServiceKikan, setRyakuServiceKikan] = useState();
  const [visibleServiceKikan, setVisibleServiceKikan] = useState(false);
  const [listChecked, setListChecked] = useState({
    frequency: false,
    time: false,
    serviceNaiyou: false,
    serviceKikan: false,
    ryaku: false,
    serviceAddition: false,
  });
  const [sidSelected, setSidSelected] = useState(null);
  const [isErrorTime, setIsErrorTime] = useState();

  const [listServiceNaiyou, setListServiceNaiyou] = useState([]);
  const [listServiceAddition, setListServiceAddition] = useState([]);
  const [syuruiCd, setSyuruiCd] = useState(null);
  const [serviceSyuruiCd, setServiceSyuruiCd] = useState(null);
  const [selectOptionArr, setSelectOptionArr] = useState([]);
  const { menu } = props;

  useEffect(() => {
    if (Object.keys(props.dataInit).length !== 0) {
      setFrequency(props.dataInit.youbi - 1);
      setStartTime(props.dataInit.startTime.substring(0, 5));
      setEndTime(props.dataInit.endTime.substring(0, 5));
      setRyaku(props.dataInit.ryaku || "");
      setServiceAddition(
        props.dataInit.kasanInfo ? props.dataInit.kasanInfo.split(",") : []
      );
      setServiceNaiyou(checkServiceNaiyou(props.dataInit));
      setJigyousyoNoServiceKikan(props.dataInit.jigyousyoNoServiceKikan);
      setRyakuServiceKikan(props.dataInit.ryakuServiceKikan);
      setListChecked({
        frequency: false,
        time: false,
        serviceNaiyou: false,
        serviceKikan: false,
        ryaku: false,
        serviceAddition: false,
      });
      setSyuruiCd(props.dataInit.syuruiCd);
    }
  }, [props.dataInit]);

  useEffect(() => {
    if (props.serviceAddition.length > 0 && Object.keys(props.dataInit).length !== 0) {
      const listServiceAdditionNew = getListAddition(props.serviceAddition, props.dataInit.syuruiCd);
      setListServiceAddition(listServiceAdditionNew);
      if (props.serviceNaiyou.length > 0) {
        const listServiceNaiyouNew = processListServiceNaiyou(
          props.dataInit.kasanInfo ? props.dataInit.kasanInfo.split(",") : [],
          listServiceAdditionNew,
        );
        setListServiceNaiyou(listServiceNaiyouNew);
      }
    } else if (props.serviceNaiyou.length >= 0) {
      setListServiceNaiyou(props.serviceNaiyou);
      // set init syuruiCd for adding ServiceKikanMaster
      const serviceContent = _.find(props.serviceNaiyou, (e) => e.kaigo === 1);
      if (serviceContent) {
        if (menu === "kyotaku" || menu === "syoukibo" || menu === "fukugo") {
          if (serviceContent.kaigoServiceSyuruiCd) {
            setServiceSyuruiCd(
              serviceContent.kaigoServiceSyuruiCd.split(";")[0]
            );
          } else {
            setServiceSyuruiCd("0");
          }
        } else if (menu === "yobou" || menu === "houkatu") {
          if (serviceContent.yobouServiceSyuruiCd) {
            setServiceSyuruiCd(
              serviceContent.yobouServiceSyuruiCd.split(";")[0]
            );
          } else {
            setServiceSyuruiCd("0");
          }
        }
      }
    }

    if (props.serviceAddition.length == 0) {
      setListServiceAddition([]);
    }
    if (props.serviceNaiyou.length) {
      if (props.serviceNaiyou[0].serviceType == "ショート") {
        return setSelectOptionArr(props.serviceNaiyou.filter((item) => item.kaigoServiceSyuruiCd.includes(syuruiCd)));
      }
      return setSelectOptionArr(props.serviceNaiyou);
    }
  }, [props.serviceAddition, props.dataInit, props.serviceNaiyou]);

  useEffect(() => {
    const minutesOfStartTime = startTime ? parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) : 0;
    const minutesOfEndTime = endTime ? parseInt(endTime.substring(0, 2)) * 60 + parseInt(endTime.substring(3)) : 0;
    if (minutesOfStartTime >= minutesOfEndTime && listChecked.time) {
      setIsErrorTime(true);
    } else {
      setIsErrorTime(false);
    }
  });

  function checkYobou(menu) {
    return menu === "yobou" || menu === "houkatu" ? true : false;
  }

  function checkOthers(value) {
    if(value?.serviceNaiyou?.includes("その他")) {
      return value?.serviceNaiyou
    } else if (menu === "syoukibo" && (value.serviceNaiyou === "通い" || value.serviceNaiyou === "泊まり" || value.serviceNaiyou === "訪問")) {
      return "小規模"
    } else if (menu === "fukugo" && (value.serviceNaiyou === "通い" || value.serviceNaiyou === "泊まり" || value.serviceNaiyou === "訪問" || value.serviceNaiyou === "看護")) {
      return "複合型"
    } else {
      return value.paletteName
    }
  }

  function checkServiceNaiyou(value) {
    if(value?.serviceNaiyou?.includes("その他")) {
      return value?.ryaku
    } else {
      return value.serviceNaiyou
    }
  }

  function selectColor(value) {
    if(value?.serviceNaiyou?.includes("その他") 
      || value?.serviceNaiyou?.includes("通い") || value?.serviceNaiyou?.includes("訪問") 
      || value?.serviceNaiyou?.includes("泊まり") || value?.serviceNaiyou?.includes("看護")) 
    {
      const result = props?.colorMaster?.find((c) => c.paletteName === value.serviceNaiyou)
      return result?.swColorBak
    } else {
      return value.swColorBak
    }
  }

  function handleChangeDayOfWeek(e) {
    setFrequency(e.target.value);
    setListCheckTrue(["frequency"]);
  }
  function handleChangeChecked(e) {
    const listCheckedNew = listChecked;
    listCheckedNew[e.target.value] = e.target.checked;
    setListChecked({ ...listCheckedNew });
    setInitValue(e.target.value);
  }

  function setInitValue(field) {
    if (field === "frequency") setFrequency(props.dataInit.youbi - 1);
    if (field === "time") {
      setStartTime(props.dataInit.startTime.substring(0, 5));
      setEndTime(props.dataInit.endTime.substring(0, 5));
    }
    if (field === "serviceNaiyou") setServiceNaiyou(props.dataInit.serviceNaiyou);
    if (field === "serviceKikan") {
      setJigyousyoNoServiceKikan(props.dataInit.jigyousyoNoServiceKikan);
      setRyakuServiceKikan(props.dataInit.ryakuServiceKikan);
    }
    if (field === "ryaku") setRyaku(props.dataInit.ryaku || "");
    if (field === "serviceAddition") setServiceAddition(props.dataInit.kasanInfo ? props.dataInit.kasanInfo.split(",") : []);
  }

  function setListCheckTrue(list) {
    const listCheckedNew = listChecked;
    list.forEach((item) => (listCheckedNew[item] = true));
    setListChecked({ ...listCheckedNew });
  }

  function isListMinNotEmpty(list) {
    return list.some((item) => item.min);
  }

  function handleChangeStartTime(value) {
    // set serviceNaiyou when change time
    const serviceNaiyouNew = getServiceNaiyou(value, endTime, listServiceNaiyou);
    if (isListMinNotEmpty(listServiceNaiyou)) {
      setServiceNaiyou(serviceNaiyouNew);
      setRyaku(serviceNaiyouNew);
      setListCheckTrue(["time", "serviceNaiyou", "ryaku"]);
    } else {
      setListCheckTrue(["time"]);
    }

    setStartTime(value);
  }

  function handleChangeEndTime(value) {
    // set serviceNaiyou when change time
    const serviceNaiyouNew = getServiceNaiyou(startTime, value, listServiceNaiyou);
    if (isListMinNotEmpty(listServiceNaiyou)) {
      setServiceNaiyou(serviceNaiyouNew);
      setRyaku(serviceNaiyouNew);
      setListCheckTrue(["time", "serviceNaiyou", "ryaku"]);
    } else {
      setListCheckTrue(["time"]);
    }
    setEndTime(value);
  }

  function handleOnClickTime(value) {
    const timeAddNumber = parseInt(value);
    let totalminutes;
    let result;
    if (startTime) {
      totalminutes = parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) + timeAddNumber;
    } else {
      totalminutes = timeAddNumber;
    }
    const hours = totalminutes / 60 >= 10 ? "" + (parseInt(totalminutes / 60) % 24) : "0" + parseInt(totalminutes / 60);
    const minutes = totalminutes % 60 >= 10 ? "" + parseInt(totalminutes % 60) : "0" + parseInt(totalminutes % 60);
    result = hours + ":" + minutes;
    // setEndTime(result);
    // set serviceNaiyou when change time
    const serviceNaiyouNew = getServiceNaiyou(startTime, result, listServiceNaiyou);
    if (isListMinNotEmpty(listServiceNaiyou)) {
      setServiceNaiyou(serviceNaiyouNew);
      setRyaku(serviceNaiyouNew);
      setListCheckTrue(["time", "serviceNaiyou", "ryaku"]);
    } else {
      setListCheckTrue(["time"]);
    }
    setEndTime(result);
  }

  function handleChangeServiceAddition(e) {
    let serviceAdditionNew = serviceAddition;
    if (e.target.checked) {
      serviceAdditionNew.push(e.target.value);
      // eslint-disable-next-line brace-style
    } else {
      serviceAdditionNew = serviceAdditionNew.filter((item) => item !== e.target.value);
    }
    const listServiceNaiyouNew = processListServiceNaiyou(serviceAdditionNew, listServiceAddition);

    const checkExists = listServiceNaiyouNew.some((item) => item.serviceNaiyou == serviceNaiyou);
    const listServiceNaiyouOld = listServiceNaiyou;
    const isChangeListServiceNaiyou =
      listServiceNaiyouOld.length != listServiceNaiyouNew.length;
    if (isChangeListServiceNaiyou) {
      const serviceNaiyouNew = getServiceNaiyou(startTime, endTime, listServiceNaiyouNew);
      setServiceNaiyou(serviceNaiyouNew);
      setRyaku(serviceNaiyouNew);
      setListCheckTrue(["serviceNaiyou", "ryaku"]);
    }

    setListServiceNaiyou([...listServiceNaiyouNew]);
    setServiceAddition([...serviceAdditionNew]);
    setListCheckTrue(["serviceAddition"]);
  }

  function handleChangeRyaku(e) {
    setRyaku(e.target.value);
    setListCheckTrue(["ryaku"]);
  }

  function handleChangeServiceNaiyou(value) {
    setServiceNaiyou(value);
    setRyaku(value);
    setListCheckTrue(["serviceNaiyou", "ryaku"]);
  }

  function handleCancel() {
    props.onHide();
  }

  function handleOk() {
    props.onOk({
      listChecked,
      frequency,
      startTime,
      endTime,
      ryaku,
      serviceNaiyou,
      serviceAddition: serviceAddition.join(","),
      syuruiCd,
      sid: sidSelected,
      jigyousyoNoServiceKikan,
      ryakuServiceKikan,
      swColorBak: props.dataInit.swColorBak,
    });
  }

  // handle ServiceKikan
  const onSaveServiceKikan = (value) => {
    const kasanDefault = value?.kasanDefault;
    const startTimeArray = kasanDefault.match("<時間帯開始>(.*)</時間帯開始>");
    const endTimeArray = kasanDefault.match("<時間帯終了>(.*)</時間帯終了>");
    const serviceAdditionStr = kasanDefault.match("<加算>(.*)</加算>");
    let serviceAdditionValue =
      serviceAdditionStr && serviceAdditionStr[1]
        ? serviceAdditionStr[1].split(",")
        : [];
    let startTimeValue =
      startTimeArray && startTimeArray[1]
        ? startTimeArray[1].substring(0, 2) +
          ":" +
          startTimeArray[1].substring(2)
        : "";
    let endTimeValue =
      endTimeArray && endTimeArray[1]
        ? endTimeArray[1].substring(0, 2) + ":" + endTimeArray[1].substring(2)
        : "";
    const listServiceAdditionNew = getListAddition(
      props.serviceAddition,
      value.syuruiCd
    );

    // delete serviceAddition not in list serviceAddition
    const listName = listServiceAdditionNew.map((item) => item.addition);
    serviceAdditionValue = serviceAdditionValue.filter((item) =>
      listName.includes(item)
    );

    const listServiceNaiyouNew = processListServiceNaiyou(serviceAdditionValue, listServiceAdditionNew);

    if ((startTimeValue == "" || startTimeValue == "00:00") && (endTimeValue == "" || endTimeValue == "00:00")) {
      startTimeValue = startTime;
      endTimeValue = endTime;
    }
    const countMinute = countMinutes(startTimeValue, endTimeValue);
    const serviceNaiyouNew = getServiceNaiyouByTime(countMinute, listServiceNaiyouNew);
    if (isListMinNotEmpty(listServiceNaiyouNew)) {
      setServiceNaiyou(serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : "");
      setRyaku(serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : "");
      // set list check
      setListCheckTrue(["time", "serviceNaiyou", "serviceKikan", "ryaku", "serviceAddition"]);
    } else {
      setListCheckTrue(["time", "serviceKikan", "serviceAddition"]);
    }
    setSelectOptionArr(props.serviceNaiyou.filter((item) => item.kaigoServiceSyuruiCd.includes(value.syuruiCd)));
    setListServiceNaiyou(listServiceNaiyouNew);
    setStartTime(startTimeValue);
    setEndTime(endTimeValue);
    // setServiceAddition(serviceAdditionValue);
    setJigyousyoNoServiceKikan(value.jigyousyoNo);
    setRyakuServiceKikan(value.ryaku);
    setSidSelected(value.id);
    setVisibleServiceKikan(false);
    setListServiceAddition(listServiceAdditionNew);
    setSyuruiCd(value.syuruiCd);

    props.onShow();
  };

  function handleShowServiceKikan() {
    props.onHide();
    setVisibleServiceKikan(true);
  }

  function handleDisableButtonOk() {
    if (listChecked.time && isErrorTime) return true;
    for (const field in listChecked) {
      if (listChecked[field]) {
        return false;
      }
    }
    return true;
  }

  function processListServiceNaiyou(serviceAdditionSelected, serviceAdditionList) {
    // check PTOT
    const isPTOT = serviceAdditionList.some((item) => (item.addition ? item.addition.includes("PTOT") : false));
    let listSerViceNaiyouNew = props.serviceNaiyou;
    if (isPTOT) {
      let isPTOTSelected = false;
      serviceAdditionSelected.forEach((item) => {
        if (item.includes("PTOT")) {
          isPTOTSelected = true;
        }
      });

      if (isPTOTSelected) {
        listSerViceNaiyouNew = props.serviceNaiyou.filter(
          (item) =>
            item.serviceNaiyou.includes("PTOT") ||
            item.serviceNaiyou === "定期巡回訪看"
        );
      } else {
        listSerViceNaiyouNew = props.serviceNaiyou.filter(
          (item) =>
            !item.serviceNaiyou.includes("PTOT") ||
            item.serviceNaiyou === "定期巡回訪看"
        );
      }
    }
    // eslint-disable-next-line no-undef
    return [...listSerViceNaiyouNew];
  }

  function countMinutes(startTime, endTime) {
    const minutesOfStartTime = startTime ? parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) : 0;
    const minutesOfEndTime = endTime ? parseInt(endTime.substring(0, 2)) * 60 + parseInt(endTime.substring(3)) : endTime;
    return minutesOfEndTime - minutesOfStartTime;
  }

  function getServiceNaiyouByTime(minutes, listServiceNaiyou) {
    // let serviceNaiyouSelected = listServiceNaiyou.find(
    //   (item) => item.min == null
    // );
    // if (serviceNaiyouSelected) {
    //   return serviceNaiyouSelected;
    // }
    const listServiceNaiyouSorted = _.orderBy(listServiceNaiyou, "min", "asc");
    const serviceNaiyouSelected = listServiceNaiyouSorted.find((item) => item.min >= minutes);
    return serviceNaiyouSelected;
  }

  function getServiceNaiyou(startTime, endTime, listServiceNaiyou) {
    const minutes = countMinutes(startTime, endTime);
    const serviceNaiyouObj = getServiceNaiyouByTime(minutes, listServiceNaiyou);
    return serviceNaiyouObj ? serviceNaiyouObj.serviceNaiyou : "";
  }

  function getListAddition(list, syuruiCd) {
    if (syuruiCd == null) {
      return list;
    }
    return list.filter((item) => {
      let listSyuruiCd = [];
      if (menu === "kyotaku" || menu === "syoukibo" || menu === "fukugo") {
        listSyuruiCd = item.kaigoServiceSyuruiCd
          ? item.kaigoServiceSyuruiCd.split(";")
          : [];
      } else {
        listSyuruiCd = item.yobouServiceSyuruiCd
          ? item.yobouServiceSyuruiCd.split(";")
          : [];
      }
      if (listSyuruiCd.includes(syuruiCd)) return item;
    });
  }

  return (
    (<Fragment>
      <Modal
        open={props.visible}
        title={props.title}
        onCancel={() => handleCancel()}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <Button
            key="back"
            type="primary"
            style={{
              backgroundColor: "#ffffff",
              color: "#616161",
              border: "1px solid #dbdbdb",
            }}
            onClick={() => handleCancel()}
          >
            キャンセル
          </Button>,
          <Button key="back" type="primary" onClick={handleOk} disabled={handleDisableButtonOk()}>
            修正する
          </Button>,
        ]}
        width={900}
      >
        <div>
          <div>
            <Row>
              <Col span={1}>
                <Checkbox value="frequency" checked={listChecked.frequency} onChange={handleChangeChecked}></Checkbox>
              </Col>
              <Col span={4} style={{ marginLeft: -8 }}>
                曜日
              </Col>
              <Col span={19}>
                <Radio.Group options={FrequencyOptions} onChange={handleChangeDayOfWeek} value={frequency} />
              </Col>
            </Row>
            <Row style={{ marginTop: 10, display: "flex", alignItems: "center" }}>
              <Col span={1}>
                <Checkbox value="time" checked={listChecked.time} onChange={handleChangeChecked}></Checkbox>
              </Col>
              <Col span={3} style={{ marginLeft: -8 }}>
                開始時間
              </Col>
              <Col span={4}>
                <TimeKBCus timeString={startTime} handleInput={handleChangeStartTime} />
              </Col>
              <Col span={16} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: 10 }}>終了時間</div>
                <TimeKBCus timeString={endTime} handleInput={handleChangeEndTime} />
                <AddTime timeSetting={props.timeSetting} onClickTime={handleOnClickTime} />
                {isErrorTime ? <div style={{ marginLeft: 20, color: "red" }}>{"開始時間 < 終了時間"}</div> : ""}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={14}>
                <Row style={{ display: "flex", alignItems: "center" }}>
                  <Col span={1}></Col>
                  <Col span={6}>サービス種類</Col>
                  <Col span={9}>
                    <Input
                      readOnly={true}
                      style={{
                        width: 150,
                        cursor: "not-allowed",
                        backgroundColor: selectColor(props.dataInit) || "white",
                      }}
                      value={checkOthers(props.dataInit) || ""}
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Col span={1}>
                    <Checkbox value="serviceNaiyou" checked={listChecked.serviceNaiyou} onChange={handleChangeChecked}></Checkbox>
                  </Col>
                  <Col span={6}>サービス内容</Col>
                  <Col span={9}>
                    <Select
                      value={serviceNaiyou}
                      style={{ width: 150 }}
                      onChange={handleChangeServiceNaiyou}
                    >
                      {[...new Set(selectOptionArr)].map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Col span={1}>
                    <Checkbox value="serviceKikan" checked={listChecked.serviceKikan} onChange={handleChangeChecked}></Checkbox>
                  </Col>
                  <Col span={6}>サービス事業所</Col>
                  <Col span={17}>
                    <Input
                      readOnly={true}
                      style={{ width: 280, backgroundColor: "#f5f5f5" }}
                      value={(jigyousyoNoServiceKikan || "") + "   " + (ryakuServiceKikan || "")}
                      onClick={handleShowServiceKikan}
                    />
                    <Button type="primary" onClick={handleShowServiceKikan}>
                      事
                    </Button>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Col span={1}>
                    <Checkbox value="ryaku" checked={listChecked.ryaku} onChange={handleChangeChecked}></Checkbox>
                  </Col>
                  <Col span={6}>文字入力</Col>
                  <Col span={9}>
                    <Input style={{ width: 150 }} value={ryaku} onChange={handleChangeRyaku} />
                  </Col>
                </Row>
              </Col>
              <Col span={10} style={{ display: "flex" }}>
                <div>
                  <Checkbox value="serviceAddition" checked={listChecked.serviceAddition} onChange={handleChangeChecked}></Checkbox>
                </div>
                <div style={{ marginRight: 10 }}>加算</div>
                <div
                  style={{
                    border: "2px solid #ccc",
                    width: "300px",
                    height: "400px",
                    overflowY: "scroll",
                  }}
                >
                  {listServiceAddition.map((item) => (
                    // eslint-disable-next-line react/jsx-key
                    (<div style={{ marginLeft: 2 }}>
                      <Checkbox
                        key={item.id}
                        value={item.addition}
                        checked={serviceAddition.includes(item.addition)}
                        onChange={handleChangeServiceAddition}
                      >
                        {item.addition}
                      </Checkbox>
                    </div>)
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <ServiceKikanWeekly
        visible={visibleServiceKikan}
        serviceType={props.dataInit.paletteName}
        hideModal={() => {
          props.onShow();
          setVisibleServiceKikan(false);
        }}
        onSaveServiceKikan={onSaveServiceKikan}
        syuruiCd={serviceSyuruiCd}
        className="break-word-office"
        jigyousyoNoServiceKikan={jigyousyoNoServiceKikan}
        menu={menu}
      />
    </Fragment>)
  );
}

export { EditMultiWeeklyModal };
