/* eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

const fabric = require("fabric").fabric;

class TwoLine2 extends FabricCanvasTool {
  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => o.selectable = o.evented = false);
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.call_back = props.update_select;
  }

  doMouseDown(o) {
    const canvas = this._canvas;
    this.isDown = true;
    const pointer = canvas.getPointer(o.e);
    [this.startX, this.startY] = [pointer.x, pointer.y];
    const points1 = [this.startX, this.startY, this.startX + 150, this.startY];
    const line1 = new fabric.Line(points1, {
      strokeWidth: this._width,
      fill: this._color,
      stroke: this._color,
      originX: "center",
      originY: "center",
      selectable: false,
      evented: false
    });
    const points2 = [this.startX + 50, this.startY + 20, this.startX + 90, this.startY - 15];
    const line2 = new fabric.Line(points2, {
      strokeWidth: this._width,
      fill: this._color,
      stroke: this._color,
      originX: "center",
      originY: "center",
      selectable: false,
      evented: false
    });
    this.groupc = new fabric.Group([line1, line2], {
      left: this.startX,
      top: this.startY,
      angle: 0,
      selectable: true,
    });
    canvas.add(this.groupc);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    const canvas = this._canvas;
    const pointer = canvas.getPointer(o.e);
    this.groupc.set(
      {
        scaleX: pointer.x / this.startX,
        scaleY: pointer.x / this.startX,
      }
    );

    this.groupc.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.call_back();
  }
}

export default TwoLine2;