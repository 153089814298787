import React, { Component } from "react";
import { Modal, Input } from "antd";
import PropTypes from "prop-types";

class SaveTantoKaigiTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateName: "",
    };
  }

  handleOk = () => {
    this.props.onHide();
    if (this.state.templateName) {
      this.props.onSubmit(this.state.templateName);
      this.setState({ templateName: "" });
    }
  };

  handleCancel = () => {
    this.props.onHide();
  };

  handleChangeTemplateName = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      (<Modal
        open={this.props.visible}
        title="テンプレート保存ダイアログ"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="tanto-kaigi-save-template-modal"
      >
        <Input
          name="templateName"
          value={this.state.templateName}
          onChange={this.handleChangeTemplateName}
        />
      </Modal>)
    );
  }
}

SaveTantoKaigiTemplateModal.propTypes = {
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  onHide: PropTypes.func,
};

export default SaveTantoKaigiTemplateModal;
