import { iryouhokenActionTypes } from "./actionName.js";
import { IryouhokenMasterApi } from "../../api/riyousyaHoken.js";

function getList(hokenNo) {
  return (dispatch) => {
    dispatch({
      type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_GET_LIST_REQUEST,
    });
    IryouhokenMasterApi.getList(hokenNo).then(
      (data) => {
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_GET_LIST_SUCCESS,
          data: data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_GET_LIST_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
//追加時,初期化
function init(callback) {
  return (dispatch) => {
    dispatch({
      type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_INIT_REQUEST,
    });
    IryouhokenMasterApi.init().then(
      (data) => {
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_INIT_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_INIT_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
//新規追加
function create(body, callback) {
  return (dispatch) => {
    dispatch({
      type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_CREATE_REQUEST,
    });
    IryouhokenMasterApi.create(body).then(
      (data) => {
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_CREATE_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_CREATE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
//修正
function update(body, callback) {
  return (dispatch) => {
    dispatch({
      type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATE_REQUEST,
    });
    IryouhokenMasterApi.update(body).then(
      (data) => {
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATE_SUCCESS,
          data: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATE_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}
//並び替え
function updateList(body, callback) {
  return (dispatch) => {
    dispatch({
      type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_REQUEST,
    });
    IryouhokenMasterApi.updateList(body).then(
      (data) => {
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_SUCCESS,
          payload: data,
        });
        return callback(data);
      },
      (err) => {
        checkError(err);
        dispatch({
          type: iryouhokenActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export const IryouhokenMasterActions = {
  getList,
  init,
  create,
  update,
  updateList,
}
