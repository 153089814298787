import {
  REPORT_WEEKLY_REQUEST,
  REPORT_WEEKLY_SUCCESS,
  REPORT_WEEKLY_ERROR,
} from "./actionName.js";

import {
  LoadDataApi,
  DeleteRecords,
  DeleteAllImages,
  DeleteAllRecords,
} from "../../api/reportsWeekly";

function loadDataCreator(parameterObj) {
  return (dispatch) => {
    dispatch({
      type: REPORT_WEEKLY_REQUEST,
    });

    LoadDataApi.loadDataApi(parameterObj)
      .then((data) =>
        dispatch({
          type: REPORT_WEEKLY_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: REPORT_WEEKLY_ERROR,
        error: err,
      }));
  };
}

function deleteRecordsCreator(delValue, parameterObj) {
  return (dispatch) => {
    dispatch({
      type: REPORT_WEEKLY_REQUEST,
    });

    DeleteRecords.deleteRecords(delValue, parameterObj)
      .then((data) =>
        dispatch({
          type: REPORT_WEEKLY_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: REPORT_WEEKLY_ERROR,
        error: err,
      }));
  };
}

function deleteAllImagesCreator(record, parameterObj) {
  return (dispatch) => {
    dispatch({
      type: REPORT_WEEKLY_REQUEST,
    });

    DeleteAllImages.deleteAllImages(record, parameterObj)
      .then((data) =>
        dispatch({
          type: REPORT_WEEKLY_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: REPORT_WEEKLY_ERROR,
        error: err,
      }));
  };
}

function deleteAllRecordsCreator(record, parameterObj) {
  return (dispatch) => {
    dispatch({
      type: REPORT_WEEKLY_REQUEST,
    });

    DeleteAllRecords.deleteAllRecords(record, parameterObj)
      .then((data) =>
        dispatch({
          type: REPORT_WEEKLY_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => ({
        type: REPORT_WEEKLY_ERROR,
        error: err,
      }));
  };
}

export {
  loadDataCreator,
  deleteRecordsCreator,
  deleteAllImagesCreator,
  deleteAllRecordsCreator,
};
