import { Form } from "@ant-design/compatible";
import { Button, Col, Divider, Input, Modal, Radio, Row, Select, Table } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { toKatakana } from "wanakana";
import {
  formatStorage,
  getMenu,
  isManagement,
} from "../../../common/function_common/functionCommon";
import {
  disRiyouBunruiTekiyou,
  displayJpDateKanji,
  displaySeireki,
} from "../../../common/function_common/functionDisplay";
import TextAreaSelect from "./TextAreaSelect";
import TextInput from "./TextInput";
import TextInputArea from "./TextInputArea";

const { Option } = Select;

const gengo = ["令和", "平成", "昭和", "大正", "明治"];
const year = 64;
const month = 12;
const day = 31;

class FormBasicNormal extends Component {
  constructor(props) {
    super(props);

    const isMNGT = isManagement();
    const menu = formatStorage(getMenu());
    const sid = menu.si;
    const systemName = menu.sn;

    this.state = {
      visibleZip: false,
      textSearch: "",
      rowActive: null,
      record: null,
      onDialog: false,
      modalOn: false,

      isMNGT,
      sid,
      systemName,

      riyousyaBirthday: this.props.data.birthday != "0001-01-01" ? dayjs(this.props.data.birthday, "YYYY-MM-DD") : "",

      gengo: this.selectOptions(gengo, 1),
      year: this.selectOptions(year, 2),
      month: this.selectOptions(month, 2),
      day: this.selectOptions(day, 2),
      errText1: "利用者名を入力してください",
      errText2: "フリガナを入力してください",
      errText3: "生年月日を入力してください",
      gengoPart: "",
      yearPart: "",
      monthPart: "",
      dayPart: "",
      gender: this.props.data.gender,

      furigana: toKatakana(this.props.data.furigana),
    };
  }

  componentDidMount() {
    this.defaultSeinengappiSet(this.state.riyousyaBirthday);
    console.log("Component is mounted to the DOM!");
  }

  componentDidUpdate(preProps) {
    const { zipSearch } = this.props;
    const { onDialog } = this.state;

    if (zipSearch !== preProps.zipSearch) {
      if (zipSearch.length === 1 && !onDialog) {
        const record = zipSearch[0];

        const { setFieldsValue } = this.props.form;

        if (record) {
          setFieldsValue({
            zip: record.zip,
            address1: record.address,
          });
        }

        this.setState({
          rowActive: null,
          record: null,
          visibleZip: false,
        });
      }
      else {
        const { visibleZip, modalOn } = this.state;
        if (!visibleZip && modalOn) {
          this.setState({
            visibleZip: true,
            modalOn: false,
          });
        }
      }
    }
  }

  selectOptions = (value, type) => {
    const options = [
      <Option key={0} value="">
        &nbsp;
      </Option>,
    ];
    if (type == 1) {
      for (let i = 0; i < value.length; i++) {
        options.push(
          <Option key={i + 1} value={value[i]}>
            {value[i]}
          </Option>
        );
      }
    }

    if (type == 2) {
      for (let i = 1; i <= value; i++) {
        options.push(
          <Option key={i} value={i}>
            {i}
          </Option>
        );
      }
    }

    return options;
  };

  searchYubinBango = (text, onDialog) => {
    const { getFieldValue } = this.props.form;
    // const { visibleZip } = this.state

    if (!text) {
      text = getFieldValue("zip");
      this.setState({
        textSearch: text,
      });
    }

    this.setState({
      onDialog: onDialog ? onDialog : false,
      modalOn: true,
    });

    this.props.searchYuuBinBango(text);
  };

  closeSearchYuubinBango = () => {
    this.setState({
      visibleZip: false,
      modalOn: false,
    });
  };

  onChangeInputSearch = (e) => {
    this.setState({
      textSearch: e.target.value,
    });
  };

  onRowClick = (record, rowIndex) => {
    this.setState({
      rowActive: rowIndex,
      record,
    });
  };

  handleSelectRowZip = () => {
    const { record } = this.state;
    const { setFieldsValue } = this.props.form;
    if (record) {
      setFieldsValue({
        zip: record.zip,
        address1: record.address,
      });
    }

    this.setState({
      rowActive: null,
      record: null,
      visibleZip: false,
      modalOn: false,
    });
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  handleChangeGender = (selected) => {
    this.setState({ gender: selected });
    this.props.onChange("gender", selected);
  }

  handleChangeSelect = (field, selected) => {

    const {
      gengoPart,
      yearPart,
      monthPart,
      dayPart,
    } = this.state;

    this.setState({ [field]: selected });

    if (field == "gengoPart") {
      this.props.onChange("birthday", displaySeireki(selected, yearPart, monthPart, dayPart));
    }
    else if (field == "yearPart") {
      this.props.onChange("birthday", displaySeireki(gengoPart, selected, monthPart, dayPart));
    }
    else if (field == "monthPart") {
      this.props.onChange("birthday", displaySeireki(gengoPart, yearPart, selected, dayPart));
    }
    else if (field == "dayPart") {
      this.props.onChange("birthday", displaySeireki(gengoPart, yearPart, monthPart, selected));
    }
  };

  defaultSeinengappiSet = (birthday) => {
    const jpDate = displayJpDateKanji(birthday);
    const yearIndex = jpDate.indexOf("年");
    const monthIndex = jpDate.indexOf("月");
    const dayIndex = jpDate.indexOf("日");

    this.setState({
      gengoPart: jpDate.slice(0, 2),
      yearPart: jpDate.slice(2, yearIndex),
      monthPart: jpDate.slice(yearIndex + 1, monthIndex),
      dayPart: jpDate.slice(monthIndex + 1, dayIndex)
    });
  }

  onBlur = (value) => {
    const katakana = toKatakana(value);
    this.setState({ furigana: katakana });
    this.props.onChange("furigana", katakana);
  }

  handleChangeFurigana = (value) => {
    this.setState({ furigana: value });
    this.props.onChange("furigana", value);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      zipLoading,
      zipSearch,

      // 設定ところ
      bunrui_is_dis,
      bunrui_title,
      systemBunrui_is_dis,
      systemBunrui_title,
      tekiyou_is_dis,
      tekiyou_title,
      fax_title,

      // yougo master
      riyousyaBunrui1,
      riyousyaBunrui2,
      riyousyaBunrui3,
      riyousyaBunrui4,
      riyousyaBunrui5,
      systemBunrui1,
      systemBunrui2,
      systemBunrui3,
      systemBunrui4,
      systemBunrui5,
      nyukinBunrui,
      nyukinTekiyo,
    } = this.props;

    const { systemName, textSearch, visibleZip, isMNGT } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const {
      gengo,
      year,
      month,
      day,
      gengoPart,
      yearPart,
      monthPart,
      dayPart,
      gender,
      furigana,
    } = this.state;

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        {...formItemLayout}
        labelAlign="left"
      >
        {isMNGT ? (
          <div>
            <Form.Item label={"整理番号"} colon={false}>
              {systemName === "kyotaku" && this.props.data &&
                this.props.data.riyousyaKyotaku &&
                this.props.data.riyousyaKyotaku[0] ?
                (
                  <span>{this.props.data.riyousyaKyotaku[0].code}</span>
                ) : (
                  ""
                )}
              {(systemName === "syoukibo" || systemName === "fukugo") && this.props.data &&
                this.props.data.riyousyaSyoukibo &&
                this.props.data.riyousyaSyoukibo[0] ?
                (
                  <span>{this.props.data.riyousyaSyoukibo[0].code}</span>
                ) : (
                  ""
                )}
              {systemName === "yobou" && this.props.data &&
                this.props.data.riyousyaYobou &&
                this.props.data.riyousyaYobou[0] ?
                (
                  <span>{this.props.data.riyousyaYobou[0].code}</span>
                ) : (
                  ""
                )}
            </Form.Item>
            <Divider />
          </div>
        ) : (
          ""
        )}
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>利用者</span>
          </Col>
          <Col sm={18} md={18} className="rdu-tab-content">
            {getFieldDecorator("nameSyusei", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })(<TextInput style={{ width: 200 }} />)}
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>フリガナ</span>
          </Col>
          <Col sm={18} md={18} className="rdu-tab-content">
            {/* {getFieldDecorator("furigana", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })( <TextInput style={{ width: 200 }} /> )} */}
            <TextInput
              style={{ width: 200 }}
              value={furigana}
              onChange={(selected) => this.handleChangeFurigana(selected)}
              onBlur={(e) => this.onBlur(e.target.value)}
            />
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>生年月日</span>
          </Col>
          {/* <Col sm={18} md={18} className="rdu-tab-content">
            {this.props.data &&
              this.props.data.birthday &&
              this.props.data.birthday !== "0001-01-01" ? (
              <span>
                {`${displayJpDateKanji(
                  this.props.data.birthday
                )}  ${dayjs().diff(
                  dayjs(this.props.data.birthday, "YYYY-MM-DD"),
                  "years"
                )}歳`}
              </span>
            ) : null}
          </Col> */}
          <Col sm={{ span: 18 }}>
            <Select
              value={gengoPart}
              style={{ width: 80 }}
              onChange={(selected) => this.handleChangeSelect("gengoPart", selected)}
            >
              {gengo}
            </Select>
            <Select
              value={yearPart}
              style={{ width: 60 }}
              onChange={(selected) => this.handleChangeSelect("yearPart", selected)}
            >
              {year}
            </Select>
            <Select
              value={monthPart}
              style={{ width: 60 }}
              onChange={(selected) => this.handleChangeSelect("monthPart", selected)}
            >
              {month}
            </Select>
            <Select
              value={dayPart}
              style={{ width: 60 }}
              onChange={(selected) => this.handleChangeSelect("dayPart", selected)}
            >
              {day}
            </Select>

            <span>
              &nbsp;&nbsp;&nbsp;
              {this.props.data.birthday != "0001-01-01" ? `${dayjs().diff(
                dayjs(this.props.data.birthday, "YYYY-MM-DD"),
                "years"
              )}歳` : ""}
            </span>
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>性別</span>
          </Col>
          <Col sm={18} md={18} className="rdu-tab-content">
            {/* {this.props.data &&
              this.props.data.gender &&
              this.props.data.gender === 1 ? (
              <div className="c-blue">男性</div>
            ) : (
              <div className="c-red">女性</div>
            )} */}
            <Radio.Group
              value={gender}
              onChange={(e) => this.handleChangeGender(e.target.value)}
            >
              <Radio value={1}>男性</Radio>
              <Radio value={2}>女性</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>郵便番号</span>
          </Col>
          <Col sm={18} md={18} className="rdu-tab-content">
            {getFieldDecorator("zip", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })(<TextInput style={{ width: 100 }} />)}
            <Button
              type="primary"
              style={{ marginLeft: 8 }}
              onClick={this.searchYubinBango.bind(this, "", false)}
            >
              〒検索
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>住所1</span>
          </Col>
          <Col sm={18} md={18} className="rdu-tab-content">
            {getFieldDecorator("address1", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })(<TextInput />)}
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>住所2</span>
          </Col>
          <Col sm={18} md={18} className="rdu-tab-content">
            {getFieldDecorator("address2", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })(<TextInput />)}
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab-2">
          <Col sm={24} md={24} className="rdu-tab-title">
            <span>その他住所</span>
          </Col>
          <Col sm={24} md={24} className="rdu-tab-content">
            {getFieldDecorator("sonotaAddress", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })(
              <TextInputArea
                element="sonotaAddress"
                autoSize={{ minRows: 4, maxRows: 99 }}
              />
            )}
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>電話番号</span>
          </Col>
          <Col sm={18} md={18} className="rdu-tab-content">
            {getFieldDecorator("tel", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })(<TextInput style={{ width: 200 }} />)}
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>{fax_title ? fax_title : "FAX"}</span>
          </Col>
          <Col sm={18} md={18} className="rdu-tab-content">
            {getFieldDecorator("fax", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })(<TextInput style={{ width: 200 }} />)}
          </Col>
        </Row>
        <Divider />
        {/* bunrui1 */}
        {disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 0) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 0)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("bunrui1", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={riyousyaBunrui1}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate("利用者分類1", naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* bunrui2 */}
        {disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 1) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 1)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("bunrui2", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={riyousyaBunrui2}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate("利用者分類2", naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* bunrui3 */}
        {disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 2) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 2)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("bunrui3", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={riyousyaBunrui3}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate("利用者分類3", naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* bunrui4 */}
        {disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 3) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 3)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("bunrui4", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={riyousyaBunrui4}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate("利用者分類4", naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* bunrui5 */}
        {disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 4) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(bunrui_is_dis, bunrui_title, 4)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("bunrui5", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={riyousyaBunrui5}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate("利用者分類5", naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* systemBunrui1 */}
        {disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 0) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 0)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("systemBunrui1", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={systemBunrui1}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate(`${systemName}分類1`, naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* systemBunrui2 */}
        {disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 1) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 1)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("systemBunrui2", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={systemBunrui2}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate(`${systemName}分類2`, naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* systemBunrui3 */}
        {disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 2) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 2)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("systemBunrui3", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={systemBunrui3}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate(`${systemName}分類3`, naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* systemBunrui4 */}
        {disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 3) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 3)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("systemBunrui4", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={systemBunrui4}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate(`${systemName}分類4`, naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* systemBunrui5 */}
        {disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 4) ? (
          <div>
            <Row className="rdu-tab">
              <Col sm={6} md={6} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(systemBunrui_is_dis, systemBunrui_title, 4)}</span>
              </Col>
              <Col sm={18} md={18} className="rdu-tab-content">
                {getFieldDecorator("systemBunrui5", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect
                    isMaster={false}
                    isSelect={true}
                    rowSet={1}
                    dataSelect={systemBunrui5}
                    yougoMasterUpdate={(naiyou) =>
                      this.yougoMasterUpdate(`${systemName}分類5`, naiyou)
                    }
                  />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* tekiyou1 */}
        {disRiyouBunruiTekiyou(tekiyou_is_dis, tekiyou_title, 0) ? (
          <div>
            <Row className="rdu-tab-2">
              <Col sm={24} md={24} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(tekiyou_is_dis, tekiyou_title, 0)}</span>
              </Col>
              <Col sm={24} md={24} className="rdu-tab-content">
                {getFieldDecorator("tekiyo1", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect isMaster={false} isSelect={false} rowSet={4} />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* tekiyou2 */}
        {disRiyouBunruiTekiyou(tekiyou_is_dis, tekiyou_title, 1) ? (
          <div>
            <Row className="rdu-tab-2">
              <Col sm={24} md={24} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(tekiyou_is_dis, tekiyou_title, 1)}</span>
              </Col>
              <Col sm={24} md={24} className="rdu-tab-content">
                {getFieldDecorator("tekiyo2", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect isMaster={false} isSelect={false} rowSet={4} />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        {/* tekiyou3 */}
        {disRiyouBunruiTekiyou(tekiyou_is_dis, tekiyou_title, 2) ? (
          <div>
            <Row className="rdu-tab-2">
              <Col sm={24} md={24} className="rdu-tab-title">
                <span>{disRiyouBunruiTekiyou(tekiyou_is_dis, tekiyou_title, 2)}</span>
              </Col>
              <Col sm={24} md={24} className="rdu-tab-content">
                {getFieldDecorator("tekiyo3", {
                  rules: [
                    { required: false, message: "必須項目をセットして下さい" },
                  ],
                })(
                  <TextAreaSelect isMaster={false} isSelect={false} rowSet={4} />
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}
        <Row className="rdu-tab">
          <Col sm={6} md={6} className="rdu-tab-title">
            <span>入金分類</span>
          </Col>
          <Col sm={18} md={18} className="rdu-tab-content">
            {getFieldDecorator("nyukinBunrui", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })(
              <TextAreaSelect
                isMaster={false}
                isSelect={true}
                rowSet={1}
                dataSelect={nyukinBunrui}
                yougoMasterUpdate={(naiyou) =>
                  this.yougoMasterUpdate("入金分類", naiyou)
                }
              />
            )}
          </Col>
        </Row>
        <Divider />
        <Row className="rdu-tab-2">
          <Col sm={24} md={24} className="rdu-tab-title">
            <span>入金摘要</span>
          </Col>
          <Col sm={24} md={24} className="rdu-tab-content">
            {getFieldDecorator("nyukinTekiyou", {
              rules: [{ required: false, message: "必須項目をセットして下さい" }],
            })(
              <TextAreaSelect
                isMaster={false}
                isSelect={true}
                rowSet={4}
                dataSelect={nyukinTekiyo}
                yougoMasterUpdate={(naiyou) =>
                  this.yougoMasterUpdate("入金摘要", naiyou)
                }
              />
            )}
          </Col>
        </Row>
        <Divider />
        <Modal
          title={
            <div>
              <h3 style={{ display: "inline", paddingRight: 16 }}>
                〒郵便番号
              </h3>
              <Input
                onChange={this.onChangeInputSearch}
                value={textSearch}
                style={{ maxWidth: 280 }}
              />
              <Button
                type="primary"
                onClick={this.searchYubinBango.bind(this, textSearch, true)}
              >
                検索
              </Button>
            </div>
          }
          open={visibleZip}
          onOk={this.handleSelectRowZip}
          onCancel={this.closeSearchYuubinBango}
          footer={[
            <Button key="back" onClick={this.closeSearchYuubinBango}>
              キャンセル
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleSelectRowZip}
            >
              OK
            </Button>,
          ]}
          closable={false}
          className="modal-search-zip"
        >
          <Table
            columns={[
              {
                title: "zip",
                dataIndex: "zip",
              },
              {
                title: "address",
                dataIndex: "address",
              },
            ]}
            dataSource={zipSearch}
            loading={zipLoading}
            size="small"
            pagination={{ pageSize: 6 }}
            showHeader={false}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.onRowClick(record, rowIndex);
                }, // click row
              };
            }}
            rowClassName={(record, index) =>
              this.state.rowActive != null && index === this.state.rowActive
                ? "pljp-table-row-hover"
                : ""
            }
          />
        </Modal>
      </Form>)
    );
  }
}

export const FormBasic = Form.create({
  name: "input-riyou-basic",
  onValuesChange(props, changedValues, allValues) {
    props.saveOnchangeToState(allValues, "fBasic", "");
  },
  mapPropsToFields(props) {
    return {
      nameSyusei: Form.createFormField({
        value: props.data && props.data.nameSyusei ? props.data.nameSyusei : props.data.name
      }),
      // furigana: Form.createFormField({
      //   value: props.data.furigana ? props.data.furigana : null
      // }),
      // gender: Form.createFormField({
      //   value: props.data &&
      //     props.data.gender &&
      //     props.data.gender === 1 ? "男性" : "女性"
      // }),
      zip: Form.createFormField({
        value: props.data ? props.data.zip : null,
      }),
      address1: Form.createFormField({
        value: props.data ? props.data.address1 : null,
      }),
      address2: Form.createFormField({
        value: props.data ? props.data.address2 : null,
      }),
      sonotaAddress: Form.createFormField({
        value: props.data ? props.data.sonotaAddress : null,
      }),
      tel: Form.createFormField({
        value: props.data ? props.data.tel : null,
      }),
      fax: Form.createFormField({
        value: props.data ? props.data.fax : null,
      }),
      bunrui1: Form.createFormField({
        value: props.data ? props.data.bunrui1 : null,
      }),
      bunrui2: Form.createFormField({
        value: props.data ? props.data.bunrui2 : null,
      }),
      bunrui3: Form.createFormField({
        value: props.data ? props.data.bunrui3 : null,
      }),
      bunrui4: Form.createFormField({
        value: props.data ? props.data.bunrui4 : null,
      }),
      bunrui5: Form.createFormField({
        value: props.data ? props.data.bunrui5 : null,
      }),
      systemBunrui1: Form.createFormField({
        value: props.data ? props.data.systemBunrui1 : null,
      }),
      systemBunrui2: Form.createFormField({
        value: props.data ? props.data.systemBunrui2 : null,
      }),
      systemBunrui3: Form.createFormField({
        value: props.data ? props.data.systemBunrui3 : null,
      }),
      systemBunrui4: Form.createFormField({
        value: props.data ? props.data.systemBunrui4 : null,
      }),
      systemBunrui5: Form.createFormField({
        value: props.data ? props.data.systemBunrui5 : null,
      }),
      tekiyo1: Form.createFormField({
        value: props.data ? props.data.tekiyo1 : null,
      }),
      tekiyo2: Form.createFormField({
        value: props.data ? props.data.tekiyo2 : null,
      }),
      tekiyo3: Form.createFormField({
        value: props.data ? props.data.tekiyo3 : null,
      }),
      nyukinBunrui: Form.createFormField({
        value: props.data ? props.data.nyukinBunrui : null,
      }),
      nyukinTekiyou: Form.createFormField({
        value: props.data ? props.data.nyukinTekiyou : null,
      }),
    };
  },
})(FormBasicNormal);
