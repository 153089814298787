import { seikatuCheckActionTypes } from "../action_types/index";

// constants
import { ConfigName } from "../../../config-name";
import { ApiPaths } from "../../constants";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils/index";

function createUpSeikatu(body) {
  return async (dispatch) => {
    try {
      dispatch({
        type: seikatuCheckActionTypes.SAVE_SEIKATU_CHECK_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.SeikatuKinouCheck.SaveSeikatuCheck,
        body,
      });
      dispatch({
        type: seikatuCheckActionTypes.SAVE_SEIKATU_CHECK_SUCCESS,
        seikatuCheck: data,
      });
    }
    catch (error) {
      dispatch({
        type: seikatuCheckActionTypes.SAVE_SEIKATU_CHECK_ERROR,
        error,
      });
    }
  };
}

function getSeikatuCheckById(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_REQUEST,
      });
      if (id === "new" || id === 0) {
        return dispatch({
          type: seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_SUCCESS,
          seikatuCheck: null,
        });
      }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.SeikatuKinouCheck.GetSeikatuKinouCheck}?id=${id}`,
      });
      dispatch({
        type: seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_SUCCESS,
        seikatuCheck: data,
      });
    }
    catch (error) {
      dispatch({
        type: seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_ERROR,
        error,
      });
    }
  };
}

function getSeikatuCheckByKKId(kkId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_REQUEST,
      });
      if (kkId === "new" || kkId === 0) {
        return dispatch({
          type: seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_SUCCESS,
          seikatuCheck: null,
        });
      }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.SeikatuKinouCheck.GetSeikatuKinouCheckByKKId}?kkId=${kkId}`,
      });
      dispatch({
        type: seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_SUCCESS,
        seikatuCheck: data,
      });
    }
    catch (error) {
      dispatch({
        type: seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_ERROR,
        error,
      });
    }
  };
}

function getListSeikatuCheckToQuote(riyousyaId, seikatuId) {
  const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
  return async (dispatch) => {
    const body = { riyousyaId, seikatuId, systemControlName: menu.scn, systemControlId: menu.si };
    try {
      dispatch({ type: seikatuCheckActionTypes.LOAD_SEIKATU_CHECK_TO_QUOTE });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.SeikatuKinouCheck.GetListSeikatuCheckToQuote}`,
        query: body,
      });
      dispatch({
        type: seikatuCheckActionTypes.LOAD_SEIKATU_CHECK_TO_QUOTE_SUCCESS,
        seikatuChecks: data,
      });
    }
    catch (error) {
      dispatch({
        type: seikatuCheckActionTypes.LOAD_SEIKATU_CHECK_TO_QUOTE_ERROR,
        error,
      });
    }
  };
}

function getListId(riyousyaId) {
  const sort =
    StorageUtils.getValue("list_sort_life_riyousya").jokusoPlan || {};
  const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
  return async (dispatch) => {
    const body = {
      riyousyaId,
      sid: menu.si,
      sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
    };
    try {
      dispatch({ type: seikatuCheckActionTypes.GET_IDS_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.SeikatuKinouCheck.GetIdsSeikatuCheck,
        query: body,
      });
      dispatch({
        type: seikatuCheckActionTypes.GET_IDS_SUCCESS,
        idsSeikatuCheck: data,
      });
    }
    catch (error) {
      dispatch({
        type: seikatuCheckActionTypes.GET_IDS_ERROR,
        error,
      });
    }
  };
}

function getAdlInyo(riyousyaId, callback) {
  const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
  return async (dispatch) => {
    const body = { riyousyaId, sid: menu.si };
    try {
      dispatch({
        type: seikatuCheckActionTypes.GET_ADL_INYO,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.SeikatuKinouCheck.GetAdlInyo,
        query: body,
      });
      callback(data);
      dispatch({
        type: seikatuCheckActionTypes.GET_ADL_INYO_SUCCESS,
      });
    }
    catch (error) {
      dispatch({
        type: seikatuCheckActionTypes.GET_ADL_INYO_ERROR,
        error,
      });
    }
  };
}

function setNewData() {
  return (dispatch) => {
    dispatch({
      type: seikatuCheckActionTypes.SET_NEW_DATA
    })
  }
}

export const SeikatuCheck = {
  createUpSeikatu,
  getListId,
  getSeikatuCheckById,
  getSeikatuCheckByKKId,
  getListSeikatuCheckToQuote,
  getAdlInyo,
  setNewData
};
