import {
  RIYOUSYA_PAGE_REQUEST,
  RIYOUSYA_PAGE_ERROR,
  RIYOUSYA_LIST_SUCCESS,
  RIYOUSYA_LOADMORE_SUCCESS,
  RIYOUSYA_DETAIL_PAGE_REQUEST,
  RIYOUSYA_DETAIL_PAGE_ERROR,
  RIYOUSYA_DETAIL_SUCCESS,
  RIYOUSYA_SHIRYOU_PAGE_REQUEST,
  RIYOUSYA_SHIRYOU_PAGE_ERROR,
  RIYOUSYA_SHIRYOU_SUCCESS,
  RIYOUSYA_INPUT_ADD,
  RIYOUSYA_INPUT_DELETE,
  RIYOUSYA_INPUT_SORT,
  RIYOUSYA_UPDATE_SERVER,
  RIYOUSYA_MEAL_RESET,
  RIYOUSYA_MEAL_ABOVE_SUCCESS,
  RIYOUSYA_MEAL_BELOW_SUCCESS,
  RIYOUSYA_MEAL_DEL_SYOKUJISEN,
  RIYOUSYA_MEAL_DEL_SHOKUJI,
  RIYOUSYA_MEAL_UPDATE_SYOKUJISEN,
  RIYOUSYA_MEAL_UPDATE_SHOKUJI,

  // RIYOUSYA_MEAL_UPDATE_REQUEST,
  RIYOUSYA_MEAL_UPDATE_SUCCESS,
  RIYOUSYA_MEAL_UPDATE_ERROR,
  RIYOUSYA_FILE_UPDATE_REQUEST,
  // RIYOUSYA_FILE_UPDATE_ERROR,
  RIYOUSYA_FILE_UPDATE_SUCCESS,
  RIYOUSYA_FILE_INSERT_REQUEST,
  // RIYOUSYA_FILE_INSERT_ERROR,
  RIYOUSYA_FILE_INSERT_SUCCESS,

  // RIYOUSYA_FILE_DETAIL_REQUEST,
  // RIYOUSYA_FILE_DETAIL_ERROR,
  RIYOUSYA_FILE_DETAIL_SUCCESS,
  RIYOUSYA_FILE_DETAIL_ERROR,

  CHANGE_FACESHEET,
  RIYOU_CHART_TEMPLATE_SAVE,
  RIYOU_CHART_TEMPLATE_LOAD,
  RIYOU_CHART_TEMPLATE_DELETE,

  // 3S UPDATE
  GET_RIYOUSYA_SUCCESS,
  GET_RIYOUSYA_ERROR,
  GET_LIST_RIYOUSYA_ID_SUCCESS,
  GET_LIST_RIYOUSYA_ID_ERROR,
  RIYOUSYA_HOKEN_ABOVE_REQUEST,
  RIYOUSYA_HOKEN_ABOVE_SUCCESS,
  RIYOUSYA_HOKEN_BELOW_REQUEST,
  RIYOUSYA_HOKEN_BELOW_SUCCESS,
  RIYOUSYA_LIST_IS_KEEP,
  KAIGOHOKEN_MASTER_SPIN_REQUEST,
  LOAD_KAIGOHOKEN_MASTER_SUCCESS,
  LOAD_KAIGOHOKEN_MASTER_ERROR,
  KOHIFUTAN_MASTER_SPIN_REQUEST,
  LOAD_KOHIFUTAN_MASTER_SUCCESS,
  LOAD_KOHIFUTAN_MASTER_ERROR,
  ITAKUTANTO_MASTER_SPIN_REQUEST,
  LOAD_ITAKUTANTO_MASTER_SUCCESS,
  LOAD_ITAKUTANTO_MASTER_ERROR,
  LOAD_KAIGODO_MASTER_RIYOU_PAGE,
  RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST,
  RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS,
  RIYOUSYA_KOHI_UPDATE_REQUEST,
  RIYOUSYA_KOHI_UPDATE_SUCCESS,
  RIYOUSYA_KAIGOHOKEN_DELETE,
  RIYOUSYA_KOHIJOHO_DELETE,
} from "../../actions/riyousyaActions/actionName.js";

import _ from "lodash";
import {
  openNotificationWithIcon,
  riyouAddSortDel,
} from "../../../common/function_common/functionCommon.js";

// import { getValueLocalstorage } from '../../common/function_common/functionCommon.js';

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  riyousya: [],
  loading: false,
  page: 1,
  page_total: 1,
  detail: null,
  shiryou: [],
  mealAbove: [],
  mealBelow: [],
  updateMealAbove: [],
  updateMealBelow: [],
  delSyokujisenIds: "",
  delShokujiIds: "",
  isMealUpdating: false,
  isMealMessage: {},
  loadingUpload: false,
  fdetail: null,
  changeFacesheetState: false,
  saveFacesheetState: false,
  openFacesheetState: false,
};

export default function messageListReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RIYOUSYA_KAIGOHOKEN_DELETE:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        hokenAbove: state.hokenAbove.filter((item) => {
          return item.id !== action.id;
        }),
      };

    case RIYOUSYA_KOHIJOHO_DELETE:
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        hokenBelow: state.hokenBelow.filter((item) => {
          return item.id !== action.id;
        }),
      };

    case RIYOUSYA_FILE_UPDATE_REQUEST: {
      return {
        ...state,
        loadingUpload: true,
      };
    }

    case RIYOUSYA_FILE_UPDATE_SUCCESS: {
      return {
        ...state,
        loadingUpload: false,
      };
    }

    case RIYOUSYA_FILE_INSERT_REQUEST: {
      return {
        ...state,
        loadingUpload: true,
      };
    }

    case RIYOUSYA_FILE_INSERT_SUCCESS: {
      return {
        ...state,
        loadingUpload: false,
      };
    }

    case RIYOUSYA_FILE_DETAIL_ERROR: {
      return {
        ...state,
        fdetail: null,
      };
    }

    case RIYOUSYA_FILE_DETAIL_SUCCESS: {
      return {
        ...state,
        fdetail: action.payload,
      };
    }

    case RIYOUSYA_MEAL_RESET: {
      return {
        ...state,
        isMealUpdating: false,
        isMealMessage: {},
        delSyokujisenIds: "",
        delShokujiIds: "",
      };
    }

    case RIYOUSYA_MEAL_UPDATE_SUCCESS: {
      return {
        ...state,
        isMealUpdating: true,
        isMealMessage: { type: "success", message: "保存しました" },
        // mealAbove: action.payload.mealAbove,
        // mealBelow: action.payload.mealBelow,
      };
    }

    case RIYOUSYA_MEAL_UPDATE_ERROR: {
      return {
        ...state,
        isMealUpdating: false,
        isMealMessage: { type: "error", message: "保存できませんでした" },
      };
    }

    case RIYOUSYA_MEAL_UPDATE_SYOKUJISEN:
      let aboveParams = null;
      let updateSyokujisen = state.mealAbove;
      let updateMealAbove = state.updateMealAbove;

      // case update
      if (action.params && action.params.id) {
        aboveParams = state.mealAbove.find((item) => {
          return item.id === action.params.id
            ? Object.assign(item, action.params)
            : null;
        });

        updateSyokujisen = state.mealAbove.map((item) => {
          return item.id === action.params.id
            ? Object.assign(item, action.params)
            : item;
        });
      }

      // case add
      if (action.params && (!action.params.id || action.params.id < 0)) {
        if (updateSyokujisen.every((item) => item.id != action.params.id)) {
          aboveParams = action.params;
          updateSyokujisen.unshift(action.params);
        }
      }

      updateMealAbove.push(aboveParams);

      return {
        ...state,
        mealAbove: updateSyokujisen,
        updateMealAbove: updateMealAbove,
      };

    case RIYOUSYA_MEAL_UPDATE_SHOKUJI:
      let belowParams = null;
      let updateShokuji = state.mealBelow;
      let updateMealBelow = state.updateMealBelow;

      // case update
      if (action.params && action.params.id) {
        belowParams = state.mealBelow.find((item) => {
          return item.id === action.params.id
            ? Object.assign(item, action.params)
            : item;
        });

        updateShokuji = state.mealBelow.map((item) => {
          return item.id === action.params.id
            ? Object.assign(item, action.params)
            : item;
        });
      }

      // case add
      if (action.params && (!action.params.id || action.params.id < 0)) {
        if (updateShokuji.every((item) => item.id != action.params.id)) {
          belowParams = action.params;
          updateShokuji.unshift(action.params);
        }
      }

      updateMealBelow.push(belowParams);

      return {
        ...state,
        mealBelow: updateShokuji,
        updateMealBelow: updateMealBelow,
      };

    case RIYOUSYA_MEAL_DEL_SYOKUJISEN:
      let mealAbove = state.mealAbove;

      if (action.params[0]) {
        mealAbove = state.mealAbove.filter((item) => {
          return item.id !== action.params[0];
        });
      } else {
        mealAbove = state.mealAbove.filter((item, index) => {
          return index !== action.params[1];
        });
      }

      return {
        ...state,
        mealAbove: mealAbove,
        delSyokujisenIds: action.params[0]
          ? state.delSyokujisenIds + action.params[0] + ","
          : state.delSyokujisenIds,
      };

    case RIYOUSYA_MEAL_DEL_SHOKUJI:
      let mealBelow = state.mealBelow;

      if (action.params[0]) {
        mealBelow = state.mealBelow.filter((item) => {
          return item.id !== action.params[0];
        });
      } else {
        mealBelow = state.mealBelow.filter((item, index) => {
          return index !== action.params[1];
        });
      }

      return {
        ...state,
        mealBelow: mealBelow,
        delShokujiIds: action.params[0]
          ? state.delShokujiIds + action.params[0] + ","
          : state.delShokujiIds,
      };

    case RIYOUSYA_MEAL_ABOVE_SUCCESS:
      let mealAboveList =
        action.payload && action.payload.data
          ? action.payload.data
          : state.mealAbove;
      let updateMealAboveList = state.updateMealAbove;

      if (updateMealAboveList.length > 0) {
        mealAboveList = mealAboveList.map((item) => {
          let margeAbove = null;
          for (let i = 0; updateMealAboveList.length > i; i++) {
            margeAbove =
              item.id === updateMealAboveList[i].id
                ? Object.assign(item, updateMealAboveList[i])
                : null;
          }
          return margeAbove ? margeAbove : item;
        });
      }

      return {
        ...state,
        mealAbove: mealAboveList,
        pageMealAbove:
          action.payload && action.payload.meta && action.payload.meta.page
            ? action.payload.meta.page
            : 1,
        pageTotalMealAbove:
          action.payload &&
          action.payload.meta &&
          action.payload.meta.page_total
            ? action.payload.meta.page_total
            : 1,
      };

    case RIYOUSYA_MEAL_BELOW_SUCCESS:
      let mealBelowList =
        action.payload && action.payload.data
          ? action.payload.data
          : state.mealBelow;
      let updateMealBelowList = state.updateMealBelow;

      if (updateMealBelowList.length > 0) {
        mealBelowList = mealBelowList.map((item) => {
          let margeBelow = null;
          for (let i = 0; updateMealBelowList.length > i; i++) {
            margeBelow =
              item.id === updateMealBelowList[i].id
                ? Object.assign(item, updateMealBelowList[i])
                : null;
          }
          return margeBelow ? margeBelow : item;
        });
      }

      return {
        ...state,
        mealBelow: mealBelowList,
        pageMealBelow:
          action.payload && action.payload.meta && action.payload.meta.page
            ? action.payload.meta.page
            : 1,
        pageTotalMealBelow:
          action.payload &&
          action.payload.meta &&
          action.payload.meta.page_total
            ? action.payload.meta.page_total
            : 1,
      };

    case RIYOUSYA_UPDATE_SERVER:
      return {
        ...state,
        detail: action.payload ? action.payload : state.detail,
      };

    case RIYOUSYA_INPUT_SORT:
      return {
        ...state,
        detail: riyouAddSortDel(
          state.detail,
          action.params.type,
          action.params.sortType,
          action.params.index
        ),
      };

    case RIYOUSYA_INPUT_DELETE: {
      return {
        ...state,
        detail: riyouAddSortDel(
          state.detail,
          action.params.type,
          "del",
          action.params.index
        ),
      };
    }

    case RIYOUSYA_INPUT_ADD:
      return {
        ...state,
        detail: riyouAddSortDel(state.detail, action.params.type, "add", null),
      };

    case RIYOUSYA_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RIYOUSYA_PAGE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case RIYOUSYA_LIST_SUCCESS:
      return {
        ...state,
        riyousya:
          action.payload && action.payload.data
            ? action.payload.data
            : state.riyousya,
        page:
          action.payload && action.payload.meta && action.payload.meta.page
            ? action.payload.meta.page
            : 1,
        page_total:
          action.payload &&
          action.payload.meta &&
          action.payload.meta.page_total
            ? action.payload.meta.page_total
            : 1,
        loading: false,
      };

    case RIYOUSYA_LOADMORE_SUCCESS:
      let preLoadArr = [...state.riyousya];

      if (action.payload && action.payload.data) {
        preLoadArr.push.apply(preLoadArr, action.payload.data);
      }

      return {
        ...state,
        riyousya: preLoadArr,
        page:
          action.payload &&
          action.payload.data &&
          action.payload.data.length > 0
            ? action.page
            : action.page && action.page > 1
            ? action.page - 1
            : action.page,
        page_total:
          action.payload &&
          action.payload.meta &&
          action.payload.meta.page_total
            ? action.payload.meta.page_total
            : 1,
        loading: false,
      };

    /**
     * Detail Riyousya Page
     *
     */
    case RIYOUSYA_DETAIL_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        openFacesheetState: false,
      };

    case RIYOUSYA_DETAIL_PAGE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case RIYOUSYA_DETAIL_SUCCESS: {
      return {
        ...state,
        detail: action.payload ? action.payload : state.detail,
        loading: false,
        openFacesheetState: true,
      };
    }

    /**
     * Shiryou Riyousya Page
     *
     */
    case RIYOUSYA_SHIRYOU_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RIYOUSYA_SHIRYOU_PAGE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case RIYOUSYA_SHIRYOU_SUCCESS:
      return {
        ...state,
        shiryou:
          action.payload && action.payload.data ? action.payload.data : [],
        page:
          action.payload && action.payload.meta && action.payload.meta.page
            ? action.payload.meta.page
            : 1,
        page_total:
          action.payload &&
          action.payload.meta &&
          action.payload.meta.page_total
            ? action.payload.meta.page_total
            : 1,
        loading: false,
      };

    // 3S UPDATE
    case GET_RIYOUSYA_SUCCESS:
      return {
        ...state,
        riyousya: action.riyousya,
      };
    case GET_RIYOUSYA_ERROR:
      return {
        ...state,
        riyousya: null,
      };
    case GET_LIST_RIYOUSYA_ID_SUCCESS:
      return {
        ...state,
        riyousyaIds: action.riyousyaIds,
      };
    case GET_LIST_RIYOUSYA_ID_ERROR:
      return {
        ...state,
        riyousyaIds: null,
      };

    case RIYOUSYA_HOKEN_ABOVE_REQUEST:
      return {
        ...state,
        // hokenLoading: true
      };

    case RIYOUSYA_HOKEN_ABOVE_SUCCESS:
      return {
        ...state,
        // hokenLoading: false,
        hokenAbove:
          action.payload && action.payload.data
            ? action.payload.data
            : state.mealAbove,
        pageHokenAbove:
          action.payload && action.payload.meta && action.payload.meta.page
            ? action.payload.meta.page
            : 1,
        pageTotalHokenAbove:
          action.payload &&
          action.payload.meta &&
          action.payload.meta.page_total
            ? action.payload.meta.page_total
            : 1,
      };

    case RIYOUSYA_HOKEN_BELOW_REQUEST:
      return {
        ...state,
        // kohiLoading: true,
      };

    case RIYOUSYA_HOKEN_BELOW_SUCCESS:
      return {
        ...state,
        // kohiLoading: false,
        hokenBelow:
          action.payload && action.payload.data
            ? action.payload.data
            : state.mealBelow,
        pageHokenBelow:
          action.payload && action.payload.meta && action.payload.meta.page
            ? action.payload.meta.page
            : 1,
        pageTotalHokenBelow:
          action.payload &&
          action.payload.meta &&
          action.payload.meta.page_total
            ? action.payload.meta.page_total
            : 1,
      };

    case KAIGOHOKEN_MASTER_SPIN_REQUEST:
      return {
        ...state,
        loadingKaigo: true,
      };
    case LOAD_KAIGOHOKEN_MASTER_SUCCESS:
      return {
        ...state,
        kaigoHokenMasterList: action.data,
        loadingKaigo: false,
      };

    case LOAD_KAIGOHOKEN_MASTER_ERROR:
      return {
        ...state,
        kaigoHokenMasterList: null,
        loadingKaigo: false,
      };

    case KOHIFUTAN_MASTER_SPIN_REQUEST:
      return {
        ...state,
        loadingKohi: true,
      };

    case LOAD_KOHIFUTAN_MASTER_SUCCESS:
      return {
        ...state,
        kohifutanMasterList: action.data,
        loadingKohi: false,
      };

    case LOAD_KOHIFUTAN_MASTER_ERROR:
      return {
        ...state,
        kohifutanMasterList: null,
        loadingKohi: false,
      };

    case ITAKUTANTO_MASTER_SPIN_REQUEST:
      return {
        ...state,
        loadingItaku: true,
      };
    case LOAD_ITAKUTANTO_MASTER_SUCCESS:
      return {
        ...state,
        itakuTantoMasterList: action.data,
        loadingItaku: false,
      };

    case LOAD_ITAKUTANTO_MASTER_ERROR:
      return {
        ...state,
        itakuTantoMasterList: null,
        loadingItaku: false,
      };

    case LOAD_KAIGODO_MASTER_RIYOU_PAGE:
      return {
        ...state,
        kaigodoMaster: action.data,
      };

    case RIYOUSYA_KAIGOHOKEN_UPDATE_REQUEST:
      return {
        ...state,
        hokenLoading: true,
      };

    case RIYOUSYA_KAIGOHOKEN_UPDATE_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      let updateHoken1 = state.hokenAbove;
      const index1 = state.hokenAbove.findIndex(
        (item) => item.id === action.payload.id
      );

      // case update
      if (index1 != null && index1 >= 0) {
        updateHoken1 = state.hokenAbove.map((item) => {
          return item.id === action.payload.id
            ? Object.assign(item, action.payload)
            : item;
        });
      } else {
        if (updateHoken1.every((item) => item.id != action.payload.id)) {
          updateHoken1.unshift(action.payload);
        }
      }

      return {
        ...state,
        hokenAbove: updateHoken1.map((a) => Object.assign({ ...a })),
        hokenLoading: false,
      };

    case RIYOUSYA_KOHI_UPDATE_REQUEST:
      return {
        ...state,
        kohiLoading: true,
      };

    case RIYOUSYA_KOHI_UPDATE_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      let updateHoken2 = state.hokenBelow;
      const index2 = state.hokenBelow.findIndex(
        (item) => item.id === action.payload.id
      );

      // case update
      if (index2 != null && index2 >= 0) {
        updateHoken2 = state.hokenBelow.map((item) => {
          return item.id === action.payload.id
            ? Object.assign(item, action.payload)
            : item;
        });
      } else {
        if (updateHoken2.every((item) => item.id != action.payload.id)) {
          updateHoken2.unshift(action.payload);
        }
      }

      return {
        ...state,
        kohiLoading: false,
        hokenBelow: updateHoken2.map((a) => Object.assign({ ...a })),
      };

    case RIYOUSYA_LIST_IS_KEEP: {
      return {
        ...state,
        addRiyousyaId: action.data.addRiyousyaId,
        isKeep: action.data.isKeep,
        searchName: action.data.searchName,
      };
    }

    case CHANGE_FACESHEET: {
      if (action?.data?.type === "save") {
        return {
          ...state,
          saveFacesheetState: action?.data?.state
        }
      }
      if (action?.data?.type === "change") {
        return {
          ...state,
          changeFacesheetState: action?.data?.state,
        }
      }
      if (action?.data?.type === "open") {
        return {
          ...state,
          openFacesheetState: action?.data?.state,
        }
      }
    }

    case RIYOU_CHART_TEMPLATE_SAVE: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        // riyouChartTmp: action.data,
      }
    }
    case RIYOU_CHART_TEMPLATE_LOAD: {
      return {
        ...state,
        // riyouChartTmp: action.data,
      }
    }
    case RIYOU_CHART_TEMPLATE_DELETE: {
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
      }
    }

    default:
      return state;
  }
}
