/* eslint-disable brace-style */
/* eslint-disable indent */
import { inquiryRequestActionTypes } from "../../actions/inquiryRequestActions/actionName";

function inquiryRequest(state = {}, action) {
  switch (action.type) {
    case inquiryRequestActionTypes.GET_SERVICE_SYURUI_MASTER:
    case inquiryRequestActionTypes.GET_SERVICE_SYURUI_MASTER_SUCCESS:
      return {
        ...state,
        serviceSyuruiMaster: action.serviceSyuruiMaster
      };
    case inquiryRequestActionTypes.GET_SERVICE_SYURUI_MASTER_ERROR:
      return {
        ...state
      };
    default:
      return { ...state };
  }
}

export default inquiryRequest;
