/* eslint-disable no-irregular-whitespace */
import pdfMake from "pdfmake";
import { General } from "../../constants";
// import pdfFonts from 'pdfmake/build/vfs_fonts';

const GENDER_OPTIONS = [
  {
    label: "男",
    value: 1,
  },
  {
    label: "女",
    value: 2,
  },
];
const FONT_ROUTE = window.location.origin + General.FontIPAGP;
const FONT_IPAGP = {
  ipagp: {
    normal: FONT_ROUTE,
    bold: FONT_ROUTE,
    italics: FONT_ROUTE,
    bolditalics: FONT_ROUTE,
  },
};

function printJokuso(list, optionMasters) {
  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 44;
    const MAX_LINE = 10;
    const stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    stringArr.forEach((item) => {
      countOld = count;
      count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
      if (count < MAX_LINE) result = result + item + "\n";
      else {
        result =
          result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
        return;
      }
    });
    return result;
  };
  const formatDateJapan = (dateStr) => {
    if (!dateStr) return "";

    const dob = new Date(dateStr);
    const age = calculateAge(dob);
    return (
      dob.getFullYear() +
      "年 " +
      (dob.getMonth() + 1) +
      "月 " +
      dob.getDate() +
      "日生（" +
      age +
      "歳）"
    );
  };

  const calculateAge = (dob) => {
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  const formatDate = (dateStr) => {
    return dateStr ? dateStr.replaceAll("-", "/") : "";
  };

  const generateOption = (options, field) => {
    if (options && options[field]) {
      return options[field]
        .split(";")
        .filter((item) => !!item)
        .map((item) => {
          // let temp = (item && item.split(":")[0]) ?? item;
          const temp = item.includes(":") ? item.split(":")[0] : item;
          return { label: item, value: temp };
        });
    }
    return [];
  };

  const generateOption2 = (options, field, firstPosition, endPosition) => {
    if (options && options[field]) {
      const optionsArr = options[field].split(";").filter((item) => !!item);
      const startIndex = firstPosition || 0;
      const endIndex = endPosition || optionsArr.length;
      const resultOptions = [];
      for (let i = startIndex; i < endIndex; i++) {
        const temp = optionsArr[i].includes(":")
          ? optionsArr[i].split(":")[0]
          : optionsArr[i];
        resultOptions.push({ label: optionsArr[i], value: temp });
      }
      return resultOptions;
    }
    return [];
  };

  const generateOption3 = (options, field) => {
    if (options && options[field]) {
      const optionsArr = options[field].split(";").filter((item) => !!item);
      const resultOptions = [];
      optionsArr.forEach((item) => {
        resultOptions.push({ label: item, value: item.split(":")[1] });
      });

      return resultOptions;
    }
    return [];
  };

  const generateCheckBoxString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value == optionSelected) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    return result;
  };

  const generateCheckBoxString2 = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (optionSelected.indexOf(item.value) > -1) {
        result += " ■" + item.label;
      } else {
        result += " □" + item.label;
      }
    });
    return result;
  };

  const generateCheckBoxArray = (options, optionSelected) => {
    return options.map((item) => {
      if (item.value == optionSelected) return " ■" + item.label;
      return " □" + item.label;
    });
  };

  const generatePage = (data, optionMasters) => {
    return [
      {
        text: "褥瘡対策に関するスクリーニング・ケア計画書/診療計画書",
        style: "header",
        alignment: "center",
        margin: [0, 55, 0, 10],
      },
      {
        fontSize: 8,
        margin: [0, 13, 0, 10],
        columns: [
          {
            text: "",
            width: "50%",
          },
          {
            text: "評価日 " + formatDate(data.hyoukaDate),
          },
          {
            text: "計画作成日 " + formatDate(data.makeDate),
          },
        ],
      },
      {
        fontSize: 8,
        margin: [20, 4, 0, 4],
        columns: [
          {
            text: "氏名    ",
            width: "8%",
          },
          {
            fontSize: 10,
            text: data.riyousyaName || "      ",
            width: "25%",
          },
          {
            text: "殿",
            width: "3%",
          },
          {
            margin: [2, 0, 0, 0],
            text: generateCheckBoxString(GENDER_OPTIONS, data.riyousyaGender),
          },
        ],
      },
      {
        margin: [20, 2, 0, 2],
        fontSize: 8,
        text: formatDateJapan(data.riyousyaBirthDay),
      },
      {
        fontSize: 8,
        margin: [0, 5],
        columns: [
          {
            text: "",
            width: "75%",
          },
          {
            text: `記入担当者名   ${data.tantoName}`,
          },
        ],
      },
      {
        fontSize: 8,
        margin: [20, 27, 0, 6],
        columns: [
          {
            text: "褥瘡の有無",
            width: "10%",
          },
          {
            text: "",
            width: "64%",
          },
          {
            text: `褥瘡発生日 ${formatDate(data.bedsoreDateOfOnset)}`,
            width: "23%",
          },
        ],
      },
      {
        fontSize: 8,
        margin: [20, 3, 0, 0],
        columns: [
          {
            text: `1．現在 ${generateCheckBoxString(
              generateOption(optionMasters, "jokusoumu"),
              data.isBedsore
            )}(${generateCheckBoxString(
              generateOption(optionMasters, "jokusobui"),
              data.bedsorePartOther
            )}(`,
            width: "*",
          },
          {
            text: data.bedsorePartFreeDescription,
            width: "19%",
          },
          {
            text: ")",
            width: "5%",
          },
        ],
      },
      {
        fontSize: 8,
        margin: [20, 10, 0, 0],
        columns: [
          {
            text: `2．過去 ${generateCheckBoxString(
              generateOption(optionMasters, "jokusoumu"),
              data.isPastBedsore
            )}(${generateCheckBoxString2(
              generateOption3(optionMasters, "jokusobui"),
              data.pastBedsorePart
            )}(`,
            width: "*",
          },
          {
            text: data.pastBedsorePartFreeDescription,
            width: "19%",
          },
          {
            text: ")",
            width: "5%",
          },
        ],
      },
      {
        margin: [20, 10, 0, 5],
        fontSize: 8,
        table: {
          widths: [10, 50, 30, 45, "*", 90],
          body: [
            [
              {
                text: "危険因子の評価",
                rowSpan: 13,
                alignment: "center",
                margin: [0, 50, 0, 0],
              },
              {
                text: "障害高齢者の日常生活自立度",
                colSpan: 3,
                alignment: "center",
              },
              { text: "" },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "ziritsudo"),
                  data.dailyDegree
                ),
              },
              { text: "対処", alignment: "center" },
            ],
            [
              { text: "" },
              { text: "ADLの状況", rowSpan: 4, alignment: "justify" },
              { text: "入浴", colSpan: 2 },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "adlnyuyoku"),
                  data.adlBathe
                ),
              },
              {
                text: "「自分で行っていない」、「あり」に1つ以上該当する場合、褥瘡ケア計画を立案し、実施する。",
                rowSpan: 12,
              },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "食事摂取", colSpan: 2 },
              { text: "" },
              {
                text: `${generateCheckBoxString(
                  generateOption(optionMasters, "shokuzisesshu"),
                  data.adlDietaryIntake
                )}（※1）`,
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "更衣", rowSpan: 2 },
              { text: "上衣" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "koui"),
                  data.adlDressingUpper
                ),
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "下衣" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "koui"),
                  data.adlDressingLower
                ),
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "基本動作", rowSpan: 4 },
              { text: "寝返り", colSpan: 2 },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "negaeri"),
                  data.kihonTurningOver
                ),
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "座位の保持", colSpan: 2 },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "zai"),
                  data.kihonSittingContinuous
                ),
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "座位での乗り移り", colSpan: 2 },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "zai"),
                  data.kihonTransfer
                ),
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "立位の保持", colSpan: 2 },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "ritsui"),
                  data.kihonKeepStanding
                ),
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "排せつの状況", rowSpan: 3 },
              { text: "尿失禁", colSpan: 2 },
              { text: "" },
              {
                text: `${generateCheckBoxString(
                  generateOption(optionMasters, "sikkin"),
                  data.haisetuUrinationIncontinence
                )}（※2）`,
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "便失禁", colSpan: 2 },
              { text: "" },
              {
                text: `${generateCheckBoxString(
                  generateOption(optionMasters, "sikkin"),
                  data.haisetuDefecationIncontinence
                )}（※3）`,
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "バルーンカテーテル使用", colSpan: 2 },
              { text: "" },
              {
                text: `${generateCheckBoxString(
                  generateOption(optionMasters, "catheter"),
                  data.haisetuBalloonCatheter
                )}`,
              },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "過去3か月以内に褥瘡の既往があるか", colSpan: 3 },
              { text: "" },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "kioreki"),
                  data.isBedsoreWithinThreeMonths
                ),
              },
              { text: "" },
            ],
          ],
        },
      },
      {
        margin: [35, 0, 0, 15],
        fontSize: 8,
        text: "※1  経管栄養・経静脈栄養等の場合　　※2　バルーンカテーテル等を使用もしくは自己導尿等の場合　　※3　人工肛門等の場合",
      },
      {
        fontSize: 8,
        margin: [20, 5, 0, 5],
        pageBreak: "after",
        table: {
          widths: [10, 50, "*", "*"],
          body: [
            [
              {
                text: "褥瘡の状態の評価",
                rowSpan: 7,
                alignment: "center",
                margin: [0, 80, 0, 0],
              },
              { text: "深さ", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "jokusohukasa", null, 3),
                  data.depthEvaluation
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "jokusohukasa", 3, null),
                  data.depthEvaluation
                ),
              },
            ],
            [
              { text: "" },
              { text: "浸出液", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "sinshutueki", null, 3),
                  data.leachateEvaluation
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "sinshutueki", 3, null),
                  data.leachateEvaluation
                ),
              },
            ],
            [
              { text: "" },
              { text: "大きさ", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "jokusoookisa", null, 6),
                  data.sizeEvaluation
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "jokusoookisa", 6, null),
                  data.sizeEvaluation
                ),
              },
            ],
            [
              { text: "" },
              { text: "炎症/感染", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "enshou", null, 2),
                  data.infectionEvaluation
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "enshou", 2, null),
                  data.infectionEvaluation
                ),
              },
            ],
            [
              { text: "" },
              { text: "肉芽組織", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "nikuga", null, 2),
                  data.granulationEvaluation
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "nikuga", 2, null),
                  data.granulationEvaluation
                ),
              },
            ],
            [
              { text: "" },
              { text: "壊死組織", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "esi", null, 1),
                  data.necroticTissueEvaluation
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "esi", 1, null),
                  data.necroticTissueEvaluation
                ),
              },
            ],
            [
              { text: "" },
              { text: "ポケット", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "jokusopocket", null, 1),
                  data.pocketSizeEvaluation
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "jokusopocket", 1, null),
                  data.pocketSizeEvaluation
                ),
              },
            ],
          ],
        },
      },
      {
        fontSize: 8,
        margin: [0, 13, 0, 10],
        columns: [
          {
            text: "",
            width: "50%",
          },
          {
            text: "評価日 " + formatDate(data.hyoukaDate),
          },
          {
            text: "計画作成日 " + formatDate(data.makeDate),
          },
        ],
      },
      {
        fontSize: 8,
        margin: [20, 4, 0, 4],
        columns: [
          {
            text: "氏名    ",
            width: "8%",
          },
          {
            fontSize: 10,
            text: data.riyousyaName || "      ",
            width: "25%",
          },
          {
            text: "殿",
            width: "3%",
          },
          {
            margin: [2, 0, 0, 0],
            text: generateCheckBoxString(GENDER_OPTIONS, data.riyousyaGender),
          },
        ],
      },
      {
        margin: [20, 2, 0, 2],
        fontSize: 8,
        text: formatDateJapan(data.riyousyaBirthDay),
      },
      {
        fontSize: 8,
        margin: [20, 15, 0, 5],
        table: {
          widths: [10, 80, 50, "*"],
          heights: [10, 80, 80, 80, 80, 80, 80, 80, 80],
          body: [
            [
              {
                text: "褥瘡ケア計画・看護計画",
                rowSpan: 9,
                margin: [0, 250, 0, 0],
              },
              { text: "留意する項目", colSpan: 2 },
              { text: "" },
              { text: "計画の内容" },
            ],
            [
              { text: "" },
              { text: "関連職種が共同して取り組むべき事項", colSpan: 2 },
              { text: "" },
              { text: formatString10Line(data.mattersRelatedJobs) },
            ],
            [
              { text: "" },
              { text: "評価を行う間隔", colSpan: 2 },
              { text: "" },
              { text: formatString10Line(data.evaluationInterval) },
            ],
            [
              { text: "" },
              {
                text: "圧迫、ズレ力の排除（体位変換、体圧分散寝具、頭部挙上方法、車椅子姿勢保持等）",
                rowSpan: 2,
              },
              { text: "ベット上" },
              { text: formatString10Line(data.removedPressureOnBed) },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "イス上" },
              { text: formatString10Line(data.removedPressureOnChair) },
            ],
            [
              { text: "" },
              { text: "スキンケア", colSpan: 2 },
              { text: "" },
              { text: formatString10Line(data.skinCare) },
            ],
            [
              { text: "" },
              { text: "栄養状態改善", colSpan: 2 },
              { text: "" },
              { text: formatString10Line(data.improvedNutrition) },
            ],
            [
              { text: "" },
              { text: "リハビリテーション", colSpan: 2 },
              { text: "" },
              { text: formatString10Line(data.rehabilitation) },
            ],
            [
              { text: "" },
              { text: "その他", colSpan: 2 },
              { text: "" },
              { text: formatString10Line(data.bedsoreOther) },
            ],
          ],
        },
      },
      {
        fontSize: 8,
        columns: [
          {
            text: "",
            width: "65%",
          },
          {
            text: "説明日　　　年　　　月　　　日",
            width: "*",
          },
        ],
      },
      {
        fontSize: 8,
        margin: [0, 3],
        columns: [
          {
            text: "",
            width: "65%",
          },
          {
            text: "説明者氏名",
            width: "*",
          },
        ],
      },
      {
        text: "",
        pageBreak: "after",
      },
    ];
  };

  const generateMultiPage = (list, optionMasters) => {
    let result = [];
    list.forEach((item) => {
      result = [...result, ...generatePage(item, optionMasters)];
    });
    result.pop();
    return result;
  };

  pdfMake.fonts = FONT_IPAGP;

  const documentDefinition = {
    info: {
      title: "褥瘡計画書",
    },
    pageMargins: [30, 10, 30, 10],
    content: generateMultiPage(list, optionMasters),
    defaultStyle: {
      font: "ipagp",
    },
  };
  pdfMake.createPdf(documentDefinition).open();
}

function printHaisetsu(list, optionMasters) {
  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 78;
    const MAX_LINE = 10;
    const stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    if (stringArr.length > 1) {
      stringArr.forEach((item) => {
        countOld = count;
        count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
        if (count < MAX_LINE) {
          result = result + item + "\n";
        } else {
          result =
            result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
          return;
        }
      });
    } else {
      stringArr.forEach((item) => {
        result =
          result + item.substring(0, (MAX_LINE - count) * LENGTH_LINE - 3);
        return;
      });
    }
    return result;
  };
  const formatDateJapan = (dateStr) => {
    if (!dateStr) return "";

    const dob = new Date(dateStr);
    const age = calculateAge(dob);
    return (
      dob.getFullYear() +
      "年 " +
      (dob.getMonth() + 1) +
      "月 " +
      dob.getDate() +
      "日生（" +
      age +
      "歳）"
    );
  };

  const calculateAge = (dob) => {
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  const formatDate = (dateStr) => {
    return dateStr ? dateStr.replaceAll("-", "/") : "";
  };

  const generateCheckBoxString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value == optionSelected) {
        result += "■" + item.label;
      } else {
        result += "□" + item.label;
      }
    });
    return result;
  };

  const generateCheckBoxArray = (options, optionSelected) => {
    return options.map((item) => {
      if (item.value == optionSelected) return " ■" + item.label;
      return " □" + item.label;
    });
  };

  const generateOption2 = (options, field) => {
    if (options && options[field]) {
      const optionsArr = options[field].split(";").filter((item) => !!item);
      const resultOptions = [];
      optionsArr.forEach((item) => {
        resultOptions.push({ label: item, value: item.split(":")[0] });
      });
      return resultOptions;
    }
    return [];
  };

  const generatePage = (data) => {
    return [
      {
        text: "排せつの状態に関するスクリーニング・支援計画書",
        style: "header",
        alignment: "center",
        margin: [0, 50, 0, 10],
      },
      {
        fontSize: 8,
        margin: [0, 3, 0, 10],
        columns: [
          {
            text: "",
            width: "50%",
          },
          {
            text: "評価日 " + formatDate(data.hyoukaDate),
          },
          {
            text: "計画作成日 " + formatDate(data.makeDate),
          },
        ],
      },
      {
        fontSize: 8,
        margin: [20, 1, 0, 1],
        columns: [
          {
            text: "氏名",
            width: "8%",
          },
          {
            fontSize: 10,
            text:
              data.riyousya && data.riyousya.name
                ? data.riyousya.name
                : "      ",
            width: "25%",
          },
          {
            text: "殿",
            width: "3%",
          },
          {
            margin: [2, 0, 0, 0],
            text: data.riyousya
              ? generateCheckBoxString(GENDER_OPTIONS, data.riyousya.gender)
              : " ",
          },
        ],
      },
      {
        margin: [20, 1, 0, 1],
        fontSize: 8,
        text: formatDateJapan(data.riyousya && data.riyousya.birthday),
      },
      {
        fontSize: 8,
        margin: [0, 1],
        columns: [
          {
            text: "",
            width: "75%",
          },
          {
            text: `記入者名   ${
              data.tantoMaster && data.tantoMaster.name
                ? data.tantoMaster.name
                : "      "
            }`,
          },
        ],
      },
      {
        fontSize: 8,
        margin: [0, 1],
        columns: [
          {
            text: "",
            width: "75%",
          },
          {
            text: `医師名　   ${
              data.doctorMaster && data.doctorMaster.name
                ? data.doctorMaster.name
                : "      "
            }`,
          },
        ],
      },
      {
        fontSize: 8,
        margin: [0, 1],
        columns: [
          {
            text: "",
            width: "75%",
          },
          {
            text: `看護師名   ${
              data.nurseTantoMaster && data.nurseTantoMaster.name
                ? data.nurseTantoMaster.name
                : "      "
            }`,
          },
        ],
      },
      {
        fontSize: 8,
        margin: [20, 20, 0, 0],
        text: "排せつの状態及び今後の見込み",
      },
      {
        margin: [20, 5, 0, 5],
        fontSize: 8,
        table: {
          widths: [50, 105, 105, 105, 105],
          heights: [10, 10, 50, 50, 50, 50],
          body: [
            [
              { text: "", rowSpan: 2 },
              { text: "施設入所時", rowSpan: 2, alignment: "center" },
              { text: "評価時", rowSpan: 2, alignment: "center" },
              { text: "３か月後の見込み", colSpan: 2, alignment: "center" },
              { text: "" },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "支援を行った場合", alignment: "center" },
              { text: "支援を行わない場合", alignment: "center" },
            ],
            [
              { text: "排尿の状態", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui1"),
                  data.urinationAdmissionStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui1"),
                  data.urinationEvaluationStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui1"),
                  data.urinationThreeSupportStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui1"),
                  data.urinationThreeNoSupportStatus
                ),
              },
            ],
            [
              { text: "排便の状態", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui2"),
                  data.defecationAdmissionStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui2"),
                  data.defecationEvaluationStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui2"),
                  data.defecationThreeSupportStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui2"),
                  data.defecationThreeNoSupportStatus
                ),
              },
            ],
            [
              { text: "おむつ使用の有無", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui3"),
                  data.diaperUserAdmissionStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui3"),
                  data.diaperUserEvaluationStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui3"),
                  data.diaperUserThreeSupportStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui3"),
                  data.diaperUserThreeNoSupportStatus
                ),
              },
            ],
            [
              { text: "ポータブルトイレ使用の有無", alignment: "center" },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui4"),
                  data.portableToiletAdmissionStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui4"),
                  data.portableToiletEvaluationStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui4"),
                  data.portableToiletThreeSupportStatus
                ),
              },
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: generateCheckBoxArray(
                  generateOption2(optionMasters, "bunrui4"),
                  data.portableToiletThreeNoSupportStatus
                ),
              },
            ],
          ],
        },
      },
      {
        margin: [20, 20, 0, 0],
        fontSize: 8,
        table: {
          widths: ["*"],
          body: [
            [
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: [
                  "排せつの状態に関する支援の必要性",
                  {
                    text: generateCheckBoxString(
                      [
                        { label: "あり", value: 1 },
                        { label: "なし", value: 0 },
                      ],
                      data.excretionStatusNecessity
                    ),
                    alignment: "center",
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        fontSize: 8,
        margin: [20, 20, 0, 0],
        text: "支援の必要性をありとした場合、以下を記載",
      },
      {
        margin: [20, 5, 0, 5],
        fontSize: 8,
        table: {
          widths: ["*"],
          heights: [80],
          body: [
            [
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: [
                  "排せつに介護を要する要因",
                  {
                    fontSize: 7,
                    text: formatString10Line(data.excretionCauseSupport),
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 5, 0, 5],
        fontSize: 8,
        table: {
          widths: ["*"],
          heights: [80],
          body: [
            [
              {
                margin: [-10, 0, 0, 0],
                type: "none",
                ol: [
                  "支援計画",
                  {
                    fontSize: 7,
                    text: formatString10Line(data.supportProgram),
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        fontSize: 8,
        margin: [20, 3, 0, 0],
        text: "上記の内容、及び支援開始後であってもいつでも希望に応じて支援計画を中断又は中止できることについて説明を受け、理解した上で、支援計画にある支援の実施を希望します。",
      },
      {
        fontSize: 8,
        margin: [0, 10, 0, 0],
        columns: [
          {
            text: "",
            width: "65%",
          },
          {
            text: "説明日　　　年　　　月　　　日",
            width: "*",
          },
        ],
      },
      {
        fontSize: 8,
        margin: [0, 3],
        columns: [
          {
            text: "",
            width: "65%",
          },
          {
            text: "説明者氏名",
            width: "*",
          },
        ],
      },
      {
        text: "",
        pageBreak: "after",
      },
    ];
  };

  const generateMultiPage = (list) => {
    let result = [];
    list.forEach((item) => {
      result = [...result, ...generatePage(item)];
    });
    result.pop();
    return result;
  };

  pdfMake.fonts = FONT_IPAGP;

  const documentDefinition = {
    info: {
      title: "排せつ支援計画書",
    },
    pageMargins: [30, 10, 30, 10],
    content: generateMultiPage(list),
    defaultStyle: {
      font: "ipagp",
    },
  };
  pdfMake.createPdf(documentDefinition).open();
}

function printKobetsu(list, optionMasters, data) {
  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 65;
    const MAX_LINE = 10;
    const stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    stringArr.forEach((item) => {
      countOld = count;
      count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
      if (count < MAX_LINE) result = result + item + "\n";
      else {
        result =
          result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
        return;
      }
    });
    return result;
  };

  const formatString10Line2 = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 32;
    const MAX_LINE = 10;
    const stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    stringArr.forEach((item) => {
      countOld = count;
      count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
      if (count < MAX_LINE) result = result + item + "\n";
      else {
        result =
          result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
        return;
      }
    });
    return result;
  };

  const formatString4Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 10;
    const MAX_LINE = 4;
    const stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    stringArr.forEach((item) => {
      countOld = count;
      count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
      if (count < MAX_LINE) result = result + item + "\n";
      else {
        result =
          result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
        return;
      }
    });
    return result;
  };
  const formatDateJapan = (dateStr) => {
    if (!dateStr) return "";

    const dob = new Date(dateStr);
    const age = calculateAge(dob);
    return (
      dob.getFullYear() +
      "年 " +
      (dob.getMonth() + 1) +
      "月 " +
      dob.getDate() +
      "日生（" +
      age +
      "歳）"
    );
  };

  const calculateAge = (dob) => {
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  const formatDate = (dateStr) => {
    if (dateStr !== "0001-01-01") {
      return dateStr ? dateStr.replaceAll("-", "/") : "";
    }
    return "           ";
  };

  const generateOption = (options, field) => {
    if (options && options[field]) {
      return options[field]
        .split(";")
        .filter((item) => !!item)
        .map((item) => {
          // let temp = (item && item.split(":")[0]) ?? item;
          const temp = item.includes(":") ? item.split(":")[0] : item;
          return { label: item, value: temp };
        });
    }
    return [];
  };

  const generateJobOption = (options, field) => {
    if (field !== null) {
      const optionsArr = options.split(";").filter((item) => !!item);
      let result = [];
      optionsArr.forEach((item) => {
        if (item.split(":")[0] === field) {
          result = item.split(":")[1];
        }
      });
      return result;
    }
    return [];
  };

  const generateKaigodoOption = (options, optionSelected) => {
    return options.map((item) => {
      if (item.kaigodoNo === optionSelected) {
        return item.kaigodoName;
      }
    });
  };

  const generateSyoubyouOption = (options) => {
    let result = "";
    options.map((item) => {
      if (item.msm_syoubyoumei !== null) {
        result += item.msm_syoubyoumei + "  ";
      } else {
        result += "";
      }
    });
    return result;
  };

  const generateKouiOption = (options, num) => {
    let count = 0;
    let result = "";
    options.slice(num, num + 3).map((item) => {
      count += 1;
      if (item.im_koui !== null) result += item.im_koui + "\n";
      else result += "";
    });
    if (count === 3) {
      return result;
    }
  };

  const generateCheckBoxString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value == optionSelected) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    return result;
  };

  const generatePage = (data, optionMasters, job) => {
    return [
      {
        text: "【個別機能訓練計画書】",
        style: "header",
        alignment: "center",
        margin: [0, 30, 0, 10],
      },
      {
        fontSize: 8,
        margin: [15, 10, 0, 5],
        table: {
          heights: ["auto", 12, 12, "auto", "auto"],
          widths: [30, 81, 19, 35, 50, 51, 45, 15, "*"],
          body: [
            [{ text: "利用者本人の希望" }, { text: "家族の希望" }],
            [
              { text: "作成日 :  " + formatDate(data.createDate), colSpan: 4 },
              { text: "" },
              { text: "" },
              { text: "" },
              {
                text: "前回作成日 :  " + formatDate(data.lastTimeCreateDate),
                colSpan: 3,
              },
              { text: "" },
              { text: "" },
              {
                text: "初回作成日 :  " + formatDate(data.firstTimeCreateDate),
                colSpan: 2,
              },
              { text: "" },
            ],
            [
              { text: "ふりがな", alignment: "center", margin: [0, 2, 0, 0] },
              {
                text: `${data.riyousya ? data.riyousya.furigana : " "}\n\n${
                  data.riyousya ? data.riyousya.name : " "
                }`,
                colSpan: 2,
                rowSpan: 2,
                alignment: "center",
                margin: [0, 2, 0, 0],
              },
              { text: "" },
              { text: "性別", alignment: "center", margin: [0, 2, 0, 0] },
              {
                text: formatDateJapan(data.riyousya && data.riyousya.birthday),
                rowSpan: 2,
                colSpan: 2,
                margin: [0, 9, 0, 0],
                alignment: "center",
              },
              { text: "" },
              {
                text: generateKaigodoOption(
                  optionMasters.kaidogo,
                  data.careLevel
                ),
                rowSpan: 2,
                alignment: "center",
                margin: [0, 9, 0, 0],
              },
              {
                text: `計画作成者 :  ${
                  data.createTanto ? data.createTanto.name : " "
                }\n\n　職種 :  ${generateJobOption(
                  job.naiyou,
                  data.createTanto ? data.createTanto.jobCategory : " "
                )} `,
                rowSpan: 2,
                colSpan: 2,
                margin: [0, 2, 0, 0],
              },
              { text: "" },
            ],
            [
              { text: "氏名", alignment: "center", margin: [0, 2, 0, 0] },
              {
                text: data.riyousya ? data.riyousya.name : " ",
                colSpan: 2,
                alignment: "center",
              },
              { text: "" },
              {
                text: generateCheckBoxString(
                  GENDER_OPTIONS,
                  data.riyousya ? data.riyousya.gender : " "
                ),
                margin: [0, 2, 0, 0],
              },
              { text: "" },
              { text: "" },
              { text: "" },
              {
                text: `職種 :  ${generateJobOption(
                  job.naiyou,
                  data.createTanto ? data.createTanto.jobCategory : " "
                )} `,
                colSpan: 2,
                margin: [24, 0, 0, 0],
              },
              { text: "" },
            ],
            [
              {
                text: "障害高齢者の日常生活自立度:",
                rowSpan: 2,
                colSpan: 2,
                alignment: "center",
                margin: [0, 3, 0, 0],
              },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "bunrui1"),
                  data.impairedDegree
                ),
                rowSpan: 2,
                colSpan: 3,
              },
              { text: "" },
              { text: "" },
              {
                text: "認知症高齢者の日常生活自立度:",
                rowSpan: 2,
                colSpan: 3,
                alignment: "center",
                margin: [0, 3, 0, 0],
              },
              { text: "" },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "bunrui2"),
                  data.dementiaDegree
                ),
                rowSpan: 2,
              },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
            ],
          ],
        },
      },
      {
        margin: [15, 3, 0, 0],
        fontSize: 8,
        text: "Ⅰ  利用者の基本情報",
      },
      {
        fontSize: 8,
        margin: [15, 3, 0, 3],
        table: {
          widths: ["50%", "50%"],
          heights: ["auto", 80],
          body: [
            [{ text: "利用者本人の希望" }, { text: "家族の希望" }],
            [
              { text: formatString10Line2(data.userRequest) },
              { text: formatString10Line2(data.userFamilyRequest) },
            ],
          ],
        },
      },
      {
        fontSize: 8,
        margin: [15, 3, 0, 3],
        table: {
          widths: ["50%", "50%"],
          heights: ["auto", 80, "auto", 80],
          body: [
            [
              { text: "利用者本人の社会参加の状況" },
              { text: "利用者の居宅の環境（環境因子）" },
            ],
            [
              { text: formatString10Line2(data.userSocialParticipationNotice) },
              { text: formatString10Line2(data.userEnvironmentNotice) },
            ],
          ],
        },
      },
      {
        margin: [15, 6, 0, 0],
        fontSize: 8,
        text: "健康状態・経過",
      },
      {
        fontSize: 8,
        margin: [15, 3, 0, 3],
        table: {
          widths: ["*"],
          heights: [15, 15],
          body: [
            [
              {
                text: `病名:  ${
                  data.mainSyoubyoumei ? data.mainSyoubyoumei : " "
                }`,
                margin: [0, 3, 0, 0],
              },
            ],
            [
              {
                text: `発症日・受傷日:    ${formatDate(
                  data.onsetDate ? data.onsetDate : " "
                )}          直近の入院日:    ${formatDate(
                  data.latestAdmissionDate ? data.latestAdmissionDate : " "
                )}          直近の退院日:    ${formatDate(
                  data.latestDischargeDate ? data.latestDischargeDate : " "
                )}`,
                margin: [0, 3, 0, 0],
              },
            ],
          ],
        },
      },
      {
        fontSize: 8,
        margin: [15, 3, 0, 3],
        table: {
          widths: ["*"],
          heights: ["auto", 80],
          body: [
            [{ text: "治療経過（手術がある場合は手術日・術式等）" }],
            [{ text: formatString10Line(data.progress) }],
          ],
        },
      },
      {
        fontSize: 8,
        margin: [15, 3, 0, 3],
        table: {
          widths: ["*"],
          heights: ["auto", 100],
          body: [
            [
              {
                text: "合併疾患・コントロール状態（高血圧、心疾患、呼吸器疾患、糖尿病等）",
              },
            ],
            [
              {
                text: `${generateSyoubyouOption(
                  data.complicationsControl
                )}\n\n\n${formatString10Line(
                  data.complicationsControlNotices
                    ? data.complicationsControlNotices
                    : "  "
                )}`,
              },
            ],
          ],
        },
      },
      {
        fontSize: 8,
        margin: [15, 3, 0, 3],
        pageBreak: "after",
        table: {
          widths: ["*"],
          heights: ["auto", 80],
          body: [
            [
              {
                text: "機能訓練実施上の留意事項（開始前・訓練中の留意事項・運動強度・負荷量等）",
              },
            ],
            [{ text: formatString10Line(data.implementationStatus) }],
          ],
        },
      },
      {
        fontSize: 8,
        margin: [15, 30, 0, 5],
        table: {
          heights: ["auto", 12, 12, "auto", "auto"],
          widths: [30, 81, 19, 35, 50, 51, 45, 15, "*"],
          body: [
            [
              { text: "作成日 :  " + formatDate(data.createDate), colSpan: 4 },
              { text: "" },
              { text: "" },
              { text: "" },
              {
                text: "前回作成日 :  " + formatDate(data.lastTimeCreateDate),
                colSpan: 3,
              },
              { text: "" },
              { text: "" },
              {
                text: "初回作成日 :  " + formatDate(data.firstTimeCreateDate),
                colSpan: 2,
              },
              { text: "" },
            ],
            [
              { text: "ふりがな", alignment: "center", margin: [0, 2, 0, 0] },
              {
                text: `${data.riyousya ? data.riyousya.furigana : " "}\n\n${
                  data.riyousya ? data.riyousya.name : " "
                }`,
                colSpan: 2,
                rowSpan: 2,
                alignment: "center",
                margin: [0, 2, 0, 0],
              },
              { text: "" },
              { text: "性別", alignment: "center", margin: [0, 2, 0, 0] },
              {
                text: formatDateJapan(data.riyousya && data.riyousya.birthday),
                rowSpan: 2,
                colSpan: 2,
                margin: [0, 9, 0, 0],
                alignment: "center",
              },
              { text: "" },
              {
                text: generateKaigodoOption(
                  optionMasters.kaidogo,
                  data.careLevel
                ),
                rowSpan: 2,
                alignment: "center",
                margin: [0, 9, 0, 0],
              },
              {
                text: `計画作成者 :  ${
                  data.createTanto ? data.createTanto.name : " "
                }\n\n職種 :  ${generateJobOption(
                  job.naiyou,
                  data.createTanto ? data.createTanto.jobCategory : " "
                )} `,
                rowSpan: 2,
                colSpan: 2,
                margin: [0, 2, 0, 0],
              },
              { text: "" },
            ],
            [
              { text: "氏名", alignment: "center", margin: [0, 2, 0, 0] },
              {
                text: data.riyousya ? data.riyousya.name : " ",
                colSpan: 2,
                alignment: "center",
              },
              { text: "" },
              {
                text: generateCheckBoxString(
                  GENDER_OPTIONS,
                  data.riyousya ? data.riyousya.gender : " "
                ),
                margin: [0, 2, 0, 0],
              },
              { text: "" },
              { text: "" },
              { text: "" },
              {
                text: `職種 :  ${generateJobOption(
                  job.naiyou,
                  data.createTanto ? data.createTanto.jobCategory : " "
                )} `,
                colSpan: 2,
                margin: [24, 0, 0, 0],
              },
              { text: "" },
            ],
            [
              {
                text: "障害高齢者の日常生活自立度:",
                rowSpan: 2,
                colSpan: 2,
                alignment: "center",
                margin: [0, 3, 0, 0],
              },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "bunrui1"),
                  data.impairedDegree
                ),
                rowSpan: 2,
                colSpan: 3,
              },
              { text: "" },
              { text: "" },
              {
                text: "認知症高齢者の日常生活自立度:",
                rowSpan: 2,
                colSpan: 3,
                alignment: "center",
                margin: [0, 3, 0, 0],
              },
              { text: "" },
              { text: "" },
              {
                text: generateCheckBoxString(
                  generateOption(optionMasters, "bunrui2"),
                  data.dementiaDegree
                ),
                rowSpan: 2,
              },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
              { text: "" },
            ],
          ],
        },
      },
      {
        margin: [15, 5, 0, 3],
        fontSize: 8,
        text: "Ⅱ　個別機能訓練の目標・個人機能訓練項目の設定",
      },
      {
        margin: [15, 0, 0, 0],
        fontSize: 8,
        text: "個別機能訓練の目標",
      },
      {
        fontSize: 8,
        margin: [15, 3, 0, 2],
        table: {
          widths: [23, 218, 23, "*"],
          heights: ["auto", 45, 45, 45],
          body: [
            [
              {
                text: `短期目標(今後3か月)   目標達成度 (${generateCheckBoxString(
                  generateOption(optionMasters, "lifeBunrui1"),
                  data.shortGoalAchievement
                )})`,
                colSpan: 2,
              },
              { text: "" },
              {
                text: `長期目標        目標達成度      (${generateCheckBoxString(
                  generateOption(optionMasters, "lifeBunrui1"),
                  data.longGoalAchievement
                )})`,
                colSpan: 2,
              },
              { text: "" },
            ],
            [
              { text: "(機能)" },
              { text: generateKouiOption(data.goalFunctionalTraining, 0) },
              { text: "(機能)" },
              { text: generateKouiOption(data.goalFunctionalTraining, 9) },
            ],
            [
              { text: "(活動)" },
              { text: generateKouiOption(data.goalFunctionalTraining, 3) },
              { text: "(活動)" },
              { text: generateKouiOption(data.goalFunctionalTraining, 12) },
            ],
            [
              { text: "(参加)" },
              { text: generateKouiOption(data.goalFunctionalTraining, 6) },
              { text: "(参加)" },
              { text: generateKouiOption(data.goalFunctionalTraining, 15) },
            ],
          ],
        },
      },
      {
        margin: [15, 5, 0, 0],
        fontSize: 8,
        text: "個別機能訓練項目",
      },
      {
        fontSize: 8,
        margin: [15, 3, 0, 2],
        table: {
          widths: [7, 190, 85, 40, 35, "*"],
          heights: ["auto", 35, 35, 35, 35],
          body: [
            [
              {
                text: "プログラム内容(何を目的に(～のために)～する)",
                colSpan: 2,
                alignment: "center",
              },
              { text: "" },
              { text: "留意点", alignment: "center" },
              { text: "頻度", alignment: "center" },
              { text: "時間", alignment: "center" },
              { text: "主な実施者", alignment: "center" },
            ],
            [
              { text: "①", margin: [0, 13, 0, 0] },
              {
                text: data.kobetukinouKunren2Program[0].snm_saiKomoku,
                margin: [0, 13, 0, 0],
              },
              {
                text: formatString4Line(data.kobetukinouKunren2Program[0].note),
              },
              {
                text: `週 ${
                  data.kobetukinouKunren2Program[0].times
                    ? data.kobetukinouKunren2Program[0].times
                    : "    "
                } 回`,
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
              {
                text: `${
                  data.kobetukinouKunren2Program[0].min
                    ? data.kobetukinouKunren2Program[0].min
                    : "   "
                } 分`,
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
              {
                text: generateJobOption(
                  job.naiyou,
                  data.kobetukinouKunren2Program[0].personnel
                ),
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
            ],
            [
              { text: "②", margin: [0, 13, 0, 0] },
              {
                text: data.kobetukinouKunren2Program[1].snm_saiKomoku,
                margin: [0, 13, 0, 0],
              },
              {
                text: formatString4Line(data.kobetukinouKunren2Program[1].note),
              },
              {
                text: `週 ${
                  data.kobetukinouKunren2Program[1].times
                    ? data.kobetukinouKunren2Program[1].times
                    : "    "
                } 回`,
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
              {
                text: `${
                  data.kobetukinouKunren2Program[1].min
                    ? data.kobetukinouKunren2Program[1].min
                    : "   "
                } 分`,
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
              {
                text: generateJobOption(
                  job.naiyou,
                  data.kobetukinouKunren2Program[1].personnel
                ),
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
            ],
            [
              { text: "③", margin: [0, 13, 0, 0] },
              {
                text: data.kobetukinouKunren2Program[2].snm_saiKomoku,
                margin: [0, 13, 0, 0],
              },
              {
                text: formatString4Line(data.kobetukinouKunren2Program[2].note),
              },
              {
                text: `週 ${
                  data.kobetukinouKunren2Program[2].times
                    ? data.kobetukinouKunren2Program[2].times
                    : "    "
                } 回`,
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
              {
                text: `${
                  data.kobetukinouKunren2Program[2].min
                    ? data.kobetukinouKunren2Program[2].min
                    : "   "
                } 分`,
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
              {
                text: generateJobOption(
                  job.naiyou,
                  data.kobetukinouKunren2Program[2].personnel
                ),
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
            ],
            [
              { text: "④", margin: [0, 13, 0, 0] },
              {
                text: data.kobetukinouKunren2Program[3].snm_saiKomoku,
                margin: [0, 13, 0, 0],
              },
              {
                text: formatString4Line(data.kobetukinouKunren2Program[3].note),
              },
              {
                text: `週 ${
                  data.kobetukinouKunren2Program[3].times
                    ? data.kobetukinouKunren2Program[3].times
                    : "    "
                } 回`,
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
              {
                text: `${
                  data.kobetukinouKunren2Program[3].min
                    ? data.kobetukinouKunren2Program[3].min
                    : "   "
                } 分`,
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
              {
                text: generateJobOption(
                  job.naiyou,
                  data.kobetukinouKunren2Program[3].personnel
                ),
                alignment: "center",
                margin: [0, 13, 0, 0],
              },
            ],
          ],
        },
      },
      {
        fontSize: 9,
        margin: [0, 0, 0, 10],
        columns: [
          {
            text: "",
            width: "70%",
          },
          {
            text: `プログラム立案者 :   ${
              data.plannerTanto ? data.plannerTanto.name : " "
            }`,
          },
        ],
      },
      {
        fontSize: 8,
        margin: [15, 5, 0, 3],
        table: {
          widths: ["50%", "50%"],
          heights: ["auto", 60],
          body: [
            [
              {
                text: "利用者本人・家族等がサービス利用時間以外に実施すること",
              },
              { text: "特記事項" },
            ],
            [
              { text: formatString10Line2(data.notNotice) },
              { text: formatString10Line2(data.tokki) },
            ],
          ],
        },
      },
      {
        fontSize: 8,
        margin: [15, 5, 0, 0],
        text: "Ⅲ  個別機能訓練実施後の対応",
      },
      {
        fontSize: 8,
        margin: [15, 3, 0, 3],
        table: {
          widths: ["50%", "50%"],
          heights: ["auto", 60],
          body: [
            [
              { text: "個別機能訓練の実施による変化" },
              { text: "個別機能訓練実施における課題とその要因" },
            ],
            [
              { text: formatString10Line2(data.change) },
              { text: formatString10Line2(data.reEvaluation) },
            ],
          ],
        },
      },
      // {
      //   fontSize: 7,
      //   margin: [15, 1, 0, 3],
      //   text: "※個別機能訓練の実施結果等をふまえ、個別機能訓練の目標や見直しや訓練項目の変更等を行った場合は、個別機能訓練計画書の再作成又は変更等を行い、個別機能訓練の目標・訓練項目等に係る最新の情報が把握できるようにすること。初回作成時にはⅢについては記載不要である。"
      // },
      {
        fontSize: 8,
        margin: [15, 12, 0, 3],
        table: {
          widths: ["50%", "50%"],
          // heights: [60],
          body: [
            [
              {
                text: `事業所番号 :${
                  data.serviceKikanMaster
                    ? data.serviceKikanMaster.jigyousyoNo
                    : " "
                }\n\n事業所名　 :${
                  data.serviceKikanMaster
                    ? data.serviceKikanMaster.jigyousyoName
                    : " "
                }\n\n住所　　　 :${
                  data.serviceKikanMaster
                    ? data.serviceKikanMaster.address1
                    : " "
                } ${
                  data.serviceKikanMaster
                    ? data.serviceKikanMaster.address2
                    : " "
                }\n\n電話番号　 :${
                  data.serviceKikanMaster ? data.serviceKikanMaster.tel : " "
                }`,
              },
              { text: "\n\n  説明日 :\n\n説明者 :" },
            ],
          ],
        },
      },
      {
        text: "",
        pageBreak: "after",
      },
    ];
  };

  const generateMultiPage = (list, optionMasters, data) => {
    let result = [];
    list.forEach((item) => {
      result = [...result, ...generatePage(item, optionMasters, data)];
    });
    result.pop();
    return result;
  };

  pdfMake.fonts = FONT_IPAGP;

  const documentDefinition = {
    info: {
      title: "個別機能訓練計画書",
    },
    pageMargins: [30, 10, 30, 10],
    content: generateMultiPage(list, optionMasters, data),
    defaultStyle: {
      font: "ipagp",
    },
  };
  pdfMake.createPdf(documentDefinition).open();
}

function printErrorCSV(list, tabName, date) {
  const generatePage = (data, tabName, date) => {
    /**
     * それぞれのcsvの内容を一直線の要素にして返す
     * @param {*} item  csv単体
     * @param {*} csvName csvの様式名
     */
    const csvNaiyouOption = (item, csvName) => {
      if (csvName == "riyou") {
        let result = "";
        const lastName = item.last_name ? item.last_name : "";
        const firstName = item.first_name ? item.first_name : "";
        result += lastName + firstName;
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_first_name == null) {
          result += "  利用者名=空";
        }
        if (item.none_last_name == null) {
          result += "  利用者姓=空";
        }
        if (item.none_gender == null) {
          result += "  利用者性別=空";
        }
        if (item.none_birthday == null) {
          result += "  利用者生年月日=空";
        }
        if (item.none_care_level == null) {
          result += "  要介護度=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
      if (csvName == "kagakuteki") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理明細番号=空";
        }
        if (item.none_impaired_elderly_independence_degree == null) {
          result += "  障害高齢者の日常生活自立度=空";
        }
        if (item.none_dementia_elderly_independence_degree == null) {
          result += "  認知度高齢者の日常生活自立度=空";
        }
        if (item.none_care_level == null) {
          result += "  要介護度=空";
        }
        if (item.none_facility_outpatient_category == null) {
          result += "  施設／通所・居宅区分=空";
        }
        if (item.none_evaluate_date == null) {
          result += "  評価日=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
      if (csvName == "kagakutekiKioureki") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_external_system_management_detail_number == null) {
          result += "  外部システム管理明細番号=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
      if (csvName == "kagakutekiFukuyaku") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_external_system_management_detail_number == null) {
          result += "  外部システム管理明細番号=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
      if (csvName == "kobetu") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_trinity_attempt == null) {
          result += "  一体的取組=空";
        }
        if (item.none_evaluate_date == null) {
          result += "  作成日=空";
        }
        if (item.none_care_level == null) {
          result += "  要介護度=空";
        }
        if (item.none_impaired_elderly_independence_degree == null) {
          result += "  障害高齢者の日常生活自立度=空";
        }
        if (item.none_dementia_elderly_independence_degree == null) {
          result += "  認知度高齢者の日常生活自立度=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=0200";
        }
        return result;
      }
      if (csvName == "seikatu") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_trinity_attempt == null) {
          result += "  一体的取組=空";
        }
        if (item.none_evaluate_date == null) {
          result += "  評価日=空";
        }
        if (item.none_care_level == null) {
          result += "  要介護度=空";
        }
        if (item.none_impaired_elderly_independence_degree == null) {
          result += "  障害高齢者の日常生活自立度=空";
        }
        if (item.none_dementia_elderly_independence_degree == null) {
          result += "  認知度高齢者の日常生活自立度=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=0200";
        }
        return result;
      }
      if (csvName == "kokuEisei") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_care_level == null) {
          result += "  要介護度=空";
        }
        if (item.none_filing_date_02 == null) {
          result += "  アセス実施日=空";
        }
        if (item.none_record_staff_job_category_01 == null) {
          result += "  記入者職員職種1=空";
        }
        if (item.none_record_staff_job_category_02 == null) {
          result += "  記入者職員職種2=空";
        }
        if (item.none_oral_care_record_staff_job_category_01 == null) {
          result += "  記録者職種1=空";
        }
        if (
          item.oral_care_filing_date_02 &&
          item.none_oral_care_record_staff_job_category_02 == null
        ) {
          result += "  記録者職種2=空";
        }
        if (
          item.oral_care_filing_date_03 &&
          item.none_oral_care_record_staff_job_category_03 == null
        ) {
          result += "  記録者職種3=空";
        }
        if (
          item.oral_care_filing_date_04 &&
          item.none_oral_care_record_staff_job_category_04 == null
        ) {
          result += "  記録者職種4=空";
        }
        if (
          item.oral_care_filing_date_05 &&
          item.none_oral_care_record_staff_job_category_05 == null
        ) {
          result += "  記録者職種5=空";
        }
        if (
          item.oral_care_filing_date_06 &&
          item.none_oral_care_record_staff_job_category_06 == null
        ) {
          result += "  記録者職種6=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
      if (csvName == "kokuKinou") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_plan_create_date == null) {
          result += "  計画作成日=空";
        }
        if (item.none_care_level == null) {
          result += "  要介護度=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
      if (csvName == "jokuso") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_care_level == null) {
          result += "  要介護度=空";
        }
        if (item.none_impaired_elderly_independence_degree == null) {
          result += "  障害高齢者の日常生活自立度=空";
        }
        if (item.none_dementia_elderly_independence_degree == null) {
          result += "  認知度高齢者の日常生活自立度=空";
        }
        if (item.none_evaluate_date == null) {
          result += "  評価日=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=0200";
        }
        return result;
      }
      if (csvName == "haisetu") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_evaluate_date == null) {
          result += "  評価日=空";
        }
        if (item.none_care_level == null) {
          result += "  要介護度=空";
        }
        if (item.none_impaired_elderly_independence_degree == null) {
          result += "  自立度=空";
        }
        if (item.none_dementia_elderly_independence_degree == null) {
          result += "  認知度=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=0200";
        }
        return result;
      }
      if (csvName == "jiritu") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_evaluate_date == null) {
          result += "  評価日=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
      if (csvName == "yakuzaiChange") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_create_date == null) {
          result += "  記録日=空";
        }
        if (item.none_external_system_management_detail_number == null) {
          result += "  外部システム管理明細番号=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
      if (csvName == "yakuzaiKioureki") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_external_system_management_number == null) {
          result += "  外部システム管理番号=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_external_system_management_detail_number == null) {
          result += "  外部システム管理明細番号=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
      if (csvName == "rehabiliAssessment") {
        let result = "";
        result += item?.none_name ? item.none_name.replaceAll(/\s+/g, "") : "";
        if (item.none_care_facility_id == null) {
          result += "  事業所番号=空";
        }
        if (item.none_service_code == null) {
          result += "  サービス種類コード=空";
        }
        if (item.none_insurer_no == null) {
          result += "  保険者番号=空";
        }
        if (item.none_insured_no == null) {
          result += "  被保険者番号=空";
        }
        if (item.none_trinity_attempt == null) {
            result += "  一体的取組=空";
        }
        if (item.none_care_level == null) {
            result += "  要介護度=空";
        }
        if (item.none_impaired_elderly_independence_degree == null) {
            result += "  自立度=空";
        }
        if (item.none_dementia_elderly_independence_degree == null) {
            result += "  認知度=空";
        }
        if (item.none_short_term_intensive_rehabilitation == null) {
            result += "  短期集中リハビリテーション実施加算算定=空";
        }
        if (item.none_evaluate_date == null) {
          result += "  計画作成日=空";
        }
        if (item.none_version == null) {
          result += "  バージョン番号=空";
        }
        return result;
      }
    };

    return [
      {
        text: "LIFE連携出力エラーリスト",
        decoration: "underline",
        style: "header",
        fontSize: 18,
        alignment: "left",
        margin: [0, 30, 0, 30], // 左、上、右、下
      },
      {
        fontSize: 12,
        margin: [10, 3, 0, 10],
        alignment: "left",
        columns: [
          {
            text: `【${tabName}】`,
            width: "30%",
          },
          {
            text: "作成月:  " + date,
          },
        ],
      },
      // 利用者
      data.NullCSVRiyouExport
        ? data.NullCSVRiyouExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "SERVICE_USER_INFO",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "riyou"), // ここに関数を入れる
            },
          ])
        : null,
      // 科学的
      data.NullCSVKagakutekiExport
        ? data.NullCSVKagakutekiExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "kagakuteki",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "kagakuteki"),
            },
          ])
        : null,
      // 科学的既往歴
      data.NullCSVKagakutekiKiourekiExport
        ? data.NullCSVKagakutekiKiourekiExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "kagakuteki_kioureki",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "kagakutekiKioureki"), // ここに関数を入れる
            },
          ])
        : null,
      // 科学的服薬
      data.NullCSVKagakutekiFukuyakuExport
        ? data.NullCSVKagakutekiFukuyakuExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "kagakuteki_fukuyaku",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "kagakutekiFukuyaku"), // ここに関数を入れる
            },
          ])
        : null,
      // 個別
      data.NullCSVKobetsuExport
        ? data.NullCSVKobetsuExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "kobetukinou_kunren2",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "kobetu"),
            },
          ])
        : null,
      // 生活
      data.NullCSVSeikatuExport
        ? data.NullCSVSeikatuExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "seikatu_kinou_check",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "seikatu"), // ここに関数を入れる
            },
          ])
        : null,
      // 口腔衛生
      data.NullCSVKokuEiseiExport
        ? data.NullCSVKokuEiseiExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "kokueisei",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "kokuEisei"), // ここに関数を入れる
            },
          ])
        : null,
      // 口腔機能
      data.NullCSVKokuKinoExport
        ? data.NullCSVKokuKinoExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "kokukino",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "kokuKinou"), // ここに関数を入れる
            },
          ])
        : null,
      // 褥瘡
      data.NullCSVJokusoExport
        ? data.NullCSVJokusoExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "jokuso_plan",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "jokuso"), // ここに関数を入れる
            },
          ])
        : null,
      // 排せつ
      data.NullCSVHaisetuExport
        ? data.NullCSVHaisetuExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "haisetu_sien_plan",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "haisetu"), // ここに関数を入れる
            },
          ])
        : null,
      // 自立
      data.NullCSVJirituExport
        ? data.NullCSVJirituExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "jiritu_sokusin",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "jiritu"), // ここに関数を入れる
            },
          ])
        : null,
      // 薬剤変更
      data.NullCSVYakuzaiChangeExport
        ? data.NullCSVYakuzaiChangeExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "yakuzai_change",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "yakuzaiChange"), // ここに関数を入れる
            },
          ])
        : null,
      // 薬剤既往歴
      data.NullCSVYakuzaiKiourekiExport
        ? data.NullCSVYakuzaiKiourekiExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "yakuzai_change_kioureki",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "yakuzaiKioureki"), // ここに関数を入れる
            },
          ])
        : null,
      // 通リハ計画書
      data.nullCsvRihabiliExport
        ? data.nullCsvRihabiliExport.map((item) => [
            {
              fontSize: 10,
              margin: [20, 15, 0, 2],
              text: "rehabili_assessment",
            },
            {
              fontSize: 9,
              decoration: "underline",
              margin: [20, 0, 0, 5],
              text: csvNaiyouOption(item, "rehabiliAssessment"), // ここに関数を入れる
            },
          ])
        : null,
      {
        text: "",
        pageBreak: "after",
      },
    ];
  };

  const generateMultiPage = (list, tabName, date) => {
    let result = [];
    list.forEach((item) => {
      result = [...result, ...generatePage(item, tabName, date)]; // メインコンテンツ
    });
    result.pop();
    return result;
  };

  pdfMake.fonts = FONT_IPAGP;

  // ここは先に発火
  const documentDefinition = {
    info: {
      title: "CSV未出力",
    },
    pageMargins: [40, 10, 40, 10],
    content: generateMultiPage(list, tabName, date), // ここでページ数の調整
    defaultStyle: {
      font: "ipagp",
    }
  };
  pdfMake.createPdf(documentDefinition).open();
}

function printErrorCheckMessCSV(list){
  const pdfMake = require('pdfmake/build/pdfmake');
  const pdfFonts = require('pdfmake/build/vfs_fonts');
  pdfMake.fonts = FONT_IPAGP;
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const colWidthDefault = 70;
  const maxLengthCol2 = Math.max(
    ...list.map((item) => (item.name ? item.name.length : 0))
  );
  const col2Width = maxLengthCol2 * 13;

  const tableBody = [
    [
      { text: '利用票ID', style: 'tableHeader' }, 
      { text: '利用者名', style: 'tableHeader' }, 
      { text: '作成年月', style: 'tableHeader' }, 
      { text: 'エラー内容', style: 'tableHeader' }, 
    ],
    ...list.map((item) => [
      { text: item.id, style: ['cell', 'col'], pageBreak: 'auto' },
      { text: item.name, style: ['cell', 'col2'], pageBreak: 'auto' },
      { text: item.riyouhyouDate, style: ['cell', 'col'], pageBreak: 'auto' },
      { text: item.errorMes, style: ['cell', 'cellwrap'], pageBreak: 'auto' },
    ])
  ];

  const documentDefinition = {
    content: [
      { text: '利用票エラーチェック', style: 'header'},
      {
        table: {
          widths: [colWidthDefault, col2Width, colWidthDefault, 'auto'],
          body: tableBody,
        },
      }
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        decoration: 'underline', 
        decorationStyle: 'solid', 
        decorationColor: 'black',
      },
      tableHeader: {
        bold: true,
        color: 'black',
        margin: [3, 5],
        textWrap: "nowrap"
      },
      cell: {
        margin: [5, 5],
        textWrap: "nowrap",
      },
      cellwrap: {
        textWrap: "break-word"
      },
      col: {
        width: colWidthDefault,
      },
      col2: {
        margin: [5, 0, 0, 0],
        textWrap: 'nowrap',
        width: col2Width,
      },
    },
    defaultStyle: {
        font: "ipagp",
    },
    pageBreakBefore: function(currentNode) {
         return currentNode.startPosition.top >= 730
    },
    footer: function(currentPage, pageCount) {
      return {
        text: `${currentPage.toString()} / ${pageCount}`,
        alignment: 'center',
        margin: [0, 10],
      };
    },
  };
  
  pdfMake.createPdf(documentDefinition).open();
}

export { printJokuso, printHaisetsu, printKobetsu, printErrorCSV, printErrorCheckMessCSV };

