import {
  SEND_GROUP_API_REQUEST,
  SEND_GROUP_API_ERROR,
  SEND_GROUP,
  SEND_GROUP_ALL,
  SEND_GROUP_ADD_LOCAL,
  SEND_GROUP_DEL_LOCAL,
  SEND_GROUP_ADD_TEXT,
  SEND_GROUP_UPDATE_SERVER,
} from "../../actions/sendGroupActions/actionName";
import {
  addSendGroupRecords,
  delSendGroupRecords,
  openNotificationWithIcon,
} from "../../../common/function_common/functionCommon";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  loading: false,
  groups: [],
  groupRecords: [],
  isUpdateGroup: false,
  isLoadindUpdate: false,
};
export default function sendGroupReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEND_GROUP_API_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SEND_GROUP_API_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SEND_GROUP_UPDATE_SERVER:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        groupRecords: action.payload,
        isUpdateGroup: false,
      };

    case SEND_GROUP:
      return {
        ...state,
        groups: action.groups,
      };

    case SEND_GROUP_ALL:
      return {
        ...state,
        groupRecords: action.payload,
        isUpdateGroup: false,
      };

    case SEND_GROUP_ADD_LOCAL:
      let tmpAdd = addSendGroupRecords(
        state.groupRecords,
        action.checkListUsers,
        action.selectedGroup
      );
      if (tmpAdd.userExisted) {
        openNotificationWithIcon(
          "error",
          `[${tmpAdd.userExisted}]はすでに存在します`
        );
      }

      return {
        ...state,
        groupRecords: tmpAdd.data,
        userExisted: tmpAdd.userExisted,
        isUpdateGroup: true,
      };

    case SEND_GROUP_DEL_LOCAL:
      let tmpDel = delSendGroupRecords(
        state.groupRecords,
        action.checkListGroups,
        action.selectedGroup,
        state.delIds
      );
      return {
        ...state,
        groupRecords: tmpDel.data,
        delIds: tmpDel.delIds,
        isUpdateGroup: true,
      };

    case SEND_GROUP_ADD_TEXT:
      state.groups.push(action.text);
      return {
        ...state,
      };

    default:
      return state;
  }
}
