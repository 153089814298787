import React, { Component } from "react";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import "./UserManagement.css";
import { FormInput } from "./UI/FormInput";

class Edit extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      userId: match.params.userId,
    };
  }

  /**
   *
   */
  componentDidMount() {
    // load data
    const { userId } = this.state;

    this.props.getUserInformation(userId);
    this.props.tantoMasterList();
  }

  /**
   *
   */
  componentDidUpdate() {}

  updateUser = (values) => {
    this.props.updateUser(values);
  };

  createUser = (values) => {
    this.props.createUser(values);
  };

  /**
   *
   */
  render() {
    const { sessionTimeout, userDetail } = this.props;

    return (
      <CommonPageContainer sessionTimeout={sessionTimeout}>
        <FormInput
          data={userDetail ? userDetail : {}}
          history={this.props.history}
          updateUser={this.updateUser}
          createUser={this.createUser}
          tantoMasterList={this.props.tantoMaster}
        />
      </CommonPageContainer>
    );
  }
}

export default Edit;
