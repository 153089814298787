import { ServiceKikanMasterActionTypes } from "../../../../management/redux/action_types";
import { openNotificationWithIcon } from "../../../common/function_common/functionCommon";

function serviceKikanMaster(
  state = { loading: false, serviceKikanList: [] },
  action
) {
  switch (action.type) {
    case ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceKikanMaster: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.GET_INITIAL_SERVICE_KIKAN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_INITIAL_SERVICE_KIKAN_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceKikanMaster: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_INITIAL_SERVICE_KIKAN_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceSyuruiMasters: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_SYURUI_MASTERS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceAdditions: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_ADDITION_BY_SYURUI_FAILURE: {
      return {
        ...state,
        loading: false,
        serviceAdditions: [],
      };
    }
    case ServiceKikanMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        hojinInfoMasters: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.SEARCH_HOJIN_INFO_MASTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        hojinInfoMasters: [],
      };
    }
    case ServiceKikanMasterActionTypes.CREATE_SERVICE_KIKAN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.CREATE_SERVICE_KIKAN_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.CREATE_SERVICE_KIKAN_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.UPDATE_SERVICE_KIKAN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.UPDATE_SERVICE_KIKAN_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.UPDATE_SERVICE_KIKAN_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.CHECK_LINKED_WEEKLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.CHECK_LINKED_WEEKLY_SUCCESS: {
      return {
        ...state,
        loading: false,
        linkedWeeklySchedule: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.CHECK_LINKED_WEEKLY_FAILURE: {
      return {
        ...state,
        loading: false,
        linkedWeeklySchedule: null,
      };
    }
    case ServiceKikanMasterActionTypes.GET_HOJIN_INFO_MASTER_REQUEST: {
      return {
        ...state,
      };
    }
    case ServiceKikanMasterActionTypes.GET_HOJIN_INFO_MASTER_SUCCESS: {
      return {
        ...state,
        hojinInfo: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_HOJIN_INFO_MASTER_FAILURE: {
      return {
        ...state,
        hojinInfo: null,
      };
    }
    case ServiceKikanMasterActionTypes.CREATE_HOJIN_INFO_MASTER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.CREATE_HOJIN_INFO_MASTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        hojinInfo: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.CREATE_HOJIN_INFO_MASTER_FAILURE: {
      return {
        ...state,
        loading: false,
        hojinInfo: null,
      };
    }
    case ServiceKikanMasterActionTypes.GET_LIST_SERVICE_MONTHLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_LIST_SERVICE_MONTHLY_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceMonthlyList: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_LIST_SERVICE_MONTHLY_FAILURE: {
      return {
        ...state,
        loading: false,
        serviceMonthlyList: [],
      };
    }
    case ServiceKikanMasterActionTypes.GET_TAISEI_MASTERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_TAISEI_MASTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        taiseiMasters: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_TAISEI_MASTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        taiseiMasters: null,
      };
    }
    case ServiceKikanMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_SUCCESS: {
      return {
        ...state,
        loading: false,
        tikibetuTikikubun: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_TIKIBETU_TIKIKUBUN_FAILURE: {
      return {
        ...state,
        loading: false,
        tikibetuTikikubun: null,
      };
    }
    case ServiceKikanMasterActionTypes.GET_TIKI_KUBUN_MASTER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_TIKI_KUBUN_MASTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        tikiKubunMaster: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_TIKI_KUBUN_MASTER_FAILURE: {
      return {
        ...state,
        loading: false,
        tikiKubunMaster: null,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SERVICE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SERVICE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceKikanList: action.data,
      };
    case ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SERVICE_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        serviceKikanList: [],
      };
    case ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SYURUI_CD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ServiceKikanMasterActionTypes.CREATE_SERVICE_MONTHLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SYURUI_CD_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceKikanList: action.data,
      };
    case ServiceKikanMasterActionTypes.GET_SERVICE_KIKAN_BY_SYURUI_CD_FAILURE:
      return {
        ...state,
        loading: false,
        serviceKikanList: [],
      };

    case ServiceKikanMasterActionTypes.CREATE_SERVICE_MONTHLY_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.CREATE_SERVICE_MONTHLY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.UPDATE_SERVICE_MONTHLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.UPDATE_SERVICE_MONTHLY_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.UPDATE_SERVICE_MONTHLY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.DELETE_SERVICE_MONTHLY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.DELETE_SERVICE_MONTHLY_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.DELETE_SERVICE_MONTHLY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ServiceKikanMasterActionTypes.SAVE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.SAVE_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        isReload: true,
      };
    }
    case ServiceKikanMasterActionTypes.SAVE_FAILURE: {
      if (action.error.message == "Request failed with status code 400") {
        openNotificationWithIcon("error", "既に同じ事業所が登録されています");
      } else {
        openNotificationWithIcon("error", action.error.message);
      }
      console.log("error", action.error);
      return {
        ...state,
        loading: false,
        isReload: false,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceKikanMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_SUCCESS: {
      return {
        ...state,
        syuruiCds: action.data.map((e) => e.serviceSyuruiCd),
      };
    }
    case ServiceKikanMasterActionTypes.GET_SYURUI_CD_BY_SERVICE_TYPE_FAILURE: {
      return {
        ...state,
        syuruiCds: [],
      };
    }
    case ServiceKikanMasterActionTypes.GET_MAX_SORT_NUM_REQUEST: {
      return {
        ...state,
        loading: true,
        sortNumCheck: false,
      };
    }
    case ServiceKikanMasterActionTypes.GET_MAX_SORT_NUM_SUCCESS: {
      return {
        ...state,
        loading: false,
        sortNumCheck: true,
        serviceKikanSortNum: action.data,
      };
    }
    case ServiceKikanMasterActionTypes.GET_MAX_SORT_NUM_FAILURE: {
      return {
        ...state,
        loading: false,
        sortNumCheck: true,
        serviceKikanSortNum: null,
      };
    }
    case ServiceKikanMasterActionTypes.TURN_OFF_RELOAD_SERVICE_KIKAN:
      return {
        ...state,
        isReload: undefined,
      };
    default: {
      return { ...state };
    }
  }
}

export default serviceKikanMaster;
