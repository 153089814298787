import { Form } from "@ant-design/compatible";
import { Button, DatePicker, Modal } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import React, { useEffect } from "react";
import { ConstSet } from "../../constants/index";
dayjs.extend(dayjsPluginUTC);

function CreatesRecord(props) {
  useEffect(() => {
    props.form.setFieldsValue({
      date: dayjs.utc(new Date(), ConstSet.DATE_FORMAT),
    });
  }, []);
  const { getFieldValue } = props.form;
  const formatValues = {
    date:
      dayjs(getFieldValue("date")).format(ConstSet.DATE_FORMAT) || new Date(),
  };
  return (
    (<Modal
      maskClosable={false}
      open={props.visible}
      title={"作成年月日"}
      onCancel={() => props.handleClose()}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={() => props.handleCancel()}
        >
          キャンセル
        </Button>,
        <Button
          key="back"
          type="primary"
          onClick={() => props.handleOk(formatValues)}
        >
          Ok
        </Button>,
      ]}
      width={500}
    >
      {props.form.getFieldDecorator("date")(<DatePicker />)}
    </Modal>)
  );
}

const _CreatesRecord = Form.create()(CreatesRecord);

export { _CreatesRecord as CreatesRecord };
