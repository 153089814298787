import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Table, notification } from "antd";
import React, { Component } from "react";
import { KanriLayoutContainer } from "./UI/KanriLayout";

import axios from "axios";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { apiUrls } from "../../common/configs/common-url";
import {
  getMenu,
  getPath,
  isLife,
  isManagement,
  setValueLocalstorage,
} from "../../common/function_common/functionCommon";
import { ConfigName } from "../../config-name";
import SearchDialog from "./UI/SearchDialog";

class KanriUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      selectedRowKeys: [], // Check here to configure the default column
      displayList: [],
      visible: false,
      visibleDelConfirmPopup: false,
      path: getPath(),
    };

    this.reloadAll = this.reloadAll.bind(this);
  }

  componentDidMount() {
    // load data
    this.props.getUserList();
    this.props.getSearchData("users");

    // update from state
    const { userSelectedRows } = this.props;
    if (userSelectedRows && userSelectedRows.length > 0) {
      this.setState({
        selectedRowKeys: userSelectedRows,
      });
    }
  }

  componentDidUpdate(oldProps) {
    const { notifyDisplay, notifyContent } = this.props;

    // case update vitalId when isert value to DOM
    if (oldProps.notifyDisplay !== notifyDisplay) {
      if (notifyDisplay === 1) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("success", notifyContent);
      }

      if (notifyDisplay === 2) {
        // @TODO: fix css, prefix plij not update for message and notification
        this.openNotificationWithIcon("error", notifyContent);
      }
    }
  }

  /**
   *
   */
  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  /**
   * Reload all data from server
   *
   */
  async reloadAll() {
    //
    await this.props.clearSelectedRow("user");

    //
    this.setState({
      selectedRowKeys: [],
    });

    this.props.getUserList();
  }

  onSelectChange = (selectedRowKeys) => {
    // this this info have been save in global state
    this.setState({ selectedRowKeys });

    this.props.updateSelectedRows("user", selectedRowKeys);
  };

  handleOkSearch = (text1, text2, text3) => {
    this.props.postUserList(text1, text2, text3);
    //
    this.setState({
      visible: false,
    });
  };

  handleCancelSearch = () => {
    //
    this.setState({
      visible: false,
    });
  };

  disSearchDialog = () => {
    //
    this.setState({
      visible: true,
    });
  };

  selectedDisplay = () => {
    const { selectedRowKeys } = this.state;

    // UPDATE ON LOCAL
    this.props.selectedDisplay(selectedRowKeys);
  };

  async loginKanri(record) {
    const { path } = this.state;
    const tid = record.tantoMaster ? record.tantoMaster.id : 0;
    const menu = getMenu();
    const preMenu = JSON.parse(localStorage.getItem(menu));

    // case Management
    if (isManagement()) {
      let menuSetting = "";
      await axios(apiUrls.INIT + apiUrls.KANRI_MENU_SETTING + `?tid=${tid}`, {
        method: "GET",
      }).then((res) => {
        menuSetting = res.data;
      });

      preMenu.menu = menuSetting;
      if (menuSetting) {
        preMenu.si = menuSetting.split(";")[0].split(",")[0];
        preMenu.sn = menuSetting.split(";")[0].split(",")[1];
        preMenu.sy = menuSetting.split(";")[0].split(",")[2];
        preMenu.scn = menuSetting.split(";")[0].split(",")[3];
      }

      const key = isLife() ? ConfigName.SWLIFE_AUTH : "auth";
      const auth = JSON.parse(localStorage.getItem(key));
      delete auth.jwtTokenType;
      localStorage.setItem(key, JSON.stringify(auth));

      preMenu.officeAuth =
        record.officeMnAuth != 0 ? String(record.officeMnAuth) : "0";
      preMenu.usageAuth =
        record.usageAuth != null || record.usageAuth != ""
          ? record.usageAuth
          : "";
      localStorage.setItem(menu, JSON.stringify(preMenu));
      setValueLocalstorage("uid", record ? record.id : 0);
      setValueLocalstorage(
        "tid",
        record.tantoMaster ? record.tantoMaster.id : 0
      ); // fix tid
      setValueLocalstorage(
        "mode",
        record.tantoMaster ? record.tantoMaster.name : "kanri"
      ); // kanri login mode
      setValueLocalstorage("menu", preMenu);

      this.props.history.push(path);
      window.location.reload();

      return;
    }

    // case LIFE
    if (isLife()) {
      let menuSetting = "";
      await axios(
        apiUrls.INIT + apiUrls.KANRI_MENU_SETTING_LIFE + `?tid=${tid}`,
        {
          method: "GET",
        }
      ).then((res) => {
        menuSetting = res.data;
      });

      preMenu.menu = menuSetting;
      if (menuSetting) {
        preMenu.si = menuSetting.split(";")[0].split(",")[0];
        preMenu.sn = menuSetting.split(";")[0].split(",")[1];
        preMenu.sy = menuSetting.split(";")[0].split(",")[2];
        preMenu.scn = menuSetting.split(";")[0].split(",")[3];
      }

      const key = isLife() ? ConfigName.SWLIFE_AUTH : "auth";
      const auth = JSON.parse(localStorage.getItem(key));
      delete auth.jwtTokenType;
      localStorage.setItem(key, JSON.stringify(auth));

      preMenu.officeAuth =
        record.officeMnAuth != 0 ? String(record.officeMnAuth) : "0";
      preMenu.usageAuth =
        record.usageAuth != null || record.usageAuth != ""
          ? record.usageAuth
          : "";
      localStorage.setItem(menu, JSON.stringify(preMenu));
      setValueLocalstorage("uid", record ? record.id : 0);
      setValueLocalstorage(
        "tid",
        record.tantoMaster ? record.tantoMaster.id : 0
      ); // fix tid
      setValueLocalstorage(
        "mode",
        record.tantoMaster ? record.tantoMaster.name : "kanri"
      ); // kanri login mode
      setValueLocalstorage("menu", preMenu);

      this.props.history.push(path);
      window.location.reload();

      return;
    }

    // case Default
    let menuKanri = "";
    await axios(
      apiUrls.INIT + apiUrls.KANRI_MENU_USER_LOGIN + `?tid=${tid}`,
      {
        method: "GET",
      }
    ).then((res) => {
      menuKanri = res.data;
    });

    preMenu.officeAuth =
      record.officeMnAuth != 0 ? String(record.officeMnAuth) : "0";
    preMenu.usageAuth =
      record.usageAuth != null || record.usageAuth != ""
        ? record.usageAuth
        : "";
    preMenu.menu = menuKanri;
    localStorage.setItem(menu, JSON.stringify(preMenu));
    setValueLocalstorage("uid", record ? record.id : 0);
    setValueLocalstorage("tid", record.tantoMaster ? record.tantoMaster.id : 0); // fix tid
    setValueLocalstorage(
      "mode",
      record.tantoMaster ? record.tantoMaster.name : "kanri"
    ); // kanri login mode
    setValueLocalstorage("menu", preMenu);

    this.props.history.push(path);
    window.location.reload();

    return;
  }

  setDisplayList = () => {
    const displayList = [];
    const list = this.props.kanriUserList;
    for (const i in list) {
      displayList.push(list[i].id);
    }
    this.props.displayList(displayList);
  };

  handleDel = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.setState({
        visibleDelConfirmPopup: true,
      });
    }
  };

  handleOKDelConfim = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      this.props.deleteUser(selectedRowKeys.toString());
    }

    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  handleCancelDelConfim = () => {
    this.setState({
      visibleDelConfirmPopup: false,
    });
  };

  render() {
    const { kanriUserList, loading, searchData } = this.props;
    // eslint-disable-next-line prefer-const, no-unused-vars
    let { sortedInfo, filteredInfo, visible, selectedRowKeys, path } =
      this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    // prepare export data
    const dataExportCsv = [];
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      const tmpDataExportCsv = kanriUserList.filter((item) =>
        selectedRowKeys.includes(item.id)
      );
      if (tmpDataExportCsv && tmpDataExportCsv.length > 0)
        for (let i = 0; i < tmpDataExportCsv.length; i++) {
          dataExportCsv.push({
            id: tmpDataExportCsv[i].id,
            ユーザID: tmpDataExportCsv[i].uid,
            名前: tmpDataExportCsv[i].name,
            担当者ID: tmpDataExportCsv[i].tid,
            メニュー権限: tmpDataExportCsv[i].usageAuth,
            利用者アクセス制限: tmpDataExportCsv[i].riyousyaAccessCtrl,
            システム管理者権限: tmpDataExportCsv[i].sysMnAuth,
            追加権限: tmpDataExportCsv[i].addAuth,
          });
        }
    }

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 50,
        sorter: (a, b) => a.id - b.id,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "ユーザID",
        dataIndex: "uid",
        key: "uid",
        width: 100,
        sorter: (a, b) =>
          (a.uid ? a.uid : "") < (b.uid ? b.uid : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "uid" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "名前",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) =>
          (a.name ? a.name : "") < (b.name ? b.name : "") ? -1 : 1,
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "担当者ID",
        dataIndex: "tantoMaster",
        key: "tantoMaster",
        width: 150,
        render: (text, record) =>
          record.tantoMaster ? record.tantoMaster.id : "",
        sorter: (a, b) =>
          (a.tantoMaster ? a.tantoMaster.id : 0) -
          (b.tantoMaster ? b.tantoMaster.id : 0),
        sortOrder: sortedInfo.columnKey === "tantoMaster" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "メニュー権限",
        dataIndex: "usageAuth",
        key: "usageAuth",
        width: 150,
        sorter: (a, b) =>
          (a.usageAuth ? a.usageAuth : "") < (b.usageAuth ? b.usageAuth : "")
            ? -1
            : 1,
        sortOrder: sortedInfo.columnKey === "usageAuth" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "利用者アクセス制限",
        dataIndex: "riyousyaAccessCtrl",
        key: "riyousyaAccessCtrl",
        width: 180,
        sorter: (a, b) =>
          (a.riyousyaAccessCtrl ? a.riyousyaAccessCtrl : 0) -
          (b.riyousyaAccessCtrl ? b.riyousyaAccessCtrl : 0),
        sortOrder:
          sortedInfo.columnKey === "riyousyaAccessCtrl" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "システム管理者権限",
        dataIndex: "sysMnAuth",
        key: "sysMnAuth",
        width: 180,
        sorter: (a, b) =>
          (a.sysMnAuth ? a.sysMnAuth : 0) - (b.sysMnAuth ? b.sysMnAuth : 0),
        sortOrder: sortedInfo.columnKey === "sysMnAuth" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "追加権限",
        dataIndex: "addAuth",
        key: "addAuth",
        width: 120,
        sorter: (a, b) =>
          (a.addAuth ? a.addAuth : 0) - (b.addAuth ? b.addAuth : 0),
        sortOrder: sortedInfo.columnKey === "addAuth" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "アクション",
        key: "operation",
        fixed: "right",
        width: 180,
        render: (text, record) => (
          <div>
            <Button type="primary" onClick={this.setDisplayList}>
              <Link to={`${path}/kanri/user/${record.id}`}>編集</Link>
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 8 }}
              onClick={this.loginKanri.bind(this, record)}
              disabled={
                !(record.tantoMaster && record.tantoMaster.id)
              }
            >
              ログイン
            </Button>
          </div>
        ),
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const menu = (
      <Menu>
        <Menu.Item>
          <CSVLink
            filename={`ユーザー${Date.now()}.csv`}
            data={dataExportCsv}
            className="btn btn-primary"
            disabled={!(dataExportCsv && dataExportCsv.length > 0)}
          >
            ダウンロード
          </CSVLink>
        </Menu.Item>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" onClick={this.handleDel}>
            削除
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      (<KanriLayoutContainer router="user" breadcrumbLink={"/kanri/user"} breadcrumbText={"ユーザ管理"}>
        <div className="kanri-user-page">
          <div className="table-operations">
            <Button type="primary" onClick={this.reloadAll}>
              全表示
            </Button>
            <Button type="primary" onClick={this.disSearchDialog}>
              検索
            </Button>
            <Button type="primary" onClick={this.selectedDisplay}>
              選択表示
            </Button>
            <Button type="primary">
              <Link to={`${path}/kanri/user/new`}>新規登録</Link>
            </Button>
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button type="primary">
                その他機能 <CaretDownOutlined />
              </Button>
            </Dropdown>

            {/* <Button onClick={this.clearFilters}>Clear filters</Button> */}
            {/* <Button type='primary' onClick={this.clearAll}>クリア</Button> */}
          </div>
          <div className="kanri-table">
            <Table
              scroll={{ x: 1500 }}
              columns={columns}
              dataSource={kanriUserList}
              rowSelection={rowSelection}
              className="table"
              rowKey={"id"}
              pagination={{ pageSize: 1000 }}
              onChange={this.handleChange}
              size="small"
              bordered
              loading={loading}
            />
          </div>
        </div>
        <SearchDialog
          title={"検索画面"}
          subTitle1={"対象項目"}
          subTitle2={"検索する文字"}
          subTitle3={"検索方法"}
          visible={visible}
          handleOk={this.handleOkSearch}
          handleCancel={this.handleCancelSearch}
          searchData={searchData}
        />
        <Modal
          title="処理確認"
          open={this.state.visibleDelConfirmPopup}
          onOk={this.handleOKDelConfim}
          onCancel={this.handleCancelDelConfim}
          okText="OK"
          cancelText="キャンセル"
        >
          <p>削除してもよろしいでしょうか？</p>
        </Modal>
      </KanriLayoutContainer>)
    );
  }
}

export default KanriUser;
