/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import MenuBusiness from "../../common/component/UI/MenuBusinessV2";
import MenuRecord from "../../common/component/UI/MenuRecordV2";
import { getValueLocalstorage } from "../../common/function_common/functionCommon";
import { checkMenuDis } from "../../common/function_common/functionDisplay";
import MenuTop from "../../management/components/MenuTop/index";
import "./Dengon.css";
import Validate from "./UI/validate";
import DengonForm from "./dengonForm";
import DengonList from "./dengonList";

import MenuTopLife from "../../life/components/MenuTop/index";

// const Option = Select.Option;

class Dengon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      shown: false,
      indeterminate: true,
      checkAll: false,
      tid: getValueLocalstorage("tid"),
      socket: this.props.socket,
      userSelect: false,
      messageContent: false,
      pageNo: 0,
    };
  }

  /**
   *
   */
  componentDidMount() {
    this.props.userList(); // dispatch get user list
    this.props.sendGroup(); // dispatch get group list
    this.props.messageList(this.state.tid, this.state.pageNo); // dispatch get message list by tid
  }

  /**
   *
   */
  getGroupFilter = (value) => {
    this.props.groupFilter(value);
  };

  /**
   *
   * @param {*} text
   */
  getSearch = (text) => {
    this.props.search(text);
  };

  /**
   *
   * @param {*} text
   * @param {*} sentToIds
   */
  getSendMessage = (text, sentToIds) => {
    const { users } = this.props;

    const tids = [];
    Object.keys(users).forEach(function (user) {
      sentToIds.forEach(function (id) {
        if (users[user].id === id) {
          tids.push(users[user].tidCopy);
        }
      });
    });

    // validate
    if (tids.length > 0 && text !== null && text !== "") {
      this.setState({
        userSelect: false,
        messageContent: false,
      });

      const message = { ids: tids, message: text };
      const data = { type: 1, data: message };
      this.props.addnew(JSON.stringify(data), this.state.tid);
    }
    else {
      // Validate
      if (tids.length === 0) {
        this.setState({
          userSelect: true,
          messageContent: false,
        });
      }

      if (text === null || text === "") {
        this.setState({
          userSelect: false,
          messageContent: true,
        });
      }

      if (tids.length === 0 && text === "") {
        this.setState({
          userSelect: true,
          messageContent: true,
        });
      }
    }
  };

  /**
   *
   * @param {*} checkedList
   */
  getSendToIds = (checkedList) => {
    this.props.sendToIds(checkedList);
  };

  /**
   *
   * @param {*} tid
   * @param {*} pageNo
   */
  getLoadMore = (tid, pageNo) => {
    this.props.loadMore(tid, pageNo);
  };

  /**
   *
   */
  getRely = (tantoId, readf, id) => {
    // If not read yet, update
    if (readf === 0) {
      this.props.mitayo(id.toString());
    }

    const listItem = [];
    const { users } = this.props;

    Object.keys(users).forEach(function (user) {
      if (users[user].tidCopy === tantoId) {
        listItem.push(users[user].id);
      }
    });

    this.props.sendToIds(listItem);
  };

  /**
   *
   * @param {*} id
   */
  getMitayo(id) {
    this.props.mitayo(id);
  }

  /**
   *
   */
  render() {
    const { users, groups, groupName, name, messages, sendTo, loading } =
      this.props;

    const currentMenu = checkMenuDis();
    return (
      <CommonPageContainer sessionTimeout={this.props.sessionTimeout} id={"dengon-page"}>
        <div className={"submenu"}>
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <div>
                    {this.props.history.location.pathname.indexOf(
                      "management"
                    ) === 1 ? (
                      <MenuTop
                        isDengon={true}
                        title={"伝言一覧"}
                        iconType="notification"
                        history={this.props.history}
                      />
                    ) : this.props.history.location.pathname.indexOf("life") ===
                      1 ? (
                      <MenuTopLife
                        isDengon={true}
                        title={"伝言一覧"}
                        iconType="notification"
                        history={this.props.history}
                      />
                    ) : (
                      <SubHeaderV2
                        TopLeft={
                          <SubHeaderTitleV2
                            title={"伝言一覧"}
                            iconType="notification"
                          />
                        }
                        TopRight2={
                          currentMenu.indexOf("record") >= 0 ? (
                            <MenuRecord isDengon={true} />
                          ) : (
                            <MenuBusiness isDengon={true} />
                          )
                        }
                      />
                    )}
                  </div>
                );
              }
              /**
               * @TODO design UI for mobile
               *
               * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
               */
              return null;

            }}
          </MediaQuery>
        </div>
        <div className="dengon-container" id="dengon">
          <DengonForm
            users={users}
            groups={groups}
            groupName={groupName}
            name={name}
            sendTo={sendTo}
            groupFilter={(event) => this.getGroupFilter(event)}
            search={(text) => this.getSearch(text)}
            sendMessage={(text, sentToIds) =>
              this.getSendMessage(text, sentToIds)
            }
            sendToIds={(checkedList) => this.getSendToIds(checkedList)}
          />
          <Validate
            userSelect={this.state.userSelect}
            messageContent={this.state.messageContent}
          />
          <DengonList
            messages={messages}
            loading={loading}
            loadMore={(tid, pageNo) => this.getLoadMore(tid, pageNo)}
            rely={(tantoId, readf, id) => this.getRely(tantoId, readf, id)}
            mitayo={(id) => this.getMitayo(id)}
          />
        </div>
      </CommonPageContainer>
    );
  }
}

export default Dengon;
