import { GET_ID_FAMILY_STATUS, UPDATE_ID_FAMILY_STATUS, UPDATE_ID_FAMILY_STATUS_FAIL } from '../../actions/familySuportStatusAction/actionName';
import { notification } from "antd/lib/index";

export default function FamilySuportStatusReducers(state = {
    loading: true
}, action) {
    switch (action.type) {
        case GET_ID_FAMILY_STATUS:
            return {
                ...state,
                data: action.data,
                loading: false
            };
        case UPDATE_ID_FAMILY_STATUS:
            notification["success"]({
                message: '保存しました',
                placement: "topRight",
                duration: 3,
                style: { whiteSpace: "pre-wrap" },
            });
            return {
                ...state,
                data: action.data,
                loading: false,
                notifyContentEdit: { type: "success" }
            };
        case UPDATE_ID_FAMILY_STATUS_FAIL:
            notification["error"]({
                message: action.error.message,
                placement: "topRight",
                duration: 3,
                style: { whiteSpace: "pre-wrap" },
            });
            return {
                state,
                data: action.data,
                loading: false,
                notifyContentEdit: { type: "error" }
            };
        default:
            return state;
    }
}