import React from "react";
import { Link } from "react-router-dom";

import {
  FileTextOutlined,
  MessageOutlined,
  NotificationOutlined,
  ScheduleOutlined,
  UserOutlined,
} from '@ant-design/icons';

const MenuBusiness = (props) => {
  const menuInfo = JSON.parse(localStorage.getItem("menu"));
  return (
    <ul className="sub-menu">
      {menuInfo &&
      menuInfo.usageAuth &&
      menuInfo.usageAuth.includes("sisetureport") ? (
        <Link
          to={"/document-list"}
          onClick={
            props.goToPage
              ? (e) => {
                  e.preventDefault();
                  props.goToPage("/document-list");
                }
              : null
          }
        >
          <li className={props.isDocument ? "active" : null}>
            <span>
              <FileTextOutlined />
            </span>
            <span>書類</span>
          </li>
        </Link>
      ) : null}

      {menuInfo &&
      menuInfo.usageAuth &&
      menuInfo.usageAuth.includes("nyutaisho") ? (
        <Link
          to={"/in-out-status"}
          onClick={
            props.goToPage
              ? (e) => {
                  e.preventDefault();
                  props.goToPage("/in-out-status");
                }
              : null
          }
        >
          <li className={props.isStatus ? "active" : null}>
            <span>
              <ScheduleOutlined />
            </span>
            <span>入退所</span>
          </li>
        </Link>
      ) : null}

      {/* <Link to={"/duty-report"}>
        <li className={props.isDiary ? "active" : null}>
          <span><Icon type="schedule" theme="outlined" /></span>
          <span>業務</span>
        </li>
      </Link> */}

      <Link
        to={"/riyousya"}
        onClick={
          props.goToPage
            ? (e) => {
                e.preventDefault();
                props.goToPage("/riyousya");
              }
            : null
        }
      >
        <li className={props.isUser ? "active" : null}>
          <span>
            <UserOutlined />
          </span>
          <span>一覧</span>
        </li>
      </Link>

      <Link
        to={"/progress"}
        onClick={
          props.goToPage
            ? (e) => {
                e.preventDefault();
                props.goToPage("/progress");
              }
            : null
        }
      >
        <li className={props.isProgress ? "active" : null}>
          <span>
            <MessageOutlined twoToneColor="red" />
          </span>
          <span>申送</span>
        </li>
      </Link>

      <Link
        to={"/dengon"}
        onClick={
          props.goToPage
            ? (e) => {
                e.preventDefault();
                props.goToPage("/dengon");
              }
            : null
        }
      >
        <li className={props.isDengon ? "active" : null}>
          <span>
            <NotificationOutlined />
          </span>
          <span>伝言</span>
        </li>
      </Link>
    </ul>
  );
};

export default MenuBusiness;
