import React from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Row, Col } from "antd";

// import { convertMessageByCode } from '../../../common/function_common/functionCommon'

import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import InputFukuyakuKB from "../../../common/model-kiroku/InputFukuyakuKB";
import TextAreaSelectKusuri from "../../../common/model-kiroku/TextAreaSelectKusuri";

class NormalFormFukuyaku extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif ? true : false,
      type: null,
      extendValue: false,
    };

    this.onChangeYakumei = this.onChangeYakumei.bind(this);

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    this.getHandleDrugSearch = this.getHandleDrugSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.props.form.resetFields();
    }
  }

  onChangeYakumei(value) {
    this.props.handleFormInput(value, "fukuyaku", "yakumei");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "fukuyaku", "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "fukuyaku", "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "fukuyaku", "tantoId");
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index, 6);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index, 6);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou, 6);
  }

  /**
   * Drug search
   */
  getHandleDrugSearch(bunrui, name) {
    this.props.handleDrugSearch(bunrui, name);
  }

  render() {
    // const { getFieldDecorator } = this.props.form;
    const {
      tantoMaster,
      data,
      yougoMasterNa,
      dataBunrui,
      drugList,
      drugLoading,
      disabled,
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 21 },
      },
    };

    return (
      <Form
        layout="vertical"
        {...formItemLayout}
        // onSubmit={this.handleSubmit}
      >
        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>担当者 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>薬名 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              {/* <InputFukuyakuKB
                                value={data.yakumei}
                                dataBunrui={dataBunrui}
                                drugList={drugList}
                                drugLoading={drugLoading}
                                handleInputSelect={this.onChangeYakumei}
                                handleDrugSearch={this.getHandleDrugSearch}

                                disabled={disabled}
                            /> */}
              <TextAreaSelectKusuri
                value={data.yakumei}
                dataBunrui={dataBunrui}
                drugList={drugList}
                drugLoading={drugLoading}
                handleInputSelect={this.onChangeYakumei}
                handleDrugSearch={this.getHandleDrugSearch}
                // style={{ width: 550 }}

                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>コメント :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={2}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const FormFukuyaku = Form.create()(NormalFormFukuyaku);
export default FormFukuyaku;
