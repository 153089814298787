/* eslint-disable */
import { Button, Checkbox, Modal } from "antd";
import React from 'react';
import { InputEventPlanDialog } from "./InputEventPlanDialog";
import { InputUserPlanDialog } from "./InputUserPlanDialog";

export class CommandButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputVisible: false,
            deleteVisible: false,
            checked: false,
            inputField: null
        }
    }

    showModal(inputField) {
        this.setState({
            inputVisible: true,
            inputField: inputField
        });
    };

    closeModal() {
        this.setState({
            inputVisible: false
        });
    };

    handleOk(values) {
        if (this.props.riyousyaId == null) {
            this.props.handleInputPlan(this.props.selectedPlans.map(i => {
                return { date: i.date, eventPlanId: i.eventPlanId, event: values.plan };
            }));
            this.setState({ inputVisible: false });
        } else {
            if (this.props.mobile === "mobile") {
                this.props.handleInputPlan(this.props.selectedPlans.map(i => {
                    return {
                        date: i.date,
                        riyousyaId: this.props.riyousyaId,
                        riyousyaPlanId: i.riyousyaPlanId,
                        yotei: values.yotei,
                        sonota: values.sonota
                    };
                }));
            } else {
                this.props.handleInputPlan(this.props.selectedPlans.map(i => {
                    return {
                        date: i.date,
                        riyousyaId: this.props.riyousyaId,
                        riyousyaPlanId: i.riyousyaPlanId,
                        yotei: this.state.inputField === "yotei" ? values.plan : i.yotei,
                        sonota: this.state.inputField === "sonota" ? values.plan : i.sonota
                    };
                }));
            }
            this.setState({ inputVisible: false });
        }
    };

    showDeleteConfirm() {
        this.setState({
            deleteVisible: true
        });
    };

    closeDeleteConfirm() {
        this.setState({
            deleteVisible: false,
            checked: false
        });
    };

    handleConfirmDelete() {
        if (this.state.checked) {
            if (this.props.riyousyaId == null) {
                this.props.handleDeletePlan(this.props.selectedPlans.map(i => i.eventPlanId));
            } else {
                this.props.handleDeletePlan(this.props.selectedPlans.map(i => i.riyousyaPlanId));
            }
        }
        this.setState({
            deleteVisible: false,
            checked: false
        });
    }

    handleChangeCheckbox() {
        this.setState({
            checked: !this.state.checked
        });
    };

    renderInputDialog() {
        if (this.props.riyousyaId == null) {
            return <InputEventPlanDialog
                visible={this.state.inputVisible}
                onCancel={() => this.closeModal()}
                onSubmit={(values) => this.handleOk(values)}
                plan={this.props.selectedPlans[0] ? this.props.selectedPlans[0].event : null}
                yougoMasterNaiyou={this.props.yougoMasterNaiyou}
                yougoMasterEditState={index => this.props.yougoMasterEditState(index)}
                yougoMasterEditCancel={index => this.props.yougoMasterEditCancel(index)}
                yougoMasterEditSave={naiyou => this.props.yougoMasterEditSave(naiyou)}
            />;
        } else {
            if (this.props.mobile === "mobile")
                return <InputUserPlanDialog
                    date={this.props.date}
                    visible={this.state.inputVisible}
                    onCancel={() => this.closeModal()}
                    onSubmit={(values) => this.handleOk(values)}
                    yotei={this.props.selectedPlans[0] ? this.props.selectedPlans[0].yotei : null}
                    sonota={this.props.selectedPlans[0] ? this.props.selectedPlans[0].sonota : null}
                    yougoMasterNaiyou={this.props.yougoMasterNaiyou}
                    yougoMasterEditState={index => this.props.yougoMasterEditState(index)}
                    yougoMasterEditCancel={index => this.props.yougoMasterEditCancel(index)}
                    yougoMasterEditSave={naiyou => this.props.yougoMasterEditSave(naiyou)}
                />;
            else
                return <InputEventPlanDialog
                    visible={this.state.inputVisible}
                    onCancel={() => this.closeModal()}
                    onSubmit={(values) => this.handleOk(values)}
                    plan={this.props.selectedPlans[0] ? (this.state.inputField === "yotei" ? this.props.selectedPlans[0].yotei : this.props.selectedPlans[0].sonota) : null}
                    yougoMasterNaiyou={this.props.yougoMasterNaiyou}
                    yougoMasterEditState={index => this.props.yougoMasterEditState(index)}
                    yougoMasterEditCancel={index => this.props.yougoMasterEditCancel(index)}
                    yougoMasterEditSave={naiyou => this.props.yougoMasterEditSave(naiyou)}
                />;
        }
    }

    render() {
        return (
            (<div style={this.props.mobile === "mobile" ? { width: "fit-content", margin: "0 auto" } : { display: "inline" }}>
                {this.props.riyousyaId == null ?
                    <Button
                        disabled={this.props.selectedPlans === null || this.props.selectedPlans.length === 0}
                        style={{ marginLeft: '20px' }}
                        type={"primary"}
                        onClick={() => this.showModal("plan")}
                    >{"予定入力"}</Button>
                    : this.props.mobile === "mobile" ?
                        <Button
                            disabled={this.props.selectedPlans === null || this.props.selectedPlans.length === 0}
                            type={"primary"}
                            onClick={() => this.showModal("both")}
                        >{"予定入力"}</Button>
                        : <div style={{ display: 'inline' }}>
                            <Button
                                disabled={this.props.selectedPlans === null || this.props.selectedPlans.length === 0}
                                style={{ marginLeft: '20px' }}
                                type={"primary"}
                                onClick={() => this.showModal("yotei")}
                            >{"予定入力1"}</Button>
                            <Button
                                disabled={this.props.selectedPlans === null || this.props.selectedPlans.length === 0}
                                style={{ marginLeft: "2px" }}
                                type={"primary"}
                                onClick={() => this.showModal("sonota")}
                            >{"予定入力2"}</Button>
                        </div>
                }
                <Button
                    style={{ marginLeft: this.props.mobile === "mobile" ? '50px' : "2px" }}
                    disabled={this.props.selectedPlans === null || (this.props.selectedPlans.filter(i => i.eventPlanId != null).length === 0 && this.props.selectedPlans.filter(i => i.riyousyaPlanId != null).length === 0)}
                    onClick={() => this.showDeleteConfirm()}
                    type={"primary"}
                >{"予定消去"}</Button>
                {this.renderInputDialog()}
                <Modal
                    open={this.state.deleteVisible}
                    title="削除確認（同意するにチェックしてOKボタンを押してください）"
                    onCancel={() => this.closeDeleteConfirm()}
                    footer={[
                        <Button disabled={!this.state.checked} key="delete" type={"primary"}
                            onClick={() => this.handleConfirmDelete()}>
                            OK
                        </Button>,
                        <Button key="canceldelete" onClick={() => this.closeDeleteConfirm()}>
                            Cancel
                        </Button>
                    ]}
                >
                    <div style={{ backgroundColor: "aliceblue" }}>
                        <span style={{ display: "block" }}>{"予定を削除しますか？"}</span>
                        <Checkbox
                            checked={this.state.checked}
                            onChange={() => this.handleChangeCheckbox()}
                            value={this.state.checked}
                        >{"削除に同意する"}</Checkbox>
                    </div>
                </Modal>
            </div>)
        );
    }
}