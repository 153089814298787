/* eslint-disable object-curly-spacing */
/* eslint-disable no-unused-vars */
import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import {
  formatStorage,
  getMenu,
  isLife,
  isManagement,
  localStorageClearCus,
  setValueIsKeep,
} from "../common/function_common/functionCommon";
import { ApiPaths } from "../life/constants";

const FileDelete = {
  async delete(fileManagementId) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_DELETE, {
        method: "DELETE",
        data: {
          fileManagementId: "" + fileManagementId,
        },
      });
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const FileDetail = {
  async get(fileManagementId) {
    try {
      const res = await axios(
        apiUrls.INIT + apiUrls.REPORT_FILE_BY_ID + `${fileManagementId}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 * Gazouの更新
 */
const FileUpdate = {
  async update(params) {
    // defind update from riyousya page
    const sid = isManagement() || isLife() ? formatStorage(getMenu()).si : "";
    params.sid = sid;
    if (!params.kirokuType) {
      params.riyouType = true;
    }

    // update kubun to localstorage
    setValueIsKeep(
      "fileRiyouDisChild",
      params.kubun ? params.kubun : "noinput"
    );

    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_UPDATE, {
        method: "PUT",
        data: params,
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const FileInsert = {
  async insert(params) {
    const sid = isManagement() || isLife() ? formatStorage(getMenu()).si : "";
    const formData = new FormData();

    // update kubun to localstorage
    setValueIsKeep(
      "fileRiyouDisChild",
      params.kubun ? params.kubun : "noinput"
    );

    formData.append("systemControlName", params.systemControlName);
    formData.append("systemControlId", params.systemControlId);
    formData.append("riyousyaId", params.riyousyaId);
    formData.append("tantoId", params.tantoId);
    formData.append("date", params.date);
    formData.append("time", params.time);
    // formData.append('type', '.jpg')
    // formData.append('fileName', 'fileName')
    formData.append("thumbnail", params.thumbnail ? params.thumbnail : null);
    formData.append("priorityf", params.priorityf ? params.priorityf : "0");
    formData.append("kubun", params.kubun ? params.kubun : "");
    formData.append("comment", params.comment ? params.comment : "");
    formData.append(
      "reportCaseTyuif",
      params.reportCaseTyuif ? params.reportCaseTyuif : "0"
    );
    formData.append("file", params.file);
    formData.append("riyouType", true);
    formData.append("sid", sid);

    try {
      const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_INSERT, {
        method: "POST",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

const RiyouUpdate = {
  async update(params) {
    const mainSID = formatStorage(getMenu()).si;
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_UPDATE_SERVER +
        `?mainSID=${mainSID}&systemName=${systemName}`,
        {
          method: "PUT",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const RiyouUpdateOpen = {
  async update(params) {
    const mainSID = formatStorage(getMenu()).si;
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_UPDATEOPEN_SERVER +
        `?mainSID=${mainSID}&systemName=${systemName}`,
        {
          method: "PUT",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const ListApi = {
  async list(
    pageNo,
    pageSize,
    mainSID,
    isRiyouf,
    isNyuusho,
    furigana,
    systemName,
    id
  ) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.GET_RIYOUSYA_LIST +
        `?pageNo=${pageNo}&pageSize=${pageSize}&mainSID=${mainSID}&isRiyouf=${isRiyouf}&isNyuusho=${isNyuusho}&furigana=${furigana}&systemName=${systemName}&id=${id}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },

  async add(params) {
    const systemName = formatStorage(getMenu()).sn;
    const mainSid = formatStorage(getMenu()).si;
    const checkSisetu = !(isManagement() || isLife());
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOUSYA_SAVE_ADD +
        `?systemName=${systemName}&mainSid=${mainSid}&checkSisetu=${checkSisetu}`,
        {
          method: "POST",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        console.log("network error");
      }
      else {
        // Check Token Exist
        if (err.response.status === 403) {
          localStorageClearCus();

          window.location.replace("/login");
        }

        return err;
      }
    }
  },
};

const DetailApi = {
  async detail(riyousyaId) {
    const systemName = formatStorage(getMenu()).sn;
    const mainSid = formatStorage(getMenu()).si;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.GET_RIYOUSYA_DETAIL +
        `/${riyousyaId}?systemName=${systemName}&mainSid=${mainSid}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const ShiryouApi = {
  async shiryou(pageNo, pageSize, mainSID, riyousyaId, processType, selectKubun) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.GET_RIYOUSYA_SHIRYOU +
        `?pageNo=${pageNo}&pageSize=${pageSize}&mainSID=${mainSID}&riyousyaId=${riyousyaId}&processType=${processType}&selectKubun=${selectKubun}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const ListShokujiApi = {
  async list(pageNo, pageSize, mainSID, riyousyaId) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_MEAL_ABOVE_LIST +
        `?pageNo=${pageNo}&pageSize=${pageSize}&mainSID=${mainSID}&riyousyaId=${riyousyaId}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const ListSKRApi = {
  async list(pageNo, pageSize, mainSID, riyousyaId) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_MEAL_BELOW_LIST +
        `?pageNo=${pageNo}&pageSize=${pageSize}&mainSID=${mainSID}&riyousyaId=${riyousyaId}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

// ページ保険情報読み込み
const ListHokenApi = {
  async list(pageNo, pageSize, mainSID, riyousyaId) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_HOKEN_ABOVE_LIST +
        `?pageNo=${pageNo}&pageSize=${pageSize}&mainSID=${mainSID}&riyousyaId=${riyousyaId}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
  async save(params) {
    const mainSID = formatStorage(getMenu()).si;
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_HOKEN_ABOVE_SAVE +
        `?mainSID=${mainSID}&systemName=${systemName}`,
        {
          method: "POST",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },

  async del(id) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.RIYOU_HOKEN_ABOVE_DEL + `?id=${id}`, {
        method: "DELETE",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },

  async getHokenName(hokensyaNo) {
    try {
      const res = await axios(
        apiUrls.INIT + apiUrls.GET_HOKEN_NAME + `?hokenNo=${hokensyaNo}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const ListKohiApi = {
  async list(pageNo, pageSize, mainSID, riyousyaId) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_HOKEN_BELOW_LIST +
        `?pageNo=${pageNo}&pageSize=${pageSize}&mainSID=${mainSID}&riyousyaId=${riyousyaId}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },

  async save(params) {
    const mainSID = formatStorage(getMenu()).si;
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_HOKEN_BELOW_SAVE +
        `?mainSID=${mainSID}&systemName=${systemName}`,
        {
          method: "POST",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },

  async del(id) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.RIYOU_HOKEN_BELOW_DEL + `?id=${id}`, {
        method: "DELETE",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const MealApi = {
  save(params) {
    //
    const listUpdate = [];
    if (params && params.delSyokujisenIds) {
      listUpdate.unshift(delSyokujisen(params.delSyokujisenIds));
    }

    if (params && params.delShokujiIds) {
      listUpdate.unshift(delShokuji(params.delShokujiIds));
    }

    listUpdate.unshift(updateSyokujisen(params));

    return axios
      .all(listUpdate)
      .then(
        axios.spread(function (list) {
          return { mealAbove: list.syokujisen, mealBelow: list.shokuji };
        })
      )
      .catch((err) => {
        localStorageClearCus(err);
      });
  },
};

/**
 * single del syokujisen
 *
 */
async function delSyokujisen(ids) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.RIYOU_SYOKUJISEN_DEL + `?ids=${ids}`, {
      method: "DELETE",
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      return;
    }

    // Check Token Exist
    if (err.response.status === 403) {
      localStorageClearCus();

      window.location.replace("/login");
    }

    return err;

  }
}

/**
 * single update syokujisen
 *
 */
async function updateSyokujisen(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.RIYOU_SYOKUJISEN_INPUT, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      return;
    }

    // Check Token Exist
    if (err.response.status === 403) {
      localStorageClearCus();

      window.location.replace("/login");
    }

    return err;

  }
}

/**
 * single del shokuji jokyou rireki
 *
 */
async function delShokuji(ids) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.RIYOU_SHOKUJI_DEL + `?ids=${ids}`, {
      method: "DELETE",
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      return;
    }

    // Check Token Exist
    if (err.response.status === 403) {
      localStorageClearCus();

      window.location.replace("/login");
    }

    return err;

  }
}

/**
 * single update shokuji jokyou rireki
 *
 */
async function updateShokuji(params) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.RIYOU_SHOKUJI_INPUT, {
      method: "POST",
      data: params,
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      return;
    }

    // Check Token Exist
    if (err.response.status === 403) {
      localStorageClearCus();

      window.location.replace("/login");
    }

    return err;

  }
}

const KaigoHokenMasterApi = {
  async list(hokenNo) {
    try {
      const res = await axios(
        apiUrls.INIT + apiUrls.KAIGOHOKEN_MASTER_LIST + `?hokenNo=${hokenNo}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },

  // 介護保険マスターアップデート（仮）
  async save(params) {
    const mainSID = formatStorage(getMenu()).si;
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_KAIGOHOKEN_UPDATE +
        `?mainSID=${mainSID}&systemName=${systemName}`,
        {
          method: "POST",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const ServiceKikanMasterAPi = {
  async getJigyousyoName(jigyousyoNo, syuruiCd) {
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.GET_JIGYOUSYO_NAME +
        `?jigyousyoNo=${jigyousyoNo}&syuruiCd=${syuruiCd}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const KohiMasterApi = {
  async getKohi(kohiNo) {
    try {
      const res = await axios(apiUrls.INIT + apiUrls.GET_KOHI_MASTER + `?kohiNo=${kohiNo}`, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const KohifutanMasterApi = {
  async list(hutanNo) {
    try {
      const res = await axios(
        apiUrls.INIT + apiUrls.GET_KOHIFUTAN_MASTER + `?hutanNo=${hutanNo}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },

  async getKohifutan(hutanNo) {
    try {
      const res = await axios(
        apiUrls.INIT + apiUrls.GET_KOHIFUTAN_MASTER2 + `?hutanNo=${hutanNo}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const ItakuTantoMasterApi = {
  async list(name, id) {
    try {
      const res = await axios(
        apiUrls.INIT + apiUrls.ITAKUTANTO_MASTER_LIST + `?name=${name}&id=${id}`,
        {
          method: "GET",
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },

  async save(params) {
    const mainSID = formatStorage(getMenu()).si;
    const systemName = formatStorage(getMenu()).sn;
    try {
      const res = await axios(
        apiUrls.INIT +
        apiUrls.RIYOU_KAIGOHOKEN_UPDATE +
        `?mainSID=${mainSID}&systemName=${systemName}`,
        {
          method: "POST",
          data: params,
        }
      );
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

const KaigodoMasterApi = {
  async list() {
    try {
      const res = await axios(apiUrls.INIT + ApiPaths.LifeList.GetKaidodoList, {
        method: "GET",
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        return;
      }

      // Check Token Exist
      if (err.response.status === 403) {
        localStorageClearCus();

        window.location.replace("/login");
      }

      return err;

    }
  },
};

export {
  DetailApi, FileDelete, FileDetail, FileInsert, FileUpdate, ItakuTantoMasterApi, KaigoHokenMasterApi, KaigodoMasterApi, KohiMasterApi,
  KohifutanMasterApi, ListApi, ListHokenApi,
  ListKohiApi, ListSKRApi, ListShokujiApi, MealApi, RiyouUpdate,
  RiyouUpdateOpen, ServiceKikanMasterAPi, ShiryouApi
};

