import { NotificationOutlined } from "@ant-design/icons";
import React from "react";

const TopLeft = (props) => {
  return (
    <div>
      <span style={{ fontSize: "15px", color: "white" }}>
        <NotificationOutlined /> {props.title}
      </span>
      {props.count ? (
        <span
          style={{ fontSize: "15px", color: "white", marginLeft: 20 }}
        >{`${props.countNum}件表示中`}</span>
      ) : null}
      <span style={{ fontSize: "25px", color: "white" }}>{props.content}</span>
    </div>
  );
};

export default TopLeft;
