import { Col, Modal, Row } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { loadMonitoring } from "../../../actions/monitoringActions/actionCreators";
import { MonitoringApi } from "../../../api/monitoring";
import LineBreakText from "../../../common/component/LineBreakText";
import { ConstSet } from "../../../common/configs/constset";

class MonitoringModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      monitoringDateTo: props.monitoringDate
        ? dayjs(props.monitoringDate, ConstSet.DATE_FORMAT).format(
          ConstSet.DATE_FORMAT
        )
        : "",
      selectedCell: "",
      monitorings: [],
    };
    this.selectedCellRef = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      const { page, monitoringDateTo } = this.state;
      const { riyousyaId } = this.props;
      this.props.loadMonitoring(
        { page, riyousyaId, monitoringDateTo },
        () => { }
      );
    }

    const { monitorings } = this.props;
    const { monitorings: monitoringsState } = this.state;

    if (this.props.visible && monitorings && monitorings.length > 0) {
      if (monitorings.length !== monitoringsState.length) {
        this.setState({ monitorings });
        this.addMonitoringEvaluates(monitorings);
      }
      else if (monitorings.length > 0 && monitoringsState.length > 0) {
        if (monitorings[0].id !== monitoringsState[0].id) {
          this.setState({ monitorings });
          this.addMonitoringEvaluates(monitorings);
        }
      }
    }

    if (
      this.props.monitoringDate &&
      this.props.monitoringDate !== prevProps.monitoringDate
    ) {
      this.setState({
        monitoringDateTo: dayjs(
          this.props.monitoringDate,
          ConstSet.DATE_FORMAT
        ).format(ConstSet.DATE_FORMAT),
      });
    }
  }

  addMonitoringEvaluates(monitorings) {
    for (let i = 0; i < monitorings.length; i++) {
      const monitoring = monitorings[i];
      const monitoringId = monitoring.id;

      MonitoringApi.getMonitoringById(monitoringId).then((result) => {
        if (result && Array.isArray(result.monitoringMokuhyous)) {
          monitoring["evaluates"] = result.monitoringMokuhyous.map(
            (item) => item.evaluate
          );
          this.setState({ monitorings });
        }
      });
    }
  }

  handleOk() {
    if (this.state.selectedCell) {
      this.props.onSelectItem(this.state.selectedCell);
      this.setState({ selectedCell: "" });

      if (this.selectedCellRef) {
        this.selectedCellRef.classList.remove(
          "tanto-kaigi-modal__td--selected"
        );
        this.selectedCellRef = null;
      }
    }
    this.props.onHide();
  }

  handleCancel() {
    this.props.onHide();
  }

  handleSelectCell(name, record, rowIndex, e) {
    if (this.selectedCellRef) {
      this.selectedCellRef.classList.remove("tanto-kaigi-modal__td--selected");
    }
    this.selectedCellRef = e.target;
    this.selectedCellRef.classList.add("tanto-kaigi-modal__td--selected");

    this.setState({
      selectedCell: name ? record[name] : record,
    });
  }

  handlePageChange(page) {
    this.setState({ page });
    const { monitoringDateTo } = this.state;
    const { riyousyaId } = this.props;
    this.props.loadMonitoring(
      { page, riyousyaId, monitoringDateTo },
      () => { }
    );
  }

  render() {
    const { monitorings } = this.state;

    return (
      (<Modal
        open={this.props.visible}
        title="モニタリング"
        width={800}
        style={{ maxWidth: "calc(100% - 30px)" }}
        onOk={() => this.handleOk()}
        onCancel={() => this.handleCancel()}
        className="tanto-kaigi-modal"
      >
        <Row>
          <Col span={24}>
            <div className="pljp-table pljp-table-small pljp-table-scroll-position-left">
              <div className="pljp-table-content">
                <div className="pljp-table-body">
                  <table style={{ width: "100%" }}>
                    <colgroup>
                      <col style={{ width: "50%" }} />
                      <col style={{ width: "50%" }} />
                    </colgroup>
                    <thead className="pljp-table-thead">
                      <tr>
                        <th>
                          <span className="pljp-table-header-column">
                            <div>
                              <span className="pljp-table-column-title">
                                総評
                              </span>
                            </div>
                          </span>
                        </th>
                        <th>
                          <span className="pljp-table-header-column">
                            <div>
                              <span className="pljp-table-column-title">
                                評価、対応、課題等
                              </span>
                            </div>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    {Array.isArray(monitorings) &&
                      monitorings.map((monitoring, parentIndex) => (
                        <tbody key={parentIndex} className="pljp-table-tbody">
                          {monitoring.evaluates &&
                            monitoring.evaluates.length > 0
                            ? (
                              monitoring.evaluates.map((evaluate, index) =>
                                index == 0 ? (
                                  <tr key={index}>
                                    <td
                                      className="tanto-kaigi-modal__comment"
                                      rowSpan={
                                        monitoring.evaluates &&
                                        monitoring.evaluates.length
                                      }
                                    >
                                      <div
                                        title={monitoring.comment}
                                        className="tanto-kaigi-modal__td"
                                        onClick={(e) =>
                                          this.handleSelectCell(
                                            "comment",
                                            monitoring,
                                            null,
                                            e
                                          )
                                        }
                                      >
                                        {
                                          <LineBreakText
                                            text={monitoring.comment}
                                          />
                                        }
                                      </div>
                                    </td>
                                    <td style={{ display: "block" }}>
                                      <div
                                        title={evaluate}
                                        className="tanto-kaigi-modal__td tanto-kaigi-modal__evaluate"
                                        onClick={(e) =>
                                          this.handleSelectCell(
                                            null,
                                            evaluate,
                                            null,
                                            e
                                          )
                                        }
                                      >
                                        {<LineBreakText text={evaluate} />}
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        title={evaluate}
                                        className="tanto-kaigi-modal__td tanto-kaigi-modal__evaluate"
                                        onClick={(e) =>
                                          this.handleSelectCell(
                                            null,
                                            evaluate,
                                            null,
                                            e
                                          )
                                        }
                                      >
                                        {<LineBreakText text={evaluate} />}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td
                                  className="tanto-kaigi-modal__comment"
                                  rowSpan={
                                    monitoring.evaluates &&
                                    monitoring.evaluates.length
                                  }
                                >
                                  <div
                                    title={monitoring.comment}
                                    className="tanto-kaigi-modal__td"
                                    onClick={(e) =>
                                      this.handleSelectCell(
                                        "comment",
                                        monitoring,
                                        null,
                                        e
                                      )
                                    }
                                  >
                                    {<LineBreakText text={monitoring.comment} />}
                                  </div>
                                </td>
                                <td style={{ display: "block" }}>
                                  <div
                                    className="tanto-kaigi-modal__td tanto-kaigi-modal__evaluate"
                                    onClick={(e) =>
                                      this.handleSelectCell(null, "", null, e)
                                    }
                                  />
                                </td>
                              </tr>
                            )}
                        </tbody>
                      ))}
                  </table>
                </div>
                {!Array.isArray(monitorings) || monitorings.length === 0 ? (
                  <div className="pljp-table-placeholder">
                    <div className="pljp-empty pljp-empty-normal">
                      <div className="pljp-empty-image">
                        <svg
                          width="64"
                          height="41"
                          viewBox="0 0 64 41"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            transform="translate(0 1)"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <ellipse
                              fill="#F5F5F5"
                              cx="32"
                              cy="33"
                              rx="32"
                              ry="7"
                            ></ellipse>
                            <g fillRule="nonzero" stroke="#D9D9D9">
                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                              <path
                                d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                fill="#FAFAFA"
                              ></path>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <p className="pljp-empty-description">
                        データがありません
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </Modal>)
    );
  }
}

const mapStateToProps = () => (state) => ({
  monitorings: state.documentList.monitorings,
  metaMonitoring: state.documentList.metaMonitoring,
});

const mapDispatchToProps = () => (dispatch) => ({
  loadMonitoring: ({ page, riyousyaId, monitoringDateTo }, callback) =>
    dispatch(loadMonitoring({ page, riyousyaId, monitoringDateTo }, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringModal);
