import { MonitoringMNGTActionType } from "../action_types";
import { CommonUtils } from "../../utils/index";

function monitoringManagement(state = {}, action) {
  switch (action.type) {
    case MonitoringMNGTActionType.CREATE_BATCH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case MonitoringMNGTActionType.CREATE_BATCH_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "作成されました");
      return {
        ...state,
        createBatch: action.createBatch,
        loading: false,
        isReload: true,
      };
    }
    case MonitoringMNGTActionType.CREATE_BATCH_FAILURE: {
      CommonUtils.openNotificationWithIcon(
        "error",
        "該当の計画書がありませんでした"
      );
      return {
        ...state,
        loading: false,
      };
    }

    case MonitoringMNGTActionType.GET_ALL_REQUEST: {
      return {
        ...state,
        loading: true,
        isReload: false,
      };
    }
    case MonitoringMNGTActionType.GET_ALL_SUCCESS: {
      return {
        ...state,
        list: action.getAll.result,
        pagination: {
          current: action.getAll.page,
          pageSize: action.getAll.pageSize,
          total: action.getAll.totalCount,
        },
        loading: false,
      };
    }
    case MonitoringMNGTActionType.GET_ALL_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case MonitoringMNGTActionType.KEEP_VALUE_SEARCH:
      return {
        ...state,
        keepValue: action.keepValue,
      };

    default: {
      return { ...state };
    }
  }
}

export default monitoringManagement;
