import { Col, Modal, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import LineBreakText from "../../../../common/component/LineBreakText";

class RowContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      confirmModalVisible: false,
      sortNum: null,
    };
  }

  componentDidMount() {
    // this.props.changeEditMobileMode(false)
  }

  showModal = () => {
    this.setState({
      confirmModalVisible: true,
    });
  };

  handleOk = () => {
    if (!this.props.valuesChange) {
      this.props.changeValuePlan2(true);
    }
    this.setState({
      confirmModalVisible: false,
    });
    this.props.deleteRowsKyotakuPlan2(this.state.sortNum);
    this.props.handleExpand(null, null);
  };

  handleCancel = () => {
    this.setState({
      confirmModalVisible: false,
    });
  };

  handleOnclick = (e) => {
    if (e.target.type !== "button") {
      this.props.handleExpand(!this.state.expand, this.props.info);
    }
  };

  handleRemoveRow = (sortNum, e) => {
    e.preventDefault();
    this.setState({
      sortNum,
    });
    this.showModal();
  };

  render() {
    const { info, indexRow, expandedRowKeys } = this.props;
    const { confirmModalVisible } = this.state;
    return (
      (<div
        className={"document-table-record"}
        // className="pointer p-1"
        style={
          expandedRowKeys === info.sortNum ? { backgroundColor: "#dbe6e6" } : {}
        }
        onClick={this.handleOnclick}
      >
        <Row>
          <Col sx={23} ms={23} md={18} className="row-plan-2">
            <span className="text-bold pr-1">
              <strong>{`${indexRow + 1}短期目標: `}</strong>
            </span>
            <LineBreakText text={info.shortMokuhyou} />
          </Col>
          <Col sx={23} ms={23} md={6} className="row-plan-2">
            <span className="text-bold pr-1">
              <strong>{"目標達成度: "}</strong>
            </span>
            {`${info.attainment}`}
          </Col>
        </Row>
        {/* {expandedRowKeys === info.sortNum ?
          <div className="mt-1" >
            <Row type="flex" justify="space-between">
              <Col sm={6} md={6}>
                <Button className="btn-tab-delete white"
                  onClick={(e) => this.handleRemoveRow(info.sortNum, e)}
                >
                  削除
                </Button>
              </Col>
              <Col sm={2} md={4}>
                <MediaQuery maxDeviceWidth={767}>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.props.changeEditMode(true)
                      this.props.changeEditMobileMode(true)
                    }}
                  >
                    修正
                  </Button>
                </MediaQuery>
              </Col>
              <Col sm={6} md={6} className="btn-right flex-end">
                <MediaQuery query="(min-device-width: 768px)">
                  {(matches) => {
                    if (matches) {
                      return (
                        <div>
                          <Button
                            className="btn-tab-delete white mr-1"
                            disabled={info.sortNum === 1}
                            onClick={() => this.props.upRowKyotakuPlan2(info.sortNum)}
                          >
                            上へ<Icon type="caret-up" />
                          </Button>
                          <Button
                            className="btn-tab-delete white mr-1"
                            onClick={() => this.props.downRowKyotakuPlan2(info.sortNum)}
                            disabled={info.sortNum === kyotakuPlan2Rows.length}
                          >
                            下へ<Icon type="caret-down" />
                          </Button>
                        </div>
                      )
                    }
                    return (
                      <div>
                        <span style={{ marginRight: "10px" }}>順序</span>
                        <Button
                          className="btn-tab-delete white mr-1"
                          disabled={info.sortNum === 1}
                          onClick={() => this.props.upRowKyotakuPlan2(info.sortNum)}
                        >
                          <Icon type="caret-up" />
                        </Button>
                        <Button
                          className="btn-tab-delete white mr-1"
                          onClick={() => this.props.downRowKyotakuPlan2(info.sortNum)}
                          disabled={info.sortNum === kyotakuPlan2Rows.length}
                        >
                          <Icon type="caret-down" />
                        </Button>
                      </div>
                    )
                  }}
                </MediaQuery>

              </Col>
            </Row>
          </div> : <div />} */}
        <Modal
          open={confirmModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>この行を削除しますか？</p>
        </Modal>
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    expandedRowKeys: state.monitoring.expandedRowKeys,
    monitoringRows: state.monitoring.monitoringRows,
    valuesChange: state.kyotakuPlan2.valuesChange,
    editDetail: state.kyotakuPlan2.editDetail,
  };
};

const mapDispatchToProps = () => {
  return {
    // deleteRowsKyotakuPlan2: (sortNum) => dispatch(deleteRowsKyotakuPlan2(sortNum)),
    // upRowKyotakuPlan2: (sortNum) => dispatch(upRowKyotakuPlan2(sortNum)),
    // downRowKyotakuPlan2: (sortNum) => dispatch(downRowKyotakuPlan2(sortNum)),
    // changeEditMobileMode: (editDetail) => dispatch(changeEditMobileMode(editDetail)),
    // changeValuePlan2: (type) => dispatch(changeValuePlan2(type)),
    // changeEditMode: (mode) => dispatch(changeEditMode(mode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RowContent);
