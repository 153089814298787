import { ApiPaths } from "../../constants/api_paths";
import {
  RiyousyaYobouActionTypes,
  ErrorActionTypes,
} from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils } from "../../utils/index";

function getById(riyousyaId, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      const query = {
        riyousyaId,
        sid,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.RiyousyaYobou.GetById,
        query,
      });
      return callback(data)
    } catch (error) {
      dispatch({
        type: RiyousyaYobouActionTypes.GET_JIGYOUSYO_ID_YOBOU_FAILURE,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

export const RiyousyaYobouActions = {
  getById,
};
