import {
  FormOutlined,
  MessageOutlined,
  NotificationOutlined,
  ScheduleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { MoshiokuriContext } from "../IdleTimerPage";

class MenuRecord extends React.Component {
  render() {
    const menuInfo = JSON.parse(localStorage.getItem("menu"));
    return (
      <ul className="sub-menu">
        {menuInfo &&
          menuInfo.usageAuth &&
          menuInfo.usageAuth.includes("sisetukiroku") ?
          (
            <Link to={"/report"}>
              <li className={this.props.isDailyRe ? "active" : null}>
                <span>
                  <FormOutlined />
                </span>
                <span>日別</span>
              </li>
            </Link>
          ) : null}
        {menuInfo &&
          menuInfo.usageAuth &&
          menuInfo.usageAuth.includes("sisetuyotei") ?
          (
            <Link to={"/plan"}>
              <li className={this.props.isPlan ? "active" : null}>
                <span>
                  <ScheduleOutlined />
                </span>
                <span>予定</span>
              </li>
            </Link>
          ) : null}

        {menuInfo &&
          menuInfo.usageAuth &&
          menuInfo.usageAuth.includes("nissi") ?
          (
            <Link to={"/duty-report"}>
              <li className={this.props.isDiary ? "active" : null}>
                <span>
                  <ScheduleOutlined />
                </span>
                <span>日誌</span>
              </li>
            </Link>
          ) : null}

        <Link to={"/riyousya"}>
          <li className={this.props.isUser ? "active" : null}>
            <span>
              <UserOutlined />
            </span>
            <span>一覧</span>
          </li>
        </Link>

        <Link to={"/progress"}>
          <li className={this.props.isProgress ? "active" : null}>
            <span>
              <MessageOutlined twoToneColor="red" />
            </span>
            <span>申送</span>
          </li>
        </Link>

        <Link to={"/dengon"}>
          <li className={this.props.isDengon ? "active" : null}>
            <span>
              <NotificationOutlined />
            </span>
            <span>伝言</span>
          </li>
        </Link>
      </ul>
    );
  }
}
MenuRecord.contextType = MoshiokuriContext;
export default MenuRecord;
