import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import React, { Component } from "react";

// import '@ant-design/compatible/assets/index.css';


import { Button, Checkbox, Col, Modal, Row, notification } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import {
  filterRiyousyaData,
  getDataInOut,
  inOutQuote,
  insertDataInOut,
  resetStatus,
  sortReportCaseData,
} from "../../../actions/ioStatusActions/actionCreators";
import { loadFilterMasterSetting } from "../../../actions/planActions/actionCreators";
import AddRiyousyaModal from "../../../common/component/control/AddRiyousyaModal";
import DatePickerControl from "../../../common/component/control/DatePickerControl";
import SearchRiyousyaControl from "../../../common/component/control/SearchRiyousyaControl";
import SortControl from "../../../common/component/control/SortControl";
import { ConstSet } from "../../../common/configs/constset";
import { Filter } from "./Filter";
import SearchRiyousyaModal2 from "./SearchRiyousyaModal2";

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleConfirm: false,
      isChangeForm: false,
      visibleSort: false,
      sortData: this.props.sortObj,
      isVisibleSearchFurigana: false,
      isVisibleAddFurigana: false,
      listName: "",
      openStartDate: false,
      openEndDate: false,
      data: {
        name: null,
        date: null,
        riyousya_id: null,
        service_kikan_master_id: null,
        kubun: "入所",
        bunrui: null,
        nyusyo_date: null,
        nyusyo_riyu: null,
        room: null,
        gei: "0",
        taisho_date: null,
        taisho_riyu: null,
        sou: "0",
        comment: null,
      },
      roomVisible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      selectedDate,
      page,
      filterObj,
      sortObj,
      searchName,
      getDataIoStatus,
    } = this.props;
    const date = selectedDate;
    const time = `${date.get("year")}-${date.get("month") + 1}-${date.get("date")}`;
    if (
      nextProps.notifyContentInsert &&
      nextProps.notifyContentInsert.type === "success"
    ) {
      getDataIoStatus(time, filterObj, sortObj, searchName, page);
      this.setState({
        isVisibleAddFurigana: false,
        isChangeForm: false,
      });
    }
    if (
      nextProps.notifyContentQuote &&
      nextProps.notifyContentQuote.type === "success"
    ) {
      getDataIoStatus(time, filterObj, sortObj, searchName, page);
      this.setState({
        isVisibleAddFurigana: false,
        isChangeForm: false,
      });
    }
  }

  componentDidMount() {
    this.props.getFilterMasterData();
  }

  showSearchRiyousyaModal() {
    if (this.props.riyousyaId) {
      // this.props.handleGoToInputReportCase(this.props.riyousyaId);
    }
    else {
      this.setState({
        isVisibleSearchFurigana: true,
      });
    }
  }

  onOkSearch(riyousya) {
    const date = this.props.selectedDate;
    const time = `${date.get("year")}-${date.get("month") + 1}-${date.get("date")}`;
    if (riyousya) {
      this.setState((prevState) => ({
        isVisibleSearchFurigana: false,
        isVisibleAddFurigana: true,
        data: {
          ...prevState.data,
          name: riyousya.name,
          riyousya_id: `${riyousya.id}`,
          nyusyo_date: time,
          date: null,
          service_kikan_master_id: null,
          kubun: "入所",
          bunrui: riyousya.latestNr,
          nyusyo_riyu: null,
          room: null,
          gei: "0",
          taisho_date: null,
          taisho_riyu: null,
          sou: "0",
          comment: null,
        },
      }));
    }
    this.onStartChange(date);
    this.setState({
      isChangeForm: false,
    });
  }

  onCancelAdd() {
    const { isChangeForm } = this.state;
    if (isChangeForm == true) {
      this.setState({
        visibleConfirm: true,
      });
    }
    else {
      this.setState({
        isVisibleAddFurigana: false,
        openStartDate: false,
        openEndDate: false,
      });
    }
  }

  onCancelSearch() {
    this.setState({
      isVisibleSearchFurigana: false,
    });
  }

  onOkAdd() {
    this.props.inserData();
  }

  onKubunValueChange(event) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        kubun: event.target.value,
      },
      isChangeForm: true,
    }));
  }

  onChange = (field, value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [field]: value,
      },
    }));
  };

  onStartChange = (value) => {
    this.onChange("nyusyo_date", value);
    this.setState({
      isChangeForm: true,
    });
  };

  onEndChange = (value) => {
    this.onChange("taisho_date", value);
    this.setState({
      isChangeForm: true,
    });
  };

  disabledStartDate = (startValue) => {
    const { data } = this.state;
    if (!startValue || !data.taisho_date) {
      return false;
    }
    return startValue.valueOf() > data.taisho_date.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { data } = this.state;
    if (!endValue || !data.nyusyo_date) {
      return false;
    }
    return endValue.valueOf() <= data.nyusyo_date.valueOf();
  };

  clearStartDate() {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        nyusyo_date: null,
      },
      isChangeForm: true,
      openStartDate: false,
    }));
  }

  clearEndDate() {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        taisho_date: null,
      },
      isChangeForm: true,
      openEndDate: false,
    }));
  }

  onRoomChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        room: values.length > 0 ? values : null,
      },
      isChangeForm: true,
    }));
  }

  onNyusyoRiyuChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        nyusyo_riyu: values,
      },
      isChangeForm: true,
    }));
  }

  onTaishoRiyuChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        taisho_riyu: values,
      },
      isChangeForm: true,
    }));
  }

  onBunruiChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        bunrui: values.length > 0 ? values : null,
      },
      isChangeForm: true,
    }));
  }

  onCommentChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        comment: values,
      },
      isChangeForm: true,
    }));
  }

  onGeiChange(event) {
    const checked = event.target.checked ? "1" : "0";
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        gei: checked,
      },
      isChangeForm: true,
    }));
  }

  onSouChange(event) {
    const checked = event.target.checked ? "1" : "0";
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        sou: checked,
      },
      isChangeForm: true,
    }));
  }

  onSubmitInsertIoStatus() {
    const { data } = this.state;
    const nyusyoDate = new Date(data.nyusyo_date);
    const taishoDate = new Date(data.taisho_date);
    if (!data.taisho_date || taishoDate.getTime() >= nyusyoDate.getTime()) {
      this.props.onInsertSubmit(data);
    }
  }
  async onQuoteInOut() {
    const { selectedDate } = this.props;
    await this.props.inOutQuote(selectedDate.format(ConstSet.DATE_FORMAT));
  }

  openNotificationWithIcon(type, notifyContent) {
    notification[type]({
      message: notifyContent,
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  }

  componentDidUpdate() {
    const { notifyContentInsert } = this.props;
    if (notifyContentInsert && notifyContentInsert.type) {
      this.openNotificationWithIcon(
        notifyContentInsert.type,
        notifyContentInsert.message
      );
      this.props.resetStatus();
    }
  }

  getListName(value) {
    this.setState({
      listName: value,
    });
  }

  haneleRoomVisible() {
    this.setState({
      roomVisible: true,
    });
  }
  onRoomCancel() {
    this.setState({
      roomVisible: false,
    });
  }

  roomValueChange = (value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        room: value.length > 0 ? value : null,
      },
      isChangeForm: true,
      roomVisible: false,
    }));
  };

  nyusyoRiyuValueChange = (value) => {
    const { data } = this.state;
    const arr = [];
    if (data.nyusyo_riyu) {
      arr.push(data.nyusyo_riyu);
    }
    arr.push(value);
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        nyusyo_riyu: arr.join("、"),
      },
      isChangeForm: true,
    }));
  };

  taishoRiyuValueChange = (value) => {
    const { data } = this.state;
    const arr = [];
    if (data.taisho_riyu) {
      arr.push(data.taisho_riyu);
    }
    arr.push(value);
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        taisho_riyu: arr.join("、"),
      },
      isChangeForm: true,
    }));
  };

  bunruiValueChange = (value) => {
    const { data } = this.state;
    const arr = [];
    if (data.bunrui) {
      arr.push(data.bunrui);
    }
    arr.push(value);
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        bunrui: value.length > 0 ? value : null,
      },
      isChangeForm: true,
    }));
  };

  commentValueChange = (value) => {
    const { data } = this.state;
    const arr = [];
    if (data.comment) {
      arr.push(data.comment);
    }
    arr.push(value);
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        comment: arr.join("、"),
      },
      isChangeForm: true,
    }));
  };

  onOpenStartDateStatus(status) {
    this.setState({
      openStartDate: status,
    });
  }

  onOpenEndDateStatus(status) {
    this.setState({
      openEndDate: status,
    });
  }
  onHideConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }
  onCancelConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  async onOkConfirm() {
    await this.setState({
      isVisibleAddFurigana: false,
      visibleConfirm: false,
      isChangeForm: false,
    });
  }

  toggleOpenLongText = () => {
    this.props.toggleOpenLongText();
  };

  render() {
    const {
      selectedDate,
      filterMasterData,
      handleChangeMealStatusCheckBox,
      isMealStatus,
      filterObj,
      sortObj,
      inserting,
      isDisLongText,
    } = this.props;
    const sortOptions = [
      { value: "furiganaRiyousya", display: "フリガナ" },
      { value: "status", display: "状況" },
      { value: "room", display: "部屋" },
      { value: "nyusyoDate", display: "入所日" },
      { value: "taishoDate", display: "退所日" },
      { value: "kubun", display: "入所ショート区分" },
      { value: "bunrui1", display: "分類名1" },
      { value: "bunrui2", display: "分類名2" },
      { value: "bunrui3", display: "分類名3" },
      { value: "bunrui4", display: "分類名4" },
      { value: "bunrui5", display: "分類名5" },
    ];
    const {
      isVisibleAddFurigana,
      isVisibleSearchFurigana,
      data,
      listName,
      openStartDate,
      openEndDate,
      visibleConfirm,
      roomVisible,
    } = this.state;
    return (
      (<div>
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <Row
                  type="flex"
                  style={{
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                  className={"io-multi-button"}
                >
                  <DatePickerControl
                    style={{ paddingLeft: "5px" }}
                    value={selectedDate}
                    classDatepicker={"datepicker"}
                    onChange={(value) => this.props.handleSelectDay(value)}
                  />
                  <Button
                    style={{ marginLeft: "5px", display: "inline" }}
                    type="primary"
                    danger
                    size="default"
                    onClick={() => this.showSearchRiyousyaModal()}
                  >
                    追加
                  </Button>
                  <Button
                    onClick={() => this.onQuoteInOut()}
                    type="primary"
                    style={{ marginLeft: "5px", display: "inline" }}
                  >
                    入所引用
                  </Button>
                  <Filter
                    filterDataBackup={filterObj}
                    nyutaishoFilterData={filterMasterData}
                    handleFilterOk={(filter) =>
                      this.props.handleFilterOk(filter)
                    }
                  />
                  <SortControl
                    style={{
                      display: "inline-block",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                    count={5}
                    sortOptions={sortOptions}
                    sortData={sortObj}
                    handleSortData={(filterObj) =>
                      this.props.handleSortOk(filterObj)
                    }
                  />
                  <SearchRiyousyaControl
                    style={{ display: "flex" }}
                    searchName={this.props.searchName}
                    handleSearch={(furigana) =>
                      this.props.onChangeSearchName(furigana)
                    }
                  />
                  <Checkbox
                    style={{
                      padding: "5px",
                      wordBreak: "keep-all",
                      display: "flex",
                      alignItems: "center",
                    }}
                    checked={isMealStatus}
                    onChange={() => handleChangeMealStatusCheckBox()}
                    value={isMealStatus}
                  >
                    {"食事状況表示"}
                  </Checkbox>
                  <Button type="primary" onClick={this.toggleOpenLongText}>
                    {isDisLongText ? (
                      <span>
                        <MinusOutlined /> 格納
                      </span>
                    ) : (
                      <span>
                        <PlusOutlined /> 展開
                      </span>
                    )}
                  </Button>
                </Row>
              );
            }
            {
              return (
                <div>
                  <Row
                    type="flex"
                    style={{
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                    className={"io-multi-button"}
                  >
                    <DatePickerControl
                      style={{ paddingLeft: "5px" }}
                      value={selectedDate}
                      classDatepicker={"datepicker"}
                      onChange={(value) => this.props.handleSelectDay(value)}
                    />
                    <Button
                      style={{ marginLeft: "5px", display: "inline" }}
                      type="primary"
                      danger
                      size="default"
                      onClick={() => this.showSearchRiyousyaModal()}
                    >
                      追加
                    </Button>
                    <Button
                      onClick={() => this.onQuoteInOut()}
                      type="primary"
                      style={{ marginLeft: "5px", display: "inline" }}
                    >
                      入所引用
                    </Button>
                  </Row>
                  <Row>
                    <Col span={18}>
                      <Filter
                        filterDataBackup={filterObj}
                        nyutaishoFilterData={filterMasterData}
                        handleFilterOk={(filter) =>
                          this.props.handleFilterOk(filter)
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <SortControl
                        style={{ padding: "10px" }}
                        count={5}
                        sortOptions={sortOptions}
                        sortData={sortObj}
                        handleSortData={(filterObj) =>
                          this.props.handleSortOk(filterObj)
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginLeft: "10px" }}>
                    <SearchRiyousyaControl
                      style={{ display: "flex" }}
                      searchName={this.props.searchName}
                      handleSearch={(furigana) =>
                        this.props.onChangeSearchName(furigana)
                      }
                    />
                    <Checkbox
                      style={{
                        padding: "5px",
                        wordBreak: "keep-all",
                        display: "flex",
                        alignItems: "center",
                      }}
                      checked={isMealStatus}
                      onChange={() => handleChangeMealStatusCheckBox()}
                      value={isMealStatus}
                    >
                      {"食事状況表示"}
                    </Checkbox>
                  </Row>
                  <Row>
                    <Button type="primary" onClick={this.toggleOpenLongText}>
                      {isDisLongText ? (
                        <span>
                          <MinusOutlined /> 格納
                        </span>
                      ) : (
                        <span>
                          <PlusOutlined /> 展開
                        </span>
                      )}
                    </Button>
                  </Row>
                </div>
              );
            }
          }}
        </MediaQuery>
        <Modal
          title={"キャンセル確認"}
          open={visibleConfirm}
          onCancel={() => this.onHideConfirm()}
          footer={[
            <Button key="cancel" onClick={() => this.onCancelConfirm()}>
              キャンセル
            </Button>,
            <Button key="ok" type="primary" onClick={() => this.onOkConfirm()}>
              Ok
            </Button>,
          ]}
        >
          <p>キャンセルしてもよろしいですか？</p>
        </Modal>
        <SearchRiyousyaModal2
          visible={isVisibleSearchFurigana}
          onOk={(riyousya) => this.onOkSearch(riyousya)}
          onCancel={() => this.onCancelSearch()}
        />
        <AddRiyousyaModal
          inserting={inserting}
          data={data}
          visible={isVisibleAddFurigana}
          listName={listName}
          openStartDate={openStartDate}
          openEndDate={openEndDate}
          onOk={() => this.onOkAdd()}
          onCancel={() => this.onCancelAdd()}
          roomValueChange={(value) => this.roomValueChange(value)}
          nyusyoRiyuValueChange={(value) => this.nyusyoRiyuValueChange(value)}
          taishoRiyuValueChange={(value) => this.taishoRiyuValueChange(value)}
          bunruiValueChange={(value) => this.bunruiValueChange(value)}
          commentValueChange={(value) => this.commentValueChange(value)}
          getListName={(value) => this.getListName(value)}
          onKubunValueChange={(event) => this.onKubunValueChange(event)}
          onGeiChange={(event) => this.onGeiChange(event)}
          onSouChange={(event) => this.onSouChange(event)}
          onRoomChange={(event) => this.onRoomChange(event)}
          onNyusyoRiyuChange={(event) => this.onNyusyoRiyuChange(event)}
          onTaishoRiyuChange={(event) => this.onTaishoRiyuChange(event)}
          onBunruiChange={(event) => this.onBunruiChange(event)}
          onCommentChange={(event) => this.onCommentChange(event)}
          onSubmitInsertIoStatus={() => this.onSubmitInsertIoStatus()}
          disabledStartDate={(value) => this.disabledStartDate(value)}
          onStartChange={(value) => this.onStartChange(value)}
          disabledEndDate={(value) => this.disabledEndDate(value)}
          onEndChange={(value) => this.onEndChange(value)}
          clearStartDate={() => this.clearStartDate()}
          clearEndDate={() => this.clearEndDate()}
          onOpenStartDateStatus={(status) => this.onOpenStartDateStatus(status)}
          onOpenEndDateStatus={(status) => this.onOpenEndDateStatus(status)}
          roomVisible={roomVisible}
          haneleRoomVisible={() => this.haneleRoomVisible()}
          onRoomCancel={() => this.onRoomCancel()}
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filterMasterData: state.plan.filterMaster,
    isMealStatus: state.ioStatusReducers.isMealStatus,
    notifyContentInsert: state.ioStatusReducers.notifyContentInsert,
    notifyContentQuote: state.ioStatusReducers.notifyContentQuote,
    data: state.ioStatusReducers.data,
    inserting: state.ioStatusReducers.inserting,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchDebounce = _.debounce(dispatch, 500);
  const dispatchDebounce2 = _.debounce(dispatch, 250);
  return {
    getFilterMasterData: () => dispatch(loadFilterMasterSetting()),
    filterNyutaishoData: (searchData) =>
      dispatch(filterRiyousyaData(searchData)),
    sortReportCases: (sortData) => dispatch(sortReportCaseData(sortData)),
    onInsertSubmit: (value) => dispatchDebounce(insertDataInOut(value)),
    getDataIoStatus: (value, filterObj, sort, searchName, page) =>
      dispatchDebounce2(getDataInOut(value, filterObj, sort, searchName, page)),
    resetStatus: () => dispatch(resetStatus()),
    inOutQuote: (date) => dispatch(inOutQuote(date)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
