/* eslint-disable no-nested-ternary */
import axios from "axios/index";
import React from "react";
import ReactDOM from "react-dom";
import LifeLayout from "./layouts/life";
import ManagementLayout from "./layouts/management";
import UserLayout from "./layouts/user";
import { CommonUtils as CommonUtilsLife } from "./life/utils";
import { CommonUtils } from "./management/utils";
import { unregister } from "./registerServiceWorker";

axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

const systemTitle = localStorage.getItem("kirokuTitle");
const systemTitleM = localStorage.getItem("MNGTTitle");
const systemTitleL = localStorage.getItem("lifeTitle");

document.title =
  document.URL.indexOf("management") !== -1
    ? (systemTitleM ? "SmileWeb+" + systemTitleM : "SmileWeb+居宅管理")
    : document.URL.indexOf("life") !== -1
      ? (systemTitleL ? "SmileWeb+" + systemTitleL : "SmileWeb+LIFE")
      : (systemTitle ? "SmileWeb+" + systemTitle : "SmileWeb+施設記録");

ReactDOM.render(
  CommonUtils.isAdmin(window.location.pathname) ? (
    <ManagementLayout />
  ) : CommonUtilsLife.isAdmin(window.location.pathname) ? (
    <LifeLayout />
  ) : (
    <UserLayout />
  ),
  document.getElementById("root")
);

// registerServiceWorker();
unregister();
