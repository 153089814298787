/* eslint-disable no-case-declarations */
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import _ from "lodash";
import {
  ADD_NEW_MONITORING_HYOKA,
  CHANGE_EDIT_MODE,
  CHANGE_EXPANDED_ROW_KEY,
  CREATE_MONITORING_ERROR,
  CREATE_MONITORING_SUCCESS,
  GET_RESULT_MONITORING,
  GET_ROWINFO_MONITORING,
  KEEP_VALUE_MONITORING,
  LOAD_MONITORING_BY_ID_ERROR,
  LOAD_MONITORING_BY_ID_SUCCESS,
  LOAD_MONITORING_SPIN,
  QUOTE_PLAN2_TO_ROWS,
  REMOVE_MONITORING_HYOKA,
  RESET_STATUS,
  UPDATE_MONITORING_ERROR,
  UPDATE_MONITORING_HYOKAS,
  UPDATE_MONITORING_SUCCESS,
  UPDATE_OPTION_OF_MONITORING,
  UPDATE_ROWS_MONITORING,
} from "../../actions/monitoringActions/actionName";
import { ConstSet } from "../../common/configs/constset";
import {
  openNotificationWithIcon,
  sortByDate,
} from "../../common/function_common/functionCommon";
dayjs.extend(dayjsPluginUTC);

function sortData(data) {
  return _.sortBy(data, [
    function (o) {
      return o.sortNum;
    },
  ]);
}
function processData(
  data,
  attainment,
  condition,
  satisPerson,
  satisFamily,
  cope
) {
  const newItemdata = { ...data, attainment };
  newItemdata.results = newItemdata.results.map((item) => {
    return { ...item, condition, satisPerson, satisFamily, cope };
  });
  return newItemdata;
}
function getNewMonitoringRows(oldRows, updatedRow) {
  const newRows = [...oldRows];

  if (updatedRow && updatedRow.sortNum) {
    for (let i = 0; i < newRows.length; i++) {
      if (newRows[i].sortNum === updatedRow.sortNum) {
        newRows[i] = { ...updatedRow };
        break;
      }
    }
  }
  return newRows;
}

export default function monitoring(
  state = { valuesChange1: false, editMode: true, status: null },
  action
) {
  switch (action.type) {
    case KEEP_VALUE_MONITORING:
      const monitoringTmp = state.monitoring || {};
      monitoringTmp["monitoringDate"] = action["monitoringDate"];
      monitoringTmp["tantoId"] = action["tantoId"];
      monitoringTmp["comment"] = action["comment"];

      return {
        ...state,
        monitoring: monitoringTmp,
      };
    case RESET_STATUS:
      return {
        ...state,
        status: null,
      };
    case CHANGE_EDIT_MODE:
      return {
        ...state,
        editMode: action.mode,
      };
    case LOAD_MONITORING_SPIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ROWINFO_MONITORING:
      return {
        ...state,
        monitoringRows: getNewMonitoringRows(
          state.monitoringRows,
          action.rowInfo
        ),
        rowInformation: action.rowInfo,
      };
    case CHANGE_EXPANDED_ROW_KEY:
      return {
        ...state,
        expandedRowKeys: action.key,
      };
    case LOAD_MONITORING_BY_ID_SUCCESS:
      return {
        ...state,
        expandedRowKeys: null,
        monitoringResults: [],
        rowInformation: {},
        monitoring: action.monitoring,
        monitoringCarePlanDate: null,
        monitoringRows: action.monitoring
          ? sortData(action.monitoring.monitoringMokuhyous)
          : null,
        monitoringHyokas:
          action.monitoring && Array.isArray(action.monitoring.monitoringHyokas)
            ? sortByDate({
              list: action.monitoring.monitoringHyokas,
              key: "hyokaDate",
            })
            : null,
        loading: false,
      };
    case LOAD_MONITORING_BY_ID_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        monitoring: null,
      };
    case UPDATE_MONITORING_SUCCESS:
      openNotificationWithIcon("success", "保存しました", action.callback);
      return {
        ...state,
        monitoring: action.updatedMonitoring,
        monitoringHyokas:
          action.updatedMonitoring &&
            Array.isArray(action.updatedMonitoring.monitoringHyokas)
            ? sortByDate({
              list: action.updatedMonitoring.monitoringHyokas,
              key: "hyokaDate",
            })
            : null,
        valuesChange1: false,
        loading: false,
        expandedRowKeys: null,
      };
    case UPDATE_MONITORING_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
      };
    case CREATE_MONITORING_SUCCESS:
      openNotificationWithIcon("success", "保存しました", action.callback);
      localStorage.setItem(
        "RowKey4",
        action.createdMonitoring.id +
        "_" +
        action.createdMonitoring.riyousyaId +
        "_" +
        action.createdMonitoring.sid
      );
      return {
        ...state,
        monitoring: action.createdMonitoring,
        monitoringHyokas:
          action.createdMonitoring &&
            Array.isArray(action.createdMonitoring.monitoringHyokas)
            ? sortByDate({
              list: action.createdMonitoring.monitoringHyokas,
              key: "hyokaDate",
            })
            : null,
        expandedRowKeys: null,
        loading: false,
        status: "success",
      };
    case CREATE_MONITORING_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        status: "error",
      };
    case GET_RESULT_MONITORING:
      let resultsNew;
      if (action.results && action.results.length === 0) {
        resultsNew = [
          {
            naiyou: "",
            service: "",
            condition: "",
            satisPerson: "",
            satisFamily: "",
            cope: "",
            sortNum: 1,
          },
        ];
      }
      else {
        resultsNew = sortData(action.results);
      }
      return {
        ...state,
        monitoringResults: resultsNew,
      };
    case UPDATE_ROWS_MONITORING:
      // eslint-disable-next-line no-case-declarations
      const monitoringRowsClone = [...state.monitoringRows];
      // eslint-disable-next-line no-case-declarations
      // const kyotakuPlan2RowsFormatedClone = [...state.kyotakuPlan2Rows];
      monitoringRowsClone[action.sortNum - 1] = action.data;
      // kyotakuPlan2RowsFormatedClone[action.sortNum - 1] = action.data;
      return {
        ...state,
        monitoringRows: monitoringRowsClone,
      };
    case UPDATE_MONITORING_HYOKAS:
      return {
        ...state,
        monitoringHyokas: action.data,
      };
    case UPDATE_OPTION_OF_MONITORING:
      // eslint-disable-next-line no-case-declarations
      const monitoringRowsClone2 = [...state.monitoringRows];
      if (state.expandedRowKeys) {
        return {
          ...state,
          // kyotakuPlan2: { ...state.kyotakuPlan2, planDate, renewYears, plan2DouiDate, comment, progressF },
          monitoringRows: monitoringRowsClone2.map((item) =>
            processData(
              item,
              action.attainment,
              action.condition,
              action.satisPerson,
              action.satisFamily,
              action.cope
            )
          ),
          rowInformation: {
            ...state.rowInformation,
            attainment: action.attainment,
          },
          monitoringResults: [...state.monitoringResults].map((item) => {
            return {
              ...item,
              condition: action.condition,
              satisPerson: action.satisPerson,
              satisFamily: action.satisFamily,
              cope: action.cope,
            };
          }),
        };
      }
      return {
        ...state,
        // kyotakuPlan2: { ...state.kyotakuPlan2, planDate: action.planDate },
        monitoringRows: monitoringRowsClone2.map((item) =>
          processData(
            item,
            action.attainment,
            action.condition,
            action.satisPerson,
            action.satisFamily,
            action.cope
          )
        ),
      };
    case QUOTE_PLAN2_TO_ROWS:
      const kyotakuPlan2Rows = action.kyotakuPlan2
        ? [...sortData(action.kyotakuPlan2.seikatuMokuhyous)]
        : null;
      const kyotakuPlan2Date =
        action.kyotakuPlan2 && action.kyotakuPlan2.planDate
          ? action.kyotakuPlan2.planDate
          : null;

      return {
        ...state,
        expandedRowKeys: null,
        monitoringResults: [],
        rowInformation: {},
        monitoring: null,
        monitoringCarePlanDate: kyotakuPlan2Date,
        loading: false,
        monitoringHyokas: [],
        monitoringRows: kyotakuPlan2Rows.map((item, index) => {
          return {
            shortMokuhyou: item.shortMokuhyou,
            sortNum: index + 1,
            attainment: "",
            evaluate: "",
            results: item.details.map((detail, index) => {
              return {
                naiyou: detail.sienNaiyou,
                service: detail.jigyousyo,
                sortNum: index + 1,
                condition: "",
                satisPerson: "",
                satisFamily: "",
                cope: "",
              };
            }),
          };
        }),
      };
    case ADD_NEW_MONITORING_HYOKA:
      const newMoniotingHyoka = {
        hyokaDate: dayjs.utc(new Date(), ConstSet.DATE_FORMAT),
        hyokaTantoId: null,
        hyoka: "",
      };
      return {
        ...state,
        monitoringHyokas: [newMoniotingHyoka, ...state.monitoringHyokas],
      };
    case REMOVE_MONITORING_HYOKA:
      state.monitoringHyokas.splice(action.index, 1);
      return {
        ...state,
        monitoringHyokas: [...state.monitoringHyokas],
      };
    default:
      return state;
  }
}
