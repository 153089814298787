import { iryoKougakuFutanActionTypes } from "./actionName.js";
import { IryoKougakuFutanMasterApi } from "../../api/riyousyaHoken.js";

function getList(kubun) {
  return (dispatch) => {
    dispatch({
      type: iryoKougakuFutanActionTypes.IRYO_KOUGAKU_FUTAN_MASTER_GET_LIST_REQUEST,
    });
    IryoKougakuFutanMasterApi.getList(kubun).then(
      (data) => {
        dispatch({
          type: iryoKougakuFutanActionTypes.IRYO_KOUGAKU_FUTAN_MASTER_GET_LIST_SUCCESS,
          data: data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: iryoKougakuFutanActionTypes.IRYO_KOUGAKU_FUTAN_MASTER_GET_LIST_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export const IryoKougakuFutanMasterActions = {
  getList,
}


// //追加時,初期化
// export function init(callback) {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_INIT_REQUEST,
//       });
//       const data = await RequestUtils.callApi({
//         method: "get",
//         path: ApiPaths.IryouhokenMaster.Init,
//       });
//       dispatch({
//         type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_INIT_SUCCESS,
//         data,
//       })
//       return callback(data)
//     } catch (error) {
//       dispatch({ type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_INIT_ERROR });
//       dispatch({
//         type: ErrorActionTypes.SET_ERROR,
//         error,
//       });
//     }
//   };
// }

// //新規追加
// export function create(body, callback) {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_CREATE_REQUEST,
//       });
//       const data = await RequestUtils.callApi({
//         method: "post",
//         path: ApiPaths.IryouhokenMaster.Create,
//         body: {
//           ...body,
//         },
//       });
//       dispatch({
//         type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_CREATE_SUCCESS,
//         data,
//       })
//       return callback(data)
//     } catch (error) {
//       dispatch({ type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_CREATE_ERROR });
//       dispatch({
//         type: ErrorActionTypes.SET_ERROR,
//         error,
//       });
//     }
//   };
// }

// //修正
// export function update(body, callback) {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATE_REQUEST,
//       });
//       const data = await RequestUtils.callApi({
//         method: "put",
//         path: ApiPaths.IryouhokenMaster.Update,
//         body: {
//           ...body,
//         },
//       });
//       dispatch({
//         type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATE_SUCCESS,
//         data,
//       })
//       return callback(data)
//     } catch (error) {
//       dispatch({ type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATE_ERROR });
//       dispatch({
//         type: ErrorActionTypes.SET_ERROR,
//         error,
//       });
//     }
//   };
// }

// export function loadMaxSort(callback) {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: iryoKougakuFutanActionTypes.LOAD_MAX_SORT_REQUEST,
//       });
//       const data = await RequestUtils.callApi({
//         method: "get",
//         path: ApiPaths.IryouhokenMaster.MaxSort,
//       });
//       dispatch({
//         type: iryoKougakuFutanActionTypes.LOAD_MAX_SORT_SUCCESS,
//       })
//       return callback(data)
//     } catch (error) {
//       dispatch({ type: iryoKougakuFutanActionTypes.LOAD_MAX_SORT_ERROR });
//       dispatch({
//         type: ErrorActionTypes.SET_ERROR,
//         error,
//       });
//     }
//   };
// }

// //並び替え
// export function updateList(body, callback) {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_REQUEST,
//       });
//       const data = await RequestUtils.callApi({
//         method: "put",
//         path: ApiPaths.IryouhokenMaster.UpdateList,
//         query: body,
//       });
//       dispatch({
//         type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_SUCCESS,
//         data,
//       })
//       return callback(data)
//     } catch (error) {
//       dispatch({ type: iryoKougakuFutanActionTypes.IRYOUHOKEN_MASTER_UPDATELIST_ERROR });
//       dispatch({
//         type: ErrorActionTypes.SET_ERROR,
//         error,
//       });
//     }
//   };
// }


