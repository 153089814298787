export const iryouhokenActionTypes = {
  IRYOUHOKEN_MASTER_SPIN_REQUEST: "IRYOUHOKEN_MASTER_SPIN_REQUEST",

  IRYOUHOKEN_MASTER_GET_LIST_REQUEST: "IRYOUHOKEN_MASTER_GET_LIST_REQUEST",
  IRYOUHOKEN_MASTER_GET_LIST_SUCCESS: "IRYOUHOKEN_MASTER_GET_LIST_SUCCESS",
  IRYOUHOKEN_MASTER_GET_LIST_ERROR: "IRYOUHOKEN_MASTER_GET_LIST_ERROR",

  IRYOUHOKEN_MASTER_INIT_REQUEST: "IRYOUHOKEN_MASTER_INIT_REQUEST",
  IRYOUHOKEN_MASTER_INIT_SUCCESS: "IRYOUHOKEN_MASTER_INIT_SUCCESS",
  IRYOUHOKEN_MASTER_INIT_ERROR: "IRYOUHOKEN_MASTER_INIT_ERROR",

  IRYOUHOKEN_MASTER_CREATE_REQUEST: "IRYOUHOKEN_MASTER_CREATE_REQUEST",
  IRYOUHOKEN_MASTER_CREATE_SUCCESS: "IRYOUHOKEN_MASTER_CREATE_SUCCESS",
  IRYOUHOKEN_MASTER_CREATE_ERROR: "IRYOUHOKEN_MASTER_CREATE_ERROR",

  IRYOUHOKEN_MASTER_UPDATE_REQUEST: "IRYOUHOKEN_MASTER_UPDATE_REQUEST",
  IRYOUHOKEN_MASTER_UPDATE_SUCCESS: "IRYOUHOKEN_MASTER_UPDATE_SUCCESS",
  IRYOUHOKEN_MASTER_UPDATE_ERROR: "IRYOUHOKEN_MASTER_UPDATE_ERROR",

  IRYOUHOKEN_MASTER_UPDATELIST_REQUEST: "IRYOUHOKEN_MASTER_UPDATELIST_REQUEST",
  IRYOUHOKEN_MASTER_UPDATELIST_SUCCESS: "IRYOUHOKEN_MASTER_UPDATELIST_SUCCESS",
  IRYOUHOKEN_MASTER_UPDATELIST_ERROR: "IRYOUHOKEN_MASTER_UPDATELIST_ERROR",

  LOAD_MAX_SORT_REQUEST: "LOAD_MAX_SORT_REQUEST",
  LOAD_MAX_SORT_SUCCESS: "LOAD_MAX_SORT_SUCCESS",
  LOAD_MAX_SORT_ERROR: "LOAD_MAX_SORT_ERROR",
}


