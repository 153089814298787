import { Checkbox, Col, Radio, Row, Select } from "antd";
import _ from "lodash";
import React from "react";

const Option = Select.Option;
const RadioGroup = Radio.Group;

export default class SearchConditions extends React.Component {
  constructor(props) {
    super(props);

    const {
      settingNaiyo1,
      yougoMaster11,
      yougoMaster12,
      yougoMaster13,
      yougoMaster14,
      yougoMaster15,
      yougoMaster21,
    } = props;
    let yougoMasterOption = yougoMaster11;
    if (this.props.filterData.search.field10) {
      const valueKey = this.props.filterData.search.field10.split("=")[0];
      if (valueKey === "分類名2") {
        yougoMasterOption = yougoMaster12;
      }
      if (valueKey === "分類名3") {
        yougoMasterOption = yougoMaster13;
      }
      if (valueKey === "分類名4") {
        yougoMasterOption = yougoMaster14;
      }
      if (valueKey === "分類名5") {
        yougoMasterOption = yougoMaster15;
      }
    }

    this.state = {
      kubun: "", // 1
      settingNaiyo1,
      classify1: settingNaiyo1 ? settingNaiyo1[0] : null, // 2
      classify2: "",
      yougoMaster11,
      yougoMaster12,
      yougoMaster13,
      yougoMaster14,
      yougoMaster15,
      yougoMasterOption,
      yougoMaster21,
      floor: yougoMaster21 ? yougoMaster21[0] : null, // 3
    };

    // bind place

    this.handleCheckbox1 = this.handleCheckbox1.bind(this);
    this.handleChange10 = this.handleChange10.bind(this);
    this.handleChange11 = this.handleChange11.bind(this);

    this.handleCheckbox2 = this.handleCheckbox2.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);

    this.handleCheckbox3 = this.handleCheckbox3.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
  }

  componentDidUpdate() {
    const { filterData, settingNaiyo1 } = this.props;
    if (
      settingNaiyo1 &&
      !filterData.search.checkbox1 &&
      this.state.classify1 != settingNaiyo1[0]
    ) {
      this.setState({
        classify1: settingNaiyo1[0],
        classify2: "",
        yougoMasterOption: this.state.yougoMaster11,
      });
    }
  }

  /**
   *
   * @param {*} e
   */
  handleCheckbox1(e) {
    // console.log('radio checked', e.target.checked);
    const { filterData } = this.props;
    // use cloneDeep to avoid modify props directly
    const newData = _.cloneDeep(filterData);
    newData.search.checkbox1 = e.target.checked;

    this.props.handleUpdateState(newData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  /**
   *
   * @param {*} value
   */
  handleChange10(value) {
    // console.log(`selected ${value}`);
    const { filterData } = this.props;
    // use cloneDeep to avoid modify props directly
    const newData = _.cloneDeep(filterData);
    newData.search.checkbox1 = true;
    newData.search.field10 = value;
    newData.search.field11 = null;

    this.props.handleUpdateState(newData);

    /**
     * @TODO Save to localstorate ???
     */

    // handle field2 form value of field1
    const valueKey = value.split("=")[0];

    this.setState({
      classify1: value,
      classify2: "",
    });

    if (valueKey === "分類名1") {
      this.setState({
        yougoMasterOption: this.state.yougoMaster11,
      });
    }

    if (valueKey === "分類名2") {
      this.setState({
        yougoMasterOption: this.state.yougoMaster12,
      });
    }

    if (valueKey === "分類名3") {
      this.setState({
        yougoMasterOption: this.state.yougoMaster13,
      });
    }

    if (valueKey === "分類名4") {
      this.setState({
        yougoMasterOption: this.state.yougoMaster14,
      });
    }

    if (valueKey === "分類名5") {
      this.setState({
        yougoMasterOption: this.state.yougoMaster15,
      });
    }
  }

  handleChange11(value) {
    const { filterData } = this.props;
    // use cloneDeep to avoid modify props directly
    const newData = _.cloneDeep(filterData);
    newData.search.checkbox1 = true;
    newData.search.field11 = value;

    if (!newData.search.field10) {
      newData.search.field10 = this.state.settingNaiyo1[0];
    }
    this.props.handleUpdateState(newData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  /**
   *
   * @param {*} e
   */
  handleCheckbox2(e) {
    const { filterData } = this.props;
    // use cloneDeep to avoid modify props directly
    const newData = _.cloneDeep(filterData);
    newData.search.checkbox2 = e.target.checked;
    newData.search.field2 = "入所";

    this.props.handleUpdateState(newData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  handleChange2 = (e) => {
    // console.log('radio checked', e.target.value);
    const { filterData } = this.props;
    // use cloneDeep to avoid modify props directly
    const newData = _.cloneDeep(filterData);
    newData.search.checkbox2 = true;
    newData.search.field2 = e.target.value;

    this.props.handleUpdateState(newData);

    /**
     * @TODO Save to localstorate ???
     */
  };

  /**
   *
   * @param {*} e
   */
  handleCheckbox3(e) {
    const { filterData } = this.props;
    // use cloneDeep to avoid modify props directly
    const newData = _.cloneDeep(filterData);
    newData.search.checkbox3 = e.target.checked;

    this.props.handleUpdateState(newData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  /**
   *
   * @param {*} value
   */
  handleChange3(value) {
    // console.log(`selected ${value}`);
    const { filterData } = this.props;
    // use cloneDeep to avoid modify props directly
    const newData = _.cloneDeep(filterData);
    newData.search.checkbox3 = true;
    newData.search.field3 = value;

    this.props.handleUpdateState(newData);

    /**
     * @TODO Save to localstorate ???
     */
  }

  render() {
    const { filterData } = this.props;
    return (
      <div className={"kn-search-con-n"}>
        <Row>
          <Col xs={6}>
            <Checkbox
              onChange={this.handleCheckbox1}
              checked={filterData.search.checkbox1}
            >
              検索
            </Checkbox>
          </Col>
          <Col xs={6}>
            <Select
              // showSearch
              style={{ width: "100%" }}
              value={
                filterData.search.field10
                  ? filterData.search.field10
                  : this.state.settingNaiyo1[0]
              }
              optionFilterProp="children"
              onChange={this.handleChange10}
              // onFocus={this.handleFocus}
              // onBlur={this.handleBlur}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.settingNaiyo1
                ? this.state.settingNaiyo1.map((rel, index) => {
                  const tmp = rel.split("=");
                  return (
                    <Option key={index} value={rel}>
                      {tmp[1]}
                    </Option>
                  );
                })
                : null}
            </Select>
          </Col>

          <Col xs={{ span: 11, offset: 1 }}>
            <Select
              // showSearch
              style={{ width: "100%" }}
              optionFilterProp="children"
              value={filterData.search.field11}
              onChange={this.handleChange11}
              // onFocus={this.handleFocus}
              // onBlur={this.handleBlur}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.yougoMasterOption
                ? this.state.yougoMasterOption.map((rel, index) => {
                  return (
                    <Option key={index} value={rel}>
                      {rel}
                    </Option>
                  );
                })
                : null}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Checkbox
              onChange={this.handleCheckbox2}
              checked={filterData.search.checkbox2}
            >
              検索
            </Checkbox>
          </Col>

          <Col xs={18}>
            <RadioGroup
              onChange={this.handleChange2}
              value={filterData.search.field2}
            >
              <Radio value={"入所"}>入所利用者</Radio>
              <Radio value={"ショート"}>ショート利用者</Radio>
            </RadioGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Checkbox
              onChange={this.handleCheckbox3}
              checked={filterData.search.checkbox3}
            >
              検索
            </Checkbox>
          </Col>
          <Col xs={6}>
            <span>部屋分類: </span>
          </Col>

          <Col xs={{ span: 11, offset: 1 }}>
            <Select
              // showSearch
              style={{ width: "100%" }}
              value={filterData.search.field3}
              optionFilterProp="children"
              onChange={this.handleChange3}
              // onFocus={this.handleFocus}
              // onBlur={this.handleBlur}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.yougoMaster21
                ? this.state.yougoMaster21.map((rel, index) => {
                  return (
                    <Option key={index} value={rel}>
                      {rel}
                    </Option>
                  );
                })
                : null}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }
}
