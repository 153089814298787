import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Select } from "antd";
import { getkaigodo } from "../../../actions/riyousyaActions/actionCreators.js";

const Option = Select.Option;

class SelectOptionsFormNew extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.props;
    this.state = {
      value: value ? value : null,
    };

    // bind place
  }

  componentDidMount() {
    this.props.getkaigodo();
  }

  handleSelectChange = (value) => {
    this.props.onChangeCus(value);
    this.setState({
      value: value,
    });

    this.triggerChange(value);
  };

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  render() {
    const { kaigodoMaster, value, style } = this.props;

    return (
      <div>
        <Select
          value={value}
          onChange={this.handleSelectChange}
          dropdownMenuStyle={{
            height: 360,
            overflowY: "visible",
            maxHeight: 800,
          }}
          style={style}
          popupMatchSelectWidth={false}
          popupClassName="hoken-dropdown"
        >
          <Option value="">&nbsp;&nbsp;&nbsp;&nbsp;</Option>
          {kaigodoMaster && kaigodoMaster.length > 0
            ? kaigodoMaster.map(function (item, index) {
                return (
                  <Option key={item.id} value={item.kaigodoNo}>
                    {item.kaigodoName}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>
    );
  }
}

SelectOptionsFormNew.propTypes = {
  // visible: PropTypes.bool,
  // onSelect: PropTypes.func,
  // onHide: PropTypes.func
};

const mapStateToProps = () => (state) => ({
  // loading: state.riyousya.loadingKaigo,
  kaigodoMaster: state.riyousya.kaigodoMaster,
});

const mapDispatchToProps = () => (dispatch) => ({
  getkaigodo: () => dispatch(getkaigodo()),
  // updateDocotrMasterList: (data) => dispatch(updateDocotrMasterList(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectOptionsFormNew);
