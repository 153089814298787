import { CaretRightOutlined, MessageOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Collapse, Divider, Modal, Popconfirm, Spin } from "antd";
import React from "react";

import FormFukuyaku from "./FormFukuyaku";
import FormGazou from "./FormGazou";
import FormHaiben from "./FormHaiben";
import FormHainyou from "./FormHainyou";
import FormKaigo from "./FormKaigo";
import FormMeal from "./FormMeal";
import FormNyuyoku from "./FormNyuyoku";
import FormReportCase from "./FormReportCase";
import FormSuibun from "./FormSuibun";
import FormTokki from "./FormTokki";
import FormVital from "./FormVital";

const { Panel } = Collapse;

const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 2,
  border: 0,
  overflow: "hidden",
};

export default class Content extends React.Component {
  constructor(props) {
    super(props);
    const { openTab } = this.props;
    this.state = {
      indexRow: null,

      isOpenVital: openTab && openTab[0] && openTab[0] === "1" ? "1" : "",
      isOpenMeal: openTab && openTab[1] && openTab[1] === "1" ? "2" : "",
      isOpenSuibun: openTab && openTab[2] && openTab[2] === "1" ? "3" : "",
      isOpenHaisetu: openTab && openTab[3] && openTab[3] === "1" ? "4" : "",
      isOpenNyuyoku: openTab && openTab[4] && openTab[4] === "1" ? "5" : "",
      isOpenFukuyaku: openTab && openTab[5] && openTab[5] === "1" ? "6" : "",
      isOpenKaigo: openTab && openTab[6] && openTab[6] === "1" ? "7" : "",
      isOpenTokki: openTab && openTab[7] && openTab[7] === "1" ? "8" : "",
      isOpenGazou: openTab && openTab[8] && openTab[8] === "1" ? "9" : "",

      naiyou2Type: 0,

      naiyou: "",
      naiyou2: "",
      tyuif: 0,
      nishif: 0,

      visible: false,

      updateState: {
        vital: false,
        meal: false,
        suibun: false,
        hainyou: false,
        haiben: false,
        nyuyoku: false,
        fukuyaku: false,
        kaigo: false,
        tokki: false,
        gazou: false,
      },

      openComent: "",
      openType: null,
    };

    // handle edit comment setting
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    // handle edit ect setting
    this.getYougoMasterEctState = this.getYougoMasterEctState.bind(this);
    this.getYougoMasterEctCancel = this.getYougoMasterEctCancel.bind(this);
    this.getYougoMasterEctSave = this.getYougoMasterEctSave.bind(this);

    this.getHandleDrugSearch = this.getHandleDrugSearch.bind(this);

    this.onChangeNaiyou2 = this.onChangeNaiyou2.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    if (isOpen !== prevProps.isOpen) {
      if (isOpen) {
        this.setState({
          isOpen: true,
          isOpenVital: "1",
          isOpenMeal: "2",
          isOpenSuibun: "3",
          isOpenHaisetu: "4",
          isOpenNyuyoku: "5",
          isOpenFukuyaku: "6",
          isOpenKaigo: "7",
          isOpenTokki: "8",
          isOpenGazou: "9",
        });
      }
      else {
        this.setState({
          isOpen: false,
          isOpenVital: "",
          isOpenMeal: "",
          isOpenSuibun: "",
          isOpenHaisetu: "",
          isOpenNyuyoku: "",
          isOpenFukuyaku: "",
          isOpenKaigo: "",
          isOpenTokki: "",
          isOpenGazou: "",
        });
      }
    }
  }

  handleActions = (record, index) => {
    this.setState({
      indexRow: index,
    });
  };

  onChangePanel = (key) => {
    switch (key) {
      case "1": {
        const isOpenVital = this.state.isOpenVital;
        if (isOpenVital) {
          this.setState({
            isOpenVital: "",
          });
        }
        else {
          this.setState({
            isOpenVital: "1",
          });
        }
        return;
      }
      case "2": {
        const isOpenMeal = this.state.isOpenMeal;
        if (isOpenMeal) {
          this.setState({
            isOpenMeal: "",
          });
        }
        else {
          this.setState({
            isOpenMeal: "2",
          });
        }

        return;
      }
      case "3": {
        const isOpenSuibun = this.state.isOpenSuibun;
        if (isOpenSuibun) {
          this.setState({
            isOpenSuibun: "",
          });
        }
        else {
          this.setState({
            isOpenSuibun: "3",
          });
        }

        return;
      }
      case "4": {
        const isOpenHaisetu = this.state.isOpenHaisetu;
        if (isOpenHaisetu) {
          this.setState({
            isOpenHaisetu: "",
          });
        }
        else {
          this.setState({
            isOpenHaisetu: "4",
          });
        }

        return;
      }
      case "5": {
        const isOpenNyuyoku = this.state.isOpenNyuyoku;
        if (isOpenNyuyoku) {
          this.setState({
            isOpenNyuyoku: "",
          });
        }
        else {
          this.setState({
            isOpenNyuyoku: "5",
          });
        }

        return;
      }
      case "6": {
        const isOpenFukuyaku = this.state.isOpenFukuyaku;
        if (isOpenFukuyaku) {
          this.setState({
            isOpenFukuyaku: "",
          });
        }
        else {
          this.setState({
            isOpenFukuyaku: "6",
          });
        }

        return;
      }
      case "7": {
        const isOpenKaigo = this.state.isOpenKaigo;
        if (isOpenKaigo) {
          this.setState({
            isOpenKaigo: "",
          });
        }
        else {
          this.setState({
            isOpenKaigo: "7",
          });
        }
        return;
      }
      case "8": {
        const isOpenTokki = this.state.isOpenTokki;
        if (isOpenTokki) {
          this.setState({
            isOpenTokki: "",
          });
        }
        else {
          this.setState({
            isOpenTokki: "8",
          });
        }

        return;
      }
      case "9": {
        const isOpenGazou = this.state.isOpenGazou;
        if (isOpenGazou) {
          this.setState({
            isOpenGazou: "",
          });
        }
        else {
          this.setState({
            isOpenGazou: "9",
          });
        }

        return;
      }
      default:
        return;
    }
  };

  handleFormInput = (value, type, param) => {
    this.props.handleFormInput(value, type, param);
  };

  handleFormInputSuibun = (index, value, type, param) => {
    this.props.handleFormInputSuibun(index, value, type, param);
  };

  handleFormInputFukuyaku = (index, value, type, param) => {
    this.props.handleFormInputFukuyaku(index, value, type, param);
  };

  handleFormInputKaigo = (index, value, type, param) => {
    this.props.handleFormInputKaigo(index, value, type, param);
  };

  loadZenkai = () => {
    this.props.loadZenkai();
  };

  /**
   * Handle Add, Edit Setting Comment
   */
  /**
   * move to input state of table
   * @param {*} index
   */
  getYougoMasterEditState(index, type) {
    this.props.yougoMasterEditState(index, type);
  }

  /**
   * Cancel Save
   * @param {*} index
   */
  getYougoMasterEditCancel = (index, type) => {
    this.props.yougoMasterEditCancel(index, type);
  };

  /**
   * Save function
   * @param {*} naiyou
   */
  getYougoMasterEditSave(naiyou, type) {
    this.props.yougoMasterEditSave(naiyou, type);
  }
  // END Handle Add, Edit Setting Comment

  /**
   * Handle Add, Edit Setting Ect
   */
  /**
   * move to input state of table
   * @param {*} index
   */
  getYougoMasterEctState(index) {
    this.props.yougoMasterEctState(index);
  }

  /**
   * Cancel Save
   * @param {*} index
   */
  getYougoMasterEctCancel(index) {
    this.props.yougoMasterEctCancel(index);
  }

  /**
   * Save function
   * @param {*} naiyou
   */
  getYougoMasterEctSave(type, naiyou) {
    this.props.yougoMasterEctSave(type, naiyou);
  }
  // END Handle Add, Edit Setting Comment

  showModalComent = (type, naiyou, naiyou2, tyuif, nishif, openType) => {
    this.setState({
      openComent: naiyou2,
      visible: true,
      naiyou2Type: type,
      naiyou,
      naiyou2,
      tyuif,
      nishif,
      openType,
    });
  };

  handleOkComent = () => {
    this.setState({ visible: false });
    // this.setState({ loading: true });
    // setTimeout(() => {
    //     this.setState({ loading: false, visible: false });
    // }, 3000);
  };

  handleCancelComent = () => {
    this.setState({ visible: false });
  };

  /**
   * Drug search
   */
  getHandleDrugSearch(bunrui, name) {
    this.props.handleDrugSearch(bunrui, name);
  }

  onChangeNaiyou2(value) {
    const { naiyou2Type, openType } = this.state;

    this.setState({
      openComent: value,
    });

    switch (naiyou2Type) {
      case 1:
        this.props.handleFormInput(value, "vital", "reportCaseNaiyou2");
        break;

      case 2:
        this.props.handleFormInput(value, "meal", "reportCaseNaiyou2");
        break;

      case 3:
        this.props.handleFormInput(value, "suibun", "reportCaseNaiyou2");
        break;

      case 4:
        if (openType && openType === 41)
          this.props.handleFormInput(value, "hainyou", "reportCaseNaiyou2");

        if (openType && openType === 42)
          this.props.handleFormInput(value, "haiben", "reportCaseNaiyou2");

        break;

      case 5:
        this.props.handleFormInput(value, "nyuyoku", "reportCaseNaiyou2");
        break;

      case 6:
        this.props.handleFormInput(value, "fukuyaku", "reportCaseNaiyou2");
        break;

      case 7:
        this.props.handleFormInputKaigo(
          openType,
          value,
          "kaigo",
          "reportCaseNaiyou2"
        );
        break;

      case 8:
        this.props.handleFormInput(value, "tokki", "reportCaseNaiyou2");
        break;

      case 9:
        this.props.handleFormInput(value, "gazou", "reportCaseNaiyou2");
        break;

      default:
        break;
    }
  }

  onChangeTyuif = () => {
    const { naiyou2Type, tyuif, openType } = this.state;
    const value = tyuif ? 0 : 1;

    this.setState({
      tyuif: !tyuif,
    });

    switch (naiyou2Type) {
      case 1:
        this.props.handleFormInput(value, "vital", "reportCaseTyuif");
        break;

      case 2:
        this.props.handleFormInput(value, "meal", "reportCaseTyuif");
        break;

      case 3:
        this.props.handleFormInput(value, "suibun", "reportCaseTyuif");
        break;

      case 4:
        if (openType && openType === 41)
          this.props.handleFormInput(value, "hainyou", "reportCaseTyuif");

        if (openType && openType === 42)
          this.props.handleFormInput(value, "haiben", "reportCaseTyuif");

        break;

      case 5:
        this.props.handleFormInput(value, "nyuyoku", "reportCaseTyuif");
        break;

      case 6:
        this.props.handleFormInput(value, "fukuyaku", "reportCaseTyuif");
        break;

      case 7:
        this.props.handleFormInputKaigo(
          openType,
          value,
          "kaigo",
          "reportCaseTyuif"
        );
        break;

      case 8:
        this.props.handleFormInput(value, "tokki", "reportCaseTyuif");
        break;

      case 9:
        this.props.handleFormInput(value, "gazou", "reportCaseTyuif");
        break;

      default:
        break;
    }
  };

  onChangeNishif = () => {
    const { naiyou2Type, nishif, openType } = this.state;
    const value = nishif ? 0 : 1;

    this.setState({
      nishif: !nishif,
    });

    switch (naiyou2Type) {
      case 1:
        this.props.handleFormInput(value, "vital", "reportCaseNishif");
        break;

      case 2:
        this.props.handleFormInput(value, "meal", "reportCaseNishif");
        break;

      case 3:
        this.props.handleFormInput(value, "suibun", "reportCaseNishif");
        break;

      case 4:
        if (openType && openType === 41)
          this.props.handleFormInput(value, "hainyou", "reportCaseNishif");

        if (openType && openType === 42)
          this.props.handleFormInput(value, "haiben", "reportCaseNishif");

        break;

      case 5:
        this.props.handleFormInput(value, "nyuyoku", "reportCaseNishif");
        break;

      case 6:
        this.props.handleFormInput(value, "fukuyaku", "reportCaseNishif");
        break;

      case 7:
        this.props.handleFormInputKaigo(
          openType,
          value,
          "kaigo",
          "reportCaseNishif"
        );
        break;

      case 8:
        this.props.handleFormInput(value, "tokki", "reportCaseNishif");
        break;

      case 9:
        this.props.handleFormInput(value, "gazou", "reportCaseNishif");
        break;

      default:
        break;
    }
  };

  // 1
  onChangeVital = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { vital: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { vital: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  // 2
  onChangeMeal = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { meal: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { meal: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  // 3
  onChangeSuibun = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { suibun: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { suibun: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  // 4
  onChangeHainyou = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { hainyou: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.handleFormInput("排尿", "hainyou", "haisetuKubun");
      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { hainyou: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  // 4'
  onChangeHaiben = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { haiben: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.handleFormInput("排便", "haiben", "haisetuKubun");
      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { haiben: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  // 5
  onChangeNyuyoku = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { nyuyoku: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { nyuyoku: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  // 6
  onChangeFukuyaku = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { fukuyaku: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { fukuyaku: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  // 7
  onChangeKaigo = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { kaigo: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { kaigo: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  // 8
  onChangeTokki = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { tokki: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { tokki: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  // 9
  onChangeGazou = (e) => {
    if (e.target.checked) {
      const tmpValue = Object.assign(this.state.updateState, { gazou: true });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
    else {
      const tmpValue = Object.assign(this.state.updateState, { gazou: false });
      this.setState({
        updateState: tmpValue,
      });

      this.props.saveButUpdate(tmpValue);
    }
  };

  onClickAddSuibun = () => {
    const tmpValue = Object.assign(this.state.updateState, { suibun: true });
    this.setState({
      updateState: tmpValue,
    });

    this.props.saveButUpdate(tmpValue);

    this.props.onClickAddSuibun();
  };

  onClickAddFukuyaku = () => {
    const tmpValue = Object.assign(this.state.updateState, { fukuyaku: true });
    this.setState({
      updateState: tmpValue,
    });

    this.props.saveButUpdate(tmpValue);

    this.props.onClickAddFukuyaku();
  };

  onClickAddKaigo = () => {
    const tmpValue = Object.assign(this.state.updateState, { kaigo: true });
    this.setState({
      updateState: tmpValue,
    });

    this.props.saveButUpdate(tmpValue);

    this.props.onClickAddKaigo();
  };

  confirmDel = (type, recordId, kaigoIndex) => {
    this.props.delRecord(type, recordId, kaigoIndex);
  };

  cancelDel = () => { };

  handleYotei = (item) => {
    this.props.handleYoutei(item);
  };

  syokichiItem = (type, kubun) => {
    this.props.syokichiItem(type, kubun);
  };

  syokichiUpdate = (type, kubun) => {
    this.props.syokichiUpdate(type, kubun);
  };

  render() {
    const {
      isOpenVital,
      isOpenMeal,
      isOpenSuibun,
      isOpenHaisetu,
      isOpenNyuyoku,
      isOpenFukuyaku,
      isOpenKaigo,
      isOpenTokki,
      isOpenGazou,
      updateState,
    } = this.state;

    const {
      tantoMaster,
      vital,
      meal,
      suibun,
      hainyou,
      haiben,
      nyuyoku,
      fukuyaku,
      kaigo,
      tokki,
      gazou,
      zenkai,
      // loadingZenkai,

      //
      settingData,

      //
      yougoMasterEctSelect1,
      yougoMasterEctSelect2,
      yougoMasterEctSelect3,

      yougoMasterVC,
      yougoMasterMC,
      yougoMasterSC,
      yougoMasterHC,
      yougoMasterNC,
      yougoMasterFC,
      yougoMasterKC,
      yougoMasterTC,
      yougoMasterGC,

      yougoMasterSy,
      haisetuType,
      haisetuState,
      nyuyokuType,

      drugList,
      drugLoading,
      yougoDrugBunrui,

      teikyouData,

      gazouKubun,
      file,
      imageUrl,

      riyousyaId,

      loading,

      filePreview,
    } = this.props;

    /**
     * ECT input type 準備
     */
    let ect1Type = null;
    let ect2Type = null;
    let ect3Type = null;

    const vitalEctType =
      settingData && settingData.vitalEctType ? settingData.vitalEctType : "";
    // if (inputMode) {
    if (vitalEctType) {
      const vitalEctTypeConvert = vitalEctType.toString().split(";");
      // ect1 Setting
      if (vitalEctTypeConvert[0]) {
        if (vitalEctTypeConvert[0] === "1") {
          ect1Type = 1;
        }

        if (vitalEctTypeConvert[0] === "4") {
          ect1Type = 4;
        }

        if (vitalEctTypeConvert[0] === "5") {
          ect1Type = 5;
        }
      }

      // ect2 Setting
      if (vitalEctTypeConvert[1]) {
        if (vitalEctTypeConvert[1] === "1") {
          ect2Type = 1;
        }

        if (vitalEctTypeConvert[1] === "4") {
          ect2Type = 4;
        }

        if (vitalEctTypeConvert[1] === "5") {
          ect2Type = 5;
        }
      }

      // ect3 Setting
      if (vitalEctTypeConvert[2]) {
        if (vitalEctTypeConvert[2] === "1") {
          ect3Type = 1;
        }

        if (vitalEctTypeConvert[2] === "4") {
          ect3Type = 4;
        }

        if (vitalEctTypeConvert[2] === "5") {
          ect3Type = 5;
        }
      }
    }

    return (
      (<Spin spinning={!!loading}>
        <div id="rwa-content">
          <Collapse
            accordion
            className="rwa-c-col  margin24"
            expandIconPosition="left"
            bordered={false}
            activeKey={[isOpenVital]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={this.onChangePanel.bind(this, "1")}
          >
            <Panel header="バイタル" key="1" style={customPanelStyle}>
              <Card
                type="inner"
                title={
                  <Checkbox
                    checked={updateState.vital}
                    onChange={this.onChangeVital}
                  >
                    修正
                  </Checkbox>
                }
                extra={
                  <span>
                    <Button
                      type="primary"
                      onClick={this.showModalComent.bind(
                        this,
                        1,
                        vital.reportCaseNaiyou ? vital.reportCaseNaiyou : "",
                        vital.reportCaseNaiyou2 ? vital.reportCaseNaiyou2 : "",
                        vital.reportCaseTyuif ? vital.reportCaseTyuif : "",
                        vital.reportCaseNishif ? vital.reportCaseNishif : ""
                      )}
                      disabled={!this.state.updateState.vital}
                    >
                      <MessageOutlined /> 申送
                    </Button>

                    {updateState.vital ? (
                      <Popconfirm
                        title="削除してもよろしいですか？"
                        onConfirm={this.confirmDel.bind(
                          this,
                          1,
                          vital && vital.vitalId ? vital.vitalId : null
                        )}
                        onCancel={this.cancelDel.bind(this, 1)}
                        okText="OK"
                        cancelText="キャンセル"
                      >
                        <Button style={{ marginLeft: "16px" }} type="primary"
                          danger>
                          削除
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </span>
                }
              >
                <FormVital
                  disabled={!updateState.vital}
                  data={vital}
                  //
                  tantoMaster={tantoMaster}
                  // update input for data saving
                  handleFormInput={this.handleFormInput}
                  // zenkai
                  zenkai={zenkai}
                  // setting data
                  settingData={settingData}
                  // Other Comment Setting
                  yougoMasterNa={yougoMasterVC}
                  vitalEct={
                    settingData && settingData.vitalEct
                      ? settingData.vitalEct
                      : ""
                  }
                  vitalCol={
                    settingData && settingData.vitalCol
                      ? settingData.vitalCol.toString().split(";")
                      : ""
                  }
                  ect1Type={ect1Type}
                  ect2Type={ect2Type}
                  ect3Type={ect3Type}
                  yougoMasterEditState={this.getYougoMasterEditState}
                  yougoMasterEditCancel={this.getYougoMasterEditCancel}
                  yougoMasterEditSave={this.getYougoMasterEditSave}
                  yougoMasterEctState={this.getYougoMasterEctState}
                  yougoMasterEctCancel={this.getYougoMasterEctCancel}
                  yougoMasterEctSave={this.getYougoMasterEctSave}
                  yougoMasterEctSelect1={yougoMasterEctSelect1}
                  yougoMasterEctSelect2={yougoMasterEctSelect2}
                  yougoMasterEctSelect3={yougoMasterEctSelect3}
                />
              </Card>
            </Panel>
          </Collapse>

          <Collapse
            accordion
            className="rwa-c-col"
            expandIconPosition="left"
            bordered={false}
            activeKey={[isOpenMeal]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={this.onChangePanel.bind(this, "2")}
          >
            <Panel header="食事摂取" key="2" style={customPanelStyle}>
              <Card
                type="inner"
                title={
                  <Checkbox
                    checked={updateState.meal}
                    onChange={this.onChangeMeal}
                  >
                    修正
                  </Checkbox>
                }
                extra={
                  <span>
                    <Button
                      type="primary"
                      onClick={this.showModalComent.bind(
                        this,
                        2,
                        meal.reportCaseNaiyou ? meal.reportCaseNaiyou : "",
                        meal.reportCaseNaiyou2 ? meal.reportCaseNaiyou2 : "",
                        meal.reportCaseTyuif ? meal.reportCaseTyuif : "",
                        meal.reportCaseNishif ? meal.reportCaseNishif : ""
                      )}
                      disabled={!this.state.updateState.meal}
                    >
                      <MessageOutlined /> 申送
                    </Button>
                    {updateState.meal ? (
                      <Popconfirm
                        title="削除してもよろしいですか？"
                        onConfirm={this.confirmDel.bind(
                          this,
                          2,
                          meal && meal.mealId ? meal.mealId : null
                        )}
                        onCancel={this.cancelDel.bind(this, 2)}
                        okText="OK"
                        cancelText="キャンセル"
                      >
                        <Button style={{ marginLeft: "16px" }} type="primary"
                          danger>
                          削除
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </span>
                }
              >
                <FormMeal
                  disabled={!updateState.meal}
                  data={meal}
                  //
                  tantoMaster={tantoMaster}
                  // update input for data saving
                  handleFormInput={this.handleFormInput}
                  // setting data
                  settingData={settingData}
                  // Other Comment Setting
                  yougoMasterNa={yougoMasterMC}
                  yougoMasterEditState={this.getYougoMasterEditState}
                  yougoMasterEditCancel={this.getYougoMasterEditCancel}
                  yougoMasterEditSave={this.getYougoMasterEditSave}
                />
              </Card>
            </Panel>
          </Collapse>

          <Collapse
            accordion
            className="rwa-c-col"
            expandIconPosition="left"
            bordered={false}
            activeKey={[isOpenSuibun]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={this.onChangePanel.bind(this, "3")}
          >
            <Panel header="水分摂取" key="3" style={customPanelStyle}>
              <Divider orientation="left">
                <Checkbox
                  checked={updateState.suibun}
                  onChange={this.onChangeSuibun}
                >
                  修正
                </Checkbox>
              </Divider>

              {suibun && suibun.length >= 0
                ? suibun.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      type="inner"
                      extra={
                        <span>
                          <Button
                            type="primary"
                            onClick={this.showModalComent.bind(
                              this,
                              3,
                              item.reportCaseNaiyou ? item.reportCaseNaiyou : "",
                              item.reportCaseNaiyou2
                                ? item.reportCaseNaiyou2
                                : "",
                              item.reportCaseTyuif ? item.reportCaseTyuif : "",
                              item.reportCaseNishif ? item.reportCaseNishif : ""
                            )}
                            disabled={!this.state.updateState.suibun}
                          >
                            <MessageOutlined /> 申送
                          </Button>
                          {updateState.suibun ? (
                            <Popconfirm
                              title="削除してもよろしいですか？"
                              onConfirm={this.confirmDel.bind(
                                this,
                                3,
                                item && item.suibunId ? item.suibunId : null
                              )}
                              onCancel={this.cancelDel.bind(this, 3)}
                              okText="OK"
                              cancelText="キャンセル"
                            >
                              <Button style={{ marginLeft: "16px" }} type="primary"
                                danger>
                                削除
                              </Button>
                            </Popconfirm>
                          ) : null}
                        </span>
                      }

                      style={{ marginTop: "8px" }}
                    >
                      <FormSuibun
                        disabled={!updateState.suibun}
                        data={item}
                        //
                        tantoMaster={tantoMaster}
                        // update input for data saving
                        handleFormInput={this.handleFormInputSuibun.bind(
                          this,
                          index
                        )}
                        // setting data
                        settingData={settingData}
                        // Other Comment Setting
                        yougoMasterNa={yougoMasterSC}
                        yougoMasterEditState={this.getYougoMasterEditState}
                        yougoMasterEditCancel={this.getYougoMasterEditCancel}
                        yougoMasterEditSave={this.getYougoMasterEditSave}
                        yougoMasterSy={yougoMasterSy}
                      />
                    </Card>
                  );
                })
                : null}

              <Button
                icon={<PlusOutlined />}
                onClick={this.onClickAddSuibun}
                style={{
                  background: "#8904B1",
                  color: "#ffffff",
                  float: "right",
                  marginTop: "16px",
                }}
              >
                追加
              </Button>
            </Panel>
          </Collapse>

          <Collapse
            accordion
            className="rwa-c-col"
            expandIconPosition="left"
            bordered={false}
            activeKey={[isOpenHaisetu]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={this.onChangePanel.bind(this, "4")}
          >
            <Panel header="排泄" key="4" style={customPanelStyle}>
              <Card
                type="inner"
                title={
                  <Checkbox
                    checked={updateState.hainyou}
                    onChange={this.onChangeHainyou}
                  >
                    排尿
                  </Checkbox>
                }
                extra={
                  <span>
                    <Button
                      type="primary"
                      onClick={this.showModalComent.bind(
                        this,
                        4,
                        hainyou.reportCaseNaiyou
                          ? hainyou.reportCaseNaiyou
                          : "",
                        hainyou.reportCaseNaiyou2
                          ? hainyou.reportCaseNaiyou2
                          : "",
                        hainyou.reportCaseTyuif ? hainyou.reportCaseTyuif : "",
                        hainyou.reportCaseNishif
                          ? hainyou.reportCaseNishif
                          : "",
                        41
                      )}
                      disabled={!this.state.updateState.hainyou}
                    >
                      <MessageOutlined /> 申送
                    </Button>
                    {updateState.hainyou ? (
                      <Popconfirm
                        title="削除してもよろしいですか？"
                        onConfirm={this.confirmDel.bind(
                          this,
                          41,
                          hainyou && hainyou.haisetuId
                            ? hainyou.haisetuId
                            : null
                        )}
                        onCancel={this.cancelDel.bind(this, 41)}
                        okText="OK"
                        cancelText="キャンセル"
                      >
                        <Button style={{ marginLeft: "16px" }} type="primary"
                          danger>
                          削除
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </span>
                }
              >
                <FormHainyou
                  disabled={!updateState.hainyou}
                  data={hainyou}
                  //
                  tantoMaster={tantoMaster}
                  // update input for data saving
                  handleFormInput={this.handleFormInput}
                  // setting data
                  settingData={settingData}
                  // Other Comment Setting
                  yougoMasterNa={yougoMasterHC}
                  yougoMasterEditState={this.getYougoMasterEditState}
                  yougoMasterEditCancel={this.getYougoMasterEditCancel}
                  yougoMasterEditSave={this.getYougoMasterEditSave}
                  haisetuType={haisetuType}
                  haisetuState={haisetuState}
                  riyousyaId={riyousyaId}
                  syokichiItem={this.syokichiItem.bind(this, 1)}
                  syokichiUpdate={this.syokichiUpdate.bind(this, 1)}
                />
              </Card>
              <Card
                type="inner"
                title={
                  <Checkbox
                    checked={updateState.haiben}
                    onChange={this.onChangeHaiben}
                  >
                    排便
                  </Checkbox>
                }
                extra={
                  <span>
                    <Button
                      type="primary"
                      onClick={this.showModalComent.bind(
                        this,
                        4,
                        haiben.reportCaseNaiyou ? haiben.reportCaseNaiyou : "",
                        haiben.reportCaseNaiyou2
                          ? haiben.reportCaseNaiyou2
                          : "",
                        haiben.reportCaseTyuif ? haiben.reportCaseTyuif : "",
                        haiben.reportCaseNishif ? haiben.reportCaseNishif : "",
                        42
                      )}
                      disabled={!this.state.updateState.haiben}
                    >
                      <MessageOutlined /> 申送
                    </Button>
                    {updateState.haiben ? (
                      <Popconfirm
                        title="削除してもよろしいですか？"
                        onConfirm={this.confirmDel.bind(
                          this,
                          42,
                          haiben && haiben.haisetuId ? haiben.haisetuId : null
                        )}
                        onCancel={this.cancelDel.bind(this, 42)}
                        okText="OK"
                        cancelText="キャンセル"
                      >
                        <Button style={{ marginLeft: "16px" }} type="primary"
                          danger>
                          削除
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </span>
                }
                style={{ marginTop: "8px" }}
              >
                <FormHaiben
                  disabled={!updateState.haiben}
                  data={haiben}
                  //
                  tantoMaster={tantoMaster}
                  // update input for data saving
                  handleFormInput={this.handleFormInput}
                  // setting data
                  settingData={settingData}
                  // Other Comment Setting
                  yougoMasterNa={yougoMasterHC}
                  yougoMasterEditState={this.getYougoMasterEditState}
                  yougoMasterEditCancel={this.getYougoMasterEditCancel}
                  yougoMasterEditSave={this.getYougoMasterEditSave}
                  haisetuType={haisetuType}
                  haisetuState={haisetuState}
                  riyousyaId={riyousyaId}
                  syokichiItem={this.syokichiItem.bind(this, 2)}
                  syokichiUpdate={this.syokichiUpdate.bind(this, 2)}
                />
              </Card>
            </Panel>
          </Collapse>

          <Collapse
            accordion
            className="rwa-c-col"
            expandIconPosition="left"
            bordered={false}
            activeKey={[isOpenNyuyoku]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={this.onChangePanel.bind(this, "5")}
          >
            <Panel header="入浴" key="5" style={customPanelStyle}>
              <Card
                type="inner"
                title={
                  <Checkbox
                    checked={updateState.nyuyoku}
                    onChange={this.onChangeNyuyoku}
                  >
                    修正
                  </Checkbox>
                }
                extra={
                  <span>
                    <Button
                      type="primary"
                      onClick={this.showModalComent.bind(
                        this,
                        5,
                        nyuyoku.reportCaseNaiyou
                          ? nyuyoku.reportCaseNaiyou
                          : "",
                        nyuyoku.reportCaseNaiyou2
                          ? nyuyoku.reportCaseNaiyou2
                          : "",
                        nyuyoku.reportCaseTyuif ? nyuyoku.reportCaseTyuif : "",
                        nyuyoku.reportCaseNishif ? nyuyoku.reportCaseNishif : ""
                      )}
                      disabled={!this.state.updateState.nyuyoku}
                    >
                      <MessageOutlined /> 申送
                    </Button>
                    {updateState.nyuyoku ? (
                      <Popconfirm
                        title="削除してもよろしいですか？"
                        onConfirm={this.confirmDel.bind(
                          this,
                          5,
                          nyuyoku && nyuyoku.nyuyokuId
                            ? nyuyoku.nyuyokuId
                            : null
                        )}
                        onCancel={this.cancelDel.bind(this, 5)}
                        okText="OK"
                        cancelText="キャンセル"
                      >
                        <Button style={{ marginLeft: "16px" }} type="primary"
                          danger>
                          削除
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </span>
                }
              >
                <FormNyuyoku
                  disabled={!updateState.nyuyoku}
                  data={nyuyoku}
                  //
                  tantoMaster={tantoMaster}
                  // update input for data saving
                  handleFormInput={this.handleFormInput}
                  // setting data
                  settingData={settingData}
                  // Other Comment Setting
                  yougoMasterNa={yougoMasterNC}
                  yougoMasterEditState={this.getYougoMasterEditState}
                  yougoMasterEditCancel={this.getYougoMasterEditCancel}
                  yougoMasterEditSave={this.getYougoMasterEditSave}
                  nyuyokuType={nyuyokuType}
                  riyousyaId={riyousyaId}
                  syokichiItem={this.syokichiItem.bind(this, 3)}
                  syokichiUpdate={this.syokichiUpdate.bind(this, 3)}
                />
              </Card>
            </Panel>
          </Collapse>

          <Collapse
            accordion
            className="rwa-c-col"
            expandIconPosition="left"
            bordered={false}
            activeKey={[isOpenFukuyaku]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={this.onChangePanel.bind(this, "6")}
          >
            <Panel header="服薬" key="6" style={customPanelStyle}>
              <Divider orientation="left">
                <Checkbox
                  checked={updateState.fukuyaku}
                  onChange={this.onChangeFukuyaku}
                >
                  修正
                </Checkbox>
              </Divider>

              {fukuyaku && fukuyaku.length >= 0
                ? fukuyaku.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      type="inner"
                      extra={
                        <span>
                          <Button
                            type="primary"
                            onClick={this.showModalComent.bind(
                              this,
                              6,
                              item.reportCaseNaiyou
                                ? item.reportCaseNaiyou
                                : "",
                              item.reportCaseNaiyou2
                                ? item.reportCaseNaiyou2
                                : "",
                              item.reportCaseTyuif
                                ? item.reportCaseTyuif
                                : "",
                              item.reportCaseNishif
                                ? item.reportCaseNishif
                                : ""
                            )}
                            disabled={!this.state.updateState.fukuyaku}
                          >
                            <MessageOutlined /> 申送
                          </Button>
                          {updateState.fukuyaku ? (
                            <Popconfirm
                              title="削除してもよろしいですか？"
                              onConfirm={this.confirmDel.bind(
                                this,
                                6,
                                item && item.fukuyakuId
                                  ? item.fukuyakuId
                                  : null
                              )}
                              onCancel={this.cancelDel.bind(this, 6)}
                              okText="OK"
                              cancelText="キャンセル"
                            >
                              <Button style={{ marginLeft: "16px" }} type="primary"
                                danger>
                                削除
                              </Button>
                            </Popconfirm>
                          ) : null}
                        </span>
                      }

                      style={{ marginTop: "8px" }}
                    >
                      <FormFukuyaku
                        disabled={!updateState.fukuyaku}
                        data={item}
                        //
                        tantoMaster={tantoMaster}
                        // update input for data saving
                        handleFormInput={this.handleFormInputFukuyaku.bind(
                          this,
                          index
                        )}
                        // setting data
                        settingData={settingData}
                        // Other Comment Setting
                        yougoMasterNa={yougoMasterFC}
                        yougoMasterEditState={this.getYougoMasterEditState}
                        yougoMasterEditCancel={this.getYougoMasterEditCancel}
                        yougoMasterEditSave={this.getYougoMasterEditSave}
                        //
                        dataBunrui={yougoDrugBunrui}
                        drugList={drugList}
                        drugLoading={drugLoading}
                        handleDrugSearch={this.getHandleDrugSearch}
                      />
                    </Card>
                  );
                })
                : null}

              <Button
                icon={<PlusOutlined />}
                onClick={this.onClickAddFukuyaku}
                style={{
                  background: "#8904B1",
                  color: "#ffffff",
                  float: "right",
                  marginTop: "16px",
                }}
              >
                追加
              </Button>
            </Panel>
          </Collapse>

          <Collapse
            accordion
            className="rwa-c-col"
            expandIconPosition="left"
            bordered={false}
            activeKey={[isOpenKaigo]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={this.onChangePanel.bind(this, "7")}
          >
            <Panel header="提供記録" key="7" style={customPanelStyle}>
              <Divider orientation="left">
                <Checkbox
                  checked={updateState.kaigo}
                  onChange={this.onChangeKaigo}
                >
                  修正
                </Checkbox>
              </Divider>

              {kaigo && kaigo.length >= 0
                ? kaigo.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      title={
                        item.youtei && updateState.kaigo ? (
                          <Button
                            style={{ background: "#d32f2f", color: "white" }}
                            onClick={this.handleYotei.bind(this, item)}
                          >
                            実施
                          </Button>
                        ) : (
                          ""
                        )
                      }
                      type="inner"
                      extra={
                        <span>
                          <Button
                            type="primary"
                            onClick={this.showModalComent.bind(
                              this,
                              7,
                              item.reportCaseNaiyou
                                ? item.reportCaseNaiyou
                                : "",
                              item.reportCaseNaiyou2
                                ? item.reportCaseNaiyou2
                                : "",
                              item.reportCaseTyuif
                                ? item.reportCaseTyuif
                                : "",
                              item.reportCaseNishif
                                ? item.reportCaseNishif
                                : "",
                              index
                            )}
                            disabled={
                              !this.state.updateState.kaigo
                            }
                          >
                            <MessageOutlined /> 申送
                          </Button>
                          {updateState.kaigo ? (
                            <Popconfirm
                              title="削除してもよろしいですか？"
                              onConfirm={this.confirmDel.bind(
                                this,
                                7,
                                item && item.kaigoId ? item.kaigoId : null,
                                index
                              )}
                              onCancel={this.cancelDel.bind(this, 7)}
                              okText="OK"
                              cancelText="キャンセル"
                            >
                              <Button
                                style={{ marginLeft: "16px" }}
                                type="primary"
                                danger
                              >
                                削除
                              </Button>
                            </Popconfirm>
                          ) : null}
                        </span>
                      }
                      style={{ marginTop: "8px" }}
                    >
                      <FormKaigo
                        disabled={!updateState.kaigo}
                        data={item}
                        //
                        tantoMaster={tantoMaster}
                        // update input for data saving
                        handleFormInput={this.handleFormInputKaigo.bind(
                          this,
                          index
                        )}
                        // setting data
                        settingData={settingData}
                        // Other Comment Setting
                        yougoMasterNa={yougoMasterKC}
                        yougoMasterEditState={this.getYougoMasterEditState}
                        yougoMasterEditCancel={this.getYougoMasterEditCancel}
                        yougoMasterEditSave={this.getYougoMasterEditSave}
                        teikyouData={teikyouData}
                      />
                    </Card>
                  );
                })
                : null}

              <Button
                icon={<PlusOutlined />}
                onClick={this.onClickAddKaigo}
                style={{
                  background: "#8904B1",
                  color: "#ffffff",
                  float: "right",
                  marginTop: "16px",
                }}
              >
                追加
              </Button>
            </Panel>
          </Collapse>

          <Collapse
            accordion
            className="rwa-c-col"
            expandIconPosition="left"
            bordered={false}
            activeKey={[isOpenTokki]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={this.onChangePanel.bind(this, "8")}
          >
            <Panel header="特記" key="8" style={customPanelStyle}>
              <Card
                type="inner"
                title={
                  <Checkbox
                    checked={updateState.tokki}
                    onChange={this.onChangeTokki}
                  >
                    修正
                  </Checkbox>
                }
                extra={
                  <span>
                    <Button
                      type="primary"
                      onClick={this.showModalComent.bind(
                        this,
                        8,
                        tokki.reportCaseNaiyou ? tokki.reportCaseNaiyou : "",
                        tokki.reportCaseNaiyou2 ? tokki.reportCaseNaiyou2 : "",
                        tokki.reportCaseTyuif ? tokki.reportCaseTyuif : "",
                        tokki.reportCaseNishif ? tokki.reportCaseNishif : ""
                      )}
                      disabled={!this.state.updateState.tokki}
                    >
                      <MessageOutlined /> 申送
                    </Button>
                    {updateState.tokki ? (
                      <Popconfirm
                        title="削除してもよろしいですか？"
                        onConfirm={this.confirmDel.bind(
                          this,
                          8,
                          tokki && tokki.tokkiId ? tokki.tokkiId : null
                        )}
                        onCancel={this.cancelDel.bind(this, 8)}
                        okText="OK"
                        cancelText="キャンセル"
                      >
                        <Button style={{ marginLeft: "16px" }} type="primary"
                          danger>
                          削除
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </span>
                }
              >
                <FormTokki
                  disabled={!updateState.tokki}
                  data={tokki}
                  //
                  tantoMaster={tantoMaster}
                  // update input for data saving
                  handleFormInput={this.handleFormInput}
                  // setting data
                  settingData={settingData}
                  // Other Comment Setting
                  yougoMasterNa={yougoMasterTC}
                  yougoMasterEditState={this.getYougoMasterEditState}
                  yougoMasterEditCancel={this.getYougoMasterEditCancel}
                  yougoMasterEditSave={this.getYougoMasterEditSave}
                />
              </Card>
            </Panel>
          </Collapse>

          <Collapse
            accordion
            className="rwa-c-col"
            expandIconPosition="left"
            bordered={false}
            activeKey={[isOpenGazou]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={this.onChangePanel.bind(this, "9")}
          >
            <Panel header="画像等" key="9" style={customPanelStyle}>
              <Card
                type="inner"
                title={
                  <Checkbox
                    checked={updateState.gazou}
                    onChange={this.onChangeGazou}
                  >
                    修正
                  </Checkbox>
                }
                extra={
                  <span>
                    <Button
                      type="primary"
                      onClick={this.showModalComent.bind(
                        this,
                        9,
                        gazou.reportCaseNaiyou ? gazou.reportCaseNaiyou : "",
                        gazou.reportCaseNaiyou2 ? gazou.reportCaseNaiyou2 : "",
                        gazou.reportCaseTyuif ? gazou.reportCaseTyuif : "",
                        gazou.reportCaseNishif ? gazou.reportCaseNishif : ""
                      )}
                      disabled={!this.state.updateState.gazou}
                    >
                      <MessageOutlined /> 申送
                    </Button>
                    {updateState.gazou ? (
                      <Popconfirm
                        title="削除してもよろしいですか？"
                        onConfirm={this.confirmDel.bind(
                          this,
                          9,
                          gazou && gazou.fileManagementId
                            ? gazou.fileManagementId
                            : null
                        )}
                        onCancel={this.cancelDel.bind(this, 9)}
                        okText="OK"
                        cancelText="キャンセル"
                      >
                        <Button style={{ marginLeft: "16px" }} type="primary"
                          danger>
                          削除
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </span>
                }
              >
                <FormGazou
                  disabled={!updateState.gazou}
                  data={gazou}
                  //
                  tantoMaster={tantoMaster}
                  // update input for data saving
                  handleFormInput={this.handleFormInput}
                  // setting data
                  settingData={settingData}
                  // Other Comment Setting
                  yougoMasterNa={yougoMasterGC}
                  yougoMasterEditState={this.getYougoMasterEditState}
                  yougoMasterEditCancel={this.getYougoMasterEditCancel}
                  yougoMasterEditSave={this.getYougoMasterEditSave}
                  gazouKubun={gazouKubun}
                  file={file}
                  imageUrl={imageUrl}
                  filePreview={filePreview}
                />
              </Card>
            </Panel>
          </Collapse>

          <Modal
            open={this.state.visible}
            title="申し送り"
            onOk={this.handleOkComent}
            onCancel={this.handleCancelComent}
            footer={[
              <Button key="back" type="primary" onClick={this.handleOkComent}>
                OK
              </Button>,
              <Button key="submit" onClick={this.handleCancelComent}>
                キャンセル
              </Button>,
            ]}
          >
            <p>{this.state.naiyou ? "(" + this.state.naiyou + ")" : ""}</p>
            <div style={{ marginBottom: "8px" }}>
              <Checkbox
                checked={this.state.tyuif ? this.state.tyuif : false}
                onChange={this.onChangeTyuif}
              >
                申し送りフラグ
              </Checkbox>
              <Checkbox
                checked={this.state.nishif ? this.state.nishif : false}
                onChange={this.onChangeNishif}
              >
                日誌フラグ
              </Checkbox>
            </div>
            <FormReportCase
              value={this.state.openComent}
              onChangeNaiyou2={this.onChangeNaiyou2}
            />
          </Modal>
        </div>
      </Spin>)
    );
  }
}
