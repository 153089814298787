export const apiUrls = {
  INIT: "",
  API_PORT: 9000,
  LOGIN: "/api/v1/login",
  LOGOUT: "/api/v1/logout",
  DECODE_TOKEN: "/api/v1/token",
  MAINTAINANCE_MODE_MNGT: "/api/v1/maintenance-modeMNGT",

  KANRI_SEARCH_DATA: "/api/v1/kanri-search-data", // /api/v1/kanri-search-data?type=users
  KANRI_MENU_SETTING: "/api/v1/kanri-user-setting",
  KANRI_USERS_LIST: "/api/v1/kanri-users-list", // GET AND POST
  KANRI_FILES_LIST: "/api/v1/kanri-files-list", // GET AND POST
  KANRI_FILES_BY_IDS: "/api/v1/kanri-file-by-ids",
  KANRI_FILES_SINGLE_RECORD: "/api/v1/file-single-record",
  KANRI_DENGON_LIST: "/api/v1/kanri-dengon-list", // GET AND POST
  KANRI_DENGON_BY_IDS: "/api/v1/kanri-dengon-by-ids",
  KANRI_DENGON_SINGLE_RECORD: "/api/v1/dengon-single-record",
  KANRI_MOSHIOKURI_LIST: "/api/v1/kanri-moshiokuri-list", // GET AND POST
  KANRI_MOSHIOKURI_BY_IDS: "/api/v1/kanri-moshiokuri-by-ids",
  KANRI_MOSHIOKURI_SINGLE_RECORD: "/api/v1/moshiokuri-single-record",
  KANRI_OPENSL_LIST: "/api/v1/kanri-opensl-list", // GET AND POST
  KANRI_OPENSL_BY_IDS: "/api/v1/kanri-opensl-by-ids",
  KANRI_OPENSL_SINGLE_RECORD: "/api/v1/opensl-single-record",
  KANRI_KYM_LIST: "/api/v1/kanri-kym-list", // GET AND POST
  KANRI_KYM_BY_IDS: "/api/v1/kanri-kym-by-ids",
  KANRI_KYM_SINGLE_RECORD: "/api/v1/kym-single-record",
  KANRI_SETTINGMASTER_LIST: "/api/v1/kanri-sm-list", // GET AND POST
  KANRI_YOUGOMASTER_LIST: "/api/v1/kanri-ym-list", // GET AND POST
  KANRI_TANTOMASTER_LIST: "/api/v1/kanri-tm-list", // GET AND POST
  KANRI_USERRIYOUSYALINK_LIST: "/api/v1/kanri-url-list", // GET AND POST

  // CRUD User Table
  USER_ADD: "/api/v1/users-insert",
  USER_UPDATE: "/api/v1/users-update",
  USER_DEL: "/api/v1/users-del",
  GET_USER_LOGIN_DETAIL: "/api/v1/user-information",

  // FILE
  FILE_UPDATE: "/api/v1/kanri-file-edit",
  FILE_ADD: "/api/v1/kanri-file-create",

  // DENGON
  DENGON_UPDATE: "/api/v1/kanri-dengon-edit",
  DENGON_ADD: "/api/v1/kanri-dengon-create",
  DENGON_DELETE: "/api/v1/kanri-dengon-delete",

  // MOSHIOKURI
  MOSHIOKURI_UPDATE: "/api/v1/kanri-moshiokuri-edit",
  MOSHIOKURI_ADD: "/api/v1/kanri-moshiokuri-create",
  MOSHIOKURI_DELETE: "/api/v1/kanri-moshiokuri-delete",

  // OPEN_SCREEN_LOG
  OPENSL_UPDATE: "/api/v1/kanri-opensl-edit",
  OPENSL_ADD: "/api/v1/kanri-opensl-create",
  OPENSL_DELETE: "/api/v1/kanri-opensl-delete",

  // KAIGO_YOUGO_MASTER
  KYM_UPDATE: "/api/v1/kanri-kym-edit",
  KYM_ADD: "/api/v1/kanri-kym-create",
  KYM_DELETE: "/api/v1/kanri-kym-delete",

  //
  POST_TANTO_MASTER: "/api/v1/tantomaster-insert",
  PUT_TANTO_MASTER: "/api/v1/tantomaster-update",
  DEL_TANTO_MASTER: "/api/v1/tantomaster-del",

  //
  POST_USER_RIYOUSYA_LINK: "/api/v1/userriyousyalink-insert",
  PUT_USER_RIYOUSYA_LINK: "/api/v1/userriyousyalink-update",
  DEL_USER_RIYOUSYA_LINK: "/api/v1/userriyousyalink-del",

  GET_RIYOUSYA_LIST: "/api/v1/riyousya-list",
  GET_RIYOUSYA_DETAIL: "/api/v1/riyousya-detail",
  GET_RIYOUSYA_SHIRYOU: "/api/v1/file-riyousya", // pageNo=1&pageSize=20&mainSID=59&riyousyaId=286&processType=riyousyaSiryou&selectKubun=hoka
  RIYOU_UPDATE_SERVER: "/api/v1/riyousya-update",
  RIYOU_MEAL_ABOVE_LIST: "/api/v1/riyou-syokujisen",
  RIYOU_MEAL_BELOW_LIST: "/api/v1/riyou-shokuji-rireki",

  GET_ROOM_LIST: "/api/v1/room-list",
  GET_MESSAGE_LIST: "/api/v1/message-data-list",
  GET_MESSAGE_ADD: "/api/v1/message-data-add",
  GET_MESSAGE_GROUP: "/api/v1/send-group",
  GET_USER_LIST: "/api/v1/users-list",
  SENDGROUP_GETALL: "/api/v1/sendgroup-getall",
  SENDGROUP_UPDATE: "/api/v1/sendgroup-update",

  RIYOUSYA_SEARCH: "/api/v1/mngt/riyousya",
  RIYOUSYA_SEARCH2: "/api/v1/riyousya2",
  RIYOUSYA_GET: "/api/v1/riyousyaId",
  GET_LIST_RIYOUSYA_ID: "/api/v1/riyousyaIds",

  GET_TANTO_MASTER_LIST: "/api/v1/tanto-master-list",
  GET_TANTO_MASTER_LIST2: "/api/v1/tanto-master-list-by-sid",
  GET_TANTO_MASTER_LIST_KIKAN: "/api/v1/tanto-master-list-bykikan",
  GET_TANTO_MASTER_BY_ID: "/api/v1/tanto-master-by-id",

  // GET_YOUGO_MASTER_NAIYOU_ALL: "/api/v1/yougo-master-All",
  GET_YOUGO_MASTER_NAIYOU: "/api/v1/yougomaster-naiyou",
  GET_YOUGO_MASTER_ECT: "/api/v1/yougomaster-ect",
  POST_YOUGO_MASTER: "/api/v1/yougomaster-insert",
  PUT_YOUGO_MASTER: "/api/v1/yougomaster-update",
  DEL_YOUGO_MASTER: "/api/v1/yougomaster-del",

  UPDATE_MESSAGE_READF: "/api/v1/message-mitayo",
  GET_AVATAR: "/api/v1/avatar",

  GET_BYOUMEI_MASTER_LIST: "/api/v1/byoumei-master",
  GET_DRUG_MASTER_LIST: "/api/v1/drug-master",
  GET_MACHINE_MASTER_LIST: "/api/v1/machine-master",
  GET_SERVICE_SYURUI_MASTER_LIST: "/api/v1/service-syurui-master",
  GET_SERVICE_KIKAN_MASTER_LIST: "/api/v1/service-kikan-master",
  GET_IRYOU_KIKAN_MASTER_LIST: "/api/v1/iryou-kikan-master",
  GET_KAIGO_YOUGO_MASTER: "/api/v1/kaigo-yougo-list",
  GET_KAIGO_YOUGO_MASTER_ALL: "/api/v1/kaigo-yougo-list-all",
  GET_KAIGO_YOUGO_MASTER_ID: "/api/v1/kaigo-yougo-get",
  UPDATE_KAIGO_YOUGO_MASTER: "/api/v1/kaigo-yougo-update",
  GET_SISETU_KAIGO_NAIYOU_MASTER: "/api/v1/sisetu-kaigo-naiyou-list",
  UPDATE_KAIGO_YOUGO_LIST_MASTER: "/api/v1/kaigo-yougo-list-update",
  GET_KAIGO_YOUGO_MAX_SORT: "/api/v1/kaigo-yougo-max-sort",

  NYUTAISHO_BASIC_DATA: "/api/v1/report-basic",
  GAIHAKU_RIREKI: "/api/v1/report-basic/",

  REPORT_RIYOUSYA: "/api/v1/report-riyousya-list",
  REPORT_VITAL: "/api/v1/report-vital-list",
  REPORT_SHUUKEI: "/api/v1/report-shuukei",

  REPORT_VITAL_UPDATE: "/api/v1/report-vital-update",
  REPORT_VITAL_INSERT: "/api/v1/report-vital-insert",
  REPORT_VITAL_DELETE: "/api/v1/report-vital-delete",

  REPORT_MEAL_UPDATE: "/api/v1/report-meal-update",
  REPORT_MEAL_INSERT: "/api/v1/report-meal-insert",
  REPORT_MEAL_DELETE: "/api/v1/report-meal-delete",

  REPORT_SUIBUN_UPDATE: "/api/v1/report-suibun-update",
  REPORT_SUIBUN_INSERT: "/api/v1/report-suibun-insert",
  REPORT_SUIBUN_DELETE: "/api/v1/report-suibun-delete",

  REPORT_HAISETU_UPDATE: "/api/v1/report-haisetu-update",
  REPORT_HAISETU_INSERT: "/api/v1/report-haisetu-insert",
  REPORT_HAISETU_DELETE: "/api/v1/report-haisetu-delete",

  REPORT_NYUYOKU_UPDATE: "/api/v1/report-nyuyoku-update",
  REPORT_NYUYOKU_INSERT: "/api/v1/report-nyuyoku-insert",
  REPORT_NYUYOKU_DELETE: "/api/v1/report-nyuyoku-delete",

  REPORT_FUKUYAKU_UPDATE: "/api/v1/report-fukuyaku-update",
  REPORT_FUKUYAKU_INSERT: "/api/v1/report-fukuyaku-insert",
  REPORT_FUKUYAKU_DELETE: "/api/v1/report-fukuyaku-delete",

  REPORT_KAIGO_UPDATE: "/api/v1/report-kaigonaiyou-update",
  REPORT_KAIGO_INSERT: "/api/v1/report-kaigonaiyou-insert",
  REPORT_KAIGO_DELETE: "/api/v1/report-kaigonaiyou-delete",
  REPORT_KAIGO_INSERT_UPDATE: "/api/v1/report-kaigonaiyou/addeditmul",

  REPORT_TOKKI_UPDATE: "/api/v1/report-tokki-update",
  REPORT_TOKKI_INSERT: "/api/v1/report-tokki-insert",
  REPORT_TOKKI_DELETE: "/api/v1/report-tokki-delete",

  REPORT_GAZOU_UPDATE: "/api/v1/report-filemanagement-update",
  REPORT_GAZOU_INSERT: "/api/v1/report-filemanagement-insert",
  REPORT_GAZOU_DELETE: "/api/v1/report-filemanagement-delete",

  FILE_DEL_MUL: "/api/v1/report-filemanagement-delete-mul ",

  REPORT_MEAL: "/api/v1/report-meal-list",
  REPORT_SUIBUN: "/api/v1/report-suibun-list",
  REPORT_HAISETU: "/api/v1/report-haisetu-list",
  REPORT_NYUYOKU: "/api/v1/report-nyuyoku-list",
  REPORT_FUKUYAKU: "/api/v1/report-fukuyaku-list",
  REPORT_KAIGONAIYOU: "/api/v1/report-kaigonaiyou-list",
  REPORT_TOKKI: "/api/v1/report-tokki-list",
  REPORT_GAZOU: "/api/v1/file-management-list",

  DAILY_PLAN_WEEKLY: "/api/v1/sisetu-daily-plan-weekly",

  NYUTAISHO_DAILYPLAN: "/api/v1/sisetu-daily-plan-new",
  NYUTAISHO_DAILYPLAN_NEW: "/api/v1/sisetu-daily-plan-new",
  NYUTAISHO_FILTER: "/api/v1/report-filter",

  NYUTAISHO_ZENKAI_LOAD: "/api/v1/previous-vital",

  DOWNLOAD_FILE_KIROKU: "/api/v1/file-management-url",
  LOAD_FILE: "/api/v1/file",
  LOAD_FILE_PREVIEW: "/api/v1/file-preview",

  NYUTAISHO_BETWEEN_DATES: "/api/v1/nyutaisho-rireki-between-dates",
  NYUTAISHO_RIREKI_LIST_BYDATE: "/api/v1/nyutaisho-rireki-list-bydate",
  NYUTAISHO_RIREKI_UPDATE: "/api/v1/nyutaisho-rireki-update",
  NYUTAISHO_RIREKI_INSERT: "/api/v1/nyutaisho-rireki-insert",
  NYUTAISHO_RIREKI_DELETE: "/api/v1/nyutaisho-rireki-delete",
  NYUTAISHO_RIREKI_QUOTE: "/api/v1/nyutaisho-rireki-quote",

  GAIHAKU_GAISYUTU_RIREKI_BYDATE: "/api/v1/gaihaku-gaisyutu-rireki-bydate",
  GAIHAKU_GAISYUTU_RIREKI_UPDATE: "/api/v1/gaihaku-gaisyutu-rireki-update",

  GET_SISETU_DAILY_PLAN_LIST: "/api/v1/sisetu-daily-plan-doc-list",
  GET_SISETU_DAILY_PLAN_BY_ID: "/api/v1/sisetu-daily-plan-by-id",
  // GET_SISETU_DAILY_PLAN_TEMPLATE: "/api/v1/sisetu-daily-plan-template", updated 2020-04-09
  SAVE_SISETU_DAILY_PLAN: "/api/v1/save-sisetu-daily-plan",
  DELETE_SISETU_DAILY_PLAN: "/api/v1/delete-sisetu-daily-plan",

  // updated 2020-04-09
  SAVE_SISETU_DAILY_KAIGO_SERVICE_TEMPLATE:
    "/api/v1/sisetu-daily-kaigo-naiyo-temp-save",
  GET_SISETU_DAILY_KAIGO_SERVICE_TEMPLATE_NAME:
    "/api/v1/sisetu-daily-kaigo-service-temp-name",
  GET_SISETU_DAILY_KAIGO_SERVICE_TEMPLATE:
    "/api/v1/sisetu-daily-kaigo-service-temp",
  SAVE_SISETU_DAILY_ACT_TEMPLATE: "/api/v1/sisetu-daily-act-temp-save",
  GET_SISETU_DAILY_ACT_TEMPLATE: "/api/v1/sisetu-daily-act-temp",

  GET_RIYOUSYA_PLAN: "/api/v1/riyousya-plan",
  CREATE_RIYOUSYA_PLAN: "/api/v1/riyousyaPlan-insert",
  UPDATE_RIYOUSYA_PLAN: "/api/v1/riyousyaPlan-update",
  DELETE_RIYOUSYA_PLAN: "/api/v1/riyousyaPlan-delete",

  GET_EVENT_PLAN: "/api/v1/event-plan",
  CREATE_EVENT_PLAN: "/api/v1/eventPlan-insert",
  UPDATE_EVENT_PLAN: "/api/v1/eventPlan-update",
  DELETE_EVENT_PLAN: "/api/v1/eventPlan-delete",

  GET_SYSTEM_NAMES: "/api/v1/system-control-list",
  GET_SETTING_MASTER: "/api/v1/setting-master",
  POST_SETTING_MASTER: "/api/v1/settingmaster-insert",
  PUT_SETTING_MASTER: "/api/v1/settingmaster-update",
  DEL_SETTING_MASTER: "/api/v1/settingmaster-del",

  GET_OPEN_SCREEN_LOG: "/api/v1/get-open-screen-log",
  SAVE_OPEN_SCREEN_LOG: "/api/v1/save-open-screen-log",

  GET_REPORT_CASE: "/api/v1/report-case-list",
  CREATE_REPORT_CASE: "/api/v1/report-case-insert",
  UPDATE_REPORT_CASE: "/api/v1/report-case-update",
  DELETE_REPORT_CASE: "/api/v1/report-case-delete",
  GET_REPORT_CASE_BY_ID: "/api/v1/report-case-by-id",
  GET_REPORT_CASE_START: "/api/v1/moshiokuri",

  GET_ALL_DOCUMENT_LIST: "/api/v1/doc-list",

  GET_KYOTAKU_PLAN1_LIST: "/api/v1/kyotaku-plan1-list",
  GET_KYOTAKU_PLAN1_BY_ID: "/api/v1/kyotaku-plan1",
  CREATE_KYOTAKU_PLAN1: "/api/v1/kyotaku-plan1",
  UPDATE_KYOTAKU_PLAN1: "/api/v1/kyotaku-plan1",
  DELETE_KYOTAKU_PLAN1: "/api/v1/kyotaku-plan1",
  GET_KYOTAKU_PLAN1_TO_QUOTE: "/api/v1/kyotaku-plan1-quote-home",
  GET_LIST_ID_PLAN1: "/api/v1/kyotaku-plan1-list-id",
  GET_LATEST_PLAN1: "/api/v1/kyotaku-plan1-latest",

  GET_KYOTAKU_PLAN2_LIST: "/api/v1/kyotaku-plan2-list",
  GET_KYOTAKU_PLAN2_BY_ID: "/api/v1/kyotaku-plan2",
  CREATE_KYOTAKU_PLAN2: "/api/v1/kyotaku-plan2-home",
  UPDATE_KYOTAKU_PLAN2: "/api/v1/kyotaku-plan2-home",
  DELETE_KYOTAKU_PLAN2: "/api/v1/kyotaku-plan2",
  GET_KYOTAKU_PLAN2_TO_QUOTE: "/api/v1/kyotaku-plan2-quote-home",
  GET_LIST_ID_PLAN2: "/api/v1/kyotaku-plan2-list-id",
  GET_LATEST_PLAN2: "/api/v1/kyotaku-plan2-latest",

  GET_VIEW_PLANS_BY_RIYOUSYA: "/api/v1/view-riyousya-plans",

  GET_RIYOU_KAIGOHOKEN_LIST: "/api/v1/riyou-kaigohoken-list",

  SYOKICHI_ITEM: "/api/v1/kiroku-syokichi",
  SYOKICHI_UPDATE: "/api/v1/kiroku-syokichi-insert-update",

  EVENT_PLAN_RIYOUSYA: "/api/v1/event-riyousya-plan",

  //
  REPORT_VITAL_BY_ID: "/api/v1/report-vital/", // {id}
  REPORT_MEAL_BY_ID: "/api/v1/report-meal/", // {id}
  REPORT_SUIBUN_BY_ID: "/api/v1/report-suibun/", // {id}
  REPORT_HAISETU_BY_ID: "/api/v1/report-haisetu/", // {id}
  REPORT_NYUYOKU_BY_ID: "/api/v1/report-nyuyoku/", // {id}
  REPORT_FUKUYAKU_BY_ID: "/api/v1/report-fukuyaku/", // {id}
  REPORT_KAIGO_BY_ID: "/api/v1/report-kaigonaiyou/", // {id}
  REPORT_TOKKI_BY_ID: "/api/v1/report-tokki/", // {id}
  REPORT_FILE_BY_ID: "/api/v1/report-filemanagement/", // {id}

  SETTING_MASTER_ALL: "/api/v1/setting-all", // ?systemId=59
  SETTING_MASTER_ALL_NEW: "/api/v1/setting-new", // ?systemId=59&singleTextTitle=bunsui_set&singleText=sw利用者分類表示設定
  KIHON_ADL_MASTER_ALL: "/api/v1/kihon-adl-list",

  ZIP_MASTER_SEARCH: "/api/v1/zip-master-search", // ?text=730-0021

  RIYOU_SYOKUJISEN_DEL: "/api/v1/riyou-syokujisen/delete", // ?ids="1,2,3,4,5"
  RIYOU_SHOKUJI_DEL: "/api/v1/riyou-shokuji/delete", // ?ids="1,2,3,4,5"
  RIYOU_SYOKUJISEN_INPUT: "/api/v1/riyou-syokujisen/update",
  RIYOU_SHOKUJI_INPUT: "/api/v1/riyou-shokuji/update",

  // 3S UPDATE
  GET_DUTY_REPORT: "/api/v1/duty-report-list",
  CREATE_DUTY_REPORT: "/api/v1/duty-report-insert",
  EDIT_DUTY_REPORT: "/api/v1/duty-report-update",
  STATISTICAL_OUT_IN: "/api/v1/statistical-out-in",
  STATISTICAL_OUT_IN_BYKIND: "/api/v1/statistical-out-in-bykind",
  PEOPLE_OUT_IN_BYKIND: "/api/v1/people-out-in-list-bykind",

  GET_MONITORING_BY_RIYOUSYA: "/api/v1/monitoring-list",
  DELETE_MONITORING: "/api/v1/monitoring",
  GET_MONITORING_BY_ID: "/api/v1/monitoring-by-id",
  UPDATE_MONITORING: "/api/v1/monitoring-update",
  CREATE_MONITORING: "/api/v1/monitoring-insert",

  GET_LATEST_MONITORING: "/api/v1/monitoring-latest",
  GET_LATEST_TANTO_KAIGI: "/api/v1/tanto-kaigi-latest",
  GET_LATEST_INQUIRY_REQUEST: "/api/v1/syoukai-irai-latest",
  GET_LATEST_KEIKA_KIROKU: "/api/v1/keika-kiroku-latest",
  GET_LATEST_WEEKLY_PLAN: "/api/v1/kyotaku-weekly-plan-lastest",
  GET_LATEST_FACESHEET: "/api/v1/facesheet-latest",
  GET_LATEST_KIHON_CHECK: "/api/v1/kihon-check-latest",
  GET_LATEST_YOBOUSIEN_PLAN: "/api/v1/yobousien-plan-latest",
  GET_LATEST_HYOUKAHYOU: "/api/v1/hyoukahyou-latest",

  GET_KEIKA_KIROKU: "/api/v1/keika-kiroku-list",
  DELETE_KEIKA_KIROKU: "/api/v1/keika-kiroku",
  LOAD_KEIKA_KIROKU_BY_DATE: "/api/v1/keika-kiroku-list-by-date",
  GET_KEIKA_KIROKU_BY_ID: "/api/v1/keika-kiroku-get-by-id",
  UPDATE_KEIKA_KIROKU: "/api/v1/keika-kiroku-create", // please add keika kiroku id when update
  CREATE_KEIKA_KIROKU: "/api/v1/keika-kiroku-create", // keika kiroku id is null will create
  GET_LISTID_KEIKA: "/api/v1/keika-kiroku-list-id",
  GET_LISTID_MONITORING: "/api/v1/monitoring-list-id",
  GET_LIST_MONITORING_VIEW: "/api/v1/monitoring-view-list",
  GET_LISTID_TANTOKAIGI: "/api/v1/tanto-kaigi-list-id",

  GET_TANTO_KAIGI_BY_RIYOUSYA: "/api/v1/tanto-kaigi-list-home",
  DELETE_TANTO_KAIGI: "/api/v1/tanto-kaigi",
  LOAD_TANTO_KAIGI_BY_DATE: "/api/v1/tanto-kaigi-list-between-date",
  GET_TANTO_KAIGI_BY_iD: "/api/v1/tanto-kaigi-get-by-id",
  CREATE_TANTO_KAIGI: "/api/v1/create-tanto-kaigi",
  UPDATE_TANTO_KAIGI: "/api/v1/update-tanto-kaigi",
  GET_TANTO_KAIGI_TEMPLATE: "/api/v1/get-tanto-kaigi-template",
  CREATE_TANTO_KAIGI_TEMPLATE: "/api/v1/tanto-kaigi-template-save",
  GET_DATA_KIKAN_MASTER: "/api/v1/service-kikan-master-name",
  GET_DOCTOR_MASTER_LIST: "/api/v1/get-list-doctor-master",
  GET_RIYOU_CONCERN_LIST: "/api/v1/get-list-riyou-concern",
  GET_RIYOU_CONCERN_LIST2: "/api/v1/get-list-riyou-concern2",
  GET_RIYOU_CONCERN_LIST_DETAIL: "/api/v1/get-list-riyou-concern-detail",

  GET_WEEKLY_PLAN_DETAIL_BY_ID: "/api/v1/kyotaku-weekly-plan-get-day",
  GET_WEEKLY_PLAN_TO_QUOTE_TAB: "/api/v1/kyotaku-weekly-plan-list-quote-tab",

  GET_LATEST_TASK: "/api/v1/kadai-seiri-lastest",

  // 病歴
  GET_RIYOU_BYOUREKI_LIST: "/api/v1/get-list-riyou-byoureki",

  // usage-ticket-management
  GET_USAGE_TICKET_MANAGEMENT: "/api/v1/riyouhyou/list-riyouhyou-management",
  DELETE_USAGE_TICKET_MANAGEMENT: "/api/v1/riyouhyou/delete-by-list-id",
  GET_LIST_SYURUI_MASTER: "/api/v1/riyouhyou/get-list-syurui-master",
  GET_LIST_LIST_WEEKLY: "/api/v1/riyouhyou/get-list-weekly",
  SAVE_DATA_EDIT: "/api/v1/riyouhyou/update-riyouhyou-by-id",
  GET_USER_ACTIVE: "/api/v1/riyouhyou/get-list-user-active",
  DELETE_USAGE_TICKET_MANAGEMENT_ACTUAL: "/api/v1/riyouhyou/delete-by-list-id-actual",
  GET_LIST_RIYOUHYOU_QUOTE: "/api/v1/riyouhyou/get-list-user-active-quote",
  GET_LIST_OFFICE_BY_RIYOUHYOU_ID: "/api/v1/riyouhyou/list-office-by-riyouhyou-id",
  GET_LIST_RIYOUHYOU_BY_ID_LIST: "/api/v1/riyouhyou/list-riyouhyou-by-id-list",
  GET_LIST_RIYOUHYOU_QUOTE_USE_SLIP: "/api/v1/riyouhyou/quote-adoptf",
  GET_USER_ACTIVE_QUOTE_DETAIL: "/api/v1/riyouhyou/get-user-active-quote-detail",
  GET_LIST_RIYOUHYOU_QUOTE_BY_NENGETU: "/api/v1/riyouhyou/get-list-riyouhyou-quote-detail",
  GET_LIST_OFFICE_BY_RIYOUHYOU: "/api/v1/riyouhyou/get-list-office-by-riyouhyou",
  GET_LIST_ERRORS: "/api/v1/riyouhyou/get-check-miss-by-riyouhyou",
  SAVE_ALL_USESLIP_KAIGOHOKEN: "/api/v1/riyouhyou/save-useslip-kaigohoken",
  GET_ALL_OFFICE_QUOTE_ACTUAL: "/api/v1/riyouhyou/get-all-office-quote-actual",
  SAVE_QUOTE_ACTUAL_BY_TYPE: "/api/v1/riyouhyou/quote-actual-by-type",
  GET_LIST_QUOTE_WEEKLY: "/api/v1/riyouhyou/get-list-quote-actual",

  // assetment
  ASSESSMENT_MENU_GET: "/api/v1/assessment-menu-get/", // {id}/{sn}
  FAMILY_STATUS_GET: "/api/v1/familysuport-get/", // {id}/{sn}
  FAMILY_STATUS_POST: "/api/v1/familysuport-put/", // {id}/{sn}

  SCHEDULE_GET: "/api/v1/schedule-get/", // {id}/{sn}
  SCHEDULE_POST: "/api/v1/schedule-update/", // {id}/{sn}

  FACE_SHEET_GET: "/api/v1/face-sheet-get/", // {id}
  FACE_SHEET_POST: "/api/v1/face-sheet-put/", // {id}
  HEALTH_STATUS_GET: "/api/v1/health-status-get/", // {id}
  HEALTH_STATUS_POST: "/api/v1/health-status-put/", // {id}
  HISTORY_GET: "/api/v1/history-get/", // {id}
  OVERVIEW_ALL_SUMMARY_GET: "/api/v1/over-all-summary/", // {id}/{sn}
  OVERVIEW_ALL_SUMMARY_UPDATE: "/api/v1/over-all-summary-update/", // {id}/{sn}
  DOCTOR_OPINION_GET: "/api/v1/doctor-opinion/", // {id}/{sn}
  DOCTOR_OPINION_UPDATE: "/api/v1/doctor-opinion-update/", // {id}/{sn}
  COGNITIVE_FUNCTION_GET: "/api/v1/cognitive-function/", // {id}/{sn}
  COGNITIVE_FUNCTION_UPDATE: "/api/v1/cognitive-function-update/", // {id}/{sn}


  HISTORY_INSERT: "/api/v1/history-insert/", // {id}
  HISTORY_DELETE: "/api/v1/history-delete/", // {id}
  CLONE_KAIGOCHOSA: "/api/v1/kaigochosa-clone/", // {id}/{chosaid}
  LIFE_FUNCTION_GET: "/api/v1/life-function-get", // {id}/{sn}
  LIFE_FUNCTION_UPDATE: "/api/v1/life-function-update/", // {id}/{sn}

  LOAD_HOUSING_STATUS: "/api/v1/housing-status",
  UPDATE_HOUSING_STATUS: "/api/v1/housing-status/update/",
  LOAD_SERVICE_STATUS: "/api/v1/service-status",
  UPDATE_SERVICE_STATUS: "/api/v1/service-status/update/",

  LOAD_BASIC_ACTION: "/api/v1/basic-action",
  UPDATE_BASIC_ACTION: "/api/v1/basic-action/update/",

  LOAD_SOCIAL_LIFE: "/api/v1/social-life",
  UPDATE_SOCIAL_LIFE: "/api/v1/social-life/update/",

  LOAD_KAIGOCHOSA: "/api/v1/kaigochosa",
  LOAD_ANSWER: "/api/v1/kaigochosa-answer",
  UPDATE_ANSWER: "/api/v1/kaigochosa-answer/update",
  LOAD_QUESTION: "/api/v1/question-master-list",

  MEDICAL_HEALTH_GET: "/api/v1/medical-health-get/", // {id}/{sn}
  MEDICAL_HEALTH_UPDATE: "/api/v1/medical-health-update/"// {id}/{sn}
};
