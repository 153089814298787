import React from "react";
import { Row, Col, Divider } from "antd";
import LineBreakText from "../../../common/component/LineBreakText";

const KusuriInfo = (props) => {
  return (
    <div>
      {props.riyousya &&
      props.riyousya.riyouKusuri &&
      props.riyousya.riyouKusuri.length > 0
        ? props.riyousya.riyouKusuri.map((item, index) => {
            if (item.stop === 0) {
              return (
                <Row key={index}>
                  <Col sm={8}>
                    <span className="rdp-basic-tab-title">{item.yakumei}</span>
                  </Col>
                  <Col sm={16}>
                    <span className="rdp-basic-tab-content">
                      <LineBreakText text={item.bikou ? item.bikou : "　"} />
                    </span>
                  </Col>
                  <Divider />
                </Row>
              );
            } else {
              return null;
            }
          })
        : null}
    </div>
  );
};

export default KusuriInfo;
