import { UseSlipActionType } from "../action_types";
import { CommonUtils } from "../../utils/index";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { PURGE } from "redux-persist";

function useslip(state = {}, action) {
  switch (action.type) {
    case UseSlipActionType.GET_ALL_USESLIP_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }
    case UseSlipActionType.GET_ALL_USESLIP_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.data || [],
        metaData: CommonUtils.pagination(action.data.meta),
        reload: false,
        type: "",
      };
    }
    case UseSlipActionType.GET_ALL_USESLIP_FAILURE: {
      return {
        ...state,
        data: [],
        metaData: {},
        loading: false,
        type: "",
      };
    }
    case UseSlipActionType.GET_RIYOUHYOU_BY_ID: {
      return {
        ...state,
        loading: true,
        isLoadRiyouhyouSuccess: false,
      };
    }
    case UseSlipActionType.GET_RIYOUHYOU_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        riyouhyou: action.data,
        isLoadRiyouhyouSuccess: true,
        riyouhyouForm: { ...state.riyouhyouForm, comment: action.data.comment, jissekiComment: action.data.jissekiComment, adoptf: !!action.data.adoptf, tantoId: action.data.tantoId },
      };
    }
    case UseSlipActionType.GET_RIYOUHYOU_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        riyouhyou: {},
      };
    }
    case UseSlipActionType.GET_LIST_SERVICE_TYPE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UseSlipActionType.GET_LIST_SERVICE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        listServiceType: action.data,
      };
    }
    case UseSlipActionType.GET_LIST_SERVICE_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        listServiceType: [],
      };
    }
    case UseSlipActionType.GET_ALL_USESLIP_QUOTE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UseSlipActionType.GET_SERVICE_CONTENT_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case UseSlipActionType.GET_ALL_USESLIP_QUOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        listUseSlipQuote: action.data,
      };
    }
    case UseSlipActionType.GET_ALL_USESLIP_QUOTE_FAILURE: {
      return {
        ...state,
        loading: false,
        listUseSlipQuote: [],
      };
    }
    case UseSlipActionType.REMOVE_USESLIP_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "remove",
      };
    }
    case UseSlipActionType.REMOVE_USESLIP_SUCCESS: {
      CommonUtils.openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
        dataDelete: action.data,
        reload: true,
        type: "",
      };
    }
    case UseSlipActionType.REMOVE_USESLIP_FAILURE: {
      CommonUtils.openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    case UseSlipActionType.GET_SERVICE_CONTENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceContent: action.data,
      };
    case UseSlipActionType.GET_SERVICE_CONTENT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        serviceContent: {},
      };

    case UseSlipActionType.GET_SERVICE_ADDITION_BY_TYPE:
      return {
        ...state,
        loading: true,
      };

    case UseSlipActionType.GET_SERVICE_ADDITION_BY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceAddition: action.data,
      };
    case UseSlipActionType.GET_SERVICE_ADDITION_BY_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        serviceAddition: {},
      };
    case UseSlipActionType.GET_SYURUI_CD:
      return {
        ...state,
        loading: true,
      };

    case UseSlipActionType.GET_SYURUI_CD_SUCCESS:
      return {
        ...state,
        loading: false,
        syuruiCdRyaku: action.data,
      };
    case UseSlipActionType.GET_SYURUI_CD_FAILURE:
      return {
        ...state,
        loading: false,
        syuruiCdRyaku: {},
      };
    case UseSlipActionType.GET_SERVICE_CODE:
      return {
        ...state,
        loading: true,
        loadingServiceCode: true,
      };
    case UseSlipActionType.GET_SERVICE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceCode: action.data,
        isCheckAddRecord: action.type,
        loadingServiceCode: false,
      };
    case UseSlipActionType.GET_SERVICE_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        serviceCode: [],
        loadingServiceCode: false,
      };
    case UseSlipActionType.GET_EXPAND_CODE:
      return {
        ...state,
        loading: true,
        loadingServiceCodeExpand: true,
      };
    case UseSlipActionType.GET_EXPAND_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        expandCode: action.data,
        loadingServiceCodeExpand: false,
      };
    case UseSlipActionType.GET_EXPAND_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        expandCode: [],
        loadingServiceCodeExpand: false,
      };
    case UseSlipActionType.GET_SERVICE_ADDITION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_SERVICE_ADDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        listServiceAddition: action.data,
      };
    case UseSlipActionType.GET_SERVICE_ADDITION_FAILURE:
      return {
        ...state,
        loading: false,
        listServiceAddition: [],
      };
    case UseSlipActionType.GET_SERVICE_ADDITION_BY_OFFICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_SERVICE_ADDITION_BY_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        officeInfo: action.data,
      };
    case UseSlipActionType.GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_SUCCESS:
      return {
        ...state,
        loading: false,
        listServiceContent: action.data,
      };
    case UseSlipActionType.GET_SERVICE_CONTENT_BY_TYPE_AND_SYURUI_FAILURE:
      return {
        ...state,
        loading: false,
        listServiceContent: [],
      };
    case UseSlipActionType.GET_SERVICE_COLOR_MASTER_USESLIP_REQUEST:
      return {
        ...state,
        loadingColor: true,
        isGetColorMaster: false,
      };
    case UseSlipActionType.GET_SERVICE_COLOR_MASTER_USESLIP_SUCCESS:
      return {
        ...state,
        loadingColor: false,
        listColorMaster: action.data,
        isGetColorMaster: true,
      };
    case UseSlipActionType.GET_SERVICE_COLOR_MASTER_USESLIP_FAILURE:
      return {
        ...state,
        loadingColor: false,
        listColorMaster: [],
        isGetColorMaster: false,
      };
    case UseSlipActionType.GET_SERVICE_COLOR_MASTER_SONOTA_REQUEST:
      return {
        ...state,
        loadingColor: true,
      };
    case UseSlipActionType.GET_SERVICE_COLOR_MASTER_SONOTA_SUCCESS:
      return {
        ...state,
        loadingColor: false,
        listColorSonota: action.data,
      };
    case UseSlipActionType.GET_SERVICE_COLOR_MASTER_SONOTA_FAILURE:
      return {
        ...state,
        loadingColor: false,
        listColorSonota: [],
      };
    case UseSlipActionType.GET_NAIYOU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_NAIYOU_SUCCESS:
      return {
        ...state,
        loading: false,
        naiyou: action.data,
      };
    case UseSlipActionType.GET_NAIYOU_FAILURE:
      return {
        ...state,
        loading: false,
        naiyou: [],
      };
    case UseSlipActionType.GET_DUPLICATE_CHECK_NOT_POSSIBLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_DUPLICATE_CHECK_NOT_POSSIBLE_SUCCESS:
      return {
        ...state,
        loading: false,
        additionDuplicate: action.data,
      };
    case UseSlipActionType.GET_DUPLICATE_CHECK_NOT_POSSIBLE_FAILURE:
      return {
        ...state,
        loading: false,
        additionDuplicate: [],
      };
    case UseSlipActionType.GET_INFORMATION_KAIGOHOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_INFORMATION_KAIGOHOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        // loadingView: false,
        kaigohoken: action.data,
      };
    case UseSlipActionType.GET_INFORMATION_KAIGOHOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        kaigohoken: {},
      };
    case UseSlipActionType.UPDATE_RIYOUHYOU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.UPDATE_RIYOUHYOU_SUCCESS:
      CommonUtils.openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        isCheckSuccess: action.type,
        idCreated: action.data.id,
      };
    case UseSlipActionType.SET_RIYOUHYOU_UNSUCESS:
      return {
        ...state,
        isCheckSuccess: null,
      };
    case UseSlipActionType.UPDATE_RIYOUHYOU_FAILURE:
      return {
        ...state,
        loading: false,
        riyouhyou: {},
      };
    // case UseSlipActionType.REMOVE_ADDITION_OFFICE:
    //   return {
    //     ...state,
    //
    //     officeInfo: [],
    //   };
    // case UseSlipActionType.REMOVE_ADDITION_DUPLICATE:
    //   return {
    //     ...state,
    //
    //     additionDuplicate: [],
    //   };
    case UseSlipActionType.GET_HOLIDAY_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_HOLIDAY_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        holidayMaster: action.data,
      };
    case UseSlipActionType.GET_HOLIDAY_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
        holidayMaster: [],
      };

    case UseSlipActionType.REMOVE_RIYOUHYOU:
      return {
        ...state,
        loading: false,
        riyouhyou: {},
        riyouhyouView: {},
      };
    case UseSlipActionType.GET_RIYOYHYOU_IDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_RIYOYHYOU_IDS_SUCCESS:
      return {
        ...state,
        loading: false,
        riyouhyouIds: action.data,
      };
    case UseSlipActionType.GET_RIYOYHYOU_IDS_FAILURE:
      return {
        ...state,
        loading: false,
        riyouhyouIds: [],
      };
    case UseSlipActionType.REMOVE_NAIYOU:
      return {
        ...state,
        loading: false,
        naiyou: "",
      };
    case UseSlipActionType.GET_SERVICE_ADDITION_REQUEST_OLD:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_SERVICE_ADDITION_SUCCESS_OLD:
      return {
        ...state,
        loading: false,
        listServiceAddition: action.data,
      };
    case UseSlipActionType.GET_SERVICE_ADDITION_FAILURE_OLD:
      return {
        ...state,
        loading: false,
        listServiceAddition: [],
      };

    case UseSlipActionType.REMOVE_EXPAND_CODE:
      return {
        ...state,
        loading: false,
        expandCode: [],
      };
    case UseSlipActionType.GET_WEEKLY_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_WEEKLY_QUOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_WEEKLY_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        weeklySchedule: action.data,
      };
    case UseSlipActionType.GET_WEEKLY_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        weeklyQuote: action.data,
      };  
    case UseSlipActionType.GET_WEEKLY_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        weeklySchedule: [],
      };
    case UseSlipActionType.GET_ATTACHED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UseSlipActionType.GET_ATTACHED_SUCCESS:
      return {
        ...state,
        loading: false,
        listAttached: action.data,
      };
    case UseSlipActionType.GET_ATTACHED_FAILURE:
      return {
        ...state,
        loading: false,
        listAttached: [],
      };
    case UseSlipActionType.SET_RIYOUHYOUFORM:
      return {
        ...state,
        riyouhyouForm: { ...state.riyouhyouForm, ...action.data },
      };
    case UseSlipActionType.SET_RIYOUHYOUFORM_SUCESS:
      return {
        ...state,
      };
    case UseSlipActionType.REMOVE_RIYOUHYOUFORM:
      return {
        ...state,
        renzokuData: null,
        kyufuData: null,
        isQuoteWeekly: false,
        weeklySchedule: [],
        isLoadRiyouhyouSuccess: false,
        url: null,
        isGetOverProcess: false,
        isGetOverProcessActual: false,
        listDataAttached: null,
        listDataServiceSonota: [],
        kaigohoken: {},
        riyouhyouView: {},
        urlUseSlipPrintPDF: null,
        riyouhyouForm: {
          id: null,
          riyousyaId: null,
          riyouhyouDate: null,
          sid: null,
          nengetu: null,
          hokensyaNo: null,
          hihokenNo: null,
          kaigodoName: null,
          yukoDateFrom: null,
          tekiyouDate: null,
          yukoDateTo: null,
          tantoId: null,
          adoptf: false,
          kaigoHokenId: null,
          comment: null,
          douiDate: null,
          listDataService: [],
          listDataNotParent: [],
          listDataShow: [],
          formValuesDouble: null,
          kubunsikyu: null,
          tankiDays: null,
          listDataServiceSonota: [],
          smallTotal: null,
          listHistory: [],
          keisanKubun: null
        },
      };
    case UseSlipActionType.GET_URL:
      return { ...state, url: action.url };
    case UseSlipActionType.IS_LOADING: //loading
      return { ...state, loading: true };
    case UseSlipActionType.STOP_LOADING:
      return { ...state, loading: false };
    case UseSlipActionType.GET_RENZOKU_LAST_MONTH_REQUEST: //loading: 3 => removed loading : true
      return { ...state };
    case UseSlipActionType.GET_RENZOKU_LAST_MONTH_SUCESS:
      return { ...state, renzokuData: action.data };
    case UseSlipActionType.GET_RENZOKU_LAST_MONTH_FAIL:
      return { ...state, loading: false };
    case UseSlipActionType.GET_OVER_PROCESS:
      return { ...state, isGetOverProcess: action.data };
    case UseSlipActionType.GET_KYUFUDAYS_REQUEST:
      return { ...state, loading: true };
    case UseSlipActionType.GET_KYUFUDAYS_SUCCESS:
      return { ...state, kyufuData: action.data, loading: false };
    case UseSlipActionType.GET_KYUFUDAYS_FAILURE:
      return { ...state, loading: false };
    case UseSlipActionType.SET_LIST_DATA_SERVICE_SONOTA:
      return { ...state, listDataServiceSonota: action.data };
    case UseSlipActionType.SET_LIST_ATTACHED:
      return { ...state, listDataAttached: action.data };
    case UseSlipActionType.GET_OVER_PROCESS_ACTUAL:
      return { ...state, isGetOverProcessActual: action.data };
    case UseSlipActionType.GET_LIST_RIYOUHYOU_QUOTES_REQUEST: //loading: 2 => removed loading : true
      return { ...state };
    case UseSlipActionType.GET_LIST_RIYOUHYOU_QUOTES_SUCCESS:
      return { ...state, listRiyouhyouQuotes: action.data };
    case UseSlipActionType.GET_LIST_RIYOUHYOU_QUOTES_FAILURE:
      return { ...state };
    case UseSlipActionType.GET_RIYOUHYOU_QUOTE_BY_ID_REQUEST:
      return { ...state, loading: true, isGetDataQuote: true };
    case UseSlipActionType.GET_RIYOUHYOU_QUOTE_BY_ID_SUCCESS:
      return { ...state, riyouhyouQuote: action.data };
    case UseSlipActionType.GET_RIYOUHYOU_QUOTE_BY_ID_FAILURE:
      return { ...state, loading: false };
    case UseSlipActionType.GET_RIYOUHYOU_LAST_QUOTE_BY_ID_REQUEST:
      return { ...state, loading: true, isGetDataQuote: true };
    case UseSlipActionType.GET_RIYOUHYOU_LAST_QUOTE_BY_ID_SUCCESS:
      return { ...state, riyouhyouLastQuote: action.data };
    case UseSlipActionType.GET_RIYOUHYOU_LAST_QUOTE_BY_ID_FAILURE:
      return { ...state };
    case UseSlipActionType.CLEAR_RIYOUHYOU_QUOTE:
      return { ...state, riyouhyouQuote: null, riyouhyouLastQuote: null };
    case UseSlipActionType.GET_RIYOUHYOU_OF_RENZOKU_DAY_REQUEST: //loading: 4 => removed loading : true
      return { ...state };
    case UseSlipActionType.GET_RIYOUHYOU_OF_RENZOKU_DAY_SUCCESS:
      return { ...state, riyouhyouOfRenzoku: action.data };
    case UseSlipActionType.GET_ALL_USESLIP_QUOTE_FAILURE:
      return { ...state, loading: false };
    case UseSlipActionType.GET_URL_FROM_USE_SLIP:
      return { ...state, urlUseSlip: action.data };
    case UseSlipActionType.REMOVE_URL_FROM_USE_SLIP:
      return { ...state, urlUseSlip: null };
    case UseSlipActionType.CLEAR_RIYOUHYOU: {
      return { ...state, riyouhyou: {} };
    }
    case UseSlipActionType.SET_LIST_HISTORY:
      return { ...state, listHistory: action.data };
    case UseSlipActionType.GET_URL_FROM_MANAGEMENT:
      return { ...state, isStartManagement: action.data };
    case UseSlipActionType.SET_LIST_MANAGEMENT:
      return {
        ...state,
        listManagement: action.data,
        // loading: false,
        // reload: false,
      };
    case UseSlipActionType.GET_ALL_SYURUI_MASTER_REQUEST:
      return { ...state, loading: true };
    case UseSlipActionType.SET_OLD_PARAMS:
      return { ...state, oldParams: action.data };
    case UseSlipActionType.GET_ALL_SYURUI_MASTER_SUCCESS:
      return { ...state, loading: false, listSyuruiList: action.dataReduce };
    case UseSlipActionType.GET_ALL_SYURUI_MASTER_FAILURE:
      return { ...state, loading: false };
    case UseSlipActionType.GET_RIYOUHYOU_VIEW_BY_ID_REQUEST:
      return { ...state, loadingView: true };
    case UseSlipActionType.GET_RIYOUHYOU_VIEW_BY_ID_SUCCESS:
      return { ...state, loadingView: false, riyouhyouView: action.data };
    case UseSlipActionType.GET_RIYOUHYOU_VIEW_BY_ID_FAILURE:
      return { ...state, loadingView: false };
    case UseSlipActionType.STOP_LOADING_VIEW:
      return { ...state, loadingView: false };
    case UseSlipActionType.REMOVE_RIYOUHYOU_VIEW:
      return { ...state, riyouhyouView: {} };
    case UseSlipActionType.DUPLICATE_MONTH_LIST_MANAGEMENT:
      return { ...state, isDuplicateMonthListManagement: action.data };
    case UseSlipActionType.SET_LIST_OFFICE_ACTUAL:
      return { ...state, listOfficeActual: action.data };
    case UseSlipActionType.SET_LIST_OFFICE_ACTUAL_CHECKED:
      return { ...state, listOfficeActualChecked: action.data };
    case UseSlipActionType.SET_LIST_WEEKLY_QUOTE_ACTUAL:
      return { ...state, loading: false, listWeeklyQuote: action.data };
    case UseSlipActionType.COPY_DATA_QUOTE_ACTUAL:
      return { ...state, loading: false, isQuoteWeekly: action.data }
    case UseSlipActionType.GET_WEEKLY_QUOTE_FAILURE:
      return { ...state, loading: false };
    case UseSlipActionType.SAVE_URL_USESLIP:
      return { ...state, urlUseSlipPrintPDF: action.data }
    case UseSlipActionType.SAVE_SELECT_KEY:
      return { ...state, selectedRowKeys: action.data}
    default:
      return state;
  }
}

export default useslip;
