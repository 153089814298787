export const LOAD_MONITORING_SUCCESS = "LOAD_MONITORING_SUCCESS";
export const LOAD_MONITORING_ERROR = "LOAD_MONITORING_ERROR";

export const DELETE_MONITORING_SUCCESS = "DELETE_MONITORING_SUCCESS";
export const DELETE_MONITORING_ERROR = "DELETE_MONITORING_ERROR";

export const LOAD_MONITORING_BY_ID_SUCCESS = "LOAD_MONITORING_BY_ID_SUCCESS";
export const LOAD_MONITORING_BY_ID_ERROR = "LOAD_MONITORING_BY_ID_ERROR";

export const CREATE_MONITORING_SUCCESS = "CREATE_MONITORING_SUCCESS";
export const UPDATE_MONITORING_SUCCESS = "UPDATE_MONITORING_SUCCESS";

export const CREATE_MONITORING_ERROR = "CREATE_MONITORING_ERROR";
export const UPDATE_MONITORING_ERROR = "UPDATE_MONITORING_ERROR";

export const LOAD_SPIN_REQUEST = "LOAD_SPIN_REQUEST";
export const GET_RESULT_MONITORING = "GET_RESULT_MONITORING";
export const CHANGE_EXPANDED_ROW_KEY = "CHANGE_EXPANDED_ROW_KEY";
export const GET_ROWINFO_MONITORING = "GET_ROWINFO_MONITORING";
export const UPDATE_ROWS_MONITORING = "UPDATE_ROWS_MONITORING";
export const CHANGE_EDIT_MODE = "CHANGE_EDIT_MODE";
export const UPDATE_OPTION_OF_MONITORING = "UPDATE_OPTION_OF_MONITORING";
export const RESET_STATUS = "RESET_STATUS";
export const QUOTE_PLAN2_TO_ROWS = "QUOTE_PLAN2_TO_ROWS";
export const UPDATE_MONITORING_HYOKAS = "UPDATE_MONITORING_HYOKAS";
export const ADD_NEW_MONITORING_HYOKA = "ADD_NEW_MONITORING_HYOKA";
export const KEEP_VALUE_MONITORING = "KEEP_VALUE_MONITORING";
export const LOAD_MONITORING_SPIN = "LOAD_MONITORING_SPIN";
export const REMOVE_MONITORING_HYOKA = "REMOVE_MONITORING_HYOKA";
