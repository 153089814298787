import { connect } from "react-redux";

import { SettingMasterAllCreator } from "../../actions/SettingMasterActions/actionCreators";

import {
  riyousyaShiryouCreator,
  updateFileCreator,
  insertFileCreator,
  fileDetailCreator,
  deleteFileCreator,
  riyousyaDetailCreator,
} from "../../actions/riyousyaActions/actionCreators";

import {
  downloadCreator,
  loadFilePreview,
} from "../../actions/fileManagementActions/actionCreators";

import RiyousyaShiryou from "./riyousyaShiryou";
import AddEdit from "./AddEdit";

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    riyousyaDetail: state.riyousya.detail,

    setting: state.settingMaster.setting,
    shiryou: state.riyousya.shiryou,
    loading: state.riyousya.loading,
    page: state.riyousya.page,
    page_total: state.riyousya.page_total,
    fdetail: state.riyousya.fdetail,
    loadingUpload: state.riyousya.loadingUpload,
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getRiyousya: (riyousyaId) => dispatch(riyousyaDetailCreator(riyousyaId)),

    loadSetting: (callback) => dispatch(SettingMasterAllCreator(callback)),
    loadData: (pageNo, riyousyaId, processType, selectKubun) =>
      dispatch(
        riyousyaShiryouCreator(pageNo, riyousyaId, processType, selectKubun)
      ),

    updateFile: (params, callback) =>
      dispatch(updateFileCreator(params, callback)),
    insertFile: (params, callback) =>
      dispatch(insertFileCreator(params, callback)),
    fileDetail: (fileManagementId) =>
      dispatch(fileDetailCreator(fileManagementId)),
    deleteFile: (fileManagementId, callback) =>
      dispatch(deleteFileCreator(fileManagementId, callback)),

    /**
     * Donload actions
     *
     */
    download: (fileId, fileName) => dispatch(downloadCreator(fileId, fileName)),
    loadFilePreview: (params, callback) =>
      dispatch(loadFilePreview(params, callback)),
  };
};

export const RiyousyaShiryouContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RiyousyaShiryou);
export const InputRiyousyaShiryouContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEdit);
