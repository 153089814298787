export const LOAD_KEIKA_KIROKU_SUCCESS = "LOAD_KEIKA_KIROKU_SUCCESS";
export const LOAD_KEIKA_KIROKU_ERROR = "LOAD_KEIKA_KIROKU_ERROR";

export const DELETE_KEIKA_KIROKU_SUCCESS = "DELETE_KEIKA_KIROKU_SUCCESS";
export const DELETE_KEIKA_KIROKU_ERROR = "DELETE_KEIKA_KIROKU_ERROR";

export const LOAD_KEIKA_KIROKU_BY_ID_SUCCESS =
  "LOAD_KEIKA_KIROKU_BY_ID_SUCCESS";
export const LOAD_KEIKA_KIROKU_BY_ID_ERROR = "LOAD_KEIKA_KIROKU_BY_ID_ERROR";
export const LOAD_SPIN_REQUEST = "LOAD_SPIN_REQUEST";
export const LOAD_KEIKA_KIROKU_BY_DATE_SUCCESS =
  "LOAD_KEIKA_KIROKU_BY_DATE_SUCCESS";
export const LOAD_KEIKA_KIROKU_BY_DATE_ERROR =
  "LOAD_KEIKA_KIROKU_BY_DATE_ERROR";

export const CREATE_KEIKA_KIROKU_SUCCESS = "CREATE_KEIKA_KIROKU_SUCCESS";
export const CREATE_KEIKA_KIROKU_ERROR = "CREATE_KEIKA_KIROKU_ERROR";

export const UPDATE_KEIKA_KIROKU_SUCCESS = "UPDATE_KEIKA_KIROKU_SUCCESS";
export const UPDATE_KEIKA_KIROKU_ERROR = "UPDATE_KEIKA_KIROKU_ERROR";
export const LOAD_KEIKA_KIROKU_SPIN = "LOAD_KEIKA_KIROKU_SPIN";
export const RESET_CURRENT_KEIKA_KIROKU = "RESET_CURRENT_KEIKA_KIROKU";

export const GET_IDS_REQUEST = "GET_IDS_REQUEST";
export const GET_IDS_SUCCESS = "GET_IDS_SUCCESS";
export const GET_IDS_ERROR = "GET_IDS_ERROR";
