import { Button, Checkbox, Modal, Table } from "antd";
import React from "react";
// import PopoverKaigoNaiyou from "./PopoverKaigoNaiyou";
import { guid } from "../../../common/timePicker/formatTimePicker";
import { PopoverKaigoNaiyou } from "./PopoverKaigoNaiyou";
class KaigoNaiyouModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visiblePopover: false,
      dataDaily: [],
      timeRow: this.props.timeRow,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dataDetail &&
      this.props.dataDetail !== prevProps.dataDetail
    ) {
      const { newDailyPlanDetails } = this.props.dataDetail;
      const foundItem = newDailyPlanDetails.find(
        (item) => item.time === this.props.timeRow
      );
      const kaigoNaiyous =
        foundItem && foundItem.kaigoNaiyous ? foundItem.kaigoNaiyous || [] : [];

      this.setState({
        dataDaily: [...kaigoNaiyous],
        timeRow: this.props.timeRow,
      });
    }
  }

  addData() {
    const newData = [...this.state.dataDaily];
    newData.push({ code: "", name: "", kyotuf: 0 });
    this.setState({ dataDaily: newData });
  }

  removeKaigoNaiyou(index) {
    const dataClone = [...this.state.dataDaily];
    dataClone.splice(index, 1);
    this.setState({ dataDaily: dataClone });
  }

  onChangeKyotuf(value, index) {
    const dataClone = [...this.state.dataDaily];
    dataClone[index].kyotuf = value ? 1 : 0;
    this.setState({ dataDaily: dataClone });
  }

  handleCancel() {
    this.props.hideModalKaigo();
    this.setState({
      visiblePopover: false,
    });
  }
  handleOk() {
    this.props.dataNaiyouModal(this.state.dataDaily);
    this.props.hideModalKaigo();
  }
  handlePopover(index) {
    this.setState({
      visiblePopover: true,
      rowIndex: index,
    });
  }
  hanleVisible = (visible) => {
    this.setState({
      visiblePopover: visible,
    });
  };
  handleClose() {
    // this.search();

    this.setState({
      visiblePopover: false,
    });
  }

  selectRecordKaigo(rc) {
    const { rowIndex, dataDaily } = this.state;
    const dataClone = [...dataDaily];

    dataClone[rowIndex].name = rc.kaigoNaiyou;
    dataClone[rowIndex].code = rc.kaigoNaiyouCode;
    this.setState({
      dataDaily: dataClone,
      visiblePopover: false,
    });
  }

  render() {
    const footer = (
      <div>
        <Button className={"cancelBtn"} onClick={() => this.handleCancel()}>
          <span>キャンセル</span>
        </Button>
        <Button
          className="pljp-btn pljp-btn-primary"
          onClick={() => this.handleOk()}
        >
          <span>OK</span>
        </Button>
      </div>
    );
    const headerModal = (
      <div>
        <span>{this.state.timeRow}</span>
        <Button onClick={() => this.addData()} style={{ float: "right" }}>
          追加
        </Button>
      </div>
    );
    const columns = [
      {
        title: "",
        width: "30%",
        dataIndex: "kyotuf",
        render: (text, record, index) => (
          <div>
            <Checkbox
              checked={record.kyotuf === 1}
              onChange={(e) => this.onChangeKyotuf(e.target.checked, index)}
            >
              共通
            </Checkbox>
          </div>
        ),
      },
      {
        title: "",
        width: "70%",
        dataIndex: "name",
        render: (text, record, index) => (
          <div>
            <span>{record.name}</span>
            <span style={{ float: "right" }}>
              <Button
                onClick={() => this.handlePopover(index)}
                className={"btn-popover"}
              >
                M
              </Button>
              <Button
                onClick={() => this.removeKaigoNaiyou(index)}
                className={"btn-remove-popover"}
              >
                -
              </Button>
            </span>
          </div>
        ),
      },
    ];

    return (
      (<div>
        <Modal
          getContainer={() =>
            document.getElementById("input-kyotaku-dailyplan-page")
          }
          closable={false}
          onCancel={() => this.handleCancel()}
          open={this.props.visible}
          title={headerModal}
          footer={footer}
        >
          <Table
            rowKey={guid()}
            showHeader={false}
            columns={columns}
            bordered={true}
            size={"small"}
            pagination={false}
            dataSource={this.state.dataDaily}
            className={"table-popover"}
          />
        </Modal>
        <PopoverKaigoNaiyou
          visiblePopover={this.state.visiblePopover}
          handleVisible={(visible) => this.hanleVisible(visible)}
          selectRecordKaigo={(rc) => this.selectRecordKaigo(rc)}
          handleClose={() => this.handleClose()}
        />
      </div>)
    );
  }
}
export default KaigoNaiyouModal;
