export const RiyousyaYobouActionTypes = {
  GET_JIGYOUSYO_ID_YOBOU_REQUEST: "GET_ALL_JIGYOUSYO_ID_YOBOU_REQUEST",
  GET_JIGYOUSYO_ID_YOBOU_SUCCESS: "GET_ALL_JIGYOUSYO_ID_YOBOU_SUCCESS",
  GET_JIGYOUSYO_ID_YOBOU_FAILURE: "GET_ALL_JIGYOUSYO_ID_YOBOU_FAILURE",

  GET_SERVICEKIKAN_YOBOU_REQUEST: "GET_SERVICEKIKAN_YOBOU_REQUEST",
  GET_SERVICEKIKAN_YOBOU_SUCCESS: "GET_SERVICEKIKAN_YOBOU_SUCCESS",
  GET_SERVICEKIKAN_YOBOU_FAILURE: "GET_SERVICEKIKAN_YOBOU_FAILURE",

};
