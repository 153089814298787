import React from "react";
import { Col, Row, Table } from "antd";
import MediaQuery from "react-responsive";
import LineBreakText from "../../../common/component/LineBreakText";

class ViewPlan1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const columnTable1 = [
      {
        className: "title-view-tb",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "35%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "35%",
      },
    ];
    const columnTable2 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "20%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "50%",
      },
    ];
    const columnTable3 = [
      {
        className: "",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content",
        width: "20%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title2",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content2",
        width: "20%",
      },
      {
        className: "",
        title: "",
        dataIndex: "title3",
        width: "15%",
      },
      {
        className: "",
        title: "",
        dataIndex: "content3",
        width: "15%",
      },
    ];
    const columnTable4 = [
      {
        className: "title-view1",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "content",
        width: "85%",
        className: "content-tb1",
        render: (text) => <LineBreakText text={text} />,
      },
    ];
    const columnTable5 = [
      {
        className: "title-view1",
        title: "",
        dataIndex: "title",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "content",
        width: "85%",
        className: "content-tb1 table-cell-wordbreak",
        render: (text) => <LineBreakText text={text} />,
      },
    ];
    let riyu = null;
    if (this.props.data && this.props.data.riyu) {
      if (this.props.data.riyu === 1) {
        riyu = "一人暮らし";
      } else if (this.props.data.riyu === 2) {
        riyu = "家族等が障害、疾病等";
      } else if (this.props.data.riyu === 3) {
        riyu = "その他";
      }
      if (this.props.data.riyuHoka) {
        riyu = riyu + "(" + this.props.data.riyuHoka + ")";
      }
    } else {
      riyu = "　";
    }
    const dataTable1 = [
      {
        title: "計画作成者",
        content:
          this.props.data && this.props.data.tantoName
            ? this.props.data.tantoName
            : "　",
        title2: "区分",
        content2:
          this.props.data && this.props.data.kubun !== ""
            ? this.props.data.kubun
            : "　",
      },
    ];
    const dataTable2 = [
      {
        title: "要介護度",
        content:
          this.props.data && this.props.data.kaigodoName
            ? this.props.data.kaigodoName
            : "　",
        title2: "有効期間",
        content2:
          (this.props.data && this.props.data.yukoDateFrom
            ? this.props.data.yukoDateFrom
            : "　") +
          (this.props.data && this.props.data.yukoDateTo
            ? "~" + this.props.data.yukoDateTo
            : "　"),
      },
    ];
    const dataTable3 = [
      {
        title: "計画作成日",
        content:
          this.props.data && this.props.data.planDate
            ? this.props.data.planDate
            : "　",
        title2: "初回作成日",
        content2:
          this.props.data && this.props.data.firstPlanDate
            ? this.props.data.firstPlanDate
            : "　",
        title3: "同意年月日",
        content3:
          this.props.data && this.props.data.plan1DouiDate
            ? this.props.data.plan1DouiDate
            : "　",
      },
    ];
    const dataTable4 = [
      {
        className: "content-tableview",
        title: "利用者及び家族の生活に対する意向",
        content:
          this.props.data && this.props.data.ikou ? this.props.data.ikou : "　",
      },
      {
        title: "介護認定審査会の意見及びサービス種類の指定",
        content:
          this.props.data && this.props.data.iken ? this.props.data.iken : "　",
      },
      {
        title: "総合的な援助の方針",
        content:
          this.props.data && this.props.data.housin
            ? this.props.data.housin
            : "　",
      },
      {
        title: "生活支援中心型の算定理由",
        content: riyu,
      },
    ];
    const dataTable5 = [
      {
        title: "コメント",
        content:
          this.props.data && this.props.data.comment
            ? this.props.data.comment
            : "　",
      },
    ];
    return (
      <MediaQuery query="(min-device-width: 768px)">
        {(matches) => {
          if (matches) {
            return (
              <div id={"view-plan1-container"}>
                <div
                  style={{
                    float: "right",
                    fontWeight: "bold",
                    margin: "5px 0px",
                  }}
                >
                  {this.props.data && this.props.data.progressF === 1
                    ? "最新"
                    : "　"}
                </div>
                <Table
                  bordered={false}
                  className={"view-plan-table1"}
                  pagination={false}
                  showHeader={false}
                  columns={columnTable1}
                  dataSource={dataTable1}
                />
                <Table
                  className={"view-plan-table2"}
                  pagination={false}
                  showHeader={false}
                  columns={columnTable2}
                  dataSource={dataTable2}
                />
                <Table
                  className={"view-plan-table3"}
                  pagination={false}
                  showHeader={false}
                  columns={columnTable3}
                  dataSource={dataTable3}
                />
                <Table
                  style={{ marginTop: "20px" }}
                  className={"view-plan-table4"}
                  pagination={false}
                  showHeader={false}
                  columns={columnTable4}
                  dataSource={dataTable4}
                />
                <Table
                  bordered={false}
                  style={{ margin: "20px 0px" }}
                  className={"view-plan-table4"}
                  pagination={false}
                  showHeader={false}
                  columns={columnTable5}
                  dataSource={dataTable5}
                />
              </div>
            );
          }
          return (
            <div className="view-plan1-container-m">
              <Row className="row-content">
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>計画作成日</span>
                </Col>
                <Col className="input-value" span={6}>
                  <label>
                    {this.props.data && this.props.data.planDate
                      ? this.props.data.planDate
                      : "　"}
                  </label>
                </Col>
                <Col
                  style={{ float: "right", borderBottom: "1px solid" }}
                  span={3}
                >
                  <label>
                    {this.props.data && this.props.data.progressF === 1
                      ? "最新"
                      : "　"}
                  </label>
                </Col>
              </Row>
              <Row className="row-content">
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>初回作成日</span>
                </Col>
                <Col className="input-value" span={6}>
                  <label>
                    {this.props.data &&
                    this.props.data.firstPlanDate &&
                    this.props.data.firstPlanDate !== ""
                      ? this.props.data.firstPlanDate
                      : "　"}
                  </label>
                </Col>
              </Row>
              <Row className="row-content">
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>計画作成者</span>
                </Col>
                <Col className="input-value" span={6}>
                  <label>
                    {this.props.data ? this.props.data.tantoName : "　"}
                  </label>
                </Col>
                <Col style={{ float: "right", borderBottom: "1px solid" }}>
                  <label>
                    {this.props.data && this.props.data.kubun !== ""
                      ? this.props.data.kubun
                      : "　"}
                  </label>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>介護保 険情報</span>
                </Col>
                <Col span={6} style={{ margin: " 0px 10px" }}>
                  <label>
                    {this.props.data ? this.props.data.kaigodoName : "　"}
                  </label>
                </Col>
              </Row>
              <Row style={{ margin: "5px 0px 20px 0px" }}>
                <Col span={6}></Col>
                <Col span={16} style={{ margin: " 0px 10px" }}>
                  <span style={{ fontWeight: "bold" }}>有効期限：</span>
                  <span>
                    {this.props.data && this.props.data.yukoDateFrom
                      ? this.props.data.yukoDateFrom
                      : "　"}
                    {this.props.data && this.props.data.yukoDateTo
                      ? "～" + this.props.data.yukoDateTo
                      : "　"}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span style={{ fontWeight: "bold" }}>
                    利用者及び家族の生活に対する意向:{" "}
                  </span>
                </Col>
              </Row>
              <Row className="label-mobile">
                <Col className="input-value">
                  <LineBreakText
                    text={
                      this.props.data && this.props.data.ikou
                        ? this.props.data.ikou
                        : "　"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <span style={{ fontWeight: "bold" }}>
                    介護認定審査会の意見及びサービス種類の指定:
                  </span>
                </Col>
              </Row>
              <Row className="label-mobile">
                <Col className="input-value">
                  <LineBreakText
                    text={
                      this.props.data && this.props.data.iken
                        ? this.props.data.iken
                        : "　"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <span style={{ fontWeight: "bold" }}>
                    総合的な援助の方針:{" "}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="input-value">
                  <LineBreakText
                    text={
                      this.props.data && this.props.data.housin
                        ? this.props.data.housin
                        : "　"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <span style={{ fontWeight: "bold" }}>
                    生活支援中心型の算定理由:
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="input-value">
                  <LineBreakText
                    text={
                      this.props.data && this.props.data.riyuHoka
                        ? this.props.data.riyuHoka
                        : "　"
                    }
                  />
                </Col>
              </Row>
              <Row className="row-content">
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>同意年月日:</span>
                </Col>
                <Col className="input-value" span={6}>
                  <LineBreakText
                    text={
                      this.props.data && this.props.data.plan1DouiDate
                        ? this.props.data.plan1DouiDate
                        : "　"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <span style={{ fontWeight: "bold" }}>コメント:</span>
                </Col>
              </Row>
              <Row>
                <Col className="input-value">
                  <LineBreakText
                    text={
                      this.props.data && this.props.data.comment
                        ? this.props.data.comment
                        : "　"
                    }
                  />
                </Col>
              </Row>
            </div>
          );
        }}
      </MediaQuery>
    );
  }
}
export default ViewPlan1;
