import React, { Component } from "react";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import "./RiyousyaShiryou.css";
import { FormInput } from "./UI/FormInput";

class AddEdit extends Component {
  constructor(props) {
    super(props);

    const { params } = this.props.match;

    this.state = {
      riyousyaId: params.riyousyaId,
      fileManagementId: params.fileManagementId,
      file: null,
      isDeleteModalConfirm: false,
      filePreview: null,
    };

    this.loadFilePreview = this.loadFilePreview.bind(this);
  }

  /**
   *
   */
  componentDidMount() {
    const { setting } = this.props;
    const { fileManagementId, riyousyaId } = this.state;

    // Load riyousya detail
    this.props.getRiyousya(riyousyaId);

    if (!setting) {
      // LoadSetting
      this.props.loadSetting();
    }

    // load content
    this.props.fileDetail(fileManagementId);

    this.loadFilePreview(fileManagementId);
  }

  loadFilePreview(fileManagementId) {
    this.props.loadFilePreview(
      Object.assign({ fileManagementId }),
      (data) => {
        this.setState({ filePreview: data });
      }
    );
  }

  /**
   *
   */
  render() {
    const { sessionTimeout, riyousyaDetail, setting, fdetail } = this.props;
    const { filePreview } = this.state;

    return (
      <CommonPageContainer
        id="riyousya-shiryou-addedif"
        sessionTimeout={sessionTimeout}
      >
        <FormInput
          gazouRiyouKubun={
            setting && setting.gazouRiyouKubun ? setting.gazouRiyouKubun : ""
          }
          gazouKirokuKubun={
            setting && setting.gazouKubun ? setting.gazouKubun : ""
          }
          data={fdetail}
          history={this.props.history}
          riyousyaDetail={riyousyaDetail}
          updateFile={(params, callback) =>
            this.props.updateFile(params, callback)
          }
          insertFile={(params, callback) =>
            this.props.insertFile(params, callback)
          }
          deleteFile={(id, callback) => this.props.deleteFile(id, callback)}
          riyousyaId={this.state.riyousyaId}
          filePreview={filePreview}
        />
      </CommonPageContainer>
    );
  }
}

export default AddEdit;
