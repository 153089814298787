import React, { Component } from "react";

class KanriKangoKiroku extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <div>Hello KanriKangoKiroku page</div>;
  }
}

export default KanriKangoKiroku;
