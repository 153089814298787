import {
  SYOKICHI_ITEM,
  SYOKICHI_UPDATE,
} from "../../actions/syokichiActions/actionName";
import { GetSyokichi, UpdateSyokichi } from "../../api/syokichi";

/**
 *
 * @param {*} riyousyaId
 * @param {*} kubun
 */
function syokichiItemCreator(riyousyaId, kubun) {
  return (dispatch) => {
    GetSyokichi.get(riyousyaId, kubun).then((data) =>
      dispatch({
        type: SYOKICHI_ITEM,
        payload: data,
      })
    );
  };
}

/**
 *
 * @param {*} riyousyaId
 * @param {*} kubun
 */
function syokichiUpdateCreator(riyousyaId, kubun, syokichi) {
  return (dispatch) => {
    UpdateSyokichi.update(riyousyaId, kubun, syokichi).then((data) =>
      dispatch({
        type: SYOKICHI_UPDATE,
        payload: data,
      })
    );
  };
}

export { syokichiItemCreator, syokichiUpdateCreator };
