import dayjs from "dayjs";
import { ConstSet } from "../../../common/configs/constset";
import { ConfigName } from "../../../config-name";
import { ApiPaths } from "../../constants";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils";
import { ErrorActionTypes, JirituSokusinActionTypes } from "../action_types";

function getJirituSokusinAdlMaster() {
  return async (dispatch) => {
    try {
      dispatch({ type: JirituSokusinActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.JirituSokusin.getJirituSokusinAdlMaster,
      });

      dispatch({
        type: JirituSokusinActionTypes.JIRITU_SOKUSIN_ADL_MASTER_SUCCESS,
        data,
      });
    }
    catch (error) {
      dispatch({
        type: JirituSokusinActionTypes.JIRITU_SOKUSIN_ADL_MASTER_ERROR,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getJirituSokusinLifeYougo() {
  return async (dispatch) => {
    try {
      dispatch({ type: JirituSokusinActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.JirituSokusin.getJirituSokusinLifeYougo,
      });

      dispatch({
        type: JirituSokusinActionTypes.JIRITU_SOKUSIN_LIFE_YOUGO_SUCCESS,
        data,
      });
    }
    catch (error) {
      dispatch({
        type: JirituSokusinActionTypes.JIRITU_SOKUSIN_LIFE_YOUGO_ERROR,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getJirituSokusinById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: JirituSokusinActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.JirituSokusin.getJirituSokusinById}?id=${id}`,
      });

      dispatch({
        type: JirituSokusinActionTypes.LOAD_JIRITU_SOKUSIN_SUCCESS,
        data,
      });
    }
    catch (error) {
      dispatch({ type: JirituSokusinActionTypes.LOAD_JIRITU_SOKUSIN_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteJirituSokusin(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: JirituSokusinActionTypes.REMOVE_JIRITU_SOKUSIN_REQUEST,
      });

      const data = await RequestUtils.callApi({
        method: "delete",
        path: `${ApiPaths.JirituSokusin.delJirituSokusin}?id=${id}`,
      });

      dispatch({
        type: JirituSokusinActionTypes.REMOVE_JIRITU_SOKUSIN_SUCCESS,
        data,
      });
      return callback(data);
    }
    catch (error) {
      dispatch({ type: JirituSokusinActionTypes.REMOVE_JIRITU_SOKUSIN_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function createUpJirituSokusin(body, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: JirituSokusinActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.JirituSokusin.saveJirituSokusin,
        body,
      });
      dispatch({
        type: JirituSokusinActionTypes.SAVE_JIRITU_SOKUSIN_SUCCESS,
        data,
      });
      callback(data);
    }
    catch (error) {
      dispatch({
        type: JirituSokusinActionTypes.SAVE_JIRITU_SOKUSIN_ERROR,
        error,
      });
    }
  };
}

function getListJirituSokusinToQuote(riyousyaId, id) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = { riyousyaId, id, systemControlName: menu.scn, systemControlId: menu.si };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.JirituSokusin.getListJirituSokusinToQuote}`,
        query,
      });
      dispatch({
        type: JirituSokusinActionTypes.LOAD_JIRITU_SOKUSIN_TO_QUOTE_SUCCESS,
        data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListIdsJirituSokusin(params) {
  const SWLIFEListRiyousya = StorageUtils.getValue(
    ConfigName.SWLIFE_LISTRIYOUSYA
  );
  const jirituSort =
    SWLIFEListRiyousya.sort && SWLIFEListRiyousya.sort.jiritu
      ? SWLIFEListRiyousya.sort.jiritu
      : {};
  return async (dispatch) => {
    try {
      dispatch({ type: JirituSokusinActionTypes.LOAD_SPIN_REQUEST });

      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = {
        sid: menu.si || null,
        inUsed: SWLIFEListRiyousya.isUsed ? 1 : 0,
        searchName: params.searchName,
        fromDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .startOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        toDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .endOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(jirituSort)),
        riyousyaId: params.riyousyaId,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.JirituSokusin.getListIdsJirituSokusin,
        query,
      });

      dispatch({
        type: JirituSokusinActionTypes.GET_LIST_IDS_JIRITU_SOKUSIN,
        ids: data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getFirstLoadData(riyousyaId, fdayMonth, ldayMonth) {
  return async (dispatch) => {
    try {
      const query = {
        riyousyaId,
        fdayMonth,
        ldayMonth,
      }
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.JirituSokusin.getFirstLoadData,
        query,
      })

      dispatch({ type: JirituSokusinActionTypes.GET_FIRST_LOAD_DATA, data });
    } catch (error) {
      dispatch({ type: JirituSokusinActionTypes.GET_FIRST_LOAD_DATA_ERROR });
      dispatch({ type: ErrorActionTypes.SET_ERROR, error})
    }
  }
}

export const JirituSokusinActions = {
  getJirituSokusinAdlMaster,
  getJirituSokusinLifeYougo,
  getJirituSokusinById,
  deleteJirituSokusin,
  createUpJirituSokusin,
  getListJirituSokusinToQuote,
  getListIdsJirituSokusin,

  getFirstLoadData,
};
