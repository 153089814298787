/* eslint-disable react/jsx-key */
import { Avatar, List, Row, Tabs } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import {
  checkFileStyle,
  getPath,
  getValueLocalstorage,
} from "../../../common/function_common/functionCommon";
import { guid } from "../../../common/timePicker/formatTimePicker";

const FA = require("react-fontawesome");
const { TabPane } = Tabs;

export default class ShiryouRiyousya extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imaxpi: getValueLocalstorage("imaxpi"),
    };

    // bind place
  }

  callbackRiyou = (key) => {
    this.props.tabParentChange(key);
  };

  callbackKubun = (key) => {
    this.props.tabChildChange(key);
  };

  fileDisplay = (record) => {
    this.props.fileDisplay(record);
  };

  render() {
    const {
      gazouRiyouKubun,
      gazouKubun,
      shiryou,
      loading,
      pageTotal,
      page,
      processType,
      selectKubunRiyou,
      selectKubunKiroku,
    } = this.props;
    const pageSize = 100;
    const total = pageTotal ? pageTotal * 100 : pageSize;
    const { imaxpi } = this.state;
    const path = getPath();
    return (
      <div className="riyousya-shiryou-content">
        <Row>
          <div className="rsp-c-tab">
            <Tabs
              onChange={this.callbackRiyou}
              type="card"
              activeKey={processType ? processType : "riyousyaSiryou"}
            >
              <TabPane tab="利用者資料" key="riyousyaSiryou">
                {gazouRiyouKubun ? (
                  <Tabs
                    onChange={this.callbackKubun}
                    activeKey={selectKubunRiyou ? selectKubunRiyou : "noinput"}
                  >
                    {gazouRiyouKubun
                      .toString()
                      .split(";")
                      .map((item) => {
                        if (item) {
                          const tmp = item.toString().split("_");
                          if (tmp[0] && tmp[1]) {
                            return (
                              <TabPane tab={tmp[0]} key={tmp[1]}>
                                <List
                                  className="rscp-content-list"
                                  loading={loading}
                                  itemLayout="hirozontal"
                                  pagination={{
                                    onChange: page => {
                                      this.props.loadNewPage(page);
                                    },
                                    pageSize,
                                    current: page,
                                    total,
                                  }}
                                  dataSource={shiryou}
                                  renderItem={(item, index) => (
                                    <List.Item
                                      key={index}
                                      actions={[
                                        <div className="rscp-content-list-action">
                                          <Row className="rscp-content-list-action-up" style={{ justifyContent: "flex-end" }}>
                                            {item.priorityf ? (
                                              <span className="rscp-content-list-action-up-l">
                                                <FA name="bookmark-o" />
                                              </span>
                                            ) : null}

                                            <span className="rscp-content-list-action-up-r">
                                              {(page - 1) * pageSize + index + 1}
                                            </span>
                                          </Row>

                                          <Link
                                            to={`${path}/riyousya-shiryou-input/${item.riyousya_id}/${item.id}`}
                                          >
                                            <Row className="rscp-content-list-action-down">
                                              <span>
                                                <FA
                                                  name="pencil-square-o"
                                                  key={guid()}
                                                />
                                                編集
                                              </span>
                                            </Row>
                                          </Link>
                                        </div>,
                                      ]}
                                    >
                                      <List.Item.Meta
                                        avatar={
                                          <Avatar
                                            onClick={this.fileDisplay.bind(
                                              this,
                                              item
                                            )}
                                            src={
                                              !imaxpi || !item.thumbnail
                                                ? checkFileStyle(item.type).icon
                                                : item.thumbnail
                                            }
                                          />
                                        }
                                        title={
                                          <div className="rscp-content-list-title">
                                            <span>
                                              <FA name="clock-o" key={guid()} />{" "}
                                              {`${item.date} ${item.time}`}
                                            </span>
                                            <span>
                                              <FA
                                                name="paperclip"
                                                key={guid()}
                                              />{" "}
                                              {item.file_name}
                                            </span>
                                            <span>
                                              <FA
                                                name="commenting-o"
                                                key={guid()}
                                              />{" "}
                                              {item.comment}
                                            </span>
                                          </div>
                                        }
                                      />
                                    </List.Item>
                                  )}
                                />
                              </TabPane>
                            );
                          }
                        }
                      })}
                  </Tabs>
                ) : null}
              </TabPane>
              <TabPane tab="記録添付" key="kirokuTenpu">
                {gazouKubun ? (
                  <Tabs
                    onChange={this.callbackKubun}
                    activeKey={
                      selectKubunKiroku ? selectKubunKiroku : "noinput"
                    }
                  >
                    {gazouKubun
                      .toString()
                      .split(";")
                      .map((item) => {
                        if (item) {
                          const tmp = item.toString().split("_");
                          if (tmp[0] && tmp[1]) {
                            return (
                              <TabPane tab={tmp[0]} key={tmp[1]}>
                                <List
                                  className="rscp-content-list"
                                  loading={loading}
                                  itemLayout="hirozontal"
                                  pagination={{
                                    onChange: page => {
                                      this.props.loadNewPage(page);
                                    },
                                    pageSize,
                                    current: page,
                                    total,
                                  }}
                                  dataSource={shiryou}
                                  renderItem={(item, index) => (
                                    <List.Item
                                      key={index}
                                      actions={[
                                        <div className="rscp-content-list-action" key={0}>
                                          <Row className="rscp-content-list-action-up">
                                            {item.priorityf ? (
                                              <span className="rscp-content-list-action-up-l">
                                                <FA name="bookmark-o" />
                                              </span>
                                            ) : null}

                                            <span>{(page - 1) * pageSize + index + 1}</span>
                                          </Row>
                                          <Row className="rscp-content-list-action-down">
                                            <Link
                                              to={`${path}/riyousya-shiryou-input/${item.riyousya_id}/${item.id}`}
                                            >
                                              <span>
                                                <FA
                                                  name="pencil-square-o"
                                                  key={guid()}
                                                />
                                                編集
                                              </span>
                                            </Link>
                                          </Row>
                                        </div>,
                                      ]}
                                    >
                                      <List.Item.Meta
                                        avatar={
                                          <Avatar
                                            onClick={this.fileDisplay.bind(
                                              this,
                                              item
                                            )}
                                            src={
                                              !imaxpi || !item.thumbnail
                                                ? checkFileStyle(item.type).icon
                                                : item.thumbnail
                                            }
                                          />
                                        }
                                        title={
                                          <div className="rscp-content-list-title">
                                            <span>
                                              <FA name="clock-o" key={guid()} />{" "}
                                              {`${item.date} ${item.time}`}
                                            </span>
                                            <span>
                                              <FA
                                                name="paperclip"
                                                key={guid()}
                                              />{" "}
                                              {item.file_name}
                                            </span>
                                            <span>
                                              <FA
                                                name="commenting-o"
                                                key={guid()}
                                              />{" "}
                                              {item.comment}
                                            </span>
                                          </div>
                                        }
                                      />
                                    </List.Item>
                                  )}
                                />
                              </TabPane>
                            );
                          }
                        }
                      })}
                  </Tabs>
                ) : null}
              </TabPane>
            </Tabs>
          </div>
        </Row>
      </div>
    );
  }
}
