import React, { Component } from "react";
import { Modal, Table } from "antd";
import { connect } from "react-redux";

import { loadTantoKaigi } from "../../../actions/tantoKaigiActions/actionCreators";

class StaffMeetingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sort: { kaisaiDate: "desc" },
      selectedRecord: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.props.loadTantoKaigi(
        {
          riyousyaId: this.props.riyousyaId,
          page: this.state.page,
          sort: this.state.sort,
        },
        (callback) => {}
      );
    }
  }

  handleOk() {
    const { selectedRecord } = this.state;

    if (selectedRecord && selectedRecord.id) {
      this.props.onSelectItem(selectedRecord);
    }
    this.props.onHide();
  }

  handleCancel() {
    this.props.onHide();
  }

  handlePageChange(page) {
    this.setState({ page });
    this.props.loadTantoKaigi(
      {
        riyousyaId: this.props.riyousyaId,
        page,
        sort: this.state.sort,
      },
      (callback) => {}
    );
  }

  handleSelectRow(record) {
    this.setState({ selectedRecord: record });
  }

  getColumnWidth() {
    const modal = document.querySelector(".staff-meeting-modal");
    return modal ? modal.offsetWidth - 150 : 200;
  }

  render() {
    const { selectedRecord } = this.state;
    const { tantoKaigis, metaTantoKaigi } = this.props;
    const columns = [
      {
        title: "開催日",
        dataIndex: "kaisaiDate",
        key: "kaisaiDate",
        width: 100,
      },
      {
        title: "検討内容",
        dataIndex: "naiyou",
        key: "naiyou",
        onCell: () => ({
          style: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        }),
      },
    ];
    const pagingOptions = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: metaTantoKaigi && metaTantoKaigi.total,
      current: metaTantoKaigi && metaTantoKaigi.page,
      pageSize: metaTantoKaigi && metaTantoKaigi.pageSize,
    };

    return (
      (<Modal
        open={this.props.visible}
        title="担当者会議"
        onOk={() => this.handleOk()}
        onCancel={() => this.handleCancel()}
        className="staff-meeting-modal"
      >
        <Table
          className="staff-meeting-table"
          tableLayout="fixed"
          style={{ cursor: "pointer" }}
          size={"small"}
          rowKey={"id"}
          bordered={true}
          columns={columns}
          dataSource={tantoKaigis}
          pagination={pagingOptions}
          onRow={(record) => ({
            onClick: () => this.handleSelectRow(record),
          })}
          rowClassName={(record) =>
            selectedRecord != null && record.id === selectedRecord.id
              ? "pljp-table-row-hover"
              : "pointer-row"
          }
        />
      </Modal>)
    );
  }
}

const mapStateToProps = () => (state) => ({
  tantoKaigis: state.documentList.tantoKaigis,
  metaTantoKaigi: state.documentList.metaTantoKaigi,
});

const mapDispatchToProps = () => (dispatch) => ({
  loadTantoKaigi: (args, callback) => dispatch(loadTantoKaigi(args, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffMeetingModal);
