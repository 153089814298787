import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";
import { reportWeeklyFormat } from "../common/function_common/reportWeeklyDataFormat";

async function decodeToken() {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.DECODE_TOKEN, {
      method: "GET",
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

/**
 * DEL ALL RECORD
 *
 * @todo handle error exception
 */
const DeleteRecords = {
  deleteRecords(record, parameterObj) {
    const lisDel = [];

    for (const key in record) {
      if (key && record[key]) {
        const slipKey = key.split("-");

        if (slipKey[0] && slipKey[1]) {
          switch (slipKey[0]) {
            case "1":
              lisDel.push(vitalDelete(slipKey[1]));
              break;

            case "2":
              lisDel.push(mealDelete(slipKey[1]));
              break;

            case "3":
              lisDel.push(suibunDelete(slipKey[1]));
              break;

            case "4":
              lisDel.push(haisetuDelete(slipKey[1]));
              break;

            case "5":
              lisDel.push(nyuyokuDelete(slipKey[1]));
              break;

            case "6":
              lisDel.push(fukuyakuDelete(slipKey[1]));
              break;

            case "7": {
              const countKaigoDel = slipKey[1].split(",");
              if (countKaigoDel.length > 0) {
                countKaigoDel.forEach((item) => {
                  if (item) {
                    lisDel.push(kaigoDelete(item));
                  }
                });
              }
              break;
            }

            case "8":
              lisDel.push(tokkiDelete(slipKey[1]));
              break;

            case "9":
              lisDel.push(gazouDelete(slipKey[1]));
              break;

            default:
              lisDel.push(decodeToken());
          }
        }
      }
    }

    return axios
      .all(lisDel)
      .then(
        axios.spread(function () {
          //
          return LoadDataApi.loadDataApi(parameterObj);
        })
      )
      .catch((err) => {
        clearLocalStorage(err);
      });
  },
};

/**
 * DEL ALL RECORD
 *
 * @todo handle error exception
 */
const DeleteAllImages = {
  deleteAllImages(record, parameterObj) {
    const lisDel = [];

    if (record && record.length > 0) {
      for (let i = 0; i < record.length; i++) {
        if (record[i].fileManagementId) {
          const ids = record[i].fileManagementId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(gazouDelete(ids[j]));
              }
            }
          }
        }
      }
    }

    return Promise.all(lisDel)
      .then(
        axios.spread(function () {
          return LoadDataApi.loadDataApi(parameterObj);
        })
      )
      .catch((err) => {
        clearLocalStorage(err);
      });
  },
};

/**
 * DEL ALL RECORD
 *
 * @todo handle error exception
 */
const DeleteAllRecords = {
  deleteAllRecords(record, parameterObj) {
    const lisDel = [];

    if (record && record.length > 0) {
      for (let i = 0; i < record.length; i++) {
        if (record[i].vitalId) {
          const ids = record[i].vitalId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(vitalDelete(ids[j]));
              }
            }
          }
        }

        if (record[i].mealId) {
          const ids = record[i].mealId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(mealDelete(ids[j]));
              }
            }
          }
        }

        if (record[i].suibunId) {
          const ids = record[i].suibunId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(suibunDelete(ids[j]));
              }
            }
          }
        }

        if (record[i].haisetuId) {
          const ids = record[i].haisetuId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(haisetuDelete(ids[j]));
              }
            }
          }
        }

        if (record[i].nyuyokuId) {
          const ids = record[i].nyuyokuId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(nyuyokuDelete(ids[j]));
              }
            }
          }
        }

        if (record[i].fukuyakuId) {
          const ids = record[i].fukuyakuId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(fukuyakuDelete(ids[j]));
              }
            }
          }
        }

        if (record[i].kaigoId) {
          const ids = record[i].kaigoId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(kaigoDelete(ids[j]));
              }
            }
          }
        }

        if (record[i].tokkiId) {
          const ids = record[i].tokkiId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(tokkiDelete(ids[j]));
              }
            }
          }
        }

        if (record[i].fileManagementId) {
          const ids = record[i].fileManagementId.toString().split(",");
          if (ids && ids.length > 0) {
            for (let j = 0; j < ids.length; j++) {
              if (ids[j]) {
                lisDel.push(gazouDelete(ids[j]));
              }
            }
          }
        }
      }
    }

    return Promise.all(lisDel)
      .then(
        axios.spread(function () {
          return LoadDataApi.loadDataApi(parameterObj);
        })
      )
      .catch((err) => {
        clearLocalStorage(err);
      });
  },
};

/**
 *
 * @param {*} parameterObj
 * parameterObj.tabName: active tabName
 * parameterObj.isPropData: if propData loaded, return false and reuse
 * parameterObj.isLoadBasic: check if have to reload riyousya info again
 *
 *
 */
const LoadDataApi = {
  loadDataApi(parameterObj) {
    return Promise.all([
      getVitalRecord(parameterObj),
      getMealRecord(parameterObj),
      getSuibunRecord(parameterObj),
      getHaisetuRecord(parameterObj),
      getNyuyokuRecord(parameterObj),
      getFukuyakuRecord(parameterObj),
      getKaigonaiyoRecord(parameterObj),
      getTokkiRecord(parameterObj),
      getGaizouRecord(parameterObj),
      getPlan(parameterObj),
    ])
      .then(
        axios.spread(function (
          vital,
          meal,
          suibun,
          haisetu,
          nyuyoku,
          fukuyaku,
          kaigo,
          tokki,
          gazou,
          plan
        ) {
          return reportWeeklyFormat(
            parameterObj,
            vital,
            meal,
            suibun,
            haisetu,
            nyuyoku,
            fukuyaku,
            kaigo,
            tokki,
            gazou,
            plan
          );
        })
      )
      .catch((err) => {
        clearLocalStorage(err);
      });
  },
};

/**
 * get record data
 *
 * @param {obj} parameterObj
 */
async function getVitalRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getMealRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
        shubetu: parameterObj.shubetu && parameterObj.shubetu !== "全表示"
          ? parameterObj.shubetu
          : null, // mealType
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getSuibunRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getHaisetuRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
        haisetuKubun: parameterObj.haisetuKubun && parameterObj.haisetuKubun !== "両方"
          ? parameterObj.haisetuKubun
          : null,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getNyuyokuRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getFukuyakuRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getKaigonaiyoRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGONAIYOU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
        kaigoNaiyouCode: parameterObj.kaigoNaiyouCode &&
          parameterObj.kaigoNaiyouCode !== "全表示"
          ? parameterObj.kaigoNaiyouCode
          : null,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getTokkiRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getGaizouRecord(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU, {
      method: "POST",
      data: {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaIds: parameterObj.riyousyaId,
        startDate: parameterObj.startDate,
        endDate: parameterObj.endDate,
      },
    });
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

async function vitalDelete(vitalId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_VITAL_DELETE, {
      method: "DELETE",
      data: {
        vitalId: "" + vitalId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}
// end vital API

async function mealDelete(mealId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_MEAL_DELETE, {
      method: "DELETE",
      data: {
        mealId: "" + mealId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function suibunDelete(suibunId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_SUIBUN_DELETE, {
      method: "DELETE",
      data: {
        suibunId: "" + suibunId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function haisetuDelete(haisetuId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_HAISETU_DELETE, {
      method: "DELETE",
      data: {
        haisetuId: "" + haisetuId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function nyuyokuDelete(nyuyokuId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_NYUYOKU_DELETE, {
      method: "DELETE",
      data: {
        nyuyokuId: "" + nyuyokuId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function fukuyakuDelete(fukuyakuId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_FUKUYAKU_DELETE, {
      method: "DELETE",
      data: {
        fukuyakuId: "" + fukuyakuId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function kaigoDelete(kaigoId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_KAIGO_DELETE, {
      method: "DELETE",
      data: {
        kaigoId: "" + kaigoId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function tokkiDelete(tokkiId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_TOKKI_DELETE, {
      method: "DELETE",
      data: {
        tokkiId: "" + tokkiId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

async function gazouDelete(fileManagementId) {
  try {
    const res = await axios(apiUrls.INIT + apiUrls.REPORT_GAZOU_DELETE, {
      method: "DELETE",
      data: {
        fileManagementId: "" + fileManagementId,
      },
    });
    return res.data;
  }
  catch (err) {
    if (!err.response) {
      // network error
      window.location.reload();
    }
    else {
      return err.response;
    }
  }
}

const ZenkaiLoad = {
  async load(date, riyousyaId) {
    const key = getMenu();
    try {
      const res = await axios(apiUrls.INIT + apiUrls.NYUTAISHO_ZENKAI_LOAD, {
        method: "POST",
        data: {
          systemControlName: JSON.parse(localStorage.getItem(key)).sn,
          systemControlId: JSON.parse(localStorage.getItem(key)).si,
          riyousyaIds: "" + riyousyaId,
          date,
        },
      });
      return res.data;
    }
    catch (err) {
      if (!err.response) {
        // network error
        window.location.reload();
      }
      else {
        return err.response;
      }
    }
  },
};

/**
 *
 * @param {*} riyousyaIds
 * @param {*} parameterObj
 * parameterObj.nyutaishoDate: load date data that is selected by user
 */
async function getPlan(parameterObj) {
  const key = getMenu();
  try {
    const res = await axios(
      apiUrls.INIT +
      apiUrls.DAILY_PLAN_WEEKLY +
      "?riyousyaId=" +
      parameterObj.riyousyaId +
      "&mainSID=" +
      JSON.parse(localStorage.getItem(key)).si,
      {
        method: "GET",
      }
    );
    return res.data;
  }
  catch (err) {
    clearLocalStorage(err);
  }
}

/**
 *
 */
function clearLocalStorage(err) {
  /**
   * @TODO handle error
   */
  if (!err.response) {
    // network error
  }
  else {
    // Check Token Exist
    if (err.response.status === 403) {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("doubleLogin");
      localStorage.removeItem("getAvatarResponse");
      localStorage.removeItem("getAvatarResponseData");
      window.location.replace("/login");
    }

    return err;
  }
}

export {
  DeleteAllImages,
  DeleteAllRecords, DeleteRecords, LoadDataApi, ZenkaiLoad
};

