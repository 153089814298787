import {
  FileDoneOutlined,
  LinkOutlined,
  MessageOutlined,
  OrderedListOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TableOutlined,
  TeamOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Col, Row } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getPath } from "../../common/function_common/functionCommon";
import { KanriLayoutContainer } from "./UI/KanriLayout";

import "./kanri.css";

class Kanri extends Component {
  render() {
    const path = getPath();
    return (
      <KanriLayoutContainer router="dashboard" breadcrumbLink={"/kanri/dashboard"} breadcrumbText={""}>
        <Row gutter={16} className="kanri-home-page">
          <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/table"}>
              <Row className="khp-dash">
                <Col span={16}><span className="nav-text">パレット管理<span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <TableOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col>

          <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/user"}>
              <Row className="khp-dash">
                <Col span={16}><span className="nav-text">ユーザ管理 <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <UserOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col>

          <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/userriyousyalink"} >
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">ユーザリンク <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"> <span> <LinkOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col>

          <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/tantomaster"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">担当者マスタ <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <TeamOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col>

          <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/settingmaster"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">セッティングマスタ <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <SettingOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col > <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/yougomaster"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">用語マスタ <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <ToolOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col > <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/lifeyougomaster"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">LIFE用語マスタ <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <ToolOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col > <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/kaigoyougomaster"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">介護用語マスタ <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <ToolOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col > <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/dengondata"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">伝言データ <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <MessageOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col > <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/moshiokurilink"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">申し送りリンク <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <ScheduleOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col > <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/file"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">ファイル管理 <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <FileDoneOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col > <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/sendgroup"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text"> グループ設定 <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <UsergroupAddOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col > <Col span={6} className="f-khp-dash">
            <Link to={path + "/kanri/openscreenlog"}>
              <Row className="khp-dash">
                <Col span={16}>
                  <span className="nav-text">画面遷移ログ <span style={{ display: "block", fontSize: 12, color: "#888" }}>累計記録: 156x</span></span></Col>
                <Col span={8} className="f-khp-dash-sp"><span> <OrderedListOutlined className="khp-dash-sp" /></span></Col>
              </Row>
            </Link>
          </Col >
        </Row >
      </KanriLayoutContainer >
    );
  }
}

export default Kanri;
